import React, { Component } from "react";
import {
  EVENT_FORM_CONFERENCE,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import {
  determineConferencingType,
  conferenceIconUrl,
  isPhoneConferencingWhatsApp,
  isConferencingPhone,
  renderConferencingActionText,
} from "../lib/conferencing";
import { truncateConferenceUrl } from "../services/googleCalendarHelpers";
import {
  openConferencingURL,
  hasConferencingData,
  getPhoneNumberFromText,
  OpenLink,
  handleError,
} from "../services/commonUsefulFunctions";
import GlobalKeyMapTile from "./globalKeyMapTile";
import { connect } from "react-redux";
import CopiableContentContainer from "./copiableContentContainer";
import Broadcast from "../broadcasts/broadcast";
import { Video } from "react-feather";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import NativeConferencingInfo from "./nativeConferencingInfo";
import classNames from "classnames";
import conferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import { getEventConferenceData } from "../services/eventResourceAccessors";
import { isAppInTaskMode } from "../services/appFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";

const HANGOUT_LINK_STYLE = {
  left: "-18px",
  top: "-13px",
};

const DEFAULT_VIDEO_TILE_PLACEMENT = -75;

class EventConferencing extends Component {
  constructor(props) {
    super(props);

    this.openConferencingLink = this.openConferencingLink.bind(this);
    this.copyConferencingLink = this.copyConferencingLink.bind(this);
    this.onClickEditConferencing = this.onClickEditConferencing.bind(this);

    !this.props.shouldIgnoreBroadcast &&
      Broadcast.subscribe("OPEN_CONFERENCING_LINK", this.openConferencingLink);
    !this.props.shouldIgnoreBroadcast &&
      conferencingBroadcasts.subscribe(
        "COPY_EXPANDED_CONFERENCING_LINK",
        this.copyConferencingLink
      );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    !this.props.shouldIgnoreBroadcast &&
      Broadcast.unsubscribe("OPEN_CONFERENCING_LINK");
    !this.props.shouldIgnoreBroadcast &&
      conferencingBroadcasts.subscribe(
        "COPY_EXPANDED_CONFERENCING_LINK",
        this.copyConferencingLink
      );
  }

  render() {
    if (!this.props.conferenceUrl) {
      return null;
    }

    return <div>{this.renderConferencingContent()}</div>;
  }

  getEventConferencingWidth() {
    if (this.props.isMobileView) {
      return "w-full";
    } else {
      return !!this.props.agendaPanelStyle ? "agenda-up-next-content-width" : "width-250"
    }
  }

  renderConferencingContent() {
    const containsConferencingData = hasConferencingData(this.props.event);
    return (
      <div
        id="event-conferencing"
        className={classNames(
          "position-relative",
          this.getEventConferencingWidth()
        )}
      >
        {!this.props.hideShortCut && (
          <GlobalKeyMapTile
            style={this.props.agendaPanelStyle || HANGOUT_LINK_STYLE}
            shortcut={this.props.agendaPanelStyle ? "V" : "GV"}
            shouldHide={this.props.shouldHideEdit}
          />
        )}

        {this.determineTitle()}

        {containsConferencingData ? (
          <NativeConferencingInfo
            event={this.props.event}
            isPhone={isConferencingPhone(this.props.conferenceUrl)}
            showCopiableHotKeys={true}
          />
        ) : null}

        {!containsConferencingData && !this.props.agendaPanelStyle && (
          <div
            className="zoom-additional-info-section"
            style={{ wordBreak: "break-all", width: 270 }}
          >
            <CopiableContentContainer
              style={{ alignItems: "flex-start" }}
              textToCopy={this.props.conferenceUrl}
              hotKeys="Y V"
            >
              {truncateConferenceUrl(this.props.conferenceUrl)}
            </CopiableContentContainer>
          </div>
        )}

        {!this.props.agendaPanelStyle && !this.props.hideTile && (
          <div
            className="expanded-view-tile"
            style={{ right: DEFAULT_VIDEO_TILE_PLACEMENT, top: 0 }}
          >
            <ShortcutTile shortcut={"V"} />
          </div>
        )}
      </div>
    );
  }

  renderTextWithoutCopiableContent() {
    return (
      <div className="join-conference-text">
        {renderConferencingActionText(
          this.props.conferenceUrl,
          this.props.event
        )}
      </div>
    );
  }

  renderTextWithCopiableContent() {
    return (
      <div
        className="hoverable-text cursor-pointer"
        onClick={this.openConferencingLink}
      >
        <CopiableContentContainer
          style={{ alignItems: "flex-start" }}
          textToCopy={this.props.conferenceUrl}
          hotKeys="Y V"
          copiableHintRightHandeSide={this.props.agendaPanelStyle}
        >
          {renderConferencingActionText(
            this.props.conferenceUrl,
            this.props.event
          )}
        </CopiableContentContainer>
      </div>
    );
  }

  determineTitle() {
    if (this.props.displayTitleWithCopy) {
      return this.renderTextWithCopiableContent();
    }
    return (
      <CopiableContentContainer
        style={{ alignItems: "center" }}
        shouldHide={this.props.shouldHideEdit}
        textToCopy={this.props.conferenceUrl}
      >
        {this.renderTitle()}
      </CopiableContentContainer>
    );
  }

  renderTitle() {
    return (
      <div
        className="event-hangout-button-wrapper"
        onClick={this.openConferencingLink}
      >
        {!this.props.hideIcon && (
          <div className="event-expand-icon">
            {this.determineConferencingIcon()}
          </div>
        )}

        {this.renderTextWithoutCopiableContent()}
      </div>
    );
  }

  determineConferencingIcon() {
    const eventConferenceData = getEventConferenceData(this.props.event);
    if (eventConferenceData?.conferenceSolution?.iconUri) {
      return this.renderIcon(eventConferenceData.conferenceSolution.iconUri);
    } else if (determineConferencingType(this.props.conferenceUrl)) {
      return this.renderIcon(
        conferenceIconUrl(determineConferencingType(this.props.conferenceUrl))
      );
    } else {
      return <Video className="non-clickable-icon" size="14" />;
    }
  }

  renderIcon(iconURL) {
    return <img alt="" width="16px" height="16px" src={iconURL} />;
  }

  onClickEditConferencing() {
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      actionMode,
    } = this.props;
    if (
      isAppInTaskMode({
        actionMode,
        reverseSlotsText
      })
    ) {
      return;
    }

    Broadcast.publish(
      "GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED",
      EVENT_FORM_CONFERENCE
    );
  }

  copyConferencingLink() {
    if (!this.props.conferenceUrl) {
      return;
    }

    navigator.clipboard.writeText(this.props.conferenceUrl).then(
      () => {
        if (!this._isMounted) {
          return;
        }
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Copied conferencing link"
        );
        /* clipboard successfully set */
      },
      function (e) {
        handleError(e);
        /* clipboard write failed */
      }
    );
  }

  openConferencingLink(e) {
    if (this.props.conferenceUrl) {
      if (
        isPhoneConferencingWhatsApp(this.props.currentUser) &&
        getPhoneNumberFromText(this.props.conferenceUrl, true)
      ) {
        OpenLink(getPhoneNumberFromText(this.props.conferenceUrl, true));
        return;
      }

      openConferencingURL(
        this.props.conferenceUrl,
        this.props.event?.userEmail || this.props.currentUser.email
      );
    }

    if (this.props.shouldShowGlobalKeyMap) this.props.hideGlobalKeyMap();

    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
  };
}

function mapStateToProps(state) {
  let {
    shouldShowGlobalKeyMap,
    currentUser,
    isMobileView,
    actionMode,
  } = state;

  return {
    shouldShowGlobalKeyMap,
    currentUser,
    isMobileView,
    actionMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const temporaryStateStore = useTemporaryStateStore();

  return (
    <BaseComponent
      {...props}
      temporaryStateStore={temporaryStateStore}
    />
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(withStore(EventConferencing));
