import React, { useMemo } from "react";
import classNames from "classnames";
import { format, getHours } from "date-fns";
import { useSelector } from "react-redux";
import SchedulingAssistantEvent, { SchedulingAssistantSummaryEvent } from "./schedulingAssistantEvent";
import { getEventUserEventID } from "../../services/eventResourceAccessors";
import DroppableTime, { TIME_SEGMENTS } from "./droppableTime";
import {
  AttendeeGroup,
  getBusyAndTentativeRanges,
  getHourDisplayText,
  isWithinWorkHours,
  SchedulingAssistantItem,
  WorkHours,
} from "./helperFunctions";
import { getHoursOfDay } from "../../lib/dateFunctions";
import { uniqueBy } from "../../lib/arrayFunctions";

interface SingleDayProps {
  date: Date
  groups: AttendeeGroup[]
  setTime: (time: Date) => void
  items: SchedulingAssistantItem[]
  isShowingWorkHoursOnly: boolean
  calendarColor: string
  workHours: WorkHours
  priorityAttendees: Set<string>
  user: User
}

export default function SingleDay({
  date,
  groups,
  setTime,
  items, // always going to be an array
  isShowingWorkHoursOnly,
  calendarColor,
  workHours,
  priorityAttendees,
  user,
}: SingleDayProps) {
  const format24HourTime = useSelector((state) => state.format24HourTime);

  // TODO: Investigate why there are duplicate events being passed at all.
  // We shouldn't need to dedupe here.
  const uniqueItems = useMemo(() => (
    uniqueBy(items, (item) => {
      return `${getEventUserEventID(item.event)}-${item.groupID}`;
    })
  ), [items]);

  const { busyRanges, tentativeRanges } = useMemo(() => {
    return getBusyAndTentativeRanges(uniqueItems, priorityAttendees);
  }, [uniqueItems, priorityAttendees]);

  // TODO: There is a known bug at the end of DST where slots are not selectable on the repeated hour.
  // There is also a duplicate key error (non-crashing) since one hour is repeated.
  const hours = useMemo(() => {
    const allHours = getHoursOfDay(date);
    if (isShowingWorkHoursOnly) {
      return allHours.filter(hourDate => isWithinWorkHours(hourDate, workHours));
    }
    return allHours;
  }, [isShowingWorkHoursOnly]);

  const hoursText = hours.map((hour) => {
    const hourInt = getHours(hour);
    return getHourDisplayText({ hourInt, format24HourTime });
  });

  const columns = Array.from(
    { length: hoursText.length },
    (_, index) => index + 1,
  );
  const rows = Array.from(
    { length: groups.length + 3 },
    (_, index) => index + 1,
  );

  const renderOnClickSpace = (row: number, hour: number) => {
    return (
      <React.Fragment key={hour}>
        {[
          TIME_SEGMENTS.START_OF_HOUR,
          TIME_SEGMENTS.HALF_HOUR,
        ].map(segment => (
          <DroppableTime key={segment} hour={hour} date={date} row={row} setTime={setTime} timeSegment={segment} />
        ))}
      </React.Fragment>
    );
  };

  return (
    <div
      className={classNames(
        "flex flex-col",
        "relative",
      )}
    >
      {/* Date Header */}
      <div className="font-bold text-center date-header-height relative flex flex-col justify-center p-2 default-border-right">
        <div className="sticky w-max left-2 font-weight-300">{format(date, "PPPP")}</div>
      </div>
      <div className="w-max">
        <div
          className="grid default-border-top relative"
          style={{ gridTemplateColumns: `repeat(${hours.length}, 1fr)` }} // Inline style for 24 equal columns
        >
          {/* Render the DnD droppable spots. This is overlaid on top of the grid since we don't need
          separate drop zones for each row in the grid. That would result in potentially thousands
          of extra drop zones and would significantly impact performance. */}
          <div className="absolute inset-0 flex">
            {columns.map((_col, colIndex) => {
              const hourDate = hours[colIndex];
              return renderOnClickSpace(0, getHours(hourDate));
            })}
          </div>
          {/* Render the grid */}
          {rows.map((row, rowIndex) =>
            columns.map((col) => (
              <div
                key={`${row}-${col}`}
                className={classNames(
                  "cell-width default-border-right",
                  rowIndex === rows.length - 1 ? "add-attendee-cell-height" : "cell-height",
                  "flex items-center justify-center default-font-size",
                  rowIndex === rows.length - 1 ? "" : "default-border-bottom",
                )}
              >
                {row === 1
                  ? hoursText[col - 1]
                  : null}
              </div>
            )),
          )}
        </div>
      </div>

      {uniqueItems.map((item) => (
        <SchedulingAssistantEvent
          key={`${getEventUserEventID(item.event)}-${item.groupID}`}
          calendarColor={calendarColor}
          date={date}
          groups={groups}
          isShowingWorkHoursOnly={isShowingWorkHoursOnly}
          item={item}
          workHours={workHours}
          user={user}
        />
      ))}
      {busyRanges.map(range => (
        <SchedulingAssistantSummaryEvent
          key={`${range.start.toISOString()}-${range.end.toISOString()}`}
          calendarColor={calendarColor}
          date={date}
          end={range.end}
          isShowingWorkHoursOnly={isShowingWorkHoursOnly}
          start={range.start}
          workHours={workHours}
          priorityAttendees={priorityAttendees}
        />
      ))}
      {tentativeRanges.map(range => (
        <SchedulingAssistantSummaryEvent
          key={`${range.start.toISOString()}-${range.end.toISOString()}`}
          calendarColor={calendarColor}
          date={date}
          end={range.end}
          isShowingWorkHoursOnly={isShowingWorkHoursOnly}
          isTentative
          start={range.start}
          workHours={workHours}
          priorityAttendees={priorityAttendees}
        />
      ))}
    </div>
  );
}
