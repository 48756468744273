/* Error should be a string */

import broadcast from "../broadcasts/broadcast";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { isSameEmail } from "./stringFunctions";
import { getUserEmail, getUserProvider } from "./userFunctions";
import { CALENDAR_PROVIDERS } from "./vimcalVariables";

export const PERMISSION_MODAL_TYPES = {
  UPDATE_AUTH_PERMISSIONS: "UPDATE_AUTH_PERMISSIONS",
  UPDATE_CALENDAR_PERMISSIONS: "UPDATE_CALENDAR_PERMISSIONS",
  UPDATE_EMAIL_PERMISSIONS: "UPDATE_EMAIL_PERMISSIONS",
  USER_CONSENT_SCREEN: "USER_CONSENT_SCREEN",
  UPDATE_GOOGLE_DRIVE_PERMISSIONS: "UPDATE_GOOGLE_DRIVE_PERMISSIONS",
  UPDATE_GOOGLE_GROUPS_PERMISSIONS: "UPDATE_GOOGLE_GROUPS_PERMISSIONS",
};

export const ERROR_TYPES = {
  AUTH_EXPIRED: "auth_expired",
  PERMISSION_DENIED: "permission_denied",
  EMAIL_PERMISSION_DENIED: "email_permission_denied",
};
/* At current time we only match auth_expired and permission_denied */
export function broadcastOpenPermissionModal({
  error,
  userEmail,
  provider = CALENDAR_PROVIDERS.GOOGLE,
  allLoggedInUsers,
}) {
  // need to check current user since otherwise the modal will never go away.
  // e.g could have account that user is not using and then if user loses auth to that, then this modal will never disappear
  if (!userEmail || isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return;
  }

  const matchingUser = allLoggedInUsers.find((user) =>
    isSameEmail(getUserEmail(user), userEmail)
  );
  if (isEmptyObjectOrFalsey(matchingUser)) {
    return;
  }
  const permissionProvider = provider || getUserProvider(matchingUser);

  switch (error) {
    case ERROR_TYPES.AUTH_EXPIRED:
      broadcast.publish("OPEN_PERMISSIONS_MODAL", {
        modalContent: PERMISSION_MODAL_TYPES.UPDATE_AUTH_PERMISSIONS,
        permissionProvider,
        permissionEmail: userEmail,
      });
      return;
    case ERROR_TYPES.PERMISSION_DENIED:
      broadcast.publish("OPEN_PERMISSIONS_MODAL", {
        modalContent: PERMISSION_MODAL_TYPES.UPDATE_CALENDAR_PERMISSIONS,
        permissionProvider,
        permissionEmail: userEmail,
      });
      return;
    case ERROR_TYPES.EMAIL_PERMISSION_DENIED:
      broadcast.publish("OPEN_PERMISSIONS_MODAL", {
        modalContent: PERMISSION_MODAL_TYPES.UPDATE_EMAIL_PERMISSIONS,
        permissionProvider,
        permissionEmail: userEmail,
      });
      return;
    case PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN:
      // backend passes back boolean (.is_missing_refresh_token) to indicate if user needs to re-consent
      broadcast.publish("OPEN_PERMISSIONS_MODAL", {
        modalContent: PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN,
        permissionProvider,
        permissionEmail: userEmail,
      });
      return;
    default:
      return;
  }
}

export function isNeedToShowUserConsentScreen(response) {
  return response?.user?.is_missing_refresh_token;
}

export function isPermissionsError(error) {
  const allPermissionErrors = Object.values(ERROR_TYPES).concat(PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN);
  return allPermissionErrors.includes(error);
}
