import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { constructRequestURL } from "../services/api";
import Fetcher from "../services/fetcher";
import {
  createAvailabilityTextFromEvent,
  generateBookableSlotsFromObj,
  generateFreeSlotsFromBusySlots,
  getAllBusySlots,
  guessTimeZone,
  handleError,
  localData,
  sortPersonalLinks,
  createDefaultPersonalLinkEventSummary,
  determineConferencingFromCurrentUser,
  checkIfBookableSlotsAreValid,
  convertToTimeZone,
  shouldRoundToNearest15,
} from "../services/commonUsefulFunctions";
import {
  PERSONAL_LINK_LOCAL_DATA,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import { determineBookingLink } from "../lib/envFunctions";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import Broadcast from "../broadcasts/broadcast";
import {
  fetchFreeBusySlots,
  getBlockedCalendarsID,
  getPersonalLinkHotKeyIndex,
} from "../lib/availabilityFunctions";
import { isVersionV2 } from "../services/versionFunctions";
import {
  useAllCalendars,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { getUserEmail, getUserName, getUserToken } from "../lib/userFunctions";
import { FEATURE_TRACKING_ACTIONS, trackFeatureUsage } from "./tracking";
import { getPersonalLinkIsIgnoreInternalConflicts, getUpcomingTrips } from "../lib/personalLinkFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getUserPrimaryCalendar } from "../lib/calendarFunctions";
import { getCalendarUserCalendarID } from "../services/calendarAccessors";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { isActionModeCreateAvailability } from "../services/appFunctions";

class PersonalLinkHotKeyContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickCopySlot = this.onClickCopySlot.bind(this);
    this.copyContent = this.copyContent.bind(this);
    this.failedToCopy = this.failedToCopy.bind(this);
    this.copyOnlyLink = this.copyOnlyLink.bind(this);
    this.fetchPersonalLinks = this.fetchPersonalLinks.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this._callFetchTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.fetchPersonalLinks();
    }, SECOND_IN_MS * 10);

    AvailabilityBroadcast.subscribe(
      "COPY_GLOBAL_PERSONAL_LINK_SLOTS",
      this.onClickCopySlot
    );
    AvailabilityBroadcast.subscribe(
      "COPY_GLOBAL_PERSONAL_LINK_ONLY",
      this.copyOnlyLink
    );
    AvailabilityBroadcast.subscribe("FETCH_PERSONAL_LINKS_FOR_CURRENT_USER", this.fetchPersonalLinks);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._callFetchTimer);
    this._callFetchTimer = null;

    AvailabilityBroadcast.unsubscribe("COPY_GLOBAL_PERSONAL_LINK_SLOTS");
    AvailabilityBroadcast.unsubscribe("COPY_GLOBAL_PERSONAL_LINK_ONLY");
    AvailabilityBroadcast.unsubscribe("FETCH_PERSONAL_LINKS_FOR_CURRENT_USER");
  }

  render() {
    return null;
  }

  fetchPersonalLinks() {
    const {
      currentUser,
      isSwitchingAccount
    } = this.props;
    if (!currentUser?.email || isSwitchingAccount) {
      return;
    }

    const path = "personal_links";
    const url = constructRequestURL(path, isVersionV2());

    return Fetcher.get(url, {}, true, currentUser.email)
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        if (response.personal_links) {
          this.savePersonalLinks(response.personal_links);
        } else if (response.personal_link) {
          this.savePersonalLinks(response.personal_link);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  savePersonalLinks(personalLinks) {
    const {
      currentUser
    } = this.props;
    if (isEmptyObjectOrFalsey(currentUser)) {
      return;
    }
    const sortedLinks = sortPersonalLinks(personalLinks);
    localData(
      "set",
      `${PERSONAL_LINK_LOCAL_DATA}_${currentUser.email}`,
      JSON.stringify(sortedLinks)
    );

    this.props.setPersonalLinks(sortedLinks);

    if (isEmptyArray(sortedLinks)) {
      this.createDefaultPersonalLink();
    }
  }

  createDefaultPersonalLink() {
    const path = "personal_links";
    const url = constructRequestURL(path, isVersionV2());
    const linkData = this.constructDefaultLinkData();

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted || !response || !response.personal_link) {
          return;
        }

        let personalLinkArray = [response.personal_link];

        localData(
          "set",
          `${PERSONAL_LINK_LOCAL_DATA}_${this.props.currentUser.email}`,
          JSON.stringify(personalLinkArray)
        );
        this.props.setPersonalLinks(personalLinkArray);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  constructDefaultLinkData() {
    const { masterAccount } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const { currentUser } = this.props;
    let defaultStartEndTime = [
      { start: { hour: 9 }, end: { hour: 12, minute: 0 } },
      { start: { hour: 13, minute: 0 }, end: { hour: 17, minute: 0 } },
    ];
    const defaultAvailableTimes = {
      Monday: defaultStartEndTime,
      Tuesday: defaultStartEndTime,
      Wednesday: defaultStartEndTime,
      Thursday: defaultStartEndTime,
      Friday: defaultStartEndTime,
    };

    const primaryCalendar = getUserPrimaryCalendar({
      allCalendars,
      email: getUserEmail(currentUser)
    });

    const blocked_calendar_ids = 
      isEmptyObjectOrFalsey(primaryCalendar) ? 
        null : 
        [getCalendarUserCalendarID(primaryCalendar)];


    return {
      personal_link: {
        slots: defaultAvailableTimes,
        name: "30 Minute Meeting",
        time_zone: guessTimeZone(),
        buffer_before: 0,
        buffer_after: 0,
        days_forward: 14,
        title: createDefaultPersonalLinkEventSummary({
          user: currentUser,
          masterAccount,
        }),
        conferencing: determineConferencingFromCurrentUser(
          currentUser,
          allCalendars
        ).value,
        description: "",
        attendees: [],
        location: "",
        duration: 30,
        blocked_calendar_ids: blocked_calendar_ids
      },
    };
  }

  determineHotKeyIndex(index) {
    let updatedIndex = index + 1;
    if (updatedIndex === 10) {
      return 0;
    }

    return updatedIndex;
  }

  determinePersonalLinkFromIndex(inputIndex) {
    if (!this.props.currentUser.email) {
      return null;
    }

    if (!this.props.personalLinks || this.props.personalLinks.length === 0) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `You have not set up any personal links yet.`
      );
      return null;
    }

    const index = getPersonalLinkHotKeyIndex(inputIndex);
    let personalLink = this.props.personalLinks[index];

    if (!personalLink) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "A personal link does not exist for that hot key."
      );
      return null;
    }

    return personalLink;
  }

  getUpcomingTrips() {
    const { masterAccount } = this.props.masterAccount
    const upcomingTrips = getUpcomingTrips({
      user: this.props.currentUser,
      masterAccount: masterAccount,
    });
    return upcomingTrips;
  }

  copyOnlyLink(inputIndex) {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      return;
    }
    let personalLink = this.determinePersonalLinkFromIndex(inputIndex);
    if (!personalLink) {
      return;
    }
    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.COPY_PERSONAL_LINK}::HotKeyContainer::personal_link_only`,
      userToken: getUserToken(this.props.currentUser),
    });

    let content = this.determineLinkURL(personalLink);
    navigator.clipboard.writeText(content).then(
      () => {
        /* clipboard successfully set */
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Copied link for "${personalLink.name}" to clipboard`
        );
      },
      () => this.failedToCopy(personalLink)
    );
  }

  async onClickCopySlot(inputIndex) {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      return;
    }
    const personalLink = this.determinePersonalLinkFromIndex(inputIndex);
    if (!personalLink) {
      return;
    }

    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.COPY_PERSONAL_LINK}::HotKeyContainer::slots`,
      userToken: getUserToken(this.props.currentUser),
    });

    const bookableSlots = generateBookableSlotsFromObj({
      slots: personalLink.slots,
      timeZone: personalLink.time_zone,
      daysForward: personalLink.days_forward,
      shouldFormatIntoUTC: false,
      bufferBefore: personalLink.buffer_before,
      bufferAfter: personalLink.buffer_after,
      upcomingTrips: this.getUpcomingTrips()
    });

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `No available slots to copy for "${personalLink.name}".`
      );
      return;
    }

    const { currentUser } = this.props;
    const {
      masterAccount
    } = this.props.masterAccount;

    try {
      const response = await fetchFreeBusySlots({
        currentUser,
        conferencing: personalLink.conferencing,
        bookableSlots,
        blockedCalendars: getBlockedCalendarsID(personalLink.blocked_calendars),
        isIgnoreInternalConflicts: getPersonalLinkIsIgnoreInternalConflicts({
          personalLink,
          masterAccount,
          user: currentUser,
        }),
      });
      if (!this._isMounted) {
        return;
      }

      if (isEmptyObjectOrFalsey(response)) {
        this.failedToCopy(personalLink);
        return;
      }

      this.parseFetchedBusyFreeSlots(response, personalLink);
    } catch (error) {
      handleError(error);

      if (!this._isMounted) {
        return;
      }

      this.failedToCopy(personalLink);
    }
  }

  parseFetchedBusyFreeSlots(inputResponse, personalLink) {
    // get all free_busy slots
    const allBusySlots = getAllBusySlots({
      response: inputResponse,
      roundUpInterval: shouldRoundToNearest15(personalLink.duration) ? 15 : 30,
      bufferBefore: personalLink.buffer_before,
      bufferAfter: personalLink.buffer_after,
      bufferFromNow: personalLink.buffer_from_now,
    });

    const allFreeSlots = this.getFreeTimes(allBusySlots, personalLink);

    this.createFreeTimesContent(allFreeSlots, personalLink);
  }

  getFreeTimes(busySlots, personalLink) {
    return generateFreeSlotsFromBusySlots({
      busySlots,
      slots: personalLink.slots,
      daysForward: personalLink.days_forward,
      durationMinutes: personalLink.duration,
      timeZone: personalLink.time_zone,
      upcomingTrips: this.getUpcomingTrips()
    });
  }

  createFreeTimesContent(sortedEvents, personalLink) {
    let filteredEvents = sortedEvents.slice(0, 4);

    let formattedForTimeZone = filteredEvents.map((e) => {
      return {
        start_time: convertToTimeZone(e.start_time, {
          timeZone: this.props.currentTimeZone,
        }),
        end_time: convertToTimeZone(e.end_time, {
          timeZone: this.props.currentTimeZone,
        }),
      };
    });

    let jsDateFreeSlots = formattedForTimeZone.map((s) => {
      return {
        isAvailability: true,
        eventStart: s.start_time,
        eventEnd: s.end_time,
      };
    });

    if (jsDateFreeSlots.length === 0) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `No available slots to copy for "${personalLink.name}".`
      );
      return;
    }

    let content = createAvailabilityTextFromEvent({
      eventList: jsDateFreeSlots,
      currentTimeZone: this.props.currentTimeZone,
      format24HourTime: this.props.format24HourTime,
      capDuration: true,
      duration: personalLink.duration,
    });

    content = this.addPersonalLinkURLToString(content, personalLink);

    this.copyContent(content, personalLink);
  }

  copyContent(content, personalLink) {
    navigator.clipboard.writeText(content).then(
      () => {
        if (!this._isMounted) {
          return;
        }
        /* clipboard successfully set */
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Copied slots for "${personalLink.name}" to clipboard`
        );
      },
      () => this.failedToCopy(personalLink)
    );
  }

  failedToCopy(personalLink) {
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `Failed to copy slots for "${personalLink.name}"`
    );
  }

  addPersonalLinkURLToString(str, personalLink) {
    return (
      str +
      "\nIf it's easier, you can book here: " +
      this.determineLinkURL(personalLink)
    );
  }

  determineLinkURL(personalLink) {
    if (!personalLink) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `Failed to copy."`
      );
    }

    const { token, slug } = personalLink;
    let link = determineBookingLink();
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;

    const { userName } = getUserName({ masterAccount, user: currentUser });
    const method = !!userName && !!slug ? `${userName}/${slug}` : token;

    return `${link}/p/${method}`;
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    personalLinks,
    currentTimeZone,
    format24HourTime,
    actionMode,
  } = state;

  return {
    currentUser,
    personalLinks,
    currentTimeZone,
    format24HourTime,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPersonalLinks: (event) =>
      dispatch({ data: event, type: "SET_PERSONAL_LINKS" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(PersonalLinkHotKeyContainer));
