import React, { Component } from "react";
import { trackEvent } from "../components/tracking";
import { connect } from "react-redux";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import { Edit, Trash2, X } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import { TEXT_TEMPLATE } from "../services/googleCalendarService";
import _ from "underscore";
import { getUserToken } from "../lib/userFunctions";
import { getTemplateId } from "../services/templateFunctions";

class TextTemplateView extends Component {
  constructor(props) {
    super(props);

    this.deleteTemplate = this.deleteTemplate.bind(this);
  }

  render() {
    return (
      <div>
        {this.renderTopBar()}
        {this.renderTemplateTitle()}
        {this.renderContent()}
      </div>
    );
  }

  renderTopBar() {
    return (
      <div className="expand-event-top-bar">
        <div className="text-template-view-icons">
          <Edit
            size="16"
            onClick={this.editTextTemplate.bind(this)}
            className="clickable-icon"
          />
        </div>

        <div
          className="text-template-view-icons margin-bottom-five"
          style={{ marginRight: 12 }}
        >
          <Trash2
            size="16"
            className="clickable-icon"
            onClick={this.deleteTemplate.bind(this)}
          />
        </div>

        <div className="text-template-view-icons">
          <X
            onClick={this.onClickExitTemplate.bind(this)}
            size={20}
            className="clickable-icon"
          />
        </div>
      </div>
    );
  }

  renderTemplateTitle() {
    return (
      <div className="text-template-wrapper">
        <div className="text-template-title-wrapper" style={{ marginLeft: 20 }}>
          <div className="text-template-text">
            {this.props.template.description || "No title"}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      this.props.template.description &&
      this.props.template.description.length > 0 && (
        <div
          className="select-availability-panel-content-wrapper"
          style={{ marginLeft: 20, width: 320, marginTop: 20 }}
        >
          {this.props.template.text.split("\n").map((i, key) => {
            if (i.length === 0) {
              return (
                <div key={key} className="empty-line">
                  {" "}
                </div>
              );
            } else {
              return <div key={key}>{i}</div>;
            }
          })}
        </div>
      )
    );
  }

  //================
  // EVENT HANDLERS
  //================

  deleteTemplate() {
    trackEvent({
      category: "home",
      action: "deleted_template",
      label: "text_template",
      userToken: getUserToken(this.props.currentUser),
    });

    const templateId = getTemplateId(this.props.template);
    const path = `templates/${templateId}`;
    const url = constructRequestURLV2(path);

    Broadcast.publish("DELETE_TEMPLATE", {
      url,
      template: this.props.template,
      templateId,
    });
  }

  onClickExitTemplate() {
    Broadcast.publish("TOGGLE_SHOW_TEMPLATE");
  }

  //=================
  // PRIVATE METHODS
  //=================

  constructTemplateData() {
    return {
      event: {
        text: this.state.description,
        description: this.state.descriptionName,
        google_calendar_event: null,
      },
    };
  }

  editTextTemplate() {
    Broadcast.publish("UPDATE_TEMPLATE", TEXT_TEMPLATE, this.props.template);
  }
}

function mapStateToProps(state) {
  let { currentUser } = state;

  return { currentUser };
}

function mapDispatchToProps(dispatch) {
  return {
    removeTemplate: (data) => dispatch({ data: data, type: "REMOVE_TEMPLATE" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextTemplateView);
