import React, { useState } from "react";
import { ThumbsDown, ThumbsUp, X } from "react-feather";
import broadcast from "../../broadcasts/broadcast";
import { RIGHT_PANEL_CONTENT_WIDTH } from "../../lib/vimcalVariables";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  calculateMarginTopClassname,
  createAvailabilityTextFromEvent,
  createLabelAndValueForReactSelect,
  isHTMLText,
  isKeyCodeEscape,
  parseIntWithSafeGuard,
} from "../../services/commonUsefulFunctions";
import { getReactSelectBaseStyle } from "../select/styles";
import DropdownIndicator from "../select/dropDownIndicator";
import ColoredLine from "../line";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import StyleConstants, {
  DEFAULT_FONT_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import { splitSlotIntoDuration } from "../../lib/availabilityFunctions";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { removeDuplicateEventsBasedOnTime } from "../../lib/eventFunctions";
import RichTextContainer from "../richTextContainer";
import SelectTimeZoneInput from "../selectTimeZoneInput";
import { protectMidnightCarryOver } from "../../lib/rbcFunctions";
import { determineRBCEventEndWithEventStart } from "../../services/commonUsefulFunctions";
import { getTimeInAnchorTimeZone } from "../../services/commonUsefulFunctions";
import SettingToggleOption from "../settings/common/settingToggleOption";
import { getDefaultFontColor } from "../../lib/styleFunctions";
import { blurCalendar } from "../../services/appFunctions";
import CreatableSelect from "../../../components/creatableSelect";
import { LAYOUT_BROADCAST_VALUES } from "../../lib/broadcastValues";

const SHOW_FORMATTED = "Formatted";
const SHOW_ORIGINAL = "Original";

const DURATION_OPTIONS = createLabelAndValueForReactSelect([
  15, 30, 45, 60, 90, 120,
]);

const SELECTED_THUMBS_UP = "SELECTED_THUMBS_UP";
const SELECTED_THUMBS_DOWN = "SELECTED_THUMBS_DOWN";

export default function ReverseSlotsPanel() {
  const reverseSlotsText = useTemporaryStateStore(
    (state) => state.reverseSlotsText
  );
  const [formatOption, setFormatOption] = useState(SHOW_FORMATTED);
  const originalReverseSlotsImage = useTemporaryStateStore(
    (state) => state.originalReverseSlotsImage
  );
  const setReversSlotsEventsAndText = useTemporaryStateStore(
    (state) => state.setReversSlotsEventsAndText
  );
  const originalReverseSlotsInputText = useTemporaryStateStore(
    (state) => state.originalReverseSlotsInputText
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const reverseSlotDuration = useTemporaryStateStore(
    (state) => state.reverseSlotDuration
  );
  const [duration, setDuration] = useState(reverseSlotDuration);
  const completionID = useTemporaryStateStore((state) => state.completionID);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const reversedSlotsEvents = useTemporaryStateStore(
    (state) => state.reversedSlotsEvents
  );
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const [senderTimeZone, setSenderTimeZone] = useState(
    useTemporaryStateStore((state) => state.senderTimeZone)
  );
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const shouldShowTopBar = useSelector((state) => state.shouldShowTopBar);
  const toggleIsHideTimesWithBusyEvents = useTemporaryStateStore((state) => state.toggleIsHideTimesWithBusyEvents);
  const isHideTimesWithBusyEvents = useTemporaryStateStore((state) => state.isHideTimesWithBusyEvents);

  return (
    <div className={classNames("w-full flex flex-col items-center", calculateMarginTopClassname(shouldShowTopBar))}>
      {renderHeader()}
      {renderSelectOriginalOrFormatted({
        formatOption,
        setFormatOption,
        isDarkMode,
      })}
      {renderPreview({
        displayText: reverseSlotsText,
        originalReverseSlotsImage,
        originalReverseSlotsInputText,
        formatOption,
      })}
      {renderFeedback({
        completionID,
        selectedFeedback,
        setSelectedFeedback,
        isDarkMode,
      })}
      {renderDuration({
        isDarkMode,
        duration,
        setDuration,
        reversedSlotsEvents,
      })}
      {renderTimeZoneSelect({
        senderTimeZone,
        setSenderTimeZone,
        currentTimeZone,
        reversedSlotsEvents,
        setReversSlotsEventsAndText,
        format24HourTime,
      })}
      {renderHideTimesWithBusyEventsToggle({
        isChecked: isHideTimesWithBusyEvents,
        onToggle: () => {
          toggleIsHideTimesWithBusyEvents();
          blurCalendar();
        }
      })}
      <ColoredLine inputClassName="mt-6 mb-8" width={RIGHT_PANEL_CONTENT_WIDTH} />

      <RenderNoneWork />
    </div>
  );
}

function renderHeader() {
  return (
    <div className="select-availability-panel-wrapper w-full flex items-center">
      <div className="create-availability-select-availability-title">
        Select the best time
      </div>

      <div className="close-button-wrapper" onClick={closePanel}>
        <X size={20} className="hoverable-secondary-text-color" />
      </div>
    </div>
  );
}

function renderTimeZoneSelect({
  senderTimeZone,
  setSenderTimeZone,
  currentTimeZone,
  reversedSlotsEvents,
  setReversSlotsEventsAndText,
  format24HourTime,
}) {
  const onChangeTimeZone = (updatedTimeZone) => {
    setSenderTimeZone(updatedTimeZone);
    let updatedEventsList = [];
    reversedSlotsEvents.forEach((event) => {
      const { eventStart, eventEnd } = event;
      const updatedStartTime = getTimeInAnchorTimeZone(
        eventStart,
        updatedTimeZone,
        senderTimeZone
      );
      const updatedEndTime = getTimeInAnchorTimeZone(
        eventEnd,
        updatedTimeZone,
        senderTimeZone
      );

      updatedEventsList = updatedEventsList.concat({
        ...event,
        ...{
          eventStart: updatedStartTime,
          eventEnd: updatedEndTime,
          rbcEventEnd: protectMidnightCarryOver(
            determineRBCEventEndWithEventStart(updatedStartTime, updatedEndTime)
          ),
        },
      });
      const updatedText = createAvailabilityTextFromEvent({
        eventList: updatedEventsList,
        currentTimeZone,
        format24HourTime,
        skipPreSlotText: true,
      });
      setReversSlotsEventsAndText({
        reverseSlotsText: updatedText,
        reversedSlotsEvents: updatedEventsList,
      });
      mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", updatedEventsList);
    });
  };

  return (
    <div className="flex items-center justify-between px-8 mt-2 w-full">
      <div className="secondary-text-color default-font-size">
        Time zone
      </div>

      <SelectTimeZoneInput
        inputClassName="ftf-panel-select-time-zone-width"
        onChange={onChangeTimeZone}
        timeZone={senderTimeZone}
        controllerBackgroundColor={"transparent !important"}
      />
    </div>
  );
}

function renderOriginalImage({
  originalReverseSlotsImage,
  originalReverseSlotsInputText,
}) {
  if (!originalReverseSlotsImage) {
    return null;
  }

  return (
    <img
      src={originalReverseSlotsImage}
      alt={""}
      className="w-auto h-auto object-fill overflow-hidden"
      style={{ maxWidth: RIGHT_PANEL_CONTENT_WIDTH, maxHeight: 280 }}
    />
  );
}

function renderPreview({
  displayText,
  originalReverseSlotsImage,
  originalReverseSlotsInputText,
  formatOption,
}) {
  if (formatOption !== SHOW_FORMATTED && !originalReverseSlotsImage) {
    // render preview text, since it could be rich text -> need to render it as rich text
    if (!isHTMLText(originalReverseSlotsInputText)) {
      return (
        <div
          className={classNames(
            "select-availability-panel-content-wrapper whitespace-pre-wrap",
            "overflow-hidden"
          )}
          style={{ width: RIGHT_PANEL_CONTENT_WIDTH }}
        >
          {originalReverseSlotsInputText}
        </div>
      );
    }
    return (
      <div
        style={{ width: RIGHT_PANEL_CONTENT_WIDTH }}
        className="flex items-center justify-center"
      >
        <RichTextContainer
          containerClassName="ai-scheduler-modal-rich-text-editor mb-2"
          val={originalReverseSlotsInputText}
          autoFocus={true}
          placeholder={"Paste any text here for Vimcal to schedule"}
          readOnly={true}
          modules={{ toolbar: false }}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "select-availability-panel-content-wrapper whitespace-pre-wrap overflow-y-auto",
        originalReverseSlotsImage && formatOption === SHOW_ORIGINAL
          ? "overflow-hidden"
          : ""
      )}
      style={{ width: RIGHT_PANEL_CONTENT_WIDTH }}
    >
      {formatOption === SHOW_FORMATTED
        ? displayText
        : renderOriginalImage({
            originalReverseSlotsImage,
            originalReverseSlotsInputText,
          })}
    </div>
  );
}

function onKeyDownSelect(data) {
  if (isKeyCodeEscape(data?.keyCode)) {
    blurCalendar();
  }
}

function renderDuration({
  isDarkMode,
  duration,
  setDuration,
  reversedSlotsEvents,
}) {
  const onChange = (option) => {
    const minutes = parseIntWithSafeGuard(option.value);
    if (!minutes) {
      return;
    }

    setDuration(minutes);
    let splittedEvents = [];
    reversedSlotsEvents.forEach((e) => {
      const newSlots = splitSlotIntoDuration({
        breakDuration: minutes,
        start: e.eventStart,
        end: e.eventEnd,
        currentSlots: splittedEvents,
        hideCancel: true,
        isTemporaryAIEvent: true,
      });

      splittedEvents = splittedEvents.concat(newSlots);
    });
    splittedEvents = removeDuplicateEventsBasedOnTime(splittedEvents);

    mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", splittedEvents);
  };

  return (
    <div className="flex items-center justify-between px-8 mt-6 w-full">
      <div className="secondary-text-color default-font-size">Duration</div>

      <div className="flex items-center">
        <CreatableSelect
          isSearchable={true}
          // ref={this._selectBufferBefore}
          className={classNames(
            "select-duration-time",
            isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          classNamePrefix="dark-mode"
          value={{
            value: duration,
            label: duration,
          }}
          options={DURATION_OPTIONS}
          onChange={onChange}
          noOptionsMessage={() => "Set"}
          openMenuOnFocus={true}
          // onKeyDown={this.onKeyDown}
          formatCreateLabel={(userInput) => userInput}
          tabSelectsValue={true}
          tabIndex={3}
          styles={getReactSelectBaseStyle({ isDarkMode, showBorder: true })}
          components={{ DropdownIndicator }}
          onKeyDown={onKeyDownSelect}
        />
        <div className="secondary-text-color default-font-size ml-2">
          minutes
        </div>
      </div>
    </div>
  );
}

function renderFeedback({
  completionID,
  selectedFeedback,
  setSelectedFeedback,
  isDarkMode,
}) {
  const getFeedbackButtonFill = (isSelected) => {
    if (isSelected) {
      return getDefaultFontColor(isDarkMode);
    }

    return undefined;
  };
  return (
    <div className="flex items-center justify-between w-full px-8 mt-3">
      <div className="secondary-text-color default-font-size">
        How did we do?
      </div>
      <div className="flex items-center">
        <ThumbsUp
          size={14}
          className="hoverable-secondary-text-color mr-3 fill-transparent"
          onClick={() => onClickThumbsUp({ setSelectedFeedback, completionID })}
          fill={getFeedbackButtonFill(selectedFeedback === SELECTED_THUMBS_UP)}
        />
        <ThumbsDown
          size={14}
          className="hoverable-secondary-text-color fill-transparent"
          onClick={() => {
            closePanel();
            onClickThumbsDown({ completionID });
          }}
          fill={getFeedbackButtonFill(
            selectedFeedback === SELECTED_THUMBS_DOWN
          )}
        />
      </div>
    </div>
  );
}

function onClickThumbsUp({ completionID, setSelectedFeedback }) {
  setSelectedFeedback(SELECTED_THUMBS_UP);
  broadcast.publish(
    SET_DISAPPEARING_NOTIFICATION_MESSAGE,
    "Thank you for your feedback!"
  );
  backendBroadcasts.publish("SEND_LLM_FEEDBACK", { completionID, quality: 1 });
}

function onClickThumbsDown({ completionID }) {
  backendBroadcasts.publish("SEND_LLM_FEEDBACK", { completionID, quality: 0 });
  broadcast.publish(
    SET_DISAPPEARING_NOTIFICATION_MESSAGE,
    "Thank you for your feedback!"
  );
}

function renderHideTimesWithBusyEventsToggle({
  isChecked,
  onToggle
}) {
  return (
    <div className="default-font-size flex justify-between items-center px-8 w-full mt-3 height-34px">
      <div className="secondary-text-color">Hide times with busy events</div>
      <SettingToggleOption 
        isChecked={isChecked}
        onToggle={onToggle}
        hideContainerMarginBottom={true}
      />
    </div>
  )
}

function RenderNoneWork() {
  return (
    <div className="flex items-center default-font-size mb-2">
      <div className="secondary-text-color mr-2">
        None of these times work?{" "}
      </div>
      <div
        className="underline cursor-pointer"
        onClick={() => {
          layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_SLOTS);
          broadcast.publish("REMOVE_TEMPORARY_EVENTS");
          closePanel();
        }}
      >
        Send Slots back
      </div>
    </div>
  );
}

function closePanel() {
  layoutBroadcast.publish("RESET_REVERSED_SLOTS_TEXT", true);
  broadcast.publish("REMOVE_TEMPORARY_EVENTS");
}

function renderSelectOriginalOrFormatted({
  formatOption,
  setFormatOption,
  isDarkMode,
}) {
  const getLeftPositionForHighLight = () => {
    if (formatOption === SHOW_FORMATTED) {
      return "6px";
    }

    return "112px";
  };

  return (
    <div
      className={classNames(
        "flex items-center select-availability-type-selector px-1.5 relative",
        "duration-300",
        "mb-4"
      )}
    >
      <div
        className={classNames("absolute", "duration-200")}
        style={{
          width: "106px",
          height: "25px",
          left: getLeftPositionForHighLight(),
          backgroundColor: isDarkMode ? "#444754" : "white",
          boxShadow:
            "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px",
          borderRadius: "6px",
        }}
      ></div>
      {[SHOW_FORMATTED, SHOW_ORIGINAL].map((option, index) => {
        return (
          <div
            className="flex py-1.5 z-10"
            key={`availability-option-${index}`}
          >
            <button
              key={`eventResponse${index}`}
              style={determineResponseButtonStyle({
                isDarkMode,
                option,
                selectedOption: formatOption,
              })}
              onClick={() => setFormatOption(option)}
              className={classNames("hoverable-container-text duration-200")}
            >
              {option}
            </button>
          </div>
        );
      })}
    </div>
  );
}

function determineResponseButtonStyle({ isDarkMode, option, selectedOption }) {
  const isOptionSelected = option === selectedOption;

  const getTextColor = () => {
    return isDarkMode ? StyleConstants.darkModeTextColor : DEFAULT_FONT_COLOR;
  };
  return {
    fontWeight: "400",
    backgroundColor: "transparent",
    // backgroundColor: isOptionSelected ? "red" : "transparent",
    fontSize: 12,
    color: isOptionSelected ? getTextColor() : null,
    borderRadius: 6,
    width: "106px",
  };
}
