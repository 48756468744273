import classNames from "classnames";
import React from "react";
import DefaultSwitch from "../../defaultSwitch";

interface SettingToggleOptionProps {
  label: string
  isChecked: boolean
  onToggle: () => void
  hideContainerMarginBottom?: boolean
}

export default function SettingToggleOption({
  label,
  isChecked,
  onToggle,
  hideContainerMarginBottom = false,
}: SettingToggleOptionProps) {
  return (
    <div
      className={classNames(
        "default-font-size cursor-pointer select-none",
        "transition duration-200",
        "hoverable-secondary-text-color",
        "flex items-center justify-between",
        hideContainerMarginBottom ? "" : "mb-3",
      )}
    >
      <div onClick={onToggle}>{label}</div>

      <DefaultSwitch isChecked={isChecked} onChange={onToggle} />
    </div>
  );
}
