import React from "react";
import { ArrowRight } from "react-feather";
import OnboardingSaveButton from "./onboardingSaveButton";
import OnboardingSkipButton from "./onboardingSkipButton";

interface SaveAndSkipButtonsProps {
  hideDefaultIcon?: boolean
  isDisabled?: boolean
  onClickNext?: React.MouseEventHandler<HTMLDivElement>
  onClickSave: React.MouseEventHandler<HTMLButtonElement>
  shouldHideSkip?: boolean
}

export default function SaveAndSkipButtons({ hideDefaultIcon, isDisabled, onClickNext, onClickSave, shouldHideSkip }: SaveAndSkipButtonsProps) {
  return (
    <div className="flex justify-center items-center mt-10 gap-10">
      {(shouldHideSkip || !onClickNext) ? null : <OnboardingSkipButton onClickNext={onClickNext} />}

      <div>
        <OnboardingSaveButton
          buttonText={<NextButtonLabel shouldRenderArrow={!shouldHideSkip} />}
          hideDefaultIcon={hideDefaultIcon}
          isDisabled={isDisabled}
          onClick={onClickSave}
          marginTopOverride={0}
          width={160}
        />
      </div>
    </div>
  );
}

function NextButtonLabel({ shouldRenderArrow }: { shouldRenderArrow: boolean }) {
  return (
    <div className="flex items-center">
      <div>Next</div>
      {shouldRenderArrow ? <ArrowRight size={14} /> : null}
    </div>
  );
}
