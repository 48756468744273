import { isEmptyArray } from "../../lib/arrayFunctions";
import {
  MAX_USER_NAME_LENGTH,
  MINIMUM_USER_NAME_LENGTH,
} from "../../lib/bookingFunctions";
import { getAccountHideWeekend, showAccountDeclinedEvents } from "../../lib/settingsFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import { BACKEND_SETTINGS_NAMES } from "../../lib/vimcalVariables";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../services/appFunctions";

export function getWarningText({
  isAvailable,
  isFetching,
  userName,
  hideBadgeAndWarning,
}) {
  if (hideBadgeAndWarning) {
    // user hasn't click on save yet and no need to show warning
    return null;
  } else if (!userName || userName.length < MINIMUM_USER_NAME_LENGTH) {
    return `Username has to be ${MINIMUM_USER_NAME_LENGTH} or more characters`;
  } else if (userName > MAX_USER_NAME_LENGTH) {
    return `Username can not be more than ${MAX_USER_NAME_LENGTH} characters`;
  } else if (isFetching) {
    return null;
  } else if (!isAvailable) {
    return "Username is unavailable";
  }

  return null;
}

export function toggleShowDeclinedEvents({ masterAccount }) {
  const updatedSettings = {
    show_declined_events: !showAccountDeclinedEvents({ masterAccount }),
  };
  updateMasterAccountSettingsForFrontendAndBackend({
    masterAccount,
    updatedSettings,
  });
}

export function toggleWeekends({ masterAccount }) {
  const updatedHideWeekend = !getAccountHideWeekend({ masterAccount });
  const updatedSettings = {
    [BACKEND_SETTINGS_NAMES.HIDE_WEEKENDS]: updatedHideWeekend,
  };
  updateMasterAccountSettingsForFrontendAndBackend({
    masterAccount,
    updatedSettings,
  });
}

export function getSelectedUserIndex(initialSettingsUser, allUsers) {
  if (!initialSettingsUser || isEmptyArray(allUsers)) {
    return 0;
  }
  const matchingIndex = allUsers.findIndex(
    (user) => isSameEmail(getObjectEmail(user?.value), getObjectEmail(initialSettingsUser)),
  );
  return matchingIndex < 0 ? 0 : matchingIndex;
}
