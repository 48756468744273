import React from "react";
import { GOOGLE_ICON, VIMCAL_LOGO_SVG } from "../../services/globalVariables";
import Spinner from "../../components/spinner";
import classNames from "classnames";
import { getDefaultModalBorder } from "../../lib/styleFunctions";

const DefaultLoginButton = ({ isLoading }) => {
  const SHARED_ICON_CLASSNAME = "mr-5 ml-1";
  return (
    <div
      style={{
        height: "43.5px",
        color: "rgba(0, 0, 0, 0.54)",
        border: getDefaultModalBorder(false),
      }}
      className="bg-white hover:bg-gray-200 duration-100 rounded-md flex justify-center items-center text-center default-font-size font-weight-400 cursor-pointer my-2 login-button-dimension mb-2 select-none"
    >
      {isLoading ? (
        <div
          className={classNames(
            "width-18px height-18px relative",
            SHARED_ICON_CLASSNAME,
          )}
        >
          <Spinner
            useSmallSpinner={true}
            className="absolute -top-30px left-2.5"
          />
        </div>
      ) : (
        <img
          alt=""
          width="18px"
          height="18px"
          className={SHARED_ICON_CLASSNAME}
          src={GOOGLE_ICON}
        />
      )}
      Sign-in with Google
    </div>
  );
};

const LoginButton = ({
  desktopState,
  initialDesktopState,
  onClickLoginButton,
  onLoginSuccess,
  setDesktopState,
  isLoading,
}) => {
  if (desktopState.askToReLogin) {
    return (
      <div>
        This link has expired,
        <span
          style={{
            marginLeft: 5,
            marginRight: 5,
            color: "#0000EE",
            cursor: "pointer",
          }}
          onClick={() => setDesktopState(initialDesktopState)}
        >
          click here
        </span>
        <span>to login again.</span>
      </div>
    );
  }

  if (desktopState.isLoggedIn) {
    return (
      <div className="login-button-launch-app rounded-md" onClick={() => onLoginSuccess()}>
        <div className="launch-app-icon">
          <img alt="" width="20px" height="20px" src={VIMCAL_LOGO_SVG} />
        </div>

        <div className="launch-app-text">{"Launch Vimcal"}</div>
      </div>
    );
  }
  /* Default login button */
  return (
    <div
      className="vimcal-updated-login-google-button"
      onClick={onClickLoginButton}
    >
      <DefaultLoginButton isLoading={isLoading} />
    </div>
  );
};

export default LoginButton;
