import _ from "underscore";
import create from "zustand";
import { persist } from "zustand/middleware";
import { isEmptyObjectOrFalsey } from "../typeGuards";

export interface OutlookCategory {
  id: string
  displayName: string
  color: string
}

export interface OutlookCategoriesState {
  outlookCategories: Partial<Record<string, OutlookCategory[]>>
  setOutlookCategories: (...newCategoriesList: Record<string, OutlookCategory[]>[]) => void
  clearOutlookCategories: () => void
}

export const useOutlookCategoriesStore = create<OutlookCategoriesState>(
  persist<OutlookCategoriesState>(
    (set, get) => ({
      outlookCategories: {},
      setOutlookCategories: (...newCategoriesList) => {
        let updatedCategories = get().outlookCategories;
        newCategoriesList.forEach(newCategories => {
          updatedCategories = { ...updatedCategories, ...newCategories };
        });
        // Only update the store if the categories have changed, otherwise this will
        // trigger unnecessary re-renders.
        if (!_.isEqual(updatedCategories, get().outlookCategories)) {
          set({ outlookCategories: updatedCategories });
        }
      },
      clearOutlookCategories: () => {
        if (!isEmptyObjectOrFalsey(get().outlookCategories)) {
          set({ outlookCategories: {} });
        }
      },
    }),
    {
      name: "outlook-categories-storage",
    },
  ),
);
