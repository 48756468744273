import React, { Component } from "react";
import Calendar from "react-monthly-calendar-fork-default-iso-week-number";
import { connect } from "react-redux";
import {
  convertDateIntoEpochUnixWeek,
  getFirstDayOfMonthlyCalendarJSDate,
  getLastDayOfMonthlyCalendarJSDate,
  hasStateOrPropsChanged,
  getTodayDate,
  convertToTimeZone,
  isValidJSDate,
  isMac,
  getWeekStartOnInt,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CornerUpLeft,
} from "react-feather";
import {
  ISO_8601,
} from "../services/globalVariables";
import {
  format,
  startOfMonth,
  isValid,
  addMonths,
  subMonths,
  // getWeeksInMonth,
} from "date-fns";
import { createWindow } from "../lib/stateManagementFunctions";
import {
  useAllCalendars,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import ProfilePictureThroughURL from "./profilePictureThroughURL";
import classNames from "classnames";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import {
  BROADCAST_VALUES,
  LAYOUT_BROADCAST_VALUES,
} from "../lib/broadcastValues";
import { useAppSettings } from "../services/stores/settings";
import { createUUID } from "../services/randomFunctions";
import { shouldDisplayWeekNumber } from "../lib/settingsFunctions";
import { getLastSelectedDate } from "../lib/localData";
import { getUserEmail, getUserProfilePhotoUrl } from "../lib/userFunctions";
import ExpandIconWithAnimation from "./icons/expandIconWithAnimation";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { isSameDayWithErrorCheck, isSameMonthWithErrorCheck } from "../lib/dateFunctions";

const monthlyNavigationArrowSize = "16";

class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.monthlyCalendarRef = React.createRef();

    this.state = {
      hoverDate: null,
      activeStartDate: this.monthlyCalendarRef.current
        ? this.monthlyCalendarRef.current.state.activeStartDate
        : null,
      isMac: isMac(),
      isAccountsExpanded: false,
    };

    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.onMouseEnterDay = this.onMouseEnterDay.bind(this);
    this.onMouseLeaveDay = this.onMouseLeaveDay.bind(this);
    this.onClickDay = this.onClickDay.bind(this);
    this.updateSelectedDay = this.updateSelectedDay.bind(this);
    this.revertBackToCurrentDay = this.revertBackToCurrentDay.bind(this);
    this.openAccounts = this.openAccounts.bind(this);
    this.closeAccounts = this.closeAccounts.bind(this);

    if (props.modalCalendarSelectedDay) {
      // do not want to unmount on modal
      return;
    }
    Broadcast.subscribe("NEXT_MONTH", this.nextMonth);
    Broadcast.subscribe("PREVIOUS_MONTH", this.previousMonth);
    Broadcast.subscribe(
      "UPDATE_MONTHLY_CALENDAR_START_OF_MONTH",
      this.updateSelectedDay
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.OPEN_ACCOUNTS_DROPDOWN,
      this.openAccounts
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.CLOSE_ACCOUNTS_DROPDOWN,
      this.closeAccounts
    );
  }

  componentDidMount() {
    if (
      !this.state.activeStartDate &&
      this.monthlyCalendarRef &&
      this.monthlyCalendarRef.current &&
      this.monthlyCalendarRef.current.state
    ) {
      this.setState({
        activeStartDate: this.monthlyCalendarRef.current.state.activeStartDate,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.modalCalendarSelectedDay) {
      // do not want to unmount on modal
      return;
    }
    Broadcast.unsubscribe("NEXT_MONTH");
    Broadcast.unsubscribe("PREVIOUS_MONTH");
    Broadcast.unsubscribe("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH");
    Broadcast.unsubscribe(BROADCAST_VALUES.OPEN_ACCOUNTS_DROPDOWN);
    Broadcast.unsubscribe(BROADCAST_VALUES.CLOSE_ACCOUNTS_DROPDOWN);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      [],
      false
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.modalCalendarSelectedDay) {
      return;
    } else if (
      isValid(this.props.modalCalendarSelectedDay) &&
      !prevProps.modalCalendarSelectedDay
    ) {
      // Jump to month
      this.setState({
        activeStartDate: startOfMonth(this.props.modalCalendarSelectedDay),
      });
    } else if (
      isValid(this.props.modalCalendarSelectedDay) &&
      !isSameDayWithErrorCheck(
        this.props.modalCalendarSelectedDay,
        prevProps.modalCalendarSelectedDay
      ) &&
      !isSameMonthWithErrorCheck(
        this.props.modalCalendarSelectedDay,
        prevProps.modalCalendarSelectedDay
      )
    ) {
      this.setDate(startOfMonth(this.props.modalCalendarSelectedDay));
    }
  }

  render() {
    const { isHideMonthlyCalendar } = this.props.appSettings;
    const getParentClassname = () => {
      if (this.props.modalCalendarSelectedDay) {
        return "";
      }
      if (!this.shouldShowHideCalendarOptions()) {
        return "";
      }
      return isHideMonthlyCalendar ? "h-9" : "";
    };
    return (
      <div
        className={classNames(
          getParentClassname()
          // getWeeksInMonth(this.state.activeStartDate, {weekStartsOn: getWeekStartOnInt(this.props.weekStart)}) > 5 ? "mb-2" : ""
        )}
      >
        {this.renderHeader()}
        {this.renderActualCalendar()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderActualCalendar() {
    const { masterAccount } = this.props.masterAccount;
    return (
      <Calendar
        id="monthly-calendar"
        className={classNames(
          this.determineMonthlyCalendarClassName(),
          this.shouldHideCalendar() ? "opacity-0" : ""
        )}
        ref={this.monthlyCalendarRef}
        onChange={this.props.onNavigateToDate || this.props.selectDay}
        onClickDay={this.onClickDay}
        value={
          this.props.modalCalendarSelectedDay ||
          this.props.selectedDay ||
          getLastSelectedDate() ||
          new Date()
        }
        activeStartDate={new Date()}
        prevLabel={this.renderPreviousMonthButton()}
        nextLabel={this.renderNextMonthButton()}
        tileContent={({ date }) => this.renderDayTile(date)}
        onActiveDateChange={(e) => {
          this.setState({ activeStartDate: e.activeStartDate });
        }}
        formatShortWeekday={(locale, date) => this.getDayOfWeek(date)}
        formatMonthYear={(locale, date) => format(date, "MMM y")}
        calendarType={this.determineCalendarType()}
        showWeekNumbers={
          shouldDisplayWeekNumber({ masterAccount }) &&
          !this.props.modalCalendarSelectedDay
        }
        showFixedNumberOfWeeks={!this.props.modalCalendarSelectedDay}
      />
    );
  }

  shouldHideCalendar() {
    const { isHideMonthlyCalendar } = this.props.appSettings;
    const { modalCalendarSelectedDay } = this.props;
    return !modalCalendarSelectedDay && isHideMonthlyCalendar;
  }

  renderMonthlyControls() {
    const currentDay = isValidJSDate(this.state.activeStartDate)
      ? this.state.activeStartDate
      : this.props.selectedDay;
    const { modalCalendarSelectedDay } = this.props;

    if (!currentDay) {
      return null;
    }
    return (
      <div
        className={classNames(
          "flex items-center mt-2 mb-2 select-none",
          modalCalendarSelectedDay ? "ml-5" : "margin-left-18px"
        )}
      >
        <div
          className={classNames(
            this.shouldShowHideCalendarOptions()
              ? "cursor-pointer select-none hoverable-secondary-text-color duration-200"
              : "",
            "flex items-center"
          )}
        >
          {this.renderHideMonthyCalendarButton()}
          <div
            className={classNames("font-weight-400 font-size-12 w-16")}
            onClick={() => {
              if (!this.shouldShowHideCalendarOptions()) {
                // do nothing
                return;
              }
              const { isHideMonthlyCalendar, setIsHideMonthlyCalendar } =
                this.props.appSettings;
              setIsHideMonthlyCalendar(!isHideMonthlyCalendar);
            }}
          >
            {format(currentDay, "MMM y")}
          </div>
        </div>

        <div
          className={classNames(
            this.shouldHideCalendar() ? "opacity-0" : "",
            "duration-200",
            "flex items-center"
          )}
        >
          <ChevronLeft
            size={monthlyNavigationArrowSize}
            className="clickable-icon ml-2"
            onClick={this.previousMonth}
          />

          <ChevronRight
            size={monthlyNavigationArrowSize}
            className="clickable-icon ml-2"
            onClick={this.nextMonth}
          />
        </div>
      </div>
    );
  }

  renderHideMonthyCalendarButton() {
    if (!this.shouldShowHideCalendarOptions()) {
      return null;
    }
    const { isHideMonthlyCalendar, setIsHideMonthlyCalendar } =
      this.props.appSettings;
    return (
      <ChevronDown
        size={monthlyNavigationArrowSize}
        className={classNames(
          isHideMonthlyCalendar ? "rotate-negative-90-degrees" : "",
          "mr-2",
          "rotate-transition-200ms"
        )}
        onClick={() => {
          setIsHideMonthlyCalendar(!isHideMonthlyCalendar);
        }}
      />
    );
  }

  renderRevertBack() {
    const currentDay = isValidJSDate(this.state.activeStartDate)
      ? this.state.activeStartDate
      : this.props.selectedDay;

    if (!currentDay) {
      return;
    }

    return !isSameMonthWithErrorCheck(currentDay, new Date()) ? (
      <CornerUpLeft
        size={monthlyNavigationArrowSize - 2}
        className="clickable-icon ml-2"
        onClick={this.revertBackToCurrentDay}
      />
    ) : null;
  }

  renderHeader() {
    const { currentUser } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return (
      <div className={classNames("flex justify-between items-center")}>
        {this.renderMonthlyControls()}

        {this.props.modalCalendarSelectedDay ? null : (
          <div
            className={classNames(
              "cursor-pointer items-center flex",
              this.isUserMaestroUser() ? "mr-3.5" : "",
              "hoverable-secondary-text-color",
            )}
            onClick={() =>
              layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_ACCOUNTS)
            }
          >
            {this.isUserMaestroUser() ? (
              <div className="mr-2 default-font-size">
                Schedule as
              </div>
            ) : null}
            <ProfilePictureThroughURL
              avatarUrl={getUserProfilePhotoUrl({user: currentUser, masterAccount})}
              email={getUserEmail(currentUser)}
              className={classNames(
                "rounded-full",
                this.isUserMaestroUser() ? "mr-2" : "mr-3"
              )}
            />
            {this.isUserMaestroUser() ? (
              <ExpandIconWithAnimation isOpen={this.state.isAccountsExpanded} />
            ) : null}
          </div>
        )}
      </div>
    );
  }

  renderPreviousMonthButton() {
    return (
      <span>
        <ChevronLeft
          size={monthlyNavigationArrowSize}
          className="clickable-icon"
          onClick={this.previousMonth}
        />
      </span>
    );
  }

  renderNextMonthButton() {
    return (
      <span>
        <ChevronRight
          size={monthlyNavigationArrowSize}
          className="clickable-icon"
          onClick={this.nextMonth}
        />
      </span>
    );
  }

  renderDayTile(date) {
    const getClassName = () => {
      if (
        this.props.modalCalendarSelectedDay &&
        isSameDayWithErrorCheck(date, this.props.modalCalendarSelectedDay)
      ) {
        return "monthly-calendar-selected-day";
      }
      if (
        isSameDayWithErrorCheck(
          date,
          convertToTimeZone(new Date(), { timeZone: this.props.currentTimeZone })
        )
      ) {
        return "monthly-calendar-today";
      }
      if (
        !this.props.modalCalendarSelectedDay &&
        isSameDayWithErrorCheck(this.props.selectedDay, date)
      ) {
        return "monthly-calendar-selected-day";
      }
      if (this.state.hoverDate
        && isSameDayWithErrorCheck(date, this.state.hoverDate)
      ) {
        return "monthly-calendar-hover-date";
      }
      if (
        !this.props.modalCalendarSelectedDay &&
        this.props.agendaDay &&
        isSameDayWithErrorCheck(date, this.props.agendaDay)
      ) {
        return "monthly-calendar-hover-date";
      }
      if (isSameMonthWithErrorCheck(date, this.state.activeStartDate || new Date())) {
        return "default-font-color";
      }
      return "secondary-text-color";
    };

    return (
      <div
        className="h-full w-full bg-transparent flex justify-center items-center cursor-pointer"
        onMouseEnter={() => this.onMouseEnterDay(date)}
        onMouseLeave={this.onMouseLeaveDay}
      >
        <div
          className={classNames(
            "inline-flex justify-center items-center font-weight-300 text-center select-none duration-200 font-size-10",
            "monthly-calendar-date-container",
            getClassName(),
          )}
        >
          {format(date, "d")}
        </div>
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onClickDay(e) {
    if (!this.props.modalCalendarSelectedDay) {
      Broadcast.publish("NAVIGATE_DATE", e);
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  shouldShowHideCalendarOptions() {
    return this.props.shouldShowHideCalendarOption;
  }

  determineMonthlyCalendarClassName() {
    const { isHideMonthlyCalendar } = this.props.appSettings;

    let monthlyCalendarClassName = "monthly-calendar";
    const { masterAccount } = this.props.masterAccount;

    if (
      !this.props.modalCalendarSelectedDay &&
      shouldDisplayWeekNumber({ masterAccount })
    ) {
      monthlyCalendarClassName =
        monthlyCalendarClassName + " monthly-calendar-with-week-numbers";
    }

    if (this.shouldShowHideCalendarOptions() && isHideMonthlyCalendar) {
      monthlyCalendarClassName = monthlyCalendarClassName + " invisible";
    } else if (this.shouldShowHideCalendarOptions()) {
      monthlyCalendarClassName = monthlyCalendarClassName + " mt-2";
    }

    return monthlyCalendarClassName;
  }

  getDayOfWeek(date) {
    return format(date, "EEEEE");
  }

  onMouseEnterDay(day) {
    this.setState({
      hoverDate: day,
    });

    if (!this.props.modalCalendarSelectedDay) {
      this.props.setAgendaDay(day);
    }
  }

  onMouseLeaveDay() {
    if (!this.props.modalCalendarSelectedDay) {
      this.props.setAgendaDay(getTodayDate(this.props.currentTimeZone));
    }

    this.setState({
      hoverDate: null,
    });
  }

  setDate(date) {
    this.monthlyCalendarRef.current.setActiveStartDate(date);
  }

  nextMonth() {
    let currentActiveStartDate =
      this.monthlyCalendarRef.current.state.activeStartDate;
    let nextMonth = addMonths(currentActiveStartDate, 1);

    this.updateAgendaEventData(currentActiveStartDate, nextMonth);

    this.setDate(nextMonth);
  }

  previousMonth() {
    let currentActiveStartDate =
      this.monthlyCalendarRef.current.state.activeStartDate;
    let previousMonth = subMonths(currentActiveStartDate, 1);

    this.updateAgendaEventData(currentActiveStartDate, previousMonth);

    this.setDate(previousMonth);
  }

  determineCalendarType() {
    const weekStartInt = getWeekStartOnInt(this.props.weekStart);
    if (weekStartInt === 0) {
      // Sunday
      return "US";
    } else if (weekStartInt === 1) {
      // Monday
      return ISO_8601;
    } else if (weekStartInt === 6) {
      // Saturday
      return "Arabic";
    } else {
      return ISO_8601;
    }
  }

  revertBackToCurrentDay() {
    const currentActiveStartDate =
      this.monthlyCalendarRef.current.state.activeStartDate;
    const today = new Date();

    this.updateAgendaEventData(currentActiveStartDate, today);

    this.setDate(today);
  }

  updateSelectedDay(newSelectedDay) {
    if (
      !newSelectedDay ||
      !this.monthlyCalendarRef ||
      !this.monthlyCalendarRef.current
    ) {
      return;
    }

    let newMonth = startOfMonth(newSelectedDay);

    this.updateAgendaEventData(this.state.activeStartDate, newMonth);

    this.setDate(newMonth);
  }

  updateAgendaEventData(prevActiveStartDate, newActiveStartDate) {
    if (
      isValid(prevActiveStartDate) &&
      isValid(newActiveStartDate) &&
      !isSameDayWithErrorCheck(newActiveStartDate, prevActiveStartDate)
    ) {
      let start = getFirstDayOfMonthlyCalendarJSDate(
        newActiveStartDate,
        this.props.weekStart
      );
      let end = getLastDayOfMonthlyCalendarJSDate(
        newActiveStartDate,
        this.props.weekStart
      );
      let unixStart = convertDateIntoEpochUnixWeek(start);
      let unixEnd = convertDateIntoEpochUnixWeek(end);

      const { selectedDay, weekStart, selectedCalendarView } = this.props;
      let { windowStartDate, windowEndDate } = createWindow({
        windowJSDate: getSelectedDayWithBackup(selectedDay),
        isMonth: true,
        weekStart,
        selectedCalendarView,
      });

      if (unixStart < windowStartDate || unixEnd > windowEndDate) {
        let startEndDates = {
          start: unixStart,
          end: unixEnd,
          jsDateStart: start,
          jsDateEnd: end,
          fetchId: createUUID(),
        };

        Broadcast.publish(
          "UPDATE_EVENT_IN_AGENDA_WITH_NEW_EVENTS_FROM_INDEXDB",
          startEndDates
        );
      }
    }
  }

  openAccounts() {
    if (this.state.isAccountsExpanded) {
      return;
    }
    this.setState({
      isAccountsExpanded: true,
    });
  }

  closeAccounts() {
    if (!this.state.isAccountsExpanded) {
      return;
    }
    this.setState({
      isAccountsExpanded: false,
    });
  }

  isUserMaestroUser() {
    const { masterAccount } = this.props.masterAccount;
    return isUserMaestroUser(masterAccount);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
  };
}

function mapStateToProps(state) {
  let {
    selectedDay,
    isMac,
    currentHoverEvent,
    agendaDay,
    currentPreviewedEvent,
    currentTimeZone,
    currentUser,
    isDarkMode,
    weekStart,
  } = state;

  return {
    selectedDay,
    isMac,
    currentHoverEvent,
    agendaDay,
    currentPreviewedEvent,
    currentTimeZone,
    currentUser,
    isDarkMode,
    weekStart,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const appSettings = useAppSettings();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      appSettings={appSettings}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(MonthlyCalendar));
