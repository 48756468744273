import React, { Component }       from 'react';
import { Edit2 }                  from 'react-feather';


export default class EditableContentContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false
    };
  }

  render() {
    const {
      style,
      children,
      nonHoverOpacity,
      iconStyle
    } = this.props;

    const {
      isHovering
    } = this.state;

    return (
      <span
        onMouseEnter={() => this.setState({isHovering: true})}
        onMouseLeave={() => this.setState({isHovering: false})}
        style={Object.assign({
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        }, style)}
      >
        {children}

        {!this.props.shouldHide && <span style={Object.assign({opacity: isHovering ? 1 : nonHoverOpacity ?? 0, marginLeft: 10}, iconStyle)}>
          <span onClick={this.onClickEdit.bind(this)}>
            <Edit2
              size="14"
              className="clickable-icon"
            />
          </span>
        </span>}
      </span>
    );
  }

  onClickEdit() {
    const {
      onClickEdit
    } = this.props;

    if (onClickEdit) {
      onClickEdit();
    }
  }
}
