import { removeDuplicatesFromArray, safeSortObjects } from "../../../lib/arrayFunctions";
import { isSameEmail } from "../../../lib/stringFunctions";
import { getAvailableDomains, getTeamPlanTeamName } from "../../../lib/teamPlanFunctions";
import { getSelectedUserName, getUserDomain, getUserEmail } from "../../../lib/userFunctions";
import { EXCLUDED_DOMAINS } from "../../../services/globalVariables";
import { isUserLimitedAccess } from "../../../services/maestroFunctions";
import type { AllUserDomainsState } from "../../../services/stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";

export const TEAM_SETTING_LABEL_CLASS_NAME = "default-font-size mb-2 font-medium";

interface FilterAndSortDomainsOptions {
  allLoggedInUsers: User[]
  allUserDomains: AllUserDomainsState["allUserDomains"]
  teamPlan: OptionalOrEmptyObject<TeamPlan>
}

/**
 * If a team plan already exists: return all the domains available from the team plan, sorted by domain name.
 *
 * Otherwise, take all the user's domains, filter out the domains only associated with limited access users,
 * and sort by domain name.
 */
export function filterAndSortDomains({ allLoggedInUsers, allUserDomains, teamPlan }: FilterAndSortDomainsOptions) {
  let filteredDomains: Domain[] = [];
  if (isEmptyObjectOrFalsey(teamPlan)) {
    // Any domain that the user is logged into should be available to select for new team plans.
    // Limited access users are excluded since the user has not signed in with their domain.
    Object.entries(allUserDomains).forEach(([email, domain]) => {
      const user = allLoggedInUsers.find(u => isSameEmail(getUserEmail(u), email));
      if (!domain || !user || isUserLimitedAccess(user)) {
        return;
      }

      filteredDomains.push(domain);
    });
    filteredDomains = removeDuplicatesFromArray(filteredDomains);
  } else {
    // The backend returns the available domains if the team plan already exists.
    filteredDomains = getAvailableDomains(teamPlan);
  }
  return safeSortObjects(filteredDomains, "name");
}

/**
 * When creating a team plan, we should provide an initial team name to reduce friction.
 */
export function getInitialTeamName(teamPlan: TeamPlan | Record<string, never>, currentUser?: User, isCreatingTeam?: boolean) {
  const teamName = getTeamPlanTeamName(teamPlan);
  if (teamName) {
    return teamName;
  }

  if (!isCreatingTeam) {
    // If the team plan already exists, don't show a team name.
    // The admin may think a name is already set if the field is pre-populated.
    return "";
  }

  const fallbackName = "My Workspace";

  if (!currentUser) {
    return fallbackName;
  }

  const domain = getUserDomain({ user: currentUser });
  if (domain && !(EXCLUDED_DOMAINS as Readonly<string[]>).includes(domain)) {
    const domainName = domain.replace("@", "");
    return `${domainName}'s Workspace`;
  }

  const { fullName } = getSelectedUserName({ user: currentUser });
  if (fullName) {
    return `${fullName}'s Workspace`;
  }

  return fallbackName;
}
