import React, { useState } from "react";
import { useAnnualUpgradeInvoicesStore, useStripeSubscriptions } from "../../services/stores/finance";
import {
  getBillingTotal,
  isScheduledToBeCancelled,
  isYearlyPlan,
} from "../../lib/stripeFunctions";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { trackEvent } from "../tracking";
import { getUserToken } from "../../lib/userFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import UpgradeModal from "./upgradeModal";
import CustomButtonV2 from "../buttons/customButtonV2";
import { useIsCurrentUserAdmin, useIsFixedSeating } from "../teamPlans/hooks";

/**
 * The button to upgrade a user's subscription from monthly to annual.
 * Does not render anything if the user cannot upgrade the subscription.
 */
export default function UpgradeBillingPlan() {
  const stripeSubscriptions = useStripeSubscriptions(
    (state) => state.stripeSubscriptions,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const annualUpgradeNonProratedInvoice = useAnnualUpgradeInvoicesStore(state => state.annualUpgradeNonProratedInvoice);
  const isAdmin = useIsCurrentUserAdmin();
  const isFixedSeating = useIsFixedSeating();

  const onClickUpgradeToAnnualPlan = () => {
    setIsUpgradeModalOpen(false);

    if (isWaiting) {
      return;
    }

    setIsWaiting(true);
    backendBroadcasts.publish("UPGRADE_FROM_MONTHLY_TO_ANNUAL");
    trackEvent({
      category: "upgrade to annual plan",
      action: "onClickUpgradeToAnnualPlan_1",
      label: "upgradeBillingPlan.tsx",
      userToken: getUserToken(currentUser),
    });
  };

  if (isEmptyObjectOrFalsey(stripeSubscriptions)) {
    // No subscription to upgrade.
    return null;
  }

  if (isYearlyPlan({ stripeSubscriptions })) {
    // Subscription is already upgraded.
    return null;
  }

  if (isScheduledToBeCancelled({ stripeSubscription: stripeSubscriptions })) {
    // Can't upgrade if the subscription is being cancelled.
    return null;
  }

  if (!isAdmin) {
    // User is not the owner.
    return null;
  }

  if (isFixedSeating) {
    return null;
  }

  if (!annualUpgradeNonProratedInvoice) {
    return null;
  }

  const newMonthlyAmount = annualUpgradeNonProratedInvoice.subtotal / 12;
  const originalMonthlyAmount = getBillingTotal(stripeSubscriptions);
  const percentSavings = Math.round(100 * (1 - (newMonthlyAmount / originalMonthlyAmount)));

  return (
    <>
      <UpgradeModal
        onClickExit={() => setIsUpgradeModalOpen(false)}
        onClickSave={onClickUpgradeToAnnualPlan}
        isOpen={isUpgradeModalOpen}
      />
      <CustomButtonV2
        buttonType={BLUE_BUTTON}
        label={`Save ${percentSavings}% by switching to annual`}
        onClick={() => setIsUpgradeModalOpen(true)}
        shouldRenderSpinner={isWaiting}
      />
    </>
  );
}
