import { addDays, endOfDay, endOfMonth, endOfQuarter, endOfWeek, startOfDay, startOfMonth, startOfQuarter, startOfWeek, subDays, subMonths } from "date-fns";
import { FETCH_CALENDAR_EVENTS_ENDPOINT } from "../../lib/endpoints";
import { getDefaultHeaders } from "../../lib/fetchFunctions";
import { constructRequestURLV2 } from "../../services/api";
import { getMatchingUserProviderIDsFromUserCalendarIDs } from "../../lib/calendarFunctions";
import { isAfterDay } from "../../services/commonUsefulFunctions";
import { fetcherPost } from "../../services/fetcherFunctions";
import { DATE_PICKER_TIME_FRAME } from "./datePicker";
import { getHumanReadableTimeInMinutes } from "../metrics/metricsAccessorFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";

export function getOnePagerTitle(data) {
  return data?.name || "";
}

export function getOnePagerStartDate(data) {
  return data?.startDate;
}

export function getOnePagerEndDate(data) {
  return data?.endDate;
}

export function getOnePagerMinsSaved30Days(data) {
  return data?.minsSaved30Days || 0;
}

export function getOnePagerMinsSaved90Days(data) {
  return data?.minsSaved90Days || 0;
}

export function getOnePagerProposedChanges(data) {
  return data?.proposedChanges || [];
}

export function getOnePagerProposedChangeTitle(proposedChange) {
  return proposedChange?.title;
}

export function getOnePagerProposedChangeEventStart(proposedChange) {
  return proposedChange?.eventStart;
}

export function getOnePagerProposedChangeEventEnd(proposedChange) {
  return proposedChange?.eventEnd;
}

export function getOnePagerProposedChangeAttendeeEmails(proposedChange) {
  return proposedChange?.attendeeEmails || [];
}

export function getOnePagerProposedChangeAttendeeEmailsString(proposedChange) {
  return getOnePagerProposedChangeAttendeeEmails(proposedChange).join(", ");
}

export function getOnePagerProposedChangeOriginalDurationMins(proposedChange) {
  return proposedChange?.originalDurationMins;
}

export function getOnePagerProposedChangeProposedDurationMins(proposedChange) {
  return proposedChange?.proposedDurationMins;
}

export function getOnePagerProposedChangeOriginalDurationHumanReadable(proposedChange) {
  return getHumanReadableTimeInMinutes(getOnePagerProposedChangeOriginalDurationMins(proposedChange));
}

export function getOnePagerProposedChangeProposedDurationHumanReadable(proposedChange) {
  return getHumanReadableTimeInMinutes(getOnePagerProposedChangeProposedDurationMins(proposedChange));
}

export function getOnePagerProposedChangeOriginalFrequency(proposedChange) {
  return proposedChange?.originalFrequency;
}

export function getOnePagerProposedChangeProposedFrequency(proposedChange) {
  return proposedChange?.proposedFrequency;
}

export function getOnePagerProposedChangeMinsSavedOver90Days(proposedChange) {
  return proposedChange?.minsSavedOver90Days;
}

export function getOnePagerProposedChangeMinsSavedOver90DaysHumanReadable(proposedChange) {
  return getHumanReadableTimeInMinutes(getOnePagerProposedChangeMinsSavedOver90Days(proposedChange));
}

export async function fetchEvents({
  allCalendars,
  controller,
  currentTimeZone,
  endDate,
  startDate,
  userCalendarIDs,
  userEmail,
}) {
  const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
    userCalendarIDs,
    allCalendars,
    userEmail,
  });
  const body = {
    timeMin: startOfDay(startDate).toISOString(),
    timeMax: endOfDay(endDate).toISOString(),
    timeZone: currentTimeZone,
    calendarIds: providerIDs,
  };
  const url = constructRequestURLV2(FETCH_CALENDAR_EVENTS_ENDPOINT);
  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  };
  return await fetcherPost({
    controller,
    email: userEmail,
    payloadData,
    url,
  });
}

/**
 * Splits a date range into smaller windows of a specified size.
 *
 * @param {Object} params - The parameters object.
 * @param {Date} params.startDate - The beginning of the date range.
 * @param {Date} params.endDate - The end of the date range.
 * @param {number} params.windowSize - The size of each window in days.
 * @returns {Array<{windowStart: Date, windowEnd: Date}>} An array of window objects.
 */
export function splitIntoFetchWindow({startDate, endDate, windowSize}) {
  // Create an array to store our window objects
  const windows = [];
  const formattedStartDate = startOfDay(startDate);
  const formattedEndDate = endOfDay(endDate);

  // Set the initial start date
  let currentStartDate = startOfDay(formattedStartDate);

  // Continue until we've processed the entire date range (including the end date)
  while (!isAfterDay(currentStartDate, formattedEndDate)) {
    // Calculate the end date for the current window
    let currentEndDate = addDays(currentStartDate, windowSize - 1);

    // If the current window's end date exceeds the overall end date,
    // cap it at the overall end date
    if (isAfterDay(currentEndDate, formattedEndDate)) {
      currentEndDate = formattedEndDate;
    }

    // Add the window to our collection
    windows.push({
      windowStart: currentStartDate,
      windowEnd: currentEndDate,
    });

    // Update the current start date to the day after the current window's end date
    // This ensures windows don't overlap
    currentStartDate = addDays(currentEndDate, 1);
  }

  return windows.reverse(); // prioritize the most recent windows
}

export function getDateRangeForTimeFrameOption(option) {
  const today = new Date();
  const getLastQuarter = () => {
    const today = new Date();
    // Get the current quarter's start date
    const currentQuarterStart = startOfQuarter(today);
    // Subtract 1 day from the current quarter's start to get the end of the previous quarter
    const lastQuarterEnd = subDays(currentQuarterStart, 1);
    // Get the start of the previous quarter
    const lastQuarterStart = startOfQuarter(lastQuarterEnd);

    return {
      startDate: lastQuarterStart,
      endDate: lastQuarterEnd,
    };
  };

  switch (option) {
    case DATE_PICKER_TIME_FRAME.THIS_WEEK:
      return {
        startDate: startOfWeek(today),
        endDate: endOfWeek(today),
      };
    case DATE_PICKER_TIME_FRAME.THIS_MONTH:
      return {
        startDate: startOfMonth(today),
        endDate: endOfMonth(today),
      };
    case DATE_PICKER_TIME_FRAME.THIS_QUARTER:
      return {
        startDate: startOfQuarter(today),
        endDate: endOfQuarter(today),
      };
    case DATE_PICKER_TIME_FRAME.LAST_WEEK:
      return {
        startDate: subDays(today, 7),
        endDate: today,
      };
    case DATE_PICKER_TIME_FRAME.LAST_MONTH:
      return {
        startDate: subMonths(today, 1),
        endDate: today,
      };

    case DATE_PICKER_TIME_FRAME.LAST_QUARTER:
      return getLastQuarter();
    case DATE_PICKER_TIME_FRAME.CUSTOM:
      // last quarter
      return getLastQuarter();
    default:
      // default to last quarter
      return getLastQuarter();
  }
}

export function splitProposedChangesIntoChunks(proposedChanges) {
  if (isEmptyArrayOrFalsey(proposedChanges)) {
    return [];
  }

  const firstTwo = proposedChanges.slice(0, 2);
  const result = proposedChanges.slice(2).reduce((resultArray, item, index) => {
    const perChunk = 3;
    const chunkIndex = Math.floor(index / perChunk);

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return [firstTwo, ...result];
}
