import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LinkIcon from "../../../components/icons/linkIcon";
import CustomButton from "../../../components/customButton";
import { BLUE_BUTTON, SECOND_IN_MS } from "../../../services/globalVariables";
import { useIsMounted } from "../../../services/customHooks/useIsMounted";
import { MAESTRO_NEW_USER_PAGES } from "../../../services/globalMaestroVariables";
import { MAGIC_LINK_PATH } from "../../../services/maestro/maestroRouting";
import { copyContent } from "../../../services/appFunctions";

const MagicLinkCreated = ({
  magicLink,
  setMaestroAuthPage,
  setMagicLink,
}) => {
  const { token, email, first_name, last_name } = magicLink;
  const [copyTimeout, setCopyTimeout] = useState(false);
  const componentIsMounted = useIsMounted();
  const history = useHistory();

  /* Clear magicLink and timeout on unmount */
  useEffect(() => {
    return () => {
      if (copyTimeout) {
        clearTimeout(copyTimeout);
      }

      setMagicLink(null);
    };
  }, []);

  const renderButtons = () => {
    return (
      <div>
        <div className="flex justify-center">
          <CustomButton
            buttonType={BLUE_BUTTON}
            className="maestro-magic-link-button"
            onClick={() => history.push("/home")}
            label="Finish"
          />
        </div>
        <div
          className="default-font-size font-weight-500 mt-4 hoverable-secondary-text-color cursor-pointer"
          onClick={() =>
            setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO)
          }
        >
          Create another Executive Profile
        </div>
      </div>
    );
  };

  const renderExecutiveCard = () => {
    return (
      <div className="magic-link-executive-card mt-8 mb-16">
        <div className="magic-link-executive-card-info">
          <div className="max-w-full overflow-hidden truncate whitespace-nowrap">
            {first_name} {last_name}
          </div>
          <div className="magic-link-executive-card-info-email">{email}</div>
        </div>
        <div
          className="magic-link-executive-card-copy"
          onClick={() => {
            const resetTimeout = setTimeout(() => {
              if (!componentIsMounted?.current) {
                return;
              }

              setCopyTimeout(null);
            }, 3 * SECOND_IN_MS);

            setCopyTimeout(resetTimeout);
            copyContent(`${window.location.origin}/${MAGIC_LINK_PATH}?token=${token}`);
          }}
        >
          {copyTimeout ? (
            <div>Copied!</div>
          ) : (
            <>
              <div className="mr-2" style={{ height: "" }}>
                <LinkIcon />
              </div>
              <div>Copy Link</div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderExecutiveCard()}
      {renderButtons()}
    </>
  );
};

export default MagicLinkCreated;
