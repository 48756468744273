import React, { Component }                   from 'react';
import StyleConstants                         from '../services/globalVariables';
import CustomButtonBox                        from './customButtonBox';
import { Paperclip }                          from 'react-feather';
import { OpenLink }                           from '../services/commonUsefulFunctions';


class EventFileAttachment extends Component {

  render() {
    return (
      <div>
        {this.props.extendAttendanceList ? this.renderExpandedAttachmentButton() : this.renderTruncatedAttachmentButton()}
      </div>
    );
  }


  //================
  // RENDER METHODS
  //================


  renderExpandedAttachmentButton() {
    const {
      attachments,
    } = this.props;
    if (!attachments) {
      return null;
    }
    return attachments.map((attachment, index) => {
      return (
        <div key={`attachement_${attachment.title}_${index}`} ref={index} className={index === 0 ? "" : "margin-top-ten"}>
          <CustomButtonBox onClick={() => {
            if (attachment.isOutlookAttachment) {
              const link = document.createElement("a");
              link.download = attachment.title;
              link.href = attachment.fileUrl;
              link.click();
            } else {
              OpenLink(attachment.fileUrl);
            }
          }}>
            <div className="file-attachment-expand-file">
              <Paperclip className="non-clickable-icon" size="12" />

              <div className="file-attachment-title">
                {attachment.title}
              </div>
            </div>
          </CustomButtonBox>
        </div>
      );
    });
  }

  renderTruncatedAttachmentButton() {
    const {
      attachments,
    } = this.props;
    if (!attachments) {
      return null;
    }
    const attachmentCount = String(attachments.length) + attachments.length > 1 ? " attachments" : " attachment";

    return (
      <div className="file-attachment-truncated">
        <Paperclip color={StyleConstants.darkGray} size="12" />

        <div className="margin-top-ten">
          {attachmentCount}
        </div>
      </div>
    );
  }
}


export default EventFileAttachment;
