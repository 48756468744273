import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { getBillingTotal, getHumanReadableDollarFromCents } from "../../lib/stripeFunctions";
import { getUserToken } from "../../lib/userFunctions";
import { isErrorResponse } from "../../services/api";
import { handleError, sendMessageToSentry } from "../../services/commonUsefulFunctions";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import { useStripeSubscriptions } from "../../services/stores/finance";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import CustomButtonV2 from "../buttons/customButtonV2";
import EventModalPopup from "../eventModalPopup";
import { uncancelSubscription } from "../queries/subscriptions";
import { useIsCurrentUserOwner } from "../teamPlans/hooks";
import { trackEvent } from "../tracking";

interface ResumeSubscriptionModalProps {
  isModalOpen: boolean
  setIsModalOpen: StateSetter<boolean>
}

/**
 * The modal to resume a subscription that is scheduled to be cancelled at a future date.
 * Cannot be used for subscriptions that have already been cancelled.
 * Only the team plan owner should have access to this.
 */
export default function ResumeSubscriptionModal({ isModalOpen, setIsModalOpen }: ResumeSubscriptionModalProps) {
  const [isResuming, setIsResuming] = useState(false);
  const isDarkMode = useSelector(state => state.isDarkMode);
  const currentUser = useSelector(state => state.currentUser);
  const isOwner = useIsCurrentUserOwner();
  const stripeSubscription = useStripeSubscriptions(state => state.stripeSubscriptions);

  useEffect(() => {
    if (isOwner) {
      trackEvent({
        category: "resume_subscription",
        action: "resumeSubscription_0",
        label: "resumeSubscriptionModal.tsx",
        userToken: getUserToken(currentUser),
      });
    } else {
      sendMessageToSentry(
        "Resume subscription modal mounted for non-owner",
        `User token: ${getUserToken(currentUser)}`,
      );
    }
  }, []);

  if (!isOwner || !currentUser) {
    return null;
  }

  const closeModal = () => {
    if (isResuming) {
      // Do not unmount while request is being processed.
      return;
    }

    setIsModalOpen(false);
  };

  const resumeSubscription = async () => {
    setIsResuming(true);
    try {
      const response = await uncancelSubscription(currentUser);
      if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        // Do nothing.
      } else {
        // After the billing info finishes refreshing, it should automatically
        // unmount this modal.
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
      }
    } catch(e) {
      handleError(e);
    } finally {
      setIsResuming(false);
    }
  };

  const billingTotal = getBillingTotal(stripeSubscription);

  return (
    <EventModalPopup
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      width={420}
      title={"Resume your subscription"}
      style={determineDefaultModalStyle(isDarkMode)}
    >
      <div className="default-font-size">
        Your subscription will resume and you will be charged{" "}
        ${getHumanReadableDollarFromCents(billingTotal)}{" "}
        on {format(new Date(stripeSubscription.current_period_end * 1000), "PPP")}.
      </div>
      <div className="mt-6 flex gap-2.5 justify-end">
        <CustomButtonV2
          buttonType={WHITE_BUTTON}
          disabled={isResuming}
          label="Cancel"
          onClick={closeModal}
        />
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          disabled={isResuming}
          label="Resume"
          onClick={resumeSubscription}
        />
      </div>
    </EventModalPopup>
  );
}
