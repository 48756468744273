import type { Stripe } from "stripe-types";
import { forceTwoDecimals, sendMessageToSentry } from "../services/commonUsefulFunctions";
import { DOLLARS_IN_CENTS, EA_ANNUAL_PRICE, EA_MONTHLY_PRICE, NON_EA_ANNUAL_PRICE, NON_EA_MONTHLY_PRICE } from "../services/globalVariables";
import {
  isAdminsDayDiscount,
  isMonthOffPromotion,
  isWinbackPromotion,
  isYCDiscount,
} from "./stateManagementFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { MONTHLY, YEARLY } from "./vimcalVariables";
import _ from "underscore";
import type { TruncatedStripeSubscription } from "../types/stripe/truncatedStripe";
import { immutablySortArray } from "./arrayFunctions";

const STRIPE_YEAR_INTERVAL = "year";
const STRIPE_MONTH_INTERVAL = "month";

/**
 * Stripe sometimes provides the interval in `subscription.plan.interval`, but the `plan` field is deprecated
 * and isn't always included in responses.
 */
export function getPlanInterval({ stripeSubscriptions }: { stripeSubscriptions: Stripe.Subscription | null | Record<string, never> }) {
  return stripeSubscriptions?.items?.data?.[0]?.price?.recurring?.interval;
}

export function isYearlyPlan({ stripeSubscriptions }: { stripeSubscriptions: Stripe.Subscription | null | Record<string, never> }) {
  return getPlanInterval({ stripeSubscriptions }) === STRIPE_YEAR_INTERVAL;
}

export function isMonthlyPlan({ stripeSubscriptions }: { stripeSubscriptions: Stripe.Subscription | null | Record<string, never> }) {
  return getPlanInterval({ stripeSubscriptions }) === STRIPE_MONTH_INTERVAL;
}

export function isScheduledToBeCancelled({ stripeSubscription }: { stripeSubscription: Stripe.Subscription | null | Record<string, never> }) {
  if (isEmptyObjectOrFalsey(stripeSubscription)) {
    return false;
  }

  return Boolean(stripeSubscription.cancel_at_period_end && stripeSubscription.cancel_at);
}

export function getHasFreeMonth({ promotions, referral }: { promotions: Promotion[], referral?: Referral }) {
  return (
    isMonthOffPromotion(promotions) ||
    referral?.credited === false
  );
}

/**
 * Returns the total amount for each billing cycle in cents.
 */
export function getBillingTotal(stripeSubscription: Stripe.Subscription | null | Record<string, never>) {
  const items = stripeSubscription?.items?.data;
  if (isEmptyArrayOrFalsey(items)) {
    return 0;
  }

  let total = 0;
  items.forEach(item => {
    total += (item.price.unit_amount ?? 0) * (item.quantity ?? 0);
  });

  return total;
}

export function shouldShowDiscount({ promotions, referral }: { promotions: Promotion[], referral?: Referral }) {
  return (
    isMonthOffPromotion(promotions) ||
    referral?.credited === false ||
    isYCDiscount(promotions) ||
    isAdminsDayDiscount(promotions) ||
    isWinbackPromotion(promotions)
  );
}

export function getHumanReadableDollarFromCents(amountInCents: number) {
  return forceTwoDecimals(amountInCents / DOLLARS_IN_CENTS);
}

/**
 * Returns the subtotal in cents.
 * If an invoice is not available, the amount is assumed based on the account and plan types.
 */
export function getNewSubscriptionSubtotal({
  annualUpgradeNonProratedInvoice,
  isEA,
  plan,
  subscription,
  upcomingInvoice,
}: {
  annualUpgradeNonProratedInvoice: Stripe.UpcomingInvoice | null
  isEA: boolean
  plan?: typeof MONTHLY | typeof YEARLY
  subscription: TruncatedStripeSubscription | Stripe.Subscription | Record<string, never> | null
  upcomingInvoice: Stripe.UpcomingInvoice | Record<string, never>
}) {
  // An upcoming invoice is not available if the user does not have a subscription,
  // or if the subscription they have is cancelled.
  const shouldHaveInvoice = !isEmptyObjectOrFalsey(subscription) && subscription.status !== "canceled";

  if (plan === MONTHLY) {
    const currentInterval = upcomingInvoice?.lines?.data?.[0]?.price?.recurring?.interval;
    if (currentInterval === "month") {
      return upcomingInvoice.subtotal;
    }

    if (shouldHaveInvoice) {
      sendMessageToSentry("Could not determine subtotal", "There is no monthly invoice available");
    }
    return (isEA ? EA_MONTHLY_PRICE : NON_EA_MONTHLY_PRICE) * DOLLARS_IN_CENTS;
  }

  if (isEmptyObjectOrFalsey(annualUpgradeNonProratedInvoice)) {
    if (shouldHaveInvoice) {
      sendMessageToSentry("Could not determine subtotal", "There is no annual invoice available");
    }
    return (isEA ? EA_ANNUAL_PRICE : NON_EA_ANNUAL_PRICE) * DOLLARS_IN_CENTS;
  }

  return annualUpgradeNonProratedInvoice.subtotal;
}

/**
 * Fetch the unit prices for the Vimcal and Vimcal EA products from the subscription's invoice.
 */
export function getUnitPricesFromInvoice(
  invoice: Stripe.Invoice | Stripe.UpcomingInvoice,
  teamPlan: TeamPlan,
): { vimcalSeatPrice: number, vimcalEASeatPrice: number, errorIdentifyingUnitPrices: boolean } {
  const items = invoice.lines.data;
  const itemUnitPrices = items.map(item => item.price?.unit_amount ?? item.amount / (item.quantity ?? 1));

  if (itemUnitPrices.length === 0) {
    // The subscription does not have any products.
    sendMessageToSentry("Error getting prices from invoice", "Invoice has 0 products");
    return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: true };
  } else if (itemUnitPrices.length > 2) {
    // We only have two products, so there should never be more than two line items.
    sendMessageToSentry("Error getting prices from invoice", `Invoice has ${itemUnitPrices.length} line items`);
    return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: true };
  } else if (itemUnitPrices.length == 2) {
    // Team plan has both EA and non-EA members.
    // This array was newly created and is safe to sort in place.
    const sorted = immutablySortArray(itemUnitPrices);
    // We have also checked that there are exactly two items in the array.
    return {
      vimcalSeatPrice: sorted[0],
      vimcalEASeatPrice: sorted[1],
      errorIdentifyingUnitPrices: false,
    };
  } else if (teamPlan.non_ea_seat_count > 0 && teamPlan.ea_seat_count === 0) {
    // Team plan only has non-EA members.
    return {
      vimcalSeatPrice: itemUnitPrices[0],
      vimcalEASeatPrice: 0,
      errorIdentifyingUnitPrices: false,
    };
  } else if (teamPlan.non_ea_seat_count === 0 && teamPlan.ea_seat_count > 0) {
    // Team plan only has EA members.
    return {
      vimcalSeatPrice: 0,
      vimcalEASeatPrice: itemUnitPrices[0],
      errorIdentifyingUnitPrices: false,
    };
  } else {
    // The invoice has one line item but we can't identify which product it belongs to.
    sendMessageToSentry("Error getting prices from invoice", "Could not identify price in invoice");
    return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: true };
  }
}

/**
 * We have two stores that store Stripe subscription info, but one is truncated and the other is the full subscription.
 * Use this function to truncate the full subscription so we can update both at the same time.
 */
export function truncateStripeSubscription(subscription: Stripe.Subscription): TruncatedStripeSubscription {
  return _.pick(subscription, "id", "customer", "status", "trial_end", "trial_start");
}

/**
 * Depending on the subscription settings, the invoice's due date could be in the due_date or the
 * next_payment_attempt properties. If both are null, use the invoice creation date.
 * @returns the due date in seconds since the Unix epoch
 */
export function getInvoiceDueDate(invoice: Stripe.Invoice | Stripe.UpcomingInvoice) {
  return invoice.due_date ?? invoice.next_payment_attempt ?? invoice.created;
}
