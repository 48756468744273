import { isMac } from "../../services/commonUsefulFunctions";
export const MULTIPLE_ACCOUNT = "MULTIPLE_ACCOUNT";
export const NOTIFICATIONS = "NOTIFICATIONS";

const ACTIVATION_KEY = isMac() ? "Command" : "Ctrl";

export const helpCenterSearchOptions = [
  {
    title: "Add multiple accounts",
    tags: ["account", "multiple", "calendar"],
    value: MULTIPLE_ACCOUNT,
  },
  {
    title: "Notifications",
    tags: ["notifications"],
    value: NOTIFICATIONS,
  },
];

export const CHROME_EXTENSION = {
  title: "Chrome extension",
  tags: ["chrome", "extension"],
  value: `
		<div>
			With the
			<a href="https://chrome.google.com/webstore/detail/vimcal/akopimcimmdmklcmegcflfidpfegngke?hl=en-US">Vimcal Chrome Extension</a>,
			you can quickly create a meeting in Gmail or send your availabilities in
			a Twitter DM, without ever leaving the tab.
		</div>
		
		<div>
			The default 3-day view gives you just the right amount
			of visibility into your upcoming schedule.
		</div>
		
		<div>
			Simply press ${ACTIVATION_KEY}+Shift+K in the browser to pop open the Chrome Extension!
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/chrome-extension-availability.gif' />
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/chrome-extension-create.gif' />
		</div>
		
		<div>
			We've distilled the most essential parts of Vimcal into the extension to keep it beautiful and lightweight.
			It currently supports one account and hotkeys are not yet enabled.
		</div>
	`,
};

export const MEET_WITH = {
  title: "Meet with",
  tags: ["meet with", "command j", "cmd j"],
  value: `
		<div>
			Hit "${ACTIVATION_KEY} J" to quickly search any calendar you have access to. This will overlay
			their calendars with yours so you can see the gaps where everyone is free.
		</div>
		
		<div>
			If you create an event from here, we'll automatically add everyone you looked up
			as attendees and select your default conferencing option for you.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/command_J.gif' />
		</div>
		
		<div>
			You can quickly clear all the calendars by hitting the "Esc" key.
		</div>
	`,
};

export const EVENT_TEMPLATES = {
  title: "Event templates",
  tags: ["event templates"],
  value: `
		<div>
			Coffee chats, onboarding calls, interviews. We create certain events over
			and over again, yet they're not quite recurring.
		</div>
		
		<div>
			Instead of manually entering the same details each time, you can simply create
			a template! Hit ${ACTIVATION_KEY} K" -> "template" and select "Create event template".
		</div>
		
		<div>
			Fill out all the details that stay constant for each meeting. For example, coffee meetings
			might always be called "Coffee Chat", last 45 minutes, and happen at the same neighborhood spot.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/event-template.gif' />
		</div>
		
		<div>
			To pre-populate a new event using the template, type "${ACTIVATION_KEY} ;" or search "Use template" in Command
			Center. If you've already started creating the event, we'll only apply the fields you haven't modified yet!
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/event-template-apply.gif' />
		</div>
	`,
};

export const OPEN_LOCATION = {
  title: "Open location",
  tags: ["location", "google maps"],
  value: `
		<div>
			Using the "L" hotkey opens up an event's location in Google Maps.
			However, with everyone staying indoors, Zoom is now the new coffee
			shop.
		</div>
		
		<div>
			If we detect a Zoom link (or any other URL), we'll redirect
			you there instead!
		</div>
	`,
};

export const MAIN_CALENDARS = {
  title: "Main Calendars",
  tags: ["main calendars", "multiple calendars"],
  value: `
		<div>
			Many of us have important invites scattered across a work,
			personal, and other accounts, but we want to see them
			in one place.
		</div>
		
		<div>
			With Main Calendars, you can view and coordinate events
			for any of your priority calendars, from any one account!
		</div>
		
		<div>
			All your favorite Vimcal features will prioritize events across
			all Main Calendars instead of just your primary:
		
			<ul>
				<li>
					Vimcal booking links removing conflicting slots to avoid double booking
				</li>
		
				<li>
					"V", Mac Menu Bar, and Upcoming section queuing up events
				</li>
		
				<li>
					Search ('/') results
				</li>
		
				<li>
					And more
				</li>
			</ul>
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/multi-cal.gif' />
		</div>
		
		<div>
			<b>
				How to set up:
			</b>
		</div>
		
		<ol>
			<li>
				Share high-priority calendars from other accounts (e.g. personal, family)
				to the one you use most (e.g. work) - <a href="https://www.loom.com/share/05f8162a51af432e86a5f6132787d141">instructions here</a>
			</li>
		
			<li>
				In your work account, denote these as your Main Calendars
				via Calendar Settings
			</li>
		</ol>
	`,
};

export const FLASH_SHORTCUTS = {
  title: "Flash shortcuts",
  tags: ["flash shortcuts", "hints", "suggestion"],
  value: `
		<div>
			Using the keyboard is significantly faster than reaching for the
			mouse. That's why we added hotkeys for almost everything in Vimcal.
		</div>
		
		<div>
			Press "F" to flash all the shortcuts for the current page you're on,
			whether that's navigating to the next week or responding "attending"
			to an event.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/f_and_v_cropped.gif' />
		</div>
		
		<div>
			For most shortcuts, you don't need to press "F" to access the shortcut
			itself - it's just a hint! The more you use the Shortcut Flasher, the
			faster you'll fly through your calendar over time.
		</div>
	`,
};

export const CMD_K = {
  title: "Command Center",
  tags: ["command center", "command k", "cmd k"],
  value: `
		<div>
			The Command Center is Vimcal's central hub.
		</div>
		
		<div>
			It allows you to access every feature and setting within
			Vimcal without ever leaving your keyboard.
		</div>
		
		<div>
			Hit "${ACTIVATION_KEY} K" and start typing out a command. You can type
			"avail" to access our Availability feature or "Jan 5" to jump
			to that date.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/command_k_vf_cropped.gif' />
		</div>
		
		<div>
			The Command Center is contextual. For example, you'll see a different
			set of options when looking at your calendar compared to when creating
			an invite.
		</div>
		
		<div>
			If you ever forget a shortcut or where to find something, you
			can always default back to the Command Center.
		</div>
	`,
};

export const MULTIPLE_CALENDARS = {
  title: "Multiple accounts",
  tags: ["multiple accounts"],
  value: `
		<div>
			You can use Vimcal with your work, personal, or any other calendar, each with
			its own shortcut so you can instantly toggle between them.
		</div>
		
		<div>
		To view all your accounts and their shortcuts, open Command Center and
			select "Show Accounts". Choose "Add Account" to log in with another one.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/multiple_accounts.gif'/>
		</div>
		
		<div>
			We always remember the last account you switched to. This way, you'll land
			into the right calendar each time you open up Vimcal in a new tab.
		</div>
	`,
};

export const OPEN_NEXT_EVENT = {
  title: "Next event",
  tags: ["next event", "upcoming"],
  value: `
		<div>
			You can navigate to adjacent events on your calendar with
			the arrow keys.
		</div>
		
		<div>
			The "up" arrow key takes you to earlier events
			in the same day while "down" takes you to later events. "Left"
			and "right" will take you to events in the previous and next
			day, respectively.
		</div>
		
		<div>
			Pressing "N" will quickly open the current or next event on your
			calendar. This will save you time when running from meeting to meeting.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/2020-03-09-next-event-shortcut.gif' />
		</div>
	`,
};

export const NLP = {
  title: "NLP",
  tags: ["create event", "nlp"],
  value: `
		<div>
			In Vimcal, creating an event is as intuitive as describing
			it in a conversation.
		</div>
		
		<div>
			Press "C" to create a new event.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/nlp-create-event.gif' />
		</div>
		
		<div>
			There's only one rule - always start with the title first.
			Everything else can come in any order afterwards.
		</div>
		
		<div>
			Try "Coffee chat 2pm tomorrow with Mike at Starbucks" or
			"Design Sync June 6 at 3pm with Mike over Zoom".
		</div>
	`,
};

export const PAINTER = {
  title: "Painter",
  tags: ["color", "painter", "change color"],
  value: `
		<div>
			Color coding is a great way to see how you're spending your time
			at a glance, and we've made this faster than ever with Painter!
		</div>
		
		<div>
			Select a meeting and type "P" to instantly change its color.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/painter.gif' />
		</div>
		
		<div>
			Whether you're categorizing an external call, team sync, or work out,
			you can customize its color via Command Center -> "Painter Settings".
		</div>
		
		<div>
			Use the arrow keys to quickly paint multiple events.
			You can color code your entire week in seconds
			without breaking flow.
		</div>
	`,
};

export const PERSONAL_LINKS = {
  title: "Personal Links",
  tags: ["schedule", "personal links"],
  value: `
		<div>
			Personal Links allow you to set recurring availabilities for
			different types of meetings, e.g. 30-minute sales calls
			or hour-long office hours.
		</div>
		
		<div>
			When you copy a Personal Link to share, we intelligently populate it
			with Slots to preserve the personal touch. Copy a link from anywhere in
			Vimcal using the global hotkeys "Y1", "Y2", etc.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/personal-links.gif' />
		</p>
		
		<div>
			Creating a new link is a breeze. Simply drag the time windows when others
			can book and set the details for the calendar invite.
		</div>
	`,
};

export const RESCHEDULE = {
  title: "Reschedule",
  tags: ["Reschedule", "booking links"],
  value: `
		<div>
			As a busy professional, you're constantly moving things
			around on your calendar.
		</div>
		
		<div>
			To speed this up, hover over the event and press "R" to
			reschedule a meeting! Simply type out the new start time
			in plain English and we'll do the heavy lifting for you.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/reschedule-event.gif' />
		</div>
	`,
};

export const SLOTS = {
  title: "Slots",
  tags: ["slots", "booking links"],
  value: `
		<div>
			We designed availability sharing to always feel fast and personal with Slots.
		</div>

		<div>
			You can choose specific times for specific people, which
			removes the discomfort of handing out a generic appointment link.
		</div>

		<div>
			Simply press "A" and start dragging available times that you'd like to share.
			These time slots will be formatted in real time, ready for you to copy.
		</div>

		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/slots.gif' />
		</div>

		<div>
			You can even customize the booking link for a specific person by adding
			their name and email. The recipient will only see the free times on
			your calendar, so you'll never get double booked.
		</div>
	`,
};

export const TIME_TRAVEL = {
  title: "Time Travel",
  tags: ["time travel", "time zone"],
  value: `
		<div>
			Whether you work remotely or have a global clientele, coordinating across
			time zones is time consuming and error-prone.
		</div>
		
		<div>
			In Vimcal, you can instantly travel to anywhere in the world to coordinate as
			if you were there.
		</div>
		
		<div>
			Press "Z" and select a time zone. Every hour in that time zone will be mapped to
			every hour in yours. No more mental math or Googling!
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/time-travel.gif' />
		</div>
		
		<div>
			You can also combine Time Travel and Slots to share your availabilities in
			someone else's time to be more thoughtful.
		</div>
	`,
};

export const OPEN_VIDEO_CONFERENCING = {
  title: "Video conferencing",
  tags: ["video conferencing", "open"],
  value: `
		<div>
			Press "V" to quickly open a meeting's video conferencing link,
			whether it's Zoom or Hangouts.
		</div>
		
		<div>
			If you don't have a meeting selected, Vimcal will take you
			directly to your current or next call - whichever is more relevant.
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3-us-west-1.amazonaws.com/video_conferencing.gif' />
		</div>
		
		<div>
			If you're using our Mac Desktop App (${ACTIVATION_KEY} K -> "Desktop"), you can
			type <b>${ACTIVATION_KEY} + Shift + J</b> to do the same thing, but from anywhere
			on your computer!
		</div>
		
		<div>
			<img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/command_shift_j.png' />
		</div>
	`,
};

export const OUTGOING_SLOTS_AVAILABILITY = {
  title: "Outgoing slots availability",
  tags: ["outgoing slots availability", "slots", "previously sent slots"],
  value: `
		<div>
			There's no way to see previously sent availability as of right now.
		</div>

		<div>
			We designed it this way to prevent clutter on your calendar and since we prevent double bookings and remove slots that are already taken, you shouldn't need to know what slots you've sent in the past. 
		</div>
	`,
};

export const OFFLINE = {
  title: "Offline",
  tags: ["offline"],
  value: `
		<div>
			We currently don't have a fully functioning offline mode. We'll store the state of your calendar from when you lost internet, but we don't have a concept of drafts or anything similar. It's something we're considering in the future!
		</div>
	`,
};

export const DATA_USAGE = {
  title: "Data usage",
  tags: ["data"],
  value: `
		<div>
			Vimcal stores almost everything locally on the client and close to nothing on our servers since we primarily use Google's API. You can think of Vimcal's servers as a relay between Google's servers and the client. We don't store company information or contacts in our servers. Vimcal only caches a small percent of calendar invites for performance gains, most of which are deleted on a daily basis. All user tokens, API keys, and other sensitive OAuth-related data are encrypted using the latest security measures. When a user logs out from their computer, their data is erased.
		</div>
	`,
};

export const HIDE_DECLINED_EVENTS = {
  title: "Hide declined events",
  tags: ["hide declined events"],
  value: `
		<div>
			You can currently change it in your GCal settings. Here are the steps:

			<div>
				<ol>
					<li>Go to your Google Calendar's settings page. Make sure you're on the right account</li>
					<li>Scroll down to 'View Options' (also clickable on the left-side menu)</li>
					<li>Select or unselect 'Show declined events'</li>
					<li>Refresh Vimcal after a few seconds and the changes should propagate</li>
				</ol>
			</div>
		</div>
	`,
};

export const EMBED_LINK = {
  title: "Embed booking link",
  tags: ["embed", "booking link"],
  value: `
		<div>
			There's an option to toggle off ""Embed link"", which removes the HTML formatting and shows a line that tells people to click the link to book.

			<div>
				This also allows you to send availabilities over text message, LinkedIn, Twitter DMs, and anywhere else outside email!
			</div>
		</div>
	`,
};

export const EVENT_NOTIFICATION = {
  title: "Notification time",
  tags: ["notifications"],
  value: `
		<div>
			<div>
				The cadence of notifications is determined via your google cal settings which you can access through:
				
				<div>
					<ol>
						<li>${ACTIVATION_KEY} K 'Open Google Cal'</li>
						<li>Selecting 'Settings' at the top right (the gear icon)</li> 
						<li>Go to your name under 'Settings for my calendars' and then 'Event notifications'</li>
					</ol>
				</div>
		</div>
	`,
};

export const MAC_NOTIFICATIONS = {
  title: "Mac desktop notifications",
  tags: ["notifications", "mac"],
  value: `
		<div>
			<div>
				You should have received a notification saying notification is enabled or a request from the OS asking you to enable notification. 
				
				<div>
				If you did not, please go to System preferences -> notifications and toggle on notifications for Vimcal
				<div>
				<img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/system_preferences.png' />

				<div>
				If you select "Banner", the notification will disappear after a few seconds. If you select "Alerts", the notification will stay until you dismiss it. 
				</div>
				
				<div>
				As a last sanity check, go back to Vimcal, if you hit ${ACTIVATION_KEY} K -> 'test notifications'. Now you should have received a notification.
				</div>
				
				<div>
					*Note: If you still have not received notifications, there's a chance you're experiencing a rare mac bug. This <a href="https://www.howtogeek.com/348935/not-getting-macos-notifications-here’s-how-to-fix-it-without-rebooting/">website</a> has instructions on how to fix it.
				</div>
		</div>
	`,
};

export const CHROME_NOTIFICATIONS = {
  title: "Browser notifications",
  tags: ["notifications", "chrome", "browser"],
  value: `
		<div>
			<div>
				<div>	
					You should have received a notification saying notification is enabled or a request from the browser asking you to enable notification. If you did not, you can manually enable it by clicking the lock button next to the url bar
				</div>
				
				<img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/chrome_notification.png' />

				<div>
					To test if notifications works now, you can hit ${ACTIVATION_KEY} + k -> test notifications. If you don't see "test notifications", you have to enable notifications again (${ACTIVATION_KEY} + k -> enable notifications)
				</div>

				<div>
					If you still do not see notifications, there's a chance that you have no enabled notifications for your Chrome. Head over to System Preferences -> Notifications and make sure that the browser (e.g. Chrome) notifications are turned on.
				</div>
				<img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/system_preferences_chrome.png' />
				
				<div>
					As a last sanity check, go back to Vimcal, if you hit ${ACTIVATION_KEY} K -> 'test notifications'. Now you should have received a notification.
				</div>
		</div>
	`,
};

export const CONTACT_GROUPS = {
  title: "Contact groups",
  tags: ["contact", "group", "group contacts", "contact groups"],
  value: `
		<div>
  			<div>
  				Over half the meetings you schedule are with the same people.
			</div>

			<div>
			  To help speed things up, we're happy to announce Contact Groups!
			</div>

			<div>
			  A Contact Group is a collection of people that you can batch-invite
			  to a meeting or whose calendars you can pull up all at once when
			  using Meet With (Cmd J).
			</div>

			<div>
			  Simply type Cmd K -> "Groups", click the "+" button, and give a
			  nickname to a set of your contacts.
			</div>

			<div>
			  <img src='https://vimcal-email-assets.s3.us-west-1.amazonaws.com/contact-groups.gif' />
			</div>

			<br>

			<div>
			  No more typing out the same emails one after the other!
			</div>
		</div>
	  `,
};

// exported directly from backend's new_member_mailer files
// add new variables to this array for fuse search to work
const NEW_MEMBER_EMAILS = [
  // CHROME_EXTENSION,
  CMD_K,
  MEET_WITH,
  EVENT_TEMPLATES,
  FLASH_SHORTCUTS,
  OPEN_LOCATION,
  MAIN_CALENDARS,
  MULTIPLE_CALENDARS,
  OPEN_NEXT_EVENT,
  NLP,
  PAINTER,
  PERSONAL_LINKS,
  RESCHEDULE,
  SLOTS,
  TIME_TRAVEL,
  OPEN_VIDEO_CONFERENCING,
  CONTACT_GROUPS,
];

// from alex's email snippets
// https://docs.google.com/spreadsheets/d/1M3ls8VBdXcepS6yuTu-aPWn4cBbvu7tSpCCrBsxdcpo/edit#gid=0
const EMAIL_SNIPPETS = [
  OUTGOING_SLOTS_AVAILABILITY,
  OFFLINE,
  DATA_USAGE,
  HIDE_DECLINED_EVENTS,
  EMBED_LINK,
  EVENT_NOTIFICATION,
  MAC_NOTIFICATIONS,
  CHROME_NOTIFICATIONS,
];

export const ALL_HELP_CENTER_OPTIONS = NEW_MEMBER_EMAILS.concat(EMAIL_SNIPPETS);
