import classNames from "classnames";
import React from "react";
import { Edit, Trash } from "react-feather";

const SIZE = 12;
const SHARED_CLASS_NAMES =
  "cursor-pointer hoverable-secondary-text-color duration-200";
export default function EditAndTrashIcons({ onClickTrash, onClickEdit }) {
  return (
    <div className="flex gap-2">
      <EditButton onClick={onClickEdit} />
      <TrashButton onClick={onClickTrash} />
    </div>
  );
}

function EditButton({ onClick }) {
  return (
    <div className="w-max relative hoverable-container">
      <div
        className="default-font-size tool-tip-container absolute"
      >
        {"Edit name and email"}
      </div>
      <Edit
        size={SIZE}
        className={classNames(SHARED_CLASS_NAMES)}
        onClick={onClick}
      />
    </div>
  );
}

function TrashButton({ onClick }) {
  return (
    <div className="w-max relative hoverable-container">
      <div
        className="default-font-size tool-tip-container absolute"
      >
        {"Remove row"}
      </div>
      <Trash
        size={SIZE}
        className={classNames(SHARED_CLASS_NAMES)}
        onClick={onClick}
      />
    </div>
  );
}
