import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmptyObjectOrFalsey } from "../js/services/typeGuards";
import routeBroadcast from "../js/broadcasts/routeBroadcast";
import appBroadcast from "../js/broadcasts/appBroadcast";
import DynamicSplitLayout from "./dynamicSplitView";
import VimcalEAFormTestimonial from "./vimcalEAForm/vimcalEAFormTestimonial";
import { VIMCAL_EA_TESTIMONIAL_HEADER } from "../js/services/experiments";
import VimcalEALoginRightPanel from "./vimcalEALoginRightPanel";

export default function VimcalEALogin() {
  const currentUser = useSelector((state) => state.currentUser);
  useEffect(() => {
    if (!isEmptyObjectOrFalsey(currentUser)) {
      routeBroadcast.publish("GO_TO_HOME");
      return;
    }
    appBroadcast.publish("SET_APP_LIGHT_MODE");
  }, []);

  return (
    <DynamicSplitLayout
      leftContent={<VimcalEAFormTestimonial headerKey={VIMCAL_EA_TESTIMONIAL_HEADER.LADY_ON_LAPTOP} />}
      rightContent={<VimcalEALoginRightPanel />}
      leftPanelClassName="hidden md:block vimcal-ea-testimonial-left-panel-bg-color min-panel-height"
      rightPanelClassName="vimcal-ea-login-right-panel min-panel-height"
      className="h-screen md:h-full"
    />
  );
}
