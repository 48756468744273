import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  convertEmailToName,
  convertMinutesIntoDayHoursAndMinutes,
  filterForLocation,
  generateConferenceRooms,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  PHONE_NUMBER_CONFERENCE,
  WHATS_APP_STRING,
} from "../services/googleCalendarService";
import {
  VIMCAL_SIGNATURE,
  TITLE_SECTION,
  TIME_SECTION,
  LOCATION_SECTION,
  CONFERENCE_SECTION,
  ROOMS_SECTION,
  ATTENDEE_SECTION,
  DETAIL_SECTION,
  AVAILABILITY_SECTION,
  REMINDER_SECTION,
  EVENT_TEMPLATE_FROM_EVENT,
  EVENT_COLOR_SECTION,
} from "../services/googleCalendarService";
import _ from "underscore";
import Checkbox from "./checkbox";
import { BLUE_BUTTON, WHITE_BUTTON } from "../services/globalVariables";
import Classnames from "classnames";
import Broadcast from "../broadcasts/broadcast";
import CustomButton from "./customButton";
import moment from "moment";
import GoogleColors from "../services/googleColors";
import {
  getEventColorID,
  getEventConferenceData,
  getEventDescription,
  getEventReminders,
  getEventTitle,
  getEventTransparency,
  getEventUserEmail,
  getEventVisibility,
} from "../services/eventResourceAccessors";
import {
  getConferenceDataConferenceName,
  getEventDescriptionUIClassnames,
  getHumanAttendees,
  getSanitizedEventDescription,
  isConferenceDataGoogleHangoutMeet,
  isConferenceDataPhone,
  isConferenceDataWhatsApp,
  isConferenceDataZoom,
} from "../lib/eventFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { pluralize } from "../lib/stringFunctions";
import { useDistroListDictionary } from "../services/stores/eventsData";
import { getCustomConferencingName } from "../lib/settingsFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { getMatchingUIUserForEvent } from "../lib/tagsFunctions";

const { noConferenceString, zoomString, googleHangoutString } =
  GoogleCalendarService;

class SelectEventDetailToCopyToEventTemplate extends PureComponent {
  constructor(props) {
    super(props);

    const color = this.determineEventColor();
    const sections = [
      TITLE_SECTION,
      TIME_SECTION,
      LOCATION_SECTION,
      CONFERENCE_SECTION,
      ROOMS_SECTION,
      ATTENDEE_SECTION,
      DETAIL_SECTION,
      AVAILABILITY_SECTION,
      REMINDER_SECTION,
    ];

    if (color) {
      sections.push(EVENT_COLOR_SECTION);
    }
    const { event } = props;

    this.state = {
      event,
      sanitizedDescription: getSanitizedEventDescription(event),
      acceptedInputs: sections,
      keyMap: {},
    };

    this.isSelectedSection = this.isSelectedSection.bind(this);
    this.clickSection = this.clickSection.bind(this);
    this.turnEventIntoTemplate = this.turnEventIntoTemplate.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getContactNameFromEmail();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.event !== this.props.event) {
      const { event } = this.props;
      const sanitizedDescription = getSanitizedEventDescription(event);
      this.setState({ event, sanitizedDescription });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        <div className={"select-event-detail-to-event-template-wrapper"}>
          {this.renderTitle()}
          {this.renderTime()}
          {this.renderWhere()}
          {this.renderConference()}
          {this.renderRooms()}
          {this.renderAttendees()}
          {this.renderDescription()}
          {this.determineEventColor() ? this.renderColor() : null}
          {this.renderAvailabilityAndVisibility()}
          {this.renderReminders()}
        </div>

        <div
          className="display-flex-flex-direction-row-justify-content-flex-end"
          style={{
            marginTop: 20,
          }}
        >
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={this.props.closeModal}
            label="Cancel"
            addPaddingToRight={true}
          />

          <CustomButton
            buttonType={BLUE_BUTTON}
            shouldFocus={true}
            onClick={() => this.turnEventIntoTemplate()}
            label="Create template"
          />
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderTitle() {
    const eventTitle = getEventTitle(this.state.event);
    if (eventTitle?.length > 0) {
      return (
        <div
          className={"cursor-pointer"}
          onClick={() => this.clickSection(TITLE_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(TITLE_SECTION))}

            <div className={"select-event-detail-section-title"}>Title</div>
          </div>

          <div className={"event-to-template-section-content"}>
            {this.state.event.summaryUpdatedWithVisibility}
          </div>
        </div>
      );
    }
  }

  renderTime() {
    return (
      <div
        className={Classnames("margin-top-ten", "cursor-pointer")}
        onClick={() => this.clickSection(TIME_SECTION)}
      >
        <div className={"display-flex-flex-direction-row align-items-center"}>
          {this.renderButton(this.isSelectedSection(TIME_SECTION))}

          <div className={"select-event-detail-section-title"}>
            Event Duration
          </div>
        </div>

        <div className={"event-to-template-section-content"}>
          {this.renderAllDayOrTime(this.state.event)}
        </div>
      </div>
    );
  }

  renderAllDayOrTime() {
    if (this.state.event.allDay) {
      let daysDiff =
        moment(this.state.event.eventEnd).diff(
          moment(this.state.event.eventStart),
          "days"
        ) + 1;

      return <div>{`${daysDiff} ${pluralize(daysDiff, "Day")}`}</div>;
    } else {
      let endTimeClone = moment(this.state.event.eventEnd).clone();

      let hoursDiff = moment(this.state.event.eventEnd).diff(
        moment(this.state.event.eventStart),
        "hours"
      );

      endTimeClone.subtract(hoursDiff, "hours");

      let minutesDiff = endTimeClone.diff(
        moment(this.state.event.eventStart),
        "minutes"
      );

      return (
        <div>
          {hoursDiff > 0 ? `${hoursDiff} ${pluralize(hoursDiff, "Hour")}` : ""}{" "}
          {minutesDiff > 0
            ? `${minutesDiff} ${pluralize(minutesDiff, "Minute")}`
            : ""}
        </div>
      );
    }
  }

  renderWhere() {
    let location = filterForLocation(this.state.event);

    if (location && location.length > 0) {
      return (
        <div
          className={Classnames("margin-top-ten", "cursor-pointer")}
          onClick={() => this.clickSection(LOCATION_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(LOCATION_SECTION))}

            <div className={"select-event-detail-section-title"}>Location</div>
          </div>

          <div className={"event-to-template-section-content"}>{location}</div>
        </div>
      );
    }
  }

  renderConference() {
    const conference = this.determineConference();

    if (conference === noConferenceString) {
      return null;
    }

    return (
      <div
        className={Classnames("margin-top-ten", "cursor-pointer")}
        onClick={() => this.clickSection(CONFERENCE_SECTION)}
      >
        <div className={"display-flex-flex-direction-row align-items-center"}>
          {this.renderButton(this.isSelectedSection(CONFERENCE_SECTION))}

          <div className={"select-event-detail-section-title"}>
            Video conference
          </div>
        </div>

        <div className={"event-to-template-section-content"}>{conference}</div>
      </div>
    );
  }

  renderRooms() {
    let roomsArray = generateConferenceRooms(this.state.event);

    if (roomsArray.length > 0) {
      return (
        <div
          className={Classnames("margin-top-ten", "cursor-pointer")}
          onClick={() => this.clickSection(ROOMS_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(ROOMS_SECTION))}

            <div className={"select-event-detail-section-title"}>
              Conference rooms
            </div>
          </div>

          <div className={"event-to-template-section-content"}>
            {roomsArray.map((r, index) => {
              return (
                <div key={`select_room_event_to_template_${index}`}>{r}</div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  renderAttendees() {
    const {
      event,
    } = this.state;
    const {
      currentUser,
      emailToNameIndex,
    } = this.props;
    const attendees = getHumanAttendees(event);
    if (isEmptyArrayOrFalsey(attendees)) {
      return null;
    }
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { distroListDictionary } = this.props.distroListDictionary;
    return (
      <div
        className={Classnames("margin-top-ten", "cursor-pointer")}
        onClick={() => this.clickSection(ATTENDEE_SECTION)}
      >
        <div className={"display-flex-flex-direction-row align-items-center"}>
          {this.renderButton(this.isSelectedSection(ATTENDEE_SECTION))}

          <div className={"select-event-detail-section-title"}>Attendees</div>
        </div>

        <div className={"event-to-template-section-content"}>
          {attendees.map((a, index) => {
            return (
              <div key={`select_attendees_event_to_template_${index}`}>
                {convertEmailToName({
                  email: getObjectEmail(a),
                  currentUser,
                  emailToNameIndex,
                  masterAccount,
                  allLoggedInUsers,
                  distroListDictionary,
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderDescription() {
    const {
      event,
      sanitizedDescription,
    } = this.state;
    const eventDescription = getEventDescription(event);
    if (eventDescription) {
      const description = eventDescription
        .replace("<div><br></div><div><br></div>" + VIMCAL_SIGNATURE, "")
        .replace(VIMCAL_SIGNATURE, "");

      if (description.length <= 2) {
        return null;
      }

      return (
        <div
          className={Classnames(
            getEventDescriptionUIClassnames({ sanitizedDescription, event, userEmail: getEventUserEmail(event) }),
            "margin-top-ten",
            "cursor-pointer"
          )}
          onClick={() => this.clickSection(DETAIL_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(DETAIL_SECTION))}
            <div className={"select-event-detail-section-title"}>Detail</div>
          </div>

          <div
            className={"event-to-template-section-content"}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      );
    }
  }

  renderColor() {
    const backgroundColor = this.determineEventColor();
    return (
      <div
        className="margin-top-ten"
        onClick={() => this.clickSection(EVENT_COLOR_SECTION)}
      >
        <div className={"display-flex-flex-direction-row align-items-center"}>
          {this.renderButton(this.isSelectedSection(EVENT_COLOR_SECTION))}

          <div className="select-event-detail-section-title display-flex-flex-direction-row align-items-center">
            Event color
            <div
              style={{
                backgroundColor,
                width: 15,
                height: 15,
                marginLeft: 10,
                borderRadius: "100%",
                marginBottom: 2,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  renderAvailabilityAndVisibility() {
    const eventTransparency = getEventTransparency(this.state.event);
    const eventVisibility = getEventVisibility(this.state.event);

    if (eventVisibility || eventTransparency) {
      return (
        <div
          className={Classnames("margin-top-ten", "cursor-pointer")}
          onClick={() => this.clickSection(AVAILABILITY_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(AVAILABILITY_SECTION))}

            <div className={"select-event-detail-section-title"}>
              Availability & Visibility
            </div>
          </div>

          <div className={"event-to-template-section-content"}>
            {eventTransparency ? (
              <div>Availability: {eventTransparency}</div>
            ) : null}

            {eventVisibility ? <div>Visibility: {eventVisibility}</div> : null}
          </div>
        </div>
      );
    }
  }

  renderReminders() {
    const eventReminders = getEventReminders(this.state.event);
    if (
      eventReminders &&
      !eventReminders.useDefault &&
      eventReminders.overrides
    ) {
      return (
        <div
          className={Classnames("margin-top-ten", "cursor-pointer")}
          onClick={() => this.clickSection(REMINDER_SECTION)}
        >
          <div className={"display-flex-flex-direction-row align-items-center"}>
            {this.renderButton(this.isSelectedSection(REMINDER_SECTION))}

            <div className={"select-event-detail-section-title"}>Reminders</div>
          </div>

          <div className={"event-to-template-section-content"}>
            {eventReminders.overrides.map((reminder, index) => {
              let reminderText =
                reminder.method +
                " " +
                convertMinutesIntoDayHoursAndMinutes(reminder.minutes) +
                " before";
              return (
                <div key={`select_reminder_event_to_template_${index}`}>
                  {reminderText}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  renderButton(isOn) {
    return <Checkbox isChecked={isOn} />;
  }

  //=================
  // PRIVATE METHODS
  //=================

  determineConference() {
    const { event } = this.state;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    const eventConferenceData = getEventConferenceData(event);
    if (isConferenceDataZoom(eventConferenceData)) {
      return zoomString;
    }
    if (isConferenceDataPhone(eventConferenceData)) {
      return PHONE_NUMBER_CONFERENCE;
    }
    if (isConferenceDataWhatsApp(eventConferenceData)) {
      return WHATS_APP_STRING;
    }
    const matchingUser = getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
    const matchingUserCustomConferencing = getCustomConferencingName({
      user: matchingUser,
    });
    if (getConferenceDataConferenceName(eventConferenceData) === matchingUserCustomConferencing) {
      return matchingUserCustomConferencing;
    }
    // TODO: for each conferencing (google,phone, whatsapp, zoom, custom) -> does it show properly in tempalte
    //note: we don't get conferenceData for outlook
    if (isConferenceDataGoogleHangoutMeet(eventConferenceData)) {
      return googleHangoutString;
    }
    return noConferenceString;
  }

  isSelectedSection(section) {
    return this.state.acceptedInputs.includes(section);
  }

  clickSection(section) {
    let updatedAcceptedInputs = _.clone(this.state.acceptedInputs);

    if (this.state.acceptedInputs.includes(section)) {
      updatedAcceptedInputs = updatedAcceptedInputs.filter(
        (i) => i !== section
      );

      this.setState({ acceptedInputs: updatedAcceptedInputs });
    } else {
      updatedAcceptedInputs = updatedAcceptedInputs.concat(section);

      this.setState({ acceptedInputs: updatedAcceptedInputs });
    }
  }

  getContactNameFromEmail() {
    const {
      event,
    } = this.state;
    const attendees = getHumanAttendees(event);
    // search in indexDB
    if (isEmptyArrayOrFalsey(attendees)) {
      return;
    }

    const emailList = [];
    attendees.forEach((e) => {
      if (!this.props.emailToNameIndex[e?.email]) {
        emailList.push(getObjectEmail(e));
      }
    });

    if (isEmptyArrayOrFalsey(emailList)) {
      return;
    }

    Broadcast.publish("SEARCH_CONTACTS_DOMAIN_DB", emailList);
  }

  determineEventColor() {
    const event = this.props.event;

    const colorId = getEventColorID(event);

    if (colorId && GoogleColors.primaryEventsColors[colorId]) {
      return GoogleColors.primaryEventsColors[colorId].color;
    }

    return null;
  }

  turnEventIntoTemplate() {
    Broadcast.publish(
      "UPDATE_TEMPLATE",
      EVENT_TEMPLATE_FROM_EVENT,
      this.props.event,
      this.state.acceptedInputs
    );
  }
}

function mapStateToProps(state) {
  let { emailToNameIndex, currentUser } = state;

  return {
    emailToNameIndex,
    currentUser,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const distroListDictionary = useDistroListDictionary();
  const allCalendars = useAllCalendars();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      distroListDictionary={distroListDictionary}
      allCalendars={allCalendars}
    />
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(withStore(SelectEventDetailToCopyToEventTemplate))
);
