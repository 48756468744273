import React, { PureComponent, createRef } from "react";
import Modal from "react-modal";
import { UploadCloud } from "react-feather";
import {
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
  MODAL_BLUR,
  MODAL_BORDER_RADIUS,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  TYPE_JPEG,
  TYPE_PNG,
  getModalBackgroundColor,
} from "../../services/globalVariables";
import pasteBroadcast from "../../broadcasts/pasteBroadcast";
import VimcalLoadingAnimation from "../icons/vimcalLoadingAnimation";
import SaveButton from "../saveButton";
import {
  FIND_FREE_TIME_ID,
  REVERSE_SLOTS_CONTAINER_ID,
} from "../../services/elementIDVariables";
import broadcast from "../../broadcasts/broadcast";
import AILoadingAnimation from "../animation/aiLoadingAnimation";
import BetaTag from "./betaTag";
import LoadingSkeleton from "../loadingSkeleton";
import RichTextContainer from "../richTextContainer";
import LoadingDots from "../loadingDots";
import { hasEventPreventDefault, isHTMLText } from "../../services/commonUsefulFunctions";
import classNames from "classnames";
import CloseButton from "../closeButton";
import { formatBookingLinkText, getParsableBookingLinkData, isAllowedBookingLink } from "../../lib/availabilityFunctions";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { devPrint } from "../../services/devFunctions";
import { MODAL_OVERLAY_Z_INDEXES } from "../../lib/modalFunctions";
import { getSidePoppedOverModalBorder } from "../../lib/styleFunctions";

const REVERSE_SLOTS_TEXT_AREA = "reverse-slots-text-area";
export default class AISchedulerModal extends PureComponent {
  constructor(props) {
    super(props);
    this._uploadRef = createRef();

    const isInitialTextURL = isAllowedBookingLink(props.initialReverseSlotsText);

    this.state = {
      pastedText: !isInitialTextURL ? props.initialReverseSlotsText ?? "" : "",
      isLoading:
        !!props.initialReverseSlotsText || props.isLoading ? true : false,
      pastedURL: isInitialTextURL
        ? props.initialReverseSlotsText
        : "",
      showWarning: false,
      isLoadingURL: isInitialTextURL || false,
    };

    this.onHandleInput = this.onHandleInput.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.setText = this.setText.bind(this);
    this.onClickSchedule = this.onClickSchedule.bind(this);
    this.resetLoading = this.resetLoading.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeURL = this.onChangeURL.bind(this);
    this.handlePasteIntoRichText = this.handlePasteIntoRichText.bind(this);

    pasteBroadcast.subscribe("UPDATE_REVERSE_SLOTS_TEXT", this.setText);
    pasteBroadcast.subscribe("RESET_MODAL_LOADING", this.resetLoading);
  }

  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      const textPasteArea = document.getElementById(REVERSE_SLOTS_TEXT_AREA);
      textPasteArea?.addEventListener("paste", this.handlePasteIntoRichText);
    }, 0.5 * SECOND_IN_MS);
  }

  componentWillUnmount() {
    this._isMounted = false;
    const textPasteArea = document.getElementById(REVERSE_SLOTS_TEXT_AREA);
    textPasteArea?.removeEventListener("paste", this.handlePasteIntoRichText);
    pasteBroadcast.unsubscribe("UPDATE_REVERSE_SLOTS_TEXT");
    pasteBroadcast.unsubscribe("RESET_MODAL_LOADING");
  }

  render() {
    const { isOpen, isDarkMode } = this.props;
    return (
      <Modal
        isOpen={isOpen || false}
        style={this.getStyle({ isDarkMode })}
        onRequestClose={this.onCloseModal}
        ariaHideApp={false}
        id={FIND_FREE_TIME_ID}
        className="relative overflow-hidden"
      >
        {this.renderContentContainer()}
      </Modal>
    );
  }

  onCloseModal() {
    const { onRequestClose } = this.props;
    const { resetReverseSlotsData } = this.props.temporaryStateStore;
    resetReverseSlotsData();
    onRequestClose();
  }

  isLoadingURL() {
    return this.state.isLoadingURL && this.state.isLoading;
  }

  isLoadingNormalText() {
    return this.state.isLoading && !this.state.isLoadingURL;
  }

  renderContentContainer() {
    return (
      <div
        className={classNames(
          "flex flex-col p-5 relative default-font-color justify-start",
          this.getHeightClassName()
        )}
        id={REVERSE_SLOTS_CONTAINER_ID}
      >
        {this.renderHeader()}
        {this.renderSubText()}
        {this.renderPasteText()}
        {this.renderURLInput()}
        {this.renderCopyImage()}
        {this.renderScheduleButton()}
        {this.renderLoadingScreen()}
        {this.renderCloseButton()}
      </div>
    );
  }

  renderURLInput() {
    if (this.isLoadingNormalText()) {
      return null;
    }

    return (
      <div>
        <input
          className={classNames(
            "default-input-field",
            "padding-14px my-2",
            "ai-scheduler-modal-background-color default-font-size",
            this.isLoadingURL() ? "mt-5" : ""
          )}
          value={this.state.pastedURL}
          onChange={this.onChangeURL}
          placeholder={"Paste Calendly or Vimcal booking link URL"}
          disabled={this.isLoadingURL()}
          // onKeyDown={this.onKeyDownEscape}
          // onKeyDownCapture={onKeyChange ? onKeyChange : _.noop}
          // onFocusCapture={() => this.setState({ section: section })}
          autoComplete="new-password"
        />
        {this.state.showWarning ? (
          <div className="default-font-size warning-color -mt-1.5 ml-4">
            Please only paste a calendly or Vimcal URL
          </div>
        ) : null}
      </div>
    );
  }

  renderCloseButton() {
    return (
      <div className="absolute right-5">
        <CloseButton onClick={this.onCloseModal} />
      </div>
    );
  }

  renderLoadingScreen() {
    if (!this.state.isLoading) {
      return null;
    }
    return (
      <div className="flex flex-col items-center">
        <AILoadingAnimation />
        <div className="default-font-size flex items-center">
          <div>AI finding times</div>
          <LoadingDots />
        </div>
      </div>
    );
  }

  renderWaitingScreen() {
    const { isLoading } = this.state;
    if (!isLoading) {
      return null;
    }

    return (
      <div className="absolute w-full h-full">
        <div className="-ml-5 -mt-16">
          <VimcalLoadingAnimation />
        </div>
      </div>
    );
  }

  renderScheduleButton() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <SaveButton
        onClick={this.onClickSchedule}
        width={"100%"}
        height={"42px"}
        hideDefaultIcon={true}
        buttonText={"Schedule"}
        marginTop={"20px"}
        justifyContent={"center"}
        disabled={!this.state.pastedText && !this.state.pastedURL}
      />
    );
  }

  renderSubText() {
    return (
      <div className="secondary-text-color default-font-size mt-2">
        Paste text or upload a screenshot of times other people suggest to you,
        and let Vimcal AI show you when you’re free.
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="font-weight-400 font-size-16 flex items-center justify-center">
        <div className="ml-2">✨ Free Time Finder</div>

        <BetaTag containerClassName="ml-2" />
      </div>
    );
  }

  renderPasteText() {
    const { isLoading, pastedText } = this.state;
    if (this.isLoadingURL()) {
      return null;
    }
    const { originalReverseSlotsImage } = this.props.temporaryStateStore;
    if (isLoading && !pastedText && !originalReverseSlotsImage) {
      return (
        <div className="flex justify-center">
          <LoadingSkeleton style={{ height: 192, width: 200, marginTop: 20 }} />
        </div>
      );
    }
    if (originalReverseSlotsImage) {
      return (
        <div className="flex justify-center items-center">
          <img
            src={originalReverseSlotsImage}
            alt={""}
            className="w-auto h-auto object-fill overflow-hidden mt-5"
            style={{ maxWidth: "100%", maxHeight: "192px" }}
          />
        </div>
      );
    }

    if (isLoading && !isHTMLText(pastedText)) {
      const { isDarkMode } = this.props;
      return (
        <textarea
          disabled={isLoading}
          value={pastedText}
          autoFocus={true}
          onChange={this.onHandleInput}
          className={classNames(
            "resize-none bg-transparent w-full rounded-md p-4 mt-5 h-48",
            isLoading ? "text-area-secondary-text-color-override" : ""
          )}
          placeholder="Paste any text here for Vimcal to schedule"
          style={{
            backgroundColor: isDarkMode ? "rgba(54, 54, 69, 0.5)" : undefined,
            border: isDarkMode
              ? "1px solid rgba(255, 255, 255, 0.15)"
              : `1px solid ${LIGHT_MODE_SECONDARY_TEXT_COLOR}`,
          }}
        />
      );
    }

    return (
      <RichTextContainer
        id={REVERSE_SLOTS_TEXT_AREA}
        containerClassName="ai-scheduler-modal-rich-text-editor allow-focus mt-5 mb-2"
        val={pastedText}
        onSetValue={this.onHandleInput}
        autoFocus={true}
        placeHolder={"Paste your conversation here"}
        readOnly={isLoading}
        modules={{ toolbar: false }}
      />
    );
  }

  handlePasteIntoRichText(event) {
    // Prevent the default paste behavior
    hasEventPreventDefault(event);
    try {
      // Get the text content from the clipboard
      const text = (event.clipboardData || window.clipboardData)?.getData("text/plain");
      // Insert text manually
      document.execCommand("insertText", false, text);
    } catch (error) {
      devPrint(error);
    }
  }

  onClickSchedule() {
    const { pastedText, pastedURL } = this.state;
    const updatedState = {};
    const isValidPastedURL = isAllowedBookingLink(pastedURL);
    if (!pastedText && pastedURL && !isValidPastedURL) {
      this.setState({ showWarning: true });
      return;
    }

    if (isValidPastedURL) {
      updatedState["isLoadingURL"] = true;
      getParsableBookingLinkData(pastedURL);
    } else {
      const formattedBookingLinkTextFromPastedText = formatBookingLinkText(pastedText);
      const isPastedTextBookingLinkText = isAllowedBookingLink(formattedBookingLinkTextFromPastedText);
      if (isPastedTextBookingLinkText) {
        updatedState["isLoadingURL"] = true;
        updatedState["pastedText"] = "";
        updatedState["pastedURL"] = formattedBookingLinkTextFromPastedText;
        getParsableBookingLinkData(formattedBookingLinkTextFromPastedText);
      }
    }

    this.setState({ isLoading: true, ...updatedState });

    if (updatedState.isLoadingURL) {
      return;
    }
    pasteBroadcast.publish("HANDLE_PASTE_TEXT_AI", { pastedText });
  }

  onHandleInput(text) {
    this.setState({ pastedText: text });
  }

  resetLoading() {
    this.setState({ isLoading: false });
  }

  setText(text) {
    this.setState({ pastedText: text });
  }

  async processFile(file) {
    if (!file) {
      return;
    }
    // Read the file content using FileReader
    const reader = new FileReader();
    reader.onload = (event) => {
      const pasteImage = event.target.result;
      this.setState({ isLoading: true });
      const { saveOriginalReverseSlotsImage } = this.props.temporaryStateStore;
      saveOriginalReverseSlotsImage(pasteImage);
      pasteBroadcast.publish("HANDLE_UPLOADED_IMAGE", pasteImage);
    };
    reader.readAsDataURL(file);
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    if (![TYPE_PNG, TYPE_JPEG].includes(file?.type)) {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Please only upload .png or .jpeg files"
      );
      return;
    }

    this.processFile(file);
  }

  onClickUpload() {
    this._uploadRef.current.click();
  }

  renderCopyImage() {
    if (this.state.isLoading) {
      return null;
    }

    const { isDarkMode } = this.props;
    return (
      <div
        className="flex items-center hoverable-secondary-text-color default-font-size justify-center py-4 mt-2 rounded-md"
        style={{
          backgroundColor: isDarkMode ? "rgba(54, 54, 69, 0.5)" : undefined,
          border: isDarkMode
            ? "1px dashed rgba(255, 255, 255, 0.15)"
            : `1px dashed ${LIGHT_MODE_SECONDARY_TEXT_COLOR}`,
        }}
        onClick={this.onClickUpload}
      >
        <input
          type="file"
          ref={this._uploadRef}
          onChange={this.handleFileChange}
          style={{ display: "none" }}
        />
        <UploadCloud size={12} />
        <div className="ml-2">Upload image</div>
      </div>
    );
  }

  getHeightClassName() {
    if (this.isLoadingURL()) {
      return "find-free-time-loading-url-only";
    }

    if (this.isLoadingNormalText()) {
      return ""
    }

    return "find-free-time-modal";
  }

  onChangeURL(e) {
    const loweredCase = getInputStringFromEvent(e).toLowerCase();
    this.setState({ pastedURL: loweredCase, showWarning: false });
  }

  getStyle({ isDarkMode }) {
    return {
      overlay: {
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0)",
        zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
      },
      content: {...({
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: 5,
        backgroundColor: getModalBackgroundColor(isDarkMode),
        color: "white",
        width: "470px",
        backdropFilter: MODAL_BLUR,
        WebkitBackdropFilter: MODAL_BLUR,
      }),
      ...getSidePoppedOverModalBorder(isDarkMode),
      ...{borderRadius: MODAL_BORDER_RADIUS},
      },
    };
  }
}
