import React, { Component, useRef, useEffect } from "react";
import { ATTENDEE_EVENT_TENTATIVE, DATE_TIME_24_HOUR_FORMAT } from "../services/googleCalendarService";
import { batch, connect } from "react-redux";
import {
  Users,
  UserX,
  Check,
  Plus,
  Home,
  Printer,
  MapPin,
  RefreshCw,
} from "react-feather";
import {
  isUserOnlyPersonWhoAcceptedAndRestDeclined,
  getEmailBasedOnCalendarId,
  shouldReduceTextSize,
  hasEventPreventDefault,
  createFadedColorIfIndexDoesNotExist,
  isEventInThePast,
  dimDarkModeColor,
  isOnboardingMode,
  hasStopEventPropagation,
  isFirefox,
  getDimmedColor,
  determineEventBackgroundColor,
} from "../services/commonUsefulFunctions";
import StyleConstants, {
  DELETE_SLOT,
  FADED_GREY_TEXT_COLOR,
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  ONLY_PERSON_ATTENDING_REST_DECLINED,
  SELF_ATTENDING_STATUS,
} from "../services/globalVariables";
import GlobalKeyMapTile from "./globalKeyMapTile";
import { X } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import GoogleColors from "../services/googleColors";
import { format, isSameMinute, differenceInMinutes } from "date-fns";
import classNames from "classnames";
import {
  determinedMergedEventBackgroundColor,
  shouldShowTransparentMergedBackground,
  shouldShowReducedHeightTransparentMergedEvent,
  isEventBetween15And30Minutes,
  isOutOfOfficeEvent,
  isAvailabilityEvent,
  getHumanAttendees,
  getSelfAttendingStatus,
  isTemporaryEvent,
  isLocationString,
  isAllDayEvent,
  isWorkplaceEvent,
  isSpecialEvent,
  isEventWorkLocationHome,
  isEventWorkLocationOffice,
  isAllDayWorkLocationEvent,
  isFreeEvent,
  isMergedEvent,
  getEventStaticInfo,
  determineOutlookCategoryColor,
  isPreviewOutlookEvent,
  isRecurringEvent,
  isEventHiddenEvent,
} from "../lib/eventFunctions";
import OnboardBroadcast from "../broadcasts/onboardBroadcast";
import ScheduleCheckBox from "./scheduling/scheduleCheckBox";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import {
  useHideRightHandSidebar,
  useIsMouseMoving,
} from "../services/stores/appFunctionality";
import { useEventHotKeysIndex } from "../services/stores/eventsData";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  determineCalendarColor,
  getMatchingUserFromEvent,
} from "../lib/calendarFunctions";
import { useCustomEvent } from "../services/stores/customEvent";
import {
  getClientEventID,
  getEventColorID,
  getEventLocation,
  getEventStatus,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import {
  getEventClientRect,
  isActionModeUpsertEvent,
  isAppInTaskMode,
  isAvailabilityPanelShowing,
  isEventFormShowing,
  isGroupVoteCalendarViewPreviewShowing,
  isInActionMode,
  isSlotBreakDurationToggleShowing,
  shouldTruncateRightHandPanel,
} from "../services/appFunctions";
import { getDragStartAndEnd } from "../lib/rbcFunctions";
import {
  getMatchingUIUserForEvent,
  getSmartTagString,
  getTagColor,
  getTagsFromEvent,
} from "../lib/tagsFunctions";
import {
  isGroupVoteEvent,
  isOutstandingSlotEvent,
  isTemporaryAIEvent,
} from "../lib/availabilityFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { getEventTagColor } from "../lib/tagsFunctions";
import { isFindTimeEventFormEvent } from "../lib/temporaryEventFunctions";
import groupVoteBroadcast from "../broadcasts/groupVoteBroadcast";
import { getDefaultBackgroundColor, getDefaultFontColor, getTentativeSideBarBackgroundStyle } from "../lib/styleFunctions";
import {
  getWorkLocationBackgroundColor,
  getWorkLocationTextColor,
} from "../lib/workLocationFunctions";
import { isOutlookShowAsTentativeEvent } from "../resources/outlookVariables";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { shouldDimPastEvents } from "../lib/settingsFunctions";
import { getDateTimeFormatLowercaseAMPM } from "../lib/dateFunctions";
import { preventHoverExpandedEvent } from "../lib/featureFlagFunctions";
import { isOutlookOOOEvent, OUTLOOK_OOO_EVENT_COLOR } from "../lib/outlookFunctions";
import { AVAILABILITY_BROADCAST_VALUES } from "../lib/broadcastValues";

class CustomEvent extends Component {
  constructor(props) {
    super(props);

    this._cachedEventInfo = {};

    this.setEventOnHoverTimer = null;
    this.isMouseHoverAllowed = false;
    this._isGroupVotePreview = isGroupVoteCalendarViewPreviewShowing();

    // keep track of last start time for drag so we don't re-render
    this._lastStartTime = null;
    this._lastEndTime = null;
    this._mouseOverTimer = null;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.removeAvailabilityEvent = this.removeAvailabilityEvent.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.onClickEvent = this.onClickEvent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.allowMouseOverTimer();
  }

  // can't use hasStateOrPropsChanged here because weekly calendar sends down some props that aren't immutable
  shouldComponentUpdate(newProps, newState) {
    if (this.props.event.uniqueEtag !== newProps.event.uniqueEtag) {
      return true;
    } else if (isActionModeUpsertEvent(newProps.actionMode) !== isActionModeUpsertEvent(this.props.actionMode)) {
      return true;
    } else if (
      this.props.event.summaryUpdatedWithVisibility !==
      newProps.event.summaryUpdatedWithVisibility
    ) {
      return true;
    } else if (
      this.props.eventIndexStore.eventHotKeysIndex !==
      newProps.eventIndexStore.eventHotKeysIndex
    ) {
      return true;
    } else if (
      this.props.event.isTemporary &&
      (!isSameMinute(this.props.event.eventStart, newProps.event.eventStart) ||
        !isSameMinute(this.props.event.eventEnd, newProps.event.eventEnd))
    ) {
      return true;
    } else if (this.shouldRerenderBasedOnDrag(newProps.event)) {
      return true;
    } else if (this.props.currentUser !== newProps.currentUser) {
      return true;
    } else if (
      shouldDimPastEvents({
        masterAccount: this.props.masterAccount.masterAccount,
      }) !==
      shouldDimPastEvents({
        masterAccount: newProps.masterAccount.masterAccount,
      })
    ) {
      return true;
    } else if (
      this.props.selectedCalendarView !== newProps.selectedCalendarView
    ) {
      return true;
    } else if (this.props.popupEvent !== newProps.popupEvent) {
      return true;
    } else if (this.props.hoverPopupEvent !== newProps.hoverPopupEvent) {
      return true;
    } else if (
      shouldTruncateRightHandPanel(this.props.hideRightHandSidebar) !==
      shouldTruncateRightHandPanel(newProps.hideRightHandSidebar)
    ) {
      return true;
    } else if (this.props.format24HourTime !== newProps.format24HourTime) {
      return true;
    } else if (
      this.isResponsiveToHoverEventID() &&
      this.props.temporaryStateStore.hoveredEventID !==
        newProps.temporaryStateStore.hoveredEventID
    ) {
      return true;
    } else if (this.props.isDarkMode !== newProps.isDarkMode) {
      return true;
    } else if (
      getSmartTagString(this.props.event) !== getSmartTagString(newProps.event)
    ) {
      return true;
    } else if (this.props.allLoggedInUsers !== newProps.allLoggedInUsers) {
      return true;
    } else if (this.props.allCalendars !== newProps.allCalendars) {
      return true;
    } else if (this.props.currentTimeZone !== newProps.currentTimeZone) {
      return true;
    } else if (isEventHiddenEvent(this.props.event) !== isEventHiddenEvent(newProps.event)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      shouldTruncateRightHandPanel(this.props.hideRightHandSidebar) !==
      shouldTruncateRightHandPanel(prevProps.hideRightHandSidebar)
    ) {
      this.isMouseHoverAllowed = false;
      this.allowMouseOverTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setEventOnHoverTimer);
    clearTimeout(this._mouseOverTimer);

    this.setEventOnHoverTimer = null;
    this._lastStartTime = null;
    this._lastEndTime = null;

    this._isMounted = false;
    this._cachedEventInfo = null;
  }

  render() {
    // can't cache via etag because when you drag/resize events, this.props.event.start changes
    const { isEventAvailibilityEvent } = this.determineEventInfo();
    const {
      event,
    } = this.props;
    if (this.isHiddenEvent()) {
      return this.renderHiddenEventBorder();
    }

    return (
      <div
        id={getEventUserEventID(event)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={`custom_event_${getEventUserEventID(event)}`}
        className={classNames(
          "custom-event-container",
          this.determineContainerHeight(),
          isEventAvailibilityEvent ? "position-relative" : "",
          determinedMergedEventBackgroundColor(event, true),
          "prevent-point-events-custom-event",
          this.isTransparentAndMergedEvent() 
            && !isWorkplaceEvent(event)
            && this.isDisplayAsAllDay() ? "pl-2.5" : "",
        )}
        onContextMenu={this.onContextMenu}
        onClick={this.onClickEvent}
      >
        {this.renderEventContent()}
      </div>
    );
  }

  renderEventContent() {
    const { startTime, endTime } = this.getEventTime();

    const {
      minutesDiffBetweenStartAndEnd,
      displayWarning,
      attendees,
      displaySummaryAndTimeAsOneLine,
      isEventAvailibilityEvent,
      location,
    } = this.determineEventInfo();
    const { event } = this.props;
    if (isAllDayWorkLocationEvent(event)) {
      return this.renderAllDayWorkingLocationEvent();
    }
    return (
      <>
        {this.renderMapTile(displaySummaryAndTimeAsOneLine)}
        {!this.alwaysDisplayAsMuliLineEvent() && displaySummaryAndTimeAsOneLine
          ? this.renderSingleLineEvent(displayWarning, location, minutesDiffBetweenStartAndEnd)
          : null}

        {this.renderLeftSideBar()}
        {this.renderTemporaryAISchedulingEvent()}

        {this.renderMultiLineEvent(
          startTime,
          endTime,
          minutesDiffBetweenStartAndEnd,
          displayWarning,
          attendees,
          displaySummaryAndTimeAsOneLine,
          location
        )}
        {isEventAvailibilityEvent ? this.renderCloseButton() : null}
      </>
    );
  }

  renderTemporaryAISchedulingEvent() {
    if (!this.isResponsiveToHoverEventID()) {
      return null;
    }

    if (!this.hideLabel()) {
      return null;
    }

    return (
      <div className="w-full h-full flex items-center justify-center">
        <Plus size={16} />
      </div>
    );
  }

  isTemporaryAIEvent() {
    return isTemporaryAIEvent(this.props.event);
  }

  renderEventIcon() {
    const { event } = this.props;
    if (isOutOfOfficeEvent(event)) {
      return <UserX size={10} className="mr-1" strokeWidth={3} />;
    }
    if (isWorkplaceEvent(event)) {
      const color = this.getEventColor();
      if (isEventWorkLocationHome(event)) {
        return (
          <Home
            size={10}
            className="mr-1 h-full"
            strokeWidth={3}
            color={color}
          />
        );
      }
      if (isEventWorkLocationOffice(event)) {
        return (
          <Printer
            size={10}
            className="mr-1 h-full"
            strokeWidth={3}
            color={color}
          />
        );
      }
      return (
        <MapPin
          size={10}
          className="mr-1 h-full"
          strokeWidth={3}
          color={color}
        />
      );
    }
    return null;
  }

  shouldReduceTextSize() {
    return shouldReduceTextSize(this.props.event);
  }

  renderSingleLineEvent(displayWarning, location, eventDuration) {
    let singleLineClassName = this.shouldReduceTextSize()
      ? "font-size-10 mt-0"
      : "";
    const style = isWorkplaceEvent(this.props.event)
      ? {
          color: getWorkLocationTextColor({
            color: this.getEventColor(),
            isDarkMode: this.getIsDarkMode(),
            shouldDim: this.shouldDim(),
          }),
        }
      : undefined;

    return (
      <div
        className={classNames(
          isAllDayWorkLocationEvent(this.props.event)
            ? "all-day-work-location-text-wrapper"
            : classNames("custom-event-display-single-line-event", this.isRecurringEvent() ? "event-label-with-recurring-event-icon" : ""),
          singleLineClassName,
          this.isTransparentAndMergedEvent() &&
            isEventBetween15And30Minutes(this.props.event)
            ? "pt-0.5"
            : ""
        )}
        style={style}
      >
        {this.renderEventIcon()}
        <span className={"font-weight-400"}>
          {displayWarning ? this.renderWarning() : null}

          {this.props.event.summaryUpdatedWithVisibility}
        </span>

        {!this.props.event.displayAsAllDay && (
          <span className="margin-right-2">,</span>
        )}

        {!this.props.event.displayAsAllDay && (
          <span
            className={classNames(
              "custom-event-display-single-line-event",
              "font-weight-200",
              singleLineClassName
            )}
          >
            {format(
              this.props.event.start || this.props.event.eventStart,
              getDateTimeFormatLowercaseAMPM(this.props.format24HourTime)
            )}
          </span>
        )}

        {location ? (
          <span>
            <span className="margin-right-5">,</span>

            <span className="font-weight-200">{location}</span>
          </span>
        ) : null}
        {this.renderRecurringEventIcon({displaySummaryAndTimeAsOneLine: true, eventDuration})}
      </div>
    );
  }

  hideLabel() {
    const { hoveredEventID } = this.props.temporaryStateStore;
    return (
      this.isResponsiveToHoverEventID() &&
      !isNullOrUndefined(hoveredEventID) &&
      hoveredEventID === getClientEventID(this.props.event)
    );
  }

  isSpecialEvent() {
    const { event } = this.props;
    return isSpecialEvent(event);
  }

  renderMultiLineEvent(
    startTime,
    endTime,
    minutesDiffBetweenStartAndEnd,
    displayWarning,
    attendees,
    displaySummaryAndTimeAsOneLine,
    location
  ) {
    const differenceBetweenStartAndEndInMins = differenceInMinutes(
      this.props.event.eventEnd,
      this.props.event.eventStart
    );
    const showLocationWithTime =
      location && differenceBetweenStartAndEndInMins < 75;

    const shouldDisplayAttendeesSection = () => {
      return (
        attendees &&
        attendees.length > 0 &&
        !this.props.event.displayAsAllDay &&
        !this.isSpecialEvent() &&
        (location && !showLocationWithTime
          ? differenceBetweenStartAndEndInMins >= 90
          : differenceBetweenStartAndEndInMins >= 75)
      );
    };

    if (
      this.props.event.displayGroupVoteInfo &&
      minutesDiffBetweenStartAndEnd <= 45 &&
      !this.props.event.displayAsAllDay
    ) {
      return this.renderCheckBox(
        displaySummaryAndTimeAsOneLine && minutesDiffBetweenStartAndEnd < 30
      );
    }

    const determineTimeLabel = () => {
      if (this.props.event.displayAsAllDay && this.isAvailabilityEvent()) {
        return "All day";
      } else if (showLocationWithTime) {
        return `${startTime}, ${location}`;
      } else {
        return `${startTime} - ${endTime}`;
      }
    };

    const getMaxHeight = () => {
      const LINE_HEIGHT = 15;
      if (minutesDiffBetweenStartAndEnd < 60) {
        return 1 * LINE_HEIGHT;
      }
      if (minutesDiffBetweenStartAndEnd === 60) {
        return 2 * LINE_HEIGHT;
      }
      const numberOfLines = Math.max(
        1,
        Math.floor(minutesDiffBetweenStartAndEnd / 20) - 1
      );
      return numberOfLines * LINE_HEIGHT; // 15 is the height of the line height
    };

    return (
      <div className="padding-left-3 h-full">
        {!displaySummaryAndTimeAsOneLine && (
          <div
            className={classNames(
              "custom-event-thirty-min-or-more-container",
              this.hideLabel() ? "text-transparent-important duration-200" : "",
              "duration-200"
            )}
            style={{
              maxHeight: getMaxHeight(),
            }}
          >
            {this.renderEventIcon()}

            {displayWarning && this.renderWarning()}

            <span
              className={classNames(
                this.shouldAddAdditionalPadding(
                  this.props.event.summaryUpdatedWithVisibility
                )
                  ? "pl-0.5"
                  : ""
              )}
            >
              {this.props.event.summaryUpdatedWithVisibility}
            </span>
          </div>
        )}

        {(!displaySummaryAndTimeAsOneLine ||
          this.alwaysDisplayAsMuliLineEvent()) && (
          <div
            className={classNames(
              this.hideLabel() ? "text-transparent-important" : "",
              "duration-200",
              "custom-event-thirty-min-or-more-display-time",
              this.isAvailabilityEvent()
                ? "text-dark-mode-background-color"
                : "",
              this.isRecurringEvent() && minutesDiffBetweenStartAndEnd < 75 ? "event-label-with-recurring-event-icon" : "",
            )}
          >
            {determineTimeLabel()}
          </div>
        )}

        {location && !displaySummaryAndTimeAsOneLine && !showLocationWithTime
          ? this.renderLocation({location, minutesDiffBetweenStartAndEnd})
          : null}

        {this.renderCheckBox()}

        {shouldDisplayAttendeesSection() && (
          <div className="custom-event-number-of-participants">
            <Users size="12" />

            <div className="custom-event-number-of-participants-styling">
              {attendees.length}
            </div>
          </div>
        )}
        {displaySummaryAndTimeAsOneLine ? null : this.renderRecurringEventIcon({displaySummaryAndTimeAsOneLine})}
      </div>
    );
  }

  alwaysDisplayAsMuliLineEvent() {
    return (
      this.isAvailabilityEvent() ||
      this.isFindTimeEventFormEvent() ||
      this.isOutstandingSlotEvent()
    );
  }

  renderDuration(duration) {
    // duration comes in form of {hours:x, minutes: y};
    return null;
    // const renderDurationText = (duration) => (
    //   <div className="display-flex-flex-direction-row align-items-center font-weight-300">
    //     {duration.hours ?
    //       <div>
    //         {`${duration.hours} ${pluralize(duration.hours, 'hr')}`}
    //       </div> : null
    //     }

    //     {duration.minutes ?
    //       <div className={classNames(duration.hours ? "ml-1" : "")}>
    //         {`${duration.minutes} ${pluralize(duration.minutes, 'min')}`}
    //       </div> : null
    //     }
    //   </div>
    // );

    // if (this.isAvailabilityEvent()) {
    //   return null;
    // }

    // return (
    //   <div>
    //     {this.renderDuration(reformatMinDuration(differenceInMinutes(
    //       this.props.event.end || this.props.event.eventEnd,
    //       this.props.event.start || this.props.event.eventStart
    //     )))}
    //   </div>
    // );
  }

  renderLocation({location, minutesDiffBetweenStartAndEnd}) {
    return (
      <div className={classNames("font-weight-200", minutesDiffBetweenStartAndEnd < 120 ? "event-label-with-recurring-event-icon" : "")}>
        {location}
      </div>
    );
  }

  renderCheckBox(isInReduceSpace = false) {
    if (!this.props.event.displayGroupVoteInfo) {
      return null;
    }

    const shouldDisplayContainerMargin =
      isInReduceSpace ||
      (this.isAvailabilityEvent() && this.props.event.displayAsAllDay);
    return (
      <div
        className={classNames(
          "w-full flex flex-row items-center",
          shouldDisplayContainerMargin ? "" : "mt-1"
        )}
      >
        {this.props.event.hideCheckBox ? null : (
          <ScheduleCheckBox
            isChecked={this.props.event.isChecked}
            size="w-4 h-4"
          />
        )}
        <div
          className={classNames(
            "ml-1.5 font-weight-200 flex items-center",
            isInReduceSpace ? "font-size-12" : "default-font-size"
          )}
        >
          {this.props.event.hideSlotCount ? null : (
            <>
              <Check
                size={isInReduceSpace ? 14 : 16}
                className="mr-0.5 text-dark-mode-background-color"
              />
              <div className="h-4 text-dark-mode-background-color">
                {this.props.event.slotCount}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  getEventColor() {
    const { event } = this.props;
    if (event.color) {
      return event.color;
    }
    if (getEventColorID(event)) {
      return GoogleColors.primaryEventsColors[getEventColorID(event)].color;
    }
    const { allCalendars } = this.props.allCalendars;
    const userCalendarID = getEventUserCalendarID(event);
    return determineCalendarColor(allCalendars[userCalendarID]);
  }

  renderWarning() {
    const color = this.getEventColor();
    const shouldDim = this.shouldDim();
    return (
      <span
        className="warning-only-attendee"
        style={{
          backgroundColor: this.getIsDarkMode()
            ? this.createDimmedDarkModeColor(color, shouldDim)
            : createFadedColorIfIndexDoesNotExist(color, shouldDim),
          color:
            this.getIsDarkMode() && shouldDim
              ? FADED_GREY_TEXT_COLOR_DARK_MODE
              : null,
        }}
      >
        !
      </span>
    );
  }

  renderHiddenEventBorder() {
    return (
      <div
        className="w-full h-full flex items-center justify-center"
        onContextMenu={this.onContextMenu}
      >
        <div
          id={getEventUserEventID(this.props.event)}
          className="hidden-event-inner-border"
        >
        </div>
      </div>
    );
  }

  isHiddenEvent() {
    return isEventHiddenEvent(this.props.event);
  }

  renderCloseButton() {
    if (this.isTemporaryAIEvent()) {
      return null;
    }

    if (this.props.event.hideCancel) {
      return null;
    }

    return (
      <div
        onClick={this.removeAvailabilityEvent}
        className={classNames(
          "remove-availability-event",
          this.shouldDim() ? "slots-dimmed-background-color" : ""
        )}
      >
        <X size={15} className={classNames("clickable-icon-dark")} />
      </div>
    );
  }

  renderMapTile(displaySummaryAndTimeAsOneLine) {
    const { eventHotKeysIndex } = this.props.eventIndexStore;

    if (
      !!eventHotKeysIndex[getEventUserEventID(this.props.event)] &&
      !isInActionMode(this.props.actionMode)
    ) {
      return (
        <GlobalKeyMapTile
          style={{
            left: this.props.event.displayAsAllDay ? "-8px" : "-12px",
            top: this.getKeyMapPositionTop(displaySummaryAndTimeAsOneLine),
            fontWeight: 400,
            zIndex: 100,
          }}
          shortcut={
            eventHotKeysIndex[getEventUserEventID(this.props.event)].label
          }
        />
      );
    }
  }

  getKeyMapPositionTop(displaySummaryAndTimeAsOneLine) {
    // need to move firefox 16px up
    if (isFirefox()) {
      if (this.props.event.displayAsAllDay) {
        return "11px";
      }

      return displaySummaryAndTimeAsOneLine ? "8px" : "10px";
    }

    return displaySummaryAndTimeAsOneLine ? "-4px" : "-2px";
  }

  getMatchingUIUserForEvent() {
    const { event } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  renderAllDayWorkingLocationEvent() {
    const color = this.getEventColor();
    const backgroundColor = getWorkLocationBackgroundColor({
      color,
      isDarkMode: this.getIsDarkMode(),
    });
    return (
      <div className="flex items-center">
        <div
          className={classNames(
            "h-4",
            "w-max",
            "rounded-3xl",
            "px-1.5",
            "flex items-center",
            "flex-shrink-0"
          )}
          style={{ backgroundColor }}
        >
          {this.renderSingleLineEvent()}
        </div>
        <div className="h-1 w-full" style={{ backgroundColor }}></div>
      </div>
    );
  }

  getEventCalendarColor() {
    const {
      event,
    } = this.props;
    if (event.color) {
      return event.color;
    }
    const {
      allCalendars,
    } = this.props.allCalendars;
    const userCalendarID = getEventUserCalendarID(event);
    const calendarColor = determineCalendarColor(
      allCalendars[userCalendarID]
    );
    return calendarColor;
  }

  renderFreeEventBar() {
    const {
      event,
    } = this.props;
    const calendarColor = this.getEventCalendarColor();
    const getBorderColor = () => {
      if (this.shouldDim()) {
        return getDimmedColor({ color: calendarColor, isDarkMode: this.getIsDarkMode() });
      }
      return calendarColor;
    };
    const isTransparentBackgroundColor = this.isTransparentBackgroundColor();
    const getConditionalBorderWidth = () => {
      if (!isTransparentBackgroundColor) {
        return 1;
      }
      if (event.displayAsAllDay) {
        return 0;
      }
      // not all day
      return this.isTransparentAndMergedEvent() ? 1 : 0;
    };

    const conditionalBorderWidthTopBottomLeft = getConditionalBorderWidth();
    const {
      top,
      bottom,
      left,
      borderTopLeftRadius,
      borderBottomLeftRadius,
    } = this.getLeftBarTopBottomLeftAndBorderRadius();
    const borderColor = getBorderColor();
    return (
      <div
        className="custom-event-special-color-bar"
        style={{
          backgroundColor: getDefaultBackgroundColor(this.getIsDarkMode()),
          borderLeft: `${conditionalBorderWidthTopBottomLeft}px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          borderTop: `${conditionalBorderWidthTopBottomLeft}px solid ${borderColor}`,
          borderBottom: `${conditionalBorderWidthTopBottomLeft}px solid ${borderColor}`,
          width: event.displayAsAllDay && isTransparentBackgroundColor ? 5 : 6,
          left,
          top,
          bottom,
          borderTopLeftRadius,
          borderBottomLeftRadius,
        }}
      ></div>
    );
  }

  renderTentativeSideBar() {
    const calendarColor = this.getEventCalendarColor();
    const color = this.shouldDim()
      ? getDimmedColor({ color: calendarColor, isDarkMode: this.getIsDarkMode() })
      : calendarColor;
    const {
      top,
      bottom,
      left,
      borderTopLeftRadius,
      borderBottomLeftRadius,
    } = this.getLeftBarTopBottomLeftAndBorderRadius();
    return (
      <div
        className="custom-event-special-color-bar"
        style={{
          background: getTentativeSideBarBackgroundStyle({color, isDarkMode: this.getIsDarkMode()}),
          width: 6,
          left,
          top,
          bottom,
          borderTopLeftRadius,
          borderBottomLeftRadius,
        }}
      ></div>
    );
  }

  isDisplayAsAllDay() {
    const {
      event,
    } = this.props;
    return event.displayAsAllDay || isAllDayEvent(event);
  }

  getLeftBarTopBottomLeftAndBorderRadius() {
    const isAllDay = this.isDisplayAsAllDay();
    const isTransparentAndMergedEvent = this.isTransparentAndMergedEvent();
    const getTop = () => {
      if (isTransparentAndMergedEvent && isAllDay && this.isFreeEvent()) {
        return 2;
      }
      return isAllDay ? 1 : 0;
    };
    const getBottom = () => {
      if (isTransparentAndMergedEvent && isAllDay && this.isFreeEvent()) {
        return 3;
      }
      return isAllDay ? 2 : 0;
    };
    const getBorderLeftRadius = () => {
      if (isAllDay && isTransparentAndMergedEvent) {
        return 1;
      }
      return 2;
    };
    const borderLeftRadius = getBorderLeftRadius();
    return {
      top: getTop(),
      bottom: getBottom(),
      left: isAllDay ? 2 : 0,
      borderTopLeftRadius: borderLeftRadius,
      borderBottomLeftRadius: borderLeftRadius,
    };
  }

  renderLeftSideBar() {
    const { event, currentUser } = this.props;
    if (this.isFreeEvent()) {
      return this.renderFreeEventBar();
    }
    if (this.isMergedEventTentative()) {
      return this.renderTentativeSideBar();
    }

    // this is when the event is a different color than the actual calendar that it's associated with
    let color = "transparent";
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const tagColor = getEventTagColor({
      event,
      currentUser,
      user: this.getMatchingUIUserForEvent(),
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    });
    const outlookCategoryColor = determineOutlookCategoryColor({
      allCalendars,
      event,
      outlookCategories,
    });

    const tags = getTagsFromEvent(event);
    if (isOutlookOOOEvent(event)) {
      color = OUTLOOK_OOO_EVENT_COLOR;
    } else if (
      getEventColorID(event) ||
      event.backgroundColor ||
      tags?.length > 0 ||
      tagColor ||
      outlookCategoryColor
    ) {
      const { allCalendars } = this.props.allCalendars;
      const userCalendarID = getEventUserCalendarID(event);
      const shouldDim = this.shouldDim();

      if (allCalendars[userCalendarID]) {
        const calendarColor = this.getEventCalendarColor();
        const firstTag = tags?.[0];
        if (
          calendarColor !== event.normalColor ||
          (calendarColor === event.normalColor &&
            firstTag &&
            getTagColor(firstTag) &&
            getTagColor(firstTag) !== "transparent" &&
            calendarColor !== getTagColor(firstTag)) ||
          calendarColor !== tagColor
        ) {
          // if it's the same as the calendar color, ignore
          color = calendarColor;
          if (shouldDim) {
            color = getDimmedColor({ color, isDarkMode: this.getIsDarkMode() });
          }
        }
      }
    }
    const {
      top,
      bottom,
      left,
      borderTopLeftRadius,
      borderBottomLeftRadius,
    } = this.getLeftBarTopBottomLeftAndBorderRadius();
    return (
      <div
        className={classNames("custom-event-special-color-bar", isOutlookOOOEvent(event) ? "outlook-ooo-side-bar" : "")}
        style={{
          backgroundColor: color,
          left,
          top,
          bottom,
          borderTopLeftRadius,
          borderBottomLeftRadius,
        }}
      ></div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onMouseEnter() {
    if (this.isResponsiveToHoverEventID()) {
      const { setHoveredEventID } = this.props.temporaryStateStore;
      setHoveredEventID(getClientEventID(this.props.event));
      return;
    }

    if (this.isOutstandingSlotEvent()) {
      const { setHoveredSlotsSlug } = this.props.temporaryStateStore;
      setHoveredSlotsSlug(this.props.event.slotSlug);
      return;
    }

    if (
      isTemporaryEvent(this.props.event) ||
      isAvailabilityEvent(this.props.event) ||
      this.props.isMobileView ||
      !this.isMouseHoverAllowed ||
      !this.props.isMouseMovingRef.current.isMouseMoving
    ) {
      return;
    }

    const {
      masterAccount,
    } = this.props.masterAccount;
    if (preventHoverExpandedEvent({user: this.props.currentUser, masterAccount})) {
      return;
    }

    this.setEventOnHoverTimer && clearTimeout(this.setEventOnHoverTimer);
    this.setEventOnHoverTimer = null;

    this.setEventOnHoverTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      const { actionMode } = this.props;
      const { reverseSlotsText } = this.props.temporaryStateStore;
      if (
        isAppInTaskMode({
          actionMode,
          reverseSlotsText,
        }) ||
        shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)
      ) {
        if (this.props.selectedCalendarView === 1) {
          return;
        }

        if (this.props.popupEvent) {
          return;
        }

        this.setUpPopup(this.props.event);
      } else {
        if (this.props.isMobileView) {
          return;
        }

        if (
          isTemporaryEvent(this.props.event) ||
          isAvailabilityEvent(this.props.event)
        ) {
          return;
        }

        this.props.setCurrentHoverEvent(this.props.event);
      }
    }, StyleConstants.timeOnMouseEnterBeforeEvent);
  }

  shouldDisplayEventAsOneLine(minutesDiffBetweenStartAndEnd) {
    if (this.props.event.start && !this.isDisplayAsAllDay()) {
      return (
        differenceInMinutes(this.props.event.end, this.props.event.start) <= 30
      );
    } else {
      return (
        minutesDiffBetweenStartAndEnd <= 30 ||
        this.isDisplayAsAllDay()
      );
    }
  }

  onMouseLeave() {
    if (this.isResponsiveToHoverEventID()) {
      const { resetHoveredEventID } = this.props.temporaryStateStore;
      resetHoveredEventID();
      return;
    }

    if (this.isOutstandingSlotEvent()) {
      const { resetHoveredSlotsSlug } = this.props.temporaryStateStore;
      resetHoveredSlotsSlug();
      return;
    }

    const { shouldStayOpen } = this.props.shouldStayOpen;
    if (shouldStayOpen) {
      return;
    }
    if (!this.props.event.isTemporary) {
      if (!isEventFormShowing()) {
        this.props.removeCurrentHoverEvent();
      }

      if (!this._isMounted) {
        return;
      }

      if (!isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
        this.props.removeHoverPopupEvent();
      }

      if (this.setEventOnHoverTimer) {
        clearInterval(this.setEventOnHoverTimer);

        this.setEventOnHoverTimer = null;
      }
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  getEventTime() {
    const { event, format24HourTime } = this.props;

    const { calculatedDragStart, calculatedDragEnd } = getDragStartAndEnd({
      dragStart: event.start,
      dragEnd: event.end,
      event,
    });

    const startTime = this.getStartTime({
      calculatedDragStart,
      calculatedDragEnd,
    });
    const endTime = format(
      calculatedDragEnd ?? event.eventEnd,
      getDateTimeFormatLowercaseAMPM(format24HourTime)
    );

    return { startTime, endTime };
  }

  getStartTime({ calculatedDragStart, calculatedDragEnd }) {
    const start = calculatedDragStart ?? this.props.event.eventStart;
    const end = calculatedDragEnd ?? this.props.event.eventEnd;

    if (format(start, "aaa") !== format(end, "aaa")) {
      // am and pm do not match
      return format(
        start,
        getDateTimeFormatLowercaseAMPM(this.props.format24HourTime)
      );
    } else {
      return format(
        start,
        this.props.format24HourTime ? DATE_TIME_24_HOUR_FORMAT : "h:mm"
      );
    }
  }

  removeAvailabilityEvent(e) {
    hasStopEventPropagation(e);

    const defaultDeleteSlot = () => {
      if (isAvailabilityPanelShowing()) {
        availabilityBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
          type: DELETE_SLOT,
          originalSlot: this.props.event,
        });
      }

      Broadcast.publish("DELETE_AVAILABILITY_SLOT", this.props.event, true);
    };

    if (isSlotBreakDurationToggleShowing()) {
      defaultDeleteSlot();
    } else if (isGroupVoteEvent(this.props.event)) {
      groupVoteBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
        type: DELETE_SLOT,
        originalSlot: this.props.event,
      });
      availabilityBroadcast.publish(
        AVAILABILITY_BROADCAST_VALUES.DELETE_GROUP_VOTE_EVENT,
        this.props.event,
      );
    } else if (this.props.event.isPersonalLink) {
      Broadcast.publish("DELETE_PERSONAL_LINK_EVENT", this.props.event);
    } else if (isOnboardingMode()) {
      OnboardBroadcast.publish("DELETE_AVAILABILITY_EVENT", this.props.event);
    } else {
      defaultDeleteSlot();
    }
  }

  setUpPopup(event) {
    const eventClientRect = getEventClientRect(event, true);
    if (eventClientRect) {
      this.props.setHoverPopupView({
        location: eventClientRect,
        event: event,
      });
    }
  }

  onClickEvent(e) {
    if (this.props.event.onClickEvent) {
      hasEventPreventDefault(e);
      hasStopEventPropagation(e);
      this.props.event.onClickEvent(this.props.event);
    }
  }

  isAvailabilityEvent() {
    return isAvailabilityEvent(this.props.event);
  }

  isOutstandingSlotEvent() {
    return isOutstandingSlotEvent(this.props.event);
  }

  onContextMenu(e) {
    hasEventPreventDefault(e);
    hasStopEventPropagation(e);

    if (isOnboardingMode()) {
      return;
    }

    if (isInActionMode(this.props.actionMode)) {
      return;
    }

    const eventClientRect = getEventClientRect(this.props.event);
    if (eventClientRect) {
      batch(() => {
        if (!shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
          this.props.setPreviewedEvent(this.props.event);
        }
        if (isPreviewOutlookEvent(this.props.event)) {
          return;
        }

        this.props.setPopupView({
          location: eventClientRect,
          event: this.props.event,
          isColor: true,
          clickLocation: { X: e.pageX, Y: e.pageY },
        });
      });
    }
  }

  createLocationText(event) {
    if (!getEventLocation(event)) {
      return null;
    }

    const location = getEventLocation(event);

    if (!isLocationString(event) || location.length === 0) {
      return null;
    }

    const splitText = location.split(",");
    return splitText[0];
  }

  determineEventInfo() {
    if (
      !!this.props.event.uniqueEtag &&
      !!this._cachedEventInfo[this.props.event.uniqueEtag]
    ) {
      return this._cachedEventInfo[this.props.event.uniqueEtag];
    }

    const email = getEmailBasedOnCalendarId(
      this.props.event,
      this.props.allCalendars.allCalendars
    );

    const selfAttendingStatus = email
      ? getSelfAttendingStatus(this.props.event, email)
      : null;

    const minutesDiffBetweenStartAndEnd = differenceInMinutes(
      this.props.event.eventEnd,
      this.props.event.eventStart
    );

    const displayWarning = isUserOnlyPersonWhoAcceptedAndRestDeclined(
      this.props.event,
      selfAttendingStatus
    );
    const attendees = getHumanAttendees(this.props.event);
    const displaySummaryAndTimeAsOneLine = this.shouldDisplayEventAsOneLine(
      minutesDiffBetweenStartAndEnd
    );

    const isEventAvailibilityEvent = this.isAvailabilityEvent();
    const location = this.createLocationText(this.props.event);

    const result = {
      email,
      selfAttendingStatus,
      minutesDiffBetweenStartAndEnd,
      displayWarning,
      attendees,
      displaySummaryAndTimeAsOneLine,
      isEventAvailibilityEvent,
      location,
    };

    if (this.props.event.uniqueEtag) {
      this._cachedEventInfo[this.props.event.uniqueEtag] = result;
    }

    return result;
  }

  shouldDim() {
    const { event, currentTimeZone } = this.props;
    if (isEmptyObjectOrFalsey(event)) {
      return false;
    }

    if (event.isPersonalLink) {
      return false;
    }

    const { masterAccount } = this.props.masterAccount;

    if (!shouldDimPastEvents({ masterAccount })) {
      return false;
    }

    return isEventInThePast({ event, currentTimeZone });
  }

  shouldRerenderBasedOnDrag(newPropsEvent) {
    const { start, end } = newPropsEvent;

    if (!start && this._lastStartTime) {
      this._lastStartTime = null;
      this._lastEndTime = null;
      return true;
    } else if (start && !this._lastStartTime) {
      this._lastStartTime = start;
      this._lastEndTime = end;
      return true;
    } else if (
      start &&
      this._lastStartTime &&
      !isSameMinute(start, this._lastStartTime)
    ) {
      this._lastStartTime = start;
      this._lastEndTime = end;
      return true;
    } else if (
      end &&
      this._lastEndTime &&
      !isSameMinute(end, this._lastEndTime)
    ) {
      this._lastStartTime = start;
      this._lastEndTime = end;
      return true;
    }

    return false;
  }

  createDimmedDarkModeColor(color, shouldDim) {
    if (!shouldDim) {
      return color;
    }

    return dimDarkModeColor(color);
  }

  shouldAddAdditionalPadding(title) {
    return title && (title[0] === "j" || title[0] === "J");
  }

  determineContainerHeight() {
    if (isAllDayWorkLocationEvent(this.props.event)) {
      return "";
    }
    if (
      this.isTransparentAndMergedEvent() &&
      differenceInMinutes(
        this.props.event.eventEnd,
        this.props.event.eventStart
      ) <= 15
    ) {
      return "custom-event-container-15-min";
    }

    if (this.isTransparentAndMergedEvent()) {
      if (shouldShowReducedHeightTransparentMergedEvent(this.props.event)) {
        return "custom-event-reduced-height";
      }

      return isAllDayEvent(this.props.event)
        ? "regular-custom-event-container margin-top-negative-1px-override"
        : "regular-custom-event-container margin-top-negative-3-override";
    }

    return "regular-custom-event-container";
  }

  allowMouseOverTimer() {
    this._mouseOverTimer && clearTimeout(this._mouseOverTimer);

    this._mouseOverTimer = setTimeout(() => {
      if (this._isMounted) {
        this.isMouseHoverAllowed = true;
      }
    }, 800);
  }

  isFindTimeEventFormEvent() {
    return isFindTimeEventFormEvent(this.props.event);
  }

  isResponsiveToHoverEventID() {
    return this.isTemporaryAIEvent() || this.isFindTimeEventFormEvent();
  }

  getTextColor() {
    const shouldDim = this.shouldDim();
    if (shouldDim) {
      return this.getIsDarkMode()
        ? FADED_GREY_TEXT_COLOR_DARK_MODE
        : FADED_GREY_TEXT_COLOR;
    }
    return getDefaultFontColor(this.getIsDarkMode());
  }

  isFreeEvent() {
    return isFreeEvent(this.props.event);
  }

  isMergedEventTentative() {
    const {
      event,
      currentUser
    } = this.props;
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return isMergedEvent(event) && (getEventStaticInfo({
      event, 
      key: SELF_ATTENDING_STATUS,
      currentUser,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    }) === ATTENDEE_EVENT_TENTATIVE || isOutlookShowAsTentativeEvent(event));
  }
  
  isTransparentBackgroundColor() {
    const {
      event,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const eventBackgroundColor = determineEventBackgroundColor({
      status: getEventStaticInfo({event, key: SELF_ATTENDING_STATUS, allCalendars, currentUser, allLoggedInUsers, masterAccount}),
      color: this.getEventColor(),
      otherStatus: getEventStatus(event),
      isDarkMode: this.getIsDarkMode(),
      onlyPersonAttending: getEventStaticInfo({event, key: ONLY_PERSON_ATTENDING_REST_DECLINED, currentUser, allCalendars, allLoggedInUsers, masterAccount}),
      event,
    });
    return eventBackgroundColor === getDefaultBackgroundColor(this.getIsDarkMode());
  }

  isTransparentAndMergedEvent() {
    return shouldShowTransparentMergedBackground(this.props.event);
  }

  renderRecurringEventIcon({displaySummaryAndTimeAsOneLine, eventDuration}) {
    if (!this.isRecurringEvent()) {
      return null;
    }
    const shouldUseReducedSize = this.shouldReduceTextSize();
    const getVerticalPosition = () => {
      if (this.isDisplayAsAllDay()) {
        return "bottom-5px";
      }
      if (shouldUseReducedSize) {
        return "short-single-line-event-recurring-event-icon-bottom";
      }
      if (displaySummaryAndTimeAsOneLine) {
        if (eventDuration > 15 && eventDuration < 25) {
          return "top-1";
        }
        if (eventDuration >= 25 && eventDuration < 30) {
          return "top-1.5";
        }
        return "bottom-2.5";
      }
      return "bottom-1.5";
    };

    const getIconSize = () => {
      if (this.isDisplayAsAllDay()) {
        return 10;
      }
      if (shouldUseReducedSize) {
        return 8;
      }
      return 10;
    };

    return <RefreshCw size={getIconSize()} className={classNames("opacity-90", "absolute right-1.5", getVerticalPosition())} strokeWidth={1} />;
  }

  isRecurringEvent() {
    return isRecurringEvent(this.props.event);
  }

  getIsDarkMode() {
    const {
      isDarkMode,
    } = this.props;
    if (this._isGroupVotePreview) {
      return false;
    }
    return isDarkMode;
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    hoverPopupEvent,
    popupEvent,
    shouldShowGlobalKeyMap,
    format24HourTime,
    isDarkMode,
    isMobileView,
    selectedCalendarView,
    currentTimeZone,
    actionMode,
  } = state;

  return {
    currentUser,
    hoverPopupEvent,
    popupEvent,
    shouldShowGlobalKeyMap,
    format24HourTime,
    isDarkMode,
    isMobileView,
    selectedCalendarView,
    currentTimeZone,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "SET_CURRENT_HOVER_PREVIEWED_EVENT" }),
    setHoverPopupView: (event) =>
      dispatch({ data: event, type: "SET_HOVER_POPUP_EVENT" }),
    removeHoverPopupEvent: () => dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
    removeCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    setPopupView: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const isMouseMovingRef = useRef(useIsMouseMoving.getState().isMouseMoving);
  // transient update through zustand
  // https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(
    () =>
      useIsMouseMoving.subscribe(
        (isMouseMoving) => (isMouseMovingRef.current = isMouseMoving),
        (state) => state.isMouseMoving
      ),
    []
  );
  const eventIndexStore = useEventHotKeysIndex();
  const allCalendars = useAllCalendars();
  const shouldStayOpen = useCustomEvent();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const masterAccount = useMasterAccount();
  const temporaryStateStore = useTemporaryStateStore();
  const allLoggedInUsers = useAllLoggedInUsers();
  const outlookCategoriesStore = useOutlookCategoriesStore();

  return (
    <BaseComponent
      {...props}
      isMouseMovingRef={isMouseMovingRef}
      eventIndexStore={eventIndexStore}
      allCalendars={allCalendars}
      shouldStayOpen={shouldStayOpen}
      hideRightHandSidebar={hideRightHandSidebar}
      masterAccount={masterAccount}
      temporaryStateStore={temporaryStateStore}
      allLoggedInUsers={allLoggedInUsers}
      outlookCategoriesStore={outlookCategoriesStore}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(CustomEvent));
