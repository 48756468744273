import React, { Component } from "react";
import {
  EVENT_FORM_LOCATION,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import {
  hasStopEventPropagation,
  OpenGoogleMapsLocation,
  handleError,
} from "../services/commonUsefulFunctions";
import GlobalKeyMapTile from "./globalKeyMapTile";
import CopiableContentContainer from "./copiableContentContainer";
import { connect } from "react-redux";
import Broadcast from "../broadcasts/broadcast";
import { MapPin } from "react-feather";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import Classnames from "classnames";
import EditableContentContainer from "./editableContentContainer";
import { isAppInTaskMode } from "../services/appFunctions";
import classNames from "classnames";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { isUrl, truncateString } from "../lib/stringFunctions";

let locationHintStyle = {
  left: "-18px",
  top: "-15px",
};

class EventLocation extends Component {
  constructor(props) {
    super(props);

    this.openInGoogleMaps = this.openInGoogleMaps.bind(this);
    this.onClickEditLocation = this.onClickEditLocation.bind(this);
    this.copyLocation = this.copyLocation.bind(this);

    !this.props.shouldIgnoreBroadcast &&
      Broadcast.subscribe(
        "OPEN_LOCATION_IN_GOOGLE_MAPS",
        this.openInGoogleMaps
      );
    !this.props.shouldIgnoreBroadcast &&
      Broadcast.subscribe("COPY_EVENT_EXPANDED_LOCATION", this.copyLocation);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    !this.props.shouldIgnoreBroadcast &&
      Broadcast.unsubscribe("OPEN_LOCATION_IN_GOOGLE_MAPS");
    !this.props.shouldIgnoreBroadcast &&
      Broadcast.unsubscribe("COPY_EVENT_EXPANDED_LOCATION");
  }

  render() {
    return (
      <div
        className={Classnames(
          "position-relative",
          this.determineWidthClassName()
        )}
      >
        <EditableContentContainer
          style={{ alignItems: "flex-start" }}
          shouldHide={
            this.props.shouldHideEdit || this.props.displayTitleWithCopy
          }
          onClickEdit={this.onClickEditLocation}
        >
          <CopiableContentContainer
            iconMarginLeft={5}
            textToCopy={this.props.location}
            style={{ alignItems: "flex-start" }}
            hotKeys="Y L"
            hotKeyRight={this.props.agendaPanelStyle ? "0px" : null}
            copiableHintRightHandeSide={
              this.props.agendaPanelStyle && this.props.location?.length <= 30
            }
          >
            {!this.props.hideShortCut && (
              <GlobalKeyMapTile
                style={this.props.agendaPanelStyle || locationHintStyle}
                shortcut={this.props.agendaPanelStyle ? "L" : "GL"}
                shouldHide={this.props.shouldHideEdit}
              />
            )}

            <div
              className={Classnames(
                "event-expand-icon-name",
                "align-items-flex-start"
              )}
            >
              {!this.props.hideIcon && (
                <div className="event-expand-icon">
                  <MapPin className="secondary-text-color" size="14" />
                </div>
              )}

              <div
                className={classNames(
                  "cursor-pointer", 
                  isUrl(this.props.location) ? "break-all" : "word-break-break-word", 
                  this.props.agendaPanelStyle ? "" : "default-font-size",
                  this.props.textColorClassName ?? "default-text-color"
                )}
                onClick={this.openInGoogleMaps}
              >
                {isUrl(this.props.location) ? truncateString(this.props.location, 60) : this.props.location}
              </div>
            </div>
          </CopiableContentContainer>
        </EditableContentContainer>

        {!this.props.agendaPanelStyle && !this.props.hideTile && (
          <div className="expanded-view-tile" style={{ right: -25, top: 0 }}>
            <ShortcutTile shortcut={"L"} />
          </div>
        )}
      </div>
    );
  }

  copyLocation() {
    if (this.props.location) {
      navigator.clipboard.writeText(this.props.location).then(
        () => {
          if (!this._isMounted) {
            return;
          }
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            "Copied event location"
          );
          /* clipboard successfully set */
        },
        function (e) {
          handleError(e);
          /* clipboard write failed */
        }
      );
    }
  }

  openInGoogleMaps(e) {
    if (this.props.location) {
      OpenGoogleMapsLocation(this.props.location, this.props.currentUser);
    }

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }

    hasStopEventPropagation(e);
  }

  onClickEditLocation() {
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    if (
      isAppInTaskMode({
        actionMode,
        reverseSlotsText
      })
    ) {
      return;
    }
    Broadcast.publish(
      "GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED",
      EVENT_FORM_LOCATION
    );
  }

  determineWidthClassName() {
    if (this.props.isMobileView) {
      return "w-screen";
    }

    return this.props.agendaPanelStyle ? "agenda-up-next-content-width" : "width-300";
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
  };
}

function mapStateToProps(state) {
  let {
    shouldShowGlobalKeyMap,
    currentUser,
    isMobileView,
    actionMode,
  } = state;

  return {
    shouldShowGlobalKeyMap,
    currentUser,
    isMobileView,
    actionMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const temporaryStateStore = useTemporaryStateStore();

  return (
    <BaseComponent
      {...props}
      temporaryStateStore={temporaryStateStore}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStore(EventLocation));
