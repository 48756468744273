import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { CALENDAR_AUDIT_MODAL_CONTENT } from "../../lib/calendarAudit/variables";
import { FEATURE_TRACKING_ACTIONS, trackFeatureUsage } from "../tracking";
import { getUserToken } from "../../lib/userFunctions";
import { AuditModal, AuditModalData } from "../../lib/calendarAudit/accessors";
import { StoredCalendar } from "../../services/stores/SharedAccountData";
import { HOME_PATH } from "../../services/routingFunctions";

type CalendarAuditConfirmationModalProps = {
  changeCount: number;
  isMounted: boolean;
  modalContent:
    | typeof CALENDAR_AUDIT_MODAL_CONTENT.RETURN_TO_VIMCAL_CONFIRMATION
    | typeof CALENDAR_AUDIT_MODAL_CONTENT.REVERT_CHANGES_CONFIRMATION
    | typeof CALENDAR_AUDIT_MODAL_CONTENT.SWITCH_CALENDARS_CONFIRMATION;
  modalData: AuditModalData;
  setModalContent: (modalContent: AuditModal) => void;
  setSelectedCalendar: (option: {
    label: string;
    value: StoredCalendar | undefined;
  }) => void;
  setProposedChanges: (emptyProposedChanges: Record<string, never>) => void;
};

export default function CalendarAuditConfirmationModal({
  changeCount,
  isMounted,
  modalContent,
  modalData,
  setModalContent,
  setProposedChanges,
  setSelectedCalendar,
}: CalendarAuditConfirmationModalProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();

  const onClose = () => {
    setModalContent(null);
  };

  const { content, label, onConfirm } = (() => {
    switch (modalContent) {
      case CALENDAR_AUDIT_MODAL_CONTENT.RETURN_TO_VIMCAL_CONFIRMATION:
        return {
          content:
            "Are you sure you want to close Executive Calendar Audit? Your existing changes will be lost.",
          label: "Close",
          onConfirm: () => {
            if (!isMounted) {
              return;
            }

            trackFeatureUsage({
              action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::edit_${changeCount}_times`,
              userToken: getUserToken(currentUser),
            });

            setModalContent(null);
            history.push(`/${HOME_PATH}`);
          },
        };
      case CALENDAR_AUDIT_MODAL_CONTENT.REVERT_CHANGES_CONFIRMATION:
        return {
          content: "Are you sure you want to revert all changes?",
          label: "Revert",
          onConfirm: () => {
            if (!isMounted) {
              return;
            }

            trackFeatureUsage({
              action: `${FEATURE_TRACKING_ACTIONS.CALENDAR_AUDIT}::revert`,
              userToken: getUserToken(currentUser),
            });

            setProposedChanges({});
            setModalContent(null);
          },
        };
      case CALENDAR_AUDIT_MODAL_CONTENT.SWITCH_CALENDARS_CONFIRMATION:
        return {
          content:
            "Are you sure you want to switch calendars?  Your existing changes will be lost.",
          label: "Switch",
          onConfirm: () => {
            if (!isMounted) {
              return;
            }

            if (modalData) {
              setSelectedCalendar(modalData);
            }

            setModalContent(null);
          },
        };
    }
  })();

  return (
    <div className="white-mode default-font-size">
      <div>{content}</div>
      <SaveAndCancelButton
        confirmButtonText={label}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </div>
  );
}
