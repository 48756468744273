import React from "react";
import classNames from "classnames";

interface RadioButtonProps {
  id?: string
  isSelected: boolean
  onClick: () => void
  isThinBorder?: boolean
}

export default function RadioButton({ id, isSelected, onClick, isThinBorder = false }: RadioButtonProps) {
  return (
    <button
      id={id}
      tabIndex={-1}
      className={classNames("radio-button", isSelected ? "checked" : "border-transition-200ms-override", isThinBorder && "border-width-1px-important")}
      onClick={onClick}
    >
      <div className={isSelected ? "inner-circular-button" : ""} />
    </button>
  );
}
