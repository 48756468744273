import React, { useEffect } from "react";
import {
  OpenLink,
  isIOS,
  isMobile,
} from "../../services/commonUsefulFunctions";
import { trackEvent } from "../tracking";
import appBroadcast from "../../broadcasts/appBroadcast";
import MobileSignupIcon from "./mobileSignupIcon";
import { DOWNLOAD_IOS_LINK, SECOND_IN_MS } from "../../services/globalVariables";
import SaveButton from "../settings/common/saveButton";
import { sendMobileSignUpEmail } from "./sharedFunctions";
import { useSelector } from "react-redux";
import { blurActiveElement } from "../../services/appFunctions";
import { getUserToken } from "../../lib/userFunctions";
import routeBroadcast from "../../broadcasts/routeBroadcast";
import { ROUTE_BROADCAST_VALUES } from "../../lib/broadcastValues";

export default function MobilePostSignUpPage() {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useSelector((state) => state.masterAccount);
  useEffect(() => {
    appBroadcast.publish("SET_APP_DARK_MODE");
    if (!isMobile()) {
      trackEvent({
        category: "mobile_signup",
        action: "1",
        label: "post signup - not mobile",
        userToken: getUserToken(currentUser),
      });
      routeBroadcast.publish(ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH, masterAccount);
      return;
    }
    trackEvent({
      category: "mobile_signup",
      action: "1",
      label: "post signup - isMobile",
      userToken: getUserToken(currentUser),
    });
    return () => {};
  }, []);

  return (
    <div className="mobile-signup-page-layout">
      <MobileSignupIcon />
      {RenderLoginBox()}
    </div>
  );
}

function RenderLoginBox() {
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <div className="py-8 px-4 download-button-container flex text-center flex-col mt-14 w-80 rounded-2xl">
      <div className="flex flex-col items-center px-8">
        <div className="font-weight-500 font-size-16">Download Vimcal</div>
        <div className="secondary-text-color default-font-size mt-6" >
          We sent you an email with links to download the Desktop app on your
          computer or laptop.
        </div>
      </div>

      {isIOS() ? (
        <div className="flex flex-col items-center mt-8 w-full px-8 mb-8">
          <div className="secondary-text-color default-font-size">
            Need a calendar on the go?
          </div>

          <div
            className="select-none cursor-pointer underline text-color-link default-font-size"
            onClick={() => {
              OpenLink(DOWNLOAD_IOS_LINK);
            }}
          >
            Download Vimcal on iOS
          </div>
        </div>
      ) : null}

      <SaveButton
        label={"Resend email"}
        className="mt-6 justify-content-center-important"
        onClick={() => {
          sendMobileSignUpEmail({user: currentUser});
          setTimeout(() => {
            blurActiveElement();
          }, 0.2 * SECOND_IN_MS);
        }}
      />
    </div>
  );
}
