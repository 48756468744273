import React from "react";
import ColoredLine from "../line";
import classNames from "classnames";
import PerAccountSection from "./perAccountSection";
import broadcast from "../../broadcasts/broadcast";
import appBroadcast from "../../broadcasts/appBroadcast";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import MaestroPerAccountSection from "./maestroPerAccountSection";
import { isDemoAccount } from "../../lib/featureFlagFunctions";
import { BROADCAST_VALUES } from "../../lib/broadcastValues";

const DEFAULT_MARGIN = "mx-4";
export default function AccountDropdown({ onClose }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const onClickAddAccount = () => {
    onClose();
    broadcast.publish(BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS);
  };

  const onClickLogout = () => {
    onClose();
    appBroadcast.publish("CLICK_LOG_OUT");
  };

  return (
    <div className="default-font-size select-none">
      <div className={classNames(DEFAULT_MARGIN, "h-9 flex items-center")}>
        Accounts
      </div>
      <ColoredLine inputClassName="mb-1" />
      <div className={classNames(DEFAULT_MARGIN)}>
        {isUserMaestroUser(masterAccount) ? (
          <MaestroPerAccountSection onClose={onClose} />
        ) : (
          <PerAccountSection onClose={onClose} />
        )}
      </div>
      <ColoredLine inputClassName="my-1" />

      {isDemoAccount(masterAccount) ? null : (
        <div
          className={classNames(
            DEFAULT_MARGIN,
            "default-font-size select-none duration-200 hoverable-secondary-text-color cursor-pointer",
            "h-9 flex items-center"
          )}
          onClick={onClickAddAccount}
        >
          Add another account
        </div>
      )}

      <div
        className={classNames(
          DEFAULT_MARGIN,
          "hoverable-secondary-text-color default-font-size select-none",
          "h-9 flex items-center",
          "mb-1.5"
        )}
        onClick={onClickLogout}
      >
        Log out of all accounts
      </div>
    </div>
  );
}
