import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  isInt,
  KEYCODE_ENTER,
} from "../../../../services/commonUsefulFunctions";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";

export default function DefaultMeetingLength({
  value,
  onSet,
  containerClassName,
}) {
  const [defaultMinutes, setMinutes] = useState(value);
  const [showWarning, setWarning] = useState(false);
  const onKeyDown = (key) => {
    if (key && [KEYCODE_ENTER].includes(key.keyCode)) {
			onSetValue();
    }
  };

  useEffect(() => {
    setMinutes(value);
  }, [value])
  
  const onSetValue = () => {
    const formattedTime = parseInt(defaultMinutes);
    if (!isInt(formattedTime) || defaultMinutes <= 0) {
      setWarning(true);
      setMinutes(value);
      return;
    }
    if (showWarning) {
      setWarning(false);
    }
    onSet(formattedTime);
  };
  return (
    <div>
      <div className={classNames(containerClassName ?? "", "mt-2", "relative")}>
        <div className={"secondary-text-color"}>Default meeting length</div>
        <div className="flex items-center">
          <input
            style={{ width: 50 }}
            className="default-input-field"
            placeholder="30"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            onChange={(e) => setMinutes(getInputStringFromEvent(e))}
            onKeyDown={onKeyDown}
            value={defaultMinutes}
            onBlur={onSetValue}
          />
          <div className="default-font-size secondary-text-color ml-2">min</div>
        </div>
        {showWarning ? (
          <div className="text-red-400 default-font-size absolute right-0 top-8">
            Please enter a number greater than 1
          </div>
        ) : null}
      </div>
    </div>
  );
}
