import classNames from "classnames";
import React from "react";
import {
  SLOTS_LINK_ALONE,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
} from "../../services/globalVariables";

const options = [
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  SLOTS_LINK_ALONE,
];
export default function SelectStyleModal({ onChangeSlotStyle, slotsType }) {
  return (
    <div>
      {options.map((option, index) => {
        const isSelected = slotsType.value === option.value;
        const subTextFirstLine = getOptionSubtext({ option });
        const subTextSecondLine = getOptionSubtextSecondLine({ option });
        return (
          <div
            key={`select-style-type-${option.value}`}
            className={classNames(
              "cursor-pointer flex justify-between items-center",
              "px-3 py-3",
              isSelected
                ? "selected-style-slot-background-color"
                : "modal-text-options-select modal-text-hover-override"
            )}
            onClick={() => onChangeSlotStyle(option)}
          >
            <div className="default-font-size">
              {option.label}
              <div
                className={classNames(
                  "default-font-size mt-1.5",
                  isSelected ? "" : "secondary-text-color"
                )}
              >
                {subTextFirstLine}
              </div>
              {subTextSecondLine ? (
                <div
                  className={classNames(
                    "default-font-size",
                    isSelected ? "" : "secondary-text-color"
                  )}
                >
                  {subTextSecondLine}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function getOptionSubtext({ option }) {
  switch (option) {
    case SLOTS_SELECT_TYPE_PLAIN_TEXT:
      return "Copy your availability in text format";
    case SLOTS_SELECT_TYPE_TEXT_URL:
      return "Include booking link with availabilities";
    case SLOTS_SELECT_TYPE_HYPER_LINKED:
      return "Include beautifully formatted HTML booking link";
    case SLOTS_LINK_ALONE:
      return "Copy just the booking link";
    default:
      return "";
  }
}

function getOptionSubtextSecondLine({ option }) {
  switch (option) {
    case SLOTS_SELECT_TYPE_PLAIN_TEXT:
      return "";
    case SLOTS_SELECT_TYPE_TEXT_URL:
      return "Tip: best for text messages and DMs";
    case SLOTS_SELECT_TYPE_HYPER_LINKED:
      return "Tip: best for emails & Slack";
    case SLOTS_LINK_ALONE:
      return "";
    default:
      return "";
  }
}
