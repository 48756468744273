import classNames from "classnames";
import React from "react";

interface VerticalLineProps {
   className?: string
   style?: React.CSSProperties
}

export function VerticalLine({ className, style }: VerticalLineProps) {
  return (
    <hr
      className={classNames("dash-line h-full w-px border-0", className)}
      style={style}
    />
  );
}
