import React from "react";

import { getEventHoldDetails, getEventHoldLinkable, getEventHoldType } from "../../services/holdFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { HOLD_TYPES } from "../../services/globalMaestroVariables";
import { ExternalLink } from "react-feather";
import { AVAILABILITY_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { ACTION_MODE, GROUP_VOTE_SELECT_OPTION } from "../../services/globalVariables";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import broadcast from "../../broadcasts/broadcast";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

const GroupVoteEditOriginal = (props) => {
  const { event, setActionMode } = props;
  const holdDetails = getEventHoldDetails(event);
  const bookingLink = getEventHoldLinkable(event);

  if (
    isEmptyObjectOrFalsey(holdDetails) ||
    isEmptyObjectOrFalsey(bookingLink) ||
    (
      getEventHoldType(event) !== HOLD_TYPES.GROUP_VOTE_LINK &&
      getEventHoldType(event) !== HOLD_TYPES.GROUP_SPREADSHEET_LINK
    )
  ) {
    return <></>;
  }

  const openBookingEditForm = async () => {
    await setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    broadcast.publish("SET_AVAILABILITY_TYPE", GROUP_VOTE_SELECT_OPTION);
    availabilityBroadcast.publish(
      AVAILABILITY_BROADCAST_VALUES.ON_CLICK_GROUP_VOTE_EDIT,
      bookingLink,
    );
  };

  return (
    <div
      className={
        classNames(
          "flex items-center justify-center",
          "edit-original-event-button",
          "mt-5 select-none duration-200",
        )
      }
      onClick={() => openBookingEditForm()}
    >
      <div
        className="default-font-size mr-4 truncate-text"
        style={{ maxWidth: "75%" }}
      >
        Edit {bookingLink?.title}
      </div>
      <ExternalLink size={16} />
    </div>
  );
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GroupVoteEditOriginal));
