import React from "react";
import { useIsFixedSeating } from "../../hooks";
import DidYouKnowAlert from "../../../availability/alerts/didYouKnowAlert";

interface InviteInfoBoxProps {
  isAddingSeats: boolean
  seatLimitReached: boolean
}

/**
 * Information about why the user can't add more emails or click the next button.
 */
export default function InviteInfoBox({ isAddingSeats, seatLimitReached }: InviteInfoBoxProps) {
  const isFixedSeating = useIsFixedSeating();

  if (!isFixedSeating) {
    return null;
  }

  if (!isAddingSeats && !seatLimitReached) {
    return null;
  }

  const { header, body } = isAddingSeats
    ? { header: "You have exceeded your seat limit", body: "Please remove some invitees to proceed." }
    : { header: "Your seat limit is reached", body: "There are no more seats available on your plan." };

  return <DidYouKnowAlert excludeDefaultMargins className="mb-2" title={header} subText={body} />;
}
