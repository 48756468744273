import create from "zustand";
import { persist } from "zustand/middleware";
import {
	MENU_BAR_TITLE_COUNTDOWN
} from "../../services/globalVariables";

export const useMenuBarDisplaySections = create(
	// keeps track of every calendar in an index
  persist(
    (set) => ({
      menuBarDisplaySections: MENU_BAR_TITLE_COUNTDOWN,
      setMenuBarDisplaySections: (menuBarDisplaySections) => {
        set((state) => ({ menuBarDisplaySections }));
      },
      resetMenuBarDisplaySections: () => set((state) => ({ menuBarDisplaySections: MENU_BAR_TITLE_COUNTDOWN })),
    }),
    {
      name: "menu-bar-display-preference-setting", // unique name
    }
  )
);

export const useIsMenuBarDarkMode = create(
	// keeps track of every calendar in an index
  persist(
    (set) => ({
      isMenuBarDarkMode: false,
      setMenuBarDarkMode: (isMenuBarDarkMode) => {
        set((state) => ({ isMenuBarDarkMode }));
      },
      resetMenuBarDarkMode: () => set((state) => ({ isMenuBarDarkMode: false })),
    }),
    {
      name: "menu-bar-dark-mode-setting", // unique name
    }
  )
);
