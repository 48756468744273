import { getUserEmail } from "./userFunctions";

export function getTeamPlanTeamName(teamPlan: TeamPlan | TruncatedTeamPlan | Record<string, never>) {
  return teamPlan.name ?? "";
}

export function getTeamPlanPhotoUrl(teamPlan: TeamPlan | TruncatedTeamPlan | Record<string, never>) {
  return teamPlan.profile_photo_url ?? "";
}

export function getTeamPlanOwnerUser(teamPlan: TeamPlan | TruncatedTeamPlan | Record<string, never>): TeamPlanMember | undefined {
  return teamPlan.admin_user;
}

export function getTeamPlanOwnerName(teamPlan: TeamPlan | TruncatedTeamPlan | Record<string, never>) {
  return getTeamPlanOwnerUser(teamPlan)?.full_name ?? "";
}

export function getTeamPlanOwnerEmail(teamPlan: TeamPlan | TruncatedTeamPlan | Record<string, never>) {
  return getUserEmail(getTeamPlanOwnerUser(teamPlan));
}

export function getIsDomainCaptureEnabled(teamPlan: TeamPlan | Record<string, never>) {
  return teamPlan.domain_capture ?? false;
}

export function getAvailableDomains(teamPlan: TeamPlan | Record<string, never>) {
  return teamPlan.available_domains ?? [];
}

export function getTeamPlanEASeatCount(teamPlan: TeamPlan | Record<string, never>) {
  return teamPlan.ea_seat_count ?? 0;
}
