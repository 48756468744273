import React, { useEffect } from "react";
import OnboardingSaveButton from "./onboardingSaveButton";
import Mousetrap from "mousetrap";
import { useSelector } from "react-redux";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { COMMAND_CENTER_PNG } from "../../services/globalVariables";
// import OnboardingLoomVideoContainer from "./onboardingLoomVideoContainer";

export default function CommandCenterReminder(props) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], () => props.onClickNext());
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });
    return () => {
      Mousetrap.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="onboarding-container onboarding-with-only-title-and-gif">
      <div className="section-title flex items-center">
        Command Center - 
        <div className="font-weight-200-override">
          Your assistant in Vimcal
        </div>
      </div>

      <div className="welcome-subtitle mt-3 display-flex-flex-direction-row">
        Command Center is the central hub of Vimcal. Quickly access any feature without leaving the keyboard.
      </div>

      <img
        alt=""
        className="gif-tutorial mt-5"
        src={isDarkMode ? COMMAND_CENTER_PNG.DARK_MODE : COMMAND_CENTER_PNG.LIGHT_MODE}
      />
      {/* <OnboardingLoomVideoContainer
        title={"CmdKReminder"}
        inputClassname="mt-5"
        videoURL="https://www.loom.com/embed/47a417a8e7bf4076976476b949a25250"
      /> */}

      <OnboardingSaveButton buttonText="Continue" onClick={props.onClickNext} />
    </div>
  );
}
