import React, { useState } from "react";
import SaveAndCancelButton from "../../../buttons/saveAndCancelButton";
import PlusMinusInput from "../../../inputs/plusMinusInput";
import { getHumanReadableDollarFromCents } from "../../../../lib/stripeFunctions";

interface AddEmptySeatsProps {
  areEAsPresent: boolean
  onClickSave: (options: { defaultVimcalSeats: number, vimcalEASeats: number }) => void
  currentDefaultVimcalSeats: number
  currentVimcalEASeats: number
  onClose: () => void
  vimcalEAUnitPrice: number
  vimcalUnitPrice: number
}

/**
 * The modal content to add empty seats to a team plan.
 */
export default function AddEmptySeats({
  areEAsPresent,
  onClickSave,
  currentDefaultVimcalSeats,
  currentVimcalEASeats,
  onClose,
  vimcalEAUnitPrice,
  vimcalUnitPrice,
}: AddEmptySeatsProps) {
  const [defaultVimcalSeats, setDefaultVimcalSeats] = useState(currentDefaultVimcalSeats);
  const [vimcalEASeats, setVimcalEASeats] = useState(currentVimcalEASeats);

  return (
    <div className="default-font-size select-none pt-2.5">
      <div className="flex flex-col gap-5">
        <EmptySeatInput
          label="Vimcal seats"
          costPerSeat={vimcalUnitPrice}
          seats={defaultVimcalSeats}
          setSeats={setDefaultVimcalSeats}
        />
        {areEAsPresent ? <EmptySeatInput
          label="Vimcal EA seats"
          costPerSeat={vimcalEAUnitPrice}
          seats={vimcalEASeats}
          setSeats={setVimcalEASeats}
        /> : null}
      </div>

      <SaveAndCancelButton
        onClose={onClose}
        onConfirm={() => {
          onClickSave({ defaultVimcalSeats, vimcalEASeats });
          onClose();
        }}
        confirmButtonText="Save"
      />
    </div>
  );
}

interface EmptySeatInputProps {
  seats: number
  setSeats: StateSetter<number>
  label: string
  costPerSeat: number
}

function EmptySeatInput({ seats, setSeats, label, costPerSeat }: EmptySeatInputProps) {
  return (
    <div>
      <div>{label}</div>
      <div className="flex items-center mt-2 gap-4">
        <PlusMinusInput value={seats} setValue={setSeats} />
        <div>
          <div>${getHumanReadableDollarFromCents(costPerSeat * seats)}</div>
          <div className="secondary-text-color">${getHumanReadableDollarFromCents(costPerSeat)} x {seats} seats</div>
        </div>
      </div>
    </div>
  );
}
