import React, { Component } from "react";
import {
  DaysDifferenceJSDate,
  expandedDateAndTimeString,
  FormatTime,
  getMonthDayYearLongForm,
} from "../services/commonUsefulFunctions";
import EditableContentContainer from "../components/editableContentContainer";
import {
  EVENT_FORM_START_DATE_INPUT,
  EVENT_FORM_START_TIME_INPUT,
  EVENT_FORM_END_TIME_INPUT,
  eventFormSectionHotKeysIndex,
} from "../services/globalVariables";
import GlobalKeyMapTile from "./globalKeyMapTile";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import { connect } from "react-redux";
import { doesEventSpanMultipleDays } from "../lib/eventFunctions";
import { removePlusAndCapitalize } from "../lib/stringFunctions";

class ExpandedEventTime extends Component {
  render() {
    const { event, shouldHideEdit, isMobileView } = this.props;

    return event.eventStart ? (
      <div className="expand-event-start-end">
        <div className={isMobileView ? "w-full" : ""}>
          <div className="display-flex-flex-direction-row justify-content-space-between">
            <div className="event-expanded-description-label">Date & Time</div>

            {shouldHideEdit ? null : <ShortcutTile shortcut={"R"} />}
          </div>

          <div className="expand-event-month-and-time-wrapper">
            {this.renderMonthDateYear(event)}

            {this.shouldShowTime(event) ? this.renderTime(event) : null}
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }

  renderTime(event) {
    const {
      shouldHideEdit,
      onClickEditTime,
    } = this.props;
    if (shouldHideEdit) {
      return (
        <div className="whitespace-nowrap">
          <span>
            {FormatTime(event.eventStart, this.props.format24HourTime)}
          </span>
          <span className="mx-1">-</span>
          <span>{FormatTime(event.eventEnd, this.props.format24HourTime)}</span>
        </div>
      );
    }

    return (
      <div>
        <EditableContentContainer
          onClickEdit={onClickEditTime}
        >
          <GlobalKeyMapTile
            style={{ top: "-27px", left: "-15px", fontWeight: 300 }}
            shouldHide={this.props.shouldHideEdit}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_START_TIME_INPUT]
            )}
          />

          <span>
            {FormatTime(event.eventStart, this.props.format24HourTime)}
          </span>

          <span style={{ marginLeft: 3, marginRight: 3 }}>-</span>

          <GlobalKeyMapTile
            shouldHide={this.props.shouldHideEdit}
            style={{ top: "-27px", left: "-15px", fontWeight: 300 }}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_END_TIME_INPUT]
            )}
          />

          <span>{FormatTime(event.eventEnd, this.props.format24HourTime)}</span>
        </EditableContentContainer>
      </div>
    );
  }

  renderMonthDateYear(event) {
    const {
      shouldHideEdit,
      onClickEditDay,
    } = this.props;
    if (shouldHideEdit) {
      return (
        <div
          className={
            this.shouldShowTime(event)
              ? "space-between-date-year-and-time"
              : "space-between-date-year-and-time-all-day"
          }
        >
          {this.determineDateDisplay(event)}
        </div>
      );
    }
    return (
      <div
        className={
          this.shouldShowTime(event)
            ? "space-between-date-year-and-time"
            : "space-between-date-year-and-time-all-day"
        }
      >
        <GlobalKeyMapTile
          style={{ top: "-15px", left: "-18px", fontWeight: 300 }}
          shouldHide={this.props.shouldHideEdit}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_START_DATE_INPUT]
          )}
        />

        <EditableContentContainer
          shouldHide={this.props.shouldHideEdit}
          style={
            this.isDisplayAllDayEventButNotAllDayEvent(event)
              ? { width: 330 }
              : {}
          }
          onClickEdit={() => onClickEditDay()}
        >
          {this.determineDateDisplay(event)}
        </EditableContentContainer>
      </div>
    );
  }

  shouldShowTime(event) {
    return event.allDay || doesEventSpanMultipleDays(event) ? false : !event.displayAsAllDay;
  }

  isDisplayAllDayEventButNotAllDayEvent(event) {
    if (event.displayAsAllDay && !event.allDay) {
      return true;
    }

    if (!event.displayAsAllDay && doesEventSpanMultipleDays(event)) {
      return true;
    }

    return false;
  }

  determineDateDisplay(event) {
    if (this.isDisplayAllDayEventButNotAllDayEvent(event)) {
      return expandedDateAndTimeString(
        event,
        this.props.format24HourTime,
        this.props.dateFieldOrder
      );
    } else {
      return this.displayMonthlyInformation(event);
    }
  }

  displayMonthlyInformation(event) {
    return DaysDifferenceJSDate(
      event.eventStart,
      event.eventEnd,
      this.props.currentTimeZone,
      true
    )
      ? `${getMonthDayYearLongForm({
          date: event.eventStart,
          dateFormat: this.props.dateFieldOrder,
        })} - ${getMonthDayYearLongForm({
          date: event.eventEnd,
          dateFormat: this.props.dateFieldOrder,
        })}`
      : getMonthDayYearLongForm({
          date: event.eventStart,
          dateFormat: this.props.dateFieldOrder,
        });
  }
}

function mapStateToProps(state) {
  let { format24HourTime, dateFieldOrder, isMobileView } = state;

  return {
    format24HourTime,
    dateFieldOrder,
    isMobileView,
  };
}

export default connect(mapStateToProps, null)(ExpandedEventTime);
