import React from "react";
import {
  getColorLabelColorID,
  getColorLabelHexColor,
  getColorLabelLabel,
} from "./colorLabelFunctions";
import { Check } from "react-feather";

export default function ColorLabelPill({ colorLabel, isSelected, onClick }) {
  const color = getColorLabelHexColor(colorLabel);
  const colorName = getColorLabelLabel(colorLabel);
  const size = 15;
  return (
    <div
      className="py-1 px-2 pill-border w-max select-none cursor-pointer"
      onClick={() => onClick(getColorLabelColorID(colorLabel))}
    >
      <div className="flex flex-row items-center">
        <div
          style={{
            backgroundColor: color,
            width: size,
            height: size,
            borderRadius: "50%",
            whiteSpace: "nowrap",
          }}
          className="flex items-center justify-center"
        >
          {isSelected ? (
            <Check size={12} color="white" strokeWidth={2} />
          ) : null}
        </div>

        {colorName ? (
          <div className="ml-2 truncate-text default-font-size font-weight-300 max-width-100px">
            {colorName}
          </div>
        ) : null}
      </div>
    </div>
  );
}
