import React from "react";
import Login from "../js/views/login";
import VimcalEALogoBlueBlock from "../js/components/logo/vimcalEALogoBlueBlock";

export default function VimcalEALoginRightPanel() {
  return (
    <div className="default-font-size flex flex-col items-center justify-center">
      <VimcalEALogoBlueBlock width="124" />
      <div className="bg-white p-8 rounded-xl mt-8">
        <div className="font-size-32 font-weight-600 mb-8 flex justify-center" style={{color: "#151518"}}>Welcome</div>
        <Login />
      </div>
    </div>
  );
}
