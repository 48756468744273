import {
  AGENDA_FORMAT_JS_DATE,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
} from "../services/globalVariables";
import {
  sortEventsJSDate,
  createDateArray,
  setDateInIndex,
  convertToTimeZone,
  GetLetterAndNumberHotKeyCombinations,
  FormatIntoJSDate,
  getEmailBasedOnCalendarId,
  getNextUpcomingEvent,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isAfterMinute,
  isBeforeMinute,
  isSameOrAfterMinute,
  isSameOrBeforeMinute,
  formatEventForReactBigCalendar,
} from "../services/commonUsefulFunctions";
import {
  format,
  isWeekend,
  differenceInCalendarDays,
  set,
  differenceInMinutes,
  getDate,
  getHours,
  getMinutes,
  startOfMinute,
  getYear,
  getMonth,
  parseISO,
  parseJSON,
} from "date-fns";
import _ from "underscore";
import {
  hasEventBeenUpdated,
  isCancelledEvent,
  isEventTooLong,
  isOutlookEvent,
  isValidEvent,
  shouldDisplayEvent,
} from "./eventFunctions";
import {
  getEventUpdatedAt,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import { formatMeetWithEvents } from "../services/meetWithFunctions";
import { isVersionV2 } from "../services/versionFunctions";
import { getConferenceRoomName } from "./conferenceRoomFunctions";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { isEmptyArray } from "./arrayFunctions";

export function createAgendaPanelIndex(data) {
  const { mainCalendarEvents, currentTimeZone } = data;
  const filteredEvents = mainCalendarEvents.filter((e) => {
    return !isEventTooLong(e)
  });

  let activeEvents;

  activeEvents = filteredEvents.filter((e) => {
    return !isCancelledEvent(e) && isValidEvent(e);
  });

  let indexByDate = {};
  let startDate;
  let endDate;
  let listOfDates;
  let duplicateArray = [];
  let timeZone = currentTimeZone;

  activeEvents.forEach((event) => {
    if (!duplicateArray.includes(getEventUserEventID(event))) {
      startDate = format(
        convertToTimeZone(event.defaultStartTime, { timeZone }),
        AGENDA_FORMAT_JS_DATE
      );
      endDate = format(
        convertToTimeZone(event.defaultEndTime, { timeZone }),
        AGENDA_FORMAT_JS_DATE
      );

      // if the dates are not equal, we create an array of dates from the start date to the end date
      // and then we put the event inside every day in the array
      if (startDate !== endDate) {
        listOfDates = createDateArray(
          event.defaultStartTime,
          event.defaultEndTime
        );

        listOfDates.forEach((d) => {
          indexByDate = setDateInIndex(d, indexByDate, event);
        });
      } else {
        indexByDate = setDateInIndex(startDate, indexByDate, event);
      }

      duplicateArray.push(getEventUserEventID(event));
    }
  });

  Object.keys(indexByDate).forEach((day) => {
    indexByDate[day] = indexByDate[day].sort((a, b) => sortEventsJSDate(a, b));
  });

  let result = { indexByDate: indexByDate };

  return result;
}

export function createCurrentWeekEventHotKeys(event) {
  let { events } = event;
  let weekEvents = events;

  const keyCombinations = GetLetterAndNumberHotKeyCombinations();
  let combinationMapping = {};

  keyCombinations.forEach((k, index) => {
    if (index < weekEvents.length) {
      let handler = k[0] + "+" + k[1];
      let sequentialHandler = k[0] + " " + k[1];
      // let combinationHandler = k[0] + '+' + k[1];
      let sequentialHandlerCap = k[0].toUpperCase() + " " + k[1].toUpperCase();
      // let combinationHandlerCap = k[0].toUpperCase() + '+' + k[1].toUpperCase();

      combinationMapping[getEventUserEventID(weekEvents[index])] = {
        handler: handler,
        label: k.toUpperCase(),
        event: weekEvents[index],
        sequentialHandler,
        sequentialHandlerCap,
      };
    }
  });

  return combinationMapping;
}

export function updateListOfEvents(event) {
  let {
    listOfEvents,
    currentEventsArray,
    currentPreviewedEvent,
    currentHoverEvent,
    temporaryEvents,
    showDeclinedEvents,
    currentUserEmail,
    allCalendars,
  } = event;

  const isSameAsTemporaryEvent = (event, temporaryEvents) => {
    return (
      temporaryEvents &&
      temporaryEvents[0] &&
      getEventUserEventID(temporaryEvents[0]) &&
      event &&
      getEventUserEventID(event) === getEventUserEventID(temporaryEvents[0])
    );
  };

  // need to clone since we update reference in each element
  let updatedEventsArray = [...currentEventsArray];

  let eventFormOriginalEvent;

  let temporaryEventsId =
    temporaryEvents?.length > 0
      ? temporaryEvents.map((e) => getEventUserEventID(e))
      : [];

  let formattedEvents = listOfEvents;
  
  const updatedAtIndex = {}; // keeps track of which event has already been updated
  currentEventsArray.forEach((e) => {
    // updated_at is in "2022-11-17T16:29:01.504Z" format so we can just use simple string comparison
    updatedAtIndex[getEventUserEventID(e)] = getEventUpdatedAt(e); 
  });

  let currentPreviewEventUserEventId = !isEmptyObjectOrFalsey(currentPreviewedEvent)
    ? getEventUserEventID(currentPreviewedEvent)
    : null;
  let currentHoverEventUserEventId = !isEmptyObjectOrFalsey(currentHoverEvent)
    ? getEventUserEventID(currentHoverEvent)
    : null;
  let updatedCurrentPreviewEvent;
  let updatedCurrentHoverEvent;

  formattedEvents.forEach((event) => {
    const userEventID = getEventUserEventID(event);
    if (
      // skip since outlook events received via webhook don't have an updated_at field
      !isOutlookEvent(event) &&
      updatedAtIndex[userEventID] &&
      (!getEventUpdatedAt(event) || updatedAtIndex[userEventID] > getEventUpdatedAt(event))
    ) {
      // early return since this event has already been updated
      return;
    }

    if (
      currentPreviewEventUserEventId &&
      getEventUserEventID(event) === currentPreviewEventUserEventId
    ) {
      updatedCurrentPreviewEvent = event;
    }

    if (
      currentHoverEventUserEventId &&
      getEventUserEventID(event) === currentHoverEventUserEventId
    ) {
      updatedCurrentHoverEvent = event;
    }

    let email = getEmailBasedOnCalendarId(event, allCalendars);
    if (!email) {
      email = currentUserEmail;
    }

    if (isSameAsTemporaryEvent(event, temporaryEvents)) {
      // currently the same as event in event form that's being edited
      eventFormOriginalEvent = event;
    } else if (temporaryEventsId.includes(getEventUserEventID(event))) {
      // Do nothing
    } else if (
      shouldDisplayEvent({
        showDeclinedEvents,
        event,
        email,
      })
    ) {
      const matchingEventIndex = updatedEventsArray.findIndex(e => getEventUserEventID(e) === getEventUserEventID(event));
      if (matchingEventIndex >= 0) {
        if (hasEventBeenUpdated(updatedEventsArray[matchingEventIndex], event)) {
          updatedEventsArray[matchingEventIndex] = event;
        }
      } else {
        // add in event
        updatedEventsArray.push(event);
      }
    } else {
      updatedEventsArray = updatedEventsArray.filter(
        (e) => getEventUserEventID(e) !== getEventUserEventID(event)
      );
    }
  });

  return {
    updatedCurrentPreviewEvent,
    updatedCurrentHoverEvent,
    updatedEventsArray,
    originalEventsArray: currentEventsArray,
    eventFormOriginalEvent,
  };
}

export function formatEventsList(params) {
  //input: currentTimeZone = this.props.currentTimeZone, activeCalendars = this.props.activeCalendar, eventFormEmails= this.props.eventFormEmails;
  const {
    calendarEvents,
    currentTimeZone,
    currentUserEmail,
    showDeclinedEvents,
    allCalendars,
  } = params;

  let events = [];

  const formatGCalEventsForReactBigCalendar = (
    events,
    currentTimeZone,
    currentUserEmail,
    showDeclinedEvents
  ) => {
    const activeEvents = events.filter((e) => {
      let email = getEmailBasedOnCalendarId(e, allCalendars);
      if (!email) {
        email = currentUserEmail;
      }

      return shouldDisplayEvent({
        showDeclinedEvents, 
        event: e,
        email,
      });
    });

    return activeEvents.map((e) => {
      return FormatIntoJSDate(e, currentTimeZone);
    });
  };

  const formattedEvents = formatGCalEventsForReactBigCalendar(
    calendarEvents,
    currentTimeZone,
    currentUserEmail,
    showDeclinedEvents
  );

  const duplicateTracker = [];
  formattedEvents.forEach((event) => {
    if (!duplicateTracker.includes(event.uniqueEtag)) {
      duplicateTracker.push(event.uniqueEtag);
      events = events.concat(event);
    }
  });

  return { events: events, currentTimeZone };
}

export function moveEventsWithArrowKey(event) {
  let {
    currentPreviewedEvent,
    formattedEventsWithTemporaryEvents,
    key,
    shouldOnlyShowWorkWeek,
  } = event;

  let result = {};

  if (isEmptyObjectOrFalsey(currentPreviewedEvent)) {
    return getNextEvent(
      formattedEventsWithTemporaryEvents,
      shouldOnlyShowWorkWeek
    );
  }

  switch (key) {
    case ARROW_UP:
      result = goUpAnEvent({
        currentPreviewedEvent,
        formattedEventsWithTemporaryEvents,
        shouldOnlyShowWorkWeek,
      });
      break;
    case ARROW_DOWN:
      result = goDownAnEvent({
        currentPreviewedEvent,
        formattedEventsWithTemporaryEvents,
        shouldOnlyShowWorkWeek,
      });
      break;
    case ARROW_LEFT:
      result = goLeftAnEvent({
        currentPreviewedEvent,
        formattedEventsWithTemporaryEvents,
        shouldOnlyShowWorkWeek,
      });
      break;
    case ARROW_RIGHT:
      result = goRightAnEvent({
        currentPreviewedEvent,
        formattedEventsWithTemporaryEvents,
        shouldOnlyShowWorkWeek,
      });
      break;
    default:
      result = { newEvent: currentPreviewedEvent };
  }

  return result;
}

function goDownAnEvent({
  currentPreviewedEvent,
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek,
}) {
  const workableEvents = getWorkableEvents(
    formattedEventsWithTemporaryEvents,
    shouldOnlyShowWorkWeek
  );
  if (workableEvents.length === 0) {
    return { newEvent: null };
  }

  const sortedEvents = workableEvents.sort((a, b) => sortEventsJSDate(a, b));
  const sortedIndex = sortedEvents.map((s) => {
    return s.uniqueEtag;
  });

  const currentEventLocation = sortedIndex.indexOf(
    currentPreviewedEvent.uniqueEtag
  );
  if (currentEventLocation === -1) {
    const nextEvent = workableEvents.filter((e) =>
      isAfterMinute(e.eventStart, currentPreviewedEvent.eventStart)
    )[0];
    if (nextEvent) {
      // just deleted event -> get event below
      return { newEvent: nextEvent };
    }

    const newEvent = getNextEvent(
      formattedEventsWithTemporaryEvents,
      shouldOnlyShowWorkWeek
    );
    return newEvent;
  }

  const newEvent = sortedEvents[currentEventLocation + 1];
  return { newEvent };
}

function goUpAnEvent({
  currentPreviewedEvent,
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek,
}) {
  const workableEvents = getWorkableEvents(
    formattedEventsWithTemporaryEvents,
    shouldOnlyShowWorkWeek
  );

  if (workableEvents.length === 0) {
    return { newEvent: null };
  }

  const sortedEvents = workableEvents.sort((a, b) => sortEventsJSDate(a, b));
  const sortedIndex = sortedEvents.map((s) => {
    return s.uniqueEtag;
  });

  const currentEventLocation = sortedIndex.indexOf(
    currentPreviewedEvent.uniqueEtag
  );

  if (currentEventLocation === -1) {
    // just deleted event -> get event above
    const filteredEvents = workableEvents.filter((e) =>
      isBeforeMinute(e.eventStart, currentPreviewedEvent.eventStart)
    );
    const previousEvent = filteredEvents[filteredEvents.length - 1];
    if (previousEvent) {
      return { newEvent: previousEvent };
    }
  }

  const newEvent = sortedEvents[currentEventLocation - 1];
  return { newEvent };
}

function goLeftAnEvent({
  currentPreviewedEvent,
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek,
}) {
  const workableEvents = getWorkableEvents(
    formattedEventsWithTemporaryEvents,
    shouldOnlyShowWorkWeek
  );
  if (workableEvents.length === 0) {
    return { newEvent: null };
  }

  let newEvent;

  workableEvents.forEach((e) => {
    if (
      differenceInCalendarDays(e.eventStart, currentPreviewedEvent.eventStart) <
      0
    ) {
      if (!newEvent) {
        newEvent = e;
      } else if (
        differenceInCalendarDays(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) >
        differenceInCalendarDays(
          newEvent.eventStart,
          currentPreviewedEvent.eventStart
        )
      ) {
        // closer date
        newEvent = e;
      } else if (
        getAbsoluteDifferenceInMinutes(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) <
          getAbsoluteDifferenceInMinutes(
            newEvent.eventStart,
            currentPreviewedEvent.eventStart
          ) &&
        differenceInCalendarDays(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) ===
          differenceInCalendarDays(
            newEvent.eventStart,
            currentPreviewedEvent.eventStart
          )
      ) {
        // closer minute to the current event
        newEvent = e;
      }
    }
  });

  return { newEvent };
}

function getWorkableEvents(
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek
) {
  let workableEvents = formattedEventsWithTemporaryEvents || [];
  if (shouldOnlyShowWorkWeek) {
    workableEvents = formattedEventsWithTemporaryEvents.filter(
      (e) => !isWeekend(e.eventStart)
    );
    if (workableEvents.length === 0) {
      return [];
    }
  }

  return workableEvents;
}

function goRightAnEvent({
  currentPreviewedEvent,
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek,
}) {
  const workableEvents = getWorkableEvents(
    formattedEventsWithTemporaryEvents,
    shouldOnlyShowWorkWeek
  );
  if (workableEvents.length === 0) {
    return { newEvent: null };
  }

  let newEvent;

  workableEvents.forEach((e) => {
    if (
      differenceInCalendarDays(e.eventStart, currentPreviewedEvent.eventStart) >
      0
    ) {
      if (!newEvent) {
        newEvent = e;
      } else if (
        differenceInCalendarDays(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) <
        differenceInCalendarDays(
          newEvent.eventStart,
          currentPreviewedEvent.eventStart
        )
      ) {
        newEvent = e;
      } else if (
        getAbsoluteDifferenceInMinutes(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) <
          getAbsoluteDifferenceInMinutes(
            newEvent.eventStart,
            currentPreviewedEvent.eventStart
          ) &&
        differenceInCalendarDays(
          e.eventStart,
          currentPreviewedEvent.eventStart
        ) ===
          differenceInCalendarDays(
            newEvent.eventStart,
            currentPreviewedEvent.eventStart
          )
      ) {
        newEvent = e;
      }
    }
  });

  return { newEvent };
}

function getAbsoluteDifferenceInMinutes(eventA, eventB) {
  // discard the date, only take the absolute difference in minutes
  let timeA = set(new Date(), {
    hours: eventA.getHours(),
    minutes: eventA.getMinutes(),
  });
  let timeB = set(new Date(), {
    hours: eventB.getHours(),
    minutes: eventB.getMinutes(),
  });

  return Math.abs(differenceInMinutes(timeA, timeB));
}

function getNextEvent(
  formattedEventsWithTemporaryEvents,
  shouldOnlyShowWorkWeek
) {
  const workableEvents = getWorkableEvents(
    formattedEventsWithTemporaryEvents,
    shouldOnlyShowWorkWeek
  );
  if (workableEvents.length === 0) {
    return { newEvent: null };
  }

  let newEvent = getNextUpcomingEvent(workableEvents);

  return { newEvent };
}

export function getConferenceRoomAvailability(event) {
  const {
    allRooms,
    roomsAvailbiityObject,
    eventStartTime,
    eventEndTime,
    eventStartDate,
    eventEndDate,
    allDay,
    selectedRoomsArray,
    inputRooms,
    isOutlook,
  } = event;

  const currentTime = new Date();
  const combineDateAndTime = (date, time) => {
    return startOfMinute(
      set(currentTime, {
        year: getYear(date),
        month: getMonth(date),
        date: getDate(date),
        hours: getHours(time),
        minutes: getMinutes(time),
      })
    );
  };

  let availableRooms = [];
  let unavailableRooms = [];

  let eventStart = combineDateAndTime(eventStartDate, eventStartTime);
  let eventEnd = combineDateAndTime(eventEndDate, eventEndTime);

  if (isEmptyArray(allRooms)) {
    return { availableRooms: [], unavailableRooms: [] };
  }

  const checkIfRoomIsAvailable = (email, r) => {
    // check if available during times
    const roomIsUnavailable = roomsAvailbiityObject[email]?.busy?.some((a) => {
      const start = isOutlook ? parseJSON(a.start) : parseISO(a.start);
      const end = isOutlook ? parseJSON(a.end) : parseISO(a.end);

      return isRoomUnavailableDuringTime(
        start,
        end,
        allDay,
        eventStart,
        eventEnd,
      );
    });

    if (roomIsUnavailable) {
      unavailableRooms = unavailableRooms.concat(r);
    } else {
      availableRooms = availableRooms.concat(r);
    }
  };

  allRooms.forEach((r) => {
    const email = r.resourceEmail;

    if (!roomsAvailbiityObject[email]) {
      unavailableRooms = unavailableRooms.concat(r);
    } else if (selectedRoomsArray.includes(r.fullName)) {
      /* Add the room to match Outlook */
      if (isOutlook) {
        checkIfRoomIsAvailable(email, r);
      }
      // Do nothing if Google
    } else if (inputRooms.includes(r.fullName)) {
      availableRooms = availableRooms.concat(r);
    } else if (
      roomsAvailbiityObject[email].busy &&
      roomsAvailbiityObject[email].busy.length === 0
    ) {
      availableRooms = availableRooms.concat(r);
    } else if (
      roomsAvailbiityObject[email].busy?.length > 0
    ) {
      checkIfRoomIsAvailable(email, r);
    } else {
      availableRooms = availableRooms.concat(r);
    }
  });

  const sortRooms = (rooms) => {
    return rooms.slice().sort((a, b) => {
      return getConferenceRoomName(a).localeCompare(getConferenceRoomName(b));
    });
  };

  return {
    availableConferenceRooms: sortRooms(availableRooms),
    unavailableConferenceRooms: sortRooms(unavailableRooms),
  };
}

function isRoomUnavailableDuringTime(
  roomStart,
  roomEnd,
  allDay,
  eventStart,
  eventEnd
) {
  if (allDay) {
    if (
      isSameOrAfterDay(eventStart, roomStart) &&
      isSameOrBeforeDay(eventEnd, roomEnd)
    ) {
      // room: |-------|
      // event:  |--|
      return true;
    } else if (
      isSameOrBeforeDay(eventStart, roomStart) &&
      isSameOrAfterDay(eventEnd, roomEnd)
    ) {
      // room:    |-------|
      // event:|--------------|
      return true;
    } else if (
      isSameOrAfterDay(eventStart, roomStart) &&
      isSameOrBeforeDay(eventStart, roomEnd) &&
      isSameOrAfterDay(eventEnd, roomEnd)
    ) {
      // room: |-------|
      // event:   |---------|
      return true;
    } else if (
      isSameOrBeforeDay(eventStart, roomStart) &&
      isSameOrAfterDay(eventEnd, roomStart) &&
      isSameOrBeforeDay(eventEnd, roomEnd)
    ) {
      // room:         |-------|
      // event: |---------|
      return true;
    } else {
      return false;
    }
  } else {
    //  Not all day
    if (
      isAfterMinute(eventStart, roomStart) &&
      isBeforeMinute(eventEnd, roomEnd)
    ) {
      // room: |-------|
      // event:  |--|
      return true;
    } else if (
      isBeforeMinute(eventStart, roomStart) &&
      isAfterMinute(eventEnd, roomEnd)
    ) {
      // room:    |-------|
      // event:|--------------|
      return true;
    } else if (
      isSameOrAfterMinute(eventStart, roomStart) &&
      isBeforeMinute(eventStart, roomEnd) &&
      isSameOrAfterMinute(eventEnd, roomEnd)
    ) {
      // room: |-------|
      // event:   |---------|
      return true;
    } else if (
      isSameOrBeforeMinute(eventStart, roomStart) &&
      isAfterMinute(eventEnd, roomStart) &&
      isSameOrBeforeMinute(eventEnd, roomEnd)
    ) {
      // room:         |-------|
      // event: |---------|
      return true;
    } else {
      return false;
    }
  }
}

// same as formatFlattendCalendarAndEvents but only using events
export function flattenEventsToUserCalendar(events) {
  const indexByUserCalendarID = {};
  events.forEach((e) => {
    const userCalendarID = getEventUserCalendarID(e);
    if (indexByUserCalendarID[userCalendarID]) {
      indexByUserCalendarID[userCalendarID].push(e);
    } else {
      indexByUserCalendarID[userCalendarID] = [e];
    }
  });
  return indexByUserCalendarID;
}

export function formatFlattendCalendarAndEvents({
  objectOfCalendarAndEvents,
  currentTimeZone,
  shouldFilterForActive,
  id,
  isMeetWithEvents,
  isPreviewOutlookEvent,
}) {
  if (isMeetWithEvents) {
    return formatObjectOfCalendarIdsAndEventsWithoutJson({
      obj: objectOfCalendarAndEvents,
      currentTimeZone,
      shouldFilterForActive,
      id,
      markAsPreviewOutlookEvent: isPreviewOutlookEvent,
    });
  } else {
    return formatObjectOfCalendarIdsAndEventsWithRawJson(
      objectOfCalendarAndEvents,
      currentTimeZone,
      shouldFilterForActive,
      id
    );
  }
}

export function formatGCalEventsList(params) {
  const {
    eventList,
    currentTimeZone,
    color,
    calendarId,
    shouldFilterForActive,
    onlyUseCalendarIdIfEventCalendarIdDoesNoeExist,
    id,
  } = params;

  let updatedEventsList = eventList;

  if (!updatedEventsList || updatedEventsList.length === 0) {
    const emptyResult = { currentTimeZone, formatEventsList: [], id };
    return emptyResult;
  }

  if (shouldFilterForActive) {
    updatedEventsList = updatedEventsList.filter((e) => {
      return !isCancelledEvent(e) && isValidEvent(e);
    });
  }

  let formatEventsList = [];

  updatedEventsList.forEach((e) => {
    let userCalendarId = isNullOrUndefined(calendarId)
      ? getEventUserCalendarID(e)
      : calendarId;
    if (onlyUseCalendarIdIfEventCalendarIdDoesNoeExist) {
      userCalendarId = getEventUserCalendarID(e) || calendarId;
    }

    const formattedE = formatEventForReactBigCalendar({
      event: e,
      currentTimeZone,
      calendarId: userCalendarId,
      color,
    });

    formatEventsList = formatEventsList.concat(formattedE);
  });

  const formattedResult = { currentTimeZone, formatEventsList, id };

  return formattedResult;
}

function formatObjectOfCalendarIdsAndEventsWithoutJson({
  obj,
  currentTimeZone,
  shouldFilterForActive,
  id,
  markAsPreviewOutlookEvent,
}) {
  // This function is only useful for looking up temporary calendars (cmd+j)
  if (isEmptyObjectOrFalsey(obj)) {
    return { currentTimeZone, formattedEventsObject: {}, id };
  }

  let formattedEventsObject = {};

  Object.keys(obj).forEach((k) => {
    let eventsList = obj[k].events;
    let color = obj[k].color;

    if (shouldFilterForActive) {
      eventsList = eventsList.filter((e) => {
        if (isVersionV2()) {
          return !isCancelledEvent(e) && "event_start" in e;
        }
        
        return !isCancelledEvent(e) && "start" in e;
      });
    }

    let formattedEvents = [];
    eventsList.forEach((e) => {
      const userCalendarId = k;

      const formattedE = formatMeetWithEvents({
        event: e,
        currentTimeZone,
        calendarID: userCalendarId,
        color,
        isPreviewOutlookEvent: markAsPreviewOutlookEvent,
      });

      formattedEvents = formattedEvents.concat(formattedE);
    });

    formattedEventsObject[k] = formattedEvents;
  });

  const response = { currentTimeZone, formattedEventsObject, id };

  return response;
}

function formatObjectOfCalendarIdsAndEventsWithRawJson(
  obj,
  currentTimeZone,
  shouldFilterForActive,
  id
) {
  // This function is only useful for looking up calendars (cmd+j)
  if (isEmptyObjectOrFalsey(obj)) {
    return { currentTimeZone, formattedEventsObject: {}, id };
  }

  let formattedEventsObject = {};

  Object.keys(obj).forEach((k) => {
    let eventsList = obj[k];

    if (shouldFilterForActive) {
      eventsList = eventsList.filter((e) => {
        return !isCancelledEvent(e) && isValidEvent(e);
      });
    }

    let formattedEvents = [];
    eventsList.forEach((e) => {
      const userCalendarId = k;

      const formattedE = formatEventForReactBigCalendar({
        event: e,
        currentTimeZone,
        calendarId: userCalendarId,
      });

      formattedEvents = formattedEvents.concat(formattedE);
    });

    formattedEventsObject[k] = formattedEvents;
  });

  let response = { currentTimeZone, formattedEventsObject, id };

  return response;
}
