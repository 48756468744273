import React, { useState, useEffect } from "react";
import CommandCenterContainer from "../commandCenterContainer";
import OnboardingSaveButton from "./onboardingSaveButton";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";

export default function CommandCenterSection(props) {
  const [searchText, setSearchText] = useState("");
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });
  }, []);

  return (
    <div className="onboarding-container">
      <div className="welcome-subtitle command-center-label">
        Try it out! Try typing "Select availability"
      </div>

      <CommandCenterContainer
        handleCloseModal={props.onClickNext}
        shouldShowCommandCenter={true}
        disableSelectOption={true}
        updateSearchText={setSearchText}
      />

      <div className="command-center-onboard-continue-button z-index-9999">
        <OnboardingSaveButton
          buttonText="Continue"
          onClick={props.onClickNext}
        />
      </div>
    </div>
  );
}
