import React, { useState } from "react";
import classNames from "classnames";
import { getNonExpiredSelectedSlotsWithDefaultTimeZone } from "../../lib/availabilityFunctions";
import AvailabilityLink from "../../views/availabilityLink";

export default function GroupVoteCreateEvent({ bookingLink, closeModal, isPreview }) {
  const [nonExpiredEvents] = useState(
    getNonExpiredSelectedSlotsWithDefaultTimeZone(bookingLink)
  );
  const [bookingLinkObj] = useState({
    ...bookingLink,
    isGroupVoteLink: true,
    isCreateGroupEvent: !isPreview,
  });
  if (nonExpiredEvents.length === 0) {
    // expired
    return <AvailabilityLink previewInformation={bookingLinkObj} isPreview={isPreview} />;
  }
  return (
    <div
      className={classNames("availability-link-background", "max-width-95-vw")}
    >
      <AvailabilityLink previewInformation={bookingLinkObj} closeModal={closeModal} isPreview={isPreview} />
    </div>
  );
}
