import _ from "underscore";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { TEAM_PLAN_ENDPOINTS } from "../../lib/endpoints";
import { getAccountState } from "../../lib/stateManagementFunctions";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { constructRequestURLV2, getErrorResponseMessage, isErrorResponse } from "../../services/api";
import { triggerRefreshWithOnlineCheck } from "../../services/appFunctions";
import { handleError } from "../../services/commonUsefulFunctions";
import { fetcherGet, fetcherPatch, fetcherPost } from "../../services/fetcherFunctions";
import { getUserConnectedAccountToken } from "../../services/maestro/maestroAccessors";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { TEAM_ROLES } from "../teamPlans/sharedFunctions";
import {
  ACCOUNT_STATE_TRIAL,
  ACCOUNT_STATE_TRIAL_CHURNED,
  ACCOUNT_STATE_TRIAL_ENDED,
} from "../../lib/vimcalVariables";
import settingsBroadcast from "../../broadcasts/settingsBroadcast";

type CheckEmailResponse = {
  is_user_eligible: boolean
  seat_type: "vimcal" | "vimcal_ea" | null
}

export function checkEmail(currentUser: User, inviteeEmail: string) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.CHECK_EMAIL);
  const payload = {
    body: JSON.stringify({ email: inviteeEmail }),
  };
  return fetcherPost<CheckEmailResponse | ErrorResponse>({
    url,
    payloadData: payload,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });
}

export interface InviteParams {
  vimcalEAEmails?: string[]
  vimcalEASeats?: number
  vimcalEmails?: string[]
  vimcalSeats?: number
}

export function inviteTeamMembers(currentUser: User, {
  vimcalEAEmails=[],
  vimcalEASeats=0,
  vimcalEmails=[],
  vimcalSeats=0,
}: InviteParams) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.INVITE_MEMBERS);
  const payload = {
    body: JSON.stringify({
      team_plan: {
        non_ea_emails: vimcalEmails,
        non_ea_seats: vimcalSeats,
        ea_emails: vimcalEAEmails,
        ea_seats: vimcalEASeats,
      },
    }),
  };
  return fetcherPost<{ team_plan: TeamPlan } | ErrorResponse>({
    url,
    payloadData: payload,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });
}

export async function respondToInvite(currentUser: User | undefined, inviteToken: string, accepting=true) {
  const payloadData = {
    body: JSON.stringify({
      invite_token: inviteToken,
      accepted_invite: accepting,
    }),
  };

  const response = await fetcherPost<{ team_plan: TeamPlan | null } | ErrorResponse>({
    url: constructRequestURLV2(TEAM_PLAN_ENDPOINTS.RESPOND_INVITE),
    payloadData,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });

  return response;
}

type PreviewCostResponse = {
  due_today: number
  new_total: number
  new_seats_total: number
  unit_prices: {
    non_ea?: number
    ea?: number
  }
}

export function previewInviteCost(currentUser: User, {
  vimcalEAEmails=[],
  vimcalEASeats=0,
  vimcalEmails=[],
  vimcalSeats=0,
}: InviteParams) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.PREVIEW_INVITE_COST);
  const payload = {
    body: JSON.stringify({
      team_plan: {
        non_ea_emails: vimcalEmails,
        non_ea_seats: vimcalSeats,
        ea_emails: vimcalEAEmails,
        ea_seats: vimcalEASeats,
      },
    }),
  };
  return fetcherPost<PreviewCostResponse | ErrorResponse>({
    url,
    payloadData: payload,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });
}

interface UpdateUserRoleParams {
  userToken: string
  role: ValueOf<typeof TEAM_ROLES>
}

export function updateUserRole(currentUser: User, { userToken, role }: UpdateUserRoleParams) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.UPDATE_ROLE);
  const payload = {
    body: JSON.stringify({
      team_plan: { new_role: role, user_token: userToken },
    }),
  };
  return fetcherPatch<{ team_plan: TeamPlan } | ErrorResponse>({
    url,
    payloadData: payload,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });
}

export function removeTeamMember(currentUser: User, memberToken: string) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.REMOVE_MEMBER);
  const param = { team_plan: { user_token: memberToken } };
  const payloadData = { body: JSON.stringify(param) };

  return fetcherPost<{ team_plan: TeamPlan }>({
    url,
    payloadData,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  }).then(response => {
    if (isEmptyObjectOrFalsey(response)) {
      throw new Error("Unexpected empty response");
    }

    if (isErrorResponse(response)) {
      const errorMessage = getErrorResponseMessage(response);
      throw new Error(errorMessage);
    }

    const teamPlan = response.team_plan;
    const allLoggedInUsers = useAllLoggedInUsers.getState().allLoggedInUsers;
    if (allLoggedInUsers.some(user => getUserToken(user) === memberToken)) {
      // When a user removes themself from a team plan, refetch all the
      // billing info then refresh the app.
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO).finally(() => {
        // After a user removes themself from a team plan, we should force
        // a refresh so they hit the pay wall.
        triggerRefreshWithOnlineCheck();
      });
      return;
    }

    if (!_.isEqual(teamPlan, useTeamPlan.getState().teamPlan)) {
      useTeamPlan.setState({ teamPlan });
    }

    const masterAccount = useMasterAccount.getState().masterAccount;
    if ([
      ACCOUNT_STATE_TRIAL,
      ACCOUNT_STATE_TRIAL_CHURNED,
      ACCOUNT_STATE_TRIAL_ENDED,
    ].includes(getAccountState(masterAccount) ?? "")) {
      // For trial accounts, removing the member also removes the seat so the billing
      // info will be outdated.
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    }
  }).catch((e) => {
    handleError(e);
    // Rethrow the error to indicate that this request was not successful.
    throw e;
  });
}

export interface TeamPlanSettingsPayload {
  name?: string
  profile_photo_s3_key?: string
  domain_capture?: boolean
  domain_ids?: number[]
}

export async function createTeamPlan(currentUser: User, params: TeamPlanSettingsPayload) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.CREATE_TEAM_PLAN);
  const body = { team_plan: params };
  const payloadData = {
    body: JSON.stringify(body),
  };
  try {
    const response = await fetcherPost<{ team_plan: TeamPlan }>({
      url,
      payloadData,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      return;
    }

    const teamPlan = response.team_plan;
    if (teamPlan) {
      if (!_.isEqual(teamPlan, useTeamPlan.getState().teamPlan)) {
        useTeamPlan.getState().setTeamPlan(teamPlan);
      }
    }

    return teamPlan;
  } catch (e) {
    handleError(e);
    // Rethrow the error to indicate that this request was not successful.
    throw e;
  }
}

export async function patchTeamPlanSettings(currentUser: User, params: TeamPlanSettingsPayload){
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.UPDATE_PROFILE);
  const body = { team_plan: params };
  const payloadData = {
    body: JSON.stringify(body),
  };
  try {
    const response = await fetcherPatch<{ team_plan: TeamPlan }>({
      url,
      payloadData,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      return;
    }

    const teamPlan = response.team_plan;
    if (teamPlan) {
      if (!_.isEqual(teamPlan, useTeamPlan.getState().teamPlan)) {
        useTeamPlan.getState().setTeamPlan(teamPlan);
      }
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    }

    return teamPlan;
  } catch (e) {
    handleError(e);
    // Rethrow the error to indicate that this request was not successful.
    throw e;
  }
}

/**
 * Check to see if there are any team plans the user is eligible to join via domain capture.
 */
export async function checkDomainCapture(currentUser: User) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.CHECK_DOMAIN_CAPTURE);
  try {
    const response = await fetcherGet<{ team_plans: TruncatedTeamPlan[] }>({
      url,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      return;
    }

    return response.team_plans;
  } catch (e) {
    handleError(e);
  }
}

/**
 * Send a request to join a team plan through domain capture.
 */
export async function joinByDomain(currentUser: User, teamPlanToken: string) {
  const url = constructRequestURLV2(TEAM_PLAN_ENDPOINTS.JOIN_BY_DOMAIN);
  const body = { team_plan: { token: teamPlanToken } };
  const payloadData = {
    body: JSON.stringify(body),
  };

  try {
    const response = await fetcherPost<{ team_plan: TeamPlan }>({
      url,
      payloadData,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      throw Error("Unexpected response when joining team plan by domain");
    }

    const teamPlan = response.team_plan;

    return teamPlan;
  } catch (e) {
    handleError(e);
    // Rethrow the error to indicate that this request was not successful.
    throw e;
  }
}
