import React from "react";
import { Star } from "react-feather";
import { PRIORITY_TAG_STYLE } from "../../../../lib/tagsFunctions";

export default function IsPrioritizedTagStar({ className }) {
  return (
    <Star
      size={PRIORITY_TAG_STYLE.SIZE}
      fill={PRIORITY_TAG_STYLE.COLOR}
      color={PRIORITY_TAG_STYLE.COLOR}
      className={className ?? ""}
    />
  );
}
