import React, { PureComponent } from "react";
import { isMac, isOnboardingMode } from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import { X, Square, Minus, ChevronLeft, ChevronRight } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import StyleConstants from "../services/globalVariables";
import classNames from "classnames";
import { useAllLoggedInUsers } from "../services/stores/SharedAccountData";
import { BROADCAST_VALUES } from "../lib/broadcastValues";
import { getUserEmail } from "../lib/userFunctions";
import { isSameEmail, isValidEmail } from "../lib/stringFunctions";

class DesktopTitleBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAccountDivOverflowing: false,
      isMac: isMac(),
    };

    this.checkWindowSize = this.checkWindowSize.bind(this);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.onClickScroll = this.onClickScroll.bind(this);
  }

  checkWindowSize() {
    if (this.accountDiv) {
      const isAccountDivOverflowing =
        this.accountDiv?.offsetHeight < this.accountDiv?.scrollHeight ||
        this.accountDiv?.offsetWidth < this.accountDiv?.scrollWidth;
      this.setState({
        isAccountDivOverflowing,
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.state.isMac) {
      this.checkWindowSize();
      window.addEventListener("resize", this.checkWindowSize);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.buttonPressTimer);
    this.buttonPressTimer = null;

    if (!this.state.isMac) {
      window.removeEventListener("resize", this.checkWindowSize);
    }
  }

  render() {
    return (
      <div
        id={
          this.state.isMac
            ? "desktop-title-header"
            : "windows-desktop-title-header"
        }
        className={classNames(
          "desktop-title-header",
          this.loggedInUserEmail()?.length > 0 ? null : "border-transparent"
        )}
      >
        {this.state.isAccountDivOverflowing
          ? this.renderLeftAccountScroll()
          : null}

        <div
          className="desktop-accounts-container"
          ref={(ref) => (this.accountDiv = ref)}
          style={this.state.isMac ? { flex: 1, justifyContent: "center" } : {}}
        >
          {this.renderAccounts()}
        </div>

        {this.state.isAccountDivOverflowing
          ? this.renderRightAccountScroll()
          : null}

        {this.renderPlusAccount()}
        {!this.state.isMac ? this.renderDraggableSpacer() : null}

        {!this.state.isMac ? this.renderWindowsButtons() : null}
      </div>
    );
  }

  onClickScroll = (shouldInvert) => {
    const accountWidth =
      document.getElementsByClassName("account-container")?.[0]?.offsetWidth;
    const amountToScroll = shouldInvert ? accountWidth * -1 : accountWidth;
    if (this.accountDiv && !isNaN(amountToScroll)) {
      this.accountDiv.scrollLeft += amountToScroll;
    }
  };

  loggedInUserEmail() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    return allLoggedInUsers.map((u) => getUserEmail(u));
  }

  renderAccountsMac({index, e, isCurrentUser, emails}) {
    return (
      <div
        className={classNames(
          "not-draggable-element-area",
          "account-container",
          "truncate-text",
          isOnboardingMode() ? "" : "cursor-pointer",
          "flex justify-center",
          isCurrentUser ? "default-font-color selected-account-wrapper" : "hoverable-tertiary-text-color",
        )}
        key={`desktop_account_${index}`}
        style={{
          width:`calc((100% - 112px)/${emails.length})`,
          maxWidth: `calc((100% - 112px)/${emails.length})`,
          borderColor: this.props.isDarkMode
            ? StyleConstants.darkModeCellBorder
            : StyleConstants.lightGray,

        }}
        onMouseDown={() => this.handleButtonPress(index, e)}
        onMouseUp={() => this.handleButtonRelease(index, e)}
        onMouseLeave={() => this.handleButtonRelease(index, e)}
      >
        <div 
          className={classNames(
            "w-max"
          )}
        >
          {e}
        </div>
      </div>
    );
  }

  renderAccountsWindows({index, e, isCurrentUser}) {
    return (
      <div
        className={classNames(
          "account-container",
          "truncate-text",
          isCurrentUser ? "color-default-text-color" : "light-font-color",
          isOnboardingMode() ? "" : "cursor-pointer",
        )}
        key={`desktop_account_${index}`}
        style={{
          width: "225px",
          borderColor: this.props.isDarkMode
            ? StyleConstants.darkModeCellBorder
            : StyleConstants.lightGray,
        }}
        onMouseDown={() => this.handleButtonPress(index, e)}
        onMouseUp={() => this.handleButtonRelease(index, e)}
        onMouseLeave={() => this.handleButtonRelease(index, e)}
      >
        {e}
      </div>
    );
  }

  renderAccounts() {
    // because fo this electron bug, we need to create a different handler for mac and pc
    // https://github.com/electron/electron/issues/37789
    const emails = this.loggedInUserEmail().filter(e => isValidEmail(e));

    return emails.map((e, index) => {
      const isCurrentUser = isSameEmail(getUserEmail(this.props.currentUser), e);
      if (this.state.isMac) {
        return this.renderAccountsMac({index, e, isCurrentUser, emails});
      }
      return this.renderAccountsWindows({index, e, isCurrentUser});
    });
  }

  renderDraggableSpacer() {
    return <div className="desktop-accounts-draggable-spacer" />;
  }

  renderLeftAccountScroll() {
    return (
      <div
        className="desktop-accounts-overflow-arrows"
        style={{
          borderColor: this.props.isDarkMode
            ? StyleConstants.darkModeCellBorder
            : StyleConstants.lightGray,
        }}
      >
        <ChevronLeft size={13} onClick={() => this.onClickScroll(true)} />
      </div>
    );
  }

  renderRightAccountScroll() {
    return (
      <div
        className="desktop-accounts-overflow-arrows"
        style={{
          borderColor: this.props.isDarkMode
            ? StyleConstants.darkModeCellBorder
            : StyleConstants.lightGray,
        }}
      >
        <ChevronRight size={13} onClick={() => this.onClickScroll(false)} />
      </div>
    );
  }

  renderPlusAccount() {
    if (isOnboardingMode()) {
      return null;
    }
    return (
      this.loggedInUserEmail()?.length > 0 && (
        <div 
          className={classNames(
            "add-account-container", 
            this.state.isMac ? "not-draggable-element-area" : ""
          )} 
          onClick={this.addAccount}
        >
          {this.renderPlusSign()}
        </div>
      )
    );
  }

  renderPlusSign() {
    return <div className="plus-sign-inside-add-account">+</div>;
  }

  renderWindowsButtons() {
    const onClickMinimize = () => {
      if (window?.vimcal?.minimizeMainWindow) {
        window?.vimcal?.minimizeMainWindow();
      }
    };

    const onClickToggleMaximize = () => {
      if (window?.vimcal?.toggleMaximizeMainWindow) {
        window?.vimcal?.toggleMaximizeMainWindow();
      }
    };

    const onClickClose = () => {
      if (window?.vimcal?.closeMainWindow) {
        window?.vimcal?.closeMainWindow();
      }
    };

    return (
      <div id="windows-buttons-container">
        <div className="windows-button" onClick={onClickMinimize}>
          <Minus size={16} />
        </div>
        <div className="windows-button" onClick={onClickToggleMaximize}>
          <Square size={16} />
        </div>
        <div className="windows-button exit-button" onClick={onClickClose}>
          <X size={16} />
        </div>
      </div>
    );
  }

  toggleAccount(index, e) {
    if (isOnboardingMode()) {
      return;
    }
    if (isSameEmail(e, getUserEmail(this.props.currentUser))) {
      return;
    }

    Broadcast.publish("TOGGLE_ACCOUNT", index + 1);
  }

  handleButtonPress(index, e) {
    this.buttonPressTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      clearTimeout(this.buttonPressTimer);

      this.buttonPressTimer = null;
    }, 200);
  }

  handleButtonRelease(index, e) {
    if (this.buttonPressTimer) {
      this.toggleAccount(index, e);
    }

    clearTimeout(this.buttonPressTimer);
    this.buttonPressTimer = null;
  }

  addAccount() {
    Broadcast.publish(BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS);
  }
}

function mapStateToProps(state) {
  let { currentUser, isDarkMode } = state;

  return { currentUser, isDarkMode };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allLoggedInUsers = useAllLoggedInUsers();

  return <BaseComponent {...props} allLoggedInUsers={allLoggedInUsers} />;
};

export default connect(mapStateToProps)(withStore(DesktopTitleBar));
