import React from "react";

const Ellipse = ({ className }) => {
  return (
    <svg
      width="1280"
      height="860"
      viewBox="0 0 1280 860"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        opacity="0.3"
        cx="652.969"
        cy="433.5"
        r="913.224"
        fill="url(#paint0_radial_831_40589)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_831_40589"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(652.969 433.5) rotate(90) scale(913.224)"
        >
          <stop stopColor="#0038FF" />
          <stop offset="1" stopColor="#FF007A" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Ellipse;
