import React from "react";
import { useSelector } from "react-redux";
import CircleWithColor from "../../../circleWithColor";
import { getColorFromID } from "../../../../lib/painterFunctions";
import DropdownIndicator from "../../../select/dropDownIndicator";
import { customMenuStyle, getReactSelectBaseStyle } from "../../../select/styles";
import { CustomSelect } from "../../../select";
import classNames from "classnames";
import PrioritizedStarButton from "./prioritizedStarButton";
import { isPriorityTag } from "../../../../lib/tagsFunctions";
import DefaultSwitch from "../../../defaultSwitch";

const ID = "special-tags-color-picker-switch";
export default function SpecialTagsColorPicker({
  colorOptions,
  colorObject,
  isToggledOn,
  additionalClassName,
  openSection,
  setTagData,
  setToggle,
  tagType, // e.g. SPECIAL_TAGS_TYPE.SOLO
  label, // Solo tag
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const { name, color_id, color } = colorObject;
  return (
    <div
      className={classNames(
        "display-flex-flex-direction-row align-items-center justify-between",
        additionalClassName ?? "",
      )}
    >
      <div className="flex items-center">
        <CustomSelect
          components={{ DropdownIndicator }}
          openMenuOnFocus={true}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
            menuListStyle: customMenuStyle({ width: 180 }),
          })}
          tabSelectsValue={false}
          isSearchable={false}
          className={"select-calendar-color-dropdown"}
          classNamePrefix="dark-mode"
          value={{
            label: (
              <CircleWithColor
                color={getColorFromID({ id: color_id, color })}
              />
            ),
            color: getColorFromID({ id: color_id, color }),
            name: name,
            value: color_id,
          }}
          onChange={(val) => {
            const { value, color } = val;
            const updatedColorData = {
              ...colorObject,
              color_id: value,
              color,
              name: tagType,
            };
            setTagData(updatedColorData);
            openSection();
          }}
          options={colorOptions}
          maxMenuHeight={155}
          alwaysShowIndicator={true}
        />

        <div className="default-font-size ml-5">{label}</div>
      </div>

      <div className="flex items-center">
        <PrioritizedStarButton
          isPrioritized={isPriorityTag(colorObject)}
          onClick={() => {
            const updatedColorData = {
              ...colorObject,
              is_prioritized: !isPriorityTag(colorObject),
            };
            setTagData(updatedColorData);
          }}
          isDisabled={!isToggledOn}
        />
        <DefaultSwitch
          id={ID}
          isChecked={isToggledOn}
          onChange={(isOn) => {
            setToggle(isOn);
            openSection();
          }}
        />
      </div>
    </div>
  );
}
