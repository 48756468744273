import { isMac } from "../services/commonUsefulFunctions";

// used for getting event from onKeyDown
export function isKeyDownCommandOrCtrl(key) {
  // return false
  if (!key) {
    return false;
  }
  if (isMac()) {
    return key.metaKey;
  }
  return key.ctrlKey;
}

export const HOT_KEY_MAP = {
  NEXT_WEEK: "I",
  LAST_WEEK: "U",
};
