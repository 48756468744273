import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import {
  constructQueryParams,
  createSlotsForSelection,
  createFreeSlotsBasedOnBusySlots,
  sortTimeSlots,
  SortArrayByDate,
  OpenLink,
  addAbbrevationToTimeZone,
  handleError,
  guessTimeZone,
  reformatMinDuration,
  determineDurationString,
  generateBookableSlotsFromObj,
  generateFreeSlotsFromBusySlots,
  getAllBusySlots,
  generateRandomId,
  checkIfBookableSlotsAreValid,
  convertToTimeZone,
  mergeBusySlots,
  isMobile,
  isOnboardingMode,
  shouldRoundToNearest15,
  addBufferToEvents,
  sendMessageToSentry,
  isValidTimeZone,
} from "../services/commonUsefulFunctions";
import ColoredLine from "../components/line";
import {
  Clock,
  MapPin,
  Video,
  Globe,
  Check,
  Calendar,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  X,
} from "react-feather";
import Classnames from "classnames";
import _ from "underscore";
import AvailabilityMonthlyCalendar from "../components/availabilityMonthlyCalendar";
import {
  createConferenceData,
  conferencingDescriptor,
  BACKEND_NO_CONFERENCING,
  BACKEND_ZOOM,
  BACKEND_HANGOUT,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  GOOGLE_UPDATES,
  getVimcalRichTextSignature,
} from "../services/googleCalendarService";
import {
  zoomImageURL,
  hangoutIconURL,
  PHONE_MEETING_ICON,
  INVITEE_NAME_BLOCK,
  NEEDS_ACTION_STATUS,
  ACCEPTED_STATUS,
  WHATS_APP_ICON,
  VIMCAL_LOGO_SVG,
  BACKEND_PERSONAL_LINK,
  BACKEND_AVAILABILITY,
  UTC_TIME_ZONE,
  DEFAULT_FONT_SIZE_PX,
  DEFAULT_BLUE,
} from "../services/globalVariables";
import { addMinutes, format, subMinutes } from "date-fns";
import GroupVotePreview from "../components/scheduling/groupVotePreview";
import {
  convertSlotsIntoISOString,
  determineBookingURL,
  getNonExpiredSelectedSlots,
  getSlotsPath,
  isNameOrEmailQuestion,
} from "../lib/availabilityFunctions";
import { useGroupVoteStore } from "../services/stores/settings";
import classNames from "classnames";
import PhoneNumber from "awesome-phonenumber";
import CustomSelect from "../components/select";
import {
  useZoomSchedulers,
  useMasterAccount,
  useAllLoggedInUsers,
} from "../services/stores/SharedAccountData";
import VimcalLogoWithMessage from "../components/onboarding/vimcalLogoWithMessage";
import { createUniqueZoom, getZoomSchedulers } from "../services/zoomFunctions";
import { trackError, trackEvent } from "../components/tracking";
import { getPersonalOnboardingSpecialist } from "../components/onboarding/sharedFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { getOutlookConferencingIconURL, isOutlookConferencingOption } from "../lib/outlookFunctions";
import { getProfilePhotoUrl, getSelectedUserName, getSocialLinks, getUserEmail, getUserName, getUserToken } from "../lib/userFunctions";
import Spinner from "../components/spinner";
import { isSelfServeOpen } from "../lib/featureFlagFunctions";
import HoverableLogo from "../components/hoverableLogo";
import onboardBroadcast from "../broadcasts/onboardBroadcast";
import ContactHandles from "../components/contact/contactHandles";
import { isSpreadsheetGroupLink } from "../lib/groupVoteFunctions";
import GroupVoteSpreadSheet from "../components/scheduling/groupVoteSpreadSheet";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEveryWord,
  getInputStringFromEvent,
  isSameEmail,
  isValidEmail,
} from "../lib/stringFunctions";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getReactSelectBaseStyle } from "../components/select/styles";
import { BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY } from "../lib/personalLinkFunctions";
import LoadingSkeleton from "../components/loadingSkeleton";
import DidYouKnowAlert from "../components/availability/alerts/didYouKnowAlert";

const TIME_FORMAT = "h:mma";
const BACKEND_PERSONAL = "personal";

const PAST_DAY = {
  title: "Expired link",
  subText:
    "Looks like the date you selected is in the past. Please try another one.",
};
const SELECTED_DAY_UNAVAILABLE = {
  title: "Selected day is no longer available",
  subText:
    "Looks like the date you selected has all its slots booked already. Please try another one.",
};

const MOBILE_WIDTH_LIMIT = 600;
const TIME_BLOCK_FORMAT = "EEEE MMMM d, yyyy";

class AvailabilityLink extends Component {
  constructor(props) {
    super(props);

    let index = this.getIndex();

    this._hasClickedSchedule = false; // used to track double click

    this.formRef = React.createRef();

    const isInOnboarding = isOnboardingMode();
    const { masterAccount } = props.masterAccount;

    const { token, photo } = getPersonalOnboardingSpecialist(masterAccount);

    this.state = {
      title: null,
      conferencing: null,
      isSubmitting: false, // need both this and isSubmitting to prevent double click and to show loading (cause re-render)
      location: null,
      duration: null,
      freeBusy: null,
      user: null,
      isAllBooked: false,
      expired: false,
      error: false,
      daySlots: {},
      selectedDay: null,
      selectedTime: null,
      hoverDay: null,
      onClickedConfirmTime: false,
      hasCapitalizedFirstLetter: false,
      showSentConfirmationEmail: false,
      nameHasIssue: false,
      emailHasIssue: false,
      shouldDisplayRequestAccessEmailSent: false,
      shouldShowMobileSelectTime: false,
      inputName: props.inputName ?? "",
      inputEmail: props.inputEmail ?? "",
      currentDayIndex: [],
      isMobile: isMobile(),
      isOnboardingMode: isInOnboarding,
      token: isInOnboarding ? token : props.match.params.token, // if onboarding, use sophie's personal link
      // token: isInOnboarding ? "b7c5633064531f2f15ffba78" : props.match.params.token, // https://book.vimcal.com/p/b7c5633064531f2f15ffba78 (for testing)
      index: index ? parseInt(Number(index)) : 0,
      errorWarning: null,
      availabilityEvents: null,
      zoomLink: null,
      isPreview: false,
      isPreviewExpired: false,
      timeSlotsInfo: null,
      host_time_zone: guessTimeZone(),
      newAttendeeSlots: [],
      customQuestions: this.props.previewInformation?.custom_questions ?? [],
      isPersonalLink: isInOnboarding ?? false,
      finishedLoadingData: false,
      onboardingSpecialistPhoto: photo,
      userCalendarId: this.props.previewInformation?.user_calendar_id ?? null,
      selectedTimeZone: this.props.currentTimeZone ?? guessTimeZone(),
      regionCodeOptions: this.createRegionCode(),
    };

    this.fetchAvailability = this.fetchAvailability.bind(this);
    this.book = this.book.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterDaySlot = this.onMouseEnterDaySlot.bind(this);
    this.onClickConfirmOnTime = this.onClickConfirmOnTime.bind(this);
    this.onClickBackFromConfirmNameAndEmail =
      this.onClickBackFromConfirmNameAndEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClickSchedule = this.onClickSchedule.bind(this);
    this.goToVimcalWebsite = this.goToVimcalWebsite.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.onClickBackFromSelectTime = this.onClickBackFromSelectTime.bind(this);
    this.goBackADay = this.goBackADay.bind(this);
    this.goForwardADay = this.goForwardADay.bind(this);
    this.closeWarningMessage = this.closeWarningMessage.bind(this);
    this.createPreviewLink = this.createPreviewLink.bind(this);
    this.createPersonalLinkPreviewData =
      this.createPersonalLinkPreviewData.bind(this);
    this.onClickDaySlot = this.onClickDaySlot.bind(this);
    this.updateCustomQuestion = this.updateCustomQuestion.bind(this);
    this.onClickLogoOnConfirmationPage = this.onClickLogoOnConfirmationPage.bind(this);

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchInitialData();

    if (this.state.isOnboardingMode) {
      trackEvent({
        category: "onboarding",
        action: "0_rendered_book_personal_onboarding",
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.getUser()),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this?.formRef?.current) {
      // We currently assign the formRef to the top of the form.
      // For overflowed forms it auto scrolls to the bottom on
      // load so we scroll to the top of the form upon update.
      this.formRef.current.scrollIntoView();
    }

    if (!isSelfServeOpen() && prevState.showSentConfirmationEmail !== this.state.showSentConfirmationEmail) {
      // only show back button if selfserve is not open
      onboardBroadcast.publish("SHOW_BACK_BUTTON_IN_ONBOARDING");
    }

    if (prevProps.updateCount !== this.props.updateCount) {
      this.fetchInitialData();
    }

    if (prevProps.customQuestionCounter !== this.props.customQuestionCounter
      && this.props.previewInformation?.custom_questions?.length > 0
    ) {
      // update custom questions, we don't need to refetch initial data
      this.setState({
        customQuestions: this.props.previewInformation.custom_questions,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.disappearingErrorMessageTimer);
    this.disappearingErrorMessageTimer = null;

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    return (
      <div
        className={classNames(
          "availability-link-background",
          "rounded-xl",
          this.state.isOnboardingMode || this.isGroupVoteView()
            ? ""
            : "height-100vh",
          "light-mode-scroll-important"
        )}
        style={
          this.state.isPreview
            ? {
                height: this.state.isOnboardingMode ? null : "100%",
                flexDirection: "column",
              }
            : {}
        }
      >
        {this.renderGreeting()}

        {this.renderLinkContainer()}
      </div>
    );
  }

  renderLoadingScreen() {
    return (
      <div className="cursor-pointer width-100-percent display-flex justify-content-center align-items-center position-relative">
        <div style={{ position: "absolute", top: 200 }}>
          <div className={"spin"}></div>
        </div>

        <div style={{ position: "absolute", top: 380 }}>
          <VimcalLogoWithMessage />
        </div>
      </div>
    );
  }

  renderGreeting() {
    if (!this.shouldDisplayWelcomeInviteeBanner()) {
      return null;
    }
    const {
      masterAccount
    } = this.props.masterAccount;

    let firstName = this.state.initialInputName.split(" ")[0];
    const {
      firstName: userFirstName
    } = getUserName({masterAccount, user: this.getUser()});

    let greeting = `Hi ${capitalizeFirstLetter(
      firstName
    )}! Please select the best time for you to meet with ${userFirstName}.`;
    if (!userFirstName) {
      greeting = `Hi ${capitalizeFirstLetter(
        firstName
      )}! Please select the best time for you to meet.`;
    }

    return (
      <div
        className="availability-custom-name-banner"
      >
        {greeting}
      </div>
    );
  }

  shouldDisplayWelcomeInviteeBanner() {
    return !(
      this.state.expired ||
      this.state.isAllBooked ||
      this.state.error ||
      this.state.showSentConfirmationEmail ||
      this.state.onClickedConfirmTime ||
      (this.state.isMobile && this.state.shouldShowMobileSelectTime) ||
      !this.state.initialInputName
    );
  }

  renderLinkContainer() {
    const {
      isInEditPersonalLink,
    } = this.state;
    return (
      <div
        className="availability-link-container"
        style={
          this.state.isMobile
            ? {
                width: isInEditPersonalLink ? "100%" : "100vh",
                height: isInEditPersonalLink ? "100%" : "100vh",
                justifyContent: "center",
              }
            : this.determineContainerStyle()
        }
      >
        {this.determineContent()}
      </div>
    );
  }

  renderShowError() {
    return this.renderShowErrorMessage("An error has occurred!");
  }

  renderShowPreviewError() {
    return (
      <div className="availability-error-container">
        <div style={{ fontSize: 12, fontWeight: 300, marginBottom: 70 }}>
          {"Event creation does not work in preview mode."}
        </div>

        {this.renderVimcalLogoWithMessage()}
      </div>
    );
  }

  renderShowExpired() {
    return this.renderShowErrorMessage("The link you clicked on has expired!");
  }

  renderShowErrorMessage(message = "An error has occurred!") {
    return (
      <div className="availability-error-container">
        <div style={{ fontSize: 24, marginBottom: 5 }}>Oh no!</div>

        <div style={{ fontSize: 12, fontWeight: 300, marginBottom: 70 }}>
          {message}
        </div>

        {this.renderVimcalLogoWithMessage()}
      </div>
    );
  }

  renderConfirmationEmail() {
    return (
      <div
        className="color-default-text-color position-relative entire-availability-container-size"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflowY: "auto",
          width: this.state.isMobile ? "90%" : null,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 60 }}>
          Confirmed!
        </div>

        {this.state.user && (
          <div
            className="text-center"
            style={{
              fontSize: 14,
              fontWeight: 300,
              marginTop: 3,
            }}
          >
            {`You are scheduled with ${
              getSelectedUserName({user: this.state.user}).fullName || this.state.user.email
            }`}
          </div>
        )}

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "75%" }}>{this.renderEventInfoWithIcons("confirmed-page-event-detail-width", "flex flex-col items-center justify-center")}</div>
        </div>

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 40,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        {this.renderRequestAccess()}

        <div
          className="availability-vimcal-logo-display"
          style={{ position: "absolute", bottom: 20, right: 20 }}
        >
          {this.renderVimcalLogoWithMessage("Powered by")}
        </div>
      </div>
    );
  }

  onClickLogoOnConfirmationPage() {
    if (this.props.onClickTryUsOut) {
      this.props.onClickTryUsOut();
    } else {
      OpenLink("https://vimcal.com");
    }
  }

  renderRequestAccess() {
    if (this.state.shouldDisplayRequestAccessEmailSent) {
      return null;
    }

    if (!isSelfServeOpen()) {
      return (
        <HoverableLogo />
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          className="text-center"
          style={{ fontSize: 12, fontWeight: 300 }}
        >
          The world's fastest calendar, beautifully designed for a remote
          world
        </div>

        <div
          className="start-free-trial-button mt-8 transition-shadow duration-200 hover:shadow-2xl"
          onClick={this.onClickLogoOnConfirmationPage}
        >
          {this.props.customOnSuccessButtonCopy ?? "Check Us Out"}
        </div>
      </div>
    );
  }

  renderVimcalLogoWithMessage(message = null) {
    return null;
    return (
      <div
        className="display-flex-flex-direction-row cursor-pointer"
        onClick={this.goToVimcalWebsite}
      >
        <div className="margin-right-5 padding-top-3">
          <img alt="" width="32px" height="32px" src={VIMCAL_LOGO_SVG} />
        </div>

        <div>
          <div className="default-font-size font-weight-300">
            {message || "Try us out!"}
          </div>

          <div style={{ fontSize: 14, fontWeight: 500 }}>Vimcal</div>
        </div>
      </div>
    );
  }

  renderBackButton(onClickHandler) {
    return (
      <div
        className="availability-back-button-container"
        onClick={onClickHandler}
      >
        <div className="availability-back-button">
          <ArrowLeft size={15} color={"#4E516A"} />
        </div>
      </div>
    );
  }

  createRegionCodeText(regionCode) {
    return `${regionCode} (+${PhoneNumber.getCountryCodeForRegionCode(
      regionCode
    )})`;
  }

  updateCustomQuestion(updates, customQuestionIndex) {
    let customQuestionsCopy = [...this.state.customQuestions];
    let customQuestion = {
      ...this.state.customQuestions[customQuestionIndex],
      ...updates,
    };
    customQuestionsCopy[customQuestionIndex] = customQuestion;
    this.setState({ customQuestions: customQuestionsCopy });
  }

  renderCustomQuestions() {
    const questionHasError = (question) => {
      return (
        this.state?.customQuestionsHasIssue &&
        question.required &&
        !question?.answer
      );
    };

    if (
      this.state?.customQuestions &&
      !isEmptyObjectOrFalsey(this.state.customQuestions)
    ) {
      const filteredQuestions = this.state.customQuestions.filter(q => !isNameOrEmailQuestion(q)); // filter out mandatory questions
      return filteredQuestions.map((question, questionIndex) => {
        // We expect that the first two questions are name and email which are already displayed above
        // TODO: if we can guarantee elsewhere that name and email will always be the first two custom questions
        // then remove above and remove the if >= 2
        switch (question.type) {
          case "multiline":
            return (
              <div
                className="mt-5 w-full"
                key={`custom_question_index_${questionIndex}`}
              >
                <div>
                  {question.description}
                  {question.required ? " *" : ""}
                </div>

                <textarea
                  className="multiline-input"
                  ref={(element) => {
                    // there is a dark-mode !important color property that needs to be overridden here
                    if (element)
                      element.style.setProperty(
                        "color",
                        "black",
                        "important"
                      );
                  }}
                  value={
                    this.state.customQuestions[questionIndex]?.answer || ""
                  }
                  onChange={(e) =>
                    this.updateCustomQuestion(
                      { answer: getInputStringFromEvent(e) },
                      questionIndex
                    )
                  }
                />
                {questionHasError(question) &&
                  this.renderWarning("*This field cannot be empty")}
              </div>
            );
          case "phoneNumber":
            return (
              <div
                className="mt-5 w-full"
                key={`custom_question_index_${questionIndex}`}
              >
                <div>
                  {question.description}
                  {question.required ? " *" : ""}
                </div>
                <div className="display-flex-flex-direction-row justify-between	mt-2">
                  <CustomSelect
                    value={{
                      label: this.createRegionCodeText(
                        question?.regionCode || "US"
                      ),
                      value: question?.regionCode || "US",
                    }}
                    onChange={(data) =>
                      this.updateCustomQuestion(
                        { regionCode: data.value },
                        questionIndex
                      )
                    }
                    options={this.state.regionCodeOptions}
                    isSearchable
                    className={classNames(
                      "select-region-code",
                      this.props.isDarkMode ? "dark-mode-select" : ""
                    )}
                    maxMenuHeight={144}
                    overrideStyles={getReactSelectBaseStyle({
                      isDarkMode: false, // always show white mode
                      showBorder: true,
                      valueContainerHeight: 38
                    })}
                  />

                  <input
                    className="availability-input"
                    style={{ paddingLeft: 10, marginLeft: "10px" }}
                    value={
                      this.state.customQuestions[questionIndex]?.answer || ""
                    }
                    onChange={(e) =>
                      this.updateCustomQuestion(
                        { answer: getInputStringFromEvent(e) },
                        questionIndex
                      )
                    }
                    placeholder="e.g. 415-123-4567"
                  />
                </div>
                {questionHasError(question) &&
                  this.renderWarning("*This field cannot be empty")}
              </div>
            );
          case "singleLine":
          default:
            return (
              <div
                className="mt-5 w-full"
                key={`custom_question_index_${questionIndex}`}
              >
                <div>
                  {question.description}
                  {question.required ? " *" : ""}
                </div>

                <input
                  className="availability-input mt-2"
                  value={
                    this.state.customQuestions[questionIndex]?.answer || ""
                  }
                  onChange={(e) =>
                    this.updateCustomQuestion(
                      { answer: getInputStringFromEvent(e) },
                      questionIndex
                    )
                  }
                />
                {questionHasError(question) &&
                  this.renderWarning("*This field cannot be empty")}
              </div>
            );
        }
      });
    } else {
      return <></>;
    }
  }

  renderEnterNameAndEmail() {
    const determineLabel = () => {
      if (this.state.isSubmitting) {
        return <Spinner useSmallSpinner={true} className="absolute -top-4" />;
      }
      return "Schedule Event";
    };
    return (
      <div
        className={classNames(
          "availability-enter-name-email-container position-relative"
        )}
        style={this.state.isMobile ? { marginTop: 15 } : {}}
      >
        <div className="width-100-percent">
          <div
            className="flex justify-start font-size-400 font-size-14"
            ref={this.formRef}
          >
            Enter Details
          </div>
        </div>

        <div className="width-100-percent font-size-14">
          <div style={{ marginTop: 30, width: "100%" }}>
            <div className="mb-2">Name *</div>

            <input
              className="availability-input"
              autoFocus={true}
              value={this.state.inputName}
              onChange={this.onChangeName}
            />

            {this.state.nameHasIssue &&
              this.renderWarning("*Please enter your name")}
          </div>

          <div style={{ marginTop: 20, width: "100%" }}>
            <div className="mb-2">Email *</div>

            <input
              className="availability-input"
              value={this.state.inputEmail}
              onChange={this.onChangeEmail}
            />

            {this.state.emailHasIssue &&
              this.renderWarning("*Please enter a valid email")}
          </div>

          {this.renderCustomQuestions()}
        </div>

        <div
          className="availability-schedule-event-button select-none sticky bottom-5"
          onClick={this.onClickSchedule}
        >
          {determineLabel()}
        </div>
      </div>
    );
  }

  renderWarning(warningString) {
    return (
      <div
        className="event-form-different-time-zone-warning"
        style={{ fontSize: 12, width: "100%" }}
      >
        {warningString}
      </div>
    );
  }

  renderErrorWarning() {
    if (!this.state.errorWarning) {
      return null;
    }

    return (
      <div
        className="availability-display-error-warning-container"
        style={
          this.state.isMobile
            ? { borderRadius: 0 }
            : {
                top: this.shouldDisplayWelcomeInviteeBanner() ? -68 : 0,
                zIndex: 1,
              }
        }
      >
        <div
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
          onClick={this.closeWarningMessage}
        >
          <X />
        </div>

        <div style={{ fontSize: 16 }}>{this.state.errorWarning.title}</div>

        <div style={{ fontSize: 14, marginTop: 8 }}>
          {this.state.errorWarning.subText}
        </div>
      </div>
    );
  }

  renderGroupVoteContainer() {
    const onClickSave = (newAttendeeSlots) => {
      // only new slots with out name
      this.setState({ onClickedConfirmTime: true, newAttendeeSlots });
    };
    return (
      <div className="flex flex-col items-center w-full">
        {this.renderEventInfo()}
        <div
          className={classNames(
            "w-full pb-2.5",
            this.state.isMobile ? "" : "px-7"
          )}
        >
          <GroupVotePreview
            bookingLink={this.state.groupVoteLink}
            skipDarkMode={true}
            setName={(inputName) => this.setState({ inputName })}
            onClickSaveAttendeeSlots={onClickSave}
            isSelectEvent={this.state.isCreateGroupEvent}
            skipAnonymous={this.state.isCreateGroupEvent} // do not hide vote count if the user is creating the event
            closeModal={this.props.closeModal}
            isPreview={this.state.isPreview}
            selectedTimeZone={this.getSelectedTimeZone()}
          />
        </div>
      </div>
    );
  }

  renderSelectDayAndTimeContainer() {
    return (
      <div
        className={
          this.state.isMobile
            ? "availability-select-day-time-container-is-mobile"
            : "availability-select-day-time-container-is-not-mobile position-relative"
        }
      >
        {this.renderErrorWarning()}

        {this.renderEventInfoAndSelectDay()}
        {!this.state.isMobile && this.renderSelectTime()}
      </div>
    );
  }

  renderEventInfoAndSelectDay() {
    return (
      <div
        className="availability-left-container"
        style={
          this.state.isMobile
            ? {
                border: "none",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }
            : {}
        }
      >
        {this.renderEventInfo()}

        <ColoredLine width={this.state.isMobile ? "100%" : "90%"} style={{minHeight: 1}} />

        {this.determineSelectDateMethod()}
      </div>
    );
  }

  renderEventInfo(additionalClassName = null) {
    const { allLoggedInUsers: { allLoggedInUsers }, masterAccount: { masterAccount } } = this.props;
    const { calendar_provider_id } = this.state;

    const getProfileUser = () => {
      if (this.state.selectedUser) {
        return this.state.selectedUser;
      }
      return calendar_provider_id ? allLoggedInUsers.find(user => isSameEmail(getUserEmail(user), calendar_provider_id)) : null;
    };

    const user = getProfileUser();
    const profilePhotoUrl = getProfilePhotoUrl({ masterAccount, user });
    const socialLinks = getSocialLinks({ masterAccount, user });

    const renderProfilePicture = () => {
      if (this.state.isGroupVoteSpreadsheet) {
        return null;
      }
      if (this.state.isOnboardingMode) {
        return this.renderProfilePicture();
      }
      return profilePhotoUrl ? this.renderProfilePicture(profilePhotoUrl) : null;
    };

    return (
      <div
        className="availability-event-info"
        style={
          this.state.isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                padding: 14,
              }
            : { paddingTop: 20, paddingBottom: this.isUsingPreviewTimeZone() ? 8 : 20, paddingLeft: 30 }
        }
      >
        <div
          className={classNames(
            "items-center",
            this.state.isOnboardingMode ? "mb-2.5" : "mb-1"
          )}
        >
          {renderProfilePicture()}

          {this.state.user ? (
            <div className="flex flex-wrap items-center gap-x-3 gap-y-2 mb-1">
              <div className="availability-user-name leading-none pt-px">
                {this.state.initialInputName
                  ? `${capitalizeFirstLetterOfEveryWord(
                      this.state.initialInputName
                    )} and ${getSelectedUserName({user: this.state.user}).fullName || this.state.user.email}`
                  : `${getSelectedUserName({user: this.state.user}).fullName || this.state.user.email}`}
              </div>
              <ContactHandles attendee={{ social_links: socialLinks }} isPreview />
            </div>
          ) : null}
        </div>

        {(this.state.duration || this.isGroupVoteView()) && (
          <div className="availability-link-title font-size-20 font-weight-400 line-clamp-2">
            {this.determineLargeTitle()}
          </div>
        )}

        {this.renderEventInfoWithIcons(additionalClassName)}
      </div>
    );
  }

  renderProfilePicture(src) {
    return (
      <img
        style={{ marginTop: -8 }}
        alt=""
        width="50px"
        height="50px"
        className="mr-2 rounded-full"
        src={src ?? this.state.onboardingSpecialistPhoto}
      />
    );
  }

  renderEventInfoWithIcons(additionalClassName = null, containerClassName) {
    const FONT_SIZE = "font-size-12";
    const ICON_FONT_SIZE = 16;
    return (
      <div className={classNames("width-100-percent", containerClassName ?? "")}>
        {this.state.duration && (
          <div
            className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
          >
            <div
              className={classNames("margin-right-10", FONT_SIZE)}
              style={{ paddingTop: 4 }}
            >
              <Clock size={ICON_FONT_SIZE} />
            </div>

            {`Duration: ${this.determineDurationText()}`}
          </div>
        )}

        {this.state.onClickedConfirmTime && this.state.selectedTime && (
          <div
            className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
          >
            <div
              className={classNames("margin-right-10", FONT_SIZE)}
            >
              <Calendar size={ICON_FONT_SIZE} />
            </div>

            {this.state.selectedTime.start}
          </div>
        )}

        {this.state.location && (
          <div
            className={`display-flex-flex-direction-row mt-1.5 align-items-center mr-4 ${FONT_SIZE} ${additionalClassName ?? ""}`}
          >
            <div
              className={classNames("margin-right-10", FONT_SIZE)}
            >
              <MapPin size={ICON_FONT_SIZE} />
            </div>

            {this.state.location || "Home"}
          </div>
        )}

        {this.state.conferencing &&
          this.state.conferencing !== BACKEND_NO_CONFERENCING && (
            <div
              className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
            >
              <div
                className={classNames("margin-right-10", FONT_SIZE)}
              >
                {this.renderConferencingIcon(this.state.conferencing)}
              </div>

              {conferencingDescriptor(
                this.state.conferencing,
                this.getUser()
              )}
            </div>
          )}

        <div
          className={`display-flex-flex-direction-row mt-1.5 ${FONT_SIZE} ${additionalClassName}`}
        >
          <div
            className="margin-right-10 display-flex"
          >
            <Globe size={ICON_FONT_SIZE} />
          </div>

          <div>{addAbbrevationToTimeZone({timeZone: this.getSelectedTimeZone()})}</div>
        </div>
        {this.isUsingPreviewTimeZone()
          ? <DidYouKnowAlert subText={"Link will be shown in recipient's local time."} className="w-60 personal-link-preview"/>
          : null
        }
      </div>
    );
  }

  isUsingPreviewTimeZone() {
    return this.getSelectedTimeZone() !== guessTimeZone() && this.props.previewInformation?.time_zone;
  }

  renderSelectDay() {
    return (
      <div className="availability-bottom-left-container">
        <div
          className="availability-link-title"
          style={{
            paddingLeft: this.state.isMobile ? 15 : 30,
            paddingTop: 25,
            paddingBottom: 15,
          }}
        >
          Select Day
        </div>

        <div
          className="select-availability-day-slots-container"
          style={this.state.isMobile ? { overflowY: "auto" } : {}}
        >
          {this.renderDaySelection()}
        </div>
      </div>
    );
  }

  renderDaySelection() {
    if (!this.state.daySlots || Object.keys(this.state.daySlots).length === 0) {
      return;
    }

    return this.state.sortedDayArray.map((k, index) => {
      return (
        <div
          key={`availability_day_slots_${index}`}
          onClick={() => this.onClickDaySlot(k)}
          className="availability-link-day-container display-flex-flex-direction-row position-relative"
          style={
            this.state.isMobile
              ? Object.assign(
                  {
                    width: "100%",
                    justifyContent: "center",
                  },
                  this.daySlotStyle(k)
                )
              : Object.assign(this.daySlotStyle(k))
          }
          onMouseEnter={() => this.onMouseEnterDaySlot(k)}
          onMouseLeave={this.onMouseLeave}
        >
          <div className="display-flex-flex-direction-row align-items-center">
            <div
              className="availability-link-day-side-bar"
              style={
                k === this.state.selectedDay
                  ? { backgroundColor: DEFAULT_BLUE }
                  : {}
              }
            ></div>

            <div
              style={{
                position: "absolute",
                left: this.state.isMobile ? "15px" : "30px",
              }}
              className="font-size-14"
            >
              {k}
            </div>
          </div>

          {k === this.state.selectedDay && !this.state.isMobile && (
            <div className="availability-check-mark">
              <Check size={18} color={"#6177d9"} />
            </div>
          )}
        </div>
      );
    });
  }

  renderSelectTime() {
    return (
      <div className="render-right-hand-container">
        {this.renderRightHandTitle()}

        <div className="availability-time-slot-container">
          {this.renderTimeSlots()}
        </div>
      </div>
    );
  }

  renderRightHandTitle() {
    if (!this.state.selectedDay) {
      return;
    }

    return (
      <div className="margin-bottom-25">
        <div className="right-hand-title">
          {moment(this.state.selectedDay).format("LL")}
        </div>

        {this.renderTitleTime()}
      </div>
    );
  }

  renderTitleTime() {
    return null;
    if (this.state.selectedTime) {
      return (
        <div className="render-title-time">
          {moment(this.state.selectedTime.start).format("dddd")}{" "}
          {moment(this.state.selectedTime.start).format(TIME_FORMAT)} -{" "}
          {moment(this.state.selectedTime.end).format(TIME_FORMAT)}
        </div>
      );
    } else {
      return <div style={{ height: 16 }}></div>;
    }
  }

  renderTimeSlots() {
    if (this.state.isLoading) {
      return (
        <div className="flex flex-col gap-4">
          <LoadingSkeleton
            style={{
              width: "80%",
              height: "60px",
            }}
          />
          <LoadingSkeleton
            style={{
              width: "80%",
              height: "60px",
            }}
          />
          <LoadingSkeleton
            style={{
              width: "80%",
              height: "60px",
            }}
          />
          <LoadingSkeleton
            style={{
              width: "80%",
              height: "60px",
            }}
          />
        </div>
      );
    }
    if (
      !(
        this.state.selectedDay &&
        this.state.daySlots &&
        this.state.daySlots[this.state.selectedDay]
      )
    ) {
      return;
    }

    return this.state.daySlots[this.state.selectedDay].map((s, index) => {
      return this.renderSelectedTimeSlot(s, index);
    });
  }

  renderSelectedTimeSlot(s, index) {
    let shouldDisplayConfirm =
      this.state.selectedTime && s.start === this.state.selectedTime.start;

    return (
      <div
        key={`availability_selected_time_slot_${index}`}
        className="display-flex-flex-direction-row width-100-percent"
        style={{
          justifyContent: this.state.isMobile ? "center" : "flex-start",
        }}
        onClick={() => this.onClickTimeSlot(s)}
      >
        <div
          className={Classnames(
            "availability-link-time-slot-container",
            shouldDisplayConfirm
              ? "availability-confirm-time-width"
              : "availability-time-width"
          )}
        >
          {shouldDisplayConfirm && !this.state.isMobile
            ? `${moment(s.start).format(TIME_FORMAT)} - ${moment(s.end).format(
                TIME_FORMAT
              )}`
            : `${moment(s.start).format(TIME_FORMAT)}`}
        </div>

        <div
          className={Classnames(
            "confirm-button",
            shouldDisplayConfirm ? "availability-confirm-time-width" : ""
          )}
          style={shouldDisplayConfirm ? { marginRight: 10 } : { width: 0 }}
          onClick={this.onClickConfirmOnTime}
        >
          {shouldDisplayConfirm ? "Confirm" : ""}
        </div>
      </div>
    );
  }

  renderConferencingIcon() {
    if (this.state.conferencing === BACKEND_ZOOM) {
      return <img alt="" width="16px" height="16px" src={zoomImageURL} />;
    } else if (this.state.conferencing === BACKEND_HANGOUT) {
      return <img alt="" width="16px" height="16px" src={hangoutIconURL} />;
    } else if (this.state.conferencing === BACKEND_PHONE) {
      return <img alt="" width="17px" height="17px" src={PHONE_MEETING_ICON} />;
    } else if (this.state.conferencing === BACKEND_WHATS_APP) {
      return <img alt="" width="17px" height="17px" src={WHATS_APP_ICON} />;
    } else if (isOutlookConferencingOption(this.state.conferencing)) {
      return <img alt="" width="17px" height="17px" src={getOutlookConferencingIconURL(this.state.conferencing)} />;
    } else {
      return <Video size="16" />;
    }
  }

  fetchInitialData() {
    if (this.state.isOnboardingMode) {
      this.fetchPersonalLinkForPersonalOnboarding();
      return;
    }

    if (this.props.previewInformation) {
      const {
        duration,
        title,
        location,
        conferencing,
        availabilityEvents,
        selectedTimeSlotIndex,
        isPreviewExpired,
        timeSlotsInfo,
        isPersonalLink,
        slots,
        time_zone,
        buffer_before,
        buffer_after,
        days_forward,
        description,
        attendees,
        token,
        invitee_full_name,
        invitee_email,
        optionalEventName,
        availabilityAttendees,
        name,
        calendar_provider_id,
        google_calendar_id,
        sender_full_name,
        isGroupVoteLink,
        selected_slots,
        isCreateGroupEvent,
        bufferFromNow,
        buffer_from_now,
        blocked_calendar_ids,
        blocked_attendee_emails,
        bufferBeforeEvent,
        bufferAfterEvent,
        ignore_internal_conflicts,
        ignore_conflicts,
        selectedUser,
        isInEditPersonalLink,
        customQuestions,
      } = this.props.previewInformation;
      const {
        masterAccount,
      } = this.props.masterAccount;

      const user = {
        full_name: getUserName({user: this.getUser(selectedUser), masterAccount}).fullName,
        email: getUserEmail(this.getUser(selectedUser)),
      };

      if (isGroupVoteLink) {
        this.setState({
          title,
          duration,
          description,
          conferencing,
          calendar_provider_id,
          google_calendar_id,
          location,
          token,
          selected_slots,
          time_zone,
          attendees,
          isGroupVoteLink,
          isPreview: this.props.isPreview,
          isGroupVoteSpreadsheet: isSpreadsheetGroupLink(this.props.previewInformation),
          groupVoteLink: this.props.previewInformation,
          // expired: // do not show expired page since this is to view results. Use isExpiredGroupVote() as a function instead
          //   getNonExpiredSelectedSlots(this.props.previewInformation).length ===
          //   0,
          isCreateGroupEvent,
        });
        return;
      } else if (isPersonalLink) {
        this.setState(
          {
            duration,
            title,
            location,
            conferencing,
            user,
            isPersonalLink,
            slots,
            time_zone,
            buffer_before,
            buffer_after,
            days_forward,
            description,
            attendees,
            isPreview: true,
            token,
            name,
            host_time_zone: time_zone,
            roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
            appointmentToken: generateRandomId(16),
            buffer_from_now: bufferFromNow ?? buffer_from_now ?? 0,
            blocked_calendar_ids,
            selectedUser,
            isLoading: true,
            isInEditPersonalLink,
            customQuestions,
            ignore_internal_conflicts,
            ignore_conflicts,
            isMobile: this.getIsMobile(isInEditPersonalLink),
            isAllBooked: false,
            expired: false,
          },
          this.createPersonalLinkPreviewData
        );
        return;
      }

      this.setState(
        {
          duration,
          title,
          location,
          conferencing,
          user,
          availabilityEvents,
          onClickPreviewText:
            selectedTimeSlotIndex === null ||
            selectedTimeSlotIndex === undefined
              ? true
              : false,
          isPreview: true,
          expired: isPreviewExpired,
          index: selectedTimeSlotIndex,
          timeSlotsInfo,
          optionalEventName,
          availabilityAttendees,
          inputName: invitee_full_name
            ? capitalizeFirstLetterOfEveryWord(invitee_full_name)
            : "",
          initialInputName: invitee_full_name,
          inputEmail: invitee_email || "",
          attendees: attendees || [],
          description,
          calendar_provider_id,
          google_calendar_id,
          sender_full_name,
          token,
          buffer_from_now: bufferFromNow ?? buffer_from_now ?? 0,
          blocked_calendar_ids,
          blocked_attendee_emails,
          buffer_before: bufferBeforeEvent ?? 0,
          buffer_after: bufferAfterEvent ?? 0,
          ignore_internal_conflicts,
          ignore_conflicts,
          selectedUser,
        },
        this.createPreviewLink
      );
    } else {
      this.fetchAvailability();
    }
  }

  createPersonalLinkPreviewData() {
    const path = "personal_links/preview";
    const url = constructRequestURL(path, isVersionV2());

    const {
      slots,
      time_zone,
      days_forward,
      buffer_before,
      buffer_after,
      blocked_calendar_ids,
      conferencing,
      ignore_internal_conflicts,
      ignore_conflicts,
    } = this.state;

    const bookableSlots = generateBookableSlotsFromObj({
      slots,
      timeZone: time_zone,
      daysForward: days_forward,
      shouldFormatIntoUTC: false,
      bufferBefore: buffer_before,
      bufferAfter: buffer_after,
    });

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      this.setState({ isAllBooked: true });
      return;
    }

    const linkData = {
      conferencing,
      time_slots: bookableSlots,
    };
    if (ignore_conflicts) {
      linkData.blocked_calendar_ids = [];
    } else {
      linkData.blocked_calendar_ids = blocked_calendar_ids;
    }
    if (ignore_internal_conflicts) {
      linkData[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] = ignore_internal_conflicts;
    }

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.handleResponseFromGetFreeEvents(response);
      })
      .catch((error) => {
        this.handleCatchError(error);
      });
  }

  getUser(inputUser) {
    return inputUser || this.state.selectedUser || this.props.currentUser;
  }

  handleResponseFromGetFreeEvents(response, printToStringIfFail = false) {
    const user = this.getUser();
    if (this.doesResponseHaveErrorAndHandleError(response)) {
      if (printToStringIfFail) {
        trackError({
          category: "personal_onboarding_signup",
          errorMessage: "error_2 " + JSON.stringify(response),
          userToken: getUserToken(user),
        });
      }
      return;
    }

    const {
      slots,
      roundUpInterval,
    } = this.state;

    // get all free_busy slots
    const allBusySlots = getAllBusySlots({
      response,
      roundUpInterval,
      bufferBefore: this.state.buffer_before,
      bufferAfter: this.state.buffer_after,
      bufferFromNow: this.state.buffer_from_now
    });

    const allFreeSlots = generateFreeSlotsFromBusySlots({
      busySlots: allBusySlots,
      slots,
      daysForward: this.state.days_forward,
      durationMinutes: this.state.duration,
      timeZone: this.state.time_zone
    });

    let dayTimeSlots =
      this.constructDayAndTimeSlotsForPersonalLink(allFreeSlots);

    if (!dayTimeSlots) {
      return;
    }

    let { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;

    this.setState({
      sortedDayArray,
      selectedDay,
      daySlots,
      zoomLink: user.zoom_link,
      phoneNumber: {
        regionCode: user.phone_region_code,
        number: user.phone_number,
      },
      userCalendarId: getEventUserCalendarID(response),
      isLoading: false,
      finishedLoadingData: true,
    });
  }

  createPreviewLink() {
    if (this.state.expired) {
      return;
    } else if (isEmptyObjectOrFalsey(this.state.timeSlotsInfo)) {
      this.setState({ expired: true });
      return;
    }

    const {
      conferencing,
      blocked_calendar_ids,
      timeSlotsInfo,
      userCalendarId,
      blocked_attendee_emails,
      ignore_internal_conflicts,
      ignore_conflicts,
    } = this.state;

    const path = `${getSlotsPath()}/preview`;
    const url = constructRequestURL(path, isVersionV2());
    let linkData = _.clone(timeSlotsInfo);
    linkData = _.omit(linkData, "end_time");
    linkData.conferencing = conferencing;
    if (ignore_conflicts) {
      linkData.blocked_calendar_ids = [];
    } else {
      linkData.blocked_calendar_ids = blocked_calendar_ids;
    }
    linkData.user_calendar_id = userCalendarId;
    if (!ignore_conflicts && !isEmptyArrayOrFalsey(blocked_attendee_emails)) {
      linkData.blocked_attendee_emails = blocked_attendee_emails;
    }
    if (ignore_internal_conflicts) {
      linkData[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] = ignore_internal_conflicts;
    }

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (this.doesResponseHaveErrorAndHandleError(response)) {
          return;
        }

        const dayTimeSlots = this.constructDayAndTimeSlots(response);

        if (!dayTimeSlots) {
          return;
        }

        const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;

        this.setState({
          sortedDayArray,
          selectedDay,
          daySlots,
          zoomLink: response.zoom_link,
          userCalendarId: getEventUserCalendarID(response),
        });
      })
      .catch((error) => {
        this.handleCatchError(error);
      });
  }

  constructDayAndTimeSlotsForPersonalLink(freeSlots) {
    // freeSlots is the result of taking out the busy slots
    if (freeSlots.length === 0) {
      this.setState({ isAllBooked: true });
      return;
    }

    let daySlots = createSlotsForSelection(freeSlots, this.state.duration);

    if (isEmptyObjectOrFalsey(daySlots)) {
      this.setState({ isAllBooked: true });
      return;
    }

    daySlots = sortTimeSlots(daySlots);

    let daysArray = daySlots ? Object.keys(daySlots) : [];

    let sortedDayArray = daysArray.sort((a, b) => SortArrayByDate(a, b));

    let selectedDay = sortedDayArray[0];

    return { sortedDayArray, selectedDay, daySlots };
  }

  constructDayAndTimeSlots(response) {
    let freeSlots = [];

    const currentTimeBuffer = [
      {
        start: subMinutes(new Date(), 30).toISOString(),
        end: addMinutes(
          new Date(),
          this.state.buffer_from_now ?? 0
        ).toISOString(),
      },
    ];
    response.free_busy.forEach((s, index) => {
      const bufferParam = {
        bufferBefore: this.state.buffer_before ?? 0,
        bufferAfter: this.state.buffer_after ?? 0,
      };
      const formattedBusySlotsWithBufferBeforeAndAfter = s.busy_slots.map((slot) => {
        // return slot;
        return {
          start: addBufferToEvents({
            time: slot.start,
            isAllDay: false,
            shouldAddBuffer: true,
            isBufferBefore: true,
            param: bufferParam,
            isOutlook: slot.is_outlook
          }),
          end: addBufferToEvents({
            time: slot.end,
            isAllDay: false,
            shouldAddBuffer: true,
            isBufferBefore: false,
            param: bufferParam,
            isOutlook: slot.is_outlook
          }),
        };
      });

      let busySlots = mergeBusySlots(currentTimeBuffer.concat(formattedBusySlotsWithBufferBeforeAndAfter));

      freeSlots = freeSlots.concat(
        createFreeSlotsBasedOnBusySlots(s.start_time, s.end_time, busySlots)
      );
    });

    const daySlots = sortTimeSlots(createSlotsForSelection(
      freeSlots,
      this.state.duration || response.availabilities.duration
    ));

    if (isEmptyObjectOrFalsey(daySlots)) {
      this.setState({ isAllBooked: true });
      return;
    }

    let selectedDay =
      daySlots && Object.keys(daySlots).length > 0 && Object.keys(daySlots)[0];

    let selectedIndexDay;

    const timeZone = isValidTimeZone(this.state.host_time_zone) ? this.state.host_time_zone : guessTimeZone();
    response.free_busy.forEach((s) => {
      if (s.index === this.state.index) {
        // format: Saturday April 17, 2021
        selectedIndexDay = format(
          convertToTimeZone(s.start_time, { timeZone }),
          TIME_BLOCK_FORMAT
        );
      }
    });

    if (selectedIndexDay && Object.keys(daySlots).includes(selectedIndexDay)) {
      selectedDay = selectedIndexDay;
    } else if (this.state.onClickPreviewText) {
      // do nothing (onClicked preview text)
    } else if (
      selectedIndexDay &&
      moment(selectedIndexDay).isBefore(moment(), "day")
    ) {
      // date is in the past

      this.displayErrorWarning(PAST_DAY);
    } else if (selectedIndexDay) {
      // day is all filled

      this.displayErrorWarning(SELECTED_DAY_UNAVAILABLE);
    } else if (!selectedIndexDay) {
      this.displayErrorWarning(PAST_DAY);
    }

    let daysArray = daySlots ? Object.keys(daySlots) : [];

    let sortedDayArray = daysArray.sort((a, b) => SortArrayByDate(a, b));

    return { sortedDayArray, selectedDay, daySlots };
  }

  book() {
    if (this._hasClickedSchedule) {
      return;
    }

    this._hasClickedSchedule = true;
    this.setState({isSubmitting: true});

    if (this.isGroupVoteView()) {
      this.submitGroupVote();
    } else if (this.state.isPersonalLink) {
      if (this.state.isOnboardingMode) {
        this.onClickSchedulePersonalOnboarding();
      } else {
        this.bookPersonalLink();
      }
    } else {
      this.bookAvailabilityLink();
    }
  }

  submitGroupVote() {
    const isSpreadsheet = isSpreadsheetGroupLink(this.state.groupVoteLink);
    if (isSpreadsheet) {
      sendMessageToSentry("Submitting group spreadsheet changes not supported in main app yet.");
      return;
    }
    const path = `group_vote_links/${this.state.token}/response`;
    const url = constructRequestURL(path, isVersionV2());

    const payloadData = {
      body: JSON.stringify({
        attendee: {
          name: this.state.inputName,
          email: this.state.inputEmail,
          slots: convertSlotsIntoISOString(this.state.newAttendeeSlots),
        },
      }),
    };

    Fetcher.patch(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        } else if (isEmptyObjectOrFalsey(response) || response.error) {
          this.setState({ error: true });
          return;
        }

        this.setState({ showSentConfirmationEmail: true });
        const { group_vote_link } = response;

        if (!isEmptyObjectOrFalsey(group_vote_link)) {
          const { setGroupVoteLinks, groupVoteLinks } =
            this.props.groupVoteStore;
          let updatedGroupVoteLinks = [];

          groupVoteLinks.forEach((g) => {
            if (g?.token === group_vote_link.token) {
              updatedGroupVoteLinks =
                updatedGroupVoteLinks.concat(group_vote_link);
            } else {
              updatedGroupVoteLinks = updatedGroupVoteLinks.concat(g);
            }
          });
          setGroupVoteLinks(updatedGroupVoteLinks);
        }
      })
      .catch((error) => {
        this.handleCatchError(error, true);
      });
  }

  createBookingLinkSummary() {
    if (this.state.title && this.state.title.length > 0) {
      return this.state.title.replace(INVITEE_NAME_BLOCK, this.state.inputName);
    } else {
      return `${this.state.inputName.trim()} <> ${
        getSelectedUserName({user: this.state.user}).fullName || getUserEmail(this.state.user)
      }`;
    }
  }

  createPersonalLinkSummary() {
    return this.state.title.replace(
      INVITEE_NAME_BLOCK,
      this.state.inputName.trim()
    );
  }

  bookAvailabilityLink() {
    const eventData = this.constructSlotsBookingData();
    const path = `${getSlotsPath()}/book`;

    const createEventParams = {
      sendUpdates: GOOGLE_UPDATES.ALL,
      conferenceDataVersion: 1,
      link_type: BACKEND_AVAILABILITY,
      link_token: this.state.token,
    };

    const queryParams = constructQueryParams(createEventParams);
    const url = constructRequestURL(path, isVersionV2()) + `?${queryParams}`;

    this.submitBook(url, { body: JSON.stringify(eventData) });
  }

  bookPersonalLink() {
    const eventData = this.constructPersonalLinkEventData();

    const path = "personal_links/book";
    const params = {
      sendUpdates: GOOGLE_UPDATES.ALL,
      conferenceDataVersion: 1,
      link_type: BACKEND_PERSONAL,
      link_token: this.state.token,
      appointment_token: this.state.appointmentToken,
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isVersionV2()) + `?${queryParams}`;

    const payloadData = { body: JSON.stringify(eventData) };

    this.submitBook(url, payloadData);
  }

  submitBook(url, payloadData) {
    return Fetcher.post(url, payloadData, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        } else if (!response) {
          trackError({
            category: "personal_onboarding_signup",
            errorMessage: "error_3_null_response",
            userToken: getUserToken(this.getUser()),
          });

          this.setState({ error: true });
          return;
        }

        if (this.props.onComplete) {
          this.props.onComplete();
        }

        this.setState({ showSentConfirmationEmail: true });
      })
      .catch((error) => {
        this.handleCatchError(error, true);
        trackError({
          category: "personal_onboarding_signup",
          errorMessage: error?.toString
            ? "error_4 " + error.toString()
            : "error_4",
          userToken: getUserToken(this.getUser()),
        });
      });
  }

  fetchAvailability() {
    // this never gets used
    const {
      token
    } = this.state;

    const path = `${getSlotsPath()}/${token}`;
    const url = constructRequestURL(path, isVersionV2());

    return Fetcher.get(url, {}, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (this.doesResponseHaveErrorAndHandleError(response)) {
          return;
        }

        const {
          title,
          duration,
          location,
          conferencing,
          user,
          client_time_zone,
        } = response.availabilities;

        const dayTimeSlots = this.constructDayAndTimeSlots(response);

        if (!dayTimeSlots) {
          return;
        }

        let { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;

        this.setState({
          title,
          duration,
          location,
          conferencing,
          selectedDay,
          user,
          daySlots,
          sortedDayArray,
          zoomLink: response.zoom_link,
          userCalendarId: getEventUserCalendarID(response),
          freeBusy: response.free_busy,
          host_time_zone: client_time_zone || guessTimeZone(),
        });
      })
      .catch((error) => {
        this.handleCatchError(error);
      });
  }

  onClickSchedule() {
    if (this.props.preventScheduling) {
      // in personal link detail page, we don't want the user to be able to actually schedule
      // since this is more of an example
      return;
    }
    let newState = {};

    if (this.state.inputName.length === 0) {
      newState["nameHasIssue"] = true;
    } else {
      newState["nameHasIssue"] = false;
    }

    const trimmedEmail = this.state.inputEmail.trim();

    if (!isValidEmail(trimmedEmail)) {
      newState["emailHasIssue"] = true;
    } else {
      newState["emailHasIssue"] = false;
    }

    newState["customQuestionsHasIssue"] = false;
    this.state?.customQuestions &&
      this.state.customQuestions.forEach((question, questionIndex) => {
        if (question?.required && !question?.answer && questionIndex >= 2) {
          newState["customQuestionsHasIssue"] = true;
        }
      });

    if (
      newState["emailHasIssue"] ||
      newState["nameHasIssue"] ||
      newState["customQuestionsHasIssue"]
    ) {
      trackEvent({
        category: "onboarding",
        action: "error_with_email_name" + trimmedEmail + this.state.inputName,
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.getUser()),
      });

      this.setState(newState);
    } else {
      if (this.state.isOnboardingMode) {
        trackEvent({
          category: "onboarding",
          action: "2_schedule_event",
          label: "personal_onboarding_signup",
          userToken: getUserToken(this.getUser()),
        });
      }

      this.book();
    }
  }

  getIndex() {
    if (typeof URL !== "function") {
      // Unsafe to use the function
      return null;
    }

    let url_string = window.location.href;
    let url = new URL(url_string);

    let index = url.searchParams.get("index");

    return index;
  }

  onClickBackFromConfirmNameAndEmail() {
    this.setState({
      onClickedConfirmTime: false,
      nameHasIssue: false,
      emailHasIssue: false,
    });
  }

  onClickBackFromSelectTime() {
    this.setState({ shouldShowMobileSelectTime: false });
  }

  onChangeName(e) {
    const name = getInputStringFromEvent(e);
    if (
      name?.length === 1 &&
      !this.state.hasCapitalizedFirstLetter
    ) {
      this.setState({
        inputName: name.toUpperCase(),
        hasCapitalizedFirstLetter: true,
      });

      return;
    }

    this.setState({ inputName: name });
  }

  onChangeEmail(e) {
    this.setState({ inputEmail: getInputStringFromEvent(e) });
  }

  onClickDaySlot(day) {
    if (day === this.state.selectedDay && !this.state.isMobile) {
      return;
    }

    let newState = { selectedDay: day, selectedTime: null };

    if (this.state.isMobile) {
      newState["shouldShowMobileSelectTime"] = true;
    }

    this.setState(newState);
  }

  handleWindowSizeChange() {
    const displayWidth = window.innerWidth;

    if (this.state.isInEditPersonalLink) {
      const isMobile = this.getIsMobile();
      if (isMobile !== this.state.isMobile) {
        this.setState({ isMobile });
      }
      return;
    }
    if (this.state.isMobile && displayWidth > MOBILE_WIDTH_LIMIT) {
      this.setState({ isMobile: false });
      return;
    }
    if (!this.state.isMobile && displayWidth <= MOBILE_WIDTH_LIMIT) {
      this.setState({ isMobile: true });
    }
  }

  onMouseLeave() {
    this.setState({ hoverDay: null });
  }

  onMouseEnterDaySlot(day) {
    this.setState({ hoverDay: day });
  }

  daySlotStyle(day) {
    if (this.state.selectedDay === day) {
      return { backgroundColor: "#EEF4FF", color: "#3980F8" };
    } else if (this.state.hoverDay === day && !this.state.isMobile) {
      return { backgroundColor: "#F6F7F7" };
    } else {
      return {};
    }
  }

  determineContent() {
    if (this.props.isPreviewingCustomQuestions) {
      return this.renderEventInformationAndEnterNameAndEmail();
    }
    if (
      this.props.showLoadingScreenBeforeDataLoads &&
      !this.state.finishedLoadingData
    ) {
      return this.renderLoadingScreen();
    }
    if (this.state.expired) {
      return this.renderShowExpired();
    }
    if (this.state.isAllBooked) {
      return this.renderIsAllBooked();
    }
    if (this.state.error) {
      if (this.state.isPreview) {
        return this.renderShowPreviewError();
      }
      return this.renderShowError();
    }
    if (this.state.showSentConfirmationEmail) {
      return this.renderConfirmationEmail();
    }
    if (this.state.onClickedConfirmTime) {
      return this.renderEventInformationAndEnterNameAndEmail();
    }
    if (this.state.isMobile && this.state.shouldShowMobileSelectTime) {
      return this.renderMobileSelectTime();
    }
    if (this.isGroupVoteView()) {
      if (this.state.isGroupVoteSpreadsheet) {
        return (
          <GroupVoteSpreadSheet
            bookingLink={this.state.groupVoteLink}
            onClickSave={() => {
              // nothing to save in preview. We either create event or view preview
            }}
            isMobile={this.state.isMobile}
            selectedTimeZone={this.getSelectedTimeZone()}
            isOnCreate={!this.state.isPreview}
          >
            {this.renderEventInfo()}
          </GroupVoteSpreadSheet>
        );
      }
      return this.renderGroupVoteContainer();
    }
    return this.renderSelectDayAndTimeContainer();
  }

  renderIsAllBooked() {
    return this.renderShowErrorMessage("All slots are booked.");
  }

  renderMobileSelectTime() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {this.renderBackButton(this.onClickBackFromSelectTime)}

        <div style={{ marginTop: 38, fontSize: 22, fontWeight: 400 }}>
          Select a Time
        </div>

        <div style={{ fontSize: DEFAULT_FONT_SIZE_PX, fontWeight: 300, marginTop: 12 }}>
          Duration: {this.state.duration} minutes
        </div>

        <div
          className="display-flex-flex-direction-row"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ marginTop: 2, marginRight: 10 }}>
            <Globe size={13} />
          </div>

          <div style={{ fontSize: DEFAULT_FONT_SIZE_PX }}>
            {addAbbrevationToTimeZone({timeZone: this.getSelectedTimeZone()})}
          </div>
        </div>

        <ColoredLine style={{ marginTop: 34, width: "100%" }} />

        {this.renderMobileSwipeDate()}

        <div
          className="width-100-percent"
          style={{ overflowY: "auto", marginBottom: 80 }}
        >
          {this.renderTimeSlots()}
        </div>
      </div>
    );
  }

  renderMobileSwipeDate() {
    let currentDayIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    return (
      <div
        className="display-flex-flex-direction-row"
        style={{ marginTop: 42, marginBottom: 30 }}
      >
        {currentDayIndex > 0 ? (
          <div className="availability-back-button" onClick={this.goBackADay}>
            <ChevronLeft size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}

        <div
          style={{
            marginLeft: 47,
            marginRight: 47,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 20 }}>
            {moment(this.state.selectedDay).format("dddd")}
          </div>

          <div
            className="text-center"
            style={{ fontSize: 14, fontWeight: 300 }}
          >
            {moment(this.state.selectedDay).format("MMM D, YYYY")}
          </div>
        </div>

        {currentDayIndex < this.state.sortedDayArray.length - 1 ? (
          <div
            className="availability-back-button"
            onClick={this.goForwardADay}
          >
            <ChevronRight size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}
      </div>
    );
  }

  renderEventInformationAndEnterNameAndEmail() {
    return (
      <div
        className={
          this.state.isMobile
            ? "width-100-percent overflow-y-auto"
            : "width-100-percent display-flex-flex-direction-row"
        }
      >
        <div
          className="availability-left-container position-relative"
          style={
            this.state.isMobile
              ? {
                  border: "none",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderBottom: "1px solid #d7d9d6",
                }
              : { alignItems: "flex-start" }
          }
        >
          {this.renderBackButton(this.onClickBackFromConfirmNameAndEmail)}

          <div
            className="schedule-event-page-margin-top"
            style={this.state.isMobile ? { marginTop: 30 } : {}}
          >
            {this.renderEventInfo("mt-1.5")}
          </div>

          <div
            className="availability-vimcal-logo-display"
            style={{ position: "absolute", bottom: 20, right: 20 }}
          >
            {this.renderVimcalLogoWithMessage("Powered by")}
          </div>
        </div>

        {this.renderEnterNameAndEmail()}
      </div>
    );
  }

  determineContainerStyle() {
    if (this.state.isPreview) {
      if (this.state.isInEditPersonalLink) {
        return {};
      }
      if (this.isGroupVoteView() && !this.state.onClickedConfirmTime) {
        return {};
      }
      return {
        width: this.state.isPersonalLink ? 900 : 800,
        height: 650,
        borderRadius: this.shouldDisplayWelcomeInviteeBanner()
          ? "0px 0px 6px 6px"
          : null,
      };
    } else if (
      this.state.error ||
      this.state.expired ||
      this.state.isAllBooked
    ) {
      return { width: 500, height: 700 };
    } else {
      return {};
    }
  }

  determineSelectDateMethod() {
    if (this.state.isPersonalLink) {
      if (this.state.isLoading) {
        return (
          <LoadingSkeleton
            style={{
              width: "80%",
              height: "160px",
              marginTop: "28px",
              marginBottom: "36px",
            }}
          />
        );
      }
      return (
        <AvailabilityMonthlyCalendar
          onClickDaySlot={this.onClickDaySlot}
          availableSlots={this.state.daySlots}
          selectedDay={this.state.selectedDay}
        />
      );
    } else {
      return this.renderSelectDay();
    }
  }

  constructPersonalLinkEventData() {
    if (!this.state.selectedTime) {
      return;
    }

    const start = {
      dateTime: moment(this.state.selectedTime.start).format(),
    };

    const end = {
      dateTime: moment(this.state.selectedTime.end).format(),
    };

    const summary = this.createPersonalLinkSummary();

    const attendees = this.createAttendees();

    const description = this.createDescription();
    const {
      masterAccount
    } = this.props.masterAccount;

    const calendar_event = {
      event_start: start,
      event_end: end,
      summary,
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      conferenceData: this.state.isOnboardingMode
        ? this.createConferencingForOnboardingPersonalLink()
        : createConferenceData(
            {
              conference: this.state.conferencing,
              currentUser: {
                ...this.getUser(),
                zoom_link: this.state.zoomLink,
              },
              zoomMeeting: null,
              isTemplate: false,
              where: "availability_link_0",
              masterAccount
            },
            getZoomSchedulers(this.props.zoomSchedulers)
          ),
      attendees,
      description,
    };

    if (this.state.location) {
      calendar_event.location = this.state.location;
    } else if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.zoomLink
    ) {
      // no location -> but has zoom personal link
      calendar_event.location = this.state.zoomLink;
    }

    // Body: 2:30pm - Wednesday, June 10, 2020
    // Subject: 2:30pm Wed, Jun 10, 2020
    const event = isVersionV2() ? { calendar_event } : { google_calendar_event: calendar_event };
    return {
      event,
      ...this.createEmailData(),
    };
  }

  createEmailData() {
    return {
      user_calendar_id: this.state.userCalendarId,
      invitee_email: this.state.inputEmail,
      invitee_name: this.state.inputName,
      duration: this.state.duration,
      conferencing: this.state.conferencing,
      invitee_time_zone_text: addAbbrevationToTimeZone({timeZone: guessTimeZone()}),
      invitee_start_time_body: this.createStartTimeBody(false),
      invitee_start_time_subject: this.createStartTimeSubject(false),
      host_start_time_subject: this.createStartTimeSubject(true),
      host_start_time_body: this.createStartTimeBody(true),
      host_time_zone_text: addAbbrevationToTimeZone({timeZone: this.state.host_time_zone}),
      ...(this.state.location && { location: this.state.location }),
    };
  }

  createDescription() {
    let updatedDescription = this.state.description || "";

    if (!(updatedDescription === "" || updatedDescription.length === 0)) {
      // add new line if there's information there already
      updatedDescription = updatedDescription + "\n\n";
    }

    let link = determineBookingURL();
    let cancelLink = `Cancel: \n ${link}/c/${this.state.appointmentToken}`;
    let rescheduleLink = `Reschedule: \n ${link}/r/${this.state.appointmentToken}`;
    const {
      masterAccount
    } = this.props.masterAccount;

    updatedDescription =
      updatedDescription +
      `Need to change this event? \n\n ${cancelLink} \n\n ${rescheduleLink} \n\n ${getVimcalRichTextSignature(masterAccount)}`;

    // We expect that the first two questions are name and email which are already displayed above
    // TODO: if we can guarantee elsewhere that name and email will always be the first two custom questions
    // then remove the if questionIndex >= 2
    if (this.state?.customQuestions) {
      this.state.customQuestions.forEach((question, questionIndex) => {
        if (questionIndex >= 2 && question?.answer) {
          updatedDescription += `\n\n${question.description.trim()}`;
          updatedDescription += "\n";
          if (question.type === "phoneNumber" && question?.answer) {
            updatedDescription += `${this.createRegionCodeText(
              question?.regionCode || "US"
            )} `;
          }
          updatedDescription += `${
            question?.answer ? question.answer : ""
          }`.trim();
        }
      });
    }

    return updatedDescription;
  }

  constructSlotsBookingData() {
    if (!this.state.selectedTime) {
      return;
    }

    const start = {
      dateTime: moment(this.state.selectedTime.start).format(),
    };

    const end = {
      dateTime: moment(this.state.selectedTime.end).format(),
    };

    const summary = this.createBookingLinkSummary();

    const attendees = this.createAttendees();
    const {
      masterAccount
    } = this.props.masterAccount;

    const eventDetails = {
      start,
      end,
      summary,
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      conferenceData: createConferenceData(
        {
          conference: this.state.conferencing,
          currentUser: {
            ...this.getUser(),
            zoom_link: this.state.zoomLink,
          },
          zoomMeeting: null,
          isTemplate: false,
          where: "availability_link_1",
          masterAccount
        },
        getZoomSchedulers(this.props.zoomSchedulers)
      ),
      attendees,
    };

    if (this.state.location) {
      eventDetails.location = this.state.location;
    } else if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.zoomLink
    ) {
      eventDetails.location = this.state.zoomLink;
    }

    if (this.state.description) {
      eventDetails.description = this.state.description;
    }

    // Body: 2:30pm - Wednesday, June 10, 2020
    // Subject: 2:30pm Wed, Jun 10, 2020

    if (isVersionV2()) {
      return {
        calendar_event: { eventDetails },
        ...this.createEmailData(),
      };
    }

    return {
      google_calendar_event: { eventDetails },
      ...this.createEmailData(),
    };
  }

  createAttendees() {
    const defaultBookingEmail = isVersionV2()
      ? this.state.calendar_provider_id
      : this.state.google_calendar_id;

    let selfResponse = {
      self: true,
      email: defaultBookingEmail
        ? defaultBookingEmail.trim()
        : this.state.user.email.trim(),
      organizer: true,
      responseStatus: ACCEPTED_STATUS,
    };

    if (defaultBookingEmail) {
      // passed in new user
      if (this.state.sender_full_name) {
        selfResponse.displayName = this.state.sender_full_name.trim();
      }
    } else if (getSelectedUserName({user: this.state.user}).fullName) {
      // default user
      selfResponse.displayName = getSelectedUserName({user: this.state.user}).fullName.trim();
    }

    let attendees = [
      selfResponse,
      {
        responseStatus: NEEDS_ACTION_STATUS,
        email: this.state.inputEmail.trim(),
        displayName: this.state.inputName.trim(),
      },
    ];

    if (this.state.attendees && this.state.attendees.length > 0) {
      let existingEmails = attendees.map((a) => a.email.trim().toLowerCase());
      this.state.attendees?.forEach((a) => {
        if (!a || !a.email) {
          return;
        }
        let email = a.email.trim();

        if (existingEmails.includes(email.toLowerCase())) {
          // already exist
          return;
        }

        let newAttendee = { responseStatus: NEEDS_ACTION_STATUS, email: email };
        if (a.name) {
          newAttendee.displayName = a.name.trim();
        }

        attendees = attendees.concat(newAttendee);
        existingEmails = existingEmails.concat(email.toLowerCase());
      });
    }

    return attendees;
  }

  createStartTimeBody(isHost) {
    let time = this.state.selectedTime.start;

    return format(
      convertToTimeZone(moment(time).toDate(), {
        timeZone: isHost ? this.state.host_time_zone : guessTimeZone(),
      }),
      "h:mmaaa - eeee, MMMM d, yyyy"
    );
  }

  createStartTimeSubject(isHost) {
    let time = this.state.selectedTime.start;

    return format(
      convertToTimeZone(moment(time).toDate(), {
        timeZone: isHost ? this.state.host_time_zone : guessTimeZone(),
      }),
      "h:mmaaa EEE, MMM d, yyyy"
    );
  }

  goBackADay() {
    let currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex - 1],
      selectedTime: null,
    });
  }

  goForwardADay() {
    let currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex + 1],
      selectedTime: null,
    });
  }

  goToVimcalWebsite() {
    OpenLink("https://www.vimcal.com/");
  }

  onClickConfirmOnTime() {
    if (this.state.isOnboardingMode) {
      trackEvent({
        category: "onboarding",
        action: "1_selected_confirm_button",
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.getUser()),
      });
    }

    this.setState({ onClickedConfirmTime: true });
  }

  onClickTimeSlot(time) {
    this.setState({ selectedTime: time });
  }

  closeWarningMessage() {
    this.setState({ errorWarning: null });
  }

  displayErrorWarning(message) {
    this.setState({ errorWarning: message });

    clearTimeout(this.disappearingErrorMessageTimer);

    this.disappearingErrorMessageTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.closeWarningMessage();
    }, 5000);
  }

  determineDurationText() {
    let durationObject = reformatMinDuration(this.state.duration);

    return determineDurationString(durationObject);
  }

  doesResponseHaveErrorAndHandleError(response) {
    let hasError = false;

    const baseUpdatedState = {isLoading: false};
    if (!response) {
      hasError = true;
      this.setState({...baseUpdatedState, error: true});
    } else if (response.error) {
      hasError = true;
      if (response.error === "expired") {
        this.setState({ ...baseUpdatedState, expired: true });
      } else {
        this.setState({ ...baseUpdatedState, error: true });
      }
    } else if (response.type === "error") {
      hasError = true;

      this.setState({ ...baseUpdatedState, error: true });
    }

    return hasError;
  }

  handleCatchError(error, shouldUpdateSubmitting = false) {
    handleError(error);

    if (!this._isMounted) {
      return;
    }

    if (shouldUpdateSubmitting) {
      this.setState({ error: true });
    } else {
      this.setState({ error: true });
    }
  }

  determineLargeTitle() {
    if (this.props.previewInformation?.name) {
      return this.props.previewInformation.name;
    }
    if (this.state.name) {
      return this.state.name;
    }

    if (this.state.title && this.state.title.length > 0) {
      if (
        this.state.title.includes(INVITEE_NAME_BLOCK) &&
        this.state.initialInputName
      ) {
        return this.state.title.replace(
          INVITEE_NAME_BLOCK,
          capitalizeFirstLetterOfEveryWord(this.state.initialInputName)
        );
      } else if (!this.state.title.includes(INVITEE_NAME_BLOCK)) {
        return this.state.title;
      }
    }

    return capitalizeFirstLetterOfEveryWord(
      this.determineDurationFromMinutes(this.state.duration)
    );
  }

  determineDurationFromMinutes(minutes) {
    let durationString = "";

    if (!minutes) {
      return "0 minute meeting";
    }

    let hours = 0;

    if (minutes > 60) {
      let minReminder = minutes % 60;
      let additionalHours = Math.floor(minutes / 60);
      hours = hours + additionalHours;
      minutes = minReminder;
    }

    if (hours > 0) {
      durationString = `${hours} Hour `;
    }

    if (minutes > 0) {
      durationString = durationString + `${minutes} minute `;
    }

    if (!durationString) {
      return "0 minute meeting";
    }

    return durationString + "meeting";
  }

  isGroupVoteView() {
    return this.state.isGroupVoteLink && !this.state.expired;
  }

  // grab personal link for onboarding
  fetchPersonalLinkForPersonalOnboarding() {
    const url = `https://prod.vimcal.com/app/${this.getVersion()}/personal_links/${this.state.token}`;

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (isEmptyObjectOrFalsey(response)) {
          trackError({
            category: "personal_onboarding_signup",
            errorMessage:
              "fetchPersonalLinkForPersonalOnboarding_null_response",
            userToken: getUserToken(this.getUser()),
          });
          this.setState({ error: true });
          return;
        } else if (isEmptyObjectOrFalsey(response.personal_link)) {
          trackError({
            category: "personal_onboarding_signup",
            errorMessage:
              "fetchPersonalLinkForPersonalOnboarding_missing_personal_link",
            userToken: getUserToken(this.getUser()),
          });
          this.setState({ error: true });
          return;
        }

        const {
          duration,
          title,
          location,
          conferencing,
          slots,
          time_zone,
          buffer_before,
          buffer_after,
          days_forward,
          description,
          attendees,
          custom_questions: customQuestions,
        } = response.personal_link;

        this.setState(
          {
            duration,
            title,
            location,
            conferencing,
            slots,
            time_zone,
            buffer_before,
            buffer_after,
            days_forward,
            description,
            attendees,
            host_time_zone: time_zone || guessTimeZone(),
            customQuestions,
            roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
          },
          this.fetchPersonalLinkAvailability
        );
      })
      .catch((error) => {
        trackError({
          category: "personal_onboarding_signup",
          errorMessage: error?.toString
            ? "error_0 " + error.toString()
            : "error_0",
          userToken: getUserToken(this.getUser()),
        });

        handleError(error);
      });
  }

  getVersion() {
    return isVersionV2() ? "v2" : "v1"
  }

  fetchPersonalLinkAvailability() {
    const url = `https://prod.vimcal.com/app/${this.getVersion()}/personal_links/${this.state.token}/availability`;

    const bookableSlots = generateBookableSlotsFromObj({
      slots: this.state.slots,
      timeZone: this.state.time_zone,
      daysForward: this.state.days_forward,
      shouldFormatIntoUTC: false,
      bufferBefore: this.state.buffer_before,
      bufferAfter: this.state.buffer_after
    });

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      this.setState({ areAllSlotsBusy: true });
      return;
    }

    const linkData = {
      conferencing: this.state.conferencing,
      time_slots: bookableSlots,
    };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    return Fetcher.post(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (isEmptyObjectOrFalsey(response)) {
          trackError({
            category: "personal_onboarding_signup",
            errorMessage: "fetchPersonalLinkAvailability_null_response",
            userToken: getUserToken(this.getUser()),
          });
          this.setState({ error: true });
          return;
        }

        const {
          free_busy,
          personal_link,
          user_calendar_id,
          zoom_link,
          phone_number,
          phone_region_code,
          user,
          default_zoom_mode,
          custom_conferencing_name,
          custom_conferencing_url,
        } = response;

        this.setState(
          {
            free_busy,
            personal_link,
            user_calendar_id,
            zoom_link,
            phone_number,
            phone_region_code,
            user,
            default_zoom_mode,
            appointmentToken: generateRandomId(16),
            custom_conferencing_name,
            custom_conferencing_url,
            finishedLoadingData: true,
          },
          () => this.handleResponseFromGetFreeEvents(response, true)
        );
      })
      .catch((error) => {
        trackError({
          category: "personal_onboarding_signup",
          errorMessage: error?.toString
            ? "error_1 " + error.toString()
            : "error_1",
          userToken: getUserToken(this.getUser()),
        });

        handleError(error);
      });
  }

  createConferencingForOnboardingPersonalLink() {
    if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.uniqueZoomMeeting
    ) {
      return createUniqueZoom(this.state.uniqueZoomMeeting);
    } else if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.zoom_link
    ) {
      const zoomEntryPoint = {
        entryPointType: "video",
        uri: this.state.zoomLink,
        label: this.state.zoomLink,
      };

      const conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: "Zoom Meeting",
        iconUri: zoomImageURL,
      };

      const entryPoints = [zoomEntryPoint];

      const conferencing = { entryPoints, conferenceSolution };
      if (this.state.zoomId) {
        // zoomId is used so you can join the meeting from the zoom app
        conferencing.conferenceId = this.state.zoomId;
      }

      return conferencing;
    }
  }

  getSelectedTimeZone() {
    if (this.props.previewInformation?.time_zone) {
      return this.props.previewInformation.time_zone;
    }
    if (this.state.isGroupVoteLink) {
      return this.state.selectedTimeZone || guessTimeZone();
    }
    return guessTimeZone();
  }

  createZoomUniqueLinkParam(isPersonalLink = false) {
    return {
      meeting: {
        topic: isPersonalLink
          ? this.createPersonalLinkSummary()
          : this.createBookingLinkSummary(),
        timezone: UTC_TIME_ZONE,
        start_time: moment(this.state.selectedTime.start)
          .toDate()
          .toISOString(),
        duration: moment(this.state.selectedTime.end)
          .startOf("minute")
          .diff(
            moment(this.state.selectedTime.start).startOf("minute"),
            "minute"
          ),
      },
    };
  }

  // creates unique zoom and do not sue current user's
  onClickSchedulePersonalOnboarding() {
    const path = `personal_links/${this.state.token}/zoom_meetings`;
    const url = constructRequestURL(path); // TODO: change to v2 for zoom later

    const param = this.createZoomUniqueLinkParam(true);

    const payloadData = {
      body: JSON.stringify(param),
    };

    if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.default_zoom_mode !== BACKEND_PERSONAL_LINK
    ) {
      // if user not logged in -> 401 error
      Fetcher.post(url, payloadData)
        .then((response) => {
          if (!this._isMounted) {
            return;
          }

          if (isEmptyObjectOrFalsey(response) || !response.join_url) {
            // create with personal links
            this.bookPersonalLink();
            return;
          }

          const { join_url, id } = response;

          this.setState(
            { zoomLink: join_url, zoomId: id, uniqueZoomMeeting: response },
            this.bookPersonalLink
          );
        })
        .catch((error) => {
          handleError(error);
          if (!this._isMounted) {
            return;
          }

          this.bookPersonalLink();
        });
    } else {
      this.bookPersonalLink();
    }
  }

  isExpiredGroupVote() {
    return getNonExpiredSelectedSlots(this.props.previewInformation).length === 0;
  }

  getIsMobile(isInEditPersonalLink) {
    if (isInEditPersonalLink || this.state.isInEditPersonalLink) {
      return window?.innerWidth <= 1052;
    }
    return isMobile();
  }

  createRegionCode() {
    const possibleRegionCode = PhoneNumber.getSupportedRegionCodes();
    return possibleRegionCode.map((c) => {
      return { value: c, label: this.createRegionCodeText(c) };
    });
  }
}

function mapStateToProps(state) {
  const { currentUser, temporaryEvents, currentTimeZone } = state;

  return { currentUser, temporaryEvents, currentTimeZone };
}

const withStore = (BaseComponent) => (props) => {
  const allLoggedInUsers = useAllLoggedInUsers();
  const store = useGroupVoteStore();
  const zoomSchedulers = useZoomSchedulers();
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      allLoggedInUsers={allLoggedInUsers}
      groupVoteStore={store}
      zoomSchedulers={zoomSchedulers}
      isDarkMode={isDarkMode}
      masterAccount={masterAccount}
    />
  );
};

export default connect(mapStateToProps)(
  withRouter(withStore(AvailabilityLink))
);
