import React from "react";
import {
  getMetricsBusiestDay,
  getMetricsDailyAverage,
  getMetricsDailyAverageChange,
  getMetricsDailyAverageIsUp,
  getMetricsMeetingHours,
  getMetricsMeetingHoursChange,
  getMetricsMeetingHoursIsUp,
  getMetricsMeetings,
  getMetricsMeetingsChange,
  getMetricsMeetingsIsUp,
} from "./metricsAccessorFunctions";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import UpDownAbsoluteChange from "./upDownAbsoluteChange";
import classNames from "classnames";

const METRICS_CONTENT_FONT_CLASSNAME = "font-size-18 font-weight-400";
const CONTAINER_CLASS_NAME = "metrics-top-level-container";
export default function MetricsTopLevel({
  data,
  previousData,
  showSkeletonScreen,
}) {
  return (
    <div className="overflow-hidden w-full mt-5 flex items-center justify-between">
      {showSkeletonScreen ? (
        <MetricsSkeletonLoadingPanel width={170} height={45} />
      ) : (
        renderDailyAverage({ data, previousData })
      )}
      {showSkeletonScreen ? (
        <MetricsSkeletonLoadingPanel width={170} height={45} />
      ) : (
        renderMeetingHours({ data, previousData })
      )}
      {showSkeletonScreen ? (
        <MetricsSkeletonLoadingPanel width={170} height={45} />
      ) : (
        renderNumberOfMeetings({ data, previousData })
      )}
      {showSkeletonScreen ? (
        <MetricsSkeletonLoadingPanel width={170} height={45} />
      ) : (
        renderMostBusy({ data })
      )}
    </div>
  );
}

function renderDailyAverage({ data, previousData }) {
  return (
    <div className={classNames("metrics-data-container", CONTAINER_CLASS_NAME)}>
      <div className="flex items-center metrics-default-font-size">
        <div className="metrics-secondary-text-color font-weight-400">
          Daily average
        </div>
      </div>

      <div
        className={classNames(
          "metrics-primary-text-color flex items-center mt-1",
          METRICS_CONTENT_FONT_CLASSNAME
        )}
      >
        {getMetricsDailyAverage({ data })}
        <div className="ml-1">hrs</div>
        <UpDownAbsoluteChange
          delta={getMetricsDailyAverageChange({ data, previousData })}
          isUp={getMetricsDailyAverageIsUp({ data, previousData })}
        />
      </div>
    </div>
  );
}

function renderMeetingHours({ data, previousData }) {
  return (
    <div className={classNames("metrics-data-container", CONTAINER_CLASS_NAME)}>
      <div className="flex items-center metrics-default-font-size">
        <div className="metrics-secondary-text-color font-weight-400">
          Meeting hours
        </div>
      </div>

      <div
        className={classNames(
          "metrics-primary-text-color flex items-center mt-1",
          METRICS_CONTENT_FONT_CLASSNAME
        )}
      >
        {getMetricsMeetingHours({ data })}
        <UpDownAbsoluteChange
          delta={getMetricsMeetingHoursChange({ data, previousData })}
          isUp={getMetricsMeetingHoursIsUp({ data, previousData })}
        />
      </div>
    </div>
  );
}

function renderNumberOfMeetings({ data, previousData }) {
  return (
    <div className={classNames("metrics-data-container", CONTAINER_CLASS_NAME)}>
      <div className="flex items-center metrics-default-font-size">
        <div className="metrics-secondary-text-color font-weight-400">
          Meetings
        </div>
      </div>

      <div
        className={classNames(
          "metrics-primary-text-color flex items-center mt-1",
          METRICS_CONTENT_FONT_CLASSNAME
        )}
      >
        {getMetricsMeetings({ data })}
        <UpDownAbsoluteChange
          delta={getMetricsMeetingsChange({ data, previousData })}
          isUp={getMetricsMeetingsIsUp({ data, previousData })}
          showOnlyAbsoluteValue={true}
        />
      </div>
    </div>
  );
}

function renderMostBusy({ data }) {
  return (
    <div className={classNames("metrics-data-container", CONTAINER_CLASS_NAME)}>
      <div className="flex items-center metrics-default-font-size">
        <div className="metrics-secondary-text-color font-weight-400">
          Busiest day
        </div>
      </div>

      <div
        className={classNames(
          "metrics-primary-text-color flex items-center mt-1",
          METRICS_CONTENT_FONT_CLASSNAME
        )}
      >
        {getMetricsBusiestDay({ data, spellOutEntireDay: true })}
      </div>
    </div>
  );
}
