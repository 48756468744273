import classNames from "classnames";
import React, { useState } from "react";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import {
  determineCalendarColor,
  getCalendarName,
  getCalendarsGroupedByUserEmail,
} from "../../../../lib/calendarFunctions";
import { getUpcomingCalendarUserCalendarIDs } from "../../../../lib/userFunctions";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import broadcast from "../../../../broadcasts/broadcast";
import { getCalendarUserCalendarID } from "../../../../services/calendarAccessors";
import { isMac } from "../../../../services/commonUsefulFunctions";
import { usePreloadImages } from "../../../../services/customHooks/usePreloadImages";
import {
  BLUE_BUTTON,
} from "../../../../services/globalVariables";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import CustomButton from "../../../customButton";
import DisabledButton from "../../../disabledButton";
import ColoredLine from "../../../line";
import { isCalendarExecutiveCalendar, isUserMaestroUser } from "../../../../services/maestroFunctions";
import ExecutiveLabel from "../../../../../components/executiveLabel";

const DARK_MODE_MENU_BAR_IMAGE =
  "https://vimcal-app-images.s3.amazonaws.com/dark-mode-menu-bar.png";
const LIGHT_MODE_MENU_BAR_IMAGE =
  "https://vimcal-app-images.s3.amazonaws.com/light-mode-menu-bar.png";
const DARK_MODE_NOTIFICATIONS_IMAGE =
  "https://vimcal-app-images.s3.amazonaws.com/dark-mode-notification.png";
const LIGHT_MODE_NOTIFICATIONS_IMAGE =
  "https://vimcal-app-images.s3.amazonaws.com/light-mode-notification.png";
const LIGHT_MODE_AGENDA_IMAGE = "https://vimcal-app-images.s3.amazonaws.com/light+mode-agenda.png";
const DARK_MODE_AGENDA_IMAGE = "https://vimcal-app-images.s3.amazonaws.com/dark+mode-agenda.png";

export default function MenuBarSettings() {
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);

  const { orderedUserEmails, indexByUserEmail } =
    getCalendarsGroupedByUserEmail({ allCalendars, currentUser, masterAccount, allLoggedInUsers });
  const [menuBarCalendars, setCalendar] = useState(
    getUpcomingCalendarUserCalendarIDs({ masterAccount, allCalendars, allLoggedInUsers })
  );
  const [hasChanged, setHasChanged] = useState(false);
  usePreloadImages([
    DARK_MODE_MENU_BAR_IMAGE,
    LIGHT_MODE_MENU_BAR_IMAGE,
    DARK_MODE_NOTIFICATIONS_IMAGE,
    LIGHT_MODE_NOTIFICATIONS_IMAGE,
  ]);

  const onClickCalendar = (calendar) => {
    let updatedIDs;
    const selectedCalendarID = getCalendarUserCalendarID(calendar);
    if (menuBarCalendars.includes(selectedCalendarID)) {
      updatedIDs = menuBarCalendars.filter((id) => id !== selectedCalendarID);
    } else {
      updatedIDs = menuBarCalendars.concat(selectedCalendarID);
    }
    setCalendar(updatedIDs);
    setHasChanged(true);
  };

  const onClickSave = () => {
    const updatedSettings = {
      menu_bar_calendars: menuBarCalendars.filter((id) => id),
    };
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings,
    });

    // repull data for menu bar and agenda
    broadcast.publish("REFRESH_MENU_BAR_APP");
    broadcast.publish("REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT");
    broadcast.publish("SYNC_ALL_LOGGED_IN_CALENDARS_AND_EVENTS");
    setHasChanged(false);
  };

  return (
    <div className="flex flex-col h-full">
      <div
        className={classNames(
          "secondary-text-color default-font-size select-none",
          "display-webkit-inline-box"
        )}
      >
        {"Select upcoming events you’d like to be notified for in "}
        <div
          className={classNames(
            "underline ml-1 cursor-pointer",
            "hover-container-show-item"
          )}
        >
          {"Up Next, "}
          <img
            alt=""
            className={"absolute invisible-hover-item"}
            src={isDarkMode ? DARK_MODE_AGENDA_IMAGE : LIGHT_MODE_AGENDA_IMAGE}
            style={{
              width: "420px",
              left: "400px",
              top: "109px",
            }}
          />
        </div>
        <div
          className={classNames(
            "underline ml-1 cursor-pointer",
            "hover-container-show-item"
          )}
        >
          {`${isMac() ? "" : "and"} notifications`}
          <img
            alt=""
            className={"absolute invisible-hover-item"}
            src={
              isDarkMode
                ? DARK_MODE_NOTIFICATIONS_IMAGE
                : LIGHT_MODE_NOTIFICATIONS_IMAGE
            }
            style={{
              width: "350px",
              left: "500px",
              top: "109px",
            }}
          />
        </div>

        {isMac() ? (
          <div className={classNames("ml-1", "flex items-center")}>
            <div>, and</div>
            <div className="underline ml-1 cursor-pointer hover-container-show-item">
              {"Menu Bar (Mac Desktop App only)"}
              <img
                alt=""
                className={"absolute invisible-hover-item"}
                src={
                  isDarkMode
                    ? DARK_MODE_MENU_BAR_IMAGE
                    : LIGHT_MODE_MENU_BAR_IMAGE
                }
                style={{
                  width: "350px",
                  left: "600px",
                  top: "109px",
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <ColoredLine inputClassName="my-4" />
      <div className="px-4 overflow-y-auto">
        {orderedUserEmails.map((email, index) => {
          const calendars = indexByUserEmail[email];
          return (
            <div
              key={`select-menu-bar-calendar-${email}`}
              className={index === orderedUserEmails.length - 1 ? "" : "mb-2"}
            >
              <div className="default-font-size secondary-text-color mb-1">
                {email}
              </div>
              {calendars.map((calendar) => {
                const isExecutiveCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar, allLoggedInUsers });
                return (
                  <div
                    key={`select-calendar-child-${getCalendarUserCalendarID(
                      calendar
                    )}`}
                    className={classNames(
                      "default-font-size flex items-center select-none h-5",
                      "cursor-pointer",
                      "default-hover-background-color",
                      "py-3.5",
                      "-ml-2",
                      "px-2",
                      "rounded-md",
                      "max-w-xs",
                      "truncate-text"
                    )}
                    onClick={() => {
                      onClickCalendar(calendar);
                    }}
                  >
                    <div
                      className="h-4 w-4 rounded mr-2 flex items-center justify-center"
                      style={{
                        backgroundColor: menuBarCalendars.includes(
                          getCalendarUserCalendarID(calendar)
                        )
                          ? determineCalendarColor(calendar)
                          : undefined,
                        border: `1px solid ${determineCalendarColor(calendar)}`,
                      }}
                    >
                      {menuBarCalendars.includes(
                        getCalendarUserCalendarID(calendar)
                      ) ? (
                        <Check color={"white"} size={12} />
                      ) : null}
                    </div>
                    <div className="truncate-text mr-2">
                      {getCalendarName({calendar})}
                    </div>
                    {isExecutiveCalendar ? <ExecutiveLabel /> : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {renderButton({ hasChanged, onClickSave: onClickSave })}
    </div>
  );
}

function renderButton({ hasChanged, onClickSave }) {
  return (
    <div className="flex justify-between mt-4">
      <div></div>
      {hasChanged ? (
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={onClickSave}
          label="Save"
        />
      ) : (
        <DisabledButton />
      )}
    </div>
  );
}
