import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { useUserTimeZoneIndexStore } from "../../services/stores/userData";
import {
  useAllCalendars,
  useAllLoggedInUsers,
} from "../../services/stores/SharedAccountData";
import { isDayView } from "../../services/commonUsefulFunctions";
import UserLocalTimeZone from "../userLocalTimeZone";
import {
  getAllPrimaryCalendarsFromAllCalendars,
  getSecondaryCalendarsFromAllCalendars,
  isCalendarSelected,
} from "../../lib/calendarFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import {
  getCalendarColorHex,
  getCalendarEmail,
  getCalendarIsPrimary,
} from "../../services/calendarAccessors";
import { isUserExecutiveUser } from "../../services/maestroFunctions";
import { getMatchingUserFromAllUsers } from "../../lib/userFunctions";

export default function MainCalendarSplitViewHeader({
  displayName,
  email,
  index,
}) {
  const userTimeZoneIndex = useUserTimeZoneIndexStore(
    (state) => state.userTimeZoneIndex
  );
  const userTimeZone = userTimeZoneIndex[email];
  const selectedCalendarView = useSelector(
    (state) => state.selectedCalendarView
  );

  const temporarySecondaryCalendarColorsIndex = useSelector(
    (state) => state.temporarySecondaryCalendarColorsIndex
  );
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );

  const getMatchingSecondaryCalendar = () => {
    const secondaryCalendars =
      getSecondaryCalendarsFromAllCalendars(allCalendars); // always returns array
    return secondaryCalendars.find((calendar) =>
      isSameEmail(getCalendarEmail(calendar), email)
    );
  };
  const matchingSecondaryCalendarFromEmail = getMatchingSecondaryCalendar();

  const getMatchingCalendar = () => {
    // check in order:
    // 1. check primary calendars
    // 2. check all calendars for matching resourceId

    // 1. check current user calendars for matching resourceId
    const primaryCalendars =
      getAllPrimaryCalendarsFromAllCalendars(allCalendars);
    const matchingPrimaryCalendar = primaryCalendars?.find((calendar) =>
      isSameEmail(getCalendarEmail(calendar), email)
    );
    if (matchingPrimaryCalendar) {
      if (
        !isCalendarSelected(matchingPrimaryCalendar) &&
        matchingSecondaryCalendarFromEmail
      ) {
        return matchingSecondaryCalendarFromEmail;
      }
      return matchingPrimaryCalendar;
    }
    const allCalendarsArray = Object.values(allCalendars);

    // 2.
    const matchingCalendar = allCalendarsArray?.find((calendar) =>
      isSameEmail(getCalendarEmail(calendar), email)
    );
    if (matchingCalendar) {
      return matchingCalendar;
    }
    return null;
  };
  const matchingCalendarFromEmail = getMatchingCalendar();

  const getLabelColor = () => {
    // check in order from matchingCalendar above
    // 3. check temporary searched events for matching resourceId
    // 4. return null -> nothing found

    // 1. check current user calendars for matching resourceId
    if (
      matchingCalendarFromEmail &&
      getCalendarColorHex(matchingCalendarFromEmail)
    ) {
      return getCalendarColorHex(matchingCalendarFromEmail);
    }
    // 3.
    if (temporarySecondaryCalendarColorsIndex?.[email]) {
      return temporarySecondaryCalendarColorsIndex[email];
    }

    // 4.
    return null;
  };

  const labelColor = getLabelColor();

  if (index === 0) {
    const shouldDisplayLocalTimeZone =
      userTimeZone &&
      (temporarySecondaryCalendarColorsIndex?.[email] ||
        !getCalendarIsPrimary(matchingCalendarFromEmail) ||
        isUserExecutiveUser({
          user: getMatchingUserFromAllUsers({
            allUsers: allLoggedInUsers,
            userEmail: email,
          }),
        }));
    return (
      <div className="font-weight-300 font-size-12 width-100-percent display-flex justify-content-space-between align-items-center">
        {isDayView(selectedCalendarView) ? <RenderDateHeader /> : <div></div>}

        <div
          className={classNames(
            "user-select-none ml-2 flex flex-col gap-2 items-end",
            shouldDisplayLocalTimeZone ? "mt-2.5" : "",
            "justify-content-space-between"
          )}
        >
          <div className="flex items-center gap-2">
            {labelColor ? (
              <div
                style={{ backgroundColor: labelColor }}
                className="w-2.5 h-2.5 rounded-full flex-shrink-0 flex-grow-0"
              ></div>
            ) : null}
            <div className="truncate-resource-title">{displayName}</div>
          </div>

          {shouldDisplayLocalTimeZone ? (
            <UserLocalTimeZone
              email={email}
              className={"mt-0.5"}
              alwaysShowLocalTimeTooltip={true}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="font-weight-300 font-size-12 width-100-percent display-flex justify-content-space-between">
      <div className="w-1 h-4"></div>
      <div
        className={classNames(
          "flex flex-col gap-2 items-end",
          userTimeZone ? "mt-3" : ""
        )}
      >
        <div className="flex items-center gap-2">
          {labelColor ? (
            <div
              style={{ backgroundColor: labelColor }}
              className="w-2.5 h-2.5 rounded-full flex-shrink-0 flex-grow-0"
            ></div>
          ) : null}
          <div className="truncate-resource-title">{displayName}</div>
        </div>
        <UserLocalTimeZone
          email={email}
          className={"mt-0.5"}
          alwaysShowLocalTimeTooltip={true}
        />
      </div>
    </div>
  );
}

function RenderDateHeader() {
  const selectedDay = useSelector((state) => state.selectedDay);
  return (
    <div
      className={classNames(
        "cursor-default",
        "border-none",
        "display-flex",
        "flex-direction-column",
        "justify-content-center",
        "align-items-center"
      )}
    >
      <div
        className={classNames(
          "font-size-10",
          "w-8",
          "h-2.5",
          "font-weight-400",
          "font",
          "agenda-regular-blue",
          "transition-monthly-agenda-date",
          "leading-normal"
        )}
      >
        {format(selectedDay, "ccc").toUpperCase()}
      </div>

      <div
        className={classNames(
          "mt-1.5",
          "border-radius-5px",
          "display-flex",
          "justify-content-center",
          "align-items-center",
          "cursor-default",
          "agenda-regular-blue-background-color",
          "transition-monthly-agenda-date",
          "font-size-20",
          "font-weight-300",
          "custom-day-header-month",
          "text-white"
        )}
      >
        {format(selectedDay, "d")}
      </div>
    </div>
  );
}
