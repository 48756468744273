/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 */
export const KEYBOARD_EVENT_KEYS = {
  ENTER: "Enter",
  SPACE: " ",
  COMMA: ",",
  ESCAPE: "Escape",
  TAB: "Tab",
} as const;

export function isKeyDownTab(event: KeyboardEvent | React.KeyboardEvent): boolean {
  return event.key === KEYBOARD_EVENT_KEYS.TAB;
}
