import React, { useState } from "react";
import {
  useBackendSubscriptionStore,
  useStripeSubscriptions,
  useStripeUpcomingInvoices,
} from "../../../services/stores/finance";
import { format, secondsToMilliseconds } from "date-fns";
import {
  getHumanReadableDollarFromCents,
  getInvoiceDueDate,
  isScheduledToBeCancelled,
} from "../../../lib/stripeFunctions";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import CustomButtonV2 from "../../buttons/customButtonV2";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import ResumeSubscriptionModal from "../resumeSubscriptionModal";
import UpgradeBillingPlan from "../upgradeBillingPlan";
import { handleError } from "../../../services/commonUsefulFunctions";
import BlockWrapper from "./blockWrapper";
import { getBackendSubscriptionStripeSubID } from "../../../services/accessors/backendSubscription";

/**
 * Renders the cancellation block if the subscription is scheduled to be cancelled, or the
 * next payment block if there is an upcoming invoice. If neither case is true, an error is
 * raised.
 */
export default function NextBlock() {
  const stripeUpcomingInvoice = useStripeUpcomingInvoices(
    (state) => state.stripeUpcomingInvoices,
  );
  const stripeSubscription = useStripeSubscriptions(state => state.stripeSubscriptions);
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);

  if (!getBackendSubscriptionStripeSubID(backendSubscription)) {
    return null;
  }

  if (isScheduledToBeCancelled({ stripeSubscription })) {
    return <CancellationBlock />;
  }

  if (isEmptyObjectOrFalsey(stripeUpcomingInvoice)) {
    handleError(new Error("Subscription has no upcoming invoice and is not scheduled to be cancelled."));
    return null;
  }

  return <NextPaymentBlock />;
}

/**
 * Renders when the user has an active subscription with an upcoming payment.
 */
function NextPaymentBlock() {
  const stripeUpcomingInvoice = useStripeUpcomingInvoices(
    (state) => state.stripeUpcomingInvoices,
  );

  if (isEmptyObjectOrFalsey(stripeUpcomingInvoice)) {
    return null;
  }

  const dueDate = getInvoiceDueDate(stripeUpcomingInvoice);
  const formattedDueDate = format(secondsToMilliseconds(dueDate), "PP");

  return (
    <BlockWrapper title="Next payment">
      <div>
        <div>${getHumanReadableDollarFromCents(stripeUpcomingInvoice.amount_due)}</div>
        <div className="secondary-text-color">on {formattedDueDate}</div>
      </div>
      <div><UpgradeBillingPlan /></div>
    </BlockWrapper>
  );
}

/**
 * Renders when the user has a subscription scheduled to be cancelled.
 */
function CancellationBlock() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stripeSubscription = useStripeSubscriptions(state => state.stripeSubscriptions);

  const cancelAtDate = stripeSubscription.cancel_at ?? stripeSubscription.current_period_end;
  const formattedCancelAtDate = format(secondsToMilliseconds(cancelAtDate), "PP");

  return (
    <BlockWrapper title="Resume subscription">
      <div>
        <div>Scheduled to cancel</div>
        <div className="secondary-text-color">on {formattedCancelAtDate}</div>
      </div>
      <div>
        <CustomButtonV2 buttonType={BLUE_BUTTON} label="Resume" onClick={() => setIsModalOpen(true)} />
      </div>
      <ResumeSubscriptionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </BlockWrapper>
  );
}
