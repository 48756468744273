import React from "react";
import { ZoomRadioSetting } from "./inputComponents";
import { ZOOM_AUTO_RECORDING_OPTIONS, ZoomSettingsState } from "./sharedFunctions";

interface AutoRecordingSettingProps {
  autoRecording: ZoomSettingsState["autoRecording"]
  setAutoRecording: React.Dispatch<React.SetStateAction<ZoomSettingsState["autoRecording"]>>
}

export function AutoRecordingLocationSetting({ autoRecording, setAutoRecording }: AutoRecordingSettingProps) {
  if (autoRecording === ZOOM_AUTO_RECORDING_OPTIONS.NONE) {
    return null;
  }

  return (
    <div className="flex gap-8 mt-2">
      <ZoomRadioSetting
        label="Cloud"
        id="zoom-cloud-auto-record-toggle"
        isSelected={autoRecording === ZOOM_AUTO_RECORDING_OPTIONS.CLOUD}
        onClick={() => setAutoRecording(ZOOM_AUTO_RECORDING_OPTIONS.CLOUD)}
      />
      <ZoomRadioSetting
        label="Local"
        id="zoom-local-auto-record-toggle"
        isSelected={autoRecording === ZOOM_AUTO_RECORDING_OPTIONS.LOCAL}
        onClick={() => setAutoRecording(ZOOM_AUTO_RECORDING_OPTIONS.LOCAL)}
      />
    </div>
  );
}
