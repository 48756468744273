import React from "react";

export default function Nvidia({className = ""}) {
  return (
    <svg width="193" height="38" viewBox="0 0 193 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_145_735)">
        <path d="M117.785 7.85233V31.8901H124.569V7.85233H117.785ZM64.4131 7.81158V31.8697H71.2577V13.1895L76.5949 13.2099C78.3468 13.2099 79.5691 13.6377 80.4043 14.534C81.484 15.6748 81.9118 17.5285 81.9118 20.8898V31.8697H88.5527V18.5878C88.5527 9.09496 82.5025 7.81158 76.5949 7.81158H64.4131ZM128.724 7.85233V31.8901H139.725C145.591 31.8901 147.506 30.9123 149.564 28.7326C151.031 27.2048 151.968 23.8232 151.968 20.136C151.968 16.7544 151.173 13.7395 149.768 11.8654C147.282 8.5042 143.656 7.85233 138.238 7.85233H128.724ZM135.447 13.0673H138.36C142.597 13.0673 145.327 14.9618 145.327 19.8916C145.327 24.8214 142.597 26.7362 138.36 26.7362H135.447V13.0673ZM108.007 7.85233L102.344 26.8992L96.9252 7.85233H89.5916L97.3326 31.8901H107.111L114.933 7.85233H108.007ZM155.145 31.8901H161.929V7.85233H155.145V31.8901ZM174.172 7.85233L164.699 31.8697H171.381L172.889 27.6122H184.093L185.519 31.8493H192.791L183.237 7.85233H174.172ZM178.572 12.2321L182.687 23.4769H174.335L178.572 12.2321Z" fill="white"/>
        <path d="M20.6358 11.8449V8.54477C20.9617 8.5244 21.2877 8.50403 21.6136 8.50403C30.6583 8.21884 36.5863 16.2857 36.5863 16.2857C36.5863 16.2857 30.1898 25.1675 23.3248 25.1675C22.4081 25.1675 21.5118 25.0249 20.6562 24.7397V14.7172C24.1804 15.145 24.8933 16.6932 26.9916 20.2173L31.6973 16.2654C31.6973 16.2654 28.2546 11.7634 22.4692 11.7634C21.8581 11.743 21.2469 11.7838 20.6358 11.8449ZM20.6358 0.926025V5.8558L21.6136 5.79469C34.1825 5.3669 42.392 16.1024 42.392 16.1024C42.392 16.1024 32.9806 27.5509 23.1822 27.5509C22.3266 27.5509 21.4914 27.4694 20.6562 27.3268V30.3825C21.3488 30.464 22.0618 30.5251 22.7544 30.5251C31.8806 30.5251 38.4808 25.8601 44.8773 20.3599C45.9366 21.2155 50.2756 23.273 51.1719 24.1693C45.1014 29.2621 30.9435 33.3566 22.9174 33.3566C22.1433 33.3566 21.4099 33.3159 20.6765 33.2344V37.5327H55.348V0.926025L20.6358 0.926025ZM20.6358 24.7397V27.3472C12.2022 25.8397 9.85956 17.0598 9.85956 17.0598C9.85956 17.0598 13.9134 12.5782 20.6358 11.8449V14.6968H20.6154C17.0913 14.269 14.3208 17.5691 14.3208 17.5691C14.3208 17.5691 15.8894 23.1304 20.6358 24.7397ZM5.66313 16.6932C5.66313 16.6932 10.654 9.31887 20.6562 8.54477V5.8558C9.57436 6.75213 0 16.1228 0 16.1228C0 16.1228 5.41868 31.8084 20.6358 33.2344V30.3825C9.47251 28.9972 5.66313 16.6932 5.66313 16.6932Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_145_735">
          <rect width="192.791" height="36.6303" fill="white" transform="translate(0 0.914429)"/>
        </clipPath>
      </defs>
    </svg>

  );
}
