import { useDraggable, useDroppable } from "@dnd-kit/core";
import { format, setHours, setMinutes, startOfMinute } from "date-fns";
import React from "react";

export const TIME_SEGMENTS = {
  START_OF_HOUR: 0,
  FIFTEEN_MINUTES: 15,
  HALF_HOUR: 30,
  FORTY_FIVE_MINUTES: 45,
} as const;

interface DroppableTimeProps {
  hour: number
  date: Date
  row: number
  setTime: (time: Date) => void
  timeSegment: ValueOf<typeof TIME_SEGMENTS>
}

export default function DroppableTime({ hour, date, row, setTime, timeSegment }: DroppableTimeProps) {
  const datetime = startOfMinute(setMinutes(setHours(date, hour), timeSegment));

  const idSuffix = `${format(date, "yyyy-MM-dd")}-${row}-${hour}-${timeSegment}`;

  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: `droppable-${idSuffix}`,
    data: { datetime },
  });

  const { setNodeRef: setDraggableNodeRef, attributes, listeners } = useDraggable({
    id: `draggable-${idSuffix}`,
    data: { fixedEdgeTimestamp: datetime },
  });

  const setNodeRef = (element: HTMLElement | null) => {
    setDroppableNodeRef(element);
    setDraggableNodeRef(element);
  };

  const onClick = () => {
    setTime(datetime);
  };

  return (
    <div
      className="flex-1"
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      onClick={onClick}
    >
    </div>
  );
}
