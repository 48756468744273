import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SaveButton from "../saveButton";
import {
  determineSaveButtonShortcut,
  isMac,
  renderTimeSlotsLine,
  createAvailabilityTextFromEvent,
} from "../../services/commonUsefulFunctions";
import Mousetrap from "mousetrap";
import classNames from "classnames";
import EmptyStatePanel from "../availability/emptyStatePanel";
import { ONBOARDING_AVAILABILITY_COPY_BUTTON_ID } from "../../services/elementIDVariables";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";

const CONTENT_WIDTH = 325;

export default function AvailabilityPanel(props) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [isSubmitting] = useState(false);
  const [content, setContent] = useState("");
  const [shouldFlashContentBox, setFlashContentBox] = useState(false);

  const _isMounted = useRef(null);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      Mousetrap.reset();
      _isMounted.current = false;
    };
  }, []);

  const copyContent = () => {
    props.onCopyAvailability(content);
  };

  useEffect(() => {
    setContent(
      createAvailabilityTextFromEvent({
        eventList: props.availabilitySlots,
        currentTimeZone: props.currentTimeZone,
        format24HourTime: false,
      })
    );

    setFlashContentBox(true);
    setTimeout(() => {
      if (!_isMounted.current) {
        return;
      }

      setFlashContentBox(false);
    }, 4000);
  }, [props.availabilitySlots, props.currentTimeZone]);

  useEffect(() => {
    Mousetrap.bind(["command+c", "command+C", "ctrl+c", "ctrl+C"], copyContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const renderBlinkingUnderline = () => {
    if (
      !props.showUnderline ||
      isEmptyArrayOrFalsey(props.availabilitySlots)
    ) {
      return;
    }

    return <div className="blinking-underline"></div>;
  };

  const renderContent = () => {
    if (isEmptyArrayOrFalsey(props.availabilitySlots)) {
      return (
        <EmptyStatePanel 
          isDarkMode={isDarkMode} 
          inputClassName="margin-left-0px-override mt-10" 
          onClickShowMeHow={props.onClickShowMeHowSlots}
        />
      );
    }

    const determineWhenContent = () => {
      if (isEmptyArrayOrFalsey(props.availabilitySlots)) {
        return (
          <div>
            Drag the slots on the calendar to create bookable times for each day
            of the week.
          </div>
        );
      } else {
        return (
          <div
            id="availability-text-content"
            className={classNames(
              "select-availability-panel-content-wrapper",
              "margin-top-ten",
              !props.stopAvailabilityFlash && shouldFlashContentBox
                ? "availability-attention-animation"
                : null
            )}
            style={{ width: CONTENT_WIDTH, maxHeight: 290 }}
          >
            {renderBlinkingUnderline()}
            {renderTimeSlotsLine(content)}
          </div>
        );
      }
    };

    return (
      <div>
        <div>{determineWhenContent()}</div>

        {props.blurAvailabilityCopy 
          ? <div className="greyed-out-areas mt-7 w-full h-12 rounded-md"></div>
          : renderCopyButton(copyContent)}
      </div>
    );
  };

  const renderCopyButton = (copyContent) => {
    return (
      <div
        className="create-availability-copy-button-wrapper"
        style={{ marginTop: 20 }}
      >
        <SaveButton
          className="event-form-save-button"
          style={{
            width: "100%",
            height: "43px",
          }}
          onClick={copyContent}
          doNotParseText={isSubmitting}
          shortcut={determineSaveButtonShortcut(isSubmitting, isMac())}
          buttonText={"Copy to clipboard"}
          buttonId={ONBOARDING_AVAILABILITY_COPY_BUTTON_ID}
        />
      </div>
    );
  };

  return (
    <div className="pl-5 pr-5">
      <div className="mt-5 font-weight-400 font-size-16 font-weight-400">
        Select Availability
      </div>

      <div className="font-weight-300 default-font-size mt-2">
        Drag the slots on your calendar to share with others.
      </div>

      {renderContent(props)}
    </div>
  );
}
