import React from "react";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";

interface DiscardChangesContentsProps {
  onCancel: () => void;
  onDiscard: () => void;
}

export function DiscardChangesContents({
  onCancel,
  onDiscard,
}: DiscardChangesContentsProps) {
  return (
    <SaveAndCancelButton
      onClose={onCancel}
      onConfirm={onDiscard}
      confirmButtonText={"Discard"}
      autoFocusConfirmButton={true}
    />
  );
}
