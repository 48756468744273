import classNames from "classnames";
import React from "react";
import VimcalIcon from "../resources/vimcalIcon";

interface LogoFlashProps {
  containerClassName?: string;
}

export default function LogoFlash(props: LogoFlashProps) {
  const { containerClassName } = props;
  return (
    <div className={classNames(containerClassName ?? "", "flex items-center")}>
      <VimcalIcon className="opacity-50 mb-4" height={30} width={30} />
    </div>
  );
}
