import { pointerWithin, type CollisionDetection } from "@dnd-kit/core";

/**
 * Based on the `pointerWithin` collision detection algorithm from dnd-kit.
 * The base algorithm does not work in all cases, particularly when the draggable element is significantly larger
 * than the drop zones. In those cases, the offset between the draggable's center and the mouse position when dragging
 * started should be taken into account.
 * @see https://github.com/clauderic/dnd-kit/blob/d9aed3906cb126daedd1adc9dbb5e127403fd1f5/packages/core/src/utilities/algorithms/pointerWithin.ts
 */
export const pointerWithinWithOffset: AddParameters<CollisionDetection, [offset: { x: number, y: number } | null]> = (
  { pointerCoordinates: originalCoordinates, ...rest },
  offset,
) => {
  if (!originalCoordinates) {
    return [];
  }

  const pointerCoordinates = {
    x: originalCoordinates.x + (offset?.x ?? 0),
    y: originalCoordinates.y + (offset?.y ?? 0),
  };

  return pointerWithin({ pointerCoordinates, ...rest });
};
