import React from "react";
import EventTitleInput from "../../../eventTitleInput";
import SelectColor from "../../../selectColor";
import DefaultSwitch from "../../../defaultSwitch";
import { getCalendarColorHex } from "../../../../services/calendarAccessors";
import { isCalendarOutlookCalendar } from "../../../../lib/calendarFunctions";

export default function CustomizeSlotsHolds({ 
  holdTitle, 
  setHoldTitle, 
  holdColorID, 
  setHoldColorID,
  shouldAddAttendeesToHolds,
  setShouldAddAttendeesToHolds,
  selectedCalendar,
  selectedUser,
}) {
  const renderHoldTitleSection = () => {
    return (
      <div className="flex items-center justify-between">
        <div className="default-font-size mt-2.5">Title</div>
        <EventTitleInput
          width={280}
          onSaveTitle={setHoldTitle}
          inputPaddingLeft={0}
          summary={holdTitle}
          containerId={"default-additional-details"}
          hideHint={true}
          hideAllSuggestions={true}
        />
      </div>
    );
  };

  const renderHoldColorSection = () => {
    if (isCalendarOutlookCalendar(selectedCalendar)) {
      return null;
    }

    return (
      <div className="flex items-center slots-row-default-margin-top">
        <div className="default-font-size" style={{marginRight: 338}}>Holds Color</div>
        <SelectColor
          onChange={(colorObject) => {
            setHoldColorID(colorObject.value);
          }}
          selectedColorID={holdColorID}
          showBorder={true}
          defaultColorOverride={getCalendarColorHex(selectedCalendar)}
          selectedUser={selectedUser}
        />
      </div>
    );
  };

  const renderHoldAttendeeSection = () => {
    return (
      <div className="flex items-center justify-start slots-row-default-margin-top">
        <div 
          className="mr-24 cursor-pointer select-none default-font-size"
          style={{marginRight: 320}}
          onClick={() => setShouldAddAttendeesToHolds(!shouldAddAttendeesToHolds)}
        >
          Add attendees
        </div>
        
        <DefaultSwitch
          id="holds-add-attendee-id"
          isChecked={shouldAddAttendeesToHolds}
          onChange={(isOn) => {
            setShouldAddAttendeesToHolds(isOn);
          }}
        />
      </div>
    )
  }

  return (
    <div>
      {renderHoldTitleSection()}
      {renderHoldColorSection()}
      {renderHoldAttendeeSection()}
      <div className="h-40"></div>
    </div>
  );
}
