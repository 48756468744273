import React from "react";
import Toolbar from "rbc-fork-react-big-calendar/lib/Toolbar";
import { withRouter } from "react-router-dom";
import { connect, batch } from "react-redux";
import { NEXT, PREVIOUS, TODAY } from "../services/googleCalendarService";
import CustomButton from "./customButton";
import ShortcutHoverHint from "./shortcutHoverHint";
import GlobalKeyMapTile from "./globalKeyMapTile";
import { AlignLeft, Search, ChevronLeft, ChevronRight } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import {
  addAbbrevationToTimeZone,
  convertDateIntoEpochUnixWeek,
  hasStateOrPropsChanged,
  calculateMarginTopClassname,
  determineScrollToHour,
  createAmPmBarWithHourDiff,
  KEYCODE_W,
  KEYCODE_D,
  KEYCODE_4,
  KEYCODE_M,
  KEYCODE_ESCAPE,
  localData,
  getFirstDayOfWeekJsDate,
  getLastDayOfWeekJsDate,
  convertToTimeZone,
  isOnboardingMode,
  hasStopEventPropagation,
  isValidJSDate,
  getTodayDate,
  isFirefox,
  isDayView,
} from "../services/commonUsefulFunctions";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import {
  BACKEND_DAY_VIEW,
  BACKEND_4_DAY_VIEW,
  BACKEND_WEEK,
  BACKEND_MONTH,
  LAST_SELECTED_DAY,
  ROLLING_SEVEN_DAY,
  SET_GLOBAL_SHORT_CUT_SUGGESTION,
  DEFAULT_FONT_COLOR,
  OPEN_SIDE_MENU_PANEL_HOTKEY,
  TOGGLE_RIGHT_PANEL_HOTKEY,
  WHITE_BUTTON_WITHOUT_FOCUS,
  BLUE_BUTTON_WITHOUT_FOCUS,
  SECOND_IN_MS,
} from "../services/globalVariables";
import { createWindow, isUserInOnboarding } from "../lib/stateManagementFunctions";
import {
  isSameWeek,
  formatISO,
  format,
  isSameMonth,
  isSameYear,
  isSameDay,
} from "date-fns";
import classNames from "classnames";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  useSubscriptionStore,
  useDefaultPaymentMethod,
} from "../services/stores/finance";
import { useAllCalendars, useMasterAccount } from "../services/stores/SharedAccountData";
import { dropdownTips } from "../services/tooltipVariables";
import WelcomeTips from "./tooltips/welcomeTips";
import { useHideRightHandSidebar, useTutorialWizard } from "../services/stores/appFunctionality";
import {
  blurCalendar,
  isActionModeCreateAvailability,
  isActionModeUpsertEvent,
  isAppInTaskMode,
  isInActionMode,
  isValidCalendarView,
  shouldHideRightHandSide,
  shouldTruncateRightHandPanel,
  updateMasterAccountSettingsForFrontendAndBackend,
} from "../services/appFunctions";
import DoorNotFilled from "../resources/doorNotFilled";
import { getAccountCompletedToolTips } from "../lib/userFunctions";
import { BACKEND_SETTINGS_NAMES } from "../lib/vimcalVariables";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";
import { TUTORIAL_WIZARD_EXPERIMENT_BUCKET, getTutorialWizardBucket } from "../lib/tracking/onboardingTracking";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { LOCAL_DATA_ACTION } from "../lib/localData";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import CustomDropdownContainer from "./customDropdownContainer";
import { CALENDAR_TYPE_DROPDOWN_ID } from "../services/elementIDVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { getDefaultUserTimeZone, getIsWelcomeTourDismissed } from "../lib/settingsFunctions";
import { HOT_KEY_MAP } from "../lib/hotkeyFunctions";
import { doesAllCalendarsContainOutlookCalendars, getActiveSelectedCalendars } from "../lib/calendarFunctions";
import { OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT } from "../lib/outlookFunctions";

const searchHintStyle = { marginLeft: "-24px", marginTop: "14px" };

const todayShortCutHint = { marginLeft: "-14px" };
let previousWeekGlobalKeymapStyle = { top: "-27px", left: "-16px" };

const menuHint = { marginLeft: "10px", marginTop: "10px" };

let globalKeyMapTileForSideMenu = {
  right: "-40px",
  top: isFirefox() ? "0px" : "-16px",
};

let menuIconStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: 50,
  height: 30,
};

let todayHotKeyHintStyle = {
  left: "-13px",
  top: "-27px",
};

const searchIconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

class MainCalendarToolBar extends Toolbar {
  constructor(props) {
    super(props);
    
    this._isWelcomeTipsHidden = this.shouldHideWelcomeTips(dropdownTips.length);

    this.state = {
      isOnboardingMode: isOnboardingMode(),
      shouldDisplayWelcomeTips: false,
      dropdownTipsCount: dropdownTips.length,
    };

    this._eventWindowSelectedDay = props.selectedDay;
    this._fetchTimeout = null;
    this._hoverTriggerCountOnHideRightHandSide = 0; // used to hide hint once component moves since onMouseLeave is never triggered

    this.pressToday = this.pressToday.bind(this);
    this.pressPrevious = this.pressPrevious.bind(this);
    this.pressNext = this.pressNext.bind(this);
    this.onClickSideMenu = this.onClickSideMenu.bind(this);
    this.onClickSearchIcon = this.onClickSearchIcon.bind(this);
    this.focusOnWeeklyCalendarToolBar =
      this.focusOnWeeklyCalendarToolBar.bind(this);
    this.onClickContainer = this.onClickContainer.bind(this);
    this.onChangeCalendarView = this.onChangeCalendarView.bind(this);
    this.determineCalendarViewChange =
      this.determineCalendarViewChange.bind(this);
    this.onKeyDownCalendarView = this.onKeyDownCalendarView.bind(this);
    this.changeTimeZone = this.changeTimeZone.bind(this);
    this.toggleShouldDisplayWelcomeTips =
      this.toggleShouldDisplayWelcomeTips.bind(this);
    this.handleClickOutsideTips = this.handleClickOutsideTips.bind(this);
    this.hideTooltipBox = this.hideTooltipBox.bind(this);
    this.jumpToDateAndScrollToTime = this.jumpToDateAndScrollToTime.bind(this);

    this.welcomeTipsRef = React.createRef();

    Broadcast.subscribe("PRESS_TODAY", this.pressToday);
    Broadcast.subscribe("NEXT_WEEK", this.pressNext);
    Broadcast.subscribe("PREVIOUS_WEEK", this.pressPrevious);
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.BLUR_WEEKLY_CALENDAR,
      this.focusOnWeeklyCalendarToolBar
    );
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
      this.determineCalendarViewChange
    );
    Broadcast.subscribe("HIDE_TOOLTIP_BOX", this.hideTooltipBox);
    mainCalendarBroadcast.subscribe("JUMP_TO_DATE_AND_SCROLL_TO_TIME", this.jumpToDateAndScrollToTime);
  }

  determineScrollToHour() {
    const {
      currentTimeZone,
      defaultBrowserTimeZone,
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const scrollTime = createAmPmBarWithHourDiff({
      timeZone: currentTimeZone,
      otherTimeZone: defaultBrowserTimeZone,
      masterAccount,
      user: currentUser,
    });

    if (scrollTime?.start < 18) {
      return scrollTime.start;
    }

    return 8;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "views", "localizer", "location"],
      null
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    Broadcast.unsubscribe("PRESS_TODAY");
    Broadcast.unsubscribe("NEXT_WEEK");
    Broadcast.unsubscribe("PREVIOUS_WEEK");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.BLUR_WEEKLY_CALENDAR);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE);
    Broadcast.unsubscribe("HIDE_TOOLTIP_BOX");
    mainCalendarBroadcast.unsubscribe("JUMP_TO_DATE_AND_SCROLL_TO_TIME");

    document.removeEventListener("mousedown", this.handleClickOutsideTips);
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkHideWelcomeTipsForPersonalOnboarding();
    document.addEventListener("mousedown", this.handleClickOutsideTips);
  }

  componentDidUpdate(prevProps) {
    this.checkForSkippingWelcomeTips();
    if (!(this.props.selectedDay && prevProps.selectedDay)) {
      return;
    }

    if (this.props.selectedDay !== prevProps.selectedDay) {
      localData(LOCAL_DATA_ACTION.SET, LAST_SELECTED_DAY, formatISO(this.props.selectedDay));
    }

    if (this._eventWindowSelectedDay === this.props.selectedDay) {
      return;
    }
    let selectedDayEpoch = convertDateIntoEpochUnixWeek(this.props.selectedDay);

    const {
      weekStart,
      selectedCalendarView
    } = this.props;
    const { 
      movingWindowStartDate, 
      movingWindowEndDate,
    } = createWindow({
      windowJSDate: getSelectedDayWithBackup(this._eventWindowSelectedDay || prevProps.selectedDay), 
      isMonth: selectedCalendarView === BACKEND_MONTH, 
      weekStart, 
      selectedCalendarView
    });

    if (
      selectedDayEpoch <= movingWindowStartDate ||
      selectedDayEpoch >= movingWindowEndDate
    ) {
      this._eventWindowSelectedDay = this.props.selectedDay;
      clearTimeout(this._fetchTimeout);
      // so we don't fetch immediatiely and run into throttling issues
      this._fetchTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        Broadcast.publish(BROADCAST_VALUES.PULL_DATA_FROM_DATE, {date: this.props.selectedDay});
        Broadcast.publish(
          "UPDATE_SECONDARY_CALENDAR_WINDOW",
          this.props.selectedDay
        );
        if (isActionModeCreateAvailability(this.props.actionMode)) {
          availabilityBroadcast.publish("CHECK_FOR_BLOCKED_CALENDAR_CONFLICTS");
        }
      }, 0.5 * SECOND_IN_MS);
    } else if (this.shouldFetchForNewEventsOnHeavyOutlookCalendars()) {
      this._eventWindowSelectedDay = this.props.selectedDay;
      clearTimeout(this._fetchTimeout);
      this._fetchTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        Broadcast.publish(BROADCAST_VALUES.PULL_DATA_FROM_DATE, {date: this.props.selectedDay});
      }, 0.5 * SECOND_IN_MS);
    }
  }

  render() {
    return (
      <div
        className={classNames(
          "weekly-tool-bar",
          this.state.isOnboardingMode
            ? ""
            : calculateMarginTopClassname(this.props.shouldShowTopBar)
        )}
        onClick={this.onClickContainer}
      >
        <div className="side-bar-menu-in-weekly-toolbar">
          <div className="side-bar-menu-icon-weekly-toolbar">
            <ShortcutHoverHint
              below
              style={menuHint}
              title={"Options"}
              shortcut={OPEN_SIDE_MENU_PANEL_HOTKEY}
            >
              <GlobalKeyMapTile
                style={globalKeyMapTileForSideMenu}
                shortcut={OPEN_SIDE_MENU_PANEL_HOTKEY}
              />

              <div
                style={menuIconStyle}
                onClick={this.onClickSideMenu}
                className="padding-left-15"
              >
                <AlignLeft size="20" className="clickable-icon" />
              </div>
            </ShortcutHoverHint>
          </div>

          <div className="weekly-toolbar-header">
            <div className="main-calendar-month-time-zone-wrapper">
              <div
                className={classNames(
                  "weekly-toolbar-start-to-end-of-week-header",
                  this.props.isMobileView
                    ? "font-weight-300 mt-1"
                    : "font-weight-400"
                )}
              >
                {this.determineToolBarHeaderLabel()}
              </div>

              {this.props.isMobileView ? null : (
                <div className="main-calendar-tool-bar-time-zone-label">
                  <ShortcutHoverHint
                    below
                    style={{
                      marginLeft: "0px",
                      marginTop: "5px",
                    }}
                    title={"Time Travel"}
                    shortcut={"Z"}
                  >
                    <div
                      className="hoverable-secondary-text-color cursor-pointer margin-top-5 font-size-10"
                      onClick={this.changeTimeZone}
                    >
                      {addAbbrevationToTimeZone({
                          timeZone: this.getCurrentTimeZone(),
                          inputDate: getSelectedDayWithBackup(this.props.selectedDay)
                        })}
                    </div>
                  </ShortcutHoverHint>
                </div>
              )}
            </div>

            <div className={this.getTodayContainerClassName()}>
              <GlobalKeyMapTile style={todayHotKeyHintStyle} shortcut={"T"} />

              <ShortcutHoverHint
                below
                style={todayShortCutHint}
                title={"Today"}
                shortcut={"T"}
              >
                <CustomButton
                  buttonType={WHITE_BUTTON_WITHOUT_FOCUS}
                  onClick={this.pressToday}
                  label="Today"
                  className="min-width-initial-important"
                  labelClassNameOverride="font-size-300-important font-size-12px-override"
                />
              </ShortcutHoverHint>
            </div>

            <div className="main-calendar-button-left-wrapper">
              <ShortcutHoverHint
                below
                style={{
                  marginLeft: "-58px",
                  marginTop: 100,
                  width: "max-content",
                }}
                title={this.determineShortCutHint(false)}
                shortcut={HOT_KEY_MAP.LAST_WEEK}
                containerStyle={{ display: "flex", alignItems: "center" }}
                hintMarginTop={70}
              >
                <GlobalKeyMapTile
                  style={previousWeekGlobalKeymapStyle}
                  shortcut={HOT_KEY_MAP.LAST_WEEK}
                />

                <button
                  onClick={this.pressPrevious}
                  ref={(input) => {
                    this.focusOnWeeklyCalendarToolBar = input;
                  }}
                  className="background-color-transparent border-transparent"
                >
                  <ChevronLeft
                    className="clickable-icon margin-top-five"
                    size="18"
                  />
                </button>
              </ShortcutHoverHint>
            </div>

            <ShortcutHoverHint
              below
              style={{
                marginLeft: "-25px",
                marginTop: 100,
                width: "max-content",
              }}
              title={this.determineShortCutHint(true)}
              shortcut={HOT_KEY_MAP.NEXT_WEEK}
              containerStyle={{ display: "flex", alignItems: "center" }}
              hintMarginTop={70}
              inputClassName="main-calendar-toolbar-right-icon"
            >
              <GlobalKeyMapTile
                style={{
                  left: "-16px",
                  top: "-27px",
                }}
                shortcut={HOT_KEY_MAP.NEXT_WEEK}
              />

              <button
                className="background-color-transparent border-transparent"
                onClick={this.pressNext}
              >
                <ChevronRight
                  className="clickable-icon  margin-top-five"
                  size="18"
                />
              </button>
            </ShortcutHoverHint>
          </div>
        </div>

        <div className="weekly-calendar-toolbar-navigation">
          {this.renderWelcomeTips()}

          {this.renderSelectCalendarView()}

          {this.renderSearchIcon()}
          {this.renderToggleRightHandeBar()}
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderToggleRightHandeBar() {
    if (this.props.isMobileView) {
      return null;
    }

    const inTask = this.isAppInTaskMode();

    if (inTask) {
      return null;
    }

    const { setHideRightHandSide } = this.props.hideRightHandSidebar;

    const shouldTruncateRightPanel = shouldTruncateRightHandPanel(
      this.props.hideRightHandSidebar
    );

    const iconColor = this.props.isDarkMode ? "white" : DEFAULT_FONT_COLOR;

    return (
      <ShortcutHoverHint
        below
        title={shouldTruncateRightPanel ? "Show panel" : "Hide panel"}
        inputClassName="main-calendar-tool-bar-search-icon-wrapper"
        style={{
          width: "max-content",
          marginLeft: "-50px",
          marginTop: "14px",
          height: 33,
        }}
        hoverTriggerCount={this._hoverTriggerCountOnHideRightHandSide}
        shortcut={TOGGLE_RIGHT_PANEL_HOTKEY}
      >
        <GlobalKeyMapTile
          style={{
            left: "28px",
            top: isFirefox() ? "-5px" : "-17px",
          }}
          shortcut={TOGGLE_RIGHT_PANEL_HOTKEY}
        />

        <div
          className={classNames(
            this.props.isDarkMode ? "opacity-50" : "opacity-50",
            "hover:opacity-100 cursor-pointer duration-200 flex ml-5",
            inTask ? "invisible" : ""
          )}
          onClick={() => {
            if (inTask) {
              return;
            }

            setHideRightHandSide(!shouldTruncateRightPanel);
            // since the component moves -> onMouseOut never gets triggered -> we need to programatically remove hover
            this._hoverTriggerCountOnHideRightHandSide += 1;
          }}
        >
          <DoorNotFilled color={iconColor} />
        </div>
      </ShortcutHoverHint>
    );
  }

  renderSearchIcon() {
    if (this.isAppInTaskMode()) {
      return null;
    }

    return (
      <ShortcutHoverHint
        below
        style={searchHintStyle}
        title={"Search"}
        shortcut={"/"}
        inputClassName="main-calendar-tool-bar-search-icon-wrapper"
      >
        <GlobalKeyMapTile
          style={{
            left: "8px",
            top: isFirefox() ? "-5px" : "-17px",
          }}
          shortcut={"/"}
        />

        <span
          onClick={this.onClickSearchIcon}
          className={classNames(
            !isInActionMode(this.props.actionMode)
              ? "clickable-icon"
              : "disabled-icon"
          )}
          style={searchIconStyle}
        >
          <Search size="18" className="tool-bar-search-icon" />
        </span>
      </ShortcutHoverHint>
    );
  }

  isRightPanelTruncated() {
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      actionMode,
    } = this.props;
    return shouldHideRightHandSide({
      hideRightHandSideBar: this.props.hideRightHandSidebar,
      reverseSlotsText,
      actionMode,
    });
  }

  renderSelectCalendarView() {
    const {
      isMobileView,
    } = this.props;

    if (isMobileView) {
      return null;
    }

    const onClick = () => {
      layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.MAIN_CALENDAR_SELECT_TOOLBAR);
    };

    return (
      <CustomDropdownContainer
        onClick={onClick}
        id={CALENDAR_TYPE_DROPDOWN_ID}
        displayText={this.determineCalendarViewSelectionValue().label}
        inputClassNames={classNames(
          "select-calendar-view",
          this.isAppInTaskMode() ? "margin-right-0px-important" : "",
        )}
      />
    );
  }

  renderCalendarViewDropdown(text, shortcut = null) {
    return (
      <div className="display-flex-flex-direction-row align-items-center justify-content-space-between">
        <div>{text}</div>

        {shortcut ? (
          <ShortcutTile
            key={`calendar_view_${text}`}
            isCommandCenter={this.props.isDarkMode ? true : false}
            shortcut={shortcut}
            width={23}
            height={23}
          />
        ) : null}
      </div>
    );
  }

  determineToolBarHeaderLabel() {
    if (this.props.isMobileView) {
      return format(this.props.selectedDay, "MMMM");
    }

    if (this.props.selectedCalendarView === BACKEND_MONTH) {
      return format(this.props.selectedDay || new Date(), "MMMM yyyy");
    }

    let startOfWeek = getFirstDayOfWeekJsDate(
      this.props.date,
      this.props.weekStart
    );
    let endOfWeek = getLastDayOfWeekJsDate(
      this.props.date,
      this.props.weekStart
    );

    let fromMonthAbbreviated = format(startOfWeek, "MMM");
    let toMonth = format(endOfWeek, "MMMM");

    let toMonthAbbreviated = format(endOfWeek, "MMM");
    let toYear = format(endOfWeek, "yyyy");

    let monthPlusYear = toMonth + " " + toYear;
    let previousMonthDashCurrentMonth =
      fromMonthAbbreviated + " - " + toMonthAbbreviated + " " + toYear;

    if (!isSameYear(startOfWeek, endOfWeek)) {
      return (
        fromMonthAbbreviated +
        " " +
        format(startOfWeek, "yyyy") +
        " - " +
        toMonthAbbreviated +
        " " +
        toYear
      );
    }

    let displayMonth = isSameMonth(startOfWeek, endOfWeek)
      ? monthPlusYear
      : previousMonthDashCurrentMonth;

    return displayMonth;
  }

  determineCalendarViewSelectionValue() {
    if (this.isCalendarView(1)) {
      return { value: 1, label: "Day" };
    } else if (this.isCalendarView(4)) {
      return { value: 4, label: "4 days" };
    } else if (this.isCalendarView(7)) {
      return { value: 7, label: "Week" };
    } else if (this.isCalendarView(BACKEND_MONTH)) {
      return { value: BACKEND_MONTH, label: "Month" };
    } else if (this.isCalendarView(ROLLING_SEVEN_DAY)) {
      return { value: ROLLING_SEVEN_DAY, label: "7 days" };
    }
  }

  renderWelcomeTips() {
    if (
      isEmptyObjectOrFalsey(this.props.currentUser) ||
      isEmptyObjectOrFalsey(this.props.masterAccount?.masterAccount)
    ) {
      return null;
    }

    const completedTooltipsCount = this.getCompletedToolTipCount();

    if (!this.shouldShowWelcomeTips()) {
      return null;
    }

    return (
      <div
        className={classNames(
          "welcome-tips-container",
          shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)
            ? "welcome-tips-hide-right-panel-override"
            : ""
        )}
        ref={this.welcomeTipsRef}
      >
        <CustomButton
          buttonType={BLUE_BUTTON_WITHOUT_FOCUS}
          label={`Welcome Tips ${completedTooltipsCount}/${this.state.dropdownTipsCount}`}
          onClick={this.toggleShouldDisplayWelcomeTips}
          style={{ borderRadius: 4, marginRight: 36, whiteSpace: "nowrap" }}
          labelClassNameOverride="font-size-300-important"
        />
        <WelcomeTips
          isHidden={!this.state.shouldDisplayWelcomeTips}
          hideWelcomeTips={() =>
            this.setState({ shouldDisplayWelcomeTips: false })
          }
        />
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onClickSearchIcon(e) {
    hasStopEventPropagation(e);
    mainCalendarBroadcast.publish(
      SET_GLOBAL_SHORT_CUT_SUGGESTION,
      "/",
      "search"
    );
    Broadcast.publish("TOGGLE_SEARCH");
  }

  onKeyDownCalendarView(event) {
    if (event.keyCode === KEYCODE_W) {
      this.determineCalendarViewChange(7);
    } else if (event.keyCode === KEYCODE_D) {
      this.determineCalendarViewChange(1);
    } else if (event.keyCode === KEYCODE_4) {
      this.determineCalendarViewChange(4);
    } else if (event.keyCode === KEYCODE_ESCAPE) {
      blurCalendar();
    } else if (event.keyCode === KEYCODE_M) {
      this.determineCalendarViewChange(BACKEND_MONTH);
    }
  }

  focusOnWeeklyCalendarToolBar() {
    this.focusOnWeeklyCalendarToolBar?.focus();
  }

  changeTimeZone(e) {
    hasStopEventPropagation(e);
    mainCalendarBroadcast.publish(
      SET_GLOBAL_SHORT_CUT_SUGGESTION,
      "Z",
      "change time zone",
    );
    Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
  }

  pressToday(e) {
    const isSameWeekAsToday = isSameWeek(this.props.selectedDay, new Date());

    this.navigate(TODAY);

    if (!isActionModeUpsertEvent(this.props.actionMode)) {
      this.props.history.push("/home");
    }

    const updatedAgendaDay = convertToTimeZone(new Date(), {
      timeZone: this.props.currentTimeZone,
    });
    batch(() => {
      this.props.setAgendaDay(updatedAgendaDay);

      this.props.removePreviewedEvent();

      if (isActionModeUpsertEvent(this.props.actionMode)) {
        this.props.removePopupEvent();
      }

      // Only scroll to time if same week
      if (isSameWeekAsToday) {
        this.scrollToCurrentTime();
      }

      mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
    });
  }

  determineShortCutHint(isNext = true) {
    let shortcut = isNext ? "Next " : "Previous ";

    if (this.isCalendarView(1)) {
      return shortcut + " day";
    } else if (this.isCalendarView(4)) {
      return shortcut + " period";
    } else if (this.isCalendarView(7)) {
      return shortcut + " week";
    } else if (this.isCalendarView(BACKEND_MONTH)) {
      return shortcut + " month";
    } else {
      return shortcut + " week";
    }
  }

  scrollToCurrentTime(hardScroll = false) {
    const currentTime = convertToTimeZone(new Date(), {
      timeZone: this.props.currentTimeZone,
    });
    const scrollToHour = determineScrollToHour(currentTime);

    this.setScrollToTime(scrollToHour, hardScroll);
  }

  setScrollToTime(hour, hardScroll) {
    Broadcast.publish(`SCROLL_TO_HOUR_${hour}`, hardScroll);
  }

  onClickSideMenu() {
    this.props.setShouldDisplayMenu(true);
  }

  pressPrevious() {
    batch(() => {
      if (isActionModeUpsertEvent(this.props.actionMode)) {
        this.props.removePopupEvent();
      }

      if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
        this.props.removeCurrentHoverEvent();
      }
    });

    this.navigate(PREVIOUS);
  }

  onChangeCalendarView(option) {
    if (this.state.isOnboardingMode) {
      return;
    }
    blurCalendar();

    this.determineCalendarViewChange(option.value);
  }

  getCurrentTimeZone() {
    const {
      currentTimeZone,
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (currentTimeZone) {
      return currentTimeZone;
    }
    return getDefaultUserTimeZone({
      masterAccount,
      user: currentUser,
    });
  }

  determineCalendarViewChange(value, inputDay = null) {
    if (!isValidCalendarView(value)) {
      return;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;

    let backendDayValue;
    switch (value) {
      case 1:
        backendDayValue = BACKEND_DAY_VIEW;
        break;
      case 4:
        backendDayValue = BACKEND_4_DAY_VIEW;
        break;
      case 7:
        backendDayValue = BACKEND_WEEK;
        break;
      case ROLLING_SEVEN_DAY:
        backendDayValue = ROLLING_SEVEN_DAY;
        break;
      case BACKEND_MONTH:
        backendDayValue = BACKEND_MONTH;
        break;
      default:
        break;
    }

    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_CALENDAR_VIEW, value); // update front end first
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: {[BACKEND_SETTINGS_NAMES.CALENDAR_VIEW]: backendDayValue},
      skipNotification: true,
    });

    this.props.setAgendaDay(getTodayDate(this.props.currentTimeZone));

    Broadcast.publish(
      BROADCAST_VALUES.PULL_DATA_FROM_DATE, {
        date: inputDay && isValidJSDate(inputDay)
          ? inputDay
          : this.props.selectedDay,
        inputSelectedCalendarView: value,
      },
    );

    // react big calendar remounts time slot on change views
    // but if moving from month to week -> this is not an issue since monthly does not have timeSlotView
    // https://github.com/jquense/react-big-calendar/issues/1818
    // can remove else code once issue above gets resolved

    if (value !== BACKEND_MONTH) {
      // remap hot keys here
      Broadcast.publish("UPDATE_HOTKEY_EVENTS_MAPPING");
    }
  }

  pressNext() {
    batch(() => {
      if (isActionModeUpsertEvent(this.props.actionMode)) {
        this.props.removePopupEvent();
      }

      if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
        this.props.removeCurrentHoverEvent();
      }
    });

    this.navigate(NEXT);
  }

  onClickContainer() {
    if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      this.props.removePopupEvent();
    }
  }

  isCalendarView(view) {
    return this.props.selectedCalendarView === view;
  }

  hideTooltipBox() {
    if (this.state.shouldDisplayWelcomeTips) {
      this.setState({ shouldDisplayWelcomeTips: false });
    }
  }

  toggleShouldDisplayWelcomeTips() {
    this.setState({
      shouldDisplayWelcomeTips: !this.state.shouldDisplayWelcomeTips,
    });
  }

  handleClickOutsideTips(event) {
    if (
      this.welcomeTipsRef &&
      !this.welcomeTipsRef.current?.contains(event?.target)
    ) {
      this.setState({
        shouldDisplayWelcomeTips: false,
      });
    }
  }

  getTodayContainerClassName() {
    if (this.props.isMobileView) {
      return "ml-5 flex items-center";
    } else {
      const sharedClassName = "items-center";
      return this.isRightPanelTruncated()
        ? classNames(
            sharedClassName,
            "weekly-toolbar-today-shortcut-hint-truncated-right-panel"
          )
        : classNames(sharedClassName, "weekly-toolbar-today-shortcut-hint");
    }
  }

  isAppInTaskMode() {
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    return isAppInTaskMode({
      actionMode,
      reverseSlotsText,
    });
  }

  jumpToDateAndScrollToTime(jsDate) {
    if (!jsDate) {
      return;
    }

    this.props.setSelectedDay(jsDate);
    const scrollToHour = determineScrollToHour(jsDate);
    Broadcast.publish(`SCROLL_TO_HOUR_${scrollToHour}`, true);
  }

  shouldShowWelcomeTips() {
    if (!this.isInShowDropdownBucket()) {
      return false;
    }
    const {
      masterAccount
    } = this.props.masterAccount;
    if (isUserInOnboarding(masterAccount)) {
      return false;
    }
    const {
      currentUser
    } = this.props;
    if (
      isEmptyObjectOrFalsey(currentUser) ||
      isEmptyObjectOrFalsey(masterAccount)
    ) {
      return null;
    }

    if (getIsWelcomeTourDismissed({ masterAccount })) {
      return false;
    }

    return true;
  }

  isInShowDropdownBucket() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      isTutorialWizardShowing
    } = this.props.tutorialWizard;
    if (isTutorialWizardShowing) {
      return false;
    }
    return getTutorialWizardBucket({masterAccount}) === TUTORIAL_WIZARD_EXPERIMENT_BUCKET.CONTROL;
  }

  getCompletedToolTipCount() {
    const {
      masterAccount
    } = this.props.masterAccount;
    return dropdownTips.filter((tip) =>
    getAccountCompletedToolTips(masterAccount)
      .includes(
        tip.key
      )
    ).length;
  }

  shouldHideWelcomeTips(inputTipsCount) {
    if (!this.isInShowDropdownBucket()) {
      // if not -> always hide
      return true;
    }
    const completedTooltipsCount = this.getCompletedToolTipCount();
    return completedTooltipsCount === (inputTipsCount || this.state?.dropdownTipsCount); // since we call this in this.state construction
  }

  checkHideWelcomeTipsForPersonalOnboarding() {
    const {
      masterAccount
    } = this.props.masterAccount;
    if (isUserInOnboarding(masterAccount)) {
      this._isWelcomeTipsHidden = true;
      Broadcast.publish("SKIP_WELCOME_TIPS");
    }
  }

  checkForSkippingWelcomeTips() {
    if (this._isWelcomeTipsHidden) {
      return;
    }
    if (this.shouldHideWelcomeTips()) {
      // so we don't over call this
      this._isWelcomeTipsHidden = true
      Broadcast.publish("SKIP_WELCOME_TIPS");
    }
  }

  // if the user has more than 4 calendars, we need to fetch day by day otherwise syncing
  // a whole week is going to error out due to throttling from outlook
  shouldFetchForNewEventsOnHeavyOutlookCalendars() {
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      selectedCalendarView
    } = this.props;
    return isDayView(selectedCalendarView) 
      && isValidJSDate(this._eventWindowSelectedDay)
      && isValidJSDate(this.props.selectedDay)
      && !isSameDay(this._eventWindowSelectedDay, this.props.selectedDay)
      && getActiveSelectedCalendars(allCalendars).length >= OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT
      && doesAllCalendarsContainOutlookCalendars(allCalendars);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    setShouldDisplayMenu: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_DISPLAY_MENU" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    removeCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    setSelectedDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
  };
}

function mapStateToProps(state) {
  let {
    isMac,
    shouldShowTopBar,
    popupEvent,
    currentTimeZone,
    selectedDay,
    currentUser,
    isDarkMode,
    currentHoverEvent,
    currentTimeZoneLabel,
    defaultBrowserTimeZone,
    selectedCalendarView,
    shouldOnlyShowWorkWeek,
    dateFieldOrder,
    weekStart,
    isMobileView,
    actionMode,
  } = state;

  return {
    isMac,
    shouldShowTopBar,
    popupEvent,
    currentTimeZone,
    selectedDay,
    currentUser,
    isDarkMode,
    currentHoverEvent,
    currentTimeZoneLabel,
    defaultBrowserTimeZone,
    selectedCalendarView,
    shouldOnlyShowWorkWeek,
    dateFieldOrder,
    weekStart,
    isMobileView,
    actionMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const subscriptionStore = useSubscriptionStore();
  const defaultPaymentMethodStore = useDefaultPaymentMethod();
  const masterAccount = useMasterAccount();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const temporaryStateStore = useTemporaryStateStore();
  const tutorialWizard = useTutorialWizard();
  const allCalendars = useAllCalendars();

  return (
    <BaseComponent
      {...props}
      subscriptionStore={subscriptionStore}
      defaultPaymentMethodStore={defaultPaymentMethodStore}
      masterAccount={masterAccount}
      hideRightHandSidebar={hideRightHandSidebar}
      temporaryStateStore={temporaryStateStore}
      tutorialWizard={tutorialWizard}
      allCalendars={allCalendars}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(MainCalendarToolBar))
);
