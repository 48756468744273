import React from "react";
import { Star } from "react-feather";
import { GOLD_STAR_HEX_CODE } from "../../../lib/styleFunctions";
import { pluralize } from "../../../lib/stringFunctions";

export default function CriticalAttendeeCount(props) {
  const {
    criticalAttendeeCount,
    isMobileLayout,
  } = props;

  const determineVoteCountText = () => {
    if (!isMobileLayout?.current) {
      return "";
    }

    return pluralize(criticalAttendeeCount, " vote");
  };

  if (!criticalAttendeeCount) {
    return null;
  }

  return (
    <>
      <Star
        className="ml-2 mr-1"
        color={GOLD_STAR_HEX_CODE}
        fill={GOLD_STAR_HEX_CODE}
        size={16}
      />
      <div className="font-weight-400 font-size-14" style={{ color: GOLD_STAR_HEX_CODE }}>
        {`${criticalAttendeeCount}${determineVoteCountText()}`}
      </div>
    </>
  );
}
