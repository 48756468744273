import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  MessageSquare,
  Settings,
  HelpCircle,
  Phone,
  Command,
  Mail,
  Gift,
  Zap,
  Droplet,
  Image,
} from "react-feather";
import Broadcast from "../../broadcasts/broadcast";
import ShortcutHoverHint from "../shortcutHoverHint";
import { useSelector } from "react-redux";
import {
  hasStopEventPropagation,
  getActiveCommandCentersKey,
  isElectron,
  OpenLink,
  isMac,
  isMacElectron,
} from "../../services/commonUsefulFunctions";
import Mousetrap from "mousetrap";
import { getPersonalOnboardingLink } from "../onboarding/sharedFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { usePermissionsStore } from "../../services/stores/permissionsStore";
import { COMMAND_KEY, PC_CONTROL_KEY } from "../../services/globalVariables";
import ShortcutTile from "../shortcutTiles/shortcutTile";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { APP_SETTINGS, FOCUS_MODE_HOTKEY } from "../../lib/vimcalVariables";
import focusModeBroadcast from "../../broadcasts/focusModeBroadcast";
import {
  isDebuggingMasterMike,
  shouldDisplayReferralInCorner,
} from "../../lib/featureFlagFunctions";
import {
  getDaysLeftOnTrial,
  getDefaultPaymentMethod,
} from "../../lib/stateManagementFunctions";
import {
  useDefaultPaymentMethod,
  useSubscriptionStore,
} from "../../services/stores/finance";
import {
  getDaysLeftHumanReadbleText,
  shouldShowTrialActive,
} from "../../lib/userFunctions";
import { trackReferral } from "../tracking";
import { isUserMaestroUser } from "../../services/maestroFunctions";

function SettingsContainer() {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const currentUser = useSelector((state) => state.currentUser);
  const reverseSlotsPermission = usePermissionsStore(
    (state) => state.reverseSlotsPermission
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const defaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.defaultPaymentMethod
  );
  const subscription = useSubscriptionStore((state) => state.subscription);
  const [shouldDisplayMenu, setDisplayMenu] = useState(false);
  const [isOnMac] = useState(isMac());
  const [displayContactInfo, setDisplayContactInfo] = useState(false);
  const [displayHints, setDisplayHints] = useState(false);
  const [showFlashHint, setFlashHint] = useState(false);
  const [referralHint, setReferralHint] = useState(false);
  const [reverseSlotsHint, setReverseSlotsHint] = useState(false);
  const [displaySettingHint, setSettingHint] = useState(false);
  const [displayFocusModeHint, setFocusModeHint] = useState(false);
  const [focusModeCountDown, setFocusModeCountDown] = useState(null);

  const isMenuOpen = useRef(false);

  useEffect(() => {
    Broadcast.subscribe("CLOSE_SETTINGS", removeContent);
    Broadcast.subscribe("OPEN_SETTINGS", openSettings);
    Mousetrap.bind("esc", removeContent);

    focusModeBroadcast.subscribe(
      "SET_FOCUS_MODE_COUNT_DOWN_SETTING_CONTAINER",
      setFocusModeCountDown
    );
    return () => {
      Broadcast.unsubscribe("CLOSE_SETTINGS");
      Broadcast.unsubscribe("OPEN_SETTINGS");
      Mousetrap.unbind("esc");
      focusModeBroadcast.unsubscribe(
        "SET_FOCUS_MODE_COUNT_DOWN_SETTING_CONTAINER"
      );
    };
  }, []);

  const openSettings = () => {
    if (!isMenuOpen.current) {
      setDisplayMenu(true);
      isMenuOpen.current = true;
    }
  };

  const onClickSettings = (e) => {
    hasStopEventPropagation(e);
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL);
    // if (shouldDisplayMenu) {
    //   removeContent();
    // } else {
    //   openSettings();
    // }
  };

  const removeContent = () => {
    if (isMenuOpen.current) {
      setDisplayMenu(false);
      isMenuOpen.current = false;
    }
  };

  const openHelpCenter = (e) => {
    hasStopEventPropagation(e);
    Broadcast.publish("OPEN_HELP_CENTER");
  };

  const bookPersonalOnboarding = (e) => {
    hasStopEventPropagation(e);
    OpenLink(getPersonalOnboardingLink(masterAccount));
  };

  const renderCommandCenterPallete = () => {
    return (
      <div
        className="default-font-size ml-4 clickable-icon flex"
        onClick={() => Broadcast.publish("TURN_ON_COMMAND_CENTER")}
      >
        <div className="leading-5">Command Center</div>

        <div className="display-flex-center margin-right-5 font-size-300">
          <ShortcutTile shortcut={isOnMac ? COMMAND_KEY : PC_CONTROL_KEY} />
          <ShortcutTile shortcut={"K"} />
        </div>
      </div>
    );
  };

  const renderHelpCenter = () => {
    return (
      <div className="height-18px" onClick={openHelpCenter}>
        <ShortcutHoverHint
          above
          style={{ bottom: "32px", right: "-10px", width: "max-content" }}
          title={"Help center"}
        >
          <HelpCircle
            size={16}
            className="clickable-icon mr-5 cursor-pointer"
            strokeWidth={isDarkMode ? 1 : 2}
          />
        </ShortcutHoverHint>
      </div>
    );
  };

  const renderCommandCenter = () => {
    if (isUserMaestroUser(masterAccount)) {
      return null;
    }
    return (
      <div
        className={classNames("mr-4 height-18px relative")}
        onClick={() => Broadcast.publish("TURN_ON_COMMAND_CENTER")}
        onMouseEnter={() => setDisplayHints(true)}
        onMouseLeave={() => setDisplayHints(false)}
      >
        {renderShortCutHints()}
        <Command
          size={16}
          strokeWidth={isDarkMode ? 1 : 2}
          className="h-4 clickable-icon line-height-16px"
        />
      </div>
    );
  };

  const hideReferralButton = () => {
    return (
      !!focusModeCountDown ||
      shouldShowTrialActive({ masterAccount, defaultPaymentMethod }) ||
      !shouldDisplayReferralInCorner({ masterAccount })
    );
  };

  const renderReverseSlotsImage = () => {
    return null; // stashing
    if (!reverseSlotsPermission) {
      return null;
    }
    return (
      <div
        className={classNames("height-18px relative", "mr-4")}
        onClick={() => {
          layoutBroadcast.publish("UPLOAD_AI_SCHEDULER");
        }}
        onMouseEnter={() => setReverseSlotsHint(true)}
        onMouseLeave={() => setReverseSlotsHint(false)}
      >
        {renderReverseSlotsHint()}
        <Image
          size={16}
          strokeWidth={isDarkMode ? 1 : 2}
          className="h-4 clickable-icon line-height-16px"
        />
      </div>
    );
  };

  const renderReverseSlotsHint = () => {
    if (!reverseSlotsHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-14 w-max",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between items-center">
          Free Time Finder
        </div>
      </div>
    );
  };

  const renderReferral = () => {
    if (hideReferralButton()) {
      return null;
    }

    return (
      <div
        className={classNames("height-18px relative", "mr-4")}
        onClick={() => {
          trackReferral({
            user: currentUser,
            action: "clicked_referral_icon",
          });
          Broadcast.publish("SHOW_REFER_TO_VIMCAL_MODAL");
        }}
        onMouseEnter={() => setReferralHint(true)}
        onMouseLeave={() => setReferralHint(false)}
      >
        {rendeReferralHint()}
        <Gift
          size={16}
          strokeWidth={isDarkMode ? 1 : 2}
          className="h-4 clickable-icon line-height-16px"
        />
      </div>
    );
  };

  const focusShortcutWithCountDown = () => {
    return (
      <div
        className={classNames(
          "rounded-lg border-solid border-width-1px flex items-center px-2",
          isDarkMode ? "opacity-30" : "opacity-40",
          "hover:opacity-90 duration-200 cursor-pointer",
          "h-30px"
        )}
      >
        <div className="font-family-Arial font-weight-300 w-12 default-font-size">
          {focusModeCountDown}
        </div>
        <Droplet
          size={16}
          strokeWidth={2}
          className="h-3.5 line-height-14px mb-0.5"
        />
      </div>
    );
  };

  const renderFocusShortcut = () => {
    return (
      <div
        className={classNames(
          "mr-4 height-18px relative",
          focusModeCountDown ? "mb-3" : ""
        )}
        onClick={() => {
          layoutBroadcast.publish("TOGGLE_FOCUS_MODE", true);
        }}
        onMouseEnter={() => setFocusModeHint(true)}
        onMouseLeave={() => setFocusModeHint(false)}
      >
        {renderFocusModeShortcut()}
        {focusModeCountDown ? (
          focusShortcutWithCountDown()
        ) : (
          <Droplet
            size={16}
            strokeWidth={isDarkMode ? 1 : 2}
            className="h-4 line-height-16px clickable-icon"
          />
        )}
      </div>
    );
  };

  const renderFlashShortcuts = () => {
    return (
      <div
        className={classNames("mr-4 height-18px relative")}
        onClick={() => {
          setFlashHint(false);
          Broadcast.publish("TOGGLE_GLOBAL_KEY_MAP");
        }}
        onMouseEnter={() => setFlashHint(true)}
        onMouseLeave={() => setFlashHint(false)}
      >
        {renderFlashHint()}
        <Zap
          size={16}
          strokeWidth={isDarkMode ? 1 : 2}
          className="h-4 line-height-16px clickable-icon"
        />
      </div>
    );
  };

  const renderFlashHint = () => {
    if (!showFlashHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-14 w-44",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between">
          <div className="leading-5 mr-2">Flash shortcuts</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={"F"} />
          </div>
        </div>
      </div>
    );
  };

  const renderFocusModeShortcut = () => {
    if (!displayFocusModeHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-14 w-40",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between items-center">
          <div className="leading-5">Focus Mode</div>
          <div className="display-flex-center font-size-300">
            <ShortcutTile shortcut={FOCUS_MODE_HOTKEY} />
          </div>
        </div>
      </div>
    );
  };

  const rendeReferralHint = () => {
    if (!referralHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-14 w-max",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between items-center">
          Invite friends & earn
        </div>
      </div>
    );
  };

  const renderShortCutHints = () => {
    if (!displayHints) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-14 w-56",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between items-center">
          <div className="leading-5 mr-2">Command Center</div>
          <div className="display-flex-center font-size-300">
            <ShortcutTile shortcut={isOnMac ? COMMAND_KEY : PC_CONTROL_KEY} />
            <ShortcutTile shortcut={"K"} />
          </div>
        </div>
      </div>
    );
  };

  // stashing code
  const renderOtherShortcuts = () => {
    return (
      <>
        <div className="flex mt-2.5 w-full justify-between">
          <div className="leading-5 mr-2">Meet with</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={isOnMac ? COMMAND_KEY : PC_CONTROL_KEY} />
            <ShortcutTile shortcut={"J"} />
          </div>
        </div>

        <div className="flex mt-2.5 w-full justify-between">
          <div className="leading-5 mr-2">Instant Open</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={isOnMac ? COMMAND_KEY : PC_CONTROL_KEY} />
            <ShortcutTile shortcut={"O"} />
          </div>
        </div>

        <div className="flex mt-2.5 w-full justify-between">
          <div className="leading-5 mr-2">Templates</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={isOnMac ? COMMAND_KEY : PC_CONTROL_KEY} />
            <ShortcutTile shortcut={";"} />
          </div>
        </div>

        <div className="flex mt-2.5 w-full justify-between">
          <div className="leading-5 mr-2">Time Travel</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={"Z"} />
          </div>
        </div>

        <div className="flex mt-2.5 w-full justify-between">
          <div className="leading-5 mr-2">View all shortcuts</div>
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={"?"} />
          </div>
        </div>
      </>
    );
  };

  const renderContactSectionMoreInfo = () => {
    if (!displayContactInfo) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-10 w-max",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex items-center">
          <Phone size={14} className="mr-3 line-height-14px" />
          <div className="line-height-16px">Book personal onboarding</div>
        </div>

        <div className="flex mt-2.5 items-center">
          <Mail size={14} className="mr-3 line-height-14px" />
          <div className="line-height-16px">Send Feedback</div>
        </div>

        <div className="flex mt-2.5 items-center">
          <HelpCircle size={14} className="mr-3 line-height-14px" />
          <div className="line-height-16px">Help center</div>
        </div>

        <div className="flex mt-2.5 items-center">
          <Gift size={14} className="mr-3 line-height-14px" />
          <div className="line-height-16px">Refer to Vimcal</div>
        </div>
      </div>
    );
  };
  const renderContactUs = () => {
    const onClickOpenContactInfo = (e) => {
      Broadcast.publish(
        "TURN_ON_COMMAND_CENTER_WITH_DEFAULT_TEXT",
        "Resources"
      );
    };

    return (
      <div className="relative height-18px mr-4">
        {renderContactSectionMoreInfo()}
        <MessageSquare
          size={16}
          className="clickable-icon cursor-pointer"
          strokeWidth={isDarkMode ? 1 : 2}
          onMouseEnter={() => setDisplayContactInfo(true)}
          onMouseLeave={() => setDisplayContactInfo(false)}
          onClick={onClickOpenContactInfo}
        />
      </div>
    );
  };

  const renderBookPersonalOnboarding = () => {
    return (
      <div onClick={bookPersonalOnboarding}>
        <ShortcutHoverHint
          above
          style={{ bottom: "32px", right: "-10px", width: "102px" }}
          title={"Book personal onboarding"}
        >
          <Phone
            size={15}
            className="clickable-icon mr-5 cursor-pointer mt-0.5"
            strokeWidth={isDarkMode ? 1 : 2}
          />
        </ShortcutHoverHint>
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <div
        className={classNames("height-18px relative")}
        onClick={onClickSettings}
        onMouseEnter={() => setSettingHint(true)}
        onMouseLeave={() => setSettingHint(false)}
      >
        {renderSettingHint()}
        <Settings
          size={16}
          className={classNames(
            "h-4 line-height-16px",
            "cursor-pointer",
            shouldDisplayMenu ? "color-purple-blue" : "clickable-icon"
          )}
          strokeWidth={isDarkMode ? 1 : 2}
        />
      </div>
    );
  };

  const getDefaultUserPaymentMethod = () => {
    return defaultPaymentMethod ?? getDefaultPaymentMethod();
  };

  const renderSettingHint = () => {
    if (!displaySettingHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute p-4 bottom-12 default-font-size rounded-lg -right-2.5 w-fit",
          "opacity-100",
          "soft-border",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
      >
        <div className="flex w-full justify-between items-center">
          <div className="leading-5 mr-2">Settings</div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "bottom-0",
        "setting-container-border-top",
        "side-panel-width fixed right-0",
        "right-side-bar-background-color",
        "flex flex-col justify-center",
        "relative",
        isMacElectron() ? "background-opacity-0-important" : ""
      )}
    >
      <div className="relative h-12 flex flex-row items-center justify-between px-5">
        {renderTimeLeftTrial({
          masterAccount,
          focusModeCountDown,
          defaultPaymentMethod: getDefaultUserPaymentMethod(),
          subscription,
          hideReferralButton: hideReferralButton(),
        })}
        <div className="flex items-center justify-end">
          {hideReferralButton() ? renderReverseSlotsImage() : renderReferral()}
          {renderFocusShortcut()}
          {renderCommandCenter()}
          {renderContactUs()}
          {renderSettings()}
        </div>
      </div>
    </div>
  );
}

function renderTimeLeftTrial({
  masterAccount,
  focusModeCountDown,
  defaultPaymentMethod,
  subscription,
}) {
  const renderBlankSpace = () => {
    return <div />;
  };
  if (focusModeCountDown) {
    return renderBlankSpace();
  }

  if (isDebuggingMasterMike(masterAccount)) {
    // do nothing -> for testing
  } else if (!shouldShowTrialActive({ masterAccount, defaultPaymentMethod })) {
    return renderBlankSpace();
  }

  const daysLeft = getDaysLeftOnTrial({ subscription, masterAccount });

  return (
    <div
      className={classNames(
        "relative default-font-size font-weight-300 secondary-text-color",
        "flex items-center",
        "cursor-pointer"
      )}
      onClick={() => {
        if (isDebuggingMasterMike(masterAccount)) {
          // do nothing -> for testing
        }

        layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL");
      }}
    >
      {getDaysLeftHumanReadbleText(daysLeft)}

      <div className="font-weight-400 days-left-upgrade-text ml-2 mr-1">
        Upgrade
      </div>

      <Zap size={14} color="#FC3352" fill="#FC3352" />
    </div>
  );
}

export default React.memo(SettingsContainer);
