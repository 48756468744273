import React, { useEffect, useState } from "react";

import CardSelectOne from "./components/cardSelectOne";
import CardSelectTwo from "./components/cardSelectTwo";
import NewUserForm from "./components/newUserForm";
import classNames from "classnames";
import { MAESTRO_NEW_USER_PAGES } from "../../services/globalMaestroVariables";
import MagicLinkCreated from "./components/magicLinkCreated";
import magicLinkBroadcast from "../../broadcasts/magicLinkBroadcast";
import { MAGIC_LINK_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import AnimatedGreenCheckMark from "../../components/icons/animatedGreenCheckMark";
import { getMagicLinkInviteeName } from "../../services/maestro/maestroAccessors";

function MaestroLogin({ maestroAuthPage, setMaestroAuthPage }) {
  const [magicLink, setMagicLink] = useState(null);
  const isMagicLink = maestroAuthPage === MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM;
  const isMagicLinkCreated = maestroAuthPage === MAESTRO_NEW_USER_PAGES.MAGIC_LINK_CREATED;
  const { firstName } = getMagicLinkInviteeName({ magicLink });

  /* Catch magic link creation response and move to MAGIC_LINK_CREATED */
  useEffect(() => {
    magicLinkBroadcast.subscribe(
      MAGIC_LINK_BROADCAST_VALUES.SET_MAGIC_LINK_AFTER_CREATE,
      setMagicLinkAndSwitchPage,
    );

    return () => {
      magicLinkBroadcast.unsubscribe(
        MAGIC_LINK_BROADCAST_VALUES.SET_MAGIC_LINK_AFTER_CREATE,
      );
    };
  }, []);

  const maestroAuthPageText = {
    [MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE]: "Who are you logging in as?",
    [MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO]: "Does your exec already use Vimcal?",
    [MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM]: "Create an Executive Profile",
    [MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM]: "Add an Executive",
    [MAESTRO_NEW_USER_PAGES.MAGIC_LINK_CREATED]: "Copy the invite link below and share it with your exec",
  };

  const setMagicLinkAndSwitchPage = (magicLink) => {
    if (isEmptyObjectOrFalsey(magicLink)) {
      return;
    }

    setMagicLink(magicLink); // Update magic link in sate
    setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.MAGIC_LINK_CREATED); // Move to the created page
  };

  /* Determine the modal content based on maestroAuthPage */
  const renderMaestroAuthPage = () => {
    switch(maestroAuthPage) {
      case MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE:
        return (
          <CardSelectOne setMaestroAuthPage={setMaestroAuthPage} />
        );
      case MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO:
        return (
          <CardSelectTwo setMaestroAuthPage={setMaestroAuthPage} />
        );
      case MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM: // Utilize fall-through
      case MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM:
        return (
          <NewUserForm
            isMagicLink={isMagicLink}
          />
        );
      case MAESTRO_NEW_USER_PAGES.MAGIC_LINK_CREATED:
        return (
          <MagicLinkCreated
            magicLink={magicLink}
            setMaestroAuthPage={setMaestroAuthPage}
            setMagicLink={setMagicLink}
          />
        );
      default:
        /* We should never enter here */
        return null;
    }
  };

  return (
    <div
      className={classNames(
        "ml-auto mr-auto text-center select-none flex flex-col justify-center",
        [
          MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM,
          MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM,
          MAESTRO_NEW_USER_PAGES.MAGIC_LINK_CREATED,
        ].includes(maestroAuthPage) ? "maestro-new-user-slim" : "",
      )}
      id="maestro-new-user"
    >
      {
        isMagicLinkCreated ? (
          <div className="mx-auto">
            <AnimatedGreenCheckMark style={{ margin: 0, marginBottom: "1.5rem" }} />
          </div>
        ) : null
      }
      <h2 className="mb-10 px-2">
        {maestroAuthPageText[maestroAuthPage]}
      </h2>

      <div
        className={classNames(
          "flex justify-center",
          "maestro-new-user-transition",
          isMagicLinkCreated ? "flex-col items-center" : "",
        )}
        key={`maestro-auth-page-${maestroAuthPage}`}
      >
        {renderMaestroAuthPage()}
      </div>
    </div>
  );
}

export default MaestroLogin;
