import React, { Component }                       from "react";
import {
  isElectron,
}                                                 from "../services/commonUsefulFunctions";
import {
  GOOGLE_ICON,
  SECOND_IN_MS,
  VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND,
}                                                 from "../services/globalVariables";

class MenubarLoginPage extends Component {

  constructor(props) {
    super(props);

    this._setDesktopBridgeTimeout = null;
    this._desktopBridgeAttempt = 0;

    this.state = {
      isElectron: isElectron(),
    };

    this.openVimcal = this.openVimcal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadDesktopBridge();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._setDesktopBridgeTimeout);
    this._setDesktopBridgeTimeout = null;
  }

  render() {
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "30px",
          textAlign: "center",
        }}
        id="login-page"
      >
        <div>
          <div className='font-weight-500 font-size-24'>
            Vimcal
          </div>

          <div className="mt-4 secondary-text-color default-font-size">
            Please sign in through the desktop app.
          </div>

          <div
            className="login-google-button"
            style={{marginTop: "10px"}}
          >
            {this.renderLaunchVimcal()}
          </div>
        </div>
      </div>
    );
  }

  renderLaunchVimcal() {
    return (
      <div
        style={{borderRadius: 4}}
        className="login-button-launch-app mt-5"
        onClick={this.openVimcal}
      >
        <div className='mx-2 mt-1 bg-transparent'>
          <img
            alt=""
            width="20px"
            height="20px"
            src={VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND}
          />
        </div>

        <div className='launch-app-text'>
          {"Launch Vimcal"}
        </div>
      </div>
    );
  }

  renderBlankGoogleButton() {
    return (
      <div className="login-button-launch-app">
        <div className='launch-app-icon'>
          <img
            alt=""
            width="20px"
            height="20px"
            src={GOOGLE_ICON}
          />
        </div>

        <div className='launch-app-text'>
          {"Sign in with Google"}
        </div>
      </div>
    );
  }

  loadDesktopBridge() {
    if (!isElectron()) {
      return;
    }
    if (!window?.vimcal) {
      if (this._desktopBridgeAttempt > 100) {
        return;
      }
      clearTimeout(this._setDesktopBridgeTimeout);
      this._desktopBridgeAttempt += 1;
      this._setDesktopBridgeTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadDesktopBridge();
      }, SECOND_IN_MS * (0.1 * this._desktopBridgeAttempt));
      return;
    }
    this._desktopBridgeAttempt = 0;

    if (!this.props.authenticated) {
      window.vimcal.updateTrayTitle("", null, 250);
    }
  }

  openVimcal() {
    if (this.state.isElectron && window?.vimcal) {
      window.vimcal.launchMainApp();
    }
  }
}


export default MenubarLoginPage;
