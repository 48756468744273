global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React from "react";
import Classnames from "classnames";

/* Spinner used for checkboxes next to calendar toggle */
function SearchSpinner({ className, color, marginLeft, size }) {
  const sizeStr = `${size}px`;
  return (
    <div
      className={Classnames("checkbox-spinner", className)}
      style={{
        borderBottomColor: color,
        height: sizeStr,
        marginLeft,
        marginTop: sizeStr,
        width: sizeStr,
      }}
    />
  );
}

export default SearchSpinner;
