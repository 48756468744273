import React from "react";
import classNames from "classnames";
import CircleWithColor from "../circleWithColor";

interface ColorPillProps {
  color: string
  isReadOnly?: boolean
  name: string
}

/**
 * TODO: Refactor the TagsPill component to use this for consistency.
 */
export default function ColorPill({ color, name, isReadOnly }: ColorPillProps) {

  return (
    <div className={classNames("max-w-full flex color-pill", isReadOnly ? "read-only" : "")}>
      <CircleWithColor color={color} colorName={null} size={12} />
      <div className="color-pill-label">{name}</div>
    </div>
  );
}
