import classNames from "classnames";
import React from "react";
import { ChevronLeft } from "react-feather";

interface ModalTitleHeaderProps {
  title?: string;
  onClickBack?: () => void;
  fontSizeClassName?: string;
  headerClassName?: string;
}

function ModalTitleHeader({
  title,
  onClickBack,
  fontSizeClassName,
  headerClassName,
}: ModalTitleHeaderProps) {
  const lineClampClassName = headerClassName
    ?.split(" ")
    ?.includes("two-line-header-limit")
    ? "two-line-header-limit"
    : "";
  const endQuoteClassName = headerClassName?.split(" ")?.includes("end-quote")
    ? "end-quote"
    : "";

  return (
    <div className="display-flex-flex-direction-row">
      {onClickBack ? (
        <div
          className="close-button-wrapper"
          style={{ marginRight: 10, paddingBottom: 0 }}
          onClick={onClickBack}
        >
          <ChevronLeft color="#707070" size={20} className="clickable-icon" />
        </div>
      ) : null}
      <div
        className={classNames(
          fontSizeClassName || "font-size-16",
          "font-weight-400 select-none",
          lineClampClassName,
        )}
      >
        {title}
      </div>
      {endQuoteClassName ? (
        <div aria-hidden="true" className={endQuoteClassName} />
      ) : null}
    </div>
  );
}

export default ModalTitleHeader;
