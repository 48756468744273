import classNames from "classnames";
import React from "react";
import {
  hasStopEventPropagation,
} from "../../services/commonUsefulFunctions";
import { getDaysLeftHumanReadbleText } from "../../lib/userFunctions";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";

export default function TrialLabel({ daysLeft, additionalClassNames }) {
  const labelText = getDaysLeftHumanReadbleText(daysLeft);
  return (
    <div
      className={classNames(
        "free-trial-label",
        additionalClassNames || "",
        "width-max-content"
      )}
      onClick={(e) => {
        hasStopEventPropagation(e);
        layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL")
      }}
    >
      {labelText}
    </div>
  );
}
