import React from 'react'
import {format, isSameMonth} from 'date-fns';
import {useSelector} from 'react-redux';
import
{
  OUT_OF_RANGE_MONTH_COLOR,
  OUT_OF_RANGE_MONTH_COLOR_DARK_MODE,
} from '../services/globalVariables'


const CustomDayOfWeekMonthlyHeader = ({date}) => {
  const selectedDay = useSelector(state => state.selectedDay);
  const isDarkMode = useSelector(state => state.isDarkMode);
  let isOutOfMonth = !isSameMonth(date, selectedDay);
  let additionalStyle = {};

  if (isOutOfMonth) {
    additionalStyle ={backgroundColor: isDarkMode ? OUT_OF_RANGE_MONTH_COLOR_DARK_MODE : OUT_OF_RANGE_MONTH_COLOR};
  }

  return (
    <div
      className="monthly-day-of-week-header"
      style={additionalStyle}
    >
      {format(date, 'EEE').toUpperCase()}
    </div>
  );
}


export default CustomDayOfWeekMonthlyHeader

