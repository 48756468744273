import { trackError, trackEvent } from "../components/tracking";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { getUserToken } from "../lib/userFunctions";
import { constructRequestURL } from "./api";
import {
  checkIfBookableSlotsAreValid,
  createSlotsForSelection,
  generateBookableSlotsFromObj,
  generateFreeSlotsFromBusySlots,
  getAllBusySlots,
  handleError,
} from "./commonUsefulFunctions";
import Fetcher from "./fetcher";
import { ROUND_ROBIN_EA_ONBOARDING_TOKENS, ROUND_ROBIN_ONBOARDING_TOKENS } from "./globalVariables";
import { useOnboardingStore } from "./stores/onboardingStore";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export const ROUND_ROBIN_CATEGORY = "round_robin";
interface RoundRobinFreeSlot {
  start_time: string
  end_time: string
  availableLinkTokens: string[]
}

interface CompileFreeSlotsOptions {
  availabilities: PersonalLinkAvailability[]
  daysForward: number
  durationMinutes: number
  roundUpInterval: number
}

/**
 * Compile a list of free slots from the array of availabilities provided.
 * Each slot in the returned value includes an array of the personal link tokens
 * that are available for that particular slot.
 */
export function compileFreeSlots({
  availabilities,
  daysForward,
  durationMinutes,
  roundUpInterval,
}: CompileFreeSlotsOptions) {
  const compiledFreeSlots: Record<string, RoundRobinFreeSlot> = {};
  availabilities.forEach(availability => {
    const link = availability.personal_link;
    const busySlots = getAllBusySlots({
      response: availability,
      roundUpInterval,
      bufferBefore: link.buffer_before,
      bufferAfter: link.buffer_after,
      bufferFromNow: link.buffer_from_now,
    });

    const thisLinkFreeSlots = generateFreeSlotsFromBusySlots({
      busySlots,
      slots: link.slots,
      daysForward,
      durationMinutes,
      timeZone: availability.personal_link.time_zone,
      upcomingTrips: link.trips,
    });

    const thisLinkSplitFreeSlots = createSlotsForSelection(thisLinkFreeSlots, durationMinutes);
    Object.values(thisLinkSplitFreeSlots).forEach(freeSlots => {
      freeSlots.forEach(({ start, end }) => {
        if (start in compiledFreeSlots) {
          compiledFreeSlots[start].availableLinkTokens.push(link.token);
        } else {
          compiledFreeSlots[start] = {
            start_time: start,
            end_time: end,
            availableLinkTokens: [link.token],
          };
        }
      });
    });
  });

  return Object.values(compiledFreeSlots);
}

/**
 * Take a list of personal link tokens, fetch the links, and return as a
 * record where the token is the key and the personal link is the value.
 */
export async function fetchRoundRobinPersonalLinks(tokens: Readonly<string[]>) {
  const promises = tokens.map(token => {
    const url = constructRequestURL(`personal_links/${token}`, true);
    return Fetcher.get<{ personal_link: PersonalLink }>(url);
  });

  const responses = await Promise.all(promises);
  const personalLinks: Record<string, PersonalLink> = {};
  responses.forEach(r => {
    if (isEmptyObjectOrFalsey(r)) {
      return;
    }

    personalLinks[r.personal_link.token] = r.personal_link;
  });
  return personalLinks;
}

/**
 * Take a list of personal link tokens, fetch the availabilities, and return as a
 * record where the personal link token is the key and the availability is the value.
 */
export async function fetchRoundRobinAvailabilities(personalLinks: PersonalLink[]) {
  const promises = personalLinks.map(personalLink => {
    const bookableSlots = generateBookableSlotsFromObj({
      slots: personalLink.slots,
      timeZone: personalLink.time_zone,
      daysForward: personalLink.days_forward,
      shouldFormatIntoUTC: false,
      bufferBefore: personalLink.buffer_before,
      bufferAfter: personalLink.buffer_after,
      upcomingTrips: personalLink.trips,
    });

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      return new Promise<void>((resolve) => resolve());
    }

    const linkData = {
      conferencing: personalLink.conferencing,
      time_slots: bookableSlots,
    };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };
    const url = constructRequestURL(`personal_links/${personalLink.token}/availability`, true);
    return Fetcher.post<PersonalLinkAvailability>(url, payloadData);
  });

  const responses = await Promise.all(promises);
  const availabilities: Record<string, PersonalLinkAvailability> = {};

  responses.forEach(r => {
    if (isEmptyObjectOrFalsey(r)) {
      return;
    }

    availabilities[r.personal_link.token] = r;
  });

  return availabilities;
}

export async function fetchPersonalLinkForPersonalOnboarding(user: User, isEA: boolean) {
  const currentState = useOnboardingStore.getState();
  // If already fetching, do not start a second round of fetching.
  // If data exists in both availabilities and personalLinks, no need to fetch again.
  if (
    currentState.isFetchingAvailabilities ||
    (
      !isEmptyObjectOrFalsey(currentState.availabilities) &&
      !isEmptyObjectOrFalsey(currentState.personalLinks)
    )
  ) {
    return;
  }
  useOnboardingStore.setState({ isFetchingAvailabilities: true });

  const tokens = isEA
    ? ROUND_ROBIN_EA_ONBOARDING_TOKENS
    : ROUND_ROBIN_ONBOARDING_TOKENS;

  try {
    const personalLinks = await fetchRoundRobinPersonalLinks(tokens);
    trackEvent({
      category: ROUND_ROBIN_CATEGORY,
      action: "loading",
      label: "loaded personal links",
      userToken: getUserToken(user),
    });
    useOnboardingStore.setState({ personalLinks });
    fetchPersonalLinkAvailability(user, personalLinks);
  } catch (error) {
    useOnboardingStore.setState({ isFetchingAvailabilities: false });
    trackError({
      category: "personal_onboarding_signup",
      errorMessage: typeof error === "object" && error?.toString
        ? "error_0 " + error.toString()
        : "error_0",
      userToken: getUserToken(user),
    });

    handleError(error);
  }
}

async function fetchPersonalLinkAvailability(user: User, personalLinks: Record<string, PersonalLink>) {
  try {
    const availabilities = await fetchRoundRobinAvailabilities(Object.values(personalLinks));

    if (isEmptyObjectOrFalsey(availabilities)) {
      trackEvent({
        category: ROUND_ROBIN_CATEGORY,
        action: "loading",
        label: "error loading personal links availabilities",
        userToken: getUserToken(user),
      });
      useOnboardingStore.setState({ areAllSlotsBusy: true, isFetchingAvailabilities: false });
      return;
    }
    trackEvent({
      category: ROUND_ROBIN_CATEGORY,
      action: "loading",
      label: "loaded personal links availabilities",
      userToken: getUserToken(user),
    });

    useOnboardingStore.setState({ availabilities, isFetchingAvailabilities: false });
  } catch (error) {
    useOnboardingStore.setState({ isFetchingAvailabilities: false });
    trackError({
      category: "personal_onboarding_signup",
      errorMessage: typeof error === "object" && error?.toString
        ? "error_1 " + error.toString()
        : "error_1",
      userToken: getUserToken(user),
    });
    handleError(error);
  }
}
