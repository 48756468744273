import React, { Component } from "react";
import {
  isMac,
} from "../../../../services/commonUsefulFunctions";
import GoogleColors from "../../../../services/googleColors";
import { connect } from "react-redux";
import ColoredLine from "../../../line";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import {
  deleteSmartTag,
  focusOnDefaultModal,
  updateSmartTags,
} from "../../../../services/appFunctions";
import SmartTagSetting from "./smartTagSetting";
import CustomButtonBox from "../../../customButtonBox";
import { getColorFromID, isSmartTag, summarizeAutoTag } from "../../../../lib/painterFunctions";
import { Edit2 } from "react-feather";
import tagsBroadcast from "../../../../broadcasts/tagsBroadcast";
import CircleWithColor from "../../../circleWithColor";
import { BLUE_BUTTON, SECOND_IN_MS } from "../../../../services/globalVariables";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import InternalExternalTagsSection from "./internalExternalTagsSection";
import SoloTagsContainer from "./soloTagsContainer";
import {
  getAllUniqueTagsFromNonMagicLinkUsers,
  isPriorityTag,
  isTagSpecialTag,
  TAG_USER_TOKENS_KEY,
} from "../../../../lib/tagsFunctions";
import CustomButton from "../../../customButton";
import IsPrioritizedTagStar from "./isPrioritizedTagStar";
import { getDefaultUser, getUserToken } from "../../../../lib/userFunctions";
import { COLOR_TAG_SETTINGS_ID } from "../../../../services/elementIDVariables";
import { isEmptyArrayOrFalsey } from "../../../../services/typeGuards";
import { isUserMagicLinkUser } from "../../../../services/maestroFunctions";
import RecurringTagContainer from "./recurringTagContainer";

export const UPDATE_PAINT_SETTINGS = "UPDATE_PAINT_SETTINGS";
const DEFAULT_COLOR_CHOICE = {
  color: "transparent",
  name: "",
  color_id: "-1",
  id: null,
};

class ColorSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paintSettings: this.createPaintSettings(),
      keyMap: {},
      isMac: isMac(),
      isCmdKeyDown: false,
      colorOptions: this.createColorOptions(),
      hasChanged: false,
      expandedIndex: -1,
      isInternalExternalOpen: false,
      isSoloTagsOpen: false,
      isRecurringTagsOpen: false,
    };

    this.onClickAddMore = this.onClickAddMore.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickSavePerPainterRule = this.onClickSavePerPainterRule.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.createPaintSettings = this.createPaintSettings.bind(this);
    this.setInternalExternalSectionOpen =
      this.setInternalExternalSectionOpen.bind(this);
    this.setSoloTagsContainerOpen = this.setSoloTagsContainerOpen.bind(this);
    this.setRecurringTagsContainerOpen = this.setRecurringTagsContainerOpen.bind(this);
    this.recreatePaintSettings = this.recreatePaintSettings.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    tagsBroadcast.subscribe(UPDATE_PAINT_SETTINGS, this.recreatePaintSettings);
  }

  componentWillUnmount() {
    this._isMounted = false;
    tagsBroadcast.unsubscribe(UPDATE_PAINT_SETTINGS);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.paintSettings !== prevState.paintSettings &&
      !this.state.hasChanged
    ) {
      this.setState({ hasChanged: true });
    }
  }

  render() {
    return (
      <div id={COLOR_TAG_SETTINGS_ID}>
        <div className="pr-2">
          <div className="default-font-size secondary-text-color">
            Create tags to categorize your events. Add auto-tagging for Vimcal
            to do it on your behalf.
          </div>
          <ColoredLine inputClassName="mt-4 mb-4" />

          {this.renderAddMoreAndLabel()}
          <div className="color-setting-label-container">
            {this.renderLabels()}
            {this.renderSpecialTags()}
          </div>
        </div>
      </div>
    );
  }

  renderAddMoreAndLabel() {
    return (
      <div className="flex justify-between items-center">
        <div className="font-weight-500 font-size-14">Your tags</div>
        {this.userHasNoTags() ? null : (
          <CustomButtonBox
            className="hover-container-secondary-text-color"
            onClick={this.onClickAddMore}
            isDisabled={this.isEditFieldOpen()}
          >
            <div className="secondary-text-color font-weight-300">
              Create new tag
            </div>
          </CustomButtonBox>
        )}
      </div>
    );
  }

  getDefaultUser() {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return getDefaultUser({
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  renderSpecialTags() {
    const defaultUser = this.getDefaultUser();
    return (
      <div>
        <div className="font-weight-500 font-size-14 mt-12 mb-2 select-none">
          Special tags
        </div>
        <div className="default-font-size secondary-text-color mb-6 select-none">
          Ready-to-use Smart Tags. Simply toggle on the ones you find useful.
        </div>
        <InternalExternalTagsSection
          colorOptions={this.state.colorOptions}
          isOpen={this.state.isInternalExternalOpen}
          setIsOpen={this.setInternalExternalSectionOpen}
          selectedUser={defaultUser}
        />

        <SoloTagsContainer
          colorOptions={this.state.colorOptions}
          isOpen={this.state.isSoloTagsOpen}
          setIsOpen={this.setSoloTagsContainerOpen}
          selectedUser={defaultUser}
        />

        <RecurringTagContainer
          colorOptions={this.state.colorOptions}
          isOpen={this.state.isRecurringTagsOpen}
          setIsOpen={this.setRecurringTagsContainerOpen}
          selectedUser={defaultUser}
        />

        <div className="h-20 w-2"></div>
      </div>
    );
  }

  userHasNoTags() {
    return isEmptyArrayOrFalsey(this.state.paintSettings);
  }

  renderLabels() {
    if (this.userHasNoTags()) {
      return (
        <div className="mt-4">
          <CustomButton
            shouldFocus={false}
            buttonType={BLUE_BUTTON}
            onClick={this.onClickAddMore}
            addPaddingToRight={false}
            label="Create your first tag"
          />
        </div>
      );
    }
    return this.state.paintSettings.map((c, index) => {
      if (isTagSpecialTag(c)) {
        return null;
      }
      return (
        <div key={`render_color_labels_${index}`}>
          {this.renderSelectColorInput(c, index)}
        </div>
      );
    });
  }

  renderSelectColorInput(colorInfo, index) {
    if (index !== this.state.expandedIndex) {
      return this.renderClosedTag({
        index,
        colorInfo,
      });
    }

    return (
      <SmartTagSetting
        colorInfo={colorInfo}
        index={index}
        colorOptions={this.state.colorOptions}
        onClickSave={this.onClickSavePerPainterRule}
        onClickCancel={this.onClickCancel}
        onClickDelete={() => {
          // close and delete
          this.setState({ expandedIndex: -1 }, () => {
            this.onClickDelete(index);
          });
        }}
      />
    );
  }

  onClickCancel() {
    // need to revert it to what it was before
    this.setState(
      {
        expandedIndex: -1,
        paintSettings: this.createPaintSettings(),
      },
      () => {
        focusOnDefaultModal();
      }
    );
  }

  renderClosedTag({ index, colorInfo }) {
    const { id, name, color } = colorInfo;
    return (
      <div
        key={`smart-tag-${index}`}
        className="painter-section-container flex items-center mt-4 justify-between"
      >
        <div className="flex items-center select-none">
          <CircleWithColor color={getColorFromID({ id, color, size: 10 })} />

          <div
            className="truncate-text flex items-center"
            style={{ maxWidth: 555 }}
          >
            <div className="font-weight-300 ml-5 default-font-size">{name}</div>

            <div className="ml-2 font-weight-300 default-font-size secondary-text-color">
              {summarizeAutoTag({ painterSetting: colorInfo })}
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {isPriorityTag(colorInfo) ? <IsPrioritizedTagStar /> : null}
          <Edit2
            className="hoverable-secondary-text-color ml-4"
            size={16}
            onClick={() => this.setState({ expandedIndex: index })}
          />
        </div>
      </div>
    );
  }

  filterOutSpecialTags(tags) {
    return tags?.filter((tag) => !isTagSpecialTag(tag)) ?? [];
  }

  onClickSavePerPainterRule({ painterSetting, index }) {
    const updatedPaintSettings = [...this.state.paintSettings];
    updatedPaintSettings[index] = painterSetting;

    this.setState(
      { paintSettings: this.filterOutSpecialTags(updatedPaintSettings) },
      this.onClickSave,
    );
  }

  isEditFieldOpen() {
    return this.state.expandedIndex >= 0;
  }

  onClickAddMore(e) {
    if (this.isEditFieldOpen()) {
      return;
    }

    const updatedPaintSettings = [...this.state.paintSettings]
      .concat(DEFAULT_COLOR_CHOICE);

    this.setState({
      paintSettings: this.filterOutSpecialTags(updatedPaintSettings),
      expandedIndex: updatedPaintSettings.length - 1,
    });
  }

  onClickDelete(index) {
    let updatedPaintSettings = [...this.state.paintSettings];

    deleteSmartTag({
      userSmartTagId: this.state.paintSettings[index]?.user_smart_tag_id,
      user: this.getDefaultUser(),
    });

    if (index === 0 && updatedPaintSettings.length === 1) {
      // Only one left and trying to delete it
      // go to default state
      this.setState({ paintSettings: [] });
      return;
    }

    updatedPaintSettings = updatedPaintSettings
      .slice(0, index)
      .concat(
        updatedPaintSettings.slice(index + 1, updatedPaintSettings.length)
      );

    this.setState({
      paintSettings: this.filterOutSpecialTags(updatedPaintSettings),
    });
    this.refetchCalendarEvents();
  }

  refetchCalendarEvents() {
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      backendBroadcasts.publish("FETCH_EVENTS_FOR_CALENDAR_WITHIN_WINDOW");
    }, 0.5 * SECOND_IN_MS);
  }

  onClickSave() {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const getAllUserTokens = () => {
      return allLoggedInUsers.filter((user) => !isUserMagicLinkUser({ user })).map((user) => {
        return getUserToken(user);
      });
    };

    const getUserTokens = (tag) => {
      if (!isSmartTag({ painterSetting: tag }) || isEmptyArrayOrFalsey(tag?.rules?.fields)) {
        return getAllUserTokens();
      }
      return tag?.[TAG_USER_TOKENS_KEY] ?? null;
    };

    const { paintSettings } = this.state;
    const filteredPaintSettings = paintSettings
      .filter((c) => c?.name?.length > 0)
      .map((c) => ({
        color: c?.color,
        color_id: c?.color_id || "1",
        name: c?.name,
        rules: c?.rules ?? null,
        id: Number.isInteger(c?.id) ? c?.id : undefined,
        is_prioritized: c?.is_prioritized ?? false,
        [TAG_USER_TOKENS_KEY]: getUserTokens(c),
      }));

    updateSmartTags({
      smartTags: filteredPaintSettings,
      user: this.getDefaultUser(),
    });

    this.setState({ expandedIndex: -1, hasChanged: false }, () => {
      focusOnDefaultModal();
      this.refetchCalendarEvents();
    });
  }

  createColorOptions() {
    const options = GoogleColors.createColorCircleTags.filter(
      (c) => c.name !== "default"
    );

    // move last element which is no color to the top
    // Create a new array with the last element moved to the front
    const optionWithNoColorOnTop = [options[options.length - 1], ...options.slice(0, options.length - 1)];
    return optionWithNoColorOnTop;
  }

  createPaintSettings() {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return getAllUniqueTagsFromNonMagicLinkUsers({
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  recreatePaintSettings() {
    this.setState({ paintSettings: this.createPaintSettings() });
  }

  setInternalExternalSectionOpen(isOpen) {
    this.setState({ isInternalExternalOpen: isOpen });
  }

  setSoloTagsContainerOpen(isOpen) {
    if (this.state.isSoloTagsOpen === isOpen) {
      return;
    }
    this.setState({ isSoloTagsOpen: isOpen });
  }

  setRecurringTagsContainerOpen(isOpen) {
    if (this.state.isRecurringTagsOpen === isOpen) {
      return;
    }
    this.setState({ isRecurringTagsOpen: isOpen });
  }
}

function mapStateToProps(state) {
  let { currentUser, isDarkMode } = state;

  return {
    currentUser,
    isDarkMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPaintOrder: (event) =>
      dispatch({ data: event, type: "SET_PAINT_ORDER" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allCalendars = useAllCalendars();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      allCalendars={allCalendars}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(ColorSetting));
