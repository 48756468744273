import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/customButton";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import { HOME_PATH } from "../../../services/routingFunctions";
import { useMagicLink } from "../../../services/stores/magicLinkStore";

function MagicLinkSignupError({ errorType }) {
  const history = useHistory();
  const { resetMagicLinkToken } = useMagicLink();
  const errorTypes = {
    consumed: "has already been used",
    expired: "is no longer active",
    invalid: "is invalid",
  };

  const handleOnClick = () => {
    resetMagicLinkToken();
    history.push(`/${HOME_PATH}`);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center select-none signup-login-layout mt-10 mb-10 mx-3">
      <div className="flex flex-col justify-center items-start">
        <div className="text-white signup-create-account-font-size font-weight-500 mb-5 text-center">
          This link {errorTypes[errorType] ?? errorTypes["invalid"]}
        </div>
        {/* <div className="signup-create-account-subtitle font-weight-400 secondary-text-color mt-1 mb-4">
          Sub copy can be added here
        </div> */}
        <CustomButton
          buttonType={BLUE_BUTTON}
          className="maestro-magic-link-go-home-button"
          onClick={handleOnClick}
          label="Go to Vimcal"
        />
      </div>
    </div>
  );
}

export default MagicLinkSignupError;
