import React, { Component } from "react";
import { loadTheme, isElectron, isOSSchemeDarkMode, handleError } from "./js/services/commonUsefulFunctions";
import Broadcast from "./js/broadcasts/broadcast";
import MenuBarCalendarHomeView from "./js/views/menuBarCalendarHomeView";
import {
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
  MINUTE_IN_MS,
  SECOND_IN_MS,
} from "./js/services/globalVariables";
import MenubarLoginPage from "./js/views/menuBarLoginPage";
import { shouldHideMenuBarInterval } from "./js/lib/featureFlagFunctions";
import "./js/styles/menu-bar.css";
import "./js/styles/menu-bar-dark-mode.css";
import { useAllLoggedInUsers, useMasterAccount } from "./js/services/stores/SharedAccountData";
import { useIsMenuBarDarkMode } from "./js/services/stores/menuBarStores";
import { isElectronGlassyBackground } from "./js/lib/clientFunctions";
import { triggerMenuBarRefreshWithOnlineCheck } from "./js/services/appFunctions";
import { isEmptyObjectOrFalsey } from "./js/services/typeGuards";
import menubarBroadcast from "./js/broadcasts/menubarBroadcast";
import { MENU_BAR_BROADCAST_VALUES } from "./js/lib/broadcastValues";
import { getSelectedTheme, THEME } from "./js/lib/userFunctions";

class MenuBarApp extends Component {
  constructor(props) {
    super(props);

    let isAppElectron = isElectron();

    this._desktopBridgeAttempt = 0;
    this._setDesktopBridgeTimeout = null;

    // to rerender menuBarApp so we check for current user without the user having to refresh
    this._rerenderTimer = null;
    this._refreshTimeout = null;

    this.state = {
      isElectron: isAppElectron,
      isHidden: true,
      rerenderCount: 0,
    };

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.checkForElectronVisibility =
      this.checkForElectronVisibility.bind(this);
    this.determineDarkModeSetting = this.determineDarkModeSetting.bind(this);
    this.OSColorSchemeListener = this.OSColorSchemeListener.bind(this);
    this.runMinuteInterval = this.runMinuteInterval.bind(this);

    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    if (mql) {
      mql.addEventListener("change", this.OSColorSchemeListener);
    }

    menubarBroadcast.subscribe(
      MENU_BAR_BROADCAST_VALUES.CHECK_MENU_BAR_COLOR,
      this.determineDarkModeSetting
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadDesktopBridge();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._refreshTimeout);
    this._refreshTimeout = null;

    clearTimeout(this._setDesktopBridgeTimeout);
    this._setDesktopBridgeTimeout = null;

    clearTimeout(this._wakeUpTimer);
    this._wakeUpTimer = null;

    clearTimeout(this._rerenderTimer);
    this._rerenderTimer = null;

    menubarBroadcast.unsubscribe(
      MENU_BAR_BROADCAST_VALUES.CHECK_MENU_BAR_COLOR,
    );

    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    if (mql) {
      mql.removeEventListener("change", this.OSColorSchemeListener);
    }

    if (this.state.isElectron && window && window.vimcal) {
      window.vimcal.removeOnMenuBarLogout &&
        window.vimcal.removeOnMenuBarLogout(this.onClickLogout);
      window.vimcal.removeOnMenuBarVisibilityChange &&
        window.vimcal.removeOnMenuBarVisibilityChange(
          this.checkForElectronVisibility
        );
      window.vimcal.removeOnMainAppLoginSuccess &&
        window.vimcal.removeOnMainAppLoginSuccess(this.onLoginSuccess);
      window.vimcal.removeMenuBarMinuteInterval &&
        window.vimcal.removeMenuBarMinuteInterval(this.runMinuteInterval);
    }
  }

  render() {
    if (this.isUserLoggedIn()) {
      return <MenuBarCalendarHomeView />;
    } else {
      return <MenubarLoginPage />;
    }
  }

  isUserLoggedIn() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    return allLoggedInUsers.length > 0;
  }

  checkForElectronVisibility(event, data) {
    if (!data || !data.result) {
      return;
    }

    let action = data.result;

    if (["onHide", "onMinimize", "onBlur"].includes(action)) {
      this.setState({ isHidden: true });
    } else if (["onShow", "onRestore", "onFocus"].includes(action)) {
      Broadcast.publish(MENU_BAR_BROADCAST_VALUES.MENU_BAR_SYNC_OTHER_CALENDARS);

      this.setState({ isHidden: false });
    }
  }

  runMinuteInterval() {
    this.setState({ rerenderCount: this.state.rerenderCount + 1 });
    Broadcast.publish("RUN_AGENDA_INTERVAL");
  }

  setRerenderTimer() {
    this._rerenderTimer = setInterval(() => {
      if (!this._isMounted) {
        return;
      }

      this.setState({ rerenderCount: this.state.rerenderCount + 1 });
      // Every minute
    }, MINUTE_IN_MS);
  }

  onLoginSuccess() {
    clearTimeout(this._refreshTimeout);

    // on refresh (login, updating user data, we sometimes have to refresh the menu bar)
    // there's no reason to do this right after one another
    // adding a delay helps us make sure we only update every few seconds to avoid additional writes
    this._refreshTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.determineDarkModeSetting();
      this.triggerRefresh();
    }, 3 * SECOND_IN_MS);
  }

  determineDarkModeSetting() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (this.shouldFollowOSColorScheme()) {
      // nothing -> use default theme
      if (isOSSchemeDarkMode()) {
        this.setDarkMode();
      } else {
        this.setLightMode();
      }
      return;
    }
    const theme = getSelectedTheme({masterAccount});
    if (theme === THEME.DARK_MODE_THEME) {
      this.setDarkMode();
    } else if (theme === THEME.LIGHT_MODE_THEME) {
      this.setLightMode();
    }
  }

  shouldFollowOSColorScheme() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (isEmptyObjectOrFalsey(masterAccount)) {
      return true;
    }
    const theme = getSelectedTheme({masterAccount});
    return theme === THEME.MATCH_OS_THEME;
  }

  async triggerRefresh() {
    triggerMenuBarRefreshWithOnlineCheck();
  }

  setDarkMode() {
    const { setMenuBarDarkMode } = this.props.isMenuBarDarkMode;
    loadTheme(DARK_MODE_THEME, true);
    setMenuBarDarkMode(true);
  }

  setLightMode() {
    const { setMenuBarDarkMode } = this.props.isMenuBarDarkMode;
    loadTheme(LIGHT_MODE_THEME, true);
    setMenuBarDarkMode(false);
  }

  onClickLogout() {
    if (this.state.isElectron && window?.vimcal) {
      window.vimcal.updateTrayTitle("", null);
    }

    this.triggerRefresh();
  }

  OSColorSchemeListener(e) {
    if (!this.shouldFollowOSColorScheme()) {
      return;
    }

    const newColorScheme = e.matches ? "dark" : "light";

    if (newColorScheme === "dark") {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }

  loadGlassyBackground() {
    if (isElectronGlassyBackground()) {
      document.documentElement.setAttribute("background-style", "glassy");
    }
  }

  loadDesktopBridge() {
    try {
      if (!isElectron()) {
        return;
      }
      if (!window?.vimcal) {
        if (this._desktopBridgeAttempt > 100) {
          return;
        }
        clearTimeout(this._setDesktopBridgeTimeout);
        this._desktopBridgeAttempt += 1;
        this._setDesktopBridgeTimeout = setTimeout(() => {
          if (!this._isMounted) {
            return;
          }
          this.loadDesktopBridge();
        }, SECOND_IN_MS * (0.1 * this._desktopBridgeAttempt));
        return;
      }
      this._desktopBridgeAttempt = 0;

      if (
        !window?.vimcal?.onMenuBarMinuteInterval ||
        !shouldHideMenuBarInterval()
      ) {
        this.setRerenderTimer();
      }

      this.loadGlassyBackground();

      window.vimcal.onMenuBarLogout &&
        window.vimcal.onMenuBarLogout(this.onClickLogout);
      window.vimcal.onMenuBarVisibilityChange &&
        window.vimcal.onMenuBarVisibilityChange(
          this.checkForElectronVisibility
        );
      window.vimcal.onMainAppLoginSuccess &&
        window.vimcal.onMainAppLoginSuccess(this.onLoginSuccess);
      window.vimcal.onMenuBarMinuteInterval &&
        window.vimcal.onMenuBarMinuteInterval(this.runMinuteInterval);

      this.determineDarkModeSetting();

      if (window?.vimcal?.successfullyLoadedTrayWindow) {
        window.vimcal.successfullyLoadedTrayWindow();
      }
    } catch (error) {
      handleError(error);
    }
  }
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allLoggedInUsers = useAllLoggedInUsers();
  const isMenuBarDarkMode = useIsMenuBarDarkMode();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      allLoggedInUsers={allLoggedInUsers}
      isMenuBarDarkMode={isMenuBarDarkMode}
      masterAccount={masterAccount}
    />
  );
};

export default withStore(MenuBarApp);
