import React from "react";
import RoundRobinScheduler from "../onboarding/roundRobinScheduler";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import {
  getUserEmail,
  getUserName,
  getUserToken,
} from "../../lib/userFunctions";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import { trackEvent } from "../tracking";

interface OnboardingModalProps {
  onClose: () => void;
}

export default function OnboardingModal({ onClose }: OnboardingModalProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const onCompleteTracking = () => {
    trackEvent({
      category: "personal_onboarding",
      action: "booked personal onboarding outside of onboarding flow",
      label: "login_page",
      userToken: getUserToken(currentUser),
    });
  };

  // TODO: investigate complication error for currentUser below
  return (
    <div className="onboarding-modal">
      <RoundRobinScheduler
        /* @ts-ignore */
        currentUser={currentUser}
        inputName={getUserName({ masterAccount, user: currentUser }).fullName}
        inputEmail={getUserEmail(currentUser)}
        onComplete={onCompleteTracking}
        onClickTryUsOut={onClose}
        customOnSuccessButtonCopy={"Take me to Vimcal"}
        isEA={isUserMaestroUser(masterAccount)}
      />
    </div>
  );
}
