import AppBroadcast from "../../broadcasts/appBroadcast";
import { APP_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { getHomeLink } from "../../lib/envFunctions";
import Broadcast from "../../broadcasts/broadcast";
import { convertTrueFalseStringIntoValue } from "../../services/commonUsefulFunctions";

export const receiveMessage = (event) => {
  // Do we trust the sender of this message? (might be
  // different from what we originally opened, for example).

  const BASE_URL = getHomeLink();

  if (event.origin !== BASE_URL) {
    return;
  }

  const { data } = event;

  // Example of data:
  // {
  //   "zoom_logged_in": "true",
  //   "zoom_link": "https://us06web.zoom.us/j/5947159280?pwd=MEdYRHpZc2w5b1VOZTRlMkgwTUFOUT09",
  //   "default_zoom_mode": "unique_meeting_ids"
  // }

  // if we trust the sender and the source is our popup
  if (!data || !convertTrueFalseStringIntoValue(data.zoom_logged_in)) {
    if (data && typeof data === "object" && "zoom_logged_in" in data) {
      // only show if zoom_logged_in is in data otherwise, we could show the popup with ohter events that come in
      Broadcast.publish("ERROR_ON_ZOOM_LOGIN");
    }
    return;
  }

  AppBroadcast.publish(APP_BROADCAST_VALUES.UPDATE_CURRENT_USER_ACCESS_TOKEN, {
    personalLink: data.zoom_link,
    defaultZoomMode: data.default_zoom_mode,
  });
};
