import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Plus, Trash } from "react-feather";
import { useSelector } from "react-redux";

import ReminderEmailContentEditable from "./reminderEmailEditor";
import CustomSelect from "../select";
import {
  REMINDER_EMAILS_PROPERTIES,
  REMINDER_EMAIL_CONTENT,
  REMINDER_EMAIL_DEFAULT_TIMING,
  REMINDER_EMAIL_TIMING_UNIT,
  createInitialContentEditableText,
  formatDeltaToText,
  getReminderEmailUnitsOptions,
} from "./reminderEmailFunctions";
import {
  SELECT_DAY_LIST,
  SELECT_DAY_VALUES,
  SELECT_HOUR_LIST,
  SELECT_HOUR_VALUES,
  SELECT_MINUTE_LIST,
  SELECT_MINUTE_VALUES,
} from "../select/helpFunctions";
import { pluralize } from "../../lib/stringFunctions";

export default function ReminderEmailContainer({
  containerClassName,
  reminderEmails,
  setEmailReminders,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const unitOptions = useMemo(() => getReminderEmailUnitsOptions(), []);
  const [initialReminderEmail] = useState(reminderEmails);
  const { initialReminderBody, initialReminderTitle } =
    useMemo(() => createInitialContentEditableText({
      reminderEmailBody: initialReminderEmail[REMINDER_EMAILS_PROPERTIES.BODY],
      reminderEmailTitle:
        initialReminderEmail[REMINDER_EMAILS_PROPERTIES.TITLE],
    }), [reminderEmails]);
  const [body, setBody] = useState(initialReminderBody);
  const [title, setTitle] = useState(initialReminderTitle);
  const [timingArr, setTimingArr] = useState(
    initialReminderEmail[REMINDER_EMAILS_PROPERTIES.TIMINGS],
  );
  const bodyEditorRef = useRef();
  const titleEditorRef = useRef();

  /* Update parent state */
  useEffect(() => {
    const bodyEditor = bodyEditorRef?.current?.getEditor();
    const titleEditor = titleEditorRef?.current?.getEditor();
    const updatedEmailReminders = {
      timings: timingArr,
    };

    if (bodyEditor) {
      updatedEmailReminders.body = formatDeltaToText({
        contentType: REMINDER_EMAIL_CONTENT.BODY,
        delta: bodyEditor.getContents(),
      });
    }

    if (titleEditor) {
      updatedEmailReminders.subject = formatDeltaToText({
        contentType: REMINDER_EMAIL_CONTENT.TITLE,
        delta: titleEditor.getContents(),
      });
    }

    setEmailReminders(updatedEmailReminders);
  }, [body, timingArr, title]);

  return (
    <div className={classNames("default-font-size", containerClassName || "")}>
      <ReminderEmailContentEditable
        contentDelta={title}
        contentType={REMINDER_EMAIL_CONTENT.TITLE}
        ref={titleEditorRef}
        setContentDelta={setTitle}
      />
      <ReminderEmailContentEditable
        contentDelta={body}
        contentType={REMINDER_EMAIL_CONTENT.BODY}
        ref={bodyEditorRef}
        setContentDelta={setBody}
      />

      <div className="secondary-text-color mb-2.5 mt-5 flex justify-between items-center">
        Timing
      </div>

      {timingArr?.map((timing, index) => {
        const { advance_time: advanceTime, duration_unit: durationUnit } =
          timing;

        const getOptions = () => {
          switch (durationUnit) {
            case REMINDER_EMAIL_TIMING_UNIT.DAYS:
              return SELECT_DAY_LIST;
            case REMINDER_EMAIL_TIMING_UNIT.HOURS:
              return SELECT_HOUR_LIST;
            case REMINDER_EMAIL_TIMING_UNIT.MINUTES:
              return SELECT_MINUTE_LIST;
            default:
              return SELECT_MINUTE_LIST;
          }
        };

        const getValues = (inputDurationUnit) => {
          switch (inputDurationUnit) {
            case REMINDER_EMAIL_TIMING_UNIT.DAYS:
              return SELECT_DAY_VALUES;
            case REMINDER_EMAIL_TIMING_UNIT.HOURS:
              return SELECT_HOUR_VALUES;
            case REMINDER_EMAIL_TIMING_UNIT.MINUTES:
              return SELECT_MINUTE_VALUES;
            default:
              return SELECT_MINUTE_VALUES;
          }
        };

        return (
          <div
            key={`reminder-email-timing-${index}`}
            className="flex items-center gap-1.5 hoverable-visibility-parent"
          >
            <CustomSelect
              isSearchable={true}
              className={classNames(
                "select-duration-time",
                isDarkMode ? "dark-mode-select" : "",
                "select-default-font-size",
              )}
              classNamePrefix="dark-mode"
              value={{
                value: advanceTime,
                label: advanceTime,
              }}
              options={getOptions()}
              onChange={(option) => {
                setTimingArr((prevTiming) =>
                  prevTiming.map((reminder, i) =>
                    i === index
                      ? { ...reminder, advance_time: option.value }
                      : reminder,
                  ),
                );
              }}
              openMenuOnFocus={true}
              formatCreateLabel={(userInput) => userInput}
              maxMenuHeight={130}
              tabSelectsValue={true}
              tabIndex={4}
            />

            <CustomSelect
              isSearchable={true}
              className={classNames(
                "select-duration-time",
                isDarkMode ? "dark-mode-select" : "",
                "select-default-font-size",
              )}
              alwaysShowIndicator={true}
              classNamePrefix="dark-mode"
              value={{
                value: durationUnit,
                label: pluralize(advanceTime, durationUnit),
              }}
              options={unitOptions}
              onChange={(option) => {
                setTimingArr((prevTiming) =>
                  prevTiming.map((reminder, i) => {
                    /* Update based on previous timings */
                    /* Match the index */
                    if (i == index) {
                      const validValues = getValues(option.value);
                      /* If the advance_time is valid for the given duration_unit, use it */
                      /* Otherwise update the advance_time to the first valid value */
                      return {
                        advance_time: validValues.includes(
                          reminder.advance_time,
                        )
                          ? reminder.advance_time
                          : validValues[0],
                        duration_unit: option.value,
                      };
                    }

                    return reminder;
                  }),
                );
              }}
              noOptionsMessage={() => "Set"}
              openMenuOnFocus={true}
              // onKeyDown={this.onKeyDown}
              formatCreateLabel={(userInput) => userInput}
              tabSelectsValue={true}
              tabIndex={2}
            />
            <div>before event</div>

            {index !== 0 ? (
              <Trash
                size={14}
                className="cursor-pointer hoverable-secondary-text-color hoverable-visibility-child"
                onClick={() => {
                  setTimingArr((prevTiming) =>
                    prevTiming.filter((_, i) => i !== index),
                  );
                }}
              />
            ) : null}
          </div>
        );
      })}

      {timingArr?.length === 3 ? null : (
        <div
          className="select-none cursor-pointer hoverable-secondary-text-color mt-2.5 flex items-center"
          onClick={() => {
            setTimingArr(timingArr.concat(REMINDER_EMAIL_DEFAULT_TIMING));
          }}
        >
          <Plus size={14} />
          <div>Add another reminder</div>
        </div>
      )}
    </div>
  );
}
