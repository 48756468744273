import React, { useEffect, useMemo, useState } from "react";
import {
  GOOGLE_DRIVE_DONT_GIVE_ACCESS,
  GOOGLE_DRIVE_PERMISSION_ROLES,
  GOOGLE_DRIVE_PERMISSION_TYPES,
  GoogleDrivePermissions,
} from "./permissionsModal";
import CustomSelectV2 from "../../select/selectV2";

type GoogleDrivePermissionRoleProps = {
  // attendees?: any;
  parentPermissions: GoogleDrivePermissions;
  setParentPermissions: (permissions: GoogleDrivePermissions) => void;
  type:
    | ValueOf<typeof GOOGLE_DRIVE_PERMISSION_TYPES>
    | typeof GOOGLE_DRIVE_DONT_GIVE_ACCESS;
};

export default function GoogleDrivePermissionRole({
  // attendees,
  parentPermissions,
  setParentPermissions,
  type,
}: GoogleDrivePermissionRoleProps) {
  const GOOGLE_DRIVE_PERMISSION_ROLES_OPTIONS = [
    { label: "Viewer", value: GOOGLE_DRIVE_PERMISSION_ROLES.READER },
    { label: "Commenter", value: GOOGLE_DRIVE_PERMISSION_ROLES.COMMENTER },
    { label: "Editor", value: GOOGLE_DRIVE_PERMISSION_ROLES.WRITER },
  ];
  const [permissionRole, setPermissionRole] = useState<
    ValueOf<typeof GOOGLE_DRIVE_PERMISSION_ROLES>
  >(GOOGLE_DRIVE_PERMISSION_ROLES.READER);

  const optionTextByType = useMemo(() => {
    switch (type) {
      case GOOGLE_DRIVE_PERMISSION_TYPES.USER:
        return "Share with attendees";
      case GOOGLE_DRIVE_PERMISSION_TYPES.ANYONE:
        return "Allow anyone with the link to access";
      case GOOGLE_DRIVE_DONT_GIVE_ACCESS:
        return "Don't give access";
    }
  }, [type]);

  const renderContent = () => {
    switch (type) {
      case GOOGLE_DRIVE_PERMISSION_TYPES.USER:
      case GOOGLE_DRIVE_PERMISSION_TYPES.ANYONE:
        return (
          <CustomSelectV2
            isMulti={false}
            isSearchable={false}
            menuPosition="absolute"
            onChange={(option) => setPermissionRole(option?.value as ValueOf<typeof GOOGLE_DRIVE_PERMISSION_ROLES>)}
            options={GOOGLE_DRIVE_PERMISSION_ROLES_OPTIONS}
            value={GOOGLE_DRIVE_PERMISSION_ROLES_OPTIONS.find(
              (option) => option.value === permissionRole,
            )}
          />
        );
      case GOOGLE_DRIVE_DONT_GIVE_ACCESS:
        return <></>;
    }
  };

  // Update the parent permission role when changed
  useEffect(() => {
    setParentPermissions({ ...parentPermissions, role: permissionRole });
  }, [permissionRole]);

  return (
    <div
      onClick={() => {
        // Set radio to selected type
        if (parentPermissions.type !== type) {
          setParentPermissions({
            role: permissionRole,
            type,
          });
        }
      }}
    >
      <div className="flex items-center font-size-12 p-2 pl-0">
        <input
          checked={type === parentPermissions.type}
          className="mr-2"
          id={type}
          name="permission_select"
          readOnly={true}
          type="radio"
          value={type}
        />
        <label htmlFor={type}>{optionTextByType}</label>
      </div>
      <div>{renderContent()}</div>
    </div>
  );
}
