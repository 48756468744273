import React from "react";

export default function CalendarAuditLogo() {
  return (
    <svg
      width="68"
      height="17"
      viewBox="0 0 68 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1911_6347)">
        <path
          d="M1.00554 3.3649C-0.0357023 4.47443 -0.395636 6.02816 0.540953 7.22142C1.1952 8.05497 2.23498 8.40774 3.20914 8.22543C7.16979 4.38389 10.9758 3.13632 13.8847 2.89131C14.3345 2.85342 14.7624 2.83954 15.1656 2.84365C15.9628 1.99968 16.8603 1.24649 17.8429 0.604465L17.85 0.586457C17.85 0.586457 13.606 -0.402269 9.47461 0.18787C5.86721 0.703163 2.30847 1.97667 1.00554 3.3649Z"
          fill="#121212"
        />
        <path
          d="M3.93289 11.5428C3.15367 10.55 3.1667 9.188 3.88023 8.219C7.63755 4.71315 11.2065 3.57648 13.9231 3.34766L14.6347 3.43678C13.3057 5.00289 12.3022 6.83903 11.7091 8.83193C10.9271 9.04494 10.1548 9.43484 9.42095 9.94645C8.45525 10.6197 7.5433 11.5116 6.74328 12.5163C5.72942 12.7624 4.61912 12.417 3.93289 11.5428Z"
          fill="#121212"
        />
        <path
          d="M7.07605 12.8324C7.02051 12.9325 6.9706 13.037 6.9269 13.146C6.36188 14.5538 7.045 16.153 8.45271 16.7181C8.91861 16.9051 9.40523 16.9555 9.86865 16.888C10.3324 16.823 10.7858 16.6384 11.1806 16.3285C11.2629 16.2639 11.3406 16.1954 11.4134 16.1238C11.348 15.8104 11.2921 15.4928 11.2462 15.1714C10.9598 13.1668 11.0873 11.1985 11.5638 9.35303C10.9331 9.55966 10.3005 9.89074 9.68216 10.3219C8.74759 10.9734 7.85819 11.8451 7.07605 12.8324Z"
          fill="#121212"
        />
        <path
          d="M13.9229 3.34743C14.2075 3.32345 14.4829 3.30942 14.7485 3.30371C14.7102 3.34777 14.6722 3.39205 14.6344 3.43655L13.9229 3.34743Z"
          fill="#121212"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4004 5.69052L23.796 13.7772H25.6191L29.0147 5.69052L27.047 5.68311L24.7075 11.5078L22.3681 5.68311L20.4004 5.69052Z"
          fill="#121212"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9053 5.6908V13.7776H31.6907V5.6908H29.9053ZM45.9289 13.7776V8.75754C45.9289 6.66185 44.4183 5.54902 42.9094 5.54902C41.9273 5.54902 40.8953 6.04895 40.2583 6.91344L40.1439 7.06873L40.0414 6.90531C39.4839 6.01644 38.5348 5.54902 37.5848 5.54902C36.6558 5.54902 35.9653 5.99684 35.4797 6.57355L35.248 6.84869V5.69081H33.4626V13.7776H35.248V9.48216C35.248 8.88249 35.3813 8.30137 35.6896 7.86634C36.0022 7.42533 36.4875 7.14539 37.1594 7.14539C37.694 7.14539 38.1124 7.35553 38.393 7.73184C38.6697 8.10291 38.803 8.62256 38.803 9.23014V13.7776H40.5884V9.16205C40.6292 8.63225 40.7893 8.12536 41.1016 7.75344C41.4175 7.37739 41.8806 7.14539 42.4999 7.14539C43.0345 7.14539 43.4528 7.35553 43.7335 7.73184C44.0102 8.10291 44.1435 8.62256 44.1435 9.23014V13.7776H45.9289ZM54.7317 11.4112C54.1049 12.8694 52.6883 13.9037 50.9704 13.9037C48.6187 13.9037 46.801 11.9951 46.801 9.73419C46.801 7.38161 48.6947 5.54902 50.9704 5.54902C52.6585 5.54902 54.0894 6.56928 54.731 8.05617L53.1053 8.50704C52.7086 7.71611 51.935 7.12963 50.9704 7.12963C49.5517 7.12963 48.4603 8.34572 48.4603 9.73419C48.4603 11.1239 49.5529 12.323 50.9704 12.323C51.9475 12.323 52.7233 11.7535 53.1073 10.9471L54.7317 11.4112ZM61.8629 12.6881V13.7776H63.6483V5.69081H61.8629V6.80203L61.6364 6.56365C61.0613 5.95818 60.2444 5.54902 59.2268 5.54902C57.0346 5.54902 55.2149 7.37682 55.2149 9.73419C55.2149 11.9984 57.0358 13.9037 59.2268 13.9037C60.246 13.9037 61.063 13.509 61.6376 12.9194L61.8629 12.6881ZM59.4316 12.3073C57.9695 12.3073 56.8742 11.1267 56.8742 9.73419C56.8742 8.34288 57.9684 7.14539 59.4316 7.14539C60.8459 7.14539 61.9889 8.34118 61.9889 9.73419C61.9889 11.129 60.8285 12.3073 59.4316 12.3073ZM65.3649 3.0127V13.7776H67.1503V3.0127H65.3649Z"
          fill="#121212"
        />
        <path
          d="M31.8766 3.45489C31.8766 4.04562 31.3977 4.52451 30.8069 4.52451C30.2162 4.52451 29.7373 4.04562 29.7373 3.45489C29.7373 2.86414 30.2162 2.38525 30.8069 2.38525C31.3977 2.38525 31.8766 2.86414 31.8766 3.45489Z"
          fill="#121212"
        />
      </g>
      <defs>
        <clipPath id="clip0_1911_6347">
          <rect width="67.15" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
