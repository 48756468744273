import create from "zustand";
import { persist } from "zustand/middleware";
import { isNullOrUndefined } from "../typeGuards";
import type { Stripe } from "stripe-types";
import type { TruncatedStripeSubscription } from "../../types/stripe/truncatedStripe";
import { truncateStripeSubscription } from "../../lib/stripeFunctions";

interface UsePromotionsState {
  availablePromotions: Promotion[]
  unappliedPromotions: Promotion[]
  unseenPromotions: Promotion[]
  setAvailablePromotions: (promotions: Promotion[]) => void
  setUnappliedPromotions: (promotions: Promotion[]) => void
  setUnseenPromotions: (promotions: Promotion[]) => void
  resetPromotions: () => void
}

export const usePromotionsStore = create(
  persist<UsePromotionsState>(
    (set) => ({
      availablePromotions: [],
      unappliedPromotions: [],
      unseenPromotions: [],
      setAvailablePromotions: (promotions) => {
        set(() => ({ availablePromotions: promotions }));
      },
      setUnappliedPromotions: (promotions) => {
        set(() => ({ unappliedPromotions: promotions }));
      },
      setUnseenPromotions: (promotions) => {
        set(() => ({ unseenPromotions: promotions }));
      },
      resetPromotions: () => set(() => ({
        availablePromotions: [],
        unappliedPromotions: [],
        unseenPromotions: [],
      })),
    }),
    {
      name: "promotions-storage", // unique name
    },
  ),
);

interface UseSubscriptionState {
  // TODO: Probably safer to default to null instead of an empty object.
  subscription: TruncatedStripeSubscription | Record<string, never>
  setSubscription: (subscription: TruncatedStripeSubscription) => void
  resetSubscription: () => void
}

export const useSubscriptionStore = create<UseSubscriptionState>(
  persist(
    (set) => ({
      subscription: {},
      setSubscription: (subscription) => {
        set(() => ({ subscription }));
      },
      resetSubscription: () => set(() => ({ subscription: {} })),
    }),
    {
      name: "subscription-storage",
    },
  ),
);

interface UseDefaultPaymentMethodState {
  // TODO: Probably safer to default to null instead of an empty object.
  defaultPaymentMethod: DefaultPaymentMethod | Record<string, never>
  needCard: boolean
  setNeedCard: (newNeedCard: boolean) => void
  setDefaultPaymentMethod: (paymentMethod: DefaultPaymentMethod) => void
  resetDefaultPaymentMethod: () => void
}

export const useDefaultPaymentMethod = create(
  persist<UseDefaultPaymentMethodState>(
    (set) => ({
      defaultPaymentMethod: {},
      needCard: true,
      setNeedCard: (needCard) => {
        if (isNullOrUndefined(needCard)) {
          return;
        }
        set(() => ({ needCard }));
      },
      setDefaultPaymentMethod: (defaultPaymentMethod) =>
        set(() => ({ defaultPaymentMethod })),
      resetDefaultPaymentMethod: () =>
        set(() => ({ defaultPaymentMethod: {}, needCard: true })),
    }),
    {
      name: "default-payment-method-storage",
    },
  ),
);

interface UseChargesState {
  charges: Stripe.Charge[]
  setCharges: (charges: Stripe.Charge[]) => void
  resetCharges: () => void
}

export const useCharges = create<UseChargesState>(
  // aka receipts
  (set) => ({
    charges: [],
    setCharges: (charges) => set(() => ({ charges })),
    resetCharges: () => set(() => ({ charges: [] })),
  }),
);

interface UseStripePaymentMethodsState {
  stripePaymentMethods: Stripe.PaymentMethod[]
  setStripePaymentMethods: (paymentMethods: Stripe.PaymentMethod[]) => void
  resetStripePaymentMethods: () => void
}

export const useStripePaymentMethods = create<UseStripePaymentMethodsState>(
  // payment method from stripe through the billing backend endpoint
  (set) => ({
    stripePaymentMethods: [],
    setStripePaymentMethods: (stripePaymentMethods) =>
      set(() => ({ stripePaymentMethods })),
    resetStripePaymentMethods: () =>
      set(() => ({ stripePaymentMethods: [] })),
  }),
);

// TODO: Rename hook and properties to make it clearer that this is a singular subscription.
interface UseStripeSubscriptionsState {
  // TODO: Probably safer to default to null instead of an empty object.
  stripeSubscriptions: Stripe.Subscription | Record<string, never>
  setStripeSubscriptions: (subscription: Stripe.Subscription) => void
  resetStripeSubScriptions: () => void
}

export const useStripeSubscriptions = create<UseStripeSubscriptionsState>(
  // subscription data from stripe through the billing backend endpoint
  (set) => ({
    stripeSubscriptions: {},
    setStripeSubscriptions: (stripeSubscriptions) => {
      set(() => ({ stripeSubscriptions }));
      // We have two stores storing similar data
      // TODO: See if there's a way to consolidate these two stores.
      useSubscriptionStore.setState({ subscription: truncateStripeSubscription(stripeSubscriptions) });
    },
    // TODO: Fix typo.
    resetStripeSubScriptions: () =>
      set(() => ({ stripeSubscriptions: {} })),
  }),
);

// TODO: Rename hook and properties to make it clearer that this is a singular invoice.
interface UseStripeUpcomingInvoicesState {
  // TODO: Probably safer to default to null instead of an empty object.
  stripeUpcomingInvoices: Stripe.UpcomingInvoice | Record<string, never>
  setStripeUpcomingInvoices: (invoice: Stripe.UpcomingInvoice) => void
  resetStripeUpcomingInvoices: () => void
}

export const useStripeUpcomingInvoices = create<UseStripeUpcomingInvoicesState>(
  // upcoming stripe invoices through the billing backend endpoint
  (set) => ({
    stripeUpcomingInvoices: {},
    setStripeUpcomingInvoices: (stripeUpcomingInvoices) =>
      set(() => ({ stripeUpcomingInvoices })),
    resetStripeUpcomingInvoices: () =>
      set(() => ({ stripeUpcomingInvoices: {} })),
  }),
);

interface UseHasBillingBeenFetchedState {
  hasBillingBeenFetched: boolean
  setBillingHasBeenFetched: (fetched: boolean) => void
  resetBillingHasBeenFetched: () => void

}

export const useHasBillingBeenFetched = create<UseHasBillingBeenFetchedState>(
  (set) => ({
    hasBillingBeenFetched: false,
    setBillingHasBeenFetched: (hasBillingBeenFetched) =>
      set(() => ({ hasBillingBeenFetched })),
    resetBillingHasBeenFetched: () =>
      set(() => ({ hasBillingBeenFetched: false })),
  }),
);

interface UseStripeCustomerEmailState {
  stripeCustomerEmail: string | null
  setStripeCustomerEmail: (email: string) => void
  resetStripeCustomerEmail: () => void
}

export const useStripeCustomerEmail = create<UseStripeCustomerEmailState>(
  (set) => ({
    stripeCustomerEmail: null,
    setStripeCustomerEmail: (stripeCustomerEmail) =>
      set(() => ({ stripeCustomerEmail })),
    resetStripeCustomerEmail: () =>
      set(() => ({ stripeCustomerEmail: null })),
  }),
);

interface UseAnnualUpgradeInvoicesState {
  annualUpgradeProratedInvoice: Stripe.UpcomingInvoice | null
  annualUpgradeNonProratedInvoice: Stripe.UpcomingInvoice | null
  setAnnualUpgradeInvoices: (invoices: {
    annualUpgradeProratedInvoice: Stripe.UpcomingInvoice | null
    annualUpgradeNonProratedInvoice: Stripe.UpcomingInvoice | null
  }) => void
  resetAnnualUpgradeInvoices: () => void
}

/**
 * This store is not persisted because the prorated amounts change constantly. Should be fetched fresh each time.
 */
export const useAnnualUpgradeInvoicesStore = create<UseAnnualUpgradeInvoicesState>(
  (set) => ({
    annualUpgradeProratedInvoice: null,
    annualUpgradeNonProratedInvoice: null,
    setAnnualUpgradeInvoices: (invoices) => {
      set(() => (invoices));
    },
    resetAnnualUpgradeInvoices: () => {
      set(() => ({
        annualUpgradeProratedInvoice: null,
        annualUpgradeNonProratedInvoice: null,
      }));
    },
  }),
);

interface UseBackendSubscriptionState {
  backendSubscription: BackendSubscription | null
  setBackendSubscription: (subscription: BackendSubscription) => void
  resetBackendSubscription: () => void
}

/**
 * The store for the backend subscription (data that is saved directly in our DB), NOT the full Stripe subscription.
 */
export const useBackendSubscriptionStore = create<UseBackendSubscriptionState>(
  (set) => ({
    backendSubscription: null,
    setBackendSubscription: (subscription) => set(() => ({ backendSubscription: subscription })),
    resetBackendSubscription: () => set({ backendSubscription: null }),
  }),
);
