import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Mousetrap from "mousetrap";
import produce from "immer";

import OnboardingSaveButton from "../../onboarding/onboardingSaveButton";
import CheckBox from "../../checkbox";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../../lib/tracking/onboardingTracking";
import { hasEventPreventDefault } from "../../../services/commonUsefulFunctions";
import CalendarContainer from "./calendarContainer";
import { getPrimaryUserCalendarIDByMagicLinkEmail } from "../../../services/maestroFunctions";
import { isCalendarSelected } from "../../../lib/calendarFunctions";
import { getCalendarUserCalendarID } from "../../../services/calendarAccessors";

function MagicLinkSelectCalendars(props) {
  const {
    magicLink,
    magicLinkAllCalendars,
    onClickNext,
    setMagicLinkAllCalendars,
    step,
  } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  /* userCalendarID of the primary calendar belonging to the email invited by Magic Link */
  const primaryUserCalendarID = getPrimaryUserCalendarIDByMagicLinkEmail({
    magicLink,
    magicLinkAllCalendars,
  });
  const flattenedCalendars = Object.values(magicLinkAllCalendars)?.flat();
  const areAllCalendarsSelected = !flattenedCalendars?.find(
    (calendar) => !isCalendarSelected(calendar),
  );
  /* Check if the primaryUserCalendarID is selected */
  /* We disable going to the next step if it is not */
  const isPrimaryUserCalendarIDSelected = isCalendarSelected(
    flattenedCalendars?.find(
      (calendar) =>
        getCalendarUserCalendarID(calendar) === primaryUserCalendarID,
    ),
  );

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    Mousetrap.bind(["enter"], handleOnClickNext);
    return () => {
      Mousetrap.unbind(["enter"]);
    };
  }, []);

  /* Prevent going to next page if primary calendar is not selected */
  const handleOnClickNext = (e) => {
    hasEventPreventDefault(e);

    if (isPrimaryUserCalendarIDSelected) {
      onClickNext();
    }

    return;
  };

  const toggleAllCalendars = () => {
    /* If all calendars are selected, toggle all of them off UNLESS it is primary */
    if (areAllCalendarsSelected) {
      const updatedMagicLinkAllCalendars = produce(
        magicLinkAllCalendars,
        (draftState) => {
          const userEmails = Object.keys(magicLinkAllCalendars);

          userEmails.forEach(userEmail => {
            draftState[userEmail] = draftState[userEmail]?.map(calendar => ({
              ...calendar,
              selected:
              getCalendarUserCalendarID(calendar) === primaryUserCalendarID
                ? true
                : false,
            }));
          });
        },
      );

      setMagicLinkAllCalendars(updatedMagicLinkAllCalendars);
    } else {
      /* If not all calendars are selected, toggle all of them on */
      const updatedMagicLinkAllCalendars = produce(
        magicLinkAllCalendars,
        (draftState) => {
          const userEmails = Object.keys(magicLinkAllCalendars);

          userEmails.forEach(userEmail => {
            draftState[userEmail] = draftState[userEmail]?.map(calendar => ({
              ...calendar,
              selected: true,
            }));
          });
        },
      );

      setMagicLinkAllCalendars(updatedMagicLinkAllCalendars);
    }
  };

  return (
    <div className="onboarding-container">
      <div className="section-title mb-4">
        What calendars would you like to share with your EA?
      </div>

      <div className="magic-link-calendar-select-wrapper">
        <CalendarContainer
          magicLinkAllCalendars={magicLinkAllCalendars}
          primaryUserCalendarID={primaryUserCalendarID}
          setMagicLinkAllCalendars={setMagicLinkAllCalendars}
        />
      </div>


      <div
        className="cursor-pointer flex items-center my-4"
        onClick={() => toggleAllCalendars()}
      >
        <CheckBox
          backgroundColor={areAllCalendarsSelected ? "#3B81F7" : "transparent"}
          borderColor={"#3B81F7"}
          borderWidth={1}
          isChecked={areAllCalendarsSelected}
        />
        <div className="magic-link-select-calendar-share-all secondary-text-color">
          Share all my calendars
        </div>
      </div>

      <OnboardingSaveButton
        buttonText={
          <div className="flex items-center">
            <div>Confirm</div>
          </div>
        }
        isDisabled={!isPrimaryUserCalendarIDSelected}
        onClick={handleOnClickNext}
        marginTopOverride={0}
        width={160}
      />
    </div>
  );
}

export default MagicLinkSelectCalendars;
