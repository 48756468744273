import { format, subDays } from "date-fns";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  isMobileHorizontally,
  hasStopEventPropagation,
} from "../../services/commonUsefulFunctions";
import classNames from "classnames";
import "../../styles/groupVoteLinkStyles.css";
import "../../styles/groupVoteDarkModeStyles.css";
import { Check, Star, X } from "react-feather";
import ScheduleCheckBox from "./scheduleCheckBox";
import SchedulingCheckMark from "./schedulingCheckMark";
import { useSelector } from "react-redux";
import {
  getAttendees,
  createKeyFromSlot,
  determineSlotAttendeeIndex,
  isSameSlot,
  parseEventsWithDefaultTimeZone,
  sortSlotsChronologically,
  sortSlotsByAttendeeVote,
  getAttendeeNameAndEmailKey,
  isMultiDaySlot,
  getCriticalAttendees,
  sortAttendeeListByCritical,
  determineSlotCriticalAttendeeIndex,
} from "../../lib/availabilityFunctions";
import { SORT_BY_VOTE_COUNT } from "./schedulingSharedVariables";
import _ from "underscore";
import { isEventSlotAllDayEvent } from "../../lib/rbcFunctions";
import { pluralize } from "../../lib/stringFunctions";
import { createUUID } from "../../services/randomFunctions";
import CriticalAttendeeCount from "./groupVoteSpreadSheet/criticalAttendeeCount";
import CriticalAttendeeDisplay from "./groupVoteSpreadSheet/criticalAttendeeDisplay";
import { getObjectEmail } from "../../lib/objectFunctions";

const HEADER_HEIGHT = "group-vote-date-time-header";
const MARKED_GREEN_BACKGROUND = "bg-green-100";
const DID_NOT_MARK_BACKGROUND = "bg-gray-50";
const NEW_ATTENDEE_SELECTED_COLOR = "bg-green-50";
const NEW_ATTENDEE_SELECTED_COLOR_DARK_MODE = "bg-green-500";

/*
need backend to pass back objects like this:
{
	title,
	duration,
	description,
	conferencing,
	google_calendar_id,
	location,
	token //* similar to how we create token for slots,
	selected_slots: [{start, end}],
	attendees: [
		{
			name,
			email,
			slots: [
				{
					start, 
					end,
					response: "accepted" or "tenative"
				},
				...
			]
		},
		...
	]
}
*/

export default function GroupVoteSchedulingTable({
  hideInputField,
  bookingLink,
  newAttendeeSlots,
  setNewAttendeeSlot,
  newUserName,
  onChangeUserName,
  skipDarkMode,
  selectedEvent,
  onSelectColumn,
  skipAnonymous,
  sortBy,
  selectedTimeZone,
  isPreview,
}) {
  const [attendeeList, setAttendeeList] = useState(getAttendees(bookingLink));
  const selectedTimeZoneRef = useRef(selectedTimeZone);
  const [slotAttendeeIndex] = useState(determineSlotAttendeeIndex(bookingLink));
  const criticalAttendees = getCriticalAttendees(bookingLink);
  const slotCriticalAttendeeIndex = determineSlotCriticalAttendeeIndex(bookingLink);
  const sortedAttendeeList = sortAttendeeListByCritical({ attendeeList, criticalAttendees });
  const [availableTimes, setAvailableTimes] = useState(
    hideInputField && !isPreview
      ? parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone).sort(
          (a, b) => sortSlotsByAttendeeVote({a, b, slotAttendeeIndex, slotCriticalAttendeeIndex, selectedTimeZone})
        )
      : sortSlotsChronologically(
          parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone)
        )
  );
  const isMobileLayout = useRef(isMobileHorizontally());
  const [rerenderCount, setRerenderCount] = useState(0);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [hideAttendees] = useState(!skipAnonymous && bookingLink.anonymous);
  const handleResize = () => {
    if (isMobileHorizontally() !== isMobileLayout.current) {
      isMobileLayout.current = isMobileHorizontally();
      setRerenderCount(createUUID(3));
    }
  };

  useEffect(() => {
    selectedTimeZoneRef.current = selectedTimeZone;
  }, [selectedTimeZone]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    if (!isPreview && onSelectColumn && availableTimes?.length > 0) {
      // select first column
      onSelectColumn(availableTimes[0]);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isFirstUpdate = useRef(true); // avoid useEffect in initial render
  useEffect(() => {
    if (!hideInputField) {
      return;
    }
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    if (sortBy === SORT_BY_VOTE_COUNT) {
      setAvailableTimes(
        parseEventsWithDefaultTimeZone(
          bookingLink,
          selectedTimeZoneRef.current
        ).sort((a, b) => sortSlotsByAttendeeVote({a, b, slotAttendeeIndex, slotCriticalAttendeeIndex, selectedTimeZone}))
      );
    } else {
      setAvailableTimes(
        sortSlotsChronologically(
          parseEventsWithDefaultTimeZone(
            bookingLink,
            selectedTimeZoneRef.current
          )
        )
      );
    }
  }, [sortBy]);

  const shouldDisplayDarkMode = () => {
    return isDarkMode && !skipDarkMode;
  };

  const isSelectedSlotForCreate = (slot) => {
    return hideInputField && isSameSlot(slot, selectedEvent);
  };

  const renderLeftAttendeesSection = () => {
    return (
      <aside
        className={classNames(
          "group-vote-link-border-right",
          "group-vote-attendees-container"
        )}
      >
        {isMobileLayout.current ? null : (
          <div
            className={classNames(
              HEADER_HEIGHT,
              "group-vote-link-border-bottom"
            )}
          ></div>
        )}
        {hideAttendees ? null : (
          <div className="participant-container font-weight-400 default-font-size">{`${
            sortedAttendeeList.length
          } ${pluralize(sortedAttendeeList.length, "attendee")}`}</div>
        )}

        {hideInputField ? null : (
          <div
            className={classNames(
              "participant-container group-vote-link-border-top",
              "group-vote-input-container",
              isMobileLayout.current ? "justify-content-center" : "",
              "group-vote-selector-background-color",
              "padding-left-10px-important"
            )}
          >
            <input
              value={newUserName}
              onChange={onChangeUserName}
              placeholder={"Enter your name"}
              autoComplete="new-password"
              className="group-vote-name-input w-44 mr-3"
            />
          </div>
        )}
        {!hideAttendees && !isMobileLayout.current
          ? sortedAttendeeList.map((a, index) => {
            return (
              <div
                key={`group-vote-attendee-${index}`}
                className="participant-container group-vote-link-border-top"
              >
                <div className="flex flex-col justify-center">
                  <div className="truncate-text max-width-160px default-font-size">
                    {a.name || getObjectEmail(a)}
                  </div>
                  <CriticalAttendeeDisplay attendee={a} criticalAttendees={criticalAttendees} />
                </div>
              </div>
            );
          })
          : null}
      </aside>
    );
  };

  const renderDateHeader = (slot) => {
    const {
      month,
      dayOfMonth,
      dayOfWeek,
      endMonth,
      endDayOfMonth,
      endDayOfWeek,
    } = getDateDetails(slot);
    if (isMobileLayout.current) {
      return (
        <div className={classNames("flex flex-col items-center")}>
          <div className="default-font-size">{month}</div>
          <div className="font-size-20 font-weight-400">{dayOfMonth}</div>
          <div className="default-font-size">{dayOfWeek.toUpperCase()}</div>
        </div>
      );
    } else if (isMultiDaySlot(slot)) {
      return (
        <div className="flex items-center w-full justify-center">
          <div className="flex items-center flex-col">
            <div className="mt-2 default-font-size">{month}</div>
            <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
            <div className="default-font-size font-weight-300">
              {dayOfWeek.toUpperCase()}
            </div>
          </div>

          <div className="mx-1.5">-</div>

          <div className="flex items-center flex-col">
            <div className="mt-2 default-font-size">{endMonth}</div>
            <div className="font-size-14 font-weight-400">{endDayOfMonth}</div>
            <div className="default-font-size font-weight-300">
              {endDayOfWeek.toUpperCase()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames("flex flex-col items-center")}>
          <div className="mt-2 default-font-size">{month}</div>
          <div className="font-size-14 font-weight-400">{dayOfMonth}</div>
          <div className="default-font-size font-weight-300">
            {dayOfWeek.toUpperCase()}
          </div>
        </div>
      );
    }
  };

  const renderTime = (slot) => {
    const { startTime, endTime } = getTimeDetails(slot);
    const isAllDay = isEventSlotAllDayEvent(slot);
    return (
      <div
        className={classNames(
          isMobileLayout.current
            ? "display-flex flex-direction-row"
            : "display-flex flex-direction-column justify-content-center",
          "font-size-12",
          isMobileLayout.current ? "" : "mt-2.5"
        )}
      >
        <div
          className={classNames(
            "display-flex justify-content-center",
            isMobileLayout.current ? "margin-right-10" : ""
          )}
        >
          {isAllDay ? "All day" : startTime}
        </div>
        <div className="display-flex justify-content-center">
          {isAllDay ? "" : endTime}
        </div>
      </div>
    );
  };

  const renderCount = (slot, index) => {
    if (hideAttendees) {
      return null;
    }
    const determineVoteCountText = (count) => {
      if (!isMobileLayout.current) {
        return "";
      }

      return pluralize(count, " vote");
    };

    const renderHoverAttendeeInfo = () => {
      if (
        !sortedAttendeeList ||
        sortedAttendeeList.length < 2 ||
        availableTimes.length < 2
      ) {
        return null;
      }

      const determineLeftPosition = () => {
        if (availableTimes?.length - 1 === index) {
          return "-left-20";
        } else if (index === 0) {
          return "left-0";
        }

        return "-left-10";
      };

      return (
        <div
          className={classNames(
            "attendee-count-more-info-container box-shadow-6",
            // getDate(slot.eventStart) === 5 ? "" : "hover-target",
            determineLeftPosition(),
            isMobileLayout.current && index === 0 ? "-bottom-20" : "bottom-10",
            "hover-target"
          )}
        >
          {sortedAttendeeList.map((a, index) => {
            if (index > 6) {
              return null;
            }
            const isMarked = hasAttendeeMarkedTime({
              attendee: a,
              slot,
              slotAttendeeIndex,
              selectedTimeZone,
            });

            return (
              <div
                key={`hover-attendee-container-${index}`}
                className="flex items-center"
              >
                <div className="mr-2 truncate-text max-width-100px">
                  {a.name}
                </div>
                {isMarked ? (
                  <SchedulingCheckMark />
                ) : (
                  <X className="text-red-500" size="16" strokeWidth={6} />
                )}
              </div>
            );
          })}
        </div>
      );
    };

    const key = createKeyFromSlot(slot, selectedTimeZone);
    const TEXT_COLOR = shouldDisplayDarkMode()
      ? "text-blue-200"
      : "text-blue-600";
    const attendeeCount = slotAttendeeIndex[key]?.length || 0;
    const criticalAttendeeCount = slotCriticalAttendeeIndex[key]?.length ?? 0;

    const isSelectedEvent = isSelectedSlotForCreate(slot);
    return (
      <div
        className={classNames(
          "flex items-center",
          isMobileLayout.current ? "mt-2" : "justify-center time-slot-height",
          "cursor-pointer",
          // isMobileLayout.current ? "" : determineHoverBackgroundColor(),
          "relative",
          // "hover-trigger",
          isSelectedEvent ? "group-vote-selected-event-time-slot" : "",
          isSelectedEvent && sortedAttendeeList?.length === 0
            ? "group-vote-selected-event-time-slot-border-bottom"
            : ""
        )}
      >
        {/* {renderHoverAttendeeInfo()} */}
        <Check
          className={classNames("mr-1", TEXT_COLOR)}
          size={14}
          strokeWidth={4}
        />
        <div
          className={classNames(
            TEXT_COLOR,
            "font-weight-400",
            "default-font-size"
          )}
        >
          {`${attendeeCount}${determineVoteCountText(attendeeCount)}`}
        </div>
        <CriticalAttendeeCount criticalAttendeeCount={criticalAttendeeCount} isMobileLayout={isMobileLayout} />
      </div>
    );
  };

  const hasAttendeeSelectedSlot = (slot) => {
    return newAttendeeSlots.some((s) => {
      return isSameSlot(s, slot);
    });
  };

  const renderNewUserMark = (slot, disableClick) => {
    // where new user marks which times work for him/her
    const hasSelectedSlot = hasAttendeeSelectedSlot(slot);

    const onChangeAttendeeSlot = () => {
      setNewAttendeeSlot(slot);
    };

    return (
      <ScheduleCheckBox
        onChange={disableClick ? _.noop : onChangeAttendeeSlot}
        isChecked={hasSelectedSlot}
        defaulBorder={
          isMobileLayout.current
            ? "border-gray-200 hover:border-blue-300 duration-100"
            : ""
        }
      />
    );
  };

  const renderNonMobileSlotSection = (slot, index) => {
    const onClickColumn = (e) => {
      if (hideInputField) {
        hasStopEventPropagation(e);
        onSelectColumn && onSelectColumn(slot);
      }
    };

    const onClickBox = (e) => {
      hasStopEventPropagation(e);
      setNewAttendeeSlot(slot);
    };

    const isSelectedEvent = isSelectedSlotForCreate(slot);
    return (
      <li
        key={`slots-container-${index}`}
        className={classNames(
          "slot-container",
          isMultiDaySlot(slot) ? "slot-container-multi-day-event" : "",
          index === 0 ? "" : "group-vote-link-border-left",
          hideInputField ? "cursor-pointer" : ""
        )}
        onClick={onClickColumn}
      >
        <div
          className={classNames(
            "group-vote-link-border-bottom",
            HEADER_HEIGHT,
            isSelectedEvent ? "group-vote-date-time-selected-event" : "",
            hasAttendeeSelectedSlot(slot) ? getSelectedBackgroundColor() : ""
          )}
        >
          {renderDateHeader(slot)}
          {renderTime(slot)}
        </div>
        {renderCount(slot, index)}
        {hideInputField ? null : (
          <div
            className={classNames(
              "group-vote-link-border-top participant-mark-container group-vote-input-container",
              "group-vote-selector-background-color",
              "cursor-pointer hover:bg-blue-300 duration-200"
            )}
            onClick={onClickBox}
          >
            {renderNewUserMark(slot, true)}
          </div>
        )}

        {hideAttendees
          ? null
          : sortedAttendeeList.map((a, attendeeIndex) => {
              const isMarked = hasAttendeeMarkedTime({
                attendee: a,
                slot,
                slotAttendeeIndex,
                selectedTimeZone,
              });
              return (
                <div
                  key={`attendee-booking-mark-${attendeeIndex}-${index}`}
                  className={classNames(
                    "participant-mark-container group-vote-link-border-top",
                    isMarked
                      ? MARKED_GREEN_BACKGROUND
                      : DID_NOT_MARK_BACKGROUND,
                    isSelectedEvent
                      ? "group-vote-selected-event-time-slot"
                      : "",
                    isSelectedEvent && attendeeIndex === sortedAttendeeList.length - 1
                      ? "group-vote-selected-event-time-slot-border-bottom"
                      : ""
                  )}
                >
                  {isMarked ? <SchedulingCheckMark /> : null}
                </div>
              );
            })}
      </li>
    );
  };

  const getSelectedBackgroundColor = () => {
    return shouldDisplayDarkMode()
      ? NEW_ATTENDEE_SELECTED_COLOR_DARK_MODE
      : NEW_ATTENDEE_SELECTED_COLOR;
  };

  const renderMobileSlotSection = (slot, index) => {
    return (
      <li
        key={`slots-container-${index}`}
        className={classNames(
          "slot-container",
          "group-vote-link-border-top",
          "align-items-center",
          hasAttendeeSelectedSlot(slot) ? getSelectedBackgroundColor() : ""
        )}
      >
        <div className="display-flex align-items-center">
          {renderDateHeader(slot)}
          <div className="ml-6">
            {renderTime(slot)}
            {renderCount(slot, index)}
          </div>
        </div>

        {renderNewUserMark(slot)}
      </li>
    );
  };

  const timeTable = useMemo(() => {
    // only call render here if available times or new times are picked
    // more performant
    return (
      <ul className="group-vote-time-container">
        {availableTimes.map((t, index) => {
          return isMobileLayout.current
            ? renderMobileSlotSection(t, index)
            : renderNonMobileSlotSection(t, index);
        })}
      </ul>
    );
  }, [availableTimes, newAttendeeSlots, selectedEvent, isMobileLayout.current]);

  return (
    <div
      className={classNames(
        "group-vote-link-container",
        isMobileLayout.current ? "w-full" : ""
      )}
    >
      {renderLeftAttendeesSection()}
      {timeTable}
    </div>
  );
}

function getTimeDetails(slot) {
  const { eventStart, eventEnd } = slot;

  return {
    startTime: format(eventStart, "p"),
    endTime: format(eventEnd, "p"),
  };
}

function getDateDetails(slot) {
  const { eventStart, eventEnd } = slot;
  if (isMultiDaySlot(slot)) {
    const updatedEnd = subDays(eventEnd, 1);
    return {
      month: format(eventStart, "MMM"),
      dayOfMonth: format(eventStart, "d"),
      dayOfWeek: format(eventStart, "E"),
      endMonth: format(updatedEnd, "MMM"),
      endDayOfMonth: format(updatedEnd, "d"),
      endDayOfWeek: format(updatedEnd, "E"),
    };
  }

  return {
    month: format(eventStart, "MMM"),
    dayOfMonth: format(eventStart, "d"),
    dayOfWeek: format(eventStart, "E"),
    endMonth: format(eventEnd, "MMM"),
    endDayOfMonth: format(eventEnd, "d"),
    endDayOfWeek: format(eventEnd, "E"),
  };
}

function hasAttendeeMarkedTime({
  attendee,
  slot,
  slotAttendeeIndex,
  selectedTimeZone,
}) {
  const key = createKeyFromSlot(slot, selectedTimeZone);
  return slotAttendeeIndex[key]?.includes(getAttendeeNameAndEmailKey(attendee));
}
