import React, { useEffect, useRef, useState } from "react";
import {
  OPTION_EQUAL,
  RULE_SECTION_TITLE,
  isConditionalEqual,
  isConditionContainsAny,
  isConditionContainsAll,
  RULE_SECTION_ATTENDEES,
  isConditionIsInDomain,
  RULE_SECTION_LOCATION,
  isAttendeesRule,
  OPTION_TYPE,
} from "./smartTagHelpers";
import {
  KEYCODE_ENTER,
  KEYCODE_TAB,
  KEYCODE_COMMA,
  hasEventPreventDefault,
  hasStopEventPropagation,
} from "../../../../services/commonUsefulFunctions";
import {
  getLastArrayElement,
  getEveryElementExceptLast,
  isEmptyArray,
} from "../../../../lib/arrayFunctions";
import { getEmailDomain, getInputStringFromEvent, lowerCaseAndTrimString, splitStringIntoEmails } from "../../../../lib/stringFunctions";
import ReactSelectAttendeeAutoComplete from "../../../reactSelectAttendeeAutoComplete";
import { blurCalendar } from "../../../../services/appFunctions";
import { isEmptyArrayOrFalsey } from "../../../../services/typeGuards";

const TAG_RULE_INPUT_WIDTH = 300;

export default function RuleValueInput({
  name,
  tagRuleSections,
  index,
  setTagRuleSections,
  condition,
  value,
  values, // always protected to be at least empty array []
}) {
  const [inputVal, setInputVal] = useState(
    condition === OPTION_EQUAL.value ? value : "",
  );
  const previousCondition = useRef(condition);
  const previousName = useRef(name);

  useEffect(() => {
    // handle changing condition and resetting input
    if (
      condition !== previousCondition.current ||
      name !== previousName.current
    ) {
      setInputVal("");
    }
    previousCondition.current = condition;
    previousName.current = name;
    return () => {};
  }, [condition, value, values, name]);

  const getPlaceholderText = () => {
    switch (name) {
      case RULE_SECTION_TITLE:
        if (isConditionalEqual(condition)) {
          return "E.g. 1:1";
        }
        if (isConditionContainsAny(condition)) {
          return "E.g. run, workout, gym";
        }
        if (isConditionContainsAll(condition)) {
          return "E.g. Engineering, Review";
        }
        return "E.g. run, workout, gym";
      case RULE_SECTION_ATTENDEES:
        if (isConditionIsInDomain(condition)) {
          return "E.g. dundermifflin.com, vancerefrigeration.com";
        }
        return "E.g. michael@dundermifflin.com, dwight@dundermifflin.com, jim@dundermifflin.com";
      case RULE_SECTION_LOCATION:
        if (isConditionContainsAny(condition)) {
          return "E.g. Starbucks, Blue Bottle, Philz";
        }
        if (isConditionContainsAll(condition)) {
          return "E.g. park, new york";
        }
        return "E.g. starbucks";
      default:
        return "E.g. 1:1";
    }
  };

  const onKeyDown = (e) => {
    if (isConditionalEqual(condition)) {
      return;
    }
    const { keyCode } = e;
    if (
      keyCode === KEYCODE_ENTER ||
      keyCode === KEYCODE_TAB ||
      keyCode === KEYCODE_COMMA
    ) {
      // add input value
      hasEventPreventDefault(e);
      setInputVal(""); // reset input value
    }
  };

  if (
    lowerCaseAndTrimString(name) === OPTION_TYPE.ATTENDEE &&
    !isConditionIsInDomain(condition)
  ) {
    return (
      <ReactSelectAttendeeAutoComplete
        addAttendees={(attendee) => {
          const { isAddDomain, domain, emailList, value: email } = attendee;
          if (isAddDomain) {
            // domains
            if (!domain) {
              return;
            }
            const updatedValues = values.concat(domain);
            const newTagRule = [...tagRuleSections];
            if (!newTagRule[index]) {
              return;
            }
            newTagRule[index].values = updatedValues;
            setTagRuleSections(newTagRule);
            return;
          }
          if (!isEmptyArrayOrFalsey(emailList)) {
            return;
          }
          const parsedEmailsFromString = splitStringIntoEmails(email);
          if (isEmptyArrayOrFalsey(parsedEmailsFromString)) {
            return;
          }

          const newTagRule = [...tagRuleSections];
          if (!newTagRule[index]) {
            return;
          }

          const updatedValues = [
            ...values,
            ...parsedEmailsFromString
              .map(email => {
                const emailDomain = getEmailDomain(email);
                if (!emailDomain) {
                  return null;
                }
                return isConditionIsInDomain(condition) ? emailDomain : email;
              })
              .filter(Boolean),
          ];

          newTagRule[index].values = updatedValues;
          setTagRuleSections(newTagRule);
        }}
        defaultText={getPlaceholderText()}
        onEscape={(e) => {
          hasEventPreventDefault(e);
          hasStopEventPropagation(e);
          blurCalendar();
        }}
        selectedGuests={[]}
        useRecentlySearchedContacts={true}
        includeEmailAndName={true}
        useRecentlySearched={true}
        className="availability-search-attendees search-temporary-calendars light-right-panel-background-color calendarList-meet-with"
        controllerWidth={TAG_RULE_INPUT_WIDTH}
        skipContactLists={true}
        containerClassName={"ml-2.5"}
        showDomainSearchOption={true}
      />
    );
  }
  return (
    <input
      className="default-input-field ml-2.5 font-weight-300 default-font-size"
      style={{ height: 34, width: TAG_RULE_INPUT_WIDTH }}
      placeholder={getPlaceholderText()}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
      onKeyDown={onKeyDown}
      onChange={(e) => {
        const updatedText = getInputStringFromEvent(e);
        hasEventPreventDefault(e);

        const newTagRule = [...tagRuleSections];
        if (!newTagRule[index]) {
          return;
        }

        if (isConditionalEqual(condition)) {
          newTagRule[index].value = updatedText;
        } else if (!updatedText) {
          // delete last element
          newTagRule[index].values = getEveryElementExceptLast(values);
        } else if (inputVal === "") {
          if (isEmptyArray(values)) {
            // need to handle if there's no value at all here, don't want to break it up
            newTagRule[index].values = [updatedText];
          } else {
            newTagRule[index].values = values.concat(updatedText);
          }
        } else if (
          isAttendeesRule(name) &&
          [" ", ","].includes(getLastArrayElement(updatedText))
        ) {
          // if attendees and tries to use space or comma, we should split it
          newTagRule[index].values = getEveryElementExceptLast(values).concat(
            lowerCaseAndTrimString(updatedText),
          );
          setInputVal("");
          setTagRuleSections(newTagRule);
          return; // early return because following through will cause error
        } else {
          if (inputVal.length > 0 && getLastArrayElement(values) !== inputVal) {
            // pressed x to delete value, we should not replace the previous value
            newTagRule[index].values = values.concat(updatedText);
          } else {
            newTagRule[index].values =
              getEveryElementExceptLast(values).concat(updatedText);
          }
        }
        setInputVal(updatedText);
        setTagRuleSections(newTagRule);
      }}
      value={inputVal}
    />
  );
}
