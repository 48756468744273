import React, { forwardRef, useEffect } from "react";
import { createPortal } from "react-dom";
import CommandCenter from "../commandCenter";
import GoogleColors from "../../services/googleColors";
import { DEFAULT_NORMAL_TAG_RULE, getAllUniqueTagsFromNonMagicLinkUsers, getTagName, TAG_USER_TOKENS_KEY } from "../../lib/tagsFunctions";
import { updateSmartTags } from "../../services/appFunctions";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";
import { immutablySortArray } from "../../lib/arrayFunctions";
import { getDefaultUser, getUserToken } from "../../lib/userFunctions";
import { AllLoggedInUsersState, useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { isUserMagicLinkUser } from "../../services/maestroFunctions";
import { MasterAccountState } from "../../services/stores/SharedAccountData";
import { isSmartTag } from "../../lib/painterFunctions";
type TagsCommandCenterProps = {
  filterValue: string;
  handleModalClose: (value: boolean) => void;
  setDisableInput: (value: boolean) => void;
  setFilterValue: (value: string) => void;
}

/* Pass ref for our onClick handler since command center is rendered through portal */
/* Prevents closing when we click no a color */
const TagsCommandCenter = forwardRef<any, TagsCommandCenterProps>((props, ref) => {
  const {
    filterValue,
    handleModalClose,
    setDisableInput,
    setFilterValue,
  } = props;
  const currentUser = useSelector((state: any) => state.currentUser);
  const allLoggedInUsers = useAllLoggedInUsers((state: AllLoggedInUsersState) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state: MasterAccountState) => state.masterAccount);

  /* Get the layout element to create portal */
  const layoutElement = document.getElementById("layout");

  /* Map the colors for command center */
  /* Filter default since functionally it is "No color" */
  const colorOptions = () => {
    return immutablySortArray(Object.keys(GoogleColors.tagColors)).filter((key) => {
      const tag = GoogleColors.tagColors[key];
      const isDefaultColor = getTagName(tag) === "default";

      return !isDefaultColor;
    }).map((key) => {
      const tag = GoogleColors.tagColors[key];

      return {
        key: tag.name,
        title: createColorNameAndColor(tag),
        onClickHandler: () => handleOnTagClick(tag),
        searchQueries: `${tag.name}, ${tag.nickName}`,
        colorName: tag.name,
        colorId: tag.color_id,
      };
    });
  };

  /* Copied from the other color command center */
  /* Create the JSX for color + name for a given color */
  const createColorNameAndColor = (tag) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            border: tag.color === "transparent" ? "dashed 1px gray" : "",
            width: 12,
            height: 12,
            borderRadius: "50%",
            backgroundColor: tag.color,
            marginRight: 20,
          }}
        ></div>

        {capitalizeFirstLetter(tag.name)}
      </div>
    );
  };

  /* Add the new tag to settings and send request */
  const handleOnTagClick = (tag) => {
    const newTag = {
      name: filterValue,
      color: tag.color,
      color_id: tag.id,
      rules: DEFAULT_NORMAL_TAG_RULE,
      [TAG_USER_TOKENS_KEY]: allLoggedInUsers.filter((user) => !isUserMagicLinkUser({ user })).map((user) => {
        return getUserToken(user);
      }),
    };
    const getAllUserTokens = () => {
      return allLoggedInUsers.filter((user) => !isUserMagicLinkUser({ user })).map((user) => {
        return getUserToken(user);
      });
    };

    /* Set update event tags to true */
    const uniqueTags = [...getAllUniqueTagsFromNonMagicLinkUsers({
      allLoggedInUsers,
      masterAccount,
      currentUser,
    })];
    uniqueTags.forEach((tag) => {
      if (!isSmartTag({ painterSetting: tag })) {
        tag[TAG_USER_TOKENS_KEY] = getAllUserTokens();
      }
    });

    updateSmartTags({
      shouldUpdateEventTags: true,
      smartTags: [...uniqueTags, newTag],
      user: getDefaultUser({
        allLoggedInUsers,
        masterAccount,
        currentUser,
      }),
    });

    setFilterValue("");
  };

  /* Re-enable keyboard input in selector component when this unmounts */
  useEffect(() => () => {
    setDisableInput(false);
  }, [setDisableInput]);

  return (
    <div className="paint-colors-command-center" ref={ref}>
      { /* Create a portal to make the command center open in middle of screen */}
      {layoutElement ? createPortal(
        (
          <CommandCenter
            // handleCloseModal={handleModalClose}
            handleCloseModal={handleModalClose}
            options={colorOptions()}
            placeholder="Select color for new tag"
            title=""
          />
        ), layoutElement,
      ) : null}
    </div>
  );
});

TagsCommandCenter.displayName = "TagsCommandCenter";
export default TagsCommandCenter;
