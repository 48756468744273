import React from "react";
import {
  DARK_MODE_TEXT_COLOR,
  DEFAULT_FONT_COLOR,
} from "../../services/globalVariables";

export default function LargeShortTile({ text, isLeftHandSide = false }) {
  const containerWidthSize = text.length > 2 ? 120 : 68;
  return (
    <div
      className="flex items-center justify-center select-none"
      style={{
        height: 68,
        width: containerWidthSize,
        maxHeight: 68,
        minHeight: 68,
        fontSize: 48,
        fontWeight: 400,
        boxShadow: "rgb(0 0 0 / 12%) 0px 4px 0px",
        borderRadius: 8,
        marginRight: isLeftHandSide ? 10 : 0,
        backgroundColor: DEFAULT_FONT_COLOR,
      }}
    >
      <div
        className="flex justify-center items-center h-12"
        style={{
          color: DARK_MODE_TEXT_COLOR,
        }}
      >
        {text}
      </div>
    </div>
  );
}
