import React from "react";
import SelectColor from "../selectColor";
import { getCalendarColorHex } from "../../services/calendarAccessors";
import {
  doesCalendarHaveCategories,
  isCalendarOutlookCalendar,
} from "../../lib/calendarFunctions";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import ColoredLine from "../line";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";
import SelectCategories from "../eventForm/selectCategories";

export default function GroupVoteHoldsSection({
  holdsColorID,
  selectedCalendar,
  selectedUser,
  setHoldsColorID,
  setHoldsTitle,
  title,
  width,
  onChangeCategories,
  selectedOutlookCategories,
}) {
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories,
  );
  const renderHoldsTitleSection = () => {
    return (
      <div className="display-flex-flex-direction-row align-items-center justify-content-space-between margin-top-20">
        <div className="default-font-size font-weight-300 truncate-text max-width-100px width-100px secondary-text-color">
          Title
        </div>
        <input
          className="default-input-field"
          onChange={(e) => setHoldsTitle(getInputStringFromEvent(e))}
          style={{ width: 220, padding: 10 }}
          value={title}
        />
      </div>
    );
  };

  const renderHoldsColorSection = () => {
    const hasOutlookCategories =
      isCalendarOutlookCalendar(selectedCalendar) &&
      doesCalendarHaveCategories({
        calendar: selectedCalendar,
        outlookCategories: outlookCategories,
      });
    if (isCalendarOutlookCalendar(selectedCalendar) && !hasOutlookCategories) {
      // only show holds if there's categories and outlook
      return null;
    }

    const renderColorSelect = () => {
      if (isCalendarOutlookCalendar(selectedCalendar)) {
        return (
          <SelectCategories
            initialCategories={selectedOutlookCategories}
            onChange={(newCategories) => {
              if (onChangeCategories) {
                onChangeCategories(newCategories);
              }
            }}
            selectedCalendar={selectedCalendar}
            containerClassName={"width-220px"}
            showBorder={true}
          />
        );
      }
      return (
        <SelectColor
          onChange={(data) => {
            setHoldsColorID(data.value);
          }}
          selectedColorID={holdsColorID}
          showBorder={true}
          locationRight={0}
          defaultColorOverride={getCalendarColorHex(selectedCalendar)}
          selectedUser={selectedUser}
        />
      );
    };

    return (
      <div className="display-flex-flex-direction-row align-items-center mt-2.5">
        <div className="default-font-size font-weight-300 truncate-text width-100px mr-auto secondary-text-color">
          Holds color
        </div>
        {renderColorSelect()}
      </div>
    );
  };

  return (
    <>
      <div className="mx-auto" style={{ width }}>
        <p className="font-size-12 font-weight-300 create-group-vote-slots-hold-description secondary-text-color">
          Fill in details for the calendar holds that will be auto-created from
          the Slots you dragged
        </p>
        {renderHoldsTitleSection()}
        {renderHoldsColorSection()}
      </div>
      <div className="mt-3">
        <ColoredLine />
      </div>
    </>
  );
}
