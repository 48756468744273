import React from "react";
import { BACKEND_MONTH, ROLLING_SEVEN_DAY } from "../services/globalVariables";
import { useSelector } from "react-redux";
import ColoredLine from "./line";
import classNames from "classnames";
import { Check } from "react-feather";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import {
  toggleShowDeclinedEvents,
  toggleWeekends,
} from "./settings/settingFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { showAccountDeclinedEvents } from "../lib/settingsFunctions";
import { useAppSettings } from "../services/stores/settings";
import {
  isActionModeCreateAvailability,
  isInMonthlyView,
} from "../services/appFunctions";

const DAY_VIEW_OPTION = { label: "Day", value: 1, hotKey: "D" };
const WEEK_VIEW_OPTION = { label: "Week", value: 7, hotKey: "W" };
const SEVEN_DAY_ROLLING_VIEW_OPTION = {
  label: "7 days",
  value: ROLLING_SEVEN_DAY,
  hotKey: "7",
};
const FOUR_DAY_ROLLING_VIEW_OPTION = { label: "4 days", value: 4, hotKey: "4" };
const MONTH_VIEW_OPTION = { label: "Month", value: BACKEND_MONTH, hotKey: "M" };

const OPTION_CLASSNAME = classNames(
  "account-email-hover", // hover
  "flex items-center cursor-pointer",
  "rounded-md",
  "py-2",
  "justify-between",
  "hoverable-container"
);
const OPTION_STYLE = {
  marginLeft: "-12px",
  marginRight: "-12px",
  paddingLeft: "12px",
  paddingRight: "12px",
};

export default function CalendarDropdownOptions({ onClose }) {
  const actionMode = useSelector((state) => state.actionMode);
  const selectedCalendarView = useSelector(
    (state) => state.selectedCalendarView
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const shouldOnlyShowWorkWeek = useSelector(
    (state) => state.shouldOnlyShowWorkWeek
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isSplitView = useAppSettings((state) => state.isSplitView);
  const setIsSplitView = useAppSettings((state) => state.setIsSplitView);

  const showEnabledHideWeekendOption = () => {
    return selectedCalendarView === 7;
  };

  const getOptions = () => {
    if (isActionModeCreateAvailability(actionMode)) {
      return [
        DAY_VIEW_OPTION,
        WEEK_VIEW_OPTION,
        SEVEN_DAY_ROLLING_VIEW_OPTION,
        FOUR_DAY_ROLLING_VIEW_OPTION,
      ];
    }
    return [
      DAY_VIEW_OPTION,
      WEEK_VIEW_OPTION,
      SEVEN_DAY_ROLLING_VIEW_OPTION,
      FOUR_DAY_ROLLING_VIEW_OPTION,
      MONTH_VIEW_OPTION,
    ];
  };
  return (
    <div className="mx-4 my-1.5 default-font-size select-none">
      {getOptions().map((option) => (
        <div
          key={`calendar-dropdown-select-${option.value}`}
          className={OPTION_CLASSNAME}
          style={OPTION_STYLE}
          onClick={() => {
            onClose();
            mainCalendarBroadcast.publish(
              MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
              option.value
            );
          }}
        >
          {option.label}
          <ShortcutTile
            key={`calendar-view-shortcut-${option.label}`}
            isCommandCenter={isDarkMode ? true : false}
            shortcut={option.hotKey}
            width={23}
            height={23}
          />
        </div>
      ))}

      <ColoredLine inputClassName="my-1" />

      {showEnabledHideWeekendOption() ? (
        <div
          className={OPTION_CLASSNAME}
          style={OPTION_STYLE}
          onClick={() => {
            onClose();
            toggleWeekends({ masterAccount });
          }}
        >
          Show Weekends
          {shouldOnlyShowWorkWeek ? null : <RenderCheck />}
        </div>
      ) : (
        <RenderDisabledHideWeekendView
          shouldOnlyShowWorkWeek={shouldOnlyShowWorkWeek}
        />
      )}
      <div
        className={OPTION_CLASSNAME}
        style={OPTION_STYLE}
        onClick={() => {
          onClose();
          toggleShowDeclinedEvents({ masterAccount });
        }}
      >
        Show declined events
        {showAccountDeclinedEvents({ masterAccount }) ? <RenderCheck /> : null}
      </div>

      {isInMonthlyView(selectedCalendarView) ? (
        <RenderDisabledSplitView isSplitView={isSplitView} />
      ) : (
        <div
          className={OPTION_CLASSNAME}
          style={OPTION_STYLE}
          onClick={() => {
            setIsSplitView(!isSplitView);
            onClose();
          }}
        >
          Split view
          {isSplitView ? <RenderCheck /> : null}
        </div>
      )}
    </div>
  );
}

function RenderDisabledHideWeekendView(shouldOnlyShowWorkWeek) {
  return (
    <div
      className={classNames(
        OPTION_CLASSNAME,
        "secondary-text-color",
        "background-color-transparent-important",
        "cursor-default-important"
      )}
      style={OPTION_STYLE}
    >
      Show Weekends
      {shouldOnlyShowWorkWeek ? null : <RenderCheck />}
    </div>
  );
}

function RenderCheck() {
  return (
    <Check
      className="non-clickable-icon-modal ml-3"
      size="14"
      strokeWidth={3}
    />
  );
}

// for monthly when we don't want to split
function RenderDisabledSplitView(isSplitView) {
  return (
    <div
      className={classNames(
        OPTION_CLASSNAME,
        "secondary-text-color",
        "background-color-transparent-important",
        "cursor-default-important"
      )}
      style={OPTION_STYLE}
    >
      Split view
      {isSplitView ? null : <RenderCheck />}
    </div>
  );
}
