import React from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

const MaestroCard = (props) => {
  const {
    bluePillText,
    onClick,
    shouldAddLeftMargin,
    text,
  } = props;

  return (
    <div
      className="flex flex-row cursor-pointer"
      style={{ height: "min-content" }}
    >
      <div
        className={classNames(
          "h-60 w-60",
          "flex flex-col items-center justify-center relative",
          "bg-transparent cursor-pointer font-weight-400 rounded-md select-none",
          "maestro-login-card",
          shouldAddLeftMargin ? "ml-10" : "",
        )}
        onClick={onClick}
      >
        {
          bluePillText ? (
            <div className="maestro-login-card-recommended">
              {bluePillText}
            </div>
          ) : null
        }
        <div className="font-size-16 font-size-300 maestro-login-card-text">
          {text}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MaestroCard);
