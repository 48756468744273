import { stringIncludesZoomURLCombinations } from "../services/commonUsefulFunctions";
import { isUrl } from "./stringFunctions";

const ERROR_NOT_URL = "* Please enter a valid URL";
const ERROR_NOT_GITHUB = "* Please enter a valid GitHub link.";
const ERROR_NOT_LINKEDIN = "* Please enter a valid LinkedIn link.";
const ERROR_NOT_TELEGRAM_USERNAME_OR_URL = "* Please enter a valid Telegram username or URL.";
const ERROR_NOT_TWITTER_HANDLE_OR_URL = "* Please enter a valid Twitter handle or URL.";
const ERROR_NOT_YOUTUBE = "* Please enter a valid YouTube URL or handle.";
const ERROR_NOT_YOUTUBE_HANDLE = "* Please enter a valid YouTube handle.";
const ERROR_NOT_ZOOM = "* Please enter a valid Zoom link.";
const ERROR_NO_HTTPS = "* The URL above needs to include https://";

const GITHUB_REGEX = /github\.com\/.+/i
// For LinkedIn: do not explicitly check for .com because other domains (such as .ca and .co.uk) are also valid.
const LINKEDIN_REGEX = /linkedin\.[a-z\.]+\/(in|company)\/.+/i
// https://support.google.com/youtube/answer/6180214
const YOUTUBE_REGEX = /youtube\.com\/(@|channel\/|c\/|user\/).+/i

// https://core.telegram.org/method/account.checkUsername
const TELEGRAM_PATH_URL_REGEX = /(https:\/\/)?t\.me\/(?<username>[a-zA-Z0-9\_]+)/
const TELEGRAM_SUBDOMAIN_URL_REGEX = /(https:\/\/)?(?<username>[a-zA-Z0-9\_]+)\.t\.me/
const TELEGRAM_USERNAME_REGEX = /^[a-zA-Z0-9\_]+$/

// https://help.twitter.com/en/managing-your-account/x-username-rules
const TWITTER_HANDLE_REGEX = /^[a-zA-Z0-9\_]+$/
// https://support.google.com/youtube/answer/11585688
const YOUTUBE_HANDLE_REGEX = /^[a-zA-Z0-9\_\-\.]+$/

export function doesUrlIncludeProtocol(url: string) {
  const lowerCaseUrl = url.toLowerCase();
  return lowerCaseUrl.startsWith("https://") || lowerCaseUrl.startsWith("http://");
}

export function extractTelegramUsernameFromUrl(url: string) {
  const pathUrlMatch = url.match(TELEGRAM_PATH_URL_REGEX);
  if (pathUrlMatch?.groups?.username) {
    return pathUrlMatch?.groups?.username;
  }

  const subdomainUrlMatch = url.match(TELEGRAM_SUBDOMAIN_URL_REGEX);
  if (subdomainUrlMatch?.groups?.username) {
    return subdomainUrlMatch?.groups?.username;
  }
}

export function extractTwitterHandleFromUrl(url: string) {
  for (const domain of ["x.com/", "twitter.com/"]) {
    if (url.includes(domain)) {
      const path = url.split(domain)[1] ?? "";
      const handle = path.split("?")[0];

      if (!handle) {
        continue;
      }

      if (handle.startsWith("@")) {
        return handle;
      }
      return `@${handle}`;
    }
  }
}

export function extractYouTubeHandleFromUrl(url: string) {
  const handleIndex = url.indexOf("@");
  if (handleIndex >= 0) {
    return url.slice(handleIndex);
  }
}

export function validateWebsiteUrl(url: string, regExp?: RegExp, regExpErrorMessage?: string) {
  if (!isUrl(url)) {
    return ERROR_NOT_URL;
  } else if (regExp && regExpErrorMessage && !regExp.test(url)) {
    return regExpErrorMessage;
  }
}

export function validateGitHubUrl(url: string) {
  return validateWebsiteUrl(url, GITHUB_REGEX, ERROR_NOT_GITHUB)
}

export function validateLinkedInUrl(url: string) {
  return validateWebsiteUrl(url, LINKEDIN_REGEX, ERROR_NOT_LINKEDIN);
}

export function validateTelegramUsernameOrUrl(usernameOrUrl: string) {
  if (!(
    TELEGRAM_USERNAME_REGEX.test(usernameOrUrl) ||
    TELEGRAM_PATH_URL_REGEX.test(usernameOrUrl) ||
    TELEGRAM_SUBDOMAIN_URL_REGEX.test(usernameOrUrl)
  )) {
    return ERROR_NOT_TELEGRAM_USERNAME_OR_URL;
  }
}

export function validateTwitterHandleOrUrl(handleOrUrl: string) {
  let handle = handleOrUrl;

  if (handleOrUrl.includes("x.com/") || handleOrUrl.includes("twitter.com/")) {
    handle = extractTwitterHandleFromUrl(handleOrUrl) ?? "";
  } 

  const username = handle.startsWith("@") ? handle.slice(1) : handle;

  if (!TWITTER_HANDLE_REGEX.test(username)) {
    return ERROR_NOT_TWITTER_HANDLE_OR_URL;
  }
}

/**
 * Not every YouTube channel has a proper handle, so support the full URL as well.
 */
export function validateYouTubeUrlOrHandle(urlOrHandle: string) {
  if (urlOrHandle.startsWith("@")) {
    if (!YOUTUBE_HANDLE_REGEX.test(urlOrHandle.slice(1))) {
      return ERROR_NOT_YOUTUBE_HANDLE;
    }
  } else {
    if (!YOUTUBE_REGEX.test(urlOrHandle)) {
      return ERROR_NOT_YOUTUBE;
    }
  }
}

export function validateZoomUrl(url: string | null | undefined) {
  if (!url || !isUrl(url)) {
    return ERROR_NOT_URL;
  } else if (!stringIncludesZoomURLCombinations(url)) {
    return ERROR_NOT_ZOOM;
  } else if (!doesUrlIncludeProtocol(url)) {
    return ERROR_NO_HTTPS;
  }
}
