import React, { Component } from "react";
import { connect } from "react-redux";
import { Bell, X } from "react-feather";
import { convertMinutesIntoDayHoursAndMinutes } from "../services/commonUsefulFunctions";
import Classnames from "classnames";
import {
  getCalendarFromUserCalendarID,
} from "../lib/calendarFunctions";
import { useAllCalendars } from "../services/stores/SharedAccountData";
import {
  getCalendarDefaultReminders
} from "../services/calendarAccessors";
import classNames from "classnames";
import { format, startOfDay, subMinutes } from "date-fns";
import { MINUTE_IN_DAY } from "../services/globalVariables";
import { capitalizeFirstLetter } from "../lib/stringFunctions";

class EventReminder extends Component {
  render() {
    const {
      reminders
    } = this.props;

    return (
      <div>
        {reminders.useDefault
          ? this.renderDefaultReminder()
          : this.renderAdditionalReminders()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderDefaultReminder() {
    const { allCalendars } = this.props.allCalendars;
    
    const {
      userCalendarID
    } = this.props;

    const matchingCalendar = getCalendarFromUserCalendarID({
      userCalendarID,
      allCalendars
    });

    const matchingCalendarDefaultReminders =
      getCalendarDefaultReminders(matchingCalendar);
    // we only ever show reminders for current user's calendar

    if (matchingCalendarDefaultReminders?.length > 0) {
      return matchingCalendarDefaultReminders.map((reminder, index) => {
        let reminderTextType =
          reminder.method === "popup"
            ? "Notification"
            : capitalizeFirstLetter(reminder.method);
        let reminderText =
          reminderTextType +
          " " +
          convertMinutesIntoDayHoursAndMinutes(reminder.minutes) +
          " before";

        if (parseInt(reminder.minutes) === 0) {
          if (this.props.editMode) {
            reminderText = reminderTextType + " 0 minutes before";
          } else {
            reminderText = "When event starts";
          }
        }

        return this.renderReminder({reminderText, reminder, index, isLastReminderIndex: index === matchingCalendarDefaultReminders.length - 1});
      });
    }

    return null;
  }

  /**
   * @param {number} minutes
   */
  getDayText(minutes) {
    if (minutes > MINUTE_IN_DAY) {
      return `${Math.ceil(minutes / MINUTE_IN_DAY)} days before`;
    } else if (minutes > 0) {
      return "the day before";
    } else {
      return "the day of";
    }
  }

  getAllDayNotificationTimeString(minutesBefore) {
    const reminderTime = subMinutes(startOfDay(new Date()), minutesBefore);
    if (this.props.format24HourTime) {
      return format(reminderTime, "k:mm");
    }
    if (reminderTime.getMinutes() === 0) {
      return format(reminderTime, "h aa");
    }
    
    return format(reminderTime, "p");
  }

  renderAdditionalReminders() {
    const {
      isAllDayEvent
    } = this.props;

    if (isAllDayEvent) {
      return (
        this.props.reminders.overrides &&
        this.props.reminders.overrides.map((reminder, index) => {
          let reminderTextType =
            reminder.method === "popup"
              ? "Notification"
              : capitalizeFirstLetter(reminder.method);

          let reminderText = `${reminderTextType} ${this.getDayText(reminder.minutes)} at ${this.getAllDayNotificationTimeString(reminder.minutes)}`;
  
          if (parseInt(reminder.minutes) === 0) {
            reminderText = "When event starts";
          }
  
          return this.renderReminder({reminderText, reminder, index, isLastReminderIndex: index === this.props.reminders.overrides.length - 1});
        })
      );
    } else {
      return (
        this.props.reminders.overrides &&
        this.props.reminders.overrides.map((reminder, index) => {
          let reminderTextType =
            reminder.method === "popup"
              ? "Notification"
              : capitalizeFirstLetter(reminder.method);
          let reminderText =
            reminderTextType +
            " " +
            convertMinutesIntoDayHoursAndMinutes(reminder.minutes) +
            " before";
  
          if (parseInt(reminder.minutes) === 0) {
            if (this.props.editMode) {
              reminderText = reminderTextType + " 0 minutes before";
            } else {
              reminderText = "When event starts";
            }
          }
  
          return this.renderReminder({reminderText, reminder, index, isLastReminderIndex: index === this.props.reminders.overrides.length - 1});
        })
      );
    }
  }

  renderReminder({
    reminderText, 
    reminder, 
    index,
    isLastReminderIndex
  }) {
    return (
      <div className="event-alarm-wrapper" key={`addtional_reminders_${index}`}>
        <div
          className={Classnames(
            "event-alarm-icon",
            "mt-1",
            "secondary-text-color",
            isLastReminderIndex ? "mb-1.5" : ""
          )}
          key={`addtional_reminders_icon_wrapper_${index}`}
        >
          <Bell className={classNames("secondary-text-color")} size="14" />

          <div style={{ marginLeft: 10 }}>{reminderText}</div>
        </div>

        {this.props.editMode ? this.renderXButton(reminder) : null}
      </div>
    );
  }

  renderXButton(reminder) {
    return (
      <div
        className={Classnames("display-flex-center")}
        onClick={() => this.props.removeReminder(reminder)}
      >
        <X className="clickable-icon" size={13} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentUser, format24HourTime } = state;

  return {
    currentUser,
    format24HourTime
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();

  return <BaseComponent {...props} allCalendars={allCalendars} />;
};

export default connect(
  mapStateToProps,
  null
)(withStore(EventReminder));
