import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommandCenter from "./commandCenter";
import Broadcast from "../broadcasts/broadcast";
import {
  PutCommaBetweenWordInString,
  isEditable,
  OpenLink,
  isElectron,
  openGoogleLink,
  determineGoogleSettingsLink,
  isOnboardingMode,
  guessTimeZone,
  openConferencingURL,
  isMac,
  isWindows,
  isTooltipCompleted,
} from "../services/commonUsefulFunctions";
import {
  BUSY_DURING_EVENT,
  EVENT_TEMPLATE,
  FREE_DURING_EVENT,
  TEXT_TEMPLATE,
} from "../services/googleCalendarService";
import {
  EVENT_FORM_END_DATE_INPUT,
  EVENT_FORM_END_TIME_INPUT,
  EVENT_FORM_START_DATE_INPUT,
  EVENT_FORM_START_TIME_INPUT,
  PC_CONTROL_KEY,
  eventFormSectionHotKeysIndex,
  EVENT_FORM_SUMMARY,
  EVENT_FORM_LOCATION,
  EVENT_FORM_ATTENDEE,
  EVENT_FORM_DESCRIPTION,
  EVENT_FORM_COLOR,
  BACKEND_MONTH,
  GMAIL,
  GOOGLE_DRIVE,
  GOOGLE_CALENDAR,
  NEW_GOOGLE_MEET,
  SLOTS_AVAILABILITY_SELECTION,
  PERSONAL_LINK_SELECTION,
  HIDE_SHOW_AVAILABILITY_DETAILS,
  SLOTS_STYLE_PICKER_ID,
  ROLLING_SEVEN_DAY,
  COMMAND_KEY,
  DOWNLOAD_INTEL_DESKTOP_LINK,
  DOWNLOAD_M1_DESKTOP_LINK,
  GROUP_VOTE_SELECT_OPTION,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  DOWNLOAD_PC_DESKTOP_LINK,
  TOGGLE_RIGHT_PANEL_HOTKEY,
  SLOTS_LINK_ALONE,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
  SECOND_IN_MS,
  ACTION_MODE,
} from "../services/globalVariables";
import Push from "push.js";
import {
  isDemoAccount,
  isInternalTeamUser,
  shouldDisplayColorLabel,
  shouldHideAIFeatures,
  shouldShowCalendarAudit,
  stripeCustomerPortalFlag,
} from "../lib/featureFlagFunctions";
import {
  createWritableCalendarList,
  doTemporaryTimeZonesExist,
} from "../lib/stateManagementFunctions";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import {
  getZoomPersonalLink,
} from "../lib/conferencing";
import { allowOutlookEventProposal, getPreviouslySelectedSlots, isEventHiddenEvent, isGoogleEvent, isOutOfOfficeEvent, shouldShowHiddenEventsOption, shouldShowProposeTime } from "../lib/eventFunctions";
import appBroadcast from "../broadcasts/appBroadcast";
import {
  SLOTS_RICH_TEXT,
  SLOTS_PLAIN_TEXT,
  SLOTS_PLAIN_TEXT_URL,
  FOCUS_MODE_HOTKEY,
  APP_SETTINGS,
  CONFERENCING_SETTINGS_ID,
  BACKEND_SETTINGS_NAMES,
  FEEDBACK_TYPE,
  PREFERENCES_SETTINGS_ID,
} from "../lib/vimcalVariables";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
  useZoomSchedulers,
} from "../services/stores/SharedAccountData";
import {
  isCalendarSelected,
  getUserCalendarIDFromEmail,
  getCalendarName,
  getAllPrimaryCalendarsIDsFromAllCalendars,
  getDuplicateCalendarNames,
} from "../lib/calendarFunctions";
import { tooltipKeys } from "../services/tooltipVariables";
import {
  getEventConferenceURL,
  getEventDescription,
  getEventLocation,
  getEventMasterEventID,
  getEventUserCalendarID,
} from "../services/eventResourceAccessors";
import {
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { useFeatureFlags, useHideRightHandSidebar } from "../services/stores/appFunctionality";
import {
  getPreviewEvent,
  isActionModeCreateAvailability,
  isActionModeUpsertEvent,
  isCalendarListEventFormFindTimeButtonShowing,
  isCopySlotsButtonShowing,
  isEventFormFindTimeButtonShowing,
  isFocusModeCountingDown,
  isFocusModePaused,
  isFocusModePlayingMusic,
  isGoogleUser,
  isInExpandedViewState,
  isInHomeState,
  isMacIntelSync,
  isRSVPSectionRendered,
  isSlotBreakDurationToggleShowing,
  isSlotsDetailSectionOption,
  isSlotsPageOpen,
  shouldTruncateRightHandPanel,
  triggerRefreshWithOnlineCheck,
  updateMasterAccountSettingsForFrontendAndBackend,
} from "../services/appFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import { getUpcomingCalendarUserCalendarIDs, getUserEmail, getUserProvider, isUserSSOAdmin } from "../lib/userFunctions";
import { onClickGoogleProposeTime } from "../lib/googleFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import { isOutlookEventForwardable } from "../lib/outlookFunctions";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { getZoomSchedulers } from "../services/zoomFunctions";
import { useMetricsStore } from "../services/stores/metricsStore";
import { trackMetricsOpen } from "./metrics/metricsAccessorFunctions";
import eventFormBroadcast from "../broadcasts/eventFormBroadcast";
import { delayByMs } from "../lib/asyncFunctions";
import { AVAILABILITY_BROADCAST_VALUES, BROADCAST_VALUES, EXPANDED_VIEW_BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { shouldHideDelegatedUserCalendar } from "../services/maestroFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { toggleShowDeclinedEvents, toggleWeekends } from "./settings/settingFunctions";
import { getCustomConferencingName, shouldAutoAddEmoji, shouldDimPastEvents, shouldDisplayWeekNumber, shouldHideDefaultSignature, shouldMergeEvents, showAccountDeclinedEvents } from "../lib/settingsFunctions";
import { replacePlusWithAndAndCapitalize, truncateString } from "../lib/stringFunctions";
import { getDeleteEventCopy } from "../lib/copy";
import expandedEventViewBroadcast from "../broadcasts/expandedEventViewBroadcast";
import { getHomeLink } from "../lib/envFunctions";
import { trackReferral } from "./tracking";
import { useAppSettings } from "../services/stores/settings";
import { PERMISSION_MODAL_TYPES } from "../lib/authFunctions";

const DEFAULT_PLACEHOLDER = `Try: "Availability", "Time travel", "Next week", or "Template"`;

class CommandCenterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: this.props.defaultText ?? "",
      activeCommandCentersShortCut: this.props.isMac
        ? COMMAND_KEY
        : PC_CONTROL_KEY,
    };

    this.updateDisplayString = this.updateDisplayString.bind(this);
    this.goToEventFormSection = this.goToEventFormSection.bind(this);
    this.flashShortcuts = this.flashShortcuts.bind(this);
    this.viewAllShortcuts = this.viewAllShortcuts.bind(this);
    this.toggleVimCalSignature = this.toggleVimCalSignature.bind(this);
    this.toggleDarkMode = this.toggleDarkMode.bind(this);
    this.changeEventColor = this.changeEventColor.bind(this);
    this.goToEditEvent = this.goToEditEvent.bind(this);
    this.emailGuests = this.emailGuests.bind(this);
    this.openUpdatePhoneModal = this.openUpdatePhoneModal.bind(this);
    this.toggleWorkWeek = this.toggleWorkWeek.bind(this);
    this.openPainter = this.openPainter.bind(this);
    this.toggleColorSetting = this.toggleColorSetting.bind(this);
    this.openVimcal = this.openVimcal.bind(this);
    this.toggleWeekNumbers = this.toggleWeekNumbers.bind(this);
    this.setAvailabilityType = this.setAvailabilityType.bind(this);
    this.copyAndOpenZoomPersonalLink =
      this.copyAndOpenZoomPersonalLink.bind(this);
    this.openNewGoogleMeet = this.openNewGoogleMeet.bind(this);
    this.setMatchOSColorScheme = this.setMatchOSColorScheme.bind(this);
    this.openSelectFile = this.openSelectFile.bind(this);
    this.testNotifications = this.testNotifications.bind(this);
    this.openBillingPortal = this.openBillingPortal.bind(this);
    this.referToVimcal = this.referToVimcal.bind(this);
    this.toggleCalendarAuditCSV = this.toggleCalendarAuditCSV.bind(this);
  }

  render() {
    if (!this.props.shouldShowCommandCenter) {
      return null;
    }
    return (
      <CommandCenter
        title="Command Center"
        placeholder={DEFAULT_PLACEHOLDER}
        handleCloseModal={this.props.handleCloseModal}
        options={this.whichOptions()}
        onChangeInput={this.updateDisplayString}
        showResultFromDefaultText={true}
        usePlaceHolderAsDefaultText={DEFAULT_PLACEHOLDER}
        disableSelectOption={this.props.disableSelectOption}
        defaultText={this.props.defaultText}
      />
    );
  }

  componentDidMount() {
    this._isMounted = true;

    /* Check if the tooltip has been completed */
    const { masterAccount } = this.props.masterAccount;

    if (!isTooltipCompleted(masterAccount, tooltipKeys.COMMAND_CENTER)) {
      Broadcast.publish(BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED, tooltipKeys.COMMAND_CENTER);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //================
  // EVENT HANDLERS
  //================

  rescheduleEvent() {
    Broadcast.publish("OPEN_RESCHEDULE_EVENT_COMMAND_CENTER");
  }

  acceptEvent() {
    Broadcast.publish("ACCEPT_EVENT");
  }

  declineEvent() {
    Broadcast.publish("DECLINE_EVENT");
  }

  tentativelyAccept() {
    Broadcast.publish("MAYBE_ATTENDING_EVENT");
  }

  changeEventLocation() {
    this.goToEditEvent();

    Broadcast.publish("GO_TO_LOCATION");
  }

  changeEventTitle() {
    this.goToEditEvent();

    Broadcast.publish("GO_TO_TITLE");
  }

  changeEventAttendeeList() {
    this.goToEditEvent();

    Broadcast.publish("GO_TO_ATTENDEE_LIST");
  }

  changeEventDescription() {
    this.goToEditEvent();

    Broadcast.publish("GO_TO_DESCRIPTION");
  }

  //=================
  // PRIVATE METHODS
  //=================

  whichOptions() {
    if (isOnboardingMode()) {
      return this.getDefaultHomeOptions();
    } else if (isActionModeUpsertEvent(this.props.actionMode)) {
      return this.getEditEventOptions();
    } else if (isActionModeCreateAvailability(this.props.actionMode)) {
      return this.getCreateAvailabilityOptions();
    } else if (
      (isInExpandedViewState() || isInHomeState()) &&
      !isEmptyObjectOrFalsey(this.getPreviewEvent())
    ) {
      return this.constructPreviewedEventOptions();
    } else {
      return this.getDefaultHomeOptions();
    }
  }

  flashShortcuts() {
    this.props.toggleGlobalKeyMap();
  }

  updateDisplayString(string) {
    this.setState({ inputText: string });
    if (this.props.updateSearchText) {
      this.props.updateSearchText(string);
    }
  }

  respondToEvent() {
    if (isRSVPSectionRendered()) {
      const rsvpOptions = [
        {
          key: "acceptEvent",
          title: "Accept event",
          onClickHandler: this.acceptEvent.bind(this),
          searchQueries: "accept, yes, attending, attend, event, rsvp",
          shortcut: "G and Y",
        },
        {
          key: "declineEvent",
          title: "Decline event",
          onClickHandler: this.declineEvent.bind(this),
          searchQueries: "decline, no, refute, event, not, rsvp",
          shortcut: "G and N",
        },
        {
          key: "maybeEvent",
          title: "Tentatively attending",
          onClickHandler: this.tentativelyAccept.bind(this),
          searchQueries:
            "maybe, tentatively, tentative, attending, attend, event, rsvp",
          shortcut: "G and M",
        },
      ];
      const event = this.getPreviewEvent();
      if (isGoogleEvent(event)) {
        return rsvpOptions.concat(this.addGoogleRSVPCommentOption());
      }
      return rsvpOptions;
    } else {
      return null;
    }
  }

  constructPreviewedEventOptions() {
    const event = this.getPreviewEvent();

    if (!event) {
      return this.getDefaultHomeOptions();
    }

    const description = getEventDescription(event);
    let eventResponse = this.respondToEvent() || [];

    let editEventOptions = [
      {
        key: "rescheduleEvent",
        title: "Reschedule event",
        onClickHandler: this.rescheduleEvent.bind(this),
        searchQueries:
          "change time, modify, update, move back forward, reschedule, push, find another, start, end",
        shortcut: "R",
      },
      {
        key: "editPreviewedEvent",
        title: "Edit event",
        onClickHandler: this.goToEditEvent,
        searchQueries: "change, edit, event",
        shortcut: "E",
      },
      this.sendGuestEmail(),
      this.sendRunningLateEmail(),
      {
        key: "eventTitle",
        title: "Change event title",
        onClickHandler: () => this.goToEventFormSection(EVENT_FORM_SUMMARY),
        searchQueries: "title, name, summary, change, edit",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_SUMMARY]
        ),
      },
      {
        key: "changeEventStartDate",
        title: "Change event start date",
        onClickHandler: () =>
          this.goToEventFormSection(EVENT_FORM_START_DATE_INPUT),
        searchQueries: "start, date, change, edit",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_START_DATE_INPUT]
        ),
      },
      {
        key: "changeEventStartTime",
        title: "Change event start time",
        onClickHandler: () =>
          this.goToEventFormSection(EVENT_FORM_START_TIME_INPUT),
        searchQueries: "change, event, start, time",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_START_TIME_INPUT]
        ),
      },
      {
        key: "changeEventEndTime",
        title: "Change event end time",
        onClickHandler: () =>
          this.goToEventFormSection(EVENT_FORM_END_TIME_INPUT),
        searchQueries: "change, event, end, time",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_END_TIME_INPUT]
        ),
      },
      {
        key: "changeEventEndDate",
        title: "Change event end date",
        onClickHandler: () =>
          this.goToEventFormSection(EVENT_FORM_END_DATE_INPUT),
        searchQueries: "change, event, end, date",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_END_DATE_INPUT]
        ),
      },
      {
        key: "eventLocation",
        title: "Change event location",
        onClickHandler: () => this.goToEventFormSection(EVENT_FORM_LOCATION),
        searchQueries:
          "change, event, location, meet, place, maps, update maps",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_LOCATION]
        ),
      },
      {
        key: "eventAttendee",
        title: "Add/remove attendees",
        onClickHandler: () => this.goToEventFormSection(EVENT_FORM_ATTENDEE),
        searchQueries:
          "change, event, remove, attendee, guest, guests, people, contact, add people, remove",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_ATTENDEE]
        ),
      },
      {
        key: "copyAttendees",
        title: "Copy guest emails",
        onClickHandler: this.copyGuestEmails,
        searchQueries: "copy, email, emails, attendees, guest",
      },
      {
        key: "changeEventColor",
        title: "Change event color",
        onClickHandler: this.changeEventColor,
        searchQueries: "change, event, colors, colours",
      },
      {
        key: "eventDescription",
        title: "Change event description",
        onClickHandler: () => this.goToEventFormSection(EVENT_FORM_DESCRIPTION),
        searchQueries:
          "change, event, description, edit, edit notes, notes, update",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_DESCRIPTION]
        ),
      },
      {
        key: "deleteEvent",
        title: getDeleteEventCopy({event, alwaysShowEventText: true}),
        onClickHandler: () => Broadcast.publish("CLICK_DELETE"),
        searchQueries: "delete, remove",
        shortcut: "Del",
      },
      {
        key: "turnEventIntoTemplate",
        title: "Create template from event",
        onClickHandler: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TURN_EVENT_INTO_TEMPLATE),
        searchQueries: "turn, make, convert, template, snippet",
      },
      this.viewShortCutOption(),
    ];

    if (!isOutOfOfficeEvent(event) || !isGoogleEvent(event)) {
      editEventOptions.push(
        {
          key: "markEventAsFree",
          title: "Mark event as free",
          onClickHandler: () =>
            Broadcast.publish(BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY, FREE_DURING_EVENT),
          searchQueries: "mark as free, transparent, open",
        },
        {
          key: "markAsBusy",
          title: "Mark event as busy",
          onClickHandler: () =>
            Broadcast.publish(BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY, BUSY_DURING_EVENT),
          searchQueries: "mark as busy, opaque, closed, dns, do not schedule",
        },
      );
    }

    editEventOptions.push(this.instantOpenOption());
    if (shouldShowHiddenEventsOption(event)) {
      editEventOptions.push(this.getHiddenEventsOption());
    }

    let options = [].concat(eventResponse);

    if (getEventLocation(event)) {
      options.push({
        key: "openLocation",
        title: "Open location in Google Maps",
        onClickHandler: () => Broadcast.publish("OPEN_LOCATION_IN_GOOGLE_MAPS"),
        shortcut: "L",
        searchQueries: "location, maps, link",
      });
    }

    if (description?.includes("https://zoom.us")) {
      options.push({
        key: "openZoomLink",
        title: "Open Zoom link",
        onClickHandler: () => Broadcast.publish("OPEN_CONFERENCING_LINK"),
        shortcut: "V",
        searchQueries: "video, conferencing, link, zoom",
      });
    }

    if (getEventConferenceURL(event) && this.isGoogleUser()) {
      options.push({
        key: "openGoogleHangoutsLink",
        title: "Open Google Meet Link",
        onClickHandler: () => Broadcast.publish("OPEN_CONFERENCING_LINK"),
        shortcut: "V",
        searchQueries: "video, conferencing, google, hangout, meet, zoom",
      });
    }

    const {
      currentUser
    } = this.props;
    const {
      allCalendars
    } = this.props.allCalendars;
    if (this.isEditable(event)) {
      options = options.concat(editEventOptions);
      // if (isGoogleEvent(event)) {
      if (this.isGoogleUser()) {
        options = options.concat(this.painterOption());
      }
    }

    options = options.concat({
      shortcut: "Y P",
      key: "duplicateEvent",
      title: "Duplicate event",
      onClickHandler: this.duplicateEvent,
      searchQueries: "duplicate, copy, event",
    });

    const { masterAccount } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const writableCalendars = createWritableCalendarList({
      emailToNameIndex: this.props.emailToNameIndex,
      allCalendars: allCalendars,
      currentUser,
      masterAccount,
      allLoggedInUsers,
    });

    if (shouldShowProposeTime({ event, allCalendars })) {
      options = options.concat(this.getGoogleProposeTimeOption(event));
    } else if (allowOutlookEventProposal(event)) {
      options = options.concat(this.getOutlookProposeTimeOption());
    }

    const outlookForwardingOptions = this.forwardOutlookOptions();
    if (outlookForwardingOptions) {
      options = options.concat(outlookForwardingOptions);
    }

    if (writableCalendars?.length > 0) {
      const eventCalendarId = getEventUserCalendarID(event);
      writableCalendars.forEach((c) => {
        const calendarUserCalendarID = getCalendarUserCalendarID(c.value);
        if (eventCalendarId !== calendarUserCalendarID) {
          options = options.concat({
            key: `CopyTo${calendarUserCalendarID}`,
            title: `Copy to ${truncateString(c.label, 40)}`,
            onClickHandler: () =>
              expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT, calendarUserCalendarID),
            searchQueries: `copy, duplicate, ${getCalendarProviderId(c.value)}, ${c.label
              }`,
          });
        }
      });
    }

    return options.concat(this.getDefaultHomeOptions());
  }

  instantOpenOption() {
    return {
      key: "instantOpen",
      title: "Instant Open",
      onClickHandler: () => Broadcast.publish("INSTANT_OPEN_COMMAND_CENTER"),
      shortcut: `${this.state.activeCommandCentersShortCut} O`,
      searchQueries: "open, description, events, command, links, instant",
    };
  }

  getCreateAvailabilityOptions() {
    const DEFAULT_OPTIONS = [
      {
        key: "showPersonalLinks",
        title: "View Personal Links",
        onClickHandler: () =>
          this.setAvailabilityType(PERSONAL_LINK_SELECTION, false),
        searchQueries: "personal links",
      },
      {
        key: "ShowLinkSlots",
        title: "Select Slots",
        onClickHandler: () =>
          this.setAvailabilityType(SLOTS_AVAILABILITY_SELECTION, false),
        searchQueries: "availability, slots, link, booking",
      },
      {
        key: "showGroupVoteLinks",
        title: "View Group Vote links",
        onClickHandler: () =>
          this.setAvailabilityType(GROUP_VOTE_SELECT_OPTION, false),
        searchQueries:
          "availability, votes, voting, poll, ballot, doodle, link, booking, group, spreadsheet, spread sheet",
      },
      this.userTemplateOption(),
    ];

    let options = [];
    if (isCopySlotsButtonShowing()) {
      options = [
        {
          key: "showPersonalLinks",
          title: "Copy to clipboard",
          onClickHandler: () => Broadcast.publish("COPY_AVAILABILITY_CONTENT"),
          shortcut: `${this.state.activeCommandCentersShortCut} C`,
          searchQueries: "copy slots",
        },
      ];

      if (document.getElementById(SLOTS_STYLE_PICKER_ID)) {
        // has rich text toggle
        options = options.concat([
          {
            key: "CopyLinkOnlySlots",
            title: "Copy only link to clipboard",
            shortcut: `${this.state.activeCommandCentersShortCut} Shift C`,
            onClickHandler: () =>
              AvailabilityBroadcast.publish("COPY_AVAILABILITY_LINK_ONLY"),
            searchQueries: "copy link, slots",
          },
          {
            key: "toggleOptionsDetails",
            title: document.getElementById(HIDE_SHOW_AVAILABILITY_DETAILS)
              ? "Hide invite details"
              : "Personalize invite",
            onClickHandler: () =>
              AvailabilityBroadcast.publish("TOGGLE_AVAILABILITY_DETAILS"),
            searchQueries:
              "availability, slots, text, booking, hide, add, show, remove, invite, personalize",
          },
          {
            key: "setSlotsToNone",
            title: `Set booking link style to "${SLOTS_SELECT_TYPE_PLAIN_TEXT.label}"`,
            onClickHandler: () =>
              Broadcast.publish("UPDATE_SLOTS_STYLE", SLOTS_PLAIN_TEXT),
            searchQueries: "slots, none, plain text, no link, remove link",
          },
          {
            key: "setSlotsToPlainTextURL",
            title: `Set booking link style to "${SLOTS_SELECT_TYPE_TEXT_URL.label}"`,
            onClickHandler: () =>
              Broadcast.publish("UPDATE_SLOTS_STYLE", SLOTS_PLAIN_TEXT_URL),
            searchQueries: "slots, plain text url",
          },
          {
            key: "setSlotsToRichText",
            title: `Set booking link style to "${SLOTS_SELECT_TYPE_HYPER_LINKED.label}"`,
            onClickHandler: () =>
              Broadcast.publish("UPDATE_SLOTS_STYLE", SLOTS_RICH_TEXT),
            searchQueries: "slots, rich text, hyperlinked, url",
          },
          {
            key: "setSlotsToURLOnly",
            title: `Set booking link style to "${SLOTS_LINK_ALONE.label}"`,
            onClickHandler: () =>
              Broadcast.publish("UPDATE_SLOTS_STYLE", SLOTS_LINK_ALONE.value),
            searchQueries: "slots, url only, only url, just url",
          },
        ]);
      }
      options = options.concat(DEFAULT_OPTIONS);
    } else {
      options = options.concat(DEFAULT_OPTIONS);
    }

    if (isSlotBreakDurationToggleShowing()) {
      options = options.concat({
        key: "toggleBreakDuration",
        title: "Toggle combine adjacent Slots",
        onClickHandler: () =>
          AvailabilityBroadcast.publish("TOGGLE_COMBINE_ADJACENT_SLOTS"),
        searchQueries: "break into duration toggle, combine adjacent slots",
      });
    }

    if (
      getPreviouslySelectedSlots(
        this.props.currentTimeZone,
        this.props.currentUser
      )
    ) {
      options = options.concat(this.useLastSelectedSlots());
    }

    if (isSlotsPageOpen()) {
      options = options.concat(this.getUpcomingWeekAvailability());
    }

    if (isSlotsDetailSectionOption()) {
      options = options.concat([
        {
          key: "focusTitle",
          title: "Set event title",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "title"
            ),
          searchQueries: "set, title",
        },
        {
          key: "focusDuration",
          title: "Set event duration",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "duration"
            ),
          searchQueries: "set, duration",
        },
        {
          key: "focusConferencing",
          title: "Set event conferencing",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "conferencing"
            ),
          searchQueries: "set conferencing",
        },

        {
          key: "focusDescription",
          title: "Set event description",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "description"
            ),
          searchQueries: "set description",
        },
        {
          key: "focusLocation",
          title: "Set event location",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "location"
            ),
          searchQueries: "set location",
        },
        {
          key: "focusCalendar",
          title: "Set event calendar",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "calendar"
            ),
          searchQueries: "set calendar",
        },
        {
          key: "focusAttendeeEmail",
          title: "Set event invitee email",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "email"
            ),
          searchQueries: "set invitee email",
        },
        {
          key: "focusAttendeeName",
          title: "Set event invitee name",
          onClickHandler: () =>
            AvailabilityBroadcast.publish("FOCUS_AVAILABILITY_ELEMENT", "name"),
          searchQueries: "set invitee name",
        },
        {
          key: "focusAddAttendees",
          title: "Add event attendees",
          onClickHandler: () =>
            AvailabilityBroadcast.publish(
              "FOCUS_AVAILABILITY_ELEMENT",
              "attendees"
            ),
          searchQueries: "add event attendees",
        },
      ]);
    }

    options = options
      .concat(this.hideAllOtherCalendarOption())
      .concat(this.defaultOptions())
      .concat(this.helpCenterOption())
      .concat(this.openTravelingOptions());

    return options;
  }

  getEditEventOptions() {
    let options = [
      this.userTemplateOption(),
      {
        key: "eventTitle",
        title: "Change event title",
        onClickHandler: () => Broadcast.publish("GO_TO_TITLE"),
        searchQueries: "title, name, summary, change, edit",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_SUMMARY]
        ),
      },
      {
        key: "eventLocation",
        title: "Change location",
        onClickHandler: () => Broadcast.publish("GO_TO_LOCATION"),
        searchQueries:
          "change, event, location, meet, place, maps, update maps",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_LOCATION]
        ),
      },
      {
        key: "eventAttendee",
        title: "Add/remove attendee",
        onClickHandler: () => Broadcast.publish("GO_TO_ATTENDEE_LIST"),
        searchQueries:
          "change, event, remove, attendee, guest, guests, people, contact, add people, remove",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_ATTENDEE]
        ),
      },
      {
        key: "copyAttendees",
        title: "Copy guest emails",
        onClickHandler: this.copyGuestEmails,
        searchQueries: "copy, email, emails, attendees, guest",
      },
      {
        key: "eventFormChangeColor",
        title: "Change event color",
        onClickHandler: () => Broadcast.publish("GO_TO_COLOR"),
        searchQueries: "colors, change, event, colours",
      },
      {
        key: "eventDescription",
        title: "Change description",
        onClickHandler: () => Broadcast.publish("GO_TO_DESCRIPTION"),
        searchQueries:
          "change, event, description, edit, edit notes, notes, update",
        shortcut: replacePlusWithAndAndCapitalize(
          eventFormSectionHotKeysIndex[EVENT_FORM_DESCRIPTION]
        ),
      },
      this.viewShortCutOption(),
      {
        key: "darkMode",
        title: `${this.props.isDarkMode ? "Disable" : "Enable"} dark mode`,
        onClickHandler: this.toggleDarkMode,
        searchQueries:
          "darkmode, dark, mode, light, theme, color, enable, disable, black, white, night, colour",
      },
    ];

    options = options
      .concat(this.hideAllOtherCalendarOption())
      .concat(this.defaultOptions());
    if (isEventFormFindTimeButtonShowing()) {
      options = options.concat({
        key: "eventFormFindFreeTime",
        title: "Find times for meeting",
        onClickHandler: () => {eventFormBroadcast.publish("FIND_TIME_INSIDE_EVENT_FORM")},
        searchQueries: "ai, artificial intelligence, find times slots, openings",
      });
    }

    return options.concat(this.createCommandsForShowAndHideCalendars());
  }

  hideAllOtherCalendarOption() {
    return [
      this.declutterEverythingButPrimaryOption(),
      this.declutterEverythingButMainOption(),
      this.declutterEverythingExceptPrimary(),
    ];
  }

  declutterEverythingExceptPrimary() {
    const { allCalendars } = this.props.allCalendars;
    const allPrimaryCalendarsIDs =
      getAllPrimaryCalendarsIDsFromAllCalendars(allCalendars);

    return {
      key: "showAllPrimaryCalendars",
      title: "Only show primary calendars",
      onClickHandler: () =>
        Broadcast.publish(
          "ONLY_TOGGLE_ON_SELECT_CALENDARS",
          allPrimaryCalendarsIDs
        ),
      searchQueries: "declutter, move, hide, primary calendars",
    };
  }

  declutterEverythingButPrimaryOption() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;

    return {
      key: "hideAllCalendars",
      title: `Declutter - only show ${getUserEmail(this.props.currentUser)}`,
      onClickHandler: () =>
        Broadcast.publish("ONLY_TOGGLE_ON_SELECT_CALENDARS", [
          getUserCalendarIDFromEmail({
            email: getUserEmail(this.props.currentUser),
            allCalendars,
            allLoggedInUsers,
            masterAccount,
          }),
        ]),
      searchQueries:
        "declutter, remove, hide, secondary calendars, all, unselect, show, nuke, clear, focus, clean",
    };
  }

  declutterEverythingButMainOption() {
    const { masterAccount } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    return {
      key: "hideAllNonMainCalendars",
      title: "Declutter - only show upcoming events",
      onClickHandler: () =>
        Broadcast.publish(
          "ONLY_TOGGLE_ON_SELECT_CALENDARS",
          getUpcomingCalendarUserCalendarIDs({ masterAccount, allCalendars, allLoggedInUsers })
        ),
      searchQueries:
        "declutter main, remove, hide, secondary calendars, all, unselect, show, nuke, clear, focus, clean",
    };
  }

  getDefaultHomeOptions() {
    let options = [];
    const { masterAccount } = this.props.masterAccount;

    let stripeBillingOption = {
      key: "stripeCustomerPortal",
      title: "Billing",
      onClickHandler: this.openBillingPortal,
      searchQueries:
        "subscriptions, payments, billing, credit card, invoices, stripe, cancel",
    };
    let selectAvailabilityOption = {
      key: "selectAvailability",
      title: "Select your availability",
      onClickHandler: () => {
        mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
        this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
      },
      shortcut: "A",
      searchQueries:
        "set, select, select availability, set availability, set availabilities, availabilities, coordinate, free, send, copy, paste, drag, slots, when, all, open, periods, ranges",
    };
    let editEventOption = {
      key: "editEvent",
      title: "Edit event",
      onClickHandler: this.goToEditEvent,
      shortcut: "E",
      searchQueries: "change, modify, update",
    };
    let defaultHomeOptions = [
      {
        key: "createEvent",
        title: "Create event",
        onClickHandler: this.createEvent.bind(this),
        shortcut: "C",
        searchQueries: "new, create, send, start, create event",
      },
      {
        key: "flashShortcuts",
        title: "Flash shortcuts",
        onClickHandler: this.flashShortcuts,
        searchQueries: "flash, short, shortcuts",
        shortcut: "F",
      },
      {
        key: "setTimeZone",
        title: "Time Travel",
        onClickHandler: () =>
          Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE"),
        searchQueries:
          "set, time, timezone, timetravel, zones, change, adjust, travel",
        shortcut: "Z",
      },
      ...this.getFeedbackOptions(),
      this.referToVimcalOption(),
      this.settingsModalOption(),
      {
        key: "showPersonalLinks",
        title: "View Personal Links",
        onClickHandler: () => this.setAvailabilityType(PERSONAL_LINK_SELECTION),
        searchQueries: "personal links",
      },
      {
        key: "ShowLinkSlots",
        title: "Select Slots",
        onClickHandler: () =>
          this.setAvailabilityType(SLOTS_AVAILABILITY_SELECTION),
        searchQueries: "availability, slots, link, booking",
      },
      {
        key: "showGroupVoteLinks",
        title: "View Group Vote links",
        onClickHandler: () =>
          this.setAvailabilityType(GROUP_VOTE_SELECT_OPTION),
        searchQueries: "availability, slots, group vote link, spreadsheet, spread sheet",
      },
      this.declutterEverythingButPrimaryOption(),
      this.declutterEverythingButMainOption(),
      this.declutterEverythingExceptPrimary(),
      {
        key: "openContactCommandCenter",
        title: "Meet with",
        onClickHandler: this.openContactCommandCenter,
        searchQueries: "meet, meet with, contact, command, center, open",
        shortcut: `${this.state.activeCommandCentersShortCut} J`,
      },
      {
        key: "templates",
        title: "Use template",
        onClickHandler: () =>
          Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER"),
        shortcut: `${this.state.activeCommandCentersShortCut} ;`,
        searchQueries: "template, use",
      },
      this.viewShortCutOption(),
      {
        key: "addCreateEventTemplate",
        title: "Create event template",
        onClickHandler: this.createTemplate,
        searchQueries: "create, templates, snippet",
      },
      this.testNotificationOption(),
      {
        key: "addCreateTextTemplate",
        title: "Create a Sticky",
        onClickHandler: this.createTextTemplate,
        searchQueries: "create, templates, snippet, text",
      },
      {
        key: "search",
        title: "Search",
        onClickHandler: () => Broadcast.publish("TOGGLE_SEARCH"),
        shortcut: "/",
        searchQueries: "search, find, look up, where, query",
      },
      {
        key: "ShowTemplatesCommandCenterContainer",
        title: "Edit templates",
        onClickHandler: this.showTemplates,
        searchQueries: "show, edit, change, templates, template, update templates",
      },
      this.findTimesAvailabilitySlotsOption(),
      {
        key: "ShowAccount",
        title: "Show accounts",
        onClickHandler: this.showAccounts,
        searchQueries: "show, accounts, calendars, emails, switch",
      },
      {
        key: "CopyOpenZoomPersonalLink",
        title: "Launch and copy Zoom personal link",
        onClickHandler: this.copyAndOpenZoomPersonalLink,
        searchQueries: "zoom, personal link, copy, open, launch",
      },
      {
        key: "CopyZoomPersonalLink",
        title: "Copy Zoom personal link",
        onClickHandler: () => this.copyAndOpenZoomPersonalLink(true),
        searchQueries: "zoom, personal link, copy",
      },
      {
        key: "updateDefaultConferencing",
        title: "Conferencing settings",
        onClickHandler: this.openUpdateZoomModal,
        searchQueries: "conferencing, default, settings",
      },
      {
        key: "updateConferencingSettings",
        title: "Zoom settings",
        onClickHandler: this.openUpdateZoomModal,
        searchQueries: "zoom, conferencing, video, settings",
      },
      {
        key: "updatePhone",
        title: "Phone settings",
        onClickHandler: this.openUpdatePhoneModal,
        searchQueries:
          "phone, update, number, conferencing, settings, whatsapp",
      },
      {
        key: "addCustomConferencing",
        title: "Custom conferencing",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CONFERENCING, conferencingSetting: CONFERENCING_SETTINGS_ID.CUSTOM }),
        searchQueries: `conferencing, default, custom, around.co, settings, ${getCustomConferencingName({
          user: this.props.currentUser,
        }).toLowerCase()}`,
      },
      {
        key: "removeSignature",
        title: `${this.determineRemoveOrAddVimCalSignature()} Vimcal signature`,
        onClickHandler: this.toggleVimCalSignature,
        searchQueries: "signature, vimcal, settings",
      },
      {
        key: "darkMode",
        title: `${this.props.isDarkMode ? "Disable" : "Enable"} dark mode`,
        onClickHandler: this.toggleDarkMode,
        searchQueries:
          "darkmode, dark, mode, light, theme, color, enable, disable, black, white, night, settings",
      },
      {
        key: "matchOSScheme",
        title: "Match OS Color Scheme",
        onClickHandler: this.setMatchOSColorScheme,
        searchQueries:
          "auto, dark mode, light mode, theme, color, match, os, enable, disable, black, white, night, colour, settings",
      },
      this.instantOpenOption(),
      {
        key: "oneDayView",
        title: "Day calendar view",
        shortcut: "D",
        onClickHandler: () => this.setSelectedCalendarView(1),
        searchQueries: "1, one, day, view, agenda",
      },
      {
        key: "fourDayView",
        title: "4-day calendar view",
        shortcut: "4",
        onClickHandler: () => this.setSelectedCalendarView(4),
        searchQueries: "4, four, day, view",
      },
      {
        key: "sevenDayView",
        title: "Week calendar view",
        shortcut: "W",
        onClickHandler: () => this.setSelectedCalendarView(7),
        searchQueries: "7, week, seven, day, view",
      },
      {
        key: "monthView",
        title: "Month calendar view",
        shortcut: "M",
        onClickHandler: () => this.setSelectedCalendarView(BACKEND_MONTH),
        searchQueries: "month, view, calendar",
      },
      {
        key: "rollingSevenDayView",
        title: "Rolling 7-day calendar view",
        shortcut: "7",
        onClickHandler: () => this.setSelectedCalendarView(ROLLING_SEVEN_DAY),
        searchQueries: "7 day, 7day, 7-day, seven, rolling",
      },
      {
        key: "toggleWeekNumbers",
        title: shouldDisplayWeekNumber({ masterAccount })
          ? "Hide week numbers"
          : "Show week numbers",
        onClickHandler: this.toggleWeekNumbers,
        searchQueries: "week numbers, settings",
      },
      this.toggleDimPastEvents(),
      {
        key: "defaultGuestPermissions",
        title: "Update default guest permissions",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
        searchQueries:
          "default guest permissions, edit, settings, update, default",
      },
      {
        key: "loadICSFile",
        title: "Upload ICS file",
        onClickHandler: this.openSelectFile,
        searchQueries: "load, upload, ics, file, create",
      },
      this.openslotsSettingsOption(),
      this.removeEmojiOption(),
      this.mergeEventsOption(),
      this.contactGroupOption(),
      this.calendarSettingsOption(),
      this.teamPlansOption(),
      this.downloadVimcalIOS(),
      this.sendGuestEmail(),
      this.sendRunningLateEmail(),
      ...this.updateUsernameOption(),
      this.defaultPayMethodOption(),
      this.toggleShowDeclinedEvents(),
      this.openAffilateProgram(),
      this.bookPersonalOnboarding(),
      this.splitCalendarView(),
    ];

    const previewEvent = this.getPreviewEvent();
    if (
      !isEmptyObjectOrFalsey(previewEvent) &&
      this.isEditable(previewEvent)
    ) {
      options = options.concat(editEventOption);
    }

    if (!isActionModeCreateAvailability(this.props.actionMode)) {
      options = options.concat(selectAvailabilityOption);
    }

    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      let removeSecondaryCalendar = {
        key: "Remove",
        title: "Remove temporary time zone",
        onClickHandler: this.resetTimeZone,
        searchQueries:
          "remove, label, remove label, timezone, time zone, gutter, secondary",
        shortcut: "ESC",
      };

      options = options.concat(removeSecondaryCalendar);
    }

    options = options.concat(defaultHomeOptions);

    if (this.props.selectedCalendarView === 7) {
      options = options.concat({
        key: "workWeekView",
        title: this.props.shouldOnlyShowWorkWeek
          ? "Show weekends"
          : "Show work days only (hide weekends)",
        onClickHandler: this.toggleWorkWeek,
        searchQueries:
          "toggle, week, work, workweek, weekday, weekend, hide, show, settings",
      });
    }

    if (this.props.isMac) {
      if (isMacIntelSync()) {
        options = options.concat({
          key: "downloadVimcalDesktop",
          title: "Download Vimcal for Mac",
          onClickHandler: this.downloadVimcal,
          searchQueries: "apple, desktop, vimcal, download, mac, intel",
        });
      } else {
        options = options.concat({
          key: "downloadVimcalDesktop_m1",
          title: "Download Vimcal for Mac",
          onClickHandler: this.downloadVimcalAppleM1Chip,
          searchQueries: "apple silicon, desktop, vimcal, download, mac, m1, m2, m3, m4, m5, m6, m7, m8, m9, m10",
        });
      }
      options = options.concat(this.updateMenuBar());
    } else if (isWindows()) {
      options = options.concat({
        key: "downloadVimcalDesktop_pc",
        title: "Download Vimcal for Windows",
        onClickHandler: this.downloadVimcalDesktopPC,
        searchQueries: "desktop, vimcal, download, pc, windows",
      });
    }

    // options = options.concat({
    //   key: "downloadVimcalExtension",
    //   title: "Install Vimcal Chrome Extension",
    //   onClickHandler: this.downloadExtension,
    //   searchQueries: "chrome, vimcal, extension, download",
    // });

    if (isElectron()) {
      options = options.concat({
        key: "openVimcal",
        title: "Open Vimcal",
        onClickHandler: this.openVimcal,
        searchQueries: "open, vimcal, calendar",
      });
    }

    const googleOptions = [
      {
        key: "openGCal",
        title: "Open Google Calendar",
        onClickHandler: () =>
          openGoogleLink(GOOGLE_CALENDAR, this.props.currentUser.email),
        searchQueries: "open, google, calendar, gcal",
      },
      {
        key: "openGmail",
        title: "Open Gmail",
        onClickHandler: () =>
          openGoogleLink(GMAIL, this.props.currentUser.email),
        searchQueries: "open, google, gmail, mail, email",
      },
      {
        key: "openGoogleDrive",
        title: "Open Google Drive",
        onClickHandler: () =>
          openGoogleLink(GOOGLE_DRIVE, this.props.currentUser.email),
        searchQueries: "open, google, drive",
      },
      {
        key: "openGoogleSettings",
        title: "Open Google Calendar settings",
        onClickHandler: () =>
          OpenLink(determineGoogleSettingsLink(this.props.currentUser.email)),
        searchQueries: "google calendar settings",
      },
    ];

    if (isCalendarListEventFormFindTimeButtonShowing()) {
      options = options.concat(this.findTimesCreateEventOption());
    }

    options = options.concat(this.createCommandsForShowAndHideCalendars());

    options = options.concat([
      {
        key: "refresh",
        title: "Refresh Vimcal",
        onClickHandler: this.refreshApp,
        shortcut: `${this.props.isMac ? COMMAND_KEY : PC_CONTROL_KEY} R`,
        searchQueries: "refresh",
      },
      {
        key: "update",
        title: "Update Vimcal",
        onClickHandler: this.refreshApp,
        searchQueries: "update vimcal",
      },
      this.helpCenterOption(),
    ]);

    options = options.concat(googleOptions).concat(this.updatePainterOption());

    if (
      getPreviouslySelectedSlots(
        this.props.currentTimeZone,
        this.props.currentUser
      )
    ) {
      options = options.concat(this.useLastSelectedSlots(true));
    }

    if (stripeCustomerPortalFlag(this.props.currentUser)) {
      options = options.concat(stripeBillingOption);
    }

    options = options.concat(this.anchorTimeZoneOptions());

    if (shouldShowCalendarAudit(masterAccount)) {
      options = options.concat(this.calendarAuditCSVOption());
    }

    if (this.isGoogleUser()) {
      options = options.concat(this.googleCalendarSettingOptions());
    }
    options = options.concat(this.displayCalendarSettingsOption());
    options = options.concat(this.focusMode());
    options = options.concat(this.toggleRightHandSide());

    if (this.isGoogleUser()) {
      options = options.concat(this.googleMeetOptions());
    }

    if (!shouldHideAIFeatures(this.props.currentUser)) {
      options = options.concat(this.openReverseSlotsScheduleOption());
    }
    options = options.concat(this.logoutOption());
    options = options.concat(this.metricsOption());
    options = options.concat(this.openTravelingOptions());

    if (shouldDisplayColorLabel({user: this.props.currentUser})) {
      options = options.concat(this.colorLabelOption());
    }

    if (isUserSSOAdmin(masterAccount)) {
      options = options.concat(this.ssoAdminOption());
    }
    options = options.concat(this.updateInternalMeetingsSetting());
    if (!isDemoAccount(masterAccount)) {
      options = options.concat({
        key: "AddAccount",
        title: "Add account",
        onClickHandler: this.addAccount,
        searchQueries: "add, accounts, calendars, emails, additional",
      });
    }

    if (isGoogleUser(this.props.currentUser)) {
      options = options.concat({
        key: "GrantGroupsPermissions",
        title: "Grant groups permissions",
        onClickHandler: () => {
          const user = this.props.currentUser;
          Broadcast.publish(BROADCAST_VALUES.OPEN_INCREMENTAL_PERMISSIONS_MODAL, {
            modalContent: PERMISSION_MODAL_TYPES.UPDATE_GOOGLE_GROUPS_PERMISSIONS,
            permissionProvider: getUserProvider(user), // Should always be Google
            permissionEmail: getUserEmail(user),
          });
        },
        searchQueries: "grant, groups, permissions",
      });
    }

    return options;
  }

  copyAndOpenZoomPersonalLink(skipOpen = false) {
    const {
      masterAccount
    } = this.props.masterAccount;
    const zoomPersonalLink = getZoomPersonalLink({
      user: this.props.currentUser,
      schedulers: getZoomSchedulers(this.props.zoomSchedulers),
      masterAccount
    });
    if (!zoomPersonalLink) {
      this.openUpdateZoomModal();
      return;
    }

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Your Personal Zoom URL was successfully copied."
    );
    // Copy and then open zoom link
    navigator.clipboard.writeText(zoomPersonalLink).then(
      () => {
        !skipOpen && openConferencingURL(zoomPersonalLink);
      },
      function (error) {
        !skipOpen && openConferencingURL(zoomPersonalLink);
        /* clipboard write failed */
      }
    );
  }

  openNewGoogleMeet() {
    openGoogleLink(NEW_GOOGLE_MEET, this.props.currentUser.email);
  }

  setSelectedCalendarView(view) {
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE, view);
  }

  toggleWorkWeek() {
    const {
      masterAccount
    } = this.props.masterAccount;
    toggleWeekends({masterAccount});
  }

  toggleWeekNumbers() {
    const { masterAccount } = this.props.masterAccount;

    const updatedSettings = {
      week_number: !shouldDisplayWeekNumber({ masterAccount }),
    };
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings
    });
  }

  openBillingPortal() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.BILLING })
  }

  clickLogout() {
    appBroadcast.publish("CLICK_LOG_OUT");
  }

  showAccounts() {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_ACCOUNTS);
  }

  resetTimeZone() {
    if (isOnboardingMode()) {
      Broadcast.publish("SELECT_TIME_ZONE", { timeZone: guessTimeZone() });
    }

    Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
  }

  downloadVimcal() {
    OpenLink(DOWNLOAD_INTEL_DESKTOP_LINK);
  }

  downloadVimcalDesktopPC() {
    OpenLink(DOWNLOAD_PC_DESKTOP_LINK);
  }

  downloadExtension() {
    OpenLink(
      "https://chrome.google.com/webstore/detail/vimcal/akopimcimmdmklcmegcflfidpfegngke?hl=en-US"
    );
  }

  toggleDimPastEvents() {
    const {
      masterAccount
    } = this.props.masterAccount;
    return {
      key: "dimPastEvents",
      title:
        shouldDimPastEvents({ masterAccount })
          ? "Remove dimming of past events"
          : "Dim past events",
      onClickHandler: () => {
        const updatedSettings = {
          dim_past_events: !shouldDimPastEvents({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
      searchQueries:
        "lighten, shade, fade, lighter, brighter, darker, dim, contrast, past events, brightness, older, settings",
    }
  }

  downloadVimcalAppleM1Chip() {
    OpenLink(DOWNLOAD_M1_DESKTOP_LINK);
  }

  addAccount() {
    Broadcast.publish(BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS);
  }

  emailGuests(isRunningLate = false) {
    const previewEvent = this.getPreviewEvent();

    if (!isEmptyObjectOrFalsey(previewEvent)) {
      Broadcast.publish(
        "SHOW_EMAIL_ATTENDEES_MODAL",
        previewEvent,
        isRunningLate
      );
    } else {
      Broadcast.publish("EMAIL_UPCOMING_EVENT", isRunningLate);
    }
  }

  getPreviewEvent() {
    return getPreviewEvent({
      popupEvent: this.props.popupEvent,
      hoverPopupEvent: this.props.hoverPopupEvent,
      currentPreviewedEvent: this.props.currentPreviewedEvent,
    });
  }

  // TODO: add upcoming email event

  openContactCommandCenter() {
    Broadcast.publish("TURN_ON_CONTACT_COMMAND_CENTER");
  }

  copyGuestEmails() {
    Broadcast.publish("COPY_GUEST_EMAILS");
  }

  determineRemoveOrAddVimCalSignature() {
    const { masterAccount } = this.props.masterAccount;
    return shouldHideDefaultSignature({ masterAccount }) ? "Add" : "Remove";
  }

  toggleVimCalSignature() {
    const { masterAccount } = this.props.masterAccount;
    const updatedSettings = {
      hide_default_signature: !shouldHideDefaultSignature({
        masterAccount,
      }),
    };
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings,
    });
  }

  referToVimcal() {
    trackReferral({
      user: this.props.currentUser,
      action: "opened_referral - command center",
    });
    Broadcast.publish("SHOW_REFER_TO_VIMCAL_MODAL");
  }

  updatePainterOption() {
    return {
      key: "updateColorSetting",
      title: "Update Tag settings",
      onClickHandler: this.toggleColorSetting,
      searchQueries: "colors, painter, settings, setting, update, colours, tags, smart tags, auto tags",
    }
  }

  sendFeedback() {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK);
  }

  showTemplates() {
    Broadcast.publish("TOGGLE_SHOW_TEMPLATE");
  }

  duplicateEvent() {
    expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT);
  }

  setMatchOSColorScheme() {
    Broadcast.publish("SET_COLOR_SCHEME_BASED_ON_OS");
    Broadcast.publish("SET_COLOR_SCHEME_ON_OS_BACKEND");
  }

  toggleDarkMode() {
    const currentIsDarkMode = this.props.isDarkMode;

    if (currentIsDarkMode) {
      Broadcast.publish("DISABLE_DARK_MODE");
    } else {
      Broadcast.publish("ENABLE_DARK_MODE");
    }
  }

  changeEventColor() {
    this.goToEventFormSection(EVENT_FORM_COLOR);
  }

  goToEventFormSection(section) {
    Broadcast.publish("GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED", section);
  }

  viewAllShortcuts() {
    this.props.displayShortcutsLegend();
  }

  testNotifications() {
    // macOS sometiems does not pick up notification and needs us to actually send a notification
    // https://www.howtogeek.com/348935/not-getting-macos-notifications-here’s-how-to-fix-it-without-rebooting/
    const TITLE = "Notification enabled for Vimcal";
    if (isElectron() && isMac() && window?.vimcal?.setNotification) {
      const notificationBody = {
        title: TITLE,
      };

      window.vimcal.setNotification(notificationBody);
    } else {
      Push.create(TITLE, {
        icon: process.env.PUBLIC_URL + "/logo192.png",
        onClick: () => this.onClickEnabledNotification(),
      });
    }
  }

  createCommandsForShowAndHideCalendars() {
    let calendarCommands = [];
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    if (isEmptyObjectOrFalsey(allCalendars)) {
      return [];
    }

    const duplicateNames = getDuplicateCalendarNames(
      Object.values(allCalendars),
      this.props.emailToNameIndex
    );

    Object.keys(allCalendars).forEach((c, index) => {
      const calendar = allCalendars[c];
      if (!calendar?.calendar) {
        return;
      }
      if (shouldHideDelegatedUserCalendar({
        calendar,
        allCalendars,
        allLoggedInUsers,
        masterAccount,
      })
      ) {
        return;
      }

      const id = getCalendarUserCalendarID(calendar);

      const name = getCalendarName({
        calendar,
        emailToNameIndex: this.props.emailToNameIndex,
        currentUser: this.props.currentUser,
        masterAccount,
      });
      const email = getCalendarProviderId(calendar);

      let displayString = name;
      if (duplicateNames.includes(name) && name !== email) {
        displayString = `${name} (${email})`;
      }

      displayString = truncateString(displayString, 55);

      calendarCommands = calendarCommands.concat({
        key: id,
        title: `${isCalendarSelected(calendar)
          ? "Hide calendar: "
          : "Show calendar: "
          } ${displayString}`,
        onClickHandler: () =>
          Broadcast.publish("TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID", id),
        searchQueries:
          (name ? PutCommaBetweenWordInString(name) : "") +
          `, calendars, show, hide, remove, ${email}`,
      });
    });

    return calendarCommands;
  }

  goToEditEvent() {
    expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.EDIT_EXPANDED_EVENT);
  }

  createEvent() {
    Broadcast.publish("CREATE_EVENT_HANDLER");
  }

  openUpdateZoomModal() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CONFERENCING, conferencingSetting: CONFERENCING_SETTINGS_ID.ZOOM });
  }

  openUpdatePhoneModal() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CONFERENCING, conferencingSetting: CONFERENCING_SETTINGS_ID.PHONE });
  }

  toggleColorSetting() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PAINTER_SETINGS });
  }

  openVimcal() {
    OpenLink(getHomeLink());
  }

  createTemplate() {
    Broadcast.publish("CREATE_TEMPLATE", EVENT_TEMPLATE);
  }

  createTextTemplate() {
    Broadcast.publish("CREATE_TEMPLATE", TEXT_TEMPLATE);
  }

  setAvailabilityType(type, shouldTurnOnAvailability = true) {
    if (shouldTurnOnAvailability) {
      this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    }

    Broadcast.publish("SET_AVAILABILITY_TYPE", type);
  }

  refreshApp() {
    triggerRefreshWithOnlineCheck();
  }

  openPainter() {
    Broadcast.publish("TURN_ON_COLOR_COMMAND_CENTER");
  }

  onClickEnabledNotification() {
    if (isElectron() && window?.vimcal?.focusWindow) {
      window.vimcal.focusWindow();
    } else if (window?.focus){
      window.focus();
    }
  }

  defaultOptions() {
    let options = [
      {
        key: "flashShortcuts",
        title: "Flash shortcuts",
        onClickHandler: this.flashShortcuts,
        searchQueries: "flash, short, shortcuts",
        shortcut: `F`,
      },
      {
        key: "setTimeZone",
        title: "Time Travel",
        onClickHandler: () =>
          Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE"),
        searchQueries:
          "set, time, timezone, timetravel, zones, change, adjust, travel",
        shortcut: `Z`,
      },
      {
        key: "openContactCommandCenter",
        title: "Meet with",
        onClickHandler: this.openContactCommandCenter,
        searchQueries: "meet, meet with, contact, command, center, open",
        shortcut: `${this.state.activeCommandCentersShortCut} J`,
      },
      this.viewShortCutOption(),
      ...this.getFeedbackOptions(),
      {
        key: "ShowAccount",
        title: "Show accounts",
        onClickHandler: this.showAccounts,
        searchQueries: "show, accounts, calendars, emails, switch",
      },
      {
        key: "CopyOpenZoomPersonalLink",
        title: "Launch and copy Zoom personal link",
        onClickHandler: this.copyAndOpenZoomPersonalLink,
        searchQueries: "zoom, personal link, copy, open, launch",
      },
      {
        key: "CopyZoomPersonalLink",
        title: "Copy Zoom personal link",
        onClickHandler: () => this.copyAndOpenZoomPersonalLink(true),
        searchQueries: "zoom, personal link, copy",
      },
      this.declutterEverythingButPrimaryOption(),
      this.declutterEverythingButMainOption(),
      this.declutterEverythingExceptPrimary(),
      this.testNotificationOption(),
      {
        key: "openNewGoogleMeetLink",
        title: "Launch new Google Meet",
        onClickHandler: this.openNewGoogleMeet,
        searchQueries: "link, open, google meet, new, hangout, launch",
      },
      {
        key: "logOut",
        title: "Log out",
        onClickHandler: this.clickLogout.bind(this),
        searchQueries: "log out, sign out, authenticate, logout",
      },
      {
        key: "darkMode",
        title: `${this.props.isDarkMode ? "Disable" : "Enable"} dark mode`,
        onClickHandler: this.toggleDarkMode,
        searchQueries:
          "darkmode, dark, mode, light, theme, color, enable, disable, black, white, night",
      },
      {
        key: "matchOSScheme",
        title: "Match OS Color Scheme",
        onClickHandler: this.setMatchOSColorScheme,
        searchQueries:
          "auto, dark mode, light mode, theme, color, match, os, enable, disable, black, white, night, colour",
      },
      {
        key: "oneDayView",
        title: "Day calendar view",
        shortcut: "D",
        onClickHandler: () => this.setSelectedCalendarView(1),
        searchQueries: "1, one, day, view, agenda",
      },
      {
        key: "fourDayView",
        title: "4-day calendar view",
        shortcut: "4",
        onClickHandler: () => this.setSelectedCalendarView(4),
        searchQueries: "4, four, day, view",
      },
      {
        key: "sevenDayView",
        title: "Week calendar view",
        shortcut: "W",
        onClickHandler: () => this.setSelectedCalendarView(7),
        searchQueries: "7, week, seven, day, view",
      },
      {
        key: "monthView",
        title: "Month calendar view",
        shortcut: "M",
        onClickHandler: () => this.setSelectedCalendarView(BACKEND_MONTH),
        searchQueries: "month, view, calendar",
      },
      {
        key: "rollingSevenDayView",
        title: "Rolling 7-day calendar view",
        shortcut: "7",
        onClickHandler: () => this.setSelectedCalendarView(ROLLING_SEVEN_DAY),
        searchQueries: "7 day, 7day, 7-day, seven, rolling",
      },
      this.toggleDimPastEvents(),
      this.removeEmojiOption(),
      this.mergeEventsOption()
    ];
    options = options.concat(this.createCommandsForShowAndHideCalendars());
    options = options.concat(this.anchorTimeZoneOptions());
    return options;
  }

  mergeEventsOption() {
    const {
      masterAccount
    } = this.props.masterAccount;
    return {
      key: "mergeEvents",
      title: shouldMergeEvents({ masterAccount })
        ? "Disable auto-merge for duplicate events"
        : "Enable auto-merge for duplicate events",
      onClickHandler: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.AUTO_MERGE_EVENTS]: !shouldMergeEvents({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
      searchQueries:
        "merge, unmerge, events, same, matching, similar, color, merge, settings",
    };
  }

  useLastSelectedSlots(toggleOnAvailability = false) {
    return {
      key: "setLastSelectedSlotsAndTimeZone",
      title: "Use previously selected Slots",
      onClickHandler: () => {
        if (toggleOnAvailability) {
          this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
        }

        AvailabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE);
      },
      searchQueries: "previously, selected, slots, last",
    };
  }

  getUpcomingWeekAvailability() {
    return {
      key: "getUpcomingWeekAvailability",
      title: "Get upcoming week availability",
      onClickHandler: () => {
        AvailabilityBroadcast.publish("GET_UPCOMING_WEEK_AVAILABILITY");
      },
      searchQueries: "previously, selected, slots, last, find times for everyone, Get upcoming week availability",
    };
  }

  openSelectFile() {
    let element = document.getElementById("ics-file-select");
    if (!element) {
      return;
    }

    element.click();
    element = null;
  }

  removeEmojiOption() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      currentUser
    } = this.props;
    return {
      key: "auto_add_emoji",
      title: shouldAutoAddEmoji({ masterAccount, user: currentUser })
        ? "Disable automatically adding emojis to events"
        : "Enable automatically adding emojis to events",
      onClickHandler: () => {
        const updatedSettings = {
          auto_emojis: !shouldAutoAddEmoji({ masterAccount, user: currentUser }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
          user: currentUser,
        });
      },
      searchQueries: "emoji, setting, automatically adding, settings",
    };
  }

  anchorTimeZoneOptions() {
    const addAnchorTimeZoneOptions = {
      key: "add_time_zone",
      title: "Update anchor time zones",
      onClickHandler: () => layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
      searchQueries: "time zone, add, anchor, new, extra, settings, permanent, local time zone",
    };

    if (!this.props.anchorTimeZones || this.props.anchorTimeZones?.length < 1) {
      return addAnchorTimeZoneOptions;
    }

    const removeOptions = [
      {
        key: "REMOVE_ALL_ANCHOR_TIME_ZONES",
        title: "Remove all anchor time zones",
        onClickHandler: () => Broadcast.publish("REMOVE_ALL_ANCHOR_TIME_ZONES"),
        searchQueries: "clear, remove, time zone, anchor, all",
      },
    ];

    return [addAnchorTimeZoneOptions].concat(removeOptions);
  }

  contactGroupOption() {
    return {
      key: "Contact_nick_name_option",
      title: "Update contact groups",
      onClickHandler: () => layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CONTACT_GROUPS }),
      searchQueries:
        "assign, update, create, contact group nicknames, settings",
    };
  }

  calendarSettingsOption() {
    return {
      key: "open_calendar_settings",
      title: "Update calendar settings",
      onClickHandler: () => layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CALENDAR_SETTINGS }),
      searchQueries:
        "calendar settings, share permissions, main calendars, update, primary calendar, multiple calendars, settings",
    };
  }

  googleCalendarSettingOptions() {
    return [
      {
        key: "google_notification_setting",
        title: "Update notification time",
        onClickHandler: () =>
          Broadcast.publish("OPEN_GOOGLE_CALENDAR_SETTINGS_MODAL"),
        searchQueries: "notification, settings",
      }
    ];
  }

  displayCalendarSettingsOption() {
    return [
      {
        key: "google_declined_events_setting",
        title: "Update show/hide declined events",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
        searchQueries: "show declined events settings",
      },
      {
        key: "google_update_week_start_setting",
        title: "Update start of the week",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
        searchQueries: "week start settings, start of week",
      },
      {
        key: "google_date_format_setting",
        title: "Update date format",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
        searchQueries: "date format settings",
      },
      {
        key: "google_time_format_setting",
        title: "Update time format",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES }),
        searchQueries: "time format settings",
      },
    ];
  }

  colorLabelOption() {
    const goToColorLabel = () => {
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
        initialContent: APP_SETTINGS.PREFERENCES,
        scrollToSettingContent: APP_SETTINGS.COLOR_LABEL,
      });
    };
    return {
      key: "colorLabels",
      title: "Update color labels",
      onClickHandler: goToColorLabel,
      searchQueries: "color label, nickname",
    };
  }

  teamPlansOption() {
    const goToTeamPlansPage = () => {
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.TEAM });
    };
    return {
      key: "teamPlans",
      title: "View team plan",
      onClickHandler: goToTeamPlansPage,
      searchQueries: "team plans, teams",
    };
  }

  downloadVimcalIOS() {
    const downloadIOS = () => {
      Broadcast.publish("OPEN_DOWNLOAD_IOS_MODAL");
    };
    return {
      key: "vimcalIOS",
      title: "Download Vimcal for iOS",
      onClickHandler: downloadIOS,
      searchQueries: "ios, mobile, iphone, download",
    };
  }

  viewShortCutOption() {
    return {
      key: "showShortcutsLegend",
      title: "View all shortcuts",
      onClickHandler: this.viewAllShortcuts,
      shortcut: `?`,
      searchQueries: "shortcuts, view, shortcut, help, legend",
    };
  }

  sendGuestEmail() {
    return {
      key: "emailGuests",
      title: "Email guests",
      onClickHandler: () => this.emailGuests(),
      searchQueries:
        "email, guests, message, mail, note, attendees, people, notify",
      shortcut: replacePlusWithAndAndCapitalize("g+e"),
    };
  }

  sendRunningLateEmail() {
    return {
      key: "runningLate",
      title: "Send running late email",
      onClickHandler: () => this.emailGuests(true),
      searchQueries:
        "email, guests, guests, message, mail, note, running, late, attendees, people, notify",
    };
  }

  helpCenterOption() {
    return {
      key: "help_center_option",
      title: "Open Help Center",
      onClickHandler: () => Broadcast.publish("OPEN_HELP_CENTER"),
      searchQueries: "open, help center, page, resources",
    };
  }

  userTemplateOption() {
    return {
      key: "templates",
      title: "Use template",
      onClickHandler: () =>
        Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER"),
      shortcut: `${this.state.activeCommandCentersShortCut} ;`,
      searchQueries: "template, use",
    };
  }

  referToVimcalOption() {
    return {
      key: "referVimcal",
      title: "Refer to Vimcal",
      onClickHandler: this.referToVimcal,
      searchQueries:
        "refer, vimcal, refer to, recommend, email, send, invite, resources",
    };
  }

  updateMenuBar() {
    return {
      key: "menuBarSettings",
      title: "Update notifications, upcoming section, and menu bar events",
      onClickHandler: () => layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.MENU_BAR }),
      searchQueries: "menu bar, change events, upcoming, agenda, next, notifications",
    };
  }

  bookPersonalOnboarding() {
    return {
      key: "book_personal_onboarding",
      title: "Book personal onboarding",
      onClickHandler: () => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_ONBOARDING_MODAL),
      searchQueries: "schedule, book personal onboarding, help, resources",
    };
  }

  testNotificationOption() {
    return {
      key: "TestNotification",
      title: "Test notification",
      onClickHandler: this.testNotifications,
      searchQueries:
        "notification, enable, disable, toggle, alert, reminder, settings, test",
    };
  }

  focusMode() {
    const toggleFocusModeOption = {
      key: "focusMode",
      title: "Focus mode",
      shortcut: FOCUS_MODE_HOTKEY,
      onClickHandler: () => {
        layoutBroadcast.publish("TOGGLE_FOCUS_MODE", true);
      },
      searchQueries: "Focus mode, quiet, distraction",
    };
    let focusModeOptions = [toggleFocusModeOption];
    if (isFocusModeCountingDown()) {
      focusModeOptions = focusModeOptions.concat([
        {
          key: "pauseFocusMode",
          title: "Pause Focus mode timer",
          onClickHandler: () => {
            focusModeBroadcast.publish("PAUSE_FOCUS_MODE_TIMER");
          },
          searchQueries: "Focus mode, pause timer",
        },
        this.stopFocusModeTimerOption()
      ])
    } else if (isFocusModePaused()) {
      focusModeOptions = focusModeOptions.concat([
        {
          key: "resumeFocusMode",
          title: "Resume Focus mode timer",
          onClickHandler: () => {
            focusModeBroadcast.publish("RESUME_FOCUS_MODE_TIMER");
          },
          searchQueries: "Focus mode, resume timer, start, play",
        },
        this.stopFocusModeTimerOption()
      ]);
    }

    if (isFocusModePlayingMusic()) {
      focusModeOptions = focusModeOptions.concat({
        key: "stopPlayingFocusModeMusic",
        title: "Pause Focus mode music",
        onClickHandler: () => {
          focusModeBroadcast.publish("PAUSE_FOCUS_MODE_MUSIC");
        },
        searchQueries: "Focus mode, music, sound, stop, pause",
      });
    }

    return focusModeOptions;
  }

  googleMeetOptions() {
    return [
      {
        key: "openNewGoogleMeetLink",
        title: "Launch new Google Meet",
        onClickHandler: this.openNewGoogleMeet,
        searchQueries: "link, open, google meet, new, hangout, launch",
      },
      {
        key: "updateGoogleMeet",
        title: "Google Meet settings",
        onClickHandler: () =>
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.CONFERENCING, conferencingSetting: CONFERENCING_SETTINGS_ID.GOOGLE }),
        searchQueries: "Google meet, conferencing, settings",
      },
    ]
  }

  isGoogleUser() {
    return isGoogleUser(this.props.currentUser);
  }

  stopFocusModeTimerOption() {
    return {
      key: "stopFocusMode",
      title: "Stop Focus mode timer",
      onClickHandler: () => {
        focusModeBroadcast.publish("STOP_FOCUS_MODE_TIMER");
      },
      searchQueries: "Focus mode, stop timer, remove",
    };
  }

  createFocusModeBlock() {
    return [
      {
        key: "createFocusModeBlock",
        title: "Create Focus mode blocks",
        onClickHandler: () => {
          this.props.setCreateFocusModeBlock(true);
        },
        searchQueries: "create, block, Focus mode, quiet, distraction, time",
      },
    ];
  }

  painterOption() {
    return {
      key: "eventPainter",
      title: "Color label",
      onClickHandler: this.openPainter,
      searchQueries: "colors, paint, painter, label, change, colours label",
      shortcut: "P",
    };
  }

  settingsModalOption() {
    return {
      key: "settingsModal",
      title: "Go to settings",
      onClickHandler: () => { layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL) },
      searchQueries: "settings, panel, modal, change",
    }
  }

  toggleRightHandSide() {
    const { setHideRightHandSide } = this.props.hideRightHandSidebar;
    const shouldTruncateRightPanel = shouldTruncateRightHandPanel(
      this.props.hideRightHandSidebar
    );

    return [
      {
        key: "hideRightHandSide",
        title: `${shouldTruncateRightPanel ? "Show" : "Hide"} right hand panel`,
        onClickHandler: () => {
          setHideRightHandSide(!shouldTruncateRightPanel);
        },
        shortcut: TOGGLE_RIGHT_PANEL_HOTKEY,
        searchQueries: "hide, right, monthly calendar, agenda, panel, truncate, toggle, full screen",
      },
    ];
  }

  updateUsernameOption() {
    return [
      {
        key: "personalizeBookingLink",
        title: "Personalize booking link",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PROFILE });
        },
        searchQueries: "personalize booking link personalize personal links update link url",
      },
      {
        key: "personalizeAccountName",
        title: "Update account name",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PROFILE });
        },
        searchQueries: "Update account name full name first name last name",
      },
      {
        key: "updateSocial",
        title: "Update social handles",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PROFILE });
        },
        searchQueries: "update social, linkedin, github, website, twitter, x, telegram, youtube",
      },
      {
        key: "updateProfile",
        title: "Update profile",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PROFILE });
        },
        searchQueries: "profile, update account name full name first name last name",
      },
    ];
  }

  defaultPayMethodOption() {
    return {
      key: "defaultPayMethod",
      title: "Preferred payment method",
      onClickHandler: () => {
        layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.BILLING, skipBillingPromotion: true });
      },
      searchQueries: "Preferred payment method",
    };
  }

  metricsOption() {
    return [
      {
        key: "showMetrics",
        title: "Show metrics",
        onClickHandler: () => {
          trackMetricsOpen({
            where: "command center",
            user: this.props.currentUser,
          });
          layoutBroadcast.publish("SHOW_METRICS_MODAL");
        },
        searchQueries: "metrics, data, my week, analytics, audit",
      }
    ];
  }

  openReverseSlotsScheduleOption() {
    return [
      {
        key: "ai_scheduler",
        title: "Free Time Finder",
        onClickHandler: () => {
          layoutBroadcast.publish("UPLOAD_AI_SCHEDULER");
        },
        searchQueries: "ai, artificial intelligence, scheduler, reverse slots, free time finder, calendly",
      }
    ];
  }

  getOutlookProposeTimeOption() {
    return [
      {
        key: "tenativeProposeTime",
        title: "Tentative and propose new time",
        onClickHandler: () => {
          expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME);
        },
        searchQueries: "outlook Propose new time, suggest new time",
      },
      {
        key: "declineProposeTime",
        title: "Decline and propose new time",
        onClickHandler: () => {
          expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME);
        },
        searchQueries: "outlook Decline and propose new time",
      },
    ];
  }

  getGoogleProposeTimeOption(event) {
    return [
      {
        key: "proposeTime",
        title: "Propose time",
        onClickHandler: () => {
          onClickGoogleProposeTime({ event });
        },
        searchQueries: "Propose time, suggest new time",
      },
    ];
  }

  isEditable(previewEvent) {
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      allCalendars
    } = this.props.allCalendars;
    if (isEmptyObjectOrFalsey(previewEvent)) {
      return false;
    }

    return isEditable({
      event: previewEvent,
      allCalendars,
      actionMode,
      reverseSlotsText,
    });
  }

  openTravelingOptions() {
    return [
      {
        key: "viewMyTrips",
        title: "View my trips",
        onClickHandler: async () => {
          if (!isActionModeCreateAvailability(this.props.actionMode)) {
            await this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
          }
          Broadcast.publish("SET_AVAILABILITY_TYPE", PERSONAL_LINK_SELECTION);
          availabilityBroadcast.publish("OPEN_TRAVELING_MODAL");
        },
        searchQueries: "trips, vacation, flight, travel",
      },
      {
        key: "createATrip",
        title: "Create a trip",
        onClickHandler: async () => {
          if (!isActionModeCreateAvailability(this.props.actionMode)) {
            await this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
          }
          Broadcast.publish("SET_AVAILABILITY_TYPE", PERSONAL_LINK_SELECTION);
          availabilityBroadcast.publish("OPEN_TRAVELING_MODAL");
          await delayByMs(0.1 * SECOND_IN_MS);
          availabilityBroadcast.publish("CREATE_A_TRIP");
        },
        searchQueries: "trips, vacation, flight, travel, add",
      }
    ];
  }

  toggleShowDeclinedEvents() {
    const {
      masterAccount
    } = this.props.masterAccount;
    return {
      key: "toggleShowDeclinedEvents",
      title: showAccountDeclinedEvents({ masterAccount }) ? "Hide declined events" : "Show declined events",
      onClickHandler: () => {
        toggleShowDeclinedEvents({ masterAccount });
      },
      searchQueries: "show hide declined events",
    };
  }

  openslotsSettingsOption() {
    return {
      key: "availabilitySettings",
      title: "Open Slots settings",
      onClickHandler: () =>
        layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.SLOTS }),
      searchQueries: "open availability default settings, slots",
    };
  }

  forwardOutlookOptions() {
    const event = this.getPreviewEvent();
    if (!isOutlookEventForwardable({event})) {
      return null;
    }

    const forwardThisEvent = {
      key: "forwardThisEvent",
      title: "Forward this event",
      onClickHandler: () =>
        layoutBroadcast.publish("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", {event}),
      searchQueries: "outlook, forward this event",
    };

    const originalRecurringEvent = getOriginalRecurringEventFromIndex(
      event,
      this.props.originalRecurrenceEventIndex
    )
    if (getEventMasterEventID(event) && originalRecurringEvent) {
      return [
        forwardThisEvent,
        {
          key: "forwardAllEventsInTheSeries",
          title: "Forward all events in the series",
          onClickHandler: () =>
            layoutBroadcast.publish("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", {event, originalRecurringEvent}),
          searchQueries: "outlook, Forward all events in the series",
        }
      ];
    }

    return [
      forwardThisEvent
    ];
  }

  logoutOption() {
    const {
      allLoggedInUsers,
      full_name
    } = this.props.allLoggedInUsers;
    const logoutAll = [{
      key: "logOut",
      title: "Log out",
      onClickHandler: this.clickLogout.bind(this),
      searchQueries: "log out, sign out, authenticate, logout, disconnect",
    }];
    const disconnectAllLoggedInAccount = allLoggedInUsers.map(user => {
      const {
        email
      } = user;
      return {
        key: `disconnect_${email}}`,
        title: `Disconnect ${email}`,
        onClickHandler: () => {
          backendBroadcasts.publish("LOGOUT_OF_SINGLE_ACCOUNT", {user});
        },
        searchQueries: `log out, ${email}${full_name ? `, ${full_name}` : ""}, disconnect, logout`,
      }
    });
    if (disconnectAllLoggedInAccount.length === 1) {
      return logoutAll;
    }

    return logoutAll.concat(disconnectAllLoggedInAccount);
  }

  findTimesAvailabilitySlotsOption() {
    return {
      key: "slotsFindTimesAvailability",
      title: isCalendarListEventFormFindTimeButtonShowing() ? "Find times for availability" : "Find times",
      onClickHandler: () => availabilityBroadcast.publish("GET_AVAILABLE_SLOTS_FIND_TIME"),
      searchQueries:
        "ai, artificial intelligence, find times for slots",
    };
  }

  findTimesCreateEventOption() {
    return {
      key: "slotsFindTimesCreateEvent",
      title: "Find times for meeting",
      onClickHandler: () => eventFormBroadcast.publish("FIND_TIME_CREATE_EVENT"),
      searchQueries:
        "ai, artificial intelligence, find times create event",
    };
  }

  getFeedbackOptions() {
    return [
      {
        key: "askQuestion",
        title: "Ask a question",
        onClickHandler: () => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.QUESTION),
        searchQueries:
          "submit, send, share, question, support, aloha, resources, ?",
      },
      {
        key: "sendFeedback",
        title: "Share feedback",
        onClickHandler: () => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.FEEDBACK),
        searchQueries:
          "submit, send, share, feedback, support, aloha, resources",
      },
      {
        key: "sendBugReport",
        title: "Send bug report",
        onClickHandler: () => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.BUG_REPORT),
        searchQueries:
          "submit, send, share, broken, fix, bug, support, aloha, resources",
      },
      {
        key: "sendFeatureRequest",
        title: "Send feature request",
        onClickHandler: () => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.FEATURE_REQUEST),
        searchQueries:
          "submit, send, share, request, feature, want, would like, support, aloha, resources",
      },
    ]
  }

  openAffilateProgram() {
    return {
      key: "affiliateProgram",
      title: "Join our affiliate program",
      onClickHandler: () => {
        layoutBroadcast.publish("OPEN_AFFILIATE_MODAL");
      },
      searchQueries:
        "affiliate, partner, join",
    };
  }

  addGoogleRSVPCommentOption() {
    return [
      {
        key: "addNote",
        title: "Add note",
        onClickHandler: () => {
          expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.ADD_GOOGLE_RSVP_COMMENT);
        },
        searchQueries:
          "comment, note, rsvp",
      },
    ];
  }

  ssoAdminOption() {
    return [
      {
        key: "advanced settings",
        title: "Open advanced settings",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.ADVANCED });
        },
        searchQueries:
          "advanced settings",
      },
    ];
  }

  updateInternalMeetingsSetting() {
    const {
      currentUser
    } = this.props;
    return [
      {
        key: "internalMeetingsSetting",
        title: "Update internal meetings settings",
        onClickHandler: () => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
            initialContent: APP_SETTINGS.PREFERENCES,
            scrollToSettingContent: PREFERENCES_SETTINGS_ID.INTERNAL_DOMAINS,
            initialSettingsUser: currentUser,
            initialSlotsSettingsUser: currentUser,
          });
        },
        searchQueries:
          "internalMeetings",
      },
    ];
  }

  splitCalendarView() {
    const {
      isSplitView,
      setIsSplitView,
    } = this.props.appSettings;
    return {
      key: "splitCalendar",
      title: `${isSplitView ? "Unsplit " : "Split "} view`,
      onClickHandler: () => {
        setIsSplitView(!isSplitView);
      },
      searchQueries:
        "split calendar view separate",
    };
  }

  getHiddenEventsOption() {
    const event = this.getPreviewEvent();
    const isEventCurrentlyHidden = isEventHiddenEvent(event);
    return {
      key: "toggleHiddenEvent",
      title: isEventCurrentlyHidden ? "Show hidden event" : "Hide event",
      onClickHandler: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TOGGLE_HIDE_EVENT),
      searchQueries:
        "toggle hidden event, hide event, show event",
    };
  }

  toggleCalendarAuditCSV() {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_CALENDAR_AUDIT);
  }

  calendarAuditCSVOption() {
    return {
      key: "calendarAuditCSV",
      title: "Executive Calendar Audit",
      onClickHandler: this.toggleCalendarAuditCSV,
      searchQueries: "calendar, audit, csv",
    };
  }
}

function mapStateToProps(state) {
  let {
    isMac,
    currentHoverEvent,
    defaultBrowserTimeZone,
    activeCalendars,
    currentUser,
    currentPreviewedEvent,
    currentTimeZone,
    currentTimeZoneLabel,
    isDarkMode,
    weekStart,
    shouldOnlyShowWorkWeek,
    selectedCalendarView,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    popupEvent,
    hoverPopupEvent,
    originalRecurrenceEventIndex,
    eventFormEmails,
    actionMode,
  } = state;

  return {
    isMac,
    currentHoverEvent,
    defaultBrowserTimeZone,
    activeCalendars,
    currentUser,
    currentPreviewedEvent,
    currentTimeZone,
    currentTimeZoneLabel,
    isDarkMode,
    weekStart,
    shouldOnlyShowWorkWeek,
    selectedCalendarView,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    popupEvent,
    hoverPopupEvent,
    originalRecurrenceEventIndex,
    eventFormEmails,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
    setWeeklyCalendarToZoom: (day) =>
      dispatch({ data: day, type: "SET_WEEKLY_CALENDAR_TO_ZOOM" }),
    removeCurrentHoverEvent: (data) =>
      dispatch({ data: data, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    toggleGlobalKeyMap: () => dispatch({ type: "TOGGLE_SHOW_GLOBAL_KEY_MAP" }),
    displayShortcutsLegend: () =>
      dispatch({ type: "DISPLAY_SHORTCUTS_LEGEND" }),
    setCreateFocusModeBlock: (data) =>
      dispatch({ data: data, type: "SET_CREATE_FOCUS_MODE_BLOCKS" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const zoomSchedulers = useZoomSchedulers();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const temporaryStateStore = useTemporaryStateStore();
  const permissionsStore = usePermissionsStore();
  const allLoggedInUsers = useAllLoggedInUsers();
  const metricsStore = useMetricsStore();
  const featureFlags = useFeatureFlags();
  const appSettings = useAppSettings();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      zoomSchedulers={zoomSchedulers}
      hideRightHandSidebar={hideRightHandSidebar}
      temporaryStateStore={temporaryStateStore}
      permissionsStore={permissionsStore}
      allLoggedInUsers={allLoggedInUsers}
      metricsStore={metricsStore}
      featureFlags={featureFlags}
      appSettings={appSettings}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(CommandCenterContainer)));
