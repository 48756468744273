import React from "react";
import DefaultSwitch from "../../defaultSwitch";
import RadioButton from "../../radioButton";
import CheckBox from "../../checkbox";

interface SectionProps {
  children: React.ReactNode
  title: string
}

export function Section({ children, title }: SectionProps) {
  return (
    <div className="p-5">
      <div className="font-size-14 font-medium mb-4">
        {title}
      </div>
      <div className="flex flex-col gap-4">
        {children}
      </div>
    </div>
  );
}

interface SettingProps {
  additionalChildren?: React.ReactNode
  label: string
  helperText?: string
  id?: string
  isSelected: boolean
  onClick: () => void
}

export function ZoomCheckboxSetting({ additionalChildren=null, label, helperText, id, isSelected, onClick }: SettingProps) {
  return (
    <div className="flex gap-4">
      <div className="flex-shrink-0">
        <CheckBox id={id} isChecked={isSelected} onChange={onClick} />
      </div>
      <div className="pt-px leading-0">
        <LabelText htmlFor={id} text={label} />
        {additionalChildren}
        <HelperText text={helperText} />
      </div>
    </div>
  );
}

export function ZoomRadioSetting({ label, helperText, id, isSelected, onClick }: SettingProps) {
  return (
    <div className="flex items-center gap-4">
      <div className="flex-shrink-0">
        <RadioButton id={id} isSelected={isSelected} onClick={onClick} />
      </div>
      <div className="pt-px leading-0">
        <LabelText htmlFor={id} text={label} />
        <HelperText text={helperText} />
      </div>
    </div>
  );
}

export function ZoomSwitchSetting({ label, helperText, id, isSelected, onClick }: SettingProps) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <LabelText htmlFor={id} text={label} />
        <DefaultSwitch id={id} isChecked={isSelected} onChange={onClick} />
      </div>
      <HelperText text={helperText} />
    </div>
  );
}

function LabelText({ htmlFor, text }: { htmlFor?: string, text: string }) {
  return (
    <label htmlFor={htmlFor} className="default-font-size font-light leading-tight cursor-pointer">
      {text}
    </label>
  );
}

function HelperText({ text }: { text?: string }) {
  if (!text) {
    return null;
  }

  return (
    <div className="default-font-size secondary-text-color font-light leading-tight mt-2">
      {text}
    </div>
  );
}
