import React from "react";
import SpinnerV2 from "../spinnerV2";

/**
 * A spinner wrapped in an onboarding container to render while waiting for
 * queries to finish fetching.
 */
export default function OnboardingSpinner() {
  return (
    <div className="onboarding-container mx-auto py-20" style={{ maxWidth: 598 }}>
      <SpinnerV2 />
    </div>
  );
}
