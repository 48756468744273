import React, { PureComponent } from "react";
import CopiableContentContainer from "../copiableContentContainer";
import {
  formatPhoneNumberHumanReadable,
  formatRFCPhoneNumber,
  OpenLink,
} from "../../services/commonUsefulFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";

class PhoneConference extends PureComponent {
  formatDisplayPhoneNumber() {
    if (isEmptyObjectOrFalsey(this.props.number)) {
      return "";
    }
    let phoneNumber = formatRFCPhoneNumber(
      this.props.number,
      this.props.regionCode
    );

    return phoneNumber.replace("tel:", "").replace("-", " ");
  }

  render() {
    const displayNumber = this.formatDisplayPhoneNumber();

    return (
      this.props.number &&
      this.props.regionCode && (
        <div className="margin-left-16">
          <div>
            <div
              className="join-conference-link margin-left-22px"
              onClick={() =>
                OpenLink(
                  formatRFCPhoneNumber(this.props.number, this.props.regionCode)
                )
              }
            >
              Join Phone
            </div>

            <div style={{ marginLeft: 22 }} className="margin-top-3">
              <CopiableContentContainer textToCopy={displayNumber}>
                ({this.props.regionCode})
                <div className="margin-left-5">{displayNumber}</div>
              </CopiableContentContainer>

              <div className="margin-top-3">
                Call {this.props.firstName} at{" "}
                {formatPhoneNumberHumanReadable(
                  this.props.number,
                  this.props.regionCode
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default PhoneConference;
