import React from "react";
import ShortcutTile from "./shortcutTile";

interface FilledShortcutTilesProps {
  shortcut: string
  doNotSplit?: boolean
  isCommandCenter?: boolean
}

export default function FilledShortcutTiles({ shortcut, doNotSplit, isCommandCenter }: FilledShortcutTilesProps) {
  if (!shortcut) {
    return null;
  }

  const splitShortcutPhrase = doNotSplit ? [shortcut] : shortcut.split(" ");

  return (
    <div className="flex">
      {splitShortcutPhrase.map((s, index) => {
        if (["then", "and", "or"].includes(s)) {
          return (
            <span
              key={`shortcutPhrase${index}`}
              className="font-size-12 ml-2 mr-1 flex items-center"
            >
              {s}
            </span>
          );
        } else {
          return (
            <ShortcutTile
              key={`shortcutTile${index}`}
              isCommandCenter={isCommandCenter}
              shortcut={s}
            />
          );
        }
      })}
    </div>
  );
}
