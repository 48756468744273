import React, { PureComponent } from "react";
import {
  KEYCODE_ENTER,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  updateStringForTime,
  chronoHasKnownTime,
  customChronoWordDetection,
  guessTimeZone,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Classnames from "classnames";
import {
  format,
  isSameMinute,
  isValid,
  addMinutes,
  subMinutes,
  setMinutes,
  set,
  startOfMinute,
} from "date-fns";
import { getInputStringFromEvent } from "../lib/stringFunctions";
import { getDateTimeFormat } from "../lib/dateFunctions";

const SELECT_TIME = "Select time";

class SelectEventTime extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      time: this.props.defaultDisplaySelect
        ? SELECT_TIME
        : this.props.eventTime,
      inputText: null,
      timeZone:
        this.props.timeZone || this.props.currentTimeZone || guessTimeZone(),
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.saveInputContent = this.saveInputContent.bind(this);
  }

  componentDidUpdate(prevState) {
    if (
      this.props.innerRef &&
      isValidJSDate(this.props.eventTime) &&
      !isSameMinute(prevState.time, this.props.eventTime)
    ) {
      const newTimeString = format(
        this.props.eventTime,
        this.determineTimeFormat()
      );
      this.props.innerRef.current.state.inputValue = newTimeString;

      this.setState({ time: this.props.eventTime });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          fontSize: 16,
          flexDirection: "column",
        }}
        id="select-event-time"
      >
        {this.renderDatePicker()}
      </div>
    );
  }

  renderDatePicker() {
    // dateFormat is for formatting the input
    // timeFormat is for formatting the dropdown
    return (
      <DatePicker
        selected={
          this.props.selectInOptions && !isValid(this.state.time)
            ? null
            : startOfMinute(this.state.time)
        }
        onChange={(time, other) => this.onChange(time, other)}
        showTimeSelect
        id={this.props.id || "datePicker"}
        tabIndex={this.props.inputTabIndex}
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption={this.props.isStartTime ? "Start time" : "End time"}
        dateFormat={getDateTimeFormat(this.props.format24HourTime)}
        timeFormat={getDateTimeFormat(this.props.format24HourTime)}
        timeClassName={this.handleTimeColor}
        calendarClassName="select-time-input"
        className={Classnames(
          `select-event-time-input ${this.props.additionalClassname}`,
          "select-event-time-normal",
          this.determineBackgroundColor()
        )}
        showPopperArrow={false}
        popperClassName="event-form-react-select-input-popper"
        onKeyDown={this.onKeyDown}
        placeholderText={this.props.isStartTime ? "Start time" : "End time"}
        ref={this.props.innerRef}
        onBlur={this.props.onBlur}
        onClickOutside={this.saveInputContent}
        onChangeRaw={(e) => this.setInputText(getInputStringFromEvent(e))}
        autoComplete="off"
      />
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onChange(time, origin) {
    // time comes in as jsDate
    if (!origin) {
      // Problem with library, onSelect also triggers when you type so need to detect if change came from typing or selecting.
      // if origin is not null -> typing.
      this.props.innerRef.current.state.inputValue = format(
        time,
        this.determineTimeFormat()
      );

      this.setState({ time });

      this.props.setEventTime(time);
    }
  }

  determineTimeFormat() {
    return getDateTimeFormat(this.props.format24HourTime);
  }

  setInputText(string) {
    this.setState({ inputText: string });
  }

  saveInputContent() {
    // this.props.innerRef.current.state.inputValue ||
    let inputValue =
      this.state.inputText ||
      format(this.state.time, this.determineTimeFormat());
    let updatedValue = inputValue;
    let updatedTimeString = updateStringForTime(updatedValue);

    if (updatedTimeString) {
      updatedValue = updatedTimeString;
    }

    let chronoGuess = customChronoWordDetection({ text: updatedValue });
    let chronoGuessHasKnownTime = chronoHasKnownTime(chronoGuess);

    let updatedTime = this.state.time;

    if (chronoGuessHasKnownTime) {
      // event time is valid
      let hours = chronoGuess[0].start.knownValues.hour;
      let minutes = chronoGuess[0].start.knownValues.minute;

      if (
        !Object.keys(chronoGuess[0].start.knownValues).includes("meridiem") &&
        !this.props.format24HourTime &&
        (hours < 8 ||
          (this.props.eventStartTime &&
            this.props.eventStartTime.getHours() > hours))
      ) {
        // make into pm if before 8pm or if start time is in pm already
        hours = hours + 12;
      }

      updatedTime = set(this.state.time, { hours, minutes });
    }

    let stringTime = format(updatedTime, this.determineTimeFormat());

    this.setState({ time: updatedTime });

    this.props.innerRef.current.state.inputValue = stringTime;

    this.props.setEventTime(updatedTime);
  }

  determineBackgroundColor() {
    if (this.props.highlightField) {
      return "highlight-field-color";
    } else if (this.props.hasIssue) {
      return "problem-background-color";
    } else if (this.props.selectInOptions) {
      return "template-select-event-time";
    } else {
      return "theme-select-event-time-input";
    }
  }

  onKeyDown(key) {
    if (key && [KEYCODE_TAB, KEYCODE_ENTER].includes(key.keyCode)) {
      this.saveInputContent();

      if (key.keyCode === KEYCODE_ENTER) {
        this.props.innerRef.current.setOpen(false);

        this.props.onEnter();
      } else {
        this.props.innerRef.current.state.open = false;
      }
    } else if ([KEYCODE_DOWN_ARROW, KEYCODE_UP_ARROW].includes(key.keyCode)) {
      let newlySelected;

      if (!this.state.time || !isValid(this.state.time)) {
        newlySelected = setMinutes(
          new Date(),
          Math.ceil(new Date().getMinutes() / 30) * 30
        );
      } else {
        newlySelected =
          key.keyCode === KEYCODE_DOWN_ARROW
            ? addMinutes(this.state.time, 30)
            : subMinutes(this.state.time, 30);
      }

      let newlySelectedString = format(
        newlySelected,
        this.determineTimeFormat()
      );

      this.props.innerRef.current.state.inputValue = newlySelectedString;

      let newlyFocusedElement = document.getElementsByClassName(
        "react-datepicker__time-list-item--selected"
      );

      newlyFocusedElement &&
        newlyFocusedElement.length > 0 &&
        newlyFocusedElement[0].scrollIntoView({ block: "center" });

      this.setState({ time: newlySelected });
    }
  }
}

function mapStateToProps(state) {
  let { currentTimeZone, isDarkMode, format24HourTime } = state;

  return {
    currentTimeZone,
    isDarkMode,
    format24HourTime,
  };
}

export default connect(mapStateToProps)(SelectEventTime);
