import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GlobalKeyMapTile from "./globalKeyMapTile";
import ShortcutHoverHint from "./shortcutHoverHint";
import Broadcast from "../broadcasts/broadcast";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import SaveButton from "./saveButton";
import {
  calculateMarginTopClassname,
  isInt,
  removeLeadingZeros,
  sortEventsByStartTime,
  handleError,
  isMac,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  KEYCODE_CONTROL,
  KEYCODE_C,
  KEYCODE_ESCAPE,
  guessTimeZone,
  createAvailabilityTextFromEvent,
  determineSaveButtonShortcut,
  sortPersonalLinks,
  KEYCODE_COMMAND_FIREFOX,
  KEYCODE_K,
  getTimeInAnchorTimeZone,
  isAfterMinute,
  isBeforeMinute,
  getCurrentTimeInCurrentTimeZone,
  detectBrowser,
  getActiveCommandCentersKey,
  isHangoutGSuiteIntegration,
  isTemplateZoomConferencing,
  isTooltipCompleted,
  hasEventPreventDefault,
  hasStopEventPropagation,
  getLastElementOfArray,
  updateEventsTime,
  omitNullOrUndefinedProps,
  getComponentLocation,
  generateAvailabilityToken,
  isValidJSDate,
  sortEventsJSDate,
  isBeforeDay,
  getMonthDayYearLongForm,
  convertEmailToName,
  createDefaultPersonalLinkEventSummary,
  isElectron,
  isDayView,
  isValidTimeZone,
} from "../services/commonUsefulFunctions";
import {
  Plus,
  FilePlus,
  Settings,
  X,
  Edit2,
  Minus,
  CornerUpLeft,
  ChevronDown,
} from "react-feather";
import { FEATURE_TRACKING_ACTIONS, trackEvent, trackFeatureUsage } from "./tracking";
import StyleConstants, {
  BACKEND_PERSONAL_LINK,
  AVAILABILITY_PERSONAL_SLOT_CONTAINER_ID,
  SLOTS_TEXT_SELECTION,
  SLOTS_AVAILABILITY_SELECTION,
  PERSONAL_LINK_SELECTION,
  BOOKING_LINK,
  SAFARI,
  SLOTS_STYLE_PICKER_ID,
  SLOTS_DETAIL_FIELDS,
  GROUP_VOTE_SELECT_OPTION,
  BACKEND_GROUP_VOTE_LINK,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  SLOTS_LINK_ALONE,
  HIDE_SHOW_AVAILABILITY_DETAILS,
  MEETING_DETAIL_ID,
  ADD_SLOT,
  UPDATE_SLOT,
  DELETE_SLOT,
  DEFAULT_FONT_COLOR,
  MODAL_BLUR,
  ISO_DATE_FORMAT,
  SECOND_IN_MS,
  SLOTS_TEXT_FORMAT_PICKER_ID,
  SAVE_BUTTON_HEIGHT,
} from "../services/globalVariables";
import PersonalLinkContainer from "./personalLinkContainer";
import PersonalLinkDetailPage from "./personalLinkDetailPage";
import _ from "underscore";
import AvailabilityAdditionalDetails from "./availabilityAdditionalDetails";
import { isAfter, parseISO, differenceInMinutes, isBefore, addMinutes, formatISO, endOfDay, addDays, differenceInDays, subDays, startOfDay, isSameDay, isSameYear } from "date-fns";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import CustomButton from "./customButton";
import {
  formatContactsToAttendees,
  getPreviouslySelectedSlots,
} from "../lib/eventFunctions";
import { safariCopy } from "../lib/safariFunctions";
import {
  ZOOM_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  WHATS_APP_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION,
  FREE_DURING_EVENT,
  VIMCAL_SIGNATURE,
  BUSY_DURING_EVENT,
} from "../services/googleCalendarService";
import {
  isTemplatePhoneConferencing,
  isTemplateWhatsAppConferencing,
  isTemplateCustomConferencing,
  isValidCustomConferencing,
  createCustomConferencingOption,
} from "../lib/conferencing";
import {
  createWritableCalendarList,
  setLastestAvailabiltyType,
  isLatestAvailabiltyTypePersonalLink,
  getAllExtraTimeZones,
  getMostLeftHandTimeZone,
} from "../lib/stateManagementFunctions";
import {
  getSlotsPresets,
  convertCopyFromPlaceHolders,
  determineBookingURL,
  determineSlotsSelectType,
  sortGroupVote,
  getBlockedCalendarsID,
  getLastSlotDuration,
  saveLastSlotDuration,
  isSameSlot,
  combineSlots,
  splitSlotIntoDuration,
  fetchPersonalLinks,
  getActiveGroupVotes,
  getGroupVoteURLLink,
  fetchFreeBusySlots,
  isOutstandingSlotEvent,
  getUserAvailabilitySettings,
  sortMultipleTimeZonesForSlots,
  TEXT_STYLE_OPTIONS,
} from "../lib/availabilityFunctions";
import classNames from "classnames";
import GroupVoteDetailPage from "./scheduling/groupVoteDetailPage";
import { LATEST_SLOTS_VERSION, SLOTS_VERSION, useAppSettings, useGroupVoteStore } from "../services/stores/settings";
import GroupVoteListView from "./scheduling/groupVoteListView";
import { protectMidnightCarryOver } from "../lib/rbcFunctions";
import {
  SLOTS_RICH_TEXT,
  SLOTS_PLAIN_TEXT,
  SLOTS_PLAIN_TEXT_URL,
  LINK_ALONE,
  HOLD_TITLE,
  HOLD_COLOR_ID,
  ADD_ATTENDEE_TO_HOLDS,
  APP_SETTINGS,
} from "../lib/vimcalVariables";
import ColoredLine from "./line";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getCalendarFromEmail,
  getCalendarFromProviderID,
  getUserCalendar,
  getActiveCalendarsIDsFromAllCalendars,
  getCalendarName,
  isCalendarSelected,
  filterForAllWritableCalendars,
  findBestGuessWritableCalendar,
  getCalendarUserEmail,
  isCalendarOutlookCalendar,
  getMatchingCalendarWithCalendarOwnerFromEmail,
} from "../lib/calendarFunctions";
import { getConnectedAccountUserName, getMatchingExecutiveUserFromCalendar, isCalendarExecutiveCalendar, isUserMaestroUser, LINKABLE_TYPES } from "../services/maestroFunctions";
import CheckBox from "./checkbox";
import { tooltipKeys } from "../services/tooltipVariables";
import TooltipBox from "./tooltips/tooltipBox";
import {
  getTemplateAttendees,
  getTemplateConferenceData,
  getTemplateDescription,
  getTemplateDuration,
  getTemplateEmail,
  getTemplateIsAllDay,
  getTemplateLocation,
  getTemplateTitle,
  isTextTemplate,
  isZoomFromIntegrationTemplate,
} from "../services/templateFunctions";
import {
  getCalendarObject,
  getCalendarOwnerEmail,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import EventModalPopup from "./eventModalPopup";
import SelectBlockedCalendars from "./availability/selectBlockedCalendars";
import { getBlockedCalendars } from "../services/accountFunctions";
import DefaultSwitch from "./defaultSwitch";
import {
  AVAILABILITY_COPY_BUTTON_ID,
  AVAILABILITY_PANEL_ID,
  COMBINE_ADJACENT_SLOTS_ID,
  GET_UPCOMING_WEEK_OPENINGS_MESSAGE,
  PERSONAL_LINK_ANIMATION_ID,
  PREVIOUSLY_SELECTED_SLOTS_MESSAGE,
  SLOTS_PAGE_ID,
} from "../services/elementIDVariables";
import { useAvailabilityStore } from "../services/stores/availabilityStores";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import EmptyStatePanel from "./availability/emptyStatePanel";
import { isVersionV2 } from "../services/versionFunctions";
import PersonalLinkAnimation from "./availability/personalLinkAnimation";
import SlotsHoldsSection from "./availability/slotsHoldsSection";
import { customMenuStyle, getReactSelectBaseStyle } from "./select/styles";
import {
  getAccountName,
  getMasterAccountTemplates,
  getMatchingUserFromAllUsers,
  getUserEmail,
  getUserName,
  getUserSlotsCustomQuestions,
  getUserToken,
} from "../lib/userFunctions";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import SelectStyleModal from "./availability/selectStyleModal";
import {
  SELECT_USER_TYPE,
  SelectUser,
  getAllUsers,
} from "./settings/common/selectUser";
import {
  getSavedPersonalLinks,
  savePersonalLinksIntoLocalData,
} from "../lib/localData";
import GrabAvailableSlotsContainer from "./availability/grabAvailableSlotsContainer";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import PersonalizeSlotsSection from "./availability/personalizeSlotsSection";
import CustomDropdownContainer from "./customDropdownContainer";
import AILogo from "./icons/aiLogo";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import {
  copyFormattedText,
  doesStringContainBoldTags,
  doesStringContainTime,
  extractBoldText,
  getDateAndTimeFromInput,
  getHtmlAndTextContent,
  getInputStringFromEvent,
  isSameEmail,
  pluralize,
  slugifyString,
  strContainsDateAndTime,
} from "../lib/stringFunctions";
import { blurCalendar, copyContent, getSlotsDefaultOpenSections, isGroupVoteDetailPageOpen, isSlotsPendingLoadingAttendees, removeSearchedTimeZone } from "../services/appFunctions";
import { DEFAULT_CUSTOM_QUESTIONS } from "./customQuestions";
import { addDefaultToArray, immutablySortArray, isEmptyArray, removeDuplicatesFromArray } from "../lib/arrayFunctions";
import { determineDefaultModalStyle, MODAL_OVERLAY_Z_INDEXES, MODAL_TYPES } from "../lib/modalFunctions";
import modalBroadcast from "../broadcasts/modalBroadcast";
import {convertOutlookConferencingToHumanReadable, isOutlookUser, isTemplateOutlookConferencing, shouldGateExtendedProperties } from "../lib/outlookFunctions";
import { SLOTS_SECTIONS, useAccountActivity, useAppTimeZones } from "../services/stores/appFunctionality";
import PersonalLinksTraveling from "./modal/personalLinksTraveling";
import { BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY, getCurrentTrip, getPersonalLinkToken, getTripEndDate, getTripTimeZoneAbbreviation, getUpcomingTrips } from "../lib/personalLinkFunctions";
import DidYouKnowAlert from "./availability/alerts/didYouKnowAlert";
import WarningAlert from "./availability/alerts/warningAlert";
import CriticalAlert from "./availability/alerts/criticalAlert";
import { isWarningAllDaySlotsWarning } from "./availability/alerts/utils";
import { AVAILABILITY_BROADCAST_VALUES, BACKEND_BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, BROADCAST_VALUES, HOLDS_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES, MODAL_BROADCAST_VALUES } from "../lib/broadcastValues";
import { useOutstandingSlotsStore } from "../services/stores/outstandingSlots";
import { filterOutstandingSlotsByUser } from "../lib/temporaryEventFunctions";
import OutstandingSlotsSection from "./availability/outstandingSlotsSection";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import broadcast from "../broadcasts/broadcast";
import { GROUP_SPREADSHEET_LINKS_ENDPOINT, LATEST_GROUP_VOTE_LINKS } from "../lib/endpoints";
import { isSpreadsheetGroupLink } from "../lib/groupVoteFunctions";
import GroupVoteCreateButton from "./scheduling/groupVoteCreateButton";
import { hasGroupSpreadsheetAccess, shouldShowSlotsTextFormat } from "../lib/featureFlagFunctions";
import { SHOW_EVERY_SELECTED_TIME_ZONE_COPY, SLOTS_IN_BUFFER_AFTER_CONFLICTS, SLOTS_IN_BUFFER_BEFORE_CONFLICTS } from "../lib/copy";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { getDefaultUserTimeZone, getInternalDomainAndEmails, shouldHideDefaultSignature } from "../lib/settingsFunctions";
import { createUUID } from "../services/randomFunctions";
import { getAvailabilityPanelPreviewStyle, getDefaultModalBorder, getModalBackgroundColorWithExtraOpacity, getModalBackgroundColorWithNoOpacity, getSidePoppedOverModalBorder, getTransparentModalStyle, LIGHT_MODE_AVAILABILITY_BORDER_COLOR } from "../lib/styleFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { useDistroListDictionary } from "../services/stores/eventsData";
import { getEventUserEventID } from "../services/eventResourceAccessors";
import ExpandIconWithAnimation from "./icons/expandIconWithAnimation";
import SlotsTimeZoneBlock from "./availability/slotsTimeZoneBlock";
import { getHumanReadableTimeInMinutes } from "./metrics/metricsAccessorFunctions";
import Alert from "./availability/alerts/alert";
import SpinnerV2 from "./spinnerV2";
import holdsBroadcast from "../broadcasts/holdsBroadcast";
import SlotsTextStyleModal from "./availability/slotsTextStyleModal";
import { filterOutResourceAttendees } from "../services/attendeeFunctions";
import { isUserFromMagicLink } from "../services/maestro/maestroAccessors";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";

const AVAILABILITY_TEXT_CONTENT = "availability-text-content";
const SELECT_USER_ID = "select-availability-user-id";

const RISK_OF_DOUBLE_BOOKING_TITLE = "Risk of being double booked";
const ALL_DAY_BLOCKING_EVENT_TITLE = "All-day events blocking Slots";

const EVENT_MODAL_CONTENT = {
  CHECK_FOR_CONFLICT: "CHECK_FOR_CONFLICT",
  SELECT_SLOTS_STYLE: "SELECT_SLOTS_STYLE",
  SELECT_SLOTS_TEXT_STYLES: "SELECT_SLOTS_TEXT_STYLES",
  PERSONAL_LINKS_TRAVELING: "PERSONAL_LINKS_TRAVELING",
};

const DEFAULT_COPIED_SLOTS_MESSAGE = "Copied Slots to clipboard.";
const DEFAULT_COPIED_SLOTS_URL_MESSAGE = "Copied Slots URL to clipboard.";

const {
  TITLE_DETAIL,
  DURATION_DETAIL,
  CONFERENCING_DETAIL,
  ATTENDEES_DETAIL,
  SELECTED_CALENDAR_DETAIL,
  LOCATION_DETAIL,
  DESCRIPTION_DETAIL,
} = SLOTS_DETAIL_FIELDS;

const closeShortCutHint = {
  marginLeft: "-104px",
  marginTop: "-6px",
};

const closeButtonTile = {
  left: "-25px",
  top: "-14px",
};

const DEFAULT_PAGE = "default_page";
const EDIT_LINK_DETAIL = "edit_link_detail";
const EDIT_GROUP_VOTE_DETAIL = "EDIT_GROUP_VOTE_DETAIL";
const EDIT_GROUP_SPREADSHEET_DETAIL = "EDIT_GROUP_SPREADSHEET_DETAIL";

const SELECT_COPY_TYPE_OPTIONS = [
  SLOTS_AVAILABILITY_SELECTION,
  PERSONAL_LINK_SELECTION,
  GROUP_VOTE_SELECT_OPTION,
];

const HOVERING_TOOL_TIP = {
  FIND_TIMES: "find-times",
  PREVIOUSLY_SELECTED_SLOTS: "previously-selected-slots",
};

const AVAILABILITY_TYPE_WITH_SWITCH = [BOOKING_LINK];

const CONTAINER_ID = "create-availability-panel";

class AvailabilityPanel extends Component {
  constructor(props) {
    super(props);

    this.windowSizeChangeTimer = null;
    this.description = createRef();
    this.topRef = createRef();

    const { allCalendars } = props.allCalendars;
    const {
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      title,
      duration,
      conferencing,
      googleId,
      location,
      description,
      allowReschedule,
      bufferFromNow,
      combineAdjacentSlots,
      holdTitle,
      token,
      blockedCalendars,
      contentShowsAllTimeZones,
      shouldAddAttendeesToHolds,
      bufferBeforeEvent,
      bufferAfterEvent,
    } = this.getAccountSettingsInfo();
    this._draggedSlots = []; // keeps track of what the user has dragged. NO need to keep in state since we don't need to force re-render
    this._checkForConflictEvents = []; // fetchedEvents for check for conflict
    const sortedGroupLinks = this.getSortedGroupVoteLinks();
    const {
      slotsDefaultOpenSections,
    } = this.props.accountActivity;
    const {
      currentUser,
      currentTimeZone,
      personalLinks,
    } = this.props;

    const {
      isStylingSectionExpanded,
      isHoldsSectionExpanded,
      isPersonalizeSectionExpanded,
      isMeetingDetailsExpanded,
    } = getSlotsDefaultOpenSections(slotsDefaultOpenSections);

    const {
      slotsTextStyles,
      lastSelectedSlotsStyle,
    } = this.props.appSettings;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    this.state = {
      allowReschedule: allowReschedule,
      content: "",
      availabilityType: this.getInitialAvailabilityType(props),
      duration,
      minutesQuery: "",
      isSubmitting: false,
      conferencing,
      token,
      innerHeight: window.innerHeight,
      isCmdKeyDown: false,
      isMac: isMac(),
      isOutstandingSlotsSectionOpen: false,
      personalLinks: addDefaultToArray(personalLinks),
      page: DEFAULT_PAGE,
      personalLinkInfo: {}, // keep track of which link the user is editing
      groupVoteInfo: {},
      savedTemporaryEvents: [],
      isCreateNewPersonalLink: false,
      optionalInviteeName: "",
      optionalInviteeEmail: "",
      attendees: [],
      eventTitle: title,
      previousSelectedSlots: getPreviouslySelectedSlots(
        currentTimeZone,
        currentUser,
      ),
      location,
      description,
      selectedCalendar:
        getCalendarFromProviderID({
          allCalendars,
          providerID: googleId,
          selectedUser: this.getSelectedUser(),
          allLoggedInUsers,
          masterAccount,
        }) || getCalendarFromEmail({
          email: getUserEmail(currentUser),
          allLoggedInUsers,
          allCalendars,
          masterAccount,
        }),
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      slotsType: determineSlotsSelectType({
        availabilitySettings: getUserAvailabilitySettings({ user: currentUser }),
        lastSelectedSlotsStyle,
        masterAccount,
        currentUser,
      }),
      showCustomQuestions: false,
      showSlotsHoldForm: false,
      slotsHoldTitle: holdTitle,
      shouldHideSlotsTooltip: true,
      bufferFromNow,
      shouldDisplayModal: false,
      blockedCalendars,
      blockedCalendarsID: getBlockedCalendarsID(blockedCalendars), // if on different user, gets switched in didMount::checkForUnselectedInitialCalendar
      isCombineAdjacentSlots: combineAdjacentSlots,
      slotHoldOverrideColorID: null, // user manually selected a different color than the preset colorID
      slug: this.createSlug({ inputDuration: duration, inputToken: token }),
      showSelector: false,
      eventModalContent: null,
      modalTop: 20,
      contentShowsAllTimeZones,
      selectedUserIndex: this.getAllLoggedInUserIndex(currentUser),
      allUsersForSelect: this.getAllUsersForSelect(),
      shouldAddAttendeesToHolds,
      shouldShowExpandedPersonalizeSection: false,
      showPersonalizeScreenshot: false,
      blockedAttendeesCalendars: [],
      hoveringToolTip: null,
      isLoadingFindTimes: false,
      bufferBeforeEvent,
      bufferAfterEvent,
      // alerts are objects with title and subtitle {title, subText}
      didYouKnowAlert: null,
      warningAlert: null,
      criticalAlert: null,
      isIgnoreConflicts: false,
      sortedGroupLinks,
      activeGroupVotes: getActiveGroupVotes(sortedGroupLinks),
      isIgnoreInternalConflicts: false,
      selectedOutlookCategories: [],
      isStylingSectionExpanded,
      isPersonalizeSectionExpanded,
      isHoldsSectionExpanded,
      isMeetingDetailsExpanded,
      textStyles: slotsTextStyles?.filter(textStyle => Object.values(TEXT_STYLE_OPTIONS).includes(textStyle)),
    };

    this.onChangeMinutesInput = this.onChangeMinutesInput.bind(this);
    this.onSelectMinutes = this.onSelectMinutes.bind(this);
    this.copyPureText = this.copyPureText.bind(this);
    this.copyContent = this.copyContent.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.onKeyDownSelect = this.onKeyDownSelect.bind(this);
    this.onChangeAvailabilityType = this.onChangeAvailabilityType.bind(this);
    this.addNewPersonalLink = this.addNewPersonalLink.bind(this);
    this.updatePersonalLinks = this.updatePersonalLinks.bind(this);
    this.deletePersonalLink = this.deletePersonalLink.bind(this);
    this.createTextFromSelection = this.createTextFromSelection.bind(this);
    this.onChangeInviteeName = this.onChangeInviteeName.bind(this);
    this.onChangeInviteeEmail = this.onChangeInviteeEmail.bind(this);
    this.onChangeAttendees = this.onChangeAttendees.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.copyLinkOnly = this.copyLinkOnly.bind(this);
    this.getPreviouslyUsedSlotsAndTimeZone =
      this.getPreviouslyUsedSlotsAndTimeZone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onClickTemplate = this.onClickTemplate.bind(this);
    this.onClickSettings = this.onClickSettings.bind(this);
    this.onSelectConferencing = this.onSelectConferencing.bind(this);
    this.onChangeCalendar = this.onChangeCalendar.bind(this);
    this.applyEventTemplate = this.applyEventTemplate.bind(this);
    this.updateContentText = this.updateContentText.bind(this);
    this.addGroupVoteLink = this.addGroupVoteLink.bind(this);
    this.addGroupSpreadsheetLink = this.addGroupSpreadsheetLink.bind(this);
    this.updateSlotsStyle = this.updateSlotsStyle.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.toggleShowCustomQuestions = this.toggleShowCustomQuestions.bind(this);
    this.toggleShowSlotsHoldForm = this.toggleShowSlotsHoldForm.bind(this);
    this.onChangeSlotsHoldTitle = this.onChangeSlotsHoldTitle.bind(this);
    this.constructHoldEvents = this.constructHoldEvents.bind(this);
    this.deleteLastAvailabilityEvent =
      this.deleteLastAvailabilityEvent.bind(this);
    this.onChangeAllowReschedule = this.onChangeAllowReschedule.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openSelectBlockedCalendarsModal =
      this.openSelectBlockedCalendarsModal.bind(this);
    this.setUserDraggedSlots = this.setUserDraggedSlots.bind(this);
    this.updateUserDraggedSlotsTimeZone =
      this.updateUserDraggedSlotsTimeZone.bind(this);
    this.toggleBreakIntoDuration = this.toggleBreakIntoDuration.bind(this);
    this.onChangeIsCombineAdjacentSlots =
      this.onChangeIsCombineAdjacentSlots.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.onChangeHoldsColorID = this.onChangeHoldsColorID.bind(this);
    this.generateSlugAndCopyLinkOnly =
      this.generateSlugAndCopyLinkOnly.bind(this);
    this.openSelectSlotStyleModal = this.openSelectSlotStyleModal.bind(this);
    this.openSelectSlotTextFormatModal = this.openSelectSlotTextFormatModal.bind(this);
    this.onChangeSlotStyle = this.onChangeSlotStyle.bind(this);
    this.onChangeShouldAddAttendeesToHolds =
      this.onChangeShouldAddAttendeesToHolds.bind(this);
    this.getUpcomingSlotsAvailability =
      this.getUpcomingSlotsAvailability.bind(this);
    this.blurMainCalendar = this.blurMainCalendar.bind(this);
    this.pressX = this.pressX.bind(this);
    this.onSelectUserIndex = this.onSelectUserIndex.bind(this);
    this.setSelectedUser = this.setSelectedUser.bind(this);
    this.openPersonalLink = this.openPersonalLink.bind(this);
    this.onChangeBlockedAttendeesCalendars =
      this.onChangeBlockedAttendeesCalendars.bind(this);
    this.showExpandedPersonalizeSection =
      this.showExpandedPersonalizeSection.bind(this);
    this.refreshAllUsersForSelect = this.refreshAllUsersForSelect.bind(this);
    this.openGetAvailableSlotsModal =
      this.openGetAvailableSlotsModal.bind(this);
    this.closeGetAvailableSlotsModal =
      this.closeGetAvailableSlotsModal.bind(this);
    this.removeHoverTooltip = this.removeHoverTooltip.bind(this);
    this.resetLoadingFindTimes = this.resetLoadingFindTimes.bind(this);
    this.showLoadingFindTimeSpinner = this.showLoadingFindTimeSpinner.bind(this);
    this.setAllDaySlotsWarning = this.setAllDaySlotsWarning.bind(this);
    this.checkAllDayEventsBlocking = this.checkAllDayEventsBlocking.bind(this);
    this.getCheckForConflictBlockedEvents = this.getCheckForConflictBlockedEvents.bind(this);
    this.openTravelingModal = this.openTravelingModal.bind(this);
    this.updateSingleAttendee = this.updateSingleAttendee.bind(this);
    this.setIsIgnoreConflicts = this.setIsIgnoreConflicts.bind(this);
    this.setGenericSlotsWarning = this.setGenericSlotsWarning.bind(this);
    this.removeSlotsBeforeAfterConflictWarning = this.removeSlotsBeforeAfterConflictWarning.bind(this);
    this.setSlotsStateAndProps = this.setSlotsStateAndProps.bind(this);
    this.setUpdateIngoreInternalConflicts = this.setUpdateIngoreInternalConflicts.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.pasteTextIntoDescription = this.pasteTextIntoDescription.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onChangeTextStyles = this.onChangeTextStyles.bind(this);

    Broadcast.subscribe("COPY_AVAILABILITY_CONTENT", this.copyContent);
    Broadcast.subscribe("UPDATE_PERSONAL_LINKS", this.updatePersonalLinks);
    Broadcast.subscribe("DELETE_PERSONAL_LINK", this.deletePersonalLink);
    Broadcast.subscribe("SET_AVAILABILITY_TYPE", this.onChangeAvailabilityType);
    Broadcast.subscribe("UPDATE_SLOTS_STYLE", this.updateSlotsStyle);
    AvailabilityBroadcast.subscribe(
      "COPY_AVAILABILITY_LINK_ONLY",
      this.generateSlugAndCopyLinkOnly
    );
    AvailabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE,
      this.getPreviouslyUsedSlotsAndTimeZone
    );
    AvailabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.APPLY_EVENT_TEMPLATE_TO_SLOTS,
      this.applyEventTemplate
    );
    AvailabilityBroadcast.subscribe(
      "UPDATE_AVAILABILITY_SELECTION",
      this.updateContentText
    );
    AvailabilityBroadcast.subscribe(
      "DELETE_LAST_AVAILABILITY_EVENT",
      this.deleteLastAvailabilityEvent
    );
    AvailabilityBroadcast.subscribe(
      "SET_USER_DRAGGED_SLOTS",
      this.setUserDraggedSlots
    );
    AvailabilityBroadcast.subscribe(
      "UPDATE_USER_DRAGGED_SLOTS_TIME_ZONE",
      this.updateUserDraggedSlotsTimeZone
    );
    AvailabilityBroadcast.subscribe(
      "TOGGLE_COMBINE_ADJACENT_SLOTS",
      this.toggleBreakIntoDuration
    );
    AvailabilityBroadcast.subscribe(
      "SET_COMBINE_ADJACENT_SLOTS",
      this.onChangeIsCombineAdjacentSlots
    );
    AvailabilityBroadcast.subscribe(
      "ADD_GROUP_VOTE_LINK",
      this.addGroupVoteLink
    );
    AvailabilityBroadcast.subscribe(
      "ADD_GROUP_SPREADSHEET_LINK",
      this.addGroupSpreadsheetLink,
    );
    AvailabilityBroadcast.subscribe("SET_SELECTED_USER", this.setSelectedUser);
    AvailabilityBroadcast.subscribe(
      "OPEN_PERSONAL_LINK_DETAIL",
      this.openPersonalLink
    );
    AvailabilityBroadcast.subscribe(
      "REFRESH_ALL_USERS_IN_SELECT",
      this.refreshAllUsersForSelect
    );
    AvailabilityBroadcast.subscribe(
      "OPEN_GET_AVAILABLE_SLOTS_MODAL",
      this.openGetAvailableSlotsModal
    );
    AvailabilityBroadcast.subscribe("RESET_LOADING_FIND_TIMES", this.resetLoadingFindTimes);
    AvailabilityBroadcast.subscribe("SHOW_LOADING_FIND_TIME_SPINNER", this.showLoadingFindTimeSpinner);
    AvailabilityBroadcast.subscribe("CLOSE_GET_AVAILABLE_SLOTS_MODAL", this.closeGetAvailableSlotsModal);
    AvailabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING, this.setAllDaySlotsWarning);
    AvailabilityBroadcast.subscribe("CHECK_SLOTS_ALL_DAY_BLOCKING_EVENTS", this.checkAllDayEventsBlocking);
    AvailabilityBroadcast.subscribe("CHECK_FOR_BLOCKED_CALENDAR_CONFLICTS", this.getCheckForConflictBlockedEvents);
    AvailabilityBroadcast.subscribe("OPEN_TRAVELING_MODAL", this.openTravelingModal);
    AvailabilityBroadcast.subscribe("CLOSE_AVAILABILITY_PANEL_MODAL", this.closeModal);
    AvailabilityBroadcast.subscribe("UPDATE_SINGLE_ATTENDEE", this.updateSingleAttendee);
    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.SET_GENERIC_SLOTS_WARNING, this.setGenericSlotsWarning);
    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.REMOVE_SLOTS_BEFORE_AFTER_CONFLICT_WARNING, this.removeSlotsBeforeAfterConflictWarning);
    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.SET_SLOTS_STATE_AND_PROPS, this.setSlotsStateAndProps);
    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.ON_CLICK_GROUP_VOTE_EDIT, this.onClickEdit);
    AvailabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_DESCRIPTION, this.pasteTextIntoDescription);
    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.UPDATE_SLOTS_TYPE, this.onChangeSlotStyle);

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleMigration();

    const { setSelectedUser } = this.props.availabilityStore;
    setSelectedUser(this.getSelectedUser());

    const { setBreakDuration, resetBreakDuration } =
      this.props.availabilityStore;

    if (this.state.isCombineAdjacentSlots) {
      resetBreakDuration();
    } else {
      setBreakDuration(this.state.duration);
    }

    this.fetchPersonalLinks();
    this.fetchGroupVoteLinksForAllUsers();

    const { masterAccount } = this.props.masterAccount;
    if (
      !this.isPersonalLink() &&
      !isTooltipCompleted(masterAccount, tooltipKeys.SLOTS)
    ) {
      this.setState({ shouldHideSlotsTooltip: false });
      Broadcast.publish(BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED, tooltipKeys.SLOTS);
    }

    backendBroadcasts.publish("GET_LATEST_MASTER_ACCOUNT");
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_OUTSTANDING_SLOTS);

    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.setState({ showSelector: true }); // to make the animation smooth
    }, 0.1 * SECOND_IN_MS);

    this.getCheckForConflictBlockedEvents();
    this.checkForUnselectedInitialCalendar();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    modalBroadcast.publish("REMOVE_BOTTOM_MODAL_CONTENT");

    clearTimeout(this.windowSizeChangeTimer);

    this.windowSizeChangeTimer = null;

    this._isMounted = false;

    const { resetBreakDuration } = this.props.availabilityStore;
    const { resetSelectedUser } = this.props.availabilityStore;
    resetSelectedUser();
    resetBreakDuration();

    layoutBroadcast.publish("TOGGLE_OFF_SLOTS_ANIMATION");
    Broadcast.unsubscribe("COPY_AVAILABILITY_CONTENT");
    Broadcast.unsubscribe("SET_CREATE_AVAILABILITY_CONFERENCE");
    Broadcast.unsubscribe("UPDATE_PERSONAL_LINKS");
    Broadcast.unsubscribe("DELETE_PERSONAL_LINK");
    Broadcast.unsubscribe("SET_AVAILABILITY_TYPE");
    Broadcast.unsubscribe("UPDATE_SLOTS_STYLE");
    AvailabilityBroadcast.unsubscribe("COPY_AVAILABILITY_LINK_ONLY");
    AvailabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE);
    AvailabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.APPLY_EVENT_TEMPLATE_TO_SLOTS);
    AvailabilityBroadcast.unsubscribe("UPDATE_AVAILABILITY_SELECTION");
    AvailabilityBroadcast.unsubscribe("DELETE_LAST_AVAILABILITY_EVENT");
    AvailabilityBroadcast.unsubscribe("SET_USER_DRAGGED_SLOTS");
    AvailabilityBroadcast.unsubscribe("UPDATE_USER_DRAGGED_SLOTS_TIME_ZONE");
    AvailabilityBroadcast.unsubscribe("TOGGLE_COMBINE_ADJACENT_SLOTS");
    AvailabilityBroadcast.unsubscribe("SET_COMBINE_ADJACENT_SLOTS");
    AvailabilityBroadcast.unsubscribe("ADD_GROUP_VOTE_LINK");
    AvailabilityBroadcast.unsubscribe("ADD_GROUP_SPREADSHEET_LINK");
    AvailabilityBroadcast.unsubscribe("SET_SELECTED_USER");
    AvailabilityBroadcast.unsubscribe("OPEN_PERSONAL_LINK_DETAIL");
    AvailabilityBroadcast.unsubscribe("REFRESH_ALL_USERS_IN_SELECT");
    AvailabilityBroadcast.unsubscribe("OPEN_GET_AVAILABLE_SLOTS_MODAL");
    AvailabilityBroadcast.unsubscribe("RESET_LOADING_FIND_TIMES");
    AvailabilityBroadcast.unsubscribe("SHOW_LOADING_FIND_TIME_SPINNER");
    AvailabilityBroadcast.unsubscribe("CLOSE_GET_AVAILABLE_SLOTS_MODAL");
    AvailabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING);
    AvailabilityBroadcast.unsubscribe("CHECK_SLOTS_ALL_DAY_BLOCKING_EVENTS");
    AvailabilityBroadcast.unsubscribe("CHECK_FOR_BLOCKED_CALENDAR_CONFLICTS");
    AvailabilityBroadcast.unsubscribe("OPEN_TRAVELING_MODAL");
    AvailabilityBroadcast.unsubscribe("CLOSE_AVAILABILITY_PANEL_MODAL");
    AvailabilityBroadcast.unsubscribe("UPDATE_SINGLE_ATTENDEE");
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.SET_GENERIC_SLOTS_WARNING);
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.REMOVE_SLOTS_BEFORE_AFTER_CONFLICT_WARNING);
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.SET_SLOTS_STATE_AND_PROPS);
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.ON_CLICK_GROUP_VOTE_EDIT);
    AvailabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_DESCRIPTION);
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.UPDATE_SLOTS_TYPE);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let hasChangedText = false;
    if (
      this.props.currentTimeZone !== prevProps.currentTimeZone ||
      this.state.contentShowsAllTimeZones !== prevState.contentShowsAllTimeZones || // Update when toggling "Show every time zone"
      this.state.textStyles !== prevState.textStyles // Update when toggling text style like bold date
    ) {
      this.createTextFromSelection();
      hasChangedText = true; // so we don't update text twice
    }

    if ((isDayView(this.props.selectedCalendarView) || this.props.appSettings.isSplitView)
      && !isEmptyArrayOrFalsey(this.props.temporaryEvents)
      && isEmptyArrayOrFalsey(prevProps.temporaryEvents)
    ) {
      // going from zero events to one
      this.checkForUpdateAccountOnDayViewDrag();
    }

    // Upon selecting a new user, clear out the temporary outstanding slot events from the previous user.
    if (
      this.state.selectedUserIndex !== prevState.selectedUserIndex &&
      this.props.temporaryEvents?.length > 0
    ) {
      const filteredTemporaryEvents = this.props.temporaryEvents.filter(event => !event.slotSlug);
      mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", filteredTemporaryEvents);
    }

    const updatedState = {};

    const updatedCriticalAlert = this.determineCriticalAlert();
    if (
      this.props.temporaryEvents !== prevProps.temporaryEvents ||
      this.state.duration !== prevState.duration ||
      this.state.slotsType.value !== prevState.slotsType.value ||
      this.state.blockedCalendarsID !== prevState.blockedCalendarsID ||
      this.state.isIgnoreConflicts !== prevState.isIgnoreConflicts ||
      this.props.temporaryTimeZones !== prevProps.temporaryTimeZones
    ) {
      if (!hasChangedText) {
        this.createTextFromSelection();
      }

      const warningAlert = this.determineWarningAlert();
      if (!this.isSameWarning(warningAlert, this.state.warningAlert) && !isWarningAllDaySlotsWarning(this.state.warningAlert)) {
        updatedState["warningAlert"] = warningAlert;
      }
      if (!this.isSameWarning(updatedCriticalAlert, this.state.criticalAlert)) {
        updatedState["criticalAlert"] = updatedCriticalAlert;
      }
    }

    if (this.state.selectedCalendar !== prevState.selectedCalendar
      || this.state.blockedCalendarsID !== prevState.blockedCalendarsID
      || this.props.temporaryEvents !== prevProps.temporaryEvents
      || this.state.isIgnoreConflicts !== prevState.isIgnoreConflicts
    ) {
      const {
        blockedCalendarsID,
        selectedCalendar,
      } = this.state;
      const selectedCalendarUserCalendarID = getCalendarUserCalendarID(selectedCalendar);
      const shouldDisplayDoubleBookWarning = blockedCalendarsID?.length > 0 &&
        !blockedCalendarsID.includes(selectedCalendarUserCalendarID) &&
        !this.isSlotsPlainText() &&
        !this.state.isIgnoreConflicts;
      if (shouldDisplayDoubleBookWarning) {
        updatedState["criticalAlert"] = {
          title: RISK_OF_DOUBLE_BOOKING_TITLE,
          subText: `Please scroll down to "Check for conflicts" inside "Meeting details" and make sure "${this.getSelectedCalendarDisplayName()}" is selected to prevent the recipient from booking a meeting where you have conflicts.`
        };
      } else if (updatedCriticalAlert?.title === RISK_OF_DOUBLE_BOOKING_TITLE
        && !shouldDisplayDoubleBookWarning
      ) {
        updatedState["criticalAlert"] = null;
      } else if (updatedCriticalAlert) {
        updatedState["criticalAlert"] = updatedCriticalAlert;
      } else {
        updatedState["criticalAlert"] = null;
      }
    }

    if (
      this.state.duration !== prevState.duration ||
      this.state.attendees !== prevState.attendees ||
      this.state.optionalInviteeEmail !== prevState.optionalInviteeEmail ||
      this.state.optionalInviteeFullName !== prevState.optionalInviteeFullName
    ) {
      const updatedSlug = this.createSlug({});
      if (updatedSlug !== this.state.slug) {
        updatedState["slug"] = updatedSlug;
      }
    }
    if (this.props.eventFormEmails !== prevProps.eventFormEmails) {
      updatedState["isMeetingDetailsExpanded"] = true;
    }

    // Upon archiving the last outstanding slot, close the modal.
    if (this.state.isOutstandingSlotsSectionOpen && !this.hasPreviousSlots()) {
      updatedState["isOutstandingSlotsSectionOpen"] = false;
    }

    if (this.state.blockedCalendarsID !== prevState.blockedCalendarsID) {
      this.getCheckForConflictBlockedEvents(); // refetch
    }

    /* Check if it's the first state where we open personal links */
    // if (
    //   this.isPersonalLink() &&
    //   !(prevState?.availabilityType?.value === BACKEND_PERSONAL_LINK)
    // ) {
    //   /* Check if the tooltip has been completed */
    //   const { masterAccount } = this.props.masterAccount;
    //   if (!isTooltipCompleted(masterAccount, tooltipKeys.PERSONAL_LINKS)) {
    //     Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.PERSONAL_LINKS);
    //   }
    // }
    if (this.props.groupVoteStore.groupSpreadsheetLinks !== prevProps.groupVoteStore.groupSpreadsheetLinks || 
      this.props.groupVoteStore.groupVoteLinks !== prevProps.groupVoteStore.groupVoteLinks
    ) {
      const sortedGroupVotes = this.getSortedGroupVoteLinks();
      updatedState.sortedGroupLinks = sortedGroupVotes;
      updatedState.activeGroupVotes = getActiveGroupVotes(sortedGroupVotes);
    }
    if (this.state.isIgnoreInternalConflicts && isEmptyArray(getInternalDomainAndEmails({
      masterAccount: this.props.masterAccount.masterAccount,
      user: this.getSelectedUser(),
    }))) {
      updatedState.isIgnoreInternalConflicts = false;
    }

    if (isEmptyArrayOrFalsey(prevProps.temporaryEvents) 
      && !isEmptyArrayOrFalsey(this.props.temporaryEvents)
      && !isEmptyArrayOrFalsey(this.state.attendees)
    ) {
      // If we have attendees, we need to expand the meeting details section
      updatedState.isMeetingDetailsExpanded = true;
    }

    if (!isEmptyObjectOrFalsey(updatedState)) {
      this.setState(updatedState);
    }
  }

  toggleShowCustomQuestions() {
    this.setState({ showCustomQuestions: !this.state.showCustomQuestions });
  }

  toggleShowSlotsHoldForm() {
    const updatedSlotsHoldForm = !this.state.showSlotsHoldForm;
    this.setState({ showSlotsHoldForm: updatedSlotsHoldForm }, () => {
      if (updatedSlotsHoldForm && this.isSlotsPlainText()) {
        if (this.state.eventTitle) {
          Broadcast.publish(`UPDATE_EVENT_INPUT_VALUE_${CONTAINER_ID}`, this.state.eventTitle);
        } else {
          Broadcast.publish(`REMOVE_EVENT_INPUT_VALUE_${CONTAINER_ID}`);
        }
      }
    });
  }

  render() {
    const {
      isIgnoreConflicts,
      duration,
      blockedCalendarsID,
      blockedAttendeesCalendars,
      attendees,
      shouldDisplayModal,
      isIgnoreInternalConflicts,
    } = this.state;
    return (
      <div
        id={AVAILABILITY_PANEL_ID}
        className={classNames(
          "create-availability-container",
          this.state.page === EDIT_LINK_DETAIL ? "overflow-auto-override" : "",
          calculateMarginTopClassname(this.props.shouldShowTopBar)
        )}
      >
        <div ref={this.topRef}></div>
        {this.determineRender()}

        <GrabAvailableSlotsContainer
          blockedCalendarsID={isIgnoreConflicts ? [] : blockedCalendarsID}
          duration={duration}
          user={this.getSelectedUser()}
          blockedAttendeeEmails={isIgnoreConflicts ? [] : blockedAttendeesCalendars}
          attendees={isIgnoreConflicts ? [] : attendees}
          isIgnoreConflicts={isIgnoreConflicts}
          isIgnoreInternalConflicts={isIgnoreInternalConflicts}
        />

        <EventModalPopup
          isOpen={shouldDisplayModal}
          onRequestClose={this.closeModal}
          width={this.getEventModalWidth()}
          title={this.getEventModalTitle()}
          style={this.getEventModalStyle()}
          hideTitle={this.isSelectSlotsStyleModal() || this.isSelectSlotsTextFormatModal()}
          hideCloseButton={this.isSelectSlotsStyleModal() || this.isSelectSlotsTextFormatModal()}
        >
          {this.getEventModalContent()}
        </EventModalPopup>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================


  determineRender() {
    if (this.state.page === DEFAULT_PAGE) {
      return this.renderDefaultPage();
    } else if (this.state.page === EDIT_GROUP_VOTE_DETAIL) {
      return (
        <GroupVoteDetailPage
          isNew={this.state.isCreateNewGroupVoteLink}
          onClickBack={() => this.setState({ page: DEFAULT_PAGE })}
          bookingLink={this.state.groupVoteInfo}
          selectedUser={this.getSelectedUser()}
        />
      );
    } else if (this.state.page === EDIT_GROUP_SPREADSHEET_DETAIL) {
      return (
        <GroupVoteDetailPage
          isNew={this.state.isCreateNewGroupVoteLink}
          onClickBack={() => this.setState({ page: DEFAULT_PAGE })}
          bookingLink={this.state.groupVoteInfo}
          selectedUser={this.getSelectedUser()}
          variant="spreadsheet"
        />
      );
    } else if (this.state.page === EDIT_LINK_DETAIL) {
      return (
        <PersonalLinkDetailPage
          selectedUser={this.getSelectedUser()}
          isNew={this.state.isCreateNewPersonalLink}
          onClickBack={() => this.setState({ page: DEFAULT_PAGE })}
          personalLinkInfo={this.state.personalLinkInfo}
        />
      );
    } else {
      return this.renderDefaultPage();
    }
  }

  onClickEdit(bookingLink) {
    const isSpreadsheet = isSpreadsheetGroupLink(bookingLink);
    this.scrollToTopOfPage();

    this.setState({
      page: isSpreadsheet ? EDIT_GROUP_SPREADSHEET_DETAIL : EDIT_GROUP_VOTE_DETAIL,
      isCreateNewGroupVoteLink: false,
      groupVoteInfo: bookingLink,
    });
  }

  renderDefaultPage() {
    const onClickCopy = (bookingLink) => {
      if (isEmptyObjectOrFalsey(bookingLink)) {
        return;
      }
      const isSpreadsheet = isSpreadsheetGroupLink(bookingLink);
      const {
        slug,
        token,
      } = bookingLink;
      const userName = this.getAvailabilityUserName();
      const groupVoteURLLink = getGroupVoteURLLink({
        userName,
        slug,
        token,
        isSpreadsheet,
      });
      navigator.clipboard.writeText(groupVoteURLLink).then(
        () => {
          if (!this._isMounted) {
            return;
          }

          /* clipboard successfully set */
          Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
          setLastestAvailabiltyType(this.getSelectedUser(), true);

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            bookingLink.title
              ? `Copied link for "${bookingLink.title}" to clipboard`
              : "Copied link to clipboard",
          );
        },
        function () {
          /* clipboard write failed */
          Broadcast.publish("SHOW_ERROR_MESSAGE");
        }
      );
    };
    const onClickPreview = (bookingLink) => {
      AvailabilityBroadcast.publish("DISPLAY_GROUP_VOTE_LINK_PREVIEW_MODAL", {
        ...bookingLink,
        isGroupVoteLink: true,
      });
    };

    const onClickDuplicate = (bookingLink) => {
      const isSpreadsheet = isSpreadsheetGroupLink(bookingLink);
      this.setState({
        page: isSpreadsheet ? EDIT_GROUP_SPREADSHEET_DETAIL : EDIT_GROUP_VOTE_DETAIL,
        isCreateNewGroupVoteLink: true,
        groupVoteInfo: _.omit(bookingLink, ["attendees", "token"]),
      });
    };

    const onClickDelete = (bookingLink) => {
      if (!bookingLink?.token) {
        return;
      }

      const isSpreadsheet = isSpreadsheetGroupLink(bookingLink);

      const token = bookingLink.token;
      const path = isSpreadsheet
        ? `${GROUP_SPREADSHEET_LINKS_ENDPOINT}/${token}`
        : `${LATEST_GROUP_VOTE_LINKS}/${token}`;
      const url = constructRequestURL(path, isVersionV2());

      Fetcher.delete(url, {}, true, getUserEmail(this.getSelectedUser()))
        .then((response) => {
          if (this.shouldHaltProcess(response) || !response.success) {
            return;
          }

          if (isSpreadsheet) {
            const { removeGroupSpreadsheetLinkByToken } = this.props.groupVoteStore;
            removeGroupSpreadsheetLinkByToken(token);
          } else {
            const { removeGroupVoteLinkByToken } = this.props.groupVoteStore;
            removeGroupVoteLinkByToken(token);
          }

          if (!isEmptyArrayOrFalsey(response?.deleted_events)) {
            mainCalendarBroadcast.publish(
              MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS,
              {
                userEventIDs: response?.deleted_events?.map(event => getEventUserEventID(event)),
              },
            );
          }
        })
        .catch(handleError);
    };

    const determineChildren = () => {
      if (this.isGroupVotePage()) {
        const {
          sortedGroupLinks,
          activeGroupVotes,
        } = this.state;

        return (
          <>
            {activeGroupVotes?.length > 0 ? (
              <div className="flex justify-end mt-2.5 mr-5">
                <GroupVoteCreateButton />
              </div>
            ) : null}
            <GroupVoteListView
              groupVoteLinks={sortedGroupLinks}
              onEdit={this.onClickEdit}
              onCopyLink={onClickCopy}
              onPreview={onClickPreview}
              onDuplicate={onClickDuplicate}
              onDelete={onClickDelete}
              selectedUser={this.getSelectedUser()}
            />
          </>
        );
      } else {
        return (
          <>
            {this.renderHeaderTools()}
            <div
              className={classNames(
                "select-availability-content-layout-wrapper"
              )}
              id={this.determineContentId()}
            >
              {this.renderContent()}
            </div>
          </>
        );
      }
    };

    return (
      <div className="create-availability-wrapper">
        {this.renderTitleAndCloseButton()}
        {this.renderSelectType()}

        {determineChildren()}
      </div>
    );
  }

  renderTooltip({ message, shortcut, width, left }) {
    const { isDarkMode } = this.props;
    return (
      <div
        className={classNames(
          "p-4 default-font-size rounded-lg",
          "absolute top-10",
          "z-10",
          isDarkMode
            ? "setting-hover-tool-tip-background-color"
            : "bg-gray-100 box-shadow-6"
        )}
        style={{width, left}}
      >
        <div className="flex w-full justify-between">
          <div className="leading-5 mr-2">{message}</div>
          {shortcut ? (
            <div className="display-flex-center margin-right-5 font-size-300">
              <ShortcutTile shortcut={shortcut} width="max-content" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderBottomTooltips() {
    const { isDarkMode } = this.props;

    const { hoveringToolTip, isLoadingFindTimes } = this.state;

    const getAILogoFillColor = () => {
      if (isLoadingFindTimes) {
        return "transparent";
      }
      return isDarkMode ? undefined : DEFAULT_FONT_COLOR;
    };
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center default-font-size mt-2.5">
          <div
            className="flex items-center bottom-tooltip-container select-none duration-200 relative mr-2"
            onClick={this.getUpcomingSlotsAvailability}
            id={GET_UPCOMING_WEEK_OPENINGS_MESSAGE}
            onMouseLeave={this.removeHoverTooltip}
            onMouseEnter={() =>
              this.setState({ hoveringToolTip: HOVERING_TOOL_TIP.FIND_TIMES })
            }
          >
            {isLoadingFindTimes 
              ? <div className="width-13px"><SpinnerV2 variant="smaller" /></div>
              : <AILogo fillColor={getAILogoFillColor()} />
            }
            <div className="ml-2">Find times</div>
            {hoveringToolTip === HOVERING_TOOL_TIP.FIND_TIMES
              ? this.renderTooltip({ 
                  message: "Let Vimcal AI select open Slots for you and any teammates you invite as attendees based on all your preferences", 
                  shortcut: "Shift A",
                  width: "335px",
                  left: "-10px" 
                })
              : null}
          </div>

          {this.isUserMaestroUser()
            ? this.renderMaestroPreviousSlotsButton()
            : this.renderNonMaestroPreviousSlotsButton()}
        </div>

        {this.renderTemplateAndSettingIcons()}
      </div>
    );
  }

  renderNonMaestroPreviousSlotsButton() {
    const hasPreviousSlots = this.hasPreviousSlots();
    const { hoveringToolTip } = this.state;

    return (
      <div
        className={classNames(
          "flex items-center bottom-tooltip-container select-none duration-200 relative",
          hasPreviousSlots ? "" : "disabled-bottom-tooltip",
        )}
        id={PREVIOUSLY_SELECTED_SLOTS_MESSAGE}
        onClick={
          hasPreviousSlots ? this.getPreviouslyUsedSlotsAndTimeZone : _.noop
        }
        onMouseLeave={this.removeHoverTooltip}
        onMouseEnter={() =>
          this.setState({
            hoveringToolTip: HOVERING_TOOL_TIP.PREVIOUSLY_SELECTED_SLOTS,
          })
        }
      >
        <CornerUpLeft size={14} />
        <div className="ml-2">Use past Slots</div>
        {hasPreviousSlots && hoveringToolTip === HOVERING_TOOL_TIP.PREVIOUSLY_SELECTED_SLOTS
          ? this.renderTooltip({
              message: "Quickly resurface the last set of Slots you copied and the duration of the meeting. This is great if you want to resend the same times out.",
              shortcut: "Enter",
              width: "329px",
              left: "-134px",
            })
          : null}
      </div>
    );
  }

  renderMaestroPreviousSlotsButton() {
    const hasPreviousSlots = this.hasPreviousSlots();
    const { isOutstandingSlotsSectionOpen } = this.state;

    const onClick = () => {
      this.setState({ isOutstandingSlotsSectionOpen: !isOutstandingSlotsSectionOpen });
    };

    return (
      <div
        className={classNames(
          "flex items-center bottom-tooltip-container select-none duration-200 relative",
          hasPreviousSlots ? "" : "disabled-bottom-tooltip",
          isOutstandingSlotsSectionOpen ? "bottom-tooltip-container-active" : "",
        )}
        id={PREVIOUSLY_SELECTED_SLOTS_MESSAGE}
        onClick={
          hasPreviousSlots ? onClick : _.noop
        }
        onMouseLeave={this.removeHoverTooltip}
        onMouseEnter={() =>
          this.setState({
            hoveringToolTip: HOVERING_TOOL_TIP.PREVIOUSLY_SELECTED_SLOTS,
          })
        }
      >
        <div className="ml-2">Previously sent</div>
        <ChevronDown
          size={12}
          className={classNames(
            "ml-1 duration-300 transform",
            isOutstandingSlotsSectionOpen ? "rotate-180" : "",
          )}
        />
      </div>
    );
  }

  renderPreview() {
    const { isDarkMode } = this.props;
    const {
      contentShowsAllTimeZones,
    } = this.state;

    const allTimeZones = this.getAllTimeZones();
    const renderToggle = () => {
      if (allTimeZones?.length <= 1) {
        return null;
      }
      return (
        <ShortcutHoverHint
          above
          style={{
            top: "-30px",
            right: "0px",
            width: "max-content",
            zIndex: 11,
          }}
          title={SHOW_EVERY_SELECTED_TIME_ZONE_COPY}
        >
        <DefaultSwitch
          isChecked={contentShowsAllTimeZones}
            onChange={(val) => {
              this.setState({ contentShowsAllTimeZones: val });
              const {
                setShouldShowAllTimeZonesInSlots
              } = this.props.appSettings;
              setShouldShowAllTimeZonesInSlots(val);
            }}
            additionalClassNames={"mt-1"}
        />
        </ShortcutHoverHint>
      );
    };

    if (this.shouldDisplaySlotsContent()) {
      return (
        <div
          className={classNames(
            "select-availability-panel-content-wrapper",
            this.shouldReduceFontSizeForPreview() ? "font-size-10" : ""
          )}
          style={{
            width: "320px",
            minHeight: this.isLinkAlone() ? "inherit" : null,
            ...getAvailabilityPanelPreviewStyle({isDarkMode})
          }}
        >
          <div id={AVAILABILITY_TEXT_CONTENT}>
            {this.renderTextPreview()}
          </div>
          <ColoredLine
            style={{
              border: isDarkMode ? "white" : `1px solid ${LIGHT_MODE_AVAILABILITY_BORDER_COLOR}`,
              marginLeft: "-10px",
              width: "318px",
            }}
            inputClassName="my-4"
          />
          <div className="pb-1 flex justify-between">
            {this.renderTimeZoneSection()}
            {renderToggle()}
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center w-full">
        <EmptyStatePanel isDarkMode={isDarkMode} />
      </div>
    );
  }

  getAllTimeZones() {
    const leftHandTimeZone = this.getLeftHandTimeZone();
    const { temporaryTimeZones, currentTimeZone } = this.props;
    if (isEmptyArrayOrFalsey(temporaryTimeZones)) {
      return [currentTimeZone];
    }
    return removeDuplicatesFromArray([]
      .concat(addDefaultToArray(temporaryTimeZones))
      .concat(addDefaultToArray(currentTimeZone))
      .concat(addDefaultToArray(leftHandTimeZone)),
    );
  }

  renderTimeZoneSection() {
    const { temporaryTimeZones, currentTimeZone } = this.props;
    const {
      contentShowsAllTimeZones,
    } = this.state;
    const leftHandTimeZone = this.getLeftHandTimeZone();

    const allTimeZones = this.getAllTimeZones();
    const sortedTimeZones = sortMultipleTimeZonesForSlots({ timeZones: allTimeZones });

    const shouldTZBeHighlighted = (tz) => {
      if (tz === currentTimeZone) {
        return true;
      }
      if (!contentShowsAllTimeZones) {
        return false;
      }
      return true;
    };

    const shouldShowCancelButton = (tz) => {
      return temporaryTimeZones?.includes(tz) && tz !== leftHandTimeZone;
    };

    const removeTimeZone = (tz, e) => {
      removeSearchedTimeZone({
        timeZone: tz,
        currentTimeZone,
        temporaryTimeZones,
        event: e,
        leftHandTimeZone,
      });
    };

    return (
      <div className="flex flex-wrap gap-2 items-center w-60">{
        sortedTimeZones.map((tz) => {
          return (
            <SlotsTimeZoneBlock
              date={this.getFirstSlotsStartDate()}
              showCancelButton={shouldShowCancelButton(tz)}
              key={tz}
              timeZone={tz}
              isFilled={shouldTZBeHighlighted(tz)}
              onClick={(e) => {
                hasEventPreventDefault(e);
                hasStopEventPropagation(e);
                if (shouldTZBeHighlighted(tz)) {
                  if (sortedTimeZones.length === 1) {
                    // if only one time zone
                    broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
                    return;
                  }
                }
                Broadcast.publish("SELECT_TIME_ZONE", {
                  timeZone: tz,
                });
              }}
              onClickCancelButton={(e) => {
                removeTimeZone(tz, e);
              }}
            />
          );
        })}
        <ShortcutHoverHint
          below
          style={{
            width: "max-content",
            zIndex: 1,
            top: "16px",
          }}
          title={"Time Travel"}
          shortcut={"Z"}
          containerStyle={{
            display: "flex",
            alignItems: "center",
          }}
          showBorder={true}
        >
          <Plus
            size={16}
            className="clickable-icon hoverable-secondary-text-color"
            strokeWidth={2}
            onClick={() => {
            broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE")
          }}
        />
        </ShortcutHoverHint>
      </div>
    );
  }

  renderShowTemplates() {
    const { masterAccount } = this.props.masterAccount;
    if (isEmptyArrayOrFalsey(getMasterAccountTemplates({ masterAccount }))) {
      return null;
    }

    return (
      <div onClick={this.onClickTemplate}>
        <ShortcutHoverHint
          above
          style={{
            bottom: "25px",
            right: "0px",
            zIndex: 11,
          }}
          title={"Template"}
          shortcut={`${getActiveCommandCentersKey()} ;`}
          shouldShow={true}
        >
          <FilePlus
            size={16}
            className="clickable-icon mr-2.5"
            strokeWidth={1}
          />
        </ShortcutHoverHint>
      </div>
    );
  }

  renderTemplateAndSettingIcons() {
    return (
      <div className="flex justify-between mt-2.5">
        <div className="ml-5"></div>

        <div className="flex justify-end items-center">
          {this.isSlotsPlainText() ? (
            <div id={SLOTS_TEXT_SELECTION} />
          ) : (
            this.renderShowTemplates()
          )}

          <div onClick={this.onClickSettings}>
            <ShortcutHoverHint
              above
              style={{
                bottom: "25px",
                right: "0px",
                width: "max-content",
                zIndex: 11,
              }}
              title={"Default Slots settings"}
            >
              <Settings size={16} className="clickable-icon" strokeWidth={1} />
            </ShortcutHoverHint>
          </div>
        </div>
      </div>
    );
  }

  renderSlotsTooltip() {
    if (this.state.shouldHideSlotsTooltip) {
      return null;
    }

    return (
      <div className="px-5 pt-5">
        <TooltipBox
          description="You can click the gear icon to change the default text, duration, title, or any other field when sending Slots."
          hideLearnMore={true}
          onClick={(e) => {
            hasEventPreventDefault(e);
            hasStopEventPropagation(e);
            this.setState({
              shouldHideSlotsTooltip: true,
            });
          }}
          title="Did You Know?"
        />
      </div>
    );
  }

  onClickCreate() {
    if (this.isGroupVotePage()) {
      this.addGroupVoteLink();
    } else {
      this.addNewPersonalLink();
    }
  }

  renderCreateButton() {
    const isPrimaryButton = () => {
      if (this.isPersonalLink()) {
        return this.isPersonalLinksEmpty();
      } else if (this.isGroupVotePage()) {
        // group vote
        const { groupSpreadsheetLinks, groupVoteLinks } = this.props.groupVoteStore;
        const allGroupLinks = [...groupSpreadsheetLinks, ...groupVoteLinks];
        return isEmptyArray(allGroupLinks);
      }

      return false;
    };

    return (
      <CustomButton
        buttonType={isPrimaryButton()}
        onClick={this.onClickCreate}
        label="Create"
        shouldRenderPlusButton={true}
        labelClassNameOverride={"font-size-300-important"}
        className="border-radius-6px-important"
      />
    );
  }

  isPersonalLinksEmpty() {
    const { personalLinks } = this.state;
    return isEmptyArrayOrFalsey(personalLinks);
  }

  renderChangeTimeZoneOrCreateNewLink() {
    if (this.isGroupVotePage() || this.isPersonalLink()) {
      const { groupSpreadsheetLinks, groupVoteLinks } = this.props.groupVoteStore;
      const allGroupLinks = [...groupSpreadsheetLinks, ...groupVoteLinks];
      if (
        this.isGroupVotePage() &&
        (isEmptyArray(allGroupLinks))
      ) {
        // do not render group vote if there's no group vote
        return null;
      }

      if (this.isPersonalLink() && this.shouldShowPersonalLinkAnimation()) {
        return null;
      }

      return this.renderCreateButton();
    } else if (this.props.temporaryEvents?.length > 0) {
      return this.renderChangeTimeZone();
    }

    return null;
  }

  renderChangeTimeZone() {
    return (
      <div
        className={classNames(
          "cursor-pointer",
          "hoverable-secondary-text-color",
          "default-font-size",
          "select-none"
        )}
        onClick={this.changeTimeZone}
      >
        Change time zone
      </div>
    );
  }

  renderTitleAndCloseButton() {
    return (
      <div
        className={classNames(
          "select-availability-panel-wrapper items-center",
          this.shouldRenderSelectAccount() ? "margin-top-0px-override" : ""
        )}
      >
        <div className="create-availability-select-availability-title">
          Select Availability
        </div>

        <div className="flex items-center">
          {this.renderSelectAccounts()}
          {this.renderCloseButton()}
        </div>
      </div>
    );
  }

  renderCloseButton() {
    return (
      <ShortcutHoverHint
        left
        style={closeShortCutHint}
        title={"Close"}
        shortcut={"Esc"}
      >
        <GlobalKeyMapTile style={closeButtonTile} shortcut={"Esc"} />

        <div className="close-button-wrapper" onClick={this.pressX}>
          <X color="#707070" size={20} className="clickable-icon" />
        </div>
      </ShortcutHoverHint>
    );
  }

  renderCustomQuestions() {
    return (
      <div className="mt-5">
        <div
          className="select-availability-panel-content-wrapper mt-2.5 width-320px"
          onClick={() => this.onClickSettings(2)}
        >
          {this.renderEventCustomQuestionsContent()}

          {this.renderEditPen()}
        </div>
      </div>
    );
  }

  renderCustomQuestionsHeader() {
    if (this.isSlotsPlainText()) {
      return null;
    }
    if (!this.state.isMeetingDetailsExpanded) {
      return null;
    }

    return (
      <div id={MEETING_DETAIL_ID}>
        <div
          className="display-flex items-center create-availability-panel-preview-text mt-4 align-items-center"
          onClick={this.toggleShowCustomQuestions}
        >
          {this.state.showCustomQuestions ? (
            <>
              <Minus
                size={12}
                id={HIDE_SHOW_AVAILABILITY_DETAILS}
                className="mr-3 mb-0.5"
              />
              Hide Custom Questions
            </>
          ) : (
            <>
              <Plus size={12} className="mr-3 mb-0.5" />
              Show Custom Questions
            </>
          )}
        </div>
      </div>
    )
  }

  renderSlotsHoldForm() {
    const onChangeCategories = (selectedOutlookCategories) => {
      this.setState({
        selectedOutlookCategories,
      });
    };

    return (
      <SlotsHoldsSection
        attendees={this.state.attendees}
        onKeyDownSelect={this.onKeyDownSelect}
        onChangeSlotsHoldTitle={this.onChangeSlotsHoldTitle}
        onChangeHoldsColorID={this.onChangeHoldsColorID}
        onChangeShouldAddAttendeesToHolds={
          this.onChangeShouldAddAttendeesToHolds
        }
        overrideColorID={this.state.slotHoldOverrideColorID}
        selectedCalendar={this.state.selectedCalendar}
        shouldAddAttendeesToHolds={this.state.shouldAddAttendeesToHolds}
        title={this.state.slotsHoldTitle}
        selectedUser={this.getSelectedUser()}
        isDisabled={!this.state.showSlotsHoldForm}
        onChangeCategories={onChangeCategories}
        selectedOutlookCategories={this.state.selectedOutlookCategories}
      />
    );
  }

  renderEditPen() {
    return (
      <div className="edit-pen">
        <Edit2 size={18} className="clickable-icon" />
      </div>
    );
  }

  onClickBack() {
    this.setState({ page: DEFAULT_PAGE });
  }

  showExpandedPersonalizeSection() {
    this.setState({
      shouldShowExpandedPersonalizeSection: true,
      showPersonalizeScreenshot: false,
    });
  }

  renderEventCustomQuestionsContent() {
    const customQuestions = this.getUserCustomQuestions();
    return (
      <div>
        {customQuestions.map(
          (question, index) => {
            return (
              <div
                key={`custom_quesion_index_${index}`}
                className="personal-link-event-detail-info"
              >
                <span>{"\u2022"}</span>

                <span>&nbsp;</span>
                {question.description}
                {question.required ? " *" : ""}
              </div>
            );
          }
        )}
      </div>
    );
  }

  renderPersonalizeSlotsSection() {
    if (!this.shouldShowSlotsDetailContent()) {
      return null;
    }
    const {
      isPersonalizeSectionExpanded,
      optionalInviteeName,
      optionalInviteeEmail,
    } =
      this.state;
    const onClickToggle = () => {
      this.updateDefaultOpenSections(SLOTS_SECTIONS.PERSONALIZE_INVITE);
      this.setState({ isPersonalizeSectionExpanded: !isPersonalizeSectionExpanded });
    };
    const createPreviewText = () => {
      if (optionalInviteeName && optionalInviteeEmail) {
        return `${optionalInviteeName} <${optionalInviteeEmail}>`;
      }
      if (optionalInviteeName) {
        return optionalInviteeName;
      }
      if (optionalInviteeEmail) {
        return optionalInviteeEmail;
      }
      return "None";
    };

    return (
      <div>
        <div
          className={classNames("default-font-size font-weight-400", "flex items-center justify-between", "cursor-pointer select-none")}
          onClick={onClickToggle}
        >
          Personalize invite
          <ExpandIconWithAnimation isOpen={isPersonalizeSectionExpanded} />
        </div>

				{isPersonalizeSectionExpanded ?
          <PersonalizeSlotsSection
            optionalInviteeName={this.state.optionalInviteeName}
            onChangeInviteeName={this.onChangeInviteeName}
            optionalInviteeEmail={this.state.optionalInviteeEmail}
            onChangeInviteeEmail={this.onChangeInviteeEmail}
            selectedUser={this.getSelectedUser()}
          />
          : this.renderSectionPreview({ text: createPreviewText(), onClick: onClickToggle })
        }

        {this.renderSolidLine()}
      </div>
    );
  }

  shouldHideAttendeesSwitch() {
    return this.isSlotsPlainText();
  }

  shouldDisableAttendeesSwitchAndSetToFalse() {
    if (this.isSlotsPlainText()) {
      return false;
    }
    return this.state.isIgnoreConflicts;
  }

  renderOptionalDetails() {
    if (!this.shouldShowSlotsDetailContent()) {
      return this.renderOnlyAttendees();
    }
    const {
      isMeetingDetailsExpanded,
      duration,
      conferencing,
      location,
      attendees,
      showCustomQuestions,
      selectedCalendar,
    } = this.state;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      emailToNameIndex
    } = this.props;

    const getWhereText = () => {
      if (conferencing?.value === NO_CONFERENCING_OPTION || conferencing?.value === "none") {
        if (location?.trim().length > 0) {
          return `${location}`;
        }
        return "No conferencing";
      }
      if (conferencing?.label) {
        return `${conferencing?.label}`;
      }
      return "No conferencing";
    };

    const createPreviewText = () => {
      const durationText = getHumanReadableTimeInMinutes(duration);
      const whereText = getWhereText();
      const numberOfInvitees = attendees?.length || 0;
      return `${durationText}, ${whereText}, ${numberOfInvitees} ${pluralize(numberOfInvitees, "invitee")}`;
    };

    const createCalendarPreview = () => {
      const calendarLabel = getCalendarName({
        calendar: selectedCalendar,
        emailToNameIndex,
        currentUser: this.getSelectedUser(),
        masterAccount,
      });
      return `Calendar: ${calendarLabel}`;
    };

    const onClickToggleSection = () => {
      this.updateDefaultOpenSections(SLOTS_SECTIONS.MEETING_DETAILS);
      this.setState({ isMeetingDetailsExpanded: !isMeetingDetailsExpanded });
    };

    return (
      <div>
        <div
          className={classNames("default-font-size font-weight-400", "flex items-center justify-between", "cursor-pointer select-none")}
          onClick={onClickToggleSection}
        >
          Meeting details
          <ExpandIconWithAnimation isOpen={isMeetingDetailsExpanded} />
        </div>
        {isMeetingDetailsExpanded ?
          <AvailabilityAdditionalDetails
            containerClassName={classNames(isMeetingDetailsExpanded ? "" : "h-0 overflow-hidden opacity-0")}
            showFindTimesForEveryone={
              this.state.blockedAttendeesCalendars?.length > 0
            }
            onClickFindTimesForEveryone={this.getUpcomingSlotsAvailability}
            hideInitDescription={true}
            isSlots={true}
            hideReschedulingOption={true}
            searchForContact={true}
            optionalInviteeName={this.state.optionalInviteeName}
            onChangeInviteeName={this.onChangeInviteeName}
            optionalInviteeEmail={this.state.optionalInviteeEmail}
            onChangeInviteeEmail={this.onChangeInviteeEmail}
            onChangeAttendees={this.onChangeAttendees}
            attendees={this.state.attendees}
            location={this.state.location}
            onChangeLocation={this.onChangeLocation}
            onChangeTitle={this.onChangeTitle}
            eventTitle={this.state.eventTitle}
            onSelectMinutes={this.onSelectMinutes}
            duration={this.state.duration}
            minutesQuery={this.state.minutesQuery}
            onDurationInputChange={this.onChangeMinutesInput}
            conferencing={this.state.conferencing}
            onSelectConferencing={this.onSelectConferencing}
            onChangeDescription={this.onChangeDescription}
            description={this.state.description}
            selectedCalendar={this.state.selectedCalendar}
            onChangeCalendar={this.onChangeCalendar}
            descriptionRef={this.description}
            showHideDetails={false}
            containerId={CONTAINER_ID}
            allowReschedule={this.state.allowReschedule}
            onChangeAllowReschedule={this.onChangeAllowReschedule}
            onSelectBlockingCalendars={this.openSelectBlockedCalendarsModal}
            blockedCalendars={this.state.blockedCalendars}
            blockedCalendarsID={this.state.blockedCalendarsID}
            selectedUser={this.getSelectedUser()}
            blockedAttendeesCalendars={this.state.blockedAttendeesCalendars}
            onChangeBlockedAttendeesCalendars={
              this.onChangeBlockedAttendeesCalendars
            }
            customQuestions={this.getUserCustomQuestions()}
            isSlotsPlainText={this.isSlotsPlainText()}
            showOnlyDurationAndAttendees={this.isSlotsPlainText() && !this.state.showSlotsHoldForm}
            isIgnoreConflicts={this.state.isIgnoreConflicts}
            onChangeIgnoreConflicts={this.setIsIgnoreConflicts}
            hideAttendeesSwitch={this.shouldHideAttendeesSwitch()}
            disableAttendeesSwitchAndSetToFalse={this.shouldDisableAttendeesSwitchAndSetToFalse()}
            setUpdateIngoreInternalConflicts={this.setUpdateIngoreInternalConflicts}
            isIgnoreInternalConflicts={this.state.isIgnoreInternalConflicts}
            onChangeUser={this.onChangeUser}
          />
          : <div>
              {this.renderSectionPreview({text: createPreviewText(), onClick: onClickToggleSection})}
              {this.renderSectionPreview({text: createCalendarPreview(), onClick: onClickToggleSection})}
            </div>
        }
        {this.renderCustomQuestionsHeader()}
        {showCustomQuestions && isMeetingDetailsExpanded ? this.renderCustomQuestions() : null}
      </div>
    );
  }

  renderDidYouKnowAlert() {
    const {
      didYouKnowAlert,
    } = this.state;
    if (isEmptyObjectOrFalsey(didYouKnowAlert)) {
      return null;
    }
    const {
      title,
      subText,
    } = didYouKnowAlert;
    if (!title || !subText) {
      return null;
    }
    return <DidYouKnowAlert subText={subText} title={title} />;
  }

  renderWarningAlert() {
    const {
      warningAlert
    } = this.state;
    if (isEmptyObjectOrFalsey(warningAlert)) {
      return null;
    }
    const {
      title,
      subText
    } = warningAlert;
    if (!title || !subText) {
      return null;
    }
    return <WarningAlert subText={subText} title={title} />;
  }

  renderCriticalAlert() {
    const {
      criticalAlert
    } = this.state;
    if (isEmptyObjectOrFalsey(criticalAlert)) {
      return null;
    }
    const {
      title,
      subText
    } = criticalAlert;
    if (!title || !subText) {
      return null;
    }
    return <CriticalAlert subText={subText} title={title} />;
  }

  renderWarnings() {
    return (
      <>
        {this.renderCriticalAlert()}
        {this.renderWarningAlert()}
        {this.renderDidYouKnowAlert()}
      </>
    );
  }

  renderSolidLine() {
    return (
      <div className={classNames("-ml-5 -mr-5", "my-4")}>
        <ColoredLine />
      </div>
    );
  }

  onChangeTextStyles(style) {
    const getUpdatedTextStyles = () => {
      if (this.state.textStyles?.includes(style)) {
        return this.state.textStyles.filter((s) => s !== style);
      }
      return [...this.state.textStyles, style];
    };
    const updatedTextStyles = getUpdatedTextStyles();
    this.setState({ textStyles: updatedTextStyles }, () => {
      setTimeout(() => {
        if (!this._isMounted || !document.getElementById(SLOTS_TEXT_FORMAT_PICKER_ID)) {
          return;
        }
        this.openSelectSlotTextFormatModal(); // reduces bouncing if the preview changes and moves the dropdown container up/down
      }, 0.05 * SECOND_IN_MS);
    });
    const {
      setSlotsTextStyles,
    } = this.props.appSettings;
    setSlotsTextStyles(updatedTextStyles);
  }

  onChangeSlotStyle(type) {
    const getTitle = () => {
      const { masterAccount } = this.props.masterAccount;
      /* Get the default text for slots */
      const defaultText = createDefaultPersonalLinkEventSummary({
        user: this.getSelectedUser(),
        masterAccount,
      });
      /* User has not changed the title */
      /* Check by matching default text to the current title */
      const shouldEmptyText = defaultText === this.state.eventTitle;
      /* Mode is plaintext and above is true -> empty the title */
      if (type.value === SLOTS_PLAIN_TEXT && shouldEmptyText) {
        return "";
      }
      /* User has changed text -> used changed text */
      /* Title is empty and switching off of plaintext -> use the default text */
      return this.state.eventTitle || defaultText
    };

    const {
      setLastSelectedSlotsStyle,
    } = this.props.appSettings;

    this.setState({
      eventTitle: getTitle(),
      slotsType: type,
      warningAlert: type.value === SLOTS_PLAIN_TEXT ? null : this.state.warningAlert,
      criticalAlert: type.value === SLOTS_PLAIN_TEXT ? null : this.state.criticalAlert
    }, () => {
      this.checkAllDayEventsBlocking();
      if (this.isSlotsPlainText()) {
        Broadcast.publish(`REMOVE_EVENT_INPUT_VALUE_${CONTAINER_ID}`);
      } else {
        Broadcast.publish(`UPDATE_EVENT_INPUT_VALUE_${CONTAINER_ID}`, getTitle());
      }
      setLastSelectedSlotsStyle(type?.value);
    });
    this.closeModal();
  }

  renderSlotsTypeSelection() {
    return (
      <div className={classNames("flex items-center justify-between", "slots-row-container-medium")}>
        <div className="default-font-size secondary-text-color">Booking link style</div>
        <CustomDropdownContainer 
          onClick={this.openSelectSlotStyleModal}
          id={SLOTS_STYLE_PICKER_ID}
          displayText={this.state.slotsType.label}
        />
      </div>
    );
  }

  // bolded date, etc
  renderSlotsTextFormat() {
    const {
      textStyles,
    } = this.state;
    if (!shouldShowSlotsTextFormat(this.getSelectedUser())) {
      return null;
    }
    if (this.isLinkAlone()) {
      return null;
    }

    const getDisplayText = () => {
      if (isEmptyArrayOrFalsey(textStyles)) {
        return "Default";
      }
      return "Custom";
    };

    return (
      <div className={classNames("flex items-center justify-between", "slots-row-container-medium", "mt-2")}>
        <div className="default-font-size secondary-text-color">Date & time</div>
        <CustomDropdownContainer
          onClick={this.openSelectSlotTextFormatModal}
          id={SLOTS_TEXT_FORMAT_PICKER_ID}
          displayText={getDisplayText()}
        />
      </div>
    );
  }

  updateDefaultOpenSections(section) {
    const {
      slotsDefaultOpenSections,
      setSlotsDefaultOpenSections,
    } = this.props.accountActivity;
    const updatedSlotsDefaultOpenSections = slotsDefaultOpenSections.includes(section)
      ? slotsDefaultOpenSections.filter((s) => s !== section)
      : [...slotsDefaultOpenSections, section];
    setSlotsDefaultOpenSections(updatedSlotsDefaultOpenSections);
  }

  renderSlotsHoldSection() {
    /* Check if calendar is owner role if belonging to Outlook */
    /* We can't write extended properties to shared calendars */
    if (shouldGateExtendedProperties(this.state.selectedCalendar)) {
      return null;
    }
    const {
      temporaryEvents,
    } = this.props;
    const {
      isHoldsSectionExpanded,
      showSlotsHoldForm,
      attendees,
      shouldAddAttendeesToHolds,
    } = this.state;

    const onClickToggle = () => {
      this.updateDefaultOpenSections(SLOTS_SECTIONS.HOLDS);
      this.setState({ isHoldsSectionExpanded: !isHoldsSectionExpanded });
    };
    const createPreviewText = () => {
      if (showSlotsHoldForm) {
        return "On";
      }
      return "Off";
    };

    const renderHoldsExpanded = () => {
      return (
        <div
          className={classNames(
            "default-font-size",
            "font-weight-300 flex items-center justify-start cursor-pointer",
            "container-hover-icon-visibility",
            isHoldsSectionExpanded ? "mt-4" : ""
          )}
          onClick={this.toggleShowSlotsHoldForm}
        >
          <CheckBox
            className="display-flex justify-content-center create-availability-panel-preview-text align-items-center"
            isChecked={showSlotsHoldForm}
          />
          <div className="ml-2 truncate-text w-max pt-0.5 default-font-size hoverable-secondary-text-color select-none cursor-pointer">
            Create Holds for Slots
          </div>
        </div>
      );
    };

    const shouldShowWarning = isHoldsSectionExpanded
      && attendees?.length > 0 
      && shouldAddAttendeesToHolds 
      && isOutlookUser(this.getSelectedUser())
      && showSlotsHoldForm;
    const getAlertCopy = () => {
      if (temporaryEvents?.length > 0) {
        return "Attendees will receive an email notification when Holds are created.";
      }
      return "Attendees will receive an email notification when a Hold is created.";
    };

    return (
      <div>
        <div
          className={classNames("default-font-size font-weight-400", "flex items-center justify-between", "cursor-pointer select-none")}
          onClick={onClickToggle}
        >
          Holds
          <ExpandIconWithAnimation isOpen={isHoldsSectionExpanded} />
        </div>
        {isHoldsSectionExpanded
          ? renderHoldsExpanded() 
          : this.renderSectionPreview({ text: createPreviewText(), onClick: onClickToggle })
        }
        {isHoldsSectionExpanded ? this.renderSlotsHoldForm() : null}
        {shouldShowWarning 
          ? <Alert
              title=""
              subText={getAlertCopy()}
              className="margin-top-0px-override" 
            /> 
          : null
        }
        {this.renderSolidLine()}
      </div>
    );
  }

  shouldRenderEmbedToggle() {
    return (
      this.state.availabilityType &&
      AVAILABILITY_TYPE_WITH_SWITCH.includes(this.state.availabilityType.value)
    );
  }

  renderHeaderTools() {
    if (this.isPersonalLink()) {
      return null;
    }

    return (
      <>
        {this.renderSlotsTooltip()}
      </>
    );
  }

  /// renders the preview text inside the preview box
  renderTextPreview() {
    if (this.isLinkAlone()) {
      // only show link
      return this.renderPreviewLink();
    } else {
      let slotIndex = 0;
      let timeSlotCount = 0;
      return (
        <>
          {this.state.content.split("\n").map((i, index) => {
            const isTimeText = this.doesTextIncludeTime(i);
            slotIndex = timeSlotCount;

            if (isTimeText) {
              timeSlotCount += 1;
            }

            if (i.length === 0) {
              return (
                <div key={`availability_empty_line_${index}`}>
                  <br />
                </div>
              );
            } else if (this.isHorizontalText()
              && i.includes(" - ")
              && doesStringContainTime(i)
            ) {
              return (
                <div key={`horizontal-text-${index}`}>
                  {this.renderEachLine(i, index, slotIndex, isTimeText)}
                  <br />
                </div>
              );
            } else {
              return this.renderEachLine(i, index, slotIndex, isTimeText);
            }
          })}

          {this.isSlotsPlainTextURL() ? this.renderLinkAsTextOption() : null}
        </>
      );
    }
  }

  shouldReduceFontSizeForPreview() {
    const { contentShowsAllTimeZones } = this.state;

    return (
      contentShowsAllTimeZones &&
      !this.isLinkAlone() &&
      this.getAllExtraTimeZonesForSlots()?.length > 1
    );
  }

  renderContent() {
    if (this.isPersonalLink()) {
      return this.renderPersonalLinkContainers();
    }
    return (
      <div>
        <div id="availability-panel-content">
          <div id={SLOTS_PAGE_ID}>
            {this.renderPreview()}

            {this.renderWarnings()}
            {this.renderBottomTooltips()}

            {this.isUserMaestroUser() ? (
              <OutstandingSlotsSection
                currentTimeZone={this.props.currentTimeZone}
                dateFieldOrder={this.props.dateFieldOrder}
                format24HourTime={this.props.format24HourTime}
                isOpen={this.state.isOutstandingSlotsSectionOpen}
                selectedUser={this.getSelectedUser()}
              />
            ) : null}

            {this.renderStylingSection()}
          </div>

          {this.isSlotsPlainText()
            ? null
            : this.renderPersonalizeSlotsSection()}
          {this.renderOptionalDetails()}
        </div>

        <div className="h-5"></div>
        {this.shouldShowSlotsDetailContent() ? this.renderCopyButton() : null}
      </div>
    );
  }

  renderOnlyAttendees() {
    return (
      <AvailabilityAdditionalDetails
        showHideDetails={false}
        isSlots={true}
        hideReschedulingOption={true}
        searchForContact={true}
        showOnlyDurationAndAttendees={true}
        onChangeAttendees={this.onChangeAttendees}
        attendees={this.state.attendees}
        onChangeBlockedAttendeesCalendars={
          this.onChangeBlockedAttendeesCalendars
        }
        slotsSectionRowClassName={"margin-top-32px-override"}
        selectedUser={this.getSelectedUser()}
      />
    );
  }
  // renders the content once the user has selected slots
  renderStylingSection() {
    const {
      isStylingSectionExpanded,
      textStyles,
    } = this.state;
    if (!this.shouldShowSlotsDetailContent()) {
      return null;
    }
    const onClickToggle = () => {
      this.updateDefaultOpenSections(SLOTS_SECTIONS.STYLING);
      this.setState({ isStylingSectionExpanded: !isStylingSectionExpanded });
    };
    const createPreviewText = () => {
      const slotsTypeLabel = this.state.slotsType.label;
      if (!isEmptyArrayOrFalsey(textStyles)) {
        return `Custom ${slotsTypeLabel}`;
      }
      return slotsTypeLabel;
    };

    return (
      <>
        <div
          className={classNames(
            "default-font-size mt-5 font-weight-400",
            "flex items-center justify-between",
            isStylingSectionExpanded ? "mb-2" : "",
            "cursor-pointer select-non"
          )}
          onClick={onClickToggle}
        >
          Styling
          <ExpandIconWithAnimation isOpen={isStylingSectionExpanded} />
        </div>
        <div>
          {isStylingSectionExpanded 
            ? <>
                {this.renderSlotsTypeSelection()}
                {this.renderSlotsTextFormat()}
              </>
            : this.renderSectionPreview({ text: createPreviewText(), onClick: onClickToggle }) 
          }
        </div>
        {this.renderSolidLine()}
        {this.isUserMaestroUser()
          ? this.renderSlotsHoldSection()
          : null}
      </>
    );
  }

  renderPersonalLinkContainers() {
    const shouldRenderPersonalLinkAnimation =
      this.shouldShowPersonalLinkAnimation();

    return (
      <div>
        <div className="flex justify-end mb-2.5">
          {shouldRenderPersonalLinkAnimation ? null : (
            <div className="flex justify-between items-center w-full">
              {this.renderShowImTravelingButton()}
              {this.renderCreateButton()}
            </div>
          )}
        </div>
        {shouldRenderPersonalLinkAnimation ? null : this.renderShowTravelingBanner()}

        {shouldRenderPersonalLinkAnimation
          ? this.renderPersonalLinksAnimation()
          : this.renderPersonalLinks()}
      </div>
    );
  }

  renderShowTravelingBanner() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const allTrips = getUpcomingTrips({
      user: this.getSelectedUser(),
      masterAccount
    });
    const currentTrip = getCurrentTrip(allTrips);
    if (isEmptyObjectOrFalsey(currentTrip)) {
      return null;
    }
    const FONT_COLOR = "#4066DB";
    const displayEndDate = getMonthDayYearLongForm({
      date: getTripEndDate(currentTrip), 
      dateFormat: this.props.dateFieldOrder, 
      isShortDay: true,
      hideYear: isSameYear(getTripEndDate(currentTrip), new Date())
    });
    return (
      <div
        className="px-5 py-3 rounded-md my-3"
        style={{backgroundColor: "#CAD6FB"}}
      >
        <div
          className="font-weight-500 default-font-size"
          style={{color: FONT_COLOR}}
        >
          Enjoy your trip!
        </div>
        <div
          style={{color: FONT_COLOR, maxWidth: "280px"}}
          className="default-font-size mt-1 break-words"
        >
          {`Your Personal Links are set to ${getTripTimeZoneAbbreviation(currentTrip)} until ${displayEndDate}`}
          <div>
          </div>
        </div>
      </div>
    );
  }

  renderShowImTravelingButton() {
    return (
      <div
        className={classNames(
          "cursor-pointer",
          "hoverable-secondary-text-color",
          "default-font-size",
          "select-none",
        )}
        onClick={this.openTravelingModal}
      >
        {"Set travel dates"}
      </div>
    );
  }

  shouldShowPersonalLinkAnimation() {
    const { masterAccount } = this.props.masterAccount;

    return (
      !isTooltipCompleted(masterAccount, tooltipKeys.PERSONAL_LINKS) ||
      this.isPersonalLinksEmpty()
    );
  }

  renderPersonalLinksAnimation() {
    const hasNoPersonalLinks = this.isPersonalLinksEmpty();

    return (
      <>
        <div
          id={PERSONAL_LINK_ANIMATION_ID}
          className="animation-background-container px-2 pb-2 pt-4 rounded-lg mx-4 relative h-72 mt-4"
        >
          <PersonalLinkAnimation />
          <div className="default-font-size mt-5 mx-3">
            Personal links are permanent booking links that automatically
            prevent double bookings
          </div>
        </div>

        <SaveButton
          width="330px"
          height={SAVE_BUTTON_HEIGHT}
          buttonText={hasNoPersonalLinks ? "Create" : "Start"}
          marginTop={40}
          onClick={() => {
            if (hasNoPersonalLinks) {
              this.addNewPersonalLink();
            } else {
              Broadcast.publish(
                BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED,
                tooltipKeys.PERSONAL_LINKS,
              );
            }
          }}
          style={{ zIndex: 1, marginLeft: 15 }}
        />
      </>
    );
  }

  renderPersonalLinks() {
    return (
      <div id={AVAILABILITY_PERSONAL_SLOT_CONTAINER_ID}>
        {this.state.personalLinks.map((personalLink, index) => {
          return (
            <PersonalLinkContainer
              selectedUser={this.getSelectedUser()}
              key={`personal-link-container_${index}_${getPersonalLinkToken(personalLink)}`}
              uniqueKey={index}
              personalLinkInfo={personalLink}
              onClickEdit={() => this.openPersonalLink({ personalLink })}
            />
          );
        })}
      </div>
    );
  }

  /// renders the booking link as text
  renderPreviewLink() {
    return (
      <div
        onClick={() => this.onClickPreview()}
        style={{
          color: this.props.isDarkMode
            ? StyleConstants.darkModeBlueColor
            : "#1974e8",
          cursor: "pointer",
          wordBreak: "break-all",
        }}
      >
        {this.getBookingUrlEndpoint()}
      </div>
    );
  }

  renderBreakDurationToggle() {
    const onClickToggle = () => {
      const updatedIsOn = !this.state.isCombineAdjacentSlots;
      blurCalendar();
      this.onChangeIsCombineAdjacentSlots(updatedIsOn);
    };

    return (
      <div className="flex items-center mt-2">
        <div
          className="default-font-size mr-6 cursor-pointer select-none secondary-text-color w-40"
          onClick={onClickToggle}
        >
          Combine adjacent Slots
        </div>

        <DefaultSwitch
          id={COMBINE_ADJACENT_SLOTS_ID}
          isChecked={this.state.isCombineAdjacentSlots}
          onChange={(isOn) => {
            blurCalendar();
            this.onChangeIsCombineAdjacentSlots(isOn);
          }}
        />
      </div>
    );
  }

  onChangeHoldsColorID(colorID) {
    if (!colorID) {
      return;
    }

    this.setState({ slotHoldOverrideColorID: colorID });
  }

  onChangeShouldAddAttendeesToHolds(shouldAddAttendeesToHolds) {
    this.setState({ shouldAddAttendeesToHolds: shouldAddAttendeesToHolds });
  }

  onChangeIsCombineAdjacentSlots(isOn) {
    if (isOn === this.state.isCombineAdjacentSlots) {
      return;
    }

    const { setBreakDuration, resetBreakDuration } =
      this.props.availabilityStore;

    this.setState({ isCombineAdjacentSlots: isOn }, () => {
      // set it after we update state
      if (isOn) {
        resetBreakDuration();
        this.props.setTemporaryEvent(this._draggedSlots);
      } else {
        setBreakDuration(this.state.duration);
        this.breakExistingSlotTemporaryEventsIntoDuration();
      }
    });
  }

  toggleBreakIntoDuration() {
    const updatedIsOn = !this.state.isCombineAdjacentSlots;
    this.onChangeIsCombineAdjacentSlots(updatedIsOn);
  }

  getFirstSlotsStartDate() {
    const userSelectedSlots = this.getUserSelectedSlots();
    if (isEmptyArrayOrFalsey(userSelectedSlots)) {
      return null;
    }
    const sortedAvailabilitySlots = immutablySortArray(userSelectedSlots, (a, b) =>
      sortEventsJSDate(a, b, false));
    return sortedAvailabilitySlots[0]?.eventStart;
  }

  renderLinkAsTextOption() {
    return (
      <div>
        {convertCopyFromPlaceHolders({
          copy: this.state.plainTextURLLinkCopy,
          currentTimeZone: this.props.currentTimeZone,
          duration: this.state.duration,
          extraTimeZones: this.getAllExtraTimeZonesForSlots(),
          showAllTimeZones: this.state.contentShowsAllTimeZones,
          date: this.getFirstSlotsStartDate()
        })}

        {this.renderPreviewLink()}
      </div>
    );
  }

  renderEachLine(line, index, timeSlotIndex, isTimeText) {
    const containBulletPoint = line.includes("\u2022");

    const trimmedLine = line.trimStart();
    if (containBulletPoint) {
      const withoutBullet = line.replace("\u2022", "");
      return (
        <div key={`bullet-point-${index}`}>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>

          <span>{"\u2022"}</span>

          <span>&nbsp;</span>

          {this.renderEachLine(withoutBullet, index, timeSlotIndex, isTimeText)}
        </div>
      );
    } else if (isTimeText) {
      if (!this.state.isSubmitting) {
        if (doesStringContainBoldTags(trimmedLine)) {
          const {
            boldText,
            afterBold,
          } = extractBoldText(trimmedLine);
          return (
            <span key={`bold-time-text-${index}`}>
              <strong>{boldText} </strong>
              <span
                style={{
                  color: this.props.isDarkMode
                    ? StyleConstants.darkModeBlueColor
                    : "#1974e8",
                  cursor: "pointer",
                }}
                target="_blank"
                onClick={() => this.onClickPreview(timeSlotIndex)}
              >
                {afterBold}
              </span>
            </span>
          );
        }

        if (strContainsDateAndTime(trimmedLine)) {
          const { date, time } = getDateAndTimeFromInput(trimmedLine);
          return (
            <span key={`default-date-time-text-${index}`}>
              <span>{date} </span>
              <span
                style={{
                  color: this.props.isDarkMode
                    ? StyleConstants.darkModeBlueColor
                    : "#1974e8",
                  cursor: "pointer",
                }}
                target="_blank"
                onClick={() => this.onClickPreview(timeSlotIndex)}
              >
                {time}
              </span>
            </span>
          );
        }
        return (
          <span
            key={`time-text-default-${index}`}
            style={{
              color: this.props.isDarkMode
                ? StyleConstants.darkModeBlueColor
                : "#1974e8",
              cursor: "pointer",
            }}
            target="_blank"
            onClick={() => this.onClickPreview(timeSlotIndex)}
          >
            {trimmedLine}
          </span>
        );
      } else {
        // need to paste with `getBookingUrlEndpoint` because we copy in the rendered code
        if (doesStringContainBoldTags(trimmedLine)) {
          const {
            boldText,
            afterBold,
          } = extractBoldText(trimmedLine);
          return (
            <span key={`bold-time-text-a-tag-${index}`}>
              <strong>{boldText} </strong>
              <a
                href={`${this.getBookingUrlEndpoint()}?index=${timeSlotIndex}`}
                rel="noopener noreferrer"
                style={{ color: "#1974e8" }}
                target="_blank"
              >
                {afterBold}
              </a>
            </span>
          );
        }
        if (strContainsDateAndTime(trimmedLine)) {
          const { date, time } = getDateAndTimeFromInput(trimmedLine);
          return (
            <span key={`default-date-time-text-${index}`}>
              <span>{date} </span>
              <a
                href={`${this.getBookingUrlEndpoint()}?index=${timeSlotIndex}`}
                rel="noopener noreferrer"
                key={`time-text-a-tag-default-${index}`}
                style={{ color: "#1974e8" }}
                target="_blank"
              >
                {time}
              </a>
            </span>
          );
        }
        return (
          <a
            href={`${this.getBookingUrlEndpoint()}?index=${timeSlotIndex}`}
            rel="noopener noreferrer"
            key={`time-text-a-tag-default-${index}`}
            style={{ color: "#1974e8" }}
            target="_blank"
          >
            {trimmedLine}
          </a>
        );
      }
    } else if (doesStringContainBoldTags(trimmedLine)) {
      const {
        boldText,
        afterBold,
      } = extractBoldText(trimmedLine);
      if (!afterBold) {
        return <strong key={`bold-text-${index}`}>{boldText}</strong>;
      }
      return (
        <span key={`combined-bold-and-default-text-${index}`}>
          <strong>{boldText} </strong>
          <span>
            {afterBold}
          </span>
        </span>
      );
    } else {
      return <span key={`slot-line-${index}`}>{trimmedLine}</span>;
    }
  }

  renderCopyButton() {
    return (
      <div
        className={classNames(
          "mt-5 sticky bottom-0",
          "create-availability-copy-button-wrapper",
          "side-panel-modal-background-color"
        )}
      >
        <SaveButton
          className="event-form-save-button"
          style={{
            height: SAVE_BUTTON_HEIGHT,
            marginBottom: 20,
            width: "100%",
          }}
          onClick={this.copyContent}
          doNotParseText={this.state.isSubmitting}
          shortcut={determineSaveButtonShortcut(
            this.state.isSubmitting,
            this.props.isMac
          )}
          buttonText={"Copy to clipboard"}
          buttonId={AVAILABILITY_COPY_BUTTON_ID}
          disabled={!this.shouldDisplaySlotsContent()}
        />
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  pressX() {
    Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
  }

  openPersonalLink({ personalLink, isCreateNewPersonalLink = false }) {
    if (!personalLink) {
      return;
    }

    this.scrollToTopOfPage();

    this.setState({
      page: EDIT_LINK_DETAIL,
      isCreateNewPersonalLink,
      personalLinkInfo: personalLink
    });
  }

  getInitialAvailabilityType() {
    return SLOTS_AVAILABILITY_SELECTION;
    const {
      eventFormEmails
    } = this.props;
    if (eventFormEmails?.length > 0) {
      return SLOTS_AVAILABILITY_SELECTION;
    }

    const isPersonalLink = isLatestAvailabiltyTypePersonalLink(
      this.getSelectedUser()
    );
    return isPersonalLink
      ? PERSONAL_LINK_SELECTION
      : SLOTS_AVAILABILITY_SELECTION;
  }

  changeTimeZone() {
    Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
  }

  getUserCustomQuestions() {
    if (isEmptyArray(getUserSlotsCustomQuestions(this.getSelectedUser()))) {
      return DEFAULT_CUSTOM_QUESTIONS;
    }
    return getUserSlotsCustomQuestions(this.getSelectedUser());
  }

  onClickPreview(selectedTimeSlotIndex = null) {
    const userSelectedSlots = this.getUserSelectedSlots();

    const {
      bufferFromNow,
      token,
      duration,
      conferencing,
      eventTitle,
      availabilityType,
      optionalInviteeName,
      optionalInviteeEmail,
      attendees,
      location,
      blockedCalendarsID,
      blockedAttendeesCalendars,
      bufferBeforeEvent,
      bufferAfterEvent,
      isIgnoreConflicts,
      isIgnoreInternalConflicts,
    } = this.state;

    const timeSlotsInfo = this.createTimeSlots();

    const isPreviewExpired = () => {
      if (!timeSlotsInfo.end_time) {
        return true;
      }

      return (
        timeSlotsInfo.end_time &&
        isBefore(parseISO(timeSlotsInfo.end_time), new Date())
      );
    };

    if (userSelectedSlots) {
      const availabilityInfo = {
        duration,
        location: null,
        conferencing: conferencing.value,
        availabilityEvents: userSelectedSlots,
        timeSlotsInfo,
        isPreviewExpired: isPreviewExpired(),
        selectedTimeSlotIndex,
        title: eventTitle,
        sender_full_name: this.getSelectedCalendarName(),
        token,
        modalTitle:
          availabilityType === BACKEND_PERSONAL_LINK
            ? "Personal Link Preview"
            : "Slots Preview",
        custom_questions: this.getUserCustomQuestions(),
        bufferFromNow,
        bufferBeforeEvent,
        bufferAfterEvent,
        blocked_calendar_ids: isIgnoreConflicts ? [] : blockedCalendarsID,
        selectedUser: this.getSelectedUser(),
      };
      
      if (isIgnoreInternalConflicts) {
        availabilityInfo[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] = true;
      }

      if (isVersionV2()) {
        availabilityInfo["calendar_provider_id"] = getCalendarProviderId(
          this.state.selectedCalendar
        );
        availabilityInfo["user_calendar_id"] = getCalendarUserCalendarID(
          this.state.selectedCalendar
        );
      } else {
        availabilityInfo["google_calendar_id"] = getCalendarProviderId(
          this.state.selectedCalendar
        );
      }

      if (optionalInviteeName) {
        availabilityInfo.invitee_full_name = optionalInviteeName;
      }

      if (optionalInviteeEmail) {
        availabilityInfo.invitee_email = optionalInviteeEmail;
      }

      if (attendees?.length > 0) {
        availabilityInfo.attendees = JSON.stringify(attendees);
      }

      if (location) {
        availabilityInfo.location = location;
      }

      const description = this.getDescription();
      if (description) {
        availabilityInfo.description = description;
      }

      if (!isIgnoreConflicts && blockedAttendeesCalendars?.length > 0) {
        availabilityInfo.blocked_attendee_emails = blockedAttendeesCalendars;
      }

      layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.DISPLAY_AVAILABILITY_PREVIEW_MODAL, availabilityInfo);
    }

    trackEvent({
      category: "create_availability_panel",
      action: "on_click_preview",
      label: "create_availability_panel",
      userToken: getUserToken(this.getSelectedUser()),
    });
  }

  getDescription() {
    if (this.description?.current?.getEditorContents) {
      return this.description?.current?.getEditorContents() || "";
    }

    return this.state.description || "";
  }

  onChangeAvailabilityType(type) {
    if (type !== BOOKING_LINK) {
      modalBroadcast.publish("REMOVE_BOTTOM_MODAL_CONTENT");
    }

    let updatedState = { availabilityType: type };

    if (this.determineLinkType(type) === BACKEND_GROUP_VOTE_LINK) {
      // if group vote -> refetch
      this.fetchGroupVoteLinks();
    }

    // hide temporary events on changing to personal link and vice versa
    if (
      (this.determineLinkType(type) === BACKEND_PERSONAL_LINK ||
        this.determineLinkType(type) === BACKEND_GROUP_VOTE_LINK) &&
      this.determineLinkType(this.state.availabilityType) === BOOKING_LINK
    ) {
      // Move to personal link
      if (this.props.temporaryEvents?.length > 0) {
        updatedState.savedTemporaryEvents = _.clone(this.props.temporaryEvents);
        this.props.setTemporaryEvent(null);
      }
    } else if (
      this.determineLinkType(type) === BOOKING_LINK &&
      (this.determineLinkType(this.state.availabilityType) ===
        BACKEND_PERSONAL_LINK ||
        this.determineLinkType(this.state.availabilityType) ===
          BACKEND_GROUP_VOTE_LINK)
    ) {
      // Move away from personal link
      if (this.state.savedTemporaryEvents?.length > 0) {
        this.props.setTemporaryEvent(this.state.savedTemporaryEvents);
      }
    }

    this.setState(updatedState, this.createTextFromSelection);

    blurCalendar();
  }

  addNewPersonalLink() {
    this.setState({
      page: EDIT_LINK_DETAIL,
      isCreateNewPersonalLink: true,
      personalLinkInfo: {},
    });
  }

  addGroupVoteLink() {
    this.setState({
      page: EDIT_GROUP_VOTE_DETAIL,
      isCreateNewGroupVoteLink: true,
      groupVoteInfo: {},
    });
  }

  addGroupSpreadsheetLink() {
    this.setState({
      page: EDIT_GROUP_SPREADSHEET_DETAIL,
      isCreateNewGroupVoteLink: true,
      groupVoteInfo: {},
    });
  }

  deletePersonalLink(token) {
    let updatedPersonalLinks = this.state.personalLinks;
    updatedPersonalLinks = updatedPersonalLinks.filter(
      (p) => p.token !== token
    );

    this.savePersonalLinks(updatedPersonalLinks);
  }

  applyEventTemplate(template) {
    if (isEmptyObjectOrFalsey(template) || isTextTemplate(template)) {
      return;
    }

    const attendees = getTemplateAttendees(template);
    const conferenceData = getTemplateConferenceData(template);
    const description = getTemplateDescription(template);
    const duration = getTemplateDuration(template);
    const templateEmail = getTemplateEmail(template);
    const location = getTemplateLocation(template);
    const summary = getTemplateTitle(template);

    let updatedState = {
      isMeetingDetailsExpanded: true,
    };

    let updatedFlashSections = {};
    if (summary) {
      updatedState.eventTitle = summary;
      updatedFlashSections[TITLE_DETAIL] = true;
      Broadcast.publish(`UPDATE_EVENT_INPUT_VALUE_${CONTAINER_ID}`, summary);
    }

    if (getTemplateIsAllDay(template)) {
      // if all day -> ignore
    } else if (!isEmptyObjectOrFalsey(duration)) {
      let durationInMinutes = 0;
      if (duration.hours) {
        durationInMinutes += duration.hours * 60;
      }

      if (duration.minutes) {
        durationInMinutes += duration.minutes;
      }

      const warningAlert = this.determineWarningAlert();
      const criticalAlert = this.determineCriticalAlert(durationInMinutes);
      updatedState.warningAlert = warningAlert;
      updatedState.criticalAlert = criticalAlert;
      if (durationInMinutes) {
        this.onSelectMinutes({value: durationInMinutes});
      }
      updatedState.minutesQuery = `${durationInMinutes}`;
      updatedFlashSections[DURATION_DETAIL] = true;
    }

    if (attendees?.length > 0) {
      let updatedAttendees = [];
      filterOutResourceAttendees(attendees).forEach((a) => {
        let attendee = { value: getObjectEmail(a), email: getObjectEmail(a) };
        if (a.displayName) {
          attendee.name = a.displayName;
          attendee.label = `${a.displayName} (${getObjectEmail(a)})`;
        } else {
          attendee.label = getObjectEmail(a);
        }
        updatedAttendees = updatedAttendees.concat(attendee);
      });

      updatedState.attendees = updatedAttendees;
      updatedFlashSections[ATTENDEES_DETAIL] = true;
    }
    const { masterAccount } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    if (templateEmail) {
      const allWritableCalendars = createWritableCalendarList({
        emailToNameIndex: this.props.emailToNameIndex,
        allCalendars: getUserCalendar(
          this.props.allCalendars.allCalendars,
          getUserEmail(this.getSelectedUser())
        ),
        currentUser: this.getSelectedUser(),
        masterAccount,
        allLoggedInUsers,
      });
      const matchingCalendar = allWritableCalendars.find(
        (c) => getCalendarProviderId(c?.value) === templateEmail
      );
      if (matchingCalendar) {
        updatedState.selectedCalendar = matchingCalendar.value;
        updatedFlashSections[SELECTED_CALENDAR_DETAIL] = true;
      }
    }

    if (location) {
      updatedState.location = location;
      updatedFlashSections[LOCATION_DETAIL] = true;
    }

    if (description) {
      updatedState.description = description;
      this.setDescriptionInQuill(description);
      updatedFlashSections[DESCRIPTION_DETAIL] = true;
    }

    const templateConferenceData = getTemplateConferenceData(template);

    if (conferenceData) {
      if (isZoomFromIntegrationTemplate(templateConferenceData)) {
        updatedState.conferencing = ZOOM_CONFERENCING_OPTION;
      } else if (isHangoutGSuiteIntegration(templateConferenceData)) {
        updatedState.conferencing = HANGOUT_CONFERENCING_OPTION;
      } else if (isTemplateZoomConferencing(templateConferenceData)) {
        updatedState.conferencing = ZOOM_CONFERENCING_OPTION;
      } else if (isTemplatePhoneConferencing(conferenceData)) {
        updatedState.conferencing = PHONE_CONFERENCING_OPTION;
      } else if (isTemplateWhatsAppConferencing(conferenceData)) {
        updatedState.conferencing = WHATS_APP_CONFERENCING_OPTION;
      } else if (isTemplateOutlookConferencing(conferenceData)) {
        updatedState.conferencing = {
          label: convertOutlookConferencingToHumanReadable(conferenceData.conferenceType),
          value: conferenceData.conferenceType,
        };
      } else if (
        isTemplateCustomConferencing(conferenceData, this.getSelectedUser()) &&
        isValidCustomConferencing(this.getSelectedUser())
      ) {
        updatedState.conferencing = createCustomConferencingOption(
          this.getSelectedUser()
        );
      } else {
        updatedState.conferencing = NO_CONFERENCING_OPTION;
      }

      if (updatedState.conferencing) {
        updatedFlashSections[CONFERENCING_DETAIL] = true;
      }
    }

    this.setState(updatedState, () => {
      AvailabilityBroadcast.publish(
        "FLASH_SLOTS_DETAIL_CHANGES",
        updatedFlashSections
      );
    });
  }

  onChangeInviteeName(name) {
    this.setState({ optionalInviteeName: name });
  }

  onChangeDescription(description) {
    this.setState({ description });
  }

  onChangeLocation(location) {
    this.setState({ location });
  }

  onChangeInviteeEmail(email) {
    this.setState({ optionalInviteeEmail: email });
  }

  onChangeAttendees(attendees) {
    this.setState({ attendees });
  }

  onChangeTitle(title) {
    this.setState({ eventTitle: title });
  }

  openSelectBlockedCalendarsModal() {
    this.setState({
      shouldDisplayModal: true,
      eventModalContent: EVENT_MODAL_CONTENT.CHECK_FOR_CONFLICT,
    });
  }

  openSelectSlotTextFormatModal() {
    const getModalTop = () => {
      const clickedTop = getComponentLocation(SLOTS_TEXT_FORMAT_PICKER_ID).top + 60;
      const MODAL_HEIGHT = 204;
      const windowHeight = window?.innerHeight;
      if (windowHeight && (clickedTop + MODAL_HEIGHT > windowHeight)) {
        return windowHeight - MODAL_HEIGHT - 8; // 8 is a little extra space on the bottom
      }
      return clickedTop;
    };

    this.setState({
      shouldDisplayModal: true,
      eventModalContent: EVENT_MODAL_CONTENT.SELECT_SLOTS_TEXT_STYLES,
      modalTop: getModalTop(),
    });
  }

  openSelectSlotStyleModal() {
    this.setState({
      shouldDisplayModal: true,
      eventModalContent: EVENT_MODAL_CONTENT.SELECT_SLOTS_STYLE,
      modalTop: getComponentLocation(SLOTS_STYLE_PICKER_ID).top + 60,
    });
  }

  setIsIgnoreConflicts(isIgnoreConflicts) {
    const updatedState = { isIgnoreConflicts };
    if (isIgnoreConflicts) {
      if (this.isDoubleBookCriticalAlertWarning()) {
        updatedState.criticalAlert = null;
      }
      if (this.isAllDayBlockingWarning()) {
        updatedState.warningAlert = null;
      }
    }
    blurCalendar();
    this.setState(updatedState);
  }

  updateSingleAttendee(inputAttendee) {
    const {
      attendees
    } = this.state;
    if (isEmptyObjectOrFalsey(inputAttendee) || isEmptyArray(attendees)) {
      return;
    }
    const updatedAttendees = attendees.map(attendee => isSameEmail(getObjectEmail(attendee), getObjectEmail(inputAttendee)) ? inputAttendee : attendee);
    this.setState({attendees: updatedAttendees});
  }

  closeModal() {
    this.setState({
      shouldDisplayModal: false,
      eventModalContent: null,
    });
  }

  onChangeAllowReschedule() {
    this.setState({ allowReschedule: !this.state.allowReschedule });
  }

  onChangeSlotsHoldTitle(e) {
    this.setState({ slotsHoldTitle: getInputStringFromEvent(e) });
  }

  updatePersonalLinks({ response, isNew = false, openDetails = false }) {
    const token = response.personal_link.token;
    let updatedPersonalLinks = this.state.personalLinks;

    if (isNew) {
      // append to end
      updatedPersonalLinks = updatedPersonalLinks.filter(
        (p) => p.token !== token
      );

      updatedPersonalLinks = updatedPersonalLinks.concat(
        response.personal_link
      );
    } else {
      // replace at current index
      let temp = [];
      updatedPersonalLinks.forEach((t) => {
        if (t.token === token) {
          temp = temp.concat(response.personal_link);
        } else {
          temp = temp.concat(t);
        }
      });

      updatedPersonalLinks = temp;
    }

    const sortedLinks = sortPersonalLinks(updatedPersonalLinks);

    this.props.setPersonalLinks(sortedLinks);

    savePersonalLinksIntoLocalData(sortedLinks, this.getSelectedUser());

    this.setState({ personalLinks: sortedLinks, page: DEFAULT_PAGE }, () => {
      if (openDetails) {
        const personalLink = response.personal_link;
        this.openPersonalLink({ personalLink });
      }
    });
  }

  getSlugFromResponse(response) {
    return response?.availabilities?.slug;
  }

  showTeamAttendeeStillLoading() {
    availabilityBroadcast.publish("SHOW_STILL_LOADING_SLOTS_WARNING");
  }

  async generateSlugAndCopyLinkOnly() {
    if (isSlotsPendingLoadingAttendees()) {
      this.showTeamAttendeeStillLoading();
      return;
    }
    // need to generate slug first, otherwise we default.
    if (
      isEmptyArray(this.getUserSelectedSlots()) ||
      isGroupVoteDetailPageOpen()
    ) {
      return;
    }
    if (this.isSlotsPlainText()) {
      return;
    }

    this.copyLinkOnly();
    this.setState({isSubmitting: true});
    const response = await this.createLink(true);
    if (!this._isMounted) {
      return;
    } else if (!response?.availabilities) {
      // error occured
      this.copyEmptyString();
      return;
    }

    const backendSlug = this.getSlugFromResponse(response);
    if (backendSlug && backendSlug !== this.state.slug) {
      this.setState({ slug: backendSlug, isSubmitting: false }, () => {
        this.copyLinkOnly();
      });
    }

    Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
  }

  copyEmptyString() {
    // on error
    navigator.clipboard.writeText("").then(
      () => {},
      function (e) {}
    );
  }

  async copyContent() {
    if (this.state.isSubmitting) {
      return;
    }
    if (isSlotsPendingLoadingAttendees()) {
      this.showTeamAttendeeStillLoading();
      return;
    }

    if (isGroupVoteDetailPageOpen()) {
      return;
    }

    if (isEmptyArrayOrFalsey(this.getUserSelectedSlots())) {
      return;
    }

    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.COPY_SLOTS}::copySlots`,
      userToken: getUserToken(this.getSelectedUser())
    });

    this.saveSlotsAndTimeZone();

    this.copySlotsToClipBoard(); // this needs to be here otherwise safari copy fails
    this.setState({ isSubmitting: true });
    const response = await this.createLink(false);
    if (!this._isMounted) {
      return;
    } else if (!response?.availabilities) {
      // error occured
      this.copyEmptyString();
      return;
    }

    const backendSlug = this.getSlugFromResponse(response);
    if (backendSlug && backendSlug !== this.state.slug) {
      this.setState({ slug: backendSlug }, () => {
        this.copySlotsToClipBoard();
      });
    }

    Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
  }

  // to be called after we save slots on backend
  copySlotsToClipBoard() {
    if (this.isSlotsPlainText()) {
      this.copyPureText();
      return;
    }

    if (this.hasSlotsBookingLink()) {
      if (this.isLinkAlone()) {
        // no need to set isSubmitting
        this.copyLinkOnly();
        return;
      }

      this.setState({ isSubmitting: true }, () => {
        if (this.isSlotsRichText()) {
          this.copyTextAsHTML();
        } else {
          this.copyPureTextWithLink();
        }
      });
    } else {
      this.setState({ isSubmitting: true }, () => {
        this.copyPureText();
      });
    }

    setLastestAvailabiltyType(this.getSelectedUser(), false);
  }

  async copyLinkOnly() {
    if (this.state.isSubmitting) {
      return;
    }

    if (isEmptyArrayOrFalsey(this.getUserSelectedSlots())) {
      return;
    }

    trackEvent({
      category: "create_availability_panel",
      action: `${FEATURE_TRACKING_ACTIONS.COPY_SLOTS}::copyLinkOnly`,
      event_name: "slots_copied",
      slots_type: "linkOnly",
      label: `create_availability_panel_${this.getExtraTracking()}`,
      userToken: getUserToken(this.getSelectedUser()),
    });

    this.saveSlotsAndTimeZone();
    if (this.hasSlotsBookingLink()) {
      this.copyOnlyAvailabilityLink();
    } else {
      this.copyPureText();
    }

    setLastestAvailabiltyType(this.getSelectedUser(), false);
  }

  hasSlotsBookingLink() {
    return [LINK_ALONE, SLOTS_PLAIN_TEXT_URL, SLOTS_RICH_TEXT].includes(
      this.state.slotsType.value
    );
  }

  shouldDisplaySlotsContent() {
    return this.getUserSelectedSlots()?.length > 0;
  }

  getUserSelectedSlots() {
    return this.props.temporaryEvents?.filter(event => !isOutstandingSlotEvent(event));
  }

  copyTextAsHTML(id = AVAILABILITY_TEXT_CONTENT) {
    let contentContainer = document.getElementById(id);

    if (!contentContainer) {
      return;
    }

    if (detectBrowser() === SAFARI) {
      safariCopy(id);
      return;
    }

    let str = contentContainer.innerHTML;

    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    }

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    contentContainer = null;
    str = null;

    trackEvent({
      category: "create_availability_panel",
      action: "on_click_copy_link_html",
      event_name: "slots_copied",
      slots_type: "hyperlinked",
      label: `create_availability_panel_${this.getExtraTracking()}`,
      userToken: getUserToken(this.getSelectedUser()),
    });
  }

  getExtraTracking() {
    try {
      const user = this.getSelectedUser();
      const {
        masterAccount,
      } = this.props.masterAccount;
      return `link: ${this.getBookingUrlEndpoint()} || isUserFromMagicLink: ${isUserFromMagicLink({ user })} || getAccountName: ${JSON.stringify(getAccountName({ masterAccount }))} || getConnectedAccountUserName: ${JSON.stringify(getConnectedAccountUserName({ user }))}`;
    } catch (error) {
      return "error";
    }
  }

  copyOnlyAvailabilityLink() {
    const content = this.getBookingUrlEndpoint();

    navigator.clipboard.writeText(content).then(
      () => {
        if (!this._isMounted) {
          return;
        }
        /* clipboard successfully set */
        trackEvent({
          category: "create_availability_panel",
          action: "copy_text_with_url",
          event_name: "slots_copied",
          slots_type: "url_only",
          label: "create_availability_panel",
          userToken: getUserToken(this.getSelectedUser()),
        });
      },
      function () {
        /* clipboard write failed */
      }
    );
  }

  copyPureTextWithLink() {
    let content = this.state.content;
    const linkCopy = convertCopyFromPlaceHolders({
      copy: this.state.plainTextURLLinkCopy,
      currentTimeZone: this.props.currentTimeZone,
      duration: this.state.duration,
      extraTimeZones: this.getAllExtraTimeZonesForSlots(),
      showAllTimeZones: this.state.contentShowsAllTimeZones,
      date: this.getFirstSlotsStartDate()
    });
    const urlTokenText = `\n${linkCopy}\n ${this.getBookingUrlEndpoint()}`;

    content = content + urlTokenText;

    trackEvent({
      category: "create_availability_panel",
      action: "copy_text_with_url",
      event_name: "slots_copied",
      slots_type: "plaintext_with_url",
      label: `create_availability_panel_${this.getExtraTracking()}`,
      userToken: (this.getSelectedUser()),
    });

    if (doesStringContainBoldTags(content)) {
      const {
        htmlContent,
        text,
      } = getHtmlAndTextContent(content);
      copyFormattedText({
        htmlContent,
        text,
      });
      return;
    }
    copyContent(content);
  }

  // returns the booking link url after we get the slug back
  getBookingUrlEndpoint() {
    const username = this.getAvailabilityUserName();
    if (username) {
      return `${determineBookingURL()}/t/${username}/${this.state.slug}`;
    }

    return `${determineBookingURL()}/t/${this.state.token}`;
  }

  async copyPureText() {
    const {
      content,
    } = this.state;
    this.saveSlotsAndTimeZone();
    if (doesStringContainBoldTags(content)) {
      const {
        htmlContent,
        text,
      } = getHtmlAndTextContent(content);
      await copyFormattedText({
        htmlContent,
        text,
      });
    } else {
      await copyContent(content);
    }

    trackEvent({
      category: "create_availability_panel",
      action: "copy_text",
      event_name: "slots_copied",
      slots_type: "plaintext_only",
      label: "create_availability_panel",
      userToken: getUserToken(this.getSelectedUser()),
    });

    if (!this.state.showSlotsHoldForm) {
      Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
    }

    this.showNotificationAndSetLastState();
  }

  async createLink(copyOnlyLink = false) {
    if (isSlotsPendingLoadingAttendees()) {
      this.showTeamAttendeeStillLoading();
      return;
    }
    const path = isVersionV2() ? "slots" : "availabilities";
    const url = constructRequestURL(path, isVersionV2());
    const linkData = this.constructLinkData();

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(this.getSelectedUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          this.setState({ isSubmitting: false });
          Broadcast.publish("SHOW_ERROR_MESSAGE");

          return;
        }

        let newState = { isSubmitting: false };

        if (response.availabilities) {
          this.setState(newState);

          /* Create holds after availability is created if form is toggled */
          const { selectedCalendar, showSlotsHoldForm } = this.state;
          if (response?.availabilities?.token && showSlotsHoldForm) {
            holdsBroadcast.publish(
              HOLDS_BROADCAST_VALUES.CREATE_LINKABLE_HOLDS,
              {
                calendar: selectedCalendar,
                holdEvents: this.constructHoldEvents(),
                linkableToken: response?.availabilities?.token,
                linkableType: LINKABLE_TYPES.AVAILABILITY_LINK,
                user: this.getSelectedUser(),
              },
            );
          }
        } else {
          Broadcast.publish("SHOW_ERROR_MESSAGE");
          this.setState(newState);
        }

        if (copyOnlyLink) {
          this.showNotificationAndSetLastState(DEFAULT_COPIED_SLOTS_URL_MESSAGE);
        } else if (this.isSlotsPlainText()) {
          // For plain text, the slots are copied immediately and a notification broadcast is sent
          // in copyPureText. No need to send an additional broadcast here after the backend responds.
        } else if (!this.isSlotsRichText()) {
          this.showNotificationAndSetLastState();
        } else {
          this.showNotificationAndSetLastState();
        }
        return response;
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }

        trackEvent({
          category: "create_availability_panel",
          action: "error_create_link_catch",
          label: `create_availability_panel_${this.getExtraTracking()}`,
          userToken: getUserToken(this.getSelectedUser()),
        });

        Broadcast.publish("SHOW_ERROR_MESSAGE");

        this.setState({ isSubmitting: false });
      });
  }

  createTimeSlots() {
    const userSelectedSlots = this.getUserSelectedSlots().filter(
      (e) => e.isAvailability,
    );

    if (userSelectedSlots.length === 0) {
      return { time_slots: [], endTime: null };
    }

    const mergedEvents = combineSlots(userSelectedSlots);

    const sortedAvailabilityEvents = sortEventsByStartTime(mergedEvents);

    let end_time;
    let time_slots = [];

    sortedAvailabilityEvents.forEach((e, index) => {
      const formattedStart = getTimeInAnchorTimeZone(
        e.eventStart,
        this.props.currentTimeZone,
        guessTimeZone()
      ).toISOString();

      const formattedEnd = getTimeInAnchorTimeZone(
        e.eventEnd,
        this.props.currentTimeZone,
        guessTimeZone()
      ).toISOString();

      const slot = {
        start_time: formattedStart,
        end_time: formattedEnd,
        index: index,
      };

      if (
        !end_time ||
        (e.eventEnd && isAfter(e.eventEnd, parseISO(end_time)))
      ) {
        end_time = formattedEnd;
      }

      time_slots = time_slots.concat(slot);
    });

    return { time_slots, end_time };
  }

  constructHoldEvents() {
    const vholds_id = `vholds_${createUUID(16)}`;
    let userSelectedSlots = this.getUserSelectedSlots().filter(
      (e) => e.isAvailability,
    );

    if (isEmptyArrayOrFalsey(userSelectedSlots)) {
      return;
    }
    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.HOLDS_CREATED}::${userSelectedSlots.length}`,
      userToken: getUserToken(this.getSelectedUser())
    });

    const mergedEvents = combineSlots(userSelectedSlots);

    const sortedAvailabilityEvents = sortEventsByStartTime(mergedEvents);

    let end_time;
    let slot_holds = {
      vholds_id,
      user_calendar_id: getCalendarUserCalendarID(this.state.selectedCalendar),
      events: [],
    };

    const {
      allCalendars: { allCalendars },
      allLoggedInUsers: { allLoggedInUsers },
      masterAccount: { masterAccount },
    } = this.props;

    const { attendees, shouldAddAttendeesToHolds, selectedOutlookCategories } = this.state;

    const holdAttendees = shouldAddAttendeesToHolds
      ? formatContactsToAttendees({
          allLoggedInUsers,
          calendar: this.state.selectedCalendar,
          contacts: attendees,
          masterAccount,
        })
      : null;

    const slotPresets = getSlotsPresets({
      allCalendars,
      currentUser: this.getSelectedUser(),
      masterAccount,
    });

    sortedAvailabilityEvents.forEach((e) => {
      let formattedStart = getTimeInAnchorTimeZone(
        e.eventStart,
        this.props.currentTimeZone,
        guessTimeZone()
      ).toISOString();

      let formattedEnd = getTimeInAnchorTimeZone(
        e.eventEnd,
        this.props.currentTimeZone,
        guessTimeZone()
      ).toISOString();

      if (
        !end_time ||
        (e.eventEnd && isAfter(e.eventEnd, parseISO(end_time)))
      ) {
        end_time = formattedEnd;
      }

      const trimmedTitle = this.state.slotsHoldTitle.trim();
      const getDescription = () => {
        if (!this.hasSlotsBookingLink()) {
          return shouldHideDefaultSignature({ masterAccount, user: this.getSelectedUser() })
            ? undefined
            : VIMCAL_SIGNATURE;
        }
        return `Slots booking link:\n${this.getBookingUrlEndpoint()}\n\n${shouldHideDefaultSignature({ masterAccount, user: this.getSelectedUser() }) ? "" : VIMCAL_SIGNATURE}`;
      };

      if (isVersionV2()) {
        const holdEvent = {
          attendees: holdAttendees,
          description: getDescription(),
          event_end: { dateTime: formattedEnd, date: null },
          google_id: getCalendarProviderId(this.state.selectedCalendar),
          reminders: { overrides: [], useDefault: false },
          is_reminder_on: false,
          event_start: { dateTime: formattedStart, date: null },
          title: trimmedTitle,
          colorId:
            this.state.slotHoldOverrideColorID ?? slotPresets[HOLD_COLOR_ID],
          transparency: FREE_DURING_EVENT,
          categories: selectedOutlookCategories,
        };
        if (isCalendarOutlookCalendar(this.state.selectedCalendar) && !isEmptyArrayOrFalsey(selectedOutlookCategories)) {
          holdEvent["categories"] = selectedOutlookCategories;
        }

        slot_holds.events = slot_holds.events.concat(
          omitNullOrUndefinedProps(holdEvent)
        );
      } else {
        slot_holds.events = slot_holds.events.concat({
          description: shouldHideDefaultSignature({ masterAccount, user: this.getSelectedUser() })
            ? undefined
            : VIMCAL_SIGNATURE,
          end: { dateTime: formattedEnd, date: null },
          google_id: getCalendarProviderId(this.state.selectedCalendar),
          reminders: { useDefault: true },
          start: { dateTime: formattedStart, date: null },
          summary: trimmedTitle,
          transparency: FREE_DURING_EVENT,
        });
      }
    });

    return slot_holds;
  }

  getSelectedCalendarName() {
    const {
      emailToNameIndex,
    } = this.props;
    const email = getCalendarOwnerEmail(this.state.selectedCalendar);
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      distroListDictionary
    } = this.props.distroListDictionary;
    return convertEmailToName({
      email,
      currentUser: this.getSelectedUser(),
      emailToNameIndex,
      masterAccount,
      allLoggedInUsers,
      doNotReturnEmailByDefault: true,
      distroListDictionary,
    });
  }

  constructLinkData() {
    const { time_slots, end_time } = this.createTimeSlots();
    const { slug, isIgnoreConflicts, blockedAttendeesCalendars } = this.state;

    const linkData = {
      conferencing: this.state.conferencing.value,
      duration: this.state.duration,
      title: this.state.eventTitle,
      slots_time_zone: this.props.currentTimeZone,
      client_time_zone: this.props.defaultBrowserTimeZone || guessTimeZone(),
      end_time,
      time_slots,
      token: this.state.token,
      // This was a legacy field. The backend now provides the name from the user's profile.
      sender_full_name: null,
      slots_type: this.state.slotsType.value,
      custom_questions: this.getUserCustomQuestions(),
      slug,
    };

    if (isVersionV2()) {
      linkData["calendar_provider_id"] = getCalendarProviderId(
        this.state.selectedCalendar
      );
      linkData["user_calendar_id"] = getCalendarUserCalendarID(
        this.state.selectedCalendar
      );
    } else {
      linkData["google_calendar_id"] = getCalendarProviderId(
        this.state.selectedCalendar
      );
    }

    if (this.state.optionalInviteeName) {
      linkData.invitee_full_name = this.state.optionalInviteeName;
    }

    if (this.state.optionalInviteeEmail) {
      linkData.invitee_email = this.state.optionalInviteeEmail;
    }

    if (this.state.isIgnoreInternalConflicts) {
      linkData[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] = true;
    }

    if (this.state.attendees?.length > 0) {
      const trimmedAttendees = this.state.attendees.map(attendee => {
        return {
          email: getObjectEmail(attendee),
          isOptional: attendee.isOptional,
          name: attendee.name
        }
      });
      linkData.attendees = JSON.stringify(trimmedAttendees);
    }

    if (this.state.location) {
      linkData.location = this.state.location;
    }

    const description = this.getDescription();
    if (description) {
      linkData.description = description;
    }

    if (isIgnoreConflicts) {
      linkData.blocked_calendar_ids = [];
    } else if (this.state.blockedCalendarsID) {
      // do not need to chceck for empty array since [] is a valid option
      linkData.blocked_calendar_ids = this.state.blockedCalendarsID;
    }

    if (!isIgnoreConflicts &&  blockedAttendeesCalendars?.length > 0) {
      linkData.blocked_attendees_calendars = blockedAttendeesCalendars;
    }

    return { availability_link: linkData };
  }

  doesTextIncludeTime(text) {
    if (
      this.isSlotsRichText() &&
      text.includes(" - ") &&
      (doesStringContainTime(text))
    ) {
      return true;
    }

    return false;
  }

  handleWindowSizeChange() {
    if (this.windowSizeChangeTimer) {
      clearTimeout(this.windowSizeChangeTimer);

      this.windowSizeChangeTimer = null;
    }

    this.windowSizeChangeTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      let innerHeight = window.innerHeight;

      this.setState({ innerHeight: innerHeight });
    }, 500);
  }

  onChangeMinutesInput(value) {
    this.setState({ minutesQuery: value });
  }

  onKeyDownSelect(data) {
    if (!data) {
      return;
    }

    if (this.state.isMac) {
      if (
        data.keyCode === KEYCODE_COMMAND_LEFT ||
        data.keyCode === KEYCODE_COMMAND_RIGHT ||
        data.keyCode === KEYCODE_COMMAND_FIREFOX
      ) {
        this.setState({ isCmdKeyDown: true });
      } else {
        if (this.state.isCmdKeyDown && data.keyCode === KEYCODE_C) {
          this.copyContent();
        } else if (this.state.isCmdKeyDown && data.keyCode === KEYCODE_K) {
          Broadcast.publish("TURN_ON_COMMAND_CENTER");
        } else if (data.keyCode === KEYCODE_ESCAPE) {
          blurCalendar();
        }

        this.setState({ isCmdKeyDown: false });
      }
    } else {
      // PC
      if (data.keyCode === KEYCODE_CONTROL) {
        this.setState({ isCmdKeyDown: true });
      } else {
        if (this.state.isCmdKeyDown && data.keyCode === KEYCODE_C) {
          this.copyContent();
        } else if (this.state.isCmdKeyDown && data.keyCode === KEYCODE_K) {
          Broadcast.publish("TURN_ON_COMMAND_CENTER");
        } else if (data.keyCode === KEYCODE_ESCAPE) {
          blurCalendar();
        }

        this.setState({ isCmdKeyDown: false });
      }
    }
  }

  onSelectMinutes(selected) {
    if (!isInt(selected.value)) {
      return;
    }
    const value = Math.abs(removeLeadingZeros(selected.value));

    this.props.setAvailabilitySelectedMinutes(value);

    const updatedState = { duration: value };
    if (!this.state.isCombineAdjacentSlots) {
      const { setBreakDuration } = this.props.availabilityStore;
      setBreakDuration(value);

      // update all temporary events
      this.breakExistingSlotTemporaryEventsIntoDuration(value);
    }

    const warningAlert = this.determineWarningAlert();
    const criticalAlert = this.determineCriticalAlert(value);

    if (!this.isSameWarning(warningAlert, this.state.warningAlert)) {
      updatedState.warningAlert = warningAlert;
    }
    if (!this.isSameWarning(criticalAlert, this.state.criticalAlert)) {
      updatedState.criticalAlert = criticalAlert;
    }

    this.setState(updatedState);
  }

  // breaks existing
  breakExistingSlotTemporaryEventsIntoDuration(duration = null) {
    if (!this._draggedSlots.length === 0) {
      return;
    }

    let updatedSlots = [];
    this._draggedSlots.forEach((e) => {
      const newSlots = splitSlotIntoDuration({
        breakDuration: duration ?? this.state.duration,
        start: e.eventStart,
        end: e.eventEnd,
        currentSlots: updatedSlots,
      });

      updatedSlots = updatedSlots.concat(newSlots);
    });

    this.props.setTemporaryEvent(updatedSlots);
  }

  updateContentText(updatedCurrentUser) {
    if (!isSameEmail(getUserEmail(updatedCurrentUser), getUserEmail(this.getSelectedUser()))) {
      // do not update if different user
      return;
    }
    const { masterAccount } = this.props.masterAccount;
    const {
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      bufferFromNow,
      bufferBeforeEvent,
      bufferAfterEvent
    } = getSlotsPresets({
      currentUser: updatedCurrentUser,
      masterAccount,
    });

    this.setState(
      {
        bufferFromNow,
        plainTextCopy,
        plainTextURLCopy,
        plainTextURLLinkCopy,
        richTextCopy,
        bufferBeforeEvent,
        bufferAfterEvent
      },
      this.createTextFromSelection
    );
  }

  createTextFromSelection() {
    const {
      currentTimeZone,
      format24HourTime,
    } = this.props;
    const {
      duration,
      contentShowsAllTimeZones,
      isCombineAdjacentSlots,
      plainTextCopy,
      plainTextURLCopy,
      richTextCopy,
      textStyles,
    } = this.state;
    const userSelectedSlots = this.getUserSelectedSlots();
    const determinePreSlotsCopy = () => {
      switch (this.state.slotsType.value) {
        case SLOTS_PLAIN_TEXT:
          return plainTextCopy;
        case SLOTS_PLAIN_TEXT_URL:
          return plainTextURLCopy;
        case SLOTS_RICH_TEXT:
          return richTextCopy;
        default:
          return richTextCopy;
      }
    };

    const extraTimeZones = this.getAllExtraTimeZonesForSlots();

    const content = createAvailabilityTextFromEvent({
      eventList: userSelectedSlots,
      currentTimeZone,
      format24HourTime,
      isRichText: this.isSlotsRichText(),
      preSlotsCopy: convertCopyFromPlaceHolders({
        copy: determinePreSlotsCopy(),
        currentTimeZone,
        duration,
        extraTimeZones,
        showAllTimeZones: contentShowsAllTimeZones,
        date: this.getFirstSlotsStartDate(),
      }),
      extraTimeZones,
      showAllTimeZones: contentShowsAllTimeZones,
      showTimeZoneOnEachLine: this.shouldShowTimeZoneOnEachLine(),
      skipMerge: !isCombineAdjacentSlots,
      textStyles,
    });

    this.setState({ content });
  }

  shouldShowTimeZoneOnEachLine() {
    return this.state.textStyles?.includes(TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE);
  }

  getAllExtraTimeZonesForSlots() {
    const { anchorTimeZones, temporaryTimeZones, currentTimeZone } = this.props;

    return getAllExtraTimeZones({
      anchorTimeZones,
      temporaryTimeZones,
      defaultTimeZone: this.getLeftHandTimeZone(),
      isForSlots: true,
      currentTimeZone,
    });
  }

  getLeftHandTimeZone() {
    const {
      lastSelectedTimeZone
    } = this.props.appTimeZone;
    const {
      temporaryTimeZones
    } = this.props;
    const { masterAccount } = this.props.masterAccount;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone: getDefaultUserTimeZone({
        masterAccount,
        user: this.getSelectedUser(),
      }),
      temporaryTimeZones
    });
  }

  isPersonalLink() {
    return (
      this.state.availabilityType?.value === BACKEND_PERSONAL_LINK
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  savePersonalLinks(updatedPersonalLinks, user, skipSavingIntoRedux = false) {
    const sortedLinks = sortPersonalLinks(updatedPersonalLinks);
    if (!skipSavingIntoRedux) {
      this.props.setPersonalLinks(sortedLinks);
    }
    savePersonalLinksIntoLocalData(sortedLinks, user ?? this.getSelectedUser());

    this.setState({ personalLinks: sortedLinks });
  }

  async fetchGroupVoteLinksForAllUsers() {
    try {
      const {
        allLoggedInUsers
      } = this.props.allLoggedInUsers;
      const {
        groupVoteDictionary,
        setGroupVoteDictionary,
        groupVoteSpreadsheetDictionary,
        setGroupVoteSpreadsheetDictionary,
      } = this.props.groupVoteStore;
      const {
        masterAccount
      } = this.props.masterAccount;
      this.fetchGroupVoteLinks(); // set group vote for selected user;
  
      const pollPromises = [];
      const spreadsheetPromises = [];
  
      allLoggedInUsers.forEach((user) => {
        const userEmail = getUserEmail(user);
        if (hasGroupSpreadsheetAccess({user, masterAccount})) {
          const spreadsheetUrl = constructRequestURL(GROUP_SPREADSHEET_LINKS_ENDPOINT, true);
          spreadsheetPromises.push(Fetcher.get(spreadsheetUrl, {}, true, userEmail).then((response) => ({
            userEmail,
            response
          })));
        }
        const pollUrl = constructRequestURL(LATEST_GROUP_VOTE_LINKS, isVersionV2());
        pollPromises.push(Fetcher.get(pollUrl, {}, true, userEmail).then((response) => ({
          userEmail,
          response
        })));
      });
      const pollResponses = await Promise.all(pollPromises);
      if (!this._isMounted) {
        return;
      }
      const newGroupVoteDictionary = {};
      pollResponses.forEach((userEmailResponse) => {
        const {
          userEmail,
          response
        } = userEmailResponse;
        if (response?.group_vote_links) {
          newGroupVoteDictionary[userEmail] = response.group_vote_links;
        }
      });
      const updatedGroupVoteDictionary = {
        ...groupVoteDictionary,
        ...newGroupVoteDictionary
      };
      setGroupVoteDictionary(updatedGroupVoteDictionary);
      const spreadsheetResponses = await Promise.all(spreadsheetPromises);
      if (!this._isMounted) {
        return;
      }
      const newGroupVoteSpreadsheetDictionary = {};
      spreadsheetResponses.forEach((userEmailResponse) => {
        const {
          userEmail,
          response
        } = userEmailResponse;
        if (response?.group_spreadsheet_links) {
          newGroupVoteSpreadsheetDictionary[userEmail] = response.group_spreadsheet_links;
        }
      });
      const updatedGroupVoteSpreadsheetDictionary = {
        ...groupVoteSpreadsheetDictionary,
        ...newGroupVoteSpreadsheetDictionary
      };
      setGroupVoteSpreadsheetDictionary(updatedGroupVoteSpreadsheetDictionary);
    } catch (error) {
      handleError(error);
    }
  }

  async fetchGroupVoteLinks(inputUser) {
    const {
      groupVoteDictionary,
      groupVoteSpreadsheetDictionary,
    } = this.props.groupVoteStore;
    const { setGroupLinks } = this.props.groupVoteStore;
    const user = inputUser || this.getSelectedUser();
    const userEmail = getUserEmail(user);
    setGroupLinks({
      groupVoteLinks: groupVoteDictionary[userEmail] || [],
      groupSpreadsheetLinks: groupVoteSpreadsheetDictionary[userEmail] || [],
    });
    const promises = [];

    const pollUrl = constructRequestURL(LATEST_GROUP_VOTE_LINKS, isVersionV2());
    promises.push(Fetcher.get(pollUrl, {}, true, userEmail));
    const {
      masterAccount,
    } = this.props.masterAccount;

    if (hasGroupSpreadsheetAccess({user, masterAccount})) {
      const spreadsheetUrl = constructRequestURL(GROUP_SPREADSHEET_LINKS_ENDPOINT, true);
      promises.push(Fetcher.get(spreadsheetUrl, {}, true, userEmail));
    } else {
      promises.push(new Promise((resolve) => resolve(null)));
    }

    try {
      const [pollResponse, spreadsheetResponse] = await Promise.all(promises);
      if (!this._isMounted) {
        return;
      }

      setGroupLinks({
        groupVoteLinks: pollResponse?.group_vote_links,
        groupSpreadsheetLinks: spreadsheetResponse?.group_spreadsheet_links,
      });
    } catch (error) {
      handleError(error);
    }
  }

  shouldHaltProcess(response) {
    return !this._isMounted || isEmptyObjectOrFalsey(response) || response.error;
  }

  async fetchPersonalLinks(inputUser) {
    const personalLinks = await fetchPersonalLinks(
      inputUser ?? this.getSelectedUser()
    );
    if (!this._isMounted) {
      return;
    }

    if (personalLinks) {
      this.savePersonalLinks(personalLinks);
    }
  }

  determineLinkType(val) {
    if (isEmptyObjectOrFalsey(val)) {
      return null;
    }

    return val.value;
  }

  checkAllDayEventsBlocking() {
    if (this.isSlotsPlainText()) {
      if (isWarningAllDaySlotsWarning(this.state.warningAlert)) {
        // remove warning
        this.setState({ warningAlert: null });
      }
      return;
    }
    const userSelectedSlots = this.getUserSelectedSlots();
    const {
      blockedCalendarsID,
      blockedAttendeesCalendars,
      bufferFromNow,
      bufferBeforeEvent,
      bufferAfterEvent,
    } = this.state;
    availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.CHECK_FOR_BLOCKING_CALENDARS, {
      eventsToCheck: userSelectedSlots,
      blockingUserCalendarID: blockedCalendarsID,
      blockedAttendeeEmails: blockedAttendeesCalendars,
      additionalEvents: this._checkForConflictEvents,
      bufferFromNow,
      bufferBeforeEvent,
      bufferAfterEvent,
    });
  }

  removeSlotsBeforeAfterConflictWarning() {
    const {
      warningAlert,
    } = this.state;
    if (!warningAlert?.title) {
      // nothing to remove
      return;
    }
    if ([SLOTS_IN_BUFFER_BEFORE_CONFLICTS.title, SLOTS_IN_BUFFER_AFTER_CONFLICTS.title].includes(warningAlert.title)) {
      // only remove if warning is before or after conflict
      this.setState({ warningAlert: null });
    }
  }

  isExistingWarningAlert() {
    return this.state.warningAlert?.title;
  }

  setGenericSlotsWarning({ title, subText }) {
    const {
      isIgnoreConflicts,
    } = this.state;
    if (isIgnoreConflicts) {
      return;
    }
    if (this.isExistingWarningAlert()) {
      // warning already exists -> do not override
      return;
    }
    const updatedWarning = {
      title,
      subText,
    };
    this.setState({ warningAlert: updatedWarning });
  }

  setAllDaySlotsWarning(warningSubText) {
    const {
      isIgnoreConflicts,
      warningAlert,
    } = this.state;
    if (isIgnoreConflicts) {
      if (this.isAllDayBlockingWarning()) {
        this.setState({ warningAlert: null }); // remove warning
      }
      return;
    }
    if (!warningSubText && isWarningAllDaySlotsWarning(warningAlert)) {
      // remove warning
      this.setState({ warningAlert: null });
      return;
    }

    const updatedWarning = {
      title: ALL_DAY_BLOCKING_EVENT_TITLE,
      subText: warningSubText
    };
    if (this.isSameWarning(updatedWarning, warningAlert)) {
      // same warning, no point in updating
      return;
    }
    if (!warningSubText && !isWarningAllDaySlotsWarning(warningAlert)) {
      // do not allow this function to remove a differen warning
      return;
    }
    if (!warningSubText && !warningAlert) {
      // warning is already empty -> no need to update
      return;
    }
    this.setState({ warningAlert: updatedWarning });
  }

  determineCriticalAlert(duration) {
    const {
      currentTimeZone,
    } = this.props;
    const {
      criticalAlert,
    } = this.state;

    const userSelectedSlots = this.getUserSelectedSlots();

    if (isEmptyArray(userSelectedSlots)) {
      return null;
    }
    let longestDuration = 0;
    let latestSlot = null;
    userSelectedSlots.forEach((e) => {
      let duration = differenceInMinutes(e.eventEnd, e.eventStart);
      if (duration > longestDuration) {
        longestDuration = duration;
      }

      let endTime = e.eventEnd;
      if (!latestSlot) {
        latestSlot = endTime;
      } else if (isAfterMinute(endTime, latestSlot)) {
        latestSlot = endTime;
      }
    });

    let currentDuration = duration || this.state.duration;
    const currentTime = getCurrentTimeInCurrentTimeZone(currentTimeZone);
    const title = "Booking link will be empty";
    if (longestDuration < currentDuration && !this.isSlotsPlainText()) {
      return {title, subText: "The event duration is longer than any of the selected Slots."}
    }
    
    if (
      isBeforeMinute(
        latestSlot,
        currentTime
      )
    ) {
      return {title, subText: "All selected slots are in the past."};
    }
    if (criticalAlert && criticalAlert.title !== title) {
      // keep what we have if it's a different error
      return criticalAlert
    }
    return null;
  }

  determineWarningAlert() {
    const {
      currentTimeZone
    } = this.props;
    const userSelectedSlots = this.getUserSelectedSlots();
    if (isEmptyArray(userSelectedSlots)) {
      return null;
    }

    let firstStartTime = null;
    userSelectedSlots.forEach((e) => {
      if (!firstStartTime 
        || (isValidJSDate(firstStartTime) && isBeforeMinute(e.eventStart, firstStartTime))
      ) {
        firstStartTime = e.eventStart;
      }
    });

    const currentTime = getCurrentTimeInCurrentTimeZone(currentTimeZone);
    
    if (this.state.bufferFromNow
      && isValidJSDate(firstStartTime)
      && isBeforeMinute(
        firstStartTime,
        addMinutes(currentTime, this.state.bufferFromNow)
      )
    ) {
      // to check if we should show buffer from current time warning 
      return {title: "Warning", subText: "Some Slots will be unavailable due to the buffer from current time."};
    }

    const {
      warningAlert,
      blockedCalendarsID,
      isIgnoreConflicts,
    } = this.state;
    if (
      isEmptyArrayOrFalsey(blockedCalendarsID)
      && !this.isDoubleBookCriticalAlertWarning()
      && !this.isSlotsPlainText()
      && !isIgnoreConflicts
    ) {
      return {
        title: RISK_OF_DOUBLE_BOOKING_TITLE,
        subText: `Please scroll down to "Check for conflicts" inside "Meeting details" and make sure "${this.getSelectedCalendarDisplayName()}" is selected if you want to prevent meetings from being booked during times you have a conflict.`
      };
    }

    if (isWarningAllDaySlotsWarning(warningAlert)) {
      // if all day warning exists -> keep that warning, let this.setAllDaySlotsWarning() remove it.
      return warningAlert;
    }

    return null;
  }

  determineContentId() {
    return this.determineLinkType(this.state.availabilityType);
  }

  saveSlotsAndTimeZone() {
    const userSelectedSlots = this.getUserSelectedSlots();
    if (
      isEmptyObjectOrFalsey(this.getSelectedUser()) ||
      isEmptyArrayOrFalsey(userSelectedSlots)
    ) {
      return;
    }
    const {
      currentTimeZone,
    } = this.props;

    const slots = [];
    const guessedTimeZone = guessTimeZone();
    userSelectedSlots.forEach((s) => {
      slots.push({
        eventStart: getTimeInAnchorTimeZone(
          s.eventStart,
          currentTimeZone,
          guessedTimeZone,
        ).toISOString(),
        eventEnd: getTimeInAnchorTimeZone(
          s.eventEnd,
          currentTimeZone,
          guessedTimeZone,
        ).toISOString(),
        rbcEventEnd: protectMidnightCarryOver(
          getTimeInAnchorTimeZone(
            s.rbcEventEnd,
            currentTimeZone,
            guessedTimeZone,
          )
        ).toISOString(),
        index: s.index,
        raw_json: s.raw_json,
      });
    });

    const { setLastSelectedSlotsAndTimeZone } = this.props.availabilityStore;
    setLastSelectedSlotsAndTimeZone({ slots, timeZone: guessedTimeZone, currentTimeZoneOnCopySlots: currentTimeZone });
    saveLastSlotDuration(this.getSelectedUser(), this.state.duration);
  }

  setSlotsStateAndProps({state, props}) {
    this.setState(state);
    const {
      currentTimeZone,
      selectedDay,
      temporaryTimeZones,
      temporaryEvents
    } = props;
    if (isValidJSDate(selectedDay)) {
      this.props.selectDay(selectedDay);
    }
    const timeZoneData = {};
    if (currentTimeZone && currentTimeZone !== this.props.currentTimeZone) {
      timeZoneData.timeZone = currentTimeZone;
    }
    if (!isEmptyArrayOrFalsey(temporaryTimeZones)) {
      timeZoneData.groupTimeZone = temporaryTimeZones;
    }
    if (!isEmptyObjectOrFalsey(timeZoneData)) {
      Broadcast.publish("SELECT_TIME_ZONE", timeZoneData);
    }

    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      // need delay here otherwise when we switch time zones, temporary events also get switched up.
      mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", temporaryEvents);
    }, 0.5 * SECOND_IN_MS);
  }

  getPreviouslyUsedSlotsAndTimeZone() {
    const {
      currentTimeZoneOnCopySlots,
    } = this.props.availabilityStore;
    const {
      currentTimeZone,
      temporaryTimeZones,
    } = this.props;
    const previousTimeZoneOnMainCalendarUpdate = useTemporaryStateStore.getState().previousTimeZoneOnMainCalendarUpdate;
    const previouslySelectedSlots = getPreviouslySelectedSlots(
      isValidTimeZone(previousTimeZoneOnMainCalendarUpdate) ? previousTimeZoneOnMainCalendarUpdate : currentTimeZone,
      this.getSelectedUser(),
    );

    if (!previouslySelectedSlots) {
      return;
    }

    if (previouslySelectedSlots) {
      this.props.setTemporaryEvent(previouslySelectedSlots);
    }

    const lastUsedDuration = getLastSlotDuration(this.getSelectedUser());
    if (lastUsedDuration) {
      this.setState({ duration: lastUsedDuration });
    }

    if (!isEmptyArrayOrFalsey(temporaryTimeZones)) {
      // do nothing
    } else if (currentTimeZoneOnCopySlots && currentTimeZoneOnCopySlots !== currentTimeZone) {
      Broadcast.publish("SELECT_TIME_ZONE", {
        timeZone: currentTimeZoneOnCopySlots,
      });
    }
  }

  getBlockedCalendarsAndIDsCheck({selectedCalendar, inputBlockedCalendarsID}) {
    const blockedCalendarsID = inputBlockedCalendarsID ?? this.state.blockedCalendarsID;
    if (blockedCalendarsID?.includes(getCalendarUserCalendarID(selectedCalendar))) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const matchingUser = getMatchingUserFromAllUsers({ allUsers: allLoggedInUsers, userEmail: getCalendarUserEmail(selectedCalendar) });
    const matchingUserBlockedCalendars = getBlockedCalendars({ user: matchingUser, allLoggedInUsers, allCalendars, masterAccount });
    const matchingUserBlockedUserCalendarIDs = getBlockedCalendarsID(matchingUserBlockedCalendars);
    if (matchingUserBlockedUserCalendarIDs?.includes(getCalendarUserCalendarID(selectedCalendar))) {
      // if user has default calendars set
      return {
        blockedCalendars: matchingUserBlockedCalendars,
        blockedCalendarsID: matchingUserBlockedUserCalendarIDs,
      };
    } else {
      const updatedBlockedCalendars = [{
        user: matchingUser,
        calendars: [getCalendarObject(selectedCalendar)]
      }];
      return {
        blockedCalendars: updatedBlockedCalendars,
        blockedCalendarsID: [getCalendarUserCalendarID(selectedCalendar)],
      };
    }
  }

  onChangeCalendar(selectedCalendar) {
    if (isEmptyObjectOrFalsey(selectedCalendar)) {
      return;
    }
    const updatedState = { selectedCalendar, selectedOutlookCategories: []};
    const checkedBlockedCalendarAndBlockedCalendarsID = this.getBlockedCalendarsAndIDsCheck({selectedCalendar});
    if (checkedBlockedCalendarAndBlockedCalendarsID) {
      updatedState.blockedCalendars = checkedBlockedCalendarAndBlockedCalendarsID.blockedCalendars;
      updatedState.blockedCalendarsID = checkedBlockedCalendarAndBlockedCalendarsID.blockedCalendarsID;
    }
    this.setState(updatedState);
  }

  onSelectConferencing(conferencing) {
    this.setState({ conferencing });
  }

  onClickTemplate() {
    Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER");
  }

  onClickSettings() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { 
      initialContent: APP_SETTINGS.SLOTS,
      initialSlotsSettingsUser: this.getSelectedUser(),
    });
  }

  isGroupVotePage() {
    return this.state.availabilityType.value === BACKEND_GROUP_VOTE_LINK;
  }

  isLinkAlone() {
    return (
      this.state.slotsType === SLOTS_LINK_ALONE ||
      this.state.slotsType?.value === LINK_ALONE
    );
  }

  isSlotsRichText() {
    return this.state.slotsType.value === SLOTS_RICH_TEXT;
  }

  isSlotsPlainTextURL() {
    return this.state.slotsType.value === SLOTS_PLAIN_TEXT_URL;
  }

  isSlotsPlainText() {
    return this.state.slotsType.value === SLOTS_PLAIN_TEXT;
  }

  updateSlotsStyle(slotsStyle) {
    switch (slotsStyle) {
      case SLOTS_PLAIN_TEXT:
        this.setState({ slotsType: SLOTS_SELECT_TYPE_PLAIN_TEXT });
        return;
      case SLOTS_PLAIN_TEXT_URL:
        this.setState({ slotsType: SLOTS_SELECT_TYPE_TEXT_URL });
        return;
      case SLOTS_RICH_TEXT:
        this.setState({ slotsType: SLOTS_SELECT_TYPE_HYPER_LINKED });
        return;
      case SLOTS_LINK_ALONE.value:
        this.setState({ slotsType: SLOTS_LINK_ALONE });
        return;
      default:
        return;
    }
  }

  // removes the last availability event
  deleteLastAvailabilityEvent() {
    const lastAvailabilityEvent = getLastElementOfArray(
      this.getUserSelectedSlots(),
    );
    if (!lastAvailabilityEvent) {
      return;
    }

    Broadcast.publish("DELETE_AVAILABILITY_SLOT", lastAvailabilityEvent); // deletes slot
  }

  updateUserDraggedSlotsTimeZone({ prevTimeZone, newTimeZone }) {
    if (prevTimeZone === newTimeZone) {
      return;
    }

    const updatedSlots = updateEventsTime({
      events: this._draggedSlots,
      prevTimeZone,
      newTimeZone,
    });

    this._draggedSlots = updatedSlots;
  }

  setUserDraggedSlots({ originalSlot = null, newSlot, type = ADD_SLOT }) {
    const setSlot = (updatedSlots) => {
      const combinedSlots = combineSlots(updatedSlots);
      this._draggedSlots = combinedSlots;
    };

    if (type === ADD_SLOT) {
      // add slot
      const updatedSlot = this._draggedSlots.concat(newSlot);
      setSlot(updatedSlot);
    } else if (type === UPDATE_SLOT) {
      // update slot
      const filteredSlots = this._draggedSlots.filter(
        (s) => !isSameSlot(s, originalSlot)
      );

      setSlot(filteredSlots.concat(newSlot));
    } else if (type === DELETE_SLOT) {
      // delete slot
      const filteredSlots = this._draggedSlots.filter(
        (s) => !isSameSlot(s, originalSlot)
      );

      setSlot(filteredSlots);
    }
  }

  getAvailabilityUserName() {
    const { masterAccount } = this.props.masterAccount;
    const { userName } = getUserName({
      masterAccount,
      user: this.getSelectedUser(),
    });

    return userName;
  }

  createSlug({
    inputDuration,
    inputToken,
    inputAttendees,
    inputInviteeEmail,
    inputInviteeFullName,
  }) {
    // need to do this since we are using this in constructor for this.state
    const duration = inputDuration ?? this.state?.duration ?? 30;
    const token = inputToken ?? this.state?.token ?? "";
    const attendees = inputAttendees ?? this.state?.attendees ?? [];
    const optionalInviteeName =
      inputInviteeFullName ?? this.state?.optionalInviteeName ?? "";
    const optionalInviteeEmail =
      inputInviteeEmail ?? this.state?.optionalInviteeEmail ?? "";

    const slotsToken = inputToken ?? token;
    return (
      slugifyString(
        `${inputDuration ?? duration}-min${this.getAttendeeSlug({
          inviteeName: optionalInviteeName ?? inputInviteeFullName,
          inviteeEmail: optionalInviteeEmail ?? inputInviteeEmail,
          attendees: inputAttendees ?? attendees,
        })}`
      ) +
      "-" +
      slotsToken.slice(-6)
    );
  }

  getAttendeeSlug({ inviteeName, inviteeEmail, attendees }) {
    if (inviteeName) {
      return `-${this.getFullNameSlug({ fullName: inviteeName })})}`;
    } else if (inviteeEmail) {
      return `-${inviteeEmail.substring(0, 5)}`;
    } else {
      const firstAttendee = attendees?.[0];
      if (isEmptyObjectOrFalsey(firstAttendee)) {
        return "";
      }

      return firstAttendee.label
        ? `-${firstAttendee.label.substring(0, 5)}`
        : "";
    }
  }

  getFullNameSlug({ fullName }) {
    if (!fullName) {
      return null;
    }

    const nameParts = fullName.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts[1];

    // if name is "aaron harris" -> below gets "aharr"
    const firstNameSlug = firstName?.slice(0, 5) ?? "";
    const lastNameSlug =
      firstNameSlug.length < 5
        ? lastName?.slice(0, 5 - firstNameSlug.length) ?? ""
        : "";
    const nameSlug = firstNameSlug + lastNameSlug;

    return nameSlug;
  }

  removeHoverTooltip() {
    this.setState({
      hoveringToolTip: null,
    });
  }

  closeGetAvailableSlotsModal() {
    modalBroadcast.publish("REMOVE_BOTTOM_MODAL_CONTENT");
  }

  openGetAvailableSlotsModal() {
    modalBroadcast.publish(MODAL_BROADCAST_VALUES.SET_BOTTOM_MODAL_CONTENT, MODAL_TYPES.FIND_FREE_TIMES);
  }

  refreshAllUsersForSelect() {
    this.setState({
      allUsersForSelect: this.getAllUsersForSelect(),
    });
  }

  getSelectedUser() {
    const { currentUser } = this.props;

    if (!this.state) {
      return currentUser;
    }

    const { selectedUserIndex, allUsersForSelect } = this.state;
    return allUsersForSelect[selectedUserIndex]?.value ?? currentUser;
  }

  shouldRenderSelectAccount() {
    const { allUsersForSelect } = this.state;
    return allUsersForSelect.length > 1;
  }

  setSelectedUser(userEmail) {
    if (!userEmail) {
      return;
    }
    const matchingUserIndex = this.state.allUsersForSelect.findIndex(
      (user) => isSameEmail(getUserEmail(user?.value), userEmail)
    );
    this.onSelectUserIndex(matchingUserIndex);
  }

  onSelectUserIndex(index) {
    const { allUsersForSelect } = this.state;

    const updatedUser = allUsersForSelect[index]?.value;

    if (!updatedUser) {
      // should never get to here
      return;
    }

    this.fetchGroupVoteLinks(updatedUser);

    const userPersonalLinks = getSavedPersonalLinks(updatedUser); // get the personal links saved from local data
    this.fetchPersonalLinks(updatedUser);

    const { setSelectedUser } = this.props.availabilityStore;
    setSelectedUser(updatedUser);

    const updatedAccountState = this.getUpdatedAccountState(updatedUser);
    this.fetchGroupVoteLinks(updatedUser);
    if (
      updatedAccountState.isCombineAdjacentSlots !==
      this.state.isCombineAdjacentSlots
    ) {
      // combine or split up slots
      const { setBreakDuration, resetBreakDuration } =
        this.props.availabilityStore;
      if (updatedAccountState.isCombineAdjacentSlots) {
        resetBreakDuration();
        this.props.setTemporaryEvent(this._draggedSlots);
      } else {
        setBreakDuration(updatedAccountState.duration);
        this.breakExistingSlotTemporaryEventsIntoDuration(
          updatedAccountState.duration
        );
      }
    }

    this.props.setAvailabilitySelectedMinutes(updatedAccountState.duration);

    if (updatedAccountState.description !== this.state.description) {
      this.setDescriptionInQuill(updatedAccountState.description);
    }

    this.setState(
      {
        ...{ selectedUserIndex: index },
        ...updatedAccountState,
        ...{ personalLinks: userPersonalLinks },
        selectedOutlookCategories: [],
      },
      () => {
        const { setSelectedUser } = this.props.availabilityStore;
        setSelectedUser(this.getSelectedUser());
        this.createTextFromSelection();
        broadcast.publish(`UPDATE_EVENT_INPUT_VALUE_${CONTAINER_ID}`, this.state.eventTitle);

        broadcast.publish(BROADCAST_VALUES.REFETCH_MEET_WITH_EVENTS, {
          userEmail: getUserEmail(updatedUser),
          isTeamSlots: true,
        });
      }
    );
  }

  renderSectionPreview({ text, onClick }) {
    if (!text) {
      return null;
    }

    return (
      <div
        className="default-font-size secondary-text-color cursor-pointer mt-1 select-none"
        onClick={onClick}
      >
        {text}
      </div>
    );
  }

  renderSelectAccounts() {
    const { isDarkMode } = this.props;

    if (!this.shouldRenderSelectAccount()) {
      return null;
    }

    return (
      <SelectUser
        onMenuClose={this.blurMainCalendar}
        setSelectedUserIndex={this.onSelectUserIndex}
        id={SELECT_USER_ID}
        selectedUser={this.getSelectedUser()}
        addExecutiveLabel={true}
        inputContainerClassName={"mr-2"}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          controlWidth: "68px",
          menuListStyle: customMenuStyle({
            width: 200,
            zIndex: 15,
            right: "-32px",
          }),
          singleValueDisplay: "flex",
          valueContainerHeight: "24px",
        })}
        useAccountProfileAsSelectedValue={true}
      />
    );
  }

  renderSelectType() {
    const { availabilityType, showSelector } = this.state;

    const getLeftPositionForHighLight = () => {
      if (availabilityType.value === BOOKING_LINK) {
        return "6px";
      }

      if (availabilityType.value === BACKEND_PERSONAL_LINK) {
        return "112px";
      }

      if (availabilityType.value === BACKEND_GROUP_VOTE_LINK) {
        return "218px";
      }
    };

    return (
      <div
        className={classNames(
          "flex items-center select-availability-type-selector px-1.5 relative",
          "duration-300",
          showSelector ? "opacity-100" : "opacity-0"
        )}
      >
        <div
          className={classNames("absolute", "duration-200")}
          style={{
            width: "106px",
            height: "25px",
            left: getLeftPositionForHighLight(),
            transition: showSelector ? "left 300ms" : undefined,
            backgroundColor: this.props.isDarkMode ? "#444754" : "white",
            boxShadow:
              "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px",
            borderRadius: "6px",
          }}
        ></div>
        {SELECT_COPY_TYPE_OPTIONS.map((option, index) => {
          return (
            <div
              className="flex py-1.5 z-10"
              key={`availability-option-${index}`}
            >
              <button
                key={`eventResponse${index}`}
                style={this.determineResponseButtonStyle(option)}
                onClick={() => this.onChangeAvailabilityType(option)}
                className={classNames("hoverable-container-text duration-200")}
              >
                {option.label}
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  determineResponseButtonStyle(option) {
    const isOptionSelected = option.value === this.state.availabilityType.value;

    return {
      fontWeight: "400",
      backgroundColor: "transparent",
      // backgroundColor: isOptionSelected ? "red" : "transparent",
      fontSize: 12,
      color: isOptionSelected ? this.determineDefaultTextColor() : null,
      borderRadius: 6,
      width: "106px",
    };
  }

  determineDefaultTextColor() {
    return this.props.isDarkMode
      ? StyleConstants.darkModeTextColor
      : DEFAULT_FONT_COLOR;
  }

  getEventModalStyle() {
    const { modalTop } = this.state;
    const { isDarkMode } = this.props;
    if (this.isSelectSlotsStyleModal() || this.isSelectSlotsTextFormatModal()) {
      return getTransparentModalStyle({
        top: modalTop,
        isDarkMode,
        additionalContentStyle: {
          backgroundColor: isElectron()
            ? getModalBackgroundColorWithNoOpacity(isDarkMode)
            : getModalBackgroundColorWithExtraOpacity(isDarkMode),
          color: isDarkMode ? StyleConstants.darkModeModalTextColor : "",
          maxHeight: "90vh",
          backdropFilter: MODAL_BLUR,
          WebkitBackdropFilter: MODAL_BLUR,
          border: getDefaultModalBorder(isDarkMode),
          right: "16px",
          zIndex: 5,
        },
      });
    }
    return determineDefaultModalStyle(this.props.isDarkMode);
  }

  getEventModalContent() {
    const getDisabledFormats = () => {
      if (this.getAllTimeZones()?.length > 1 && this.state.contentShowsAllTimeZones) {
        return [TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE];
      }
      return [];
    };
    const getAlwaysCheckedFormats = () => {
      if (this.getAllTimeZones()?.length > 1 && this.state.contentShowsAllTimeZones) {
        return [TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE];
      }
      return [];
    };

    switch (this.state.eventModalContent) {
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_TEXT_STYLES:
        return (
          <SlotsTextStyleModal
            onChangeTextStyles={this.onChangeTextStyles}
            textStyles={this.state.textStyles}
            disabledFormats={getDisabledFormats()}
            alwaysCheckedFormats={getAlwaysCheckedFormats()}
          />
        );
      case EVENT_MODAL_CONTENT.CHECK_FOR_CONFLICT:
        return (
          <SelectBlockedCalendars
            onClickSave={(updatedBlockedCalendarsID) => {
              this.closeModal();
              this.setState({
                blockedCalendarsID: updatedBlockedCalendarsID,
              });
            }}
            onClickCancel={this.closeModal}
            showSetAsDefault={true}
            inputBlockedCalendars={this.state.blockedCalendars}
            blockedCalendarsID={this.state.blockedCalendarsID}
            selectedUser={this.getSelectedUser()}
          />
        );
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_STYLE:
        return (
          <SelectStyleModal
            onChangeSlotStyle={this.onChangeSlotStyle}
            slotsType={this.state.slotsType}
          />
        );
      case EVENT_MODAL_CONTENT.PERSONAL_LINKS_TRAVELING:
        return (
          <PersonalLinksTraveling 
            onClose={this.closeModal} 
            user={this.getSelectedUser()}
          />
        );
      default:
        return null;
    }
  }

  getEventModalWidth() {
    switch (this.state.eventModalContent) {
      case EVENT_MODAL_CONTENT.CHECK_FOR_CONFLICT:
        return 420;
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_STYLE:
        // return CONTENT_WIDTH + 3;
        return 326;
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_TEXT_STYLES:
        // return CONTENT_WIDTH + 3;
        return 326;
      default:
        return 420;
    }
  }
  getEventModalTitle() {
    switch (this.state.eventModalContent) {
      case EVENT_MODAL_CONTENT.CHECK_FOR_CONFLICT:
        return "Check for conflict";
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_STYLE:
        return "Booking link style";
      case EVENT_MODAL_CONTENT.SELECT_SLOTS_TEXT_STYLES:
        return "Text styles";
      case EVENT_MODAL_CONTENT.PERSONAL_LINKS_TRAVELING:
        return "My trips";
      default:
        return "";
    }
  }

  isSelectSlotsStyleModal() {
    return (
      this.state.eventModalContent === EVENT_MODAL_CONTENT.SELECT_SLOTS_STYLE
    );
  }

  isSelectSlotsTextFormatModal() {
    return (
      this.state.eventModalContent === EVENT_MODAL_CONTENT.SELECT_SLOTS_TEXT_STYLES
    );
  }

  async getUpcomingSlotsAvailability() {
    availabilityBroadcast.publish("GET_UPCOMING_WEEK_AVAILABILITY");
  }

  showLoadingFindTimeSpinner() {
    this.setState({ isLoadingFindTimes: true });
  }

  resetLoadingFindTimes() {
    if (!this.state.isLoadingFindTimes) {
      return;
    }
    this.setState({ isLoadingFindTimes: false });
  }

  getUpdatedAccountState(user) {
    const { allCalendars } = this.props.allCalendars;
    const { lastSelectedSlotsStyle } = this.props.appSettings;
    const { masterAccount } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const selectedUser = user ?? this.getSelectedUser();
    const {
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      title,
      duration,
      conferencing,
      googleId,
      location,
      description,
      allowReschedule,
      bufferFromNow,
      combineAdjacentSlots,
      holdTitle,
      contentShowsAllTimeZones,
      token,
      blockedCalendars,
      shouldAddAttendeesToHolds,
      bufferBeforeEvent,
      bufferAfterEvent,
    } = this.getAccountSettingsInfo(user);
    const selectedCalendar = getCalendarFromProviderID({
      allCalendars,
      providerID: googleId,
      selectedUser,
      allLoggedInUsers,
      masterAccount,
    }) || getCalendarFromEmail({
      email: getUserEmail(selectedUser),
      allLoggedInUsers,
      allCalendars,
      masterAccount,
    });
    const blockedCalendarsID = getBlockedCalendarsID(blockedCalendars);
    const checkedBlockedCalendarAndBlockedCalendarsID = this.getBlockedCalendarsAndIDsCheck({selectedCalendar, inputBlockedCalendarsID: blockedCalendarsID});
    return {
      allowReschedule: allowReschedule,
      duration,
      conferencing,
      token,
      eventTitle: title,
      location,
      description,
      selectedCalendar,
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      slotsType: determineSlotsSelectType({
        availabilitySettings: getUserAvailabilitySettings({ user: selectedUser }),
        lastSelectedSlotsStyle,
        masterAccount,
        currentUser: selectedUser,
      }),
      slotsHoldTitle: holdTitle,
      bufferFromNow,
      blockedCalendars: checkedBlockedCalendarAndBlockedCalendarsID ? checkedBlockedCalendarAndBlockedCalendarsID.blockedCalendars : blockedCalendars,
      blockedCalendarsID: checkedBlockedCalendarAndBlockedCalendarsID ? checkedBlockedCalendarAndBlockedCalendarsID.blockedCalendarsID : blockedCalendarsID,
      isCombineAdjacentSlots: combineAdjacentSlots, // no need to set this here
      slug: this.createSlug({ inputDuration: duration, inputToken: token }),
      contentShowsAllTimeZones,
      combineAdjacentSlots,
      shouldAddAttendeesToHolds,
      bufferBeforeEvent,
      bufferAfterEvent
    };
  }

  getAllUsersForSelect() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;
    return getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: SELECT_USER_TYPE.ALL_USERS,
    });
  }

  getAccountSettingsInfo(user) {
    const { currentUser } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const {
      shouldShowAllTimeZonesInSlots,
    } = this.props.appSettings;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const selectedUser = user ?? currentUser;
    const {
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      title,
      duration,
      conferencing,
      googleId,
      location,
      description,
      allowReschedule,
      bufferFromNow,
      combineAdjacentSlots,
      [HOLD_TITLE]: holdTitle,
      contentShowsAllTimeZones,
      [ADD_ATTENDEE_TO_HOLDS]: shouldAddAttendeesToHolds,
      bufferBeforeEvent,
      bufferAfterEvent,
    } = getSlotsPresets({
      allCalendars,
      currentUser: selectedUser,
      masterAccount,
    });
    const blockedCalendars = getBlockedCalendars({
      user: selectedUser,
      allLoggedInUsers,
      allCalendars,
      masterAccount,
    });
    const token = generateAvailabilityToken(selectedUser);
    return {
      plainTextCopy,
      plainTextURLCopy,
      plainTextURLLinkCopy,
      richTextCopy,
      title,
      duration,
      conferencing,
      googleId,
      location,
      description,
      allowReschedule,
      bufferFromNow,
      combineAdjacentSlots,
      holdTitle,
      contentShowsAllTimeZones: !isNullOrUndefined(shouldShowAllTimeZonesInSlots) ? shouldShowAllTimeZonesInSlots : contentShowsAllTimeZones,
      token,
      blockedCalendars,
      shouldAddAttendeesToHolds,
      bufferBeforeEvent,
      bufferAfterEvent
    };
  }

  async getCheckForConflictBlockedEvents() {
    const {
      currentUser,
      selectedDay,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;

    // only fetch for calendars that are not showing
    const activeCalendarUserCalendarIDs = getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(currentUser),
    }) ?? [];
    const filteredUserCalendarID = this.state.blockedCalendarsID?.filter(id => !activeCalendarUserCalendarIDs.includes(id))
    if (isEmptyArray(filteredUserCalendarID)) {
      return;
    }
    const getStartAndEndTime = () => {
      const today = startOfDay(new Date());
      if (isSameDay(selectedDay, today) || isBeforeDay(selectedDay, today)) {
        return {
          startTime: formatISO(today),
          endTime: formatISO(endOfDay(addDays(today, 14)))
        }
      }

      return {
        startTime: formatISO(subDays(selectedDay, 7)),
        endTime: formatISO(endOfDay(addDays(selectedDay, 7)))
      };
    }

    const {
      startTime,
      endTime
    } = getStartAndEndTime();
    const promises = filteredUserCalendarID.map(async (userCalendarID) => {
      const response = await fetchFreeBusySlots({
        currentUser: this.getSelectedUser(),
        conferencing: null,
        bookableSlots: [{
          start_time: startTime,
          end_time: endTime,
          index: 0
        }],
        blockedCalendars: [userCalendarID], // userCalendarIDs
        blockedAttendeeEmails: [],
      });
      return { userCalendarID, response };
    });

    Promise.all(promises)
      .then((responses) => {
        if (!this._isMounted) {
          return;
        }
        let updatedBusyTimes = [];
        responses.forEach((finishedPromise) => {
          const unformattedBusy = finishedPromise?.response?.free_busy?.[0]?.busy_slots;
          if (isEmptyArray(unformattedBusy)) {
            return;
          }
          const parsedBusyEvents = unformattedBusy.map(freeBusySlot => {
            const eventStart = parseISO(freeBusySlot.start);
            const eventEnd = parseISO(freeBusySlot.end);
            const isAllDay = differenceInDays(eventEnd, eventStart) > 0;
            return {
              eventStart,
              eventEnd,
              transparency: BUSY_DURING_EVENT,
              event_start: isAllDay ? {date: formatISO(eventStart, ISO_DATE_FORMAT)} : {date_time: freeBusySlot.start},
              event_end: isAllDay ? {date: formatISO(eventEnd, ISO_DATE_FORMAT)} : {date_time: freeBusySlot.end},
              user_calendar_id: finishedPromise.userCalendarID,
              isCheckForConflictEventCalendar: true,
            };
          });
          updatedBusyTimes = updatedBusyTimes.concat(parsedBusyEvents);
        });
        this._checkForConflictEvents = updatedBusyTimes;
        this.checkAllDayEventsBlocking();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  getAllLoggedInUserIndex(user) {
    const allUsers = this.getAllUsersForSelect();
    const matchingIndex = allUsers.findIndex(
      (loggedInUser) => isSameEmail(getUserEmail(loggedInUser?.value), getUserEmail(user))
    );
    if (matchingIndex < 0) {
      return 0;
    }

    return matchingIndex;
  }

  setDescriptionInQuill(description) {
    this.description?.current?.setEditorContents(
      this.description.current?.getEditor(),
      description
    );
  }

  blurMainCalendar() {
    blurCalendar();
  }

  onChangeBlockedAttendeesCalendars(attendeeEmails) {
    this.setState({ blockedAttendeesCalendars: attendeeEmails });
  }

  openTravelingModal() {
    this.setState({
      eventModalContent: EVENT_MODAL_CONTENT.PERSONAL_LINKS_TRAVELING,
      shouldDisplayModal: true,
    })
  }

  isPlaintextHolds() {
    return SLOTS_PLAIN_TEXT === this.state.slotsType.value && this.state.showSlotsHoldForm;
  }
  
  isSameWarning(warningA, warningB) {
    if (isEmptyObjectOrFalsey(warningA) && !isEmptyObjectOrFalsey(warningB)) {
      return false;
    }
    if (!isEmptyObjectOrFalsey(warningA) && isEmptyObjectOrFalsey(warningB)) {
      return false;
    }
    return warningA?.title === warningB?.title 
      && warningA?.subText === warningB?.subText;
  }

  isDoubleBookCriticalAlertWarning() {
    const {
      criticalAlert
    } = this.state;
    return criticalAlert?.title?.includes("double booked")
  }

  isAllDayBlockingWarning() {
    const {
      warningAlert
    } = this.state;
    return warningAlert?.title === ALL_DAY_BLOCKING_EVENT_TITLE;
  }

  getSelectedCalendarDisplayName() {
    const {
      emailToNameIndex,
      currentUser
    } = this.props;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      selectedCalendar
    } = this.state;
    return getCalendarName({
      calendar: selectedCalendar,
      emailToNameIndex,
      currentUser,
      masterAccount,
    });
  }

  isUserMaestroUser() {
    const { masterAccount } = this.props.masterAccount;
    return isUserMaestroUser(masterAccount);
  }

  getPreviousSlots() {
    const { masterAccount } = this.props.masterAccount;

    if (this.isUserMaestroUser()) {
      const { outstandingSlots } = this.props.outstandingSlotsStore;
      const selectedUser = this.getSelectedUser();
      if (outstandingSlots) {
        return filterOutstandingSlotsByUser(outstandingSlots, selectedUser, masterAccount);
      } else {
        return [];
      }
    }
    return this.state.previousSelectedSlots ?? [];
  }

  hasPreviousSlots() {
    return this.getPreviousSlots().length > 0;
  }

  showNotificationAndSetLastState(message = DEFAULT_COPIED_SLOTS_MESSAGE) {
    const { slug: omitted, token: ommitedToken, ...restOfState } = this.state;
    availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.REUSE_SLOTS_NOTIFICATION, {
      message,
      slotsData: {state: restOfState, props: this.props},
    });
  }

  getSortedGroupVoteLinks() {
    const { groupSpreadsheetLinks, groupVoteLinks } = this.props.groupVoteStore;
    const allGroupLinks = [...groupSpreadsheetLinks, ...groupVoteLinks];
    return sortGroupVote(allGroupLinks);
  }

  setUpdateIngoreInternalConflicts(isIgnoreInternalConflicts) {
    this.setState({ isIgnoreInternalConflicts });
  }

  pasteTextIntoDescription(text) {
    if (!text) {
      return;
    }
    const updatedDescription = (this.state.description || "") + text;
    this.setDescriptionInQuill(updatedDescription);
  }

  shouldShowSlotsDetailContent() {
    return this.getUserSelectedSlots()?.length > 0;
  }

  scrollToTopOfPage() {
    try {
      if (!this.topRef?.current || !this.topRef?.current?.scrollIntoView) {
        return;
      }
      this.topRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    } catch (error) {
      handleError(error);
    }
  }

  checkForUpdateAccountOnDayViewDrag() {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      temporaryEvents
    } = this.props;
    const resourceId = temporaryEvents?.[0]?.resourceId;
    if (!resourceId) {
      return;
    }
    const matchingCalendarFromResourceId = getMatchingCalendarWithCalendarOwnerFromEmail({
      email: resourceId,
      allCalendars,
      checkForPrimaryOnly: true,
    });
    if (!matchingCalendarFromResourceId) {
      return;
    }
    this.onChangeCalendar(matchingCalendarFromResourceId);
    const userEmail = getCalendarUserEmail(matchingCalendarFromResourceId);
    if (isSameEmail(userEmail, getUserEmail(this.getSelectedUser()))) {
      return;
    }
    this.onChangeUser(userEmail);
  }

  checkForUnselectedInitialCalendar() {
    const {
      selectedCalendar,
      blockedCalendarsID,
    } = this.state;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const selectedCalendarUserCalendarID = getCalendarUserCalendarID(selectedCalendar);
    const shouldDisplayDoubleBookWarning = blockedCalendarsID?.length > 0 &&
      !blockedCalendarsID.includes(selectedCalendarUserCalendarID) &&
      !this.isSlotsPlainText() &&
      !this.state.isIgnoreConflicts;
    if (isCalendarSelected(selectedCalendar) && !shouldDisplayDoubleBookWarning) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const writableCalendars = filterForAllWritableCalendars(allCalendars);

    const bestGuessWritableCalendar = findBestGuessWritableCalendar({
      writableCalendars,
      currentUser: this.getSelectedUser(),
      checkForPrimaryOnly: true, // we only want to check for primary and exec calendars
      masterAccount,
      allLoggedInUsers,
      skipCheckForMatchingDelegateSecondaryCalendar: false,
      allCalendars,
      checkForMatchingUserCalendar: true,
    });
    if (isEmptyObjectOrFalsey(bestGuessWritableCalendar)) {
      return;
    }
    const isExecCalendar = isCalendarExecutiveCalendar({calendar: bestGuessWritableCalendar, allLoggedInUsers});
    const getUpdatedCalendarUserEmail = () => {
      if (isUserMaestroUser(masterAccount) && isExecCalendar) {
        // get matching executive user
        return getUserEmail(getMatchingExecutiveUserFromCalendar({ calendar: bestGuessWritableCalendar, allLoggedInUsers }))
          || getCalendarUserEmail(bestGuessWritableCalendar);
      }
      return getCalendarUserEmail(bestGuessWritableCalendar);
    };
    const updatedCalendarUserEmail = getUpdatedCalendarUserEmail();
    const shouldChangeUser = updatedCalendarUserEmail && !isSameEmail(updatedCalendarUserEmail, getUserEmail(this.getSelectedUser()));
    if (!shouldChangeUser) {
      // we only need to change calendar if the user is the same since otherwise, onChangeUser will change the calendar and could cause race condition between setting state
      this.onChangeCalendar(bestGuessWritableCalendar);
      return;
    }
    this.onChangeUser(updatedCalendarUserEmail);
  }

  onChangeUser(userEmail) {
    if (!userEmail) {
      return;
    }
    const {
      allUsersForSelect,
    } = this.state;
    if (isEmptyArrayOrFalsey(allUsersForSelect)) {
      return;
    }
    const matchingUserIndex = allUsersForSelect.findIndex((user) => isSameEmail(getUserEmail(user.value), userEmail));
    if (matchingUserIndex === -1) {
      return;
    }
    this.onSelectUserIndex(matchingUserIndex);
  }

  isHorizontalText() {
    return this.state.textStyles?.includes(TEXT_STYLE_OPTIONS.HORIZONTAL);
  }

  handleMigration() {
    // to handle backwards compatibility
    const {
      slotsVersion,
      setSlotsVersion,
    } = this.props.appSettings;
    if (slotsVersion === LATEST_SLOTS_VERSION) {
      return;
    }
    const {
      contentShowsAllTimeZones,
    } = this.state;
    if (!slotsVersion || slotsVersion < SLOTS_VERSION.V2) {
      // this makes it so for existing users, they see the same default experience as before for single time zone
      if (contentShowsAllTimeZones) {
        this.onChangeTextStyles(TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE);
      }
    }
    setSlotsVersion(LATEST_SLOTS_VERSION);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAvailabilitySelectedMinutes: (data) =>
      dispatch({ data: data, type: "SET_AVAILABILITY_SELECTED_MINUTES" }),
    setTemporaryEvent: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
    setPersonalLinks: (event) =>
      dispatch({ data: event, type: "SET_PERSONAL_LINKS" }),
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
  };
}

function mapStateToProps(state) {
  let {
    isMac,
    shouldShowTopBar,
    temporaryEvents,
    currentTimeZone,
    currentUser,
    defaultBrowserTimeZone,
    isDarkMode,
    eventFormEmails,
    currentTimeZoneLabel,
    format24HourTime,
    personalLinks,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    selectedDay,
    dateFieldOrder,
    selectedCalendarView,
  } = state;

  return {
    isMac,
    shouldShowTopBar,
    temporaryEvents,
    currentTimeZone,
    currentUser,
    defaultBrowserTimeZone,
    isDarkMode,
    eventFormEmails,
    currentTimeZoneLabel,
    format24HourTime,
    personalLinks,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    selectedDay,
    dateFieldOrder,
    selectedCalendarView,
  };
}

const withStore = (BaseComponent) => (props) => {
  const store = useGroupVoteStore();
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const availabilityStore = useAvailabilityStore();
  const allLoggedInUsers = useAllLoggedInUsers();
  const appTimeZone = useAppTimeZones();
  const outstandingSlotsStore = useOutstandingSlotsStore();
  const distroListDictionary = useDistroListDictionary();
  const accountActivity = useAccountActivity();
  const appSettings = useAppSettings();

  return (
    <BaseComponent
      {...props}
      groupVoteStore={store}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      availabilityStore={availabilityStore}
      allLoggedInUsers={allLoggedInUsers}
      appTimeZone={appTimeZone}
      outstandingSlotsStore={outstandingSlotsStore}
      distroListDictionary={distroListDictionary}
      accountActivity={accountActivity}
      appSettings={appSettings}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(AvailabilityPanel)));

