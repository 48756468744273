import React, { useEffect, useState } from "react";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { ArrowRight } from "react-feather";
import { trackEvent } from "../tracking";
import { isSelfServeOpen } from "../../lib/featureFlagFunctions";
import { setHasBookedOnboarding } from "../../services/accountFunctions";
import { getUserEmail, getUserName, getUserToken } from "../../lib/userFunctions";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import classNames from "classnames";
import { WHITE_BUTTON } from "../../services/globalVariables";
import RoundRobinScheduler from "./roundRobinScheduler";
import CalendlyBookingPage from "../specialComponents/calendlyBookingPage";


export default function WelcomePageEmphasizePersonalOnboarding({
  isEA,
  numberOfExecutiveAssistants,
  ...props
}) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const [
    hasCompletedPersonalOnboardingBooking,
    setHasCompletedBookingPersonalOnboarding,
  ] = useState(false);
  const isBlockedAfterOnboardingSignup = props.shouldSeeMandatoryOnboarding; // always blocking for this experiment

  useEffect(() => {
    const {
      step,
    } = props;
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
      additionalInfo: `shouldSeeMandatoryOnboarding: ${props.shouldSeeMandatoryOnboarding}`,
    });
  }, []);

  const takeMeToSelfOnboardingButton = () => {
    if (!isSelfServeOpen()) {
      return;
    }
    if (isBlockedAfterOnboardingSignup) {
      return null;
    }
    const onClick = () => {
      // loadTheme(DARK_MODE_THEME);
      // AppBroadcast.publish("SET_APP_DARK_MODE");
      trackEvent({
        category: "onboarding",
        action: "chose_self_serve",
        label: "personal_onboarding_signup",
        userToken: getUserToken(currentUser),
      });

      props.onClickNext();
    };

    return (
      <div
        className={classNames(
          "mb-4",
          "flex justify-end",
          "skip-personal-onboarding-button-wrapper"
        )}
      >
        <div
          className={classNames(
            "custom-button-box overflow-y-hidden rounded-lg",
            "hoverable-secondary-text-color-override",
            WHITE_BUTTON,
            "font-size-14",
            "min-width-initial-important",
            "hoverable-secondary-text",
            "font-size-400-important",
            "font-size-14-important",
            "w-max-content-important"
          )}
          onClick={onClick}
        >
          Let me explore Vimcal
          <ArrowRight className="ml-2" size={16} />
        </div>
      </div>
    );
  };

  const onComplete = () => {
    trackEvent({
      category: "onboarding",
      action: "3_completed_personal_onboarding_signup",
      label: "personal_onboarding_signup",
      userToken: getUserToken(currentUser),
    });

    setHasBookedOnboarding();

    setHasCompletedBookingPersonalOnboarding(true);
  };

  const renderTakeMeToSelfOnboardingButton = () => {
    if (
      props.shouldSeeMandatoryOnboarding &&
      !hasCompletedPersonalOnboardingBooking
    ) {
      return null;
    }

    return takeMeToSelfOnboardingButton();
  };

  const getNormalOnboardingSubtext = () => {
    return isBlockedAfterOnboardingSignup
      ? "We've done this 10,000+ times! You'll learn calendaring best practices and leave as a Vimcal power user."
      : "It's totally free, and you'll still be able to explore Vimcal on your own in the meantime.";
  };

  return (
    <div
      className={classNames("onboarding-container pt-2.5 pb-2.5")}
      style={{ minHeight: "800px", height: "calc(100vh - 100px)" }}
    >
      {renderTakeMeToSelfOnboardingButton()}
      <div className="font-weight-600 select-none flex items-center justify-center">
        <div className="section-title text-center">
          {isEA
            ? "Book your personalized onboarding call"
            : "Book a 1:1 onboarding with one of our Calendar Experts!"}
        </div>
      </div>

      <div className="welcome-subtitle mt-4">
        {isEA
          ? "We’ve done this over 10,000 times! We’ll set up your account and turn you into a power user."
          : getNormalOnboardingSubtext()}
      </div>

      {isEA ? (
        <CalendlyBookingPage isEA numberOfExecutiveAssistants={numberOfExecutiveAssistants} />
      ) : (
        renderVimcalBookingLink({
          isEA,
          masterAccount,
          currentUser,
          onClickNext: props.onClickNext,
          onComplete,
          hideOnClickNext:
            isBlockedAfterOnboardingSignup,
        })
      )}
    </div>
  );
}

function renderVimcalBookingLink({
  isEA,
  masterAccount,
  currentUser,
  onClickNext,
  onComplete,
  hideOnClickNext,
}) {
  return (
    <div className="mt-6">
      <RoundRobinScheduler
        currentUser={currentUser}
        inputName={getUserName({ masterAccount, user: currentUser }).fullName}
        inputEmail={getUserEmail(currentUser)}
        onComplete={onComplete}
        onClickTryUsOut={onClickNext}
        customOnSuccessButtonCopy={"Take me to Vimcal"}
        isEA={isEA}
        hideOnClickNext={hideOnClickNext}
      />
    </div>
  );
}
