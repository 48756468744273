import React, { useMemo, useState } from "react";
import { getHumanReadableDollarFromCents, getUnitPricesFromInvoice } from "../../../lib/stripeFunctions";
import { FEEDBACK_TYPE, MONTHLY, YEARLY } from "../../../lib/vimcalVariables";
import { useTeamPlan } from "../../../services/stores/userData";
import PlanOptionWrapper, { PlanOptionWrapperProps } from "./planOptionWrapper";
import { useGetBillingInfo, useIsCurrentUserAdmin } from "../../teamPlans/hooks";
import { useAnnualUpgradeInvoicesStore, useStripeUpcomingInvoices } from "../../../services/stores/finance";
import SpinnerV2 from "../../spinnerV2";
import ColoredLine from "../../line";
import { pluralize } from "../../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import CustomButtonV2 from "../../buttons/customButtonV2";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import { hasStopEventPropagation, sendMessageToSentry } from "../../../services/commonUsefulFunctions";
import broadcast from "../../../broadcasts/broadcast";
import { BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES } from "../../../lib/broadcastValues";
import { triggerRefreshWithOnlineCheck } from "../../../services/appFunctions";
import PaymentModal from "./paymentModal";
import LogoutButton from "./logoutButton";
import EditSeats from "./editSeats";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";

interface PlanOptionProps extends Omit<PlanOptionWrapperProps, "children" | "className"> {
  option: typeof MONTHLY | typeof YEARLY
  price: number
}

function PlanOption({ option, price, ...wrapperProps }: PlanOptionProps) {
  return (
    <PlanOptionWrapper className="p-4 basis-0 flex-grow flex flex-col gap-2" {...wrapperProps}>
      <div className="flex gap-2.5 items-center">
        <span className="default-font-size non-selected-text-color leading-loose">Billed {option === YEARLY ? "yearly" : "monthly"}</span>
        {option === YEARLY ? (
          <span
            className="px-2 py-1 font-medium text-white font-size-10 rounded-md"
            style={{ background: "linear-gradient(95.72deg, #F329B5 6.71%, #FF3645 95.6%)" }}
          >
            Best value
          </span>
        ) : null}
      </div>
      <div className="text-xl">
        ${getHumanReadableDollarFromCents(price)}/month
      </div>
    </PlanOptionWrapper>
  );
}

interface SeatRowProps {
  label: string
  paymentOption: typeof YEARLY | typeof MONTHLY
  seatCount: number
  unitPrice: number
}

function SeatRow({ label, paymentOption, seatCount, unitPrice }: SeatRowProps) {
  if (seatCount === 0) {
    return null;
  }

  return (
    <>
      <div className="non-selected-text-color">{label}</div>
      <div className="non-selected-text-color text-right">
        {seatCount} {pluralize(seatCount, "seat")}{" "}
        x ${getHumanReadableDollarFromCents(unitPrice / (paymentOption === YEARLY ? 12 : 1))}
      </div>
    </>
  );
}

interface TeamTrialFinishedProps {
  freeTrialIsOver?: boolean
  isInModal?: boolean
  setShowEditPlanContent: StateSetter<boolean>
  showEditPlanContent: boolean
}

export default function TeamTrialFinished({ freeTrialIsOver, isInModal, setShowEditPlanContent, showEditPlanContent }: TeamTrialFinishedProps) {
  const [paymentOption, setPaymentPlan] = useState<typeof YEARLY | typeof MONTHLY>(YEARLY);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [referral] = useState<Referral>();

  const hasBillingBeenFetched = useGetBillingInfo();
  const upcomingInvoice = useStripeUpcomingInvoices(state => state.stripeUpcomingInvoices);
  const annualUpgradeNonProratedInvoice = useAnnualUpgradeInvoicesStore(state => state.annualUpgradeNonProratedInvoice);

  const monthlyTotal = upcomingInvoice?.subtotal ?? 0;
  const yearlyTotal = annualUpgradeNonProratedInvoice?.subtotal ?? 0;
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const isCurrentUserAdmin = useIsCurrentUserAdmin();

  const { errorIdentifyingUnitPrices, vimcalSeatPrice, vimcalEASeatPrice } = useMemo(() => {
    if (!hasBillingBeenFetched || !isCurrentUserAdmin) {
      return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: false };
    }

    if (isEmptyObjectOrFalsey(teamPlan)) {
      sendMessageToSentry("Error getting prices from invoice", "Missing team plan");
      return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: true };
    }

    const invoice = (paymentOption === YEARLY) ? annualUpgradeNonProratedInvoice : upcomingInvoice;

    if (isEmptyObjectOrFalsey(invoice)) {
      sendMessageToSentry("Error getting prices from invoice", "No invoice to read from");
      return { vimcalSeatPrice: 0, vimcalEASeatPrice: 0, errorIdentifyingUnitPrices: true };
    }

    return getUnitPricesFromInvoice(invoice, teamPlan);
  }, [
    hasBillingBeenFetched,
    isCurrentUserAdmin,
    teamPlan,
    paymentOption,
    annualUpgradeNonProratedInvoice,
    upcomingInvoice,
  ]);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    hasStopEventPropagation(e);
    setIsPaymentModalOpen(true);
  };

  const closeModal = () => {
    setIsPaymentModalOpen(false);
    if (showThankYouPage && isInModal) {
      broadcast.publish(BROADCAST_VALUES.CLOSE_LAYOUT_MODAL);
      triggerRefreshWithOnlineCheck();
    }
  };

  // For all these early returns, skip if the payment modal is open. Otherwise the payment
  // modal may be unmounted right after converting.
  if (!hasBillingBeenFetched && !isPaymentModalOpen) {
    return (
      <ContentWrapper freeTrialIsOver={freeTrialIsOver}>
        <div className="flex items-center justify-center" style={{ height: 200 }}><SpinnerV2 /></div>
      </ContentWrapper>
    );
  }

  if (!isCurrentUserAdmin && !isPaymentModalOpen) {
    return (
      <ContentWrapper freeTrialIsOver={freeTrialIsOver}>
        <div className="default-font-size text-center">
          Please reach out to one of your team plan admins to start your subscription,
          or contact us if you want to start your own subscription.
        </div>
      </ContentWrapper>
    );
  }

  if ((errorIdentifyingUnitPrices || isEmptyObjectOrFalsey(teamPlan)) && !isPaymentModalOpen) {
    return (
      <ContentWrapper freeTrialIsOver={freeTrialIsOver}>
        <div className="default-font-size text-center">
          Please contact us to set up your team plan subscription.
        </div>
      </ContentWrapper>
    );
  }

  if (showEditPlanContent && !isPaymentModalOpen) {
    return <EditSeats onRequestClose={() => setShowEditPlanContent(false)} />;
  }

  return (
    <ContentWrapper freeTrialIsOver={freeTrialIsOver}>
      <div className="flex gap-8">
        <PlanOption
          option={YEARLY}
          price={yearlyTotal / 12}
          isSelected={paymentOption === YEARLY}
          onClick={() => setPaymentPlan(YEARLY)}
        />
        <PlanOption
          option={MONTHLY}
          price={monthlyTotal}
          isSelected={paymentOption === MONTHLY}
          onClick={() => setPaymentPlan(MONTHLY)}
        />
      </div>
      <div className="mt-5 grid gap-4 default-font-size">
        <SeatRow label="Vimcal" paymentOption={paymentOption} seatCount={teamPlan.non_ea_seat_count} unitPrice={vimcalSeatPrice} />
        <SeatRow label="Vimcal EA" paymentOption={paymentOption} seatCount={teamPlan.ea_seat_count} unitPrice={vimcalEASeatPrice} />
        {/* Empty div for grid alignment. */}
        <div></div>
        <div className="text-right cursor-pointer trial-over-link" onClick={() => setShowEditPlanContent(true)}>
          Edit seats
        </div>
        <div className="col-span-2"><ColoredLine style={{ backgroundColor: "rgba(255, 255, 255, 0.16)"}} /></div>
        <div className="non-selected-text-color">Due today</div>
        <div className="non-selected-text-color text-right">
          ${getHumanReadableDollarFromCents(paymentOption === YEARLY ? yearlyTotal : monthlyTotal)}
        </div>
      </div>

      <CustomButtonV2
        buttonType={BLUE_BUTTON}
        className="w-full mt-5"
        disabled={!hasBillingBeenFetched}
        label="Upgrade now"
        onClick={onClick}
      />

      <PaymentModal
        closeModal={closeModal}
        isInModal={isInModal}
        isOpen={isPaymentModalOpen}
        paymentOption={paymentOption}
        referral={referral}
        showThankYouPage={showThankYouPage}
        setShowThankYouPage={setShowThankYouPage}
      />
    </ContentWrapper>
  );
}

interface ContentWrapperProps {
  children: React.ReactNode
  freeTrialIsOver?: boolean
}

function ContentWrapper({ children, freeTrialIsOver }: ContentWrapperProps) {
  const onClickContactSupport = () => {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.QUESTION);
  };

  return (
    <div className="trial-over-background-gradient p-8">
      <div className="width-500px mx-auto">
        <div className="text-3xl font-normal text-center text-white">
          Upgrade your calendar
        </div>
        <div className="color-medium-gray mt-3 mb-5 text-center default-font-size max-w-sm mx-auto">
          Join thousands of CEOs, investors, and others who trust Vimcal to manage their time.
        </div>

        {children}

        {freeTrialIsOver ? <LogoutButton /> : null}

        <div className="mt-10 font-size-10 text-center">
          <span className="non-selected-text-color">Questions? Visit our help docs or{" "}</span>
          <span className="cursor-pointer trial-over-link" onClick={onClickContactSupport}>contact support</span>
          <span className="non-selected-text-color">.</span>
        </div>
      </div>
    </div>
  );
}
