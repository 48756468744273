import produce from "immer";
import { useState } from "react";

export const useColumns = (initialColumns: { [columnType: string]: number }) => {
  const [columnWidths, setColumnWidths] = useState(initialColumns);

  const getColumnWidth = (columnType: string) => {
    if (Object.keys(columnWidths).includes(columnType)) {
      return columnWidths[columnType];
    }

    return 0;
  };

  const handleResize = ({ columnType, updatedWidth }: { columnType: string, updatedWidth: number }) => {
    const updatedColumnWidths = produce(columnWidths, (draftState) => {
      draftState[columnType] = updatedWidth;
    });

    setColumnWidths(updatedColumnWidths);
  };

  return { columnWidths, getColumnWidth, handleResize };
};
