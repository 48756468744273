import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import OnboardingSaveButton from "./onboardingSaveButton";
import Mousetrap from "mousetrap";
import classNames from "classnames";

const ONBOARDING_TYPE = {
  PERSONAL_ONBOARDING: "personalOnboarding",
  SELF_SERVE: "self-serve",
};
const DEFAULT_ONBOARD_TYPE = ONBOARDING_TYPE.PERSONAL_ONBOARDING;

export default function ChooseOnboardingType({
  onClickPersonal,
  onClickSelfServe,
  step,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [onboardingType, setOnboardingType] = useState(DEFAULT_ONBOARD_TYPE);

  const onboardTypeRef = useRef(DEFAULT_ONBOARD_TYPE);
  const onSelectType = (type) => {
    setOnboardingType(type);
    onboardTypeRef.current = type;
  };

  const onClickSave = () => {
    if (onboardTypeRef.current === ONBOARDING_TYPE.PERSONAL_ONBOARDING) {
      onClickPersonal();
    } else {
      onClickSelfServe();
    }
  };

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, []);

  return (
    <div className="onboarding-container min-height-500px">
      <div className="section-title">Choose your onboarding</div>
      <div className={classNames("flex mt-8 mb-3", "choose-onboarding-option-flex-direction")}>
        {renderPersonalOnboarding({
          onClick: () => onSelectType(ONBOARDING_TYPE.PERSONAL_ONBOARDING),
          isSelected: onboardingType === ONBOARDING_TYPE.PERSONAL_ONBOARDING,
        })}
        <div className="w-6 h-5"></div>
        {renderSelfServeOnboarding({
          onClick: () => onSelectType(ONBOARDING_TYPE.SELF_SERVE),
          isSelected: onboardingType === ONBOARDING_TYPE.SELF_SERVE,
        })}
      </div>

      <OnboardingSaveButton buttonText="Continue" onClick={onClickSave} />
    </div>
  );
}

function renderPersonalOnboarding({ onClick, isSelected }) {
  return (
    <div
      className={classNames(
        "onboarding-type-content-wrap",
        isSelected
          ? "selected"
          : ""
      )}
    >
      <div
        className={classNames("select-onboarding-type-container")}
        onClick={onClick}
      >
        <div className={
          classNames(
            "w-full py-3", 
            "bg-red-400", 
            "flex items-center justify-center",
            "absolute top-0",
            "rounded-t-xl",
            "default-font-size"
          )}
          style={{
            background: "linear-gradient(to right, #CD309B, #E97273)"
          }}
        >
          Recommended
        </div>
        <div
          className={classNames(
            "text-white",
            "font-weight-500",
            "choose-onboarding-option-title"
          )}
        >
          Personalized onboarding
        </div>

        <div className="choose-onboarding-subtext secondary-text-color mt-3 w-full">
          Schedule a call to have your mind blown 🤯
        </div>
      </div>
    </div>
  );
}

function renderSelfServeOnboarding({ onClick, isSelected }) {
  return (
    <div
      className={classNames(
        "onboarding-type-content-wrap",
        isSelected
          ? "selected"
          : ""
      )}
    >
      <div
        className={classNames("select-onboarding-type-container")}
        onClick={onClick}
      >
        <div
          className={classNames(
            "text-white",
            "font-weight-500",
            "choose-onboarding-option-title"
          )}
        >
          Let me explore
        </div>

        <div className="choose-onboarding-subtext secondary-text-color mt-3 w-full">
          Jump directly into the app 
        </div>
      </div>
    </div>
  );
}
