import React from "react";
import { Trash } from "react-feather";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";

interface SocialLinkFieldProps {
  errorText?: string | null
  handle: React.ReactNode
  label: string
  placeholder?: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

export default function SocialLinkField({ errorText, handle, label, placeholder, setValue, value }: SocialLinkFieldProps) {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(getInputStringFromEvent(e))
  }

  return (
    <div>
      <div className="flex gap-1.5 items-center">
        <span>
          {handle}
        </span>
        <span className="default-font-size secondary-text-color grow">{label}</span>
        <input
          className="default-input-field settings-social-link-input"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {value ? <Trash className="clickable-icon ml-2" size={12} onClick={() => setValue("")} /> : <span className="ml-2 w-3"></span>}
      </div>
      {errorText ? <div className="warning-color default-font-size mt-1">{errorText}</div> : null}
    </div>
  );
}
