import React, { useEffect } from "react";
import classNames from "classnames";
import { Check } from "react-feather";
import { SECOND_IN_MS } from "../../../services/globalVariables";

interface ConfirmationProps {
  setShouldDisplay: StateSetter<boolean>
  shouldDisplay: boolean
  /** Update this value to reset the timer. */
  triggerCount?: number
}

/**
 * A confirmation component to display after a user successfully saves changes.
 * Auto-disappears after 2 seconds.
 *
 * If it is expected that a user could save multiple times within a 2-second window,
 * update the `triggerCount` prop with each save to reset the timer. The prop can
 * be ignored otherwise.
 */
export default function Confirmation({
  setShouldDisplay,
  shouldDisplay,
  triggerCount=0,
}: ConfirmationProps) {
  useEffect(() => {
    if (shouldDisplay) {
      const timeout = window.setTimeout(() => setShouldDisplay(false), 2 * SECOND_IN_MS);

      return () => window.clearTimeout(timeout);
    }
  }, [shouldDisplay, triggerCount]);

  return (
    <div
      className={classNames(
        "flex items-center default-font-size font-weight-300 ml-4",
        "duration-200 pointer-events-none",
        shouldDisplay ? "opacity-100" : "opacity-0",
      )}
    >
      <div
        className="rounded-full bg-transparent border-1px flex items-center justify-center p-0.5 mr-1"
        style={{ border: "1px solid #7AD7B2" }}
      >
        <Check color="#7AD7B2" size={12} />
      </div>
      Saved!
    </div>
  );
}
