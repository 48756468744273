import classNames from "classnames";
import React from "react";
import { Droplet } from "react-feather";
import { useSelector } from "react-redux";

export default function FocusModeCountdown({
  focusModeCountDown,
  inputClassName,
  hideBorder = false,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  return (
    <div
      className={classNames(
        hideBorder ? "border-width-0px" : "border-width-1px",
        isDarkMode
          ? "text-gray-400 hover:text-white"
          : "text-gray-400 hover:text-gray-700",
        "rounded-tl-lg border-solid  flex items-center px-2",
        "duration-200 cursor-pointer",
        "h-30px",
        inputClassName ?? "",
        "select-none"
      )}
    >
      <div className="font-family-Arial font-weight-300 w-12 default-font-size">
        {focusModeCountDown}
      </div>
      <Droplet
        size={16}
        strokeWidth={2}
        className="h-3.5 line-height-14px mb-0.5"
      />
    </div>
  );
}
