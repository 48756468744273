import classNames from "classnames";
import React from "react";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { DARK_MODE_DISABLED_TEXT_BUTTON_COLOR, LIGHT_MODE_DISABLED_TEXT_BUTTON_COLOR } from "../services/globalVariables";
import ShortcutTiles from "./shortcutTiles/shortcutTiles";

interface SaveButtonProps extends Pick<React.HTMLProps<HTMLButtonElement>, "disabled" | "onClick" | "style" | "tabIndex"> {
  buttonId?: string
  buttonText?: React.ReactNode
  cursor?: string
  doNotParseText?: boolean
  focusRef?: React.LegacyRef<HTMLButtonElement>
  height?: number | string
  hideDefaultIcon?: boolean
  justifyContent?: string
  marginTop?: number | string
  overrideClassName?: string
  shortcut?: string | null
  textStyle?: React.CSSProperties
  width?: number | string
}

function SaveButton({
  buttonId,
  buttonText,
  cursor,
  disabled,
  doNotParseText,
  focusRef,
  height,
  hideDefaultIcon,
  justifyContent,
  marginTop,
  onClick,
  overrideClassName,
  shortcut,
  style,
  tabIndex,
  textStyle,
  width,
}: SaveButtonProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  return (
    <button
      ref={focusRef}
      className={classNames(
        overrideClassName ||
        (disabled ? "save-button-disabled" : getEnabledButtonClassNames(isDarkMode)),
        "flex-shrink-0",
      )}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      id={buttonId || "default-save-button-id"}
      style={Object.assign(
        {
          // TODO: Remove the dedicated props for style overrides.
          // Instead, just pass them through the `style` prop.
          width: width ? width : 100,
          height: height ? height : 100,
          borderRadius: 6,
          cursor: cursor || "pointer",
          borderWidth: 0,
          marginTop: marginTop ? marginTop : 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: justifyContent ?? "space-between",
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: "center",
          userSelect: "none",
        },
        style,
      )}
    >
      <div
        style={Object.assign({
          color: disabled
            ? getDisabledTextColor(isDarkMode)
            : "white",
          fontSize: 12,
          fontWeight: "400",
        },
        textStyle,
        )}
      >
        {buttonText ? buttonText : "Save"}
      </div>

      {!shortcut && !hideDefaultIcon && (
        <Check color={disabled ? getDisabledTextColor(isDarkMode) : "white"} size="12" />
      )}

      {shortcut && !doNotParseText && (
        <div className={"display-flex-flex-direction-row"}>
          <ShortcutTiles
            shortcut={shortcut}
            border={disabled ? `1px solid ${getDisabledTextColor(isDarkMode)}` : null}
            color={disabled ? getDisabledTextColor(isDarkMode) : null}
          />
        </div>
      )}
      {shortcut && doNotParseText && (
        <div className="display-flex-flex-direction-row position-relative">
          {shortcut}
        </div>
      )}
    </button>
  );
}

function getDisabledTextColor(isDarkMode: boolean | undefined) {
  return isDarkMode ? DARK_MODE_DISABLED_TEXT_BUTTON_COLOR : LIGHT_MODE_DISABLED_TEXT_BUTTON_COLOR;
}

function getEnabledButtonClassNames(isDarkMode: boolean | undefined) {
  if (isDarkMode) {
    // get lighter on hover
    return "bg-blue-600 hover:bg-blue-500 duration-200";
  } else {
    // get darker on hover
    return "bg-blue-600 hover:bg-blue-700 duration-200";
  }
}

export default SaveButton;
