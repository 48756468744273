import React, { createRef, useEffect, useState } from "react";
import { isEmptyArray } from "../../lib/arrayFunctions";
import CustomButton from "../customButton";
import {
  BLUE_BUTTON,
  ISO_DATE_FORMAT,
  WHITE_BUTTON,
} from "../../services/globalVariables";
import {
  getMonthDayYearLongForm,
  isAfterDay,
  isBeforeDay,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isValidTimeZone,
} from "../../services/commonUsefulFunctions";
import {
  addDays,
  format,
  formatISO,
  getYear,
  isSameMonth,
  isSameYear,
} from "date-fns";
import classNames from "classnames";
import { Edit2, Trash } from "react-feather";
import SelectTimeZoneInput from "../selectTimeZoneInput";
import {
  filterAndSortUpcomingTrips,
  getTripEndDate,
  getTripID,
  getTripName,
  getTripStartDate,
  getTripTimeZone,
  getTripTimeZoneAbbreviation,
  getUpcomingTrips,
  isTripOverlappingOtherTrips,
} from "../../lib/personalLinkFunctions";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { isMaestroUserOnDelegatedAccount } from "../../services/maestroFunctions";
import { useSelector } from "react-redux";
import DateRangePicker from "../DateRangePicker";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";

export default function PersonalLinksTraveling({ user }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [upcomingTrips, setUpcomingTrips] = useState(
    getUpcomingTrips({
      user,
      masterAccount,
    })
  );
  const [editingTrip, setEditingTrip] = useState(null);
  const [originalTrip, setOriginalTrip] = useState(null); // to help with filtering
  const [warning, setWarning] = useState(null);
  
  useEffect(() => {
    availabilityBroadcast.subscribe("CREATE_A_TRIP", onClickCreate);
    return () => {
      availabilityBroadcast.unsubscribe("CREATE_A_TRIP");
    }
  }, []);
  
  const onClickCreate = () => {
    setEditingTrip({
      name: "",
      start_date: "",
      end_date: "",
      time_zone: "",
    });
  };

  const onClickEditTrip = (trip) => {
    if (isEmptyObjectOrFalsey(trip)) {
      return;
    }
    setEditingTrip(trip);
    setOriginalTrip(trip);
  };

  const onSubmitSave = (trips) => {
    backendBroadcasts.publish(
      BACKEND_BROADCAST_VALUES.UPDATE_PERSONAL_LINK_TRIPS,
      {
        trips,
        user,
        isUpdatingExecutiveProfile: isMaestroUserOnDelegatedAccount({
          masterAccount,
          user,
        }),
      }
    );
  };

  const setUpcomingTripsAndSort = (trips) => {
    setUpcomingTrips(filterAndSortUpcomingTrips(trips));
  };

  const onClickDelete = (trip) => {
    const tripID = getTripID(trip);
    const updatedTrips = upcomingTrips.filter(
      (trip) => getTripID(trip) !== tripID
    );
    setUpcomingTripsAndSort(updatedTrips);
    onSubmitSave(updatedTrips);
  };

  const onClickSave = (trip) => {
    const existingTrips = upcomingTrips.filter(
      (trip) => getTripID(trip) !== getTripID(originalTrip)
    );
    const overLappingTrip = isTripOverlappingOtherTrips({
      trip,
      allTrips: existingTrips,
    });
    if (!getTripName(trip)) {
      setWarning(`Please enter a trip name.`);
      return;
    }
    if (!isEmptyObjectOrFalsey(overLappingTrip)) {
      setWarning(
        `Trip overlaps with your "${getTripName(overLappingTrip)}" trip.`
      );
      return;
    }
    if (!isValidTimeZone(getTripTimeZone(trip))) {
      setWarning(`Please enter a valid time zone.`);
      return;
    }
    setWarning(null);
    const updatedTrips = existingTrips.concat(trip);
    setUpcomingTripsAndSort(updatedTrips);
    setEditingTrip(null);
    setOriginalTrip(null);
    onSubmitSave(updatedTrips);
  };

  const onClickCancel = () => {
    setEditingTrip(null);
    setOriginalTrip(null);
  };

  return (
    <div className="overflow-y-auto modal-content-max-height">
      {isEmptyObjectOrFalsey(editingTrip) ? (
        renderEmptyState({
          onClickCreate,
        })
      ) : (
        <PersonalLinksEditTripSection
          trip={editingTrip}
          onClickSave={onClickSave}
          onClickCancel={onClickCancel}
          warning={warning}
          setWarning={setWarning}
        />
      )}
      {renderUpcomingTrips({
        upcomingTrips,
        onClickEditTrip,
        editingTrip,
        onClickDelete,
      })}
    </div>
  );
}

function renderUpcomingTrips({
  upcomingTrips,
  onClickEditTrip,
  editingTrip, // if currently editing -> hide pencil and trash
  onClickDelete,
}) {
  if (isEmptyArray(upcomingTrips)) {
    return null;
  }

  return (
    <div className="mt-6">
      <div className="font-size-16">Upcoming trips</div>
      {upcomingTrips.map((trip, index) => {
        return (
          <div
            key={`trip-key-${getTripName(trip)}-${index}`}
            className="modal-selected-option-background-color rounded-md p-4 default-font-size mt-2"
          >
            <div>{getTripName(trip)}</div>
            <div
              className={classNames(
                "secondary-text-color mt-1",
                "flex items-center justify-between"
              )}
            >
              {formatDateRange(getTripStartDate(trip), getTripEndDate(trip))}
              <div>
                {editingTrip ? null : (
                  <Edit2
                    size={16}
                    className="hoverable-secondary-text-color"
                    onClick={() => {
                      onClickEditTrip(trip);
                    }}
                  />
                )}
                {editingTrip ? null : (
                  <Trash
                    size={16}
                    className="hoverable-secondary-text-color ml-4"
                    onClick={() => {
                      onClickDelete(trip);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="secondary-text-color mt-1">
              Time zone: {getTripTimeZoneAbbreviation(trip)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function PersonalLinksEditTripSection({
  trip,
  onClickSave,
  onClickCancel,
  warning,
  setWarning,
}) {
  const FOCUS_ELEMENTS = {
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
  };
  const [focusedElement, setFocusedElement] = useState(null);
  const [tripName, setTripName] = useState(getTripName(trip));
  const [startDate, setStartDate] = useState(getTripStartDate(trip));
  const [hoverDate, setHoverDate] = useState(null);
  const [endDate, setEndDate] = useState(getTripEndDate(trip));
  const [timeZone, setTimeZone] = useState(getTripTimeZone(trip));
  useState(false);
  const startDateRef = createRef();
  const dateFieldOrder = useSelector((state) => state.dateFieldOrder);

  const getDisplayStartAndEndDates = () => {
    let displayStartDate = startDate;
    let displayEndDate = endDate;
    if (focusedElement === FOCUS_ELEMENTS.START_DATE && hoverDate) {
      if (isSameOrAfterDay(hoverDate, endDate)) {
        displayStartDate = hoverDate;
        displayEndDate = addDays(hoverDate, 1);
      } else {
        displayStartDate = hoverDate;
      }
    }
    if (focusedElement === FOCUS_ELEMENTS.END_DATE && hoverDate) {
      if (isSameOrBeforeDay(hoverDate, startDate)) {
        displayStartDate = hoverDate;
      } else {
        displayEndDate = hoverDate;
      }
    }
    return {
      displayStartDate,
      displayEndDate,
    };
  };

  const renderDateRangePicker = () => {
    const { displayStartDate, displayEndDate } = getDisplayStartAndEndDates();
    return (
      <div>
        <div className="flex items-center my-4">
          <div className="mr-2.5">from</div>
          <div
            className={classNames(
              "personal-link-travel-date-container",
              focusedElement === FOCUS_ELEMENTS.START_DATE
                ? "personal-link-travel-date-container-focused"
                : ""
            )}
            onClick={() => setFocusedElement(FOCUS_ELEMENTS.START_DATE)}
          >
            {getMonthDayYearLongForm({
              date: displayStartDate,
              dateFormat: dateFieldOrder,
              isShortDay: true,
            })}
          </div>
          <div className="mx-2.5">to</div>
          <div
            className={classNames(
              "personal-link-travel-date-container",
              focusedElement === FOCUS_ELEMENTS.END_DATE
                ? "personal-link-travel-date-container-focused"
                : ""
            )}
            onClick={() => setFocusedElement(FOCUS_ELEMENTS.END_DATE)}
          >
            {getMonthDayYearLongForm({
              date: displayEndDate,
              dateFormat: dateFieldOrder,
              isShortDay: true,
            })}
          </div>
        </div>
        {focusedElement ? (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            isSelectingEndDate={focusedElement === FOCUS_ELEMENTS.END_DATE}
            setDate={(date) => {
              if (focusedElement === FOCUS_ELEMENTS.START_DATE) {
                if (isAfterDay(date, endDate)) {
                  setStartDate(date);
                  setEndDate(addDays(date, 1));
                } else {
                  setStartDate(date);
                }
                setFocusedElement(FOCUS_ELEMENTS.END_DATE);
              }

              if (focusedElement === FOCUS_ELEMENTS.END_DATE) {
                if (isBeforeDay(date, startDate)) {
                  setStartDate(date);
                } else {
                  setEndDate(date);
                }
              }
            }}
            setParentHoverDate={(date) => {
              setHoverDate(date);
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="default-font-size personal-links-trip-modal-background-color rounded-lg p-4">
      <input
        placeholder={"Trip title"}
        className={classNames("create-event-input-field")}
        value={tripName}
        onChange={(e) => setTripName(getInputStringFromEvent(e))}
        autoFocus={true}
      />

      <div className="flex items-center justify-start mt-4">
        <div className="min-width-80px">I will be in</div>
        <SelectTimeZoneInput
          timeZone={timeZone}
          onChange={(timeZone) => {
            setTimeZone(timeZone);
            startDateRef.current?.focus();
          }}
          menuListMaxHeight={focusedElement ? undefined : "104px"}
        />
      </div>

      {renderDateRangePicker({
        startDate,
        endDate,
        setStartDate,
        setEndDate,
      })}

      {warning ? (
        <div className="default-font-size warning-color my-1">{warning}</div>
      ) : null}
      <div className="flex justify-end mt-8">
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          label="Cancel"
          onClick={onClickCancel}
        />
        <CustomButton
          buttonType={BLUE_BUTTON}
          label="Save"
          onClick={() => {
            onClickSave({
              name: tripName,
              start_date: formatISO(startDate, ISO_DATE_FORMAT),
              end_date: formatISO(endDate, ISO_DATE_FORMAT),
              time_zone: timeZone,
            });
          }}
        />
      </div>
    </div>
  );
}

function formatDateRange(startDate, endDate) {
  const currentYear = getYear(new Date());
  const isCurrentYear = isSameYear(new Date(currentYear, 0, 1), startDate);

  let startFormat, endFormat;

  if (isCurrentYear && isSameYear(startDate, endDate)) {
    if (isSameMonth(startDate, endDate)) {
      startFormat = "MMM d";
      endFormat = "d";
    } else {
      startFormat = "MMM d";
      endFormat = "MMM d";
    }
  } else {
    startFormat = "MMM d, yyyy";
    endFormat = "MMM d, yyyy";
  }
  return `${format(startDate, startFormat)} - ${format(endDate, endFormat)}`;
}

function renderEmptyState({ onClickCreate }) {
  return (
    <div>
      <div className="default-font-size secondary-text-color mb-6">
        Creating a trip will convert all your Personal Links to that time zone
        for the specified dates.
      </div>
      <CustomButton
        buttonType={WHITE_BUTTON}
        label="+ Create trip"
        onClick={onClickCreate}
      />
    </div>
  );
}
