import classNames from "classnames";
import React from "react";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import { getObjectEmail } from "../../lib/objectFunctions";
import { AttendeeGroup } from "./helperFunctions";
import { GOLD_STAR_HEX_CODE } from "../../lib/styleFunctions";
import { Star } from "react-feather";

interface AttendeeSectionProps {
  groups: AttendeeGroup[]
  attendees: Attendee[]
  addAttendees?: (args: { email: string, userEmail: string }) => void
  priorityAttendees: Set<string>
  onTogglePriority: (args: { email: string }) => void
}

export default function AttendeeSection({
  groups,
  attendees,
  addAttendees,
  priorityAttendees,
  onTogglePriority,
}: AttendeeSectionProps) {
  return (
    <div
      className={classNames(
        "w-max",
        "margin-bottom-14px",
        "default-border-right",
      )}
    >
      <div className="date-header-height"></div>
      <div className="cell-height"></div>
      <div
        className={classNames(
          "font-weight-500",
          "cell-height",
          "px-4",
          "flex items-center",
          "truncate w-44",
        )}
      >
        Mutual availability
      </div>
      {groups.map((group) => {
        const { title, id } = group;
        const isPriorityAttendee = priorityAttendees.has(id);
        return (
          <div
            key={id}
            className={classNames(
              "cell-height",
              "default-border-top",
              "flex items-center justify-start",
              "px-4",
              "gap-2",
            )}
          >
            <div className="truncate max-width-108px select-none">{title}</div>
            <Star
              className="mr-4 gold-star-hoverable-secondary-text-color"
              color={isPriorityAttendee ? GOLD_STAR_HEX_CODE : undefined}
              fill={isPriorityAttendee ? GOLD_STAR_HEX_CODE : "transparent"}
              onClick={() => onTogglePriority({ email: id })}
              size={14}
            />
          </div>
        );
      })}

      <div className="default-border-top"></div>
      {addAttendees ? (
        <ReactSelectAttendeeAutoComplete
          additionalClassNames="select-attendee mt-2.5 mb-1"
          selectedGuests={attendees?.map((a) => getObjectEmail(a))}
          addAttendeeIntoEventForm={addAttendees}
        />
      ) : null}
    </div>
  );
}
