import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  CSSProperties,
} from "react";
import { useSelector } from "react-redux";
import type { Tags, TagsProps } from "./tagsVariables";
import TagOption from "./tagOption";
import {
  getMatchingUserAndTags,
  getTagId,
  matchingSettingTagInSmartTags,
} from "../../lib/tagsFunctions";
import TagsCommandCenter from "./tagsCommandCenter";
import {
  KEYCODE_DOWN_ARROW,
  KEYCODE_ENTER,
  KEYCODE_ESCAPE,
  KEYCODE_UP_ARROW,
} from "../../services/commonUsefulFunctions";
import { COLOR_SELECTOR_ID } from "../../services/elementIDVariables";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { APP_SETTINGS } from "../../lib/vimcalVariables";
import tagsBroadcast from "../../broadcasts/tagsBroadcast";
import { trackEvent } from "../tracking";
import { isModalOpen } from "../../services/appFunctions";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import ColoredLine from "../line";
import { Settings } from "react-feather";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { getMatchingUserFromAllUsers } from "../../lib/userFunctions";
import { isUserMagicLinkUser } from "../../services/maestroFunctions";

type TagsSelectorProps = {
  popupEventRef?: React.MutableRefObject<HTMLDivElement | null>;
  style?: React.CSSProperties;
  showOnLeftHandSide?: boolean
} & TagsProps;

export const UPDATE_EVENT_TAGS = "UPDATE_EVENT_TAGS";

const TagsSelector: React.FC<TagsSelectorProps> = (props) => {
  const {
    displayLocation,
    filterValue,
    tags,
    setFilterValue,
    setTags,
    setSelectorDisplayIndex,
    userEmail,
    showOnLeftHandSide, // usually on right but if putting it on the right goes out of bounds, put it on the left
  } = props;
  const masterAccount = useMasterAccount((state: any) => state.masterAccount);
  const getContainerStyle = (): CSSProperties => {
    const containerStyle: CSSProperties = props.style ? { ...props.style } : {};
    if (selectContainerRef.current) {
      const topPosition =
        selectContainerRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const distanceFromBottom = windowHeight - topPosition;

      containerStyle.maxHeight = `${distanceFromBottom - 20}px`;
    }
    return containerStyle;
  };

  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [showTagsCommandCenter, setShowTagsCommandCenter] =
    useState<boolean>(false);
  const [isColorSelectorAbove, setIsColorSelectorAbove] = useState<boolean>(
    !!document.getElementById(COLOR_SELECTOR_ID)
  );
  const tagsCommandCenterRef = useRef<HTMLDivElement | null>(null);
  const popupEventRef = props.popupEventRef;
  const selectContainerRef = useRef<HTMLInputElement | null>(null);
  const [containerStyle, setContainerStyle] = useState<CSSProperties>(
    getContainerStyle()
  );
  const [shouldHightlightSettingsSection, setHighlightSettingsSection] =
    useState<boolean>(false);

  /* TODO: Add typescript for below */
  const currentUser = useSelector((state: any) => state.currentUser);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state: any) => state.allLoggedInUsers
  );
  /* TODO: Add typescript for above */

  const tagsSetting: Tags = getMatchingUserAndTags({
    currentUser,
    allLoggedInUsers,
    userEmail,
    masterAccount,
  });

  const matchingUser = getMatchingUserFromAllUsers({
    allUsers: allLoggedInUsers,
    userEmail,
  });

  /* Filter paint colors if we have it and a filterValue */
  let filteredTags: Tags = tagsSetting;
  if (tagsSetting?.length > 0 && (filterValue ?? "").length > 0) {
    filteredTags = tagsSetting.filter((p_c: Tag) =>
      p_c.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  /* Empty states */
  const renderEmptyTags: () => JSX.Element = () => {
    /* Create new label if user searched something */
    if ((filterValue ?? "").length > 0) {
      return (
        <div
          className={classNames(
            "paint-colors-selector-option filter-no-matches flex justify-center last-option select-none",
            highlightedIndex === 0 ? "highlighted" : ""
          )}
          onClick={displayTagsCommandCenter}
        >
          Create tag: "
          <span className="filter-no-matches-value">{filterValue}</span>"
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderTagSettingsSection: () => JSX.Element = () => {
    return (
      <div
        className={classNames(
          "flex items-center select-none",
          "last-option cursor-pointer",
          "paint-colors-selector-option",
          shouldHightlightSettingsSection ? "highlighted" : ""
        )}
        onMouseEnter={() => {
          setHighlightedIndex(null);
          setHighlightSettingsSection(true);
        }}
        onMouseLeave={() => setHighlightSettingsSection(false)}
        onClick={() => {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
            initialContent: APP_SETTINGS.PAINTER_SETINGS,
            initialSettingsUser: getMatchingUserFromAllUsers({
              allUsers: allLoggedInUsers,
              userEmail,
            }),
          });
          setSelectorDisplayIndex({ displayLocation: null });
        }}
      >
        <Settings size={14} strokeWidth={2} />
        <div className="ml-3">Tag settings</div>
      </div>
    );
  };

  /* Activate the color pick command center for tags */
  const displayTagsCommandCenter = () => {
    setDisableInput(true);
    setShowTagsCommandCenter(true);
  };

  /* Create updated tags array and pass it to setTags */
  const updateAndSetTags = useCallback(
    (tag, newTagIndex = null) => {
      // If the tag is a smart tag, we don't do anything
      if (
        matchingSettingTagInSmartTags({ inputTag: tag, smartTags: tags })
          ?.isSmartTag
      ) {
        return;
      }

      /* Map tags into an array of user_smart_tag_id */
      /* Then get the index of the paint color if it exist */
      const userTagIdToSearch = getTagId(tag);
      const existingTagIdx = tags
        .map((p_c) => getTagId(p_c))
        .indexOf(userTagIdToSearch);

      let updatedTags;
      /* Index of returns -1 if not found */
      if (existingTagIdx !== -1) {
        updatedTags = tags.filter((p_c) => getTagId(p_c) !== userTagIdToSearch);

        // @ts-ignore
        trackEvent({
          category: "tags",
          action: "remove_tag",
          label: displayLocation,
        });
      } else {
        updatedTags = [...tags, tag];

        // @ts-ignore
        trackEvent({
          category: "tags",
          action: "add_tag",
          label: displayLocation,
        });
      }

      /* Filter smart tags then get only the necessary keys */
      const filteredUpdatedTags = updatedTags
        .filter((tag) => !tag?.isSmartTag)
        .map((tag) => ({
          color: tag?.color,
          color_id: tag?.color_id,
          name: tag?.name,
          user_smart_tag_id: tag?.user_smart_tag_id,
          is_prioritized: tag?.is_prioritized ?? false,
        }));

      setTags(filteredUpdatedTags);

      /* If on right click menu, set highlight to newly created tag */
      if (newTagIndex) {
        setHighlightedIndex(newTagIndex);
      }
    },
    [displayLocation, setTags, tags]
  );

  /* Handle keyboard keys (arrow up and down, enter, and esc) */
  useEffect(() => {
    const updateHighlightedIndex = (e) => {
      const keyDown = KEYCODE_DOWN_ARROW;
      const keyEscape = KEYCODE_ESCAPE;
      const keyEnter = KEYCODE_ENTER;
      const keyUp = KEYCODE_UP_ARROW;

      /* Return if any of the following is not met */
      /* Selector isn't open */
      /* Key is not arrow up / down, escape, or enter */
      /* Paint color command center is open (disableInput) */
      if (
        !selectContainerRef?.current ||
        ![keyDown, keyEscape, keyEnter, keyUp].includes(e.keyCode) ||
        disableInput ||
        isModalOpen()
      ) {
        return;
      }

      /* Handle escape key */
      if (keyEscape === e.keyCode) {
        setSelectorDisplayIndex({ displayLocation: null, index: null });
      }

      /* Handle enter key */
      if (keyEnter === e.keyCode) {
        /* No matches, so we enter create flow */
        if (filteredTags.length === 0 && filterValue.length > 0) {
          displayTagsCommandCenter();
          return;
        }

        /* If we have paint colors at highlighted index */
        if (
          (highlightedIndex || highlightedIndex === 0) &&
          filteredTags[highlightedIndex] &&
          !matchingSettingTagInSmartTags({
            inputTag: filteredTags[highlightedIndex],
            smartTags: tags,
          })?.isSmartTag
        ) {
          updateAndSetTags(filteredTags[highlightedIndex]);
          return;
        }

        /* No labels so we open painter settings */
        if (
          filteredTags?.length === 0 &&
          highlightedIndex === 0 &&
          filterValue.length === 0
        ) {
          layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
            initialContent: APP_SETTINGS.PAINTER_SETINGS,
          });
        }
      }

      /* Handle arrow key */
      if ([keyDown, keyUp].includes(e.keyCode)) {
        /* When going up -> Decrease index and default to filtered paint colors length */
        /* This starts us at the last element if there is no index */

        /* When going down -> Increase index and default to -1 */
        /* This starts us at the first element if there is no index */
        const direction = e.keyCode === keyUp ? "up" : "down";
        let adjustedIndex;

        /* Handle no matching tags */
        if (filteredTags.length === 0) {
          adjustedIndex = 0;
        }

        /* Handle matching tag / no filter */
        if (filteredTags.length > 0) {
          adjustedIndex =
            direction === "up"
              ? (highlightedIndex ?? filteredTags?.length) - 1
              : (highlightedIndex ?? -1) + 1;

          /* Going down on the last element will wrap to the first element */
          if (adjustedIndex === filteredTags?.length) {
            adjustedIndex = 0;
          }

          /* Going up on the first element will wrap to the last element */
          if (adjustedIndex === -1) {
            adjustedIndex = filteredTags?.length - 1;
          }
        }

        setHighlightedIndex(adjustedIndex);
      }
    };

    window.addEventListener("keydown", updateHighlightedIndex);

    return () => {
      window.removeEventListener("keydown", updateHighlightedIndex);
    };
  }, [
    disableInput,
    filteredTags,
    filterValue,
    highlightedIndex,
    tags,
    selectContainerRef,
    setFilterValue,
    setSelectorDisplayIndex,
    updateAndSetTags,
  ]);

  /* Click handler for closing element */
  useEffect(() => {
    const handleMouseDown = (e) => {
      /* If user clicks outside of the container, hide selector */
      if (
        selectContainerRef?.current &&
        !selectContainerRef.current.contains(e.target)
      ) {
        /* For popupEvent, we do nothing and handle onClick in parent */
        /* Prevents opening again when we click to collapse (works like a toggle) */
        /* Also return if the paint color command center is open */
        if (
          (popupEventRef?.current &&
            popupEventRef.current.contains(e.target)) ||
          tagsCommandCenterRef?.current
        ) {
          return;
        }

        setSelectorDisplayIndex({ displayLocation: null });
      }
    };

    window.addEventListener("mousedown", handleMouseDown);
    setContainerStyle(getContainerStyle());

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [popupEventRef, setSelectorDisplayIndex]);

  /* Handle setting index to null in event form */
  /* Handle updating painter after select */
  useEffect(() => {
    /* For the input in tagsEventForm.tsx */
    tagsBroadcast.subscribe("SET_PAINTER_HIGHLIGHTED_INDEX_TO_NULL", () =>
      setHighlightedIndex(null)
    );
    tagsBroadcast.subscribe(UPDATE_EVENT_TAGS, updateAndSetTags);

    return () => {
      tagsBroadcast.unsubscribe("SET_PAINTER_HIGHLIGHTED_INDEX_TO_NULL");
      tagsBroadcast.unsubscribe(UPDATE_EVENT_TAGS);
    };
  }, [updateAndSetTags]);

  /* Check if event has colors above it */
  useEffect(() => {
    const colorSelector = document.getElementById(COLOR_SELECTOR_ID);

    if (colorSelector && !isColorSelectorAbove) {
      setIsColorSelectorAbove(true);
    }
  }, [isColorSelectorAbove]);

  /* Clean up when unmounting */
  useEffect(() => () => setFilterValue(""), [setFilterValue]);

  return (
    <div
      className={classNames(
        "paint-colors-selector-container",
        showOnLeftHandSide ? "right-160px-important left-initial-important" : "",
        displayLocation ?? "",
        isColorSelectorAbove ? "google-event" : "",
        "medium-blur"
      )}
      ref={selectContainerRef}
      style={containerStyle}
    >
      {isUserMagicLinkUser({user: matchingUser}) ? null
        : <div
          className="paint-colors-selector-input-container"
          onMouseEnter={() => setHighlightedIndex(null)}
        >
          <input
            className="paint-colors-input"
            onChange={(e) => setFilterValue(getInputStringFromEvent(e) ?? "")}
            placeholder="Add a tag..."
            type="text"
            value={filterValue}
            autoFocus={true}
          />
        </div>
      }

      <div className="paint-colors-selector-option-container">
        {filteredTags.length > 0 ? (
          filteredTags.map((p_c, idx) => (
            <div
              className={classNames(
                "paint-colors-selector-option flex",
                idx === highlightedIndex ? "highlighted" : ""
              )}
              onClick={() => updateAndSetTags(p_c)}
              onMouseEnter={() => setHighlightedIndex(idx)}
              key={`paint-colors-selector-${displayLocation}-${getTagId(p_c)}`}
            >
              <TagOption
                displayLocation={displayLocation}
                eventTags={tags}
                isSelector={true}
                tag={p_c}
              />
            </div>
          ))
        ) : (
          <>{renderEmptyTags()}</>
        )}

        <ColoredLine />
        {renderTagSettingsSection()}
      </div>
      {/* Render the command center if user is creating a new color */}
      {showTagsCommandCenter ? (
        <TagsCommandCenter
          filterValue={filterValue}
          handleModalClose={() => setShowTagsCommandCenter(false)}
          ref={tagsCommandCenterRef}
          setDisableInput={setDisableInput}
          setFilterValue={setFilterValue}
        />
      ) : null}
    </div>
  );
};

export default TagsSelector;
