import { constructRequestURL } from "../services/api";
import {
  constructQueryParams,
  determineElectronLoginEnv,
  handleError,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import { SSO_SAML_ENDPOINTS } from "./endpoints";
import { capitalizeFirstLetterOfEveryWord } from "./stringFunctions";

export const SSO_TOKEN_LOGIN_ERROR = {
  INVALID_TOKEN: "invalid token",
  NOT_FOUND: "not found",
  INVALID_LOGIN_TOKEN: "invalid login token", // already consumed or it's been 5 min
};

export function getSAMLRedirectLink({ email, isDesktopLogin, isMagicLink }) {
  const baseUrl = constructRequestURL(SSO_SAML_ENDPOINTS.REDIRECT, true);
  if (isDesktopLogin) {
    return `${baseUrl}?email=${email}&is_desktop=true`;
  }
  if (isMagicLink) {
    return `${baseUrl}?email=${email}&is_magic_link=true`;
  }
  return `${baseUrl}?email=${email}`;
}

export async function isDomainEnabled(domain) {
  if (!domain) {
    return;
  }
  // get does not take in param as a body, need to pass it in as query param
  const params = { domain };
  const queryParams = constructQueryParams(params);
  const url = `${constructRequestURL(
    SSO_SAML_ENDPOINTS.CHECK_IS_ENABLED,
    true
  )}?${queryParams}`;
  try {
    const response = await Fetcher.get(url, {}, false);
    return response?.is_enabled;
  } catch (e) {
    handleError(e);
  }
}

// Possible vendor names are:
// saml2
// saml1
// adfs
// azure_ad
// google
// okta
// one_login
// ping_identity
export const SSO_VENDOR_NAMES = {
  OKTA: "okta",
  SAML2: "saml2",
  SAML1: "saml1",
  ADFS: "adfs",
  AZURE_AD: "azure_ad",
  GOOGLE: "google",
  ONE_LOGIN: "one_login",
  PING_IDENTITY: "ping_identity",
  ENTRA: "entra",
};
export const DEFAULT_SSO_VENDOR_OPTION = {
  value: SSO_VENDOR_NAMES.OKTA,
  name: "Okta",
};

export function getSSOVendorOptions() {
  const getDisplayName = (vendor) => {
    const str = vendor
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return capitalizeFirstLetterOfEveryWord(str);
  };

  return Object.values(SSO_VENDOR_NAMES).map((vendor) => {
    return { value: vendor, label: getDisplayName(vendor) };
  });
}

export async function createSSOIdentityProviderConfig({
  samlAccountId,
  name,
  vendor,
  metadataXML,
  metadataURL,
  userEmail,
}) {
  const url = `${constructRequestURL(
    SSO_SAML_ENDPOINTS.CREATE_IDENTIFY_PROVIDER_CONFIG,
    true
  )}/${samlAccountId}`;
  const params = {
    saml_account_id: samlAccountId,
    name,
    vendor,
    metadata_xml: metadataXML?.trim(),
    metadata_url: metadataURL?.trim(),
  };
  const payloadData = {
    body: JSON.stringify(params),
  };
  // We need only one from metadata_xml or metadata_url, if both are passed the metadata_xml will be used
  //   {
  //     "saml_account_id": "saml_account_id",
  //     "name": "Configuration Name",
  //     "vendor": "Vendor Name",
  //     "metadata_xml": "Metadata XML",
  //     "metadata_url": "Metadata URL"
  // }
  try {
    const response = await Fetcher.post(url, payloadData, true, userEmail);

    // response:
    // {
    //   "identity_provider_configuration": {
    //     "id": "configuration_id",
    //     "saml_account_id": "saml_account_id",
    //     "name": "Configuration Name",
    //     "vendor": "Vendor Name",
    //     "metadata_xml": "Metadata XML",
    //     "metadata_url": "Metadata URL"
    //   }
    //     {
    //       "connection_configuration": {
    //       "entity_id": "81fc78f0afc45balsjdflaskdlffc681d5d05ba",
    //       "acs_url": "https://248e-186-243-95-108.ngrok-free.app/app/v2/sso/saml/callback?sp_entity_id=81fc78f0afc45b6ec035fcalksjdflajsldfjalsdf",
    //       "slo_url": "https://248e-186-243-95-108.ngrok-free.app/app/v2/sso/saml/e11961146a0181bcb2a8b19f70508278/logout?sp_entity_id=81fc78f0afc45b6ecalksjdflajsdlkfajsldfjalsdjfl",
    // acs_xml: <...>
    //   }
    //    }
    // }

    return response?.connection_configuration;
  } catch (e) {
    handleError(e);
  }
}

export async function getSSOIdentityConfig(userEmail) {
  if (!userEmail) {
    return;
  }
  const url = constructRequestURL(SSO_SAML_ENDPOINTS.GET_SSO_CONFIG, true);
  const response = await Fetcher.get(url, {}, true, userEmail);
  return response?.connection_configuration;
}

export const SAML_CONFIG_KEY = {
  ENTITY_ID: "entity_id",
  NAME: "name",
  VENDOR: "vendor",
  ACS_XML: "acs_xml",
  ACS_URL: "acs_url",
  SLO_URL: "slo_url", // for logout
};

// note: this is fake data used only for UI testing
export const SAML_CONFIG_MOCK_VALUES = {
  entity_id: "5asdfas5-askdjfalsjdlfksjalsjdf-2354rqwerqwerq",
  name: "Configuration Name",
  vendor: "Vendor Name",
  slo_url: "https://example.com/sp/slo",
  acs_xml: `<EntityDescriptor xmlns="urn:oasis:names:tc:SAML:2.0:metadata" entityID="https://example.com/sp">
    <SPSSODescriptor
        AuthnRequestsSigned="false"
        WantAssertionsSigned="true"
        protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
        
        <KeyDescriptor use="signing">
            <KeyInfo xmlns="http://www.w3.org/2000/09/xmldsig#">
                <X509Data>
                    <X509Certificate>
                        MIIC+DCCAeCgAwIBAgIGAYk25ZjhMA0GCSqGSIb3DQEBCwUAMB8xHTAbBgNVBAMM
                        FGV4YW1wbGUuY29tIFNpZ25pbmcgQ0EwHhcNMTkxMDAxMDAwMDAwWhcNMjAxMDAx
                        MDAwMDAwWjAeMRwwGgYDVQQDDBNaslkdjfalsjdflkatcGxlLmNvbTCCASIwDQYJ
                        KoZIhvcNAQEBBQADggEPADCCAQoCggEBAOJb4arDhQ2HgTx0ZpNxdFtiFbDJhKJ/
                        Pzkt7xzIp/4QpD/HePch5dWvJ3BFEI7R2E1tyMRYlNndSP1EJ57SFrT1ANbgd6sG
                        Ei9xriVB0PlI2xhBd1y9HnghQGBxY/zOlEOpR4jVdpvEmcd/cqE4KOvNNwi+TD7U
                        H4HcZwnYEnBhElHPL2VrsPbGy9S/2O8Z2Kt5Wot9I+6SnayGaslkdjflajsdfasd
                        d4exg0oE5F3hD5U9+ft6Kv/0XfpeE0k2iR8QVQIDAQABoyEwHzAdBgNVHQ4EFgQU
                        A2ltWojNcSgJeUF70A8elKrQ0MMwDQYJKoZIhvcNAQELBQADggEBAIhsRnVG6tBz
                        ruu0/o3Wom/DFDFkz9GnT4RPOLXL3YftElK+nxZbGBI2w2K4OzgFZsKn+06CvQ0m
                        C0uMy0SCMx/RFeBMLgZzXhweL1MrqCJ26e6mpQPKKpOWn7JbJjvcrUg4l1ygE2vq
                        BM2lB1HPP6B48w5YpBl3xgkhOWX2u1FbqG3SLtOFlQ==
                    </X509Certificate>
                </X509Data>
            </KeyInfo>
        </KeyDescriptor>
        
        <NameIDFormat>
            urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress
        </NameIDFormat>
        
        <AssertionConsumerService
            Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"
            Location="https://example.com/sp/acs"
            index="0"/>
            
        <SingleLogoutService
            Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect"
            Location="https://example.com/sp/slo"/>
    </SPSSODescriptor>
</EntityDescriptor>
`,
  acs_url: "Metadata URL",
};

export async function authenticateViaSSOLoginToken({ ssoLoginToken }) {
  try {
    const url = constructRequestURL(
      SSO_SAML_ENDPOINTS.SSO_AUTHENTICATION,
      true
    );
    const payloadData = {
      body: JSON.stringify({ sso_login_token: ssoLoginToken }),
      credentials: "include",
    };
    return Fetcher.post(url, payloadData, false);
  } catch (error) {
    return error;
  }
}

export function createDesktopLoginLinkFromSSOToken({ ssoLoginToken }) {
  const env = determineElectronLoginEnv();
  return `vimcal-${env}://sso_login_token=${ssoLoginToken}`;
}
