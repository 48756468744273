import React from "react";
import {
  getEmailDomain,
  getInputStringFromEvent,
  lowerCaseAndTrimString,
} from "../../../lib/stringFunctions";
import { getUserEmail } from "../../../lib/userFunctions";
import { useSelector } from "react-redux";
import { EXCLUDED_DOMAINS } from "../../../services/globalVariables";
import DomainLabelContainer from "./domainLabelContainer";
import { hasEventPreventDefault } from "../../../services/commonUsefulFunctions";
import { KEYBOARD_EVENT_KEYS } from "../../../services/keyboardEventFunctions";

interface InternalDomainSettingProps {
  selectedUser: User
  addEmail: (email: string) => void
  removeEmail: (email: string) => void
  domainEmails: string[]
  currentDomainText: string
  setCurrentDomainText: StateSetter<string>
}

export default function InternalDomainSetting({
  selectedUser,
  addEmail,
  removeEmail,
  domainEmails,
  currentDomainText,
  setCurrentDomainText,
}: InternalDomainSettingProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const user = selectedUser || currentUser;

  const getDomain = () => {
    const userDomain = getEmailDomain(getUserEmail(user));
    if (!userDomain || (EXCLUDED_DOMAINS as Readonly<string[]>).includes(userDomain)) {
      return "appleseed.com";
    }

    return userDomain;
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (([
      KEYBOARD_EVENT_KEYS.ENTER,
      KEYBOARD_EVENT_KEYS.TAB,
      KEYBOARD_EVENT_KEYS.COMMA,
      KEYBOARD_EVENT_KEYS.SPACE,
    ] as string[]).includes(e.key)) {
      // add input value
      hasEventPreventDefault(e);
      const formattedInputValue = lowerCaseAndTrimString(currentDomainText);
      if (!formattedInputValue) {
        return;
      }
      addEmail(formattedInputValue);
      setCurrentDomainText("");
    }
  };

  const placeHolderDomainURL = getDomain();

  return (
    <div>
      <div className="secondary-text-color default-font-size mt-2">
        Enter a domain or email that counts as an internal meeting.
      </div>
      <input
        className="default-input-field mt-4 font-weight-300 default-font-size w-full"
        style={{ height: 34 }}
        placeholder={`E.g. ${placeHolderDomainURL} or johnny@${placeHolderDomainURL}`}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        onChange={(e) => {
          const text = getInputStringFromEvent(e);
          setCurrentDomainText(text);
        }}
        value={currentDomainText}
        onKeyDown={onKeyDown}
      />
      <DomainLabelContainer
        domainEmails={domainEmails}
        removeEmail={removeEmail}
      />
    </div>
  );
}
