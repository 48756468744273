import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  KEYCODE_ESCAPE,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  KEYCODE_ENTER,
  KEYCODE_CONTROL,
  combinedContactAndDomainResponse,
  hasEventPreventDefault,
  isValidAttendeeString,
  getRecentContactsMatches,
  KEYCODE_COMMAND_FIREFOX,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import { getListOfEmailsFromString, splitGroupedAttendees } from "../lib/eventFunctions";
import { getRecentlySearchedContacts } from "../lib/stateManagementFunctions";
import classNames from "classnames";
import {
  filterOutInvalidContacts,
  getContactGroupMatches,
  getDomainAndContacts,
  getRecentContactsForUpsert,
  sortContactsArrayByExactMatch,
} from "../lib/contactFunctions";
import { differenceInSeconds } from "date-fns";
import { getReactSelectBaseStyle } from "./select/styles";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { getMeetWithUserEmail } from "../services/meetWithFunctions";
import { getUserEmail } from "../lib/userFunctions";
import { getAbsoluteDifferenceInLengthOfStrings, isStringInString, isUrl, isValidEmail, lowerCaseAndTrimString, truncateString } from "../lib/stringFunctions";
import { isEmptyArrayOrFalsey, isTypeString } from "../services/typeGuards";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../lib/vimcalVariables";
import { getObjectEmail } from "../lib/objectFunctions";
import CreatableSelect from "../../components/creatableSelect";
import { TYPING_DELAY } from "../services/globalVariables";
import { searchDistroLists } from "../lib/distroListFunctions";


class ReactSelectAttendeeAutoComplete extends Component {
  constructor(props) {
    super(props);

    this._searchTimeout = null;

    const recentContacts = this.createRecentContacts();

    this.state = {
      contacts: recentContacts,
      query: "",
      recentContacts: recentContacts,
      selectedOption: this.createDefault(),
      pressCommand: null,
      preventOpenMenuOnFocus: false, // so we don't open menu on focus
    };

    this.setAttendee = this.setAttendee.bind(this);
    this.createDefault = this.createDefault.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.filterOption = this.filterOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.useRecentlySearchedContacts &&
      this.props.currentUser !== prevProps.currentUser
    ) {
      this.setState({ recentContacts: this.createRecentContacts() });
    }

    if (
      this.props.shouldUpdateDefaultTextOnChange &&
      this.props.defaultText !== prevProps.defaultText
    ) {
      this.setState({ selectedOption: this.createDefault() });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._searchTimeout);
  }

  render() {
    return (
      <div onFocusCapture={this.props.onFocus} className={this.props.containerClassName ?? ""}>
        <CreatableSelect
          inputId={this.props.id || "creatable-attendee"}
          ref={this.props.innerRef}
          className={classNames(
            "react-select-attendee-auto-complete",
            "select-default-font-size",
            this.props.className ??
              classNames(
                "select-attendee",
                this.props.additionalClassNames ?? "",
              ),
          )}
          classNamePrefix={
            this.props.isInModal ? "dark-mode-modal" : "dark-mode"
          }
          value={this.state.selectedOption}
          onFocusCapture={this.onFocus}
          promptText="Add attendees"
          isDisabled={this.props.isDisabled}
          isSearchable={true}
          autoFocus={this.props.shouldAutoFocus}
          tabIndex={this.props.inputTabIndex}
          onKeyDown={this.onKeyDown}
          onChange={this.setAttendee}
          options={
            this.state.query.length > 0
              ? this.state.contacts
              : this.state.recentContacts
          }
          onBlur={this.onBlur}
          noOptionsMessage={() =>
            this.props.useRecentlySearchedContacts
              ? "No recently searched contacts"
              : "No recent contacts"
          }
          formatCreateLabel={(userInput) =>
            `${this.props.createLabel || "Add"} ${userInput}`
          }
          openOnFocus={true}
          maxMenuHeight={this.props.maxMenuHeight || 200}
          tabSelectsValue={
            this.state.query?.length > 0 ? true : false
          }
          onInputChange={this.onInputChange}
          onFocus={this.onFocus}
          openMenuOnFocus={!this.state.preventOpenMenuOnFocus && !this.props.hideMenuOnFocus}
          inputValue={this.state.query}
          styles={getReactSelectBaseStyle({
            isDarkMode: this.props.isDarkMode,
            controllerBackgroundColor: this.props.controllerBackgroundColor,
            controlWidth: this.props.controllerWidth,
            controllerMarginLeft: this.props.controllerMarginLeft,
            showBorder: this.props.hideBorder ? false : true,
          })}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          filterOption={this.filterOption}
        />
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onBlur() {
    this.props.onBlurReactSelectAttendeeAutoComplete &&
      this.props.onBlurReactSelectAttendeeAutoComplete();
  }

  filterOption(data) {
    // data comes in the format of
    // data:
    //   {value: 'john@test.com', label: 'John Test (john@test.com)', name: 'John Test', contact: {…}}
    //   label: "John Test (john@test.com)"
    //   value: "john@test.com"
    try {
      const {
        value,
        label,
      } = data;
      const {
        query,
      } = this.state;
      if (!query) {
        // user has typed nothing in, should just render list
        return true;
      }
      return isStringInString(value, query) || isStringInString(label, query);
    } catch (e) {
      return true;
    }
  }

  onFocus() {
    this.props.onFocus && this.props.onFocus();
  }

  async fetchContactsFromDB(input, isPaste = false) {
    if (!input) {
      return;
    }

    const searchInput = lowerCaseAndTrimString(input);
    const existingEmails = this.props.selectedGuests;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const {
      currentUser,
      calendarUserEmail,
      shouldFetchDistroLists,
    } = this.props;

    let { contactResponse, domainResponse } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser,
      userEmail: calendarUserEmail ?? getUserEmail(currentUser),
      searchText: searchInput,
    });

    if (!this._isMounted) {
      return;
    }
    if (input !== this.state.query) {
      // has changed
      return;
    }
    if (shouldFetchDistroLists) {
      const userEmail = calendarUserEmail || getUserEmail(currentUser);
      const distroLists = await searchDistroLists({ string: searchInput, userEmail });

      if (!this._isMounted) {
        return;
      }

      const formattedDistroLists = distroLists.map(distroList => {
        const name = distroList.name || "";
        return {
          email: getObjectEmail(distroList),
          firstName: name,
          fullName: [name],
          name,
          primary: true,
          userEmail,
        };
      });
      domainResponse = domainResponse.concat(formattedDistroLists);
    }

    this.setOptionsBasedOnSearch({
      domainResponse,
      contactResponse,
      existingEmails,
      isPaste,
    });
  }

  onInputChange(value, action) {
    if (action.action !== "input-change") {
      return;
    }

    this.props.setValidAddressWarning &&
      this.props.setValidAddressWarning(false);

    // if user paste -> get results immediately
    const isPaste = getAbsoluteDifferenceInLengthOfStrings(this.state.query, value) > 1;
    const shouldSkipDebounce = isPaste;
    this.setState({ query: value }, () => {
      clearTimeout(this._searchTimeout);
      this.props.updateAttendeeQuery && this.props.updateAttendeeQuery(value);
      if (!value) {
        return;
      }

      if (shouldSkipDebounce) {
        this.fetchContactsFromDB(value, isPaste);
        return;
      }

      // search with indexdb
      this._searchTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.fetchContactsFromDB(value, isPaste);
      }, TYPING_DELAY);
    });
  }

  setOptionsBasedOnSearch({
    domainResponse,
    contactResponse,
    existingEmails,
    isPaste,
  }) {
    if (!this._isMounted) {
      return;
    }
    const {
      currentUser,
      useRecentlySearched,
    } = this.props;
    const {
      query,
    } = this.state;

    const recentContactMatches = getRecentContactsMatches(
      useRecentlySearched
        ? getRecentlySearchedContacts(currentUser)
        : getRecentContactsForUpsert({ user: currentUser }),
      query,
    ).filter((c) => !c.hasMultiple);

    const contactGroupMatches = this.props.skipContactLists ? [] : getContactGroupMatches(
      currentUser,
      query,
    );
    const nickAndRecentSearchMatches =
      recentContactMatches.concat(contactGroupMatches);
    const filteredContacts = combinedContactAndDomainResponse(
      nickAndRecentSearchMatches,
      domainResponse,
      contactResponse,
      existingEmails,
    );
    let sortedEmails = sortContactsArrayByExactMatch(
      filteredContacts,
      query,
    );
    if (isPaste && isValidEmail(query)) {
      // searchResults
      // if paste result -> always add it first
      sortedEmails = [{
        email: query,
      }].concat(sortedEmails);
    }

    const searchResults = sortedEmails.map((c) => {
      const selectLabelAndValue = this.createContactValueAndLabel(c);
      selectLabelAndValue.contact = c;
      return selectLabelAndValue;
    });

    // searchResult contains array of:
    // {
    //   "value": "alex@vimcal.com",
    //   "label": "Alex Bayer (alex@vimcal.com)",
    //   "name": "Alex Bayer",
    //   "contact": {
    //       "name": "Alex Bayer",
    //       "email": "alex@vimcal.com",
    //       "updated": "",
    //       "fullName": [
    //           "alex",
    //           "bayer",
    //           "alex bayer"
    //       ]
    //   }
    // }
    if (this.props.showDomainSearchOption && (isUrl(query) || isUrl(query?.substring(1)))) {
      const label = `Add domain: ${query}`;
      this.setState({ contacts: [
        {
          value: query,
          isAddDomain: true, // so parent container knows this is to add the domain
          label,
          name: label,
          domain: query,
        },
      ].concat(searchResults) });
      return;
    }

    this.setState({ contacts: searchResults });
  }

  resetInputFocus() {
    // inorder to show the blinker, we need to blur and then focus the input
    // blur -> prevent menu from opening -> focus -> allow menu to open
    this.setState({preventOpenMenuOnFocus: true}, () => {
      this.blurInput();
      this.focusInput();
      this.setState({preventOpenMenuOnFocus: false});
    });
  }

  setAttendee(selected) {
    this.resetInputFocus();

    const email = selected?.value?.email
      ? selected.value.email
      : selected.value;
    const emailList = getListOfEmailsFromString(email, [
      getUserEmail(this.props.currentUser),
    ]);
    const newState = {};
    newState["contacts"] = this.createRecentContacts(); // reset contacts to default on search and enter

    if (isValidAttendeeString(email)) {
      this.props.setValidAddressWarning &&
        this.props.setValidAddressWarning(false);
    }
    newState["query"] = "";
    this.props.updateAttendeeQuery && this.props.updateAttendeeQuery("");

    // remove value after adding attendee
    newState["selectedOption"] = this.createDefault();

    this.setState(newState);
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { currentUser, emailToNameIndex } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;

    if (this.props.addAttendeeList && emailList?.length > 1) {
      if (this.isGroupVotePage()) {
        this.props.addAttendeeList(splitGroupedAttendees(
          selected,
          currentUser,
          emailToNameIndex,
        ));
      } else {
        this.props.addAttendeeList(emailList);
      }
    } else if (this.props.includeEmailAndName && selected?.value) {
      // Handle edge case when multiple emails selected but gets filtered down to one.
      if (this.isGroupVotePage() && emailList?.length === 1 && selected.emailArray?.length > 1) {
        const splitAttendees = splitGroupedAttendees(
          selected,
          currentUser,
          emailToNameIndex,
        );
        const matchedAttendee = splitAttendees.find(a => a.value === emailList[0]);
        if (matchedAttendee) {
          this.props.addAttendees(matchedAttendee);
        }
      } else {
        this.props.addAttendees(selected);
      }
    } else if (this.props.addAttendeeIntoEventForm) {
      this.props.addAttendeeIntoEventForm({
        email,
        userEmail: getMeetWithUserEmail({
          allLoggedInUsers,
          contact: selected?.contact,
          currentUser,
          allCalendars,
          masterAccount,
        }),
        contact: selected?.contact,
      });
    } else if (this.props.addAttendee) {
      this.props.addAttendee(email);
    }
  }

  focusInput() {
    if (this.props.innerRef?.current?.focus) {
      this.props.innerRef?.current?.focus();
    }
  }

  blurInput() {
    if (this.props.innerRef?.current?.blur) {
      this.props.innerRef?.current?.blur();
    }
  }

  onKeyDown(event) {
    // command-left = 91
    // enter = 13
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
      return;
    }

    if (event && event.keyCode === KEYCODE_ESCAPE) {
      this.props.innerRef &&
        this.props.innerRef.current &&
        this.props.innerRef.current.blur();

      this.props.onEscape && this.props.onEscape();
    } else if (!this.props.onCommandEnter) {
      return;
    } else if (
      event &&
      [KEYCODE_ENTER].includes(event.keyCode) &&
      isValidJSDate(this.state.pressCommand) &&
      differenceInSeconds(new Date(), this.state.pressCommand) < 2
    ) {
      hasEventPreventDefault(event);

      this.props.onCommandEnter && this.props.onCommandEnter();
    } else if (
      event &&
      [
        KEYCODE_CONTROL,
        KEYCODE_COMMAND_LEFT,
        KEYCODE_COMMAND_RIGHT,
        KEYCODE_COMMAND_FIREFOX,
      ].includes(event.keyCode)
    ) {
      this.setState({ pressCommand: new Date() });
    } else if (this.state.pressCommand) {
      this.setState({ pressCommand: null });
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  createDefault() {
    const defaultText = "Add attendees";
    const text = this.props.defaultText || defaultText;

    return [{ label: text, value: text }];
  }

  createRecentContacts(contacts = null) {
    const { currentUser } = this.props;
    const formattedContacts =
      contacts ||
      (this.props.useRecentlySearchedContacts
        ? getRecentlySearchedContacts(currentUser)
        : getRecentContactsForUpsert({ user: currentUser })) ||
      [];
    const filteredContacts = filterOutInvalidContacts(formattedContacts);
    const recentContacts = filteredContacts.map((c) => {
      return this.createContactValueAndLabel(c);
    });
    if (this.props.skipContactLists) {
      return recentContacts.filter(c => isEmptyArrayOrFalsey(c.emailArray));
    }

    return recentContacts;
  }

  createContactValueAndLabel(c) {
    if (c?.emailArray?.length > 0) {
      return {
        value: c.email,
        label: truncateString(this.createValue(c), 200),
        name: c.name,
        emailArray: c.emailArray,
      };
    }

    return {
      value: c.email,
      label: truncateString(this.createValue(c), 200),
      name: c.name,
    };
  }

  createValue(contact) {
    const { email, name, hasMultiple, fullName } = contact;
    const getInputName = () => {
      if (name) {
        return name;
      }
      if (fullName && isTypeString(fullName)) {
        // this is out of abundance of caution since full name can be an array at times
        return fullName;
      }
    };
    const inputName = getInputName();

    if (!inputName) {
      return email;
    }

    if (hasMultiple) {
      return inputName;
    }

    if (inputName.includes(email)) {
      return email;
    } else {
      return `${inputName} (${email})`;
    }
  }

  isGroupVotePage() {
    return this.props.componentLocation === REACT_ATTENDEE_SELECT_LOCATION.GROUP_VOTE_DETAIL_PAGE_LOCATION;
  }
}

function mapStateToProps(state) {
  const { currentUser, emailToNameIndex, isDarkMode } = state;

  return { currentUser, emailToNameIndex, isDarkMode };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allCalendars = useAllCalendars();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      allCalendars={allCalendars}
    />
  );
};

export default connect(
  mapStateToProps,
)(withRouter(withStore(ReactSelectAttendeeAutoComplete)));
