import React, { Component } from "react";
import CustomButton from "../../components/customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import {
  isCommandKeyPressed,
  isElectron,
  KEYCODE_ENTER,
  isMac,
  detectBrowser,
  getOS,
} from "../../services/commonUsefulFunctions";
import _ from "underscore";
import classNames from "classnames";
import { FEEDBACK_TYPE } from "../../lib/vimcalVariables";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import FeedbackAttachments from "../../components/feedbackAttachments";
import RadioButton from "../../components/radioButton";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import Dylan from "./Dylan.png";
import Icee from "./Icee.png";
import { X } from "react-feather";

const FEEDBACK_TYPES = [
  {
    key: "feedback",
    label: "Feedback",
    type: FEEDBACK_TYPE.FEEDBACK,
  },
  {
    key: "bug_report",
    label: "Report",
    type: FEEDBACK_TYPE.BUG_REPORT,
  },
  {
    key: "feature_request",
    label: "Feature Request",
    type: FEEDBACK_TYPE.FEATURE_REQUEST,
  },
  {
    key: "question",
    label: "Question",
    type: FEEDBACK_TYPE.QUESTION,
  },
];

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbackTypeIndex: this.getFeedbackTypeIndex(),
      description: "",
      keyMap: {},
      attachmentKeys: [],
      areAllAttachmentsUploaded: true,
      hasClickedSubmit: false,
    };

    this.submitFeedback = this.submitFeedback.bind(this);
    this.onSelectFeedbackType = this.onSelectFeedbackType.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.updateKeyMap = this.updateKeyMap.bind(this);

    // can't use mousetrap -> if focused on input -> mousetrap won't work
    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener("keyup", this.handleKeyUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener("keyup", this.handleKeyUp, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const wasReadyToSubmit = prevState.hasClickedSubmit && prevState.areAllAttachmentsUploaded;
    const isReadyToSubmit = this.state.hasClickedSubmit && this.state.areAllAttachmentsUploaded;

    // Safely handle the case where the user clicks submit before all the attachments finish uploading.
    // Only trigger submitFeedback once, when the form becomes ready to submit.
    if (isReadyToSubmit && !wasReadyToSubmit) {
      this.submitFeedback();
    }
  }

  render() {
    return (
      <div>
        {this.renderHeader()}

        {this.renderDescribeFeedback()}
        <FeedbackAttachments
          setAttachmentKeys={attachmentKeys => this.setState({ attachmentKeys })}
          setAreAllAttachmentsUploaded={areAllAttachmentsUploaded => this.setState({ areAllAttachmentsUploaded })}
        />
        {this.renderActions()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderHeader() {
    const IMAGE_SIZE_CLASSNAME = "w-12 h-12";

    const onClose = () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    };

    return (
      <div>
        <div className="flex justify-between items-start">
          <div className="relative">
            <img src={Dylan} alt="feedback" className={classNames(IMAGE_SIZE_CLASSNAME, "z-index-1", "feedback-headshot-border")} />
            <img src={Icee} alt="feedback" className={classNames(IMAGE_SIZE_CLASSNAME, "absolute left-7", "feedback-headshot-border")} />
          </div>
          <X size={16} className="hoverable-secondary-text-color" onClick={onClose} />
        </div>
        <div className="font-weight-400 font-size-14">
          How can we help?
        </div>

        <div className="mt-4 flex items-center">
          {this.renderFeedbackTypeList()}
        </div>
      </div>
    );
  }

  renderFeedbackTypeList() {
    return FEEDBACK_TYPES.map((type, index) => {
      return (
        <div
          style={{ display: "flex", marginRight: "35px", cursor: "pointer" }}
          key={`feedbackOption${index}`}
          onClick={() => this.onSelectFeedbackType(index)}
        >
          <RadioButton isSelected={this.state.feedbackTypeIndex === index} isThinBorder={true} />
          <span
            className={classNames(
              "margin-left-five default-font-size select-none",
              this.state.feedbackTypeIndex === index
                ? "color-default-text-color"
                : "secondary-text-color",
            )}
          >
            {type.label}
          </span>
        </div>
      );
    });
  }

  renderDescribeFeedback() {
    return (
      <div className="my-4">
        <textarea
          autoFocus
          ref={(input) => (this.description = input)}
          spellCheck={true}
          onChange={(event) =>
            this.setState({ description: getInputStringFromEvent(event) })
          }
          value={this.state.description}
          placeholder={"I need help with..."}
          className="text-template-input-content-field feedback-description-textarea line-height-20px rounded-md"
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          onClick={this.props.onClose}
          buttonType={WHITE_BUTTON}
          label="Cancel"
          addPaddingToRight={true}
        />

        <CustomButton
          onClick={() => this.setState({ hasClickedSubmit: true })}
          buttonType={BLUE_BUTTON}
          label={"Send"}
          shouldRenderSpinner={this.state.hasClickedSubmit}
        />
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onSelectFeedbackType(index) {
    this.setState({ feedbackTypeIndex: index });
  }

  submitFeedback() {
    const feedbackData = this.constructFeedbackData();
    backendBroadcasts.publish("SEND_FEEDBACK", { feedbackData, type: FEEDBACK_TYPES[this.state.feedbackTypeIndex].type });

    this.props.onClose();
  }

  //=================
  // PRIVATE METHODS
  //=================

  constructFeedbackData() {
    const getPlatform = () => {
      // Mac Desktop App (Intel)
      // Mac Desktop App (M1)
      // Mac Browser (Safari)
      // Mac Browser (Chromium)
      // Mac Browser (Mozilla)
      // Windows Desktop App
      // Windows Browser (Chromium)
      // Windows Browser (Mozilla)
      // iPhone App
      // iPad App (edited)
      if (isElectron()) {
        return isMac() ? "Mac Desktop App" : "Windows Desktop App";
      } else {
        return `${getOS()} Browser (${detectBrowser()})`;
      }
    };

    return {
      feedback: {
        feedback_type: FEEDBACK_TYPES[this.state.feedbackTypeIndex].key,
        description: this.state.description,
        platform: getPlatform(),
        os: getOS(),
        device: null,
        attachment_keys: this.state.attachmentKeys,
      },
    };
  }

  updateKeyMap(e) {
    let keyMap = _.clone(this.state.keyMap);
    keyMap[e.keyCode] = e.type === "keydown";

    this.setState({ keyMap });
  }

  handleKeyUp(e) {
    this.setState({ keyMap: {} });
  }

  handleKeyDown(e) {
    this.updateKeyMap(e);

    if (
      this.state.keyMap[KEYCODE_ENTER] &&
      isCommandKeyPressed(this.state.keyMap)
    ) {
      this.submitFeedback();
    }
  }

  getFeedbackTypeIndex() {
    switch (this.props.feedbackType) {
      case FEEDBACK_TYPE.FEEDBACK:
        return 0;
      case FEEDBACK_TYPE.BUG_REPORT:
        return 1;
      case FEEDBACK_TYPE.FEATURE_REQUEST:
        return 2;
      case FEEDBACK_TYPE.QUESTION:
        return 3;
      default:
        return 0;
    }
  }
}

export default Feedback;
