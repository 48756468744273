import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getCombinedEmailContact,
} from "../../../../lib/stateManagementFunctions";
import ReactSelectAttendeeAutoComplete from "../../../reactSelectAttendeeAutoComplete";
import CustomButton from "../../../customButton";
import { BLUE_BUTTON, LIGHTGRAY } from "../../../../services/globalVariables";
import DisabledButton from "../../../disabledButton";
import classNames from "classnames";
import _ from "underscore";
import { X, Plus, FilePlus, Trash } from "react-feather";
import ShortcutHoverHint from "../../../shortcutHoverHint";
import { useAllLoggedInUsers, useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { getAllUsers, SelectUser, SELECT_USER_TYPE } from "../../common/selectUser";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import ColoredLine from "../../../line";
import { extractEmailsFromString, getInputStringFromEvent, isValidEmail, truncateString } from "../../../../lib/stringFunctions";
import { removeDuplicatesFromArray } from "../../../../lib/arrayFunctions";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { getContactGroups } from "../../../../lib/settingsFunctions";
import { createUUID } from "../../../../services/randomFunctions";
import { isEmptyArrayOrFalsey } from "../../../../services/typeGuards";
import { getUserToken } from "../../../../lib/userFunctions";

const SELECT_USER_ID = "contact-group-select-user";

type ContactGroupWithUUID = ContactGroup & { uuid: string }

export default function ContactsGroupModal() {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);

  const selectedUser = useMemo(() => {
    const allUsers = getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: SELECT_USER_TYPE.ALL_USERS,
      addExecutiveLabel: true,
    });
    return allUsers[selectedUserIndex]?.value;
  }, [allLoggedInUsers, currentUser, masterAccount, selectedUserIndex]);

  const getInitialList = useCallback((user: User): ContactGroupWithUUID[] => {
    const filteredList = getContactGroups({ user }).filter(
      (c) => c.hasMultiple,
    );
    return filteredList?.length >= 1
      ? filteredList.map(group => ({ ...group, uuid: createUUID() }))
      : [createNewGroup()];
  }, []);

  const [groupContacts, setGroupContacts] = useState<ContactGroupWithUUID[]>(
    () => getInitialList(selectedUser),
  );
  const [hasChanged, setHasChanged] = useState(false);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex) ?? {};

  const [selectedContactGroupIndex, setSelectedContactGroupIndex] = useState(0);

  const isNewGroup = (group: ContactGroupWithUUID) => {
    return !group?.name && group?.emailArray?.length === 0;
  };

  useEffect(() => {
    // reset on user change
    const recentlySearchedContacts = getInitialList(selectedUser);
    setGroupContacts(recentlySearchedContacts);
    setSelectedContactGroupIndex(0);
    setHasChanged(false);
  }, [getUserToken(selectedUser)]);

  useEffect(() => {
    const element = document.getElementById(
      `nick-name-option_${selectedContactGroupIndex}`,
    );
    if (!element) {
      return;
    }

    element.scrollIntoView();
  }, [groupContacts.length]);

  const onDeleteContactLabel = (index: number) => {
    const determineNewIndex = () => {
      if (index === 0) {
        return 0;
      } else if (index === groupContacts.length - 1) {
        // last element
        return groupContacts.length <= 1 ? 0 : groupContacts.length - 2;
      } else {
        return index - 1;
      }
    };
    setSelectedContactGroupIndex(determineNewIndex);

    const updatedGroupedContacts = groupContacts.filter((_, i) => i !== index);
    if (updatedGroupedContacts.length === 0) {
      updatedGroupedContacts.push(createNewGroup());
    }
    setGroupContacts(updatedGroupedContacts);

    setHasChanged(true);
  };

  const hasReachedGroupLengthLimit = () => {
    // Limit up to 30 contact nick names
    return groupContacts?.length >= 30;
  };

  const onClickDuplicate = () => {
    if (
      hasReachedGroupLengthLimit() ||
      (isNewGroup(groupContacts[0]) && groupContacts.length === 1)
    ) {
      return;
    }

    const duplicatedElement = _.clone(groupContacts[selectedContactGroupIndex]);
    duplicatedElement["name"] = "";
    duplicatedElement.uuid = createUUID();
    const updatedGroupContacts = groupContacts.concat(duplicatedElement);
    setGroupContacts(updatedGroupContacts);
    setSelectedContactGroupIndex(updatedGroupContacts.length - 1);
    setHasChanged(true);
  };

  const updateGroup = (updatedGroup: ContactGroupWithUUID, index: number) => {
    const updatedGroupedContacts = groupContacts
      .slice(0, index)
      .concat(updatedGroup)
      .concat(groupContacts.slice(index + 1, groupContacts.length));
    setGroupContacts(updatedGroupedContacts);
    setHasChanged(true);
  };

  const onClickAddMore: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e?.stopPropagation();
    if (hasReachedGroupLengthLimit()) {
      return;
    }

    const updatedGroupContacts = groupContacts.concat(createNewGroup());
    setGroupContacts(updatedGroupContacts);
    setSelectedContactGroupIndex(updatedGroupContacts.length - 1);
    setHasChanged(true);
  };

  const renderContactGroupForInformation = () => {
    const group = groupContacts[selectedContactGroupIndex];
    if (!group) {
      return null;
    }

    const onChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      let updatedGroup = _.clone(groupContacts[selectedContactGroupIndex]);
      updatedGroup = Object.assign(updatedGroup, { name: getInputStringFromEvent(e) });
      updateGroup(updatedGroup, selectedContactGroupIndex);
    };

    // TODO: I don't think emailArray is ever included here.
    // Validate once reactSelectAttendeeAutoComplete is migrated to TS.
    const addAttendees = (attendee: { emailArray?: string[], label: string, name: string, value: string }) => {
      if (!attendee) {
        return;
      }

      const getEmailList = () => {
        if (attendee?.emailArray) {
          return attendee?.emailArray;
        }
        const extractEmails = extractEmailsFromString(attendee.label);
        if (isValidEmail(attendee.value)) {
          return [attendee.value];
        }
        if (!isEmptyArrayOrFalsey(extractEmails)) {
          return extractEmails;
        }
        return [];
      };

      const attendeeEmailList = getEmailList();
      let updatedGroup = _.clone(groupContacts[selectedContactGroupIndex]);
      const updatedEmailList = removeDuplicatesFromArray(
        updatedGroup.emailArray
          ? updatedGroup.emailArray.concat(attendeeEmailList).sort()
          : attendeeEmailList,
      ).filter((e) => isValidEmail(e));

      updatedGroup = Object.assign(updatedGroup, {
        emailArray: updatedEmailList,
        email: getCombinedEmailContact(updatedEmailList, emailToNameIndex)
          .email,
      });
      updateGroup(updatedGroup, selectedContactGroupIndex);
    };

    return (
      <div className="flex-grow overflow-y-auto rounded pr-2">
        {renderDeleteAndDuplicateButtons()}
        <div className="flex items-center justify-between">
          <div className="select-none default-font-size secondary-text-color">
            Nickname:
          </div>
          <input
            className="default-input-field ml-2.5"
            style={{ height: 37, width: 320 }}
            placeholder="E.g. Core team"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            onChange={(e) => onChangeText(e)}
            value={group?.name || ""}
          />
        </div>

        <div className="flex items-center justify-between mt-2">
          <div className="select-none default-font-size secondary-text-color">
            Contacts:
          </div>
          <ReactSelectAttendeeAutoComplete
            createLabel="Add"
            defaultText="Search for people"
            addAttendees={(attendee: { label: string, name: string, value: string }) => {
              addAttendees(attendee);
            }}
            maxMenuHeight={150}
            additionalClassNames="select-attendee-nickname"
            id={`creatable-attendee-${selectedContactGroupIndex}`}
            // className="availability-search-attendees contact-nick-name"
            useRecentlySearchedContacts={true}
            includeEmailAndName={true}
            useRecentlySearched={true}
          />
        </div>
        {renderExpandedAttendeeList(group, selectedContactGroupIndex)}
      </div>
    );
  };

  const renderDeleteAndDuplicateButtons = () => {
    return (
      <div className="flex w-full justify-end mb-2">
        <ShortcutHoverHint
          below
          style={{
            width: "max-content",
            marginLeft: "-100px",
          }}
          title={"Duplicate group"}
        >
          <FilePlus
            size={14}
            className={classNames(
              hasReachedGroupLengthLimit() || isNewGroup(groupContacts[0])
                ? "opacity-30"
                : "clickable-icon cursor-pointer",
            )}
            onClick={onClickDuplicate}
          />
        </ShortcutHoverHint>

        <ShortcutHoverHint
          below
          style={{
            width: "max-content",
            marginLeft: "-65px",
          }}
          title={"Remove group"}
        >
          <Trash
            size={14}
            className={classNames(
              groupContacts?.length === 1 && isNewGroup(groupContacts[0])
                ? "opacity-30"
                : "clickable-icon cursor-pointer",
              "ml-4",
            )}
            onClick={
              groupContacts?.length === 1 && isNewGroup(groupContacts[0])
                ? _.noop
                : () => onDeleteContactLabel(selectedContactGroupIndex)
            }
          />
        </ShortcutHoverHint>
      </div>
    );
  };

  const onClickSave = () => {
    const filteredList = groupContacts.filter(
      (c) => c?.hasMultiple && c?.emailArray?.length >= 1,
    );
    setHasChanged(false);

    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
      {
        settings: {
          contact_groups: filteredList,
        },
        user: selectedUser,
      },
    );
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
  };

  const renderButtons = () => {
    return (
      <div className="flex justify-end pt-4">
        {hasChanged ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={onClickSave}
            label="Save"
          />
        ) : (
          <DisabledButton label="Save" />
        )}
      </div>
    );
  };

  const renderExpandedAttendeeList = (group: ContactGroupWithUUID, index: number) => {
    if (!group || !group.emailArray) {
      return null;
    }

    const onClickDeleteEmail = (emailIndex: number) => {
      let updatedGroup = _.clone(groupContacts[index]);
      const updatedEmailList = group.emailArray.filter((_, i) => i !== emailIndex);

      updatedGroup = Object.assign(updatedGroup, {
        emailArray: updatedEmailList,
        email: getCombinedEmailContact(updatedEmailList, emailToNameIndex)
          .email,
      });
      updateGroup(updatedGroup, index);
    };

    return (
      <div className="flex flex-col items-end">
        {group.emailArray.map((email, emailIndex) => {
          return (
            <div
              className="default-font-size mt-2 flex items-center container-hover-icon-visibility"
              key={email}
            >
              <div className=" max-w-sm truncate-text select-none">
                {truncateString(email, 40)}
              </div>

              <X
                className="clickable-icon ml-2"
                size={14}
                onClick={() => onClickDeleteEmail(emailIndex)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderContactGroupOptionsMenu = () => {
    return groupContacts.map((group, index) => {
      const nickname = group.name || "New group";
      const isSelectedNickName = index === selectedContactGroupIndex;
      return (
        <div
          key={group.uuid}
          id={`nick-name-option_${index}`}
          className={classNames(
            isSelectedNickName
              ? "modal-selected-option-background-color"
              : "modal-hover-background-color",
            "cursor-pointer",
            "w-52",
            "p-3",
            "display-flex-flex-direction-row",
            "align-middle",
            "justify-between",
          )}
          onClick={() => setSelectedContactGroupIndex(index)}
        >
          <div
            className="default-font-size w-72 truncate-text margin-top-3 h-18 select-none"
            style={{ height: 19 }}
          >
            {nickname}
          </div>
        </div>
      );
    });
  };

  const renderAddRemoveButtons = () => {
    return (
      <div
        className={classNames(
          "flex items-center rounded-b-md justify-center",
          hasReachedGroupLengthLimit()
            ? "disabled-icon"
            : "hoverable-secondary-text-color cursor-pointer",
          "py-2 mr-2",
          "default-font-size select-none",
        )}
        style={{
          borderBottom: `1px solid ${isDarkMode ? "#343645" : LIGHTGRAY}`,
          borderLeft: `1px solid ${isDarkMode ? "#343645" : LIGHTGRAY}`,
          borderRight: `1px solid ${isDarkMode ? "#343645" : LIGHTGRAY}`,
        }}
        onClick={onClickAddMore}
      >
        <Plus size={12} className="mr-2" />
        New group
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="default-font-size secondary-text-color">
        Group your friends and teammates together (e.g. "Eng team") and add the entire group to an invite all at once.
      </div>
      <ColoredLine inputClassName="mt-4 mb-4" />
      <div className="flex-grow flex gap-4 h-0">
        <div className="flex flex-col w-52">
          <div
            style={{ border: `1px solid ${isDarkMode ? "#343645" : LIGHTGRAY}` }}
            className="mr-2 rounded-t-md flex-grow overflow-x-hidden overflow-y-auto"
          >
            {renderContactGroupOptionsMenu()}
          </div>
          {renderAddRemoveButtons()}
        </div>
        <SelectUser
          setSelectedUserIndex={setSelectedUserIndex}
          id={SELECT_USER_ID}
          selectedUser={selectedUser}
          inputContainerClassName="settings-select-user-position"
          addExecutiveLabel={true}
          showAccountLabel={true}
        />

        {renderContactGroupForInformation()}
      </div>
      {renderButtons()}
    </div>
  );
}

function createNewGroup(): ContactGroupWithUUID {
  // email: "john@vimcal.com, mchlzhao@gmail.com, alex@vimcal.com"
  // emailArray: (3) ["john@vimcal.com", "mchlzhao@gmail.com", "alex@vimcal.com"]
  // hasMultiple: true
  // name: "John Li, Michael Zhao, & Alex Bayer"
  // isContactGroup: true
  return {
    email: "",
    emailArray: [],
    hasMultiple: true,
    name: "",
    isContactGroup: true,
    uuid: createUUID(),
  };
}
