import React, { useState, useEffect } from "react";
import {
  getSearchParams,
  handleError,
  openLinkOnSamePage,
} from "../services/commonUsefulFunctions";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import Spinner from "./spinner";
import { getHomeLink } from "../lib/envFunctions";

const TUTORIAL = "tutorial";
const PRODUCT_UPDATES = "product_update";
const METRICS = "metrics";
const UNSUBSCRIBE_IMAGE =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/sad_puppy_2.jpeg";

export default function UnsubscribeFromEmail() {
  const [type] = useState(getURLParams().type);
  const [userToken] = useState(getURLParams().token);
  const [isLoading, setIsLoading] = useState(true);

  const componentIsMounted = useIsMounted();

  const hitUnsubscribe = () => {
    const path = `users/${userToken}/${type}/unsubscribe`;
    const url = constructRequestURL(path);

    Fetcher.post(url, {}, false)
      .then((response) => {
        if (!componentIsMounted.current) {
          return;
        }

        setIsLoading(false);
      })
      .catch(handleError);
  };
  useEffect(() => {
    hitUnsubscribe();

    // remove token and user token information from url without reloading page
    // https://stackoverflow.com/questions/824349/how-do-i-modify-the-url-without-reloading-the-page
    window.history.pushState("", "", "/unsubscribe");
  }, []);

  const renderLoadingState = () => {
    return <Spinner />;
  };

  const renderContent = () => {
    const determineUnsubscribeType = () => {
      switch (type) {
        case TUTORIAL:
          return "tutorial";
        case PRODUCT_UPDATES:
          return "product update";
        case METRICS:
          return "metric";
        default:
          return "these";
      }
    };

    return (
      <div className="m-5 box-shadow-0">
        <div className="bg-white text-2xl font-weight-400 py-5 rounded-t-lg text-center items-center justify-center flex">
          Unsubscribe Successful
        </div>

        <div className="bg-white py-5 pb-5 rounded-b-lg flex flex-col items-center">
          <div className="mb-5 text-center">
            {`We’ll miss you! You’ll no longer receive ${determineUnsubscribeType()} emails from this list.`}
          </div>

          <div className="w-full flex justify-center items-center">
            <img alt="" width="70%" className="mb-5" src={UNSUBSCRIBE_IMAGE} />
          </div>

          <div
            className="cursor-pointer text-blue-600 underline mb-5"
            onClick={() => openLinkOnSamePage(getHomeLink())}
          >
            {"Return to Vimcal"}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="w-screen height-100vh flex items-center justify-center light-mode-font-color"
      style={{ backgroundColor: "#E7ECEE" }}
    >
      {isLoading ? renderLoadingState() : renderContent()}
    </div>
  );
}

function getURLParams() {
  const urlParams = getSearchParams();
  if (!urlParams || !urlParams.get) {
    return { type: "", token: "" };
  }

  const type = urlParams.get("type");
  const token = urlParams.get("ut");

  return { type, token };
}
