import React, { useState, useEffect } from "react";
import Spinner from "../spinner";

export default function LoadingState() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const updateDots = () => {
      if (dots.length < 3) {
        setDots(dots + ".");
      } else {
        setDots("");
      }
    };

    const interval = setInterval(updateDots, 500);

    return () => {
      clearInterval(interval);
    };
  }, [dots]);

  return (
    <div className="metrics-loading-screen">
      <Spinner className="mb-20" />
      <p className="w-48 select-none secondary-text-color ml-5">
        Loading your metrics{dots}
      </p>
      <div className="secondary-text-color default-font-size mt-4">
        We'll let you know through email and in app-notification when your metrics are ready
      </div>
    </div>
  );
}
