global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */
import React from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../select";
import classNames from "classnames";

import { blurInputOnEscape } from "../../services/commonUsefulFunctions";
import { NO_CONFERENCE_STRING } from "../../services/googleCalendarService";
import { convertOutlookConferencingToHumanReadable } from "../../lib/outlookFunctions";
import { OUTLOOK_CONFERENCING } from "../../resources/outlookVariables";


const ConferenceSelect = (props) => {
  const {
    conference,
    inputRef,
    onChange,
    id,
    notification,
    options
  } = props;

  const isDarkMode = useSelector((state) => state.isDarkMode);
  // mark previously selected option with ' (restore)'
  const onChangeValue = (option) => {
    onChange({option});
  }

  return (
    <CustomSelect
      tabIndex={9}
      isSearchable={false}
      ref={inputRef}
      openMenuOnFocus={true}
      tabSelectsValue={false}
      className={classNames(
        "select-conference",
        notification ?? "",
        isDarkMode ? "dark-mode-select" : "",
        conference === NO_CONFERENCE_STRING ? "font-color-secondary-text" : "",
        "ml-2.5"
      )}
      id={id}
      value={{
        label: convertConferencingToHumanReadable(conference),
        value: conference,
      }}
      onChange={onChangeValue}
      classNamePrefix="dark-mode"
      options={options}
      onKeyDown={(event) => blurInputOnEscape(event, inputRef.current)}
      autoFocus={false}
    />
  );
};

function convertConferencingToHumanReadable(conferencing) {
  if (OUTLOOK_CONFERENCING[conferencing]) {
    return convertOutlookConferencingToHumanReadable(conferencing);
  }

  return conferencing;
}

export default ConferenceSelect;
