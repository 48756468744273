import React from "react";
import { getHumanReadableDollarFromCents } from "../../../../lib/stripeFunctions";
import { pluralize } from "../../../../lib/stringFunctions";
import { StripeCost } from "../../updatedAddTeamMembersModal";
import { useIsFixedSeating, useOpenSeats } from "../../hooks";
import LoadingSkeleton from "../../../loadingSkeleton";
import { getStripeCostNewTotal } from "../../../../services/accessors/stripeCost";

interface SeatSummaryProps {
  isPayingMonthly: boolean
  stripeCost: StripeCost
  newVimcalEAEmails: string[]
  newVimcalEmails: string[]
  vimcalEAUnitPrice: number
  vimcalUnitPrice: number
}

export default function SeatSummary({ isPayingMonthly, stripeCost, newVimcalEAEmails, newVimcalEmails, vimcalEAUnitPrice, vimcalUnitPrice }: SeatSummaryProps) {
  const isFixedSeating = useIsFixedSeating();
  const canPurchaseSeats = !isFixedSeating;
  const billingCycle = isPayingMonthly ? "month" : "year";

  // If fixed seating, there's nothing to fetch.
  const isFetchingStripeCost = (!isFixedSeating && getStripeCostNewTotal(stripeCost) === null);
  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();

  return (
    <div className="secondary-text-color mb-4">
      <SeatTypeSummary
        product="Vimcal"
        billingCycle={billingCycle}
        canPurchaseSeats={canPurchaseSeats}
        isFetchingStripeCost={isFetchingStripeCost}
        openSeatsLeft={openVimcalSeats - newVimcalEmails.length}
        unitPrice={vimcalUnitPrice}
      />
      <SeatTypeSummary
        product="Vimcal EA"
        billingCycle={billingCycle}
        canPurchaseSeats={canPurchaseSeats}
        isFetchingStripeCost={isFetchingStripeCost}
        openSeatsLeft={openVimcalEASeats - newVimcalEAEmails.length}
        unitPrice={vimcalEAUnitPrice}
      />
    </div>
  );
}

interface SeatTypeSummaryProps {
  billingCycle: string
  canPurchaseSeats: boolean
  isFetchingStripeCost: boolean
  openSeatsLeft: number
  product: string
  unitPrice: number
}

/**
 * A summary of a seat type including how many seats are open and, if allowed, how much it costs to add a seat.
 * If there are no open seats and the user cannot add seats, nothing is rendered.
 */
function SeatTypeSummary({ billingCycle, canPurchaseSeats, isFetchingStripeCost, openSeatsLeft, product, unitPrice }: SeatTypeSummaryProps) {
  if (isFetchingStripeCost) {
    return <LoadingSkeleton className="mb-2" style={{ height: "min-content", width: 400 }}>&nbsp;</LoadingSkeleton>;
  }

  const copy = (() => {
    const humanReadableUnitPrice = getHumanReadableDollarFromCents(unitPrice);

    // It's possible for users to have negative open seats if there was some manual setup done to work around
    // our previous limitations.
    // Let's just show zero here, they will see the cost to fix the seats on the next screen.
    let copy = `${Math.max(openSeatsLeft, 0)} open ${product} ${pluralize(openSeatsLeft, "seat")} remaining`;
    if (canPurchaseSeats) {
      copy += `, then $${humanReadableUnitPrice}/${billingCycle} to add more`;
    }

    return copy;
  })();

  if (!copy) {
    return null;
  }

  return (
    <div className="mb-2">{copy}</div>
  );
}
