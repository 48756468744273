import React from "react";
import classNames from "classnames";
import SaveAndCancelButton from "../../../buttons/saveAndCancelButton";

interface UserNameModalProps {
  isWarningModalOpen: boolean
  onClickExit: () => void
  onClickSave: () => void
}

export default function UserNameModal({ isWarningModalOpen, onClickExit, onClickSave }: UserNameModalProps) {
  if (!isWarningModalOpen) {
    return null;
  }

  return (
    <div
      className={classNames(
        "absolute w-full h-full top-0 left-0",
        "default-modal-overlay-background-color",
        "flex items-center justify-center"
      )}
      onClick={onClickExit}
    >
      <div className="default-font-size default-font-color default-modal-content-background-color p-6 rounded-md">
        Changing your username will mean that all of your copied links will no
        longer work and will need to be updated.
        <SaveAndCancelButton
          onClose={onClickExit}
          onConfirm={onClickSave}
          confirmButtonText={"Update"}
        />
      </div>
    </div>
  );
}
