import classNames from "classnames";
import { addMinutes, subMinutes } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import agendaBroadcast from "../broadcasts/agendaBroadcast";
import { determineEventColor, isCancelledEvent, isValidEvent } from "../lib/eventFunctions";
import { isAppInTaskMode } from "../services/appFunctions";
import broadcast from "../broadcasts/broadcast";
import {
  determineTimeRemaining,
  FormatIntoJSDate,
  GetConferenceURL,
  guessTimeZone,
  hasStopEventPropagation,
  isBeforeMinute,
  isInFocusMode,
  isSameOrAfterMinute,
  isSameOrBeforeMinute,
  isWindows,
  openConferencingURL,
  OpenGoogleMapsLocation,
  sortEventsByStartTime,
} from "../services/commonUsefulFunctions";
import { HOVER_UPCOMING_EVENT_ID } from "../services/elementIDVariables";
import {
  getEventLocation,
  getEventUniqueEtag
} from "../services/eventResourceAccessors";
import { HOVER_UPCOMING_EVENTS } from "../services/globalVariables";
import { useHideRightHandSidebar } from "../services/stores/appFunctionality";
import { useHiddenEventsIDs } from "../services/stores/eventsData";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { capitalizeFirstLetter, pluralize } from "../lib/stringFunctions";
import { createUUID } from "../services/randomFunctions";
import ShortcutTiles from "./shortcutTiles/shortcutTiles";
import { getMatchingUIUserForEvent } from "../lib/tagsFunctions";

export default function HoverUpcomingEvent() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const upcomingEventsRef = useRef([]);

  const [renderCount, setRenderCount] = useState(0);
  const currentUser = useSelector((state) => state.currentUser);
  const actionMode = useSelector((state) => state.actionMode);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const reverseSlotsText = useTemporaryStateStore((state) => state.reverseSlotsText);
  const outlookCategories = useOutlookCategoriesStore((state) => state.outlookCategories);

  const {
    hiddenEventsIDs, 
    addHiddenEventsIDs,
    resetHiddenEventsIDs
  } = useHiddenEventsIDs();
  
  const hideRightHandSidebar = useHideRightHandSidebar();

  const onExitEvent = (event) => {
    const eventUserEventID = getEventUniqueEtag(event);
    if (!eventUserEventID) {
      return;
    }
    
    addHiddenEventsIDs([eventUserEventID]);
  };

  const updateRenderCount = () => {
    setRenderCount(createUUID(5));
  };

  const saveUpcomingEvent = (events) => {
    if (!events) {
      return;
    }

    setUpcomingEvents(events);
    upcomingEventsRef.current = events;
  };

  const removeCurrentEvent = () => {
    const eventIDs = upcomingEventsRef.current.map(e => getEventUniqueEtag(e));
    addHiddenEventsIDs(eventIDs);
  }

  const removeCurrentEventIfOutdated = () => {
    const currentEvent = upcomingEventsRef.current[0];
    if (!isValidEvent(currentEvent)) {
      return;
    }

    if (isBeforeMinute(addMinutes(currentEvent.eventStart, 10), new Date())) {
      removeCurrentEvent();
    }
  }

  useEffect(() => {
    agendaBroadcast.publish("GET_UPCOMING_EVENTS", HOVER_UPCOMING_EVENTS);
  }, [hideRightHandSidebar.shouldHideRightHandSide]);
  
  useEffect(() => {
    agendaBroadcast.subscribe("SET_HOVER_UPCOMING_EVENT", saveUpcomingEvent);
    agendaBroadcast.subscribe(
      "UPDATE_HOVER_UPCOMING_EVENT_COUNT",
      updateRenderCount
    );
    agendaBroadcast.subscribe("CLEAR_OUT_HIDDEN_EVENTS", () => {
      resetHiddenEventsIDs()
    });
    agendaBroadcast.subscribe("REMOVE_EVENT_FROM_UPCOMING_EVENT", (event) => {
      onExitEvent(event);
    });
    agendaBroadcast.subscribe("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT", removeCurrentEvent);
    agendaBroadcast.subscribe("REMOVE_CURRENT_EVENT_IF_OUTDATED", removeCurrentEventIfOutdated);

    agendaBroadcast.publish("GET_UPCOMING_EVENTS", HOVER_UPCOMING_EVENTS);

    return () => {
      agendaBroadcast.unsubscribe("SET_HOVER_UPCOMING_EVENT");
      agendaBroadcast.unsubscribe("UPDATE_HOVER_UPCOMING_EVENT_COUNT");
      agendaBroadcast.unsubscribe("CLEAR_OUT_HIDDEN_EVENTS");
      agendaBroadcast.unsubscribe("REMOVE_EVENT_FROM_UPCOMING_EVENT");
      agendaBroadcast.unsubscribe("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT");
      agendaBroadcast.unsubscribe("REMOVE_CURRENT_EVENT_IF_OUTDATED");
    };
  }, []);

  if (
    isAppInTaskMode({
      actionMode,
      reverseSlotsText
    }) ||
    isInFocusMode()
  ) {
    return null;
  }

  if (upcomingEvents.length === 0) {
    return null;
  }

  const currentTime = new Date();
  const currentTimeZone = guessTimeZone();
  const formattedEvent = upcomingEvents.map((event) =>
    FormatIntoJSDate(event, currentTimeZone)
  );
  const tenMinsAfter = subMinutes(currentTime, 10);
  const twoMinutesBefore = addMinutes(currentTime, 2);

  const shouldDisplayEvent = (event) => {
    if (isCancelledEvent(event)) {
      return false;
    } else if (
      isSameOrAfterMinute(event.eventStart, currentTime) &&
      isSameOrBeforeMinute(event.eventStart, twoMinutesBefore)
    ) {
      // starting within next 2 minutes
      return true;
    } else if (
      isSameOrAfterMinute(event.eventStart, tenMinsAfter) &&
      isSameOrBeforeMinute(event.eventStart, currentTime)
    ) {
      // started within last 10 minutes
      return true;
    } else {
      return false;
    }
  };

  const filteredEvents = formattedEvent.filter((e) => shouldDisplayEvent(e));

  if (filteredEvents.length === 0) {
    return null;
  }

  const sortedEvents = sortEventsByStartTime(formattedEvent);
  const displayEvent = sortedEvents[0];

  if (hiddenEventsIDs.includes(getEventUniqueEtag(displayEvent))) {
    return null;
  }

  const renderActionButton = (event) => {
    const conferenceURL = GetConferenceURL(event);
    const location = getEventLocation(event);
    const CLASS_NAME = classNames(
      "default-font-size",
      "flex items-center h-8 rounded flex justify-center duration-200 text-white py-2 px-3 cursor-pointer justify-between w-24",
      "hover:bg-blue-600 bg-blue-500",
    );

    if (conferenceURL) {
      return (
        <div
          className={CLASS_NAME}
          onClick={(e) => {
            hasStopEventPropagation(e);
            openConferencingURL(conferenceURL, currentUser.email);
            broadcast.publish("SHOW_NEXT_EVENT");
            onExitEvent(displayEvent);
          }}
        >
          <div className="">Join</div>

          <ShortcutTiles shortcut="V" />
        </div>
      );
    } else if (location) {
      return (
        <div
          className={CLASS_NAME}
          onClick={(e) => {
            hasStopEventPropagation(e);
            OpenGoogleMapsLocation(location, currentUser);
            broadcast.publish("SHOW_NEXT_EVENT");
            onExitEvent(displayEvent);
          }}
        >
          <div className="">Map</div>
          <ShortcutTiles shortcut="L" />
        </div>
      );
    } else {
      return (
        <div
          className={CLASS_NAME}
          onClick={(e) => {
            hasStopEventPropagation(e);
            broadcast.publish("SHOW_NEXT_EVENT");
            onExitEvent(displayEvent);
          }}
        >
          <div className="">Open</div>
          <ShortcutTiles shortcut="N" />
        </div>
      );
    }
  };

  const { timeRemainingText } = determineTimeRemaining(displayEvent);
  const matchingEventUser = getMatchingUIUserForEvent({
    event: displayEvent,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
    currentUser,
  });

  return (
    <div
      className={classNames(
        "fixed bottom-10 left-position-center-hover-upcoming-events z-50 rounded-lg",
        "hover-upcoming-event-background-color",
        isDarkMode ? "small-blur" : "large-blur",
        "flex items-center justify-between",
        "pl-4 pr-4 py-2",
        isDarkMode ? "" : "box-shadow-6",
        "select-none",
        "cursor-pointer",
        "default-bottom-left-modal-border"
      )}
      id={HOVER_UPCOMING_EVENT_ID}
      onClick={() => {broadcast.publish("SHOW_NEXT_EVENT")}}
    >
      <div className="w-44">
        <div
          className={classNames(
            "font-size-12",
            isDarkMode ? "text-gray-400" : "text-gray-400",
            "ml-4"
          )}
        >
          {timeRemainingText ? capitalizeFirstLetter(timeRemainingText) : "Now"}
        </div>

        <div className="flex items-center">
          <div
            style={{
              backgroundColor: determineEventColor({
                event: displayEvent,
                allCalendars,
                user: matchingEventUser,
                currentUser,
                allLoggedInUsers,
                outlookCategories,
                masterAccount,
                where: "hoverUpcomingEvent",
              }),
            }}
            className={classNames("rounded-full w-2 h-2 mr-2", isWindows() ? "" : "mb-0.5")}
          ></div>
          <div
            className={classNames(
              "default-font-size truncate-text max-width-150px",
              "white"
            )}
          >
            {displayEvent.summaryUpdatedWithVisibility}
          </div>
        </div>

        {sortedEvents.length > 1 ? (
          <div
            className={classNames(
              "default-font-size",
              "ml-4",
              isDarkMode ? "text-gray-400" : "text-gray-400"
            )}
          >{`+${sortedEvents.length - 1} more ${pluralize(
            sortedEvents.length - 1,
            "event"
          )}`}</div>
        ) : null}
      </div>

      <div className="flex items-center">
        {renderActionButton(displayEvent)}

        <X
          size={16}
          className="clickable-icon ml-3"
          onClick={(e) => {
            hasStopEventPropagation(e);
            onExitEvent(displayEvent);
          }}
        />
      </div>
    </div>
  );
}
