import React from "react";
import {
  getMetricsMetWithData,
  getMetricsPeopleMet,
  getMetricsTotalPeopleMet,
} from "./metricsAccessorFunctions";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import classNames from "classnames";
import { isGenericDomain } from "../../lib/stringFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import { immutablySortArray, isEmptyArray } from "../../lib/arrayFunctions";
import { Copy } from "react-feather";
import broadcast from "../../broadcasts/broadcast";
import { SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../services/globalVariables";
import { copyContent } from "../../services/appFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import ShortcutHoverHint from "../shortcutHoverHint";

export default function MetricsMetWith({ data, showSkeletonScreen }) {
  const metWithData = getMetricsMetWithData(data);
  if (!showSkeletonScreen && isEmptyArray(metWithData)) {
    // if not showing skeleton and has no data -> do not show section
    return null;
  }
  const renderContent = () => {
    if (showSkeletonScreen) {
      return (
        <div className="flex flex-col gap-4">
          <MetricsSkeletonLoadingPanel width={400} height={40} />
          <MetricsSkeletonLoadingPanel width={400} height={40} />
          <MetricsSkeletonLoadingPanel width={400} height={40} />
          <MetricsSkeletonLoadingPanel width={400} height={40} />
        </div>
      );
    }

    const getIndividualMetWithText = (item) => {
      const { domain, sample_people, total_people_met } = item;
      if (isGenericDomain(domain)) {
        const samplePerson = sample_people?.[0];
        if (samplePerson?.name) {
          return `${samplePerson.name} (${getObjectEmail(samplePerson)})`;
        }
        return `${getObjectEmail(samplePerson)}`;
      }
      if (sample_people?.length === 1) {
        const samplePerson = sample_people?.[0];
        if (total_people_met - 1 <= 0) {
          return `${
            samplePerson.name || getObjectEmail(samplePerson)
          } at ${domain}`;
        }
        return `${samplePerson.name || getObjectEmail(samplePerson)} & ${
          total_people_met - 1
        } more at ${domain}`;
      }
      if (total_people_met - 2 <= 0) {
        return `${
          sample_people[0]?.name || getObjectEmail(sample_people[0])
        } & ${
          sample_people[1]?.name || getObjectEmail(sample_people[1])
        } at ${domain}`;
      }

      return `${sample_people[0]?.name || getObjectEmail(sample_people[0])}, ${
        sample_people[1]?.name || getObjectEmail(sample_people[1])
      }, and ${total_people_met - 2} more at ${domain}`;
    };

    return (
      <div className="gap-4 metrics-default-font-size metrics-primary-text-color flex flex-col">
        {metWithData.map((item, index) => {
          const samplePeople = item.sample_people;
          const profilePictureBaseClassname = "rounded-full w-8 h-8 mr-2";
          const imgSrc = samplePeople?.[0]?.profile_photo_url || "";
          return (
            <div key={index} className="flex items-center gap-1">
              {imgSrc ? (
                <img
                  className={classNames(profilePictureBaseClassname)}
                  src={imgSrc}
                  alt="profile"
                />
              ) : (
                <div
                  className={classNames(
                    profilePictureBaseClassname,
                    "default-bottom-left-modal-border metrics-met-with-without-profile-url",
                  )}
                ></div>
              )}
              <div>{getIndividualMetWithText(item)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderCopyButton = () => {
    if (showSkeletonScreen) {
      return null;
    }
    const peopleMetWith = getMetricsPeopleMet(data);
    if (isEmptyArrayOrFalsey(peopleMetWith)) {
      return null;
    }
    return (
      <ShortcutHoverHint
        below
        title={"Copy people you met with"}
        inputClassName="flex items-center"
        style={{
          width: "max-content",
          marginLeft: "-50px",
          marginTop: "14px",
          height: 33,
          top: "8px",
        }}
      >
        <Copy
          className="hoverable-secondary-text-color"
          size={14}
          onClick={() => {
            const peopleMetWithString = immutablySortArray(peopleMetWith).join(", ");
            copyContent(peopleMetWithString);
            broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Copied people met",
            );
          }}
        />
      </ShortcutHoverHint>
    );
  };

  const numberOfPeopleMet = getMetricsTotalPeopleMet(data);
  return (
    <div>
      <div
        className={classNames(
          "mt-12 font-weight-300 font-size-16",
          "flex gap-2",
          "flex items-center",
        )}
      >
        <div className="default-font-color">People you met with</div>
        {renderCopyButton()}
      </div>
      <div className="mt-2 default-font-size secondary-text-color">{`You met with ${numberOfPeopleMet} ${
        numberOfPeopleMet === 1 ? "person" : "people"
      } this week.`}</div>
      <div className="default-font-size w-full metrics-data-container mt-5">
        {renderContent()}
      </div>
    </div>
  );
}
