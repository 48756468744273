import classNames from "classnames";
import React, { useState } from "react";
import { Gift, X } from "react-feather";
import { useSelector } from "react-redux";
import { shouldDisplayReferralInCorner } from "../lib/featureFlagFunctions";
import { getDefaultPaymentMethod } from "../lib/stateManagementFunctions";
import {
  getShouldHideReferralPopup,
  setShouldHideReferralPopup,
  shouldShowTrialActive,
} from "../lib/userFunctions";
import broadcast from "../broadcasts/broadcast";
import { hasStopEventPropagation } from "../services/commonUsefulFunctions";
import { useDefaultPaymentMethod } from "../services/stores/finance";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { trackReferral } from "./tracking";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { useReferralStore } from "../services/stores/appFunctionality";

export default function ReferralPopup() {
  const [hideReferralPopup, setHideReferralPopup] = useState(
    getShouldHideReferralPopup()
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const defaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.defaultPaymentMethod
  );
  const isInReferBoost = useReferralStore((state) => state.isInReferBoost);

  if (hideReferralPopup) {
    return null;
  }

  const getDefaultUserPaymentMethod = () => {
    return defaultPaymentMethod ?? getDefaultPaymentMethod();
  };

  if (
    shouldDisplayReferralInCorner({ masterAccount }) ||
    shouldShowTrialActive({
      masterAccount,
      defaultPaymentMethod: getDefaultUserPaymentMethod(),
    })
  ) {
    return null;
  }

  const onClickExit = (e) => {
    hasStopEventPropagation(e);
    setHideReferralPopup(true);
    setShouldHideReferralPopup();
  };

  const getNormalUserReferralText = () => {
    if (isInReferBoost) {
      return "You both get 2 months free when they subscribe" ;
    }
    return "You both get 1 month free when they subscribe" ;
  }

  return (
    <div
      className={classNames(
        "refer-popup-container rounded-lg mx-6 p-4",
        "mt-5 mb-5",
        "cursor-pointer duration-200",
        "relative"
      )}
      onClick={() => {
        broadcast.publish("SHOW_REFER_TO_VIMCAL_MODAL")
        trackReferral({
          user: currentUser,
          action: "clicked_referral_popup"
        });
      }}
    >
      <X
        size={14}
        className="absolute right-2 top-2 cursor-pointer secondary-text-color duration-200 hover-primary-color"
        onClick={onClickExit}
      />
      <div className="flex items-center">
        <Gift size={14} />
        <div className="ml-2 font-weight-300">
          { isUserMaestroUser(masterAccount) ? 
            "Invite friends and get rewarded!" : 
            "Invite friends and earn free Vimcal" 
          }
        </div>
      </div>

      <div className="secondary-text-color mt-2 font-weight-300 font-size-11" style={{ wordBreak: "break-word" }}>
        { isUserMaestroUser(masterAccount) ? 
          "We will send you a $100 Amazon gift card for each friend that subscribes." : 
          getNormalUserReferralText()
        }
      </div>
    </div>
  );
}
