import React from "react";
import { getObjectEmail } from "../../lib/objectFunctions";
import { sendMessageToSentry } from "../../services/commonUsefulFunctions";
import { useTeamPlan } from "../../services/stores/userData";
import { isMemberAdmin } from "../teamPlans/sharedFunctions";

/**
 * The billing settings a user sees when they are NOT an admin of their subscription.
 * This includes members of a team plan that are not admins.
 */
export default function NonAdminBillingSettings() {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const admins = (teamPlan.active_users || []).filter(isMemberAdmin);

  if (admins.length === 0) {
    // This should never happen, every team plan needs at least 1 admin.
    sendMessageToSentry("Error showing billing settings", "No admins exist on this team plan");
    return null;
  }

  if (admins.length > 1) {
    return (
      <div className="default-font-size">
        <div>
          For questions about your subscription, please contact one of your admins:
        </div>
        <ul className="list-inside my-1">
          {admins.map(admin => (
            <li key={getObjectEmail(admin)}><AdminName admin={admin} /></li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="default-font-size">
      For questions about your subscription, please contact your admin:{" "}
      <AdminName admin={admins[0]} />
    </div>
  );
}

function AdminName({ admin }: { admin: TeamPlanMember }) {
  return (
    <span>
      {admin.full_name || getObjectEmail(admin)}
      {admin.full_name ? ` (${getObjectEmail(admin)})` : null}
    </span>
  );
}
