import React from "react";
import { Minus, Plus } from "react-feather";
import { VerticalLine } from "../verticalLine";

const BUTTON_CLASS_NAME = "w-8 h-full flex items-center justify-center cursor-pointer select-none hoverable-secondary-text-color";

interface PlusMinusInputProps {
  value: number
  setValue: StateSetter<number>
}

export default function PlusMinusInput({ value, setValue }: PlusMinusInputProps) {
  return (
    <div className="flex items-center h-9 secondary-border-color rounded-lg">
      <div
        className={BUTTON_CLASS_NAME}
        onClick={() => {
          const updatedValue = value - 1;
          if (updatedValue < 0) {
            setValue(0);
            return;
          }
          setValue(updatedValue);
        }}
      >
        <Minus size={14} />
      </div>
      <VerticalLine />
      <div className="w-8 flex items-center justify-center">{value}</div>
      <VerticalLine />
      <div
        className={BUTTON_CLASS_NAME}
        onClick={() => {
          setValue(value + 1);
        }}
      >
        <Plus size={14} />
      </div>
    </div>
  );
}
