/* eslint-disable react/display-name */
import React, { PureComponent } from "react";
import { useSelector } from "react-redux";
import magicLinkBroadcast from "../../broadcasts/magicLinkBroadcast";
import { MAGIC_LINK_BROADCAST_VALUES } from "../../lib/broadcastValues";
import Fetcher from "../../services/fetcher";
import { constructRequestURLV2 } from "../../services/api";
import { lowerCaseAndTrimString } from "../../lib/stringFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { handleError } from "../../services/commonUsefulFunctions";
import broadcast from "../../broadcasts/broadcast";
import { SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../services/globalVariables";
import { MAGIC_LINK_ENDPOINTS } from "../../lib/endpoints";

class MagicLinksContainer extends PureComponent {
  constructor(props) {
    super(props);

    /* Binds */
    this.createMagicLink = this.createMagicLink.bind(this);

    /* Broadcast subscriptions */
    magicLinkBroadcast.subscribe(
      MAGIC_LINK_BROADCAST_VALUES.CREATE_MAGIC_LINK,
      this.createMagicLink,
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    /* Unsubscribe from broadcast */
    magicLinkBroadcast.unsubscribe(
      MAGIC_LINK_BROADCAST_VALUES.CREATE_MAGIC_LINK,
    );
  }

  async createMagicLink({ email, firstName, lastName }) {
    const path = MAGIC_LINK_ENDPOINTS.CREATE_MAGIC_LINK;
    const url = constructRequestURLV2(path);
    const param = {
      email: lowerCaseAndTrimString(email),
      first_name: firstName?.trim(),
      last_name: lastName?.trim(),
    };
    const payloadData = {
      body: JSON.stringify(param),
    };

    try {
      const { currentUser } = this.props;

      const response = await Fetcher.post(
        url,
        payloadData,
        true,
        getUserEmail(currentUser),
      );

      /* Turn off spinner in component when response is received */
      magicLinkBroadcast.publish(
        MAGIC_LINK_BROADCAST_VALUES.TOGGLE_CREATE_MAGIC_LINK_SPINNER,
        false,
      );

      if (
        !this._isMounted ||
        isEmptyObjectOrFalsey(response)
      ) {
        return;
      }

      const { error, magic_link } = response;

      if (error || isEmptyObjectOrFalsey(magic_link)) {
        broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          error || "Failed to create magic link.",
        );
        return;
      }

      magicLinkBroadcast.publish(
        MAGIC_LINK_BROADCAST_VALUES.SET_MAGIC_LINK_AFTER_CREATE,
        magic_link,
      );
    } catch (error) {
      handleError(error);
    }
  }

  render() {
    return null;
  }
}

const withStore = (BaseComponent) => (props) => {
  const currentUser = useSelector((state) => state.currentUser);

  return <BaseComponent {...props} currentUser={currentUser} />;
};

export default withStore(MagicLinksContainer);
