import { constructRequestURL } from "../../services/api";
import Fetcher from "../../services/fetcher";
import {
  getMondayNumberOfTheYear,
  getMondayOfDate,
  getMondayOfLastCompleteWeek,
} from "../../lib/dateFunctions";
import { isUserDelegatedUser } from "../../services/maestroFunctions";
import { SPECIAL_TAGS_TYPE } from "../../lib/vimcalVariables";
import { trackEvent } from "../tracking";
import {
  format,
  getMonth,
  getYear,
  lastDayOfYear,
  startOfMonth,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../../services/typeGuards";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { isEmptyArray, reverseArray } from "../../lib/arrayFunctions";
import {
  getExternalTagFromUser,
  getInternalTagFromUser,
  getSoloTagFromUser,
  getTagColorFilteringForTransparent,
} from "../../lib/tagsFunctions";
import { DEFAULT_PRIMARY_CALENDAR_COLOR } from "../../services/globalVariables";
import { getFullDayName } from "../../lib/stringFunctions";
import { METRICS_ENDPOINTS } from "../../lib/endpoints";

export const METRICS_PERIOD = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  QUARTERLY: "quarterly",
};

const additionalBreakDowns = [
  {
    category_name: "design_long_name_lalalalal",
    time: 250,
    color: "#9e69af",
  },
  {
    category_name: "product_1",
    time: 800,
    color: "#f4511e",
  },
  {
    category_name: "sales_1",
    time: 15,
    color: "#ef6c00",
  },
  {
    category_name: "HR_1",
    time: 12,
    color: "#009688",
  },
  {
    category_name: "personal_1",
    time: 8,
    color: "#3f51b5",
  },

  {
    category_name: "design_2",
    time: 14,
    color: "#9e69af",
  },
  {
    category_name: "product_2",
    time: 20,
    color: "#f4511e",
  },
  {
    category_name: "sales_2",
    time: 15,
    color: "#ef6c00",
  },
  {
    category_name: "HR_2",
    time: 12,
    color: "#009688",
  },
  {
    category_name: "personal_2",
    time: 8,
    color: "#3f51b5",
  },

  {
    category_name: "design_3",
    time: 14,
    color: "#9e69af",
  },
  {
    category_name: "product_3",
    time: 20,
    color: "#f4511e",
  },
  {
    category_name: "sales_3",
    time: 15,
    color: "#ef6c00",
  },
  {
    category_name: "HR_3",
    time: 12,
    color: "#009688",
  },
  {
    category_name: "personal_3",
    time: 8,
    color: "#3f51b5",
  },
];

const METRICS_PERIODS = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
  WEEKLY: "weekly",
};

export const METRICS_VIEW = {
  HOUR: "hr",
  PERCENTAGE: "%",
};

export const METRICS_VIEW_OPTIONS = Object.values(METRICS_VIEW);

export const EXTERNAL_INTERNAL_CONSTS = {
  INTERNAL: {
    backgroundColor: "#DE3573",
  },
  EXTERNAL: {
    backgroundColor: "#14FFCD",
  },
};

export const METRICS_SAMPLE_DATA = {
  period: METRICS_PERIODS.WEEKLY,
  from: "2023-01-01",
  to: "2023-10-08",
  calendar: [
    { calendar_name: "mike@vimcal.com", hex: "#1974E8" },
    { calendar_name: "mike1@weveapp.com", hex: "#1974E8" },
    { calendar_name: "mike2@weveapp.com", hex: "#1974E8" },
    { calendar_name: "mike3@weveapp.com", hex: "#1974E8" },
    { calendar_name: "mike4@weveapp.com", hex: "#1974E8" },
  ],
  meetings_duration: 72, // int in mins
  all_events_minutes: 1000, // all minutes (even events without attendees)
  number_of_meetings: 5,
  busiest_day: "Tuesday",
  external_meetings_time: 270,
  internal_meetings_time: 90,
  swiss_cheese_time: 32,
  daily_average: 32.5,
  category_breakdown: [
    {
      category_name: "design",
      time: 14, // percentage should be number min of events with tags/number of mins of all events
      color: "#9e69af",
    },
    {
      category_name: "product",
      time: 20,
      color: "#f4511e",
    },
    {
      category_name: "sales",
      time: 15,
      color: "#ef6c00",
    },
    {
      category_name: "HR",
      time: 12,
      color: "#009688",
    },
    {
      category_name: "personal",
      time: 8,
      color: "#3f51b5",
    },

    ...additionalBreakDowns,
  ],
};

export function getMetricsCalendarName(calendar) {
  return calendar?.calendar_name ?? "";
}

export function getMetricsDailyAverage({ data }) {
  return getDailyAverage({ data });
}

function getDailyAverage({ data, getInMinutes }) {
  if (!isNullOrUndefined(data?.daily_average)) {
    if (getInMinutes) {
      return data.daily_average ?? 0;
    }
    return convertMinutesToHoursWithOneDecimal(data.daily_average ?? 0);
  }
  return getMeetingDuration({ data }) / 7;
}

export function getMetricsDailyAverageChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getDailyAverage({ data, getInMinutes: true }),
    previous: getDailyAverage({ data: previousData, getInMinutes: true }),
  });
}

export function getMetricsDailyAverageIsUp({ data, previousData }) {
  return isMetricUp({
    current: getDailyAverage({ data }),
    previous: getDailyAverage({ data: previousData }),
  });
}

function getMeetingDuration({ data }) {
  return data?.meetings_duration;
}

export function getMetricsMeetingHours({ data }) {
  return convertMinutesToHoursWithOneDecimal(getMeetingDuration({ data }));
}

export function getMetricsInHumanReadableHoursAndMinutes({ data }) {
  return getHumanReadableTimeInMinutes(getMeetingDuration({ data }));
}

export function getMetricsMeetingHoursChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getMeetingDuration({ data }),
    previous: getMeetingDuration({ data: previousData }),
  });
}

export function getMetricsMeetingHoursIsUp({ data, previousData }) {
  return isMetricUp({
    current: getMeetingDuration({ data }),
    previous: getMeetingDuration({ data: previousData }),
  });
}

export function getMetricsMeetings({ data }) {
  return data?.number_of_meetings;
}

export function getMetricsMeetingsChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getMetricsMeetings({ data }),
    previous: getMetricsMeetings({ data: previousData }),
  });
}

export function getMetricsMeetingsIsUp({ data, previousData }) {
  return isMetricUp({
    current: getMetricsMeetings({ data }),
    previous: getMetricsMeetings({ data: previousData }),
  });
}

export function getMetricsBusiestDay({ data, spellOutEntireDay = false }) {
  const busiestDay = data?.busiest_day ?? "";
  if (spellOutEntireDay) {
    return getFullDayName(busiestDay);
  }
  return busiestDay;
}

export function getMetricsMeetingTypeTotal({ data }) {
  return (
    getMetricsMeetingTypeInternal({ data }) +
    getMetricsMeetingTypeExternal({ data })
  );
}

export function getMetricsInternalPercentage({ data }) {
  return getPercentageWithGuard({
    numerator: getMetricsMeetingTypeInternal({ data }),
    denominator: getMetricsMeetingTypeTotal({ data }),
  });
}

export function getMetricsExternalPercentage({ data }) {
  return getPercentageWithGuard({
    numerator: getMetricsMeetingTypeExternal({ data }),
    denominator: getMetricsMeetingTypeTotal({ data }),
  });
}

export function isSpecialTag(name) {
  return Object.values(SPECIAL_TAGS_TYPE).includes(name);
}

export function getMetricsMeetingTypeExternal({ data }) {
  if (
    data?.category_breakdown?.find(
      (category) =>
        getBreakdownCategoryName(category) === SPECIAL_TAGS_TYPE.EXTERNAL
    )
  ) {
    return (
      data?.category_breakdown?.find(
        (category) =>
          getBreakdownCategoryName(category) === SPECIAL_TAGS_TYPE.EXTERNAL
      )?.time || 0
    );
  }
  return data?.external_meetings_time || 0;
}

export function getMetricsMeetingTypeInternal({ data }) {
  if (
    data?.category_breakdown?.find(
      (category) =>
        getBreakdownCategoryName(category) === SPECIAL_TAGS_TYPE.INTERNAL
    )
  ) {
    return (
      data?.category_breakdown?.find(
        (category) =>
          getBreakdownCategoryName(category) === SPECIAL_TAGS_TYPE.INTERNAL
      )?.time || 0
    );
  }
  return data?.internal_meetings_time || 0;
}

export function isInternalTag(name) {
  return name === SPECIAL_TAGS_TYPE.INTERNAL;
}

export function isExternalTag(name) {
  return name === SPECIAL_TAGS_TYPE.EXTERNAL;
}

export function isSoloTag(name) {
  return name === SPECIAL_TAGS_TYPE.SOLO;
}

export function getMetricsMeetingTypeExternalChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getMetricsMeetingTypeExternal({ data }),
    previous: getMetricsMeetingTypeExternal({ data: previousData }),
  });
}

export function getMetricsMeetingTypeInternalChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getMetricsMeetingTypeInternal({ data }),
    previous: getMetricsMeetingTypeInternal({ data: previousData }),
  });
}

export function getMetricsMeetingTypeExternalIsUp({ data, previousData }) {
  return isMetricUp({
    current: getMetricsMeetingTypeExternal({ data }),
    previous: getMetricsMeetingTypeExternal({ data: previousData }),
  });
}

export function getMetricsMeetingTypeInternalIsUp({ data, previousData }) {
  return isMetricUp({
    current: getMetricsMeetingTypeInternal({ data }),
    previous: getMetricsMeetingTypeInternal({ data: previousData }),
  });
}

export function getMetricsSwissCheese({ data }) {
  return data?.swiss_cheese_time;
}

export function getMetricsSwissCheeseChange({ data, previousData }) {
  return getAbsoluteChange({
    current: getMetricsSwissCheese({ data }),
    previous: getMetricsSwissCheese({ data: previousData }),
  });
}

export function getMetricsSwissCheeseIsUp({ data, previousData }) {
  return isMetricUp({
    current: getMetricsSwissCheese({ data }),
    previous: getMetricsSwissCheese({ data: previousData }),
  });
}

export function getMetricsBreakdownTotal({ data }) {
  const breakdowns = getMetricsMeetingsBreakdown({ data });
  if (isEmptyArray(breakdowns)) {
    return 0;
  }

  return breakdowns.reduce((accumulator, category) => {
    return accumulator + category.time;
  }, 0);
}

export function getMetricsCategoryPercentage({ data, breakDown }) {
  return getPercentageWithGuard({
    numerator: breakDown.time,
    denominator: getAllEventsInMinutes({ data }),
  });
}

export const SORT_BY_TYPE = {
  VALUE_ASCENDING: "value-ascending",
  VALUE_DESCENDING: "value-descending",
};
export function getMetricsMeetingsBreakdown({
  data,
  sortByType = SORT_BY_TYPE.VALUE_DESCENDING,
}) {
  if (!sortByType) {
    return data?.category_breakdown;
  }

  return data?.category_breakdown?.sort((a, b) => b.time - a.time);
}

// given minutes, return something like 3 hours and 34 minutes
export function getHumanReadableTimeInMinutes(minutes) {
  if (!minutes) {
    return "0 mins";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  const hourText = hours === 1 ? "hr" : "hrs";
  const minuteText = remainingMinutes === 1 ? "min" : "mins";

  if (hours === 0) {
    return `${remainingMinutes} ${minuteText}`;
  }
  if (remainingMinutes === 0) {
    return `${hours} ${hourText}`;
  }

  return `${hours} ${hourText} ${remainingMinutes} ${minuteText}`;
}

export function convertMinutesToHoursWithOneDecimal(minutes) {
  if (!minutes) {
    return 0;
  }
  const hours = minutes / 60;
  if (hours % 1 !== 0) {
    return hours.toFixed(1);
  }

  return hours.toFixed(0);
}

// defaultValue is used for when there's no denominator
export function getPercentageWithGuard({
  numerator,
  denominator,
  defaultValueForNoDenominator,
}) {
  if (!denominator) {
    if (!defaultValueForNoDenominator) {
      return defaultValueForNoDenominator;
    }
    return 0;
  }

  const percentage = (numerator / denominator) * 100;
  if (percentage % 1 !== 0) {
    return percentage.toFixed(1);
  }

  return percentage.toFixed(0);
}

function getAbsoluteChange({ current, previous }) {
  if (!previous && !current) {
    return 0;
  }
  if (!previous) {
    return current;
  }
  if (!current) {
    return -1 * previous;
  }
  return current - previous;
}

function isMetricUp({ current, previous }) {
  return current - previous > 0;
}

export function getAllEventsInMinutes({ data }) {
  return data?.all_events_minutes;
}

export function getCurrentMondayOfTheYear() {
  const today = new Date();
  return getMondayNumberOfTheYear(today);
}

export function getTwoWeeksAgoMonday() {
  const currentPeriod = getCurrentMondayOfTheYear();
  const currentMonday = getMondayOfDate(new Date());

  if (currentPeriod <= 2) {
    // can't do 52 here because some years, the last week is 53
    const lastYear = subYears(currentMonday, 1);
    const secondToLastWeek = subWeeks(lastDayOfYear(lastYear), 1);
    return {
      weekNumber: getMondayNumberOfTheYear(secondToLastWeek),
      year: lastYear.getFullYear(),
    };
  }
  return { weekNumber: currentPeriod - 2, year: currentMonday.getFullYear() };
}

export function getMonthNumberAndYear(jsDate) {
  const month = getMonth(jsDate) + 1; // Convert to 1-based month index (1 = January, 12 = December)
  const year = getYear(jsDate);

  if (month === 1) {
    // January
    const lastYear = year - 1;
    return {
      monthNumber: 12, // December
      year: lastYear,
    };
  }

  return {
    monthNumber: month - 1, // Previous month (1-based index, so it decrements by 1)
    year,
  };
}

export function getLastMonthNumber() {
  const currentDate = new Date();
  return getMonthNumberAndYear(currentDate);
}

export function getWeekNumberAndYear(jsDate) {
  const weekNumber = getMondayNumberOfTheYear(jsDate);
  const currentMonday = getMondayOfDate(jsDate);

  return {
    weekNumber,
    year: currentMonday.getFullYear(),
  };
}

export function getLastPeriodIndexAndYearForWeek() {
  return getWeekNumberAndYear(getMondayOfLastCompleteWeek());
}

export function getMetricsResponse({
  currentUser,
  weekNumber,
  monthNumber,
  year,
  period,
}) {
  const path = "users/user-metrics";
  const url = constructRequestURL(path, true);
  const param = {};

  if (period) {
    param["period"] = period;
    if (period === METRICS_PERIOD.MONTHLY) {
      param["period_index"] = monthNumber; // nth month of the
    } else if (period === METRICS_PERIOD.WEEKLY) {
      param["period_index"] = weekNumber; // nth monday of the year
    }
  } else if (!isNullOrUndefined(weekNumber)) {
    param["period_index"] = weekNumber; // nth monday of the year
  }
  if (isUserDelegatedUser(currentUser)) {
    param["delegated_user_email"] = getUserEmail(currentUser);
  }
  if (!isNullOrUndefined(year)) {
    param["year"] = year;
  }
  const payloadData = {
    body: JSON.stringify(param),
  };
  return Fetcher.post(url, payloadData, true, getUserEmail(currentUser));
}

export function isMetricsReady(response) {
  return !!getMetricsData(response);
}

export function isMetricsReportStillCalculating(response) {
  return response?.error === "report not ready";
}

export function updateMetricsCalendars({ userCalendarIDs, user }) {
  if (isEmptyObjectOrFalsey(user)) {
    return;
  }
  const path = "calendars/user-metrics/enable";
  const url = constructRequestURL(path, true);
  const param = {
    enabled_calendar_ids: userCalendarIDs,
  };
  const payloadData = {
    body: JSON.stringify(param),
  };
  return Fetcher.patch(url, payloadData, true, getUserEmail(user));
}

export function getMetricsData(response) {
  return response?.user_metrics_report?.data;
}

export function getMetricsDataID(metricsData) {
  return metricsData?.user_metrics_report?.id;
}

export function getMetricsBreakdownID(breakdown) {
  return breakdown?.id;
}

export function trackMetricsOpen({ where, user }) {
  trackEvent({
    category: "metrics",
    action: "open_tracking_from",
    label: where,
    userToken: getUserToken(user),
  });
}

export function getMetricsWeekNumber(response) {
  return response?.user_metrics_report?.period_number_of_year;
}

export function getMetricsPeriod({ data }) {
  return data?.user_metrics_report?.period;
}

export function getMetricsStartDate({ response }) {
  return response?.user_metrics_report?.from;
}

export function getMetricsEndDate({ data }) {
  return data?.user_metrics_report?.to;
}

export function getMetricsCalendars({ data }) {
  return data?.user_metrics_report?.calendar;
}

// this is not the actual color of the tag but for when we want to render it in metrics, etc
export function getSpecialTagDisplayColorForMetrics({
  breakDown,
  currentUser,
}) {
  const { category_name, color } = breakDown;
  if (isInternalTag(category_name)) {
    return (
      getTagColorFilteringForTransparent(
        getInternalTagFromUser({ user: currentUser })
      ) ?? EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor
    );
  }
  if (isExternalTag(category_name)) {
    return (
      getTagColorFilteringForTransparent(
        getExternalTagFromUser({ user: currentUser })
      ) ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor
    );
  }
  if (isSoloTag(category_name)) {
    return (
      getTagColorFilteringForTransparent(
        getSoloTagFromUser({ user: currentUser })
      ) ?? DEFAULT_PRIMARY_CALENDAR_COLOR
    );
  }
  if (!color || color === "transparent") {
    return DEFAULT_PRIMARY_CALENDAR_COLOR;
  }
  return color;
}

export function hasExplicitInternalTag(breakdowns) {
  return breakdowns.find((breakdown) =>
    isInternalTag(getBreakdownCategoryName(breakdown))
  );
}

export function hasExplicitExternalTag(breakdowns) {
  return breakdowns.find((breakdown) =>
    isExternalTag(getBreakdownCategoryName(breakdown))
  );
}

export function getPastWeeksInternalMetrics({ pastWeeksBreakdowns }) {
  // find the id of the first instance of internal metrics in breakdown and grab the id
  const matchingArr = pastWeeksBreakdowns.find((subArray) =>
    subArray.find((breakdown) =>
      isInternalTag(getBreakdownCategoryName(breakdown))
    )
  );
  return matchingArr?.find((match) =>
    isInternalTag(getBreakdownCategoryName(match))
  );
}

export function getPastWeeksExternalMetrics({ pastWeeksBreakdowns }) {
  // find the id of the first instance of external metrics in breakdown and grab the id
  const matchingArr = pastWeeksBreakdowns?.find((subArray) =>
    subArray.find((breakdown) =>
      isExternalTag(getBreakdownCategoryName(breakdown))
    )
  );
  return matchingArr?.find((match) =>
    isExternalTag(getBreakdownCategoryName(match))
  );
}

function getBreakdownCategoryName(breakdown) {
  return breakdown?.category_name;
}

const PROFILE_PICTURE =
  "https://vimcal-profile-photos.s3.amazonaws.com/Michael-Zhao-1707874429377.png";
const MOCK_DOMAIN_SUMMARY_0 = [
  {
    domain: "vimcal.com",
    total_people_met: 107,
    sample_people: [
      {
        email: "kenny@vimcal.com",
        name: "Kenny Fitzgerald",
        profile_photo_url: PROFILE_PICTURE,
      },
      {
        email: "bruno@vimcal.com",
        name: "Bruno Vieira",
        profile_photo_url: PROFILE_PICTURE,
      },
    ],
  },
  {
    domain: "gmail.com",
    sample_people: [
      {
        email: "mike@gmail.com",
        name: "Michael Zhao",
        profile_photo_url: PROFILE_PICTURE,
      },
    ],
    total_people_met: 1,
  },
  {
    domain: "weveapp.com",
    total_people_met: 200,
    sample_people: [
      {
        email: "kenny@weveapp.com",
        name: "Kenny Fitzgerald",
        profile_photo_url: PROFILE_PICTURE,
      },
    ],
  },
  {
    domain: "test.com",
    sample_people: [
      {
        email: "bruno@test.com",
        name: "Bruno Vieira",
        profile_photo_url: PROFILE_PICTURE,
      },
    ],
    total_people_met: 1,
  },
  {
    domain: "test-with-only-2.com",
    sample_people: [
      {
        email: "mike@test-with-only-2.com",
        name: "Mike Zhao",
      },
      {
        email: "kenny@test-with-only-2.com",
        name: "Kenny Test",
        profile_photo_url: PROFILE_PICTURE,
      },
    ],
    total_people_met: 2,
  },
  // up to 20 domains
];

const MOCK_DOMAIN_1 = [
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 1,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 2,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenneth.d.fitzgerald@gmail.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 3,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 4,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 5,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 6,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 7,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 8,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 9,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 10,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 11,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 12,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 13,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 14,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 15,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 16,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 17,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "Kenny Fitzgerald",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 18,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url: null,
      },
    ],
    total_people_met: 19,
  },
  {
    domain: "vimcal.com",
    sample_people: [
      {
        name: "",
        email: "kenny@vimcal.com",
        profile_photo_url:
          "https://vimcal-profile-photos.s3.amazonaws.com/Kenny-Fitzgerald-1707873033446.jpg",
      },
    ],
    total_people_met: 20,
  },
];

export function getMetricsMetWithData(data) {
  return data?.domain_summary || [];
}

export function getMetricsPeopleMet(data) {
  return data?.people_met || [];
}

export function getMetricsTotalPeopleMet(data) {
  const domainSummary = getMetricsMetWithData(data);
  const totalPeopleMet = domainSummary.reduce(
    (sum, { total_people_met }) => sum + (total_people_met || 0),
    0
  );
  return totalPeopleMet;
}

export function hasEveryWeekForLast12Weeks(last12Weeks) {
  if (isEmptyArrayOrFalsey(last12Weeks)) {
    return false;
  }
  let hasAll12Weeks = true;
  for (let i = 1; i <= 12; i++) {
    const currentDate = getMondayOfDate(subWeeks(new Date(), i));
    const currentDateString = format(currentDate, "yyyy-MM-dd");

    // Check if there is existing data for this date
    const existingEntry = last12Weeks?.find(
      (entry) => getMetricsStartDate({ response: entry }) === currentDateString
    );
    if (!existingEntry) {
      hasAll12Weeks = false;
    }
  }
  return hasAll12Weeks;
}

export function backfillMetricsDataBack12Weeks({ existingData, initialDate }) {
  const backfilledData = [];
  for (let i = 0; i < 12; i++) {
    const currentDate = getMondayOfDate(subWeeks(initialDate ?? new Date(), i));
    const currentDateString = format(currentDate, "yyyy-MM-dd");

    // Check if there is existing data for this date
    const existingEntry = existingData?.find(
      (entry) => entry.date === currentDateString
    );

    if (existingEntry) {
      backfilledData.push(existingEntry);
    } else {
      backfilledData.push({
        date: format(currentDate, "yyyy-MM-dd"),
      });
    }
  }

  return reverseArray(backfilledData); // Reverse to have the oldest date first
}

export async function bulkFetchMetricsReports({
  period,
  currentUser,
  periodNumber,
  year,
  delegatedUserEmail,
}) {
  //   #### Parameters:
  // - `period` (optional): The reporting period. Defaults to `weekly`. Valid values are:
  //   - `weekly`
  //   - `monthly`
  //   - `yearly`
  // - `amount` (optional): The number of reports to retrieve. Defaults to `12`.
  // - `year` (optional): The year for which to retrieve the reports. Defaults to the current year.
  const url = constructRequestURL(METRICS_ENDPOINTS.GET_LAST_12_WEEKS_OF_METRICS, true);
  const param = {
    period,
    period_number: periodNumber, // it has to be called period_number since you can get monthly and even yearly reports in the same endpoint) 
    year,
  };
  if (delegatedUserEmail) {
    param.delegated_user_email = delegatedUserEmail;
  }
  const payloadData = {
    body: JSON.stringify(param),
  };
  return Fetcher.post(url, payloadData, true, getUserEmail(currentUser));
}
