import { format, isSameDay } from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getInputStringFromEvent } from "../lib/stringFunctions";
import SaveAndCancelButton from "./buttons/saveAndCancelButton";
import { isValidJSDate } from "../services/commonUsefulFunctions";
import { formatDate, getDateTimeFormatLowercaseAMPM } from "../lib/dateFunctions";

interface OutlookProposeTimeNoteProps {
  startTime: Date
  endTime: Date
  onClose: () => void
  onSubmit: (message: string) => void
}

export default function OutlookProposeTimeNote({
  startTime,
  endTime,
  onClose,
  onSubmit,
}: OutlookProposeTimeNoteProps) {
  const dateFieldOrder = useSelector((state) => state.dateFieldOrder);
  const format24HourTime = useSelector(state => state.format24HourTime);
  const [message, setMessage] = useState("");

  const displayString = (() => {
    if (!isValidJSDate(startTime) || !isValidJSDate(endTime)) {
      return "";
    }

    const timeFormatString = getDateTimeFormatLowercaseAMPM(format24HourTime);
    const formattedStartDate = formatDate(startTime, dateFieldOrder);
    const formattedStartTime = format(startTime, timeFormatString);
    const formattedEndTime = format(endTime, timeFormatString);

    if (isSameDay(startTime, endTime)) {
      return `${formattedStartDate} from ${formattedStartTime} - ${formattedEndTime}`;
    }

    const formattedEndDate = formatDate(endTime, dateFieldOrder);
    return `${formattedStartDate} ${formattedStartTime} to ${formattedEndDate} ${formattedEndTime}`;
  })();

  return (
    <div className="default-font-size">
      <div className="flex items-center gap-2.5">
        <div>Proposed new time:</div>
        <div className="font-weight-500">{displayString}</div>
      </div>

      <textarea
        className="email-input-container h-32 default-font-size mt-2.5"
        autoFocus={true}
        placeholder={"Enter message"}
        onChange={(e) => setMessage(getInputStringFromEvent(e))}
        tabIndex={1}
      />

      <SaveAndCancelButton
        onClose={onClose}
        onConfirm={() => onSubmit(message)}
        confirmButtonText="Submit"
      />
    </div>
  );
}
