import classNames from "classnames";
import React from "react";

export default function BetaTag({ containerClassName }) {
  return (
    <div className={classNames("ai-beta-tag-wrapper", containerClassName ?? "")}>
      <div className="ai-beta-tag">
        <div className="ai-beta-tag-text">Beta</div>
      </div>
    </div>
  );
}
