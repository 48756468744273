import React, { Component, createRef } from "react";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import { connect, batch } from "react-redux";
import EventFileAttachment from "../components/eventFileAttachment";
import EventAttendingList from "../components/eventAttendingList";
import EventLocation from "../components/eventLocation";
import EventConferencing from "../components/eventConferencing";
import { withRouter } from "react-router-dom";
import EventReminder from "../components/eventReminder";
import {
  EVENT_FORM_SUMMARY,
  EVENT_FORM_START_DATE_INPUT,
  EVENT_FORM_START_TIME_INPUT,
  EVENT_FORM_CONFERENCE,
  EVENT_FORM_ROOM,
  EVENT_FORM_ATTENDEE,
  EVENT_FORM_NOTIFICATION,
  EVENT_FORM_BUSY_FREE,
  EVENT_FORM_DEFAULT_AVAILABILITY,
  EVENT_FORM_DESCRIPTION,
  eventFormSectionHotKeysIndex,
  CAN_NOT_UPDATE_EVENT_MESSAGE,
  DEFAULT_PRIMARY_CALENDAR_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  EVENT_CONTAINER_WIDTH,
  GENERIC_ERROR_MESSAGE,
  ACTION_MODE,
} from "../services/globalVariables";
import SelectEventDetailToCopyToEventTemplate from "../components/selectEventDetailToCopyToEventTemplate";
import EventResponse from "../components/eventResponse";
import GoogleCalendarService, {
  BUSY_DURING_EVENT,
  GOOGLE_HANGOUT_STRING,
  GOOGLE_UPDATES,
  OUT_OF_OFFICE_AUTO_DECLINE_ALL,
  OUT_OF_OFFICE_AUTO_DECLINE_NEW,
  PHONE_NUMBER_CONFERENCE,
  WHATS_APP_STRING,
} from "../services/googleCalendarService";
import LightModeConferenceRoomDoorSVG from "../components/lightModeConferenceRoomDoorSVG";
import DarkModeConferenceRoomDoorSVG from "../components/darkModeConferenceRoomIcon";
import Broadcast from "../broadcasts/broadcast";
import EventModalPopup from "../components/eventModalPopup";
import {
  generateConferenceRoomsAndStatus,
  generateConferenceRooms,
  filterForLocation,
  isEditable,
  formatForEventTemplates,
  shouldDisplayDeleteEventButton,
  DoesEventLocationOrDescriptionContainZoom,
  canDisplayAttendees,
  hasStateOrPropsChanged,
  calculateMarginTop,
  determineMultipleConferenceWarning,
  hasPermissionToModify,
  getRRuleStringFromRecurrence,
  isBeforeMinute,
  getCurrentTimeInCurrentTimeZone,
  blurInputOnEscape,
  getEmailBasedOnCalendarId,
  parseRRuleStringToHumanReadableText,
  getGoogleEventId,
  constructQueryParams,
  doesEventHaveModifyPermissionAndIsNotAnOrangizer,
  isValidJSDate,
  hasStopEventPropagation,
  hasEventPreventDefault,
  isTemplateZoomConferencing,
  isHangoutGSuiteIntegration,
  convertEmailToName,
  handleError,
  formatEventForReactBigCalendar,
} from "../services/commonUsefulFunctions";
import ExpandedTemplateEventTime from "../components/expandedTemplateEventTime";
import ExpandedEventTime from "../components/expandedEventTime";
import {
  Briefcase,
  Lock,
  Calendar,
  Video,
  Eye,
  UserX,
  ExternalLink,
  ChevronDown,
  RefreshCw,
} from "react-feather";
import { EVENT_TEMPLATE } from "../services/googleCalendarService";
import GlobalKeyMapTile from "../components/globalKeyMapTile";
import CopiableContentContainer from "../components/copiableContentContainer";
import EditableContentContainer from "../components/editableContentContainer";
import _ from "underscore";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import classNames from "classnames";
import ExpandViewToolbar from "../components/expandedView/expandViewToolbar";
import {
  appendInlineAttachments,
  doesEventAskForResponse,
  getEventAttendeeDomains,
  getEventDescriptionUIClassnames,
  getEventVisibilityOptions,
  getHumanAttendees,
  getSanitizedEventDescription,
  isAllDayEvent,
  isShowAsBirthdayEvent,
  isFreeEvent,
  isGoogleEvent,
  isMergedEvent,
  isOutlookEvent,
  isOutOfOfficeEvent,
  isPreviewOutlookEvent,
  isWorkplaceEvent,
  isRecurringEvent,
  isEventHiddenEvent,
  HIDDEN_EVENT_TOGGLE,
  toggleEventHiddenEventProperty,
} from "../lib/eventFunctions";
import ConferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import CustomSelect from "../components/select";
import CircleWithColor from "../components/circleWithColor";
import { determineEventColor } from "../lib/eventFunctions";
import FreeBusyContainer from "../components/freeBusyContainer";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  hasWriteAccessToUserCalendarID,
  determineCalendarColor,
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
  getCalendarFromUserCalendarID,
  getMatchingUserFromEvent,
  getCalendarFromProviderID,
  isGoogle,
  getCalendarUserEmail,
} from "../lib/calendarFunctions";
import {
  addEventUserCalendarID,
  getEventAttachments,
  getEventAttendees,
  getEventConferenceData,
  getEventCreator,
  getEventEnd,
  getEventExtendedProperties,
  getEventExtendedPropertiesPrivate,
  getEventID,
  getEventIsRemindersOn,
  getEventLocation,
  getEventMasterEventID,
  getEventOrganizer,
  getEventReminders,
  getEventStart,
  getEventTransparency,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
  getEventVisibility,
  isEventShowAsFromGmail,
  isOutlookDraftEvent,
} from "../services/eventResourceAccessors";
import {
  getCalendarDefaultReminders,
  getCalendarProviderId,
} from "../services/calendarAccessors";
import {
  getTagsFromTemplate,
  getTemplateEmail,
  getTemplateId,
  getTemplateOutOfOfficeDeclineMode,
  getTemplateProviderId,
  isOutOfOfficeTemplate,
} from "../services/templateFunctions";
import {
  EXPANDED_SCROLL_CONTAINER,
  EXPANDED_VIEW_CONTAINER_ID,
} from "../services/elementIDVariables";
import { useHideRightHandSidebar } from "../services/stores/appFunctionality";
import {
  PREVIEW_EVENT_TYPE,
  copyContent,
  getCurrentPreviewEventType,
  getPopUpEvent,
  getPreviewEvent,
  isAppInTaskMode,
  isGoogleUser,
  isInActionMode,
  isInSearchState,
  shouldTruncateRightHandPanel,
} from "../services/appFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import recurrenceBroadcast from "../broadcasts/recurrenceBroadcast";
import { getMatchingUserFromAllUsers, getSelectedUserName, getUserEmail } from "../lib/userFunctions";
import { setEventFormFocusSection } from "../lib/stateManagementFunctions";
import Tags from "../components/tags";
import { DISPLAY_LOCATION_EXPANDED_EVENT } from "../components/tags/tagsVariables";
import {
  TAGS_LIMIT,
  calculateEventColorId,
  getAllTagsFromEvent,
  getMatchingUIUserForEvent,
  getTagsFromEvent,
  hasReachedTagsLimit,
} from "../lib/tagsFunctions";
import { createUpdatedSingleEventWithColor } from "../lib/mimicEventUpdate";
import EnrichedCompanyDetails from "../components/expandedView/EnrichedCompanyDetails";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import tagsBroadcast from "../broadcasts/tagsBroadcast";
import { determineUniqueToken } from "../services/mergeEventFunctions";
import TagsEmptyIcon from "../components/tags/icons/tagsEmptyIcon";
import { onClickEditOriginalEvent } from "../lib/googleFunctions";
import {
  convertOutlookConferencingToHumanReadable,
  getOutlookProposedEventTimes,
  isOutlookUser,
  isTemplateOutlookConferencing,
  shouldHideEventResponseOptions,
} from "../lib/outlookFunctions";
import { shouldGateExtendedProperties } from "../lib/outlookFunctions";
import { immutablySortArray, isEmptyArray } from "../lib/arrayFunctions";
import { format, isSameDay, parseJSON } from "date-fns";
import { isMeetWithEvent } from "../lib/meetWithFunctions";
import SmartHolds from "../components/expandedView/smartHolds";
import { isEventHoldEvent } from "../services/holdFunctions";
import {
  customMenuStyle,
  getReactSelectBaseStyle,
} from "../components/select/styles";
import {
  getDefaultFontColor,
  getDefaultSecondaryTextColor,
} from "../lib/styleFunctions";
import {
  isTemplateCustomConferencing,
  isTemplatePhoneConferencing,
  isTemplateWhatsAppConferencing,
  isValidCustomConferencing,
} from "../lib/conferencing";
import { getTransparencyOptions } from "../services/sharedEventFormFunctions";
import {
  BACKEND_BROADCAST_VALUES,
  BROADCAST_VALUES,
  EXPANDED_VIEW_BROADCAST_VALUES,
  MAIN_CALENDAR_BROADCAST_VALUES,
  RECURRENCE_BROADCAST_VALUES,
} from "../lib/broadcastValues";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import CategoriesList from "../components/expandedView/categoriesList";
import { getFullEvent } from "../lib/fetchFunctions";
import Spinner from "../components/spinner";
import {
  TEST_PREVIEW_OUTLOOK_LOCATION,
  isTestingPreviewOutlook,
} from "../services/testFunctions";
import {
  capitalizeFirstLetter,
  isSameEmail,
  lowerCaseAndTrimString,
  removePlusAndCapitalize,
} from "../lib/stringFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { getCustomConferencingName } from "../lib/settingsFunctions";
import expandedEventViewBroadcast from "../broadcasts/expandedEventViewBroadcast";
import { useDistroListDictionary } from "../services/stores/eventsData";
import { hasPermissionToViewDistroList } from "../lib/featureFlagFunctions";
import { shouldHideAIFeatures } from "../lib/featureFlagFunctions";
import GroupVoteEditOriginal from "../components/expandedView/groupVoteEditOriginal";
import { determineDefaultModalStyle } from "../lib/modalFunctions";
import { getDistroListsFromEvent } from "../lib/distroListFunctions";
import { getGuestAttendanceCounts } from "../services/attendeeFunctions";

const { attendee_event_declined } = GoogleCalendarService;
const CREATE_TEMPLATE_FROM_EVENT = "create_event_from_template";

class EventExpandedView extends Component {
  constructor(props) {
    super(props);
    this.topRef = createRef();

    const {
      currentEvent,
      shouldShowResponseBar,
      shouldHideEdit,
      eventLocationOrDescriptionContainZoom,
      isPopUpEvent,
      doesEventHaveConference,
      eventLocation,
      conferenceWarning,
      eventConferenceRoom,
      eventAttendee,
      shouldDisplayReminders,
      hasPermissionToModifyEvent,
      repeatText,
      sanitizedDescription,
    } = this.determineEventAndEditability();

    this.state = {
      shouldDisplayModal: false,
      event: currentEvent,
      shouldShowResponseBar: shouldShowResponseBar,
      shouldHideEdit,
      eventLocationOrDescriptionContainZoom,
      modalTitle:
        "Which event details do you want to copy into an event template?",
      modalWidth: 500,
      modalHeight: 600,
      modalContent: CREATE_TEMPLATE_FROM_EVENT,
      doesEventHaveConference,
      eventLocation,
      conferenceWarning,
      eventConferenceRoom,
      eventAttendee,
      isPopUpEvent,
      shouldDisplayReminders,
      hasPermissionToModifyEvent,
      shouldDisplayOrganizerHoverEmail: false,
      shouldDisplayCreatorHoverEmail: false,
      repeatText,
      attendeeDomains: [],
      isAttendeesDropdownOpen: false,
      sanitizedDescription,
      eventAttachments: [],
    };

    this.onClickExitExpandedView = this.onClickExitExpandedView.bind(this);
    this.onClickExitPopup = this.onClickExitPopup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.copyGuestEmails = this.copyGuestEmails.bind(this);
    this.editEventSection = this.editEventSection.bind(this);
    this.determineEventAndEditability =
      this.determineEventAndEditability.bind(this);
    this.updateEventInState = this.updateEventInState.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.doesEventHaveConference = this.doesEventHaveConference.bind(this);
    this.openEventExpandedConferencing =
      this.openEventExpandedConferencing.bind(this);
    this.openEventLocation = this.openEventLocation.bind(this);
    this.onClickEditConferencing = this.onClickEditConferencing.bind(this);
    this.onClickCreateTemplateFromEvent =
      this.onClickCreateTemplateFromEvent.bind(this);
    this.duplicateFromEvent = this.duplicateFromEvent.bind(this);
    this.editEventTemplate = this.editEventTemplate.bind(this);
    this.deleteEventTemplate = this.deleteEventTemplate.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.setTags = this.setTags.bind(this);
    this.setEventAttachments = this.setEventAttachments.bind(this);
    this.setFullOutlookEvent = this.setFullOutlookEvent.bind(this);
    this.toggleHiddenEvent = this.toggleHiddenEvent.bind(this);
    this.onClickX = this.onClickX.bind(this);

    !this.props.shouldIgnoreBroadcast && this.subscribeBroadcasts();
  }

  componentDidMount() {
    this._isMounted = true;
    recurrenceBroadcast.publish(
      RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT,
      this.state.event
    );
    if (this.props.popupEvent?.event) {
      this.updateEventInState();
    } else if (this.props.hoverPopupEvent?.event) {
      this.updateEventInState();
    }

    this.fetchFullOutlookPreviewEvent();
    this.enrichCompanyDomains();
  }

  componentWillUnmount() {
    this._isMounted = false;

    !this.props.shouldIgnoreBroadcast && this.unsubscribeBroadcasts();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      null
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.event && !prevState.event) {
      !this.props.shouldIgnoreBroadcast && this.subscribeBroadcasts();
    } else if (!this.state.event && prevState.event) {
      !this.props.shouldIgnoreBroadcast && this.unsubscribeBroadcasts();
    }

    if (
      this.props.event !== prevProps.event ||
      this.props.currentPreviewedEvent !== prevProps.currentPreviewedEvent ||
      this.props.currentHoverEvent !== prevProps.currentHoverEvent ||
      this.props.popupEvent !== prevProps.popupEvent ||
      this.props.hoverPopupEvent !== prevProps.hoverPopupEvent
    ) {
      this.updateEventInState({ previousEvent: prevState.event });
    }

    if (getEventUserEventID(this.props.currentPreviewedEvent) !== getEventUserEventID(prevProps.currentPreviewedEvent)
      || getEventUserEventID(this.props.popupEvent?.event) !== getEventUserEventID(prevProps.popupEvent?.event)
    ) {
      // only scroll if it's an entirely different event in expanded view
      this.scrollToTopOfPage();
    }

    if (
      this.props.originalRecurrenceEventIndex !==
      prevProps.originalRecurrenceEventIndex
    ) {
      // to change the repeatText
      const event = this.getCurrentEvent();
      const repeatText = this.determineRepeatText(event) || "";
      if (repeatText !== this.state.repeatText) {
        this.setState({
          repeatText,
        });
      }
    }
  }

  render() {
    if (!this.state.event) {
      return null;
    }

    // FOR DEV:
    // console.log("this.state.event", this.state.event);
    // console.log("original_event", getOriginalRecurringEventFromIndex(
    //   this.state.event,
    //   this.props.originalRecurrenceEventIndex
    // ));
    const {
      actionMode,
    } = this.props;

    return (
      <div
        id={this.props.id || EXPANDED_VIEW_CONTAINER_ID}
        className={
          this.state.isPopUpEvent
            ? "expanded-popup-event-wrapper"
            : "expanded-event-wrapper"
        }
      >
        {this.shouldHideToolBar() ? null : (
          <ExpandViewToolbar
            event={this.state.event}
            shouldHideEdit={this.state.shouldHideEdit}
            template={this.props.template}
            shouldDisplayDeleteButton={shouldDisplayDeleteEventButton({
              event: this.state.event,
              actionMode,
              allCalendars: this.props.allCalendars.allCalendars,
              reverseSlotsText: this.props.temporaryStateStore.reverseSlotsText,
            })}
            shouldDisplayMoreOptions={
              !isEmptyObjectOrFalsey(this.state.event) &&
              !isInActionMode(actionMode) &&
              !this.props.isTemplate
            }
            onClickTemplateButton={this.onClickCreateTemplateFromEvent}
            shouldDisplayEmailButton={this.shouldDisplayEmail()}
            editEventTemplate={this.editEventTemplate}
            onClickCloseButton={this.onClickX}
            toolBarMarginTop={this.determineToolbarMarginTop()}
            deleteEventTemplate={this.deleteEventTemplate}
            isAppInTaskMode={this.isAppInTaskMode()}
            isInSearch={isInSearchState()}
            originalRecurringEvent={getOriginalRecurringEventFromIndex(
              this.state.event,
              this.props.originalRecurrenceEventIndex
            )}
          />
        )}
        <div
          id={EXPANDED_SCROLL_CONTAINER}
          className={classNames(
            this.props.ignoreOverflow ? "" : "expanded-event-header-wrapper",
            this.state.isPopUpEvent
              ? "pop-up-expanded-view-content-container"
              : "",
            this.getPopupEventOverflowClassname(),
          )}
        >
          <div ref={this.topRef}></div>
          {this.renderTitle()}

          <div className="px-5 pb-5 pt-1">
            {this.props.template ? (
              <ExpandedTemplateEventTime
                onClickEdit={() =>
                  this.editEventSection(EVENT_FORM_START_DATE_INPUT)
                }
                event={this.state.event}
              />
            ) : (
              <ExpandedEventTime
                onClickEditTime={() =>
                  this.editEventSection(EVENT_FORM_START_TIME_INPUT)
                }
                onClickEditDay={() =>
                  this.editEventSection(EVENT_FORM_START_DATE_INPUT)
                }
                event={this.state.event}
                shouldHideEdit={
                  this.state.shouldHideEdit ||
                  !this.state.hasPermissionToModifyEvent
                }
              />
            )}

            {this.renderRepeatText()}
            {this.renderOutlookProposedTimes()}
            {this.renderEditOriginalEventButton()}
            {
              isEventHoldEvent(this.state.event) && !this.isAppInTaskMode() ?
                (
                  <>
                    <SmartHolds event={this.state.event} />
                    <GroupVoteEditOriginal event={this.state.event} />
                  </>
                ) :
                null
            }
            <CategoriesList event={this.state.event} />
            {this.renderTags()}

            <>{this.renderWhere()}</>

            {this.props.template ? this.renderTemplateConferencing() : null}

            <>{this.renderAttendees()}</>
            <EnrichedCompanyDetails
              attendeeDomains={this.state.attendeeDomains}
              userEventID={getEventUserEventID(this.state.event)}
            />

            <>{this.renderDescription()}</>
            <>{this.renderAvailabilityAndVisibility()}</>

            <>{this.renderReminders()}</>

            <>{this.renderAttachments()}</>

            <>
              {this.renderCalendarAndOrganizer()}
              {this.renderOutOfOfficeIcon()}
            </>

            {this.renderMergedEventCalendarDropdown()}

            <FreeBusyContainer event={this.state.event} />
            {this.renderPreviewLoadingSpinner()}

            <div className="h-16"></div>

            {this.renderModal()}
          </div>
        </div>

        {this.renderEventResponseBar()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderEventResponseBar() {
    if (!this.state.shouldShowResponseBar) {
      return null;
    }
    return (
      <div
        className={classNames(
          this.state.isPopUpEvent ? "absolute bottom-0 border-bottom-corner-radius-6px" : "absolute bottom-0",
          "event-response-expand-event"
        )}
      >
        <EventResponse
          event={this.state.event}
          isPopUpEvent={this.state.isPopUpEvent}
        />
      </div>
    );
  }

  renderOutlookProposedTimes() {
    // Note: can not propose new time for all day events on outlook
    const proposedTimes = getOutlookProposedEventTimes(this.state.event);
    if (isEmptyArray(proposedTimes)) {
      return null;
    }

    const getJSEventStartAndEnd = (proposedNewTime) => {
      const eventStart = parseJSON(proposedNewTime.start.dateTime);
      const eventEnd = parseJSON(proposedNewTime.end.dateTime);
      return { eventStart, eventEnd };
    };

    const getProposedTimeString = ({ eventStart, eventEnd }) => {
      const isStartAm = format(eventStart, "a") === "AM";
      const isEndAm = format(eventEnd, "a") === "AM";

      if (isSameDay(eventStart, eventEnd)) {
        const sameDayFormat =
          eventStart.getMinutes() === 0 && eventEnd.getMinutes() === 0
            ? "MMM d, yyyy ha"
            : "MMM d, yyyy h:mma";

        let formattedStartDate = format(
          eventStart,
          sameDayFormat
        ).toLowerCase();
        const formattedEndTime = format(eventEnd, "h:mma").toLowerCase();

        return `${capitalizeFirstLetter(
          formattedStartDate
        )} - ${formattedEndTime}`;
      } else {
        const startFormat =
          eventStart.getMinutes() === 0 ? "MMM d, haa" : "MMM d, h:mma";
        const endFormat =
          eventEnd.getMinutes() === 0 ? "MMM d, yyyy haa" : "MMM d, yyyy h:mma";

        let formattedStartDate = format(eventStart, startFormat).toLowerCase();
        let formattedEndDate = format(eventEnd, endFormat).toLowerCase();

        // Check if AM/PM indicators are equal for different days
        if (isStartAm === isEndAm) {
          formattedStartDate = format(eventStart, startFormat)
            .toLowerCase()
            .replace(/am|pm/, "");
          formattedEndDate = format(
            eventEnd,
            "h 'pm' MMM d, yyyy"
          ).toLowerCase();
        }

        return `${capitalizeFirstLetter(
          formattedStartDate
        )} - ${capitalizeFirstLetter(formattedEndDate)}`;
      }
    };

    const onClickTime = ({
      eventStart,
      eventEnd,
      proposedStart,
      proposedEnd,
    }) => {
      const updatedEvent = {
        ...this.state.event,
        eventStart,
        eventEnd,
        event_start: proposedStart,
        event_end: proposedEnd,
        defaultStartTime: proposedStart.dateTime ?? proposedStart.date,
        defaultEndTime: proposedEnd.dateTime ?? proposedEnd.date,
        isUpdateProposedTime: true,
        originalProposedEvent: this.state.event, // need to save here otherwise we lose the original event
      };
      batch(() => {
        this.props.setPreviewedEvent(updatedEvent);
        this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      });
    };
    const getAttendeeDisplayAndEmail = (attendee) => {
      return {
        displayName: attendee?.displayName || attendee?.emailAddress?.name || "",
        email: attendee?.email || attendee?.emailAddress?.address || "",
      };
    };
    return (
      <div>
        {proposedTimes.map((attendee, index) => {
          const { eventStart, eventEnd } = getJSEventStartAndEnd(
            attendee.proposedNewTime
          );
          if (!isValidJSDate(eventStart) || !isValidJSDate(eventEnd)) {
            return null;
          }
          const {
            displayName,
            email,
          } = getAttendeeDisplayAndEmail(attendee);
          return (
            <div
              key={`outlook_proposed_time_${email ?? ""}_${index}`}
              className="flex flex-col items-start hoverable-secondary-text-color mt-2 select-none"
              onClick={() => {
                onClickTime({
                  eventStart,
                  eventEnd,
                  proposedStart: attendee.proposedNewTime?.start,
                  proposedEnd: attendee.proposedNewTime?.end,
                });
              }}
            >
              {`${
                displayName ?? email
              } proposed:`}
              <div>{getProposedTimeString({ eventStart, eventEnd })}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderRepeatText() {
    const {
      event,
    } = this.state;
    if (!isRecurringEvent(event)) {
      return null;
    }
    return (
      <div className="mt-2.5 default-font-size secondary-text-color flex gap-1.5 secondary-text-color">
        <RefreshCw size="12" className="mt-0.5 mr-1.5 flex-shrink-0" />
        {this.state.repeatText || "Recurring"}
      </div>
    );
  }

  renderTitle() {
    const { event } = this.state;
    const { allCalendars } = this.props.allCalendars;
    const { currentUser, template } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const determineSideBarColor = () => {
      if (template) {
        // we can keep google_id here because this is for templates
        const providerID = getTemplateEmail(event) ?? getUserEmail(currentUser);

        return determineCalendarColor(
          getCalendarFromProviderID({ providerID, allCalendars, allLoggedInUsers, masterAccount }),
        );
      }
      return determineEventColor({
        event,
        allCalendars,
        user: this.getMatchingUIUserForEvent(),
        currentUser,
        allLoggedInUsers,
        outlookCategories,
        masterAccount,
        where: "eventExpandedView::renderTitle",
      });
    };

    // if it's a templates -> we default to the current user color
    const color = determineSideBarColor();

    const determineOutOfOfficeText = () => {
      if (!isOutOfOfficeEvent(event)) {
        return "";
      }

      if (
        lowerCaseAndTrimString(event?.summaryUpdatedWithVisibility)?.includes(
          "out of office"
        ) ||
        lowerCaseAndTrimString(event?.summaryUpdatedWithVisibility)?.includes(
          "ooo"
        )
      ) {
        return "";
      }

      return " (Out of office)";
    };

    const determineSideTitleStyle = () => {
      return {
        borderLeft: `8px solid ${color}`,
        minHeight: 50,
        justifyContent: "center",
        padding: 10,
        paddingLeft: 10,
        width: EVENT_CONTAINER_WIDTH,
      };
    };

    return (
      <div
        className={
          event.summaryUpdatedWithVisibility
            ? "expanded-event-header-has-title"
            : "expanded-event-header-no-title"
        }
      >
        <div className="expanded-event-line-wrapper">
          <div style={determineSideTitleStyle()}>
            <GlobalKeyMapTile
              style={{ top: "-15px", left: "-16px", fontWeight: 300 }}
              shouldHide={this.state.shouldHideEdit}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_SUMMARY]
              )}
            />

            <EditableContentContainer
              shouldHide={this.state.shouldHideEdit}
              onClickEdit={() => this.editEventSection(EVENT_FORM_SUMMARY)}
            >
              <span className="word-break-break-word">
                {`${
                  event.summaryUpdatedWithVisibility
                }${determineOutOfOfficeText()}`}
              </span>
            </EditableContentContainer>
          </div>
        </div>
      </div>
    );
  }

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={this.closeModal}
        width={this.state.modalWidth}
        maxHeight={this.state.modalHeight}
        title={this.state.modalTitle}
        style={determineDefaultModalStyle(this.props.isDarkMode)}
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  renderPreviewLoadingSpinner() {
    const { event } = this.state;
    if (!isPreviewOutlookEvent(event)) {
      return null;
    }
    return <Spinner useSmallSpinner={true} className={"ml-40"} />;
  }

  renderModalContent() {
    switch (this.state.modalContent) {
      case CREATE_TEMPLATE_FROM_EVENT:
        return (
          <SelectEventDetailToCopyToEventTemplate
            event={this.state.event}
            closeModal={this.closeModal}
          />
        );
      default:
        return <div></div>;
    }
  }

  renderOtherConferenceWarning() {
    if (!this.state.conferenceWarning) {
      return null;
    }

    return (
      <div
        className="conflicting-zoom-warning margin-left-12 font-size-12"
        style={{ width: 280 }}
      >
        <div>*</div>

        <div className="margin-left-5">{this.state.conferenceWarning}</div>
      </div>
    );
  }

  determineToolbarMarginTop() {
    if (this.props.ignoreMarginTop || this.state.isPopUpEvent) {
      return 10;
    } else {
      return calculateMarginTop(this.props.shouldShowTopBar, 10);
    }
  }

  renderWhere() {
    if (!this.shouldDisplayWhereSection(this.state.event)) {
      return null;
    }

    return (
      <div className="event-container-section-container">
        <div className="event-expanded-description-label">Where</div>

        {this.state.eventLocation ? (
          <EventLocation
            location={this.state.eventLocation}
            shouldHideEdit={this.state.shouldHideEdit}
            shouldIgnoreBroadcast={this.props.shouldIgnoreBroadcast}
            hideTile={this.props.hideShortcutsTiles}
          />
        ) : null}

        <div className="margin-top-where">
          {this.state.doesEventHaveConference ? (
            <EventConferencing
              event={this.state.event}
              conferenceUrl={this.state.event.conferenceUrl}
              shouldHideEdit={this.state.shouldHideEdit}
              shouldIgnoreBroadcast={this.props.shouldIgnoreBroadcast}
              hideTile={this.props.hideShortcutsTiles}
            />
          ) : null}
        </div>

        {this.renderOtherConferenceWarning()}

        {/*Render office */}
        {this.doesEventHaveConferenceRooms() ? (
          <div className="event-form-conference-wrapper">
            <EditableContentContainer
              iconStyle={{ marginTop: 5 }}
              style={{ alignItems: "flex-start" }}
              shouldHide={this.state.shouldHideEdit}
              onClickEdit={() => this.editEventSection(EVENT_FORM_CONFERENCE)}
            >
              <GlobalKeyMapTile
                style={{ top: "-12px", left: "-18px", fontWeight: 300 }}
                shouldHide={this.state.shouldHideEdit}
                shortcut={removePlusAndCapitalize(
                  eventFormSectionHotKeysIndex[EVENT_FORM_ROOM]
                )}
              />

              <div className="expanded-view-conference-icon margin-bottom-seven">
                {this.props.isDarkMode ? (
                  <DarkModeConferenceRoomDoorSVG />
                ) : (
                  <LightModeConferenceRoomDoorSVG />
                )}
              </div>

              <div className="event-form-selected-room" style={{ width: 270 }}>
                {this.renderConferenceRooms()}
              </div>
            </EditableContentContainer>
          </div>
        ) : null}
      </div>
    );
  }

  renderTemplateConferencing() {
    const eventConferenceData = getEventConferenceData(this.state.event);
    if (!eventConferenceData) {
      return null;
    }
    const { currentUser } = this.props;

    const getLabel = () => {
      if (eventConferenceData?.conferenceSolution?.name) {
        return eventConferenceData.conferenceSolution?.name;
      }
      if (!eventConferenceData.conferenceType) {
        return null;
      }
      if (isTemplateZoomConferencing(eventConferenceData)) {
        return "Zoom";
      }
      if (
        isTemplateCustomConferencing(eventConferenceData, currentUser) &&
        isValidCustomConferencing(currentUser)
      ) {
        return getCustomConferencingName({ user: currentUser });
      }
      if (isTemplatePhoneConferencing(eventConferenceData)) {
        return PHONE_NUMBER_CONFERENCE;
      }
      if (isTemplateWhatsAppConferencing(eventConferenceData)) {
        return WHATS_APP_STRING;
      }
      if (
        isHangoutGSuiteIntegration(eventConferenceData) &&
        isGoogleUser(currentUser)
      ) {
        return GOOGLE_HANGOUT_STRING;
      }
      if (
        isTemplateOutlookConferencing(eventConferenceData) &&
        isOutlookUser(currentUser)
      ) {
        return convertOutlookConferencingToHumanReadable(
          eventConferenceData.conferenceType
        );
      }
      return "Video conferencing";
    };
    const text = getLabel();

    if (!text) {
      return null;
    }

    return (
      <>
        {!this.shouldDisplayWhereSection(this.state.event) ? (
          <div className="event-expanded-description-label mt-7">Where</div>
        ) : null}

        <div className="display-flex-flex-direction-row align-middle font-weight-300">
          <Video className="non-clickable-icon margin-top-2" size="14" />

          <div className="margin-left-10">{capitalizeFirstLetter(text)}</div>
        </div>
      </>
    );
  }

  renderConferenceRooms() {
    const conferenceRooms = generateConferenceRoomsAndStatus(this.state.event);

    return conferenceRooms.map((room, index) => {
      return (
        <div
          key={`expanded_selected_room_list_${room.name}_${index}`}
          className={
            room.response === attendee_event_declined
              ? "expanded-view-render-selected-conference-room cross-through'"
              : "expanded-view-render-selected-conference-room"
          }
        >
          {room.name}
        </div>
      );
    });
  }

  renderAvailabilityAndVisibility() {
    return (
      <div className="event-container-section-container">
        <EditableContentContainer
          style={{ alignItems: "flex-start" }}
          shouldHide={this.state.shouldHideEdit}
          onClickEdit={() => this.editEventSection(EVENT_FORM_BUSY_FREE)}
        >
          <div className="event-expanded-description-label">
            Availability & Visibility
          </div>
        </EditableContentContainer>

        <div className="flex items-center gap-4">
          {this.renderFreeBusySection()}
          {this.renderVisibilitySection()}
        </div>
      </div>
    );
  }

  shouldShowFreeBusyAndVisibilityAsPlainText() {
    const { event } = this.state;
    return isMeetWithEvent(event) || isOutlookDraftEvent(event) || isShowAsBirthdayEvent(event) || isEventShowAsFromGmail(event);
  }

  renderFreeBusySection() {
    const { event } = this.state;
    const isFree = isFreeEvent(event);
    const onChange = (updatedFreeBusy) => {
      mainCalendarBroadcast.publish("UPDATE_FOR_OWN_VERSION_OF_EVENT", {
        event,
        updatedProperties: this.constructEventDataV2({
          transparency: updatedFreeBusy.value,
        }),
      });
    };
    const { isDarkMode, isTemplate } = this.props;
    const transparency = getEventTransparency(event) ?? BUSY_DURING_EVENT;
    const options = getTransparencyOptions({
      calendar: this.getCalendar(),
      isTemplate,
      originalTransparency: transparency,
    });
    const isDisabled = isEventShowAsFromGmail(event) || isShowAsBirthdayEvent(event) || isTemplate || options.length <= 1 || isInSearchState();

    return (
      <div
        className={classNames(
          "flex items-center",
          isDisabled
            ? ""
            : "dark-mode-hoverable-secondary-text-color-container-override"
        )}
      >
        <GlobalKeyMapTile
          shouldHide={this.state.shouldHideEdit}
          style={{ top: "-25px", left: "-18px", fontWeight: 300 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_BUSY_FREE]
          )}
        />

        <Briefcase size="14" className="mr-2.5 secondary-text-color" />
        {this.shouldShowFreeBusyAndVisibilityAsPlainText() ? (
          <div className="width-90px secondary-text-color">
            {isFree ? "Free" : "Busy"}
          </div>
        ) : (
          <CustomSelect
            ref={(input) => {
              this.freeBusyRef = input;
            }}
            isDisabled={isDisabled}
            openMenuOnFocus={true}
            value={options.find((option) => option.value === transparency)}
            onChange={onChange}
            options={options}
            onKeyDown={(e) => blurInputOnEscape(e, this.freeBusyRef)}
            className={classNames(isDarkMode ? "dark-mode-select" : "")}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              controlWidth: "100px",
              showBorder: false,
              hoveredControllerTextColor: getDefaultFontColor(isDarkMode),
              controllerTextColor: getDefaultSecondaryTextColor(isDarkMode),
              menuListStyle: customMenuStyle({ minWidth: "max-content" }),
            })}
            classNamePrefix="dark-mode"
            isSearchable={false}
          />
        )}
      </div>
    );
  }

  renderVisibilitySection() {
    const { event } = this.state;
    const { isDarkMode, isTemplate } = this.props;
    const isDisabled = isTemplate || isInSearchState();
    const onChange = (updatedVisibility) => {
      mainCalendarBroadcast.publish("UPDATE_FOR_OWN_VERSION_OF_EVENT", {
        event,
        updatedProperties: this.constructEventDataV2({
          visibility: updatedVisibility.value,
        }),
      });
    };

    return (
      <div
        className={classNames(
          "flex items-center",
          isDisabled
            ? ""
            : "dark-mode-hoverable-secondary-text-color-container-override"
        )}
      >
        <GlobalKeyMapTile
          shouldHide={this.state.shouldHideEdit}
          style={{ top: "-25px", left: "-18px", fontWeight: 300 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_DEFAULT_AVAILABILITY]
          )}
        />

        <Lock size="14" className="mr-2.5 secondary-text-color" />
        {this.shouldShowFreeBusyAndVisibilityAsPlainText() ? (
          <div className="width-140px secondary-text-color">
            {capitalizeFirstLetter(
              getEventVisibility(event) ?? "Default visibiilty"
            )}
          </div>
        ) : (
          <CustomSelect
            ref={(input) => {
              this.freeBusyRef = input;
            }}
            isDisabled={isDisabled}
            openMenuOnFocus={true}
            value={{
              label: capitalizeFirstLetter(
                getEventVisibility(event) ?? "Default visibiilty"
              ),
              value: "",
            }}
            onChange={onChange}
            options={getEventVisibilityOptions(event)}
            onKeyDown={(event) => blurInputOnEscape(event, this.freeBusyRef)}
            className={classNames(isDarkMode ? "dark-mode-select" : "")}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              controlWidth: "140px",
              showBorder: false,
              hoveredControllerTextColor: getDefaultFontColor(isDarkMode),
              controllerTextColor: getDefaultSecondaryTextColor(isDarkMode),
            })}
            classNamePrefix="dark-mode"
            isSearchable={false}
          />
        )}
      </div>
    );
  }

  renderAttendees() {
    if (isOutOfOfficeEvent(this.state.event)) {
      return null;
    }

    const attendees = this.state.eventAttendee;
    const shouldShowAttendees = canDisplayAttendees(this.state.event);

    if (isEmptyArray(attendees)) {
      return null;
    }
    const { isAttendeesDropdownOpen } = this.state;

    const renderList = () => {
      if (!shouldShowAttendees) {
        return this.renderCanNotDisplayAttendees();
      }

      if (
        this.shouldShowMinimizeAttendeesOption() &&
        !isAttendeesDropdownOpen
      ) {
        return null;
      }
      return (
        <EventAttendingList
          attendees={this.state.eventAttendee}
          organizer={getEventOrganizer(this.state.event)}
        />
      );
    };

    const { distroListDictionary } = this.props.distroListDictionary;
    const { guestCount, acceptedCount, needsActionResponseString } =
      getGuestAttendanceCounts({
        attendees: getEventAttendees(this.state.event),
        distroListDictionary,
      });

    return (
      <div className="event-container-section-container">
        <div className="flex justify-between items-center width-320px">
          <EditableContentContainer
            shouldHide={this.state.shouldHideEdit || !shouldShowAttendees}
            onClickEdit={() => this.editEventSection(EVENT_FORM_ATTENDEE)}
          >
            <CopiableContentContainer
              shouldHide={!shouldShowAttendees}
              textToCopy={this.createAttendeeString(
                this.state.event,
                attendees
              )}
            >
              <div className="attendee-name-row mb-1 items-center">
                <GlobalKeyMapTile
                  shouldHide={this.state.shouldHideEdit}
                  style={{ top: "-18px", left: "-18px", fontWeight: 300 }}
                  shortcut={removePlusAndCapitalize(
                    eventFormSectionHotKeysIndex[EVENT_FORM_ATTENDEE]
                  )}
                />

                <div className="attendee-event-expanded-description-label">
                  {canDisplayAttendees(this.state.event) ? guestCount : "Attendees"}
                </div>

                {shouldShowAttendees && (
                  <div className="default-font-size secondary-text-color">
                    ({acceptedCount} yes
                    {needsActionResponseString})
                  </div>
                )}
              </div>
            </CopiableContentContainer>
          </EditableContentContainer>
          {this.renderShowDropdownArrow()}
        </div>

        {renderList()}
      </div>
    );
  }

  shouldShowMinimizeAttendeesOption() {
    const { eventAttendee } = this.state;
    const shouldShowAttendees = canDisplayAttendees(this.state.event);
    return shouldShowAttendees && eventAttendee?.length > 10;
  }

  renderShowDropdownArrow() {
    if (!this.shouldShowMinimizeAttendeesOption()) {
      return null;
    }
    const { isAttendeesDropdownOpen } = this.state;
    const onClick = (e) => {
      hasEventPreventDefault(e);
      hasStopEventPropagation(e);
      this.setState({
        isAttendeesDropdownOpen: !isAttendeesDropdownOpen,
      });
    };
    return (
      <ChevronDown
        className={classNames(
          "clickable-icon clickable-icon-with-rotate",
          "transition-transform-200ms",
          isAttendeesDropdownOpen ? "rotate-180-degrees" : ""
        )}
        size={16}
        onClick={onClick}
      />
    );
  }

  // Also a case where if you have more than 40 people -> also do not display attendees
  renderCanNotDisplayAttendees() {
    return (
      <div className="margin-top-five">
        The full list has been hidden at the organizer's request.
      </div>
    );
  }

  getSanitizedDescription(event) {
    return getSanitizedEventDescription(event);
  }

  renderDescription() {
    const { event, eventAttachments, sanitizedDescription } = this.state;

    const HTMLDescription = isOutlookEvent(this.state.event)
      ? appendInlineAttachments({
          attachments: eventAttachments,
          description: sanitizedDescription,
          isDescriptionBlank: this.state.event.isDescriptionBlank,
        })
      : sanitizedDescription;

    if (!HTMLDescription) {
      return null;
    }

    return (
      <div className="event-container-section-container">
        <GlobalKeyMapTile
          shouldHide={this.state.shouldHideEdit}
          style={{ top: "-15px", left: "-18px", fontWeight: 300 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_DESCRIPTION]
          )}
        />

        <div className="event-expanded-description-label">
          <EditableContentContainer
            shouldHide={this.state.shouldHideEdit}
            onClickEdit={() => this.editEventSection(EVENT_FORM_DESCRIPTION)}
          >
            Description
          </EditableContentContainer>
        </div>

        <div
          className={getEventDescriptionUIClassnames({sanitizedDescription, event, userEmail: getEventUserEmail(event)})}>
          <div
            className={
              isOutlookEvent(this.state.event)
                ? ""
                : "display-linebreak display-list-item"
            }
            target="_blank"
            dangerouslySetInnerHTML={{
              __html: HTMLDescription,
            }}
          ></div>
        </div>
      </div>
    );
  }

  shouldDisplayOutlookReminders(inputEvent) {
    const event = inputEvent ?? this.state?.event;
    if (!event) {
      return;
    }

    /* Default to true for Google */
    return isOutlookEvent(event) ? getEventIsRemindersOn(event) : true;
  }

  renderReminders() {
    if (!this.state.shouldDisplayReminders) {
      return null;
    }

    return (
      <div className="event-container-section-container">
        <div className="event-expanded-description-label">
          <GlobalKeyMapTile
            shouldHide={this.state.shouldHideEdit}
            style={{ top: "-15px", left: "-18px", fontWeight: 300 }}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_NOTIFICATION]
            )}
          />

          <EditableContentContainer
            shouldHide={this.state.shouldHideEdit}
            onClickEdit={() => this.editEventSection(EVENT_FORM_NOTIFICATION)}
          >
            Reminders
          </EditableContentContainer>
        </div>

        <div>
          <EventReminder
            reminders={getEventReminders(this.state.event)}
            isAllDayEvent={isAllDayEvent(this.state.event)}
            userCalendarID={getEventUserCalendarID(this.state.event)}
          />
        </div>
      </div>
    );
  }

  renderAttachments() {
    let eventAttachments = getEventAttachments(this.state.event) ?? [];

    /* Attachments specifcally fetched from end point */
    /* TODO: Investigate the size limit of files */
    if (!isEmptyArray(this.state.eventAttachments)) {
      /* Filter out inline attachments and map to { fileUrl, title, isOutlookAttachment: true } */
      eventAttachments = eventAttachments.concat(
        this.state.eventAttachments
          .filter((attachment) => !attachment.isInline)
          .map((attachment) => {
            const { contentBytes, contentType, name } = attachment;

            return {
              fileUrl: `data:${contentType};base64,${contentBytes}`,
              isOutlookAttachment: true,
              title: name,
            };
          })
      );
    }

    if (isEmptyArray(eventAttachments)) {
      return null;
    }

    return (
      <div className="event-container-section-container">
        <div className="event-expanded-description-label">Attachments</div>

        <section className="expand-event-file-attachment">
          <EventFileAttachment
            extendAttendanceList={true}
            attachments={eventAttachments}
          />
        </section>
      </div>
    );
  }

  getCalendar() {
    const { currentUser, isTemplate } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const { event } = this.state;
    if (isTemplate) {
      const providerID = getTemplateProviderId(event);
      return getCalendarFromProviderID({
        allCalendars,
        providerID,
        selectedUser: currentUser,
        allLoggedInUsers,
        masterAccount,
      });
    }

    const userCalendarID = getEventUserCalendarID(event);

    return getCalendarFromUserCalendarID({
      userCalendarID,
      allCalendars,
    });
  }

  isGoogleTemplate() {
    const { isTemplate } = this.props;
    if (!isTemplate) {
      return false;
    }

    const calendar = this.getCalendar();
    return isGoogle(calendar);
  }

  renderOutOfOfficeIcon() {
    const { isTemplate } = this.props;
    const { event } = this.state;

    const isGoogleOutOfOfficeEvent = isTemplate
      ? isOutOfOfficeTemplate(event) && this.isGoogleTemplate()
      : isOutOfOfficeEvent(event) && isGoogleEvent(event);

    if (!isGoogleOutOfOfficeEvent) {
      return null;
    }

    let message = "Meetings will not be declined";
    const declineMode = isTemplate
      ? getTemplateOutOfOfficeDeclineMode(event)
      : event.ooo_decline_mode;
    if (declineMode === OUT_OF_OFFICE_AUTO_DECLINE_ALL) {
      message = "New and existing meetings will be declined";
    } else if (declineMode === OUT_OF_OFFICE_AUTO_DECLINE_NEW) {
      message = "Only new meetings will be declined";
    }

    return (
      <div className="flex flex-row default-font-size mt-5 items-center">
        <UserX size="14" className="ml-0.5" />
        <div className="ml-2 default-font-size">{message}</div>
      </div>
    );
  }

  // this section is only valid on google since outlook does not have the creator field
  renderCalendarAndOrganizer() {
    const { event } = this.state;
    if (!this.state.event) {
      return null;
    }

    const creator = getEventCreator(event);
    const organizer = getEventOrganizer(event);

    if (isOutOfOfficeEvent(event)) {
      if (!getObjectEmail(creator)) {
        return null;
      }
      return (
        <div className="display-flex-flex-direction-row align-items-center mt-5">
          <Calendar
            size="14"
            className="margin-right-10 secondary-text-color flex-shrink-0"
          />
          <div className="default-font-size organizer-creator-hover-section secondary-text-color truncate max-width-200">
            {this.convertEmailToName({ email: getObjectEmail(creator) })}
          </div>
        </div>
      );
    }

    let organizerDisplayString =
      this.determineDisplayStringForCreatorOrganizer(organizer);
    let creatorDisplayString =
      this.determineDisplayStringForCreatorOrganizer(creator);

    if (organizerDisplayString === creatorDisplayString) {
      // if the same name -> use email instead
      if (!isSameEmail(getObjectEmail(creator), getObjectEmail(organizer))) {
        organizerDisplayString = getObjectEmail(organizer);
      }

      creatorDisplayString = getObjectEmail(creator);
    }

    if (
      !organizerDisplayString &&
      !getObjectEmail(organizer) &&
      !creatorDisplayString &&
      !getObjectEmail(creator)
    ) {
      // if the values below are all "" or null, no reason to display parent wrapper either
      if (!event.resourceId) {
        return null;
      }
      return (
        <div className="display-flex-flex-direction-row align-items-center mt-5">
          <Calendar
            size="14"
            className="margin-right-10 secondary-text-color flex-shrink-0"
          />
          <div className="default-font-size organizer-creator-hover-section secondary-text-color truncate max-width-280px">
            {event.resourceId}
          </div>
        </div>
      );
    }

    return (
      <div className="display-flex-flex-direction-row align-items-center mt-5">
        <Calendar
          size="14"
          className="margin-right-10 secondary-text-color flex-shrink-0"
        />

        <div>
          {organizerDisplayString ? (
            <div
              className="default-font-size organizer-creator-hover-section secondary-text-color truncate max-width-280px"
              onMouseEnter={() =>
                this.setState({ shouldDisplayOrganizerHoverEmail: true })
              }
              onMouseLeave={() =>
                this.setState({ shouldDisplayOrganizerHoverEmail: false })
              }
            >
              {capitalizeFirstLetter(organizerDisplayString)}
            </div>
          ) : null}

          {getObjectEmail(organizer) &&
          !isSameEmail(organizerDisplayString, getObjectEmail(organizer)) &&
          this.state.shouldDisplayOrganizerHoverEmail ? (
            <div className="expanded-event-email-suggestion-container">
              <div
                className="expanded-event-email-suggestion truncate max-width-280px"
                style={{ left: 0, justifyContent: "flex-start" }}
              >
                {getObjectEmail(organizer)}
              </div>
            </div>
          ) : null}

          {getObjectEmail(creator) &&
          isSameEmail(
            getObjectEmail(creator),
            getObjectEmail(organizer)
          ) ? null : (
            <>
              {creatorDisplayString ? (
                <div
                  className="font-size-12 organizer-creator-hover-section secondary-text-color truncate max-width-280px"
                  onMouseEnter={() =>
                    this.setState({ shouldDisplayCreatorHoverEmail: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ shouldDisplayCreatorHoverEmail: false })
                  }
                >
                  Created by: {capitalizeFirstLetter(creatorDisplayString)}
                </div>
              ) : null}

              {!isSameEmail(creatorDisplayString, getObjectEmail(creator)) &&
              getObjectEmail(creator) &&
              this.state.shouldDisplayCreatorHoverEmail ? (
                <div
                  className="expanded-event-email-suggestion truncate max-width-200"
                  style={{ marginLeft: 10, justifyContent: "flex-start" }}
                >
                  {getObjectEmail(creator)}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  }

  renderMergedEventCalendarDropdown() {
    if (isInSearchState()) {
      return null;
    }

    if (this.isPopUpEvent() && this.isAppInTaskMode()) {
      // since this is view event only
      return null;
    }

    const { mergedEventCalendarsOptions } = this.state;

    if (isEmptyArray(mergedEventCalendarsOptions)) {
      return null;
    }

    const onChangeOption = (option) => {
      if (
        isEmptyObjectOrFalsey(option) ||
        isEmptyObjectOrFalsey(option.value)
      ) {
        return;
      }
      const updatedEvent = option.value;

      this.selectCalendarEvent && this.selectCalendarEvent.blur();
      this.updateEventInState({ inputEvent: updatedEvent });
      const {
        currentPreviewedEvent,
        hoverPopupEvent,
        currentHoverEvent,
        popupEvent,
      } = this.props;
      const previewEventType = getCurrentPreviewEventType({
        currentPreviewedEvent,
        hoverPopupEvent,
        currentHoverEvent,
      });
      if (previewEventType === PREVIEW_EVENT_TYPE.CURRENT_PREVIEWED_EVENT) {
        this.props.setPreviewedEvent(updatedEvent);
      } else if (
        previewEventType === PREVIEW_EVENT_TYPE.POPUP_EVENT &&
        popupEvent
      ) {
        mainCalendarBroadcast.publish("SET_POPUP_EVENT", updatedEvent);
      }
    };

    return (
      <div
        className={classNames(
          "display-flex-flex-direction-row align-items-center mt-2",
          this.isPopUpEvent() ? "" : "mb-32"
        )}
      >
        <Eye size="14" className="margin-right-10 mb-1" />

        <CustomSelect
          openMenuOnFocus={true}
          ref={(input) => {
            this.selectCalendarEvent = input;
          }}
          value={{
            label: (
              <CircleWithColor
                color={this.determineMergeEventColor(this.state.event)}
                colorName={this.determineMergedEventTitle(this.state.event)}
              />
            ),
            value: "",
          }}
          onChange={onChangeOption}
          options={this.state.mergedEventCalendarsOptions}
          onKeyDown={(event) =>
            blurInputOnEscape(event, this.selectCalendarEvent)
          }
          className={classNames(
            "select-event-form-email",
            this.props.isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          classNamePrefix="dark-mode"
          isSearchable={false}
        />
      </div>
    );
  }

  updateEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    this.updateEventInState({ inputEvent: event });
  }

  isSameEventAsDisplayEvent({ newEvent, displayEvent }) {
    if (
      isEmptyObjectOrFalsey(newEvent) ||
      isEmptyObjectOrFalsey(displayEvent)
    ) {
      return false;
    }

    if (
      getEventUserEventID(newEvent) &&
      getEventUserEventID(newEvent) === getEventUserEventID(displayEvent)
    ) {
      // actually just the same event
      return true;
    }

    return (
      !!determineUniqueToken(newEvent) &&
      getEventUserEventID(newEvent) !== getEventUserEventID(displayEvent) &&
      determineUniqueToken(newEvent) === determineUniqueToken(displayEvent)
    );
  }

  createCalendarListForMergedEvents(event) {
    if (this.props.isTemplate) {
      return null;
    }
    // need to use this.state?. because this.state hasn't been initialized yet in constructor where we use this function first
    if (
      this.isSameEventAsDisplayEvent({
        newEvent: event,
        displayEvent: this.state?.event,
      }) &&
      this.state?.mergedEventCalendarsOptions
    ) {
      // same unique token as the initially selected element
      return this.state?.mergedEventCalendarsOptions;
    }

    if (isEmptyObjectOrFalsey(event) || isEmptyArray(event.mergedEvents)) {
      return null;
    }

    const eventList = event.mergedEvents;
    let options = [];
    let allNames = [];
    let duplicateNames = [];
    eventList.forEach((e) => {
      const { name } = this.getDisplayCalendarFromCalendarId(e);
      if (name && allNames.includes(name)) {
        duplicateNames = duplicateNames.concat(name);
      } else if (name) {
        allNames = allNames.concat(name);
      }
    });

    eventList.forEach((e) => {
      const { name, email } = this.getDisplayCalendarFromCalendarId(e);
      let displayName = duplicateNames.includes(name) ? email : name;
      let title = this.determineMergedEventTitle(e, displayName);
      e.mergedCalendarDisplayTitle = title;
      options = options.concat({
        value: e,
        label_string: title,
        label: (
          <CircleWithColor
            color={this.determineMergeEventColor(e)}
            colorName={title}
          />
        ),
      });
    });

    return immutablySortArray(options, (a, b) =>
      a?.label_string?.localeCompare(b?.label_string)
    );
  }

  determineMergedEventTitle(e, inputName = null) {
    return (
      inputName ||
      e.mergedCalendarDisplayTitle ||
      e.resourceId ||
      e.temporaryCalendarId ||
      ""
    );
  }

  determineMergeEventColor(e) {
    return e.normalColor || e.color || DEFAULT_PRIMARY_CALENDAR_COLOR;
  }

  getDisplayCalendarFromCalendarId(event) {
    const email = getEmailBasedOnCalendarId(
      event,
      this.props.allCalendars.allCalendars
    );

    if (!email) {
      return {
        name: this.props.emailToNameIndex[event.resourceId],
        email: event.resourceId,
      };
    }

    return { name: this.props.emailToNameIndex[email], email };
  }

  renderEditOriginalEventButton() {
    const { event } = this.state;
    const { hideAllEdits } = this.props;
    if (hideAllEdits) {
      return null;
    }

    if (isOutlookEvent(event)) {
      return null;
    }

    const { allCalendars } = this.props.allCalendars;
    const eventCalendarEmail = getEmailBasedOnCalendarId(event, allCalendars);
    if (isWorkplaceEvent(event)) {
      // also show for work place event since we can't edit this yet
    } else if (
      !doesEventHaveModifyPermissionAndIsNotAnOrangizer(
        event,
        eventCalendarEmail
      )
    ) {
      return null;
    }

    const onClick = () => {
      onClickEditOriginalEvent(event);
    };

    return (
      <div
        className="flex items-center justify-center edit-original-event-button mt-5 select-none duration-200"
        onClick={onClick}
      >
        <div className="default-font-size mr-4">Edit original event</div>
        <ExternalLink size={16} />
      </div>
    );
  }

  getMatchingUserFromEvent() {
    const { event } = this.state;
    const { allCalendars } = this.props.allCalendars;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    return getMatchingUserFromEvent({
      event,
      allCalendars,
      allLoggedInUsers,
    });
  }

  getMatchingUIUserForEvent() {
    const { event } = this.state;
    const { allCalendars } = this.props.allCalendars;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;
    return getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  getAllTags() {
    const { isTemplate, currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const { event } = this.state;
    if (isTemplate) {
      return getTagsFromTemplate(event);
    }
    return getAllTagsFromEvent({
      event,
      currentUser,
      user: this.getMatchingUIUserForEvent(),
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    });
  }

  renderTags() {
    const { isTemplate } = this.props;
    if (isTemplate) {
      // show read only tags
    } else if (!this.isEventEditablePrivateProperty()) {
      return null;
    }

    const allTags = this.getAllTags();
    /* Check if calendar is owner role if belonging to Outlook */
    /* We can't write extended properties to shared calendars */
    const { allCalendars } = this.props.allCalendars;
    const eventUserCalendarID = getEventUserCalendarID(this.state.event);
    const calendar = allCalendars[eventUserCalendarID];

    if (
      (isTemplate && isEmptyArray(allTags)) ||
      shouldGateExtendedProperties(calendar)
    ) {
      return null;
    }
    const matchingUser = this.getMatchingUIUserForEvent();

    return (
      <div className="flex items-center mt-5">
        <div className="self-start mt-2.5 mr-2.5">
          <TagsEmptyIcon />
        </div>
        <Tags
          displayLocation={DISPLAY_LOCATION_EXPANDED_EVENT}
          tags={allTags}
          setTags={this.setTags}
          userEmail={getUserEmail(matchingUser)}
          isReadOnly={this.props.template || isInSearchState()}
          matchingUser={matchingUser}
        />
      </div>
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  constructTagsEventDataV2({ id, tags = [] }) {
    const eventData = {
      user_calendar_id: getEventUserCalendarID(this.state.event),
      calendar_event: {
        ...(!isNullOrUndefined(id) && { colorId: id }),
        provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(this.state.event),
          this.props.allCalendars.allCalendars
        ),
        extended_properties: {
          ...getEventExtendedProperties(this.state.event),
          private: {
            ...getEventExtendedPropertiesPrivate(this.state.event),
            tags: JSON.stringify(tags),
          },
        },
      },
      user_event_id: getEventUserEventID(this.state.event),
    };

    return eventData;
  }

  setTags(tags) {
    const existingTags = getTagsFromEvent(this.state.event);
    if (tags?.length > existingTags?.length && hasReachedTagsLimit(tags)) {
      // do not show this if we're removing tags
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `You can only add up to ${TAGS_LIMIT} tags.`
      );
      return;
    }

    if (isEmptyObjectOrFalsey(this.state.event)) {
      this.props.removePopupEvent();
      return;
    }

    const event = this.state.event;
    const { allCalendars } = this.props.allCalendars;

    const id = calculateEventColorId({
      allCalendars,
      event,
      tags,
      previousTags: getTagsFromEvent(event),
    });
    /* We need full event for Outlook */
    const eventData = this.constructTagsEventDataV2({
      id,
      tags,
    });
    const isRecurringEvent = getEventMasterEventID(event);

    if (isRecurringEvent) {
      // recurring event
      tagsBroadcast.publish(
        "DISPLAY_RECURRING_TAGS_MODAL",
        getOriginalRecurringEventFromIndex(
          event,
          this.props.originalRecurrenceEventIndex
        ),
        event,
        eventData
      );

      return;
    }

    /* Fake tag selection update */
    const updatedEvent = {
      ...this.state.event,
      extended_properties: {
        ...getEventExtendedProperties(this.state.event),
        private: {
          ...getEventExtendedPropertiesPrivate(this.state.event),
          tags: JSON.stringify(tags),
        },
      },
    };
    this.setState({ event: updatedEvent });

    const path = "events";
    const params = {
      sendUpdates: GOOGLE_UPDATES.NONE,
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars
      ),
      event_provider_id: getGoogleEventId(event),
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, true) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
      updatedTemporaryEvent: createUpdatedSingleEventWithColor({
        event,
        colorID: id,
        extendedProperties: getEventExtendedProperties(eventData?.calendar_event),
      }),
    });

    Broadcast.publish("SET_LAST_SELECTED_EVENT", event);
  }

  determineDisplayStringForCreatorOrganizer(creatorObject) {
    if (isEmptyObjectOrFalsey(creatorObject)) {
      return "";
    }
    const { currentUser } = this.props;
    if (isSameEmail(getObjectEmail(creatorObject), getUserEmail(currentUser))) {
      return getSelectedUserName({ user: currentUser }).fullName;
    }

    return (
      creatorObject.displayName ||
      creatorObject.display_name ||
      this.convertEmailToName({ email: getObjectEmail(creatorObject) }) ||
      getObjectEmail(creatorObject)
    );
  }

  convertEmailToName({ email }) {
    if (!email) {
      return "";
    }
    const { emailToNameIndex, currentUser } = this.props;
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      distroListDictionary
    } = this.props.distroListDictionary;
    return convertEmailToName({
      email,
      currentUser,
      emailToNameIndex,
      masterAccount,
      allLoggedInUsers,
      allCalendars,
      checkForPrimaryOnly: false,
      checkForMatchingCalendarOnProviderID: true,
      distroListDictionary,
    });
  }

  subscribeBroadcasts() {
    expandedEventViewBroadcast.subscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.TURN_EVENT_INTO_TEMPLATE,
      this.onClickCreateTemplateFromEvent
    );
    expandedEventViewBroadcast.subscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.TOGGLE_HIDE_EVENT,
      this.toggleHiddenEvent
    );
    Broadcast.subscribe("COPY_GUEST_EMAILS", this.copyGuestEmails);
    Broadcast.subscribe(
      "GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED",
      this.editEventSection
    );
    expandedEventViewBroadcast.subscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.EDIT_EXPANDED_EVENT,
      this.editEvent
    );
    Broadcast.subscribe(
      "OPEN_EVENT_EXPANDED_VIDEO",
      this.openEventExpandedConferencing
    );
    Broadcast.subscribe("OPEN_EVENT_EXPANDED_LOCATION", this.openEventLocation);
    expandedEventViewBroadcast.subscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT,
      this.duplicateFromEvent
    );
    Broadcast.subscribe("UPDATE_PREVIEW_EVENT", this.updateEvent);
    Broadcast.subscribe("UPDATE_EVENT_ATTACHMENTS", this.setEventAttachments);
    expandedEventViewBroadcast.subscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.SET_FULL_OUTLOOK_EVENT,
      this.setFullOutlookEvent
    );
  }

  unsubscribeBroadcasts() {
    expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.TURN_EVENT_INTO_TEMPLATE);
    expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.TOGGLE_HIDE_EVENT);
    Broadcast.unsubscribe("COPY_GUEST_EMAILS");
    Broadcast.unsubscribe("GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED");
    expandedEventViewBroadcast.unsubscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.EDIT_EXPANDED_EVENT
    );
    Broadcast.unsubscribe("OPEN_EVENT_EXPANDED_VIDEO");
    Broadcast.unsubscribe("OPEN_EVENT_EXPANDED_LOCATION");
    expandedEventViewBroadcast.unsubscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT
    );
    Broadcast.unsubscribe("UPDATE_PREVIEW_EVENT");
    Broadcast.unsubscribe("UPDATE_EVENT_ATTACHMENTS");
    expandedEventViewBroadcast.unsubscribe(
      EXPANDED_VIEW_BROADCAST_VALUES.SET_FULL_OUTLOOK_EVENT
    );
  }

  onClickEditConferencing() {
    this.editEventSection(EVENT_FORM_CONFERENCE);
  }

  areEventsDifferent(event1, event2) {
    if (event1 && !event2) {
      return true;
    } else if (!event1 && event2) {
      return true;
    } else if (
      event1 &&
      event2 &&
      getEventUserEventID(event1) !== getEventUserEventID(event2)
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldRenderReminders(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return false;
    }

    const { allCalendars } = this.props.allCalendars;
    const matchingCalendar = getCalendarFromUserCalendarID({
      userCalendarID: getEventUserCalendarID(event),
      allCalendars,
    });

    const eventReminders = getEventReminders(event);
    if (!eventReminders || !this.shouldDisplayOutlookReminders(event)) {
      return false;
    }

    if (eventReminders) {
      if (eventReminders.useDefault) {
        return getCalendarDefaultReminders(matchingCalendar)?.length > 0;
      } else {
        // using custom reminders
        return eventReminders.overrides?.length > 0;
      }
    }
  }

  determineModifyEventPermission(event, shouldHideEdit) {
    if (!event || shouldHideEdit) {
      return false;
    }

    return hasPermissionToModify(event, this.props.allCalendars.allCalendars);
  }

  async editEvent() {
    const { event, isPopUpEvent, shouldHideEdit } = this.state;
    const { history, setPreviewedEvent } = this.props;

    let eventShouldHideEdit = shouldHideEdit;

    if (isPreviewOutlookEvent(event)) {
      const fullEvent = await this.fetchFullOutlookPreviewEvent();
      if (fullEvent) {
        if (
          getEventID(fullEvent) !== getEventID(this.state.event) ||
          this.isAppInTaskMode() // if already in task mode -> don't do anything
        ) {
          // if change -> early return
          return;
        }
        // is the same event
        eventShouldHideEdit = this.shouldHideEditButton(fullEvent);
      } else {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE
        );
        return; // early exit
      }
    }

    if (eventShouldHideEdit) {
      return Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        CAN_NOT_UPDATE_EVENT_MESSAGE
      );
    }

    batch(() => {
      if (isMergedEvent(event) || isPopUpEvent) {
        setPreviewedEvent(event);
      }

      mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");

      history.push("/home");
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
    });
  }

  duplicateFromEvent(userCalendarId = null) {
    const { allCalendars } = this.props.allCalendars;
    if (
      isEmptyObjectOrFalsey(this.state.event) ||
      isEmptyObjectOrFalsey(allCalendars)
    ) {
      return;
    }

    batch(() => {
      if (userCalendarId) {
        // to copy event to a different calendar
        let updatedPreviewEvent = _.clone(this.state.event);
        updatedPreviewEvent = addEventUserCalendarID(
          updatedPreviewEvent,
          userCalendarId
        );
        updatedPreviewEvent.originalEventUserCalendarID =
          getEventUserCalendarID(this.state.event); // original calendar user calendar id so copy to calendar will work for recurring events
        updatedPreviewEvent.isCopiedFromAnotherCalendar = true;

        this.props.setPreviewedEvent(updatedPreviewEvent);
      } else if (isMergedEvent(this.state.event)) {
        this.props.setPreviewedEvent(this.state.event);
      } else if (this.state.isPopUpEvent) {
        const popUpEvent = getPopUpEvent({
          popupEvent: this.props.popupEvent,
          hoverPopupEvent: this.props.hoverPopupEvent,
        });
        popUpEvent && this.props.setPreviewedEvent(popUpEvent);
      }

      mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");

      this.props.history.push("/home");
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      this.props.setDuplicateEvent(true);
    });
  }

  getCurrentEvent(event = null) {
    if (event) {
      return event;
    } else if (this.props.event) {
      return this.props.event;
    } else {
      const {
        popupEvent,
        hoverPopupEvent,
        currentPreviewedEvent,
        currentHoverEvent,
      } = this.props;
      return getPreviewEvent({
        popupEvent,
        hoverPopupEvent,
        currentPreviewedEvent,
        currentHoverEvent,
      });
    }
  }

  determineEventAndEditability(event = null) {
    // note: there's hierarchy in how we determine event

    const currentEvent = this.getCurrentEvent(event);

    const shouldShowResponseBar = this.shouldShowResponseBar(currentEvent);

    const shouldHideEdit = this.shouldHideEditButton(currentEvent);

    const eventLocationOrDescriptionContainZoom =
      currentEvent && DoesEventLocationOrDescriptionContainZoom(currentEvent);

    const doesEventHaveConference = this.doesEventHaveConference(currentEvent);

    const eventLocation = filterForLocation(currentEvent);

    const conferenceWarning = determineMultipleConferenceWarning(currentEvent);

    const eventConferenceRoom = generateConferenceRooms(currentEvent);

    const eventAttendee = getHumanAttendees(currentEvent);

    const shouldDisplayReminders = this.shouldRenderReminders(currentEvent);

    const hasPermissionToModifyEvent = this.determineModifyEventPermission(
      currentEvent,
      shouldHideEdit
    );
    const sanitizedDescription = this.getSanitizedDescription(currentEvent);

    return {
      currentEvent,
      shouldShowResponseBar,
      shouldHideEdit,
      eventLocationOrDescriptionContainZoom,
      isPopUpEvent: this.isPopUpEvent(),
      doesEventHaveConference,
      conferenceWarning,
      eventConferenceRoom,
      eventLocation,
      eventAttendee,
      shouldDisplayReminders,
      repeatText: this.determineRepeatText(currentEvent) || "",
      hasPermissionToModifyEvent,
      mergedEventCalendarsOptions:
        this.createCalendarListForMergedEvents(currentEvent),
      sanitizedDescription,
    };
  }

  updateEventInState({ inputEvent = null, previousEvent = null } = {}) {
    const {
      currentEvent,
      shouldShowResponseBar,
      shouldHideEdit,
      eventLocationOrDescriptionContainZoom,
      isPopUpEvent,
      doesEventHaveConference,
      eventLocation,
      conferenceWarning,
      eventConferenceRoom,
      eventAttendee,
      shouldDisplayReminders,
      hasPermissionToModifyEvent,
      mergedEventCalendarsOptions,
      sanitizedDescription,
    } = this.determineEventAndEditability(inputEvent);

    /* Check for attachments on the event (only for Outlook) */
    /* Only run if the event id has changed */
    if (
      getEventID(previousEvent) !== getEventID(currentEvent) ||
      (getEventID(previousEvent) === getEventID(currentEvent) &&
        isPreviewOutlookEvent(previousEvent) &&
        !isPreviewOutlookEvent(currentEvent)) // changed from preview to non-preview
    ) {
      /* Prevent attachments showing on wrong event */
      this.setState({ eventAttachments: [] }, () => {
        if (isOutlookEvent(currentEvent)) {
          const { allCalendars } = this.props.allCalendars;
          const eventCalendar = getCalendarFromUserCalendarID({
            allCalendars,
            userCalendarID: getEventUserCalendarID(currentEvent),
          });

          backendBroadcasts.publish(
            BACKEND_BROADCAST_VALUES.FETCH_EVENT_ATTACHMENTS,
            {
              calendarProviderID: getCalendarProviderId(eventCalendar),
              eventProviderID: getEventID(currentEvent),
              userEmail: getCalendarUserEmail(eventCalendar),
            }
          );
        }
      });
    }

    this.setState(
      {
        event: currentEvent,
        shouldShowResponseBar,
        shouldHideEdit,
        repeatText: this.determineRepeatText(currentEvent) || "",
        eventLocationOrDescriptionContainZoom,
        isPopUpEvent,
        doesEventHaveConference,
        eventLocation,
        conferenceWarning,
        eventConferenceRoom,
        eventAttendee,
        shouldDisplayReminders,
        hasPermissionToModifyEvent,
        mergedEventCalendarsOptions,
        sanitizedDescription,
      },
      () => {
        this.fetchFullOutlookPreviewEvent();
        this.enrichCompanyDomains();
        this.getDistroListForEvent(currentEvent);

        if (isMeetWithEvent(currentEvent)) {
          // no need to fetch event details for meet with event
          return;
        }
        recurrenceBroadcast.publish(
          RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT,
          currentEvent
        );
      }
    );
  }

  async getDistroListForEvent(event) {
    try {
      const {
        allLoggedInUsers
      } = this.props.allLoggedInUsers;
      const matchingUser = getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers, 
        userEmail: getEventUserEmail(event)
      });
      if (!hasPermissionToViewDistroList(matchingUser)) {
        return;
      }

      const updatedDictionary = await getDistroListsFromEvent(event);
      if (!this._isMounted) {
        return;
      }
      if (
        getEventUserEventID(this.state.event) !== getEventUserEventID(event)
      ) {
        // event has changed
        return;
      }
      if (isEmptyObjectOrFalsey(updatedDictionary)) {
        return;
      }
      const { setDistroListDictionary, distroListDictionary } =
        this.props.distroListDictionary;
      setDistroListDictionary({
        ...distroListDictionary,
        ...updatedDictionary,
      });
    } catch (error) {
      handleError(error);
    }
  }

  enrichCompanyDomains() {
    if (shouldHideAIFeatures(this.getMatchingUserFromEvent())) {
      return;
    }

    const { event } = this.state;
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const domains = getEventAttendeeDomains({ event, allLoggedInUsers });
    if (isEmptyArrayOrFalsey(domains)) {
      this.setState({ attendeeDomains: [] });
      return;
    }

    this.setState({ attendeeDomains: domains }, () => {
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_DOMAIN_SUMMARIES, domains);
    });
  }

  createAttendeeString(event, filteredAttendees = null) {
    let emailString = "";
    let emailToNameIndex = this.props.emailToNameIndex;
    let string;
    let email;

    let attendees = filteredAttendees || this.state.eventAttendee;

    attendees &&
      attendees.forEach((a, index) => {
        email = a.email;

        if (email !== this.props.currentUser.email) {
          if (emailToNameIndex[email]) {
            string = `${emailToNameIndex[a.email]} <${email}>`;
          } else {
            string = `${email}`;
          }

          emailString = emailString + string;

          if (index + 1 < attendees.length) {
            emailString = emailString + ", ";
          }
        }
      });

    return emailString;
  }

  editEventSection(section) {
    if (!this.state.shouldHideEdit) {
      if (isInSearchState()) {
        // exit search state
        Broadcast.publish("TOGGLE_SEARCH");
        if (this.state.event) {
          this.props.setPreviewedEvent(this.state.event);
        }
      }
      setEventFormFocusSection(section);

      if (this.props.shouldShowGlobalKeyMap) {
        this.props.hideGlobalKeyMap();
      }

      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
    } else {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        CAN_NOT_UPDATE_EVENT_MESSAGE
      );
    }
  }

  isPopUpEvent() {
    return this.props.isPopUpEvent;
  }

  copyGuestEmails() {
    if (!isEmptyObjectOrFalsey(this.state.event)) {
      const emailString = this.createAttendeeString(this.state.event);
      copyContent(emailString);
    }
  }

  onClickCreateTemplateFromEvent() {
    this.setState({
      modalWidth: 500,
      modalHeight: 600,
      modalTitle: "Which event details do you want to copy into an event template?",
      shouldDisplayModal: true,
      modalContent: CREATE_TEMPLATE_FROM_EVENT,
    });
  }

  closeModal() {
    this.setState({ shouldDisplayModal: false });
  }

  editEventTemplate() {
    Broadcast.publish(
      "UPDATE_TEMPLATE",
      EVENT_TEMPLATE,
      formatForEventTemplates(this.props.template, this.props.currentTimeZone)
    );
  }

  deleteEventTemplate() {
    const templateId = getTemplateId(this.props.template);
    const path = `templates/${templateId}`;
    const url = constructRequestURLV2(path);

    Broadcast.publish("DELETE_TEMPLATE", {
      url,
      template: this.props.template,
      templateId,
    });
  }

  shouldShowResponseBar(event) {
    const { hideRightHandSidebar } = this.props;
    const { allCalendars } = this.props.allCalendars;

    const isInTaskMode = this.isAppInTaskMode();
    const eventAsksForResponse = doesEventAskForResponse({
      event,
      allCalendars,
    });

    if (isOutOfOfficeEvent(event) || isInTaskMode || isInSearchState()) { // state doesn't exist yet
      return false;
    }
    if (isEventShowAsFromGmail(event) || isShowAsBirthdayEvent(event)) {
      return false;
    }

    if (
      !eventAsksForResponse ||
      !hasWriteAccessToUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars
      )
    ) {
      return false;
    }

    if (
      !shouldTruncateRightHandPanel(hideRightHandSidebar) &&
      this.isPopUpEvent()
    ) {
      return false;
    }

    if (shouldHideEventResponseOptions(event)) {
      // do not show resposne if organizer
      return false;
    }

    return true;
  }

  shouldDisplayWhereSection(event) {
    return (
      this.state.eventLocation ||
      this.state.doesEventHaveConference ||
      this.doesEventHaveConferenceRooms(event)
    );
  }

  openEventLocation() {
    if (getEventLocation(this.state.event)) {
      Broadcast.publish(`OPEN_LOCATION_IN_GOOGLE_MAPS`);
    }
  }

  openEventExpandedConferencing(skipCheck = false) {
    if (
      !skipCheck &&
      this.state.event &&
      isBeforeMinute(
        this.state.event.eventEnd,
        getCurrentTimeInCurrentTimeZone(this.props.currentTimeZone)
      )
    ) {
      ConferencingBroadcasts.publish(
        "OPEN_PAST_CONFERENCING_MODAL",
        this.state.event.summaryUpdatedWithVisibility
      );
      return;
    }

    if (this.state.doesEventHaveConference) {
      Broadcast.publish(`OPEN_CONFERENCING_LINK`);
    }
  }

  doesEventHaveConference(currentEvent) {
    return currentEvent && !!currentEvent.conferenceUrl;
  }

  doesEventHaveConferenceRooms() {
    const conferenceRooms = this.state.eventConferenceRoom;

    return conferenceRooms?.length > 0;
  }

  doesEventHaveTime(event) {
    return (
      event.eventStart ||
      event.eventEnd ||
      getEventStart(event) ||
      getEventEnd(event)
    );
  }

  shouldHideToolBar() {
    return this.props.hideToolbar;
  }

  shouldDisplayEmail() {
    if (this.isAppInTaskMode()) {
      return false;
    }

    if (this.props.template) {
      return false;
    }

    return true;
  }

  isAppInTaskMode() {
    const { actionMode } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;
    return isAppInTaskMode({
      actionMode,
      reverseSlotsText,
    });
  }

  shouldHideEditButton(currentEvent) {
    if (!currentEvent) {
      return true;
    }
    if (isOutlookDraftEvent(currentEvent)) {
      return true;
    }

    if (this.isAppInTaskMode()) {
      return true;
    }

    const { actionMode } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;
    const { allCalendars } = this.props.allCalendars;
    return !isEditable({
      event: currentEvent,
      allCalendars,
      actionMode,
      reverseSlotsText,
    });
  }

  isEventEditablePrivateProperty() {
    const { allCalendars } = this.props.allCalendars;
    const { actionMode } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;

    return isEditable({
      event: this.state.event,
      allCalendars,
      actionMode,
      reverseSlotsText,
      isPrivateProperty: true,
    });
  }

  onClickExitExpandedView() {
    if (this.props.template) {
      Broadcast.publish("TOGGLE_SHOW_TEMPLATE");
    } else {
      mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

      this.props.history.push("/home");
    }
  }

  onClickExitPopup() {
    mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
  }

  determineRepeatText(event) {
    const originalRecurrenceEvent = getOriginalRecurringEventFromIndex(
      event,
      this.props.originalRecurrenceEventIndex
    );

    const rRuleString = getRRuleStringFromRecurrence(originalRecurrenceEvent);
    if (!originalRecurrenceEvent || !rRuleString) {
      return "";
    }

    return parseRRuleStringToHumanReadableText(rRuleString);
  }

  constructEventDataV2(updatedData) {
    const { event } = this.state;
    return {
      user_calendar_id: getEventUserCalendarID(event),
      delete_conferencing: false,
      calendar_event: {
        ...updatedData,
        provider_id: getGoogleEventId(event),
      },
      user_event_id: getEventUserEventID(event),
    };
  }

  setEventAttachments({ attachments, eventProviderID }) {
    /* If the fetch was for a different event then we return */
    if (
      isEmptyArray(attachments) ||
      getEventID(this.state.event) !== eventProviderID
    ) {
      return;
    }

    this.setState({ eventAttachments: attachments });
  }

  async fetchFullOutlookPreviewEvent() {
    const { event } = this.state;
    if (isTestingPreviewOutlook(TEST_PREVIEW_OUTLOOK_LOCATION.GET_FULL_EVENT)) {
      return;
    }
    if (!isPreviewOutlookEvent(event)) {
      return;
    }
    try {
      const response = await getFullEvent({
        event,
        userEmail: getEventUserEmail(event),
      });
      if (!this._isMounted || !response?.event) {
        return;
      }
      const { event: fullEvent } = response;
      this.setFullOutlookEvent(fullEvent);
      return fullEvent;
    } catch (error) {
      handleError(error);
    }
  }

  toggleHiddenEvent() {
    const {
      event,
    } = this.state;
    const isEventCurrentlyHidden = isEventHiddenEvent(event);
    toggleEventHiddenEventProperty(event, isEventCurrentlyHidden ? HIDDEN_EVENT_TOGGLE.OFF : HIDDEN_EVENT_TOGGLE.ON);
  }

  // replacing the outlook preview event with the full version of the event
  setFullOutlookEvent(fullEvent) {
    const eventID = getEventID(fullEvent);
    if (getEventID(fullEvent) !== eventID) {
      return; // event has change
    }
    const {
      currentTimeZone,
      currentPreviewedEvent,
      popupEvent,
      currentHoverEvent,
      hoverPopupEvent,
    } = this.props;
    const formattedEvent = formatEventForReactBigCalendar({
      event: fullEvent,
      currentTimeZone,
      calendarId: getEventUserCalendarID(fullEvent),
    });
    mainCalendarBroadcast.publish(
      MAIN_CALENDAR_BROADCAST_VALUES.REPLACE_OUTLOOK_PREVIEW_EVENT,
      formattedEvent
    );

    if (currentPreviewedEvent || popupEvent) {
      mainCalendarBroadcast.publish("SET_PREVIEW_EVENT", formattedEvent);
    } else if (currentHoverEvent || hoverPopupEvent) {
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.SET_HOVER_POPUP_EVENT,
        formattedEvent
      );
    }
  }

  scrollToTopOfPage() {
    try {
      if (!this.topRef?.current?.scrollIntoView) {
        return;
      }
      this.topRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    } catch (error) {
      handleError(error);
    }
  }

  getPopupEventOverflowClassname() {
    if (this.props.ignoreOverflow) {
      return "";
    }
    if (!this.state.isPopUpEvent) {
      return "";
    }
    return this.props.event?.displayAsAllDay
      ? "pop-up-expanded-view-content-container-all-day"
      : "pop-up-expanded-view-content-container-not-all-day";
  }

  onClickX() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    !this.state.isPopUpEvent
      ? this.onClickExitExpandedView()
      : this.onClickExitPopup();
  }
}

function mapStateToProps(state) {
  let {
    popupEvent,
    shouldShowGlobalKeyMap,
    emailToNameIndex,
    shouldShowTopBar,
    currentTimeZone,
    currentPreviewedEvent,
    currentHoverEvent,
    currentUser,
    isDarkMode,
    hoverPopupEvent,
    originalRecurrenceEventIndex,
    actionMode,
  } = state;

  return {
    popupEvent,
    shouldShowGlobalKeyMap,
    emailToNameIndex,
    shouldShowTopBar,
    currentTimeZone,
    currentPreviewedEvent,
    currentHoverEvent,
    currentUser,
    isDarkMode,
    hoverPopupEvent,
    originalRecurrenceEventIndex,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
    removeCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    removeTemplate: (data) => dispatch({ data: data, type: "REMOVE_TEMPLATE" }),
    setDuplicateEvent: (data) => dispatch({ data, type: "DUPLICATE_EVENT" }),
    removeHoverPopupEvent: () => dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
    setActionMode: (data) => dispatch({ data, type: "SET_ACTION_MODE" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const allLoggedInUsers = useAllLoggedInUsers();
  const temporaryStateStore = useTemporaryStateStore();
  const masterAccount = useMasterAccount();
  const outlookCategoriesStore = useOutlookCategoriesStore();
  const distroListDictionary = useDistroListDictionary();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      hideRightHandSidebar={hideRightHandSidebar}
      allLoggedInUsers={allLoggedInUsers}
      temporaryStateStore={temporaryStateStore}
      masterAccount={masterAccount}
      outlookCategoriesStore={outlookCategoriesStore}
      distroListDictionary={distroListDictionary}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(EventExpandedView))
);
