import classNames from "classnames";
import React, { useState } from "react";
import GroupVoteSpreadSheetTable from "./groupVoteSpreadSheetTable";
import { removeDuplicatesFromArray } from "../../../services/commonUsefulFunctions";
import { getAttendeeUUID } from "./sharedFunctions";
import SelectVoteOrChronologicallyDropdown from "../../availability/selectVoteOrChronologicallyDropdown";
import { SORT_BY_CHRONOLOGICALLY_OPTION } from "../schedulingSharedVariables";
import availabilityBroadcast from "../../../broadcasts/availabilityBroadcast";
import broadcast from "../../../broadcasts/broadcast";
import { parseISO } from "date-fns";

export default function GroupVoteSpreadSheet({
  children,
  bookingLink,
  selectedTimeZone,
  isOnCreate,
}) {
  // each attendee should look something like this:
  // {
  //   "uuid": "some-uuid-to-create-or-update",
  //   "name": "Name",
  //   "email": "email@email.com",
  //   "slots": [
  //     {
  //       "start/startDate": "start-value",
  //       "end/endDate": "start-value",
  //       "selected": "true/false",
  //       "comment": "some comment",
  //     }
  //   ]
  // }
  const [editedAttendees, setEditedAttendees] = useState([]);
  const [deletedAttendeesUUID, setDeletedAttendeesUUID] = useState([]);
  const [sortBy, setSortBy] = useState(SORT_BY_CHRONOLOGICALLY_OPTION);
  const [selectedSlotForEventCreation, setSlotForEventCreation] =
    useState(null);

  const onDeleteAttendee = (uuid) => {
    setDeletedAttendeesUUID(
      removeDuplicatesFromArray(deletedAttendeesUUID.concat(uuid))
    );
  };

  const renderCreateButton = () => {
    return (
      <div
        className={classNames(
          "custom-button-box width-90px text-white",
          "mr-7",
          "blue-button"
        )}
        onClick={() => {
          if (!selectedSlotForEventCreation) {
            return;
          }
          const getSlotEventStartAndEnd = () => {
            // need to recalcuate for time zone
            const { start, end, startDate, endDate } =
              selectedSlotForEventCreation;
            return {
              eventStart: parseISO(startDate ?? start),
              eventEnd: parseISO(endDate ?? end),
            };
          };

          availabilityBroadcast.publish(
            "CREATE_EVENT_FROM_BOOKING_LINK",
            bookingLink,
            getSlotEventStartAndEnd()
          );
          broadcast.publish("CLOSE_LAYOUT_MODAL");
        }}
      >
        {"Create"}
      </div>
    );
  };

  const onUpdateAttendee = (updatedAttendee) => {
    const updated = editedAttendees
      .filter(
        (attendee) =>
          getAttendeeUUID(attendee) !== getAttendeeUUID(updatedAttendee)
      )
      .concat(updatedAttendee);
    setEditedAttendees(updated);
  };

  const renderSelect = () => {
    if (!isOnCreate) {
      return null;
    }
    return (
      <div className="mr-7">
        <SelectVoteOrChronologicallyDropdown value={sortBy} onSet={setSortBy} />
      </div>
    );
  };

  const renderScheduler = () => {
    return (
      <div
        className={classNames(
          "group-vote-spreadsheet-container",
          "mx-7",
          "rounded-md",
          "default-light-mode-border",
        )}
      >
        <GroupVoteSpreadSheetTable
          bookingLink={bookingLink}
          selectedTimeZone={selectedTimeZone}
          onDeleteAttendee={onDeleteAttendee}
          onUpdateAttendee={onUpdateAttendee}
          isOnCreate={isOnCreate}
          sortBy={sortBy.value}
          onSetSlotForCreation={setSlotForEventCreation}
        />
      </div>
    );
  };
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div
        className={classNames(
          "overflow-y-auto h-full",
          "group-vote-spreadsheet-table-scroll-wrapper"
        )}
      >
        <div className="flex justify-between w-full items-center">
          {children}
          {renderSelect()}
        </div>

        {renderScheduler()}
      </div>

      {isOnCreate ? (
        <div className="w-full flex justify-end my-4">
          {renderCreateButton()}
        </div>
      ) : (
        <div className="h-4"></div>
      )}
    </div>
  );
}
