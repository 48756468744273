import React from "react";
import StyleConstants, { LIGHT_MODE_TILE_BACKGROUND_COLOR, LIGHT_MODE_TILE_TEXT_COLOR } from "../../services/globalVariables";
import classNames from "classnames";
import { useSelector } from "react-redux";

interface ShortcutTileProps {
  backgroundColor?: string
  border?: string
  color?: string
  height?: string | number
  inputClassname?: string
  isCommandCenter?: boolean
  large?: boolean
  shortcut: string
  width?: string | number
  useDefaultTextColor?: boolean
}

export default function ShortcutTile({
  width,
  shortcut,
  large,
  backgroundColor,
  border,
  color,
  isCommandCenter,
  height,
  inputClassname,
  useDefaultTextColor,
}: ShortcutTileProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const determineWidth = () => {
    if (width) {
      return width;
    }

    if (shortcut?.length === 1) {
      return 24;
    }

    return;
  };

  const getTextHeight = () => {
    if (["[", "]", "Backspace"].includes(shortcut)) {
      return "fit-content";
    } else {
      return;
    }
  };

  const getTextColor = () => {
    if (useDefaultTextColor) {
      return undefined;
    }
    if (color) {
      return color;
    }

    if (border?.includes("white")) {
      return "white";
    }

    return isDarkMode ? "rgba(255, 255, 255, 0.9)" : LIGHT_MODE_TILE_TEXT_COLOR;
  };

  const getTileBackgroundColor = () => {
    if (backgroundColor) {
      return backgroundColor;
    }

    return isDarkMode ? StyleConstants.defaultFontColor : LIGHT_MODE_TILE_BACKGROUND_COLOR;
  };

  return (
    <span
      className={classNames(
        "short-cut-tile",
        isCommandCenter ? "short-cut-tile-command-center" : "",
        inputClassname ?? "",
      )}
      style={{
        fontSize: large ? "4px" : "10px",
        background: getTileBackgroundColor(),
        border,
        width: determineWidth(),
        height: height ?? 22,
      }}
    >
      <span
        style={{
          fontSize: large ? "18px" : "12px",
          color: getTextColor(),
          // color: color || "rgba(255, 255, 255, 0.9)",
          height: getTextHeight(),
        }}
      >
        {shortcut}
      </span>
    </span>
  );
}
