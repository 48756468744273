import classNames from "classnames";
import React from "react";
import type { Icon } from "react-feather";

interface ButtonWrapperProps extends Omit<React.HTMLProps<HTMLSpanElement>, "children"> {
  IconComponent: Icon
}

/**
 * A wrapper for the next/previous buttons associated with a carousel.
 */
export default function ButtonWrapper({ IconComponent, className, ...props }: ButtonWrapperProps) {

  return (
    <span
      className={classNames(
        "flex items-center justify-center rounded-full w-8 h-8 cursor-pointer",
        "box-shadow-9 select-none",
        "hoverable-secondary-background-color",
        "transition",
        className,
      )}
      {...props}
    >
      <IconComponent
        size={16}
        color={"white"}
      />
    </span>
  );
}
