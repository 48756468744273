import React, { Component } from "react";
import { addAbbrevationToTimeZone } from "../services/commonUsefulFunctions";
import Classnames from "classnames";
import { getTemplateDuration } from "../services/templateFunctions";
import { pluralize } from "../lib/stringFunctions";


class ExpandedTemplateEventTime extends Component {

  render() {
    let event = this.props.event;
    return this.shouldShowDateAndTime(event) ? (
      <div className="expand-event-start-end">
        <div>
          <div className="event-expanded-description-label">
            Date & Time
          </div>

          <div className="expand-event-month-and-time-wrapper">
            {this.determineTimeText(event)}
          </div>

          {this.renderTimeZone(event)}
        </div>
      </div>
    ) : <div></div>;
  }

  renderAllDayText(event) {
    const templateDuration = getTemplateDuration(event);
    return (
      <div className="edit-template-from-to">
        <div className="event-template-edit-view-mode-time-text">
          Duration:
        </div>

        {`${templateDuration.days} ${pluralize(templateDuration.days, 'Day')}`}
      </div>
    );
  }

  shouldShowDateAndTime(event) {
    return event.allDay || getTemplateDuration(event);
  }

  determineTimeText(event) {
    if (event.allDay) {
      return this.renderAllDayText(event);
    } else if (getTemplateDuration(event)) {
      return this.renderTemplateStartEndTime(event);
    }
  }

  renderTemplateStartEndTime(event) {
    const templateDuration = getTemplateDuration(event);
    return (
      <div>
        <div className="edit-template-from-to">
          <div className="event-template-edit-view-mode-time-text">
            Duration:
          </div>

         {`${templateDuration.hours} ${pluralize(templateDuration.hours, 'Hour')}, ${templateDuration.minutes} ${pluralize(templateDuration.minutes, 'Minute')}`}
        </div>
      </div>
    );
  }

  renderTimeZone(event) {
    if (!event.startTimeZone && !event.endTimeZone) {
      return <div></div>;
    } else {
      if (event.startTimeZone === event.endTimeZone && event.startTimeZone) {
        // Same timezone
        return (
          <div className={Classnames("margin-top-ten", "display-flex-flex-direction-row")}>
            <div className="event-template-edit-view-mode-time-text">
              Time zone:
            </div>

           {addAbbrevationToTimeZone({timeZone: event.startTimeZone})}
          </div>
        );
      } else if ((!event.startTimeZone || !event.endTimeZone) && (event.startTimeZone || event.endTimeZone)) {
        // only one timezone
        return  (
          <div className={Classnames("margin-top-ten", "display-flex-flex-direction-row")}>
            <div className="event-template-edit-view-mode-time-text">
              Time zone:
            </div>

            {addAbbrevationToTimeZone({timeZone: event.startTimeZone, returnNull: true}) || addAbbrevationToTimeZone({timeZone: event.endTimeZone})}
          </div>
        );
      } else if (event.startTimeZone && event.endTimeZone) {
        // different timezone
        return (
          <div className="margin-top-ten">
            <div className="display-flex-flex-direction-row">
              <div className="event-template-edit-view-mode-time-text">
                Start time zone:
              </div>

             {addAbbrevationToTimeZone({timeZone: event.startTimeZone})}
            </div>

            <div className={Classnames("margin-top-ten", "display-flex-flex-direction-row")}>
              <div className="event-template-edit-view-mode-time-text">
                End time zone:
              </div>

             {addAbbrevationToTimeZone({timeZone: event.endTimeZone})}
            </div>
          </div>
        );
      }
    }
  }
}


export default ExpandedTemplateEventTime;
