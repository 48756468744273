import React, { useEffect, useMemo, useState } from "react";
import VimcalEAFormTestimonial from "./vimcalEAFormTestimonial";
import VimcalEASignupPage from "./vimcalEASignupPage";
import DynamicSplitLayout from "../dynamicSplitView";
import appBroadcast from "../../js/broadcasts/appBroadcast";
import routeBroadcast from "../../js/broadcasts/routeBroadcast";
import { useSelector } from "react-redux";
import { isEmptyObjectOrFalsey } from "../../js/services/typeGuards";
import { generateIDForVimcalEATestimonialHeader, VIMCAL_EA_FORM_CATEGORY } from "../../js/services/experiments";
import { trackEvent } from "../../js/components/tracking";
import { EA_COUNT_OPTIONS } from "../../js/components/onboarding/sharedFunctions";
import CalendlyBookingPage from "../../js/components/specialComponents/calendlyBookingPage";
import onboardBroadcast from "../../js/broadcasts/onboardBroadcast";
import { ONBOARD_BROADCAST_VALUES } from "../../js/lib/broadcastValues";
import classNames from "classnames";
import VimcalEALogo from "./vimcalEALogo";

export default function VimcalEAForm() {
  const currentUser = useSelector((state) => state.currentUser);
  const id = useMemo(() => generateIDForVimcalEATestimonialHeader(), []);

  const [shouldShowBookingPage, setShouldShowBookingPage] = useState(false);
  const [numberOfEAs, setNumberOfEAs] = useState(EA_COUNT_OPTIONS[0]);

  useEffect(() => {
    if (!isEmptyObjectOrFalsey(currentUser)) {
      routeBroadcast.publish("GO_TO_HOME");
      return;
    }
    appBroadcast.publish("SET_APP_LIGHT_MODE");
    onboardBroadcast.subscribe(ONBOARD_BROADCAST_VALUES.OPEN_BOOKING_PAGE, (numberOfEAStr) => {
      setShouldShowBookingPage(true);
      setNumberOfEAs(numberOfEAStr);
    });

    trackEvent({
      category: VIMCAL_EA_FORM_CATEGORY,
      action: "landed",
      label: id,
    });

    return () => {
      onboardBroadcast.unsubscribe(ONBOARD_BROADCAST_VALUES.OPEN_BOOKING_PAGE);
    };
  }, [id]);

  if (shouldShowBookingPage) {
    return (
      <div
        className={classNames("onboarding-container pt-2.5 pb-2.5")}
        style={{ minHeight: "800px", height: "calc(100vh - 100px)" }}
      >
        <VimcalEALogo height="200px" />
        <CalendlyBookingPage isEA numberOfExecutiveAssistants={numberOfEAs} />
      </div>
    );
  }

  return (
    <DynamicSplitLayout
      leftContent={<VimcalEAFormTestimonial id={id} />}
      rightContent={<VimcalEASignupPage id={id} />}
      leftPanelClassName="vimcal-ea-testimonial-left-panel-bg-color min-panel-height"
      rightPanelClassName="bg-white vimcal-ea-signup-page-right-panel-padding min-panel-height"
    />
  );
}
