import React, { useEffect, useState } from "react";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import {
  useDefaultPaymentMethod,
  useHasBillingBeenFetched,
  usePromotionsStore,
} from "../../../../services/stores/finance";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import BillingPaymentMethod from "../../../billing/billingPaymentMethod";
import LoadingSkeleton from "../../../loadingSkeleton";
import { shouldShowTrialActive } from "../../../../lib/userFunctions";
import layoutBroadcast from "../../../../broadcasts/layoutBroadcast";
import { isAdminsDayDiscount, isMonthOffPromotion, isYCDiscount } from "../../../../lib/stateManagementFunctions";
import { ADMINS_DAY_DISCOUNT_COPY, YC_DISCOUNT_COPY } from "../../../../lib/copy";
import { useIsCurrentUserAdmin } from "../../../teamPlans/hooks";
import NonAdminBillingSettings from "../../../billing/nonAdminBillingSettings";
import AdminBillingSettings from "../../../billing/adminBillingSettings";

interface BillingSettingsProps {
  skipBillingPromotion: boolean
}

/**
 * The billing pane of the settings modal. This component determines which content to show based
 * on the admin status of the logged in users.
 */
export default function BillingSettings({ skipBillingPromotion }: BillingSettingsProps) {
  // if skipBillingPromotion is true, we will not show the promotion page for selecting price.
  // This is for onboarding to minimize friction
  const [showWaitingForBilling, setShowWaitingForBilling] = useState(false);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isTeamPlanAdmin = useIsCurrentUserAdmin();
  const defaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.defaultPaymentMethod,
  );
  const unappliedPromotions = usePromotionsStore(
    (state) => state.unappliedPromotions,
  );

  const hasBillingBeenFetched = useHasBillingBeenFetched(
    (state) => state.hasBillingBeenFetched,
  ); // this will cause trigger

  useEffect(() => {
    backendBroadcasts.publish("CHECK_FOR_PROMOTIONS_UNAPPLIED");
  }, []);

  const onPaymentSuccess = () => {
    setShowWaitingForBilling(true);
  };

  // We want to direct users on team plan to contact us directly about billing
  const onClickAddPaymentMethod = () => {
    layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL");
  };

  const renderPromotionalCopy = () => {
    if (isYCDiscount(unappliedPromotions)) {
      return (
        <div className="default-font-size mt-1">
          {YC_DISCOUNT_COPY}
        </div>
      );
    }
    if (isAdminsDayDiscount(unappliedPromotions)) {
      return (
        <div className="default-font-size mt-1">
          {ADMINS_DAY_DISCOUNT_COPY}
        </div>
      );
    }
    if (isMonthOffPromotion(unappliedPromotions)) {
      return (
        <div className="default-font-size mt-1">
          {"Remember, you have one free month so we won't charge you until then!"}
        </div>
      );
    }
    return null;
  };

  if (shouldShowTrialActive({ masterAccount, defaultPaymentMethod })) {
    if (showWaitingForBilling) {
      return renderSkeleton();
    }

    if (!isTeamPlanAdmin) {
      return <NonAdminBillingSettings />;
    }

    return (
      <div>
        <div className="default-font-size">Join thousands of CEOs, investors, and others who trust Vimcal to manage their time.</div>
        {renderPromotionalCopy()}
        <div className="mt-4">
          <BillingPaymentMethod
            isAddPaymentMethod={true}
            overrideOnClick={skipBillingPromotion ? undefined : onClickAddPaymentMethod}
            inputOnSuccess={onPaymentSuccess}
          />
        </div>
      </div>
    );
  }

  if (!hasBillingBeenFetched) {
    return renderSkeleton();
  }

  if (!isTeamPlanAdmin) {
    return <NonAdminBillingSettings />;
  }

  return <AdminBillingSettings />;
}

function renderSkeleton() {
  return <LoadingSkeleton style={{ width: "100%", height: 200, borderRadius: 8 }} />;
}

