import classNames from "classnames";
import React, { useState } from "react";
import ColoredLine from "../line";
import { useBackendSubscriptionStore, useCharges } from "../../services/stores/finance";
import { OpenLink } from "../../services/commonUsefulFunctions";
import { format } from "date-fns";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { Stripe } from "stripe-types";
import { getBackendSubscriptionStripeSubID } from "../../services/accessors/backendSubscription";

export default function BillingReceipts() {
  const charges = useCharges((state) => state.charges);
  const [shouldShowAll, setShowAllCharges] = useState(false);
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);

  // If the subscription is not managed through Stripe, there are no receipts to show.
  if (!getBackendSubscriptionStripeSubID(backendSubscription)) {
    return null;
  }

  const renderPerReceipt = (charge: Stripe.Charge) => {
    const RECEIPT_SPACING = "mr-16";
    return (
      <div className="flex items-center mt-8 default-font-size w-full justify-between pr-2.5">
        <div
          style={{ minWidth: 140 }}
          className={classNames(
            "font-weight-400",
            RECEIPT_SPACING,
            "flex items-center",
          )}
        >
          {format(new Date(charge.created * 1000), "PPP")}
        </div>

        <div
          style={{ minWidth: 52 }}
          className={classNames("mr-8", RECEIPT_SPACING)}
        >
          ${(Math.round(charge.amount) / 100).toFixed(2)}
        </div>

        {charge.paid ? (
          <div
            style={{ minWidth: 48 }}
            className={classNames(
              RECEIPT_SPACING,
              "flex items-center font-size-12 justify-center w-12 h-5 rounded bg-green-100 text-green-700 font-weight-400",
            )}
          >
            Paid
          </div>
        ) : (
          <div
            className={classNames(
              RECEIPT_SPACING,
              "flex items-center font-size-12 justify-center w-12 h-5 rounded bg-yellow-100 text-yellow-700 font-weight-400",
            )}
          >
            Void
          </div>
        )}

        {charge.receipt_url ? (
          <div
            onClick={() => OpenLink(charge.receipt_url)}
            className="default-font-size font-weight-400 text-blue-500 cursor-pointer"
          >
            View
          </div>
        ) : null}
      </div>
    );
  };

  const renderCharges = () => {
    if (isEmptyArrayOrFalsey(charges)) {
      return null;
    }

    const chargesToShow = shouldShowAll ? charges : charges.slice(0, 2);
    return chargesToShow.map((c) => {
      return <div key={c.id}>{renderPerReceipt(c)}</div>;
    });
  };

  return (
    <div>
      <div className="mb-4 default-font-size font-weight-400 mt-14 secondary-text-color">RECEIPTS</div>

      <ColoredLine />

      {renderCharges()}

      {!shouldShowAll && charges.length > 2 ? (
        <div
          className="w-full flex text-center default-font-size justify-center mt-5 font-weight-400 text-blue-500 cursor-pointer"
          onClick={() => setShowAllCharges(true)}
        >
          SEE ALL
        </div>
      ) : null}
    </div>
  );
}
