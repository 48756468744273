import React                from 'react';


function DottedLine(props) {
  return (
    <div
      className='dotted-spaced'
      style={props.style}
    >
      {'.'}
    </div>
  );
}

export default DottedLine;
