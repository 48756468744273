import React, { Component } from "react";
import ModalTextOptionsSelect from "../components/modalTextOptionsSelect";
import {
  WeekOfMonth,
  ConvertDayOfWeekIntegerIntoWeekdayConstant,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService from "../services/googleCalendarService";
import { RRule } from "rrule";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../lib/stringFunctions";
import { isLastOccurrenceOfWeekdayInMonth } from "../lib/dateFunctions";
import { getWeekdayConstant } from "../lib/rruleFunctions";

let { doesNotRepeat, custom } = GoogleCalendarService;

class DefaultRepeatOptions extends Component {
  constructor(props) {
    super(props);

    this.constructRepeatRules = this.constructRepeatRules.bind(this);
  }

  render() {
    const options = this.listRepeatOptions();

    return (
      <ModalTextOptionsSelect
        onRef={(ref) => (this.modalSelectRepeat = ref)}
        selectOption={options.repeatOptions}
        defaultOption={
          options.repeatOptions.includes(this.props.defaultOption)
            ? this.props.defaultOption
            : custom
        }
        noSaveButton={true}
        sendInformationBackToParentComponent={this.constructRepeatRules}
      />
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  listRepeatOptions() {
    const {
      date
    } = this.props;
    const byWeekDay = date.getDay() === 0 ? 6 : date.getDay() - 1;

    // Does not repeat rule
    let doesNotRepeatRRule = {};
    let doesNotRepeatText = doesNotRepeat;

    // Create daily rule
    let dailyRRule = new RRule({
      freq: RRule.DAILY,
    });
    let dailyRuleText = capitalizeFirstLetter(dailyRRule.toText());

    // Create weekly rule
    let weeklyRRule = new RRule({
      freq: RRule.WEEKLY,
      byweekday: ConvertDayOfWeekIntegerIntoWeekdayConstant(byWeekDay),
    });
    let weeklyRuleText = capitalizeFirstLetter(weeklyRRule.toText());

    // Create monthly rule
    // https://stackoverflow.com/questions/21737974/moment-js-how-to-get-week-of-month-google-calendar-style
    let monthlyRRule = new RRule({
      freq: RRule.MONTHLY,
      byweekday: ConvertDayOfWeekIntegerIntoWeekdayConstant(
        byWeekDay,
        WeekOfMonth(date)
      ),
    });

    let monthlyRuleText = capitalizeFirstLetter(monthlyRRule.toText());

    // Create annual rule
    let annualRRule = new RRule({
      freq: RRule.YEARLY,
      bymonth: parseInt(format(date, "M")),
      bymonthday: date.getDate(),
    });
    let annualRuleText = capitalizeFirstLetter(annualRRule.toText());

    // every weekday
    const weekdayRRule = new RRule({
      freq: RRule.WEEKLY,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    });
    const weekdayRuleText = capitalizeFirstLetter(weekdayRRule.toText());

    if (isLastOccurrenceOfWeekdayInMonth(date)) {
      const weekdayConstant = getWeekdayConstant(date);
      const lastWeekdayOfMonth = new RRule({
        freq: RRule.MONTHLY,
        byweekday: weekdayConstant.nth(-1), // Last occurrence of the specified weekday each month
      });
      const lastWeekdayOfMonthText = capitalizeFirstLetter(lastWeekdayOfMonth.toText());
      return {
        repeatOptions: [
          doesNotRepeatText,
          dailyRuleText,
          weeklyRuleText,
          monthlyRuleText,
          lastWeekdayOfMonthText,
          annualRuleText,
          weekdayRuleText,
          custom,
        ],
        repeatKey: {
          [doesNotRepeatText]: doesNotRepeatRRule,
          [dailyRuleText]: dailyRRule,
          [weeklyRuleText]: weeklyRRule,
          [monthlyRuleText]: monthlyRRule,
          [lastWeekdayOfMonthText]: lastWeekdayOfMonth,
          [annualRuleText]: annualRRule,
          [weekdayRuleText]: weekdayRRule,
        },
      };
    }

    return {
      repeatOptions: [
        doesNotRepeatText,
        dailyRuleText,
        weeklyRuleText,
        monthlyRuleText,
        annualRuleText,
        weekdayRuleText,
        custom,
      ],
      repeatKey: {
        [doesNotRepeatText]: doesNotRepeatRRule,
        [dailyRuleText]: dailyRRule,
        [weeklyRuleText]: weeklyRRule,
        [monthlyRuleText]: monthlyRRule,
        [annualRuleText]: annualRRule,
        [weekdayRuleText]: weekdayRRule,
      },
    };
  }

  constructRepeatRules(repeat) {
    if (repeat === custom) {
      this.props.selectCustomRepeat();
    } else {
      let constructedRule = {
        rRule: this.listRepeatOptions().repeatKey[repeat],
        ruleText: repeat,
      };

      this.props.sendInformationBackToParentComponent(constructedRule);
    }
  }
}

export default DefaultRepeatOptions;
