import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { ChevronUp, Music } from "react-feather";
import Mousetrap from "mousetrap";
import CloudRain from "./resources/cloudRain";
import NoSound from "./resources/noSound";
import { Howl } from "howler";
import { trackEvent } from "../components/tracking";
import { useSelector } from "react-redux";
import { BEACH_MUSIC, COFFEE_MUSIC, RAIN_MUSIC } from "./sharedVariables.ts";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import {
  FIRE_PLACE_MUSIC,
  FOCUS_MODE_SOUND_OFF,
  FOCUS_MODE_SOUND_ON,
  JAZZ_MUSIC,
  LOFI_MUSIC,
  SNOW_MUSIC,
} from "./sharedVariables";
import CampFire from "./resources/campFire";
import Snow from "./resources/snow";
import Headphones from "./resources/headphones";
import CoffeeCup from "./resources/coffeeCup";
import Beach from "./resources/beach";
import SoundNoise from "./resources/soundNoise";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import { getUserToken } from "../lib/userFunctions";

const NO_SOUND = "No sound";
const RAIN = "Rain";
const COFFEE = "Cafe";
const BEACH = "Beach";
const JAZZ = "Jazz";
const LOFI = "Lo-Fi";
const FIRE_PLACE = "Fireplace";
const SNOW = "Snow trail";
const INITIAL_DEFAULT_STATE = "Vibes";

const SOUND_OPTIONS = [RAIN, COFFEE, BEACH, SNOW];
const SOUND_OPTIONS_2 = [JAZZ, LOFI, FIRE_PLACE, NO_SOUND];
const SOUND_SOURCE = {
  [NO_SOUND]: null,
  [RAIN]: RAIN_MUSIC,
  [COFFEE]: COFFEE_MUSIC,
  [BEACH]: BEACH_MUSIC,
  [JAZZ]: JAZZ_MUSIC,
  [LOFI]: LOFI_MUSIC,
  [FIRE_PLACE]: FIRE_PLACE_MUSIC,
  [SNOW]: SNOW_MUSIC,
};

const SOUND_HOT_KEYS = {
  [RAIN]: "1",
  [COFFEE]: "2",
  [BEACH]: "3",
  [SNOW]: "4",
  [JAZZ]: "5",
  [LOFI]: "6",
  [FIRE_PLACE]: "7",
  [NO_SOUND]: "0",
};

export default function SoundContainer({ containerClassName }) {
  const [sound, setSound] = useState(INITIAL_DEFAULT_STATE);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const componentIsMounted = useIsMounted();

  const soundPlayer = useRef(null);

  const bindHotKeyToPlaySound = () => {
    Object.keys(SOUND_HOT_KEYS).forEach((sound) => {
      Mousetrap.bind([SOUND_HOT_KEYS[sound]], function (e) {
        onClickSound(sound);
      });
    });
  }

  useEffect(() => {
    focusModeBroadcast.subscribe("BIND_HOTKEY_TO_PLAY_SOUND", bindHotKeyToPlaySound);
    focusModeBroadcast.subscribe("PAUSE_FOCUS_MODE_MUSIC", () => {
      if (!componentIsMounted.current) {
        return;
      }
      
      pauseSound();
      setSound(INITIAL_DEFAULT_STATE);
    });

    return () => {
      focusModeBroadcast.unsubscribe("BIND_HOTKEY_TO_PLAY_SOUND");
      focusModeBroadcast.unsubscribe("PAUSE_FOCUS_MODE_MUSIC");
      pauseSound();
      soundPlayer.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://www.youtube.com/watch?v=ZYy8w5QmdbM
  const playSound = (option) => {
    if (option !== NO_SOUND) {
      trackEvent({
        category: "feature_usage",
        action: `sound_${option}`,
        label: "focus_mode",
        userToken: getUserToken(currentUser),
      });
    }

    pauseSound();

    if (!SOUND_SOURCE[option]) {
      return;
    }

    const sound = new Howl({
      // src: "https://soundbible.com/mp3/45min_april_rainstorm-mike-koenig.mp3",
      src: SOUND_SOURCE[option],
      loop: true,
      html5: true, // to prevent cors error from external source
    });
    soundPlayer.current = sound;
    soundPlayer.current.play();
  };

  const isSoundPlaying = () => {
    return sound !== NO_SOUND && sound !== INITIAL_DEFAULT_STATE;
  }

  const pauseSound = () => {
    if (soundPlayer.current) {
      soundPlayer.current.pause();
    }
  };

  const getIcon = (option) => {
    const size = 24;
    const iconIndex = {
      [NO_SOUND]: <NoSound size={size} />,
      [RAIN]: <CloudRain size={size} />,
      [COFFEE]: <CoffeeCup size={size} />,
      [BEACH]: <Beach size={size} />,
      [JAZZ]: <Music size={size} />,
      [LOFI]: <SoundNoise size={size} />,
      [FIRE_PLACE]: <CampFire size={size} />,
      [SNOW]: <Snow size={size} />,
      [INITIAL_DEFAULT_STATE]: <Headphones size={size} />,
    };

    return iconIndex[option];
  };

  const onClickSound = (option) => {
    if (!componentIsMounted.current) {
      return;
    }

    setSound(option);

    if (option === NO_SOUND) {
      pauseSound();
      return;
    }

    playSound(option);
  };

  const soundDropDown = () => {
    return (
      <div
        onClick={() => {
          setShowMoreOptions(!showMoreOptions);
        }}
        className="w-40 h-12 large-blur rounded-lg flex items-center justify-center text-white cursor-pointer z-20 default-hover-blur-button duration-200"
      >
        {getIcon(sound)}
        <div className="default-font-size mx-4 leading-6 h-5 select-none width-70px">
          {sound}
        </div>

        <ChevronUp
          className={classNames(
            "default-font-size duration-200 transition-transform",
            showMoreOptions ? "rotate-upside-down" : "",
          )}
          size={20}
        />
      </div>
    );
  };

  const renderAllSoundOptions = () => {
    const renderOptionRow = (option) => {
      return (
        <div
          className={classNames(
            "flex items-center default-font-size font-weight-300 py-3 text-white rounded cursor-pointer",
            sound === option ? "selected-option-background-color" : "",
            option === NO_SOUND ? "pl-7 pr-6" : "px-6",
            "hover-sound-container-option duration-200"
          )}
          onClick={() => {
            onClickSound(option);
          }}
        >
          <div className="h-6 w-6">{getIcon(option)}</div>

          <div
            className={classNames(
              "default-font-size h-5",
              "w-24 h-5",
              "mt-0.5",
              option === NO_SOUND ? "ml-2" : "ml-3"
            )}
          >
            {option}
          </div>

          <div
            className={classNames(
              "border-width-1px border-solid flex items-center justify-center text-center rounded",
              "w-5 h-5",
              sound === option ? "border-white" : "border-gray-400"
            )}
          >
            <div
              className={classNames("h-4 font-size-12", sound === option ? "text-white" : "text-gray-400")}
              style={{ lineHeight: "18px" }}
            >
              {SOUND_HOT_KEYS[option] ?? "0"}
            </div>
          </div>
        </div>
      );
    };

    if (!showMoreOptions) {
      return null;
    }

    return (
      <div
        className={classNames("flex select-none p-2", "large-blur rounded-lg")}
      >
        <div className="mr-2">
          {SOUND_OPTIONS.map((option, index) => {
            return (
              <div
                key={`sound-options-${option}`}
                className={index !== SOUND_OPTIONS.length - 1 ? "mb-2" : ""}
              >
                {renderOptionRow(option)}
              </div>
            );
          })}
        </div>

        <div>
          {SOUND_OPTIONS_2.map((option, index) => {
            return (
              <div
                key={`sound-options-${option}`}
                className={index !== SOUND_OPTIONS_2.length - 1 ? "mb-2" : ""}
              >
                {renderOptionRow(option)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const modalCover = () => {
    if (!showMoreOptions) {
      return null;
    }

    return (
      <div
        onClick={() => setShowMoreOptions(false)}
        className="fixed top-0 left-0 right-0 z-10 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
      ></div>
    );
  };

  return (
    <div 
      id={isSoundPlaying() ? FOCUS_MODE_SOUND_ON : FOCUS_MODE_SOUND_OFF}
      className={classNames(containerClassName ?? "")}
    >
      <div className="absolute bottom-14 right-0 z-20">
        {renderAllSoundOptions()}
      </div>
      {soundDropDown()}
      {modalCover()}
    </div>
  );
}
