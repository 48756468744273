import { ZOOM_MEETING_BY_ZOOM_ID } from "../../lib/endpoints";
import { constructRequestURL, isErrorResponse } from "../../services/api";
import Fetcher from "../../services/fetcher";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";

export async function fetchZoomMeetingByZoomId(email: string, zoomId: number) {
  const url = constructRequestURL(`${ZOOM_MEETING_BY_ZOOM_ID}/${zoomId}`, true);

  const response = await Fetcher.get<ZoomMeeting | ErrorResponse>(
    url,
    {},
    true,
    email,
  );

  if (isEmptyObjectOrFalsey(response)) {
    return null;
  }

  if (isErrorResponse(response)) {
    return null;
  }

  return response;
}

export async function patchZoomMeetingByZoomId(
  email: string,
  zoomId: number,
  updatedSettings: DeepPartial<ZoomMeeting> & { schedule_for: string },
) {
  const url = constructRequestURL(`${ZOOM_MEETING_BY_ZOOM_ID}/${zoomId}`, true);
  const payloadData = {
    body: JSON.stringify({
      meeting_details: updatedSettings,
    }),
  };

  const response = await Fetcher.patch<ZoomMeeting | ErrorResponse>(
    url,
    payloadData,
    true,
    email,
  );

  if (isEmptyObjectOrFalsey(response)) {
    return null;
  }

  if (isErrorResponse(response)) {
    return null;
  }

  return response;
}
