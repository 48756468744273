import React, { Component } from "react";
import { connect } from "react-redux";
import Classnames from "classnames";
import {
  minDifferentBetweenTimeZones,
  getFirstDayOfWeekJsDate,
  isOnboardingMode,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import { DATE_TIME_12_HOUR_FORMAT, DATE_TIME_24_HOUR_FORMAT } from "../services/googleCalendarService";
import { set, format, addMinutes } from "date-fns";
import {
  getAllTimeZonesInGutter,
  getMostLeftHandTimeZone,
  getOrderedPermanentTimeZoneList,
} from "../lib/stateManagementFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { useAppTimeZones } from "../services/stores/appFunctionality";
import classNames from "classnames";
import { isStartOfDay, isSameDateTimeOptimized, isSameDayOptimized } from "../lib/dateFunctions";
import { useOOOBusyEventsDictionaryStore } from "../services/stores/eventsData";
import { isNullOrUndefined } from "../services/typeGuards";
import { shouldAddExtraWidthToGutter } from "../services/appFunctions";
import { useTemporaryTimeZonesStore } from "../services/stores/temporaryStateStores";

const HOUR_FORMAT = "h a";

class TimeSlotWrapper extends Component {
  constructor(props) {
    super(props);

    const isStartOfHour =
      props.resource === undefined && props.value.getMinutes() === 0;
    this.state = {
      isStartOfHour,
      isOnboardingMode: isOnboardingMode(),
    };

    this.scrollToTime = this.scrollToTime.bind(this);

    if (isStartOfHour) {
      Broadcast.subscribe(
        `SCROLL_TO_HOUR_${this.props.value.getHours()}`,
        this.scrollToTime,
      );
    }
  }

  componentWillUnmount() {
    if (this.state.isStartOfHour) {
      Broadcast.unsubscribe(`SCROLL_TO_HOUR_${this.props.value.getHours()}`);
    }
  }

  shouldComponentUpdate(newProps, newState) {
    if (this.props.isDarkMode !== newProps.isDarkMode) {
      return true;
    }
    if (this.state.isStartOfHour &&
      this.props.temporaryTimeZonesStore.hoveredTimeZone !== newProps.temporaryTimeZonesStore.hoveredTimeZone
    ) {
      return true;
    }

    if (this.isGrid()) {
      if (this.props.value !== newProps.value && !isSameDateTimeOptimized(this.props.value, newProps.value)) {
        return true;
      }
      if (this.props.temporaryTimeZones !== newProps.temporaryTimeZones) {
        return true;
      }
      if (this.props.OOOBusyEventsDictionaryStore.OOOBusyEventsColorAndRange !== newProps.OOOBusyEventsDictionaryStore.OOOBusyEventsColorAndRange) {
        return true;
      }
    }

    if (this.props.resource || this.isGrid()) {
      // https://github.com/jquense/react-big-calendar/issues/186
      // resource: null /* grid */ | undefined /* gutter */
      return false;
    }

    if (
      this.props.selectedDay &&
      newProps.selectedDay &&
      !isSameDayOptimized(this.props.selectedDay, newProps.selectedDay)
    ) {
      return true;
    } else if (this.props.weekStart !== newProps.weekStart) {
      return true;
    } else if (
      this.state.isStartOfHour &&
      this.props.currentTimeZoneLabel !== newProps.currentTimeZoneLabel
    ) {
      return true;
    } else if (
      this.state.isStartOfHour &&
      this.props.currentTimeZone !== newProps.currentTimeZone
    ) {
      return true;
    } else if (this.props.anchorTimeZones !== newProps.anchorTimeZones) {
      return true;
    } else if (this.props.temporaryTimeZones !== newProps.temporaryTimeZones) {
      return true;
    } else if (this.props.isMobileView !== newProps.isMobileView) {
      return true;
    } else if (
      this.props.masterAccount.masterAccount !==
      newProps.masterAccount.masterAccount
    ) {
      return true;
    } else if (this.props.format24HourTime !== newProps.format24HourTime) {
      return true;
    } else if (
      this.props.appTimeZone.lastSelectedTimeZone !==
      newProps.appTimeZone.lastSelectedTimeZone
    ) {
      return true;
    } else if (
      this.props.defaultBrowserTimeZone !== newProps.defaultBrowserTimeZone
    ) {
      return true;
    } else if (
      this.props.appTimeZone.orderedTimeZones !==
      newProps.appTimeZone.orderedTimeZones
    ) {
      return true;
    } else if (this.props.currentUser !== newProps.currentUser) {
      return true;
    }
    return false;
  }

  render() {
    if (!this.state.isStartOfHour) {
      // default for other slots
      return this.renderDefaultChildren();
    }

    if (
      (this.props.isMobileView || this.state.isOnboardingMode) &&
      this.state.isStartOfHour
    ) {
      // one time zone
      return (
        <div
          ref={(input) => {
            this.timeSlotWrapper = input;
          }}
          className={this.props.children.props.className}
        >
          {this.renderSingleTimeZone()}
        </div>
      );
    }

    if (
      this.state.isStartOfHour &&
      (this.getAllPermanentTimeZones().length > 0 ||
        this.props.temporaryTimeZones?.length > 0)
    ) {
      return (
        <div
          ref={(input) => {
            this.timeSlotWrapper = input;
          }}
        >
          {this.renderAdditionalTimeZones()}
        </div>
      );
    }

    if (this.state.isStartOfHour) {
      // one time zone
      return (
        <div
          ref={(input) => {
            this.timeSlotWrapper = input;
          }}
          className={this.props.children.props.className}
        >
          {this.renderSingleTimeZone()}
        </div>
      );
    }

    return this.renderDefaultChildren();
  }

  renderDefaultChildren() {
    if (this.isGrid() && this.props.children.props.style) {
      return (
        <div
          ref={(input) => {
            this.timeSlotWrapper = input;
          }}
          className={this.props.children.props.className}
          style={this.props.children.props.style} // we only want to show style if it's part of grid
        >
          {this.props.children}
        </div>
      );
    }
    return (
      <div
        ref={(input) => {
          this.timeSlotWrapper = input;
        }}
        className={this.props.children.props.className}
      >
        {this.props.children}
      </div>
    );
  }

  getLeftHandTimeZone() {
    const { lastSelectedTimeZone } = this.props.appTimeZone;
    const { defaultBrowserTimeZone, temporaryTimeZones } = this.props;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone,
      temporaryTimeZones,
    });
  }

  renderSingleTimeZone() {
    return (
      <div
        className={classNames(
          "display-flex-center time-slot-time rbc-time-slot rbc-label single-time-zone",
          this.isStartOfDay() ? "mt-2" : ""
        )}
      >
        {format(this.setDayToSelectedDay(), this.determineTimeFormat())}
      </div>
    );
  }

  isStartOfDay() {
    return isStartOfDay(this.props.value);
  }

  renderSelectTimeZoneOverlay(timeZone) {
    if (this.getAllTimeZones()?.length <= 1) {
      return null;
    }
    const {
      currentTimeZone,
    } = this.props;
    if (timeZone === currentTimeZone) {
      return null;
    }
    const { setHoveredTimeZone } = this.props.temporaryTimeZonesStore;
    return (
      <div
        className="absolute w-full h-24 cursor-pointer"
        onClick={() => {
          setHoveredTimeZone(null);
          Broadcast.publish("SELECT_TIME_ZONE", {
            timeZone,
            isToggleTimeZone: true,
          });
        }}
        onMouseEnter={() => {
          setHoveredTimeZone(timeZone);
        }}
        onMouseLeave={() => {
          setHoveredTimeZone(null);
        }}
      >
      </div>
    );
  }

  getTimeZoneTextColor({timeZone, isCurrentTimeZone}) {
    const { hoveredTimeZone } = this.props.temporaryTimeZonesStore;
    if (hoveredTimeZone === timeZone) {
      return "default-font-color"; // default font color
    }
    return !isCurrentTimeZone ? "light-grey-text dimmed-text" : "";
  }

  renderLeftGutterHourLabel({
    time,
    isCurrentTimeZone,
    shouldDisplayMinutes,
    additionalClassNames = null,
    key,
    timeZone,
  }) {
    const { currentTimeZone } = this.props;
    return (
      <div
        className={Classnames(
          "display-flex-center",
          "multiple-time-zone-abbreviated-text-gutter",
          shouldAddExtraWidthToGutter({ timeZone, currentTimeZone }) ? "extra-width-slot-wrapper" : "",
          "rbc-label",
          this.getTimeZoneTextColor({timeZone, isCurrentTimeZone}),
          additionalClassNames || "",
          "duration-200",
          this.isStartOfDay() ? "mt-2" : "",
          "relative",
        )}
        key={key}
      >
        {this.renderSelectTimeZoneOverlay(timeZone)}
        {format(
          time,
          this.determineTimeFormatForMultipleTimeZone(
            isCurrentTimeZone,
            shouldDisplayMinutes
          )
        )}
      </div>
    );
  }

  renderRightGutterHourLabel({
    time,
    isCurrentTimeZone,
    shouldDisplayMinutes,
    key,
    additionalClassNames = "",
    timeZone,
  }) {
    const { currentTimeZone } = this.props;
    return (
      <div
        className={Classnames(
          "display-flex-center",
          "multiple-time-zone-abbreviated-text-gutter",
          shouldAddExtraWidthToGutter({ timeZone, currentTimeZone }) ? "extra-width-slot-wrapper" : "",
          "rbc-label",
          this.getTimeZoneTextColor({timeZone, isCurrentTimeZone}),
          additionalClassNames,
          "duration-200",
          this.isStartOfDay() ? "mt-2" : "",
          "relative",
        )}
        key={`time_zone_index_${key || ""}`}
      >
        {this.renderSelectTimeZoneOverlay(timeZone)}
        {format(
          time,
          this.determineTimeFormatForMultipleTimeZone(
            isCurrentTimeZone,
            shouldDisplayMinutes
          )
        )}
      </div>
    );
  }

  getAllTimeZones() {
    const { temporaryTimeZones } = this.props;
    const permanentTimeZones = this.getAllPermanentTimeZones();
    return getAllTimeZonesInGutter({
      permanentTimeZones,
      temporaryTimeZones,
    });
  }

  renderAdditionalTimeZones() {
    const allTimeZones = this.getAllTimeZones();
    return (
      <div className={Classnames("flex justify-around items-center", "gap-1")}>
        {allTimeZones.map((tz, index) => {
          const { shouldDisplayMinutes, otherTime } =
            this.determineShouldDisplayMinute(tz, this.props.currentTimeZone);
          if (index === 0) {
            return this.renderLeftGutterHourLabel({
              time: otherTime,
              isCurrentTimeZone: tz === this.props.currentTimeZone,
              shouldDisplayMinutes,
              key: `${tz}-${index}`,
              timeZone: tz,
            });
          }

          return this.renderRightGutterHourLabel({
            time: otherTime,
            isCurrentTimeZone: tz === this.props.currentTimeZone,
            shouldDisplayMinutes,
            key: `${tz}-${index}`,
            timeZone: tz,
          });
        })}
      </div>
    );
  }

  determineShouldDisplayMinute(firstTimeZone, secondTimeZone) {
    let minsDiff = minDifferentBetweenTimeZones(
      firstTimeZone,
      secondTimeZone,
      this.setDayToSelectedDay()
    );

    const otherTime = addMinutes(this.props.value, minsDiff);
    return { shouldDisplayMinutes: (minsDiff / 60) % 1 !== 0, otherTime };
  }

  determineTimeFormatForMultipleTimeZone(
    isCurrentTimeZone,
    shouldDisplayMinutes
  ) {
    if (!isCurrentTimeZone && shouldDisplayMinutes) {
      if (this.props.format24HourTime) {
        return DATE_TIME_24_HOUR_FORMAT;
      } else {
        return DATE_TIME_12_HOUR_FORMAT;
      }
    } else {
      if (this.props.format24HourTime) {
        return DATE_TIME_24_HOUR_FORMAT;
      } else {
        return HOUR_FORMAT;
      }
    }
  }

  scrollToTime(hardScroll = false) {
    if (!this.timeSlotWrapper) {
      return;
    }

    if (hardScroll) {
      this.timeSlotWrapper.scrollIntoView();
    } else {
      this.timeSlotWrapper.scrollIntoView({ behavior: "smooth" });
    }
  }

  setDayToSelectedDay() {
    const {
      weekStart,
      selectedDay,
      value,
    } = this.props;
    return set(
      getFirstDayOfWeekJsDate(
        selectedDay || new Date(),
        weekStart,
      ),
      {
        hours: value.getHours(),
        minutes: value.getMinutes(),
      }
    );
  }

  determineTimeFormat() {
    return this.props.format24HourTime ? DATE_TIME_24_HOUR_FORMAT : HOUR_FORMAT;
  }

  shouldPrint() {
    // For dev
    const {
      value,
    } = this.props;
    if (!value) {
      return false;
    }
    return value.getHours() === 10
      && value.getMinutes() === 0;
  }

  getAllPermanentTimeZones() {
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;
    const { orderedTimeZones } = this.props.appTimeZone;
    return getOrderedPermanentTimeZoneList({
      masterAccount,
      currentUser,
      orderedTimeZones,
    });
  }

  isGrid() {
    return this.props.resource === null || !isNullOrUndefined(this.props.resource);
  }

  isGutter() {
    return this.props.resource === undefined;
  }
}

function mapStateToProps(state) {
  let {
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    format24HourTime,
    selectedDay,
    weekStart,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    currentUser,
    isDarkMode,
  } = state;

  return {
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    format24HourTime,
    selectedDay,
    weekStart,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    currentUser,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const appTimeZone = useAppTimeZones();
  const OOOBusyEventsDictionaryStore = useOOOBusyEventsDictionaryStore();
  const temporaryTimeZonesStore = useTemporaryTimeZonesStore();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      appTimeZone={appTimeZone}
      OOOBusyEventsDictionaryStore={OOOBusyEventsDictionaryStore}
      temporaryTimeZonesStore={temporaryTimeZonesStore}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(TimeSlotWrapper));
