import _ from "underscore";
import {
  createKeyFromSlotISOString,
  getCriticalAttendees,
  getSpreadsheetAttendees,
} from "../../../lib/availabilityFunctions";
import { isEmptyArray } from "../../../lib/arrayFunctions";
import { lowerCaseAndTrimString } from "../../../lib/stringFunctions";
import { createUUID } from "../../../services/randomFunctions";
import { getObjectEmail } from "../../../lib/objectFunctions";

const SAMPLE_DATA = {
  title: "sample data",
  location: "",
  time_zone: "America/New_York",
  calendar_provider_id: "mike@vimcal.com",
  google_calendar_id: "mike@vimcal.com",
  token: "9b9d7d04c95cde96244e6b42",
  conferencing: "zoom",
  selected_slots: [
    {
      end: "2024-05-24T14:00:00.000Z",
      start: "2024-05-24T11:00:00.000Z",
    },
    {
      end: "2024-05-29T14:00:00.000Z",
      start: "2024-05-29T11:00:00.000Z",
    },
    {
      end: "2024-05-28T14:00:00.000Z",
      start: "2024-05-28T11:00:00.000Z",
    },
    {
      endDate: "2024-05-27",
      startDate: "2024-05-26",
    },
  ],
  attendees: [
    {
      name: "NY on table",
      email: "lkasjdkal@yahoo.com",
      uuid: "27be12ec-6665-4986-9bf9-f57d617abf9c",
      slots: [
        {
          end: "2024-05-28T14:00:00.000Z",
          start: "2024-05-28T11:00:00.000Z",
          selected: true,
          comment: "Will be in car",
        },
        {
          end: "2024-05-29T14:00:00.000Z",
          start: "2024-05-29T11:00:00.000Z",
          selected: true,
        },
        {
          endDate: "2024-05-27",
          startDate: "2024-05-26",
          selected: false,
          comment: "OOO",
        },
      ],
    },
    {
      email: "onlyemail@email.com",
      uuid: "27be12ec-6665-4986-9bf9-f57d617abf10",
      slots: [
        {
          end: "2024-05-24T14:00:00.000Z",
          start: "2024-05-24T11:00:00.000Z",
          selected: true,
          comment: "phone only"
        },
        {
          end: "2024-05-28T14:00:00.000Z",
          start: "2024-05-28T11:00:00.000Z",
          selected: false,
        },
        {
          endDate: "2024-05-27",
          startDate: "2024-05-26",
          selected: true,
        },
        {
          end: "2024-05-29T14:00:00.000Z",
          start: "2024-05-29T11:00:00.000Z",
          selected: true,
        }
      ],
    },
  ],
  description: "",
  duration: null,
  slug: "-7am-10am-24---7-10am-25----26",
};

const SAMPLE_MASTER_ACCOUNT = {
  first_name: "Michael",
  full_name: "Michael Zhao",
};

export const SAMPLE_BACKEND_GROUP_VOTE_SPRSHEET_RESPONSE = {
  group_spreadsheet_link: {
    ...SAMPLE_DATA,
    ...SAMPLE_MASTER_ACCOUNT,
    profile_photo_url:
      "https://vimcal-profile-photos.s3.amazonaws.com/Michael-Zhao-1707874429377.png",
    social_links: [
      {
        social_network_name: "linkedin",
        url: "https://www.linkedin.com/in/michaelwzhao/",
      },
      {
        social_network_name: "twitter",
        url: "https://twitter.com/@mikeyyyzhao",
      },
      {
        social_network_name: "website",
        url: "https://vimcal.com",
      },
    ],
    time_zone: "America/New_York",
    title: "(7am-10am)24 + 7-10am(25) + 26",
    token: "9b9d7d04c95cde96244e6b42",
    user: {
      email: "mike@vimcal.com",
      first_name: "Michael",
      full_name: "Michael Zhao",
    },
  },
};

// Mock data for what we pass into the backend
export const MOCK_INPUT_RESPONSE = {
  responses: {
    edited_attendees: [
      {
        uuid: createUUID(),
        name: "Bob the Builder",
        email: "bob@vimcal.com",
        slots: [
          {
            startDate: "2024-04-27",
            endDate: "2024-04-29",
            selected: true,
            comment: "Will be available to build via Zoom",
          },
        ],
      },
    ],
    deleted_attendees: [createUUID()],
  },
};

// {uuid_slot: {slotTimes: comment}}
export const createCommentDictionary = (bookingLink) => {
  const attendees = getSpreadsheetAttendees(bookingLink);
  const commentDictionary = {};
  attendees.forEach((a) => {
    const uuid = getAttendeeUUID(a);
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((slot) => {
      const key = createKeyFromSlotISOString(slot);
      const comment = slot.comment;
      if (!comment) {
        return;
      }

      if (!commentDictionary[uuid]) {
        commentDictionary[uuid] = {
          [key]: comment,
        };
        return;
      }
      commentDictionary[uuid][key] = comment;
    });
  });

  return commentDictionary;
};

export function getAttendeeEmail(attendee) {
  try {
    return lowerCaseAndTrimString(attendee?.email);
  } catch (e) {
    return "";
  }
}

export function getAttendeeUUID(attendee) {
  return attendee?.uuid;
}

export function createGroupVoteSpreadsheetSlotAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getSpreadsheetAttendees(bookingLink);
  let slotAttendeeIndex = {};

  attendees.forEach((a) => {
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((s) => {
      if (!s.selected) {
        // only track selected
        return;
      }
      const key = createKeyFromSlotISOString(s);
      if (!slotAttendeeIndex[key]) {
        slotAttendeeIndex[key] = [getAttendeeUUID(a)];
      } else {
        slotAttendeeIndex[key] = slotAttendeeIndex[key].concat(
          getAttendeeUUID(a)
        );
      }
    });
  });

  return slotAttendeeIndex;
}

export function determineSpreadsheetCriticalAttendeeIndex({ attendeeList, bookingLink}) {
  // get which attendee clicked on which slot
  const criticalAttendees = getCriticalAttendees(bookingLink);
  let slotAttendeeIndex = {};

  attendeeList.filter((a) => criticalAttendees.includes(getObjectEmail(a))).forEach((a) => {
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((s) => {
      if (!s.selected) {
        // only track selected
        return;
      }
      const key = createKeyFromSlotISOString(s);
      if (!slotAttendeeIndex[key]) {
        slotAttendeeIndex[key] = [getAttendeeUUID(a)];
      } else {
        slotAttendeeIndex[key] = slotAttendeeIndex[key].concat(
          getAttendeeUUID(a),
        );
      }
    });
  });

  return slotAttendeeIndex;
}

// attendee:
// {
//   "name": "NY on table",
//   "email": "lkasjdkal@yahoo.com",
//   "uuid": "27be12ec-6665-4986-9bf9-f57d617abf9c",
//   "slots": [
//       {
//           "end": "2024-04-28T14:00:00.000Z",
//           "start": "2024-04-28T11:00:00.000Z",
//           "selected": true,
//           "comment": "Will be in car"
//       },
//       {
//           "end": "2024-04-25T14:00:00.000Z",
//           "start": "2024-04-25T11:00:00.000Z",
//           "selected": true
//       },
//       {
//           "endDate": "2024-4-27",
//           "startDate": "2024-4-26",
//           "selected": false,
//           "comment": "OOO"
//       }
//   ]
// }
export function onToggleGroupVoteSpreadsheetSlot({
  slot,
  attendeeList,
  attendee,
  isSelected,
}) {
  const doesAttendeeSlotIncludeSlot = (inputAttendee) => {
    return inputAttendee.slots.some(
      (s) => createKeyFromSlotISOString(s) === createKeyFromSlotISOString(slot)
    );
  };
  return attendeeList.map((a) => {
    if (getAttendeeUUID(a) !== getAttendeeUUID(attendee)) {
      return a;
    }
    if (!doesAttendeeSlotIncludeSlot(a)) {
      const addedSlot = _.omit(slot, ["eventStart", "eventEnd"]);
      return {
        ...a,
        slots: a.slots.concat({
          ...addedSlot,
          selected: isSelected,
        }),
      };
    }
    const updatedSlots = a.slots.map((s) => {
      if (createKeyFromSlotISOString(s) !== createKeyFromSlotISOString(slot)) {
        return s;
      }
      return {
        ...s,
        selected: isSelected,
      };
    });
    return {
      ...a,
      slots: updatedSlots,
    };
  });
}

export function onUpdateGroupVoteSpreadsheetSlotComment({
  slot,
  attendeeList,
  attendee,
  comment,
}) {
  const doesAttendeeSlotIncludeSlot = (inputAttendee) => {
    return inputAttendee.slots.some(s => createKeyFromSlotISOString(s) === createKeyFromSlotISOString(slot));
  }
  return attendeeList.map((a) => {
    if (getAttendeeUUID(a) !== getAttendeeUUID(attendee)) {
      return a;
    }
    if (!doesAttendeeSlotIncludeSlot(a)) {
      const addedSlot = _.omit(slot, ["eventStart", "eventEnd"]);
      return {
        ...a,
        slots: a.slots.concat({
          ...addedSlot,
          comment,
        }),
      }
    }
    const updatedSlots = a.slots.map((s) => {
      if (createKeyFromSlotISOString(s) !== createKeyFromSlotISOString(slot)) {
        return s;
      }
      return {
        ...s,
        comment,
      }
    });
    return {
      ...a,
      slots: updatedSlots,
    }
  })
}
