import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import useInterval from "../../services/customHooks/useInterval";
import { SECOND_IN_MS } from "../../services/globalVariables";
import { createUUID } from "../../services/randomFunctions";
import ButtonWrapper from "./buttonWrapper";
import { getSlidePosition, SLIDES_ON_LEFT } from "./carouselFunctions";

interface CarouselProps {
  gap?: number
  slideSize: { width: number, height: number }
  slides: React.ReactNode[]
}

/**
 * This is a very minimal carousel built to avoid needing external libraries.
 *
 * This carousel works by rendering two copies of every slide and transforming each
 * of them individually. This allows the slides to rendered in a constant order in the
 * DOM. If elements were reordered, we would need to have some transitions that happen
 * instantaneously while others happen over a duration, which adds complexity.
 *
 * Limitations:
 * - Each slide must be the same width and height.
 * - The slide width and height must be explicitly provided.
 * - The slides should be memoized. If this value changes, the carousel will be reset.
 * - No click and drag to change slides.
 * - There should be enough slides to fill the width of the container even when 4 slides
 *   are off-screen. If there aren't enough slides, duplicate the elements in the slides
 *   array.
 */
export default function Carousel({
  gap=0,
  slides,
  slideSize,
}: CarouselProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [slides]);

  const nextSlide = useCallback(() => setSelectedIndex(current => current + 1), []);
  const prevSlide = useCallback(() => setSelectedIndex(current => current - 1), []);

  const { resetTimer } = useInterval({ callback: nextSlide, timeout: 3 * SECOND_IN_MS });

  const slidesWithUUIDs = useMemo(() => (
    slides.map(slide => ({ key: createUUID(), slide }))
  ), [slides]);

  const slideStyles: React.CSSProperties[] = slidesWithUUIDs.map((_, index) => {
    const position = getSlidePosition(index, selectedIndex, slidesWithUUIDs.length);
    const transform = position * (slideSize.width + gap) + gap;
    const isFirstOrLast = position === -1 * SLIDES_ON_LEFT || position === slidesWithUUIDs.length - SLIDES_ON_LEFT - 1;
    return {
      transform: `translateX(${transform}px)`,
      transitionDuration: isFirstOrLast ? "0ms" : undefined,
    };
  });

  return (
    <>
      <div
        className="relative overflow-hidden mt-8 py-4 box-content"
        style={{ height: slideSize.height }}
      >
        {slidesWithUUIDs.map(({ key, slide }, index) => (
          <div
            key={key}
            className="absolute top-0 left-0 transition-transform duration-300"
            style={{ ...slideStyles[index], ...slideSize }}
          >
            {slide}
          </div>
        ))}
      </div>
      <div className="flex justify-center gap-6 mt-3.5 mb-2.5">
        <ButtonWrapper
          IconComponent={ChevronLeft}
          onClick={() => {
            prevSlide();
            resetTimer();
          }}
        />
        <ButtonWrapper
          IconComponent={ChevronRight}
          onClick={() => {
            nextSlide();
            resetTimer();
          }}
        />
      </div>
    </>
  );
}
