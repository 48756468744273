import React, { Component } from "react";
import ReactSelectAttendeeAutoComplete from "./reactSelectAttendeeAutoComplete";
import classNames from "classnames";
import { Copy, X } from "react-feather";
import { connect } from "react-redux";

import { trackEvent, trackReferral } from "./tracking";
import ProfilePictureWithName from "./profilePictureWithName";
import { constructRequestURL } from "../services/api";
import Broadcast from "../broadcasts/broadcast";
import { type MasterAccountState, useMasterAccount } from "../services/stores/SharedAccountData";
import ColoredLine from "./line";
import { BLUE_BUTTON, WHITE_BUTTON } from "../services/globalVariables";
import { isUserMaestroUser } from "../services/maestroFunctions";
import {
  type ReferralState,
  useReferralStore,
} from "../services/stores/appFunctionality";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../lib/vimcalVariables";
import { isSameEmail, isValidEmail } from "../lib/stringFunctions";
import { copyContent } from "../services/appFunctions";
import { getAccountReferralCode, getUserToken } from "../lib/userFunctions";
import type { SelectOptionType } from "./select";
import { KEYBOARD_EVENT_KEYS } from "../services/keyboardEventFunctions";
import CustomButtonV2 from "./buttons/customButtonV2";

interface ReferToVimcalProps {
  cancelButtonTextLabel?: string
  closeModal: () => void
  hideEmailSection?: boolean
  where?: string

  /** The following props are never passed to this component. */
  defaultEmptyStateMessage?: string
  emailSectionLabel?: string
  hideReferralSection?: boolean
  onClickSave?: (selectedGuests: SelectOptionType[]) => void
  onSet?: (selectedGuests: SelectOptionType[]) => void
  saveButtonText?: string
}

interface ReferToVimcalZustandProps {
  masterAccount: MasterAccountState
  referralStore: ReferralState
}

interface ReferToVimcalReduxProps {
  currentUser: User
  isMac: boolean
}

type AllReferToVimcalProps = ReferToVimcalProps & ReferToVimcalZustandProps & ReferToVimcalReduxProps

interface ReferToVimcalState {
  referCopied: boolean
  referral_url: string
  selectedGuests: SelectOptionType[]
}

const withZustandHooks = (Component: typeof ReferToVimcal) => {
  return function WrappedComponent(props: ReferToVimcalProps & ReferToVimcalReduxProps) {
    const masterAccount = useMasterAccount();
    const referralStore = useReferralStore();
    return (
      <Component
        {...props}
        masterAccount={masterAccount}
        referralStore={referralStore}
      />
    );
  };
};

class ReferToVimcal extends Component<AllReferToVimcalProps, ReferToVimcalState> {
  constructor(props: AllReferToVimcalProps) {
    super(props);

    const {
      masterAccount,
    } = this.props.masterAccount;
    const referralCode = getAccountReferralCode(masterAccount);
    this.state = {
      referCopied: false,
      referral_url: `https://vimcal.com/?referral_code=${referralCode}`,
      selectedGuests: [],
    };

    this.addContact = this.addContact.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    // can't use mousetrap -> if focused on input -> mousetrap won't work
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidMount() {
    trackReferral({
      user: this.props.currentUser,
      action: "opened_referral",
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  render() {
    return (
      <div>
        {this.renderContent()}

        <div className="mt-14 flex gap-2.5 justify-end w-full">
          {this.props.cancelButtonTextLabel ? (
            <CustomButtonV2
              buttonType={WHITE_BUTTON}
              label={this.props.cancelButtonTextLabel}
              onClick={this.props.closeModal}
            />
          ) : null}

          {this.props.hideEmailSection ? null : (
            <CustomButtonV2
              buttonType={BLUE_BUTTON}
              label={this.props.saveButtonText || "Send Referral"}
              onClick={this.onClickSave}
              disabled={this.state.selectedGuests.length === 0}
            />
          )}
        </div>
      </div>
    );
  }

  createSelectedGuestsEmailArray() {
    return this.state.selectedGuests.map((a) => {
      return a.value;
    });
  }

  renderRecommendationList() {
    return this.state.selectedGuests.map((guest, index) => {
      return (
        <div
          key={`refer_recommendation_list_${index}`}
          className="refer-name-section display-flex-flex-direction-row"
          style={{ alignItems: "center" }}
        >
          <div
            className="display-flex-flex-direction-row"
            style={{ alignItems: "center" }}
          >
            <ProfilePictureWithName
              name={guest.label || guest.value}
              className="list-of-search-email-icon"
              style={{
                borderWidth: "2px",
                borderColor: "#6a707d",
                borderStyle: "solid",
                backgroundColor: "#6a707d",
                color: "white",
                fontWeight: "400",
                fontSize: 12,
                height: 25,
                width: 25,
                marginRight: 10,
                paddingLeft: 1,
                paddingTop: 1,
              }}
            />

            <div>{guest.__isNew__ ? guest.value : guest.label}</div>
          </div>

          <div
            onClick={() => this.removeEmail(guest.value)}
            className="delete-referral-guest h-4"
          >
            <X className="clickable-icon" size={16} />
          </div>
        </div>
      );
    });
  }

  renderContent() {
    return (
      <div>
        {this.props.hideReferralSection
          ? null
          : this.renderReferralLinkSection()}

        {this.props.hideEmailSection || this.props.hideReferralSection ? null : (
          <div className="flex justify-between items-center my-5">
            <ColoredLine />
            <div
              className={classNames(
                "default-font-size",
                "mx-5 w-max",
                "secondary-text-color",
              )}
            >
              OR
            </div>
            <ColoredLine />
          </div>
        )}

        {this.props.hideEmailSection ? null : (
          <>
            <div className="refer-input-label margin-top-0px-override">
              {this.props.emailSectionLabel ?? "Email Referral"}
            </div>
            <ReactSelectAttendeeAutoComplete
              className="refer-select-contact"
              shouldAutoFocus={true}
              addAttendees={this.addContact}
              defaultText="Enter name or email"
              componentLocation={REACT_ATTENDEE_SELECT_LOCATION.REFER_MENU}
              hideMenuOnFocus={true}
              selectedGuests={this.createSelectedGuestsEmailArray()}
              includeEmailAndName={true}
              isInModal={true}
            />
            {this.state.selectedGuests.length > 0 ? (
              <>{this.renderEmailsAndSave()}</>
            ) : null}
            <div className="h-32"></div>
          </>
        )}
      </div>
    );
  }

  renderReferralLinkSection() {
    return (
      <>
        <>{this.renderEmptyState()}</>
        <div className="refer-input-label">Referral Link</div>

        <div className="flex items-center">
          <div className="refer-code-copy-wrapper w-max-content-important secondary-border-color rounded-lg cursor-pointer duration-200 flex items-center">
            <div
              className={classNames(
                "default-font-size",
                "mr-4 ml-4",
                "default-font-color",
              )}
            >
              {this.state.referral_url}
            </div>

            <button
              className="refer-code-copy-button default-button border-radius-8px-important"
              onClick={() => {
                copyContent(this.state.referral_url);
                this.setState({ referCopied: true });
              }}
            >
              <Copy size={15} />
            </button>
          </div>

          {this.state.referCopied ? (
            <div className="refer-code-copy-text">Copied!</div>
          ) : null}
        </div>
      </>
    );
  }

  renderEmptyState() {
    const { masterAccount } = this.props.masterAccount;

    if (isUserMaestroUser(masterAccount)) {
      return (
        <div className="default-font-size secondary-text-color pt-2.5">
          We will send you a $100 Amazon gift card for each friend that
          subscribes
        </div>
      );
    }
    const { defaultEmptyStateMessage } = this.props;

    const getNormalUserMessage = () => {
      if (defaultEmptyStateMessage) {
        return defaultEmptyStateMessage;
      }
      const { isInReferBoost } = this.props.referralStore;
      if (isInReferBoost) {
        return "You will both get 2 months free when they become a paying user!";
      }
      return "You will both get 1 month free when they become a paying user!";
    };

    return (
      <div className="default-font-size secondary-text-color pt-2.5">
        {getNormalUserMessage()}
      </div>
    );
  }

  renderEmailsAndSave() {
    return (
      <div
        style={{
          maxHeight: 300,
          overflowY: "visible",
          overflowX: "hidden",
        }}
      >
        {this.renderRecommendationList()}
      </div>
    );
  }

  removeEmail(email: string) {
    const newGuestList = this.state.selectedGuests.filter(
      (g) => g.value.toLowerCase() !== email.toLowerCase(),
    );

    if (this.props.onSet) {
      this.props.onSet(newGuestList);
    }
    this.setState({ selectedGuests: newGuestList });
  }

  addContact(selected: SelectOptionType) {
    if (!selected) {
      return;
    }

    const emailWithoutWhiteSpaces = selected.value ? selected.value.trim() : "";

    if (
      !isValidEmail(emailWithoutWhiteSpaces) ||
      this.state.selectedGuests.some(guest => isSameEmail(guest.value, emailWithoutWhiteSpaces))
    ) {
      return;
    } else {
      trackEvent({
        category: "Refer",
        action: "added_email_to_refer",
        label: `refer_panel_${this.props.where ?? "refer_modal"}`,
        userToken: getUserToken(this.props.currentUser),
      });

      const newGuestList = this.state.selectedGuests.concat(selected);

      if (this.props.onSet) {
        this.props.onSet(newGuestList);
      }
      this.setState({ selectedGuests: newGuestList });
    }
  }

  onClickSave() {
    if (this.state.selectedGuests.length === 0) {
      return;
    }

    trackReferral({
      user: this.props.currentUser,
      action: `sent_referral_from_modal_${this.state.selectedGuests.length}`,
    });

    if (this.props.onClickSave) {
      this.props.onClickSave(this.state.selectedGuests);
      return;
    }

    const referralsData = this.constructReferralsData();

    const path = "referrals/send";
    const url = constructRequestURL(path);
    const payloadData = {
      body: JSON.stringify(referralsData),
    };

    trackEvent({
      category: "Home",
      action: "referral",
      label: "send_referral",
      userToken: getUserToken(this.props.currentUser),
    });

    Broadcast.publish("REFER", {
      url,
      payloadData,
      numberOfReferrals: this.state.selectedGuests.length,
    });

    this.props.closeModal();
  }

  constructReferralsData() {
    return {
      referral: {
        emails: this.state.selectedGuests,
      },
    };
  }

  handleKeyDown(e: KeyboardEvent) {
    const isCommandKeyPressed = this.props.isMac ? e.metaKey : e.ctrlKey;

    if (
      e.key === KEYBOARD_EVENT_KEYS.ENTER &&
      isCommandKeyPressed
    ) {
      this.onClickSave();
    }
  }
}

function mapStateToProps(state: ReferToVimcalReduxProps) {
  const { currentUser, isMac } = state;

  return {
    currentUser,
    isMac,
  };
}

export default connect(mapStateToProps)(withZustandHooks(ReferToVimcal)) as React.FC<ReferToVimcalProps>;
