import React, { PureComponent } from "react";
import CustomButtonBox from "./customButtonBox";
import ShortcutHoverHint from "./shortcutHoverHint";
import GlobalKeyMapTile from "./globalKeyMapTile";
import Broadcast from "../broadcasts/broadcast";
import {
  createAbbreviationForTimeZone,
  hasEventPreventDefault,
  hasStopEventPropagation,
  isOnboardingMode,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import Classnames from "classnames";
import classNames from "classnames";
import {
  getAllExtraTimeZones,
  doTemporaryTimeZonesExist,
  getMostLeftHandTimeZone,
  getOrderedPermanentTimeZoneList,
  getAllTimeZonesInGutter,
} from "../lib/stateManagementFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  SECOND_IN_MS,
  SET_GLOBAL_SHORT_CUT_SUGGESTION,
} from "../services/globalVariables";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import broadcast from "../broadcasts/broadcast";
import { useAppTimeZones } from "../services/stores/appFunctionality";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import DnDGutterHeader from "./calendar/dndGutterHeader";
import { arrayMove } from "@dnd-kit/sortable";
import {
  blurCalendar,
  removeSearchedTimeZone,
  shouldAddExtraWidthToGutter,
  updateMasterAccountSettingsForFrontendAndBackend,
} from "../services/appFunctions";
import { isMaestroUserOnDelegatedAccount } from "../services/maestroFunctions";
import { getDefaultUserTimeZone } from "../lib/settingsFunctions";
import { truncateString } from "../lib/stringFunctions";
import HoverableCircleCancelButton from "./hoverableCircleCancelButton";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";

const BLUE_SELECTED_TIME_ZONE_CLASSNAME =
  "time-zone-gutter-temporary-time-zone-selected";
const BLUE_NOT_SELECTED_TIME_ZONE_CLASSNAME =
  "time-zone-gutter-temporary-time-zone-not-selected";
const CLEAR_SELECTED_TIME_ZONE_CLASSNAME = "time-zone-gutter-button-selected";
const CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME =
  "time-zone-gutter-button-not-selected";

class TimeGutterHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      flashGutterTimeZone: false,
      otherGutterStyles:
        props.currentTimeZoneLabel && props.isMinified ? "mb-7" : "",
      isOnboardingMode: isOnboardingMode(),
    };
    this._dragTimeZoneTimer = null;

    this.resetTimeZone = this.resetTimeZone.bind(this);
    this.setTimeZone = this.setTimeZone.bind(this);
    this.toggleSecondaryTimeZone = this.toggleSecondaryTimeZone.bind(this);
    this.removeGutterFlash = this.removeGutterFlash.bind(this);
    this.addAdditionalStyles = this.addAdditionalStyles.bind(this);
    this.onClickTimeZoneBox = this.onClickTimeZoneBox.bind(this);
    this.flashTimeZoneGutter = this.flashTimeZoneGutter.bind(this);
    this.removeGutterFlash = this.removeGutterFlash.bind(this);
    this.renderIndividualTimeZone = this.renderIndividualTimeZone.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onClickDndTimeZone = this.onClickDndTimeZone.bind(this);
    this.onDragUpdateTimeZone = this.onDragUpdateTimeZone.bind(this);

    Broadcast.subscribe(
      "TOGGLE_SECONDARY_TIME_ZONE_GUTTER_HEADER",
      this.toggleSecondaryTimeZone,
    );
    broadcast.subscribe(
      `${props.screen || ""}SHOW_GUTTER_FLASH`,
      this.flashTimeZoneGutter,
    );
    Broadcast.subscribe(
      `${props.screen || ""}REMOVE_GUTTER_FLASH`,
      this.removeGutterFlash,
    );
    Broadcast.subscribe(
      `${props.screen || ""}ADD_ADDITIONAL_STYLES_TO_GUTTER`,
      this.addAdditionalStyles,
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._dragTimeZoneTimer);
    this._dragTimeZoneTimer = null;
    Broadcast.unsubscribe("TOGGLE_SECONDARY_TIME_ZONE_GUTTER_HEADER");
    broadcast.unsubscribe(`${this.props.screen || ""}SHOW_GUTTER_FLASH`);
    Broadcast.unsubscribe(`${this.props.screen || ""}REMOVE_GUTTER_FLASH`);
    Broadcast.unsubscribe(
      `${this.props.screen || ""}ADD_ADDITIONAL_STYLES_TO_GUTTER`,
    );
  }

  render() {
    if (this.props.hideContent) {
      return null;
    }

    if (this.props.isMobileView) {
      return (
        <div
          className={classNames(
            "time-gutter-header",
            this.state.otherGutterStyles || "",
          )}
        ></div>
      );
    }

    return (
      <div
        className={classNames(
          "time-gutter-header",
          this.state.otherGutterStyles || "",
        )}
      >
        {this.renderCurrentTimeZoneBox()}
        {this.renderTimeZoneLabel()}
      </div>
    );
  }

  renderCurrentTimeZoneBox() {
    return (
      <ShortcutHoverHint
        below
        style={{
          marginLeft: "0px",
          marginTop: "5px",
          width: "max-content",
          zIndex: 1,
        }}
        containerStyle={
          this.state.flashGutterTimeZone ? { zIndex: 1 } : undefined
        }
        title={"Time Travel"}
        shortcut={"Z"}
      >
        <CustomButtonBox
          className={classNames(
            "margin-top-15",
            this.state.flashGutterTimeZone ? "vimcal-purple-glow" : "",
            "hover-container-secondary-text-color",
          )}
          onClick={
            this.props.onClickTimeTravel
              ? this.props.onClickTimeTravel
              : this.onClickTimeZoneBox
          }
        >
          <div
            className={Classnames(
              "gutter-timezone",
              "display-flex-center",
              "default-font-size",
              "font-size-300-important",
              "font-size-12px-override",
              this.state.flashGutterTimeZone
                ? "default-font-color"
                : "secondary-text-color",
            )}
          >
            <GlobalKeyMapTile
              style={{ top: "-19px", left: "-27px", fontWeight: 300 }}
              shortcut={"Z"}
            />

            {createAbbreviationForTimeZone(
              this.props.currentTimeZone,
              this.getSelectedDay(),
            )}
          </div>
        </CustomButtonBox>
      </ShortcutHoverHint>
    );
  }

  onClickTimeZoneBox(e) {
    hasStopEventPropagation(e);
    Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");

    mainCalendarBroadcast.publish(
      SET_GLOBAL_SHORT_CUT_SUGGESTION,
      "Z",
      "change time zone",
    );
  }

  getAllPermanentTimeZones() {
    const { masterAccount } = this.props.masterAccount;
    const { currentUser, temporaryTimeZones } = this.props;
    const { orderedTimeZones } = this.props.appTimeZone;
    if (temporaryTimeZones?.length > 0) {
      return [];
    }
    return getOrderedPermanentTimeZoneList({
      masterAccount,
      currentUser,
      orderedTimeZones,
    });
  }

  getAllTimeZonesInGutter() {
    const { temporaryTimeZones } = this.props;
    const permanentTimeZones = this.getAllPermanentTimeZones();
    return getAllTimeZonesInGutter({
      permanentTimeZones,
      temporaryTimeZones,
    });
  }

  onDragUpdateTimeZone({ oldIndex, newIndex }) {
    const gutterTimeZones = this.getAllTimeZonesInGutter();
    const { currentUser } = this.props;
    const { masterAccount } = this.props.masterAccount;
    const { setOrderedTimeZones } = this.props.appTimeZone;
    blurCalendar();
    const updatedOrderedTimeZones = arrayMove(
      gutterTimeZones,
      oldIndex,
      newIndex,
    );
    setOrderedTimeZones(updatedOrderedTimeZones);

    // below is used so we don't cause lagginess on frontend
    clearTimeout(this._dragTimeZoneTimer);
    this._dragTimeZoneTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      updateMasterAccountSettingsForFrontendAndBackend({
        masterAccount,
        updatedSettings: { ordered_time_zones: updatedOrderedTimeZones },
        user: currentUser,
        isUpdatingExecutiveProfile: isMaestroUserOnDelegatedAccount({
          masterAccount,
          user: currentUser,
        }), // if on delegate user but changing setting for non delegate user
        skipNotification: true,
      });
      // people play with this, set after 5 sec
    }, 5 * SECOND_IN_MS);
  }

  renderTimeZoneLabel() {
    // array of time zones that are visible in the gutter
    const { temporaryTimeZones } = this.props;

    const permanentTimeZones = this.getAllPermanentTimeZones();
    const gutterTimeZones = this.getAllTimeZonesInGutter();

    if (
      this.props.isMobileView ||
      gutterTimeZones.length === 1 || // no need to show gutter if there's only one gutter time zone
      this.state.isOnboardingMode
    ) {
      return null;
    }

    if (isEmptyArrayOrFalsey(temporaryTimeZones)) {
      return (
        <DnDGutterHeader
          items={permanentTimeZones}
          setItems={this.onDragUpdateTimeZone}
          renderIndividualTimeZone={this.renderIndividualTimeZone}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onClick={this.onClickDndTimeZone}
        />
      );
    }
    return (
      <DnDGutterHeader
        items={temporaryTimeZones}
        setItems={({ oldIndex, newIndex }) => {
          blurCalendar();
          const updatedOrderedTimeZones = arrayMove(
            temporaryTimeZones,
            oldIndex,
            newIndex,
          );
          this.props.setTemporaryTimeZones(updatedOrderedTimeZones);
        }}
        renderIndividualTimeZone={this.renderIndividualTimeZone}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onClick={this.onClickDndTimeZone}
      />
    );
  }

  renderIndividualTimeZone({
    timeZone,
    index,
    setNodeRef,
    style,
    attributes,
    listeners,
  }) {
    // we +1 at the top, but need to take in the index of the extra gutter time zones
    const { isDragging } = this.state;
    const { currentTimeZone, isDarkMode } = this.props;
    const leftMostTimeZone = this.getLeftHandTimeZone();
    const isTemporaryTimeZone = leftMostTimeZone !== timeZone && this.isTemporaryTimeZone(timeZone, index - 1);
    const lengthOfAbbreviation = createAbbreviationForTimeZone(timeZone, this.getSelectedDay()).length;
    const getFontSizeOverride = () => {
      if (lengthOfAbbreviation >= 8) {
        // GMT-4:30
        return "font-size-6px-override";
      }
      if (lengthOfAbbreviation >= 7) {
        // GMT-4:30
        return "font-size-7px-override";
      }
      if (lengthOfAbbreviation === 5) {
        // GMT-4
        return "font-size-9px-override";
      }
      if (lengthOfAbbreviation > 3) {
        return "font-size-8px-override";
      }
      return "";
    };

    return (
      <div
        key={`time-zone-gutter-${index || ""}-${timeZone}`}
        className={Classnames(
          isDragging ? "cursor-grab-grabbing" : "cursor-pointer",
          "display-flex-center text-center",
          "position-relative",
          "current-time-zone-label-gutter-header",
          "gutter-label-container",
          shouldAddExtraWidthToGutter({ timeZone, currentTimeZone }) ? "extra-width-gutter-label-container" : "",
          this.getGutterTimeZoneBackgroundColor(timeZone, index - 1),
          this.props.currentTimeZone !== timeZone &&
            !doTemporaryTimeZonesExist(this.props.temporaryTimeZones)
            ? "light-grey-text"
            : "",
          "hoverable-visibility-non-interactable-parent",
          isDragging ? "z-50" : "",
          !isTemporaryTimeZone && currentTimeZone === timeZone && !isDarkMode
            ? "light-mode-tz-gutter-border"
            : "",
          getFontSizeOverride(),
        )}
        onClick={(e) => {
          this.onClickDndTimeZone(timeZone, e);
        }}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {isDragging || timeZone === currentTimeZone ? null : (
          <GlobalKeyMapTile
            shouldHide={this.determineToggleIndex() !== index}
            style={{ top: "-27px", left: "-10px", fontWeight: 300 }}
            shortcut={"'"}
          />
        )}

        {truncateString(
          createAbbreviationForTimeZone(timeZone, this.getSelectedDay()),
          8,
          false,
        )}

        {isTemporaryTimeZone ? this.renderXButton(timeZone) : null}
      </div>
    );
  }
  renderXButton(timeZone) {
    const { currentTimeZone, temporaryTimeZones } = this.props;
    const onClick = (e) => {
      removeSearchedTimeZone({
        timeZone,
        currentTimeZone,
        temporaryTimeZones,
        event: e,
        leftHandTimeZone: this.getLeftHandTimeZone(),
      });
    };
    return <HoverableCircleCancelButton onClick={onClick} />;
  }

  getLeftHandTimeZone() {
    const { lastSelectedTimeZone } = this.props.appTimeZone;
    const { defaultBrowserTimeZone, temporaryTimeZones } = this.props;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone,
      temporaryTimeZones,
    });
  }

  determineToggleIndex() {
    const {
      anchorTimeZones,
      temporaryTimeZones,
      currentUser,
      currentTimeZone,
    } = this.props;
    const leftHandTimeZone = this.getLeftHandTimeZone();
    const { masterAccount } = this.props.masterAccount;

    if (
      !(
        anchorTimeZones?.length > 0 ||
        doTemporaryTimeZonesExist(temporaryTimeZones)
      )
    ) {
      return;
    }

    let allTimeZones = [leftHandTimeZone].concat(
      getAllExtraTimeZones({
        anchorTimeZones,
        temporaryTimeZones,
        defaultTimeZone: getDefaultUserTimeZone({
          masterAccount,
          user: currentUser,
        }),
      }),
    );

    let currentTimeZoneIndex = allTimeZones.indexOf(currentTimeZone);
    let nextTimeZoneIndex = currentTimeZoneIndex + 1;

    if (nextTimeZoneIndex >= allTimeZones.length) {
      return 0;
    }

    return nextTimeZoneIndex;
  }

  toggleSecondaryTimeZone(isReverse = false) {
    const { currentTimeZone } = this.props;
    const allTimeZones = this.getAllTimeZonesInGutter();

    const currentTimeZoneIndex = allTimeZones.indexOf(currentTimeZone);
    let updatedTimeZone =
      allTimeZones[currentTimeZoneIndex + (isReverse ? -1 : 1)];
    if (!updatedTimeZone) {
      updatedTimeZone = isReverse
        ? allTimeZones[allTimeZones.length - 1]
        : allTimeZones[0];
    }

    // still not found -> early return
    if (!updatedTimeZone) {
      return;
    }
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: updatedTimeZone,
      isToggleTimeZone: true,
    });
  }

  setTimeZone() {
    const leftHandTimeZone = this.getLeftHandTimeZone();
    const timeZone = this.props.currentTimeZoneLabel || leftHandTimeZone;
    Broadcast.publish("SELECT_TIME_ZONE", { timeZone, isToggleTimeZone: true });
  }

  resetTimeZone() {
    const leftHandTimeZone = this.getLeftHandTimeZone();
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: leftHandTimeZone,
      keepSecondaryTimeZone: true,
      isToggleTimeZone: true,
    });
  }

  isTemporaryTimeZone(timeZone, index) {
    const { temporaryTimeZones } = this.props;
    if (isEmptyArray(temporaryTimeZones)) {
      return null;
    }
    return temporaryTimeZones.includes(timeZone);
  }

  getGutterTimeZoneBackgroundColor(timeZone, index) {
    const { currentTimeZone, anchorTimeZones, temporaryTimeZones } = this.props;
    const leftHandTimeZone = this.getLeftHandTimeZone();
    const isLeftHandTimeZone = timeZone === leftHandTimeZone;
    const isSelected = timeZone === currentTimeZone;

    if (temporaryTimeZones?.includes(timeZone)) {
      return currentTimeZone === timeZone
        ? BLUE_SELECTED_TIME_ZONE_CLASSNAME
        : BLUE_NOT_SELECTED_TIME_ZONE_CLASSNAME;
    }

    if (isLeftHandTimeZone) {
      if (temporaryTimeZones?.length > 0 && isSelected) {
        return BLUE_SELECTED_TIME_ZONE_CLASSNAME;
      }
      if (isSelected) {
        return CLEAR_SELECTED_TIME_ZONE_CLASSNAME;
      }
      return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
    }

    if (
      !isEmptyArray(anchorTimeZones) &&
      anchorTimeZones.includes(timeZone) &&
      anchorTimeZones[index]
    ) {
      if (isSelected) {
        return CLEAR_SELECTED_TIME_ZONE_CLASSNAME;
      }
      return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
    }

    if (
      !isEmptyArray(temporaryTimeZones) &&
      temporaryTimeZones.includes(timeZone)
    ) {
      if (isSelected) {
        return BLUE_SELECTED_TIME_ZONE_CLASSNAME;
      }
      return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
      // return BLUE_NOT_SELECTED_TIME_ZONE_CLASSNAME;
    }

    if (isSelected) {
      return CLEAR_SELECTED_TIME_ZONE_CLASSNAME;
    }
    return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
  }

  determineSelectedClassName() {
    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      return BLUE_SELECTED_TIME_ZONE_CLASSNAME;
    }

    return CLEAR_SELECTED_TIME_ZONE_CLASSNAME;
  }

  determineNonSelectedClassName() {
    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      return BLUE_NOT_SELECTED_TIME_ZONE_CLASSNAME; // blue
    }

    return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
  }

  determineSelectedClassNameForOnboarding() {
    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      return BLUE_SELECTED_TIME_ZONE_CLASSNAME;
    }

    return CLEAR_SELECTED_TIME_ZONE_CLASSNAME;
  }

  determineNonSelectedClassNameForOnboarding() {
    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      return BLUE_NOT_SELECTED_TIME_ZONE_CLASSNAME;
    }

    return CLEAR_NOT_SELECTED_TIME_ZONE_CLASSNAME;
  }

  addAdditionalStyles(styles) {
    this.setState({ otherGutterStyles: styles });
  }

  onDragStart() {
    this.setState({ isDragging: true });
  }

  onClickDndTimeZone(timeZone, e) {
    hasEventPreventDefault(e);
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone,
      isToggleTimeZone: true,
    });
  }

  onDragEnd() {
    this.setState({ isDragging: false });
  }

  flashTimeZoneGutter() {
    this.setState({ flashGutterTimeZone: true }, () => {
      // this._flashTimeTravelTimeout = setTimeout(() => {
      // if (!this._isMounted) {
      //   return;
      // }
      // this.removeGutterFlash();
      // }, 10 * SECOND_IN_MS);
    });
  }

  removeGutterFlash() {
    this.setState({ flashGutterTimeZone: false });
  }

  getSelectedDay() {
    return getSelectedDayWithBackup(this.props.selectedDay);
  }
}

function mapStateToProps(state) {
  let {
    isMac,
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    selectedDay,
    currentUser,
    isDarkMode,
  } = state;

  return {
    isMac,
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    selectedDay,
    currentUser,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const appTimeZone = useAppTimeZones();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      appTimeZone={appTimeZone}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setWeeklyCalendarToZoom: (day) =>
      dispatch({ data: day, type: "SET_WEEKLY_CALENDAR_TO_ZOOM" }),
    setTemporaryTimeZones: (data) =>
      dispatch({ data: data, type: "SET_TEMPORARY_TIME_ZONES" }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStore(TimeGutterHeader));
