import React, { Component } from "react";
import fetchBroadcast from "../broadcasts/fetchBroadcast";
import {
  convertToTimeZone,
  determineSyncWindow,
  handleError,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isValidJSDate,
  removeDuplicatesFromArray,
  sendErrorToTracking,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { useAccountActivity } from "../services/stores/appFunctionality";
import {
  createCalendarEventsSyncRequestForOutlook,
  createCalendarEventsSyncRequestGoogle,
  createSyncOnlyCalendarRequest,
  formatSyncResponseEventsAndCalendar,
  splitEventsIntoCancelledAndEventsInWindow,
} from "../lib/calendarSyncHelpers";
import {
  isQuotaExceededError,
  isPaidAccessRequiredError,
  getResponseLastSyncAtUTC,
  getOutlookChoppedSyncWindows,
  getBackgroundSyncTimes,
  getCurrentSyncWindow,
  getSelectedDayWithBackup,
  shouldSkipFetchEventsResponse,
  getEntireCurrentSyncWindowForPreviewEvents,
} from "../lib/syncFunctions";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { PERMISSION_MODAL_TYPES, broadcastOpenPermissionModal, isNeedToShowUserConsentScreen, isPermissionsError } from "../lib/authFunctions";
import {
  BACKEND_MONTH,
  MINUTE_IN_MS,
  SECOND_IN_MS,
} from "../services/globalVariables";
import appBroadcast from "../broadcasts/appBroadcast";
import broadcast from "../broadcasts/broadcast";
import { trackError } from "./tracking";
import {
  createUpdatedAllCalendars,
  filterOutBirthdayCalendar,
  getActiveCalendarsForUser,
  getActiveCalendarsIDsFromAllCalendars,
  getActiveSelectedCalendars,
  getAllPrimaryCalendarsFromAllCalendars,
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  getMatchingCalendarsForUser,
  getMatchingUserProviderIDsFromUserCalendarIDs,
  getUpdatedUserTimeZonesIndexAndLastSet,
  isCalendarSelected,
  parseSyncResponseCalendars,
} from "../lib/calendarFunctions";
import { createWindow } from "../lib/stateManagementFunctions";
import { isCalendarExecutiveCalendar, isUserDelegatedUser, isUserMaestroUser, shouldHideDelegatedUser } from "../services/maestroFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import { immutablySortArray } from "../lib/arrayFunctions";
import {
  addEventsIntoIndexDB,
  deleteEventsFromIndexDB,
} from "../lib/dbFunctions";
import db from "../services/db";
import Fetcher from "../services/fetcher";
import { constructRequestURL, isErrorResponse } from "../services/api";
import { formatEventsArrayForReactBigCalendar } from "../lib/eventFunctions";
import { getOutlookAndGoogleUsers, getUpcomingCalendarUserCalendarIDsForUser, getUserEmail, getUserToken, isSameUser, sortFetchUsers } from "../lib/userFunctions";
import { formatISO, isSameMonth } from "date-fns";
import { PROVIDER_TYPES, formatAndHandleOutlookPreviewEvents, isOutlookUser } from "../lib/outlookFunctions";
import { getDifferenceInPerformanceTrackingInSecs, getTrackingTimeNow, trackInitialLoadTimes } from "../lib/performanceTrackingFunctions";
import { FETCH_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { FETCH_CALENDAR_EVENTS_ENDPOINT } from "../lib/endpoints";
import { getCalendarProviderId, getCalendarUserCalendarID } from "../services/calendarAccessors";
import { getDefaultHeaders, getOutlookPreviewEvents, isValidOutlookPreviewResponse } from "../lib/fetchFunctions";
import { shouldGetOutlookPreviewEvents } from "../lib/featureFlagFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { delayByMs } from "../lib/asyncFunctions";
import { TEST_PREVIEW_OUTLOOK_LOCATION, isTestingPreviewOutlook } from "../services/testFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { createUUID } from "../services/randomFunctions";
import { isSameEmail } from "../lib/stringFunctions";
import { useUserTimeZoneIndexStore } from "../services/stores/userData";
import { getRetryAfterMS, shouldRetryBasedOnResponse } from "../lib/backendFunctions";

const RETRY_TYPE = {
  INITIAL_SYNC: "INITIAL_SYNC",
  WINDOW_SYNC: "WINDOW_SYNC",
};
class FetchCalendarAndEventsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this._syncedUsersEmails = []; // to track which user has been synced
    this._timeoutList = []; // keeps track of timeouts so we can cancel them on unmount
    this._fetchIDOutlookPreviewEvent = null;

    this.fetchInitialCalendarAndEvents =
      this.fetchInitialCalendarAndEvents.bind(this);
    this.fetchCurrentWindowEvents = this.fetchCurrentWindowEvents.bind(this);
    this.getOutlookPreviewEventParentCaller = this.getOutlookPreviewEventParentCaller.bind(this);
    fetchBroadcast.subscribe(
      FETCH_BROADCAST_VALUES.FETCH_INITIAL_CALENDAR_AND_EVENTS,
      this.fetchInitialCalendarAndEvents,
    );
    fetchBroadcast.subscribe(
      FETCH_BROADCAST_VALUES.FETCH_EVENTS_FOR_CURRENT_WINDOW,
      this.fetchCurrentWindowEvents,
    );
    fetchBroadcast.subscribe(FETCH_BROADCAST_VALUES.FETCH_PREVIEW_OUTLOOK_EVENTS, this.getOutlookPreviewEventParentCaller);
  }

  componentDidMount() {
    this._isMounted = true;
    this.startBackgroundFetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._timeoutList.forEach((timer) => {
      clearTimeout(timer);
    });

    fetchBroadcast.unsubscribe(FETCH_BROADCAST_VALUES.FETCH_INITIAL_CALENDAR_AND_EVENTS);
    fetchBroadcast.unsubscribe(FETCH_BROADCAST_VALUES.FETCH_EVENTS_FOR_CURRENT_WINDOW);
    fetchBroadcast.unsubscribe(FETCH_BROADCAST_VALUES.FETCH_PREVIEW_OUTLOOK_EVENTS);
  }

  render() {
    return null;
  }

  // it all starts here
  // isCleanRefresh gets passed in from the broadcast
  async fetchInitialCalendarAndEvents({ isCleanRefresh, windowFetchID }) {
    // on sync we need to do a few things:
    // 1. call the sync end point
    // 2. parse calendars into allCalendars
    // 3. format events using formatEventForReactBigCalendar
    // 4. filter events out for the weekly calendar based on window
    // 5. delete events in db on isCleanRefresh for each calendar
    // 6. if not clean refresh -> delete cancelled events
    // 7. put events in window into weekly calendar on userEmail basis
    const { currentUser, isSwitchingAccount } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    if (isSwitchingAccount || isEmptyObjectOrFalsey(currentUser)) {
      return;
    }

    const {
      googleUsers,
      outlookUsers,
    } = getOutlookAndGoogleUsers({
      allLoggedInUsers,
      currentUser,
      allCalendars,
      masterAccount,
      shouldFilterOutHiddenDelegatedUsers: false,
    });
    if (outlookUsers?.length > 0) {
      // get preview event first
      this._fetchIDOutlookPreviewEvent = createUUID();
      const fetchID = this._fetchIDOutlookPreviewEvent;
      this.getOutlookPreviewEvents({
        users: outlookUsers,
        isCleanRefresh,
        fetchID,
      });
    }

    // put google events first because they load faster
    if (!isEmptyArrayOrFalsey(googleUsers)) {
      await this.fetchGoogleUserCalendarAndEmails({
        users: googleUsers,
        isCleanRefresh,
        windowFetchID,
      });
    }

    if (isEmptyArrayOrFalsey(outlookUsers)) {
      // no point in going down if outlook users is empty
      return;
    }
    if (!this._isMounted) {
      return;
    }
    const {
      selectedDay,
      selectedCalendarView,
      weekStart,
    } = this.props;
    const checkedSelectedDate = getSelectedDayWithBackup(selectedDay);
    const syncWindows = getOutlookChoppedSyncWindows({
      selectedCalendarView,
      selectedDay: checkedSelectedDate,
      weekStart,
      userCalendarIDsToFetch: this.findUserCalendarIDsThatIsMostActive(),
    });

    // fetch for toggled on calendars first and then after a few seconds, then fetch for toggled off calendars
    const {
      toggledOnCalendars: toggledOnOutlookUsers,
      toggledOffCalendars: toggledOffOutlookUsers,
    } = this.getToggledOffAndOnUsers(outlookUsers);
    if (toggledOnOutlookUsers.length > 0) {
      // we need to fetch the unselected calendars
      for (const [index, syncWindow] of syncWindows.entries()) {
        // do not want to delay if monthly
        // no need to add additional delay with delayByMs since we already queue it up on the backend
        // can not call this.fetchCurrentWindowEvents([syncWindow]); because this also calls preview and if there are multiple active users, this is actaully slower.
        await this.batchRequestOutlookUserPerWindow({
          users: toggledOnOutlookUsers,
          isCleanRefresh,
          windowFetchID,
          syncWindow,
          index,
        });
      }
    }
    if (toggledOnOutlookUsers.length > 0 && toggledOffOutlookUsers.length > 0) {
      // no point in delaying if there are no toggled on users or if there are no toggled off users
      await delayByMs(SECOND_IN_MS * 3);
    }

    if (toggledOffOutlookUsers.length > 0) {
      // we need to fetch the selected calendars
      for (const [index, syncWindow] of syncWindows.entries()) {
        // do not want to delay if monthly
        // no need to add additional delay with delayByMs since we already queue it up on the backend
        // can not call this.fetchCurrentWindowEvents([syncWindow]); because this also calls preview and if there are multiple active users, this is actaully slower.
        await this.batchRequestOutlookUserPerWindow({
          users: toggledOffOutlookUsers,
          isCleanRefresh,
          windowFetchID,
          syncWindow,
          index,
        });
      }
    }
  }

  async handleGetCalendarAndEventsError({
    response,
    user,
    isRetry,
    isCleanRefresh,
    windowFetchID,
    timeMin,
    timeMax,
    retryType = RETRY_TYPE.INITIAL_SYNC,
    index,
  }) {
    const { error, user: responseUser } = response;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      currentUser,
    } = this.props;
    if (isPaidAccessRequiredError(error)) {
      layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL");
      return;
    }

    if (isQuotaExceededError(error, user) || !isPermissionsError(error) || shouldRetryBasedOnResponse(response, currentUser)) {
      const getRetryInMS = () => {
        if (shouldRetryBasedOnResponse(response, currentUser)) {
          return getRetryAfterMS(response);
        }
        return SECOND_IN_MS * 1;
      };

      const retryTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        if (retryType === RETRY_TYPE.INITIAL_SYNC) {
          if (isOutlookUser(user)) {
            this.batchRequestOutlookUserPerWindow({
              users: [user],
              isCleanRefresh,
              windowFetchID,
              syncWindow: { timeMin, timeMax },
              index,
              isRetry,
            });
          } else {
            this.fetchGoogleUserCalendarAndEmails({
              users: [user],
              isCleanRefresh,
              windowFetchID,
              isRetry,
            });
          }
        } else if (retryType === RETRY_TYPE.WINDOW_SYNC) {
          // nothing for now
        }
      }, getRetryInMS());

      this._timeoutList = this._timeoutList.concat(retryTimeout);
    }

    broadcastOpenPermissionModal({
      error: isNeedToShowUserConsentScreen(response) ? PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN : error,
      userEmail: getUserEmail(responseUser) || getUserEmail(user),
      provider: responseUser?.provider,
      allLoggedInUsers,
    });
  }

  // parse responses that were put into a promise.all
  async parseGroupedFetchResponses({
    coupledResponses,
    isCleanRefresh,
    windowFetchID,
    timeMin,
    timeMax,
    providerType,
    isSyncingCalendarOnly = false,
    index, // syncWindowIndex
    isRetry,
  }) {
    const filteredCoupledResponses = coupledResponses?.filter((coupleResponse) => !!coupleResponse?.response) ?? [];
    const {
      allCalendars,
      setAllCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    let allUserEmails = [];
    let groupedUpdatedAllCalendars = allCalendars;
    let promiseForDBUpdate = [];
    filteredCoupledResponses.forEach((userAndResponse, responseIndex) => {
      if (isEmptyObjectOrFalsey(userAndResponse?.response)) {
        return;
      }
      const { response, user } = userAndResponse;
      if (!getUserEmail(user)) {
        return;
      }
      if (isErrorResponse(response) && !isRetry) {
        // checked for 429
        // handling errors
        this.handleGetCalendarAndEventsError({
          response,
          user,
          isRetry: true,
          isCleanRefresh,
          windowFetchID,
          timeMin,
          timeMax,
          retryType: RETRY_TYPE.INITIAL_SYNC,
          index,
          providerType,
        });
        return;
      }
      allUserEmails = allUserEmails.concat(getUserEmail(user)); // at this point this is a valid response
      if (responseIndex === 0) {
        appBroadcast.publish(
          "CHECK_FOR_TODAY_AND_BACKEND_TRIGGER_APP_UPDATE",
          response,
        );
      }
      const { onReceiveInitialSync } = this.props.accountActivity;
      onReceiveInitialSync(); // get rid of loading screen from login
      const { updatedAllCalendars, formattedEvents } =
        this.parseResponseIntoAllCalendarsAndEvents({
          response,
          user,
          isCleanRefresh,
          inputAllCalendars: groupedUpdatedAllCalendars,
        });
      groupedUpdatedAllCalendars = updatedAllCalendars;
      if (isSyncingCalendarOnly) {
        // do nothing
      } else if (formattedEvents?.length > 0) {
        promiseForDBUpdate = promiseForDBUpdate.concat(
          this.wipeAndStoreIntoDB({
            formattedEvents,
            user,
            updatedAllCalendars,
            isCleanRefresh,
            timeMin,
            timeMax,
          }),
        );
      }
    });
    const eventsFromDBUpsert = await Promise.all(promiseForDBUpdate);
    if (!this._isMounted) {
      return;
    }

    const {
      userTimeZoneIndex,
      setUserTimeZoneIndex,
      userTimeZoneLastSetIndex,
      setUserTimeZoneLastSetIndex,
    } = this.props.userTimeZoneIndexStore;
    const {
      newUserTimeZonesIndex,
      newUserTimeZoneLastSetIndex,
    } = getUpdatedUserTimeZonesIndexAndLastSet({allCalendars: groupedUpdatedAllCalendars});
    setUserTimeZoneIndex({
      ...userTimeZoneIndex,
      ...newUserTimeZonesIndex,
    });
    setUserTimeZoneLastSetIndex({
      ...userTimeZoneLastSetIndex,
      ...newUserTimeZoneLastSetIndex,
    });
    setAllCalendars(groupedUpdatedAllCalendars, currentUser, "setAllCalendars_5");
    const activeCalendarIds = this.getActiveUserCalendarIDsDictionary(
      groupedUpdatedAllCalendars,
    );

    if (isSyncingCalendarOnly) {
      // no point in continuing if we're only syncing calendars
      return;
    }
    //! logic below is all related to events

    let allFormattedEvents = [];
    eventsFromDBUpsert.forEach((response) => {
      if (isEmptyObjectOrFalsey(response)) {
        return;
      }
      const { eventsWithinTimeWindow } = response;
      allFormattedEvents = allFormattedEvents.concat(eventsWithinTimeWindow);
    });

    if (isEmptyArrayOrFalsey(allFormattedEvents)) {
      return;
    }

    if (!this.isSelectedDayInWindow({ timeMin, timeMax })) {
      return;
    }

    if (isTestingPreviewOutlook(TEST_PREVIEW_OUTLOOK_LOCATION.INITIAL_SYNC)) {
      return;
    }

    if (isCleanRefresh) {
      const timeZoneOption = { timeZone: this.props.currentTimeZone };
      const param = {
        eventList: allFormattedEvents,
        calendarIds: activeCalendarIds,
        lastSyncAtUTC: this.getLastResponseTime(
          filteredCoupledResponses.map((r) => r?.response),
        ),
        deleteEventUserEmails: allUserEmails,
        windowForRemovingEvents: {
          timeMin: convertToTimeZone(timeMin, timeZoneOption),
          timeMax: convertToTimeZone(timeMax, timeZoneOption),
        },
        fromWhere:
          "fetchCalendarAndEventsContainer::parseGroupedFetchResponses",
      };
      broadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.WIPE_OUT_EVENTS_AND_ADD_EVENTS, param);
    } else {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR, {
        listOfEvents: allFormattedEvents,
      });
    }
  }

  async fetchGoogleUserCalendarAndEmails({
    users,
    isCleanRefresh,
    windowFetchID,
    isRetry,
  }) {
    const { allCalendars } = this.props.allCalendars;
    const { selectedDay, selectedCalendarView, weekStart, currentUser } = this.props;
    const sortedUsers = sortFetchUsers({users, currentUser});
    const checkedSelectedDate = getSelectedDayWithBackup(selectedDay);
    const { minDate, maxDate } = determineSyncWindow({
      selectedDay: checkedSelectedDate,
      selectedCalendarView,
      weekStart,
      isGoogleInitialSync: true,
    });
    const fetchPromises = sortedUsers.map(async (user) => {
      if (!this._isMounted) {
        return;
      }
      const upcomingUserCalendarIDs = this.getAllActivePrimaryAndUpcomingUserCalendarIDsForUser({user, fromWhere: "fetchGoogleUserCalendarAndEmails"});
      const { request } = createCalendarEventsSyncRequestGoogle({
        user,
        weekStart,
        allCalendars,
        isCleanRefresh,
        minDate,
        maxDate,
        otherUserCalendarIDsToFetch: upcomingUserCalendarIDs,
      });
      const response = await request;
      if (!this._isMounted) {
        return;
      }
      return { user, response };
    });

    const startTime = getTrackingTimeNow();
    const coupledResponses = await Promise.all(fetchPromises);
    const endTime = getTrackingTimeNow();
    if (isCleanRefresh) {
      trackInitialLoadTimes({
        user: currentUser,
        delayInSec: getDifferenceInPerformanceTrackingInSecs(startTime, endTime),
        provider: PROVIDER_TYPES.GOOGLE,
        calendarView: selectedCalendarView,
        functionType: "fetchGoogleCalendarAndEvents",
      });
    }

    if (!this._isMounted) {
      return;
    }
    await this.parseGroupedFetchResponses({
      coupledResponses,
      isCleanRefresh,
      windowFetchID,
      timeMin: minDate,
      timeMax: maxDate,
      providerType: PROVIDER_TYPES.GOOGLE,
      isRetry,
    });
  }

  getAllActivePrimaryAndUpcomingUserCalendarIDsForUser({ user, fromWhere}) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    if (shouldHideDelegatedUser({ user, allCalendars })) {
      return [];
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const filteredAllCalendars = getMatchingCalendarsForUser({ allCalendars, userEmail: getUserEmail(user) });
    const upcomingUserCalendarIDs = this.getUpcomingUserCalendarIDsForUser({user});
    const primaryCalendarsIDs = getAllPrimaryCalendarsFromAllCalendars(filteredAllCalendars).map((c) => getCalendarUserCalendarID(c));
    const selectedCalendarIDs = getActiveCalendarsIDsFromAllCalendars({
      allCalendars: filteredAllCalendars,
      currentUserEmail: getUserEmail(user),
    });

    if (!isUserMaestroUser(masterAccount)) {
      return removeDuplicatesFromArray([...upcomingUserCalendarIDs, ...primaryCalendarsIDs, ... selectedCalendarIDs]);
    }

    // get the matching executive calendar for delegated users
    const executiveCalendarUserCalendarIDs = Object.values(filteredAllCalendars)
      .filter(calendar => isCalendarExecutiveCalendar({ calendar, allLoggedInUsers }))
      .map(calendar => getCalendarUserCalendarID(calendar));

    return removeDuplicatesFromArray([...executiveCalendarUserCalendarIDs, ...upcomingUserCalendarIDs, ...primaryCalendarsIDs, ... selectedCalendarIDs]);
  }

  getToggledOffAndOnUsers(users) {
    if (isEmptyArrayOrFalsey(users)) {
      return {
        toggledOnCalendars: [],
        toggledOffCalendars: [],
      };
    }
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    const toggledOnCalendars = users.filter(user => {
      if (shouldHideDelegatedUser({ user, allCalendars })) {
        return false;
      }
      if (isSameUser(user, currentUser)) {
        // always include current user as part of toggled on
        return true;
      }
      const matchingCalendars = getMatchingCalendarsForUser({ allCalendars, userEmail: getUserEmail(user) });
      return getActiveSelectedCalendars(matchingCalendars).length > 0;
    });
    const toggledOffCalendars = users.filter(user => {
      if (shouldHideDelegatedUser({ user, allCalendars })) {
        return true;
      }
      if (isSameUser(user, currentUser)) {
        // never include current user as part of toggled off
        return false;
      }
      const matchingCalendars = getMatchingCalendarsForUser({ allCalendars, userEmail: getUserEmail(user) });
      return getActiveSelectedCalendars(matchingCalendars).length === 0; // none are selected
    });
    return {
      toggledOnCalendars,
      toggledOffCalendars,
    };
  }

  getUpcomingUserCalendarIDsForUser({user}) {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    return getUpcomingCalendarUserCalendarIDsForUser({
      masterAccount,
      allCalendars,
      user,
      allLoggedInUsers,
    });
  }

  getActiveUserCalendars(inputAllCalendars) {
    const allCalendars =
      inputAllCalendars ?? this.props.allCalendars.allCalendars;
    const activeCalendar = [];
    Object.keys(allCalendars).forEach((key) => {
      if (isCalendarSelected(allCalendars[key])) {
        activeCalendar.push(allCalendars[key]);
      }
    });
    return activeCalendar;
  }

  getActiveCalendars(inputAllCalendars) {
    const allCalendars =
      inputAllCalendars ?? this.props.allCalendars.allCalendars;
    const activeCalendars = [];
    Object.values(allCalendars).forEach((calendar) => {
      if (isCalendarSelected(calendar)) {
        activeCalendars.push(calendar);
      }
    });
    return activeCalendars;
  }

  // note: this returns object
  getActiveUserCalendarIDsDictionary(inputAllCalendars) {
    const allCalendars =
      inputAllCalendars ?? this.props.allCalendars.allCalendars;
    const activeCalendarIdsDict = {};
    Object.keys(allCalendars).forEach((key) => {
      activeCalendarIdsDict[key] = isCalendarSelected(allCalendars[key]);
    });
    return activeCalendarIdsDict;
  }

  getLastResponseTime(responses) {
    if (isEmptyArrayOrFalsey(responses)) {
      return null;
    }
    const filteredLastSyncAtUTC = responses
      .map((response) => response?.last_synced_at_utc)
      .filter((time) => !!time);
    const sorted = immutablySortArray(filteredLastSyncAtUTC, (a, b) => a - b);
    return sorted?.[0];
  }

  getOutlookPreviewEventParentCaller({
    users,
    userCalendarIDs,
    inputTimeMin,
    inputTimeMax,
    inputAllCalendars,
  }) {
    this._fetchIDOutlookPreviewEvent = createUUID();
    const fetchID = this._fetchIDOutlookPreviewEvent;
    this.getOutlookPreviewEvents({
      users,
      isCleanRefresh: false,
      fetchID,
      userCalendarIDs,
      inputTimeMin,
      inputTimeMax,
      inputAllCalendars,
      onlySyncEvents: true,
    });
  }

  async getOutlookPreviewEvents({
    users,
    isCleanRefresh,
    fetchID,
    userCalendarIDs,
    inputTimeMin,
    inputTimeMax,
    inputAllCalendars,
    onlySyncEvents,
    isRetry,
  }) {
    if (isEmptyArrayOrFalsey(users)) {
      return;
    }
    const allCalendars = inputAllCalendars ?? this.props.allCalendars.allCalendars;
    const filteredUsers = users.filter((user) => shouldGetOutlookPreviewEvents(user)
      && !shouldHideDelegatedUser({ user, allCalendars }),
    );
    if (isEmptyArrayOrFalsey(filteredUsers)) {
      return;
    }

    const {
      selectedDay,
      weekStart,
      selectedCalendarView,
      currentTimeZone,
      currentUser,
    } = this.props;
    const {
      timeMin,
      timeMax,
    } = getEntireCurrentSyncWindowForPreviewEvents({
      selectedDay: getSelectedDayWithBackup(selectedDay),
      weekStart,
      selectedCalendarView,
    });
    const lastSyncUTC = formatISO(new Date()); // format: 2024-05-13T16:00:01Z
    const getActiveUserCalendarIDs = (user) => {
      if (userCalendarIDs?.length > 0) {
        return userCalendarIDs.filter((userCalendarID) => {
          const matchingCalendar = getCalendarFromUserCalendarID({
            userCalendarID,
            allCalendars,
          });
          return isSameEmail(getCalendarUserEmail(matchingCalendar), getUserEmail(user));
        });
      }
      return this.getActiveCalendars(allCalendars)
        .filter((calendar) => isSameEmail(getCalendarUserEmail(calendar), getUserEmail(user)))
        .map((calendar) => getCalendarUserCalendarID(calendar));
    };

    const callPreviewEvents = async (user) => {
      if (!this._isMounted) {
        return;
      }
      const calendarIDs = getActiveUserCalendarIDs(user);
      if (isEmptyArrayOrFalsey(calendarIDs)) {
        if (!isCleanRefresh) {
          return;
        } else if (isCleanRefresh && isSameUser(user, currentUser)) {
          // for login when we have no calendars yet
          // if same user as current user -> go ahead and call preview
          // do nothing
        } else {
          return;
        }
      }

      const request = getOutlookPreviewEvents({
        calendarIDs,
        timeMin: isValidJSDate(inputTimeMin) ? inputTimeMin : timeMin,
        timeMax: isValidJSDate(inputTimeMax) ? inputTimeMax : timeMax,
        currentTimeZone,
        user,
        onlySyncEvents,
        allCalendars,
      });
      const response = await request;
      if (!this._isMounted) {
        return;
      }
      return {
        user,
        response,
      };
    };

    const fetchPromises = filteredUsers.map(callPreviewEvents);

    try {
      const startTime = getTrackingTimeNow();
      const allResponses = await Promise.all(fetchPromises);
      if (fetchID !== this._fetchIDOutlookPreviewEvent) {
        return;
      }
      const endTime = getTrackingTimeNow();
      if (isCleanRefresh) {
        trackInitialLoadTimes({
          user: currentUser,
          delayInSec: getDifferenceInPerformanceTrackingInSecs(startTime, endTime),
          provider: PROVIDER_TYPES.OUTLOOK,
          calendarView: selectedCalendarView,
          functionType: "fetchOutlookPreview",
        });
      }

      if (!isRetry) {
        const filteredResponseForRetry = allResponses.filter(resolvedPromise => {
          return shouldRetryBasedOnResponse(resolvedPromise?.response, currentUser) && resolvedPromise?.user;
        });

        if (!isEmptyArrayOrFalsey(filteredResponseForRetry)) {
          this.getOutlookPreviewEvents({
            users: filteredResponseForRetry.map(r => r.user),
            isCleanRefresh,
            fetchID,
            userCalendarIDs,
            inputTimeMin,
            inputTimeMax,
            inputAllCalendars,
            isRetry: true,
          });
        }
      }

      const filteredResponses = allResponses?.filter((coupledResponse) => isValidOutlookPreviewResponse({response: coupledResponse?.response, onlySyncEvents}));
      if (isEmptyArrayOrFalsey(filteredResponses)) {
        return;
      }

      // update allCalendars from response
      const updatedCalendars = onlySyncEvents
        ? allCalendars
        : this.formatAndSetOutlookPreviewCalendars({
          responses: filteredResponses,
          lastSyncUTC,
          isCleanRefresh,
        });
      this.formatAndHandleOutlookPreviewEvents({
        responses: filteredResponses,
        lastSyncUTC,
        isCleanRefresh,
        updatedCalendars,
      });
    } catch (error) {
      handleError(error);
    }
  }

  formatAndHandleOutlookPreviewEvents({
    responses,
    updatedCalendars,
  }) {
    const { currentTimeZone } = this.props;
    formatAndHandleOutlookPreviewEvents({
      responses,
      activeCalendarProviderIDs: this.getActiveUserCalendars(updatedCalendars).map((c) => getCalendarProviderId(c)),
      currentTimeZone,
    });
  }

  formatAndSetOutlookPreviewCalendars({
    responses,
    lastSyncUTC,
    isCleanRefresh,
  }) {
    // already checked for valid response
    const {
      allCalendars,
      setAllCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    let groupedUpdatedAllCalendars = allCalendars;
    responses.forEach((coupledResponse) => {
      const {
        response,
        user,
      } = coupledResponse;
      const existingAllCalendarEmails = Object.keys(groupedUpdatedAllCalendars ?? {}).map((k) =>
        getCalendarUserEmail(groupedUpdatedAllCalendars[k]),
      );
      const isNewUserSync = !existingAllCalendarEmails.includes(getUserEmail(user));
      const { calendars } = response;
      const { responseAllCalendars } =
        parseSyncResponseCalendars(calendars, lastSyncUTC, groupedUpdatedAllCalendars);
      const updatedAllCalendars = createUpdatedAllCalendars({
        responseAllCalendars,
        allCalendars: groupedUpdatedAllCalendars,
        userEmail: getUserEmail(user),
        isCleanRefresh,
        currentUser,
        isNewUserSync,
        allLoggedInUsers,
        masterAccount,
        whereFrom: "fetchCalendarAndEventsContainer::formatAndSetOutlookPreviewCalendars",
      });
      groupedUpdatedAllCalendars = updatedAllCalendars;
    });
    setAllCalendars(groupedUpdatedAllCalendars, currentUser, "setAllCalendars_7");
    return groupedUpdatedAllCalendars;
  }

  async batchRequestOutlookUserPerWindow({
    users, // already comes sorted
    isCleanRefresh,
    windowFetchID,
    syncWindow,
    index,
    isRetry,
  }) {
    if (isEmptyArrayOrFalsey(users)) {
      return;
    }
    const { timeMin, timeMax } = syncWindow;
    const { allCalendars } = this.props.allCalendars;
    const {
      selectedDay,
      selectedCalendarView,
      currentUser,
    } = this.props;
    const checkedSelectedDate = getSelectedDayWithBackup(selectedDay);

    const syncTime = Date.now();
    const fetchCalendarAndEventsPromises = [];
    const fetchOnlyCalendarsPromise = [];
    for (const user of users) {
      const upcomingUserCalendarIDs = this.getAllActivePrimaryAndUpcomingUserCalendarIDsForUser({user, fromWhere: "batchRequestOutlookUserPerWindow"});
      const shouldSyncCalendarAndEvents = () => {
        if (upcomingUserCalendarIDs?.length > 0) {
          return true;
        }
        if (!isCleanRefresh) {
          return false;
        }
        // isCleanRefresh
        return isSameUser(user, currentUser);
      };
      const shouldSyncBothCalendarAndEvents = shouldSyncCalendarAndEvents();
      if (shouldSyncBothCalendarAndEvents) {
        // fetch both calendar and events
        const request = createCalendarEventsSyncRequestForOutlook({
          user,
          selectedDay: checkedSelectedDate,
          selectedCalendarView,
          allCalendars,
          isCleanRefresh,
          inputTimeMin: timeMin,
          inputTimeMax: timeMax,
          syncTime,
          otherUserCalendarIDsToFetch: upcomingUserCalendarIDs,
        });
        fetchCalendarAndEventsPromises.push(
          (async () => {
            const response = await request;
            return { user, response };
          })(),
        );
      } else {
        // syncing only calendars
        if (index > 0) {
          // no reason to call for other windows if we're only syncing calendars
          continue; // do not use return, will exit entire function
        }
        const request = createSyncOnlyCalendarRequest({ user });
        fetchOnlyCalendarsPromise.push(
          (async () => {
            const response = await request;
            return { user, response };
          })(), // need to call function here otherwise it will push in a type function
        );
      }
    }

    if (fetchCalendarAndEventsPromises.length > 0) {
      const startTime = getTrackingTimeNow();
      const coupledFetchCalendarAndEventsResponses = await Promise.all(fetchCalendarAndEventsPromises);
      if (!this._isMounted) {
        return;
      }
      const endTime = getTrackingTimeNow();
      if (isCleanRefresh) {
        trackInitialLoadTimes({
          user: currentUser,
          delayInSec: getDifferenceInPerformanceTrackingInSecs(startTime, endTime),
          provider: PROVIDER_TYPES.OUTLOOK,
          calendarView: selectedCalendarView,
          functionType: "fetchOutlookCalendarAndEvents",
        });
      }

      await this.parseGroupedFetchResponses({
        coupledResponses: coupledFetchCalendarAndEventsResponses,
        isCleanRefresh,
        windowFetchID,
        timeMin,
        timeMax,
        providerType: PROVIDER_TYPES.OUTLOOK,
        index,
        isRetry,
      });
    }

    if (fetchOnlyCalendarsPromise.length > 0) {
      const startTimeOnlyCalendar = getTrackingTimeNow();
      const coupledFetchOnlyCalendarResponses = await Promise.all(fetchOnlyCalendarsPromise);
      const endTimeOnlyCalendar = getTrackingTimeNow();
      if (isCleanRefresh) {
        trackInitialLoadTimes({
          user: currentUser,
          delayInSec: getDifferenceInPerformanceTrackingInSecs(startTimeOnlyCalendar, endTimeOnlyCalendar),
          provider: PROVIDER_TYPES.OUTLOOK,
          calendarView: selectedCalendarView,
          functionType: "fetchOutlookOnlyCalendar",
        });
      }
      if (!this._isMounted) {
        return;
      }
      await this.parseGroupedFetchResponses({
        coupledResponses: coupledFetchOnlyCalendarResponses,
        isCleanRefresh,
        windowFetchID,
        timeMin,
        timeMax,
        providerType: PROVIDER_TYPES.OUTLOOK,
        isSyncingCalendarOnly: true,
        isRetry,
        index,
      });
    }
  }

  async wipeAndStoreIntoDB({
    formattedEvents,
    user,
    updatedAllCalendars,
    isCleanRefresh,
    timeMax,
    timeMin,
    fromWhere,
  }) {
    const allCalendars =
      updatedAllCalendars ?? this.props.allCalendars.allCalendars;
    const { email } = user;
    const { currentUser } = this.props;
    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
      todayLeftJSDate,
      todayRightJSDate,
    } = this.getMovingWindow();
    const activeCalendarUserIDs = getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(currentUser),
      isInitialSync: !this._syncedUsersEmails.includes(email), // really this is isFirstSync
    });

    const { eventsWithinTimeWindow, cancelledEvents, nonCancelledEvents } =
      splitEventsIntoCancelledAndEventsInWindow({
        formattedEvents,
        activeCalendarUserIDs,
        windowStartDateJSDate: dbWindowStartJSDate,
        windowEndDateJSDate: dbWindowEndJSDate,
        todayLeftJSDate,
        todayRightJSDate,
      });
    this._syncedUsersEmails = removeDuplicatesFromArray(
      this._syncedUsersEmails.concat(email),
    );

    const returnDefault = () => {
      return {
        eventsWithinTimeWindow,
        cancelledEvents,
        nonCancelledEvents,
      };
    };

    if (!isEmptyArrayOrFalsey(cancelledEvents)) {
      const listOfDeletedEvents = cancelledEvents.map((e) => {
        return getEventUserCalendarID(e);
      });

      if (!this._isMounted) {
        return returnDefault();
      }

      await deleteEventsFromIndexDB({
        userEventIDs: listOfDeletedEvents,
        userEmail: email,
        currentUserEmail: getUserEmail(currentUser),
        where: "fetchCalendarAndEventsContainer::wipeAndStoreIntoDB",
      });
      if (!this._isMounted) {
        return returnDefault();
      }
    }

    if (isCleanRefresh) {
      try {
        if (!this._isMounted) {
          return returnDefault();
        }
        await db.wipeOutEventsOnRefresh({
          email,
          timeMax,
          timeMin,
        });
      } catch (e) {
        handleError(e);
      }
    }

    // !warning
    // Do not add check here becasue otherwise, user can switch accounts and if it's the right time,
    // we're going to delete events and never add in the events that should have been added in.
    // if (!this._isMounted) {
    //   return returnDefault();
    // }

    await addEventsIntoIndexDB({
      userEmail: email,
      events: formattedEvents,
    });
    if (!this._isMounted) {
      return returnDefault();
    }

    return {
      eventsWithinTimeWindow,
      cancelledEvents,
      nonCancelledEvents,
    };
  }

  // returns updatedAllCalendars and formattedEvents from response
  parseResponseIntoAllCalendarsAndEvents({
    response,
    user,
    isCleanRefresh,
    inputAllCalendars,
  }) {
    if (!getUserEmail(user)) {
      return;
    }
    const email = getUserEmail(user);
    const { currentTimeZone, currentUser } = this.props;
    const allCalendars =
      filterOutBirthdayCalendar(inputAllCalendars ?? this.props.allCalendars.allCalendars);
    const existingAllCalendarEmails = Object.keys(allCalendars ?? {}).map((k) =>
      getCalendarUserEmail(allCalendars[k]),
    );
    const isNewUserSync = !existingAllCalendarEmails.includes(email);
    const {
      formattedEvents,
      responseAllCalendars,
      calendarContacts,
      primaryCalendarCount,
    } = formatSyncResponseEventsAndCalendar({
      email,
      response,
      currentTimeZone,
      allCalendars,
    });
    if (primaryCalendarCount === 0) {
      trackError({
        category: "calendar_bugs",
        errorMessage: "zero primary calendars",
        userToken: getUserToken(currentUser),
      });
      sendErrorToTracking({message: `zero primary calendars: ${primaryCalendarCount}`}, email);
    } else if (primaryCalendarCount > 1) {
      trackError({
        category: "calendar_bugs",
        errorMessage: `multiple_primaryCalendars_${primaryCalendarCount}`,
        userToken: getUserToken(currentUser),
      });
      sendErrorToTracking({message: `multiple primary calendars: ${primaryCalendarCount}`}, email);
    }

    if (calendarContacts?.length > 0) {
      broadcast.publish(
        "ADD_NEW_CONTACTS_INTO_INDEXDB",
        calendarContacts,
        email,
      );
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;

    const updatedAllCalendars = createUpdatedAllCalendars({
      responseAllCalendars,
      allCalendars,
      userEmail: email,
      isCleanRefresh,
      currentUser,
      isNewUserSync,
      allLoggedInUsers,
      masterAccount,
      whereFrom: "fetchCalendarAndEventsContainer::parseResponseIntoAllCalendarsAndEvents",
    });
    return {
      updatedAllCalendars,
      formattedEvents,
    };
  }

  getMovingWindow() {
    const { selectedDay, weekStart, selectedCalendarView } = this.props;
    return createWindow({
      windowJSDate: getSelectedDayWithBackup(selectedDay),
      isMonth: selectedCalendarView === BACKEND_MONTH,
      weekStart,
      selectedCalendarView,
    });
  }

  isMainCalendarMonthView() {
    return this.props.selectedCalendarView === BACKEND_MONTH;
  }

  async backgroundSync() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      googleUsers,
      outlookUsers,
    } = getOutlookAndGoogleUsers({
      allLoggedInUsers,
      currentUser,
      allCalendars,
      masterAccount,
    });
    await this.backgroundSyncForUsers({ users: googleUsers });
    if (!this._isMounted) {
      return;
    }
    await this.backgroundSyncForUsers({ users: outlookUsers });
  }

  async backgroundSyncForUsers({ users }) {
    // sync everything at once
    if (isEmptyArrayOrFalsey(users)) {
      return;
    }
    const { weekStart } = this.props;
    const { allSyncWindows } = getBackgroundSyncTimes({ weekStart });

    const onComplete = async (result) => {
      if (isEmptyObjectOrFalsey(result)) {
        return;
      }
      const { formattedEvents, user, timeMin, timeMax } = result;
      if (isEmptyArrayOrFalsey(formattedEvents) || !this._isMounted) {
        return;
      }
      if (!this._isMounted) {
        return;
      }
      await this.wipeAndStoreIntoDB({
        formattedEvents,
        user,
        isCleanRefresh: false,
        timeMin,
        timeMax,
        fromWhere: "backgroundSyncForUsers",
      });
      if (!this._isMounted) {
        return;
      }
    };
    await this.fetchEventsForUsers({
      users,
      syncWindows: allSyncWindows,
      onComplete,
    });
  }

  async fetchEventsForUsers({
    users,
    syncWindows,
    onComplete, // takes {formattedEvents, user, timeMin, timeMax, response}
    isRetry = false,
  }) {
    const {
      currentUser,
    } = this.props;
    const sortedUsers = sortFetchUsers({
      users,
      currentUser,
    });
    for (const user of sortedUsers) {
      for (const syncWindow of syncWindows) {
        const { timeMin, timeMax } = syncWindow;
        if (!this._isMounted) {
          return;
        }
        // leave this underneath the timeout so we always get latest version
        const { allCalendars } = this.props.allCalendars;
        const upcomingUserCalendarIDs = this.getAllActivePrimaryAndUpcomingUserCalendarIDsForUser({ user, fromWhere: "fetchEventsForUsers" });

        const userCalendarIDs = removeDuplicatesFromArray(getActiveCalendarsForUser({
          allCalendars,
          userEmail: getUserEmail(user),
          upcomingUserCalendarIDs,
        }).map((c) => getCalendarUserCalendarID(c)));
        // returns formatted events
        const {
          formattedEvents,
          response,
        } = await this.getEvents({
          user,
          userCalendarIDs,
          timeMin,
          timeMax,
        });
        if (this._isMounted && !isRetry && shouldRetryBasedOnResponse(response, currentUser)) {
          // checked for 429
          const retryAfterMS = getRetryAfterMS(response);
          await delayByMs(retryAfterMS);
          if (!this._isMounted) {
            return;
          }
          await this.fetchEventsForUsers({
            users: [user],
            syncWindows: [syncWindow],
            onComplete,
            isRetry: true,
          });
        }
        if (!formattedEvents || !this._isMounted) {
          return {};
        }
        if (onComplete) {
          onComplete({
            formattedEvents,
            user,
            timeMin,
            timeMax,
            response,
          });
        }
        // Wait for 10 seconds in between
        await delayByMs(10 * SECOND_IN_MS);
      }
    }
  }

  fetchCurrentWindowEvents(inputSyncWindows) { // takes in array of sync windows [{timeMin, timeMax}]
    const { selectedDay, weekStart, selectedCalendarView, currentTimeZone } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const syncWindows = !isEmptyArrayOrFalsey(inputSyncWindows) ? inputSyncWindows : getCurrentSyncWindow({
      selectedDay: getSelectedDayWithBackup(selectedDay),
      weekStart,
      selectedCalendarView,
    });

    const onComplete = async (result) => {
      if (isEmptyObjectOrFalsey(result)) {
        return;
      }
      const { response, formattedEvents, user, timeMin, timeMax } = result;
      if (isEmptyArrayOrFalsey(formattedEvents) || !this._isMounted) {
        return;
      }
      const { eventsWithinTimeWindow } = await this.wipeAndStoreIntoDB({
        formattedEvents,
        user,
        isCleanRefresh: true,
        timeMin,
        timeMax,
        fromWhere: "fetchCurrentWindowEvents",
      });
      if (!this._isMounted) {
        return;
      }
      broadcast.publish("UPDATE_MENU_BAR_AND_AGENDA");

      if (!this.isSelectedDayInWindow({timeMin, timeMax})) {
        return;
      }

      const timeZoneOption = { timeZone: currentTimeZone };
      const param = {
        eventList: eventsWithinTimeWindow,
        calendarIds: this.getActiveUserCalendarIDsDictionary(),
        lastSyncAtUTC: getResponseLastSyncAtUTC(response),
        deleteEventUserEmails: [getUserEmail(user)],
        windowForRemovingEvents: {
          timeMin: convertToTimeZone(timeMin, timeZoneOption),
          timeMax: convertToTimeZone(timeMax, timeZoneOption),
        },
        fromWhere: "fetchCalendarAndEventsContainer::fetchCurrentWindowEvents",
      };
      broadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.WIPE_OUT_EVENTS_AND_ADD_EVENTS, param);
    };

    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      outlookUsers,
    } = getOutlookAndGoogleUsers({
      allLoggedInUsers,
      currentUser,
      allCalendars,
      masterAccount,
    });
    if (outlookUsers?.length > 0) {
      // get preview event first
      this.getOutlookPreviewEventParentCaller({
        users: outlookUsers,
        inputTimeMin: inputSyncWindows?.[0]?.timeMin,
        inputTimeMax: inputSyncWindows?.[0]?.timeMax,
      });
    }

    const filteredAllLoggedInUsers = allLoggedInUsers.filter(user => !shouldHideDelegatedUser({ user, allCalendars }));
    this.fetchEventsForUsers({
      users: filteredAllLoggedInUsers,
      syncWindows,
      onComplete,
    });
  }

  isSelectedDayInWindow({ timeMin, timeMax }) {
    const {
      selectedDay,
    } = this.props;
    const checkedSelectedDate = getSelectedDayWithBackup(selectedDay);
    if (this.isMainCalendarMonthView()) {
      return isSameMonth(timeMin, checkedSelectedDate)
        || isSameMonth(timeMax, checkedSelectedDate)
        || (
          isSameOrAfterDay(checkedSelectedDate, timeMin)
            && isSameOrBeforeDay(checkedSelectedDate, timeMax)
        );
    }

    return isSameOrAfterDay(checkedSelectedDate, timeMin)
      && isSameOrBeforeDay(checkedSelectedDate, timeMax);
  }

  isTodayInWindow({ timeMin, timeMax }) {
    const today = new Date();
    return (
      isSameOrAfterDay(today, timeMin) && isSameOrBeforeDay(today, timeMax)
    );
  }

  async getEvents({ user, userCalendarIDs, timeMin, timeMax }) {
    if (!getUserEmail(user)) {
      return {};
    }
    const { allCalendars } = this.props.allCalendars;
    const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
      userCalendarIDs,
      allCalendars,
      userEmail: getUserEmail(user),
    });

    if (isEmptyArrayOrFalsey(providerIDs)) {
      return {};
    }
    const url = constructRequestURL(FETCH_CALENDAR_EVENTS_ENDPOINT, true);

    const { currentTimeZone } = this.props;
    const body = {
      timeMin: timeMin.toISOString(),
      timeMax: timeMax.toISOString(),
      timeZone: currentTimeZone,
      calendarIds: providerIDs,
    };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(body),
    };

    if (!this._isMounted) {
      return {};
    }
    const response = await Fetcher.post(url, payloadData, true, getUserEmail(user));
    if (shouldSkipFetchEventsResponse(response) || !this._isMounted) {
      return {};
    }
    const { events } = response;
    return {
      formattedEvents: this.formatFetchedEvents({ events }),
      response,
    };
  }

  findUserCalendarIDsThatIsMostActive() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    let mostActiveUserCalendarIDs = null;
    allLoggedInUsers.forEach((user) => {
      const activeCalendarIDs = this.getAllActivePrimaryAndUpcomingUserCalendarIDsForUser({user, fromWhere: "findUserCalendarIDsThatIsMostActive"});
      if (!mostActiveUserCalendarIDs || activeCalendarIDs.length > mostActiveUserCalendarIDs.length) {
        mostActiveUserCalendarIDs = activeCalendarIDs;
      }
    });
    return mostActiveUserCalendarIDs;
  }

  startBackgroundFetch() {
    const backgroundFetchTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.backgroundSync();
    }, 1 * MINUTE_IN_MS);
    this._timeoutList = this._timeoutList.concat(backgroundFetchTimeout);
  }

  formatFetchedEvents({ events, isPreviewOutlookEvent }) {
    const { currentTimeZone } = this.props;
    return formatEventsArrayForReactBigCalendar({
      events,
      currentTimeZone,
      isPreviewOutlookEvent,
    });
  }
}

function mapStateToProps(state) {
  const {
    currentUser,
    selectedDay,
    currentTimeZone,
    weekStart,
    dateFieldOrder,
    selectedCalendarView,
  } = state;

  return {
    currentUser,
    selectedDay,
    currentTimeZone,
    weekStart,
    dateFieldOrder,
    selectedCalendarView,
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const accountActivity = useAccountActivity();
  const userTimeZoneIndexStore = useUserTimeZoneIndexStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
      accountActivity={accountActivity}
      userTimeZoneIndexStore={userTimeZoneIndexStore}
    />
  );
};

export default connect(
  mapStateToProps,
  null,
)(withStore(FetchCalendarAndEventsContainer));
