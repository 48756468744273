import React from "react";
import CustomButton from "./customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../services/globalVariables";
import Broadcast from "../broadcasts/broadcast";
import {
  isElectron,
  openLinkOnSamePage,
  OpenLink,
} from "../services/commonUsefulFunctions";
import { CALENDAR_PROVIDERS } from "../lib/vimcalVariables";
import { getOutLookLoginURL } from "../lib/outlookFunctions";
import { onClickGoogleLogin } from "../lib/googleFunctions";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { isUserDelegatedUser, isUserMaestroUser } from "../services/maestroFunctions";
import { MAESTRO_ACCOUNT_TYPES } from "../services/globalMaestroVariables";
import { getUserEmail } from "../lib/userFunctions";
import { equalAfterTrimAndLowerCased, truncateString } from "../lib/stringFunctions";

export default function RequestPermissionsModal(props) {
  const { email, provider, showConsentScreen } = props;
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const currentUserEmail = getUserEmail(currentUser);

  const isOutlook = provider === CALENDAR_PROVIDERS.OUTLOOK;

  const messageEmail = email || currentUserEmail;
  const messages = {
    auth: `Your session with ${isOutlook ? "Outlook" : "Google"
      } has expired. Please re-authenticate "${messageEmail}" to continue using Vimcal.`,
    calendar:
      `Certain permissions are required for Vimcal to work correctly. Please re-authenticate "${messageEmail}" to continue.`,
    contacts:
      `Google has changed how contacts are synced. Please update permissions for ${messageEmail} so that your contacts can stay up to date.`,
    email:
      `Additional permissions are required to send emails via your Outlook account. Please re-authenticate "${messageEmail}" to continue.`,
  };

  const matchingUser = allLoggedInUsers.find(user => equalAfterTrimAndLowerCased(getUserEmail(user), messageEmail));
  const getAccountType = () => {
    if (!isUserMaestroUser(masterAccount)) {
      return null;
    }
    if (isUserDelegatedUser(matchingUser)) {
      return MAESTRO_ACCOUNT_TYPES.SCHEDULER_FOR_OTHERS;
    } else {
      return MAESTRO_ACCOUNT_TYPES.MYSELF;
    }
  }
  const accountType = getAccountType();
  if (isOutlook) {
    return (
      <div className="mt-5">
        <div className="default-font-size">{messages[props.messageType]}</div>
        <div className="display-flex justify-content-flex-end mt-8">
          {matchingUser ? <CustomButton
            buttonType={WHITE_BUTTON}
            addPaddingToRight={true}
            onClick={() => {
              Broadcast.publish("CLOSE_LAYOUT_MODAL");
              backendBroadcasts.publish("LOGOUT_OF_SINGLE_ACCOUNT", {user: matchingUser})
            }}
            label={truncateString(`Disconnect ${getUserEmail(matchingUser)}`, 25)}
          /> : null}
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => 
              isElectron()
                ? OpenLink(getOutLookLoginURL({
                    isDesktopLogin: true, 
                    userEmail: email, 
                    accountType,
                    showConsentScreen
                  }))
                : openLinkOnSamePage(getOutLookLoginURL({ 
                    userEmail: messageEmail, 
                    showConsentScreen,
                    accountType,
                  }))
            }
            addPaddingToRight={false}
            label="Re-authenticate"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
      <div className="default-font-size">{messages[props.messageType]}</div>
      <>
        {props.messageType !== "auth" && (
          <img
            src={
              "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/click-all-permissions.png"
            }
            alt={"vimcal-click-all-permissions"}
            style={{ marginTop: "1rem", maxWidth: "100%" }}
          />
        )}
      </>

      <div className="display-flex justify-content-flex-end mt-8">
        {matchingUser ? <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={() => {
            Broadcast.publish("CLOSE_LAYOUT_MODAL");
            backendBroadcasts.publish("LOGOUT_OF_SINGLE_ACCOUNT", {user: matchingUser})
          }}
          label={truncateString(`Disconnect ${getUserEmail(matchingUser)}`, 25)}
        /> : null}
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={() => { onClickGoogleLogin({ hint: messageEmail, showConsentScreen, accountType }) }}
          addPaddingToRight={false}
          label="Re-authenticate"
        />
      </div>
    </div>
  )
}
