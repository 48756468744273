import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MonthlyCalendar from "../components/monthlyCalendar";
import {
  hasStateOrPropsChanged,
  calculateMarginTop,
  getCurrentTimeInCurrentTimeZone,
} from "../services/commonUsefulFunctions";
import AgendaPanel from "../components/agendaPanel";
import Broadcast from "../broadcasts/broadcast";
import SettingsContainer from "../components/settings/settingsContainer";
import { useAllCalendars, useMasterAccount } from "../services/stores/SharedAccountData";
import classNames from "classnames";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { useAppSettings } from "../services/stores/settings";
import { shouldDisplayWeekNumber } from "../lib/settingsFunctions";

class MonthlyCalendarWithEventPreview extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      false,
    );
  }

  render() {
    const {
      shouldHideContent,
      shouldShowTopBar,
      isMobileView,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      isHideMonthlyCalendar,
    } = this.props.appSettings;
    const getMonthlyCalendarMB = () => {
      if (isHideMonthlyCalendar) {
        return "";
      }
      return shouldDisplayWeekNumber({ masterAccount }) ? "mb-4" : "mb-1.5";
    };
    return (
      <div
        className={classNames("monthly-calendar-with-agenda duration-150", shouldHideContent ? "opacity-0" : "")}
        style={{
          paddingTop: calculateMarginTop(shouldShowTopBar, 5),
        }}
        onMouseLeave={this.closeSettingModal}
      >
        <div className={classNames("monthly-calendar-side-bar mt-2", getMonthlyCalendarMB())}>
          <MonthlyCalendar
            modalCalendarSelectedDay={null}
            shouldShowHideCalendarOption={true}
          />
        </div>

        {this.renderEventSidePanelOrAgenda()}

        {isMobileView ? null : <SettingsContainer />}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderEventSidePanelOrAgenda() {
    const { allCalendars } = this.props.allCalendars;
    return !isEmptyObjectOrFalsey(allCalendars) ? (
      <AgendaPanel
        date={
          this.props.agendaDay || getCurrentTimeInCurrentTimeZone(this.props.currentTimeZone)
        }
      />
    ) : <div className="h-full"></div>;
  }

  closeSettingModal() {
    Broadcast.publish("CLOSE_SETTINGS");
  }
}

function mapStateToProps(state) {
  let {
    currentTimeZone,
    agendaDay,
    shouldShowTopBar,
    isDarkMode,
    isMobileView,
  } = state;

  return {
    currentTimeZone,
    agendaDay,
    shouldShowTopBar,
    isDarkMode,
    isMobileView,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const appSettings = useAppSettings();
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} allCalendars={allCalendars} appSettings={appSettings} masterAccount={masterAccount} />;
};

export default connect(mapStateToProps)(
  withRouter(withStore(MonthlyCalendarWithEventPreview)),
);
