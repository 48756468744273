import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, connect } from "react-redux";
import {
  AVAILABILITY,
} from "../../services/googleCalendarService";
import {
  format,
  startOfHour,
  set,
  addDays,
  startOfMinute,
  addMinutes,
  addHours,
  subHours,
} from "date-fns";
import CustomWeek from "./onboardingCustomWeekView";
import _ from "underscore";
import isSameDay from "date-fns/isSameDay";
import classNames from "classnames";
import ShortcutHoverHint from "../shortcutHoverHint";
import GlobalKeyMapTile from "../globalKeyMapTile";
import AvailabilityIcon from "../availability-icon-svg";
import { Plus } from "react-feather";
import CustomEvent from "../customEvent";
import TimeSlotWrapper from "../timeSlotWrapper";
import TimeGutterHeader from "../timeGutterHeader";
import {
  isBeforeMinute,
  updateToStartOfNextDayIfLastSlot,
  RoundToClosestMinuteJSDate,
  determineRBCEventEndWithEventStart,
  guessTimeZone,
  getTimeInAnchorTimeZone,
  handleError,
  determineTimeRemaining,
  formatTimeJSDate,
  convertToTimeZone,
  isValidJSDate,
} from "../../services/commonUsefulFunctions";
import {
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  SELECT_AVAILABILITY_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import { PANEL_CONTENT_AVAILABILITY } from "./sharedVariables";
import AvailabilityPanel from "./availabilityPanel";
import DisappearingNotificationMessage from "../disappearingNotificationMessage";
import OnboardBroadcast from "../../broadcasts/onboardBroadcast";
import {
  determineShadedCalendarHours,
  determineRBCLocalizer,
} from "../../lib/stateManagementFunctions";
import MinifiedMainCalendar from "./minifiedMainCalendar";
import MainCalendarToolbar from "../mainCalendarToolBar";
import ClassNames from "classnames";
import { isNextEventNow } from "../../services/sharedAgendaFunctions";
import EventConferencing from "../eventConferencing";
import { isOnClickSlot, protectMidnightCarryOver } from "../../lib/rbcFunctions";
import { getClientEventID } from "../../services/eventResourceAccessors";
import { getDefaultBackgroundColor } from "../../lib/styleFunctions";
import { createUUID } from "../../services/randomFunctions";
import { getDateTimeFormat } from "../../lib/dateFunctions";

const DUMMY_EVENT = "DUMMY_EVENT";

function MinifiedCalendarHomeView(props) {
  const [localizer] = useState(determineRBCLocalizer(0));
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const [latestTimeZone, setLatestTimeZone] = useState(currentTimeZone); // used to track prev time zone
  const [shadedAvailabiityHours, setShadedAvailabilityHours] = useState({});
  const [renderCount, setRenderCount] = useState(0);
  // const [availabilitySlots, setAvailabilitySlots] = useState([]);
  const availabilitySlots = useRef(props.initialSlots || []);

  const setAvailabilitySlots = (updatedSlots) => {
    availabilitySlots.current = updatedSlots;

    // send slots back to parent component
    props.setSlots && props.setSlots(updatedSlots);

    setRenderCount(renderCount + 1);
  };

  const [shouldDisplayCalendar, setDisplayCalendar] = useState(true);

  const updateAvailabilitySlots = (event) => {
    let newEventsList = availabilitySlots.current.filter(
      (e) => getClientEventID(e) !== getClientEventID(event)
    );
    setAvailabilitySlots(newEventsList);
  };

  useEffect(() => {
    resetTimeZoneToDefault();

    OnboardBroadcast.subscribe(
      "DELETE_AVAILABILITY_EVENT",
      updateAvailabilitySlots
    );
    return () => {
      resetTimeZoneToDefault();
    };
  }, []);

  const resetTimeZoneToDefault = () => {
    const updatedTimeZone = guessTimeZone();
    props.setCurrentTimeZone(updatedTimeZone);
    props.setCurrentTimeZoneLabel(null);
  };

  useEffect(() => {
    setShadedAvailabilityHours(
      determineShadedCalendarHours({
        timeZone: currentTimeZone,
        timeZoneLabel: currentTimeZone,
        defaultTimeZone: guessTimeZone()
      })
    );

    setDisplayCalendar(false);

    let updatedSlots = [];
    availabilitySlots.current.forEach((s) => {
      let clonedEvent = _.clone(s);
      clonedEvent.eventStart = getTimeInAnchorTimeZone(
        s.eventStart,
        latestTimeZone,
        currentTimeZone
      );
      clonedEvent.eventEnd = getTimeInAnchorTimeZone(
        s.eventEnd,
        latestTimeZone,
        currentTimeZone
      );
      clonedEvent.rbcEventEnd = getTimeInAnchorTimeZone(
        s.rbcEventEnd,
        latestTimeZone,
        currentTimeZone
      );

      updatedSlots = updatedSlots.concat(clonedEvent);
    });

    setAvailabilitySlots(updatedSlots);
    setLatestTimeZone(currentTimeZone);
  }, [currentTimeZone]);

  useEffect(() => {
    if (!shouldDisplayCalendar) {
      setDisplayCalendar(true);
    }
  }, [shouldDisplayCalendar]);

  const timeRangeFormat = (slots) => {
    let { start, end } = slots;
    const formatStyle = getDateTimeFormat(format24HourTime);
    return `${format(start, formatStyle)} - ${format(end, formatStyle)}`;
  };

  const renderCustomDayOfWeekHeader = (param) => {
    const { date } = param;
    const isToday = isSameDay(date, new Date());
    return (
      <div className="display-flex flex-direction-column align-items-center pt-4 font-size-12 default-font-color h-14">
        <div className={isToday ? "day-of-week-label-today" : ""}>
          {format(date, "EEE")}
        </div>

        <div
          className={classNames(
            "header-date-box",
            isToday ? "header-date-today" : ""
          )}
        >
          {format(date, "d")}
        </div>
      </div>
    );
  };

  let calendarComponents = {
    event: ({ event }) => <CustomEvent event={event} hideLeftBar={true} />,
    toolbar: MainCalendarToolbar,
    week: {
      header: renderCustomDayOfWeekHeader,
    },
    timeSlotWrapper: TimeSlotWrapper,
    timeGutterHeader: () => (
      <TimeGutterHeader
        hideContent={props.hideGutter}
        glowTimeZone={props.glowTimeZone}
        onClickTimeTravel={props.onClickTimeTravel}
        isMinified={true}
        screen={props.screen}
      />
    ),
  };

  let CALENDAR_TIME_FORMATS = {
    timeGutterFormat: "ha",
    dayFormat: "EEE Mo", // Mon 1st
    // dayRangeHeaderFormat: 'D MMMM dddd',
    eventTimeRangeFormat: _.noop,
    selectRangeFormat: timeRangeFormat,
  };

  const format24HourTime = useSelector((state) => state.format24HourTime);

  const styleGetter = (event) => {
    let style;
    if (
      isBeforeMinute(event.eventStart, new Date()) &&
      isBeforeMinute(event.eventEnd, new Date())
    ) {
      const backgroundColor = isDarkMode ? "#313665" : "#bdc3fe";
      style = {
        color: isDarkMode
          ? FADED_GREY_TEXT_COLOR_DARK_MODE
          : "rgb(132, 131, 131)",
        backgroundColor,
        border: `1px solid ${
          getDefaultBackgroundColor(isDarkMode)
        }`,
      };
    } else {
      style = {
        color: determineTextColor(event, isDarkMode),
        backgroundColor: event.isAvailability
          ? SELECT_AVAILABILITY_COLOR
          : "#5A69FC",
        border: `1px solid ${
          getDefaultBackgroundColor(isDarkMode)
        }`,
      };
    }

    return { style };
  };

  const onSelectSlot = (slot, existingSlots = null) => {
    if (!props.isAvailabilityMode) {
      return;
    }

    const { start, end } = slot;

    if (isBeforeMinute(start, new Date()) && isBeforeMinute(end, new Date())) {
      // show disappearing message
      OnboardBroadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, "You can not select a Slot in the past.");
      return;
    }

    let timeEnd = updateToStartOfNextDayIfLastSlot(slot.end);
    let eventStart = start;
    if (isBeforeMinute(start, new Date())) {
      eventStart = startOfMinute(RoundToClosestMinuteJSDate(new Date(), 5));
    }

    if (isOnClickSlot(slot)) {
      timeEnd = addMinutes(eventStart, 30);
    }

    const newSlot = {
      isTemporary: true,
      isAvailability: true,
      eventStart,
      index: availabilitySlots.current ? availabilitySlots.current.length : 0,
      eventEnd: timeEnd,
      rbcEventEnd: protectMidnightCarryOver(determineRBCEventEndWithEventStart(eventStart, timeEnd)),
      status: AVAILABILITY,
      raw_json: { status: AVAILABILITY },
      id: createUUID(),
    };

    if (existingSlots) {
      setAvailabilitySlots(existingSlots.concat(newSlot));
    } else {
      setAvailabilitySlots(availabilitySlots.current.concat(newSlot));
    }
  };

  const dummyEvents = useMemo(() => {
    return createDummyEvents(props, currentTimeZone);
  }, [props.hideEvents, currentTimeZone]);

  const onMoveEvent = ({ event, start, end }) => {
    if (!isValidJSDate(start) || !isValidJSDate(end)) {
      return;
    }
    const newEventsList = availabilitySlots.current.filter(
      (e) => getClientEventID(e) !== getClientEventID(event)
    );
    onSelectSlot({ event, start, end }, newEventsList);
  };

  const slotGetter = (info) => {
    // this.state.shadedAvailabiityHours {start: 6, end: 16}
    const hour = info.getHours();

    let shouldShade =
      hour <= shadedAvailabiityHours.start ||
      hour >= shadedAvailabiityHours.end;

    return { className: shouldShade ? "time-slot-time-zone-slot-shade" : "" };
  };

  const events = dummyEvents.concat(availabilitySlots.current);

  const renderCoverOverLay = () => {
    if (!props.shouldDisplayOverlay) {
      return null;
    }

    return (
      <div className="absolute w-full h-full z-10">
      </div>
    );
  };

  return (
    <div
      className={classNames("onboarding-calendar-container", "position-relative", props.className ?? "", "w-full")}
    >
      {renderCoverOverLay()}
      <DisappearingNotificationMessage isOnboarding={true} />
      {props.children || null}
      <div className="relative">
        <MinifiedMainCalendar
          params={{
            events,
            onMoveEvent,
            localizer,
            calendarComponents,
            calendarTimeFormats: CALENDAR_TIME_FORMATS,
            CustomWeek,
            styleGetter,
            onSelectSlot,
            isAvailabilityMode: props.isAvailabilityMode,
            slotGetter,
            currentTimeZone,
            showToolBar: props.showToolBar,
          }}
          shouldDisplayCalendar={shouldDisplayCalendar}
        />

        {renderAvailabilityButton(props)}
        {renderCreateEventButton(props)}
      </div>
      {renderPanel(
        props,
        availabilitySlots,
        currentTimeZone
      )}

      <div className="minified-calendar-right-panel">
        <div className="greyed-out-areas w-24 h-4 mt-4"></div>

        <div className="greyed-out-areas mt-4 w-48 h-32"></div>

        <div className="greyed-out-areas height-1px w-72 mt-5"></div>
        {props.showUpcomingEvent
          ? renderNextEvent(props, events[0], 0)
          : renderGreyedOutAgendaSection()}
      </div>
    </div>
  );
}

function renderGreyedOutAgendaSection() {
  return (
    <>
      <div className="greyed-out-areas h-5 w-32 mt-5 self-start ml-8"></div>

      <div className="greyed-out-areas h-20 w-48 mt-5 self-start ml-8"></div>

      <div className="greyed-out-areas h-10 w-48 mt-5 self-start ml-8"></div>
      <div className="greyed-out-areas h-10 w-48 mt-5 self-start ml-8"></div>
    </>
  );
}

function renderNextEvent(props, nextEvent, index) {
  let { timeRemaining, timeRemainingText } = determineTimeRemaining(nextEvent);
  let furthestReminderMin = 10;

  return (
    <div
      key={`next_event_${index}`}
      className="agenda-next-event-wrapper margin-left-34px mt-4"
      style={{ borderBottom: "0px solid transparent" }}
    >
      <div
        className={ClassNames(
          "up-next-section",
          index > 0 ? "mt-5" : "margin-top-5"
        )}
      >
        {timeRemaining < furthestReminderMin
          ? <div className="agenda-upnext-text mr-2.5">
              {isNextEventNow(nextEvent) ? "NOW" : "Up Next"}
            </div>
          : null
        }

        <div
          className={`time-remaining ${
            timeRemaining < furthestReminderMin ? "time-remaining-warning" : ""
          }`}
        >
          {timeRemainingText}
        </div>
      </div>

      {props.showUpNextArrow ? (
        <div className="arrow blink">SOME TEXT</div>
      ) : null}

      <div className="agenda-next-event-summary">
        <GlobalKeyMapTile
          style={{ top: "-5px", left: "-26px", fontWeight: 300 }}
          shortcut={"N"}
        />

        {nextEvent.summaryUpdatedWithVisibility}
      </div>

      <div className="agenda-upnext-time-wrapper">
        <div className="agenda-subsection-label">Time:</div>

        <div className="inline-block font-weight-300">
          {formatTimeJSDate(
            convertToTimeZone(nextEvent.defaultStartTime, {
              timeZone: guessTimeZone(),
            }),
            false
          ) +
            " - " +
            formatTimeJSDate(
              convertToTimeZone(nextEvent.defaultEndTime, {
                timeZone: guessTimeZone(),
              }),
              false
            )}
        </div>
      </div>

      {/* <div className="agenda-location-wrapper">
        <div className="agenda-subsection-label">Location:</div>

        <div className="agenda-location-text font-weight-300">
          <EventLocation
            agendaPanelStyle={{ left: "-95px", top: "-4px", zIndex: 1 }}
            location={filterForLocation(nextEvent)}
            hideIcon={true}
            shouldIgnoreBroadcast={true}
            displayTitleWithCopy={true}
          />
        </div>
      </div> */}

      <div className="agenda-conference-wrapper">
        <div className="agenda-subsection-label">Video:</div>

        <div className="inline-block font-weight-300">
          <EventConferencing
            agendaPanelStyle={{ left: "-95px", top: "-3px" }}
            conferenceUrl={nextEvent.conferenceUrl}
            hideIcon={true}
            shouldIgnoreBroadcast={true}
            displayTitleWithCopy={true}
          />
        </div>
      </div>
    </div>
  );
}

function renderAvailabilityButton(props) {
  if (props.isAvailabilityMode || props.hideAvailabilityButton) {
    return null;
  }

  return (
    <ShortcutHoverHint
      above
      style={{
        marginLeft: "-25px",
        top: "-55px",
        position: "absolute",
      }}
      containerStyle={{
        position: "absolute",
        bottom: 20,
        right: props.hideCreate ? 32 : 104,
        zIndex: 2,
      }}
      title={"Select availability"}
      shortcut={"A"}
    >
      <GlobalKeyMapTile
        // style={createAvailabilityKeyMapHint}
        shortcut={"A"}
      />

      {props.showAvailabilityHint ? (
        <div
          className="helper-hints"
          style={{
            width: 150,
            left: -180,
          }}
        >
          Click Here to toggle Availability mode
        </div>
      ) : null}

      <button
        className={classNames(
          "hoverable-button",
          "create-availability-button",
          props.shouldAnimateAvailability
            ? "availability-attention-animation"
            : ""
        )}
        style={{
          width: 50,
          height: 50,
          borderRadius: 35,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          border: "none",
        }}
        onClick={props.onClickAvailabilty}
      >
        <AvailabilityIcon />
      </button>
    </ShortcutHoverHint>
  );
}

function renderCreateEventButton(props) {
  if (props.hideCreate) {
    return null;
  }

  return (
    <ShortcutHoverHint
      above
      style={{ marginLeft: "-12px", top: "-39px" }}
      containerStyle={{
        position: "absolute",
        bottom: 20,
        right: 30,
        zIndex: 2,
      }}
      title={"Create"}
      shortcut={"C"}
    >
      <GlobalKeyMapTile
        // style={createAvailabilityKeyMapHint}
        shortcut={"C"}
      />

      <button
        // ref="button"
        className="hoverable-button create-event-button"
        style={{
          width: 50,
          height: 50,
          borderRadius: 35,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          border: "none",
        }}
        // onClick={this.toggleAvailabilityMode}
      >
        <Plus color={"white"} size="25" />
      </button>
    </ShortcutHoverHint>
  );
}

function createDummyEvents(props, inputTimeZone) {
  if (props.hideEvents) {
    return [];
  }

  const guessedTimeZone = guessTimeZone();
  let timeZone = inputTimeZone || guessedTimeZone;

  const convertToCurrentTimeZone = (jsDate) => {
    if (guessedTimeZone === timeZone) {
      return jsDate;
    }

    return getTimeInAnchorTimeZone(jsDate, guessedTimeZone, timeZone);
  };

  return [
    {
      summaryUpdatedWithVisibility: "Family Zoom", // Dummy event
      eventStart: convertToCurrentTimeZone(RoundToClosestMinuteJSDate(new Date(), 30)),
      eventEnd: convertToCurrentTimeZone(startOfHour(addHours(new Date(), 2))),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(addHours(new Date(), 2))
      ),
      user_event_id: createUUID(),
      defaultStartTime: convertToCurrentTimeZone(RoundToClosestMinuteJSDate(new Date(), 30)).toISOString(),
      defaultEndTime: convertToCurrentTimeZone(
        startOfHour(addHours(new Date(), 2))
      ).toISOString(),
      conferenceUrl: "https://zoom.us/j/preview",
    },
    {
      summaryUpdatedWithVisibility: "🌮 Lunch - Tacos",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 1), { hours: 12 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 1), { hours: 14 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 1), { hours: 14 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Dinner",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 18 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 19 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 19 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Review launch strategy",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(new Date(), { hours: 9 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(new Date(), { hours: 11 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(new Date(), { hours: 11 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "1:1",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 15 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 16 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 16 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "☕ Coffee",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 5), { hours: 10 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 5), { hours: 11 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 5), { hours: 11 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Walk meeting",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 4), { hours: 15 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 4), { hours: 16 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 4), { hours: 16 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Board meeting",
      eventStart: convertToCurrentTimeZone(
        startOfHour(subHours(new Date(), 2))
      ),
      eventEnd: convertToCurrentTimeZone(startOfHour(subHours(new Date(), 1))),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(subHours(new Date(), 1))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Marketing review",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 10 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 11 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 2), { hours: 11 }))
      ),
      user_event_id: createUUID(),
    },
    {
      summaryUpdatedWithVisibility: "Creative Workout",
      eventStart: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 11 }))
      ),
      eventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 13 }))
      ),
      uniqueEtag: 12345,
      user_calendar_id: 12345,
      status: DUMMY_EVENT,
      raw_json: { status: DUMMY_EVENT },
      isDummy: true,
      rbcEventEnd: convertToCurrentTimeZone(
        startOfHour(set(addDays(new Date(), 3), { hours: 13 }))
      ),
      user_event_id: createUUID(),
    },
  ];
}

function renderPanel(
  props,
  availabilitySlots,
  currentTimeZone
) {
  const {
    onClickShowMeHowSlots
  } = props;

  const onCopyAvailability = (content, slots) => {
    navigator.clipboard
      .writeText(content)
      .then(
        () => {
          /* clipboard successfully set */
        },
        function () {
          /* clipboard write failed */
        }
      )
      .catch((e) => {
        handleError(e);
      });

    props.onToggleAvailability();
  };
  return (
    <div
      id="onboarding-calendar-panel"
      className={props.panelContent ? "panel-open" : ""}
    >
      <div className={classNames(props.panelContent === PANEL_CONTENT_AVAILABILITY ? "" : "overflow-x-hidden w-0 invisible")}>

        <AvailabilityPanel
          availabilitySlots={availabilitySlots.current}
          onCopyAvailability={onCopyAvailability}
          currentTimeZone={currentTimeZone}
          stopAvailabilityFlash={props.stopAvailabilityFlash}
          showUnderline={props.showTimeZoneUnderline}
          blurAvailabilityCopy={props.blurAvailabilityCopy}
          onClickShowMeHowSlots={onClickShowMeHowSlots}
        />
      </div>
    </div>
  );
}

function determineTextColor(event, isDarkMode) {
  if (event.isDummy) {
    return "white";
  } else if (event.isAvailability) {
    return getDefaultBackgroundColor(isDarkMode);
  }

  return "white";
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "SET_TIME_ZONE" }),
    setCurrentTimeZoneLabel: (day) =>
      dispatch({ data: day, type: "SET_TIME_ZONE_LABEL" }),
  };
}

export default connect(null, mapDispatchToProps)(MinifiedCalendarHomeView);
