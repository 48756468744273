import React from "react";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import CustomButtonV2 from "../../buttons/customButtonV2";

/**
 * If a user is in a trial state and they're trying to create a team plan, we should only
 * allow this if they have not been on a team plan before. If they have already been on a
 * team plan and are trying to create another one while in the trial state, we should
 * capture their payment method first.
 */
export default function NeedPaymentSettings() {
  const onClick = () => {
    layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL");
  };

  return (
    <>
      <div className="default-font-size">
        Before creating your team plan, please provide a payment method.
      </div>
      <div className="mt-2">
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          label="Add payment method"
          onClick={onClick}
        />
      </div>
    </>
  );
}
