import React from "react";

const TomatoOn = ({className}) => (
  <div className={className ?? ""}>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0493 11.6832L13.6808 11.313C12.6588 10.2908 12.6588 8.63374 13.6808 7.61151V7.61151C14.703 6.58956 16.3601 6.58956 17.3823 7.61151C17.3823 6.16576 18.5543 4.99375 20.0001 4.99375V4.99375C21.4458 4.99375 22.6178 6.16576 22.6178 7.61151C23.6401 6.58956 25.2971 6.58956 26.3194 7.61151V7.61151C27.3413 8.63374 27.3413 10.2908 26.3194 11.313L25.9525 11.6799"
        stroke="white"
        strokeOpacity="0.9"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6645 11.6632C16.4606 11.824 18.2434 12.1093 20 12.5169C21.7567 12.1093 23.5395 11.824 25.3356 11.6632V11.6632C30.6711 11.6632 33.3389 15.8483 33.3389 21.6674C33.3389 29.0342 27.3669 35.0063 20 35.0063C12.6332 35.0063 6.66113 29.0342 6.66113 21.6674C6.66113 15.8483 9.32891 11.6632 14.6645 11.6632"
        stroke="white"
        strokeOpacity="0.9"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default TomatoOn;
