import React, { useState } from "react";
import AvailabilitySectionContainer from "./availabilitySectionContainer";
import {
  getGroupVoteURLLink,
  isGroupVoteLinkExpired,
} from "../../lib/availabilityFunctions";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import classNames from "classnames";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { getUserEmail, getUserName } from "../../lib/userFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { constructRequestURL } from "../../services/api";
import { isVersionV2 } from "../../services/versionFunctions";
import Fetcher from "../../services/fetcher";
import {
  GROUP_SPREADSHEET_LINKS_ENDPOINT,
  LATEST_GROUP_VOTE_LINKS,
} from "../../lib/endpoints";
import { handleError } from "../../services/commonUsefulFunctions";
import { isSpreadsheetGroupLink } from "../../lib/groupVoteFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import CustomButtonV2 from "../buttons/customButtonV2";

export default function GroupVoteInfoContainer({
  bookingLinkInfo,
  uniqueKey,
  onEdit,
  onCopyLink,
  onDuplicate,
  onDelete,
  selectedUser,
}) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [isFetchingOnCreate, setIsFetchingOnCreate] = useState(false);
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);
  const [isExpired] = useState(isGroupVoteLinkExpired(bookingLinkInfo));
  const componentIsMounted = useIsMounted();

  const onClickDuplicate = () => {
    onDuplicate && onDuplicate(bookingLinkInfo);
  };

  const onClickTrash = () => {
    onDelete && onDelete(bookingLinkInfo);
  };

  const onClickEdit = () => {
    onEdit && onEdit(bookingLinkInfo);
  };

  const copyOnlyLink = () => {
    onCopyLink && onCopyLink(bookingLinkInfo);
  };

  const onClickShare = () => {
    const { userName } = getUserName({
      masterAccount,
      user: selectedUser,
    });
    const isSpreadsheet = isSpreadsheetGroupLink(bookingLinkInfo);
    availabilityBroadcast.publish("OPEN_GROUP_VOTE_LINK_INVITE", {
      token: bookingLinkInfo?.token,
      groupVoteLinkURL: getGroupVoteURLLink({
        userName,
        slug: bookingLinkInfo?.slug,
        token: bookingLinkInfo?.token,
        isSpreadsheet,
      }),
      isSpreadsheet,
    });
  };

  const getLatestGroupVote = async () => {
    const url = constructRequestURL(LATEST_GROUP_VOTE_LINKS, isVersionV2());
    if (!getUserEmail(selectedUser)) {
      return;
    }
    return Fetcher.get(url, {}, true, getUserEmail(selectedUser));
  };

  const getLatestGroupVoteSpreadsheet = async () => {
    const url = constructRequestURL(
      GROUP_SPREADSHEET_LINKS_ENDPOINT,
      isVersionV2()
    );
    if (!getUserEmail(selectedUser)) {
      return;
    }
    return Fetcher.get(url, {}, true, getUserEmail(selectedUser));
  };

  const onClickPreview = async () => {
    try {
      setIsFetchingPreview(true);
      await fetchLatestVersion();
      if (!componentIsMounted.current) {
        return;
      }
      setIsFetchingPreview(false);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchLatestVersion = async () => {
    if (isSpreadsheetGroupLink(bookingLinkInfo)) {
      try {
        const result = await getLatestGroupVoteSpreadsheet();
        if (!componentIsMounted.current) {
          return;
        }
        const matchingGroupVote = result?.group_spreadsheet_links?.find(
          (groupVote) => groupVote.token === bookingLinkInfo.token
        );
        availabilityBroadcast.publish(
          "OPEN_GROUP_VOTE_EVENT_PICKER",
          matchingGroupVote ?? bookingLinkInfo
        );
      } catch (error) {
        handleError(error);
        availabilityBroadcast.publish(
          "OPEN_GROUP_VOTE_EVENT_PICKER",
          bookingLinkInfo
        );
      }
    } else {
      try {
        const result = await getLatestGroupVote();
        if (!componentIsMounted.current) {
          return;
        }
        const matchingGroupVote = result?.group_vote_links?.find(
          (groupVote) => groupVote.token === bookingLinkInfo.token
        );
        availabilityBroadcast.publish(
          "OPEN_GROUP_VOTE_EVENT_PICKER",
          matchingGroupVote ?? bookingLinkInfo
        );
      } catch (error) {
        handleError(error);
        availabilityBroadcast.publish(
          "OPEN_GROUP_VOTE_EVENT_PICKER",
          bookingLinkInfo
        );
      }
    }
  };

  const onClickCreateEvent = async () => {
    setIsFetchingOnCreate(true);
    await fetchLatestVersion();
    if (!componentIsMounted.current) {
      return;
    }
    setIsFetchingOnCreate(false);
  };

  return (
    <AvailabilitySectionContainer
      bookingLinkInfo={bookingLinkInfo}
      uniqueKey={uniqueKey}
      onClickPreview={onClickPreview}
      previewText={"View results"}
      onClickDuplicate={onClickDuplicate}
      onClickTrash={onClickTrash}
      onClickEdit={onClickEdit}
      copyOnlyLink={copyOnlyLink}
      onClickShare={onClickShare}
      isExpired={isExpired}
      isFetchingPreview={isFetchingPreview}
    >
      {isExpired ? (
        <div className="absolute free-trial-label right-5 bottom-16">
          Expired
        </div>
      ) : (
        <div className={classNames("absolute bottom-2 right-4")}>
          <CustomButtonV2
            buttonType={BLUE_BUTTON}
            onClick={onClickCreateEvent}
            label={"Create event"}
            shouldRenderSpinner={isFetchingOnCreate}
          />
        </div>
      )}
    </AvailabilitySectionContainer>
  );
}
