import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import GroupVoteSchedulingTable from "./groupVoteSchedulingTable";
import { Calendar } from "rbc-fork-react-big-calendar";
import StatelessCalendarToolBar from "./statelessCalendarToolBar";
import StatelessCalendarDayHeader from "./statelessCalendarDayHeader";
import { determineRBCLocalizer } from "../../lib/stateManagementFunctions";
import { useSelector } from "react-redux";
import _ from "underscore";
import { format, startOfHour, subHours, isSameMinute } from "date-fns";
import {
  defaultSlotGetter,
  defaultCustomDayLayout,
} from "../../lib/rbcFunctions";
import {
  SELECT_AVAILABILITY_COLOR,
  BLUE_BUTTON,
} from "../../services/globalVariables";
import {
  determineSlotAttendeeIndex,
  createKeyFromSlot,
  createTemporaryEvent,
  isSameSlot,
  getInitialBookingLinkDay,
  getSlotWithMostNumberOfVotes,
  isSlotInSelectSlots,
  parseEventsWithDefaultTimeZone,
} from "../../lib/availabilityFunctions";
import CustomEvent from "../customEvent";
import DisabledButton from "../disabledButton";
import CustomButton from "../customButton";
import { isValidJSDate } from "../../services/commonUsefulFunctions";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import useMainCalendarEvents from "../../services/customHooks/useMainCalendarEvents";
import useDefaultEventStyle from "../../services/customHooks/useDefaultEventStyle";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getDefaultBackgroundColor } from "../../lib/styleFunctions";
import { getEventUniqueKey } from "../../lib/eventFunctions";
import { GROUP_VOTE_CALENDAR_VIEW_PREVIEW } from "../../services/elementIDVariables";
import SelectVoteOrChronologicallyDropdown from "../availability/selectVoteOrChronologicallyDropdown";
import {
  SORT_BY_CHRONOLOGICALLY_OPTION,
  SORT_BY_VOTE_OPTION,
} from "./schedulingSharedVariables";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { getWorkHours } from "../../lib/settingsFunctions";
import { createUUID } from "../../services/randomFunctions";
import { getDateTimeFormat } from "../../lib/dateFunctions";

const CALENDAR_COMPONENTS = {
  event: CustomEvent,
  toolbar: StatelessCalendarToolBar,
  week: {
    header: StatelessCalendarDayHeader,
  },
};

export default function GroupVotePreview({
  bookingLink,
  skipDarkMode,
  isSelectEvent,
  closeModal,
  setName,
  onClickSaveAttendeeSlots,
  skipAnonymous,
  isPreview,
  selectedTimeZone,
}) {
  // const bookingLink = EXAMPLE_BOOKING_LINK
  // skipDarkMode is to remove dark mode styling on preview
  const [isTable, setIsTable] = useState(true);
  const weekStart = useSelector((state) => state.weekStart);
  const [localizer] = useState(determineRBCLocalizer(parseInt(weekStart || 0)));
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);

  const shouldDisplayDarkModeContent = () => {
    return isDarkMode && !skipDarkMode;
  };

  const newAttendeeSlots = useRef([]);
  const [newUserName, setUserName] = useState("");
  const [rerenderCount, setRerenderCount] = useState(0);
  const [sortBy, setSortBy] = useState(
    isSelectEvent ? SORT_BY_VOTE_OPTION : SORT_BY_CHRONOLOGICALLY_OPTION,
  );

  const [currentDate, setCurrentDate] = useState(
    getInitialBookingLinkDay(bookingLink),
  );
  const [mainCalendarEvents, fetchEvents] = useMainCalendarEvents({
    currentDate,
    shouldSkipFetchEvents: !isSelectEvent,
  });
  const determineCalendarStyle = useDefaultEventStyle({
    ignoreDarkMode: skipDarkMode,
    checkTagColor: true,
  });

  const onChangeUserName = (e) => {
    const name = getInputStringFromEvent(e);
    setName && setName(name);
    setUserName(name);
  };

  const hasNewAttendeeCheckedSlot = (slot) => {
    return newAttendeeSlots.current.some((s) => isSameSlot(s, slot));
  };

  const renderTableOrCalendarPicker = () => {
    if (window.outerHeight < 800) {
      // no point in showing calendar if screen is too small
      return null;
    }
    return (
      <>
        <div
          className={classNames(
            "cursor-pointer mr-5 flex flex-col items-center select-none",
            "duration-200",
            isTable ? "group-vote-selected-blue" : "group-vote-unselected-gray",
            "font-size-14",
          )}
          onClick={() => setIsTable(true)}
        >
          Table {renderUnderline(isTable)}
        </div>
        <div
          className={classNames(
            "cursor-pointer flex flex-col items-center select-none",
            "duration-200",
            !isTable
              ? "group-vote-selected-blue"
              : "group-vote-unselected-gray",
            "font-size-14",
          )}
          onClick={() => setIsTable(false)}
        >
          Calendar {renderUnderline(!isTable)}
        </div>
      </>
    );
  };

  const onClickEvent = (slot) => {
    if (isSelectEvent) {
      selectedEvent.current = slot;
      setRerenderCount(createUUID());
    } else if (hasNewAttendeeCheckedSlot(slot)) {
      const updatedSlots = newAttendeeSlots.current.filter(
        (s) => !isSameSlot(s, slot),
      );
      newAttendeeSlots.current = updatedSlots;
      setSelectedSlots(getSelectedSlotsForRbc());
    } else {
      const updatedSlots = newAttendeeSlots.current.concat({
        eventStart: slot.eventStart,
        eventEnd: slot.eventEnd,
      });
      newAttendeeSlots.current = updatedSlots;
      setSelectedSlots(getSelectedSlotsForRbc());
    }
  };

  const getSelectedSlotsForRbc = (inputSlots) => {
    const filteredSlotList = parseEventsWithDefaultTimeZone(bookingLink, selectedTimeZone);
    const availabilityEvents = filteredSlotList.map((s, index) => {
      const { eventStart, eventEnd } = s;
      const key = createKeyFromSlot(s, selectedTimeZone);
      const slotCount =
        slotCountIndex && slotCountIndex[key] ? slotCountIndex[key].length : 0;

      return {
        ...createTemporaryEvent({
          startTime: eventStart,
          endTime: eventEnd,
          index,
          hideCancel: true,
        }),
        displayGroupVoteInfo: true,
        hideCheckBox: isSelectEvent,
        slotCount,
        onClickEvent,
        isChecked: hasNewAttendeeCheckedSlot(s),
        hideSlotCount: !skipAnonymous && bookingLink.anonymous, // do not need to hide on create event
        isGroupVote: true,
        resourceId: currentUser?.email,
      };
    });

    if (!isSelectEvent) {
      return availabilityEvents;
    }

    const additionalSlots = inputSlots ?? mainCalendarEvents;
    return additionalSlots.concat(availabilityEvents);
  };

  const [slotCountIndex] = useState(determineSlotAttendeeIndex(bookingLink));
  const [selectedSlots, setSelectedSlots] = useState(getSelectedSlotsForRbc());
  const selectedEvent = useRef(
    getSlotWithMostNumberOfVotes(slotCountIndex, selectedSlots),
  );

  useEffect(() => {
    // recalculate events whenever main calendar events change
    setSelectedSlots(getSelectedSlotsForRbc(mainCalendarEvents));
  }, [mainCalendarEvents]);

  const renderUnderline = (isSelected) => {
    return (
      <div
        className={classNames(
          "w-10 h-1 mt-1.5 rounded",
          isSelected ? "bg-blue-500" : "bg-transparent",
        )}
      ></div>
    );
  };

  const renderScheduler = () => {
    if (isTable) {
      return (
        <div
          className={classNames(
            "overflow-y-auto",
            "group-vote-table-scroll-wrapper",
          )}
        >
          <div className="flex w-full justify-center">
            <GroupVoteSchedulingTable
              sortBy={sortBy?.value}
              selectedEvent={selectedEvent.current}
              bookingLink={bookingLink}
              newAttendeeSlots={newAttendeeSlots.current}
              setNewAttendeeSlot={onClickEvent}
              newUserName={newUserName}
              onChangeUserName={onChangeUserName}
              skipDarkMode={skipDarkMode}
              hideInputField={isSelectEvent}
              onSelectColumn={onClickEvent}
              skipAnonymous={skipAnonymous}
              selectedTimeZone={selectedTimeZone}
              isPreview={isPreview}
            />
          </div>
        </div>
      );
    } else {
      return renderCalendar();
    }
  };

  const onClickSave = () => {
    onClickSaveAttendeeSlots &&
      onClickSaveAttendeeSlots(newAttendeeSlots.current);
  };

  const onClickCreate = () => {
    availabilityBroadcast.publish(
      "CREATE_EVENT_FROM_BOOKING_LINK",
      bookingLink,
      selectedEvent.current,
    );
    closeModal && closeModal();
  };

  const renderSaveButton = () => {
    if (isSelectEvent) {
      if (
        !(
          selectedEvent.current &&
          selectedEvent.current.eventStart &&
          isValidJSDate(selectedEvent.current.eventStart)
        )
      ) {
        return <DisabledButton label="Create" />;
      }
      return (
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={onClickCreate}
          addPaddingToRight={true}
          label="Create"
        />
      );
    } else {
      if (newAttendeeSlots.current.length === 0) {
        return <DisabledButton label="Save" />;
      }

      return (
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={onClickSave}
          addPaddingToRight={true}
          label="Save"
        />
      );
    }
  };

  const renderCalendar = () => {
    const timeRangeFormat = (slots) => {
      const { start, end } = slots;
      const formatStyle = getDateTimeFormat(format24HourTime);
      return `${format(start, formatStyle)} - ${format(end, formatStyle)}`;
    };

    const CALENDAR_TIME_FORMATS = {
      timeGutterFormat: "ha",
      dayFormat: (date) => {
        return date.toISOString();
      },
      // dayRangeHeaderFormat: 'D MMMM dddd',
      eventTimeRangeFormat: _.noop,
      selectRangeFormat: timeRangeFormat,
    };

    const eventStyleGetter = (event) => {
      const isSelectedEvent =
        event.isAvailability &&
        selectedEvent.current &&
        selectedEvent.current.eventStart &&
        isSameMinute(event.eventStart, selectedEvent.current.eventStart) &&
        selectedEvent.current.eventEnd &&
        isSameMinute(event.eventEnd, selectedEvent.current.eventEnd);

      const isEventInSelectedSlots =
        !isSelectEvent && isSlotInSelectSlots(event, newAttendeeSlots.current);

      const determineBorderColor = () => {
        if (isSelectEvent && isSelectedEvent) {
          return shouldDisplayDarkModeContent() ? "white" : "#4A90E2";
        } else if (isEventInSelectedSlots) {
          return shouldDisplayDarkModeContent() ? "white" : "#4A90E2";
        }

        return getDefaultBackgroundColor(shouldDisplayDarkModeContent());
      };

      const availabilityStyle = {
        backgroundColor: SELECT_AVAILABILITY_COLOR,
        color: getDefaultBackgroundColor(shouldDisplayDarkModeContent()),
        borderWidth: isSelectedEvent || isEventInSelectedSlots ? "3px" : "1px",
        borderColor: determineBorderColor(),
        borderStyle: "solid",
        // transition: "border-width 0.1s ease-in-out"
      };
      if (event.isAvailability) {
        return { style: availabilityStyle };
      } else {
        return { style: determineCalendarStyle(event) };
      }
    };

    const determineInitialScroll = () => {
      return subHours(startOfHour(currentDate), 1);
    };

    const onNavigate = (date) => {
      setCurrentDate(date);
      fetchEvents(date);
    };

    const getShadedWorkHours = (date) => {
      return defaultSlotGetter({
        date,
        workHours: getWorkHours({ masterAccount, user: currentUser }),
      });
    };

    return (
      <>
        {isSelectEvent ? null : (
          <div className="w-full py-2.5 group-vote-selector-background-color flex justify-center mb-2.5">
            <input
              value={newUserName}
              onChange={onChangeUserName}
              placeholder={"Enter your name"}
              autoComplete="new-password"
              className="group-vote-name-input w-80"
            />
          </div>
        )}
        <div className="group-scheduling-calendar-wrapper">
          <Calendar
            keyAccessor={getEventUniqueKey}
            date={currentDate}
            onNavigate={onNavigate}
            className={classNames(
              "remove-rbc-day-header-click",
              "group-vote-calendar",
              skipDarkMode ? "remove-dark-mode-rbc-style" : "modal-rbc-calendar",
            )}
            events={selectedSlots}
            defaultView={"week"}
            localizer={localizer}
            components={CALENDAR_COMPONENTS}
            step={15}
            timeslots={4}
            toolbar={true}
            slotPropGetter={getShadedWorkHours}
            startAccessor="eventStart"
            endAccessor="eventEnd"
            allDayAccessor="displayAsAllDay"
            showMultiDayTimes={true}
            dayLayoutAlgorithm={defaultCustomDayLayout}
            formats={CALENDAR_TIME_FORMATS}
            eventPropGetter={eventStyleGetter}
            scrollToTime={determineInitialScroll()}
          />
        </div>
      </>
    );
  };
  return (
    <div
      className={classNames(
        "preview-table-calendar-container",
        skipDarkMode ? "" : "group-vote-dark-mode-wrapper",
      )}
      id={GROUP_VOTE_CALENDAR_VIEW_PREVIEW}
    >
      <div className="flex items-center justify-center w-full mb-2.5 relative">
        {isSelectEvent && isTable && !isPreview ? (
          <div className="absolute bottom-0.5 right-0">
            <SelectVoteOrChronologicallyDropdown
              value={sortBy}
              onSet={setSortBy}
            />
          </div>
        ) : null}
        {renderTableOrCalendarPicker()}
      </div>
      {renderScheduler()}
      {isPreview ? (
        <div className="h-4"></div>
      ) : (
        <div
          className={classNames(
            "w-full h-16 sticky -bottom-5 flex items-center justify-end",
            shouldDisplayDarkModeContent()
              ? "modal-background-color"
              : "bg-white",
          )}
        >
          {renderSaveButton()}
        </div>
      )}
    </div>
  );
}
