import React, { useState } from "react";
import BillingPaymentMethod from "./billingPaymentMethod";
import BillingReceipts from "./billingReceipts";
import BillingSummary from "./billingSummary";
import CancelModal from "./cancelModal";
import ContactVimcal from "./contactVimcal";

/**
 * The billing settings a user sees when they are an admin of their subscription. This
 * includes users who are on an individual plan as well as users who are admins on a
 * team plan.
 */
export default function AdminBillingSettings() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <div className="pr-6">
      <BillingSummary />
      <BillingPaymentMethod />
      <BillingReceipts />
      <CancelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        message={message}
        setMessage={setMessage}
      />
      <ContactVimcal setIsCancelModalOpen={setIsModalOpen} />
    </div>
  );
}
