import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import { getDateTimeFormat } from "../lib/dateFunctions";

export default function BigClock() {
  const [renderCounter, setRenderCounter] = useState("");
  const format24HourTime = useSelector((state) => state.format24HourTime);

  const setTime = (time: string) => {
    setRenderCounter(time);
  };

  useEffect(() => {
    focusModeBroadcast.subscribe("SET_BIG_CLOCK_TIME", setTime);

    return () => {
      focusModeBroadcast.unsubscribe("SET_BIG_CLOCK_TIME");
    };
  }, []);

  return (
    <div className="big-clock select-none flex justify-center">
      {format(new Date(), getDateTimeFormat(format24HourTime))}
    </div>
  );
}
