import React, { useEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom'
import { CombinedTags, Tags } from "./tagsVariables";
import { ValidDisplayLocations } from "./tagsVariables";
import { displayLabelFromTags, matchingSettingTagInSmartTags } from "../../lib/tagsFunctions";
import TagsIcon from "./icons/tagsIcon";
import CheckBox from "../checkbox";
import { useSelector } from "react-redux";
import AutoTagStar from "../settings/features/tags/autoTagStar";
import classNames from "classnames";

type TagOptionProps = {
  displayLocation: ValidDisplayLocations;
  eventTags: Tags;
  isSelector?: boolean;
  tag: CombinedTags;
  shouldRenderCheckbox?: boolean;
};

const TagOption: React.FC<TagOptionProps> = (props) => {
  const {
    displayLocation,
    eventTags,
    isSelector = false,
    tag,
    shouldRenderCheckbox = true,
  } = props;
  const [isSmartTagHovered, setIsSmartTagHovered] = useState(false);
  const hoverDivRef = useRef<HTMLDivElement | null>(null);
  const layoutElement = document.getElementById("layout") ?? document.body;
  const smartTagStarRef = useRef<HTMLDivElement | null>(null);
  const isDarkMode = useSelector((state: any) => state.isDarkMode);
  const isTagSelected = !!eventTags?.find((p_c) => p_c.user_smart_tag_id === tag.user_smart_tag_id)
  const isOptionSmartTagged = matchingSettingTagInSmartTags({ inputTag: tag, smartTags: eventTags })?.isSmartTag;

  useEffect(() => {
    if (isSmartTagHovered && hoverDivRef?.current && smartTagStarRef?.current) {
      /* Prevent showing before we move if needed */
      hoverDivRef.current.style.opacity = "1";

      const smartTagStarBoundingRect = smartTagStarRef?.current.getBoundingClientRect();
      hoverDivRef.current.style.right = `${(window.innerWidth - smartTagStarBoundingRect.right).toString()}px`;
      hoverDivRef.current.style.top = `${(smartTagStarBoundingRect.y + 20).toString()}px`;

      /* Moving location for selector */
    }
  }, [isSelector, isSmartTagHovered])

  const renderHint = () => (
    <div
      className={classNames(
        "more-information-modal paint-colors-smart-tag-hint",
        isDarkMode ? "dark-mode" : "light-mode"
      )}
      ref={hoverDivRef}>Smart tags can not be manually removed.
    </div>
  );

  return (
    <>
      {shouldRenderCheckbox &&
        <div className="mr-2">
          <CheckBox
            disabled={isOptionSmartTagged}
            isChecked={isTagSelected}
          />
        </div>
      }
      <TagsIcon
        displayLocation={displayLocation}
        tags={[tag]}
      />
      <div className="paint-colors-label select-none">
        {displayLabelFromTags({ tags: [tag] })}
      </div>
      {
        isOptionSmartTagged ? (
          <div
            onMouseEnter={() => setIsSmartTagHovered(true)}
            onMouseLeave={() => setIsSmartTagHovered(false)}
            className="paint-colors-smart-tag-star"
            ref={smartTagStarRef}
          >
            <div className="ml-2 flex items-center">
              <AutoTagStar isDarkMode={isDarkMode} />
            </div>
            {isSmartTagHovered ? createPortal(renderHint(), layoutElement) : null}
          </div>
        ) : null
      }
    </>
  )
}

export default TagOption;
