import React from "react";

export default function GoogleDriveIncrementalAuth() {
  return (
    <svg
      width="175"
      height="108"
      viewBox="0 0 175 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Google Drive Authentication Icon"
    >
      <g filter="url(#filter0_d_4024_369)">
        <circle
          cx="46.8784"
          cy="54.0669"
          r="44.8784"
          fill="url(#paint0_linear_4024_369)"
        />
      </g>
      <g filter="url(#filter1_d_4024_369)">
        <circle cx="120.418" cy="53.8784" r="44.8784" fill="white" />
        <circle
          cx="120.418"
          cy="53.8784"
          r="44.7841"
          stroke="#CECEDB"
          strokeWidth="0.188565"
        />
      </g>
      <path
        d="M28.0121 41.8628C25.5645 44.4709 24.7184 48.1231 26.92 50.9281C28.4579 52.8874 30.9021 53.7167 33.192 53.2881C42.502 44.2581 51.4486 41.3255 58.2863 40.7495C59.3436 40.6605 60.3494 40.6279 61.2973 40.6375C63.1712 38.6537 65.2809 36.8832 67.5904 35.374L67.6073 35.3317C67.6073 35.3317 57.631 33.0075 47.9197 34.3947C39.4401 35.606 31.0748 38.5996 28.0121 41.8628Z"
        fill="url(#paint1_linear_4024_369)"
      />
      <path
        d="M42.2829 64.1174C42.1524 64.3525 42.035 64.5983 41.9323 64.8544C40.6041 68.1636 42.2099 71.923 45.5189 73.2513C46.6141 73.6909 47.758 73.8092 48.8474 73.6507C49.9375 73.4978 51.0031 73.0639 51.9313 72.3352C52.1248 72.1834 52.3072 72.0227 52.4785 71.8542C52.3246 71.1175 52.1933 70.3711 52.0854 69.6156C51.4122 64.9034 51.7119 60.2767 52.8319 55.9386C51.3494 56.4243 49.8625 57.2026 48.4089 58.216C46.2121 59.7474 44.1214 61.7964 42.2829 64.1174Z"
        fill="url(#paint2_linear_4024_369)"
      />
      <path
        d="M34.7693 53.2726C33.0921 55.5504 33.0614 58.7519 34.8931 61.0855C36.5062 63.1405 39.1161 63.9524 41.4993 63.3738C43.3799 61.0125 45.5235 58.9157 47.7935 57.3332C49.5186 56.1306 51.3338 55.214 53.1722 54.7134C54.5663 50.0288 56.9252 45.7127 60.0492 42.0313C60.138 41.9267 60.2274 41.8226 60.3173 41.7191C59.6932 41.7325 59.0457 41.7655 58.3766 41.8218C51.9906 42.3597 43.6014 45.0316 34.7693 53.2726Z"
        fill="url(#paint3_linear_4024_369)"
      />
      <g clipPath="url(#clip0_4024_369)">
        <path
          d="M93.8465 73.8824L96.6058 78.6485C97.1791 79.6519 98.0034 80.4403 98.9709 81.0136L108.826 63.9561H89.1162C89.1162 65.0669 89.4029 66.1778 89.9763 67.1812L93.8465 73.8824Z"
          fill="#0066DA"
        />
        <path
          d="M120.4 43.8886L110.546 26.8311C109.578 27.4044 108.754 28.1928 108.181 29.1962L89.9763 60.7312C89.4134 61.7129 89.117 62.8247 89.1162 63.9564H108.826L120.4 43.8886Z"
          fill="#00AC47"
        />
        <path
          d="M141.83 81.0136C142.797 80.4403 143.622 79.6519 144.195 78.6485L145.342 76.6776L150.824 67.1812C151.398 66.1778 151.684 65.0669 151.684 63.9561H131.974L136.168 72.1982L141.83 81.0136Z"
          fill="#EA4335"
        />
        <path
          d="M120.401 43.8883L130.255 26.8307C129.288 26.2574 128.177 25.9707 127.03 25.9707H113.771C112.624 25.9707 111.513 26.2932 110.546 26.8307L120.401 43.8883Z"
          fill="#00832D"
        />
        <path
          d="M131.975 63.9561H108.825L98.9707 81.0136C99.9383 81.587 101.049 81.8737 102.196 81.8737H138.604C139.751 81.8737 140.862 81.5512 141.83 81.0136L131.975 63.9561Z"
          fill="#2684FC"
        />
        <path
          d="M141.722 44.9637L132.62 29.1962C132.047 28.1928 131.223 27.4044 130.255 26.8311L120.4 43.8886L131.975 63.9564H151.649C151.649 62.8455 151.362 61.7346 150.789 60.7312L141.722 44.9637Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4024_369"
          x="0.491483"
          y="7.67996"
          width="92.7739"
          height="92.7739"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.754259" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4024_369"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4024_369"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4024_369"
          x="66.54"
          y="0"
          width="107.757"
          height="107.757"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_4024_369"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4024_369"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4024_369"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4024_369"
          x1="46.8784"
          y1="9.18848"
          x2="46.8784"
          y2="98.9453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B0E42" />
          <stop offset="1" stopColor="#12061F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4024_369"
          x1="64.9676"
          y1="38.6615"
          x2="17.6815"
          y2="59.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4024_369"
          x1="64.9676"
          y1="38.6615"
          x2="17.6815"
          y2="59.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4024_369"
          x1="64.9676"
          y1="38.6615"
          x2="17.6815"
          y2="59.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
        <clipPath id="clip0_4024_369">
          <rect
            width="62.5683"
            height="55.903"
            fill="white"
            transform="translate(89.1172 25.9707)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
