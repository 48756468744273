import React from "react";
import { connect, useSelector } from "react-redux";

import ShortcutTile from "../../shortcutTiles/shortcutTile";
import Broadcast from "../../../broadcasts/broadcast";
import { hasEventPreventDefault } from "../../../services/commonUsefulFunctions";
import { PC_CONTROL_KEY, COMMAND_KEY } from "../../../services/globalVariables";
import {
  useAllCalendars,
  useMasterAccount,
} from "../../../services/stores/SharedAccountData";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";
import { FOCUS_MODE_HOTKEY } from "../../../lib/vimcalVariables";
import { usePermissionsStore } from "../../../services/stores/permissionsStore";
import {
  shouldHideAIFeatures,
} from "../../../lib/featureFlagFunctions";
import { trackMetricsOpen } from "../../metrics/metricsAccessorFunctions";
import { LAYOUT_BROADCAST_VALUES } from "../../../lib/broadcastValues";

const SideMenuBarPowerFeatureSection = (props) => {
  const isMac = useSelector((state) => state.isMac);
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className="side-menu-tool-bar-section-wrapper">
      <div className="side-menu-tool-bar-text">Power Features</div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => Broadcast.publish("TURN_ON_COMMAND_CENTER")}
      >
        <div className="side-menu-description-text">Command Center</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={isMac ? COMMAND_KEY : PC_CONTROL_KEY} />
          <ShortcutTile shortcut={"K"} />
        </div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => {
          trackMetricsOpen({
            where: "Side panel",
            user: currentUser,
          });
          layoutBroadcast.publish("SHOW_METRICS_MODAL");
        }}
      >
        <div className="side-menu-description-text">Metrics</div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => Broadcast.publish("TOGGLE_SHOW_TEMPLATE")}
      >
        <div className="side-menu-description-text">Templates</div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={props.toggleGlobalKeyMap}
      >
        <div className="side-menu-description-text">Flash Shortcuts</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={"F"} />
        </div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={(e) => {
          hasEventPreventDefault(e);

          props.displayShortcutsLegend();
        }}
      >
        <div className="side-menu-description-text">View All Shortcuts</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={"?"} />
        </div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => Broadcast.publish("INSTANT_OPEN_COMMAND_CENTER")}
      >
        <div className="side-menu-description-text">Instant Open</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={isMac ? COMMAND_KEY : PC_CONTROL_KEY} />
          <ShortcutTile shortcut={"O"} />
        </div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => layoutBroadcast.publish("TOGGLE_FOCUS_MODE", true)}
      >
        <div className="side-menu-description-text">Focus mode</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={FOCUS_MODE_HOTKEY} />
        </div>
      </div>
      <div
        className="side-menu-toolbar-list-item"
        onClick={() => Broadcast.publish("OPEN_HELP_CENTER")}
      >
        <div className="side-menu-description-text">Help Center</div>
      </div>
      {shouldHideAIFeatures(currentUser) ? null : (
        <div
          className="side-menu-toolbar-list-item flex items-center justify-between"
          onClick={() => layoutBroadcast.publish("UPLOAD_AI_SCHEDULER")}
        >
          <div className="side-menu-description-text">Free Time Finder</div>
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    toggleGlobalKeyMap: () => dispatch({ type: "TOGGLE_SHOW_GLOBAL_KEY_MAP" }),
    displayShortcutsLegend: () =>
      dispatch({ type: "DISPLAY_SHORTCUTS_LEGEND" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const permissionsStore = usePermissionsStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      permissionsStore={permissionsStore}
    />
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withStore(SideMenuBarPowerFeatureSection));
