import React from "react";

export default function VimcalEALogo({height}) {
  return (
    <svg width={height || "120"} height="43" viewBox="0 0 214 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_119_729)">
        <mask id="mask0_119_729" maskUnits="userSpaceOnUse" x="0" y="3" width="143" height="37">
          <path d="M142.766 3.09958H0V39.8894H142.766V3.09958Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_119_729)">
          <path d="M2.18684 10.4176C-0.0776454 12.8306 -0.860425 16.2096 1.17646 18.8047C2.59932 20.6174 4.86061 21.3847 6.97921 20.9882C15.5928 12.6336 23.8701 9.92044 30.1964 9.38758C31.1745 9.30518 32.1052 9.275 32.982 9.28394C34.7158 7.44848 36.6676 5.81044 38.8044 4.41416L38.8199 4.37499C38.8199 4.37499 29.5902 2.22473 20.6053 3.50816C12.76 4.62882 5.02046 7.39841 2.18684 10.4176Z" fill="#2B3163"/>
          <path d="M8.5535 28.2026C6.85886 26.0435 6.88722 23.0814 8.43898 20.9741C16.6104 13.3496 24.372 10.8776 30.2801 10.3799L31.8276 10.5737C28.9375 13.9797 26.7549 17.9729 25.4652 22.3071C23.7644 22.7703 22.0849 23.6183 20.4889 24.7309C18.3887 26.195 16.4054 28.135 14.6655 30.3197C12.4606 30.8551 10.0459 30.1039 8.5535 28.2026Z" fill="#2B3163"/>
          <path d="M15.3899 31.0072C15.2691 31.2248 15.1605 31.4522 15.0655 31.6892C13.8367 34.7508 15.3223 38.2288 18.3838 39.4577C19.397 39.8644 20.4553 39.974 21.4632 39.8274C22.4718 39.6859 23.4578 39.2844 24.3165 38.6104C24.4954 38.47 24.6643 38.3211 24.8228 38.1654C24.6804 37.4836 24.5588 36.793 24.459 36.0941C23.8362 31.7345 24.1135 27.454 25.1498 23.4403C23.7782 23.8897 22.4024 24.6097 21.0576 25.5473C19.0251 26.9641 17.0909 28.86 15.3899 31.0072Z" fill="#2B3163"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M41.095 16.1586L48.4799 33.7454H52.4447L59.8296 16.1586L55.5503 16.1425L50.4623 28.81L45.3743 16.1425L41.095 16.1586Z" fill="#2B3163"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M61.7659 16.1582V33.7454H65.6489V16.1582H61.7659ZM96.6141 33.7454V22.8277C96.6141 18.27 93.3287 15.8499 90.0472 15.8499C87.9115 15.8499 85.6669 16.9371 84.2816 18.8172L84.0327 19.1549L83.8099 18.7995C82.5976 16.8664 80.5334 15.8499 78.4673 15.8499C76.4471 15.8499 74.9454 16.8238 73.8891 18.078L73.3855 18.6764V16.1582H69.5026V33.7454H73.3855V24.4036C73.3855 23.0994 73.6754 21.8356 74.3458 20.8895C75.0257 19.9304 76.081 19.3216 77.5423 19.3216C78.7048 19.3216 79.6148 19.7786 80.2251 20.597C80.8268 21.404 81.1167 22.5341 81.1167 23.8555V33.7454H84.9997V23.7075C85.0882 22.5552 85.4364 21.4529 86.1158 20.644C86.8028 19.8262 87.8097 19.3216 89.1567 19.3216C90.3192 19.3216 91.2292 19.7786 91.8395 20.597C92.4412 21.404 92.7311 22.5341 92.7311 23.8555V33.7454H96.6141ZM115.758 28.5989C114.395 31.7703 111.314 34.0195 107.578 34.0195C102.464 34.0195 98.5107 29.8687 98.5107 24.9517C98.5107 19.8354 102.629 15.8499 107.578 15.8499C111.25 15.8499 114.361 18.0687 115.757 21.3024L112.221 22.2829C111.359 20.5628 109.676 19.2873 107.578 19.2873C104.493 19.2873 102.119 21.9321 102.119 24.9517C102.119 27.9738 104.495 30.5818 107.578 30.5818C109.703 30.5818 111.39 29.3434 112.226 27.5897L115.758 28.5989ZM131.267 31.3758V33.7454H135.15V16.1582H131.267V18.5749L130.775 18.0565C129.524 16.7397 127.747 15.8499 125.534 15.8499C120.767 15.8499 116.809 19.8249 116.809 24.9517C116.809 29.8759 120.769 34.0195 125.534 34.0195C127.751 34.0195 129.528 33.1613 130.777 31.8788L131.267 31.3758ZM125.98 30.5476C122.8 30.5476 120.418 27.98 120.418 24.9517C120.418 21.9259 122.797 19.3216 125.98 19.3216C129.055 19.3216 131.541 21.9222 131.541 24.9517C131.541 27.985 129.018 30.5476 125.98 30.5476ZM138.883 10.3339V33.7454H142.766V10.3339H138.883Z" fill="#2B3163"/>
          <path d="M66.0548 11.2956C66.0548 12.5803 65.0132 13.6218 63.7284 13.6218C62.4438 13.6218 61.4022 12.5803 61.4022 11.2956C61.4022 10.0109 62.4438 8.96938 63.7284 8.96938C65.0132 8.96938 66.0548 10.0109 66.0548 11.2956Z" fill="#2B3163"/>
          <path d="M29.6003 10.3432C30.2057 10.2913 30.7911 10.261 31.3558 10.2486C31.2745 10.344 31.1937 10.4398 31.1134 10.5361L29.6003 10.3432Z" fill="#2B3163"/>
        </g>
        <path d="M207.893 0H160.399C157.257 0 154.711 2.54657 154.711 5.68792V37.3012C154.711 40.4426 157.257 42.9891 160.399 42.9891H207.893C211.034 42.9891 213.581 40.4426 213.581 37.3012V5.68792C213.581 2.54657 211.034 0 207.893 0Z" fill="#2B3163"/>
        <path d="M166.059 32.3741V10.4868H180.345V13.5037H169.52V19.7445H178.984V22.7318H169.52V29.3571H180.493V32.3741H166.059ZM183.538 32.3741L191.169 10.4868H195.606L203.266 32.3741H199.628L197.942 27.464H188.862L187.176 32.3741H183.538ZM189.897 24.3881H196.907L193.417 14.184L189.897 24.3881Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_119_729">
          <rect width="213.705" height="43" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
