import React from "react";
import { Plus } from "react-feather";

export default function AddMoreButton({ onClick }) {
  return (
    <div
      className="flex items-center hoverable-secondary-text-color duration-200 select-none"
      onClick={onClick}
    >
      <Plus size={14} />
      <div className="default-font-size ml-2">Add color label</div>
    </div>
  );
}
