import React, { PureComponent } from "react";
import {
  renderTimeSlotsLine,
  createAvailabilityTextFromEvent,
  guessTimeZone,
  reformatMinDuration,
  handleError,
  generateFreeSlotsFromBusySlots,
  generateBookableSlotsFromObj,
  getAllBusySlots,
  renderSpinner,
  checkIfBookableSlotsAreValid,
  convertToTimeZone,
  shouldRoundToNearest15,
  OpenLink,
} from "../services/commonUsefulFunctions";
import { Edit2, Trash2, FilePlus } from "react-feather";
import ColoredLine from "./line";
import { connect } from "react-redux";
import EventModalPopup from "./eventModalPopup";
import StyleConstants, {
  COPY_AVAILABILITIES_TRIGGER_HOT_KEY,
  ALL_SLOTS_TAKEN,
  WHITE_BUTTON,
  BLUE_BUTTON,
  DISCARD_WARNING_MODAL_WIDTH,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  SECOND_IN_MS,
} from "../services/globalVariables";
import { FEATURE_TRACKING_ACTIONS, trackEvent, trackFeatureUsage } from "./tracking";
import Broadcast from "../broadcasts/broadcast";
import { constructRequestURL } from "../services/api";
import Fetcher from "../services/fetcher";
import ShortcutHoverHint from "./shortcutHoverHint";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import CustomButton from "./customButton";
import _ from "underscore";
import { determineBookingLink } from "../lib/envFunctions";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import GlobalKeyMapTile from "./globalKeyMapTile";
import { setLastestAvailabiltyType } from "../lib/stateManagementFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import classNames from "classnames";
import {
  fetchFreeBusySlots,
  getBlockedCalendarsID,
  getPersonalLinkIFrame,
} from "../lib/availabilityFunctions";
import { isVersionV2 } from "../services/versionFunctions";
import { getUserEmail, getUserName, getUserToken } from "../lib/userFunctions";
import {
  SELECT_USER_TYPE,
  SelectUser,
  getAllUsers,
} from "./settings/common/selectUser";
import { customMenuStyle, getReactSelectBaseStyle } from "./select/styles";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { getAllTripsID, getPersonalLinkInternalLabel, getPersonalLinkToken, getUpcomingTrips } from "../lib/personalLinkFunctions";
import { createPortal } from "react-dom";
import { PERSONAL_LINK_COPY } from "../lib/copy";
import { AVAILABILITY_PANEL_MODAL_ID } from "../services/elementIDVariables";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { equalAfterTrimAndLowerCased, pluralize, truncateString } from "../lib/stringFunctions";
import { createUUID } from "../services/randomFunctions";
import { getSidePoppedOverModalBorder, getTransparentModalStyle } from "../lib/styleFunctions";
import SaveAndCancelButton from "./buttons/saveAndCancelButton";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getInternalDomainAndEmails } from "../lib/settingsFunctions";
import ExpandIconWithAnimation from "./icons/expandIconWithAnimation";
import { determineDefaultModalStyle, MODAL_OVERLAY_Z_INDEXES } from "../lib/modalFunctions";

const SLOT_WRITING_CONTAINER_WIDTH = 250;
const ERROR_OCCURED = "An error occured fetching existing events.";
const PREVIEW_MODAL = "PREVIEW_MODAL";
const CONFIRM_CANCEL_MODAL = "CONFIRM_CANCEL";
const CONFIRM_DUPLICATE_MODAL = "CONFIRM_DUPLICATE";
const SELECT_USER_ID = "select-duplicate";
const SHARE_POPUP_MODAL = "share-popup-modal";
const COPY_IFRAME_MODAL = "copy-iframe-modal";

class PersonalLinkContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.determineInitialState(props);
    this._fetchTimeOut = null;
    this._fetchID = null;
    this._fetchBusyFreeResponse = null;

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickTrash = this.onClickTrash.bind(this);
    this.onClickCopySlot = this.onClickCopySlot.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.hidePreview = this.hidePreview.bind(this);
    this.copyOnlyLink = this.copyOnlyLink.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
    this.fetchFreeBusySlots = this.fetchFreeBusySlots.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onClickDuplicate = this.onClickDuplicate.bind(this);
    this.confirmDuplicate = this.confirmDuplicate.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);

    this.handlePersonalLinkBroadcast({
      inputPersonalLinkInfo: props.personalLinkInfo,
      isSubscribe: true,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.uniqueKey === 0) {
      this.fetchFreeBusySlots();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentTimeZone !== prevProps.currentTimeZone) {
      if (this.state.isModalOpen) {
        this.fetchFreeBusySlots();
      }

      this.setState({ readyToShowPreview: false });
    }

    if (
      this.props.personalLinkInfo?.token !== prevProps.personalLinkInfo?.token
    ) {
      const updatedState = this.determineInitialState(this.props);
      this.setState(updatedState);

      // remove existing token broadcast first and then use current token
      this.handlePersonalLinkBroadcast({
        inputPersonalLinkInfo: prevProps.personalLinkInfo,
        isSubscribe: false,
      });

      this.handlePersonalLinkBroadcast({
        inputPersonalLinkInfo: this.props.personalLinkInfo,
        isSubscribe: true,
      });
    }

    const prevTrips = this.getUpcomingTrips(
      prevProps.masterAccount.masterAccount,
      prevProps.selectedUser,
    );
    const currentTrips = this.getUpcomingTrips();
    if (getAllTripsID(prevTrips) !== getAllTripsID(currentTrips)) {
      // reset cache
      this._fetchBusyFreeResponse = null;
      this._fetchID = null;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.handlePersonalLinkBroadcast({ isSubscribe: false });
  }

  //================
  // RENDER METHODS
  //================

  render() {
    let personalLinkInfo = this.props.personalLinkInfo;
    if (isEmptyObjectOrFalsey(personalLinkInfo)) {
      return null;
    }

    return (
      <div
        key={`personal_link_container_${this.props.uniqueKey}`}
        id={`personal_link_container_${this.props.uniqueKey}`}
        className="personal-link-container"
        onMouseEnter={this.onMouseEnter}
      >
        {this.renderTooltip()}

        <div
          className="mb-3 pl-5 font-size-16 font-weight-400"
        >
          {truncateString(getPersonalLinkInternalLabel(this.props.personalLinkInfo), 65)}

          <div className="display-flex-flex-direction-row default-font-size font-weight-300 user-select-none margin-top-ten secondary-text-color">
            {this.renderHourText()}

            {this.state.duration.minutes
              ? `${this.state.duration.minutes} ${pluralize(
                this.state.duration.minutes,
                "min",
              )}`
              : null}
          </div>
        </div>

        <ColoredLine />

        <div
          className="display-flex-flex-direction-row padding-10 align-items-center justify-content-space-between"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {this.renderCopySlots()}
          {this.renderCopyLink()}
        </div>

        {this.renderPreview()}
        {this.renderModal()}
      </div>
    );
  }

  renderCopyLink() {
    return (
      <ShortcutHoverHint
        style={{
          marginLeft: "0px",
          marginTop: "5px",
          top: -45,
          left: 10,
        }}
        title={`YY${this.determineKeyIndex()}`}
        shouldHideHint={this.props.uniqueKey > 9}
      >
        <GlobalKeyMapTile
          style={{
            left: "-30px",
            top: "-20px",
          }}
          shortcut={`YY${this.determineKeyIndex()}`}
          shouldHide={this.props.uniqueKey > 9}
        />
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={this.copyOnlyLink}
          label="Copy Link"
          buttonTabIndex={4}
          labelClassNameOverride={"font-weight-300-important"}
          className={"personal-link-container-buttons"}
        />
      </ShortcutHoverHint>
    );
  }

  determineKeyIndex() {
    let index = this.props.uniqueKey + 1;
    if (index === 10) {
      return 0;
    }

    return index;
  }

  determineModalWidth() {
    if (this.isSharePopupModal()) {
      return "180px";
    }

    if (this.isModalConfirmDelete() || this.isModalConfirmDuplicate()) {
      return DISCARD_WARNING_MODAL_WIDTH;
    } else if (this.isModalCopyIframe()) {
      return 500;
    } else {
      return SLOT_WRITING_CONTAINER_WIDTH;
    }
  }

  determineModalTitle() {
    const { name } = this.state;
    if (this.isModalConfirmDelete()) {
      return `Are you sure you want to delete "${name}"?`;
    } else if (this.isModalConfirmDuplicate()) {
      return `Are you sure you want to duplicate "${name}"?`;
    } else if (this.isModalPreview()) {
      return "Text to copy";
    } else if (this.isModalCopyIframe()) {
      return `Embed “${name}" scheduling page`;
    } else {
      return "";
    }
  }

  isModalOpen() {
    if (this.isModalPreview()) {
      return this.state.isModalOpen && this.state.readyToShowPreview;
    }
    return this.state.isModalOpen;
  }

  renderPreview() {
    const { isPreviewOpen, previewTopPosition, previewModalTopPosition, readyToShowPreview } =
      this.state;
    if (!isPreviewOpen || !previewTopPosition || !readyToShowPreview) {
      return;
    }
    const {
      isDarkMode,
    } = this.props;
    return createPortal(
      <div
        className={classNames("fixed", "default-bottom-left-modal-border")}
        style={{...{
          padding: "20px",
          position: "absolute",
          top: previewTopPosition,
          right: "370px",
          left: "auto",
          bottom: "auto",
          borderRadius: 10,
          zIndex: 5,
          backgroundColor: isDarkMode
            ? StyleConstants.darkModeModalBackgroundColor
            : "white",
          color: isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : "",
        },
        ...getSidePoppedOverModalBorder(isDarkMode),
      }}
      >
        {this.renderPreviewContent()}
      </div>,
      document.body,
    );
  }

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.isModalOpen}
        skipDisablingHotKeys={true}
        onRequestClose={this.closeModal}
        width={this.determineModalWidth()}
        title={this.determineModalTitle()}
        style={this.determineModalStyle()}
        hideCloseButton={this.isSharePopupModal() || this.isModalPreview()}
        hideTitle={this.isSharePopupModal()}
        modalID={this.state.isModalOpen ? AVAILABILITY_PANEL_MODAL_ID : null}
      >
        {this.renderPopupContentContainer()}
      </EventModalPopup>
    );
  }

  renderPopupContentContainer() {
    if (this.isSharePopupModal()) {
      return this.renderShareDropDown();
    }

    if (this.isModalConfirmDelete()) {
      return (
        <SaveAndCancelButton
          onClose={this.closeModal}
          onConfirm={this.confirmDelete}
          confirmButtonText={"Delete"}
          autoFocusConfirmButton={true}
        />
      );
    } else if (this.isModalConfirmDuplicate()) {
      return this.renderDuplicatePersonalLinkModal();
    } else if (
      this.state.content === ALL_SLOTS_TAKEN ||
      this.state.content === ERROR_OCCURED
    ) {
      return <div className="default-font-size">{this.state.content}</div>;
    } else if (this.isModalCopyIframe()) {
      return this.renderCopyToWebsite();
    } else {
      return this.renderPreviewContent();
    }
  }

  renderCopyToWebsite() {
    const iframeWithURL = getPersonalLinkIFrame(this.state.url);
    const onClickCancel = () => {
      this.closeModal();
    };
    const onClickCopy = () => {
      navigator.clipboard.writeText(iframeWithURL).then(
        () => {
          if (!this._isMounted) {
            return;
          }
          /* clipboard successfully set */
          this.closeModal();
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Copied embedded code for "${this.state.name}" to clipboard`,
          );
        },
        function (e) {
          handleError(e);
          /* clipboard write failed */
        },
      );
    };
    return (
      <div className="display-flex-flex-direction-column default-font-size">
        <div className="secondary-text-color">
          {PERSONAL_LINK_COPY.ADD_TO_WEBSITE}
        </div>

        <div className="mt-5">Embed code</div>
        <div className="mt-2 p-4 personal-link-copy-iframe-url rounded-lg secondary-text-color">
          {iframeWithURL}
        </div>

        <div className="flex justify-end mt-8">
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={onClickCancel}
            label="Cancel"
            addPaddingToRight={true}
          />
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={onClickCopy}
            label="Copy"
          />
        </div>
      </div>
    );
  }

  isModalCopyIframe() {
    return this.state.modalType === COPY_IFRAME_MODAL;
  }

  renderPreviewContent() {
    return (
      <div
        className="select-availability-panel-content-wrapper span-overflow-wrap-break-word"
        style={{
          backgroundColor: this.props.isDarkMode ? "#44475A" : null,
          width: SLOT_WRITING_CONTAINER_WIDTH,
        }}
      >
        {renderTimeSlotsLine(this.state.content)}
      </div>
    );
  }

  renderDuplicatePersonalLinkModal() {
    const { isDarkMode } = this.props;
    const renderButtons = () => {
      return (
        <SaveAndCancelButton
          onClose={this.closeModal}
          onConfirm={this.confirmDuplicate}
          confirmButtonText={"Duplicate"}
          autoFocusConfirmButton={true}
        />
      );
    };
    const duplicateCalendarSection = () => {
      if (!this.state.allUsers || this.state.allUsers.length <= 1) {
        return null;
      }

      return (
        <div className="mb-14">
          <div className="default-font-size secondary-text-color mt-6 mb-4">
            Duplicate to calendar
          </div>
          <SelectUser
            setSelectedUserIndex={(selectedUserIndex) => {
              this.setState({ selectedUserIndex });
            }}
            id={SELECT_USER_ID}
            selectedUser={this.getSelectedUser()}
            addExecutiveLabel={true}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              showBorder: true,
              controlWidth: "200px",
              menuListStyle: customMenuStyle({ width: 200, left: "0px" }),
              menuListMaxHeight: "90px",
            })}
            inputContainerClassName={"flex"}
            selectUserType={SELECT_USER_TYPE.ALL_USERS}
          />
        </div>
      );
    };

    return (
      <div>
        {duplicateCalendarSection()}
        {renderButtons()}
      </div>
    );
  }

  getSelectedUser() {
    if (
      !isNullOrUndefined(this.state.selectedUserIndex) &&
      this.state.allUsers[this.state.selectedUserIndex]?.value
    ) {
      return this.state.allUsers[this.state.selectedUserIndex]?.value;
    }

    return this.props.selectedUser ?? this.props.currentUser;
  }

  getAllUsers() {
    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    return getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: SELECT_USER_TYPE.ALL_USERS,
    });
  }

  renderHourText() {
    if (!this.state.duration.hours) {
      return null;
    }

    return (
      <div className="margin-right-5">
        {`${this.state.duration.hours} ${pluralize(
          this.state.duration.hours,
          "hr",
        )}`}
      </div>
    );
  }

  renderTooltip() {
    return (
      <div className="display-flex-flex-direction-row justify-content-space-between align-items-center">
        <div
          className="hoverable-secondary-text-color margin-left-20 default-font-size"
          onClick={this.onClickPreview}
        >
          Preview
        </div>

        <div className="display-flex-flex-direction-row justify-content-flex-end padding-10 margin-right-10">
          <ShortcutHoverHint
            left
            style={{
              right: 35,
              top: -5,
            }}
            title={"Duplicate"}
          >
            <FilePlus
              className="clickable-icon margin-right-10"
              size="17"
              onClick={this.onClickDuplicate}
            />
          </ShortcutHoverHint>

          <ShortcutHoverHint
            left
            style={{
              right: 35,
              top: -5,
            }}
            title={"Delete"}
          >
            <Trash2
              className="clickable-icon margin-right-10"
              size="17"
              onClick={this.onClickTrash}
            />
          </ShortcutHoverHint>

          <ShortcutHoverHint
            left
            style={{
              right: 25,
              top: -5,
            }}
            title={"Edit"}
          >
            <Edit2
              className="clickable-icon"
              size="17"
              onClick={this.onClickEdit}
            />
          </ShortcutHoverHint>
        </div>
      </div>
    );
  }

  renderCopySlots() {
    const isPopupOpen = this.isSharePopupModal();
    const getLabel = () => {
      return (
        <div className="flex items-center">
          <div className="mr-2 select-none">Share</div>
          <ExpandIconWithAnimation isOpen={isPopupOpen} />
        </div>
      );
    };
    const onClick = () => {
      this.setState({
        modalType: SHARE_POPUP_MODAL,
        isModalOpen: true,
        previewTopPosition: this.getTopLocation(SHARE_POPUP_MODAL),
      });
    };
    return (
      <CustomButton
        buttonType={WHITE_BUTTON}
        onClick={onClick}
        label={getLabel()}
        buttonTabIndex={4}
        labelClassNameOverride={"font-weight-300-important"}
        className={isPopupOpen ? "highlight-white-button-focused-border-override" : "personal-link-container-buttons"}
      />
    );
  }

  renderHotKey() {
    if (this.determineHotKeyIndex(this.props.uniqueKey) > 9) {
      return null;
    }

    return (
      <div
        className={classNames(
          this.state.shouldShowSpinnerForCopy
            ? "margin-left-35"
            : "margin-left-ten",
          "flex",
        )}
      >
        <ShortcutTile
          shortcut={COPY_AVAILABILITIES_TRIGGER_HOT_KEY.toUpperCase()}
        />
        <ShortcutTile
          shortcut={this.determineHotKeyIndex(this.props.uniqueKey)}
        />
      </div>
    );
  }

  renderSpinner() {
    return (
      <div
        style={{
          position: "absolute",
          top: "-20px",
          right: "24px",
        }}
      >
        {renderSpinner()}
      </div>
    );
  }

  determineModalStyle() {
    const {
      isDarkMode,
    } = this.props;
    const {
      previewTopPosition,
    } = this.state;
    if (this.isSharePopupModal()) {
      return getTransparentModalStyle({
        top: previewTopPosition + 156,
        isDarkMode,
        right: "138px",
      });
    }

    if (this.isModalPreview()) {
      return {
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 370,
          bottom: 0,
          backgroundColor: "transparent",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {...({
          padding: "20px",
          position: "absolute",
          top: previewTopPosition,
          right: "10px",
          left: "auto",
          bottom: "auto",
          zIndex: 5,
          backgroundColor: isDarkMode
            ? StyleConstants.darkModeModalBackgroundColor
            : "white",
          color: isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : "",
        }),
        ...getSidePoppedOverModalBorder(isDarkMode),
        },
      };
    } else {
      return determineDefaultModalStyle(isDarkMode);
    }
  }

  //=================
  // EVENT HANDLERS
  //=================

  onClickEdit() {
    this.props.onClickEdit();
  }

  isSharePopupModal() {
    return this.state.modalType === SHARE_POPUP_MODAL;
  }

  isModalConfirmDelete() {
    return this.state.modalType === CONFIRM_CANCEL_MODAL;
  }

  isModalConfirmDuplicate() {
    return this.state.modalType === CONFIRM_DUPLICATE_MODAL;
  }

  isModalPreview() {
    return this.state.modalType === PREVIEW_MODAL;
  }

  onClickTrash() {
    this.setState({
      modalType: CONFIRM_CANCEL_MODAL,
      isModalOpen: true,
    });
  }

  onClickDuplicate() {
    this.setState({
      modalType: CONFIRM_DUPLICATE_MODAL,
      isModalOpen: true,
    });
  }

  confirmDuplicate() {
    // permit list from backend project:
    // :slug,
    // :name,
    // :duration,
    // :buffer_before,
    // :buffer_after,
    // :conferencing,
    // :description,
    // :title,
    // :time_zone,
    // :token,
    // :days_forward,
    // :location,
    // :buffer_from_now,
    // blocked_calendar_ids: [],
    // :attendees => [
    //   :name,
    //   :email,
    //   :label,
    //   :value
    // ],
    // :custom_questions => [
    //   :description,
    //   :required,
    //   :type
    // ],
    // :slots => {}
    this.closeModal();
    
    const PERMIT_LIST = [
      "slug",
      "name",
      "duration",
      "buffer_before",
      "buffer_after",
      "conferencing",
      "description",
      "title",
      "time_zone",
      "token",
      "days_forward",
      "location",
      "buffer_from_now",
      "blocked_calendar_ids",
      "attendees",
      "custom_questions",
      "slots",
      "ignore_conflicts",
      "ignore_internal_conflicts",
    ];

    let personalLinkInfo = {
      ...this.props.personalLinkInfo,
      name: `Copy of ${this.props.personalLinkInfo["name"]}`,
      blocked_calendar_ids: getBlockedCalendarsID(
        this.props.personalLinkInfo?.blocked_calendars,
      ),
    };

    personalLinkInfo = _.omit(
      personalLinkInfo,
      "token",
      "url",
      "url_name",
      "created_at",
      "blocked_calendars",
      "settings",
      "trips",
      "social_links",
      "profile_photo_url",
    );
    personalLinkInfo = _.omit(personalLinkInfo, (value, key) => !PERMIT_LIST.includes(key));

    const path = "personal_links";
    const url = constructRequestURL(path, isVersionV2());
    const linkData = { personal_link: personalLinkInfo };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    Fetcher.post(
      url,
      payloadData,
      true,
      getUserEmail(this.getSelectedUser()),
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          Broadcast.publish("SHOW_ERROR_MESSAGE");
          return;
        }

        if (!response?.personal_link) {
          Broadcast.publish("SHOW_ERROR_MESSAGE");
        }

        if (!equalAfterTrimAndLowerCased(getUserEmail(this.getSelectedUser()), getUserEmail(this.props.selectedUser?.email))) {
          availabilityBroadcast.publish(
            "SET_SELECTED_USER",
            getUserEmail(this.getSelectedUser()),
          );
          setTimeout(() => {
            availabilityBroadcast.publish("OPEN_PERSONAL_LINK_DETAIL", {
              personalLink: response.personal_link,
            });
          }, 0.5 * SECOND_IN_MS);
        } else {
          Broadcast.publish("UPDATE_PERSONAL_LINKS", {
            response,
            isNew: true,
            openDetails: true,
          });
        }

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Duplicated "${response.personal_link.name}"`,
        );
      })
      .catch((error) => {
        handleError(error);

        Broadcast.publish("SHOW_ERROR_MESSAGE");
      });
  }

  confirmDelete() {
    const {
      token,
      name,
    } = this.state;
    const path = `personal_links/${token}`;
    const url = constructRequestURL(path, isVersionV2());

    return Fetcher.delete(
      url,
      {},
      true,
      getUserEmail(this.getSelectedUser()),
    )
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        if (response.deleted) {
          Broadcast.publish("DELETE_PERSONAL_LINK", token);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Deleted "${name}"`,
          );
          trackEvent({
            category: "personal-links",
            action: "deleted-personal-link",
            label: `token: ${token}`,
            userToken: getUserToken(this.props.currentUser),
          });
        }
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }

        Broadcast.publish("SHOW_ERROR_MESSAGE");
      });
  }

  onClickPreview() {
    OpenLink(this.state.url);

    // const { personalLinkInfo, currentUser } = this.props;

    // const { allCalendars } = this.props.allCalendars;

    // Broadcast.publish("DISPLAY_AVAILABILITY_PREVIEW_MODAL", {
    //   isPersonalLink: true,
    //   userCalendarId: getUserCalendarIDFromEmail(
    //     currentUser.email,
    //     allCalendars
    //   ),
    //   modalTitle: "Personal Link Preview",
    //   ...personalLinkInfo,
    //   blocked_calendar_ids: getBlockedCalendarsID(
    //     personalLinkInfo.blocked_calendars
    //   ),
    // });
  }

  onClickCopySlot() {
    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.COPY_PERSONAL_LINK}::slots`,
      userToken: getUserToken(this.getSelectedUser()),
    });
    if (this.state.fetchBusyEventsErroredOut) {
      Broadcast.publish("SHOW_ERROR_MESSAGE");
      return;
    }
    if (this.state.content === ALL_SLOTS_TAKEN) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `No available slots to copy for "${this.state.name}".`,
      );

      this.setState({ shouldShowSpinnerForCopy: false });
      return;
    }
    if (!this.state.readyToShowPreview) {
      this.setState(
        { shouldShowSpinnerForCopy: true },
        this.fetchFreeBusySlots,
      );
      return;
    }
    navigator.clipboard.writeText(this.state.content).then(
      () => {
        if (!this._isMounted) {
          return;
        }

        /* clipboard successfully set */
        Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
        setLastestAvailabiltyType(this.getSelectedUser(), true);

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Copied slots for "${this.state.name}" to clipboard`,
        );
      },
      function () {
        /* clipboard write failed */
        Broadcast.publish("SHOW_ERROR_MESSAGE");
      },
    );
  }

  copyOnlyLink() {
    navigator.clipboard.writeText(this.state.url).then(
      () => {
        if (!this._isMounted) {
          return;
        }

        /* clipboard successfully set */
        trackFeatureUsage({
          action: `${FEATURE_TRACKING_ACTIONS.COPY_PERSONAL_LINK}::link_only`,
          userToken: getUserToken(this.getSelectedUser()),
        });

        Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
        setLastestAvailabiltyType(this.getSelectedUser(), true);

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Copied link for "${this.state.name}" to clipboard`,
        );
      },
      function () {
        /* clipboard write failed */
        Broadcast.publish("SHOW_ERROR_MESSAGE");
      },
    );
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      modalType: null,
      isPreviewOpen: false,
    });
  }

  getTopLocation(inputModalType) {
    const currModalType = inputModalType ?? this.state.modalType;
    let previewTopPosition = 20;

    let elementDom = document.getElementById(
      `personal_link_container_${this.props.uniqueKey}`,
    );

    if (elementDom) {
      let elementPositionInfo = elementDom.getBoundingClientRect();
      let innerHeight = window.innerHeight;

      previewTopPosition = elementPositionInfo.top;

      if (
        currModalType === SHARE_POPUP_MODAL &&
        elementPositionInfo.top + 250 > innerHeight
      ) {
        // 86 = height of entire component + height of dropdown
        // Chose 250 to give a little more breathing room on bottom
        previewTopPosition = innerHeight - 250;
      } else if (
        currModalType !== SHARE_POPUP_MODAL &&
        elementPositionInfo.top + 400 > innerHeight
      ) {
        // 235 = height of entire component + height of dropdown
        // Chose 250 to give a little more breathing room on bottom
        previewTopPosition = innerHeight - 400;
      }
    }
    elementDom = null;
    return previewTopPosition;
  }

  showPreview() {
    if (this.state.isModalOpen) {
      return;
    }
    this.setState({
      isModalOpen: true,
      modalType: PREVIEW_MODAL,
      previewTopPosition: this.getTopLocation(),
    });
  }

  hidePreview() {
    clearTimeout(this._fetchTimeOut);
    if (this.isModalConfirmDelete()) {
      return;
    }

    if (!this.state.isModalOpen) {
      return;
    }

    this.setState({ isModalOpen: false });
  }

  createFreeTimesContent(sortedEvents) {
    const filteredEvents = sortedEvents.slice(0, 4);

    const formattedForTimeZone = filteredEvents.map((e) => {
      return {
        start_time: convertToTimeZone(e.start_time, {
          timeZone: this.props.currentTimeZone,
        }),
        end_time: convertToTimeZone(e.end_time, {
          timeZone: this.props.currentTimeZone,
        }),
      };
    });

    const jsDateFreeSlots = formattedForTimeZone.map((s) => {
      return {
        isAvailability: true,
        eventStart: s.start_time,
        eventEnd: s.end_time,
      };
    });

    if (jsDateFreeSlots.length === 0) {
      // all slots are busy
      this.setState({
        content: ALL_SLOTS_TAKEN,
        readyToShowPreview: true,
        fetchBusyEventsErroredOut: false,
        shouldShowSpinnerForCopy: false,
      });

      return;
    }

    const content = this.addPersonalLinkURLToString(
      createAvailabilityTextFromEvent({
        eventList: jsDateFreeSlots,
        currentTimeZone: this.props.currentTimeZone,
        format24HourTime: this.props.format24HourTime,
        capDuration: true,
        duration: this.state.durationMinutes,
      }),
    );

    if (this.state.shouldShowSpinnerForCopy) {
      this.setState(
        { content, readyToShowPreview: true, fetchBusyEventsErroredOut: false },
        this.onClickCopySlot,
      );
      return;
    }

    this.setState({ content, readyToShowPreview: true });
  }

  addPersonalLinkURLToString(str) {
    return str + "\nIf it's easier, you can book here: " + this.state.url;
  }

  determineLinkURL({ token, slug }) {
    const { masterAccount } = this.props.masterAccount;
    const { selectedUser, currentUser } = this.props;
    const link = determineBookingLink();

    const { userName } = getUserName({
      masterAccount,
      user: selectedUser ?? currentUser,
    });
    const method = !!userName && !!slug ? `${userName}/${slug}` : token;

    return `${link}/p/${method}`;
  }

  async fetchFreeBusySlots() {
    if (this._fetchID) {
      // currently fetching
      return;
    }
    this._fetchID = createUUID();

    if (this._fetchBusyFreeResponse) {
      this.parseFetchedBusyFreeSlots();
      return;
    }

    const {
      slots,
      timeZone,
      daysForward,
      bufferBefore,
      bufferAfter,
      shouldShowSpinnerForCopy,
      name,
      conferencing,
      isIgnoreInternalConflicts,
    } = this.state;

    const { personalLinkInfo } = this.props;

    const upcomingTrips = this.getUpcomingTrips();
    const bookableSlots = generateBookableSlotsFromObj({
      slots,
      timeZone,
      daysForward,
      shouldFormatIntoUTC: false,
      bufferBefore,
      bufferAfter,
      upcomingTrips,
    });

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      if (shouldShowSpinnerForCopy) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `No available slots to copy for "${name}".`,
        );
      }

      this.setState({
        content: ALL_SLOTS_TAKEN,
        readyToShowPreview: true,
        shouldShowSpinnerForCopy: false,
      });
      return;
    }

    const response = await fetchFreeBusySlots({
      currentUser: this.getSelectedUser(),
      conferencing,
      bookableSlots,
      blockedCalendars: getBlockedCalendarsID(
        personalLinkInfo.blocked_calendars,
      ),
      isIgnoreInternalConflicts,
    });

    if (!this._isMounted) {
      return;
    }

    this._fetchID = null;

    if (!response) {
      this.setState({
        fetchBusyEventsErroredOut: true,
        shouldShowSpinnerForCopy: false,
        content: ERROR_OCCURED,
        readyToShowPreview: true,
      });
      return;
    }

    this.parseFetchedBusyFreeSlots(response);
    this._fetchBusyFreeResponse = response;
  }

  parseFetchedBusyFreeSlots(inputResponse) {
    const response = inputResponse || this._fetchBusyFreeResponse;
    // get all free_busy slots
    const {
      roundUpInterval,
      bufferBefore,
      bufferAfter,
      bufferFromNow,
      slots,
      durationMinutes,
      timeZone,
      daysForward,
    } = this.state;

    const busySlots = getAllBusySlots({
      response,
      roundUpInterval,
      bufferBefore,
      bufferAfter,
      bufferFromNow,
    });

    const allFreeSlots = generateFreeSlotsFromBusySlots({
      busySlots,
      slots,
      daysForward,
      durationMinutes,
      timeZone,
      bufferBefore,
      bufferAfter,
      upcomingTrips: this.getUpcomingTrips(),
    });

    this.createFreeTimesContent(allFreeSlots);
  }

  getUpcomingTrips(inputMasterAccount, inputUser) {
    const { masterAccount } = this.props.masterAccount;
    return getUpcomingTrips({
      user: inputUser ?? this.props.selectedUser,
      masterAccount: inputMasterAccount ?? masterAccount,
    });
  }

  onMouseEnter() {
    this._fetchTimeOut = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.fetchFreeBusySlots();
    }, 1 * SECOND_IN_MS);
  }

  determineInitialState(props) {
    let {
      buffer_before,
      buffer_after,
      buffer_from_now,
      time_zone,
      days_forward,
      slots,
      duration,
      token,
      name,
      conferencing,
      slug,
      ignore_internal_conflicts,
    } = props.personalLinkInfo;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      selectedUser,
      currentUser
    } = this.props;

    const getIsIgnoreInternalConflicts = () => {
      if (!ignore_internal_conflicts) {
        return false;
      }
      if (isEmptyArray(getInternalDomainAndEmails({
        masterAccount, 
        user: selectedUser ?? currentUser
      }))) {
        return false;
      }
      return ignore_internal_conflicts;
    };

    return {
      isModalOpen: false,
      previewTopPosition: 0,
      busySlots: [],
      content: "",
      bufferBefore: buffer_before || 0,
      bufferAfter: buffer_after || 0,
      bufferFromNow: buffer_from_now || 0,
      roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
      timeZone: time_zone || guessTimeZone(),
      url: this.determineLinkURL({ token, slug }),
      token,
      name,
      daysForward: days_forward || 14,
      duration: reformatMinDuration(duration),
      durationMinutes: duration,
      slots: slots || {},
      fetchBusyEventsErroredOut: false,
      conferencing,
      readyToShowPreview: false,
      shouldShowSpinnerForCopy: false,
      modalType: null,
      allUsers: this.getAllUsers(),
      selectedUserIndex: undefined,
      isPreviewOpen: false,
      previewModalTopPosition: null,
      isIgnoreInternalConflicts: getIsIgnoreInternalConflicts(),
    };
  }

  determineHotKeyIndex(index) {
    let updatedIndex = index + 1;
    if (updatedIndex === 10) {
      return 0;
    }

    return updatedIndex;
  }

  handlePersonalLinkBroadcast({
    inputPersonalLinkInfo = null,
    isSubscribe = false,
  }) {
    const { personalLinkInfo } = this.props;

    const personalLink = inputPersonalLinkInfo ?? personalLinkInfo;

    if (!getPersonalLinkToken(personalLink)) {
      return;
    }

    const { token } = personalLink;

    if (isSubscribe) {
      AvailabilityBroadcast.subscribe(
        `COPY_PERSONAL_LINK_SLOTS_${token}`,
        this.onClickCopySlot,
      );
      AvailabilityBroadcast.subscribe(
        `COPY_ONLY_PERSONAL_LINK_${token}`,
        this.copyOnlyLink,
      );
    } else {
      AvailabilityBroadcast.unsubscribe(`COPY_PERSONAL_LINK_SLOTS_${token}`);
      AvailabilityBroadcast.unsubscribe(`COPY_ONLY_PERSONAL_LINK_${token}`);
    }
  }

  renderShareDropDown() {
    const SHARED_CLASS_NAME =
      "p-3 modal-text-options-select modal-text-hover-override flex justify-between select-none";
    const { shouldShowSpinnerForCopy } = this.state;
    const onClickCopySlots = () => {
      this.fetchFreeBusySlots();
      this.onClickCopySlot();
    };
    const onClickAddToWebsite = () => {
      this.setState({ modalType: COPY_IFRAME_MODAL, isModalOpen: true });
    };
    const showPreview = () => {
      this.setState({
        isPreviewOpen: true,
        previewModalTopPosition: this.getTopLocation(PREVIEW_MODAL),
      });
    };
    const hidePreview = () => {
      this.setState({ isPreviewOpen: false });
    };
    return (
      <div className={classNames("default-font-size cursor-pointer")}>
        <div
          className={classNames(SHARED_CLASS_NAME, "flex items-center")}
          onClick={onClickCopySlots}
          onMouseEnter={showPreview}
          onMouseLeave={hidePreview}
        >
          Copy Slots
          {shouldShowSpinnerForCopy
            ? this.renderSpinner()
            : this.renderHotKey()}
        </div>
        <div
          className={classNames(SHARED_CLASS_NAME)}
          onClick={onClickAddToWebsite}
        >
          Embed on website
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    isDarkMode,
    format24HourTime,
    eventFormEmails,
    currentTimeZoneLabel,
  } = state;

  return {
    currentUser,
    currentTimeZone,
    isDarkMode,
    format24HourTime,
    eventFormEmails,
    currentTimeZoneLabel,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(PersonalLinkContainer));
