import { OutlookCategoriesState } from "../../services/stores/outlookCategoriesStore";
import { safeSortObjects } from "../../lib/arrayFunctions";
import { OUTLOOK_COLORS } from "../../services/outlookColors";
import { getCalendarOwnerEmail } from "../../services/calendarAccessors";
import { getEventCategories, getEventUserEventID } from "../../services/eventResourceAccessors";
import { ColorOption } from "./types";
import { StoredCalendar } from "../../services/stores/SharedAccountData";

export function getOutlookColors(
  currentCalendar: StoredCalendar,
  outlookCategories: OutlookCategoriesState["outlookCategories"],
): ColorOption[] {
  const calendarOwner = getCalendarOwnerEmail(currentCalendar);
  const userCategories = outlookCategories[calendarOwner] ?? [];

  return safeSortObjects(userCategories, "displayName")
    .slice(0, 12)
    .map(({ color, displayName }) => ({
      color: OUTLOOK_COLORS[color]?.hex ?? "transparent",
      name: displayName,
      value: displayName,
    }));
}

interface ConstructOutlookEventDataOptions {
  categoryName: string
  event: VimcalOutlookEvent
}

export function constructOutlookEventData({ categoryName, event }: ConstructOutlookEventDataOptions) {
  const categories = getEventCategories(event) ?? [];
  const eventData = {
    calendar_event: {
      categories: getNewCategoryList(categories, categoryName),
    },
    user_event_id: getEventUserEventID(event),
  };

  const temporaryEventData: VimcalOutlookEvent = {
    ...event,
    categories: eventData.calendar_event.categories,
  };

  return { eventData, temporaryEventData };
}

export function getNewCategoryList(categoryNames: string[], categoryName: string) {
  if (categoryNames.includes(categoryName)) {
    return categoryNames.filter(c => c !== categoryName);
  } else {
    return [...categoryNames, categoryName];
  }
}
