import React from "react";
import { Check } from "react-feather";
import {
  getCalendarDescription,
  getCalendarEmail,
  getCalendarIsPrimary,
  getCalendarProviderId,
} from "../../services/calendarAccessors";
import { createLabelAndValueForReactSelect } from "../../services/commonUsefulFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import {
  determineCalendarColor,
  getCalendarName,
} from "../../lib/calendarFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { isCalendarExecutiveCalendar, isUserMaestroUser } from "../../services/maestroFunctions";
import ExecutiveLabel from "../../../components/executiveLabel";
import classNames from "classnames";

export function getMinutesList(showZero = false) {
  const list = showZero ? [0, 15, 25, 30, 45, 60] : [15, 25, 30, 45, 60];
  return createLabelAndValueForReactSelect(list);
}

// get list of select options for hours
export function getSelectTimeList({ is24HourFormat }) {
  const list = is24HourFormat
    ? [
        "0:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ]
    : [
        "12am",
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm",
      ];
  return list.map((label, index) => {
    return { label, value: index };
  });
}

export function createCalendarReactSelectOptions({
  currentCalendar,
  writableCalendars,
  masterAccount,
  emailToNameIndex,
  user,
  currentUser,
  allLoggedInUsers,
}) {
  const createLabel = ({ calendar }) => {
    const isExecutiveCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar: calendar?.value, allLoggedInUsers });
    return (
      <div className="flex items-center">
        <div
          className="min-height-16px h-4 w-4 min-width-16px rounded mr-2 flex items-center justify-center"
          style={{
            backgroundColor: determineCalendarColor(calendar.value),
          }}
        >
          {getCalendarProviderId(currentCalendar) ===
          getCalendarProviderId(calendar.value) ? (
            <Check color={"white"} size={12} />
          ) : null}
        </div>
        <div className={classNames("truncate", isExecutiveCalendar ? "max-width-112px" : "")}>
          {getCalendarName({
            calendar: calendar.value,
            emailToNameIndex,
            currentUser: user || currentUser,
            masterAccount,
          })}
        </div>
        {isExecutiveCalendar ? <ExecutiveLabel className="ml-2" /> : null}
      </div>
    );
  };

  const indexByUserEmail = {};
  writableCalendars.forEach((calendar) => {
    if (isEmptyObjectOrFalsey(calendar)) {
      return;
    }

    const userEmail = calendar.userEmail ?? calendar.value.userEmail;
    if (indexByUserEmail[userEmail]) {
      if (getCalendarIsPrimary(calendar.value)) {
        // put primary email at the top
        indexByUserEmail[userEmail] = [calendar].concat(
          indexByUserEmail[userEmail]
        );
      } else {
        indexByUserEmail[userEmail] =
          indexByUserEmail[userEmail].concat(calendar);
      }
    } else if (userEmail) {
      indexByUserEmail[userEmail] = [calendar];
    }
  });

  const userEmails = Object.keys(indexByUserEmail);
  let keys = [];

  userEmails.forEach((email) => {
    if (isSameEmail(email, getUserEmail(user))) {
      keys = [email].concat(keys);
    } else {
      keys = keys.concat(email);
    }
  });

  let options = [];
  keys.forEach((email) => {
    const calendars = indexByUserEmail[email];
    options = options.concat({
      label: email,
      options: calendars.map((c) => {
        return { label: createLabel({ calendar: c }), value: c.value };
      }),
    });
  });

  return options;
}

export function filterForCalendarSelect({
  candidate,
  input,
  emailToNameIndex,
  user,
  masterAccount,
}) {
  if (!input) {
    return true;
  }

  const lowerCaseInput = input.toLowerCase();

  const calendar = candidate?.value;
  if (getCalendarName(calendar)?.toLowerCase().includes(lowerCaseInput)) {
    return true;
  }
  if (getCalendarEmail(calendar)?.toLowerCase().includes(lowerCaseInput)) {
    return true;
  }
  if (
    getCalendarDescription(calendar)?.toLowerCase().includes(lowerCaseInput)
  ) {
    return true;
  }
  if (
    getCalendarName({
      calendar: calendar,
      emailToNameIndex,
      currentUser: user,
      masterAccount,
    })
      ?.toLowerCase()
      .includes(lowerCaseInput)
  ) {
    return true;
  }

  return false;
}

export const SELECT_HOUR_VALUES = [1, 2, 3];
export const SELECT_DAY_VALUES = [1, 2, 3, 4, 5, 6, 7];
export const SELECT_MINUTE_VALUES = [5, 10, 15, 30, 45, 60];
export const SELECT_HOUR_LIST = createLabelAndValueForReactSelect(SELECT_HOUR_VALUES);
export const SELECT_DAY_LIST = createLabelAndValueForReactSelect(SELECT_DAY_VALUES);
export const SELECT_MINUTE_LIST = createLabelAndValueForReactSelect(SELECT_MINUTE_VALUES);
