import React from "react";
import { isElectron } from "../js/services/commonUsefulFunctions";

export default function DraggableHeader() {
  if (!isElectron()) {
    return null;
  }

  return (
    <div className="desktop-drag-region w-screen h-10 z-50 top-0 fixed" />
  );
}
