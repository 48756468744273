import React from "react";

const OnboardingDark = () => (
  <svg
    width="186"
    height="112"
    viewBox="0 0 362 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_252_560)">
      <rect width="362" height="215" rx="5" fill="#25282B" />
      <line x1="76.5" y1="-2.18557e-08" x2="76.5" y2="215" stroke="#494E52" />
      <line x1="219.5" y1="-2.18557e-08" x2="219.5" y2="215" stroke="#494E52" />
      <line x1="76" y1="44.5" x2="362" y2="44.5" stroke="#494E52" />
      <line x1="76" y1="88.5" x2="362" y2="88.5" stroke="#494E52" />
      <line x1="76" y1="132.5" x2="362" y2="132.5" stroke="#494E52" />
      <line x1="76" y1="176.5" x2="362" y2="176.5" stroke="#494E52" />
      <rect
        x="54"
        y="41"
        width="8"
        height="32"
        rx="3"
        transform="rotate(90 54 41)"
        fill="#474D52"
        fillOpacity="0.5"
      />
      <rect
        x="54"
        y="85"
        width="8"
        height="32"
        rx="3"
        transform="rotate(90 54 85)"
        fill="#474D52"
        fillOpacity="0.5"
      />
      <rect
        x="54"
        y="129"
        width="8"
        height="32"
        rx="3"
        transform="rotate(90 54 129)"
        fill="#474D52"
        fillOpacity="0.5"
      />
      <rect
        x="54"
        y="173"
        width="8"
        height="32"
        rx="3"
        transform="rotate(90 54 173)"
        fill="#474D52"
        fillOpacity="0.5"
      />
      <g filter="url(#filter0_d_252_560)">
        <rect x="219" y="133" width="139" height="40" rx="4" fill="#D81B60" />
      </g>
      <line
        x1="77"
        y1="44"
        x2="219"
        y2="44"
        stroke="#DB6A7D"
        strokeWidth="2"
      />
      <g filter="url(#filter1_d_252_560)">
        <rect x="76" y="89" width="139" height="40" rx="4" fill="#429BDF" />
      </g>
      <g filter="url(#filter2_d_252_560)">
        <rect
          x="76"
          y="177"
          width="139"
          height="40"
          rx="4"
          fill="url(#paint0_linear_252_560)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_252_560"
        x="215"
        y="133"
        width="147"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_252_560"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_252_560"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_252_560"
        x="72"
        y="89"
        width="147"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_252_560"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_252_560"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_252_560"
        x="72"
        y="177"
        width="147"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_252_560"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_252_560"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_252_560"
        x1="76"
        y1="197"
        x2="215"
        y2="197"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#4153AF" />
        <stop offset="1" stopColor="#8332A4" />
      </linearGradient>
      <clipPath id="clip0_252_560">
        <rect width="362" height="215" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OnboardingDark;
