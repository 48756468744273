import React from "react";
import ColoredLine from "../../line";

interface SettingsSubtitleProps {
  children: React.ReactNode
  id?: string
  labelClassName?: string
}

export default function SettingsSubtitle({ children, id, labelClassName="mt-8 mb-4" }: SettingsSubtitleProps) {
  return (
    <>
      <div id={id} className={labelClassName}>
        {children}
      </div>
      <ColoredLine inputClassName="mb-4" />
    </>
  );
}
