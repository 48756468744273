import React from "react";
import { Edit2, Trash2, FilePlus, Share, Check } from "react-feather";
import { hasStopEventPropagation } from "../../services/commonUsefulFunctions";
import ShortcutHoverHint from "../shortcutHoverHint";
import ColoredLine from "../line";
import { getAttendees } from "../../lib/availabilityFunctions";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { isSpreadsheetGroupLink } from "../../lib/groupVoteFunctions";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { hasGroupSpreadsheetAccess } from "../../lib/featureFlagFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { pluralize, truncateString } from "../../lib/stringFunctions";
import SpinnerV2 from "../spinnerV2";

export default function AvailabilitySectionContainer({
  bookingLinkInfo,
  uniqueKey,
  onClickPreview,
  onClickDuplicate,
  onClickTrash,
  onClickEdit,
  copyOnlyLink,
  copySlot,
  children,
  onClickShare,
  previewText,
  isFetchingPreview,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const onClickPreviewWrapper = (e) => {
    hasStopEventPropagation(e);
    if (onClickPreview) {
      onClickPreview();
    }
  };

  const onClickDuplicateWrapper = (e) => {
    hasStopEventPropagation(e);
    onClickDuplicate && onClickDuplicate();
  };

  const onClickTrashWrapper = (e) => {
    hasStopEventPropagation(e);
    onClickTrash && onClickTrash();
  };

  const onClickEditWrapper = (e) => {
    hasStopEventPropagation(e);
    onClickEdit && onClickEdit();
  };

  const copyOnlyLinkWrapper = (e) => {
    hasStopEventPropagation(e);
    copyOnlyLink && copyOnlyLink();
  };

  const onClickCopySlotWrapper = (e) => {
    hasStopEventPropagation(e);
    copySlot && copySlot();
  };

  if (isEmptyObjectOrFalsey(bookingLinkInfo)) {
    return null;
  }

  const { title, duration } = bookingLinkInfo;
  const attendees = getAttendees(bookingLinkInfo);

  const renderCopySlots = () => {
    if (!copySlot) {
      return null;
    }
    return (
      <div className="copy-link-container display-flex-flex-direction-row align-items-center">
        <div
          className={
            "copy-link display-flex-flex-direction-row align-items-center"
          }
          onClick={onClickCopySlotWrapper}
        >
          {"Copy Slots"}
        </div>
      </div>
    );
  };

  const renderHourText = () => {
    if (isEmptyObjectOrFalsey(duration)) {
      return null;
    }

    if (!duration.hours) {
      return null;
    }

    return (
      <div className="margin-right-5">
        {`${duration.hours} ${pluralize(duration.hours, "hr")}`}
      </div>
    );
  };

  const renderTooltip = () => {
    return (
      <div className="display-flex-flex-direction-row justify-content-space-between align-items-center">
        <div
          className="hoverable-text margin-left-20 default-font-size"
          onClick={onClickPreviewWrapper}
        >
          {isFetchingPreview ? <SpinnerV2 variant="small" /> : previewText ?? "Preview"}
        </div>

        <div className="display-flex-flex-direction-row justify-content-flex-end padding-10 margin-right-10">
          {onClickShare ? (
            <ShortcutHoverHint
              left
              style={{
                right: 30,
                top: -5,
              }}
              title={"Share"}
            >
              <Share
                className="clickable-icon mr-2.5"
                size="17"
                onClick={onClickShare}
              />
            </ShortcutHoverHint>
          ) : null}

          <ShortcutHoverHint
            left
            style={{
              right: 35,
              top: -5,
            }}
            title={"Duplicate"}
          >
            <FilePlus
              className="clickable-icon margin-right-10"
              size="17"
              onClick={onClickDuplicateWrapper}
            />
          </ShortcutHoverHint>

          <ShortcutHoverHint
            left
            style={{
              right: 35,
              top: -5,
            }}
            title={"Delete"}
          >
            <Trash2
              className="clickable-icon margin-right-10"
              size="17"
              onClick={onClickTrashWrapper}
            />
          </ShortcutHoverHint>

          <ShortcutHoverHint
            left
            style={{
              right: 25,
              top: -5,
            }}
            title={"Edit"}
          >
            <Edit2
              className="clickable-icon"
              size="17"
              onClick={onClickEditWrapper}
            />
          </ShortcutHoverHint>
        </div>
      </div>
    );
  };

  const renderAttendeesAttending = () => {
    const getText = () => {
      if (isEmptyArray(attendees)) {
        return null;
      }
      const votedAttendees = attendees.filter(attendee => attendee?.slots?.length > 0);
      const pendingAttendees = attendees.filter(attendee => !attendee?.slots?.length);

      if (votedAttendees.length === 0) {
        return `${pendingAttendees.length} pending`;
      }

      if (pendingAttendees.length === 0) {
        return `${votedAttendees.length} voted`;
      }

      return `${votedAttendees.length} voted, ${pendingAttendees.length} pending`;
    };

    const renderIcon = () => {
      if (!hasGroupSpreadsheetAccess({ user: currentUser, masterAccount })) {
        return null;
      }
      if (isSpreadsheetGroupLink(bookingLinkInfo)) {
        return (
          <div className="mr-2">
            <Check size={10} strokeWidth={2} />
            <div className={classNames("secondary-background-color w-5 h-0.5 rounded-lg", "opacity-50")}></div>
            <div className={classNames("secondary-background-color w-3 h-0.5 rounded-lg mt-0.5", "opacity-50")}></div>
          </div>
        );
      }
      return <Check size={14} strokeWidth={3} className="mr-2" />;
    }

    const label = getText();
    return (
      <div className="display-flex-flex-direction-row default-font-size font-weight-300 user-select-none margin-top-ten secondary-text-color items-center">
        {renderIcon()}
        {label}
      </div>
    );
  };

  return (
    <div
      key={`group-vote-link-container-${uniqueKey}`}
      className="personal-link-container"
    >
      {renderTooltip()}
      <div
        style={{
          marginTop: 15,
          marginBottom: 20,
          paddingLeft: 20,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        {truncateString(title, 65)}

        {renderAttendeesAttending()}
        <div className="display-flex-flex-direction-row default-font-size font-weight-300 user-select-none margin-top-ten">
          {renderHourText()}

          {duration?.minutes
            ? `${duration.minutes} ${pluralize(duration.minutes, "min")}`
            : null}
        </div>
      </div>
      {children}

      <ColoredLine />

      <div
        className="display-flex-flex-direction-row padding-10 align-items-center justify-content-space-between height-50"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {renderCopySlots()}

        <div
          className="default-font-size font-weight-300 display-flex-flex-direction-row hoverable-text"
          onClick={copyOnlyLinkWrapper}
        >
          Copy Link
        </div>
      </div>
    </div>
  );
}
