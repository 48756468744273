import { format } from "date-fns";
import React, { useState } from "react";
import { isScheduledToBeCancelled } from "../../lib/stripeFunctions";
import { useStripeSubscriptions } from "../../services/stores/finance";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import Alert from "../availability/alerts/alert";
import { ALERT_TYPE } from "../availability/alerts/utils";
import { useIsCurrentUserOwner } from "../teamPlans/hooks";
import ResumeSubscriptionModal from "./resumeSubscriptionModal";

/**
 * An alert warning the user that their subscription is scheduled to be cancelled.
 * If the current user is the owner of the subscription, they will have the option to resume it.
 */
export default function CancellationWarning() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stripeSubscription = useStripeSubscriptions(
    (state) => state.stripeSubscriptions,
  );

  const isOwner = useIsCurrentUserOwner();

  if (isEmptyObjectOrFalsey(stripeSubscription) || !isScheduledToBeCancelled({ stripeSubscription })) {
    return null;
  }

  const cancelAt = stripeSubscription.cancel_at ?? stripeSubscription.current_period_end;

  return (
    <>
      <Alert
        variant={ALERT_TYPE.WARNING}
        title={(
          <>
            <span>
              Your subscription is scheduled to cancel on{" "}
              {format(new Date(cancelAt * 1000), "PPP")}.
            </span>
            {isOwner ? (
              <span>
                {" "}
                <span className="text-color-link cursor-pointer" onClick={() => setIsModalOpen(true)}>
                  Click here
                </span>
                {" "}to resume.
              </span>
            ) : null}
          </>
        )}
      />
      {isOwner ? (
        <ResumeSubscriptionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ): null}
    </>
  );
}
