import RRule from "rrule";
import { addDays } from "date-fns";

import { handleError } from "../services/commonUsefulFunctions";
import { isStringArraysEqualIgnoringOrder } from "./arrayFunctions";

export function getWeekdayConstant(date) {
  const days = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ];
  return days[date.getUTCDay()];
}

export function getRecurrenceFrequencyForAudit(rruleString) {
  try {
    if (!rruleString) {
      return "";
    }
    const rrule = RRule.fromString(rruleString);
    const frequency = rrule.options.freq;
    const interval = rrule.options.interval || 1;
    const byweekday = rrule.options.byweekday || [];
    const bymonthday = rrule.options.bymonthday || [];
    const daysPerMonth = bymonthday.length || 1;
    const daysPerWeek = byweekday.length || 1;

    // Calculate occurrences per day based on frequency
    switch (frequency) {
      case RRule.DAILY:
        return interval === 1 ? "Daily" : "Custom";
      case RRule.WEEKLY:
        if (![1, 2].includes(interval)) {
          return "Custom";
        }
        if (isStringArraysEqualIgnoringOrder([RRule.MO.weekday, RRule.TU.weekday, RRule.WE.weekday, RRule.TH.weekday, RRule.FR.weekday], byweekday)) {
          return "Weekdays";
        }
        if (isStringArraysEqualIgnoringOrder([RRule.MO.weekday, RRule.TU.weekday, RRule.WE.weekday, RRule.TH.weekday, RRule.FR.weekday, RRule.SA.weekday, RRule.SU.weekday], byweekday)) {
          return "Every day";
        }
        if (isStringArraysEqualIgnoringOrder([RRule.SA.weekday, RRule.SU.weekday], byweekday)) {
          return "Weekends";
        }
        if (daysPerWeek > 1) {
          return "Custom";
        }

        return interval === 1 ? "Weekly" : "Biweekly";
      case RRule.MONTHLY:
        if (daysPerMonth > 1) {
          return "Custom";
        }

        return interval === 1 ? "Monthly" : "Custom";
      case RRule.YEARLY:
        return interval === 1 ? "Yearly" : "Custom";
      default:
        return "Custom";
    }
  } catch (e) {
    handleError(e);
    return "Custom";
  }
}

export function getNumberOfOccurrencesOverXDays({
  numberOfDays,
  rruleString,
  startDate,
}) {
  try {
    if (!rruleString) {
      return 0;
    }
    const rrule = RRule.fromString(rruleString);
    const endDate = addDays(startDate, numberOfDays);
    const occurrences = rrule.between(startDate, endDate, true);

    // # of days from startDate to endDate
    return occurrences.length;
  } catch (e) {
    handleError(e);
    return 0;
  }
}
