import React from "react";
import { batch, useDispatch } from "react-redux";
import { REDUCER_TYPES } from "../../services/reducers";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { APP_SETTINGS } from "../../lib/vimcalVariables";
import { Plus } from "react-feather";

export default function CreateColorLabelPlus({ selectedUser }) {
  const dispatch = useDispatch();
  const onClick = () => {
    batch(() => {
      dispatch({ type: REDUCER_TYPES.REMOVE_POPUP_EVENT });
      dispatch({ type: REDUCER_TYPES.REMOVE_CURRENT_PREVIEW_EVENT });
    });
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
      initialContent: APP_SETTINGS.PREFERENCES,
      scrollToSettingContent: APP_SETTINGS.COLOR_LABEL,
      initialSettingsUser: selectedUser,
    });
  };

  return (
    <div
      className="cursor-pointer pill-border w-6 h-6 flex items-center justify-center"
      onClick={onClick}
    >
      <Plus size={14} strokeWidth={2} />
    </div>
  );
}
