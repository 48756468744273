import React, { useEffect, useMemo, useState } from "react";
import CustomSelect from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import { customMenuStyle, getReactSelectBaseStyle } from "../../../select/styles";
import { useSelector } from "react-redux";
import CircleWithColor from "../../../circleWithColor";
import { getColorFromID } from "../../../../lib/painterFunctions";
import classNames from "classnames";
import DefaultSwitch from "../../../defaultSwitch";
import ColoredLine from "../../../line";
import { Edit2 } from "react-feather";
import CustomButton from "../../../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../../../services/globalVariables";
import { APP_SETTINGS, BACKEND_SETTINGS_NAMES, PREFERENCES_SETTINGS_ID, SPECIAL_TAGS_TYPE } from "../../../../lib/vimcalVariables";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import { useIsMounted } from "../../../../services/customHooks/useIsMounted";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import {
  DEFAULT_SPECIAL_COLOR_DATA,
  getExternalTagFromUser,
  getInternalTagFromUser,
  isPriorityTag,
} from "../../../../lib/tagsFunctions";
import PrioritizedStarButton from "./prioritizedStarButton";
import IsPrioritizedTagStar from "./isPrioritizedTagStar";
import { arraysAreEqual, isEmptyArray, removeDuplicatesFromArray } from "../../../../lib/arrayFunctions";
import {
  isValidEmail,
  isUrl,
  lowerCaseAndTrimString,
  formatEmail,
  isGenericDomain,
  cleanUpDomainEmail,
} from "../../../../lib/stringFunctions";
import {
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../../../../services/typeGuards";
import DomainLabelContainer from "../../common/domainLabelContainer";
import InternalDomainSetting from "../../common/internalDomainSetting";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { getInternalDomainAndEmails } from "../../../../lib/settingsFunctions";
import { SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import { isUserDelegatedUser } from "../../../../services/maestroFunctions";

export default function InternalExternalTagsSection({
  colorOptions,
  isOpen,
  setIsOpen,
  selectedUser,
}) {
  const componentIsMounted = useIsMounted();
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const {
    originalInternalColorData,
    originalExternalColorData,
    originalDomainEmails,
    originalIsInternalToggledOn,
    originalIsExternalToggledOn,
  } = getOriginalData({ user: selectedUser, masterAccount });
  const currentInternalDomains = useMemo(() => {
    return originalDomainEmails;
  }, [selectedUser, masterAccount]);

  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [currentDomainText, setCurrentDomainText] = useState("");
  const [domainEmails, setDomainEmails] = useState(originalDomainEmails);
  const domainEmailsRef = React.useRef(originalDomainEmails);

  const shouldHideUpsertDomains = currentInternalDomains?.length > 0;

  useEffect(() => {
    onClickCancel();
  }, [selectedUser]);

  useEffect(() => {
    settingsBroadcast.subscribe("CLOSE_INTERNAL_EXTERNAL_TAGS", () => {
      setIsOpen(false);
    });
    settingsBroadcast.subscribe("SET_INTERNAL_EXTERNAL_WARNING", setWarning);

    return () => {
      settingsBroadcast.unsubscribe("CLOSE_INTERNAL_EXTERNAL_TAGS");
      settingsBroadcast.unsubscribe("SET_INTERNAL_EXTERNAL_WARNING");
    };
  }, []);

  const setDomainEmailsAndRef = (emails) => {
    domainEmailsRef.current = emails;
    setDomainEmails(emails);
    setWarning("");
  };

  // default to transparent
  const [internalColorData, setInternalColorData] = useState(
    originalInternalColorData
  );
  const [externalColorData, setExternalColorData] = useState(
    originalExternalColorData
  );
  const [isInternalToggledOn, setInternalToggleOn] = useState(
    originalIsInternalToggledOn
  );
  const [isExternalToggledOn, setExternalToggleOn] = useState(
    originalIsExternalToggledOn
  );
  const [warning, setWarning] = useState("");

  const addEmail = (email) => {
    openSection();
    const formattedEmail = formatEmail(email);
    if (isGenericDomain(formattedEmail)) {
      setWarning(
        `"${formattedEmail} is too generic. Please enter your organization's domain (e.g. vimcal.com) or a specific teammate's email, e.g. freelancer@${formattedEmail}."`
      );
      return;
    }
    if (!isUrl(formattedEmail) && !isValidEmail(formattedEmail)) {
      setWarning("Please enter a valid URL or email address");
      return;
    }

    if (domainEmails.includes(formattedEmail)) {
      return;
    }
    setDomainEmailsAndRef(
      removeDuplicatesFromArray([...domainEmails, formattedEmail])
    );
  };

  const removeEmail = (email) => {
    openSection();
    setDomainEmailsAndRef(
      domainEmails.filter((guestEmail) => guestEmail !== email)
    );
  };

  const onClickSave = () => {
    if (currentDomainText && isUrl(lowerCaseAndTrimString(currentDomainText))) {
      addEmail(currentDomainText);
      setCurrentDomainText("");
    }

    if (!componentIsMounted.current) {
      return;
    }

    // the addEmail above is not instant, so need to use ref
    if (
      domainEmailsRef.current.length === 0 &&
      (isInternalToggledOn || isExternalToggledOn) // if both are off, then it's ok to have zero domains
    ) {
      setWarning("Please set at least one internal domain.");
      return;
    }

    if (
      !originalIsInternalToggledOn &&
      !isInternalToggledOn &&
      parseInt(internalColorData.color_id) !== -1
    ) {
      // If the tag is not toggled on, and the color is not the default color, then we need to delete the tag
      setWarning("You can only set the color if the tag is toggled on.");
      return;
    }

    if (
      !originalExternalColorData &&
      !isExternalToggledOn &&
      parseInt(externalColorData.color_id) !== -1
    ) {
      // If the tag is not toggled on, and the color is not the default color, then we need to delete the tag
      setWarning("You can only set the color if the tag is toggled on.");
      return;
    }

    if (warning) {
      setWarning("");
    }

    let updatedSpecialTags = [];
    const updatedDomains = domainEmailsRef.current.filter((link) =>
      isUrl(cleanUpDomainEmail(link))
    );
    const updatedEmails = domainEmailsRef.current.filter((link) =>
      isValidEmail(cleanUpDomainEmail(link))
    );

    if (shouldUpdateInternalTags()) {
      const updatedInternalTag = {
        special_tag_name: SPECIAL_TAGS_TYPE.INTERNAL,
        color: internalColorData.color,
        color_id: internalColorData.color_id,
        domains: updatedDomains,
        emails: updatedEmails,
        is_prioritized: isPriorityTag(internalColorData),
      };
      if (!isInternalToggledOn && !isNullOrUndefined(internalColorData.id)) {
        // if off -> provide id to delete
        updatedInternalTag.delete = true;
        updatedInternalTag.id = internalColorData.id;
      }
      updatedSpecialTags = updatedSpecialTags.concat(updatedInternalTag);
    }

    if (shouldUpdateExternalTags()) {
      const updatedExternalTag = {
        special_tag_name: SPECIAL_TAGS_TYPE.EXTERNAL,
        color: externalColorData.color,
        color_id: externalColorData.color_id,
        domains: updatedDomains,
        emails: updatedEmails,
        is_prioritized: isPriorityTag(externalColorData),
      };
      if (!isExternalToggledOn && !isNullOrUndefined(externalColorData.id)) {
        // if off -> provide id to delete
        updatedExternalTag.delete = true;
        updatedExternalTag.id = externalColorData.id;
      }
      updatedSpecialTags = updatedSpecialTags.concat(updatedExternalTag);
    }

    backendBroadcasts.publish("UPSERT_SPECIAL_TAGS", {
      specialTags: updatedSpecialTags,
      user: selectedUser,
    });
    if (!shouldHideUpsertDomains) {
      const internalDomains = removeDuplicatesFromArray(
        updatedDomains.concat(updatedEmails)
      );
      const updatedSettings = {
        [BACKEND_SETTINGS_NAMES.INTERNAL_DOMAINS]: internalDomains,
      };
      updateMasterAccountSettingsForFrontendAndBackend({
        isUpdatingExecutiveProfile: isUserDelegatedUser(selectedUser),
        masterAccount,
        updatedSettings,
        user: selectedUser,
      });
    }
  };

  const shouldUpdateInternalTags = () => {
    return shouldUpdateBackend({
      currentColorData: internalColorData,
      originalColorData: originalInternalColorData,
      currentIsToggledOn: isInternalToggledOn,
      originalIsToggledOn: originalIsInternalToggledOn,
      domainEmails: domainEmailsRef.current,
      originalDomainEmails,
      currentIsPrioritized: isPriorityTag(internalColorData),
      originalIsPrioritized: isPriorityTag(originalInternalColorData),
      where: SPECIAL_TAGS_TYPE.INTERNAL,
    });
  };
  const shouldUpdateExternalTags = () => {
    return shouldUpdateBackend({
      currentColorData: externalColorData,
      originalColorData: originalExternalColorData,
      currentIsToggledOn: isExternalToggledOn,
      originalIsToggledOn: originalIsExternalToggledOn,
      domainEmails: domainEmailsRef.current,
      originalDomainEmails,
      currentIsPrioritized: isPriorityTag(externalColorData),
      originalIsPrioritized: isPriorityTag(originalExternalColorData),
      where: SPECIAL_TAGS_TYPE.EXTERNAL,
    });
  };

  const onClickCancel = () => {
    // revert data
    setDomainEmailsAndRef(originalDomainEmails);
    setInternalColorData(originalInternalColorData);
    setExternalColorData(originalExternalColorData);
    setInternalToggleOn(originalIsInternalToggledOn);
    setExternalToggleOn(originalIsExternalToggledOn);
    setIsOpen(false);
    setWarning(false);
  };

  const openSection = () => {
    setIsOpen(true);
  };

  if (!isOpen) {
    return (
      <div className="painter-section-container mt-4">
        {renderHeader({ isOpen, openSection })}
        <div className="secondary-text-color mt-2 default-font-size mb-6 select-none">
          Internal meetings only include guests from whitelisted domains. A
          meeting with anyone else is external.
        </div>

        {renderClosedInternal({
          colorObject: internalColorData,
          domains: domainEmails,
          isOn: isInternalToggledOn,
          shouldHideUpsertDomains,
        })}
        <ColoredLine inputClassName="mt-4 mb-4" />
        {renderClosedExternal({
          colorObject: externalColorData,
          domains: domainEmails,
          isOn: isExternalToggledOn,
          shouldHideUpsertDomains,
        })}
      </div>
    );
  }

  return (
    <div className="painter-section-container mt-4">
      {renderHeader({ isOpen, openSection })}
      <div className="secondary-text-color mt-2 default-font-size select-none">
        Internal meetings only include guests from whitelisted domains. A
        meeting with anyone else is external.
      </div>
      <div className="mt-6">
        {renderColorPicker({
          type: SPECIAL_TAGS_TYPE.INTERNAL,
          isDarkMode,
          colorOptions,
          colorObject: internalColorData,
          isToggledOn: isInternalToggledOn,
          onSetColorIndex: setInternalColorData,
          isOpen,
          openSection,
          setToggle: setInternalToggleOn,
        })}
        {shouldHideUpsertDomains
          ? renderClickHereToEditInternalDomains(domainEmails)
          : null}

        {renderColorPicker({
          type: SPECIAL_TAGS_TYPE.EXTERNAL,
          isDarkMode,
          colorOptions,
          colorObject: externalColorData,
          additionalClassName: "mt-4",
          isToggledOn: isExternalToggledOn,
          onSetColorIndex: setExternalColorData,
          isOpen,
          openSection,
          setToggle: setExternalToggleOn,
        })}
      </div>

      {shouldHideUpsertDomains ? null : (
        <ColoredLine inputClassName="mt-8 mb-8" />
      )}

      {isOpen
        ? renderDomainsForEditMode({
            currentDomainText,
            setCurrentDomainText,
            domainEmails,
            isOpen,
            addEmail,
            removeEmail,
            selectedUser,
            shouldHideUpsertDomains,
          })
        : renderDomainForDisplayMode({ domainEmails })}
      {warning ? (
        <div className="warning-color default-font-size mt-1">{warning}</div>
      ) : null}

      {renderSaveButton({
        isOpen,
        onClickSave,
        onClickCancel,
      })}
    </div>
  );
}

function renderColorPicker({
  type,
  isDarkMode,
  colorOptions,
  colorObject,
  isToggledOn,
  onSetColorIndex,
  additionalClassName,
  openSection,
  setToggle,
}) {
  const getTypeLabel = () => {
    switch (type) {
      case SPECIAL_TAGS_TYPE.INTERNAL:
        return "Internal";
      case SPECIAL_TAGS_TYPE.EXTERNAL:
        return "External";
      default:
        return "";
    }
  };
  const { name, color_id, color } = colorObject;
  const isPrioritized = isPriorityTag(colorObject);

  return (
    <div
      className={classNames(
        "display-flex-flex-direction-row align-items-center justify-between",
        additionalClassName ?? ""
      )}
    >
      <div className="flex items-center">
        <CustomSelect
          components={{ DropdownIndicator }}
          openMenuOnFocus={true}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
            menuListStyle: customMenuStyle({ width: 180 }),
          })}
          tabSelectsValue={false}
          isSearchable={false}
          className={"select-calendar-color-dropdown"}
          classNamePrefix="dark-mode"
          value={{
            label: (
              <CircleWithColor
                color={getColorFromID({ id: color_id, color })}
              />
            ),
            color: getColorFromID({ id: color_id, color }),
            name: name,
            value: color_id,
          }}
          onChange={(val) => {
            // val = {value: '2', name: 'Sage', color: '#33b679', label: {…}}
            const { value, color } = val;
            openSection();
            const updatedColorData = {
              ...colorObject,
              color_id: value,
              color,
              name: type,
            };
            onSetColorIndex(updatedColorData);
          }}
          options={colorOptions}
          maxMenuHeight={155}
          alwaysShowIndicator={true}
        />

        <div className="default-font-size ml-5">{getTypeLabel()}</div>
      </div>

      <div className="flex items-center">
        <PrioritizedStarButton
          isPrioritized={isPrioritized}
          onClick={() => {
            const updatedColorData = {
              ...colorObject,
              is_prioritized: !isPrioritized,
            };
            onSetColorIndex(updatedColorData);
          }}
          isDisabled={!isToggledOn}
        />
        <DefaultSwitch
          id={`default-switch-${type}`}
          isChecked={isToggledOn}
          onChange={(isOn) => {
            setToggle(isOn);
            openSection();
          }}
        />
      </div>
    </div>
  );
}

function renderHeader({ isOpen, openSection }) {
  return (
    <div className="font-weight-500 default-font-size flex justify-between items-center select-none">
      Internal and External Meetings
      {isOpen ? null : (
        <Edit2
          className="hoverable-secondary-text-color"
          size={16}
          onClick={openSection}
        />
      )}
    </div>
  );
}

// for when it's not edit mode
function renderDomainForDisplayMode({ domainEmails }) {
  return (
    <div>
      <div className="default-font-size">Internal domains and emails</div>
      {domainEmails?.length > 0 ? (
        <DomainLabelContainer domainEmails={domainEmails} />
      ) : (
        <div className="default-font-size secondary-text-color">None</div>
      )}
    </div>
  );
}

// for when it's not edit mode
function renderDomainsForEditMode({
  currentDomainText,
  setCurrentDomainText,
  domainEmails,
  isOpen,
  addEmail,
  removeEmail,
  selectedUser,
  shouldHideUpsertDomains,
}) {
  if (shouldHideUpsertDomains) {
    return null;
  }
  if (!isOpen) {
    return null;
  }
  return (
    <div>
      <div className="default-font-size">Internal domains and emails</div>
      <InternalDomainSetting
        selectedUser={selectedUser}
        addEmail={addEmail}
        removeEmail={removeEmail}
        domainEmails={domainEmails}
        currentDomainText={currentDomainText}
        setCurrentDomainText={setCurrentDomainText}
      />
    </div>
  );
}

function renderSaveButton({ isOpen, onClickSave, onClickCancel }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex items-center justify-end mt-5">
      <CustomButton
        buttonType={WHITE_BUTTON}
        onClick={onClickCancel}
        label="Cancel"
        addPaddingToRight={true}
      />

      <CustomButton
        buttonType={BLUE_BUTTON}
        onClick={onClickSave}
        label="Save"
      />
    </div>
  );
}

function getOriginalData({ user, masterAccount }) {
  const internalTag = getInternalTagFromUser({ user });
  const externalTag = getExternalTagFromUser({ user });
  return {
    originalInternalColorData: getOriginalInternalTagData({ tag: internalTag }),
    originalExternalColorData: getOriginalExternalTagData({ tag: externalTag }),
    originalDomainEmails: getInternalDomainAndEmails({ user, masterAccount }),
    originalIsInternalToggledOn: !isEmptyObjectOrFalsey(internalTag),
    originalIsExternalToggledOn: !isEmptyObjectOrFalsey(externalTag),
  };
}

function getOriginalDomainEmails({ internalTag, externalTag }) {
  const tag = !isEmptyObjectOrFalsey(internalTag) ? internalTag : externalTag;
  const fields = tag?.rules?.fields;
  if (isEmptyArray(fields)) {
    return [];
  }
  return fields.map((field) => field.value);
}

function getOriginalInternalTagData({ tag }) {
  if (isEmptyObjectOrFalsey(tag)) {
    return {
      name: SPECIAL_TAGS_TYPE.INTERNAL,
      ...DEFAULT_SPECIAL_COLOR_DATA,
    };
  }
  return tag;
}

function getOriginalExternalTagData({ tag }) {
  if (isEmptyObjectOrFalsey(tag)) {
    return {
      name: SPECIAL_TAGS_TYPE.EXTERNAL,
      ...DEFAULT_SPECIAL_COLOR_DATA,
    };
  }
  return tag;
}

function shouldUpdateBackend({
  currentColorData,
  originalColorData,
  currentIsToggledOn,
  originalIsToggledOn,
  domainEmails,
  originalDomainEmails,
  currentIsPrioritized,
  originalIsPrioritized,
}) {
  if (currentIsToggledOn !== originalIsToggledOn) {
    return true;
  }
  if (currentIsPrioritized !== originalIsPrioritized) {
    return true;
  }

  if (currentIsToggledOn === originalIsToggledOn) {
    if (currentIsToggledOn) {
      return (
        currentColorData.color_id !== originalColorData.color_id ||
        !arraysAreEqual(domainEmails, originalDomainEmails)
      );
    }

    // if it's off, updating things lke color and domains don't matter
    return false;
  }

  if (currentColorData.color_id !== originalColorData.color_id) {
    return true;
  }

  if (!arraysAreEqual(domainEmails, originalDomainEmails)) {
    return true;
  }

  return false;
}

function renderClosedInternal({
  colorObject,
  domains,
  isOn,
  shouldHideUpsertDomains,
}) {
  const { color_id, color } = colorObject;
  const isPrioritized = isPriorityTag(colorObject);
  const getDomainText = () => {
    if (isEmptyArray(domains)) {
      return "";
    }
    const filteredDomains = domains.map((d) => cleanUpDomainEmail(d));
    return `${filteredDomains.join(", ")}`;
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center w-full">
          <CircleWithColor color={getColorFromID({ id: color_id, color })} />
          <div className="default-font-size ml-5">Internal</div>
          <div className="default-font-size secondary-text-color ml-4 truncate-text width-480px">
            {getDomainText()}
          </div>
        </div>

        {isOn && isPrioritized ? (
          <IsPrioritizedTagStar className="mr-4" />
        ) : null}

        <div className="default-font-size secondary-text-color mt-0.5">
          {isOn ? "On" : "Off"}
        </div>
      </div>
      {shouldHideUpsertDomains
        ? renderClickHereToEditInternalDomains(domains)
        : null}
    </>
  );
}

function renderClickHereToEditInternalDomains(domains) {
  if (isEmptyArray(domains)) {
    return null;
  }
  const onClickHere = () => {
    settingsBroadcast.publish(SETTINGS_BROADCAST_VALUES.SET_SETTINGS_PAGE, {
      option: APP_SETTINGS.PREFERENCES,
      scrollToSection: PREFERENCES_SETTINGS_ID.INTERNAL_DOMAINS,
    });
  };

  return (
    <div className="select-none">
      <DomainLabelContainer domainEmails={domains} />
      <div className="default-font-size mt-4 secondary-text-color">
        Add more internal domains/emails{" "}
        <span
          className="ml-0.5 text-color-link cursor-pointer"
          onClick={onClickHere}
        >
          here
        </span>
      </div>
    </div>
  );
}

function renderClosedExternal({ colorObject, isOn }) {
  const { color_id, color } = colorObject;
  const isPrioritized = isPriorityTag(colorObject);
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center w-full">
        <CircleWithColor color={getColorFromID({ id: color_id, color })} />
        <div className="default-font-size ml-5">External</div>
      </div>

      {isOn && isPrioritized ? <IsPrioritizedTagStar className="mr-4" /> : null}
      <div className="default-font-size secondary-text-color mt-0.5">
        {isOn ? "On" : "Off"}
      </div>
    </div>
  );
}
