import {
  startOfHour,
  addHours,
  addDays,
  setDay,
  addMinutes,
  startOfMinute,
  setMinutes,
  setHours,
} from "date-fns";
import { BACKEND_ZOOM } from "../../services/googleCalendarService";
import {
  convertISOSlotsArrayToJSDate,
  ACCEPTED,
} from "./schedulingSharedVariables";

const EXAMPLE_SLOT_0 = {
  start: startOfHour(new Date()).toISOString(),
  // end: addHours(startOfHour(new Date()), 2).toISOString(),
  end: addMinutes(startOfHour(new Date()), 30).toISOString(),
};
const EXAMPLE_SLOT_1 = {
  start: startOfMinute(
    setMinutes(addHours(addDays(new Date(), 2), 2), 15)
  ).toISOString(),
  end: startOfMinute(
    setMinutes(addHours(addDays(new Date(), 2), 5), 45)
  ).toISOString(),
};
const EXAMPLE_SLOT_2 = {
  start: startOfHour(setHours(addDays(new Date(), 1), 12)).toISOString(),
  end: startOfHour(setHours(addDays(new Date(), 1), 16)).toISOString(),
};
const EXAMPLE_SLOT_3 = {
  start: startOfHour(addHours(addDays(new Date(), 3), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 3), 3)).toISOString(),
};
const EXAMPLE_SLOT_4 = {
  start: startOfHour(addHours(addDays(new Date(), 4), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 4), 3)).toISOString(),
};
const EXAMPLE_SLOT_5 = {
  start: startOfHour(addHours(addDays(new Date(), 5), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 5), 3)).toISOString(),
};
const EXAMPLE_SLOT_6 = {
  start: startOfHour(addHours(addDays(new Date(), 6), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 6), 3)).toISOString(),
};
const EXAMPLE_SLOT_7 = {
  start: startOfHour(addHours(addDays(new Date(), 7), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 7), 3)).toISOString(),
};
const EXAMPLE_SLOT_8 = {
  start: startOfHour(addHours(addDays(new Date(), 8), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 8), 3)).toISOString(),
};
const EXAMPLE_SLOT_9 = {
  start: startOfHour(addHours(addDays(new Date(), 9), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 9), 3)).toISOString(),
};
const EXAMPLE_SLOT_10 = {
  start: startOfHour(addHours(addDays(new Date(), 10), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 10), 3)).toISOString(),
};
const EXAMPLE_SLOT_11 = {
  start: startOfHour(addHours(addDays(new Date(), 11), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 11), 3)).toISOString(),
};
const EXAMPLE_SLOT_12 = {
  start: startOfHour(addHours(addDays(new Date(), 12), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 12), 3)).toISOString(),
};
const EXAMPLE_SLOT_13 = {
  start: startOfHour(addHours(addDays(new Date(), 13), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 13), 3)).toISOString(),
};
const EXAMPLE_SLOT_14 = {
  start: startOfHour(addHours(addDays(new Date(), 14), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 14), 3)).toISOString(),
};
const EXAMPLE_SLOT_15 = {
  start: startOfHour(addHours(addDays(new Date(), 15), 2)).toISOString(),
  end: startOfHour(addHours(addDays(new Date(), 15), 3)).toISOString(),
};
const EXAMPLE_SLOT_16 = {
  start: startOfHour(addHours(setDay(new Date(), 16), 2)).toISOString(),
  end: startOfHour(addHours(setDay(new Date(), 16), 3)).toISOString(),
};
const EXAMPLE_SLOT_ALL_DAY_1 = {
  startDate: "2021-11-19",
  endDate: "2021-11-19",
};
const EXAMPLE_SLOT_ALL_DAY_2 = {
  startDate: "2021-11-18",
  endDate: "2021-11-18",
};
const EXAMPLE_SLOT_ALL_DAY_3 = {
  startDate: "2021-11-20",
  endDate: "2021-11-20",
};


const EXAMPLE_SELECTED_SLOTS = [
  EXAMPLE_SLOT_ALL_DAY_1,
  EXAMPLE_SLOT_ALL_DAY_2,
  EXAMPLE_SLOT_1,
  EXAMPLE_SLOT_ALL_DAY_3
  // EXAMPLE_SLOT_1,
  // EXAMPLE_SLOT_2,
  // EXAMPLE_SLOT_3,
  // EXAMPLE_SLOT_4,
  // EXAMPLE_SLOT_5,
  // EXAMPLE_SLOT_6,
  // EXAMPLE_SLOT_7,
  // EXAMPLE_SLOT_8,
  // EXAMPLE_SLOT_9,
  // EXAMPLE_SLOT_10,
  // EXAMPLE_SLOT_11,
  // EXAMPLE_SLOT_12,
  // EXAMPLE_SLOT_13,
  // EXAMPLE_SLOT_14,
  // EXAMPLE_SLOT_15,
  // EXAMPLE_SLOT_16,
];

const EXAMPLE_DURATION = 35;

function formatTestSlots(exampleSlot, timeZone) {
  return convertISOSlotsArrayToJSDate([exampleSlot], timeZone).map((s) => {
    return {
      start: s.eventStart.toISOString(),
      end: s.eventEnd.toISOString(),
      responseType: ACCEPTED,
    };
  });
}

const EXAMPLE_TIME_ZONE = "America/Los_Angeles";
export const EXAMPLE_BOOKING_LINK = {
  title: "Fun time",
  // duration: EXAMPLE_DURATION, // in minutes
  description: "lets have some fun",
  conferencing: BACKEND_ZOOM,
  calendar_provider_id: "mchlzhao@gmail.com", // can ignore this for google_calendar_id
  location: "bounce house NYC",
  // token: generateAvailabilityToken({email: "mike@vimcal.com", full_name: "Michael Zhao", first_name: "Michael", last_name: "Zhao"}),
  token: "example_group_vote_token_12345",
  selected_slots: EXAMPLE_SELECTED_SLOTS,
  time_zone: EXAMPLE_TIME_ZONE,
  anonymous: false,
  attendees: [
    {
      name: "Michael Jordan",
      email: "michael.jordan@goat.com",
      slots: [EXAMPLE_SLOT_ALL_DAY_1],
    },
    {
      name: "Kobe Bryant",
      email: "kobe.bryant@goat.com",
      slots: [EXAMPLE_SLOT_1],
    },
    {
      name: "Jeremy Lin",
      email: "jeremy.link@goat.com",
      slots: formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE)
        .concat(formatTestSlots(EXAMPLE_SLOT_4, EXAMPLE_TIME_ZONE))
        .concat(formatTestSlots(EXAMPLE_SLOT_5, EXAMPLE_TIME_ZONE))
        .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    },

    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
    // {
    //   name: "Kobe Bryant_last",
    //   email: "kobe.bryant@goat.com",
    //   slots: formatTestSlots(EXAMPLE_SLOT_1, EXAMPLE_TIME_ZONE)
    //     .concat(formatTestSlots(EXAMPLE_SLOT_2, EXAMPLE_TIME_ZONE))
    //     .concat(formatTestSlots(EXAMPLE_SLOT_3, EXAMPLE_TIME_ZONE)),
    // },
  ],
};
export const EXAMPLE_UPDATED_SLOT = {
  name: "Updated_1",
  email: "kobe.bryant@goat.com",
  slots: formatTestSlots(EXAMPLE_SLOT_5, EXAMPLE_TIME_ZONE)
    .concat(formatTestSlots(EXAMPLE_SLOT_10, EXAMPLE_TIME_ZONE))
    .concat(formatTestSlots(EXAMPLE_SLOT_11, EXAMPLE_TIME_ZONE)),
};
