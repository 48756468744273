import React from "react";
import Broadcast from "../broadcasts/broadcast";
import { connect } from "react-redux";
import { format, isSameDay } from "date-fns";
import Classnames from "classnames";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";

const CustomMonthlyDateHeader = ({ date, selectDay }) => {
  function determineMonthDateStyle(date) {
    if (isSameDay(new Date(), date)) {
      return "agenda-regular-blue-background-color";
    } else {
      return "month-date-header-hover duration-200";
    }
  }

  return (
    <div
      className="month-date-header-container"
      onMouseEnter={() =>
        Broadcast.publish("ON_MOUSE_OVER_MONTH_DATE", date, true)
      }
      onMouseLeave={() =>
        Broadcast.publish("ON_MOUSE_OVER_MONTH_DATE", new Date(), false)
      }
    >
      <div
        className={Classnames(
          "month-date-header",
          determineMonthDateStyle(date)
        )}
        onClick={() => {
          selectDay(date);
          mainCalendarBroadcast.publish("SET_MAIN_CALENDAR_TO_DAY_VIEW", date);
        }}
      >
        {format(date, "d")}
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }) };
}

export default connect(null, mapDispatchToProps)(CustomMonthlyDateHeader);
