import { format } from "date-fns";
import {
  getEventAttendees,
  getEventCreatedAt,
  getEventEndJSDate,
  getEventMasterEventID,
  getEventStartJSDate,
  getEventTitle,
  getEventUniqueEtag,
} from "../../services/eventResourceAccessors";
import {
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
  isTypeNumber,
} from "../../services/typeGuards";
import {
  determineDateFormatString,
  formatDate,
  getDateTimeFormatLowercaseAMPM,
} from "../dateFunctions";
import { getEventDuration, isRecurringEvent } from "../eventFunctions";
import {
  getNumberOfOccurrencesOverXDays,
  getRecurrenceFrequencyForAudit,
} from "../rruleFunctions";
import {
  createAbbreviationForTimeZone,
  handleError,
  parseRRuleStringToHumanReadableText,
} from "../../services/commonUsefulFunctions";
import { getAccountCompletedToolTips, getUserName } from "../userFunctions";
import {
  AUDIT_COLUMN_TYPES,
  AUDIT_COLUMNS_REQUIRING_Z_INDEX_CLASSNAME,
  CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP,
  CALENDAR_AUDIT_READY_MODAL_TOOLTIP,
  calendarAuditFrequencyOptions,
} from "./variables";
import {
  AuditColumn,
  EventToRender,
  isTypeOfGroupedRecurrence,
  MasterEventRecurrences,
  OnePagerData,
  ProposedChanges,
} from "./accessors";
import { isOutlookUser } from "../outlookFunctions";
import { MasterAccountState } from "../../services/stores/SharedAccountData";

export function calculateMinutesSavedForEvent({
  event,
  masterEventRecurrences,
  numberOfDays,
  proposedChanges,
}: {
  event: VimcalEvent;
  masterEventRecurrences: MasterEventRecurrences;
  numberOfDays: number;
  proposedChanges: ProposedChanges;
}) {
  const uniqueEtag = getEventUniqueEtag(event);

  /* No changes */
  if (isEmptyObjectOrFalsey(proposedChanges[uniqueEtag])) {
    return 0;
  }

  const eventDuration = getEventDuration(event);
  const proposedDuration =
    proposedChanges[uniqueEtag].duration ?? eventDuration;

  if (isRecurringEvent(event)) {
    const eventRecurrence =
      masterEventRecurrences[getEventMasterEventID(event)];

    /* Prevent mismatched RRule strings from showing diff numbers due to options */
    const isMatchingLabel =
      proposedChanges[uniqueEtag].frequencyLabel ===
      getRecurrenceFrequencyForAudit(eventRecurrence);

    /* Current time over X days */
    const currentFrequency = getNumberOfOccurrencesOverXDays({
      numberOfDays,
      rruleString: eventRecurrence,
      startDate: getEventStartJSDate(event),
    });
    const timeOverXDays = currentFrequency * eventDuration;

    /* Proposed time over X days */
    const proposedFrequency = isMatchingLabel
      ? currentFrequency
      : getNumberOfOccurrencesOverXDays({
        numberOfDays,
        rruleString: proposedChanges[uniqueEtag].frequency ?? eventRecurrence,
        startDate: getEventStartJSDate(event),
      });
    const proposedTimeOverXDays = proposedDuration * proposedFrequency;

    return Math.round(timeOverXDays - proposedTimeOverXDays);
  } else {
    return eventDuration - proposedDuration;
  }
}

export function getEventProposedChanges(
  { event, proposedChanges }:
  { event: VimcalEvent, proposedChanges: ProposedChanges },
) {
  if (isEmptyObjectOrFalsey(event)) {
    return {
      duration: "",
      frequency: null,
    };
  }

  const uniqueEtag = getEventUniqueEtag(event);
  const proposedChangesForEvent = proposedChanges[uniqueEtag];
  if (isEmptyObjectOrFalsey(proposedChangesForEvent)) {
    return {
      duration: "", // Show nothing
      frequency: null, // Use default option
    };
  }

  return {
    duration: proposedChangesForEvent.duration ?? "",
    frequency: proposedChangesForEvent.frequency ?? null,
  };
}

export function minutesToHoursRoundedOneDecimal(minutes) {
  if (!isTypeNumber(minutes)) {
    return 0;
  }

  return Math.round((minutes / 60) * 10) / 10;
}

export function formatAuditEventMeetingTimeColumn({
  dateFieldOrder,
  event,
  format24HourTime,
  rruleString,
}) {
  try {
    if (isEmptyObjectOrFalsey(event)) {
      return "";
    }
    const eventStart = getEventStartJSDate(event);
    const eventEnd = getEventEndJSDate(event);
    if (isRecurringEvent(event)) {
      return `${format(
        eventStart,
        getDateTimeFormatLowercaseAMPM(format24HourTime),
      )}-${format(eventEnd, getDateTimeFormatLowercaseAMPM(format24HourTime))}${
        rruleString
          ? `, ${parseRRuleStringToHumanReadableText(rruleString)}`
          : ""
      }`;
    }

    return `${format(
      eventStart,
      getDateTimeFormatLowercaseAMPM(format24HourTime),
    )}-${format(
      eventEnd,
      getDateTimeFormatLowercaseAMPM(format24HourTime),
    )}, ${format(eventStart, "EEE")}, ${format(eventStart, determineDateFormatString(dateFieldOrder))}`;
  } catch (error) {
    handleError(error);
    return "";
  }
}

export function formatAuditCreatedAt({ dateFieldOrder, event }) {
  try {
    if (isEmptyObjectOrFalsey(event)) {
      return "";
    }

    return formatDate(new Date(getEventCreatedAt(event)), dateFieldOrder);
  } catch (error) {
    handleError(error);
    return "";
  }
}

export function constructOnePagerData({
  endDate,
  eventsToRender,
  groupedRecurrences,
  masterAccount,
  matchingUser,
  masterEventRecurrences,
  proposedChanges,
  startDate,
}: {
  endDate: Date;
  eventsToRender: EventToRender[];
  groupedRecurrences: Record<string, VimcalEvent[]>;
  masterAccount: MasterAccountState["masterAccount"];
  matchingUser: User;
  masterEventRecurrences: MasterEventRecurrences;
  proposedChanges: ProposedChanges;
  startDate: Date;
}) {
  const data = {
    name: getUserName({ masterAccount, user: matchingUser })?.fullName,
    startDate,
    endDate,
  };
  let totalMinsSaved90Days = 0;

  data["proposedChanges"] = eventsToRender
    .map((eventOrGroupedRecurrence) => {
      const masterEventID = isTypeOfGroupedRecurrence(eventOrGroupedRecurrence)
        ? eventOrGroupedRecurrence.masterEventID
        : null;
      const occurrences = masterEventID
        ? groupedRecurrences[masterEventID]?.sort((eventA, eventB) =>
          getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1,
        )
        : [{} as VimcalEvent];
      const event: VimcalEvent = masterEventID
        ? occurrences[0]
        : (eventOrGroupedRecurrence as VimcalEvent);
      const eventUniqueEtag = getEventUniqueEtag(event);

      if (
        !eventUniqueEtag ||
        isEmptyObjectOrFalsey(proposedChanges[eventUniqueEtag])
      ) {
        return null;
      }

      const isRecurring = isRecurringEvent(event);
      const minsSavedOver90Days =
        calculateMinutesSavedForEvent({
          event,
          masterEventRecurrences,
          numberOfDays: 90,
          proposedChanges,
        });
      /* Only adjust recurring time saved */
      const proposedChangesForEvent = getEventProposedChanges({
        event,
        proposedChanges,
      });
      const originalFrequency = getRecurrenceFrequencyForAudit(
        masterEventRecurrences[getEventMasterEventID(event)],
      );
      const frequencyLabel = isRecurring
        ? calendarAuditFrequencyOptions.find(
          (option) =>
            option.value === (proposedChangesForEvent.frequency ?? null),
        )?.label
        : null;
      totalMinsSaved90Days += minsSavedOver90Days;

      return {
        title: getEventTitle(event),
        eventStart: getEventStartJSDate(event),
        eventEnd: getEventEndJSDate(event),
        attendeeEmails: getEventAttendees(event)?.map(
          (attendee) => attendee.email,
        ),
        originalDurationMins: getEventDuration(event),
        proposedDurationMins: proposedChangesForEvent.duration || getEventDuration(event),
        originalFrequency: isRecurring ? originalFrequency : null,
        proposedFrequency:
          frequencyLabel === "--" ? originalFrequency : frequencyLabel,
        minsSavedOver90Days,
      };
    })
    .filter((eventData) => !isNullOrUndefined(eventData));
  data["minsSaved90Days"] = totalMinsSaved90Days;

  return data as OnePagerData;
}

export function constructAuditColumns({ user }) {
  const colorColumnName = isOutlookUser(user) ? "Categories" : "Color";

  return [
    {
      hideable: false,
      hidden: false,
      label: "Title",
      resizable: true,
      type: AUDIT_COLUMN_TYPES.TITLE,
    },
    {
      hideable: true,
      hidden: false,
      label: `Meeting time (${createAbbreviationForTimeZone()})`,
      resizable: true,
      type: AUDIT_COLUMN_TYPES.MEETING_TIME,
    },
    {
      hideable: true,
      hidden: false,
      label: "Attendees",
      resizable: true,
      type: AUDIT_COLUMN_TYPES.ATTENDEES,
    },
    {
      hideable: true,
      hidden: false,
      label: "Internal / External",
      resizable: true,
      type: AUDIT_COLUMN_TYPES.INTERNAL_EXTERNAL,
    },
    {
      hideable: true,
      hidden: false,
      label: colorColumnName,
      resizable: true,
      type: AUDIT_COLUMN_TYPES.COLOR,
    },
    {
      hideable: true,
      hidden: false,
      label: "Tags",
      resizable: true,
      type: AUDIT_COLUMN_TYPES.TAGS,
    },
    {
      hideable: true,
      hidden: false,
      label: "Created",
      resizable: true,
      type: AUDIT_COLUMN_TYPES.CREATED,
    },
    {
      hideable: false,
      hidden: false,
      label: "Current",
      resizable: false,
      type: AUDIT_COLUMN_TYPES.CURRENT_DURATION,
    },
    {
      hideable: false,
      hidden: false,
      label: "Proposed",
      resizable: false,
      type: AUDIT_COLUMN_TYPES.PROPOSED_DURATION,
    },
    {
      hideable: false,
      hidden: false,
      label: "Current",
      resizable: false,
      type: AUDIT_COLUMN_TYPES.CURRENT_FREQUENCY,
    },
    {
      hideable: false,
      hidden: false,
      label: "Proposed",
      resizable: false,
      type: AUDIT_COLUMN_TYPES.PROPOSED_FREQUENCY,
    },
  ] as AuditColumn[];
}

export function doesAuditColumnRequireZIndex(columnType: string) {
  return AUDIT_COLUMNS_REQUIRING_Z_INDEX_CLASSNAME.includes(columnType);
}

export function minToDaysHoursMins(inputMinutes: number) {
  const absoluteMinutes = Math.abs(inputMinutes);

  let remainingTime = absoluteMinutes;

  const days = Math.floor(absoluteMinutes / 1440);
  remainingTime -= Math.floor(days * 1440);

  const hours = Math.floor(remainingTime / 60);
  remainingTime -= Math.floor(hours * 60);

  const minutes = remainingTime;

  return {
    days,
    hours,
    minutes,
  };
}

// TODO: Add more sort keys and functions
export const CALENDAR_AUDIT_SORT_BY_KEYS = {
  MEETING_TIME_DESCENDING: "MEETING_TIME_DESCENDING",
} as const;

export const CALENDAR_AUDIT_SORT_BY = {
  MEETING_TIME_DESCENDING: ({
    filteredEvents,
    groupedRecurrences,
  }: {
    filteredEvents: EventToRender[];
    groupedRecurrences: Record<string, VimcalEvent[]>;
  }) => {
    return filteredEvents.sort((a, b) => {
      /* Get event A */
      const masterEventIDForA = isTypeOfGroupedRecurrence(a)
        ? a.masterEventID
        : null;
      const occurrencesA = masterEventIDForA
        ? groupedRecurrences[masterEventIDForA]?.sort((eventA, eventB) =>
          getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1,
        )
        : null;
      const outerEventA = masterEventIDForA ? occurrencesA?.[0] : a;

      /* Get event B */
      const masterEventIDForB = isTypeOfGroupedRecurrence(b)
        ? b.masterEventID
        : null;
      const occurrencesB = masterEventIDForB
        ? groupedRecurrences[masterEventIDForB]?.sort((eventA, eventB) =>
          getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1,
        )
        : null;
      const outerEventB = masterEventIDForB ? occurrencesB?.[0] : b;

      return getEventStartJSDate(outerEventA) > getEventStartJSDate(outerEventB)
        ? -1
        : 1;
    });
  },
} as const;

export function shouldDisplayCalendarAuditIntro(masterAccount) {
  const completedToolTips = getAccountCompletedToolTips(masterAccount);

  return !completedToolTips?.includes(CALENDAR_AUDIT_INTRO_MODAL_TOOLTIP);
}

export function shouldDisplayCalendarAuditReady(masterAccount) {
  const completedToolTips = getAccountCompletedToolTips(masterAccount);

  return !completedToolTips?.includes(CALENDAR_AUDIT_READY_MODAL_TOOLTIP);
}

export function fillArrayToXElements(
  { array, count }:
  { array: EventToRender[], count: number },
) {
  if (isEmptyObjectOrFalsey(array)) {
    return Array.from(Array(count).keys());
  }

  /* Array meets or exceeds count requirements */
  /* For used scenario, exceeding does not matter so no need to slice */
  if (array.length >= count) {
    return array;
  }

  return [
    ...array,
    ...(Array.from(Array(count - array.length).keys())),
  ];
}
