import React, { useMemo } from "react";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import broadcast from "../../broadcasts/broadcast";
import ProfilePictureThroughURL from "../profilePictureThroughURL";
import { getUserEmail, getUserProfilePhotoUrl } from "../../lib/userFunctions";
import { useSelector } from "react-redux";
import { Check, X } from "react-feather";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import classNames from "classnames";
import { isSameEmail } from "../../lib/stringFunctions";
import { groupByMyAccountAndExecs } from "../../services/maestroFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";

export default function MaestroPerAccountSection({ onClose }) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const { myAccounts, execAccounts } = useMemo(
    () => groupByMyAccountAndExecs({ allLoggedInUsers }),
    []
  );

  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return null;
  }

  return (
    <div>
      <MyAccounts
        execAccounts={execAccounts}
        myAccounts={myAccounts}
        onClose={onClose}
      />
      <ExecAccounts execAccounts={execAccounts} onClose={onClose} />
    </div>
  );
}

function MyAccounts({ myAccounts, execAccounts, onClose }) {
  return (
    <div className="mt-2">
      {isEmptyArrayOrFalsey(execAccounts) ? null : (
        <div className="mb-1">My accounts</div>
      )}
      {myAccounts.map((user) => (
        <div key={`my-accounts-${getUserEmail(user)}`}>
          <User user={user} onClose={onClose} />
        </div>
      ))}
    </div>
  );
}

function ExecAccounts({ execAccounts, onClose }) {
  if (isEmptyArrayOrFalsey(execAccounts)) {
    return null;
  }
  return (
    <div className="mt-4">
      <div className="mb-1">Executive accounts</div>
      {execAccounts.map((user) => (
        <div key={`exec-accounts-${getUserEmail(user)}`}>
          <User user={user} onClose={onClose} />
        </div>
      ))}
    </div>
  );
}

function User({ user, onClose }) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  return (
    <div
      className={classNames(
        "account-email-hover", // hover
        "flex items-center cursor-pointer",
        "rounded-md",
        "py-2",
        "justify-between",
        "hoverable-container",
        "hoverable-secondary-text-color"
      )}
      onClick={() => {
        onClose();
        broadcast.publish("SWITCH_ACCOUNTS", { account: user });
      }}
      style={{
        marginLeft: "-12px",
        marginRight: "-12px",
        paddingLeft: "12px",
      }}
    >
      <div className="flex items-center gap-2">
        <ProfilePictureThroughURL
          avatarUrl={getUserProfilePhotoUrl({user, masterAccount})}
          email={getUserEmail(user)}
          isSmall={true}
        />
        <div className="truncate-text max-width-200px font-weight-300 default-font-size select-none">
          {getUserEmail(user)}
        </div>
        {isSameEmail(getUserEmail(user), getUserEmail(currentUser)) ? (
          <Check
            className="non-clickable-icon-modal mr-3"
            size="14"
            strokeWidth={3}
          />
        ) : null}
      </div>

      <div className={classNames("default-font-size items-center flex")}>
        <XCloseAccount account={user} onClose={onClose} />
      </div>
    </div>
  );
}

function XCloseAccount({ account, onClose }) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const logoutOfAccount = ({ account, e }) => {
    backendBroadcasts.publish("LOGOUT_OF_SINGLE_ACCOUNT", { user: account, e });
  };
  return (
    <div className={classNames("reveal-on-hover", "mr-3")}>
      {allLoggedInUsers.length === 1 ? null : (
        <div
          className="flex items-center justify-center"
          onClick={(e) => {
            onClose();
            logoutOfAccount({ account, e });
          }}
        >
          <X className="hoverable-secondary-text-color" size={16} />
        </div>
      )}
    </div>
  );
}
