import React from "react";

export default function PurpleWarning() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.000305176C13.9707 0.000305176 18 4.02961 18 9.00031C18 13.971 13.9707 18.0003 9 18.0003C4.0293 18.0003 0 13.971 0 9.00031C0 4.02961 4.0293 0.000305176 9 0.000305176ZM9 1.80031C7.09044 1.80031 5.25909 2.55887 3.90883 3.90914C2.55857 5.2594 1.8 7.09075 1.8 9.00031C1.8 10.9099 2.55857 12.7412 3.90883 14.0915C5.25909 15.4417 7.09044 16.2003 9 16.2003C10.9096 16.2003 12.7409 15.4417 14.0912 14.0915C15.4414 12.7412 16.2 10.9099 16.2 9.00031C16.2 7.09075 15.4414 5.2594 14.0912 3.90914C12.7409 2.55887 10.9096 1.80031 9 1.80031ZM9 11.7003C9.23869 11.7003 9.46761 11.7951 9.6364 11.9639C9.80518 12.1327 9.9 12.3616 9.9 12.6003C9.9 12.839 9.80518 13.0679 9.6364 13.2367C9.46761 13.4055 9.23869 13.5003 9 13.5003C8.7613 13.5003 8.53239 13.4055 8.3636 13.2367C8.19482 13.0679 8.1 12.839 8.1 12.6003C8.1 12.3616 8.19482 12.1327 8.3636 11.9639C8.53239 11.7951 8.7613 11.7003 9 11.7003ZM9 3.60031C9.23869 3.60031 9.46761 3.69513 9.6364 3.86391C9.80518 4.03269 9.9 4.26161 9.9 4.50031V9.9003C9.9 10.139 9.80518 10.3679 9.6364 10.5367C9.46761 10.7055 9.23869 10.8003 9 10.8003C8.7613 10.8003 8.53239 10.7055 8.3636 10.5367C8.19482 10.3679 8.1 10.139 8.1 9.9003V4.50031C8.1 4.26161 8.19482 4.03269 8.3636 3.86391C8.53239 3.69513 8.7613 3.60031 9 3.60031Z"
        fill="#BB7CDA"
      />
    </svg>
  );
}
