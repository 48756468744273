export function clampValueBetween0And100(value, startClamp, endClamp) {
  if (!value) {
    return startClamp || 0;
  }
  return Math.min(endClamp || 100, Math.max(startClamp || 0, value));
}

export function isZero(value) {
  return typeof value === "number" && value === 0;
}
