import React, { useState } from "react";
import { useTeamPlan } from "../../../services/stores/userData";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import SpinnerV2 from "../../spinnerV2";
import { useGetBillingInfo } from "../../teamPlans/hooks";
import IndividualTrialFinished from "./individualTrialFinished";
import TeamTrialFinished from "./teamTrialFinished";

interface TrialFinishedProps {
  freeTrialIsOver?: boolean
  isInModal?: boolean
}

export default function TrialFinished(props: TrialFinishedProps) {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  // While the edit plan content is open, do not switch back to the individual trial finished component.
  // Otherwise there is an awkward transition when you remove the last member from your team plan.
  const [showEditPlanContent, setShowEditPlanContent] = useState(false);

  const hasBillingBeenFetched = useGetBillingInfo();

  if (!hasBillingBeenFetched) {
    return (
      <div
        className="trial-over-background-gradient width-500px p-8 flex items-center justify-center"
        style={{ height: 300 }}
      >
        <SpinnerV2 />
      </div>
    );
  } else if (
    !showEditPlanContent &&
    (isEmptyObjectOrFalsey(teamPlan) || (teamPlan.ea_seat_count + teamPlan.non_ea_seat_count === 1))
  ) {
    // If the user is not on a team plan, or if the user's team plan only has a single seat,
    // they should see the individual subscription modal.
    return <IndividualTrialFinished {...props} />;
  } else {
    // If the user is on a team plan with at least 2 people, they should see
    // the team plan subscription modal.
    return (
      <TeamTrialFinished
        {...props}
        setShowEditPlanContent={setShowEditPlanContent}
        showEditPlanContent={showEditPlanContent}
      />
    );
  }
}
