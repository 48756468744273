import React, { Component } from "react";
import { connect } from "react-redux";
import { Edit } from "react-feather";
import ShortcutHoverHint from "../shortcutHoverHint";
import GlobalKeyMapTile from "../globalKeyMapTile";
import expandedEventViewBroadcast from "../../broadcasts/expandedEventViewBroadcast";
import { EXPANDED_VIEW_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { isActionModeCreateAvailability } from "../../services/appFunctions";

let editButtonShortCut = { marginLeft: "-79px", marginTop: "-9px" };

let editButtonTile = {
  left: "-9px",
  top: "-19px",
};

class EditButton extends Component {
  render() {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      return null;
    }

    return (
      <ShortcutHoverHint
        left
        style={editButtonShortCut}
        title={"Edit"}
        shortcut={"E"}
        containerStyle={this.props.containerStyle}
      >
        <GlobalKeyMapTile
          style={this.props.shortCutStyle || editButtonTile}
          shortcut={"E"}
        />

        <Edit
          size="15"
          className="clickable-icon"
          onClick={this.onClickEditButton.bind(this)}
        />
      </ShortcutHoverHint>
    );
  }

  onClickEditButton() {
    expandedEventViewBroadcast.publish(
      EXPANDED_VIEW_BROADCAST_VALUES.EDIT_EXPANDED_EVENT
    );
  }
}

function mapStateToProps(state) {
  const { actionMode } = state;

  return { actionMode };
}

export default connect(mapStateToProps, null)(EditButton);
