import React from "react";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { APP_SETTINGS } from "../../lib/vimcalVariables";

export default function GoToTagsButton() {
  return (
    <CustomButton
      buttonType={BLUE_BUTTON}
      onClick={onClickGoToTags}
      label="Go to Tags"
      className="mt-4"
    />
  );
}

function onClickGoToTags() {
  layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
    initialContent: APP_SETTINGS.PAINTER_SETINGS,
  });
}
