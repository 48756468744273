import {
  endOfMonth,
  endOfWeek,
  parseISO,
  startOfMonth,
  subWeeks,
} from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import InternalExternalBox from "./internalExternalBox";
import {
  METRICS_PERIOD,
  METRICS_SAMPLE_DATA,
  getLastMonthNumber,
  getLastPeriodIndexAndYearForWeek,
  getMetricsResponse,
  getMetricsWeekNumber,
  getMonthNumberAndYear,
  getTwoWeeksAgoMonday,
  isMetricsReady,
  isMetricsReportStillCalculating,
  updateMetricsCalendars,
} from "./metricsAccessorFunctions";
import MetricsBreakDown from "./metricsBreakDown";
import MetricsTopLevel from "./metricsTopLevel";
import SwissCheese from "./swissCheese";
import { useSelector } from "react-redux";
import { handleError } from "../../services/commonUsefulFunctions";
import LoadingState from "./loadingState";
import SelectBlockedCalendars from "../availability/selectBlockedCalendars";
import { X } from "react-feather";
import DateHeaderSelection from "./dateHeaderSelection";
import classNames from "classnames";
import { isEmptyArray } from "../../lib/arrayFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useMetricsStore } from "../../services/stores/metricsStore";
import {
  isUserDelegatedUser,
  isUserExecutiveUser,
  isUserMaestroUser,
} from "../../services/maestroFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import {
  getMondayNumberOfTheYear,
  getMondayOfDate,
} from "../../lib/dateFunctions";
import HoverableScrollContainer from "../hoverableScrollContainer";
import ProgressInYear from "./progressInYear";
import modalBroadcast from "../../broadcasts/modalBroadcast";
import { trackFeatureUsage } from "../tracking";
import {
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../../services/typeGuards";
import DropdownIndicator from "../select/dropDownIndicator";
import { CustomSelect } from "../select";
import { getReactSelectBaseStyle } from "../select/styles";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import MetricsLineGraph from "./metricsLineGraph";
import {
  isShowingMonthlyMetrics,
  shouldShowMetricsLineGraph,
} from "../../lib/featureFlagFunctions";
import MetricsMetWith from "./metricsMetWith";
import {
  getAllUsers,
  SELECT_USER_TYPE,
  SelectUser,
} from "../settings/common/selectUser";
import { isSameEmail } from "../../lib/stringFunctions";
import { blurCalendar } from "../../services/appFunctions";

const SELECT_USER_ID = "select-metrics-user-id";

const PERIOD_OPTIONS = [
  { value: METRICS_PERIOD.WEEKLY, label: "Weekly" },
  { value: METRICS_PERIOD.MONTHLY, label: "Monthly" },
];
// const IS_DEBUG = true && !process.env.REACT_APP_CLIENT_ENV;
const IS_DEBUG = false;
export default function MetricsWrapper() {
  const [data, setData] = useState(IS_DEBUG ? METRICS_SAMPLE_DATA : null);
  const [previousData, setPreviousData] = useState(
    IS_DEBUG ? METRICS_SAMPLE_DATA : null
  );
  const [startDate, setStartDate] = useState(
    IS_DEBUG ? parseISO(METRICS_SAMPLE_DATA.from) : null
  );
  const [endDate, setEndDate] = useState(
    IS_DEBUG ? parseISO(METRICS_SAMPLE_DATA.to) : null
  );
  const [calendars, setCalendars] = useState(null);
  const currentUser = useSelector((state) => state.currentUser);
  const [shouldShowSelectCalendarModal, setShowSelectCalendarModal] =
    useState(false);
  const [shouldShowDateSelection, setShowDateSelection] = useState(false);
  const [currentlyOpenMonth, setMonth] = useState(null); // takes in jsDate
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const metricsData = useMetricsStore((state) => state.metricsData);
  const setMetricsData = useMetricsStore((state) => state.setMetricsData);
  const latestSuccessfullyFetchedWeek = useMetricsStore(
    (state) => state.latestSuccessfullyFetchedWeek
  );
  const resetMetricsData = useMetricsStore((state) => state.resetMetricsData);
  const setLatestSuccessfullyFetchedWeek = useMetricsStore(
    (state) => state.setLatestSuccessfullyFetchedWeek
  );
  const componentIsMounted = useIsMounted();
  const [showSkeletonScreen, setShowSkeletonScreen] = useState(false);
  const [period, setPeriod] = useState(METRICS_PERIOD.WEEKLY);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const allUsersForSelect = useMemo(
    () =>
      getAllUsers({
        currentUser,
        allLoggedInUsers,
        masterAccount,
        selectUserType: SELECT_USER_TYPE.EXECUTIVE_AND_MASTER_ACCOUNT,
      }),
    [currentUser, allLoggedInUsers, masterAccount]
  );

  const shouldShowUserDropdown = useMemo(
    () => isUserMaestroUser(masterAccount) && allUsersForSelect.length >= 2,
    [masterAccount, allLoggedInUsers, currentUser]
  );

  const getInitialUserIndex = () => {
    if (!shouldShowUserDropdown) {
      return 0;
    }
    if (isEmptyArray(allUsersForSelect)) {
      return 0;
    }

    const matchingIndex = allUsersForSelect.findIndex((user) =>
      isSameEmail(getUserEmail(user?.value), getUserEmail(currentUser))
    );
    if (matchingIndex >= 0) {
      return matchingIndex;
    }
    if (
      isUserDelegatedUser(allUsersForSelect[0]?.value) &&
      !isUserDelegatedUser(currentUser)
    ) {
      // toggle onto different user (e.g. personal) which might not match the stripe email of master account
      return allUsersForSelect.findIndex(
        (user) => !isUserDelegatedUser(user.value)
      );
    }
    return 0;
  };

  const [selectedUserIndex, setSelectedUserIndex] = useState(() =>
    getInitialUserIndex()
  );

  // TODO: handle if user selects month in the past -> then goes to weekly view
  const selectWeek = (startOfWeek) => {
    const inputDate = parseISO(startOfWeek);
    const weekNumber = getMondayNumberOfTheYear(inputDate);
    setStartDate(inputDate);
    setEndDate(endOfWeek(inputDate, { weekStartsOn: 1 }));
    setShowSkeletonScreen(true);
    getMetrics({
      user: getSelectedUser(),
      masterAccount,
      setData,
      setPreviousData,
      setStartDate,
      setEndDate,
      setCalendars,
      setMonth,
      cachedMetricsData: metricsData,
      latestSuccessfullyFetchedWeek,
      setLatestSuccessfullyFetchedWeek,
      setMetricsData,
      componentIsMounted,
      weekNumber,
      setShowSkeletonScreen,
      inputYear: inputDate.getFullYear(),
      byPassCache: true,
    });
  };

  // used when user is in monthly mode = ()
  const selectMonth = (startOfMonth) => {
    const { monthNumber, year } =
      getMonthNumberAndYear(startOfMonth);
    setStartDate(startOfMonth);
    setEndDate(endOfWeek(startOfMonth, { weekStartsOn: 1 }));
    setShowSkeletonScreen(true);
    getMetrics({
      user: getSelectedUser(),
      masterAccount,
      setData,
      setPreviousData,
      setStartDate,
      setEndDate,
      setCalendars,
      setMonth,
      cachedMetricsData: metricsData,
      latestSuccessfullyFetchedWeek,
      setLatestSuccessfullyFetchedWeek,
      setMetricsData,
      componentIsMounted,
      monthNumber,
      setShowSkeletonScreen,
      inputYear: year,
      period,
      byPassCache: true,
    });
  };

  useEffect(() => {
    getMetrics({
      user: getSelectedUser(),
      masterAccount,
      setData,
      setPreviousData,
      setStartDate,
      setEndDate,
      setCalendars,
      setMonth,
      cachedMetricsData: metricsData,
      latestSuccessfullyFetchedWeek,
      setLatestSuccessfullyFetchedWeek,
      setMetricsData,
      componentIsMounted,
      setShowSkeletonScreen,
    });
    modalBroadcast.publish("REMOVE_BOTTOM_MODAL_CONTENT");
    trackFeatureUsage({
      action: "metrics",
      userToken: getUserToken(getSelectedUser()),
    });
  }, []);

  const getBackupDates = () => {
    const referenceDate = subWeeks(new Date(), 1);
    const backupStartDate = getMondayOfDate(referenceDate);
    const backupEndDate = endOfWeek(referenceDate, { weekStartsOn: 1 });
    return {
      backupStartDate,
      backupEndDate,
    };
  };

  const getSelectedUser = () => {
    if (!shouldShowUserDropdown) {
      return currentUser;
    }
    const user = allUsersForSelect[selectedUserIndex]?.value || currentUser;
    return user;
  };

  const renderSelectUser = () => {
    if (!shouldShowUserDropdown) {
      return null;
    }
    const onSelectUserIndex = (index) => {
      setSelectedUserIndex(index);
      setShowSkeletonScreen(true);
      const user = allUsersForSelect[index]?.value || currentUser;
      getMetrics({
        user,
        masterAccount,
        setData,
        setPreviousData,
        setStartDate,
        setEndDate,
        setCalendars,
        setMonth,
        cachedMetricsData: metricsData,
        latestSuccessfullyFetchedWeek,
        setLatestSuccessfullyFetchedWeek,
        setMetricsData,
        componentIsMounted,
        setShowSkeletonScreen,
        byPassCache: true,
      });
      trackFeatureUsage({
        action: "updated metrics user",
        userToken: getUserToken(user),
      });
    };
    return (
      <SelectUser
        onMenuClose={() => blurCalendar()}
        setSelectedUserIndex={onSelectUserIndex}
        id={SELECT_USER_ID}
        selectedUser={getSelectedUser()}
        addExecutiveLabel={true}
        inputContainerClassName={"margin-bottom-0px-override"}
        selectUserType={SELECT_USER_TYPE.EXECUTIVE_AND_MASTER_ACCOUNT}
        addMeLabel={true}
      />
    );
  };

  const renderPeriodDropdown = () => {
    if (!isShowingMonthlyMetrics()) {
      return null;
    }
    return (
      <CustomSelect
        components={{ DropdownIndicator }}
        className={classNames(
          isDarkMode ? "dark-mode-select" : "light-mode-metrics-select"
        )}
        classNamePrefix="dark-mode"
        value={
          PERIOD_OPTIONS.find((option) => option.value === period) ??
          PERIOD_OPTIONS[0]
        }
        options={PERIOD_OPTIONS}
        onChange={(option) => {
          const updatedPeriod = option.value;
          setPeriod(updatedPeriod);
          blurCalendar();
          if (updatedPeriod === METRICS_PERIOD.MONTHLY) {
            setStartDate(startOfMonth(new Date()));
            setEndDate(endOfMonth(new Date()));
            const { monthNumber, year } = getLastMonthNumber();
            getMetrics({
              user: getSelectedUser(),
              masterAccount,
              setData,
              setPreviousData,
              setStartDate,
              setEndDate,
              setCalendars,
              setMonth,
              cachedMetricsData: metricsData,
              latestSuccessfullyFetchedWeek,
              setLatestSuccessfullyFetchedWeek,
              setMetricsData,
              componentIsMounted,
              setShowSkeletonScreen,
              period: updatedPeriod,
              monthNumber,
              inputYear: year,
            });
          } else {
            const { weekNumber, year } = getLastPeriodIndexAndYearForWeek();

            setStartDate(getMondayOfDate(new Date()));
            setEndDate(endOfWeek(new Date(), { weekStartsOn: 1 }));
            getMetrics({
              user: getSelectedUser(),
              masterAccount,
              setData,
              setPreviousData,
              setStartDate,
              setEndDate,
              setCalendars,
              setMonth,
              cachedMetricsData: metricsData,
              latestSuccessfullyFetchedWeek,
              setLatestSuccessfullyFetchedWeek,
              setMetricsData,
              componentIsMounted,
              setShowSkeletonScreen,
              period: updatedPeriod,
              inputYear: year,
              weekNumber,
            });
          }
        }}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          controlWidth: 100,
        })}
        alwaysShowIndicator={true}
      />
    );
  };

  const renderHeaderSelection = (backupStartDate, backupEndDate) => {
    return (
      <>
        {renderOverlay({
          shouldShowSelectCalendarModal,
          setShowSelectCalendarModal,
          shouldShowDateSelection,
          setShowDateSelection,
        })}
        <DateHeaderSelection
          setShowDateSelection={setShowDateSelection}
          shouldShowDateSelection={shouldShowDateSelection}
          startJSDate={startDate ?? backupStartDate}
          startDate={startDate}
          endJSDate={endDate ?? backupEndDate}
          currentlyOpenMonth={currentlyOpenMonth}
          selectWeek={selectWeek}
          selectMonth={selectMonth}
          setMonth={setMonth}
          period={period}
          shouldShowUserDropdown={shouldShowUserDropdown}
        />

        {renderSelectUser()}
      </>
    );
  };

  if (!data) {
    const { backupStartDate, backupEndDate } = getBackupDates();
    return (
      <div>
        <div className="flex justify-end pr-10">
          <div className="flex items-center justify-between w-full pl-10">
            {renderDateRange({
              startJSDate: startDate ?? backupStartDate,
              endJSDate: endDate ?? backupEndDate,
              period,
            })}
            <div className="flex items-center gap-1">
              {renderPeriodDropdown()}
              {renderHeaderSelection(backupStartDate, backupEndDate)}
            </div>
          </div>
        </div>
        <div className="metrics-loading-container">
          <LoadingState />
        </div>
      </div>
    );
  }
  const getPeriod = () => {
    if (!isShowingMonthlyMetrics()) {
      return METRICS_PERIOD.WEEKLY;
    }
    return period || METRICS_PERIOD.WEEKLY;
  };

  return (
    <HoverableScrollContainer
      inputClassName={"max-h-full overflow-y-auto mr-1.5 metrics-wrapper"}
    >
      <div
        className={classNames(
          "flex flex-col bg-transparent w-full overflow-x-hidden px-10"
        )}
      >
        <div className="flex justify-between">
          {renderDateRange({
            startJSDate: startDate,
            endJSDate: endDate,
            period,
          })}
          <div className="flex items-center gap-2">
            {renderPeriodDropdown()}
            {renderHeaderSelection()}
          </div>
        </div>
        <ProgressInYear
          endJSDate={endDate}
          showSkeletonScreen={showSkeletonScreen}
        />
        <div className="mt-12 font-weight-300 font-size-16">Meetings</div>
        <div className="mt-2 default-font-size secondary-text-color">
          Breakdown of time in meetings with other people.
        </div>
        <MetricsTopLevel
          data={data}
          previousData={previousData}
          showSkeletonScreen={showSkeletonScreen}
        />
        {shouldShowMetricsLineGraph(getSelectedUser()) ? (
          <MetricsLineGraph
            period={getPeriod()}
            startDate={startDate}
            showSkeletonScreen={showSkeletonScreen}
            user={getSelectedUser()}
          />
        ) : null}

        <div className="mt-5 flex items-center w-full justify-between">
          <InternalExternalBox
            data={data}
            previousData={previousData}
            showSkeletonScreen={showSkeletonScreen}
          />
          <SwissCheese
            data={data}
            previousData={previousData}
            showSkeletonScreen={showSkeletonScreen}
          />
        </div>

        <div className="mt-12 font-weight-300 font-size-16">
          All calendar events
        </div>
        <div className="mt-2 default-font-size secondary-text-color">
          Breakdown of all calendar events, including personal times and meeting
          time.
        </div>
        <MetricsBreakDown data={data} showSkeletonScreen={showSkeletonScreen} />
        {shouldShowMetricsLineGraph(getSelectedUser()) ? (
          <MetricsMetWith data={data} showSkeletonScreen={showSkeletonScreen} />
        ) : null}

        {renderSelectCalendar({
          calendars: calendars?.filter((c) => c.metrics_enabled),
          shouldShowSelectCalendarModal,
          setShowSelectCalendarModal,
          user: getSelectedUser(),
          resetMetricsData,
          setData,
          setStartDate,
          setEndDate,
        })}
      </div>
    </HoverableScrollContainer>
  );
}

function renderSelectCalendar({
  calendars,
  shouldShowSelectCalendarModal,
  setShowSelectCalendarModal,
  user,
  resetMetricsData,
  setData,
  setStartDate,
  setEndDate,
}) {
  if (isEmptyArray(calendars)) {
    return <div className="h-4"></div>;
  }

  function humanReadableEmailString() {
    const allNames = calendars
      .map((calendar) => {
        return calendar?.name;
      })
      .filter((email) => email);

    const count = allNames.length;
    if (count === 0) {
      return "";
    }
    if (count === 1) {
      return allNames[0];
    }
    if (count === 2) {
      return `${allNames[0]} and ${allNames[1]}`;
    }
    return `${allNames[0]}, ${allNames[1]}, and ${count - 2} more`;
  }

  const onClickExitModal = () => {
    setShowSelectCalendarModal(false);
  };

  const onUpdateCalendars = async (updatedUserCalendarIDs) => {
    onClickExitModal();
    resetMetricsData();
    setData(null);
    setStartDate(null);
    setEndDate(null);
    await updateMetricsCalendars({
      userCalendarIDs: updatedUserCalendarIDs,
      user,
    });
  };

  const renderModal = () => {
    if (!shouldShowSelectCalendarModal) {
      return null;
    }
    const blockedUserCalendarIDs = calendars?.map(
      (calendar) => calendar.user_calendar_id
    );
    return (
      <div
        className={classNames(
          "absolute top right-40 bottom-16 metrics-select-calendar-modal z-30 p-4 rounded-lg",
          "w-96"
        )}
      >
        <SelectBlockedCalendars
          onClickSave={(updatedUserCalendarIDs) => {
            onUpdateCalendars(updatedUserCalendarIDs);
          }}
          topDescription="Select calendars to include in metrics"
          onClickCancel={onClickExitModal}
          blockedCalendars={null}
          blockedCalendarsID={blockedUserCalendarIDs}
          selectedUser={user}
          updateLabel={"Update"}
          isInDarkMode={true}
          inputCalendars={calendars}
        />
        <X
          className="clickable-icon absolute right-4 top-4"
          size={16}
          onClick={onClickExitModal}
        />
      </div>
    );
  };

  const renderChange = () => {
    if (isUserExecutiveUser({ user })) {
      return null;
    }
    return (
      <div
        className="ml-2 underline hoverable-secondary-text-color duration-200 select-none"
        onClick={() => setShowSelectCalendarModal(true)}
      >
        Change
      </div>
    );
  };

  return (
    <div className="flex secondary-text-color default-font-size w-full items-center my-8 justify-center">
      Included calendars:
      {renderModal()}
      <div className="ml-2 truncate-text metrics-calendar-line">
        {humanReadableEmailString()}
      </div>
      {renderChange()}
    </div>
  );
}

function renderDateRange({ startJSDate, endJSDate, period }) {
  if (!startJSDate || !endJSDate) {
    return null;
  }

  const getHeader = () => {
    switch (period) {
      case METRICS_PERIOD.WEEKLY:
        return "Weekly Metrics";
      case METRICS_PERIOD.MONTHLY:
        return "Monthly Metrics";
      default:
        return "Weekly Metrics";
    }
  };

  return (
    <div className="flex items-center font-size-18 font-weight-500">
      <div className="metrics-primary-text-color">{getHeader()}</div>
    </div>
  );
}

async function getMetrics({
  user,
  masterAccount,
  setData,
  setPreviousData,
  setStartDate,
  setEndDate,
  setCalendars,
  setMonth,
  cachedMetricsData, // cached data
  latestSuccessfullyFetchedWeek,
  setLatestSuccessfullyFetchedWeek,
  setMetricsData,
  componentIsMounted,
  weekNumber,
  setShowSkeletonScreen,
  isRetry, // so we don't loop and keep retrying
  inputYear,
  period, // weekly or monthly
  monthNumber,
  byPassCache,
}) {
  const BY_PASS_CACHE = false;
  if (isEmptyObjectOrFalsey(user)) {
    return;
  }
  try {
    const getCurrentPeriodNumber = () => {
      if (period === METRICS_PERIOD.MONTHLY) {
        return getLastMonthNumber().monthNumber;
      }
      return getLastPeriodIndexAndYearForWeek().weekNumber;
    };
    const currentPeriodNumber = getCurrentPeriodNumber();

    const isSamePeriod = () => {
      if (period === METRICS_PERIOD.MONTHLY) {
        return false; // TODO: need to implement
      }
      return latestSuccessfullyFetchedWeek === currentPeriodNumber;
    };

    let response;
    if (
      !BY_PASS_CACHE &&
      !byPassCache &&
      isNullOrUndefined(weekNumber) &&
      !isUserDelegatedUser(user) &&
      isMetricsReady(cachedMetricsData) &&
      isSamePeriod &&
      (!period || cachedMetricsData?.period === period)
    ) {
      response = cachedMetricsData;
    } else {
      // no cached response -> fetch from server
      response = await getMetricsResponse({
        currentUser: user,
        masterAccount,
        weekNumber, // passing undefined will get current week's metrics
        year: inputYear, // by default, we don't need to pass anything in
        period: isShowingMonthlyMetrics() ? period : METRICS_PERIOD.WEEKLY,
        monthNumber,
      });
      if (!componentIsMounted?.current) {
        return;
      }
      if (
        isNullOrUndefined(weekNumber) && // do not set if it's not last week. Only cache last week's results
        isMetricsReady(response) &&
        !isUserDelegatedUser(user) &&
        getMetricsWeekNumber(response) === currentPeriodNumber // only save current period
      ) {
        setMetricsData(response);
        setLatestSuccessfullyFetchedWeek(currentPeriodNumber);
      }
    }

    if (!isMetricsReady(response)) {
      if (isNullOrUndefined(weekNumber) && !isRetry) {
        // getting current week's metrics failed -> grab last week's
        const { weekNumber, year } = getTwoWeeksAgoMonday();
        getMetrics({
          user,
          masterAccount,
          setData,
          setPreviousData,
          setStartDate,
          setEndDate,
          setCalendars,
          setMonth,
          cachedMetricsData, // cached data
          latestSuccessfullyFetchedWeek,
          setLatestSuccessfullyFetchedWeek,
          setMetricsData,
          componentIsMounted,
          weekNumber,
          setShowSkeletonScreen,
          isRetry: true,
          inputYear: year,
        });
      }
      return;
    }

    if (isMetricsReportStillCalculating(response)) {
      if (isMetricsReady(cachedMetricsData) && isUserDelegatedUser(user)) {
        // show last week's report
        response = cachedMetricsData;
      }
    }

    const { data, calendars, previous_data, from, to } =
      response.user_metrics_report;
    const fromDate = parseISO(from);
    const toDate = parseISO(to);

    setData(data);
    setShowSkeletonScreen(false);
    setPreviousData(previous_data);
    setStartDate(fromDate);
    setMonth(fromDate);
    setEndDate(toDate);
    setCalendars(calendars);
  } catch (error) {
    handleError(error);
  }
}

function renderOverlay({
  shouldShowSelectCalendarModal,
  setShowSelectCalendarModal,
  shouldShowDateSelection,
  setShowDateSelection,
}) {
  if (!shouldShowSelectCalendarModal && !shouldShowDateSelection) {
    return null;
  }

  const onClickExitModal = () => {
    if (shouldShowSelectCalendarModal) {
      setShowSelectCalendarModal(false);
    }

    if (shouldShowDateSelection) {
      setShowDateSelection(false);
    }
  };

  return (
    <div
      className="z-20 w-full h-full absolute top-0 left-0"
      onClick={onClickExitModal}
    ></div>
  );
}
