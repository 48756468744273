import classNames from 'classnames';
import React            from 'react';

export default function sidePanel({
  className,
  children,
  overrideClassName
}) {
  return (
    <div className={classNames(overrideClassName ?? "side-panel-container", className ?? "")}>
      {children}
    </div>
  );
}
