import React from "react";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { getUserToken } from "../../lib/userFunctions";
import { RED_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import CustomButton from "../customButton";
import EventModalPopup from "../eventModalPopup";
import { trackEvent } from "../tracking";

interface CancelModalProps {
  isModalOpen: boolean
  setIsModalOpen: StateSetter<boolean>
  message: string
  setMessage: StateSetter<string>
}

/**
 * The modal that asks the user to confirm and provide feedback when cancelling their subscription.
 */
export default function CancelModal({ isModalOpen, setIsModalOpen, message, setMessage }: CancelModalProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);
  const currentUser = useSelector(state => state.currentUser);

  return (
    <EventModalPopup
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      width={420}
      title={"Are you sure you want to cancel your account?"}
      style={determineDefaultModalStyle(isDarkMode)}
    >
      <div className="mt-5">
        <textarea
          placeholder={
            "Please help us improve by sharing your reason for cancelling"
          }
          className="email-input-container"
          onChange={(e) => {
            setMessage(getInputStringFromEvent(e));
          }}
        />

        <div className="subscription-cancellation-modal-content-button-options">
          <CustomButton
            buttonType={WHITE_BUTTON}
            addPaddingToRight={true}
            onClick={() => setIsModalOpen(false)}
            label="No"
          />

          <CustomButton
            buttonType={RED_BUTTON}
            shouldFocus={true}
            onClick={() => {
              backendBroadcasts.publish("CANCEL_SUBSCRIPTION", {
                message,
                user: currentUser,
              });
              setIsModalOpen(false);
              trackEvent({
                category: "cancel subscription",
                action: "cancelSubscription_1",
                label: `BillingSettings.js || ${message || "No message"}`,
                userToken: getUserToken(currentUser),
              });
            }}
            label="Yes"
            style={{ marginRight: "15px" }}
          />
        </div>
      </div>
    </EventModalPopup>
  );
}
