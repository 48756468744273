import { modulo } from "../../lib/numberFunctions";

/**
 * Determines how many slides should be on the left offscreen of the active slide. Should be
 * at least 2 so the slide on the far left can move immediately with no transition duration.
 */
export const SLIDES_ON_LEFT = 2;

/**
 * Get the index of the slide where 0 means it is the active index. A negative number means
 * the slide is to the left of the active index, and thus hidden from the carousel.
 */
export function getSlidePosition(slideIndex: number, selectedIndex: number, slideCount: number) {
  return modulo(slideIndex - selectedIndex + SLIDES_ON_LEFT, slideCount) - SLIDES_ON_LEFT;
}
