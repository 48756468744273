import React, { useEffect, useMemo, useRef, useState } from "react";
import { formatEmail, getInputStringFromEvent } from "../../../lib/stringFunctions";
import ProfilePhotoInput, { extractImage } from "../../settings/common/profilePhotoInput";
import SaveButton from "../../settings/common/saveButton";
import { getAvailableDomains, getIsDomainCaptureEnabled, getTeamPlanPhotoUrl, getTeamPlanTeamName } from "../../../lib/teamPlanFunctions";
import usePresignedUpload, { PRESIGNED_UPLOAD_STATUS } from "../../../services/customHooks/usePresignedUpload";
import { useSelector } from "react-redux";
import { getMasterAccountEmail, getUserEmail } from "../../../lib/userFunctions";
import { getFileExtension } from "../../../lib/fileFunctions";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { useTeamPlan } from "../../../services/stores/userData";
import type AvatarEditor from "react-avatar-editor";
import DomainDetection from "./domainDetection";
import { filterAndSortDomains, getInitialTeamName, TEAM_SETTING_LABEL_CLASS_NAME } from "./sharedFunctions";
import { createTeamPlan, patchTeamPlanSettings, type TeamPlanSettingsPayload } from "../../queries/teamPlans";
import { useIsCurrentUserOwner } from "../hooks";
import { useAllLoggedInUsers, useAllUserDomains, useMasterAccount } from "../../../services/stores/SharedAccountData";
import broadcast from "../../../broadcasts/broadcast";
import { GENERIC_ERROR_MESSAGE, SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../../services/globalVariables";

const ERROR_PHOTO_UPLOAD = "There was an error uploading your photo";

export interface AdminSettingsActionsProps {
  isDisabled?: boolean
  onClickNext?: React.MouseEventHandler
  onClickSave: React.MouseEventHandler
}

interface AdminSettingsProps {
  ActionsComponent?: React.FC<AdminSettingsActionsProps>
  isCreatingTeam?: boolean
  onSaveSuccess?: () => void
  onClickSkip?: () => void
}

/**
 * This shows the form that allows admins to change the team plan's settings/profile.
 */
export default function AdminSettings({ ActionsComponent=DefaultActions, isCreatingTeam, onClickSkip, onSaveSuccess }: AdminSettingsProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const [teamName, setTeamName] = useState(() => getInitialTeamName(teamPlan, currentUser, isCreatingTeam));
  const [profilePhotoErrorText, setProfilePhotoErrorText] = useState<string>();
  const profilePhotoCropperRef = useRef<AvatarEditor>(null);
  const [profilePhotoFile, setProfilePhotoFile] = useState<File>();
  // If you're creating a team plan and an initial team name is set,
  // the user can submit right away without any additional changes.
  const [hasChanged, setHasChanged] = useState(Boolean(isCreatingTeam && teamName));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTeamPlanNameWarning, setShowTeamPlanNameWarning] = useState(false);
  const teamPlanPhoto = getTeamPlanPhotoUrl(teamPlan);
  const [isDomainDetectionEnabled, setIsDomainDetectionEnabled] = useState(false);
  const [selectedDomainId, setSelectedDomainId] = useState<number>();
  const isOwner = useIsCurrentUserOwner();
  const allUserDomains = useAllUserDomains(state => state.allUserDomains);
  const allLoggedInUsers = useAllLoggedInUsers(state => state.allLoggedInUsers);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const masterAccountEmail = getMasterAccountEmail({ masterAccount });

  const sortedDomains = useMemo(() => {
    return filterAndSortDomains({ allLoggedInUsers, allUserDomains, teamPlan });
  }, [allLoggedInUsers, allUserDomains, teamPlan]);

  useEffect(() => {
    // First, check if the owner has already set a domain to capture.
    const selectedDomain = getAvailableDomains(teamPlan).find(domain => domain.captured);
    if (selectedDomain) {
      setSelectedDomainId(selectedDomain.id);
      return;
    }

    // Second, try to find the domain associated with the master account.
    const primaryDomain = sortedDomains.find(domain => masterAccountEmail.endsWith(formatEmail(domain.name)));

    // Third, fallback to the first domain in the sorted list.
    const defaultDomain = primaryDomain ?? sortedDomains[0];
    setSelectedDomainId(defaultDomain?.id);
  }, [masterAccountEmail, sortedDomains, teamPlan]);

  useEffect(() => {
    if (isEmptyObjectOrFalsey(teamPlan)) {
      setIsDomainDetectionEnabled(true);
    } else {
      const capturedDomains = getAvailableDomains(teamPlan).filter(domain => domain.captured);
      setIsDomainDetectionEnabled(getIsDomainCaptureEnabled(teamPlan) && capturedDomains.length > 0);
      setTeamName(getTeamPlanTeamName(teamPlan));
    }
  }, [teamPlan]);

  const updateProfilePicture: StateSetter<File | undefined> = (newFile) => {
    setHasChanged(true);
    setProfilePhotoFile(newFile);
  };

  const presignedUploadPayloadData = useMemo(() => {
    if (!profilePhotoFile) {
      return {};
    }
    // attach the file
    // Set the S3 key/filename in a way that avoids conflicts.
    // Example key: Kenny-Fitzgerald-1706907495665.jpg
    const extension = profilePhotoFile
      ? getFileExtension(profilePhotoFile)
      : "";
    // TODO: check to make sure teamname is not null
    const concatenatedName = `${teamName ?? ""}`.slice(0, 16); // TODO: we should also use the UUID team name

    // team_plans is the sub-folder
    const profilePhotoS3Key = `team_plans/${concatenatedName}-${new Date().valueOf()}${
      extension ? "." + extension : ""
    }`;

    return { key: profilePhotoS3Key };
  }, [profilePhotoFile]);

  const { presignedUpload, presignedUploadStatus } = usePresignedUpload({
    path: "settings/profile_photo_upload_url",
    payloadData: presignedUploadPayloadData,
    userEmail: getUserEmail(currentUser),
    v2: true,
  });

  // Disable the save button if nothing has changed or if the form is being submitted.
  const isDisabled = !hasChanged || isSubmitting || (
    profilePhotoFile && presignedUploadStatus !== PRESIGNED_UPLOAD_STATUS.READY
  );

  const onToggleDomainCapture = () => {
    setIsDomainDetectionEnabled(current => !current);

    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const onChangeSelectedDomain = (newDomainId: number | undefined) => {
    if (!newDomainId || newDomainId === -1) {
      return;
    }
    setSelectedDomainId(newDomainId);

    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const updateTeamPlan = (teamPlanS3Key?: string) => {
    if (!currentUser) {
      return;
    }

    const payload: TeamPlanSettingsPayload = { name: teamName };

    if (teamPlanS3Key) {
      payload.profile_photo_s3_key = teamPlanS3Key;
    }

    if (isOwner) {
      payload.domain_capture = isDomainDetectionEnabled;

      if (isDomainDetectionEnabled) {
        payload.domain_ids = selectedDomainId ? [selectedDomainId] : [];
      }
    }

    const query = isEmptyObjectOrFalsey(teamPlan) ? createTeamPlan : patchTeamPlanSettings;
    query(currentUser, payload).then(response => {
      if (isEmptyObjectOrFalsey(response)) {
        throw new Error("Team plan could not be updated");
      }
      if (profilePhotoFile) {
        setProfilePhotoFile(undefined);
      }
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    }).catch(() => {
      setIsSubmitting(false);
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        GENERIC_ERROR_MESSAGE,
      );
    });
  };

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (!teamName) {
      setShowTeamPlanNameWarning(true);
      return;
    }

    const croppedProfilePhoto = extractImage(profilePhotoCropperRef, profilePhotoFile);

    setIsSubmitting(true);
    if (croppedProfilePhoto) {
      croppedProfilePhoto.toBlob((blob) => {
        if (!blob) {
          return;
        }
        presignedUpload(blob)
          .then((resp) => {
            if (isEmptyObjectOrFalsey(resp)) {
              throw new Error();
            }
            updateTeamPlan(resp.s3FileKey);
          })
          .catch(() => {
            setProfilePhotoErrorText(ERROR_PHOTO_UPLOAD);
          });
      }, profilePhotoFile?.type || "image/jpeg");
    } else {
      updateTeamPlan();
    }
  };

  const onChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newTeamName = getInputStringFromEvent(e);
    setTeamName(newTeamName);
    if (!hasChanged) {
      setHasChanged(true);
    }
    if (showTeamPlanNameWarning && newTeamName) {
      setShowTeamPlanNameWarning(false);
    }
  };

  return (
    <>
      <div className={TEAM_SETTING_LABEL_CLASS_NAME}>Photo</div>
      <div>
        <ProfilePhotoInput
          errorText={profilePhotoErrorText}
          originalProfilePhotoSrc={teamPlanPhoto}
          profilePhotoCropperRef={profilePhotoCropperRef}
          profilePhotoFile={profilePhotoFile}
          setProfilePhotoFile={updateProfilePicture}
        />
      </div>

      <div className={TEAM_SETTING_LABEL_CLASS_NAME}>Name*</div>
      <div>
        <input
          className="default-input-field width-240px-important height-34px"
          value={teamName}
          onChange={onChangeName}
          placeholder="Add team name"
        />
      </div>
      {showTeamPlanNameWarning ? (
        <div className="warning-color default-font-size mt-1">
          Please provide a name for your team
        </div>
      ) : null}

      <DomainDetection
        isDomainDetectionEnabled={isDomainDetectionEnabled}
        onChangeSelectedDomain={onChangeSelectedDomain}
        onToggleDomainCapture={onToggleDomainCapture}
        selectedDomainId={selectedDomainId}
        sortedDomains={sortedDomains}
      />

      <div className="flex-grow"></div>

      <ActionsComponent onClickSave={onClick} onClickNext={onClickSkip} isDisabled={isDisabled} />
    </>
  );
}

function DefaultActions({ isDisabled, onClickSave }: AdminSettingsActionsProps) {
  return <SaveButton onClick={onClickSave} disabled={isDisabled} />;
}
