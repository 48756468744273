// example of conference room object
// {
// 	"resourceEmail": "c_188e63tmdpriei2jhuqr1vqt88u32@resource.calendar.google.com",
// 	"resourceName": [
// 			"headquarters-1-test",
// 			"headquarters-1-test"
// 	],
// 	"userVisibilityDescription": "",
// 	"buildingId": "Headquarters",
// 	"roomsObject": {
// 			"id": 19221877,
// 			"domain_id": 1,
// 			"domain_room_id": "394fb1e5e5d78bcbc6aae89aaabc7659",
// 			"etag": "\"WK9tybb2TgVN-FukjV5Q7sjTXQHDeuZZ70913_bjogQ/PPPZYnZU8QG_Z8-umGPYbfos46Y\"",
// 			"resource_id": "60569719732",
// 			"resource_name": "Test",
// 			"generated_resource_name": "Headquarters-1-Test",
// 			"resource_type": null,
// 			"resource_email": "c_188e63tmdpriei2jhuqr1vqt88u32@resource.calendar.google.com",
// 			"description": null,
// 			"user_visible_description": null,
// 			"capacity": null,
// 			"floor_name": "1",
// 			"floor_section": null,
// 			"category": "CATEGORY_UNKNOWN",
// 			"building": {
// 					"id": 1,
// 					"building_id": "Headquarters"
// 			}
// 	},
// 	"fullName": "Headquarters-1-Test",
// 	"capacity": 0

import { constructRequestURLV2 } from "../services/api";
import { isGoogleUser } from "../services/appFunctions";
import {
  constructQueryParams,
  handleError,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../services/typeGuards";
import { immutablySortArray } from "./arrayFunctions";
import { GET_PAGINATED_CONFERENCE_ROOMS } from "./endpoints";
import { getDefaultHeaders } from "./fetchFunctions";
import { getUserEmail } from "./userFunctions";

// }
export function getConferenceRoomName(conferenceRoom) {
  if (isEmptyObjectOrFalsey(conferenceRoom)) {
    return "";
  }
  try {
    return (
      conferenceRoom.fullName ||
      conferenceRoom.roomsObject?.generated_resource_name ||
      conferenceRoom.resourceName?.[0] ||
      conferenceRoom.resourceEmail ||
      conferenceRoom.display_name ||
      ""
    );
  } catch (e) {
    return "";
  }
}

export function getRoomsObject(room) {
  return room?.roomsObject;
}

export function getConferenceRoomID(room) {
  return room?.resourceEmail;
}

export function getRoomCapacity(room) {
  return room?.capacity;
}

export function getRoomBuildingID(room) {
  try {
    return room?.building?.id || room?.outlook_room_list?.id || "";
  } catch (e) {
    return "";
  }
}

export async function fetchPaginatedConferenceRooms({ user, page }) {
  try {
    if (isGoogleUser(user)) {
      return; // for now, we only paginate outlook rooms
    }
    const payloadData = {
      headers: getDefaultHeaders(),
    };
    let url = constructRequestURLV2(GET_PAGINATED_CONFERENCE_ROOMS);

    if (!isNullOrUndefined(page)) {
      // still works incase page is 0
      const queryParams = constructQueryParams({ page });
      url += `?${queryParams}`;
    }

    return Fetcher.get(url, payloadData, true, getUserEmail(user));
  } catch (e) {
    // no errors
    handleError(e);
  }
}

export function sortConferenceRooms(rooms) {
  try {
    if (isEmptyArrayOrFalsey(rooms)) {
      return [];
    }
    return immutablySortArray(rooms, (a, b) =>
      getConferenceRoomName(a).localeCompare(getConferenceRoomName(b))
    );
  } catch (e) {
    return rooms;
  }
}

export function getBuildingConferenceRoomCount(building) {
  try {
    return building?.room_count || 0;
  } catch (e) {
    return 0;
  }
}

export function getBuildingIDFromRawObject(building) {
  return building?.id;
}

// TODO: remove hard code sooner than later but need this for pilot
export function getBuildingNameFromRawObject(building) {
  try {
    const buildingID = getBuildingIDFromRawObject(building);
    const name = building?.display_name || building?.building_id || "";
    if (buildingID === 88 && name === "Century Tower 1") {
      return "2nd Century - Tower 1";
    }
    if (buildingID === 104 && name === "Century Tower 2") {
      return "2nd Century - Tower 2";
    }
    return name;
  } catch (e) {
    return "";
  }
}
