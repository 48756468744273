import React, { useEffect, useState } from "react";
import "../styles/focus-mode.css";
import SmallClock from "./components/smallClock.tsx";
import LogoFlash from "./components/logoFlash.tsx";
import PomodoroSelect from "./components/pomodoroSelect";
import { ArrowLeft, ChevronLeft, ChevronRight, Clock } from "react-feather";
import UpcomingEvents from "./upcomingEvents";
import SoundContainer from "./soundContainer";
import TomatoOn from "./resources/tomatoOn";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Mousetrap from "mousetrap";
import { getBackgroundImage } from "./sharedFunctions";
import { showDesignFlag } from "../lib/featureFlagFunctions";
import LoginTitleBar from "../views/login/loginTitleBar";
import { isWindowsElectron } from "../services/commonUsefulFunctions";
import { addDays, format, subDays } from "date-fns";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import PreloadResources from "../components/preloadResources";
import { ALL_FOCUS_MODE_SOUND_TRACKS } from "./sharedVariables";
import DraggableHeader from "../../components/draggableHeader";

const POMODORO_TIMER_ICON_CLASS_NAME =
  "text-white cursor-pointer opacity-60 hover:opacity-100 duration-200 mr-4";
const FOCUS_MODE_CONTAINER_ID = "FOCUS_MODE_CONTAINER_ID";

export default function FocusModeContainer({ onClickExit, isShowing }) {
  const [isPomodoro, setIsPomodoro] = useState(true);
  const currentUser = useSelector((state) => state.currentUser);
  const [showHint, setShowHint] = useState(false);
  const [showBackToVimcalHint, setBackToVimcalHint] = useState(false);
  const [testDate, setTestDate] = useState(new Date());

  const [backgroundImage, setBackgroundImage] = useState(
    getBackgroundImage(currentUser)
  );

  const bindEscapeHotKey = () => {
    Mousetrap.bind(["escape", "`"], function (e) {
      onClickExit && onClickExit();
    });
  };

  const checkBackgroundForNewDay = () => {
    const newBackgroundImage = getBackgroundImage(currentUser);
    if (newBackgroundImage !== backgroundImage) {
      setBackgroundImage(newBackgroundImage);
    }
  }

  useEffect(() => {
    focusModeBroadcast.subscribe("BIND_ESCAPE_HOTKEY", bindEscapeHotKey);
    focusModeBroadcast.subscribe("UPDATE_FOCUS_MODE_BACKGROUND_IMAGE", checkBackgroundForNewDay);

    return () => {
      focusModeBroadcast.unsubscribe("BIND_ESCAPE_HOTKEY");
      focusModeBroadcast.unsubscribe("UPDATE_FOCUS_MODE_BACKGROUND_IMAGE");
    };
  }, []);

  const renderBackButton = () => {
    if (!onClickExit) {
      return null;
    }

    return (
      <div
        className="cursor-pointer"
        onClick={onClickExit}
        onMouseEnter={() => setBackToVimcalHint(true)}
        onMouseLeave={() => setBackToVimcalHint(false)}
      >
        {showBackToVimcalHint ? (
          <div
            className={classNames(
              "large-blur rounded py-2 px-4 select-none absolute",
              "top-14 -left-5",
              "flex items-center",
              "w-48 flex justify-between",
              "text-white"
            )}
          >
            <div className="default-font-size hot-key-text-height">
              Back to Vimcal
            </div>

            <div className="border-width-1px border-solid border-white h-6 flex items-center justify-center text-center px-1.5">
              <div className="hot-key-text-height default-font-size">ESC</div>
            </div>
          </div>
        ) : null}
        <div className="h-12 w-12 xl-3-blur rounded-lg flex items-center justify-center default-hover-blur-button duration-200">
          <ArrowLeft className="font-weight-400 text-white" size={20} />
        </div>
      </div>
    );
  };

  const renderHint = () => {
    if (!showHint) {
      return null;
    }

    return (
      <div
        className={classNames(
          "flex items-center large-blur select-none default-font-size font-weight-300 py-2 px-4 text-white rounded",
          "absolute top-8",
          isPomodoro ? "-left-4" : "w-max -left-14"
        )}
      >
        <div className="default-font-size">
          {isPomodoro ? "Clock" : "Pomodoro Timer"}
        </div>
      </div>
    );
  };

  // TODO: delete
  const renderDesignControls = () => {
    if (!showDesignFlag(currentUser)) {
      return null;
    }

    const getNextImage = () => {
      const newDate = addDays(testDate, 1);
      setBackgroundImage(getBackgroundImage(currentUser, newDate));
      setTestDate(newDate);
    };

    const getPreviousImage = () => {
      const newDate = subDays(testDate, 1);
      setBackgroundImage(getBackgroundImage(currentUser, newDate));
      setTestDate(newDate);
    };

    const ICON_CLASSNAME = "select-none";
    return (
      <div className="absolute bottom-4 left-12 flex items-center">
        <ChevronLeft className={ICON_CLASSNAME} onClick={getPreviousImage} />
        <ChevronRight className={ICON_CLASSNAME} onClick={getNextImage} />

        <div>
          {format(testDate, "PPP")} - {backgroundImage}
        </div>
      </div>
    );
  };

  const renderPrefetchAudio = () => {
    return <PreloadResources audioLinks={ALL_FOCUS_MODE_SOUND_TRACKS} />;
  };

  return (
    <div
      id={FOCUS_MODE_CONTAINER_ID}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        boxShadow: "inset 0 0 0 2000px rgba(15, 15, 15, .20)",
      }}
      className="focus-mode-background flex h-full"
    >
      <DraggableHeader />
      {isWindowsElectron() ? <LoginTitleBar /> : null}

      <div
        className={classNames("absolute top-9 left-12", "flex items-center")}
      >
        {renderBackButton()}
        <SmallClock containerClassName="ml-10" />
      </div>

      <LogoFlash containerClassName="absolute bottom-4 left-12" />
      {renderDesignControls()}

      <div className="flex items-center m-auto">
        <div className="relative">{renderHint()}</div>

        <div
          className="height-34px"
          onMouseEnter={() => setShowHint(true)}
          onMouseLeave={() => setShowHint(false)}
          onClick={() => {
            setIsPomodoro(!isPomodoro);
            setShowHint(false);
          }}
        >
          {isPomodoro ? (
            <Clock className={POMODORO_TIMER_ICON_CLASS_NAME} size={34} />
          ) : (
            <TomatoOn className={POMODORO_TIMER_ICON_CLASS_NAME} size={40} />
          )}
        </div>

        <PomodoroSelect containerClassName="" isPomodoro={isPomodoro} />
      </div>

			{isShowing ? <UpcomingEvents containerClassName="absolute right-12 top-8" /> : null}
      
      <SoundContainer containerClassName="absolute right-12 bottom-8" />
    </div>
  );
}
