import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountUserName } from "../../lib/userFunctions";
import useUsernameCheck from "../../services/customHooks/useUsernameCheck";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import Mousetrap from "mousetrap";
import { getWarningText } from "../settings/settingFunctions";
import classNames from "classnames";
import ApprovalBadgeWithLoadingSpinner from "../settings/common/approvalBadgeWithLoadingSpinner";
import { KEYCODE_ENTER } from "../../services/commonUsefulFunctions";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import updateSettingsBroadcast from "../../broadcasts/updateSettingsBroadcast";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../lib/broadcastValues";
import SaveAndSkipButtons from "./saveAndSkipButtons";

export default function CustomizePersonalLink({ step, onClickNext }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const initialUsername = getAccountUserName({ masterAccount });
  const [username, setUsername] = useState(initialUsername);
  const [usernameAvailable, isFetching] = useUsernameCheck({
    usernameCandidate: username,
  });
  const currentUser = useSelector((state) => state.currentUser);

  const usernameAvailableRef = useRef(usernameAvailable);
  const userNameRef = useRef(username);

  useEffect(() => {
    usernameAvailableRef.current = usernameAvailable;
  }, [usernameAvailable]);

  useEffect(() => {
    userNameRef.current = username;
  }, [username]);

  useEffect(() => {
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, []);

  const onClickSave = () => {
    if (username === initialUsername) {
      // nothing changed
      onClickNext();
      return;
    } else if (!usernameAvailableRef.current) {
      return;
    }

    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_NAME,
      {
        username: userNameRef.current,
      },
    );

    onClickNext();
  };

  const hasUsernameChanged = () => {
    return username !== initialUsername;
  };

  const warning = getWarningText({
    isAvailable: !hasUsernameChanged() || usernameAvailable,
    userName: username,
    isFetching,
    hideBadgeAndWarning: !hasUsernameChanged(),
  });

  const onKeyDown = (key) => {
    if (KEYCODE_ENTER === key?.keyCode) {
      onClickSave();
    }
  };
  return (
    <div className="onboarding-container">
      <div className="section-title">Create your username</div>
      <div className="welcome-subtitle mt-3">
        Choose a URL that best describes you or your business.
      </div>

      <div>
        <div className="flex items-center mt-16">
          <div className="secondary-text-color default-font-size">
            book.vimcal.com/
          </div>
          <input
            className="ml-2 default-input-field input-customize-username"
            value={username}
            onChange={(e) => setUsername(getInputStringFromEvent(e))}
            onKeyDown={onKeyDown}
          />
          {hasUsernameChanged() ? (
            <ApprovalBadgeWithLoadingSpinner
              isFetching={isFetching}
              warning={warning}
              spinnerLocationClassName={
                "user-name-spinner-location absolute right-3"
              }
              containerClassName={"absolute right-18px"}
            />
          ) : null}
        </div>
        {warning ? (
          <div
            className={classNames(
              "warning-color default-font-size",
              "absolute mt-2",
            )}
          >
            {warning}
          </div>
        ) : null}
      </div>

      <SaveAndSkipButtons
        isDisabled={hasUsernameChanged() && (!usernameAvailable || isFetching)}
        onClickNext={onClickNext}
        onClickSave={onClickSave}
      />
    </div>
  );
}
