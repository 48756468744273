import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";

export const MORE_INFO_ICON_SIZE = 14;
export const ALERT_TYPE = {
  CRITICAL: "critical",
  WARNING: "warning",
  DID_YOU_KNOW: "didYouKnow",
} as const;

export function isWarningAllDaySlotsWarning(warning: null | { title?: string, subText?: string }) {
  if (isEmptyObjectOrFalsey(warning)) {
    return false;
  }
  return (
    warning.title?.includes("all-day") || warning.subText?.includes("all-day") || false
  );
}
