import React from "react";
import GoToTagsButton from "./goToTagsButton";

export default function MetricsBreakDownEmptyState() {
  return (
    <div className="mt-2 relative z-10">
      {renderBars()}
      {renderMainText()}
      {renderSubText()}
    </div>
  );
}

function renderMainText() {
  return (
    <div className="font-size-16 flex justify-center mt-2">
      No meeting tagged
    </div>
  );
}

function renderSubText() {
  return (
    <div className="text-white default-font-size flex justify-center mt-2 default-font-size flex-col items-center">
      <div className="metrics-secondary-text-color">
        You can tag more meetings within Settings, in the Painter section.{" "}
      </div>
      <div className="metrics-secondary-text-color mt-2">
        Set up rules in seconds and know where you’re spending your time{" "}
      </div>
      <GoToTagsButton />
    </div>
  );
}

function renderBars() {
  return (
    <div className="flex items-end justify-center">
      <div className="h-10 w-2.5 metrics-faded-background-color mr-1.5"></div>
      <div className="h-6 w-2.5 metrics-faded-background-color mr-1.5"></div>
      <div className="h-7 w-2.5 metrics-faded-background-color"></div>
    </div>
  );
}
