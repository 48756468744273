import create from "zustand";
import { persist } from "zustand/middleware";

export const useMetricsStore = create(
  // keeps track of every calendar in an index
  persist(
    (set) => ({
      last12Weeks: [],
      setLast12Weeks: (last12Weeks) => {
        set((state) => ({ last12Weeks }));
      },
      lastFetchHistoricalMetricsTime: null,
      setLastFetchHistoricalMetricsTime: (lastFetchHistoricalMetricsTime) => {
        set((state) => ({ lastFetchHistoricalMetricsTime }));
      },
      metricsData: null,
      setMetricsData: (metricsData) => {
        set((state) => ({ metricsData }));
      },
      latestSuccessfullyFetchedWeek: null,
      setLatestSuccessfullyFetchedWeek: (latestSuccessfullyFetchedWeek) => {
        set((state) => ({ latestSuccessfullyFetchedWeek }));
      },
      resetMetricsData: () =>
        set((state) => ({
          metricsData: null,
          latestSuccessfullyFetchedWeek: null,
          last12Weeks: [],
          lastFetchHistoricalMetricsTime: null,
        })),
    }),
    {
      name: "metrics-data-store", // unique name
    }
  )
);
