import classNames from "classnames";
import React, { useEffect, useState } from "react";

export default function LoadingDots() {
  const [shownIndex, setShownIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShownIndex((shownIndex) => (shownIndex >= 3 ? 0 : shownIndex + 1));
    }, 400); // Interval of 500ms for each new dot

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          "duration-200",
          shownIndex > 0 ? "" : "invisible"
        )}
      >
        .
      </div>
      <div
        className={classNames(
          "duration-200",
          shownIndex > 1 ? "" : "invisible"
        )}
      >
        .
      </div>
      <div
        className={classNames(
          "duration-200",
          shownIndex > 2 ? "" : "invisible"
        )}
      >
        .
      </div>
    </div>
  );
}
