import React, { useState, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  // PointerSensor, // useful for pen, mouse, etc
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CLICKED_THRESHOLD = 10; // Pixels

export default function DnDGutterHeader({
  items,
  setItems,
  renderIndividualTimeZone,
  onDragStart,
  onDragEnd,
  onClick,
}) {
  // Mouse sensor with activation constraint
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: CLICKED_THRESHOLD, // Drag starts after moving 10px
    },
  });
  const sensors = useSensors(
    mouseSensor,
    // useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      setItems({ oldIndex, newIndex });
    }
    if (onDragEnd) {
      onDragEnd();
    }
  };

  const handleDragStart = (event) => {
    if (onDragStart) {
      onDragStart();
    }
  };

  if (items.length === 1) {
    // no need for drag and drop if it's only one item
    const loneItem = items[0];
    return (
      <SortableItem
        key={`${loneItem}-${0}`}
        id={loneItem}
        renderIndividualTimeZone={renderIndividualTimeZone}
        index={0}
        onClick={onClick}
      />
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={items}>
        <div className="flex justify-around absolute bottom-0 w-full">
          {items.map((item, index) => (
            <SortableItem
              key={`${item}-${index}`}
              id={item}
              renderIndividualTimeZone={renderIndividualTimeZone}
              index={index}
              onClick={onClick}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

function SortableItem({ id, renderIndividualTimeZone, index, onClick }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const [mouseStartPosition, setMouseStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = useCallback(
    (event) => {
      setMouseStartPosition({ x: event.clientX, y: event.clientY });
      if (listeners.onMouseDown) {
        listeners.onMouseDown(event);
      }
    },
    [listeners]
  );

  const handleClick = useCallback(
    (event) => {
      const distance = Math.sqrt(
        Math.pow(mouseStartPosition.x - event.clientX, 2) +
          Math.pow(mouseStartPosition.y - event.clientY, 2)
      );

      if (distance < CLICKED_THRESHOLD) {
        if (onClick) {
          onClick(id, event);
        }
      }
    },
    [onClick, id, mouseStartPosition]
  );

  const combinedListeners = {
    ...listeners,
    onMouseDown: handleMouseDown,
    onClick: handleClick,
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const renderChild = (timeZone) => {
    if (renderIndividualTimeZone) {
      return renderIndividualTimeZone({
        timeZone,
        index: index + 1,
        setNodeRef,
        style,
        attributes,
        listeners: combinedListeners,
      });
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...combinedListeners}
      >
        {timeZone}
      </div>
    );
  };

  return renderChild(id);
}
