import React, { useEffect, useState } from "react";
import {
  isValidTimeZone,
  hasStopEventPropagation,
  removeDuplicatesFromArray,
} from "../services/commonUsefulFunctions";
import SelectTimeZoneInput from "./selectTimeZoneInput";
import { Trash, Plus } from "react-feather";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { LOCAL_TIME_ZONE } from "../lib/vimcalVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { useSelector } from "react-redux";
import { filterOutInvalidTimeZones } from "../lib/timeFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import classNames from "classnames";
import { getAnchorTimeZonesInSettings, getDefaultUserTimeZone, shouldUseTimeZoneOverride } from "../lib/settingsFunctions";

const TIME_ZONE_INPUT_WIDTH = 400;
const ADD_TIME_ZONE_TEXT = "Add time zone";
export default function AnchorTimeZoneModal(props) {
  const { isUpdatingExecutiveProfile } = props;
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);

  const getInitialAnchorTimeZones = (initialTimeZones) => {
    // show "Add time zone option if less than 5 time zones"
    if (isEmptyArray(initialTimeZones)) {
      return [];
    }
    
    const filteredTimeZone = initialTimeZones.slice(-6);
    return removeDuplicatesFromArray(filteredTimeZone);
  };

  const getUser = () => {
    return props.selectedUser ?? currentUser;
  };

  const [updatedAnchorTimeZones, setAnchorTimeZones] = useState(
    getInitialAnchorTimeZones(
      getAnchorTimeZonesInSettings({ user: getUser(), masterAccount })
    )
  );
  const [hasChanged, setHasChanged] = useState(false);

  const [userDefaultTimeZone, setUserDefaultTimeZone] = useState(
    shouldUseTimeZoneOverride({ masterAccount, user: getUser() })
      ? getDefaultUserTimeZone({
          masterAccount,
          user: getUser(),
        })
      : LOCAL_TIME_ZONE
  );

  useEffect(() => {
    if (!props.selectedUser) {
      return;
    }

    const user = props.selectedUser;
    const updatedAnchorTimeZone = getInitialAnchorTimeZones(
      getAnchorTimeZonesInSettings({ user, masterAccount })
    );
    setAnchorTimeZones(updatedAnchorTimeZone);
    const updatedDefaultTimeZone = shouldUseTimeZoneOverride({
      masterAccount,
      user,
    })
      ? getDefaultUserTimeZone({
          masterAccount,
          user,
        })
      : LOCAL_TIME_ZONE;
    setUserDefaultTimeZone(updatedDefaultTimeZone);
    setHasChanged(false);
  }, [props.selectedUser]);

  const onChangeDefaultUserTimeZone = (timeZone) => {
    setUserDefaultTimeZone(timeZone);
    layoutBroadcast.publish("UPDATE_USER_DEFAULT_TIME_ZONE", {
      newTimeZone: timeZone,
      user: getUser(),
      isUpdatingExecutiveProfile,
    });
    setHasChanged(true);
  };

  const onChangeAnchorTimeZone = (timeZone, index) => {
    if (!isValidTimeZone(timeZone)) {
      return;
    }

    const newAnchorTimeZones = filterOutInvalidTimeZones(
      updatedAnchorTimeZones
        .slice(0, index)
        .concat(timeZone)
        .concat(
          updatedAnchorTimeZones.slice(index + 1, updatedAnchorTimeZones.length)
        )
        .filter((tz) => tz !== ADD_TIME_ZONE_TEXT)
    );

    setAnchorTimeZones(newAnchorTimeZones);
    layoutBroadcast.publish("UPDATE_ANCHOR_TIME_ZONES", {
      timeZones: newAnchorTimeZones,
      user: getUser(),
      isUpdatingExecutiveProfile,
    });

    setHasChanged(true);
  };

  const onDeleteTimeZone = (tzToDelete) => {
    const newAnchorTimeZones = filterOutInvalidTimeZones(
      updatedAnchorTimeZones.filter((tz) => tz !== tzToDelete)
    );
    setAnchorTimeZones(newAnchorTimeZones);
    layoutBroadcast.publish("UPDATE_ANCHOR_TIME_ZONES", {
      timeZones: newAnchorTimeZones,
      user: getUser(),
      isUpdatingExecutiveProfile,
    });
    setHasChanged(true);
  };

  const onClickAddMore = (e) => {
    hasStopEventPropagation(e);
    const newAnchorTimeZones =
      updatedAnchorTimeZones.concat(ADD_TIME_ZONE_TEXT);
    setAnchorTimeZones(newAnchorTimeZones);
    setHasChanged(true);
  };

  const renderLocalTimeZoneSection = () => {
    return (
      <div className="mb-8 mt-4">
        <div className="mb-2 select-none default-font-size w-full secondary-text-color">
          Select which time zones you want to display on your calendar.
        </div>

        <div className="flex items-center margin-left-1px">
          <SelectTimeZoneInput
            timeZone={userDefaultTimeZone}
            onChange={onChangeDefaultUserTimeZone}
            addOptionForLocalTimeZone={true}
            updateSelectValueOnChange={true}
            controlWidth={TIME_ZONE_INPUT_WIDTH}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderLocalTimeZoneSection()}

      <div className="mb-2 select-none default-font-size w-full secondary-text-color">
        {isEmptyArray(updatedAnchorTimeZones)
          ? "Add additional time zones to display on your calendar."
          : "Select the additional time zones to display on your calendar."}
      </div>

      {updatedAnchorTimeZones.map((tz, index) => {
        return (
          <div
            className="mb-3 flex items-center"
            key={`select-anchor-time-zone-${tz}-${index}`}
          >
            <SelectTimeZoneInput
              tabIndex={1}
              timeZone={tz}
              onChange={(tz) => onChangeAnchorTimeZone(tz, index)}
              controlWidth={TIME_ZONE_INPUT_WIDTH}
            />

            <Trash
              size={16}
              className="clickable-icon margin-left-ten"
              onClick={() => onDeleteTimeZone(tz)}
              strokeWidth={1}
            />
          </div>
        );
      })}
      {updatedAnchorTimeZones?.length <= 5 ? (
        <div
          className={classNames(
            "hoverable-secondary-text-color justify-content-flex-start-important default-font-size",
            isEmptyArray(updatedAnchorTimeZones) ? "mt-2" : "mt-5",
            "flex justify-center cursor-pointer mb-5"
          )}
          onClick={onClickAddMore}
        >
          <div className="margin-right-5">
            <Plus size={12} />
          </div>
          Add more
        </div>
      ) : (
        null
      )}
    </div>
  );
}
