import React, { useState } from "react";
import { useSelector } from "react-redux";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import GoogleCalendarService from "../../../../services/googleCalendarService";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import SelectGuestPermissions from "../../../selectGuestPermissions";
import { getAllUsers, SelectUser, SELECT_USER_TYPE } from "../../common/selectUser";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { determineDefaultGuestPermissions } from "../../../../lib/settingsFunctions";
import { getUserEmail } from "../../../../lib/userFunctions";
import AccountLabel from "../../accountLabel";
import { POSITION } from "../../../../lib/styleFunctions";

const SELECT_USER_ID = "preferences-select-user-id";
export default function DefaultGuestPermissions() {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const selectedUser = getAllUsers({
    currentUser,
    allLoggedInUsers,
    masterAccount,
    selectUserType: SELECT_USER_TYPE.ALL_USERS,
  })[selectedUserIndex]?.value;

  const onClickSaveGuestPermissions = (option) => {
    const { modifyEventsString, inviteOthersString, seeGuestListString } =
      GoogleCalendarService;
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");

    if (option.includes(modifyEventsString)) {
      updateSettingsBroadcast.publish(UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_GUEST_PERMISSIONS, {
        defaultPermissions: [
          modifyEventsString,
          inviteOthersString,
          seeGuestListString,
        ],
        user: selectedUser,
      });
    } else {
      updateSettingsBroadcast.publish(UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_GUEST_PERMISSIONS, {
        defaultPermissions: option,
        user: selectedUser,
      });
    }
  };

  return (
    <React.Fragment key={`${SELECT_USER_ID}-${getUserEmail(selectedUser)}`}>
      <AccountLabel position={POSITION.TOP} />
      <div className="w-max">
        <SelectUser
          setSelectedUserIndex={setSelectedUserIndex}
          id={SELECT_USER_ID}
          selectedUser={selectedUser}
          inputContainerClassName="mb-2"
        />
      </div>
      <SelectGuestPermissions
        defaultOption={determineDefaultGuestPermissions({ user: selectedUser })}
        sendInformationBackToParentComponent={onClickSaveGuestPermissions}
        updateOnChange={true}
      />
    </React.Fragment>
  );
}
