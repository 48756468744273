import classNames from "classnames";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  getMinutes,
  isAfter,
  isSameMinute,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";
import { useSelector } from "react-redux";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { isCancelledEvent } from "../lib/eventFunctions";
import { getUpcomingCalendarUserCalendarIDs, getUserEmail } from "../lib/userFunctions";
import {
  convertMinutesIntoDayHoursAndMinutes,
  FormatIntoJSDate,
  GetConferenceURL,
  hasStopEventPropagation,
  isSameOrAfterMinute,
  openConferencingURL,
  OpenGoogleMapsLocation,
  sortEventsJSDate,
} from "../services/commonUsefulFunctions";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import db from "../services/db";
import { getEventLocation } from "../services/eventResourceAccessors";
import { mergeSameEvents } from "../services/mergeEventFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { getFocusModeUpcomingStatePreference, setFocusModeUpcomingStatePreference } from "./sharedFunctions";
import { DEXIE_EVENT_COLUMNS, isDBEventItemWithinWindow } from "../lib/dbFunctions";
import { devErrorPrint } from "../services/devFunctions";
import { getDefaultUserTimeZone } from "../lib/settingsFunctions";
import { createUUID } from "../services/randomFunctions";
import { immutablySortArray } from "../lib/arrayFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";

export default function UpcomingEvents({ containerClassName }) {
  const [isShowing, setShowing] = useState(getFocusModeUpcomingStatePreference());
  const currentUser = useSelector((state) => state.currentUser);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const componentIsMounted = useIsMounted();
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const allCalendars = useAllCalendars((state) => state.allCalendars);

  const fetchEventCounter = useRef(0);

  useEffect(() => {
    focusModeBroadcast.subscribe("UPDATE_UPCOMING_EVENT", getUpcomingEvents);
    getUpcomingEvents();

    return () => {
      focusModeBroadcast.unsubscribe("UPDATE_UPCOMING_EVENT");
    };
  }, []);

  const renderToggleIcon = () => {
    if (upcomingEvents.length === 0) {
      return null;
    }

    return (
      <div 
        className="w-full flex justify-end items-center cursor-pointer"
        onClick={() => {
          const updatedIsShowing = !isShowing;
          setShowing(updatedIsShowing);
          setFocusModeUpcomingStatePreference(updatedIsShowing);
        }}
      >
        {isShowing ? null : (
          <div className="default-font-size text-white select-none">Upcoming</div>
        )}

        <ChevronDown
          size={24}
          className={classNames(
            "ml-6 cursor-pointer duration-200",
            "text-white",
            "z-10",
            isShowing ? "rotate-upside-down" : ""
          )}
        />
      </div>
    );
  };

  const renderStartsInText = () => {
    const getTitle = () => {
      if (!upcomingEvents || upcomingEvents.length === 0) {
        return "Your schedule is clear!";
      }

      const nextEvent = upcomingEvents[0];
      const { eventStart } = nextEvent;

      const currentTime = new Date();
      if (
        isSameOrAfterMinute(currentTime, eventStart) ||
        isSameMinute(currentTime, eventStart)
      ) {
        return `Now`;
      } else {
        return `Starts in ${convertMinutesIntoDayHoursAndMinutes(
          differenceInMinutes(eventStart, currentTime)
        )}`;
      }
    };

    return (
      <div
        className={classNames(
          "text-white default-font-size font-weight-300 mt-2 select-none duration-200",
          isShowing ? "opacity-100" : "opacity-0",
          "flex justify-end"
        )}
      >
        {getTitle()}
      </div>
    );
  };

  const renderUpcomingEvents = () => {
    const getTimeLabel = (date) => {
      if (getMinutes(date) === 0) {
        return "h";
      } else {
        return "h:mm";
      }
    };

    const getTileLabel = (event) => {
      const { eventStart, eventEnd } = event;

      if (format(eventStart, "aa") !== format(eventEnd, "aa")) {
        return `${format(
          eventStart,
          getTimeLabel(eventStart) + " a"
        )} - ${format(eventEnd, getTimeLabel(eventEnd) + " a")}`;
      } else {
        return `${format(eventStart, getTimeLabel(eventStart))} - ${format(
          eventEnd,
          getTimeLabel(eventEnd) + " a"
        )}`;
      }
    };

    const onClickEvent = (event) => {
      mainCalendarBroadcast.publish("SET_PREVIEW_EVENT", event);
      layoutBroadcast.publish("TOGGLE_FOCUS_MODE", false);
    };

    const renderActionButton = (event) => {
      const conferenceURL = GetConferenceURL(event);
      const location = getEventLocation(event);
      const CLASS_NAME = "selected-option-background-color flex items-center h-8 rounded w-16 flex justify-center";

      if (conferenceURL) {
        return (
          <div 
            className={CLASS_NAME}
            onClick={(e) => {
              hasStopEventPropagation(e);
              openConferencingURL(conferenceURL, currentUser.email)
              onClickEvent(event);
            }}
          >
            Join
          </div>
        )
      } else if (location) {
        return (
          <div 
            className={CLASS_NAME}
            onClick={(e) => {
              hasStopEventPropagation(e);
              OpenGoogleMapsLocation(location, currentUser)
              onClickEvent(event);
            }}
          >
            Map
          </div>
        )
      } else {
        return null;
      }
    };

    return (
      <div className={classNames(isShowing ? "opacity-100" : "opacity-0")}>
        {upcomingEvents?.map((event, index) => {
          return (
            <div
              key={`upcoming-event-${index}-${event.uniqueEtag}`}
              className={classNames(
                "upcoming-event-container",
                "duration-200 default-hover-blur-button",
                "xl-3-blur",
                // "extreme-blur",
                "flex justify-between",
                "text-white",
                "mt-4",
                "px-4",
                "rounded-md",
                "select-none",
                "default-font-size",
                "select-none cursor-pointer"
              )}
              onClick={() => {
                onClickEvent(event);
              }}
            >
              <div>
                <div className="event-title default-font-size">
                  {event.summaryUpdatedWithVisibility}
                </div>
                <div>{getTileLabel(event)}</div>
              </div>

              {renderActionButton(event)}
            </div>
          );
        })}
      </div>
    );
  };

  const getUpcomingEvents = () => {
    if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
      return;
    }

    const newCounter = createUUID();
    fetchEventCounter.current = newCounter;

    let dbFetchPromiseArrary = [];
    let allEvents = [];

    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({masterAccount, allCalendars, allLoggedInUsers});

    const oneDayBefore = startOfDay(subDays(new Date(), 1));
    const oneDayAfter = endOfDay(addDays(new Date(), 1));

    const currentTime = new Date();

    allLoggedInUsers.forEach((user) => {
      const email = getUserEmail(user);

      let calendarDBFetchPromise = db
        .fetch(email)
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(upcomingCalendarIDs)
        .and(function (item) {
          return isDBEventItemWithinWindow({
            item,
            windowStart: oneDayBefore,
            windowEnd: oneDayAfter,
          });
        })
        .toArray()
        .then((response) => {
          if (!componentIsMounted.current || newCounter !== fetchEventCounter.current) {
            // not mounted anymore or fetch counter has changed
            return;
          }

          const calendarEvents = response.map((e) => {
            return e.event;
          });

          const formattedEvents = calendarEvents.map((e) => {
            return FormatIntoJSDate(e, getDefaultUserTimeZone({masterAccount, user: currentUser}));
          });

          const filteredEvents = formattedEvents.filter(
            (e) =>
              !isCancelledEvent(e) &&
              isAfter(e.eventEnd, currentTime) &&
              isToday(e.eventStart)
          );
          const sortedEvents = immutablySortArray(filteredEvents, (a, b) =>
            sortEventsJSDate(a, b)
          );

          allEvents = allEvents.concat(sortedEvents);
        })
        .catch((err) => {devErrorPrint(err, "dbFetch_")});

      dbFetchPromiseArrary = dbFetchPromiseArrary.concat(
        calendarDBFetchPromise
      );
    });

    if (dbFetchPromiseArrary.length === 0) {
      return;
    }

    Promise.all(dbFetchPromiseArrary).then(() => {
      if (!componentIsMounted.current || newCounter !== fetchEventCounter.current) {
        // not mounted anymore or fetch counter has changed
        return;
      }

      if (allEvents.length === 0) {
        setUpcomingEvents([]);
        return;
      }

      // filter out events that are duplicates
      const mergedEvents = mergeSameEvents({
        eventList: allEvents,
        alwaysMerge: true,
        allCalendars,
        masterAccount,
        allLoggedInUsers,
      });

      const sortedEvents = immutablySortArray(mergedEvents, (a, b) => sortEventsJSDate(a, b));

      const filterTime = addMinutes(currentTime, 10);
      if (sortedEvents.length === 1) {
        setUpcomingEvents(sortedEvents);
      } else {
        const filteredEvent = sortedEvents.filter((e) =>
          isAfter(e.eventEnd, filterTime)
        );
        if (filteredEvent.length === 0) {
          setUpcomingEvents(sortedEvents.slice(0, 2));
        } else {
          setUpcomingEvents(filteredEvent.slice(0, 2));
        }
      }
    }).catch((err) => {devErrorPrint(err, "promise_")});
  };

  return (
    <div className={classNames(containerClassName ?? "")}>
      {renderToggleIcon()}
      {renderStartsInText()}
      {renderUpcomingEvents()}
    </div>
  );
}
