import React, { useState, useMemo } from "react";
import ShortcutHoverHint from "../shortcutHoverHint";
import { Edit, X, Trash2, MoreVertical, CornerUpRight } from "react-feather";
import GlobalKeyMapTile from "../globalKeyMapTile";
import DeleteEventButton from "./deleteEventButton";
import EditButton from "./editButton";
import Broadcast from "../../broadcasts/broadcast";
import { createWritableCalendarList } from "../../lib/stateManagementFunctions";
import { useSelector } from "react-redux";
import { allowOutlookEventProposal, isEventHiddenEvent, isOutOfOfficeEvent, isPreviewOutlookEvent, shouldShowHiddenEventsOption } from "../../lib/eventFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import Email from "../icons/email";
import { getCalendarUserCalendarID } from "../../services/calendarAccessors";
import classNames from "classnames";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
  isOutlookDraftEvent,
} from "../../services/eventResourceAccessors";
import { isElectronGlassyBackground } from "../../lib/clientFunctions";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { isOutlookEventForwardable } from "../../lib/outlookFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { truncateString } from "../../lib/stringFunctions";
import expandedEventViewBroadcast from "../../broadcasts/expandedEventViewBroadcast";
import { EXPANDED_VIEW_BROADCAST_VALUES } from "../../lib/broadcastValues";
import FilledShortcutTiles from "../shortcutTiles/filledShortcutTiles";

const ICON_MARGIN_RIGHT = 16;

function ExpandViewToolbar(props) {
  const [displayMoreOptions, setMoreOptions] = useState(false);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [showForwardOptions, shouldShowForwardOptions] = useState(false);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);

  const writableCalendars = useMemo(() => {
    // const eventCalendar = allCalendars[getEventUserCalendarID(props.event)];
    return createWritableCalendarList({
      emailToNameIndex,
      allCalendars: allCalendars,
      currentUser,
      masterAccount,
      allLoggedInUsers,
    });
  }, [allCalendars, emailToNameIndex, props.event]);

  const toggleMoreOptions = () => {
    setMoreOptions(!displayMoreOptions);
  };

  const renderMoreOptions = () => {
    if (isOutOfOfficeEvent(props.event)) {
      return null;
    }

    if (!props.shouldDisplayMoreOptions) {
      return null;
    }

    return (
      <span className="position-relative">
        {isPreviewOutlookEvent(props.event)
          ? null
          : <MoreVertical
            size={16}
            className="clickable-icon mt-0.5"
            onClick={toggleMoreOptions}
            style={{ marginRight: ICON_MARGIN_RIGHT - 3 }}
          />
        }

        {displayMoreOptions ? renderOverlay(toggleMoreOptions) : null}
        {displayMoreOptions ? (
          <div
            className={classNames(
              "absolute event-pop-up xl-2-blur select-more-expanded-options",
              "dark-mode-panel-dropdown-background-color",
              props.popupEvent ? "top-7 -right-14" : "top-0 right-10",
              isElectronGlassyBackground()
                ? "pop-up-container-full-opacity"
                : "",
              "max-h-96 overflow-y-auto",
            )}
          >
            {renderOptionsDropdown(props, toggleMoreOptions, writableCalendars)}
          </div>
        ) : null}
      </span>
    );
  };

  if (isEmptyObjectOrFalsey(props.event)) {
    return null;
  }
  if (isOutlookDraftEvent(props.event)) {
    return (
      <div
        className="display-flex-flex-direction-row justify-content-flex-end align-items-center"
        style={{ marginTop: props.toolBarMarginTop }}
      >
        {renderX(props)}
      </div>
    );
  }

  return (
    <div
      className="display-flex-flex-direction-row justify-content-flex-end align-items-center"
      style={{ marginTop: props.toolBarMarginTop }}
    >
      <div className={props.popupEvent ? "-mt-0.5" : ""}>
        {renderMoreOptions()}
      </div>
      {renderOutlookFowarding({
        props,
        showForwardOptions,
        shouldShowForwardOptions,
      })}
      {showForwardOptions
        ? renderOutlookForwardingOptions({ props, shouldShowForwardOptions })
        : null}

      <div className={props.popupEvent ? "-mt-0.5" : ""}>
        {renderEmail(props)}
      </div>

      {renderEdit(props)}
      {renderTrash(props)}
      {renderX(props)}
    </div>
  );
}

function renderOutlookForwardingOptions({ props, shouldShowForwardOptions }) {
  const { event, originalRecurringEvent } = props;
  const options = [
    {
      label: "This event",
      action: () => {
        layoutBroadcast.publish("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", {event});
      },
    },
    {
      label: "All events in the series",
      action: () => {
        layoutBroadcast.publish("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", {
          event,
          originalRecurringEvent: originalRecurringEvent,
        });
      },
    },
  ];
  const onClickOption = (action) => {
    action();
    shouldShowForwardOptions(false);
  };

  const onClickDismiss = () => {
    shouldShowForwardOptions(false);
  };

  return (
    <div>
      {renderOverlay(onClickDismiss)}
      <div
        className={classNames(
          "absolute event-pop-up xl-2-blur select-more-expanded-options",
          props.popupEvent ? "top-7 -right-14" : "top-11 right-10",
          "dark-mode-panel-dropdown-background-color",
          "forwarding-event-options-container-width",
          isElectronGlassyBackground() ? "pop-up-container-full-opacity" : "",
        )}
      >
        {options.map((p, index) => {
          return (
            <div
              key={`expanded_view_options_${index}`}
              className="select-expanded-option"
              onClick={() => onClickOption(p.action)}
            >
              {p.label}

              {p.shortcut ? (
                <div><FilledShortcutTiles shortcut={p.shortcut} /></div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function renderOverlay(toggleMoreOptions) {
  return (
    <div
      onClick={toggleMoreOptions}
      className="layout-over-lay"
      style={{ zIndex: 1, left: "-10000px", backgroundColor: "transparent" }}
    ></div>
  );
}

function renderOptionsDropdown(props, toggleMoreOptions, writableCalendars) {
  const options = [
    {
      label: "Duplicate",
      action: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT),
      shortcut: "Y P",
    },
    {
      label: "Create template from event",
      action: props.onClickTemplateButton,
    },
  ];

  if (shouldShowHiddenEventsOption(props.event)) {
    const isEventCurrentlyHidden = isEventHiddenEvent(props.event);
    options.push({
      label: isEventCurrentlyHidden ? "Show hidden event" : "Hide event",
      action: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TOGGLE_HIDE_EVENT),
    });
  }

  if (allowOutlookEventProposal(props.event)) {
    options.push({
      label: "Tentative and propose new time",
      action: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME),
    });
    options.push({
      label: "Decline and propose new time",
      action: () => expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME),
    });
  }

  if (!isEmptyArrayOrFalsey(writableCalendars)) {
    const eventCalendarId = getEventUserCalendarID(props.event);
    writableCalendars.forEach((c) => {
      if (eventCalendarId !== getEventUserCalendarID(c.value)) {
        options.push({
          label: `Copy to ${truncateString(c.label, 26)}`,
          action: () => {
            expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT, getCalendarUserCalendarID(c.value));
          },
        });
      }
    });
  }

  const onClickOption = (action) => {
    action();
    toggleMoreOptions();
  };

  return options.map((p, index) => {
    return (
      <div
        key={`expanded_view_options_${index}`}
        className="select-expanded-option"
        onClick={() => onClickOption(p.action)}
      >
        {p.label}

        {p.shortcut ? <div><FilledShortcutTiles shortcut={p.shortcut} /></div> : null}
      </div>
    );
  });
}

function renderOutlookFowarding({
  props,
  showForwardOptions,
  shouldShowForwardOptions,
}) {
  const { event, isAppInTaskMode, isInSearch, originalRecurringEvent } = props;
  if (isAppInTaskMode || isInSearch) {
    return null;
  }

  if (!isOutlookEventForwardable({event})) {
    return null;
  }

  const onClick = () => {
    if (getEventMasterEventID(event) && originalRecurringEvent) {
      // show menu
      shouldShowForwardOptions(true);
      return;
    }

    layoutBroadcast.publish("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", {event});
  };

  return (
    <ShortcutHoverHint
      left
      style={{
        marginLeft: "-80px",
      }}
      title={"Forward"}
    >
      <CornerUpRight
        size={16}
        className="clickable-icon mt-0.5"
        onClick={onClick}
        style={{ marginRight: ICON_MARGIN_RIGHT - 3 }}
      />
    </ShortcutHoverHint>
  );
}

function renderEmail(props) {
  if (isPreviewOutlookEvent(props.event)) {
    return null;
  }
  return (
    <Email
      event={props.event}
      shouldHide={!props.shouldDisplayEmailButton}
      onClickEmail={() =>
        Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", props.event)
      }
      containerStyle={{ marginRight: ICON_MARGIN_RIGHT }}
      hoverHintStyle={{
        top: "-10px",
        right: "30px",
      }}
    />
  );
}

function renderEdit(props) {
  if (props.template) {
    return (
      <span>
        <Edit
          size="16"
          onClick={props.editEventTemplate}
          // style={{marginTop: 1}}
          className="clickable-icon"
          style={{ marginRight: ICON_MARGIN_RIGHT }}
        />
      </span>
    );
  }

  if (props.shouldHideEdit) {
    return null;
  }

  return <EditButton containerStyle={{ marginRight: ICON_MARGIN_RIGHT }} />;
}

function renderX(props) {
  return (
    <ShortcutHoverHint
      left
      style={{
        marginLeft: "-100px",
        marginTop: props.popupEvent || props.template ? "-5px" : "-9px",
      }}
      title={"Close"}
      shortcut={"Esc"}
      containerStyle={{ marginRight: 15 }}
    >
      <GlobalKeyMapTile
        style={{ left: "-10px", top: "-21px" }}
        shortcut={"Esc"}
      />

      <X
        size="18"
        className="clickable-icon"
        onClick={props.onClickCloseButton}
      />
    </ShortcutHoverHint>
  );
}

function renderTrash(props) {
  if (props.template) {
    return (
      <span>
        <Trash2
          size="16"
          className="clickable-icon mr-4"
          onClick={props.deleteEventTemplate}
        />
      </span>
    );
  }

  if (!props.shouldDisplayDeleteButton) {
    return null;
  }

  return (
    <DeleteEventButton
      event={props.event}
      containerMarginRight={ICON_MARGIN_RIGHT}
    />
  );
}

export default ExpandViewToolbar;
