import React from "react";
import { useIsCurrentUserAdmin } from "../hooks";
import AdminSettings from "./adminSettings";
import MemberSettings from "./memberSettings";

export default function TeamPlanSettings() {
  const isAdmin = useIsCurrentUserAdmin();

  return (
    <div className="flex flex-col">
      {isAdmin ? <AdminSettings /> : <MemberSettings />}
    </div>
  );
}
