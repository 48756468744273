import React, { useCallback, useMemo, useState } from "react";
import { Plus } from "react-feather";
import classNames from "classnames";
import { getAttendeeEmail } from "../../../../lib/groupSchedulingFunctions";
import { useSelector } from "react-redux";
import CheckBoxSpinner from "../../../spinners/checkboxSpinner";
import ShareLink from "../shareLink";
import { getDefaultFontColor } from "../../../../lib/styleFunctions";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import EventModalPopup from "../../../eventModalPopup";
import AddEmptySeats from "./addEmptySeats";
import type { GroupSchedulingAttendeeType } from "../../../../lib/groupSchedulingVariables";
import { EmailPlanDictionary, getIsAddingSeats, groupEmailPlanDictionaryBySeat } from "../sharedFunctions";
import { DOLLARS_IN_CENTS, EA_ANNUAL_PRICE, EA_MONTHLY_PRICE, NON_EA_ANNUAL_PRICE, NON_EA_MONTHLY_PRICE } from "../../../../services/globalVariables";
import { StripeCost } from "../../updatedAddTeamMembersModal";
import { getExistingTeamMembers } from "../../sharedFunctions";
import { useTeamPlan } from "../../../../services/stores/userData";
import { useIsFixedSeating, useOpenSeats, useSubmitInviteMembers } from "../../hooks";
import SeatSummary from "./seatSummary";
import { getObjectEmail } from "../../../../lib/objectFunctions";
import SpinnerV2 from "../../../spinnerV2";
import NewMemberInput from "../../newMemberInput";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { isUserInFreeTrial } from "../../../../lib/stateManagementFunctions";
import _ from "underscore";
import { hasAccountBeenOnTeamPlan } from "../../../../services/accountFunctions";
import { determineDefaultModalStyle } from "../../../../lib/modalFunctions";
import { getTeamPlanEASeatCount } from "../../../../lib/teamPlanFunctions";
import { getStripeCostVimcalCoreUnitPrice, getStripeCostVimcalEAUnitPrice } from "../../../../services/accessors/stripeCost";

interface AddSeatsSectionProps {
  attendeeForModal?: GroupSchedulingAttendeeType
  closePlanModal: () => void
  hasCheckedAttendeeForModal: boolean
  isPayingMonthly: boolean
  nextPage: () => void
  setHasCheckedAttendeeForModal: StateSetter<boolean>
  setShareToken: StateSetter<string | null>
  shareToken: string | null
  showHeader?: boolean
  emailPlanDictionary: EmailPlanDictionary
  setEmailPlanDictionary: StateSetter<EmailPlanDictionary>
  defaultVimcalSeats: number
  setDefaultVimcalSeats: StateSetter<number>
  vimcalEASeats: number
  setVimcalEASeats: StateSetter<number>
  stripeCost: StripeCost
}

/**
 * The page where the user adds open seats and invites others to join their team plan.
 */
export default function AddSeatsSection({
  attendeeForModal,
  closePlanModal,
  hasCheckedAttendeeForModal,
  isPayingMonthly,
  nextPage,
  setHasCheckedAttendeeForModal,
  setShareToken,
  shareToken,
  showHeader,
  emailPlanDictionary,
  setEmailPlanDictionary,
  defaultVimcalSeats,
  setDefaultVimcalSeats,
  vimcalEASeats,
  setVimcalEASeats,
  stripeCost,
}: AddSeatsSectionProps) {
  const [isFetchingEligibility, setIsFetchingEligibility] = useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const { inviteTeamMembers, isSubmittingInvites } = useSubmitInviteMembers();

  // If the user is in a free trial plan, they send invites without reviewing payment.
  const isFreeTrial = isUserInFreeTrial(masterAccount) && (
    !isEmptyObjectOrFalsey(teamPlan) || !hasAccountBeenOnTeamPlan(masterAccount)
  );

  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();
  const { vimcalEmails, vimcalEAEmails } = useMemo(() => (
    groupEmailPlanDictionaryBySeat(emailPlanDictionary)
  ), [emailPlanDictionary]);

  const existingMemberEmails = useMemo(() => (
    getExistingTeamMembers(teamPlan).map(u => getObjectEmail(u))
  ), [teamPlan]);
  const areEAsPresent = getTeamPlanEASeatCount(teamPlan) > 0;

  // Users can have multiple invites sent.
  // For payment preview purposes, we only care about new users being added to the plan.
  const newVimcalEmails = useMemo(() => (
    vimcalEmails.filter(e => !existingMemberEmails.includes(e))
  ), [existingMemberEmails, vimcalEmails]);
  const newVimcalEAEmails = useMemo(() => (
    vimcalEAEmails.filter(e => !existingMemberEmails.includes(e))
  ), [existingMemberEmails, vimcalEAEmails]);
  const isFixedSeating = useIsFixedSeating();
  const isAddingSeats = getIsAddingSeats({
    defaultVimcalSeats,
    newVimcalEAEmails,
    newVimcalEmails,
    openVimcalEASeats,
    openVimcalSeats,
    vimcalEASeats,
  });

  const vimcalUnitPrice = useMemo(() => {
    const unitPrice = getStripeCostVimcalCoreUnitPrice(stripeCost);
    if (unitPrice) {
      return unitPrice;
    }

    return DOLLARS_IN_CENTS * (isPayingMonthly ? NON_EA_MONTHLY_PRICE : NON_EA_ANNUAL_PRICE);
  }, [isPayingMonthly, stripeCost]);

  const vimcalEAUnitPrice = useMemo(() => {
    const unitPrice = getStripeCostVimcalEAUnitPrice(stripeCost);
    if (unitPrice) {
      return unitPrice;
    }

    return DOLLARS_IN_CENTS * (isPayingMonthly ? EA_MONTHLY_PRICE : EA_ANNUAL_PRICE);
  }, [isPayingMonthly, stripeCost]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickSaveAddMoreEmptySeats = ({
    defaultVimcalSeats,
    vimcalEASeats,
  }) => {
    setDefaultVimcalSeats(defaultVimcalSeats);
    setVimcalEASeats(vimcalEASeats);
  };

  const onClickNext = useCallback(() => {
    if (isFreeTrial) {
      inviteTeamMembers({ vimcalEAEmails, vimcalEmails })
        .then(closePlanModal)
        .catch(_.noop); // Errors are already handled.
    } else {
      nextPage();
    }
  }, [closePlanModal, isFreeTrial, nextPage, vimcalEAEmails, vimcalEmails]);

  const renderModal = () => {
    return (
      <EventModalPopup
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        width={230}
        title={"Add more seats"}
        style={determineDefaultModalStyle(isDarkMode)}
      >
        <AddEmptySeats
          areEAsPresent={areEAsPresent}
          onClickSave={onClickSaveAddMoreEmptySeats}
          currentDefaultVimcalSeats={defaultVimcalSeats}
          currentVimcalEASeats={vimcalEASeats}
          onClose={closeModal}
          vimcalEAUnitPrice={vimcalEAUnitPrice}
          vimcalUnitPrice={vimcalUnitPrice}
        />
      </EventModalPopup>
    );
  };

  const isDisabledGoToNextStep = () => {
    if (isFixedSeating && isAddingSeats) {
      return true;
    }

    if (isSubmittingInvites) {
      return true;
    }

    return isEmptyObjectOrFalsey(emailPlanDictionary) &&
      vimcalEASeats === 0 &&
      defaultVimcalSeats === 0;
  };

  return (
    <>
      {showHeader ? (
        <div className="group-scheduling-team-plans-section-header mb-2">
          {isFixedSeating ? "Invite users to your team plan" : "Add more seats to your team"}
        </div>
      ) : null}
      {(isFreeTrial || !areEAsPresent) ? null : (
        <SeatSummary
          isPayingMonthly={isPayingMonthly}
          stripeCost={stripeCost}
          newVimcalEAEmails={newVimcalEAEmails}
          newVimcalEmails={newVimcalEmails}
          vimcalEAUnitPrice={vimcalEAUnitPrice}
          vimcalUnitPrice={vimcalUnitPrice}
        />
      )}

      <div className="mb-2 flex items-center">
        Email Invite
        {isFetchingEligibility ? (
          <div className="ml-2">
            <CheckBoxSpinner
              className="group-scheduling-team-plans-review-spinner"
              color={getDefaultFontColor(isDarkMode)}
              hideRightSidePadding={true}
              hideTopMargin={true}
              size={12}
            />
          </div>
        ) : null}
      </div>

      <NewMemberInput
        areEAsPresent={areEAsPresent}
        defaultVimcalSeats={defaultVimcalSeats}
        emailPlanDictionary={emailPlanDictionary}
        hasCheckedInitialEmail={hasCheckedAttendeeForModal}
        initialEmailToCheck={getAttendeeEmail(attendeeForModal)}
        setDefaultVimcalSeats={setDefaultVimcalSeats}
        setEmailPlanDictionary={setEmailPlanDictionary}
        setHasCheckedInitialEmail={setHasCheckedAttendeeForModal}
        setIsFetchingEligibility={setIsFetchingEligibility}
        setVimcalEASeats={setVimcalEASeats}
        vimcalEASeats={vimcalEASeats}
      />

      {(isFixedSeating || isFreeTrial) ? null : (
        <div
          className="flex items-center gap-2 text-color-link mb-5 cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <Plus size={14} />
          <div>Add more seats</div>
        </div>
      )}
      <ShareLink
        shareToken={shareToken}
        setShareToken={setShareToken}
      />

      <div
        className={classNames(
          "group-scheduling-team-plans-next-button flex items-center justify-center",
          isDisabledGoToNextStep()
            ? "opacity-75 pointer-events-none cursor-not-allowed"
            : "",
        )}
        onClick={onClickNext}
      >
        {isFreeTrial
          ? (isSubmittingInvites ? <SpinnerV2 variant="small" /> : "Send Invites")
          : "Next: Payment"}
      </div>
      {renderModal()}
    </>
  );
}
