import classNames from "classnames";
import React from "react";
import VimcalHoverableLogo from "./logo/vimcalHoverableLogo";

export default function HoverableLogo({
  containerClassName,
  onClick,
  customCopy,
}) {
  const onClickLogo = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames(
        "font-size-14 flex flex-col items-center justify-center",
        "cursor-pointer",
        "light-mode-hoverable-secondary-text-fill",
        "light-mode-hoverable-secondary-text",
        "select-none",
        containerClassName ?? "mt-5"
      )}
      onClick={onClickLogo}
    >
      <VimcalHoverableLogo />

      <div className="mt-2 font-size-12">
        {customCopy ?? "The world's fastest calendar"}
      </div>
    </div>
  );
}
