import React, { useState } from "react";
import { useSelector } from "react-redux";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { getUserToken } from "../../lib/userFunctions";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import CustomButtonV2 from "../buttons/customButtonV2";
import EventModalPopup from "../eventModalPopup";
import { removeTeamMember } from "../queries/teamPlans";
import { getDisplayName } from "./sharedFunctions";

interface RemoveMemberModalProps {
  isOpen: boolean
  onRequestClose: () => void
  removedTeammate: TeamPlanMember | undefined
}

export default function RemoveMemberModal({ isOpen, onRequestClose, removedTeammate }: RemoveMemberModalProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);
  const currentUser = useSelector(state => state.currentUser);
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [showError, setShowError] = useState(false);

  const onClickConfirmDelete = () => {
    if (!removedTeammate || !currentUser) {
      return;
    }

    setShowError(false);
    setIsRemovingMember(true);

    removeTeamMember(currentUser, getUserToken(removedTeammate)).then(() => {
      onRequestClose();
    }).catch(() => {
      setShowError(true);
    }).finally(() => {
      setIsRemovingMember(false);
    });
  };

  return (
    <EventModalPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldFreeze={isRemovingMember}
      width={400}
      title="Confirm removal"
      style={determineDefaultModalStyle(isDarkMode)}
    >
      <div>
        <div className="default-font-size">
          {`Are you sure you want to remove ${getDisplayName(
            removedTeammate,
          ) || "this member"} from your team plan?`}
        </div>
        {showError ? (
          <div className="default-font-size warning-color mt-2">There was an error removing this member.</div>
        ) : null}
        <div className="flex justify-end mt-8 gap-2.5">
          <CustomButtonV2
            buttonType={WHITE_BUTTON}
            disabled={isRemovingMember}
            onClick={onRequestClose}
            label="Cancel"
          />

          <CustomButtonV2
            buttonType={BLUE_BUTTON}
            autoFocus
            onClick={onClickConfirmDelete}
            shouldRenderSpinner={isRemovingMember}
            label="Confirm"
          />
        </div>
      </div>
    </EventModalPopup>
  );
}
