import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import MaestroCard from "./maestroCard";
import { MAESTRO_ACCOUNT_TYPES, MAESTRO_NEW_USER_PAGES } from "../../../services/globalMaestroVariables";
import { NEW_EA_LOGIN_PATH } from "../../../services/maestro/maestroRouting";
import { canUseMagicLinks } from "../../../lib/featureFlagFunctions";

const CardSelectOne = ({ history, setMaestroAuthPage }) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <>
      {/* Login as self (delegation: null) */}
      <MaestroCard
        onClick={() => history.push(`/${NEW_EA_LOGIN_PATH}?accountType=` + MAESTRO_ACCOUNT_TYPES.MYSELF)}
        text="Myself"
      />
      {/* Login as another user (magic link or delegation: limited_access) */}
      <MaestroCard
        onClick={() => {
          if (canUseMagicLinks(currentUser)) {
            setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO);
            return;
          }

          /* Temporarily gate full access -> Force limited */
          setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM);
        }}
        shouldAddLeftMargin={true}
        text="My executive"
      />
    </>
  );
};

export default withRouter(CardSelectOne);
