import React from "react";
import { getTeamPlanPhotoUrl, getTeamPlanTeamName } from "../../../lib/teamPlanFunctions";
import { useTeamPlan } from "../../../services/stores/userData";
import { TEAM_SETTING_LABEL_CLASS_NAME } from "./sharedFunctions";

/**
 * The member settings show the public settings for the team plan like the name and profile photo.
 * This is read-only since members cannot change these properties.
 */
export default function MemberSettings() {
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const teamPlanPhoto = getTeamPlanPhotoUrl(teamPlan);
  const teamPlanName = getTeamPlanTeamName(teamPlan);

  return (
    <>
      {teamPlanPhoto ? (
        <>
          <div className={TEAM_SETTING_LABEL_CLASS_NAME}>Photo</div>
          <img
            className="rounded-full width-75px height-75px mb-5 default-bottom-left-modal-border"
            src={teamPlanPhoto}
            alt="Profile"
          />
        </>
      ) : null}

      {teamPlanName ? (
        <>
          <div className={TEAM_SETTING_LABEL_CLASS_NAME}>Name</div>
          <div className="font-size-14">{teamPlanName}</div>
        </>
      ) : null}
    </>
  );
}
