import React, { useState } from "react";
import CustomButton from "./customButton";
import Checkbox from "./checkbox";
import { CustomQuestionType } from "./customQuestions.tsx";
import CustomSelect from "./select";
import StyleConstants, {
  BLUE_BUTTON,
  WHITE_BUTTON,
} from "../services/globalVariables";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getInputStringFromEvent } from "../lib/stringFunctions";

const CustomQuestionOptions = [
  { value: CustomQuestionType.SingleLine, label: "Single Line" },
  { value: CustomQuestionType.Multiline, label: "Multi-Line" },
  { value: CustomQuestionType.PhoneNumber, label: "Phone Number" },
];

export const ModifyCustomQuestionModal = ({
  closeModal,
  applyChange,
  selectedCustomQuestion,
  isAdd,
}) => {
  const [description, setDescription] = useState(
    selectedCustomQuestion?.description || ""
  );
  const [required, setRequired] = useState(
    selectedCustomQuestion?.required || false
  );
  const [option, setOption] = useState(
    CustomQuestionOptions.find(
      (option) => option.value === selectedCustomQuestion?.type
    ) || CustomQuestionOptions[0]
  );
  const [errorMessage, setErrorMessage] = useState("");
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onClickApply = () => {
    if (!description) {
      setErrorMessage("Question description cannot be empty.");
    } else {
      applyChange({
        type: option.value,
        required,
        description,
      });
      closeModal();
    }
  };

  return (
    <div className="display-flex-flex-direction-col">
      <div className="py-1 default-font-size">Question</div>

      <textarea
        className="custom-question-input-container"
        onChange={(e) => setDescription(getInputStringFromEvent(e))}
        value={description}
      />

      {errorMessage && (
        <div className="text-red-400 default-font-size mt-1">{errorMessage}</div>
      )}

      <div className="py-2 display-flex-flex-direction-row">
        <Checkbox
          isChecked={required}
          onChange={() => setRequired(!required)}
        />
        <div
          className="px-1 cursor-pointer default-font-size select-none"
          style={{
            color: isDarkMode ? StyleConstants.darkModeTextColor : "grey",
          }}
          onClick={() => setRequired(!required)}
        >
          Required
        </div>
      </div>

      <div className="py-1 mt-5 default-font-size">Answer Type</div>
      <CustomSelect
        showBorder={true}
        className={classNames(
          "custom-question-select",
          isDarkMode ? "dark-mode-select" : "",
          "select-default-font-size"
        )}
        classNamePrefix="dark-mode-modal"
        onChange={(option) => setOption(option)}
        value={option}
        options={CustomQuestionOptions}
      />

      <div
        className="display-flex-flex-direction-row justify-end"
        style={{ marginTop: "85px" }}
      >
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={closeModal}
          label="Cancel"
        />

        <CustomButton
          buttonType={BLUE_BUTTON}
          shouldFocus={true}
          onClick={onClickApply}
          label={isAdd ? "Add" : "Apply"}
        />
      </div>
    </div>
  );
};
