import React from "react";
import {
  DARK_MODE_TEXT_COLOR,
  DEFAULT_FONT_COLOR,
} from "../../services/globalVariables";

interface MediumShortcutTileProps {
  text: string
  isLeftHandSide?: boolean
  width?: string | number
}

export default function MediumShortcutTile({ text, isLeftHandSide = false, width }: MediumShortcutTileProps) {
  const SIZE = 48;
  return (
    <div
      className="flex items-center justify-center select-none"
      style={{
        height: SIZE,
        width: width ?? SIZE,
        maxHeight: SIZE,
        minHeight: SIZE,
        fontSize: 28,
        fontWeight: 400,
        boxShadow: "rgb(0 0 0 / 12%) 0px 4px 0px",
        borderRadius: 8,
        marginRight: isLeftHandSide ? 10 : 0,
        backgroundColor: DEFAULT_FONT_COLOR,
      }}
    >
      <div
        className="flex justify-center items-center h-12"
        style={{
          color: DARK_MODE_TEXT_COLOR,
        }}
      >
        {text}
      </div>
    </div>
  );
}
