import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { SECOND_IN_MS } from "../services/globalVariables";

/**
 * TODO: Fix this component. The scrollbar always shows in Chrome, Safari, and Firefox.
 */
function HoverableScrollContainer({ children, inputClassName }) {
  const [scrolling, setScrolling] = useState(false);
  const containerRef = React.createRef();

  const handleScroll = () => {
    if (!scrolling) {
      setScrolling(true);
    }
    clearTimeout(window.scrollTimeout);
    window.scrollTimeout = setTimeout(() => {
      setScrolling(false);
    }, SECOND_IN_MS * 0.5);
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  return (
    <div
      className={classNames(
        inputClassName ?? "",
        "scroll-container",
        scrolling ? "scrolling" : "",
      )}
      ref={containerRef}
    >
      {children}
    </div>
  );
}

export default HoverableScrollContainer;
