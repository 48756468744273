import React, { useEffect, useState, useMemo } from "react";
import {
  loadTheme,
  hasStopEventPropagation,
  handleError,
  isElectron,
  splitDollarsAndCents,
} from "../../../services/commonUsefulFunctions";
import {
  DARK_MODE_THEME,
  EA_ANNUAL_PER_MONTH_PRICE,
  EA_MONTHLY_PRICE,
  LIGHT_MODE_THEME,
  NON_EA_ANNUAL_PRICE,
  NON_EA_MONTHLY_PRICE,
} from "../../../services/globalVariables";
import AppBroadcast from "../../../broadcasts/appBroadcast";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { MONTHLY, YEARLY } from "../../../lib/vimcalVariables";
import {
  VIMCAL_EA_TESTIMONIALS,
  VIMCAL_TESTIMONIALS,
} from "../sharedVariables";
import { ChevronLeft, ArrowRight } from "react-feather";
import {
  isAdminsDayDiscount,
  isFreeTrialOver,
  isPayingUser,
  isUserInOnboarding,
  isWinbackPromotion,
  isYCDiscount,
} from "../../../lib/stateManagementFunctions";
import { useHistory } from "react-router-dom";
import backendBroadcasts from "../../../broadcasts/backendBroadcasts";
import { constructRequestURL } from "../../../services/api";
import Fetcher from "../../../services/fetcher";
import { useIsMounted } from "../../../services/customHooks/useIsMounted";
import {
  usePromotionsStore,
  useSubscriptionStore,
} from "../../../services/stores/finance";
import useLatestMasterAccount from "../../../services/customHooks/useLatestMasterAccount";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import { isUnauthorizedPaymentRoute } from "../../../services/routingFunctions";
import { skipRerouteTrialOver } from "../../../lib/featureFlagFunctions";
import broadcast from "../../../broadcasts/broadcast";
import { isUserMaestroUser } from "../../../services/maestroFunctions";
import { triggerRefreshWithOnlineCheck } from "../../../services/appFunctions";
import { shouldShowDiscount } from "../../../lib/stripeFunctions";
import {
  ADMINS_DAY_DISCOUNT_COPY,
  WINBACK_DISCOUNT_COPY,
  YC_DISCOUNT_COPY,
} from "../../../lib/copy";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { BACKEND_BROADCAST_VALUES } from "../../../lib/broadcastValues";
import PlanOptionWrapper from "./planOptionWrapper";
import PaymentModal from "./paymentModal";
import LogoutButton from "./logoutButton";
import Carousel from "../../carousel/carousel";
import TestimonialContainer from "../../../../components/testimonialContainer";

interface PlanOptionProps {
  option: typeof MONTHLY | typeof YEARLY;
  isSelected: boolean;
  price: number;
  helperText: string;
  onClick: () => void;
}

function PlanOption({
  helperText,
  isSelected,
  onClick,
  option,
  price,
}: PlanOptionProps) {
  const [dollars, cents] = splitDollarsAndCents(price);
  const showCents = cents !== "00";

  return (
    <PlanOptionWrapper
      className="flex flex-col items-center justify-center trial-over-selection-box"
      isSelected={isSelected}
      onClick={onClick}
    >
      {option === YEARLY ? (
        <div
          className="w-full h-6 absolute top-0 border-top rounded-t-lg flex items-center justify-center text-white default-font-size font-weight-400"
          style={{
            backgroundImage:
              "linear-gradient(92.43deg, #D218C2 0%, #F50160 36.98%, #F72B56 65.1%, #FC8378 97.92%)",
          }}
        >
          Popular - Save ~17%
        </div>
      ) : null}
      <div className="default-font-size font-size-300">
        {option === MONTHLY ? "Monthly plan" : "Yearly plan"}
      </div>
      <div className="mt-2 flex relative">
        <div className="font-size-20 absolute -left-0.5 top-2.5 transform -translate-x-full">
          $
        </div>
        <div className="font-size-50">{dollars}</div>
        {showCents ? (
          <div className="font-size-20 absolute right-0 top-2.5 transform translate-x-full">
            .{cents}
          </div>
        ) : null}
      </div>
      <div className="default-font-size font-weight-300 color-medium-gray">
        {helperText}
      </div>
    </PlanOptionWrapper>
  );
}

interface IndividualTrialFinishedProps {
  freeTrialIsOver?: boolean;
  isInModal?: boolean;
}

export default function IndividualTrialFinished({
  freeTrialIsOver,
  isInModal,
}: IndividualTrialFinishedProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentOption, setPaymentPlan] = useState<
    typeof YEARLY | typeof MONTHLY
  >(YEARLY);

  const isDarkMode = useSelector((state) => state.isDarkMode);
  const isSubscribeRoute = useMemo(() => isUnauthorizedPaymentRoute(), []);
  const currentUser = useSelector((state) => state.currentUser);
  const isUnauthorized = useMemo(() => isEmptyObjectOrFalsey(currentUser), []);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const unappliedPromotions = usePromotionsStore(
    (state) => state.unappliedPromotions
  );
  const subscription = useSubscriptionStore((state) => state.subscription);
  const [referral, setReferral] = useState<Referral>();
  const [hasStartedLoadingStripe, setStartLoadingStripe] = useState(false);
  const showDiscount = shouldShowDiscount({
    promotions: unappliedPromotions,
    referral,
  });
  const componentIsMounted = useIsMounted();
  const [showThankYouPage, setShowThankYouPage] = useState(false);

  const history = useHistory();

  const [getLatestMasterAccount] = useLatestMasterAccount();
  const handleUpdatedMasterAccount = (updatedMasterAccount: MasterAccount) => {
    if (skipRerouteTrialOver(currentUser)) {
      // do nothing
    } else if (isPayingUser(updatedMasterAccount)) {
      GoToVimcalWithRefresh();
    }
  };

  const GoToVimcalWithRefresh = () => {
    if (isInModal) {
      return;
    }

    history.push("/home?ref=r");
  };

  useEffect(() => {
    // Prefetch the billing since we need it to display the actual price owed
    // in the payment method modal.
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);

    if (isInModal) {
      // do nothing
      if (skipRerouteTrialOver(currentUser)) {
        // do nothing
      } else if (isPayingUser(masterAccount)) {
        // remove modal
        // broadcast.publish("CLOSE_LAYOUT_MODAL");
      }
    } else if (isSubscribeRoute) {
      // do nothing -> do not reroute
    } else if (isEmptyObjectOrFalsey(currentUser)) {
      // logged out -> go to
      history.push("/login");
      return;
    } else if (skipRerouteTrialOver(currentUser)) {
      // do nothing
    } else if (isPayingUser(masterAccount)) {
      history.push("/home");
      return;
    }

    if (!isUnauthorized && !isSubscribeRoute) {
      getLatestMasterAccount(handleUpdatedMasterAccount);
      backendBroadcasts.publish("CHECK_FOR_PROMOTIONS_UNAPPLIED");
      checkForReferral();
      getSubscriptionDetails();
    }

    if (isUnauthorized) {
      // if unauthorized -> always default to light mode
      loadTheme(LIGHT_MODE_THEME);
      AppBroadcast.publish("SET_APP_LIGHT_MODE");
    } else if (isDarkMode) {
      loadTheme(DARK_MODE_THEME);
      AppBroadcast.publish("SET_APP_DARK_MODE");
    } else {
      loadTheme(LIGHT_MODE_THEME);
      AppBroadcast.publish("SET_APP_LIGHT_MODE");
    }
  }, []);

  const getSubscriptionDetails = () => {
    backendBroadcasts.publish("GET_SUBSCRIPTION_DETAILS");
  };

  const checkForReferral = () => {
    if (isEmptyObjectOrFalsey(currentUser)) {
      return;
    }

    const url = constructRequestURL("referrals");
    Fetcher.get(url, {}, true, currentUser.email)
      .then((result) => {
        if (!componentIsMounted?.current || isEmptyObjectOrFalsey(result)) {
          return;
        }

        setReferral(result);
      })
      .catch(handleError);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (showThankYouPage && isInModal) {
      broadcast.publish("CLOSE_LAYOUT_MODAL");
      triggerRefreshWithOnlineCheck();
    }
  };

  const renderBackButton = () => {
    if (isInModal) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute flex items-center justify-center rounded-full w-10 h-10 duration-200 cursor-pointer top-16 left-16",
          "hoverable-secondary-background-color"
        )}
        onClick={(e) => {
          if (isFreeTrialOver(masterAccount, subscription)) {
            AppBroadcast.publish("CLICK_LOG_OUT");
            return;
          }

          hasStopEventPropagation(e);
          if (isElectron() && hasStartedLoadingStripe) {
            GoToVimcalWithRefresh();
          } else {
            history.push("/home");
          }
        }}
      >
        <ChevronLeft size={24} color="white" />
      </div>
    );
  };

  const renderPlanSelection = () => {
    return (
      <div className="flex flex-row mt-4 justify-center gap-8">
        <PlanOption
          option={MONTHLY}
          helperText="per month"
          onClick={() => setPaymentPlan(MONTHLY)}
          isSelected={paymentOption === MONTHLY}
          price={
            isUserMaestroUser(masterAccount)
              ? EA_MONTHLY_PRICE
              : NON_EA_MONTHLY_PRICE
          }
        />
        <PlanOption
          option={YEARLY}
          helperText={
            isUserMaestroUser(masterAccount)
              ? "per month (billed annually)"
              : "per year"
          }
          onClick={() => setPaymentPlan(YEARLY)}
          isSelected={paymentOption === YEARLY}
          price={
            isUserMaestroUser(masterAccount)
              ? EA_ANNUAL_PER_MONTH_PRICE
              : NON_EA_ANNUAL_PRICE
          }
        />
      </div>
    );
  };

  const renderGetStartedButton = () => {
    return (
      <div
        className={classNames("mt-8", "selected-plan-get-started")}
        onClick={(e) => {
          hasStopEventPropagation(e);
          setIsModalOpen(true);
          setStartLoadingStripe(true);
        }}
      >
        Get Started
        <ArrowRight className="color-white ml-3" size={16} />
      </div>
    );
  };

  const renderPromotionalBanner = () => {
    if (!showDiscount) {
      return null;
    }
    const getCopy = () => {
      if (isYCDiscount(unappliedPromotions)) {
        return YC_DISCOUNT_COPY;
      }
      if (isAdminsDayDiscount(unappliedPromotions)) {
        return ADMINS_DAY_DISCOUNT_COPY;
      }
      if (isWinbackPromotion(unappliedPromotions)) {
        return WINBACK_DISCOUNT_COPY;
      }
      return "Remember, you'll be credited a free month upon subscribing!";
    };
    return (
      <div className="welcome-subtitle display-flex-flex-direction-row text-center justify-center font-size-12-important italic mt-2">
        {getCopy()}
      </div>
    );
  };

  const renderTitle = () => {
    if (isUnauthorized || isSubscribeRoute) {
      return (
        <>
          <div className="text-3xl font-normal text-center text-white">
            {"Join thousands of CEOs, investors, and other busy folks"}
          </div>
          <div className="text-3xl font-normal text-center text-white">
            who trust Vimcal to manage their time.
          </div>
        </>
      );
    }

    const determineTitle = () => {
      if (isWinbackPromotion(unappliedPromotions)) {
        return "Welcome back!";
      }

      return isUserInOnboarding(masterAccount)
        ? "We hope you enjoyed your onboarding!"
        : "We hope you enjoyed using Vimcal!";
    };

    return (
      <>
        <div
          className={classNames(
            "text-3xl font-normal text-center text-white",
            isInModal ? "mt-5" : ""
          )}
        >
          {determineTitle()}
        </div>
        <div className="welcome-subtitle mt-3 display-flex-flex-direction-row text-center justify-center font-size-12-important">
          {
            "Join thousands of CEOs, investors, and others who trust Vimcal to manage their time."
          }
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames(
        "overflow-y-auto flex flex-col trial-over-background-gradient",
        isInModal ? "" : "height-100vh"
      )}
      style={{ width: isInModal ? 900 : undefined }}
    >
      <div
        className={classNames(
          isInModal
            ? "flex flex-col items-center justify-center"
            : "onboarding-container",
          "pt-2.5 pb-2.5"
        )}
        style={{ minHeight: isInModal ? undefined : "900px" }}
      >
        {isUnauthorized ? null : renderBackButton()}
        {renderTitle()}
        <div
          className="font-size-14 font-weight-400 mt-8"
          style={{ color: "#B9B5C0" }}
        >
          Choose your plan
        </div>
        {renderPromotionalBanner()}
        {renderPlanSelection()}
        {renderGetStartedButton()}
        {freeTrialIsOver ? <LogoutButton /> : null}

        <CarouselWrapper isInModal={isInModal} />

        <PaymentModal
          closeModal={closeModal}
          isInModal={isInModal}
          isOpen={isModalOpen}
          paymentOption={paymentOption}
          referral={referral}
          showThankYouPage={showThankYouPage}
          setShowThankYouPage={setShowThankYouPage}
        />
      </div>
    </div>
  );
}

interface CarouselWrapperProps {
  isInModal?: boolean;
}

function CarouselWrapper({ isInModal }: CarouselWrapperProps) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const testimonials = isUserMaestroUser(masterAccount)
    ? VIMCAL_EA_TESTIMONIALS
    : VIMCAL_TESTIMONIALS;
  const slides = useMemo(() => {
    return testimonials.map((testimonial) => {
      return (
        <TestimonialContainer
          testimonial={testimonial}
          key={testimonial.name}
        />
      );
    });
  }, []);

  return (
    <div
      className={classNames(
        "welcome-back-tweets select-none",
        isInModal ? "mt-4" : "mt-8"
      )}
    >
      <Carousel
        gap={28}
        slideSize={{ width: 384, height: isUserMaestroUser(masterAccount) ? 208 : 164 }}
        slides={slides}
      />
    </div>
  );
}
