import { StoredCalendar } from "../../services/stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { AUDIT_COLUMN_TYPES, CALENDAR_AUDIT_MODAL_CONTENT } from "./variables";

export type AuditColumn = {
  hideable: boolean;
  hidden: boolean;
  label: string;
  resizable: boolean;
  type: ValueOf<typeof AUDIT_COLUMN_TYPES>;
};

export type AuditColumnWidth = {
  [type in ValueOf<typeof AUDIT_COLUMN_TYPES>]: number;
};

export type AuditModal = ValueOf<typeof CALENDAR_AUDIT_MODAL_CONTENT> | null;
export type AuditModalData = {
  label: string;
  value: StoredCalendar | undefined;
} | null;

export type ConfirmationModalData = {
  content: string;
  label: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

export type GroupedRecurrence = {
  isGroupedRecurrence: true;
  masterEventID: string;
};

export type MasterEventRecurrences = {
  [masterEventID: string]: string;
};

export type OnePagerData = {
  endDate: Date;
  minsSaved30Days: number;
  name: string;
  proposedChanges: {
    title: string;
    eventStart: Date;
    eventEnd: Date;
    attendeeEmails: string[];
    originalDurationMins: number;
    proposedDurationMins: number;
    originalFrequency: string | null;
    proposedFrequency: string;
    minsSavedOver30Days: number;
  };
  startDate: Date;
};

export type ProposedChanges = {
  [uniqueEtag: string]: {
    duration: number;
    frequency: string;
    frequencyLabel: string;
  };
};

export type EventToRender = GroupedRecurrence | VimcalEvent | number;

export function getAuditColumnIsHidden(column: AuditColumn) {
  if (isEmptyObjectOrFalsey(column)) {
    return true;
  }

  return column.hidden;
}

export function getAuditColumnLabel(column: AuditColumn) {
  if (isEmptyObjectOrFalsey(column)) {
    return "";
  }

  return column.label;
}

export function getAuditColumnResizable(column: AuditColumn) {
  if (isEmptyObjectOrFalsey(column)) {
    return false;
  }

  return column.resizable;
}

export function getAuditColumnType(column: AuditColumn) {
  if (isEmptyObjectOrFalsey(column)) {
    return "";
  }

  return column.type;
}

export function isTypeOfGroupedRecurrence(
  argument: any,
): argument is GroupedRecurrence {
  return (
    argument !== undefined &&
    argument?.isGroupedRecurrence === true &&
    typeof argument?.masterEventID === "string"
  );
}
