import React, { useState, forwardRef } from "react";
import Creatable from "react-select/creatable";

const CreatableSelect = forwardRef(
  (
    {
      isSearchable,
      placeholder,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const shouldRenderValue = () => {
      if (isSearchable === false) {
        // default is true
        return true;
      }
      return !isFocused;
    };

    return (
      <Creatable
        {...props}
        placeholder={placeholder || ""}
        ref={ref}
        blurInputOnSelect={true}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }
          setIsFocused(true);
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setIsFocused(false);
        }}
        controlShouldRenderValue={shouldRenderValue()}
      />
    );
  },
);

CreatableSelect.displayName = "CreatableSelect";

export default CreatableSelect;
