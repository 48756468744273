import React, { useState } from "react";
import DropZone from "react-dropzone";
import Broadcast from "../broadcasts/broadcast";
import {
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  TYPE_JPEG,
  TYPE_PNG,
  TYPE_TEXT_CALENDAR,
} from "../services/globalVariables";
import { isEmailAttendeesModalOpen, isReverseSlotsModalOpen } from "../services/appFunctions";
import pasteBroadcast from "../broadcasts/pasteBroadcast";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import emailBroadcast from "../broadcasts/emailBroadcast";
import { useSelector } from "react-redux";
import { shouldHideAIFeatures } from "../lib/featureFlagFunctions";
import { BROADCAST_VALUES } from "../lib/broadcastValues";

// https://stackoverflow.com/questions/63181345/is-it-possible-to-implement-a-fullscreen-react-dropzone-on-top-of-everything-whi
// https://github.com/react-dropzone/react-dropzone/issues/753

const DropZoneContainer = ({ children }) => {
  const [shouldDisplayLayover, setLayover] = useState(false);
  const reverseSlotsPermission = usePermissionsStore(
    (state) => state.reverseSlotsPermission
  );
  const saveOriginalReverseSlotsImage = useTemporaryStateStore(
    (state) => state.saveOriginalReverseSlotsImage
  );
  const currentUser = useSelector((state) => state.currentUser);
  const shouldShowAIFeature = reverseSlotsPermission && !shouldHideAIFeatures(currentUser);

  const onChangeUpload = (files) => {
    setLayover(false);
    if (!files || !files[0]) {
      return;
    }

    if (isEmailAttendeesModalOpen()) {
      emailBroadcast.publish("UPLOAD_EMAIL_FILES", files);
      return;
    }

    const file = files[0];
    if (file.type === TYPE_TEXT_CALENDAR) {
      Broadcast.publish(BROADCAST_VALUES.PARSE_ICS_FILE, file);
    } else if (file.type === TYPE_PNG || file.type === TYPE_JPEG) {
      if (!shouldShowAIFeature) {
        return;
      }

      const reader = new FileReader();
      layoutBroadcast.publish("UPLOAD_AI_SCHEDULER", {
        isLoading: true,
      });
      reader.onload = (event) => {
        const pasteImage = event.target.result;
        saveOriginalReverseSlotsImage(pasteImage);
        pasteBroadcast.publish("HANDLE_UPLOADED_IMAGE", pasteImage);
      };
      reader.readAsDataURL(file);
    }
  };

  const getAcceptProperties = () => {
    if (isEmailAttendeesModalOpen()) {
      return "";
    }
    if (!shouldShowAIFeature) {
      return [TYPE_TEXT_CALENDAR];
    }
    return [TYPE_TEXT_CALENDAR, TYPE_PNG, TYPE_JPEG];
  };

  return (
    <DropZone
      disableClick
      noClick={true}
      noKeyboard={true}
      onDrop={onChangeUpload}
      maxSize={3000000} // 3MB for email
      onDragEnter={() => {
        // if it's the reverse slots modal -> remove
        if (isReverseSlotsModalOpen()) {
          Broadcast.publish("CLOSE_LAYOUT_MODAL");
        }
        setLayover(true);
      }}
      onDragLeave={() => {
        setLayover(false);
      }}
      multiple={isEmailAttendeesModalOpen()}
      noDragEventsBubbling={true}
      accept={getAcceptProperties()}
      onDropAccepted={() => setLayover(false)}
      onDropRejected={(fileRejections) => {
        if (isEmailAttendeesModalOpen()) {
          emailBroadcast.publish("UPLOAD_EMAIL_FILES_REJECTED");
          return;
        }
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Please only upload .ics files"
        );
        setLayover(false);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          {shouldDisplayLayover ? (
            <div
              id="layout-cover-over-lay"
              className="layout-shortcuts-legend-cover mt-0 flex justify-center items-center small-blur"
            >
              <div className="drag-drop-container flex flex-col">
                <div>
                  {shouldShowAIFeature
                    ? "Drop an image for Free Time Finder"
                    : "Drag and drop .ics files"}
                </div>
                {shouldShowAIFeature ? (
                  <div className="mt-2">
                    or an .ics file to add events to your calendar
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <input {...getInputProps()} hidden />
          {children}
        </div>
      )}
    </DropZone>
  );
};

export default DropZoneContainer;
