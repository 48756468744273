import React from "react";

const DoorNotFilled = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="14"
      rx="1.5"
      stroke={color ?? "#323232"}
    />
    <path d="M11 0.5V14.5" stroke={color ?? "#323232"} />
  </svg>
);

export default DoorNotFilled;
