import React from "react";
import appBroadcast from "../../../broadcasts/appBroadcast";
import { APP_BROADCAST_VALUES } from "../../../lib/broadcastValues";

export default function LogoutButton() {
  return (
    <div
      className="hoverable-secondary-text-color default-font-size mt-3 text-center"
      onClick={() => appBroadcast.publish(APP_BROADCAST_VALUES.CLICK_LOG_OUT)}
    >
      Log out of all accounts
    </div>
  );
}
