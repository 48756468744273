import React from "react";
import { useSelector } from "react-redux";
import broadcast from "../../../broadcasts/broadcast";
import { isWindows, OpenLink } from "../../../services/commonUsefulFunctions";
import {
  DOWNLOAD_INTEL_DESKTOP_LINK,
  DOWNLOAD_M1_DESKTOP_LINK,
  DOWNLOAD_PC_DESKTOP_LINK,
} from "../../../services/globalVariables";
import { useDispatch } from "react-redux";
import { isMacMSeriesSync } from "../../../services/appFunctions";

const SideMenuBarDownloadSection = () => {
  const isMac = useSelector((state) => state.isMac);
  const dispatch = useDispatch();

  const renderDownloadContent = () => {
    if (isMac) {
      return (
        <>
          <div
            className="side-menu-toolbar-list-item"
            onClick={() => OpenLink(isMacMSeriesSync() ? DOWNLOAD_M1_DESKTOP_LINK : DOWNLOAD_INTEL_DESKTOP_LINK)}
          >
            <div className="side-menu-description-text">
              Mac Desktop App
            </div>
          </div>
        </>
      );
    } else if (isWindows()) {
      return (
        <div
          className="side-menu-toolbar-list-item"
          onClick={() => OpenLink(DOWNLOAD_PC_DESKTOP_LINK)}
        >
          <div className="side-menu-description-text">
            Desktop App (Windows)
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const downloadMobile = () => {
    const openIOSModal = () => {
      broadcast.publish("OPEN_DOWNLOAD_IOS_MODAL");
      dispatch({ type: "SET_SHOULD_DISPLAY_MENU", data: false });
    };

    return (
      <div className="side-menu-toolbar-list-item" onClick={openIOSModal}>
        <div className="side-menu-description-text">Mobile (iOS)</div>
      </div>
    );
  };

  const downloadChromeExtension = () => {
    return (
      <div
        className="side-menu-toolbar-list-item"
        onClick={() =>
          OpenLink(
            "https://chrome.google.com/webstore/detail/vimcal/akopimcimmdmklcmegcflfidpfegngke?hl=en-US"
          )
        }
      >
        <div className="side-menu-description-text">Chrome Extension</div>
      </div>
    );
  };
  return (
    <div className="side-menu-tool-bar-section-wrapper">
      <div className="side-menu-tool-bar-text">Downloads</div>

      {downloadMobile()}
      {renderDownloadContent()}
      {/* {downloadChromeExtension()} */}
    </div>
  );
};

export default SideMenuBarDownloadSection;
