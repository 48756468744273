import React from "react";
import { useSelector } from "react-redux";
import {
  OpenLink,
  determineGoogleSettingsLink,
} from "../services/commonUsefulFunctions";

export default function GoogleCalendarSettingsModal() {
  const currentUser = useSelector((state) => state.currentUser);

  const renderGoogleSettingsList = () => {
    // sample list of settings:
    // autoAddHangouts: "true"
    // dateFieldOrder: "DMY"
    // defaultEventLength: "25"
    // format24HourTime: "false"
    // hideInvitations: "false"
    // hideWeekends: "false"
    // locale: "en"
    // remindOnRespondedEventsOnly: "false"
    // showDeclinedEvents: "false"
    // timezone: "America/New_York"
    // useKeyboardShortcuts: "true"
    // weekStart: "1"

    const settingsList = [
      "Notifications time",
    ];
    return settingsList.map((s, index) => {
      return (
        <li key={`google-setting-${index}`} className="mb-1">
          {s}
        </li>
      );
    });
  };

  return (
    <div className="default-font-size">
      <div>Vimcal takes the following settings directly from Google:</div>

      <div className="mt-3 mb-3 ml-6">{renderGoogleSettingsList()}</div>

      <div>
        To edit any of these settings, please visit{" "}
        <a
          href={determineGoogleSettingsLink(currentUser.email)}
          onClick={() =>
            OpenLink(determineGoogleSettingsLink(currentUser.email))
          }
        >
          Google Calendar settings
        </a>{" "}
        and then reload Vimcal.
      </div>
    </div>
  );
}
