import React from "react";
import CheckBox from "../checkbox";
import classNames from "classnames";
import { BUTTON_TYPE } from "../../services/appFunctions";
import RadioButton from "../radioButton";

export default function SelectAnswer({
  label,
  onClick,
  isSelected,
  buttonType = BUTTON_TYPE.SQUARE_BUTTON,
}) {
  return (
    <div className="flex items-center">
      {buttonType === BUTTON_TYPE.RADIO_BUTTON ? (
        <RadioButton isSelected={isSelected} onClick={onClick} />
      ) : (
        <CheckBox isChecked={isSelected} onChange={onClick} />
      )}
      <div
        className={classNames("ml-2 cursor-pointer default-font-size select-none")}
        onClick={onClick}
      >
        {label}
      </div>
    </div>
  );
}
