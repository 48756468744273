import React, { Component } from "react";
import { connect } from "react-redux";
import ApiClient from "../../../../services/apiClient";
import Broadcast from "../../../../broadcasts/broadcast";
import {
  BLUE_BUTTON,
  ZOOM_PERSONAL_LINK,
  ZOOM_UNIQUE,
  MEDIUM_GRAY,
  WHITE_BUTTON,
} from "../../../../services/globalVariables";
import CustomButton from "../../../customButton";
import { getZoomPersonalLink, isDefaultZoomPersonalLink } from "../../../../lib/conferencing";
import DisabledButton from "../../../disabledButton";
import ZoomLoginButton from "../../../zoomLoginButton";
import VerifiedCheck from "./verifiedCheck";
import Fetcher from "../../../../services/fetcher";
import { constructRequestURL } from "../../../../services/api";
import {
  handleError,
  hasEventPreventDefault,
} from "../../../../services/commonUsefulFunctions";
import AppBroadcast from "../../../../broadcasts/appBroadcast";
import {
  useMasterAccount,
  useZoomSchedulers,
} from "../../../../services/stores/SharedAccountData";
import {
  getDelegatedZoomPMI,
  isUserBeingScheduledFor,
  isUserMaestroUser,
} from "../../../../services/maestroFunctions";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import { getZoomSchedulers } from "../../../../services/zoomFunctions";
import {
  PERSONAL_ZOOM_LINK_INPUT_LABEL,
} from "../../../../lib/copy";
import EventModalPopup from "../../../eventModalPopup";
import MaestroZoomLoginInfo from "../../../modal/maestroZoomLoginInfo";
import classNames from "classnames";
import { getUserEmail } from "../../../../lib/userFunctions";
import { getInputStringFromEvent, isSameEmail } from "../../../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { APP_BROADCAST_VALUES, UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import { determineDefaultModalStyle } from "../../../../lib/modalFunctions";
import CustomSelectV2, { SELECT_VARIANTS } from "../../../select/selectV2";
import ZoomBlueIcon from "../../../icons/zoomBlueIcon";

const ALWAYS_USE_PERSONAL_LINK_OPTION = { label: "Use Personal Meeting Link", value: true };
const GENERATE_UNIQUE_ZOOM_LINKS_OPTION = { label: "Generate unique Zoom links", value: false };

class ZoomSettings extends Component {
  constructor(props) {
    super(props);

    const user = this.getUser();
    const zoomLink = user?.zoom_link ?? "";
    const shouldAlwaysUsePersonalLink = isDefaultZoomPersonalLink(user);

    this.state = {
      zoomLink,
      shouldDisplayErrorOnZoomLogin: false,
      personalLinkErrorMessage: null,
      shouldAlwaysUsePersonalLink,
      initialIsLoggedIntoZoom: user?.has_zoom_access,
      authHasError: false,
    };

    this.onChangePersonalLink = this.onChangePersonalLink.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onLoginZoom = this.onLoginZoom.bind(this);
    this.onLogoutZoom = this.onLogoutZoom.bind(this);
    this.showErrorOnZoomLogin = this.showErrorOnZoomLogin.bind(this);
    this.toggleUsePersonalLink = this.toggleUsePersonalLink.bind(this);
    this.onClickZoomLogin = this.onClickZoomLogin.bind(this);
    this.closeModal = this.closeModal.bind(this);

    Broadcast.subscribe("ERROR_ON_ZOOM_LOGIN", this.showErrorOnZoomLogin);
    Broadcast.subscribe(
      "TOGGLE_ALWAYS_USE_PERSONAL_LINK_SETTING",
      this.toggleUsePersonalLink,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.inputUser &&
      prevProps.inputUser &&
      (!isSameEmail(getUserEmail(this.props.inputUser), getUserEmail(prevProps.inputUser)) ||
        this.props.inputUser.id !== prevProps.inputUser.id)
    ) {
      this.checkZoomAuth();
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkZoomAuth();
  }

  componentWillUnmount() {
    this._isMounted = false;
    Broadcast.unsubscribe("ERROR_ON_ZOOM_LOGIN");
    Broadcast.unsubscribe("TOGGLE_ALWAYS_USE_PERSONAL_LINK_SETTING");
  }

  render() {
    const { masterAccount } = this.props.masterAccount;
    if (!this.isUserLoggedIn() && !getZoomPersonalLink({
      user: this.getUser(),
      schedulers: this.getZoomSchedulers(),
      masterAccount,
    })) {
      return (
        <div>
          <div className="rounded flex items-center justify-between w-full px-8 py-6 zoom-setting-login-section-background">
            <div className="flex items-center justify-center gap-2 default-font-size">
              <ZoomBlueIcon />
              <div>Add Zoom</div>
            </div>

            <CustomButton
              buttonType={WHITE_BUTTON}
              onClick={this.onClickZoomLogin}
              label="Connect"
            />

          </div>
          {this.renderModal()}
          {this.state.authHasError ? <div className="default-font-size mt-2 warning-color">
            Error connecting to Zoom
          </div> : null}
          {this.renderError()}
        </div>

      );
    }
    return this.renderLoggedInView();
  }

  renderLoggedInView() {
    return (
      <div className="width-100-percent">
        {this.renderUserDelegationMessage()}

        {this.renderPersonalZoomLink()}

        {this.renderPersonalZoomError()}

        {this.renderZoomAuthenticateSection()}

        {this.renderError()}

        {this.renderToggleUsePersonalLink()}

        {this.renderButtons()}
        {this.renderModal()}
      </div>
    );
  }

  onClickZoomLogin() {
    const { masterAccount } = this.props.masterAccount;
    if (isUserMaestroUser(masterAccount)) {
      this.setState({ shouldDisplayModal: true });
      return;
    }
    this.onLoginZoom();
  }

  onLoginZoom() {
    AppBroadcast.publish("AUTHENTICATE_ZOOM", this.getUser());
  }

  showErrorOnZoomLogin() {
    this.setState({ shouldDisplayErrorOnZoomLogin: true });
  }

  onLogoutZoom() {
    AppBroadcast.publish(APP_BROADCAST_VALUES.LOGOUT_ZOOM, getUserEmail(this.getUser()));
  }

  isUserBeingScheduledFor() {
    const { masterAccount } = this.props.masterAccount;
    return isUserBeingScheduledFor({
      user: this.getUser(),
      schedulers: this.getZoomSchedulers(),
      masterAccount,
    });
  }

  renderUserDelegationMessage() {
    if (!this.isUserBeingScheduledFor()) {
      return null;
    }
    return (
      <div className="zoom-delegated-message">
        {getUserEmail(this.getUser())}'s Zoom account is automatically connected as you
        have scheduling privilege on their behalf.
      </div>
    );
  }

  renderZoomLogOut() {
    return (
      <div className="display-flex-flex-direction-row items-center">
        <VerifiedCheck />

        <div className="default-font-size ml-1">Authenticated</div>

        {this.isUserBeingScheduledFor() ? null : (
          <>
            <div
              style={{
                width: 2,
                height: 18,
                backgroundColor: MEDIUM_GRAY,
                marginLeft: 10,
              }}
            ></div>

            <div
              className="default-font-size margin-left-10 cursor-pointer hoverable-text"
              onClick={this.onLogoutZoom}
            >
              Log out
            </div>
          </>
        )}
      </div>
    );
  }

  renderZoomAuthenticateSection() {
    return (
      <div className="display-flex-flex-direction-row align-items-center justify-content-space-between margin-top-twenty">
        <div className="default-font-size secondary-text-color">
          Zoom account
        </div>

        {this.determineZoomButton()}
      </div>
    );
  }

  determineZoomButton() {
    const user = this.getUser();
    if (this.state.authHasError) {
      return (
        <div className="display-flex flex-direction-column justify-content-flex-end">
          <ZoomLoginButton
            onClickLogin={this.onClickZoomLogin}
            containerClassName="margin-left-15"
            labelClassName="font-size-12"
            containerWidth={150}
            label="Re-authenticate"
          />

          <div className="default-font-size mt-2 warning-color">
            Error connecting to Zoom
          </div>
        </div>
      );
    } else if (this.isUserLoggedIn()) {
      return this.renderZoomLogOut();
    } else if (!user.has_zoom_access) {
      return <ZoomLoginButton onClickLogin={this.onClickZoomLogin} />;
    } else {
      return <ZoomLoginButton onClickLogin={this.onClickZoomLogin} />;
    }
  }

  isUserLoggedIn() {
    const user = this.getUser();
    return user?.has_zoom_access || this.isUserBeingScheduledFor();
  }

  renderError() {
    if (!this.state.shouldDisplayErrorOnZoomLogin) {
      return null;
    }

    return (
      <div
        className={classNames(
          "event-form-different-time-zone-warning",
          "default-font-size",
          "w-full",
          "flex justify-end",
        )}
      >
        Oh no! An error occured on Zoom login.
      </div>
    );
  }

  getZoomSchedulers() {
    return getZoomSchedulers(this.props.zoomSchedulers);
  }

  renderPersonalZoomLink() {
    const user = this.getUser();
    const isUserScheduledFor = this.isUserBeingScheduledFor();
    const delegatedPMI = isUserScheduledFor
      ? getDelegatedZoomPMI({ user, schedulers: this.getZoomSchedulers() })
      : null;

    return (
      <div className="display-flex-flex-direction-row items-start justify-content-space-between margin-top-ten">
        <div className="default-font-size secondary-text-color">
          {PERSONAL_ZOOM_LINK_INPUT_LABEL}
        </div>

        {isUserScheduledFor && !!delegatedPMI ? (
          <div className="default-font-size">
            {`https://zoom.us/j/${delegatedPMI}`}
          </div>
        ) : (
          <input
            value={this.state.zoomLink}
            placeholder="e.g. https://zoom.us/j/0123456789?pwd=123456"
            style={{ width: 300 }}
            onChange={this.onChangePersonalLink}
            className="default-input-field"
          />
        )}
      </div>
    );
  }

  renderToggleUsePersonalLink() {
    return (
      <div className="flex items-center justify-end mt-5">
        <CustomSelectV2
          className="w-52"
          value={this.state.shouldAlwaysUsePersonalLink ? ALWAYS_USE_PERSONAL_LINK_OPTION : GENERATE_UNIQUE_ZOOM_LINKS_OPTION}
          isSearchable={false}
          variant={SELECT_VARIANTS.OUTLINED}
          options={[
            ALWAYS_USE_PERSONAL_LINK_OPTION,
            GENERATE_UNIQUE_ZOOM_LINKS_OPTION,
          ]}
          onChange={(option) => this.toggleUsePersonalLink(option.value, false)}
        />
      </div>
    );
  }

  renderPersonalZoomError() {
    if (!this.state.personalLinkErrorMessage) {
      return null;
    }

    return (
      <div className="event-form-different-time-zone-warning default-font-size width-100-percent flex justify-end">
        {this.state.personalLinkErrorMessage}
      </div>
    );
  }

  renderButtons() {
    const { zoomLink, shouldAlwaysUsePersonalLink } = this.state;
    const { initialZoomLink, initialShouldAlwaysUsePersonalLink } =
      this.getUserInitialZoomData();

    const hasChanged =
      zoomLink !== initialZoomLink ||
      shouldAlwaysUsePersonalLink !== initialShouldAlwaysUsePersonalLink;

    return (
      <div className="display-flex-flex-direction-row-justify-content-flex-end mt-4">
        {hasChanged ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={this.onClickSave}
            label="Save"
          />
        ) : (
          <DisabledButton label="Save" />
        )}
      </div>
    );
  }

  onChangePersonalLink(e) {
    this.setState({ zoomLink: getInputStringFromEvent(e) });
  }

  getUserInitialZoomData() {
    const user = this.getUser();
    return {
      initialZoomLink: user?.zoom_link || "",
      initialShouldAlwaysUsePersonalLink: isDefaultZoomPersonalLink(user),
    };
  }

  onClickSave(e) {
    const user = this.getUser();
    const { zoomLink, shouldAlwaysUsePersonalLink } = this.state;

    if (zoomLink && zoomLink !== user.zoom_link) {
      const personalLinkErrorMessage = ApiClient.validateZoomLink(zoomLink);
      if (personalLinkErrorMessage) {
        this.setState({ personalLinkErrorMessage });
        hasEventPreventDefault(e);
        return;
      }
    }

    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");

    if ((zoomLink || "") !== (user.zoom_link || "")) {
      AppBroadcast.publish("UPDATE_ZOOM_LINK", {
        link: zoomLink,
        user: this.getUser(),
      });
    }

    if (isDefaultZoomPersonalLink(user) !== shouldAlwaysUsePersonalLink) {
      updateSettingsBroadcast.publish(
        UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
        {
          settings: {
            default_zoom_mode: shouldAlwaysUsePersonalLink ? ZOOM_PERSONAL_LINK : ZOOM_UNIQUE,
          },
          user: this.getUser(),
        },
      );
    }
  }

  toggleUsePersonalLink(action, changeInitial = false) {
    const updatedAction = !!action;
    if (changeInitial) {
      this.setState({
        shouldAlwaysUsePersonalLink: updatedAction,
        authHasError: false,
      });
    } else {
      this.setState({
        shouldAlwaysUsePersonalLink: updatedAction,
        authHasError: false,
      });
    }
  }

  checkZoomAuth() {
    if (!this.getUser().has_zoom_access) {
      // no need to check this if already logged out
      return;
    }

    const path = "zoom_access_token/refresh";
    const url = constructRequestURL(path);

    return Fetcher.post(url, {}, true, getUserEmail(this.getUser()))
      .then((response) => {
        // on success, you’ll get an empty object {}  and the status is 200
        // on failure, you’ll get
        //   {
        //     error: 'zoom_refresh_failed',
        //     message: 'Zoom is unauthorized'
        //   }
        if (!this._isMounted) {
          return;
        }

        if (isEmptyObjectOrFalsey(response)) {
          if (this.state.authHasError) {
            this.setState({ authHasError: false });
          }
          return;
        }

        if (response.error === "zoom_refresh_failed") {
          this.setState({ authHasError: true });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  getUser() {
    return this.props.inputUser ?? this.props.currentUser;
  }

  closeModal() {
    this.setState({ shouldDisplayModal: false });
  }

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={this.closeModal}
        width={"700px"}
        title={"Zoom access instructions"}
        style={determineDefaultModalStyle(this.props.isDarkMode)}
      >
        <MaestroZoomLoginInfo
          onClose={this.closeModal}
          onClickZoomLogin={this.onLoginZoom}
        />
      </EventModalPopup>
    );
  }
}

function mapStateToProps(state) {
  const { currentUser, isDarkMode } = state;

  return {
    currentUser,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const zoomSchedulers = useZoomSchedulers();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      zoomSchedulers={zoomSchedulers}
      masterAccount={masterAccount}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(ZoomSettings));
