import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import useUsernameCheck from "../../../../services/customHooks/useUsernameCheck";
import UserNameSettings from "./userNameSettings";
import { getProfilePhotoUrl, getUserEmail, getUserName } from "../../../../lib/userFunctions";
import SettingsSubtitle from "../../common/settingsSubtitle";
import ProfilePhotoInput, { extractImage } from "../../common/profilePhotoInput";
import { getFileExtension } from "../../../../lib/fileFunctions";
import { useSelector } from "react-redux";
import { usePreloadImages } from "../../../../services/customHooks/usePreloadImages";
import { sanitizeString } from "../../../../lib/jsVariables";
import usePresignedUpload from "../../../../services/customHooks/usePresignedUpload";
import { getInputStringFromEvent, slugifyString } from "../../../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import type AvatarEditor from "react-avatar-editor";

// TODO: Fetcher currently doesn't give us the exact error, so it's difficult to be more specific than this.
// We could refactor, or fetch without using Fetcher if we want to be more specific.
// Likely errors: image is too big, or the presigned upload link has expired.
const ERROR_PHOTO_UPLOAD = "There was an error uploading your photo";

interface NameAndProfilePhotoProps {
  selectedUser?: User
  isUpdatingExecutiveProfile: boolean
  formRef: React.MutableRefObject<HTMLFormElement>
  setHasChanged: StateSetter<boolean>
  setIsFormValid: StateSetter<boolean>
  setShowWarningModalOnSubmit: StateSetter<boolean>
  showErrorText: boolean
  setIsUploadingPhoto: StateSetter<boolean>
}

export default function NameAndProfilePhoto({
  selectedUser,
  isUpdatingExecutiveProfile,
  formRef,
  setHasChanged,
  setIsFormValid,
  setShowWarningModalOnSubmit,
  showErrorText,
  setIsUploadingPhoto,
}: NameAndProfilePhotoProps) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    userName: initialUserName,
  } = getUserName({ masterAccount, user: selectedUser });
  const originalProfilePhotoSrc = getProfilePhotoUrl({ masterAccount, user: selectedUser });
  /* We need to do this since React re-renders with updated vars before useEffect set is finished */
  /* This causes an error to flicker when changing selected user */
  const [initialUserNameState, setInitialUserNameState] = useState<string>(initialUserName);
  const currentUser = useSelector((state) => state.currentUser);
  const [profilePhotoFile, setProfilePhotoFile] = useState<File>();
  const [profilePhotoErrorText, setProfilePhotoErrorText] = useState<string>();
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [username, setUsername] = useState(initialUserName);
  const profilePhotoCropperRef = useRef<AvatarEditor>(null);
  const [usernameAvailable, isFetching] = useUsernameCheck({
    usernameCandidate: username,
    selectedUser,
  });
  useEffect(() => {
    if (isEmptyObjectOrFalsey(selectedUser)) {
      return;
    }

    const {
      firstName: inputFirstName,
      lastName: inputLastName,
      userName: inputUserName,
    } = getUserName({ masterAccount, user: selectedUser });

    if (inputUserName !== initialUserNameState) {
      setInitialUserNameState(inputUserName);
    }

    if (inputFirstName !== firstName) {
      setFirstName(inputFirstName);
    }

    if (inputLastName !== lastName) {
      setLastName(inputLastName);
    }

    if (inputUserName !== username) {
      setUsername(inputUserName);
    }

    setProfilePhotoFile(undefined);
    setProfilePhotoErrorText(undefined);
  }, [selectedUser]);

  // useEffect(() => {
  //   const { userName: initialUserName } = getUserName({
  //     masterAccount,
  //     user: selectedUser,
  //   });
  //   console.log("here_2", {initialUserName, username});

  //   if (initialUserName !== username) {
  //     console.log("here_3")
  //     setUsername(initialUserName);
  //   }
  // }, [masterAccount]);

  const selectedUserEmail = getUserEmail(selectedUser);
  const presignedUploadPayloadData = useMemo(() => {
    if (!profilePhotoFile) {
      return {};
    }
    // attach the file
    // Set the S3 key/filename in a way that avoids conflicts.
    // Example key: Kenny-Fitzgerald-1706907495665.jpg
    const extension = profilePhotoFile
      ? getFileExtension(profilePhotoFile)
      : "";
    const concatenatedName = `${firstName}-${lastName}`.slice(0, firstName.length === 15 ? 15 : 16);
    const profilePhotoS3Key = `${concatenatedName}-${new Date().valueOf()}${extension ? "." + extension : ""}`;

    return { key: profilePhotoS3Key };
  }, [profilePhotoFile]);

  const { presignedUpload } = usePresignedUpload({
    path: "settings/profile_photo_upload_url",
    payloadData: presignedUploadPayloadData,
    userEmail: selectedUserEmail ?? getUserEmail(currentUser),
    v2: true,
  });

  // Once a new image is saved, preload it before switching back to the image input.
  const onImagePreload = useCallback(() => setProfilePhotoFile(undefined), []);
  const preloadImageSrcs: string[] = useMemo(() => (
    originalProfilePhotoSrc ? [originalProfilePhotoSrc] : []
  ), [originalProfilePhotoSrc]);
  usePreloadImages(preloadImageSrcs, onImagePreload);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setUsername(slugifyString(username));
    }, 500);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [username]);

  const updateAccountSettings = (profilePhotoS3Key?: string) => {
    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_NAME,
      {
        firstName: sanitizeString(firstName),
        lastName: sanitizeString(lastName),
        profilePhotoS3Key,
        username: hasChangedUsername ? sanitizeString(username) : undefined,
        user: selectedUser,
        isUpdatingExecutiveProfile,
      },
    );
  };

  const onClickSave: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const croppedProfilePhoto = extractImage(profilePhotoCropperRef, profilePhotoFile);

    if (croppedProfilePhoto) {
      setIsUploadingPhoto(true);
      croppedProfilePhoto.toBlob(blob => {
        if (!blob) {
          return;
        }
        presignedUpload(blob)
          .then(resp => {
            if (isEmptyObjectOrFalsey(resp)) {
              throw new Error();
            }
            updateAccountSettings(resp.s3FileKey);
          }).catch(() => {
            setProfilePhotoErrorText(ERROR_PHOTO_UPLOAD);
          }).finally(() => {
            setIsUploadingPhoto(false);
          });
      }, profilePhotoFile?.type || "image/jpeg");
    } else {
      updateAccountSettings();
    }
  };

  const hasChangedUsername = useMemo(() => username !== initialUserName, [initialUserName, username]);

  const hasChanged = useMemo(() => (
    firstName !== initialFirstName ||
    lastName !== initialLastName ||
    hasChangedUsername ||
    !!profilePhotoFile
  ), [firstName, hasChangedUsername, initialFirstName, initialLastName, lastName, profilePhotoFile]);

  const onChangeFirstName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFirstName(getInputStringFromEvent(e));
  };

  const onChangeLastName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setLastName(getInputStringFromEvent(e));
  };

  const renderNameSection = () => {
    return (
      <div className="flex items-center default-font-size default-font-weight">
        <FirstName onChange={onChangeFirstName} value={firstName} />
        <LastName onChange={onChangeLastName} value={lastName} />
      </div>
    );
  };

  const isValidName = useMemo(() => Boolean(firstName && lastName), [firstName, lastName]);

  useEffect(() => {
    setShowWarningModalOnSubmit(hasChangedUsername);
  }, [hasChangedUsername]);

  useEffect(() => {
    setHasChanged(hasChanged);
  }, [hasChanged]);

  useEffect(() => {
    setIsFormValid(isValidName && (!hasChangedUsername || usernameAvailable));
  }, [hasChangedUsername, isValidName, usernameAvailable]);

  return (
    <form ref={formRef} onSubmit={onClickSave}>
      <SettingsSubtitle labelClassName="my-2">Name and profile photo</SettingsSubtitle>
      <div className="mb-5 secondary-text-color default-font-size">
        Changing your name and profile photo will update your booking page.
      </div>
      <ProfilePhotoInput
        errorText={profilePhotoErrorText}
        originalProfilePhotoSrc={originalProfilePhotoSrc}
        profilePhotoCropperRef={profilePhotoCropperRef}
        profilePhotoFile={profilePhotoFile}
        setProfilePhotoFile={setProfilePhotoFile}
      />
      {renderNameSection()}
      {!isValidName && showErrorText
        ? <FirstNameAndLastNameWarning />
        : null}
      <UserNameSettings
        setValue={setUsername}
        initialUserName={initialUserNameState}
        value={username}
        isAvailable={!hasChangedUsername || usernameAvailable}
        isFetching={isFetching}
        showWarningAboutChangingUsername
        canShowWarning={showErrorText}
      />
    </form>
  );
}

interface NameFieldProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value: string
}

function FirstName({ onChange, value }: NameFieldProps) {
  return (
    <div>
      <div className="mb-1.5">First name*</div>
      <input
        className="default-input-field settings-update-name-input"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

function FirstNameAndLastNameWarning() {
  return (
    <div className="warning-color default-font-size mt-1">
      You must include a first and last name
    </div>
  );
}

function LastName({ onChange, value }: NameFieldProps) {
  return (
    <div className="ml-8">
      <div className="mb-1.5">Last name*</div>
      <input
        className="default-input-field settings-update-name-input"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
