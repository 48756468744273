import Fetcher from "./fetcher";
import { constructRequestURL } from "./api";
import { constructQueryParams } from "./commonUsefulFunctions";
import { getEventUserEventID } from "./eventResourceAccessors";
import { DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE, GOOGLE_UPDATES } from "./googleCalendarService";
import { getEventHoldID } from "./holdFunctions";
import { validateZoomUrl } from "../lib/urlFunctions";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";

const request = (requester, path, params, email, body, v2 = false) => {
  if (typeof email !== "string") {
    console.warn("bad email:", email);
    throw new Error("Invalid api request with non-string email");
  }

  let url = constructRequestURL(path, v2);
  if (!isEmptyObjectOrFalsey(params)) {
    url += `?${constructQueryParams(params)}`;
  }

  const payloadData = {
    headers: getDefaultHeaders(),
    ...(body && { body: JSON.stringify(body) }),
  };

  return requester(url, payloadData, /* authorizationRequired */ true, email);
};

const http = {
  post: (path, params, email, v2 = false) => request(Fetcher.post, path, params, email, null, v2),
  patch: (path, params, email, v2 = false) => request(Fetcher.patch, path, params, email, null, v2),
  delete: (path, params, email, body, v2 = false) =>
    request(Fetcher.delete, path, params, email, body, v2),
};

const ApiClient = {
  deleteEvent: (
    event,
    { calendar_provider_id, event_provider_id, sendUpdates, message, emailData },
    email,
    v2 = false
  ) =>
    http.delete(
      "events",
      {
        calendar_provider_id,
        event_provider_id,
        user_event_id: getEventUserEventID(event),
        sendUpdates: sendUpdates && !message ? GOOGLE_UPDATES.ALL : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE, // Use vimcal mailer when message exists, otherwise have google send email update
      },
      email,
      {
        ...emailData,
        message: message,
      },
      v2
    ),
  deleteAllHoldEvents: (event, { google_calendar_id, emailData }, email) =>
    http.delete(
      "events/holds",
      {
        google_calendar_id,
        vholds_id: getEventHoldID(event),
      },
      email,
      {
        ...emailData,
      },
      true
    ),
  // See: https://marketplace.zoom.us/docs/api-reference/zoom-api/meetings/meetingcreate
  createZoomMeeting: (params, email, isSchedulingForDelegatedUser = false) =>
    isSchedulingForDelegatedUser
      ? http.post("delegated_zoom_meeting", params, email)
      : http.post("zoom_meetings", params, email),
  deleteZoomMeeting: (meeting, email) =>
    http.delete(`zoom_meetings/${meeting.id}`, {}, email),
  logoutZoom: (email) => http.delete("zoom_access_token", {}, email),
  recordUserEvent: (params, email) => http.post("user_events", params, email),
  updateZoomLink: (zoom_link, email) =>
    http.patch("zoom_link", { zoom_link }, email),
  updateDefaultConferencing: (default_conferencing_option, email) =>
    http.post(
      "settings/default_conferencing_option",
      { default_conferencing_option },
      email
    ),
  validateZoomLink: validateZoomUrl,
};

export default ApiClient;
