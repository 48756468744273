import { useCallback, useEffect, useState } from "react";

import Fetcher from "../fetcher";
import { handleError } from "../commonUsefulFunctions";
import { constructRequestURL } from "../api";

interface UsePresignedUploadOptions {
  path: string
  payloadData: {
    key?: string
  }
  userEmail: string | null
  v2: boolean
}

interface PresignedUrlResponse {
  fields: Record<string, string>
  url: string
}

type PresignedUpload = (file: File | Blob) => (
  Promise<void | { s3FileKey: string }>
)

export const PRESIGNED_UPLOAD_STATUS = {
  LOADING: "loading",
  READY: "ready",
  UPLOADING: "uploading",
  SUCCESS: "success",
  ERROR: "error",
} as const;
export type PresignedUploadStatus = ValueOf<typeof PRESIGNED_UPLOAD_STATUS>

/**
 * The `payloadData` option must be memoized if it is provided.
 */
export default function usePresignedUpload({ payloadData, path, userEmail, v2 }: UsePresignedUploadOptions) {
  const [uploadUrl, setUploadUrl] = useState<string>();
  const [uploadFields, setUploadFields] = useState<Record<string, string>>();
  const [presignedUploadStatus, setPresignedUploadStatus] = useState<PresignedUploadStatus>(PRESIGNED_UPLOAD_STATUS.LOADING);
  const url = constructRequestURL(path, v2);

  const resetUrl = useCallback(() => {
    setUploadUrl(undefined);
    setUploadFields(undefined);
  }, []);

  useEffect(() => {
    // TODO: Auto-create a new link once the current link expires.
    setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.LOADING);
    resetUrl();
    if (!payloadData.key) {
      return;
    }
    const params = { body: JSON.stringify({ presigned_options: payloadData }) };
    Fetcher.post<PresignedUrlResponse>(url, params, true, userEmail)
      .then(response => {
        if (!response || !("url" in response)) {
          throw new Error("Unexpected response when creating a presigned url.");
        }

        setUploadUrl(response.url);
        setUploadFields(response.fields);
      })
      .catch(e => {
        handleError(e);
        setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.ERROR);
      });
  }, [payloadData, resetUrl, url, userEmail]);

  useEffect(() => {
    if (uploadFields && uploadUrl) {
      setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.READY);
    }
  }, [uploadFields, uploadUrl]);

  const presignedUpload: PresignedUpload = useCallback(async (file) => {
    if (!uploadUrl || !uploadFields || presignedUploadStatus === PRESIGNED_UPLOAD_STATUS.LOADING) {
      return;
    }

    const formData = new FormData();

    Object.entries(uploadFields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Always add file as the last entry in formData.
    formData.append("file", file);

    const params = {
      headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    };

    setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.UPLOADING);

    try {
      const response = await Fetcher.post(uploadUrl, params, false);
      if (!response) {
        throw new Error();
      }
      setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.SUCCESS);
      return { s3FileKey: formData.get("key") as string | null ?? "" };
    } catch (e) {
      setPresignedUploadStatus(PRESIGNED_UPLOAD_STATUS.ERROR);
      handleError(e);
    }
  }, [presignedUploadStatus, uploadFields, uploadUrl]);

  return { presignedUpload, presignedUploadStatus, uploadUrl, uploadFields };
}
