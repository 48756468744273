import React, { Component } from "react";
import { startOfHour, setHours } from "date-fns";
import classNames from "classnames";
import { Calendar } from "rbc-fork-react-big-calendar";
import withDragAndDrop from "rbc-fork-react-big-calendar/lib/addons/dragAndDrop";
import _ from "underscore";
import { getEventUniqueKey, isAvailabilityEvent } from "../../lib/eventFunctions";
const DnDCalendar = withDragAndDrop(Calendar);

export default class MinifiedMainCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldShowCalendar: true, renderCount: 0 };
    this._hasScrolled = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shouldDisplayCalendar && !this.props.shouldDisplayCalendar) {
      this.setState({ shouldShowCalendar: false }, () => {
        this._hasScrolled = false;
        this.setState({ shouldShowCalendar: true });
      });
    }

    if (
      this.props.params.currentTimeZone !== prevProps.params.currentTimeZone
    ) {
      this._hasScrolled = false;
    }
  }

  render() {
    const {
      events,
      isTopBarShowing,
      onMoveEvent,
      localizer,
      calendarComponents,
      calendarTimeFormats,
      CustomWeek,
      styleGetter,
      onSelectSlot,
      isAvailabilityMode,
      slotGetter,
      showToolBar,
    } = this.props.params;
    if (!this.state.shouldShowCalendar) {
      return null;
    }

    const moveAccessor = (event) => {
      return isAvailabilityEvent(event);
    };

    // TODO: weird bug where after creation/edit, the very next drag/drop is ignored
    return (
      <DnDCalendar
        className={classNames(
          "onboarding-weekly-calendar",
          isTopBarShowing
            ? "margin-top-30 calendar-height-with-top-bar"
            : "height-100-percent",
        )}
        events={events}
        date={new Date()}
        draggableAccessor={moveAccessor}
        resizableAccessor={moveAccessor}
        keyAccessor={getEventUniqueKey}
        onEventDrop={onMoveEvent}
        onEventResize={onMoveEvent}
        localizer={localizer}
        defaultView={"week"}
        toolbar={showToolBar}
        step={15}
        timeslots={4}
        showMultiDayTimes={true}
        formats={calendarTimeFormats} //CALENDAR_TIME_FORMATS
        components={calendarComponents}
        view={"week"}
        views={{ week: CustomWeek }}
        onNavigate={_.noop}
        onView={_.noop}
        resizable
        scrollToTime={this.determineScrollToTime()}
        startAccessor="eventStart"
        endAccessor="rbcEventEnd"
        allDayAccessor="displayAsAllDay"
        eventPropGetter={styleGetter}
        onSelectSlot={onSelectSlot}
        selectable={isAvailabilityMode}
        slotPropGetter={slotGetter}
        onSelectEvent={_.noop}
      />
    );
  }

  determineScrollToTime() {
    return startOfHour(setHours(new Date(), 8));
  }
}
