import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

export interface PlanOptionWrapperProps {
  children: React.ReactNode
  className?: string
  isSelected: boolean
  onClick: () => void
}

export default function PlanOptionWrapper({ children, className, isSelected, onClick }: PlanOptionWrapperProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  return (
    <div
      className={classNames(
        "relative rounded-xl font-normal cursor-pointer select-none bg-white bg-opacity-8",
        isSelected ? "selected-plan-border" : "non-selected-plan-border",
        isSelected ? "text-white" : "non-selected-text-color",
        isDarkMode ? "" : "box-shadow-6",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
