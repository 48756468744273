import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import {
  determineCalendarColor,
  getCalendarName,
  getCalendarUserEmail,
  getOrderedAllCalendars,
  getUserCalendar,
} from "../../lib/calendarFunctions";
import {
  getCalendarEmail,
  getCalendarUserCalendarID,
} from "../../services/calendarAccessors";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import CheckBox from "../checkbox";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import CustomButton from "../customButton";
import DefaultSwitch from "../defaultSwitch";
import { useSelector } from "react-redux";
import DisabledButton from "../disabledButton";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { isEmailLoggedIn } from "../../lib/stateManagementFunctions";
import { isCalendarExecutiveCalendar, isUserMaestroUser, shouldHideDelegatedUser } from "../../services/maestroFunctions";
import { removeDuplicatesFromArray } from "../../services/commonUsefulFunctions";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { isSameEmail, lowerCaseAndTrimString } from "../../lib/stringFunctions";
import ExecutiveLabel from "../../../components/executiveLabel";
import { getObjectEmail } from "../../lib/objectFunctions";


export default function SelectBlockedCalendars({
  blockedCalendarsID,
  descriptionOverrideClassName,
  setCalendarContainer,
  onClickSave,
  onClickCancel,
  showSetAsDefault,
  onChangeCalendarIDs,
  isFullScreen,
  selectedUser,
  topDescription,
  updateLabel,
  inputBlockedCalendars, // array of objects {user, calendars: []}
  inputCalendars, // passing in explict array of calendars objects. e.g. metrics
  onlyShowLoggedInCalendars,
  containerClassName,
}) {
  const [shouldSetAsDefault, setAsDefault] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );

  const { sortedUserEmails, blockedCalendarsByEmail, duplicateNames } =
    getBlockedCalendarsByUserEmail({
      blockedCalendars: inputBlockedCalendars,
      inputCalendars,
      onlyShowLoggedInCalendars,
      allLoggedInUsers,
    });

  const [blockedCalendarsUserCalendarIDs, setBlockedCalendarsUserCalendarID] =
    useState(blockedCalendarsID);
  const [isDisabled, setDisabled] = useState(true);
  const isFirstUpdate = useRef(true);

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    setDisabled(false);

    if (onChangeCalendarIDs) {
      onChangeCalendarIDs(blockedCalendarsUserCalendarIDs);
    }
  }, [blockedCalendarsUserCalendarIDs, shouldSetAsDefault]);

  const renderButtons = () => {
    if (!onClickSave || !onClickCancel) {
      return null;
    }

    return (
      <div className="flex items-center justify-end mt-4">
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={onClickCancel}
          label="Cancel"
          addPaddingToRight={true}
        />

        {isDisabled ? (
          <DisabledButton />
        ) : (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => {
              onClickSave(blockedCalendarsUserCalendarIDs);
              if (shouldSetAsDefault) {
                availabilityBroadcast.publish(
                  "SAVE_SLOTS_SETTINGS",
                  {
                    blocked_calendar_ids: blockedCalendarsUserCalendarIDs,
                  },
                  selectedUser,
                );
              }
            }}
            label={updateLabel ?? "Save"}
          />
        )}
      </div>
    );
  };

  const onClickCalendar = (userCalendarID) => {
    if (blockedCalendarsUserCalendarIDs.includes(userCalendarID)) {
      setBlockedCalendarsUserCalendarID(
        blockedCalendarsUserCalendarIDs.filter((id) => id !== userCalendarID),
      );
    } else {
      setBlockedCalendarsUserCalendarID(
        blockedCalendarsUserCalendarIDs.concat(userCalendarID),
      );
    }
  };

  const renderSetAsDefault = () => {
    if (!showSetAsDefault) {
      return null;
    }

    return (
      <div className="flex items-center">
        <div
          className="mr-4 default-font-size cursor-pointer select-none hoverable-secondary-text-color"
          onClick={() => setAsDefault(!shouldSetAsDefault)}
        >
          Set as default
        </div>

        <DefaultSwitch
          isChecked={shouldSetAsDefault}
          onChange={() => {
            setAsDefault(!shouldSetAsDefault);
          }}
        />
      </div>
    );
  };

  const renderCalendarPerCurrentUser = (email) => {
    let userCalendars = getOrderedAllCalendars({
      allCalendars: getUserCalendar(allCalendars, email),
      currentUserEmail: email,
      allLoggedInUsers,
      masterAccount,
    });

    const matchingCalendar = blockedCalendarsByEmail[email];
    if (matchingCalendar) {
      const userCalendarsUserCalendarIDs = userCalendars.map((c) =>
        getCalendarUserCalendarID(c),
      );
      const filteredOutBlockedCalendars = matchingCalendar.filter(
        (c) =>
          !userCalendarsUserCalendarIDs.includes(getCalendarUserCalendarID(c)),
      );
      if (filteredOutBlockedCalendars.length > 0) {
        userCalendars = userCalendars.concat(filteredOutBlockedCalendars);
      }
    }

    const createKey = (userEmail, googleID, index) => {
      return `select-calendar-calendar-${userEmail}_${googleID}_${index}`;
    };

    return userCalendars.map((calendar, index) => {
      const id = createKey(email, getCalendarEmail(calendar), index);
      const calendarColor = determineCalendarColor(calendar);
      const calendarUserCalendarID = getCalendarUserCalendarID(calendar);
      const isBlocked = blockedCalendarsUserCalendarIDs.includes(
        calendarUserCalendarID,
      );
      const isExecCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({calendar, allLoggedInUsers});

      return (
        <div
          key={id}
          id={id}
          className={classNames(
            "default-font-size font-weight-300 ml-2.5 flex items-center cursor-pointer",
            "mr-1 py-1 pr-1",
            "pr-3",
            "select-none",
          )}
          onClick={() => {
            onClickCalendar(calendarUserCalendarID);
          }}
        >
          <CheckBox
            backgroundColor={isBlocked ? calendarColor : "transparent"}
            borderColor={calendarColor}
            borderWidth={1}
            isChecked={isBlocked}
          />
          <div
            className={classNames(
              "ml-2 pt-0.5",
              "default-font-color",
              "flex items-center gap-2",
            )}
          >
            <div className={classNames("truncate", "max-width-280px")}>
              {getDefaultCalendarName(calendar) ??
                getCalendarName({
                  calendar,
                  emailToNameIndex,
                  currentUser,
                  masterAccount,
                })
              }
            </div>
            {isExecCalendar ? <ExecutiveLabel /> : null}
          </div>
        </div>
      );
    });
  };

  const renderBlockedCalendarForOtherAccountUsers = ({
    blockedCalendarsByEmail,
    email,
  }) => {
    return (
      <>
        {blockedCalendarsByEmail[email]?.map((calendar, index) => {
          const calendarName = getCalendarName({
            calendar,
            emailToNameIndex,
            currentUser,
            masterAccount,
          });
          const calendarColor = determineCalendarColor(calendar);
          const calendarUserCalendarID = getCalendarUserCalendarID(calendar);
          const isBlocked = blockedCalendarsUserCalendarIDs.includes(
            calendarUserCalendarID,
          );

          return (
            <div
              key={`main_calendar_list_${index}`}
              className={classNames(
                "ml-2.5 flex items-center select-none cursor-pointer",
                index !== 0 ? "mt-2" : "",
              )}
              onClick={() => {
                onClickCalendar(calendarUserCalendarID);
              }}
            >
              <CheckBox
                backgroundColor={isBlocked ? calendarColor : "transparent"}
                borderColor={calendarColor}
                isChecked={isBlocked}
              />

              <div
                className={classNames(
                  "ml-2 select-none",
                  "truncate-text w-full default-font-color",
                )}
              >
                {getDefaultCalendarName(calendar) ?? calendarName}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const getUserEmailList = () => {
    let usersEmails = [];
    const user = selectedUser || currentUser;
    const sortedLoggedInUserEmails = allLoggedInUsers
      .map((user) => getUserEmail(user))
      .sort();

    usersEmails = [getUserEmail(user)]
      .concat(
        sortedLoggedInUserEmails.filter(
          (email) => !isSameEmail(email, getUserEmail(user)),
        ),
      )
      .concat(
        sortedUserEmails.filter(
          (e) =>
            !isSameEmail(e, getUserEmail(user)) && !sortedLoggedInUserEmails.includes(e),
        ),
      );
    if (inputCalendars) {
      const inputCalendarUserEmails = inputCalendars
        .map((calendar) => getCalendarUserEmail(calendar))
        .filter((userEmail) => userEmail);
      usersEmails = usersEmails.concat(inputCalendarUserEmails);
    }
    return removeDuplicatesFromArray(usersEmails);
  };

  const renderBlockedCalendarList = () => {
    const sortedUserEmailsWithCurrentUserEmail = getUserEmailList();

    return (
      <div
        className={classNames(
          "mb-10",
          "width-inhert-important default-font-size",
          setCalendarContainer ?? "",
          "overflow-y-auto",
        )}
        style={
          isFullScreen
            ? { maxHeight: "calc(100% - 78px)" }
            : { maxHeight: "40vh" }
        }
      >
        {sortedUserEmailsWithCurrentUserEmail.map((email, index) => {
          const isUserLoggedIn = isEmailLoggedIn({ email, allLoggedInUsers });
          const matchingUser = allLoggedInUsers.find(user => isSameEmail(getUserEmail(user), email));
          if (shouldHideDelegatedUser({user: matchingUser, allCalendars})) {
            return null;
          }
          const showAllCalendarsForEmail = isUserLoggedIn;

          return (
            <div key={`user_main_calendar_container_${index}`}>
              <div
                className={classNames(
                  "secondary-text-color",
                  "my-2.5",
                  "flex items-center",
                )}
              >
                <div className="default-font-size font-weight-400">{email}</div>

                {isUserLoggedIn ? null : (
                  <div className="default-font-size italic ml-2">
                    Signed out
                  </div>
                )}
              </div>

              {showAllCalendarsForEmail
                ? renderCalendarPerCurrentUser(email)
                : renderBlockedCalendarForOtherAccountUsers({
                  blockedCalendarsByEmail,
                  email,
                  duplicateNames,
                })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classNames(containerClassName)}>
      <div
        className={classNames(
          descriptionOverrideClassName ?? "",
          "default-font-size secondary-text-color",
        )}
      >
        {topDescription ??
          "Select the calendars you want to check conflicts for."}
      </div>

      {renderBlockedCalendarList()}
      {renderSetAsDefault()}
      {renderButtons()}
    </div>
  );
}

function getDefaultCalendarName(calendar) {
  if (calendar?.summary?.length > 0) {
    return calendar.summary;
  } else if (calendar?.raw_json?.summary?.length > 0) {
    return calendar.raw_json.summary;
  }

  return null;
}

function getBlockedCalendarsByUserEmail({
  blockedCalendars,
  inputCalendars,
  onlyShowLoggedInCalendars,
  allLoggedInUsers,
}) {
  let allUserEmails = [];
  let blockedCalendarsByEmail = {};
  let allNamesTracker = [];
  let duplicateNames = [];
  let blockedUserCalendarIDs = [];

  const parseBlockedCalendar = (inputBlockedCalendars, key) => {
    inputBlockedCalendars.forEach((blockedCalendarsByUser) => {
      const email =
        inputBlockedCalendars?.user_email ??
        getObjectEmail(blockedCalendarsByUser?.user);

      if (!email || !blockedCalendarsByUser[key]) {
        return;
      }

      allUserEmails = allUserEmails.concat(email);

      // need to wrap in object because that's how allCalendars are set up and thus all function use .calendar methodology
      const mainCalendars = blockedCalendarsByUser[key].map((c) => {
        return { calendar: c };
      });
      mainCalendars.forEach((c) => {
        const calendarName = getCalendarName({ calendar: c });
        const userCalendarID = getCalendarUserCalendarID(c);

        blockedUserCalendarIDs = blockedUserCalendarIDs.concat(userCalendarID);

        if (allNamesTracker.includes(calendarName)) {
          duplicateNames = duplicateNames.concat(calendarName);
        } else {
          allNamesTracker = allNamesTracker.concat(calendarName);
        }
      });
      blockedCalendarsByEmail[email] = mainCalendars;
    });
  };

  if (blockedCalendars?.length > 0) {
    parseBlockedCalendar(blockedCalendars, "calendars");
  }

  if (!isEmptyArray(inputCalendars)) {
    inputCalendars.forEach((calendar) => {
      const userEmail = calendar?.user_email;
      if (!userEmail) {
        return;
      }
      if (blockedCalendarsByEmail[userEmail]) {
        const existingUserCalendarIDs = blockedCalendarsByEmail[userEmail].map(
          (c) => getCalendarUserCalendarID(c),
        );
        if (
          existingUserCalendarIDs.includes(
            getCalendarUserCalendarID({ calendar }),
          )
        ) {
          // already exists
          return;
        }
        blockedCalendarsByEmail[userEmail] = blockedCalendarsByEmail[
          userEmail
        ].concat({ calendar });
      } else {
        blockedCalendarsByEmail[userEmail] = [{ calendar }];
      }
    });
  }

  let sortedUserEmails = removeDuplicatesFromArray(allUserEmails).sort();
  if (onlyShowLoggedInCalendars && allLoggedInUsers) {
    const loggedInUserEmails = allLoggedInUsers.map((user) =>
      getUserEmail(user),
    );
    sortedUserEmails = sortedUserEmails.filter((email) => {
      return loggedInUserEmails.includes(lowerCaseAndTrimString(email)) || blockedCalendarsByEmail[email]?.length > 0;
    });
  }

  return {
    sortedUserEmails,
    blockedCalendarsByEmail,
    duplicateNames,
    blockedUserCalendarIDs,
  };
}
