import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSavedTeamInviteToken } from "../../lib/stateManagementFunctions";
import { ONBOARD_STEP, trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { ACCOUNT_STATE_PREPAID } from "../../lib/vimcalVariables";
import { getBackendSubscriptionIsFixedSeating } from "../../services/accessors/backendSubscription";
import { hasAccountBeenOnTeamPlan } from "../../services/accountFunctions";
import { useBackendSubscriptionStore } from "../../services/stores/finance";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { useGetBillingInfo } from "../teamPlans/hooks";
import AdminSettings from "../teamPlans/teamPlanSettings/adminSettings";
import OnboardingSpinner from "./onboardingSpinner";
import SaveAndSkipButtons from "./saveAndSkipButtons";

interface CreateTeamPlanProps {
  onClickNext: () => void
  onSkip: () => void
  teamPlanInvites?: TeamPlanInvite[]
}

export default function CreateTeamPlan({ onClickNext, onSkip, teamPlanInvites }: CreateTeamPlanProps) {
  const currentUser = useSelector(state => state.currentUser);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const hasBillingBeenFetched = useGetBillingInfo();
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const savedTeamInviteToken = getSavedTeamInviteToken();

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: ONBOARD_STEP.ADD_TEAM_MEMBERS,
    });
  }, []);

  useEffect(() => {
    if (
      // The user has clicked an invite link to join a team plan and should not be prompted
      // to create a team plan.
      savedTeamInviteToken ||
      // User is already on a team plan.
      !isEmptyObjectOrFalsey(teamPlan) ||
      // A backend subscription is required for a team plan to be created.
      (hasBillingBeenFetched && !backendSubscription) ||
      // Subscriptions with fixed seating could probably be supported if needed,
      // but we're not anticipating this case for now.
      getBackendSubscriptionIsFixedSeating(backendSubscription) ||
      // Users are not eligible if they have already been on a team plan.
      hasAccountBeenOnTeamPlan(masterAccount) ||
      // If the user already is invited to another team plan, don't prompt them
      // to create one of their own.
      masterAccount.state === ACCOUNT_STATE_PREPAID ||
      !isEmptyArrayOrFalsey(teamPlanInvites)
    ) {
      // User not eligible for a trial team plan. Skip this step.
      onSkip();
    }
  }, [
    backendSubscription,
    hasBillingBeenFetched,
    masterAccount,
    savedTeamInviteToken,
    teamPlan,
    teamPlanInvites,
  ]);

  const onSaveSuccess = () => {
    onClickNext();
  };

  if (!hasBillingBeenFetched) {
    return <OnboardingSpinner />;
  }

  return (
    <div className="onboarding-container">
      <div className="flex flex-col">
        <div className="section-title text-center">
          Create your team
        </div>
        <div className="welcome-subtitle mt-3 mb-12">
          The highest-powered teams use Vimcal Teams
        </div>
        <AdminSettings
          ActionsComponent={SaveAndSkipButtons}
          isCreatingTeam
          onClickSkip={onSkip}
          onSaveSuccess={onSaveSuccess}
        />
      </div>
    </div>
  );
}
