import React, { Component } from "react";
import GoogleCalendarService from "../services/googleCalendarService";
import {
  hasEventPreventDefault,
  KEYCODE_ENTER,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ESCAPE,
  KEYCODE_CONTROL,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  KEYCODE_C,
  KEYCODE_SEMI_COLON,
} from "../services/commonUsefulFunctions";
import StyleConstants,
{ HIGH_LIGHT_COLOR, SECOND_IN_MS } from "../services/globalVariables";
import { connect } from "react-redux";
import _ from "underscore";
import {
  differenceInMilliseconds,
  subSeconds,
} from "date-fns";
import Broadcast from "../broadcasts/broadcast";
import classNames from "classnames";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getGoogleMapsAutoCompleteAndDetail } from "../lib/googleFunctions";
import { getInputStringFromEvent, truncateString } from "../lib/stringFunctions";
import { SLOTS_ELEMENT } from "../lib/vimcalVariables";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { AVAILABILITY_BROADCAST_VALUES } from "../lib/broadcastValues";
import { getEventKeyCode } from "../services/appFunctions";

let { locationAutoComplete } = GoogleCalendarService;


class LocationSearchInput extends Component {

  constructor(props) {
    super(props);

    // Need timer for location detail -> if call too quickly -> request fails
    this._typingTimeout = null;
    this._locationAutocomplete = null;
    this._locationDetail = null;
    this._lastPressedCmd = subSeconds(new Date(), 3);

    this.state = {
      query: this.props.defaultLocation,
      suggestions: [],
      hoverOverSuggestion: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMouseLeaveSuggestion = this.onMouseLeaveSuggestion.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickSuggestion = this.onClickSuggestion.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);
    this.pasteInText = this.pasteInText.bind(this);

    availabilityBroadcast.subscribe(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_LOCATION, this.pasteInText);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUpLocationAutoComplete();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultLocation !== this.props.defaultLocation) {
      this.setState({ query: this.props.defaultLocation });
    }
  }

  componentWillUnmount() {
    this._locationAutocomplete = null;
    this._locationDetail = null;
    clearTimeout(this._typingTimeout);
    this._typingTimeout = null;
    this._isMounted = false;
    availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_LOCATION);
  }


  // TODO: move to calendar home view
  render() {
    return (
      <div>
        <input
          placeholder={this.props.hidePlaceHolder ? undefined : "Location"}
          className={classNames(this.props.customClassName ?? `${locationAutoComplete} ${this.props.changeNotificationClassName}`, "default-font-size", "location-input")}
          tabIndex={this.props.inputTabIndex}
          onFocus={this.onFocusInput}
          onBlur={this.onBlurInput}
          ref={this.props.focusRef}
          value={this.state.query ?? ""}
          id={locationAutoComplete}
          style={{
            backgroundColor: this.props.doesEventNotHavePermissionToEditActualEvent ? HIGH_LIGHT_COLOR : '',
            color: this.props.doesEventNotHavePermissionToEditActualEvent ? StyleConstants.defaultFontColor : null,
            width: this.props.width || null,
          }}
          onBlurCapture={this.props.onBlur}
          onChange={(e) => this.handleChange(getInputStringFromEvent(e))}
          onKeyDown={this.onKeyDown}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />

        {this.renderSuggestions()}
      </div>
    );
  }

  renderSuggestions() {
    let suggestions = this.state.suggestions;

    return suggestions.length > 0 && (
      <div
        className="autocomplete-dropdown-container margin-top-ten"
        style={{marginLeft: this.props.suggestionMarginLeft || 21, maxHeight: 180, overflowY: 'auto'}}
      >
        {suggestions.map(suggestion => {
          return (
            <div
              className="location-suggestion-item"
              style={suggestion.active ? {backgroundColor: this.props.isDarkMode ? StyleConstants.darkModeHoverBackgroundColor : '#F2F3F4'} : {}}
              key={suggestion.key}
              onMouseEnter={() => this.onMouseEnterSuggestion(suggestion)}
              onMouseLeave={this.onMouseLeaveSuggestion}
              onClick={() => this.onClickSuggestion(suggestion)}
            >
              <span>{suggestion.label}</span>
            </div>
          );
        })}
      </div>
    );
  }


  //================
  // EVENT HANDLERS
  //================



  handleChange(address) {
    this.setState({ query: address });

    if (this._typingTimeout) {
      clearTimeout(this._typingTimeout);

      this._typingTimeout = null;
    }

    this.props.onChange(address);

    this._typingTimeout = setTimeout(() => {
      this.searchForLocationSuggestion(address)
    }, 200);
  };

  pasteInText(text) {
    if (!text) {
      return;
    }
    const updatedQuery = (this.state.query || "") + text;
    this.props.onChange(updatedQuery, false, true);
    this.setState({
      query: updatedQuery
    });
  }

  onClickSuggestion(option) {
    const address = option.value;
    const {
      placeId
    } = option;
    if (!placeId) {
      this.setState({suggestions: [], query: address});
      this.props.onChange(address, false, true);
      return;
    }

    this._locationDetail?.getDetails(
      {placeId},
      detail => {
        if (!this._isMounted) {
          return;
        }

        if (detail?.formatted_address) {
          let completeQuery = detail.formatted_address;

          if (detail.name && !detail.formatted_address.includes(detail.name)) {
            completeQuery = `${detail.name}, ${detail.formatted_address}`;
          }
          this.setState({suggestions: [], query: completeQuery});
          this.props.onChange(completeQuery, false, true);
        } else {
          this.setState({suggestions: [], query: address});
          this.props.onChange(address, false, true);
        }
      }
    )
  }

  // TODO: replace keycode
  //https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
  onKeyDown(e) {
    if (![
      KEYCODE_ENTER,
      KEYCODE_TAB,
      KEYCODE_UP_ARROW,
      KEYCODE_DOWN_ARROW,
      KEYCODE_ESCAPE,
      KEYCODE_CONTROL,
      KEYCODE_COMMAND_LEFT,
      KEYCODE_COMMAND_RIGHT,
      KEYCODE_C,
      KEYCODE_SEMI_COLON,
    ].includes(getEventKeyCode(e))) {
      return;
    }
    if (e.keyCode === KEYCODE_SEMI_COLON &&
      this.props.checkForTemplateShortcut && 
      this._lastPressedCmd &&
      differenceInMilliseconds(new Date(), this._lastPressedCmd) < (2 * SECOND_IN_MS)
    ) {
      // do not show in personal links
      Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER", e, SLOTS_ELEMENT.LOCATION);
      return;
    }
    if (e.keyCode === KEYCODE_ENTER) {
      hasEventPreventDefault(e);
      const diffNowAndCmd = differenceInMilliseconds(new Date(), this._lastPressedCmd);
      if (this._lastPressedCmd && diffNowAndCmd < (2 * SECOND_IN_MS)) {
        // on press enter and cmd at the same time
        // Event form takes care of cmd + enter
      } else {
        this.onSelectSuggestionTabOrEnter();
      }
    } else if (e.keyCode === KEYCODE_TAB && this.state.suggestions.length > 0) {
      hasEventPreventDefault(e);
      this.onSelectSuggestionTabOrEnter();
    } else if ([KEYCODE_CONTROL, KEYCODE_COMMAND_LEFT, KEYCODE_COMMAND_RIGHT].includes(e.keyCode)) {
      this._lastPressedCmd = new Date();
    } else if (e.keyCode === KEYCODE_UP_ARROW && this.state.suggestions.length > 0) {
      this.setState({suggestions: this.pressUpSuggestion()});
    } else if (e.keyCode === KEYCODE_DOWN_ARROW && this.state.suggestions.length > 0) {
      this.setState({suggestions: this.pressDownSuggestions()});
    } else if (e.keyCode === KEYCODE_ESCAPE) {
      if (this.state.suggestions.length > 0) {
        this.setState({suggestions: []});
      } else {
        this.props.onPressEscape && this.props.onPressEscape();
      }
    } else if (this.props.isAvailability && e.keyCode === KEYCODE_C) {
      const diffNowAndCmd = differenceInMilliseconds(new Date(), this._lastPressedCmd);
      if (this._lastPressedCmd && diffNowAndCmd < (2 * SECOND_IN_MS)) {
        // on press enter and cmd at the same time
        // Event form takes care of cmd + enter
        hasEventPreventDefault(e);
        Broadcast.publish("COPY_AVAILABILITY_CONTENT");
      }
    }
  }

  removeSuggestions() {
    if (!this.state.hoverOverSuggestion && this.state.suggestions.length > 0) {
      this.setState({suggestions: []});
    }
  }

  pressUpSuggestion() {
    const {
      suggestions
    } = this.state;
    const updatedSuggestions = this.makeAllSuggestionsInactive(suggestions);
    const activeSuggestions = this.state.suggestions.filter(s => s.active);

    const currentIndex = activeSuggestions.length > 0 
      ? activeSuggestions[0].index 
      : null;

    if (currentIndex === 0) {
      // first suggestion
      return updatedSuggestions;
    } else if (currentIndex === undefined || currentIndex === null) {
      // Cant do !currentIndex because 0 would also fail here
      // Nothing is currently active/highlighted
      let lastSuggestionIndex = suggestions.length - 1;

      if (lastSuggestionIndex >= 0 && updatedSuggestions[lastSuggestionIndex]) {
        // Make sure the first index exists
        updatedSuggestions[lastSuggestionIndex].active = true;
      }

      return updatedSuggestions;
    } else {
      updatedSuggestions[currentIndex - 1].active = true;

      return updatedSuggestions;
    }
  }

  pressDownSuggestions() {
    let suggestions = this.state.suggestions;
    let updatedSuggestions = this.makeAllSuggestionsInactive(suggestions);
    let activeSuggestions = this.state.suggestions.filter(s => s.active);

    let currentIndex = null;
    if (activeSuggestions.length > 0) {
      currentIndex = activeSuggestions[0].index;
    }

    if (currentIndex === suggestions.length - 1) {

      // last suggestion
      return updatedSuggestions;
    } else if (currentIndex === undefined || currentIndex === null) {
      // Nothing is currently active/highlighted
      if (updatedSuggestions[0]) {
        // Make sure the first index exists
        updatedSuggestions[0].active = true;
      }

      return updatedSuggestions;
    } else {
      // go down one
      updatedSuggestions[currentIndex + 1].active = true;

      return updatedSuggestions;
    }
  }

  onSelectSuggestionTabOrEnter() {
    let activeSuggestion = this.state.suggestions.filter(s => s.active);

    if (activeSuggestion.length > 0) {
      this.onClickSuggestion(activeSuggestion[0]);
    }
  }

  onMouseLeaveSuggestion() {
    this.setState({hoverOverSuggestion: false});
  }

  onMouseEnterSuggestion(suggestion) {
    let resetSuggestions = this.makeAllSuggestionsInactive(this.state.suggestions);

    let updatedSuggestions = [];

    resetSuggestions.forEach(s => {
      if (s.index === suggestion.index) {
        let updatedElement = s;
        updatedElement.active = true;

        updatedSuggestions = updatedSuggestions.concat(updatedElement);
      } else {
        updatedSuggestions = updatedSuggestions.concat(s);
      }
    });

    this.setState({
      hoverOverSuggestion: true,
      suggestions: updatedSuggestions
    });
  }

  makeAllSuggestionsInactive(suggestions) {
    let updatedSuggestions = [];
    let element;

    suggestions.forEach(s => {
      element = _.clone(s);
      element.active = false;

      updatedSuggestions = updatedSuggestions.concat(element);
    });

    return updatedSuggestions;
  }

  setUpLocationAutoComplete() {
    const {
      detail,
      autoComplete
    } = getGoogleMapsAutoCompleteAndDetail();
    this._locationAutocomplete = autoComplete;
    this._locationDetail = detail;
  }

  onBlurInput() {
    this.removeSuggestions();
  }

  onFocusInput() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  searchForLocationSuggestion(inputText=null, isRetry = false) {
    const input = inputText || this.state.input;

    if (this._locationAutocomplete && input?.length > 0) {
      this._locationAutocomplete.getPlacePredictions(
        {input},
        predictions => {
          if (!this._isMounted || isRetry) {
            return;
          }
          if (!predictions) {
            // try again
            this.setUpLocationAutoComplete();
            setTimeout(() => {
              if (!this._isMounted) {
                return;
              }
              this.searchForLocationSuggestion(input, true); // so we don't get into infinite case
            }, 0.5 * SECOND_IN_MS);
            return;
          }

          const suggestions = this.formatLocationPredictions(predictions);

          this.setState({suggestions});
        });
    } else if (!this._locationAutocomplete) {
      this.setUpLocationAutoComplete();
    }
  }

  formatLocationPredictions(predictions) {
    if (isEmptyArray(predictions)) {
      return [];
    }

    return predictions.map((p, index) => {
      return {
        key: p.description.replace(/,/g, '_').replace(/ /g,"_"),
        label: truncateString(p.description, 45),
        value: p.description,
        active: false,
        placeId: p.place_id,
        index
      };
    });
  }
}

function mapStateToProps(state) {
  const {
    isDarkMode
  } = state;

  return {
    isDarkMode
  };
}



export default connect(mapStateToProps, null)(LocationSearchInput);
