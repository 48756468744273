import React from "react";
import produce from "immer";

import {
  getCalendarIsSelected,
  getCalendarUserCalendarID,
} from "../../../services/calendarAccessors";
import classNames from "classnames";
import CheckBox from "../../checkbox";
import ColoredLine from "../../line";
import CalendarSelectList from "./calendarSelectList";

function CalendarContainer(props) {
  const {
    magicLinkAllCalendars,
    primaryUserCalendarID,
    setMagicLinkAllCalendars,
  } = props;
  const userEmails = Object.keys(magicLinkAllCalendars);

  const toggleUserCalendars = ({
    calendars,
    isAnyCalendarSelected,
    isOnlyPrimarySelected,
    userEmail,
  }) => {
    /* If there are calendars selected, toggle all of them off UNLESS it is primary */
    if (isAnyCalendarSelected && !isOnlyPrimarySelected) {
      const updatedMagicLinkAllCalendars = produce(
        magicLinkAllCalendars,
        (draftState) => {
          draftState[userEmail] = calendars.map((calendar) => ({
            ...calendar,
            selected:
              getCalendarUserCalendarID(calendar) === primaryUserCalendarID
                ? true
                : false,
          }));
        },
      );

      setMagicLinkAllCalendars(updatedMagicLinkAllCalendars);
    } else {
      /* Disable button if only primary is selected */
      if (isOnlyPrimarySelected) {
        return;
      }

      /* If there are no calendars selected, toggle all of them on */
      const updatedMagicLinkAllCalendars = produce(
        magicLinkAllCalendars,
        (draftState) => {
          draftState[userEmail] = calendars.map((calendar) => ({
            ...calendar,
            selected: true,
          }));
        },
      );

      setMagicLinkAllCalendars(updatedMagicLinkAllCalendars);
    }
  };

  return (
    <>
      {userEmails.map((userEmail, idx) => {
        /* Format similarly to allCalendars to allow usage of accessor functions */
        const calendars = magicLinkAllCalendars[userEmail];
        const isAnyCalendarSelected = calendars.some((calendar) =>
          getCalendarIsSelected(calendar),
        );
        const selectedCalendars = calendars.filter((calendar) =>
          getCalendarIsSelected(calendar),
        );
        /* Shirt circuit ensures we have only 1 item at index 0 */
        const isOnlyPrimarySelected =
          selectedCalendars?.length === 1 &&
          getCalendarUserCalendarID(selectedCalendars[0]) === primaryUserCalendarID;

        return (
          <div
            className={classNames(
              "magic-link-calendar-select-container",
              userEmails.length - 1 === idx ? "" : "mb-2",
            )}
            key={`calendar-select-${userEmail}-${idx}`}
          >
            <div
              className={classNames(
                "magic-link-calendar-select-email",
                "container-hover-icon-visibility",
                "calendar-hover-agenda-panel",
              )}
              onClick={() =>
                toggleUserCalendars({
                  calendars,
                  isAnyCalendarSelected,
                  isOnlyPrimarySelected,
                  userEmail,
                })
              }
            >
              <div className="truncate-text w-full">{userEmail}</div>
              <CheckBox
                backgroundColor={
                  isAnyCalendarSelected ? "#3B81F7" : "transparent"
                }
                borderColor={"#3B81F7"}
                borderWidth={1}
                disabled={isOnlyPrimarySelected}
                isChecked={isAnyCalendarSelected}
              />
            </div>
            <ColoredLine
              inputClassName="magic-link-colored-line"
              width="calc(100% - 24px)"
            />
            <CalendarSelectList
              calendars={calendars}
              magicLinkAllCalendars={magicLinkAllCalendars}
              primaryUserCalendarID={primaryUserCalendarID}
              setMagicLinkAllCalendars={setMagicLinkAllCalendars}
              userEmail={userEmail}
            />
          </div>
        );
      })}
    </>
  );
}

export default CalendarContainer;
