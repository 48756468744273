import { LOCAL_DATA_ACTION } from "../lib/localData";
import { handleError, isInt, localData } from "./commonUsefulFunctions";
import { generateLargeRandomNumber } from "./randomFunctions";

export const SIGNUP_GIF = "signup_gif";
export const SIGNUP_TESTIMONIAL = "signup_testimonials";

export const ONBOARDING_EXPERIMENT_CALENDLY_VS_VIMCAL_V7 = {
  VIMCAL: "vimcal",
  CALENDLY: "calendly",
};

export const VIMCAL_EA_TESTIMONIAL_HEADER = {
  COURTNEY_QUOTE: "courtney",
  STOCK_PHOTO: "stock_photo",
  LADY_ON_LAPTOP: "lady_on_laptop",
};

export const VIMCAL_EA_HEADER_IMAGE = {
  COURTNEY_QUOTE: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/courtney.png",
  STOCK_PHOTO: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal-ea-stockphoto.png",
  LADY_ON_LAPTOP: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/rodeo-project-cropped.png",
};

const VIMCAL_EA_EXPERIMENT_HEADER_ID = "vimcal_ea_form_id";
export function generateIDForVimcalEATestimonialHeader() {
  const storedNum = parseInt(localData(LOCAL_DATA_ACTION.GET, VIMCAL_EA_EXPERIMENT_HEADER_ID), 10);
  if (isInt(storedNum)) {
    return storedNum;
  }
  const id = generateLargeRandomNumber();
  localData(LOCAL_DATA_ACTION.SET, VIMCAL_EA_EXPERIMENT_HEADER_ID, id);
  return id;
}

// plan: run A/A test
// then allocation 70% to COURTNEY_QUOTE and 30% to STOCK_PHOTO
export function getVimcalEATestimonialHeader({id, headerKey}) {
  if (headerKey) {
    return headerKey;
  }
  const isEven = id % 2 === 0;
  if (isEven) {
    // allocation 70% of the time
    return VIMCAL_EA_TESTIMONIAL_HEADER.COURTNEY_QUOTE;
  }
  return VIMCAL_EA_TESTIMONIAL_HEADER.COURTNEY_QUOTE;
}

export const VIMCAL_EA_FORM_CATEGORY = "vimcal_ea_form";

// the hash value is deterministic - this function will return the same value given the same input
// this is a simplier version of the djb2 hash function
// really quick has for string: http://www.cse.yorku.ca/~oz/hash.html
// has excellent distribution and speed on many different sets of keys and table sizes
export function hashCode(str) {
  try {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  } catch (error) {
    handleError(error);
    return 0;
  }
}

/**
 * checks if the id should be in a certain bucket based on a hashed percent.
 * The primary reason to use hashing is to handle non-uniformly distributed IDs or when IDs are strings or other types.
 * @param {int} id  - usually a master account id, etc
 * @param {int} percentage - The percentage threshold for bucket allocation (0-100)
 */
export function isInBucket({ id, percentage }) {
  if (!id || !percentage) {
    return false;
  }
  try {
    const hashValue = Math.abs(hashCode(id.toString()));
    return hashValue % 100 <= percentage;
  } catch (error) {
    handleError(error);
    return false;
  }
}
