import React, { Component } from "react";
import Classnames from "classnames";
import SaveButton from "./saveButton";
import {
  KEYCODE_ENTER,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
} from "../services/commonUsefulFunctions";

class ModalTextOptionsSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: props.defaultOption,
      index: 0,
    };

    this.onSelection = this.onSelection.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNextSelectedOption = this.handleNextSelectedOption.bind(this);
    this.handlePreviousSelectedOption =
      this.handlePreviousSelectedOption.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    const scrollTo = this.refs.highlightedItem;
    scrollTo?.scrollIntoView();

    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);

    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  render() {
    return (
      <div>
        <div className="flex flex-col gap-1">
          {this.renderList()}
        </div>

        {this.props.noSaveButton ? null : (
          <SaveButton
            width={"100%"}
            height={SAVE_BUTTON_HEIGHT}
            marginTop={50}
            onClick={() => {
              this.props.sendInformationBackToParentComponent(
                this.state.selectedOption
              );
            }}
          />
        )}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderList() {
    return this.props.selectOption.map((option, index) => {
      return (
        <div
          className={Classnames(
            "modal-text-options select-none",
            "rounded-md",
            this.isSelectedOption(option)
              ? "modal-text-options-selected"
              : "modal-text-options-select"
          )}
          onClick={() => this.onSelection(option, index)}
          key={`modalTextOptionsSelect_${index}`}
          ref={
            this.isSelectedOption(option)
              ? "highlightedItem"
              : "nonHighlightedItem"
          }
        >
          {option === "Every weekday"
            ? "Every weekday (Monday to Friday)"
            : option}
        </div>
      );
    });
  }

  //=================
  // PRIVATE METHODS
  //=================

  isSelectedOption(option) {
    return option === this.state.selectedOption;
  }

  //================
  // EVENT HANDLERS
  //================

  handleKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODE_UP_ARROW:
        this.handlePreviousSelectedOption();

        break;
      case KEYCODE_DOWN_ARROW:
        this.handleNextSelectedOption();

        break;
      case KEYCODE_ENTER:
        let result = this.props.selectOption[this.state.index];

        this.onSelection(result, this.state.index);

        break;
      default:
        break;
    }
  }

  handlePreviousSelectedOption() {
    let newIndex;

    if (this.state.index === 0) {
      newIndex = this.props.selectOption.length - 1;
    } else {
      newIndex = this.state.index - 1;
    }

    this.setState(
      {
        index: newIndex,
      },
      () => {
        this.setState({
          selectedOption: this.props.selectOption[this.state.index],
        });
      }
    );
  }

  handleNextSelectedOption() {
    this.setState(
      {
        index: (this.state.index + 1) % this.props.selectOption.length,
      },
      () => {
        this.setState({
          selectedOption: this.props.selectOption[this.state.index],
        });
      }
    );
  }

  onSelection(option, index) {
    this.setState({
      selectedOption: option,
      index: index,
    });

    if (typeof this.props.sendInformationBackToParentComponent === "function") {
      this.props.sendInformationBackToParentComponent(option);
    }

    if (typeof this.props.shouldUseDefaultOption === "function") {
      this.props.selectedOptionFromList();
    }
  }
}

export default ModalTextOptionsSelect;
