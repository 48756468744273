import React from "react";

interface OnboardingSkipButtonProps {
  onClickNext: React.MouseEventHandler<HTMLDivElement>
}

export default function OnboardingSkipButton({ onClickNext }: OnboardingSkipButtonProps) {
  return (
    <div
      className="default-font-size hoverable-secondary-text-color"
      onClick={onClickNext}
    >
      Skip for now
    </div>
  );
}
