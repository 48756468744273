import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
  isInt,
  removeLeadingZeros,
  removeDuplicatesFromArray,
} from "../../../../services/commonUsefulFunctions";
import AvailabilityAdditionalDetails from "../../../availabilityAdditionalDetails";
import CustomButton from "../../../customButton";
import {
  BLUE_BUTTON,
  SLOTS_SELECT_TYPE_PLAIN_TEXT,
  SLOTS_SELECT_TYPE_TEXT_URL,
  SLOTS_SELECT_TYPE_HYPER_LINKED,
  SLOTS_LINK_ALONE,
} from "../../../../services/globalVariables";
import DisabledButton from "../../../disabledButton";
import MoreInformationHint from "../../../moreInformationHint";
import InputFieldWithBlocks from "../../../inputFieldWithBlocks";
import availabilityBroadcast from "../../../../broadcasts/availabilityBroadcast";
import {
  convertCopyFromPlaceHolders,
  getSlotsPresets,
  getDefaultSlotsCopy,
  getBufferDaysHoursMinutesFromMinutes,
  convertBufferDaysHoursMinutesToMinutes,
  getBlockedCalendarsID,
  getDefaultMinuteListForReactSelect,
  doesListOfQuestionsIncludeCompany,
  doesTitleIncludeCompanyQuestion,
  isSameQuestion,
} from "../../../../lib/availabilityFunctions";
import {
  SLOTS_STYLE,
  SLOTS_PLAIN_TEXT,
  SLOTS_PLAIN_TEXT_URL,
  SLOTS_RICH_TEXT,
  SLOTS_PLAIN_TEXT_COPY,
  SLOTS_PLAIN_TEXT_URL_COPY,
  SLOTS_PLAIN_TEXT_URL_LINK_COPY,
  SLOTS_RICH_TEXT_COPY,
  HOLD_COLOR_ID,
  HOLD_TITLE,
  ADD_ATTENDEE_TO_HOLDS,
  BACKEND_AVAILABILITY_SETTINGS_NAMES,
} from "../../../../lib/vimcalVariables";
import { getCalendarFromProviderID } from "../../../../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import {
  CustomQuestions,
  ModifyCustomQuestionMode,
  DEFAULT_CUSTOM_QUESTIONS,
} from "../../../customQuestions";
import { ModifyCustomQuestionModal } from "../../../modifyCustomQuestionModal";
import classNames from "classnames";
import { getCalendarProviderId } from "../../../../services/calendarAccessors";
import BufferFromNow from "../../../availability/bufferFromNow";
import SelectBlockedCalendars from "../../../availability/selectBlockedCalendars";
import { getBlockedCalendars } from "../../../../services/accountFunctions";
import { isVersionV2 } from "../../../../services/versionFunctions";
import { isUserMaestroUser } from "../../../../services/maestroFunctions";
import CustomizeSlotsHolds from "./CustomizeSlotsHolds";
import { getReactSelectBaseStyle } from "../../../select/styles";
import DefaultSwitch from "../../../defaultSwitch";
import { SHOW_EVERY_TIME_ZONE_ID } from "../../../../services/elementIDVariables";
import DropdownIndicator from "../../../select/dropDownIndicator";
import ColoredLine from "../../../line";
import {
  SELECT_USER_TYPE,
  SelectUser,
  getAllUsers,
} from "../../common/selectUser";
import EventModalPopup from "../../../eventModalPopup";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import { equalAfterTrimAndLowerCased } from "../../../../lib/stringFunctions";
import { blurCalendar } from "../../../../services/appFunctions";
import { getUserEmail } from "../../../../lib/userFunctions";
import CreatableSelect from "../../../../../components/creatableSelect";
import { determineDefaultModalStyle } from "../../../../lib/modalFunctions";

const SELECT_USER_ID = "slots-select-user-id";

const MODAL_CONTENT = {
  BLOCKED_CALENDARS: "BLOCKED_CALENDARS",
  CUSTOM_QUESTIONS: "CUSTOM_QUESTIONS",
};

const DROPDOWN_OPTIONS = [
  {
    value: "{{time_zone}}",
    label: "Time zone",
    extraTextOnSelect: String.fromCharCode(160),
  },
  {
    value: "{{duration}}",
    label: "Duration",
    extraTextOnSelect: " minutes",
  },
];

const SECTION_LABEL_DEFAULT_CLASSNAME = "default-font-size font-weight-300";
export default function AvailabilitySettingsContainer({ initialUser }) {
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const [minuteList, setMinutesList] = useState(
    getDefaultMinuteListForReactSelect(true)
  );
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const [isShowingContent, setShowingContent] = useState(true); // so we can unmount and reset everything
  const [modalContent, setModalContent] = useState(null);

  const getUser = () => {
    return selectedUser ?? currentUser;
  };
  const getUsers = () => {
    return getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: SELECT_USER_TYPE.ALL_USERS,
      addExecutiveLabel: true,
    });
  };

  const getMatchingInitialUserIndex = () => {
    const matchingIndex = getUsers().findIndex((user) => {
      return equalAfterTrimAndLowerCased(
        user?.value?.email,
        initialUser?.email
      );
    });
    return matchingIndex >= 0 ? matchingIndex : 0; //0 is always the current user
  };

  const [selectedUserIndex, setSelectedUserIndex] = useState(
    getMatchingInitialUserIndex()
  );
  const selectedUser = getUsers()[selectedUserIndex]?.value;
  const userRef = useRef(getUser());

  const availabilitySettings = getUser().availability_settings;
  const {
    title: inputTitle,
    duration: inputDuration,
    conferencing: inputConferencing,
    googleId: inputGoogleId,
    location: inputLocation,
    description: inputDescription,
    customQuestions: inputCustomQuestions,
    bufferFromNow: inputBufferFromNow,
    allowRescheduleAndCancel: inputAllowRescheduleAndCancel,
    combineAdjacentSlots: inputCombineAdjacentSlots,
    [HOLD_TITLE]: inputHoldTitle,
    [HOLD_COLOR_ID]: inputHoldColorID,
    [ADD_ATTENDEE_TO_HOLDS]: inputShouldAddAttendeesToHolds,
    bufferAfterEvent: inputBufferAfterEvent,
    bufferBeforeEvent: inputBufferBeforeEvent,
  } = getSlotsPresets({ allCalendars, currentUser: getUser(), masterAccount });

  useEffect(() => {
    // changes user
    const user = getUsers()[selectedUserIndex]?.value ?? currentUser;
    if (userRef.current.email === user?.email) {
      return;
    }
    userRef.current = user;
    setShowingContent(false);
    // Set it back to true after a delay
    setTimeout(() => {
      setShowingContent(true);
    }, 5); // Adjust the delay time as needed
    const updatedAvailabilitySettings = user.availability_settings;
    const {
      title: updatedInputTitle,
      duration: updatedInputDuration,
      conferencing: updatedInputConferencing,
      googleId: updatedInputGoogleId,
      location: updatedInputLocation,
      description: updatedInputDescription,
      customQuestions: updatedInputCustomQuestions,
      bufferFromNow: updatedInputBufferFromNow,
      allowRescheduleAndCancel: updatedInputAllowRescheduleAndCancel,
      combineAdjacentSlots: updatedInputCombineAdjacentSlots,
      [HOLD_TITLE]: updatedInputHoldTitle,
      [HOLD_COLOR_ID]: updatedInputHoldColorID,
      [ADD_ATTENDEE_TO_HOLDS]: updatedInputShouldAddAttendeesToHolds,
      bufferAfterEvent: updatedInputBufferAfterEvent,
      bufferBeforeEvent: updatedInputBufferBeforeEvent,
    } = getSlotsPresets({
      allCalendars,
      currentUser: user,
      masterAccount,
    });
    setEventTitle(updatedInputTitle);
    setLocation(updatedInputLocation);
    setHoldTitle(updatedInputHoldTitle);
    setHoldColorID(updatedInputHoldColorID);
    setShouldAddAttendeesToHolds(updatedInputShouldAddAttendeesToHolds);
    setDuration(updatedInputDuration);
    setMinuteQuery("");
    setConferencing(updatedInputConferencing);
    setDescription(updatedInputDescription);
    setHasChanged(false);
    isFirstUpdate.current = true;
    setSelectedCalendar(
      getCalendarFromProviderID({
        allCalendars,
        providerID: updatedInputGoogleId,
        selectedUser: user,
        allLoggedInUsers,
        masterAccount,
      })
    );
    setCustomQuestions(
      isEmptyObjectOrFalsey(updatedInputCustomQuestions)
        ? DEFAULT_CUSTOM_QUESTIONS
        : updatedInputCustomQuestions
    );
    setBufferFromNow(
      getBufferDaysHoursMinutesFromMinutes(updatedInputBufferFromNow)
    );
    setAllowReschedule(updatedInputAllowRescheduleAndCancel);

    const blockedCalendars = getBlockedCalendars({user, allLoggedInUsers, allCalendars, masterAccount});
    setBlockedCalendars(blockedCalendars);
    setBlockedCalendarsID(getBlockedCalendarsID(blockedCalendars));
    setCombineAdjacentTimeSlots(updatedInputCombineAdjacentSlots);
    setBufferBeforeEvent(updatedInputBufferBeforeEvent);
    setBufferAfterEvent(updatedInputBufferAfterEvent);

    setPlainTextCopy(
      getDefaultSlotsCopy(SLOTS_PLAIN_TEXT, updatedAvailabilitySettings)
        .preSlotsCopy
    );
    setPlainTextURLCopy(
      getDefaultSlotsCopy(SLOTS_PLAIN_TEXT_URL, updatedAvailabilitySettings)
        .preSlotsCopy
    );
    setPlainTextURLLinkCopy(
      getDefaultSlotsCopy(SLOTS_PLAIN_TEXT_URL, updatedAvailabilitySettings)
        .linkCopy
    );
    setRichTextCopy(
      getDefaultSlotsCopy(SLOTS_RICH_TEXT, updatedAvailabilitySettings)
        .preSlotsCopy
    );
    return () => {};
  }, [selectedUserIndex]);

  const [bufferBeforeEvent, setBufferBeforeEvent] = useState(
    inputBufferBeforeEvent
  );
  const [bufferAfterEvent, setBufferAfterEvent] = useState(
    inputBufferAfterEvent
  );

  const [selectedSlotType, setSlotType] = useState(
    availabilitySettings?.slots_style ?? SLOTS_PLAIN_TEXT_URL
  );

  // copies for each type
  const [plainTextCopy, setPlainTextCopy] = useState(
    getDefaultSlotsCopy(SLOTS_PLAIN_TEXT, availabilitySettings).preSlotsCopy
  );
  const [plainTextURLCopy, setPlainTextURLCopy] = useState(
    getDefaultSlotsCopy(SLOTS_PLAIN_TEXT_URL, availabilitySettings).preSlotsCopy
  );
  const [plainTextURLLinkCopy, setPlainTextURLLinkCopy] = useState(
    getDefaultSlotsCopy(SLOTS_PLAIN_TEXT_URL, availabilitySettings).linkCopy
  );
  const [richTextCopy, setRichTextCopy] = useState(
    getDefaultSlotsCopy(SLOTS_RICH_TEXT, availabilitySettings).preSlotsCopy
  );

  const descriptionRef = useRef(null);

  const [attendees] = useState([]);
  const [location, setLocation] = useState(inputLocation);
  const [eventTitle, setEventTitle] = useState(inputTitle);
  const [holdTitle, setHoldTitle] = useState(inputHoldTitle);
  const [holdColorID, setHoldColorID] = useState(inputHoldColorID);
  const [shouldAddAttendeesToHolds, setShouldAddAttendeesToHolds] = useState(
    inputShouldAddAttendeesToHolds
  );
  const [duration, setDuration] = useState(inputDuration);
  const [minutesQuery, setMinuteQuery] = useState("");
  const [conferencing, setConferencing] = useState(inputConferencing);
  const [description, setDescription] = useState(inputDescription);
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(
    getCalendarFromProviderID({
      allCalendars,
      providerID: inputGoogleId,
      selectedUser: getUser(),
      allLoggedInUsers,
      masterAccount,
    })
  );
  const [customQuestions, setCustomQuestions] = useState(
    isEmptyObjectOrFalsey(inputCustomQuestions)
      ? DEFAULT_CUSTOM_QUESTIONS
      : inputCustomQuestions
  );

  const [selectedCustomQuestionIndex, setSelectedCustomQuestionIndex] =
    useState(-1);
  const [modifyCustomQuestionMode, setModifyCustomQuestionMode] =
    useState(null);

  const [bufferFromNow, setBufferFromNow] = useState(
    getBufferDaysHoursMinutesFromMinutes(inputBufferFromNow)
  );
  const [allowRescheduleAndCancel, setAllowReschedule] = useState(
    inputAllowRescheduleAndCancel
  );
  const [blockedCalendars, setBlockedCalendars] = useState(
    getBlockedCalendars({user: getUser(), allLoggedInUsers, allCalendars, masterAccount}),
  );
  const [blockedCalendarsID, setBlockedCalendarsID] = useState(
    getBlockedCalendarsID(getBlockedCalendars({user: getUser(), allLoggedInUsers, allCalendars, masterAccount})),
  );

  const [combineAdjacentTimeSlots, setCombineAdjacentTimeSlots] = useState(
    inputCombineAdjacentSlots
  );

  const isFirstUpdate = useRef(true); // avoid useEffect in initial render
  useEffect(() => {
    if (isFirstUpdate.current || hasChanged) {
      isFirstUpdate.current = false;
      return;
    }

    setHasChanged(true);
  }, [
    plainTextCopy,
    plainTextURLCopy,
    plainTextURLLinkCopy,
    richTextCopy,
    attendees,
    location,
    duration,
    conferencing,
    description,
    selectedCalendar,
    eventTitle,
    customQuestions,
    blockedCalendars,
    blockedCalendarsID,
    combineAdjacentTimeSlots,
    holdTitle,
    holdColorID,
    shouldAddAttendeesToHolds,
    bufferBeforeEvent,
    bufferAfterEvent,
  ]);

  const onSelectMinutes = (selected) => {
    if (isInt(selected?.value)) {
      let value = Math.abs(removeLeadingZeros(selected.value));
      setDuration(value);
    }
  };

  const renderTabs = () => {
    return (
      <div className="flex mt-5">
        {[
          SLOTS_SELECT_TYPE_HYPER_LINKED,
          SLOTS_SELECT_TYPE_TEXT_URL,
          SLOTS_SELECT_TYPE_PLAIN_TEXT,
        ].map((t, index) => {
          const isSelected = t.value === selectedSlotType;
          return (
            <div
              className={classnames(
                "flex-grow flex items-center justify-center text-center",
                "h-9 border-solid",
                "border-0 border-bottom-width-1px-important border-left-width-0px-override border-top-width-0px-override border-right-width-0px-override",
                "transition-colors duration-200",
                isSelected ? "border-green-400" : "border-transparent-override",
                "cursor-pointer",
                isSelected ? "" : "disabled-text-color",
                "default-font-size"
              )}
              key={`slots_setting_tab_${t.label}`}
              onClick={() => setSlotType(t.value)}
            >
              {t.label}
            </div>
          );
        })}
      </div>
    );
  };

  const renderPreSlotsCopySection = () => {
    const determinePreSlotCopy = () => {
      switch (selectedSlotType) {
        case SLOTS_PLAIN_TEXT:
          return plainTextCopy;
        case SLOTS_PLAIN_TEXT_URL:
          return plainTextURLCopy;
        case SLOTS_RICH_TEXT:
          return richTextCopy;
        default:
          return richTextCopy;
      }
    };

    const determineSetPreSlotCopyMethod = () => {
      switch (selectedSlotType) {
        case SLOTS_PLAIN_TEXT:
          return setPlainTextCopy;
        case SLOTS_PLAIN_TEXT_URL:
          return setPlainTextURLCopy;
        case SLOTS_RICH_TEXT:
          return setRichTextCopy;
        default:
          return setRichTextCopy;
      }
    };

    return (
      <div className="flex justify-between">
        <div
          className={classnames(
            SECTION_LABEL_DEFAULT_CLASSNAME,
            "flex",
            "pr-2",
            "secondary-text-color"
          )}
        >
          Pre-Slots copy
          <MoreInformationHint
            containerClassName="ml-2.5 default-font-color"
            variables={["{{time_zone}}", "{{duration}}"]}
            hintContainerClassName={isDarkMode ? "bg-gray-500" : ""}
          />
        </div>
        <InputFieldWithBlocks
          containerClassname={classnames(
            selectedSlotType === SLOTS_RICH_TEXT
              ? "availability-setting-rich-text-copy-container"
              : "availability-setting-copy-container",
            " availability-content-width-important"
          )}
          initialSummary={determinePreSlotCopy()}
          options={DROPDOWN_OPTIONS}
          setText={determineSetPreSlotCopyMethod()}
          suggestionWidth={325}
          containerID={"pre-slots-copy-container"}
          resetCounter={`${selectedSlotType}-${getUser()?.email}`}
        />
      </div>
    );
  };

  const renderLinkCopy = () => {
    return (
      <div className="flex justify-between slots-row-default-margin-top">
        <div
          className={classnames(
            SECTION_LABEL_DEFAULT_CLASSNAME,
            "flex",
            "secondary-text-color"
          )}
        >
          Link copy
          <MoreInformationHint
            containerClassName="ml-2.5 default-font-color"
            variables={["{{time_zone}}", "{{duration}}"]}
            hintContainerClassName={isDarkMode ? "bg-gray-500" : ""}
          />
        </div>
        <InputFieldWithBlocks
          containerClassname={
            "availability-setting-copy-container availability-content-width-important"
          }
          initialSummary={plainTextURLLinkCopy}
          options={DROPDOWN_OPTIONS}
          setText={setPlainTextURLLinkCopy}
          suggestionWidth={325}
          containerID={"link-copy-container"}
          resetCounter={`${getUserEmail(getUser())}`}
        />
      </div>
    );
  };

  const onClickCloseModal = () => {
    setModalContent(null);
    setSelectedCustomQuestionIndex(-1);
    setModifyCustomQuestionMode(null);
  };

  const renderModal = () => {
    const getModalDetails = () => {
      switch (modalContent) {
        case MODAL_CONTENT.BLOCKED_CALENDARS:
          return {
            width: "700px",
            title: "Select default check for conflict calendars",
          };
        case MODAL_CONTENT.CUSTOM_QUESTIONS:
          return {
            width: "700px",
            title: "Custom questions",
          };
        default:
          return {
            width: "700px",
            title: "",
          };
      }
    };
    const { width, title } = getModalDetails();

    const renderContent = () => {
      switch (modalContent) {
        case MODAL_CONTENT.BLOCKED_CALENDARS:
          return (
            <SelectBlockedCalendars
              onClickSave={(updatedBlockedCalendarsID) => {
                setBlockedCalendarsID(updatedBlockedCalendarsID);
                onClickCloseModal();
              }}
              inputBlockedCalendars={blockedCalendars}
              blockedCalendarsID={blockedCalendarsID}
              updateLabel={"Apply"}
              onClickCancel={onClickCloseModal}
            />
          );
        case MODAL_CONTENT.CUSTOM_QUESTIONS:
          switch (modifyCustomQuestionMode) {
            case ModifyCustomQuestionMode.Add:
              return (
                <ModifyCustomQuestionModal
                  closeModal={() => {
                    setModifyCustomQuestionMode(null);
                    onClickCloseModal();
                  }}
                  applyChange={(question) => {
                    const newCustomQuestions = [...customQuestions].concat(
                      question
                    );
                    setCustomQuestions(newCustomQuestions);
                    setModifyCustomQuestionMode(null);
                    onClickCloseModal();
                  }}
                />
              );
            case ModifyCustomQuestionMode.Edit:
              const selectedIndexWithCompany =
                doesListOfQuestionsIncludeCompany(customQuestions)
                  ? selectedCustomQuestionIndex
                  : selectedCustomQuestionIndex - 1;
              return (
                <ModifyCustomQuestionModal
                  closeModal={() => {
                    setModifyCustomQuestionMode(null);
                    onClickCloseModal();
                  }}
                  applyChange={(question) => {
                    let newCustomQuestions = [...customQuestions];
                    newCustomQuestions[selectedIndexWithCompany] = question;
                    setCustomQuestions(newCustomQuestions);
                    onClickCloseModal();
                  }}
                  selectedCustomQuestion={
                    customQuestions[selectedIndexWithCompany]
                  }
                />
              );
            default:
              return null;
          }
        default:
          return null;
      }
    };

    return (
      <EventModalPopup
        isOpen={!!modalContent}
        onRequestClose={onClickCloseModal}
        width={width}
        title={title}
        style={determineDefaultModalStyle(isDarkMode)}
      >
        {renderContent()}
      </EventModalPopup>
    );
  };
  const renderPreviewSection = () => {
    const determineText = () => {
      switch (selectedSlotType) {
        case SLOTS_PLAIN_TEXT:
          return {
            topText: plainTextCopy,
            bottomText: "",
          };
        case SLOTS_PLAIN_TEXT_URL:
          return {
            topText: plainTextURLCopy,
            bottomText: plainTextURLLinkCopy,
          };
        case SLOTS_RICH_TEXT:
          return {
            topText: richTextCopy,
            bottomText: "",
          };
        default:
          return {
            topText: richTextCopy,
            bottomText: "",
          };
      }
    };

    const { topText, bottomText } = determineText();

    const renderBottomSection = () => {
      if (!bottomText) {
        return null;
      }

      return (
        <div className="mt-2">
          {convertCopyFromPlaceHolders({
            copy: bottomText,
            currentTimeZone,
            duration,
            extraTimeZones: getAllTimeZones(),
          })}
          <div
            className={classnames(
              "w-64 h-5 mt-2",
              "background-color-greyed-out"
            )}
          ></div>
        </div>
      );
    };

    const getAllTimeZones = () => {
      return removeDuplicatesFromArray([
        currentTimeZone,
        "America/Los_Angeles",
        "America/New_York",
      ]);
    };

    return (
      <div className="flex justify-between slots-row-default-margin-top">
        <div
          className={classnames(
            SECTION_LABEL_DEFAULT_CLASSNAME,
            "secondary-text-color"
          )}
        >
          Preview
        </div>
        <div
          className={classnames(
            "resize-none",
            "default-font-size font-weight-300",
            "w-32",
            "bg-transparent",
            "dark-mode-inside-modal-content-border rounded p-2.5",
            "availability-content-width-important",
            "select-none",
            "padding-bottom-16px-important",
            "overflow-x-hidden break-words"
          )}
          style={{
            backgroundColor: isDarkMode ? "#2F313F" : "#F2F2F2",
            color: "rgb(161, 161, 161)",
          }}
        >
          {convertCopyFromPlaceHolders({
            copy: topText,
            currentTimeZone,
            duration,
            extraTimeZones: getAllTimeZones(),
          })}
          <div
            className={classnames(
              "w-52 h-5 mt-2",
              "background-color-greyed-out"
            )}
          ></div>
          <div
            className={classnames(
              "w-36 h-5 mt-2 ml-6",
              "background-color-greyed-out"
            )}
          ></div>
          <div
            className={classnames(
              "w-36 h-5 mt-2 ml-6",
              "background-color-greyed-out"
            )}
          ></div>

          <div
            className={classnames(
              "w-52 h-5 mt-2",
              "background-color-greyed-out"
            )}
          ></div>
          <div
            className={classnames(
              "w-36 h-5 mt-2 ml-6",
              "background-color-greyed-out"
            )}
          ></div>

          {renderBottomSection()}
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="flex items-end justify-end pr-2.5">
        {hasChanged ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={onClickSave}
            label="Save"
          />
        ) : (
          <DisabledButton label="Save" />
        )}
      </div>
    );
  };

  const onChangeDescription = (html) => {
    setDescription(html);
    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const onChangeAllowReschedule = () => {
    setAllowReschedule(!allowRescheduleAndCancel);
    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const onClickSave = () => {
    // Available options:
    // pre_slots_copy
    // link_copy
    // title
    // duration
    // conferencing
    // attendees //* skipping for now
    // google_calendar_id for v1 and calendar_provider_id for v2
    // location
    // description
    // hyperlinked
    // availability_type
    // buffer before event
    // buffer after event
    const updatedSettings = {
      [SLOTS_PLAIN_TEXT_COPY]: plainTextCopy,
      [SLOTS_PLAIN_TEXT_URL_COPY]: plainTextURLCopy,
      [SLOTS_PLAIN_TEXT_URL_LINK_COPY]: plainTextURLLinkCopy,
      [SLOTS_RICH_TEXT_COPY]: richTextCopy,
      title: eventTitle,
      duration,
      conferencing: conferencing?.value,
      location,
      description: description,
      custom_questions: customQuestions,
      buffer_from_now: convertBufferDaysHoursMinutesToMinutes(bufferFromNow),
      allow_reschedule_and_cancel: allowRescheduleAndCancel,
      blocked_calendar_ids: blockedCalendarsID,
      combine_adjacent_slots: combineAdjacentTimeSlots,
      [ADD_ATTENDEE_TO_HOLDS]: shouldAddAttendeesToHolds,
      buffer_before: bufferBeforeEvent,
      buffer_after: bufferAfterEvent,
    };

    if (isUserMaestroUser(masterAccount)) {
      updatedSettings[HOLD_COLOR_ID] = holdColorID;
      updatedSettings[HOLD_TITLE] = holdTitle;
    }

    if (isVersionV2()) {
      updatedSettings["calendar_provider_id"] =
        getCalendarProviderId(selectedCalendar);
    } else {
      updatedSettings["google_calendar_id"] =
        getCalendarProviderId(selectedCalendar);
    }

    availabilityBroadcast.publish(
      "SAVE_SLOTS_SETTINGS",
      updatedSettings,
      getUser()
    );
    availabilityBroadcast.publish(
      "SET_COMBINE_ADJACENT_SLOTS",
      combineAdjacentTimeSlots
    );

    setHasChanged(false);
    isFirstUpdate.current = true;
  };

  const onClickOpenBlockingCalendars = () => {
    setModalContent(MODAL_CONTENT.BLOCKED_CALENDARS);
  };

  const meetingDetailsSection = () => {
    return (
      <>
        <div className="mt-2.5">
          <AvailabilityAdditionalDetails
            selectedUser={getUser()}
            // onChangeAttendees={setAttendees}
            // attendees={attendees}
            location={location}
            onChangeLocation={setLocation}
            onChangeTitle={setEventTitle}
            eventTitle={eventTitle}
            onSelectMinutes={onSelectMinutes}
            duration={duration}
            minutesQuery={minutesQuery}
            onDurationInputChange={setMinuteQuery}
            // conferencing={conferencing}
            // onSelectConferencing={setConferencing}
            description={description}
            selectedCalendar={selectedCalendar}
            onChangeCalendar={setSelectedCalendar}
            justifyBetweenLabel={true}
            ignoreBroadcast={true}
            descriptionRef={descriptionRef}
            onChangeDescription={onChangeDescription}
            moveTitleHintNextToTitle={true}
            containerId="availabiity-settings"
            allowRescheduleAndCancel={allowRescheduleAndCancel}
            onChangeAllowReschedule={onChangeAllowReschedule}
            onSelectBlockingCalendars={onClickOpenBlockingCalendars}
            blockedCalendars={blockedCalendars}
            blockedCalendarsID={blockedCalendarsID}
            setCombineAdjacentTimeSlots={setCombineAdjacentTimeSlots}
            combineAdjacentTimeSlots={combineAdjacentTimeSlots}
            customQuestions={customQuestions}
          />
        </div>
      </>
    );
  };

  const customQuestionsSection = () => {
    return (
      <CustomQuestions
        showModifyQuestionModal={(mode) => {
          setModifyCustomQuestionMode(mode);
          setModalContent(MODAL_CONTENT.CUSTOM_QUESTIONS);
        }}
        questions={customQuestions}
        onSelectIndex={(index) => setSelectedCustomQuestionIndex(index)}
        deleteCustomQuestion={(question) => {
          const updatedQuestions = customQuestions.filter((q, i) => {
            return !isSameQuestion(q, question);
          });
          setCustomQuestions(updatedQuestions);
        }}
        setQuestions={(questions) => {
          setCustomQuestions(questions);
        }}
        isAvailabilitySettings={true}
        showWarningForCompany={
          !doesListOfQuestionsIncludeCompany(customQuestions) &&
          doesTitleIncludeCompanyQuestion(eventTitle)
        } // if user has the company variable but does not have company toggled on
      />
    );
  };

  const customSlotsHolds = () => {
    return (
      <div className="pb-5">
        <CustomizeSlotsHolds
          holdTitle={holdTitle}
          setHoldTitle={setHoldTitle}
          holdColorID={holdColorID}
          setHoldColorID={setHoldColorID}
          shouldAddAttendeesToHolds={shouldAddAttendeesToHolds}
          setShouldAddAttendeesToHolds={setShouldAddAttendeesToHolds}
          selectedCalendar={selectedCalendar}
          selectedUser={selectedUser}
        />
      </div>
    );
  };

  const custombufferFromNow = () => {
    return (
      <div className="mt-5">
        <BufferFromNow
          bufferFromNow={bufferFromNow}
          hasChanged={hasChanged}
          setBufferFromNow={setBufferFromNow}
          setHasChanged={setHasChanged}
        />
      </div>
    );
  };

  const bufferSection = () => {
    return (
      <div>
        {custombufferFromNow()}
        {bufferFromEvents()}
        {isUserMaestroUser(masterAccount) ? null : <div className="h-36"></div>}
      </div>
    );
  };

  const bufferFromEvents = () => {
    const onSelectBufferBefore = (selectValue) => {
      setBufferBeforeEvent(selectValue.value);
    };

    const onSelectBufferAfter = (selectValue) => {
      setBufferAfterEvent(selectValue.value);
    };
    return (
      <div className="mt-5 display-flex-flex-direction-row">
        <div style={{ width: 170 }}>
          <div className="font-weight-300 default-font-size secondary-text-color">
            Buffer before conflicts
          </div>

          <div className="display-flex-flex-direction-row align-items-center mt-2">
            <div>
              <CreatableSelect
                isSearchable={true}
                // ref={this._selectBufferBefore}
                className={classNames(
                  "select-duration-time",
                  isDarkMode ? "dark-mode-select" : "",
                  "select-default-font-size"
                )}
                classNamePrefix="dark-mode"
                value={{
                  value: bufferBeforeEvent,
                  label: bufferBeforeEvent,
                }}
                options={minuteList}
                onChange={onSelectBufferBefore}
                noOptionsMessage={() => "Set"}
                openMenuOnFocus={true}
                // onKeyDown={this.onKeyDown}
                formatCreateLabel={(userInput) => userInput}
                tabSelectsValue={true}
                tabIndex={3}
                styles={getReactSelectBaseStyle({
                  isDarkMode,
                  showBorder: true,
                })}
                components={{ DropdownIndicator }}
              />
            </div>

            <div className="font-weight-300 default-font-size ml-2.5">
              mins
            </div>
          </div>
        </div>

        <div style={{ width: 170 }}>
          <div className="font-weight-300 default-font-size ml-2.5 secondary-text-color">
            Buffer after conflicts
          </div>

          <div className="display-flex-flex-direction-row align-items-center mt-2">
            <div>
              <CreatableSelect
                isSearchable={true}
                // ref={this._selectBufferAfter}
                className={classNames(
                  "select-duration-time",
                  isDarkMode ? "dark-mode-select" : "",
                  "select-default-font-size"
                )}
                classNamePrefix="dark-mode"
                value={{
                  value: bufferAfterEvent,
                  label: bufferAfterEvent,
                }}
                options={minuteList}
                onChange={onSelectBufferAfter}
                noOptionsMessage={() => "Set"}
                openMenuOnFocus={true}
                // onKeyDown={(e) => this.onKeyDown(e, true)}
                formatCreateLabel={(userInput) => userInput}
                tabSelectsValue={true}
                tabIndex={4}
                styles={getReactSelectBaseStyle({
                  isDarkMode,
                  showBorder: true,
                })}
                components={{ DropdownIndicator }}
              />
            </div>

            <div className="font-weight-300 default-font-size ml-2.5">
              mins
            </div>
          </div>
        </div>
      </div>
    );
  };

  const defaultCopyableText = () => {
    return (
      <>
        {renderTabs()}
        <div
          className={classnames(
            "py-4",
            isDarkMode ? "border-gray-600" : "border-gray-200"
          )}
          style={{
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            paddingLeft: "0px",
          }}
        >
          {renderPreSlotsCopySection()}
          {selectedSlotType === SLOTS_PLAIN_TEXT_URL ? renderLinkCopy() : null}
          {renderPreviewSection()}
        </div>
      </>
    );
  };

  const HEADER_LABEL_CLASSNAME = "font-size-16 font-weight-400";

  if (!isShowingContent) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex-grow flex flex-col overflow-y-auto pr-5">
        <SelectUser
          setSelectedUserIndex={setSelectedUserIndex}
          id={SELECT_USER_ID}
          selectedUser={selectedUser}
          inputContainerClassName="settings-select-user-position"
          addExecutiveLabel={true}
          showAccountLabel={true}
        />
        <div className={classNames(HEADER_LABEL_CLASSNAME)}>
          Copyable Text
        </div>
        {defaultCopyableText()}
        <div className={classNames(HEADER_LABEL_CLASSNAME, "mt-8")}>
          Meeting Details
          <ColoredLine inputClassName="my-2" />
        </div>
        {meetingDetailsSection()}

        <div className={classNames(HEADER_LABEL_CLASSNAME, "mt-8")}>
          Custom Questions
          <ColoredLine inputClassName="my-2" />
        </div>
        {customQuestionsSection()}

        <div className={classNames(HEADER_LABEL_CLASSNAME, "mt-4")}>
          Scheduling Buffer
          <ColoredLine inputClassName="my-2" />
        </div>
        {bufferSection()}

        {isUserMaestroUser(masterAccount) ? (
          <div className={classNames(HEADER_LABEL_CLASSNAME, "mt-8")}>
            Slots Holds
            <ColoredLine inputClassName="my-2" />
          </div>
        ) : null}

        {isUserMaestroUser(masterAccount) ? customSlotsHolds() : null}
      </div>
      {renderModal()}
      {renderButtons()}
    </div>
  );
}
