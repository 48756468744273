import classNames from "classnames";
import React from "react";
import { Check } from "react-feather";

type CheckBoxProps = {
  backgroundColor?: string;
  className?: string;
  id?: string;
  isChecked?: boolean;
  borderColor?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  borderWidth?: number;
};

export default function CheckBox({
  backgroundColor,
  className,
  id,
  isChecked,
  borderColor,
  style,
  disabled,
  onChange,
  borderWidth,
}: CheckBoxProps) {
  const determineBackgroundColor = () => {
    if (backgroundColor) {
      return backgroundColor;
    }

    // handle using scss (.check-box)
    return undefined;
  };

  const determineBorderColor = () => {
    if (borderColor) {
      return borderColor;
    } else if (backgroundColor) {
      return backgroundColor;
    }

    return undefined;
  };

  return (
    <button
      className={classNames(
        className,
        "check-box",
        isChecked ? "checked" : "border-transition-200ms-override",
        "flex-shrink-0",
      )}
      id={id}
      style={Object.assign(
        {
          backgroundColor: determineBackgroundColor(),
          borderColor: determineBorderColor(),
          borderWidth,
        },
        style,
      )}
      disabled={disabled}
      onClick={onChange}
    >
      {isChecked ? (
        <Check
          color={
            "white"
          }
          size="12"
        />
      ) : null}
    </button>
  );
}
