import classNames from "classnames";
import React from "react";

interface LoadingSkeletonProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export default function LoadingSkeleton({ children, className, ...props }: LoadingSkeletonProps) {
  return (
    <div
      className={classNames("skeleton", className)}
      {...props}
    >{children}</div>
  );
}
