import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Broadcast from "../broadcasts/broadcast";
import { ATTENDEE_EVENT_ATTENDING, ATTENDEE_EVENT_NEEDS_ACTION } from "../services/googleCalendarService";
import { useAllCalendars } from "../services/stores/SharedAccountData";
import { useEnrichedContactsStore } from "../services/stores/enrichedContacts";
import EnrichedContact from "./contact/enrichedContact";
import { immutablySortArray, isEmptyArray } from "../lib/arrayFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { isEventAttendeeOrganizer } from "../lib/eventFunctions";
import { isSameEmail } from "../lib/stringFunctions";
import { getUserEmail } from "../lib/userFunctions";

class FullAttendeeList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sortedAttendees: this.sortedAttendeeList(),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchContactsInfoFromDB();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.attendees !== prevProps.attendees) {
      this.fetchContactsInfoFromDB();
      this.setState({ sortedAttendees: this.sortedAttendeeList() });
    } else if (this.props.emailToNameIndex !== prevProps.emailToNameIndex) {
      this.setState({ sortedAttendees: this.sortedAttendeeList() });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return this.renderIndividualAttendee();
  }

  //================
  // RENDER METHODS
  //================

  renderIndividualAttendee() {
    return this.state.sortedAttendees
      .map((attendee) => {
        /* Get enriched details for attendee if available */
        /* Return attendee if not found */
        const attendeeEmail = getObjectEmail(attendee);
        const enrichedAttendee = this.props.enrichedContacts.find(
          (contact) => isSameEmail(getObjectEmail(contact), attendeeEmail),
        );

        return !!enrichedAttendee && !isEmptyObjectOrFalsey(enrichedAttendee)
          ? Object.assign({}, attendee, enrichedAttendee)
          : attendee;
      })
      .map((attendee, index) => {
        return (
          <React.Fragment
            key={`full_attending_list_${getObjectEmail(attendee)}_${index}`}
          >
            {this.determineRenderAttendeeWithCopyOrNot(attendee, index)}
          </React.Fragment>
        );
      });
  }

  determineRenderAttendeeWithCopyOrNot(attendee, index) {
    if (this.props.editMode) {
      return this.renderSingleAttendee(attendee, index);
    } else {
      return this.renderSingleAttendeeWithCopy(attendee, index);
    }
  }

  renderSingleAttendeeWithCopy(attendee, index) {
    return <>{this.renderSingleAttendee(attendee, index, true)}</>;
  }

  renderSingleAttendee(attendee, index, renderWithCopy = false) {
    const {
      organizer,
      isInEventForm,
      showColorBorder,
      removeAttendee,
      attendees,
      editMode,
      makeAttendeeOptional,
    } = this.props;

    return (
      <EnrichedContact
        attendee={attendee}
        attendees={attendees}
        editMode={editMode}
        index={index}
        makeAttendeeOptional={makeAttendeeOptional}
        removeAttendee={removeAttendee}
        renderWithCopy={renderWithCopy}
        showColorBorder={showColorBorder}
        isInEventForm={isInEventForm}
        organizer={organizer}
      />
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  fetchContactsInfoFromDB() {
    if (
      isEmptyArray(this.props.attendees) ||
      !getUserEmail(this.props.currentUser)
    ) {
      return;
    }

    let searchArray = [];
    this.props.attendees.forEach((a) => {
      if (a.email && !this.props.emailToNameIndex[a.email.toLowerCase()]) {
        searchArray = searchArray.concat(a.email.toLowerCase());
      }
    });

    if (isEmptyArray(searchArray)) {
      return;
    }

    Broadcast.publish("SEARCH_CONTACTS_DOMAIN_DB", searchArray);
  }

  sortedAttendeeList() {
    const {
      attendees,
      organizer,
    } = this.props;
    if (isEmptyArray(attendees)) {
      return [];
    }

    let organizerList = [];
    let attendingList = [];
    let respondedList = [];
    let restOfList = [];
    attendees.forEach((a) => {
      if (isEventAttendeeOrganizer(a) ||
        (organizer && isSameEmail(getObjectEmail(organizer), getObjectEmail(a)))
      ) {
        organizerList = organizerList.concat(a);
      } else if (a.responseStatus === ATTENDEE_EVENT_ATTENDING) {
        attendingList = attendingList.concat(a);
      } else if (a.responseStatus !== ATTENDEE_EVENT_NEEDS_ACTION) {
        respondedList = respondedList.concat(a);
      } else {
        restOfList = restOfList.concat(a);
      }
    });

    const getDisplayText = (attendee) => {
      if (isEmptyObjectOrFalsey(this.props.emailToNameIndex)) {
        return attendee?.email ?? "";
      }

      if (this.props.emailToNameIndex[getObjectEmail(attendee)]) {
        return this.props.emailToNameIndex[getObjectEmail(attendee)];
      }

      return getObjectEmail(attendee) ?? "";
    };

    const sortAttendeesAlphabetically = (list) => {
      if (isEmptyArrayOrFalsey(list)) {
        return [];
      }
      return immutablySortArray(list, (a, b) =>
        getDisplayText(a).localeCompare(getDisplayText(b)),
      );
    };

    const sortedAttendeeOrganizer = sortAttendeesAlphabetically(organizerList);
    const sortedRespondedList = sortAttendeesAlphabetically(respondedList);
    const sortedAttending = sortAttendeesAlphabetically(attendingList);
    const sortedRestOfList = sortAttendeesAlphabetically(restOfList);

    return sortedAttendeeOrganizer
      .concat(sortedAttending)
      .concat(sortedRespondedList)
      .concat(sortedRestOfList);
  }
}

function mapStateToProps(state) {
  let {
    temporarySecondaryCalendarColorsIndex,
    emailToNameIndex,
    currentUser,
    isDarkMode,
  } = state;

  return {
    temporarySecondaryCalendarColorsIndex,
    emailToNameIndex,
    currentUser,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const enrichedContacts = useEnrichedContactsStore(
    (state) => state.enrichedContacts,
  );

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      enrichedContacts={enrichedContacts}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(FullAttendeeList));
