import React from "react";
import ShortcutTile from "./shortcutTile";

interface ShortcutTilesProps {
  shortcut: string
  backgroundColor?: string | null
  border?: string | null
  color?: string | null
}

export default function ShortcutTiles({ shortcut, backgroundColor, border, color }: ShortcutTilesProps) {
  if (!shortcut) {
    return null;
  }

  const splitShortcutPhrase = shortcut.split(" ");

  return (
    <>
      {splitShortcutPhrase.map((s, index) => {
        if (["then", "and", "+"].includes(s)) {
          return (
            <div
              key={`${index}-${s}`}
              className={"display-flex-center"}
              style={{ fontSize: 12, marginLeft: 8, marginRight: 4 }}
            >
              <div className={"display-flex-center"}>{s}</div>
            </div>
          );
        } else {
          return (
            <ShortcutTile
              key={`${index}-${s}`}
              shortcut={s}
              backgroundColor={backgroundColor || "transparent"}
              border={border || "1px solid white"}
              color={color || ""}
            />
          );
        }
      })}
    </>
  );
}
