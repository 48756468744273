import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { X } from "react-feather";

export default function HoverableCircleCancelButton({ style, onClick }) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const getBaseStyle = () => {
    if (isDarkMode) {
      return {
        backgroundColor: "#442F3D",
        color: "#FE7979",
      };
    }
    return {
      backgroundColor: "#D2422E",
      color: "#FFFFFF",
    };
  };

  const combinedStyle = { ...getBaseStyle(), ...(style || {}) };

  return (
    <div
      className={classNames(
        "hoverable-visibility-non-interactable-child",
        "absolute",
        "-top-2.5 -right-0.5",
        "rounded-full w-4 h-4",
        "flex items-center justify-center"
      )}
      style={combinedStyle}
      onClick={onClick}
    >
      <X size={12} strokeWidth={2} />
    </div>
  );
}
