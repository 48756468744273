import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../customButton";
import {
  BLUE_BUTTON,
  LIGHTGRAY,
} from "../../../../services/globalVariables";
import DisabledButton from "../../../disabledButton";
import classNames from "classnames";
import {
  OWNER_ROLE,
  READER_ROLE,
  ALL_ROLES,
  determinePermissionLabel,
} from "../../../../services/googleCalendarService";
import { handleError } from "../../../../services/commonUsefulFunctions";
import ReactSelectAttendeeAutoComplete from "../../../reactSelectAttendeeAutoComplete";
import _ from "underscore";
import CustomSelect from "../../../select";
import { X } from "react-feather";
import Fetcher from "../../../../services/fetcher";
import { constructRequestURL } from "../../../../services/api";
import {
  setCalendarACL,
  getCalendarACL,
  createDefaultCalendarACL,
  orderLoggedInUsers,
} from "../../../../lib/stateManagementFunctions";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import { useIsMounted } from "../../../../services/customHooks/useIsMounted";
import {
  determineCalendarColor,
  getUserCalendarIDFromEmail,
  getOrderedAllCalendars,
  getUserCalendar,
  getCalendarName,
  isCalendarOutlookCalendar,
} from "../../../../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import {
  getCalendarEditRole,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../../../../services/calendarAccessors";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import { getReactSelectBaseStyle } from "../../../select/styles";
import { isValidEmail } from "../../../../lib/stringFunctions";
import { getUserEmail } from "../../../../lib/userFunctions";
import { isCalendarExecutiveCalendar, isUserMaestroUser, shouldHideDelegatedUser } from "../../../../services/maestroFunctions";
import ExecutiveLabel from "../../../../../components/executiveLabel";

export default function CalendarSettings(props) {
  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useIsMounted();
  useEffect(() => {
    listACL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Using an empty dependency array ensures this only runs on unmount

  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const [hasChanged, setHasChanged] = useState(false);
  const [selectedCalendarId, setSelectedCalendarId] = useState(
    props.calendarSettingCalendarId ||
      getUserCalendarIDFromEmail({ email: getUserEmail(currentUser), allCalendars, allLoggedInUsers, masterAccount }) ||
      ""
  );

  const createOwnerCalendarList = () => {
    let calendarList = [];
    Object.keys(allCalendars).forEach((k) => {
      if (getCalendarEditRole(allCalendars[k]) === OWNER_ROLE) {
        calendarList = calendarList.concat(allCalendars[k]);
      }
    });

    return calendarList;
  };

  const [ownerCalendars] = useState(createOwnerCalendarList());

  const [calendarACL, setCurrentCalendarACL] = useState(
    getCalendarACL(currentUser) ||
      createDefaultCalendarACL(Object.values(ownerCalendars))
  );

  // keep track of who we're sending new invites to
  // {userCalendarId_1: [{email, permission}]};

  const onClickSave = () => {
    backendBroadcasts.publish("UPDATE_ACL_PERMISSION", calendarACL);
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
  };

  const renderButtons = () => {
    return (
      <div className="flex items-center absolute bottom-4 right-5 mr-8">
        {hasChanged ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={onClickSave}
            label="Save"
          />
        ) : (
          <DisabledButton label="Save" />
        )}
      </div>
    );
  };

  const renderUserCalendars = (userEmail) => {
    const userCalendars = getOrderedAllCalendars({
      allCalendars: getUserCalendar(allCalendars, userEmail),
      currentUserEmail: userEmail,
      allLoggedInUsers,
      masterAccount,
    });

    return userCalendars.map((calendar, index) => {
      const calendarUserCalendarID = getCalendarUserCalendarID(calendar);
      const isSelectedCalendar = calendarUserCalendarID === selectedCalendarId;
      const isExecCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar, allLoggedInUsers });

      return (
        <div
          key={`calendar-setting-option-${index}`}
          id={`calendar-setting-option_${index}`}
          className={classNames(
            isSelectedCalendar ? "modal-selected-option-background-color" : "modal-hover-background-color",
            "cursor-pointer",
            "p-2",
            "display-flex-flex-direction-row",
            "align-middle",
            "justify-between"
          )}
          onClick={() => setSelectedCalendarId(calendarUserCalendarID)}
        >
          <div
            className="default-font-size w-72 truncate margin-top-3 h-18 select-none flex flex-row items-center"
            style={{ height: 19 }}
          >
            <div
              className="w-2 h-2 rounded-full mr-2.5 mb-px flex-shrink-0"
              style={{
                backgroundColor: determineCalendarColor(
                  allCalendars[calendarUserCalendarID]
                ),
              }}
            ></div>
            <div className="flex gap-2 items-center">
              <div className={classNames("truncate", isExecCalendar ? "max-width-160px" : "max-width-200px")}>
                {getCalendarName({
                  calendar,
                  emailToNameIndex,
                  currentUser,
                  masterAccount,
                })}
              </div>
              {isExecCalendar && <ExecutiveLabel />}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderCalendarOptions = () => {
    const orderedUserList = orderLoggedInUsers(
      allLoggedInUsers,
      currentUser.email
    );
    const filteredList = orderedUserList.filter(user => !shouldHideDelegatedUser({ user, allCalendars }));

    return filteredList.map((user, index) => {
      const userEmail = user.email;
      return (
        <div key={`user_calendar_settings_${userEmail}_${index}`}>
          <div className={classNames("default-font-size font-weight-400 mt-2.5 mx-2.5 mb-1 secondary-text-color", index !== 0 ? "mt-6" : "")}>
            {user.email}
          </div>

          {renderUserCalendars(userEmail)}
        </div>
      );
    });
  };

  const addAttendees = (attendee) => {
    if (!attendee) {
      return;
    }

    let attendeeEmailList = attendee?.emailArray || [attendee?.value];
    let updatedGroup = _.clone(calendarACL);
    let updatedGroupList = calendarACL[selectedCalendarId] || [];
    const existingEmails = updatedGroupList.map((e) => e.email?.toLowerCase());

    attendeeEmailList = attendeeEmailList.filter(
      (e) => isValidEmail(e) && !existingEmails.includes(e?.toLowerCase())
    );

    const newPermissionList = attendeeEmailList.map((e) => {
      return { email: e, permission: READER_ROLE };
    });

    updatedGroupList = updatedGroupList.concat(newPermissionList);
    updatedGroup[selectedCalendarId] = updatedGroupList;

    setCurrentCalendarACL(updatedGroup);
    setHasChanged(true);
  };

  const listACL = () => {
    let promiseList = [];
    let userCalendarIDACL = {};
    allLoggedInUsers.forEach((user) => {
      const userEmail = getUserEmail(user);
      const orderedAllCalendars = getOrderedAllCalendars({
        allCalendars: getUserCalendar(allCalendars, userEmail),
        currentUserEmail: userEmail,
        allLoggedInUsers,
        masterAccount,
      });
      orderedAllCalendars.forEach((calendar) => {
        if (getCalendarEditRole(calendar) !== OWNER_ROLE) {
          return;
        }

        const path = `calendars/${getCalendarUserCalendarID(calendar)}/acl`;
        const url = constructRequestURL(path);
        const fetchPromise = Fetcher.get(url, {}, true, user.email).then(
          (response) => {
            if (!response?.acl?.items) {
              return;
            }

            let sharedCalendarList = [];
            response.acl.items.forEach((i) => {
              if (i?.id?.includes("user")) {
                sharedCalendarList = sharedCalendarList.concat({
                  email: i.id.replace("user:", ""),
                  permission: i.role,
                });
              }
            });
            userCalendarIDACL[getCalendarUserCalendarID(calendar)] =
              sharedCalendarList;
          }
        );
        promiseList = promiseList.concat(fetchPromise);
      });
    });

    Promise.all(promiseList)
      .then(() => {
        if (!componentIsMounted?.current) {
          return;
        }
        setCalendarACL(currentUser, userCalendarIDACL); // stores it in local storage
        setCurrentCalendarACL(userCalendarIDACL);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const renderCalendarSettingDetail = () => {
    const onChangeRole = (index, updatedUserPermission) => {
      let updatedCalendarACL = _.clone(calendarACL);
      let updatedPermissionsList = updatedCalendarACL[selectedCalendarId];
      updatedPermissionsList = updatedPermissionsList
        .slice(0, index)
        .concat(updatedUserPermission)
        .concat(
          updatedPermissionsList.slice(index + 1, updatedPermissionsList.length)
        );
      updatedCalendarACL[selectedCalendarId] = updatedPermissionsList;
      setCurrentCalendarACL(updatedCalendarACL);
      if (!hasChanged) {
        setHasChanged(true);
      }
    };

    const onDeletePerson = (index) => {
      let updatedCalendarACL = _.clone(calendarACL);
      let updatedPermissionsList = updatedCalendarACL[selectedCalendarId];
      updatedPermissionsList = updatedPermissionsList
        .slice(0, index)
        .concat(
          updatedPermissionsList.slice(index + 1, updatedPermissionsList.length)
        );
      updatedCalendarACL[selectedCalendarId] = updatedPermissionsList;
      setCurrentCalendarACL(updatedCalendarACL);
      if (!hasChanged) {
        setHasChanged(true);
      }
    };

    const ownerCalendarIds = ownerCalendars.map((calendar) =>
      getCalendarUserCalendarID(calendar)
    );
    
    const matchingCalendar = allCalendars[selectedCalendarId];
    if (isCalendarOutlookCalendar(matchingCalendar)) {
      return null;
    }

    if (!ownerCalendarIds.includes(selectedCalendarId)) {
      return (
        <div className="ml-2">
          <div className="font-size-16 font-weight-500">You can</div>
          <div className="default-font-size secondary-text-color mt-2">See all event details</div>
        </div>
      )
    }

    return (
      <div className="ml-2 overflow-y-auto flex-grow">
        <div className="font-size-16 font-weight-500 mb-2.5">
          Share with specific people
        </div>

        <ReactSelectAttendeeAutoComplete
          createLabel="Add"
          defaultText="Search for people"
          addAttendees={(attendee) => addAttendees(attendee)}
          maxMenuHeight={150}
          additionalClassNames="select-attendee-nickname mb-4"
          id={`creatable-attendee-${selectedCalendarId}`}
          useRecentlySearchedContacts={true}
          includeEmailAndName={true}
          useRecentlySearched={true}
          calendarUserEmail={allCalendars[selectedCalendarId]?.userEmail}
        />

        {!!calendarACL[selectedCalendarId]
          ? calendarACL[selectedCalendarId].map((c, index) => {
              const isSelf =
                c.email ===
                getCalendarProviderId(allCalendars[selectedCalendarId]);
              return (
                <div
                  className="flex flex-row items-center h-12"
                  key={`new-shared-calendar-invites-${index}`}
                >
                  <div className="default-font-size font-size-300 truncate flex-grow mr-4">
                    {c.email}
                  </div>

                  {isSelf ? (
                    <div className="font-weight-300 default-font-size">
                      Owner
                    </div>
                  ) : (
                    <CustomSelect
                      options={ALL_ROLES}
                      value={{
                        value: c.permission,
                        label: determinePermissionLabel(c.permission),
                      }}
                      className={classNames(
                        "select-permission-type",
                        isDarkMode ? "dark-mode-select" : "",
                        "select-default-font-size"
                      )}
                      classNamePrefix="dark-mode-modal"
                      onChange={(selected) =>
                        onChangeRole(index, {
                          email: c.email,
                          permission: selected.value,
                        })
                      }
                      overrideStyles={getReactSelectBaseStyle({
                        isDarkMode
                      })}
                    />
                  )}

                  {isSelf ? null : (
                    <X
                      size={16}
                      className="clickable-icon ml-2"
                      onClick={() => onDeletePerson(index)}
                    />
                  )}
                </div>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <div className="flex h-full pb-10">
      <div
        style={{
          border: `1px solid ${isDarkMode ? "#343645" : LIGHTGRAY}`,
          width: 267,
        }}
        className="mr-2 default-corner-radius overflow-x-hidden overflow-y-auto"
      >
        {renderCalendarOptions()}
      </div>

      {renderCalendarSettingDetail()}
      {renderButtons()}
    </div>
  );
}
