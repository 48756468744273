import React from "react";

import { filterOutstandingSlotsByUser } from "../../lib/temporaryEventFunctions";
import OutstandingSlotOption from "./outstandingSlotOption";
import { useOutstandingSlotsStore } from "../../services/stores/outstandingSlots";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import classNames from "classnames";
import { OUTSTANDING_SLOTS_SECTION_ID } from "../../services/elementIDVariables";
import ColoredLine from "../line";
import useMeasuredRef from "../../services/customHooks/useMeasureRef";

const OUTSTANDING_SLOTS_SECTION_MAX_HEIGHT = 330;

interface OutstandingSlotsSectionProps {
  currentTimeZone: string
  dateFieldOrder: DateFieldOrder
  format24HourTime: boolean
  isOpen: boolean
  selectedUser: User
}

export default function OutstandingSlotsSection({ currentTimeZone, dateFieldOrder, format24HourTime, isOpen, selectedUser }: OutstandingSlotsSectionProps) {
  const { outstandingSlots } = useOutstandingSlotsStore();
  const { masterAccount } = useMasterAccount();
  const { domNode: sectionDomNode, measuredRef } = useMeasuredRef<HTMLDivElement>();

  const filteredOutstandingSlots: OutstandingSlot[] = filterOutstandingSlotsByUser(outstandingSlots, selectedUser, masterAccount);

  const sectionScrollHeight = sectionDomNode?.scrollHeight ?? 0;
  const isScrollable = sectionScrollHeight > OUTSTANDING_SLOTS_SECTION_MAX_HEIGHT;

  const style = isOpen ? { maxHeight: OUTSTANDING_SLOTS_SECTION_MAX_HEIGHT } : {};

  return (
    <>
      <div
        ref={measuredRef}
        id={OUTSTANDING_SLOTS_SECTION_ID}
        className={classNames(
          "outstanding-slots-section", 
          isOpen ? "outstanding-slots-section-visible" : "",
          // During the transition when the accordion shrinks/grows, it may add a scrollbar unless
          // we explicitly hide it.
          isScrollable ? "overflow-y-auto" : "overflow-y-hidden",
        )}
        style={style}
      >
        <div className="default-font-size mb-2 font-weight-400">
          Previously sent
        </div>
        {filteredOutstandingSlots.map((outstandingSlot, index) => (
          <OutstandingSlotOption
            key={outstandingSlot.slug}
            currentTimeZone={currentTimeZone}
            dateFieldOrder={dateFieldOrder}
            format24HourTime={format24HourTime}
            outstandingSlot={outstandingSlot}
            index={index}
          />
        ))}
      </div>
      <div className={classNames("-ml-5 -mr-5", "transition-all", isOpen ? "my-4 opacity-100" : "my-0 opacity-0")}>
        <ColoredLine />
      </div>
    </>
  );
}
