import React from "react";

import { Grid } from "react-feather";

import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";
import { useAllCalendars } from "../../services/stores/SharedAccountData";
import ColorPill from "./colorPill";
import { OUTLOOK_COLORS } from "../../services/outlookColors";
import { getEventCategories, getEventUserCalendarID } from "../../services/eventResourceAccessors";
import { doesCalendarHaveCategories, getCalendarFromUserCalendarID } from "../../lib/calendarFunctions";
import { getCalendarOwnerEmail } from "../../services/calendarAccessors";

interface CategoriesListProps {
  event: VimcalEvent | VimcalPrivateEvent
}

export default function CategoriesList({ event }: CategoriesListProps) {
  const categories = getEventCategories(event);
  const allCalendars = useAllCalendars(state => state.allCalendars);
  const outlookCategories = useOutlookCategoriesStore(state => state.outlookCategories);

  if (isEmptyArrayOrFalsey(categories)) {
    return null;
  }
  const calendar = getCalendarFromUserCalendarID({ allCalendars, userCalendarID: getEventUserCalendarID(event) });
  const ownerEmail = getCalendarOwnerEmail(calendar);

  if (!doesCalendarHaveCategories({ calendar, outlookCategories })) {
    return null;
  }

  return (
    <div className="flex items-baseline gap-2.5 margin-top-30">
      <div>
        <Grid size={13} className="secondary-text-color" />
      </div>
      <div className="categories-list-wrapper flex flex-wrap gap-2 flex-grow flex-shrink">
        {categories.map(category => {
          const presetName = outlookCategories[ownerEmail]?.find(c => c.displayName === category)?.color ?? "";
          const color = OUTLOOK_COLORS[presetName]?.hex ?? "transparent";
          return <ColorPill key={category} color={color} name={category} isReadOnly />;
        })}
      </div>
    </div>
  );
}
