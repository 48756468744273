import React, { Component } from "react";
import CommandCenter from "./commandCenter";
import { batch, connect } from "react-redux";
import * as linkify from "linkifyjs";
import {
  OpenLink,
  sortEventsJSDate,
  isBeforeMinute,
  isSameOrAfterMinute,
  isSameOrBeforeMinute,
} from "../services/commonUsefulFunctions";
import { Link, Calendar } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import { getNowOrUpcomingEvent } from "../lib/eventFunctions";
import { isSameDay, parseISO, subMinutes, addMinutes } from "date-fns";
import { withRouter } from "react-router-dom";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import { getEventDescription, getEventUserCalendarID } from "../services/eventResourceAccessors";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { getUpcomingCalendarUserCalendarIDs } from "../lib/userFunctions";
import { truncateString } from "../lib/stringFunctions";
import { immutablySortArray } from "../lib/arrayFunctions";
import { formatDate } from "../lib/dateFunctions";

class InstantOpenCommandCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventOptions: [],
      linkOptions: this.createLinkOptions(),
    };

    this.setCurrentEvents = this.setCurrentEvents.bind(this);
    Broadcast.subscribe(
      "GET_EVENTS_FOR_OPEN_COMMAND_CENTER",
      this.setCurrentEvents
    );
  }

  componentDidMount() {
    Broadcast.publish(
      "GET_EVENTS_IN_CURRENT_VIEW",
      "GET_EVENTS_FOR_OPEN_COMMAND_CENTER"
    );
  }

  componentWillUnmount() {
    Broadcast.unsubscribe("GET_EVENTS_FOR_OPEN_COMMAND_CENTER");
  }

  render() {
    if (!this.props.shouldShowCommandCenter) {
      return null;
    }

    return (
      <CommandCenter
        title="Instant Open"
        handleCloseModal={this.props.handleCloseModal}
        options={this.state.linkOptions.concat(this.state.eventOptions)}
        onChangeInput={this.updateInput}
        placeholder={this.props.event 
          ? `Search by event title or URL in description`
          : 'Search by event title'}
      />
    );
  }

  createLinkOptions() {
    const {
      event
    } = this.props;

    if (!event) {
      return [];
    }

    let links = this.getAllLinks(getEventDescription(event));
    if (!links || links.length === 0) {
      return [];
    }

    let options = [];
    links.forEach((e, index) => {
      let label = this.getLinkLabel(e);
      options = options.concat({
        key: `link_${index}`,
        title: this.determineTitle(
          label + ` (${event.summaryUpdatedWithVisibility})` || e.href
        ),
        onClickHandler: () => OpenLink(e.href),
        searchQueries: `${label} ${e.href}`,
      });
    });
    return options;
  }

  determineTitle(title, isEvent = false) {
    return (
      <div className="flex flex-row items-center">
        {isEvent ? <Calendar size={14} /> : <Link size={14} />}
        <div className="ml-2.5 pt-1" style={{ lineHeight: "14px" }}>
          {truncateString(title, 60)}
        </div>
      </div>
    );
  }

  getLinkLabel(linkMatch) {
    if (!linkMatch) {
      return null;
    }

    return linkMatch.value || linkMatch.href;
  }

  getAllLinks(html) {
    if (!html) {
      return null;
    }

    let temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    let aLinks = temporalDivElement.getElementsByTagName("a");
    if (aLinks.length === 0) {
      // text
      temporalDivElement = null;
      return linkify.find(html);
    }

    let links = [];
    for (let item of aLinks) {
      if (item && item.href) {
        links = links.concat({
          href: item.href,
          value: item.innerText || item.innerHTML || item.href,
        });
      }
    }

    temporalDivElement = null;

    return links;
  }

  setCurrentEvents(events) {
    if (!events || events.length === 0) {
      return;
    }

    const sortedEvents = immutablySortArray(events, (a, b) => sortEventsJSDate(a, b));

    let duplicateEvents = [];
    let searchEvents = [];
    sortedEvents.forEach((e) => {
      if (searchEvents.includes(e.summaryUpdatedWithVisibility)) {
        duplicateEvents = duplicateEvents.concat(
          e.summaryUpdatedWithVisibility
        );
      } else {
        searchEvents = searchEvents.concat(e.summaryUpdatedWithVisibility);
      }
    });

    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;

    let pastEventsOption = [];
    let futureEvents = [];
    let timeNow = new Date();
    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({masterAccount, allCalendars, allLoggedInUsers});

    let upcomingEvent = getNowOrUpcomingEvent(sortedEvents, upcomingCalendarIDs);
    let isUpComingEventSameDay =
      upcomingEvent &&
      isSameDay(parseISO(upcomingEvent.defaultStartTime), new Date());

    let nowPreviousBar = subMinutes(new Date(), 10);
    let nowLaterBar = addMinutes(new Date(), 10);

    sortedEvents.forEach((e, index) => {
      let label = duplicateEvents.includes(e.summaryUpdatedWithVisibility)
        ? `${e.summaryUpdatedWithVisibility} (${formatDate(
          e.eventStart,
          this.props.dateFieldOrder,
        )})`
        : e.summaryUpdatedWithVisibility;

      let option = {
        key: `events_${index}`,
        title: this.determineTitle(label, true),
        onClickHandler: () => {
          batch(() => {
            mainCalendarBroadcast.publish("SET_PREVIEW_EVENT", e);
            this.props.history.push("/home/expanded");
          });
        },
        searchQueries: `${e.summaryUpdatedWithVisibility}`,
      };

      if (!upcomingCalendarIDs.includes(getEventUserCalendarID(e))) {
        // do nothing
      } else if (
        isSameOrBeforeMinute(parseISO(e.defaultStartTime), nowLaterBar) &&
        isSameOrAfterMinute(parseISO(e.defaultEndTime), nowPreviousBar)
      ) {
        option.shortcut = "NOW";
      } else if (
        isUpComingEventSameDay &&
        upcomingEvent.uniqueEtag === e.uniqueEtag
      ) {
        option.shortcut = "Upcoming";
      }

      if (
        isBeforeMinute(parseISO(e.defaultEndTime), timeNow) ||
        e.displayAsAllDay
      ) {
        pastEventsOption = pastEventsOption.concat(option);
      } else {
        futureEvents = futureEvents.concat(option);
      }
    });
    let eventOptions = futureEvents.concat(pastEventsOption);
    this.setState({ eventOptions });
  }
}

function mapStateToProps(state) {
  let {
    dateFieldOrder,
  } = state;

  return {
    dateFieldOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
  };
}

const withStore = BaseComponent => props => {
  // Fetch initial state
  const allCalendars = useAllCalendars()
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent 
      {...props} 
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStore(InstantOpenCommandCenter)));
