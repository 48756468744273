import React, { Component } from "react";
import EventForm from "../components/eventForm";
import { connect, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import { rrulestr } from "rrule";
import GoogleCalendarService, {
  DEFAULT,
  TEMPLATE,
  UPDATE_TEMPLATE,
  EDIT,
  CREATE_NEW,
  RECURRING_EVENT,
  VIMCAL_SIGNATURE,
  renderDefaultSignatureWithEmptyLinesAbove,
  TITLE_SECTION,
  TIME_SECTION,
  LOCATION_SECTION,
  CONFERENCE_SECTION,
  ROOMS_SECTION,
  ATTENDEE_SECTION,
  AVAILABILITY_SECTION,
  REMINDER_SECTION,
  EVENT_COLOR_SECTION,
  whichConferenceOption,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  WHATS_APP_STRING,
  GOOGLE_HANGOUT_STRING,
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_NEEDS_ACTION,
  OUT_OF_OFFICE_AUTO_DECLINE_NEW,
  OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE,
  NO_CONFERENCE_STRING,
} from "../services/googleCalendarService";
import Broadcast from "../broadcasts/broadcast";
import {
  generateConferenceRooms,
  getEmailBasedOnCalendarId,
  doesEventHaveModifyPermissionAndIsNotAnOrangizer,
  getConferenceURLFromNative,
  replaceStringInLinkTag,
  isTemplateZoomConferencing,
  getRRuleStringFromRecurrence,
  RoundToClosestMinuteJSDate,
  getFirstDayOfWeekJsDate,
  convertToTimeZone,
  isOnboardingMode,
  isOrganizer,
  getEventStartEndFromRawJSONTimeString,
  determineConferenceType,
  isHangoutGSuiteIntegration,
} from "../services/commonUsefulFunctions";
import _ from "underscore";
import parse from "html-react-parser";
import {
  isValid,
  startOfDay,
  parseISO,
  startOfMinute,
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  set,
  startOfHour,
  isSameDay,
  addMinutes,
  addDays,
  subDays,
  parseJSON,
} from "date-fns";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import { createWritableCalendarList } from "../lib/stateManagementFunctions";
import {
  determineConferenceText,
  isDefaultZoomPersonalLink,
  isTemplateCustomConferencing,
  isTemplatePhoneConferencing,
  isTemplateWhatsAppConferencing,
  isValidCustomConferencing,
} from "../lib/conferencing";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import {
  filterForAllWritableCalendars,
  findBestGuessWritableCalendar,
  getAllEditableCalendars,
  getCalendarFromEmail,
  getCalendarFromProviderID,
  getMatchingUserFromEvent,
  getUserCalendar,
  isCalendarSelected,
  isCalendarOutlookCalendar,
  getCalendarUserEmail,
  doesCalendarHaveEditableRole,
} from "../lib/calendarFunctions";
import {
  getEventAttachments,
  getEventAttendees,
  getEventCategories,
  getEventColorID,
  getEventConferenceData,
  getEventConferenceURL,
  getEventDescription,
  getEventGuestPermissions,
  getEventLocation,
  getEventMasterEventID,
  getEventOrganizer,
  getEventOutOfOfficeDeclineMessage,
  getEventOutOfOfficeDeclineMode,
  getEventPrivateCopy,
  getEventRawData,
  getEventRecurrence,
  getEventReminders,
  getEventStart,
  getEventTitle,
  getEventTransparency,
  getEventUserCalendarID,
  getEventUserEventID,
  getEventVisibility,
  GUESTS_CAN_INVITE_OTHERS,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
} from "../services/eventResourceAccessors";
import {
  getConferenceDataConferenceName,
  getEventStartAndEnd,
  getEventStartTimeZone,
  getHumanAttendees,
  isAllDayEvent,
  isConferenceDataPhone,
  isConferenceDataWhatsApp,
  isConferenceDataZoom,
  isOutlookEvent,
  showChangesWillOnlyShowOnThisCalendar,
} from "../lib/eventFunctions";
import { convertOutlookConferencingToHumanReadable, isHumanReadableOutlookConferencingOption, isOutlookConferencingOption, isOutlookUser, isTemplateOutlookConferencing } from "../lib/outlookFunctions";
import {
  getTemplateColorID,
  getTemplateConferenceData,
  getTemplateDescription,
  getTemplateDuration,
  getTemplateEmail,
  getTemplateHumanAttendees,
  getTemplateLocation,
  getTemplateOutOfOfficeDeclineMessage,
  getTemplateOutOfOfficeDeclineMode,
  getTemplateReminders,
  getTemplateTitle,
  getTemplateTransparency,
  getTemplateVisibility,
  isEventTemplateAllDay,
} from "../services/templateFunctions";
import {
  getCalendarEmail,
  getCalendarProviderId,
} from "../services/calendarAccessors";
import { getPreviewEvent, isGoogleUser } from "../services/appFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { UTC_TIME_ZONE } from "../services/globalVariables";
import { getMatchingUserFromAllUsers, getUserEmail } from "../lib/userFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { sanitizeString, sanitizeStringAndLinkify } from "../lib/jsVariables";
import { getMatchingUIUserForEvent, getTagsFromEvent, getTagsStringFromEvent } from "../lib/tagsFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { parseHoldEventAttendees, getConferenceOptionFromHold, isEventHoldEvent, getEventHoldDetails } from "../services/holdFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { determineDefaultGuestPermissions, getCustomConferencingName, getCustomConferencingURL, getDefaultMeetingLength, getDefaultPhoneOption, shouldHideDefaultSignature } from "../lib/settingsFunctions";
import { capitalizeFirstLetter, equalAfterTrimAndLowerCased, isSameEmail, isUrl } from "../lib/stringFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { isAttendeeResource } from "../services/attendeeFunctions";
import { EVENT_HAS_MODIFY_PERMISSION_AND_IS_NOT_AN_ORGANIZER } from "../services/sharedEventFormFunctions";

let {
  freeDuringEvent,
  busyDuringEvent,
  noConferenceString,
  doesNotRepeat,
  modifyEventsString,
  inviteOthersString,
  seeGuestListString,
  zoomString,
  googleHangoutString,
  phoneNumberConference,
} = GoogleCalendarService;

class EventFormContainer extends Component {
  constructor(props) {
    super(props);

    const mode = this.determineMode();

    let state = this.constructInitialState(mode);

    let originalState = _.clone(state);

    this.state = Object.assign(state, { mode });
    this.state.originalState = originalState;

    this.onPressExit = this.onPressExit.bind(this);
    this.checkMode = this.checkMode.bind(this);
  }

  shouldComponentUpdate(newProps, newState) {
    return false;
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  render() {
    return (
      <EventForm
        onRef={(ref) => (this.editView = ref)}
        mode={this.state.mode}
        initialState={this.state}
        originalUnchangedState={
          this.props.fromEvent
            ? this.constructInitialState(CREATE_NEW)
            : this.state.originalState
        }
        shouldSaveEverything={this.shouldSaveEveryField()}
      />
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onPressExit() {
    this.editView.onPressExit();
  }

  //=================
  // PRIVATE METHODS
  //=================

  determineMode() {
    if (
      this.props.isTemplate &&
      !isEmptyObjectOrFalsey(this.props.eventData) &&
      this.props.fromEvent
    ) {
      return TEMPLATE;
    } else if (this.props.isTemplate && !isEmptyObjectOrFalsey(this.props.eventData)) {
      return UPDATE_TEMPLATE;
    } else if (this.props.isTemplate) {
      return TEMPLATE;
    } else if (this.props.isDuplicate) {
      return CREATE_NEW;
    } else if (getEventMasterEventID(this.props.eventData)) {
      return RECURRING_EVENT;
    } else if (
      getPreviewEvent({
        popupEvent: this.props.popupEvent,
        currentPreviewedEvent: this.props.currentPreviewedEvent,
        hoverPopupEvent: this.props.hoverPopupEvent,
        currentHoverEvent: this.props.currentHoverEvent,
      })
    ) {
      return EDIT;
    } else {
      return CREATE_NEW;
    }
  }

  createRoomAvailabilityIndex(event) {
    const eventAttendees = getEventAttendees(event);
    if (!eventAttendees) {
      return {};
    }

    let roomsAvailabilityIndex = {};

    eventAttendees.forEach((a) => {
      if (isAttendeeResource(a)) {
        roomsAvailabilityIndex[a.displayName] = a.responseStatus;
      }
    });

    return roomsAvailabilityIndex;
  }

  getCalendarFromEmail(email, inputAllCalendars) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getCalendarFromEmail({
      email,
      allLoggedInUsers,
      allCalendars: inputAllCalendars || allCalendars,
      masterAccount,
    });
  }

  constructInitialState(mode) {
    if (this.props.fromEvent && mode === TEMPLATE) {
      // create_template_from_event
      const event = this.props.eventData;

      const roomsArray = generateConferenceRooms(event);
      const {
        allCalendars
      } = this.props.allCalendars;

      const existingConferencing = this.determineExistingConference({
        conferenceData: getEventConferenceData(event),
        conferenceURL: event.conferenceUrl,
        hangoutLink: getEventConferenceURL(event),
        createTemplateFromEvent: true,
        event,
      });

      this.removeHoverEvent();

      let eventStart = this.setNewEventStartTime(true);
      let eventEnd = this.setNewEventStartTime(false);
      const calendar = this.getCalendarFromEmail(
        getUserEmail(this.props.currentUser),
      );
      const {
        allLoggedInUsers
      } = this.props.allLoggedInUsers
      const {
        masterAccount
      } = this.props.masterAccount;

      // do not want to add smart tags into template from event
      const eventWithoutSmartTags = _.omit(event, "tags");

      return {
        summary: this.acceptedInput(TITLE_SECTION)
          ? getEventTitle(event) ?? ""
          : "",
        separateStartEndTimezone: this.acceptedInput(TIME_SECTION)
          ? !(event.startTimeZone === event.endTimeZone)
          : false,
        startTimeZone: this.acceptedInput(TIME_SECTION)
          ? event.startTimeZone
          : this.props.currentTimeZone,
        endTimeZone: this.acceptedInput(TIME_SECTION)
          ? event.endTimeZone
          : this.props.currentTimeZone,
        location: this.acceptedInput(LOCATION_SECTION)
          ? getEventLocation(event) ?? ""
          : "",
        attendees: this.acceptedInput(ATTENDEE_SECTION)
          ? this.determineAttendeesAndChangeResponseToTentative(event)
          : [],
        newlyAddedAttendees: [],
        eventColorId: this.acceptedInput(EVENT_COLOR_SECTION)
          ? getEventColorID(eventWithoutSmartTags)
          : null,
        originalEvent: eventWithoutSmartTags,
        recurringEventId: getEventMasterEventID(event),
        eventStartDate: event.startTimeZone
          ? startOfDay(
              convertToTimeZone(event.defaultStartTime, {
                timeZone: event.startTimeZone,
              })
            )
          : parseISO(event.defaultStartTime),
        eventEndDate: event.endTimeZone
          ? startOfDay(
              convertToTimeZone(event.defaultEndTime, {
                timeZone: event.endTimeZone,
              })
            )
          : parseISO(event.defaultEndTime),
        eventStartTime: this.acceptedInput(TIME_SECTION)
          ? startOfMinute(
              convertToTimeZone(event.defaultStartTime, {
                timeZone: event.startTimeZone || this.props.currentTimeZone,
              })
            )
          : startOfMinute(eventStart),
        eventEndTime: this.acceptedInput(TIME_SECTION)
          ? startOfMinute(
              convertToTimeZone(event.defaultEndTime, {
                timeZone: event.endTimeZone || this.props.currentTimeZone,
              })
            )
          : startOfMinute(eventEnd),
        showSelectInitiallyInSelectEventTime: this.acceptedInput(TIME_SECTION),
        reminders: this.acceptedInput(REMINDER_SECTION)
          ? this.setReminder(getEventReminders(event))
          : { useDefault: true },
        allDayReminders: this.setReminder(getEventReminders(event), true),
        description: this.acceptedInput(REMINDER_SECTION)
          ? sanitizeStringAndLinkify(this.createEditDescription({
            description: getEventDescription(event),
            mode,
            calendar
          }))
          : "",
        newAttendee: null,
        recurrenceRules: {},
        repeatText: doesNotRepeat,
        guestPermissions: this.determineGuestPermissions(),
        allDay: this.acceptedInput(TIME_SECTION) ? isAllDayEvent(event) : false,
        transparency: this.acceptedInput(AVAILABILITY_SECTION)
          ? getEventTransparency(event) ?? busyDuringEvent
          : busyDuringEvent,
        conference: this.acceptedInput(CONFERENCE_SECTION)
          ? existingConferencing.conference
          : noConferenceString,
        conferenceURL: this.acceptedInput(CONFERENCE_SECTION)
          ? existingConferencing.link
          : null,
        conferencingOptions: this.createConferencingOptions(
          getEventConferenceData(event),
          true
        ),
        isNativeLink: existingConferencing.isNativeLink,
        timeDifferenceBetweenEventStartEnd: this.acceptedInput(TIME_SECTION)
          ? differenceInMinutes(event.eventEnd, event.eventStart)
          : differenceInMinutes(eventEnd, eventStart),
        dateDifferenceBetweenEventStartEnd: this.acceptedInput(TIME_SECTION)
          ? differenceInDays(event.eventEnd, event.eventStart)
          : differenceInDays(eventEnd, eventStart),
        eventID: getEventUserEventID(event),
        hasTimeZoneBeenSet: this.acceptedInput(TIME_SECTION)
          ? this.getHasTimeZoneBeenSet(event)
          : false,
        visibility: this.acceptedInput(AVAILABILITY_SECTION)
          ? getEventVisibility(event) || DEFAULT
          : DEFAULT,
        roomAvailabilityIndex: this.acceptedInput(ROOMS_SECTION)
          ? this.createRoomAvailabilityIndex(event)
          : {},
        calendar,
        eventObject: eventWithoutSmartTags ?? null,
        inputConferenceRooms: this.acceptedInput(ROOMS_SECTION)
          ? roomsArray
          : null,
        fromEventToTemplate: true,
        saveButtonText: this.determineSaveButtonText(mode),
        duration: this.acceptedInput(TIME_SECTION)
          ? this.createDurationBasedOnEvent(event)
          : this.createDuration(event),
        latestFocus: "summary",
        keyMap: {},
        writableCalendars: createWritableCalendarList({
          emailToNameIndex: this.props.emailToNameIndex,
          allCalendars: getUserCalendar(
            allCalendars,
            getCalendarUserEmail(calendar)
          ),
          currentUser: getMatchingUserFromAllUsers({
            allUsers: allLoggedInUsers,
            userEmail: getCalendarUserEmail(calendar)
          }),
          masterAccount,
          allLoggedInUsers,
        }),
        outOfOfficeAutoDeclineMode: getEventOutOfOfficeDeclineMode(event),
        outOfOfficeDeclineMessage: getEventOutOfOfficeDeclineMessage(event),
      };
    } else if (
      (this.props.isDuplicate ||
        [UPDATE_TEMPLATE, TEMPLATE, EDIT, RECURRING_EVENT].includes(mode)) &&
      !isEmptyObjectOrFalsey(this.props.eventData)
    ) {
      const isTemplate = [UPDATE_TEMPLATE, TEMPLATE].includes(mode);
      // UPDATE_EVENT
      let event = this.props.eventData;

      let roomsArray = generateConferenceRooms(event);

      const {
        allCalendars
      } = this.props.allCalendars;
      const {
        currentUser,
        emailToNameIndex,
        isDuplicate
      } = this.props;
      const {
        masterAccount,
      } = this.props.masterAccount;
      const {
        allLoggedInUsers,
      } = this.props.allLoggedInUsers;

      const eventCalendarEmail = getEmailBasedOnCalendarId(event, allCalendars);

      this.removeHoverEvent();

      const calendar = this.getCalendar({mode, event});

      const doesEventNotHavePermissionToEditActualEvent = isTemplate
        ? false
        : showChangesWillOnlyShowOnThisCalendar({event, allCalendars, currentUser, allLoggedInUsers, masterAccount});

      const { originalRecurringEventInstance, repeatText, recurrenceRules } =
        this.determineRecurringEventInformation(event);

      const eventTitle = getEventTitle(event) ?? getTemplateTitle(event) ?? "";
      const eventDescription =
        getEventDescription(event) ?? getTemplateDescription(event);
      const eventLocation =
        getEventLocation(event) ?? getTemplateLocation(event) ?? "";
      const attendees =
        getHumanAttendees(event) ?? getTemplateHumanAttendees(event) ?? [];
      const eventTransparency =
        getEventTransparency(event) ??
        getTemplateTransparency(event) ??
        busyDuringEvent;
      const eventVisibility =
        getEventVisibility(event) ?? getTemplateVisibility(event) ?? DEFAULT;
      const eventReminders =
        getEventReminders(event) ?? getTemplateReminders(event);
      const eventRecurringEventID = getEventMasterEventID(event); // templates should not have recurring ID
      const eventColorID = getEventColorID(event) ?? getTemplateColorID(event);
      const isAllDay =
        isAllDayEvent(event) || isEventTemplateAllDay(event) || false;
      const conferenceData =
        getEventConferenceData(event) ?? getTemplateConferenceData(event);

      const eventAttendees = this.determineAttendees({event, attendees, calendar});

      const existingConferencing = this.determineExistingConference({
        conferenceData,
        hangoutLink: getEventConferenceURL(event),
        conferenceURL: event.conferenceUrl,
      });

      const { startTimeZone, endTimeZone } = this.getEventTimeZone(event);

      const { eventStartTime, eventEndTime } = this.getEventTimeForEdit({
        event,
        mode,
        startTimeZone,
        endTimeZone,
      });
      const { eventStart, eventEnd } = getEventStartAndEnd(event);

      const defaultMeetingLength = this.getDefaultUserMeetingDuration();

      const outOfOfficeAutoDeclineMode = isTemplate
        ? getTemplateOutOfOfficeDeclineMode(event)
        : getEventOutOfOfficeDeclineMode(event);

      const outOfOfficeDeclineMessage = isTemplate
        ? getTemplateOutOfOfficeDeclineMessage(event)
        : getEventOutOfOfficeDeclineMessage(event);

      const returnState = {
        summary: eventTitle || "",
        writableCalendars: createWritableCalendarList({
          emailToNameIndex,
          allCalendars: isDuplicate || isTemplate ? allCalendars : getUserCalendar(
            allCalendars,
            getCalendarUserEmail(calendar),
          ),
          currentUser: isTemplate ? currentUser : getMatchingUserFromAllUsers({
            allUsers: allLoggedInUsers,
            userEmail: getCalendarUserEmail(calendar),
          }),
          masterAccount,
          allLoggedInUsers,
        }),
        eventColorId: eventColorID,
        separateStartEndTimezone: !(event.startTimeZone === event.endTimeZone),
        startTimeZone,
        endTimeZone,
        originalEvent: this.props.isDuplicate ? null : event,
        originalEventDescription: eventDescription,
        originalRecurringEventInstance,
        // TODO: The originalStartTime means start time of the first instance
        //  of a recurring event. First check to see if the originalStartTime
        //  object even exists.. This should not be set as start/end time zone.
        location: eventLocation,
        attendees: eventAttendees,
        newlyAddedAttendees: [],
        recurringEventId: eventRecurringEventID,
        eventStartDate: this.determineEditStartDate({mode, event, eventStart, eventEnd}),
        eventEndDate: this.determineEditEndDate({mode, event, eventStart, eventEnd}),
        eventStartTime,
        eventEndTime,
        reminders: this.setReminder(eventReminders),
        allDayReminders: this.setReminder(eventReminders, true),
        description: sanitizeStringAndLinkify(this.createEditDescription({description: eventDescription, mode, calendar})),
        newAttendee: null,
        recurrenceRules,
        repeatText,
        guestPermissions: this.determineGuestPermissions(event),
        allDay: isAllDay,
        transparency: eventTransparency,
        conference: existingConferencing.conference,
        conferenceURL: existingConferencing.link,
        conferencingOptions: this.createConferencingOptions(conferenceData),
        isNativeLink: existingConferencing.isNativeLink,
        timeDifferenceBetweenEventStartEnd: this.checkMode(
          UPDATE_TEMPLATE,
          mode
        )
          ? defaultMeetingLength
          : differenceInMinutes(event.eventEnd, event.eventStart),
        dateDifferenceBetweenEventStartEnd: this.checkMode(
          UPDATE_TEMPLATE,
          mode
        )
          ? 0
          : differenceInDays(event.eventEnd, event.eventStart),
        eventID: getEventUserEventID(event),
        hasTimeZoneBeenSet: this.getHasTimeZoneBeenSet(event),
        visibility: eventVisibility,
        roomAvailabilityIndex: this.createRoomAvailabilityIndex(event),
        calendar,
        eventObject: this.getEventObject(),
        inputConferenceRooms: roomsArray,
        fromEventToTemplate: this.props.fromEvent,
        saveButtonText: this.determineSaveButtonText(mode),
        duration: this.createDuration(event),
        isDuplicate: this.props.isDuplicate,
        isCopiedFromAnotherCalendar: event.isCopiedFromAnotherCalendar,
        latestFocus: "summary",
        keyMap: {},
        isUpdateProposedTime: event?.isUpdateProposedTime,
        outOfOfficeAutoDeclineMode,
        outOfOfficeDeclineMessage,
        categories: isTemplate ? getEventRawData(event)?.categories ?? [] : getEventCategories(event),
        originalEventConferenceData: this.isCopyToCalendarEvent() ? getEventConferenceData(event) : null, // we only copy over the existing conference data on copy to different calendar
        attachments: getEventAttachments(event) || [],
      };

      /* Parse hold details and set required states */
      const isHoldEvent = isEventHoldEvent(event);
      if (isHoldEvent) {
        const holdDetails = getEventHoldDetails(event);
        const {
          attendees: holdJSONAttendees,
          conferencing: holdConferencing,
          description: holdDescription,
          location: holdLocation,
          isCreatingNonHoldEvent,
          vholds_id,
        } = holdDetails ?? {};

        const {
          conference: holdConference,
          link: holdConferenceLink,
        } = getConferenceOptionFromHold({
          calendar,
          conference: holdConferencing,
          currentUser,
          event,
        });

        const holdEventState = {
          attendees: parseHoldEventAttendees({
            attendees: holdJSONAttendees,
            currentUser,
            masterAccount,
          }),
          conference: holdConference,
          conferenceLink: holdConferenceLink,
          description: sanitizeString(this.createEditDescription({description: holdDescription, mode, calendar})),
          hasDefaultTitle: true,
          location: holdLocation,
          isCreatingNonHoldEvent,
          vholds_id,
        };
        // set individually so we don't replace good data with empty default data
        if (!isEmptyArray(holdEventState.attendees)) {
          returnState.attendees = holdEventState.attendees;
        }
        if (holdEventState.conference && holdEventState.conference !== NO_CONFERENCE_STRING) {
          returnState.conference = holdEventState.conference;
        }
        if (holdEventState.conferenceLink) {
          returnState.conferenceLink = holdEventState.conferenceLink;
        }
        if (holdEventState.description) {
          returnState.description = holdEventState.description;
        }
        if (holdEventState.hasDefaultTitle) {
          returnState.hasDefaultTitle = holdEventState.hasDefaultTitle;
        }
        if (holdEventState.location) {
          returnState.location = holdEventState.location;
        }
        if (holdEventState.isCreatingNonHoldEvent) {
          returnState.isCreatingNonHoldEvent = holdEventState.isCreatingNonHoldEvent;
        }
        if (holdEventState.vholds_id) {
          returnState.vholds_id = holdEventState.vholds_id
        }
      }

      if (this.checkMode(UPDATE_TEMPLATE, mode)) {
        returnState["inputEvent"] = event;
      }

      if (!this.props.isDuplicate) {
        if (getEventPrivateCopy(event)) {
          // https://issuetracker.google.com/issues/140406138
          returnState["shouldHighlight"] = true;
        } else {
          returnState["shouldHighlight"] = this.shouldHighlightField({
            eventAttendees,
            event,
            mode,
            eventCalendarEmail
          });
        }
        returnState["eventNotHavePermissionToEditActualEvent"] =
          doesEventNotHavePermissionToEditActualEvent;
        returnState[EVENT_HAS_MODIFY_PERMISSION_AND_IS_NOT_AN_ORGANIZER] =
          doesEventHaveModifyPermissionAndIsNotAnOrangizer(
            event,
            eventCalendarEmail
          );
        returnState["eventDoesNotHavePermissionToInviteOthers"] =
          !this.doesEventHasPermissionToInviteOthers(
            doesEventNotHavePermissionToEditActualEvent,
            event
          );
        returnState["shouldHideGuestList"] = this.shouldHideGuestList(
          doesEventNotHavePermissionToEditActualEvent,
          event
        );
      }

      return returnState;
    } else {
      // new_event
      let eventStart = this.setNewEventStartTime(true);
      let eventEnd = this.setNewEventStartTime(false);

      const {
        allCalendars,
      } = this.props.allCalendars;
      const {
        currentUser,
        emailToNameIndex,
      } = this.props;

      const calendar = this.determineNewEventCalendar();

      const {
        attendees,
        createdEventWithExistingAttendees,
        description,
        conference,
        conferenceURL,
        location,
      } = this.dataOnCreateEventWithExistingAttendees(mode, calendar);

      if (this.props.allDayDate) {
        Broadcast.publish("REMOVE_EVENT_ALL_DAY_DATE");
      }

      const defaultMeetingLength = this.getDefaultUserMeetingDuration();
      const {
        masterAccount,
      } = this.props.masterAccount;
      const {
        allLoggedInUsers,
      } = this.props.allLoggedInUsers;

      return {
        shouldDisplayOverlay: isUserMaestroUser(masterAccount)? false : this.checkMode(CREATE_NEW, mode),
        summary: this.props.title
          || this.props.temporaryStateStore.bookingLinkState?.title
          || "",
        hasDefaultTitle: !!this.props.title,
        writableCalendars: createWritableCalendarList({
          emailToNameIndex,
          allCalendars,
          currentUser,
          masterAccount,
          allLoggedInUsers,
        }),
        eventColorId: null,
        separateStartEndTimezone: false,
        startTimeZone: this.props.currentTimeZone,
        endTimeZone: this.props.currentTimeZone,
        location: location || "",
        attendees: attendees,
        createdEventWithExistingAttendees: createdEventWithExistingAttendees,
        newlyAddedAttendees: [],
        eventStartDate: this.props.allDayDate
          ? startOfDay(eventStart)
          : eventStart,
        eventEndDate: this.props.allDayDate ? startOfDay(eventEnd) : eventEnd,
        eventStartTime: eventStart,
        eventEndTime: eventEnd,
        reminders: { useDefault: true },
        allDayReminders: { useDefault: false, overrides: [] },
        description: description,
        newAttendee: null,
        recurrenceRules: {},
        repeatText: doesNotRepeat,
        guestPermissions: this.determineGuestPermissions(),
        allDay: this.props.allDayDate || false,
        transparency: this.props.allDayDate ? freeDuringEvent : busyDuringEvent,
        conference,
        conferenceURL: conferenceURL,
        conferencingOptions: this.createConferencingOptions(),
        visibility: DEFAULT,
        timeDifferenceBetweenEventStartEnd: differenceInMinutes(
          eventEnd,
          eventStart,
        ),
        dateDifferenceBetweenEventStartEnd: differenceInDays(
          eventEnd,
          eventStart,
        ),
        hasTimeZoneBeenSet: false,
        descriptionName: this.props.descriptionName,
        calendar: this.props.isTemplate ? null : calendar, // Default to Select Calendar for templates
        roomAvailabilityIndex: {},
        selectedTimeSlot: this.props.selectedTimeSlot || this.props.allDayDate,
        selectedConferenceRooms: null,
        saveButtonText: this.determineSaveButtonText(mode),
        duration: { days: 1, hours: 0, minutes: defaultMeetingLength },
        latestFocus: "NLP_bar",
        keyMap: {},
        isOnboarding: isOnboardingMode(),
        hasPressedAddDescription: !!this.props.temporaryStateStore.bookingLinkState.description,
        outOfOfficeAutoDeclineMode: OUT_OF_OFFICE_AUTO_DECLINE_NEW,
        outOfOfficeDeclineMessage: OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE,
        attachments: [],
      };
    }
  }

  isCreatedFromDayViewWithResourceID() {
    const {
      temporaryEvents
    } = this.props;
    // note: functions depend on all these variables being true
    // We should not change the checks below easily
    return temporaryEvents?.length === 1 &&
      temporaryEvents[0].resourceId &&
      doesCalendarHaveEditableRole(temporaryEvents[0].matchingCalendar);
  }

  determineNewEventCalendar() {
    const {
      temporaryEvents,
      currentUser,
    } = this.props;
    if (this.isCreatedFromDayViewWithResourceID()) {
      // create event in day view by dragging on calendar
      return temporaryEvents[0].matchingCalendar;
    }

    // for creating new event with resources
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;

    // on create event, you only want to show calendars that belong to current user
    const filteredAllCalendars = getUserCalendar(
      allCalendars,
      getUserEmail(currentUser)
    );
    const currentUserCalendar = this.getCalendarFromEmail(
      getUserEmail(currentUser),
      filteredAllCalendars,
    );
    if (!isCalendarSelected(currentUserCalendar)) {
      const writableCalendars = filterForAllWritableCalendars(allCalendars);
      const bestGuessWritableCalendar = findBestGuessWritableCalendar({
        writableCalendars,
        currentUser,
        masterAccount,
        allLoggedInUsers,
        allCalendars,
      });
      if (bestGuessWritableCalendar) {
        return bestGuessWritableCalendar;
      }
      // if primary is not selected but another writable calendar is, then use that writable calendar
    }
    return currentUserCalendar;
  }

  determineEditStartDate({mode, event, eventStart, eventEnd}) {
    const isUpdateTemplate = this.checkMode(UPDATE_TEMPLATE, mode);
    if (isUpdateTemplate || !event.defaultStartTime) {
      return startOfDay(new Date());
    }

    const {
      defaultStartTime,
      startTimeZone,
      setEventTimeDirectlyFromEventStartAndEventEnd,
    } = event;
    if (setEventTimeDirectlyFromEventStartAndEventEnd) {
      return startOfDay(eventStart);
    }

    if (!defaultStartTime) {
      return startOfDay(new Date());
    }

    const {
      currentTimeZone
    } = this.props;

    if (isOutlookEvent(event)) {
      return getEventStartEndFromRawJSONTimeString({
        event,
        currentTimeZone,
        eventStart,
        eventEnd,
      }).eventStartJSDate;
    }

    return getEventStartEndFromRawJSONTimeString({
      event,
      currentTimeZone: startTimeZone ?? currentTimeZone,
      eventStart,
      eventEnd,
    }).eventStartJSDate;
  }

  getEventObject() {
    const {
      eventData
    } = this.props;
    if (!eventData) {
      return null;
    }

    if (this.props.isDuplicate) {
      if (this.props.isTemplate) {
        return null;
      }
      // delete everything minus tags
      const existingTagsString = getTagsStringFromEvent(eventData);
      const existingTags = getTagsFromEvent(eventData);

      if (isEmptyArray(existingTags)) {
        return null;
      }

      // delete everything else in private property minus tags
      return {
        extended_properties: {
          private: {
            tags: existingTagsString
          }
        }
      };
    }

    return eventData;
  }

  determineEditEndDate({mode, event, eventStart, eventEnd}) {
    const {
      currentTimeZone
    } = this.props;
    const {
      endTimeZone,
      setEventTimeDirectlyFromEventStartAndEventEnd,
    } = event;

    if (setEventTimeDirectlyFromEventStartAndEventEnd) {
      return startOfDay(eventEnd);
    }

    if (this.checkMode(UPDATE_TEMPLATE, mode)) {
      if (event.eventStart && event.eventEnd) {
        let parsedEventStart = parseISO(event.eventStart);
        let parsedEventEnd = parseISO(event.eventEnd);

        if (isValid(parsedEventStart) && isValid(parsedEventEnd)) {
          let diffDays = differenceInDays(
            startOfDay(parsedEventEnd),
            startOfDay(parsedEventStart)
          );

          return startOfDay(addDays(new Date(), diffDays));
        }
      }
    } else if (event.defaultEndTime) {
      if (isOutlookEvent(event)) {
        return getEventStartEndFromRawJSONTimeString({
          event,
          currentTimeZone,
          eventStart,
          eventEnd
        }).eventEndJSDate;
      }

      return getEventStartEndFromRawJSONTimeString({
        event,
        currentTimeZone: endTimeZone ?? currentTimeZone,
        eventStart,
        eventEnd
      }).eventEndJSDate;
    }

    return startOfDay(new Date());
  }

  createUpcomingTime(isStart = false) {
    if (isStart) {
      return startOfMinute(
        convertToTimeZone(RoundToClosestMinuteJSDate(new Date(), 30), {
          timeZone: this.props.currentTimeZone,
        })
      );
    }

    return addMinutes(
      startOfMinute(
        convertToTimeZone(RoundToClosestMinuteJSDate(new Date(), 30), {
          timeZone: this.props.currentTimeZone,
        })
      ),
      this.getDefaultUserMeetingDuration()
    );
  }

  getDefaultUserMeetingDuration() {
    const { masterAccount } = this.props.masterAccount;
    return getDefaultMeetingLength({masterAccount, user: this.props.currentUser});
  }

  getCalendar({mode, event}) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (mode === UPDATE_TEMPLATE) {
      const { allCalendars } = this.props.allCalendars;
      // this can return null for templates which is fine because some templates do not have a calendar associated it with it.
      return getCalendarFromProviderID({
        allCalendars,
        providerID: getTemplateEmail(event),
        allLoggedInUsers,
        masterAccount,
      });
    }

    return this.determineCalendarFromCalendarId(
      getEventUserCalendarID(event)
    );
  }

  determineCalendarFromEmail(email) {
    const matchingCalendar = this.getCalendarFromEmail(email);
    if (matchingCalendar) {
      return matchingCalendar;
    } else {
      return this.getCalendarFromEmail(getUserEmail(this.props.currentUser));
    }
  }

  determineCalendarFromCalendarId(calendarId) {
    const { allCalendars } = this.props.allCalendars;
    const allWritableCalendars = getAllEditableCalendars(allCalendars);

    if (allWritableCalendars[calendarId]) {
      return allWritableCalendars[calendarId];
    } else {
      return this.getCalendarFromEmail(getUserEmail(this.props.currentUser));
    }
  }

  doesEventHasPermissionToInviteOthers(
    doesEventNotHavePermissionToEditActualEvent,
    event
  ) {
    // Anything not false is true here. If modify is true then guest can invite others will be set to undefined but you can still invite others.
    if (doesEventNotHavePermissionToEditActualEvent) {
      return (
        getEventGuestPermissions(event, GUESTS_CAN_MODIFY) ||
        getEventGuestPermissions(event, GUESTS_CAN_INVITE_OTHERS) !== false
      );
    } else {
      return true;
    }
  }

  shouldHideGuestList(doesEventNotHavePermissionToEditActualEvent, event) {
    if (doesEventNotHavePermissionToEditActualEvent) {
      return !(
        getEventGuestPermissions(event, GUESTS_CAN_MODIFY) ||
        getEventGuestPermissions(event, GUESTS_CAN_SEE_OTHER_GUESTS) !== false
      );
    } else {
      return true;
    }
  }

  createDurationBasedOnEvent(event) {
    let days = differenceInDays(event.eventEnd, event.eventStart) + 1;
    let hours = differenceInHours(event.eventEnd, event.eventStart);
    let minutes = differenceInMinutes(event.eventEnd, event.eventStart) % 60;

    return { days: days === 0 ? 1 : days, hours, minutes };
  }

  createDuration(event) {
    return getTemplateDuration(event) ?? { days: 1, hours: 0, minutes: this.getDefaultUserMeetingDuration() };
  }

  determineAttendees({event, attendees, calendar}) {
    if (event.isCopiedFromAnotherCalendar) {
      return [];
    }

    if (event.keepAttendees) {
      return attendees;
    } else if (!this.props.isDuplicate || attendees.length === 0) {
      return attendees;
    } else {
      const getNewAttendeeEmail = () => {
        if (isCalendarOutlookCalendar(calendar)) {
          return this.props.currentUser.email;
        }

        return getCalendarProviderId(
          allEditableCalendars[getEventUserCalendarID(event)]
        );
      }
      // if duplicate -> need to flip organizer and flip attendance
      // if calendar has permission to edit -> keep current atteendees
      // else -> flip organizers
      const { allCalendars } = this.props.allCalendars;

      const allEditableCalendars = getAllEditableCalendars(allCalendars);
      if (allEditableCalendars[getEventUserCalendarID(event)]) {
        return this.setEmailAsOrganizerAndRemoveAttendeeAttendance(
          attendees,
          getNewAttendeeEmail()
        );
      } else {
        return this.setEmailAsOrganizerAndRemoveAttendeeAttendance(
          attendees,
          this.props.currentUser.email
        );
      }
    }
  }

  setEmailAsOrganizerAndRemoveAttendeeAttendance(attendees, email) {
    const attendeeEmailArray = attendees.map((a) => a.email);
    const filterAttendeeEmailArray = attendeeEmailArray.filter(
      (e) => e !== email
    );

    let updatedEmailArray = [];

    updatedEmailArray = updatedEmailArray.concat({
      email: email,
      responseStatus: ATTENDEE_EVENT_ATTENDING,
    });

    filterAttendeeEmailArray.forEach((e) => {
      updatedEmailArray = updatedEmailArray.concat({
        email: e,
        responseStatus: ATTENDEE_EVENT_NEEDS_ACTION,
      });
    });

    return updatedEmailArray;
  }

  determineAttendeesAndChangeResponseToTentative(event) {
    const attendees = getHumanAttendees(event) || [];

    if (attendees.length === 0) {
      return [];
    } else {
      let updatedAttendees = [];
      let updatedAttendeeInfo;

      attendees.forEach((a) => {
        if (a.email === this.props.currentUser.email) {
          updatedAttendees = updatedAttendees.concat(a);
        } else {
          updatedAttendeeInfo = _.clone(a);
          updatedAttendeeInfo.responseStatus = ATTENDEE_EVENT_NEEDS_ACTION;

          updatedAttendees = updatedAttendees.concat(updatedAttendeeInfo);
        }
      });

      return updatedAttendees;
    }
  }

  acceptedInput(select) {
    return this.props.fromEvent && this.props.fromEvent.includes(select);
  }

  dataOnCreateEventWithExistingAttendees(mode, calendar) {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      temporaryEvents,
      currentUser,
    } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    const getNewEventUser = () => {
      if (this.isCreatedFromDayViewWithResourceID()) {
        const matchingUser = getMatchingUserFromAllUsers({ 
          allUsers: allLoggedInUsers, 
          userEmail: temporaryEvents?.[0]?.resourceId
        });
        if (matchingUser) {
          // create event in day view by dragging on calendar
          return matchingUser;
        }
      }
      const calendarEmail = getCalendarEmail(calendar);
      const matchingUserFromCalendarEmail = getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail: calendarEmail
      });
      if (calendarEmail && matchingUserFromCalendarEmail) {
        return matchingUserFromCalendarEmail;
      }
      // default to current user
      return currentUser;
    };

    const matchingUser = getNewEventUser();
    const defaultConference = whichConferenceOption({
      currentUser: matchingUser,
      calendar
    });

    if (!isEmptyObjectOrFalsey(this.props.temporaryStateStore.bookingLinkState)) {
      // from booking link like calendly
      let attendees = [];
      const senderEmail = this.props.temporaryStateStore.bookingLinkState?.sender_email;
      if (senderEmail) {
        attendees = [
          {
            displayName: this.props.temporaryStateStore.bookingLinkState.sender_name ?? "",
            email: senderEmail,
            responseStatus: ATTENDEE_EVENT_ATTENDING,
          },
          {
            email: getUserEmail(matchingUser),
            responseStatus: ATTENDEE_EVENT_ATTENDING,
          }
        ];
      }

      const description = this.props.temporaryStateStore.bookingLinkState?.description ?? "";
      const location = this.props.temporaryStateStore.bookingLinkState?.location || "";

      return {
        attendees,
        conference: senderEmail ? defaultConference.conference : noConferenceString,
        conferenceURL: senderEmail ? defaultConference.link : null,
        createdEventWithExistingAttendees: !!senderEmail,
        location,
        description,
      };
    }

    if (this.props.eventFormEmails?.length > 0) {
      const {
        eventFormEmails,
        temporaryEvents
      } = this.props;

      const getSelfAttendeeEmail = () => {
        if (this.isCreatedFromDayViewWithResourceID()) {
          return temporaryEvents[0].resourceId;
        }
        return getUserEmail(matchingUser);
      };

      const getAttendeeList = () => {
        const attendeeEmailList = eventFormEmails
          .map((e) => {
            return { email: e, responseStatus: ATTENDEE_EVENT_NEEDS_ACTION };
          });
        const selfAttendeeEmail = getSelfAttendeeEmail();
        if (eventFormEmails.includes(selfAttendeeEmail)) {
          // if the list includes the organizer -> remove the email and add self attendee as accepted
          return attendeeEmailList
            .filter((attendee) => !equalAfterTrimAndLowerCased(attendee.email, selfAttendeeEmail))
            .concat({
              email: selfAttendeeEmail,
              responseStatus: ATTENDEE_EVENT_ATTENDING,
            });
        }

        return attendeeEmailList
          .concat({
            email: selfAttendeeEmail,
            responseStatus: ATTENDEE_EVENT_ATTENDING,
          });
      };

      const attendeeList = getAttendeeList();

      const isPersonalZoomLink = isDefaultZoomPersonalLink(matchingUser);
      let location = ""
      let description = "";
      let conferenceURL = "";

      if (isOutlookUser(matchingUser) && (isPersonalZoomLink || defaultConference.conference !== zoomString)) {
        // we create unique zoom in didMount of eventform index
        location = defaultConference.link ?? "";
        description = defaultConference.link ?? "";
      }

      if (isPersonalZoomLink || defaultConference.conference !== zoomString) {
        conferenceURL = defaultConference.link ?? "";
      }

      const {
        masterAccount
      } = this.props.masterAccount;
      if (
        this.checkMode(TEMPLATE, mode)
        || shouldHideDefaultSignature({masterAccount, user: matchingUser})
      ) {
        // nothing
      } else if (description) {
        description = description + "<br/><br/>" + renderDefaultSignatureWithEmptyLinesAbove(masterAccount);
      } else {
        description = renderDefaultSignatureWithEmptyLinesAbove(masterAccount);
      }

      return {
        attendees: attendeeList,
        conference: defaultConference.conference,
        conferenceURL,
        createdEventWithExistingAttendees: true,
        description,
        location
      };
    } else {
      return {
        attendees: [],
        conference: noConferenceString,
        conferenceURL: null,
        createdEventWithExistingAttendees: false,
        description:
          this.checkMode(TEMPLATE, mode) ||
          shouldHideDefaultSignature({masterAccount, user: matchingUser})
            ? ""
            : renderDefaultSignatureWithEmptyLinesAbove(masterAccount),
        location: ""
      };
    }
  }

  determineSaveButtonText(mode) {
    if (this.checkMode(UPDATE_TEMPLATE, mode)) {
      return "Update Template";
    } else if (this.checkMode(TEMPLATE, mode)) {
      return "Create Template";
    } else if (this.checkMode(CREATE_NEW, mode)) {
      return "Create Event";
    } else if (this.checkMode(EDIT, mode)) {
      return "Update Event";
    } else {
      return "Save";
    }
  }

  setNewEventStartTime(isStart = false) {
    const defaultMeetingLength = this.getDefaultUserMeetingDuration();
    // make sure to account for time zone
    if (this.props.selectedTimeSlot && !this.props.allDayDate) {
      return isStart
        ? this.props.selectedTimeSlot["start"]
        : this.props.selectedTimeSlot["end"];
    } else if (this.props.allDayDate) {
      if (this.props.temporaryEvents && this.props.temporaryEvents[0]) {
        const date = isStart
          ? this.props.temporaryEvents[0].eventStart
          : subDays(this.props.temporaryEvents[0].eventEnd, 1);
        return isStart
          ? startOfHour(set(date, { hours: 10 }))
          : startOfMinute(set(date, { hours: 10, minutes: defaultMeetingLength }));
      }

      return isStart
        ? startOfHour(set(new Date(), { hours: 10 }))
        : startOfMinute(set(new Date(), { hours: 10, minutes: defaultMeetingLength }));
    } else if (this.props.temporaryEvents && this.props.temporaryEvents[0]) {
      const event = this.props.temporaryEvents[0];

      if (event.allDay) {
        return isStart
          ? RoundToClosestMinuteJSDate(event["eventStart"], 30)
          : RoundToClosestMinuteJSDate(event["eventEnd"], 30);
      }

      return isStart ? event["eventStart"] : event["eventEnd"];
    } else {
      // Rressing "c" or the create event button
      let currentDay;
      const currentTime = new Date();

      if (this.props.selectedCalendarView === 1) {
        currentDay = this.props.selectedDay;
      } else if (
        isSameDay(
          getFirstDayOfWeekJsDate(currentTime, this.props.weekStart),
          getFirstDayOfWeekJsDate(this.props.selectedDay, this.props.weekStart)
        )
      ) {
        // if it's within the same week, use the current day
        currentDay = currentTime;
      } else {
        currentDay = this.props.selectedDay;
      }

      //set currentTime with currentDay
      const eventTime = set(
        convertToTimeZone(currentTime, {
          timeZone: this.props.currentTimeZone,
        }),
        {
          year: currentDay.getFullYear(),
          month: currentDay.getMonth(),
          date: currentDay.getDate(),
        }
      );

      const startTime = RoundToClosestMinuteJSDate(eventTime, 30);
      return isStart
        ? startTime
        : addMinutes(startTime, defaultMeetingLength);
    }
  }

  createConferencingOptions(conferenceData, createTemplateFromEvent = false) {
    let eventConferencingOptions = this.createDefaultConferencingOptions();

    if (conferenceData && !createTemplateFromEvent) {
      const conferenceName = this.createConferencingName(conferenceData);

      const loweredCaseConferenceName = conferenceName.toLowerCase();
      if (
        !loweredCaseConferenceName.includes("zoom") &&
        !loweredCaseConferenceName.includes("google") &&
        !loweredCaseConferenceName.includes("phone") &&
        !loweredCaseConferenceName.includes("whatsapp") &&
        !isHumanReadableOutlookConferencingOption(conferenceName)
      ) {
        eventConferencingOptions = [
          { value: conferenceName, label: conferenceName },
        ].concat(eventConferencingOptions);
      }
    }

    return eventConferencingOptions;
  }

  createDefaultConferencingOptions() {
    let conferenceOptions = [
      { value: zoomString, label: zoomString },
      { value: googleHangoutString, label: googleHangoutString },
      { value: noConferenceString, label: noConferenceString },
    ];

    switch (getDefaultPhoneOption({ user: this.props.currentUser })) {
      case BACKEND_PHONE:
        conferenceOptions.splice(2, 0, {
          value: phoneNumberConference,
          label: phoneNumberConference,
        });
        break;
      case BACKEND_WHATS_APP:
        conferenceOptions.splice(2, 0, {
          value: WHATS_APP_STRING,
          label: WHATS_APP_STRING,
        });
        break;
      default:
        // default to phone
        conferenceOptions.splice(2, 0, {
          value: phoneNumberConference,
          label: phoneNumberConference,
        });
    }

    if (getCustomConferencingURL({ user: this.props.currentUser })) {
      const conferencingName = getCustomConferencingName({
        user: this.props.currentUser,
      });
      conferenceOptions.splice(3, 0, {
        value: conferencingName,
        label: conferencingName,
      });
    }

    return conferenceOptions;
  }

  createConferencingName(conferenceData) {
    // only call this function after checking for doesEventHaveNativeConferencing();
    const conferenceSolutionName = conferenceData?.conferenceSolution?.name;

    if (isConferenceDataZoom(conferenceData)) {
      return zoomString;
    }
    if (isConferenceDataPhone(conferenceData)) {
      return "Phone";
    }
    if (isOutlookConferencingOption(conferenceData)) {
      return convertOutlookConferencingToHumanReadable(conferenceData);
    }

    return conferenceSolutionName ?? "Existing conferencing";
  }

  createEditDescription({description, mode, calendar}) {
    if (!description || description.length === 0) {
      return "";
    } else if (
      description === VIMCAL_SIGNATURE &&
      !this.checkMode(UPDATE_TEMPLATE, mode)
    ) {
      const {
        masterAccount
      } = this.props.masterAccount;
      return renderDefaultSignatureWithEmptyLinesAbove(masterAccount);
    } else if (isCalendarOutlookCalendar(calendar)) {
      return description;
    } else {
      const updatedRichText = this.updateStringToRichText(description);
      const textWithoutBrackets = replaceStringInLinkTag(updatedRichText);

      return textWithoutBrackets;
    }
  }

  updateStringToRichText(description) {
    let withUpdatedNextLine = description.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    let withUpdatedUrl = this.updateTextWithUrl(withUpdatedNextLine);

    return withUpdatedUrl;
  }

  updateTextWithUrl(string) {
    let updatedReturnString = string;

    let parsed = parse(string);

    if (!parsed || !_.isArray(parsed)) {
      return string;
    }

    parsed.forEach((a) => {
      if (_.isString(a)) {
        let substringArray = a.split(" ");
        let updatedSubString;

        substringArray.forEach((s) => {
          if (isUrl(s)) {
            updatedSubString = `<a href="${s}" rel="noopener noreferrer" target="_blank">${s}</a>`;

            updatedReturnString = updatedReturnString.replace(
              s,
              updatedSubString
            );
          }
        });
      }
    });

    return updatedReturnString;
  }

  checkMode(mode, inputMode = null) {
    if (inputMode) {
      return inputMode === mode;
    }

    return this.state.mode === mode;
  }

  setReminder(reminder, isAllDay = false) {
    if (reminder) {
      return reminder;
    }

    if (isAllDay) {
      return { useDefault: false, overrides: [] };
    } else {
      return {
        useDefault: false,
        overrides: [{ method: "popup", minutes: 10 }],
      };
    }
  }

  determineGuestPermissions(inputEvent = null) {
    if (!inputEvent) {
      return determineDefaultGuestPermissions({ user: this.props.currentUser });
    } else if (getEventGuestPermissions(inputEvent, GUESTS_CAN_MODIFY)) {
      const modify = getEventGuestPermissions(inputEvent, GUESTS_CAN_MODIFY)
        ? modifyEventsString
        : false;

      return [modify, inviteOthersString, seeGuestListString];
    } else {
      const inviteOthers =
        getEventGuestPermissions(inputEvent, GUESTS_CAN_INVITE_OTHERS) !== false
          ? inviteOthersString
          : false;
      const seeGuestList =
        getEventGuestPermissions(inputEvent, GUESTS_CAN_SEE_OTHER_GUESTS) !==
        false
          ? seeGuestListString
          : false;

      return [false, inviteOthers, seeGuestList];
    }
  }

  determineExistingConference({
    event, // can be null
    conferenceData,
    conferenceURL,
    hangoutLink,
    createTemplateFromEvent = false,
  }) {
    const nativeConferenceLink = getConferenceURLFromNative(conferenceData);
    // Example of nativeConferenceLink = https://us02web.zoom.us/j/123456789
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    if (nativeConferenceLink && !createTemplateFromEvent) {
      return {
        conference: this.createConferencingName(conferenceData, true),
        link: nativeConferenceLink,
        isNativeLink: true,
      };
    } else if (this.props.isTemplate || this.props.isDuplicate) {
      // if template uses zoom
      if (isConferenceDataZoom(conferenceData)) {
        return { conference: zoomString };
      }
      if (isConferenceDataPhone(conferenceData)) {
        return {conference: phoneNumberConference};
      }
      if (isConferenceDataWhatsApp(conferenceData)) {
        return {conference: WHATS_APP_STRING};
      }
      const matchingUser = getMatchingUIUserForEvent({
        event,
        allCalendars,
        allLoggedInUsers,
        masterAccount,
        currentUser,
      });
      const matchingUserCustomConferencing = getCustomConferencingName({
        user: matchingUser,
      });
      if (getConferenceDataConferenceName(conferenceData) === matchingUserCustomConferencing) {
        return { conference: getCustomConferencingName({ user: currentUser }) };
      }
      if (isTemplateZoomConferencing(conferenceData)) {
        return { conference: zoomString };
      }
      if (isTemplateCustomConferencing(conferenceData, currentUser)
        && isValidCustomConferencing(currentUser)
      ) {
        return { conference: getCustomConferencingName({ user: currentUser }) };
      }
      if (isTemplatePhoneConferencing(conferenceData)) {
        return { conference: phoneNumberConference };
      }
      if (isTemplateWhatsAppConferencing(conferenceData)) {
        return { conference: WHATS_APP_STRING };
      }
      if (isHangoutGSuiteIntegration(conferenceData) && isGoogleUser(currentUser)) {
        return { conference: GOOGLE_HANGOUT_STRING };
      }
      if (isTemplateOutlookConferencing(conferenceData) && isOutlookUser(currentUser)) {
        return { conference: conferenceData.conferenceType };
      }
      // else case gets covered by no conferencing at the end
    }
    if (hangoutLink || conferenceData?.createRequest) {
      // Google hangout
      return { conference: googleHangoutString, link: hangoutLink ?? null };
    }
    if (isOutlookConferencingOption(conferenceData)) {
      return { conference: conferenceData };
    }
    if (conferenceURL && determineConferenceText(determineConferenceType(conferenceURL))) {
      return { conference: determineConferenceText(determineConferenceType(conferenceURL)), link: conferenceURL };
    }

    return { conference: noConferenceString, link: null };
  }

  determineRecurringEventInformation(event) {
    const originalRecurringEventInstance = getOriginalRecurringEventFromIndex(
      event,
      this.props.originalRecurrenceEventIndex,
      event.originalEventUserCalendarID
    );

    if (!originalRecurringEventInstance) {
      const rRuleString = getRRuleStringFromRecurrence(event);

      const eventRecurrence = getEventRecurrence(event);
      return {
        originalRecurringEventInstance,
        repeatText:
          eventRecurrence && rRuleString
            ? capitalizeFirstLetter(rrulestr(rRuleString).toText())
            : doesNotRepeat,
        recurrenceRules:
          eventRecurrence && rRuleString ? rrulestr(rRuleString) : {},
      };
    }

    const originalRRuleString = getRRuleStringFromRecurrence(
      originalRecurringEventInstance
    );
    const rrule = rrulestr(originalRRuleString);

    return {
      originalRecurringEventInstance,
      repeatText: capitalizeFirstLetter(rrule.toText()),
      recurrenceRules: rrule,
    };
  }

  removeHoverEvent() {
    if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      batch(() => {
        this.props.setPreviewedEvent(this.props.currentHoverEvent);
        this.props.removeHoverPopupEvent();
        this.props.removeCurrentHoverEvent();
      });
    }
  }

  getEventTimeZone(event) {
    const { startTimeZone, endTimeZone } = event;
    if (!isOutlookEvent(event)) {
      return {
        startTimeZone: startTimeZone || this.props.currentTimeZone,
        endTimeZone: endTimeZone || this.props.currentTimeZone,
      };
    }

    const eventStartTimeZone =
      !startTimeZone || startTimeZone === UTC_TIME_ZONE
        ? this.props.currentTimeZone
        : startTimeZone;
    const eventEndTimeZone =
      !endTimeZone || endTimeZone === UTC_TIME_ZONE
        ? this.props.currentTimeZone
        : endTimeZone;

    return {
      startTimeZone: eventStartTimeZone,
      endTimeZone: eventEndTimeZone,
    };
  }

  getHasTimeZoneBeenSet(event) {
    if (!getEventStart(event)) {
      return false;
    }

    if (isOutlookEvent(event)) {
      return (
        !!getEventStartTimeZone(event) &&
        getEventStartTimeZone(event) !== UTC_TIME_ZONE
      );
    }

    return getEventStartTimeZone(event);
  }

  getEventTimeForEdit({ event, mode, startTimeZone, endTimeZone }) {
    const { 
      eventStart, 
      eventEnd, 
      defaultStartTime, 
      defaultEndTime,
      setEventTimeDirectlyFromEventStartAndEventEnd
    } = event;
    if (setEventTimeDirectlyFromEventStartAndEventEnd) {
      return {eventStartTime: eventStart, eventEndTime: eventEnd};
    }

    if (this.checkMode(UPDATE_TEMPLATE, mode) && eventStart && eventEnd) {
      return {
        eventStartTime: this.createUpcomingTime(true),
        eventEndTime: this.createUpcomingTime(false),
      };
    }

    if (isOutlookEvent(event) && defaultStartTime && defaultEndTime) {
      return {
        eventStartTime: startOfMinute(
          convertToTimeZone(parseJSON(defaultStartTime), {
            timeZone: startTimeZone,
          })
        ),
        eventEndTime: startOfMinute(
          convertToTimeZone(parseJSON(defaultEndTime), {
            timeZone: endTimeZone,
          })
        ),
      };
    }

    return {
      eventStartTime: startOfMinute(
        convertToTimeZone(
          defaultStartTime || this.createUpcomingTime(true),
          { timeZone: startTimeZone }
        )
      ),
      eventEndTime: startOfMinute(
        convertToTimeZone(
          defaultEndTime || this.createUpcomingTime(false),
          { timeZone: endTimeZone }
        )
      ),
    };
  }

  shouldSaveEveryField() {
    if (this.props.isDuplicate || (this.props.fromEvent && this.checkMode(TEMPLATE))) {
      return true;
    }

    const {
      bookingLinkState
    } = this.props.temporaryStateStore;
    if (!isEmptyObjectOrFalsey(bookingLinkState)) {
      return true;
    }

    return false;
  }

  shouldHighlightField({
    eventAttendees,
    event,
    mode,
    eventCalendarEmail
  }) {
    if (isOutlookEvent(event)) {
      // if do not have access to edit event on outlook, do not show edit button
      return false;
    }
    const shouldHighLight = [EDIT, RECURRING_EVENT].includes(mode) &&
    !(
      (
        (eventAttendees?.length > 0 &&
          isOrganizer(eventAttendees, eventCalendarEmail)) ||
        isSameEmail(getObjectEmail(getEventOrganizer(event)), eventCalendarEmail)
      )
      // || getEventGuestPermissions(event, GUESTS_CAN_MODIFY)  // bug with Google so we need to show shaded region
    );
    return shouldHighLight;
  }

  isCopyToCalendarEvent() {
    return this.props.isDuplicate && this.props.eventData?.isCopiedFromAnotherCalendar;
  }

  isDuplicateEvent() {
    return this.props.isDuplicate && !this.props.eventData?.isCopiedFromAnotherCalendar;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
    removeCurrentHoverEvent: (data) =>
      dispatch({ data: data, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    removeHoverPopupEvent: () => dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
  };
}

function mapStateToProps(state) {
  let {
    temporaryEvents,
    agendaDay,
    selectedDay,
    currentUser,
    currentTimeZone,
    currentHoverEvent,
    currentPreviewedEvent,
    eventFormEmails,
    emailToNameIndex,
    originalRecurrenceEventIndex,
    weekStart,
    popupEvent,
    hoverPopupEvent,
    selectedCalendarView,
  } = state;

  return {
    temporaryEvents,
    agendaDay,
    selectedDay,
    currentUser,
    currentTimeZone,
    currentHoverEvent,
    currentPreviewedEvent,
    eventFormEmails,
    emailToNameIndex,
    originalRecurrenceEventIndex,
    weekStart,
    popupEvent,
    hoverPopupEvent,
    selectedCalendarView,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const temporaryStateStore = useTemporaryStateStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      temporaryStateStore={temporaryStateStore}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(EventFormContainer))
);
