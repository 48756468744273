import classNames from "classnames";
import React, { useState } from "react";
import { Calendar } from "react-feather";
import EventModalPopup from "../eventModalPopup";
import {
  determineDefaultModalStyle,
  MODAL_OVERLAY_Z_INDEXES,
} from "../../lib/modalFunctions";
import {
  getDefaultModalBorder,
  getModalBackgroundColorWithNoOpacity,
  getTransparentModalStyle,
} from "../../lib/styleFunctions";
import { MODAL_BLUR } from "../../services/globalVariables";
import {
  getMonthDayYearLongForm,
  hasStopEventPropagation,
  isAfterDay,
  isBeforeDay,
} from "../../services/commonUsefulFunctions";
import { useSelector } from "react-redux";
import { addDays, differenceInDays } from "date-fns";
import DateRangePicker from "../DateRangePicker";
import { getDateRangeForTimeFrameOption } from "./helperFunctions";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import CalendarAuditConfirmationModal from "./confirmationModal";
import { CALENDAR_AUDIT_IDS } from "../../lib/calendarAudit/variables";

export const DATE_PICKER_TIME_FRAME = {
  THIS_WEEK: "This week",
  THIS_MONTH: "This month",
  THIS_QUARTER: "This quarter",
  LAST_WEEK: "Last week",
  LAST_MONTH: "Last month",
  LAST_QUARTER: "Last quarter",
  CUSTOM: "Custom",
};

export const DATE_PICKER_OPTIONS = [
  DATE_PICKER_TIME_FRAME.THIS_WEEK,
  DATE_PICKER_TIME_FRAME.THIS_MONTH,
  DATE_PICKER_TIME_FRAME.THIS_QUARTER,
  DATE_PICKER_TIME_FRAME.LAST_WEEK,
  DATE_PICKER_TIME_FRAME.LAST_MONTH,
  DATE_PICKER_TIME_FRAME.LAST_QUARTER,
  DATE_PICKER_TIME_FRAME.CUSTOM,
];

const MODAL_CONTENT = {
  SELECT: "select",
  CONFIRMATION_MODAL: "confirmation-modal",
};

export default function DatePicker({
  startDate,
  endDate,
  hasProposedChanges,
  option,
  setStartDate,
  setEndDate,
  setOption,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTop, setModalTop] = useState(0);
  const [modalLeft, setModalLeft] = useState(0);
  const getButtonPosition = () => {
    const eventDom = document.getElementById(CALENDAR_AUDIT_IDS.DATE_PICKER);

    if (!eventDom) {
      return { left: 0, top: 0 };
    }

    return eventDom.getBoundingClientRect();
  };
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [tempOption, setTempOption] = useState(option);

  const [modalContent, setModalContent] = useState(MODAL_CONTENT.SELECT);

  const getModalContent = () => {
    if (modalContent === MODAL_CONTENT.CONFIRMATION_MODAL) {
      return (
        <CalendarAuditConfirmationModal
          content="Are you sure you want to apply these changes? Your existing changes will be lost."
          onClose={() => {
            setModalContent(MODAL_CONTENT.SELECT);
            setIsModalOpen(false);

            // reset
            setTempStartDate(startDate);
            setTempEndDate(endDate);
          }}
          onConfirm={() => {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
            setOption(tempOption);
            setIsModalOpen(false);
            setModalContent(MODAL_CONTENT.SELECT);
          }}
        />
      );
    }
    return (
      <RenderDropdownContent
        startDate={tempStartDate}
        setStartDate={setTempStartDate}
        endDate={tempEndDate}
        setEndDate={setTempEndDate}
        tempOption={tempOption}
        setTempOption={setTempOption}
        closeModal={() => {
          setIsModalOpen(false);

          // reset
          setTempStartDate(startDate);
          setTempEndDate(endDate);
        }}
        onApply={() => {
          if (hasProposedChanges) {
            setModalContent(MODAL_CONTENT.CONFIRMATION_MODAL);
            setIsModalOpen(true);
            return;
          }

          setStartDate(tempStartDate);
          setEndDate(tempEndDate);
          setOption(tempOption);
          setIsModalOpen(false);
        }}
      />
    );
  };

  const getModalStyle = () => {
    if (modalContent === MODAL_CONTENT.CONFIRMATION_MODAL) {
      return determineDefaultModalStyle(false, false, {
        overlay: { zIndex: MODAL_OVERLAY_Z_INDEXES.AUDIT },
      });
    }
    return getTransparentModalStyle({
      top: modalTop,
      isDarkMode: false,
      additionalContentStyle: {
        backgroundColor: getModalBackgroundColorWithNoOpacity(false),
        backdropFilter: MODAL_BLUR,
        WebkitBackdropFilter: MODAL_BLUR,
        border: getDefaultModalBorder(false),
        left: modalLeft,
      },
      additionalOverlayStyle: {
        zIndex: MODAL_OVERLAY_Z_INDEXES.AUDIT,
      },
    });
  };

  return (
    <div
      id={CALENDAR_AUDIT_IDS.DATE_PICKER}
      className={classNames("flex items-center gap-2", "date-picker-container")}
      onClick={() => {
        if (isModalOpen) {
          return;
        }
        setModalContent(MODAL_CONTENT.SELECT);
        const { left, top } = getButtonPosition();
        setModalTop(top + 40);
        setModalLeft(left);
        setIsModalOpen(true);
      }}
    >
      <Calendar size={14} />
      <div className="default-font-size light-mode-default-text-color select-none">
        {option}
      </div>
      <EventModalPopup
        height={modalContent === MODAL_CONTENT.CONFIRMATION_MODAL ? null : 360}
        hideHeader={true}
        isOpen={isModalOpen}
        onRequestClose={(e) => {
          setTempOption(option); // reset
          setTempStartDate(startDate);
          setTempEndDate(endDate);
          hasStopEventPropagation(e);
          setIsModalOpen(false);
        }}
        skipDisablingHotKeys={true}
        style={getModalStyle()}
        width={540}
      >
        {getModalContent()}
      </EventModalPopup>
    </div>
  );
}

export function RenderDropdownContent({
  closeModal,
  endDate,
  onApply,
  setEndDate,
  setStartDate,
  setTempOption,
  startDate,
  tempOption,
}) {
  const FOCUS_ELEMENTS = {
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
  };
  const [error, setError] = useState("");
  const [focusedElement, setFocusedElement] = useState(
    FOCUS_ELEMENTS.START_DATE,
  );
  const [hoverDate, setHoverDate] = useState(null);
  const [inputMonth, setInputMonth] = useState(null);
  const dateFieldOrder = useSelector((state) => state.dateFieldOrder);

  const clearError = () => {
    if (error) {
      setError("");
    }
  };

  const getStartDate = () => {
    if (hoverDate) {
      if (focusedElement === FOCUS_ELEMENTS.START_DATE) {
        return hoverDate;
      } else {
        // end date
        if (isBeforeDay(hoverDate, startDate)) {
          return hoverDate;
        }
      }
    }
    return startDate;
  };

  const getEndDate = () => {
    if (hoverDate) {
      if (focusedElement === FOCUS_ELEMENTS.END_DATE) {
        if (isBeforeDay(hoverDate, startDate)) {
          return startDate;
        }
        return hoverDate;
      }
    }
    return endDate;
  };

  return (
    <div className="flex default-font-size pb-4 light-mode-default-text-color white-mode">
      <div className="light-mode-default-text-color default-font-size p-4 gap-0.5 flex flex-col">
        {DATE_PICKER_OPTIONS.map((label) => (
          <div
            key={label}
            className={classNames(
              "cursor-pointer p-2 rounded duration-200 select-none",
              "light-mode-hover-background-color",
              tempOption === label
                ? "light-mode-selected-background-color"
                : "",
            )}
            onClick={() => {
              setTempOption(label);
              const { startDate, endDate } =
                getDateRangeForTimeFrameOption(label);

              clearError();
              setStartDate(startDate);
              setEndDate(endDate);
              setInputMonth(startDate);
            }}
          >
            {label}
          </div>
        ))}
      </div>

      <div>
        <div className="flex items-center my-4">
          <div className="mr-2.5">from</div>
          <div
            className={classNames(
              "personal-link-travel-date-container",
              focusedElement === FOCUS_ELEMENTS.START_DATE
                ? "personal-link-travel-date-container-focused"
                : "",
            )}
            onClick={() => {
              setFocusedElement(FOCUS_ELEMENTS.START_DATE);
              setInputMonth(startDate);
            }}
          >
            {getMonthDayYearLongForm({
              date: startDate,
              dateFormat: dateFieldOrder,
              isShortDay: true,
            })}
          </div>
          <div className="mx-2.5">to</div>
          <div
            className={classNames(
              "personal-link-travel-date-container",
              focusedElement === FOCUS_ELEMENTS.END_DATE
                ? "personal-link-travel-date-container-focused"
                : "",
            )}
            onClick={() => {
              setFocusedElement(FOCUS_ELEMENTS.END_DATE);
              setInputMonth(endDate);
            }}
          >
            {getMonthDayYearLongForm({
              date: endDate,
              dateFormat: dateFieldOrder,
              isShortDay: true,
            })}
          </div>
        </div>
        <DateRangePicker
          inputMonth={inputMonth}
          startDate={getStartDate()}
          endDate={getEndDate()}
          isSelectingEndDate={focusedElement === FOCUS_ELEMENTS.END_DATE}
          setDate={(date) => {
            setTempOption(DATE_PICKER_TIME_FRAME.CUSTOM);
            setHoverDate(null);

            let start = getStartDate();
            let end = getEndDate();

            if (focusedElement === FOCUS_ELEMENTS.START_DATE) {
              if (isAfterDay(date, endDate)) {
                start = date;
                end = addDays(date, 1);
              } else {
                start = date;
              }
              setFocusedElement(FOCUS_ELEMENTS.END_DATE);
            }

            if (focusedElement === FOCUS_ELEMENTS.END_DATE) {
              if (isBeforeDay(date, startDate)) {
                end = startDate;
                start = date;
              } else {
                end = date;
              }
            }

            /* Limit to 100 days */
            if (differenceInDays(end, start) >= 100) {
              setError("Max date range is 100 days.");
              return;
            } else {
              clearError();
              setStartDate(start);
              setEndDate(end);
            }
          }}
          setParentHoverDate={(date) => {
            setHoverDate(date);
          }}
        />
      </div>

      {error ? (
        <div className="text-red-500 font-size-12 absolute bottom-14 right-6">
          {error}
        </div>
      ) : null}

      <SaveAndCancelButton
        onClose={() => {
          closeModal();
        }}
        confirmButtonText="Apply"
        className="absolute bottom-4 right-6"
        onConfirm={(e) => {
          hasStopEventPropagation(e);
          onApply();
        }}
      />
    </div>
  );
}
