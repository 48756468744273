import { OUTLOOK_COLORS } from "./outlookColors";
import { OutlookCategory } from "./stores/outlookCategoriesStore";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export function getCategoryDisplayName(category: OutlookCategory) {
  if (isEmptyObjectOrFalsey(category)) {
    return "";
  }

  return category.displayName;
}

export function getCategoryColor(category: OutlookCategory) {
  if (isEmptyObjectOrFalsey(category)) {
    return "";
  }

  return category.color;
}

export function getCategoryColorHex(category: OutlookCategory) {
  if (isEmptyObjectOrFalsey(category)) {
    return null;
  }

  const categoryColor = getCategoryColor(category);
  return OUTLOOK_COLORS[categoryColor]?.hex;
}
