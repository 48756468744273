import React from "react";
import CustomButton from "../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import classNames from "classnames";

interface SaveAndCancelButtonProps {
  onClose: () => void
  onConfirm: () => void
  confirmButtonText?: string
  autoFocusConfirmButton?: boolean
  className?: string
}

export default function SaveAndCancelButton({
  onClose,
  onConfirm,
  confirmButtonText,
  autoFocusConfirmButton = false,
  className,
}: SaveAndCancelButtonProps) {
  return (
    <div className={classNames("event-form-discard-changes-wrapper gap-2.5", className)}>
      <CustomButton
        buttonType={WHITE_BUTTON}
        onClick={onClose}
        label="Cancel"
      />

      <CustomButton
        shouldFocus={autoFocusConfirmButton}
        buttonType={BLUE_BUTTON}
        onClick={onConfirm}
        label={confirmButtonText || "Save"}
      />
    </div>
  );
}
