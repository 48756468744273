import React, { Component } from "react";
import { connect, Provider, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import MainCalendar from "../components/mainCalendar";
import SideMenuBar from "../components/sideMenu/sideMenuBar";
import SidePanel from "../components/sidePanel";
import MonthlyCalendarWithEventPreview from "../views/monthlyCalendarWithEventPreview";
import {
  constructQueryParams,
  formatEventForReactBigCalendar,
  flattenResponseFromFetchEvents,
  splitUpStringIntoArray,
  localData,
  isElectron,
  hasEventPreventDefault,
  hasStateOrPropsChanged,
  determineScrollToHour,
  sendMessageToSentry,
  handleError,
  removeDuplicatesFromArray,
  sortPersonalLinks,
  filterOtherPeoplesCalendarsAndReport,
  sentryHandleFailToCreateOrUpdate,
  clearCommonUsefulFunctionCache,
  convertDateIntoEpochUnixWeek,
  resetGuessTimeZone,
  constructEmailData,
  fibonacci,
  isInFocusMode,
  splitEmailIntoNames,
  isValidJSDate,
  guessTimeZoneFromLocation,
  getCurrentTimeInCurrentTimeZone,
  convertToTimeZone,
  isMacElectron,
  isOSSchemeDarkMode,
  isMac,
  isDayView,
} from "../services/commonUsefulFunctions";
import {
  getMatchingExecutiveCalendar,
  isUserDelegatedUser,
  isUserLimitedAccess,
  isUserMaestroUser,
  shouldHideDelegatedUser,
  shouldHideDelegatedUserCalendar,
} from "../services/maestroFunctions";
import { constructRequestURL, constructRequestURLV2, isErrorResponse } from "../services/api";
import ApiClient from "../services/apiClient";
import _ from "underscore";
import Broadcast from "../broadcasts/broadcast";
import db from "../services/db";
import {
  TEMPLATE_DELETED,
  EVENT_TEMPLATE,
  TEXT_TEMPLATE,
  EVENT_TEMPLATE_FROM_EVENT,
  DATE_TIME_12_HOUR_FORMAT,
} from "../services/googleCalendarService";
import Fetcher from "../services/fetcher";
import { persistor, store } from "../config/reduxConfig";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "../../App";
import * as Sentry from "@sentry/browser";
import {
  BACKEND_MONTH,
  DEBOUNCE_BACKEND_TIMER,
  PERSONAL_LINK_LOCAL_DATA,
  SECOND_IN_MS,
  MINUTE_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  FAILED_TO_DELETE_EVENT_MESSAGE,
  FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE,
  SUCCESSFUL_DELETE_MULTIPLE_EVENTS_MESSAGE,
  ACTION_MODE,
} from "../services/globalVariables";
import { trackError } from "../components/tracking";
import PersonalLinkHotKeyContainer from "../components/personalLinkHotKeyContainer";
import {
  formatISO,
  startOfDay,
  format,
  isSameWeek,
  isSameMonth,
  parseISO,
  isValid,
  differenceInSeconds,
  addDays,
  endOfDay,
  isSameDay,
  differenceInMinutes,
} from "date-fns";
import {
  createWindow,
  getPersonalContactsSyncToken,
  storePersonalContactsSyncToken,
  getOtherContactsSyncToken,
  storeOtherContactsSyncToken,
  getDomainUserSyncDate,
  getShouldSkipDomainResourcesSync,
  setLastSwitchAccountTime,
  getAllLoggedInAccountDetails,
  getCalendarACL,
  shouldRefreshPage,
  getCurrentViewFetchDates,
  getAccountForEmail,
  updateAllLoggedInUsers,
  getCalendarSelectedOverridePerUser,
  getContactLastSyncTime,
  setContactLastSyncTime,
  isUserInOnboarding,
  clearPersonalContactsSyncToken,
  clearOtherContactsSyncToken,
} from "../lib/stateManagementFunctions";
import BackendBroadcast from "../broadcasts/backendBroadcasts";
import { setCurrentUserEmail, getCurrentUserEmail, getLastBackendResponseVersion, setLastBackendResponseVersion, LOCAL_DATA_ACTION, getAutoLogoutLoginDate, setAutoLogoutLoginDate } from "../lib/localData";
import { getNameFromContact, sortContacts } from "../lib/contactFunctions";
import detectWakeUp from "../webWorkers/detectWakeup.worker";
import EventFormContainer from "./eventFormContainer";
import EventExpandedView from "../views/eventExpandedView";
import TemplateView from "./templateView";
import PopupEvent from "../components/popupEvent";
import AvailabilityPanel from "../components/availabilityPanel";
import {
  clearEventStaticCache,
  formatEventsArrayForReactBigCalendar,
  getEventEndValue,
  getEventStartValue,
  isCancelledEvent,
} from "../lib/eventFunctions";
import {
  formatFlattendCalendarAndEvents,
  formatGCalEventsList,
} from "../lib/webWorkerFunctions";
import CalendarList from "../components/calendarList";
import appBroadcast from "../broadcasts/appBroadcast";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { useAccountActivity, useFeatureFlags, useHideRightHandSidebar, useReferralStore, useTabID } from "../services/stores/appFunctionality";
import { isSelectedSlotAllDayEvent } from "../lib/rbcFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useAllUserDomains,
  useMasterAccount,
  useZoomSchedulers,
} from "../services/stores/SharedAccountData";
import {
  getActiveCalendarsIDsFromAllCalendars,
  getActiveCalendarsFromAllCalendars,
  getAllCalendarUserCalendarIDs,
  isValidCalendar,
  doesMainCalendarsIncludeUserCalendarID,
  getUserCalendar,
  toggleOffAllCalendars,
  getUserEmailFromUserCalendarID,
  getUserEmailFromEventWithCurrentUserBackup,
  getCalendarFromUserCalendarID,
  getMatchingUserFromEvent,
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
  getMatchingCalendarsForUser,
  getMatchingPrimaryCalendarForUser,
  getMatchingUserProviderIDsFromUserCalendarIDs,
  getCalendarName,
  getCalendarUserEmail,
} from "../lib/calendarFunctions";
import produce from "immer";
import { useTeamPlan } from "../services/stores/userData";
import {
  getEventMasterEventID,
  getEventStart,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import { getCalendarUserCalendarID } from "../services/calendarAccessors";
import { checkOnlineStatus, hasBasicInternetConnection } from "../lib/onlineCheckFunctions";
import { getPopUpEvent, getPreviewEvent, isActionModeCreateAvailability, isActionModeUpsertEvent, isAppInTaskMode, isGoogleUser, isInActionMode, isSchedulingAssistantShowing, isSelectColorPopup, shouldHideRightHandSide, shouldTruncateRightHandPanel, triggerRefreshWithOnlineCheck } from "../services/appFunctions";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import agendaBroadcast from "../broadcasts/agendaBroadcast";
import { isVersionV2, storeAppVersionV1 } from "../services/versionFunctions";
import { CALENDAR_PROVIDERS, SYNC_DETECT_WAKE_UP, UI_DETECT_WAKE_UP } from "../lib/vimcalVariables";
import ContactBroadcast from "../broadcasts/contactBroadcast";
import contactBroadcast from "../broadcasts/contactBroadcast";
import { addTemplateToMasterAccount, getAuthenticatedUserFromUser, getMatchingUserFromAllUsers, getUpcomingCalendarUserCalendarIDs, getUserDomain, getUserEmail, getUserProvider, getUserToken, removeTemplateFromMasterAccount, updateMasterAccountTemplates } from "../lib/userFunctions";
import classNames from "classnames";
import { getMimicEventID, isTemporaryEventRecurringEvent, MIMIC_TYPE, createMimicEventFromEvent } from "../lib/mimicEventUpdate";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { EVENT_PANEL_WRAPPER_ID } from "../services/elementIDVariables";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import ReverseSlotsPanel from "../components/scheduling/reverseSlotsPanel";
import dbBroadcast from "../broadcasts/dbBroadcast";
import { DEXIE_EVENT_COLUMNS, addBuildingsIntoDB, addConferenceRoomsIntoDB, addContactsIntoDB, addEventsIntoIndexDB, deleteEventsFromIndexDB, getRecurringEventsFromDB, isDBEventItemWithinWindow, isEventWithinJSWindow } from "../lib/dbFunctions";
import { getUserIDFromScheduler } from "../services/zoomFunctions";
import { DEMO_ACCOUNT_EMAIL, hasPermissionToViewDistroList, isDemoAccount, isInternalTeamUser, isOpenToConstantSync, shouldGetOutlookPreviewEvents, shouldShowCalendarAudit } from "../lib/featureFlagFunctions";
import { DELETE_RECURRING_TYPE, listRecurringInstances } from "../lib/vimcalFunctions";
import { safeJSONParse } from "../lib/jsonFunctions";
import teamPlanBroadcasts from "../broadcasts/teamPlanBroadcasts";
import { PERMISSION_MODAL_TYPES, broadcastOpenPermissionModal, isNeedToShowUserConsentScreen } from "../lib/authFunctions";
import FetchCalendarAndEventsContainer from "../components/fetchCalendarAndEventsContainer";
import fetchBroadcast from "../broadcasts/fetchBroadcast";
import { addBufferToWindow, getDayViewSyncWindow, getDayViewSyncWindowForOutlookPreviewEvents, getSelectedDayWithBackup } from "../lib/syncFunctions";
import { addDefaultToArray } from "../lib/arrayFunctions";
import { useMetricsStore } from "../services/stores/metricsStore";
import conferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import { isOpenFindTimeFeatureFlag, isShowBoostReferral } from "../lib/referralFunctions";
import { getDifferenceInPerformanceTrackingInSecs, getTrackingTimeNow, trackMovingBetweenWindows } from "../lib/performanceTrackingFunctions";
import { clearTagsCache } from "../lib/tagsFunctions";
import { getEventHoldID, isEventHoldEvent } from "../services/holdFunctions";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { APP_BROADCAST_VALUES, BACKEND_BROADCAST_VALUES, BROADCAST_VALUES, CONTACT_BROADCAST_VALUES, FETCH_BROADCAST_VALUES, HOLDS_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES, MODAL_BROADCAST_VALUES, SCHEDULING_ASSISTANT_BROADCAST_VALUES } from "../lib/broadcastValues";
import { CONTACTS_ENDPOINTS, FETCH_CALENDAR_EVENTS_ENDPOINT } from "../lib/endpoints";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { createUUID, randomNumber } from "../services/randomFunctions";
import updateSettingsBroadcast from "../broadcasts/updateSettingsBroadcast";
import { getAnchorTimeZonesInSettings, getDefaultUserTimeZone, getIsAccountIn24HourFormat } from "../lib/settingsFunctions";
import { isSameEmail } from "../lib/stringFunctions";
import { getUserConnectedAccountToken } from "../services/maestro/maestroAccessors";
import { fetcherGet } from "../services/fetcherFunctions";
import { isOutlookUser, OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT, PROVIDER_TYPES } from "../lib/outlookFunctions";
import { getObjectEmail, getObjectUserEmail } from "../lib/objectFunctions";
import { fetchPaginatedConferenceRooms, getRoomBuildingID } from "../lib/conferenceRoomFunctions";
import { isLocal } from "../services/devFunctions";
import { createWebhooksConsumerForAllUsers, createWebhooksConsumerForUser, disconnectWebhooksForAllCalendarsForUser, disconnectWebhooksForAllUsers, disconnectWebhooksForUserCalendarID, doesWebhookSubscriptionExistForUserCalendarID, IS_DEBUGGING_LOCAL_WEBHOOKS, reloadWebhooksForAllUsers, shouldWebhookAlwaysStayOn } from "../services/webhookFunctions";
import { clearEventStyleCache } from "../lib/stylesCache";
import schedulingAssistantBroadcast from "../broadcasts/schedulingAssistantBroadcast";
import holdsBroadcast from "../broadcasts/holdsBroadcast";
import { getRetryAfterMS, shouldRetryBasedOnResponse } from "../lib/backendFunctions";
import { delayByMs } from "../lib/asyncFunctions";
import { getLocalEventFormState } from "../services/sharedEventFormFunctions";
import { MODAL_TYPES } from "../lib/modalFunctions";
import modalBroadcast from "../broadcasts/modalBroadcast";
import { shouldDisplayCalendarAuditReady } from "../lib/calendarAudit/functions";

const detectWakeUpWorker = new detectWakeUp();
const CONTACT_TYPES = {
  OTHER: "other",
  PERSONAL: "personal",
};

class CalendarHomeView extends Component {
  constructor(props) {
    super(props);

    this.weeklyCalendar = React.createRef();
    this._updateMenuBarTimer = null;

    // to track if another call has been made before wiping the data from mainCalendar
    this._allCalendarsEventsSyncID = null;

    // note: will be null if local and not debugging
    this._consumerWebHook = null;

    this._timeoutList = []; // keeps track of timeouts so we can cancel them on unmount

    // tracks which userCalendarsIDs are loading so we don't load the same calendar twice since it's async
    this._loadingChannels = [];

    this._lastUserSyncUTC = {};

    this.completeEventFormTimer = null;

    this.shouldHaltHandlingUserSyncResponse = false;
    this.debounceRefresh = false;
    this._alreadyDebouncedInitialFullSync = false;
    this._lastFetchContactsTime = null;
    this._hasSyncedFetchContactThroughInitialFullSync = false; // so we don't call syncing functions before they're called on initial sync

    // keep track of all the original recurring events timeout ids so we can remove them on unmount
    this._fetchOriginalRecurringEventsTimeoutList = [];

    this.reloadReduxVariables();

    this.handlers = {
      // DELETE_EVENT: this.onDelete.bind(this),
      CREATE_EVENT: (e) => this.createEventHandler(e, props),
      DEFAULT_OVERRIDE_COMMAND_C: () => { },
      DEFAULT_OVERRIDE_COMMAND_A: () => { },
      DEFAULT_OVERRIDE_COMMAND_F: () => { },
      DEFAULT_OVERRIDE_COMMAND_W: () => { },
      DEFAULT_OVERRIDE_COMMAND_T: () => { },
      DEFAULT_OVERRIDE_COMMAND_U: () => { },
      DEFAULT_OVERRIDE_COMMAND_I: () => { },
      DEFAULT_OVERRIDE_COMMAND_Y: () => { },
      DEFAULT_OVERRIDE_COMMAND_O: () => { },
      DEFAULT_OVERRIDE_COMMAND_E: () => { },
      DEFAULT_OVERRIDE_CONTROL_C: () => { },
      DEFAULT_OVERRIDE_CONTROL_A: () => { },
      DEFAULT_OVERRIDE_CONTROL_F: () => { },
      DEFAULT_OVERRIDE_CONTROL_W: () => { },
      DEFAULT_OVERRIDE_CONTROL_T: () => { },
      DEFAULT_OVERRIDE_CONTROL_U: () => { },
      DEFAULT_OVERRIDE_CONTROL_I: () => { },
      DEFAULT_OVERRIDE_CONTROL_Y: () => { },
      DEFAULT_OVERRIDE_CONTROL_O: () => { },
      DEFAULT_OVERRIDE_CONTROL_E: () => { },
    };

    this.state = {
      editMode: false,
      handlers: this.handlers,
      selectedTimeSlot: null,
      allDayDate: null,
      createAllDayEvent: null,
      todayDate: format(new Date(), "P"),
      isMacOSElectronOSDarkTheme: isMacElectron() && isOSSchemeDarkMode(),
    };

    this._reloadComponentTimer = null;

    this.initialFullSync = this.initialFullSync.bind(this);
    this.initialUserSync = this.initialUserSync.bind(this);
    this.setCreateNewEventTimes = this.setCreateNewEventTimes.bind(this);
    this.goBackHome = this.goBackHome.bind(this);
    this.shouldShowRightPanel = this.shouldShowRightPanel.bind(this);
    this.getMovingWindow = this.getMovingWindow.bind(this);
    this.removeTimeSlot = this.removeTimeSlot.bind(this);
    this.removeAllDayEventDate = this.removeAllDayEventDate.bind(this);
    this.pullDataFromDate = this.pullDataFromDate.bind(this);
    this.determineSideLayoutPanelContent =
      this.determineSideLayoutPanelContent.bind(this);
    this.setTemplateType = this.setTemplateType.bind(this);
    this.onSwitchAccount = this.onSwitchAccount.bind(this);
    this.shouldWaitToRefreshFromUpdate =
      this.shouldWaitToRefreshFromUpdate.bind(this);
    this.createOrUpdateTemplates = this.createOrUpdateTemplates.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.deleteEventResponse = this.deleteEventResponse.bind(this);
    this.deleteAllHoldEvents = this.deleteAllHoldEvents.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.updateLastUsedTemplate = this.updateLastUsedTemplate.bind(this);
    this.updateFollowingInstancesOfRecurringEventOrganizer =
      this.updateFollowingInstancesOfRecurringEventOrganizer.bind(this);
    this.updateAllInstancesOfRecurringEvent =
      this.updateAllInstancesOfRecurringEvent.bind(this);
    this.completelyRefreshApp = this.completelyRefreshApp.bind(this);
    this.sendReferral = this.sendReferral.bind(this);
    this.handleResponseFromUserSync =
      this.handleResponseFromUserSync.bind(this);
    this.parseContacts = this.parseContacts.bind(this);
    this.fetchAllContacts = this.fetchAllContacts.bind(this);
    this.fetchPersonalContacts = this.fetchPersonalContacts.bind(this);
    this.fetchOtherContacts = this.fetchOtherContacts.bind(this);
    this.fetchDomainUsers = this.fetchDomainUsers.bind(this);
    this.parseMultipleEventsResponse =
      this.parseMultipleEventsResponse.bind(this);
    this.disconnectWebhooks = this.disconnectWebhooks.bind(this);
    this.onConnectedToWebhooks = this.onConnectedToWebhooks.bind(this);
    this.removeLoadingWebhooksChannel = this.removeLoadingWebhooksChannel.bind(this);
    this.toggleSelectCalendarCable = this.toggleSelectCalendarCable.bind(this);
    this.setUpActionCable = this.setUpActionCable.bind(this);
    this.updateMenuBarAndAgenda = this.updateMenuBarAndAgenda.bind(this);
    this.checkUpdateTodayDate = this.checkUpdateTodayDate.bind(this);
    this.reloadActionCable = this.reloadActionCable.bind(this);
    this.updateMenuBar = this.updateMenuBar.bind(this);
    this.upsertACL = this.upsertACL.bind(this);
    this.deleteACL = this.deleteACL.bind(this);
    this.updateACLPermissions = this.updateACLPermissions.bind(this);
    this.onExitEventForm = this.onExitEventForm.bind(this);
    this.refreshMenuBarApp = this.refreshMenuBarApp.bind(this);
    this.deleteMultipleEvents = this.deleteMultipleEvents.bind(this);
    this.updateInstances = this.updateInstances.bind(this);
    this.fetchCalendarEvents = this.fetchCalendarEvents.bind(this);
    this.setContactsIntoIndexDB = this.setContactsIntoIndexDB.bind(this);
    this.setDomainResourcesTimestamps = this.setDomainResourcesTimestamps.bind(this);
    this.syncAllLoggedInUserCalendarsAndEvents = this.syncAllLoggedInUserCalendarsAndEvents.bind(this);
    this.deleteEventsFromDB = this.deleteEventsFromDB.bind(this);
    this.storeEventsIntoUserDB = this.storeEventsIntoUserDB.bind(this);
    this.fetchEventsForCalendarForWindow = this.fetchEventsForCalendarForWindow.bind(this);
    this.createWebhookSubscription = this.createWebhookSubscription.bind(this);
    this.checkForTodayAndUpdate = this.checkForTodayAndUpdate.bind(this);
    this.fetchZoomSchedulers = this.fetchZoomSchedulers.bind(this);
    this.handleOSThemeChangeChange = this.handleOSThemeChangeChange.bind(this);
    this.parseSingleEventResponse = this.parseSingleEventResponse.bind(this);
    this.fetchContacts = this.fetchContacts.bind(this);

    Broadcast.subscribe("REMOVE_TIME_SLOT", this.removeTimeSlot);
    Broadcast.subscribe(
      "REMOVE_EVENT_ALL_DAY_DATE",
      this.removeAllDayEventDate,
    );
    Broadcast.subscribe(BROADCAST_VALUES.PULL_DATA_FROM_DATE, this.pullDataFromDate);
    Broadcast.subscribe("CREATE_TEMPLATE", (type) =>
      this.setTemplateType(type),
    );
    Broadcast.subscribe(
      "UPDATE_TEMPLATE",
      (type, template, acceptedInputs, title) =>
        this.setTemplateType(type, template, acceptedInputs, title),
    );
    Broadcast.subscribe("SWITCH_ACCOUNTS", this.onSwitchAccount);
    Broadcast.subscribe(
      "CALENDAR_HOME_VIEW_ON_EXIT_EVENT_FORM",
      this.onExitEventForm,
    );
    Broadcast.subscribe("CREATE_EVENT", this.createEvent);
    Broadcast.subscribe(BROADCAST_VALUES.UPDATE_EVENT, this.updateEvent);
    Broadcast.subscribe(BROADCAST_VALUES.UPDATE_INSTANCES, this.updateInstances);
    Broadcast.subscribe("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE", this.createOrUpdateTemplates);
    Broadcast.subscribe("DELETE_EVENT", this.deleteEvent);
    Broadcast.subscribe(
      "DELETE_MULTIPLE_EVENT_REQUEST",
      this.deleteMultipleEvents,
    );
    Broadcast.subscribe("DELETE_ALL_HOLD_EVENTS", this.deleteAllHoldEvents);
    Broadcast.subscribe("DELETE_TEMPLATE", this.deleteTemplate);
    Broadcast.subscribe("UPDATE_LAST_USED_TEMPLATE", this.updateLastUsedTemplate);
    Broadcast.subscribe(
      "UPDATE_FOLLOWING_INSTANCES_AS_ORGANIZER",
      this.updateFollowingInstancesOfRecurringEventOrganizer,
    );
    Broadcast.subscribe(
      "UPDATE_RECURRING_ALL_INSTANCES",
      this.updateAllInstancesOfRecurringEvent,
    );
    Broadcast.subscribe("COMPLETELY_REFRESH_APP", (data) =>
      this.completelyRefreshApp(data),
    );
    Broadcast.subscribe("REFER", this.sendReferral);
    Broadcast.subscribe("ADD_NEW_CONTACTS_INTO_INDEXDB", (contacts, userEmail) =>
      this.setSecondaryCalendarContactsIntoIndexDB(contacts, userEmail),
    );
    Broadcast.subscribe(
      "PARSE_MULTIPLE_EVENTS_RESPONSE",
      this.parseMultipleEventsResponse,
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.ON_DISCONNECT_WEBHOOKS,
      this.removeLoadingWebhooksChannel,
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.ON_CONNECT_WEBHOOKS,
      this.onConnectedToWebhooks,
    );
    Broadcast.subscribe(
      "TOGGLE_SELECT_CALENDAR_CABLE",
      this.toggleSelectCalendarCable,
    );
    Broadcast.subscribe(
      "UPDATE_MENU_BAR_AND_AGENDA",
      this.updateMenuBarAndAgenda,
    );
    Broadcast.subscribe("CHECK_UPDATE_TODAY_DATE", this.checkUpdateTodayDate);
    Broadcast.subscribe("UPDATE_MENU_BAR", this.updateMenuBar);
    BackendBroadcast.subscribe(
      "UPDATE_ACL_PERMISSION",
      this.updateACLPermissions,
    );
    Broadcast.subscribe("REFRESH_MENU_BAR_APP", this.refreshMenuBarApp);
    BackendBroadcast.subscribe(
      BACKEND_BROADCAST_VALUES.FETCH_EVENTS_FOR_CALENDAR,
      this.fetchCalendarEvents,
    );
    contactBroadcast.subscribe("SAVE_CONTACTS_INTO_DB", this.setContactsIntoIndexDB);
    contactBroadcast.subscribe("SET_DOMAIN_RESOURCE_TS", this.setDomainResourcesTimestamps);
    Broadcast.subscribe("SYNC_ALL_LOGGED_IN_CALENDARS_AND_EVENTS", this.syncAllLoggedInUserCalendarsAndEvents);
    dbBroadcast.subscribe("ADD_EVENTS_TO_DB", this.storeEventsIntoUserDB);
    backendBroadcasts.subscribe("FETCH_EVENTS_FOR_CALENDAR_WITHIN_WINDOW", this.fetchEventsForCalendarForWindow);
    appBroadcast.subscribe("CHECK_FOR_TODAY_AND_BACKEND_TRIGGER_APP_UPDATE", this.checkForTodayAndUpdate);
    conferencingBroadcasts.subscribe("FETCH_ZOOM_SCHEDULERS", this.fetchZoomSchedulers);
    backendBroadcasts.subscribe("INITIAL_USER_SYNC", this.initialUserSync);
    backendBroadcasts.subscribe(BACKEND_BROADCAST_VALUES.UPDATE_EVENT_RESPONSE, this.parseSingleEventResponse);
    contactBroadcast.subscribe(CONTACT_BROADCAST_VALUES.FETCH_CONTACTS, this.fetchContacts);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      false,
    );
  }

  componentDidMount() {
    // Save for debugging since we always use this
    // console.log("allCalendars_", Object.values(this.props.allCalendars.allCalendars).filter(c => getCalendarUserEmail(c) === "mike@vimcal.com") );
    // console.log("allCalendars_", this.props.allCalendars.allCalendars);
    // console.log("masterAccount_", this.props.masterAccount.masterAccount);
    // console.log("currentUser_", this.props.currentUser);
    // console.log("allLoggedInUsers_", this.props.allLoggedInUsers.allLoggedInUsers);
    // console.log("this.props.currentUser_", this.props.currentUser);
    this._isMounted = true;

    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    this._consumerWebHook = createWebhooksConsumerForAllUsers(allLoggedInUsers);

    if (this.props.isInOnboardingPreload) {
      return;
    }
    this.updateDesktopIconDayOfMonth();

    if (isDemoAccount(this.props.masterAccount)) {
      try {
        const loginDate = getAutoLogoutLoginDate(DEMO_ACCOUNT_EMAIL);
        if (!loginDate) {
          // set login date
          setAutoLogoutLoginDate(DEMO_ACCOUNT_EMAIL);
        } else if (isSameDay(loginDate, new Date())) {
          return;
        }
        // log out if it's been more than a day
        appBroadcast.publish("CLICK_LOG_OUT");
        return;
      } catch (error) {
        handleError(error);
      }
    }

    this.addOSThemeListener();

    this.checkMetrics();

    const tabEmail = getCurrentUserEmail();
    const {
      currentUser,
      availabilitySelectedMinutes,
      popupEvent,
      hoverPopupEvent,
    } = this.props;
    if (tabEmail && !isSameEmail(tabEmail, getUserEmail(currentUser))) {
      const matchingAccount = getAccountForEmail(tabEmail);
      if (!matchingAccount) {
        sendMessageToSentry(
          "Log out on no matchingAccount",
          `${tabEmail}_${getUserEmail(currentUser)}`,
        );

        trackError({
          category: "log_out_bug",
          errorMessage: `Log_out_on_no_matchingAccount_${matchingAccount}_${tabEmail}`,
          userToken: getUserToken(currentUser),
        });

        appBroadcast.publish("CLICK_LOG_OUT");
        return;
      }

      this.onSwitchAccount({
        account: matchingAccount,
        shouldNotDisplayNotification: true,
      });
      return;
    }

    if (shouldRefreshPage()) {
      this.props.history.push("/home");
      triggerRefreshWithOnlineCheck();
      return;
    }

    this.startDetectWakeUpInterval();

    this._isSwitchingAccount = false;
    this.setSentryEmail(getUserEmail(currentUser));

    getUserEmail(currentUser) && this.initialFullSync(true);

    this.setUpActionCable();

    batch(() => {
      const defaultUserTimeZone = this.getDefaultUserTimeZone();
      if (this.props.defaultBrowserTimeZone !== defaultUserTimeZone) {
        this.props.setDefaultBrowserTimeZone(defaultUserTimeZone);
      }

      if (
        !isEmptyObjectOrFalsey(currentUser.availability_settings) &&
        currentUser.availability_settings.duration !==
        availabilitySelectedMinutes
      ) {
        this.props.setAvailabilitySelectedMinutes(
          currentUser.availability_settings.duration,
        );
      }

      if (!isEmptyObjectOrFalsey(popupEvent)) {
        this.props.removePopupEvent();
      }

      if (!isEmptyObjectOrFalsey(hoverPopupEvent)) {
        this.props.removeHoverPopupEvent();
      }
    });

    this.fetchZoomSchedulers();

    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_OUTSTANDING_SLOTS);
    }, 5 * SECOND_IN_MS);
    storeAppVersionV1(); // used for migration
  }

  updateACLPermissions(updatedPermissions) {
    if (isEmptyObjectOrFalsey(updatedPermissions)) {
      return;
    }

    let deletedIndex = {}; // {user_calendar_id: [email]};
    let updatedIndex = {}; // {user_calendar_id: [{email, permission}]};
    let currentCalendarACL = getCalendarACL(this.props.currentUser) || {};

    Object.keys(currentCalendarACL).forEach((k) => {
      // get deleted emails
      deletedIndex[k] = [];
      let newEmails = [];
      if (updatedPermissions[k]) {
        // get array of emails to see if an email doesn't exist
        newEmails = updatedPermissions[k].map((e) => getUserEmail(e));
      }
      currentCalendarACL[k].forEach((a) => {
        if (!newEmails.includes(getUserEmail(a))) {
          deletedIndex[k] = deletedIndex[k].concat(getUserEmail(a));
        }
      });
    });
    Object.keys(updatedPermissions).forEach((k) => {
      // to see if an email got added or updated
      updatedIndex[k] = []; // array of objects {email, permission};
      let existingRole = {};
      if (currentCalendarACL[k]) {
        // get array of emails to see if an email doesn't exist
        currentCalendarACL[k].forEach((r) => {
          existingRole[getObjectEmail(r)] = r.permission;
        });
      }
      updatedPermissions[k]?.forEach((a) => {
        if (!existingRole[getObjectEmail(a)] || existingRole[getObjectEmail(a)] !== a.permission) {
          updatedIndex[k] = updatedIndex[k].concat(a);
        }
      });
    });

    let promiseArray = [];
    Object.keys(deletedIndex).forEach((k) => {
      deletedIndex[k].forEach((e) => {
        promiseArray = promiseArray.concat(this.deleteACL(k, `user:${e}`));
      });
    });

    Object.keys(updatedIndex).forEach((k) => {
      updatedIndex[k].forEach((p) => {
        promiseArray = promiseArray.concat(
          this.upsertACL(k, p.permission, getObjectEmail(p)),
        );
      });
    });

    Promise.all(promiseArray)
      .then((responses) => {
      })
      .catch((e) => {
        handleError(e);
      });
  }

  deleteACL(calendarId, rule_id) {
    let path = `calendars/${calendarId}/acl`;
    let url = constructRequestURL(path);

    // The format will be 'user:{USER_EMAIL}' for permissions shared to users.
    // In the future, we might do this with domains or groups, so the format for
    // those would be 'domain:{DOMAIN_EMAIL}' or 'group:{GROUP_EMAIL}'.
    // User permissions suffice for now.
    // rule_id: 'user:michael.zhao@vimcal.com'
    let payloadData = {
      body: JSON.stringify({ rule_id }),
    };

    return Fetcher.delete(url, payloadData, true, getUserEmail(this.props.currentUser));
  }

  upsertACL(calendarId, role, permissionReceiver) {
    let path = `calendars/${calendarId}/acl`;
    let url = constructRequestURL(path);

    // The 4 roles are listed here:
    // https://developers.google.com/calendar/api/v3/reference/acl
    // { role: 'reader', permission_receiver: 'michael.zhao@vimcal.com' }
    let payloadData = {
      body: JSON.stringify({ role, permission_receiver: permissionReceiver }),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser));
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._allCalendarsEventsSyncID = null;

    clearTimeout(this._updateMenuBarTimer);
    this._updateMenuBarTimer = null;

    this._timeoutList.forEach((timer) => {
      clearTimeout(timer);
    });

    this.removeOSThemeListener();

    clearTimeout(this._reloadComponentTimer);
    this._reloadComponentTimer = null;

    disconnectWebhooksForAllUsers(this._consumerWebHook);
    this._fetchOriginalRecurringEventsTimeoutList?.forEach((t) => {
      clearTimeout(t);
    });

    this.removeSyncs();

    Broadcast.unsubscribe("REMOVE_TIME_SLOT");
    Broadcast.unsubscribe("REMOVE_EVENT_ALL_DAY_DATE");
    Broadcast.unsubscribe(BROADCAST_VALUES.PULL_DATA_FROM_DATE);
    Broadcast.unsubscribe("CREATE_TEMPLATE");
    Broadcast.unsubscribe("UPDATE_TEMPLATE");
    Broadcast.unsubscribe("SWITCH_ACCOUNTS");
    Broadcast.unsubscribe("CALENDAR_HOME_VIEW_ON_EXIT_EVENT_FORM");
    Broadcast.unsubscribe("CREATE_EVENT");
    Broadcast.unsubscribe(BROADCAST_VALUES.UPDATE_EVENT);
    Broadcast.unsubscribe(BROADCAST_VALUES.UPDATE_INSTANCES);
    Broadcast.unsubscribe("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE");
    Broadcast.unsubscribe("DELETE_MULTIPLE_EVENT_REQUEST");
    Broadcast.unsubscribe("DELETE_ALL_HOLD_EVENTS");
    Broadcast.unsubscribe("DELETE_TEMPLATE");
    Broadcast.unsubscribe("UPDATE_LAST_USED_TEMPLATE");
    Broadcast.unsubscribe("UPDATE_FOLLOWING_INSTANCES_AS_ORGANIZER");
    Broadcast.unsubscribe("COMPLETELY_REFRESH_APP");
    Broadcast.unsubscribe("UPDATE_RECURRING_ALL_INSTANCES");
    Broadcast.unsubscribe("REFER");
    Broadcast.unsubscribe("ADD_NEW_CONTACTS_INTO_INDEXDB");
    Broadcast.unsubscribe("PARSE_MULTIPLE_EVENTS_RESPONSE");
    Broadcast.unsubscribe(BROADCAST_VALUES.ON_DISCONNECT_WEBHOOKS);
    Broadcast.unsubscribe(BROADCAST_VALUES.ON_CONNECT_WEBHOOKS);
    Broadcast.unsubscribe("TOGGLE_SELECT_CALENDAR_CABLE");
    Broadcast.unsubscribe("UPDATE_MENU_BAR_AND_AGENDA");
    Broadcast.unsubscribe("CHECK_UPDATE_TODAY_DATE");
    Broadcast.unsubscribe("UPDATE_MENU_BAR");
    BackendBroadcast.unsubscribe("UPDATE_USER_TIME_ZONE");
    BackendBroadcast.unsubscribe("UPDATE_ACL_PERMISSION");
    Broadcast.unsubscribe("REFRESH_MENU_BAR_APP");
    BackendBroadcast.unsubscribe(BACKEND_BROADCAST_VALUES.FETCH_EVENTS_FOR_CALENDAR);
    contactBroadcast.unsubscribe("SAVE_CONTACTS_INTO_DB");
    contactBroadcast.unsubscribe("SET_DOMAIN_RESOURCE_TS");
    Broadcast.unsubscribe("SYNC_ALL_LOGGED_IN_CALENDARS_AND_EVENTS");
    dbBroadcast.unsubscribe("ADD_EVENTS_TO_DB");
    backendBroadcasts.unsubscribe("FETCH_EVENTS_FOR_CALENDAR_WITHIN_WINDOW");
    appBroadcast.unsubscribe("CHECK_FOR_TODAY_AND_BACKEND_TRIGGER_APP_UPDATE");
    conferencingBroadcasts.unsubscribe("FETCH_ZOOM_SCHEDULERS");
    backendBroadcasts.unsubscribe("INITIAL_USER_SYNC");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.UPDATE_EVENT_RESPONSE);
    ContactBroadcast.unsubscribe(CONTACT_BROADCAST_VALUES.FETCH_CONTACTS);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    batch(() => {
      if (
        isActionModeUpsertEvent(this.props.actionMode) !== isActionModeUpsertEvent(prevProps.actionMode) &&
        isSelectColorPopup(this.props.popupEvent)
      ) {
        this.props.removePopupEvent();
      }

      if (this.props.shouldDisplayMenu !== prevProps.shouldDisplayMenu) {
        mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
      }
    });

    if (this.props.allLoggedInUsers.allLoggedInUsers !== prevProps.allLoggedInUsers.allLoggedInUsers) {
      this.handleUpdateUserForWebhooks();
    }
  }

  render() {
    // keeping for dev purposes since we always print master account to debug
    // console.log("currentUser_", this.props.currentUser);

    if (this.props.isInOnboardingPreload) {
      return (
        <>
          <FetchCalendarAndEventsContainer
            isSwitchingAccount={this._isSwitchingAccount}
            allCalendarsEventsSyncID={this._allCalendarsEventsSyncID}
            fetchId={this.fetchId}
          />
          <CalendarList renderOnlyContainer={true} />
        </>
      );
    }

    // on macs, OS theme changes the default background of the desktop app which we have to account for
    return (
      <div className={
        classNames(
          "calendar-home-view",
          this.state.isMacOSElectronOSDarkTheme && !this.props.isDarkMode ? "os-dark-theme-light" : "",
        )}
      >
        <MainCalendar
          createNewEventFromSelectSlot={this.setCreateNewEventTimes}
        />
        <FetchCalendarAndEventsContainer
          isSwitchingAccount={this._isSwitchingAccount}
          allCalendarsEventsSyncID={this._allCalendarsEventsSyncID}
          fetchId={this.fetchId}
        />

        <div
          id="side-menu-bar-container"
          className={
            this.props.shouldDisplayMenu
              ? "side-menu-bar-on-calendar-home-view"
              : ""
          }
        >
          <SideMenuBar setShouldDisplayMenu={this.props.setShouldDisplayMenu} />
        </div>

        {this.renderPopup()}

        <div
          className={this.getRightHandPanelClassName()}
          id={EVENT_PANEL_WRAPPER_ID}
        >
          {this.determineSideLayoutPanelContent()}
        </div>

        <PersonalLinkHotKeyContainer
          isSwitchingAccount={this._isSwitchingAccount}
        />

        {this.props.isMobileView ? null : (
          <SidePanel
            overrideClassName={this.shouldHideRightHandSide() ? "w-0 max-w-xs min-w-0 overflow-hidden" : null}
            className="height-100vh"
          >
            <MonthlyCalendarWithEventPreview
              shouldHideContent={this.shouldShowRightPanel()}
            />
          </SidePanel>
        )}
      </div>
    );
  }

  getRightHandPanelClassName() {
    if (!this.shouldShowRightPanel()) {
      return "";
    }

    return classNames("event-panel-on", this.props.isDarkMode ? "box-shadow-initial-important" : "");
  }

  renderPopup() {
    const {
      popupEvent,
      hoverPopupEvent,
      hideRightHandSidebar,
      actionMode,
    } = this.props;
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    if (
      !(
        (getPopUpEvent({
          popupEvent,
          hoverPopupEvent,
        })) &&
        (isAppInTaskMode({
          reverseSlotsText,
          actionMode,
        }) ||
          (isSelectColorPopup(popupEvent)) ||
          shouldTruncateRightHandPanel(hideRightHandSidebar)
        )
      )
    ) {
      return null;
    }

    return <PopupEvent />;
  }

  determineSideLayoutPanelContent() {
    const shouldPrint = false;
    const isExpandedEventRightPanelHidden = shouldTruncateRightHandPanel(this.props.hideRightHandSidebar);

    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    const {
      actionMode,
      popupEvent,
      currentPreviewedEvent,
      currentHoverEvent,
      hoverPopupEvent,
    } = this.props;

    if (isActionModeCreateAvailability(actionMode)) {
      return <AvailabilityPanel />;
    } else if (
      this.props.isDuplicateEvent &&
      isActionModeUpsertEvent(actionMode) &&
      getPreviewEvent({
        popupEvent,
        currentPreviewedEvent,
      })
    ) {
      // Duplicate event
      shouldPrint && console.log("here_10");
      return (
        <EventFormContainer
          isDuplicate={true}
          eventData={getPreviewEvent({
            popupEvent,
            currentPreviewedEvent,
          })}
          onRef={(ref) => (this.editView = ref)}
        />
      );
    } else if (
      !isExpandedEventRightPanelHidden &&
      !isEmptyObjectOrFalsey(currentHoverEvent) &&
      !isActionModeUpsertEvent(actionMode) &&
      !this.props.templateSideBar.templateType
    ) {
      shouldPrint && console.log("0 expanded view hover");

      return <EventExpandedView />;
    } else if (
      getPreviewEvent({
        popupEvent,
        currentPreviewedEvent,
        hoverPopupEvent,
        currentHoverEvent,
      }) &&
      isActionModeUpsertEvent(actionMode) &&
      !this.props.templateSideBar.templateType
    ) {
      shouldPrint && console.log("1 edit preview");

      return (
        <EventFormContainer
          eventData={getPreviewEvent({
            popupEvent,
            currentPreviewedEvent,
            hoverPopupEvent,
            currentHoverEvent,
          })}
          onRef={(ref) => (this.editView = ref)}
        />
      );
    } else if (
      isActionModeUpsertEvent(actionMode) &&
      this.props.templateSideBar.templateType === EVENT_TEMPLATE_FROM_EVENT
    ) {
      shouldPrint &&
        console.log("3 create event template through existing event");

      return (
        <EventFormContainer
          isTemplate={true}
          onRef={(ref) => (this.editView = ref)}
          eventData={this.props.templateSideBar.template}
          fromEvent={this.props.templateSideBar.acceptedInputs}
        />
      );
    } else if (
      isActionModeUpsertEvent(actionMode) &&
      this.props.templateSideBar.templateType === EVENT_TEMPLATE &&
      this.props.templateSideBar.title
    ) {
      shouldPrint && console.log("4 create event with title");

      return (
        <EventFormContainer
          isTemplate={true}
          title={this.props.templateSideBar.title}
          onRef={(ref) => (this.editView = ref)}
          eventData={this.props.templateSideBar.template}
        />
      );
    } else if (
      isActionModeUpsertEvent(actionMode) &&
      this.props.templateSideBar.templateType === EVENT_TEMPLATE
    ) {
      shouldPrint &&
        console.log("5 edit/update event template", this.props.templateSideBar);

      return (
        <EventFormContainer
          isTemplate={true}
          onRef={(ref) => (this.editView = ref)}
          eventData={this.props.templateSideBar.template}
        />
      );
    } else if (
      isActionModeUpsertEvent(actionMode) &&
      this.props.templateSideBar.templateType === TEXT_TEMPLATE &&
      this.props.templateSideBar.title
    ) {
      shouldPrint && console.log("9 create sticky with title");

      return (
        <TemplateView
          onRef={(ref) => (this.templateView = ref)}
          title={this.props.templateSideBar.title}
          backHome={this.goBackHome}
          template={this.props.templateSideBar.template}
        />
      );
    } else if (
      isActionModeUpsertEvent(actionMode) &&
      this.props.templateSideBar.templateType === TEXT_TEMPLATE
    ) {
      shouldPrint && console.log("6 text template update");

      return (
        <TemplateView
          onRef={(ref) => (this.templateView = ref)}
          backHome={this.goBackHome}
          template={this.props.templateSideBar.template}
        />
      );
    } else if (
      !getPreviewEvent({
        popupEvent,
        currentPreviewedEvent,
      }) &&
      isActionModeUpsertEvent(actionMode)
    ) {
      shouldPrint &&
        console.log(
          "7 new events",
          this.determineIsAllDay(),
          this.state.selectedTimeSlot,
        );

      return (
        <EventFormContainer
          allDayDate={this.determineIsAllDay()}
          selectedTimeSlot={this.state.selectedTimeSlot}
          onRef={(ref) => (this.editView = ref)}
        />
      );
    } else if (reverseSlotsText) {
      shouldPrint && console.log("9 reverse slots");
      return <ReverseSlotsPanel />;
    } else if (!isExpandedEventRightPanelHidden) {
      shouldPrint && console.log("8 else expand");

      return <EventExpandedView />;
    }

    return null;
  }

  //================
  // EVENT HANDLERS
  //================

  removeEventFormDataTimer() {
    if (!this.completeEventFormTimer) {
      return;
    }

    clearTimeout(this.completeEventFormTimer);
    this.completeEventFormTimer = null;
  }

  removeTimeSlot() {
    if (this.state.selectedTimeSlot) {
      this.setState({ selectedTimeSlot: null });
    }
  }

  goBackHome() {
    this.props.history.push("/home");
  }

  setCreateNewEventTimes(slot, updatedTemporaryEvent) {
    this.setState(
      {
        selectedTimeSlot: slot,
      },
      () => {
        this.props.setTemporaryEvent([updatedTemporaryEvent]);
        this.state.handlers.CREATE_EVENT();
        this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
        this.props.removePreviewedEvent();
        this.props.history.push("/home");
      },
    );
  }

  expandEvent(props) {
    if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
      props.history.push("/home/expanded");
    } else {
      props.history.push("/home");
    }
  }

  completelyRefreshApp(currentAccount) {
    this.onSwitchAccount({ account: currentAccount, isCompletelyRefreshApp: true });
  }

  onSwitchAccount({
    account,
    isCompletelyRefreshApp = false,
    shouldNotDisplayNotification = false,
    checkForFirstLogin = false,
  }) {
    this._isSwitchingAccount = true;

    setLastSwitchAccountTime();
    const { resetTeamPlan } = this.props.teamPlan;
    resetTeamPlan();

    const currentUserAccount = _.clone(this.props.currentUser);

    this.removeSyncs();
    persistor.pause();

    Broadcast.publish("WIPE_OUT_EVENTS");
    this.props.logout();

    persistor
      .purge()
      .then(() => {
        if (!this._isMounted) {
          return;
        }
        if (isCompletelyRefreshApp) {
          db.resetEmailDBIndex();

          const loggedInAccounts = getAllLoggedInAccountDetails();
          db.logOut(loggedInAccounts)
            .then(() => {
              if (!this._isMounted) {
                return;
              }

              this.resetReduxAndMount({
                account,
                isCompletelyRefreshApp,
                shouldNotDisplayNotification,
                checkForFirstLogin,
              });
            })
            .catch((err) => {
              handleError(err);

              if (!this._isMounted) {
                return;
              }
            });
        } else {
          this.resetReduxAndMount({
            account,
            isCompletelyRefreshApp,
            shouldNotDisplayNotification,
            checkForFirstLogin,
          });
        }
      })
      .catch((err) => {
        handleError(err);

        if (!this._isMounted) {
          return;
        }
        this.resetReduxAndMount({
          account: currentUserAccount,
          isCompletelyRefreshApp,
          shouldNotDisplayNotification,
          checkForFirstLogin,
        });
      });
  }

  setSentryEmail(email) {
    if (!email) {
      return;
    }

    Sentry.configureScope(function (scope) {
      scope.setUser({ email });
      scope.setTag("email", email);
    });
  }

  reopenEventFormAfterIncrementalAuth() {
    if (isEmptyObjectOrFalsey(getLocalEventFormState())) {
      return;
    }

    this.props.setActionMode({
      data: ACTION_MODE.UPSERT_EVENT,
      shouldKeepLocalEventState: true,
    });
  }

  resetReduxAndMount({
    account,
    isCompletelyRefreshApp = false,
    shouldNotDisplayNotification = false,
    checkForFirstLogin = false,
  }) {
    ReactDOM.unmountComponentAtNode(document.getElementById("root"));

    if (!account) {
      trackError({
        category: "no_account_found_on_switch_account",
        errorMessage: `length:${getAllLoggedInAccountDetails()?.length}`,
        userToken: null,
      });
      return;
    }

    const email = getUserEmail(account);
    this.setSentryEmail(email);

    const {
      setAllCalendars,
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    // reset all calendars
    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({masterAccount, allCalendars, allLoggedInUsers});
    const savedSelectedIndex = getCalendarSelectedOverridePerUser(account);
    const isMaestro = isUserMaestroUser(masterAccount);
    // this won't work on first login slash after seeing an account as the delegate

    if (isMaestro && !savedSelectedIndex ) {
      // unselect everything that is not in user master account
      const primaryCalendarForAccount = getMatchingPrimaryCalendarForUser({allCalendars, user: account});
      if (shouldHideDelegatedUserCalendar({
        calendar: primaryCalendarForAccount,
        allCalendars,
        allLoggedInUsers,
        masterAccount,
      })) {
        // toggle on the original calendar that the exec profile was created from
        const matchingExecutiveCalendar = getMatchingExecutiveCalendar({
          allCalendars,
          user: account,
        });
        const updatedAllCalendars = toggleOffAllCalendars({
          allCalendars,
          exceptionList: [getCalendarUserCalendarID(matchingExecutiveCalendar)],
        });
        setAllCalendars(updatedAllCalendars, account, "setAllCalendars_8.0");
      } else {
        const primaryCalendarUserCalendarID = getCalendarUserCalendarID(primaryCalendarForAccount);
        const updatedAllCalendars = toggleOffAllCalendars({
          allCalendars,
          exceptionList: primaryCalendarUserCalendarID
            ? [primaryCalendarUserCalendarID]
            : [], // except the primary calendar of the user
        });
        setAllCalendars(updatedAllCalendars, account, "setAllCalendars_8.1");
      }
    } else if (savedSelectedIndex) {
      // only run this if we toggle account not on new user
      let selectedCalendarIDs = [];
      Object.keys(savedSelectedIndex).forEach((userCalendarID) => {
        if (isUserMaestroUser(masterAccount)) {
          const calendar = getCalendarFromUserCalendarID({
            userCalendarID,
            allCalendars,
          });
          if (shouldHideDelegatedUserCalendar({
            calendar,
            allCalendars,
            allLoggedInUsers,
            masterAccount,
          })) {
            return;
          }
        }
        if (savedSelectedIndex[userCalendarID]) {
          selectedCalendarIDs = selectedCalendarIDs.concat(userCalendarID);
        }
      });

      if (isUserMaestroUser(masterAccount)
        && isUserDelegatedUser(account)
      ) {
        // get the matching calendar that the account is from
        const matchingExecutiveCalendar = getMatchingExecutiveCalendar({
          allCalendars,
          user: account,
        });
        if (selectedCalendarIDs.length === 0 || (matchingExecutiveCalendar && !selectedCalendarIDs.includes(getCalendarUserCalendarID(matchingExecutiveCalendar)))) {
          selectedCalendarIDs = selectedCalendarIDs.concat(getCalendarUserCalendarID(matchingExecutiveCalendar));
        }
      }
      if (selectedCalendarIDs.length === 0) {
        // if nothing is selected -> toggle on main calendar
        selectedCalendarIDs = upcomingCalendarIDs;
      }
      const updatedAllCalendars = toggleOffAllCalendars({
        allCalendars,
        exceptionList: selectedCalendarIDs,
      });

      setAllCalendars(updatedAllCalendars, account, "setAllCalendars_0");

      /* Open event form if needed */
      this.reopenEventFormAfterIncrementalAuth();
    }

    this.loadAccountData(account);

    persistor.persist();

    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>,
      document.getElementById("root"),
    );

    !isCompletelyRefreshApp &&
      !shouldNotDisplayNotification &&
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `Switched to ${getUserEmail(account)}`,
      );

    Sentry.configureScope(function (scope) {
      scope.setUser({ email: getUserEmail(account) });
    });
  }

  loadAccountData(account) {
    if (isEmptyObjectOrFalsey(account)) {
      return;
    }

    this.props.storeUserData(account);
    if (getUserEmail(account)) {
      setCurrentUserEmail(getUserEmail(account));
    }

    backendBroadcasts.publish("APPLY_MASTER_ACCOUNT_SETTINGS");
    batch(() => {
      const {
        masterAccount,
      } = this.props.masterAccount;

      // set personal links
      const unformattedPersonalLinks = localData(
        "get",
        `${PERSONAL_LINK_LOCAL_DATA}_${getUserEmail(account)}`,
      );

      const formattedPersonalLinks = unformattedPersonalLinks
        ? safeJSONParse(unformattedPersonalLinks)
        : null;
      if (formattedPersonalLinks) {
        const sortedLinks = sortPersonalLinks(formattedPersonalLinks);
        this.props.setPersonalLinks(sortedLinks);
      }

      const defaultUserTimeZone = getDefaultUserTimeZone({
        masterAccount,
        user: account,
      });

      if (isEmptyObjectOrFalsey(this.props.currentTimeZone)) {
        this.props.setTimeZone(defaultUserTimeZone);
      }

      if (this.props.defaultBrowserTimeZone !== defaultUserTimeZone) {
        this.props.setDefaultBrowserTimeZone(defaultUserTimeZone);
      }

      const anchorTimeZones = getAnchorTimeZonesInSettings({ user: account, masterAccount });
      if (anchorTimeZones?.length > 0) {
        this.props.setAnchorTimeZones(anchorTimeZones);
      }
    });
  }

  removeSyncs() {
    clearTimeout(this.refreshAppRandomMinuteCountdown);
    this.removeEventFormDataTimer();
    this.refreshAppRandomMinuteCountdown = null;
  }

  async syncOnWakeUp(syncAttemptNumber = 0) {
    // syncAtempt is the number of attempt
    if (syncAttemptNumber === 10) {
      return;
    }

    const isOnline = await checkOnlineStatus();
    if (!isOnline) {
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this.syncOnWakeUp(syncAttemptNumber + 1);
      }, fibonacci(syncAttemptNumber) * SECOND_IN_MS);
      return;
    }

    this.reloadReduxVariables();

    // whatever backend sends should always be layered ontop of index db
    const randomId = createUUID();
    this.fetchId = randomId;

    const { fetchStart, fetchEnd } = this.getCurrentViewFetchDates();

    this.backendEventsFetchID = createUUID();
    const {
      allCalendars,
    } = this.props.allCalendars;
    const currentUserEmail = getUserEmail(this.props.currentUser);

    const allActiveCalendarsUserCalendarID = getActiveCalendarsFromAllCalendars({
      allCalendars,
      currentUserEmail,
    })
      .map(calendar => getCalendarUserCalendarID(calendar));

    this.fetchEventsForUserCalendarIDs({
      userCalendarIDs: allActiveCalendarsUserCalendarID,
      dateFrom: fetchStart,
      dateTo: fetchEnd,
      fetchId: randomId,
      backendEventsFetchID: this.backendEventsFetchID,
    });
  }

  initialFullSync(isCleanRefresh = false) {
    let loginTime = localData(LOCAL_DATA_ACTION.GET, DEBOUNCE_BACKEND_TIMER);

    if (!this._alreadyDebouncedInitialFullSync && loginTime) {
      let jsDateLoginTime = parseISO(loginTime);
      if (
        isValid(jsDateLoginTime) &&
        differenceInSeconds(new Date(), jsDateLoginTime) < 5 &&
        differenceInSeconds(new Date(), jsDateLoginTime) >= 0
      ) {
        this.debounceInitialFullSync();
        return;
      }
    }

    if (this._isSwitchingAccount) {
      return;
    }

    this.reloadReduxVariables();

    const DEBOUNCE_TIMER = 500; // need to debounce otherwise we saw that it was overwhelming backend

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this.syncAllLoggedInUserCalendarsAndEvents(isCleanRefresh);
      }, 1 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this.initialUserSync(isCleanRefresh);
      }, 2 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        backendBroadcasts.publish("FETCH_AI_PERMISSION");
      }, 3 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        /* Check if user has pending team plan invites */
        teamPlanBroadcasts.publish("FETCH_TEAM_PLAN_INVITES");
      }, 4 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this._hasSyncedFetchContactThroughInitialFullSync = true;

        this.fetchContacts();
      }, 5 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_OUTLOOK_CATEGORIES);
      }, 6 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.INITIAL_CONNECTED_USERS_SYNC);
      }, 7 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        const {
          allLoggedInUsers,
        } = this.props.allLoggedInUsers;
        allLoggedInUsers.forEach((user) => {
          if (hasPermissionToViewDistroList(user)) {
            backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_DISTRO_LISTS, user);
          }
        });
      }, 8 * DEBOUNCE_TIMER),
    );

    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        updateSettingsBroadcast.publish("UPDATE_ACCOUNT_LOCAL_TIME_ZONE");
      }, 10 * SECOND_IN_MS),
    );
  }

  debounceInitialFullSync() {
    this._timeoutList = this._timeoutList.concat(
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this._alreadyDebouncedInitialFullSync = true;

        this.initialFullSync(true);
      }, SECOND_IN_MS),
    );
  }

  reloadActionCable() {
    if (!this._consumerWebHook) {
      return;
    }
    // https://github.com/rails/rails/blob/main/actioncable/app/assets/javascripts/action_cable.js
    this.setUpActionCable();
    reloadWebhooksForAllUsers(this._consumerWebHook);
  }

  getCalendarName(calendar) {
    const {
      currentUser,
      emailToNameIndex,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getCalendarName({
      calendar,
      currentUser,
      masterAccount,
      emailToNameIndex
    });
  }

  setUpActionCable() {
    if (!this._isMounted || !this._consumerWebHook) {
      return;
    }

    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    Object.values(allCalendars).forEach(calendar => {
      if (
        shouldWebhookAlwaysStayOn({ calendar, masterAccount, allCalendars, allLoggedInUsers })
      ) {
        // we connect to web hooks if it's selected or it's a main calendar
        this.createWebhookSubscription(calendar);
      }
    });
  }

  removeLoadingWebhooksChannel(userCalendarID) {
    // remove from loading channels once it's been connected or disconnected
    this._loadingChannels = this._loadingChannels.filter((c) => c !== userCalendarID);
  }

  onConnectedToWebhooks(userCalendarID) {
    if (!this._isMounted || !userCalendarID) {
      return;
    }
    this.removeLoadingWebhooksChannel(userCalendarID);

    if (IS_DEBUGGING_LOCAL_WEBHOOKS) {
      const {
        allCalendars
      } = this.props.allCalendars;
      console.log("onConnectedToWebhooks_: ", userCalendarID, this.getCalendarName(getCalendarFromUserCalendarID({
        userCalendarID: userCalendarID,
        allCalendars,
      })));
      console.log("this._consumerWebHook_: ", {...this._consumerWebHook});
    }
  }

  disconnectWebhooks(userCalendarID) {
    try {
      if (!this._isMounted || !userCalendarID) {
        return;
      }
      if (!this._consumerWebHook) {
        return;
      }
      const { allCalendars } = this.props.allCalendars;
      const { masterAccount } = this.props.masterAccount;
      const { allLoggedInUsers } = this.props.allLoggedInUsers;
      const matchingCalendar = getCalendarFromUserCalendarID({
        userCalendarID,
        allCalendars,
      });
      if (!matchingCalendar) {
        return;
      }

      // remove from loading channels since it's been connected
      this.removeLoadingWebhooksChannel(userCalendarID);
      if (shouldWebhookAlwaysStayOn({ calendar: matchingCalendar, masterAccount, allCalendars, skipCheckForSelected: true, allLoggedInUsers })) {
        return;
      }

      disconnectWebhooksForUserCalendarID({
        webhooksForUser: this._consumerWebHook[getCalendarUserEmail(matchingCalendar)],
        userCalendarID,
      });

      if (IS_DEBUGGING_LOCAL_WEBHOOKS) {
        console.log("onDisconnectWebhooks_", userCalendarID, this.getCalendarName(getCalendarFromUserCalendarID({
          userCalendarID: userCalendarID,
          allCalendars,
        })));
        console.log("this._consumerWebHook_: ", {...this._consumerWebHook});
      }
    } catch (error) {
      handleError(error);
    }
  }

  toggleSelectCalendarCable(calendar, shouldConnect = true) {
    const userCalendarID = getCalendarUserCalendarID(calendar);
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    if (!this._isMounted || !userCalendarID) {
      return;
    }
    if (!shouldConnect && shouldWebhookAlwaysStayOn({ calendar, masterAccount, allCalendars, skipCheckForSelected: true, allLoggedInUsers })) {
      // if turning off, we don't want to disconnect primary calendar or calendars that touch notifications
      return;
    }

    if (!isValidCalendar(calendar)) {
      return;
    }

    if (shouldConnect) {
      this.createWebhookSubscription(calendar);
    } else {
      this.disconnectWebhooks(userCalendarID);
    }
  }

  fetchAllContacts(user, { page = 1 } = {}) {
    if (this._isSwitchingAccount || isEmptyObjectOrFalsey(user)) {
      return;
    }

    const path = "contacts";
    const params = { page };

    const lastSyncAt = getContactLastSyncTime(user);
    if (lastSyncAt) {
      params.updated_at = lastSyncAt;
    }

    const url = `${constructRequestURL(path)}?${constructQueryParams(params)}`;
    Fetcher.get(url, {}, true, getUserEmail(user)).then((response) => {
      if (!this._isMounted || isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        return;
      }
      const {
        contacts,
      } = response;
      if (!contacts) {
        return;
      }

      if (contacts.length === 0) {
        // no new contacts, so we can save the last time we finished asking for contacts
        setContactLastSyncTime(user);
        return;
      }

      this.setContactsIntoIndexDB({ user, contacts, page }); // save contacts to DB
      this.fetchAllContacts(user, { page: page + 1 }); // fetch next page
    }).catch((error) => {
      handleError(error);
    });
  }

  fetchContacts() {
    if (this._isSwitchingAccount) {
      return;
    }
    if (!this._hasSyncedFetchContactThroughInitialFullSync) {
      return;
    }
    if (this._lastFetchContactsTime && differenceInMinutes(new Date(), this._lastFetchContactsTime) < 5) {
      //if less than 5 minutes, don't fetch again
      return;
    }

    this._lastFetchContactsTime = new Date();

    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    allLoggedInUsers.forEach(async (user) => {
      if (isEmptyObjectOrFalsey(user)) {
        return;
      }

      // The first request fetches the data saved in our account, while the other ones
      // issue a new request to Google, asking for these fields
      this.fetchAllContacts(user);
      if (user.provider === CALENDAR_PROVIDERS.OUTLOOK) {
        ContactBroadcast.publish(CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_CONTACTS, { user });
        ContactBroadcast.publish(CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_DOMAIN_USERS, { user, shouldFetchDomainUsers: this.shouldFetchDomainUsers(user) });
      } else {
        this.fetchPersonalContacts({ user, syncToken: getPersonalContactsSyncToken(user) });
        this.fetchOtherContacts({ user, syncToken: getOtherContactsSyncToken(user) });
        this.fetchDomainUsers({user}); // note: we only sync this once a day
      }
    });
  }

  shouldFetchDomainUsers(user) {
    if (!getUserEmail(user)) {
      return true;
    }

    // This needs to come first
    const shouldSkipDomainResourcesSync = localData(
      LOCAL_DATA_ACTION.GET,
      getShouldSkipDomainResourcesSync(getUserEmail(user)),
    );
    if (shouldSkipDomainResourcesSync) {
      return false;
    }

    const domainUsersSyncedAt = localData(
      LOCAL_DATA_ACTION.GET,
      getDomainUserSyncDate(getUserEmail(user)),
    );
    if (!domainUsersSyncedAt) {
      return true;
    }

    // Only call this once a day
    const parsedTime = parseISO(domainUsersSyncedAt);
    if (!isValidJSDate(parsedTime)) {
      return true;
    }

    return !isSameDay(new Date(), parsedTime);
  }

  async fetchDomainUsers({user, pageToken = null, requestCount = 0, retryAttempt = 0}) {
    if (retryAttempt > 3) {
      return;
    }
    if (this._isSwitchingAccount) {
      return;
    }

    if (!getUserEmail(user)) {
      return;
    }

    if (!this.shouldFetchDomainUsers(user)) {
      return;
    }

    // MAX REQUEST COUNT
    if (requestCount >= 15) {
      this.setDomainResourcesTimestamps(user);
    }

    const path = "domain_users";
    let url = constructRequestURL(path);

    if (!isNullOrUndefined(pageToken)) {
      const params = { next_page_token: pageToken };
      const queryParams = constructQueryParams(params);
      url += `?${queryParams}`;
    }

    const payloadData = {};
    payloadData["headers"] = getDefaultHeaders();

    const retryFetch = () => {
      const updatedRetryAttempt = retryAttempt + 1;
      setTimeout(() => {
        if (!this._isMounted || this._isSwitchingAccount) {
          return;
        }
        this.fetchDomainUsers({ user, pageToken, requestCount, retryAttempt: updatedRetryAttempt });
      }, SECOND_IN_MS * updatedRetryAttempt * retryAttempt);
    };

    try {
      const response = await Fetcher.get(url, payloadData, true, getUserEmail(user));
      if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
        return;
      }

      if (response.error) {
        retryFetch();
        return;
      }

      if (response.skip_domain_resources_sync) {
        // Question: how does this get set to false?
        localData(LOCAL_DATA_ACTION.SET, getShouldSkipDomainResourcesSync(getUserEmail(user)), true);
        return;
      }

      const { nextPageToken } = response.domain_users;
      const {
        contacts,
      } = response;

      this.setContactsIntoIndexDB({ user, contacts });

      if (nextPageToken) {
        this.fetchDomainUsers({
          user,
          pageToken: nextPageToken,
          requestCount: requestCount++,
        });
      } else {
        this.setDomainResourcesTimestamps(user);
      }
    } catch (error) {
      handleError(error);
      retryFetch();
    }
  }

  setDomainResourcesTimestamps(user) {
    if (!getUserEmail(user)) {
      return;
    }

    const currentTime = new Date().toISOString();

    localData(LOCAL_DATA_ACTION.SET, getDomainUserSyncDate(getUserEmail(user)), currentTime);
  }

  async fetchOutlookConferenceRooms({user, page}) {
    try {
      const response = await fetchPaginatedConferenceRooms({user, page});

      if (!this._isMounted || isEmptyObjectOrFalsey(response) || response?.error) {
        return;
      }

      const { rooms, buildings, next_page } = response;
      const userEmail = getUserEmail(user);

      if (!isEmptyArrayOrFalsey(buildings)) {
        this.setBuildingsIntoIndexDB({buildings, userEmail});
      }

      if (!isEmptyArrayOrFalsey(rooms)) {
        this.setConferenceRoomsIntoIndexDB({ conferenceRooms: rooms, userEmail });
      }

      if (next_page) {
        /* First fetch is page 1 so we start at two or add to page */
        this.fetchOutlookConferenceRooms({ user, page: next_page });
      }
    } catch (e) {
      // ignore error for now
    }
  }

  initialUserSync(isCleanRefresh) {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    let allNewCurrentUsers = [];

    const createInitialUserRequest = ({
      userEmail,
      shouldCheckMasterAccount,
      isProxyUser = false,
      connectedAccountToken,
    }) => {
      let url = isProxyUser
        ? constructRequestURL("proxy_users/initial_user_sync")
        : constructRequestURLV2("initial_user_sync");

      if (!isCleanRefresh && this._lastUserSyncUTC[userEmail]) {
        let params = { last_synced_at: this._lastUserSyncUTC[userEmail] };
        let queryParams = constructQueryParams(params);

        url += `?${queryParams}`;
      }

      const payloadData = {};
      payloadData["headers"] = getDefaultHeaders();

      return parseInitialUserSync({
        url,
        payloadData,
        isCleanRefresh,
        userEmail,
        shouldCheckMasterAccount,
        connectedAccountToken,
      });
    };

    const parseInitialUserSync = ({
      url,
      payloadData,
      isCleanRefresh,
      userEmail,
      shouldCheckMasterAccount,
      connectedAccountToken,
    }) => {
      if (this._isSwitchingAccount) {
        return;
      }

      return fetcherGet({
        authorizationRequired: true,
        connectedAccountToken,
        email: userEmail || getUserEmail(this.props.currentUser),
        payloadData,
        url,
      })
        .then((response) => {
          if (!this._isMounted) {
            return;
          }
          if (isEmptyObjectOrFalsey(response) || isEmptyObjectOrFalsey(this.props.currentUser)) {
            return;
          }

          this.checkFeatureFlags(response);

          const { current_user, last_synced_at_utc } = response;
          allNewCurrentUsers = allNewCurrentUsers.concat(current_user);
          this._lastUserSyncUTC[userEmail] = last_synced_at_utc;

          this.handleResponseFromUserSync(
            response,
            userEmail,
            shouldCheckMasterAccount,
          );
          // if (!isCleanRefresh) {
          //   this.handleResponseFromUserSync(
          //     response,
          //     userEmail,
          //     shouldCheckMasterAccount,
          //   );
          // } else {
          //   this.handleResponseFromUserSync(
          //     response,
          //     userEmail,
          //     shouldCheckMasterAccount,
          //   );
            // delete buildings and conference rooms before putting newly fetched ones in
            // const dbDeletePromise =
            //   db.wipeOutConferenceRoomsAndBuildings(userEmail);

            // if (isEmptyArrayOrFalsey(dbDeletePromise)) {
            //   this.handleResponseFromUserSync(
            //     response,
            //     userEmail,
            //     shouldCheckMasterAccount,
            //   );
            //   return;
            // }

            // Promise.all(dbDeletePromise)
            //   .then(() => {
            //     if (!this._isMounted) {
            //       return;
            //     }

            //     this.handleResponseFromUserSync(
            //       response,
            //       userEmail,
            //       shouldCheckMasterAccount,
            //     );
            //   })
            //   .catch((error) => {
            //     handleError(error);

            //     if (!this._isMounted) {
            //       return;
            //     }

            //     this.handleResponseFromUserSync(
            //       response,
            //       userEmail,
            //       shouldCheckMasterAccount,
            //     );
            //   });
          // }
        })
        .catch(handleError);
    };

    let fetchPromises = [];
    allLoggedInUsers.forEach((user, index) => {
      const fetchPromise = createInitialUserRequest({
        userEmail: getUserEmail(user),
        shouldCheckMasterAccount: index === 0,
        isProxyUser: isUserLimitedAccess(user),
        connectedAccountToken: getUserConnectedAccountToken({ user }),
      });
      if (isOutlookUser(user) && !isUserLimitedAccess(user)) {
        this.fetchOutlookConferenceRooms({user});
      }
      fetchPromises = fetchPromises.concat(fetchPromise);
    });

    Promise.all(fetchPromises).then(() => {
      if (!this._isMounted || this._isSwitchingAccount) {
        return;
      }

      const { allLoggedInUsers, setAllLoggedInUsers } =
        this.props.allLoggedInUsers;

      const updatedAllLoggedInUsers = updateAllLoggedInUsers(
        allLoggedInUsers,
        allNewCurrentUsers,
      );
      setAllLoggedInUsers(updatedAllLoggedInUsers);
      this.refreshMenuBarApp();
    });
  }

  syncAllLoggedInUserCalendarsAndEvents(isCleanRefresh = false) {
    this._allCalendarsEventsSyncID = createUUID(); // keep track to make sure another sync has not been called
    fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_INITIAL_CALENDAR_AND_EVENTS, {
      isCleanRefresh,
      windowFetchID: this._allCalendarsEventsSyncID,
    });
  }

  checkForTodayAndUpdate(response) {
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }

    this.checkUpdateTodayDate();

    // Response to refresh
    const syncBackendVersion = response.client_version;
    const localBackendVersion = getLastBackendResponseVersion();
    if (!syncBackendVersion) {
      return;
    }

    if (!localBackendVersion) {
      setLastBackendResponseVersion(syncBackendVersion);
    } else if (
      !this.debounceRefresh &&
      localBackendVersion &&
      localBackendVersion !== syncBackendVersion &&
      !this.shouldWaitToRefreshFromUpdate()
    ) {
      this.debounceRefresh = true;
      this.refreshApp(syncBackendVersion);
      return true;
    }
    return false;
  }

  shouldFetchAdditionalContacts(entry) {
    return (
      entry["openSearch$itemsPerPage"] &&
      entry["openSearch$totalResults"] &&
      parseInt(entry["openSearch$totalResults"]["$t"]) >
      parseInt(entry["openSearch$itemsPerPage"]["$t"])
    );
  }

  /**
   * @param {Object} options
   * @param {User | null | undefined} options.user
   * @param {string | null | undefined} options.syncToken
   * @param {string | null | undefined} options.pageToken
   * @param {number=} options.tries
   * @param {"personal" | "other"} options.type
   */
  contactFetcher({ user, syncToken, pageToken, tries = 0, type }) {
    if (this._isSwitchingAccount || isEmptyObjectOrFalsey(user) || tries > 3) {
      return;
    }

    const path = type === CONTACT_TYPES.PERSONAL ? CONTACTS_ENDPOINTS.PERSONAL : CONTACTS_ENDPOINTS.OTHER;
    const params = {};

    if (syncToken) {
      params["sync_token"] = syncToken;
    }

    if (pageToken) {
      params["page_token"] = pageToken;
    }

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path) + `?${queryParams}`;

    const retryFetch = (clearSyncToken = false) => {
      const updatedTries = tries + 1;
      setTimeout(() => {
        if (!this._isMounted || this._isSwitchingAccount) {
          return;
        }
        this.contactFetcher({
          user,
          syncToken: clearSyncToken ? undefined : syncToken,
          pageToken,
          tries: updatedTries,
          type,
        });
      }, SECOND_IN_MS * updatedTries * updatedTries);
    };

    return Fetcher.get(url, {}, true, getUserEmail(user))
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        if (isErrorResponse(response)) {
          if (response.error?.includes("insufficientPermissions")) {
            Broadcast.publish(
              "OPEN_PERMISSIONS_MODAL",
              {
                modalContent: "UPDATE_CONTACTS_PERMISSIONS",
                permissionProvider: response.user?.provider,
                permissionEmail: getUserEmail(response.user) ?? getUserEmail(user),
              },
            );
          } else if (response.error?.includes("failedPrecondition")) {
            this.contactFetcher({ user, tries: tries + 1, type });
          } else if (response.error?.includes("Sync token is expired")) {
            if (type === CONTACT_TYPES.PERSONAL) {
              clearPersonalContactsSyncToken(user);
            } else {
              clearOtherContactsSyncToken(user);
            }
            retryFetch(true);
          } else {
            retryFetch();
            // different error message
          }

          return;
        }

        const {
          contacts,
          nextPageToken,
          nextSyncToken,
        } = response;
        this.setContactsIntoIndexDB({ user, contacts }); // set into indexDB

        if (nextPageToken) {
          this.contactFetcher({ user, pageToken: nextPageToken, type });
        }

        if (nextSyncToken) {
          if (type === CONTACT_TYPES.PERSONAL) {
            storePersonalContactsSyncToken(nextSyncToken, user);
          } else {
            storeOtherContactsSyncToken(nextSyncToken, user);
          }
        }
      })
      .catch((error) => {
        handleError(error);
        retryFetch();
      });
  }

  /**
   * @param {Object} options
   * @param {User | null | undefined} options.user
   * @param {string | null | undefined} options.syncToken
   * @param {string | null | undefined} options.pageToken
   * @param {number=} options.tries
   */
  fetchPersonalContacts(options) {
    return this.contactFetcher({ ...options, type: CONTACT_TYPES.PERSONAL });
  }

  /**
   * @param {Object} options
   * @param {User | null | undefined} options.user
   * @param {string | null | undefined} options.syncToken
   * @param {string | null | undefined} options.pageToken
   * @param {number=} options.tries
   */
  fetchOtherContacts(options) {
    return this.contactFetcher({ ...options, type: CONTACT_TYPES.OTHER });
  }

  parseContacts({ contacts = [], user }) {
    if (isEmptyArrayOrFalsey(contacts)) {
      return [];
    }

    return contacts.map((contact) => {
      const {
        email,
        first_name,
        last_name,
        primary,
        primary_email,
      } = contact;
      return {
        email,
        firstName: first_name,
        lastName: last_name,
        name: getNameFromContact(contact),
        fullName: this.getFullNameArray(contact),
        primary,
        accountPrimaryEmail: primary_email,
        userEmail: getUserEmail(user),
      };
    });
  }

  getFullNameArray(contact) {
    const {
      first_name,
      last_name,
      name,
      email,
    } = contact;
    const nameSplit = [first_name, last_name, name].filter(name => !!name) ?? [];

    return [].concat(nameSplit).concat(splitEmailIntoNames(email));
  }

  handleResponseFromUserSync(
    response,
    userEmail,
    shouldCheckMasterAccount = false,
  ) {
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }

    const {
      buildings,
      rooms,
      current_user,
      master_account,
    } = response;

    if (buildings) {
      this.setBuildingsIntoIndexDB({buildings, userEmail});
    }

    if (rooms) {
      this.setConferenceRoomsIntoIndexDB({
        conferenceRooms: rooms,
        userEmail,
      });
    }

    if (isSameEmail(userEmail, getUserEmail(this.props.currentUser))) {
      if (!isEmptyObjectOrFalsey(current_user)) {
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION, current_user);
      }
    }

    if (shouldCheckMasterAccount) {
      /* Merge the master accounts settings into master accounts before setting into store */
      appBroadcast.publish(
        "UPDATE_MASTER_ACCOUNT",
        master_account,
      );
    }
  }

  formatAndParseEvents(eventList, isCleanRefresh = false) {
    if (!eventList || eventList.length === 0) {
      this.parseEventIntoMainCalendarAndAgendaPanel([], [], [], isCleanRefresh);
      return;
    }

    let formattedEvents = [];
    let eventsWithinTimeWindow = [];
    let cancelledEvents = [];

    let inputCurrentTimeZone = this.props.currentTimeZone;

    const { formatEventsList } = formatGCalEventsList({
      eventList,
      currentTimeZone: inputCurrentTimeZone,
    });

    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
      todayLeftJSDate,
      todayRightJSDate,
    } = this.getMovingWindow();

    let recurringEvents = [];
    const {
      allCalendars,
    } = this.props.allCalendars;
    const activeCalendarUserIDs = getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(this.props.currentUser),
    });

    formatEventsList.forEach((e) => {
      formattedEvents = formattedEvents.concat(e);

      if (getEventMasterEventID(e)) {
        recurringEvents = recurringEvents.concat(e);
      }

      if (
        activeCalendarUserIDs.includes(getEventUserCalendarID(e)) &&
        e.epochUnixWeek &&
        (isEventWithinJSWindow({event: e, windowStart: dbWindowStartJSDate, windowEnd: dbWindowEndJSDate}) ||
          isEventWithinJSWindow({event: e, windowStart: todayLeftJSDate, windowEnd: todayRightJSDate}) ||
          isCancelledEvent(e))
      ) {
        eventsWithinTimeWindow = eventsWithinTimeWindow.concat(e);
      }

      if (isCancelledEvent(e)) {
        cancelledEvents = cancelledEvents.concat(e);
      }
    });

    this.parseEventIntoMainCalendarAndAgendaPanel(
      formattedEvents,
      eventsWithinTimeWindow,
      cancelledEvents,
      isCleanRefresh,
    );
  }

  createEventHandler(e, props) {
    hasEventPreventDefault(e);
    this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
    this.props.removePreviewedEvent();
  }

  checkUpdateTodayDate() {
    if (this.state.todayDate !== format(new Date(), "P")) {
      const key = { key: Math.floor(Math.random() * 100000000) };

      db.fetch(getUserEmail(this.props.currentUser))
        .hashKey.put(key)
        .then(() => {
          if (!this._isMounted) {
            return;
          }

          this.updateTodayDate();
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }

  updateTodayDate() {
    this.setState({ todayDate: format(new Date(), "P") });
    Broadcast.publish("UPDATE_DAY");
  }

  //=================
  // PRIVATE METHODS
  //=================

  determineIsAllDay() {
    if (this.state.createAllDayEvent && this.state.allDayDate) {
      return this.state.allDayDate;
    } else if (
      this.state.selectedTimeSlot &&
      isSelectedSlotAllDayEvent({
        start: this.state.selectedTimeSlot.start,
        end: this.state.selectedTimeSlot.end,
      })
    ) {
      return formatISO(this.state.selectedTimeSlot.start);
    } else {
      return false;
    }
  }

  reloadReduxVariables() {
    if (isEmptyObjectOrFalsey(this.props.currentUser)) {
      // Redux gets wiped out on refresh
      const currentUserEmail = getCurrentUserEmail();
      const formattedAllAccounts = getAllLoggedInAccountDetails();

      if (currentUserEmail && formattedAllAccounts.length > 0) {
        let currentAccount;

        formattedAllAccounts.forEach((a) => {
          if (!currentAccount && isSameEmail(getObjectEmail(a), currentUserEmail)) {
            currentAccount = a;
          }
        });

        if (currentAccount) {
          this.loadAccountData(currentAccount);

          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR); // pull events from main calendar after setting active users
        }
      }
    }
  }

  refreshApp(clientVersion) {
    let randomMin = randomNumber(1, 20);

    clearTimeout(this.refreshAppRandomMinuteCountdown);
    this.refreshAppRandomMinuteCountdown = null;

    if (!hasBasicInternetConnection()) {
      this.refreshAppRandomMinuteCountdown = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.refreshApp(clientVersion);
      }, randomMin * MINUTE_IN_MS);
      return;
    }

    if (
      isInternalTeamUser(this.props.currentUser)
    ) {
      // If vimcal employee -> refresh right away
      this.debounceRefresh = false;
      localData(LOCAL_DATA_ACTION.SET, "backendAppVersion", clientVersion);
      Broadcast.publish("SET_SHOULD_REFRESH_APP");
      return;
    }

    this.refreshAppRandomMinuteCountdown = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      if (this.shouldWaitToRefreshFromUpdate()) {
        this.refreshApp(clientVersion);

        return;
      }

      this.debounceRefresh = false;

      localData(LOCAL_DATA_ACTION.SET, "backendAppVersion", clientVersion);

      Broadcast.publish("SET_SHOULD_REFRESH_APP");
    }, randomMin * MINUTE_IN_MS);
  }

  deleteTemplate(data) {
    let { url, templateId } = data;

    this.displayInProcessOfDeletingNotification();

    return Fetcher.delete(url, {}, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (isEmptyObjectOrFalsey(response)) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            "Failed to delete template",
          );
        } else if (response.message === TEMPLATE_DELETED) {
          const { masterAccount } = this.props.masterAccount;
          const updatedMasterAccount = removeTemplateFromMasterAccount({ masterAccount, templateId});
          appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount);

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            "Deleted template",
          );
        }
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Failed to delete template",
        );
      });
  }

  getUserEmailFromEventWithCurrentUserBackupFromEvent(event) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;

    return getUserEmailFromEventWithCurrentUserBackup({
      event,
      allCalendars,
      currentUser,
    });
  }

  handleErrorOnSingleEventDeletion(originalEvent) {
    if (isEmptyObjectOrFalsey(originalEvent)) {
      return;
    }
    mainCalendarBroadcast.publish("REMOVE_MIMIC_DELETE_EVENT", originalEvent);

    const {
      allCalendars,
    } = this.props.allCalendars;

    const {
      currentTimeZone,
      currentUser,
    } = this.props;

    if (
      getActiveCalendarsIDsFromAllCalendars({
        allCalendars,
        currentUserEmail: getUserEmail(currentUser),
      }).includes(getEventUserCalendarID(originalEvent))
    ) {
      // For cases where the timezone changes after opening edit event
      const updatedOriginalEvent = formatEventForReactBigCalendar({
        event: originalEvent,
        currentTimeZone,
        calendarId: getEventUserCalendarID(originalEvent),
      });

      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, {
        event: updatedOriginalEvent,
        timeZone: currentTimeZone,
      });
    }
  }

  deleteEvent(
    event,
    { calendar_provider_id, event_provider_id, sendUpdates, userEmail, message },
    shouldSkipHoldModal = false,
  ) {
    if (isEventHoldEvent(event) && !shouldSkipHoldModal) {
      return Broadcast.publish("DELETE_HOLD_EVENTS", event);
    } else {
      this.fakeDeleteEvent({ event, type: MIMIC_TYPE.DELETE_SINGLE_EVENT });
    }

    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
      currentTimeZone,
    } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    const emailData = constructEmailData({
      event,
      currentTimeZone,
      currentUser: getMatchingUserFromEvent({
        event,
        allCalendars,
        allLoggedInUsers,
      }) ?? currentUser,
      masterAccount,
    });

    return ApiClient.deleteEvent(
      event,
      {
        calendar_provider_id,
        event_provider_id,
        sendUpdates,
        message,
        emailData,
      },
      userEmail || this.getUserEmailFromEventWithCurrentUserBackupFromEvent(event),
      true,
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            FAILED_TO_DELETE_EVENT_MESSAGE,
          );
          this.handleErrorOnSingleEventDeletion(event);
          return;
        }

        this.deleteEventResponse({
          response,
          userEventId: getEventUserEventID(event),
          deletedEvent: event,
          userEmail,
        });
      })
      .catch((response) => {
        handleError(response);

        this.handleErrorOnSingleEventDeletion(event);

        if (this._isMounted) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            FAILED_TO_DELETE_EVENT_MESSAGE,
          );
        }
      });
  }

  deleteAllHoldEvents(event, { google_calendar_id, userEmail, isSmartHold }) {
    /* Don't show the "Deleting..." text if smart holds */
    if (!isSmartHold) {
      this.displayInProcessOfDeletingNotification();
    }

    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
      currentTimeZone,
    } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const emailData = constructEmailData({
      event,
      currentUser: getMatchingUserFromEvent({
        event,
        allCalendars,
        allLoggedInUsers,
      }) ?? currentUser,
      masterAccount,
      currentTimeZone,
    });

    const inputUserEmail = userEmail || getUserEmail(this.props.currentUser);

    return ApiClient.deleteAllHoldEvents(
      event,
      {
        google_calendar_id,
        emailData,
      },
      inputUserEmail,
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            FAILED_TO_DELETE_EVENT_MESSAGE,
          );
          return;
        }

        if (response.events?.length > 0) {
          const {
            events,
          } = response;
          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS, {
            userEventIDs: events.map(event => getEventUserEventID(event)),
          });
          this.deleteEventsFromDB({
            userEventIDs: events.map(event => getEventUserEventID(event)),
            userEmail: inputUserEmail,
            isOnUserDelete: true,
          });
        }

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          SUCCESSFUL_DELETE_MULTIPLE_EVENTS_MESSAGE,
        );
      })
      .catch((response) => {
        handleError(response);

        if (this._isMounted) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            FAILED_TO_DELETE_EVENT_MESSAGE,
          );
        }
      });
  }

  async deleteMultipleEvents(data) {
    const { url, payloadData, userEventId, deletedEvent, userEmail, type } = data;

    this.displayInProcessOfDeletingNotification();
    const masterEventID = getEventMasterEventID(deletedEvent);
    mainCalendarBroadcast.publish("DELETE_RECURRING_EVENTS", {
      masterEventID,
      followingDate: type === DELETE_RECURRING_TYPE.THIS_AND_FOLLOWING
        ? deletedEvent?.eventStart
        : undefined, // only send for this and following
    });

    const inputEmail = userEmail || this.getUserEmailFromEventWithCurrentUserBackupFromEvent(deletedEvent);
    try {
      const response = await Fetcher.delete(
        url,
        payloadData,
        true,
        inputEmail,
      );
      if (!this._isMounted) {
        return;
      }

      if (!response) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE,
        );
        // if fail -> re-pull events from indexDB
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
        return;
      }

      if (response.events?.length === 0) {
        if (type === DELETE_RECURRING_TYPE.ALL
          && getEventMasterEventID(deletedEvent)
        ) {
          // delete event from db and main calendar
          await db.deleteRecurringEvents({
            email: inputEmail,
            masterEventID,
          });
          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            "Events deleted",
          );
          return;
        }

        // if not organizer -> this and following could not pass back deleted events
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Events deleted",
        );

        this.pullDataFromDate({date: getSelectedDayWithBackup(this.props.selectedDay)});
      } else {
        await db.deleteRecurringEvents({
          email: inputEmail,
          masterEventID,
          originalEvent: type === DELETE_RECURRING_TYPE.THIS_AND_FOLLOWING
            ? deletedEvent
            : undefined,
        });
        this.deleteEventResponse({
          response,
          userEventId,
          deletedEvent,
          userEmail,
        });
      }
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        FAILED_TO_DELETE_EVENT_MESSAGE,
      );
    }
  }

  async deleteEventResponse({ response, userEventId, deletedEvent, userEmail, isSmartHold }) {
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }

    const { event, events } = response;

    const createUserEventIDs = () => {
      if (userEventId) {
        return [userEventId];
      } else if (event) {
        return [getEventUserEventID(event)];
      } else if (events) {
        const cancelledEvents = events.filter(e => isCancelledEvent(e));
        return cancelledEvents.map((e) => getEventUserEventID(e));
      }
    };

    const userEventIDs = createUserEventIDs();

    // delete from dexie
    if (userEventIDs?.length > 0) {
      await this.deleteEventsFromDB({
        userEmail,
        userEventIDs,
        isOnUserDelete: true,
      });
    }

    const determineNotificationText = () => {
      if (isSmartHold) {
        /* Smart hold deletion text */
        return "Meeting created and holds auto-deleted";
      } else if (!isNullOrUndefined(event)) {
        /* Single event deletion text */
        return "Event deleted";
      } else if (events) {
        /* Multiple event deletion text */
        return "Events deleted";
      }
    };

    if (!isNullOrUndefined(event)) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, {
        deleteEvent: deletedEvent,
      });

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        determineNotificationText(),
      );
      const {
        currentPreviewedEvent,
        currentHoverEvent,
      } = this.props;
      if (userEventIDs?.includes(getEventUserEventID(currentPreviewedEvent)) ||
        userEventIDs?.includes(getEventUserEventID(currentHoverEvent))
      ) {
        this.removePreviewEvents();
      }
    } else if (events) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        determineNotificationText(),
      );
      this.parseMultipleEventsResponse(response, "deleteEventResponse");
    }
  }

  async deleteEventsFromDB({
    userEmail,
    userEventIDs,
    isOnUserDelete = false,
  }) {
    await deleteEventsFromIndexDB({
      userEventIDs,
      userEmail,
      currentUserEmail: getUserEmail(this.props.currentUser),
      isOnUserDelete,
      where: "calendarHomeView::deleteEventsFromDB",
    });

    if (!this._isMounted) {
      return;
    }

    this.updateMenuBarAndAgenda();
  }

  updateLastUsedTemplate(data) {
    let { url } = data;

    return Fetcher.patch(url, {}, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        const { masterAccount } = this.props.masterAccount;
        const updatedMasterAccount = updateMasterAccountTemplates({ masterAccount, template: response.template });
        appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount);
      })
      .catch((response) => {
        handleError(response);
      });
  }

  async updateAllInstancesOfRecurringEvent(data) {
    const { originalEvent, url, payloadData, lastState, userEmail } = data;

    this.displaySavingEventNotification();
    const email = userEmail || getUserEmail(this.props.currentUser);

    try {
      const response = await Fetcher.patch(
        url,
        payloadData,
        true,
        email,
      );

      if (!this._isMounted) {
        return;
      }

      if (this.didFailToUpdateRecurringEvent(response)) {
        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent,
          isUpdateEvent: true,
          lastState,
          response,
          location: "updateAllInstancesOfRecurringEvent_0",
          isRecurring: true,
        });

        return;
      }

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Events updated",
      );

      const masterEventID = getEventMasterEventID(originalEvent);
      await db.deleteRecurringEvents({
        email,
        masterEventID,
      });
      mainCalendarBroadcast.publish("DELETE_RECURRING_EVENTS", {masterEventID});

      this.parseMultipleEventsResponse(
        response,
        "updateAllInstancesOfRecurringEvent",
      );
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }

      this.handleResponseErrorOnEventUpdateOrCreate({
        originalEvent,
        isUpdateEvent: true,
        lastState,
        response: error,
        location: "updateAllInstancesOfRecurringEvent_1",
        isRecurring: true,
      });
    }
  }

  async updateFollowingInstancesOfRecurringEventOrganizer(data) {
    const {
      updateUrl,
      deleteUrl,
      deletePayloadData,
      payloadData,
      originalEvent,
      lastState,
      userEmail,
    } = data;

    this.displaySavingEventNotification();
    const email = userEmail || getUserEmail(this.props.currentUser);

    try {
      const response = await Promise.all([
        Fetcher.delete(
          deleteUrl,
          deletePayloadData,
          true,
          email,
        ),
        Fetcher.patch(
          updateUrl,
          payloadData,
          true,
          email,
        ),
      ]);

      if (!this._isMounted) {
        return;
      }

      if (this.didFailToUpdateRecurringEvent(response)) {
        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent,
          isUpdateEvent: true,
          lastState,
          response,
          location: "updateFollowingInstancesOfRecurringEventOrganizer_0",
          isRecurring: true,
        });

        return;
      }

      const [deletedEventsResponse, editedEventsResponse] = response;

      if (!deletedEventsResponse || !editedEventsResponse) {
        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent,
          isUpdateEvent: true,
          lastState,
          response,
          location: "updateFollowingInstancesOfRecurringEventOrganizer_1",
          isRecurring: true,
        });

        return;
      }
      const masterEventID = getEventMasterEventID(originalEvent);
      const followingDate = originalEvent.eventStart;
      await db.deleteRecurringEvents({
        email,
        masterEventID,
        originalEvent,
      });
      mainCalendarBroadcast.publish("DELETE_RECURRING_EVENTS", {
        masterEventID,
        followingDate,
      });

      const combinedEventsResponse = {
        calendar: deletedEventsResponse.calendar,
        events: deletedEventsResponse.events.concat(
          editedEventsResponse.events,
        ),
      };

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Events updated",
      );

      this.parseMultipleEventsResponse(
        combinedEventsResponse,
        "updateFollowingInstancesOfRecurringEventOrganizer",
      );
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }

      this.handleResponseErrorOnEventUpdateOrCreate({
        originalEvent,
        isUpdateEvent: true,
        lastState,
        response: error,
        location: "updateFollowingInstancesOfRecurringEventOrganizer_2",
        isRecurring: true,
      });
    }
  }

  createOrUpdateTemplates(data) {
    let {
      url,
      payloadData,
      isUpdate,
      isTextTemplate,
      originalEvent,
      lastState,
    } = data;

    this.displaySavingEventNotification();

    if (isUpdate) {
      return Fetcher.patch(url, payloadData, true, getUserEmail(this.props.currentUser))
        .then((response) => {
          if (!this._isMounted) {
            return;
          } else if (isEmptyObjectOrFalsey(response)) {
            this.bringUpEventFormLastState(
              lastState,
              true,
              originalEvent,
              true,
            );
            Broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Failed to update template",
            );

            return;
          }

          const { masterAccount } = this.props.masterAccount;
          const updatedMasterAccount = updateMasterAccountTemplates({ masterAccount, template: response.template });
          appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount);

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `${isTextTemplate ? "Text" : "Event"} template updated`,
          );
        })
        .catch((response) => {
          handleError(response);
          if (!this._isMounted) {
            return;
          }

          this.bringUpEventFormLastState(lastState, true, null, true);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Failed to update ${isTextTemplate ? "text" : "event"} template`,
          );
        });
    } else {
      return Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser))
        .then((response) => {
          if (!this._isMounted) {
            return;
          } else if (isEmptyObjectOrFalsey(response)) {
            Broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              `Failed to create ${isTextTemplate ? "text" : "event"} template`,
            );
            this.bringUpEventFormLastState(lastState, false, null, true);

            return;
          }

          const { masterAccount } = this.props.masterAccount;
          const updatedMasterAccount = addTemplateToMasterAccount({ masterAccount, template: response.template });
          appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount);

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `${isTextTemplate ? "Text" : "Event"} template created`,
          );
        })
        .catch((response) => {
          handleError(response);

          if (!this._isMounted) {
            return;
          }

          this.bringUpEventFormLastState(lastState, false, null, true);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Failed to create ${isTextTemplate ? "text" : "event"} template`,
          );
        });
    }
  }

  displaySavingEventNotification() {
    Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, "Saving...");
  }

  displayInProcessOfDeletingNotification() {
    Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, "Deleting...");
  }

  fakeDeleteEvent({ event, type }) {
    if (!type || isEmptyObjectOrFalsey(event)) {
      // always require type, otherwise this is too dangerous since we might have stale events
      return;
    }

    if (type === MIMIC_TYPE.DELETE_SINGLE_EVENT) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_MIMIC_DELETE_EVENT, event);
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, {
        deleteEvent: event,
      });
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Event deleted",
      );
      return; // early exit, otherwise, show in process of deleting
    }

    this.displayInProcessOfDeletingNotification();
  }

  createMimicEventForUpsert({
    type,
    eventData,
    updatedTemporaryEvent,
    originalEvent,
    isSmartHold = false,
  }) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    if (!type) {
      // always require type, otherwise this is too dangerous since we might have stale events
      return;
    }

    const updateEventTitle = ({ fakeEvent, eventData }) => {
      // since we put a slight ttimeout on the event update, we need to make sure the event is updated
      if (!fakeEvent) {
        return null;
      }

      if (eventData?.calendar_event?.title) {
        fakeEvent.summaryUpdatedWithVisibility = eventData.calendar_event.title;
      }

      return fakeEvent;
    };

    const {
      temporaryEvents,
    } = this.props;
    if (type === MIMIC_TYPE.CREATE_SINGLE_EVENT) {
      if (isEmptyArrayOrFalsey(temporaryEvents)) {
        return;
      }
      const fakeEvent = updateEventTitle({
        fakeEvent: createMimicEventFromEvent({
          event: temporaryEvents[0],
          allCalendars,
        }),
        eventData,
      });

      if (!fakeEvent) {
        return;
      }
      if (!isSmartHold) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Event created",
        );
      }
      return fakeEvent; // early exit so we don't get to "Creating event"
    }

    if (type === MIMIC_TYPE.UPDATE_SINGLE_EVENT) {
      if (!isEmptyObjectOrFalsey(updatedTemporaryEvent)) {
        const fakeEvent = createMimicEventFromEvent({
          event: updatedTemporaryEvent,
          allCalendars,
        });

        if (!fakeEvent || isEmptyObjectOrFalsey(originalEvent)) {
          return;
        }

        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, {
          deleteEvent: originalEvent,
          addTemporaryEvent: fakeEvent,
        });

        if(!isSmartHold) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            "Event updated",
          );
        }
        return; // early exit so we don't get to "Creating event"
      }

      if (isEmptyArrayOrFalsey(temporaryEvents)) {
        return;
      }
      const fakeEvent = updateEventTitle({
        fakeEvent: createMimicEventFromEvent({
          event: temporaryEvents[0],
          allCalendars,
        }),
        eventData,
      });

      if (!fakeEvent) {
        return;
      }

      if (!isSmartHold) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Event updated",
        );
      }
      return fakeEvent; // early exit so we don't get to "Creating event"
    }

    this.displaySavingEventNotification(); // last resort if it fails to mimic event update/creation
  }

  createEvent(data) {
    const {
      url,
      payloadData,
      jumpToEvent,
      lastState,
      userEmail,
      eventData,
    } = data;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      holdEvent,
      resetHoldEvent,
    } = this.props.temporaryStateStore;
    /* If we have a holdEvent in temporaryState */
    /* And the eventData (from the updated event) === holdsId as the holdEvent */
    /* Then the event is a smart hold (since holdEvent is only set in smartHold) */
    const isSmartHold = !isEmptyObjectOrFalsey(holdEvent) && eventData?.vholds_id === getEventHoldID(holdEvent);
    const mimicEvent = this.createMimicEventForUpsert({
      type: isTemporaryEventRecurringEvent(eventData) ? MIMIC_TYPE.CREATE_RECURRING_EVENTS : MIMIC_TYPE.CREATE_SINGLE_EVENT,
      eventData,
      isSmartHold,
    });
    this.addMimicEvent(mimicEvent);
    const emailForBackend = userEmail || getUserEmail(this.props.currentUser);

    return Fetcher.post(
      url,
      payloadData,
      true,
      emailForBackend,
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.removeMimicEvent(mimicEvent);

        if (isEmptyObjectOrFalsey(response) || response.error) {
          this.handleResponseErrorOnEventUpdateOrCreate({
            originalEvent: null,
            isUpdateEvent: false,
            lastState,
            response,
            location: "createEvent_0",
            isRecurring: false,
          });
          if (response?.error) {
            broadcastOpenPermissionModal({
              error: isNeedToShowUserConsentScreen(response) ? PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN : response.error,
              userEmail: getUserEmail(response.user) || emailForBackend,
              currentUserEmail: getUserEmail(this.props.currentUser),
              provider: response.user?.provider,
              allLoggedInUsers,
            });
          }
          return;
        }

        if (response?.deleted_user_event_ids) {
          const {
            deleted_user_event_ids,
          } = response;
          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS, {
            userEventIDs: deleted_user_event_ids,
          });

          this.deleteEventsFromDB({
            userEventIDs: deleted_user_event_ids,
            userEmail: emailForBackend,
            isOnUserDelete: true,
          });
          resetHoldEvent();
        }

        if (response.event) {
          this.parseSingleEventResponse({
            response,
            jumpToEvent,
            isEdit: false,
          });
        } else if (response.events) {
          if (isEmptyArrayOrFalsey(response.events)) {
            // if empty string of events, remove temporary events
            Broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Failed to create event",
            );
            Broadcast.publish("REMOVE_TEMPORARY_EVENTS");
            return;
          }

          if (!isSmartHold) {
            Broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Events created",
            );
          }

          this.parseMultipleEventsResponse(response, "createEvent");
        }
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }

        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent: null,
          isUpdateEvent: false,
          lastState,
          response: error,
          location: "createEvent_1",
          isRecurring: false,
          mimicEvent,
        });
      });
  }

  addMimicEvent(mimicEvent, removeTemporaryEvent = true) {
    if (isEmptyObjectOrFalsey(mimicEvent)) {
      return;
    }
    const {
      mimicEventsList,
    } = this.props;

    batch(() => {
      if (removeTemporaryEvent) {
        this.props.setTemporaryEvent(null);
      }
      this.props.setMimicEventsList([...addDefaultToArray(mimicEventsList), mimicEvent]);
    });
  }

  removeMimicEvent(mimicEvent) {
    const mimicEventID = getMimicEventID(mimicEvent);
    if (!mimicEventID) {
      return;
    }
    const {
      mimicEventsList,
    } = this.props;
    if (isEmptyArrayOrFalsey(mimicEventsList)) {
      return;
    }
    const updatedList = mimicEventsList.filter((e) => getMimicEventID(e) !== mimicEventID);
    if (updatedList.length === mimicEventsList.length) {
      return;
    }
    this.props.setMimicEventsList(updatedList);
  }

  handleResponseErrorOnEventUpdateOrCreate({
    originalEvent,
    isUpdateEvent,
    lastState,
    response,
    location,
    isRecurring,
    mimicEvent,
  }) {
    this.removeMimicEvent(mimicEvent);

    const {
      allCalendars,
    } = this.props.allCalendars;
    if (!originalEvent) {
      Broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    } else if (
      getActiveCalendarsIDsFromAllCalendars({
        allCalendars,
        currentUserEmail: getUserEmail(this.props.currentUser),
      }).includes(getEventUserCalendarID(originalEvent))
    ) {
      // For cases where the timezone changes after opening edit event
      const timeZone = this.props.currentTimeZone;
      const updatedOriginalEvent = formatEventForReactBigCalendar({
        event: originalEvent,
        currentTimeZone: timeZone,
        calendarId: getEventUserCalendarID(originalEvent),
      });

      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, {
        event: updatedOriginalEvent,
        removeTemporaryEvent: true,
        timeZone,
      });
    } else {
      Broadcast.publish(
        "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
        getEventUserCalendarID(originalEvent),
      );
    }

    // Reload event in event form
    this.bringUpEventFormLastState(
      lastState,
      isUpdateEvent,
      originalEvent,
      false,
    );

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `Failed to ${isUpdateEvent ? "update" : "create"} event`,
    );

    sentryHandleFailToCreateOrUpdate({
      originalEvent: originalEvent,
      currentUserEmail: getUserEmail(this.props.currentUser),
      isRecurring,
      response,
      location,
      lastState,
      isUpdate: isUpdateEvent,
    });
  }

  bringUpEventFormLastState(lastState, isUpdate, originalEvent, isTemplate) {
    if (isEmptyObjectOrFalsey(lastState)) {
      return;
    }

    if (isTemplate) {
      this.props.setLastEventFormState(lastState);

      this.setTemplateType(EVENT_TEMPLATE, originalEvent);

      this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
    } else if (!isUpdate) {
      // do not batch, order matters here
      // New event
      batch(() => {
        this.props.history.push("/home");
        this.props.setLastEventFormState(lastState);
      });

      this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
    } else if (isUpdate && !isEmptyObjectOrFalsey(originalEvent)) {
      // Existing event
      batch(() => {
        this.props.setLastEventFormState(lastState);
        this.props.setPreviewedEvent(originalEvent);
        this.props.history.push("/home");
      });

      this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
    }
  }

  updateEvent(data) {
    let {
      url,
      payloadData,
      originalEvent,
      remapWeeklyCalendarHotKeys,
      hasCalendarChanged,
      lastState,
      userEmail,
      eventData,
      updatedTemporaryEvent,
      shouldDeleteEvent,
    } = data;
    const { allCalendars } = this.props.allCalendars;
    /* When editing, we can assume that the event is a smart hold using only eventData */
    const isSmartHold = !!eventData?.vholds_id;
    let mimicEvent = null;
    if (shouldDeleteEvent) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, {
        deleteEvent: originalEvent,
      });
    } else {
      mimicEvent = this.createMimicEventForUpsert({
        type: MIMIC_TYPE.UPDATE_SINGLE_EVENT,
        eventData,
        updatedTemporaryEvent,
        originalEvent,
        isSmartHold,
      });
      this.addMimicEvent(mimicEvent);
    }

    return Fetcher.patch(
      url,
      payloadData,
      true,
      userEmail || this.getUserEmailFromEventWithCurrentUserBackupFromEvent(originalEvent),
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.removeMimicEvent(mimicEvent);

        // Check for multiple events here
        if (!isEmptyObjectOrFalsey(response) && !!response.events) {
          this.parseMultipleEventsResponse(response, "updateEvent");
          return;
        }

        if (isEmptyObjectOrFalsey(response) || !response.calendar || !response.event) {
          if (shouldDeleteEvent) {
            mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
          }
          this.handleResponseErrorOnEventUpdateOrCreate({
            originalEvent,
            isUpdateEvent: true,
            lastState,
            response,
            location: "updateEvent_0",
            isRecurring: false,
          });
          return;
        }

        if (hasCalendarChanged && originalEvent) {
          // Remove original event from indexDb
          db.fetch(userEmail || getUserEmail(this.props.currentUser))
            .events.where(DEXIE_EVENT_COLUMNS.USER_EVENT_ID)
            .equals(getEventUserEventID(originalEvent))
            .delete()
            .then((dbResponse) => {
              if (!this._isMounted) {
                return;
              }

              if (!isSmartHold) {
                Broadcast.publish(
                  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
                  "Event updated",
                );
              }

              this.parseSingleEventResponse({
                response,
                jumpToEvent: false,
                isEdit: true,
                remapWeeklyCalendarHotKeys,
              });
            })
            .catch((err) => {
              handleError(err);

              if (!this._isMounted) {
                return;
              }

              this.handleResponseErrorOnEventUpdateOrCreate({
                originalEvent,
                isUpdateEvent: true,
                lastState,
                response,
                location: "updateEvent_1",
                isRecurring: false,
              });
            });
        } else {
          if (!isSmartHold) {
            Broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Event updated",
            );
          }

          this.parseSingleEventResponse({
            response,
            jumpToEvent: false,
            isEdit: true,
            remapWeeklyCalendarHotKeys,
          });
        }

        if (isSmartHold) {
          this.deleteAllHoldEvents(originalEvent, {
            google_calendar_id: getEmailFromUserCalendarID(
              getEventUserCalendarID(originalEvent),
              allCalendars,
            ),
            userEmail: getUserEmailFromEvent(originalEvent, allCalendars),
            isSmartHold: true,
          });
        }
      })
      .catch((response) => {
        handleError(response);

        if (!this._isMounted) {
          return;
        }

        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent,
          isUpdateEvent: true,
          lastState,
          response,
          location: "updateEvent_2",
          isRecurring: false,
          mimicEvent,
        });
      });
  }

  async updateInstances({
    path,
    body,
    originalEvent,
    lastState = {},
    url = null,
    deleteAllInstances,
  }, v2 = false) {
    this.displaySavingEventNotification();

    if (deleteAllInstances) {
      const masterEventID = getEventMasterEventID(originalEvent);
      mainCalendarBroadcast.publish("DELETE_RECURRING_EVENTS", {
        masterEventID,
        followingDate: DELETE_RECURRING_TYPE.ALL, // outlook only has this and all, and this can only be done on all.
      });
    }

    try {
      const response = await Fetcher.patch(
        url || constructRequestURL(path, v2),
        { body: JSON.stringify(body) },
        true,
        this.getUserEmailFromEventWithCurrentUserBackupFromEvent(originalEvent),
      );
      if (!this._isMounted) {
        return;
      }

      if (this.didFailToUpdateRecurringEvent(response)) {
        if (deleteAllInstances) {
          // if it fails -> repull out events
          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
        }
        this.handleResponseErrorOnEventUpdateOrCreate({
          originalEvent,
          isUpdateEvent: true,
          lastState,
          response,
          location: "updateInstances_0",
          isRecurring: true,
        });
        return;
      }

      if (deleteAllInstances && getEventMasterEventID(originalEvent)) {
        // delete event from db and main calendar
        await db.deleteRecurringEvents({
          email: getEventUserEmail(originalEvent),
          masterEventID: getEventMasterEventID(originalEvent),
        });
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Reply sent",
        );
        return;
      }

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Events updated",
      );
      this.parseMultipleEventsResponse(response, "updateInstances");
    } catch (error) {
      handleError(error);

      if (!this._isMounted) {
        return;
      }

      this.handleResponseErrorOnEventUpdateOrCreate({
        originalEvent,
        isUpdateEvent: true,
        lastState,
        response: error,
        location: "updateInstances_1",
        isRecurring: true,
      });
    }
  }

  sendReferral(data) {
    let { url, payloadData, numberOfReferrals } = data;

    return Fetcher.patch(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `Referral${numberOfReferrals > 1 ? "s" : ""} sent. Thank you ❤️`,
        );
      })
      .catch((response) => {
        handleError(response);
      });
  }

  didFailToUpdateRecurringEvent(response) {
    return (
      isEmptyObjectOrFalsey(response) ||
      (response.events?.length === 0)
    );
  }

  parseSingleEventResponse({
    response,
    jumpToEvent,
    isEdit,
    remapWeeklyCalendarHotKeys,
  }) {
    if (!response || (response && !response.calendar) || !response.event) {
      return;
    }
    if (
      !getAllCalendarUserCalendarIDs(
        this.props.allCalendars.allCalendars,
      ).includes(getEventUserCalendarID(response.event))
    ) {
      this.filterOutOtherPeopleCalendarEventsAndReport(
        [response.event],
        "parseSingleEventResponse",
      );
      return;
    }

    let timeZone = this.props.currentTimeZone;
    const formattedEvent = formatEventForReactBigCalendar({
      event: response.event,
      currentTimeZone: timeZone,
      calendarId: getEventUserCalendarID(response.event),
    });

    if (jumpToEvent) {
      let newDate = formattedEvent.eventStart;
      this.props.selectDay(newDate);
      Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", newDate);
    }

    let formattedEventAndCalendar = {
      event: formattedEvent,
      calendar: response.calendar,
      removeTemporaryEvent: true,
      timeZone,
    };

    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const userCalendarID = getEventUserCalendarID(formattedEvent);
    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({ masterAccount, allCalendars, allLoggedInUsers });
    const userEmailFromCalendarUserCalendarID = getUserEmailFromUserCalendarID(
      userCalendarID,
      allCalendars,
    );

    const isIncludedInMainCalendars = doesMainCalendarsIncludeUserCalendarID(
      upcomingCalendarIDs,
      userCalendarID,
    );
    const formattedEventList = [formattedEvent];

    if (
      getActiveCalendarsIDsFromAllCalendars({
        allCalendars,
        currentUserEmail: getUserEmail(this.props.currentUser),
      }).includes(userCalendarID)
    ) {
      this.storeEventsIntoUserDB({
        userEmail: userEmailFromCalendarUserCalendarID,
        userEvents: formattedEventList,
        updateMenuBarAndAgenda: isIncludedInMainCalendars,
      });

      if (response?.removed_event) {
        // when you change a google event's calendar on edit
        const param = {deleteEvent: response?.removed_event};
        if (isEdit) {
          param.updateEventParam = formattedEventAndCalendar;
        } else {
          param.addEventParam = formattedEventAndCalendar;
        }
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, param);
      } else if (isEdit) {
        mainCalendarBroadcast.publish(
          MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENT_INTO_WEEKLY_CALENDAR,
          formattedEventAndCalendar,
        );
      } else {
        mainCalendarBroadcast.publish(
          MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR,
          formattedEventAndCalendar,
        );
      }
    } else {
      // Toggling calendar id (for hidden calendars) gets handled by storeEventsIntoUserDB
      this.storeEventsIntoUserDB({
        userEmail: userEmailFromCalendarUserCalendarID,
        userEvents: formattedEventList,
        updateMenuBarAndAgenda: isIncludedInMainCalendars,
        toggleCalendarID: userCalendarID,
      });

      Broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    }

    const {
      currentPreviewedEvent,
      currentHoverEvent,
    } = this.props;

    if (
      formattedEvent &&
      (getEventUserEventID(formattedEvent) === getEventUserEventID(currentPreviewedEvent) ||
        getEventUserEventID(formattedEvent) === getEventUserEventID(currentHoverEvent)
      )
    ) {
      // only replace if it's the same event
      // update previewed event (e.g.color change)
      this.props.setPreviewedEvent(formattedEvent);
    }

    if (jumpToEvent) {
      let scrollToHour = determineScrollToHour(formattedEvent.eventStart);

      Broadcast.publish(`SCROLL_TO_HOUR_${scrollToHour}`, false);
    }

    remapWeeklyCalendarHotKeys &&
      Broadcast.publish("UPDATE_HOTKEY_EVENTS_MAPPING");
  }

  removePreviewEvents() {
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  }

  createWebhookSubscription(calendar) {
    if (!this._consumerWebHook) {
      return;
    }
    const userCalendarID = getCalendarUserCalendarID(calendar);
    const userEmail = getCalendarUserEmail(calendar);
    const subscriptionName = `cable: userEmail: ${userEmail} || calendarID: ${userCalendarID}`;
    const { tabID } = this.props.tabIDStore;
    const subscriptionID = isElectron()
      ? `cable: userEmail: ${userEmail} || calendarID: ${userCalendarID}`
      : `cable: userEmail: ${userEmail} || calendarID: ${userCalendarID}|| tabID:${tabID}`;

    const { allCalendars } = this.props.allCalendars;
    if (
      this._loadingChannels.includes(userCalendarID) ||
      doesWebhookSubscriptionExistForUserCalendarID({
        webhooksForAllUsers: this._consumerWebHook,
        userCalendarID,
      })
    ) {
      // cable is already loaded
      return;
    }

    // since creating subscription is async, we add to loading channels so we don't load the same calendar twice
    // we remove from loading channels in onConnectWebhooks and onDisconnectWebhooks
    this._loadingChannels = this._loadingChannels.concat(userCalendarID);
    try {
      // create webhook consumer for user first if it doesn't exist
      if (!this._consumerWebHook[userEmail]) {
        this._consumerWebHook[userEmail] = createWebhooksConsumerForUser(userEmail);
      }
  
      const uniqueIdentifier = createUUID();
      this._consumerWebHook[userEmail]?.subscriptions?.create(
        { channel: "CalendarChannel", user_calendar_id: userCalendarID },
        {
          received(data) {
  
            if (isEmptyObjectOrFalsey(data)) {
              return;
            }
  
            if (data?.events || data?.recurring_events || data?.delete_events) {
              Broadcast.publish(
                "PARSE_MULTIPLE_EVENTS_RESPONSE",
                {
                  calendar: allCalendars[userCalendarID].calendar,
                  events: data?.events,
                  recurringEvents: data?.recurring_events,
                  deleteEvents: data?.delete_events,
                  userEmail: data?.user_email,
                  calendarProviderId: data?.calendar_provider_id,
                  doNotActivateHiddenCalendar: true,
                },
                subscriptionName,
                `${subscriptionID}_${uniqueIdentifier}`,
              );
            }
          },
          connected() {
            Broadcast.publish(BROADCAST_VALUES.ON_CONNECT_WEBHOOKS, userCalendarID);
          },
          disconnected() {
            Broadcast.publish(BROADCAST_VALUES.ON_DISCONNECT_WEBHOOKS, userCalendarID);
          },
        },
      );
    } catch (error) {
      handleError(error);
    }
  }

  async getMatchingDeletedOutlookUserEventIDs(response) {
    const userEmail = getObjectUserEmail(response);
    if (isEmptyArrayOrFalsey(response?.deleteEvents)
      || !userEmail
    ) {
      return;
    }

    const { deleteEvents } = response;

    let userEventIDsToBeDeleted = [];

    const getEventsFromDB = async (eventToDelete) => {
      if (isEmptyObjectOrFalsey(eventToDelete)) {
        return;
      }

      const { user_event_id, event_id } = eventToDelete;

      if (user_event_id) {
        // single event
        const matchingUserEventIDEvents = await db.fetch(userEmail).events.where({ user_event_id }).toArray();
        if (matchingUserEventIDEvents?.length > 0) {
          userEventIDsToBeDeleted = userEventIDsToBeDeleted.concat(matchingUserEventIDEvents.map(hit => getEventUserEventID(hit?.event)));
        }
      }

      if (event_id) {
        // recurring event
        const matchingMasterEventID = await getRecurringEventsFromDB({ masterEventID: event_id, userEmail });
        if (matchingMasterEventID?.length > 0) {
          userEventIDsToBeDeleted = userEventIDsToBeDeleted.concat(matchingMasterEventID.map(hit => getEventUserEventID(hit?.event)));
        }
      }
    };

    try {
      const fetchPromises = deleteEvents.map(event => getEventsFromDB(event));
      await Promise.all(fetchPromises);
      userEventIDsToBeDeleted = removeDuplicatesFromArray(userEventIDsToBeDeleted.filter(id => id));
      return userEventIDsToBeDeleted;
    } catch (err) {
      handleError(err);
    }
  }

  // Delete old instances of recurring events and return new instances
  async parseOutlookRecurringEvents(response) {
    if (isEmptyObjectOrFalsey(response?.recurringEvents)) {
      return;
    }

    const { recurringEvents, userEmail, calendarProviderId } = response;
    // recurringEvents can be either for create or update.
    // recurringEvents is an array of masterEventIDs ids ["AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAENAADJH9gyifHWQJDvUxlnnXDRAADhnuJ1AAA="]
    // userEmail: "mike@weveapp.com"
    // calendarProviderId: "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAAAAQ98AAA="
    if (!userEmail || !calendarProviderId) {
      return;
    }

    let allUserEventIDsToBeDeleted = [];

    const getEventsToDeleteFromDB = async (masterEventID) => {
      if (!masterEventID) {
        return;
      }

      const dbHits = await getRecurringEventsFromDB({
        userEmail,
        masterEventID,
      });

      if (!dbHits) {
        return;
      }

      const userEventIDs = dbHits.map(hit => getEventUserEventID(hit?.event)).filter(e => !!e);
      allUserEventIDsToBeDeleted = allUserEventIDsToBeDeleted.concat(userEventIDs);
      return userEventIDs;
    };

    try {
      const { windowStartDateJSDate, windowEndDateJSDate } = this.getMovingWindow();
      let eventsForUpsert = [];
      const fetchedListedRecurringEvents = await listRecurringInstances({
        masterEventIds: recurringEvents,
        timeMin: formatISO(windowStartDateJSDate),
        timeMax: formatISO(windowEndDateJSDate),
        calendarProviderId,
        userEmail,
      });
      if (recurringEvents?.length > 0) {
        await Promise.all(recurringEvents.map(eventID => getEventsToDeleteFromDB(eventID)));
      }

      if (fetchedListedRecurringEvents?.length > 0) {
        const cancelledEvents = fetchedListedRecurringEvents.filter(event => isCancelledEvent(event));
        const upsertedEvent = fetchedListedRecurringEvents.filter(event => !isCancelledEvent(event));
        eventsForUpsert = eventsForUpsert.concat(upsertedEvent);
        allUserEventIDsToBeDeleted = allUserEventIDsToBeDeleted.concat(cancelledEvents.map(event => getEventUserEventID(event)));
        const upsertedEventUserEventID = upsertedEvent.map(event => getEventUserEventID(event));

        // filter out events that are to be updated so we odn't delete and add and create race condition
        allUserEventIDsToBeDeleted = allUserEventIDsToBeDeleted.filter(id => !upsertedEventUserEventID.includes(id));
      }

      allUserEventIDsToBeDeleted = removeDuplicatesFromArray(allUserEventIDsToBeDeleted.filter(id => id));
      return {
        userEventIDsForDeletion: allUserEventIDsToBeDeleted,
        eventsForUpsert,
      };
    } catch (err) {
      handleError(err);
    }
  }

  isInternalUser() {
    return isInternalTeamUser(this.props.currentUser) || getUserEmail(this.props.currentUser)?.includes("mikevimcal");
  }

  async parseMultipleEventsResponse(response, caller) {
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }
    const shouldPrint = isLocal() || this.isInternalUser();
    if (shouldPrint) {
      console.log("=====webhook_response_caller=====", caller);
      console.log("current time: ", new Date());
      console.log("webhook_response_0", response);
    }
    const currentTime = new Date();

    const hasOutlookRecurringEvents = !isEmptyObjectOrFalsey(response?.recurringEvents);
    let outLookUserEventIDsToBeDeleted = [];
    if (hasOutlookRecurringEvents) {
      // returns list of userEventIDs to be deleted and eventsForUpsert
      const parsedResult = await this.parseOutlookRecurringEvents(response);
      shouldPrint && console.log("webhook_response_1", parsedResult, currentTime);
      if (parsedResult?.userEventIDsForDeletion?.length > 0) {
        const {
          userEventIDsForDeletion,
        } = parsedResult;
        outLookUserEventIDsToBeDeleted = outLookUserEventIDsToBeDeleted.concat(userEventIDsForDeletion);
      }

      if (parsedResult?.eventsForUpsert?.length > 0) {
        // add events to be added later
        const {
          eventsForUpsert,
        } = parsedResult;
        response.events = [
          ...(response?.events || []),
          ...eventsForUpsert,
        ];
      }
    }

    if (response?.deleteEvents?.length > 0) {
      const deletedOutlookUserEventIDs = await this.getMatchingDeletedOutlookUserEventIDs(response);
      if (deletedOutlookUserEventIDs?.length > 0) {
        outLookUserEventIDsToBeDeleted = outLookUserEventIDsToBeDeleted.concat(deletedOutlookUserEventIDs);
      }
    }

    // delete all the outlook events at once
    if (outLookUserEventIDsToBeDeleted.length > 0 && response?.userEmail) {
      // delete events fromIndexDB
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS, {
        userEventIDs: outLookUserEventIDsToBeDeleted,
      });

      await this.deleteEventsFromDB({
        userEventIDs: outLookUserEventIDsToBeDeleted,
        userEmail: getObjectUserEmail(response),
        isOnUserDelete: false,
      });
    }

    if (isEmptyArrayOrFalsey(response?.events)) {
      return;
    }

    const userCalendarID =
      getCalendarUserCalendarID(response) || getEventUserCalendarID(response);
    // if (actionCableHookID && response.events.length >= 100) {
    //   // track how often large action cable events come in
    //   trackError({
    //     category: "action_cable",
    //     errorMessage: `hookId=${actionCableHookID}_events=${response.events.length}`,
    //     userToken: this.props.currentUser.token
    //   });
    // }

    //  Recurring events -> need to add it to weekly calendar, agenda, and indexDB
    let unformattedEvents = [];
    const {
      windowStartDate,
      windowEndDate,
      todayLeftWindow,
      todayRightWindow,
    } = this.getMovingWindow();

    const isUnformattedEventInWindow = (event) => {
      const unixStart = convertDateIntoEpochUnixWeek(getEventStartValue(event));
      const unixEnd = convertDateIntoEpochUnixWeek(getEventEndValue(event));

      return (
        (unixStart <= windowEndDate && unixEnd >= windowStartDate) ||
        (unixStart <= todayRightWindow && unixEnd >= todayLeftWindow)
      );
    };

    response.events.forEach((e) => {
      if (isEmptyObjectOrFalsey(e) || !getEventStart(e)) {
        return;
      }

      if (isUnformattedEventInWindow(e)) {
        unformattedEvents = unformattedEvents.concat(e);
      }
    });

    let filteredOutOtherPeoplesCalendarEventsList =
      this.filterOutOtherPeopleCalendarEventsAndReport(
        unformattedEvents,
        caller,
      );

    const formatMultipleEventsResponse = () => {
      const { formatEventsList } = formatGCalEventsList({
        eventList: filteredOutOtherPeoplesCalendarEventsList,
        currentTimeZone: this.props.currentTimeZone,
        onlyUseCalendarIdIfEventCalendarIdDoesNoeExist: true,
        calendarId: userCalendarID,
      });

      storeResponseEventsIntoUserDB({
        formattedEvents: formatEventsList,
        doNotActivateHiddenCalendar: response.doNotActivateHiddenCalendar,
      });
    };

    const storeResponseEventsIntoUserDB = async ({
      formattedEvents,
      doNotActivateHiddenCalendar = false,
    }) => {
      const { allCalendars } = this.props.allCalendars;
      const { masterAccount } = this.props.masterAccount;
      const { allLoggedInUsers } = this.props.allLoggedInUsers;

      const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({ masterAccount, allCalendars, allLoggedInUsers });
      const userEmailFromCalendarUserCalendarID = getUserEmailFromUserCalendarID(
        userCalendarID,
        allCalendars,
      );
      const isIncludedInMainCalendars = doesMainCalendarsIncludeUserCalendarID(
        upcomingCalendarIDs,
        userCalendarID,
      );

      if (
        getActiveCalendarsIDsFromAllCalendars({ allCalendars }).includes(
          userCalendarID,
          getUserEmail(this.props.currentUser),
        )
      ) {
        await this.storeEventsIntoUserDB({
          userEmail: userEmailFromCalendarUserCalendarID,
          userEvents: formattedEvents,
          updateMenuBarAndAgenda: isIncludedInMainCalendars,
        });

        const eventHoldIds = formattedEvents?.map(event => getEventHoldID(event))?.filter(eventHoldId => !!eventHoldId);
        if (!isEmptyArrayOrFalsey(eventHoldIds)) {
          holdsBroadcast.publish(HOLDS_BROADCAST_VALUES.REMOVE_MIMIC_EVENTS, eventHoldIds);
        }

        shouldPrint && console.log("webhook_response_added_to_main_calendar", formattedEvents);
        mainCalendarBroadcast.publish(
          MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR,
          {
            listOfEvents: formattedEvents,
            shouldRemoveTemporaryEvents: true,
          },
        );
      } else if (doNotActivateHiddenCalendar) {
        // do nothing -> do not activate hidden calendar
        // add it to indexdb but don't add it to weekly calendar
        this.storeEventsIntoUserDB({
          userEmail: userEmailFromCalendarUserCalendarID,
          userEvents: formattedEvents,
          updateMenuBarAndAgenda: isIncludedInMainCalendars,
        });
      } else {
        // Toggling calendar id (for hidden calendars) gets handled by storeEventsIntoUserDB
        this.storeEventsIntoUserDB({
          userEmail: userEmailFromCalendarUserCalendarID,
          userEvents: formattedEvents,
          updateMenuBarAndAgenda: isIncludedInMainCalendars,
          toggleCalendarID: userCalendarID,
        });
      }
    };

    formatMultipleEventsResponse();
  }

  onExitEventForm(data) {
    this.removeEventFormDataTimer();

    const { removeTemporaryEvent } = data;

    batch(() => {
      this.props.setActionMode({ data: null });

      // Stash below
      if (removeTemporaryEvent) {
        this.props.setTemporaryEvent(null);
      }

      if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
        this.props.setPreviewedEvent(null);
      }

      this.props.setLastEventFormState(null);
      this.props.setDuplicateEvent(false);

      if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
        this.props.removePopupEvent();
      }

      if (!isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
        this.props.removeHoverPopupEvent();
      }

      if (!isEmptyObjectOrFalsey(this.props.templateSideBar)) {
        this.props.removeTemplateSideBar();
      }

      this.props.removeEventFormEmails();
      this.props.removeTemporarySecondaryCalendarColorsIndex();
    });

    // set timeout here so we can finish unmounting component and close the modal before we reset props. Otherwise, we get lag
    mainCalendarBroadcast.publish("REMOVE_GLOBAL_SHORT_CUT_SUGGESTION");
    this.completeEventFormTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.resetReduxOnExitEventForm(data);
    }, 300);
  }

  resetReduxOnExitEventForm(data) {
    const { addEventBackIntoWeeklyCalendar, originalEvent } = data;
    this.removeTimeSlot();

    Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
    if (addEventBackIntoWeeklyCalendar && isEmptyObjectOrFalsey(originalEvent)) {
      Broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    }

    if (
      addEventBackIntoWeeklyCalendar &&
      !isEmptyObjectOrFalsey(originalEvent) &&
      getActiveCalendarsIDsFromAllCalendars({
        allCalendars: this.props.allCalendars.allCalendars,
        currentUserEmail: getUserEmail(this.props.currentUser),
      }).includes(getEventUserCalendarID(originalEvent))
    ) {
      // For cases where the timezone changes after opening edit event
      const timeZone = this.props.currentTimeZone;
      const updatedOriginalEvent = formatEventForReactBigCalendar({
        event: originalEvent,
        currentTimeZone: timeZone,
        calendarId: getEventUserCalendarID(originalEvent),
      });

      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, {
        event: updatedOriginalEvent,
        removeTemporaryEvent: true,
        timeZone,
      });
    }

    this.removeEventFormDataTimer();
  }

  shouldWaitToRefreshFromUpdate() {
    return isInActionMode(this.props.actionMode) || isInFocusMode();
  }

  setTemplateType(type, template = null, acceptedInputs = null, title = null) {
    batch(() => {
      this.props.setTemplateSideBar({
        templateType: type,
        template,
        acceptedInputs,
        title,
      });

      this.props.history.push("/home");

      this.removePreviewEvents();
      this.props.setActionMode({ data: ACTION_MODE.UPSERT_EVENT });
    });
  }

  getCurrentViewFetchDates() {
    const {
      selectedCalendarView,
      selectedDay,
      weekStart,
    } = this.props;
    return getCurrentViewFetchDates({
      selectedCalendarView,
      selectedDate: getSelectedDayWithBackup(selectedDay),
      weekStart,
    });
  }

  // fetch events for one calendar
  fetchCalendarEvents({calendar, inputAllCalendars}) {
    const { fetchStart, fetchEnd } = this.getCurrentViewFetchDates();

    const randomId = createUUID();
    this.fetchId = randomId;

    this.backendEventsFetchID = createUUID();
    this.fetchEventsForUserCalendarIDs({
      userCalendarIDs: [getCalendarUserCalendarID(calendar)],
      dateFrom: fetchStart,
      dateTo: fetchEnd,
      fetchId: randomId,
      backendEventsFetchID: this.backendEventsFetchID,
      inputAllCalendars,
    });
  }

  formatEventResponse(flattenedResponse, timeZone) {
    if (isVersionV2()) {
      return formatEventsArrayForReactBigCalendar({
        events: flattenedResponse,
        currentTimeZone: timeZone,
      });
    } else {
      const { formattedEventsObject } = formatFlattendCalendarAndEvents({
        objectOfCalendarAndEvents: flattenedResponse,
        currentTimeZone: timeZone,
        shouldFilterForActive: true,
      });

      return formattedEventsObject;
    }
  }

  async fetchEventsForUserCalendarIDs({
    userCalendarIDs,
    dateFrom,
    dateTo,
    fetchId,
    backendEventsFetchID,
    inputAllCalendars,
    inputSelectedCalendarView,
  }) {
    const currentSelectedCalendarView = inputSelectedCalendarView || this.props.selectedCalendarView;
    if (isEmptyArrayOrFalsey(userCalendarIDs)) {
      return;
    }
    const successfullyFetchedUserEmails = [];
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const currentAllCalendars = inputAllCalendars || allCalendars;

    if (isEmptyObjectOrFalsey(allLoggedInUsers)) {
      return;
    }

    let allFilteredForWeeklyCalendarEvents = [];

    const getFilteredUserCalendarIDsForUser = (userEmail) => {
      const matchingCalendarsForUser = getMatchingCalendarsForUser({allCalendars, userEmail});
      return userCalendarIDs.filter((userCalendarID) => {
        return !!getCalendarFromUserCalendarID({
          userCalendarID,
          allCalendars: matchingCalendarsForUser,
        });
      });
    };

    /* Self explanatory */
    /* Calls filterForEventsWithinWindow then at the end calls deleteEventsFromDBAndStoreNewlyFetchedEvents  */
    const formatAndStoreFetchedEvents = async ({flattenedResponse, userEmail}) => {
      if (this.fetchId !== fetchId) {
        return;
      }
      const timeZone = this.props.currentTimeZone;
      const isAppVersion2 = isVersionV2();

      // formattedEventsObject is an object of formatted main calendar events with the user calendar id as the key
      const formattedEvents = this.formatEventResponse(flattenedResponse, timeZone);
      let fetchedUserEvents = [];
      // add events to array to add into weekly calendar first while events are being processed in indexdb
      if (isAppVersion2) {
        allFilteredForWeeklyCalendarEvents =
          allFilteredForWeeklyCalendarEvents.concat(
            filterForEventsWithinWindow(formattedEvents),
          );

        fetchedUserEvents = fetchedUserEvents.concat(
          filterForEventsWithinWindow(formattedEvents),
        );
      } else {
        Object.keys(formattedEvents).forEach((k) => {
          if (!isValidCalendar(currentAllCalendars[k])) {
            return;
          }

          const userEvents = formattedEvents[k];
          // add events to array to set into main calendar
          allFilteredForWeeklyCalendarEvents =
            allFilteredForWeeklyCalendarEvents.concat(
              filterForEventsWithinWindow(userEvents),
            );

          fetchedUserEvents = fetchedUserEvents.concat(
            filterForEventsWithinWindow(userEvents),
          );
        });
      }
      successfullyFetchedUserEmails.push(userEmail);

      // Delete all events in indexedDb if valid response before adding events
      return deleteEventsFromDBAndStoreNewlyFetchedEvents({
        userEmail,
        eventsToAdd: fetchedUserEvents,
      });
    };

    /* Self explanatory */
    const filterForEventsWithinWindow = (inputEvents) => {
      if (isEmptyArrayOrFalsey(inputEvents)) {
        return [];
      }

      let filteredEvents = [];
      const {
        dbWindowStartJSDate,
        dbWindowEndJSDate,
        todayLeftJSDate,
        todayRightJSDate,
      } = this.getMovingWindow();
      const activeCalendarUserIDs = getActiveCalendarsIDsFromAllCalendars({
        allCalendars: currentAllCalendars,
        currentUserEmail: getUserEmail(this.props.currentUser),
      });

      inputEvents.forEach((e) => {
        if (
          activeCalendarUserIDs.includes(getEventUserCalendarID(e)) &&
          (isEventWithinJSWindow({event: e, windowStart: dbWindowStartJSDate, windowEnd: dbWindowEndJSDate})
            || isEventWithinJSWindow({event: e, windowStart: todayLeftJSDate, windowEnd: todayRightJSDate})
            || isCancelledEvent(e))
        ) {
          filteredEvents = filteredEvents.concat(e);
        }
      });

      return filteredEvents;
    };

    /* Self explanatory */
    const deleteEventsFromDBAndStoreNewlyFetchedEvents = async ({
      userEmail,
      eventsToAdd,
    }) => {
      // 1) delete events from the current window
      // next -> need to store each usrCalendarID pair into appropriate indexDB based on user email
      const addEventsIntoUserDB = () => {
        // add events into db
        return this.storeEventsIntoUserDB({
          userEmail,
          userEvents: eventsToAdd,
        }); // store events into indexdb, have to wait until we're done deleting
      };

      /* If window gets changed during fetch, we cancel in favor of new fetch */
      const shouldHalt = () => {
        return (
          !this._isMounted ||
          (this.fetchId !== fetchId &&
            this.backendEventsFetchID !== backendEventsFetchID) ||
          this.isSwitchingAccount
        );
      };

      if (shouldHalt()) {
        return;
      }

      try {
        const userCalendarIDsToDelete = getFilteredUserCalendarIDsForUser(userEmail);
        await db.wipeOutEventsWithMatchingUserCalendarIDs({
          email: userEmail,
          timeMin: dateFrom,
          timeMax: dateTo,
          userCalendarIDs: userCalendarIDsToDelete,
        });
        return addEventsIntoUserDB();
      } catch (error) {
        handleError(error);
        await addEventsIntoUserDB();
      }
    };

    /* Self explanatory */
    /* Calls formatAndStoreFetchedEvents */
    const sendRequest = async ({
      user,
      inputTimeMin,
      inputTimeMax,
      isRetry = false,
    }) => {
      if (shouldHideDelegatedUser({ user, allCalendars: currentAllCalendars })) {
        return;
      }
      const userEmail = getUserEmail(user);
      // given userCalendarIDs
      const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
        userCalendarIDs,
        allCalendars: currentAllCalendars,
        userEmail: getUserEmail(user),
      });
      if (isEmptyArrayOrFalsey(providerIDs)) {
        // nothing to filter for
        return;
      }
      const isAppVersion2 = isVersionV2();
      const url = constructRequestURL(FETCH_CALENDAR_EVENTS_ENDPOINT, isAppVersion2);
      const isUserGoogle = isGoogleUser(user);

      const getTimeMinAndMax = () => {
        if (inputTimeMin && inputTimeMax) {
          return {timeMin: inputTimeMin, timeMax: inputTimeMax};
        }
        if (!isUserGoogle) {
          return {timeMin: dateFrom, timeMax: dateTo};
        }
        const {
          timeMin,
          timeMax,
        } = addBufferToWindow({
          timeMin: dateFrom,
          timeMax: dateTo,
        });
        return {
          timeMin,
          timeMax,
        };
      };

      const {
        timeMin,
        timeMax,
      } = getTimeMinAndMax();

      const body = {
        timeMin: timeMin.toISOString(),
        timeMax: timeMax.toISOString(),
        timeZone: this.props.currentTimeZone,
        calendarIds: providerIDs,
      };

      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };

      if (this._isSwitchingAccount) {
        return;
      }

      const startTime = getTrackingTimeNow();
      try {
        const response = await Fetcher.post(url, payloadData, true, userEmail);
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        const endTime = getTrackingTimeNow();
        trackMovingBetweenWindows({
          user,
          delayInSec: getDifferenceInPerformanceTrackingInSecs(startTime, endTime),
          provider: getUserProvider(user), // outlook or google
          calendarView: currentSelectedCalendarView,
        });

        if (isErrorResponse(response)) {
          const {
            currentUser,
          } = this.props;
          if (!isRetry && shouldRetryBasedOnResponse(response, currentUser)) {
            // has checked for 429
            const retryAfterMS = getRetryAfterMS(response);
            await delayByMs(retryAfterMS);
            if (!this._isMounted) {
              return;
            }
            sendRequest({user, inputTimeMin, inputTimeMax, isRetry: true});
            return;
          }
          broadcastOpenPermissionModal({
            error: isNeedToShowUserConsentScreen(response) ? PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN : response.error,
            userEmail: getUserEmail(response.user) || userEmail,
            currentUserEmail: getUserEmail(this.props.currentUser),
            provider: getUserProvider(response.user),
            allLoggedInUsers,
          });
          return; // Make sure to exit the function after rejecting
        }

        return formatAndStoreFetchedEvents({
          flattenedResponse: isAppVersion2 ? response?.events : flattenResponseFromFetchEvents(response),
          userEmail,
        });
      } catch (error) {
        handleError(error);
      }
    };

    let fetchPromises = [];
    const usersForOutlookPreview = [];
    const lastSyncAtUTC = new Date().toISOString();
    const aggregateStartTime = getTrackingTimeNow();
    allLoggedInUsers.forEach((user) => {
      /* Send backend request for user */
      /* Returns a promise */
      // only send users that are in the list of emails to fetch
      const matchingCalendarsForUser = getMatchingCalendarsForUser({allCalendars: currentAllCalendars, user});
      const matchingUserCalendarIDsForUser = userCalendarIDs.filter((userCalendarID) => {
        return !!getCalendarFromUserCalendarID({
          userCalendarID,
          allCalendars: matchingCalendarsForUser,
        });
      });
      const isActiveUser = Object.values(matchingCalendarsForUser).some(calendar => userCalendarIDs.includes(getCalendarUserCalendarID(calendar)));
      if (!isActiveUser) {
        return;
      }
      // note: do not need to chop up monthly for outlook since for most users, this is slower than just done it all at once.
      // if (isOutlookUser(user) && this.isMainCalendarMonthView()) {
      //   // break this into every week of the month
      //   const {
      //     selectedCalendarView,
      //     selectedDay,
      //     weekStart,
      //   } = this.props;
      //   const syncWindows = getOutlookChoppedSyncWindows({
      //     selectedCalendarView,
      //     selectedDay,
      //     weekStart,
      //   });
      //   syncWindows.forEach(syncWindow => {
      //     const fetchPromise = sendRequest({
      //       user,
      //       inputTimeMin: syncWindow.timeMin,
      //       inputTimeMax: syncWindow.timeMax,
      //     });
      //     fetchPromises = fetchPromises.concat(fetchPromise);
      //   });
      const {
        selectedDay,
      } = this.props;
      const requestParams = { user };
      if (isDayView(currentSelectedCalendarView) && isOutlookUser(user) && matchingUserCalendarIDsForUser.length >= OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT) {
        // only if outlook user with a lot of calendar open
        const {
          timeMin,
          timeMax,
        } = getDayViewSyncWindow({ selectedDay });
        requestParams.inputTimeMin = timeMin;
        requestParams.inputTimeMax = timeMax;
      }
      const fetchPromise = sendRequest(requestParams);
      fetchPromises = fetchPromises.concat(fetchPromise);
      if (shouldGetOutlookPreviewEvents(user)) {
        usersForOutlookPreview.push(user);
      }
    });
    const outlookPreviewParam = {
      users: usersForOutlookPreview,
      fetchID: fetchId,
      inputAllCalendars: currentAllCalendars,
      userCalendarIDs,
      dateFrom,
      dateTo,
    };
    if (isDayView(currentSelectedCalendarView)) {
      const {
        timeMin,
        timeMax,
      } = getDayViewSyncWindowForOutlookPreviewEvents({ selectedDay: this.props.selectedDay });
      outlookPreviewParam.dateFrom = timeMin;
      outlookPreviewParam.dateTo = timeMax;
    }
    this.fetchOutlookPreviewEvents(outlookPreviewParam);

    try {
      await Promise.all(fetchPromises);
      if (!this._isMounted || this.fetchId !== fetchId) {
        return;
      }

      // removes all events in main calendar and replace with new events
      const {
        currentUser,
        currentTimeZone,
      } = this.props;
      const timeZoneOption = { timeZone: currentTimeZone };
      const aggregateEndTime = getTrackingTimeNow();
      trackMovingBetweenWindows({
        user: currentUser,
        delayInSec: getDifferenceInPerformanceTrackingInSecs(aggregateStartTime, aggregateEndTime),
        provider: getUserProvider(currentUser), // outlook or google
        calendarView: currentSelectedCalendarView,
        isAggregate: true,
      });
      Broadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.WIPE_OUT_EVENTS_AND_ADD_EVENTS,
        {
          eventList: allFilteredForWeeklyCalendarEvents,
          lastSyncAtUTC,
          deleteEventUserEmails: successfullyFetchedUserEmails,
          windowForRemovingEvents: {
            timeMin: convertToTimeZone(dateFrom, timeZoneOption),
            timeMax: convertToTimeZone(dateTo, timeZoneOption),
          },
          fromWhere: "calendarHomeView::fetchEventsForCalendar",
        },
      );
      if (isSchedulingAssistantShowing()) {
        schedulingAssistantBroadcast.publish(SCHEDULING_ASSISTANT_BROADCAST_VALUES.ADD_EVENTS, allFilteredForWeeklyCalendarEvents);
      }

      // so index db can not reset the events afterwards
      /* Fixes race condition where db is faster than index db */
      /* Also cancels fetches if we change window */
      this.fetchId = null;
    } catch (error) {
      handleError(error);
    }
  }

  removeAllDayEventDate() {
    this.setState({
      allDayDate: null,
      createAllDayEvent: null,
    });
  }

  getMovingWindow(date, inputSelectedCalendarView = null) {
    const {
      selectedDay,
    } = this.props;
    const getDate = () => {
      if (!date) {
        return getSelectedDayWithBackup(selectedDay);
      }
      if (isValidJSDate(date)) {
        return date;
      }
      const parsedDate = parseISO(date);
      if (isValidJSDate(parsedDate)) {
        return parsedDate;
      }
      return getSelectedDayWithBackup(selectedDay);
    };

    const { selectedCalendarView, weekStart } = this.props;
    const currentSelectedCalendarView = inputSelectedCalendarView || selectedCalendarView;
    return createWindow({
      windowJSDate: getDate(),
      isMonth: this.isMainCalendarMonthView(currentSelectedCalendarView),
      weekStart,
      selectedCalendarView: currentSelectedCalendarView,
    });
  }

  async storeEventsIntoUserDB({
    userEmail,
    userEvents,
    toggleCalendarID = null,
  }) {
    const response = await addEventsIntoIndexDB({
      userEmail,
      events: userEvents,
    });

    if (!this._isMounted) {
      return;
    }

    this.updateMenuBarAndAgenda();

    if (toggleCalendarID) {
      Broadcast.publish(
        "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
        toggleCalendarID,
      );
    }

    return response;
  }

  setContactsIntoIndexDB({ user, contacts }) {
    if (isEmptyObjectOrFalsey(user) || isEmptyArrayOrFalsey(contacts) || !getUserEmail(user)) {
      return;
    }

    const sortedContacts = sortContacts(contacts);

    const { domainContacts, normalContacts } = sortedContacts.reduce(
      ({ domainContacts, normalContacts }, contact) =>
        contact.is_same_domain
          ? { domainContacts: [...domainContacts, contact], normalContacts }
          : { domainContacts, normalContacts: [...normalContacts, contact] },
      { domainContacts: [], normalContacts: [] },
    );

    const parsedDomainContacts = this.parseContacts({ contacts: domainContacts, user });
    const parsedContacts = this.parseContacts({ contacts: normalContacts, user });

    const userDomain = getUserDomain({ user }); // returns @something.com;
    const filteredContacts = userDomain ? parsedContacts.filter(c => !getObjectEmail(c)?.includes(userDomain)) : parsedContacts; // filter out contacts with the same domain

    const userEmail = getUserEmail(user);
    addContactsIntoDB({
      userEmail,
      formattedContacts: parsedDomainContacts,
      where: "HomeViewModel::setContactsIntoIndexDB.domains",
      isDomain: true,
    });

    addContactsIntoDB({
      userEmail,
      formattedContacts: filteredContacts,
      where: "HomeViewModel::setContactsIntoIndexDB.contacts",
    });
  }

  setBuildingsIntoIndexDB({ buildings, userEmail }) {
    // buildings: '&domainBuildingId, *buildingId, *buildingName'
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const matchingUser = getMatchingUserFromAllUsers({ allUsers: allLoggedInUsers, userEmail });

    if (isEmptyObjectOrFalsey(matchingUser) || isEmptyArrayOrFalsey(buildings)) {
      return;
    }

    let formattedBuildings;

    switch(getUserProvider(matchingUser)) {
      case PROVIDER_TYPES.GOOGLE:
        formattedBuildings = buildings.filter(b => !isEmptyObjectOrFalsey(b))
        .map((b) => {
          return {
            domainBuildingId: b.domain_building_id || "",
            buildingId: splitUpStringIntoArray(b.building_id),
            buildingName: splitUpStringIntoArray(b.name),
            buildingObject: b,
            fullName: b.building_id || "",
          };
        });
        break;
      case PROVIDER_TYPES.OUTLOOK: {
        formattedBuildings = buildings.filter(b => !isEmptyObjectOrFalsey(b))
        .map((b) => {
          return {
            domainBuildingId: b.domain_provider_id || "",
            buildingId: splitUpStringIntoArray(b.provider_id),
            buildingName: splitUpStringIntoArray(b.display_name),
            buildingObject: b,
            fullName: b.display_name || "",
          };
        });
        break;
      }
      default:
        return;
    }

    addBuildingsIntoDB({
      userEmail,
      formattedBuildings,
    });

    if (isUserMaestroUser(masterAccount)) {
      // only check for Vimcal EA users
      allLoggedInUsers.forEach((user) => {
        if (isSameEmail(getUserEmail(getAuthenticatedUserFromUser(user)), userEmail)) {
          addBuildingsIntoDB({
            userEmail: getUserEmail(user),
            formattedBuildings,
          });
        }
      });
    }
  }

  setConferenceRoomsIntoIndexDB({ conferenceRooms, userEmail }) {
    // conferenceRooms: '&resourceEmail, *resourceName, userVisibilityDescription, roomsObject'
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const matchingUser = getMatchingUserFromAllUsers({ allUsers: allLoggedInUsers, userEmail });

    if (isEmptyObjectOrFalsey(matchingUser) || isEmptyArrayOrFalsey(conferenceRooms)) {
      return;
    }

    const getFormattedConferenceRooms = () => {
      switch(getUserProvider(matchingUser)) {
        case PROVIDER_TYPES.GOOGLE:
          return conferenceRooms
            .filter(c => !isEmptyObjectOrFalsey(c))
            .map((r) => {
              return {
                resourceEmail: r.resource_email || "",
                resourceName: r.generated_resource_name
                  ? splitUpStringIntoArray(r.generated_resource_name)
                  : splitUpStringIntoArray(r.resource_name),
                userVisibilityDescription: splitUpStringIntoArray(
                  r.user_visible_description,
                ),
                buildingId: (r.building && r.building.building_id) || "",
                roomsObject: r,
                fullName: r.generated_resource_name || r.resource_name,
                capacity: r.capacity || 0,
              };
            });
        case PROVIDER_TYPES.OUTLOOK: {
          // TODO: need to make sure this is backwards compatible
          return conferenceRooms
            .filter(c => !isEmptyObjectOrFalsey(c))
            .map((r) => {
              return {
                resourceEmail: getObjectEmail(r) || "",
                resourceName: r.display_name,
                userVisibilityDescription: "",
                buildingId: getRoomBuildingID(r),
                roomsObject: r,
                fullName: r.nickname,
                capacity: r.capacity || 0,
                features: r.features,
              };
            });
        }
        default:
          return;
      }
    };

    const formattedConferenceRooms = getFormattedConferenceRooms();
    if (!formattedConferenceRooms) {
      return;
    }
    addConferenceRoomsIntoDB({
      userEmail,
      formattedConferenceRooms,
    });

    if (isUserMaestroUser(masterAccount)) {
      // only check for Vimcal EA users
      allLoggedInUsers.forEach((user) => {
        if (isSameEmail(getUserEmail(getAuthenticatedUserFromUser(user)), userEmail)) {
          addConferenceRoomsIntoDB({
            userEmail: getUserEmail(user),
            formattedConferenceRooms,
          });
        }
      });
    }
  }

  setSecondaryCalendarContactsIntoIndexDB(secondaryContacts, userEmail = null) {
    // secondaryContacts format: [{email: c.calendar.google_id, name: c.summary, calendarId: c.calendar.user_calendar_id}, ...]

    const formattedContacts = secondaryContacts.map((c) => {
      const {
        email,
        name,
        updated,
      } = c;
      return {
        email: email,
        fullName: [].concat(splitUpStringIntoArray(name)).concat(splitEmailIntoNames(email)),
        name: name,
        updated: updated || "",
      };
    });

    addContactsIntoDB({
      userEmail: userEmail || getUserEmail(this.props.currentUser),
      formattedContacts,
      where: "HomeViewModel::setSecondaryCalendarContactsIntoIndexDB",
    });
  }

  updateMenuBar() {
    if (!isElectron()) {
      return;
    }
    clearTimeout(this._updateMenuBarTimer);
    this._updateMenuBarTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      if (window?.vimcal) {
        if (window.vimcal.reformatMenuBarAgendaFromMain) {
          window.vimcal.reformatMenuBarAgendaFromMain();
        }
      }
    }, 4 * SECOND_IN_MS);
  }

  fetchEventsForCalendarForWindow(params) {
    const getWindowDate = () => {
      if (!isEmptyObjectOrFalsey(params)) {
        const {
          date,
        } = params;
        if (date) {
          return date;
        }
      }
      return this.props.selectedDay;
    };
    const getCalendarView = () => {
      if (!isEmptyObjectOrFalsey(params)) {
        const {
          inputSelectedCalendarView,
        } = params;
        if (inputSelectedCalendarView) {
          return inputSelectedCalendarView;
        }
      }
      return this.props.selectedCalendarView;
    };
    const windowDate = getWindowDate();
    const calendarView = getCalendarView();
    const {
      fetchWindowStartJSDate,
      fetchWindowEndJSDate,
    } = this.getMovingWindow(windowDate, calendarView);
    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const randomBackendEventsFetchID = createUUID();

    // need backendEventsID because if we cancel fetchID, indexDB fetch will be nullified but we want backend response
    // to still be parsed, etc
    this.backendEventsFetchID = randomBackendEventsFetchID;
    const currentUserEmail = getUserEmail(currentUser);
    const allActiveCalendarsUserCalendarID = getActiveCalendarsFromAllCalendars({
      allCalendars,
      currentUserEmail,
    }).map(calendar => getCalendarUserCalendarID(calendar));

    this.fetchEventsForUserCalendarIDs({
      userCalendarIDs: allActiveCalendarsUserCalendarID,
      dateFrom: fetchWindowStartJSDate,
      dateTo: fetchWindowEndJSDate,
      fetchId: this.fetchId,
      backendEventsFetchID: randomBackendEventsFetchID,
      inputSelectedCalendarView: params?.inputSelectedCalendarView,
    });
  }

  pullDataFromDate({
    date,
    inputSelectedCalendarView = null,
  }) {
    const windowDate = date || this.props.selectedDay;
    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
      todayLeftJSDate,
      todayRightJSDate,
    } = this.getMovingWindow(windowDate, inputSelectedCalendarView);
    /* Both keep track of the current fetch */
    const randomId = createUUID();

    this.fetchId = randomId;
    /* Fixes issue where we don't store into indexed db when setting this.fetchId to null for race condition */

    // Need to check if selected day is within range
    // if it is -> pull the range
    // pull what is out of the range with fetch

    Broadcast.publish("EMPTY_EVENTS_AND_EVENTS_INDEX");

    // whatever backend sends should always be layered ontop of index db
    /* Fetches from backend and stores into index db */
    this.fetchEventsForCalendarForWindow({
      date,
      inputSelectedCalendarView,
    });

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const currentUserEmail = getUserEmail(this.props.currentUser);

    let fetchedResults = [];
    let dbPromises = [];
    /* index db fetch */
    allLoggedInUsers.forEach((user) => {
      const userEmail = getUserEmail(user);
      const currentActiveCalendars = getActiveCalendarsIDsFromAllCalendars({
        allCalendars: getUserCalendar(allCalendars, userEmail),
        currentUserEmail,
      });

      const dbPromise = db
        .fetch(userEmail)
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(currentActiveCalendars)
        .and((item) => {
          return (
            isDBEventItemWithinWindow({
              item,
              windowStart: dbWindowStartJSDate,
              windowEnd: dbWindowEndJSDate,
            })
          ) ||
            (
              isDBEventItemWithinWindow({
                item,
                windowStart: todayLeftJSDate,
                windowEnd: todayRightJSDate,
              })
            );
        })
        .toArray()
        .then((response) => {
          if (
            !this._isMounted ||
            isEmptyObjectOrFalsey(response) ||
            !this.fetchId ||
            this.fetchId !== randomId
          ) {
            return;
          }

          fetchedResults = fetchedResults.concat(response);
        })
        .catch((err) => {
          handleError(err);
        });

      dbPromises = dbPromises.concat(dbPromise);
    });

    Promise.all(dbPromises)
      .then(() => {
        if (!this._isMounted || this.fetchId !== randomId) {
          return;
        }

        const activeCalendars = getActiveCalendarsIDsFromAllCalendars({
          allCalendars,
          currentUserEmail: getUserEmail(this.props.currentUser),
        });

        const listOfEvents = fetchedResults
          .map((r) => {
            return r.event;
          })
          .filter((e) => activeCalendars.includes(getEventUserCalendarID(e)));
        const {
          currentTimeZone,
        } = this.props;
        const formattedEvents = listOfEvents.map(event => {
          return formatEventForReactBigCalendar({
            event,
            currentTimeZone,
          });
        });

        if (this.fetchId !== randomId || isEmptyArrayOrFalsey(listOfEvents)) {
          return;
        }

        mainCalendarBroadcast.publish(
          MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR,
          {listOfEvents: formattedEvents},
        );
      })
      .catch((err) => {
        handleError(err);
      });
  }

  updateMenuBarAndAgenda() {
    Broadcast.publish("REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT");
    this.updateMenuBar();
  }

  createCalendarObject(calendarObject, events) {
    let updatedCalendarObject = _.clone(calendarObject);

    updatedCalendarObject.events = events;

    if (updatedCalendarObject) {
      updatedCalendarObject.last_synced_at = calendarObject.last_synced_at;
    }

    return updatedCalendarObject;
  }

  shouldShowRightPanel() {
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    return (
      !isEmptyObjectOrFalsey(this.props.currentHoverEvent) ||
      !isEmptyObjectOrFalsey(this.props.currentPreviewedEvent) ||
      isInActionMode(this.props.actionMode) ||
      this.props.isCreateFocusModeBlocks ||
      reverseSlotsText
    );
  }

  updateDesktopIconDayOfMonth() {
    if (!isElectron() || !isMac()) {
      return;
    }
    // note: electron does not update the icon for the day of the month on refresh, only on quit and restarting the app
    if (window?.vimcal?.setAppIconForDayOfMonth) {
      const dayOfMonth = new Date().getDate();
      window.vimcal.setAppIconForDayOfMonth(dayOfMonth);
    } else if (window?.vimcal?.updateAppIconForDayOfMonth) {
      window.vimcal.updateAppIconForDayOfMonth();
    }
  }

  isMainCalendarMonthView(inputSelectedCalendarView = null) {
    if (inputSelectedCalendarView) {
      return inputSelectedCalendarView === BACKEND_MONTH;
    }

    return this.props.selectedCalendarView === BACKEND_MONTH;
  }

  isSelectedDayInCurrentView() {
    if (this.isMainCalendarMonthView()) {
      return isSameMonth(new Date(), this.props.selectedDay);
    }

    return isSameWeek(new Date(), this.props.selectedDay);
  }

  filterOutOtherPeopleCalendarEventsAndReport(events, location) {
    const { allCalendars } = this.props.allCalendars;

    return filterOtherPeoplesCalendarsAndReport({
      events,
      allCalendarIds: getAllCalendarUserCalendarIDs(allCalendars),
      location,
      currentUserEmail: getUserEmail(this.props.currentUser),
    });
  }

  updateFocusModeContent() {
    const formattedCurrentTimeString = format(new Date(), DATE_TIME_12_HOUR_FORMAT);
    focusModeBroadcast.publish("UPDATE_UPCOMING_EVENT");
    focusModeBroadcast.publish("SET_BIG_CLOCK_TIME", formattedCurrentTimeString);
    focusModeBroadcast.publish("SET_SMALL_CLOCK_TIME", formattedCurrentTimeString);
  }

  fetchForTodayAndTomorrowsEvents() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (this.isInternalUser()) {
      // always call for internal users
    } else if (!isOpenToConstantSync(masterAccount)) {
      return;
    }

    const {
      currentTimeZone,
    } = this.props;
    const currentTime = getCurrentTimeInCurrentTimeZone(currentTimeZone);
    fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_EVENTS_FOR_CURRENT_WINDOW, [
      {
        timeMin: startOfDay(currentTime),
        timeMax: endOfDay(addDays(currentTime, 1)),
      },
    ]);
  }

  startDetectWakeUpInterval() {
    detectWakeUpWorker.onmessage = (event) => {
      if (!this._isMounted || isEmptyObjectOrFalsey(event?.data)) {
        return;
      }
      // messages will either be UI based on sync based
      // UI portion below
      if (event.data[UI_DETECT_WAKE_UP.IS_DIFFERENT_MINUTE]) {
        resetGuessTimeZone();
        Broadcast.publish(
          "GET_AGENDA_UPCOMING_EVENTS_AND_CHECK_FOR_NOTIFICATIONS",
        );
        if (isElectron() && window?.vimcal?.runMenuBarMinuteInterval) {
          window.vimcal.runMenuBarMinuteInterval();
        }
        mainCalendarBroadcast.publish("UPDATE_MAIN_CALENDAR_CURRENT_TIME");
        if (isInFocusMode()) {
          this.updateFocusModeContent();
        }
        agendaBroadcast.publish("UPDATE_HOVER_UPCOMING_EVENT_COUNT");
      }

      if (event.data[UI_DETECT_WAKE_UP.WAKE_UP]) {
        guessTimeZoneFromLocation();
        if (isInFocusMode()) {
          this.updateFocusModeContent();
          focusModeBroadcast.publish("BIND_ESCAPE_HOTKEY");
          focusModeBroadcast.publish("BIND_SPACE_TO_START");
          focusModeBroadcast.publish("BIND_HOTKEY_TO_PLAY_SOUND");
        }
        this.updateDesktopIconDayOfMonth();
        Broadcast.publish("CHECK_UPDATE_TODAY_DATE");
        agendaBroadcast.publish("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT");
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR);
        Broadcast.publish("CHECK_FOR_NOTIFICATION");
        Broadcast.publish("UPDATE_MENU_BAR");
      }

      if (event.data[UI_DETECT_WAKE_UP.IS_DIFFERENT_DAY]) {
        Broadcast.publish("CHECK_UPDATE_TODAY_DATE");
        this.updateDesktopIconDayOfMonth();
        Broadcast.publish("UPDATE_FAVICON");
        Broadcast.publish("PRESS_TODAY");
        // clear the cache every day
        clearCommonUsefulFunctionCache();
        clearEventStaticCache();
        focusModeBroadcast.publish("UPDATE_FOCUS_MODE_BACKGROUND_IMAGE");
      }

      if (event.data[UI_DETECT_WAKE_UP.IS_DIFFERENT_WEEK]) {
        agendaBroadcast.publish("CLEAR_OUT_HIDDEN_EVENTS");
        appBroadcast.publish(APP_BROADCAST_VALUES.CLEAR_ENRICH_CONTACT_SEARCHED_CACHE);
      }

      // ----- sync portion below (dependent on internet) -----
      let hasCheckedSubscription = false; // so we don't call it again on wakeup
      let hasCheckedMetrics = false;
      let hasReloadedGoogleMaps = false;
      if (event.data[SYNC_DETECT_WAKE_UP.IS_DIFFERENT_MINUTE]) {
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.RELOAD_GOOGLE_PLACES_SCRIPT); // no harm in checking since we early exit if it already exists
        hasReloadedGoogleMaps = true;
        this.reloadActionCable();
        clearEventStyleCache();
      }

      if (event.data[SYNC_DETECT_WAKE_UP.WAKE_UP]) {
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_OUTLOOK_CATEGORIES);
        if (!hasReloadedGoogleMaps) {
          backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.RELOAD_GOOGLE_PLACES_SCRIPT); // no harm in checking since we early exit if it already exists
        }

        hasCheckedSubscription = true;
        BackendBroadcast.publish("GET_SUBSCRIPTION_DETAILS", true);

        hasCheckedMetrics = true;
        backendBroadcasts.publish("CHECK_FOR_METRICS");

        Broadcast.publish("CHECK_FORCE_APP_REFRESH"); // check to see if it's time to for a refresh
        this.fetchZoomSchedulers();
        this.syncOnWakeUp(0);
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.CLEAR_COMPANY_DOMAINS_PENDING_BACKEND);
      }

      if (event.data[SYNC_DETECT_WAKE_UP.INITIAL_SYNC]) {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.RESET_MAIN_CALENDAR_CACHE);
        clearTagsCache();
        this.initialFullSync(false);

        if (!hasCheckedSubscription) {
          BackendBroadcast.publish("GET_SUBSCRIPTION_DETAILS", true);
        }

        availabilityBroadcast.publish("FETCH_PERSONAL_LINKS_FOR_CURRENT_USER");
        this.fetchZoomSchedulers();
      }

      if (event.data[SYNC_DETECT_WAKE_UP.IS_DIFFERENT_DAY]) {
        if (!hasCheckedMetrics) {
          backendBroadcasts.publish("CHECK_FOR_METRICS");
        }
        BackendBroadcast.publish("CHECK_FOR_PROMOTIONS_NOT_SEEN");
      }

      if (event.data[SYNC_DETECT_WAKE_UP.IS_DIFFERENT_WEEK]) {
        // nothing right now
      }

      if (event.data[SYNC_DETECT_WAKE_UP.SYNC_CURRENT_VIEW]) {
        fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_EVENTS_FOR_CURRENT_WINDOW);
      }

      if (event.data[SYNC_DETECT_WAKE_UP.SYNC_TODAY]) {
        this.fetchForTodayAndTomorrowsEvents();
      }
    };
  }

  refreshMenuBarApp() {
    if (!isElectron()) {
      return;
    }
    if (window?.vimcal) {
      window.vimcal.onLoginSuccess();
    }
  }

  async fetchZoomSchedulers() {
    const {
      currentUser,
    } = this.props;
    const { masterAccount } = this.props.masterAccount;
    if (!isUserMaestroUser(masterAccount)) {
      return;
    }

    try {
      const url = constructRequestURLV2("all_schedulers");
      const response = await Fetcher.get(url, {}, true, getUserEmail(currentUser));
      if (!this._isMounted || isEmptyObjectOrFalsey(response) || isEmptyArrayOrFalsey(response)) {
        return;
      }
      const {
        schedulers: schedulersToSet,
      } = response;
      const { schedulers, setSchedulers } = this.props.zoomSchedulers;
      const updatedSchedulers = produce(schedulers, (draftState) => {
        schedulersToSet.forEach((scheduler) => {
          if (getUserIDFromScheduler(scheduler)) {
            // Don't touch schedulers array if we have an error
            // Need to keep it to get the proper user
            if (scheduler.error) {
              scheduler.schedulers = draftState[getUserIDFromScheduler(scheduler)]?.schedulers ?? [];
            }
            draftState[getUserIDFromScheduler(scheduler)] = scheduler;
          }
        });
      });
        //   updatedSchedulers:
        //   {
        //     3: {
        //         "email": "mike@vimcal.com",
        //         "host_email": "mike@vimcal.com",
        //         "schedulers": [
        //             {
        //                 "id": "_f8jxVIHSO-t4cuU17WqQA",
        //                 "email": "seamus@vimcal.com",
        //                 "pmi": 4335695133
        //             }
        //         ]
        //     }
        // }

      setSchedulers(updatedSchedulers);
    } catch (error) {
      handleError(error);
    }
  }

  getDefaultUserTimeZone() {
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;

    return getDefaultUserTimeZone({ masterAccount, user: currentUser });
  }

  isUserIn24HourFormat(inputMasterAccount) {
    const { currentUser } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getIsAccountIn24HourFormat({
      masterAccount: inputMasterAccount ?? masterAccount,
      user: currentUser,
    });
  }

  shouldHideRightHandSide() {
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    const {
      hideRightHandSidebar,
      actionMode,
    } = this.props;
    return shouldHideRightHandSide({
      hideRightHandSideBar: hideRightHandSidebar,
      actionMode,
      reverseSlotsText,
    });
  }

  checkMetrics() {
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      backendBroadcasts.publish("CHECK_FOR_METRICS");
    }, 2 * SECOND_IN_MS);
  }

  stopReferBoost() {
    const {
      stopReferBoost,
      isInReferBoost,
    } = this.props.referralStore;
    if (isInReferBoost) {
      stopReferBoost();
    }
  }

  showReferBoostModal() {
    const {
      hasShownReferBoost,
      isInReferBoost,
    } = this.props.referralStore;
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (isUserInOnboarding(masterAccount)) {
      return;
    }
    if (isUserMaestroUser(masterAccount)) {
      // do not show to maestro users
      return;
    }
    if (hasShownReferBoost || isInReferBoost) {
      return;
    }
  }

  checkFeatureFlags(response) {
    if (isShowBoostReferral(response)) {
      this.showReferBoostModal();
    } else {
      this.stopReferBoost();
    }

    const { masterAccount } = this.props.masterAccount;
    if (
      shouldShowCalendarAudit(masterAccount) &&
      shouldDisplayCalendarAuditReady(masterAccount)
    ) {
      modalBroadcast.publish(
        MODAL_BROADCAST_VALUES.SET_BOTTOM_MODAL_CONTENT,
        MODAL_TYPES.CALENDAR_AUDIT,
      );
    }

    const {
      shouldShowFindTime,
      setShouldShowFindTime,
    } = this.props.featureFlags;

    if (!shouldShowFindTime
      && isOpenFindTimeFeatureFlag(response)
    ) {
      setShouldShowFindTime();
    }
  }

  handleOSThemeChangeChange(e) {
    if (isEmptyObjectOrFalsey(e)) {
      return;
    }
    this.setState({
      isMacOSElectronOSDarkTheme: e.matches ? true : false,
    });
  }

  addOSThemeListener() {
    if (!isMacElectron()) {
      return;
    }
    if (window?.matchMedia) {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (mediaQuery?.addListener) {
        mediaQuery?.addListener(this.handleOSThemeChangeChange);
      }
    }
  }

  removeOSThemeListener() {
    if (!isMacElectron()) {
      return;
    }
    if (window?.matchMedia) {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (mediaQuery?.removeListener) {
        mediaQuery?.removeListener(this.handleOSThemeChangeChange);
      }
    }
  }

  async fetchOutlookPreviewEvents({
    users,
    inputAllCalendars,
    dateFrom,
    dateTo,
    userCalendarIDs,
  }) {
    if (isEmptyArrayOrFalsey(users)) {
      return;
    }
    fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_PREVIEW_OUTLOOK_EVENTS, {
      users,
      userCalendarIDs,
      inputAllCalendars,
      inputTimeMin: dateFrom,
      inputTimeMax: dateTo,
    });
  }

  handleUpdateUserForWebhooks() {
    if (!this._consumerWebHook) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const currentAllLoggedInUserEmails = allLoggedInUsers.map(getUserEmail);

    // delete users who are no longer logged in
    Object.keys(this._consumerWebHook).forEach((userEmail) => {
      if (!currentAllLoggedInUserEmails.includes(userEmail)) {
        disconnectWebhooksForAllCalendarsForUser(this._consumerWebHook[userEmail]);
        delete this._consumerWebHook[userEmail];
      }
    });

    // add new users
    allLoggedInUsers.forEach((user) => {
      const userEmail = getUserEmail(user);
      if (!this._consumerWebHook[userEmail]) {
        this._consumerWebHook[userEmail] = createWebhooksConsumerForUser(userEmail);
      }
    });
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
    setActionMode: ({ data, shouldKeepLocalEventState = false }) => dispatch({ data, type: "SET_ACTION_MODE", shouldKeepLocalEventState }),
    storeTemplates: (data) => dispatch({ data: data, type: "STORE_TEMPLATE" }),
    setShouldDisplayMenu: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_DISPLAY_MENU" }),
    removePreviewedEvent: () =>
      dispatch({ type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    removeCurrentHoverEvent: () =>
      dispatch({ type: "REMOVE_CURRENT_HOVER_EVENT" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    logout: () => dispatch({ type: "USER_LOGOUT" }),
    setDefaultBrowserTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "STORE_DEFAULT_BROWSER_TIME_ZONE" }),
    storeUserData: (user) => dispatch({ data: user, type: "STORE_USER_DATA" }),
    setTemplateSideBar: (data) =>
      dispatch({ data: data, type: "SET_TEMPLATE_SIDE_BAR" }),
    setTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "SET_TIME_ZONE" }),
    removeTemplateSideBar: () => dispatch({ type: "REMOVE_TEMPLATE_SIDE_BAR" }),
    createTemplate: (event) =>
      dispatch({ data: event, type: "CREATE_TEMPLATE" }),
    removeTemplate: (data) => dispatch({ data: data, type: "REMOVE_TEMPLATE" }),
    hideShortcutsLegend: () => dispatch({ type: "HIDE_SHORTCUTS_LEGEND" }),
    setTemporaryEvent: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
    setAvailabilitySelectedMinutes: (data) =>
      dispatch({ data: data, type: "SET_AVAILABILITY_SELECTED_MINUTES" }),
    setLastEventFormState: (data) =>
      dispatch({ data: data, type: "SET_LAST_EVENT_FORM_STATE" }),
    removeHoverPopupEvent: () =>
      dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
    setPersonalLinks: (event) =>
      dispatch({ data: event, type: "SET_PERSONAL_LINKS" }),
    setAnchorTimeZones: (data) =>
      dispatch({ data: data, type: "SET_ANCHOR_TIME_ZONES" }),
    setDuplicateEvent: (data) => dispatch({ data, type: "DUPLICATE_EVENT" }),
    removeEventFormEmails: (event) =>
      dispatch({ data: event, type: "REMOVE_EVENT_FORM_EMAILS" }),
    removeTemporarySecondaryCalendarColorsIndex: () =>
      dispatch({ type: "REMOVE_SECONDARY_CALENDAR_COLORS_INDEX" }),
    setMimicEventsList: (data) =>
      dispatch({ data: data, type: "SET_MIMIC_EVENTS_LIST" }),
  };
}

function mapStateToProps(state) {
  let {
    shouldShowShortcutsLegend,
    templateSideBar,
    agendaDay,
    shouldShowTopBar,
    popupEvent,
    currentUser,
    currentPreviewedEvent,
    selectedDay,
    shouldShowGlobalKeyMap,
    currentTimeZone,
    shouldDisplayMenu,
    currentHoverEvent,
    temporaryEvents,
    isDarkMode,
    defaultBrowserTimeZone,
    weekStart,
    availabilitySelectedMinutes,
    isDuplicateEvent,
    hoverPopupEvent,
    paintSettings,
    format24HourTime,
    dateFieldOrder,
    selectedCalendarView,
    originalRecurrenceEventIndex,
    isMobileView,
    isCreateFocusModeBlocks,
    actionMode,
    mimicEventsList,
  } = state;

  return {
    shouldShowShortcutsLegend,
    templateSideBar,
    agendaDay,
    shouldShowTopBar,
    popupEvent,
    currentUser,
    currentPreviewedEvent,
    selectedDay,
    shouldShowGlobalKeyMap,
    currentTimeZone,
    shouldDisplayMenu,
    currentHoverEvent,
    temporaryEvents,
    isDarkMode,
    defaultBrowserTimeZone,
    weekStart,
    availabilitySelectedMinutes,
    isDuplicateEvent,
    hoverPopupEvent,
    paintSettings,
    format24HourTime,
    dateFieldOrder,
    selectedCalendarView,
    originalRecurrenceEventIndex,
    isMobileView,
    isCreateFocusModeBlocks,
    actionMode,
    mimicEventsList,
  };
}

const withStore = (BaseComponent) => (props) => {
  const tabIDStore = useTabID();
  const teamPlan = useTeamPlan();

  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allUserDomains = useAllUserDomains();
  const masterAccount = useMasterAccount();
  const zoomSchedulers = useZoomSchedulers();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const temporaryStateStore = useTemporaryStateStore();
  const accountActivity = useAccountActivity();
  const metricsStore = useMetricsStore();
  const referralStore = useReferralStore();
  const featureFlags = useFeatureFlags();

  return (
    <BaseComponent
      {...props}
      tabIDStore={tabIDStore}
      teamPlan={teamPlan}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      allUserDomains={allUserDomains}
      masterAccount={masterAccount}
      zoomSchedulers={zoomSchedulers}
      hideRightHandSidebar={hideRightHandSidebar}
      temporaryStateStore={temporaryStateStore}
      accountActivity={accountActivity}
      metricsStore={metricsStore}
      referralStore={referralStore}
      featureFlags={featureFlags}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStore(CalendarHomeView)));
