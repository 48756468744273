import React, { Component } from "react";
import {
  handleError,
  guessTimeZone,
  isElectron,
} from "../services/commonUsefulFunctions";
import { constructRequestURL, isErrorResponse } from "../services/api";
import Broadcast from "../broadcasts/broadcast";
import Fetcher from "../services/fetcher";
import MenuBarAgendaPanel from "../components/menuBarAgendaPanel";
import { subDays, addDays, endOfDay, startOfDay } from "date-fns";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getMatchingUserProviderIDsFromUserCalendarIDs,
  isValidCalendar,
} from "../lib/calendarFunctions";
import { flattenEventsToUserCalendar, formatFlattendCalendarAndEvents } from "../lib/webWorkerFunctions";
import { getUpcomingCalendarUserCalendarIDs, getUserEmail } from "../lib/userFunctions";
import { addEventsIntoIndexDB } from "../lib/dbFunctions";
import { FETCH_CALENDAR_EVENTS_ENDPOINT } from "../lib/endpoints";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getRetryAfterMS, shouldRetryBasedOnResponse } from "../lib/backendFunctions";
import { delayByMs } from "../lib/asyncFunctions";
import { MENU_BAR_BROADCAST_VALUES } from "../lib/broadcastValues";

class MenuBarCalendarHomeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isElectron: isElectron(),
    };

    this.fetchEvents = this.fetchEvents.bind(this);

    Broadcast.subscribe(MENU_BAR_BROADCAST_VALUES.MENU_BAR_SYNC_OTHER_CALENDARS, this.fetchEvents);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    Broadcast.unsubscribe(MENU_BAR_BROADCAST_VALUES.MENU_BAR_SYNC_OTHER_CALENDARS);
  }

  render() {
    return <MenuBarAgendaPanel />;
  }

  fetchEvents() {
    return; // test removing this and seeing if this is what's causing problem
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;

    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({masterAccount, allCalendars});
    if (
      isEmptyArrayOrFalsey(upcomingCalendarIDs) ||
      isEmptyArrayOrFalsey(allLoggedInUsers)
    ) {
      return;
    }

    let fetchPromises = [];

    const sendRequest = (userEmail, isRetry = false) => {
      const url = constructRequestURL(FETCH_CALENDAR_EVENTS_ENDPOINT, true);
      const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
        userCalendarIDs: upcomingCalendarIDs,
        allCalendars,
        userEmail,
      });
      if (isEmptyArrayOrFalsey(providerIDs)) {
        return;
      }
      const body = {
        timeMin: startOfDay(subDays(new Date(), 1)).toISOString(),
        timeMax: endOfDay(addDays(new Date(), 1)).toISOString(),
        timeZone: guessTimeZone(),
        calendarIds: providerIDs,
      };

      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };

      const handleResponse = async ({response, resolve, reject}) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          reject();
          return;
        }
        if (isErrorResponse(response)) {
          if (!isRetry && shouldRetryBasedOnResponse(response)) {
            const retryAfterMS = getRetryAfterMS(response);
            await delayByMs(retryAfterMS);
            if (!this._isMounted) {
              reject();
              return;
            }
            return await sendRequest(userEmail, true);
          }
          reject();
          return;
        }
        if (isEmptyArrayOrFalsey(response?.events)) {
          reject();
          return;
        }
        formatAndStoreFetchedEvents(
          flattenEventsToUserCalendar(response.events),
          userEmail,
          resolve,
        );
      };

      return new Promise((resolve, reject) => {
        Fetcher.post(url, payloadData, true, userEmail)
          .then((response) => handleResponse({response, resolve, reject}))
          .catch((error) => {
            reject();
            handleError(error);
          });
      }).catch(handleError);
    };

    const formatAndStoreFetchedEvents = (
      flattenedResponse,
      userEmail,
      resolvePromise = null,
    ) => {
      const guessedTimeZone = guessTimeZone();

      // formattedEventsObject is an object of formatted main calendar events with the user calendar id as the key
      const { formattedEventsObject } = formatFlattendCalendarAndEvents({
        objectOfCalendarAndEvents: flattenedResponse,
        currentTimeZone: guessedTimeZone,
        shouldFilterForActive: true,
      });

      let fetchedUserEvents = [];

      // add events to array to add into weekly calendar first while events are being processed in indexdb
      Object.keys(formattedEventsObject).forEach((k) => {
        if (!isValidCalendar(allCalendars[k])) {
          return;
        }

        const userEvents = formattedEventsObject[k];
        // add events to array to set into main calendar

        fetchedUserEvents = fetchedUserEvents.concat(userEvents);
      });

      // TODO: we call this sync on wakeup but it does not handle deletion of events. It only handles upsert
      this.storeEventsIntoDB(
        userEmail,
        fetchedUserEvents,
        resolvePromise
      );
    };

    allLoggedInUsers.forEach((user) => {
      fetchPromises = fetchPromises.concat(sendRequest(getUserEmail(user)));
    });

    Promise.all(fetchPromises).then(() => {
      if (!this._isMounted) {
        return;
      }
      // only reset it after we're done fetching for all logged in users to avoid flash
      Broadcast.publish(MENU_BAR_BROADCAST_VALUES.MENU_BAR_REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT);
    }).catch(handleError);
  }

  async storeEventsIntoDB(userEmail, userEvents, resolvePromise) {
    await addEventsIntoIndexDB({
      userEmail,
      events: userEvents,
    });
    if (!this._isMounted) {
      return;
    }

    if (resolvePromise) {
      resolvePromise();
    }
  }
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
    />
  );
};

export default withStore(MenuBarCalendarHomeView);
