import classNames from "classnames";
import React, { useRef } from "react";
import EventFormDate from "../eventFormDate";
import _ from "underscore";
import { Calendar, Clock, Globe, Minus } from "react-feather";
import SelectEventTime from "../selectEventTime";
import {
  addAbbrevationToTimeZone,
  getTimeInAnchorTimeZone,
  guessTimeZone,
} from "../../services/commonUsefulFunctions";
import DefaultSwitch from "../defaultSwitch";
import { isBefore } from "date-fns";
import { WorkHours } from "./helperFunctions";
import type ReactDatePicker from "react-datepicker";

const SCHEDULING_ASSISTANT_EVENT_START_DATE =
  "scheduling-assistant-event-start-date";
const SCHEDULING_ASSISTANT_EVENT_START_TIME =
  "scheduling-assistant-event-start-time";
const SCHEDULING_ASSISTANT_EVENT_END_TIME =
  "scheduling-assistant-event-end-time";

interface DateTimeInputProps {
  eventStart: Date
  eventEnd: Date
  setEventStartDate: (date: Date) => void
  setEventStartTime: (date: Date) => void
  setEventEndTime: (date: Date) => void
  startTimeZone: string
  endTimeZone: string
  isShowingWorkHoursOnly: boolean
  setIsShowingWorkHoursOnly: StateSetter<boolean>
  workHours: WorkHours
  isShowingWarningUnderneath?: boolean
}

export default function DateTimeInput({
  eventStart,
  eventEnd,
  setEventStartDate,
  setEventStartTime,
  setEventEndTime,
  startTimeZone,
  endTimeZone,
  isShowingWorkHoursOnly,
  setIsShowingWorkHoursOnly,
  workHours,
  isShowingWarningUnderneath = false,
}: DateTimeInputProps) {
  const startDateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<ReactDatePicker>(null);
  const endTimeRef = useRef<ReactDatePicker>(null);

  const workingHoursAreAllDay = (
    workHours.startWorkHour === 0 &&
    workHours.endWorkHour === 24
  );

  const convertedEndTime = getTimeInAnchorTimeZone(eventEnd, endTimeZone, startTimeZone);

  const updateEndTime = (newTime: Date) => {
    const convertedNewTime = getTimeInAnchorTimeZone(newTime, startTimeZone, endTimeZone);
    setEventEndTime(convertedNewTime);
  };

  return (
    <div
      className={classNames(
        isShowingWarningUnderneath ? "" : "mb-4",
        "flex",
        "items-center",
        "gap-4",
        "scheduling-assistant-wrapper",
      )}
    >
      <div className="flex items-center gap-2">
        <Calendar size={12} className="secondary-text-color" />
        <EventFormDate
          innerRef={startDateRef}
          additionalClassname={""}
          tabIndex={1}
          autoFocus={false}
          isStartDate={true}
          date={eventStart}
          id={SCHEDULING_ASSISTANT_EVENT_START_DATE}
          placeholder={"Start date"}
          sendData={setEventStartDate}
          onComplete={_.noop}
          modalTitle={"Select Start Date"}
          onEscape={_.noop}
          onBlur={_.noop}
        />
      </div>

      <div className="flex items-center gap-2">
        <Clock size={12} className="secondary-text-color" />
        <SelectEventTime
          additionalClassname={""}
          inputTabIndex={2}
          id={SCHEDULING_ASSISTANT_EVENT_START_TIME}
          eventTime={eventStart}
          timeZone={startTimeZone}
          setEventTime={setEventStartTime}
          isStartTime={true}
          innerRef={startTimeRef}
        />
        <Minus className="secondary-text-color" size={12} />
        <SelectEventTime
          additionalClassname={isBefore(convertedEndTime, eventStart) ? "problem-background-color" : ""}
          inputTabIndex={3}
          id={SCHEDULING_ASSISTANT_EVENT_END_TIME}
          eventTime={convertedEndTime}
          timeZone={startTimeZone}
          setEventTime={updateEndTime}
          isStartTime={false}
          innerRef={endTimeRef}
        />
      </div>
      <div className="flex items-center gap-2">
        <Globe size={12} className="secondary-text-color" />
        <div className="font-size-12">
          {getTimeZoneText({ endTimeZone, eventEnd, eventStart, startTimeZone })}
        </div>
      </div>

      {/* At the moment, we do not support work hours that begin one day and end the next. */}
      {workingHoursAreAllDay ? null : (
        <div className="flex flex-grow justify-end items-center gap-2">
          <span>Working hours only?</span>
          <DefaultSwitch
            tabIndex={4}
            isChecked={isShowingWorkHoursOnly}
            onChange={setIsShowingWorkHoursOnly}
          />
        </div>
      )}
    </div>
  );
}

function getTimeZoneText({ endTimeZone, eventEnd, eventStart, startTimeZone }: {
  endTimeZone: string
  eventEnd: Date
  eventStart: Date
  startTimeZone: string
}) {
  const guessedTimeZone = guessTimeZone();
  const startTimeZoneWithFallback = startTimeZone || guessedTimeZone;
  const endTimeZoneWithFallback = endTimeZone || guessedTimeZone;

  let text = addAbbrevationToTimeZone({
    timeZone: startTimeZoneWithFallback,
    inputDate: eventStart,
  });
  if (startTimeZoneWithFallback !== endTimeZoneWithFallback) {
    text += " - ";
    text += addAbbrevationToTimeZone({
      timeZone: endTimeZoneWithFallback,
      inputDate: eventEnd,
    });
  }

  return text;
}
