import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { LOCAL_TIME_ZONE } from "../lib/vimcalVariables";
import {
  addAbbrevationToTimeZone,
  CreateTimeZoneList,
  KEYCODE_ESCAPE,
  createAbbreviationForTimeZone,
  guessTimeZone,
  handleError,
  sendMessageToSentry,
} from "../services/commonUsefulFunctions";
import { 
  TIME_ZONE_SEARCH_QUERY_INDEX, 
  countryTimeZones, 
  getAllTimeZoneForGMTOffset, 
  getMatchingSingleTimeZoneCountry, 
  getMatchingSingleTimeZoneCountryResult, 
  getOffSetFromGMTSearchString, 
  stringContainsGMTAndNumber,
  getMatchingTimeZoneForMultipleCountryTZs,
  getMatchingMultipleTimeZoneKey,
  timeZonesForCountriesWithMultipleTimeZone,
  getMatchingPopularTimeZones
} from "../services/timeZone";
import CustomSelect from "./select";
import { getReactSelectBaseStyle } from "./select/styles";
import { GOOGLE_GEOCODING_RESPONSE_STATUS, GOOGLE_TIME_ZONE_RESPONSE_STATUS, getGeoCodeFromAddress, getGoogleMapsAutoComplete, getTimeZoneFromLocation } from "../lib/googleFunctions";

class SelectTimeZoneInput extends Component {
  // Declare a new state variable, which we'll call "count"

  constructor(props) {
    super(props);

    this._searchLocationTimer = null;

    // props.timeZone needs to be like this
    const initialTimeZone = props.timeZone || guessTimeZone();

    this.state = {
      timeZone: {
        label: addAbbrevationToTimeZone({timeZone: initialTimeZone}),
        value: initialTimeZone,
      },
      timeZoneOptions: this.determineTimeZoneList(),
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.filterOption = this.filterOption.bind(this);
    this.onChangeTimeZone = this.onChangeTimeZone.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.setUpLocationAutoComplete();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSelectValueOnChange) {
      if (this.props.timeZone && prevProps.timeZone !== this.props.timeZone) {
        // update select value on change
        this.setState({
          timeZone: {
            label: addAbbrevationToTimeZone({timeZone: this.props.timeZone}),
            value: this.props.timeZone,
          },
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;
  }

  render() {
    return (
      <CustomSelect
        ref={"select-time-zone"}
        autoFocus={this.props.autoFocus}
        tabIndex={this.props.tabIndex}
        isSearchable
        className={classNames(
          this.props.inputClassName || "time-zone-select",
          "default-font-size",
          this.props.isDarkMode ? "dark-mode-select" : "",
          "select-default-font-size"
        )}
        classNamePrefix="dark-mode-modal"
        value={this.state.timeZone}
        onChange={this.onChangeTimeZone}
        options={this.state.timeZoneOptions}
        filterOption={this.filterOption}
        tabSelectsValue={false}
        onInputChange={this.onInputChange}
        onKeyDown={(event) => this.onKeyDown(event, "select-time-zone")}
        maxMenuHeight={150}
        isDisabled={this.props.isDisabled}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode: this.props.isDarkMode, 
          controlWidth: this.props.controlWidth,
          controllerBackgroundColor: this.props.controllerBackgroundColor,
          menuListMaxHeight: this.props.menuListMaxHeight,
        })}
        alwaysShowIndicator={true}
      />
    );
  }

  onKeyDown(event, ref) {
    // ESC key
    if (event && event.keyCode === KEYCODE_ESCAPE) {
      this.refs[ref] && this.refs[ref].blur();

      this.props.onEscape && this.props.onEscape();
    }
  }

  onChangeTimeZone(option) {
    if (option.isAddOn) {
      this.fetchGeoCode(option.value);
    } else {
      if (this.props.onChange) {
        this.props.onChange(option.value);
      }

      this.setState({ timeZone: option });
    }
  }

  onInputChange(inputText = null) {
    let input = inputText || "";

    this._searchLocationTimer && clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;

    this._searchLocationTimer = setTimeout(
      () => this.searchForLocationSuggestion(input),
      500
    );
  }

  filterOption(option, inputValue) {
    const { label, value, isAddOn } = option;
    const query = inputValue.toLowerCase();

    let additionalSearchQueries = "";
    if (TIME_ZONE_SEARCH_QUERY_INDEX[value]) {
      additionalSearchQueries = TIME_ZONE_SEARCH_QUERY_INDEX[value];
    }

    if (stringContainsGMTAndNumber(inputValue)) {
      const offset = getOffSetFromGMTSearchString(inputValue);
      if (inputValue.includes("+")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt+${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      } else if (inputValue.includes("-")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt-${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      }
    }

    return (
      isAddOn ||
      label?.toLowerCase().indexOf(query) >= 0 ||
      value?.toLowerCase().indexOf(query) >= 0 ||
      additionalSearchQueries?.indexOf(query) >= 0
    );
  }

  setUpLocationAutoComplete() {
    this.locationAutocomplete = getGoogleMapsAutoComplete();
  }

  searchForLocationSuggestion(input) {
    if (!this._isMounted) {
      return;
    }

    if (this.locationAutocomplete && input?.length > 0) {
      this.locationAutocomplete.getPlacePredictions(
        { input: input, types: ["(cities)"] },
        (predictions) => {
          if (!this._isMounted) {
            return;
          }

          const formattedSuggestions =
            this.formatLocationPredictions(predictions);
          const options = _.clone(this.state.timeZoneOptions);

          // {label, value}
          const filteredOptions = this.getSearchOptions({input, options, formattedSuggestions})

          this.setState({ timeZoneOptions: filteredOptions });
        }
      );
    } else if (!this.locationAutocomplete) {
      this.setUpLocationAutoComplete();
    }
  }

  getSearchOptions({input, options, formattedSuggestions}) {
    const matchingPopularTimeZone = getMatchingPopularTimeZones({
      input,
    });
    if (matchingPopularTimeZone.length > 0) {
      return options
        .filter((o) => !o.isAddOn)
        .concat(formattedSuggestions)
        .concat(this.formatSingleTimeZoneCountries(getMatchingSingleTimeZoneCountryResult(input)))
        .concat(this.formatMultipleTimeZoneCountries(getMatchingTimeZoneForMultipleCountryTZs(input)));
    }

    return this.formatSingleTimeZoneCountries(getMatchingSingleTimeZoneCountryResult(input))
      .concat(this.formatMultipleTimeZoneCountries(getMatchingTimeZoneForMultipleCountryTZs(input)))
      .concat(options
        .filter((o) => !o.isAddOn)
      )
      .concat(formattedSuggestions);
  }

  formatMultipleTimeZoneCountries(matches) {
    return matches.map((key) => {
      const countryKey = getMatchingMultipleTimeZoneKey(key);
      const timeZone = timeZonesForCountriesWithMultipleTimeZone[countryKey]
      return {
        value: timeZone,
        label: `(${createAbbreviationForTimeZone(timeZone)}) ${countryKey}`
      }
    });
  }

  formatSingleTimeZoneCountries(matches) {
    return matches.map((country) => {
      const matchingCountry = getMatchingSingleTimeZoneCountry(country);
      const timeZone = countryTimeZones[matchingCountry];
      return {
        value: countryTimeZones[matchingCountry],
        label: `(${createAbbreviationForTimeZone(timeZone)}) ${matchingCountry}`,
      }
    });
  }

  async fetchGeoCode(address) {
    try {
      const response = await getGeoCodeFromAddress(address);
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_GEOCODING_RESPONSE_STATUS.OK &&
        output.results?.[0]?.geometry?.location
      ) {
        // if API reports everything was returned successfully
        this.fetchTimeZone(output.results[0].geometry.location);
      } else {
        sendMessageToSentry(
          "eventForm: error on fetchGeoCode",
          "output.status !== ok"
        );
      }
    } catch (error) {
      handleError(error);
    }
  }

  async fetchTimeZone(location) {
    try {
      const response = await getTimeZoneFromLocation(location); // const { lat, lng } = location;
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (output?.status === GOOGLE_TIME_ZONE_RESPONSE_STATUS.OK && output.timeZoneId) {
        // Add what happens if you're a success here
        let updatedTimeZone = {
          value: output.timeZoneId,
          label: output.timeZoneName,
        };
        let updatedOptions =
          this.state.timeZoneOptions.concat(updatedTimeZone);

        if (this.props.onChange) {
          this.props.onChange(updatedTimeZone.value);
        }

        this.setState({
          timeZone: updatedTimeZone,
          timeZoneOptions: updatedOptions,
        });
      } else {
        sendMessageToSentry(
          "error on fetchTimeZone",
          "output.status !== ok"
        );
      }
    } catch (error) {
      handleError(error);
    }
  }

  formatLocationPredictions(predictions) {
    if (!predictions || predictions.length === 0) {
      return [];
    }

    return predictions.map((p, index) => {
      return {
        label: `${p.description}`,
        value: p.description,
        isAddOn: true,
      };
    });
  }

  determineTimeZoneList() {
    const {
      addOptionForLocalTimeZone,
    } = this.props;
    if (addOptionForLocalTimeZone) {
      return [{value: LOCAL_TIME_ZONE, label: LOCAL_TIME_ZONE}]
        .concat(CreateTimeZoneList(guessTimeZone()));
    }
    
    return CreateTimeZoneList(guessTimeZone());
  }
}

function mapStateToProps(state) {
  const { isDarkMode } = state;

  return {
    isDarkMode,
  };
}

export default connect(mapStateToProps, null)(SelectTimeZoneInput);
