import React, { useEffect, useState } from "react";
import { OpenLink } from "../../services/commonUsefulFunctions";
import { EVENT_FORM_ATTENDEE_SCROLL_CONTAINER, EVENT_FORM_SCROLL_CONTAINER, EXPANDED_ATTENDEES_CONTAINER, EXPANDED_SCROLL_CONTAINER } from "../../services/elementIDVariables";
import _ from "underscore";
import { SOCIAL_NETWORK_NAMES } from "./contactVariables";
import LinkedInHandle from "./handleIcons/linkedInHandle";
import TelegramHandle from "./handleIcons/telegramHandle";
import TwitterHandle from "./handleIcons/twitterHandle";
import YouTubeHandle from "./handleIcons/youTubeHandle";
import LinkHandle from "./handleIcons/linkHandle";
import { extractTwitterHandleFromUrl } from "../../lib/urlFunctions";
import GitHubHandle from "./handleIcons/gitHubHandle";
import { isNullOrUndefined } from "../../services/typeGuards";
import { getEnrichedAttendeeSocialLinks } from "../../lib/enrichedContactFunctions";

export const SOCIAL_LINK_HANDLE_COMPONENTS = {
  [SOCIAL_NETWORK_NAMES.GITHUB]: GitHubHandle,
  [SOCIAL_NETWORK_NAMES.LINKEDIN]: LinkedInHandle,
  [SOCIAL_NETWORK_NAMES.TELEGRAM]: TelegramHandle,
  [SOCIAL_NETWORK_NAMES.TWITTER]: TwitterHandle,
  [SOCIAL_NETWORK_NAMES.YOUTUBE]: YouTubeHandle,
};

interface ContactHandlesProps {
  attendee: EnrichedAttendee
  isLastContact: boolean
  isPreview?: boolean
}

/**
 * Use the isPreview prop to disable tooltips and force the handle to render in light mode.
 * Useful for the availability page preview.
 */
const ContactHandles = ({ attendee, isLastContact, isPreview }: ContactHandlesProps) => {
  const [tooltipText, setTooltipText] = useState<string>();

  const onScroll = () => {
    setTooltipText(undefined);
    removeOnScrollListener();
  };

  useEffect(() => {
    return () => {
      // clean up listener
      removeOnScrollListener();
    }
  }, []);

  const addOnScrollListner = () => {
    const expandedViewContainer = document.getElementById(EXPANDED_SCROLL_CONTAINER);
    const attendeeScrollContainer = document.getElementById(EXPANDED_ATTENDEES_CONTAINER);
    const eventFormScrollContainer = document.getElementById(EVENT_FORM_SCROLL_CONTAINER);
    const eventFormAttendeesScrollContainer = document.getElementById(EVENT_FORM_ATTENDEE_SCROLL_CONTAINER);

    expandedViewContainer?.addEventListener("scroll", onScroll);
    attendeeScrollContainer?.addEventListener("scroll", onScroll);
    eventFormScrollContainer?.addEventListener("scroll", onScroll);
    eventFormAttendeesScrollContainer?.addEventListener("scroll", onScroll);
  };

  const removeOnScrollListener = () => {
    const expandedViewContainer = document.getElementById(EXPANDED_SCROLL_CONTAINER);
    const attendeeScrollContainer = document.getElementById(EXPANDED_ATTENDEES_CONTAINER);
    const eventFormScrollContainer = document.getElementById(EVENT_FORM_SCROLL_CONTAINER);
    const eventFormAttendeesScrollContainer = document.getElementById(EVENT_FORM_ATTENDEE_SCROLL_CONTAINER);

    expandedViewContainer?.removeEventListener("scroll", onScroll);
    attendeeScrollContainer?.removeEventListener("scroll", onScroll);
    eventFormScrollContainer?.removeEventListener("scroll", onScroll);
    eventFormAttendeesScrollContainer?.removeEventListener("scroll", onScroll);
  };

  const onHoverChange = (newTooltipText: string | undefined) => {
    if (isNullOrUndefined(newTooltipText)) {
      // remove listener
      removeOnScrollListener();
    } else {
      // add listener
      addOnScrollListner();
    }

    setTooltipText(newTooltipText);
  };

  const socialLinks: SocialLink[] = getEnrichedAttendeeSocialLinks(attendee);

  return (
    <div className="flex mt-0.5">
      {tooltipText ? (
        <div className="full-attendee-list-attendee-handle-hover-box pointer-events-none">
          <div
            className="full-attendee-list-attendee-handle-hover-item"
            style={{ top: isLastContact ? -36 : 24 }}
          >
            <div>{tooltipText}</div>
          </div>
        </div>
      ) : null}
      {socialLinks.map(socialLink => {
        if (socialLink.social_network_name === SOCIAL_NETWORK_NAMES.FACEBOOK) {
          return null;
        }

        const HandleComponent = _.get(SOCIAL_LINK_HANDLE_COMPONENTS, socialLink.social_network_name, LinkHandle)
        let newTooltipText = socialLink.social_network_name.charAt(0).toUpperCase() + socialLink.social_network_name.slice(1);
        switch(socialLink.social_network_name) {
          case SOCIAL_NETWORK_NAMES.GITHUB:
            newTooltipText = "GitHub";
            break;
          case SOCIAL_NETWORK_NAMES.LINKEDIN:
            newTooltipText = "LinkedIn";
            break;
          case SOCIAL_NETWORK_NAMES.TELEGRAM:
            newTooltipText = "Telegram";
            break;
          case SOCIAL_NETWORK_NAMES.TWITTER:
            newTooltipText = extractTwitterHandleFromUrl(socialLink.url) ?? "Twitter";
            break;
          case SOCIAL_NETWORK_NAMES.YOUTUBE:
            newTooltipText = "YouTube";
            break;
          default:
            newTooltipText = socialLink.url.replace(/(^\w+:|^)\/\//, "");
        }

        return (
          <HandleComponent
            key={socialLink.social_network_name}
            className="mr-1"
            disableDarkStyles={isPreview}
            onClick={() => OpenLink(socialLink.url)}
            onMouseEnter={() => onHoverChange(isPreview ? undefined : newTooltipText)}
            onMouseLeave={() => onHoverChange(undefined)}
          />
        );
      })}
    </div>
  );
};

export default ContactHandles;
