import {
  addMinutes,
  endOfMinute,
  parseISO,
  startOfMinute,
  subMinutes,
} from "date-fns";
import { constructRequestURL } from "../services/api";
import {
  checkIfBookableSlotsAreValid,
  createFreeSlotsBasedOnBusySlots,
  generateBookableSlotsFromObj,
  getTimeInAnchorTimeZone,
  guessTimeZone,
  handleError,
  isInt,
  mergeBusySlots,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import { isVersionV2 } from "../services/versionFunctions";
import { removeDuplicateEventsBasedOnTime } from "./eventFunctions";
import { splitSlotIntoDuration } from "./availabilityFunctions";
import { INVITEE_NAME_BLOCK } from "../services/globalVariables";
import { getSelectedUserName, getUserName } from "./userFunctions";
import { getDefaultHeaders } from "./fetchFunctions";

export const DELETE_RECURRING_TYPE = {
  THIS: "this",
  ALL: "all",
  THIS_AND_FOLLOWING: "this_and_following"
};

export function getVimcalBookingLinkDataFromText(text, defaultTimeZone) {
  if (!isVimcalBookingLinkText(text)) {
    return {};
  }
  return {
    date: getVimcalBookingLinkDateFromText(text),
    duration: getVimcalBookingLinkDuration(text),
    timeZone: getVimcalBookingLinkIANATimeZone(text, defaultTimeZone),
  };
}
export function isVimcalBookingLinkText(text) {
  if (!text) {
    return false;
  }
  // is vimcal if it has "May 9, 2023"
  const dateRegex =
    /\b(?:January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}\b/;
  return dateRegex.test(text);
}

function getVimcalBookingLinkDateFromText(text) {
  const dateRegex =
    /\b(?:January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}\b/;
  const match = text.match(dateRegex);
  if (match) {
    return match[0];
  }

  return null;
}

function getVimcalBookingLinkDuration(text) {
  if (!text) {
    return 30;
  }

  const minutesRegex = /(\d+)\sminutes/;
  const match = text.match(minutesRegex);

  if (match) {
    const minutes = parseInt(match[1], 10);
    return isInt(minutes) ? minutes : 30;
  }

  return 30;
}

function getVimcalBookingLinkIANATimeZone(text, defaultTimeZone) {
  if (!text) {
    return defaultTimeZone ?? guessTimeZone();
  }

  const loweredText = text.toLowerCase();
  if (loweredText.includes("america/new york")) {
    return "America/New_York";
  }

  if (loweredText.includes("america/chicago")) {
    return "America/Chicago";
  }

  if (loweredText.includes("america/denver")) {
    return "America/Denver";
  }

  if (loweredText.includes("america/los angeles")) {
    return "America/Los_Angeles";
  }

  if (loweredText.includes("america/honolulu")) {
    return "Pacific/Honolulu";
  }

  return defaultTimeZone ?? guessTimeZone();
}

export function getAllVimcalSlotTimesFromText(text) {
  if (!text) {
    return null;
  }

  const pattern =
    /(\w+\s\d+\s\(\w+\)):\s+•\s+(\d{1,2}:\d+[ap]m\s+-\s+\d{1,2}:\d+[ap]m)/g;

  const matches = Array.from(
    text.matchAll(pattern),
    (match) => `${match[1]} ${match[2]}`
  );
  return matches;
}

export function getVimcalSlotDurationFromText(text) {
  const pattern = /(\d+)\s*(?:minutes|minute)/i;

  const match = text.match(pattern);
  if (match) {
    const numberBeforeMinutes = match[1];
    return numberBeforeMinutes;
  } else {
    return 30;
  }
}

export function getVimcalSlotTimeZoneFromText(text) {
  const pattern = /\(([^)]+)\)/;
  const match = text.match(pattern);

  return "America/New_York";

  if (match) {
    const timeZone = match[1];
    console.log("timezone match_", match);

    return timeZone;
  } else {
    return null;
  }
}

export function getVimcalAvailabilityTypeAndToken(urlString) {
  if (!urlString) {
    return null;
  }
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    handleError(e);
    return null;
  }

  const pathname = url.pathname; // "/t/michaelzhao/30-min-a5b414"

  // Split the pathname into parts
  const parts = pathname.split("/"); // ["", "t", "michaelzhao", "30-min-a5b414"]

  // Get the second part of the pathname (the 't')
  const type = parts[1]; // "t"
  if (parts.length === 3) {
    // token is the third part of the pathname
    return {
      type,
      token: parts[2],
    };
  }

  // Get the remaining parts of the pathname (excluding the 't')
  return {
    type,
    userName: parts[2] ?? "",
    slug: parts[3] ?? "",
  };
}

export function getBookingProjectFetchURL({ userName, slug, token, path }) {
  const endpoint =
    slug && userName ? `/by-slug/${userName}/${slug}` : `/${token}`;
  return constructRequestURL(path + endpoint, true);
}

export async function fetchBookingProjectRescheduleSlots({
  token,
  masterAccount,
  currentUser,
  currentTimeZone,
}) {
  const path = `${
    isVersionV2() ? "slots" : "availabilities"
  }/appointment/${token}`;
  const url = constructRequestURL(path, isVersionV2());

  const response = await Fetcher.get(url);
  const availability = response?.availability_link;
  if (!availability) {
    return;
  }
  const { token: fetchToken } = availability;
  const fetchAvailabilityResponse = await fetchBookingProjectSlotsData({
    token: fetchToken,
    masterAccount,
    currentUser,
    currentTimeZone,
  });

  return fetchAvailabilityResponse;
}

export async function fetchBookingProjectSlotsData({
  userName,
  slug,
  token,
  masterAccount,
  currentUser,
  currentTimeZone,
}) {
  const url = getBookingProjectFetchURL({
    path: "slots",
    userName,
    slug,
    token,
  });
  const response = await Fetcher.get(url);
  const availability = response?.availabilities;
  if (!availability) {
    return;
  }
  const { title, client_time_zone, duration, description, user } = availability;
  return {
    ...constructDayAndTimeSlots({
      response,
      buffer_from_now: availability?.buffer_from_now ?? 0,
      duration: availability?.duration ?? 30,
      currentTimeZone,
    }),
    ...{
      title: getTitleWithName({ masterAccount, currentUser, title }),
      client_time_zone,
      duration,
      description,
      user,
    },
  };
}

export async function fetchBookingProjectPersonalLinksData({
  userName,
  slug,
  token,
  masterAccount,
  currentUser,
  initialPath = "personal_links",
  currentTimeZone,
}) {
  const url = getBookingProjectFetchURL({
    path: initialPath,
    userName,
    slug,
    token,
  });
  const response = await Fetcher.get(url);
  const personalLinks = response?.personal_link;
  if (!personalLinks) {
    return;
  }
  const {
    slots,
    days_forward: daysForward,
    buffer_before: bufferBefore,
    buffer_after: bufferAfter,
    buffer_from_now: bufferFromNow,
    time_zone: timeZone,
    token: personalLinksToken,
    conferencing,
    title,
    duration,
    description,
  } = personalLinks;
  const bookableSlots = generateBookableSlotsFromObj({
    slots,
    timeZone,
    daysForward,
    shouldFormatIntoUTC: false,
    bufferBefore,
    bufferAfter,
  });
  if (!checkIfBookableSlotsAreValid(bookableSlots)) {
    return;
  }

  // given slots above, get when user is free
  const path = `personal_links/${personalLinksToken}/availability`;
  const urlAvailability = constructRequestURL(path, isVersionV2());
  const linkData = {
    conferencing,
    time_slots: bookableSlots,
  };

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(linkData),
  };

  const availability = await Fetcher.post(urlAvailability, payloadData);
  if (!availability?.free_busy) {
    return;
  }
  const { user } = availability;
  return {
    ...constructDayAndTimeSlots({
      response: availability,
      buffer_from_now: bufferFromNow ?? 0,
      duration: duration ?? 30,
      currentTimeZone,
    }),
    ...{
      title: getTitleWithName({ masterAccount, currentUser, title }),
      duration,
      description,
      user,
    },
  };
}

function getTitleWithName({ title, currentUser, masterAccount }) {
  const name = getUserName({ masterAccount, currentUser }).fullName;
  return title.replace(
    INVITEE_NAME_BLOCK,
    name || getSelectedUserName({user: currentUser}).fullName
  );
}

// TODO: this needs to be moved to backend
export function constructDayAndTimeSlots({
  response,
  buffer_from_now,
  duration,
  currentTimeZone,
}) {
  let freeSlots = [];

  response.free_busy.forEach((s) => {
    let currentTimeBuffer = [
      {
        start: subMinutes(new Date(), 30).toISOString(),
        end: addMinutes(new Date(), buffer_from_now).toISOString(),
      },
    ];

    const busySlots = mergeBusySlots(currentTimeBuffer.concat(s.busy_slots));
    freeSlots = freeSlots.concat(
      createFreeSlotsBasedOnBusySlots(s.start_time, s.end_time, busySlots)
    );
  });
  const guardedDuration = duration || 30;

  let splittedEvents = [];
  const guessedTimeZone = guessTimeZone();
  freeSlots.forEach((s) => {
    let slotStart = startOfMinute(parseISO(s.start_time));
    let slotEnd = endOfMinute(parseISO(s.end_time));
    if (currentTimeZone && currentTimeZone !== guessedTimeZone) {
      slotStart = getTimeInAnchorTimeZone(
        slotStart,
        guessedTimeZone,
        currentTimeZone
      );
      slotEnd = getTimeInAnchorTimeZone(
        slotEnd,
        guessedTimeZone,
        currentTimeZone
      );
    }
    const newEvents = splitSlotIntoDuration({
      breakDuration: guardedDuration || 30,
      start: slotStart,
      end: slotEnd,
      currentSlots: splittedEvents,
      hideCancel: true,
      isTemporaryAIEvent: true,
      breakIntoInterval: true,
    });
    splittedEvents = splittedEvents.concat(newEvents);
  });
  splittedEvents = removeDuplicateEventsBasedOnTime(splittedEvents);

  return { freeSlots, splittedEvents };
}

export function getVimcalBookingURLFromText(str) {
  if (!str) {
    return;
  }

  const slotsUrlMatch = str.match(/https:\/\/book\.vimcal\.com\/t\/[\w/-]+/);
  if (slotsUrlMatch?.[0]) {
    return slotsUrlMatch?.[0];
  }

  const personalLinksUrlMatch = str.match(
    /https:\/\/book\.vimcal\.com\/p\/[\w/-]+/
  );
  if (personalLinksUrlMatch?.[0]) {
    return personalLinksUrlMatch?.[0];
  }

  return null;
}

export async function listRecurringInstances({
  masterEventIds,
  timeMin, // in formatISO format
  timeMax, // in formatISO format
  calendarProviderId,
  userEmail,
}) {
  if (!userEmail 
    || !masterEventIds 
    || !timeMin 
    || !timeMax 
    || !calendarProviderId 
    || calendarProviderId.length === 0
  ) {
    return;
  }

  const params = {
    body: JSON.stringify({
      calendar_provider_id: calendarProviderId,
      timeMin,
      timeMax,
      masterEventIds,
    }),
  };
  const url = constructRequestURL("recurring_events/list_instances", true);
  try {
    const result = await Fetcher.post(url, params, true, userEmail);
    return result?.events;
  } catch (error) {
    handleError(error);
  }
}
