import React from "react";
import {
  addDataLayerTracking,
  trackOnLoginLoginAttribution,
} from "../../components/tracking";
import { getOutLookLoginURL } from "../../lib/outlookFunctions";
import {
  isElectron,
  OpenLink,
  openLinkOnSamePage,
} from "../../services/commonUsefulFunctions";
import { MICROSOFT_LOGO } from "../../services/globalVariables";
import Spinner from "../../components/spinner";
import classNames from "classnames";
import { useAccountActivity } from "../../services/stores/appFunctionality";
import { CALENDAR_PROVIDERS } from "../../lib/vimcalVariables";
import { getDefaultModalBorder } from "../../lib/styleFunctions";

export default function OutlookLoginButton({
  isDesktopLogin,
  isSignUp,
  experimentType,
  accountType,
  showConsentScreen,
  isLoading,
  isMagicLink,
}) {
  const url = getOutLookLoginURL({
    isDesktopLogin,
    accountType,
    showConsentScreen,
    isMagicLink,
  });
  const setLastClickedLoginButton = useAccountActivity((state) => state.setLastClickedLoginButton);
  const SHARED_ICON_CLASSNAME = "mr-5 margin-left-18px";
  return (
    <div
      style={{
        height: "43.5px",
        color: "rgba(0, 0, 0, 0.54)",
        border: getDefaultModalBorder(false)
      }}
      className={classNames(
        "bg-white hover:bg-gray-200 duration-100 rounded-md flex justify-center items-center text-center default-font-size font-weight-400 cursor-pointer mb-2 login-button-dimension select-none",
      )}
      onClick={() => {
        setLastClickedLoginButton(CALENDAR_PROVIDERS.OUTLOOK);
        if (isElectron()) {
          OpenLink(url);
        } else {
          trackOnLoginLoginAttribution({
            isOutlook: true,
            src: isSignUp ? `signup_${experimentType}` : "login",
          });
          addDataLayerTracking({
            event: `pressed_${
              isSignUp ? "signup" : "login"
            }_${experimentType}_outlook`,
          });
          openLinkOnSamePage(url);
        }
      }}
    >
      {isLoading ? (
        <div className={classNames("width-18px height-18px relative", SHARED_ICON_CLASSNAME)}>
          <Spinner useSmallSpinner={true} className="absolute -top-30px left-2.5" />
        </div>
      ) : (
        <img
          alt=""
          width="18px"
          height="18px"
          className={SHARED_ICON_CLASSNAME}
          src={MICROSOFT_LOGO}
        />
      )}
      Sign-in with Microsoft
    </div>
  );
}
