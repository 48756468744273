import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import EventModalPopup from "../../eventModalPopup";
import { useStripeModule } from "../stripePaymentModal";
import ThankYouPage from "./thankYouPage";
import { MONTHLY, YEARLY } from "../../../lib/vimcalVariables";
import { devErrorPrint } from "../../../services/devFunctions";
import { getConversionType, SUBSCRIPTION_LENGTH, trackPaywallConversionMixPanel } from "../../../lib/mixpanelTracking";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { getUnauthorizedPaymentUserFromQueryParam } from "../../../services/queryParamFunctions";
import { useHistory } from "react-router-dom";
import { determineDefaultModalStyle, MODAL_OVERLAY_Z_INDEXES } from "../../../lib/modalFunctions";

interface PaymentModalProps {
  closeModal: () => void
  isInModal?: boolean
  isOpen: boolean
  paymentOption: typeof MONTHLY | typeof YEARLY
  referral?: Referral
  showThankYouPage: boolean
  setShowThankYouPage: StateSetter<boolean>
}

/**
 * When this component is mounted with `isOpen` being true, the Stripe module will be imported.
 */
export default function PaymentModal({ closeModal, isInModal, isOpen, paymentOption, referral, setShowThankYouPage, showThankYouPage }: PaymentModalProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [shouldFreezeModal, setShouldFreezeModal] = useState(false);
  const history = useHistory();

  const {
    stripePaymentModalRef,
    stripePromiseRef,
    elementsRef,
    hasCompletedImport,
    importStripeModule,
  } = useStripeModule();

  // comes in as subscribe?email=mike@vimcal.com
  const unauthorizedEmail = useMemo(() => getUnauthorizedPaymentUserFromQueryParam(), []);
  const isUnauthorized = useMemo(() => isEmptyObjectOrFalsey(currentUser), []);
  // keep track of if going from churned to paying or trial to paying, etc
  const conversionType = useMemo(() => getConversionType({ masterAccount }), []);

  useEffect(() => {
    if (isOpen && !hasCompletedImport) {
      importStripeModule();
    }
  }, [hasCompletedImport, isOpen]);

  const goToVimcalWithRefresh = () => {
    if (isInModal) {
      return;
    }

    history.push("/home?ref=r");
  };

  const onCompletePayment = () => {
    setShowThankYouPage(true);

    trackPaywallConversionMixPanel({
      currentUser,
      subscriptionFrequency: paymentOption === MONTHLY
        ? SUBSCRIPTION_LENGTH.MONTHLY
        : SUBSCRIPTION_LENGTH.ANNUAL,
      conversionType,
    });

    if (!isUnauthorized) {
      // only auto refresh if authorized
      setTimeout(() => {
        goToVimcalWithRefresh();
      }, 2000);
    }
  };

  const onPaymentError = (error: string) => {
    devErrorPrint(error, "onPaymentError_");
    // do nothing for now. pending backend investigation on bugs found on sentry
  };

  if (!isOpen || !hasCompletedImport || !elementsRef.current || !stripePaymentModalRef.current) {
    return null;
  }

  const modalStyle = determineDefaultModalStyle(
    isDarkMode,
    false,
    { overlay: { zIndex: MODAL_OVERLAY_Z_INDEXES.PAYMENT } },
  );

  return (
    <EventModalPopup
      isOpen={isOpen}
      onRequestClose={closeModal}
      width={showThankYouPage ? 600 : 400}
      title={""}
      style={modalStyle}
      shouldFreeze={shouldFreezeModal}
      hideHeader={!showThankYouPage}
    >
      {showThankYouPage ? (
        <ThankYouPage isInModal={isInModal} paymentOption={paymentOption} referral={referral} />
      ) : (
        <elementsRef.current stripe={stripePromiseRef.current}>
          <stripePaymentModalRef.current
            plan={paymentOption}
            setShouldFreezeModal={setShouldFreezeModal}
            referral={referral}
            email={unauthorizedEmail}
            onSuccess={onCompletePayment}
            onError={onPaymentError}
            shouldRenderCloseButton={true}
            closeModal={closeModal}
          />
        </elementsRef.current>
      )}
    </EventModalPopup>
  );
}
