import React from "react";

export default function SwissCheeseIcon({ width, height }) {
  return (
    <svg width={width ??"43"} height={height ??"44"} viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.940613 43.0433L0.940613 0.827682L42.3745 0.827683L42.3745 23.5646C42.1203 23.522 41.8592 23.4998 41.5929 23.4998C39.0023 23.4998 36.9023 25.5999 36.9023 28.1904C36.9023 30.781 39.0023 32.881 41.5929 32.881C41.8592 32.881 42.1203 32.8589 42.3745 32.8162L42.3745 43.0433L0.940613 43.0433ZM13.4492 21.9401C16.0398 21.9401 18.1398 24.0402 18.1398 26.6308C18.1398 29.2213 16.0398 31.3214 13.4492 31.3214C10.8586 31.3214 8.75857 29.2213 8.75857 26.6308C8.75857 24.0402 10.8586 21.9401 13.4492 21.9401ZM15.0125 8.64169C15.0125 7.34641 13.9625 6.29638 12.6672 6.29638C11.3719 6.29638 10.3219 7.34641 10.3219 8.64169C10.3219 9.93697 11.3719 10.987 12.6672 10.987C13.9625 10.987 15.0125 9.93697 15.0125 8.64169ZM29.475 12.5576C30.9861 12.5576 32.2112 13.7826 32.2112 15.2938C32.2112 16.8049 30.9861 18.03 29.475 18.03C27.9638 18.03 26.7388 16.8049 26.7388 15.2938C26.7388 13.7826 27.9638 12.5576 29.475 12.5576Z" fill="url(#paint0_linear_740_1382)"/>
      <defs>
      <linearGradient id="paint0_linear_740_1382" x1="1.31512" y1="12.3708" x2="42.3151" y2="36.8709" gradientUnits="userSpaceOnUse">
      <stop stopColor="#CF207A"/>
      <stop offset="0.5" stopColor="#E33D71"/>
      <stop offset="1" stopColor="#F65968"/>
      </linearGradient>
      </defs>
    </svg>
  );
}
