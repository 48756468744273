import { useEffect } from "react";
import useForceUpdate from "./useForceUpdate";

interface UseIntervalOptions {
  callback: TimerHandler
  enabled?: boolean
  timeout: number
}

/**
 * Execute a callback on a regular interval. The callback should be memoized to prevent
 * unintentional resets of the useEffect.
 */
export default function useInterval({ callback, enabled=true, timeout }: UseIntervalOptions) {
  const { updateCount, forceUpdate } = useForceUpdate();

  useEffect(() => {
    if (enabled) {
      const interval = window.setInterval(callback, timeout);
      return () => window.clearInterval(interval);
    }
  }, [callback, enabled, timeout, updateCount]);

  return { resetTimer: forceUpdate };
}
