import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-monthly-calendar-fork-default-iso-week-number";
import { addMonths, format, isSameDay, isSameMonth, subMonths } from "date-fns";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  getWeekStartOnInt,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import { ChevronLeft, ChevronRight } from "react-feather";
import { ISO_8601, SECOND_IN_MS } from "../services/globalVariables";

export default function DateRangePicker({
  startDate,
  endDate,
  isSelectingEndDate,
  setDate,
  setParentHoverDate,
  inputMonth,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const weekStart = useSelector((state) => state.weekStart);
  const [hoverDate, setHoverDate] = useState(null);
  const [currentMonthDate, setCurrentMonthDate] = useState(
    startDate ?? new Date(),
  );
  const hoverTimer = useRef(null);

  useEffect(() => {
    if (inputMonth) {
      setCurrentMonthDate(inputMonth);
    }
  }, [inputMonth]);

  const renderDayTile = ({ date }) => {
    const getTextColor = () => {
      if (
        !isSelectingEndDate && // start date
        hoverDate &&
        isSameOrBeforeDay(date, endDate) &&
        isSameOrAfterDay(date, hoverDate) &&
        isSameOrBeforeDay(hoverDate, startDate)
      ) {
        return "text-white";
      }

      if (
        isSelectingEndDate &&
        hoverDate &&
        isSameOrAfterDay(date, startDate) &&
        isSameOrBeforeDay(date, hoverDate)
      ) {
        return "text-white";
      }
      if (
        isSameOrAfterDay(date, startDate) &&
        isSameOrAfterDay(endDate, date)
      ) {
        return "text-white";
      }
      if (hoverDate && isSameDay(date, hoverDate)) {
        return "text-white";
      }

      if (!isSameMonth(currentMonthDate, date)) {
        return "secondary-text-color";
      }
      return "default-font-color";
    };

    const getBackgroundColor = () => {
      if (
        (hoverDate && isSameDay(date, hoverDate)) ||
        isSameDay(date, startDate) ||
        isSameDay(date, endDate)
      ) {
        return "date-range-start-end-date";
      }
      if (
        !isSelectingEndDate && // start date
        hoverDate &&
        isSameOrBeforeDay(date, endDate) &&
        isSameOrAfterDay(date, hoverDate) &&
        isSameOrBeforeDay(hoverDate, startDate)
      ) {
        // hover date is before start date
        return "date-range-picker-in-between-date";
      }

      if (
        isSelectingEndDate &&
        hoverDate &&
        isSameOrAfterDay(date, startDate) &&
        isSameOrBeforeDay(date, hoverDate)
      ) {
        // hover date (end date) is after end date
        return "date-range-picker-in-between-date";
      }
      if (
        isSameOrAfterDay(date, startDate) &&
        isSameOrBeforeDay(date, endDate)
      ) {
        // in between
        return "date-range-picker-in-between-date";
      }

      if (hoverDate && isSameDay(date, hoverDate)) {
        return "date-range-picker-in-between-date";
      }

      return "";
    };
    return (
      <div
        className={classNames(
          "font-size-12-important",
          "w-90-percent h-90-percent flex justify-center items-center rounded-md",
          getTextColor(),
          getBackgroundColor(),
        )}
        onMouseEnter={() => {
          setHoverDate(date);
          if (setParentHoverDate) {
            setParentHoverDate(date);
          }
          clearTimeout(hoverTimer.current);
        }}
        onMouseLeave={() => {
          hoverTimer.current = setTimeout(() => {
            setHoverDate(null);
            setParentHoverDate(null);
          }, 0.1 * SECOND_IN_MS);
        }}
        onClick={() => setDate(date)}
      >
        {format(date, "d")}
      </div>
    );
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setCurrentMonthDate(activeStartDate);
    // You can perform additional actions here if needed
  };

  const renderHeader = () => {
    if (!isValidJSDate(currentMonthDate)) {
      return;
    }
    return (
      <div className="flex items-center ml-5 mt-2 mb-2 select-none">
        <div className="font-weight-400 font-size-12 w-20">
          {format(currentMonthDate, "MMM y")}
        </div>

        <ChevronLeft
          size={16}
          className="clickable-icon ml-2"
          onClick={() => {
            setCurrentMonthDate(subMonths(currentMonthDate, 1));
          }}
        />

        <ChevronRight
          size={16}
          className="clickable-icon ml-2"
          onClick={() => {
            setCurrentMonthDate(addMonths(currentMonthDate, 1));
          }}
        />
      </div>
    );
  };

  const determineCalendarType = () => {
    const weekStartInt = getWeekStartOnInt(weekStart);
    if (weekStartInt === 0) {
      // Sunday
      return "US";
    } else if (weekStartInt === 1) {
      // Monday
      return ISO_8601;
    } else if (weekStartInt === 6) {
      // Saturday
      return "Arabic";
    } else {
      return ISO_8601;
    }
  };

  return (
    <div className="date-range-picker-container">
      {renderHeader()}
      <Calendar
        tileContent={({ date }) => renderDayTile({ date, isDarkMode })}
        onActiveStartDateChange={handleActiveStartDateChange}
        value={currentMonthDate}
        calendarType={determineCalendarType()}
      />
    </div>
  );
}
