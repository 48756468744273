import classNames from "classnames";
import React, { useState } from "react";
import {
  determineCalendarColor,
  getCalendarFromEmail,
} from "../lib/calendarFunctions";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import LoadingSkeleton from "./loadingSkeleton";

const PROFILE_PICTURE_BASE_STYLING = {
  width: 30,
  height: 30,
  borderRadius: 9999999,
};

const SMALL_PROFLIE_PICTURE_BASE_STYLING = {
  width: 20,
  height: 20,
  borderRadius: 9999999,
};

export default function ProfilePictureThroughURL({
  className = "",
  email,
  avatarUrl,
  isSmall = false,
}) {
  const [hasFinishedLoading, setFinishedLoading] = useState(false);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const renderLoadingSkeleton = () => {
    return (
      <LoadingSkeleton
        style={isSmall ? SMALL_PROFLIE_PICTURE_BASE_STYLING : PROFILE_PICTURE_BASE_STYLING} 
        className={className}
      />
    );
  };

  const getComputedEmailLabel = () => {
    if (email) {
      return email[0]?.toUpperCase();
    }
    return "";
  };

  const shouldDisplayComputedProfilePicture = !avatarUrl || !hasFinishedLoading;
  const renderComputedProfilePicture = () => {
    if (!email) {
      return renderLoadingSkeleton();
    }
    if (shouldDisplayComputedProfilePicture) {
      const calendarColor =
        determineCalendarColor(getCalendarFromEmail({
          email,
          allLoggedInUsers,
          allCalendars,
          masterAccount,
        })) ??
        "#01579B";

      return (
        <div
          style={{
            backgroundColor: calendarColor,
          }}
          className={classNames(
            className ?? "",
            "rounded-full",
            isSmall ? "small-profile-picture-circle fonts-size-12" : "profile-picture-circle font-size-16",
            " flex items-center justify-center ",
            "text-white",
            "select-none",
          )}
        >
          {getComputedEmailLabel()}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {shouldDisplayComputedProfilePicture ? renderComputedProfilePicture() : null}
      <img
        alt=""
        className={classNames(
          className ?? "",
          shouldDisplayComputedProfilePicture ? "hidden" : "",
          isSmall ? "small-profile-picture-circle" : "profile-picture-circle",
        )}
        src={avatarUrl}
        onLoad={() => {
          setFinishedLoading(true);
        }}
        onError={() => {
          setFinishedLoading(false);
        }}
      />
    </>
  );
}
