import { constructRequestURL } from "../../services/api";
import Fetcher from "../../services/fetcher";
import { handleError } from "../../services/commonUsefulFunctions";
import { getAllVimcalSlotTimesFromText } from "../vimcalFunctions";
import { extractDurationInMinutes } from "../timeFunctions";

export const REVERSE_SLOTS_MODELS = {
  MODEL_GPT_3_5_TURBO: "gpt_3_5_turbo",
  MODEL_GPT_3_5_TURBO_16K: "gpt_3_5_turbo_16k",
  MODEL_GPT_4: "gpt_4",
  MODEL_CLAUDE_1_3: "claude_1_3",
  MODEL_CLAUDE_INSTANT_1_1: "claude_instant_1_1",
  MODEL_CLAUDE_INSTANT_1_2: "claude_instant_1_2",
  MODEL_CHAT_BISON: "chat_bison",
  MODEL_TEXT_BISON: "text_bison",
  MODEL_GPT_4_TURBO: "gpt_4_turbo",
};

export const REVERSE_SLOTS_PROVIDERS = {
  PROVIDER_ANTHROPIC: "anthropic",
  PROVIDER_GOOGLE: "google",
  PROVIDER_OPEN_AI: "open_ai",
};

export async function getReverseSlotCompletion({
  inputText,
  currentTimeZone,
  currentDate,
  currentTime,
  currentUser,
  currentYear,
  model = REVERSE_SLOTS_MODELS.MODEL_CLAUDE_INSTANT_1_2,
  provider = REVERSE_SLOTS_PROVIDERS.PROVIDER_ANTHROPIC,
}) {
  const path = "reverse_slots";
  const url = constructRequestURL(path, true);

  const param = {
    input_text: inputText,
    current_time: currentTime,
    current_date: currentDate,
    current_time_zone: currentTimeZone,
    current_year: currentYear,
    model,
    provider
  };
  const payloadData = {
    body: JSON.stringify(param),
  };

  try {
    const response = await Fetcher.post(
      url,
      payloadData,
      true,
      currentUser.email
    );
    return response;
  } catch (error) {
    handleError(error);
  }
}

export function formatTextForSlotCompletion(text) {
  if (!text) {
    return { inputText: "" };
  }

  const CHARACTER_LIMIT = 800;

  const isVimcalSlotText = getAllVimcalSlotTimesFromText(text)?.length > 0;
  if (!isVimcalSlotText) {
    const duration = extractDurationInMinutes(text);
    return {
      inputText: text.substring(0, CHARACTER_LIMIT),
      detectedDuration: duration,
    };
  }

  const pattern = /\b(\d+)\s?(?:minutes?|mins?)\b/i;
  const match = pattern.exec(text);
  const updatedString = text.replace(pattern, "");
  const detectedDuration = match?.[1]; // in minutes
  return {
    inputText: updatedString.substring(0, CHARACTER_LIMIT),
    detectedDuration,
  };
}
