import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import appBroadcast from "../broadcasts/appBroadcast";
import { APP_BROADCAST_VALUES } from "../lib/broadcastValues";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { constructRequestURLV2, isErrorResponse } from "../services/api";
import { fetcherGet } from "../services/fetcherFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { useAllLoggedInUsers } from "../services/stores/SharedAccountData";
import { constructQueryParams } from "../services/commonUsefulFunctions";

// TODO: This could probably be used elsewhere. Move to a shared location if so.
type UserAuthResponse = {
  user: User,
  master_account: MasterAccount,
  settings: UserSettings,
  availability_settings: AvailabilitySettings
  connected_users: User[],
  connected_user_domains: Record<string, Domain>
  client_version?: string
  domain?: Domain
}

export default function DemoLogin() {
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const loginKey = searchParams.get("key");

  useEffect(() => {
    const fetchDemoUser = async () => {
      if (!loginKey) {
        /* Redirect to login if key is missing */
        history.push("/login");
      }

      const path = "demo_login";
      const queryParams = constructQueryParams({ loginKey });
      const url = constructRequestURLV2(path) + `?${queryParams}`;
      const payloadData = {
        headers: getDefaultHeaders(),
        credentials: "include" as RequestCredentials,
      };

      const response = await fetcherGet<UserAuthResponse | ErrorResponse>({
        authorizationRequired: false,
        email: null,
        payloadData,
        url,
      });

      if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        /* Return home if an error occurs */
        history.push("/login");
      }

      appBroadcast.publish(APP_BROADCAST_VALUES.HANDLE_LOGIN_RESPONSE, response);
    };

    if (
      !isEmptyArrayOrFalsey(allLoggedInUsers) ||
      !isEmptyObjectOrFalsey(currentUser)
    ) {
      /* Redirect home if user is already logged in */
      history.push("/home");
    } else {
      /* Use key in params to ping endpoint for demo account */
      fetchDemoUser();
    }
  }, []);

  return (
    <></>
  );
}
