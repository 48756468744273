import { startOfHour, addHours } from "date-fns";

export const DARK_PURPLE = "#6177d9";
export const AVAILABILITY = "AVAILABILITY";
export const PANEL_CONTENT_AVAILABILITY = "PANEL_CONTENT_AVAILABILITY";
export const CONFETTI_DURATION = 4500;

// Variables should be deleted below. Saving incase we need to revert changes
export const WELCOME = "WELCOME";
export const CHOOSE_THEME = "CHOOSE_THEME";
export const FULL_PRODUCT = "FULL_PRODUCT";
export const CMD_K_INSTRUCTION = "CMD_K_INSTRUCTION";
export const COMMAND_CENTER_SECTION = "COMMAND_CENTER_SECTION";
export const CMD_K_REMINDER = "CMD_K_REMINDER";
export const FLASH_SHORTCUTS = "FLASH_SHORTCUTS";
export const GO_TO_CONFERENCING = "CONFERENCING";
export const CONFERENCING_SETTING = "CONFERENCING_SETTING";
export const COPIED_AVAILABILITY_TOAST = "COPIED_AVAILABILITY_TOAST";
export const TIME_TRAVEL = "TIME_TRAVEL";
export const AVAILABILITY_GIF = "AVAILABILITY_GIF";
export const PASTE_IN_EMAIL = "PASTE_IN_EMAIL";
export const TIME_TRAVEL_GIF = "TIME_TRAVEL_GIF";
export const AVAILABILITY_TIME_TRAVEL_REMINDER =
  "AVAILABILITY_TIME_TRAVEL_REMINDER";
export const NLP_DEMO = "NLP_DEMO";
export const THANK_YOU_PAGE = "THANK_YOU_PAGE";
export const PERSONAL_ONBOARDING = "PERSONAL_ONBOARDING";
export const CUSTOMIZE_PERSONAL_LINKS = "CUSTOMIZE_PERSONAL_LINKS";
export const DOWNLOAD_PAGE = "DOWNLOAD_PAGE";
// Variables should be deleted above.

export const EXAMPLE_SLOT = {
  isTemporary: true,
  isAvailability: true,
  eventStart: addHours(startOfHour(new Date()), 2),
  index: 0,
  eventEnd: addHours(startOfHour(new Date()), 3),
  rbcEventEnd: addHours(startOfHour(new Date()), 3),
  status: AVAILABILITY,
  raw_json: { status: AVAILABILITY },
  id: "example",
};

export const LIGHT_MODE_VIDEO_CONFERENCING_GIF =
  "https://onboardinggifs.s3.us-west-1.amazonaws.com/f_and_v_cropped.gif";
export const LIGHT_MODE_AVAILABILITY =
  "https://onboardinggifs.s3.us-west-1.amazonaws.com/availabilty-dark-cropped.gif";
export const LIGHT_MODE_TIME_TRAVEL =
  "https://onboardinggifs.s3.us-west-1.amazonaws.com/time-travel-cropped.gif";
export const LIGHT_MODE_NLP =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/nlp-create-event.gif";
export const TEAM_PHOTO =
  "https://vimcal-app-images.s3.amazonaws.com/team-may-14-2023.gif";

export const TOAST_DURATION = 2000;

export const VIMCAL_EA_TESTIMONIALS: ReadonlyArray<{
  name: string;
  role: string;
  profilePicture: string;
  quote: string;
}> = [
  {
    name: "Kristy McDonough",
    role: "Operations Lead at Perfect Venue",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_ea_testimonial/Kristy.jpeg",
    quote:
      "Vimcal EA simplifies my job, saves me time, and streamlines my work. The event template makes scheduling repeat events effortless, and the availability feature is a big help when booking anything with multiple people in or outside of our company. I highly recommend Vimcal EA to anyone who wants to make scheduling or booking calendar events easier than ever before!",
  },
  {
    name: "Nicole Schafer",
    role: "Event Producer at Miwa Events",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_ea_testimonial/Nicole.jpeg",
    quote:
      "I'm now probably saving an hour a day on otherwise meaningless activity. That alone makes Vimcal EA worthwhile.",
  },
  {
    name: "Eryn Anitavi",
    role: "CEO at Sapphire Partners",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_ea_testimonial/Eryn.jpg",
    quote:
      "I like to support productivity tools, especially when the company and their team is as awesome as the folks at Vimcal. There are dozens of calendar tools out there but at the end of the day...they just do it better. And my prediction is that they eventually overtake Calendly, so that's where I put my vote.",
  },
  {
    name: "Jane Hermina",
    role: "Executive Assistant at Athena",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_ea_testimonial/Jane+Hermina.jpeg",
    quote:
      "Vimcal EA has revolutionized my scheduling experience with its incredibly user-friendly interface, making it a breeze to coordinate appointments for my client. I love its seamless functionality and sleek design!",
  },
  {
    name: "Cortney H.",
    role: "Executive Assistant to the CEO at Zapier",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_ea_testimonial/Cortney.jpeg",
    quote:
      "Scheduling internationally would take me 10 to 15 minutes. Using Vimcal probably takes me less than 2.",
  },
  {
    name: "Alexa Watkins",
    role: "Executive Assistant at Aescape",
    profilePicture:
      "https://vimcal-app-images.s3.us-east-1.amazonaws.com/Alexa.jpeg",
    quote: `To all of my EAs out there, if you haven't looked at Vimcal yet, please hear me out, this tool has cut the amount of time I spent on my exec's calendar in half. And it's made for us by one of us: Thank you Vimcal for making my work life easier: Every time I "select availability" and "copy to clipboard" I high five you mentally. You're my unsung hero.`,
  },
];

export const VIMCAL_TESTIMONIALS: ReadonlyArray<{
  name: string;
  profilePicture: string;
  role: string;
  quote: string;
}> = [
  {
    name: "Dick Costolo",
    role: "Managing Partner, 1 Advisors",
    profilePicture:
      "https://vimcal-app-images.s3.amazonaws.com/dickCostolo.jpg",
    quote:
      "@Vimcal, call it Superhuman for calendar, new favorite productivity tool",
  },
  {
    name: "Ryan Hoover",
    role: "Founder & Investor at Weekend Fund",
    profilePicture: "https://vimcal-app-images.s3.amazonaws.com/ryanHoover.jpg",
    quote:
      "The workflows and tools we use at @weekendfund: Spark, Slack, Airtable, Zapier, Vimcal, Zoom, and AngelList",
  },
  {
    name: "Alex MacCaw",
    role: "CEO, Reflect",
    profilePicture: "https://vimcal-app-images.s3.amazonaws.com/alexmccaw.jpg",
    quote:
      "I just got onboarded to @vimcal and I'm blown away. Easily the best calendar I've used. They've also solved the awkward calendly link handoff.",
  },
  {
    name: "Nik Sharma",
    role: "CEO, Sharma Brands",
    profilePicture: "https://vimcal-app-images.s3.amazonaws.com/nikSharma.jpg",
    quote:
      'Vimcal is the "Superhuman of Calendars" as most people call it. My favorite feature is the ability to quickly share available times for calls/meetings.',
  },
  {
    name: "Yuri Sagalov",
    role: "Managing Partner at Wayfinder Ventures",
    profilePicture: "https://vimcal-app-images.s3.amazonaws.com/yuri.jpg",
    quote: `My favorite calendar just keeps getting better. 6 days from feature request to built and shipped! I absolutely love this pace of iteration by @vimcal. `,
  },
  {
    name: "Hampus Jakobsson",
    profilePicture: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_testimal/Hampus+Jakobsson.png",
    role: "General Partner at Pale blue dot",
    quote: `There are few products that give me so much joy as @vimcal. Every single time I get an email from them, I stop what I am doing to open it.`,
  },
  {
    name: "Scott Chacon",
    role: "Cofounder of GitHub",
    profilePicture: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_testimal/Scott.jpeg",
    quote: `If you use calendars, try out @vimcal. It's amazing. I'm delighted by little things every day.`,
  },
  {
    name: "Adora Cheung",
    profilePicture: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_testimal/Adora.jpeg",
    role: "Cofounder of Instalab",
    quote: `Life is short, use @vimcal. I started using this when I was a Partner at YC, and I continue to use it today as a Founder. It's the only productivity tool I can't work without`,
  },
  {
    name: "Zach Ware",
    profilePicture: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/vimcal_testimal/Zach.jpeg",
    role: "Managing Partner at Freehouse Capital Partners",
    quote: `I'm not a @vimcal investor (ugh) but I am a voracious user. You should be too. So happy that I bought it for my whole team.`,
  },
];
