import React, { useEffect, useState } from "react";
import { CustomSelect } from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import { customMenuStyle, getReactSelectBaseStyle } from "../../../select/styles";
import { useSelector } from "react-redux";
import CircleWithColor from "../../../circleWithColor";
import {
  createColorValueAndLabel,
} from "../../../../services/googleColors";
import {
  getAllColorLabelIds,
  getColorLabelColorID,
  getColorLabelHexColor,
  getColorLabelLabel,
} from "../../../colorSelector/colorLabelFunctions";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";
import { Trash } from "react-feather";

export default function ColorLabelRow({
  index,
  allColorLabels,
  colorLabel,
  onChangeColor,
  onChangeText,
  onClickTrash,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const colorID = getColorLabelColorID(colorLabel);
  const label = getColorLabelLabel(colorLabel);

  const allExistingColorIDs = getAllColorLabelIds(allColorLabels);
  const options = createColorValueAndLabel().filter((option) => {
    if (option.value === "0") {
      return false; // never show default
    }
    return (
      !allExistingColorIDs.includes(option.value) || option.value === colorID
    );
  });

  return (
    <div className="flex items-center mb-4 hoverable-visibility-parent">
      <CustomSelect
        components={{ DropdownIndicator }}
        overrideStyles={getReactSelectBaseStyle({
          controlWidth: 64,
          isDarkMode,
          showBorder: true,
          menuListStyle: customMenuStyle({ width: 180, zIndex: 5 }),
        })}
        tabSelectsValue={false}
        isSearchable={true}
        tabIndex={11}
        className={""}
        classNamePrefix="dark-mode"
        value={{
          label: <CircleWithColor color={getColorLabelHexColor(colorLabel)} />,
          value: colorID,
        }}
        onChange={(colorOption) => onChangeColor({ index, colorOption })}
        options={options}
        alwaysShowIndicator={true}
      />

      <input
        className="default-input-field height-34px width-300px-important ml-3"
        value={label}
        onChange={(e) => {
          const text = getInputStringFromEvent(e);
          onChangeText({ index, text });
        }}
        placeholder={`E.g. "1:1" or "Internal"`}
      />

      <Trash
        size={16}
        className="hoverable-visibility-child hoverable-secondary-text-color ml-3"
        onClick={() => onClickTrash(index)}
      />
    </div>
  );
}
