import classNames from "classnames";
import React from "react";


export default function DynamicSplitLayout({
  leftContent = <div></div>,
  rightContent = <div></div>,
  direction = "horizontal",
  className = "",
  leftPanelClassName = "",
  rightPanelClassName = "",
}) {
  const containerClasses = classNames(
    "flex",
    "md:h-full",
    "w-full",
    "overflow-y-auto",
    "max-h-screen",
    "flex-col",
    {
      "md:flex-row": direction === "horizontal",
      "md:flex-col": direction !== "horizontal",
      "overflow-x-hidden": direction === "horizontal",
    },
    className,
  );

  const panelClasses = classNames(
    "flex-1",
    "flex",
    "items-center",
    "justify-center",
    "border",
    "border-gray-200",
  );

  return (
    <div className={containerClasses}>
      <div className={classNames(panelClasses, leftPanelClassName)}>
        {leftContent}
      </div>
      <div className={classNames(panelClasses, rightPanelClassName)}>
        {rightContent}
      </div>
    </div>
  );
}
