import React, { useEffect } from "react";
import OnboardingSaveButton from "./onboardingSaveButton";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { ONBOARD_STEP, trackOnboarding } from "../../lib/tracking/onboardingTracking";
import SelectAnswer from "./selectAnswer";
import Mousetrap from "mousetrap";
import { BUTTON_TYPE } from "../../services/appFunctions";
import { trackEvent } from "../tracking";
import { getUserToken } from "../../lib/userFunctions";
import { EA_COUNT_OPTIONS } from "./sharedFunctions";

const THIS_STEP = ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS;

interface NumberOfExecutiveAssistantsProps {
  onClickNext: () => void
  numberOfExecutiveAssistants: string
  setNumberOfExecutiveAssistants: React.Dispatch<React.SetStateAction<string>>
}

export default function NumberOfExecutiveAssistants({
  onClickNext,
  numberOfExecutiveAssistants,
  setNumberOfExecutiveAssistants,
}: NumberOfExecutiveAssistantsProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const onClickSave = async () => {
    if (!numberOfExecutiveAssistants) {
      return;
    }

    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: THIS_STEP,
    });

    trackEvent({
      category: "onboarding_questionnaire",
      action: "select_number_of_executive_assistants",
      label: numberOfExecutiveAssistants,
      userToken: getUserToken(currentUser),
    });

    onClickNext();
  };

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: THIS_STEP,
    });

    return () => {
      Mousetrap.reset();
    };
  }, [numberOfExecutiveAssistants]);

  return (
    <div className="onboarding-container min-height-500px">
      <div className="section-title">How many EAs are at your company?</div>
      <div className="flex flex-col mt-8 mb-3 gap-3 width-250">
        {EA_COUNT_OPTIONS.map((option) => {
          return (
            <SelectAnswer
              key={option}
              label={option}
              onClick={() => setNumberOfExecutiveAssistants(option)}
              isSelected={numberOfExecutiveAssistants === option}
              buttonType={BUTTON_TYPE.RADIO_BUTTON}
            />
          );
        })}
      </div>

      <OnboardingSaveButton
        buttonText="Continue"
        onClick={onClickSave}
        isDisabled={!numberOfExecutiveAssistants}
      />
    </div>
  );
}
