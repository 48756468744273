import classNames from "classnames";
import React from "react";

interface SettingsPaneProps extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  contentProps?: React.HTMLProps<HTMLDivElement>
  children: React.ReactNode
  title: React.ReactNode
}

export default function SettingsPane({
  className,
  contentProps: { className: contentClassName, ...contentProps } = {},
  children,
  title,
  ...props
}: SettingsPaneProps) {
  return (
    <div className={classNames("flex flex-col gap-5", className)} {...props}>
      <div className="font-size-20 font-normal select-none">{title}</div>
      <div
        className={classNames("flex-grow overflow-x-hidden overflow-y-auto h-0", contentClassName)}
        {...contentProps}
      >
        {children}
      </div>
    </div>
  );
}
