import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function HoverPortal({
  children,
  className,
  portalChildren,
  portalClassName,
  portalDomNode,
  scrollElement,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (isHovered) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isHovered]);

  const renderPortalElement = () => {
    if (
      !portalDomNode ||
      !divRef.current ||
      !isHovered
    ) {
      return <></>;
    }

    return (
      createPortal(
        <div
          className={portalClassName}
          style={{
            left: divRef.current.getBoundingClientRect().x,
            top: divRef.current.getBoundingClientRect().y + 25,
          }}
        >
          {portalChildren}
        </div>,
        portalDomNode,
      )
    );
  };

  return (
    <div
      className={className}
      onMouseEnter={() => {
        if (!isHovered) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (isHovered) {
          setIsHovered(false);
        }
      }}
      ref={divRef}
    >
      {children}
      {renderPortalElement()}
    </div>
  );
}
