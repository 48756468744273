import Lottie from "lottie-react";
import React from "react";
import PersonalLinkAnimationLightJSON from "./resources/personalLinkAnimationLight.json";
import PersonalLinkAnimationDarkJSON from "./resources/personalLinkAnimationDark.json";
import { useSelector } from "react-redux";

export default function PersonalLinkAnimation() {
	const isDarkMode = useSelector((state) => state.isDarkMode);
	return (
		<Lottie animationData={isDarkMode ? PersonalLinkAnimationDarkJSON : PersonalLinkAnimationLightJSON} loop={true} />
	);
}
