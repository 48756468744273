import { getUserToken } from "../../lib/userFunctions";
import { useBackendSubscriptionStore, useHasBillingBeenFetched, useStripePaymentMethods, useStripeSubscriptions } from "../../services/stores/finance";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import { useCallback, useEffect, useState } from "react";
import { isMemberAdmin, isMemberOwner } from "./sharedFunctions";
import { getDefaultPaymentFromSubscription } from "../../lib/groupSchedulingFunctions";
import { InviteParams, inviteTeamMembers } from "../queries/teamPlans";
import { useSelector } from "react-redux";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { getErrorResponseMessage, isErrorResponse } from "../../services/api";
import { handleError } from "../../services/commonUsefulFunctions";
import { getBackendSubscriptionCoreSeatCount, getBackendSubscriptionEASeatCount, getBackendSubscriptionIsFixedSeating } from "../../services/accessors/backendSubscription";

/**
 * Triggers a broadcast to fetch the billing info from the backend if it has not already been fetched.
 * Returns a boolean for whether the billing info is fetched and available.
 */
export function useGetBillingInfo() {
  const hasBillingBeenFetched = useHasBillingBeenFetched(state => state.hasBillingBeenFetched);

  useEffect(() => {
    if (!hasBillingBeenFetched) {
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    }
  }, [hasBillingBeenFetched]);

  return hasBillingBeenFetched;
}

export function useIsCurrentUserAdmin(): boolean {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const allLoggedInUsers = useAllLoggedInUsers(state => state.allLoggedInUsers);
  const hasBillingBeenFetched = useGetBillingInfo();

  if (!hasBillingBeenFetched) {
    return false;
  }

  if (isEmptyObjectOrFalsey(teamPlan)) {
    // The current user will be an admin if they create a plan.
    return true;
  }

  const adminTokens = new Set(teamPlan.active_users.filter(isMemberAdmin).map(u => getUserToken(u)));
  return allLoggedInUsers.some(u => adminTokens.has(getUserToken(u)));
}

export function useIsCurrentUserOwner(): boolean {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const allLoggedInUsers = useAllLoggedInUsers(state => state.allLoggedInUsers);
  const hasBillingBeenFetched = useGetBillingInfo();

  if (!hasBillingBeenFetched) {
    return false;
  }

  if (isEmptyObjectOrFalsey(teamPlan)) {
    // The current user will be the owner if they create a plan.
    return true;
  }

  const ownerTokens = new Set(teamPlan.active_users.filter(isMemberOwner).map(u => getUserToken(u)));
  return allLoggedInUsers.some(u => ownerTokens.has(getUserToken(u)));
}

/**
 * If this is true, then no changes can be made to the team plan/subscription that affects
 * the amount due each cycle. This includes:
 * - Adding/removing seats
 * - Upgrading the subscription from monthly to yearly
 */
export function useIsFixedSeating(): boolean {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const hasBillingBeenFetched = useGetBillingInfo();
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);

  if (!hasBillingBeenFetched) {
    return false;
  }

  if (!isEmptyObjectOrFalsey(teamPlan)) {
    return teamPlan.is_fixed_seating;
  }

  if (!backendSubscription) {
    // If there's no subscription, then there's nothing that can be updated.
    return true;
  }

  return getBackendSubscriptionIsFixedSeating(backendSubscription);
}

/**
 * Returns the number of open seats in the team plan, grouped by seat type.
 */
export function useOpenSeats(): { openVimcalSeats: number, openVimcalEASeats: number } {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const hasBillingBeenFetched = useGetBillingInfo();
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);
  const masterAccount = useMasterAccount(state => state.masterAccount);

  if (!hasBillingBeenFetched) {
    return {
      openVimcalEASeats: 0,
      openVimcalSeats: 0,
    };
  }

  if (isEmptyObjectOrFalsey(teamPlan)) {
    if (!backendSubscription || isEmptyObjectOrFalsey(masterAccount)) {
      // No subscription or team plan to check, so assume no open seats.
      return {
        openVimcalSeats: 0,
        openVimcalEASeats: 0,
      };
    } else {
      // The team plan hasn't been created, so the current user is the only user on the subscription.
      const totalVimcalSeats = getBackendSubscriptionCoreSeatCount(backendSubscription);
      const totalVimcalEASeats = getBackendSubscriptionEASeatCount(backendSubscription);
      const isEA = isUserMaestroUser(masterAccount);
      const occupiedVimcalSeats = isEA ? 0 : 1;
      const occupiedVimcalEASeats = isEA ? 1 : 0;
      return {
        openVimcalSeats: totalVimcalSeats - occupiedVimcalSeats,
        openVimcalEASeats: totalVimcalEASeats - occupiedVimcalEASeats,
      };
    }
  }

  let vimcalUserCount = 0;
  let vimcalEAUserCount = 0;

  const summarizeCounts = (member: TeamPlanMember) => {
    if (member.scheduling_for_others) {
      vimcalEAUserCount++;
    } else {
      vimcalUserCount++;
    }
  };

  teamPlan.active_users.forEach(summarizeCounts);
  teamPlan.pending_users.forEach(summarizeCounts);

  return {
    openVimcalSeats: teamPlan.non_ea_seat_count - vimcalUserCount,
    openVimcalEASeats: teamPlan.ea_seat_count - vimcalEAUserCount,
  };
}

export function useDefaultPaymentFromSubscription() {
  const stripePaymentMethods = useStripePaymentMethods(state => state.stripePaymentMethods);
  const stripeSubscriptions = useStripeSubscriptions(state => state.stripeSubscriptions);
  return getDefaultPaymentFromSubscription({ stripePaymentMethods, stripeSubscriptions });
}

export function useSubmitInviteMembers() {
  const currentUser = useSelector(state => state.currentUser);
  const isMounted = useIsMounted();
  const [isSubmittingInvites, setIsSubmittingInvites] = useState(false);
  const setTeamPlan = useTeamPlan(state => state.setTeamPlan);

  const triggerInviteTeamMembers = useCallback(async (params: InviteParams) => {
    if (!currentUser) {
      return;
    }

    setIsSubmittingInvites(true);
    try {
      const response = await inviteTeamMembers(currentUser, params);
      if (!isMounted.current) {
        return;
      }

      if (isEmptyObjectOrFalsey(response) || !("team_plan" in response)) {
        throw new Error("Unexpected empty response");
      }

      if (isErrorResponse(response)) {
        const errorMessage = getErrorResponseMessage(response);
        throw new Error(errorMessage);
      }

      setTeamPlan(response.team_plan);
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    } catch (e) {
      handleError(e);

      // Re-throw the error so .then can be reliably used for successful calls.
      throw e;
    } finally {
      setIsSubmittingInvites(false);
    }
  }, [currentUser]);

  return { inviteTeamMembers: triggerInviteTeamMembers, isSubmittingInvites };
}
