global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */
import {
  SLOTS_PLAIN_TEXT,
  SLOTS_PLAIN_TEXT_URL,
  SLOTS_RICH_TEXT,
  LINK_ALONE,
} from "../lib/vimcalVariables";

export const LIGHTGRAY = "#E0E0E0";
export const DARKGRAY = "#4A4A4A";
export const MEDIUM_GRAY = "#A1A1A1";
export const DEFAULT_BLUE = "#176EFF";
export const LIGHT_BLUE = "#E0EBFD";
export const HOVER_BLUE = "#1D4ED8";

const FONT = "Raleway";
export const DEFAULT_FONT_COLOR = "#4E516A";
const FONT_WEIGHT_NORMAL = "400";
const SHADOW_BOX = "0px 2px 4px #E0E0E0";
const MARGIN_TOP_PREVIEW = 15;
const SIDE_PANEL_WORK_SPACE_WIDTH = "290px";
const BLUE_COLOR = "#1581ff";
const LIGHT_BLUE_COLOR = "#DEEBFF";
const FONT_WEIGHT_SEMI_BOLD = "600";
const NAV_BAR_HEIGHT = 50;
const WEEKLY_CALENDAR_TOOLBAR_HEIGHT = 84;
export const SAVE_BUTTON_HEIGHT = 44;
const ICON_COLOR_LIGHT_GRAY = "rgb(224, 224, 224)";
export const SELECT_AVAILABILITY_COLOR = "#50E3C2";
const SIDE_BAR_COLOR = "#FDFDFD";
const PRIMARY_CALENDAR_COLOR = "#5A69FC";
const MONTHLY_CALENDAR_MARGIN_TOP = "20px";
const ANIMATION_SPEED = "0.2s";
const SELECT_DAY_COLOR = "#98B9E9";
const TIME_ON_MOUSE_ENTER_BEFORE_EVENT = 150;
export const DEFAULT_FONT_SIZE_PX = "12px";
const EVENT_FORM_WIDTH = 320;
export const DARK_MODE_TEXT_COLOR = "rgba(255, 255, 255, 0.85)";
export const LIGHT_MODE_HOVER_OUTLINE = "#c2c2c2";

export const DARK_MODE_DIVDIER_COLOR = "#454555";
export const LIGHT_MODE_DIVIDER_COLOR = "#E5E7EB";
export const LIGHT_MODE_HINT_TEXT_COLOR = "#343548";
export const LIGHT_MODE_TILE_TEXT_COLOR = "#343548";
export const LIGHT_MODE_TILE_BACKGROUND_COLOR = "#DBE1ED";
export const DARK_MODE_DISABLED_TEXT_BUTTON_COLOR = "#7A7A81";
export const LIGHT_MODE_DISABLED_TEXT_BUTTON_COLOR = "#EAEAEA";
export const MODAL_BORDER_RADIUS = "14px";

export const MIN_HEIGHT = "34px";

export const DARK_MODE_MONTHLY_CALENDAR_AGENDA_BACKGROUND_COLOR = "#272833";
export const DARK_MODE_BACKGROUND_COLOR = "#191921";
const DARK_MODE_MONTHLY_AGENDA_BACKGROUND_COLOR = "#21242A"; //"#26242D";
const DARK_MODE_RIGHT_SIDE_BOX_SHADOW = "23px 11px 28px 0 #1d1e21";
export const DARK_MODE_COMMAND_CENTER_BACKGROUND_COLOR = "#444754";
export const DARK_MODE_HOVER_BACKGROUND_COLOR = "#44475A";
export const DARK_MODE_COMMAND_CENTER_TEXT_COLOR = "#d6d9dc";
export const DARK_MODE_CELL_BORDER = "#333646";
const DARK_MODE_WEEKLY_CALENDAR_TEXT_COLOR = "#959ba0";
export const DARK_MODE_MODAL_BACKGROUND_COLOR = "#2F3140";
export const DARK_MODE_MODAL_BACKGROUND_COLOR_WITH_OPACITY =
  "rgba(36, 38, 52, 0.87)"; // for glassy feel
export const LIGHT_MODE_MODAL_BACKGROUND_COLOR_WITH_OPACITY =
  "rgba(255, 255, 255, 0.6)";
export const MODAL_BLUR = "blur(20px)";
export const DARK_MODE_MODAL_TEXT_COLOR = "rgba(255, 255, 255, 0.85)";
const AGENDA_HOVER_BLUE = "#1967D2";
const AGENDA_REGULAR_BLUE = "#1581ff";
const AGENDA_CLICKED_ON_DATE_COLOR = "#80ABEA";
const AGENDA_HOVER_GREY = "#6A707D";
const WEEKLY_CALENDAR_LIGHT_MODE_BACKGROUND = "#DBE1ED";
const SEARCH_HOVERED_COLOR = "#F4F6FB";
const DARK_MODE_RED_COLOR = "#CF667A";
const DARK_MODE_BLUE_COLOR = "#039BE5";
export const FADED_LIGHT_BLUE = "#F2FAFF";
export const PERSONAL_LINK_LOCAL_DATA = "personalLink";
export const ALL_SLOTS_TAKEN = "All slots are booked.";
export const AVAILABILITY_PERSONAL_SLOT_CONTAINER_ID = "personal_link_id";
export const AVAILABILITY_GROUP_VOTE_CONTAINER_ID =
  "AVAILABILITY_GROUP_VOTE_CONTAINER_ID";
export const DARK_PURPLE = "#859BF3";
export const LIGHT_PURPLE = "#F5F6FB";
export const HOVER_PURPLE = "#E9ECF8";
export const FADED_GREY_TEXT_COLOR = "rgba(32,33,36,0.38)"; // taken from faded events on gcal
export const FADED_GREY_TEXT_COLOR_DARK_MODE = "rgb(255,255,255, 0.5)";
export const FADED_WHITE_MODE_COLOR_FOR_DIMMED_PAST_EVENTS = "#848383";
export const AGENDA_FORMAT_JS_DATE = "MMMM d, yyyy";
export const ISO_DATE_FORMAT = { representation: "date" }; //https://date-fns.org/v2.19.0/docs/formatISO
export const ZOOM_PERSONAL_LINK = "personal_link";
export const ZOOM_UNIQUE = "unique_meeting_ids";
export const MINUTE_IN_DAY = 1440;
export const SECOND_IN_MS = 1000;
export const TYPING_DELAY = 0.1 * SECOND_IN_MS; // a fast human can type at ~100 milliseconds per character
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const DAY_IN_MS = 86400000; // a day is 86,400,000 milliseconds long (24 * 60 * 60 * 1000)
export const NON_EA_MONTHLY_PRICE = 15;
export const NON_EA_ANNUAL_PRICE = 150;
export const NON_EA_ANNUAL_PER_MONTH_PRICE = 12.5;
export const EA_MONTHLY_PRICE = 75;
export const EA_ANNUAL_PRICE = 750;
export const EA_ANNUAL_PER_MONTH_PRICE = 62.5;
export const DOLLARS_IN_CENTS = 100;
export const EVENT_CONTAINER_WIDTH = 360;
export const HOVER_UPCOMING_EVENTS = "HOVER_UPCOMING_EVENTS";
export const HOVER_HINT_BACKGROUND_COLOR = "#44475A";
export const DARK_MODE_INSIDE_MODAL_BORDER_COLOR = "#454554";
export const DARK_MODE_SECONDARY_TEXT_COLOR = "#9196A2";
export const LIGHT_MODE_SECONDARY_TEXT_COLOR = "#91959C";
export const ARROW_LEFT = "left";
export const ARROW_RIGHT = "right";
export const ARROW_UP = "up";
export const ARROW_DOWN = "down";
export const ISO_8601 = "ISO 8601";

export const ADD_SLOT = "ADD_SLOT";
export const DELETE_SLOT = "DELETE_SLOT";
export const UPDATE_SLOT = "UPDATE_SLOT";

export const OPEN_SIDE_MENU_PANEL_HOTKEY = "[";
export const TOGGLE_RIGHT_PANEL_HOTKEY = "]";

export const FADED_LIGHT_GREY = "#F2F2F2";

export const COMMAND_CENTER_PNG = {
  DARK_MODE: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/dark-mode-command-center.png",
  LIGHT_MODE: "https://vimcal-app-images.s3.us-east-1.amazonaws.com/light-mode-command-center.png",
} as const;

// 7am inclusive, 8pm exclusive
export const DEFAULT_AM_BAR = 9;
export const DEFAULT_PM_BAR = 19;
export const COMMAND_KEY = "⌘";
export const DOWNLOAD_INTEL_DESKTOP_LINK =
  "https://vimcal-production.s3.us-west-1.amazonaws.com/Vimcal-1.0.34.dmg";
// "https://www.vimcal.com/downloads/mac-intel";
export const DOWNLOAD_M1_DESKTOP_LINK =
  "https://vimcal-m1.s3.us-west-1.amazonaws.com/Vimcal-1.0.34-arm64.dmg";
// "https://www.vimcal.com/downloads/mac-m1";
export const DOWNLOAD_PC_DESKTOP_LINK =
  "https://vimcal-pc.s3.amazonaws.com/Vimcal-1.0.33.exe";
export const DOWNLOAD_IOS_LINK =
  "https://apps.apple.com/us/app/vimcal-calendar/id1608841561";
export const PERSONAL_LINK_LABEL_MORE_INFO_SCREENSHOT =
  "https://vimcal-app-images.s3.amazonaws.com/label-personallinks.png";
export const SLOTS_PERSONALIZE_SCREENSHOT =
  "https://vimcal-app-images.s3.amazonaws.com/personalize_screen_shot.png";
// "https://www.vimcal.com/downloads/windows";
export const PERSONAL_LINK_NICKNAME_MORE_INFO_SCREENSHOT = "https://vimcal-app-images.s3.us-east-1.amazonaws.com/personal_link_nickname.png";
export const CLICK_ALL_PERMISSIONS_SCREENSHOT = "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/click-all-permissions.png";
export const PERMISSIONS_SCREENSHOT_WHITE = "https://vimcal-app-images.s3.us-east-1.amazonaws.com/permissions.png";

export const REPLACEMENT_TEXT_OBJECT = {
  monday: "monday",
  monda: "monday",
  mond: "monday",
  tuesday: "tuesday",
  tuesda: "tuesday",
  tuesd: "tuesday",
  tues: "tuesday",
  wednesday: "wednesday",
  wednesda: "wednesday",
  wednesd: "wednesday",
  wednes: "wednesday",
  wedne: "wednesday",
  wedn: "wednesday",
  thursday: "thursday",
  thursda: "thursday",
  thursd: "thursday",
  thurs: "thursday",
  thur: "thursday",
  friday: "friday",
  frida: "friday",
  frid: "friday",
  saturday: "saturday",
  saturda: "saturday",
  saturd: "saturday",
  satur: "saturday",
  satu: "saturday",
  sunday: "sunday",
  sunda: "sunday",
  sund: "sunday",
} as const;

export const REACT_SELECT_BORDER_RADIUS = "7px"; // default is 4px

// the default background color that google passes back for color id 14. Don't use this color though since it doesn't actually match up
export const DEFAULT_GOOGLE_BACKEND_BACKGROUND_COLOR = "#9fe1e7";

export const DARK_MODE_THEME = "dark";
export const LIGHT_MODE_THEME = "light";

// LocalStorage variables
export const GENERIC_ERROR_MESSAGE = "Oh no! Looks like an error has occurred.";
export const FAILED_TO_DELETE_EVENT_MESSAGE = "Failed to delete event";
export const FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE =
  "Failed to delete events";
export const SUCCESSFUL_DELETE_MULTIPLE_EVENTS_MESSAGE = "Events deleted";

export const TOP_BAR_HEIGHT = 30;
export const PC_CONTROL_KEY = "Ctrl";
// Changing this to https://vimcal-email-assets.s3-us-west-1.amazonaws.com/zoom_logo.png causes
// event creation to fail with: Cannot have multiple conferences of type Hangout and NamedHangout.
export const zoomImageURL =
  "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V";
export const aroundCoImageURL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/aroundCo.png";
export const hangoutIconURL =
  "https://lh5.googleusercontent.com/proxy/bWvYBOb7O03a7HK5iKNEAPoUNPEXH1CHZjuOkiqxHx8OtyVn9sZ6Ktl8hfqBNQUUbCDg6T2unnsHx7RSkCyhrKgHcdoosAW8POQJm_ZEvZU9ZfAE7mZIBGr_tDlF8Z_rSzXcjTffVXg3M46v";
export const microsoftTeamsIconURL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/1200px-Microsoft_Office_Teams_(2018%E2%80%93present).svg.png";
export const whereByIconURL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/whereby-icon.png";
export const SKYPE_ICON_URL =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/skype-consumer-50.png";
export const SKYPE_FOR_BUSINESS_ICON =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/skype-for-business-50px.png";
export const GO_TO_MEETING_ICON_URL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/gotomeeting.png";
export const WEB_EX_ICON_URL =
  "https://lh3.googleusercontent.com/9U711X53DF1_qMLFgJJbotGEm3gEC0ZlLYjUH2qUD7jjhN-qR2ae-s4SdAtnA2UxnejSfWe1nPC5YlI2D9f8C4YffKk8QFbWwSTvUldjWSzOg8HUW5TzcxRl_OdzlwTZ9pQf4F65Uvv86z6Si3hMDyjY6nVobGrczeDS-DFqyFk9qapzAxFXXw1gJjoG1L8up8oinzXtsWljdEaowUzkph1ferImqmsYIhbSx0eh6hJ3JifdWaUg_XzrFgIfCMW7AfRyaylsEHM1L5mIgsAnCxEvc2Jvw6bBtsbtWtVInPKQ_Q5GypfX09tvr8WMI31I8EZrLZBlxQi0M5uPfivvcJhOwMN9ypUgV7JjBqeez-r5xw672wNRw24o02mIqv5gIKDOS9DtmroXSxN609duBN7t8SEfkN6HV7GOMi94TMyR7oBdFAiKLiDR1fD1d1F0J9pCOqq0lVndo7EEsCpygmK-hHhy2a_m-FjMrft7JiTo-ecqlSNkEX8jmU8WgvnPY-g0ZuSML1YTHLAWjFU7T6FCLYw3SzrPEpl1yLZL1z9iVlCUi2_Mr4drM2eUTIeZyKO9-mPi6nBaiN_UPSXnyf12DCdCByURdX7YekzuTlNfElYbF4DBuo5OU5DgqLlcSlLlx44DIn4dfhGt-6Fnzr8FqyYwxVKbU_Nx7Rk8h8fAdUnamZRHxPAH=s96-no";
export const UBER_CONFERENCING_ICON_URL =
  "https://lh3.googleusercontent.com/0Ddoyt7CUI-UdwCAHFjd4Jzo60CkPqoVPOOlAZExnuPg9gK8G_ytz51mhOwLHhOa1PYrAaT8U6P8oIJ28EeHvZUJg0EnxIQV6UBnlgtZZdP0vas92-75Js1ov9qPohiL_-fvOv2aeA=s150-no";
export const BLUE_JEANS_ICON_URL =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/bluejeans.png";
export const RING_CENTRAL_ICON_URL =
  "https://netstorage.ringcentral.com/dpw/apps/7gufiGT3T3CCuCP37hMDaQ/7a8e3042-67a9-477b-8300-39ecfddaef6a.png";
export const PHONE_MEETING_ICON =
  "https://lh3.googleusercontent.com/pw/ACtC-3edDKxOEVFC6VET34hGZ6N-7AmFdJdyQEB9ua8lMUECnxPNAwwYif1hddMg5WuoE1YHvxNPkq7fkPNS1ZoszY6htoZ0ZdKxvOOdD8GwNPI2htuq9kLB4syX_3OAbmI8Qs5tKAPqBxjP7MRF-kREpw=s64-no";
export const CHIME_MEETING_ICON =
  "https://pics.freeicons.io/uploads/icons/png/10223485731536125450-512.png";
export const WHATS_APP_ICON =
  "https://lh3.googleusercontent.com/proxy/zI_doeNVnb8WEpnWL6_GmKFTbDVbfmVZ6vseOC5YC9ObACzZ_Eyyp15Cw5MMLvS3Yr0kOasyO5Lgrsh6F3qZdy2201dywiO2rOx0kjm2GbxY_mS8SPs6rlBrSKMSNkX4Xq4b0_6H=s0-d";
export const ZOOM_CIRCULAR_ICON =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/zoom_rounded_circle_cut.png";
export const GOOGLE_ICON =
  "https://vimcal-email-assets.s3-us-west-1.amazonaws.com/google_icon.png";
export const VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/logos/circle%402x-dark.png";
export const VIMCAL_LOGO_CLEAR_BACKGROUND =
  "https://vimcal-app-images.s3.amazonaws.com/vimcal_logo_clear_background.png";
export const VIMCAL_LOGO_SVG =
  "https://vimcal-email-assets.s3.us-west-1.amazonaws.com/logos/circle%402x-dark.png";
export const VIMCAL_IOS_QR_CODE =
  "https://vimcal-app-images.s3.amazonaws.com/download-ios-qr.png";
export const SOPHIE_BOOK_PERSONAL_ONBOARD_LINK =
  "https://book.vimcal.com/p/2afcb2c987892faf5e497b50";
export const DYLAN_BOOK_PERSONAL_ONBOARD_LINK =
  "https://book.vimcal.com/p/c29q5WaMbaC4zQZ7";
export const SOPHIE_PROFILE_PICTURE =
  "https://vimcal-app-images.s3.amazonaws.com/sophie_profile_onboarding_pic.png";
export const DYLAN_HEAD_SHOT =
  "https://vimcal-app-images.s3.amazonaws.com/dylan-head-shot.jpg";
export const MICROSOFT_LOGO =
  "https://vimcal-app-images.s3.amazonaws.com/Microsoft_logo.svg.png";

export const NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX = {
  PAT: "rnhWMJugUtH1QrG5",
  JAN: "zHg4dKpLPnM5Fo85",
} as const;

export const ROUND_ROBIN_ONBOARDING_TOKENS = [
  NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX.PAT, // Pat Magno. order here matters. using pickToken() in roundRobinScheduler, we pick the first element 70% of the time
  NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX.JAN, // Jan Cruz.
  // "c29q5WaMbaC4zQZ7", // Dylan McLeod. Do not use for now.
] as const;

export const ROUND_ROBIN_EA_ONBOARDING_TOKENS = [
  "2wsMg3jFRvu6PsPQ", // Jan Cruz.
  "f0fca50c7474732002773026", // Dylan McLeod.
] as const;

// Sections of event form
export const EVENT_FORM_SUMMARY = "EVENT_FORM_SUMMARY";
export const EVENT_FORM_ALL_DAY = "EVENT_FORM_ALL_DAY";
export const EVENT_FORM_START_DATE_INPUT = "EVENT_FORM_START_DATE_INPUT";
export const EVENT_FORM_START_TIME_INPUT = "EVENT_FORM_START_TIME_INPUT";
export const EVENT_FORM_END_DATE_INPUT = "EVENT_FORM_END_DATE_INPUT";
export const EVENT_FORM_END_TIME_INPUT = "EVENT_FORM_END_TIME_INPUT";
export const EVENT_FORM_REPEAT = "EVENT_FORM_REPEAT";
export const EVENT_FORM_TIME_ZONE = "EVENT_FORM_TIME_ZONE";
export const EVENT_FORM_LOCATION = "EVENT_FORM_LOCATION";
export const EVENT_FORM_CONFERENCE = "EVENT_FORM_CONFERENCE";
export const EVENT_FORM_ROOM = "EVENT_FORM_ROOM";
export const EVENT_FORM_ATTENDEE = "EVENT_FORM_ATTENDEE";
export const EVENT_FORM_GUESTS_CAN = "EVENT_FORM_GUESTS_CAN";
export const EVENT_FORM_COLOR = "EVENT_FORM_COLOR";
export const EVENT_FORM_EMAIL = "EVENT_FORM_EMAIL";
export const EVENT_FORM_NOTIFICATION = "EVENT_FORM_NOTIFICATION";
export const EVENT_FORM_BUSY_FREE = "EVENT_FORM_BUSY_FREE";
export const EVENT_FORM_DEFAULT_AVAILABILITY =
  "EVENT_FORM_DEFAULT_AVAILABILITY";
export const EVENT_FORM_DESCRIPTION = "EVENT_FORM_DESCRIPTION";
export const HIGH_LIGHT_COLOR = "#FFFCF5";
export const CAN_NOT_UPDATE_EVENT_MESSAGE =
  "You don't have permission to edit this event.";
export const DESKTOP_TITLE_BAR_HEIGHT = 41;
export const BLUE_BUTTON = "blue-button";
export const RED_BUTTON = "red-button";
export const WHITE_BUTTON = "white-button";
export const WHITE_BUTTON_WITHOUT_FOCUS = "white-button-without-focus";
export const BLUE_BUTTON_WITHOUT_FOCUS = "blue-button-without-focus";
export const DEFAULT_BUTTON = "default-button";
export const MICROSOFT_TEAMS_CONFERENCING = "teams.microsoft";
export const WHEREBY_CONFERENCING = "whereby.";
export const GO_TO_MEETING_CONFERENCING = ".gotomeet";
export const WEB_EX_CONFERENCING = "webex.";
export const UBER_CONFERENCING = "uberconference.";
export const BLUE_JEANS_CONFERENCING = "bluejeans.";
export const ZOOM_CONFERENCING = "zoom.us";
export const GOOGLE_CONFERENCING = "google.com";
export const RING_CENTRAL_CONFERENCING = "ringcentral.";
export const CHIME_CONFERENCING = "chime.aws";
export const SKYPE_CONSUMER_CONFERENCING = "skype";
export const WHATS_APP_CONFERENCING = "wa.me";
export const LYNC_BUSINESS_CONFERENCING = ".lync.";
export const GOOGLE_HANGOUT = "hangout.google.com";
export const GOOGLE_MEET = "meet.google.com";
export const AROUND_CO_MEET = "around.co";
export const WEEKEND_KEY = "workWeek";

export const BACKEND_DAY_VIEW = "day";
export const BACKEND_4_DAY_VIEW = "4_day";
export const BACKEND_WEEK = "week";
export const BACKEND_MONTH = "month";
export const ROLLING_SEVEN_DAY = "rolling_7_day";
export const BACKEND_YEAR = "year";
export type BackendCalendarView = typeof BACKEND_DAY_VIEW | typeof BACKEND_4_DAY_VIEW | typeof BACKEND_WEEK | typeof ROLLING_SEVEN_DAY | typeof BACKEND_MONTH | typeof BACKEND_YEAR

export const DEFAULT_PRIMARY_CALENDAR_COLOR = "#039be5";
export const DEFAULT_PRIMARY_CALENDAR_FADED_COLOR = "#B2E1F8";
export const OUT_OF_RANGE_MONTH_COLOR = "#F8F9FA";
export const OUT_OF_RANGE_MONTH_COLOR_DARK_MODE = "rgba(255, 255, 255, 0.03)";
export const MENU_BAR_TITLE_COUNTDOWN = "title_countdown";
export const MENU_BAR_LOGO = "logo";
export const DEFAULT_COLOR_ID = "14";
export const DEFAULT_EVENT_COLOR = "#039be5";
export const MENU_BAR_TITLE_POLICY = "MENU_BAR_TITLE_POLICY";
export const LOGGED_IN_ACCOUNTS = "loggedInAccounts";
export const DEBOUNCE_BACKEND_TIMER = "DEBOUNCE_BACKEND_TIMER";
export const ELECTRON_APP_VERSION_NUMBER = "ELECTRON_APP_VERSION_NUMBER";
export const GMAIL = "GMAIL";
export const GOOGLE_CALENDAR = "GOOGLE_CALENDAR";
export const GOOGLE_DRIVE = "GOOGLE_DRIVE";
export const NEW_GOOGLE_MEET = "NEW_GOOGLE_MEET";
export const UTC_TIME_ZONE = "UTC";
export const BACKEND_TEXT = "text";
export const BACKEND_PERSONAL_LINK = "personal_link";
export const BACKEND_GROUP_VOTE_LINK = "group_vote"; // TODO: change to actual value
export const BACKEND_AVAILABILITY = "availability";

export const SELECTED_BLUE = "rgb(21, 129, 255)";
export const LIGHT_FADED_GRAY = "#F1F3F4";
export const AVAILABLE_DAY_MOMENT_FORMAT = "dddd LL";
export const COPY_AVAILABILITIES_TRIGGER_HOT_KEY = "y";
export const PERSONAL_LINK_DETAIL_CONTAINER = "PERSONAL-LINK-DETAIL-CONTAINER";
export const LAST_SELECTED_DAY = "LAST_SELECTED_DAY";
export const NEEDS_ACTION_STATUS = "needsAction";
export const ACCEPTED_STATUS = "accepted";

export const PHOTOS = {
  LOADING_SCREEN_PHOTO:
    "https://vimcal-app-images.s3.amazonaws.com/cloud-mountains.jpg",
} as const;

export const INVITEE_NAME_BLOCK = "{{Invitee_name}}";
export const INVITEE_COMPANY_NAME = "{{Company_name}}";
export const ALL_BOOKING_VARIABLES = {
  INVITEE_NAME_BLOCK: INVITEE_NAME_BLOCK,
  INVITEE_COMPANY_NAME: INVITEE_COMPANY_NAME,
} as const;

// for hotkey multiple upcoming events
export const TYPE_CONFERENCING = "TYPE_CONFERENCING";
export const TYPE_LOCATION = "TYPE_LOCATION";
export const TYPE_UP_NEXT = "TYPE_UP_NEXT";
export const TYPE_EMAIL_RUNNING_LATE = "TYPE_EMAIL_RUNNING_LATE";
export const TYPE_EMAIL = "TYPE_EMAIL";

export const PLAIN_TEXT = "plain_text";
export const BOOKING_LINK = "link";
export const HIDE_SHOW_AVAILABILITY_DETAILS = "HIDE_SHOW_AVAILABILITY_DETAILS";
export const MEETING_DETAIL_ID = "MEETING_DETAIL_ID";

export const SLOTS_TEXT_SELECTION = "SLOTS_TEXT_SELECTION";
export const SLOTS_AVAILABILITY_SELECTION = {
  value: BOOKING_LINK,
  label: "Slots",
} as const;
export const PERSONAL_LINK_SELECTION = {
  value: BACKEND_PERSONAL_LINK,
  label: "Personal Links",
} as const;
export const GROUP_VOTE_SELECT_OPTION = {
  value: BACKEND_GROUP_VOTE_LINK,
  label: "Group Vote",
} as const;

export const SLOTS_SELECT_TYPE_PLAIN_TEXT = {
  value: SLOTS_PLAIN_TEXT,
  label: "Plaintext only",
} as const;

export const SLOTS_BACKGROUND_COLOR = {
  DEFAULT: SELECT_AVAILABILITY_COLOR,
  DIMMED: "#2E5D52",
} as const;

export const SLOTS_SELECT_TYPE_TEXT_URL = {
  value: SLOTS_PLAIN_TEXT_URL,
  label: "Plaintext + URL",
} as const;
export const SLOTS_SELECT_TYPE_HYPER_LINKED = {
  value: SLOTS_RICH_TEXT,
  label: "Hyperlinked Text",
} as const;
export const SLOTS_LINK_ALONE = {
  value: LINK_ALONE,
  label: "URL only",
} as const;

export const AVAILABILITY_ADDITIONAL_DETAILS_ID =
  "AVAILABILITY_ADDITIONAL_DETAILS_ID";

export const COMPONENT_NOTIFICATION = "glow-component-has-updated-notification";
export const AVAILABILITY_HYPERLINK_TOGGLE = "AVAILABILITY-HYPERLINK-TOGGLE";
export const SLOTS_STYLE_PICKER_ID = "SLOTS_STYLE_PICKER_ID";
export const SLOTS_TEXT_FORMAT_PICKER_ID = "SLOTS_TEXT_FORMAT_PICKER_ID";
export const DEFAULT_GUEST_PERMISSIONS = "DEFAULT_GUEST_PERMISSIONS";

export const DURATION_UNITS = [
  "m",
  "min",
  "mins",
  "minute",
  "minutes",
  "hr",
  "hrs",
  "hour",
  "hours",
] as const;

// event attributes:
export const IS_EDITABLE = "isEditable";
export const EVENT_CALENDAR_EMAIL = "eventCalendarEmail";
export const SELF_ATTENDING_STATUS = "selfAttendingStatus";
export const ONLY_PERSON_ATTENDING_REST_DECLINED =
  "isUserOnlyPersonWhoAcceptedAndRestDeclined";
export const FADED_COLOR = "fadedColor";
export const NORMAL_COLOR = "normalColor";
export const DARK_MODE_FADED_COLOR = "darkModeFadedColor";
export const MERGED_EVENTS = "mergedEvents";
export const RSVP_WITHOUT_SHOW_AS = "RSVP_WITHOUT_SHOW_AS"; // only relevent to outlook. This is what the user actually rsvped and not including

// use this for portal
export const getModalBackgroundColorWithoutGlassy = (isDarkMode: boolean) => {
  return isDarkMode ? "rgba(36, 38, 52, 1)" : "white";
};

export const getModalBackgroundColor = (isDarkMode?: boolean) => {
  // return isDarkMode ? "#21222E" : "white"; // "#21222E is the color of DARK_MODE_MODAL_BACKGROUND_COLOR_WITH_OPACITY with no opacity against our default background
  return isDarkMode ? DARK_MODE_MODAL_BACKGROUND_COLOR_WITH_OPACITY : "white";
};

export const DEFAULT_RECENT_TIMEZONES = [
  {
    isTimeZone: true,
    value: "America/Los_Angeles",
    label: "(PDT) America/Los Angeles",
  },
  {
    isTimeZone: true,
    value: "America/New_York",
    label: "(EDT) America/New York",
  },
  {
    isTimeZone: true,
    value: "America/Chicago",
    label: "(CDT) America/Chicago",
  },
  { isTimeZone: true, value: "Europe/London", label: "(BST) Europe/London" },
  { isTimeZone: true, value: "Europe/Paris", label: "(CEST) Europe/Paris" },
  { isTimeZone: true, value: "Asia/Hong_Kong", label: "(HKT) Asia/Hong Kong" },
  { isTimeZone: true, value: "Asia/Shanghai", label: "(CST) Asia/Shanghai" },
  { isTimeZone: true, value: "Asia/Singapore", label: "(SGT) Asia/Singapore" },
  { isTimeZone: true, value: "Asia/Tokyo", label: "(JST) Asia/Tokyo" },
  { isTimeZone: true, value: "Asia/Kolkata", label: "(IST) Asia/Kolkata" },
  { isTimeZone: true, value: "Universal", label: "(UTC) Universal" },
] as const;

export const SAFARI = "Safari";
export const OPERA = "Opera";
export const CHROME = "Chrome";
export const FIREFOX = "Firefox";
export const INTERNET_EXPLORE = "IE";

export const EXCLUDED_DOMAINS = [
  "google.com",
  "gmail.com",
  "outlook.com",
  "hotmail.com",
  "yahoo.com",
  "hey.com",
  "live.com",
  "icloud.com",
] as const;

export const TYPE_JPEG = "image/jpeg";
export const TYPE_PNG = "image/png";
export const TYPE_TEXT_CALENDAR = "text/calendar";

export const SET_DISAPPEARING_NOTIFICATION_MESSAGE =
  "SET_DISAPPEARING_NOTIFICATION_MESSAGE";

export const SET_GLOBAL_SHORT_CUT_SUGGESTION =
  "SET_GLOBAL_SHORT_CUT_SUGGESTION";

export const CONFERENCING_OPTIONS_OBJECT = {
  [ZOOM_CONFERENCING]: "Zoom Meeting",
  [AROUND_CO_MEET]: "Around Meeting",
  [GOOGLE_CONFERENCING]: "Google Meet",
  [MICROSOFT_TEAMS_CONFERENCING]: "Microsoft Teams Meeting",
  [GO_TO_MEETING_CONFERENCING]: "GoToMeeting",
  [WEB_EX_CONFERENCING]: "Webex Meeting",
  [UBER_CONFERENCING]: "UberConference Meeting",
  [BLUE_JEANS_CONFERENCING]: "BlueJeans Meeting",
  [WHEREBY_CONFERENCING]: "Whereby Meeting",
  [RING_CENTRAL_CONFERENCING]: "RingCentral Meeting",
  [CHIME_CONFERENCING]: "Chime Meeting",
  [SKYPE_CONSUMER_CONFERENCING]: "Skype",
  [LYNC_BUSINESS_CONFERENCING]: "Skype For Business",
  [WHATS_APP_CONFERENCING]: "WhatsApp",
} as const;

export const CONFERENCING_ICONS = {
  [ZOOM_CONFERENCING]: zoomImageURL,
  [AROUND_CO_MEET]: aroundCoImageURL,
  [GOOGLE_CONFERENCING]: hangoutIconURL,
  [MICROSOFT_TEAMS_CONFERENCING]: microsoftTeamsIconURL,
  [GO_TO_MEETING_CONFERENCING]: GO_TO_MEETING_ICON_URL,
  [WEB_EX_CONFERENCING]: WEB_EX_ICON_URL,
  [UBER_CONFERENCING]: UBER_CONFERENCING_ICON_URL,
  [BLUE_JEANS_CONFERENCING]: BLUE_JEANS_ICON_URL,
  [WHEREBY_CONFERENCING]: whereByIconURL,
  [RING_CENTRAL_CONFERENCING]: RING_CENTRAL_ICON_URL,
  [CHIME_CONFERENCING]: CHIME_MEETING_ICON,
  [SKYPE_CONSUMER_CONFERENCING]: SKYPE_ICON_URL,
  [LYNC_BUSINESS_CONFERENCING]: SKYPE_FOR_BUSINESS_ICON,
  [WHATS_APP_CONFERENCING]: WHATS_APP_ICON,
} as const;

export const ACTION_MODE = {
  CREATE_AVAILABILITY: "CREATE_AVAILABILITY",
  UPSERT_EVENT: "UPSERT_EVENT",
  OUTLOOK_PROPOSE_TIME: "OUTLOOK_PROPOSE_TIME",
} as const;

export const ZOOM_SEARCH_STRINGS = [
  "zoom.us/u/",
  "zoom.us/j/",
  // "zoom.us/s/", // user needs to open this in browser
  "zoom.us/w/",
  "zoom.us/my/",
  "zoom.us/wc/",
  "/join",
] as const;

export const CONFERENCING_OPTIONS_ARRAY = Object.keys(
  CONFERENCING_OPTIONS_OBJECT,
) as (keyof typeof CONFERENCING_OPTIONS_OBJECT)[];

export const DEFAULT_FREE_TRIAL_LENGTH = 7;

export const DISCARD_WARNING_MODAL_WIDTH = 425;

export const eventFormSectionHotKeysIndex = {
  EVENT_FORM_SUMMARY: "g+s",
  EVENT_FORM_ALL_DAY: "g+q",
  EVENT_FORM_START_DATE_INPUT: "g+d",
  EVENT_FORM_START_TIME_INPUT: "g+t",
  EVENT_FORM_END_TIME_INPUT: "g+h",
  EVENT_FORM_END_DATE_INPUT: "g+o",
  EVENT_FORM_REPEAT: "g+w",
  EVENT_FORM_TIME_ZONE: "g+t",
  EVENT_FORM_LOCATION: "g+l",
  EVENT_FORM_CONFERENCE: "g+v",
  EVENT_FORM_ROOM: "g+r",
  EVENT_FORM_ATTENDEE: "g+a",
  EVENT_FORM_GUESTS_CAN: "g+b",
  EVENT_FORM_COLOR: "g+c",
  EVENT_FORM_EMAIL: "g+g",
  EVENT_FORM_NOTIFICATION: "g+u",
  EVENT_FORM_BUSY_FREE: "g+i",
  EVENT_FORM_DEFAULT_AVAILABILITY: "g+p",
  EVENT_FORM_DESCRIPTION: "g+k",
} as const;

export const SLOTS_DETAIL_FIELDS = {
  TITLE_DETAIL: "title",
  DURATION_DETAIL: "duration",
  CONFERENCING_DETAIL: "conferencing",
  ATTENDEES_DETAIL: "attendees",
  SELECTED_CALENDAR_DETAIL: "selected_calendar",
  LOCATION_DETAIL: "location",
  DESCRIPTION_DETAIL: "description",
} as const;

export const LAST_USED_SLOTS_HOT_KEYS = "enter";

export const DIGIT_TO_NUMBER_OBJECT = {
  a: 1,
  an: 1,
  zero: 0,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
  "twenty one": 21,
  "twenty-one": 21,
  "twenty two": 22,
  "twenty-two": 22,
  "twenty three": 23,
  "twenty-three": 23,
  "twenty four": 24,
  "twenty-four": 24,
  "twenty five": 25,
  "twenty-five": 25,
  "twenty six": 26,
  "twenty-six": 26,
  "twenty seven": 27,
  "twenty-seven": 27,
  "twenty eight": 28,
  "twenty-eight": 28,
  "twenty nine": 29,
  "twenty-nine": 29,
  "thirty one": 31,
  "thirty-one": 31,
  "thirty two": 32,
  "thirty-two": 32,
  "thirty three": 33,
  "thirty-three": 33,
  "thirty four": 34,
  "thirty-four": 34,
  "thirty five": 35,
  "thirty-five": 35,
  "thirty six": 36,
  "thirty-six": 36,
  "thirty seven": 37,
  "thirty-seven": 37,
  "thirty eight": 38,
  "thirty-eight": 38,
  "thirty nine": 39,
  "thirty-nine": 39,
  "forty one": 41,
  "forty-one": 41,
  "forty two": 42,
  "forty-two": 42,
  "forty three": 43,
  "forty-three": 43,
  "forty four": 44,
  "forty-four": 44,
  "forty five": 45,
  "forty-five": 45,
  "forty six": 46,
  "forty-six": 46,
  "forty seven": 47,
  "forty-seven": 47,
  "forty eight": 48,
  "forty-eight": 48,
  "forty nine": 49,
  "forty-nine": 49,
  "fifty one": 51,
  "fifty-one": 51,
  "fifty two": 52,
  "fifty-two": 52,
  "fifty three": 53,
  "fifty-three": 53,
  "fifty four": 54,
  "fifty-four": 54,
  "fifty five": 55,
  "fifty-five": 55,
  "fifty six": 56,
  "fifty-six": 56,
  "fifty seven": 57,
  "fifty-seven": 57,
  "fifty eight": 58,
  "fifty-eight": 58,
  "fifty nine": 59,
  "fifty-nine": 59,
  "ninety-five": 95,
  "ninety five": 95,
  twenty: 20,
  thirty: 30,
  forty: 40,
  fifty: 50,
  sixty: 60,
  ninty: 90,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
} as const;

const StyleConstants = {
  lightGray: LIGHTGRAY,
  darkGray: DARKGRAY,
  mediumGray: MEDIUM_GRAY,
  font: FONT,
  defaultFontColor: DEFAULT_FONT_COLOR,
  fontWeightNormal: FONT_WEIGHT_NORMAL,
  shadowBox: SHADOW_BOX,
  previewEventMarginTop: MARGIN_TOP_PREVIEW,
  sidePanelWorkSpaceWidth: SIDE_PANEL_WORK_SPACE_WIDTH,
  blueColor: BLUE_COLOR,
  fontWeightSemiBold: FONT_WEIGHT_SEMI_BOLD,
  lightBlueColor: LIGHT_BLUE_COLOR,
  navbarHeight: NAV_BAR_HEIGHT,
  weeklyCalendarToolbarHeight: WEEKLY_CALENDAR_TOOLBAR_HEIGHT,
  saveButtonHeight: SAVE_BUTTON_HEIGHT,
  iconColorLightGray: ICON_COLOR_LIGHT_GRAY,
  selectAvailabilityColor: SELECT_AVAILABILITY_COLOR,
  sideBarConstant: SIDE_BAR_COLOR,
  primaryCalendarColor: PRIMARY_CALENDAR_COLOR,
  monthlyCalendarMarginTop: MONTHLY_CALENDAR_MARGIN_TOP,
  animationSpeed: ANIMATION_SPEED,
  selectDayColor: SELECT_DAY_COLOR,
  timeOnMouseEnterBeforeEvent: TIME_ON_MOUSE_ENTER_BEFORE_EVENT,
  eventFormWidth: EVENT_FORM_WIDTH,
  darkModeTextColor: DARK_MODE_TEXT_COLOR,
  darkModeBackgroundColor: DARK_MODE_BACKGROUND_COLOR,
  darkModeMonthlyAgendaBackgroundColor:
    DARK_MODE_MONTHLY_AGENDA_BACKGROUND_COLOR,
  darkModeRightSideBoxShadow: DARK_MODE_RIGHT_SIDE_BOX_SHADOW,
  darkModeCommandCenterBackgroundColor:
    DARK_MODE_COMMAND_CENTER_BACKGROUND_COLOR,
  darkModeHoverBackgroundColor: DARK_MODE_HOVER_BACKGROUND_COLOR,
  darkModeCommandCenterTextColor: DARK_MODE_COMMAND_CENTER_TEXT_COLOR,
  darkModeCellBorder: DARK_MODE_CELL_BORDER,
  darkModeWeeklyCalendarTextColor: DARK_MODE_WEEKLY_CALENDAR_TEXT_COLOR,
  darkModeModalBackgroundColor: DARK_MODE_MODAL_BACKGROUND_COLOR,
  darkModeModalTextColor: DARK_MODE_MODAL_TEXT_COLOR,
  agendaHoverBlue: AGENDA_HOVER_BLUE,
  agendaRegularBlue: AGENDA_REGULAR_BLUE,
  agendaHoverGrey: AGENDA_HOVER_GREY,
  weeklyCalendarLightModeBackground: WEEKLY_CALENDAR_LIGHT_MODE_BACKGROUND,
  agendaClickedOnDateColor: AGENDA_CLICKED_ON_DATE_COLOR,
  searchHoveredColor: SEARCH_HOVERED_COLOR,
  darkModeRedColor: DARK_MODE_RED_COLOR,
  darkModeBlueColor: DARK_MODE_BLUE_COLOR,
  selectedDayBlue: "rgb(21, 129, 255)",
} as const;

export default StyleConstants;
