import { format, parseISO } from "date-fns";
import { convertToTimeZone } from "../services/commonUsefulFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { getDateTimeFormatLowercaseAMPM } from "./dateFunctions";

export function getOutstandingSlotPlaceholderTitle(outstandingSlot: OutstandingSlot) {
  if (isEmptyArrayOrFalsey(outstandingSlot?.time_slots)) {
    return "Untitled";
  }
  return `Untitled | ${outstandingSlot.time_slots.length} Slots`;
}

export function getOutstandingSlotTitle(outstandingSlot: OutstandingSlot) {
  return outstandingSlot.slot_title;
}

export function getOutstandingSlotSlug(outstandingSlot: OutstandingSlot) {
  return outstandingSlot.slug;
}

export function convertOutstandingSlotCreatedAt(outstandingSlot: OutstandingSlot, timeZone: string) {
  return convertToTimeZone(parseISO(outstandingSlot.created_at), { timeZone });
}

interface ParseOutstandingSlotCreatedAtOptions {
  dateFieldOrder: DateFieldOrder
  format24HourTime: boolean
  outstandingSlot: OutstandingSlot
  timeZone: string
}

export function parseOutstandingSlotCreatedAt({ dateFieldOrder, format24HourTime, outstandingSlot, timeZone }: ParseOutstandingSlotCreatedAtOptions) {
  const createdAt = convertOutstandingSlotCreatedAt(outstandingSlot, timeZone);

  const timeFormat = getDateTimeFormatLowercaseAMPM(format24HourTime);
  const creationDate = format(createdAt, dateFieldOrder === "DMY" ? "d LLL" : "LLL d");
  const creationTime = format(createdAt, timeFormat);
  return { creationDate, creationTime };
}

export function getTimeSlotIndex(timeSlot: OutstandingSlotTimeSlot) {
  return timeSlot.index;
}

export function convertTimeSlotStartTime(timeSlot: OutstandingSlotTimeSlot, timeZone: string): Date {
  return convertToTimeZone(parseISO(timeSlot.start_time), { timeZone: timeZone });
}

export function convertTimeSlotEndTime(timeSlot: OutstandingSlotTimeSlot, timeZone: string): Date {
  return convertToTimeZone(parseISO(timeSlot.end_time), { timeZone: timeZone });
}
