import React from "react";
import {
  SEND_EMAIL_AS,
  getDefaultEmailSendAsOption,
} from "../../../../lib/settingsFunctions";
import { BACKEND_SETTINGS_NAMES } from "../../../../lib/vimcalVariables";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import { CustomSelect } from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getReactSelectBaseStyle } from "../../../select/styles";
import { getMasterAccountSettings } from "../../../../lib/userFunctions";

const OPTIONS = [
  { value: SEND_EMAIL_AS.EA, label: "Me" },
  { value: SEND_EMAIL_AS.EXECUTIVE, label: "Exec" },
];
export default function SettingAlwaysSendAsEA() {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const defaultSendAsEA = getDefaultEmailSendAsOption({ masterAccount });
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const setMasterAccount = useMasterAccount((state) => state.setMasterAccount);

  const onChange = (option) => {
    const updatedSettings = {
      [BACKEND_SETTINGS_NAMES.DEFAULT_SELF_AS_SENDER]: option.value,
    };
    const updatedMasterAccountSetting = {
      ...(getMasterAccountSettings(masterAccount) ?? {}),
      ...updatedSettings,
    };
    const updatedMasterAccount = {
      ...masterAccount,
      ...{ settings: updatedMasterAccountSetting },
    };
    setMasterAccount(updatedMasterAccount);
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="secondary-text-color default-font-size">{`Default sender when emailing attendees on exec's invite`}</div>
      <CustomSelect
        isSearchable={false}
        components={{ DropdownIndicator }}
        className={classNames(isDarkMode ? "dark-mode-select" : "")}
        classNamePrefix="dark-mode-modal"
        value={{
          value: defaultSendAsEA,
          label: defaultSendAsEA === SEND_EMAIL_AS.EA ? "Me" : "Exec",
        }}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          controlWidth: 80,
        })}
        options={OPTIONS}
        onChange={onChange}
      />
    </div>
  );
}
