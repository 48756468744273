import { Quill } from "react-quill";
import { localConsoleError } from "../../services/testFunctions";

export const EMAIL_REMINDER_BLOT_NAME = "emailReminderVariable";
export const BLOCK_TO_P_BLOT_NAME = "blockToP";

const Embed = Quill.import("blots/embed");
// Create a configurable block format
const Block = Quill.import("blots/block");
class EmailReminderVariableBlot extends Embed {
  static blotName = EMAIL_REMINDER_BLOT_NAME;
  static className = EMAIL_REMINDER_BLOT_NAME;
  static tagName = "div";

  static create(innerText) {
    const node = super.create();
    node.setAttribute("class", "reminder-email-variable");
    node.innerText = innerText;

    return node;
  }


  static value(node) {
    return node.innerText;
  }
}
class ConfigurableBlock extends Block {
  static tagName = "div"; // default to div
  static setBlockTag(tagName) {
    if (tagName !== "p" && tagName !== "div") {
      localConsoleError("Invalid tag name. Using 'div' as fallback.");
      tagName = "div";
    }
    ConfigurableBlock.tagName = tagName;
    // Re-register with new tag name
    Quill.register("blots/block", ConfigurableBlock, true);
  }
}

// Export a configuration function
export const configureBlockFormat = (useParagraphTags = false) => {
  ConfigurableBlock.setBlockTag(useParagraphTags ? "p" : "div");
};

Quill.register(`formats/${EMAIL_REMINDER_BLOT_NAME}`, EmailReminderVariableBlot);
