import React from "react";
import { DOWNLOAD_IOS_LINK, VIMCAL_IOS_QR_CODE } from "../../services/globalVariables";

export default function DownloadIOS() {
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="mt-4 default-font-size">
        Download from {" "}
        <a href={DOWNLOAD_IOS_LINK}>App Store link</a>
      </div>

      <img
        alt=""
        width="400px"
        height="400px"
        className="mt-4 mb-4"
        src={VIMCAL_IOS_QR_CODE}
      />
    </div>
  );
}
