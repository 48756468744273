import classNames from "classnames";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { getTentativeBackgroundStyle } from "../../lib/styleFunctions";
import useDefaultEventStyle from "../../services/customHooks/useDefaultEventStyle";
import { getModalBackgroundColor } from "../../services/globalVariables";
import { AttendeeGroup, calculateEventPosition, getLeft, getWidth, isEventWithinWorkHours, isSmallInternalEvent, ROW_HEIGHT, SCHEDULING_ASSISTANT_COLORS, SchedulingAssistantItem, WorkHours } from "./helperFunctions";
import { isEmptyObjectOrFalsey, isNotEmptySet } from "../../services/typeGuards";
import { getUserEmail } from "../../lib/userFunctions";
import { hasEventPreventDefault, hasStopEventPropagation, isInt } from "../../services/commonUsefulFunctions";
import schedulingAssistantBroadcast from "../../broadcasts/schedulingAssistantBroadcast";
import { SCHEDULING_ASSISTANT_BROADCAST_VALUES } from "../../lib/broadcastValues";

const EVENT_CLASS_NAME = classNames(
  "absolute default-font-size flex items-center justify-start",
  "whitespace-nowrap overflow-hidden",
  "rounded-md",
  "z-index-1",
);

interface SchedulingAssistantEventProps {
  calendarColor: string
  date: Date
  groups: AttendeeGroup[]
  isShowingWorkHoursOnly: boolean
  item: SchedulingAssistantItem
  user: User
  workHours: WorkHours
}

export default function SchedulingAssistantEvent({ calendarColor, date, groups, isShowingWorkHoursOnly, item, workHours, user }: SchedulingAssistantEventProps) {
  const { event } = item;
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const isInternalEvent = isSmallInternalEvent({ item, userEmail: getUserEmail(user) });

  const getBackgroundColorAndBorder = () => {
    if (isInternalEvent) {
      if (isDarkMode) {
        return {
          backgroundColor: SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_BACKGROUND_COLOR_DARK_MODE,
          textColor: SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_DARK_MODE_TEXT_COLOR,
        };
      }
      return {
        backgroundColor: SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_BACKGROUND_COLOR_LIGHT_MODE,
        textColor: SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_LIGHT_MODE_TEXT_COLOR,
      };
    }
    return {
      backgroundColor: SCHEDULING_ASSISTANT_COLORS.DEFAULT_BACKGROUND_COLOR,
      textColor: SCHEDULING_ASSISTANT_COLORS.DEFAULT_TEXT_COLOR,
    };
  };
  const {
    backgroundColor,
    textColor,
  } = getBackgroundColorAndBorder();

  const determineCalendarStyle = useDefaultEventStyle({
    checkTagColor: true,
    isSchedulingAssistant: true,
    overrideColor: backgroundColor,
    overrideTextColor: textColor,
    treatNeedsActionAsAttending: true,
  });

  if (isShowingWorkHoursOnly && !isEventWithinWorkHours(event, workHours)) {
    return null;
  }

  const { top, left, width } = calculateEventPosition({
    item,
    groups,
    date,
    isShowingWorkHoursOnly,
    workHours,
  });

  const calendarStyle = determineCalendarStyle(event);

  const baseStyle: React.CSSProperties = {
    top,
    left,
    width,
    border: `2px solid ${getModalBackgroundColor(isDarkMode)}`,
    margin: "1px",
    color: calendarStyle.color,
    borderColor: calendarStyle.borderColor,
    height: ROW_HEIGHT,
  };

  // TODO: Should tentative events always have a lower z-index than busy events?
  // Otherwise the top summary row of events may look wrong.
  const eventStyle: React.CSSProperties = (() => {
    if (calendarStyle.background) {
      return {
        ...baseStyle,
        background: calendarStyle.background,
      };
    }

    return {
      ...baseStyle,
      backgroundColor: calendarStyle.backgroundColor,
    };
  })();

  return (
    <div
      className={classNames(EVENT_CLASS_NAME, "cursor-pointer", "select-none")}
      style={eventStyle}
      onClick={(e) => {
        if (isEmptyObjectOrFalsey(e)) {
          return;
        }
        hasStopEventPropagation(e);
        hasEventPreventDefault(e);
        const {
          event,
        } = item;
        if (isInt(e.pageX) && isInt(e.pageY)) {
          schedulingAssistantBroadcast.publish(SCHEDULING_ASSISTANT_BROADCAST_VALUES.SET_PREVIEW_EVENT, {
            event,
            clickLocation: { X: e.pageX, Y: e.pageY },
          });
        }
      }}
    >
      {item.title}
    </div>
  );
}

interface SchedulingAssistantSummaryEventProps {
  calendarColor: string
  date: Date
  end: Date
  isShowingWorkHoursOnly: boolean
  isTentative?: boolean
  start: Date
  workHours: WorkHours
  priorityAttendees: Set<string>
}

/**
 * An event at the top of the scheduling assistant, summarizing the availability of all selected attendees.
 */
export function SchedulingAssistantSummaryEvent({
  calendarColor,
  date,
  end,
  isShowingWorkHoursOnly,
  isTentative,
  start,
  workHours,
  priorityAttendees,
}: SchedulingAssistantSummaryEventProps) {
  const clampedStart = isBefore(start, startOfDay(date)) ? startOfDay(date) : start;
  const clampedEnd = isAfter(end, endOfDay(date)) ? endOfDay(date) : end;

  const width = getWidth({ date, end: clampedEnd, isShowingWorkHoursOnly, start: clampedStart, workHours });
  const left = getLeft({ date, isShowingWorkHoursOnly, start: clampedStart, workHours });

  if (width === 0) {
    return null;
  }

  const color = isNotEmptySet(priorityAttendees)
    ? SCHEDULING_ASSISTANT_COLORS.CRITICAL_EVENT_BACKGROUND_COLOR
    : SCHEDULING_ASSISTANT_COLORS.SUMMARY_EVENT_BACKGROUND_COLOR;

  const style: React.CSSProperties = {
    color,
    height: ROW_HEIGHT - 2,
    left,
    margin: "1px",
    top: 72,
    width,
  };

  if (isTentative) {
    style.background = getTentativeBackgroundStyle(color);
  } else {
    style.backgroundColor = color;
  }

  return <div className={classNames(EVENT_CLASS_NAME, "pointer-events-none")} style={style}></div>;
}
