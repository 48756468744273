import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { isEmptyArray } from "../../lib/arrayFunctions";

export default function TitleVariables({ containerClassName, variables }) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  if (isEmptyArray(variables)) {
    return null;
  }

  return (
    <div
      className={classNames(
        containerClassName ?? "",
        "more-information-modal",
        "default-font-color",
        isDarkMode ? "background-color-modal-background-color" : "bg-white"
      )}
    >
      <div>Available variables:</div>

      {variables.map((variable, index) => {
        return (
          <div key={`title-variables-${index}`} className="margin-top-five">
            {variable}
          </div>
        );
      })}
    </div>
  );
}
