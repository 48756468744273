import React                        from 'react';
import { OpenLink }                 from '../../services/commonUsefulFunctions';
import CopiableContentContainer     from '../copiableContentContainer';


const ExpandedHangoutInfo = (props) => (
  <div className="hangout-conference-container">
    {props.options.video && props.options.video.uri &&
      <div
        onClick={() => OpenLink(props.options.video.uri)}
        className="join-conference-link"
      >
        Join Hangouts Meet
      </div>
    }

    {props.options.video && props.options.video.label &&
      <CopiableContentContainer textToCopy={props.options.video.label} iconMarginTop={7}>
        <div className="hangout-link-subtext">
          {props.options.video.label}
        </div>
      </CopiableContentContainer>
    }

    {props.options.video &&
      <div className="hangout-label-text">
        Meeting ID
      </div>
    }

    {props.options.video && props.options.video.label &&
      <CopiableContentContainer textToCopy={props.options.video.label}>
        <div
          className="hangout-clickable-link-subtext cursor-pointer"
          onClick={() => OpenLink(props.options.video.label)}
        >
          {props.options.video.label}
        </div>
      </CopiableContentContainer>
    }

    {props.options.phone &&
      <div className="hangout-label-text">
        Phone Numbers
      </div>
    }

    {props.options.phone && props.options.phone.regionCode && props.options.phone.label &&
      <CopiableContentContainer textToCopy={`(${props.options.phone.regionCode}) ${props.options.phone.label}`} iconMarginTop={7}>
        <div className="hangout-link-subtext cursor-pointer">
          {`(${props.options.phone.regionCode}) ${props.options.phone.label}`}
        </div>
      </CopiableContentContainer>
    }

    {props.options.phone && props.options.phone.pin &&
      <CopiableContentContainer textToCopy={props.options.phone.pin} iconMarginTop={7}>
        <div className="hangout-link-subtext">
          {`PIN: ${props.options.phone.pin}`}
        </div>
      </CopiableContentContainer>
    }
  </div>
);

export default ExpandedHangoutInfo;
