import classNames from "classnames";
import React from "react";

export default function NewTag() {
  return (
    <div
      className={classNames(
        "font-size-12 font-weight-300",
        "flex items-center justify-center",
        "new-tag px-2.5 py-1.5",
        "rounded-md",
        "text-white"
      )}
    >
      New
    </div>
  );
}
