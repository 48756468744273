import React, { useState } from "react";
import DefaultSwitch from "../defaultSwitch";
import { useSelector } from "react-redux";
import classNames from "classnames";
import MoreInfoIcon from "../moreInfoIcon";
import { ExternalLink } from "react-feather";
import ShortcutHoverHint from "../shortcutHoverHint";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import {
  APP_SETTINGS,
  PREFERENCES_SETTINGS_ID,
} from "../../lib/vimcalVariables";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getInternalDomainAndEmails } from "../../lib/settingsFunctions";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { DARK_MODE_CELL_BORDER } from "../../services/globalVariables";

export default function IgnoreInternalConflicts({
  onChange,
  isIgnoreInternalConflicts,
  className,
  selectedUser,
  displayGoToSetting = true,
  moreInfoOverrideClassName,
}) {
  const [isShowingIgnoreInternalMoreInfo, setIsShowingIgnoreInternalMoreInfo] =
    useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onMouseEnterIcon = () => {
    setIsShowingIgnoreInternalMoreInfo(true);
  };
  const onMouseLeaveIcon = () => {
    setIsShowingIgnoreInternalMoreInfo(false);
  };
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const internalDomains = getInternalDomainAndEmails({
    masterAccount,
    user: selectedUser ?? currentUser,
  });
  const isDisabled = isEmptyArray(internalDomains);

  const renderIgnoreInternalConflictMoreInfoIcon = () => {
    return (
      <div
        className="ml-1 flex items-center justify-center gap-2"
        onMouseEnter={onMouseEnterIcon}
        onMouseLeave={onMouseLeaveIcon}
      >
        <MoreInfoIcon />
      </div>
    );
  };

  const renderGoToSetting = () => {
    if (!displayGoToSetting) {
      return null;
    }
    return (
      <ShortcutHoverHint
        below
        style={{ width: "200px", left: "-85px", backgroundColor: isDarkMode ? DARK_MODE_CELL_BORDER : "white" }}
        containerStyle={{height: "16px"}}
        title="Go to your settings to define what email domains and addresses constitute an 'internal' meeting"
        labelOverrideClassName={"line-height-18px-override"}
      >
        <ExternalLink
          size={15}
          className="ml-1 cursor-pointer clickable-icon"
          onClick={() => {
            layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
              initialContent: APP_SETTINGS.PREFERENCES,
              scrollToSettingContent: PREFERENCES_SETTINGS_ID.INTERNAL_DOMAINS,
              initialSettingsUser: selectedUser,
              initialSlotsSettingsUser: selectedUser,
            });
          }}
        />
      </ShortcutHoverHint>
    );
  };

  const renderIgnoreInternalConflictMoreInfo = () => {
    if (!isShowingIgnoreInternalMoreInfo) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute more-information-modal",
          isDarkMode ? "background-color-modal-background-color" : "bg-white",
          "top-72px",
          "width-300px-important",
          moreInfoOverrideClassName ?? ""
        )}
      >
        When toggled on, meetings can be booked over conflicting meetings you defined as "internal". This will still respect the conflicts of the teammates you invite as attendees.
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-between",
        className ?? ""
      )}
    >
      <div
        className={"availability-detail-section-text flex items-center gap-1"}
      >
        Ignore my internal conflicts
        {renderIgnoreInternalConflictMoreInfoIcon()}
        {renderGoToSetting()}
      </div>
      <DefaultSwitch
        tabIndex={-1}
        isChecked={isIgnoreInternalConflicts}
        onChange={(isIgnoreInternal) => {
          if (onChange) {
            onChange(isIgnoreInternal);
          }
        }}
        disabled={isDisabled}
      />
      {renderIgnoreInternalConflictMoreInfo()}
    </div>
  );
}
