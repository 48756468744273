import React from "react";
import AILogo from "../icons/aiLogo";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { FIND_TIME_TEXT_BOTTOM } from "../../services/elementIDVariables";

export default function FindTimesHoverableText({ onClick, className, id }) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <div
      className={classNames(
        "flex justify-end hover-container-secondary-text-color-fill cursor-pointer default-font-size duration-200 select-none",
        className ?? ""
      )}
      onClick={onClick}
      id={id ?? FIND_TIME_TEXT_BOTTOM}
    >
      <AILogo fillColor={isDarkMode ? "#8E8E8E" : "#ABABAB"} />
      <div className="ml-2">Find times</div>
    </div>
  );
}
