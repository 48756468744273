import React, { Component } from "react";
import FullAttendeeList from "./fullAttendeeList";
import { connect } from "react-redux";
import { EXPANDED_ATTENDEES_CONTAINER } from "../services/elementIDVariables";
import classNames from "classnames";

class EventAttendingList extends Component {
  render() {
    return <>{this.renderExtendedGuestAttendance()}</>;
  }

  //================
  // RENDER METHODS
  //================

  renderExtendedGuestAttendance() {
    const {
      attendees,
      organizer,
    } = this.props;

    return (
      <div
        id={EXPANDED_ATTENDEES_CONTAINER}
        className={classNames(
          "event-guest-attendance-list",
        )}
      >
        <FullAttendeeList
          attendees={attendees}
          organizer={organizer}
        />
      </div>
    );
  }

  openAttendeeList() {
    const {
      onClick,
      shouldShowGlobalKeyMap,
      hideGlobalKeyMap
    } = this.props;

    onClick();

    if (shouldShowGlobalKeyMap) {
      hideGlobalKeyMap();
    } 
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
  };
}

function mapStateToProps(state) {
  let { shouldShowGlobalKeyMap } = state;

  return { shouldShowGlobalKeyMap };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAttendingList);
