import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import Select, {
  type NamedProps as SelectProps,
  type OptionTypeBase,
  type StylesConfig,
} from "react-select";
import DropdownIndicator from "./dropDownIndicator";
import {
  defaultStyles,
  defaultDarkStyles,
  defaultDarkModalStyles,
  getReactSelectBaseStyle,
} from "./styles";

export type SelectOptionType<ValueType = string> = {
  label: React.ReactNode;
  menuLabel?: React.ReactNode;
  value: ValueType;
  __isNew__?: boolean
};

interface CustomSelectProps<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false
> extends Omit<SelectProps<OptionType, IsMulti>, "isMulti" | "styles"> {
  alwaysShowIndicator?: boolean;
  darkModeOverride?: boolean;
  lightModeOverride?: boolean;
  overrideStyles?: StylesConfig<OptionType, IsMulti>;
  hideIndicator?: boolean;
  innerRef?: React.Ref<Select<OptionType, IsMulti>>;
  isMulti: IsMulti;
  showBorder?: boolean;
  alwaysOpen?: boolean;
  isDisabled?: boolean;
}

/* Min props -> onChange, options, value */
/* Recommended -> pass in className.  Currently have not audited all the scss styling. */
export function CustomSelect<
  OptionType extends OptionTypeBase = SelectOptionType,
  IsMulti extends boolean = false
>({
  alwaysShowIndicator,
  className,
  components,
  darkModeOverride,
  lightModeOverride,
  hideIndicator,
  innerRef,
  onMenuClose,
  onMenuOpen,
  overrideStyles,
  showBorder,
  placeholder,
  onFocus,
  onBlur,
  isDisabled,
  ...props
}: CustomSelectProps<OptionType, IsMulti>) {
  /* Hooks */
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [isFocused, setIsFocused] = useState(false);

  /* Check if we have necessary props */
  if (!props?.onChange) {
    // TODO: Error handling here as we should have all of the above props
    return null;
  }

  const determineStyle = (
    classNamePrefix?: string | null,
  ): StylesConfig<OptionType, IsMulti> => {
    if (((!classNamePrefix || !isDarkMode) && !darkModeOverride) || lightModeOverride) {
      if (showBorder) {
        return getReactSelectBaseStyle({});
      }
      return defaultStyles();
    } else {
      if (classNamePrefix === "dark-mode") {
        return defaultDarkStyles();
      }

      if (classNamePrefix === "dark-mode-modal") {
        return defaultDarkModalStyles();
      }

      return defaultDarkStyles();
    }
  };

  const shouldRenderValue = () => {
    if (props.isSearchable === false) {
      // default is true
      return true;
    }
    return !isFocused;
  };

  return (
    <Select<OptionType, IsMulti>
      {...props}
      className={classNames(
        (isDarkMode || darkModeOverride) && !lightModeOverride ? "select-dark-mode" : "",
        className ?? "select-default-class",
        alwaysShowIndicator ? "" : "select-only-show-indicator-on-hover",
      )}
      components={{
        ...components,
        DropdownIndicator: hideIndicator
          ? () => null
          : components?.DropdownIndicator ?? DropdownIndicator,
      }}
      ref={innerRef}
      formatOptionLabel={(option, meta) =>
        meta.context === "menu"
          ? option.menuLabel ?? option.label
          : option.label
      }
      styles={overrideStyles ?? determineStyle(props?.classNamePrefix)}
      // menuIsOpen={props?.alwaysOpen} //! TODO: comment out if uncommented -> only for testing
      onMenuClose={() => {
        if (onMenuClose) {
          onMenuClose();
        }
      }}
      onMenuOpen={() => {
        if (onMenuOpen) {
          onMenuOpen();
        }
      }}
      placeholder={placeholder || ""}
      blurInputOnSelect={true}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
        setIsFocused(true);
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        setIsFocused(false);
      }}
      controlShouldRenderValue={shouldRenderValue()}
      isDisabled={isDisabled}
    />
  );
}

const CustomSelectWithRef = React.forwardRef<
  Select<SelectOptionType, false>,
  Omit<CustomSelectProps<SelectOptionType, false>, "isMulti">
>((props, ref) => <CustomSelect isMulti={false} {...props} innerRef={ref} />);
CustomSelectWithRef.displayName = "CustomSelect";
export default CustomSelectWithRef;
