import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import focusModeBroadcast from "../../broadcasts/focusModeBroadcast";
import { getCurrentTimeInCurrentTimeZone } from "../../services/commonUsefulFunctions";
import { getDateTimeFormat } from "../../lib/dateFunctions";

export default function SmallClock({ containerClassName }) {
  const [renderCounter, setRenderCounter] = useState("");
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const currentTimeZone = useSelector((state) => state.currentTimeZone);

  const setTime = (time: string) => {
    setRenderCounter(time);
  };

  useEffect(() => {
    focusModeBroadcast.subscribe("SET_SMALL_CLOCK_TIME", setTime);

    return () => {
      focusModeBroadcast.unsubscribe("SET_SMALL_CLOCK_TIME");
    };
  }, []);

  const renderTime = () => {
    return (
      <div className="font-size-16 font-weight-400 text-white">
        {format(getCurrentTimeInCurrentTimeZone(currentTimeZone), getDateTimeFormat(format24HourTime))}
      </div>
    );
  };

  const renderDate = () => {
    return (
      <div className="font-size-16 font-weight-400 text-white">
        {format(new Date(), "MMM d").toUpperCase()}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        containerClassName ?? "",
        "semi-transparent-text",
        "select-none"
      )}
    >
      {renderDate()}
      {renderTime()}
    </div>
  );
}
