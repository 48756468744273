import React, { Component } from "react";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import {
  KEYCODE_ENTER,
  KEYCODE_ESCAPE,
} from "../../services/commonUsefulFunctions";
import { blurCalendar, isKeyCodeCommandOrControl } from "../../services/appFunctions";
import classNames from "classnames";
import { X } from "react-feather";
import CustomButton from "../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import DisabledButton from "../disabledButton";
import { getInputStringFromEvent, isValidEmail, truncateString } from "../../lib/stringFunctions";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../../lib/vimcalVariables";

export default class ForwardOutlookEventModal extends Component {
  constructor(props) {
    super(props);

    this._isCmdKeyDown = false;
    this._message = React.createRef();

    this.state = {
      selectedGuests: [],
      message: "",
    };

    this.addContact = this.addContact.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSend = this.onClickSend.bind(this);
  }
  render() {
    return (
      <div>
        <ReactSelectAttendeeAutoComplete
          shouldAutoFocus={true}
          className="email-attendees-contact"
          addAttendees={this.addContact}
          defaultText="Enter name or email"
          componentLocation={REACT_ATTENDEE_SELECT_LOCATION.FORWARD_OUTLOOK}
          hideMenuOnFocus={true}
          selectedGuests={this.createSelectedGuestsEmailArray()}
          includeEmailAndName={true}
          isInModal={true}
          onKeyDown={this.onKeyDown}
          inputTabIndex={0}
        />
        {this.renderEmails()}
        {this.renderMessage()}
        {this.renderButtons()}
      </div>
    );
  }

  renderEmails() {
    if (this.getSelectedGuests().length === 0) {
      return null;
    }

    return (
      <div className="selected-email w-full flex-wrap">
        {this.getSelectedGuests().map((e, index) => {
          return (
            <div
              key={`selected_email_list_${index}`}
              className="selected-email-container"
            >
              <div className="margin-right-5">
                {truncateString(e.value, 40)}
              </div>

              <div className="display-flex-center">
                <X
                  className="hoverable-secondary-text-color"
                  size={13}
                  onClick={() => this.onClickRemoveEmail(e.value)}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  onClickRemoveEmail(email) {
    const updatedSelectedGuests = this.state.selectedGuests.filter(
      (g) => g.value !== email
    );
    this.setState({ selectedGuests: updatedSelectedGuests });
  }

  renderMessage() {
    return (
      <div className="mt-4">
        <div
          className={classNames(
            "email-subject-title",
            "margin-top-5",
            "flex justify-between items-center"
          )}
        >
          Message
        </div>
        <textarea
          // id={EMAIL_TEXT_AREA}
          className="email-input-container h-32 default-font-size"
          placeholder={"Add a message(optional)"}
          onKeyDown={(data) => this.onKeyDown(data)}
          onChange={this.onChangeMessage}
          tabIndex={2}
          ref={this._message}
        />
      </div>
    );
  }

  onChangeMessage(e) {
    this.setState({ message: getInputStringFromEvent(e) });
  }

  addContact(selected) {
    if (!selected) {
      return;
    }

    const emailWithoutWhiteSpaces = selected.value ? selected.value.trim() : "";

    if (
      !isValidEmail(emailWithoutWhiteSpaces) &&
      !this.getSelectedGuests().includes(emailWithoutWhiteSpaces)
    ) {
      return;
    }
    const { selectedGuests } = this.state;

    const updatedAddedGuests = selectedGuests.concat(selected);
    this.setState({ selectedGuests: updatedAddedGuests });
  }

  createSelectedGuestsEmailArray() {
    return this.getSelectedGuests().map((a) => {
      return a.value;
    });
  }

  getSelectedGuests() {
    const { selectedGuests } = this.state;
    return selectedGuests;
  }

  onKeyDown(data) {
    if (!data) {
      return;
    }

    if (isKeyCodeCommandOrControl(data.keyCode)) {
      this._isCmdKeyDown = true;
    } else {
      if (this._isCmdKeyDown && data.keyCode === KEYCODE_ENTER) {
        this.onClickSend();
      } else if (data.keyCode === KEYCODE_ESCAPE) {
        blurCalendar();
      }
      this._isCmdKeyDown = false;
    }
  }

  onClickSend() {
    const { selectedGuests, message } = this.state;
    const { outlookForwardOriginalEvent, event } = this.props;
    const recipients = selectedGuests.map((guest) => {
      const { name, value: email } = guest;
      return { name, email };
    });
    const param = {
      recipients,
      message,
      event,
      isRecurring: !!outlookForwardOriginalEvent,
    };

    backendBroadcasts.publish("FORWARD_OUTLOOK_EVENT", param);

    this.props.closeModal();
  }

  onClickCancel() {
    this.props.closeModal();
  }

  renderButtons() {
    return (
      <div
        className="weekly-calendar-modal-content-button-options"
        style={{ marginTop: 20 }}
      >
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={this.onClickCancel}
          label="Cancel"
          buttonTabIndex={4}
        />

        {this.getSelectedGuests().length === 0 ? (
          <DisabledButton />
        ) : (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={this.onClickSend}
            label="Send"
            buttonTabIndex={3}
          />
        )}
      </div>
    );
  }
}
