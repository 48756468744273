import classNames from "classnames";
import React from "react";
import {
  TEXT_STYLE_OPTIONS,
  TEXT_STYLE_OPTIONS_LIST,
} from "../../lib/availabilityFunctions";
import { Check } from "react-feather";

export default function SlotsTextStyleModal({
  onChangeTextStyles,
  textStyles,
  disabledFormats,
  alwaysCheckedFormats,
}) {
  return (
    <div>
      {TEXT_STYLE_OPTIONS_LIST.map((option, index) => {
        const isDisabled = disabledFormats?.includes(option);
        const isSelected = textStyles?.includes(option);
        return (
          <div
            key={`select-style-type-${option}`}
            className={classNames(
              isDisabled ? "" : "cursor-pointer",
              "flex justify-between items-center",
              "px-3 py-3",
              "modal-text-options-select modal-text-hover-override",
              "select-none",
              isDisabled ? "secondary-text-color" : "",
            )}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              onChangeTextStyles(option);
            }}
          >
            <div className="default-font-size">{getLabel({ option })}</div>
            {isSelected || alwaysCheckedFormats?.includes(option) ? <Check size={14} /> : null}
          </div>
        );
      })}
    </div>
  );
}

function getLabel({ option }) {
  switch (option) {
    case TEXT_STYLE_OPTIONS.BOLD_DATE:
      return "Bolded dates";
    case TEXT_STYLE_OPTIONS.HORIZONTAL:
      return "One line per date";
    case TEXT_STYLE_OPTIONS.SHORTEN_DATE:
      return "Abbreviated dates";
    case TEXT_STYLE_OPTIONS.SPACE_BETWEEN_DATES:
      return "Additional space between lines";
    case TEXT_STYLE_OPTIONS.SHOW_TIME_ZONE_ON_EACH_LINE:
      return "Time zone on each line";
    default:
      return "";
  }
}
