import React, { Component } from "react";
import { ACTION_MODE, BLUE_BUTTON, SECOND_IN_MS, WHITE_BUTTON } from "../services/globalVariables";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import ColoredLine from "../components/line";
import { connect } from "react-redux";
import { Trash2, X } from "react-feather";
import EventModalPopup from "../components/eventModalPopup";
import CustomButton from "../components/customButton";
import {
  KEYCODE_K,
  KEYCODE_J,
  KEYCODE_SEMI_COLON,
  KEYCODE_ESCAPE,
  KEYCODE_ENTER,
  isCommandKeyPressed,
  hasEventPreventDefault,
  calculateMarginTopClassname,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import _ from "underscore";
import moment from "moment";
import { trackEvent } from "../components/tracking";
import { getInputStringFromEvent } from "../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getUserToken } from "../lib/userFunctions";
import { TEMPLATE_VIEW_ID } from "../services/elementIDVariables";
import { determineDefaultModalStyle } from "../lib/modalFunctions";
import { getTemplateId } from "../services/templateFunctions";

const DESCRIPTION = "description";
const CONTENT = "content";
const DESCRIPTION_PLACEHOLDER = "Sticky title";
const CONTENT_PLACEHOLDER = "Enter content";

class TemplateView extends Component {
  constructor(props) {
    super(props);

    let title = this.determineTitle(props);

    this.state = {
      description: this.props.template ? this.props.template.text : "",
      descriptionName: title,
      originalDescription: this.props.template ? this.props.template.text : "",
      originalDescriptionName: title,
      discardWarningModal: false,
      keyMap: {},
      latestFocus: CONTENT,
      popup: null,
      lastTimeSet: moment(),
    };

    this.onClickExitTemplate = this.onClickExitTemplate.bind(this);
    this.exitTemplate = this.exitTemplate.bind(this);
    this.constructTemplateData = this.constructTemplateData.bind(this);
    this.updateKeyMap = this.updateKeyMap.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.focusField = this.focusField.bind(this);
    this.onRequestCloseEventModal = this.onRequestCloseEventModal.bind(this);
    this.onClickCloseModal = this.onClickCloseModal.bind(this);
    this.onFocusContent = this.onFocusContent.bind(this);
    this.onFocusDescription = this.onFocusDescription.bind(this);
    this.onClickTemplateData = this.onClickTemplateData.bind(this);
    this.pasteTemplateText = this.pasteTemplateText.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);

    Broadcast.subscribe("FOCUS_TEMPLATE_FIELD", this.focusField);
    Broadcast.subscribe("PASTE_INTO_TEXT_TEMPLATE", this.pasteTemplateText);
  }

  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.content?.focus();
    }, 0.5 * SECOND_IN_MS);
    
    this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);

    this.props.onRef(this);

    this.props.removePreviewedEvent();

    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener("keyup", this.handleKeyUp, false);

    trackEvent({
      category: "home", 
      action: "opened_create_text_template", 
      label: "text_template", 
      userToken: getUserToken(this.props.currentUser)
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.popupEvent, this.props.popupEvent)) {
      this.setState({ popup: moment() });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.onRef(undefined);

    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener("keyup", this.handleKeyUp, false);
    Broadcast.unsubscribe("FOCUS_TEMPLATE_FIELD");
    Broadcast.unsubscribe("PASTE_INTO_TEXT_TEMPLATE");
  }

  render() {
    return (
      <div
        id={TEMPLATE_VIEW_ID}
        className={calculateMarginTopClassname(this.props.shouldShowTopBar)}
      >
        {this.renderDescription()}

        {this.renderWarningDiscardModal()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderWarningDiscardModal() {
    return (
      <EventModalPopup
        isOpen={this.state.discardWarningModal}
        onRequestClose={this.onRequestCloseEventModal}
        width={300}
        title="Are you sure you want to discard this template?"
        style={determineDefaultModalStyle(this.props.isDarkMode)}
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  renderModalContent() {
    return (
      <div className="discard-template-modal-container">
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={this.onClickCloseModal}
          label="Cancel"
          addPaddingToRight={true}
        />

        <CustomButton
          buttonType={BLUE_BUTTON}
          shouldFocus={true}
          onClick={this.exitTemplate}
          label="Discard"
        />
      </div>
    );
  }

  renderDescription() {
    return (
      <div className="text-template-wrapper">
        <div>
          <div className="text-template-title-wrapper">
            <div className="text-template-text">Sticky</div>

            <div
              onClick={this.onClickExitTemplate}
              className="text-template-icon"
            >
              <X size={20} className="clickable-icon" />
            </div>
          </div>
        </div>

        <input
          ref={(input) => {
            this.content = input;
          }}
          autoFocus
          tabIndex={1}
          type="text"
          spellCheck={true}
          value={this.state.descriptionName}
          onChange={(e) => this.setState({ descriptionName: getInputStringFromEvent(e) })}
          placeholder={DESCRIPTION_PLACEHOLDER}
          className="text-template-title-input"
          onFocus={this.onFocusContent}
        />

        <div className="template-description-name rounded-md">
          <textarea
            ref={(input) => {
              this.description = input;
            }}
            tabIndex={2}
            spellCheck={true}
            onChange={(event) =>
              this.setState({ description: getInputStringFromEvent(event) })
            }
            value={this.state.description}
            placeholder={CONTENT_PLACEHOLDER}
            className="text-template-input-content-field"
            onFocus={this.onFocusDescription}
          />

          <div className="text-template-controls-wrapper">
            <ColoredLine style={{ width: "90%", backgroundColor: this.props.isDarkMode ? "#78788A" : undefined }} />

            <div className="text-template-control-buttons-wrapper">
              <div className="text-template-control-format">
                <div
                  tabIndex={3}
                  onClick={this.onClickTemplateData}
                  className="font-weight-500 default-font-size cursor-pointer hoverable-secondary-text-color select-none"
                >
                  Save
                </div>
              </div>

              {this.props.template && this.renderTrash()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onClickExitTemplate() {
    if (
      (this.state.descriptionName === "" && this.state.description === "") ||
      (this.state.descriptionName === this.state.originalDescriptionName &&
        this.state.description === this.state.originalDescription)
    ) {
      this.exitTemplate();
    } else {
      this.setState({
        discardWarningModal: true,
        lastTimeSet: moment(),
      });
    }
  }

  deleteTemplate() {
    trackEvent({
      category: "home",
      action: "deleted_template",
      label: "text_template",
      userToken: getUserToken(this.props.currentUser),
    });

    const templateId = getTemplateId(this.props.template);
    const path = `templates/${templateId}`;
    const url = constructRequestURLV2(path);

    this.exitTemplate();

    Broadcast.publish("DELETE_TEMPLATE", {
      url,
      template: this.props.template,
      templateId,
    });
  }

  renderTrash() {
    return (
      <div className="text-template-trash-button" onClick={this.deleteTemplate}>
        <Trash2 size="14" className="clickable-icon" />
      </div>
    );
  }

  exitTemplate() {
    this.props.backHome();
    this.props.removeTemplateSideBar();
    this.props.setActionMode(null);
  }

  constructTemplateData() {
    return {
      event: {
        text: this.state.description,
        description: this.state.descriptionName,
        google_calendar_event: null,
      },
    };
  }

  //=================
  // PRIVATE METHODS
  //=================

  onClickTemplateData() {
    if (this.props.template) {
      this.updateTemplate(this.constructTemplateData());
    } else {
      this.createTemplate(this.constructTemplateData());
    }

    this.props.removeTemplateSideBar();
  }

  onFocusDescription() {
    this.setState({ latestFocus: DESCRIPTION });
  }

  onFocusContent() {
    this.setState({ latestFocus: CONTENT });
  }

  onClickCloseModal() {
    this.setState({ discardWarningModal: false });
  }

  onRequestCloseEventModal() {
    this.setState({ discardWarningModal: false });
  }

  pasteTemplateText(text) {
    if (this.state.latestFocus === DESCRIPTION) {
      this.setState((prevState, props) => {
        return { description: prevState["description"] + text };
      });
    } else if (this.state.latestFocus === CONTENT) {
      this.setState((prevState, props) => {
        return { descriptionName: prevState["descriptionName"] + text };
      });
    }
  }

  focusField() {
    if (
      [CONTENT, DESCRIPTION].includes(this.state.latestFocus) &&
      this[this.state.latestFocus]
    ) {
      this[this.state.latestFocus].focus();
    }
  }

  createTemplate(eventData) {
    const path = "templates";
    const url = constructRequestURLV2(path);
    const payloadData = {
      body: JSON.stringify(eventData),
    };

    this.props.backHome();
    this.props.setActionMode(null);

    trackEvent({
      category: "home", 
      action: "created_text_template", 
      label: "text_template", 
      userToken: getUserToken(this.props.currentUser)
    });

    Broadcast.publish("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE", {
      url,
      payloadData,
      isTextTemplate: true,
    });
  }

  updateTemplate(eventData) {
    const templateId = getTemplateId(this.props.template);
    const path = `templates/${templateId}`;
    const url = constructRequestURLV2(path);
    const payloadData = {
      body: JSON.stringify(eventData),
    };

    this.props.backHome();
    this.props.setActionMode(null);

    trackEvent({
      category: "home",
      action: "updated_text_template",
      label: "text_template",
      userToken: getUserToken(this.props.currentUser),
    });

    Broadcast.publish("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE", {
      url,
      payloadData,
      isUpdate: true,
      isTextTemplate: true,
    });
  }

  handleKeyUp(e) {
    this.setState({ keyMap: {} });
  }

  updateKeyMap(e) {
    let keyMap = _.clone(this.state.keyMap);
    keyMap[e.keyCode] = e.type === "keydown";

    this.setState({ keyMap });
  }

  handleKeyDown(e) {
    this.updateKeyMap(e);

    if (
      this.state.keyMap[KEYCODE_ENTER] &&
      isCommandKeyPressed(this.state.keyMap)
    ) {
      this.onClickTemplateData();
    }

    // TODO: BUG WHERE COMMAND CENTER SHOWS WHEN JUST COMMAND IS PRESSED BECAUSE KEYUP AND KEYDOWN OVERWRITING KEYMAP IN STATE AT THE SAME TIME
    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_K]
    ) {
      Broadcast.publish("TURN_ON_COMMAND_CENTER", e);
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_J]
    ) {
      hasEventPreventDefault(e);
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_SEMI_COLON]
    ) {
      Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER", e);
    }

    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        hasEventPreventDefault(e);

        // TODO: BUG WHERE CLICKING ESCAPE OUTSIDE WHEN FOCUS IS OUT OF EVENT EDIT VIEW DOESN'T CLOSE
        if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
          this.props.removePopupEvent();
          break;
        } else if (this.state.discardWarningModal) {
          return;
        } else if (
          !this.props.isCommandCenterOn &&
          isEmptyObjectOrFalsey(this.props.popupEvent) &&
          (!this.state.popup || moment().diff(this.state.popup) > 200) &&
          (!this.state.lastTimeSet ||
            moment().diff(this.state.lastTimeSet) > 1000)
        ) {
          this.onClickExitTemplate();
        }

        return;
      default:
        break;
    }
  }

  determineTitle(props) {
    if (props.template) {
      return this.props.template.description;
    } else if (props.title) {
      return props.title;
    } else {
      return "";
    }
  }
}

function mapStateToProps(state) {
  let {
    popupEvent,
    isCommandCenterOn,
    saveTemplate,
    currentUser,
    shouldShowTopBar,
    isDarkMode,
  } = state;

  return {
    popupEvent,
    isCommandCenterOn,
    saveTemplate,
    currentUser,
    shouldShowTopBar,
    isDarkMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
    createTemplate: (data) => dispatch({ data: data, type: "CREATE_TEMPLATE" }),
    removeTemplate: (data) => dispatch({ data: data, type: "REMOVE_TEMPLATE" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    removeTemplateSideBar: () => dispatch({ type: "REMOVE_TEMPLATE_SIDE_BAR" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateView);
