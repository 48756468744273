import classNames from "classnames";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BROADCAST_VALUES } from "../../../lib/broadcastValues";
import { getHasFreeMonth } from "../../../lib/stripeFunctions";
import { MONTHLY, YEARLY } from "../../../lib/vimcalVariables";
import { triggerRefreshWithOnlineCheck } from "../../../services/appFunctions";
import broadcast from "../../../broadcasts/broadcast";
import { usePromotionsStore } from "../../../services/stores/finance";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import AnimatedGreenCheckMark from "../../icons/animatedGreenCheckMark";
import ColoredLine from "../../line";
import ReferToVimcal from "../../referToVimcal";

interface ThankYouPageProps {
  isInModal?: boolean
  paymentOption: typeof MONTHLY | typeof YEARLY
  referral?: Referral
}

export default function ThankYouPage({ isInModal, paymentOption, referral }: ThankYouPageProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const isUnauthorized = useMemo(() => isEmptyObjectOrFalsey(currentUser), []);
  const history = useHistory();
  const unappliedPromotions = usePromotionsStore(
    (state) => state.unappliedPromotions,
  );


  const hasFreeMonth = getHasFreeMonth({ referral, promotions: unappliedPromotions });


  const onClickGoToVimcal = () => {
    if (isUnauthorized) {
      history.push("/login");
    } else if (isInModal) {
      broadcast.publish(BROADCAST_VALUES.CLOSE_LAYOUT_MODAL);
      triggerRefreshWithOnlineCheck();
    } else {
      history.push("/home?ref=r");
    }
  };

  return (
    <div>
      <AnimatedGreenCheckMark />

      <div className={classNames("font-size-24 font-weight-500 flex justify-center", "-mt-4")}>
        {hasFreeMonth && paymentOption === MONTHLY
          ? "Payment method successfully updated!"
          : "Payment successful!"}
      </div>

      <div className="text-center mt-5 secondary-text-color default-font-size">
        {hasFreeMonth && paymentOption === MONTHLY
          ? "Your first payment will occur after your free month."
          : "Your payment was successful. We've also sent you a confirmation email."}
      </div>

      <ColoredLine inputClassName={"secondary-border-color mt-10 mb-10"} />

      <div className="flex justify-center font-size-20 font-weight-500">
        Refer to Vimcal and save on your subscription
      </div>

      <div className="ml-5 mr-5 mt-5">
        <ReferToVimcal
          closeModal={onClickGoToVimcal}
          cancelButtonTextLabel={"Go back home"}
          hideEmailSection
          where={"completed_payment"}
        />
      </div>

    </div>
  );
}
