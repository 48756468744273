import { ONBOARDING_RESPONSES_ENDPOINT } from "../../lib/endpoints";
import { getUserEmail } from "../../lib/userFunctions";
import { constructRequestURL } from "../../services/api";
import Fetcher from "../../services/fetcher";


export async function postOnboardingResponse(
  currentUser: User,
  role: string,
  otherProducts: string[] = [],
  numberOfExecutiveAssistants = "",
) {
  const url = constructRequestURL(ONBOARDING_RESPONSES_ENDPOINT, true);
  const payload = {
    body: JSON.stringify({
      response: {
        role,
        number_of_executive_assistants: numberOfExecutiveAssistants,
        other_products: otherProducts,
      },
    }),
  };
  await Fetcher.post(url, payload, true, getUserEmail(currentUser));
}
