import React from "react";
import CustomButton from "./customButton";
import { GENERIC_ERROR_MESSAGE, WHITE_BUTTON } from "../services/globalVariables";

interface GenericErrorModalContentProps {
  onClose: () => void
}

export default function GenericErrorModalContent({ onClose }: GenericErrorModalContentProps) {
  return (
    <div className="weekly-calendar-modal-content">
      <div className="default-font-size">{GENERIC_ERROR_MESSAGE}</div>

      <div
        className="flex justify-end mt-5"
      >
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={onClose}
          label="OK"
          shouldFocus={true}
        />
      </div>
    </div>
  );
}
