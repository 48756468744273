import React from "react";
import { Star } from "react-feather";
import { PRIORITY_TAG_STYLE } from "../../../../lib/tagsFunctions";
import classNames from "classnames";
import _ from "underscore";
import ShortcutHoverHint from "../../../shortcutHoverHint";

export default function PrioritizedStarButton({
  isPrioritized,
  onClick,
  isDisabled,
}) {
  return (
    <div
      className={classNames(
        "rounded-md edit-tag-icon-container height-34px flex items-center justify-center mx-2",
        isDisabled ? "border-opacity-50" : "hoverable-secondary-text-color"
      )}
      style={{ width: 34, minWidth: 34 }}
      onClick={isDisabled ? _.noop : onClick}
    >
      <ShortcutHoverHint
        below
        style={{ width: "max-content", marginTop: 10, right: "-64px" }}
        title={isDisabled ? "Activate the toggle to enable tag prioritization" : "Prioritize this tag's color over others."}
        shortcut={undefined}
      >
        {isPrioritized ? (
          <Star
            fill={PRIORITY_TAG_STYLE.COLOR}
            color={PRIORITY_TAG_STYLE.COLOR}
            size={18}
            className="mt-1.5"
          />
        ) : (
          <Star size={18} className={classNames(isDisabled ? "opacity-50" : "", "mt-1.5")} />
        )}
      </ShortcutHoverHint>
    </div>
  );
}
