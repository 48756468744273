import React, { useEffect, useState } from "react";
import OnboardingSaveButton from "./onboardingSaveButton";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import SelectAnswer from "./selectAnswer";
import Mousetrap from "mousetrap";
import { BUTTON_TYPE } from "../../services/appFunctions";
import { trackEvent } from "../tracking";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { ALL_ROLES, ROLES } from "./sharedFunctions";
import { constructRequestURL, isErrorResponse } from "../../services/api";
import { CONVERT_TO_EA_ACCOUNT_ENDPOINT } from "../../lib/endpoints";
import Fetcher from "../../services/fetcher";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import appBroadcast from "../../broadcasts/appBroadcast";
import { getSavedTeamInviteToken, removeSavedTeamInviteToken } from "../../lib/stateManagementFunctions";
import { respondToInvite } from "../queries/teamPlans";
import { handleError } from "../../services/commonUsefulFunctions";

export default function RoleQuestionSelection({ step, onClickNext, role, setRole, customRole, setCustomRole }) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customRoleError, setCustomRoleError] = useState(false);

  const isOtherRole = role === ROLES.OTHER;

  const onClickSave = async () => {
    if (!role) {
      return;
    }

    if (isOtherRole && !customRole) {
      setCustomRoleError(true);
      return;
    } else if (customRoleError) {
      setCustomRoleError(false);
    }

    setIsSubmitting(true);

    if (role === ROLES.EXECUTIVE_ASSISTANT && !masterAccount.scheduling_for_others) {
      const url = constructRequestURL(CONVERT_TO_EA_ACCOUNT_ENDPOINT, true);
      const response = await Fetcher.patch(url, {}, true, getUserEmail(currentUser));
      if (!isEmptyObjectOrFalsey(response)) {
        appBroadcast.publish("UPDATE_MASTER_ACCOUNT", response.master_account);
      }
    }

    const inviteToken = getSavedTeamInviteToken();
    if (inviteToken) {
      // Make sure this happens after the master account is converted so that they fill
      // the correct seat type.
      respondToInvite(currentUser, inviteToken).then(response => {
        // Don't use await here, no need to hold up the onboarding flow.
        if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
          return;
        }
        removeSavedTeamInviteToken();
      }).catch(e => {
        handleError(e);
      });
    }

    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });
    trackEvent({
      category: "onboarding_questionnaire",
      action: "select_role",
      label: isOtherRole ? customRole : role,
      userToken: getUserToken(currentUser),
    });
    onClickNext(role);
  };

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, [role]);

  return (
    <div className="onboarding-container min-height-500px">
      <div className="section-title">What is your current role?</div>
      <div className="flex flex-col mt-8 mb-3 gap-3 width-250">
        {ALL_ROLES.map((type) => {
          return (
            <SelectAnswer
              key={type}
              label={type}
              onClick={() => setRole(type)}
              isSelected={role === type}
              buttonType={BUTTON_TYPE.RADIO_BUTTON}
            />
          );
        })}
      </div>

      {isOtherRole ? (
        <div className="width-250">
          <input
            className="default-input-field"
            placeholder="Role"
            value={customRole}
            onChange={(e) => setCustomRole(e.target.value)}
          />
          {customRoleError ? (
            <div className="warning-color font-size-12 mt-1.5">
              Please provide your current role
            </div>
          ) : null}
        </div>
      ) : null}

      <OnboardingSaveButton
        buttonText="Continue"
        onClick={onClickSave}
        isDisabled={!role || isSubmitting}
      />
    </div>
  );
}
