import React, { PureComponent } from "react";
import {
  BLUE_BUTTON,
  WHITE_BUTTON,
  RED_BUTTON,
  DEFAULT_BUTTON,
  WHITE_BUTTON_WITHOUT_FOCUS,
  BLUE_BUTTON_WITHOUT_FOCUS,
} from "../services/globalVariables";
import { hasStopEventPropagation } from "../services/commonUsefulFunctions";
import classNames from "classnames";
import Spinner from "./spinner";
import { blurCalendar } from "../services/appFunctions";

class CustomButton extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldFocus) {
      this.customButton.focus();
    }
  }

  render() {
    return (
      <button
        className={this.determineClassName()}
        onClick={this.onClick}
        ref={(ref) => {
          this.customButton = ref;
        }}
        tabIndex={this.props.buttonTabIndex}
        style={Object.assign({}, this.props.style)}
        type={this.props.type ?? "button"}
      >
        <div
          style={Object.assign({}, this.props.labelStyle)}
          className={classNames(
            "flex items-center",
            this.props.removeDefaultFontStyles ? "" : "default-font-size font-weight-500",
            this.props.labelClassNameOverride ?? "",
          )}
        >
          {this.renderPlusIcon()}
          {this.renderSpinner()}
          {this.props.shouldRenderSpinner ? null : this.props.label}
        </div>
      </button>
    );
  }

  renderSpinner() {
    const {
      removeSpinnerRight,
      shouldRenderSpinner,
    } = this.props;

    if (!shouldRenderSpinner) {
      return null;
    }

    return (
      <Spinner
        useSmallSpinner={true}
        className={
          classNames(
            "absolute",
            "-top-6",
            removeSpinnerRight ? "" : "right-11",
          )}
      />
    );
  }

  renderPlusIcon() {
    const { shouldRenderPlusButton } = this.props;
    if (!shouldRenderPlusButton) {
      return null;
    }

    return <div className="font-size-20 mr-2 mb-0.5">+</div>;
  }

  onClick(e) {
    hasStopEventPropagation(e);
    this.props.onClick(e);
    blurCalendar();
  }

  determineClassName() {
    let defaultClassName = classNames("custom-button-box overflow-y-hidden rounded-lg", this.props.shouldRenderSpinner ? "relative" : "");

    if (this.props.addPaddingToRight) {
      defaultClassName = classNames(defaultClassName, "margin-right-10");
    }

    if (this.props.buttonType === WHITE_BUTTON_WITHOUT_FOCUS) {
      return classNames(
        defaultClassName,
        WHITE_BUTTON_WITHOUT_FOCUS,
        this.props.className ?? "",
      );
    } else if (this.props.buttonType === BLUE_BUTTON_WITHOUT_FOCUS) {
      return classNames(
        defaultClassName,
        BLUE_BUTTON_WITHOUT_FOCUS,
        this.props.className ?? "",
      );
    } else if (this.props.buttonType === BLUE_BUTTON) {
      return classNames(
        defaultClassName,
        BLUE_BUTTON,
        this.props.className ?? "",
      );
    } else if (this.props.buttonType === WHITE_BUTTON) {
      return classNames(
        defaultClassName,
        WHITE_BUTTON,
        this.props.className ?? "",
      );
    } else if (this.props.buttonType === RED_BUTTON) {
      return classNames(
        defaultClassName,
        RED_BUTTON,
        this.props.className ?? "",
      );
    } else {
      return classNames(
        defaultClassName,
        DEFAULT_BUTTON,
        this.props.className ?? "",
      );
    }
  }
}

export default CustomButton;
