import { addDays } from "date-fns";
import { isForRelease } from "../lib/featureFlagFunctions";
import {
  localData,
  removeDuplicatesFromArray,
} from "../services/commonUsefulFunctions";
import { DEFAULT_POMODORO_BACKGROUND, IMAGE_9 } from "./sharedVariables";
import { ALL_FOCUS_MODE_IMAGES } from "./sharedVariables.ts";
import { safeJSONParse } from "../lib/jsonFunctions";
import { LOCAL_DATA_ACTION } from "../lib/localData";

const FOCUS_MODE_SHOULD_HIDE_UPCOMING_EVENTS =
  "FOCUS_MODE_SHOULD_HIDE_UPCOMING_EVENTS";

export function getBackgroundImage(currentUser, inputDate = null) {
  // inputDate is only used for testing
  if (isForRelease(currentUser)) {
    return IMAGE_9;
  }

  const currentDate = inputDate ?? new Date();
  const dateOfMonth = currentDate.getDate();
  if (ALL_FOCUS_MODE_IMAGES[dateOfMonth]) {
    return ALL_FOCUS_MODE_IMAGES[dateOfMonth];
  }

  const remainder = 31 - dateOfMonth;
  return ALL_FOCUS_MODE_IMAGES[remainder] ?? DEFAULT_POMODORO_BACKGROUND;
}

export function getRandomBackgroundImage() {
  return ALL_FOCUS_MODE_IMAGES[
    Math.floor(Math.random() * ALL_FOCUS_MODE_IMAGES.length)
  ];
}

export function getPreloadBackgroundImages(currentUser) {
  // only preload 5 images
  const currentDayImage = getBackgroundImage(currentUser);
  let prefetchImages = [currentDayImage];

  const currentDate = new Date();
  [1, 2, 3, 4].forEach((num) => {
    const dateInAdvance = addDays(currentDate, num);
    const dateInAdvanceImage = getBackgroundImage(currentUser, dateInAdvance);
    prefetchImages = prefetchImages.concat(dateInAdvanceImage);
  });

  prefetchImages = removeDuplicatesFromArray(prefetchImages);

  return prefetchImages;
}

export function setFocusModeUpcomingStatePreference(shouldHide) {
  localData(
    "set",
    FOCUS_MODE_SHOULD_HIDE_UPCOMING_EVENTS,
    JSON.stringify(shouldHide ?? true)
  );
}

export function getFocusModeUpcomingStatePreference() {
  const unformatted = localData(LOCAL_DATA_ACTION.GET, FOCUS_MODE_SHOULD_HIDE_UPCOMING_EVENTS);
  return unformatted ? safeJSONParse(unformatted) : true;
}
