import classNames from "classnames";
import React from "react";
import CompleteCheckmarkSVG from "../../icons/greenCheckMark";
import ShareLink from "./shareLink";
import { useSelector } from "react-redux";
import { useIsFixedSeating } from "../hooks";
import { StripeCost } from "../updatedAddTeamMembersModal";
import { getStripeCostDueToday } from "../../../services/accessors/stripeCost";

interface CompleteSectionProps {
  closeModal: () => void
  setShareToken: StateSetter<string | null>
  shareToken: string | null
  stripeCost: StripeCost
}

const CompleteSection = ({
  closeModal,
  setShareToken,
  shareToken,
  stripeCost,
}: CompleteSectionProps) => {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const isFixedSeating = useIsFixedSeating();

  return (
    <div
      className={
        classNames(
          "group-scheduling-team-plans-container group-scheduling-team-plans-container-small",
          isDarkMode ? "dark-mode" : "",
        )
      }
    >
      <div className="flex flex-col p-6 grow">
        <div className="mb-4 flex justify-center">
          <CompleteCheckmarkSVG />
        </div>

        <div className="ml-auto mr-auto group-scheduling-team-plans-section-container mb-6">
          <div className="text-center group-scheduling-team-plans-section-header mb-2">
            {(getStripeCostDueToday(stripeCost) ?? 0) > 0 ? "Payment Successful" : "Invitations Sent"}
          </div>
        </div>

        {isFixedSeating ? (
          <div className="group-scheduling-team-plans-text-dark">
            When the invited users log in, they will be able to accept your invite.
          </div>
        ) : (
          <>
            <div className="mb-auto">
              <div className="mb-2">
                Share Invite
              </div>
              <div className="group-scheduling-team-plans-text-dark">
                {isFixedSeating
                  ? "Note that users will be unable to join your plan if all the seats are full.  The link expires in 14 days."
                  : "Note that you will be charged for anyone who joins your team from this link.  The link expires in 14 days."}
              </div>
            </div>

            <ShareLink
              shareToken={shareToken}
              setShareToken={setShareToken}
              shouldHideShareText={true}
            />
          </>
        )}

        <div
          className="group-scheduling-team-plans-complete-share-button mt-12"
          onClick={closeModal}
        >
          Complete
        </div>
      </div>
    </div>
  );
};

export default CompleteSection;
