import classNames from "classnames";
import React from "react";
import { ChevronDown } from "react-feather";

export default function CustomDropdownContainer({
  id,
  onClick,
  displayText,
  inputClassNames
}) {
  return (
    <div
      className={classNames("default-font-size select-style-box hover-container-show-item select-none", inputClassNames || "")}
      onClick={onClick}
      id={id}
    >
      {displayText}

      <ChevronDown size={12} className="secondary-text-color" />
    </div>
  );
}
