import React, { useEffect } from "react";
import { getActiveCommandCentersKey, hasEventPreventDefault, hasStopEventPropagation } from "../../services/commonUsefulFunctions";
// import CustomButton from "../customButton";
import Mousetrap from "mousetrap";
import { useSelector } from "react-redux";
import LargeShortTile from "./largeShortTile";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";

export default function CmdKInstruction(props) {
  const activeCommandCentersKey = getActiveCommandCentersKey();
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  useEffect(() => {
    Mousetrap.bind(
      ["command+k", "ctrl+k", "command k", "ctrl k"],
      function (e) {
        hasStopEventPropagation(e);
        hasEventPreventDefault(e);
        props.onClickNext();
      }
    );
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });

    return () => {
      Mousetrap.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="onboarding-container onboarding-container-medium">
      <div className="section-title">Command Center</div>

      <div className="try-command-k-container margin-top-20px-important">
        <div className="text-center">Open the Command Center by typing:</div>

        <div 
          className="display-flex justify-content-center mt-4 cursor-pointer"
          onClick={props.onClickNext}
        >
          <LargeShortTile text={activeCommandCentersKey} isLeftHandSide={true} />
          <LargeShortTile text={"K"} />
        </div>
      </div>

      {/* <CustomButton
        label="Continue"
        labelStyle={{ fontSize: 14, fontWeight: 300 }}
        style={{ width: 350, marginTop: 50, height: 50 }}
        onClick={props.onClickNext}
      /> */}
    </div>
  );
}
