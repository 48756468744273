import React from "react";
import { createAbbreviationForTimeZone } from "../../services/commonUsefulFunctions";
import classNames from "classnames";
import HoverableCircleCancelButton from "../hoverableCircleCancelButton";
import { useSelector } from "react-redux";
import { getSelectedDayWithBackup } from "../../lib/syncFunctions";

export default function SlotsTimeZoneBlock({
  date,
  timeZone,
  isFilled,
  onClick,
  showCancelButton,
  onClickCancelButton,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const selectedDay = useSelector((state) => state.selectedDay);

  const getStyleBackgroundColor = () => {
    if (isDarkMode) {
      return isFilled ? "#3665E3" : "transparent";
    }
    return isFilled ? "rgba(83, 148, 234, 1)" : undefined;
  };

  const getBorderStyle = () => {
    if (isDarkMode) {
      if (isFilled) {
        return "1px solid transparent";
      }
      return "1px solid #78788A";
    }
    return "1px solid rgba(178, 211, 255, 1)";
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-center",
        "cursor-pointer",
        "slots-time-zone-block",
        "relative hoverable-visibility-non-interactable-parent",
        "default-font-size",
        "duration-200"
      )}
      style={{
        backgroundColor: getStyleBackgroundColor(),
        color: isFilled ? "white" : undefined,
        border: getBorderStyle(),
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {createAbbreviationForTimeZone(timeZone, date || getSelectedDayWithBackup(selectedDay))}
      {showCancelButton ? (
        <HoverableCircleCancelButton onClick={onClickCancelButton} />
      ) : null}
    </div>
  );
}
