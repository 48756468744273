import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";
import classNames from "classnames";

import CustomButton from "../customButton";
import EventModalPopup from "../eventModalPopup";
import StyleConstants from "../../services/globalVariables";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { hasGroupSpreadsheetAccess } from "../../lib/featureFlagFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";

export default function GroupVoteCreateButton() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const currentUser = useSelector(state => state.currentUser);
  const isDarkMode = useSelector(state => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const SHARED_CLASS_NAME =
    "p-3 modal-text-options-select modal-text-hover-override flex justify-between select-none";

  const openForm = (isGroupPoll: boolean) => {
    const broadcastValue = isGroupPoll
      ? "ADD_GROUP_VOTE_LINK"
      : "ADD_GROUP_SPREADSHEET_LINK";
    availabilityBroadcast.publish(broadcastValue);
    setIsPopupOpen(false);
  };

  const getPopupStyles = () => {
    const { overlay, content } = determineDefaultModalStyle(isDarkMode);
    const boundingBox = wrapperRef.current?.getBoundingClientRect() ?? new DOMRect();

    return {
      overlay: {
        ...overlay,
        backgroundColor: "transparent",
      },
      content: {
        ...content,
        borderRadius: 10,
        padding: 0,
        top: boundingBox.bottom + 8,
        right: window.innerWidth - boundingBox.right,
        left: undefined,
        bottom: undefined,
        marginRight: undefined,
        transform: undefined,
        backgroundColor: isDarkMode
          ? StyleConstants.darkModeModalBackgroundColor
          : "white",
        color: isDarkMode
          ? StyleConstants.darkModeModalTextColor
          : "",
      },
    };
  };

  const handleClick = () => {
    if (hasGroupSpreadsheetAccess({user: currentUser, masterAccount})) {
      setIsPopupOpen(true);
    } else {
      openForm(true);
    }
  };

  return (
    <div ref={wrapperRef}>
      <CustomButton
        onClick={handleClick}
        label="Create"
        shouldRenderPlusButton={true}
        labelClassNameOverride={"font-size-300-important"}
        className="border-radius-6px-important"
      />
      <EventModalPopup
        hideCloseButton
        hideTitle
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
        style={getPopupStyles()}
      >
        <div className={classNames("default-font-size cursor-pointer")}>
          <div
            className={classNames(SHARED_CLASS_NAME, "flex items-center")}
            onClick={() => openForm(true)}
          >
            Poll
          </div>
          <div
            className={classNames(SHARED_CLASS_NAME)}
            onClick={() => openForm(false)}
          >
            Spreadsheet
          </div>
        </div>
      </EventModalPopup>
    </div>
  );
}
