import React, { useState } from "react";
import VimcalLogo from "./vimcalLogo";
import Ellipse from "./ellipse";
import classNames from "classnames";
import {
  formatEmail,
  getEmailDomain,
  getInputStringFromEvent,
  isValidEmail,
} from "../../lib/stringFunctions";
import SaveButton from "../../components/saveButton";
import { getSAMLRedirectLink, isDomainEnabled } from "../../lib/ssoFunctions";
import { Info } from "react-feather";
import { useSelector } from "react-redux";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import {
  OpenLink,
  openLinkOnSamePage,
} from "../../services/commonUsefulFunctions";
import SpinnerV2 from "../../components/spinnerV2";
import MaestroExitButton from "./loginExitButton";
import { useHistory } from "react-router-dom";
import LoginBackButton from "./loginBackButton";

export default function SsoLoginPage({ isDesktopLogin, isMagicLink }) {
  const [email, setEmail] = useState("");
  const [isWaitingForDomainValidation, setIsWaitingForDomainValidation] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();

  const isLoggedIn = !isEmptyObjectOrFalsey(currentUser);

  const onClickContinue = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage("Invalid email");
      return;
    }
    setIsWaitingForDomainValidation(true);
    const isEnabled = await isDomainEnabled(getEmailDomain(formatEmail(email)));
    setIsWaitingForDomainValidation(false);
    if (!isEnabled) {
      setErrorMessage("No matching domain found");
      return;
    }
    // redirect
    const redirectLink = getSAMLRedirectLink({ email, isDesktopLogin, isMagicLink });
    if (isLoggedIn) {
      OpenLink(redirectLink);
      return;
    }
    openLinkOnSamePage(redirectLink);
  };

  const renderError = () => {
    return (
      <div className="flex items-center rounded-md w-full text-red-500 gap-2 border-solid border-width-1px py-1 px-2 bg-red-50">
        <Info size={14} className="" />
        <div className="default-font-size">{errorMessage}</div>
      </div>
    );
  };

  const renderSpinner = () => {
    return <SpinnerV2 variant={"small"} />;
  };
  return (
    <div className={"vimcal-updated-login-container light-mode-font-color"}>
      {isLoggedIn ? (
        <MaestroExitButton
          onClick={() => {
            history.push("/home");
          }}
          shouldHideHint={true}
        />
      ) : (
        <LoginBackButton
          shouldHideHint={true}
          onClick={() => {
            if (isDesktopLogin) {
              history.push("/desktop-login");
            } else {
              history.push("/login");
            }
          }}
        />
      )}
      <VimcalLogo className="vimcal-updated-login-logo" />
      <Ellipse className="vimcal-updated-login-ellipse" />
      <div
        className={classNames(
          "center-content-absolute",
          "bg-white",
          "rounded-md",
          "flex items-center justify-center flex-col",
          "w-96 h-64",
          "z-10"
        )}
      >
        <div className="font-weight-500 font-size-20 mb-4">
          Search Company Domain
        </div>
        <div className="flex flex-col items-start w-52">
          <div className="default-font-size light-mode-secondary-text-color mb-2">
            Email address
          </div>

          <input
            className="light-mode-input default-font-size mb-2.5 default-white-mode-font-color-override"
            value={email}
            onChange={(e) => {
              setEmail(getInputStringFromEvent(e));
              if (errorMessage) {
                setErrorMessage(false);
              }
            }}
            placeholder="Enter email"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickContinue();
              }
            }}
            autoFocus
          />
          {errorMessage ? renderError() : null}

          <SaveButton
            width="100%"
            height={34}
            hideDefaultIcon={true}
            buttonText={
              isWaitingForDomainValidation ? renderSpinner() : "Continue"
            }
            justifyContent={"center"}
            disabled={!email}
            onClick={onClickContinue}
            style={{
              cursor: email ? "pointer" : "default",
              color: "white"
            }}
            textStyle={{
              fontWeight: "300",
              fontSize: 12,
            }}
          />
        </div>
      </div>
    </div>
  );
}
