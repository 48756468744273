import { getUserToken } from "../../lib/userFunctions";
import { useAllLoggedInUsers } from "../stores/SharedAccountData";

/**
 * Returns true if any logged in user has the provided token. Otherwise returns false.
 */
export default function useIsUserTokenLoggedIn(token: string): boolean {
  const { allLoggedInUsers } = useAllLoggedInUsers();

  if (!token) {
    return false;
  }

  return allLoggedInUsers.some(user => getUserToken(user) === token);
}
