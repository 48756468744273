import {
  isValidJSDate,
  isObject,
  filterExistingEmails,
  removeCommaFromString,
  isValidTimeZone,
  getTimeInAnchorTimeZone,
} from "../services/commonUsefulFunctions";
import {
  FREE_DURING_EVENT,
  BUSY_DURING_EVENT,
  ATTENDEE_EVENT_NEEDS_ACTION,
  ATTENDEE_EVENT_TENTATIVE,
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_DECLINED,
  PRIVATE,
  PUBLIC,
  CONFIDENTIAL,
} from "../services/googleCalendarService";
import icalJS from "ical"; // https://github.com/peterbraden/ical.js
import {
  addEventAttendees,
  addEventDescription,
  addEventEnd,
  addEventLocation,
  addEventRecurrence,
  addEventStart,
  addEventTitle,
  addEventTransparency,
  addEventVisibility,
} from "../services/eventResourceAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { mapMsToIana } from "../services/timeZone";
import { isDictionary, isEmptyArrayOrFalsey, isNullOrUndefined, isTypeString } from "../services/typeGuards";
import { isValidEmail } from "./stringFunctions";

export function parseICSString(icsString) {
  if (!icsString) {
    return null;
  }

  const icsIndex = icalJS.parseICS(icsString);
  if (!isDictionary(icsIndex)) {
    return null;
  }
  let events = [];
  Object.keys(icsIndex).forEach((k) => {
    if (icsIndex[k].type === "VEVENT") {
      events = events.concat(icsIndex[k]);
    }
  });

  if (isEmptyArrayOrFalsey(events)) {
    return null;
  }

  // We only support single-event ICS files.
  if (events.length !== 1) {
    return null;
  }

  const event = events[0];
  return getDetailsFromICSEvent(event);
}

function getDetailsFromICSEvent(event) {
  let details = isVersionV2() ? {} : { raw_json: {} };
  let timeZone;

  if (event.location) {
    if (isTypeString(event.location)) {
      details = addEventLocation(details, event.location);
    } else if (!isNullOrUndefined(event.location.val)) {
      details = addEventLocation(details, event.location.val);
    }
  }

  if (event.summary) {
    if (isTypeString(event.summary)) {
      details.summaryUpdatedWithVisibility = event.summary;
      details = addEventTitle(details, event.summary);
    } else if (!isNullOrUndefined(event.summary.val)) {
      details.summaryUpdatedWithVisibility = event.summary.val;
      details = addEventTitle(details, event.summary.val);
    }
  }
  if (event.tzid) {
    if (isValidTimeZone(event.tzid)) {
      timeZone = event.tzid;
    } else if (mapMsToIana(event.tzid)) {
      timeZone = mapMsToIana(event.tzid);
    }
  }
  const getStartTimeZone = () => {
    if (timeZone) {
      return timeZone;
    }
    return getTimeZoneFromJSDate(event.start);
  };
  const getEndTimeZone = () => {
    if (timeZone) {
      return timeZone;
    }
    return getTimeZoneFromJSDate(event.end);
  };

  if (event.start && isValidJSDate(event.start)) {
    details.eventStart = getStartTimeZone()
      ? getTimeInAnchorTimeZone(event.start, getStartTimeZone())
      : event.start;
    details.defaultStartTime = details.eventStart.toISOString();

    if (event.start.hasOwnProperty("dateOnly")) {
      // https://github.com/peterbraden/ical.js/issues/103
      details = addEventStart({
        event: details,
        start: { date: event.start.toISOString() },
      });
    } else {
      details = addEventStart({
        event: details,
        start: { dateTime: details.defaultStartTime },
        timeZone: getStartTimeZone(),
      });
    }
  }

  if (event.end && isValidJSDate(event.end)) {
    details.eventEnd = getEndTimeZone()
      ? getTimeInAnchorTimeZone(event.end, getEndTimeZone())
      : event.end;
    details.defaultEndTime = details.eventEnd.toISOString();

    if (event.end.hasOwnProperty("dateOnly")) {
      details = addEventEnd({
        event: details,
        end: { date: event.end.toISOString() },
      });
    } else {
      details = addEventEnd({
        event: details,
        end: { dateTime: details.defaultEndTime },
        timeZone: getEndTimeZone(),
      });
    }
  }

  if (event.attendee) {
    let attendees = processEventAttendees(event.attendee);
    if (event.organizer) {
      attendees = attendees.concat(
        filterExistingEmails(attendees, processEventAttendees(event.organizer))
      );
    }

    if (attendees.length > 0) {
      details = addEventAttendees(details, attendees);
    }
  } else if (event.organizer) {
    // no attendee but there's organizer -> make organizer attendee
    const organizer = processEventAttendees(event.organizer);
    details = addEventAttendees(details, organizer);
  }

  if (event.transp) {
    if (isTypeString(event.transp)) {
      details = addEventTransparency(details, getICSTransparency(event.transp));
    } else if (!isNullOrUndefined(event.transp.val)) {
      details = addEventTransparency(
        details,
        getICSTransparency(event.transp.val)
      );
    }
  }

  if (event.class) {
    if (isTypeString(event.class)) {
      details = addEventVisibility(details, getICSVisibility(event.class));
    } else if (!isNullOrUndefined(event.class.val)) {
      details = addEventTransparency(
        details,
        getICSVisibility(event.class.val)
      );
    }
  }

  if (event.description) {
    if (isTypeString(event.description)) {
      details = addEventDescription(details, event.description);
    } else if (!isNullOrUndefined(event.description.val)) {
      details = addEventDescription(details, event.description.val);
    }
  }

  if (event.rrule) {
    if (isTypeString(event.rrule)) {
      details = addEventRecurrence(details, [event.rrule]);
    } else if (!isNullOrUndefined(event.rrule.val)) {
      details = addEventRecurrence(details, [event.rrule.val]);
    }
  }

  return details;
}

function cleanUpICSEmailFormats(emailString) {
  if (!emailString && typeof emailString !== "string") {
    return null;
  }
  // remove "\"bluewolfpromo@icloud.com\"";
  // "mailto:yaworsk2@illinois.edu"
  return removeCommaFromString(
    emailString.toLowerCase().replace("mailto:", "")
  );
}

function processEventAttendees(eventAttendee) {
  // eventAttendees could be object or array
  if (!eventAttendee) {
    return [];
  }

  let attendees = [];
  if (isObject(eventAttendee)) {
    let attendee = createAttendeeFromParam(eventAttendee);
    if (attendee) {
      attendees = attendees.concat(attendee);
    }
  } else if (eventAttendee.length > 0 && eventAttendee.forEach) {
    eventAttendee.forEach((a) => {
      let attendee = createAttendeeFromParam(a);
      if (attendee) {
        attendees = attendees.concat(attendee);
      }
    });
  }

  return attendees;
}

function createAttendeeFromParam(attendee) {
  // params:
  // CN: "\"Alan Fischer\""
  // CUTYPE: "INDIVIDUAL"
  // PARTSTAT: "NEEDS-ACTION"
  // ROLE: "REQ-PARTICIPANT"
  // val: email

  // formatted should look like:
  // attendee{email: mike@vimcal.com", displayName: "Michael Zhao", responseStatus: "accepted"}

  let email = cleanUpICSEmailFormats(attendee.val);
  if (!isValidEmail(email)) {
    if (!attendee.params) {
      return null;
    }

    email = cleanUpICSEmailFormats(attendee.params.EMAIL);
    if (!isValidEmail(email)) {
      return null;
    }
  }

  let response = { email };
  if (!attendee.params) {
    response.responseStatus = ATTENDEE_EVENT_NEEDS_ACTION;
    return response;
  }

  if (attendee.params.CN) {
    response.displayName = removeCommaFromString(attendee.params.CN);
  }

  if (attendee.params.PARTSTAT) {
    switch (attendee.params.PARTSTAT) {
      case "TENTATIVE":
        response.responseStatus = ATTENDEE_EVENT_TENTATIVE;
        break;
      case "ACCEPTED":
        response.responseStatus = ATTENDEE_EVENT_ATTENDING;
        break;
      case "NEEDS-ACTION":
        response.responseStatus = ATTENDEE_EVENT_NEEDS_ACTION;
        break;
      case "DECLINED":
        response.responseStatus = ATTENDEE_EVENT_DECLINED;
        break;
      default:
        response.responseStatus = ATTENDEE_EVENT_NEEDS_ACTION;
    }
  } else {
    response.responseStatus = ATTENDEE_EVENT_NEEDS_ACTION;
  }

  return response;
}

function getICSTransparency(value) {
  return value === "OPAQUE" ? BUSY_DURING_EVENT : FREE_DURING_EVENT;
}

function getICSVisibility(value) {
  switch (value) {
    case "PUBLIC":
      return PUBLIC;
    case "PRIVATE":
      return PRIVATE;
    case "CONFIDENTIAL":
      return CONFIDENTIAL;
    default:
      return PRIVATE;
  }
}

function getTimeZoneFromJSDate(jsDate) {
  if (!jsDate?.tz) {
    return null;
  }

  if (isValidTimeZone(jsDate.tz)) {
    return jsDate.tz;
  }
  if (mapMsToIana(jsDate.tz)) {
    return mapMsToIana(jsDate.tz);
  }
  return null;
}
