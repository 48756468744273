import React, { PureComponent }                       from 'react';
import {
  formatTimeJSDate,
  guessTimeZone,
  openConferencingURL,
  OpenGoogleMapsLocation,
  convertToTimeZone,
  hasStopEventPropagation,
  hasEventPreventDefault
}                                                     from '../services/commonUsefulFunctions';
import ShortcutTile                                   from './shortcutTiles/shortcutTile';
import * as Mousetrap                                 from 'mousetrap';
import {
  BLUE_JEANS_CONFERENCING,
  BLUE_JEANS_ICON_URL,
  GO_TO_MEETING_CONFERENCING,
  GO_TO_MEETING_ICON_URL,
  GOOGLE_CONFERENCING,
  hangoutIconURL,
  MICROSOFT_TEAMS_CONFERENCING,
  microsoftTeamsIconURL,
  RING_CENTRAL_CONFERENCING,
  RING_CENTRAL_ICON_URL,
  TYPE_CONFERENCING,
  TYPE_LOCATION,
  TYPE_UP_NEXT,
  TYPE_EMAIL,
  UBER_CONFERENCING,
  UBER_CONFERENCING_ICON_URL,
  WEB_EX_CONFERENCING,
  WEB_EX_ICON_URL,
  WHEREBY_CONFERENCING,
  whereByIconURL,
  ZOOM_CONFERENCING,
  zoomImageURL,
  DARK_MODE_HOVER_BACKGROUND_COLOR,
  LIGHTGRAY,
  TYPE_EMAIL_RUNNING_LATE
}                                                     from '../services/globalVariables';
import {connect}                                      from 'react-redux';
import Broadcast                                      from '../broadcasts/broadcast';
import { Video }                                      from 'react-feather';
import { getEventConferenceData, getEventLocation } from '../services/eventResourceAccessors';


class MultipleUpcomingEventsModal extends PureComponent {
  constructor(props) {
    super(props);

    this.selectUpcomingEvent = this.selectUpcomingEvent.bind(this);
  }

  componentDidMount() {
    this.bindMouseTrap();
  }

  componentWillUnmount() {
    this.unbindMouseTrap();
  }

  render() {
    return (
      <>
        {this.renderUpcomingEvents()}
      </>
    );
  }

  renderUpcomingEvents() {
    let upcomingEvents = this.props.upcomingEvents;
    if (!upcomingEvents || upcomingEvents.length === 0) {
      return null;
    }

    return upcomingEvents.map((e, index) => {
      let eventIndex = this.determineIndex(index);
      return (
        <div
          className="multiple-upcoming-events-container hoverable-text"
          key={`multiple_upcoming_events_${index}`}
          onClick={() => this.selectUpcomingEvent(index)}
          style={index === 0 ? {} : {borderTop: this.props.isDarkMode ? `1px solid ${DARK_MODE_HOVER_BACKGROUND_COLOR}` : `1px solid ${LIGHTGRAY}`}}
        >
          <div className="display-flex-flex-direction-row align-items-flex-start">
            <div className="event-expand-icon">
              {this.determineConferencingIcon(e)}
            </div>

            <div>
              {e.summaryUpdatedWithVisibility}

              <div>
                {formatTimeJSDate(convertToTimeZone(e.defaultStartTime, {timeZone: this.props.currentTimeZone || guessTimeZone()}), this.props.format24HourTime)
                  + ' - ' 
                  + formatTimeJSDate(convertToTimeZone(e.defaultEndTime, {timeZone: this.props.currentTimeZone || guessTimeZone()}), this.props.format24HourTime)
                }
              </div>

              {this.renderLocation(e)}
            </div>
          </div>

          {eventIndex &&
            <div>
              <ShortcutTile shortcut={eventIndex} />
            </div>
          }
        </div>
      );
    });
  }

  renderLocation(event) {
    const eventLocation = getEventLocation(event)
    if (!(eventLocation && this.props.hotkeyType === TYPE_LOCATION)) {
      return null;
    }

    return (
      <div>
        {eventLocation}
      </div>
    );
  }

  determineIndex(index) {
    if (index < 9) {
      return index + 1
    } else if (index === 9) {
      return 0
    } else {
      return null;
    }
  }

  bindMouseTrap() {
    Mousetrap.bind('0', (e) => this.selectUpcomingEvent(9, e));
    Mousetrap.bind('1', (e) => this.selectUpcomingEvent(0, e));
    Mousetrap.bind('2', (e) => this.selectUpcomingEvent(1, e));
    Mousetrap.bind('3', (e) => this.selectUpcomingEvent(2, e));
    Mousetrap.bind('4', (e) => this.selectUpcomingEvent(3, e));
    Mousetrap.bind('5', (e) => this.selectUpcomingEvent(4, e));
    Mousetrap.bind('6', (e) => this.selectUpcomingEvent(5, e));
    Mousetrap.bind('7', (e) => this.selectUpcomingEvent(6, e));
    Mousetrap.bind('8', (e) => this.selectUpcomingEvent(7, e));
    Mousetrap.bind('9', (e) => this.selectUpcomingEvent(8, e));
  }

  unbindMouseTrap() {
    Mousetrap.bind('0');
    Mousetrap.bind('1');
    Mousetrap.bind('2');
    Mousetrap.bind('3');
    Mousetrap.bind('4');
    Mousetrap.bind('5');
    Mousetrap.bind('6');
    Mousetrap.bind('7');
    Mousetrap.bind('8');
    Mousetrap.bind('9');
  }

  selectUpcomingEvent(eventIndex, e) {
    hasStopEventPropagation(e);
    hasEventPreventDefault(e)

    if (!this.props.upcomingEvents
      || this.props.upcomingEvents.length === 0
      || !this.props.upcomingEvents[eventIndex]
    ) {
      Broadcast.publish('CLOSE_LAYOUT_MODAL');
      return;
    }

    const event = this.props.upcomingEvents[eventIndex];

    switch(this.props.hotkeyType) {
      case TYPE_CONFERENCING:
        const conferencing = event.conferenceUrl;

        openConferencingURL(conferencing, this.props.currentUser.email);

        Broadcast.publish('CLOSE_LAYOUT_MODAL');
        break;
      case TYPE_LOCATION:
        const location = getEventLocation(event);

        OpenGoogleMapsLocation(location, this.props.currentUser);

        Broadcast.publish('CLOSE_LAYOUT_MODAL');
        break;
      case TYPE_UP_NEXT:
        Broadcast.publish('SET_AGENDA_EVENT_AS_PREVIEW', event);
        Broadcast.publish('CLOSE_LAYOUT_MODAL');
        break;
      case TYPE_EMAIL:
        Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", event);
        break;
      case TYPE_EMAIL_RUNNING_LATE:
        Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", event, true);
        break;
      default:
        Broadcast.publish('CLOSE_LAYOUT_MODAL');
        break;
    }
  }

  determineConferencingIcon(event) {
    const eventConferenceData = getEventConferenceData(event);

    if (eventConferenceData?.conferenceSolution?.iconUri) {
      return this.renderIcon(eventConferenceData.conferenceSolution.iconUri);
    } else if (!event.conferenceUrl) {
      return <div style={{width: 16, height: 16}}></div>;
    } else if (this.isConferencingType(ZOOM_CONFERENCING, event)) {
      return this.renderIcon(zoomImageURL);
    } else if (this.isConferencingType(GOOGLE_CONFERENCING, event)) {
      return this.renderIcon(hangoutIconURL);
    } else if (this.isConferencingType(MICROSOFT_TEAMS_CONFERENCING, event)) {
      return this.renderIcon(microsoftTeamsIconURL);
    } else if (this.isConferencingType(WHEREBY_CONFERENCING, event)) {
      return this.renderIcon(whereByIconURL);
    } else if (this.isConferencingType(GO_TO_MEETING_CONFERENCING, event)) {
      return this.renderIcon(GO_TO_MEETING_ICON_URL);
    } else if (this.isConferencingType(WEB_EX_CONFERENCING, event)) {
      return this.renderIcon(WEB_EX_ICON_URL);
    } else if (this.isConferencingType(UBER_CONFERENCING, event)) {
      return this.renderIcon(UBER_CONFERENCING_ICON_URL);
    } else if (this.isConferencingType(BLUE_JEANS_CONFERENCING, event)) {
      return this.renderIcon(BLUE_JEANS_ICON_URL);
    } else if (this.isConferencingType(RING_CENTRAL_CONFERENCING, event)) {
      return this.renderIcon(RING_CENTRAL_ICON_URL);
    } else {
      return <Video className="non-clickable-icon" size="14" />;
    }
  }

  isConferencingType(conferencingType, event) {
    let lowerCaseLink = event.conferenceUrl.toLowerCase();

    return lowerCaseLink.indexOf(conferencingType) >= 0;
  }

  renderIcon(iconURL) {
    return (
      <img
        alt=""
        width="16px"
        height="16px"
        src={iconURL}
      />
    );
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    format24HourTime,
    isDarkMode
  } = state;

  return {
    currentUser,
    currentTimeZone,
    format24HourTime,
    isDarkMode
  };
}



export default connect(mapStateToProps, null)(MultipleUpcomingEventsModal);
