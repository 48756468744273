import { useEffect } from "react";

interface UseIntersectionObserverOptions {
  callback: (entries, observer) => void
  element: HTMLElement | null
  root?: Element | Document | null
  rootMargin?: string
  threshold?: number | number[]
}

export default function useIntersectionObserver({
  callback,
  element,
  root,
  rootMargin,
  threshold,
}: UseIntersectionObserverOptions) {

  useEffect(() => {
    if (!element || !callback) {
      return;
    }

    const observer = new IntersectionObserver(
      callback,
      { root, rootMargin, threshold },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [
    callback,
    element,
    root,
    rootMargin,
    threshold,
  ]);
}
