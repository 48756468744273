import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import AvailabilityAdditionalDetails from "../availabilityAdditionalDetails";
import {
  calculateMarginTop,
  hasStopEventPropagation,
  guessTimeZone,
  handleError,
  renderTimeSlotsLine,
  KEYCODE_ESCAPE,
  getTimeInAnchorTimeZone,
  isInt,
  omitNullOrUndefinedProps,
} from "../../services/commonUsefulFunctions";
import { useSelector } from "react-redux";
import { ChevronLeft } from "react-feather";
import classNames from "classnames";
import {
  getGroupVoteLinkInfo,
  convertSlotsIntoISOString,
  isSameSlot,
  removeDuplicateSlots,
  createGroupVoteTimeText,
  splitSlotIntoDuration,
  getGroupVoteURLLink,
  combineSlots,
  getGroupVoteDurationList,
  determineBookingURL,
  getUserAvailabilitySettings,
  isGroupVoteEvent,
} from "../../lib/availabilityFunctions";
import SelectTimeZoneInput from "../selectTimeZoneInput";
import EventModalPopup from "../eventModalPopup";
import {
  DARK_MODE_MODAL_BACKGROUND_COLOR,
  DARK_MODE_MODAL_TEXT_COLOR,
  BLUE_BUTTON,
  GENERIC_ERROR_MESSAGE,
  BACKEND_MONTH,
  ADD_SLOT,
  UPDATE_SLOT,
  DELETE_SLOT,
  WHITE_BUTTON_WITHOUT_FOCUS,
} from "../../services/globalVariables";
import CustomButton from "../customButton";
import {
  FREE_DURING_EVENT,
  NO_CONFERENCING_OPTION,
  VIMCAL_SIGNATURE,
} from "../../services/googleCalendarService";
import CheckBox from "../checkbox";
import MoreInformationHint from "../moreInformationHint";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import Fetcher from "../../services/fetcher";
import { constructRequestURL } from "../../services/api";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { useGroupVoteStore } from "../../services/stores/settings";
import {
  getCalendarFromProviderID,
  isCalendarOutlookCalendar,
} from "../../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import {
  getCalendarObject,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../../services/calendarAccessors";
import { isVersionV2 } from "../../services/versionFunctions";
import { getReactSelectBaseStyle } from "../select/styles";
import DropdownIndicator from "../select/dropDownIndicator";
import {
  CREATE_GROUP_VOTE_BUTTON_ID,
  GROUP_VOTE_DETAIL_CONTAINER,
} from "../../services/elementIDVariables";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import broadcast from "../../broadcasts/broadcast";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import {
  removeDefaultGroupVoteClickDuration,
  removeGroupVoteDurationOnDrag,
  setDefaultGroupVoteClickDuration,
  setGroupVoteDurationOnDrag,
} from "../../lib/localData";
import {
  getUserEmail,
  getUserName,
  getUserToken,
} from "../../lib/userFunctions";
import {
  formatNamesWithComma,
  getArrayDifference,
  isEmptyArray,
} from "../../lib/arrayFunctions";
import groupVoteBroadcast from "../../broadcasts/groupVoteBroadcast";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../../services/typeGuards";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import {
  AVAILABILITY_BROADCAST_VALUES,
  BROADCAST_VALUES,
  HOLDS_BROADCAST_VALUES,
  LAYOUT_BROADCAST_VALUES,
  MAIN_CALENDAR_BROADCAST_VALUES,
} from "../../lib/broadcastValues";
import {
  formatEmail,
  getInputStringFromEvent,
  isSameEmail,
  lowerCaseAndTrimString,
  slugifyString,
} from "../../lib/stringFunctions";
import {
  GROUP_SPREADSHEET_LINKS_ENDPOINT,
  LATEST_GROUP_VOTE_LINKS,
} from "../../lib/endpoints";
import {
  getDefaultUserTimeZone,
  shouldHideDefaultSignature,
} from "../../lib/settingsFunctions";
import { createUUID } from "../../services/randomFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../../lib/vimcalVariables";
import GroupVoteHoldsSection from "../availability/groupVoteHoldsSection";
import {
  isUserMaestroUser,
  LINKABLE_TYPES,
} from "../../services/maestroFunctions";
import { getEventUserEventID } from "../../services/eventResourceAccessors";
import DidYouKnowAlert from "../availability/alerts/didYouKnowAlert";
import { GROUP_VOTE_HOLD_TEXT } from "../../services/globalMaestroVariables";
import {
  getEventsHoldCategories,
  getEventsHoldColor,
  getEventsHoldTitle,
} from "../../services/maestro/maestroAccessors";
import CreatableSelect from "../../../components/creatableSelect";
import { FEATURE_TRACKING_ACTIONS, trackFeatureUsage } from "../tracking";
import CriticalAttendeeSelect from "./criticalAttendeeSelect";
import CustomButtonV2 from "../buttons/customButtonV2";
import {
  determineDefaultModalStyle,
  MODAL_OVERLAY_Z_INDEXES,
} from "../../lib/modalFunctions";
import { deleteEventsFromIndexDB } from "../../lib/dbFunctions";
import holdsBroadcast from "../../broadcasts/holdsBroadcast";
import { getSidePoppedOverModalBorder } from "../../lib/styleFunctions";
import { isInternalTeamUser } from "../../lib/featureFlagFunctions";
import { sendMessageAndDataToSentry } from "../../lib/sentryFunctions";

const CONTENT_WIDTH = 325;
const GROUP_VOTE_DETAIL_SECTION = "GROUP_VOTE_DETAIL_SECTION";

const WEEKLY_CALENDAR_MODAL = "WEEKLY_CALENDAR_MODAL";
const CONFIRM_DISCARD_MODAL = "CONFIRM_DISCARD_MODAL";
const ERROR_MESSAGE = "ERROR_MESSAGE";

const ANY_DURATION = "Any";

const GROUP_POLL_VARIANT = "poll";

/**
 * @typedef {{
 *   backgroundColor: string
 *   displayAsAllDay: true
 *   end_time_utc: string
 *   eventEnd: Date
 *   eventStart: Date
 *   event_end: {
 *     dateTime: string
 *   } | {
 *     date: string
 *   }
 *   hideCancel: unknown
 *   id: string
 *   index: number
 *   isAvailability: boolean
 *   isGroupVote: boolean
 *   isNew: boolean
 *   isTemporary: boolean
 *   isTemporaryAIEvent: boolean
 *   raw_json: {
 *     status: string
 *   }
 *   rbcEventEnd: Date
 *   resourceId: string
 *   start_time_utc: string
 *   status: string
 * }} InputSlot
 */

export default function GroupVoteDetailPage({
  onClickBack,
  isNew,
  bookingLink,
  selectedUser,
  variant = GROUP_POLL_VARIANT, // "poll" | "spreadsheet"
}) {
  const shouldShowTopBar = useSelector((state) => state.shouldShowTopBar);
  const temporaryEvents = useSelector((state) => state.temporaryEvents);
  const eventFormEmails = useSelector((state) => state.eventFormEmails);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const setGroupVoteLinkByToken = useGroupVoteStore(
    (state) => state.setGroupVoteLinkByToken,
  );
  const setGroupSpreadsheetLinkByToken = useGroupVoteStore(
    (state) => state.setGroupSpreadsheetLinkByToken,
  );
  const setGroupLinks = useGroupVoteStore((state) => state.setGroupLinks);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const selectedCalendarView = useSelector(
    (state) => state.selectedCalendarView,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const isDuplicate = () => {
    return isNew && !isEmptyObjectOrFalsey(bookingLink);
  };

  // keep track of what the user dragged so if we switch duration, we don't change it based on current temporary events
  // this could cause issues when switching between durations (esp from 60 to 15 and then back to 60)
  const userDraggedSlots = useRef([]);

  const {
    title: inputTitle,
    location: inputLocation,
    description: inputDescription,
    conferencing: inputConferencing,
    calendarProviderID: inputCalendarProviderID,
    timeZone: inputTimeZone,
    hidePoll: inputHidePoll,
    selectedSlots: inputSelectedSlots,
    token,
    attendees: inputAttendees,
    duration,
    slug: existingSlug,
    eventsHold,
    criticalAttendees: existingCriticalAttendees,
  } = getGroupVoteLinkInfo({
    currentUser: selectedUser,
    bookingLink,
    canEdit: true,
    isDuplicate: isDuplicate(),
    allCalendars,
    defaultUserTimeZone: getDefaultUserTimeZone({
      masterAccount,
      user: currentUser,
    }),
    isNew,
  });

  const [title, setTitle] = useState(inputTitle || "");
  const [location, setLocation] = useState(inputLocation || "");
  const [minutesQuery, setMinuteQuery] = useState("");
  const [conferencing, setConferencing] = useState(
    inputConferencing || NO_CONFERENCING_OPTION,
  );

  const [description, onChangeDescription] = useState(inputDescription || "");
  const [timeZone, setTimeZone] = useState(inputTimeZone || guessTimeZone());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHiddenPoll, setIsHiddenPoll] = useState(inputHidePoll ?? false);
  const [attendees, setAttendees] = useState(inputAttendees ?? []);
  const [selectedSlots, setSelectedSlots] = useState(inputSelectedSlots ?? []);
  const temporaryTimeZones = useSelector((state) => state.temporaryTimeZones);
  const format24HourTime = useSelector((state) => state.format24HourTime);
  /* Holds State */
  const availabilitySettings = getUserAvailabilitySettings({
    user: selectedUser,
  });
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const [isCreatingHolds, setIsCreatingHolds] = useState(false);
  const [holdsTitle, setHoldsTitle] = useState(
    getEventsHoldTitle({ availabilitySettings, eventsHold }),
  );
  const [criticalAttendees, setCriticalAttendees] = useState(
    existingCriticalAttendees,
  );
  const [holdsColorID, setHoldsColorID] = useState(
    getEventsHoldColor({ availabilitySettings, eventsHold }),
  );
  const [selectedOutlookCategories, setSelectedOutlookCategories] = useState(
    getEventsHoldCategories({ eventsHold }),
  );

  // const [modalContent, setModalContent] = useState(CONFIRM_DISCARD_MODAL); //CONFIRM_DISCARD_MODAL
  const [modalContent, setModalContent] = useState(WEEKLY_CALENDAR_MODAL); //CONFIRM_DISCARD_MODAL
  const [warning, setWarning] = useState("");
  const [hasChanged, setHasChanged] = useState(false);
  const componentIsMounted = useIsMounted();

  const [selectedCalendar, setSelectedCalendar] = useState(
    getCalendarFromProviderID({
      allCalendars,
      providerID: inputCalendarProviderID,
      selectedUser,
      allLoggedInUsers,
      masterAccount,
    }),
  );
  const allowBreakingSlotsIntoDuration = variant === GROUP_POLL_VARIANT;

  const [breakIntoDuration, setShouldBreakIntoDuration] = useState(
    allowBreakingSlotsIntoDuration && isBreakDurationOn(duration),
  );
  const getDefaultDuration = () => {
    if (allowBreakingSlotsIntoDuration) {
      return duration || 30;
    }
    return duration || ANY_DURATION;
  };

  const [breakDuration, setBreakDuration] = useState(getDefaultDuration());
  const getMinuteList = () => {
    return [{ value: ANY_DURATION, label: ANY_DURATION }].concat(
      getGroupVoteDurationList(),
    );
  };
  const minutesList = useMemo(() => {
    return getMinuteList();
  }, []);

  const slotContentText = useMemo(() => (
    createGroupVoteTimeText(selectedSlots, format24HourTime)
  ), [format24HourTime, selectedSlots]);

  /**
   * @type {(
   *   newAttendees: { label: string, name: string, value: string }[]
   *   fetchSearchContacts?: boolean
   * ) => void}
   */
  const addAttendee = useCallback((newAttendees, fetchSearchContacts) => {
    if (isEmptyArrayOrFalsey(newAttendees)) {
      return;
    }
    // attendee: /{name: 'Seamus', email: 'seamus@vimcal.com', slots: Array(1)}
    const existingEmails = attendees.map((a) => getObjectEmail(a));
    const filteredAttendees = newAttendees.filter(
      (a) => !existingEmails.includes(lowerCaseAndTrimString(a.value)),
    );
    if (isEmptyArrayOrFalsey(filteredAttendees)) {
      return;
    }

    const updatedAttendees = attendees.concat(
      filteredAttendees.map((a) => {
        return {
          name: a.name,
          email: lowerCaseAndTrimString(a.value),
          slots: [],
        };
      }),
    );
    setAttendees(updatedAttendees);
    if (fetchSearchContacts && updatedAttendees?.length > 1) {
      const emails = updatedAttendees.map((a) => getObjectEmail(a));
      broadcast.publish("ADD_EMAIL_TO_SEARCHED_EMAILS", {
        contact: {
          email: formatNamesWithComma(emails),
          emailArray: emails,
          hasMultiple: true,
          name: formatNamesWithComma(
            updatedAttendees.map(
              (attendee) => attendee.name || getObjectEmail(attendee),
            ),
          ),
        },
        email: emails,
        userEmail: getUserEmail(selectedUser),
      });
    }
  }, [attendees, selectedUser]);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.ADD_ATTENDEE_TO_GROUP_VOTE,
      addAttendee,
    );

    return () => {
      availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.ADD_ATTENDEE_TO_GROUP_VOTE);
    };
  }, [addAttendee]);

  useEffect(() => {
    if (isInternalTeamUser(selectedUser)) {
      console.log("Debugging group vote slots", {
        selectedSlots,
        userDraggedSlots: userDraggedSlots.current,
      });
    }
  }, [selectedSlots]);

  useEffect(() => {
    if (isNew) {
      setTimeZone(currentTimeZone);
    }
  }, [currentTimeZone]);

  useEffect(() => {
    if (!allowBreakingSlotsIntoDuration) {
      return;
    }
    if (breakIntoDuration) {
      setGroupVoteDurationOnDrag(breakDuration);
    } else {
      removeGroupVoteDurationOnDrag();
    }
  }, [breakDuration, breakIntoDuration, allowBreakingSlotsIntoDuration]);

  const setInitialSlots = () => {
    const initialTemporaryEvents =
      currentTimeZone !== inputTimeZone
        ? inputSelectedSlots.map((s) => {
          if (s.displayAsAllDay) {
            return s;
          }

          return {
            ...s,
            eventStart: getTimeInAnchorTimeZone(
              s.eventStart,
              timeZone,
              currentTimeZone,
            ),
            eventEnd: getTimeInAnchorTimeZone(
              s.eventEnd,
              timeZone,
              currentTimeZone,
            ),
            rbcEventEnd: getTimeInAnchorTimeZone(
              s.rbcEventEnd,
              timeZone,
              currentTimeZone,
            ),
          };
        })
        : inputSelectedSlots;

    setSelectedSlots(inputSelectedSlots);
    mainCalendarBroadcast.publish(
      "SET_TEMPORARY_EVENTS",
      initialTemporaryEvents,
    );
  };

  const durationRef = useRef(null);

  const descriptionRef = useRef(null);

  const isFirstUpdate = useRef(true); // avoid useEffect in initial render

  /**
   * @type {(slot: InputSlot) => void}
   */
  const deleteSlot = useCallback((slot) => {
    const updatedSlots = (temporaryEvents ?? []).filter(
      (e) => !isSameSlot(e, slot),
    );
    setSlotToSelectedSlots(updatedSlots);
    mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", updatedSlots);
  }, [temporaryEvents]);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.DELETE_GROUP_VOTE_EVENT,
      deleteSlot,
    );

    return () => {
      availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.DELETE_GROUP_VOTE_EVENT);
    };
  }, [deleteSlot]);

  /**
   * TODO: handle multi day in preview and on availability project
   * @type {(inputSlots: InputSlot[]) => void}
   */
  const setSlotToSelectedSlots = useCallback((inputSlots) => {
    if (isEmptyArrayOrFalsey(inputSlots)) {
      setSelectedSlots([]);
      return;
    }

    const filteredInputSlots = inputSlots.filter(isGroupVoteEvent);

    const updatedSlots =
      currentTimeZone !== timeZone
        ? filteredInputSlots.map((s) => {
          if (s.displayAsAllDay) {
            return s;
          }

          return {
            ...s,
            eventStart: getTimeInAnchorTimeZone(
              s.eventStart,
              currentTimeZone,
              timeZone,
            ),
            eventEnd: getTimeInAnchorTimeZone(
              s.eventEnd,
              currentTimeZone,
              timeZone,
            ),
            rbcEventEnd: getTimeInAnchorTimeZone(
              s.rbcEventEnd,
              currentTimeZone,
              timeZone,
            ),
          };
        })
        : filteredInputSlots;
    setSelectedSlots(updatedSlots);
  }, [currentTimeZone, timeZone]);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.SET_GROUP_VOTE_SLOT,
      setSlotToSelectedSlots,
    );

    return () => {
      availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.SET_GROUP_VOTE_SLOT);
    };
  }, [setSlotToSelectedSlots]);

  useEffect(() => {
    if (duration && isInt(duration)) {
      setDefaultGroupVoteClickDuration(duration);
    } else {
      removeDefaultGroupVoteClickDuration();
    }

    return () => {
      removeDefaultGroupVoteClickDuration();
    };
  }, []);

  useEffect(() => {
    // didMount
    if (selectedCalendarView === BACKEND_MONTH) {
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
        7,
      ); // go to week view
    }

    groupVoteBroadcast.subscribe("SET_USER_DRAGGED_SLOTS", setUserDraggedSlots);

    if (inputTimeZone !== currentTimeZone) {
      broadcast.publish("SELECT_TIME_ZONE", {
        timeZone: inputTimeZone,
        doNotConcatTimeZones: true,
      });
    }
    setInitialSlots();

    if (isNew) {
      if (eventFormEmails?.length > 0) {
        const updatedAttendees = eventFormEmails.map((email) => {
          return {
            name: "",
            email: email,
            slots: [],
          };
        });
        setAttendees(updatedAttendees);
      }
    } else {
      if (attendees?.length > 0) {
        broadcast.publish("ADD_EMAIL_TO_SEARCHED_EMAILS", {
          email: attendees.map((a) => getObjectEmail(a)),
          userEmail: getUserEmail(selectedUser),
        });
      }
    }

    // willUnmount
    return () => {
      availabilityBroadcast.unsubscribe("ESCAPE_GROUP_VOTE");
      groupVoteBroadcast.unsubscribe("SET_USER_DRAGGED_SLOTS");

      mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", null);

      const defaultUserTimeZone = getDefaultUserTimeZone({
        masterAccount,
        user: currentUser,
      });
      if (
        currentTimeZone !== defaultUserTimeZone ||
        temporaryTimeZones?.length > 0
      ) {
        // only reset time zone if there's multiple temporary ones
        broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
      }

      broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
      removeGroupVoteDurationOnDrag();
    };
  }, []);

  useEffect(() => {
    if (isFirstUpdate.current || hasChanged) {
      isFirstUpdate.current = false;
      return;
    }

    setHasChanged(true);
  }, [
    title,
    location,
    description,
    conferencing,
    selectedCalendar,
    timeZone,
    isHiddenPoll,
    selectedSlots,
    attendees,
    criticalAttendees,
  ]);

  const setUserDraggedSlots = ({
    originalSlot = null,
    newSlot,
    type = ADD_SLOT,
  }) => {
    const setSlot = (updatedSlots) => {
      const combinedSlots = combineSlots(updatedSlots);
      userDraggedSlots.current = combinedSlots;
    };

    if (type === ADD_SLOT) {
      // add slot
      const updatedSlot = userDraggedSlots.current.concat(newSlot);
      setSlot(updatedSlot);
    } else if (type === UPDATE_SLOT) {
      // update slot
      const filteredSlots = userDraggedSlots.current.filter(
        (s) => !isSameSlot(s, originalSlot),
      );
      setSlot(filteredSlots.concat(newSlot));
    } else if (type === DELETE_SLOT) {
      // delete slot
      const filteredSlots = userDraggedSlots.current.filter(
        (s) => !isSameSlot(s, originalSlot),
      );
      setSlot(filteredSlots);
    }
  };

  const checkForConfirmDiscard = useCallback(() => {
    if (!hasChanged) {
      confirmDiscard();
    } else {
      setModalContent(CONFIRM_DISCARD_MODAL);
    }
  }, [hasChanged]);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.ESCAPE_GROUP_VOTE_DETAIL,
      checkForConfirmDiscard,
    );

    return () => {
      availabilityBroadcast.unsubscribe(AVAILABILITY_BROADCAST_VALUES.ESCAPE_GROUP_VOTE_DETAIL);
    };
  }, [checkForConfirmDiscard]);

  const renderNameAndBackButton = () => {
    const onClickPreview = () => {
      layoutBroadcast.publish(
        LAYOUT_BROADCAST_VALUES.GROUP_VOTE_PREVIEW,
        getGroupVoteData(),
      );
    };

    const getTitle = () => {
      if (variant === GROUP_POLL_VARIANT) {
        // poll
        if (isNew) {
          return "New Group poll";
        }
        return "Group poll";
      } else {
        // spread sheet
        if (isNew) {
          return "New Group spreadsheet";
        }
        return "Group spreadsheet";
      }
    };

    return (
      <div className="flex justify-between items-center pl-2.5 w-full mt-2">
        <div className={classNames("flex", "justify-start")}>
          <div
            className="close-button-wrapper padding-bottom-0px-important"
            style={{ marginRight: 10 }}
            onClick={checkForConfirmDiscard}
          >
            <ChevronLeft color="#707070" size={20} className="clickable-icon" />
          </div>

          <div className="create-availability-select-availability-title">
            {getTitle()}
          </div>
        </div>

        <CustomButton
          buttonType={WHITE_BUTTON_WITHOUT_FOCUS}
          onClick={onClickPreview}
          label="Preview"
          className={classNames(
            "min-width-initial-important mr-4",
            isEmptyArray(selectedSlots) || !title ? "opacity-0" : "",
          )}
          labelClassNameOverride={classNames(
            "font-size-300-important font-size-12px-override",
          )}
        />
      </div>
    );
  };

  const onChangeTitle = (e) => {
    setTitle(getInputStringFromEvent(e));
    setWarning("");
  };

  const renderTitleInput = () => {
    return (
      <div className="slots-row-default-margin-top group-vote-row-grid items-center">
        <div className="availability-detail-section-text">Title</div>
        <input
          className={"create-event-input-field"}
          style={{ width: "210px" }}
          value={title}
          onChange={onChangeTitle}
          autoFocus={true}
        />
      </div>
    );
  };

  const onKeyDown = (e) => {
    if (e?.keyCode === KEYCODE_ESCAPE && durationRef.current?.blur) {
      durationRef.current.blur();
    }
  };

  const renderBreakDuration = () => {
    const renderDuration = () => {
      const getValue = () => {
        if (allowBreakingSlotsIntoDuration) {
          return breakIntoDuration ? breakDuration : ANY_DURATION;
        }
        return breakDuration;
      };

      return (
        <div className="flex items-center">
          <CreatableSelect
            isSearchable={true}
            ref={durationRef}
            className={classNames(
              "select-duration-time",
              isDarkMode ? "dark-mode-select" : "",
              "select-default-font-size",
              "default-font-color",
            )}
            classNamePrefix="dark-mode"
            value={{
              value: getValue(),
              label: getValue(),
            }}
            isDisabled={!isNew}
            options={minutesList}
            onChange={(time) => {
              // remove all temporary events
              if (time.value === ANY_DURATION) {
                if (breakIntoDuration) {
                  mainCalendarBroadcast.publish(
                    "SET_TEMPORARY_EVENTS",
                    userDraggedSlots.current,
                  );
                  setShouldBreakIntoDuration(false);
                }
                removeDefaultGroupVoteClickDuration();
                setBreakDuration(ANY_DURATION);
              } else {
                const newBreakDuration = parseInt(time.value);
                if (!newBreakDuration || !isInt(newBreakDuration)) {
                  return;
                }

                if (allowBreakingSlotsIntoDuration && !breakIntoDuration) {
                  setShouldBreakIntoDuration(true);
                }

                // chop into duration
                if (allowBreakingSlotsIntoDuration) {
                  let updatedVotes = [];
                  userDraggedSlots.current.forEach((e) => {
                    const newSlots = splitSlotIntoDuration({
                      breakDuration: newBreakDuration,
                      start: e.eventStart,
                      end: e.eventEnd,
                      currentSlots: updatedVotes,
                      isGroupVote: true,
                    });
                    updatedVotes = updatedVotes.concat(newSlots);
                  });
                  mainCalendarBroadcast.publish(
                    "SET_TEMPORARY_EVENTS",
                    updatedVotes,
                  );
                }

                setDefaultGroupVoteClickDuration(newBreakDuration);
                setBreakDuration(newBreakDuration);
              }
            }}
            noOptionsMessage={() => "Set"}
            openMenuOnFocus={true}
            onKeyDown={onKeyDown}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={true}
            tabIndex={3}
            styles={getReactSelectBaseStyle({ isDarkMode, showBorder: true })}
            components={{ DropdownIndicator }}
          />
          <div className="ml-4 default-font-size">mins</div>
        </div>
      );
    };
    return (
      <div
        className={classNames(
          "mt-3",
          "availability-detail-section-text",
          "slots-row-container-medium display-grid-important", // since slots-row-container-medium has flex but we always want grid
          "group-vote-row-grid",
        )}
      >
        Duration
        {renderDuration()}
      </div>
    );
  };

  const renderHiddenPoll = () => {
    const onClickHidePoll = (e) => {
      hasStopEventPropagation(e);
      setIsHiddenPoll(!isHiddenPoll);
    };
    return (
      <div className="slots-row-default-margin-top group-vote-row-grid items-center">
        <div
          className={classNames(
            "availability-detail-section-text flex items-center",
          )}
        >
          Hide votes
          <MoreInformationHint
            containerClassName="ml-1 pt-1"
            title="Hide votes"
            variables={[
              "Participants’ names, comments, and votes are confidential. Only you can see the results.",
            ]}
            hintContainerWidth={"w-300px"}
            hintContainerClassName={classNames(
              "width-250-important",
              isDarkMode
                ? "background-color-modal-background-color"
                : "bg-white",
              "default-font-color",
            )}
          />
        </div>
        <CheckBox isChecked={isHiddenPoll} onChange={onClickHidePoll} />
      </div>
    );
  };

  const renderHoldSection = () => {
    /* In edit and no holds */
    if (!isNew && isEmptyObjectOrFalsey(eventsHold)) {
      return null;
    }

    return (
      <div className="slots-row-default-margin-top">
        <div className="mx-auto group-vote-row-grid">
          <div className={classNames("availability-detail-section-text ")}>
            Create Holds
          </div>
          <CheckBox
            disabled={!isNew}
            isChecked={isCreatingHolds || !isNew}
            onChange={() => {
              /* If toggling off, set to default hold title */
              if (isCreatingHolds) {
                setHoldsTitle(
                  getEventsHoldTitle({ availabilitySettings, eventsHold }),
                );
              }

              setIsCreatingHolds(!isCreatingHolds);
            }}
          />
        </div>
        {/* Hold details */}
        {isCreatingHolds ? (
          <GroupVoteHoldsSection
            holdsColorID={holdsColorID}
            selectedCalendar={selectedCalendar}
            selectedUser={selectedUser}
            setHoldsColorID={setHoldsColorID}
            setHoldsTitle={setHoldsTitle}
            title={holdsTitle}
            width={CONTENT_WIDTH}
            onChangeCategories={setSelectedOutlookCategories}
            selectedOutlookCategories={selectedOutlookCategories}
          />
        ) : null}
        {/* Hold sync alert */}
        {isNew ? null : (
          <div className="mx-auto" style={{ width: CONTENT_WIDTH }}>
            <DidYouKnowAlert
              subText={GROUP_VOTE_HOLD_TEXT.DESCRIPTION}
              title={GROUP_VOTE_HOLD_TEXT.TITLE}
            />
          </div>
        )}
      </div>
    );
  };

  const renderWhenSection = () => {
    const determineContent = () => {
      if (selectedSlots.length === 0) {
        return (
          <div>
            Drag the slots on the calendar to create bookable times to share
            with others.
          </div>
        );
      } else {
        return renderTimeSlotsLine(slotContentText);
      }
    };
    return (
      <div className="slots-row-default-margin-top group-vote-row-grid">
        <div className="default-font-size font-weight-300-important secondary-text-color">
          When
        </div>

        <div
          id="availability-text-content"
          className="select-availability-panel-content-wrapper"
          style={{ width: 218 }}
        >
          {determineContent()}
        </div>
      </div>
    );
  };

  const renderAttendeeSection = () => {
    const onAddAttendee = (newAttendee) => {
      if (!newAttendee?.value) {
        return;
      }
      broadcast.publish("ADD_EMAIL_TO_SEARCHED_EMAILS", {
        email: newAttendee?.value,
      });
      addAttendee([newAttendee]);
    };

    const onAddAttendeeList = (newAttendeeList) => {
      if (isEmptyArray(newAttendeeList)) {
        return;
      }
      addAttendee(newAttendeeList, true);
    };

    const onRemoveEmail = (email) => {
      broadcast.publish("REMOVE_EMAIL", email);
      const updatedAttendees = attendees.filter(
        (a) => !isSameEmail(getObjectEmail(a), email),
      );
      setAttendees(updatedAttendees);
    };

    const toggleCriticalAttendee = (email) => {
      const formattedEmail = formatEmail(email);

      if (criticalAttendees.includes(formattedEmail)) {
        /* Use filter to remove the email */
        setCriticalAttendees(
          criticalAttendees.filter((attendeeEmail) => {
            return !isSameEmail(attendeeEmail, formattedEmail);
          }),
        );
        return;
      }

      setCriticalAttendees([...criticalAttendees, formattedEmail]);
    };

    return (
      <div
        className={classNames(
          "slots-row-default-margin-top",
          "justify-between",
          "group-vote-row-grid",
        )}
      >
        <div className="availability-detail-section-text mt-2.5">Attendees</div>

        <div>
          <ReactSelectAttendeeAutoComplete
            createLabel="Add"
            // innerRef={this.selectRef}
            addAttendees={onAddAttendee}
            addAttendeeList={onAddAttendeeList}
            defaultText="Search for people"
            // onEscape={() => this.props.setShouldDisplayMenu(false)}
            componentLocation={
              REACT_ATTENDEE_SELECT_LOCATION.GROUP_VOTE_DETAIL_PAGE_LOCATION
            }
            selectedGuests={attendees}
            useRecentlySearchedContacts={true}
            includeEmailAndName={true}
            useRecentlySearched={true}
            controllerWidth={220}
          />
        </div>

        <CriticalAttendeeSelect
          attendees={attendees}
          criticalAttendees={criticalAttendees}
          onRemoveEmail={onRemoveEmail}
          toggleCriticalAttendee={toggleCriticalAttendee}
        />
      </div>
    );
  };

  const renderTimeZoneSelection = () => {
    const onChangeTimeZone = (timeZone) => {
      broadcast.publish("SELECT_TIME_ZONE", {
        timeZone,
        doNotConcatTimeZones: true,
      });
      setTimeZone(timeZone);
    };
    return (
      <div className="slots-row-default-margin-top group-vote-row-grid items-center">
        <div className="availability-detail-section-text">Time Zone</div>
        <SelectTimeZoneInput
          inputClassName="time-zone-selection-width background-color-transparent-important"
          timeZone={timeZone}
          onChange={onChangeTimeZone}
          isDisabled={!isNew}
          updateSelectValueOnChange={true}
        />
      </div>
    );
  };

  const determineStyle = () => {
    if (isModalWeeklyCalendar()) {
      return {
        overlay: {
          position: "fixed",
          inset: `${calculateMarginTop(shouldShowTopBar)}px 360px 0px 0px`,
          backgroundColor: "transparent",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {...({
          padding: "20px",
          position: "absolute",
          bottom: "auto",
          zIndex: 5,
          backgroundColor: isDarkMode
            ? DARK_MODE_MODAL_BACKGROUND_COLOR
            : "white",
          color: isDarkMode ? DARK_MODE_MODAL_TEXT_COLOR : "",
          width: "calc(100% - 20px)",
          left: "10px",
          top: "10px",
          height: "calc(100% - 20px)",
        }),
        ...getSidePoppedOverModalBorder(isDarkMode),
        },
      };
    } else {
      return determineDefaultModalStyle(isDarkMode);
    }
  };

  const isModalWeeklyCalendar = () => {
    return modalContent === WEEKLY_CALENDAR_MODAL;
  };

  const isModalErrorMessage = () => {
    return modalContent === ERROR_MESSAGE;
  };

  const isModalConfirmDiscard = () => {
    return modalContent === CONFIRM_DISCARD_MODAL;
  };

  const closeModal = () => {
    setModalContent(WEEKLY_CALENDAR_MODAL);
  };

  const getGroupVoteData = () => {
    const isGroupPoll = variant === GROUP_POLL_VARIANT;

    const getDuration = () => {
      if (!breakDuration) {
        return null;
      }
      if (allowBreakingSlotsIntoDuration) {
        return !breakIntoDuration || breakDuration === ANY_DURATION
          ? null
          : breakDuration;
      }
      if (breakDuration === ANY_DURATION) {
        return null;
      }
      return breakDuration;
    };

    const baseData = {
      title,
      location,
      description,
      conferencing: conferencing?.value,
      time_zone: timeZone,
      ...(isGroupPoll ? { anonymous: isHiddenPoll } : {}),
      selected_slots: convertSlotsIntoISOString(
        removeDuplicateSlots(selectedSlots),
        timeZone,
      ),
      token,
      attendees: isGroupPoll
        ? attendees
        : attendees.map((a) => ({ ...a, uuid: a.uuid ?? createUUID() })),
      duration: getDuration(),
      critical_attendees: criticalAttendees,
    };

    if (baseData.selected_slots.length >= 20) {
      sendMessageAndDataToSentry(
        "[GroupVoteDetailPage] High volume of slots in group vote link",
        {
          ...baseData,
          selectedSlots,
          userDraggedSlots: userDraggedSlots.current,
          breakDuration,
        },
        getUserEmail(selectedUser),
      );
    }

    if (isNew) {
      return {
        ...baseData,
        slug: slugifyString(`${title}-${token.slice(-6)}`),
      };
    }

    return {
      ...baseData,
      slug: existingSlug, // For setting description for holds
      calendar_provider_id: getCalendarProviderId(selectedCalendar),
    };
  };

  const getGroupVoteUsername = () => {
    const { userName } = getUserName({
      masterAccount,
      user: selectedUser,
    });

    return userName;
  };

  const constructGroupVoteDescription = (slug) => {
    const isGroupPoll = variant === GROUP_POLL_VARIANT;
    const urlAvailabilityType = isGroupPoll ? "g" : "gs";
    const bookingLinkType = isGroupPoll ? "Group vote" : "Group spreadsheet";

    return `${bookingLinkType} link:\n${determineBookingURL()}/${urlAvailabilityType}/${getGroupVoteUsername()}/${slug}\n\n${
      shouldHideDefaultSignature({ masterAccount }) ? "" : VIMCAL_SIGNATURE
    }`;
  };

  const constructHoldEvents = (groupVoteData) => {
    if (isEmptyObjectOrFalsey(groupVoteData)) {
      return;
    }

    /* Get slots data for group vote */
    const { slug } = groupVoteData;

    const combinedSelectedSlots = combineSlots(selectedSlots);
    const formattedCombinedSlots = convertSlotsIntoISOString(
      removeDuplicateSlots(combinedSelectedSlots),
      timeZone,
    );

    return formattedCombinedSlots?.map((slot) => {
      const { end, endDate, start, startDate } = slot;
      const holdEvent = {
        event_end: { dateTime: end, date: endDate },
        google_id: getCalendarProviderId(selectedCalendar),
        reminders: { overrides: [], useDefault: false },
        is_reminder_on: false,
        event_start: { dateTime: start, date: startDate },
        title: holdsTitle,
        transparency: FREE_DURING_EVENT,
        description: constructGroupVoteDescription(slug),
      };

      if (
        isCalendarOutlookCalendar(selectedCalendar) &&
        !isEmptyArrayOrFalsey(selectedOutlookCategories)
      ) {
        holdEvent["categories"] = selectedOutlookCategories;
      }

      if (!isCalendarOutlookCalendar(selectedCalendar) && holdsColorID) {
        holdEvent["colorId"] = holdsColorID;
      }

      return omitNullOrUndefinedProps(holdEvent);
    });
  };

  const getHoldsData = (groupVoteData) => {
    const isOutlookCalendar = isCalendarOutlookCalendar(selectedCalendar);
    const vholds_id = eventsHold?.token ?? `vholds_${createUUID(16)}`;

    return {
      categories: isOutlookCalendar ? selectedOutlookCategories : null,
      google_color_id: isOutlookCalendar ? null : holdsColorID,
      events: constructHoldEvents(groupVoteData),
      title: holdsTitle,
      user_calendar_id: getCalendarUserCalendarID(selectedCalendar),
      vholds_id,
    };
  };

  const createHolds = ({ groupVoteData, isGroupPoll, token }) => {
    holdsBroadcast.publish(
      HOLDS_BROADCAST_VALUES.CREATE_LINKABLE_HOLDS,
      {
        calendar: selectedCalendar,
        holdEvents: getHoldsData(groupVoteData),
        linkableToken: token,
        linkableType: isGroupPoll
          ? LINKABLE_TYPES.GROUP_VOTE_LINK
          : LINKABLE_TYPES.GROUP_SPREADSHEET_LINK,
        user: selectedUser,
      },
    );
  };

  const onCreateGroupVoteLink = () => {
    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.GROUP_VOTE}::onCreateGroupVoteLink`,
      userToken: getUserToken(selectedUser),
    });

    const isGroupPoll = variant === GROUP_POLL_VARIANT;
    const path = isGroupPoll
      ? LATEST_GROUP_VOTE_LINKS
      : GROUP_SPREADSHEET_LINKS_ENDPOINT;
    const url = constructRequestURL(path, isVersionV2());
    const groupVoteData = getGroupVoteData();

    if (isVersionV2()) {
      groupVoteData["calendar_provider_id"] =
        getCalendarProviderId(selectedCalendar);
    } else {
      groupVoteData["google_calendar_id"] =
        getCalendarProviderId(selectedCalendar);
    }

    const payloadData = {
      body: JSON.stringify({
        [isGroupPoll ? "group_vote_link" : "group_spreadsheet_link"]:
          groupVoteData,
      }),
    };

    return Fetcher.post(url, payloadData, true, getUserEmail(selectedUser))
      .then((response) => {
        if (!componentIsMounted.current) {
          return;
        }

        if (isEmptyObjectOrFalsey(response) || response.error) {
          setModalContent(ERROR_MESSAGE);
          setIsSubmitting(false);
          return;
        }

        let groupVoteLink;
        if (isGroupPoll) {
          const groupVoteLinks = response.group_vote_links;
          setGroupLinks({ groupVoteLinks });
          groupVoteLink = (groupVoteLinks || []).find(
            (l) => l?.token === token,
          );
        } else {
          const groupSpreadsheetLinks = response.group_spreadsheet_links;
          setGroupLinks({ groupSpreadsheetLinks });
          groupVoteLink = (groupSpreadsheetLinks || []).find(
            (l) => l?.token === token,
          );
        }

        if (isEmptyObjectOrFalsey(groupVoteLink)) {
          onClickBack();
          return;
        }

        const { slug } = groupVoteLink;
        const { userName } = getUserName({
          masterAccount,
          user: selectedUser,
        });
        const isSpreadsheet = !isGroupPoll;
        availabilityBroadcast.publish("OPEN_GROUP_VOTE_LINK_INVITE", {
          token,
          groupVoteLinkURL: getGroupVoteURLLink({
            userName,
            slug,
            token,
            isSpreadsheet,
          }),
          newAttendeeEmails: attendees?.map((a) => getObjectEmail(a)),
          isSpreadsheet,
        });

        if (isCreatingHolds) {
          createHolds({ groupVoteData, isGroupPoll, token });
        }

        onClickBack();
      })
      .catch(handleError);
  };

  /* Update the hold details for existing hold events */
  const handleHoldsUpdate = ({ response }) => {
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }

    const {
      hold_details: holdDetails,
      hold_events: createdEvents,
      deleted_events: deletedEvents,
    } = response;
    if (!isEmptyArrayOrFalsey(createdEvents)) {
      broadcast.publish(
        BROADCAST_VALUES.PARSE_MULTIPLE_EVENTS_RESPONSE,
        {
          calendar: getCalendarObject(selectedCalendar),
          events: response.hold_events,
        },
        "createGroupVoteHolds",
      );
    }

    const userEventIDs = deletedEvents?.map((event) =>
      getEventUserEventID(event),
    );

    deleteEventsFromIndexDB({
      userEventIDs,
      userEmail: getUserEmail(selectedUser),
      currentUserEmail: getUserEmail(selectedUser),
      isOnUserDelete: true,
      where: "groupVoteDetailPage::handleHoldsUpdate",
    });

    if (!isEmptyArrayOrFalsey(userEventIDs)) {
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS,
        { userEventIDs },
      );
    }

    mainCalendarBroadcast.publish(
      MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENTS_HOLD_DETAILS,
      { holdDetails },
    );
  };

  const onUpdateGroupVoteLink = () => {
    const isGroupPoll = variant === GROUP_POLL_VARIANT;
    const path = isGroupPoll
      ? `${LATEST_GROUP_VOTE_LINKS}/${token}`
      : `${GROUP_SPREADSHEET_LINKS_ENDPOINT}/${token}`;
    const url = constructRequestURL(path, isVersionV2());
    const groupVoteData = getGroupVoteData();
    const newAttendeeEmails = getArrayDifference(
      attendees?.map((a) => getObjectEmail(a)),
      inputAttendees?.map((a) => getObjectEmail(a)),
    );
    const doHoldsExist = !isEmptyObjectOrFalsey(eventsHold);
    const payloadData = {
      body: JSON.stringify({
        [isGroupPoll ? "group_vote_link" : "group_spreadsheet_link"]:
          groupVoteData,
        event_holds: doHoldsExist ? getHoldsData(groupVoteData) : undefined,
      }),
    };
    const userEmail = getUserEmail(selectedUser);

    return Fetcher.patch(url, payloadData, true, userEmail)
      .then((response) => {
        if (!componentIsMounted.current) {
          return;
        }

        if (isEmptyObjectOrFalsey(response) || response.error) {
          setModalContent(ERROR_MESSAGE);
          setIsSubmitting(false);
          return;
        }

        const { userName } = getUserName({
          masterAccount,
          user: selectedUser,
        });

        const { group_vote_link, group_spreadsheet_link, hold_details } =
          response;
        const updatedBookingLink = isGroupPoll
          ? group_vote_link
          : group_spreadsheet_link;
        const linkUrl = getGroupVoteURLLink({
          userName,
          slug: updatedBookingLink.slug,
          token,
          isSpreadsheet: !isGroupPoll,
        });

        if (isGroupPoll) {
          setGroupVoteLinkByToken(updatedBookingLink);
        } else {
          setGroupSpreadsheetLinkByToken(updatedBookingLink);
        }

        if (!isEmptyObjectOrFalsey(hold_details)) {
          handleHoldsUpdate({ response });
        }

        availabilityBroadcast.publish("OPEN_GROUP_VOTE_LINK_INVITE", {
          token,
          groupVoteLinkURL: linkUrl,
          newAttendeeEmails,
          isSpreadsheet: !isGroupPoll,
        });

        onClickBack();
      })
      .catch(handleError);
  };

  const onClickSave = () => {
    if (title.length === 0) {
      setWarning("Group Vote links need a title");
      return;
    } else if (selectedSlots.length === 0) {
      setWarning("At least one available slot need to be selected");
      return;
    }

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    if (isNew) {
      onCreateGroupVoteLink();
    } else {
      onUpdateGroupVoteLink();
    }
  };

  const renderSaveButton = () => {
    const isDisabled = !hasChanged && !isDuplicate();
    const buttonText = isNew ? "Create" : "Save";
    return (
      <div className="create-availability-copy-button-wrapper-pinned position-stick-important mt-14">
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          onClick={onClickSave}
          label={buttonText}
          shouldRenderSpinner={isSubmitting}
          disabled={isDisabled}
          id={CREATE_GROUP_VOTE_BUTTON_ID}
          style={{
            height: 43,
            width: CONTENT_WIDTH,
            marginLeft: 15,
            marginBottom: 16,
          }}
        />
      </div>
    );
  };

  const confirmDiscard = () => {
    closeModal();
    onClickBack && onClickBack();
  };

  const determineTitle = () => {
    if (isModalErrorMessage()) {
      return "Error";
    } else if (isModalConfirmDiscard()) {
      return "Are you sure you want to discard this draft?";
    } else if (!isNew) {
      return "Update Group Vote link";
    } else {
      return "Drag times for attendees to vote on";
    }
  };

  const isModalOpen = () => {
    if (isModalWeeklyCalendar()) {
      return false;
    }

    return true;
  };

  const determineModalContent = () => {
    if (isModalErrorMessage()) {
      return (
        <div>
          <div className="mb-10 default-font-size">{GENERIC_ERROR_MESSAGE}</div>

          <div className="flex justify-end">
            <CustomButton
              buttonType={BLUE_BUTTON}
              shouldFocus={true}
              onClick={closeModal}
              label="Ok"
            />
          </div>
        </div>
      );
    } else {
      return (
        <SaveAndCancelButton
          onClose={closeModal}
          onConfirm={confirmDiscard}
          confirmButtonText={"Discard"}
          autoFocusConfirmButton={true}
        />
      );
    }
  };

  const renderWeeklyCalendarModal = () => {
    return (
      <EventModalPopup
        maxHeight={"100%"}
        isOpen={isModalOpen()}
        onRequestClose={closeModal}
        title={determineTitle()}
        style={determineStyle()}
        hideCloseButton={isModalWeeklyCalendar()}
      >
        {determineModalContent()}
      </EventModalPopup>
    );
  };

  const renderWarning = () => {
    if (!warning) {
      return null;
    }

    return (
      <div
        className="create-event-time-warning"
        style={{
          marginLeft: 0,
          marginTop: 10,
          userSelect: "none",
        }}
      >
        {warning}
      </div>
    );
  };

  const renderEventDetails = () => {
    return (
      <div id={GROUP_VOTE_DETAIL_SECTION}>
        <AvailabilityAdditionalDetails
          selectedUser={selectedUser}
          isGroupVote={true}
          location={location}
          onChangeLocation={setLocation}
          minutesQuery={minutesQuery}
          onDurationInputChange={setMinuteQuery}
          conferencing={conferencing}
          onSelectConferencing={setConferencing}
          description={description}
          selectedCalendar={selectedCalendar}
          onChangeCalendar={(calendar) => {
            setSelectedCalendar(calendar);
            setSelectedOutlookCategories([]); // reset
          }}
          ignoreBroadcast={true}
          descriptionRef={descriptionRef}
          onChangeDescription={onChangeDescription}
          moveTitleHintNextToTitle={true}
          containerId="group-vote-link"
          checkForValidConferencing={true}
          hideReschedule={true}
          hideBlockedCalendars={true}
        />
      </div>
    );
  };

  const renderTimeZoneError = () => {
    if (timeZone === currentTimeZone) {
      return null;
    }
    return (
      <div
        className="default-font-size mt-1 event-form-different-time-zone-warning"
        style={{ width: CONTENT_WIDTH }}
      >
        Note: These times are in a different time zone than your calendar.
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderTitleInput()}
        {renderWarning()}
        {variant === GROUP_POLL_VARIANT ? renderHiddenPoll() : null}
        {isUserMaestroUser(masterAccount) ? renderHoldSection() : null}
        {renderBreakDuration()}
        {renderWhenSection()}
        {renderTimeZoneError()}
        {renderTimeZoneSelection()}
        {renderAttendeeSection()}
        {renderEventDetails()}
        {renderSaveButton()}
        {renderWeeklyCalendarModal()}
      </>
    );
  };

  return (
    <div
      id={GROUP_VOTE_DETAIL_CONTAINER}
      className={classNames(
        "create-availability-wrapper select-availability-content-layout-wrapper",
        "flex flex-col",
        "group-vote-detail-page-wrapper",
      )}
    >
      {renderNameAndBackButton()}
      {renderContent()}
    </div>
  );
}

function isBreakDurationOn(duration) {
  if (isNullOrUndefined(duration)) {
    return false;
  }

  if (duration > 0) {
    return true;
  }

  return false;
}
