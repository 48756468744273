import React from "react";

export default function VimcalLogoDarkBlue({ className }) {
  return (
    <svg
      width="108"
      height="27"
      viewBox="0 0 108 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <g clipPath="url(#clip0_725_691)">
        <path
          d="M2.27209 5.34426C0.618345 7.10645 0.0466859 9.57413 1.53421 11.4693C2.57331 12.7932 4.22473 13.3535 5.77192 13.0639C12.0624 6.96265 18.1072 4.98122 22.7272 4.59208C23.4416 4.53191 24.1212 4.50986 24.7616 4.51638C26.0278 3.17597 27.4531 1.97972 29.0137 0.960032L29.025 0.931432C29.025 0.931432 22.2845 -0.638898 15.723 0.298382C9.99356 1.11679 4.34145 3.13941 2.27209 5.34426Z"
          fill="#333863"
        />
        <path
          d="M6.92139 18.3324C5.6838 16.7556 5.70451 14.5925 6.83776 13.0535C12.8053 7.48537 18.4735 5.68008 22.7883 5.31665L23.9183 5.4582C21.8076 7.94556 20.2138 10.8618 19.2719 14.027C18.0298 14.3653 16.8033 14.9845 15.6377 15.7971C14.104 16.8663 12.6556 18.283 11.385 19.8785C9.77471 20.2695 8.01129 19.7209 6.92139 18.3324Z"
          fill="#333863"
        />
        <path
          d="M11.9143 20.3808C11.8261 20.5397 11.7468 20.7058 11.6774 20.8788C10.78 23.1147 11.8649 25.6547 14.1007 26.5522C14.8407 26.8492 15.6136 26.9293 16.3496 26.8221C17.0861 26.7188 17.8062 26.4256 18.4333 25.9334C18.564 25.8308 18.6874 25.7221 18.8031 25.6083C18.6991 25.1106 18.6103 24.6062 18.5374 24.0958C18.0826 20.9119 18.2851 17.7859 19.0419 14.8547C18.0402 15.1829 17.0355 15.7087 16.0534 16.3935C14.5691 17.4282 13.1565 18.8127 11.9143 20.3808Z"
          fill="#333863"
        />
        <path
          d="M22.7882 5.31674C23.2403 5.27866 23.6777 5.25639 24.0995 5.24731C24.0387 5.31728 23.9784 5.38762 23.9183 5.45829L22.7882 5.31674Z"
          fill="#333863"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0751 9.03814L38.4681 21.8817H41.3635L46.7566 9.03814L43.6315 9.02637L39.9158 18.2774L36.2002 9.02637L33.0751 9.03814Z"
          fill="#333863"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.1713 9.03813V21.8819H51.0069V9.03813H48.1713ZM73.6205 21.8819V13.9088C73.6205 10.5804 71.2213 8.81295 68.8249 8.81295C67.2651 8.81295 65.626 9.60696 64.6143 10.98L64.4326 11.2266L64.2697 10.9671C63.3844 9.55532 61.877 8.81295 60.3681 8.81295C58.8927 8.81295 57.7961 9.52419 57.0247 10.4401L56.6568 10.8771V9.03814H53.8211V21.8819H56.6568V15.0597C56.6568 14.1073 56.8685 13.1843 57.3581 12.4934C57.8547 11.793 58.6254 11.3484 59.6926 11.3484C60.5416 11.3484 61.2061 11.6821 61.6518 12.2798C62.0912 12.8691 62.3029 13.6945 62.3029 14.6594V21.8819H65.1386V14.5513C65.2034 13.7098 65.4576 12.9048 65.9537 12.3141C66.4554 11.7168 67.1909 11.3484 68.1745 11.3484C69.0235 11.3484 69.688 11.6821 70.1337 12.2798C70.5732 12.8691 70.7848 13.6945 70.7848 14.6594V21.8819H73.6205ZM87.6015 18.1235C86.606 20.4395 84.3561 22.0821 81.6276 22.0821C77.8926 22.0821 75.0056 19.0508 75.0056 15.46C75.0056 11.7235 78.0133 8.81295 81.6276 8.81295C84.3087 8.81295 86.5813 10.4334 87.6004 12.7949L85.0183 13.511C84.3884 12.2548 83.1596 11.3233 81.6276 11.3233C79.3745 11.3233 77.6411 13.2548 77.6411 15.46C77.6411 17.6671 79.3762 19.5717 81.6276 19.5717C83.1795 19.5717 84.4116 18.6672 85.0215 17.3864L87.6015 18.1235ZM98.9275 20.1515V21.8819H101.763V9.03814H98.9275V10.803L98.5678 10.4244C97.6543 9.4628 96.3569 8.81295 94.7407 8.81295C91.2591 8.81295 88.3689 11.7159 88.3689 15.46C88.3689 19.0561 91.261 22.0821 94.7407 22.0821C96.3595 22.0821 97.6571 21.4553 98.5697 20.5188L98.9275 20.1515ZM95.0661 19.5467C92.7438 19.5467 91.0043 17.6716 91.0043 15.46C91.0043 13.2502 92.7422 11.3484 95.0661 11.3484C97.3122 11.3484 99.1277 13.2475 99.1277 15.46C99.1277 17.6752 97.2847 19.5467 95.0661 19.5467ZM104.489 4.78467V21.8819H107.325V4.78467H104.489Z"
          fill="#333863"
        />
        <path
          d="M51.3023 5.48691C51.3023 6.42514 50.5418 7.18573 49.6035 7.18573C48.6653 7.18573 47.9047 6.42514 47.9047 5.48691C47.9047 4.54868 48.6653 3.78809 49.6035 3.78809C50.5418 3.78809 51.3023 4.54868 51.3023 5.48691Z"
          fill="#333863"
        />
      </g>
      <defs>
        <clipPath id="clip0_725_691">
          <rect
            width="106.65"
            height="27"
            fill="white"
            transform="translate(0.675049)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
