import React from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { MapPin } from "react-feather";
import { createAbbreviationForTimeZone } from "../../services/commonUsefulFunctions";
import { OUTSTANDING_SLOTS_SECTION_ID } from "../../services/elementIDVariables";
import useMeasuredRef from "../../services/customHooks/useMeasureRef";
import { safeJSONParse } from "../../lib/jsonFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isTypeString } from "../../services/typeGuards";
import { convertTimeSlotEndTime, convertTimeSlotStartTime } from "../../lib/outstandingSlotFunctions";
import { getDateTimeFormatLowercaseAMPM } from "../../lib/dateFunctions";

interface OutstandingSlotPreviewProps {
  creationDate: string
  creationTime: string
  currentTimeZone: string
  dateFieldOrder: DateFieldOrder
  format24HourTime: boolean
  isPlaceholderTitle: boolean
  outstandingSlot: OutstandingSlot
  shouldDisplay: boolean
  title: string
}

export default function OutstandingSlotPreview({
  creationDate,
  creationTime,
  currentTimeZone,
  dateFieldOrder,
  format24HourTime,
  isPlaceholderTitle,
  outstandingSlot,
  shouldDisplay,
  title,
}: OutstandingSlotPreviewProps) {

  const outstandingSlotsSection = document.getElementById(OUTSTANDING_SLOTS_SECTION_ID);
  const { domRect: sectionDomRect, measuredRef } = useMeasuredRef<HTMLDivElement>();
  const height = sectionDomRect?.height ?? 0;

  if (!shouldDisplay) {
    return null;
  }

  if (!outstandingSlotsSection) {
    return null;
  }

  const outstandingSlotsSectionPosition = outstandingSlotsSection.getBoundingClientRect();

  const width = 255;
  const margin = 16;
  // Align the preview to the top of the outstanding slots section.
  // If the preview extends past the bottom of the screen, move up to fit on the screen.
  // If the preview is too tall to fit on the screen, keep the top edge visible and let the bottom overflow.
  const top = Math.max(Math.min(
    outstandingSlotsSectionPosition.top,
    window.innerHeight - (2 * margin) - height,
  ), margin);
  const left = outstandingSlotsSectionPosition.left - width - 32;

  return createPortal(
    <div
      ref={measuredRef}
      className={classNames(
        "fixed z-10",
        "p-4",
        "large-blur",
        "rounded-xl",
        "outstanding-slot-preview-modal",
      )}
      style={{
        width,
        top,
        left,
      }}
    >
      <div className="rounded-lg p-4 outstanding-slot-preview-modal-content">
        <div className="font-size-13">{title}</div>
        {isPlaceholderTitle
          ? null
          : <div className="font-size-11 mt-1.5">Created: {creationDate} &middot; {creationTime}</div>}
        <LocationSection outstandingSlot={outstandingSlot} />
        <AttendeesSection outstandingSlot={outstandingSlot} />
        <SlotsSection
          currentTimeZone={currentTimeZone}
          dateFieldOrder={dateFieldOrder}
          format24HourTime={format24HourTime}
          outstandingSlot={outstandingSlot}
        />
      </div>
    </div>,
    document.body,
  );
}

type SectionProps = Pick<OutstandingSlotPreviewProps, "outstandingSlot">

function LocationSection({ outstandingSlot }: SectionProps) {
  if (!outstandingSlot.location) {
    return null;
  }

  return (
    <div className="mt-5 font-size-11">
      <div className="secondary-text-color">Where</div>
      <div className="flex mt-1">
        <MapPin className="flex-shrink-0 mr-1.5 mt-px" size={12} />
        <span>{outstandingSlot.location}</span>
      </div>
    </div>
  );
}

function AttendeesSection({ outstandingSlot }: SectionProps) {
  let attendees = outstandingSlot.attendees;
  if (isTypeString(attendees)) {
    attendees = safeJSONParse<AvailabilityLinkAttendee[]>(attendees);
  }

  if (isEmptyArrayOrFalsey(attendees)) {
    return null;
  }

  return (
    <div className="mt-5 font-size-11">
      <div className="secondary-text-color">Attendees ({attendees.length})</div>
      {attendees?.map((attendee, index) => {
        const label = attendee.name || attendee.label || attendee.email;
        if (!label) {
          return null;
        }

        return <div key={`${label}-${index}`} className="mt-1">{label}</div>;
      })}
    </div>
  );
}

type SlotsSectionProps = Pick<OutstandingSlotPreviewProps, "currentTimeZone" | "dateFieldOrder" | "format24HourTime" | "outstandingSlot">

function SlotsSection({ currentTimeZone, dateFieldOrder, format24HourTime, outstandingSlot }: SlotsSectionProps) {
  const slotsGroupedByDate: Record<string, string[]> = {};

  const dateFormat = dateFieldOrder === "DMY" ? "d LLLL (EEEE):" : "LLLL d (EEEE):";
  const timeFormat = getDateTimeFormatLowercaseAMPM(format24HourTime);
  const timeZone = createAbbreviationForTimeZone(currentTimeZone);

  outstandingSlot.time_slots
    .sort((a, b) => parseISO(a.start_time).valueOf() - parseISO(b.start_time).valueOf())
    .forEach(slot => {
      const startTime = convertTimeSlotStartTime(slot, currentTimeZone);
      const endTime = convertTimeSlotEndTime(slot, currentTimeZone);
      const date = format(startTime, dateFormat);
      if (!(date in slotsGroupedByDate)) {
        slotsGroupedByDate[date] = [];
      }

      const formattedStartTime = format(startTime, timeFormat);
      const formattedEndTime = format(endTime, timeFormat);
      slotsGroupedByDate[date].push(`${formattedStartTime} - ${formattedEndTime} (${timeZone})`);
    });

  if (isEmptyObjectOrFalsey(slotsGroupedByDate)) {
    return null;
  }

  return (
    <div className="mt-5 font-size-11">
      {Object.entries(slotsGroupedByDate).map(([date, times]) => (
        <div key={date}>
          {date}
          <ul className="list-inside my-1">
            {times.map(time => (
              <li key={time}>{time}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
