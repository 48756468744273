import Delta from "quill-delta";
import {
  constructReminderEmailContainerID,
  createTextDeltaOp,
  formatDeltaToText,
  formatTextToVariableDelta,
  isReminderEmailContentTitle,
} from "../../components/reminderEmail/reminderEmailFunctions";

export const constructReminderEmailModules = (contentType) => {
  const constructBreakTagFormatter = () => {
    return function formatBreakTag(node, delta) {
      const replacementDelta = new Delta().insert(" ");

      return isReminderEmailContentTitle(contentType) ? replacementDelta : delta;
    };
  };

  const constructPasteHandler = () => {
    return function(node, delta) {
      const newDelta = formatTextToVariableDelta({
        contentType,
        text: formatDeltaToText({ contentType, delta }),
      });

      return newDelta;
    };
  };

  const disableEnter = {
    keyboard: {
      bindings: {
        shift_enter: {
          key: 13,
          shiftKey: true,
          handler: () => {},
        },
        enter: {
          key: 13,
          handler: () => {},
        },
      },
    },
  };

  const sanitize = (node, delta) => {
    var plaintext = node.innerText;
    delta.ops = [createTextDeltaOp(plaintext)];
    return delta;
  };

  return {
    ...(isReminderEmailContentTitle(contentType) ? disableEnter : {}),
    clipboard: {
      matchers: [
        ["BR", constructBreakTagFormatter()],
        [Node.ELEMENT_NODE, sanitize], // Remove formatting from paste
        [Node.TEXT_NODE, constructPasteHandler()], // Format variable
      ],
    },
    history: {
      delay: 100,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: `#${constructReminderEmailContainerID(contentType)}`,
    },
  };
};
