import React from "react";
import { LIGHT_MODE_SECONDARY_TEXT_COLOR } from "../services/globalVariables";

const LightModeConferenceRoomDoorSVG = ({isSecondary}) =>
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18 18">
  <g fill="none" fillRule="evenodd">
      <circle cx="7" cy="10.111" r="1" fill={isSecondary ? LIGHT_MODE_SECONDARY_TEXT_COLOR : "#000"} />
      <path stroke={isSecondary ? LIGHT_MODE_SECONDARY_TEXT_COLOR : "#000"} strokeLinecap="round" strokeLinejoin="round" d="M0 17.034h4.333V1H14v16.034h4"/>
  </g>
</svg>;

export default LightModeConferenceRoomDoorSVG;
