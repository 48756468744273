import React, { useEffect } from "react";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { useAnnualUpgradeInvoicesStore, useHasBillingBeenFetched } from "../../services/stores/finance";
import SpinnerV2 from "../spinnerV2";
import { getHumanReadableDollarFromCents } from "../../lib/stripeFunctions";
import { addYears, format } from "date-fns";
import EventModalPopup from "../eventModalPopup";
import { useSelector } from "react-redux";
import { getUserToken } from "../../lib/userFunctions";
import { trackEvent } from "../tracking";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";

interface UpgradeModelProps {
  isOpen: boolean
  onClickExit: () => void
  onClickSave: () => void
}

/**
 * The confirmation modal shown before upgrading a subscription from monthly to yearly.
 * Shows a summary of the amount due today as well as the full amount due next cycle.
 */
export default function UpgradeModal({ isOpen, onClickExit, onClickSave }: UpgradeModelProps) {
  const { hasBillingBeenFetched } = useHasBillingBeenFetched();
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const { annualUpgradeNonProratedInvoice, annualUpgradeProratedInvoice } = useAnnualUpgradeInvoicesStore();

  useEffect(() => {
    trackEvent({
      category: "upgrade to annual plan",
      action: "onClickUpgradeToAnnualPlan_0",
      label: "upgradeModal.tsx",
      userToken: getUserToken(currentUser),
    });
  }, [currentUser]);

  useEffect(() => {
    const canNotUpgradePlan = hasBillingBeenFetched && !annualUpgradeNonProratedInvoice && !annualUpgradeProratedInvoice;
    if (canNotUpgradePlan) {
      onClickExit();
    }
  }, [
    annualUpgradeNonProratedInvoice,
    annualUpgradeProratedInvoice,
    hasBillingBeenFetched,
    onClickExit,
  ]);

  const isLoading = !hasBillingBeenFetched || !annualUpgradeNonProratedInvoice || !annualUpgradeProratedInvoice;

  return (
    <EventModalPopup
      isOpen={isOpen}
      onRequestClose={onClickExit}
      width={500}
      title="Upgrading to annual plan"
      style={determineDefaultModalStyle(isDarkMode)}
    >
      {isLoading ? <SpinnerV2 /> : (
        <div>
          <div className="default-font-size">
            You will be charged an estimated prorated amount of
            ${getHumanReadableDollarFromCents(annualUpgradeProratedInvoice.amount_due)} today
          </div>
          <div className="default-font-size secondary-text-color mt-1">
            Your new total will be
            ${getHumanReadableDollarFromCents(annualUpgradeNonProratedInvoice.subtotal)}/year
            starting {format(addYears(new Date(), 1), "PPP")}
          </div>
          <SaveAndCancelButton
            onClose={onClickExit}
            onConfirm={onClickSave}
            confirmButtonText="Upgrade"
          />
        </div>
      )}
    </EventModalPopup>
  );
}
