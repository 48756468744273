import React from "react";

import TagsEmptyIcon from "./tagsEmptyIcon";
import { TagsMenuProps } from "..";
import { DISPLAY_LOCATION_POPUP_EVENT } from "../tagsVariables";
import CircleWithColor from "../../circleWithColor";

type TagsIconProps = Omit<TagsMenuProps, 'ref' | 'setTags'>;

const TagsIcon: React.FC<TagsIconProps> = ({ displayLocation, tags }) => {
  if ((!tags || tags?.length === 0) && displayLocation === DISPLAY_LOCATION_POPUP_EVENT) {
    return null;
  }

  return (
    <div
      className="flex items-center paint-colors-icon"
    >
      { /* Empty icon SVG with no colors */}
      { /* No icon for right click menu */}
      {
        (!tags || tags?.length === 0) &&
          displayLocation !== DISPLAY_LOCATION_POPUP_EVENT ?
          <TagsEmptyIcon /> :
          null
      }
      {tags?.length > 0 ? (
        <>
          { /* First color if tags > 0 */}
          <CircleWithColor color={tags[0].color} colorName={null} size={12} />
          { /* Second color if tags > 1 */}
          {
            tags?.length > 1 ? (
              <div
                className="-ml-1.5"
              >
                <CircleWithColor color={tags[1].color} colorName={null} size={12} />
              </div>
            ) : null
          }
        </>
      ) : null}
    </div>
  )
}

export default TagsIcon;
