import React from "react";
import AddVariableButton from "./addVariableButton";
import { constructReminderEmailContainerID } from "./reminderEmailFunctions";

const ReminderEmailToolbar = ({ contentType, onAddVariable }) => {
  const toolbarID = constructReminderEmailContainerID(contentType);

  return (
    <div id={toolbarID}>
      <AddVariableButton
        contentType={contentType}
        onAddVariable={onAddVariable}
      />
    </div>
  );
};

export default ReminderEmailToolbar;
