import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LAYOUT_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { isScheduledToBeCancelled } from "../../lib/stripeFunctions";
import { getUserToken, shouldShowTrialActive } from "../../lib/userFunctions";
import { FEEDBACK_TYPE } from "../../lib/vimcalVariables";
import { useDefaultPaymentMethod, useStripeSubscriptions } from "../../services/stores/finance";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { useIsCurrentUserOwner } from "../teamPlans/hooks";
import { trackEvent } from "../tracking";
import EventModalPopup from "../eventModalPopup";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import Feedback from "../feedback/feedback";

interface ContactVimcalProps {
  contactLinkLabel?: string
  label?: string
  setIsCancelModalOpen?: StateSetter<boolean>
}

/**
 * The text at the bottom of the billing settings that allows the user to contact us and/or
 * cancel their subscription.
 */
export default function ContactVimcal({
  label="Please contact us for",
  contactLinkLabel="billing questions",
  setIsCancelModalOpen,
}: ContactVimcalProps) {
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);
  const currentUser = useSelector(state => state.currentUser);
  const isDarkMode = useSelector(state => state.isDarkMode);
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const defaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.defaultPaymentMethod,
  );
  const stripeSubscription = useStripeSubscriptions(state => state.stripeSubscriptions);
  const isOwner = useIsCurrentUserOwner();

  const onClickContact = () => {
    if (layoutBroadcast.isSubscribedToEvent(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK)) {
      layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.QUESTION);
    } else {
      setIsLocalModalOpen(true);
    }
  };

  const closeLocalModal = () => {
    setIsLocalModalOpen(false);
  };

  const isInTrial = shouldShowTrialActive({masterAccount, defaultPaymentMethod});

  return (
    <>
      <EventModalPopup
        isOpen={isLocalModalOpen}
        onRequestClose={closeLocalModal}
        style={determineDefaultModalStyle(isDarkMode)}
        title="Send feedback ❤️"
        width={620}
      >
        <Feedback onClose={closeLocalModal} feedbackType={FEEDBACK_TYPE.QUESTION} />
      </EventModalPopup>
      <div className="w-full flex justify-center mt-14 default-font-size">
        {label}{" "}
        <span
          className="ml-1.5 mr-1.5 text-color-link cursor-pointer"
          onClick={onClickContact}
        >
          {contactLinkLabel}
        </span>
        {(
          !isInTrial
          && (isEmptyObjectOrFalsey(teamPlan) || isOwner)
          && !isEmptyObjectOrFalsey(stripeSubscription)
          && !isScheduledToBeCancelled({ stripeSubscription })
          && setIsCancelModalOpen
        ) ? (
            <>
              {" "}
              or to{" "}
              <span
                className="ml-1.5 text-color-link cursor-pointer"
                onClick={() => {
                  setIsCancelModalOpen(true);
                  trackEvent({
                    category: "cancel subscription",
                    action: "cancelSubscription_0",
                    label: "BillingSettings.js",
                    userToken: getUserToken(currentUser),
                  });
                }}
              >
                cancel your subscription
              </span>
              .
            </>
          ) : null}
      </div>
    </>
  );
}
