import { VIMCAL_PLAN_OPTIONS } from "../../../lib/vimcalVariables";

export const VIMCAL_DEFAULT_PLAN = {
  value: VIMCAL_PLAN_OPTIONS.VIMCAL,
  label: "Vimcal",
} as const;
export const VIMCAL_EA_PLAN = {
  value: VIMCAL_PLAN_OPTIONS.VIMCAL_EA,
  label: "Vimcal EA",
} as const;
export const PLAN_OPTIONS = [VIMCAL_DEFAULT_PLAN, VIMCAL_EA_PLAN] as const;

export type EmailPlanDictionary = Record<string, { plan: ValueOf<typeof VIMCAL_PLAN_OPTIONS>, locked: boolean }>

export type CardInfo = {
  brand: string;
  lastFour: string;
} | null

export function groupEmailPlanDictionaryBySeat(emailPlanDictionary: EmailPlanDictionary) {
  const vimcalEmails: string[] = [];
  const vimcalEAEmails: string[] = [];
  Object.entries(emailPlanDictionary).forEach(([email, seat]) => {
    if (seat.plan === VIMCAL_PLAN_OPTIONS.VIMCAL) {
      vimcalEmails.push(email);
    } else {
      vimcalEAEmails.push(email);
    }
  });
  return { vimcalEmails, vimcalEAEmails };
}

interface GetIsAddingSeatsArgs {
  defaultVimcalSeats?: number
  newVimcalEAEmails?: string[]
  newVimcalEmails?: string[]
  openVimcalEASeats?: number
  openVimcalSeats?: number
  vimcalEASeats?: number
}

/**
 * Check if seats will be added to the team plan subscription.
 * If seats are only being filled, not added, this returns false.
 */
export function getIsAddingSeats({
  defaultVimcalSeats=0,
  newVimcalEAEmails=[],
  newVimcalEmails=[],
  openVimcalEASeats=0,
  openVimcalSeats=0,
  vimcalEASeats=0,
}: GetIsAddingSeatsArgs) {
  return (
    defaultVimcalSeats > 0 ||
    vimcalEASeats > 0 ||
    newVimcalEmails.length > openVimcalSeats ||
    newVimcalEAEmails.length > openVimcalEASeats
  );
}
