import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { calculateMarginTop } from "../../services/commonUsefulFunctions";
import { ChevronRight } from "react-feather";
import CalendarList from "../calendarList";
import Account from "./sections/account";
import AvailabilityFeatures from "./sections/availabilityFeatures";
import SideMenuBarDownloadSection from "./sections/downloads";
import SideMenuBarPowerFeature from "./sections/powerFeatures";
import ImproveVimcal from "./sections/improveVimcal";
import ProfilePictureThroughURL from "../profilePictureThroughURL";
import { LAYOUT_BROADCAST_VALUES } from "../../lib/broadcastValues";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import VimcalLogoWhite from "../logo/vimcalLogoWhite";
import VimcalLogoDarkBlue from "../logo/vimcalLogoDarkBlue";
import { truncateString } from "../../lib/stringFunctions";
import { getUserProfilePhotoUrl } from "../../lib/userFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";

export default function SideMenuBar(props) {
  const shouldDisplayMenu = useSelector((state) => state.shouldDisplayMenu);
  const shouldShowShortcutsLegend = useSelector(
    (state) => state.shouldShowShortcutsLegend
  );
  const shouldShowTopBar = useSelector((state) => state.shouldShowTopBar);
  const currentUser = useSelector((state) => state.currentUser);
  const isMobileView = useSelector((state) => state.isMobileView);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const renderUserAccount = () => {
    return (
      <div
        className="side-menu-contacts-wrapper position-relative items-center pb-2 pt-2"
        style={{ marginTop: calculateMarginTop(shouldShowTopBar, 10), width: 328 }}
        onClick={onClickUserAccount}
      >
        <ProfilePictureThroughURL
          avatarUrl={getUserProfilePhotoUrl({user: currentUser, masterAccount})}
          email={currentUser.email}
        />

        <div className="contact-name ml-2">
          {truncateString(currentUser.email, 40)}
        </div>

        <div className="multiple-account-profile-picture-icon">
          <ChevronRight size={16} />
        </div>
      </div>
    );
  };

  const onMouseLeaveSideMenuPanel = () => {
    /* Prevents auto closing the side panel which prevents shortcuts legend from opening */
    if (shouldShowShortcutsLegend) {
      return;
    }

    props.setShouldDisplayMenu(false);
  };

  const onClickUserAccount = () => {
    props.setShouldDisplayMenu(false);
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_ACCOUNTS);
  };

  return (
    <div>
      <div
        className={classNames(
          "menu-bar",
          "menu-bar-background-color",
          shouldDisplayMenu ? "menu-bar-on" : "",
          "overflow-x-hidden",
          "flex flex-col",
          "justify-between"
        )}
        onMouseLeave={onMouseLeaveSideMenuPanel}
      >
        <div className={classNames("menu-bar-wrapper")}>
          {renderUserAccount()}

          {/* POWER FEATURES SECTION */}
          <SideMenuBarPowerFeature />
          {/* AVAILABILITES SECTION */}
          <AvailabilityFeatures
            setShouldDisplayMenu={props.setShouldDisplayMenu}
          />
          {/* IMPROVE VIMCAL Section */}
          <ImproveVimcal />
          {/* ACCOUNT SECTION */}
          <Account />
          {/* DOWNLOADS Section */}
          <SideMenuBarDownloadSection />
          {isMobileView ? null : <div className="h-16"></div>}
        </div>

        {isMobileView ? (
          <div className="mb-16">
            <CalendarList />
          </div>
        ) : null}
        {renderLogo({ isDarkMode })}
      </div>
    </div>
  );
}

function renderLogo({ isDarkMode }) {
  return (
    <div
      className={classNames(
        "sticky bottom-0",
        "pl-5 py-4",
        "menu-bar-background-color",
        "flex gap-1 items-center",
      )}
    >
      {isDarkMode ? <VimcalLogoWhite className={"opacity-40"} /> : <VimcalLogoDarkBlue className={"opacity-50"}/>}
    </div>
  );
}
