import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import Ellipse from "./ellipse";
import LoginButton from "./loginButton";
import LoginBackButton from "./loginBackButton";
import LoginExitButton from "./loginExitButton";
import LoginTitleBar from "./loginTitleBar";
import VimcalLogo from "./vimcalLogo";
import MaestroLogin from "../maestroLogin";
import {
  determineElectronLoginEnv,
  doesWindowLocationIncludeString,
  isElectron,
  isMobile,
  KEYCODE_BACKSPACE,
  KEYCODE_ESCAPE,
  OpenLink,
  openLinkOnSamePage,
} from "../../services/commonUsefulFunctions";
import Broadcast from "../../broadcasts/broadcast";
import {
  MAESTRO_ACCOUNT_TYPES,
  MAESTRO_NEW_USER_PAGES,
} from "../../services/globalMaestroVariables";
import {
  addDataLayerTracking,
  trackError,
  trackEvent,
  trackOnLoginLoginAttribution,
} from "../../components/tracking";
import { useSelector } from "react-redux";
import {
  getPromotionCode,
  getReferralCode,
  getTeamInviteToken,
  isAccountReferred,
  isInOnboarding,
  isWaitingToBeOnboarded,
  savePromoCode,
  saveTeamInviteToken,
  userNeedsTypeForm,
} from "../../lib/stateManagementFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import appBroadcast from "../../broadcasts/appBroadcast";
import classNames from "classnames";
import WarningSymbol from "./warningSymbol";
import OutlookLoginButton from "./outlookLoginButton";
import {
  getCompleteParamString,
  getAuthCode,
  getSignupAttribution,
  getSSOLoginTokenQueryParam,
} from "../../services/queryParamFunctions";
import SignupPage from "./signup";
import { SIGNUP_GIF, SIGNUP_TESTIMONIAL } from "../../services/experiments";
import { X } from "react-feather";
import {
  getAccountTypeFromAuthResponse,
  getGoogleLoginURL,
  isGoogleAuthCode,
} from "../../lib/googleFunctions";
import { storeAttribution } from "../../lib/attributionFunctions";
import {
  removeStoredReferralCode,
  saveSSOLoginToken,
  setStoredReferralCode,
} from "../../lib/localData";
import {
  MOBILE_DOWNLOAD_PATH,
  TYPEFORM_ROUTE,
  isSSOPath,
} from "../../services/routingFunctions";
import {
  canUseMagicLinks,
  isSelfServeOpen,
} from "../../lib/featureFlagFunctions";
import { useAccountActivity } from "../../services/stores/appFunctionality";
import { isVimcalEALoginPage, shouldShowFullLoadingScreenPendingBackendResponse } from "../../services/appFunctions";
import { getUserToken } from "../../lib/userFunctions";
import MobileSignUp from "../../components/mobileSignUp";
import { CALENDAR_PROVIDERS } from "../../lib/vimcalVariables";
import { isEmptyObjectOrFalsey, isTypeString } from "../../services/typeGuards";
import { generateLargeRandomNumber } from "../../services/randomFunctions";
import {
  MAGIC_LINK_PATH,
  NEW_EA_LOGIN_PATH,
} from "../../services/maestro/maestroRouting";
import { useMagicLink } from "../../services/stores/magicLinkStore";
import SsoLoginButton from "./ssoLoginButton";
import SsoLoginPage from "./ssoLoginPage";
import {
  APP_BROADCAST_VALUES,
  LOGIN_BROADCAST_VALUES,
} from "../../lib/broadcastValues";
import loginBroadcast from "../../broadcasts/loginBroadcast";
import SsoNewUserProviderLogin from "./ssoNewUserProviderLogin";
import DraggableHeader from "../../../components/draggableHeader";
import EventModalPopup from "../../components/eventModalPopup";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { PERMISSIONS_SCREENSHOT_WHITE } from "../../services/globalVariables";

const Login = (props) => {
  const {
    authenticated,
    history,
    isDesktopLogin,
    isMagicLink,
    isSignup,
    magicLink,
    shouldDisplayMagicLinkError,
    isNewUserSSOLogin, // after sso login, it's a new user therefore we need to show the page asking user to login with provider (google/outlook)
  } = props;
  const isElectronBoolean = isElectron();
  const currentUser = useSelector((state) => state.currentUser);
  const [desktopTimer, setDesktopTimer] = useState(false);
  const initialDesktopState = {
    askToReLogin: false,
    isLoggedIn: false,
    code: null,
    isGoogle: true,
  };
  const location = useLocation();
  const isSSOLogin = useMemo(() => isSSOPath(), [location]);
  const isVimcalEALoginView = useMemo(() => isVimcalEALoginPage(), [location]);
  const [desktopState, setDesktopState] = useState(initialDesktopState);
  /* Pages are used for Maestro new user */
  const [maestroAuthPage, setMaestroAuthPage] = useState(
    MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE
  );
  const [shouldDisplayPermissions, setShouldDisplayPermissions] =
    useState(false);
  const { masterAccount } = useMasterAccount();
  const [isMobileView] = useState(isMobile() && !isMagicLink); // Don't show mobile view if we're in magic link flow
  const [signUpExperiment] = useState(getSignUpExperimentType());
  const savedAccountType = useRef(null); // save incase user needs to press launch Vimcal again
  const [shouldHideSSOLoginButton, setShouldHideSSOLoginButton] = useState(
    isMobile() || isNewUserSSOLogin
  );

  const hasReceivedLoginCode = useAccountActivity(
    (state) => state.hasReceivedLoginCode
  );
  const isStillPendingInitialCalendarSync = useAccountActivity(
    (state) => state.isStillPendingInitialCalendarSync
  );
  const setLastClickedLoginButton = useAccountActivity(
    (state) => state.setLastClickedLoginButton
  );
  const lastClickedLoginButton = useAccountActivity(
    (state) => state.lastClickedLoginButton
  );
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const urlParams = new URLSearchParams(window.location.search);
  const urlAccountType = urlParams.get("accountType");
  const { magicLinkToken, resetMagicLinkToken } = useMagicLink();
  const [ssoLoginToken, setSsoLoginToken] = useState(null);

  useEffect(() => {
    if (ssoLoginToken) {
      appBroadcast.publish(APP_BROADCAST_VALUES.AUTHENTICATE_BY_SSO, {
        ssoLoginToken,
        isDesktopLogin,
      });
    }
  }, [ssoLoginToken]);

  useEffect(() => {
    addDataLayerTracking({
      event: `landed_on_${isSignup ? "signup" : "login"}_page`,
    });

    /* Check if user is logged in */
    /* Return home if they are (unless route is /new where we pass authenticated) */
    /* Skip if desktop login */

    const promotionCode = getPromotionCode();
    savePromoCode(promotionCode); // save it for later since we're going to reroute the user

    const ssoLoginQueryParam = getSSOLoginTokenQueryParam();

    if (ssoLoginQueryParam) {
      saveSSOLoginToken(ssoLoginQueryParam);
      setShouldHideSSOLoginButton(true);
      setSsoLoginToken(ssoLoginQueryParam);
    }

    const teamInviteToken = getTeamInviteToken();
    saveTeamInviteToken(teamInviteToken);

    let promotionTimer = null;
    const authCode = getAuthCode();
    if (authCode) {
      savedAccountType.current = getAccountTypeFromAuthResponse(
        window.location.search
      );
      if (isGoogleAuthCode(window.location.search)) {
        onLoginSuccess(authCode, savedAccountType.current);
      } else {
        loginUsingOutlookCode(authCode, savedAccountType.current);
      }

      // if we don't remove the code from the url, it will keep trying to login
      if (isDesktopLogin) {
        history.push("/desktop-login");
      } else {
        if (!currentUser?.token && allLoggedInUsers?.length === 0) {
          // only show for logged out state
          appBroadcast.publish("SHOW_FULL_SCREEN_LOADING_SCREEN");
        }

        /* Do not redirect to /login for magic links */
        isMagicLink ? history.push(MAGIC_LINK_PATH) : history.push("/login");
      }
    } else if (
      !isEmptyObjectOrFalsey(currentUser) &&
      !authenticated &&
      !isDesktopLogin
    ) {
      // if user is already logged in but lands on /login
      if (doesWindowLocationIncludeString("login?reroute=billing")) {
        history.push("/billing");
        return;
      }
      if (promotionCode) {
        // if user is already logged in but lands on /login
        promotionTimer = setTimeout(() => {
          // do not need to check is mounted because we're not setting state or anything
          if (!isInOnboarding(masterAccount)) {
            // if in onboarding -> gets handled in onboardingContainer.js
            appBroadcast.publish("ADD_PROMOTION_CODE", promotionCode);
          }
        }, 300);
      }

      // if user is already logged in but lands on /login
      if (isSSOLogin) {
        // do nothing
      } else if (isSelfServeOpen()) {
        if (isMobile()) {
          history.push(`/${MOBILE_DOWNLOAD_PATH}`);
        } else if (isInOnboarding(masterAccount)) {
          history.push(`/welcome${getParamCodeForHistory()}`);
        } else {
          returnToCalendarHome();
        }
      } else {
        // has to go through manual onboarding
        if (userNeedsTypeForm(masterAccount)) {
          history.push(`/${TYPEFORM_ROUTE}`);
          return;
        }

        if (isAccountReferred(masterAccount)) {
          history.push("/welcome");
          return;
        }

        if (isWaitingToBeOnboarded(masterAccount)) {
          history.push("/welcome");
          return;
        }

        returnToCalendarHome();
      }
    }

    // we don't have to worry about the rerouting above because this only matters for new users who have never logged in before so they'll never get rerouted
    const attribution = getSignupAttribution();
    if (attribution) {
      trackEvent({
        category: "attribution",
        action: isSignup
          ? `signup_attribution_${signUpExperiment}`
          : "login_attribution",
        label: attribution,
      });
      storeAttribution();
    }

    /* Event listener for back button (only when logged in and adding another user) */
    if (!isEmptyObjectOrFalsey(currentUser) && authenticated) {
      document.addEventListener("keydown", handleKeyDown);
    }

    /* When the user logs in, we set a timer */
    let loginTimer = null;
    if (
      isDesktopLogin &&
      desktopTimer &&
      desktopState.isLoggedIn &&
      loginTimer === null
    ) {
      loginTimer = setTimeout(() => {
        if (desktopState.isLoggedIn) {
          clearTimeout(loginTimer);
          loginTimer = null;

          setDesktopState({
            ...desktopState,
            askToReLogin: true,
          });
        }
      }, 45000);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      clearTimeout(loginTimer);
      clearTimeout(promotionTimer);
    };
  });

  /* Check for magic link toke and remove if necessary */
  useEffect(() => {
    checkForMagicLinkToken();
    loginBroadcast.subscribe(
      LOGIN_BROADCAST_VALUES.UPDATE_SHOW_SSO_BUTTON,
      ({ shouldHideSSOLoginButton }) =>
        setShouldHideSSOLoginButton(shouldHideSSOLoginButton)
    );
    return () => {
      loginBroadcast.unsubscribe(LOGIN_BROADCAST_VALUES.UPDATE_SHOW_SSO_BUTTON);
    };
  }, []);

  const getParamCodeForHistory = () => {
    const paramString = getCompleteParamString();
    if (paramString) {
      return `?${paramString}`;
    }

    return "";
  };

  const loginUsingOutlookCode = (inputCode, inputAccountType) => {
    const code = isTypeString(inputCode) ? inputCode : desktopState.code;
    if (!code) {
      return;
    }

    const accountType =
      inputAccountType ?? savedAccountType.current ?? urlAccountType;

    if (isDesktopLogin) {
      const env = determineElectronLoginEnv();
      const appLink = `vimcal-${env}://outlook=${code}${
        accountType ? `&accountType=${accountType}` : ""
      }`;

      setDesktopState({
        ...desktopState,
        isLoggedIn: true,
        code: code,
        isGoogle: false,
      });

      //Need this here otherwise the popup for open vimcal does not open
      window.open(appLink, "_self");

      if (!desktopTimer) {
        setDesktopTimer(true);
      }
    } else {
      if (props.isMaestro || !!inputAccountType) {
        appBroadcast.publish("OUTLOOK_LOGIN_WITH_AUTH_CODE", {
          response: code,
          accountType: inputAccountType ?? MAESTRO_ACCOUNT_TYPES.MYSELF,
        });
      } else {
        appBroadcast.publish("OUTLOOK_LOGIN_WITH_AUTH_CODE", {
          response: code,
          isMagicLink,
        });
      }
    }
  };

  /* Handlers */

  /* Handle backspace and back button for maestro add additional user pages */
  const handleMaestroAuthBack = () => {
    /* Prefer return to break since we don't do anything else */
    switch (maestroAuthPage) {
      case MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE:
        /* User clicked on "Myself" */
        /* We just need to remove the params to go back */
        if (getParamCodeForHistory()) {
          history.push(NEW_EA_LOGIN_PATH);
        }
        return;
      case MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO:
        /* User clicked on "I have their email and password" */
        /* We just need to remove the params to go back */
        if (getParamCodeForHistory()) {
          history.push(NEW_EA_LOGIN_PATH);
          return;
        }
        setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE);
        return;
      // Temporary gate to prevent second page -> force limited access
      case MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM:
        if (canUseMagicLinks(currentUser)) {
          setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO);
          return;
        }

        setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE);
        return;
      case MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM:
        setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CARD_SELECT_TWO);
        return;
      default:
        return;
    }
  };

  const handleKeyDown = (e) => {
    if (e?.target?.nodeName?.toLowerCase() === "input") {
      return;
    }

    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        returnToCalendarHome();
        return;
      case KEYCODE_BACKSPACE:
        handleMaestroAuthBack();
        return;
      default:
        break;
    }
  };

  const onClickLoginButton = () => {
    setLastClickedLoginButton(CALENDAR_PROVIDERS.GOOGLE);
    trackOnLoginLoginAttribution({
      isOutlook: false,
      src: isSignup ? `signup_${signUpExperiment}` : "login",
    });
    addDataLayerTracking({
      event: `pressed_${
        isSignup ? "signup" : "login"
      }_${signUpExperiment}_google`,
    });

    const accountType =
      props.isMaestro || props.isMaestroNew
        ? urlAccountType ?? MAESTRO_ACCOUNT_TYPES.MYSELF
        : null;

    if (isElectronBoolean) {
      // Need to change to production once merged to production
      OpenLink(
        getGoogleLoginURL({ isDesktopLogin: true, accountType: accountType })
      );
    } else if (isDesktopLogin) {
      openLinkOnSamePage(
        getGoogleLoginURL({ isDesktopLogin: true, accountType: accountType })
      );
    } else if (props.isMaestro || props.isMaestroNew) {
      openLinkOnSamePage(
        getGoogleLoginURL({ isDesktopLogin: false, accountType: accountType })
      );
    } else {
      openLinkOnSamePage(
        getGoogleLoginURL({ isDesktopLogin: false, isMagicLink })
      );
    }
  };

  const renderGoogleLoginButton = (isLoading) => {
    return (
      <LoginButton
        desktopState={desktopState}
        initialDesktopState={initialDesktopState}
        onClickLoginButton={onClickLoginButton}
        onLoginFailure={onLoginFailure}
        onLoginSuccess={
          desktopState.isGoogle ? onLoginSuccess : loginUsingOutlookCode
        }
        setDesktopState={setDesktopState}
        isLoading={
          isLoading && lastClickedLoginButton === CALENDAR_PROVIDERS.GOOGLE
        }
      />
    );
  };

  const renderOutlookLoginButton = (isLoading) => {
    return (
      <OutlookLoginButton
        isDesktopLogin={isDesktopLogin || isElectronBoolean}
        isSignUp={isSignup}
        experimentType={signUpExperiment}
        accountType={
          props.isMaestro || props.isMaestroNew
            ? urlAccountType ?? MAESTRO_ACCOUNT_TYPES.MYSELF
            : null
        }
        isLoading={
          isLoading && lastClickedLoginButton === CALENDAR_PROVIDERS.OUTLOOK
        }
        isMagicLink={isMagicLink}
      />
    );
  };

  const onLoginFailure = (response) => {
    trackError({
      category: "google_login_failure",
      errorMessage: response ? JSON.stringify(response) : "onLoginFailure",
      userToken: getUserToken(currentUser),
    });
  };

  const onLoginSuccess = (authCode, inputAccountType) => {
    if (isDesktopLogin) {
      const accountType =
        inputAccountType ?? savedAccountType.current ?? urlAccountType;
      const code = authCode ? authCode : desktopState.code;

      const env = determineElectronLoginEnv();

      const appLink = `vimcal-${env}://code=${code}${
        accountType ? `&accountType=${accountType}` : ""
      }`;

      setDesktopState({ ...desktopState, isLoggedIn: true, code: code });

      //Need this here otherwise the popup for open vimcal does not open
      window.open(appLink, "_self");

      if (!desktopTimer) {
        setDesktopTimer(true);
      }
    } else {
      if (props.isMaestro || props.isMaestroNew || !!inputAccountType) {
        return Broadcast.publish("ON_LOGIN_SUCCESS", {
          response: { code: authCode },
          accountType: inputAccountType ?? MAESTRO_ACCOUNT_TYPES.MYSELF,
        });
      }

      Broadcast.publish("ON_LOGIN_SUCCESS", {
        response: { code: authCode },
        isMagicLink,
      });
    }
  };

  const toggleDisplayPermissions = () => {
    setShouldDisplayPermissions(!shouldDisplayPermissions);
  };

  const isUserLoggedIn = () => {
    return desktopState.isLoggedIn;
  };

  /* Helper Functions */
  const returnToCalendarHome = () => {
    const promotionCode = getPromotionCode();
    if (promotionCode) {
      history.push(`/home?d=${promotionCode}`);
      return;
    }
    history.push("/home");
  };

  const renderLoginButtonSection = () => {
    // Remove any stored referral codes.
    // They should be in the URL here.
    removeStoredReferralCode();
    const referralCode = getReferralCode();

    if (referralCode?.length > 0) {
      setStoredReferralCode(referralCode);
    }

    const isInLoadingState = shouldShowFullLoadingScreenPendingBackendResponse({
      hasReceivedLoginCode,
      isStillPendingInitialCalendarSync,
    });

    return (
      <div className="vimcal-updated-login-button-container">
        {renderGoogleLoginButton(isInLoadingState)}
        {isUserLoggedIn() ? null : renderOutlookLoginButton(isInLoadingState)}

        {shouldHideSSOLoginButton || isUserLoggedIn() ? null : (
          <>
            <div
              className={classNames(
                "dark-mode-secondary-text-color",
                "flex justify-center",
                "width-314px default-font-size font-weight-400 mb-2"
              )}
            >
              or
            </div>
            <SsoLoginButton
              isDesktopLogin={isDesktopLogin}
              isMagicLink={isMagicLink}
            />
          </>
        )}

        {isMobileView ? null : (
          <div
            className={classNames(
              "vimcal-updated-login-learn-more",
              "secondary-text-color",
              isVimcalEALoginView ? "hover:text-blue-500" : "hover:text-white",
              "select-none",
              "cursor-pointer",
              "duration-200",
              isUserLoggedIn() ? "mt-2" : "",
            )}
            onClick={toggleDisplayPermissions}
          >
            Learn more about the permissions Vimcal uses
          </div>
        )}
      </div>
    );
  };

  const shouldShowMaestroNewUserFlow = () => {
    // If there are codes in params, we want to show login buttons
    // Otherwise we show the new user flow
    return props.isMaestroNew && !getParamCodeForHistory();
  };

  const isPastPageOneMaestroNewUser = () => {
    // Not page one or is page one and user has clicked "Myself" which adds params
    return (
      (props.isMaestroNew &&
        maestroAuthPage !== MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE) ||
      (maestroAuthPage === MAESTRO_NEW_USER_PAGES.CARD_SELECT_ONE &&
        getParamCodeForHistory())
    );
  };

  const checkForMagicLinkToken = () => {
    /* If we have a magic link token but not in magic link flow */
    /* Clear the magic link token */
    if (magicLinkToken && !window.location.href.includes(MAGIC_LINK_PATH)) {
      resetMagicLinkToken();
    }

    return;
  };

  if (isVimcalEALoginView) {
    return (
      <div>
        {renderLoginButtonSection()}
        <EventModalPopup
          isOpen={shouldDisplayPermissions}
          onRequestClose={toggleDisplayPermissions}
          style={determineDefaultModalStyle(false)}
        >
          <div className="relative">
            <img src={PERMISSIONS_SCREENSHOT_WHITE} alt="testimonial" className="width-480px" />
            <div className="bg-white w-1 h-1 absolute top-0 right-0"></div>
          </div>
        </EventModalPopup>
      </div>
    );
  }

  if (isSSOLogin) {
    if (shouldHideSSOLoginButton) {
      return (
        <SsoNewUserProviderLogin
          renderGoogleLoginButton={renderGoogleLoginButton}
          renderOutlookLoginButton={renderOutlookLoginButton}
        />
      );
    }
    return (
      <SsoLoginPage isDesktopLogin={isDesktopLogin} isMagicLink={isMagicLink} />
    );
  }

  if (isMobileView) {
    return <MobileSignUp>{renderLoginButtonSection()}</MobileSignUp>;
  }

  if (
    shouldShowFullLoadingScreenPendingBackendResponse({
      hasReceivedLoginCode,
      isStillPendingInitialCalendarSync,
    })
  ) {
    return null;
  }

  if (isSignup && !isMobileView) {
    return (
      <SignupPage
        renderLoginButtonSection={renderLoginButtonSection}
        signUpExperiment={signUpExperiment}
        renderPermissionsList={() => {
          return renderPermissionsList({
            onClickClose: toggleDisplayPermissions,
          });
        }}
        shouldDisplayPermissions={shouldDisplayPermissions}
        isMagicLink={isMagicLink}
        magicLink={magicLink}
        shouldDisplayMagicLinkError={shouldDisplayMagicLinkError}
      />
    );
  }

  // only show if isElectron and user is not logged in
  const shouldShowDesktopDragRegion = isElectronBoolean && isEmptyObjectOrFalsey(currentUser);

  return (
    <div className={classNames("vimcal-updated-login-container")}>
      {/* Desktop title bar */}
      {shouldShowDesktopDragRegion ? (
        <LoginTitleBar />
      ) : null}
      <DraggableHeader />

      {/* Back button - Only for Maestro */}
      {!isEmptyObjectOrFalsey(currentUser) &&
      authenticated &&
      isPastPageOneMaestroNewUser() ? (
        <LoginBackButton
          onClick={() => handleMaestroAuthBack({ shouldReturnHome: true })}
          shortcut="Backspace"
          title="Back"
        />
      ) : null}

      {/* Exit button */}
      {!isEmptyObjectOrFalsey(currentUser) && authenticated ? (
        <LoginExitButton onClick={returnToCalendarHome} />
      ) : null}

      {/* Logo and gradient circle */}
      <VimcalLogo className="vimcal-updated-login-logo" />
      <Ellipse className="vimcal-updated-login-ellipse" />

      {/* Frame */}
      <div
        className={classNames(
          "vimcal-updated-login-permissions-frame",
          shouldDisplayPermissions ? "vimcal-updated-login-show" : ""
        )}
      >
        {renderPermissionsList({ onClickClose: toggleDisplayPermissions })}
        <div className="vimcal-updated-login-button-container vimcal-updated-login-permissions-button">
          {renderGoogleLoginButton()}
          {isUserLoggedIn() ? null : renderOutlookLoginButton()}
        </div>
      </div>

      {shouldShowMaestroNewUserFlow() ? (
        <MaestroLogin
          maestroAuthPage={maestroAuthPage}
          setMaestroAuthPage={setMaestroAuthPage}
        />
      ) : (
        <div
          className={classNames(
            "vimcal-updated-login",
            shouldDisplayPermissions ? "" : "vimcal-updated-login-show",
            isMobileView ? "left-50-percent-important" : ""
          )}
        >
          <div className="vimcal-updated-login-header-container">
            <div className="vimcal-updated-login-header">Welcome to Vimcal</div>
          </div>

          {renderLoginButtonSection()}
        </div>
      )}
    </div>
  );
};

function renderPermissionsList({ onClickClose, hideCloseButton }) {
  return (
    <div className="vimcal-updated-login-permissions-container">
      {/* Top Row */}
      {hideCloseButton
        ? null
        : <X
          className="absolute right-5 top-5 cursor-pointer hover:text-white duration-200"
          onClick={onClickClose}
        />
      }

      <div className="vimcal-updated-login-permissions-top-row">
        <div className="vimcal-updated-login-permissions-left-column">
          See, edit, share, {"&"} permanently delete all the calendars you can
          access using Google Calendar.
        </div>
        <div className="vimcal-updated-login-permissions-right-column">
          <p>This is a fancy way of saying read {"&"} edit your calendar.</p>
          <div className="vimcal-updated-login-permissions-note">
            <p>
              <WarningSymbol />
              This is essential for Vimcal to work correctly.
            </p>
          </div>
        </div>
      </div>

      {/* Center Row */}
      <div className="vimcal-updated-login-permissions-middle-row">
        <div className="vimcal-updated-login-permissions-left-column">
          <p>See info about users on your domain</p>
          <p>See {"&"} download your contacts.</p>
          <p>
            See {"&"} download contact info automatically saved in your “Other
            contacts”.
          </p>
        </div>
        <div className="vimcal-updated-login-permissions-right-column">
          <p>
            This allows Vimcal to auto-complete email addresses when inviting
            guests to a meeting.
          </p>
          <div className="vimcal-updated-login-permissions-note">
            <p>
              <WarningSymbol />
              We will never email or share your contacts.
            </p>
          </div>
        </div>
      </div>
      {/* Bottom Row */}
      <div className="vimcal-updated-login-permissions-bottom-row">
        <div className="vimcal-updated-login-permissions-left-column">
          View calendar resources on your domain
        </div>
        <div className="vimcal-updated-login-permissions-right-column">
          This allows you to book conference rooms for your meetings.
        </div>
      </div>
    </div>
  );
}

function getSignUpExperimentType() {
  const randomNumber = generateLargeRandomNumber();
  if (randomNumber % 2 === 0) {
    return SIGNUP_GIF;
  } else {
    return SIGNUP_TESTIMONIAL;
  }
}

export default withRouter(Login);
