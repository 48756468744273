import classNames from "classnames";
import React, { useMemo } from "react";
import { Key } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { SSO_DESKTOP_LOGIN, SSO_LOGIN, SSO_MAGIC_LINK_LOGIN } from "../../services/routingFunctions";
import { OpenLink, isElectron } from "../../services/commonUsefulFunctions";
import { getHomeLink } from "../../lib/envFunctions";
import { isVimcalEALoginPage } from "../../services/appFunctions";

export default function SsoLoginButton({ isDesktopLogin, isMagicLink }) {
  const history = useHistory();
  const location = useLocation();
  const isVimcalEALoginView = useMemo(() => isVimcalEALoginPage(), [location]);

  return (
    <div
      className={classNames(
        "width-314px",
        "flex items-center justify-center",
        "default-font-size",
        "gap-3",
        "cursor-pointer",
        "font-weight-400",
        isVimcalEALoginView ? "bg-white hover:bg-gray-200 sso-login-button-vimcal-ea-login" : "hoverable-dark-mode-border dark-mode-hoverable-secondary-text-color",
        "h-11",
        "duration-200",
        "mb-4",
        "select-none",
      )}
      onClick={() => {
        const searchParams = location.search; // Extract current query params

        if (isElectron()) {
          OpenLink(`${getHomeLink()}/${SSO_DESKTOP_LOGIN}${searchParams}`);
          return;
        }

        if (isDesktopLogin) {
          history.push(`${SSO_DESKTOP_LOGIN}${searchParams}`);
        } else if (isMagicLink) {
          history.push(`${SSO_MAGIC_LINK_LOGIN}${searchParams}`);
        } else {
          history.push(`${SSO_LOGIN}${searchParams}`);
        }
      }}
    >
      <Key size={12} />
      <div>Sign-in with SSO</div>
    </div>
  );
}
