import classNames from "classnames";
import React from "react";

export default function TestimonialContainer({ testimonial }) {
  const { name, role, profilePicture, quote } = testimonial;
  return (
    <div
      className={classNames(
        "w-full h-full",
        "rounded-xl p-5 relative",
        "box-shadow-9",
        "paywall-twitter-border",
        "select-none",
        "flex flex-col justify-between"
      )}
    >
      <div className="default-font-size">{quote}</div>

      <div className="flex flex-row items-center mt-2">
        <img
          alt=""
          width="32px"
          height="32px"
          className="mr-4 rounded-full"
          src={profilePicture}
        />

        <div>
          <div className="default-font-size font-weight-400 flex flex-row items-center secondary-text-color">
            {name}
          </div>
          <div className="default-font-size font-weight-400 flex flex-row items-center secondary-text-color">
            {role}
          </div>
        </div>
      </div>
    </div>
  );
}
