import React, { useState } from "react";

// import { Divider } from "../divider";
import DefaultSwitch from "../defaultSwitch";
import ReminderEmailContainer from "../reminderEmail/reminderEmailContainer";
import { blurCalendar } from "../../services/appFunctions";

const NotificationSettingsPage = (props) => {
  const {
    enabledEmailReminders,
    inputReminderEmails,
    onEnableEmailReminders,
    saveSetting,
  } = props;
  const [reminderEmails, setReminderEmails] = useState(inputReminderEmails);

  const renderEnabledEmailReminderSection = () => {
    if (!enabledEmailReminders) {
      return null;
    }

    return (
      <ReminderEmailContainer
        containerClassName={"mt-5"}
        reminderEmails={reminderEmails}
        setEmailReminders={(reminderEmails) => {
          setReminderEmails(reminderEmails);
          saveSetting({ reminderEmails });
        }}
      />
    );
  };

  const renderEmailRemindersSection = () => {
    return (
      <div className="mt-5 default-font-size">
        <div className="font-weight-300 default-font-size secondary-text-color">
          Email Reminders
        </div>

        <div className="mt-2.5 flex justify-between">
          <div className="secondary-text-color">Enabled</div>
          <DefaultSwitch
            tabIndex={-1}
            isChecked={enabledEmailReminders}
            onChange={(isEnabled) => {
              if (onEnableEmailReminders) {
                onEnableEmailReminders(isEnabled);
              }
              blurCalendar();
            }}
          />
        </div>
        {renderEnabledEmailReminderSection()}
      </div>
    );
  };

  return (
    <div>
      {renderEmailRemindersSection()}
    </div>
  );
};

export default NotificationSettingsPage;
