import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Fetcher from "../fetcher";
import { handleError } from "../commonUsefulFunctions";
import { debounce } from "underscore";
import { showCustomizeUsername } from "../../lib/featureFlagFunctions";
import { isEmptyObjectOrFalsey } from "../typeGuards";
import { slugifyString } from "../../lib/stringFunctions";
import { getSlugAvailabilityEndpoint as getSlugAvailabilityURL } from "../../lib/endpoints";
import { SECOND_IN_MS } from "../globalVariables";
import { useIsMounted } from "./useIsMounted";

const checkSlug = async ({
  slugCandidate,
  resourceType = "slots",
  currentUser,
}) => {
  try {
    if (!slugCandidate) {
      //"!" also checks for empty string
      return true;
    }
    if (slugCandidate.length < 3) {
      return false;
    }
    const { email } = currentUser;
    const url = getSlugAvailabilityURL({ resourceType, slugCandidate });
    const response = await Fetcher.get(url, {}, true, email);
    if (isEmptyObjectOrFalsey(response)) {
      handleError(Error(`check ${resourceType} slug returned empty response`));
      return false;
    }

    return response?.slug_exists === false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export default function useSlugCheck({
  slugCandidate,
  token,
  resourceType,
  initialSlug,
}) {
  const [hasValidSlug, setHasValidSlug] = useState(false);
  const [availableSlug, setAvailableSlug] = useState(initialSlug);
  const componentIsMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState(false);
  const currentUser = useSelector((state) => state?.currentUser);
  const checkSlugDebounced = useRef(
    debounce(async (slugCandidate, token, resourceType, currentUser) => {
      let newSlug = slugifyString(slugCandidate);
      if (newSlug === initialSlug) {
        setAvailableSlug(newSlug);
        setIsFetching(false);
        setHasValidSlug(true);
        return;
      }

      setIsFetching(true);
      let hasValidSlug = await checkSlug({
        slugCandidate: newSlug,
        resourceType,
        currentUser,
      });
      if (!componentIsMounted.current) {
        return;
      }

      // if the slug is not valid we try again with the last 5 digits of the token
      if (!hasValidSlug) {
        newSlug = `${newSlug}-${token.slice(-5)}`;
        hasValidSlug = await checkSlug({
          slugCandidate: newSlug,
          resourceType,
          currentUser,
        });
        if (!componentIsMounted.current) {
          return;
        }
      }

      setAvailableSlug(newSlug);
      setIsFetching(false);
      setHasValidSlug(hasValidSlug);
    }, SECOND_IN_MS),
  ).current;

  useEffect(() => {
    if (!showCustomizeUsername()) {
      return;
    }
    const checkSlugAsync = async () => {
      setHasValidSlug(slugCandidate === initialSlug);
      // if (slugCandidate === initialSlug) {
      // if same -> ignore
      // } else
      if (slugCandidate !== initialSlug) {
        // check if it's changed
        setIsFetching(true);
        checkSlugDebounced(slugCandidate, token, resourceType, currentUser);
      }
    };

    checkSlugAsync();
  }, [slugCandidate, token, resourceType]);

  return [hasValidSlug, availableSlug, isFetching];
}
