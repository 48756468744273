import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import {
  createInputTextBlock,
  extractTextFromHtml,
  findCursorPositionOnChange,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ENTER,
  KEYCODE_ESCAPE,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  hasStopEventPropagation,
} from "../services/commonUsefulFunctions";
import StyleConstants from "../services/globalVariables";
import { useSelector } from "react-redux";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import {
  pressUpSuggestion,
  pressDownSuggestions,
  getCurrentActiveIndex,
  makeAllSuggestionsInactive
} from "../services/sharedEventFormFunctions";
import { getDefaultFontColor } from "../lib/styleFunctions";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { replaceUnixSpaceWithStringSpace, truncateString } from "../lib/stringFunctions";
import { blurCalendar } from "../services/appFunctions";

/*
This component is used when we need to add blocks into the text field 
such as in nlp for title input or availability settings with duration and time zone
*/

export default function InputFieldWithBlocks({
  containerClassname,
  onKeyDown,
  initialSummary,
  setText,
  options,
  suggestionWidth,
  containerID,
  resetCounter
}) {
  const [hoverOverSuggestion, setHoverSuggestion] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const componentIsMounted = useIsMounted();
  const isFirstLoadRef = useRef(false)
  const setTextFunctionRef = useRef(setText);

  const handleInputPaste = (e) => {
    e.preventDefault();
    let element = document.getElementById(containerID);

    if (!element) {
      return;
    }

    // get text representation of clipboard
    const plainText = (e.originalEvent || e).clipboardData.getData("text/plain");

    // insert text manually
    document.execCommand("insertHTML", false, plainText);
    element = null;
  }

  useEffect(() => {
    let element = document.getElementById(containerID);
    element?.addEventListener("input", onChangeInputContentEditableChange);
    element?.addEventListener("keydown", onInputContentEditableKeyDown);
    element?.addEventListener("paste", handleInputPaste);
    element = null;
    addValueIntoTitle();
    isFirstLoadRef.current = true;

    return () => {
      let element = document.getElementById(containerID);
      element?.removeEventListener("input", onChangeInputContentEditableChange);
      element?.removeEventListener("keydown", onInputContentEditableKeyDown);
      element?.addEventListener("paste", handleInputPaste);
      element = null;
    };
  }, []);

  useEffect(() => {
    setTextFunctionRef.current = setText
  }, [setText])

  useEffect(() => {
    if (!isFirstLoadRef.current) {
      return
    }
    textBoxRefs.current = {
      summary: initialSummary,
      replaceString: "",
      suggestions: [],
      refs: {},
    };
    setRenderCount(Math.random());
    addValueIntoTitle();
    // if resetCounter changes -> need to update initial summary and set text 
  }, [resetCounter]);

  const textBoxRefs = useRef({
    summary: initialSummary ?? "",
    suggestions: [],
    replaceString: "",
    refs: {},
  });

  const addValueIntoTitle = () => {
    let element = document.getElementById(containerID);
    if (!element) {
      return;
    }

    let updatedSpanSummary = replaceUnixSpaceWithStringSpace(
      convertTextToSpans(textBoxRefs.current.summary)
    );

    element.innerHTML = updatedSpanSummary;
    element = null;

    const updatedSummary = extractTextFromHtml(updatedSpanSummary);
    textBoxRefs.current.summary = updatedSummary;
  };

  const onChangeInputContentEditableChange = (e) => {
    if (!componentIsMounted.current) {
      return;
    }

    let element = document.getElementById(containerID);

    if (!element) {
      return;
    }

    const DEBUG_MODE = false;
    const updatedSummary = element.innerText;
    let updatedTextBoxRefs = { summary: updatedSummary };
    element = null;

    DEBUG_MODE &&
      console.log(
        "onChangeInputContentEditableChange_updatedSummary",
        updatedSummary
      );
    let changeStartedIndex = findCursorPositionOnChange(
      textBoxRefs.current.summary,
      updatedSummary
    );
    DEBUG_MODE && console.log("changeStartedIndex", changeStartedIndex);

    // text up to cursor
    let upToText = updatedSummary.substr(0, changeStartedIndex + 1);

    // get last word before space
    let textArray = upToText.split(" ");
    let lastWord = textArray[textArray.length - 1];
    DEBUG_MODE && console.log("lastWord", lastWord);

    // check if invitee_name starts with last word
    let lastWordIninviteeblockIndex;
    const loweredCaseLastWord = lastWord?.toLowerCase().trim();
    //  = INVITEE_NAME_BLOCK.toLowerCase().indexOf(lastWord.toLowerCase());
    options.forEach((p) => {
      const indexLocation = p.value.toLowerCase().indexOf(loweredCaseLastWord);
      if (
        !isNullOrUndefined(indexLocation) &&
        (isNullOrUndefined(lastWordIninviteeblockIndex) ||
          indexLocation > lastWordIninviteeblockIndex)
      ) {
        // get last location
        lastWordIninviteeblockIndex = indexLocation;
      }
    });
    DEBUG_MODE &&
      console.log("lastWordIninviteeblockIndex", lastWordIninviteeblockIndex);

    if (
      lastWord &&
      (lastWordIninviteeblockIndex === 0 || lastWordIninviteeblockIndex === 2)
    ) {
      updatedTextBoxRefs.replaceString = lastWord;
      let suggestions = [];
      options.forEach((p) => {
        if (p.value.toLowerCase().trim().includes(loweredCaseLastWord)) {
          suggestions = suggestions.concat({
            isActive: suggestions.length === 0,
            description: p.label,
            index: suggestions.length,
            extraTextOnSelect: p.extraTextOnSelect,
          });
        }
      });
      updatedTextBoxRefs.suggestions = suggestions;
    } else if (e.data === "{") {
      updatedTextBoxRefs.replaceString = "{";
      updatedTextBoxRefs.suggestions = options.map((p, index) => {
        return {
          isActive: index === 0,
          description: p.label,
          index,
          extraTextOnSelect: p.extraTextOnSelect,
        };
      });
    } else {
      updatedTextBoxRefs.replaceString = "";
      updatedTextBoxRefs.suggestions = [];
    }

    DEBUG_MODE && console.log("updatedTextBoxRefs", updatedTextBoxRefs);
    textBoxRefs.current = updatedTextBoxRefs;
    getInnerTextAndSetParentContainer();
    setRenderCount(Math.random());
  };

  const onKeyUpDownEnter = (e) => {
    if (
      textBoxRefs.current.suggestions?.length > 0 &&
      (e.keyCode === KEYCODE_ENTER || e.keyCode === KEYCODE_TAB)
    ) {
      const currentIndex = getCurrentActiveIndex(
        textBoxRefs.current.suggestions
      );
      if (isNullOrUndefined(currentIndex)) {
        return;
      }

      const highLightedSuggestion =
        textBoxRefs.current.suggestions[currentIndex];
      handleOnClickSuggestion(highLightedSuggestion);
    }
  };

  const convertTextToSpans = (text) => {
    let updatedText = text;

    options?.forEach((p) => {
      const regex = new RegExp(p.value, "gmi");
      updatedText = updatedText.replace(
        regex,
        createInputTextBlock(
          p.label,
          isDarkMode,
          determineTextContainerColor(),
          true
        )
      );
    });

    return updatedText;
  };

  const getInnerTextAndSetParentContainer = () => {
    let element = document.getElementById(containerID);

    if (!element || !element.innerText) {
      element = null;
      return;
    }
    let updatedText = element.innerText;
    options?.forEach((p) => {
      const regex = new RegExp(p.label, "gmi");
      updatedText = replaceUnixSpaceWithStringSpace(
        updatedText.replace(regex, p.value)
      );
    });

    setTextFunctionRef.current(updatedText);
    element = null;
  };

  const handleOnClickSuggestion = (suggestion) => {
    let element = document.getElementById(containerID);

    if (isEmptyObjectOrFalsey(suggestion) || !element) {
      element = null;
      return;
    }

    let updatedSummary = element.innerHTML;

    const displayBlock = createInputTextBlock(
      suggestion.description,
      isDarkMode,
      determineTextContainerColor(),
      true
    );
    updatedSummary = updatedSummary.replace(
      textBoxRefs.current.replaceString,
      suggestion.extraTextOnSelect
        ? displayBlock + suggestion.extraTextOnSelect
        : displayBlock
    );

    element.innerHTML = updatedSummary;

    let updatedSummaryText = extractTextFromHtml(updatedSummary);

    const setCaretToEnd = () => {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(element);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
      range.detach(); // optimization

      // set scroll to the end if multiline
      element.scrollTop = element.scrollHeight;
    };

    setCaretToEnd(element);
    element = null;

    textBoxRefs.current = {
      summary: updatedSummaryText,
      replaceString: "",
      suggestions: [],
    };
    setRenderCount(Math.random());
    getInnerTextAndSetParentContainer();
  };

  const determineTextContainerColor = () => {
    return getDefaultFontColor(isDarkMode);
  };

  const onKeyDownEscape = () => {
    blurCalendar();
  };

  const onInputContentEditableKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }

    if (
      [
        KEYCODE_ENTER,
        KEYCODE_TAB,
        KEYCODE_UP_ARROW,
        KEYCODE_DOWN_ARROW,
        KEYCODE_ESCAPE,
      ].includes(e.keyCode)
    ) {
      if (e.keyCode === KEYCODE_ENTER) {
        e.preventDefault();
        onKeyUpDownEnter(e);
      } else if (
        e.keyCode === KEYCODE_TAB &&
        textBoxRefs.current.suggestions?.length > 0
      ) {
        e.preventDefault();
        onKeyUpDownEnter(e);
      } else if (e.keyCode === KEYCODE_ESCAPE) {
        e.preventDefault();
        if (textBoxRefs.current.suggestions?.length > 0) {
          hasStopEventPropagation(e);
          resetSuggestions();
        } else {
          onKeyDownEscape();
        }
      } else {
        // for arrow keys
        if (textBoxRefs.current.suggestions?.length > 0) {
          hasStopEventPropagation(e);
          e.preventDefault();
        }

        const currentIndex = getCurrentActiveIndex(
          textBoxRefs.current.suggestions
        );
        if (e.keyCode === KEYCODE_UP_ARROW) {
          // const { suggestion, index } = pressUpSuggestion(
          const { suggestion } = pressUpSuggestion(
            currentIndex,
            textBoxRefs.current.suggestions
          );
          textBoxRefs.current.suggestions = suggestion;
          setRenderCount(Math.random());
        } else if (e.keyCode === KEYCODE_DOWN_ARROW) {
          // const { suggestion, index } = pressDownSuggestions(
          const { suggestion } = pressDownSuggestions(
            currentIndex,
            textBoxRefs.current.suggestions
          );
          textBoxRefs.current.suggestions = suggestion;
          setRenderCount(Math.random());
        }
      }
    }
  };

  const setSuggestionActive = (index) => {
    if (isEmptyObjectOrFalsey(textBoxRefs.current.suggestions)
      || textBoxRefs.current.suggestions.length === 0
    ) {
      return;
    }

    let updatedSuggestions = makeAllSuggestionsInactive(textBoxRefs.current.suggestions);
    updatedSuggestions.forEach(s => {
      if (s.index === index) {
        s.isActive = true;
      }
    });

    textBoxRefs.current.suggestions = updatedSuggestions;
    setRenderCount(Math.random());
  }

  const resetSuggestions = () => {
    textBoxRefs.current = {
      summary: initialSummary,
      replaceString: "",
      suggestions: [],
      refs: {},
    };
    setRenderCount(Math.random());
  };

  const onBlur = () => {
    if (hoverOverSuggestion) {
      return;
    }

    resetSuggestions();
  };

  const renderSuggestions = () => {
    let suggestions = textBoxRefs.current.suggestions;
    if (!suggestions || suggestions.length === 0) {
      return null;
    }

    return (
      <div
        className="autocomplete-dropdown-container margin-top-ten"
        style={{
          marginLeft: 0,
          maxHeight: 180,
          overflowY: "auto",
          width: suggestionWidth || 320,
        }}
        onMouseEnter={() => setHoverSuggestion(true)}
        onMouseLeave={() => setHoverSuggestion(false)}
      >
        <div
          className="location-suggestion-item"
          style={{ width: suggestionWidth || 330, fontWeight: 400 }}
        >
          Available variables
        </div>

        {suggestions.map((suggestion) => {
          return (
            <div
              className="location-suggestion-item"
              style={
                suggestion.isActive
                  ? {
                      backgroundColor: isDarkMode
                        ? StyleConstants.darkModeHoverBackgroundColor
                        : "#F2F3F4",
                      width: suggestionWidth || 330,
                    }
                  : { width: suggestionWidth || 330 }
              }
              onMouseLeave={() => makeAllSuggestionsInactive(textBoxRefs.current.suggestions)}
              onMouseEnter={() => setSuggestionActive(suggestion?.index)}
              key={`attendee_suggestion_${suggestion.index}`}
              onClick={() => handleOnClickSuggestion(suggestion)}
              // ref={this.state.refs[suggestion.key]}
            >
              <span>{truncateString(suggestion.description, 50)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div
        id={containerID}
        className={classNames(
          containerClassname,
          "create-event-input-field-description resize-none p-2.5 color-default-text-color overflow-y-auto line-height-2-important"
        )}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlurCapture={onBlur}
      ></div>
      {renderSuggestions()}
    </div>
  );
}
