import React from 'react';

function HelpCenterDescription({text}) {
	return (
    <div
      className="overflow-y-auto max-h help-center-description-container list-item default-font-size"
      target="_blank"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    ></div>
	)
}

export default React.memo(HelpCenterDescription);
