import React from "react";
import { X } from "react-feather";
import { pluralize } from "../../../../lib/stringFunctions";

interface OpenSeatsRowProps {
  plan: string
  removeSeats: () => void
  seats: number
}

/**
 * A row in NewMemberInput that represents empty seats being added.
 */
export default function OpenSeatsRow({ plan, removeSeats, seats }: OpenSeatsRowProps) {
  if (seats === 0) {
    return null;
  }

  return (
    <div className="team-plan-new-member-input-row">
      <div className="flex-grow">
        {seats} {pluralize(seats, "seat")}&nbsp;
        <span className="secondary-text-color">for {plan}</span>
      </div>
      <div className="group-scheduling-team-plans-add-attendees-x">
        <X
          className="cursor-pointer"
          onClick={removeSeats}
          size={14}
        />
      </div>
    </div>
  );
}
