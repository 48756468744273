import React from "react";
import { Search, X } from "react-feather";

export default function SearchBar({
  value,
  onChange,
  onDelete,
  onKeyDown,
  width,
}) {
  return (
    <div
      className="flex items-center help-center-search-box"
      style={{ width: width ?? undefined }}
    >
      <Search size={16} />
      <input
        value={value}
        autoFocus={true}
        onChange={onChange}
        className="help-container-search-input w-full"
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
      />
      <X size={16} className="clickable-icon" onClick={onDelete} />
    </div>
  );
}
