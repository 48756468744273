import React from "react";

export default function VimcalEALogoBlueBlock({width}) {
  return (
    <svg width={width || "155"} height="31" viewBox="0 0 155 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_372_2924)">
        <mask id="mask0_372_2924" maskUnits="userSpaceOnUse" x="0" y="2" width="103" height="27">
          <path d="M102.924 2.23457H0V28.7575H102.924V2.23457Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_372_2924)">
          <path d="M1.57656 7.51032C-0.0559769 9.24993 -0.620307 11.686 0.848145 13.5569C1.87393 14.8637 3.50416 15.4168 5.03152 15.131C11.2413 9.10796 17.2086 7.15194 21.7695 6.76778C22.4746 6.70838 23.1456 6.68662 23.7777 6.69306C25.0276 5.36983 26.4348 4.18892 27.9753 3.1823L27.9865 3.15406C27.9865 3.15406 21.3325 1.60387 14.855 2.52913C9.19904 3.33705 3.6194 5.33373 1.57656 7.51032Z" fill="#2B3163"/>
          <path d="M6.16616 20.3321C4.94445 18.7755 4.9649 16.6401 6.0836 15.1208C11.9746 9.62411 17.5702 7.84196 21.8295 7.48318L22.9452 7.62291C20.8616 10.0784 19.2881 12.9572 18.3584 16.0818C17.1322 16.4158 15.9214 17.0271 14.7708 17.8293C13.2567 18.8848 11.8268 20.2833 10.5725 21.8583C8.98291 22.2444 7.24209 21.7028 6.16616 20.3321Z" fill="#2B3163"/>
          <path d="M11.0947 22.354C11.0076 22.5109 10.9294 22.6748 10.8608 22.8457C9.97497 25.0529 11.046 27.5603 13.2531 28.4462C13.9836 28.7394 14.7466 28.8184 15.4731 28.7127C16.2003 28.6108 16.9111 28.3213 17.5302 27.8354C17.6592 27.7342 17.781 27.6268 17.8952 27.5146C17.7926 27.023 17.7049 26.5252 17.6329 26.0213C17.1839 22.8784 17.3838 19.7924 18.1309 16.8988C17.1421 17.2228 16.1503 17.7418 15.1807 18.4178C13.7155 19.4392 12.321 20.806 11.0947 22.354Z" fill="#2B3163"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M29.6265 11.6492L34.9504 24.3281H37.8088L43.1328 11.6492L40.0477 11.6376L36.3796 20.77L32.7115 11.6376L29.6265 11.6492Z" fill="#2B3163"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M44.5288 11.6489V24.3281H47.3281V11.6489H44.5288ZM69.6519 24.3281V16.4572C69.6519 13.1714 67.2834 11.4266 64.9177 11.4266C63.3779 11.4266 61.7598 12.2105 60.761 13.5659L60.5816 13.8093L60.421 13.5531C59.547 12.1595 58.0588 11.4266 56.5693 11.4266C55.1129 11.4266 54.0303 12.1288 53.2688 13.0329L52.9057 13.4643V11.6489H50.1064V24.3281H52.9057V17.5933C52.9057 16.6531 53.1147 15.742 53.598 15.0599C54.0882 14.3684 54.849 13.9295 55.9024 13.9295C56.7406 13.9295 57.3966 14.259 57.8366 14.849C58.2704 15.4308 58.4794 16.2455 58.4794 17.1982V24.3281H61.2787V17.0914C61.3426 16.2607 61.5936 15.466 62.0834 14.8829C62.5786 14.2933 63.3045 13.9295 64.2756 13.9295C65.1137 13.9295 65.7698 14.259 66.2098 14.849C66.6436 15.4308 66.8525 16.2455 66.8525 17.1982V24.3281H69.6519ZM83.4536 20.6178C82.4709 22.9041 80.2498 24.5257 77.5563 24.5257C73.8692 24.5257 71.0192 21.5332 71.0192 17.9884C71.0192 14.2999 73.9883 11.4266 77.5563 11.4266C80.2031 11.4266 82.4465 13.0263 83.4525 15.3575L80.9036 16.0644C80.2817 14.8244 79.0686 13.9048 77.5563 13.9048C75.332 13.9048 73.6208 15.8115 73.6208 17.9884C73.6208 20.1672 75.3338 22.0473 77.5563 22.0473C79.0882 22.0473 80.3045 21.1545 80.9067 19.8902L83.4536 20.6178ZM94.6343 22.6197V24.3281H97.4337V11.6489H94.6343V13.3912L94.2793 13.0174C93.3775 12.0681 92.0968 11.4266 90.5012 11.4266C87.0642 11.4266 84.2111 14.2924 84.2111 17.9884C84.2111 21.5385 87.066 24.5257 90.5012 24.5257C92.0992 24.5257 93.3803 23.907 94.2812 22.9824L94.6343 22.6197ZM90.8225 22.0227C88.5299 22.0227 86.8128 20.1716 86.8128 17.9884C86.8128 15.807 88.5283 13.9295 90.8225 13.9295C93.0397 13.9295 94.8319 15.8044 94.8319 17.9884C94.8319 20.1752 93.0126 22.0227 90.8225 22.0227ZM100.125 7.45V24.3281H102.924V7.45H100.125Z" fill="#2B3163"/>
          <path d="M47.6213 8.14334C47.6213 9.06953 46.8703 9.82036 45.9441 9.82036C45.018 9.82036 44.2671 9.06953 44.2671 8.14334C44.2671 7.21713 45.018 6.46629 45.9441 6.46629C46.8703 6.46629 47.6213 7.21713 47.6213 8.14334Z" fill="#2B3163"/>
          <path d="M21.3398 7.45675C21.7763 7.41933 22.1983 7.39746 22.6054 7.38855C22.5468 7.45727 22.4885 7.52637 22.4306 7.5958L21.3398 7.45675Z" fill="#2B3163"/>
        </g>
        <path d="M149.876 0H115.636C113.372 0 111.536 1.8359 111.536 4.10059V26.8916C111.536 29.1563 113.372 30.9922 115.636 30.9922H149.876C152.141 30.9922 153.977 29.1563 153.977 26.8916V4.10059C153.977 1.8359 152.141 0 149.876 0Z" fill="#2B3163"/>
        <path d="M119.717 23.3395V7.56029H130.016V9.73524H122.212V14.2344H129.035V16.388H122.212V21.1644H130.123V23.3395H119.717ZM132.318 23.3395L137.819 7.56029H141.018L146.54 23.3395H143.918L142.702 19.7997H136.156L134.941 23.3395H132.318ZM136.902 17.5821H141.956L139.44 10.2257L136.902 17.5821Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_372_2924">
          <rect width="154.066" height="31" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
