import React, { Component } from "react";
import { Navigate } from "rbc-fork-react-big-calendar";
import TimeGrid from "rbc-fork-react-big-calendar/lib/TimeGrid";
import { determineCalendarView } from "../../lib/stateManagementFunctions";
import { addDays, subDays, startOfDay } from "date-fns";
import { isSameOrBeforeDay } from "../../services/commonUsefulFunctions";
import { getRBCMinTime, getRBCMaxTime } from "../../lib/rbcFunctions";

class MyWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minTime: getRBCMinTime(),
      maxTime: getRBCMaxTime()
    }
  }

  render() {
    let { date } = this.props;
    let range = MyWeek.range(date, this.props);

    return <TimeGrid {...this.props} range={range} max={this.state.maxTime} min={this.state.minTime} />
  }
}

MyWeek.range = (date, props) => {
  let start = MyWeek.setToBeginningOfDay(date);
  let selectedCalendarView = 6;

  if (props && props.selectedCalendarView) {
    selectedCalendarView =
      determineCalendarView(props.selectedCalendarView) - 1;
  }

  let end = MyWeek.setToBeginningOfDay(addDays(start, selectedCalendarView));

  let current = start;
  let range = [];

  while (isSameOrBeforeDay(current, end)) {
    range = range.concat(current);
    current = addDays(current, 1);
  }

  return range;
};

MyWeek.title = (date) => {
  return `Week of ${date.toLocaleDateString()}`;
};

MyWeek.setToBeginningOfDay = (date) => {
  return startOfDay(date);
};

MyWeek.navigate = (date, action, ...props) => {
  let selectedCalendarView = 7;

  switch (action) {
    case Navigate.PREVIOUS:
      return subDays(date, selectedCalendarView);

    case Navigate.NEXT:
      return addDays(date, selectedCalendarView);

    default:
      return date;
  }
};

export default MyWeek;
