import React, { useState } from "react";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { pluralize } from "../../lib/stringFunctions";
import { isErrorResponse } from "../../services/api";
import { handleError } from "../../services/commonUsefulFunctions";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import { useHasBillingBeenFetched } from "../../services/stores/finance";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import CriticalAlert from "../availability/alerts/criticalAlert";
import CustomButtonV2 from "../buttons/customButtonV2";
import PlusMinusInput from "../inputs/plusMinusInput";
import { inviteTeamMembers } from "../queries/teamPlans";
import { useOpenSeats } from "../teamPlans/hooks";

interface RemoveSeatsProps {
  onClose: () => void
}

export default function RemoveSeats({ onClose }: RemoveSeatsProps) {
  const [vimcalSeats, setVimcalSeats] = useState(0);
  const [vimcalEASeats, setVimcalEASeats] = useState(0);
  const currentUser = useSelector(state => state.currentUser);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetBillingHasBeenFetched = useHasBillingBeenFetched(state => state.resetBillingHasBeenFetched);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const setTeamPlan = useTeamPlan(state => state.setTeamPlan);
  const { openVimcalSeats, openVimcalEASeats } = useOpenSeats();

  if (isEmptyObjectOrFalsey(teamPlan)) {
    onClose();
    return null;
  }

  const onSubmit = async () => {
    if (!currentUser) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await inviteTeamMembers(
        currentUser,
        { vimcalEASeats: -1 * vimcalEASeats, vimcalSeats: -1 * vimcalSeats },
      );

      if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        return;
      }
      setTeamPlan(response.team_plan);
      resetBillingHasBeenFetched();
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);

      onClose();
    } catch (e) {
      handleError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const canSubmit = (
    (vimcalSeats > 0 || vimcalEASeats > 0) &&
    vimcalSeats <= openVimcalSeats &&
    vimcalEASeats <= openVimcalEASeats
  );

  return (
    <div className="default-font-size">
      <div className="flex flex-col gap-6">
        <RemoveSeatInput
          label="Vimcal seats"
          openSeats={openVimcalSeats}
          seatsToRemove={vimcalSeats}
          setSeatsToRemove={setVimcalSeats}
          totalSeats={teamPlan.non_ea_seat_count}
        />
        <RemoveSeatInput
          label="Vimcal EA seats"
          openSeats={openVimcalEASeats}
          seatsToRemove={vimcalEASeats}
          setSeatsToRemove={setVimcalEASeats}
          totalSeats={teamPlan.ea_seat_count}
        />
      </div>
      <div className="flex justify-end mt-10 gap-2.5">
        <CustomButtonV2
          buttonType={WHITE_BUTTON}
          disabled={isSubmitting}
          label="Cancel"
          onClick={onClose}
        />
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          disabled={!canSubmit}
          label="Save"
          onClick={onSubmit}
          shouldRenderSpinner={isSubmitting}
        />
      </div>
    </div>
  );
}

interface RemoveSeatInputProps {
  label: string
  openSeats: number
  seatsToRemove: number
  setSeatsToRemove: StateSetter<number>
  totalSeats: number
}

function RemoveSeatInput({
  label,
  openSeats,
  seatsToRemove,
  setSeatsToRemove,
  totalSeats,
}: RemoveSeatInputProps) {
  return (
    <>
      <div>
        <div className="font-size-14 mb-2">{label}</div>
        <div className="flex gap-2 items-center mb-2">
          Remove
          <PlusMinusInput value={seatsToRemove} setValue={setSeatsToRemove} />
          {pluralize(seatsToRemove, "seat")}
        </div>
        <div className="secondary-text-color">
          Currently {totalSeats} {pluralize(totalSeats, "seat")} total,{" "}
          {openSeats} {pluralize(openSeats, "seat")} unassigned
        </div>
      </div>
      {seatsToRemove > openSeats ? (
        <CriticalAlert
          excludeDefaultMargins
          subText="You will need to remove members from the team"
        />
      ) : null}
    </>
  );
}
