import React, { useState } from "react";
import { CONFIRMED, getDisplayName, isMemberOwner, PENDING, ROLE_OPTIONS, TEAM_ROLES, TeamPlanMemberWithStatus } from "./sharedFunctions";
import { capitalizeFirstLetter, isSameEmail } from "../../lib/stringFunctions";
import { X } from "react-feather";
import classNames from "classnames";
import ColoredLine from "../line";
import { useIsCurrentUserAdmin } from "./hooks";
import { getObjectEmail } from "../../lib/objectFunctions";
import { useSelector } from "react-redux";
import { updateUserRole } from "../queries/teamPlans";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isErrorResponse } from "../../services/api";
import { useTeamPlan } from "../../services/stores/userData";
import broadcast from "../../broadcasts/broadcast";
import { GENERIC_ERROR_MESSAGE, SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../services/globalVariables";
import RoleSummary from "./roleSummary";
import CustomSelectV2, { SELECT_VARIANTS } from "../select/selectV2";
import useIsUserTokenLoggedIn from "../../services/customHooks/useIsUserTokenLoggedIn";
import { getUserToken } from "../../lib/userFunctions";
import ConfirmChangeRoleModal from "./confirmChangeRoleModal";

interface MemberListProps {
  removeMember: (member: TeamPlanMemberWithStatus) => void
  showConfirmation: () => void
  teamMembers: TeamPlanMemberWithStatus[]
}

/**
 * The table of all the members in the team plan.
 *
 * The sort order is based on the provided array, so if sorting is needed do so before rendering this.
 *
 * TODO: Add pagination.
 */
export default function MemberList({ removeMember, showConfirmation, teamMembers }: MemberListProps) {
  const headerClassName = "default-font-size secondary-text-color font-medium";

  return (
    <div className="grid gap-x-12 gap-y-4 items-center" style={{ gridTemplateColumns: "auto max-content max-content max-content max-content"}}>
      <ColoredLine inputClassName="col-span-5" />

      <div className={headerClassName}>TEAM MEMBER</div>
      <div className={headerClassName}>STATUS</div>
      <div className={headerClassName}>SEAT</div>
      <div className={classNames(headerClassName, "flex items-center gap-1")}>ROLE <RoleSummary /></div>
      <div className={headerClassName}></div> {/* Empty header */}

      <ColoredLine inputClassName="col-span-5" />

      {teamMembers.map(member => (
        <MemberRow
          key={member.email}
          member={member}
          onClickRemove={removeMember}
          showConfirmation={showConfirmation}
        />
      ))}
    </div>
  );
}

interface MemberRowProps {
  member: TeamPlanMemberWithStatus
  onClickRemove: (member: TeamPlanMemberWithStatus) => void
  showConfirmation: () => void
}

/**
 * A single member's entry in the member list.
 */
function MemberRow({ member, onClickRemove, showConfirmation }: MemberRowProps) {
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const isOwner = isMemberOwner(member);

  const statusColorClassName = (() => {
    if (member.status === CONFIRMED) {
      return "text-green-500";
    }

    if (member.status === PENDING) {
      return "text-yellow-500";
    }
  })();

  return (
    <>
      <div className="default-font-size overflow-hidden">
        <div className="truncate">{getDisplayName(member)}</div>
        {isSameEmail(getDisplayName(member), getObjectEmail(member)) ? null : (
          <div className="secondary-text-color truncate">{getObjectEmail(member)}</div>
        )}
      </div>
      <div className={classNames(statusColorClassName, "default-font-size")}>
        {capitalizeFirstLetter(member.status)}
      </div>
      <div className="font-size-11">
        {member.scheduling_for_others ? "Vimcal EA" : "Vimcal"}
      </div>
      <MemberRole member={member} showConfirmation={showConfirmation} />
      {(isCurrentUserAdmin && !isOwner)
        ? <X size={16} className="clickable-icon" onClick={() => onClickRemove(member)} />
        : <div></div>} {/* Need empty div for grid. */}
    </>
  );
}

interface MemberRoleProps {
  member: TeamPlanMemberWithStatus
  showConfirmation: () => void
}

/**
 * The member's role in the team plan. If the current user has permission to edit this member's role,
 * then a dropdown is rendered to make the update.
 */
function MemberRole({ member, showConfirmation }: MemberRoleProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const [isUpdating, setIsUpdating] = useState(false);
  const [pendingRoleForCurrentUser, setPendingRoleForCurrentUser] = useState<ValueOf<typeof TEAM_ROLES>>();
  const { setTeamPlan } = useTeamPlan();

  const isMemberCurrentUser = useIsUserTokenLoggedIn(getUserToken(member));
  const isReadOnly = !isCurrentUserAdmin || member.status === PENDING || member.team_plan_role === TEAM_ROLES.OWNER;
  const isModalOpen = !!pendingRoleForCurrentUser;

  const updateRole = async (newRole: ValueOf<typeof TEAM_ROLES> | undefined) => {
    if (!newRole || !currentUser) {
      return;
    }

    setIsUpdating(true);
    const response = await updateUserRole(currentUser, { userToken: member.token, role: newRole });
    setIsUpdating(false);

    if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
      return;
    } else {
      showConfirmation();
    }

    setTeamPlan(response.team_plan);
  };

  const onChange = (newRole: ValueOf<typeof TEAM_ROLES> | undefined) => {
    if (isMemberCurrentUser && newRole == TEAM_ROLES.MEMBER) {
      // When a user changes their own role down to member, confirm before making the change.
      setPendingRoleForCurrentUser(newRole);
    } else if (newRole != member.team_plan_role) {
      updateRole(newRole);
    }
  };

  if (isReadOnly) {
    return (
      <div className="font-size-11">{capitalizeFirstLetter(member.team_plan_role || TEAM_ROLES.MEMBER)}</div>
    );
  }

  return (
    <div className="-ml-3 w-24">
      <CustomSelectV2
        value={ROLE_OPTIONS.find(option => option.value === member.team_plan_role)}
        options={ROLE_OPTIONS}
        onChange={option => onChange(option?.value)}
        isDisabled={isUpdating}
        isSearchable={false}
        variant={SELECT_VARIANTS.OUTLINED}
      />
      {isModalOpen ? <ConfirmChangeRoleModal
        isModalOpen={isModalOpen}
        closeModal={() => setPendingRoleForCurrentUser(undefined)}
        confirm={() => updateRole(pendingRoleForCurrentUser)}
        newRole={pendingRoleForCurrentUser}
      /> : null}
    </div>
  );
}
