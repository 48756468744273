import React, { Component } from "react";
import {
  KEYCODE_ESCAPE,
  hasStopEventPropagation,
  isMac,
} from "../services/commonUsefulFunctions";
import {
  BACKEND_MONTH,
  COMMAND_KEY,
  OPEN_SIDE_MENU_PANEL_HOTKEY,
  PC_CONTROL_KEY,
  TOGGLE_RIGHT_PANEL_HOTKEY,
} from "../services/globalVariables";
import CloseButton from "../components/closeButton";
import { connect } from "react-redux";
import { isGoogleUser } from "../services/appFunctions";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import { getActiveCommandCentersKey } from "../services/commonUsefulFunctions";
import SearchBar from "./searchBar";
import broadcast from "../broadcasts/broadcast";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { getInputStringFromEvent } from "../lib/stringFunctions";
import { shouldHideAIFeatures } from "../lib/featureFlagFunctions";
import { HOT_KEY_MAP } from "../lib/hotkeyFunctions";
import FilledShortcutTiles from "./shortcutTiles/filledShortcutTiles";

const activationKey = isMac() ? COMMAND_KEY : PC_CONTROL_KEY;

function getBasicLegend({ isGoogleUser, showReversSlots }) {
  const basicCommands = [
    { text: "Command Center", tile: `${activationKey} K` },
    { text: "Flash shortcuts", tile: "F" },
    { text: "Open next event", tile: "N" },
    { text: "Open video conferencing", tile: "V" },
    { text: "Copy video conferencing link", tile: "Y and V" },
    { text: "Open location", tile: "L" },
    { text: "Copy location", tile: "Y and L" },
    { text: "Reschedule event", tile: "R" },
    { text: "Availability", tile: "A" },
    { text: "Open Personal Links", tile: "Shift Y" },
    { text: "Time Travel", tile: "Z" },
    { text: "Toggle time zone", tile: "'" },
    { text: "Search", tile: "/" },
    { text: "Focus Mode", tile: "0" },
    { text: "Meet With", tile: `${activationKey} J` },
    { text: "Instant Open", tile: `${activationKey} O` },
    { text: "Use template", tile: `${activationKey} ;` },
  ];

  const reverseSlotsCommand = {
    text: "Free Time Finder",
    tile: `${getActiveCommandCentersKey()} V`,
  };

  if (!isGoogleUser) {
    if (showReversSlots) {
      return basicCommands.concat(reverseSlotsCommand);
    }
    return basicCommands;
  }

  let totalCommands = basicCommands;

  if (showReversSlots) {
    totalCommands = totalCommands.concat(reverseSlotsCommand);
  }

  return totalCommands;
}

const EVENTS_SHORTCUTS_LEGEND = [
  { text: "Create event", tile: "C" },
  { text: "Edit event", tile: "E" },
  { text: "Delete event", tile: "Del" },
  { text: 'RSVP "Attending"', tile: "G and Y" },
  { text: 'RSVP "Maybe"', tile: "G and M" },
  { text: 'Respond "Decline"', tile: "G and N" },
  { text: "Email guests", tile: "G and E" },
  { text: "Edit title", tile: "G and S" },
  { text: "Edit date", tile: "G and D" },
  { text: "Edit start time", tile: "G and T" },
  { text: "Edit end time", tile: "G and H" },
  { text: "Edit location", tile: "G and L" },
  { text: "Edit video conferencing", tile: "G and V" },
  { text: "Edit guests", tile: "G and A" },
  { text: "Edit description", tile: "G and K" },
  { text: "Edit reminders", tile: "G and U" },
];

class ShortcutsLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };

    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    broadcast.publish("DISABLE_HOT_KEYS");
  }

  componentWillUnmount() {
    broadcast.publish("ENABLE_HOT_KEYS");
  }

  render() {
    return (
      <div className="shortcuts-legend-container position-relative">
        <div className="key-board-short-cuts-title">Keyboard Shortcuts</div>

        <div style={{ position: "absolute", left: 320, top: 25 }}>
          <CloseButton
            onClick={
              this.props.closeShortcutsLegend && this.props.closeShortcutsLegend
            }
          />
        </div>

        <SearchBar
          value={this.state.searchValue}
          onChange={this.onChangeSearchValue}
          onDelete={() => this.setState({ searchValue: "" })}
          onKeyDown={this.onKeyDown}
          width={"320px"}
        />

        {this.renderSection({
          section: getBasicLegend({
            isGoogleUser: isGoogleUser(this.props.currentUser),
            showReversSlots: !shouldHideAIFeatures(this.props.currentUser),
          }),
          header: "Most frequently used",
        })}

        {this.renderSection({
          section: this.createNavigationShortCuts(),
          header: "Navigation",
        })}

        {this.renderSection({
          section: EVENTS_SHORTCUTS_LEGEND,
          header: "Events",
        })}
      </div>
    );
  }

  onKeyDown(e) {
    if (e?.keyCode === KEYCODE_ESCAPE) {
      layoutBroadcast.publish("HIDE_LEGEND_PANEL");
      return;
    }
  }

  onChangeSearchValue(e) {
    hasStopEventPropagation(e);
    this.setState({ searchValue: getInputStringFromEvent(e) });
  }

  renderSection({ section, header }) {
    if (!section) {
      return null;
    }

    const filteredSection = this.state.searchValue
      ? section.filter((s) => {
          return (
            s.text
              .toLowerCase()
              .includes(this.state.searchValue.toLowerCase()) ||
            this.state.searchValue.toLowerCase().toLowerCase().includes(s.text)
          );
        })
      : section;

    if (filteredSection.length === 0) {
      return null;
    }

    return (
      <div>
        <div className="shortcuts-legend-section-title">{header}</div>
        {filteredSection.map((s, index) => {
          return (
            <div
              className="shortcuts-legend-section-tile-container"
              key={`shortcuts-legend-section-tile-shortcuts-${index}`}
            >
              <div className="default-font-size">{s.text}</div>

              <div><FilledShortcutTiles shortcut={s.tile} /></div>
            </div>
          );
        })}
      </div>
    );
  }

  createNavigationShortCuts() {
    const activationKey = isMac() ? COMMAND_KEY : PC_CONTROL_KEY;

    return [
      { text: "Jump to today", tile: "T" },
      { text: `Go to previous ${this.determineShortCutHint()}`, tile: `${HOT_KEY_MAP.LAST_WEEK} or K` },
      { text: `Go to next ${this.determineShortCutHint()}`, tile: `${HOT_KEY_MAP.NEXT_WEEK} or J` },
      { text: "View neighboring events", tile: "← ↑ → ↓" },
      { text: "Open side menu", tile: OPEN_SIDE_MENU_PANEL_HOTKEY },
      { text: "Toggle panel", tile: TOGGLE_RIGHT_PANEL_HOTKEY },
      { text: "Jump to date", tile: `${activationKey} K` },
      { text: "Day calendar view", tile: "D" },
      { text: "4-day calendar view", tile: "4" },
      { text: "7-day calendar view", tile: "7" },
      { text: "Week calendar view", tile: "W" },
    ];
  }

  determineShortCutHint() {
    if (this.props.selectedCalendarView === 1) {
      return "day";
    } else if (this.props.selectedCalendarView === 4) {
      return "period";
    } else if (this.props.selectedCalendarView === 7) {
      return "week";
    } else if (this.props.selectedCalendarView === BACKEND_MONTH) {
      return "month";
    } else {
      return "week";
    }
  }
}

function mapStateToProps(state) {
  let { selectedCalendarView, currentUser } = state;

  return { selectedCalendarView, currentUser };
}

const withStore = (BaseComponent) => (props) => {
  const permissionsStore = usePermissionsStore();

  return <BaseComponent {...props} permissionsStore={permissionsStore} />;
};

export default connect(mapStateToProps)(withStore(ShortcutsLegend));
