import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { MapPin, Plus, Users, Video } from "react-feather";
import { conferencingDescriptor } from "../../services/googleCalendarService";
import { splitSlotIntoDuration } from "../../lib/availabilityFunctions";
import { convertEmailToName, shouldRoundToNearest15 } from "../../services/commonUsefulFunctions";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { getAttendeeEmail } from "../../lib/groupSchedulingFunctions";
import { isEmptyArray } from "../../lib/arrayFunctions";
import "../../styles/smartHolds.css";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import { getEventHoldDetails, getEventHoldType, parseHoldEventAttendees } from "../../services/holdFunctions";
import { getEventUserCalendarID } from "../../services/eventResourceAccessors";
import { format, getMinutes } from "date-fns";
import { isEmptyObjectOrFalsey, isTypeString } from "../../services/typeGuards";
import { useDistroListDictionary } from "../../services/stores/eventsData";
import { HOLD_TYPES } from "../../services/globalMaestroVariables";
import { getUserEmail } from "../../lib/userFunctions";

const SmartHolds = ({ event }) => {
  const allLoggedInUsers = useAllLoggedInUsers(state => state.allLoggedInUsers);
  const currentUser = useSelector(state => state.currentUser);
  const emailToNameIndex = useSelector(state => state.emailToNameIndex);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const holdDetails = getEventHoldDetails(event);
  const isDarkMode = useSelector(state => state.isDarkMode);
  const setHoldEvent = useTemporaryStateStore(state => state.setHoldEvent);
  const [hoveredTimeSlot, setHoveredTimeSlot] = useState(null);
  const distroListDictionary = useDistroListDictionary(state => state.distroListDictionary);

  if (
    isEmptyObjectOrFalsey(holdDetails) ||
    getEventHoldType(event) !== HOLD_TYPES.AVAILABILITY_LINK
  ) {
    return null;
  }

  /* Parse hold details */
  const {
    attendees,
    conferencing,
    duration,
    location,
    title,
  } = holdDetails;
  /* Get eventEnd and eventStart for splitSlotIntoDuration */
  const {
    eventEnd,
    eventStart,
  } = event;

  const slotTimes = splitSlotIntoDuration({
    breakDuration: duration,
    currentSlots: [],
    currentUserEmail: getUserEmail(currentUser),
    end: eventEnd,
    hideCancel: true,
    isTemporaryAIEvent: false,
    start: eventStart,
    explicitBreakInto15: getMinutes(eventStart) === 15 || getMinutes(eventStart) === 45,
  });

  /* Parse attendees and convert to name if possible */
  const conferencingText = conferencingDescriptor(conferencing, currentUser);
  const parsedAttendees = parseHoldEventAttendees({
    attendees,
    currentUser,
    masterAccount,
    shouldOnlyParseJSON: true,
  });
  const convertedAttendees = parsedAttendees.map(attendee => convertEmailToName({
    currentUser,
    email: getAttendeeEmail(attendee),
    emailToNameIndex,
    masterAccount,
    allLoggedInUsers,
    distroListDictionary,
  }));

  const openEventFormWithSlot = (slotTime) => {
    setHoldEvent(event);

    availabilityBroadcast.publish(
      "CREATE_EVENT_FROM_SMART_HOLDS", {
        holdDetails,
        slot: slotTime,
        userCalendarID: getEventUserCalendarID(event),
      },
    );
  };

  const renderAttendees = () => {
    if (isEmptyArray(convertedAttendees)) {
      return null;
    }

    return (
      <div className="smart-holds-label">
        <Users className="secondary-text-color mr-2" size={12} />
        {
          convertedAttendees[0] +
          (convertedAttendees.length >= 2 ? `, ${convertedAttendees[1]}` : "") +
          (convertedAttendees.length >= 3 ? `, +${convertedAttendees.length - 2} more` : "")
        }
      </div>
    );
  };

  const renderConferencing = () => {
    if (!conferencingText) {
      return null;
    }

    return (
      <div className="smart-holds-label">
        <Video className="secondary-text-color mr-2" size={12} />
        {conferencingText}
      </div>
    );
  };

  const renderLocation = () => {
    if (!location) {
      return null;
    }

    return (
      <div className="smart-holds-label">
        <MapPin className="secondary-text-color mr-2" size={12} />
        {location}
      </div>
    );
  };

  const renderSlotTimes = () => {
    return slotTimes.map((slotTime, idx) => {
      const isHovered = hoveredTimeSlot === idx;
      return (
        <div
          className={classNames(isHovered ? "smart-holds-time-slot-hovered" : "", "smart-holds-time-slot")}
          key={`smart-holds-time-slot-${idx}`}
          onClick={() => openEventFormWithSlot(slotTime)}
          onMouseEnter={() => setHoveredTimeSlot(idx)}
          onMouseLeave={() => setHoveredTimeSlot(null)}
        >
          <div className={classNames(isHovered ? "smart-holds-time-slot-hovered" : "", "smart-holds-time-slot-time")}>
            {isHovered ? <Plus size={12} /> : `${format(slotTime.eventStart, "h:mmaaa")} - ${format(slotTime.eventEnd, "h:mmaaa")}`}
          </div>
        </div>
      );
    });
  };

  const renderTitle = () => {
    if (!isTypeString(title) || title?.length === 0) {
      return null;
    }

    return (
      <div className="smart-holds-label">
        {title}
      </div>
    );
  };

  return (
    <div className={classNames("smart-holds-container", isDarkMode ? "dark-mode" : "")}>
      <div className="event-expanded-description-label">Create meeting from Smart Holds</div>
      <div className="smart-holds-details">
        {renderTitle()}
        <div className="smart-holds-label">{duration} mins</div>
        {renderAttendees()}
        {renderConferencing()}
        {renderLocation()}
        {renderSlotTimes()}
        <div className="smart-holds-label font-size-10 mx-auto w-max" style={{ marginBottom: 0 }}>
          All related holds will be deleted after event is created
        </div>
      </div>
    </div>
  );
};

export default SmartHolds;
