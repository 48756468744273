import * as Sentry from "@sentry/browser";

import { ATTENDEE_ACCESS_STATES, GroupSchedulingAttendeeProp, GroupSchedulingAttendeeType } from "./groupSchedulingVariables";
import { FEATURE_TRACKING_CATEGORIES, trackEvent } from "../components/tracking";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getUserEmail, getUserToken } from "./userFunctions";
import { Stripe } from "stripe-types";
import { addDefaultToArray } from "./arrayFunctions";

export function getAttendeeAccessState(attendee: GroupSchedulingAttendeeType | undefined) {
  if (isEmptyObjectOrFalsey(attendee)) {
    return null;
  }

  return attendee?.access_state;
}

export function getAttendeeEmail(attendee: GroupSchedulingAttendeeType | GroupSchedulingAttendeeProp | undefined) {
  if (isEmptyObjectOrFalsey(attendee)) {
    return "";
  }

  return attendee?.email;
}

export function formatNewAttendeesToEmail({
  attendees,
  previousAttendees,
  didSelectedUserChange = false,
  previousTeamPlan,
  teamPlan,
}: {
  attendees: GroupSchedulingAttendeeProp[],
  previousAttendees: GroupSchedulingAttendeeProp[],
  didSelectedUserChange: boolean,
  previousTeamPlan: TeamPlan | Record<string, never> | undefined,
  teamPlan: TeamPlan | Record<string, never>,
}) {
  const previousAttendeesEmails = previousAttendees?.map(attendee => attendee.email);
  const previousTeamPlanEmails = getAllTeamPlanUserEmails({ teamPlan: previousTeamPlan });
  const teamPlanEmails = getAllTeamPlanUserEmails({ teamPlan });

  if (didSelectedUserChange) {
    return attendees.map(attendee => getAttendeeEmail(attendee));
  }

  return attendees
    .filter(attendee => {
      const attendeeEmail = getAttendeeEmail(attendee);

      /* User was just added */
      /* User got added to team plans */
      return !previousAttendeesEmails?.includes(attendeeEmail) || (
        !previousTeamPlanEmails?.includes(attendeeEmail) && teamPlanEmails?.includes(attendeeEmail)
      );
    })
    .map(attendee => getAttendeeEmail(attendee));
}

export function formatAttendeesToDummyState({ attendees, groupSchedulingAttendees }: {
  attendees: GroupSchedulingAttendeeProp[],
  groupSchedulingAttendees: GroupSchedulingAttendeeType[],
}) {
  try {
    return attendees.map(attendee => {
      const matchingAttendee = groupSchedulingAttendees.find(existingAttendee => existingAttendee.email === attendee.email);

      return {
        access_state: matchingAttendee ? matchingAttendee.access_state : ATTENDEE_ACCESS_STATES.noAccess,
        isMissingTeamPlan: matchingAttendee ? matchingAttendee.isMissingTeamPlan : false,
        isPendingTeamPlan: matchingAttendee ? matchingAttendee.isPendingTeamPlan : false,
        email: attendee.email,
        name: attendee.name,
        isOptional: attendee.isOptional,
      } as GroupSchedulingAttendeeType;
    });
  } catch(error) {
    Sentry.captureException(error);

    return [];
  }

}

export function getAllTeamPlanUserEmails({ teamPlan }: { teamPlan: TeamPlan | Record<string, never> | undefined }) {
  if (isEmptyObjectOrFalsey(teamPlan)) {
    return null;
  }

  const activeUsers = teamPlan.active_users ?? [];
  const pendingUsers = teamPlan.pending_users ?? [];

  return [...activeUsers, ...pendingUsers].map(user => getUserEmail(user));
}

export function getDefaultPaymentFromSubscription({ stripePaymentMethods, stripeSubscriptions }: {
  stripePaymentMethods: Stripe.PaymentMethod[],
  stripeSubscriptions: Stripe.Subscription | Record<string, never>
}) {
  if (
    !stripePaymentMethods ||
    !stripeSubscriptions ||
    stripePaymentMethods?.length === 0 ||
    isEmptyObjectOrFalsey(stripeSubscriptions)
  ) {
    return null;
  }

  const defaultPaymentMethod = stripeSubscriptions.default_payment_method ?? stripeSubscriptions.default_source;
  const matchingPaymentMethod = stripePaymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethod);

  if (!matchingPaymentMethod) {
    /* Get the first card as a backup */
    /* Backend should only return cards */
    /* TODO: Update backend to pass back default payment method instead of all payment methods */
    const backupPaymentMethod = stripePaymentMethods.find(
      (paymentMethod) => !!paymentMethod.card?.brand && !!paymentMethod.card?.last4,
    );

    return !isEmptyObjectOrFalsey(backupPaymentMethod) ? { brand: backupPaymentMethod.card?.brand, lastFour: backupPaymentMethod.card?.last4 } : null;
  }

  return { brand: matchingPaymentMethod.card?.brand, lastFour: matchingPaymentMethod.card?.last4 };
}

export function trackTeamSlotsTeamPlan({
  action,
  currentUser,
}) {
  trackEvent({
    category: FEATURE_TRACKING_CATEGORIES.TEAM_PLAN,
    action,
    event_name: action,
    slots_type: "team-slots",
    label: "team-slots",
    userToken: getUserToken(currentUser),
  });
}
