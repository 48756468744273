import React from "react";
import ContactHandle, { type ContactHandleProps } from "../contactHandle";
import LinkedInLogo from "./linkedInLogo";

export default function LinkedInHandle(props: ContactHandleProps) {
  return (
    <ContactHandle {...props}>
      <LinkedInLogo size={10} className="full-attendee-list-attendee-handle pl-px" />
    </ContactHandle>
  )
}
