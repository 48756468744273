import React from "react";
import { type IconProps } from "react-feather";

export default function AddImage({ className="", color="currentColor" }: IconProps) {
  return (
    <svg className={className} width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.12125 26.3334C2.46958 26.3334 1.92558 26.1152 1.48925 25.6789C1.05197 25.2416 0.833328 24.6971 0.833328 24.0455V8.78796C0.833328 8.13629 1.05197 7.59229 1.48925 7.15596C1.92558 6.71868 2.46958 6.50004 3.12125 6.50004H7.31175L9.93258 3.66671H17.2879V5.08337H10.5446L7.93508 7.91671H3.12125C2.86719 7.91671 2.65847 7.9984 2.49508 8.16179C2.33169 8.32518 2.24999 8.5339 2.24999 8.78796V24.0455C2.24999 24.2995 2.33169 24.5082 2.49508 24.6716C2.65847 24.835 2.86719 24.9167 3.12125 24.9167H24.0454C24.2995 24.9167 24.5082 24.835 24.6716 24.6716C24.835 24.5082 24.9167 24.2995 24.9167 24.0455V12.7121H26.3333V24.0455C26.3333 24.6971 26.1152 25.2411 25.6788 25.6775C25.2416 26.1147 24.6971 26.3334 24.0454 26.3334H3.12125ZM24.9167 7.91671V5.08337H22.0833V3.66671H24.9167V0.833374H26.3333V3.66671H29.1667V5.08337H26.3333V7.91671H24.9167ZM13.5833 21.7009C15.0633 21.7009 16.3147 21.1909 17.3375 20.1709C18.3575 19.148 18.8675 17.8967 18.8675 16.4167C18.8675 14.9368 18.3575 13.6854 17.3375 12.6625C16.3137 11.6425 15.0623 11.1325 13.5833 11.1325C12.1043 11.1325 10.8529 11.6425 9.82916 12.6625C8.80916 13.6854 8.29916 14.9368 8.29916 16.4167C8.29916 17.8967 8.80963 19.148 9.83058 20.1709C10.8525 21.1909 12.1034 21.7009 13.5833 21.7009ZM13.5833 20.2842C12.4831 20.2842 11.5632 19.9149 10.8237 19.1764C10.0842 18.4378 9.71441 17.5179 9.71441 16.4167C9.71441 15.3164 10.0842 14.3965 10.8237 13.657C11.5632 12.9175 12.4831 12.5478 13.5833 12.5478C14.6836 12.5478 15.6035 12.9175 16.343 13.657C17.0825 14.3965 17.4522 15.3164 17.4522 16.4167C17.4522 17.517 17.0825 18.4369 16.343 19.1764C15.6035 19.9159 14.6836 20.2856 13.5833 20.2856" fill={color} />
    </svg>
  );
}
