import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { noop } from "underscore";

import teamPlanBroadcasts from "../../broadcasts/teamPlanBroadcasts";
import {ACCEPT_TEAM_PLAN_INVITE, FETCH_TEAM_PLAN_INVITES} from "../../lib/groupSchedulingVariables";
import { constructRequestURL, isErrorResponse } from "../../services/api";
import { useSelector } from "react-redux";
import "../../styles/teamPlanInvitesStyles.css";
import { getSavedTeamInviteToken, removeSavedTeamInviteToken } from "../../lib/stateManagementFunctions";
import Broadcast from "../../broadcasts/broadcast";
import { trackError } from "../tracking";
import { ERROR_TYPE_TEAM_PLAN } from "../../lib/vimcalVariables";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { pluralize } from "../../lib/stringFunctions";
import { respondToInvite } from "../queries/teamPlans";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import CustomButtonV2 from "../buttons/customButtonV2";
import { TEAM_PLAN_ENDPOINTS } from "../../lib/endpoints";
import { fetcherGet } from "../../services/fetcherFunctions";
import { getUserConnectedAccountToken } from "../../services/maestro/maestroAccessors";
import { triggerRefreshWithOnlineCheck } from "../../services/appFunctions";
import { determineDefaultModalStyle, MODAL_OVERLAY_Z_INDEXES } from "../../lib/modalFunctions";

const INVITE_RESPONSES = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
} as const;
type InviteResponse = ValueOf<typeof INVITE_RESPONSES>;

type TeamPlanInvite = {
  created_at: string;
  inviter: string;
  token: string;
};

type TeamPlanInviteOptionProps = {
  invite: TeamPlanInvite;
  setTeamPlanInvites: (teamPlanInvites: TeamPlanInvite[]) => void;
  teamPlanInvites: TeamPlanInvite[];
}

const TeamPlanInviteOption = (
  {
    invite,
    setTeamPlanInvites,
    teamPlanInvites,
  }: TeamPlanInviteOptionProps,
) => {
  const [clickedButton, setClickedButton] = useState<InviteResponse | null>(null);
  const currentUser = useSelector((state) => state.currentUser);
  const componentIsMounted = useIsMounted();

  const handleClick = async (clickType: InviteResponse) => {
    if (clickedButton) {
      return;
    }
    setClickedButton(clickType);

    const response = await respondToInvite(currentUser, invite.token, clickType === INVITE_RESPONSES.ACCEPTED);

    if (!componentIsMounted.current) {
      return;
    }

    if (
      !response ||
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      return;
    }

    let updatedTeamPlanInvites: TeamPlanInvite[] = [];
    /* User accepted */
    if (!isEmptyObjectOrFalsey(response.team_plan) && clickType === INVITE_RESPONSES.ACCEPTED) {
      setTeamPlanInvites(updatedTeamPlanInvites);
      // The user may be coming from a locked out state (such as trial_ended) and will need a
      // refresh to ensure their master account is updated.
      triggerRefreshWithOnlineCheck();
      return;
    }

    /* Filter existing to remove current */
    updatedTeamPlanInvites = teamPlanInvites.filter((existingInvite) => existingInvite.token !== invite.token);
    setTeamPlanInvites(updatedTeamPlanInvites);
    setClickedButton(null);
  };

  return (
    <div
      className="team-plan-invites-stack"
    >
      <div className="team-plan-invites-inviter">{invite.inviter}</div>
      <div className="flex ml-auto gap-4">
        <CustomButtonV2
          buttonType="white-button"
          onClick={() => handleClick(INVITE_RESPONSES.DECLINED)}
          label="Decline"
          shouldRenderSpinner={clickedButton === INVITE_RESPONSES.DECLINED}
          disabled={!!clickedButton}
        />
        <CustomButtonV2
          buttonType="blue-button"
          onClick={() => handleClick(INVITE_RESPONSES.ACCEPTED)}
          label="Accept Invite"
          shouldRenderSpinner={clickedButton === INVITE_RESPONSES.ACCEPTED}
          disabled={!!clickedButton}
        />
      </div>
    </div>
  );
};

const TeamPlanInvitesContainer = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [teamPlanInvites, setTeamPlanInvites] = useState<TeamPlanInvite[]>([]);
  const componentIsMounted = useIsMounted();

  const acceptTeamPlanInvite = async (inviteToken: string) => {
    const response = await respondToInvite(currentUser, inviteToken);

    if (!componentIsMounted.current) {
      return;
    }

    if (!response || isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      return;
    }

    if (!response.team_plan) {
      // The team plan could be null if you try to accept an invite that you're not eligible to accept.
      // Example: A paying user cannot accept an invite to a trial team plan.
      removeSavedTeamInviteToken();
      return;
    }

    Broadcast.publish("DISPLAY_TEAM_JOIN_MODAL", response.team_plan);
  };

  const fetchTeamPlanInvites = async () => {
    const teamPlanInviteToken = getSavedTeamInviteToken();

    const response = await fetcherGet<{ team_plan_invites: TeamPlanInvite[] } | ErrorResponse>({
      url: constructRequestURL(TEAM_PLAN_ENDPOINTS.CHECK_INVITES, true),
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });
    if (!componentIsMounted.current) {
      return;
    }

    if (!response || isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      trackError({
        category: ERROR_TYPE_TEAM_PLAN,
        errorMessage: "teamPlanInvitesContainer::Empty response from backend",
        userToken: getUserToken(currentUser),
      });

      return;
    }

    if (teamPlanInviteToken) {
      teamPlanBroadcasts.publish(ACCEPT_TEAM_PLAN_INVITE, teamPlanInviteToken);
      return;
    }

    setTeamPlanInvites(response.team_plan_invites ?? []);
  };

  const modalStyle = determineDefaultModalStyle(
    isDarkMode,
    true,
    { overlay: { zIndex: MODAL_OVERLAY_Z_INDEXES.TEAM_PLAN_INVITE } },
  );

  useEffect(() => {
    teamPlanBroadcasts.subscribe(FETCH_TEAM_PLAN_INVITES, fetchTeamPlanInvites);
    teamPlanBroadcasts.subscribe(ACCEPT_TEAM_PLAN_INVITE, acceptTeamPlanInvite);

    return () => {
      teamPlanBroadcasts.unsubscribe(FETCH_TEAM_PLAN_INVITES);
      teamPlanBroadcasts.unsubscribe(ACCEPT_TEAM_PLAN_INVITE);
    };
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      id="team-plan-modal-id"
      isOpen={teamPlanInvites.length > 0}
      onRequestClose={noop}
      style={modalStyle}
    >
      <div
        className={classNames(
          "team-plan-invites-container",
          isDarkMode ? "dark-mode" : "",
        )}
      >
        <div className="mx-auto team-plan-invites-header">
          <div>Pending Invites</div>
        </div>
        <div>You have {teamPlanInvites.length} {pluralize(teamPlanInvites.length, "invite")}.  If you're already on a team, accepting a new invite will remove you from your current team.</div>
        <div className="tean-plans-invites-invite-container">
          {teamPlanInvites.map((invite) => (
            <TeamPlanInviteOption
              invite={invite}
              key={`team-plan-invites-${invite.token}`}
              setTeamPlanInvites={setTeamPlanInvites}
              teamPlanInvites={teamPlanInvites}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TeamPlanInvitesContainer;
