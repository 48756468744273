import React from "react";
import { CONFETTI_DURATION } from "./sharedVariables";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";

export default function OnboardingConfetti() {
  return (
    <div className="fixed top-1/3 left-1/2">
      <ConfettiExplosion
        force={0.8}
        duration={CONFETTI_DURATION}
        particleCount={200}
        floorWidth={window.innerWidth - 100}
        floorHeight={window.innerHeight - 100}
      />
    </div>
  );
}
