import React, { Component } from "react";
import { connect } from "react-redux";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import { HOVER_HINT_BACKGROUND_COLOR, LIGHT_MODE_HINT_TEXT_COLOR, LIGHT_MODE_TILE_BACKGROUND_COLOR } from "../services/globalVariables";
import classNames from "classnames";
import { getDefaultModalBorder } from "../lib/styleFunctions";
import { isLocal } from "../services/devFunctions";

class ShortcutHoverHint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.hoverTriggerCount && prevProps.hoverTriggerCount !== this.props.hoverTriggerCount) {
      // if the hover trigger count changed, we need to update the hover state to false
      this.setHover(false);
    }
  }

  render() {
    return (
      <span
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={Object.assign(
          { position: "relative" },
          this.props.containerStyle
        )}
        className={this.props.inputClassName || ""}
      >
        {!this.props.below && this.renderHint()}

        {this.props.children}

        {this.props.below && this.renderHint()}
      </span>
    );
  }

  renderHint() {
    if (this.props.isMobileView) {
      return null;
    }

    if (this.props.shouldHideHint) {
      return null;
    }

    return (
      (this.state.isHovering || (this.props.alwaysShow && isLocal())) && (
        <div style={Object.assign({}, this.hintStyle(), this.props.style)}>
          <span
            className={classNames("shortcut-hover-hint-text", this.props.shortcut?.length > 0 ? "mr-1" : "", this.props.labelOverrideClassName ?? "")}
          >
            {this.props.title}
          </span>

          {this.renderShortcuts()}
        </div>
      )
    );
  }

  renderShortcuts() {
    const {
      isDarkMode
    } = this.props;
    const borderStyle = `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.7)" : LIGHT_MODE_HINT_TEXT_COLOR}`;
    if (!this.props.shortcut) {
      return null;
    } else if (this.props.shortcut.length === 1) {
      return (
        <ShortcutTile
          backgroundColor={"transparent"}
          border={borderStyle}
          large={this.props.largeTileFont}
          shortcut={this.props.shortcut}
          color={this.props.overrideShortcutTextColor}
        />
      );
    } else {
      return this.props.shortcut.split(" ").map((s, index) => {
        return (
          <div key={`short_cut_hover_${index}`}>
            <ShortcutTile
              backgroundColor={"transparent"}
              border={borderStyle}
              large={this.props.largeTileFont}
              shortcut={s}
              color={this.props.overrideShortcutTextColor}
            />
          </div>
        );
      });
    }
  }

  setHover(isHovering) {
    this.setState({
      isHovering,
    });
  }

  hintStyle() {
    const {
      isDarkMode,
      showBorder,
    } = this.props;
    return {
      backgroundColor: isDarkMode ? HOVER_HINT_BACKGROUND_COLOR : LIGHT_MODE_TILE_BACKGROUND_COLOR,
      borderRadius: 4,
      color: isDarkMode ? "rgba(255, 255, 255, 0.9)" : LIGHT_MODE_HINT_TEXT_COLOR,
      fontSize: 12,
      padding: "7px 9px",
      position: "absolute",
      marginTop: this.determineHintMarginTop(),
      marginBottom: this.props.below ? "5px" : 0,
      display: "flex",
      alignItems: "center",
      cursor: "default",
      opacity: this.state.isHovering || this.props.alwaysShow ? 1 : 0,
      transition: "opacity 0.2s ease 0.1s",
      fontWeight: 300,
      zIndex: 1,
      userSelect: "none",
      ...(showBorder && { border: getDefaultModalBorder(isDarkMode) }),
    };
  }

  determineHintMarginTop() {
    if (this.props.hintMarginTop) {
      return this.props.hintMarginTop;
    } else if (this.props.above) {
      return 0;
    } else {
      return "5px";
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  onMouseEnter() {
    this.setHover(true);
  }

  onMouseLeave() {
    this.setHover(false);
  }
}

function mapStateToProps(state) {
  const { shouldShowGlobalKeyMap, isMobileView, isDarkMode } = state;

  return { shouldShowGlobalKeyMap, isMobileView, isDarkMode };
}

export default connect(mapStateToProps)(ShortcutHoverHint);
