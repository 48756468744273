import React, { useState } from "react";
import { X } from "react-feather";
import {
  ATTENDEE_ACCESS_STATES,
  GroupSchedulingAttendeeType,
} from "../../lib/groupSchedulingVariables";
import classNames from "classnames";
import usePrevious from "../specialComponents/usePrevious";
import { getAttendeeEmail } from "../../lib/groupSchedulingFunctions";
import CheckBoxSpinner from "../spinners/checkboxSpinner";
import {
  hasEventPreventDefault,
  hasStopEventPropagation,
} from "../../services/commonUsefulFunctions";
import AddPeople from "../icons/addPeople";
import { useSelector } from "react-redux";
import {
  determineCalendarColor,
  getCalendarFromEmail,
} from "../../lib/calendarFunctions";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import MarkAsOptional from "../markAsOptional";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { LIGHT_MODE_TILE_TEXT_COLOR } from "../../services/globalVariables";
import { SLOTS_ATTENDEE_LOADING_ID } from "../../services/elementIDVariables";
import { useUserTimeZoneIndexStore } from "../../services/stores/userData";
import UserLocalTimeZone from "../userLocalTimeZone";

type GroupSchedulingAttendeeProps = {
  attendee: GroupSchedulingAttendeeType;
  isDarkMode: boolean;
  emailsBeingFetched: string[];
  removeAttendee: (email: string) => void;
  showModal: () => void;
  toggleAttendeeAccessState: (attendee: GroupSchedulingAttendeeType) => void;
  hideSwitch?: boolean;
  disableAttendeesSwitchAndSetToFalse?: boolean;
};

/* Calendar Icon inside of the toggle */
const CalendarIcon = ({ isBlocked, isDarkMode }) => {
  let color;
  if (isBlocked) {
    color = "#A4BDFF";
  } else {
    color = isDarkMode ? "#B1B1B1" : "rgba(255, 255, 255, 0.5)";
  }

  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4.5V8C9 8.26522 8.89464 8.51957 8.70711 8.70711C8.51957 8.89464 8.26522 9 8 9H1C0.734784 9 0.48043 8.89464 0.292893 8.70711C0.105357 8.51957 0 8.26522 0 8V4.5H9ZM6.5 0C6.63261 0 6.75979 0.0526784 6.85355 0.146447C6.94732 0.240215 7 0.367392 7 0.5V1H8C8.26522 1 8.51957 1.10536 8.70711 1.29289C8.89464 1.48043 9 1.73478 9 2V3.5H0V2C0 1.73478 0.105357 1.48043 0.292893 1.29289C0.48043 1.10536 0.734784 1 1 1H2V0.5C2 0.367392 2.05268 0.240215 2.14645 0.146447C2.24021 0.0526784 2.36739 0 2.5 0C2.63261 0 2.75979 0.0526784 2.85355 0.146447C2.94732 0.240215 3 0.367392 3 0.5V1H6V0.5C6 0.367392 6.05268 0.240215 6.14645 0.146447C6.24021 0.0526784 6.36739 0 6.5 0Z"
        fill={color}
      />
    </svg>
  );
};

/* Toggle Switch for blocking calendar */
const GroupSchedulingSwitch = (props) => {
  const { attendee, isDarkMode, disableAttendeesSwitchAndSetToFalse } = props;

  const isBlocked = attendee.access_state === ATTENDEE_ACCESS_STATES.blocked;
  const isNoAccess = attendee.access_state === ATTENDEE_ACCESS_STATES.noAccess;

  return (
    <div
      className={classNames(
        "group-scheduling-toggle-thumb",
        disableAttendeesSwitchAndSetToFalse
          ? "group-scheduling-toggle-thumb-disabled"
          : "",
        !disableAttendeesSwitchAndSetToFalse && isBlocked
          ? "toggled-on"
          : "toggled-off",
        isNoAccess ? "disabled" : "",
        disableAttendeesSwitchAndSetToFalse ? "opacity-60" : ""
      )}
    >
      {disableAttendeesSwitchAndSetToFalse ? null : (
        <div
          className={classNames(
            attendee.access_state === ATTENDEE_ACCESS_STATES.blocked
              ? "group-scheduling-calendar-icon"
              : "group-scheduling-calendar-icon group-scheduling-hidden"
          )}
        >
          <CalendarIcon isBlocked={isBlocked} isDarkMode={isDarkMode} />
        </div>
      )}

      <div
        className={classNames(
          attendee.access_state === ATTENDEE_ACCESS_STATES.blocked
            ? "group-scheduling-calendar-icon group-scheduling-hidden"
            : "group-scheduling-calendar-icon"
        )}
      >
        <CalendarIcon isBlocked={isBlocked} isDarkMode={isDarkMode} />
      </div>
    </div>
  );
};

/* Actual component -> Rendered for attendee */
const GroupSchedulingAttendee = (props: GroupSchedulingAttendeeProps) => {
  const {
    attendee,
    emailsBeingFetched,
    isDarkMode,
    removeAttendee,
    showModal,
    toggleAttendeeAccessState,
    hideSwitch,
    disableAttendeesSwitchAndSetToFalse,
  } = props;
  const [isNameHovered, setIsNameHovered] = useState(false);
  const [isNoAcccessHovered, setIsNoAccessHovered] = useState(false);
  const [isHoveringToogle, setIsHoveringToggle] = useState(false);
  const [isHoveringAddAttendeeButton, setIsHoveringAddAttendeeButton] =
    useState(false);
  const temporarySecondaryCalendarColorsIndex = useSelector(
    (state) => state.temporarySecondaryCalendarColorsIndex
  ) ?? {};
  const userTimeZoneIndex = useUserTimeZoneIndexStore(
    (state) => state.userTimeZoneIndex
  );
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const previousHover = usePrevious(isNoAcccessHovered);
  const isBlocked = attendee.access_state === ATTENDEE_ACCESS_STATES.blocked;
  const isNoAccess = attendee.access_state === ATTENDEE_ACCESS_STATES.noAccess;
  let attendeeContainerMaxWidth = "210px";
  let emailMaxWidth = "190px";

  if (attendee.isMissingTeamPlan) {
    attendeeContainerMaxWidth = "170px";
    emailMaxWidth = "150px";
  }

  if (attendee.isPendingTeamPlan) {
    attendeeContainerMaxWidth = "110px";
    emailMaxWidth = "90px";
  }

  const getAttendeeColor = (email: string) => {
    if (temporarySecondaryCalendarColorsIndex[email]) {
      return temporarySecondaryCalendarColorsIndex[email];
    }

    const matchingCalendarFromUser = getCalendarFromEmail({
      email,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
    if (matchingCalendarFromUser) {
      return determineCalendarColor(matchingCalendarFromUser);
    }

    return null;
  };
  const email = getAttendeeEmail(attendee);
  const emailColor = getAttendeeColor(email);
  const userTimeZone = userTimeZoneIndex[email];

  return (
    <div
      className={classNames(
        "flex items-center group-scheduling-attendee relative",
        disableAttendeesSwitchAndSetToFalse ? "cursor-default-important" : "",
        userTimeZone ? "height-44px-override" : ""
      )}
      onClick={(e) => {
        hasEventPreventDefault(e);
        if (disableAttendeesSwitchAndSetToFalse) {
          return;
        }
        toggleAttendeeAccessState(attendee);
      }}
      onMouseEnter={() => {
        if (isNameHovered) {
          return;
        }
        setIsNoAccessHovered(true);
      }}
      onMouseLeave={() => setIsNoAccessHovered(false)}
    >
      <div
        className="select-none group-scheduling-edge-icon"
        style={{ maxWidth: attendeeContainerMaxWidth }}
      >
        <div className="flex items-center">
          <div
            className="w-2.5 h-2.5 rounded-full default-border mr-1.5"
            style={{
              backgroundColor: emailColor || "transparent",
              borderColor:
                emailColor ||
                (isDarkMode ? LIGHT_MODE_TILE_TEXT_COLOR : "#cbcbd2"),
              marginTop: userTimeZone ? -16 : undefined,
            }}
          ></div>
          <div
            className="flex flex-col items-start"
            style={{ maxWidth: emailMaxWidth }}
          >
            <div
              className="truncate"
              onMouseEnter={() => {
                if (isNoAcccessHovered && !!attendee.name) {
                  setIsNoAccessHovered(false);
                }

                setIsNameHovered(true);
              }}
              onMouseLeave={() => {
                setIsNameHovered(false);
                setIsNoAccessHovered(!!previousHover);
              }}
            >
              {attendee.name || email}
            </div>
            <UserLocalTimeZone email={email} />
          </div>
        </div>
      </div>
      {hideSwitch ? null : (
        <MarkAsOptional
          onClick={(attendee) => {
            const updatedAttendee = {
              ...attendee,
              isOptional: !attendee.isOptional,
            };
            availabilityBroadcast.publish(
              "UPDATE_SINGLE_ATTENDEE",
              updatedAttendee
            );
          }}
          attendee={attendee}
          className={userTimeZone ? "-margin-top-18px" : ""}
        />
      )}

      <div className="group-scheduling-right-section relative">
        {emailsBeingFetched?.includes(getAttendeeEmail(attendee)) ? (
          <CheckBoxSpinner
            className="right-2"
            color="#FFFFFF"
            hideRightSidePadding={false}
            size={16}
            id={SLOTS_ATTENDEE_LOADING_ID}
          />
        ) : (
          <>
            {attendee.isMissingTeamPlan ? (
              <div
                className="group-scheduling-add-to-team"
                onClick={showModal}
                onMouseEnter={() => setIsHoveringAddAttendeeButton(true)}
                onMouseLeave={() => setIsHoveringAddAttendeeButton(false)}
              >
                <AddPeople isDarkMode={isDarkMode} />
                <div
                  className={classNames(
                    "group-scheduling-attendee-tooltip",
                    "group-scheduling-attendee-name w-max-content-important add-attendee-to-team-tooltip",
                    isHoveringAddAttendeeButton
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  )}
                >
                  Add to team
                </div>
              </div>
            ) : null}
            {attendee.isPendingTeamPlan ? (
              <div
                className="group-scheduling-add-to-team pointer-events-none"
                onClick={showModal}
              >
                Invite Pending
              </div>
            ) : null}

            <div
              onMouseEnter={() => setIsHoveringToggle(true)}
              onMouseLeave={() => setIsHoveringToggle(false)}
            >
              {hideSwitch ? null : (
                <GroupSchedulingSwitch
                  attendee={attendee}
                  isDarkMode={isDarkMode}
                  disableAttendeesSwitchAndSetToFalse={
                    disableAttendeesSwitchAndSetToFalse
                  }
                />
              )}
              <div
                className={classNames(
                  "group-scheduling-attendee-tooltip group-scheduling-attendee-name",
                  isHoveringToogle && attendee.isMissingTeamPlan
                    ? "opacity-100 visible"
                    : "opacity-0 invisible"
                )}
              >
                Add this attendee to your Vimcal Team to also check their
                calendar for conflicts
              </div>
              {disableAttendeesSwitchAndSetToFalse ? null : (
                <div
                  className={classNames(
                    "group-scheduling-attendee-tooltip group-scheduling-attendee-name",
                    isHoveringToogle && !attendee.isMissingTeamPlan && isBlocked
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  )}
                >
                  By turning this on, the booking link will also check this
                  calendar for conflicts
                </div>
              )}

              {disableAttendeesSwitchAndSetToFalse ? null : (
                <div
                  className={classNames(
                    "group-scheduling-attendee-tooltip group-scheduling-attendee-name",
                    isHoveringToogle &&
                      !attendee.isMissingTeamPlan &&
                      !isBlocked
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  )}
                >
                  By turning this off, the booking link will skip this calendar
                  when checking for conflicts
                </div>
              )}
            </div>

            <X
              size={14}
              className="clickable-icon margin-left-5 group-scheduling-edge-icon"
              onClick={(e) => {
                hasStopEventPropagation(e);
                removeAttendee(email);
              }}
            />
          </>
        )}
      </div>
      <div
        className={classNames(
          "group-scheduling-attendee-name",
          isNoAcccessHovered &&
            isNoAccess &&
            !attendee.isMissingTeamPlan &&
            !isHoveringToogle
            ? "opacity-100 visible"
            : "opacity-0 invisible"
        )}
      >
        No access to calendar.
      </div>
    </div>
  );
};

export default GroupSchedulingAttendee;
