import React from "react";
import { BLUE_BUTTON } from "../../services/globalVariables";
import CustomButtonV2 from "../buttons/customButtonV2";
import CalendarAuditReadySVG from "../icons/calendarAudit/readyImage";

export function CalendarAuditIntroModal({ setFlashProposed }) {
  return (
    <div className="flex flex-col justify-center">
      <CalendarAuditReadySVG />
      <div className="font-medium mr-2 my-4">Calculate time savings by changing meeting duration and frequency</div>
      <div className="font-size-12 secondary-text-color">
        Export your results when you{"'"}re done and share them with your execs.
      </div>
      <div className="ml-auto">
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          className="mt-8"
          label="Ok"
          onClick={() => {
            setFlashProposed(true);
          }}
          removeDefaultFontStyles={true}
        />
      </div>
    </div>
  );
}
