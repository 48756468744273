import React from "react";
import { ArrowLeft } from "react-feather";

import ShortcutHoverHint from "../../components/shortcutHoverHint";
import { isMac } from "../../services/commonUsefulFunctions";

const LoginBackButton = ({ onClick, title, shortcut, shouldHideHint }) => {
  const roundedButtonStyle = {
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    backgroundColor: "transparent",
    display: "flex",
    marginTop: "-3px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={{ position: "absolute", top: "50px", left: "40px", zIndex: 3 }}>
      <ShortcutHoverHint
        below
        style={{
          backgroundColor: "#050012",
          color: "white",
          top: isMac() ? "0px" : "-30px",
          left: "60px",
          width: "max-content",
          zIndex: 4,
        }}
        title={title ?? "Return home"}
        shortcut={shortcut ?? "Esc"}
        overrideShortcutTextColor={"white"}
        labelOverrideClassName={"text-white-override"}
        shouldHideHint={shouldHideHint}
      >
        <button
          className="weekly-toolbar-button vimcal-updated-login-back-button"
          onClick={onClick}
          style={roundedButtonStyle}
        >
          <ArrowLeft className="non-clickable-icon" size="30" color="white" />
        </button>
      </ShortcutHoverHint>
    </div>
  );
};

export default LoginBackButton;
