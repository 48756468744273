import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUserDefaultColor } from "../lib/calendarFunctions";
import {
  createColorsWithNickName,
  createColorValueAndLabel,
  getMatchingColorFromID,
} from "../services/googleColors";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import CircleWithColor from "./circleWithColor";
import CustomSelect from "./select";
import DropdownIndicator from "./select/dropDownIndicator";
import { customMenuStyle, getReactSelectBaseStyle } from "./select/styles";
import { getUserEmail } from "../lib/userFunctions";
import { blurCalendar } from "../services/appFunctions";

export default function SelectColor({ 
  onChange, 
  selectedColorID, 
  showBorder = false, 
  locationRight, 
  defaultColorOverride,
  selectedUser,
  isDisabled = false,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);

  const currentUserColor = defaultColorOverride ?? getCurrentUserDefaultColor({
    currentUserEmail: getUserEmail(currentUser),
    allCalendars,
    allLoggedInUsers,
    masterAccount,
  });

  const colorList = createColorsWithNickName({
    defaultColor: currentUserColor, 
    user: selectedUser ?? currentUser,
    masterAccount,
    showNoColor: false
  });

  return (
    <CustomSelect
      components={{ DropdownIndicator }}
      openMenuOnFocus={true}
      overrideStyles={getReactSelectBaseStyle({
        isDarkMode, 
        showBorder: showBorder,
        menuListStyle: customMenuStyle({width: 180, right: locationRight})
      })}
      tabSelectsValue={false}
      isSearchable={true}
      inputId={"hold_color"}
      className={"select-calendar-color-dropdown"}
      classNamePrefix="dark-mode"
      value={{
        label: (
          <CircleWithColor
            color={
              getMatchingColorFromID({colorList, colorID: selectedColorID}) || currentUserColor
            }
          />
        ),
        value: "",
      }}
      onChange={onChange}
      options={createColorValueAndLabel(colorList)}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          // esc key
          blurCalendar();
        }
      }}
      filterOption={(candidate, input) => {
        return (
          candidate?.data?.name?.toLowerCase().includes(input?.toLowerCase()) ||
          candidate?.data?.nickName
            ?.toLowerCase()
            .includes(input?.toLowerCase())
        );
      }}
      alwaysShowIndicator={true}
      isDisabled={isDisabled}
    />
  );
}
