import { DEFAULT_POMODORO_BACKGROUND } from "../focusMode/sharedVariables.ts";
import { PARTNER_WITH_US_IMG } from "../lib/imageFunctions.js";
import { VIMCAL_FTF_DESIGN } from "../lib/vimcalVariables";
import { usePreloadAudio } from "../services/customHooks/usePreloadAudio";
import { usePreloadImages } from "../services/customHooks/usePreloadImages";
import {
  zoomImageURL,
  aroundCoImageURL,
  hangoutIconURL,
  microsoftTeamsIconURL,
  whereByIconURL,
  GO_TO_MEETING_ICON_URL,
  WEB_EX_ICON_URL,
  UBER_CONFERENCING_ICON_URL,
  BLUE_JEANS_ICON_URL,
  RING_CENTRAL_ICON_URL,
  PHONE_MEETING_ICON,
  CHIME_MEETING_ICON,
  WHATS_APP_ICON,
  VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND,
  ZOOM_CIRCULAR_ICON,
  VIMCAL_LOGO_SVG,
  GOOGLE_ICON,
  VIMCAL_IOS_QR_CODE,
  SKYPE_ICON_URL,
  SKYPE_FOR_BUSINESS_ICON,
  SLOTS_PERSONALIZE_SCREENSHOT,
  PERSONAL_LINK_NICKNAME_MORE_INFO_SCREENSHOT,
  CLICK_ALL_PERMISSIONS_SCREENSHOT,
} from "../services/globalVariables";
import { ZOOM_VARIABLES } from "../services/zoomFunctions.js";

const RESOURCES = [
  zoomImageURL,
  aroundCoImageURL,
  hangoutIconURL,
  microsoftTeamsIconURL,
  whereByIconURL,
  GO_TO_MEETING_ICON_URL,
  WEB_EX_ICON_URL,
  UBER_CONFERENCING_ICON_URL,
  BLUE_JEANS_ICON_URL,
  SKYPE_ICON_URL,
  SKYPE_FOR_BUSINESS_ICON,
  RING_CENTRAL_ICON_URL,
  PHONE_MEETING_ICON,
  CHIME_MEETING_ICON,
  WHATS_APP_ICON,
  VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND,
  ZOOM_CIRCULAR_ICON,
  VIMCAL_LOGO_SVG,
  GOOGLE_ICON,
  VIMCAL_IOS_QR_CODE,
  DEFAULT_POMODORO_BACKGROUND, // default focus mode background
  VIMCAL_FTF_DESIGN.DARK_MODE,
  VIMCAL_FTF_DESIGN.LIGHT_MODE,
  SLOTS_PERSONALIZE_SCREENSHOT,
  PARTNER_WITH_US_IMG,
  PERSONAL_LINK_NICKNAME_MORE_INFO_SCREENSHOT,
  CLICK_ALL_PERMISSIONS_SCREENSHOT,
].concat(Object.values(ZOOM_VARIABLES));

export default function PreloadResources({resources, audioLinks}) {
  usePreloadImages(resources ?? RESOURCES);
  usePreloadAudio(audioLinks ?? []);

  return null;
}
