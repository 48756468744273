import React from "react";
import CustomButton from "../customButton";
import { RED_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import classNames from "classnames";
import { hasStopEventPropagation } from "../../services/commonUsefulFunctions";
import { createPortal } from "react-dom";

interface ArchiveSlotModalProps {
  isWarningModalOpen: boolean
  onClickConfirm: () => void
  onClickExit: () => void
}

export default function ArchiveSlotModal({ isWarningModalOpen, onClickExit, onClickConfirm }: ArchiveSlotModalProps) {
  if (!isWarningModalOpen) {
    return null;
  }

  // TODO: @Kenny to add type to this.
  const onExit = (e) => {
    hasStopEventPropagation(e);
    onClickExit();
  }

  return createPortal(
    <div
      className={classNames(
        "absolute w-full h-full top-0 left-0",
        "default-modal-overlay-background-color",
        "flex items-center justify-center z-index-9999"
      )}
      onClick={onExit}
    >
      <div
        className="default-font-size default-font-color default-modal-content-background-color p-6 rounded-md"
        onClick={hasStopEventPropagation}
      >
        <div className="font-size-16 font-weight-400">Archive this Outstanding Slot?</div>
        <div className="mt-4">If the booking link has been shared, recipients will still be able to schedule an event.</div>
        <div className="event-form-discard-changes-wrapper">
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={onClickExit}
            label="Cancel"
            addPaddingToRight={true}
          />

          <CustomButton
            buttonType={RED_BUTTON}
            onClick={onClickConfirm}
            label="Archive"
            shouldFocus
          />
        </div>
      </div>
    </div>,
    document.body,
  );
}
