import React from "react";
import {
  getHumanReadableTimeInMinutes,
  getMetricsSwissCheese,
  getMetricsSwissCheeseChange,
  getMetricsSwissCheeseIsUp,
} from "./metricsAccessorFunctions";
import classNames from "classnames";
import MoreInformationHint from "../moreInformationHint";
import { useSelector } from "react-redux";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import SwissCheeseIcon from "./resources/swissCheeseIcon";
import UpDownAbsoluteChange from "./upDownAbsoluteChange";

export default function SwissCheese({
  data,
  previousData,
  showSkeletonScreen,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  if (showSkeletonScreen) {
    return (
      <div className="metrics-data-container metrics-two-container small-metrics-data-container">
        <MetricsSkeletonLoadingPanel width={170} height={20} />
        <MetricsSkeletonLoadingPanel
          width={"100%"}
          height={98}
          marginTop={16}
        />
      </div>
    );
  }

  const renderRadial_legacy = () => {
    if (!isDarkMode) {
      return null;
    }
    return (
      <div
        className={classNames(
          "metrics-blue-radial metrics-gradient-glow",
          "overflow-hidden max-w-full max-h-full"
        )}
        style={{
          width: "calc(100% + 200px)",
          height: "500px",
          top: 0,
          left: -10,
          borderRadius: 0,
        }}
      ></div>
    );
  };

  return (
    <div className="metrics-data-container metrics-two-container small-metrics-data-container">
      <div
        style={{ height: "127px" }}
        className="flex flex-col justify-between"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="metrics-primary-text-color font-weight-400 metrics-default-section-header">
              Swiss Cheese Time
            </div>
            <MoreInformationHint
              hideTitle={true}
              description="This shows lost productivity due to gaps of 30 min or less between meetings. Keep this under 2 hours per week as a rule of thumb."
              iconContainerClassName="h-full items-center flex"
              containerClassName="ml-2"
              hintContainerWidth="width-250-important"
              hintContainerClassNameLocation="-left-20 top-6"
            />
          </div>

          <UpDownAbsoluteChange
            delta={getMetricsSwissCheeseChange({ data, previousData })}
            isUp={getMetricsSwissCheeseIsUp({ data, previousData })}
          />
        </div>
        <div className="flex items-center">
          <SwissCheeseIcon height={65} width={65} />
          <div className="ml-6 metrics-default-font-size flex flex-col gap-1">
            <div className="flex gap-1">
              <div className="metrics-secondary-text-color">You lost</div>
              <div className="metrics-primary-text-color font-weight-400">
                {getHumanReadableTimeInMinutes(getMetricsSwissCheese({ data }))}
              </div>
            </div>
            <div className="metrics-secondary-text-color">in productivity due to</div>
            <div className="metrics-secondary-text-color">
              gaps between meetings.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
