import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  getHours,
  isAfter,
  isBefore,
  isSameDay,
  setHours,
  startOfDay,
  startOfHour,
  subDays,
} from "date-fns";
import {
  getEventEndJSDate,
  getEventStartJSDate,
} from "../../services/eventResourceAccessors";
import { getHumanAttendees, getSelfAttendingStatus, isAllDayEvent, isFreeEvent } from "../../lib/eventFunctions";
import {
  canDisplayAttendees,
  isBeforeMinute,
} from "../../services/commonUsefulFunctions";
import { ATTENDEE_EVENT_TENTATIVE } from "../../services/googleCalendarService";
import { mergeRanges, subtractRanges } from "../../lib/rangeFunctions";
import { isNotEmptySet } from "../../services/typeGuards";
import { getDomainFromEmail, isGenericDomain } from "../../lib/stringFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";

export type SchedulingAssistantItem = {
  id: string
  groupID: string
  title: string
  event: BigCalendarEvent
}

export type AttendeeGroup = {
  id: string
  title: string
}

export type WorkHours = {
  startWorkHour: number
  endWorkHour: number
}

const HOUR_IN_MINUTES = 60;
export const HOUR_CELL_WIDTH = 64;

export const SCROLL_INTO_VIEW_OPTIONS: Readonly<ScrollIntoViewOptions> = {
  behavior: "auto", // Adds smooth scrolling
  block: "nearest", // Vertical alignment (leave as is or adjust based on requirements)
  inline: "start", // Aligns the element to the start of the container horizontally
};

// groups come in as an array of objects with id and title
// {id: 'mike@vimcal.com', title: 'Michael - work'})
const HEADER_HEIGHT = 40 + 32 + 32;

// each row is 32px;
export const ROW_HEIGHT = 32;

export const calculateEventPosition = ({
  item,
  groups,
  date,
  isShowingWorkHoursOnly,
  workHours,
}: {
  item: SchedulingAssistantItem
  groups: AttendeeGroup[]
  date: Date
  isShowingWorkHoursOnly: boolean
  workHours: WorkHours
}) => {
  const { event } = item;
  const rowNumber = groups.findIndex((group) => group.id === item.groupID);

  const top = Math.round(HEADER_HEIGHT + rowNumber * ROW_HEIGHT);
  const eventStart = getEventStartJSDate(event);
  const eventEnd = getEventEndJSDate(event);
  const isAllDay = isAllDayEvent(event);
  const width = getWidth({ date, end: eventEnd, isAllDay, isShowingWorkHoursOnly, start: eventStart, workHours });
  const left = getLeft({ date, isShowingWorkHoursOnly, start: eventStart, workHours });

  return { top, left, width };
};

function clampToWorkingHours({ date, isShowingWorkHoursOnly, time, workHours }: {
  /** The date that is being rendered. */
  date: Date
  isShowingWorkHoursOnly: boolean
  /** The time that should be clamped into the date's working hours. */
  time: Date
  workHours: WorkHours
}) {
  let timeToUse = time;
  if (isBefore(time, startOfDay(date))) {
    timeToUse = startOfDay(date);
  } else if (isAfter(time, endOfDay(date))) {
    timeToUse = endOfDay(date);
  }

  if (isShowingWorkHoursOnly) {
    const hours = getHours(timeToUse);
    if (hours > workHours.endWorkHour) {
      return startOfHour(setHours(timeToUse, workHours.endWorkHour));
    } else if (hours === workHours.endWorkHour) {
      return startOfHour(timeToUse);
    } else if (hours < workHours.startWorkHour) {
      return startOfHour(setHours(timeToUse, workHours.startWorkHour));
    }
  }
  return timeToUse;
}

export function getLeft({ date, isShowingWorkHoursOnly, start, workHours }: {
  date: Date
  isShowingWorkHoursOnly: boolean
  start: Date
  workHours: WorkHours
}) {
  const clampedStart = clampToWorkingHours({ date, isShowingWorkHoursOnly, time: start, workHours });

  if (isShowingWorkHoursOnly) {
    return Math.round(
      (differenceInMinutes(clampedStart, startOfHour(setHours(clampedStart, workHours.startWorkHour))) / HOUR_IN_MINUTES) *
      HOUR_CELL_WIDTH,
    );
  }

  return Math.round(
    (differenceInMinutes(clampedStart, startOfDay(clampedStart)) / HOUR_IN_MINUTES) *
    HOUR_CELL_WIDTH,
  );
}

export function getWidth({ date, end, isAllDay, isShowingWorkHoursOnly, start, workHours }: {
  date: Date
  end: Date
  isAllDay?: boolean
  isShowingWorkHoursOnly: boolean
  start: Date
  workHours: WorkHours
}) {
  const clampedStart = clampToWorkingHours({ date, isShowingWorkHoursOnly, time: start, workHours });
  const clampedEnd = clampToWorkingHours({ date, isShowingWorkHoursOnly, time: end, workHours });
  if (isAllDay) {
    if (isShowingWorkHoursOnly) {
      return ((workHours.endWorkHour - workHours.startWorkHour)) * HOUR_CELL_WIDTH;
    }
    return 24 * HOUR_CELL_WIDTH;
  }
  if (isBeforeMinute(clampedEnd, clampedStart)) {
    // if it's somehow negative, just return 0
    return 0;
  }

  return Math.round(
    (differenceInMinutes(clampedEnd, clampedStart) / HOUR_IN_MINUTES) *
    HOUR_CELL_WIDTH,
  );
}

export const WINDOW_SYNC_DAYS_OUT = 10;
export function getSchedulingAssistantWindow(date: Date) {
  return {
    windowStart: startOfDay(subDays(date, 4)),
    windowEnd: endOfDay(addDays(date, WINDOW_SYNC_DAYS_OUT)),
  };
}

export function isWithinWorkHours(jsDate: Date, workHours: WorkHours) {
  const hour = getHours(jsDate);
  return (
    hour >= workHours.startWorkHour &&
    hour < workHours.endWorkHour
  );
}

export function isEventWithinWorkHours(event: BigCalendarEvent, workHours: WorkHours) {
  const eventStart = getEventStartJSDate(event);
  const eventEnd = getEventEndJSDate(event);
  const eventStartHours = getHours(eventStart);
  const eventEndHours = getHours(eventEnd);

  if (isAllDayEvent(event)) {
    return true;
  }

  const isStartWithinWindow = (
    eventStartHours >= workHours.startWorkHour &&
    eventStartHours < workHours.endWorkHour
  );
  if (isStartWithinWindow) {
    return true;
  }

  const isEndWithinWindow = (
    eventEndHours > workHours.startWorkHour &&
    eventEndHours <= workHours.endWorkHour
  );
  if (isEndWithinWindow) {
    return true;
  }

  const doesEventSpanWindow = (
    eventStartHours < workHours.startWorkHour &&
    eventEndHours > workHours.endWorkHour
  );
  if (doesEventSpanWindow) {
    return true;
  }

  const isEventMoreThan24Hours = differenceInDays(eventEnd, eventStart) >= 1;
  if (isEventMoreThan24Hours) {
    return true;
  }

  const doesEventSpanMultipleDays = !isSameDay(eventStart, eventEnd);
  if (
    doesEventSpanMultipleDays && (
      eventEndHours > workHours.endWorkHour ||
      eventStartHours < workHours.startWorkHour
    )
  ) {
    return true;
  }

  return false;
}

export function getHourDisplayText({ hourInt, format24HourTime }: {
  hourInt: number
  format24HourTime?: boolean
}) {
  if (format24HourTime) {
    if (hourInt < 10) {
      return `0${hourInt}`;
    }
    return `${hourInt}`;
  }
  const hour = hourInt % 12 || 12; // Convert to 12-hour format
  const amPm = hourInt < 12 ? "AM" : "PM";
  return `${hour} ${amPm}`;
}

export function getBusyAndTentativeRanges(items: SchedulingAssistantItem[], priorityAttendees: Set<string>) {
  type DateRange = { start: Date, end: Date }
  const busyRanges: DateRange[] = [];
  const tentativeRanges: DateRange[] = [];
  const filteredItems = isNotEmptySet(priorityAttendees)
    ? items.filter(item => priorityAttendees.has(item.groupID))
    : items;

  filteredItems.forEach(item => {
    const { event } = item;

    if (isFreeEvent(event)) {
      return;
    }

    const isTentative = getSelfAttendingStatus(event, item.groupID) === ATTENDEE_EVENT_TENTATIVE;
    const rangeArray = isTentative ? tentativeRanges : busyRanges;

    const isAllDay = isAllDayEvent(event);
    const start = isAllDay ? startOfDay(getEventStartJSDate(event)) : getEventStartJSDate(event);
    const end = isAllDay ? endOfDay(getEventEndJSDate(event)) : getEventEndJSDate(event);
    rangeArray.push({ start, end });
  });

  const mergedBusyRanges = mergeRanges(busyRanges);
  const mergedTentativeRanges = mergeRanges(tentativeRanges);
  const netTentativeRanges = subtractRanges(mergedTentativeRanges, mergedBusyRanges);

  return { busyRanges: mergedBusyRanges, tentativeRanges: netTentativeRanges };
}

export const SCHEDULING_ASSISTANT_COLORS = {
  DEFAULT_BACKGROUND_COLOR: "#3565E3",
  DEFAULT_TEXT_COLOR: "white",
  INTERNAL_EVENT_BACKGROUND_COLOR_DARK_MODE: "#293C77",
  INTERNAL_EVENT_DARK_MODE_TEXT_COLOR: "#7497F4",
  INTERNAL_EVENT_BACKGROUND_COLOR_LIGHT_MODE: "#C9D8FF",
  INTERNAL_EVENT_LIGHT_MODE_TEXT_COLOR: "#537BE4",
  INTERNAL_EVENT_BORDER_COLOR: "#88B153",
  SUMMARY_EVENT_BACKGROUND_COLOR: "#6D6F7C",
  CRITICAL_EVENT_BACKGROUND_COLOR: "#E89A4D",
} as const;

export const SMALL_INTERNAL_NUMBER_OF_ATTENDEES = 2;

export function isSmallInternalEvent({item, userEmail}: {item: SchedulingAssistantItem, userEmail: string}) {
  try {
    if (!canDisplayAttendees(item?.event)) {
      return false;
    }
    const attendees = getHumanAttendees(item?.event);
    const userDomain = getDomainFromEmail(userEmail);
    const attendeeDomains = attendees.map((attendee) => {
      const attendeeDomain = getDomainFromEmail(getObjectEmail(attendee), true) || "";
      return attendeeDomain;
    }).filter(domain => !!domain);
    if (attendeeDomains.some(domain => isGenericDomain(domain) || domain !== userDomain)) {
      // there's a domain that doesn't match the user's domain
      return false;
    }
    return attendeeDomains.length <= SMALL_INTERNAL_NUMBER_OF_ATTENDEES;
  } catch(error) {
    return false;
  }
}

