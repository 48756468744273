import create from "zustand";

type EventsByUser = {
  fetchRange: {start: Date, end: Date}
  lastFetched: Date
  events: VimcalEvent[]
  hasError: boolean
}

interface CalendarAuditState {
  eventsByUser: { [userEmail: string]: EventsByUser }
  setEventsByUser: (eventsByUser: { [userEmail: string]: EventsByUser }) => void
  resetEventsByUser: () => void
}

export const useCalendarAudit = create<CalendarAuditState>((set) => ({
  eventsByUser: {},
  setEventsByUser: (eventsByUser) => {
    set(() => ({ eventsByUser }));
  },
  resetEventsByUser: () => set(() => ({ eventsByUser: {} })),
}));
