import React, { useRef } from "react";
import { ChevronRight } from "react-feather";

import { displayLabelFromTags } from "../../../lib/tagsFunctions";
import TagsIcon from "../icons/tagsIcon";
import { DISPLAY_LOCATION_POPUP_EVENT, TagsProps } from "../tagsVariables";
import TagsSelector from "../tagsSelector";
import { COLOR_SELECTOR_ID } from "../../../services/elementIDVariables";
import classNames from "classnames";

export type TagsPopupEventProps = {
  displayLocation: typeof DISPLAY_LOCATION_POPUP_EVENT;
  showOnLeftHandSide: boolean | undefined;
} & Omit<TagsProps, "displayLocation">;


const TagsPopupEvent: React.FC<TagsPopupEventProps> = (props) => {
  const {
    displayLocation,
    tags,
    selectorDisplayIndex,
    setSelectorDisplayIndex,
  } = props;
  const containerForwardRef = useRef(null);
  const isColorSelectorAbove = document.getElementById(COLOR_SELECTOR_ID);

  return (
    <>
      <div
        className={classNames(
          `flex items-center paint-colors-menu p-3 ${displayLocation} border-top-1px`,
          isColorSelectorAbove ? "" : "rounded-tr-md rounded-tl-md"
        )}
        onMouseEnter={() => {
          if (displayLocation !== selectorDisplayIndex.displayLocation) {
            setSelectorDisplayIndex({ displayLocation });
          };
        }}
        ref={containerForwardRef}
      >
        <TagsIcon
          displayLocation={displayLocation}
          tags={tags}
        />
        <div className="paint-colors-label">{displayLabelFromTags({ tags })}</div>
        <ChevronRight className="ml-auto" size={14} />
      </div>
      {selectorDisplayIndex?.displayLocation === displayLocation && (<TagsSelector {...props} popupEventRef={containerForwardRef} />)}
    </>
  )
}

export default TagsPopupEvent;
