import classNames from "classnames";
import React from "react";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import {
  WHITE_BUTTON_WITHOUT_FOCUS,
} from "../../services/globalVariables";
import CustomButton from "../customButton";
import { MousePointer } from "react-feather";
import { getAvailabilityPanelPreviewStyle } from "../../lib/styleFunctions";

export default function EmptyStatePanel({
  isDarkMode,
  inputClassName,
  onClickShowMeHow,
}) {
  const onClickHow = () => {
    onClickShowMeHow
      ? onClickShowMeHow()
      : layoutBroadcast.publish("TOGGLE_ON_SLOTS_ANIMATION");
  };

  return (
    <div
      className={classNames(
        "rounded-md",
        "w-full",
        "flex flex-col items-start justify-center",
        "py-5",
        "pl-8",
        "slot-preview-container",
        inputClassName ?? ""
      )}
      style={getAvailabilityPanelPreviewStyle({isDarkMode})}
    >
      <div className="default-font-size font-weight-500 flex items-center gap-2">
        <MousePointer size={12} color={isDarkMode ? "white" : "rgba(64, 110, 229, 1)"} />
        No Slots selected yet
      </div>
      <div className="default-font-size secondary-text-color mt-2">
        Drag on your calendar to create Slots
      </div>

      {isDarkMode ? (
        <CustomButton
          buttonType={WHITE_BUTTON_WITHOUT_FOCUS}
          onClick={onClickHow}
          label="Show me how"
          className="min-width-initial-important modal-secondary-button-container mt-6"
          labelClassNameOverride="font-size-300-important font-size-12px-override"
        />
      ) : (
        <div
          className="underline cursor-pointer default-font-size mt-5"
          onClick={onClickHow}
          style={{ color: "rgba(64, 110, 229, 1)" }}
        >
          Show me how
        </div>
      )}
    </div>
  );
}
