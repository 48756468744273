import React, { useEffect, useMemo, useReducer, useState } from "react";
import Modal from "react-modal";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BLUE_BUTTON,
  WHITE_BUTTON,
} from "../../services/globalVariables";
import { getExistingTeamMembers } from "./sharedFunctions";
import classNames from "classnames";
import _ from "underscore";
import "../../styles/team-plans.css";
import { useTeamPlan } from "../../services/stores/userData";
import { EVENT_MODAL_ID } from "../../services/elementIDVariables";
import UpdatedAddTeamMembersModal from "./updatedAddTeamMembersModal";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { getTeamPlanPhotoUrl, getTeamPlanTeamName } from "../../lib/teamPlanFunctions";
import MemberList from "./memberList";
import { pluralize } from "../../lib/stringFunctions";
import { useIsCurrentUserAdmin, useIsFixedSeating, useOpenSeats } from "./hooks";
import DidYouKnowAlert from "../availability/alerts/didYouKnowAlert";
import { trackTeamSlotsTeamPlan } from "../../lib/groupSchedulingFunctions";
import RemoveMemberModal from "./removeMemberModal";
import { useDefaultPaymentMethod, useHasBillingBeenFetched, useStripeSubscriptions } from "../../services/stores/finance";
import { isScheduledToBeCancelled } from "../../lib/stripeFunctions";
import CancellationWarning from "../billing/cancellationWarning";
import LoadingSkeleton from "../loadingSkeleton";
import CustomButtonV2 from "../buttons/customButtonV2";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { hasAccountBeenOnTeamPlan } from "../../services/accountFunctions";
import Confirmation from "../settings/common/confirmation";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import TeamPlanSettings from "./teamPlanSettings";
import ColoredLine from "../line";
import NeedPaymentSettings from "./teamPlanSettings/needPaymentSettings";
import { isNewOrPreTrialAccount } from "../../lib/stateManagementFunctions";
import ContactVimcal from "../billing/contactVimcal";

const MODAL_CONTENT_ADD_NEW_MEMBERS = "MODAL_CONTENT_ADD_NEW_MEMBERS";
const MODAL_CONTENT_REMOVE_USER = "MODAL_CONTENT_REMOVE_USER";
type ModalContent = typeof MODAL_CONTENT_ADD_NEW_MEMBERS | typeof MODAL_CONTENT_REMOVE_USER;

export default function TeamPlansContainer() {
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const hasBillingBeenFetched = useHasBillingBeenFetched(state => state.hasBillingBeenFetched);
  const isAdmin = useIsCurrentUserAdmin();

  const history = useHistory();
  const teamMembers = useMemo(() => getExistingTeamMembers(teamPlan), [teamPlan]);
  const [modalContent, setModalContent] = useState<ModalContent>();
  const [removedTeammate, setRemovedTeammate] = useState<TeamPlanMember>();
  const [shouldDisplayConfirmation, setShouldDisplayConfirmation] = useState(false);
  const [confirmationTriggerCount, updateConfirmationTriggerCount] = useReducer((x: number) => x + 1, 0);

  const isFixedSeating = useIsFixedSeating();

  useEffect(() => {
    if (isEmptyObjectOrFalsey(currentUser)) {
      history.push("/login");
    }
  }, []);

  if (!hasBillingBeenFetched) {
    return <LoadingSkeleton style={{ width: "100%", height: 200, borderRadius: 8 }} />;
  }

  const showConfirmation = () => {
    setShouldDisplayConfirmation(true);
    updateConfirmationTriggerCount();
  };

  const onClickCloseModal = () => {
    setModalContent(undefined);
  };
  const hasExistingTeamPlan = !isEmptyObjectOrFalsey(teamPlan);

  const renderModal = () => {
    if (modalContent === MODAL_CONTENT_ADD_NEW_MEMBERS) {
      const ModalStyle = determineDefaultModalStyle(isDarkMode, true);
      const portalRef = document.getElementById("layout");

      if (!portalRef) {
        return;
      }

      return ReactDOM.createPortal(
        <Modal
          ariaHideApp={false}
          id={EVENT_MODAL_ID}
          isOpen={true}
          onRequestClose={_.noop}
          style={ModalStyle}
        >
          <UpdatedAddTeamMembersModal closeModal={onClickCloseModal} />
        </Modal>,
        portalRef,
      );
    }
  };

  const shouldShowTeamSettings = (
    !isEmptyObjectOrFalsey(teamPlan) && (
      isAdmin ||
      getTeamPlanTeamName(teamPlan) ||
      getTeamPlanPhotoUrl(teamPlan)
    )
  );

  const onClickRemoveMember = (teammate: TeamPlanMember) => {
    setModalContent(MODAL_CONTENT_REMOVE_USER);
    setRemovedTeammate(teammate);
  };

  return (
    <div className="flex flex-col items-stretch pr-2">
      {shouldShowTeamSettings ? (
        <>
          <div className="font-size-14">Team settings</div>
          <ColoredLine inputClassName={"mt-3 mb-5"} />
          <TeamPlanSettings />
        </>
      ) : null}
      <div className={classNames("mb-24", shouldShowTeamSettings ? "mt-7" : "")}>
        <Header
          confirmationTriggerCount={confirmationTriggerCount}
          setModalContent={setModalContent}
          setShouldDisplayConfirmation={setShouldDisplayConfirmation}
          shouldDisplayConfirmation={shouldDisplayConfirmation}
          teamMembers={teamMembers}
        />
        <TeamPlanAlert />
        {hasExistingTeamPlan ? (
          <MemberList
            removeMember={onClickRemoveMember}
            showConfirmation={showConfirmation}
            teamMembers={teamMembers}
          />
        ) : <EmptyState setModalContent={setModalContent} />}
        {renderModal()}
        <RemoveMemberModal
          isOpen={modalContent === MODAL_CONTENT_REMOVE_USER}
          onRequestClose={onClickCloseModal}
          removedTeammate={removedTeammate}
        />
        {isFixedSeating ? (
          <ContactVimcal label="Need to add or remove seats? Please" contactLinkLabel="contact us" />
        ) : null}
      </div>
    </div>
  );
}

interface HeaderProps {
  confirmationTriggerCount: number
  setModalContent: StateSetter<ModalContent | undefined>
  setShouldDisplayConfirmation: StateSetter<boolean>
  shouldDisplayConfirmation: boolean
  teamMembers: TeamPlanMember[]
}

function Header({
  confirmationTriggerCount,
  setModalContent,
  setShouldDisplayConfirmation,
  shouldDisplayConfirmation,
  teamMembers,
}: HeaderProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const stripeSubscription = useStripeSubscriptions(
    (state) => state.stripeSubscriptions,
  );

  if (isEmptyArrayOrFalsey(teamMembers)) {
    return null;
  }

  const numberOfMembers = teamMembers ? teamMembers.length : 0;
  return (
    <div className="flex items-center justify-between w-full mb-4">
      <div className="flex gap-2 align-center">
        <div className="font-size-14">{`Team members (${numberOfMembers})`}</div>
        <Confirmation
          shouldDisplay={shouldDisplayConfirmation}
          setShouldDisplay={setShouldDisplayConfirmation}
          triggerCount={confirmationTriggerCount}
        />
      </div>
      <CustomButtonV2
        disabled={isScheduledToBeCancelled({ stripeSubscription })}
        buttonType={WHITE_BUTTON}
        onClick={() => {
          setModalContent(MODAL_CONTENT_ADD_NEW_MEMBERS);
          trackTeamSlotsTeamPlan({
            action: "open team plans from billing",
            currentUser,
          });
        }}
        label="+ Add teammates"
        removeDefaultFontStyles={true}
        labelClassNameOverride={"default-font-size font-weight-400"}
      />
    </div>
  );
}

interface EmptyStateProps {
  setModalContent: StateSetter<ModalContent | undefined>
}

function EmptyState({ setModalContent }: EmptyStateProps) {
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const hasExistingTeamPlan = !isEmptyObjectOrFalsey(teamPlan);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const stripeSubscription = useStripeSubscriptions(
    (state) => state.stripeSubscriptions,
  );

  const needCard = useDefaultPaymentMethod(state => state.needCard);

  const canStartTrialTeamPlan = (
    !hasExistingTeamPlan &&
    !hasAccountBeenOnTeamPlan(masterAccount) &&
    isNewOrPreTrialAccount(masterAccount)
  );

  if (needCard && !canStartTrialTeamPlan) {
    return (
      <div>
        <NeedPaymentSettings />
      </div>
    );
  }

  return (
    <div className={classNames("default-font-size", hasExistingTeamPlan ? "mt-8" : "")}>
      <div>Teams who use Vimcal get more done in less time!</div>

      <div className="mt-5">
        You and your teammates can invite others, and you can manage all your
        billing here.
      </div>

      <CustomButtonV2
        buttonType={BLUE_BUTTON}
        disabled={isScheduledToBeCancelled({ stripeSubscription })}
        onClick={() => {
          trackTeamSlotsTeamPlan({
            action: "open team plans from billing",
            currentUser,
          });
          setModalContent(MODAL_CONTENT_ADD_NEW_MEMBERS);
        }}
        label="Create team"
        className="mt-4"
      />
    </div>
  );
}

function TeamPlanAlert() {
  const teamPlan = useTeamPlan((state) => state.teamPlan);
  const stripeSubscription = useStripeSubscriptions(
    (state) => state.stripeSubscriptions,
  );
  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();
  const totalOpenSeats = openVimcalEASeats + openVimcalSeats;

  if (isScheduledToBeCancelled({ stripeSubscription })) {
    return <CancellationWarning />;
  }

  if (!isEmptyObjectOrFalsey(teamPlan) && totalOpenSeats > 0) {
    return (
      <DidYouKnowAlert
        excludeDefaultMargins
        className="mb-4"
        title={`You have ${totalOpenSeats} open ${pluralize(totalOpenSeats, "seat")} available on your team plan`}
      />
    );
  }

  return null;
}
