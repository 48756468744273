import React from "react";
import { useSelector } from "react-redux";
import VerifiedCheck from "./verifiedCheck";

function GoogleMeetModal(props) {
  const currentUser = useSelector((state) => state.currentUser);

  const getUser = () => {
    return props.inputUser ?? currentUser;
  }

  return (
    <div className="width-100-percent mt-3">
      <div className="display-flex-flex-direction-row align-middle justify-between">
        <div className="default-font-size secondary-text-color">Authorized Account:</div>

        <div className="display-flex-flex-direction-row align-middle">
          <VerifiedCheck className="mt-1 mr-2" />

          <div className="default-font-size">{getUser().email}</div>
        </div>
      </div>
    </div>
  );
}

export default GoogleMeetModal;
