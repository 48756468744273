import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EventExpandedView from "./eventExpandedView";
import CloseButton from "../components/closeButton";
import SidePanel from "../components/sidePanel";
import {
  SortEvents,
  CreateJSDate,
  mutateUTCTimeToDesiredTimeZoneTime,
  hasEventPreventDefault,
  calculateMarginTopClassname,
  isElectron,
} from "../services/commonUsefulFunctions";
import StyleConstants from "../services/globalVariables";
import {
  KEYCODE_ENTER,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ESCAPE,
  formatForEventTemplates,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import TextTemplateView from "./textTemplateView";
import {
  TEXT_TEMPLATE,
  EVENT_TEMPLATE,
} from "../services/googleCalendarService";
import moment from "moment";
import ShortcutTile from "../components/shortcutTiles/shortcutTile";
import {
  getTemplateEndDate,
  getTemplateEndDateTime,
  getTemplateEndTimeZone,
  getTemplateLocation,
  getTemplateStart,
  getTemplateStartDate,
  getTemplateStartDateTime,
  getTemplateStartTimeZone,
  getTemplateTitle,
  isTextTemplate,
} from "../services/templateFunctions";
import classNames from "classnames";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { truncateString } from "../lib/stringFunctions";
import { getMasterAccountTemplates } from "../lib/userFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import _ from "underscore";
import { getTitleWithBackup } from "../lib/styleFunctions";

class EditTemplates extends PureComponent {
  constructor(props) {
    super(props);

    const state = this.createState();
    this.state = state;

    this.handlePreviousSelectedOption =
      this.handlePreviousSelectedOption.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onClickSearchResult = this.onClickSearchResult.bind(this);
    this.closeEditTemplatesModal = this.closeEditTemplatesModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const previousMasterAccount = prevProps.masterAccount.masterAccount;
    const previousTemplates = getMasterAccountTemplates({ masterAccount: previousMasterAccount });

    const masterAccount = this.props.masterAccount.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });

    if (!_.isEqual(_.sortBy(templates), _.sortBy(previousTemplates))) {
      const updatedState = this.createState();
      this.setState(updatedState);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  render() {
    return (
      <div
        className={classNames(
          "search-wrapper",
          calculateMarginTopClassname(this.props.shouldShowTopBar)
        )}
      >
        <div className="search-header-wrapper">
          <div className="edit-template-template-title">Templates</div>

          <div className="search-close-button-wrapper">
            <CloseButton onClick={this.closeEditTemplatesModal} />
          </div>

          <div
            className={
              isElectron()
                ? "search-empty-state-wrapper-electron"
                : "search-empty-state-wrapper"
            }
          >
            {this.renderTemplatesList()}
          </div>
        </div>

        <SidePanel className="side-panel-container-left-border">
          {this.renderEventExtendedPreview()}
        </SidePanel>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderTemplatesList() {
    return this.state.searchResults.map((template, index) => {
      return (
        <div
          ref={template.id ?? this.state.refs[template.template.id]}
          key={`template_result_${index}`}
          onClick={() => this.onClickSearchResult()}
          onMouseEnter={() => this.handleHover(template, index)}
          className="search-result-bar"
          style={{
            backgroundColor: this.determineBackgroundColor(index),
            color:
              this.isSelectedOption(index) && this.props.isDarkMode
                ? StyleConstants.darkModeCommandCenterTextColor
                : null,
          }}
        >
          <div className="search-summary-wrapper flex flex-col justify-center">
            <div className="flex justify-between items-center">
              <div className="mt-1 default-font-size font-size-14 search-left-panel-title-max-width truncate">
                {getTitleWithBackup(template?.title)}
              </div>
              {template.isCreate ? null : (
                <div className="mr-4">
                  <ShortcutTile
                    marginRight={24}
                    key={`shortcutTile_edit_template_${index}`}
                    shortcut={template.isEventTemplate ? "Event" : "Text"}
                  />
                </div>
              )}
            </div>

            {this.determineSubTitleEventTemplate(template) ||
            this.determineSubTitleTextTemplate(template) ? (
              <div className="search-date mt-2.5">
                {template.isEventTemplate
                  ? this.determineSubTitleEventTemplate(template)
                  : this.determineSubTitleTextTemplate(template)}
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  }

  determineBackgroundColor(index) {
    if (this.props.isDarkMode) {
      if (this.isSelectedOption(index)) {
        return StyleConstants.darkModeHoverBackgroundColor;
      } else {
        return StyleConstants.darkModeBackgroundColor;
      }
    } else {
      // light mode
      if (this.isSelectedOption(index)) {
        return StyleConstants.searchHoveredColor;
      } else {
        return "white";
      }
    }
  }

  determineSubTitleEventTemplate(inputTemplate) {
    if (isEmptyObjectOrFalsey(inputTemplate)) {
      return "";
    }

    const { template } = inputTemplate;

    if (getTemplateStart(template)) {
      return this.expandedDateAndTimeString(template);
    } else if (getTemplateLocation(template)) {
      // ignore rawJson for templates
      return truncateString(getTemplateLocation(template), 80); // ignore rawJson for templates
    } else {
      return "";
    }
  }

  determineSubTitleTextTemplate(template) {
    if (!template.template?.text) {
      return "";
    }

    return truncateString(template.template.text, 80);
  }

  expandedDateAndTimeString(template) {
    // January 9, 2020, 12:15am – January 10, 2020, 3:15am
    const eventStart = getTemplateStartDate(template)
      ? CreateJSDate(getTemplateStartDate(template))
      : mutateUTCTimeToDesiredTimeZoneTime(
          getTemplateStartDateTime(template),
          getTemplateStartTimeZone(template)
        );
    const eventEnd = getTemplateEndDate(template)
      ? CreateJSDate(getTemplateEndDate(template))
      : mutateUTCTimeToDesiredTimeZoneTime(
          getTemplateEndDateTime(template),
          getTemplateEndTimeZone(template)
        );

    const startDate = moment(eventStart).format("MMM D, YYYY");
    const startTime = moment(eventStart).format("h:mma");

    const endDate = moment(eventEnd).format("MMM D, YYYY");
    const endTime = moment(eventEnd).format("h:mma");
    return startDate === endDate
      ? `${startDate}, ${startTime} - ${endTime}`
      : `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
  }

  renderEventExtendedPreview() {
    if (!this.state.hoverPreviewSearchResult) {
      return (
        <div
          className="flex justify-center items-center"
          style={{ height: "100%" }}
        >
          <div className="">Filler text - Ask John for copy!</div>
        </div>
      );
    } else if (this.state.hoverPreviewSearchResult.isEventTemplate) {
      const formattedEvent = formatForEventTemplates(
        this.state.hoverPreviewSearchResult.template,
        this.props.currentTimeZone
      );

      return (
        <EventExpandedView
          ignoreMarginTop={true}
          event={formattedEvent}
          template={this.state.hoverPreviewSearchResult.template}
          shouldIgnoreBroadcast={true}
          isTemplate={true}
        />
      );
    } else if (this.state.hoverPreviewSearchResult.template) {
      return (
        <TextTemplateView
          template={this.state.hoverPreviewSearchResult.template}
        />
      );
    } else {
      return null;
    }
  }

  //================
  // EVENT HANDLERS
  //================

  handleHover(result, index) {
    this.setState({
      hoverIndex: index,
      hoverPreviewSearchResult: result,
    });
  }

  handleNextSelectedOption(e) {
    hasEventPreventDefault(e);

    let newIndex =
      (this.state.hoverIndex + 1) % this.state.searchResults.length;

    this.setState({
      hoverIndex: newIndex,
      hoverPreviewSearchResult: this.state.searchResults[newIndex],
    });

    this.scrollToOption(newIndex);
  }

  onClickCreateTemplate() {
    Broadcast.publish("CREATE_TEMPLATE", EVENT_TEMPLATE);

    this.closeEditTemplatesModal();
  }

  onClickCreateSticky() {
    Broadcast.publish("CREATE_TEMPLATE", TEXT_TEMPLATE);

    this.closeEditTemplatesModal();
  }

  handleKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        this.closeEditTemplatesModal();

        return;
      case KEYCODE_UP_ARROW:
        this.handlePreviousSelectedOption(e);

        break;
      case KEYCODE_DOWN_ARROW:
        this.handleNextSelectedOption(e);

        break;
      case KEYCODE_ENTER:
        const result = this.state.searchResults[this.state.hoverIndex];

        this.onClickSearchResult(result);

        break;
      default:
        break;
    }
  }

  handlePreviousSelectedOption(e) {
    hasEventPreventDefault(e);

    let newIndex;

    if (this.state.hoverIndex === 0) {
      newIndex = this.state.searchResults.length - 1;
    } else {
      newIndex = this.state.hoverIndex - 1;
    }

    this.setState({
      hoverIndex: newIndex,
      hoverPreviewSearchResult: this.state.searchResults[newIndex],
    });

    this.scrollToOption(newIndex);
  }

  scrollToOption(newIndex) {
    let ref = this.getRef(newIndex);
    ref &&
      ref.current &&
      ref.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
      });
  }

  getRef(newIndex) {
    const hoveredOption = this.getHoveredOption(newIndex);

    if (hoveredOption?.id) {
      return this.state.refs[hoveredOption.id];
    }

    return (
      hoveredOption &&
      hoveredOption.template &&
      this.state.refs[hoveredOption.template.id]
    );
  }

  getHoveredOption(newIndex) {
    return this.state.searchResults[newIndex];
  }

  //=================
  // PRIVATE METHODS
  //=================

  createTemplateArray() {
    const templates = getMasterAccountTemplates({ masterAccount: this.props.masterAccount.masterAccount });
    let templateArray = [];

    templates.forEach((t) => {
      if (isTextTemplate(t)) {
        templateArray = templateArray.concat({
          title: t.description || t.text || "No title",
          template: t,
          updatedAt: t.updated_at,
          isEventTemplate: false,
          lastUpdate: t.last_used_at || t.updated_at || t.created_at,
        });
      } else {
        templateArray = templateArray.concat({
          title: getTemplateTitle(t) || "No title",
          template: t,
          updatedAt: t.updated_at,
          isEventTemplate: true,
          lastUpdate: t.last_used_at || t.updated_at || t.created_at,
        });
      }
    });

    if (templates.length > 0) {
      templateArray = templateArray.sort((a, b) =>
        SortEvents(a, b, "lastUpdate", true)
      );
    }

    templateArray = templateArray.concat({
      title: "Create event template",
      onClick: this.onClickCreateTemplate.bind(this),
      id: "createEventTemplate",
      isCreate: true,
    });
    templateArray = templateArray.concat({
      title: "Create a Sticky",
      onClick: this.onClickCreateSticky.bind(this),
      id: "createSticky",
      isCreate: true,
    });

    return templateArray;
  }

  onClickSearchResult() {
    if (isEmptyObjectOrFalsey(this.state.hoverPreviewSearchResult)) {
      return;
    }

    if (this.state.hoverPreviewSearchResult.onClick) {
      this.state.hoverPreviewSearchResult.onClick();
    } else if (!this.state.hoverPreviewSearchResult.isEventTemplate) {
      Broadcast.publish(
        "UPDATE_TEMPLATE",
        TEXT_TEMPLATE,
        this.state.hoverPreviewSearchResult.template
      );
    } else {
      Broadcast.publish(
        "UPDATE_TEMPLATE",
        EVENT_TEMPLATE,
        formatForEventTemplates(
          this.state.hoverPreviewSearchResult.template,
          this.props.currentTimeZone
        )
      );
    }

    this.closeEditTemplatesModal();
  }

  closeEditTemplatesModal() {
    this.props.history.replace("/home");
  }

  isSelectedOption(index) {
    return index === this.state.hoverIndex;
  }

  createState() {
    const templateArray = this.createTemplateArray();

    const refs = templateArray.reduce((result, option) => {
      result[option.id ?? option.template.id] = createRef();
      return result;
    }, {});

    return {
      searchResults: templateArray,
      hoverIndex: 0,
      hoverPreviewSearchResult:
        templateArray.length > 0 ? templateArray[0] : null,
      refs,
    };
  }
}

function mapStateToProps(state) {
  const { currentTimeZone, isDarkMode } = state;

  return {
    currentTimeZone,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} />;
};

export default connect(mapStateToProps, null)(withRouter(withStore(EditTemplates)));
