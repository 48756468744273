import { isSameMinute } from "date-fns";
import { isNull } from "../services/typeGuards";
import { immutablySortArray } from "./arrayFunctions";

type Point = number | Date
type ExtremaPosition = "start" | "end"

type Range<T extends Point> = {
  start: T
  end: T
}

function arePointsEqual<T extends Point>(a: T, b: T) {
  if (a instanceof Date) {
    return isSameMinute(a, b);
  } else {
    return a === b;
  }
}

function extremaCompareFn<T extends Point>(
  a: { point: T, position: ExtremaPosition },
  b: { point: T, position: ExtremaPosition },
) {
  if (a.point < b.point) {
    return -1;
  } else if (a.point > b.point) {
    return 1;
  } else if (a.position === "end" && b.position === "start") {
    return -1;
  } else if (a.position === "start" && b.position === "end") {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Even though mergeAdjacent is not used in this repo, the util is copied to the RBC fork
 * repo where this option is necessary. Keep the logic here so that this helper is the source
 * of truth with all functionality, and so we can unit test.
 */
export function mergeRanges<T extends Point>(ranges: Range<T>[], mergeAdjacent=true): Range<T>[] {
  const extrema: {
    point: T
    position: "start" | "end"
  }[] = [];
  ranges.forEach(range => {
    if (arePointsEqual(range.start, range.end)) {
      return;
    }
    extrema.push({ point: range.start, position: "start" });
    extrema.push({ point: range.end, position: "end" });
  });
  const sortedExtrema = immutablySortArray(extrema, extremaCompareFn);

  const results: { start: T, end: T }[] = [];
  let currentRange: { start: T, end: T } | null = null;
  let nestedLevel = 0;
  for (const { point, position } of sortedExtrema) {
    if (position === "start") {
      nestedLevel += 1;
      if (nestedLevel === 1) {
        if (mergeAdjacent && currentRange && arePointsEqual(currentRange.end, point)) {
          // A range just closed and another one opened up on the same point.
          // No need to create a new range.
          continue;
        }
        currentRange = { start: point, end: point };
        results.push(currentRange);
      }
    } else {
      nestedLevel -= 1;
      if (currentRange) {
        currentRange.end = point;
      }
    }

    if (nestedLevel < 0) {
      throw new Error("Ranges must not end before they start.");
    }
  }

  if (nestedLevel !== 0) {
    throw new Error("nestedLevel must be 0 after all ranges processed.");
  }

  return results;
}

/**
 * Subtract one list of ranges from another. The values in the ranges can be numbers or dates.
 *
 * Each list of ranges should be deduped/merged before passing into this helper.
 *
 * Note: These are treated as continuous ranges, not as ranges of discrete values.
 * So the end points of the excluded ranges are not removed from the included ranges.
 * If a range's start and end are the same, it is an empty range.
 *
 * @example
 * subtractRanges([{ start: 0, end: 10 }], [{ start: 4, end: 10 }])
 * // returns [{ start: 0, end: 4 }]
 */
export function subtractRanges<T extends Point>(
  includedRanges: { start: T, end: T }[],
  excludedRanges: { start: T, end: T }[],
): { start: T, end: T }[] {
  // First we need a sorted list of all the range endpoints.
  // Filter out any empty ranges (where the start and end are equal).
  const extrema: {
    point: T
    type: "inclusion" | "exclusion"
    position: "start" | "end"
  }[] = [];
  includedRanges.forEach(range => {
    if (arePointsEqual(range.start, range.end)) {
      return;
    }
    extrema.push({ point: range.start, type: "inclusion", position: "start" });
    extrema.push({ point: range.end, type: "inclusion", position: "end" });
  });
  excludedRanges.forEach(range => {
    if (arePointsEqual(range.start, range.end)) {
      return;
    }
    extrema.push({ point: range.start, type: "exclusion", position: "start" });
    extrema.push({ point: range.end, type: "exclusion", position: "end" });
  });
  const sortedExtrema = immutablySortArray(extrema, extremaCompareFn);

  let include = false; // True when inside an included range.
  let exclude = false; // True when inside an excluded range.
  let range_start: T | null = null;

  // Now we iterate through the sorted extrema, building out the resultant ranges.
  const resultRanges: { start: T, end: T }[] = [];
  for (const { point, type, position } of sortedExtrema) {
    if (position === "start" && type === "inclusion") {
      range_start = range_start ?? point;
      include = true;
    } else if (position === "start" && type === "exclusion") {
      if (include && !isNull(range_start) && !arePointsEqual(point, range_start)) {
        resultRanges.push({ start: range_start, end: point });
      }
      range_start = null;
      exclude = true;
    } else if (position === "end" && type === "inclusion") {
      if (!exclude && !isNull(range_start) && !arePointsEqual(point, range_start)) {
        resultRanges.push({ start: range_start, end: point });
      }
      range_start = null;
      include = false;
    } else if (position === "end" && type === "exclusion") {
      if (include) {
        range_start = point;
      }
      exclude = false;
    }
  }

  return resultRanges;
}
