import React from "react";
import ApprovalBadgeWithLoadingSpinner from "../../common/approvalBadgeWithLoadingSpinner";
import { getWarningText } from "../../settingFunctions";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";

export default function UserNameSettings({
  initialUserName,
  setValue,
  value,
  isAvailable,
  isFetching,
  showWarningAboutChangingUsername,
  canShowWarning,
}) {
  const onChange = (e) => {
    setValue(getInputStringFromEvent(e));
  };

  const hideBadgeAndWarning = () => {
    return !canShowWarning && ((!initialUserName && !value) || value === initialUserName );
  };

  const warning = getWarningText({
    isAvailable,
    userName: value,
    isFetching,
    hideBadgeAndWarning: hideBadgeAndWarning(),
  });
  return (
    <div className="flex flex-col justify-start mt-4">
      <div className="default-font-size default-font-weight">Username*</div>
      {showWarningAboutChangingUsername ? (
        <div className="mt-1 secondary-text-color default-font-size width-450px">
          Changing your username will mean that all of your copied links will no
          longer work and will need to be updated.
        </div>
      ) : null}
      <div className="flex items-center mt-1.5">
        <input
          className="default-input-field settings-update-name-input"
          style={{ width: "434px" }}
          onChange={onChange}
          value={value}
        />
        {hideBadgeAndWarning() ? null : (
          <ApprovalBadgeWithLoadingSpinner
            isFetching={isFetching}
            warning={warning}
            spinnerLocationClassName={"user-name-spinner-location"}
          />
        )}
      </div>

      {warning ? (
        <div className="warning-color default-font-size mt-1">{warning}</div>
      ) : (
        <div className="default-font-size secondary-text-color mt-1 width-450px truncate-text">
          {`Example of your personal link: https://book.vimcal.com/p/${
            value || "${token}"
          }/example`}
        </div>
      )}
    </div>
  );
}
