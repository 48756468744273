import React from "react";
import CopiableContentContainer from "../copiableContentContainer";
import NativeConferencingInfo from "../nativeConferencingInfo";
import {
  isEventConferencingZoom,
  OpenLink,
  formatRFCPhoneNumber,
} from "../../services/commonUsefulFunctions";
import { Phone } from "react-feather";
import GoogleCalendarService from "../../services/googleCalendarService";
import { getZoomMeetingDetails } from "../../lib/conferencing";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { truncateConferenceUrl } from "../../services/googleCalendarHelpers";

const { zoomString } = GoogleCalendarService;

const EventFormNativeZoomContent = (props) => {
  return (
    <div>
      <div
        className="join-conference-link margin-left-22px margin-bottom-five"
        onClick={() => OpenLink(props.url)}
      >
        Join Zoom Meeting
      </div>

      {/* Zoom Info */}
      {props.hideConferencingInfo ? null : (
        <NativeConferencingInfo
          event={
            isEventConferencingZoom(props.originalEvent)
              ? props.originalEvent
              : null
          }
          fromEventForm={true}
          zoomMeeting={props.zoomMeeting}
        />
      )}

      {/* or just the Zoom URL */}
      {(!props.originalEvent ||
        (props.conferenceChanged && props.conference === zoomString) ||
        props.hideConferencingInfo) && (
        <div
          className="zoom-additional-info-section"
          style={{ wordBreak: "break-all", width: 290, marginLeft: 23 }}
        >
          {props.zoomMeeting ? null : (
            <CopiableContentContainer
              style={{ alignItems: "flex-start", width: 270 }}
              textToCopy={props.url}
            >
              {truncateConferenceUrl(props.url)}
            </CopiableContentContainer>
          )}
          <RenderId zoomMeeting={props.zoomMeeting} />

          {props.zoomMeeting?.password && (
            <div>
              <CopiableContentContainer textToCopy={props.zoomMeeting.password}>
                Password: {props.zoomMeeting.password}
              </CopiableContentContainer>
            </div>
          )}

          {props.zoomMeeting?.passcode && (
            <div>
              <CopiableContentContainer textToCopy={props.zoomMeeting.passcode}>
                Passcode: {props.zoomMeeting.passcode}
              </CopiableContentContainer>
            </div>
          )}

          <RenderPhoneSection zoomMeeting={props.zoomMeeting} />
          <RenderNotesSection notes={props.notes} />
        </div>
      )}
    </div>
  );
};

function RenderNotesSection({ notes }) {
  if (!notes) {
    return null;
  }
  return (
    <div className="zoom-additional-info-container width-250 default-font-color">
      <CopiableContentContainer textToCopy={notes}>
        <div target="_blank" dangerouslySetInnerHTML={{ __html: notes }}></div>
      </CopiableContentContainer>
    </div>
  );
}

function RenderId({ zoomMeeting }) {
  if (isEmptyObjectOrFalsey(zoomMeeting) || !zoomMeeting.id) {
    return null;
  }

  return (
    <div>
      <CopiableContentContainer textToCopy={zoomMeeting.id} iconMarginTop={3}>
        ID: {zoomMeeting.id}
      </CopiableContentContainer>
    </div>
  );
}

function RenderPhoneSection({ zoomMeeting }) {
  if (isEmptyObjectOrFalsey(zoomMeeting)) {
    return null;
  }
  const { phoneInfo } = getZoomMeetingDetails(zoomMeeting);

  if (isEmptyObjectOrFalsey(phoneInfo)) {
    return null;
  }

  const humanReadableNumber = formatRFCPhoneNumber(
    phoneInfo.number,
    phoneInfo.country
  ).replace("tel:", "");

  return (
    <div
      className="zoom-additional-info-container width-300 secondary-text-color"
      style={{ marginLeft: -23 }}
    >
      <div className="margin-top-3 pt-1">
        <Phone className="non-clickable-icon" size="13" />
      </div>

      <CopiableContentContainer
        textToCopy={humanReadableNumber}
        iconMarginTop={3}
      >
        <div
          className="margin-left-ten default-font-color"
          style={{ marginTop: 1 }}
        >
          {`(${phoneInfo.country}) ${humanReadableNumber}`}
        </div>
      </CopiableContentContainer>
    </div>
  );
}

export default EventFormNativeZoomContent;
