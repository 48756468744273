import create from "zustand";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../typeGuards";
import { removeDuplicatesFromArray } from "../../lib/arrayFunctions";

export const useEnrichedCompanyStore = create(
  // do not save to local storage since this could take up too much storage
  (set) => ({
    enrichedCompanies: {},
    companyNews: {},
    addCompanyNews: (newNews) => {
      if (isEmptyArrayOrFalsey(newNews)) {
        return;
      }

      set((state) => {
        let updatedCompanyNews = state.companyNews ?? {};
        newNews.forEach((info) => {
          if (!info) {
            return;
          }
          const { summarizedNews, companyURL } = info;
          if (!companyURL || !summarizedNews) {
            return;
          }
          updatedCompanyNews = addEnrichedCompanyNews({
            existingNews: updatedCompanyNews,
            companyNews: summarizedNews,
            companyURL,
          });
        });
        return {
          companyNews: updatedCompanyNews,
        };
      });
    },
    companyDomainsPendingBackend: [],
    addCompanyDomainsPendingBackend: (domains) => {
      set((state) => {
        return {
          companyDomainsPendingBackend: removeDuplicatesFromArray([...state.companyDomainsPendingBackend, ...domains]),
        };
      });
    },
    clearCompanyDomainsPendingBackend: () => {
      set((state) => ({ companyDomainsPendingBackend: [] }));
    },
    addCompanies: (companies) => {
      // companies is an object with {domain: summary...}
      if (isEmptyObjectOrFalsey(companies)) {
        return;
      }
      const filteredCompanies = Object.entries(companies)
        .filter(([key, value]) => value?.length >= 25)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      if (isEmptyObjectOrFalsey(filteredCompanies)) {
        return;
      }

      set((state) => {
        return {
          enrichedCompanies: {
            ...state.enrichedCompanies,
            ...filteredCompanies,
          },
        };
      });
    },
    resetEnrichedCompanies: () => set((state) => ({ enrichedCompanies: {}, companyDomainsPendingBackend: [] })),
  })
);

function addEnrichedCompanyNews({ existingNews, companyNews, companyURL }) {
  const updatedCompanyNews = existingNews ?? {};
  updatedCompanyNews[companyURL] = {
    updatedAt: new Date().toISOString(),
    companyNews,
  };
  return updatedCompanyNews;
}
