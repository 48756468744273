import React, { Component } from "react";
import CustomSelect from "../components/select";
import { connect } from "react-redux";
import StyleConstants from "../services/globalVariables";
import SaveButton from "../components/saveButton";
import { Check, Search, Users } from "react-feather";
import classNames from "classnames";
import { useConferenceRoomStore } from "../services/stores/conferenceRoomStores";
import {
  getBuildingConferenceRoomCount,
  getBuildingIDFromRawObject,
  getBuildingNameFromRawObject,
  getConferenceRoomID,
  getConferenceRoomName,
  getRoomBuildingID,
  getRoomCapacity,
  getRoomsObject,
} from "../lib/conferenceRoomFunctions";
import { getReactSelectBaseStyle } from "./select/styles";
import {
  getInputStringFromEvent,
  lowerCaseAndTrimStringWithGuard,
} from "../lib/stringFunctions";
import ConferencingFeatures from "./eventForm/conferencingFeatures";
import {
  isEmptyArrayOrFalsey,
  isNullOrUndefined,
} from "../services/typeGuards";
import LoadingSkeleton from "./loadingSkeleton";
import eventFormBroadcast from "../broadcasts/eventFormBroadcast";
import { EVENT_FORM_BROADCAST_VALUES } from "../lib/broadcastValues";
import { isCalendarOutlookCalendar } from "../lib/calendarFunctions";
import { immutablySortArray } from "../lib/arrayFunctions";

const SELECT_ROOM = "SELECT ROOMS";
const ALL_BUILDINGS = "All buildings";
const SELECT_BUILDING = "Select building";
const SELECT_ROOM_CAPACITY = "All room sizes";

const SELECT_ROOM_CAPACITY_OPTION = {
  label: SELECT_ROOM_CAPACITY,
  value: SELECT_ROOM_CAPACITY,
};
const ONE_OR_MORE_PEOPLE = { label: "1 or more people", value: 1 };
const TWO_OR_MORE_PEOPLE = { label: "2 or more people", value: 2 };
const THREE_OR_MORE_PEOPLE = { label: "3 or more people", value: 3 };
const FOUR_OR_MORE_PEOPLE = { label: "4 or more people", value: 4 };
const FIVE_OR_MORE_PEOPLE = { label: "5 or more people", value: 5 };
const TEN_OR_MORE_PEOPLE = { label: "10 or more people", value: 10 };
const TWENTY_OR_MORE_PEOPLE = { label: "20 or more people", value: 20 };

class SelectConferenceRoom extends Component {
  constructor(props) {
    super(props);

    const buildings = this.createBuilding(this.props.buildings);
    const roomCapacities = this.createRoomCapacityArray();

    const getSelectedCapacity = () => {
      const { attendees } = props;
      if (isEmptyArrayOrFalsey(attendees)) {
        return roomCapacities[0];
      }
      const numberOfPeople = attendees.length;
      if (numberOfPeople >= 20) {
        return TWENTY_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 10) {
        return TEN_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 5) {
        return FIVE_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 4) {
        return FOUR_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 3) {
        return THREE_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 2) {
        return TWO_OR_MORE_PEOPLE;
      } else if (numberOfPeople >= 1) {
        return ONE_OR_MORE_PEOPLE;
      }
      return roomCapacities[0];
    };

    const getInitialBuilding = () => {
      const { lastSelectedBuildingID } = this.props.conferenceRoomStore;
      if (lastSelectedBuildingID) {
        // only select the room if it's done loading
        const matchingBuilding = buildings.find(b => b.value === lastSelectedBuildingID);
        if (matchingBuilding) {
          return matchingBuilding;
        }
      }
      return buildings[0];
    };

    const selectedRooms = this.props.selectedRooms || [];
    const allRooms = this.removeDuplicateRooms(
      selectedRooms.concat(this.fetchRoomsFromProps(this.props.rooms))
    );
    this.state = {
      buildings,
      rooms: allRooms,
      allRooms,
      roomCapacities,
      selectedBuilding: getInitialBuilding(),
      selectedRoomCapacity: getSelectedCapacity(),
      selectedRooms,
      searchText: "",
      recentlySelectedRooms: this.getRecentlyUsedRooms(), // cache this since this could be a heavy operation
      selectedFeatures: [],
    };

    this.selectBuilding = this.selectBuilding.bind(this);
    this.selectRoomCapacity = this.selectRoomCapacity.bind(this);
    this.removeSelectedRoom = this.removeSelectedRoom.bind(this);
    this.onClickSaveButton = this.onClickSaveButton.bind(this);
    this.onTypeInSearch = this.onTypeInSearch.bind(this);
  }

  componentDidMount() {
    const { selectedRoomCapacity } = this.state;
    if (selectedRoomCapacity?.label !== SELECT_ROOM_CAPACITY) {
      this.selectRoomCapacity(selectedRoomCapacity);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /* Update the available rooms */
    if (prevProps.rooms !== this.props.rooms) {
      this.setState({
        rooms: this.fetchRoomsFromProps(this.props.rooms),
      });
    }

    if (
      prevState.rooms !== this.state.rooms ||
      this.props.userEmail !== prevProps.userEmail
    ) {
      // we only construct on open and so the email check is kind of pointless
      // added more for futher proofing
      this.setState({
        recentlySelectedRooms: this.getRecentlyUsedRooms(),
      });
    }
  }

  render() {
    return (
      <div>
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(90vh - 140px)" }}
        >
          {this.renderSearchTextBox()}
          {this.renderBuildingFilter()}
          {this.renderRoomCapacityFilter()}
          {this.renderFeaturesFilter()}
          {this.renderFrequentlyUsedRooms()}
          {this.renderAllRooms()}
          {this.renderAdditionalSpace()}
        </div>

        <div className="flex justify-center w-full mt-4">
          <SaveButton
            tabIndex={4}
            buttonText="Save Rooms"
            style={this.saveButtonStyle()}
            onClick={this.onClickSaveButton}
          />
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderAllRooms() {
    if (this.isWaitingToSelectBuildingForOutlook()) {
      return null;
    }
    const renderSkeleton = (key) => {
      return (
        <LoadingSkeleton
          key={key}
          style={{ width: 420, height: 20, borderRadius: 6 }}
        />
      );
    };

    const SKELETON_CLASS = "flex flex-col mt-2 gap-1";

    const renderAllLoadingSkeleton = () => {
      if (!this.props.isLoading && !this.props.isFetchingPaginatedRooms) {
        return null;
      }
      const matchingBuilding = this.getMatchingBuildingObject();
      const getNumberOfSkeletons = () => {
        const buildingRoomCount = getBuildingConferenceRoomCount(
          matchingBuilding?.buildingObject
        );
        if (!buildingRoomCount) {
          return 0;
        }
        if (buildingRoomCount === this.getAavailableRooms().length) {
          return buildingRoomCount; // none are loaded yet
        }
        return buildingRoomCount - this.getAavailableRooms().length;
      };

      const numberOfSkeletons = getNumberOfSkeletons();
      if (numberOfSkeletons > 0) {
        return (
          <div className={SKELETON_CLASS}>
            {Array.from({ length: numberOfSkeletons }, (_, index) =>
              renderSkeleton(index)
            )}
          </div>
        );
      }

      // Default to 5 skeletons
      return (
        <div className={SKELETON_CLASS}>
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
        </div>
      );
    };

    if (this.props.isLoading && !this.props.isFetchingPaginatedRooms) {
      return (
        <div className="flex items-start mt-4 flex-col">
          <div className="default-font-size secondary-text-color mt-4 mb-2">
            Available Rooms
          </div>
          {renderAllLoadingSkeleton()}
        </div>
      );
    }

    return (
      <div>
        <div className="default-font-size secondary-text-color mt-4 mb-2">
          Available Rooms
        </div>
        {this.renderAvailableRoomsList()}
        {this.props.isFetchingPaginatedRooms ? renderAllLoadingSkeleton() : null}
        {this.renderUnavailableRoomsList()}
      </div>
    );
  }

  renderFrequentlyUsedRooms() {
    if (this.isOutlookCalendar()) {
      return null;
    }
    const { recentlySelectedRooms } = this.state;
    if (isEmptyArrayOrFalsey(recentlySelectedRooms)) {
      return null;
    }
    return (
      <div>
        <div
          className={classNames(
            "default-font-size secondary-text-color ",
            "select-conferece-room-select-wrapper",
            "mt-2"
          )}
        >
          Frequently used
        </div>

        <div className="mt-2">
          {recentlySelectedRooms.map((room) => {
            return (
              <div
                key={`recently_used_room_list_${getConferenceRoomID(room)}`}
                onClick={() =>
                  this.clickOnRoom({
                    value: room,
                    label: getConferenceRoomName(room),
                  })
                }
                className={classNames(
                  "select-conference-room-list",
                  "truncate-text display-block-override"
                )}
              >
                <div className="flex items-center justify-between">
                  {this.renderRoomNameAndCapacity({
                    name: getConferenceRoomName(room),
                    room,
                  })}
                  {this.isRoomSelected(room) ? <Check size={14} /> : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderBuildingFilter() {
    const { isDarkMode } = this.props;
    if (isEmptyArrayOrFalsey(this.state.buildings)) {
      return null;
    }
    return (
      <div className="select-conferece-room-select-wrapper mr-1">
        <div className="default-font-size secondary-text-color">
          Filter by building
        </div>

        <CustomSelect
          tabIndex={2}
          className={classNames(
            "select-meeting-room",
            this.props.isDarkMode ? "dark-mode-select" : ""
          )}
          classNamePrefix="dark-mode-modal"
          value={this.state.selectedBuilding}
          onChange={this.selectBuilding}
          options={this.state.buildings}
          tabSelectsValue={false}
          overrideStyles={getReactSelectBaseStyle({ isDarkMode })}
        />
      </div>
    );
  }

  renderRoomCapacityFilter() {
    const { isDarkMode } = this.props;
    const isDisabled = this.isWaitingToSelectBuildingForOutlook();
    return (
      <div className="select-conferece-room-select-wrapper mr-1">
        <div className="default-font-size secondary-text-color">
          Filter by room capacity
        </div>

        <CustomSelect
          tabIndex={3}
          className={classNames(
            "select-meeting-room",
            this.props.isDarkMode ? "dark-mode-select" : "",
            isDisabled ? "secondary-text-color" : ""
          )}
          classNamePrefix="dark-mode-modal"
          value={this.state.selectedRoomCapacity}
          onChange={this.selectRoomCapacity}
          options={this.state.roomCapacities}
          tabSelectsValue={false}
          overrideStyles={getReactSelectBaseStyle({ isDarkMode })}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  renderFeaturesFilter() {
    /* TODO: Allow feature select to handle whatever custom fields the user has added */
    /* https://learn.microsoft.com/en-us/outlook/troubleshoot/calendaring/configure-room-finder-rooms-workspaces */
    return null;

    return <ConferencingFeatures />;
  }

  renderAdditionalSpace() {
    // to handle dropdown scroll
    const { isLoading } = this.props;
    const unavailableRoomsFiltered = this.getUnavailableRooms();
    const { rooms, recentlySelectedRooms } = this.state;
    if (this.isWaitingToSelectBuildingForOutlook()) {
      return <div className="h-40"></div>;
    }
    if (isLoading) {
      return null;
    }
    const combinedList = (rooms || [])
      .concat(unavailableRoomsFiltered || [])
      .concat(recentlySelectedRooms || []);
    if (combinedList.length === 0) {
      return <div className="h-40"></div>;
    }
    if (combinedList.length <= 3) {
      return <div className="h-32"></div>;
    }
    if (combinedList.length <= 5) {
      return <div className="h-24"></div>;
    }
    if (combinedList.length <= 7) {
      return <div className="h-14"></div>;
    }
    return null;
  }

  renderAvailableRoomsList() {
    const availableRooms = this.getAavailableRooms();
    if (this.props.errorFetchingRooms) {
      return <div className="font-weight-300 default-font-size mb-36">{"Error fetching room availability"}</div>;
    }
    if (availableRooms.length >= 1) {
      return availableRooms.map((room) => {
        return (
          <div
            key={`room_list_${getConferenceRoomID(room.value)}`}
            onClick={() => this.clickOnRoom(room)}
            className={classNames("select-conference-room-list")}
          >
            {this.renderRoomNameAndCapacity({
              name: room.label,
              room: room.value,
              showSelected: true,
            })}
            {this.isRoomSelected(room.value) ? <Check size={14} /> : null}
          </div>
        );
      });
    } else if (this.props.isFetchingPaginatedRooms || this.props.isLoading) {
      return null;
    } else {
      return (
        <div className="font-weight-300 default-font-size">
          {"No available rooms"}
        </div>
      );
    }
  }

  renderUnavailableRoomsList() {
    const unavailableRoomsFiltered = this.getUnavailableRooms();
    if (this.props.errorFetchingRooms) {
      return null;
    }

    if (isEmptyArrayOrFalsey(unavailableRoomsFiltered)) {
      return null;
    }
    if (this.props.isFetchingPaginatedRooms) {
      return null; // do not show unavailable rooms before getting all results back
    }
    return (
      <div className="mt-4">
        <div className="default-font-size secondary-text-color">
          Unavailable Rooms
        </div>
        {unavailableRoomsFiltered.map((room, index) => {
          return (
            <div
              key={`unavailable_room_list_${getConferenceRoomID(
                room
              )}_${index}`}
              className="select-conference-room-unavailable-list secondary-text-color line-through"
            >
              {getConferenceRoomName(room)}
            </div>
          );
        })}
      </div>
    );
  }

  renderSearchTextBox() {
    if (this.isWaitingToSelectBuildingForOutlook()) {
      return null;
    }
    return (
      <div className="select-conference-room-search-wrapper mb-2">
        <Search size={14} />

        <input
          autoFocus
          tabIndex={1}
          type="text"
          spellCheck={true}
          value={this.state.searchText}
          onChange={(e) => this.onTypeInSearch(getInputStringFromEvent(e))}
          placeholder="Search for room or resource"
          className="select-conference-room-search-input default-font-size"
        />
      </div>
    );
  }

  renderRoomNameAndCapacity({ name, room }) {
    const roomCapacity = getRoomCapacity(room);
    return (
      <div className="flex items-center gap-4">
        <div className="truncate max-w-md">{name}</div>
        {isNullOrUndefined(roomCapacity) ? null : (
          <div className="flex gap-2 secondary-text-color items-center">
            <Users size={12} />
            {getRoomCapacity(room)}
          </div>
        )}
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  clickOnRoom(roomValueLabel) {
    if (this.isRoomSelected(roomValueLabel.value)) {
      const updatedSelectedRooms = this.state.selectedRooms.filter(
        (r) =>
          getConferenceRoomID(r.value) !==
          getConferenceRoomID(roomValueLabel.value)
      );
      this.setState({
        selectedRooms: updatedSelectedRooms,
      });
    } else {
      const updatedSelectedRooms =
        this.state.selectedRooms.concat(roomValueLabel);
      this.setState({
        selectedRooms: updatedSelectedRooms,
      });
    }
  }

  removeSelectedRoom(room) {
    const updatedSelectedRooms = this.state.selectedRooms.filter(
      (r) =>
        getConferenceRoomName(r.value) !== getConferenceRoomName(room.value)
    );

    const updatedRooms = this.state.rooms.concat(room);

    this.setState({
      selectedRooms: updatedSelectedRooms,
      rooms: this.sortRooms(updatedRooms),
    });
  }

  sortRooms(rooms) {
    if (isEmptyArrayOrFalsey(rooms)) {
      return [];
    }
    return immutablySortArray(rooms, (a, b) =>
      getConferenceRoomName(a?.value).localeCompare(
        getConferenceRoomName(b?.value)
      )
    );
  }

  selectBuilding(option) {
    const {
      setLastSelectedBuildingID,
    } = this.props.conferenceRoomStore;
    const buildingID = option?.value;
    setLastSelectedBuildingID(buildingID);

    const { searchText } = this.state;
    const building = this.state.selectedRoomCapacity?.value;
    const updatedRooms = this.filterRooms(buildingID, building);
    const rooms = this.filterRoomsByText({ rooms: updatedRooms, searchText });
    if (this.isOutlookCalendar()) {
      eventFormBroadcast.publish(
        EVENT_FORM_BROADCAST_VALUES.FETCH_AVAILABLE_ROOMS,
        { roomListId: option.value, isFromSelectBuilding: true }
      );
    }
    this.setState({
      rooms: this.sortRooms(rooms),
      selectedBuilding: option,
    });
  }

  selectRoomCapacity(option) {
    const { searchText } = this.state;
    const { selectedBuilding } = this.state;
    const capacity = option?.value;
    const updatedRooms = this.filterRooms(selectedBuilding?.value, capacity);
    const rooms = this.filterRoomsByText({ rooms: updatedRooms, searchText });

    this.setState({
      rooms: this.sortRooms(rooms),
      selectedRoomCapacity: option,
    });
  }

  //=================
  // PRIVATE METHODS
  //=================

  saveButtonStyle() {
    return {
      width: 300,
      marginLeft: 15,
      height: StyleConstants.saveButtonHeight,
      marginBottom: 0,
      marginTop: 0,
    };
  }

  onTypeInSearch(input) {
    let updatedRooms = this.filterRooms(
      this.state.selectedBuilding?.value,
      this.state.selectedRoomCapacity?.value
    );

    const rooms = this.filterRoomsByText({
      rooms: updatedRooms,
      searchText: input,
    });
    this.setState({
      searchText: input,
      rooms: this.sortRooms(rooms),
    });
  }

  filterRoomsByText({ rooms, searchText }) {
    if (!searchText) {
      return rooms || [];
    }
    if (isEmptyArrayOrFalsey(rooms)) {
      return [];
    }
    const loweredCaseInput = lowerCaseAndTrimStringWithGuard(searchText);
    const result = rooms.filter(
      (r) =>
        r.value === SELECT_ROOM ||
        lowerCaseAndTrimStringWithGuard(r.label).includes(loweredCaseInput)
    );
    return result;
  }

  onClickSaveButton() {
    const { selectedRooms } = this.state;
    this.storeSelectedConferenceRoomsIntoStore();
    this.props.onClick(selectedRooms);
  }

  storeSelectedConferenceRoomsIntoStore() {
    const { selectedRooms } = this.state;
    const { userEmail } = this.props;
    const { setRecentlyUsedConferenceRooms } = this.props.conferenceRoomStore;
    setRecentlyUsedConferenceRooms({
      rooms: selectedRooms.map((selected) => selected.value),
      userEmail,
    });
  }

  filterRooms(buildingFilter, capacityFilter) {
    const filteredForBuilding = this.filterByBuilding(buildingFilter);

    const filteredForCapacity = this.filterForCapacity(
      capacityFilter,
      filteredForBuilding
    );

    return this.filterForClickedOnRooms(filteredForCapacity);
  }

  filterByBuilding(buildingFilter) {
    const matchingBuilding = !this.isSelectBuilding(buildingFilter)
      ? this.props.rooms.filter(
          (e) => buildingFilter && e?.buildingId === buildingFilter
        )
      : this.props.rooms;
    if (!matchingBuilding || matchingBuilding.length === 0) {
      return [];
    }

    let updatedRoomsInBuildings = [];

    matchingBuilding.forEach((r) => {
      updatedRoomsInBuildings = updatedRoomsInBuildings.concat({
        label: getConferenceRoomName(r),
        value: r,
      });
    });

    return updatedRoomsInBuildings;
  }

  isSelectBuilding(value) {
    return value === ALL_BUILDINGS || value === SELECT_BUILDING;
  }

  filterForCapacity(capacityFilter, filteredRooms = null) {
    const rooms = filteredRooms || this.state.allRooms;
    return capacityFilter === SELECT_ROOM_CAPACITY
      ? rooms
      : rooms.filter((room) => room?.value?.capacity >= capacityFilter);
  }

  createRoomCapacityArray() {
    return [
      SELECT_ROOM_CAPACITY_OPTION,
      ONE_OR_MORE_PEOPLE,
      TWO_OR_MORE_PEOPLE,
      THREE_OR_MORE_PEOPLE,
      FOUR_OR_MORE_PEOPLE,
      FIVE_OR_MORE_PEOPLE,
      TEN_OR_MORE_PEOPLE,
      TWENTY_OR_MORE_PEOPLE,
    ];
  }

  filterForClickedOnRooms(roomArray, selectedRooms = null) {
    if (isEmptyArrayOrFalsey(roomArray)) {
      return [];
    }

    let roomIDArray = [];
    let selectedRoomsArray;

    if (selectedRooms) {
      selectedRoomsArray = selectedRooms;
    } else if (this.state) {
      selectedRoomsArray = this.state.selectedRooms;
    } else {
      selectedRoomsArray = [];
    }

    if (isEmptyArrayOrFalsey(selectedRoomsArray)) {
      return roomArray;
    }

    selectedRoomsArray.forEach((room) => {
      roomIDArray = roomIDArray.concat(getConferenceRoomName(room.value));
    });

    const updatedRooms = roomArray.filter(
      (room) => !roomIDArray.includes(getConferenceRoomName(room?.value))
    );

    return updatedRooms;
  }

  getMatchingBuildingObject() {
    const { selectedBuilding } = this.state;
    const { buildings } = this.props;
    if (isEmptyArrayOrFalsey(buildings)) {
      return null;
    }
    const isOutlookCalendar = this.isOutlookCalendar();
    return isOutlookCalendar
      ? buildings.find((b) => getBuildingIDFromRawObject(b?.buildingObject) === selectedBuilding?.value)
      : buildings.find(
          (b) => getBuildingNameFromRawObject(b?.buildingObject) === selectedBuilding?.value
        );
  }

  createBuilding(buildings) {
    const isOutlookCalendar = this.isOutlookCalendar();
    const defaultOption = isOutlookCalendar
      ? { label: SELECT_BUILDING, value: SELECT_BUILDING }
      : { label: ALL_BUILDINGS, value: ALL_BUILDINGS };

    if (isEmptyArrayOrFalsey(buildings)) {
      return [defaultOption];
    }

    const getLabel = (building) => {
      // {
      //   "label": "Hong-Kong",
      //   "value": "Hong-Kong"
      // }
      return building?.label || building?.value || "";
    };

    const availableBuildings = immutablySortArray(
      buildings.map((building) => ({
        label: getBuildingNameFromRawObject(building.buildingObject),
        // We need the room list id for fetching room availability
        value: isOutlookCalendar
          ? getBuildingIDFromRawObject(building.buildingObject)
          : getBuildingNameFromRawObject(building.buildingObject),
      })),
      (a, b) => getLabel(a).localeCompare(getLabel(b))
    );

    return [defaultOption, ...availableBuildings];
  }

  getRecentlyUsedRooms() {
    const { userEmail } = this.props;
    const { recentlyUsedConferenceRooms } = this.props.conferenceRoomStore;
    const matchingUserRooms = recentlyUsedConferenceRooms[userEmail] ?? [];
    const filteredRooms = this.filterRoomsByAvailableRooms(matchingUserRooms);
    return immutablySortArray(filteredRooms, (a, b) =>
      getConferenceRoomName(a).localeCompare(getConferenceRoomName(b))
    );
  }

  filterRoomsByAvailableRooms(inputRooms) {
    const { unavailableRooms } = this.props;
    const rooms = this.state?.rooms ?? this.fetchRoomsFromProps(inputRooms);
    if (isEmptyArrayOrFalsey(rooms)) {
      return [];
    }
    const unavailableRoomsWithGuard = unavailableRooms || [];
    const unavailableRoomIDs = new Set(
      unavailableRoomsWithGuard.map((room) => getConferenceRoomID(room))
    );
    const availableRoomIDs = new Set(
      rooms
        .filter(
          (room) =>
            room.value !== SELECT_ROOM &&
            !unavailableRoomIDs.has(getConferenceRoomID(room.value))
        )
        .map((room) => getConferenceRoomID(room.value))
    );
    return inputRooms.filter((room) =>
      availableRoomIDs.has(getConferenceRoomID(room))
    );
  }

  isRoomSelected(room) {
    const { selectedRooms } = this.state;
    const selectedRoomIDs = selectedRooms
      ?.filter((room) => room.label !== SELECT_ROOM)
      .map((r) => getConferenceRoomID(r.value));
    return selectedRoomIDs?.includes(getConferenceRoomID(room));
  }

  fetchRoomsFromProps(rooms) {
    let availableRooms = [{ label: SELECT_ROOM, value: SELECT_ROOM }];
    if (isEmptyArrayOrFalsey(rooms)) {
      return availableRooms;
    }
    if (rooms) {
      rooms.forEach((r) => {
        availableRooms = availableRooms.concat({
          label: getConferenceRoomName(r),
          value: r,
        });
      });
    }

    return availableRooms;
  }

  getAavailableRooms() {
    const { rooms } = this.state;
    if (isEmptyArrayOrFalsey(rooms)) {
      return [];
    }
    return rooms.filter((room) => room.label !== SELECT_ROOM);
  }

  getUnavailableRooms() {
    const { unavailableRooms } = this.props;
    if (isEmptyArrayOrFalsey(unavailableRooms)) {
      return [];
    }
    const { selectedRoomCapacity, selectedBuilding } = this.state;
    const filteredUnavailableRooms = unavailableRooms.filter((room) => {
      if (this.isWaitingToSelectBuildingForOutlook()) {
        return false;
      }
      if (
        (!this.isSelectBuilding(selectedBuilding?.value) &&
          getRoomBuildingID(getRoomsObject(room)) !==
            selectedBuilding?.value) ||
        (selectedRoomCapacity?.value !== SELECT_ROOM_CAPACITY &&
          getRoomCapacity(room) < selectedRoomCapacity?.value)
      ) {
        return false;
      }

      return true;
    });
    return filteredUnavailableRooms;
  }

  removeDuplicateRooms(rooms) {
    const uniqueRooms = [];
    const roomIDs = [];
    rooms.forEach((room) => {
      const roomID = getConferenceRoomID(room.value);
      if (!roomIDs.includes(roomID)) {
        uniqueRooms.push(room);
        roomIDs.push(roomID);
      }
    });
    return uniqueRooms;
  }

  isOutlookCalendar() {
    const { calendar } = this.props;
    return isCalendarOutlookCalendar(calendar);
  }

  isWaitingToSelectBuildingForOutlook() {
    const { selectedBuilding } = this.state;
    return (
      this.isOutlookCalendar() && this.isSelectBuilding(selectedBuilding?.value)
    );
  }
}

function mapStateToProps(state) {
  const { currentTimeZone, currentUser, isDarkMode } = state;

  return { currentTimeZone, currentUser, isDarkMode };
}

// eslint-disable-next-line
const withStore = (BaseComponent) => (props) => {
  const conferenceRoomStore = useConferenceRoomStore();

  return <BaseComponent {...props} conferenceRoomStore={conferenceRoomStore} />;
};

export default connect(mapStateToProps)(withStore(SelectConferenceRoom));
