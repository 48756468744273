import React from "react";
import { components } from "react-select";
import { ChevronDown } from "react-feather";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown size={props?.size ? props?.size : 14} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
