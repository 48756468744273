import { delayByMs } from "../../lib/asyncFunctions";
import { GOOGLE_RESOURCE_ENDPOINTS, OUTLOOK_RESOURCE_ENDPOINTS } from "../../lib/endpoints";
import { getUserEmail } from "../../lib/userFunctions";
import { constructRequestURLV2, isErrorResponse } from "../../services/api";
import { constructQueryParams } from "../../services/commonUsefulFunctions";
import { fetcherGet } from "../../services/fetcherFunctions";
import { SECOND_IN_MS } from "../../services/globalVariables";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";

export interface OutlookDistroListMember {
  provider_id: string
  email: string
  display_name: string | null
}

export interface OutlookDistroList {
  provider_id: string
  name: string | null
  email: string
  description: string | null
  members: OutlookDistroListMember[]
  subgroups: OutlookDistroListMember[]
}

/**
 * In addition to fetching the distribution lists from our DB, this will have the backend start a worker to
 * asynchronously refresh the records in our DB if the records were not recently synced.
 */
export async function fetchOutlookDistributionLists(user: User) {
  const url = constructRequestURLV2(OUTLOOK_RESOURCE_ENDPOINTS.GET_DISTRO_LISTS);
  const response = await fetcherGet<{ outlook_groups: OutlookDistroList[] }>({ url, email: getUserEmail(user) });

  if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
    return [];
  }

  return response.outlook_groups;
}

export interface GoogleDistroListMember {
  email: string | null
  role: "MANAGER" | "MEMBER" | "OWNER"
  member_type: "CUSTOMER" | "EXTERNAL" | "GROUP" | "USER"
  status: string | null
  google_id: string
}

export interface GoogleDistroList {
  google_id: string
  email: string
  name: string
  description: string
  aliases: string[] | null
  direct_members_count: number
  google_group_members: GoogleDistroListMember[]
}

type GoogleDistroListResponse = {
  google_groups: GoogleDistroList[],
  has_more: boolean
}

export async function fetchGoogleDistributionLists(user: User) {

  const googleGroups: GoogleDistroList[] = [];
  let page = 1;
  let hasMore = true;
  const maxPages = 100;

  while (hasMore && page <= maxPages) {
    const queryParams = constructQueryParams({ page });
    const path = `${GOOGLE_RESOURCE_ENDPOINTS.LIST_DISTRO_GROUPS}?${queryParams}`;
    const url = constructRequestURLV2(path);
    const response = await fetcherGet<GoogleDistroListResponse | ErrorResponse>({ url, email: getUserEmail(user) });

    if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      hasMore = false;
      break;
    }

    googleGroups.push(...response.google_groups);
    page++;
    hasMore = response.has_more;
    if (hasMore) {
      await delayByMs(SECOND_IN_MS);
    }
  }

  return googleGroups;
}
