import { useEffect, useRef } from "react";

/* Let's us keep track of the previous state when using hooks or things of the like */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
