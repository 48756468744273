import React, { useEffect, useRef, useState } from "react";

import CustomButton from "../customButton";
import Broadcast from "../../broadcasts/broadcast";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import {
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
} from "../../lib/calendarFunctions";
import {
  getGoogleEventId,
  KEYCODE_DOWN_ARROW,
  KEYCODE_UP_ARROW,
} from "../../services/commonUsefulFunctions";
import { useAllCalendars } from "../../services/stores/SharedAccountData";
import { getEventUserCalendarID } from "../../services/eventResourceAccessors";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import classNames from "classnames";
import RadioButton from "../radioButton";

const deleteHoldOptions = {
  DELETE_SINGLE_HOLD: "This hold",
  DELETE_ALL_HOLDS: "All holds",
};

const DeleteHoldRadioButtons = ({ deleteType, setDeleteType }) => {
  return Object.keys(deleteHoldOptions).map((key, index) => {
    const isSelected = deleteType === deleteHoldOptions[key];
    return (
      <div
        key={`renderUpdateRecurringEventChoices_${index}`}
        className="round-button-text-wrapper"
        onClick={() => setDeleteType(deleteHoldOptions[key])}
      >
        <RadioButton isSelected={isSelected} />
        <div
          className={classNames(
            "margin-left-five cursor-pointer select-none font-size-14",
            "default-font-color"
          )}
        >
          {deleteHoldOptions[key]}
        </div>
      </div>
    );
  });
};

const DeleteHoldEvent = ({ closeModal, event }) => {
  const [deleteType, setDeleteType] = useState(
    deleteHoldOptions.DELETE_SINGLE_HOLD
  );
  const deleteTypeRef = useRef(deleteHoldOptions.DELETE_SINGLE_HOLD);

  const saveDeleteType = (type) => {
    deleteTypeRef.current = type;
    setDeleteType(type);
  };

  const { allCalendars } = useAllCalendars();

  const componentIsMounted = useIsMounted();

  const onKeyDown = (e) => {
    if (!componentIsMounted.current || !e) {
      return;
    }
    const { keyCode } = e;

    if (keyCode !== KEYCODE_UP_ARROW && keyCode !== KEYCODE_DOWN_ARROW) {
      return;
    }

    if (deleteTypeRef.current === deleteHoldOptions.DELETE_SINGLE_HOLD) {
      saveDeleteType(deleteHoldOptions.DELETE_ALL_HOLDS);
    } else {
      saveDeleteType(deleteHoldOptions.DELETE_SINGLE_HOLD);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const onSubmit = () => {
    /* Delete a single hold event */
    if (deleteType === deleteHoldOptions.DELETE_SINGLE_HOLD) {
      Broadcast.publish(
        "DELETE_EVENT",
        event,
        {
          calendar_provider_id: getEmailFromUserCalendarID(
            getEventUserCalendarID(event),
            allCalendars
          ),
          event_provider_id: getGoogleEventId(event),
          sendUpdates: false,
          userEmail: getUserEmailFromEvent(event, allCalendars),
          message: "",
        },
        true,
      );
    } else {
      /* Delete all hold events */
      Broadcast.publish(
        "DELETE_ALL_HOLD_EVENTS",
        event,
        {
          google_calendar_id: getEmailFromUserCalendarID(
            getEventUserCalendarID(event),
            allCalendars,
          ),
          userEmail: getUserEmailFromEvent(event, allCalendars),
        },
      );
    }

    closeModal();
  };

  return (
    <div className="margin-top-twenty">
      <DeleteHoldRadioButtons
        deleteType={deleteType}
        setDeleteType={saveDeleteType}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={closeModal}
          label="Cancel"
          addPaddingToRight={true}
        />

        <CustomButton
          buttonType={BLUE_BUTTON}
          shouldFocus={true}
          onClick={onSubmit}
          label="OK"
        />
      </div>
    </div>
  );
};

export default DeleteHoldEvent;
