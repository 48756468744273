import React from "react";
import ContactHandle, { type ContactHandleProps } from "../contactHandle";
import { Youtube } from "react-feather";

export default function YouTubeHandle(props: ContactHandleProps) {
  return (
    <ContactHandle {...props}>
      <Youtube size={12} className="full-attendee-list-attendee-handle" />
    </ContactHandle>
  )
}
