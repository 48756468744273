import classNames from "classnames";
import React from "react";
import { createPortal } from "react-dom";

import { getModalBackgroundColor } from "../../../services/globalVariables";
import CalendarAuditReady from "../calendarAuditReady";

export default function CalendarAuditReadyModal({ isDarkMode, onClose }) {
  return createPortal(
    <div
      className={classNames(
        "fixed bottom-8 left-8 z-10",
        "large-blur",
        "rounded-lg",
        "default-bottom-left-modal-border",
      )}
      style={{
        width: "calc(287px + 2rem)",
        backgroundColor: getModalBackgroundColor(isDarkMode),
      }}
    >
      <CalendarAuditReady onClose={onClose} />
    </div>,
    document.body,
  );
}
