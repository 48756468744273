import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSavedTeamInviteToken, isAccountPrepaid } from "../../lib/stateManagementFunctions";
import { pluralize } from "../../lib/stringFunctions";
import { getTeamPlanOwnerEmail, getTeamPlanOwnerName, getTeamPlanTeamName } from "../../lib/teamPlanFunctions";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyArray, isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined } from "../../services/typeGuards";
import CustomButtonV2 from "../buttons/customButtonV2";
import { checkDomainCapture, joinByDomain } from "../queries/teamPlans";
import OnboardingSkipButton from "./onboardingSkipButton";
import OnboardingSpinner from "./onboardingSpinner";

interface JoinTeamPlanByDomainProps {
  onJoin: () => void
  onSkip: () => void
  setTeamPlansFromDomainCapture: StateSetter<TruncatedTeamPlan[] | undefined>
  teamPlansFromDomainCapture?: TruncatedTeamPlan[]
  teamPlanInvites?: TeamPlanInvite[]
}

export default function JoinTeamPlanByDomain({
  onJoin,
  onSkip,
  setTeamPlansFromDomainCapture,
  teamPlansFromDomainCapture,
  teamPlanInvites,
}: JoinTeamPlanByDomainProps) {
  const currentUser = useSelector(state => state.currentUser);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const [tokenOfJoinedTeam, setTokenOfJoinedTeam] = useState<string>();
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const savedTeamInviteToken = getSavedTeamInviteToken();

  useEffect(() => {
    if (
      // The user has clicked an invite link to join a team plan and should not be prompted
      // to join other team plans.
      savedTeamInviteToken ||
      // Users can only be on one team at a time.
      !isEmptyObjectOrFalsey(teamPlan) ||
      // If the user already is invited to another team plan, don't prompt them
      // to join one via domain capture.
      isAccountPrepaid(masterAccount) ||
      !isEmptyArrayOrFalsey(teamPlanInvites)
    ) {
      onSkip();
    }
  }, [masterAccount, savedTeamInviteToken, teamPlan, teamPlanInvites]);

  useEffect(() => {
    // Don't trigger for falsey values. This variable is undefined before
    // the query finishes fetching.
    if (isEmptyArray(teamPlansFromDomainCapture)) {
      onSkip();
      return;
    }

    if (isNullOrUndefined(teamPlansFromDomainCapture) && currentUser) {
      // The team plans should have already been fetched by now. Refetch as a
      // fallback if the array has not yet been set.
      checkDomainCapture(currentUser).then(response => {
        if (isEmptyArrayOrFalsey(response)) {
          onSkip();
          return;
        }

        setTeamPlansFromDomainCapture(response);
      });
    }
  }, [teamPlansFromDomainCapture]);

  const handleJoinClick = async (token: string) => {
    if (!currentUser) {
      return;
    }

    setTokenOfJoinedTeam(token);
    try {
      await joinByDomain(currentUser, token);
      onJoin();
    } catch (e) {
      setTokenOfJoinedTeam(undefined);
    }
  };

  if (isEmptyArrayOrFalsey(teamPlansFromDomainCapture)) {
    return <OnboardingSpinner />;
  }

  return (
    <div className="onboarding-container">
      <div className="section-title text-center">
        Join your team on Vimcal
      </div>
      <div className="welcome-subtitle mt-3 mb-12">
        We found a
        {teamPlansFromDomainCapture.length === 1 ? " team " : " few teams "}
        from your organization that you may request to join.
      </div>

      <div className="w-full max-w-lg px-4 flex flex-col gap-5 mb-6 overflow-auto" style={{ maxHeight: "50vh" }}>
        {teamPlansFromDomainCapture.map(teamPlan => (
          <TeamOption
            key={teamPlan.token}
            tokenOfJoinedTeam={tokenOfJoinedTeam}
            handleJoinClick={handleJoinClick}
            teamPlan={teamPlan}
          />
        ))}
      </div>

      <OnboardingSkipButton onClickNext={onSkip} />
    </div>
  );
}

interface TeamOptionProps {
  handleJoinClick: (token: string) => void
  teamPlan: TruncatedTeamPlan
  tokenOfJoinedTeam: string | undefined
}

function TeamOption({ handleJoinClick, teamPlan, tokenOfJoinedTeam }: TeamOptionProps) {
  const ownerUserFullName = getTeamPlanOwnerName(teamPlan);
  const ownerUserEmail = getTeamPlanOwnerEmail(teamPlan);
  const memberCount = teamPlan.member_count;

  return (
    <div className="domain-capture-option flex justify-between items-center px-5 py-3 rounded-lg border border-solid">
      <div>
        <div className="default-font-size">
          {getTeamPlanTeamName(teamPlan) || `${ownerUserFullName || ownerUserEmail}'s team plan`}
        </div>
        <div className="font-size-11 secondary-text-color">
          <span>{memberCount} {pluralize(memberCount, "member")}</span>
          {ownerUserFullName ? (
            <>
              <span className="mx-2">&middot;</span>
              <span>Created by {ownerUserFullName}</span>
            </>
          ) : null}
        </div>
        {ownerUserFullName ? null : (
          <div>Created by {ownerUserEmail}</div>
        )}
      </div>
      <div>
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          disabled={!!tokenOfJoinedTeam}
          shouldRenderSpinner={tokenOfJoinedTeam === teamPlan.token}
          label="Join"
          onClick={() => handleJoinClick(teamPlan.token)}
        />
      </div>
    </div>
  );
}
