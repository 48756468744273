import { formatISO, subYears } from "date-fns";
import create from "zustand";
import { persist } from "zustand/middleware";
import { isEmptyObjectOrFalsey } from "../typeGuards";
import produce from "immer";

// TODO: add typescript

export const useGroupVoteStore = create(
  persist(
    (set, get) => ({
      // Group Vote Links
      groupVoteLinks: [],
      setGroupVoteLinks: (bookingLinks) =>
        set((state) => ({ groupVoteLinks: bookingLinks })),
      groupVoteDictionary: {}, // {userEmail: groupVoteLinks}
      setGroupVoteDictionary: (groupVoteDictionary) => {
        set((state) => ({ groupVoteDictionary }));
      },
      groupVoteSpreadsheetDictionary: {}, // {userEmail: groupSpreadsheetLinks}
      setGroupVoteSpreadsheetDictionary: (groupVoteSpreadsheetDictionary) => {
        set((state) => ({ groupVoteSpreadsheetDictionary }));
      },
      setGroupVoteLinkByToken: (bookingLink) => {
        const groupVoteLinks = get().groupVoteLinks;
        const updatedGroupVoteLinks = produce(groupVoteLinks, (draftLinks) => {
          const index = draftLinks.findIndex(
            (link) => link.token === bookingLink.token
          );
          if (index === -1) {
            draftLinks.push(bookingLink);
          } else {
            draftLinks[index] = bookingLink;
          }
        });
        set(() => ({ groupVoteLinks: updatedGroupVoteLinks }));
      },
      removeGroupVoteLinkByToken: (token) => {
        const originalLinks = get().groupVoteLinks;
        const filteredLinks = originalLinks.filter((l) => l.token !== token);
        if (originalLinks.length !== filteredLinks.length) {
          set(() => ({ groupVoteLinks: filteredLinks }));
        }
      },

      // Group Spreadsheet Links
      groupSpreadsheetLinks: [],
      setGroupSpreadsheetLinkByToken: (bookingLink) => {
        const groupSpreadsheetLinks = get().groupSpreadsheetLinks;
        const updatedGroupSpreadsheetLinks = produce(
          groupSpreadsheetLinks,
          (draftLinks) => {
            const index = draftLinks.findIndex(
              (link) => link.token === bookingLink.token
            );
            if (index === -1) {
              draftLinks.push(bookingLink);
            } else {
              draftLinks[index] = bookingLink;
            }
          }
        );
        set(() => ({ groupSpreadsheetLinks: updatedGroupSpreadsheetLinks }));
      },
      removeGroupSpreadsheetLinkByToken: (token) => {
        const originalLinks = get().groupSpreadsheetLinks;
        const filteredLinks = originalLinks.filter((l) => l.token !== token);
        if (originalLinks.length !== filteredLinks.length) {
          set(() => ({ groupSpreadsheetLinks: filteredLinks }));
        }
      },

      // Common
      setGroupLinks: ({ groupVoteLinks, groupSpreadsheetLinks }) => {
        const newState = {};

        if (groupVoteLinks) {
          newState.groupVoteLinks = groupVoteLinks;
        }

        if (groupSpreadsheetLinks) {
          newState.groupSpreadsheetLinks = groupSpreadsheetLinks;
        }

        if (!isEmptyObjectOrFalsey(newState)) {
          set(() => newState);
        }
      },
      resetAllGroupLinks: () =>
        set(() => ({
          groupVoteLinks: [],
          groupSpreadsheetLinks: [],
          groupVoteDictionary: {},
          groupVoteSpreadsheetDictionary: {}
        })),
    }),
    {
      name: "group-vote-links-storage", // unique name
    }
  )
);

export const SLOTS_VERSION = {
  V1: 1,
  V2: 2, // added time zone in line as a date & time setting
};
export const LATEST_SLOTS_VERSION = SLOTS_VERSION.V2;

export const useAppSettings = create(
  persist(
    (set) => ({
      slotsVersion: null,
      setSlotsVersion: (slotsVersion) => {
        set((state) => ({ slotsVersion }));
      },
      slotsTextStyles: [],
      setSlotsTextStyles: (slotsTextStyles) => {
        set((state) => ({ slotsTextStyles }));
      },
      isSplitView: false,
      setIsSplitView: (isSplitView) =>
        set((state) => ({ isSplitView })),
      lastUpdatedTime: formatISO(subYears(new Date(), 10)),
      isHideMonthlyCalendar: false,
      lastSelectedSlotsStyle: null,
      setLastSelectedSlotsStyle: (slotsStyle) => {
        set((state) => ({ lastSelectedSlotsStyle: slotsStyle }));
      },
      shouldShowAllTimeZonesInSlots: null,
      setShouldShowAllTimeZonesInSlots: (shouldShowAllTimeZonesInSlots) => {
        set((state) => ({ shouldShowAllTimeZonesInSlots }));
      },
      setIsHideMonthlyCalendar: (isHideMonthlyCalendar) =>
        set((state) => ({ isHideMonthlyCalendar })),
      setLastUpdatedTime: (lastUpdatedTime) =>
        set((state) => ({ lastUpdatedTime })),
      isShortendWindow: false,
      setIsShortendWindow: (isShortendWindow) =>
        set((state) => ({ isShortendWindow })),
      resetIsShortendWindow: () =>
        set((state) => ({ isShortendWindow: false })),
      resetSettings: () => {
        set((state) => ({
          isShortendWindow: false,
          isHideMonthlyCalendar: false,
          lastUpdatedTime: formatISO(subYears(new Date(), 10)),
          isSplitView: false,
          lastSelectedSlotsStyle: null,
          shouldShowAllTimeZonesInSlots: null,
          slotsVersion: null,
        }));
      },
    }),
    {
      name: "settings-storage", // unique name
    }
  )
);
