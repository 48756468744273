// This is not an extensive list and more types may need to be added
// https://developers.google.com/drive/api/guides/ref-export-formats
export const SUPPORTED_DRIVE_TYPES = [
  "application/epub+zip", // .epub
  "application/pdf", // .pdf
  "application/rtf", // .rtf
  "application/vnd.google-apps.script+json", // .json
  "application/vnd.google-apps.vid", // .mp4
  "application/vnd.oasis.opendocument.presentation", // .odp
  "application/vnd.oasis.opendocument.text", // .odt
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/x-vnd.oasis.opendocument.spreadsheet", // .ods
  "application/x-zip-compressed", // .zip
  "application/zip", // .zip
  "image/gif", // .gif
  "image/jpeg", // .jpg
  "image/png", // .png
  "image/svg+xml", // .svg
  "text/csv", // .csv
  "text/markdown", // .md
  "text/plain", // .txt
  "text/tab-separated-values", // .tsv
  "video/mp4", // .mp4
  "video/mpeg", // .mpeg
  "video/quicktime", // .mov
];

export function isDriveFile(obj: any): obj is DriveFile {
  return (
    obj?.fileUrl !== undefined &&
    obj?.iconLink !== undefined &&
    obj?.mimeType !== undefined &&
    obj?.title !== undefined
  );
}
