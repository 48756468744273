import React, { useCallback, useEffect, useMemo, useState } from "react";
import ColoredLine from "../../line";
import StyleConstants from "../../../services/globalVariables";
import SpinnerV2 from "../../spinnerV2";
import { Section, ZoomCheckboxSetting, ZoomRadioSetting, ZoomSwitchSetting } from "./inputComponents";
import {
  ZOOM_AUDIO_OPTIONS,
  ZOOM_AUTO_RECORDING_OPTIONS,
  ZoomSettingsState,
  buildPartialZoomMeetingFromSettings,
  generateNewPassword,
  getInitialValues,
  haveSettingsChanged,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  makeZoomIdReadable,
} from "./sharedFunctions";
import { calculateMarginTop, hasEventPreventDefault } from "../../../services/commonUsefulFunctions";
import { patchZoomMeetingByZoomId } from "../../queries/zoomMeetings";
import SaveButton from "../../saveButton";
import { ChevronLeft } from "react-feather";
import { AutoRecordingLocationSetting } from "./autoRecordingLocationSetting";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PasswordInput } from "./passwordInput";
import { KEYBOARD_EVENT_KEYS } from "../../../services/keyboardEventFunctions";
import EventModalPopup from "../../eventModalPopup";
import { DiscardChangesContents } from "../discardChangesContents";
import { useSelector } from "react-redux";
import GenericErrorModalContent from "../../genericErrorModalContent";
import { determineDefaultModalStyle } from "../../../lib/modalFunctions";

// TODO: Move to sharedFunctions once circular dependencies are resolved and it doesn't break tests.
async function submitChanges(email: string, zoomMeeting: ZoomMeeting, updatedSettings: ZoomSettingsState) {
  const partialZoomMeeting = buildPartialZoomMeetingFromSettings(updatedSettings, zoomMeeting.host_email);
  return await patchZoomMeetingByZoomId(email, zoomMeeting.id, partialZoomMeeting);
}

type ZoomSettingsProps =
  Pick<ZoomSettingsFormProps, "authedZoomEmail" | "onClose"> &
  { zoomMeeting: ZoomMeeting | null };

export default function ZoomSettings({ authedZoomEmail, onClose, zoomMeeting }: ZoomSettingsProps) {
  const [haveChangesBeenMade, setHaveChangesBeenMade] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const isDarkMode = useSelector(state => state.isDarkMode);
  const shouldShowTopBar = useSelector(state => state.shouldShowTopBar);

  const onClickClose = useCallback(() => {
    if (haveChangesBeenMade) {
      setIsConfirmModalOpen(true);
    } else {
      onClose();
    }
  }, [haveChangesBeenMade]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === KEYBOARD_EVENT_KEYS.ESCAPE) {
        onClickClose();
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClickClose]);

  return (
    <div className="flex-grow overflow-y-auto pb-16">
      <div
        className="font-size-18 font-medium pl-3.5 pr-5 pt-5 pb-3 flex items-center gap-2"
        style={{ marginTop: calculateMarginTop(shouldShowTopBar) }}
      >
        <ChevronLeft
          className="clickable-icon"
          onClick={onClickClose}
        />
        Zoom Settings
      </div>
      {zoomMeeting
        ? (
          <ZoomSettingsForm
            authedZoomEmail={authedZoomEmail}
            haveChangesBeenMade={haveChangesBeenMade}
            onClose={onClose}
            setHaveChangesBeenMade={setHaveChangesBeenMade}
            zoomMeeting={zoomMeeting}
          />
        ) : <ZoomSettingsLoader />}
      <EventModalPopup
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        title="Discard unsaved Zoom settings?"
        width={420}
        style={determineDefaultModalStyle(isDarkMode)}
      >
        <DiscardChangesContents
          onCancel={() => setIsConfirmModalOpen(false)}
          onDiscard={() => onClose()}
        />
      </EventModalPopup>
    </div>
  );
}

function ZoomSettingsLoader() {
  return (
    <div className="flex justify-center items-center mt-10">
      <SpinnerV2 />
    </div>
  );
}

interface ZoomSettingsFormProps {
  authedZoomEmail: string
  haveChangesBeenMade: boolean
  setHaveChangesBeenMade: React.Dispatch<React.SetStateAction<boolean>>
  onClose: (updatedZoomMeeting?: ZoomMeeting | null) => void
  zoomMeeting: ZoomMeeting
}

/**
 * Don't render this until the Zoom meeting has finished loading so we can initialize
 * state properly.
 *
 * TODO: For now, the password and use PMI fields are hidden. The logic for these is built-out already,
 * but these two settings change the join URL which requires us to edit the description of the event.
 * Changing the description is currently buggy so for now it's safest to keep the same join URL.
 * The logic for these fields is commented out. Once we bring back in, be sure to remove the
 * eslint-disable-next-line statements.
 */
function ZoomSettingsForm({ authedZoomEmail, haveChangesBeenMade, onClose, setHaveChangesBeenMade, zoomMeeting }: ZoomSettingsFormProps) {
  const initialValues = getInitialValues(zoomMeeting);
  const [audio, setAudio] = useState(initialValues.audio);
  const [autoRecording, setAutoRecording] = useState(initialValues.autoRecording);
  const [autoStartMeetingSummary, setAutoStartMeetingSummary] = useState(initialValues.autoStartMeetingSummary);
  const [autoStartAICompanionQuestions, setAutoStartAICompanionQuestions] = useState(initialValues.autoStartAICompanionQuestions);
  const [continuousMeetingChat, setContinuousMeetingChat] = useState(initialValues.continuousMeetingChat);
  const [hostVideo, setHostVideo] = useState(initialValues.hostVideo);
  const [joinBeforeHost, setJoinBeforeHost] = useState(initialValues.joinBeforeHost);
  const [meetingAuthentication, setMeetingAuthentication] = useState(initialValues.meetingAuthentication);
  const [muteUponEntry, setMuteUponEntry] = useState(initialValues.muteUponEntry);
  const [participantVideo, setParticipantVideo] = useState(initialValues.participantVideo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passwordEnabled, setPasswordEnabled] = useState(!!initialValues.password);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [password, setPassword] = useState(initialValues.password || generateNewPassword());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usePMI, setUsePMI] = useState(initialValues.usePMI);
  const [waitingRoom, setWaitingRoom] = useState(initialValues.waitingRoom);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const isDarkMode = useSelector(state => state.isDarkMode);

  const autoRecordingEnabled = autoRecording !== ZOOM_AUTO_RECORDING_OPTIONS.NONE;

  const compiledSettings = useMemo<ZoomSettingsState>(() => ({
    audio,
    autoRecording,
    autoStartMeetingSummary,
    autoStartAICompanionQuestions,
    continuousMeetingChat,
    hostVideo,
    joinBeforeHost,
    meetingAuthentication,
    muteUponEntry,
    participantVideo,
    password: passwordEnabled ? password : "",
    usePMI,
    waitingRoom,
  }), [
    audio,
    autoRecording,
    autoStartMeetingSummary,
    autoStartAICompanionQuestions,
    continuousMeetingChat,
    hostVideo,
    joinBeforeHost,
    meetingAuthentication,
    muteUponEntry,
    participantVideo,
    password,
    passwordEnabled,
    usePMI,
    waitingRoom,
  ]);

  useEffect(() => {
    const newHaveChangesBeenMade = haveSettingsChanged(
      zoomMeeting,
      compiledSettings,
    );
    if (newHaveChangesBeenMade !== haveChangesBeenMade) {
      setHaveChangesBeenMade(newHaveChangesBeenMade);
    }
  }, [haveChangesBeenMade, zoomMeeting, compiledSettings]);

  // See TODO in component documentation.
  // const generatedId = zoomMeeting.id;
  // const readableGeneratedId = useMemo(() => (
  //   makeZoomIdReadable(generatedId)
  // ), [generatedId]);

  const onSubmit = async (e: React.MouseEvent) => {
    hasEventPreventDefault(e);

    if (haveChangesBeenMade) {
      const updatedZoomMeeting = await submitChanges(
        authedZoomEmail,
        zoomMeeting,
        compiledSettings,
      );
      if (updatedZoomMeeting) {
        onClose(updatedZoomMeeting);
      } else {
        setShowErrorModal(true);
      }
    } else {
      onClose();
    }
  };

  return (
    <>
      {usePMI ? null : (
        <>
          <Section title="Meeting Chat">
            <ZoomSwitchSetting
              label="Enable Continuous Meeting Chat"
              helperText="Added attendees will have access to the Meeting Group Chat before and after the meeting"
              id="zoom-continuous-meeting-chat-toggle"
              isSelected={continuousMeetingChat}
              onClick={() => setContinuousMeetingChat(current => !current)}
            />
          </Section>

          <ColoredLine />
        </>
      )}

      {/* See TODO in component documentation. */}
      {/* <Section title="Meeting ID">
        <ZoomRadioSetting
          label={`Generated ID ${readableGeneratedId}`}
          id="zoom-generated-id-toggle"
          isSelected={!usePMI}
          onClick={() => setUsePMI(false)}
        />
        <ZoomRadioSetting
          label="Personal Meeting ID" // TODO: Add ID.
          id="zoom-personal-meeting-id-toggle"
          isSelected={usePMI}
          onClick={() => setUsePMI(true)}
        />
      </Section> */}

      {usePMI ? null : (
        <>
          {/* <ColoredLine /> */}

          <Section title="Security">
            {/* See TODO in component documentation. */}
            {/* <ZoomCheckboxSetting
              additionalChildren={(
                <PasswordInput
                  password={password}
                  passwordEnabled={passwordEnabled}
                  setPassword={setPassword}
                />
              )}
              label="Passcode"
              helperText="Only users who have the invite link or passcode can join the meeting"
              id="zoom-passcode-toggle"
              isSelected={passwordEnabled}
              onClick={() => setPasswordEnabled(current => !current)}
            /> */}
            <ZoomCheckboxSetting
              label="Waiting Room"
              helperText="Only users admitted by the host can join the meeting"
              id="zoom-waiting-room-toggle"
              isSelected={waitingRoom}
              onClick={() => setWaitingRoom(current => !current)}
            />
            <ZoomCheckboxSetting
              label="Only authenticated users can join"
              id="zoom-authentication-toggle"
              isSelected={meetingAuthentication}
              onClick={() => setMeetingAuthentication(current => !current)}
            />
          </Section>

          <ColoredLine />

          <Section title="Video">
            <ZoomSwitchSetting
              label="Host"
              id="zoom-host-video-toggle"
              isSelected={hostVideo}
              onClick={() => setHostVideo(current => !current)}
            />
            <ZoomSwitchSetting
              label="Participant"
              id="zoom-participant-video-toggle"
              isSelected={participantVideo}
              onClick={() => setParticipantVideo(current => !current)}
            />
          </Section>

          <ColoredLine />

          <Section title="Audio">
            <ZoomRadioSetting
              label="Telephone"
              id="zoom-telephone-audio-toggle"
              isSelected={audio === ZOOM_AUDIO_OPTIONS.TELEPHONY}
              onClick={() => setAudio(ZOOM_AUDIO_OPTIONS.TELEPHONY)}
            />
            <ZoomRadioSetting
              label="Computer Audio"
              id="zoom-computer-audio-toggle"
              isSelected={audio === ZOOM_AUDIO_OPTIONS.VOIP}
              onClick={() => setAudio(ZOOM_AUDIO_OPTIONS.VOIP)}
            />
            <ZoomRadioSetting
              label="Telephone and Computer Audio"
              id="zoom-both-audio-toggle"
              isSelected={audio === ZOOM_AUDIO_OPTIONS.BOTH}
              onClick={() => setAudio(ZOOM_AUDIO_OPTIONS.BOTH)}
            />
          </Section>

          <ColoredLine />

          <Section title="Advanced Options">
            <ZoomCheckboxSetting
              label="Allow participants to join anytime"
              id="zoom-join-before-host-toggle"
              isSelected={joinBeforeHost}
              onClick={() => setJoinBeforeHost(current => !current)}
            />
            <ZoomCheckboxSetting
              label="Mute participants upon entry"
              id="zoom-mute-upon-entry-toggle"
              isSelected={muteUponEntry}
              onClick={() => setMuteUponEntry(current => !current)}
            />
            <ZoomCheckboxSetting
              additionalChildren={(
                <AutoRecordingLocationSetting
                  autoRecording={autoRecording}
                  setAutoRecording={setAutoRecording}
                />
              )}
              label="Automatically record meeting"
              id="zoom-no-auto-record-toggle"
              isSelected={autoRecordingEnabled}
              onClick={() => setAutoRecording(autoRecordingEnabled ? ZOOM_AUTO_RECORDING_OPTIONS.NONE : ZOOM_AUTO_RECORDING_OPTIONS.LOCAL)}
            />
            <ZoomCheckboxSetting
              label="Automatically start meeting summary"
              id="zoom-auto-start-meeting-summary-toggle"
              isSelected={autoStartMeetingSummary}
              onClick={() => setAutoStartMeetingSummary(current => !current)}
            />
            <ZoomCheckboxSetting
              label="Automatically start meeting questions"
              id="zoom-auto-start-ai-companion-questions-toggle"
              isSelected={autoStartAICompanionQuestions}
              onClick={() => setAutoStartAICompanionQuestions(current => !current)}
            />
          </Section>
        </>
      )}

      <EventModalPopup
        isOpen={showErrorModal}
        onRequestClose={() => setShowErrorModal(false)}
        style={determineDefaultModalStyle(isDarkMode)}
        title="Oops!"
      >
        <GenericErrorModalContent onClose={() => setShowErrorModal(false)} />
      </EventModalPopup>

      <div className="fixed w-full bottom-0 pl-3 pr-4 pb-4">
        <SaveButton
          buttonText="Save"
          hideDefaultIcon
          onClick={onSubmit}
          style={{ height: StyleConstants.saveButtonHeight }}
          width="100%"
        />
      </div>
    </>
  );
}
