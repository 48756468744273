import { IS_EDITABLE, MERGED_EVENTS } from "../services/globalVariables";
import { isUserCalendarIDFromPrimaryCalendar } from "../lib/calendarFunctions";
import {
  isReclaimEvent,
  isEventPrivate,
  isOutlookEvent,
  isAllDayEvent,
  showChangesWillOnlyShowOnThisCalendar,
  isTemporaryEvent,
  getReclaimEventExtendedProperties,
  isPreviewOutlookEvent,
} from "../lib/eventFunctions";
import {
  isEditable,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import {
  getAttendeeEmails,
  getAttendeesString,
  getClientEventID,
  getEventICalUID,
  getEventID,
  getEventLocation,
  getEventMasterEventID,
  getEventTitle,
  getEventUserCalendarID,
  getEventUserEmail,
  getGCalEventId,
} from "./eventResourceAccessors";
import { getUserEmail } from "../lib/userFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isTypeString } from "./typeGuards";
import { format } from "date-fns";
import { shouldMergeEvents } from "../lib/settingsFunctions";
import { containsAllElements } from "../lib/arrayFunctions";
import { createUUID } from "./randomFunctions";
import { isSameEmail, lowerCaseAndTrimStringWithGuard } from "../lib/stringFunctions";
import { removeKeyFromObject } from "../lib/objectFunctions";

let _staticReclaimIDDict = {}; // keeps track of the provider id for each event. Mostly used for reclaim events
let _staticOutlookPreviewDict = {}; // keeps track of outlook preview events since they don't have attendees yet

// keeps track of when outlook attendees don't match (but are actually the same event)
// this is a dictionary of the event id to the event
let _staticOutlookDict = {};

let _staticDateTimeLocationToEventDict = {}; // so we can merge events that are copied but do not have attendees

export function mergeSameEvents({
  eventList,
  currentUser,
  mergeBasedOnResourceID,
  updatePreviewFunction,
  currentUserUserCalendarID,
  allCalendars,
  alwaysMerge,
  masterAccount,
  allLoggedInUsers,
}) {
  if (isEmptyArrayOrFalsey(eventList)) {
    return [];
  }

  if (alwaysMerge) {
    // do nothing here
  } else if (!shouldMergeEvents({ masterAccount })) {
    let unmergedEvents = [];
    eventList.forEach((e) => {
      if (e.mergedEvents) {
        const updatedEvent = removeMergedEventsFromEvent(e);
        if (updatePreviewFunction) {
          updatePreviewFunction(updatedEvent);
        }
        unmergedEvents = unmergedEvents.concat(updatedEvent);
      } else {
        unmergedEvents = unmergedEvents.concat(e);
      }
    });
    return unmergedEvents;
  }

  let temporaryEvents = []; // no point in merging temporary events
  const sameEventIndex = {};

  // mapOutStaticDictionaries updates_staticReclaimIDDict and _staticOutlookPreviewDict dictionaries
  mapOutStaticDictionaries(eventList);

  eventList.forEach((e) => {
    const uniqueToken = determineUniqueToken(e);
    const token = mergeBasedOnResourceID
      ? uniqueToken + "_" + e.resourceId
      : uniqueToken;

    if (isTemporaryEvent(e)) {
      temporaryEvents = temporaryEvents.concat(e);
    } else if (!sameEventIndex[token]) {
      sameEventIndex[token] = [e];
    } else {
      sameEventIndex[token] = addEventToList({
        event: e,
        list: sameEventIndex[token],
        allCalendars,
        currentUser,
        currentUserUserCalendarID,
        allLoggedInUsers,
        masterAccount,
      });
    }
  });

  const uniqueEvents = [];
  if (temporaryEvents.length > 0) {
    uniqueEvents.push(...temporaryEvents);
  }

  Object.keys(sameEventIndex).forEach((k) => {
    const events = sameEventIndex[k];

    // this is a somewhat deep copy. If we do shallow copy, we get a Converting circular structure to JSON
    let displayEvent = { ...events[0] }; // event that gets displayed
    const sortedEvents = events.sort((a, b) =>
      getEventUserCalendarID(a)?.localeCompare(getEventUserCalendarID(b)),
    );

    if (doesListContainPrivateEvents(events)) {
      uniqueEvents.push(...removeAllMergedEventsKey(events));
    } else if (events.length === 1) {
      if (displayEvent.mergedEvents) {
        displayEvent = removeMergedEventsFromEvent(displayEvent);
      }

      if (updatePreviewFunction) {
        updatePreviewFunction(displayEvent);
      }
      // no mergeable events
      uniqueEvents.push(displayEvent);
    } else {
      events.forEach((e, index) => {
        // only attach it to the first event
        if (index === 0) {
          // add to display events
          displayEvent.mergedEvents = sortedEvents;
          if (updatePreviewFunction) {
            updatePreviewFunction(displayEvent);
          }

          uniqueEvents.push(displayEvent);
        } else {
          // add merged events to hidden events
          if (updatePreviewFunction) {
            updatePreviewFunction(e);
          }
        }
      });
    }
  });

  return uniqueEvents;
}

function getProviderIDWithDateTime(event) {
  let eventID = isOutlookEvent(event)
    ? getEventICalUID(event)
    : getGCalEventId(event) || getEventID(event);
  if (getEventMasterEventID(event) && eventID?.includes("_20")) {
    // recurring event has iso date time z in the provider, but since we already add the datetime below, it's duplicate work
    // it's possible that the _ is at the front slash as part of the eventID
    // _2024 but with _20, I think we get enough protection from _ as part of eventID
    eventID = eventID.split("_20")?.[0] || eventID;
  }
  return eventID + "_" + getDateTimeString(event);
}

function getDateTimeString(event) {
  const { eventStart, eventEnd } = event;
  const isAllDay = isAllDayEvent(event);
  return (
    formatDateToken(eventStart, isAllDay) +
    "_" +
    formatDateToken(eventEnd, isAllDay)
  );
}

function mapOutStaticDictionaries(eventList) {
  if (isEmptyArrayOrFalsey(eventList)) {
    return;
  }

  _staticReclaimIDDict = {}; // empty out first
  _staticOutlookPreviewDict = {};
  _staticDateTimeLocationToEventDict = {};
  eventList.forEach((event) => {
    const id = getProviderIDWithDateTime(event);
    _staticReclaimIDDict[id] = getEventTitleTimeAndAttendeesString(event);

    if (!isPreviewOutlookEvent(event)) {
      _staticOutlookPreviewDict[getEventTitleAndTimeString(event)] = getEventTitleTimeAndAttendeesString(event);
    }

    if (!isEmptyArrayOrFalsey(getAttendeeEmails(event))) {
      // only save events with attendees
      _staticDateTimeLocationToEventDict[getEventTitleLocationAndTimeString(event)] = getEventTitleTimeAndAttendeesString(event);
    }

    if (isOutlookEvent(event) && !isEventPrivate(event)) {
      const uniqueOutlookEventToken = getOutlookUniqueToken(event);
      if (!uniqueOutlookEventToken) {
        // nothing if no ical -> sanity check
      } else if (_staticOutlookDict[uniqueOutlookEventToken]) {
        const currentEventAttendeesEmails = getAttendeeEmails(event);
        const existingEventAttendeesEmails = getAttendeeEmails(_staticOutlookDict[uniqueOutlookEventToken]);
        
        // can not use sorted string because otherwise if you have attendees like [a, b, c] and [a, c], string for eventA wont' include all attendees of event B
        if (currentEventAttendeesEmails?.length > existingEventAttendeesEmails?.length
          && containsAllElements(currentEventAttendeesEmails, existingEventAttendeesEmails)
        ) {
          // always use the event with more attendees and is more expansive
          _staticOutlookDict[uniqueOutlookEventToken] = event;
        }
      } else {
        // if doesn't exist -> go ahead and use the event
        _staticOutlookDict[uniqueOutlookEventToken] = event;
      }
    }
  });
}

function formatDateToken(jsDate, isAllDayEvent) {
  if (!isValidJSDate(jsDate)) {
    if (jsDate && isTypeString(jsDate)) {
      return jsDate;
    }
    return createUUID();
  }
  if (isAllDayEvent) {
    return format(jsDate, "P"); // 04/29/1453
  }
  return format(jsDate, "Pp"); // 04/29/1453, 12:00 AM
}

function isValidNonPrivateTitle(title) {
  return title && !lowerCaseAndTrimStringWithGuard(title).includes("busy");
}

export function determineUniqueToken(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return;
  }

  if (event.isTemporary) {
    return getClientEventID(event) || event.uniqueEtag;
  }

  const title = getEventTitle(event);
  const { eventStart, eventEnd } = event;

  if (!isReclaimEvent(event) && title && eventStart && eventEnd) {
    // make sure title is not empty. Otherwise -> default to logic around eventICalUID
    if (isPreviewOutlookEvent(event)) {
      return _staticOutlookPreviewDict[getEventTitleAndTimeString(event)] ?? getEventTitleTimeAndAttendeesString(event);
    }
    if (isOutlookEvent(event) && _staticOutlookDict[getOutlookUniqueToken(event)]) {
      return getEventTitleTimeAndAttendeesString(_staticOutlookDict[getOutlookUniqueToken(event)]);
    }

    if (
      isEmptyArrayOrFalsey(getAttendeeEmails(event)) &&
      _staticDateTimeLocationToEventDict[getEventTitleLocationAndTimeString(event)]
    ) {
      // only check if the event has no attendees
      return _staticDateTimeLocationToEventDict[getEventTitleLocationAndTimeString(event)];
    }

    // note: if we change this to something other than getEventTitleTimeAndAttendeesString(event), we also need to change the value in key/value of _staticDateTimeLocationToEventDict
    return getEventTitleTimeAndAttendeesString(event);
  }

  if (isReclaimEvent(event)) {
    const reclaimPrivateProperty = getReclaimEventExtendedProperties(event);
    const reclaimIDWithDateTime = `${reclaimPrivateProperty}_${getDateTimeString(
      event,
    )}`;
    if (_staticReclaimIDDict[reclaimIDWithDateTime]) {
      // created with dictionary from getProviderIDWithDateTime
      return _staticReclaimIDDict[reclaimIDWithDateTime];
    }
  }

  // merge 2 reclaim events that are the same but the original event is not currently in the array
  if (isReclaimEvent(event)
    && isValidNonPrivateTitle(title)
    && eventStart && eventEnd
  ) {
    if (
      isEmptyArrayOrFalsey(getAttendeeEmails(event)) &&
      _staticDateTimeLocationToEventDict[getEventTitleLocationAndTimeString(event)]
    ) {
      // only check if the event has no attendees
      return _staticDateTimeLocationToEventDict[getEventTitleLocationAndTimeString(event)];
    }
    return getEventTitleTimeAndAttendeesString(event);
  }

  if (isEventPrivate(event)) {
    return createUUID();
  }

  if (isOutlookEvent(event)) {
    return getEventICalUID(event);
  }

  // recurring events have the same gcal_event_id
  return getGCalEventId(event) || getEventID(event);
}

function getEventTitleLocationAndTimeString(event) {
  const title = getEventTitle(event);
  return title + "_" + getDateTimeString(event) + "_" + (getEventLocation(event) || "");
}

function getEventTitleAndTimeString(event) {
  const title = getEventTitle(event);
  return title + "_" + getDateTimeString(event);
}

function getEventTitleTimeAndAttendeesString(event) {
  const title = getEventTitle(event);
  const titleAndTime = title + "_" + getDateTimeString(event);
  const attendeesString = getAttendeesString(event);
  if (attendeesString) {
    return titleAndTime + "_" + attendeesString;
  }
  return titleAndTime;
}

function getOutlookUniqueToken(event) {
  const eventICalUID = getEventICalUID(event);
  if (!eventICalUID) {
    return;
  }
  return `${eventICalUID}_${getDateTimeString(event)}`;
}

function addEventToList({
  event,
  list,
  allCalendars,
  currentUser,
  currentUserUserCalendarID,
  allLoggedInUsers,
  masterAccount,
}) {
  // need to have reclaim event check at the top. If reclaim event, throw it to the back
  // if the first event is a reclaim event -> always just put the new event at the front
  if (isReclaimEvent(event)) {
    return list.concat(event);
  }

  if (isReclaimEvent(list[0])) {
    return [event].concat(list);
  }

  if (event?.conferenceUrl && !list[0]?.conferenceUrl) {
    return [event].concat(list);
  }

  if (!event?.conferenceUrl && list[0]?.conferenceUrl) {
    return list.concat(event);
  }

  if (
    list[0] &&
    !isUserCalendarIDFromPrimaryCalendar(
      getEventUserCalendarID(list[0]),
      allCalendars,
    ) &&
    isUserCalendarIDFromPrimaryCalendar(
      getEventUserCalendarID(event),
      allCalendars,
    )
  ) {
    if (
      showChangesWillOnlyShowOnThisCalendar({
        event,
        allCalendars,
        currentUser,
        allLoggedInUsers,
        masterAccount,
      }) &&
      !showChangesWillOnlyShowOnThisCalendar({
        event: list[0],
        allCalendars,
        currentUser,
        allLoggedInUsers,
        masterAccount,
      }) &&
      allCalendars &&
      (list[0]?.[IS_EDITABLE] ||
        isEditable({
          event: list[0],
          allCalendars,
        }))
    ) {
      // put event at the end if the primary calendar can not be edited and changes will not show
      return list.concat(event);
    }

    // always put primary calendar first
    return [event].concat(list);
  }

  if (
    list[0] &&
    isUserCalendarIDFromPrimaryCalendar(
      getEventUserCalendarID(list[0]),
      allCalendars,
    ) &&
    !isUserCalendarIDFromPrimaryCalendar(
      getEventUserCalendarID(event),
      allCalendars,
    )
  ) {
    return list.concat(event);
  }

  if (isEventPrivate(event) && !isEventPrivate(list[0])) {
    // put events that show details first
    return list.concat(event);
  }

  if (!isEventPrivate(event) && isEventPrivate(list[0])) {
    return [event].concat(list);
  }

  if (
    list[0] &&
    !isSameEmail(getEventUserEmail(list[0]), getUserEmail(currentUser)) &&
    isSameEmail(getEventUserEmail(event), getUserEmail(currentUser))
  ) {
    // if event is the current user's email but the start of the list isnt -> put the event first
    return [event].concat(list);
  }

  if (getEventUserEmail(event) && !isSameEmail(getEventUserEmail(event), getUserEmail(currentUser))) {
    // belongs to another logged in account -> goes to back
    return list.concat(event);
  }

  if (getEventUserCalendarID(event) === currentUserUserCalendarID) {
    return [event].concat(list);
  }

  if (
    list[0] &&
    getEventUserCalendarID(list[0]) === currentUserUserCalendarID
  ) {
    // if first one is already primary calendar event -> do nothing
    return list.concat(event);
  }

  if (
    allCalendars &&
    (event?.[IS_EDITABLE] ||
      isEditable({
        event,
        allCalendars,
      }))
  ) {
    // move editable event to the front
    return [event].concat(list);
  }

  return list.concat(event);
}

// we do not want to show private events
function removeAllMergedEventsKey(events) {
  if (!events) {
    return [];
  }
  let updatedEvents = [];
  events.forEach((e) => {
    let updatedEvent = removeMergedEventsFromEvent(e);
    updatedEvents = updatedEvents.concat(updatedEvent);
  });

  return updatedEvents;
}

function doesListContainPrivateEvents(eventList) {
  return eventList.some((e) => isEventPrivate(e) && !getEventTitle(e));
}

function removeMergedEventsFromEvent(event) {
  return removeKeyFromObject(event, MERGED_EVENTS);
}
