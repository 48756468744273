import { persist } from "zustand/middleware";
import create from "zustand";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { getConferenceRoomID } from "../../lib/conferenceRoomFunctions";

export const useConferenceRoomStore = create(
  persist(
    (set) => ({
      lastSelectedBuildingID: null,
      setLastSelectedBuildingID: (buildingID) => set({ lastSelectedBuildingID: buildingID }),
      recentlyUsedConferenceRooms: {}, // {email: [rooms]}
      setRecentlyUsedConferenceRooms: ({ rooms, userEmail }) => {
        if (isEmptyArray(rooms) || !userEmail) {
          return;
        }
        set((state) => {
          const newRoomIds = rooms.map((r) => getConferenceRoomID(r));
          const existingRooms =
            state.recentlyUsedConferenceRooms[userEmail] || [];
          const filteredExistingRooms = existingRooms.filter(
            (room) => !newRoomIds.includes(getConferenceRoomID(room))
          );

          // Update only the user's rooms, keeping the last 3 unique entries
          const updatedRooms = [...rooms, ...filteredExistingRooms].slice(0, 3);

          return {
            recentlyUsedConferenceRooms: {
              ...state.recentlyUsedConferenceRooms,
              [userEmail]: updatedRooms,
            },
          };
        });
      },
      resetRecentlyUsedConferenceRooms: () =>
        set((state) => ({ recentlyUsedConferenceRooms: {}, lastSelectedBuildingID: null })),
    }),
    {
      name: "conference-room-storage", // unique name
    }
  )
);
