import React, { useState } from "react";
import Broadcast from "../../../../broadcasts/broadcast";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useAllLoggedInUsers, useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { hasGlobalJoinSettingProperty, hasOpenOnLoginSettingsProperty, updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import SettingToggleOption from "../../common/settingToggleOption";
import { BACKEND_SETTINGS_NAMES } from "../../../../lib/vimcalVariables";
import { getActiveCommandCentersKey, isElectron } from "../../../../services/commonUsefulFunctions";
import { getIsGlobalJoinEnabled, setInStorageIsGlobalJoinEnabled, setShouldOpenOnLogin, shouldOpenOnLogin } from "../../../../lib/localData";
import { SECOND_IN_MS } from "../../../../services/globalVariables";
import { getAccountHideWeekend, getIsAccountIn24HourFormat, getMasterAccountShouldHideAllDayBusyColumn, getMasterAccountShouldHideOOOColumn, shouldAutoAddEmoji, shouldDimPastEvents, shouldDisplayWeekNumber, shouldHideDefaultSignature, shouldHideWorkingLocationEvents, shouldMergeEvents, showAccountDeclinedEvents } from "../../../../lib/settingsFunctions";
import { isUserDelegatedUser } from "../../../../services/maestroFunctions";
import { doesAllLoggedInUsersContainGoogleUsers } from "../../../../lib/userFunctions";

const LABEL_FOR_GLOBAL_JOIN = `Global join meeting short cut (${getActiveCommandCentersKey()} shift J)`;
const LABEL_FOR_OPEN_ON_LOGIN = "Open Vimcal on login";

function SettingsEventsContent(props) {
  const { selectedUser } = props;
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const isUpdatingExecutiveProfile = isUserDelegatedUser(selectedUser);

  const shouldOnlyShowWorkWeek = useSelector(
    (state) => state.shouldOnlyShowWorkWeek
  );
  const [isGlobalJoinEnabled, setIsGlobalJoinEnabled] = React.useState(getIsGlobalJoinEnabled());
  const [renderCount, setRenderCount] = useState(0);

  const toggleOptions = {
    "Week number": {
      isChecked: shouldDisplayWeekNumber({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          week_number: !shouldDisplayWeekNumber({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
    },
    "Dim past events": {
      isChecked: shouldDimPastEvents({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          dim_past_events: !shouldDimPastEvents({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
    },
    "Auto-add emoji": {
      isChecked: shouldAutoAddEmoji({ masterAccount, user: selectedUser }),
      onToggle: () => {
        const updatedSettings = {
          auto_emojis: !shouldAutoAddEmoji({ masterAccount, user: selectedUser }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          isUpdatingExecutiveProfile,
          masterAccount,
          updatedSettings,
          user: selectedUser,
        });
      },
    },
    "Merge duplicate events": {
      isChecked: shouldMergeEvents({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.AUTO_MERGE_EVENTS]: !shouldMergeEvents({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
        Broadcast.publish("RESET_EVENTS");
      },
    },
    "Hide weekends": {
      isChecked: shouldOnlyShowWorkWeek,
      onToggle: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.HIDE_WEEKENDS]: !getAccountHideWeekend({masterAccount}),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
    },
    "Show events in 24 hour format": {
      isChecked: getIsAccountIn24HourFormat({ masterAccount, user: selectedUser }),
      onToggle: () => {
        const updatedSettings = {
          military_time: !getIsAccountIn24HourFormat({ masterAccount, user: selectedUser }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
          user: selectedUser,
        });
      },
    },
    "Show declined events": {
      isChecked: showAccountDeclinedEvents({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          show_declined_events: !showAccountDeclinedEvents({masterAccount}),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
    },
    "Remove Vimcal signature": {
      isChecked: shouldHideDefaultSignature({ masterAccount, user: selectedUser }),
      onToggle: () => {
        const updatedSettings = {
          hide_default_signature: !shouldHideDefaultSignature({
            masterAccount,
            user: selectedUser,
          }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
          user: selectedUser,
        });
      },
    },
    "Hide OOO column": {
      isChecked: getMasterAccountShouldHideOOOColumn({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.HIDE_OOO_COLUMN]: !getMasterAccountShouldHideOOOColumn({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      },
    },
    "Hide all-day busy column": {
      isChecked: getMasterAccountShouldHideAllDayBusyColumn({ masterAccount }),
      onToggle: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.HIDE_BUSY_COLUMN]: !getMasterAccountShouldHideAllDayBusyColumn({ masterAccount }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          masterAccount,
          updatedSettings,
        });
      }
    }
  };
  if (isElectron() && hasGlobalJoinSettingProperty()) {
    toggleOptions[LABEL_FOR_GLOBAL_JOIN] = {
      isChecked: isGlobalJoinEnabled,
      onToggle: () => {
        const updatedIsEnabled = !isGlobalJoinEnabled;
        if (updatedIsEnabled) {
          if (window?.vimcal?.registerGlobalJoinNextMeeting) {
            window.vimcal.registerGlobalJoinNextMeeting();
          }
        } else {
          if (window?.vimcal?.unregisterGlobalJoinNextMeeting) {
            window.vimcal.unregisterGlobalJoinNextMeeting();
          }
        }
        setInStorageIsGlobalJoinEnabled(updatedIsEnabled);
        setIsGlobalJoinEnabled(updatedIsEnabled);
        setTimeout(() => {
          Broadcast.publish("UPDATE_MENU_BAR");
        }, 1 *  SECOND_IN_MS);
      },
    }
  }

  if (isElectron() && hasOpenOnLoginSettingsProperty()) {
    toggleOptions[LABEL_FOR_OPEN_ON_LOGIN] = {
      isChecked: shouldOpenOnLogin(),
      onToggle: () => {
        const updated = !shouldOpenOnLogin();
        setShouldOpenOnLogin(updated);
        if (updated) {
          if (window?.vimcal?.setOpenOnLogin) {
            window.vimcal.setOpenOnLogin();
          }
        } else {
          if (window?.vimcal?.setDoNotOpenOnLogin) {
            window.vimcal.setDoNotOpenOnLogin();
          }
        }
        setRenderCount(renderCount + 1);
      },
    };
  }

  if (doesAllLoggedInUsersContainGoogleUsers(allLoggedInUsers)) {
    // if it contains any google users, show this setting
    toggleOptions["Show working location events"] = {
      isChecked: !shouldHideWorkingLocationEvents({ masterAccount, user: selectedUser }),
      onToggle: () => {
        const updatedSettings = {
          [BACKEND_SETTINGS_NAMES.HIDE_WORK_LOCATION_EVENTS]: !shouldHideWorkingLocationEvents({ masterAccount, user: selectedUser }),
        };
        updateMasterAccountSettingsForFrontendAndBackend({
          isUpdatingExecutiveProfile,
          masterAccount,
          updatedSettings,
          user: selectedUser,
        });
      },
    };
  }

  const renderToggleOptions = () => {
    return Object.keys(toggleOptions).map((k, index) => {
      return (
        <SettingToggleOption
          key={`setting_toggle_${index}`}
          label={k}
          isChecked={toggleOptions[k].isChecked}
          onToggle={toggleOptions[k].onToggle}
        />
      )
    });
  };

  return <div className="w-full">{renderToggleOptions()}</div>;
}

function mapDispatchToProps(dispatch) {
  return {
    set24HourFormat: (data) =>
      dispatch({ data: data, type: "SET_FORMAT_24_HOUR_TIME" }),
  };
}

export default connect(null, mapDispatchToProps)(SettingsEventsContent);
