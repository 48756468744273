import create from "zustand";

export interface OnboardingState {
  availabilities: Record<string, PersonalLinkAvailability>
  personalLinks: Record<string, PersonalLink>
  areAllSlotsBusy: boolean
  isFetchingAvailabilities: boolean
}

export const useOnboardingStore = create<OnboardingState>(
  () => ({
    areAllSlotsBusy: false,
    availabilities: {},
    personalLinks: {},
    isFetchingAvailabilities: false,
  }),
);
