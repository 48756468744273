import React from "react";
import classNames from "classnames";

// Copy the base props from the input element, but don't allow the type to be overridden.
type RangeInputProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "type">

export default function RangeInput({ className, onChange, ...props }: RangeInputProps) {

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange && onChange(event);

    // Update the CSS variable so that the background of the range can update.
    const rangeInput = event.target;
    const currentValue = parseFloat(rangeInput.value);
    const minValue = parseFloat(rangeInput.min);
    const maxValue = parseFloat(rangeInput.max);
    const progress = 100 * (currentValue - minValue) / (maxValue - minValue);
    event.target.style.setProperty("--range-progress", `${progress}%`);
  }

  return (
    <input className={classNames("range-input", className)} type="range" onChange={handleChange} {...props} />
  );
}
