import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { X } from "react-feather";
import { trackTeamSlotsTeamPlan } from "../../lib/groupSchedulingFunctions";
import { useSelector } from "react-redux";
import {
  handleError,
  sendMessageToSentry,
} from "../../services/commonUsefulFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import AddSeatsSection from "./teamPlansModal/addSeatsSection/addSeatsSection";
import ReviewSection from "./teamPlansModal/reviewSection/reviewSection";
import CompleteSection from "./teamPlansModal/completeSection";
import PageCompleteCheckmark from "../icons/pageCompleteCheckmark";
import { useDefaultPaymentMethod, useStripeSubscriptions } from "../../services/stores/finance";
import VimcalErrorLogo from "../logo/vimcalErrorLogo";
import CheckBoxSpinner from "../spinners/checkboxSpinner";
import { trackError } from "../tracking";
import { ERROR_TYPE_TEAM_PLAN, VIMCAL_PLAN_OPTIONS } from "../../lib/vimcalVariables";
import { getDefaultFontColor } from "../../lib/styleFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { GroupSchedulingAttendeeType } from "../../lib/groupSchedulingVariables";
import { previewInviteCost } from "../queries/teamPlans";
import { isMonthlyPlan } from "../../lib/stripeFunctions";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { groupEmailPlanDictionaryBySeat } from "./teamPlansModal/sharedFunctions";
import SpinnerV2 from "../spinnerV2";
import { useIsFixedSeating } from "./hooks";
import { isErrorResponse } from "../../services/api";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { isUserInFreeTrial } from "../../lib/stateManagementFunctions";
import { useTeamPlan } from "../../services/stores/userData";
import { hasAccountBeenOnTeamPlan } from "../../services/accountFunctions";
import AdminSettings from "./teamPlanSettings/adminSettings";
import { isTeamPlanModalHeaderClickable } from "./sharedFunctions";
import NeedPaymentSettings from "./teamPlanSettings/needPaymentSettings";

export type StripeCost = {
  dueToday: number | null;
  newTotal: number | null;
  newSeatsTotal: number | null;
  unitPrices: {
    vimcal?: number | null
    vimcalEA?: number | null
  }
};

export type EmailPlan = {
  plan: ValueOf<typeof VIMCAL_PLAN_OPTIONS>
  locked: boolean
}

export const PAGES = {
  CREATE_TEAM: "Create team",
  ADD_SEATS: "Add seats",
  REVIEW: "Review",
} as const;

export type Page = ValueOf<typeof PAGES>;

interface UpdatedAddTeamMembersModalProps {
  attendeeForModal?: GroupSchedulingAttendeeType
  closeModal: () => void
}

export default function UpdatedAddTeamMembersModal({ attendeeForModal, closeModal }: UpdatedAddTeamMembersModalProps) {
  const [emailPlanDictionary, setEmailPlanDictionary] = useState<Record<string, EmailPlan>>({});
  const [defaultVimcalSeats, setDefaultVimcalSeats] = useState(0);
  const [vimcalEASeats, setVimcalEASeats] = useState(0);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const [hasCheckedAttendeeForModal, setHasCheckedAttendeeForModal] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [stripeCost, setStripeCost] = useState<StripeCost>({
    dueToday: null,
    newTotal: null,
    newSeatsTotal: null,
    unitPrices: {},
  });
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [shareToken, setShareToken] = useState<string | null>(null);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const needCard = useDefaultPaymentMethod(state => state.needCard);

  const addedSeats = Object.keys(emailPlanDictionary).length + vimcalEASeats + defaultVimcalSeats;

  /**
   * Even after the team plan has been created, this should stay true to allow the user to
   * revisit the first page to edit settings if they wish. If the user is editing an existing
   * team plan, this will be false and the creation page will not be available.
   */
  const isNewTeamPlan = useMemo(() => {
    return isEmptyObjectOrFalsey(teamPlan);
  }, []);

  const isTrialPlan = useMemo(() => {
    if (!isUserInFreeTrial(masterAccount)) {
      return false;
    }

    if (isEmptyObjectOrFalsey(teamPlan) && hasAccountBeenOnTeamPlan(masterAccount)) {
      return false;
    }

    return true;
  }, [masterAccount, teamPlan]);

  const pages = useMemo(() => {
    const availablePages: Page[] = [];

    if (isNewTeamPlan) {
      availablePages.push(PAGES.CREATE_TEAM);
    }

    availablePages.push(PAGES.ADD_SEATS);

    if (!isTrialPlan) {
      availablePages.push(PAGES.REVIEW);
    }

    return availablePages;
  }, [isNewTeamPlan, isTrialPlan]);

  const [currentPage, setCurrentPage] = useState(pages[0]);

  /* We want this component to be independent of group scheduling */
  /* Need to refetch everything */
  const stripeSubscriptions = useStripeSubscriptions(state => state.stripeSubscriptions);

  /* Track the page to set hasFetched */
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const componentIsMounted = useIsMounted();
  const isFixedSeating = useIsFixedSeating();

  useEffect(() => {
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
  }, []);

  const fetchProratedCost = useCallback(() => {
    if (!currentUser) {
      return;
    }

    if (isFixedSeating) {
      // No costs to fetch.
      setShouldDisplayError(false);
      return;
    }

    setIsFetching(true);

    const { vimcalEmails, vimcalEAEmails } = groupEmailPlanDictionaryBySeat(emailPlanDictionary);

    previewInviteCost(
      currentUser,
      { vimcalEmails, vimcalEAEmails, vimcalSeats: defaultVimcalSeats, vimcalEASeats },
    ).then((response) => {
      if (!componentIsMounted.current) {
        return;
      }

      if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        sendMessageToSentry("error fetching prorated cost", "empty response");

        trackError({
          category: ERROR_TYPE_TEAM_PLAN,
          errorMessage:
            "updatedAddTeamMembersModal::fetchProratedCost::Empty response from backend",
          userToken: currentUser.token,
        });

        setIsFetching(false);
        setShouldDisplayError(true);
        return;
      }

      setStripeCost({
        dueToday: response.due_today,
        newTotal: response.new_total,
        newSeatsTotal: response.new_seats_total,
        unitPrices: {
          vimcal: response.unit_prices.non_ea,
          vimcalEA: response.unit_prices.ea,
        },
      });

      setIsFetching(false);
    }).catch(handleError);
  }, [
    getUserEmail(currentUser),
    getUserToken(currentUser),
    emailPlanDictionary,
    defaultVimcalSeats,
    isFixedSeating,
    vimcalEASeats,
  ]);

  useEffect(() => {
    fetchProratedCost();
  }, [emailPlanDictionary, fetchProratedCost]);

  const renderPageSection = () => {
    if (currentPage === PAGES.REVIEW && shouldDisplayError) {
      return (
        <div className="team-plans-error-container">
          <div>
            <VimcalErrorLogo />
          </div>
          <div className="my-6 team-plans-error-text">
            Something went wrong...
          </div>
          <div
            className="team-plans-error-button"
            onClick={() => {
              if (isFetching) {
                return;
              }

              fetchProratedCost();
            }}
          >
            {isFetching ? (
              <CheckBoxSpinner
                color={getDefaultFontColor(isDarkMode)}
                className="team-plans-error-spinner"
                hideRightSidePadding={true}
                hideTopMargin={false}
                size={12}
              />
            ) : (
              "Retry"
            )}
          </div>
        </div>
      );
    }

    if (isEmptyObjectOrFalsey(stripeSubscriptions) && !isFixedSeating) {
      return <div className="flex items-center justify-center"><SpinnerV2 /></div>;
    }

    switch (currentPage) {
      case PAGES.CREATE_TEAM:
        if (
          isEmptyObjectOrFalsey(teamPlan) &&
          isUserInFreeTrial(masterAccount) &&
          hasAccountBeenOnTeamPlan(masterAccount) &&
          needCard
        ) {
          return <NeedPaymentSettings />;
        }
        return (
          <AdminSettings isCreatingTeam onSaveSuccess={() => switchPage(PAGES.ADD_SEATS)} />
        );
      case PAGES.ADD_SEATS:
        return (
          <AddSeatsSection
            attendeeForModal={attendeeForModal}
            closePlanModal={closeModal}
            emailPlanDictionary={emailPlanDictionary}
            hasCheckedAttendeeForModal={hasCheckedAttendeeForModal}
            isPayingMonthly={isMonthlyPlan({ stripeSubscriptions: stripeSubscriptions })}
            nextPage={() => {
              trackTeamSlotsTeamPlan({
                action: `added emails to team plans - ${Object.keys(emailPlanDictionary)?.length} emails`,
                currentUser,
              });
              switchPage(PAGES.REVIEW);
            }}
            setEmailPlanDictionary={setEmailPlanDictionary}
            setHasCheckedAttendeeForModal={setHasCheckedAttendeeForModal}
            shareToken={shareToken}
            showHeader={pages.length > 1}
            setShareToken={setShareToken}
            defaultVimcalSeats={defaultVimcalSeats}
            setDefaultVimcalSeats={setDefaultVimcalSeats}
            vimcalEASeats={vimcalEASeats}
            stripeCost={stripeCost}
            setVimcalEASeats={setVimcalEASeats}
          />
        );
      case PAGES.REVIEW:
        return (
          <ReviewSection
            emailPlanDictionary={emailPlanDictionary}
            fetchProratedCost={fetchProratedCost}
            isFetching={isFetching}
            nextPage={() => setShowCompleteModal(true)}
            stripeCost={stripeCost}
            stripeSubscription={stripeSubscriptions}
            defaultVimcalSeats={defaultVimcalSeats}
            vimcalEASeats={vimcalEASeats}
          />
        );
      default:
        return null;
    }
  };

  const switchPage = (newPage: Page) => {
    const canSwitch = isTeamPlanModalHeaderClickable({ addedSeats, currentPage, pages, newPage });

    if (canSwitch) {
      setCurrentPage(newPage);
    }
  };

  /* Specifically render a different modal for the completion page */
  if (showCompleteModal) {
    return (
      <CompleteSection
        closeModal={closeModal}
        setShareToken={setShareToken}
        shareToken={shareToken}
        stripeCost={stripeCost}
      />
    );
  }
  return (
    <div
      className={classNames(
        "group-scheduling-team-plans-container",
        isDarkMode ? "dark-mode" : "",
      )}
    >
      <div className="group-scheduling-team-plans-header-container group-scheduling-team-plans-bottom-border flex gap-8">
        <PageHeaders addedSeats={addedSeats} currentPage={currentPage} pages={pages} switchPage={switchPage} />
        <div className="group-scheduling-team-plans-x" onClick={closeModal}>
          <X size={20} />
        </div>
      </div>

      <div className="group-scheduling-team-plans-section-container group-scheduling-team-plans-grow">
        {renderPageSection()}
      </div>
    </div>
  );
}

interface PageHeadersProps {
  addedSeats: number
  currentPage: Page
  pages: Page[]
  switchPage: (newPage: Page) => void
}

function PageHeaders({ addedSeats, currentPage, pages, switchPage }: PageHeadersProps) {
  // There is only one step if the user is in a free trial team plan and the team plan already exists.
  if (pages.length === 1) {
    return <div className="font-size-16 ml-2 mr-auto">Add more seats to your team plan</div>;
  }

  const currentIndex = pages.indexOf(currentPage);

  return (
    <>
      {pages.map((page, index) => {
        const isClickable = isTeamPlanModalHeaderClickable({ addedSeats, currentPage, pages, newPage: page });
        return (
          <div
            className={classNames(
              "flex items-center gap-2",
              isClickable ? "cursor-pointer clickable-step" : "",
              page === currentPage
                ? ""
                : "group-scheduling-team-plans-text-dark",
            )}
            onClick={() => switchPage(page)}
            key={page}
          >
            <div
              className={classNames(
                "h-5 w-5 flex justify-center items-center rounded-full",
                currentIndex > index
                  ? ""
                  : "group-scheduling-team-plans-step-text",
                currentIndex < index ? "unchecked-step" : "",
              )}
            >
              {currentIndex > index ? (
                <PageCompleteCheckmark />
              ) : (
                `${index + 1}`
              )}
            </div>
            <div>{page}</div>
          </div>
        );
      })}
    </>
  );
}
