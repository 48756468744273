import React from "react";
import SeatsBlock from "./seatsBlock";
import NextBlock from "./nextBlock";

export default function BillingSummary() {
  return (
    <div className="flex gap-10 default-font-size" style={{ height: 200 }}>
      <SeatsBlock />
      <NextBlock />
    </div>
  );
}
