import React, { useState } from "react";
import "../../styles/helpCenterStyle.css";
import "../../styles/helpCenterStyle-dark-mode.css";
import Broadcast from "../../broadcasts/broadcast";
import {
  ALL_HELP_CENTER_OPTIONS,
  RESCHEDULE,
  CMD_K,
  MEET_WITH,
  EVENT_TEMPLATES,
  FLASH_SHORTCUTS,
  OPEN_LOCATION,
  MAIN_CALENDARS,
  MULTIPLE_CALENDARS,
  NLP,
  PAINTER,
  PERSONAL_LINKS,
  SLOTS,
  TIME_TRAVEL,
  OPEN_VIDEO_CONFERENCING,
  MAC_NOTIFICATIONS,
  CHROME_NOTIFICATIONS,
  CONTACT_GROUPS,
} from "./helpCenterVariables";
import Fuse from "fuse.js";
import { useSelector } from "react-redux";
import { LIGHTGRAY, DEFAULT_FONT_COLOR } from "../../services/globalVariables";
import classNames from "classnames";
import HelpCenterDescription from "./helpCenterDescription";
import {
  hasStopEventPropagation,
  isElectron,
} from "../../services/commonUsefulFunctions";
import SearchBar from "../searchBar";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES } from "../../lib/broadcastValues";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";

export default function HelpCenterContainer(props) {
  const [searchContent, setSearchContent] = useState("");
  const [searchedOptions, setSearchedOptions] = useState(
    props.selectedOption
      ? ALL_HELP_CENTER_OPTIONS.map((c) => {
          return { item: c };
        })
      : null
  );
  const [selectedOption, setSelectedOption] = useState(
    props.selectedOption ? { item: props.selectedOption } : null
  );
  const [shouldDisplaySearchedContent, setDisplaySearchedContent] = useState(
    props.selectedOption ? true : false
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const options = {
    includeScore: true,
    keys: ["title", "tags"],
  };
  const fuse = new Fuse(ALL_HELP_CENTER_OPTIONS, options);

  const onSearch = (text) => {
    setSearchContent(text);
    const result = fuse.search(text);
    setSearchedOptions(result);
    if (result?.length > 0) {
      setSelectedOption(result[0]);
    } else {
      setDisplaySearchedContent(false);
    }
  };

  const renderSearchBar = () => {
    const onClickDeleteSearch = (e) => {
      hasStopEventPropagation(e);
      setSelectedOption(null);
      setDisplaySearchedContent(false);
      setSearchContent("");
    };
    return (
      <SearchBar
        value={searchContent}
        onChange={(e) => onSearch(getInputStringFromEvent(e))}
        onDelete={onClickDeleteSearch}
      />
    );
  };

  const onClickContactUs = () => {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK);
  };

  const renderContent = () => {
    const renderSection = (sectionTitle, sectionList) => {
      return (
        <div>
          <div className="font-weight-400 font-size-16 mb-2 select-none">
            {sectionTitle}
          </div>

          {sectionList.map((s, index) => {
            return (
              <div
                className={classNames(
                  "font-size-12 mt-1.5 select-none cursor-pointer duration-200 transition-colors",
                  isDarkMode ? "hover:text-blue-500" : "hover:text-blue-600"
                )}
                key={`help-center-default-section-${index}`}
                onClick={(e) => {
                  hasStopEventPropagation(e);
                  setSelectedOption({ item: s.value });
                  setDisplaySearchedContent(true);
                  setSearchedOptions(
                    ALL_HELP_CENTER_OPTIONS.map((c) => {
                      return { item: c };
                    })
                  );
                }}
              >
                {s.label}
              </div>
            );
          })}
        </div>
      );
    };
    if (
      !shouldDisplaySearchedContent &&
      (!searchContent ||
        searchContent.length === 0 ||
        !searchedOptions ||
        searchedOptions.length === 0)
    ) {
      // displayDefault section
      return (
        <div
          className="help-center-default-section p-5"
          style={{ padding: "20px" }}
        >
          <div className="w-full flex justify-center font-size-18 font-weight-400 select-none">
            Top questions
          </div>

          <div className="flex justify-between mt-2">
            {renderSection("Calendar", [
              { label: "Main calendars", value: MAIN_CALENDARS },
              { label: "Time travel", value: TIME_TRAVEL },
              {
                label: "Open video conferencing",
                value: OPEN_VIDEO_CONFERENCING,
              },
              { label: "Multiple calendars", value: MULTIPLE_CALENDARS },
            ])}
            {renderSection("Basics", [
              { label: "Command Center", value: CMD_K },
              { label: "Meet With", value: MEET_WITH },
              { label: "Flash shortcuts", value: FLASH_SHORTCUTS },
              {
                label: "Notifications",
                value: isElectron() ? MAC_NOTIFICATIONS : CHROME_NOTIFICATIONS,
              },
            ])}
            {renderSection("Event", [
              { label: "Painter", value: PAINTER },
              { label: "Reschedule", value: RESCHEDULE },
              { label: "NLP event create", value: NLP },
              { label: "Event templates", value: EVENT_TEMPLATES },
            ])}
            {renderSection("Other", [
              { label: "Personal Links", value: PERSONAL_LINKS },
              { label: "Slots", value: SLOTS },
              // { label: "Chrome extension", value: CHROME_EXTENSION },
              { label: "Open location", value: OPEN_LOCATION },
              { label: "Contact groups", value: CONTACT_GROUPS },
            ])}
          </div>

          <div className="help-center-contact-us-container">
            <div className="font-weight-400">
              Can't find what you're looking for?
            </div>
            <div
              className="flex justify-center help-center-contact-us font-weight-400 default-font-size cursor-pointer text-white"
              onClick={onClickContactUs}
            >
              Contact Us
            </div>
          </div>
        </div>
      );
    }

    const renderConferencingOptionsMenu = () => {
      const determineBackgroundColor = (isSelected) => {
        if (!isSelected) {
          return null;
        }

        return isDarkMode ? "#BCC6F7" : "rgb(245, 246, 251)";
      };

      const isSelected = (option) => {
        return option?.item?.value === selectedOption?.item.value;
      };

      return searchedOptions.map((c, index) => {
        return (
          <div
            key={`help-center-searched-option-${index}`}
            style={{
              backgroundColor: determineBackgroundColor(isSelected(c)),
              borderLeft: isSelected(c)
                ? "5px solid rgb(97, 119, 217)"
                : "5px solid transparent",
              color: isSelected(c) && isDarkMode ? DEFAULT_FONT_COLOR : null,
            }}
            className={classNames(
              "cursor-pointer",
              "w-52",
              "p-3",
              "display-flex-flex-direction-row",
              "align-middle",
              "justify-between",
              "select-none"
            )}
            onClick={(e) => {
              hasStopEventPropagation(e);
              setSelectedOption(c);
            }}
          >
            <div className="default-font-size">
              {c?.item?.title || ""}
            </div>
          </div>
        );
      });
    };

    return (
      <div className="help-center-default-section display-flex-flex-direction-row py-5 pl-5 padding-right-5">
        <div
          style={{
            marginLeft: "-20px",
            borderRight: `1px solid ${isDarkMode ? "#5E5C5C" : LIGHTGRAY}`,
          }}
          className="mr-5 overflow-y-auto overflow-x-hidden w-52"
        >
          {renderConferencingOptionsMenu()}
        </div>

        {renderSearchedOptionContent(selectedOption)}
      </div>
    );
  };
  return (
    <div>
      <div className="flex justify-center w-full select-none font-size-18 font-weight-400">
        What can we help you with?
      </div>

      {renderSearchBar()}

      {renderContent()}
    </div>
  );
}

function renderSearchedOptionContent(selectedOption) {
  if (!selectedOption?.item?.value) {
    return null;
  }

  const text = selectedOption?.item?.value;
  return <HelpCenterDescription text={text} />;
}
