import React, { useEffect, useState } from "react";
import CircleWithColor from "../../../circleWithColor";
import { getColorFromID } from "../../../../lib/painterFunctions";
import {
  DEFAULT_SPECIAL_COLOR_DATA,
  getSoloTagFromUser,
  isPriorityTag,
} from "../../../../lib/tagsFunctions";
import { SPECIAL_TAGS_TYPE } from "../../../../lib/vimcalVariables";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import IsPrioritizedTagStar from "./isPrioritizedTagStar";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import TagsSaveCancelButton from "./tagsSaveCancelButton";
import SpecialTagsHeader from "./specialTagsHeader";
import SpecialTagsColorPicker from "./specialTagsColorPicker";
import { SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";

export default function SoloTagsContainer({
  colorOptions,
  isOpen,
  setIsOpen,
  selectedUser,
}) {
  const { originalTagData, originalIsToggledOn } = getSoloTagData({
    user: selectedUser,
  });
  const [warning, setWarning] = useState("");

  useEffect(() => {
    onClickCancel();
  }, [selectedUser]);

  useEffect(() => {
    settingsBroadcast.subscribe(SETTINGS_BROADCAST_VALUES.CLOSE_SOLO_TAGS, () => {
      setIsOpen(false);
    });

    return () => {
      settingsBroadcast.unsubscribe(SETTINGS_BROADCAST_VALUES.CLOSE_SOLO_TAGS);
    };
  }, []);

  const [tagData, setTagData] = useState(originalTagData);
  const [isToggledOn, setIsToggledOn] = useState(originalIsToggledOn);

  const openSection = () => {
    setIsOpen(true);
  };

  const onClickSave = () => {
    if (!originalIsToggledOn && !isToggledOn && tagData.color_id !== -1) {
      // If the tag is not toggled on, and the color is not the default color, then we need to delete the tag
      setWarning("You can only set the color if the tag is toggled on.");
      return;
    }

    setWarning("");
    const updatedSoloTag = {
      special_tag_name: SPECIAL_TAGS_TYPE.SOLO,
      color: tagData.color,
      color_id: tagData.color_id,
      is_prioritized: isPriorityTag(tagData),
    };
    if (!isToggledOn) {
      updatedSoloTag.delete = true;
      updatedSoloTag.id = tagData.id;
    }
    backendBroadcasts.publish("UPSERT_SPECIAL_TAGS", {
      specialTags: [updatedSoloTag],
      user: selectedUser,
    });
  };

  const onClickCancel = () => {
    setTagData(originalTagData);
    setIsToggledOn(originalIsToggledOn);
    setIsOpen(false);
    setWarning("");
  };

  if (!isOpen) {
    const { color_id, color } = tagData;
    return (
      <div className="painter-section-container mt-4">
        <SpecialTagsHeader
          isOpen={isOpen}
          openSection={openSection}
          label="Solo"
        />
        <div className="secondary-text-color mt-2 default-font-size mb-6">
          Automatically tag events where you're the only attendee.
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center w-full">
            <CircleWithColor color={getColorFromID({ id: color_id, color })} />
            <div className="default-font-size ml-5">Solo</div>
          </div>

          {isPriorityTag(tagData) ? (
            <IsPrioritizedTagStar className={"mr-4"} />
          ) : null}
          <div className="default-font-size secondary-text-color mt-0.5">
            {isToggledOn ? "On" : "Off"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="painter-section-container mt-4">
      <SpecialTagsHeader
        isOpen={isOpen}
        openSection={openSection}
        label="Solo"
      />
      <div className="secondary-text-color mt-2 default-font-size">
        Automatically tag events where you're the only attendee.
      </div>
      <SpecialTagsColorPicker
        colorOptions={colorOptions}
        colorObject={tagData}
        openSection={openSection}
        isToggledOn={isToggledOn}
        additionalClassName="mt-4"
        setTagData={setTagData}
        setToggle={setIsToggledOn}
        tagType={SPECIAL_TAGS_TYPE.SOLO}
        label="Solo tag"
      />
      {warning ? (
        <div className="warning-color default-font-size mt-1">{warning}</div>
      ) : null}
      <TagsSaveCancelButton
        isOpen={isOpen}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
      />
    </div>
  );
}

function getSoloTagData({ user }) {
  const matchingTag = getSoloTagFromUser({ user });
  const hasMatchingTag = !isEmptyObjectOrFalsey(matchingTag);
  return {
    originalTagData: hasMatchingTag
      ? matchingTag
      : {
        name: SPECIAL_TAGS_TYPE.SOLO,
        ...DEFAULT_SPECIAL_COLOR_DATA,
      },
    originalIsToggledOn: hasMatchingTag ? true : false,
  };
}
