import React, { Component } from "react";
import CommandCenter from "./commandCenter";
import { withRouter } from "react-router-dom";
import {
  combinedContactAndDomainResponse,
  createAttendeeSuggestionsLabel,
  getRecentContactsMatches,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import _ from "underscore";
import Broadcast from "../broadcasts/broadcast";
import { getRecentlySearchedContacts } from "../lib/stateManagementFunctions";
import { getContactGroupMatches, getDomainAndContacts, sortContactsArrayByExactMatch } from "../lib/contactFunctions";
import {
  getCalendarFromEmail,
  determineCalendarColor,
} from "../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { FEATURE_TRACKING_ACTIONS, trackFeatureUsage } from "./tracking";
import { isGroupVoteDetailPageOpen } from "../services/appFunctions";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getUserEmail, getUserToken } from "../lib/userFunctions";
import { splitGroupedAttendees } from "../lib/eventFunctions";
import { getAbsoluteDifferenceInLengthOfStrings, truncateString } from "../lib/stringFunctions";
import { TYPING_DELAY } from "../services/globalVariables";
import { AVAILABILITY_BROADCAST_VALUES } from "../lib/broadcastValues";

const CONTACT_STRING_LENGTH = 60;

class ContactCommandCenter extends Component {
  constructor(props) {
    super(props);

    this._searchTimeout = null;

    this.state = {
      inputText: "",
      options: [],
      initialOptions: this.createInitialOptions(),
    };

    this.updateInput = this.updateInput.bind(this);
    this.createOptions = this.createOptions.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.setOptionsBasedOnSearch = this.setOptionsBasedOnSearch.bind(this);
    this.createContactOption = this.createContactOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._searchTimeout);
  }

  render() {
    if (!this.props.shouldShowCommandCenter) {
      return null;
    }
    return (
      <CommandCenter
        title="Meet with"
        handleCloseModal={this.props.handleCloseModal}
        options={this.determineOptions()}
        onChangeInput={this.updateInput}
        globalHandler={this.toggleContact}
        updateSearchResultStateOnChange={true}
        placeholder={"Search by first name, last name, or email"}
        isContactCommandCenter={true}
      />
    );
  }


  //=================
  // PRIVATE METHODS
  //=================

  determineOptions() {
    if (
      this.state.inputText.length === 0 &&
      (isEmptyArray(this.props.eventFormEmails))
    ) {
      return this.state.initialOptions;
    } else {
      return this.createOptions();
    }
  }

  async updateInput(input) {
    if (!this._isMounted) {
      return;
    }

    // if user paste -> get results immediately
    const shouldSkipDebounce = getAbsoluteDifferenceInLengthOfStrings(this.state.inputText, input) > 1;
    this.setState({ inputText: input });
    clearTimeout(this._searchTimeout);
    if (shouldSkipDebounce) {
      this.onSearchTimeout(input);
      return;
    }
    this._searchTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.onSearchTimeout(input);
    }, TYPING_DELAY);
  }

  async onSearchTimeout(input) {
    const existingEmails = this.props.eventFormEmails;

    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      currentUser
    } = this.props;

    const {
      contactResponse,
      domainResponse
    } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser,
      userEmail: getUserEmail(currentUser),
      searchText: input,
    });
    if (!this._isMounted) {
      return;
    }
    if (this.state.inputText !== input) {
      // text changed
      return;
    }

    this.setOptionsBasedOnSearch(
      domainResponse,
      contactResponse,
      existingEmails
    );
  }

  setOptionsBasedOnSearch(domainResponse, contactResponse, existingEmails) {
    if (!this._isMounted) {
      return;
    }

    const {
      inputText
    } = this.state;

    const singleEmailOptions =
      getRecentlySearchedContacts(this.props.currentUser)?.filter(
        (c) => !c.hasMultiple
      ) || [];
    const recentSearchMatches = getRecentContactsMatches(
      singleEmailOptions,
      inputText
    );
    const contactGroupMatches = getContactGroupMatches(
      this.props.currentUser,
      inputText
    );
    const nickAndRecentSearchMatches =
      recentSearchMatches.concat(contactGroupMatches);

    const filteredEmails = combinedContactAndDomainResponse(
      nickAndRecentSearchMatches,
      domainResponse,
      contactResponse,
      existingEmails
    );
    const sortedEmails = sortContactsArrayByExactMatch(filteredEmails, inputText);

    let searchResults = sortedEmails.map((c) => {
      return this.createContactOption(c);
    });

    if (searchResults.length === 0) {
      searchResults = searchResults.concat(this.createContactOption({
        email: inputText
      }));
    }

    this.setState({ options: searchResults });
  }

  createContactOption(contact) {
    const determineShowRemoveEmail = (contact) => {
      const displayString = createAttendeeSuggestionsLabel(contact);

      if (
        this.props.eventFormEmails &&
        this.props.eventFormEmails.includes(contact.email)
      ) {
        return truncateString(`Hide ${displayString}`, CONTACT_STRING_LENGTH);
      } else {
        return truncateString(`Show ${displayString}`, CONTACT_STRING_LENGTH);
      }
    };

    let displayEmail = determineShowRemoveEmail(contact);

    return {
      key: contact.email,
      title: truncateString(displayEmail, CONTACT_STRING_LENGTH),
      handlerValue: { contact: contact, email: contact.email },
      searchQueries: contact.name
        ? `${contact.email}, ${contact.name}`
        : `${contact.email}`,
    };
  }

  createOptions() {
    let options =
      this.state.inputText.length === 0
        ? this.recentUsersAndTemporaryCalendars()
        : this.state.options;

    if (this.props.eventFormEmails?.length > 0) {
      options = options.concat({
        key: "removeAllTemporarySecondaryCalendars",
        title: "Clear all searched calendars",
        onClickHandler: this.removeAllOtherCalendars.bind(this),
        searchQueries: "remove, people, clear, search, remove all, calendars",
        shortcut: "ESC",
      });
    }

    return options;
  }

  recentUsersAndTemporaryCalendars() {
    let recentUsersAndTemporaryCalendars = [];

    let eventFormUsers = _.clone(this.props.eventFormEmails) || [];
    eventFormUsers = eventFormUsers.reverse();

    let value;

    eventFormUsers.length > 0 &&
      eventFormUsers.forEach((c) => {
        value = {
          key: c + "temporaryEmails",
          title: this.createTitleForEventFormUsers(c),
          handlerValue: { contact: null, email: c },
          searchQueries: c + ", hide",
        };

        recentUsersAndTemporaryCalendars =
          recentUsersAndTemporaryCalendars.concat(value);
      });

    return recentUsersAndTemporaryCalendars;
  }

  createTitleForEventFormUsers(email) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const determineColor = () => {
      const matchingCalendar = getCalendarFromEmail({
        email,
        allLoggedInUsers,
        allCalendars,
        masterAccount,
      });
      if (matchingCalendar) {
        return determineCalendarColor(matchingCalendar);
      } else {
        return (
          this.props.temporarySecondaryCalendarColorsIndex[email] ||
          "rgb(224, 224, 224)"
        );
      }
    };

    const color = determineColor();

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {truncateString(`Hide ${email}`, CONTACT_STRING_LENGTH - 10)}

        <div
          style={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            backgroundColor: `${color}`,
            marginLeft: 20,
          }}
        ></div>
      </div>
    );
  }

  removeAllOtherCalendars() {
    Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
  }

  toggleContact(handlerValue) {
    const { 
      email, 
      contact, 
      isNewContact
    } = handlerValue;

    trackFeatureUsage({
      action: FEATURE_TRACKING_ACTIONS.MEET_WITH,
      userToken: getUserToken(this.props.currentUser)
    });

    const hasMultiple = contact?.hasMultiple;

    if (hasMultiple && contact?.emailArray) {
      // if it's search for a group contact
      Broadcast.publish("ADD_EMAIL_TO_SEARCHED_EMAILS", {
        email: contact?.emailArray,
        contact,
      });
      if (isGroupVoteDetailPageOpen()) {
        const { currentUser, emailToNameIndex } = this.props;
        const groupedAttendees = {
          emailArray: contact.emailArray,
          label: contact.name,
          name: contact.name,
          value: contact.email,
        };
        const splitAttendees = splitGroupedAttendees(
          groupedAttendees,
          currentUser,
          emailToNameIndex,
        );
        availabilityBroadcast.publish(
          AVAILABILITY_BROADCAST_VALUES.ADD_ATTENDEE_TO_GROUP_VOTE,
          splitAttendees,
        );
      }
    } else if (
      this.props.eventFormEmails?.includes(email)
    ) {
      Broadcast.publish("REMOVE_EMAIL", email);
    } else {
      Broadcast.publish("ADD_EMAIL_TO_SEARCHED_EMAILS", { email, contact });
      if (isGroupVoteDetailPageOpen()) {
        const attendee = {
          label: contact.name,
          name: contact.name,
          value: contact.email,
        };
        availabilityBroadcast.publish(
          AVAILABILITY_BROADCAST_VALUES.ADD_ATTENDEE_TO_GROUP_VOTE,
          [attendee],
        );
      }

      if (isNewContact) {
        Broadcast.publish("ADD_NEW_CONTACTS_INTO_INDEXDB", [contact]);
      }
    }
  }

  createInitialOptions() {
    return getRecentlySearchedContacts(this.props.currentUser).map((c) =>
      this.createContactOption(c)
    );
  }
}

function mapStateToProps(state) {
  let {
    eventFormEmails,
    temporarySecondaryCalendarColorsIndex,
    currentUser,
    activeCalendars,
    emailToNameIndex,
  } = state;

  return {
    eventFormEmails,
    temporarySecondaryCalendarColorsIndex,
    currentUser,
    activeCalendars,
    emailToNameIndex,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withStore(ContactCommandCenter)));
