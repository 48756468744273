import React, { useEffect } from "react";
import { OnboardStep, trackOnboarding } from "../../lib/tracking/onboardingTracking";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { Check, Key, Plus } from "react-feather";
import googleCalendarIcon from "../icons/googleCalendar.png";
import outlookIcon from "../icons/microsoft-outlook-icon.png";
import classNames from "classnames";
import ColoredLine from "../line";
import {
  OpenLink,
  isElectron,
  openLinkOnSamePage,
} from "../../services/commonUsefulFunctions";
import { getGoogleLoginURL } from "../../lib/googleFunctions";
import { getOutLookLoginURL } from "../../lib/outlookFunctions";
import Mousetrap from "mousetrap";
import { doesMagicLinkExist } from "../../services/maestroFunctions";
import { SSO_DESKTOP_LOGIN, SSO_LOGIN } from "../../services/routingFunctions";
import { useHistory } from "react-router-dom";
import { getUserEmail } from "../../lib/userFunctions";
import SaveAndSkipButtons from "./saveAndSkipButtons";

const ADD_ACCOUNT_TYPE = {
  OUTLOOK: "outlook",
  GOOGLE: "google",
  EXISTING: "existing",
  SSO: "sso",
} as const;

interface AddAdditionalAccountScreenProps {
  magicLink?: MagicLink
  step: OnboardStep
  onClickNext: () => void
  shouldShowSSOLoginButton?: boolean
}

export default function AddAdditionalAccountScreen({
  magicLink,
  step,
  onClickNext,
  shouldShowSSOLoginButton,
}: AddAdditionalAccountScreenProps) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const isMagicLink = doesMagicLinkExist({ magicLink });

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    Mousetrap.bind(["enter"], onClickNext);
    return () => {
      Mousetrap.reset();
    };
  }, []);

  const getContainerMinHeight = () => {
    // title + subtext + x * accounts + add accounts and line
    const containerHeight = 40 + 60 + 90 * 2 + 90 * 3;
    return Math.max(600, containerHeight);
  };

  return (
    <div
      className="onboarding-container"
      style={{ minHeight: getContainerMinHeight() }}
    >
      <div className="section-title">Connect all your accounts</div>
      <div className="welcome-subtitle mt-3 mb-4">
        See all your work and personal calendars in one unified view.
      </div>

      {allLoggedInUsers.map((user) => (
        <AddAccountButton
          key={user.token}
          email={getUserEmail(user) ?? ""}
          type={ADD_ACCOUNT_TYPE.EXISTING}
        />
      ))}

      <ColoredLine inputClassName="mb-6 mt-10" width={256} />
      <AddAccountButton isMagicLink={isMagicLink} type={ADD_ACCOUNT_TYPE.GOOGLE} />
      <AddAccountButton isMagicLink={isMagicLink} type={ADD_ACCOUNT_TYPE.OUTLOOK} />
      {shouldShowSSOLoginButton ? (
        <AddAccountButton isMagicLink={isMagicLink} type={ADD_ACCOUNT_TYPE.SSO} />
      ) : null}

      <SaveAndSkipButtons
        onClickNext={onClickNext}
        onClickSave={onClickNext}
        shouldHideSkip={isMagicLink}
      />
    </div>
  );
}

interface AddAccountButtonProps {
  isMagicLink?: boolean
  email?: string
  type: ValueOf<typeof ADD_ACCOUNT_TYPE>
}

function AddAccountButton({ isMagicLink, email="", type }: AddAccountButtonProps) {
  const history = useHistory();
  const getButtonlabel = () => {
    if (type === ADD_ACCOUNT_TYPE.EXISTING) {
      return email;
    }
    if (type === ADD_ACCOUNT_TYPE.OUTLOOK) {
      return "Add Outlook account";
    }
    if (type == ADD_ACCOUNT_TYPE.SSO) {
      return "Add through SSO";
    }
    return "Add Google account";
  };

  const onClickGoogleLogin = () => {
    openLinkOnSamePage(
      getGoogleLoginURL({
        isDesktopLogin: false,
        isInOnboarding: true,
        isMagicLink,
      }),
    );
  };

  const onClickSSO = () => {
    if (isElectron()) {
      OpenLink(SSO_DESKTOP_LOGIN);
    } else {
      history.push(SSO_LOGIN);
    }
  };

  const onClickOutlook = () => {
    openLinkOnSamePage(
      getOutLookLoginURL({
        isDesktopLogin: false,
        isInOnboarding: true,
        isMagicLink,
      }),
    );
  };

  const onClick = () => {
    if (type === ADD_ACCOUNT_TYPE.OUTLOOK) {
      onClickOutlook();
    } else if (type === ADD_ACCOUNT_TYPE.GOOGLE) {
      onClickGoogleLogin();
    } else if (type === ADD_ACCOUNT_TYPE.SSO) {
      onClickSSO();
    }
  };

  const getIcon = () => {
    switch (type) {
      case ADD_ACCOUNT_TYPE.OUTLOOK:
        return <img src={outlookIcon} alt="" width={20} height={20} />;
      case ADD_ACCOUNT_TYPE.SSO:
        return <Key size={16} />;
      default:
        return <img src={googleCalendarIcon} alt="" width={20} height={20} />;
    }
  };

  const getRightSideIcon = () => {
    if (type === ADD_ACCOUNT_TYPE.EXISTING) {
      return (
        <div
          className="rounded-full w-5 h-5 flex items-center justify-center"
          style={{ backgroundColor: "rgba(61, 186, 134, 1)" }}
        >
          <Check size={14} color="white" />
        </div>
      );
    }
    return getIcon();
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-between font-size-14 w-64 h-12 min-height-48px",
        "px-4 rounded-md",
        "mt-4",
        "select-none",
        type === ADD_ACCOUNT_TYPE.EXISTING
          ? "pink-border"
          : "hoverable-border cursor-pointer",
        "default-font-color",
      )}
      key={`add-account-${type}-${email ?? "new"}`}
      onClick={onClick}
    >
      <div className="flex items-center">
        {type !== ADD_ACCOUNT_TYPE.EXISTING ? (
          <Plus size={16} className="mr-2" />
        ) : null}
        <div className="truncate-text max-width-180px">{getButtonlabel()}</div>
      </div>

      {getRightSideIcon()}
    </div>
  );
}
