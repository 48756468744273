// Read events from main calendar and react to changes

import { useEffect } from "react";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { useSelector } from "react-redux";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import {
  getEventCalendarProviderID,
  getMeetWithEventCalendarEmail,
  isAllDayEvent,
  isBusyEvent,
  isEventWithinDateRange,
  isEventWithinTimeRange,
} from "../../lib/eventFunctions";
import { getEventUserCalendarID } from "../../services/eventResourceAccessors";
import {
  getAllCalendarEmails,
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  getHumanReadableEmailFromUserCalendarID,
} from "../../lib/calendarFunctions";
import { useAllCalendars } from "../../services/stores/SharedAccountData";
import { getCalendarEmail } from "../../services/calendarAccessors";
import { AVAILABILITY_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { addMinutes, subMinutes } from "date-fns";
import { isSameOrAfterMinute, isSameOrBeforeMinute } from "../../services/commonUsefulFunctions";
import { SLOTS_IN_BUFFER_AFTER_CONFLICTS, SLOTS_IN_BUFFER_BEFORE_CONFLICTS } from "../../lib/copy";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { isActionModeCreateAvailability } from "../../services/appFunctions";

// reduce logic in mainCalendar.js
export default function MainCalendarEventsReaderHelper({ mainCalendarEvents }) {
  const actionMode = useSelector((state) => state.actionMode);
  const componentIsMounted = useIsMounted();
  const allCalendars = useAllCalendars((state) => state.allCalendars);

  const checkForBlockingConflict = ({
    eventsToCheck,
    blockingUserCalendarID,
    blockedAttendeeEmails,
    additionalEvents = null, // additional events ontop of mainCalendar::allEvent to check
    bufferBeforeEvent = 0, // note: buffer from now is already taken care of
    bufferAfterEvent = 0,
  }) => {
    if (!isActionModeCreateAvailability(actionMode) || !componentIsMounted.current) {
      return;
    }
    if (isEmptyArrayOrFalsey(eventsToCheck)) {
      // nothing to check, remove warning
      availabilityBroadcast.publish(
        AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING,
        null,
      );
      return;
    }

    const allEvents = mainCalendarEvents.concat(additionalEvents || []);
    if (isEveryEventInBeforeBuffer({eventsToCheck, bufferBeforeEvent, allEvents})) {
      availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.SET_GENERIC_SLOTS_WARNING, SLOTS_IN_BUFFER_BEFORE_CONFLICTS);
    } else if (isEveryEventInAfterBuffer({eventsToCheck, bufferAfterEvent, allEvents})) {
      availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.SET_GENERIC_SLOTS_WARNING, SLOTS_IN_BUFFER_AFTER_CONFLICTS);
    } else {
      availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.REMOVE_SLOTS_BEFORE_AFTER_CONFLICT_WARNING);
    }

    const allDayEvents = allEvents.filter(
      (event) =>
        isBusyEvent(event) &&
        (blockingUserCalendarID?.includes(getEventUserCalendarID(event)) ||
          blockedAttendeeEmails?.includes(
            getMeetWithEventCalendarEmail(event)
          ) ||
          blockedAttendeeEmails?.includes(getEventCalendarProviderID(event))) &&
        isAllDayEvent(event)
    );
    if (isEmptyArrayOrFalsey(allDayEvents)) {
      // no warning and no need to continue
      availabilityBroadcast.publish(
        AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING,
        null
      );
      return;
    }
    // check if any of the temporary events are in the way of the all day events
    let blockingAllDayEvents = [];
    let blockedEvents = [];
    eventsToCheck.forEach((event) => {
      const blockingEvents = getEventsInsideAllDayEvents({
        allDayEvents,
        event,
      });
      if (blockingEvents.length > 0) {
        blockingAllDayEvents = blockingAllDayEvents.concat(blockingEvents);
        blockedEvents = blockedEvents.concat(event);
      }
    });

    if (isEmptyArrayOrFalsey(blockedEvents)) {
      // early return and remove error
      availabilityBroadcast.publish(
        AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING,
        null
      );
      return;
    }

    const getBlockedCalendarEmail = () => {
      if (getMeetWithEventCalendarEmail(blockingAllDayEvents[0])) {
        return getMeetWithEventCalendarEmail(blockingAllDayEvents[0]);
      }
      const userCalendarID = getEventUserCalendarID(blockingAllDayEvents[0]);
      const humanReadableEmailFromUserCalendarID =
        getHumanReadableEmailFromUserCalendarID(userCalendarID, allCalendars);
      if (humanReadableEmailFromUserCalendarID) {
        return humanReadableEmailFromUserCalendarID;
      }
      const matchingCalendar = getCalendarFromUserCalendarID({
        userCalendarID,
        allCalendars,
      });
      const calendarEmail = getCalendarEmail(matchingCalendar);
      if (calendarEmail?.length > 50) {
        return (
          getCalendarUserEmail(matchingCalendar) ||
          matchingCalendar?.calendar?.user_email
        );
      }
      return calendarEmail;
    };

    const blockingEmail = getBlockedCalendarEmail();

    const isFromCheckForConflicts =
      blockingAllDayEvents[0]?.isCheckForConflictEventCalendar;

    const allCalendarEmails = getAllCalendarEmails(allCalendars);
    const isMyCalendar = allCalendarEmails.includes(blockingEmail);

    const getWarningCopy = () => {
      if (isMyCalendar) {
        if (!blockingEmail) {
          return `Some Slots will be unavailable due to all-day 'busy' events under 'Check For conflict'. Set them as 'free' or select times on other days.`;
        }
        return `Some Slots will be unavailable due to all-day 'busy' events on ${blockingEmail}${
          isFromCheckForConflicts ? " (under 'Check For conflict')" : ""
        }. Set them as 'free' or select times on other days.`;
      }
      if (!blockingEmail) {
        return "Some Slots will be unavailable due to all-day 'busy' events. Set them as 'free' or select times on other days.";
      }
      return `Some Slots will be unavailable due to all-day 'busy' events on ${blockingEmail}. Ask to set them as 'free' or select times on other days.`;
    };
    // show warning
    availabilityBroadcast.publish(
      AVAILABILITY_BROADCAST_VALUES.SET_ALL_DAY_SLOTS_WARNING,
      getWarningCopy()
    );
  };

  useEffect(() => {
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.CHECK_FOR_BLOCKING_CALENDARS,
      checkForBlockingConflict
    );
    return () => {
      availabilityBroadcast.unsubscribe(
        AVAILABILITY_BROADCAST_VALUES.CHECK_FOR_BLOCKING_CALENDARS
      );
    };
  }, [mainCalendarEvents, actionMode, allCalendars]);

  useEffect(() => {
    if (isActionModeCreateAvailability(actionMode)) {
      availabilityBroadcast.publish("CHECK_SLOTS_ALL_DAY_BLOCKING_EVENTS");
    }
  }, [mainCalendarEvents, actionMode]);

  useEffect(() => {
    // unsubscribe on unmount
    return () => {
      availabilityBroadcast.unsubscribe(
        AVAILABILITY_BROADCAST_VALUES.CHECK_FOR_BLOCKING_CALENDARS
      );
    };
  }, []);

  return null;
}

function getEventsInsideAllDayEvents({ allDayEvents, event }) {
  return allDayEvents.filter((allDayEvent) => {
    if (allDayEvent?.isCheckForConflictEventCalendar) {
      // AvailabilityPanel::getCheckForConflictBlockedEvents gets allday events as a 24 hour range so just need to check time
      return isEventWithinTimeRange({
        event,
        timeRangeStart: allDayEvent.eventStart,
        timeRangeEnd: allDayEvent.eventEnd,
      });
    }
    return isEventWithinDateRange({
      event,
      timeRangeStart: allDayEvent.eventStart,
      timeRangeEnd: allDayEvent.eventEnd,
    });
  });
}

function isEveryEventInBeforeBuffer({
  eventsToCheck,
  bufferBeforeEvent,
  allEvents,
}) {
  // if the every event in eventsToCheck is within the allEvents.eachEvent.eventStart - bufferBeforeEvent
  if (!bufferBeforeEvent || isEmptyArrayOrFalsey(allEvents) || isEmptyArrayOrFalsey(eventsToCheck)) {
    return false;
  }

  return eventsToCheck.every(slot => {
    return allEvents.some(event => {
      return isSameOrAfterMinute(slot.eventStart, subMinutes(event.eventStart, bufferBeforeEvent))
        && isSameOrBeforeMinute(slot.eventEnd, event.eventStart);
    });
  });
}

function isEveryEventInAfterBuffer({
  eventsToCheck,
  bufferAfterEvent,
  allEvents,
}) {
  // if the every event in eventsToCheck is within the allEvents.eachEvent.eventStart - bufferBeforeEvent
  if (!bufferAfterEvent || isEmptyArrayOrFalsey(allEvents) || isEmptyArrayOrFalsey(eventsToCheck)) {
    return false;
  }

  return eventsToCheck.every(slot => {
    return allEvents.some(event => {
      return isSameOrAfterMinute(slot.eventStart, event.eventEnd)
        && isSameOrBeforeMinute(slot.eventEnd, addMinutes(event.eventEnd, bufferAfterEvent));
    });
  });
}
