import React, { PureComponent } from "react";
import * as chrono from "chrono-node";
import Classnames from "classnames";
import MonthlyCalendar from "./monthlyCalendar";
import { X } from "react-feather";
import {
  convertDateFieldOrderToJSDateOrder,
  isValidJSDate,
  parseDateChronoDMY,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import StyleConstants from "../services/globalVariables";
import Broadcast from "../broadcasts/broadcast";
import { MOMENT_DMY_DATE_FORMAT } from "../services/googleCalendarService";
import { format, isSameDay } from "date-fns";
import classNames from "classnames";
import { getInputStringFromEvent } from "../lib/stringFunctions";

class EventFormDate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      input: format(
        this.props.date,
        convertDateFieldOrderToJSDateOrder(this.props.dateFieldOrder)
      ),
      isFocused: false,
      isModalOpen: false,
      modalDate: this.props.date,
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.convertToProperDate = this.convertToProperDate.bind(this);
    this.setDateFromModal = this.setDateFromModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    Broadcast.subscribe(
      `CLOSE_EVENT_FORM_MONTH_MODAL_${props.isStartDate ? "START" : "END"}`,
      this.closeModal
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.date &&
      this.props.date &&
      isValidJSDate(this.props.date) &&
      !isSameDay(prevProps.date, this.props.date)
    ) {
      this.setState({
        input: format(
          this.props.date,
          convertDateFieldOrderToJSDateOrder(this.props.dateFieldOrder)
        ),
        modalDate: this.props.date,
      });
    }

    if (this.props.hideMonthlyCalendar !== prevProps.hideMonthlyCalendar) {
      return true;
    }
  }

  componentWillUnmount() {
    Broadcast.unsubscribe(
      `CLOSE_EVENT_FORM_MONTH_MODAL_${this.props.isStartDate ? "START" : "END"}`
    );
  }

  render() {
    return (
      <div>
        {(this.state.isFocused || this.state.isModalOpen) &&
          this.renderCoverOverLay()}

        <input
          ref={this.props.innerRef}
          id={this.props.id}
          autoFocus={this.props.autoFocus}
          className={Classnames(
            this.determineBackgroundColor(),
            "event-form-start-end-button",
            "event-form-date-input",
            this.props.additionalClassname ?? ""
          )}
          style={{ zIndex: this.state.isFocused ? 5 : 0 }}
          tabIndex={this.props.tabIndex}
          onBlur={this.onBlur}
          onFocus={this.setFocus}
          type="text"
          value={this.state.input}
          onChange={(e) => this.onChangeInput(getInputStringFromEvent(e))}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />

        {this.renderMonthlyModal()}
      </div>
    );
  }

  renderCoverOverLay() {
    return (
      <div
        onClick={this.closeModal}
        className="layout-over-lay"
        style={{ zIndex: 3, left: "-10000px" }}
      ></div>
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter" || e.key === "Tab") {
      this.convertToProperDate();

      this.closeModal();

      if (e.key === "Enter" && this.props.onComplete) {
        this.props.onComplete();
      }
    } else if (e.key === "Escape") {
      if (this.state.isFocused) {
        this.props.innerRef?.current?.blur();
      }

      if (this.state.isModalOpen) {
        this.setState({ isModalOpen: false });
      }

      this.props.onEscape && this.props.onEscape();
    }
  }

  renderMonthlyModal() {
    if (this.props.alwaysShowModal) {
      // skip early return loginc
    } else if (!this.state.isFocused && !this.state.isModalOpen) {
      return null;
    }

    return (
      <div
        className={this.getModalContainerClassnames()}
      >
        {this.props.alwaysShowModal ? null : (
          <X
            color="#707070"
            size={20}
            className={Classnames("clickable-icon", "monthly-modal-close")}
            onClick={this.closeModal}
          />
        )}

        <div
          className={classNames(
            "monthly-modal-title",
            this.props.alwaysShowModal
              ? "top-0px-override left-16px-override"
              : ""
          )}
        >
          {this.props.modalTitle}
        </div>

        <div
          style={
            this.props.alwaysShowModal ? {} : { marginTop: 30, paddingTop: 10 }
          }
        >
          <MonthlyCalendar
            modalCalendarSelectedDay={this.state.modalDate}
            onNavigateToDate={this.setDateFromModal}
            noSaveButton={true}
          />
        </div>
      </div>
    );
  }

  getModalContainerClassnames() {
    if (this.props.alwaysShowModal) {
      return classNames(
        "absolute top-12",
        this.props.isStartDate ? "-left-4" : "-left-122px",
        this.props.hideMonthlyCalendar ? "invisible" : "",
        this.props.modalClassName ?? ""
      );
    }

    return "event-form-select-date-modal";
  }

  setDateFromModal(date) {
    const selectedDate = format(
      date,
      convertDateFieldOrderToJSDateOrder(this.props.dateFieldOrder)
    );

    this.setState(
      { input: selectedDate, modalDate: date, isModalOpen: false },
      () => {
        this.convertToProperDate();
      }
    );
  }

  onBlur() {
    if (this.props.preventSetDateOnBlur) {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
      this.setState({ isFocused: false });
      return;
    }

    this.convertToProperDate();
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  setFocus() {
    this.setState({ isFocused: true, isModalOpen: true });
    if (this.props.onFocus) {
      this.props.onFocus(this.props.isStartDate);
    }
  }

  determineBackgroundColor() {
    if (this.props.isInvalid) {
      return "problem-background-color";
    } else if (this.props.highlightField) {
      return "highlight-field-color";
    } else if (this.props.isInvalid) {
      return "";
    } else {
      return "";
    }
  }

  determineTextColor() {
    if (
      this.state.isFocused ||
      (this.props.isDarkMode && this.props.isInvalid)
    ) {
      return StyleConstants.defaultFontColor;
    }

    return this.props.isDarkMode
      ? StyleConstants.darkModeTextColor
      : StyleConstants.defaultFontColor;
  }

  convertToProperDate() {
    let chronoDate =
      this.props.dateFieldOrder === MOMENT_DMY_DATE_FORMAT
        ? parseDateChronoDMY(this.state.input)
        : chrono.en.parseDate(this.state.input, new Date(), {
            forwardDate: true,
          });

    let newDate = this.props.date;

    if (!chronoDate) {
      // check for 20th aug
      chronoDate = chrono.en.parseDate(this.state.input, new Date(), {
        forwardDate: true,
      });
    }
    if (!chronoDate) {
      const revertedDate = format(
        this.props.date,
        convertDateFieldOrderToJSDateOrder(this.props.dateFieldOrder)
      );
      this.setState({ input: revertedDate, isFocused: false }); // revert if it still fails
      this.props.onBlur && this.props.onBlur();
      return;
    }

    if (chronoDate) {
      newDate = format(
        chronoDate,
        convertDateFieldOrderToJSDateOrder(this.props.dateFieldOrder)
      );
    }

    this.setState({ input: newDate, isFocused: false });

    if (chronoDate) {
      this.props.sendData(chronoDate);
    }

    this.props.onBlur && this.props.onBlur();
  }

  onChangeInput(input) {
    let chronoDate =
      this.props.dateFieldOrder === MOMENT_DMY_DATE_FORMAT
        ? parseDateChronoDMY(input)
        : chrono.en.parseDate(input, new Date(), { forwardDate: true });

    if (!chronoDate) {
      // check for 20th aug
      chronoDate = chrono.en.parseDate(input, new Date(), {
        forwardDate: true,
      });
    }

    if (chronoDate) {
      this.setState({ modalDate: chronoDate, input: input });
    } else {
      this.setState({ modalDate: this.props.date, input: input });
    }
  }
}

function mapStateToProps(state) {
  let { isDarkMode, dateFieldOrder } = state;

  return {
    isDarkMode,
    dateFieldOrder,
  };
}

export default connect(mapStateToProps)(EventFormDate);
