import React from "react";
import SaveButton from "../saveButton";

interface OnboardingSaveButtonProps {
  buttonText: React.ReactNode
  hideDefaultIcon?: boolean
  isDisabled?: boolean
  marginTopOverride?: number | string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  shortcut?: string
  width?: number | string
}

export default function OnboardingSaveButton({
  buttonText,
  hideDefaultIcon,
  isDisabled,
  marginTopOverride,
  onClick,
  shortcut,
  width,
}: OnboardingSaveButtonProps) {
  return (
    <SaveButton
      style={{
        width: width ?? 350,
        height: 50,
        marginTop: marginTopOverride ?? 30,
        justifyContent: hideDefaultIcon ? "center" : "space-between",
      }}
      onClick={onClick}
      buttonText={buttonText}
      shortcut={hideDefaultIcon ? null : shortcut || "Enter"}
      hideDefaultIcon={hideDefaultIcon}
      disabled={isDisabled}
    />
  );
}
