import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useAllLoggedInUsers } from "../../services/stores/SharedAccountData";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import {
  getAllNonDelegateUsers,
  getMatchingNonDelegatedUserFromAllUsers,
  getUserEmail,
  getUserToken,
} from "../../lib/userFunctions";
import {
  constructQueryParams,
  handleError,
} from "../../services/commonUsefulFunctions";
import { constructRequestURLV2 } from "../../services/api";
import {
  GET_CALENDAR_ONLY_ENDPOINT,
  MAGIC_LINK_ENDPOINTS,
} from "../../lib/endpoints";
import Fetcher from "../../services/fetcher";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import Login from "../../views/login";
import Onboarding from "../onboarding/onboardingContainer";
import { HOME_PATH, LOGIN_PATH } from "../../services/routingFunctions";
import {
  getMagicLinkTokenFromSearchParams,
} from "../../services/maestro/maestroRouting";
import usePrevious from "../specialComponents/usePrevious";
import { useMagicLink } from "../../services/stores/magicLinkStore";
import { getMagicLinkConsumed } from "../../services/maestro/maestroAccessors";
import SpinnerV2 from "../spinnerV2";

/* Determines whether to show login or onboarding page */
function MagicLink() {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const componentIsMounted = useIsMounted();
  const history = useHistory();
  const { magicLinkToken, setMagicLinkToken } = useMagicLink();
  const previousAllLoggedInUsers = usePrevious(allLoggedInUsers);
  const [magicLink, setMagicLink] = useState({} as MagicLink);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  /* Check for magic link token in params */
  const urlMagicLinkToken = getMagicLinkTokenFromSearchParams();
  /* Track calendar list for allLoggedInUsers for second onboarding page */
  const [magicLinkAllCalendars, setMagicLinkAllCalendars] = useState({});
  /* Logged out / not logged into required user */
  /* Or magic link has been used */
  const shouldDisplaySignup = isEmptyObjectOrFalsey(currentUser) ||
    !getMatchingNonDelegatedUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail: magicLink?.email,
    }) ||
    getMagicLinkConsumed({ magicLink });

  /* Handle magic link token and redirect if necessary */
  useEffect(() => {
    /* Fetch and set magicLink */
    async function fetchMagicLink() {
      const path = MAGIC_LINK_ENDPOINTS.GET_MAGIC_LINK;
      const params = {
        magic_link_token: urlMagicLinkToken || magicLinkToken,
      };
      const queryParams = constructQueryParams(params);
      const url = `${constructRequestURLV2(path)}?${queryParams}`;

      try {
        const response: any = await Fetcher.get(url);

        if (
          !componentIsMounted?.current ||
          isEmptyObjectOrFalsey(response) ||
          isEmptyObjectOrFalsey(response?.magic_link)
        ) {
          setShouldDisplayError(true);
          return;
        }

        setMagicLink(response.magic_link);
      } catch (error) {
        handleError(error);
      }
    }

    /* Always set the token in storage if one exists in URL */
    if (urlMagicLinkToken) {
      setMagicLinkToken(urlMagicLinkToken);
    } else {
      /* If we don't have a url token */
      /* Check if token is missing and redirect if it is */
      if (!magicLinkToken) {
        isEmptyObjectOrFalsey(currentUser)
          ? history.push(`/${LOGIN_PATH}`)
          : history.push(`/${HOME_PATH}`);
      }
    }

    /* If token exists, fetch the magic link using it */
    fetchMagicLink();
  }, []);

  /* Handle fetching calendars for second onboarding page */
  /* Prevents empty page / having to wait */
  useEffect(() => {
    /* We don't want to show delegated users */
    const allNonDelegatedUsers = getAllNonDelegateUsers(allLoggedInUsers);
    const previousAllNonDelegatedUsers = getAllNonDelegateUsers(
      previousAllLoggedInUsers,
    );

    /* Check for new users */
    const newUsers = allNonDelegatedUsers?.filter(
      (user) =>
        /* User not found in previous users */
        !previousAllNonDelegatedUsers?.find(
          (previousUser) => getUserToken(previousUser) === getUserToken(user),
        ),
    );

    /* Do nothing if there are no new users */
    if (newUsers?.length === 0) {
      return;
    }

    /* Fetch and set magicLinkAllCalendars */
    async function fetchUserCalendars(inputUser) {
      const url = constructRequestURLV2(GET_CALENDAR_ONLY_ENDPOINT);

      try {
        const response: any = await Fetcher.get(
          url,
          {},
          true,
          getUserEmail(inputUser),
        );

        if (
          !componentIsMounted?.current ||
          isEmptyObjectOrFalsey(response) ||
          isEmptyArrayOrFalsey(response?.calendars)
        ) {
          return;
        }

        const userEmail = getUserEmail(inputUser);
        /* Must pass in previousMagicLinkAllCalendars due to concurrent requests */
        /* This way we don't overwrite the response from other fetches */
        setMagicLinkAllCalendars((previousMagicLinkAllCalendars) => ({
          ...previousMagicLinkAllCalendars,
          [userEmail as string]: response?.calendars?.map(calendar => ({
            calendar,
            selected: true,
            userEmail,
          })),
        }));
      } catch (error) {
        handleError(error);
      }
    }

    /* Loop through users and sync calendars */
    newUsers.forEach((user) => fetchUserCalendars(user));
  }, [allLoggedInUsers]);

  /* Haven't fetched magic link yet */
  /* Or failed to fetch magic link */
  if (isEmptyObjectOrFalsey(magicLink) && !shouldDisplayError) {
    /* Show spinner */
    return (
      <div className="height-100vh w-full flex flex-col justify-center items-center">
        <SpinnerV2 variant="big" />
      </div>
    );
  }

  if (shouldDisplaySignup || shouldDisplayError) {
    return (
      <Login
        authenticated={!isEmptyObjectOrFalsey(currentUser)}
        isMagicLink={true}
        isSignup={true}
        magicLink={magicLink}
        shouldDisplayMagicLinkError={shouldDisplayError}
      />
    );
  }

  /* User is logged into the proper account */
  return (
    <Onboarding
      magicLink={magicLink}
      magicLinkAllCalendars={magicLinkAllCalendars}
      setMagicLinkAllCalendars={setMagicLinkAllCalendars}
    />
  );
}

export default MagicLink;
