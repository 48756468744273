import React, { Component } from "react";
import CustomButton from "./customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../services/globalVariables";
import GoogleCalendarService from "../services/googleCalendarService";
import {
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
} from "../services/commonUsefulFunctions";
import classNames from "classnames";
import RadioButton from "./radioButton";
import { isNullOrUndefined } from "../services/typeGuards";

let {
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
} = GoogleCalendarService;

// When updating events, some events do not allow "this event", some do not allow "this and following" as option and others won't allow "all options" as an option.
const ALL_OPTIONS = [
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
];

const OPTIONS_WITHOUT_THIS_AND_FOLLOWING = [
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_ALL_INSTANCES,
];

const OPTIONS_WITHOUT_THIS_EVENT_OPTION = [
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
];

// e.g. when you change dates on repeat -> only show this and following
const OPTIONS_WITHOUT_ALL_INSTANCES = [
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
];

class WarningUpdateRecurringEvent extends Component {
  constructor(props) {
    super(props);

    let options = this.determineOptions();

    this.state = {
      option: options[0],
    };

    this.onSelectOption = this.onSelectOption.bind(this);
    this.onClickOk = this.onClickOk.bind(this);
    this.keydown = this.keydown.bind(this);

    document.addEventListener("keydown", this.keydown);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    document.removeEventListener("keydown", this.keydown);
  }

  render() {
    return (
      <div className="margin-top-twenty">
        {this.renderSelection()}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 30,
          }}
        >
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={this.props.cancel}
            label="Cancel"
            addPaddingToRight={true}
          />

          <CustomButton
            buttonType={BLUE_BUTTON}
            shouldFocus={true}
            onClick={this.onClickOk}
            label="OK"
          />
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderSelection() {
    let repeatOptions = this.determineOptions();

    return repeatOptions.map((o, index) => {
      const isSelected = this.state.option === o;
      return (
        <div
          key={`renderUpdateRecurringEventChoices_${index}`}
          className="round-button-text-wrapper"
        >
          <RadioButton isSelected={isSelected} onClick={() => this.onSelectOption(o)} />

          <div
            onClick={() => this.onSelectOption(o)}
            className={classNames(
              "margin-left-five",
              "cursor-pointer",
              "select-none",
              "font-size-14",
              "default-font-color"
            )}
          >
            {o}
          </div>
        </div>
      );
    });
  }

  determineOptions() {
    if (this.props.withoutThisEventChoice) {
      return OPTIONS_WITHOUT_THIS_EVENT_OPTION;
    } else if (this.props.withoutThisAndFollowing) {
      return OPTIONS_WITHOUT_THIS_AND_FOLLOWING;
    } else if (this.props.withoutAllInstances) {
      return OPTIONS_WITHOUT_ALL_INSTANCES;
    } else {
      return ALL_OPTIONS;
    }
  }

  //================
  // EVENT HANDLERS
  //================

  onSelectOption(option) {
    this.setState({ option });
  }

  onClickOk() {
    this.props.onClick(this.state.option);
  }

  keydown(e) {
    if (!this._isMounted) {
      return;
    }

    let keyCode = e.keyCode;

    if (keyCode !== KEYCODE_UP_ARROW && keyCode !== KEYCODE_DOWN_ARROW) {
      return;
    }

    let allOptions = this.determineOptions();

    let indexOfCurrentOption;
    allOptions.forEach((v, index) => {
      if (v === this.state.option) {
        indexOfCurrentOption = index;
      }
    });

    if (isNullOrUndefined(indexOfCurrentOption)) {
      return;
    }

    if (keyCode === KEYCODE_UP_ARROW) {
      if (indexOfCurrentOption === 0) {
        this.onSelectOption(allOptions[allOptions.length - 1]);
      } else {
        this.onSelectOption(allOptions[indexOfCurrentOption - 1]);
      }
    } else if (keyCode === KEYCODE_DOWN_ARROW) {
      if (indexOfCurrentOption === allOptions.length - 1) {
        this.onSelectOption(allOptions[0]);
      } else {
        this.onSelectOption(allOptions[indexOfCurrentOption + 1]);
      }
    }
  }
}

export default WarningUpdateRecurringEvent;
