import React from "react";
import { DARK_MODE_SECONDARY_TEXT_COLOR, LIGHT_MODE_SECONDARY_TEXT_COLOR } from "../../../../services/globalVariables";

const AutoTagStar = ({isDarkMode}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41732 8.79167L3.06315 5.8125L0.0839844 4.45833L3.06315 3.10417L4.41732 0.125L5.77148 3.10417L8.75065 4.45833L5.77148 5.8125L4.41732 8.79167ZM8.75065 9.875L8.07357 8.38542L6.58398 7.70833L8.07357 7.03125L8.75065 5.54167L9.42773 7.03125L10.9173 7.70833L9.42773 8.38542L8.75065 9.875Z"
			fill={isDarkMode ? DARK_MODE_SECONDARY_TEXT_COLOR : LIGHT_MODE_SECONDARY_TEXT_COLOR}
    />
  </svg>
);

export default AutoTagStar;
