import React from "react";

interface BlockWrapperProps {
  children: React.ReactNode
  title: string
}

export default function BlockWrapper({ children, title }: BlockWrapperProps) {
  return (
    <div className="flex-grow basis-0 flex flex-col justify-between items-stretch h-full rounded-2xl p-7 modal-selected-option-background-color">
      <div className="font-size-16">{title}</div>
      {children}
    </div>
  );
}
