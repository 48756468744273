import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { Archive, Edit2 } from "react-feather";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { createOutstandingSlotTemporaryEvent } from "../../lib/temporaryEventFunctions";
import OutstandingSlotPreview from "./outstandingSlotPreview";
import { hasStopEventPropagation } from "../../services/commonUsefulFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import {
  parseOutstandingSlotCreatedAt,
  getOutstandingSlotPlaceholderTitle,
  convertTimeSlotStartTime,
  convertTimeSlotEndTime,
  getOutstandingSlotSlug,
  getTimeSlotIndex,
  getOutstandingSlotTitle,
} from "../../lib/outstandingSlotFunctions";
import ArchiveSlotModal from "../modal/archiveSlotModal";
import produce from "immer";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import { sanitize } from "dompurify";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { SECOND_IN_MS } from "../../services/globalVariables";
import { isUndefined } from "../../services/typeGuards";
import { trackFeatureUsage } from "../tracking";
import { getUserToken } from "../../lib/userFunctions";
import DefaultSwitch from "../defaultSwitch";
import Recycle from "../icons/recycle";
import ShortcutHoverHint from "../shortcutHoverHint";
import { createTemporaryEvent } from "../../lib/availabilityFunctions";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { blurCalendar } from "../../services/appFunctions";

interface OutstandingSlotOptionProps {
  currentTimeZone: string;
  dateFieldOrder: DateFieldOrder;
  format24HourTime: boolean;
  outstandingSlot: OutstandingSlot;
  index: number;
}

export default function OutstandingSlotOption({
  currentTimeZone,
  dateFieldOrder,
  format24HourTime,
  outstandingSlot,
  index,
}: OutstandingSlotOptionProps) {
  const defaultTimeZone = useSelector(state => state.defaultBrowserTimeZone);
  const { creationDate, creationTime } = parseOutstandingSlotCreatedAt({
    dateFieldOrder,
    format24HourTime,
    outstandingSlot,
    timeZone: defaultTimeZone || currentTimeZone,
  });

  const temporaryEvents = useSelector((state) => state.temporaryEvents) || [];
  const currentUser = useSelector((state) => state.currentUser);
  const { resetHoveredSlotsSlug, setHoveredSlotsSlug } = useTemporaryStateStore();

  const [title, setTitle] = useState(outstandingSlot.slot_title);
  const [isActive, setIsActive] = useState(
    temporaryEvents.some((event) => event.slotSlug === outstandingSlot.slug)
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<number>();

  const { masterAccount } = useMasterAccount();
  // TODO: Remove type casting once store is migrated to TS.
  const username =
    outstandingSlot.username ??
    (masterAccount as MasterAccount).settings.username;

  const removeSlotFromTemporaryEvents = () => {
    const filteredTemporaryEvents = temporaryEvents.filter(
      (event) => event.slotSlug !== outstandingSlot.slug
    );
    mainCalendarBroadcast.publish(
      "SET_TEMPORARY_EVENTS",
      filteredTemporaryEvents
    );
  };

  const placeholderTitle = getOutstandingSlotPlaceholderTitle(
    outstandingSlot
  );

  const updateTemporaryEventTitles = (newTitle: string | null) => {
    if (!isActive) {
      return;
    }

    const newEvents = produce(temporaryEvents, (draftTemporaryEvents) => {
      draftTemporaryEvents.forEach((event) => {
        if (event.slotSlug === outstandingSlot.slug) {
          event.summaryUpdatedWithVisibility =
            newTitle || `Created ${placeholderTitle}`;
        }
      });
    });
    mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", newEvents);
  };

  useEffect(() => {
    const slotEventsInTemporaryEvents = temporaryEvents.filter(
      (event) => event.slotSlug === outstandingSlot.slug
    );

    // If the slot was marked active and the events are not already in temporary events, add the events.
    if (isActive && slotEventsInTemporaryEvents.length === 0) {
      const newTemporaryEvents = outstandingSlot.time_slots.map(
        (timeSlot) =>
          createOutstandingSlotTemporaryEvent({
            startTime: convertTimeSlotStartTime(timeSlot, currentTimeZone),
            endTime: convertTimeSlotEndTime(timeSlot, currentTimeZone),
            slug: getOutstandingSlotSlug(outstandingSlot),
            title:
              getOutstandingSlotTitle(outstandingSlot) ||
              `Created ${placeholderTitle}`,
            index: getTimeSlotIndex(timeSlot),
          }) as TemporaryEvent
      );
      mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", [
        ...temporaryEvents,
        ...newTemporaryEvents,
      ]);
    }

    // If the slot was marked as inactive and the events are in temporary events, remove them.
    if (!isActive && slotEventsInTemporaryEvents.length > 0) {
      removeSlotFromTemporaryEvents();
    }
  }, [isActive]);

  const toggleActive: React.MouseEventHandler = () => {
    trackFeatureUsage({
      action: `toggle outstanding slot visibility: ${!isActive}`,
      userToken: getUserToken(currentUser),
    });
    setIsActive((currentIsActive) => !currentIsActive);
  };

  const editTitle: React.MouseEventHandler = (e) => {
    hasStopEventPropagation(e);
    setIsEditingTitle(true);
  };

  const onClickRecycle: React.MouseEventHandler = (e) => {
    hasStopEventPropagation(e);
    const newTemporaryEvents = outstandingSlot.time_slots.map(
      (timeSlot, index) =>
        createTemporaryEvent({
          startTime: convertTimeSlotStartTime(timeSlot, currentTimeZone),
          endTime: convertTimeSlotEndTime(timeSlot, currentTimeZone),
          index,
          hideCancel: false,
          isTemporaryAIEvent: false
        }) as TemporaryEvent
    );
    mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", [
      ...temporaryEvents,
      ...newTemporaryEvents,
    ]);
    trackFeatureUsage({
      action: "recycle outstanding slot",
      userToken: getUserToken(currentUser),
    });
  };

  const onClickArchive: React.MouseEventHandler = (e) => {
    hasStopEventPropagation(e);
    setIsArchiveModalOpen(true);
  };

  const archiveSlot = () => {
    if (isActive) {
      removeSlotFromTemporaryEvents();
    }
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.PATCH_OUTSTANDING_SLOT, {
      archived: true,
      slug: outstandingSlot.slug,
      username,
    });
  };

  const onTitleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!isUndefined(typingTimeout)) {
      window.clearTimeout(typingTimeout);
    }
    const newTitle = getInputStringFromEvent(e);
    setTypingTimeout(
      window.setTimeout(
        () => updateTemporaryEventTitles(newTitle),
        0.2 * SECOND_IN_MS
      )
    );
    setTitle(newTitle);
  };

  const onTitleInputBlur = () => {
    setIsEditingTitle(false);
    if (title !== outstandingSlot.slot_title) {
      // Update the temporary event store so the new title is displayed on the calendar.
      if (isActive) {
        updateTemporaryEventTitles(title);
      }

      backendBroadcasts.publish(
        BACKEND_BROADCAST_VALUES.PATCH_OUTSTANDING_SLOT,
        {
          slotTitle: title ? sanitize(title) : null,
          slug: outstandingSlot.slug,
          username,
        }
      );
    }
  };

  const onTitleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.key === "Enter" || e.key === "Escape") {
      onTitleInputBlur();
    }
  };

  const onMouseEnter = () => {
    setIsHovering(true);
    setHoveredSlotsSlug(outstandingSlot.slug);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
    resetHoveredSlotsSlug();
  };

  const renderToggle = () => {
    return (
      <ShortcutHoverHint
        above
        style={{
          bottom: "24px",
          right: "-8px",
          zIndex: 11,
        }}
        containerStyle={{ height: "16px", display: "flex", alignItems: "center" }}
        title={"View"}
      >
        <DefaultSwitch
          tabIndex={-1}
          id={""}
          isChecked={isActive}
          onChange={() => {
            blurCalendar();
          }}
          additionalClassNames={""}
        />
      </ShortcutHoverHint>
    );
  };

  return (
    <div
      className={classNames(
        isActive ? "highlight-white-button-focused-border-override" : "",
        "outstanding-slot-modal-option flex gap-4 mt-2.5 px-4 py-3 rounded-md hoverable-visibility-parent"
      )}
      onClick={toggleActive}
      // onMouseEnter={onMouseEnter}
      // onMouseLeave={onMouseLeave}
    >
      <div className="flex-grow flex flex-col font-normal min-w-0">
        {isEditingTitle ? (
          <input
            autoFocus
            className="outstanding-slot-title-input bg-transparent leading-none -mt-0.5"
            onBlur={onTitleInputBlur}
            onChange={onTitleChange}
            onClick={hasStopEventPropagation}
            onKeyDown={onTitleInputKeyDown}
            value={title || ""}
            placeholder={"e.g. Meeting with Matt"}
          />
        ) : (
          <div
            className="outstanding-slot-title break-all group flex gap-2 select-none"
            onClick={editTitle}
          >
            <span className="line-clamp-2 -mt-0.5">
              {title || placeholderTitle}
            </span>
            <Edit2 className="clickable-icon flex-shrink-0" size={12} />
          </div>
        )}
        <div
          className={classNames(
            "outstanding-slot-creation-time leading-none select-none",
            isEditingTitle ? "mt-2" : "mt-1.5"
          )}
        >
          {creationDate} &middot; {creationTime}
        </div>
      </div>
      <ShortcutHoverHint
        above
        style={{
          bottom: "24px",
          right: "-26px",
          width: "max-content",
          zIndex: 11,
        }}
        containerStyle={{ height: "16px", display: "flex", alignItems: "center" }}
        title={"Archive"}
      >
        <Archive
          className="clickable-icon flex-shrink-0 margin-bottom-1px"
          size={13}
          onClick={onClickArchive}
        />
      </ShortcutHoverHint>

      <ShortcutHoverHint
        above
        style={{
          bottom: "24px",
          right: "-28px",
          width: "max-content",
          zIndex: 11,
        }}
        containerStyle={{ height: "16px", display: "flex", alignItems: "center" }}
        title={"Reuse Slots"}
      >
        <Recycle
          className="clickable-icon flex-shrink-0 margin-bottom-1px"
          size={14}
          onClick={onClickRecycle}
        />
      </ShortcutHoverHint>

      {renderToggle()}
      <OutstandingSlotPreview
        creationDate={creationDate}
        creationTime={creationTime}
        currentTimeZone={currentTimeZone}
        dateFieldOrder={dateFieldOrder}
        format24HourTime={format24HourTime}
        isPlaceholderTitle={!title}
        outstandingSlot={outstandingSlot}
        shouldDisplay={isHovering}
        title={title || placeholderTitle}
      />
      <ArchiveSlotModal
        isWarningModalOpen={isArchiveModalOpen}
        onClickConfirm={archiveSlot}
        onClickExit={() => setIsArchiveModalOpen(false)}
      />
    </div>
  );
}
