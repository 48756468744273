import React, { useCallback, useState } from "react";
import ColoredLine from "../line";
import {
  useBackendSubscriptionStore,
  useDefaultPaymentMethod,
  useStripeCustomerEmail,
} from "../../services/stores/finance";
import { useStripeModule } from "../onboarding/stripePaymentModal";
import EventModalPopup from "../eventModalPopup";
import { useSelector } from "react-redux";
import { Edit2 } from "react-feather";
import CustomButton from "../customButton";
import { BLUE_BUTTON  } from "../../services/globalVariables";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { devErrorPrint } from "../../services/devFunctions";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { getBackendSubscriptionStripeSubID } from "../../services/accessors/backendSubscription";

interface BillingPaymentMethodProps {
  isAddPaymentMethod?: boolean
  overrideOnClick?: () => void
  inputOnSuccess?: () => void
}

export default function BillingPaymentMethod({
  isAddPaymentMethod,
  overrideOnClick,
  inputOnSuccess,
}: BillingPaymentMethodProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onLoad = useCallback(() => setIsModalOpen(true), []);
  const {
    stripePaymentModalRef,
    stripePromiseRef,
    elementsRef,
    hasCompletedImport,
    importStripeModule,
  } = useStripeModule({ onLoad });
  const [shouldFreezeModal, setShouldFreezeModal] = useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const stripeCustomerEmail = useStripeCustomerEmail(
    (state) => state.stripeCustomerEmail,
  );
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const onSuccessfulPayment = () => {
    backendBroadcasts.publish("GET_SUBSCRIPTION_DETAILS");
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    if (inputOnSuccess) {
      inputOnSuccess();
    }
    setIsModalOpen(false);
  };

  const onPaymentError = (error: string) => {
  // do nothing for now. pending backend investigation on bugs found on sentry
    devErrorPrint(error, "onPaymentError_BillingPaymentMethod");
  };

  const defaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.defaultPaymentMethod,
  );

  // If the subscription is not managed through Stripe, there's no need to collect a payment method.
  if (!getBackendSubscriptionStripeSubID(backendSubscription)) {
    return null;
  }

  const renderModal = () => {
    return (
      <EventModalPopup
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        width={400}
        title={"Add your credit card"}
        style={determineDefaultModalStyle(isDarkMode)}
        shouldFreeze={shouldFreezeModal}
      >
        {hasCompletedImport ? renderStripeContent() : null}
      </EventModalPopup>
    );
  };

  const renderStripeContent = () => {
    const ElementsComponent = elementsRef.current;
    const StripePaymentModalComponent = stripePaymentModalRef.current;
    if (!ElementsComponent || !StripePaymentModalComponent) {
      return;
    }
    return (
      <ElementsComponent stripe={stripePromiseRef.current}>
        <StripePaymentModalComponent
          setShouldFreezeModal={setShouldFreezeModal}
          onSuccess={onSuccessfulPayment}
          hideInstructions={true}
          onError={onPaymentError}
        />
      </ElementsComponent>
    );
  };

  const onClickUpsertPayment = () => {
    importStripeModule();
  };

  if (isAddPaymentMethod) {
    // this is for personal onboarding so we do not show the promo screen to reduce friction
    return (
      <>
        {renderModal()}
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={() => {
            if (overrideOnClick) {
              overrideOnClick();
            } else {
              onClickUpsertPayment();
            }
          }}
          label="Add payment method"
        />
      </>
    );
  }

  return (
    <div>
      {stripeCustomerEmail ? (
        <>
          <div className="mb-4 default-font-size font-weight-400 mt-14 secondary-text-color">
            BILLING INFORMATION
          </div>

          <ColoredLine />

          <div className="flex items-center mt-5">
            <div className="secondary-text-color mr-20 default-font-size">
              Email
            </div>

            <div className="default-font-size">{stripeCustomerEmail}</div>
          </div>
        </>
      ) : null}

      <div className="mb-4 default-font-size font-weight-400 mt-14 secondary-text-color">
        PAYMENT METHOD
      </div>

      <ColoredLine />

      {!isEmptyObjectOrFalsey(defaultPaymentMethod) ? (
        <div className="flex items-center mt-5">
          <div className="secondary-text-color mr-20 default-font-size">
            {capitalizeFirstLetter(defaultPaymentMethod.brand)}
          </div>

          <div className="default-font-size mr-20">
            {`•••• ${defaultPaymentMethod.last4}`}
          </div>

          <div className="default-font-size">
            {`Expires ${String(defaultPaymentMethod.exp_month).padStart(
              2,
              "0",
            )}/${defaultPaymentMethod.exp_year}`}
          </div>

          <Edit2
            size={14}
            className="ml-3 clickable-icon mb-1"
            onClick={onClickUpsertPayment}
          />
        </div>
      ) : (
        <div className="flex items-center mb-3 w-full justify-between">
          <div className="mr-3 mt-3">
            <CustomButton
              buttonType={BLUE_BUTTON}
              onClick={onClickUpsertPayment}
              label="Add payment method"
            />
          </div>
          <div />
        </div>
      )}
      {renderModal()}
    </div>
  );
}
