export function safariCopy(elementId) {
  // https://stackoverflow.com/questions/52274735/cannot-save-formatted-data-text-html-in-the-clipboard-in-safari
  const selectElementContents = (el) => {
    let range = document.createRange();
    range.selectNodeContents(el);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  function copyToClipboard(data) {
    function listener(e) {
      e.clipboardData.setData("text/html", data);
      e.clipboardData.setData("text/plain", data);
      e.preventDefault();
    }

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  function copyHtmlToClipboard() {
    let dataHtmlElement = document.getElementById(elementId);
    let elementClassName = dataHtmlElement.className;

    dataHtmlElement.contentEditable = true;
    dataHtmlElement.readOnly = false;
    dataHtmlElement.className = null;

    selectElementContents(dataHtmlElement);
    copyToClipboard(dataHtmlElement.innerHTML);

    dataHtmlElement.contentEditable = false;
    dataHtmlElement.readOnly = true;
    dataHtmlElement.className = elementClassName;

    window.getSelection().removeAllRanges();
  }

  copyHtmlToClipboard();
}
