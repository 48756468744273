import classNames from "classnames";
import React from "react";
import { User, UserCheck } from "react-feather";
import {
  hasEventPreventDefault,
  hasStopEventPropagation,
} from "../services/commonUsefulFunctions";

export default function MarkAsOptional({ onClick, attendee, className = "" }) {
  return (
    <div
      className={classNames(
        "display-flex-center",
        "full-attendee-icon",
        attendee.isOptional ? "visibility-visible-important" : "",
        className || ""
      )}
      onClick={(e) => {
        hasStopEventPropagation(e);
        hasEventPreventDefault(e);
        onClick(attendee);
      }}
    >
      {attendee.isOptional ? (
        <UserCheck size={14} className="clickable-icon" />
      ) : (
        <User
          size={14}
          className={classNames(
            attendee.isOptional ? "opacity-0-6" : "",
            "clickable-icon"
          )}
        />
      )}

      <div className="mark-optional-flag select-none">
        Mark {attendee.isOptional ? "required" : "optional"}
      </div>
    </div>
  );
}
