import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommandCenter from "./commandCenter";
import Broadcast from "../broadcasts/broadcast";
import {
  hasChosenEventChanged,
  PutCommaBetweenWordInString,
  ReplaceSpaceWithUnderscore,
  isInt,
  updateStringForTime,
  chronoParseHasKnownValues,
  createNearestForwardDayOfWeek,
  getKeyValueFromChrono,
  textToNumber,
  customChronoWordDetection,
  convertMinutesIntoDayHoursAndMinutes,
  determineDateFnsKeyName,
  chronoHasKnownDate,
  isBeforeDay,
  getCurrentTimeInCurrentTimeZone,
} from "../services/commonUsefulFunctions";
import _ from "underscore";
import durationParse from "parse-duration";
import { trackEvent } from "./tracking";
import {
  MOMENT_DMY_DATE_FORMAT,
  MOMENT_YMD_DATE_FORMAT,
} from "../services/googleCalendarService";
import { REPLACEMENT_TEXT_OBJECT } from "../services/globalVariables";
import {
  addMinutes,
  subMinutes,
  format,
  set,
  addHours,
  addDays,
  addWeeks,
  subHours,
  subDays,
  subWeeks,
  startOfMinute,
  isSameWeek,
  differenceInMinutes,
  isSameDay,
  getDate,
  getMonth,
  getYear,
  addMonths,
} from "date-fns";
import { getDayOfMonthFromText } from "../lib/timeFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { CHRONO_KEYS, getChronoText, sortChronoKeys } from "../lib/chronoFunctions";
import { lowerCaseAndTrimStringWithGuard, pluralize } from "../lib/stringFunctions";
import { getDateComponents, getDateTimeFormat } from "../lib/dateFunctions";
import { getUserToken } from "../lib/userFunctions";

const DAY_OF_WEEK_FORMAT = "EEEE";

const DEFAULT_PLACEHOLDER = `Try: "Tuesday at 8am", "6pm", "10 minutes", or "Aug 21"`;

const LATER_SEARCH_QUERIES = "after, later, following, next ";
const EARLIER_SEARCH_QUERIES = "earlier, early, before, previous, ";

class RescheduleCommandCenter extends Component {
  constructor(props) {
    super(props);

    let initialOptions = this.createInitialOptions();

    this.state = {
      inputText: "",
      initialOptions: initialOptions,
      options: [],
    };

    this.updateInput = this.updateInput.bind(this);
  }

  componentDidMount() {
    trackEvent({
      category: "reschedule",
      action: "reschedule_command_center_did_mount",
      label: "reschedule_command_center",
      userToken: getUserToken(this.props.currentUser),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (hasChosenEventChanged(this.props.event, prevProps.event)) {
      let initialOptions = this.createInitialOptions();

      this.setState({ initialOptions, options: [] });
    }
  }

  render() {
    return (
      this.props.shouldShowCommandCenter && (
        <CommandCenter
          title="Reschedule Event"
          placeholder={DEFAULT_PLACEHOLDER}
          handleCloseModal={this.props.handleCloseModal}
          onChangeInput={this.updateInput}
          updateSearchResultStateOnChange={this.state.options.length > 0}
          options={
            this.state.options.length === 0
              ? this.state.initialOptions
              : this.state.options
          }
          usePlaceHolderAsDefaultText={DEFAULT_PLACEHOLDER}
        />
      )
    );
  }

  updateInput(input) {
    this.setState({ inputText: input }, () => this.createOptions());
  }

  chronoForwardParse(text) {
    const { dateFieldOrder } = this.props;
    const currentTime = this.getCurrentTime();
    const result = customChronoWordDetection({
      text,
      referenceDate: currentTime,
      dateFormat: dateFieldOrder,
    });
    if (!chronoHasKnownDate(result)) {
      const { matchedText, dayOfMonth } = getDayOfMonthFromText(text);

      if (dayOfMonth) {
        let dateAndMonth = set(currentTime, { date: dayOfMonth });
        if (isBeforeDay(dateAndMonth, currentTime)) {
          // go to next month if in the past
          dateAndMonth = addMonths(dateAndMonth, 1);
        }
        const updatedText = text.replace(
          matchedText,
          format(dateAndMonth, "MMMM do")
        );
        return customChronoWordDetection({
          text: updatedText,
          referenceDate: currentTime,
          dateFormat: this.props.dateFieldOrder,
        });
      }
    }

    const chronoResultText = getChronoText(result?.[0]);
    const TOMORROW_STRING = "tomorrow";
    if (result?.[0] &&
      chronoResultText &&
      lowerCaseAndTrimStringWithGuard(text).includes(TOMORROW_STRING) &&
      !lowerCaseAndTrimStringWithGuard(chronoResultText).includes(TOMORROW_STRING)
    ) {
      // if input text has tomorrow but chrono result does not
      // then add tomorrow to the chrono result
      const addOneDay = () => {
        const { day, month, year } = getDateComponents();

        // Create a date object from the input
        const date = startOfMinute(new Date(year, month - 1, day));
        // Add one day using date-fns
        const newDate = addDays(date, 1);
        // Return a new object with the updated date
        return {
          day: getDate(newDate),
          month: getMonth(newDate) + 1, // getMonth returns zero-indexed month, so add 1
          year: getYear(newDate),
        };
      };

      result[0] = {
        ...result[0],
        text,
        start: {
          ...result[0].start,
          knownValues: {
            ...result[0].start.knownValues,
            ...addOneDay(),
          },
          impliedValues: {
            ...result[0].start.impliedValues,
            ...addOneDay(),
          },
        },
      };
    }

    // if input contains the string "today" but chrono result did not pick it up
    const TODAY_STRING = "today";
    if (result?.[0] &&
      chronoResultText &&
      lowerCaseAndTrimStringWithGuard(text).includes(TODAY_STRING) &&
      !lowerCaseAndTrimStringWithGuard(chronoResultText).includes(TODAY_STRING)
    ) {
      // if input text has "today" but chrono result did not pick it up
      // then add "today" to the chrono result
      result[0] = {
        ...result[0],
        text,
        start: {
          ...result[0].start,
          knownValues: {
            ...result[0].start.knownValues,
            ...getDateComponents(),
          },
          impliedValues: {
            ...result[0].start.impliedValues,
            ...getDateComponents(),
          },
        },
      };
    }

    return result;
  }

  createOptions() {
    const formattedInputText = this.formatInputText(this.state.inputText);
    const chronoParse = this.chronoForwardParse(formattedInputText);
    const options = this.createOptionsFromChronoParse(chronoParse);

    this.setState({ options: options || [] });
  }

  formatInputText(text) {
    if (!text) {
      return "";
    }

    let loweredText = text.toLowerCase();
    let formattedString = loweredText;
    let formatted = false;

    // replace spelling of wed, wedn, wednes -> wednesday
    Object.keys(REPLACEMENT_TEXT_OBJECT).forEach((k) => {
      if (!formatted && formattedString.includes(k)) {
        let re = new RegExp("\\b" + k + "\\b", "g");
        formatted = true;

        formattedString = formattedString.replace(
          re,
          `${REPLACEMENT_TEXT_OBJECT[k]}`
        );
      }
    });

    return formattedString;
  }

  parseDurationText(text) {
    if (!text) {
      return null;
    }

    if (
      text.toLowerCase().includes("next week") ||
      text.toLowerCase().includes("last week") ||
      text.toLowerCase().includes("previous week")
    ) {
      return 168 * 60;
    } else if (
      text.toLowerCase().includes("last month") ||
      text.toLowerCase().includes("next month") ||
      text.toLowerCase().includes("previous month")
    ) {
      return 720 * 60;
    }

    let loweredCaseText = text.toLowerCase();
    let transformText = textToNumber(loweredCaseText);

    let durationResult = this.getDurationFromText(transformText);

    if (loweredCaseText.includes("half")) {
      durationResult = durationResult + 30;
    }

    return durationResult;
  }

  createOptionsForIntegerOnlyInput(stringInput) {
    let updatedString = updateStringForTime(stringInput);

    if (!updatedString || isInt(updatedString)) {
      return null;
    }

    let chronoParse = this.chronoForwardParse(updatedString);

    let options = this.createOptionsFromChronoParse(chronoParse, updatedString);

    return options;
  }

  createOptionsFromChronoParse(chronoParse, searchText = null) {
    let currentEvent = this.props.event;

    if (!currentEvent) {
      return null;
    }

    let inputText = searchText || this.state.inputText;

    let hasKnownValues = chronoParseHasKnownValues(chronoParse);

    if (
      !hasKnownValues &&
      currentEvent &&
      inputText.length > 0 &&
      isInt(inputText)
    ) {
      return this.createOptionsForIntegerOnlyInput(inputText);
    } else if (
      currentEvent &&
      inputText.length > 0 &&
      !this.doesInputTextContainDuration(inputText) &&
      this.parseDurationText(inputText)
    ) {
      let duration = this.parseDurationText(inputText);

      let eventStart = currentEvent.eventStart;

      let isSearchForEarlierTime = this.isSearchForEarlierTime(inputText);
      let isSearchForLaterTime = this.isSearchForLaterTime(inputText);

      let laterMomentTime = addMinutes(eventStart, duration);
      let earlierMomentTime = subMinutes(eventStart, duration);

      let earlierTimeText;
      let laterTimeText;
      if (duration / 60 < 24) {
        let durationText = this.createDurationText(duration);
        earlierTimeText = `to ${durationText} earlier (${format(
          earlierMomentTime,
          this.getDateTimeFormat()
        )})`;
        laterTimeText = `to ${durationText} later (${format(
          laterMomentTime,
          this.getDateTimeFormat()
        )})`;
      } else {
        earlierTimeText = `to ${format(
          earlierMomentTime,
          this.determineMomentDateFormat()
        )} at ${format(earlierMomentTime, this.getDateTimeFormat())}`;
        laterTimeText = `to ${format(
          laterMomentTime,
          this.determineMomentDateFormat()
        )} at ${format(laterMomentTime, this.getDateTimeFormat())}`;
      }

      let durationChange = {
        key: `change_duration_${duration}_minutes`,
        title: `for ${convertMinutesIntoDayHoursAndMinutes(duration)}`,
        onClickHandler: () =>
          this.rescheduleEventDuration(
            currentEvent,
            eventStart,
            addMinutes(eventStart, duration)
          ),
        searchQueries: `${duration}, minute, for`,
      };

      let earlierTimeOption = {
        key: `move_back_duration_${duration}_minutes`,
        title: earlierTimeText,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            subMinutes(eventStart, duration)
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + `${duration}, minute`,
      };

      let laterTimeOption = {
        key: `move_forward_duration_${duration}_minutes`,
        title: laterTimeText,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            addMinutes(eventStart, duration)
          ),
        searchQueries: LATER_SEARCH_QUERIES + `${duration}, minute`,
      };

      if (isSearchForEarlierTime) {
        return [earlierTimeOption];
      } else if (isSearchForLaterTime) {
        return [laterTimeOption];
      } else {
        return [laterTimeOption, earlierTimeOption, durationChange];
      }
    } else if (hasKnownValues && currentEvent) {
      let isAllDayEvent = !!currentEvent.allDay;

      let returnString = "to";

      let chronoStart = _.clone(chronoParse[0].start.knownValues);

      if (isAllDayEvent) {
        // Remove time keys for all day events
        chronoStart = _.omit(chronoStart, CHRONO_KEYS.HOUR);
        chronoStart = _.omit(chronoStart, CHRONO_KEYS.MINUTE);
      }

      let chronoStartKeys = Object.keys(chronoStart);

      let chronoStartWeekDay = chronoStartKeys.includes(CHRONO_KEYS.WEEKDAY);
      let chronoStartDate = chronoStartKeys.some(
        (r) => [CHRONO_KEYS.DAY, CHRONO_KEYS.MONTH, CHRONO_KEYS.YEAR].indexOf(r) >= 0
      );

      // if only known value is time, then return null;
      if (!chronoStartWeekDay && !chronoStartDate && isAllDayEvent) {
        return null;
      }

      let newEventStart = currentEvent.eventStart;
      let originalEvent = currentEvent.eventStart;

      // have to hard set day of week if other dates are not given
      if (chronoStartWeekDay && !chronoStartDate) {
        // pick the nearest upcoming day fo week
        let nearestDayOfWeek = createNearestForwardDayOfWeek(
          originalEvent,
          chronoStart["weekday"]
        );

        newEventStart = set(newEventStart, {
          date: getDate(nearestDayOfWeek),
          month: getMonth(nearestDayOfWeek),
          year: getYear(nearestDayOfWeek),
        });
      }

      // Set the keys using known values
      // month needs to go first otherwise if set date like 31 on feb, it's going to be incorrect.
      const sortedChronoStartKeys = sortChronoKeys(chronoStartKeys);
      sortedChronoStartKeys.forEach((k) => {
        // Moment takes in slightly different keys and values compared to chrono
        let momentKeyValue = getKeyValueFromChrono(
          k,
          chronoStart[k],
          sortedChronoStartKeys,
          this.props.format24HourTime
        );

        if (momentKeyValue) {
          newEventStart = set(newEventStart, {
            [determineDateFnsKeyName(momentKeyValue.key)]: momentKeyValue.value,
          });
        }
      });
      if (
        isSameDay(newEventStart, currentEvent.eventStart) &&
        sortedChronoStartKeys.includes(CHRONO_KEYS.DAY) &&
        sortedChronoStartKeys.includes(CHRONO_KEYS.MONTH) &&
        sortedChronoStartKeys.includes(CHRONO_KEYS.YEAR) &&
        !lowerCaseAndTrimStringWithGuard(inputText).includes("today") &&
        !lowerCaseAndTrimStringWithGuard(inputText).includes("now") &&
        !lowerCaseAndTrimStringWithGuard(inputText).includes("tomorrow")
      ) {
        // same day as current day
        newEventStart = addWeeks(newEventStart, 1);
      }

      // Known values include knowing about the day of week
      if (chronoStartWeekDay || chronoStartDate) {
        returnString =
          returnString +
          ` ${format(newEventStart, DAY_OF_WEEK_FORMAT)} (${format(
            newEventStart,
            this.determineMomentDateFormat()
          )})`;
      }

      if (!isAllDayEvent) {
        returnString =
          returnString +
          ` ${chronoStartWeekDay || chronoStartDate ? "at" : ""} ${format(
            newEventStart,
            this.getDateTimeFormat()
          )}`;
      }

      // If we want to add multiple options
      // if (shouldCreateListWithMultipleEvents) {
      //   return this.createOptionsForFollowingNWeeks(3, currentEvent, newEventStart, chronoStartTime);
      // } else {
      //   return [{
      //     key: ReplaceSpaceWithUnderscore(returnString),
      //     title: returnString,
      //     onClickHandler: () => this.createEndTimeBasedOnOriginalEventDuration(currentEvent, newEventStart),
      //     searchQueries: PutCommaBetweenWordInString(returnString)
      //   }];
      // }

      let duration = this.parseDurationText(inputText);
      if (this.doesInputTextContainDuration(inputText) && duration) {
        return [
          {
            key: ReplaceSpaceWithUnderscore(returnString),
            title:
              returnString +
              ` for ${convertMinutesIntoDayHoursAndMinutes(duration)}`,
            onClickHandler: () =>
              this.rescheduleEventDuration(
                currentEvent,
                newEventStart,
                addMinutes(newEventStart, duration)
              ),
            searchQueries: PutCommaBetweenWordInString(returnString),
          },
        ];
      }

      return [
        {
          key: ReplaceSpaceWithUnderscore(returnString),
          title: returnString,
          onClickHandler: () =>
            this.createEndTimeBasedOnOriginalEventDuration(
              currentEvent,
              newEventStart
            ),
          searchQueries: PutCommaBetweenWordInString(returnString),
        },
      ];
    } else {
      return null;
    }
  }

  createEndTimeBasedOnOriginalEventDuration(originalEvent, newEventStart) {
    let originalEventStart = originalEvent.eventStart;
    let originalEventEnd = originalEvent.eventEnd;

    let durationBetweenOriginalStartAndNewStart = differenceInMinutes(
      newEventStart,
      originalEventStart
    );
    let newEventEnd = addMinutes(
      originalEventEnd,
      durationBetweenOriginalStartAndNewStart
    );

    let updatedTime = { start: newEventStart, end: newEventEnd };

    return this.rescheduleEvent(originalEvent, updatedTime);
  }

  isSearchForEarlierTime(text) {
    if (!text) {
      return false;
    }

    let loweredText = text.toLowerCase();

    let isSearchForEarlierTime = false;
    let earlierTextArray = [
      "earli",
      "earlie",
      "early",
      "earlier",
      "before",
      "previous",
      "last",
    ];
    earlierTextArray.forEach((a) => {
      if (!isSearchForEarlierTime && loweredText.includes(a)) {
        isSearchForEarlierTime = true;
      }
    });

    return isSearchForEarlierTime;
  }

  isSearchForLaterTime(text) {
    if (!text) {
      return false;
    }

    let loweredText = text.toLowerCase();

    let isSearchForLaterTime = false;
    let laterTextArray = ["later", "after", "late", "next"];

    laterTextArray.forEach((a) => {
      if (!isSearchForLaterTime && loweredText.includes(a)) {
        isSearchForLaterTime = true;
      }
    });

    return isSearchForLaterTime;
  }

  getCurrentTime() {
    const { currentTimeZone } = this.props;
    return addMinutes(getCurrentTimeInCurrentTimeZone(currentTimeZone), 1);
  }

  createInitialOptions() {
    let currentEvent = this.props.event;

    if (!currentEvent) {
      return [];
    }

    let eventStart = currentEvent.eventStart;
    let isAllDayEvent = currentEvent.allDay;

    let upThirtyMinutes = addMinutes(eventStart, 30);
    let upOnehour = addHours(eventStart, 1);
    let toNextDay = addDays(eventStart, 1);
    let toTomorrow = set(eventStart, {
      date: addDays(this.getCurrentTime(), 1).getDate(),
    });
    let toYesterday = set(eventStart, {
      date: subDays(this.getCurrentTime(), 1).getDate(),
    });
    let upOneWeek = addWeeks(eventStart, 1);

    let backThirtyMinutes = subMinutes(eventStart, 30);
    let backOnehour = subHours(eventStart, 1);
    let oneDayEarlier = subDays(eventStart, 1);
    let backOneWeek = subWeeks(eventStart, 1);

    let options = [
      {
        key: "move_forward_30_minutes",
        title: `to 30 minutes later (${format(
          upThirtyMinutes,
          this.getDateTimeFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            upThirtyMinutes
          ),
        searchQueries: LATER_SEARCH_QUERIES + "30, minutes",
      },
      {
        key: "move_forward_next_hour",
        title: `to an hour later (${format(
          upOnehour,
          this.getDateTimeFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            upOnehour
          ),
        searchQueries: LATER_SEARCH_QUERIES + "one, 1, hour",
      },
      {
        key: "move_forward_one_day",
        title: `to the following day (${format(
          toNextDay,
          this.determineMomentDateFormat()
        )} at ${format(toNextDay, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toNextDay
          ),
        searchQueries: LATER_SEARCH_QUERIES + "1, one, day",
      },
      {
        key: "move_forward_one_week",
        title: `to next ${format(upOneWeek, DAY_OF_WEEK_FORMAT)} (${format(
          upOneWeek,
          this.determineMomentDateFormat()
        )} at ${format(upOneWeek, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            upOneWeek
          ),
        searchQueries: LATER_SEARCH_QUERIES + "1, one, week",
      },
      {
        key: "move_backward_30_minutes",
        title: `to 30 minutes earlier (${format(
          backThirtyMinutes,
          this.getDateTimeFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            backThirtyMinutes
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "30, minutes",
      },
      {
        key: "move_backward_next_hour",
        title: `to an hour earlier (${format(
          backOnehour,
          this.getDateTimeFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            backOnehour
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "one, 1, hour",
      },
      {
        key: "move_backward_one_day",
        title: `to one day earlier (${format(
          oneDayEarlier,
          this.determineMomentDateFormat()
        )} at ${format(oneDayEarlier, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            oneDayEarlier
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "1, one, day",
      },
      {
        key: "move_backward_one_week",
        title: `to last ${format(backOneWeek, DAY_OF_WEEK_FORMAT)} (${format(
          backOneWeek,
          this.determineMomentDateFormat()
        )} at ${format(backOneWeek, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            backOneWeek
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "1, one, week",
      },
      {
        key: "move_forward_tomorrow",
        title: `to tomorrow (${format(
          toTomorrow,
          this.determineMomentDateFormat()
        )} at ${format(toTomorrow, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toTomorrow
          ),
        searchQueries: LATER_SEARCH_QUERIES + "tomorrow",
      },
      {
        key: "move_back_yesterday",
        title: `to yesterday (${format(
          toYesterday,
          this.determineMomentDateFormat()
        )} at ${format(toYesterday, this.getDateTimeFormat())})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toYesterday
          ),
        searchQueries: LATER_SEARCH_QUERIES + "yesterday",
      },
    ];

    let allDayOption = [
      {
        key: "move_forward_one_day",
        title: `to the following day (${format(
          toNextDay,
          this.determineMomentDateFormat()
        )}`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toNextDay
          ),
        searchQueries: LATER_SEARCH_QUERIES + "1, one, day",
      },
      {
        key: "move_forward_one_day",
        title: `to tomorrow (${format(
          toTomorrow,
          this.determineMomentDateFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toTomorrow
          ),
        searchQueries: LATER_SEARCH_QUERIES + "tomorrow",
      },
      {
        key: "move_forward_one_week",
        title: `to next ${format(upOneWeek, DAY_OF_WEEK_FORMAT)} (${format(
          upOneWeek,
          this.determineMomentDateFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            upOneWeek
          ),
        searchQueries: LATER_SEARCH_QUERIES + "1, one, week",
      },
      {
        key: "move_backward_one_day",
        title: `to one day earlier (${format(
          oneDayEarlier,
          this.determineMomentDateFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            oneDayEarlier
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "1, one, day",
      },
      {
        key: "move_backward_one_week",
        title: `to last ${format(backOneWeek, DAY_OF_WEEK_FORMAT)} (${format(
          backOneWeek,
          this.determineMomentDateFormat()
        )})`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            backOneWeek
          ),
        searchQueries: EARLIER_SEARCH_QUERIES + "1, one, week",
      },
      {
        key: "move_back_yesterday",
        title: `to yesterday (${format(
          toYesterday,
          this.determineMomentDateFormat()
        )}`,
        onClickHandler: () =>
          this.createEndTimeBasedOnOriginalEventDuration(
            currentEvent,
            toYesterday
          ),
        searchQueries: LATER_SEARCH_QUERIES + "yesterday",
      },
    ];

    return isAllDayEvent ? allDayOption : options;
  }

  createDurationText(duration) {
    // duration comes in as minutes
    if (duration < 60) {
      return `${duration} minutes`;
    } else if (duration === 60) {
      return "an hour";
    } else if (duration / 60 <= 48) {
      let hours = Math.floor(duration / 60);
      let minutes = duration % 60;

      if (minutes === 0) {
        return `${hours} ${pluralize(hours, "hour")}`;
      }

      return `${hours} ${pluralize(hours, "hour")} and ${minutes} ${pluralize(
        minutes,
        "minute"
      )}`;
    } else {
      let years; // 8760 hours in a 365 day year
      let months; // 720 hours in a month
      let weeks; // 168 hours in a week
      let days; // 24 hours in a day
      let hours = Math.floor(duration / 60);
      let hoursRemaining = hours;
      let minutes = duration % 60;
      let durationString = "";

      if (hours >= 8760) {
        years = Math.floor(hours / 8760);
        durationString = `${years} ${pluralize(years, "year")}`;
        hoursRemaining = hoursRemaining - years * 8760;
      }

      if (hoursRemaining >= 720) {
        months = Math.floor(hoursRemaining / 720);
        hoursRemaining = hoursRemaining - months * 720;
      }

      if (hoursRemaining >= 168) {
        weeks = Math.floor(hoursRemaining / 168);
        hoursRemaining = hoursRemaining - weeks * 168;
      }

      if (hoursRemaining >= 24) {
        days = Math.floor(hoursRemaining / 24);
        hoursRemaining = hoursRemaining - days * 24;
      }

      // add years to string
      durationString = this.updateDurationString(
        durationString,
        !months && !weeks && !days && !hours && !minutes,
        years,
        "year"
      );

      // add months to string
      durationString = this.updateDurationString(
        durationString,
        !weeks && !days && !hours && !minutes,
        months,
        "month"
      );

      // add weeks to string
      durationString = this.updateDurationString(
        durationString,
        !days && !hours && !minutes,
        weeks,
        "week"
      );

      // add days to string
      durationString = this.updateDurationString(
        durationString,
        !hours && !minutes,
        days,
        "day"
      );

      // add hours to string
      durationString = this.updateDurationString(
        durationString,
        !minutes,
        hoursRemaining,
        "hour"
      );

      // add minutes to string
      durationString = this.updateDurationString(
        durationString,
        true,
        minutes,
        "minute"
      );

      return durationString;
    }
  }

  updateDurationString(durationString, isLastElement = false, duration, text) {
    if (!duration) {
      return durationString;
    }

    if (durationString.length > 0) {
      if (isLastElement) {
        return durationString + ` and ${duration} ${pluralize(duration, text)}`;
      } else {
        return durationString + `, ${duration} ${pluralize(duration, text)}`;
      }
    } else {
      return `${duration} ${pluralize(duration, text)}`;
    }
  }

  getDateTimeFormat() {
    return getDateTimeFormat(this.props.format24HourTime);
  }

  rescheduleEvent(event, updatedTime) {
    let endTime = startOfMinute(updatedTime.end);
    let startTime = startOfMinute(updatedTime.start);

    let eventData = { event, startTime, endTime };

    this.props.history.push("/home");

    Broadcast.publish(
      "TEMPORARILY_REMOVE_WEEKLY_CALENDAR_EVENT_AND_UPDATE_EVENT",
      eventData
    );
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    if (!isSameWeek(this.props.selectedDay, updatedTime.start)) {
      this.jumpToDate(updatedTime.start);
    }
  }

  rescheduleEventDuration(event, startTimeJSDate, endTimeJSDate) {
    let eventData = {
      event,
      startTime: startTimeJSDate,
      endTime: endTimeJSDate,
    };
    this.props.history.push("/home");

    Broadcast.publish(
      "TEMPORARILY_REMOVE_WEEKLY_CALENDAR_EVENT_AND_UPDATE_EVENT",
      eventData
    );
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    if (!isSameWeek(this.props.selectedDay, startTimeJSDate)) {
      this.jumpToDate(startTimeJSDate);
    }
  }

  jumpToDate(date) {
    let newDate = date;

    this.props.setSelectedDay(newDate);
    Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", newDate);
  }

  determineMomentDateFormat() {
    if (this.props.dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
      return "dd-MM-yyyy";
    } else if (this.props.dateFieldOrder === MOMENT_YMD_DATE_FORMAT) {
      return "yyyy-MM-dd";
    } else {
      return "PPP";
    }
  }

  doesInputTextContainDuration(text) {
    return text && text.toLowerCase().includes("for ");
  }

  getDurationFromText(text) {
    if (!text) {
      return null;
    }

    let formattedText = text || "";
    if (/minut($|\s)/.test(text)) {
      formattedText = formattedText.replace(/minut($|\s)/, "minute ");
    } else if (/mi($|\s)/.test(text)) {
      formattedText = formattedText.replace(/mi($|\s)/, "minute ");
    } else if (/minu($|\s)/.test(text)) {
      formattedText = formattedText.replace(/minu($|\s)/, "minute ");
    } else if (/ho($|\s)/.test(text)) {
      formattedText = formattedText.replace(/ho($|\s)/, "hour ");
    } else if (/hou($|\s)/.test(text)) {
      formattedText = formattedText.replace(/hou($|\s)/, "hour ");
    }

    return durationParse(formattedText, "m");
  }
}

function mapStateToProps(state) {
  let {
    selectedDay,
    format24HourTime,
    dateFieldOrder,
    currentUser,
    currentTimeZone,
  } = state;

  return {
    selectedDay,
    format24HourTime,
    dateFieldOrder,
    currentUser,
    currentTimeZone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RescheduleCommandCenter));
