import React, { useState } from "react";
import DefaultSwitch from "../defaultSwitch";
import { useSelector } from "react-redux";
import classNames from "classnames";
import MoreInfoIcon from "../moreInfoIcon";

export default function IgnoreConflicts({
  onChange,
  isIgnoreConflicts,
}) {
  const [
    isShowingIgnoreConflictMoreInfo,
    setIsShowingIgnoreConflictMoreInfo,
  ] = useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onMouseEnterIcon = () => {
    setIsShowingIgnoreConflictMoreInfo(true);
  };
  const onMouseLeaveIcon = () => {
    setIsShowingIgnoreConflictMoreInfo(false);
  };

  const renderIgnoreConflictMoreInfoIcon = () => {
    return (
      <div
        className="ml-1 flex items-center justify-center"
        onMouseEnter={onMouseEnterIcon}
        onMouseLeave={onMouseLeaveIcon}
      >
        <MoreInfoIcon/>
      </div>
    );
  };

  const renderIgnoreConflictMoreInfo = () => {
    if (!isShowingIgnoreConflictMoreInfo) {
      return null;
    }

    return (
      <div
        className={classNames(
          "absolute more-information-modal",
          isDarkMode ? "background-color-modal-background-color" : "bg-white",
          "top-10",
          "width-300px-important"
        )}
      >
        When toggled on, meetings can be booked at times where you and other
        attendees have existing conflicts. This is best reserved for high
        priority meetings.
      </div>
    );
  };

  return (
    <div className="flex items-center justify-between">
      <div
        className={"availability-detail-section-text flex items-center gap-1"}
      >
        Ignore all conflicts
        {renderIgnoreConflictMoreInfoIcon()}
      </div>
      <DefaultSwitch
        tabIndex={-1}
        isChecked={isIgnoreConflicts}
        onChange={(isIgnoreConflicts) => {
          if (onChange) {
            onChange(isIgnoreConflicts);
          }
        }}
      />
      {renderIgnoreConflictMoreInfo()}
    </div>
  );
}
