import React from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { convertMinutesToHoursWithOneDecimal } from "./metricsAccessorFunctions";

export default function UpDownAbsoluteChange({ delta, isUp, showOnlyAbsoluteValue }) {
  const isRoundedToZero = Number(convertMinutesToHoursWithOneDecimal(Math.abs(delta))) === 0;
  if (isUp && !isRoundedToZero) {
    return (
      <div className="metrics-red-down flex items-baseline rounded py-0.5 px-1.5 select-none ml-2 font-size-12">
      <ArrowUp size={10} />
      {showOnlyAbsoluteValue ? Math.abs(delta) : `${convertMinutesToHoursWithOneDecimal(Math.abs(delta))} hrs`}
    </div>
    )
  }

  return (
    <div className="metrics-green-up flex items-baseline rounded py-0.5 px-1.5 select-none ml-2 font-size-12">
      <ArrowDown size={10} />
      {showOnlyAbsoluteValue ? Math.abs(delta) : `${convertMinutesToHoursWithOneDecimal(Math.abs(delta))} hrs`}
    </div>
  );
}
