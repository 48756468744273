import React, { Component } from "react";
import { connect } from "react-redux";
import {
  constructQueryParams,
  getGoogleEventId,
} from "../services/commonUsefulFunctions";
import CommandCenter from "./commandCenter";
import googleColors, {
  createColorsListWithDefault,
} from "../services/googleColors";
import Broadcast from "../broadcasts/broadcast";
import { constructRequestURL } from "../services/api";
import { trackEvent } from "./tracking";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import { useAllCalendars, useMasterAccount } from "../services/stores/SharedAccountData";
import {
  getCurrentUserDefaultColor,
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
} from "../lib/calendarFunctions";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import { getCalendarColorID } from "../services/calendarAccessors";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { isVersionV2 } from "../services/versionFunctions";
import recurrenceBroadcast from "../broadcasts/recurrenceBroadcast";
import { createUpdatedSingleEventWithColor } from "../lib/mimicEventUpdate";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { APP_SETTINGS } from "../lib/vimcalVariables";
import { GOOGLE_UPDATES } from "../services/googleCalendarService";
import { getMatchingUIUserForEvent, getMatchingUserAndTags, getTagColorId } from "../lib/tagsFunctions";
import { BROADCAST_VALUES, RECURRENCE_BROADCAST_VALUES } from "../lib/broadcastValues";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { capitalizeFirstLetter } from "../lib/stringFunctions";
import { getUserEmail, getUserToken } from "../lib/userFunctions";

class ColorCommandCenter extends Component {
  constructor(props) {
    super(props);

    const { event } = props;

    this.state = {
      options: this.createOptions(props),
      isRecurringEvent: getEventMasterEventID(event),
      originalRecurringEvent: getOriginalRecurringEventFromIndex(
        event,
        this.props.originalRecurrenceEventIndex
      ),
    };

    this.updatePaintSettings = this.updatePaintSettings.bind(this);
    this.onClickColor = this.onClickColor.bind(this);
  }

  componentDidMount() {
    const {
      event,
      currentUser
    } = this.props;

    const {
      isRecurringEvent,
      originalRecurringEvent
    } = this.state;

    this._isMounted = true;

    trackEvent({
      category: "painter",
      action: "painter_command_center_did_mount",
      label: "color_command_center",
      userToken: getUserToken(currentUser),
    });

    if (
      isRecurringEvent &&
      !isEmptyObjectOrFalsey(event) &&
      !originalRecurringEvent
    ) {
      recurrenceBroadcast.publish(
        RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT,
        event,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      originalRecurringEvent
    } = this.state;
    const {
      originalRecurrenceEventIndex,
      event
    } = this.props;

    if (
      !originalRecurringEvent &&
      originalRecurrenceEventIndex !==
      prevProps.originalRecurrenceEventIndex &&
      getOriginalRecurringEventFromIndex(
        event,
        originalRecurrenceEventIndex
      )
    ) {
      this.setState({
        originalRecurringEvent: getOriginalRecurringEventFromIndex(
          event,
          originalRecurrenceEventIndex
        ),
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (
      !(this.props.shouldShowCommandCenter && !isEmptyObjectOrFalsey(this.props.event))
    ) {
      return null;
    }

    return (
      <CommandCenter
        title="Painter"
        placeholder="Search by color or name of painter"
        handleCloseModal={this.props.handleCloseModal}
        options={this.state.options}
      />
    );
  }

  createOptions(props) {
    const { allCalendars } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;

    const currentUserDefaultColor = getCurrentUserDefaultColor({
      currentUserEmail: getUserEmail(currentUser),
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });

    const eventColors = createColorsListWithDefault(currentUserDefaultColor);

    if (props?.recentTags?.length > 0) {
      // Need to reset onClickhandler here otherwise we're going to get pointer to old this.props.event
      let newPaintOrder = [];

      props.recentTags.forEach((p) => {
        if (p.colorId === "0" && !eventColors["0"]) {
          // do nothing
        } else if (p.colorId === "0") {
          let defaultColor = eventColors["0"];
          let backgroundColor = defaultColor.color;

          const calendarColorID = getCalendarColorID(
            allCalendars[getEventUserCalendarID(props.event)]
          );
          if (!isNullOrUndefined(calendarColorID)) {
            // id could be 0 so can't just use (calendarCOlorID) as check
            backgroundColor = googleColors.calendar[calendarColorID].background;
          }

          newPaintOrder = newPaintOrder.concat({
            key: defaultColor.name,
            title: this.createColorNameAndColor(
              defaultColor,
              null,
              backgroundColor
            ),
            onClickHandler: () => this.onClickColor("0", defaultColor.name),
            searchQueries: `${defaultColor.name}, ${defaultColor.nickName}, remove`,
            colorName: defaultColor.name,
            colorId: "0",
          });
        } else {
          newPaintOrder = newPaintOrder.concat({
            key: p.key,
            title: p.title,
            onClickHandler: () => this.onClickColor(p.colorId, p.colorName),
            searchQueries: p.searchQueries,
            colorName: p.colorName,
            colorId: p.id,
          });
        }
      });

      return newPaintOrder;
    }

    const paintSettings = getMatchingUserAndTags({
      currentUser,
      allLoggedInUsers,
      user: getMatchingUIUserForEvent({
        event: props.event,
        allCalendars,
        allLoggedInUsers,
        masterAccount,
        currentUser,
      }),
      masterAccount,
    });

    let colors = eventColors;
    let options = [];

    let takenColorIds = [];

    if (paintSettings && paintSettings.length > 0) {
      paintSettings.forEach((colorInfo, index) => {
        const colorId = getTagColorId(colorInfo);
        let defaultColorInfo = colors[colorId];
        takenColorIds = takenColorIds.concat(colorId);

        options = options.concat({
          key: `painter_${colorId}_${index}`,
          title: this.createColorNameAndColor(colorInfo, defaultColorInfo.name),
          onClickHandler: () => this.onClickColor(colorId, colorInfo.name),
          searchQueries: `${colorInfo.name}, ${defaultColorInfo.nickName}, ${defaultColorInfo.name}`,
          colorName: colorInfo.name,
          colorId: colorId,
        });
      });
    }

    Object.keys(colors).forEach((key) => {
      let colorInfo = colors[key];

      if (
        colorInfo.name.toLowerCase() === "default" ||
        takenColorIds.includes(key)
      ) {
        return;
      }

      options = options.concat({
        key: colorInfo.name,
        title: this.createColorNameAndColor(colorInfo),
        onClickHandler: () => this.onClickColor(key, colorInfo.name),
        searchQueries: `${colorInfo.name}, ${colorInfo.nickName}`,
        colorName: colorInfo.name,
        colorId: getTagColorId(colorInfo),
      });
    });

    let defaultColor = colors["0"];
    if (defaultColor) {
      let backgroundColor = defaultColor.color;

      const { allCalendars } = this.props.allCalendars;
      const calendarColorID = getCalendarColorID(
        allCalendars[getEventUserCalendarID(this.props.event)]
      );
      if (!isNullOrUndefined(calendarColorID)) {
        backgroundColor = googleColors.calendar[calendarColorID].background;
      }

      options = options.concat({
        key: defaultColor.name,
        title: this.createColorNameAndColor(
          defaultColor,
          null,
          backgroundColor
        ),
        onClickHandler: () => this.onClickColor("0", defaultColor.name),
        searchQueries: `${defaultColor.name}, ${defaultColor.nickName}, remove`,
        colorName: defaultColor.name,
        colorId: "0",
      });
    }

    // TODO: open modal to labels
    options = options.concat({
      key: "edit_colors_label",
      title: "Edit/Add color labels",
      onClickHandler: this.updatePaintSettings,
      searchQueries: "edit, add, create, change, setting, update",
    });

    this.props.setPaintOrder(options);

    return options;
  }

  createColorNameAndColor(colorInfo, colorName = null, backgroundColor = null) {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            backgroundColor: `${backgroundColor || colorInfo.color}`,
            marginRight: 20,
          }}
        ></div>

        {colorName
          ? capitalizeFirstLetter(colorInfo.name) + ` (${colorName})`
          : capitalizeFirstLetter(colorInfo.name)}
      </div>
    );
  }

  onClickColor(id, colorName) {
    if (this.state.isRecurringEvent) {
      this.onClickColorRecurring(id, colorName);
    } else {
      this.onClickColorNonRecurring(id, colorName);
    }
  }

  onClickColorRecurring(id, colorName) {
    Broadcast.publish(
      BROADCAST_VALUES.DISPLAY_RECURRING_COLOR_MODAL,
      id,
      this.state.originalRecurringEvent,
      this.props.event
    );

    this.updateColorNameOrder(id, colorName);
  }

  onClickColorNonRecurring(id, colorName) {
    if (!this.props.event) {
      return;
    }

    const { event } = this.props;

    const { allCalendars } = this.props.allCalendars;

    const isAppVersion2 = isVersionV2();
    const eventData = isAppVersion2
      ? this.constructEventDataV2(id)
      : this.constructEventData(id);

    const path = `events`;

    const params = isAppVersion2
      ? {
        sendUpdates: GOOGLE_UPDATES.NONE,
        calendar_provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(event),
          allCalendars
        ),
        event_provider_id: getGoogleEventId(event),
      }
      : {
        sendUpdates: GOOGLE_UPDATES.NONE,
        google_calendar_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(event),
          allCalendars
        ),
        google_event_id: getGoogleEventId(event),
      };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isAppVersion2) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    this.updateColorNameOrder(id, colorName);

    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
      updatedTemporaryEvent: createUpdatedSingleEventWithColor({
        event,
        colorID: id
      })
    });

    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    Broadcast.publish("SET_LAST_SELECTED_EVENT", this.props.event);
  }

  updateColorNameOrder(colorId, colorName) {
    if (!colorId || !colorName) {
      return;
    }

    let currentOptions = this.props.recentTags || [];
    let unselectedColors = [];
    let selectedInfo;

    currentOptions.forEach((c) => {
      if (c.colorName === colorName && c.colorId === colorId) {
        selectedInfo = c;
      } else {
        unselectedColors = unselectedColors.concat(c);
      }
    });

    let updatedPainterSetter = selectedInfo
      ? [].concat(selectedInfo).concat(unselectedColors)
      : [].concat(unselectedColors);

    this.props.setPaintOrder(updatedPainterSetter);
  }

  constructEventDataV2(id) {
    const { event } = this.props;

    const eventData = {
      user_calendar_id: getEventUserCalendarID(event),
      calendar_event: {
        colorId: id,
        provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(event),
          this.props.allCalendars.allCalendars
        ),
      },
      user_event_id: getEventUserEventID(event),
    };

    return eventData;
  }

  constructEventData(id) {
    let eventInfo = { google_calendar_event: { colorId: id } };

    let eventData = {
      user_event_id: getEventUserEventID(this.props.event),
      user_calendar_id: getEventUserCalendarID(this.props.event),
      event: eventInfo,
    };

    return eventData;
  }

  updatePaintSettings() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PAINTER_SETINGS });
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    recentTags,
    isDarkMode,
    originalRecurrenceEventIndex,
  } = state;

  return {
    currentUser,
    recentTags,
    isDarkMode,
    originalRecurrenceEventIndex,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPaintOrder: (event) =>
      dispatch({ data: event, type: "SET_PAINT_ORDER" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} allCalendars={allCalendars} masterAccount={masterAccount} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(ColorCommandCenter));
