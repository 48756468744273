import React from "react";
import { Edit2 } from "react-feather";

export default function SpecialTagsHeader({ isOpen, openSection, label }) {
  return (
    <div className="font-weight-500 default-font-size flex justify-between items-center">
      {label}
      {isOpen ? null : (
        <Edit2
          className="hoverable-secondary-text-color"
          size={16}
          onClick={openSection}
        />
      )}
    </div>
  );
}
