import React, { Component } from "react";
import { truncateConferenceUrl } from "../services/googleCalendarHelpers";
import {
  determineNativeConferenceInfo,
  OpenLink,
} from "../services/commonUsefulFunctions";
import CopiableContentContainer from "./copiableContentContainer";
import { Phone, AlertCircle, Radio, ExternalLink } from "react-feather";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getEventConferenceData } from "../services/eventResourceAccessors";
import { isConferenceDataUniqueZoomMeeting } from "../lib/zoomFunctions";
import { sanitizeStringAndLinkify } from "../lib/jsVariables";

class NativeConferencingInfo extends Component {
  render() {
    if (!this.props.event) {
      return null;
    }

    return this.renderAdditionalConferenceInformation();
  }

  renderNativeURL(conferenceRenderInfo) {
    if (!conferenceRenderInfo?.conferenceUrl) {
      return null;
    }
    if (isConferenceDataUniqueZoomMeeting(getEventConferenceData(this.props.event))) {
      // do not show in zoom
      // shows for zoom personal link
      return null;
    }
    const {
      conferenceUrl,
    } = conferenceRenderInfo;
    return (
      <div
        className="zoom-additional-info-section"
        style={{ wordBreak: "break-all", width: 270 }}
      >
        <CopiableContentContainer
          style={{
            alignItems: "flex-start",
            width: this.props.fromEventForm ? 280 : undefined,
          }}
          textToCopy={conferenceUrl}
          hotKeys={this.props.showCopiableHotKeys ? "Y V" : ""}
          copiableHintRightHandeSide={
            conferenceUrl.length <= 25
          }
          hotKeyRight="0px"
        >
          {truncateConferenceUrl(conferenceUrl)}
        </CopiableContentContainer>
      </div>
    );
  }

  renderAdditionalConferenceInformation() {
    const conferenceRenderInfo = determineNativeConferenceInfo(this.props.event);

    if (isEmptyObjectOrFalsey(conferenceRenderInfo)) {
      return null;
    }

    return (
      <div>
        {this.renderNativeURL(conferenceRenderInfo)}

        {conferenceRenderInfo.stream &&
          conferenceRenderInfo.stream.url &&
          this.renderStreamContent(conferenceRenderInfo.stream)}

        {(conferenceRenderInfo.id || conferenceRenderInfo.password) && (
          <div className="zoom-additional-info-section">
            {conferenceRenderInfo.id && (
              <div>
                <CopiableContentContainer textToCopy={conferenceRenderInfo.id}>
                  ID: {conferenceRenderInfo.id}
                </CopiableContentContainer>
              </div>
            )}

            {conferenceRenderInfo.password && (
              <div>
                <CopiableContentContainer textToCopy={conferenceRenderInfo.password}>
                  Password: {conferenceRenderInfo.password}
                </CopiableContentContainer>
              </div>
            )}

            {conferenceRenderInfo?.passcode && (
              <div>
                <CopiableContentContainer textToCopy={conferenceRenderInfo.passcode}>
                  Passcode: {conferenceRenderInfo.passcode}
                </CopiableContentContainer>
              </div>
            )}
          </div>
        )}

        {conferenceRenderInfo.phone &&
          conferenceRenderInfo.phone.length > 0 &&
          this.renderPhoneSection(conferenceRenderInfo)}

        {conferenceRenderInfo.notes && this.renderNotesSection(conferenceRenderInfo)}

        {conferenceRenderInfo.moreInfo &&
          !this.props.fromEventForm &&
          this.renderMoreInfoSection(conferenceRenderInfo)}
      </div>
    );
  }

  renderStreamContent(streamInfo) {
    let streamLabel = streamInfo.label || streamInfo.url;

    return (
      <div className="zoom-additional-info-container" style={{ marginTop: 5 }}>
        {!this.props.fromEventForm && (
          <div className="margin-top-3">
            <Radio className="non-clickable-icon" size="14" />
          </div>
        )}

        <div className="margin-left-ten"></div>

        <div style={this.props.fromEventForm ? { marginLeft: 15 } : {}}>
          <CopiableContentContainer textToCopy={streamInfo.url}>
            <div
              className="hoverable-text"
              onClick={() => OpenLink(streamInfo.url)}
            >
              {streamInfo.text}
            </div>
          </CopiableContentContainer>

          {streamLabel && (
            <div
              className="zoom-additional-info-section"
              style={{ marginLeft: 0 }}
            >
              {streamLabel}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNotesSection(conferenceInfo) {
    if (!conferenceInfo?.notes) {
      return null;
    }
    const {
      notes,
      conferenceUrl,
    } = conferenceInfo;
    const cleanedHTML = sanitizeStringAndLinkify(notes);
    const textToCopy = conferenceUrl || notes;
    return (
      <div className="zoom-additional-info-container width-250">
        {!this.props.fromEventForm && (
          <div className="margin-top-1">
            <AlertCircle className="secondary-text-color" size="14" />
          </div>
        )}

        <div
          style={{ marginLeft: this.props.fromEventForm ? 23 : 8 }}
        >
          <CopiableContentContainer textToCopy={textToCopy}>
            <div
              target="_blank"
              dangerouslySetInnerHTML={{ __html: cleanedHTML }}
              className="native-conference-info-width"
            ></div>
          </CopiableContentContainer>
        </div>
      </div>
    );
  }

  renderPhoneSection(conferenceInfo) {
    return (
      <div className="zoom-additional-info-container width-300 secondary-text-color">
        {!this.props.fromEventForm && !this.props.isPhone && (
          <div className="margin-top-3">
            <Phone className="secondary-text-color" size="13" />
          </div>
        )}

        <div
          className="margin-left-ten"
          style={
            this.props.fromEventForm || this.props.isPhone
              ? { marginLeft: 23 }
              : {}
          }
        >
          {this.renderPhoneInfo(conferenceInfo.phone)}
        </div>
      </div>
    );
  }

  renderPhoneInfo(conferenceInfo) {
    return conferenceInfo.map((z, index) => {
      return (
        <div key={`event_hangout_link_${z.regionCode}_${index}`}>
          <CopiableContentContainer textToCopy={z.label}>
            {`(${z.regionCode}) ${z.label}`}
          </CopiableContentContainer>

          {z.pin ? (
            <CopiableContentContainer textToCopy={z.pin}>
              <div className="display-flex-flex-direction-row">
                {"PIN:"}

                <div className="margin-left-5">{z.pin}</div>
              </div>
            </CopiableContentContainer>
          ) : null}

          {z.meetingCode && (
            <div className="display-flex-flex-direction-row">
              {"ID:"}

              <div className="margin-left-5">{z.meetingCode}</div>
            </div>
          )}

          {z.passcode && (
            <div className="display-flex-flex-direction-row">
              {"Passcode:"}

              <div className="margin-left-5">{z.passcode}</div>
            </div>
          )}

          {z.password && (
            <div className="display-flex-flex-direction-row">
              {"Password:"}

              <div className="margin-left-5">{z.password}</div>
            </div>
          )}

          {z.accessCode && (
            <div className="display-flex-flex-direction-row">
              {"Access code:"}

              <div className="margin-left-5">{z.accessCode}</div>
            </div>
          )}
        </div>
      );
    });
  }

  renderMoreInfoSection(conferenceInfo) {
    return (
      <div
        className="native-conferencing-more-info"
        onClick={() => OpenLink(conferenceInfo.moreInfo.url)}
      >
        <ExternalLink className="non-clickable-icon" size="14" />

        <div className="font-size-12 margin-left-ten hoverable-text secondary-text-color">
          {conferenceInfo.moreInfo.text}
        </div>
      </div>
    );
  }
}

export default NativeConferencingInfo;
