import React, { useState } from "react";
import { Plus } from "react-feather";
import EventModalPopup from "../eventModalPopup";
import { getTransparentModalStyle } from "../../lib/styleFunctions";
import { useSelector } from "react-redux";
import {
  REMINDER_EMAIL_BODY_VARIABLES,
  REMINDER_EMAIL_CONTENT,
  REMINDER_EMAIL_TITLE_VARIABLES,
  getHumanReadableReminderEmailText,
} from "./reminderEmailFunctions";

const TITLE_ID = "add-variable-button-title";
const BODY_ID = "add-variable-button-body";

export default function AddVariableButton({ contentType, onAddVariable }) {
  const [isOpen, setIsOpen] = useState(false);
  const [top, setTop] = useState(0);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onClickOpen = () => {
    const elementDom = document.getElementById(getID());

    if (!elementDom) {
      return;
    }
    const elementPositionInfo = elementDom.getBoundingClientRect();
    setTop(elementPositionInfo.top + 24);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const getID = () => {
    switch (contentType) {
      case REMINDER_EMAIL_CONTENT.TITLE:
        return TITLE_ID;
      case REMINDER_EMAIL_CONTENT.BODY:
        return BODY_ID;
      default:
        return;
    }
  };

  const getContentOptions = () => {
    switch (contentType) {
      case REMINDER_EMAIL_CONTENT.TITLE:
        return Object.values(REMINDER_EMAIL_TITLE_VARIABLES);
      case REMINDER_EMAIL_CONTENT.BODY:
        return Object.values(REMINDER_EMAIL_BODY_VARIABLES);
      default:
        return;
    }
  };

  const renderContent = () => {
    if (!isOpen) {
      return null;
    }
    const options = getContentOptions();
    return (
      <div>
        {options.map((option) => {
          return (
            <div
              key={`${option}`}
              className="p-3 modal-text-options-select modal-text-hover-override flex justify-between select-none default-font-size cursor-pointer"
              onClick={() => {
                onClose();
                onAddVariable(option);
              }}
            >
              {getHumanReadableReminderEmailText(option)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className="flex items-center hoverable-secondary-text-color"
        onClick={onClickOpen}
        id={getID()}
      >
        <Plus size={14} />
        <div className="ml-1 default-font-size">Variable</div>
      </div>
      <EventModalPopup
        isOpen={isOpen}
        onRequestClose={onClose}
        width={200}
        title={undefined}
        style={getTransparentModalStyle({
          top,
          isDarkMode,
          right: "134px",
        })}
        hideCloseButton={true}
        hideTitle={true}
      >
        {renderContent()}
      </EventModalPopup>
    </>
  );
}
