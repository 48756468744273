import React from "react";

const NoSound = ({ className, size=24 }) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36462 9.34356H3.79171C3.19371 9.34356 2.70837 9.82889 2.70837 10.4269V15.5727C2.70837 16.1707 3.19371 16.6561 3.79171 16.6561H6.36462L10.6752 20.308C11.3794 20.9049 12.4584 20.4044 12.4584 19.4814V6.51822C12.4584 5.59522 11.3783 5.09472 10.6752 5.69164L6.36462 9.34356Z"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8285 13.0701C17.8252 12.2489 17.4526 11.4505 16.7917 10.937"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8285 13.0709C17.8252 13.8921 17.4526 14.6905 16.7917 15.204"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.125 4.33337L3.25 22.2084"
        stroke="white"
        strokeOpacity="0.7"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
);

export default NoSound;
