import React, { useMemo } from "react";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { X } from "react-feather";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import {
  EXTERNAL_INTERNAL_CONSTS,
  getHumanReadableTimeInMinutes,
  getMetricsData,
  getMetricsExternalPercentage,
  getMetricsInHumanReadableHoursAndMinutes,
  getMetricsInternalPercentage,
  getMetricsMeetingTypeExternal,
  getMetricsMeetingTypeInternal,
  trackMetricsOpen,
} from "../metrics/metricsAccessorFunctions";
import { useSelector } from "react-redux";
import { useMetricsStore } from "../../services/stores/metricsStore";
import {
  getExternalTagFromUser,
  getInternalTagFromUser,
  getTagColorFilteringForTransparent,
} from "../../lib/tagsFunctions";
import classNames from "classnames";
import { isZero } from "../../lib/intFunctions";

export default function MetricsIsReadyModal({ onClose }) {
  const currentUser = useSelector((state) => state.currentUser);
  const metricsData = useMetricsStore((state) => state.metricsData);
  const data = useMemo(() => getMetricsData(metricsData), [metricsData]);
  return (
    <div className="p-5">
      {renderHeader({ onClose, data })}
      {renderGraph({ data })}
      {renderDescription({ data })}
      {renderGoToMetricsButton({ onClose, currentUser })}
    </div>
  );
}

function renderHeader({ onClose, data }) {
  return (
    <div className="flex justify-between">
      <div className="font-weight-400 font-size-14">
        <div>
          <span className="secondary-text-color">You spent</span>
          <span className="ml-1">
            {getMetricsInHumanReadableHoursAndMinutes({ data })}
          </span>
        </div>

        <div className="secondary-text-color">in meetings last week</div>
      </div>

      <X className="clickable-icon" size={16} onClick={onClose} />
    </div>
  );
}

function renderGraph({ data }) {
  const internalPercentage = getMetricsInternalPercentage({ data });
  const externalPercentage = getMetricsExternalPercentage({ data });
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <div className="display flex items-center gap-1 mt-4">
      <div
        className={classNames("h-2 rounded-l-md", isZero(parseInt(externalPercentage)) ? "rounded-r-md" : "")}
        style={{
          width: `${parseInt(internalPercentage)}%`,
          backgroundColor:
            getTagColorFilteringForTransparent(
              getInternalTagFromUser({ user: currentUser })
            ) ?? EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor,
        }}
      ></div>
      <div
        className={classNames("h-2 rounded-r-md", isZero(parseInt(internalPercentage)) ? "rounded-l-md" : "")}
        style={{
          width: `${parseInt(externalPercentage)}%`,
          backgroundColor:
            getTagColorFilteringForTransparent(
              getExternalTagFromUser({ user: currentUser })
            ) ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor,
        }}
      ></div>
    </div>
  );
}

function renderDescription({ data }) {
  const EXTERNAL = "external";
  const INTERNAL = "internal";
  const currentUser = useSelector((state) => state.currentUser);

  const renderSection = (type) => {
    const getBackgroundColor = () => {
      if (type === INTERNAL) {
        return (
          getTagColorFilteringForTransparent(
            getInternalTagFromUser({ user: currentUser })
          ) ?? EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor
        );
      }
      return (
        getTagColorFilteringForTransparent(
          getExternalTagFromUser({ user: currentUser })
        ) ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor
      );
    };
    const getHumanReadableAmount = () => {
      if (type === INTERNAL) {
        return getHumanReadableTimeInMinutes(
          getMetricsMeetingTypeInternal({ data })
        );
      }
      return getHumanReadableTimeInMinutes(
        getMetricsMeetingTypeExternal({ data })
      );
    };

    return (
      <div className="flex gap-2 default-font-size">
        <div
          className="rounded-full h-3 w-3 mt-0.5"
          style={{
            backgroundColor: getBackgroundColor(),
          }}
        ></div>
        <div>
          {type === INTERNAL ? "Internal" : "External"}
          <div className="secondary-text-color">{getHumanReadableAmount()}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex gap-6 mt-4">
      {renderSection(INTERNAL)}
      {renderSection(EXTERNAL)}
    </div>
  );
}

function renderGoToMetricsButton({ onClose, currentUser }) {
  const onClick = () => {
    trackMetricsOpen({
      where: "metrics modal",
      user: currentUser,
    });
    layoutBroadcast.publish("SHOW_METRICS_MODAL");
    onClose();
  };
  return (
    <div className="flex w-full justify-end">
      <CustomButton
        buttonType={BLUE_BUTTON}
        onClick={onClick}
        label="View full metrics"
        className="mt-4"
      />
    </div>
  );
}
