import { StripeCost } from "../../components/teamPlans/updatedAddTeamMembersModal";

export function getStripeCostDueToday(stripeCost: StripeCost) {
  return stripeCost.dueToday;
}

export function getStripeCostNewTotal(stripeCost: StripeCost) {
  return stripeCost.newTotal;
}

export function getStripeCostNewSeatsTotal(stripeCost: StripeCost) {
  return stripeCost.newSeatsTotal;
}

export function getStripeCostUnitPrices(stripeCost: StripeCost) {
  const { vimcal, vimcalEA } = stripeCost.unitPrices;
  return { vimcalCoreUnitPrice: vimcal, vimcalEAUnitPrice: vimcalEA };
}

export function getStripeCostVimcalCoreUnitPrice(stripeCost: StripeCost) {
  return stripeCost.unitPrices?.vimcal;
}

export function getStripeCostVimcalEAUnitPrice(stripeCost: StripeCost) {
  return stripeCost.unitPrices?.vimcalEA;
}
