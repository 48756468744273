import React from "react";

const Headphones = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.11 18.6888L16.3494 14.9605C16.6993 13.903 17.8054 13.2941 18.8862 13.5641V13.5641C19.4623 13.7095 19.9519 14.0884 20.2372 14.6096C20.5226 15.1309 20.5779 15.7474 20.3899 16.3111L19.1506 20.0394C18.8007 21.0969 17.6946 21.7058 16.6138 21.4358V21.4358C16.0377 21.2904 15.5481 20.9115 15.2628 20.3903C14.9774 19.869 14.9221 19.2525 15.11 18.6888Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1506 20.0394L20.2852 16.6262C20.7586 15.202 20.9999 13.711 20.9999 12.2101V11.9999C20.9999 7.02931 16.9705 2.99988 11.9999 2.99988V2.99988C7.02938 2.99988 2.99994 7.02931 2.99994 11.9999V12.2098C2.99994 13.7106 3.24127 15.2017 3.7147 16.6259L4.84937 20.0394"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.88998 18.6888L7.65061 14.9605C7.30065 13.903 6.19453 13.2941 5.11381 13.5641V13.5641C4.53764 13.7095 4.04808 14.0884 3.76273 14.6096C3.47738 15.1309 3.42203 15.7474 3.60998 16.3111L4.84928 20.0394C5.19923 21.0969 6.30535 21.7058 7.38608 21.4358V21.4358C7.96227 21.2904 8.45186 20.9116 8.73723 20.3903C9.02259 19.8691 9.07794 19.2525 8.88998 18.6888Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Headphones;
