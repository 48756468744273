import React from "react";
import { BLUE_BUTTON } from "../services/globalVariables";
import CustomButton from "./customButton";
import {
  ACCOUNT_STATE_PAYING,
  ACCOUNT_STATE_BACKSTAGE,
} from "../lib/vimcalVariables";
import {
  isUserInOnboarding,
} from "../lib/stateManagementFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";

export default function PromotionModalScreen({ modals, closeModal }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const determineSubtitle = () => {
    // Assumption that (personal) onboarding requires a credit card, so we immediately apply promotion
    if (
      isUserInOnboarding(masterAccount) ||
      [ACCOUNT_STATE_PAYING, ACCOUNT_STATE_BACKSTAGE].includes(
        masterAccount?.state
      )
    ) {
      return "Your promotion has been applied!";
    }
    return "When you start your subscription at the end of your 7-day free trial, your promotion will be applied!";
  };

  return (
    <div>
      <div className="default-font-size">{determineSubtitle()}</div>

      <div className="w-full flex justify-end mt-10">
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={closeModal}
          addPaddingToRight={true}
          label="Sounds good"
        />
      </div>
    </div>
  );
}
