import classNames from "classnames";
import React from "react";

type AnimatedGreenCheckMarkProps = Omit<React.SVGProps<SVGSVGElement>, "children" | "viewBox" | "xmlns">

/**
 * @see https://stackoverflow.com/questions/41078478/css-animated-checkmark
 */
export default function AnimatedGreenCheckMark({ className, ...props }: AnimatedGreenCheckMarkProps) {
  return (
    <svg
      className={classNames("checkmark", className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      {...props}
    >
      <circle
        className="checkmark__circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
}
