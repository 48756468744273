import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BLUE_BUTTON,
  WHATS_APP_ICON,
  PHONE_MEETING_ICON,
} from "../../../../services/globalVariables";
import CustomButton from "../../../customButton";
import { IsValidPhoneNumber } from "../../../../services/commonUsefulFunctions";
import {
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
} from "../../../../services/googleCalendarService";
import PhoneNumber from "awesome-phonenumber";
import CustomSelect from "../../../select";
import DisabledButton from "../../../disabledButton";
import classNames from "classnames";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import { getUserEmail } from "../../../../lib/userFunctions";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import { getDefaultPhoneOption } from "../../../../lib/settingsFunctions";

const ERROR_NOT_PHONE_NUMBER = "* Please enter a valid phone number.";

class updatePhone extends Component {
  constructor(props) {
    super(props);

    const phoneNumber = this.getUser().phone_number || "";
    const regionCode = this.getUser().phone_region_code || "US";

    const currentDefaultPhoneOption = getDefaultPhoneOption({ user: this.getUser() });

    this.state = {
      phoneNumber,
      regionCode,
      errorMessage: null,
      regionCodeOptions: this.createRegionCode(),
      defaultPhoneOption: currentDefaultPhoneOption,
      phoneOptions: this.createPhoneOptions(),
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
    this.onChangeRegionCode = this.onChangeRegionCode.bind(this);
    this.onChangeDefaultPhone = this.onChangeDefaultPhone.bind(this);
  }

  render() {
    return (
      <div className="width-100-percent mt-2">
        {this.renderSelectDefault()}

        <div className="display-flex-flex-direction-row align-items-center justify-between">
          <div className="default-font-size secondary-text-color">Number</div>

          <div className="display-flex-flex-direction-row">
            <CustomSelect
              showBorder={true}
              value={{
                label: this.createRegionCodeText(this.state.regionCode),
                value: this.state.regionCode,
              }}
              onChange={this.onChangeRegionCode}
              options={this.state.regionCodeOptions}
              isSearchable
              classNamePrefix="dark-mode-modal"
              className={classNames(
                "select-region-code",
                this.props.isDarkMode ? "dark-mode-select" : "",
                "select-default-font-size"
              )}
              maxMenuHeight={100}
            />

            <input
              className="default-input-field margin-left-ten default-font-size"
              style={{ paddingLeft: 10 }}
              value={this.state.phoneNumber}
              onChange={this.onChangeText}
              placeholder="e.g. 415-123-4567"
            />
          </div>
        </div>

        {this.renderIsInvalid()}

        {this.renderButtons()}
      </div>
    );
  }

  determinePhoneLabel(backendValue) {
    // given backend value -> create label for react-select
    switch (backendValue) {
      case BACKEND_PHONE:
        return this.renderPhoneLabel(PHONE_MEETING_ICON, "Phone");
      case BACKEND_WHATS_APP:
        return this.renderPhoneLabel(WHATS_APP_ICON, "WhatsApp");
      default:
        return this.renderPhoneLabel(PHONE_MEETING_ICON, "Phone");
    }
  }

  renderPhoneLabel(iconURL, label) {
    return (
      <div className="display-flex-flex-direction-row align-items-center">
        <img alt="" width="16px" height="16px" src={iconURL} />

        <div className="default-font-size margin-left-five margin-top-2">
          {label}
        </div>
      </div>
    );
  }

  renderSelectDefault() {
    return (
      <div className="display-flex-flex-direction-row align-items-center margin-bottom-20 justify-content-space-between">
        <div className="default-font-size secondary-text-color">Type</div>

        <CustomSelect
          showBorder={true}
          classNamePrefix="dark-mode-modal"
          className={classNames(
            "select-default-phone-type",
            this.props.isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          options={this.state.phoneOptions}
          value={{
            label: this.determinePhoneLabel(this.state.defaultPhoneOption),
            value: this.state.defaultPhoneOption,
          }}
          onChange={this.onChangeDefaultPhone}
        />
      </div>
    );
  }

  renderIsInvalid() {
    if (this.state.errorMessage) {
      return (
        <div
          className="event-form-different-time-zone-warning"
          style={{ fontSize: 12, width: "100%" }}
        >
          {this.state.errorMessage}
        </div>
      );
    }
  }

  renderButtons() {
    const {
      phoneNumber,
      regionCode,
      defaultPhoneOption      
    } = this.state;

    const {
      initialPhoneNumber,
      initialRegionCode,
      initialDefaultPhoneOption,
    } = this.getExistingInitalPhoneData();

    return (
      <div className="display-flex-flex-direction-row-justify-content-flex-end mt-4">
        {phoneNumber !== initialPhoneNumber ||
        regionCode !== initialRegionCode ||
        initialDefaultPhoneOption !== defaultPhoneOption ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => this.updatePhoneNumber(false)}
            label="Save"
          />
        ) : (
          <DisabledButton label="Save" />
        )}
      </div>
    );
  }

  getExistingInitalPhoneData() {
    const user = this.getUser();
    return {
      initialPhoneNumber: user.phone_number || "",
      initialRegionCode: user.phone_region_code || "",
      initialDefaultPhoneOption: getDefaultPhoneOption({ user }),
    };
  }

  onChangeText(e) {
    const text = getInputStringFromEvent(e);
    let updatedState = { phoneNumber: text };
    let phoneNumber = text ? text.trim() : "";
    if (
      this.state.errorMessage &&
      IsValidPhoneNumber(phoneNumber, this.state.regionCode)
    ) {
      updatedState["errorMessage"] = false;
    }

    this.setState(updatedState);
  }

  onChangeRegionCode(data) {
    this.setState({ regionCode: data.value });
  }

  createRegionCode() {
    let possibleRegionCode = PhoneNumber.getSupportedRegionCodes();

    return possibleRegionCode.map((c) => {
      return { value: c, label: this.createRegionCodeText(c) };
    });
  }

  createRegionCodeText(regionCode) {
    return `${regionCode} (+${PhoneNumber.getCountryCodeForRegionCode(
      regionCode
    )})`;
  }

  updatePhoneNumber(shouldSetAsDefault = false) {
    const phoneNumber = this.state.phoneNumber
      ? this.state.phoneNumber.trim()
      : "";

    if (phoneNumber.length === 0) {
      // Delete phone number
    } else if (!IsValidPhoneNumber(phoneNumber, this.state.regionCode)) {
      this.setState({ errorMessage: ERROR_NOT_PHONE_NUMBER });
      return;
    }

    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    // Backend phone example:
    // {
    //   phone_number: '925-818-5555',
    //   phone_region_code: 'US'
    // }
    const phoneInfo = {
      phone_number: this.state.phoneNumber,
      phone_region_code: this.state.regionCode,
      default_phone_option: this.state.defaultPhoneOption,
    };

    backendBroadcasts.publish("UPDATE_PHONE_NUMBER", {phoneInfo, user: this.getUser()});

    if (shouldSetAsDefault) {
      updateSettingsBroadcast.publish(
        UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_CONFERENCING,
        {defaultConferencing: BACKEND_PHONE, user: this.getUser()},
      );
    }
  }

  onChangeDefaultPhone(option) {
    this.setState({ defaultPhoneOption: option.value });
  }

  createPhoneOptions() {
    return [
      { label: this.determinePhoneLabel(BACKEND_PHONE), value: BACKEND_PHONE },
      {
        label: this.determinePhoneLabel(BACKEND_WHATS_APP),
        value: BACKEND_WHATS_APP,
      },
    ];
  }

  getUser() {
    return this.props.inputUser ?? this.props.currentUser;
  }
}

function mapStateToProps(state) {
  const { currentUser, isDarkMode } =
    state;

  return { currentUser, isDarkMode };
}

export default connect(mapStateToProps, null)(updatePhone);
