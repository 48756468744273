import { useEffect } from "react";

// https://stackoverflow.com/questions/31060642/preload-multiple-audio-files
// https://stackoverflow.com/questions/71509562/how-to-pre-download-items-from-a-json-list-array-in-react-js
export const usePreloadAudio = (audioLinks) => {
	const preloadAudio = (url) => {
    let audio = new Audio();
    // once this file loads, it will call loadedAudio()
    // the file will be kept by the browser as cache
    audio.addEventListener('canplaythrough', loadedAudio, false);
    audio.src = url;
	}

	const loadedAudio = () => {
		// function that gets called when audio is loaded
	}

  useEffect(() => {
		if (audioLinks?.length > 0) {
			for (const audioLink of audioLinks) {
				preloadAudio(audioLink);
			}
		}
		
    return () => {
    };
  }, []);
};
