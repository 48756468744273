import { isEmptyArray } from "../../../../lib/arrayFunctions";
import {
  PAINTER_MATCH_ALL,
  PAINTER_MATCH_ANY,
  SMART_TAG_VALUE_RULE,
  getPainterAutoTagField,
} from "../../../../lib/painterFunctions";
import { capitalizeFirstLetter, lowerCaseAndTrimString } from "../../../../lib/stringFunctions";
import { createUUID } from "../../../../services/randomFunctions";

export const OPTION_CONTAINS_ANY = {
  value: SMART_TAG_VALUE_RULE.CONTAINS_ANY,
  label: "contains any of",
};

export const OPTION_CONTAINS_ALL = {
  value: SMART_TAG_VALUE_RULE.CONTAINS_ALL,
  label: "contains all of",
};

export const OPTION_EQUAL = {
  value: SMART_TAG_VALUE_RULE.EQUAL,
  label: "is exactly",
};

const OPTION_IN_DOMAIN = {
  value: SMART_TAG_VALUE_RULE.IN_DOMAIN,
  label: "contains domain",
};

const ATTENDEE_OPTIONS = [
  OPTION_CONTAINS_ANY,
  OPTION_CONTAINS_ALL,
  OPTION_IN_DOMAIN,
];

const LOCATION_OPTIONS = [OPTION_CONTAINS_ANY, OPTION_CONTAINS_ALL];

const TITLE_OPTIONS = [OPTION_CONTAINS_ANY, OPTION_CONTAINS_ALL, OPTION_EQUAL];

export const OPTION_TYPE = {
  ATTENDEE: "attendees",
  TITLE: "title",
  LOCATION: "location",
};

export function getOptions(name) {
  switch (lowerCaseAndTrimString(name)) {
    case OPTION_TYPE.ATTENDEE:
      return ATTENDEE_OPTIONS;
    case OPTION_TYPE.TITLE:
      return TITLE_OPTIONS;
    case OPTION_TYPE.LOCATION:
      return LOCATION_OPTIONS;
    default:
      return TITLE_OPTIONS;
  }
}

export function getComparisonType(comparisonType) {
  switch (comparisonType) {
    case OPTION_CONTAINS_ANY.value:
      return OPTION_CONTAINS_ANY;
    case OPTION_CONTAINS_ALL.value:
      return OPTION_CONTAINS_ALL;
    case OPTION_EQUAL.value:
      return OPTION_EQUAL;
    case OPTION_IN_DOMAIN.value:
      return OPTION_IN_DOMAIN;
    default:
      return OPTION_CONTAINS_ANY;
  }
}

export function getSplitValue(str) {
  if (!str) {
    return [];
  }
  return str
    .split(",")
    .map((e) => lowerCaseAndTrimString(e))
    .filter((val) => !!val);
}

export function splitAttendeeSearchString(str) {
  if (!str) {
    return [];
  }

  return str.split(/(?:, |,|\s)/); // Split on space, comma and space, or just comma
}

export function isConditionIsInDomain(condition) {
  return condition === OPTION_IN_DOMAIN.value;
}

export function isConditionContainsAny(condition) {
  return condition === OPTION_CONTAINS_ANY.value;
}

export function isConditionContainsAll(condition) {
  return condition === OPTION_CONTAINS_ALL.value;
}

export function isConditionalLegacyMatch(condition) {
  return condition === SMART_TAG_VALUE_RULE.MATCH;
}

export function isConditionalEqual(condition) {
  return condition === OPTION_EQUAL.value;
}

export function isAttendeesRule(name) {
  return lowerCaseAndTrimString(name) === OPTION_TYPE.ATTENDEE;
}

export function getProtectedValues(values) {
  return values ?? [];
}

export function getInitialTagRuleSections(colorInfo) {
  const autoTagFields = getPainterAutoTagField(colorInfo);
  if (isEmptyArray(autoTagFields)) {
    return [createEmptyRule()];
  }
  return autoTagFields.map((field) => {
    return {
      ...field,
      uuid: createUUID(),
    };
  });
}

export function createEmptyRule() {
  return {
    name: RULE_SECTION_TITLE,
    values: [],
    condition: SMART_TAG_VALUE_RULE.CONTAINS_ANY,
    uuid: createUUID(),
  };
}

const ALL_OF_FOLLOWING_RULE_REACT_SELECT = {
  value: PAINTER_MATCH_ALL,
  label: capitalizeFirstLetter(PAINTER_MATCH_ALL),
};
const AT_LEAST_ONE_RULE_REACT_SELECT = {
  value: PAINTER_MATCH_ANY,
  label: capitalizeFirstLetter(PAINTER_MATCH_ANY),
};

export const RULE_SECTION_TITLE = "Title";
export const RULE_SECTION_ATTENDEES = "Attendees";
export const RULE_SECTION_LOCATION = "Location";

export const RULE_SECTION_REACT_SELECT = [
  {
    value: RULE_SECTION_TITLE,
    label: capitalizeFirstLetter(RULE_SECTION_TITLE),
  },
  {
    value: RULE_SECTION_ATTENDEES,
    label: capitalizeFirstLetter(RULE_SECTION_ATTENDEES),
  },
  {
    value: RULE_SECTION_LOCATION,
    label: capitalizeFirstLetter(RULE_SECTION_LOCATION),
  },
];

export const RULES_OPTIONS = [
  ALL_OF_FOLLOWING_RULE_REACT_SELECT,
  AT_LEAST_ONE_RULE_REACT_SELECT,
];
