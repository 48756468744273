import React from "react";
import { Check } from "react-feather";

function VerifiedCheck(props) {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor || "#CEEAD6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: 12,
        height: 12,
        marginBottom: 3,
      }}
      className={props.className || ""}
    >
      <Check
        color={props.checkColor || "#137333"}
        size="10"
        style={{ strokeWidth: 4 }}
      />
    </div>
  );
}

export default VerifiedCheck;
