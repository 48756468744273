import React, { PureComponent } from "react";
import {
  createLabelAndValueForReactSelect,
  isInt,
  removeLeadingZeros,
  KEYCODE_ESCAPE,
} from "../services/commonUsefulFunctions";
import Classnames from "classnames";
import { getReactSelectBaseStyle } from "./select/styles";
import { connect } from "react-redux";
import { getMinutesList } from "./select/helpFunctions";
import DropdownIndicator from "./select/dropDownIndicator";
import { pluralize } from "../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import CreatableSelect from "../../components/creatableSelect";

const HOUR_LIST = createLabelAndValueForReactSelect([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
]);
const DAY_LIST = createLabelAndValueForReactSelect([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 0,
]);

const DAYS = "days";
const HOURS = "hours";
const MINUTES = "minutes";

class SelectDuration extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      days: this.props.duration.days || 0,
      hours: this.props.duration.hours || 0,
      minutes: this.props.duration.minutes || 0,
      daysQuery: "",
      hoursQuery: "",
      minutesQuery: "",
    };

    this.setDurationInEventForm = this.setDurationInEventForm.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const updatedState = {};
    if (this.state.days !== (this.props.duration.days || 0)) {
      updatedState.days = this.props.duration.days || 0;
    }
    if (this.state.hours !== (this.props.duration.hours || 0)) {
      updatedState.hours = this.props.duration.hours || 0;
    }
    if (this.state.minutes !== (this.props.duration.minutes || 0)) {
      updatedState.minutes = this.props.duration.minutes || 0;
    }
    if (!isEmptyObjectOrFalsey(updatedState)) {
      this.setState(updatedState);
    }
  }

  render() {
    return (
      <div
        className="event-form-duration-container"
        style={this.props.style || {}}
      >
        {this.renderAllDayOrHoursAndMinutes()}
      </div>
    );
  }

  renderAllDayOrHoursAndMinutes() {
    if (this.props.allDay) {
      return (
        <div className="display-flex-flex-direction-row">
          <div style={{ marginLeft: 1 }}>{this.renderCreatable(DAYS)}</div>

          <div
            className={Classnames(
              "display-flex-center",
              "event-form-duration-label"
            )}
          >
            {pluralize(this.state.days, "Day")}
          </div>
        </div>
      );
    } else {
      return (
        <div className="display-flex-flex-direction-row">
          <div>{this.renderCreatable(HOURS)}</div>

          <div
            className={Classnames(
              "display-flex-center",
              "event-form-duration-label"
            )}
          >
            {pluralize(this.state.hours, "Hour")}
          </div>

          <div className="margin-left-25">{this.renderCreatable(MINUTES)}</div>

          <div
            className={Classnames(
              "display-flex-center",
              "event-form-duration-label"
            )}
          >
            {pluralize(this.state.minutes, "Minute")}
          </div>
        </div>
      );
    }
  }

  renderCreatable(type) {
    let tabIndex;
    let ref;
    let value;
    let options;
    let query;

    const minutesList = getMinutesList(true);
    switch (type) {
      case DAYS:
        tabIndex = 2;
        ref = "days-input";
        value = { value: this.state.days, label: this.state.days };
        options = DAY_LIST;
        query = this.state.daysQuery;
        break;
      case HOURS:
        tabIndex = 3;
        ref = "hours-input";
        value = { value: this.state.hours, label: this.state.hours };
        options = HOUR_LIST;
        query = this.state.hoursQuery;
        break;
      default:
        tabIndex = 4;
        ref = "minutes-input";
        value = { value: this.state.minutes, label: this.state.minutes };
        options =
          this.state.hours === 0
            ? minutesList.filter((m) => m.value !== 0)
            : minutesList;
        query = this.state.minutesQuery;
        break;
    }

    return (
      <CreatableSelect
        tabIndex={tabIndex}
        ref={ref}
        className="select-duration-time select-default-font-size"
        classNamePrefix="dark-mode"
        value={value}
        options={options}
        onChange={(selected) => this.onChange(selected, type)}
        noOptionsMessage={() => "Set"}
        openMenuOnFocus={true}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        onKeyDown={(event) => this.onKeyDown(event, ref)}
        formatCreateLabel={(userInput) => userInput}
        tabSelectsValue={query.length > 0 ? true : false}
        onInputChange={(value) => this.onInputChange(value, type)}
        styles={getReactSelectBaseStyle({
          isDarkMode: this.props.isDarkMode,
          showBorder: true,
        })}
        components={{ DropdownIndicator }}
      />
    );
  }

  onInputChange(value, type) {
    switch (type) {
      case DAYS:
        this.setState({ daysQuery: value });

        break;
      case HOURS:
        this.setState({ hoursQuery: value });

        break;
      case MINUTES:
        this.setState({ minutesQuery: value });

        break;
      default:
        this.setState({ minutesQuery: value });
    }
  }

  onKeyDown(event, ref) {
    // ESC key
    if (event && event.keyCode === KEYCODE_ESCAPE) {
      this.refs[ref] && this.refs[ref].blur();

      this.props.onEscape && this.props.onEscape();
    }
  }

  onChange(selected, key) {
    if (isInt(selected.value)) {
      let value = Math.abs(removeLeadingZeros(selected.value));

      if (value === 0 && this.props.allDay) {
        value = 1;
      }

      this.setState({ [key]: value }, this.setDurationInEventForm);
    }
  }

  setDurationInEventForm() {
    let newDuration = {
      days: this.state.days,
      hours: this.state.hours,
      minutes: this.state.minutes,
    };

    this.props.onChange(newDuration);
  }
}

function mapStateToProps(state) {
  const { isDarkMode } = state;

  return { isDarkMode };
}

export default connect(mapStateToProps, null)(SelectDuration);
