import React from "react";
import { VIMCAL_DEFAULT_PLAN, VIMCAL_EA_PLAN } from "../sharedFunctions";
import { capitalizeFirstLetterOfEveryWord, pluralize } from "../../../../lib/stringFunctions";
import { DOLLARS_IN_CENTS, EA_ANNUAL_PRICE, EA_MONTHLY_PRICE, NON_EA_ANNUAL_PRICE, NON_EA_MONTHLY_PRICE } from "../../../../services/globalVariables";
import { StripeCost } from "../../updatedAddTeamMembersModal";
import { getHumanReadableDollarFromCents } from "../../../../lib/stripeFunctions";
import { useDefaultPaymentFromSubscription, useIsCurrentUserAdmin } from "../../hooks";
import { useDefaultPaymentMethod } from "../../../../services/stores/finance";
import { getStripeCostDueToday, getStripeCostNewTotal, getStripeCostUnitPrices } from "../../../../services/accessors/stripeCost";

interface ReviewAddingSeatsProps {
  defaultVimcalSeats: number
  isPayingMonthly: boolean
  onClickUpsertPayment: () => void
  stripeCost: StripeCost
  vimcalEASeats: number
}

/**
 * The content of the review page when new seats are being added (either through inviting users or through
 * adding empty seats).
 */
export default function ReviewAddingSeats({
  defaultVimcalSeats,
  isPayingMonthly,
  onClickUpsertPayment,
  stripeCost,
  vimcalEASeats,
}: ReviewAddingSeatsProps) {
  const defaultNonEAPrice = DOLLARS_IN_CENTS * (isPayingMonthly ? NON_EA_MONTHLY_PRICE : NON_EA_ANNUAL_PRICE);
  const defaultEAPrice = DOLLARS_IN_CENTS * (isPayingMonthly ? EA_MONTHLY_PRICE : EA_ANNUAL_PRICE);
  const { vimcalCoreUnitPrice, vimcalEAUnitPrice } = getStripeCostUnitPrices(stripeCost);

  return (
    <>
      <div className="pb-3">Team plan details</div>
      <div className="team-plan-review-shaded-section p-4 rounded-2xl flex flex-col gap-4">
        <InvoiceLineItem
          product={VIMCAL_DEFAULT_PLAN.label}
          seats={defaultVimcalSeats}
          costPerSeat={vimcalCoreUnitPrice ?? defaultNonEAPrice}
        />
        <InvoiceLineItem
          product={VIMCAL_EA_PLAN.label}
          seats={vimcalEASeats}
          costPerSeat={vimcalEAUnitPrice ?? defaultEAPrice}
        />
        <div className="flex justify-between">
          <div>Prorated amount due today</div>
          <div>${getHumanReadableDollarFromCents(getStripeCostDueToday(stripeCost) ?? 0)}</div>
        </div>
        <div className="text-right secondary-text-color">
          New {isPayingMonthly ? "monthly" : "yearly"} total: ${getHumanReadableDollarFromCents(getStripeCostNewTotal(stripeCost) ?? 0)}
        </div>
      </div>

      <div className="pt-8 pb-3">Payment method</div>
      <div className="team-plan-review-shaded-section p-4 rounded-2xl flex justify-between">
        <PaymentMethod onClickUpsertPayment={onClickUpsertPayment} />
      </div>
    </>
  );
}

interface InvoiceLineItemProps {
  product: string
  seats: number
  costPerSeat: number
}

function InvoiceLineItem({ costPerSeat, product, seats }: InvoiceLineItemProps) {
  if (seats <= 0) {
    // TODO: Once we allow users to remove seats from plans, we may need to revisit this.
    return null;
  }

  return (
    <div className="flex justify-between">
      <div>{product}</div>
      <div className="text-right">
        <div>${getHumanReadableDollarFromCents(seats * costPerSeat)}</div>
        <div className="secondary-text-color pt-1">{seats} added {pluralize(seats, "seat")} x ${getHumanReadableDollarFromCents(costPerSeat)}</div>
      </div>
    </div>
  );
}

/**
 * The payment method block on the review page.
 * If the user is an admin, they will be able to add/edit a payment method.
 * If the user is not an admin, it will not show any credit card details.
 */
function PaymentMethod({ onClickUpsertPayment }: Pick<ReviewAddingSeatsProps, "onClickUpsertPayment">) {
  const isAdmin = useIsCurrentUserAdmin();
  const needCard = useDefaultPaymentMethod(state => state.needCard);
  const cardInfo = useDefaultPaymentFromSubscription();

  if (isAdmin) {
    if (needCard) {
      return (
        <div className="hoverable-text" onClick={onClickUpsertPayment}>Add card</div>
      );
    }

    return (
      <>
        <div className="secondary-text-color">
          {capitalizeFirstLetterOfEveryWord(cardInfo?.brand)} ending in{" "}
          {cardInfo?.lastFour}
        </div>
        <div className="hoverable-text" onClick={onClickUpsertPayment}>Edit</div>
      </>
    );
  }

  if (needCard) {
    return (
      <div className="secondary-text-color">
        Please reach out to one of your admins to add a payment method.
      </div>
    );
  }

  return (
    <div className="secondary-text-color">
      A charge will be made to the payment method your admin has on file.
    </div>
  );
}
