import React                                from 'react';
import StyleConstants, { DARK_MODE_SECONDARY_TEXT_COLOR }                       from '../services/globalVariables';

const DarkModeConferenceRoomDoorSVG = ({isSecondary}) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
    <g fill="none" fillRule="evenodd">
      <circle cx="5.667" cy="7.074" r="1" fill={isSecondary ? DARK_MODE_SECONDARY_TEXT_COLOR : StyleConstants.darkModeTextColor} />
      <path stroke={isSecondary ? DARK_MODE_SECONDARY_TEXT_COLOR : StyleConstants.darkModeTextColor} strokeLinecap="round" strokeLinejoin="round" d="M1 11.689L3.889 11.689 3.889 1 10.333 1 10.333 11.689 13 11.689"/>
    </g>
  </svg>;

export default DarkModeConferenceRoomDoorSVG;
