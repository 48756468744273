import { useIsMounted } from "./useIsMounted";
import { useSelector } from "react-redux";
import AppBroadcast from "../../broadcasts/appBroadcast";
import { handleError } from "../commonUsefulFunctions";
import { fetchLatestMasterAccount } from "../../lib/fetchFunctions";
import { isEmptyObjectOrFalsey } from "../typeGuards";
import { isErrorResponse } from "../api";

export default function useLatestMasterAccount() {
  const currentUser = useSelector((state) => state.currentUser);
  const componentIsMounted = useIsMounted();

  const getLatestMasterAccount = async (handleUpdatedMasterAccount: (masterAccount: MasterAccount) => void) => {
    try {
      const response = await fetchLatestMasterAccount({ user: currentUser });
      if (
        !componentIsMounted.current ||
        isEmptyObjectOrFalsey(response) ||
        isErrorResponse(response) ||
        !response?.master_account
      ) {
        return;
      }

      const { master_account } = response;

      AppBroadcast.publish("UPDATE_MASTER_ACCOUNT", master_account);
      handleUpdatedMasterAccount(master_account);
    } catch (error) {
      handleError(error);
    }
  };

  return [getLatestMasterAccount];
}
