import React from "react";
import classNames from "classnames";

export default function PillButton({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) {

  return (
    <button
      className={classNames(
        "cursor-pointer default-font-size duration-200 select-none",
        "pill-button",
        "width-max-content",
        "flex items-center justify-center",
        "rounded-full",
        "border-0 font-light",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}
