import { useState, useEffect, useCallback, useRef } from "react";

interface ScrollInfo {
  isTop: boolean;
  isBottom: boolean;
}

export const useScrollDetection = (elementId: string): ScrollInfo => {
  const [scrollInfo, setScrollInfo] = useState<ScrollInfo>({
    isTop: true,
    isBottom: false,
  });

  // We need scrollInfo to be accessible in the callback, but the state will become stale
  // in timeout methods unless we use a ref.
  const scrollInfoRef = useRef(scrollInfo);
  useEffect(() => {
    scrollInfoRef.current = scrollInfo;
  }, [scrollInfo]);

  const handleScroll = useCallback(() => {
    const element = document.getElementById(elementId);
    if (!element) {
      return;
    }

    // Calculate scroll position and limits
    const scrollLeft = element.scrollLeft;
    const scrollWidth = element.scrollWidth;
    const clientWidth = element.clientWidth;

    // Only update state if the element has scrollable content
    if (scrollWidth > clientWidth) {
      // Check if at left edge
      const isAtLeft = scrollLeft === 0;

      // Check if at right edge (within 1px tolerance)
      const isAtRight = Math.abs(scrollWidth - clientWidth - scrollLeft) < 1;

      // Only update state when a value has changed, otherwise this will trigger re-renders on
      // every frame that the user is scrolling.
      if (scrollInfoRef.current.isTop !== isAtLeft || scrollInfoRef.current.isBottom !== isAtRight) {
        setScrollInfo({
          isTop: isAtLeft,
          isBottom: isAtRight,
        });
      }
    }
  }, [elementId]);

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (!element) {
      return;
    }

    // Add scroll listener
    element.addEventListener("scroll", handleScroll);

    // Add resize listener to handle container size changes
    window.addEventListener("resize", handleScroll);

    // Initial check after a small delay to ensure content is rendered
    const timeoutId = setTimeout(handleScroll, 100);

    // Cleanup function
    return () => {
      element.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      clearTimeout(timeoutId);
    };
  }, [elementId, handleScroll]);

  return scrollInfo;
};
