import { OptionTypeBase, StylesConfig } from "react-select";
import {
  DARK_MODE_CELL_BORDER,
  DARK_MODE_DIVDIER_COLOR,
  DARK_MODE_MODAL_BACKGROUND_COLOR,
  DEFAULT_FONT_SIZE_PX,
  LIGHT_MODE_DIVIDER_COLOR,
  LIGHT_MODE_HOVER_OUTLINE,
  MIN_HEIGHT,
  REACT_SELECT_BORDER_RADIUS,
} from "../../services/globalVariables";

interface DefaultOptionType {
  label: string
  value: string
}

type StyleGenerator<Props = void> = <
  OptionType extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
>(props: Props) => StylesConfig<OptionType, IsMulti>

interface GetReactSelectBaseStyleProps {
  isDarkMode?: boolean
  showBorder?: boolean
  controlWidth?: number | string
  menuListStyle?: React.CSSProperties
  menuPortalStyle?: React.CSSProperties
  controllerBackgroundColor?: string
  controllerHoverBackgroundColor?: string
  controllerMarginLeft?: number | string
  menuListMaxHeight?: number | string
  overrideMenuListBackgroundColor?: string
  singleValueDisplay?: string
  valueContainerHeight?: number | string
  textColor?: string
  controllerTextColor?: string
  hoveredControllerTextColor?: string
  lightModeOverride?: boolean
}

/* Default styles */
export const getReactSelectBaseStyle: StyleGenerator<GetReactSelectBaseStyleProps> = ({
  isDarkMode,
  showBorder = true,
  controlWidth,
  menuListStyle,
  menuPortalStyle = {},
  controllerBackgroundColor,
  controllerHoverBackgroundColor,
  controllerMarginLeft,
  menuListMaxHeight,
  overrideMenuListBackgroundColor,
  singleValueDisplay,
  valueContainerHeight,
  textColor,
  controllerTextColor,
  hoveredControllerTextColor,
  lightModeOverride = false,
}) => {
  return {
    clearIndicator: (provided) => ({
      ...provided,
      svg: {
        // transform: 'scale(0)', // Scale down the SVG to make it appear thinner
        height: 14,
        width: 14,
      },
      paddingRight: "0px",
    }),
    container: (base, state) => ({
      ...base,
      fontSize: DEFAULT_FONT_SIZE_PX,
      fontWeight: 300,
      "&:hover": {
        backgroundColor: "transparent",
      },
    }),
    groupHeading: (base) => ({
      ...base,
      marginBottom: "8px",
    }),
    control: (base) => ({
      ...base,
      color: controllerTextColor,
      borderColor: showBorder
        ? (isDarkMode && !lightModeOverride) ? DARK_MODE_DIVDIER_COLOR : LIGHT_MODE_DIVIDER_COLOR
        : "transparent",
      "&:hover": {
        borderColor: (isDarkMode && !lightModeOverride) ? "white" : LIGHT_MODE_HOVER_OUTLINE,
        backgroundColor: controllerHoverBackgroundColor ?? "transparent",
        color: hoveredControllerTextColor,
      },
      borderRadius: REACT_SELECT_BORDER_RADIUS,
      backgroundColor: controllerBackgroundColor ?? "transparent",
      boxShadow: "initial",
      width: controlWidth ?? undefined,
      marginLeft: controllerMarginLeft ?? undefined,
      minHeight: MIN_HEIGHT,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (base) => ({
      ...base,
      display: singleValueDisplay ?? "block", // block is default
      color: textColor,
    }),
    valueContainer: (base) => ({
      ...base,
      height: valueContainerHeight ?? undefined,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: menuListMaxHeight ?? 190,
      backgroundColor: overrideMenuListBackgroundColor,
    }),
    // Portals can be used to have the menu be a child of a different element than the control container.
    menuPortal: (base) => ({
      ...base,
      ...menuPortalStyle,
    }),
    ...(menuListStyle && menuListStyle),
  };
};

export const customMenuStyle: StyleGenerator<Partial<React.CSSProperties>> = ({ zIndex, ...styles }) => ({
  menu: (base, state) => ({
    ...base,
    zIndex: zIndex ?? 1,
    ...styles,
  }),
});

export const defaultStyles: StyleGenerator = () => ({
  container: (base) => ({
    ...base,
    fontSize: DEFAULT_FONT_SIZE_PX,
    fontWeight: 300,
  }),
  control: (base) => ({
    ...base,
    borderColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: LIGHT_MODE_HOVER_OUTLINE,
    },
    borderRadius: REACT_SELECT_BORDER_RADIUS,
    backgroundColor: "transparent",
    boxShadow: "initial",
    minHeight: MIN_HEIGHT,
  }),
});

/* Default dark mode styles (formerly dark-mode prefix) */
export const defaultDarkStyles: StyleGenerator = () => ({
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: "rgba(255, 255, 255, 0.85)",
    "&:hover": {
      backgroundColor: DARK_MODE_MODAL_BACKGROUND_COLOR,
      borderColor: DARK_MODE_CELL_BORDER,
    },
    borderRadius: REACT_SELECT_BORDER_RADIUS,
    minHeight: MIN_HEIGHT,
  }),
  input: (base) => ({
    ...base,
    color: "#d6d9dc",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#444754",
    color: "#d6d9dc",
  }),
  option: (base) => ({
    ...base,
    "&:focus": {
      backgroundColor: "#6d7289",
      color: "#d6d9dc",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "rgba(255, 255, 255, 0.85)",
  }),
});

/* Default dark mode styles (formerly dark-mode-modal prefix) */
export const defaultDarkModalStyles: StyleGenerator = () => ({
  control: (base) => ({
    ...base,
    backgroundColor: DARK_MODE_MODAL_BACKGROUND_COLOR,
    borderColor: DARK_MODE_CELL_BORDER,
    color: "#d6d9dc",
    "&:hover": {
      backgroundColor: "#444754",
      borderColor: "#444754",
    },
    borderRadius: REACT_SELECT_BORDER_RADIUS,
    minHeight: MIN_HEIGHT,
  }),
  input: (base) => ({
    ...base,
    color: "#d6d9dc",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#6d7289",
    color: "#d6d9dc",
  }),
  option: (base) => ({
    ...base,
    "&:focus": {
      backgroundColor: "#6d7289",
      color: "#d6d9dc",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "#d6d9dc",
  }),
});
