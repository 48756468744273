import React, { useEffect, useRef, useState } from "react";
import Broadcast from "../broadcasts/broadcast";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import { useSelector } from "react-redux";
import {
  getGoogleEventId,
  constructQueryParams,
} from "../services/commonUsefulFunctions";
import { constructRequestURL } from "../services/api";
import {
  BUSY_DURING_EVENT,
  DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
  OUT_OF_OFFICE_DURING_EVENT,
  OUT_OF_OFFICE_EVENT_TYPE,
} from "../services/googleCalendarService";
import { SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../services/globalVariables";
import {
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
} from "../lib/calendarFunctions";
import { useAllCalendars } from "../services/stores/SharedAccountData";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { isVersionV2 } from "../services/versionFunctions";
import { BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { OUTLOOK_SHOW_AS } from "../resources/outlookVariables";

export default function FreeBusyContainer({ event }) {
  const originalRecurrenceEventIndex = useSelector(
    (state) => state.originalRecurrenceEventIndex
  );

  const originalRecurringIndexRef = useRef(originalRecurrenceEventIndex);
  const allCalendars = useAllCalendars((state) => state.allCalendars);

  const [isRecurringEvent] = useState(getEventMasterEventID(event));

  const constructEventData = (transparency) => {
    const isOutOfOfficeEvent = transparency === OUT_OF_OFFICE_DURING_EVENT;
    const eventData = {
      user_calendar_id: getEventUserCalendarID(event),
      delete_conferencing: false,
      calendar_event: {
        show_as: isOutOfOfficeEvent ? OUT_OF_OFFICE_EVENT_TYPE : undefined,
        transparency: isOutOfOfficeEvent ? undefined : transparency,
        provider_id: getGoogleEventId(event),
      },
      user_event_id: getEventUserEventID(event),
    };

    return eventData;
  };

  const markAsFreeBusy = (transparency) => {
    if (isRecurringEvent) {
      // recurring event
      Broadcast.publish(
        "DISPLAY_RECURRING_TRANSPARENCY_MODAL",
        transparency,
        getOriginalRecurringEventFromIndex(
          event,
          originalRecurringIndexRef.current
        ),
        event
      );
      mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

      return;
    }

    const eventData = constructEventData(transparency);

    const path = "events";

    const params = {
      sendUpdates: DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars
      ),
      event_provider_id: getGoogleEventId(event),
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isVersionV2()) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
      eventData,
    });

    Broadcast.publish("SET_LAST_SELECTED_EVENT", event);

    const labelMessage = () => {
      if (transparency === OUTLOOK_SHOW_AS.TENTATIVE) {
        return "tentative";
      }
      if (transparency === OUT_OF_OFFICE_DURING_EVENT) {
        return "out of office";
      }
      if (transparency === BUSY_DURING_EVENT) {
        return "busy";
      }
      return "free";
    };

    const transparencyLabel = labelMessage();
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `Marking event as ${transparencyLabel}`
    );

    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  };
  useEffect(() => {
    originalRecurringIndexRef.current = originalRecurrenceEventIndex;
  }, [originalRecurrenceEventIndex]);

  useEffect(() => {
    Broadcast.subscribe(
      BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY,
      markAsFreeBusy
    );
    return () => {
      Broadcast.unsubscribe(BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
