import React, { useEffect } from "react";
import Mousetrap from "mousetrap";
import { LIGHT_MODE_AVAILABILITY } from "./sharedVariables";
import OnboardingSaveButton from "./onboardingSaveButton";
import { useSelector } from "react-redux";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
// import OnboardingLoomVideoContainer from "./onboardingLoomVideoContainer";

export default function AvailabilityGif(props) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  useEffect(() => {
    Mousetrap.bind(["A", "a", "enter"], function (e) {
      props.onClickNext();
    });
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });
    return () => {
      Mousetrap.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="onboarding-container onboarding-with-only-title-and-gif">
      <div className="section-title flex items-center">
        Slots - 
        <div className="font-weight-200-override">
          the most personal way to share your availabilities
        </div>
      </div>

      <img alt="" className="gif-tutorial mt-5" src={LIGHT_MODE_AVAILABILITY} />
      {/* <OnboardingLoomVideoContainer
        title={"availability"}
        inputClassname="mt-5"
        videoURL="https://www.loom.com/embed/fe73ba69b7c24572aa33fc4321dcf764"
      /> */}

      <OnboardingSaveButton
        buttonText="Continue"
        onClick={props.onClickNext}
      />
    </div>
  );
}
