import classNames from "classnames";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import settingsBroadcast from "../../broadcasts/settingsBroadcast";
import { showCustomizeUsername } from "../../lib/featureFlagFunctions";
import {
  APP_ELEMENT_ID,
  APP_SETTINGS,
  CONFERENCING_SETTINGS_ID,
} from "../../lib/vimcalVariables";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { SECOND_IN_MS } from "../../services/globalVariables";
import CalendarSettings from "./account/calendars/calendarSettings";
import ColorSetting from "./features/tags/colorSetting";
import ConferencingSettingsModal from "./account/conferencing/conferencingSettingsModal";
import ContactsGroupModal from "./features/contacts/contactsGroupModal";
import TeamPlansContainer from "../teamPlans/teamPlansContainer";
import BillingSettings from "./admin/billing/billingSettings";
import MenuBarSettings from "./features/upNext/menuBarSettings";
import Profile from "./account/profile/profile";
import UserPreferences from "./account/general/userPreferences";
import AvailabilitySettingsContainer from "./features/slots/availabilitySettingsContainer";
import { BACKEND_BROADCAST_VALUES, SETTINGS_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { isUserSSOAdmin } from "../../lib/userFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import SsoAdminSettingsContainer from "./admin/advanced/ssoAdminSettingsContainer";
import Confirmation from "./common/confirmation";
import SettingsPane from "./settingsPane";

const SECTION_LABEL_HEADER =
  "font-size-12 settings-section-label-header font-weight-400 mb-3";

/**
 * TODO: This is a hacky way to allow us to hide scrollbars.
 * Remove when Safari supports scrollbar-gutter.
 */
const OPTIONS_WIDTH = 135;

const SETTINGS_SCROLL_CONTAINER_START_ID = "settings-scroll-container-id";
const SCROLL_BEHAVIOR = { behavior: "smooth" } as const;

interface SettingsModalProps {
  initialConferencing?: ValueOf<typeof CONFERENCING_SETTINGS_ID>
  initialSetting?: ValueOf<typeof APP_SETTINGS>
  initialSettingsUser?: User
  initialSlotsSettingsUser?: User
  onClose: () => void
  scrollToSettingContent?: string
  skipBillingPromotion: boolean
}

export default function SettingsModal({
  initialSetting,
  onClose,
  initialConferencing,
  skipBillingPromotion,
  initialSettingsUser,
  initialSlotsSettingsUser,
  scrollToSettingContent,
}: SettingsModalProps) {
  const [selectedOption, setOption] = useState<ValueOf<typeof APP_SETTINGS>>(
    initialSetting ?? APP_SETTINGS.PREFERENCES,
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const componentIsMounted = useIsMounted();
  const focusRef = useRef<HTMLDivElement>(null);
  const [confirmationTriggerCount, updateConfirmationTriggerCount] = useReducer((x: number) => x + 1, 0);

  useEffect(() => {
    const elementID = document.getElementById(
      SETTINGS_SCROLL_CONTAINER_START_ID,
    );
    if (elementID) {
      elementID?.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (scrollToSettingContent) {
      const elementID = document.getElementById(
        scrollToSettingContent,
      );
      elementID?.scrollIntoView(SCROLL_BEHAVIOR);
    }

    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    settingsBroadcast.subscribe("SHOW_SETTINGS_CONFIRMATION", () => {
      setShowConfirmation(true);
      focusRef.current?.focus();
      updateConfirmationTriggerCount();
    });

    backendBroadcasts.publish("GET_LATEST_MASTER_ACCOUNT");
    settingsBroadcast.subscribe(SETTINGS_BROADCAST_VALUES.SET_SETTINGS_PAGE, (params: {
      option: ValueOf<typeof APP_SETTINGS>
      scrollToSection?: string
    }) => {
      const {
        option,
        scrollToSection,
      } = params;
      setOption(option);
      setTimeout(() => {
        if (!componentIsMounted.current) {
          return;
        }
        if (scrollToSection) {
          const elementID = document.getElementById(
            scrollToSection,
          );
          elementID?.scrollIntoView(SCROLL_BEHAVIOR);
        }
      }, 0.2 * SECOND_IN_MS);
    });

    return () => {
      settingsBroadcast.unsubscribe("SHOW_SETTINGS_CONFIRMATION");
      settingsBroadcast.unsubscribe(SETTINGS_BROADCAST_VALUES.SET_SETTINGS_PAGE);
    };
  }, []);

  const renderSetting = () => {
    switch (selectedOption) {
      case APP_SETTINGS.PROFILE:
        return <Profile />;
      case APP_SETTINGS.CALENDAR_SETTINGS:
        return <CalendarSettings onClose={onClose} />;
      case APP_SETTINGS.PAINTER_SETINGS:
        return <ColorSetting onClose={onClose} initialSettingsUser={initialSettingsUser} />;
      case APP_SETTINGS.CONFERENCING:
        return (
          <ConferencingSettingsModal
            initialConferencing={initialConferencing}
            initialSettingsUser={initialSettingsUser}
          />
        );
      case APP_SETTINGS.CONTACT_GROUPS:
        return <ContactsGroupModal />;
      case APP_SETTINGS.PREFERENCES:
        return <UserPreferences initialSettingsUser={initialSettingsUser} />;
      case APP_SETTINGS.MENU_BAR:
        return <MenuBarSettings />;
      case APP_SETTINGS.BILLING:
        return <BillingSettings skipBillingPromotion={skipBillingPromotion} />;
      case APP_SETTINGS.TEAM:
        return <TeamPlansContainer />;
      case APP_SETTINGS.SLOTS:
        return (
          <AvailabilitySettingsContainer
            initialUser={initialSlotsSettingsUser}
          />
        );
      case APP_SETTINGS.ADVANCED:
        return (
          <SsoAdminSettingsContainer />
        );
      default:
        return null;
    }
  };
  return (
    <div className="flex h-full pr-2" id={APP_ELEMENT_ID.SETTINGS_MODAL}>
      <SettingsPane
        title="Settings"
        contentProps={{
          className: "auto-hide-scrollbar",
          style: { width: OPTIONS_WIDTH + 20 },
        }}
      >
        <Options selectedOption={selectedOption} setOption={setOption} />
      </SettingsPane>
      <div
        className={classNames(
          "width-1px mr-6 ml-2 -my-10 flex-shrink-0",
          isDarkMode
            ? "background-color-modal-background-color"
            : "calendar-border-background-color",
        )}
      ></div>
      <SettingsPane
        className="flex-grow"
        contentProps={{ className: "settings-content-container" }}
        title={
          <div ref={focusRef} className="flex items-center">
            {getSettingsTitle(selectedOption)}
            <Confirmation
              shouldDisplay={showConfirmation}
              setShouldDisplay={setShowConfirmation}
              triggerCount={confirmationTriggerCount}
            />
          </div>
        }
      >
        <div id={SETTINGS_SCROLL_CONTAINER_START_ID}></div>
        {renderSetting()}
      </SettingsPane>
    </div>
  );
}

function getSettingsTitle(selectedOption: ValueOf<typeof APP_SETTINGS>) {
  switch (selectedOption) {
    case APP_SETTINGS.PROFILE:
      return "Profile";
    case APP_SETTINGS.CALENDAR_SETTINGS:
      return "My Calendar";
    case APP_SETTINGS.PAINTER_SETINGS:
      return "Tags";
    case APP_SETTINGS.CONFERENCING:
      return "Conferencing";
    case APP_SETTINGS.TIME_ZONE:
      return "Time Zone";
    case APP_SETTINGS.CONTACT_GROUPS:
      return "Contact Groups";
    case APP_SETTINGS.PREFERENCES:
      return "General";
    case APP_SETTINGS.MENU_BAR:
      return "Up Next";
    case APP_SETTINGS.BILLING:
      return "Billing";
    case APP_SETTINGS.TEAM:
      return "Team";
    case APP_SETTINGS.SLOTS:
      return "Slots";
    case APP_SETTINGS.ADVANCED:
      return "Advanced";
    default:
      return "";
  }
}

interface OptionLabelProps {
  option: ValueOf<typeof APP_SETTINGS>
  selectedOption: ValueOf<typeof APP_SETTINGS>
  setOption: StateSetter<ValueOf<typeof APP_SETTINGS>>
}

function OptionLabel({ option, selectedOption, setOption }: OptionLabelProps) {
  return (
    <div
      className={classNames(
        "font-size-14",
        "mb-3",
        "cursor-pointer",
        "font-weight-300",
        option === selectedOption
          ? "default-font-color"
          : "hoverable-secondary-text-color ",
        "select-none",
      )}
      onClick={() => setOption(option)}
    >
      {option}
    </div>
  );
}

interface OptionsProps {
  selectedOption: ValueOf<typeof APP_SETTINGS>
  setOption: StateSetter<ValueOf<typeof APP_SETTINGS>>
}

function Options({ selectedOption, setOption }: OptionsProps) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  return (
    <div className="flex flex-col" style={{ width: OPTIONS_WIDTH }}>
      <div className={SECTION_LABEL_HEADER}>Account</div>
      <OptionLabel
        option={APP_SETTINGS.PREFERENCES}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      {showCustomizeUsername() ? (
        <OptionLabel
          option={APP_SETTINGS.PROFILE}
          selectedOption={selectedOption}
          setOption={setOption}
        />
      ) : null}
      <OptionLabel
        option={APP_SETTINGS.CALENDAR_SETTINGS}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      <OptionLabel
        option={APP_SETTINGS.CONFERENCING}
        selectedOption={selectedOption}
        setOption={setOption}
      />

      <div className={classNames(SECTION_LABEL_HEADER, "mt-6")}>Features</div>
      <OptionLabel
        option={APP_SETTINGS.SLOTS}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      <OptionLabel
        option={APP_SETTINGS.PAINTER_SETINGS}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      <OptionLabel
        option={APP_SETTINGS.CONTACT_GROUPS}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      <OptionLabel
        option={APP_SETTINGS.MENU_BAR}
        selectedOption={selectedOption}
        setOption={setOption}
      />

      <div className={classNames(SECTION_LABEL_HEADER, "mt-6")}>Admin</div>
      <OptionLabel
        option={APP_SETTINGS.BILLING}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      <OptionLabel
        option={APP_SETTINGS.TEAM}
        selectedOption={selectedOption}
        setOption={setOption}
      />
      {isUserSSOAdmin(masterAccount) ? (
        <OptionLabel
          option={APP_SETTINGS.ADVANCED}
          selectedOption={selectedOption}
          setOption={setOption}
        />
      ) : null}
    </div>
  );
}
