import React, { Component } from "react";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import CommandCenter from "./commandCenter";
import { withRouter } from "react-router-dom";
import {
  PutCommaBetweenWordInString,
} from "../services/commonUsefulFunctions";
import { connect, batch } from "react-redux";
import _ from "underscore";
import {
  EVENT_TEMPLATE,
  TEXT_TEMPLATE,
} from "../services/googleCalendarService";
import Broadcast from "../broadcasts/broadcast";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { ACTION_MODE, SECOND_IN_MS, SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../services/globalVariables";
import {
  getTemplateTitle,
  isTextTemplate,
} from "../services/templateFunctions";
import { copyContent, isActionModeCreateAvailability, isActionModeUpsertEvent, isAppInTaskMode, isTemplateViewShowing } from "../services/appFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { truncateString } from "../lib/stringFunctions";
import { SLOTS_ELEMENT } from "../lib/vimcalVariables";
import { AVAILABILITY_BROADCAST_VALUES } from "../lib/broadcastValues";
import { immutablySortArray } from "../lib/arrayFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { getMasterAccountTemplates } from "../lib/userFunctions";

const TRUNCATE_STRING_LENGTH = 70;

class TemplateCommandCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.getOnlyTemplate = this.getOnlyTemplate.bind(this);
    this.onClickTemplate = this.onClickTemplate.bind(this);
    this.getTextAndTemplatesOnHome = this.getTextAndTemplatesOnHome.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  render() {
    if (!this.props.shouldShowCommandCenter) {
      return null;
    }

    const { actionMode } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;
    return (
      <CommandCenter
        title={this.props.isStickiesTemplate ? "Use Stickies" : "Use Template"}
        handleCloseModal={this.props.handleCloseModal}
        options={this.whichOptions()}
        isTemplateCommandCenter={true}
        skipCreateTemplate={
          this.props.isStickiesTemplate ||
          isAppInTaskMode({
            actionMode,
            reverseSlotsText,
          })
        }
      />
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //================
  // EVENT HANDLERS
  //================

  onClickTemplate(template) {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      this.onClickTemplateOnAvailability(template);
      return;
    }

    batch(() => {
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Event template applied"
      );
    });

    setTimeout(() => {
      // do not have to check for isMounted here since it's not setting state
      Broadcast.publish("EVENT_FORM_APPLY_TEMPLATE", {
        json: template,
      });
    }, 0.1 * SECOND_IN_MS);

    this.updateLastUsed(template);
  }

  onClickTemplateOnAvailability(template) {
    AvailabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.APPLY_EVENT_TEMPLATE_TO_SLOTS, template);
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Event template applied"
    );
  }

  onClickTextTemplate(template) {
    // TODO: POST TO SERVER 'TEMPLATES/TEMPLATE_ID/USED/'
    const templateText = template.text;
    copyContent(templateText);

    if (this.props.onClickCopyText) {
      this.props.onClickCopyText(templateText);
    }

    this.updateLastUsed(template);

    const isSlotsElement = Object.values(SLOTS_ELEMENT).includes(this.props.slotsElementForTemplate);
    if (isActionModeCreateAvailability(this.props.actionMode) && isSlotsElement) {
      switch (this.props.slotsElementForTemplate) {
        case SLOTS_ELEMENT.LOCATION:
          AvailabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_LOCATION, templateText);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Pasted into location: ${templateText}`
          );
          return;
        case SLOTS_ELEMENT.DESCRIPTION:
          AvailabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.PASTE_TEXT_INTO_DESCRIPTION, templateText);
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Pasted into description: ${templateText}`
          );
          return;
        default:
          return;
      }
    }

    if (isActionModeUpsertEvent(this.props.actionMode)) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Sticky has been applied"
      );
      isTemplateViewShowing()
        ? Broadcast.publish("PASTE_INTO_TEXT_TEMPLATE", templateText)
        : Broadcast.publish("EVENT_FORM_APPLY_TEXT_TEMPLATE", templateText);
      return;
    }
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `Copied to clipboard: ${templateText}`
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  showTemplates() {
    Broadcast.publish("TOGGLE_SHOW_TEMPLATE");
  }

  whichOptions() {
    const path = this.props.location.pathname;

    if (this.props.isStickiesTemplate) {
      return this.getOnlyText();
    } else if (this.props.onlyText) {
      return this.getOnlyText();
    } else if (isTemplateViewShowing()) {
      return this.getOnlyText();
    } else if (isActionModeUpsertEvent(this.props.actionMode)) {
      return this.getTextAndTemplates();
    } else if (isActionModeCreateAvailability(this.props.actionMode)) {
      return this.getTextAndTemplates();
    } else if (path.includes("/home/template")) {
      return this.getTextAndTemplates();
    } else {
      return this.getTextAndTemplatesOnHome();
    }
  }

  getTextAndTemplates() {
    let options = this.createTemplate();

    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (!isEmptyObjectOrFalsey(templates)) {
      this.sortStateTemplates().forEach((template, index) => {
        options.push({
          key: `id_${template.created_at}`,
          title: isTextTemplate(template)
            ? truncateString(
                this.getDescriptionOrTextOfTextTemplateOnEdit(template),
                TRUNCATE_STRING_LENGTH
              )
            : truncateString(
                this.getSummaryOrDescriptionOfEventTemplateOnEdit(template),
                TRUNCATE_STRING_LENGTH
              ),
          onClickHandler: this.templateAction(template),
          shortcut: isTextTemplate(template) ? "Text" : "Event",
          searchQueries: PutCommaBetweenWordInString(
            isTextTemplate(template)
              ? this.getDescriptionOrTextOfTextTemplate(template)
              : this.getSummaryOrDescriptionOfEventTemplate(template)
          ),
        });
      });
    }

    return options;
  }

  // Templates on home page
  getTextAndTemplatesOnHome() {
    let options = this.createTemplate();

    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (!isEmptyObjectOrFalsey(templates)) {
      this.sortStateTemplates().forEach((template, index) => {
        options.push({
          key: `templateHomeTemplate_${index}`,
          title: isTextTemplate(template)
            ? truncateString(
                this.getDescriptionOrTextOfTextTemplate(template),
                TRUNCATE_STRING_LENGTH
              )
            : truncateString(
                this.getSummaryOrDescriptionOfEventTemplate(template),
                TRUNCATE_STRING_LENGTH
              ),
          onClickHandler: this.templateActionOnHome(template),
          shortcut: isTextTemplate(template) ? "Text" : "Event",
          searchQueries: PutCommaBetweenWordInString(
            isTextTemplate(template)
              ? this.getDescriptionOrTextOfTextTemplate(template)
              : this.getSummaryOrDescriptionOfEventTemplate(template)
          ),
        });
      });
    }

    // Show template
    options.push({
      key: "ShowTemplatesTemplateCommandCenter",
      title: "Edit templates",
      onClickHandler: this.showTemplates,
      searchQueries: "show, edit, change, templates, update template",
    });

    return options;
  }

  getOnlyTemplate() {
    let options = this.createTemplate();

    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (!isEmptyObjectOrFalsey(templates)) {
      this.sortStateTemplates().forEach((template, index) => {
        if (!isTextTemplate(template)) {
          options.push({
            key: `id_${template.created_at}`,
            title: truncateString(
              this.getSummaryOrDescriptionOfEventTemplate(template),
              TRUNCATE_STRING_LENGTH
            ),
            onClickHandler: () => this.onClickTemplate(template),
            shortcut: "Event",
            searchQueries: PutCommaBetweenWordInString(
              this.getSummaryOrDescriptionOfEventTemplate(template)
            ),
          });
        }
      });
    }

    return options;
  }

  getOnlyText() {
    let options = this.createTemplate();

    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (!isEmptyObjectOrFalsey(templates)) {
      this.sortStateTemplates().forEach((template, index) => {
        if (isTextTemplate(template)) {
          options.push({
            key: `id_${template.created_at}`,
            title: truncateString(
              this.getDescriptionOrTextOfTextTemplate(template),
              TRUNCATE_STRING_LENGTH
            ),
            onClickHandler: () => this.onClickTextTemplate(template),
            shortcut: this.props.isStickiesTemplate ? null : "Text",
            searchQueries: PutCommaBetweenWordInString(
              this.getDescriptionOrTextOfTextTemplate(template)
            ),
          });
        }
      });
    }

    return options;
  }

  sortStateTemplates() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (isEmptyArrayOrFalsey(templates)) {
      return [];
    }
    const getTemplateLastUsed = (template) => {
      return template?.last_used_at || template?.updated_at || template?.created_at;
    };

    // sort in a non-mutable way
    const sortedArray = immutablySortArray(templates.slice(), (a, b) => getTemplateLastUsed(b).localeCompare(getTemplateLastUsed(a)));
    return sortedArray;
  }

  createTemplate() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    if (isEmptyObjectOrFalsey(templates)) {
      return [
        {
          key: "addCreateEventTemplate",
          title: "Create event template",
          onClickHandler: this.createAnEventTemplate.bind(this),
          searchQueries: "create, add, template, snippet",
        },
        {
          key: "addCreateTextTemplate",
          title: "Create a Sticky",
          onClickHandler: this.createTextTemplate.bind(this),
          searchQueries: "create, add, template, snippet, text",
        },
      ];
    } else {
      return [];
    }
  }

  createAnEventTemplate() {
    Broadcast.publish("CREATE_TEMPLATE", EVENT_TEMPLATE);
  }

  createTextTemplate() {
    Broadcast.publish("CREATE_TEMPLATE", TEXT_TEMPLATE);
  }

  templateAction(template) {
    if (isTextTemplate(template)) {
      return () => this.onClickTextTemplate(template);
    } else {
      return () => this.onClickTemplate(template, false);
    }
  }

  templateActionOnHome(template) {
    if (isTextTemplate(template)) {
      return () => this.onClickTextTemplate(template);
    } else {
      return () => this.onClickTemplate(template);
    }
  }

  getSummaryOrDescriptionOfEventTemplate(template) {
    const summary = getTemplateTitle(template);
    const title = template.description;

    let returnString = title === "" || !title ? summary : title;

    if (!returnString) {
      returnString = "No title";
    }

    return returnString;
  }

  getDescriptionOrTextOfTextTemplate(template) {
    let title = template.description;

    let returnString =
      title === "" || !title ? template.text : template.description;

    if (!returnString) {
      returnString = "No title";
    }

    const getTemplateAction = () => {
      if (this.props.isStickiesTemplate) {
        return "Paste to email";
      } else if (isActionModeUpsertEvent(this.props.actionMode)) {
        return "Paste Sticky";
      }

      return "Copy to clipboard";
    };

    // we might need to change this for availability panel
    return `${getTemplateAction()}: ` + returnString;
  }

  getSummaryOrDescriptionOfEventTemplateOnEdit(template) {
    const summary = getTemplateTitle(template);
    const title = template.description;

    let returnString = title === "" || !title ? summary : title;

    if (!returnString) {
      returnString = "No title";
    }

    return returnString;
  }

  updateLastUsed(template) {
    const path = `templates/${template.id}/used`;
    const url = constructRequestURLV2(path);

    Broadcast.publish("UPDATE_LAST_USED_TEMPLATE", { url });
  }

  getDescriptionOrTextOfTextTemplateOnEdit(template) {
    let title = template.description;

    let returnString =
      title === "" || !title ? template.text : template.description;

    if (!returnString) {
      returnString = "No title";
    }

    return "Paste to event: " + returnString;
  }
}

function mapStateToProps(state) {
  let { actionMode } = state;

  return {
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
    createTemplate: (data) => dispatch({ data: data, type: "CREATE_TEMPLATE" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const temporaryStateStore = useTemporaryStateStore();
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} temporaryStateStore={temporaryStateStore} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(TemplateCommandCenter)));
