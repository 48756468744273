import classNames from "classnames";
import React from "react";

interface ColoredLineProps {
  inputClassName?: string
  style?: React.CSSProperties
  width?: string | number
}

export default function ColoredLine({ inputClassName, style={}, width="100%" }: ColoredLineProps) {
  return (
    <hr
      className={classNames("dash-line", inputClassName)}
      style={Object.assign(
        {
          height: 1,
          width,
          border: 0,
        },
        style,
      )}
    />
  );
}
