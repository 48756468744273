import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import { SelectOptionType } from "../select";
import { useSelector } from "react-redux";
import { checkEmail } from "../queries/teamPlans";
import { handleError } from "../../services/commonUsefulFunctions";
import OpenSeatsRow from "./teamPlansModal/addSeatsSection/openSeatsRow";
import { type EmailPlanDictionary, VIMCAL_DEFAULT_PLAN, VIMCAL_EA_PLAN, PLAN_OPTIONS, groupEmailPlanDictionaryBySeat, getIsAddingSeats } from "./teamPlansModal/sharedFunctions";
import InviteeRow from "./teamPlansModal/addSeatsSection/inviteeRow";
import InviteInfoBox from "./teamPlansModal/addSeatsSection/inviteInfoBox";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../../lib/vimcalVariables";
import { getObjectEmail, removeKeyFromObject } from "../../lib/objectFunctions";
import { formatEmail, isValidEmail } from "../../lib/stringFunctions";
import { isErrorResponse } from "../../services/api";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { useIsFixedSeating, useOpenSeats } from "./hooks";
import { getExistingTeamMembers } from "./sharedFunctions";
import { useTeamPlan } from "../../services/stores/userData";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { FEATURE_TRACKING_CATEGORIES, trackEvent } from "../tracking";
import { isUserInFreeTrial } from "../../lib/stateManagementFunctions";

interface NewMemberInputProps {
  areEAsPresent: boolean
  defaultVimcalSeats?: number
  emailPlanDictionary: EmailPlanDictionary
  hasCheckedInitialEmail?: boolean
  initialEmailToCheck?: string
  setDefaultVimcalSeats?: StateSetter<number>
  setEmailPlanDictionary: StateSetter<EmailPlanDictionary>
  setHasCheckedInitialEmail?: StateSetter<boolean>
  setIsFetchingEligibility: React.Dispatch<React.SetStateAction<boolean>>
  setVimcalEASeats?: StateSetter<number>
  vimcalEASeats?: number
}

export default function NewMemberInput({
  areEAsPresent,
  defaultVimcalSeats=0,
  emailPlanDictionary,
  hasCheckedInitialEmail,
  initialEmailToCheck,
  setDefaultVimcalSeats,
  setEmailPlanDictionary,
  setHasCheckedInitialEmail,
  setIsFetchingEligibility,
  setVimcalEASeats,
  vimcalEASeats=0,
}: NewMemberInputProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const memberListRef = useRef<HTMLDivElement>(null);
  const isFixedSeating = useIsFixedSeating();
  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();
  const currentUser = useSelector((state) => state.currentUser);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const masterAccount = useMasterAccount(state => state.masterAccount);

  const isCurrentUserEA = isUserMaestroUser(masterAccount);
  const defaultSeatType = isCurrentUserEA ? VIMCAL_EA_PLAN.value : VIMCAL_DEFAULT_PLAN.value;
  const { vimcalEmails, vimcalEAEmails } = groupEmailPlanDictionaryBySeat(emailPlanDictionary);
  const existingMemberEmails = getExistingTeamMembers(teamPlan).map(u => getObjectEmail(u));
  // Users can have multiple invites sent.
  // For payment preview purposes, we only care about new users being added to the plan.
  const newVimcalEmails = vimcalEmails.filter(e => !existingMemberEmails.includes(e));
  const newVimcalEAEmails = vimcalEAEmails.filter(e => !existingMemberEmails.includes(e));
  const seatLimitReached = (
    isFixedSeating &&
    openVimcalSeats - newVimcalEmails.length <= 0 &&
    openVimcalEASeats - newVimcalEAEmails.length <= 0
  );
  const isAddingSeats = getIsAddingSeats({
    defaultVimcalSeats,
    newVimcalEAEmails,
    newVimcalEmails,
    openVimcalEASeats,
    openVimcalSeats,
    vimcalEASeats,
  });

  const checkEmailEligible = useCallback(
    (email: string) => {
      if (!currentUser) {
        return;
      }

      if (!isValidEmail(email)) {
        setErrorMessage("Please provide a valid email.");
        return;
      }

      setIsFetchingEligibility(true);

      checkEmail(currentUser, email)
        .then((response) => {
          setIsFetchingEligibility(false);

          if (email === initialEmailToCheck && setHasCheckedInitialEmail) {
            setHasCheckedInitialEmail(true);
          }

          if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
            /* Display error if backend fails */
            setErrorMessage(
              "Failed to check if user is eligible for team plan.",
            );
            return;
          }

          if (!response.is_user_eligible) {
            trackEvent({
              category: FEATURE_TRACKING_CATEGORIES.TEAM_PLAN,
              action: "invite_member",
              label: `ineligible_user_for_${isUserInFreeTrial(masterAccount) ? "trial" : "paid"}_plan`,
              userToken: getUserToken(currentUser),
            });
            setErrorMessage("This person is not eligible to be invited.");
            return;
          }

          setErrorMessage(undefined);
          const updatedEmailPlanDictionary = {
            ...emailPlanDictionary,
            [email]: { plan: response.seat_type ?? defaultSeatType, locked: Boolean(response.seat_type) },
          };
          setEmailPlanDictionary(updatedEmailPlanDictionary);
        })
        .catch(handleError);
    },
    [
      initialEmailToCheck,
      getUserEmail(currentUser),
      emailPlanDictionary,
    ],
  );

  useEffect(() => {
    if (initialEmailToCheck && !hasCheckedInitialEmail) {
      checkEmailEligible(initialEmailToCheck);
    }
  }, [hasCheckedInitialEmail, initialEmailToCheck]);

  const addMember = (email: string) => {
    const formattedEmail = formatEmail(email);
    if (formattedEmail in emailPlanDictionary) {
      return;
    }

    checkEmailEligible(formattedEmail);
    return;
  };

  const onChange = (email: string, option: typeof PLAN_OPTIONS[number] | null | undefined) => {
    if (!option) {
      return;
    }
    const originalOption = emailPlanDictionary[email];
    const updatedEmailPlanDictionary = {
      ...emailPlanDictionary,
      [email]: { ...originalOption, plan: option.value },
    };
    setEmailPlanDictionary(updatedEmailPlanDictionary);
  };

  const removeMember = (email: string) => {
    if (!(email in emailPlanDictionary)) {
      return;
    }

    setEmailPlanDictionary(removeKeyFromObject(emailPlanDictionary, email));
  };

  return (
    <>
      <ReactSelectAttendeeAutoComplete
        isDisabled={seatLimitReached}
        className="mb-2"
        shouldAutoFocus={true}
        addAttendees={(attendee: SelectOptionType) => addMember(attendee.value)}
        defaultText="Enter name or email"
        componentLocation={REACT_ATTENDEE_SELECT_LOCATION.TEAM_ADD_SEATS}
        hideMenuOnFocus={true}
        selectedGuests={[]}
        includeEmailAndName={true}
        isInModal={true}
      />

      {errorMessage ? (
        <div className="default-font-size mb-4 h-2" style={{ color: "#FF7373" }}>
          {errorMessage}
        </div>
      ) : null}

      <InviteInfoBox isAddingSeats={isAddingSeats} seatLimitReached={seatLimitReached} />

      <div ref={memberListRef} className="team-plan-new-member-list flex-grow mb-8 default-font-size">
        <OpenSeatsRow
          seats={defaultVimcalSeats}
          removeSeats={() => setDefaultVimcalSeats && setDefaultVimcalSeats(0)}
          plan={VIMCAL_DEFAULT_PLAN.label}
        />
        <OpenSeatsRow
          seats={vimcalEASeats}
          removeSeats={() => setVimcalEASeats && setVimcalEASeats(0)}
          plan={VIMCAL_EA_PLAN.label}
        />
        {Object.keys(emailPlanDictionary).map((email) => (
          <InviteeRow
            key={email}
            areEAsPresent={areEAsPresent}
            email={email}
            emailPlanDictionary={emailPlanDictionary}
            onChange={onChange}
            removeAttendee={removeMember}
            memberListRef={memberListRef}
          />
        ))}
      </div>
    </>
  );
}
