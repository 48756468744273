import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useDistroListDictionary } from "../../services/stores/eventsData";
import {
  convertEmailToName,
  FormatIntoJSDate,
  getTimeInAnchorTimeZone,
  isAfterMinute,
  isBeforeMinute,
  minDifferentBetweenTimeZones,
} from "../../services/commonUsefulFunctions";
import { addDefaultToArray } from "../../lib/arrayFunctions";
import {
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
} from "../../lib/calendarFunctions";
import {
  getEventEndJSDate,
  getEventStartJSDate,
  getEventUITitle,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
} from "../../services/eventResourceAccessors";
import { isMeetWithEvent } from "../../lib/meetWithFunctions";
import {
  getCalendarColorHex,
  getCalendarEmail,
  getCalendarUserCalendarID,
} from "../../services/calendarAccessors";
import { getUserEmail } from "../../lib/userFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { getObjectEmail } from "../../lib/objectFunctions";
import AttendeeSection from "./attendeeSection";
import SingleDay from "./singleDay";
import classNames from "classnames";
import SelectedRange, { DRAGGABLE_ID } from "./selectedRange";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  isSameMinute,
} from "date-fns";
import { isBusyEvent } from "../../lib/eventFunctions";
import { getEventsFromDB, isEventWithinJSWindow } from "../../lib/dbFunctions";
import { formatEventsList } from "../../lib/webWorkerFunctions";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import { pointerWithinWithOffset } from "../../lib/dndKitHelpers";
import {
  AttendeeGroup,
  getSchedulingAssistantWindow,
  isWithinWorkHours,
  HOUR_CELL_WIDTH,
  SchedulingAssistantItem,
  SCROLL_INTO_VIEW_OPTIONS,
  SMALL_INTERNAL_NUMBER_OF_ATTENDEES,
  SCHEDULING_ASSISTANT_COLORS,
} from "./helperFunctions";
import { BLUE_BUTTON, EVENT_CONTAINER_WIDTH, SECOND_IN_MS } from "../../services/globalVariables";
import DateTimeInput from "./dateTimeInput";
import { SCHEDULING_ASSISTANT_ID, SCHEDULING_ASSISTANT_PREVIEW_EVENT_ID } from "../../services/elementIDVariables";
import CustomButtonV2 from "../buttons/customButtonV2";
import schedulingAssistantBroadcast from "../../broadcasts/schedulingAssistantBroadcast";
import { SCHEDULING_ASSISTANT_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { useScrollDetection } from "../../services/customHooks/useScrollDetection";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { getDefaultUserTimeZone, getWorkHours } from "../../lib/settingsFunctions";
import EventExpandedView from "../../views/eventExpandedView";
import { isSameEmail } from "../../lib/stringFunctions";

const DATE_KEY_FORMAT = "PPP";
const SCROLL_CONTAINER_ID = "scheduling-assistant-scrollable-content";
interface SchedulingAssistantContainerProps {
  eventStart: Date;
  eventEnd: Date;
  attendees: Attendee[];
  selectedUser: User;
  calendar: VimcalCalendar;
  addAttendees?: (args: {
    email: string;
    userEmail: string;
  }) => void;
  setEventStartDate: (newStartDate: Date, notifyChange?: boolean) => void;
  setEventStartTime: (
    time: Date,
    notifyChange?: boolean,
    endTime?: Date
  ) => void;
  setEventEndTime: (time: Date, notifyChange?: boolean) => void;
  setEventDateAndTime: (options: { startTime?: Date; endTime?: Date }) => void;
  startTimeZone: string;
  endTimeZone: string;
  buttonCopy?: string;
  onSubmit?: ({startTime, endTime}: {startTime: Date, endTime: Date}) => void;
  onClose: () => void;
}

/**
 * TODO: Consider building a context for this component. This is a lot of logic in just a single
 * component, and a lot of it needs to be passed down through multiple layers to be used in child components.
 */
export default function SchedulingAssistantContainer({
  eventStart,
  eventEnd,
  attendees,
  selectedUser,
  calendar,
  addAttendees,
  setEventStartDate,
  setEventStartTime,
  setEventEndTime,
  setEventDateAndTime,
  startTimeZone,
  endTimeZone,
  buttonCopy,
  onSubmit,
  onClose,
}: SchedulingAssistantContainerProps) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const user = selectedUser || currentUser;

  // Need to convert the user's work hours into the event's time zone.
  const workHours = (() => {
    const baseWorkHours = getWorkHours({ masterAccount, user });
    const defaultTimeZone = getDefaultUserTimeZone({ masterAccount, user });
    if (startTimeZone === defaultTimeZone) {
      return baseWorkHours;
    }

    // TODO: Test that this works as intended for time zone conversions not on the hour.
    const minutesBetweenTimeZones = minDifferentBetweenTimeZones(defaultTimeZone, startTimeZone);
    const adjustedWorkHours = {
      startWorkHour: baseWorkHours.startWorkHour - Math.ceil(minutesBetweenTimeZones / 60),
      endWorkHour: baseWorkHours.endWorkHour - Math.floor(minutesBetweenTimeZones / 60),
    };

    if (adjustedWorkHours.startWorkHour < 0 || adjustedWorkHours.endWorkHour > 24) {
      return {
        startWorkHour: 0,
        endWorkHour: 24,
      };
    }

    return adjustedWorkHours;
  })();

  const componentIsMounted = useIsMounted();
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState(false);
  const [previewEvent, setPreviewEvent] = useState<{event: BigCalendarEvent, clickLocation: {X: number, Y: number}} | null>(null);
  const eventStartRef = useRef(eventStart);
  const eventEndRef = useRef(eventEnd);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const distroListDictionary = useDistroListDictionary(
    (state) => state.distroListDictionary,
  );
  const { isTop, isBottom } =
    useScrollDetection(SCROLL_CONTAINER_ID);
  // Use these two states as a middle-man between the actual pending event and the draggable
  // region. Update these states while dragging, and only commit to the parent state once dragging stops.
  const [selectedEventStart, setSelectedEventStart] = useState(eventStart);
  const [selectedEventEnd, setSelectedEventEnd] = useState(eventEnd);
  const [priorityAttendees, setPriorityAttendees] = useState<Set<string>>(new Set());
  const [dateItemIndex, setDateItemIndex] = useState<
    Record<string, SchedulingAssistantItem[]>
  >({});
  const eventsRef = useRef<BigCalendarEvent[]>([]);
  const [isShowingWorkHoursOnly, setIsShowingWorkHoursOnly] = useState(() => {
    return isWithinWorkHours(eventStart, workHours) && isWithinWorkHours(eventEnd, workHours);
  });

  const [schedulingAssistantWindow, setSchedulingAssistantWindow] = useState(
    () => {
      return getSchedulingAssistantWindow(eventStart);
    },
  );
  const schedulingAssistantWindowRef = useRef(schedulingAssistantWindow);

  const selectedRangeRef = useRef<HTMLDivElement | null>(null);
  const onTogglePriority = ({ email }: { email: string }) => {
    setPriorityAttendees((prev) => {
      const newSet = new Set(prev);
      if (prev.has(email)) {
        newSet.delete(email);
      } else {
        newSet.add(email);
      }
      return newSet;
    });
  };

  useEffect(() => {
    selectedRangeRef.current?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS);
  }, [isShowingWorkHoursOnly]);

  useEffect(() => {
    if (!isSameMinute(eventStart, selectedEventStart)) {
      setSelectedEventStart(eventStart);
    }
  }, [eventStart]);

  useEffect(() => {
    if (!isSameMinute(eventEnd, selectedEventEnd)) {
      setSelectedEventEnd(eventEnd);
    }
  }, [eventEnd]);

  useEffect(() => {
    let hasEventStartOrEndChanged = false;
    if (
      !isSameMinute(eventStart, eventStartRef.current) ||
      !isSameMinute(eventEnd, eventEndRef.current)
    ) {
      eventStartRef.current = eventStart;
      eventEndRef.current = eventEnd;
      hasEventStartOrEndChanged = true;
    }

    if (
      hasEventStartOrEndChanged &&
      (isBeforeMinute(
        eventStartRef.current,
        schedulingAssistantWindowRef.current.windowStart,
      ) ||
        isAfterMinute(
          eventStartRef.current,
          schedulingAssistantWindowRef.current.windowEnd,
        ))
    ) {
      const updatedWindow = getSchedulingAssistantWindow(eventStart);
      setSchedulingAssistantWindow(updatedWindow);
      schedulingAssistantWindowRef.current = updatedWindow;
      fetchInitialEvents(updatedWindow);
    }
  }, [eventStart, eventEnd]);

  useEffect(() => {
    if (hasInitiallyScrolled && (isTop || isBottom)) {
      const getDate = () => {
        if (isTop) {
          return days[0];
        }
        return days[days.length - 1];
      };
      const date = getDate();
      mainCalendarBroadcast.publish("JUMP_TO_DATE_AND_SCROLL_TO_TIME", date); // set selectedDay
      const updatedWindow = getSchedulingAssistantWindow(date);
      setSchedulingAssistantWindow(updatedWindow);
      schedulingAssistantWindowRef.current = updatedWindow;

      const scrollContainer = document.getElementById(SCROLL_CONTAINER_ID);
      if (scrollContainer) {
        const hoursInDay = isShowingWorkHoursOnly
          ? workHours.endWorkHour - workHours.startWorkHour
          : 24;
        const dayWidth = HOUR_CELL_WIDTH * hoursInDay;
        let left: number;
        if (isTop) {
          // We add 4 days to the start, so offset by about 4 days.
          left = dayWidth * 4 - HOUR_CELL_WIDTH;
        } else {
          // We add 10 days to the end, so offset by about 10 days.
          left = scrollContainer.scrollWidth - dayWidth * 10 - HOUR_CELL_WIDTH;
        }
        scrollContainer.scrollTo({ left });
      }

      fetchInitialEvents(updatedWindow);
    }
  }, [isTop, isBottom, hasInitiallyScrolled]);

  const dragOffsetRef = useRef<{ x: number; y: number } | null>(null);

  const days = useMemo(() => {
    return eachDayOfInterval({
      start: schedulingAssistantWindow.windowStart,
      end: schedulingAssistantWindow.windowEnd,
    });
  }, [schedulingAssistantWindow]);

  const meetWithEvents = useTemporaryStateStore(
    (state) => state.meetWithEvents,
  );
  const eventFormEvents = useTemporaryStateStore(
    (state) => state.eventFormEvents,
  );

  const calendarColor: string = getCalendarColorHex(calendar);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
  );

  const onSelectTime = (time: Date) => {
    const duration = (() => {
      if (!selectedEventEnd || !selectedEventStart) {
        return 30;
      }

      return differenceInMinutes(selectedEventEnd, selectedEventStart);
    })();
    setEventDateAndTime({
      startTime: time,
      endTime: addMinutes(time, duration),
    });
  };

  const getDisplayName = (email: string) => {
    return convertEmailToName({
      email,
      currentUser,
      emailToNameIndex,
      masterAccount,
      allLoggedInUsers,
      allCalendars,
      distroListDictionary,
    });
  };

  // Build the attendee groups, placing the current user at the top.
  const groups: AttendeeGroup[] = (() => {
    // use userEmail as backup but should never get to that
    const calendarEmail = getCalendarEmail(calendar) || getUserEmail(user);
    const userGroup = {
      id: calendarEmail,
      title: getDisplayName(calendarEmail),
    };
    if (isEmptyArrayOrFalsey(attendees)) {
      return [userGroup];
    }
    const attendeesGroup = attendees
      .filter(
        (attendee) => !isSameEmail(getObjectEmail(attendee), calendarEmail),
      )
      .map((attendee) => {
        const email = getObjectEmail(attendee);
        return { id: email ?? "", title: getDisplayName(email ?? "") };
      });
    return [userGroup, ...attendeesGroup];
  })();

  const parseEventsAndCreateGroups = (events: BigCalendarEvent[]) => {
    const getGroupID = (event: BigCalendarEvent) => {
      const matchingCalendar = getCalendarFromUserCalendarID({
        userCalendarID: getEventUserCalendarID(event),
        allCalendars,
      });
      return isMeetWithEvent(event)
        ? getEventUserEmail(event)
        : getCalendarEmail(matchingCalendar);
    };
    const groupIDs = new Set(groups.map((group) => group.id));

    const filteredEvents = addDefaultToArray(events).filter((event) => {
      return isBusyEvent(event) && groupIDs.has(getGroupID(event));
    });
    eventsRef.current = filteredEvents;

    // update date index below
    const updatedIndex: Record<string, SchedulingAssistantItem[]> = {};
    const items = filteredEvents.map((event) => {
      const formattedEvent = FormatIntoJSDate(event, startTimeZone);
      return {
        id: getEventUserEventID(formattedEvent),
        groupID: getGroupID(formattedEvent),
        title: getEventUITitle(formattedEvent),
        event: formattedEvent,
      };
    });

    items.forEach((item) => {
      const { event } = item;
      const eventDates = eachDayOfInterval({
        start: getEventStartJSDate(event),
        end: getEventEndJSDate(event),
      });
      for (const eventDate of eventDates) {
        const formattedDate = format(eventDate, DATE_KEY_FORMAT);
        updatedIndex[formattedDate] = [...(updatedIndex[formattedDate] || []), item];
      }
    });
    setDateItemIndex(updatedIndex);
  };

  const fetchInitialEvents = async (
    inputWindow: { windowStart: Date; windowEnd: Date } | null = null,
  ) => {
    const { windowStart, windowEnd } = inputWindow || schedulingAssistantWindow;
    const resultsFromDB = await getEventsFromDB({
      userEmail: getCalendarUserEmail(calendar),
      windowStart,
      windowEnd,
      userCalendarIDs: [getCalendarUserCalendarID(calendar)],
    });
    const dbEvents =
      resultsFromDB?.map((dbItem) => {
        const { event } = dbItem;
        return event;
      }) ?? [];
    const existingEventsUserEventIDs = new Set(
      eventsRef.current.map((event) => getEventUserEventID(event)),
    );
    const filteredEvents = dbEvents.filter(
      (event) => !existingEventsUserEventIDs.has(getEventUserEventID(event)),
    );
    const formattedEvents = formatEventsList({
      calendarEvents: filteredEvents,
      currentTimeZone: startTimeZone,
      currentUserEmail: getUserEmail(user),
      showDeclinedEvents: false,
      allCalendars,
    }).events;
    const filteredCurrentSetOfEvents = eventsRef.current.filter((event) =>
      isEventWithinJSWindow({
        event,
        windowStart,
        windowEnd,
      }),
    );
    const updatedEvents = [...filteredCurrentSetOfEvents, ...formattedEvents];

    parseEventsAndCreateGroups([
      ...addDefaultToArray(meetWithEvents),
      ...addDefaultToArray(eventFormEvents),
      ...updatedEvents,
    ]);
  };

  const addEventsIntoSchedulingAssistant = (events: BigCalendarEvent[]) => {
    if (isEmptyArrayOrFalsey(events)) {
      return;
    }
    const inputEventsUserEventIDs = new Set(
      events.map((event) => getEventUserEventID(event)),
    );
    const filterOutMeetWithEvents = eventsRef.current.filter(
      (event) =>
        !isMeetWithEvent(event) &&
        !inputEventsUserEventIDs.has(getEventUserEventID(event)), // so we don't add duplicate events
    );
    const { windowStart, windowEnd } = schedulingAssistantWindowRef.current;
    const updatedEvents = [...events, ...filterOutMeetWithEvents].filter(
      (event) =>
        isEventWithinJSWindow({
          event,
          windowStart,
          windowEnd,
        }),
    );
    parseEventsAndCreateGroups([
      ...addDefaultToArray(meetWithEvents),
      ...addDefaultToArray(eventFormEvents),
      ...updatedEvents,
    ]);
  };

  useEffect(() => {
    // TODO: test 4 day view, and 7 day view
    const filterOutMeetWithEvents = eventsRef.current.filter(
      (event) => !isMeetWithEvent(event),
    );
    parseEventsAndCreateGroups([
      ...addDefaultToArray(meetWithEvents),
      ...filterOutMeetWithEvents,
      ...addDefaultToArray(eventFormEvents),
    ]);
  }, [meetWithEvents, eventFormEvents]);

  useEffect(() => {
    setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      setHasInitiallyScrolled(true);
    }, 0.5 * SECOND_IN_MS);

    fetchInitialEvents();
    schedulingAssistantBroadcast.subscribe(
      SCHEDULING_ASSISTANT_BROADCAST_VALUES.ADD_EVENTS,
      (newEvents) => addEventsIntoSchedulingAssistant(newEvents),
    );
    schedulingAssistantBroadcast.subscribe(
      SCHEDULING_ASSISTANT_BROADCAST_VALUES.SET_PREVIEW_EVENT,
      (newEvent) => setPreviewEvent(newEvent),
    );
    return () => {
      schedulingAssistantBroadcast.unsubscribe(
        SCHEDULING_ASSISTANT_BROADCAST_VALUES.ADD_EVENTS,
      );
      schedulingAssistantBroadcast.unsubscribe(
        SCHEDULING_ASSISTANT_BROADCAST_VALUES.SET_PREVIEW_EVENT,
      );
    };
  }, []);

  const parseDragEvent = (event: DragEndEvent | DragOverEvent) => {
    const activeId = event?.active?.id;
    const fixedDatetime: Date | undefined =
      event?.active?.data?.current?.fixedEdgeTimestamp;
    const targetDatetime: Date | undefined =
      event?.over?.data?.current?.datetime;
    return { activeId, fixedDatetime, targetDatetime };
  };

  const onDragEnd = (_event: DragEndEvent) => {
    setEventDateAndTime({
      startTime: selectedEventStart,
      endTime: selectedEventEnd,
    });
  };

  const onDragOver = (event: DragOverEvent) => {
    const { activeId, fixedDatetime, targetDatetime } = parseDragEvent(event);
    if (!activeId || !targetDatetime) {
      return;
    }

    if (activeId === DRAGGABLE_ID) {
      // The selected range as a whole is being moved. The duration is fixed.
      const duration = differenceInMinutes(
        selectedEventEnd,
        selectedEventStart,
      );
      setSelectedEventStart(targetDatetime);
      setSelectedEventEnd(addMinutes(targetDatetime, duration));
    } else {
      // One of the edges of the selected range is being moved. The opposite timestamp is fixed.
      if (!fixedDatetime) {
        return;
      }

      // The user can drag the start past the end and vice versa.
      if (targetDatetime > fixedDatetime) {
        setSelectedEventStart(fixedDatetime);
        const newEventEnd = getTimeInAnchorTimeZone(targetDatetime, startTimeZone, endTimeZone);
        setSelectedEventEnd(newEventEnd);
      } else {
        setSelectedEventStart(targetDatetime);
        const newEventEnd = getTimeInAnchorTimeZone(fixedDatetime, startTimeZone, endTimeZone);
        setSelectedEventEnd(newEventEnd);
      }
    }
  };

  const wrapDateTimeInputSetter = (stateSetter: (date: Date) => void) => {
    return (date: Date) => {
      stateSetter(date);
      window.setTimeout(() => {
        selectedRangeRef.current?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS);
      }, 0.1 * SECOND_IN_MS);
    };
  };

  const renderLegend = () => {
    return (
      <div className="flex items-start justify-end gap-6 mt-4">
        <div className="flex items-center gap-2">
          <div
            className="scheduling-assistant-legend-box flex items-center justify-center"
            style={{
              backgroundColor: SCHEDULING_ASSISTANT_COLORS.DEFAULT_BACKGROUND_COLOR,
            }}
          >
          </div>
          <div className="default-font-size flex flex-col gap-1">
            <div className="">Meeting</div>
          </div>
        </div>

        <div className="flex items-start gap-2">
          <div
            className="scheduling-assistant-legend-box flex items-center justify-center"
            style={{
              backgroundColor: isDarkMode ? SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_BACKGROUND_COLOR_DARK_MODE : SCHEDULING_ASSISTANT_COLORS.INTERNAL_EVENT_BACKGROUND_COLOR_LIGHT_MODE,
            }}
          >
          </div>
          <div className="default-font-size flex flex-col gap-1">
            <div className="">Internal meetings with</div>
            <div>{`${SMALL_INTERNAL_NUMBER_OF_ATTENDEES} or fewer attendees`}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderPreviewEvent = () => {
    if (!previewEvent) {
      return null;
    }
    const getLeft = () => {
      const left = previewEvent.clickLocation.X;
      if ((left + 20 + EVENT_CONTAINER_WIDTH) > (window.innerWidth - 70)) {
        return left - (EVENT_CONTAINER_WIDTH + 64);
      }
      return left;
    };
    return (
      <div>
        <div
          onClick={() => setPreviewEvent(null)}
          className="layout-over-lay"
          style={{
            zIndex: 3,
            backgroundColor: "transparent",
          }}
        ></div>
        <div
          className="absolute top-2 z-index-4 soft-border scheduling-assistant-preview-event-pop-up rounded-md"
          style={{
            width: EVENT_CONTAINER_WIDTH,
            left: getLeft(),
          }}
        >
          <EventExpandedView
            // @ts-ignore
            event={previewEvent.event}
            id={SCHEDULING_ASSISTANT_PREVIEW_EVENT_ID}
            isPopUpEvent={true}
            isEdit={false}
            onClose={() => {
              setPreviewEvent(null);
            }}
            hideShortcutsTiles={true}
            shouldShowResponseBar={false}
            ignoreOverflow={true}
            hideAllEdits={true}
          />
        </div>
      </div>

    );
  };

  const renderBottomButton = () => {
    if (onSubmit) {
      return (
        <SaveAndCancelButton
          onClose={onClose}
          onConfirm={() => {
            if (isStartTimeAfterEndTime()) {
              return;
            }
            onSubmit({startTime: selectedEventStart, endTime: selectedEventEnd});
          }}
          confirmButtonText={buttonCopy || "OK"}
        />
      );
    }
    return (
      <div className="flex justify-end">
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          className=""
          onClick={onClose}
          label={buttonCopy || "OK"}
        />
      </div>
    );
  };

  const isStartTimeAfterEndTime = () => {
    try {
      return isAfterMinute(selectedEventStart, selectedEventEnd);
    } catch {
      return false;
    }
  };

  const showEndTimeBeforeStartTimeWarning = () => {
    if (!isStartTimeAfterEndTime()) {
      return null;
    }
    return (
      <div className="warning-color default-font-size mb-2">
        End time must be after start time.
      </div>
    );
  };

  return (
    <div id={SCHEDULING_ASSISTANT_ID}>
      <DateTimeInput
        eventStart={eventStart}
        eventEnd={eventEnd}
        setEventStartDate={wrapDateTimeInputSetter(setEventStartDate)}
        setEventStartTime={wrapDateTimeInputSetter(setEventStartTime)}
        setEventEndTime={wrapDateTimeInputSetter(setEventEndTime)}
        startTimeZone={startTimeZone}
        endTimeZone={endTimeZone}
        isShowingWorkHoursOnly={isShowingWorkHoursOnly}
        setIsShowingWorkHoursOnly={setIsShowingWorkHoursOnly}
        workHours={workHours}
        isShowingWarningUnderneath={isStartTimeAfterEndTime()}
      />
      {showEndTimeBeforeStartTimeWarning()}
      <div
        className={classNames(
          "scheduling-assistant-wrapper",
          "rounded-md",
          "flex",
          "default-border-top default-border-left",
          "w-full",
          "default-border-bottom",
        )}
      >
        <AttendeeSection
          groups={groups}
          attendees={attendees}
          addAttendees={addAttendees}
          priorityAttendees={priorityAttendees}
          onTogglePriority={onTogglePriority}
        />
        <div
          id={SCROLL_CONTAINER_ID}
          className="flex items-start overflow-y-auto default-border-right rounded-md relative scheduling-assistant-scroll-container"
        >
          <DndContext
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            sensors={sensors}
            collisionDetection={(args) =>
              pointerWithinWithOffset(args, dragOffsetRef.current)
            }
          >
            {days.map((day) => (
              <SingleDay
                key={format(day, "yyyy-MM-dd")}
                date={day}
                groups={groups}
                setTime={onSelectTime}
                items={addDefaultToArray(
                  dateItemIndex[format(day, DATE_KEY_FORMAT)],
                )}
                isShowingWorkHoursOnly={isShowingWorkHoursOnly}
                calendarColor={calendarColor}
                workHours={workHours}
                priorityAttendees={priorityAttendees}
                user={user}
              />
            ))}
            <SelectedRange
              dragOffsetRef={dragOffsetRef}
              endTimeZone={endTimeZone}
              isShowingWorkHoursOnly={isShowingWorkHoursOnly}
              selectedEndTime={selectedEventEnd}
              selectedStartTime={selectedEventStart}
              selectedRangeRef={selectedRangeRef}
              startTimeZone={startTimeZone}
              windowEndDate={days[days.length - 1]}
              windowStartDate={days[0]}
              workHours={workHours}
            />
          </DndContext>
        </div>
      </div>
      {renderLegend()}

      {addAttendees ? <div className="h-36"></div> : null}
      {renderBottomButton()}
      {renderPreviewEvent()}
    </div>
  );
}
