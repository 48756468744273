import React from "react";
import ContactHandle, { type ContactHandleProps } from "../contactHandle";
import XLogo from "./xLogo";

export default function TwitterHandle(props: ContactHandleProps) {
  return (
    <ContactHandle {...props}>
      <XLogo size={9} className="full-attendee-list-attendee-handle" />
    </ContactHandle>
  )
}
