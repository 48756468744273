import React from "react";
import { X } from "react-feather";
import Modal, { type Styles } from "react-modal";
import { isFreeTrialOver } from "../../../lib/stateManagementFunctions";
import { useSubscriptionStore } from "../../../services/stores/finance";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import TrialFinished from "./trialFinished";
import { TRIAL_IS_OVER_MODAL_ID } from "../../../services/elementIDVariables";
import { MODAL_BORDER_RADIUS } from "../../../services/globalVariables";
import { MODAL_CONTENT_BOX_SHADOW, MODAL_OVERLAY_Z_INDEXES } from "../../../lib/modalFunctions";

const MODAL_STYLE: Styles = {
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.70)",
    zIndex: MODAL_OVERLAY_Z_INDEXES.PAYWALL,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderColor: "transparent",
    boxShadow: MODAL_CONTENT_BOX_SHADOW,
    zIndex: 5,
    backgroundColor: "#240E60",
    color: "white",
    width: "min-content",
    borderRadius: MODAL_BORDER_RADIUS,
    maxHeight: "90vh",
    overflowY: "auto",
  },
};

interface TrialExpiredModalProps {
  onRequestClose: () => void
}

export default function TrialExpiredModal({ onRequestClose }: TrialExpiredModalProps) {
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const subscription = useSubscriptionStore(state => state.subscription);
  const freeTrialIsOver = isFreeTrialOver(masterAccount, subscription);

  return (
    <Modal
      isOpen={true}
      style={MODAL_STYLE}
      onRequestClose={() => {
        if (freeTrialIsOver) {
          return;
        }
        onRequestClose();
      }}
      ariaHideApp={false}
      id={TRIAL_IS_OVER_MODAL_ID}
      className="relative"
    >
      <div className="relative">
        {freeTrialIsOver ? null : (
          <X className="absolute right-4 top-4 cursor-pointer text-gray-400 hover:text-white" onClick={onRequestClose}/>
        )}
        <TrialFinished isInModal={true} freeTrialIsOver={freeTrialIsOver} />
      </div>
    </Modal>
  );
}
