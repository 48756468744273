import React, { PureComponent } from "react";
import Classnames from "classnames";
import {
  createAbbreviationForTimeZone,
  isElectron,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import { connect } from "react-redux";
import { DESKTOP_TITLE_BAR_HEIGHT } from "../services/globalVariables";
import { isGroupVoteDetailPageOpen } from "../services/appFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { isEmptyArray } from "../lib/arrayFunctions";
import { isFindTimeEventFormEvent } from "../lib/temporaryEventFunctions";
import broadcast from "../broadcasts/broadcast";
import { useAppTimeZones } from "../services/stores/appFunctionality";
import { getMostLeftHandTimeZone } from "../lib/stateManagementFunctions";
import { getDefaultUserTimeZone } from "../lib/settingsFunctions";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";
import ShortcutTile from "./shortcutTiles/shortcutTile";

class LayoutTopBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isElectron: isElectron(),
    };

    this.returnToDefaultTimeZone = this.returnToDefaultTimeZone.bind(this);
    this.removeAllSearchedCalendars = this.removeAllSearchedCalendars.bind(this);
  }

  render() {
    return <div>{this.determineText()}</div>;
  }

  getSelectedDay() {
    return getSelectedDayWithBackup(this.props.selectedDay);
  }

  determineText() {
    const {
      temporaryTimeZones,
      eventFormEmails,
      message,
      currentTimeZone,
      currentTimeZoneLabel,
    } = this.props;
    const defaultTimeZone = this.getDefaultTimeZone();
    if (message) {
      return <div>{message}</div>;
    } else if (
      currentTimeZone &&
      currentTimeZone !== defaultTimeZone &&
      eventFormEmails?.length > 0
    ) {
      return this.renderCombinedMessage();
    } else if (eventFormEmails?.length > 0 && temporaryTimeZones?.length > 0) {
      return this.renderCombinedMessage();
    } else if (
      currentTimeZone &&
      currentTimeZone !== defaultTimeZone
    ) {
      if (isGroupVoteDetailPageOpen()) {
        return this.renderTimeTravelWithoutESC();
      }
      return this.renderTimeTravel();
    } else if (
      eventFormEmails?.length > 0
    ) {
      return this.renderContactBar();
    } else if (currentTimeZoneLabel) {
      return this.renderSecondaryTimeZone();
    }
  }

  renderCombinedMessage() {
    return (
      <div
        id="layout-top-flag-bar"
        className="layout-top-flag-bar"
        style={{ marginTop: this.determineMarginTop() }}
      >
        <span className="ml-1">Press</span>

        <span className={Classnames("display-flex-center cursor-pointer")} onClick={this.removeAllSearchedCalendars}>
          {this.renderEscShortcut()}
        </span>

        <span className="ml-1">to</span>

        <span
          onClick={this.removeAllSearchedCalendars}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "my-0.5",
          )}
        >
          remove all searched calendars
        </span>

        <span>.</span>

        <span className="ml-1"> Press </span>

        <span className={Classnames("display-flex-center cursor-pointer")} onClick={this.returnToDefaultTimeZone}>
          {this.renderEscShortcut()}
        </span>

        <span className="ml-1">again to</span>

        <span
          onClick={this.returnToDefaultTimeZone}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "my-0.5",
          )}
        >
          return to{" "}
          {createAbbreviationForTimeZone(this.getDefaultTimeZone(), this.getSelectedDay())}
        </span>

        <span>.</span>
      </div>
    );
  }

  renderTimeTravelWithoutESC() {
    return (
      <div
        className="layout-top-flag-bar"
        style={{ marginTop: this.determineMarginTop() }}
      >
        Your calendar is currently displayed in{" "}
        {createAbbreviationForTimeZone(this.props.currentTimeZone, this.getSelectedDay())}.
        <span
          onClick={this.returnToDefaultTimeZone}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "margin-bottom-two",
          )}
          style={{ marginTop: 2 }}
        >
          Return to{" "}
          {createAbbreviationForTimeZone(this.getDefaultTimeZone(), this.getSelectedDay())}
        </span>
      </div>
    );
  }

  renderTimeTravel() {
    return (
      <div
        className="layout-top-flag-bar"
        style={{ marginTop: this.determineMarginTop() }}
      >
        Your calendar is currently displayed in{" "}
        {createAbbreviationForTimeZone(this.props.currentTimeZone, this.getSelectedDay())}.
        <span className="ml-1">Press </span>
        <span className={Classnames("cursor-pointer display-flex-center")} onClick={this.returnToDefaultTimeZone}>
          {this.renderEscShortcut()}
        </span>
        <span className="ml-1">to</span>
        <span
          onClick={this.returnToDefaultTimeZone}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "my-0.5",
          )}
        >
          return to{" "}
          {createAbbreviationForTimeZone(this.getDefaultTimeZone(), this.getSelectedDay())}
        </span>
        <span>.</span>
      </div>
    );
  }

  renderSecondaryTimeZone() {
    return (
      <div
        className="layout-top-flag-bar"
        style={{ marginTop: this.determineMarginTop() }}
      >
        <span className="ml-1">Press</span>

        <span className={Classnames("cursor-pointer display-flex-center")} onClick={this.returnToDefaultTimeZone}>
          {this.renderEscShortcut()}
        </span>

        <span className="ml-1">to</span>

        <span
          onClick={this.returnToDefaultTimeZone}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "my-0.5",
          )}
        >
          remove secondary time zone
        </span>

        <span>.</span>
      </div>
    );
  }

  renderEscShortcut() {
    return (
      <ShortcutTile
        shortcut={"ESC"}
        backgroundColor={"transparent"}
        inputClassname={"top-bar-hover-span-button"}
        useDefaultTextColor={true}
      />
    );
  }

  renderContactBar() {
    return (
      <div
        className="layout-top-flag-bar"
        style={{ marginTop: this.determineMarginTop() }}
      >
        Your calendar is currently displaying other searched calendars.
        <span className="ml-1">Press</span>
        <span className={Classnames("cursor-pointer display-flex-center")} onClick={this.removeAllSearchedCalendars}>
          {this.renderEscShortcut()}
        </span>
        <span className="ml-1">to</span>
        <span
          onClick={this.removeAllSearchedCalendars}
          className={Classnames(
            "text-link-top-bar",
            "ml-1",
            "my-0.5",
          )}
        >
          remove all searched calendars
        </span>
        <span>.</span>
      </div>
    );
  }

  getDefaultTimeZone() {
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;
    const {
      lastSelectedTimeZone,
    } = this.props.appTimeZone;
    const defaultBrowserTimeZone = getDefaultUserTimeZone({
      masterAccount,
      user: currentUser,
    });
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone,
      skipCheckForTemporaryTimeZones: true, // no need to pass anything in here
    });
  }

  determineMarginTop() {
    return this.state.isElectron ? DESKTOP_TITLE_BAR_HEIGHT : 0;
  }

  removeAllSearchedCalendars() {
    if (!isEmptyArray(this.props.temporaryEvents)
      && this.props.temporaryEvents.find((event) => isFindTimeEventFormEvent(event))
    ) {
      broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    }
    Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
  }

  returnToDefaultTimeZone() {
    if (this.props.isMinified) {
      Broadcast.publish("SELECT_TIME_ZONE", {
        timeZone: this.getDefaultTimeZone(),
      });
      return;
    }

    Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
  }
}

function mapStateToProps(state) {
  const {
    eventFormEmails,
    currentTimeZone,
    currentTimeZoneLabel,
    currentUser,
    selectedDay,
    temporaryEvents,
  } = state;

  return {
    eventFormEmails,
    currentTimeZone,
    currentTimeZoneLabel,
    currentUser,
    selectedDay,
    temporaryEvents,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const appTimeZone = useAppTimeZones();

  return <BaseComponent {...props} masterAccount={masterAccount} appTimeZone={appTimeZone} />;
};

export default connect(mapStateToProps)(withStore(LayoutTopBar));
