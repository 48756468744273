import Modal from "react-modal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { clone, isEqual, noop, sortBy } from "underscore";
import { useSelector } from "react-redux";

import {
  ATTENDEE_ACCESS_STATES,
  GroupSchedulingAttendeeProp,
  GroupSchedulingAttendeeType,
} from "../../lib/groupSchedulingVariables";
import { constructRequestURL } from "../../services/api";
import {
  formatAttendeesToDummyState,
  formatNewAttendeesToEmail,
  getAttendeeAccessState,
  getAttendeeEmail,
  trackTeamSlotsTeamPlan,
} from "../../lib/groupSchedulingFunctions";
import { EVENT_MODAL_ID } from "../../services/elementIDVariables";
import { handleError } from "../../services/commonUsefulFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useTeamPlan } from "../../services/stores/userData";
import classNames from "classnames";
import Fetcher from "../../services/fetcher";
import GroupSchedulingAttendee from "./groupSchedulingAttendee";
import usePrevious from "../specialComponents/usePrevious";
import "../../styles/groupSchedulingStyles.css";
import {
  useStripePaymentMethods,
  useStripeSubscriptions,
  useStripeUpcomingInvoices,
} from "../../services/stores/finance";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import UpdatedAddTeamMembersModal from "../teamPlans/updatedAddTeamMembersModal";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { fetcherGet } from "../../services/fetcherFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { getUserConnectedAccountToken } from "../../services/maestro/maestroAccessors";
import { Billing } from "../../types/vimcal/stripe";
import { STRIPE_ENDPOINTS } from "../../lib/endpoints";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";

type GroupSchedulingProps = {
  attendees: GroupSchedulingAttendeeProp[];
  blockedCalendars: string[];
  removeAttendee: (email: string) => void;
  setBlockedCalendars: (attendeeEmails: string[]) => void;
  hideSwitch?: boolean;
  disableAttendeesSwitchAndSetToFalse?: boolean;
  selectedUser?: User;
};

const GroupScheduling = (props: GroupSchedulingProps) => {
  const {
    attendees,
    blockedCalendars,
    removeAttendee,
    setBlockedCalendars,
    hideSwitch,
    disableAttendeesSwitchAndSetToFalse,
    selectedUser,
  } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const isFetchingBillingRef = useRef(false);
  const { allLoggedInUsers } = useAllLoggedInUsers();
  const { masterAccount } = useMasterAccount();
  const { setStripePaymentMethods } = useStripePaymentMethods();
  const { setStripeSubscriptions } = useStripeSubscriptions();
  const { setStripeUpcomingInvoices } = useStripeUpcomingInvoices();
  const { setTeamPlan, teamPlan } = useTeamPlan();
  const [attendeeForModal, setAttendeeForModal] =
    useState<GroupSchedulingAttendeeType | null>(null);
  const [stillLoadingWarning, setStillLoadingWarning] = useState(false);
  /* Track local state of attendees */
  /* Separate from the state of the parent */
  const [groupSchedulingAttendees, setGroupSchedulingAttendees] = useState<
    GroupSchedulingAttendeeType[]
  >([]);

  const showWarning = () => {
    setStillLoadingWarning(true);
  }

  useEffect(() => {
    availabilityBroadcast.subscribe("SHOW_STILL_LOADING_SLOTS_WARNING", showWarning);
    return () => {
      availabilityBroadcast.unsubscribe("SHOW_STILL_LOADING_SLOTS_WARNING");
    };
  }, []);

  /* Check if we have fetched billing at least once upon opening */
  const [hasFetchedTeamPlan, setHasFetchedTeamPlan] = useState(false);
  /* Track the emails we have requests for to prevent async updates adding removed attendees */
  const [emailsBeingFetched, setEmailsBeingFetched] = useState<string[]>([]);
  const componentIsMounted = useIsMounted();
  const isUserMaestro = isUserMaestroUser(masterAccount);
  const previousAttendees = usePrevious(attendees);
  const previousSelectedUser = usePrevious(selectedUser);
  const previousGroupSchedulingAttendees = usePrevious(
    groupSchedulingAttendees
  );
  const previousTeamPlan = usePrevious(teamPlan);
  const portalRef = document.getElementById("layout") ?? document.body;
  
  const user = selectedUser || currentUser;

  useEffect(() => {
    if (stillLoadingWarning && isEmptyArrayOrFalsey(emailsBeingFetched)) {
      setStillLoadingWarning(false);
    }
  }, [emailsBeingFetched, stillLoadingWarning]);

  const fetchBilling = useCallback(
    (setLoadingStateFunction) => {
      const path = STRIPE_ENDPOINTS.BILLING;
      const url = constructRequestURL(path, true);

      // This is a heavy request, so prevent multiple simultaneous requests.
      if (isFetchingBillingRef.current) {
        return;
      }

      isFetchingBillingRef.current = true;

      fetcherGet<{ billing: Billing }>({
        url,
        email: getUserEmail(user),
        connectedAccountToken: getUserConnectedAccountToken({ user }),
      })
        .then((response) => {
          if (
            !componentIsMounted.current ||
            isEmptyObjectOrFalsey(response) ||
            !response.billing
          ) {
            return;
          }

          const { team_plan, payment_methods, subscription, upcoming_invoice } =
            response.billing;

          if (team_plan) {
            setTeamPlan(team_plan || {});
          }

          if (payment_methods) {
            setStripePaymentMethods(payment_methods || []);
          }

          if (subscription) {
            setStripeSubscriptions(subscription || {});
          }

          if (upcoming_invoice) {
            setStripeUpcomingInvoices(upcoming_invoice || {});
          }

          /* Set loading state to true to stop spinner */
          setLoadingStateFunction(true);
        })
        .catch(handleError)
        .finally(() => {
          isFetchingBillingRef.current = false;
        });
    },
    [
      getUserEmail(user),
      setStripePaymentMethods,
      setStripeSubscriptions,
      setStripeUpcomingInvoices,
      setTeamPlan,
    ],
  );

  const ModalStyle = determineDefaultModalStyle(isDarkMode, true);

  /* Handle setting the dummy state before sending the backend request */
  useEffect(() => {
    async function checkPermissions({ attendeeEmails, dummyState }) {
      /* Send request to backend to check access for calendars */
      const response = await Fetcher.post<{ calendars: any[] }>(
        constructRequestURL("calendars/check_calendars_read_access", true),
        { body: JSON.stringify({ calendar_ids: attendeeEmails }) },
        true,
        getUserEmail(user),
      );
      if (!componentIsMounted?.current) {
        return;
      }

      // @ts-ignore
      if (
        !response ||
        !response.calendars ||
        response.calendars?.length === 0
      ) {
        return;
      }

      /* Set state based on response */
      // @ts-ignore
      const updatedGroupSchedulingAttendees = dummyState.map((attendee) => {
        const attendeeEmail = getAttendeeEmail(attendee);
        let attendeeAccessState = getAttendeeAccessState(attendee);

        /* Will only contain new emails */
        const matchingResponseAttendee = response.calendars.find(
          (responseAttendee) =>
            getAttendeeEmail(responseAttendee) === attendeeEmail
        );
        if (
          attendeeAccessState === ATTENDEE_ACCESS_STATES.noAccess &&
          !!matchingResponseAttendee
        ) {
          attendeeAccessState = getAttendeeAccessState(
            matchingResponseAttendee
          );
        } else if (attendeeAccessState === ATTENDEE_ACCESS_STATES.blocked
          && !!matchingResponseAttendee
        ) {
          // switch from one user with permission to block to another user without permission to block
          attendeeAccessState = getAttendeeAccessState(
            matchingResponseAttendee
          );
        }

        return {
          ...attendee,
          access_state: attendeeAccessState,
        };
      });

      return updatedGroupSchedulingAttendees;
    }

    /* Returns an object with { teamPlanAttendees: string[], missingTeamPlanAttendees: GroupSchedulingAttendeeType } */
    async function checkTeamPlans({ attendees }) {
      if (!attendees) {
        return [];
      }

      return attendees.map((attendee) => {
        const activeTeamPlanMembers = teamPlan?.active_users ?? [];
        const pendingTeamPlanMembers = teamPlan?.pending_users ?? [];

        const accessState = getAttendeeAccessState(attendee);
        /* Hide the team plan buttons if user is Maestro and has access */
        const shouldHideTeamPlan =
          isUserMaestro && accessState !== ATTENDEE_ACCESS_STATES.noAccess;

        /* Check if we need to display text for team plan */
        const isPendingTeamPlan =
          !shouldHideTeamPlan &&
          !!pendingTeamPlanMembers.find(
            (user) => isSameEmail(getUserEmail(user), getAttendeeEmail(attendee))
          );
        const isMissingTeamPlan =
          !shouldHideTeamPlan &&
          !isPendingTeamPlan &&
          !activeTeamPlanMembers.find(
            (user) => isSameEmail(getUserEmail(user), getAttendeeEmail(attendee))
          );

        /* Return the booleans for whether or not user is missing or pending team plan */
        return {
          ...attendee,
          access_state:
            !isPendingTeamPlan && !isMissingTeamPlan
              ? accessState
              : ATTENDEE_ACCESS_STATES.noAccess,
          isMissingTeamPlan,
          isPendingTeamPlan,
        };
      });
    }

    async function handleAsyncStateChange({dummyState, didSelectedUserChange}) {
      const newAttendeeEmails = formatNewAttendeesToEmail({
        attendees,
        previousAttendees: previousAttendees ?? [],
        didSelectedUserChange,
        previousTeamPlan,
        teamPlan,
      });

      if (isEmptyArrayOrFalsey(newAttendeeEmails)) {
        return;
      }

      setEmailsBeingFetched(newAttendeeEmails);

      /* Check which calendars already have permissions */
      /* This check will pass if user is already on team plan and has perms */
      let updatedGroupSchedulingAttendees = await checkPermissions({
        attendeeEmails: newAttendeeEmails,
        dummyState,
      });
      if (!componentIsMounted?.current) {
        return;
      }
      updatedGroupSchedulingAttendees = await checkTeamPlans({
        attendees: updatedGroupSchedulingAttendees,
      });
      if (!componentIsMounted?.current) {
        return;
      }

      /* Set the state */
      setGroupSchedulingAttendees(updatedGroupSchedulingAttendees);
      setEmailsBeingFetched([]);
    }

    /* Check if the previous attendees is the same as the current attendees */
    const didAttendeesChange = !isEqual(
      sortBy(attendees),
      sortBy(previousAttendees || [])
    );
    const didTeamPlanChange = !isEqual(
      previousTeamPlan?.active_users,
      teamPlan?.active_users
    );
    const didTeamPlanPendingChange = !isEqual(
      previousTeamPlan?.pending_users,
      teamPlan?.pending_users
    );
    const didSelectedUserChange = selectedUser && previousSelectedUser && !isSameEmail(
      getUserEmail(selectedUser),
      getUserEmail(previousSelectedUser)
    );

    /* Keep seperate from attendees */
    if (didTeamPlanChange || (isEmptyObjectOrFalsey(teamPlan) && !hasFetchedTeamPlan)) {
      fetchBilling(setHasFetchedTeamPlan);
    }

    if (didSelectedUserChange || didAttendeesChange || didTeamPlanChange || didTeamPlanPendingChange) {
      /* Set dummy state until response comes back */
      /* Use a dummy throughout the process and not the updated state to prevent loop */
      const dummyState = formatAttendeesToDummyState({
        attendees,
        groupSchedulingAttendees: previousGroupSchedulingAttendees ?? [],
      });
      setGroupSchedulingAttendees(dummyState);

      try {
        /* Keep billing as up to date as possible */
        /* We start all state changes after we fetch updated billing */
        handleAsyncStateChange({ dummyState, didSelectedUserChange });
      } catch (e) {
        handleError(e);
      }
    }
  }, [
    allLoggedInUsers,
    attendees,
    getUserEmail(user),
    selectedUser,
    fetchBilling,
    hasFetchedTeamPlan,
    masterAccount,
    previousAttendees,
    previousGroupSchedulingAttendees,
    previousTeamPlan,
    setTeamPlan,
    setStripePaymentMethods,
    setStripeSubscriptions,
    setStripeUpcomingInvoices,
    teamPlan,
  ]);

  /* Handle setting the parent component state when toggle state for attendees change */
  useEffect(() => {
    const attendeeEmailsToBlock = groupSchedulingAttendees
      .filter(
        (attendee) => attendee.access_state === ATTENDEE_ACCESS_STATES.blocked
      )
      .map((attendee) => getAttendeeEmail(attendee));

    /* Check if blocked calendars and new blocked calendars match */
    /* Should this be order independent? */
    if (!isEqual(sortBy(attendeeEmailsToBlock), sortBy(blockedCalendars))) {
      setBlockedCalendars(attendeeEmailsToBlock);
    }
  }, [blockedCalendars, groupSchedulingAttendees, setBlockedCalendars]);

  /* Handle the toggle for attendees blocked state */
  const toggleAttendeeAccessState = (attendeeToUpdate) => {
    /* Don't do anything for no access */
    if (attendeeToUpdate.access_state === ATTENDEE_ACCESS_STATES.noAccess) {
      return;
    }

    /* Flip the state from blocked to unblocked or vice versa */
    let updatedaccess_state;
    if (attendeeToUpdate.access_state === ATTENDEE_ACCESS_STATES.blocked) {
      updatedaccess_state = ATTENDEE_ACCESS_STATES.unblocked;
    } else {
      updatedaccess_state = ATTENDEE_ACCESS_STATES.blocked;
    }

    /* If attendees are unique, we can just use findIndex to get index and update */
    const updatedGroupSchedulingAttendees = clone(groupSchedulingAttendees);
    const attendeeToUpdateIndex = groupSchedulingAttendees.findIndex(
      (attendee) => isSameEmail(getAttendeeEmail(attendee), getObjectEmail(attendeeToUpdate))
    );
    updatedGroupSchedulingAttendees[attendeeToUpdateIndex] = {
      ...updatedGroupSchedulingAttendees[attendeeToUpdateIndex],
      access_state: updatedaccess_state,
    };

    /* Update state with the updated value */
    setGroupSchedulingAttendees(updatedGroupSchedulingAttendees);
  };
  if (isEmptyArrayOrFalsey(groupSchedulingAttendees)) {
    return null;
  }

  return (
    <div
      className={classNames(
        "group-scheduling-container",
        isDarkMode ? "dark-mode" : "",
        "negative-margin-left-8px-override",
        "negative-margin-right-8px-override"
      )}
    >
      {groupSchedulingAttendees?.map((attendee, idx) => (
        <GroupSchedulingAttendee
          attendee={attendee}
          emailsBeingFetched={emailsBeingFetched}
          isDarkMode={isDarkMode ?? false}
          key={`group-scheduling-attendee-${getObjectEmail(attendee)}-${idx}`}
          removeAttendee={removeAttendee}
          showModal={() => {
            trackTeamSlotsTeamPlan({
              action: "click add team members icon",
              currentUser: user,
            });
            setAttendeeForModal(attendee);
          }}
          toggleAttendeeAccessState={toggleAttendeeAccessState}
          hideSwitch={hideSwitch}
          disableAttendeesSwitchAndSetToFalse={
            disableAttendeesSwitchAndSetToFalse
          }
        />
      ))}
      {stillLoadingWarning ? (
        <div className="warning-color default-font-size mt-2 ml-2">
          Loading your team information, please hold tight!
        </div>
      ) : null}

      {attendeeForModal
        ? ReactDOM.createPortal(
            <Modal
              ariaHideApp={false}
              id={EVENT_MODAL_ID}
              isOpen={!!attendeeForModal}
              onRequestClose={() => noop}
              style={ModalStyle}
            >
              <UpdatedAddTeamMembersModal
                attendeeForModal={attendeeForModal}
                closeModal={() => setAttendeeForModal(null)}
              />
            </Modal>,
            portalRef
          )
        : null}
    </div>
  );
};

export default GroupScheduling;
