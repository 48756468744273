import React, { useEffect } from "react";
import { isMobile } from "../../services/commonUsefulFunctions";
import { trackEvent } from "../tracking";
import appBroadcast from "../../broadcasts/appBroadcast";
import MobileSignupIcon from "./mobileSignupIcon";

// children here allows us to pass in the login button component
export default function MobileSignUp({ children }) {
  useEffect(() => {
    appBroadcast.publish("SET_APP_DARK_MODE");
    if (!isMobile()) {
      trackEvent({
        category: "mobile_signup",
        action: "0",
        label: "didMount - is not mobile",
      });
      return;
    }
    trackEvent({
      category: "mobile_signup",
      action: "0",
      label: "didMount - isMobile",
    });
    return () => {};
  }, []);

  return (
    <div className="mobile-signup-page-layout">
      <MobileSignupIcon />
      {RenderLoginBox({ children })}
    </div>
  );
}

function RenderLoginBox({ children }) {
  return (
    <div className="py-8 px-4 download-button-container flex text-center flex-col mt-14 w-80 rounded-2xl">
      <div className="font-size-16 font-weight-500">
        {"Start using the world's fastest calendar."}
      </div>
      <div className="secondary-text-color default-font-size mt-2">
        Start your 7 day free trial
      </div>
      <div className="mt-6 flex items-center flex-col">{children}</div>
    </div>
  );
}
