import React, { useRef, useState } from "react";
import SocialLinks from "./socialLinks";
import NameAndProfilePhoto from "./nameAndProfilePhoto";
import SaveButton from "../../common/saveButton";
import UserNameModal from "./userNameModal";
import { SelectUser, getAllUsers } from "../../common/selectUser";
import { useAllLoggedInUsers, useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { isUserDelegatedUser, isUserMaestroUser } from "../../../../services/maestroFunctions";
import { useSelector } from "react-redux";

export default function Profile(props) {
  const nameAndPhotoFormRef = useRef();
  const socialLinksFormRef = useRef();

  const [isNameAndPhotoFormValid, setIsNameAndPhotoFormValid] = useState(true);
  const [hasNameAndPhotoFormChanged, setHasNameAndPhotoFormChanged] = useState(false);
  const [isSocialLinksFormValid, setIsSocialLinksFormValid] = useState(true);
  const [hasSocialLinksFormChanged, setHasSocialLinksFormChanged] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [showWarningModalOnSubmit, setShowWarningModalOnSubmit] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const selectedUser = getAllUsers({
    currentUser,
    allLoggedInUsers,
    masterAccount,
  })[selectedUserIndex]?.value;
  const isUpdatingExecutiveProfile = isUserDelegatedUser(selectedUser);

  const onClickSave = () => {
    setIsWarningModalOpen(false);

    if (isSocialLinksFormValid && isNameAndPhotoFormValid) {
      if (hasNameAndPhotoFormChanged) {
        nameAndPhotoFormRef.current?.requestSubmit();
      }
      if (hasSocialLinksFormChanged) {
        socialLinksFormRef.current?.requestSubmit();
      }
      setShowErrorText(false);
    } else {
      setShowErrorText(true);
    }
  };

  const onSubmit = () => {
    if (showWarningModalOnSubmit) {
      setIsWarningModalOpen(true);
    } else {
      onClickSave();
    }
  };

  const renderSelectedUser = () => {
    if (!isUserMaestroUser(masterAccount)) {
      return null;
    }

    const SELECT_USER_ID = "profile-select-user-id";

    return (
      <SelectUser
        setSelectedUserIndex={setSelectedUserIndex}
        id={SELECT_USER_ID}
        selectedUser={selectedUser}
        inputContainerClassName="settings-select-user-position"
        addExecutiveLabel={true}
        showAccountLabel={true}
      />
    );
  };

  return (
    <div className="flex flex-col h-full">
      {renderSelectedUser()}
      <div className="overflow-y-auto">
        <NameAndProfilePhoto
          {...props}
          formRef={nameAndPhotoFormRef}
          setHasChanged={setHasNameAndPhotoFormChanged}
          setIsFormValid={setIsNameAndPhotoFormValid}
          setIsUploadingPhoto={setIsUploadingPhoto}
          setShowWarningModalOnSubmit={setShowWarningModalOnSubmit}
          showErrorText={showErrorText}
          isUpdatingExecutiveProfile={isUpdatingExecutiveProfile}
          selectedUser={selectedUser}
        />
        <SocialLinks
          {...props}
          formRef={socialLinksFormRef}
          setHasChanged={setHasSocialLinksFormChanged}
          setIsFormValid={setIsSocialLinksFormValid}
          showErrorText={showErrorText}
          isUpdatingExecutiveProfile={isUpdatingExecutiveProfile}
          selectedUser={selectedUser}
        />
      </div>
      <div className="pr-2.5 mt-3.5">
        <SaveButton
          disabled={isUploadingPhoto || !(hasNameAndPhotoFormChanged || hasSocialLinksFormChanged)}
          onClick={onSubmit}
        />
      </div>
      <UserNameModal
        isWarningModalOpen={isWarningModalOpen}
        onClickExit={() => setIsWarningModalOpen(false)}
        onClickSave={onClickSave}
      />
    </div>
  );
}
