import React, { Component } from 'react';
import {
  createMultipleConferencingWarningString,
  determineConferenceType,
  getDescriptionConferenceUrl
} from '../../services/commonUsefulFunctions';
import { GOOGLE_CONFERENCING } from '../../services/globalVariables';
import GoogleCalendarService from '../../services/googleCalendarService';

const {
  googleHangoutString,
  zoomString
} = GoogleCalendarService;

class MultipleConferenceWarning extends Component {
  determineConferenceData() {
    if (this.props.conferenceURL) {
      return this.props.conferenceURL;
    } else if (this.props.conference === zoomString) {
      return this.props.currentUser.zoom_link;
    } else if (this.props.conference === googleHangoutString) {
      return GOOGLE_CONFERENCING;
    }
  }

  render() {
    const locationConferenceUrl = this.props.location;
    const descriptionConferenceUrl = getDescriptionConferenceUrl(
      this.props.description && this.props.description.getEditorContents()
    );
    const conferenceDataUrl = this.determineConferenceData();

    const warning = createMultipleConferencingWarningString({
      locationConference: determineConferenceType(this.props.location),
      descriptionConference: determineConferenceType(descriptionConferenceUrl),
      conferenceDataConference: determineConferenceType(conferenceDataUrl),
      locationConferenceUrl,
      descriptionConferenceUrl,
      conferenceDataUrl,
    });

    if (!warning) {
      return null;
    }

    return (
      <div className="conflicting-zoom-warning ml-8">
        <div>
          *
        </div>

        <div className="margin-left-5">
          {warning}
        </div>
      </div>
    );
  }
};

export default MultipleConferenceWarning;
