import React from "react";
import { useSelector } from "react-redux";
import { DARK_MODE_SECONDARY_TEXT_COLOR } from "../../../services/globalVariables";

const TagsEmptyIcon: React.FC<{}> = () => {
  const isDarkMode = useSelector((state: any) => state.isDarkMode);
  const strokeColor = isDarkMode ? DARK_MODE_SECONDARY_TEXT_COLOR : '#A8A8A8'

  return (
    <div className="paint-colors-empty-icon flex items-center">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.16533 3.09666L0.944665 5.52599C0.911331 5.88599 0.895331 6.06666 0.924665 6.23933C0.951591 6.39304 1.0053 6.54084 1.08333 6.67599C1.17066 6.82799 1.3 6.95732 1.55733 7.21466L5.008 10.6653C5.53266 11.19 5.79466 11.452 6.09866 11.5507C6.36666 11.638 6.65533 11.638 6.92333 11.5507C7.22733 11.452 7.492 11.188 8.02 10.66L10.66 8.01999C11.188 7.49199 11.4513 7.22799 11.5507 6.92399C11.6374 6.65614 11.6372 6.3677 11.55 6.09999C11.4513 5.79532 11.188 5.53133 10.66 5.00333L7.218 1.56133C6.958 1.30133 6.82866 1.17199 6.676 1.08399C6.54065 1.00588 6.39261 0.952177 6.23866 0.925325C6.06533 0.895325 5.882 0.911992 5.516 0.945325L3.096 1.16533C2.46666 1.22266 2.15133 1.25133 1.90466 1.38866C1.68769 1.50941 1.50875 1.68835 1.388 1.90533C1.25133 2.15066 1.22266 2.46399 1.166 3.08733L1.16533 3.09666Z" stroke={strokeColor} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.47534 4.47529C4.53723 4.41335 4.58632 4.33983 4.6198 4.25892C4.65329 4.178 4.6705 4.09129 4.67047 4.00372C4.67044 3.91616 4.65316 3.82946 4.61962 3.74857C4.58609 3.66768 4.53694 3.59419 4.475 3.53229C4.41306 3.4704 4.33954 3.42131 4.25863 3.38783C4.17771 3.35434 4.091 3.33713 4.00343 3.33716C3.91587 3.33719 3.82917 3.35447 3.74828 3.38801C3.66739 3.42154 3.5939 3.47069 3.532 3.53263C3.407 3.65772 3.33681 3.82735 3.33687 4.0042C3.3369 4.09176 3.35418 4.17846 3.38772 4.25935C3.42125 4.34024 3.4704 4.41373 3.53234 4.47563C3.59428 4.53752 3.6678 4.58661 3.74871 4.62009C3.82963 4.65358 3.91634 4.67079 4.00391 4.67076C4.18075 4.6707 4.35033 4.60039 4.47534 4.47529Z" stroke={strokeColor} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

export default TagsEmptyIcon;
