import React, { PureComponent, useState } from "react";
import { X } from "react-feather";
import EventModalPopup from "./eventModalPopup";
import {
  determineRBCEventEndWithEventStart,
  updateToStartOfNextDayIfLastSlot,
  renderTimeSlotsLine,
  createAvailabilityTextFromEvent,
  handleError,
  createTimeFromDayOfWeek,
  determineSaveButtonShortcut,
  reformatMinDuration,
  convertDurationBackToMinutes,
  determineDurationString,
  blurOnEscape,
  hasStateOrPropsChanged,
  createDefaultPersonalLinkEventSummary,
  determineConferencingFromCurrentUser,
  calculateMarginTop,
  isBeforeMinute,
  isAfterMinute,
  generateAvailabilityToken,
  isValidTimeZone,
  addAbbrevationToTimeZone,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import { Calendar } from "rbc-fork-react-big-calendar";
import withDragAndDrop from "rbc-fork-react-big-calendar/lib/addons/dragAndDrop";
import GoogleCalendarService from "../services/googleCalendarService";
import CustomEvent from "./customEvent";
import StyleConstants, {
  PERSONAL_LINK_DETAIL_CONTAINER,
  DARK_MODE_BACKGROUND_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  PERSONAL_LINK_LABEL_MORE_INFO_SCREENSHOT,
  BLUE_BUTTON,
  PERSONAL_LINK_NICKNAME_MORE_INFO_SCREENSHOT,
  SECOND_IN_MS,
} from "../services/globalVariables";
import { conferenceOptionFromBackend } from "../services/googleCalendarHelpers";
import Broadcast from "../broadcasts/broadcast";
import SaveButton from "./saveButton";
import SelectTimeZoneInput from "./selectTimeZoneInput";
import PersonalLinkEventForm from "./personalLinkEventForm";
import PersonalLinkSettingsPage from "./personalLinkSettingsPage";
import { constructRequestURL } from "../services/api";
import Fetcher from "../services/fetcher";
import {
  format,
  setDay,
  setHours,
  startOfHour,
  parseISO,
  addDays,
  differenceInMinutes,
  addMinutes,
} from "date-fns";
import { determineRBCLocalizer } from "../lib/stateManagementFunctions";
import _ from "underscore";
import {
  defaultSlotGetter,
  isSelectedSlotAllDayEvent,
  defaultCustomDayLayout,
  protectMidnightCarryOver,
} from "../lib/rbcFunctions";
import { ModifyCustomQuestionModal } from "./modifyCustomQuestionModal";
import {
  CustomQuestions,
  ModifyCustomQuestionMode,
  DEFAULT_CUSTOM_QUESTIONS,
} from "./customQuestions.tsx";
import {
  convertBufferDaysHoursMinutesToMinutes,
  doesListOfQuestionsIncludeCompany,
  doesTitleIncludeCompanyQuestion,
  getBlockedCalendarList,
  getBlockedCalendarsID,
  getBlockingCalendarsPersonalLink,
  getBufferDaysHoursMinutesFromMinutes,
  isSameQuestion,
} from "../lib/availabilityFunctions";
import SelectBlockedCalendars from "./availability/selectBlockedCalendars";
import { getEmailFromUserCalendarID } from "../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { getBlockedCalendars } from "../services/accountFunctions";
import {
  getCalendarEmail,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import classNames from "classnames";
import { isVersionV2 } from "../services/versionFunctions";
import BlockedCalendarSection from "./availability/blockedCalendarSection";
import useSlugCheck from "../services/customHooks/useSlugCheck";
import PersonalLinkDetailsPanel from "./availability/personalLinkDetailsPanel";
import { getUserEmail, getUserName } from "../lib/userFunctions";
import {
  MAX_USER_NAME_LENGTH,
  MINIMUM_USER_NAME_LENGTH,
} from "../lib/bookingFunctions";
import ApprovalBadgeWithLoadingSpinner from "./settings/common/approvalBadgeWithLoadingSpinner";
import {
  shouldShowEmailReminders,
  showCustomizeUsername,
} from "../lib/featureFlagFunctions";
import { getClientEventID } from "../services/eventResourceAccessors";
import PreloadResources from "./preloadResources";
import { addDefaultToArray } from "../lib/arrayFunctions";
import {
  getNewPersonalLinkSelectedUserAndCalendar,
  getPersonalLinkInternalLabel,
  getPersonalLinkIsIgnoreInternalConflicts,
} from "../lib/personalLinkFunctions";
import {
  capitalizeFirstLetter,
  getInputStringFromEvent,
  pluralize,
} from "../lib/stringFunctions";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../services/typeGuards";
import {
  getDefaultUserTimeZone,
  getInternalDomainAndEmails,
  getWorkHours,
} from "../lib/settingsFunctions";
import GenericErrorModalContent from "./genericErrorModalContent";
import { createUUID } from "../services/randomFunctions";
import MoreInfoIcon from "./moreInfoIcon";
import SaveAndCancelButton from "./buttons/saveAndCancelButton";
import {
  DEFAULT_REMINDER_EMAILS,
  filterDuplicateTimings,
} from "./reminderEmail/reminderEmailFunctions";
import { getDateTimeFormat } from "../lib/dateFunctions";
import NotificationSettingsPage from "./personalLink/notificationSettingsPage";
import AvailabilityLink from "../views/availabilityLink";
import {
  determineDefaultModalStyle,
  MODAL_OVERLAY_Z_INDEXES,
} from "../lib/modalFunctions";
import { getDefaultModalBorder, getSidePoppedOverModalBorder } from "../lib/styleFunctions";
import CustomButton from "./customButton";
import ExpandIconWithAnimation from "./icons/expandIconWithAnimation";
import ColoredLine from "./line";

const CONTENT_WIDTH = 315;

const DnDCalendar = withDragAndDrop(Calendar);

const INPUT_NAME = "input-name";
const INPUT_NICKNAME = "input-nickname";
const CONTAINER = "personal-link-detail-container";
const CONFIRM_DISCARD_MODAL = "CONFIRM_DISCARD_MODAL";
const WEEKLY_CALENDAR_MODAL = "WEEKLY_CALENDAR_MODAL";
const ERROR_MODAL = "ERROR_MODAL";
const PREVIEW_MODAL = "preview-modal";

const OPEN_SECTION = {
  AVAILABILITY: "AVAILABILITY",
  SCHEDULE_SETTINGS: "SCHEDULE_SETTINGS",
  NOTIFICATION_SETTINGS: "NOTIFICATION_SETTINGS",
  CALENDAR_INVITE: "CALENDAR_INVITE",
  CUSTOM_QUESTIONS: "CUSTOM_QUESTIONS",
};

const OVERLAPPING_MODAL_CONTENT = {
  BLOCKED_CALENDARS: "BLOCKED_CALENDARS",
};

let { availability } = GoogleCalendarService;

class PersonalLinkDetailPage extends PureComponent {
  constructor(props) {
    super(props);

    this.windowSizeChangeTimer = null;
    this.name = React.createRef();
    this.nickname = React.createRef();
    this.containerRef = React.createRef();

    this._updatePreviewTimeout = null; // so we don't update on every keystroke

    this.state = this.constructInitialState(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeNickname = this.onChangeNickname.bind(this);
    this.createLink = this.createLink.bind(this);
    this.onExitPersonalLinkDetails = this.onExitPersonalLinkDetails.bind(this);
    this.onClickAddTimes = this.onClickAddTimes.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.determineOnSelectSlotMethod =
      this.determineOnSelectSlotMethod.bind(this);
    this.timeRangeFormat = this.timeRangeFormat.bind(this);
    this.eventStyleGetter = this.eventStyleGetter.bind(this);
    this.onEventChange = this.onEventChange.bind(this);
    this.deleteAvailableEvent = this.deleteAvailableEvent.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.setTimeZone = this.setTimeZone.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.focusSaveButton = this.focusSaveButton.bind(this);
    this.saveLink = this.saveLink.bind(this);
    this.saveLinkDetail = this.saveLinkDetail.bind(this);
    this.confirmDiscard = this.confirmDiscard.bind(this);
    this.unfocusWeeklyCalendar = this.unfocusWeeklyCalendar.bind(this);
    this.setSelectedQuestionIndex = this.setSelectedQuestionIndex.bind(this);
    this.showModifyQuestionModal = this.showModifyQuestionModal.bind(this);
    this.deleteCustomQuestion = this.deleteCustomQuestion.bind(this);
    this.onClickSetBlockingCalendar =
      this.onClickSetBlockingCalendar.bind(this);
    this.onChangeSlug = this.onChangeSlug.bind(this);
    this.checkForAvailableSlugChange =
      this.checkForAvailableSlugChange.bind(this);
    this.getShadedWorkHours = this.getShadedWorkHours.bind(this);
    this.onMouseEnterURLMoreInfo = this.onMouseEnterURLMoreInfo.bind(this);
    this.onMouseRemoveURLMoreInfo = this.onMouseRemoveURLMoreInfo.bind(this);
    this.onEnableEmailReminders = this.onEnableEmailReminders.bind(this);
    this.closeOverlappingModal = this.closeOverlappingModal.bind(this);
    this.onToggleExpand = this.onToggleExpand.bind(this);

    Broadcast.subscribe(
      "DELETE_PERSONAL_LINK_EVENT",
      this.deleteAvailableEvent,
    );
    Broadcast.subscribe(
      "FOCUS_SAVE_BUTTON_PERSONAL_LINK_DETAIL",
      this.focusSaveButton,
    );
    Broadcast.subscribe(
      "ON_BACK_PERSONAL_LINK_DETAIL",
      this.onExitPersonalLinkDetails,
    );

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    this._isMounted = true;

    let shouldPinCopyButton = this.shouldPinCopyButton();
    if (shouldPinCopyButton !== this.state.shouldPinCopyButton) {
      this.setState({ shouldPinCopyButton });
    }

    const { allCalendars } = this.props.allCalendars;

    if (!this.props.isNew) {
      // update name for emailNameIndex
      const blockedCalendarEmails = this.state.blockedCalendarsID.map(
        (userCalendarID) => {
          const email =
            getCalendarEmail(
              getBlockedCalendarList(this.state.blockedCalendars).find(
                (c) => getCalendarUserCalendarID(c) === userCalendarID,
              ),
            ) ?? getEmailFromUserCalendarID(userCalendarID, allCalendars);
          return email;
        },
      );

      Broadcast.publish("SEARCH_CONTACTS_DOMAIN_DB", blockedCalendarEmails);
    }

    if (this.state.token && !!this.props.setToken) {
      this.props.setToken(this.state.token);
    }

    if (!this.state.slug && this.props.isNew) {
      // do not do it for existing personal links
      this.saveSlugIfNotEdited({ duration: this.state.duration });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.slots !== prevState.slots) {
      this.createTextFromSelection();
    }

    if (this.state.openSection !== prevState.openSection) {
      const shouldPinCopyButton = this.shouldPinCopyButton();
      if (shouldPinCopyButton !== this.state.shouldPinCopyButton) {
        this.setState({ shouldPinCopyButton });
      }
    }

    if (!this.state.hasLinkChanged) {
      let hasChanged = hasStateOrPropsChanged(
        prevState,
        this.state,
        null,
        null,
        [
          "shouldPinCopyButton",
          "isSubmitting",
          "hasCapitalizedName",
          "hasCapitalizedNickname",
          "shouldDisplaySlotWarning",
          "hasChanged",
          "shouldDisplayModal",
          "shouldDisplayZeroDurationWarning",
          "showChangeURLHint",
        ],
        false,
      );

      if (hasChanged) {
        this.setState({ hasLinkChanged: true });
      }
    }

    this.checkForAvailableSlugChange(prevProps);
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this._updatePreviewTimeout);
    clearTimeout(this.windowSizeChangeTimer);
    this._updatePreviewTimeout = null;

    this.windowSizeChangeTimer = null;
    this._originalState = null;

    window.removeEventListener("resize", this.handleWindowSizeChange);

    Broadcast.unsubscribe("DELETE_PERSONAL_LINK_EVENT");
    Broadcast.unsubscribe("FOCUS_SAVE_BUTTON_PERSONAL_LINK_DETAIL");
    Broadcast.unsubscribe("ON_BACK_PERSONAL_LINK_DETAIL");
  }

  checkForAvailableSlugChange(prevProps) {
    const { slug } = this.props;
    const { slug: prevAvailableSlug } = prevProps;

    if (slug !== prevAvailableSlug) {
      this.setState({ slug });
    }
  }

  onChangeSlug(e) {
    const { onChangeSlug } = this.props;
    if (!onChangeSlug) {
      return;
    }

    const slug = getInputStringFromEvent(e);
    this.setState({ slug, displayBadge: true }, () => {
      onChangeSlug(slug);
    });
  }

  render() {
    return (
      <div
        id={PERSONAL_LINK_DETAIL_CONTAINER}
        className="personal-link-detail-page-wrapper relative overflow-x-hidden"
        ref={this.containerRef}
      >
        <PreloadResources
          resources={[PERSONAL_LINK_LABEL_MORE_INFO_SCREENSHOT]}
        />
        {this.renderNameAndBackButton()}

        <div id={CONTAINER}>{this.determineRender()}</div>

        {this.renderBottomPadding()}

        {this.renderSaveButton()}

        {this.renderModal()}
        {this.renderOverlappingModal()}
      </div>
    );
  }

  determineRender() {
    return (
      <div className="personal-link-detail-container">
        {this.renderLabel()}
        {this.renderNickname()}
        {this.renderURL()}
        {this.renderDivider()}
        {this.renderWhen()}
        {this.renderDivider()}
        {this.renderSetting()}
        {this.renderDivider()}
        {this.renderNotificationDetail()}
        {this.renderDivider()}
        {this.renderEventDetail()}
        {this.renderDivider()}
        {this.renderCustomQuestionsError()}
        {this.renderCustomQuestions()}
      </div>
    );
  }

  renderDivider() {
    return (
      <ColoredLine inputClassName={"my-5 -ml-5"} width={"360px"} />
    );
  }

  renderCustomQuestionsError() {
    const { customQuestions, title } = this.state;
    if (
      !doesListOfQuestionsIncludeCompany(customQuestions) &&
      doesTitleIncludeCompanyQuestion(title)
    ) {
      return (
        <div className="default-font-size warning-color mt-1">
          {"The \"{{Company_name}}\" variable is used in the title. Please check the Calendar Invite section for potential errors."}
        </div>
      );
    }

    return null;
  }

  getSlugWarningText() {
    if (!showCustomizeUsername()) {
      return null;
    }

    const { hasValidSlug, isFetchingSlug } = this.props;
    const { slug } = this.state;
    // isAvailable, isFetching, slug
    if (!slug || slug.length < MINIMUM_USER_NAME_LENGTH) {
      return `URL name has to be ${MINIMUM_USER_NAME_LENGTH} or more characters`;
    } else if (slug > MAX_USER_NAME_LENGTH) {
      return `URL name can not be more than ${MAX_USER_NAME_LENGTH} characters`;
    } else if (isFetchingSlug) {
      return null;
    } else if (!hasValidSlug) {
      return "URL name is unavailable";
    }

    return null;
  }

  getBookingLink() {
    const { slug, token } = this.state;
    const { masterAccount } = this.props.masterAccount;
    const { userName } = getUserName({ masterAccount, user: this.getUser() });

    if (userName && slug) {
      return `https://book.vimcal.com/p/${userName}/${slug}`;
    }

    return `https://book.vimcal.com/p/${token}`;
  }

  renderNickname() {
    return (
      <div className="flex flex-col">
        <div className="link-detail-label flex items-center">
          Label Nickname (only you can see)
          {this.renderNickNameMoreInfo()}
        </div>

        <input
          autoFocus={false}
          id={INPUT_NICKNAME}
          ref={this.nickname}
          className="event-form-title-and-close-input"
          tabIndex={3}
          value={this.state.nickname}
          style={{
            fontWeight: 300,
            fontSize: 12,
            width: CONTENT_WIDTH,
            marginBottom: 16,
          }}
          onChange={this.onChangeNickname}
          onKeyDown={this.onKeyDown}
          placeholder="Event nickname"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />
      </div>
    );
  }

  renderNickNameMoreInfo() {
    const {
      isDarkMode,
    } = this.props;
    return (
      <div
        className="ml-1 flex relative hoverable-display-none-parent"
      >
        <MoreInfoIcon />
        <img
          alt=""
          className="w-72 rounded-lg absolute hoverable-display-none-child"
          style={{
            right: -58,
            top: 20,
            backgroundColor: "white",
            zIndex: 999,
            border: isDarkMode ? getDefaultModalBorder(isDarkMode) : undefined,
          }}
          src={PERSONAL_LINK_NICKNAME_MORE_INFO_SCREENSHOT}
        />
      </div>
    );
  }
  renderLabelMoreInfo() {
    return (
      <div
        className="ml-1 flex relative hoverable-display-none-parent"
      >
        <MoreInfoIcon />
        <img
          alt=""
          className="rounded-lg absolute hoverable-display-none-child"
          style={{
            right: -268,
            top: 20,
            width: 320,
            backgroundColor: "white",
            zIndex: 999,
          }}
          src={PERSONAL_LINK_LABEL_MORE_INFO_SCREENSHOT}
        />
      </div>
    );
  }

  renderChangeURLMoreInfo() {
    if (this.state.displayBadge || this.props.isNew) {
      return null;
    }

    return (
      <div
        className="ml-1"
        onMouseEnter={this.onMouseEnterURLMoreInfo}
        onMouseLeave={this.onMouseRemoveURLMoreInfo}
      >
        <MoreInfoIcon />
      </div>
    );
  }

  renderURL() {
    if (!showCustomizeUsername()) {
      return null;
    }
    const { slug, displayBadge, showChangeURLHint } = this.state;
    const warning = this.getSlugWarningText();
    const { isFetchingSlug, isDarkMode } = this.props;
    return (
      <div className="my-3">
        <div className="link-detail-label flex items-baseline">
          <div className="flex items-center">
            URL
            {this.renderChangeURLMoreInfo()}
            {showChangeURLHint ? (
              <div
                className={classNames(
                  "absolute more-information-modal color-default-text-color font-weight-300",
                )}
                style={{
                  left: 80,
                  top: 200,
                  width: 255,
                  backgroundColor: isDarkMode ? "#44475A" : "white",
                }}
              >
                Changing the URL will mean that outstanding copied links will no
                longer work and will need to be updated.
              </div>
            ) : null}
          </div>

          {displayBadge ? (
            <ApprovalBadgeWithLoadingSpinner
              isFetching={isFetchingSlug}
              warning={warning}
              spinnerLocationClassName={"slug-badge-location"}
            />
          ) : null}
        </div>

        <input
          className="default-input-field mt-2.5 height-34px"
          value={slug ?? ""}
          onChange={this.onChangeSlug}
          style={{ width: CONTENT_WIDTH }}
        />

        {warning && !isFetchingSlug ? (
          <div className="font-size-10 warning-color mt-1">{warning}</div>
        ) : (
          <div
            className={classNames(
              "default-font-size secondary-text-color mt-1",
              "truncate-text",
            )}
            style={{ maxWidth: CONTENT_WIDTH }}
          >
            {`${this.getBookingLink()}`}
          </div>
        )}
      </div>
    );
  }

  renderLabel() {
    const { displayEmptyName } = this.state;
    return (
      <div>
        <div className="link-detail-label flex items-center">
          Label
          {this.renderLabelMoreInfo()}
        </div>

        <input
          autoFocus={false}
          id={INPUT_NAME}
          ref={this.name}
          className="event-form-title-and-close-input"
          tabIndex={3}
          value={this.state.name}
          style={{
            fontWeight: 300,
            fontSize: 12,
            width: CONTENT_WIDTH,
            marginBottom: displayEmptyName ? 4 : 20,
          }}
          onChange={this.onChangeName}
          onKeyDown={this.onKeyDown}
          placeholder="Event Name"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />
        {displayEmptyName ? (
          <div className="default-font-size warning-color mb-2">
            Please enter in a name
          </div>
        ) : null}
      </div>
    );
  }

  renderBottomPadding() {
    if (!this.state.shouldPinCopyButton) {
      return null;
    }

    return <div style={{ height: 200 }}></div>;
  }

  renderSaveButton() {
    const {
      shouldPinCopyButton,
    } = this.state;
    return (
      <div
        className={
          this.state.shouldPinCopyButton
            ? "create-availability-copy-button-wrapper-pinned"
            : "create-availability-copy-button-wrapper"
        }
      >
        <SaveButton
          className="event-form-save-button"
          style={{
            width: shouldPinCopyButton
              ? CONTENT_WIDTH
              : "calc(100% - 40px)",
            marginLeft: shouldPinCopyButton ? 20 : 0,
            height: "43px",
            marginBottom: 20,
          }}
          disabled={this.getSlugWarningText() || !this.state.hasLinkChanged}
          onClick={this.saveLink}
          buttonText={
            this.props.isNew ? "Create Personal Link" : "Update Personal Link"
          }
          doNotParseText={this.state.isSubmitting}
          shortcut={
            this.state.isSubmitting
              ? determineSaveButtonShortcut(
                this.state.isSubmitting,
                this.props.isMac,
              )
              : null
          }
          focusRef={(input) => {
            this.saveButton = input;
          }}
          tabIndex={14}
        />
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderTimeZone() {
    return (
      <div className="margin-top-twenty">
        <div className="link-detail-label">Time Zone</div>

        <SelectTimeZoneInput
          inputClassName="personal-link-select-time-zone"
          timeZone={this.state.timeZone}
          onChange={this.setTimeZone}
        ></SelectTimeZoneInput>
      </div>
    );
  }

  renderNameAndBackButton() {
    return (
      <div className="select-availability-panel-wrapper justify-between pl-2.5">
        <div className="create-availability-select-availability-title">
          {this.determineTitle()}
        </div>
        <div
          className="close-button-wrapper padding-bottom-0px-important"
          onClick={this.onExitPersonalLinkDetails}
        >
          <X
            size={16}
            className="clickable-icon hoverable-secondary-text-color"
          />
        </div>
      </div>
    );
  }

  determineTitle() {
    return this.props.isNew ? "New Personal Link" : "Update Personal Link";
  }

  renderOverlappingModal() {
    return (
      <EventModalPopup
        maxHeight={"100%"}
        isOpen={this.state.isOverlappingModalOpen}
        width={this.state.overlappingModalWidth}
        onRequestClose={this.closeOverlappingModal}
        title={this.determineModalTitle()}
        style={determineDefaultModalStyle(this.props.isDarkMode)}
      >
        {this.determineOverlappingModalContent()}
      </EventModalPopup>
    );
  }

  renderModal() {
    return (
      <EventModalPopup
        maxHeight={"100%"}
        isOpen={true} // always a modal open, just depends on what is open at the moment
        width={this.state.modalWidth}
        height={this.isPreviewModal() ? "100%" : undefined}
        onRequestClose={this.closeModal}
        title={this.determineModalTitle()}
        style={this.determineModalStyle()}
        hideCloseButton={this.isPreviewModal()}
      >
        {this.determineModalContent()}
      </EventModalPopup>
    );
  }

  determineOverlappingModalContent() {
    const { overlappingModalContent, customQuestions, selectedQuestionIndex } =
      this.state;
    switch (overlappingModalContent) {
      case OVERLAPPING_MODAL_CONTENT.BLOCKED_CALENDARS:
        return (
          <div>
            <SelectBlockedCalendars
              containerClassName="personal-link-modal-content-overflow-container"
              inputBlockedCalendars={this.state.blockedCalendars}
              blockedCalendarsID={this.state.blockedCalendarsID}
              onChangeCalendarIDs={(updatedBlockedCalendarsID) => {
                this.setState({
                  blockedCalendarsID: updatedBlockedCalendarsID,
                  updateCount: this.state.updateCount + 1,
                });
              }}
              isFullScreen={true}
              onlyShowLoggedInCalendars={this.props.isNew}
            />
            {this.renderOverlappingModalOkButton()}
          </div>
        );
      case ModifyCustomQuestionMode.Add:
        return (
          <ModifyCustomQuestionModal
            closeModal={this.closeOverlappingModal}
            applyChange={(question) => {
              this.setState({
                customQuestions: [...customQuestions, question],
                customQuestionCounter: this.state.customQuestionCounter + 1,
              });
            }}
            isAdd={true}
          />
        );
      case ModifyCustomQuestionMode.Edit:
        return (
          <ModifyCustomQuestionModal
            closeModal={this.closeOverlappingModal}
            applyChange={(question) => {
              const updatedCustomQuestions = [...customQuestions];
              updatedCustomQuestions[selectedQuestionIndex] = question;
              this.setState({
                customQuestions: updatedCustomQuestions,
                customQuestionCounter: this.state.customQuestionCounter + 1,
              });
            }}
            selectedCustomQuestion={customQuestions[selectedQuestionIndex]}
          />
        );
      case CONFIRM_DISCARD_MODAL:
        return (
          <SaveAndCancelButton
            onClose={this.closeOverlappingModal}
            onConfirm={this.confirmDiscard}
            confirmButtonText={"Discard"}
            autoFocusConfirmButton={true}
          />
        );
      default:
        return null;
    }
  }

  determineModalContent() {
    const { updateCount, customQuestionCounter, openSection } = this.state;
    if (this.isPreviewModal()) {
      const getPreviewInformation = () => {
        return {
          ...this.constructLinkData()?.personal_link,
          isPersonalLink: true,
          selectedUser: this.getUser(),
          isInEditPersonalLink: true,
        };
      };
      return (
        <div className="personal-link-edit-weekly-calendar-modal-container">
          <AvailabilityLink
            previewInformation={getPreviewInformation()}
            updateCount={updateCount}
            preventScheduling={true}
            customQuestionCounter={customQuestionCounter} // for when questin question has changeds
            isPreviewingCustomQuestions={openSection === OPEN_SECTION.CUSTOM_QUESTIONS}
          />
        </div>
      );
    }
    if (this.isModalWeeklyCalendar()) {
      return this.renderWeeklyCalendar();
    }

    if (this.isModalError()) {
      return <GenericErrorModalContent onClose={this.closeModal} />;
    }
    return null;
  }

  renderOverlappingModalOkButton() {
    return (
      <div className="flex justify-end mt-5">
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={this.closeOverlappingModal}
          label={"Done"}
        />
      </div>
    );
  }

  determineOverlappingModalTitle() {
    const { overlappingModalContent } = this.state;
    if (
      overlappingModalContent === OVERLAPPING_MODAL_CONTENT.BLOCKED_CALENDARS
    ) {
      return "Blocked Calendars";
    }
    if (overlappingModalContent === ModifyCustomQuestionMode.Add) {
      return "Add question";
    }
    if (overlappingModalContent === ModifyCustomQuestionMode.Edit) {
      return "Edit question";
    }
    return null;
  }

  determineModalTitle() {
    if (this.isModalConfirmDiscard()) {
      return "Are you sure you want to discard this draft?";
    }
    if (this.isModalError()) {
      return "Error occured";
    }
    if (this.isPreviewModal()) {
      return "Personal Link Preview";
    }
    if (this.isModalWeeklyCalendar()) {
      return "Drag Bookable Times";
    }
    return "Personal Link";
  }

  determineModalStyle() {
    if (this.isPreviewModal()) {
      return {
        overlay: {
          position: "fixed",
          inset: `${calculateMarginTop(
            this.props.shouldShowTopBar,
          )}px 360px 0px 0px`,
          backgroundColor: "transparent",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {
          padding: "20px",
          position: "absolute",
          bottom: "auto",
          zIndex: 5,
          backgroundColor: this.props.isDarkMode
            ? StyleConstants.darkModeModalBackgroundColor
            : "white",
          color: this.props.isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : "",
          width: "100%",
          left: "0px",
          top: "0px",
          height: "100%",
          ...getSidePoppedOverModalBorder(this.props.isDarkMode),
          borderRadius: "0px",
        },
      };
    }

    return determineDefaultModalStyle(this.props.isDarkMode);
  }

  isModalConfirmDiscard() {
    return this.state.overlappingModalContent === CONFIRM_DISCARD_MODAL;
  }

  isModalError() {
    return this.state.modalContent === ERROR_MODAL;
  }

  isPreviewModal() {
    return this.state.modalContent === PREVIEW_MODAL;
  }

  isModalWeeklyCalendar() {
    return this.state.modalContent === WEEKLY_CALENDAR_MODAL;
  }

  renderWeeklyCalendar() {
    const calendarComponents = {
      event: CustomEvent,
      week: {
        header: this.renderCustomDayOfWeekHeader,
      },
    };

    const CALENDAR_TIME_FORMATS = {
      timeGutterFormat: "ha",
      dayFormat: (date) => {
        return date.toISOString();
      },
      // dayRangeHeaderFormat: 'D MMMM dddd',
      eventTimeRangeFormat: _.noop,
      selectRangeFormat: this.timeRangeFormat,
    };

    return (
      <div>
        <div className="rbc-modal-calendar-container">
          <DnDCalendar
            className={classNames(
              "availability-weekly-calendar remove-rbc-day-header-click",
            )}
            events={this.state.slots}
            defaultView={"week"}
            localizer={this.state.localizer}
            toolbar={false}
            components={calendarComponents}
            onSelectSlot={this.determineOnSelectSlotMethod}
            draggableAccessor={this.determineDraggableAccessor}
            resizableAccessor={this.determineDraggableAccessor}
            step={15}
            timeslots={4}
            selectable={true}
            slotPropGetter={this.getShadedWorkHours}
            startAccessor="eventStart"
            endAccessor="rbcEventEnd"
            allDayAccessor="displayAsAllDay"
            showMultiDayTimes={true}
            dayLayoutAlgorithm={defaultCustomDayLayout}
            formats={CALENDAR_TIME_FORMATS}
            eventPropGetter={this.eventStyleGetter}
            onEventDrop={this.onEventChange}
            onEventResize={this.onEventChange}
            scrollToTime={this.determineInitialScroll()}
            resizable
          />
        </div>

        <div className="flex justify-end mt-5">
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={this.closeModal}
            label={"Done"}
          />
        </div>
      </div>
    );
  }

  getShadedWorkHours(date) {
    const { masterAccount } = this.props.masterAccount;
    return defaultSlotGetter({
      date,
      workHours: getWorkHours({ masterAccount, user: this.getUser() }),
    });
  }

  getUser() {
    return this.props.selectedUser ?? this.props.currentUser;
  }

  timeRangeFormat(slots) {
    const { start, end } = slots;
    const formatStyle = getDateTimeFormat(this.props.format24HourTime);
    return `${format(start, formatStyle)} - ${format(end, formatStyle)}`;
  }

  renderCustomDayOfWeekHeader(info) {
    return (
      <div className="select-personal-link-custom-day-header">
        {format(info.date, "EEE").toUpperCase()}
      </div>
    );
  }

  renderWhen() {
    const {
      openSection,
    } = this.state;

    const isAvailabilityOpen = openSection === OPEN_SECTION.AVAILABILITY;
    const subText = addAbbrevationToTimeZone({timeZone: this.state.timeZone, inputDate: new Date()});

    const onToggleExpand = () => {
      this.onToggleExpand(OPEN_SECTION.AVAILABILITY);
    };

    return (
      <div className={classNames(isAvailabilityOpen ? "" : "hoverable-secondary-text-color")}>
        {this.renderExpandableSectionHeader({
          title: "Availability",
          isOpen: isAvailabilityOpen,
          onToggle: onToggleExpand,
        })}
        {isAvailabilityOpen ?
          this.renderExpandedWhen()
          : this.renderExpandableSectionHiddenSubtext({subText, onClick: onToggleExpand})}
      </div>
    );
  }

  renderExpandedWhen() {
    const onClick = () => {
      this.setState({
        modalContent: WEEKLY_CALENDAR_MODAL,
        modalWidth: "80vw",
      });
    };

    return (
      <div>
        <PersonalLinkDetailsPanel title="" onClick={onClick}>
          {this.determineWhenContent()}
        </PersonalLinkDetailsPanel>
        {this.renderWarning(
          "At least one available slot need to be selected",
          this.state.shouldDisplaySlotWarning && isEmptyArrayOrFalsey(this.state.slots),
        )}
        {this.renderTimeZone()}
      </div>
    );
  }

  renderWarning(warning, condition = false) {
    if (!condition) {
      return null;
    }

    return (
      <div
        className="create-event-time-warning"
        style={{
          marginLeft: 0,
          marginTop: 10,
          userSelect: "none",
        }}
      >
        {warning}
      </div>
    );
  }

  renderEventDetail() {
    const onToggleExpand = () => {
      this.onToggleExpand(OPEN_SECTION.CALENDAR_INVITE);
    };
    const isCalendarInviteOpen = this.state.openSection === OPEN_SECTION.CALENDAR_INVITE;
    const {
      attendees,
      location,
      conferencing,
      duration,
    } = this.state;

    const getSubText = () => {
      const getWhereText = () => {
        if (location) {
          return location;
        }
        if (conferencing?.value
          && conferencing?.label
        ) {
          return conferencing.label;
        }
        return "";
      };
      const durationText = determineDurationString(duration);
      const whereText = getWhereText();
      const attendeesText = !isEmptyArrayOrFalsey(attendees) ? `${attendees.length} other ${pluralize(attendees.length, "attendee")}` : "";
      return [
        durationText,
        whereText,
        attendeesText,
      ].filter(Boolean).join(" | ");
    };

    return (
      <div className={classNames(isCalendarInviteOpen ? "" : "hoverable-secondary-text-color")}>
        {this.renderExpandableSectionHeader({
          title: "Calendar Invite",
          isOpen: isCalendarInviteOpen,
          onToggle: onToggleExpand,
        })}
        {isCalendarInviteOpen ?
          <PersonalLinkEventForm
            user={this.getUser()}
            onClickBack={_.noop}
            title={this.state.title}
            duration={this.state.duration}
            attendees={this.state.attendees}
            conferencing={this.state.conferencing}
            location={this.state.location}
            description={this.state.description}
            saveEventDetail={this.saveLinkDetail}
            customQuestions={this.state.customQuestions}
          />
          : this.renderExpandableSectionHiddenSubtext({subText: getSubText(), onClick: onToggleExpand})}
      </div>
    );
  }

  renderCustomQuestions() {
    const {
      customQuestions,
      title,
      customQuestionCounter,
    } = this.state;
    const isCustomQuestionsOpen = this.state.openSection === OPEN_SECTION.CUSTOM_QUESTIONS;

    const onToggleExpand = () => {
      this.onToggleExpand(OPEN_SECTION.CUSTOM_QUESTIONS);
    };

    return (
      <div className={classNames(isCustomQuestionsOpen ? "" : "hoverable-secondary-text-color")}>
        {this.renderExpandableSectionHeader({
          title: "Custom Questions",
          isOpen: isCustomQuestionsOpen,
          onToggle: onToggleExpand,
        })}
        {isCustomQuestionsOpen ?
          <div className="flex flex-col gap-2.5">
            <CustomQuestions
              showModifyQuestionModal={this.showModifyQuestionModal}
              questions={customQuestions}
              onSelectIndex={this.setSelectedQuestionIndex}
              deleteCustomQuestion={this.deleteCustomQuestion}
              setQuestions={(customQuestions) => {
                this.setState({ customQuestions, customQuestionCounter: customQuestionCounter + 1 });
              }}
              showWarningForCompany={
                !doesListOfQuestionsIncludeCompany(customQuestions) &&
                doesTitleIncludeCompanyQuestion(title)
              }
              isFullWidth={true}
            />
          </div>
          : this.renderExpandableSectionHiddenSubtext({subText: "Add custom questions", onClick: onToggleExpand})}
      </div>
    );
  }

  renderNotificationDetail() {
    if (
      !shouldShowEmailReminders(
        this.props.selectedUser ?? this.props.currentUser,
      )
    ) {
      return;
    }
    const {
      enabledEmailReminders,
    } = this.state;

    const isNotificationSettingsOpen = this.state.openSection === OPEN_SECTION.NOTIFICATION_SETTINGS;

    const onToggleExpand = () => {
      this.onToggleExpand(OPEN_SECTION.NOTIFICATION_SETTINGS);
    };

    return (
      <div className={classNames(isNotificationSettingsOpen ? "" : "hoverable-secondary-text-color")}>
        {this.renderExpandableSectionHeader({
          title: "Notifications",
          isOpen: isNotificationSettingsOpen,
          onToggle: onToggleExpand,
        })}
        {isNotificationSettingsOpen ?
          <NotificationSettingsPage
            onEnableEmailReminders={this.onEnableEmailReminders}
            enabledEmailReminders={this.state.enabledEmailReminders}
            inputReminderEmails={this.state.reminderEmails}
            saveSetting={(setting) => this.saveLinkDetail(setting, false)}
          />
          : this.renderExpandableSectionHiddenSubtext({
            subText: `Email reminders ${enabledEmailReminders ? "enabled" : "disabled"}`,
            onClick: onToggleExpand,
          })}
      </div>
    );
  }

  renderEventCustomQuestionsContent() {
    return (
      <div>
        {this.state.customQuestions.map((question, i) => {
          return (
            <div
              key={`custom-question-${i}`}
              className={i === 0 ? "" : "personal-link-event-detail-info"}
            >
              <span>{"\u2022"}</span>

              <span>&nbsp;</span>
              {question.description}
              {question.required ? " *" : ""}
            </div>
          );
        })}
      </div>
    );
  }

  onToggleExpand(section) {
    const {
      openSection,
    } = this.state;

    // if same section, close it. Otherwise, set openSection to the section
    this.setState({
      openSection: openSection === section ? null : section,
    });
  }

  renderSetting() {
    const {
      openSection,
      blockedCalendarsID,
    } = this.state;

    const isScheduleSettingsOpen = openSection === OPEN_SECTION.SCHEDULE_SETTINGS;

    const onToggleExpand = () => {
      this.onToggleExpand(OPEN_SECTION.SCHEDULE_SETTINGS);
    };
    return (
      <div className={classNames(isScheduleSettingsOpen ? "" : "hoverable-secondary-text-color")}>
        {this.renderExpandableSectionHeader({
          title: "Scheduling Settings",
          isOpen: isScheduleSettingsOpen,
          onToggle: onToggleExpand,
        })}
        {isScheduleSettingsOpen ?
          <PersonalLinkSettingsPage
            daysForward={this.state.daysForward}
            bufferBefore={this.state.bufferBefore}
            bufferAfter={this.state.bufferAfter}
            bufferFromNow={this.state.bufferFromNow}
            saveSetting={this.saveLinkDetail}
            onClickSetBlockingCalendar={this.onClickSetBlockingCalendar}
            blockedCalendars={this.state.blockedCalendars}
            blockedCalendarsID={this.state.blockedCalendarsID}
            shouldDisplayBufferPastDaysForwardWarning={this.shouldDisplayBufferPastDaysForwardWarning()}
            isIgnoreConflicts={this.state.isIgnoreConflicts}
            isIgnoreInternalConflicts={this.state.isIgnoreInternalConflicts}
            user={this.getUser()}
          />
          : this.renderExpandableSectionHiddenSubtext({subText: isEmptyArrayOrFalsey(blockedCalendarsID) ? "No blocked calendars!" : "Buffers and conflicts", onClick: onToggleExpand})}
      </div>
    );
  }

  renderExpandableSectionHiddenSubtext({subText, onClick}) {
    return (
      <div
        className="mr-4 w-full select-none default-font-size font-weight-300 truncate max-width-320px"
        onClick={onClick}
      >
        {subText}
      </div>
    );
  }

  renderExpandableSectionHeader({
    title,
    isOpen,
    onToggle,
  }) {
    return (
      <div className="mb-1">
        <div
          className={classNames("mr-4 w-full select-none default-font-size font-weight-400 mt-2", "flex items-center justify-between", "cursor-pointer")}
          onClick={onToggle}
        >
          <div className="default-font-color">{title}</div>
          <ExpandIconWithAnimation isOpen={isOpen} />
        </div>
      </div>
    );
  }

  getBlockedCalendarIDs() {
    const { blockedCalendarsID, isIgnoreConflicts } = this.state;
    if (isIgnoreConflicts) {
      return [];
    }
    return (
      blockedCalendarsID ??
      getBlockedCalendarsID(this.getUserBlockedCalendars())
    );
  }

  renderBlockedCalendarsSection() {
    const { blockedCalendars } = this.state;

    return (
      <BlockedCalendarSection
        blockedCalendars={
          blockedCalendars ?? this.getUserBlockedCalendars()
        }
        blockedCalendarsID={this.getBlockedCalendarIDs()}
        hideEdit={true}
        skipHover={true}
        titleClassNameOverride={"mr-2"}
        isInModal={true}
      />
    );
  }

  getUserBlockedCalendars() {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getBlockedCalendars({ user: this.getUser(), allLoggedInUsers, allCalendars, masterAccount });
  }

  determineBufferText() {
    if (!this.state.bufferBefore && !this.state.bufferAfter) {
      return "No buffer between events";
    }

    let returnString = "";

    if (this.state.bufferBefore) {
      returnString = `${this.state.bufferBefore} ${pluralize(
        this.state.bufferBefore,
        "min",
      )} buffer before`;

      if (this.state.bufferAfter) {
        returnString = returnString + " and ";
      }
    }

    if (this.state.bufferAfter) {
      returnString =
        returnString +
        `${this.state.bufferAfter} ${pluralize(
          this.state.bufferAfter,
          "min",
        )} buffer after`;
    }

    return returnString;
  }

  determinebufferFromNowText() {
    if (
      isEmptyObjectOrFalsey(this.state.bufferFromNow) ||
      (this.state.bufferFromNow?.days === 0 &&
        this.state.bufferFromNow?.hours === 0 &&
        this.state.bufferFromNow?.minutes === 0)
    ) {
      return "No buffer for scheduling";
    }

    const dayString =
      this.state.bufferFromNow?.days !== 0
        ? `${this.state.bufferFromNow?.days}d`
        : "";

    const hourString =
      this.state.bufferFromNow?.hours !== 0
        ? `${this.state.bufferFromNow?.hours}h`
        : "";

    const minuteString =
      this.state.bufferFromNow?.minutes !== 0
        ? `${this.state.bufferFromNow?.minutes}m`
        : "";

    const returnString = `${dayString} ${hourString} ${minuteString} buffer from now`;

    return returnString;
  }

  determineWhenContent() {
    if (isEmptyArrayOrFalsey(this.state.slots)) {
      return (
        <div>
          Drag the slots on the calendar to create bookable times for each day
          of the week.
        </div>
      );
    } else {
      return renderTimeSlotsLine(this.state.content);
    }
  }

  focusSaveButton() {
    this.saveButton && this.saveButton.focus();
  }

  renderTitle() {
    return (
      <div className="display-flex-flex-direction-row">
        <div>{"\u2022"}</div>

        <div>&nbsp;</div>

        <div>
          {this.state.title && this.state.title.length > 0
            ? this.state.title
            : "(No title)"}
        </div>
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  saveLink() {
    let errorState = {};
    if (isEmptyArrayOrFalsey(this.state.slots)) {
      errorState.shouldDisplaySlotWarning = true;
    }
    if (!this.state.name) {
      errorState.displayEmptyName = true;
    }

    if (
      !this.state.duration ||
      convertDurationBackToMinutes(this.state.duration) === 0
    ) {
      errorState.shouldDisplayZeroDurationWarning = true;
    }

    if (!isEmptyObjectOrFalsey(errorState)) {
      this.setState(errorState);
      return;
    }

    if (this.props.isNew) {
      this.createLink();
    } else {
      this.updateLink();
    }
  }

  createLink() {
    const path = "personal_links";
    const url = constructRequestURL(path, isVersionV2());
    const linkData = this.constructLinkData();

    let payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    this.setState({ isSubmitting: true });

    return Fetcher.post(url, payloadData, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          this.showErrorMessage();
          return;
        }

        if (response?.personal_link) {
          Broadcast.publish("UPDATE_PERSONAL_LINKS", { response, isNew: true });

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Created "${response.personal_link.name}"`,
          );
        } else {
          this.showErrorMessage();
        }
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }
        this.showErrorMessage();
      });
  }

  showErrorMessage() {
    this.setState({
      isSubmitting: false,
      modalContent: ERROR_MODAL,
      shouldDisplayModal: true,
    });
  }

  updateLink() {
    const path = `personal_links/${this.state.token}`;
    const url = constructRequestURL(path, isVersionV2());
    const linkData = this.constructLinkData();
    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(linkData),
    };

    this.setState({ isSubmitting: true });

    return Fetcher.patch(url, payloadData, true, getUserEmail(this.getUser()))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response) {
          this.setState({ isSubmitting: false });
          this.showErrorMessage();

          return;
        }

        if (response?.personal_link) {
          Broadcast.publish("UPDATE_PERSONAL_LINKS", { response });

          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `Updated "${response.personal_link.name}"`,
          );
        } else {
          this.showErrorMessage();
        }
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }
        this.showErrorMessage();
      });
  }

  onChangeNickname(e) {
    const nickname = getInputStringFromEvent(e);
    if (nickname.length === 1 && !this.state.hasCapitalizedNickname) {
      this.setState({
        nickname: capitalizeFirstLetter(nickname),
        hasCapitalizedNickname: true,
      });

      return;
    }

    this.setState({ nickname });
  }

  onChangeName(e) {
    const name = getInputStringFromEvent(e);
    if (name.length === 1 && !this.state.hasCapitalizedName) {
      this.setState({
        name: capitalizeFirstLetter(name),
        hasCapitalizedName: true,
        displayEmptyName: false,
      });

      return;
    }

    this.setState({ name, displayEmptyName: false });
  }

  onExitPersonalLinkDetails() {
    if (!this.state.hasLinkChanged) {
      this.props.onClickBack();
      return;
    }
    this.setState(
      { isOverlappingModalOpen: false, overlappingModalContent: CONFIRM_DISCARD_MODAL },
      () => {
        // need to reset event modal otherwise new style does not get applied
        this.setState({
          overlappingModalContent: CONFIRM_DISCARD_MODAL,
          isOverlappingModalOpen: true,
        });
      },
    );
  }

  showModifyQuestionModal(mode) {
    this.setState({
      isOverlappingModalOpen: true,
      overlappingModalContent: mode,
    });
  }

  deleteCustomQuestion(question) {
    const updatedQuestions = this.state.customQuestions.filter(
      (q) => !isSameQuestion(q, question),
    );
    const updatedCounter = this.state.customQuestionCounter + 1;
    this.setState({
      customQuestions: updatedQuestions,
      customQuestionCounter: updatedCounter,
    });
  }

  setSelectedQuestionIndex(index) {
    const {
      customQuestions,
      customQuestionCounter,
    } = this.state;
    const updatedCounter = customQuestionCounter + 1;
    if (!doesListOfQuestionsIncludeCompany(customQuestions)) {
      this.setState({ selectedQuestionIndex: index - 1, customQuestionCounter: updatedCounter });
      return;
    }
    this.setState({ selectedQuestionIndex: index, customQuestionCounter: updatedCounter });
  }

  saveLinkDetail(detail, shouldRetriggerPreviewUpdate = true) {
    this.setState(detail, () => this.saveSlugIfNotEdited(detail));

    if (!shouldRetriggerPreviewUpdate) {
      return;
    }

    clearTimeout(this._updatePreviewTimeout);
    this._updatePreviewTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.setState({ updateCount: this.state.updateCount + 1 });
    }, SECOND_IN_MS);
  }

  saveSlugIfNotEdited(detail) {
    const { hasEditedSlug, setSlug, isNew } = this.props;
    if (hasEditedSlug) {
      // if user has already edited slug -> ignore
      return;
    }
    if (!isNew) {
      // do not automatically change slug if it is not a new link
      return;
    }

    const { token } = this.state;
    const { duration } = detail;
    if (!duration) {
      return;
    }

    const newSlug =
      Object.keys(duration)
        .reduce((out, k) => {
          const value = duration[k];
          return !!value && value > 0 ? [...out, `${value}${k}`] : out;
        }, [])
        .join("-") +
      "-" +
      token.slice(-5);
    setSlug(newSlug);
  }

  setTimeZone(timeZone) {
    if (!isValidTimeZone(timeZone)) {
      return;
    }
    if (this.isPreviewModal()) {
      // need to recalculate
      this.setState({ timeZone, modalContent: null }, () => {
        this.setState({ modalContent: PREVIEW_MODAL });
      });
      return;
    }

    this.setState({ timeZone });
  }

  onKeyDown(event) {
    blurOnEscape(event, INPUT_NAME);
    blurOnEscape(event, INPUT_NICKNAME);
  }

  onClickAddTimes() {
    this.setState({
      shouldDisplayModal: true,
      modalContent: WEEKLY_CALENDAR_MODAL,
    });
  }

  closeOverlappingModal() {
    this.setState({
      isOverlappingModalOpen: false,
    });
  }

  closeModal() {
    this.setState({
      shouldDisplayModal: false,
      modalContent: PREVIEW_MODAL,
      modalWidth: undefined,
    });
  }

  confirmDiscard() {
    this.closeModal();
    this.props.onClickBack();
  }

  onEventChange(e) {
    let { start, end, event } = e;

    if (!isBeforeMinute(start, end)) {
      return;
    }

    let updatedEvent = this.createTemporaryEvent(start, end, false);

    let updatedEventsList = this.state.slots.filter(
      (t) => t.id !== getClientEventID(event),
    );
    updatedEventsList = updatedEventsList.concat(updatedEvent);

    this.setState({ slots: updatedEventsList }, this.unfocusWeeklyCalendar);
  }

  determineOnSelectSlotMethod(slot) {
    const { duration, slots } = this.state;
    const { start, end } = slot;
    let slotEnd = end;
    const durationInMinutes = convertDurationBackToMinutes(duration);
    const slotDuration = differenceInMinutes(end, start);
    if (slotDuration < durationInMinutes) {
      slotEnd = addMinutes(start, durationInMinutes);
    }

    const availableSlots = this.createTemporaryEvent(start, slotEnd, false);

    const updatedAvailableSlots = slots.concat(availableSlots);

    if (isSelectedSlotAllDayEvent(slot)) {
      return;
    }
    this.setState({ slots: updatedAvailableSlots }, this.unfocusWeeklyCalendar);
  }

  //=================
  // PRIVATE METHODS
  //=================

  handleWindowSizeChange() {
    if (this.windowSizeChangeTimer) {
      clearTimeout(this.windowSizeChangeTimer);

      this.windowSizeChangeTimer = null;
    }

    this.windowSizeChangeTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.setState({ shouldPinCopyButton: this.shouldPinCopyButton() });
    }, 500);
  }

  unfocusWeeklyCalendar() {
    let element = document.getElementsByClassName(
      "ReactModal__Overlay ReactModal__Overlay--after-open",
    )[0];

    element && element.click();
  }

  shouldPinCopyButton() {
    let innerHeight = window.innerHeight;

    let eventDom = document.getElementById(CONTAINER);

    if (!eventDom) {
      return true;
    }

    let contentHeight = eventDom.getBoundingClientRect().height;

    contentHeight = contentHeight + 260;
    eventDom = null;

    return innerHeight < contentHeight;
  }

  determineDraggableAccessor() {
    return true;
  }

  deleteAvailableEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    let updatedAvailableSlots = this.state.slots.filter(
      (t) => getClientEventID(t) !== getClientEventID(event),
    );

    this.setState({ slots: updatedAvailableSlots });
  }

  scrollToTop() {
    this.containerRef.current.scrollIntoView();
  }

  onClickSetBlockingCalendar() {
    this.setState({
      isOverlappingModalOpen: true,
      overlappingModalContent: OVERLAPPING_MODAL_CONTENT.BLOCKED_CALENDARS,
    });
  }

  constructNewPersonalLinkSlots() {
    // 9am to 5pm
    const startHour = 9;
    const endHour = 17;
    /*
    {
      "Monday": [{start: "9", end: "17"}]
      "Friday"...
    }
    */
    return this.formatAvailableSlotsIntoArray({
      Monday: [{ start: { hour: startHour }, end: { hour: endHour } }],
      Tuesday: [{ start: { hour: startHour }, end: { hour: endHour } }],
      Wednesday: [{ start: { hour: startHour }, end: { hour: endHour } }],
      Thursday: [{ start: { hour: startHour }, end: { hour: endHour } }],
      Friday: [{ start: { hour: startHour }, end: { hour: endHour } }],
    });
  }

  constructInitialState(props) {
    const { isNew, personalLinkInfo } = props;
    const { masterAccount } = props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const formattedAvailableSlots =
      !!personalLinkInfo &&
      this.formatAvailableSlotsIntoArray(
        personalLinkInfo.slots,
        personalLinkInfo.time_zone,
      );
    const formattedDuration =
      !!personalLinkInfo && reformatMinDuration(personalLinkInfo.duration);
    
    const blockedCalendars =
      !isNew && !!personalLinkInfo
        ? getBlockingCalendarsPersonalLink({
          personalLink: props.personalLinkInfo,
          masterAccount,
          user: this.getUser(),
          allCalendars,
          allLoggedInUsers,
        })
        : getNewPersonalLinkSelectedUserAndCalendar({
          allCalendars,
          user: this.getUser(),
          masterAccount,
          allLoggedInUsers,
        });

    const getIgnoreInternalConflicts = () => {
      if (isNew) {
        return false;
      }
      return getPersonalLinkIsIgnoreInternalConflicts({
        personalLink: personalLinkInfo,
        masterAccount,
        user: this.getUser(),
      });
    };

    const reminderEmails =
      isNew || isEmptyObjectOrFalsey(personalLinkInfo.email_reminders)
        ? DEFAULT_REMINDER_EMAILS
        : personalLinkInfo.email_reminders;

    const defaultNewAvailabilityText = this.createTextFromSelection(
      this.constructNewPersonalLinkSlots(),
      true,
    ); // also used to compare times in collapsed when
    return {
      customQuestionCounter: 0,
      defaultNewAvailabilityText,
      shouldDisplayModal: true,
      localizer: determineRBCLocalizer(
        parseInt(props.weekStart || 0),
        props.dateFieldOrder,
      ),
      slots: isNew
        ? this.constructNewPersonalLinkSlots()
        : formattedAvailableSlots,
      customQuestions:
        isNew || isEmptyObjectOrFalsey(personalLinkInfo.custom_questions)
          ? DEFAULT_CUSTOM_QUESTIONS
          : personalLinkInfo.custom_questions,
      content: isNew
        ? defaultNewAvailabilityText
        : this.createTextFromSelection(formattedAvailableSlots, true),
      duration: isNew ? { hours: 0, minutes: 30 } : formattedDuration,
      scrollToTime: startOfHour(setHours(new Date(), 7)),
      timeZone: isNew
        ? getDefaultUserTimeZone({
          masterAccount,
          user: this.getUser(),
        })
        : personalLinkInfo.time_zone,
      name: isNew ? "30 Minute Meeting" : personalLinkInfo.name,
      daysForward: isNew ? 14 : personalLinkInfo.days_forward,
      bufferBefore: isNew ? 0 : personalLinkInfo.buffer_before,
      bufferAfter: isNew ? 0 : personalLinkInfo.buffer_after,
      bufferFromNow: getBufferDaysHoursMinutesFromMinutes(
        isNew ? 0 : personalLinkInfo.buffer_from_now,
      ),
      shouldPinCopyButton: true,
      hasCapitalizedName: false,
      hasCapitalizedNickname: false,
      shouldDisplaySlotWarning: false,
      isSubmitting: false,
      title: isNew
        ? createDefaultPersonalLinkEventSummary({
          user: this.getUser(),
          masterAccount,
        })
        : personalLinkInfo.title,
      conferencing: isNew
        ? determineConferencingFromCurrentUser(this.getUser(), allCalendars)
        : conferenceOptionFromBackend(
          personalLinkInfo.conferencing,
          this.getUser(),
        ),
      description: isNew ? "" : personalLinkInfo.description,
      attendees: isNew ? [] : personalLinkInfo.attendees,
      location: isNew ? "" : personalLinkInfo.location,
      token: isNew
        ? generateAvailabilityToken(this.getUser())
        : personalLinkInfo.token,
      hasLinkChanged: isNew,
      modalContent: PREVIEW_MODAL,
      selectedQuestionIndex: null,
      blockedCalendars: blockedCalendars,
      blockedCalendarsID: getBlockedCalendarsID(blockedCalendars),
      slug: isNew ? props.slug : personalLinkInfo.slug,
      displayBadge: false,
      showChangeURLHint: false,
      isIgnoreConflicts: isNew
        ? false
        : personalLinkInfo.ignore_conflicts ?? false,
      isIgnoreInternalConflicts: getIgnoreInternalConflicts(),
      nickname: isNew
        ? ""
        : getPersonalLinkInternalLabel(personalLinkInfo, false),
      enabledEmailReminders: isNew
        ? false
        : personalLinkInfo.enabled_reminder_emails ?? false,
      reminderEmails: shouldShowEmailReminders(
        props.selectedUser ?? props.currentUser,
      )
        ? reminderEmails
        : null,
      openSection: OPEN_SECTION.AVAILABILITY,
      updateCount: 0,
    };
  }

  constructLinkData() {
    const { hasValidSlug, slug } = this.props;

    return {
      personal_link: {
        slots: this.createAvailableSlots(),
        name: this.state.name,
        time_zone: this.state.timeZone,
        buffer_before: this.state.bufferBefore,
        buffer_after: this.state.bufferAfter,
        buffer_from_now: convertBufferDaysHoursMinutesToMinutes(
          this.state.bufferFromNow,
        ),
        days_forward: this.state.daysForward,
        title: this.state.title,
        conferencing: this.state.conferencing.value,
        description: this.state.description,
        attendees: this.state.attendees,
        location: this.state.location,
        duration: convertDurationBackToMinutes(this.state.duration),
        token: this.state.token || generateAvailabilityToken(this.getUser()),
        custom_questions: this.state.customQuestions,
        blocked_calendar_ids: this.state.blockedCalendarsID,
        slug: hasValidSlug ? slug : undefined,
        ignore_conflicts: this.state.isIgnoreConflicts,
        nickname: this.state.nickname,
        ignore_internal_conflicts:
          this.state.isIgnoreInternalConflicts ?? false,
        email_reminders: filterDuplicateTimings({
          reminderEmails: this.state.reminderEmails,
        }),
        enabled_reminder_emails: this.state.enabledEmailReminders ?? false,
      },
    };
  }

  createAvailableSlots() {
    if (isEmptyArrayOrFalsey(this.state.slots)) {
      return {};
    }

    let slots = {};
    this.state.slots.forEach((a) => {
      let start = a.eventStart;
      let end = a.eventEnd;

      let date = format(start, "EEEE");
      let slot = {
        start: this.createAvailabilitySlotForBackend(start),
        end: this.createAvailabilitySlotForBackend(end),
      };
      if (slots[date]) {
        slots[date] = slots[date].concat(slot);
      } else {
        slots[date] = [slot];
      }
    });

    return slots;
  }

  determineInitialScroll() {
    return this.state.scrollToTime;
  }

  doesTextIncludeTime(text) {
    return text.includes(" - ");
  }

  eventStyleGetter() {
    const style = {
      backgroundColor: StyleConstants.selectAvailabilityColor,
      color: DARK_MODE_BACKGROUND_COLOR,
      borderWidth: "1px",
      borderColor: this.props.isDarkMode
        ? StyleConstants.darkModeBackgroundColor
        : "white",
    };

    return { style };
  }

  determineDayIntegerFromDayOfWeek(dayOfWeek) {
    // 7 is Sunday
    let isSunday = new Date().getDay() === 0;

    if (this.props.weekStart === "0" || this.props.weekStart === 0) {
      // starts on Sunday
      switch (dayOfWeek) {
        case "Sunday":
          return 0;
        case "Monday":
          return 1;
        case "Tuesday":
          return 2;
        case "Wednesday":
          return 3;
        case "Thursday":
          return 4;
        case "Friday":
          return 5;
        case "Saturday":
          return 6;
        default:
          return dayOfWeek;
      }
    } else if (this.props.weekStart === "6" || this.props.weekStart === 6) {
      // starts on Saturday
      switch (dayOfWeek) {
        case "Sunday":
          return 0;
        case "Monday":
          return 1;
        case "Tuesday":
          return 2;
        case "Wednesday":
          return 3;
        case "Thursday":
          return 4;
        case "Friday":
          return 5;
        case "Saturday":
          return -1;
        default:
          return dayOfWeek;
      }
    } else {
      // starts on Monday
      if (isSunday) {
        switch (dayOfWeek) {
          case "Monday":
            return -6;
          case "Tuesday":
            return -5;
          case "Wednesday":
            return -4;
          case "Thursday":
            return -3;
          case "Friday":
            return -2;
          case "Saturday":
            return -1;
          case "Sunday":
            return 0;
          default:
            return dayOfWeek;
        }
      } else {
        switch (dayOfWeek) {
          case "Monday":
            return 1;
          case "Tuesday":
            return 2;
          case "Wednesday":
            return 3;
          case "Thursday":
            return 4;
          case "Friday":
            return 5;
          case "Saturday":
            return 6;
          case "Sunday":
            return 7;
          default:
            return dayOfWeek;
        }
      }
    }
  }

  createTextFromSelection(slots, shouldReturnValue = false) {
    const eventList = addDefaultToArray(slots || this.state.slots);

    if (isEmptyArrayOrFalsey(eventList)) {
      if (shouldReturnValue) {
        return [];
      } else {
        this.setState({ content: "" });
        return;
      }
    }

    const content = createAvailabilityTextFromEvent({
      eventList,
      currentTimeZone: this.props.currentTimeZone,
      format24HourTime: this.props.format24HourTime,
      hideDate: true,
    });

    if (shouldReturnValue) {
      return content;
    }

    this.setState({ content });
  }

  createTemporaryEvent(startTime, endTime, hasSaved = false, index = null) {
    let timeEnd = updateToStartOfNextDayIfLastSlot(endTime);
    return {
      isTemporary: true,
      isPersonalLink: true,
      hasSaved,
      isAvailability: true,
      eventStart: startTime,
      index: isNullOrUndefined(index) ? this.state.slots.length : index,
      eventEnd: timeEnd,
      rbcEventEnd: protectMidnightCarryOver(
        determineRBCEventEndWithEventStart(startTime, timeEnd),
      ),
      backgroundColor: StyleConstants.selectAvailabilityColor,
      status: availability,
      raw_json: { status: availability },
      id: createUUID(),
      isGroupVote: false,
    };
  }

  formatAvailableSlotsIntoArray(slots) {
    if (isEmptyObjectOrFalsey(slots)) {
      return [];
    }

    // sample slots below:
    // const SAMPLE_AVAILABLE_TIMES = {
    //   Monday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}],
    //   Tuesday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}],
    //   Wednesday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}],
    //   Thursday: [],
    //   Friday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}],
    //   Saturday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}],
    //   Sunday: [{start: {hour: 9}, end: {hour: 12, minute: 30}}, {start: {hour: 13}, end: {hour: 17}}]
    // };

    let formattedTimes = [];
    let index = 0;
    Object.keys(slots).forEach((day) => {
      let dayOfWeekSlots = slots[day];

      dayOfWeekSlots.forEach((t) => {
        const date = setDay(
          new Date(),
          this.determineDayIntegerFromDayOfWeek(day),
        );

        const start_utc = createTimeFromDayOfWeek({
          date,
          timeZone: null,
          hour: t.start.hour,
          minute: t.start.minute,
        });
        const end_utc = createTimeFromDayOfWeek({
          date,
          timeZone: null,
          hour: t.end.hour,
          minute: t.end.minute,
        });

        let startJSDate = parseISO(start_utc);
        let endJSDate = parseISO(end_utc);
        if (isAfterMinute(startJSDate, endJSDate)) {
          endJSDate = addDays(endJSDate, 1);
        }

        formattedTimes = formattedTimes.concat(
          this.createTemporaryEvent(startJSDate, endJSDate, true, index),
        );

        index = index + 1;
      });
    });

    return formattedTimes;
  }

  createAvailabilitySlotForBackend(jsDate) {
    return { hour: format(jsDate, "H"), minute: format(jsDate, "m") };
  }

  createAttendeesString() {
    if (isEmptyArrayOrFalsey(this.state.attendees)) {
      return null;
    }

    let returnString = "";

    this.state.attendees.forEach((a, index) => {
      // only add if it's not last one
      let shouldAddComma = index !== this.state.attendees.length - 1;

      if (a.name) {
        returnString = returnString + `${a.name}${shouldAddComma ? ", " : ""}`;
      } else if (a.email) {
        returnString = returnString + `${a.email}${shouldAddComma ? ", " : ""}`;
      }
    });

    return returnString;
  }

  onEnableEmailReminders(enabledEmailReminders) {
    this.setState({ enabledEmailReminders });
  }

  onMouseEnterURLMoreInfo() {
    this.setState({ showChangeURLHint: true });
  }

  onMouseRemoveURLMoreInfo() {
    this.setState({ showChangeURLHint: false });
  }

  shouldDisplayBufferPastDaysForwardWarning() {
    const { daysForward, bufferFromNow } = this.state;
    return bufferFromNow?.days && bufferFromNow.days > daysForward;
  }

  getInternalDomainAndEmails() {
    const { masterAccount } = this.props.masterAccount;
    return getInternalDomainAndEmails({ masterAccount, user: this.getUser() });
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    isDarkMode,
    currentTimeZone,
    format24HourTime,
    isMac,
    shouldShowTopBar,
    weekStart,
    dateFieldOrder,
    emailToNameIndex,
  } = state;

  return {
    currentUser,
    isDarkMode,
    currentTimeZone,
    format24HourTime,
    isMac,
    shouldShowTopBar,
    weekStart,
    dateFieldOrder,
    emailToNameIndex,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const [hasEditedSlug, setHasEditedSlug] = useState(false);
  const [slugCandidate, setSlugCandidate] = useState(
    props?.personalLinkInfo?.slug || "",
  );
  const [token, setToken] = useState("");
  const [hasValidSlug, availableSlug, isFetching] = useSlugCheck({
    slugCandidate,
    token,
    resourceType: "personal_link",
    initialSlug: props?.personalLinkInfo?.slug,
  });

  const onChangeSlug = (newSlug) => {
    setSlugCandidate(newSlug);
    setHasEditedSlug(true);
  };

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      onChangeSlug={onChangeSlug}
      hasValidSlug={hasValidSlug}
      setSlug={setSlugCandidate}
      slug={availableSlug}
      setToken={setToken}
      hasEditedSlug={hasEditedSlug}
      isFetchingSlug={isFetching}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(
  mapStateToProps,
  null,
)(withStore(PersonalLinkDetailPage));
