import React, { Component } from "react";
import { connect } from "react-redux";
import { constructRequestURL } from "../../services/api";
import Broadcast from "../../broadcasts/broadcast";
import { handleError } from "../../services/commonUsefulFunctions";
import Fetcher from "../../services/fetcher";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getAccountCompletedToolTips } from "../../lib/userFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { BROADCAST_VALUES } from "../../lib/broadcastValues";

class TooltipsWrapper extends Component {
  constructor(props) {
    super(props);

    this.markTooltipComplete = this.markTooltipComplete.bind(this);

    Broadcast.subscribe(BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED, this.markTooltipComplete);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    Broadcast.unsubscribe(BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED);

    this._isMounted = false;
  }

  async markTooltipComplete(tooltip) {
    const { currentUser } = this.props;
    const { masterAccount, setMasterAccountCompletedTooltips } = this.props.masterAccount;

    const { email } = currentUser;

    if (!email) {
      return;
    } else if (getAccountCompletedToolTips(masterAccount)?.includes(tooltip)) {
      // already completed
      return;
    }

    /* If successful, the response is the data we want */
    /* { completedTooltips: [] } */
    Fetcher.patch(
      constructRequestURL("tooltips/complete"),
      { body: JSON.stringify({ tooltip }) },
      true,
      email
    )
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response) || response.error) {
          return;
        }

        const { completed_tooltips } = response;
        /* Check if we got data back and if the dropdown should be removed */
        if (completed_tooltips?.length > 0) {
          setMasterAccountCompletedTooltips(completed_tooltips);
        }
      })
      .catch(handleError);
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { currentUser } = state;

  return {
    currentUser,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} />;
};

export default connect(mapStateToProps, null)(withStore(TooltipsWrapper));
