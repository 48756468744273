import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { NEXT, PREVIOUS } from "../../services/googleCalendarService";

export default function StatelessCalendarToolBar({ date, label, onNavigate }) {
  const onClickPrevious = () => {
    onNavigate(PREVIOUS);
  };

  const onClickNext = () => {
    onNavigate(NEXT);
  };

  return (
    <div className="w-full flex justify-center items-center">
      <ChevronLeft
        size={16}
        className="clickable-icon mr-4"
        onClick={onClickPrevious}
      />
      <div className="font-size-14 font-weight-300 select-none">{label}</div>
      <ChevronRight
        size={16}
        className="clickable-icon ml-4"
        onClick={onClickNext}
      />
    </div>
  );
}
