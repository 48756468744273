import React from "react";

type ErrorVariant = "personal" | "executive"

export const AUTH_ERROR_VARIANTS = {
  PERSONAL: "personal",
  EXECUTIVE: "executive",
} as const;

interface AuthErrorModalProps {
  variant: ErrorVariant
}

const ERROR_MESSAGES: Record<ErrorVariant, string> = {
  personal: `
    This personal account cannot be added because an executive account for this email already exists.
    To reconnect, please add a new account and select the "My executive" option.
  `,
  executive: `
    This executive cannot be added because a personal account for this email already exists.
    To reconnect, please add a new account and select the "Myself" option.
  `,
};

export default function AuthErrorModal({ variant }: AuthErrorModalProps) {
  return (
    <div className="default-font-size">
      {ERROR_MESSAGES[variant]}
    </div>
  );
}
