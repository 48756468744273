import { useEffect, useState } from "react";
import {
  handleError,
  guessTimeZone,
  getFirstDayOfWeekJsDate,
  getLastDayOfWeekJsDate,
} from "../commonUsefulFunctions";
import { useSelector } from "react-redux";
import Fetcher from "../fetcher";
import { constructRequestURL } from "../api";
import { formatGCalEventsList } from "../../lib/webWorkerFunctions";
import { useIsMounted } from "./useIsMounted";
import {
  getActiveCalendarsIDsFromAllCalendars,
  getMatchingCalendarsForUser,
  getMatchingUserProviderIDsFromUserCalendarIDs,
} from "../../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
} from "../stores/SharedAccountData";
import { isVersionV2 } from "../versionFunctions";
import { formatEventsArrayForReactBigCalendar } from "../../lib/eventFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { FETCH_CALENDAR_EVENTS_ENDPOINT } from "../../lib/endpoints";
import {
  getCalendarUserCalendarID,
} from "../calendarAccessors";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { getDefaultHeaders } from "../../lib/fetchFunctions";
import { isEmptyObjectOrFalsey } from "../typeGuards";
import { shouldHideDelegatedUser } from "../maestroFunctions";

export default function useMainCalendarEvents({
  currentDate,
  shouldSkipFetchEvents,
  inputTimeZone,
}) {
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const weekStart = useSelector((state) => state.weekStart);
  const componentIsMounted = useIsMounted();
  const [mainCalendarEvents, setMainCalendarEvents] = useState([]);

  const fetchEvents = async (inputDate) => {
    if (shouldSkipFetchEvents) {
      return;
    }
    const upcomingCalendarIDs = getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(currentUser),
    });
    if (upcomingCalendarIDs.length === 0) {
      return;
    }
    let fetchedEvents = [];

    const sendRequest = async (user) => {
      const userEmail = getUserEmail(user);
      const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
        userCalendarIDs: upcomingCalendarIDs,
        userEmail,
        allCalendars,
      });
      if (isEmptyArray(providerIDs)) {
        // nothing to filter for
        return;
      }

      const url = constructRequestURL(
        FETCH_CALENDAR_EVENTS_ENDPOINT,
        isVersionV2()
      );

      const body = {
        timeMin: getFirstDayOfWeekJsDate(
          inputDate ?? currentDate,
          weekStart
        ).toISOString(),
        timeMax: getLastDayOfWeekJsDate(
          inputDate ?? currentDate,
          weekStart
        ).toISOString(),
        timeZone: guessTimeZone(),
        calendarIds: providerIDs,
      };

      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };

      try {
        const response = await Fetcher.post(
          url,
          payloadData,
          true,
          getUserEmail(user),
        );
        if (
          isEmptyObjectOrFalsey(response) ||
          response.error ||
          !componentIsMounted.current
        ) {
          return;
        }

        const formattedEventsList = formatEventsArrayForReactBigCalendar({
          events: response.events,
          currentTimeZone: inputTimeZone ?? guessTimeZone(),
        });
        fetchedEvents = fetchedEvents.concat(formattedEventsList);
      } catch (error) {
        handleError(error);
      }
    };

    let fetchPromises = [];
    allLoggedInUsers.forEach((user) => {
      /* Send backend request for user */
      /* Returns a promise */
      // only send users that are in the list of emails to fetch
      const matchingCalendarsForUser = getMatchingCalendarsForUser({
        allCalendars,
        user,
      });
      if (shouldHideDelegatedUser({ user, allCalendars })) {
        return;
      }
      const isActiveUser = Object.values(matchingCalendarsForUser).some(
        (calendar) =>
          upcomingCalendarIDs.includes(getCalendarUserCalendarID(calendar))
      );
      if (isActiveUser) {
        const fetchPromise = sendRequest(user);
        fetchPromises = fetchPromises.concat(fetchPromise);
      }
    });
    Promise.all(fetchPromises)
      .then(() => {
        setMainCalendarEvents(fetchedEvents);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (inputTimeZone && mainCalendarEvents.length === 0) {
      return;
    }

    const { formatEventsList } = formatGCalEventsList({
      eventList: mainCalendarEvents,
      currentTimeZone: inputTimeZone,
    });

    setMainCalendarEvents(formatEventsList);
  }, [inputTimeZone]);

  useEffect(() => {
    if (shouldSkipFetchEvents) {
      return;
    }

    fetchEvents();
  }, []);

  return [mainCalendarEvents, fetchEvents];
}
