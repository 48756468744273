import { trackEvent } from "../components/tracking";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getUserToken } from "./userFunctions";

const VERSIONS = {
  V2: "v2", // implemented 2 syncWindows and calling calendarsOnly and calendarAndEvents seperately
  V3: "v3", // added checked for isCleanRefresh
};

const VERSION = VERSIONS.V3;
export function trackInitialLoadTimes({
  user,
  delayInSec,
  provider, // outlook or google
  calendarView,
  functionType,
}) {
  if (isEmptyObjectOrFalsey(user)) {
    return;
  }
  trackEvent({
    category: "web_performance_tracking_initial_load",
    action: `fromFunction: ${functionType}, provider: ${provider} || calendarView: ${calendarView} || version: ${VERSION}`,
    label: `${delayInSec}s`,
    userToken: getUserToken(user),
  });
}

export function trackMovingBetweenWindows({
  user,
  delayInSec,
  provider, // outlook or google
  calendarView,
  isAggregate = false, // when all response comes back
}) {
  return;
  if (isEmptyObjectOrFalsey(user)) {
    return;
  }
  trackEvent({
    category: `${isAggregate ? "aggregate_" : ""}web_performance_tracking_moving_between_windows`,
    action: `provider: ${provider} || calendarView: ${calendarView}`,
    label: `${delayInSec}s`,
    userToken: getUserToken(user),
  });
}

export function getTrackingTimeNow() {
  if (performance?.now) {
    return performance.now();
  }
}

export function getDifferenceInPerformanceTrackingInSecs(startTime, endTime) {
  if (!startTime) {
    return "no startTime";
  }
  if (!endTime) {
    return "no endTime";
  }
  return ((endTime - startTime) / 1000).toFixed(2);
}
