import React, { useEffect, useState } from "react";
import classNames from "classnames";
import TagsPill from "./menu/tagsPill";
import TagsPopupEvent from "./menu/tagsPopupEvent";
import {
  DISPLAY_LOCATION_EVENT_FORM,
  DISPLAY_LOCATION_EXPANDED_EVENT,
  DISPLAY_LOCATION_POPUP_EVENT,
  TagsProps,
  SelectorDisplayIndexType,
  Tags as TagsType,
  DISPLAY_LOCATION_AUDIT,
} from "./tagsVariables";
import { useSelector } from "react-redux";

import "../../styles/tags.css";
import {
  filterTagsAgainstSettingsTags,
  getMatchingUserAndTags,
} from "../../lib/tagsFunctions";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";

export type TagsMenuProps = {
  shouldExpandTag?: boolean;
  isReadOnly?: boolean;
  userEmail?: string;
  matchingUser?: any;
  showOnLeftHandSide?: boolean;
} & Omit<
  TagsProps,
  | "filterValue"
  | "setFilterValue"
  | "selectorDisplayIndex"
  | "setSelectorDisplayIndex"
>;

const Tags: React.FC<TagsMenuProps> = (props) => {
  const {
    displayLocation,
    tags,
    setTags,
    shouldExpandTag = false,
    isReadOnly = false,
    userEmail,
    matchingUser,
    showOnLeftHandSide,
  } = props;

  /* TODO: Add types for state */
  const currentUser = useSelector((state: any) => state.currentUser);
  const isDarkMode: boolean = useSelector((state: any) => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state: any) => state.allLoggedInUsers,
  );
  const masterAccount = useMasterAccount((state: any) => state.masterAccount);
  const tagsSetting: TagsType = getMatchingUserAndTags({
    user: matchingUser,
    currentUser,
    allLoggedInUsers,
    userEmail,
    masterAccount,
  });

  const [filterValue, setFilterValue] = useState<string>("");
  const [selectorDisplayIndex, setSelectorDisplayIndex] =
    useState<SelectorDisplayIndexType>({
      displayLocation: shouldExpandTag ? DISPLAY_LOCATION_POPUP_EVENT : null,
      index: null,
    });

  const renderTagsMenu = () => {
    const filteredTags = filterTagsAgainstSettingsTags({
      tags,
      settingsTags: tagsSetting,
    });

    /* Don't place displayLocation in common since each one is different */
    const commonProps = {
      filterValue,
      tags: filteredTags,
      selectorDisplayIndex,
      setFilterValue,
      setTags,
      setSelectorDisplayIndex,
    };

    switch (displayLocation) {
      case DISPLAY_LOCATION_EVENT_FORM:
        return (
          <TagsPill
            {...commonProps}
            displayLocation={displayLocation}
            userEmail={userEmail}
          />
        );
      case DISPLAY_LOCATION_AUDIT:
      case DISPLAY_LOCATION_EXPANDED_EVENT:
        return (
          <TagsPill
            {...commonProps}
            displayLocation={displayLocation}
            isReadOnly={isReadOnly}
            userEmail={userEmail}
          />
        );
      case DISPLAY_LOCATION_POPUP_EVENT:
        return (
          <TagsPopupEvent
            {...commonProps}
            displayLocation={displayLocation}
            userEmail={userEmail}
            showOnLeftHandSide={showOnLeftHandSide}
          />
        );
      default:
        return <></>;
    }
  };

  /* Set selector index when paint colors get added */
  useEffect(() => {
    if (typeof selectorDisplayIndex?.index === "number") {
      if (
        (selectorDisplayIndex.index > tags.length ||
          selectorDisplayIndex.index === -1) &&
        selectorDisplayIndex.index !== tags.length - 1
      ) {
        setSelectorDisplayIndex({
          ...selectorDisplayIndex,
          index: tags.length - 1,
        });
      }
    }
  }, [tags, selectorDisplayIndex]);

  return (
    <div
      className={classNames(
        `paint-colors-container ${displayLocation}`,
        isDarkMode ? "dark-mode" : "",
      )}
    >
      {renderTagsMenu()}
    </div>
  );
};

export default Tags;
