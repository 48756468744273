import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import Broadcast from "../broadcasts/broadcast";
import OnboardingBroadcast from "../broadcasts/onboardBroadcast";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import {
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import { useDispatch, useSelector } from "react-redux";
import { lowerCaseAndTrimString, truncateString } from "../lib/stringFunctions";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { AVAILABILITY_BROADCAST_VALUES } from "../lib/broadcastValues";
import { REDUCER_TYPES } from "../services/reducers";
import { RE_USE_SLOTS_BUTTON_ID } from "../services/elementIDVariables";
import { isActionModeCreateAvailability, isActionModeUpsertEvent } from "../services/appFunctions";

function DisappearingNotificationMessage({ isOnboarding }) {
  const [message, setMessage] = useState(null);
  const [lastSlotsData, setSlotsData] = useState(null); // contains both state and props
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const actionMode = useSelector((state) => state.actionMode);
  const dispatch = useDispatch();

  const messageTimer = useRef(null);
  const componentIsMounted = useIsMounted();
  const setDisappearingMessage = (message) => {
    if (lastSlotsData) {
      setSlotsData(null);
    }

    clearTimeout(messageTimer.current);
    messageTimer.current = null;
    setMessage(message);
    messageTimer.current = setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      clearMessage();
    }, 5 * SECOND_IN_MS);
  };

  const clearMessage = () => {
    setMessage(null);
    clearTimeout(messageTimer.current);
    setSlotsData(null);
  }

  const setReuseSlotsNotification = ({ message, slotsData }) => {
    setSlotsData(slotsData);
    clearTimeout(messageTimer.current);
    messageTimer.current = null;
    setMessage(message);
    messageTimer.current = setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      clearMessage();
    }, 8 * SECOND_IN_MS);
  };

  useEffect(() => {
    if (isActionModeUpsertEvent(actionMode) && lastSlotsData) {
      clearMessage(); // clear message if edit mode is on
    }
  }, [actionMode, lastSlotsData]);

  useEffect(() => {
    if (isOnboarding) {
      OnboardingBroadcast.subscribe(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        setDisappearingMessage
      );
    } else {
      Broadcast.subscribe(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        setDisappearingMessage
      );
    }
    availabilityBroadcast.subscribe(
      AVAILABILITY_BROADCAST_VALUES.REUSE_SLOTS_NOTIFICATION,
      setReuseSlotsNotification
    );

    return () => {
      if (isOnboarding) {
        OnboardingBroadcast.unsubscribe(SET_DISAPPEARING_NOTIFICATION_MESSAGE);
      } else {
        Broadcast.unsubscribe(SET_DISAPPEARING_NOTIFICATION_MESSAGE);
      }
      availabilityBroadcast.unsubscribe(
        AVAILABILITY_BROADCAST_VALUES.REUSE_SLOTS_NOTIFICATION
      );
      clearTimeout(messageTimer.current);
    };
  }, []);

  const positionClassName = () => {
    if (isOnboarding) {
      return message
        ? "onboarding-notification-message"
        : "hidden-notification-message";
    } else {
      return classNames(
        "disappearing-notification-message",
        message ? "disappearing-notification-message-on" : ""
      );
    }
  };

  const onClickReUseSlots = () => {
    if (!lastSlotsData) {
      return; // sanity check
    }
    // remove notifications and clear timer
    setMessage(null);
    clearTimeout(messageTimer.current);

    // reset state
    if (!isActionModeCreateAvailability(actionMode)) {
      dispatch({ type: REDUCER_TYPES.SET_TO_CREATE_AVAILABILITY_MODE });
    }

    setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.SET_SLOTS_STATE_AND_PROPS, lastSlotsData);
      setSlotsData(null);
    }, 0.2 * SECOND_IN_MS);
  };

  const isCopiedSlotsMessage = () => {
    return lowerCaseAndTrimString(message)?.includes("copied") && 
      lowerCaseAndTrimString(message)?.includes("slots");
  };

  return (
    <div
      className={classNames(
        "disappearing-notification-message-container",
        isDarkMode ? "" : "box-shadow-6",
        "large-blur",
        positionClassName(),
        "select-none",
        lastSlotsData ? "max-width-420px-important" : ""
      )}
    >
      {truncateString(message, 56, true)}
      {lastSlotsData && isCopiedSlotsMessage() ? (
        <span
          className="text-color-link ml-4 cursor-pointer"
          onClick={onClickReUseSlots}
          id={RE_USE_SLOTS_BUTTON_ID}
        >
          Reuse Slots
        </span>
      ) : null}
    </div>
  );
}

export default DisappearingNotificationMessage;
