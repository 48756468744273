import React from "react";

const CoffeeCup = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99663 10.4993H16.0016C16.5541 10.4993 17.002 10.9472 17.002 11.4997V14.5009C17.002 17.816 14.3146 20.5034 10.9995 20.5034H8.99872C5.68363 20.5034 2.99622 17.816 2.99622 14.5009V11.4997C2.99622 10.9472 3.44412 10.4993 3.99663 10.4993Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5006 7.49818C12.8337 6.79788 12.8337 5.69743 13.5006 4.99714C14.1676 4.29685 14.1676 3.19638 13.5006 2.49609"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99917 7.49818C9.33223 6.79788 9.33223 5.69743 9.99917 4.99714C10.6661 4.29684 10.6661 3.19639 9.99917 2.49609"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.49771 7.49818C5.83076 6.79789 5.83076 5.69743 6.49771 4.99714C7.16465 4.29684 7.16465 3.19639 6.49771 2.49609"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1295 17.6172L17.9761 17.1946C19.7476 16.7892 21.0038 15.2132 21.0038 13.3958V13.3958C21.0038 12.6276 20.6986 11.8909 20.1554 11.3477C19.6122 10.8044 18.8754 10.4993 18.1072 10.4993H15.5015"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CoffeeCup;
