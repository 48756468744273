import React, { useEffect, useState } from "react";
import GrabAvailableSlotsModal from "./modal/grabAvailableSlotsModal";
import { getModalBackgroundColor } from "../services/globalVariables";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { MODAL_TYPES } from "../lib/modalFunctions";
import MetricsIsReadyModal from "./modal/metricsIsAvailableModal";
import modalBroadcast from "../broadcasts/modalBroadcast";
import TutorialWizardButton from "./tutorialWizardButton";
import ReferBoostModal from "./modal/referBoostModal";
import { MODAL_BROADCAST_VALUES } from "../lib/broadcastValues";
import CalendarAuditReadyModal from "./modal/bottomLeft/calendarAudit";

export default function BottomLeftModalContainer({ isParentModalOpen }) {
  const [modalContent, setModalContent] = useState(null);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onClose = () => {
    setModalContent(null);
  };

  useEffect(() => {
    modalBroadcast.subscribe(MODAL_BROADCAST_VALUES.SET_BOTTOM_MODAL_CONTENT, setModalContent);
    modalBroadcast.subscribe("REMOVE_BOTTOM_MODAL_CONTENT", onClose);
    return () => {
      modalBroadcast.unsubscribe(MODAL_BROADCAST_VALUES.SET_BOTTOM_MODAL_CONTENT);
      modalBroadcast.unsubscribe("REMOVE_BOTTOM_MODAL_CONTENT");
    };
  }, []);

  if (isParentModalOpen) {
    return null;
  }

  switch (modalContent) {
    case MODAL_TYPES.FIND_FREE_TIMES:
      return renderGetAvailabilityModal({
        isDarkMode,
        onClose,
      });
    case MODAL_TYPES.METRICS_IS_AVAILABLE_MODAL:
      return renderMetricsIsAvailableModal({
        isDarkMode,
        onClose,
      });
    case MODAL_TYPES.REFER_BOOST:
      return renderReferBoostModal({ isDarkMode, onClose });
    case MODAL_TYPES.CALENDAR_AUDIT:
      return <CalendarAuditReadyModal isDarkMode={isDarkMode} onClose={onClose} />;
    default:
      return <TutorialWizardButton />;
  }
}

function renderReferBoostModal({ isDarkMode, onClose }) {
  return createPortal(
    <div
      className={classNames(
        "fixed bottom-8 left-8 z-10",
        "large-blur",
        "rounded-lg",
        "default-bottom-left-modal-border",
      )}
      style={{
        backgroundColor: getModalBackgroundColor(isDarkMode),
      }}
    >
      <ReferBoostModal onClose={onClose} />
    </div>,
    document.body,
  );
}

function renderGetAvailabilityModal({ isDarkMode, onClose }) {
  return createPortal(
    <div
      className={classNames(
        "fixed bottom-8 left-8 z-10",
        "large-blur",
        "rounded-lg",
        "default-bottom-left-modal-border",
      )}
      style={{
        width: "255px",
        backgroundColor: getModalBackgroundColor(isDarkMode),
      }}
    >
      <GrabAvailableSlotsModal onClose={onClose} />
    </div>,
    document.body,
  );
}

function renderMetricsIsAvailableModal({ isDarkMode, onClose }) {
  return createPortal(
    <div
      className={classNames(
        "fixed bottom-8 left-8 z-10",
        "large-blur",
        "rounded-lg",
        "default-bottom-left-modal-border",
      )}
      style={{
        width: "294px",
        backgroundColor: getModalBackgroundColor(isDarkMode),
      }}
    >
      <MetricsIsReadyModal onClose={onClose} />
    </div>,
    document.body,
  );
}
