import React, { useCallback, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import usePresignedUpload, { PRESIGNED_UPLOAD_STATUS, PresignedUploadStatus } from "../services/customHooks/usePresignedUpload";
import AttachmentPreview from "./attachmentPreview";
import { getUserEmail } from "../lib/userFunctions";

interface FeedbackAttachmentPreviewProps {
  detachFile: (s3Key: string) => void
  file: File
  index: number
  hoveredFileIndex: number | null
  s3Key: string
  setHoveredFileIndex: React.Dispatch<React.SetStateAction<number | null>>
  updateAttachmentStatus: (status: PresignedUploadStatus) => void
}

/**
 * This wrapper includes logic to upload to S3 with a presigned url as soon as a file is selected.
 * Once mounted, do not change the values for the `file` or `s3Key` props.
 */
export default function FeedbackAttachmentPreview({
  detachFile,
  file,
  hoveredFileIndex,
  index,
  s3Key,
  setHoveredFileIndex,
  updateAttachmentStatus,
}: FeedbackAttachmentPreviewProps) {
  const currentUser = useSelector((state) => state.currentUser);

  const presignedUploadPayload = useMemo(() => {
    return { key: s3Key };
  }, [s3Key]);

  const { presignedUpload, presignedUploadStatus } = usePresignedUpload({
    payloadData: presignedUploadPayload,
    path: "feedback/attachment_upload_url",
    userEmail: getUserEmail(currentUser),
    v2: false,
  });

  const startUpload = useCallback(() => {
    presignedUpload(file);
  }, [presignedUpload]);

  // Set the status in the parent component so the form knows when all files have been uploaded.
  useEffect(() => {
    updateAttachmentStatus(presignedUploadStatus);
  }, [presignedUploadStatus]);

  // Once the presigned url is ready, start the upload process.
  useEffect(() => {
    if (presignedUploadStatus !== PRESIGNED_UPLOAD_STATUS.READY) {
      return;
    }
    startUpload();
  }, [presignedUploadStatus, startUpload]);

  return (
    <AttachmentPreview
      isUploading={presignedUploadStatus !== PRESIGNED_UPLOAD_STATUS.SUCCESS && presignedUploadStatus !== PRESIGNED_UPLOAD_STATUS.ERROR}
      fileErrors={presignedUploadStatus === "error" ? ["There was an error uploading this file."] : undefined}
      file={file}
      handleRetryFileUpload={startUpload}
      hoveredFileIndex={hoveredFileIndex}
      index={index}
      setHoveredFileIndex={setHoveredFileIndex}
      onClickRemove={() => detachFile(s3Key)}
    />
  );
}
