import React, { Component, useEffect, useRef } from "react";
import GoogleCalendarService, {
} from "../services/googleCalendarService";
import { connect } from "react-redux";
import {
  isUserOnlyPersonWhoAcceptedAndRestDeclined,
  getEmailBasedOnCalendarId,
  hasEventPreventDefault,
  isEditable,
  isEventInThePast,
  createFadedColorIfIndexDoesNotExist,
  dimDarkModeColor,
} from "../services/commonUsefulFunctions";
import { withRouter } from "react-router-dom";
import StyleConstants, {
  CAN_NOT_UPDATE_EVENT_MESSAGE,
  FADED_GREY_TEXT_COLOR,
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  DARK_MODE_BACKGROUND_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import GoogleColors from "../services/googleColors";
import { format, isSameMinute } from "date-fns";
import {
  determinedMergedEventBackgroundColor,
  isMergedEvent,
  isColorTooDarkAgainstDarkBackground,
  shouldShowTransparentMergedBackground,
  isOutOfOfficeEvent,
  isAvailabilityEvent,
  getSelfAttendingStatus,
  isTemporaryEvent,
  determineEventColor,
  doesEventSpanMultipleDays,
  isWorkplaceEvent,
  isEventWorkLocationHome,
  isEventWorkLocationOffice,
  isAllDayWorkLocationEvent,
  isSpecialEvent,
  determineOutlookCategoryColor,
} from "../lib/eventFunctions";
import classNames from "classnames";
import { Home, MapPin, Plus, Printer, UserX } from "react-feather";
import {
  useHideRightHandSidebar,
  useIsMouseMoving,
} from "../services/stores/appFunctionality";
import Broadcast from "../broadcasts/broadcast";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  determineCalendarColor,
  getMatchingUserFromEvent,
} from "../lib/calendarFunctions";
import {
  getClientEventID,
  getEventBackgroundColor,
  getEventColorID,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import {
  getEventClientRect,
  isActionModeUpsertEvent,
  isEventFormShowing,
  isInActionMode,
  shouldTruncateRightHandPanel,
} from "../services/appFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { getMatchingUIUserForEvent, getSmartTagString } from "../lib/tagsFunctions";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { isTemporaryAIEvent } from "../lib/availabilityFunctions";
import { getEventTagColor } from "../lib/tagsFunctions";
import { isFindTimeEventFormEvent } from "../lib/temporaryEventFunctions";
import {
  getWorkLocationBackgroundColor,
  getWorkLocationTextColor,
} from "../lib/workLocationFunctions";
import { getDefaultBackgroundColor, shouldShowTransparentEventBackgroundInMonthlyView } from "../lib/styleFunctions";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { shouldDimPastEvents } from "../lib/settingsFunctions";
import { getDateTimeFormatLowercaseAMPM } from "../lib/dateFunctions";
import { preventHoverExpandedEvent } from "../lib/featureFlagFunctions";

class CustomMonthEvent extends Component {
  constructor(props) {
    super(props);

    this._cachedEventInfo = {};

    this.setEventOnHoverTimer = null;
    this.isMouseHoverAllowed = false;
    this._mouseOverTimer = null;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.allowMouseOverTimer();
  }

  // can't use hasStateOrPropsChanged here because weekly calendar sends down some props that aren't immutable
  shouldComponentUpdate(newProps, newState) {
    if (this.props.event.uniqueEtag !== newProps.event.uniqueEtag) {
      return true;
    } else if (isActionModeUpsertEvent(newProps.actionMode) !== isActionModeUpsertEvent(this.props.actionMode)) {
      return true;
    } else if (
      this.props.event.isTemporary &&
      (!isSameMinute(this.props.event.eventStart, newProps.event.eventStart) ||
        !isSameMinute(this.props.event.eventEnd, newProps.event.eventEnd))
    ) {
      return true;
    } else if (
      this.props.event.summaryUpdatedWithVisibility !==
      newProps.event.summaryUpdatedWithVisibility
    ) {
      return true;
    } else if (newProps.isDarkMode !== this.props.isDarkMode) {
      return true;
    } else if (this.props.currentUser !== newProps.currentUser) {
      return true;
    } else if (
      shouldDimPastEvents({
        masterAccount: this.props.masterAccount.masterAccount,
      }) !==
      shouldDimPastEvents({
        masterAccount: newProps.masterAccount.masterAccount,
      })
    ) {
      return true;
    } else if (
      shouldTruncateRightHandPanel(this.props.hideRightHandSidebar) !==
      shouldTruncateRightHandPanel(newProps.hideRightHandSidebar)
    ) {
      return true;
    } else if (
      !isSameMinute(this.props.event.eventStart, newProps.event.eventStart)
    ) {
      return true;
    } else if (this.props.format24HourTime !== newProps.format24HourTime) {
      return true;
    } else if (
      this.props.temporaryStateStore.hoveredEventID !==
      newProps.temporaryStateStore.hoveredEventID
    ) {
      return true;
    } else if (
      getSmartTagString(this.props.event) !== getSmartTagString(newProps.event)
    ) {
      return true;
    } else if (this.props.allLoggedInUsers !== newProps.allLoggedInUsers) {
      return true;
    } else if (this.props.allCalendars !== newProps.allCalendars) {
      return true;
    } else if (this.props.currentTimeZone !== newProps.currentTimeZone) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      shouldTruncateRightHandPanel(this.props.hideRightHandSidebar) !==
      shouldTruncateRightHandPanel(prevProps.hideRightHandSidebar)
    ) {
      this.isMouseHoverAllowed = false;
      this.allowMouseOverTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setEventOnHoverTimer);

    this.setEventOnHoverTimer = null;
    this._cachedEventInfo = null;
    this._isMounted = false;
  }

  render() {
    const { isEventAvailibilityEvent, displayWarning } =
      this.determineEventInfo();

    return (
      <div
        id={getEventUserEventID(this.props.event)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={`custom_event_${getEventUserEventID(this.props.event)}`}
        className={classNames(
          this.isAllDayWorkLocationEvent()
            ? "height-initial"
            : "height-100-percent",
          isEventAvailibilityEvent ? "position-relative" : "",
          this.hideLabel() ? "text-transparent-important duration-200" : "",
          determinedMergedEventBackgroundColor(this.props.event),
          shouldShowTransparentMergedBackground(this.props.event)
            ? "margin-top-negative-1"
            : ""
        )}
        onContextMenu={this.onContextMenu}
      >
        {this.renderContent(displayWarning)}
      </div>
    );
  }

  isAllDayWorkLocationEvent() {
    return isAllDayWorkLocationEvent(this.props.event);
  }

  renderContent(displayWarning) {
    if (this.isAllDayWorkLocationEvent()) {
      return this.renderAllDayWorkingLocationEvent();
    }

    if (this.hideLabel()) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <Plus size={12} className="text-dark-mode-background-color" />
        </div>
      );
    }
    return this.renderSingleLineEvent(displayWarning);
  }

  renderAllDayWorkingLocationEvent() {
    const color = this.getEventColor();
    const { isDarkMode } = this.props;
    const backgroundColor = getWorkLocationBackgroundColor({
      color,
      isDarkMode,
      shouldDim: this.shouldDim(),
    });
    return (
      <div className="flex items-center">
        <div
          className={classNames(
            "h-4",
            "w-max",
            "rounded-3xl",
            "px-1.5",
            "flex items-center",
            "flex-shrink-0"
          )}
          style={{ backgroundColor }}
        >
          {this.renderSingleLineEvent()}
        </div>
        <div className="h-1 w-full" style={{ backgroundColor }}></div>
      </div>
    );
  }

  renderEventIcon() {
    const { event } = this.props;
    const { isDarkMode } = this.props;
    if (isOutOfOfficeEvent(event)) {
      return (
        <UserX size={10} className="mr-1" strokeWidth={3} />
      );
    }
    if (isWorkplaceEvent(event)) {
      const shouldDim = this.shouldDim();
      const color = getWorkLocationTextColor({
        color: this.getEventColor(),
        isDarkMode,
        shouldDim,
      });
      if (isEventWorkLocationHome(event)) {
        return (
          <Home
            size={10}
            className="mr-1 h-full"
            strokeWidth={3}
            color={color}
          />
        );
      }
      if (isEventWorkLocationOffice(event)) {
        return (
          <Printer
            size={10}
            className="mr-1 h-full"
            strokeWidth={3}
            color={color}
          />
        );
      }
      return (
        <MapPin
          size={10}
          className="mr-1 h-full"
          strokeWidth={3}
          color={color}
        />
      );
    }
    return null;
  }

  isSpecialEvent() {
    return isSpecialEvent(this.props.event);
  }

  hideLeftDot() {
    return (
      this.isDisplayAsAllDayEvent() ||
      doesEventSpanMultipleDays(this.props.event) ||
      this.isSpecialEvent()
    );
  }

  renderSingleLineEvent(displayWarning) {
    const {
      event,
      format24HourTime,
    } = this.props;
    const hasTransparentBackgroundColor = shouldShowTransparentEventBackgroundInMonthlyView({
      event,
      isInMonthlyView: true
    }) || shouldShowTransparentMergedBackground(event);
    const fontColor = this.determineTextColor(hasTransparentBackgroundColor);
    const { isDarkMode } = this.props;
    const shouldDim = this.shouldDim();
    const style =
      isWorkplaceEvent(event) && this.isDisplayAsAllDayEvent()
        ? {
            color: getWorkLocationTextColor({
              color: this.getEventColor(),
              isDarkMode,
              shouldDim,
            }),
          }
        : undefined;

    return (
      <div
        className={classNames(
          this.isAllDayWorkLocationEvent()
            ? "all-day-work-location-text-wrapper"
            : "custom-event-display-single-line-event",
          "margin-top-0px-override",
          determinedMergedEventBackgroundColor(event),
          shouldShowTransparentMergedBackground(event)
            ? "padding-top-1"
            : ""
        )}
        style={style}
      >
        {this.hideLeftDot() ? null : (
          <span className="pt-1">{this.renderEventButtonColor()}</span>
        )}

        {this.renderEventIcon()}

        {!event.allDay &&
          !(event.isTemporary && this.isDisplayAsAllDayEvent()) && (
            <span
              className={
                "custom-event-display-single-line-event font-weight-300 margin-top-0px-override"
              }
              style={{ color: fontColor }}
            >
              {format(
                event.start || event.eventStart,
                getDateTimeFormatLowercaseAMPM(format24HourTime)
              )}
            </span>
          )}

        <span
          className={classNames(
            "font-weight-400 ml-1",
            this.isAllDayWorkLocationEvent() ? "" : "margin-top-5 "
          )}
          style={{ color: fontColor }}
        >
          {displayWarning ? this.renderWarning() : null}
          {event.summaryUpdatedWithVisibility}
        </span>
      </div>
    );
  }

  hideLabel() {
    const { hoveredEventID } = this.props.temporaryStateStore;
    return (
      this.isResponsiveToHoverEventID() &&
      !isNullOrUndefined(hoveredEventID) &&
      hoveredEventID === getClientEventID(this.props.event)
    );
  }

  determineMergedEventColor() {
    return getDefaultBackgroundColor(this.props.isDarkMode);
  }

  renderEventButtonColor() {
    let color = "transparent";

    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const { event, currentUser } = this.props;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    color = determineEventColor({
      event,
      allCalendars,
      user: this.getMatchingUIUserForEvent(),
      currentUser,
      allLoggedInUsers,
      outlookCategories,
      masterAccount,
      where: "customMonthEvent",
    });

    let selfAttendingStatus = this.props.event.selfAttendingStatus;

    if (this.shouldDim()) {
      color = this.determineBackgroundColor(color, true);
    }

    return (
      <div
        style={{
          height: 7,
          width: 7,
          borderColor: color,
          borderWidth: 1,
          backgroundColor:
            selfAttendingStatus ===
            GoogleCalendarService.attendee_event_attending
              ? color
              : "transparent",
          borderRadius: "100%",
          borderStyle: "solid",
          display: "inline-table",
        }}
      ></div>
    );
  }

  renderWarning() {
    const color = this.getEventColor();
    const shouldDim = this.shouldDim();
    return (
      <span
        className="warning-only-attendee"
        style={{
          backgroundColor: this.determineBackgroundColor(color, shouldDim),
          color:
            this.props.isDarkMode && shouldDim
              ? FADED_GREY_TEXT_COLOR_DARK_MODE
              : null,
        }}
      >
        !
      </span>
    );
  }

  getMatchingUIUserForEvent() {
    const { event } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  isEventColorDifferentThanCalendar({ tags }) {
    const { event, currentUser } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    return (
      getEventColorID(event) ||
      getEventBackgroundColor(event) ||
      determineOutlookCategoryColor({
        allCalendars,
        event,
        outlookCategories,
      }) ||
      getEventTagColor({
        event,
        user: this.getMatchingUIUserForEvent(),
        currentUser,
        allLoggedInUsers,
        masterAccount,
        allCalendars,
      }) ||
      tags?.length > 0
    );
  }

  //================
  // EVENT HANDLERS
  //================

  isTemporaryAIEvent() {
    return isTemporaryAIEvent(this.props.event);
  }

  isFindTimeEventFormEvent() {
    return isFindTimeEventFormEvent(this.props.event);
  }

  onMouseEnter() {
    if (this.isResponsiveToHoverEventID()) {
      const { setHoveredEventID } = this.props.temporaryStateStore;
      setHoveredEventID(getClientEventID(this.props.event));
      return;
    }

    if (!this.isMouseHoverAllowed) {
      // to prevent startling mouse enters
      return;
    }
    const {
      masterAccount
    } = this.props.masterAccount;

    if (preventHoverExpandedEvent({user: this.props.currentUser, masterAccount})) {
      return;
    }

    if (
      isTemporaryEvent(this.props.event) ||
      isAvailabilityEvent(this.props.event)
    ) {
      return;
    }

    if (this.shouldSetHover()) {
      this.setEventOnHoverTimer && clearTimeout(this.setEventOnHoverTimer);
      this.setEventOnHoverTimer = null;

      this.setEventOnHoverTimer = setTimeout(() => {
        if (!this._isMounted || this.props.isMobileView) {
          return;
        }

        if (shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
          const eventClientRect = getEventClientRect(this.props.event, true);
          if (eventClientRect) {
            this.props.setHoverPopupView({
              location: eventClientRect,
              event: this.props.event,
            });
          }
        } else {
          this.props.setCurrentHoverEvent(this.props.event);
        }
      }, StyleConstants.timeOnMouseEnterBeforeEvent);
    }
  }

  shouldSetHover() {
    return (
      !this.props.event.isTemporary &&
      isEmptyObjectOrFalsey(this.props.currentPreviewedEvent) &&
      !isInActionMode(this.props.actionMode) &&
      this.props.isMouseMovingRef.current.isMouseMoving
    );
  }

  onMouseLeave() {
    if (this.isResponsiveToHoverEventID()) {
      const { resetHoveredEventID } = this.props.temporaryStateStore;
      resetHoveredEventID();
      return;
    }

    if (!this.props.event.isTemporary) {
      if (!isEventFormShowing()) {
        mainCalendarBroadcast.publish("REMOVE_HOVER_EVENT");
      }

      if (this.setEventOnHoverTimer) {
        clearTimeout(this.setEventOnHoverTimer);

        this.setEventOnHoverTimer = null;
      }
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  onContextMenu(e) {
    hasEventPreventDefault(e);
    const { actionMode, event } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;

    const { allCalendars } = this.props.allCalendars;

    const isEventEditable = isEditable({
      event,
      allCalendars,
      actionMode,
      reverseSlotsText,
    });

    if (!isEventEditable) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        CAN_NOT_UPDATE_EVENT_MESSAGE
      );
      return;
    }

    const eventClientRect = getEventClientRect(this.props.event);
    if (eventClientRect) {
      this.props.setPopupView({
        location: eventClientRect,
        event: this.props.event,
        isColor: true,
        clickLocation: { X: e.pageX, Y: e.pageY },
      });
    }
  }

  determineEventInfo() {
    if (
      !!this.props.event.uniqueEtag &&
      !!this._cachedEventInfo[this.props.event.uniqueEtag]
    ) {
      return this._cachedEventInfo[this.props.event.uniqueEtag];
    }

    const isEventAvailibilityEvent = isAvailabilityEvent(this.props.event);

    const email = getEmailBasedOnCalendarId(
      this.props.event,
      this.props.allCalendars.allCalendars
    );
    const selfAttendingStatus = email
      ? getSelfAttendingStatus(this.props.event, email)
      : null;
    const displayWarning = isUserOnlyPersonWhoAcceptedAndRestDeclined(
      this.props.event,
      selfAttendingStatus
    );

    const result = {
      isEventAvailibilityEvent,
      email,
      selfAttendingStatus,
      displayWarning,
    };

    if (this.props.event.uniqueEtag) {
      this._cachedEventInfo[this.props.event.uniqueEtag] = result;
    }

    return result;
  }

  shouldDim() {
    const { masterAccount } = this.props.masterAccount;
    const { event, currentTimeZone } = this.props;
    return (
      isEventInThePast({ event, currentTimeZone }) &&
      shouldDimPastEvents({ masterAccount })
    );
  }

  isDisplayAsAllDayEvent() {
    return this.props.event?.displayAsAllDay;
  }

  determineTextColor(hasTransparentBackgroundColor) {
    if (isWorkplaceEvent(this.props.event) && this.isDisplayAsAllDayEvent()) {
      return undefined;
    }
    if (this.shouldDim()) {
      return this.props.isDarkMode
        ? FADED_GREY_TEXT_COLOR_DARK_MODE
        : FADED_GREY_TEXT_COLOR;
    }
    if (hasTransparentBackgroundColor) {
      // use default font color
      return undefined;
    }

    if (
      isMergedEvent(this.props.event) &&
      this.props.event.normalColor
    ) {
      if (!this.props.isDarkMode && hasTransparentBackgroundColor) {
        // blank background and white text
        return DARK_MODE_BACKGROUND_COLOR;
      } else if (this.props.isDarkMode && hasTransparentBackgroundColor) {
        return "white";
      }

      return isColorTooDarkAgainstDarkBackground(this.props.event.normalColor)
        ? "white"
        : DARK_MODE_BACKGROUND_COLOR;
    } else {
      // default text color
      return undefined;
    }
  }

  determineBackgroundColor(color, isPastEvent) {
    if (!isPastEvent) {
      return color;
    }

    if (this.props.isDarkMode) {
      return dimDarkModeColor(color);
    }

    return createFadedColorIfIndexDoesNotExist(color, true);
  }

  allowMouseOverTimer() {
    this._mouseOverTimer && clearTimeout(this._mouseOverTimer);

    this._mouseOverTimer = setTimeout(() => {
      if (this._isMounted) {
        this.isMouseHoverAllowed = true;
      }
    }, 800);
  }

  isResponsiveToHoverEventID() {
    return this.isTemporaryAIEvent() || this.isFindTimeEventFormEvent();
  }

  getEventColor() {
    const { event } = this.props;
    if (event.color) {
      return event.color;
    }
    if (getEventColorID(event)) {
      return GoogleColors.primaryEventsColors[getEventColorID(event)].color;
    }
    const { allCalendars } = this.props.allCalendars;
    const userCalendarID = getEventUserCalendarID(event);
    return determineCalendarColor(allCalendars[userCalendarID]);
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    shouldShowGlobalKeyMap,
    currentPreviewedEvent,
    format24HourTime,
    isDarkMode,
    isMobileView,
    currentTimeZone,
    actionMode,
  } = state;

  return {
    currentUser,
    shouldShowGlobalKeyMap,
    currentPreviewedEvent,
    format24HourTime,
    isDarkMode,
    isMobileView,
    currentTimeZone,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "SET_CURRENT_HOVER_PREVIEWED_EVENT" }),
    removeCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    setPopupView: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    setHoverPopupView: (event) =>
      dispatch({ data: event, type: "SET_HOVER_POPUP_EVENT" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const isMouseMovingRef = useRef(useIsMouseMoving.getState().isMouseMoving);
  // transient update through zustand
  // https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(
    () =>
      useIsMouseMoving.subscribe(
        (isMouseMoving) => (isMouseMovingRef.current = isMouseMoving),
        (state) => state.isMouseMoving
      ),
    []
  );

  const allCalendars = useAllCalendars();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const masterAccount = useMasterAccount();
  const temporaryStateStore = useTemporaryStateStore();
  const allLoggedInUsers = useAllLoggedInUsers();
  const outlookCategoriesStore = useOutlookCategoriesStore();

  return (
    <BaseComponent
      {...props}
      isMouseMovingRef={isMouseMovingRef}
      allCalendars={allCalendars}
      hideRightHandSidebar={hideRightHandSidebar}
      masterAccount={masterAccount}
      temporaryStateStore={temporaryStateStore}
      allLoggedInUsers={allLoggedInUsers}
      outlookCategoriesStore={outlookCategoriesStore}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(CustomMonthEvent))
);
