import React, { useState } from "react";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { RSVP_STATUS } from "../../services/googleCalendarService";
import { CustomSelect } from "../select";
import DropdownIndicator from "../select/dropDownIndicator";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { customMenuStyle, getReactSelectBaseStyle } from "../select/styles";

const RSVP_OPTIONS = [
  { value: RSVP_STATUS.ATTENDING, label: "Yes" },
  { value: RSVP_STATUS.MAYBE, label: "Maybe" },
  { value: RSVP_STATUS.DECLINED, label: "No" },
];
export default function AddGoogleRSVPNote({
  onClose,
  currentNote,
  onSave,
  currentRSVP,
}) {
  const [note, setNote] = useState(currentNote ?? "");
  const [rsvp, setRSVP] = useState(currentRSVP ?? RSVP_STATUS.NEEDS_ACTION);
  const getOptionFromRSVP = () => {
    return RSVP_OPTIONS.find((option) => option.value === rsvp);
  };
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const onChange = (option) => {
    setRSVP(option.value);
  };

  return (
    <div>
      <textarea
        className="email-input-container h-32 default-font-size"
        autoFocus={true}
        placeholder={"Enter note"}
        value={note}
        onChange={(e) => setNote(getInputStringFromEvent(e))}
      />
      <div className="default-font-size mt-4 mb-1.5">RSVP: </div>
      <CustomSelect
        value={
          getOptionFromRSVP() ?? {
            value: RSVP_STATUS.NEEDS_ACTION,
            label: "Going?",
          }
        }
        options={RSVP_OPTIONS}
        components={{ DropdownIndicator }}
        className={classNames(isDarkMode ? "dark-mode-select" : "")}
        classNamePrefix="dark-mode-modal"
        onChange={onChange}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          controlWidth: "120px",
          menuListStyle: customMenuStyle({
            width: 120,
          }),
        })}
      />
      <SaveAndCancelButton
        onClose={onClose}
        onConfirm={() => onSave({ note, rsvp })}
        confirmButtonText={"Send"}
      />
    </div>
  );
}
