import React from "react";
import { Minus, Square, X } from "react-feather";
import { isMac } from "../../services/commonUsefulFunctions";

const LoginTitleBar = () => {
  const isMacBoolean = isMac();

  const onClickMinimize = () => {
    if (window?.vimcal?.minimizeMainWindow) {
      window?.vimcal?.minimizeMainWindow();
    }
  };

  const onClickToggleMaximize = () => {
    if (window?.vimcal?.toggleMaximizeMainWindow) {
      window?.vimcal?.toggleMaximizeMainWindow();
    }
  };

  const onClickClose = () => {
    if (window?.vimcal?.closeMainWindow) {
      window?.vimcal?.closeMainWindow();
    }
  };

  return (
    <div
      id={
        isMacBoolean
          ? "desktop-login-title-header"
          : "windows-desktop-login-title-header"
      }
    >
      {!isMacBoolean ? (
        <div id="windows-login-buttons-container">
          <div className="windows-login-button" onClick={onClickMinimize}>
            <Minus size={16} />
          </div>
          <div className="windows-login-button" onClick={onClickToggleMaximize}>
            <Square size={16} />
          </div>
          <div
            className="windows-login-button login-exit-button"
            onClick={onClickClose}
          >
            <X size={16} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LoginTitleBar;
