import CountUp from "react-countup";
import React from "react";

export default function TimeCounter({ label, value }) {
  return (
    <div className="flex items-center mr-1 gap-1">
      <CountUp
        className="calendar-audit-number-counter calendar-audit-saved-time-font"
        duration={3}
        end={value}
        preserveValue={true}
      />
      <div className="calendar-audit-saved-time-font">{label}</div>
    </div>
  );
}
