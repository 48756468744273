import {
  createStaticInformation,
  isAllDayEvent,
  getFontColorForBackgroundColor,
} from "../../lib/eventFunctions";
import {
  isBeforeMinute,
  determineEventBackgroundColor,
} from "../commonUsefulFunctions";
import { useSelector } from "react-redux";
import {
  DARK_MODE_MODAL_BACKGROUND_COLOR,
} from "../globalVariables";
import {
  ATTENDEE_EVENT_NEEDS_ACTION,
  ATTENDEE_EVENT_TENTATIVE,
} from "../googleCalendarService";
import {useAllCalendars, useAllLoggedInUsers, useMasterAccount} from "../stores/SharedAccountData";
import { getEventStatus } from "../eventResourceAccessors";
import { useTemporaryStateStore } from "../stores/temporaryStateStores";
import { useMemo } from "react";
import { useOutlookCategoriesStore } from "../stores/outlookCategoriesStore";
import { shouldDimPastEvents } from "../../lib/settingsFunctions";
import _ from "underscore";
import { getTentativeBackgroundStyle } from "../../lib/styleFunctions";

interface UseDefaultEventStyleOptions {
  ignoreDarkMode?: boolean
  user?: User
  checkTagColor?: boolean
  isSchedulingAssistant?: boolean
  overrideColor?: string
  overrideTextColor?: string
  treatNeedsActionAsAttending?: boolean
}

export default function useDefaultEventStyle({ ignoreDarkMode, user, checkTagColor, isSchedulingAssistant, overrideColor, overrideTextColor, treatNeedsActionAsAttending }: UseDefaultEventStyleOptions) {
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode) && !ignoreDarkMode;
  const allCalendars = useAllCalendars(state => state.allCalendars);
  const hoveredEventID = useTemporaryStateStore(state => state.hoveredEventID);
  const now = useMemo(() => new Date(), []);
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories,
  );
  const allLoggedInUsers = useAllLoggedInUsers(state => state.allLoggedInUsers);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const dimPastEvents = shouldDimPastEvents({ masterAccount });

  const determineCalendarStyle = (event: BigCalendarEvent | TemporaryEvent): React.CSSProperties => {
    const {
      selfAttendingStatus,
      fadedColor,
      normalColor,
      darkModeFadedColor,
      isUserOnlyPersonWhoAcceptedAndRestDeclined,
    } = createStaticInformation({
      e: event,
      currentUser: user || currentUser,
      allCalendars,
      outlookCategories,
      allLoggedInUsers,
      checkTagColor,
      isSchedulingAssistant,
      masterAccount,
    });

    const isEventInThePast = dimPastEvents && isBeforeMinute(event.eventEnd, now);

    const determineColor = () => {
      if (overrideColor) {
        return overrideColor;
      } else if (isEventInThePast) {
        return isDarkMode ? darkModeFadedColor : fadedColor;
      } else {
        return normalColor;
      }
    };

    const color = determineColor();

    const backgroundColor = determineEventBackgroundColor({
      status: selfAttendingStatus,
      color,
      otherStatus: getEventStatus(event) || null,
      onlyPersonAttending: isUserOnlyPersonWhoAcceptedAndRestDeclined,
      isDarkMode,
      hoveredEventID,
      treatNeedsActionAsAttending,
    });
    const determineTextColor = () => {
      if (overrideTextColor) {
        return overrideTextColor;
      }
      return getFontColorForBackgroundColor({
        backgroundColor,
        hasEventPassed: isEventInThePast,
        isCurrentPreviewEvent: false,
      });
    };
    const determineBorder = () => {
      if (selfAttendingStatus === ATTENDEE_EVENT_NEEDS_ACTION && !treatNeedsActionAsAttending) {
        return color;
      }
      return isDarkMode ? DARK_MODE_MODAL_BACKGROUND_COLOR : "white";
    };
    const style: React.CSSProperties = {
      borderWidth: 1,
      borderColor: determineBorder(),
      color: determineTextColor(),
      borderStyle: "solid",
      textDecorationLine: "none",
      marginTop: "-1px",
      marginLeft: "-1px",
      cursor: "default",
    };
    if (
      _.get(event, "displayAsAllDay", false) || // Temporary events.
      _.get(event, "allDay", false) || // BigCalendar events
      isAllDayEvent(event)
    ) {
      style.paddingLeft = "8px";
      style.position = "relative";
    }

    if (selfAttendingStatus === ATTENDEE_EVENT_TENTATIVE) {
      style.background = getTentativeBackgroundStyle(color);
    } else {
      style.backgroundColor = backgroundColor;
    }

    return style;
  };

  return determineCalendarStyle;
}
