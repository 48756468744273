import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import CustomDropdownContainer from "../customDropdownContainer";
import {
  addDays,
  endOfWeek,
  format,
  getMonth,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  isAfterDay,
  isAfterMinute,
  isSameOrAfterMinute,
  isSameOrBeforeMinute,
} from "../../services/commonUsefulFunctions";
import { isNotEmptyArry, reverseArray } from "../../lib/arrayFunctions";
import { METRICS_PERIOD } from "./metricsAccessorFunctions";

export default function DateHeaderSelection({
  setShowDateSelection,
  shouldShowDateSelection,
  startJSDate,
  endJSDate,
  selectWeek,
  selectMonth,
  setMonth,
  currentlyOpenMonth,
  period,
  shouldShowUserDropdown,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [anchorDate, setAnchorDate] = useState(new Date());
  if (!endJSDate || !startJSDate) {
    return null;
  }

  const renderWeeklyDropdown = () => {
    return renderWeeklyModal({
      anchorDate,
      shouldShowDateSelection,
      currentlyOpenMonth,
      setMonth,
      setShowDateSelection,
      selectWeek,
      startJSDate,
      shouldShowUserDropdown,
    });
  };

  const renderModal = () => {
    switch (period) {
      case METRICS_PERIOD.WEEKLY:
        return renderWeeklyDropdown();
      case METRICS_PERIOD.MONTHLY:
        return renderMonthlyModal({
          shouldShowDateSelection,
          anchorDate,
          currentlyOpenMonth,
          setMonth,
          setShowDateSelection,
          selectMonth,
          shouldShowUserDropdown,
        });
      default:
        return renderWeeklyDropdown();
    }
  };

  const getDisplayText = () => {
    if (period === METRICS_PERIOD.MONTHLY) {
      return format(currentlyOpenMonth, "MMM yyyy").toUpperCase();
    }
    return `${format(startJSDate, "d MMM")} - ${format(endJSDate, "d MMM")}`;
  };

  return (
    <div
      className={classNames(
        "flex items-center default-font-size",
        "metrics-primary-text-color",
      )}
    >
      {renderModal()}
      <CustomDropdownContainer
        id={"metrics-date-selection"}
        onClick={() => setShowDateSelection(true)}
        displayText={getDisplayText()}
      />
    </div>
  );
}

function getAllMondays(jsDate) {
  let date = new Date(jsDate.getFullYear(), getMonth(jsDate), 1);
  let mondays = [];

  while (isSameMonth(date, jsDate)) {
    if (date.getDay() === 1) {
      // 1 represents Monday
      mondays.push(new Date(date)); // push a copy of date
    }
    date.setDate(date.getDate() + 1); // go to the next day
  }

  return mondays;
}

function getFirstDaysOfMonthUntilLastYear(anchorDate) {
  const startDate = startOfMonth(anchorDate);
  let months = [];
  if (parseInt(anchorDate.getFullYear()) === 2023) {
    // return every month for 2023
    return getMonthsFromDecToJan(anchorDate);
  }

  let currentDate = startDate;
  while (
    !(
      currentDate.getMonth() === 0 &&
      currentDate.getFullYear() === startDate.getFullYear() - 1
    )
  ) {
    if (hasFirstWeekOfMonthPassed(currentDate, anchorDate)) {
      months.push(currentDate);
    }
    currentDate = subMonths(currentDate, 1);
  }

  return months;
}

function getMonthsFromDecToJan(inputDate) {
  let year = inputDate.getFullYear();
  let months = [];
  for (let month = 11; month >= 0; month--) {
    months.push(new Date(year, month, 1));
  }
  return months;
}

function hasFirstWeekOfMonthPassed(inputDate, anchorDate) {
  // Find the end of the first week of the month
  const endOfFirstWeek = endOfWeek(inputDate, { weekStartsOn: 1 }); // Week starts on Monday

  // Check if the current date is past the end of the first week
  return isAfterDay(anchorDate, endOfFirstWeek);
}

function renderMonthlyModal({
  shouldShowDateSelection,
  anchorDate,
  currentlyOpenMonth,
  setMonth,
  setShowDateSelection,
  selectMonth,
  shouldShowUserDropdown,
}) {
  if (!shouldShowDateSelection) {
    return null;
  }
  const months = getFirstDaysOfMonthUntilLastYear(anchorDate);
  const closeDateSelection = () => {
    setShowDateSelection(false);
  };
  return (
    <div
      className={classNames(
        "absolute top-20 metrics-select-calendar-modal z-30 p-4 rounded-lg",
        shouldShowUserDropdown ? "right-224px" : "right-14"
      )}
    >
      <div>Choose month</div>
      {months.map((month) => {
        const isCurrentlySelectedMonth = isSameMonth(currentlyOpenMonth, month);
        return (
          <div key={`select-month-dropdown-${month}}`}>
            <div
              className={classNames(
                "flex justify-between cursor-pointer py-1.5",
                !isCurrentlySelectedMonth
                  ? "hover-background-container -mx-2 px-2 rounded-md w-52"
                  : "w-48"
              )}
              onClick={() => {
                if (isCurrentlySelectedMonth) {
                  // setMonth(null);
                  return;
                }
                setMonth(month);
                selectMonth(month);
                closeDateSelection();
              }}
            >
              <div
                className={classNames(
                  isCurrentlySelectedMonth ? "default-font-color" : "",
                  "select-none"
                )}
              >
                {format(month, "MMM yyyy").toUpperCase()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
function renderWeeklyModal({
  anchorDate,
  shouldShowDateSelection,
  currentlyOpenMonth,
  setMonth,
  setShowDateSelection,
  selectWeek,
  startJSDate,
  shouldShowUserDropdown
}) {
  if (!shouldShowDateSelection) {
    return null;
  }
  const months = getFirstDaysOfMonthUntilLastYear(anchorDate).filter(startOfMonthDate => {
    const weeks = getAllMondays(startOfMonthDate).filter(monday => {
      const endOfWeekForMonday = addDays(monday, 6);
      return isSameOrBeforeMinute(endOfWeekForMonday, anchorDate)
    });
    return isNotEmptyArry(weeks);
  });
  const closeDateSelection = () => {
    setShowDateSelection(false);
  };

  const onClickWeek = (jsDate) => {
    selectWeek(format(jsDate, "yyyy-MM-dd"));
    closeDateSelection();
  };

  const renderWeekOptions = (jsDate) => {
    if (!isSameMonth(currentlyOpenMonth, jsDate)) {
      return null;
    }
    const allMondaysInMonth = reverseArray(getAllMondays(jsDate));
    return (
      <div>
        {allMondaysInMonth.map((monday) => {
          const endOfWeekForMonday = addDays(monday, 6);
          if (isAfterMinute(endOfWeekForMonday, anchorDate)) {
            return null;
          }
          const isMatchingWeek =
            startJSDate &&
            isSameOrAfterMinute(startJSDate, monday) &&
            isSameOrBeforeMinute(startJSDate, endOfWeekForMonday);
          return (
            <div
              key={`select-week-dropdown-${monday}`}
              className={classNames(
                "select-none ml-4 my-1",
                isMatchingWeek
                  ? "default-font-color"
                  : "hoverable-secondary-text-color"
              )}
              onClick={() => onClickWeek(monday)}
            >
              {`${format(monday, "d MMM")} - ${format(
                endOfWeekForMonday,
                "d MMM"
              )}`}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div
      className={classNames(
        "absolute top-20 metrics-select-calendar-modal z-30 p-4 rounded-lg",
        shouldShowUserDropdown ? "right-224px" : "right-14"
      )}
    >
      <div>Choose week</div>
      {months.map((month) => {
        const isCurrentlySelectedMonth = isSameMonth(currentlyOpenMonth, month);
        return (
          <div key={`select-month-dropdown-${month}}`}>
            <div
              className={classNames(
                "flex justify-between cursor-pointer py-1.5",
                !isCurrentlySelectedMonth
                  ? "hover-background-container -mx-2 px-2 rounded-md w-52"
                  : "w-48"
              )}
              onClick={() => {
                if (isCurrentlySelectedMonth) {
                  setMonth(null);
                  return;
                }
                setMonth(month);
              }}
            >
              <div
                className={classNames(
                  isCurrentlySelectedMonth ? "default-font-color" : "",
                  "select-none"
                )}
              >
                {format(month, "MMM yyyy").toUpperCase()}
              </div>
              <ChevronDown
                className={classNames(
                  "duration-200 transform transition-transform",
                  isCurrentlySelectedMonth ? "rotate-180" : ""
                )}
                size={16}
              />
            </div>

            {renderWeekOptions(month)}
          </div>
        );
      })}
    </div>
  );
}
