import React, { PureComponent, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Checkbox from "./checkbox";
import ReactSelectAttendeeAutoComplete from "./reactSelectAttendeeAutoComplete";
import {
  constructEmailData,
  isMac,
  KEYCODE_ESCAPE,
  KEYCODE_ENTER,
  KEYCODE_SEMI_COLON,
  getActiveCommandCentersKeyForMousetrap,
  getActiveCommandCentersKey,
  handleError,
  hasEventPreventDefault,
} from "../services/commonUsefulFunctions";
import { trackEvent } from "./tracking";
import CopiableContentContainer from "./copiableContentContainer";
import { Paperclip, X } from "react-feather";
import CustomButton from "./customButton";
import {
  BLUE_BUTTON,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  WHITE_BUTTON,
} from "../services/globalVariables";
import GoogleCalendarService from "../services/googleCalendarService";
import _ from "underscore";
import Broadcast from "../broadcasts/broadcast";
import "react-quill/dist/quill.snow.css";
import * as Mousetrap from "mousetrap";
import classNames from "classnames";
import {
  getEventAttendees,
  getEventTitle,
  getEventUserEmail,
} from "../services/eventResourceAccessors";
import { isOutlookEvent, isValidEvent } from "../lib/eventFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import { isTextTemplate } from "../services/templateFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  SELECT_USER_TYPE,
  SelectUser,
  getAllUsers,
} from "./settings/common/selectUser";
import {
  getAllExecutives,
  getDelegatedUserAuthenticatedUser,
  isMaestroUserOnDelegatedAccount,
  isUserMaestroUser,
} from "../services/maestroFunctions";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { blurCalendar, isKeyCodeCommandOrControl } from "../services/appFunctions";
import { constructRequestURL } from "../services/api";
import Fetcher from "../services/fetcher";
import {
  PERMISSION_MODAL_TYPES,
  broadcastOpenPermissionModal,
  isNeedToShowUserConsentScreen,
} from "../lib/authFunctions";
import Dropzone from "react-dropzone";
import { isEmptyArray } from "../lib/arrayFunctions";
import CustomButtonBox from "./customButtonBox";
import { EMAIL_ATTENDEES_MODAL_ID } from "../services/elementIDVariables";
import emailBroadcast from "../broadcasts/emailBroadcast";
import { isOutlookUser } from "../lib/outlookFunctions";
import DisabledButton from "./disabledButton";
import { toBase64 } from "../lib/dataFunctions";
import { isKeyDownCommandOrCtrl } from "../lib/hotkeyFunctions";
import { hasInternetConnection } from "../lib/onlineCheckFunctions";
import { getMasterAccountTemplates, getUserEmail, getUserToken } from "../lib/userFunctions";
import AttachmentPreview from "./attachmentPreview";
import { getInputStringFromEvent, isSameEmail, isValidEmail, truncateString } from "../lib/stringFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { SEND_EMAIL_AS, getDefaultEmailSendAsOption } from "../lib/settingsFunctions";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../lib/vimcalVariables";

const {
  attendee_event_attending,
  attendee_event_tentative,
  attendee_event_declined,
  attendee_event_needs_action,
} = GoogleCalendarService;

const SUBJECT_FIELD = "SUBJECT_FIELD";
const MESSAGE_FIELD = "MESSAGE_FIELD";

const ATTENDEE_STATUS = {
  YES: "Yes",
  NO: "No",
  AWAITING: "Awaiting response",
};

const EMAIL_TEXT_AREA = "EMAIL_TEXT_AREA";
class EmailAttendees extends PureComponent {
  constructor(props) {
    super(props);

    const attendeesResponseInfo = this.createExistingAttendeeResponseInfo();

    this._subject = createRef();
    this._message = createRef();
    this._dropZone = createRef();

    const allUsers = this.getUsers();

    const userIndex = this.getUserIndexFromEventUserEmail();
    this._isCmdKeyDown = false;

    this.state = {
      shouldDisplayAwaitingResponse: true,
      awaitingResponse: false,
      shouldSendCopyToMe: true,
      selectedGuests: this.createSelectedGuests(),
      addedGuests: [],
      subject: this.createSubject(),
      message: props.defaultMessage ?? "",
      existingAttendeeResponseInfo: attendeesResponseInfo,
      attendeesByStatus: this.getAttendeesByStatus(),
      existingAttendeesEmailBoolean: this.createExistingAttendeesEmailBool(
        attendeesResponseInfo
      ),
      isMessageFocused: true,
      isMac: isMac(),
      selectedUserIndex: userIndex,
      allUsers,
      /* Below key:values are used for Outlook emails */
      draftEmailId: null,
      isCreatingDraftEmailId: false, // to prevent send button if we're loading
      currentFileUpload: null,
      currentFileRemoval: null,
      allFileUploads: [],
      failedFileUploads: [],
      pendingFileUploads: [],
      successfulFileUploads: [],
      hoveredFileIndex: null,
      showFadingError: false,
      showAttachmentHint: false,
      isWaitingForInternetCheck: false,
      /* Above key:values are used for Outlook emails */
    };

    this._lastSection = null; // keeps track of the last section that was focused on

    this.onChangeSendEmailResponse = this.onChangeSendEmailResponse.bind(this);
    this.onChangeShouldSendCopyToMe =
      this.onChangeShouldSendCopyToMe.bind(this);
    this.addContact = this.addContact.bind(this);
    this.onClickRemoveEmail = this.onClickRemoveEmail.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSend = this.onClickSend.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClickTab = this.onClickTab.bind(this);
    this.onFocusMessage = this.onFocusMessage.bind(this);
    this.onBlurMessage = this.onBlurMessage.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.openTemplateCommandCenter = this.openTemplateCommandCenter.bind(this);
    this.onPasteInSticky = this.onPasteInSticky.bind(this);
    this.onSelectUserIndex = this.onSelectUserIndex.bind(this);
    this.createDraftEmail = this.createDraftEmail.bind(this);
    this.handleUploadQueue = this.handleUploadQueue.bind(this);
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.handleRetryFileUpload = this.handleRetryFileUpload.bind(this);
    this.openAddAttachmentModal = this.openAddAttachmentModal.bind(this);

    layoutBroadcast.subscribe("PASTE_IN_STICKY", this.onPasteInSticky);
    emailBroadcast.subscribe("UPLOAD_EMAIL_FILES", this.onDropAccepted);
    emailBroadcast.subscribe(
      "UPLOAD_EMAIL_FILES_REJECTED",
      this.onDropRejected
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this._message?.current?.focus();

    this.bindMouseTrap();

    if (this.state.message) {
      // textArea needs to be explicitly set in with code below
      document.getElementById(EMAIL_TEXT_AREA).value = this.state.message;
    }

    /* Send request to backend to get Outlook draft id */
    if (
      isOutlookEvent(this.props.event) &&
      this.getSelectedUser()?.email?.length > 0
    ) {
      this.createDraftEmail();
    }
  }

  componentDidUpdate() {
    this.handleUploadQueue();
  }

  componentWillUnmount() {
    this.unbindMouseTrap();

    this._isMounted = false;
    this._subject = null;
    this._message = null;

    layoutBroadcast.unsubscribe("PASTE_IN_STICKY");
    emailBroadcast.unsubscribe("UPLOAD_EMAIL_FILES");
    emailBroadcast.unsubscribe("UPLOAD_EMAIL_FILES_REJECTED");
  }

  render() {
    return (
      <div id={EMAIL_ATTENDEES_MODAL_ID}>
        {this.renderSendEmailToGuests()}
        {this.renderSelectEmail()}

        {this.renderSendCopyToMe()}

        <ReactSelectAttendeeAutoComplete
          className="email-attendees-contact"
          addAttendees={this.addContact}
          defaultText="Enter name or email"
          componentLocation={REACT_ATTENDEE_SELECT_LOCATION.EMAIL}
          hideMenuOnFocus={true}
          selectedGuests={this.createSelectedGuestsEmailArray()}
          includeEmailAndName={true}
          isInModal={true}
          onKeyDown={this.onKeyDown}
          inputTabIndex={0}
        />

        {this.renderEmails()}

        <div className="email-subject-title">Subject</div>

        <input
          className="send-email-input default-font-size mb-2.5"
          value={this.state.subject}
          onChange={this.onChangeSubject}
          placeholder="Enter subject"
          onKeyDown={(data) => this.onKeyDown(data, SUBJECT_FIELD)}
          tabIndex={1}
          ref={this._subject}
        />

        <div
          className={classNames(
            "email-subject-title",
            "margin-top-5",
            "flex justify-between items-center"
          )}
        >
          Message
          {this.renderUseStickes()}
        </div>

        <textarea
          id={EMAIL_TEXT_AREA}
          className="email-input-container h-32 default-font-size"
          autoFocus={true}
          placeholder={"Enter message"}
          onKeyDown={(data) => this.onKeyDown(data, MESSAGE_FIELD)}
          onChange={this.onChangeMessage}
          tabIndex={2}
          ref={this._message}
        />

        {this.renderSuggestions()}

        {this.renderDropZone()}

        {this.renderButtons()}
      </div>
    );
  }

  renderSelectEmail() {
    const { allUsers, selectedUserIndex } = this.state;
    const { masterAccount } = this.props.masterAccount;
    if (!isUserMaestroUser(masterAccount)) {
      return null;
    }

    if (!this.isMaestroExecEvent()) {
      return null;
    }

    if (
      !(allUsers.length > 1 && selectedUserIndex >= 0 && this.getSelectedUser())
    ) {
      return null;
    }

    return (
      <div className="flex items-center absolute right-16 top-3.5">
        <div className="secondary-text-color default-font-size mr-2">
          Send as
        </div>
        <SelectUser
          setSelectedUserIndex={this.onSelectUserIndex}
          selectedUser={this.getSelectedUser()}
          inputContainerClassName=""
          addExecutiveLabel={true}
          selectUserType={SELECT_USER_TYPE.PRIMARY_AND_EXECUTIVES}
          eventUserEmail={this.props.event?.userEmail}
        />
      </div>
    );
  }

  renderUseStickes() {
    const { masterAccount } = this.props.masterAccount;
    const templates = getMasterAccountTemplates({ masterAccount });
    const textTemplates = templates?.filter((t) =>
      isTextTemplate(t),
    );
    if (!textTemplates || isEmptyArrayOrFalsey(textTemplates)) {
      return null;
    }

    return (
      <div
        className="flex justify-center cursor-pointer hover:text-blue-500 duration-200"
        onClick={() => this.openTemplateCommandCenter(MESSAGE_FIELD)}
      >
        <div className="pt-1 default-font-size">Use Stickies</div>

        <div className="flex flex-row items-center">
          <div className="display-flex-center margin-right-5 font-size-300">
            <ShortcutTile shortcut={getActiveCommandCentersKey()} />
            <ShortcutTile shortcut={";"} />
          </div>
        </div>
      </div>
    );
  }

  renderSuggestions() {
    const RUNNING_5_MIN_LATE = "Running 5 minutes late!";
    const RUNNING_A_FEW_MIN_LATE = "Running a few minutes late!";
    const PLEASE_START_WITHOUT_ME = "Please start without me.";

    const EMAIL_SUGGESTIONS = [
      RUNNING_5_MIN_LATE,
      RUNNING_A_FEW_MIN_LATE,
      PLEASE_START_WITHOUT_ME,
    ];

    const onClickSuggestion = (suggestion) => {
      const updatedMessage = this.state.message
        ? this.state.message + " " + suggestion
        : suggestion;

      document.getElementById(EMAIL_TEXT_AREA).value = updatedMessage;
      this.setState({ message: updatedMessage });
    };

    return (
      <div className="flex mt-4">
        {EMAIL_SUGGESTIONS.map((suggestion, index) => {
          return (
            <div
              className={classNames(
                "px-4 py-1 border-solid border-width-1px rounded-full mr-2 cursor-pointer select-none font-size-12 duration-200",
                this.props.isDarkMode
                  ? "border-gray-400 hover:bg-gray-600"
                  : "border-gray-200 hover:bg-gray-100"
              )}
              key={`quick_email_suggestion_${index}`}
              onClick={() => onClickSuggestion(suggestion)}
            >
              {suggestion}
            </div>
          );
        })}
      </div>
    );
  }

  createModules() {
    return {
      toolbar: false,
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler: this.onClickTab,
          },
        },
      },
    };
  }

  createDraftEmail() {
    if (this.state.draftEmailId || this.state.isCreatingDraftEmailId) {
      return;
    }
    this.setState(
      {
        isCreatingDraftEmailId: true,
      },
      () => {
        const path = "create_draft_outlook_email";
        const url = constructRequestURL(path, true);
        const emailForBackend = getUserEmail(this.getSelectedUser());

        Fetcher.post(url, {}, true, emailForBackend)
          .then((response) => {
            if (!this._isMounted) {
              return;
            }

            if (this.isResponseError(response) || !response?.draft_email_id) {
              this.setState({
                isCreatingDraftEmailId: false,
              });
              this.handleErrorFromResponse({
                response,
                emailForBackend,
              });
              return;
            }

            this.setState({
              draftEmailId: response.draft_email_id,
              isCreatingDraftEmailId: false,
            });
          })
          .catch(handleError);
      }
    );
  }

  onClickRemoveFile(allFilesIndex) {
    /* Return if not mounted or we are already removing a file */
    if (!this._isMounted || this.state.currentFileRemoval) {
      return;
    }

    const allFileUploadsFile = this.state.allFileUploads[allFilesIndex];
    const updatedAllFileUploads = this.state.allFileUploads.toSpliced(
      allFilesIndex,
      1
    );

    /* Should never enter this */
    if (!allFileUploadsFile) {
      return;
    }

    this.setState(
      {
        currentFileRemoval: allFileUploadsFile,
      },
      () => {
        /* Find the matching file in failed, pending, and uploaded files */
        const matchingFailedFileIndex = this.state.failedFileUploads.findIndex(
          (fileWithError) => fileWithError.file === allFileUploadsFile
        );
        const matchingPendingFileIndex =
          this.state.pendingFileUploads.findIndex(
            (file) => file === allFileUploadsFile
          );
        const matchingUploadedFileIndex =
          this.state.successfulFileUploads.findIndex(
            (fileWithId) => fileWithId.file === allFileUploadsFile
          );
        /* Default to the current state */
        let updatedFailedFileUploads = this.state.failedFileUploads;
        let updatedPendingFileUploads = this.state.pendingFileUploads;
        let updatedSuccessfulFileUploads = this.state.successfulFileUploads;

        /* Splice element from array for updated state */
        if (matchingPendingFileIndex !== -1) {
          updatedPendingFileUploads = this.state.pendingFileUploads.toSpliced(
            matchingPendingFileIndex,
            1
          );
        }

        if (matchingFailedFileIndex !== -1) {
          updatedFailedFileUploads = this.state.failedFileUploads.toSpliced(
            matchingFailedFileIndex,
            1
          );
        }

        if (matchingUploadedFileIndex !== -1) {
          updatedSuccessfulFileUploads =
            this.state.successfulFileUploads.toSpliced(
              matchingUploadedFileIndex,
              1
            );

          /* Send request to remove the file from Outlook draft */
          const path = "remove_file_from_draft_outlook_email";
          const url = constructRequestURL(path, true);
          const payloadData = {
            body: JSON.stringify({
              draft_email_id: this.state.draftEmailId,
              file_id:
                this.state.successfulFileUploads[matchingUploadedFileIndex]
                  ?.fileId,
            }),
          };
          const emailForBackend = getUserEmail(this.getSelectedUser());

          Fetcher.post(url, payloadData, true, emailForBackend)
            .then((response) => {
              if (!this._isMounted) {
                return;
              }

              if (this.isResponseError(response)) {
                this.handleErrorFromResponse({
                  response,
                  emailForBackend,
                });
                return;
              }

              this.setState({
                allFileUploads: updatedAllFileUploads,
                failedFileUploads: updatedFailedFileUploads,
                currentFileRemoval: null,
                pendingFileUploads: updatedPendingFileUploads,
                successfulFileUploads: updatedSuccessfulFileUploads,
              });
            })
            .catch(handleError);

          return;
        }

        // need double set state here because one is only called if it's successfully uploaded to Outlook
        this.setState({
          allFileUploads: updatedAllFileUploads,
          currentFileRemoval: null,
          failedFileUploads: updatedFailedFileUploads,
          pendingFileUploads: updatedPendingFileUploads,
          successfulFileUploads: updatedSuccessfulFileUploads,
        });
      }
    );
  }

  //================
  // RENDER METHODS
  //===============

  renderSendEmailToGuests() {
    if (!this.state.existingAttendeeResponseInfo) {
      return null;
    }

    return Object.keys(this.state.existingAttendeeResponseInfo).map(
      (r, index) => {
        return (
          <div
            key={`existing_response_info_${index}`}
            className="display-flex-flex-direction-row align-items-center margin-top-five default-font-size"
          >
            <Checkbox
              isChecked={this.state.existingAttendeesEmailBoolean[r]}
              onChange={() => this.onChangeSendEmailResponse(r)}
            />

            <div
              className="ml-2.5 select-none cursor-pointer"
              onClick={() => this.onChangeSendEmailResponse(r)}
            >
              {`${r} (${this.state.existingAttendeeResponseInfo[r]})`}
            </div>
          </div>
        );
      }
    );
  }

  renderSendCopyToMe() {
    return (
      <div className="margin-top-five display-flex-flex-direction-row align-items-center default-font-size">
        <Checkbox
          isChecked={this.state.shouldSendCopyToMe}
          onChange={this.onChangeShouldSendCopyToMe}
        />

        <div
          className="ml-2.5 select-none cursor-pointer"
          onClick={this.onChangeShouldSendCopyToMe}
        >
          Send copy to me
        </div>
      </div>
    );
  }

  renderEmails() {
    if (this.getSelectedGuests().length > 0) {
      return (
        <div className="selected-email">
          <CopiableContentContainer
            style={{ width: "100%", flexWrap: "wrap" }}
            iconMarginLeft={5}
            iconMarginTop={15}
            textToCopy={this.createAttendeeString()}
          >
            {this.renderSelectedEmails()}
          </CopiableContentContainer>
        </div>
      );
    }
  }

  renderSelectedEmails() {
    return this.getSelectedGuests().map((e, index) => {
      return (
        <div
          key={`selected_email_list_${index}`}
          className="selected-email-container"
        >
          <div className="margin-right-5">{truncateString(e.value, 40)}</div>

          <div className="display-flex-center">
            <X
              className="hoverable-secondary-text-color"
              size={13}
              onClick={() => this.onClickRemoveEmail(e.value)}
            />
          </div>
        </div>
      );
    });
  }

  getSelectedGuests() {
    const {
      existingAttendeesEmailBoolean,
      attendeesByStatus,
      addedGuests,
      selectedGuests,
    } = this.state;
    const { currentUser } = this.props;

    if (isEmptyObjectOrFalsey(existingAttendeesEmailBoolean)) {
      return [].concat(addedGuests);
    }

    let filteredAttendees = [];
    Object.keys(existingAttendeesEmailBoolean).forEach((key) => {
      if (existingAttendeesEmailBoolean[key]) {
        const selectedAttendeeEmail = selectedGuests.map((a) => a.value);
        const matchingAttendees = attendeesByStatus[key].filter((attendee) =>
          selectedAttendeeEmail.includes(attendee.email)
        );
        filteredAttendees = filteredAttendees.concat(matchingAttendees);
      }
    });
    filteredAttendees = filteredAttendees.filter(
      (a) => !a.resource && a.email !== currentUser.email
    );
    return filteredAttendees
      .map((a) => {
        return { value: a.email, label: a.email };
      })
      .concat(addedGuests);
  }

  renderButtons() {
    const shouldPreventSend =
      this.state.isCreatingDraftEmailId ||
      !isEmptyArray(this.state.pendingFileUploads);

    const {
      isWaitingForInternetCheck
    } = this.state;
    return (
      <div
        className="weekly-calendar-modal-content-button-options"
        style={{ marginTop: 20 }}
      >
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={this.onClickCancel}
          label="Cancel"
          buttonTabIndex={4}
        />

        {shouldPreventSend ? (
          <DisabledButton label={"Send"} />
        ) : (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={this.onClickSend}
            label={isWaitingForInternetCheck ? "" : "Send"}
            buttonTabIndex={3}
            shouldRenderSpinner={isWaitingForInternetCheck}
          />
        )}
      </div>
    );
  }

  shouldDisplayEmailAttachments() {
    return isOutlookUser(this.getSelectedUser());
  }

  renderDropZone() {
    /* Don't show if not Outlook user */
    if (!this.shouldDisplayEmailAttachments()) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(
            "email-subject-title",
            "mt-4",
            "flex items-center"
          )}
        >
          <div>Attachments</div>
          {this.state.showFadingError ? (
            <div className="text-red-500 ml-2">
              One or more files were not permitted (max file size is 3MB).
            </div>
          ) : null}
        </div>

        {isEmptyArray(this.state.allFileUploads) ? null : (
          <div className="my-2">
            {this.state.allFileUploads.map((file, idx) =>
              this.renderFile(file, idx)
            )}
          </div>
        )}

        <Dropzone
          ref={this._dropZone}
          disabled={!this.state.draftEmailId}
          noKeyboard={true}
          noDrag={true}
          maxSize={3000000}
          multiple={true}
          noDragEventsBubbling={true}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({})}
              className="cursor-pointer text-sm max-w-max"
              onMouseEnter={() => this.setState({ showAttachmentHint: true })}
              onMouseLeave={() => this.setState({ showAttachmentHint: false })}
            >
              <CustomButtonBox
                className="mt-2"
                isDisabled={!this.state.draftEmailId}
              >
                <div className="file-attachment-expand-file font-weight-300">
                  {this.state.showAttachmentHint && !this.state.draftEmailId ? (
                    <div
                      className={classNames(
                        "more-information-modal w-max-content-important",
                        this.props.isDarkMode
                          ? "background-color-modal-background-color"
                          : "bg-white"
                      )}
                    >
                      Attachments are currently unavailable, possibly due to a
                      Microsoft error or weak internet connection.
                    </div>
                  ) : null}
                  <Paperclip size={12} />
                  <div
                    className={classNames(
                      "file-attachment-title",
                      this.state.draftEmailId ? "" : "opacity-50"
                    )}
                  >
                    Add Attachment(s)
                  </div>
                </div>
                <input {...getInputProps()} hidden />
              </CustomButtonBox>
            </div>
          )}
        </Dropzone>
      </>
    );
  }

  onDropRejected() {
    if (!this.shouldDisplayEmailAttachments()) {
      return;
    }

    if (!this.state.draftEmailId) {
      return;
    }

    this.setState(
      {
        showFadingError: true,
      },
      () => {
        setTimeout(() => {
          if (!this._isMounted) {
            return;
          }

          this.setState({ showFadingError: false });
        }, 10 * SECOND_IN_MS);
      }
    );
  }

  onDropAccepted(files) {
    if (!this.shouldDisplayEmailAttachments()) {
      return;
    }
    if (isEmptyArray(files) || !this.state.draftEmailId) {
      return;
    }
    this.setState({
      allFileUploads: [...this.state.allFileUploads, ...files],
      pendingFileUploads: [...this.state.pendingFileUploads, ...files],
    });
  }

  renderFile(file, index) {
    const fileErrors = this.state.failedFileUploads.find(
      (fileWithError) => fileWithError.file === file
    )?.errors;

    return (
      <AttachmentPreview
        key={`attachement_${file.name}_${index}`}
        file={file}
        fileErrors={fileErrors}
        hoveredFileIndex={this.state.hoveredFileIndex}
        index={index}
        isUploading={this.state.currentFileUpload === file}
        onClickRemove={() => this.onClickRemoveFile(index)}
        setHoveredFileIndex={(newIndex) => this.setState({ hoveredFileIndex: newIndex })}
      />
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onChangeSendEmailResponse(response) {
    let updatedResponse = _.clone(this.state.existingAttendeesEmailBoolean);

    updatedResponse[response] = !updatedResponse[response];

    this.setState({ existingAttendeesEmailBoolean: updatedResponse });
  }

  onChangeShouldSendCopyToMe() {
    this.setState({ shouldSendCopyToMe: !this.state.shouldSendCopyToMe });
  }

  onChangeSubject(e) {
    this.setState({ subject: getInputStringFromEvent(e) });
  }

  onChangeMessage(e) {
    this.setState({ message: getInputStringFromEvent(e) });
  }

  onClickCancel() {
    this.props.closeModal();
  }

  async onClickSend() {
    this.setState({ isWaitingForInternetCheck: true });
    if (!isValidEvent(this.props.event)) {
      this.setState({ isWaitingForInternetCheck: false });
      return;
    }

    const hasInternet = await hasInternetConnection();
    if (!hasInternet) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Failed to send email – no internet connection found."
      );
      this.setState({ isWaitingForInternetCheck: false });
      return;
    }
    const { masterAccount } = this.props.masterAccount;

    // Data for backend to send email
    const emailData = constructEmailData({
      event: this.props.event,
      currentUser: this.getSelectedUser() ?? this.props.currentUser,
      subject: this.state.subject,
      message: this.state.message,
      shouldSendCopyToMe: this.state.shouldSendCopyToMe,
      currentTimeZone: this.props.currentTimeZone,
      selectedGuests: this.getSelectedGuests(),
      draftEmailId: this.state.draftEmailId,
      masterAccount,
      isHTML: isOutlookEvent(this.props.event),
    });

    backendBroadcasts.publish("SEND_EMAIL", {
      emailData,
      user: this.getSelectedUser(),
    });
    trackEvent({
      category: "email",
      action: "email_attendees_directly",
      label: `attendees_count_${getEventAttendees(this.props.event)?.length}`,
      userToken: getUserToken(this.props.currentUser),
    });

    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
    this.props.closeModal();
  }

  getSelectedUser() {
    const { allUsers, selectedUserIndex } = this.state;
    return allUsers[selectedUserIndex]?.value;
  }

  handleChange(html) {
    this.setState({ message: html });
  }

  onClickTab() {
    this.setState({ isMessageFocused: false });

    return true;
  }

  onFocusMessage() {
    this.setState({ isMessageFocused: true });
  }

  onBlurMessage() {
    this.setState({ isMessageFocused: false });
  }

  onKeyDown(data, where) {
    if (!data) {
      return;
    }
    if (
      isKeyDownCommandOrCtrl(data) &&
      data.shiftKey &&
      (data.key === "a" || data.key === "A")
    ) {
      hasEventPreventDefault(data);
      this.openAddAttachmentModal();
      return;
    }
    if (isKeyDownCommandOrCtrl(data) && data.shiftKey && data.key === ".") {
      hasEventPreventDefault(data);
      // Your code here
      this.onClickCancel();
      return;
    }

    if (isKeyCodeCommandOrControl(data.keyCode)) {
      this._isCmdKeyDown = true;
    } else {
      if (this._isCmdKeyDown && data.keyCode === KEYCODE_ENTER) {
        this.onClickSend();
      } else if (this._isCmdKeyDown && data.keyCode === KEYCODE_SEMI_COLON) {
        this.openTemplateCommandCenter(where);
      } else if (data.keyCode === KEYCODE_ESCAPE) {
        blurCalendar();
      }

      this._isCmdKeyDown = false;
    }
  }

  //================
  // PRIVATE METHODS
  //================

  createSelectedGuestsEmailArray() {
    return this.getSelectedGuests().map((a) => {
      return a.value;
    });
  }

  createSubject() {
    return getEventTitle(this.props.event) ?? "";
  }

  createAttendeeString() {
    if (!this.getSelectedGuests()) {
      return "";
    }

    let emailString = "";

    this.getSelectedGuests().forEach((a, index) => {
      emailString = emailString + a.label;

      if (index + 1 < this.getSelectedGuests().length) {
        emailString = emailString + ", ";
      }
    });

    return emailString;
  }

  addContact(selected) {
    if (!selected) {
      return;
    }

    const emailWithoutWhiteSpaces = selected.value ? selected.value.trim() : "";

    if (
      !isValidEmail(emailWithoutWhiteSpaces) &&
      !this.getSelectedGuests().includes(emailWithoutWhiteSpaces)
    ) {
      return;
    }

    const updatedAddedGuests = this.state.addedGuests.concat(selected);
    this.setState({ addedGuests: updatedAddedGuests });
  }

  onClickRemoveEmail(email) {
    const updatedSelectedGuests = this.state.selectedGuests.filter(
      (g) => g.value !== email
    );
    const updatedAddedGuests = this.state.addedGuests.filter(
      (g) => g.value !== email
    );
    this.setState({
      selectedGuests: updatedSelectedGuests,
      addedGuests: updatedAddedGuests,
    });
  }

  getAllAttendees() {
    const attendees = getEventAttendees(this.props.event) ?? [];
    // filter out resources
    return attendees.filter((a) => !a.resource);
  }

  createSelectedGuests() {
    if (!this.doesEventHaveAttendees()) {
      return [];
    }

    const attendees = this.getAllAttendees();

    const filteredAttendees = attendees.filter(
      (a) => !a.resource && a.email !== this.props.currentUser.email
    );

    return filteredAttendees.map((a) => {
      return { value: a.email, label: a.email };
    });
  }

  getAttendeesByStatus() {
    if (!this.doesEventHaveAttendees()) {
      return null;
    }

    const attendees = getEventAttendees(this.props.event);
    const attendeesByStatus = {
      [ATTENDEE_STATUS.YES]: [],
      [ATTENDEE_STATUS.NO]: [],
      [ATTENDEE_STATUS.AWAITING]: [],
    };

    attendees.forEach((a) => {
      if (a.email === this.props.currentUser.email) {
        return;
      }

      const status = this.responseDictionary(a.responseStatus);

      if (Object.keys(attendeesByStatus).includes(status)) {
        attendeesByStatus[status] = attendeesByStatus[status].concat(a);
      } else {
        attendeesByStatus[status] = [a];
      }
    });

    return attendeesByStatus;
  }

  createExistingAttendeeResponseInfo() {
    if (!this.doesEventHaveAttendees()) {
      return null;
    }

    const attendees = getEventAttendees(this.props.event);
    let attendeesInfo = {
      [ATTENDEE_STATUS.YES]: 0,
      [ATTENDEE_STATUS.NO]: 0,
      [ATTENDEE_STATUS.AWAITING]: 0,
    };

    attendees.forEach((a) => {
      if (a.email === this.props.currentUser.email) {
        return;
      }

      const status = this.responseDictionary(a.responseStatus);

      if (Object.keys(attendeesInfo).includes(status)) {
        const currentCount = attendeesInfo[status];

        attendeesInfo[status] = currentCount + 1;
      } else {
        attendeesInfo[status] = 0;
      }
    });

    return attendeesInfo;
  }

  responseDictionary(response) {
    switch (response) {
      case attendee_event_attending:
        return ATTENDEE_STATUS.YES;
      case attendee_event_tentative:
        return ATTENDEE_STATUS.AWAITING;
      case attendee_event_declined:
        return ATTENDEE_STATUS.NO;
      case attendee_event_needs_action:
        return ATTENDEE_STATUS.AWAITING;
      default:
        return ATTENDEE_STATUS.AWAITING;
    }
  }

  createExistingAttendeesEmailBool(attendeesInfo) {
    if (!attendeesInfo) {
      return {};
    }

    let existingAttendeesEmailBoolean = {};

    Object.keys(attendeesInfo).forEach((a) => {
      existingAttendeesEmailBoolean[a] = true;
    });

    return existingAttendeesEmailBoolean;
  }

  doesEventHaveAttendees() {
    return getEventAttendees(this.props.event)?.length > 0;
  }

  bindMouseTrap() {
    const activeCommandCentersKey = getActiveCommandCentersKeyForMousetrap();
    Mousetrap.bind(
      [`${activeCommandCentersKey}+shift+a`],
      this.openAddAttachmentModal
    );
    Mousetrap.bind([`${activeCommandCentersKey}+shift+.`], this.onClickCancel);
    Mousetrap.bind([`${activeCommandCentersKey}+enter`], this.onClickSend);
    Mousetrap.bind(
      [
        `${activeCommandCentersKey}+;`,
        `${activeCommandCentersKey} ;`,
        `${activeCommandCentersKey}+shift+,`,
      ],
      () => this.openTemplateCommandCenter()
    );
  }

  onSelectUserIndex(index) {
    this.setState({ selectedUserIndex: index });
  }

  unbindMouseTrap() {
    const activeCommandCentersKey = getActiveCommandCentersKeyForMousetrap();
    Mousetrap.unbind(
      [`${activeCommandCentersKey}+shift+a`],
      this.openAddAttachmentModal
    );
    Mousetrap.unbind(
      [`${activeCommandCentersKey}+shift+.`],
      this.onClickCancel
    );
    Mousetrap.unbind([`${activeCommandCentersKey}+enter`], this.onClickSend);
    Mousetrap.unbind(
      [
        `${activeCommandCentersKey}+;`,
        `${activeCommandCentersKey} ;`,
        `${activeCommandCentersKey}+shift+,`,
      ],
      () => this.openTemplateCommandCenter()
    );
  }

  onPasteInSticky(text) {
    if (!text) {
      return;
    }

    const { subject, message } = this.state;

    if (this._lastSection === SUBJECT_FIELD) {
      this.setState({ subject: `${subject} ${text}` });
      this._subject?.current?.focus();
    } else {
      // this._lastSection === MESSAGE_FIELD
      const updatedMessage = `${message} ${text} `;
      this.setState({ message: updatedMessage });
      document.getElementById(EMAIL_TEXT_AREA).value = updatedMessage;
      this._message?.current?.focus();
    }
  }

  openTemplateCommandCenter(where) {
    this._lastSection = where;
    Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER");
  }

  isMaestroExecEvent() {
    const userEmail = this.props.event?.userEmail;
    if (!userEmail) {
      return false;
    }

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const matchingExec = getAllExecutives({ allLoggedInUsers }).find(
      (u) => u.email === userEmail
    );
    return !!matchingExec;
  }

  getUsers() {
    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;

    return getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: this.isMaestroExecEvent()
        ? SELECT_USER_TYPE.PRIMARY_AND_EXECUTIVES
        : SELECT_USER_TYPE.ALL_USERS,
      eventUserEmail: getEventUserEmail(this.props.event),
      addExecutiveLabel: isUserMaestroUser(masterAccount),
    });
  }

  getUserIndexFromEventUserEmail() {
    const { event, currentUser } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const allUsers = this.getUsers();
    if (isEmptyObjectOrFalsey(event) || !getEventUserEmail(event)) {
      const matchingIndex = allUsers.findIndex((u) => 
        isSameEmail(getObjectEmail(u?.value), getUserEmail(currentUser))
      );
      if (matchingIndex === -1) {
        return 0;
      }
      return matchingIndex;
    }

    // can be -1
    const matchingIndex = allUsers.findIndex((u) => 
      isSameEmail(getUserEmail(u?.value), getEventUserEmail(event))
    );
    if (matchingIndex === -1) {
      return 0;
    }
    const matchingUser = allUsers[matchingIndex]; // {label: 'seamus@vimcal.com (Executive)', value: {…}}

    const shouldDefaultToEA = getDefaultEmailSendAsOption({
      masterAccount,
      user: currentUser
    }) === SEND_EMAIL_AS.EA;
    if (shouldDefaultToEA &&
      isMaestroUserOnDelegatedAccount({ masterAccount, user: matchingUser?.value }) &&
      getDelegatedUserAuthenticatedUser(matchingUser?.value) &&
      allUsers.find((u) => isSameEmail(getObjectEmail(u?.value), getUserEmail(getDelegatedUserAuthenticatedUser(matchingUser?.value))))
    ) {
      // send as EA if on delegated account
      const matchingMaestroUserIndex = allUsers.findIndex((u) => isSameEmail(getUserEmail(u?.value), getUserEmail(getDelegatedUserAuthenticatedUser(matchingUser?.value))));
      if (matchingMaestroUserIndex !== -1) {
        return matchingMaestroUserIndex;
      }
    }

    return matchingIndex;
  }

  handleUploadQueue() {
    /* Return if component is not mounted */
    /* Or if there is no draft email id */
    /* Or if no files need to be uploaded */
    /* Or if a file is currently being uploaded */
    if (
      !this._isMounted ||
      !this.state.draftEmailId ||
      isEmptyArray(this.state.pendingFileUploads) ||
      !!this.state.currentFileUpload
    ) {
      return;
    }

    /* Shallow copy array and get the first file */
    const updatedPendingFileUploads = [...this.state.pendingFileUploads];
    const fileToUpload = updatedPendingFileUploads.shift();

    /* Set current file to upload then send request afterwards */
    this.setState(
      {
        currentFileUpload: fileToUpload,
      },
      async () => {
        /* Convert file to base64 */
        try {
          const fileContent = await toBase64(fileToUpload);
          const path = "attach_file_to_draft_outlook_email";
          const url = constructRequestURL(path, true);
          const payloadData = {
            body: JSON.stringify({
              draft_email_id: this.state.draftEmailId,
              file_name: fileToUpload.name,
              /* Strip the type from the encoded string */
              file_content: fileContent?.split(",")[1],
            }),
          };

          const emailForBackend = getUserEmail(this.getSelectedUser());
          Fetcher.post(url, payloadData, true, emailForBackend)
            .then((response) => {
              if (!this._isMounted) {
                return;
              }

              if (!response || !response.file_id) {
                this.setState({
                  currentFileUpload: null,
                  pendingFileUploads: updatedPendingFileUploads,
                  failedFileUploads: [
                    ...this.state.failedFileUploads,
                    {
                      file: fileToUpload,
                      errors: ["Failed to upload to Outlook"],
                    },
                  ],
                });

                return;
              }

              if (response.error) {
                broadcastOpenPermissionModal({
                  error: isNeedToShowUserConsentScreen(response)
                    ? PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN
                    : response.error,
                  userEmail: getUserEmail(response.user) || emailForBackend,
                  provider: response.user?.provider,
                  allLoggedInUsers:
                    this.props.allLoggedInUsers.allLoggedInUsers,
                });

                this.setState({
                  currentFileUpload: null,
                  pendingFileUploads: updatedPendingFileUploads,
                  failedFileUploads: [
                    ...this.state.failedFileUploads,
                    {
                      file: fileToUpload,
                      errors: ["Failed to upload to Outlook"],
                    },
                  ],
                });

                return;
              }

              this.setState({
                currentFileUpload: null,
                pendingFileUploads: updatedPendingFileUploads,
                successfulFileUploads: [
                  ...this.state.successfulFileUploads,
                  {
                    file: fileToUpload,
                    fileId: response.file_id,
                  },
                ],
              });
            })
            .catch(handleError);
        } catch (error) {
          handleError(error);
          return;
        }
      }
    );
  }

  isResponseError(response) {
    return isEmptyObjectOrFalsey(response) || response?.error;
  }

  handleErrorFromResponse({ response, emailForBackend }) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    broadcastOpenPermissionModal({
      error: isNeedToShowUserConsentScreen(response)
        ? PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN
        : response?.error,
      userEmail: getUserEmail(response?.user) || emailForBackend,
      provider: response?.user?.provider,
      allLoggedInUsers,
    });
  }

  openAddAttachmentModal(e) {
    hasEventPreventDefault(e);
    if (!this.shouldDisplayEmailAttachments()) {
      return;
    }
    if (this._dropZone?.current?.open) {
      this._dropZone?.current?.open();
    }
  }

  handleRetryFileUpload(file) {
    const matchingFailedFileIndex = this.state.failedFileUploads.findIndex(
      (fileWithError) => fileWithError.file === file
    );
    let updatedFailedFileUploads = [...this.state.failedFileUploads];

    if (matchingFailedFileIndex !== -1) {
      updatedFailedFileUploads = this.state.failedFileUploads.toSpliced(
        matchingFailedFileIndex,
        1
      );
    }

    this.setState({
      pendingFileUploads: [...this.state.pendingFileUploads, file],
      failedFileUploads: updatedFailedFileUploads,
    });
  }
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

function mapStateToProps(state) {
  const { currentUser, currentTimeZone, isDarkMode } = state;

  return {
    currentUser,
    currentTimeZone,
    isDarkMode,
  };
}

export default withRouter(
  connect(mapStateToProps, null)(withStore(EmailAttendees))
);
