import classNames from "classnames";
import React from "react";
import { WHITE_BUTTON } from "../services/globalVariables";

function CustomButtonBox(props) {
  return (
    <button
      // className={`custom-button-box default-button ${props.className || ""}`}
      className={classNames(
        "custom-button-box overflow-y-hidden rounded-lg",
        WHITE_BUTTON,
        props.className ?? "",
        "font-weight-500",
        "min-width-initial-important",
        props.isDisabled ? "pointer-events-none" : ""
      )}
      tabIndex={props.buttonTabIndex}
      onClick={props.onClick}
      style={Object.assign({}, props.style)}
    >
      {props.children}
    </button>
  );
}

export default CustomButtonBox;
