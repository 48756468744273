import React, { Component } from "react";
import Checkbox from "./checkbox";
import SaveButton from "./saveButton";
import { connect } from "react-redux";
import _ from "underscore";
import GoogleCalendarService from "../services/googleCalendarService";
import SelectTimeZoneInput from "./selectTimeZoneInput";
import classNames from "classnames";
import { SAVE_BUTTON_HEIGHT } from "../services/globalVariables";

let { selectTimeZone } = GoogleCalendarService;

class SelectTimeZone extends Component {
  constructor(props) {
    super(props);

    this.state = this.constructInitialState();

    this.toggleSeparateStartEndTimezone =
      this.toggleSeparateStartEndTimezone.bind(this);
    this.setTimeZone = this.setTimeZone.bind(this);
    this.onChangeStartTimeZone = this.onChangeStartTimeZone.bind(this);
    this.onChangeEndTimeZone = this.onChangeEndTimeZone.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._searchLocationTimer && clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;

    this.locationAutocomplete = null;

    this._isMounted = false;
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            isChecked={this.state.separateStartEndTimezone}
            onChange={this.toggleSeparateStartEndTimezone}
          />

          <div 
            className={classNames("ml-2.5 cursor-pointer select-none event-form-default-font-size", "default-text-color")}
            onClick={this.toggleSeparateStartEndTimezone}
          >
            {"Use separate start and end time zone"}
          </div>
        </div>

        {this.xorStartEndTimeZoneWithSelectTimeZone() &&
          this.state.separateStartEndTimezone && (
            <div
              style={{
                marginLeft: 10,
                marginTop: 10,
                color: "#FF8A65",
                fontSize: 14,
              }}
            >
              One time zone can not be select time zone
            </div>
          )}

        <div
          className="create-event-title mt-5 ml-2.5 mb-2.5"
        >
          {"Event start time zone"}
        </div>

        <SelectTimeZoneInput
          autoFocus={true}
          tabIndex={1}
          timeZone={this.state.startTimeZone}
          onChange={this.onChangeStartTimeZone}
        />

        {this.state.separateStartEndTimezone && (
          <div>
            <div
              className="create-event-title ml-2.5 mt-6 mb-2.5"
            >
              {"Event end time zone"}
            </div>

            <SelectTimeZoneInput
              autoFocus={false}
              tabIndex={2}
              timeZone={this.state.endTimeZone}
              onChange={this.onChangeEndTimeZone}
            />
          </div>
        )}

        <SaveButton
          tabIndex={3}
          width={"100%"}
          height={SAVE_BUTTON_HEIGHT}
          marginTop={140}
          onClick={this.setTimeZone}
          disabled={this.shouldSaveButtonBeDisabled()}
        />
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  shouldSaveButtonBeDisabled() {
    let isStateEqual = _.isEqual(this.state, this.constructInitialState());

    return (
      (this.state.separateStartEndTimezone &&
        this.xorStartEndTimeZoneWithSelectTimeZone()) ||
      isStateEqual
    );
  }

  xorStartEndTimeZoneWithSelectTimeZone() {
    return (
      (this.state.startTimeZone.value === selectTimeZone &&
        !(this.state.endTimeZone.value === selectTimeZone)) ||
      (!(this.state.startTimeZone.value === selectTimeZone) &&
        this.state.endTimeZone.value === selectTimeZone)
    );
  }

  setTimeZone() {
    const { endTimeZone, setEventTimeZone, startTimeZone } = this.props;

    if (!startTimeZone.isAddOn && !endTimeZone.isAddOn) {
      setEventTimeZone(this.createTimeZoneObject());
    }
  }

  toggleSeparateStartEndTimezone() {
    this.setState((prevState) => {
      return {
        separateStartEndTimezone: !prevState.separateStartEndTimezone,
      };
    });
  }

  //=================
  // PRIVATE METHODS
  //=================

  constructInitialState() {
    const { endTimeZone, separateStartEndTimezone, startTimeZone } = this.props;

    return {
      separateStartEndTimezone,
      startTimeZone,
      endTimeZone,
    };
  }

  createTimeZoneObject() {
    const { endTimeZone, separateStartEndTimezone, startTimeZone } = this.state;

    return {
      start: startTimeZone,
      end: separateStartEndTimezone ? endTimeZone : startTimeZone,
    };
  }

  onChangeStartTimeZone(option) {
    this.setState({ startTimeZone: option });
  }

  onChangeEndTimeZone(option) {
    this.setState({ endTimeZone: option });
  }
}

function mapStateToProps(state) {
  let { currentTimeZone } = state;

  return { currentTimeZone };
}

export default connect(mapStateToProps)(SelectTimeZone);
