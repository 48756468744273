import React from "react";
import { Twitter } from "react-feather";
import Broadcast from "../../../broadcasts/broadcast";
import { OpenLink } from "../../../services/commonUsefulFunctions";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";
import { LAYOUT_BROADCAST_VALUES } from "../../../lib/broadcastValues";

const ImproveVimcal = () => {
  return (
    <div className="side-menu-tool-bar-section-wrapper">
      <div className="side-menu-tool-bar-text">Show Love</div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => Broadcast.publish("SHOW_REFER_TO_VIMCAL_MODAL")}
      >
        <div className="side-menu-description-text">Refer to Vimcal 🎁</div>
      </div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK)}
      >
        <div className="side-menu-description-text">Share Feedback ❤️</div>
      </div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => OpenLink("https://twitter.com/vimcal")}
      >
        <div className="side-menu-description-text">Follow @Vimcal on Twitter</div>
        <Twitter size={13} color="#1DA1F3" className="ml-1.5" fill="#1DA1F3" />
      </div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => layoutBroadcast.publish("OPEN_AFFILIATE_MODAL")}
      >
        <div className="side-menu-description-text">Join our affiliate program 🤝</div>
      </div>
    </div>
  );
};

export default ImproveVimcal;
