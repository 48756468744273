import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../select";
import { createLabelAndValueForReactSelect } from "../../services/commonUsefulFunctions";
import { DAYS_INTO_THE_FUTURE_REACT_SELECT } from "./resources/availabilityVariables";

const BufferFromNow = ({
  bufferFromNow,
  hasChanged,
  setBufferFromNow,
  setHasChanged,
  isPersonalLinks,
}) => {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const minuteList = createLabelAndValueForReactSelect([
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
  ]);

  const hourList = createLabelAndValueForReactSelect([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ]);

  return (
    <div className={classNames(isPersonalLinks ? "margin-top-twenty" : "")}>
      <div className="font-weight-400 default-font-size secondary-text-color">
        Buffer from current time
      </div>
      {!isPersonalLinks ? (
        <div className="mt-5 font-size-12 secondary-text-color">
          The earliest someone can book a meeting with you. E.g., select ‘1 day’
          to not be booked within the next 24 hours.
        </div>
      ) : null}

      <div className="display-flex-flex-direction-column mt-2">
        <div
          className={classNames(
            "display-flex-flex-direction-row align-items-center",
            "margin-bottom-ten"
          )}
        >
          <CustomSelect
            isSearchable={true}
            className={classNames(
              "select-duration-time",
              isDarkMode ? "dark-mode-select" : "",
              "select-default-font-size"
            )}
            classNamePrefix="dark-mode"
            value={{
              value: bufferFromNow?.days,
              label: bufferFromNow?.days,
            }}
            options={[{value: 0, label: 0}].concat(DAYS_INTO_THE_FUTURE_REACT_SELECT)}
            onChange={(option) => {
              setBufferFromNow({ ...bufferFromNow, days: option.value });
              if (!hasChanged && !isPersonalLinks) {
                setHasChanged(true);
              }
            }}
            openMenuOnFocus={true}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={true}
            tabIndex={3}
            maxMenuHeight={150}
          />
          <div className="font-weight-300 default-font-size ml-2.5 margin-right-5">
            days
          </div>
        </div>

        <div
          className={classNames(
            "display-flex-flex-direction-row align-items-center",
            "margin-bottom-ten"
          )}
        >
          <CustomSelect
            isSearchable={true}
            className={classNames(
              "select-duration-time",
              isDarkMode ? "dark-mode-select" : "",
              "select-default-font-size"
            )}
            classNamePrefix="dark-mode"
            value={{
              value: bufferFromNow?.hours,
              label: bufferFromNow?.hours,
            }}
            options={hourList}
            onChange={(option) => {
              setBufferFromNow({ ...bufferFromNow, hours: option.value });
              if (!hasChanged && !isPersonalLinks) {
                setHasChanged(true);
              }
            }}
            openMenuOnFocus={true}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={true}
            tabIndex={2}
            maxMenuHeight={150}
          />
          <div className="font-weight-300 default-font-size ml-2.5 margin-right-5">
            hours
          </div>
        </div>

        <div className="display-flex-flex-direction-row align-items-center">
          <CustomSelect
            isSearchable={true}
            className={classNames(
              "select-duration-time",
              isDarkMode ? "dark-mode-select" : "",
              "select-default-font-size"
            )}
            classNamePrefix="dark-mode"
            value={{
              value: bufferFromNow?.minutes,
              label: bufferFromNow?.minutes,
            }}
            options={minuteList}
            onChange={(option) => {
              setBufferFromNow({ ...bufferFromNow, minutes: option.value });
              if (!hasChanged && !isPersonalLinks) {
                setHasChanged(true);
              }
            }}
            openMenuOnFocus={true}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={true}
            tabIndex={4}
            maxMenuHeight={100}
          />
          <div className="font-weight-300 default-font-size ml-2.5">mins</div>
        </div>
      </div>
    </div>
  );
};

export default BufferFromNow;
