import React from "react";

function DisabledButton(props) {
  return (
    <div className="custom-button-box button-disabled width-90px text-white">
      {props.label || "Save"}
    </div>
  );
}

export default DisabledButton;
