import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import type { DexieDistroList } from "../db";

interface EventHotKey {
  handler: string
  label: string
  event: VimcalEvent | VimcalPrivateEvent | TemporaryEvent
  sequentialHandler: string
  sequentialHandlerCap: string
}

interface EventHotKeysIndexState {
  eventHotKeysIndex: Record<string, EventHotKey>
  setEventHotKeysIndex: (eventHotKeysIndex: Record<string, EventHotKey>) => void
  resetEventHotKeysIndex: () => void
}

export const useEventHotKeysIndex = create<EventHotKeysIndexState>(
  subscribeWithSelector((set) => ({
    eventHotKeysIndex: {},
    setEventHotKeysIndex: (eventHotKeysIndex) =>
      set(() => ({ eventHotKeysIndex })),
    resetEventHotKeysIndex: () => set(() => ({ eventHotKeysIndex: {} })),
  })),
);

interface HiddenEventsIDsState {
  hiddenEventsIDs: string[]
  setHiddenEventsIDs: (ids: string[]) => void
  addHiddenEventsIDs: (ids: string[]) => void
  resetHiddenEventsIDs: () => void
}

export const useHiddenEventsIDs = create<HiddenEventsIDsState>((set) => ({
  hiddenEventsIDs: [],
  setHiddenEventsIDs: (hiddenEventsIDs) =>
    set(() => ({ hiddenEventsIDs })),
  addHiddenEventsIDs: (ids) =>
    set((state) => {
      const uniqueIDs = new Set(state.hiddenEventsIDs);
      ids.forEach(id => uniqueIDs.add(id));
      return { hiddenEventsIDs: Array.from(uniqueIDs) };
    }),
  resetHiddenEventsIDs: () => set(() => ({ hiddenEventsIDs: [] })),
}));

interface OOOBusyEventColorAndRangeType {
  color: string
  range: {eventStart: Date, eventEnd: Date}
  resourceId: string
  ranking: number
}

interface OOOBusyEventsDictionaryState {
  OOOBusyEventsColorAndRange: OOOBusyEventColorAndRangeType[] | null
  setOOOBusyEventsColorAndRange: (OOOBusyEventsColorAndRange: OOOBusyEventColorAndRangeType[]) => void
  resetOOOBusyEventsColorAndRange: () => void
}

// ranking comes from getOOOBusyColumnRanking which allows for fast comparison
export const useOOOBusyEventsDictionaryStore = create<OOOBusyEventsDictionaryState>((set) => ({
  OOOBusyEventsColorAndRange: null,
  setOOOBusyEventsColorAndRange: (OOOBusyEventsColorAndRange) =>
    set(() => ({ OOOBusyEventsColorAndRange })),
  resetOOOBusyEventsColorAndRange: () =>
    set(() => ({ OOOBusyEventsColorAndRange: null })),
}));

interface DistroListDictionaryState {
  distroListDictionary: Record<string, DexieDistroList>
  setDistroListDictionary: (distroListDictionary: Record<string, DexieDistroList>) => void
  resetDistroListDictionary: () => void
}

export const useDistroListDictionary = create<DistroListDictionaryState>((set) => ({
  distroListDictionary: {},
  setDistroListDictionary: (distroListDictionary) => {
    if (!distroListDictionary) {
      // sanity check
      return;
    }
    set(() => ({ distroListDictionary }));
  },
  resetDistroListDictionary: () =>
    set(() => ({ distroListDictionary: {} })),
}));
