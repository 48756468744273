import { trackEvent } from "../../components/tracking";
import { isElectron } from "../../services/commonUsefulFunctions";
import { tooltipKeys } from "../../services/tooltipVariables";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { getUserToken } from "../userFunctions";

const VERSIONS = {
  V1: "v1", // tracking with 2 options (select time from calendly or choose selfserve vs personal onboarding in the beginning)
  V2: "v2", // shorten onboarding and instead of cmd c -> use green button and move download section to the back
  V3: "v3", // with what do you want to do
  V4: "V4", // with onboarding option with recommended
  V5: "v5", // add new account
  V6: "v6", // onboarding with typeform all included
  V7: "v7", // onboarding split test with calendly vs our own booking link
  V8: "v8", // updated on aug 1 2024 -> routes 1/3 of superhuman/calendly users who are not EAs to self serve.
  V9: "v9", // updated on sept 23 2024 -> updated index
  V10: "v10", // updated on oct 29 2024 -> routes 2/3 of superhuman/calendly users who are not EAs to self serve.
  V11: "v11", // updated on dec 31 2024 -> routes all paying users to self serve
  V12: "v12", // updated on jan 27 2025 -> remove command center section
} as const;
// TODO: if add new version above, need to update CURRENT_VERSION_BELOW

const CURRENT_VERSION: ValueOf<typeof VERSIONS> = VERSIONS.V12;
export const ONBOARD_STEP = {
  QUESTION_OTHER_PRODUCTS: "question other products",
  QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS:
    "question number of executive assistants",
  QUESTION_ABOUT_CURRENT_ROLE: "question about current role",
  CHOOSE_ONBOARDING_PAGE: "choose onboarding page",
  CALENDLY_BOOKING: "calendly booking",
  HOME: "onboard welcome page",
  THEME_SELECTION: "select theme",
  USER_NAME: "pick user name",
  COMMAND_K_GIF: "command center gif",
  COMMAND_CENTER_PROMPT: "command center prompt",
  INTERACTIVE_COMMAND_CENTER: "interactive command center",
  SLOTS_GIF: "slots gif",
  INTERACTIVE_SLOTS: "interactive slots",
  PASTE_IN_EMAIL: "paste in email",
  ADD_ADDITIONAL_ACCOUNT: "add additional accounts",
  DOWNLOAD_PAGE: "download apps",
  JOIN_TEAM_PLAN_BY_DOMAIN: "join team plan by domain",
  CREATE_TEAM_PLAN: "create team plan",
  ADD_TEAM_MEMBERS: "add team members",
  TEAM_THANK_YOU: "team thank you",
  FULL_PRODUCT: "full product",
} as const;

export const ONBOARD_STEP_ORDER: Readonly<ValueOf<typeof ONBOARD_STEP>[]> = [
  ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE,
  ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS,
  // TODO: add step in here with mandatory onboarding question,
  ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE,
  ONBOARD_STEP.CALENDLY_BOOKING,
  ONBOARD_STEP.HOME,
  ONBOARD_STEP.THEME_SELECTION,
  // ONBOARD_STEP.USER_NAME,
  isElectron() ? ONBOARD_STEP.USER_NAME : ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT,
  ONBOARD_STEP.COMMAND_K_GIF,
  ONBOARD_STEP.SLOTS_GIF,
  ONBOARD_STEP.INTERACTIVE_SLOTS,
  ONBOARD_STEP.PASTE_IN_EMAIL,
  ONBOARD_STEP.JOIN_TEAM_PLAN_BY_DOMAIN,
  ONBOARD_STEP.CREATE_TEAM_PLAN,
  ONBOARD_STEP.ADD_TEAM_MEMBERS,
  ONBOARD_STEP.DOWNLOAD_PAGE,
  ONBOARD_STEP.TEAM_THANK_YOU,
  ONBOARD_STEP.FULL_PRODUCT,
];

export const MAGIC_LINK_ONBOARD_STEP = {
  ADD_ADDITIONAL_ACCOUNT: "ADD_ADDITIONAL_ACCOUNT",
  SELECT_CALENDARS: "SELECT_CALENDARS",
  CONNECT_ACCOUNTS: "CONNECT_ACCOUNTS",
} as const;

export type OnboardStep = ValueOf<typeof ONBOARD_STEP> | ValueOf<typeof MAGIC_LINK_ONBOARD_STEP>

function getStepIndex(step: ValueOf<typeof ONBOARD_STEP>) {
  const index = ONBOARD_STEP_ORDER.indexOf(step);
  if (index < 10) {
    return `0${index}`; // so 12 is not infront of 9 and will make reading easier
  }
  return index;
}

function getOnboardingAction(step: OnboardStep) {
  if ((Object.values(MAGIC_LINK_ONBOARD_STEP) as OnboardStep[]).includes(step)) {
    return step;
  }

  return `${getStepIndex(step as ValueOf<typeof ONBOARD_STEP>)}_${step}`;
}

interface TrackOnboardingArgs {
  user: Optional<User | TruncatedUser>
  masterAccount: OptionalOrEmptyObject<MasterAccount>
  step: OnboardStep
  additionalInfo?: string
}

export function trackOnboarding({
  user,
  masterAccount,
  step,
  additionalInfo,
}: TrackOnboardingArgs) {
  const getAction = () => {
    if (!additionalInfo) {
      return getOnboardingAction(step);
    }
    return `${getOnboardingAction(step)} || ${additionalInfo}`;
  };
  const action = getAction();
  trackEvent({
    category: `onboarding-${CURRENT_VERSION}`,
    action,
    label: CURRENT_VERSION,
    userToken: getUserToken(user),
  });
}

export const ONBOARD_STEP_ZERO_EXPERIMENT = {
  CALENDLY: "calendly-on-appear",
  CHOICE: "choice-on-appear",
};

export const TUTORIAL_WIZARD_EXPERIMENT_BUCKET = {
  WIZARD: "wizard",
  CONTROL: "control",
};

export function getTutorialWizardBucket(_args: { masterAccount?: MasterAccount }) {
  return TUTORIAL_WIZARD_EXPERIMENT_BUCKET.WIZARD;
}

export function hasUserCompletedAllTutorial({ completedToolTips }: { completedToolTips: string[] }) {
  if (isEmptyArrayOrFalsey(completedToolTips)) {
    return false;
  }
  return (
    completedToolTips.includes(tooltipKeys.SLOTS) &&
    completedToolTips.includes(tooltipKeys.NLP) &&
    completedToolTips.includes(tooltipKeys.TIME_TRAVEL)
  );
}
