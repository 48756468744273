import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSlotsPresets } from "../../lib/availabilityFunctions";
import { HOLD_COLOR_ID } from "../../lib/vimcalVariables";
import {
  useAllCalendars,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import SelectColor from "../selectColor";
import DefaultSwitch from "../defaultSwitch";
import { HelpCircle } from "react-feather";
import { getCalendarColorHex } from "../../services/calendarAccessors";
import {
  doesCalendarHaveCategories,
  isCalendarOutlookCalendar,
} from "../../lib/calendarFunctions";
import { blurCalendar, isGoogleUser } from "../../services/appFunctions";
import classNames from "classnames";
import SelectCategories from "../eventForm/selectCategories";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";

export default function SlotsHoldsSection({
  attendees,
  onKeyDownSelect,
  onChangeSlotsHoldTitle,
  onChangeHoldsColorID,
  overrideColorID,
  onChangeShouldAddAttendeesToHolds,
  selectedCalendar,
  shouldAddAttendeesToHolds,
  title,
  selectedUser,
  isDisabled,
  onChangeCategories,
  selectedOutlookCategories,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories
  );
  const [showAttendeesHint, setShowAttendeesHint] = useState(false);

  const slotPresets = getSlotsPresets({
    allCalendars,
    currentUser: selectedUser ?? currentUser,
    masterAccount,
  });
  const renderHoldsTitleSection = () => {
    return (
      <div className="display-flex-flex-direction-row align-items-center justify-content-space-between margin-top-20">
        <div className="default-font-size font-weight-300 truncate-text max-width-100px width-100px">
          Title
        </div>
        <input
          className={classNames(
            "default-input-field",
            isDisabled
              ? "text-area-secondary-text-color-override select-none"
              : ""
          )}
          onChange={onChangeSlotsHoldTitle}
          // placeholder="Recipient of Slots"
          style={{ width: 220, padding: 10 }}
          onKeyDown={(key) => {
            if (onKeyDownSelect) {
              onKeyDownSelect(key);
            }
          }}
          value={title}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const renderHoldsColorSection = () => {
    const hasOutlookCategories =
      isCalendarOutlookCalendar(selectedCalendar) &&
      doesCalendarHaveCategories({
        calendar: selectedCalendar,
        outlookCategories: outlookCategories,
      });
    if (isCalendarOutlookCalendar(selectedCalendar) && !hasOutlookCategories) {
      // only show holds if there's categories and outlook
      return null;
    }

    const renderColorSelect = () => {
      if (isCalendarOutlookCalendar(selectedCalendar)) {
        return (
          <SelectCategories
            initialCategories={selectedOutlookCategories}
            onChange={(newCategories) => {
              if (onChangeCategories) {
                onChangeCategories(newCategories);
              }
            }}
            selectedCalendar={selectedCalendar}
            containerClassName={"width-220px"}
            showBorder={true}
            isDisabled={isDisabled}
          />
        );
      }
      return (
        <SelectColor
          onChange={(data) => {
            onChangeHoldsColorID(data.value);
          }}
          selectedColorID={overrideColorID ?? slotPresets[HOLD_COLOR_ID]}
          showBorder={true}
          locationRight={0}
          defaultColorOverride={getCalendarColorHex(selectedCalendar)}
          selectedUser={selectedUser}
          isDisabled={isDisabled}
        />
      );
    };

    return (
      <div className="display-flex-flex-direction-row align-items-center mt-2.5">
        <div className="default-font-size font-weight-300 truncate-text width-100px mr-auto">
          Holds color
        </div>
        {renderColorSelect()}
      </div>
    );
  };

  const renderHoldsAttendeesToggleSection = () => {
    const onClickToggle = () => {
      const updatedShouldAddAttendeesToHolds = !shouldAddAttendeesToHolds;
      blurCalendar();

      onChangeShouldAddAttendeesToHolds(updatedShouldAddAttendeesToHolds);
    };

    return (
      <div className="display-flex-flex-direction-row align-items-center mt-2.5 py-2">
        <div
          className="default-font-size font-weight-300 cursor-pointer select-none"
          onClick={onClickToggle}
        >
          Add attendees
        </div>

        <div
          className="flex align-items-center mx-1 mr-auto relative"
          onMouseEnter={() => setShowAttendeesHint(true)}
          onMouseLeave={() => setShowAttendeesHint(false)}
        >
          <HelpCircle className="clickable-icon" size={18} />
          {showAttendeesHint ? (
            <div
              className={classNames(
                "attendee-suggestion-item absolute -left-24 flex flex-col",
                isDarkMode
                  ? "background-color-modal-background-color"
                  : "bg-white",
                "more-information-modal"
              )}
            >
              <div>
                {`When toggled on, the holds will also display on all attendees'
                calendars.`}
              </div>
              <div className="mt-4">
                This will automatically be disabled if there are no attendees.
              </div>
            </div>
          ) : null}
        </div>

        <DefaultSwitch
          disabled={attendees?.length === 0 || isDisabled}
          id="holds-add-attendee-id"
          isChecked={shouldAddAttendeesToHolds}
          onChange={(isOn) => {
            blurCalendar();
            onChangeShouldAddAttendeesToHolds(isOn);
          }}
        />
      </div>
    );
  };

  const renderAsterick = () => {
    if (!isGoogleUser(selectedUser) || !shouldAddAttendeesToHolds) {
      return null;
    }
    return (
      <div className="default-font-size secondary-text-color">
        *Internal teammates will not receive notifications when holds are
        created.
      </div>
    );
  };

  return (
    <>
      <p className="font-size-12 font-weight-300 create-availability-slots-hold-description secondary-text-color">
        Fill in details for the calendar holds that will be auto-created from
        the Slots you dragged
      </p>
      {renderHoldsTitleSection()}
      {renderHoldsColorSection()}
      {renderHoldsAttendeesToggleSection()}
      {renderAsterick()}
    </>
  );
}
