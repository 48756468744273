import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { X } from "react-feather";
import { hasStopEventPropagation } from "../services/commonUsefulFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import { SECOND_IN_MS, SET_GLOBAL_SHORT_CUT_SUGGESTION } from "../services/globalVariables";
import ShortcutTile from "./shortcutTiles/shortcutTile";

export default function ShortcutSuggestion(props) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [hotkey, setHotKey] = useState(null);
  const [text, setText] = useState("");
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const componentIsMounted = useIsMounted();
  const timer = useRef(null);

  const removeSuggestion = (e) => {
    e && hasStopEventPropagation(e);

    if (componentIsMounted.current) {
      removeTimer();
      setShouldDisplay(false);
      setTimeout(() => {
        if (!componentIsMounted.current) {
          return;
        }
        setHotKey(null);
        setText("");
      }, 500);
    }
  };

  const removeTimer = () => {
    clearTimeout(timer.current);
    timer.current = null;
  };

  useEffect(() => {
    mainCalendarBroadcast.subscribe(
      SET_GLOBAL_SHORT_CUT_SUGGESTION,
      updateShortcutSuggestion
    );
    mainCalendarBroadcast.subscribe(
      "REMOVE_GLOBAL_SHORT_CUT_SUGGESTION",
      removeSuggestion
    );
    return () => {
      mainCalendarBroadcast.unsubscribe(SET_GLOBAL_SHORT_CUT_SUGGESTION);
      mainCalendarBroadcast.unsubscribe("REMOVE_GLOBAL_SHORT_CUT_SUGGESTION");
      if (timer.current) {
        removeTimer();
      }
    };
  }, []);

  const updateShortcutSuggestion = (hotkey, text) => {
    removeTimer();
    setShouldDisplay(true);
    setHotKey(hotkey);
    setText(text);

    timer.current = setTimeout(() => {
      removeSuggestion();
    }, 4.5 * SECOND_IN_MS);
  };

  return (
    <div
      className={classNames(
        "absolute flex items-center justify-center",
        "bottom-8 rounded px-5 py-2 default-font-size overflow-hidden select-none",
        "shortcut-suggestion-hint-location",
        isDarkMode ? "event-form-background-color medium-blur" : "bg-gray-300 box-shadow-6",
        "transition-opacity duration-500 ease-in-out",
        "z-index-9999",
        shouldDisplay ? "opacity-100" : "opacity-0",
        hotkey && text ? "visible" : "invisible",
        "rounded-md"
      )}
    >
      <div>Next time, hit</div>
      <ShortcutTile isCommandCenter={false} shortcut={hotkey} marginBottom={1} inputClassname={"mb-0.5"} />
      <div className="ml-1">to {`${text}`} instantly!</div>

      <X
        size={16}
        className="ml-3 mb-0.5 clickable-icon"
        onClick={removeSuggestion}
      />
    </div>
  );
}
