import React, { Component } from "react";
import { connect, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import StyleConstants from "../services/globalVariables";
import {
  hasStateOrPropsChanged,
  getTodayDate,
  hasStopEventPropagation,
} from "../services/commonUsefulFunctions";
import { addDays, format, isSameDay, startOfDay } from "date-fns";
import Classname from "classnames";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  isInActionMode,
  isPopUpExpandedViewShowing,
} from "../services/appFunctions";
import { MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { RBC_VARIABLES } from "../lib/rbcFunctions";

class CustomDayHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverOverDate: false,
    };

    this.onClickDate = this.onClickDate.bind(this);
    this.onMouseEnterDay = this.onMouseEnterDay.bind(this);
    this.onMouseLeaveDay = this.onMouseLeaveDay.bind(this);
    this.createAllDayEvent = this.createAllDayEvent.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location", "date", "localizer"],
      null
    );
  }

  render() {
    return (
      <div
        className={this.headerDayClassName()}
        onClick={this.createAllDayEvent}
      >
        <div
          onMouseEnter={this.onMouseEnterDay}
          onMouseLeave={this.onMouseLeaveDay}
          onClick={this.onClickDate}
          className={this.dayOfWeekClassName()}
        >
          {format(this.props.date, "ccc").toUpperCase()}
        </div>

        <div
          onMouseEnter={this.onMouseEnterDay}
          onMouseLeave={this.onMouseLeaveDay}
          onClick={this.onClickDate}
          className={this.dayOfMonthClassName()}
        >
          {format(this.props.date, "d")}
        </div>
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onClickDate() {
    batch(() => {
      this.updateAgendaDay();
      mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
      this.props.history.push("/home");
      this.props.selectDay(this.props.date);
    });

    mainCalendarBroadcast.publish(
      MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
      1,
      this.props.date
    );
  }

  onMouseEnterDay() {
    this.updateAgendaDay();
    this.setState({ hoverOverDate: true });
  }

  onMouseLeaveDay() {
    this.updateAgendaDay(getTodayDate(this.props.currentTimeZone));
    this.setState({ hoverOverDate: false });
  }

  //=================
  // PRIVATE METHODS
  //=================

  createAllDayEvent(e) {
    hasStopEventPropagation(e);

    if (isPopUpExpandedViewShowing()) {
      mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
      return;
    }

    if (!this.state.hoverOverDate) {
      // below is what RBC sends to us when a user clicks on an all day event
      // {
      //   "slots": [
      //       "2024-12-03T05:00:00.000Z"
      //   ],
      //   "start": "2024-12-03T05:00:00.000Z",
      //   "end": "2024-12-04T05:00:00.000Z",
      //   action: "click",
      //   resourceId: null
      // };
      // this.props.date
      const startDate = startOfDay(this.props.date);
      const param = {
        slots: [startDate],
        start: startDate,
        end: addDays(startDate, 1),
        action: RBC_VARIABLES.ACTION_CLICK,
        resourceId: null,
      };
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.ON_SELECT_TIME_SLOT,
        param
      );
    }
  }

  updateAgendaDay(date = this.props.date) {
    this.props.setAgendaDay(date);
  }

  dayOfWeekClassName() {
    return Classname(
      "font-size-10",
      "w-8",
      "h-2.5",
      "font-weight-400",
      "font",
      isInActionMode(this.props.actionMode)
        ? "cursor-default"
        : "cursor-pointer",
      this.determineDayOfWeekFontColor(),
      "transition-monthly-agenda-date",
      "leading-normal"
    );
  }

  dayOfMonthClassName() {
    return Classname(
      "mt-1.5",
      "rounded-md",
      "display-flex",
      "justify-content-center",
      "align-items-center",
      isInActionMode(this.props.actionMode)
        ? "cursor-default"
        : "cursor-pointer",
      this.determineDayOfMonthBackgroundColor(),
      "transition-monthly-agenda-date",
      this.props.isMobileView ? "font-size-16" : "font-size-18",
      "font-weight-300",
      this.props.isMobileView
        ? "custom-day-header-month-mobile"
        : "custom-day-header-month",
      (this.props.isDarkMode &&
        isSameDay(this.props.selectedDay, this.props.agendaDay) &&
        isSameDay(this.props.selectedDay, this.props.date)) ||
        isSameDay(this.props.date, getTodayDate(this.props.currentTimeZone))
        ? "text-white"
        : this.determineDayOfMonthFontColor()
    );
  }

  headerDayClassName() {
    return Classname(
      "cursor-default",
      "border-none",
      isInActionMode(this.props.actionMode) ? " pointer-events-none" : "",
      "display-flex",
      "flex-direction-column",
      "justify-content-center",
      "align-items-center",
      "pt-3"
    );
  }

  determineDefaultFontColor() {
    return this.props.isDarkMode
      ? StyleConstants.darkModeTextColor
      : StyleConstants.defaultFontColor;
  }

  determineDayOfMonthFontColor() {
    if (isSameDay(this.props.agendaDay, this.props.date)) {
      return this.props.isDarkMode ? "text-white" : "default-font-color";
    }

    return "default-font-color";
  }

  determineDayOfWeekFontColor() {
    if (isSameDay(this.props.date, getTodayDate(this.props.currentTimeZone))) {
      return "agenda-regular-blue";
    } else if (isSameDay(this.props.agendaDay, this.props.date)) {
      return "agenda-hover-day-color";
    } else {
      return "default-font-color";
    }
  }

  determineDayOfMonthBackgroundColor() {
    if (isSameDay(this.props.date, getTodayDate(this.props.currentTimeZone))) {
      return "agenda-regular-blue-background-color";
    } else if (isSameDay(this.props.agendaDay, this.props.date)) {
      return "custom-day-header-month-background-color";
    } else {
      return "bg-transparent";
    }
  }
}

function mapStateToProps(state) {
  let {
    agendaDay,
    isMac,
    currentTimeZone,
    selectedDay,
    isDarkMode,
    isMobileView,
    actionMode,
  } = state;

  return {
    agendaDay,
    isMac,
    currentTimeZone,
    selectedDay,
    isDarkMode,
    isMobileView,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomDayHeader));
