import React, { Component } from "react";
import { DEFAULT_FONT_SIZE_PX, VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND } from "../../services/globalVariables";
import { OpenLink } from "../../services/commonUsefulFunctions";

class VimcalLogoWithMessage extends Component {
  constructor(props) {
    super(props);

    this.goToVimcalWebsite = this.goToVimcalWebsite.bind(this);
  }

  render() {
    return null;
    return (
      <div
        className="display-flex-flex-direction-row cursor-pointer"
        onClick={this.goToVimcalWebsite}
      >
        <div className="margin-right-5 padding-top-3">
          <img alt="" width="32px" height="32px" src={VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND} />
        </div>

        <div>
          <div style={{ fontSize: DEFAULT_FONT_SIZE_PX, fontWeight: 300 }}>
            {this.props.message || "Try us out!"}
          </div>

          <div style={{ fontSize: 14, fontWeight: 500 }}>Vimcal</div>
        </div>
      </div>
    );
  }

  goToVimcalWebsite() {
    OpenLink("https://www.vimcal.com/");
  }
}

export default VimcalLogoWithMessage;
