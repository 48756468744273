import React from "react";
import classNames from "classnames";

export interface ContactHandleProps extends React.HTMLProps<HTMLDivElement> {
  disableDarkStyles?: boolean
}

export default function ContactHandle({ children, className, disableDarkStyles, onClick, ...props }: ContactHandleProps) {
  const clickable = typeof onClick !== "undefined";

  return (
    <div
      className={classNames(
        "contact-handle flex items-center justify-center rounded-full h-5 w-5",
        clickable ? "cursor-pointer clickable-contact-handle" : "",
        disableDarkStyles ? "" : "enable-dark",
        className,
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
}
