import { useEffect } from "react";
import Broadcast from "../broadcasts/broadcast";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { usePromotionsStore } from "../services/stores/finance";

export default function DefaultChecks() {
  // component performs default checks on mount
  const unseenPromotions = usePromotionsStore((state) => state.unseenPromotions);
  useEffect(() => {
    if (unseenPromotions?.length > 0) {
      Broadcast.publish("DISPLAY_PROMOTION_MODAL", unseenPromotions);
    }

    backendBroadcasts.publish("GET_SUBSCRIPTION_DETAILS");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
