import React from "react";
import LoadingSkeleton from "../loadingSkeleton";

export default function MetricsSkeletonLoadingPanel({
  width,
  height,
  marginTop,
}) {
  return (
    <LoadingSkeleton
      style={{ width, height, borderRadius: 6, marginTop }}
      className={"darker-skeleton-override"}
    />
  );
}
