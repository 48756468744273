import React, { createRef, PureComponent } from "react";
import {
  chronoParseHasKnownValues,
  getKeyValueFromChrono,
  KEYCODE_ENTER,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ESCAPE,
  createAttendeeSuggestionsLabel,
  chronoParseHasKnownEndValues,
  createNearestForwardDayOfWeek,
  KEYCODE_CONTROL,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  stringContainsAnyWordInArray,
  chronoHasKnownOffset,
  ReplaceSpaceWithUnderscore,
  createAbbreviationForTimeZone,
  textToNumber,
  customChronoWordDetection,
  getRecentContactsMatches,
  createTimeZoneAbbrevationObject,
  convertInvalidMDYToDMY,
  createInputTextBlock,
  extractTextFromHtml,
  KEYCODE_COMMAND_FIREFOX,
  addAbbrevationToTimeZone,
  determineDateFnsKeyName,
  emojiAtBeginningOfString,
  isOnboardingMode,
  isBeforeDay,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import {
  makeAllSuggestionsInactive,
  createTemplateSummaryIndex,
  getMatchingTemplates,
  pressUpSuggestion,
  getCurrentActiveIndex,
  pressDownSuggestions,
} from "../services/sharedEventFormFunctions";
import { TIME_ZONE_ABBREVIATION_ARRAY } from "../services/timeZone";
import GoogleCalendarService, {
  WHATS_APP_STRING,
  BACKEND_WHATS_APP,
} from "../services/googleCalendarService";
import { connect } from "react-redux";
import _ from "underscore";
import nlp from "compromise";
import StyleConstants, {
  DURATION_UNITS,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import durationParse from "parse-duration";
import Broadcast from "../broadcasts/broadcast";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import {
  startOfHour,
  set,
  startOfMinute,
  addMinutes,
  differenceInMilliseconds,
  endOfWeek,
  startOfDay,
  subWeeks,
  addWeeks,
  subDays,
  getDate,
  getMonth,
  getYear,
  addYears,
  isBefore,
} from "date-fns";
import { getContactGroupMatches, getDomainAndContacts, getRecentContactsForUpsert } from "../lib/contactFunctions";
import OnboardBroadcast from "../broadcasts/onboardBroadcast";
import { getTemplateTitle } from "../services/templateFunctions";
import { getCalendarAllowedMeetingProviders, getCalendarProviderId } from "../services/calendarAccessors";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";
import { getMeetWithUserEmail } from "../services/meetWithFunctions";
import { NLP_INPUT_FIELD_NAME } from "../services/elementIDVariables";
import { determineCalendarColor, getUserPrimaryCalendar } from "../lib/calendarFunctions";
import { OUTLOOK_CONFERENCING } from "../resources/outlookVariables";
import { getGoogleMapsAutoCompleteAndDetail } from "../lib/googleFunctions";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { getCustomConferencingName, getDefaultPhoneOption } from "../lib/settingsFunctions";
import { equalAfterTrimAndLowerCased, extractEmails, isValidEmail, lowerCaseAndTrimStringWithGuard, truncateString } from "../lib/stringFunctions";
import { CHRONO_KEYS, getChronoKnownEndFromStart } from "../lib/chronoFunctions";
import { removeDuplicatesFromArray } from "../lib/arrayFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { getMasterAccountTemplates, getUserEmail } from "../lib/userFunctions";

let {
  googleHangoutString,
  zoomString,
  noConferenceString,
  phoneNumberConference,
} = GoogleCalendarService;

const SEARCH_STATE_SUMMARY = "search_state_summary";
const SEARCH_STATE_LOCATION = "search_state_location";
const SEARCH_STATE_ATTENDEES = "search_state_attendees";
const SEARCH_STATE_TIME_ZONE = "search_state_time_zone";
const SEARCH_STATE_CALENDARS = "SEARCH_STATE_CALENDARS";
const SEARCH_STATE_TEMPLATES = "SEARCH_STATE_TEMPLATE";


const CONFERENCE_ARRAY = ["zoom", "google", "hangouts", "teams"];

const AT = " at ";
const FROM = " from ";
const ON = " on ";
const IN = " in ";
const THROUGH = " through ";
const WITH = " with ";
const OVER = " over ";
const FOR = " for ";
const VIA = " via ";

const KEYWORDS_TRIMMED = [
  AT,
  FROM,
  ON,
  IN,
  THROUGH,
  WITH,
  OVER,
  FOR,
  VIA,
  "@",
  "w/",
].map((keyword) => keyword.trim());

const LOCATION_KEYWORDS = [
  AT,
  IN,
  "at ",
  "in ",
  " @ ",
  "@ ",
  "\xa0at ",
  "\xa0in",
  "\xa0@ ",
  " @",
  "\xa0@",
];
const ATTENDEES_KEYWORDS = [
  WITH,
  "with ",
  " w/ ",
  "w/ ",
  "\xa0with ",
  "\xa0w/ ",
];
const ATTENDEES_KEYWORDS_WITH_SPACE = [
  " with ",
  " w/ ",
  "\xa0with ",
  "\xa0w/ ",
];
const CONFERENCING_KEYWORDS = [
  OVER,
  THROUGH,
  ON,
  VIA,
  "over ",
  "through ",
  "via ",
  "\xa0over ",
  "\xa0through ",
  "\xa0via ",
];
const CONFERENCING_KEYWORDS_WITH_SPACE_AROUND = [
  OVER,
  THROUGH,
  ON,
  VIA,
  "\xa0over ",
  "\xa0through ",
  "\xa0via ",
];
const CONFERENCING_TYPE_KEYWORDS = [
  "zoom",
  "google",
  "whatsapp",
  "google meet",
  "around.co",
  "whereby",
  "phone",
];

const KEYWORDS_ARRAY = [
  AT,
  FROM,
  ON,
  IN,
  THROUGH,
  WITH,
  OVER,
  FOR,
  VIA,
  " @ ",
  " w/ ",
  " @",
  "\xa0@",
  " time: ",
  "at ",
  "from ",
  "on ",
  "in ",
  "through ",
  "with ",
  "over ",
  "@ ",
  "w/ ",
  "for ",
  "via ",
  "time: ",
  "\xa0at ",
  "\xa0from ",
  "\xa0on ",
  "\xa0in ",
  "\xa0through ",
  "\xa0with ",
  "\xa0over ",
  "\xa0@ ",
  "\xa0w/ ",
  "\xa0for ",
  "\xa0via ",
  "\xa0time: ",
  "\xa0/",
  "\xa0/",
  " /",
];
const KEYWORDS_BACK_TRIMMED_ARRAY = [
  " at",
  " from",
  " on",
  " in",
  " through",
  " with",
  " over",
  " @",
  " w/",
  " for",
  " via",
  " time:",
  "\xa0at",
  "\xa0from",
  "\xa0on",
  "\xa0in",
  "\xa0through",
  "\xa0with",
  "\xa0over",
  "\xa0@",
  "\xa0w/",
  "\xa0for",
  "\xa0via",
  "\xa0time:",
];
const KEYWORDS_FRONT_TRIMMED_ARRAY = [
  "at ",
  "from ",
  "on ",
  "in ",
  "through ",
  "with ",
  "over ",
  "@ ",
  "w/ ",
  "for ",
  "via ",
  "time: ",
];
const KEYWORDS_TRIMMED_ARRAY_FRONT_BACK = [
  "at",
  "from",
  "on",
  "in",
  "through",
  "with",
  "over",
  "@",
  "w/",
  "for",
  "via",
  "time:",
  "\xa0at",
  "\xa0from",
  "\xa0on",
  "\xa0in",
  "\xa0through",
  "\xa0with",
  "\xa0over",
  "\xa0@",
  "\xa0w/",
  "\xa0for",
  "\xa0via",
  "\xa0time:",
];

const ALL_KEYWORD_PERMUTATION = removeDuplicatesFromArray(
  KEYWORDS_ARRAY.concat(KEYWORDS_BACK_TRIMMED_ARRAY)
    .concat(KEYWORDS_FRONT_TRIMMED_ARRAY)
    .concat(KEYWORDS_TRIMMED_ARRAY_FRONT_BACK)
    .concat([" and ", "and", "and ", " and", "\xa0with", "\xa0with "])
);

// Time zones that chrono does not pick up
const OTHER_TIME_ZONES = ["ct", "mt", "gmt"];

const ONE_ON_ONE_COMBINATIONS = ["one on one", "1:1", "1-1", "1:1", "1 - 1"];

class NlpInput extends PureComponent {
  constructor(props) {
    super(props);

    this._placeHolderInterval = null;
    this._hasSetTracking = false;

    this._detectedStartDate = null;
    this._detectedEndDate = null;

    this.state = {
      shouldDisplayPlaceholderText: true,
      summary: "",
      query: props.value,
      locationQuery: "",
      attendeesQuery: "",
      suggestions: [],
      suggestionIndex: null,
      searchState: SEARCH_STATE_SUMMARY,
      knownLocation: "",
      knownConference: null,
      knownAttendees: {},
      knownTimeZone: null,
      knownTimeText: null,
      detectedStartTime: null,
      detectedEndTime: null,
      initialStartTime: props.initialStartTime,
      initialStartDate: props.initialStartDate,
      initialEndTime: props.initialEndTime,
      initialEndDate: props.initialEndDate,
      initialStartAndEndTimeDiff: props.initialStartAndEndTimeDiff,
      initialTimeZone: props.initialTimeZone,
      hasCapitalizedFirstLetter: false,
      lastPressedCmd: null,
      refs: {},
      knownDuration: null,
      timeZoneAbbreviationObject: [],
      initialCalendar: this.props.calendar,
      templatesSummaryIndex: createTemplateSummaryIndex(getMasterAccountTemplates({ masterAccount: props.masterAccount.masterAccount })),
      knownTemplate: null,
      hasSetAllDay: false,
      knownCalendar: "",
    };

    this._loadTimeZoneTimer = null;

    this.handleLocationSelect = this.handleLocationSelect.bind(this);
    this.handleTimeZoneSelect = this.handleTimeZoneSelect.bind(this);
    this.onKeyUpDownEnter = this.onKeyUpDownEnter.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.setAllSuggestionsInactive = this.setAllSuggestionsInactive.bind(this);
    this.addAttendees = this.addAttendees.bind(this);
    this.onMouseEnterSuggestion = this.onMouseEnterSuggestion.bind(this);
    this.onMouseLeaveSuggestion = this.onMouseLeaveSuggestion.bind(this);
    this.onChangeInputContentEditableChange =
      this.onChangeInputContentEditableChange.bind(this);
    this.onInputContentEditableKeyDown =
      this.onInputContentEditableKeyDown.bind(this);
    this.handleInputPaste = this.handleInputPaste.bind(this);
    this.handleOnClickSuggestion = this.handleOnClickSuggestion.bind(this);
    this.handleChangeInputContentEditableChange =
      this.handleChangeInputContentEditableChange.bind(this);
    this.setQueryText = this.setQueryText.bind(this);
    this.scrollToOption = this.scrollToOption.bind(this);
    this.setTimeZoneAbbreviations = this.setTimeZoneAbbreviations.bind(this);

    Broadcast.subscribe("SET_NLP_QUERY_TEXT", this.setQueryText);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updatePlaceHolder();

    // Need way to pick up onchange
    let element = this.getNLPFieldElement();

    element.addEventListener("input", this.onChangeInputContentEditableChange);
    element.addEventListener("keydown", this.onInputContentEditableKeyDown);
    element.addEventListener("paste", this.handleInputPaste);

    this.setUpLocationAutoComplete();

    // this is computationally heavy for the first time
    this._loadTimeZoneTimer = setTimeout(this.setTimeZoneAbbreviations, 1000);

    element = null;
  }

  componentWillUnmount() {
    this._isMounted = false;

    // clear timer and interval for place holder
    clearInterval(this._placeHolderInterval);
    this._placeHolderInterval = null;
    clearTimeout(this._fadePlaceHolderTimeout);
    this._fadePlaceHolderTimeout = null;

    this.locationDetail = null;

    clearTimeout(this._loadTimeZoneTimer);
    this._loadTimeZoneTimer = null;

    let element = this.getNLPFieldElement();

    element.removeEventListener(
      "input",
      this.onChangeInputContentEditableChange
    );
    element.removeEventListener("keydown", this.onInputContentEditableKeyDown);
    element.removeEventListener("paste", this.handleInputPaste);
    element = null;

    Broadcast.unsubscribe("SET_NLP_QUERY_TEXT");

    this.locationAutocomplete = null;
    clearTimeout(this.typingTimer);

    this.typingTimer = null;
  }

  render() {
    return (
      <div className="nlp-input-container">
        {this.renderNlpInputAsContentEditableDiv()}

        {this.renderSuggestions()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderSuggestions() {
    let suggestions = this.state.suggestions;
    let label = this.determineSuggestionLabel();

    return (
      suggestions.length > 0 && (
        <div
          className="autocomplete-dropdown-container margin-top-ten"
          style={{ marginLeft: 11, maxHeight: 180, overflowY: "auto" }}
        >
          {this.renderSuggestionLabel(label)}

          {suggestions.map((suggestion) => {
            return (
              <div
                className="location-suggestion-item select-none"
                style={
                  suggestion.active
                    ? {
                        backgroundColor: this.props.isDarkMode
                          ? StyleConstants.darkModeHoverBackgroundColor
                          : "#F2F3F4",
                        width: 330,
                      }
                    : { width: 330 }
                }
                key={`attendee_suggestion_${suggestion.index}`}
                onMouseEnter={() => this.onMouseEnterSuggestion(suggestion)}
                onMouseLeave={this.onMouseLeaveSuggestion}
                onClick={() => this.handleOnClickSuggestion(suggestion)}
                ref={this.state.refs[suggestion.key]}
              >
                {this.isSearchStateCalendar() 
                  ? <div className="rounded-full h-2.5 w-2.5 mr-1.5" style={{backgroundColor: determineCalendarColor(suggestion.calendar?.value)}}></div> 
                  : null
                }
                <span>{truncateString(suggestion.description, 50)}</span>
              </div>
            );
          })}
        </div>
      )
    );
  }

  renderSuggestionLabel(label) {
    if (label) {
      return (
        <div
          className="location-suggestion-item"
          style={{ width: 330, fontWeight: 400 }}
        >
          {label}
        </div>
      );
    }
  }

  renderNlpInputAsContentEditableDiv() {
    return (
      <div
        className="nlp-input"
        id={NLP_INPUT_FIELD_NAME}
        contentEditable={true}
        suppressContentEditableWarning={true}
        ref={this.props.focusRef}
        autoFocus={this.props.autoFocus}
        tabIndex={this.props.tabIndex}
        onBlurCapture={this.onBlurInput}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        dataplaceholder={this.determinePlaceholderText()}
        onFocus={this.props.onFocus}
      ></div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  handleOnClickSuggestion(suggestion) {
    switch (this.state.searchState) {
      case SEARCH_STATE_ATTENDEES:
        this.addAttendees(suggestion);
        break;
      case SEARCH_STATE_LOCATION:
        this.handleLocationSelect(suggestion);
        break;
      case SEARCH_STATE_TIME_ZONE:
        this.handleTimeZoneSelect(suggestion);
        break;
      case SEARCH_STATE_TEMPLATES:
        this.handleSelectTemplate(suggestion);
        break;
      case SEARCH_STATE_CALENDARS:
        this.handleSelectCalendar(suggestion);
        break;
      default:
        break;
    }
  }

  handleInputPaste(e) {
    e.preventDefault();
    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    // get text representation of clipboard
    let plainText = (e.originalEvent || e).clipboardData.getData("text/plain");

    // insert text manually
    document.execCommand("insertHTML", false, plainText);
    element = null;
  }

  onMouseEnterSuggestion(suggestion) {
    let resetSuggestions = makeAllSuggestionsInactive(this.state.suggestions);

    let updatedSuggestions = [];

    resetSuggestions.forEach((s) => {
      if (s.index === suggestion.index) {
        let updatedElement = s;
        updatedElement.active = true;

        updatedSuggestions = updatedSuggestions.concat(updatedElement);
      } else {
        updatedSuggestions = updatedSuggestions.concat(s);
      }
    });

    this.setState({
      hoverOverSuggestion: true,
      suggestions: updatedSuggestions,
      refs: this.createRefForSuggestion(updatedSuggestions),
    });
  }

  onMouseLeaveSuggestion() {
    this.setState({ hoverOverSuggestion: false });
  }

  onBlurInput() {
    if (!this.state.hoverOverSuggestion) {
      this.setState({
        suggestions: [],
        refs: {},
        attendeesQuery: "",
        shouldDisplayPlaceholderText: false,
      });
    }

    this.props.onBlur && this.props.onBlur();
  }

  updateInnerHtml(updatedString) {
    if (!updatedString) {
      return;
    }

    //Set it in renderNlpInputAsContentEditableDiv
    let element = this.getNLPFieldElement();
    if (!element) {
      return;
    }
    element.innerHTML = updatedString;
    element = null;

    this.setCaretToEnd();
  }

  handleLocationSelect(suggestion) {
    const address = suggestion.description;
    const {
      placeId
    } = suggestion;

    if (!placeId) {
      this.addLocation(address);
      return;
    }

    this.locationDetail?.getDetails(
      {placeId},
      detail => {
        if (!this._isMounted) {
          return;
        }

        if (detail?.formatted_address) {
          let completeQuery = detail.formatted_address;

          if (detail.name && !detail.formatted_address.includes(detail.name)) {
            completeQuery = `${detail.name}, ${detail.formatted_address}`;
          }
          this.addLocation(completeQuery);
        } else {
          this.addLocation(address);
        }
      }
    )
  }

  // adds location into event
  addLocation(address) {
    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    let currentQuery = element.innerHTML;

    // on paste, there is extra /&nbsp;
    currentQuery = currentQuery.replace(/&nbsp;/g, " ");

    let { updatedQuery, truncatedAddress } =
      this.createUpdatedQueryWithLocation(currentQuery, address);

    this.props.setLocation && this.props.setLocation(address, true);

    this.updateInnerHtml(updatedQuery);

    let updatedInnerText = extractTextFromHtml(updatedQuery);

    element = null;

    this.setState(
      {
        locationQuery: "",
        query: updatedInnerText,
        suggestions: [],
        refs: {},
        knownLocation: truncatedAddress.toLowerCase(),
      },
      () => this.onChangeInputSummarySearchState({}, updatedInnerText, true)
    );
  }

  handleSelectCalendar(suggestion) {
    if (isEmptyObjectOrFalsey(suggestion)) {
      return;
    }

    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    this.props.setCalendar(suggestion.calendar);

    let currentQuery = element.innerHTML;
    let calendarBlock = createInputTextBlock(
      suggestion.calendar.label,
      this.props.isDarkMode
    );
    let updatedInnerText = "";

    if (!this.state.calendarSearchQuery) {
      // remove last /
      let updatedQuery = this.replaceLastInstance(
        currentQuery,
        "/",
        calendarBlock
      );
      this.updateInnerHtml(updatedQuery);
      updatedInnerText = extractTextFromHtml(updatedQuery);
    } else {
      let { calendarSearchQuery } = this.state;

      let updatedQuery = this.replaceLastInstance(currentQuery, "/", "");

      let lastInstanceIndex = updatedQuery
        .toLowerCase()
        .lastIndexOf(calendarSearchQuery);

      updatedQuery =
        updatedQuery.substring(0, lastInstanceIndex) +
        calendarBlock +
        updatedQuery.substring(
          lastInstanceIndex + calendarSearchQuery.length + 1
        );

      this.updateInnerHtml(updatedQuery);
      updatedInnerText = extractTextFromHtml(updatedQuery);
    }

    element = null;

    this.setState(
      {
        suggestions: [],
        knownCalendar: suggestion.description,
        searchState: SEARCH_STATE_SUMMARY,
        calendarSearchQuery: "",
        query: updatedInnerText,
      },
      () => {
        this.onChangeInputSummarySearchState({}, this.state.query, true);
      }
    );
  }

  handleSelectTemplate(suggestion) {
    let template = suggestion.template;

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Event template applied"
    );
    Broadcast.publish("EVENT_FORM_APPLY_TEMPLATE", {
      json: template,
      shouldApplyTitle: true,
      fromNLP: true,
    });

    // Update last used template
    const path = `templates/${template.id}/used`;
    const url = constructRequestURLV2(path);

    Broadcast.publish("UPDATE_LAST_USED_TEMPLATE", { url });

    const templateTitle = getTemplateTitle(template);
    this.setState(
      {
        suggestions: [],
        searchState: SEARCH_STATE_SUMMARY,
        refs: {},
        query: suggestion.description + " ",
        knownTemplate: templateTitle
          ? templateTitle.toLowerCase().trim()
          : "",
      },
      () => {
        let element = this.getNLPFieldElement();

        if (!element) {
          return;
        }

        this.updateInnerHtml(suggestion.description + "&nbsp;");
        element = null;
      }
    );
  }

  handleTimeZoneSelect(suggestion) {
    if (isEmptyObjectOrFalsey(suggestion)) {
      return;
    }

    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    let currentQuery = element.innerHTML;

    let timeZone = { start: suggestion.value, end: suggestion.value };
    this.props.setEventTimeZone && this.props.setEventTimeZone(timeZone);

    // replace time zone with block around timezone
    if (this.state.knownTimeText) {
      // determine the location of caret based on where time changed
      let caretPosition = currentQuery.indexOf(this.state.knownTimeText);

      if (caretPosition >= 0) {
        caretPosition = caretPosition + this.state.knownTimeText.length;

        let indexTracker = currentQuery
          .toLowerCase()
          .indexOf(suggestion.abbreviation);
        let tempIndex = indexTracker;

        while (tempIndex >= 0) {
          tempIndex = currentQuery.indexOf(
            suggestion.abbreviation,
            indexTracker + 1
          );

          if (tempIndex >= 0) {
            indexTracker = tempIndex;
          }
        }

        const timeZoneAbbreviation = createAbbreviationForTimeZone(
          suggestion.value
        ).toUpperCase();

        const timeZoneBlock = createInputTextBlock(
          timeZoneAbbreviation,
          this.props.isDarkMode
        );
        const updatedQuery =
          currentQuery.substring(0, indexTracker) +
          timeZoneBlock +
          currentQuery.substring(indexTracker + suggestion.abbreviation.length);

        this.updateInnerHtml(updatedQuery);
      }
    }

    element = null;

    this.setState({
      suggestions: [],
      knownTimeZone: [
        createAbbreviationForTimeZone(suggestion.value).toLowerCase(),
        suggestion.abbreviation,
      ],
      searchState: SEARCH_STATE_SUMMARY,
    });
  }

  onInputContentEditableKeyDown(event) {
    if (
      [
        KEYCODE_ENTER,
        KEYCODE_TAB,
        KEYCODE_UP_ARROW,
        KEYCODE_DOWN_ARROW,
        KEYCODE_ESCAPE,
        KEYCODE_CONTROL,
        KEYCODE_COMMAND_LEFT,
        KEYCODE_COMMAND_RIGHT,
        KEYCODE_COMMAND_FIREFOX,
      ].includes(event.keyCode)
    ) {
      if (event.keyCode === KEYCODE_ENTER) {
        event.preventDefault();
      } else if (
        event.keyCode === KEYCODE_TAB &&
        this.state.suggestions &&
        this.state.suggestions.length > 0
      ) {
        event.preventDefault();
      } else if (
        [
          KEYCODE_CONTROL,
          KEYCODE_COMMAND_LEFT,
          KEYCODE_COMMAND_RIGHT,
          KEYCODE_COMMAND_FIREFOX,
        ].includes(event.keyCode)
      ) {
        // When user presses cmd and enter
        this.setState({ lastPressedCmd: new Date() });
        return;
      }

      this.onKeyUpDownEnter(event);
    }
  }

  onChangeInputContentEditableChange(event) {
    if (!this.state.hasCapitalizedFirstLetter) {
      // To capitalize first letter
      this.handleChangeInputContentEditableChange(event);
    }

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);

      this.typingTimer = null;
    }

    this.typingTimer = setTimeout(
      () => this.handleChangeInputContentEditableChange(event),
      200
    );
  }

  handleChangeInputContentEditableChange(event) {
    if (!this._isMounted) {
      return;
    }

    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    const newText = this.addSpaceBetweenTwoDatesSeparatedByDash(element.innerText);
    const entireText = this.addSpaceBetweenTwoDatesSeparatedByDash(element.innerText);

    if (isOnboardingMode()) {
      OnboardBroadcast.publish("NLP_FIELD_TEXT", entireText);
    }

    // Capitalize first letter
    const capitalizeFirstLetter = (text) => {
      let firstLetter = text[0].toUpperCase();

      element.innerHTML = firstLetter;

      this.setState({
        hasCapitalizedFirstLetter: true,
        query: firstLetter,
      });
      element = null;

      this.setCaretToEnd();
    };

    if (!this._hasSetTracking && newText.length >= 11) {
      // 11 length gets you "sync at 3pm", anything shorter than that doesn't make sense to track.
      this._hasSetTracking = true;
    }

    if (!this.state.hasCapitalizedFirstLetter && newText.length === 1) {
      capitalizeFirstLetter(entireText);
      element = null;
      return;
    }

    let prevText = this.state.query;

    if (newText === prevText) {
      element = null;
      return;
    }

    let newState = { query: newText, shouldDisplayPlaceholderText: false };

    // replace characters = highlight string -> type in something
    // delete characters -> press delete
    if (
      this.isDeleteCharacter(event) ||
      this.isReplaceCharacters(prevText, newText)
    ) {
      let diffBetweenNewAndPrevText = this.findDiffBetweenTwoStrings(
        newText,
        prevText
      );

      if (newText.length <= 1) {
        this.props.removeEmoji && this.props.removeEmoji();
      }

      this.handleRemovedText(diffBetweenNewAndPrevText, newText);
    }

    let durationResult = this.parseDurationText(entireText);

    if (durationResult !== this.state.knownDuration) {
      newState.knownDuration = durationResult;
    }

    // Remove text for time and date
    let {
      updatedText,
      detectedKeyWord,
      detectedKeyWordIndex,
      updatedState,
      isTimeAtEndOfInput,
    } = this.onChangeInputTimeAndDate(newState, entireText);
    newState = updatedState;

    // Set conference below
    newState = this.onChangeInputConference(newState, updatedText);

    // Set summary, location, attendees below
    let { keyWordIndex, lastKeyWord } =
      this.getLastIndexAndKeyWordFromString(updatedText);

    if (!lastKeyWord && detectedKeyWord) {
      keyWordIndex = detectedKeyWordIndex;
      lastKeyWord = detectedKeyWord;
    }

    this.onChangeInputSummarySearchState(newState, updatedText);

    // Title has been handled
    if (newState.updatedTimeZoneSuggestion) {
      // do nothing since current search state is time zone
    } else if (LOCATION_KEYWORDS.includes(lastKeyWord)) {
      this.onChangeInputLocationSearchState(
        newState,
        updatedText,
        keyWordIndex,
        isTimeAtEndOfInput
      );
    } else if (ATTENDEES_KEYWORDS.includes(lastKeyWord)) {
      this.onChangeInputAttendeeSearchState(
        newState,
        updatedText,
        isTimeAtEndOfInput
      );
    } else if (CONFERENCING_KEYWORDS.includes(lastKeyWord)) {
      newState = this.resetSearchQueries(newState);

      this.setState(newState);
    } else if (lastKeyWord && lastKeyWord.includes("/")) {
      // add suggestions of calendars
      // let suggestions = this.createCalendarDropdown();
      let { searchState, suggestions, calendarSearchQuery } =
        this.createCalendarSuggestions(keyWordIndex, updatedText);

      newState["searchState"] = searchState;
      newState["suggestions"] = suggestions;
      newState["calendarSearchQuery"] = calendarSearchQuery;

      this.setState(newState);
    } else {
      // Resetting search queries since we don't need to show suggestions if it's not location or attendees search state
      newState = this.resetSearchQueries(newState);

      let suggestions = getMatchingTemplates({
        templatesSummaryIndex: this.state.templatesSummaryIndex,
        query: updatedText,
        knownTemplate: this.state.knownTemplate,
      });

      if (suggestions.length > 0) {
        let suggestionRefs = this.createRefForSuggestion(suggestions);
        newState.suggestions = suggestions;
        newState.refs = suggestionRefs;
        newState.searchState = SEARCH_STATE_TEMPLATES;
      }

      this.setState(newState);
    }

    element = null;
  }

  onChangeInputTimeAndDate(newState, string) {
    let updatedText = string;

    let doc = nlp(string);
    let phoneArray = doc.phoneNumbers().out("array");

    // remove phone number
    if (phoneArray.length > 0) {
      string = string.replace(phoneArray[0], "");
    }

    let chronoParse = this.nlpChronoDetection(string);

    // Filter out addresses
    let filteredParseArray = [];

    if (chronoParse.length > 0) {
      chronoParse.forEach((p, index) => {
        let isAddress = this.handleIfStringIsAlsoAddress(p.text, p, string);

        let chronoText = p.text.trim().toLowerCase();

        let duration = this.parseDurationText(p.text);

        if (
          duration &&
          (duration === newState.knownDuration ||
            duration === this.state.knownDuration) &&
          !isAddress &&
          !ONE_ON_ONE_COMBINATIONS.includes(chronoText) &&
          p?.start &&
          !isEmptyObjectOrFalsey(p.start.knownValues)
        ) {
          // lunch for karen's bday for an hour
          // Need to delete an hour
          updatedText = this.removeDurationTextFromString({
            entireText: updatedText,
            durationString: p.text?.trim(),
          });
        }

        if (
          !isAddress &&
          !ONE_ON_ONE_COMBINATIONS.includes(chronoText) &&
          p &&
          p.start &&
          !isEmptyObjectOrFalsey(p.start.knownValues)
        ) {
          if (duration) {
            // filter out duration text and put time text inside
            p.text = this.filterOutDurationText(p.text);
          }
          filteredParseArray = filteredParseArray.concat(p);
        }
      });
    }

    // Get timezone string (ct, mt) that chrono does not pick up
    // knownTimeZone = ["edt", "et"]
    // 1. look through each filteredParseArray and see if next element is timezone
    // if it is -> add it to text and put as argument into determineTimeZone
    let timeZoneText;
    if (!this.state.knownTimeZone) {
      let timeZoneChronoResult = this.getTimeZoneText(
        filteredParseArray,
        string
      );

      if (timeZoneChronoResult && timeZoneChronoResult.timeZoneText) {
        timeZoneText = timeZoneChronoResult.timeZoneText;
        filteredParseArray = timeZoneChronoResult.updatedChronoArray;

        let timeZoneSuggestions = this.determineTimeZone(timeZoneText);

        if (!timeZoneSuggestions || timeZoneSuggestions.length < 1) {
          // Did not match any suggestions
          timeZoneText = null;
        }
      }
    }

    // Build up new chronoParse
    let allChronoText = this.getAllChronoText(filteredParseArray).trim();

    // If time text hasn't changed -> we don't change anything
    // Also need to check if duration has changed
    if (
      this.state.knownTimeText &&
      allChronoText.length > 0 &&
      this.state.knownTimeText === allChronoText &&
      (!newState.knownDuration ||
        this.state.knownDuration === newState.knownDuration)
    ) {
      return this.createUpdatedTextAndKeyWordForInputTime(
        filteredParseArray,
        updatedText,
        newState
      );
    }

    //Update state here
    let updatedState = _.clone(newState);
    let updatedChronoParse = this.nlpChronoDetection(allChronoText);

    // if format is DD-MM-YYYY -> Need to change the date and month order
    updatedChronoParse = convertInvalidMDYToDMY(
      updatedChronoParse,
      this.props.dateFieldOrder
    );

    if (allChronoText.length > 0) {
      updatedState.knownTimeText = allChronoText;
    }

    let hasKnownEndTime = chronoParseHasKnownEndValues(updatedChronoParse);
    let hasKnownStartTime = chronoParseHasKnownValues(updatedChronoParse);

    // Has start value
    if (
      this.doesStringIncludeAllDay(allChronoText) &&
      !this.state.hasSetAllDay &&
      !this.props.isAllDay
    ) {
      this.props.setAllDay(true);
      updatedState.hasSetAllDay = true;
    }

    if (hasKnownStartTime) {
      let knownValues = updatedChronoParse[0].start.knownValues;

      if (
        (chronoHasKnownOffset(updatedChronoParse) ||
          this.chronoTextHasOtherTimeZone(updatedChronoParse)) &&
        !this.state.knownTimeZone
      ) {
        // make sure there's no other phrase behind it
        let timeZoneSuggestions = this.determineTimeZone(
          updatedChronoParse[0].text
        );

        if (timeZoneSuggestions && timeZoneSuggestions.length > 0) {
          updatedState.updatedTimeZoneSuggestion = true;
        }
      } else if (timeZoneText) {
        updatedState.updatedTimeZoneSuggestion = true;
      }

      this.setTimeAndDate(
        knownValues,
        true,
        newState && !hasKnownEndTime && newState.knownDuration
      );
    } else if (newState.knownDuration) {
      let result = this.determineTimeBasedOnSingleWord(string, true);

      if (result) {
        this.props.setStartTime && this.props.setStartTime(result, true);

        this.updateEndTimeBasedOnDuration(result, newState.knownDuration);

        // So we don't update end time when setting start time
        updatedState.alreadyUpdatedStartTime = true;
      } else if (this.props.initialStartTime) {
        this.updateEndTimeBasedOnDuration(
          this.props.initialStartTime,
          newState.knownDuration
        );

        updatedState.alreadyUpdatedStartTime = true;
      }
    } else if (string.includes(this.state.knownTimeText) && !this.shouldSkipTextDetectForChronoString(string)) {
      // text did not get deleted
      // do nothing
    } else {
      this.handleNoKnownTimeOrDate(true);
    }

    if (hasKnownEndTime) {
      let knownValues = updatedChronoParse[0].end.knownValues;
      this.setTimeAndDate(knownValues, false, newState);
    } else if (getChronoKnownEndFromStart(updatedChronoParse)) {
      this.setTimeAndDate(getChronoKnownEndFromStart(updatedChronoParse), false, newState);
    } else if (newState.knownDuration) {
      // Do nothing since duration only applies to start time
    } else if (string.includes(this.state.knownTimeText)) {
      // text did not get deleted
      // do nothing
    } else {
      this.handleNoKnownTimeOrDate(false);
    }

    return this.createUpdatedTextAndKeyWordForInputTime(
      filteredParseArray,
      updatedText,
      updatedState
    );
  }

  shouldSkipTextDetectForChronoString(chronoString) {
    // some companies like Satsuma have day sof the week in their name. This checks if it's a company and if so, skips and removes the date
    if (!chronoString) {
      return false;
    }

    return chronoString.toLowerCase().includes("satsuma");
  }

  getTimeZoneText(chronoArray, string) {
    if (
      !chronoArray ||
      chronoArray.length === 0 ||
      !string ||
      chronoHasKnownOffset(chronoArray)
    ) {
      return;
    }

    let timeZoneText;
    let stringCopy = string;
    let updatedChronoArray = _.clone(chronoArray);

    chronoArray.forEach((c, index) => {
      if (timeZoneText) {
        return;
      }

      let text = c.text;

      let textIndex = string.indexOf(text);

      // Remove string -> trim -> get first word
      if (textIndex >= 0) {
        // new RegExp(String.fromCharCode(160) -> since there might be html space in the string
        stringCopy = stringCopy
          .substring(textIndex + text.length, stringCopy.length)
          .replace(new RegExp(String.fromCharCode(160), "g"), " ")
          .trim();
        let stringArray = stringCopy.split(" ");

        if (OTHER_TIME_ZONES.includes(stringArray[0].toLowerCase())) {
          // N lower case
          let unformattedTimeZoneText = stringArray[0];
          timeZoneText = stringArray[0].toLowerCase();

          let indexOfChronoText = string.indexOf(chronoArray[index].text);

          if (updatedChronoArray[index] && indexOfChronoText >= 0) {
            const endOfChronoText =
              indexOfChronoText + chronoArray[index].text.length;

            const indexOfNextWord = string.indexOf(
              unformattedTimeZoneText,
              endOfChronoText
            );
            const updatedChronoText = string.substring(
              indexOfChronoText,
              indexOfNextWord + unformattedTimeZoneText.length
            );

            updatedChronoArray[index].text = updatedChronoText;
          }
        }
      }
    });

    return { timeZoneText, updatedChronoArray };
  }

  updateEndTimeBasedOnDuration(jsDateTime, duration) {
    if (!jsDateTime || !duration) {
      return;
    }

    let endTime = addMinutes(jsDateTime, duration);
    this.props.setEndTime && this.props.setEndTime(endTime, true);
  }

  // used to remove conferencing from title
  removeConferencingFromTitle(title, conferencingName) {
    if (!title.toLowerCase().includes(conferencingName.toLowerCase())) {
      return title;
    }

    let updatedTitle = title;

    CONFERENCING_KEYWORDS_WITH_SPACE_AROUND.forEach((keyWord) => {
      updatedTitle = updatedTitle.replace(
        new RegExp(keyWord + conferencingName, "gi"),
        " "
      );
    });

    // const conferencingNames = [conferencingName, ` ${conferencingName}`]
    // conferencingNames.forEach(conferencing => {
    //   updatedTitle = updatedTitle.replace(new RegExp(conferencing, 'gi'), ' ');
    // });

    return updatedTitle;
  }

  onChangeInputSummarySearchState(
    newState,
    newText,
    shouldFilterTextForTime = false
  ) {
    // new RegExp(String.fromCharCode(160) -> since there might be html space in the string
    let updatedText = newText.replace(
      new RegExp(String.fromCharCode(160), "g"),
      " "
    );

    //Set time for basic cases: lunch, dinner
    if (
      newState &&
      !newState.alreadyUpdatedStartTime &&
      !newState.knownTimeText &&
      !this.state.knownTimeText
    ) {
      this.setTimeForSingleWords(updatedText);
    }

    if (shouldFilterTextForTime) {
      updatedText = this.onChangeInputTimeAndDate(
        newState,
        updatedText,
      ).updatedText;
    }

    // filter for conferencing
    CONFERENCING_TYPE_KEYWORDS.forEach((conferencingKeyWords) => {
      updatedText = this.removeConferencingFromTitle(
        updatedText,
        conferencingKeyWords
      );
    });

    let summaryEndIndex = this.getIndexOfFirstSetItem(updatedText);
    let updatedSummary = updatedText.substring(0, summaryEndIndex);

    let summaryArray = updatedSummary
      .trim()
      .split(" ")
      .filter((str) => !!str); // filter out empty string ("")
    let lastWord = summaryArray[summaryArray.length - 1];

    let hasUpdatedSummaryArray = false;
    while (
      !!lastWord &&
      ALL_KEYWORD_PERMUTATION.includes(lastWord.toLowerCase()) &&
      summaryArray.length >= 1 && // at least length of 1
      !!summaryArray[summaryArray.length - 1]
    ) {
      if (!hasUpdatedSummaryArray) {
        hasUpdatedSummaryArray = true;
      }

      summaryArray = summaryArray.slice(0, summaryArray.length - 1); // if last word is a trigger word (for, with, w/), etc
      lastWord = summaryArray[summaryArray.length - 1];
    }

    if (hasUpdatedSummaryArray) {
      updatedSummary = summaryArray.join(" ");
    }

    // remove emoji
    const emoji = emojiAtBeginningOfString(updatedSummary);

    updatedSummary = emoji
      ? updatedSummary.split(emoji)[1].trim()
      : updatedSummary;
    if (this.props.onSelectEmoji && emoji && emojiAtBeginningOfString(this.props.summary) !== emoji) {
      this.props.onSelectEmoji(emoji, updatedSummary, true);
    }

    if (updatedSummary === this.state.summary) {
      return;
    }

    let updatedState = _.clone(newState || {});

    updatedState.summary = updatedSummary;

    if (this.state.searchState !== SEARCH_STATE_TIME_ZONE) {
      updatedState.suggestions = [];
      updatedState.searchState = SEARCH_STATE_SUMMARY;
    }

    if (this.props.setSummary) {
      this.props.setSummary(updatedSummary.trim(), true, true, true);
    }

    this.setState(updatedState);
  }

  onChangeInputLocationSearchState(
    newState,
    textUpToThatPoint,
    keyWordIndex,
    isTimeAtEndOfInput
  ) {
    if (this.state.knownLocation.length > 0) {
      newState.suggestions = [];
      this.setState(newState);

      return;
    }

    let updatedLocation = this.removeKeyWordsFromString(
      textUpToThatPoint.substring(keyWordIndex, textUpToThatPoint.length + 1)
    ).trim();

    if (this.isStringKeyWord(updatedLocation)) {
      return;
    }

    newState.locationQuery = updatedLocation;

    if (updatedLocation && updatedLocation.length > 0) {
      newState.searchState = SEARCH_STATE_LOCATION;
    }

    let updatedState = _.clone(newState);

    if (updatedLocation.length > 0 || this.state.locationQuery.length > 0) {
      updatedState = this.searchForLocationSuggestion(
        updatedState,
        updatedLocation,
        isTimeAtEndOfInput
      );

      this.props.setLocation && this.props.setLocation(updatedLocation, true);

      this.setState(updatedState);
    } else {
      this.setState(updatedState);
    }
  }

  formatRegexEmails(emails) {
    if (!emails || emails.length === 0) {
      return [];
    }

    return emails.map((e) => {
      return { text: e };
    });
  }

  onChangeInputAttendeeSearchState(
    newState,
    textUpToThatPoint,
    isTimeAtEndOfInput
  ) {
    const replaceWithComma = textUpToThatPoint.replace(
      new RegExp(String.fromCharCode(160), "g"),
      ", "
    );

    let doc = nlp(replaceWithComma);

    let nlpPeople = doc.nouns().json();
    let emails = doc.emails().json();
    let regexExtractedEmails = this.formatRegexEmails(
      extractEmails(replaceWithComma)
    );

    // can't use regex here because look back breaks in safari
    // let regexMatchAfterWith = replaceWithComma?.match(/(?<=\bwith\s|\bw\/\s)(\w+)/gmi); // get first word after "with" or "w/"
    // took example from: https://stackoverflow.com/questions/8271433/how-to-match-the-first-word-after-by
    // /by\s(\w*)/
    let regexMatchAfterWithArray = replaceWithComma?.match(/with\s(\w*)/, "");
    let regexMatchAfterWith =
      regexMatchAfterWithArray?.length > 0 ? regexMatchAfterWithArray[1] : null;

    // Test cases:
    // Lunch with eng
    // lunch with Eng
    // lunch with mchl_z
    // lunch with john li
    // lunch w/ eng
    // lunch w/ john
    // lunch at 5pm w/ john
    // lunch with Eng alex -> both should show up
    // matches is an array of {text: "Lunch"}
    let matches = []
      .concat(nlpPeople)
      .concat(emails)
      .concat(regexExtractedEmails);
    let doesMatchAlreadyExist =
      regexMatchAfterWith?.toLowerCase &&
      regexMatchAfterWith?.length > 0 &&
      matches.some((m) =>
        m?.text?.toLowerCase().includes(regexMatchAfterWith.toLowerCase())
      );

    if (!doesMatchAlreadyExist && regexMatchAfterWith?.length > 0) {
      // if compromise lib above did not pick up the match after -> we add the match in
      matches = matches.concat({ text: regexMatchAfterWith });
    }

    if (matches.length === 0) {
      newState.suggestions = [];
      this.setState(newState);

      return;
    }

    // Matches has greater than one match
    let searchAttendee = "";
    let matchLocation = 0; // used to get the last match in the string when searching for attendees

    let knownAttendees = this.state.knownAttendees || {};
    let knownAttendeeKeys = Object.keys(knownAttendees).map((a) => {
      return a.toLowerCase();
    });
    let knownAttendeeValues = Object.values(knownAttendees).map((a) => {
      return a.toLowerCase();
    });

    let knownAttendeesArray = []
      .concat(knownAttendeeKeys)
      .concat(knownAttendeeValues);

    const doesAttendeeMatchNamesAtStartOfString = (match) => {
      // checks to see if the attendee is the same as the name at the start of the string
      // if the string is Sarah <> Ben re: Coaching with Sarah
      // Need to pick up Sarah at the end
      const { text } = match;
      const numberOfNames = text.split(" ").length;
      if (numberOfNames > 2) {
        // if more than 2 then probably not a name
        return false;
      }

      return equalAfterTrimAndLowerCased(
        replaceWithComma.slice(0, text.length),
        text
      );
    };

    matches.forEach((m) => {
      if (
        knownAttendeesArray.includes(
          m.text.trim().replace(/,/g, "").toLowerCase()
        ) ||
        equalAfterTrimAndLowerCased(
          this.state.knownCalendar,
          m.text.replace(/,/g, "")
        )
      ) {
        return;
      }

      let trimmedWord = m.text.trim();
      if (
        this.stringContainsAnyElementInArray(
          ATTENDEES_KEYWORDS_WITH_SPACE,
          trimmedWord
        )
      ) {
        // For when nlp picks up "lunch w/ john" as a person
        trimmedWord = this.getStringAfterLastElementMatch(
          ATTENDEES_KEYWORDS_WITH_SPACE,
          trimmedWord
        );
      }
      const index = replaceWithComma.indexOf(trimmedWord);

      if (index > matchLocation) {
        searchAttendee = trimmedWord;
        matchLocation = index;
      } else if (
        matchLocation === 0 &&
        doesAttendeeMatchNamesAtStartOfString(m)
      ) {
        // only need to check if match location is still at 0
        searchAttendee = trimmedWord;
      }
    });
    searchAttendee = searchAttendee.replace(/,/g, "").trim();

    newState.searchState = SEARCH_STATE_ATTENDEES;
    newState.attendeesQuery = searchAttendee;

    if (
      !isTimeAtEndOfInput &&
      !stringContainsAnyWordInArray(searchAttendee, CONFERENCE_ARRAY)
    ) {
      this.searchDomainAndContactsForSuggestions(searchAttendee);
    } else if (this.state.searchState === SEARCH_STATE_TIME_ZONE) {
      // Do nothing
    } else {
      newState.suggestions = [];
    }

    this.setState(newState);
  }

  updateConferencing(value) {
    if (!this.props.setConference) {
      return;
    }

    this.props.setConference({
      option: { value }, 
      notifyChange: true
    });
  }

  onChangeInputConference(newState, textUpToThatPoint) {
    const {
      allCalendars
    } = this.props.allCalendars;
    let updatedState = _.clone(newState);

    //If already set conference
    if (this.state.knownConference) {
      return updatedState;
    }

    let stringArray = textUpToThatPoint.toLowerCase().trim().split(" ");
    const lowerCaseString = textUpToThatPoint.toLowerCase();

    // StringArray is all in lowercase
    if (
      this.props.currentUser.zoom_link &&
      stringArray.some((r) => ["zoom"].indexOf(r) >= 0)
    ) {
      // Set zoom conference
      updatedState.knownConference = zoomString;

      this.updateConferencing(zoomString);
    } else if (
      stringArray.some((r) => ["google", "hangouts", "meets"].indexOf(r) >= 0)
    ) {
      // set hangout conference
      updatedState.knownConference = googleHangoutString;

      this.updateConferencing(googleHangoutString);
    } else if (stringArray.some((r) => ["phone"].indexOf(r) >= 0)) {
      // set hangout conference
      if (getDefaultPhoneOption({ user: this.props.currentUser }) === BACKEND_WHATS_APP) {
        updatedState.knownConference = WHATS_APP_STRING;

        this.updateConferencing(WHATS_APP_STRING);
      } else {
        updatedState.knownConference = phoneNumberConference;
        this.updateConferencing(phoneNumberConference);
      }
    } else if (
      stringArray.some((r) => ["whatsapp"].indexOf(r) >= 0) &&
      getDefaultPhoneOption({ user: this.props.currentUser }) === BACKEND_WHATS_APP
    ) {
      updatedState.knownConference = WHATS_APP_STRING;

      this.updateConferencing(WHATS_APP_STRING);
    } else if (
      getCustomConferencingName({ user: this.props.currentUser }) &&
      stringArray.some(
        (r) =>
          [
            getCustomConferencingName({ user: this.props.currentUser }).toLowerCase(),
          ].indexOf(r) >= 0
      )
    ) {
      const customConferencing = getCustomConferencingName({
        user: this.props.currentUser,
      });
      updatedState.knownConference = customConferencing;

      this.updateConferencing(customConferencing);
    } else if (
      lowerCaseString &&
      (lowerCaseString.includes("no conference") ||
        lowerCaseString.includes("no conferencing") ||
        lowerCaseString.includes("remove conference"))
    ) {
      updatedState.knownConference = noConferenceString;
      this.updateConferencing(noConferenceString);
    } else if (lowerCaseString?.includes("teams")
      && getCalendarAllowedMeetingProviders(getUserPrimaryCalendar({
        email: (this.props.selectedUser ?? this.props.currentUser)?.email,
        allCalendars
      }))?.includes(OUTLOOK_CONFERENCING.teamsForBusiness)
    ) {
      updatedState.knownConference = OUTLOOK_CONFERENCING.teamsForBusiness;
      this.updateConferencing(OUTLOOK_CONFERENCING.teamsForBusiness);
    }

    return updatedState;
  }

  onKeyUpDownEnter(e, otherInputProps) {
    //if key is up, down, enter, or tab -> use default key
    if (e.keyCode === KEYCODE_ESCAPE) {
      if (this.state.suggestions.length > 0) {
        let updatedState = { suggestions: [], refs: {}, searchState: null };

        this.setState(updatedState);
      } else {
        this.props.onPressEscape && this.props.onPressEscape();
      }
    } else if (
      [
        SEARCH_STATE_LOCATION,
        SEARCH_STATE_ATTENDEES,
        SEARCH_STATE_TIME_ZONE,
        SEARCH_STATE_CALENDARS,
        SEARCH_STATE_TEMPLATES,
      ].includes(this.state.searchState)
    ) {
      this.determineActionOnKeyDownSuggestion(e.keyCode);
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  setUpLocationAutoComplete() {
    const {
      autoComplete,
      detail
    } = getGoogleMapsAutoCompleteAndDetail();
    this.locationAutocomplete = autoComplete;
    this.locationDetail = detail;
  }

  determinePlaceholderText() {
    if (this.state.shouldDisplayPlaceholderText) {
      return "Try: Coffee tomorrow at 3pm";
    } else {
      return "";
    }
  }

  scrollToOption(newIndex) {
    if (newIndex) {
      let ref = this.state.refs[newIndex.key];

      ref &&
        ref.current &&
        ref.current.scrollIntoView({
          behavior: "auto",
          block: "nearest",
        });
    }
  }

  createRefForSuggestion(suggestions) {
    if (!suggestions || suggestions.length === 0) {
      return {};
    }

    let refs = suggestions.reduce((result, option) => {
      result[option.key] = createRef();

      return result;
    }, {});

    return refs;
  }

  removeKeyWordsFromString(
    string,
    keyWordsArray = KEYWORDS_BACK_TRIMMED_ARRAY
  ) {
    let updatedString = string.replace(
      new RegExp(String.fromCharCode(160), "g"),
      ""
    );
    if (updatedString[updatedString.length - 1] === "&") {
      updatedString = updatedString.substring(0, updatedString.length - 1);
    }

    keyWordsArray.forEach((w) => {
      updatedString = updatedString.replace(w, "");
    });

    return updatedString;
  }

  setTimeForSingleWords(string) {
    let result = this.determineTimeBasedOnSingleWord(string);

    if (result) {
      this.props.setStartTime && this.props.setStartTime(result, true);
    }
  }

  determineTimeBasedOnSingleWord(string, skipCheckForSingleWord = false) {
    if (!string || string.length === 0) {
      return null;
    }

    let array = string.trim().split(" ");

    if (
      !this.props.createdFromDrag &&
      (skipCheckForSingleWord || array.length === 1) &&
      array[0] &&
      array[0].length > 0
    ) {
      let word = array[0].toLowerCase().trim();

      switch (word) {
        case "lunch":
          return startOfHour(set(new Date(), { hours: 12 }));
        case "dinner":
          return startOfHour(set(new Date(), { hours: 18 }));
        default:
          break;
      }
    }
  }

  resetTime() {
    let { initialStartTime, initialEndTime } = this.state;

    this.props.setStartTime && this.props.setStartTime(initialStartTime, true);
    this.props.setEndTime && this.props.setEndTime(initialEndTime, true);

    this.setState({ detectedStartTime: null, detectedEndTime: null });
  }

  isSearchStateCalendar() {
    return this.state.searchState === SEARCH_STATE_CALENDARS;
  }

  determineSuggestionLabel() {
    let label;

    switch (this.state.searchState) {
      case SEARCH_STATE_LOCATION:
        label = "Addresses";
        break;
      case SEARCH_STATE_ATTENDEES:
        label = "Contacts";
        break;
      case SEARCH_STATE_TIME_ZONE:
        label = "Time zones";
        break;
      case SEARCH_STATE_TEMPLATES:
        label = "Templates";
        break;
      case SEARCH_STATE_CALENDARS:
        label = "Calendars";
        break;
      default:
        break;
    }

    return label;
  }

  getIndexOfFirstSetItem(newText) {
    let setItems =
      this.state.knownLocation.length > 0 ? [this.state.knownLocation] : [];
    let knownAttendees = Object.values(this.state.knownAttendees);

    if (knownAttendees.length > 0) {
      setItems = setItems.concat(knownAttendees);
    }

    if (this.state.knownCalendar) {
      setItems = setItems.concat(this.state.knownCalendar);
    }

    let setItemIndex = newText.length;

    const keywordRegex = KEYWORDS_TRIMMED.map(keyword => `${keyword}\\b`).join("|");
    setItems.forEach((search) => { // The variable that holds the word you are searching for.
      // has to work with something like "Fill project worksheet /work"
      // also work with "lunch with [John li] at [Twitter] for 20min"
      const regex = new RegExp("\\b" + search + "(?![a-z0-9])", "i");

      let tempIndex = newText.search(regex);

      if (tempIndex > 0 && tempIndex < setItemIndex) {
        setItemIndex = tempIndex;
      }

      // searches for things like "John liat";
      const regexWithKeywordBehind = new RegExp("\\b" + search + "(?=(" + keywordRegex + "|\\b))", "i");  // check for the presence of any keyword immediately after the search term
      tempIndex = newText.search(regexWithKeywordBehind);

      if (tempIndex > 0 && tempIndex < setItemIndex) {
        setItemIndex = tempIndex;
      }
    });

    return setItemIndex;
  }

  getAllChronoText(chronoParse) {
    if (!chronoParse || chronoParse.length === 0) {
      return "";
    }

    let allChronoText = "";

    chronoParse.forEach((e) => {
      allChronoText = allChronoText + e.text + " ";
    });

    return allChronoText;
  }

  resetSearchQueries(oldState) {
    let updatedState = _.clone(oldState);

    updatedState.suggestions = [];
    updatedState.locationQuery = "";
    updatedState.attendeesQuery = "";
    updatedState.suggestionIndex = null;

    return updatedState;
  }

  handleIfStringIsAlsoAddress(chronoText, chronoParseElement, text) {
    // fix address confusion here

    let textIndex = chronoParseElement.index;

    let restOfText = text.substring(textIndex, text.length + 1); // string from location of string detection to end

    let restOfTextArray = restOfText.split(" ");

    let chronoTextArray = chronoText.split(" ");
    let lastElementChronoText = chronoTextArray[chronoTextArray.length - 1];

    let lastElementInTextArray = restOfTextArray.indexOf(lastElementChronoText);

    return this.isArrayAddress(
      restOfTextArray.slice(lastElementInTextArray, lastElementInTextArray + 2)
    ).isAddress;
  }

  searchForLocationSuggestion(newState, updatedLocation, isTimeAtEndOfInput) {
    let updatedState = _.clone(newState);

    if (
      this.locationAutocomplete &&
      updatedLocation.length > 0 &&
      !isTimeAtEndOfInput
    ) {
      this.locationAutocomplete.getPlacePredictions(
        { input: updatedLocation },
        (predictions) => {
          let formattedSuggestions =
            this.formatLocationPredictions(predictions);

          this.setState({
            suggestions: formattedSuggestions,
            refs: this.createRefForSuggestion(formattedSuggestions),
          });
        }
      );
    } else if (this.state.searchState === SEARCH_STATE_TIME_ZONE) {
      // do nothing
    } else {
      if (!this.locationAutocomplete) {
        this.setUpLocationAutoComplete();
      }

      updatedState.suggestions = [];
    }

    return updatedState;
  }

  isArrayAddress(array) {
    if (array.length < 2) {
      return { isAddress: false, address: null };
    }

    let element1 = array[0];
    let element2 = array[1];

    let parsedElement1 = parseInt(element1);
    let parsedElement2 = parseInt(element2);

    const currentYear = new Date().getFullYear();
    const isWithinCurrentYearRange = (number) => {
      // do not count number that could be year for detecting address
      return number >= currentYear - 2 && number <= currentYear + 4;
    };

    if (
      parsedElement1 &&
      parsedElement1 > 100 &&
      !parsedElement2 &&
      !KEYWORDS_TRIMMED_ARRAY_FRONT_BACK.includes(element2.toLowerCase()) &&
      !isWithinCurrentYearRange(parsedElement1) &&
      !isWithinCurrentYearRange(parsedElement2)
    ) {
      let address = `${element1} ${element2}`;

      return { isAddress: true, address };
    } else {
      return { isAddress: false, address: null };
    }
  }

  parseDurationText(text) {
    if (!text) {
      return null;
    }

    let loweredCaseText = text.toLowerCase();
    let transformText = textToNumber(loweredCaseText);

    let durationResult = durationParse(transformText, "m");

    if (loweredCaseText.includes("half")) {
      durationResult = durationResult + 30;
    }

    return durationResult;
  }

  removeDurationTextFromString({entireText, durationString}) {
    if (!entireText) {
      return "";
    } else if (!durationString) {
      return entireText;
    }

    // 1) get location of duration string
    // 2) check if "for " is infront
    // 3) go to next keyword, remove string in between
    // 3.1) if no next keyword -> should not just delete everything at start of duration

    // TODO: this fails for queries where duration comes first before time and date
    // E.g. "Coffee for 20m tomorrow at 3pm" -> deletes "for 20m tomorrow"

    // 1)
    // check for space after duration string
    let durationStringIndex = entireText.indexOf(durationString);
    let durationStringLength = durationString.length; // change if there's "for " infront

    if (durationStringIndex < 0) {
      return entireText;
    }

    // 2)
    if (durationStringIndex >= 5) {
      let priorSubString = entireText
        .substring(durationStringIndex - 4, durationStringIndex)
        .toLowerCase();

      if (priorSubString === "for ") {
        durationStringIndex = durationStringIndex - 4;
        durationStringLength = durationStringLength + 4;
      }
    }

    // 3)
    const loweredCaseText = entireText.toLowerCase();
    let durationEndIndex = entireText.length;

    // TODO: bug below when you start with duration
    // The issue is that if there's a match before the next keyword, it will be overwritten
    // so 10min next monday, next monday will also be removed along with 10min

    KEYWORDS_BACK_TRIMMED_ARRAY.forEach((k) => {
      let keyWordIndex = loweredCaseText.indexOf(k, durationStringIndex);

      // update the end index to the beginning of next upcoming keyword
      if (
        keyWordIndex >= 0 &&
        keyWordIndex > durationStringIndex &&
        keyWordIndex < durationEndIndex
      ) {
        durationEndIndex = keyWordIndex;
      }
    });

    // if there's no keyword behind, only remove duration string
    let textToBeReplaced =
      entireText.length === durationEndIndex
        ? entireText.substring(
            durationStringIndex,
            durationStringIndex + durationStringLength
          )
        : entireText.substring(durationStringIndex, durationEndIndex);

    const getLastWordInString = (words) => {
      let n = words.split(" ");
      return n[n.length - 1];
    };

    const lastWord = getLastWordInString(textToBeReplaced);
    if (
      !DURATION_UNITS.includes(lastWord.toLowerCase()) &&
      !this.parseDurationText(lastWord)
    ) {
      // last word is not duration -> '20m tomorrow';
      // but do not delete if it's the m of "15 m";
      textToBeReplaced = textToBeReplaced.replace(lastWord, "");
    }

    if (!textToBeReplaced.trim()) {
      // if space
      return entireText;
    }

    const filteredEntireText = entireText
      .replace(" " + textToBeReplaced, "")
      .replace(textToBeReplaced.trim(), "");

    return filteredEntireText;
  }

  createUpdatedTextAndKeyWordForInputTime(
    chronoParseArray,
    text,
    updatedState
  ) {
    // argument text is still full string without removing time string
    let { keyWordIndex, lastKeyWord } =
      this.getLastIndexAndKeyWordFromString(text);

    const keyWord = lastKeyWord;

    const index = keyWordIndex;
    // let keyWordIndex = index;
    if (!keyWord) {
      keyWordIndex = 0;
    }

    // Need to change below to text/textUpToThatPoint
    let updatedText = text;

    let re = new RegExp("\\B@(\\S+)", "gi");
    let removedMoreRegexMatchFromString = false;

    let isTimeAtEndOfInput = false;

    chronoParseArray.forEach((p) => {
      if (!p.text) {
        return;
      }

      let textWithSpaceAfter = p.text + " ";
      let replaceText = p.text;

      // To remove @10am @twitter (back to back @)
      let regexMatches = replaceText ? replaceText.match(re) : null;

      if (
        regexMatches &&
        regexMatches.length > 1 &&
        !chronoParseHasKnownValues(this.nlpChronoDetection(regexMatches[1]))
      ) {
        let regexReplacedText = replaceText
          .replace(
            replaceText.includes(regexMatches[1] + " ")
              ? regexMatches[1] + " "
              : regexMatches[1],
            ""
          )
          .trimEnd();

        replaceText = regexReplacedText;
        textWithSpaceAfter = regexReplacedText + " ";
        removedMoreRegexMatchFromString = true;
      } else if (
        regexMatches &&
        regexMatches.length > 0 &&
        !chronoParseHasKnownValues(this.nlpChronoDetection(regexMatches[0]))
      ) {
        let regexReplacedText = replaceText
          .replace(
            replaceText.includes(regexMatches[0] + " ")
              ? regexMatches[0] + " "
              : regexMatches[0],
            ""
          )
          .trimEnd();

        replaceText = regexReplacedText;
        textWithSpaceAfter = regexReplacedText + " ";
        removedMoreRegexMatchFromString = true;
      }

      //Check if should remove text with space after
      if (updatedText.indexOf(textWithSpaceAfter) >= 0) {
        replaceText = textWithSpaceAfter;
      }

      let indexOfReplacedText = text.indexOf(replaceText);

      // update index
      if (indexOfReplacedText < keyWordIndex) {
        keyWordIndex = keyWordIndex - replaceText.length;
      }

      if (
        !removedMoreRegexMatchFromString &&
        text.length - (indexOfReplacedText + p.text.length) <= 2
      ) {
        isTimeAtEndOfInput = true;
      }

      if (
        p &&
        p.start &&
        p.start.knownValues &&
        p.start.knownValues.isDuration &&
        !this.parseDurationText(p.text)
      ) {
        return;
      }

      updatedText = updatedText.replace(replaceText, "");
    });

    return {
      updatedText,
      detectedKeyWord: keyWord,
      detectedKeyWordIndex: index,
      updatedState,
      isTimeAtEndOfInput,
    };
  }

  handleNoKnownTimeOrDate(isStart = true) {
    this.handleNoDateDetected(isStart);
    this.handleNoTimeDetected(isStart);
  }

  isStringKeyWord(string) {
    return (
      KEYWORDS_ARRAY.includes(string) ||
      KEYWORDS_BACK_TRIMMED_ARRAY.includes(string) ||
      KEYWORDS_FRONT_TRIMMED_ARRAY.includes(string) ||
      KEYWORDS_TRIMMED_ARRAY_FRONT_BACK.includes(string)
    );
  }

  setTimeAndDate(knownValues, isStart, duration) {
    let knownValueKeys = Object.keys(knownValues);

    if (
      knownValueKeys.some(
        (r) => ["weekday", "day", "month", "year"].indexOf(r) >= 0
      )
    ) {
      // set date
      this.createDateFromChrono(knownValues, isStart);
    } else {
      // No date detected
      this.handleNoDateDetected(isStart);
    }

    if (knownValueKeys.some((r) => ["hour", "minute"].indexOf(r) >= 0)) {
      // set time
      this.createTimeFromChrono(knownValues, isStart, duration);
    } else {
      // No time detected
      this.handleNoTimeDetected(isStart, duration);
    }
  }

  handleNoDateDetected(isStart) {
    if (isStart) {
      if (this._detectedStartDate) {
        // Remove start date nlp put in and put in original date
        this.props.setStartDate &&
          this.props.setStartDate(this.state.initialStartDate, true);

        this._detectedStartDate = null;
        this.setState({ knownTimeText: null });
      }
      // Else -> nothing
    } else {
      // For end date -> remove start date nlp put in and put in original date
      if (this._detectedEndDate) {
        this.props.setEndDate &&
          this.props.setEndDate(this.state.initialEndDate, true);
        this._detectedEndDate = null;
        this.setState({ knownTimeText: null });
      }
    }
  }

  handleNoTimeDetected(isStart, duration = null) {
    if (isStart) {
      if (this.state.detectedStartTime) {
        // Remove start time nlp put in and put in original date
        if (this.props.setStartTime) {
          if (duration) {
            this.props.setStartTime(
              this.state.initialStartTime,
              true,
              addMinutes(this.state.initialStartTime, duration)
            );
          } else {
            this.props.setStartTime(this.state.initialStartTime, true);
          }
        }

        this.setState({
          detectedStartTime: null,
          knownTimeText: null,
          knownTimeZone: null,
        });

        return;
      } else if (duration) {
        this.props.setStartTime &&
          this.props.setStartTime(
            this.state.initialStartTime,
            true,
            addMinutes(this.state.initialStartTime, duration)
          );
      }
      // Else -> nothing
    } else {
      // For end time -> remove end time nlp put in and put in original date
      if (this.state.detectedEndTime) {
        if (this.state.detectedStartTime) {
          // add time diff between start and end to known start time
          let time = this.state.detectedStartTime;

          let formattedTime = addMinutes(
            time,
            this.state.initialStartAndEndTimeDiff
          );

          this.props.setEndTime && this.props.setEndTime(formattedTime, true);

          this.setState({ detectedEndTime: null, knownTimeText: null });
        } else {
          // push back original end time
          this.props.setEndTime &&
            this.props.setEndTime(this.state.initialEndTime, true);

          this.setState({ detectedEndTime: null, knownTimeText: null });
        }

        return;
      }
    }
  }

  createTimeFromChrono(knownValues, isStartTime = true, duration = null) {
    let newEventTime = startOfMinute(new Date());

    let knownValuesKeys = Object.keys(knownValues);

    const keys = [CHRONO_KEYS.HOUR, CHRONO_KEYS.MINUTE];

    keys.forEach((k) => {
      if (!isNullOrUndefined(knownValues[k])) {
        let knownKeyValue = getKeyValueFromChrono(
          k,
          knownValues[k],
          knownValuesKeys,
          this.props.format24HourTime,
        );
        let typeKey = determineDateFnsKeyName(knownKeyValue.key);

        newEventTime = set(newEventTime, { [typeKey]: knownKeyValue.value });
      }
    });

    if (isStartTime) {
      this.setState({ detectedStartTime: newEventTime });

      // Update end time below with duration
      if (duration) {
        // Only start time gets passed duration and only if end time is not detected
        this.props.setStartTime &&
          this.props.setStartTime(
            newEventTime,
            true,
            addMinutes(newEventTime, duration)
          );
      } else {
        this.props.setStartTime &&
          this.props.setStartTime(
            newEventTime,
            true,
            this.state.knownDuration
              ? addMinutes(newEventTime, this.state.knownDuration)
              : null
          );
      }
    } else {
      this.setState({ detectedEndTime: newEventTime });

      this.props.setEndTime && this.props.setEndTime(newEventTime, true);
    }
  }

  createDateFromChrono(knownValues, isStartDate = true) {
    let newEventDate = startOfMinute(new Date());
    const dateRelatedKeys = [CHRONO_KEYS.MONTH, CHRONO_KEYS.DAY, CHRONO_KEYS.YEAR]; // month needs to go first otherwise if set date like 31 on feb, it's going to be incorrect.

    let knownValuesKeys = Object.keys(knownValues);

    let knownValueWeekDay = knownValuesKeys.includes("weekday");
    let knownValueDate = knownValuesKeys.some(
      (r) => dateRelatedKeys.indexOf(r) >= 0
    );

    if (knownValueWeekDay && !knownValueDate) {
      // pick the nearest upcoming day fo week
      let nearestDayOfWeek = createNearestForwardDayOfWeek(
        new Date(),
        knownValues["weekday"]
      );

      newEventDate = set(newEventDate, {
        date: getDate(nearestDayOfWeek),
        month: getMonth(nearestDayOfWeek),
        year: getYear(nearestDayOfWeek),
      });
    } else {
      dateRelatedKeys.forEach((k) => {
        if (knownValues[k] !== undefined && knownValues[k] !== null) {
          let knownKeyValue = getKeyValueFromChrono(
            k,
            knownValues[k],
            knownValuesKeys,
            this.props.format24HourTime
          );

          // chrono provide different key names than date-fns
          const keyName = determineDateFnsKeyName(knownKeyValue.key);
          newEventDate = set(newEventDate, { [keyName]: knownKeyValue.value });
        }
      });

      // if year is not part of input -> check if year is in the past
      // if year is in the past -> go to next year
      // Test cases: (*note: currently Dec 2021)
      // lunch on jan 3rd -> jan 3rd 2022
      // lunch on jan 3rd 2021 -> jan 3rd 2021
      // lunch on jan 3rd 2023 -> jan 3rd 2021
      // lunch on jan 3rd to jan 4th 2021 -> jan3rd-4th 2021
      // lunch on jan 3rd to jan 4th 2023 -> jan3rd-4th 2023
      // lunch on jan 3rd to jan 4th -> jan3rd-4th 2022
      // Lunch jan 3rd with john at Twitter HQ  -> jan 3rd with john and at twitter location
      // lunch dec 18th -> dec 18th

      if (
        !Object.keys(knownValues).includes("year") &&
        isBefore(newEventDate, new Date())
      ) {
        newEventDate = addYears(newEventDate, 1);
      }
    }

    if (isStartDate) {
      this._detectedStartDate = newEventDate;
      this.props.setStartDate && this.props.setStartDate(newEventDate, true);
    } else {
      // end date
      if (isValidJSDate(this._detectedStartDate) 
        && isValidJSDate(newEventDate)
        && isBeforeDay(newEventDate, this._detectedStartDate)
      ) {
        // if end date is before start date -> set end date to start date
        // end date is before start date
        newEventDate = this._detectedStartDate;
      }
      this._detectedEndDate = newEventDate;
      this.props.setEndDate && this.props.setEndDate(newEventDate, true);
    }
  }

  handleRemovedText(removedText, remainingText) {
    if (!removedText || removedText.length === 0) {
      return;
    }

    if (remainingText.trim().length <= 1) {
      this.resetTime();
    }

    let loweredCaseRemovedText = removedText.toLowerCase();

    if (
      (this.state.knownLocation.length > 0 &&
        loweredCaseRemovedText.indexOf(this.state.knownLocation) >= 0) ||
      (this.state.locationQuery.length > 0 &&
        loweredCaseRemovedText.indexOf(this.state.locationQuery) >= 0)
    ) {
      //Location
      this.removeLocation();
    }

    // Remove email from attendees list
    let { didReplace, removedEmails } = this.didReplacedAttendeeAndEmails(
      loweredCaseRemovedText
    );

    if (didReplace && removedEmails.length > 0) {
      this.removeAttendees(removedEmails);
    }

    let newState = {};

    if (this.state.knownDuration && !this.parseDurationText(remainingText)) {
      newState.knownDuration = null;
    }

    if (
      this.state.suggestions &&
      this.state.suggestions.length > 0 &&
      this.state.searchState === SEARCH_STATE_TIME_ZONE
    ) {
      newState.suggestions = [];
    }

    if (
      this.state.knownTimeZone &&
      (removedText.toLowerCase().includes(this.state.knownTimeZone[0]) ||
        removedText.toLowerCase().includes(this.state.knownTimeZone[1]))
    ) {
      this.props.setEventTimeZone &&
        this.props.setEventTimeZone({
          start: this.state.initialTimeZone,
          end: this.state.initialTimeZone,
        });
      newState.knownTimeZone = null;
    }

    if (
      this.state.knownCalendar &&
      removedText.includes(this.state.knownCalendar)
    ) {
      newState.knownCalendar = null;

      this.props.setCalendar({ value: this.state.initialCalendar });
    }

    if (
      this.state.hasSetAllDay &&
      this.props.isAllDay &&
      !this.doesStringIncludeAllDay(remainingText)
    ) {
      newState.hasSetAllDay = false;
      this.props.setAllDay(false);
    }

    this.setState(newState);

    this.handleReplaceConference(removedText, remainingText);
  }

  handleReplaceConference(removedText, remainingText) {
    let formattedRemainingText = remainingText.toLowerCase();

    if (
      this.state.knownConference &&
      !stringContainsAnyWordInArray(formattedRemainingText, CONFERENCE_ARRAY)
    ) {
      this.updateConferencing(noConferenceString);
      this.setState({ knownConference: null });
    }
  }

  removeAttendees(removedEmails) {
    this.props.removeListOfAttendees &&
      this.props.removeListOfAttendees(removedEmails);

    let updatedKnownAttendees = _.clone(this.state.knownAttendees);
    removedEmails.forEach((e) => {
      updatedKnownAttendees = _.omit(updatedKnownAttendees, e);
    });

    this.setState({ knownAttendees: updatedKnownAttendees });
  }

  didReplacedAttendeeAndEmails(text) {
    let didReplace = false;
    let removedEmails = [];

    if (Object.keys(this.state.knownAttendees).length === 0) {
      return { removedEmails: [], didReplace: false };
    } else {
      // Has known attendees
      let attendee;

      Object.keys(this.state.knownAttendees).forEach((email) => {
        attendee = this.state.knownAttendees[email];

        if (text.toLowerCase().indexOf(attendee) >= 0) {
          removedEmails = removedEmails.concat(email);
          didReplace = true;
        }
      });

      return { removedEmails, didReplace };
    }
  }

  removeLocation() {
    this.props.setLocation && this.props.setLocation("", true);

    this.setState({ knownLocation: "" });
  }

  findDiffBetweenTwoStrings(str1, str2) {
    let lengthDifference = Math.abs(str1.length - str2.length);

    let diff = "";

    str2.split("").forEach(function (val, i) {
      if (val !== str1.charAt(i)) diff += val;
    });

    return diff.substring(0, lengthDifference + 1);
  }

  formatLocationPredictions(predictions) {
    if (!predictions) {
      return [];
    }

    let formattedPredictions = [];
    predictions.forEach((p, index) => {
      if (p.description && p.place_id) {
        let elem = {
          description: p.description,
          index: `location_suggestion_${index}`,
          active: false,
          key: ReplaceSpaceWithUnderscore(p.description),
          placeId: p.place_id,
        };

        formattedPredictions = formattedPredictions.concat(elem);
      }
    });

    return formattedPredictions;
  }

  setAttendeesSuggestions({
    domainResponse, 
    contactsResponse, 
    searchInput
  }) {
    if (!this._isMounted) {
      return;
    }

    let updatedResponse = [];
    const matchingRecentContacts = getRecentContactsMatches(
      getRecentContactsForUpsert({user: this.props.currentUser}),
      searchInput
    );
    let contactGroupMatches = getContactGroupMatches(
      this.props.currentUser,
      searchInput
    );

    if (matchingRecentContacts?.length > 0) {
      updatedResponse = updatedResponse.concat(matchingRecentContacts)
    }

    if (domainResponse?.length > 0) {
      updatedResponse = updatedResponse.concat(domainResponse)
    }

    if (contactGroupMatches?.length > 0) {
      updatedResponse = updatedResponse.concat(contactGroupMatches)
    }

    if (contactsResponse?.length > 0) {
      updatedResponse = updatedResponse.concat(contactsResponse)
    }

    // If no matches in domain and contact
    if (updatedResponse.length === 0 && searchInput.length > 0) {
      if (searchInput.includes(" ")) {
        // space in input, can't be email
        this.setState({ suggestions: [], refs: {} });
        return;
      }

      const createFromSuggestion = [
        {
          active: true,
          description: `Add email: ${searchInput}`,
          email: searchInput,
          name: null,
          id: "add_email_index_0",
          index: 0,
          key: "add_email_option",
        },
      ];

      this.setState({
        suggestions: createFromSuggestion,
        refs: this.createRefForSuggestion(createFromSuggestion),
      });

      return;
    }

    //has matches
    const uniqueEmails = [];
    const filteredResponses = updatedResponse.filter((r) => {
      const email = getObjectEmail(r);
      if (email && !uniqueEmails.includes(email)) {
        uniqueEmails.push(email);
        return true;
      }
      return false;
    });

    const formattedUpdatedResponse = filteredResponses.map((r, index) => {
      return this.createAttendeesSuggestions(r, index);
    });

    this.setState({
      suggestions: formattedUpdatedResponse,
      refs: this.createRefForSuggestion(formattedUpdatedResponse),
    });
  }

  createUpdatedQueryWithLocation(queryString, addressString) {
    if (!queryString || !addressString) {
      return { updatedQuery: queryString || "", truncatedAddress: "" };
    }

    let locationSearchKeyWords = LOCATION_KEYWORDS.concat([
      "</span>@",
      "&nbsp;@",
    ]);

    let { index, keyWord } = this.locateLastIndexOfKeyWords(
      locationSearchKeyWords,
      queryString
    );

    if (index === -1) {
      return { updatedQuery: queryString || "", truncatedAddress: "" };
    } else {
      let lastKeyWordIndexPlusLengthOfKeyWord = index + keyWord.length;
      let addressCommaIndex = addressString.indexOf(",");
      let truncatedAddress;

      if (addressCommaIndex === -1) {
        truncatedAddress = addressString;
      } else {
        truncatedAddress = addressString.substring(0, addressCommaIndex);
      }

      const updatedQuery =
        queryString.substring(0, lastKeyWordIndexPlusLengthOfKeyWord) +
        createInputTextBlock(truncatedAddress, this.props.isDarkMode);

      return {
        updatedQuery: updatedQuery || "",
        truncatedAddress: truncatedAddress || "",
      };
    }
  }

  locateLastIndexOfKeyWords(keyWordsArray, queryString) {
    if (!queryString || queryString.length === 0) {
      return { index: -1, keyWord: null };
    }

    let workingString = queryString.toLowerCase();

    let index = -1;
    let keyWord;
    let i;

    keyWordsArray.forEach((k) => {
      i = workingString.lastIndexOf(k);

      if (i > index) {
        index = i;
        keyWord = k;
      }
    });

    return { index, keyWord };
  }

  determineActionOnKeyDownSuggestion(keyCode) {
    let suggestions = this.state.suggestions;

    let currentIndex = getCurrentActiveIndex(suggestions);

    // don't want to use switch because can't declare the same variable more than one in one block
    if (keyCode === KEYCODE_UP_ARROW) {
      let { suggestion, index } = pressUpSuggestion(
        currentIndex,
        suggestions,
        this.scrollToOption
      );

      this.setState({
        suggestionIndex: index,
        suggestions: suggestion,
        refs: this.createRefForSuggestion(suggestion),
      });
    } else if (keyCode === KEYCODE_DOWN_ARROW) {
      let { suggestion, index } = pressDownSuggestions(
        currentIndex,
        suggestions,
        this.scrollToOption
      );

      this.setState({
        suggestionIndex: index,
        suggestions: suggestion,
        refs: this.createRefForSuggestion(suggestion),
      });
    } else if (
      (keyCode === KEYCODE_ENTER || keyCode === KEYCODE_TAB) &&
      currentIndex !== undefined &&
      currentIndex !== null &&
      suggestions[currentIndex]
    ) {
      if (
        this.state.lastPressedCmd &&
        differenceInMilliseconds(new Date(), this.state.lastPressedCmd) < 2000
      ) {
        // Do nothing here
      } else if (this.state.searchState === SEARCH_STATE_ATTENDEES) {
        let attendee = suggestions[currentIndex];

        this.addAttendees(attendee);
      } else if (this.state.searchState === SEARCH_STATE_LOCATION) {
        let addressSuggestion = suggestions[currentIndex];

        this.handleLocationSelect(addressSuggestion);
      } else if (this.state.searchState === SEARCH_STATE_TIME_ZONE) {
        let timeZoneSuggestion = suggestions[currentIndex];

        this.handleTimeZoneSelect(timeZoneSuggestion);
      } else if (this.state.searchState === SEARCH_STATE_CALENDARS) {
        this.handleSelectCalendar(suggestions[currentIndex]);
      } else if (this.state.searchState === SEARCH_STATE_TEMPLATES) {
        this.handleSelectTemplate(suggestions[currentIndex]);
      }
    }
  }

  setAllSuggestionsInactive() {
    let updatedSuggestions = makeAllSuggestionsInactive(this.state.suggestions);

    this.setState({
      suggestions: updatedSuggestions,
      refs: this.createRefForSuggestion(updatedSuggestions),
    });
  }

  addAttendees(contact) {
    if (isEmptyObjectOrFalsey(contact)) {
      return;
    }

    if (!isValidEmail(contact.email) && !contact.hasMultiple) {
      return;
    }

    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;

    this.props.setAttendees &&
      this.props.setAttendees({
        email: contact.email,
        shouldRefocusAttendeeInput: false,
        notifyChange: true,
        userEmail: getMeetWithUserEmail({
          allLoggedInUsers,
          contact,
          currentUser,
          allCalendars,
          masterAccount,
        }),
      });

    let { updatedQuery, attendeeString } =
      this.createUpdatedQueryWithAttendee(contact);

    let updatedKnownAttendees = _.clone(this.state.knownAttendees);

    updatedKnownAttendees[contact.email] = attendeeString.toLowerCase();

    this.setState(
      {
        suggestionIndex: null,
        suggestions: [],
        refs: {},
        attendeesQuery: "",
        query: updatedQuery,
        knownAttendees: updatedKnownAttendees,
      },
      () => this.onChangeInputSummarySearchState({}, updatedQuery, true)
    );
  }

  createUpdatedQueryWithAttendee(contact) {
    const name = contact.name;
    const email = contact.email;

    let element = this.getNLPFieldElement();

    if (!element) {
      return;
    }

    const currentQuery = element.innerHTML;

    const lastIndex = currentQuery.lastIndexOf(this.state.attendeesQuery);

    let preAttendeesQuery = currentQuery;

    if (lastIndex >= 0) {
      preAttendeesQuery = currentQuery.substring(0, lastIndex);
    }

    const updatedQuery = `${preAttendeesQuery} ${createInputTextBlock(
      name || email,
      this.props.isDarkMode
    )}`;

    this.updateInnerHtml(updatedQuery);

    const updatedInnerText = extractTextFromHtml(updatedQuery);

    element = null;

    return { updatedQuery: updatedInnerText, attendeeString: name || email };
  }

  setCaretToEnd() {
    let target = this.getNLPFieldElement();
    if (!target) {
      return;
    }
    let range = document.createRange();
    let sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach(); // optimization

    // set scroll to the end if multiline
    target.scrollTop = target.scrollHeight;
    target = null;
    range = null;
    sel = null;
  }

  getLastIndexAndKeyWordFromString(text) {
    let workingString = text
      .toLowerCase()
      .replace(new RegExp(String.fromCharCode(160), "g"), " ");

    let keyWordIndex = -1;
    let tempIndex;
    let lastKeyWord;

    KEYWORDS_ARRAY.forEach((w) => {
      tempIndex = workingString.lastIndexOf(w);

      // Is first word in the input
      let isFirstWord = tempIndex === 0;
      let doesStartWithSpace = w[0] === " ";

      if (
        tempIndex >= 0 &&
        tempIndex + w.length > keyWordIndex &&
        (isFirstWord || doesStartWithSpace)
      ) {
        keyWordIndex = tempIndex + w.length;
        lastKeyWord = w;
      }
    });

    return { keyWordIndex, lastKeyWord };
  }

  getFirstIndexOfKeyWord(text) {
    let workingString = text.toLowerCase();

    let firstTempIndex;
    let firstKeyWordIndex = text.length;

    KEYWORDS_BACK_TRIMMED_ARRAY.forEach((k) => {
      firstTempIndex = workingString.indexOf(k);

      if (firstTempIndex >= 0 && firstTempIndex < firstKeyWordIndex) {
        firstKeyWordIndex = firstTempIndex;
      }
    });

    return firstKeyWordIndex;
  }

  isDeleteCharacter(event) {
    return event && !event.data && event.inputType === "deleteContentBackward";
  }

  isReplaceCharacters(prevText, newText) {
    return newText.length + 2 < prevText.length;
  }

  async getContactsAndDomainFromDB(attendeeQuery) {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    
    const searchInput = lowerCaseAndTrimStringWithGuard(attendeeQuery || this.state.attendeesQuery);

    const {
      contactResponse,
      domainResponse
    } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser,
      userEmail: getUserEmail(currentUser),
      searchText: searchInput,
    });
    if (!this._isMounted) {
      return;
    }
    if (!equalAfterTrimAndLowerCased(searchInput, this.state.attendeesQuery)) {
      return;
    }

    const knownEmails = new Set(this.state.knownAttendees
      ? Object.keys(this.state.knownAttendees).map((a) => {
          return a.toLowerCase();
        })
      : []);

    const filteredContact = contactResponse.filter(c => !knownEmails.has(c.email.toLowerCase()));
    const filteredDomain = domainResponse.filter(d => !knownEmails.has(d.email.toLowerCase()));

    this.setAttendeesSuggestions({
      domainResponse: filteredDomain,
      contactsResponse: filteredContact,
      searchInput
    });
  }

  searchDomainAndContactsForSuggestions(attendeeQuery = null) {
    if (!attendeeQuery || attendeeQuery.length === 0) {
      this.setState({ suggestions: [], refs: {} });
      return;
    }

    // if user paste -> get results immediately
    // no ned to put another debounce here because we already dounce with every day 
    this.getContactsAndDomainFromDB(attendeeQuery);
  }

  createAttendeesSuggestions(response, index) {
    if (response.email) {
      return {
        active: index === 0,
        description: createAttendeeSuggestionsLabel(response),
        email: response.email,
        name: response.name,
        id: `${response.email}_${index}`,
        index,
        key: response.email,
        hasMultiple: response.hasMultiple,
        emailArray: response.emailArray,
        userEmail: response.userEmail
      };
    }
  }

  stringContainsAnyElementInArray(array, string) {
    let lowerString = string.toLowerCase();

    for (let i = 0; i < array.length; i++) {
      if (lowerString.indexOf(array[i]) > -1) {
        return true;
      }
    }

    return false;
  }

  determineTimeZone(inputText) {
    if (!this.props.setEventTimeZone) {
      return;
    }

    let textArray = inputText.toLowerCase().split(" ");

    let timeZoneSuggestions = [];
    let abbreviateTimeZone = null;
    textArray.forEach((t) => {
      if (TIME_ZONE_ABBREVIATION_ARRAY.indexOf(t) >= 0) {
        this.state.timeZoneAbbreviationObject.forEach((k) => {
          if (k.abbreviation === t) {
            abbreviateTimeZone = t;
            timeZoneSuggestions = timeZoneSuggestions.concat(k.value);
          }
        });
      }
    });

    if (timeZoneSuggestions.length > 0) {
      let filteredTimeZoneSuggestions = [];
      let valueIndex = [];

      timeZoneSuggestions.forEach((k, index) => {
        if (!valueIndex.includes(k)) {
          valueIndex = valueIndex.concat(k);
          let suggestion = {
            active: filteredTimeZoneSuggestions.length === 0,
            description: addAbbrevationToTimeZone({timeZone: k}),
            index: k + index,
            value: k,
            abbreviation: abbreviateTimeZone,
            key: k,
          };
          filteredTimeZoneSuggestions =
            filteredTimeZoneSuggestions.concat(suggestion);
        }
      });

      this.setState({
        suggestions: filteredTimeZoneSuggestions,
        searchState: SEARCH_STATE_TIME_ZONE,
        refs: this.createRefForSuggestion(filteredTimeZoneSuggestions),
      });

      return filteredTimeZoneSuggestions;
    }
  }

  chronoTextHasOtherTimeZone(chronoParse) {
    if (chronoParse && chronoParse[0] && chronoParse[0].text) {
      let chronoTextArray = chronoParse[0].text.toLowerCase().split(" ");

      let containsOtherTimeZones = false;

      OTHER_TIME_ZONES.forEach((t) => {
        if (!containsOtherTimeZones && chronoTextArray.includes(t)) {
          containsOtherTimeZones = true;
        }
      });

      return containsOtherTimeZones;
    }

    return false;
  }

  getStringAfterLastElementMatch(array, string) {
    const lowerString = string.toLowerCase();
    let matchLocation = -1;
    let matchString = string;

    for (let i = 0; i < array.length; i++) {
      let index = lowerString.indexOf(array[i]);

      if (index > -1 && index > matchLocation) {
        matchLocation = index;

        matchString = string.substring(index + array[i].length);
      }
    }

    return matchString;
  }

  setQueryText(text) {
    if (!text || (this.state.query && this.state.query.length > 0)) {
      // text already exist
      return;
    }

    // sets text into nlp and also execute any code (lunch at 5pm -> sets time to 5pm)
    // document.execCommand("insertHTML", false, text);

    // sets text into nlp but does not execute code
    this.updateInnerHtml(text);
  }

  createCalendarSuggestions(keyWordIndex, textUpToThatPoint) {
    if (
      isEmptyObjectOrFalsey(this.props.writableCalendars) ||
      !keyWordIndex ||
      !textUpToThatPoint ||
      this.state.knownCalendar
    ) {
      // don't show if you already selected calendar
      return {
        searchState: SEARCH_STATE_SUMMARY,
        suggestions: [],
        calendarSearchQuery: "",
      };
    }

    const query = textUpToThatPoint
      .slice(keyWordIndex)
      .toLowerCase()
      .replace(new RegExp(String.fromCharCode(160), "gmi"), " ");

    let suggestions = []; // calendars that match the query;
    if (query.length === 0) {
      this.props.writableCalendars.forEach((c, index) => {
        suggestions = suggestions.concat(
          this.createCalendarSuggestionItem(c, index)
        );
      });
    } else {
      let calendars = [];
      this.props.writableCalendars.forEach((c) => {
        // search for label and email
        if (
          c.label.trim().toLowerCase().includes(query) ||
          getCalendarProviderId(c.value)?.trim()?.toLowerCase()?.includes(query)
        ) {
          calendars = calendars.concat(c);
        }
      });

      calendars.forEach((c, index) => {
        suggestions = suggestions.concat(
          this.createCalendarSuggestionItem(c, index)
        );
      });
    }

    return {
      searchState: SEARCH_STATE_CALENDARS,
      suggestions,
      calendarSearchQuery: query,
    }; // set suggestions in handleChangeInputContentEditableChange;
  }

  createCalendarSuggestionItem(calendar, index) {
    return {
      description: calendar.label,
      index: `calendar_suggestion_${index}`,
      active: index === 0,
      key: ReplaceSpaceWithUnderscore(calendar.label),
      calendar: calendar,
    };
  }

  replaceLastInstance(initialString, replacedString, replaceWithString) {
    const lastInstanceIndex = initialString.lastIndexOf(replacedString);

    return (
      initialString.substring(0, lastInstanceIndex) +
      replaceWithString +
      initialString.substring(lastInstanceIndex + 1)
    );
  }

  setTimeZoneAbbreviations() {
    if (!this._isMounted) {
      return;
    }

    this.setState({
      timeZoneAbbreviationObject: createTimeZoneAbbrevationObject(),
    });
  }

  doesStringIncludeAllDay(string) {
    return (
      string &&
      (string.toLowerCase().includes("all day") ||
        string.toLowerCase().includes("all-day"))
    );
  }

  nlpChronoDetection(text) {
    const chronoResult = customChronoWordDetection({text});

    if (chronoResult.length === 0 && text && text.includes("weekend")) {
      return this.detectDateForWeekend(text);
    }

    return chronoResult;
  }

  detectDateForWeekend(string) {
    // "next weekend" -> weekend after this upcoming
    // "last weeked" or "previous weekend" -> weekend before
    // just "weekend", "this weekend", or "upcoming weekend" -> this upcoming weekend
    let weekendChrono = {};
    const getSaturday = (sunday) => {
      return startOfDay(subDays(sunday, 1));
    };

    const getSunday = (date) => {
      return endOfWeek(date, { weekStartsOn: 1 });
    };

    if (string.includes("next weekend")) {
      let sunday = getSunday(addWeeks(new Date(), 1));
      weekendChrono.start = getSaturday(sunday);
      weekendChrono.end = sunday;
      weekendChrono.searchString = "next weekend";
    } else if (
      string.includes("last weekend") ||
      string.includes("previous weekend")
    ) {
      let sunday = getSunday(subWeeks(new Date(), 1));
      weekendChrono.start = getSaturday(sunday);
      weekendChrono.end = sunday;
      if (string.includes("last weekend")) {
        weekendChrono.searchString = "last weekend";
      } else if (string.includes("previous weekend")) {
        weekendChrono.searchString = "previous weekend";
      }
    } else if (
      string.includes("this weekend") ||
      string.includes("this upcoming weekend") ||
      string.includes("upcoming weekend")
    ) {
      let sunday = getSunday(new Date());
      weekendChrono.start = getSaturday(sunday);
      weekendChrono.end = sunday;

      if (string.includes("this weekend")) {
        weekendChrono.searchString = "this weekend";
      } else if (string.includes("this upcoming weekend")) {
        weekendChrono.searchString = "this upcoming weekend";
      } else if (string.includes("upcoming weekend")) {
        weekendChrono.searchString = "upcoming weekend";
      }
    }

    if (isEmptyObjectOrFalsey(weekendChrono)) {
      return [];
    }

    let { start, end } = weekendChrono;

    return [
      {
        refDate: new Date(),
        text: weekendChrono.searchString,
        start: {
          knownValues: {
            day: start.getDate(),
            month: start.getMonth() + 1,
            year: start.getFullYear(),
            hour: start.getHours(),
            minute: start.getMinutes(),
          },
        },
        end: {
          knownValues: {
            day: end.getDate(),
            month: end.getMonth() + 1,
            year: end.getFullYear(),
            hour: end.getHours(),
            minute: end.getMinutes(),
          },
        },
      },
    ];
  }

  getNLPFieldElement() {
    return document.getElementById(NLP_INPUT_FIELD_NAME);
  }

  filterOutDurationText(string) {
    let updatedString = string;
    let allMatches = [];
    DURATION_UNITS.forEach((n) => {
      let durationRegex_1 = new RegExp(
        "((?: |^)\\w+){1}" + ` ${n}` + "\\b",
        "gmi"
      );
      let durationRegex_2 = new RegExp("\\b\\d+\\s?" + `${n}s?` + "\\b", "gmi");
      let match_1 = string.match(durationRegex_1);
      let match_2 = string.match(durationRegex_2);
      if (match_1) {
        allMatches = allMatches.concat(match_1);
      }
      if (match_2) {
        allMatches = allMatches.concat(match_2);
      }
    });
    allMatches.forEach((m) => {
      updatedString = updatedString.replace(m, "");
    });

    return updatedString.trim();
  }

  // "Ski trip 1/18/2025-1/25/2025" -> "Ski trip 1/18/2025 - 1/25/2025"
  addSpaceBetweenTwoDatesSeparatedByDash(inputString) {
    if (!inputString) {
      return "";
    }
    // Regex explanation:
    // \d{1,2} matches 1 or 2 digits (for month and day)
    // \/ matches the forward slash
    // \d{1,2} matches 1 or 2 digits (for day)
    // (\d{4})? matches 0 or 1 occurrences of 4 digits (for year), making year optional
    // - matches the hyphen between dates
    // We use capturing groups to reconstruct the string with added spaces, keeping the rest of the string intact
    const regex = /(\d{1,2}\/\d{1,2}(?:\/\d{4})?)\s*-\s*(\d{1,2}\/\d{1,2}(?:\/\d{4})?)/;
    return inputString.replace(regex, '$1 - $2');
  }

  updatePlaceHolder() {
    // https://stackoverflow.com/questions/20941079/how-can-i-make-a-smooth-transition-of-my-inputs-placeholder-text
    let i = 0;
    const placeHolderList = [
      "Try: Coffee tomorrow at 3pm",
      "Try: Team lunch at noon for 45 minutes",
      "Try: Offsite at Blue bottle at 3pm for an hour",
      "Try: Launch sync with John at 5pm pt",
      "Try: Launch review at 3pm pt over Zoom",
      "Try: 1:1 with John Friday for an hour",
    ];
    this._placeHolderInterval = setInterval(() => {
      clearTimeout(this._fadePlaceHolderTimeout);
      this._fadePlaceHolderTimeout = null;

      if (!this.state.shouldDisplayPlaceholderText) {
        clearInterval(this._placeHolderInterval);
        this._placeHolderInterval = null;
        return;
      }

      const element = document.getElementById(NLP_INPUT_FIELD_NAME);
      if (!element) {
        return;
      }

      element.classList.add("fade-placeholder");
      this._fadePlaceHolderTimeout = setTimeout(() => {
        element.setAttribute("dataplaceholder", placeHolderList[i]);
        element.classList.remove("fade-placeholder");
      }, 500);

      i = (i + 1) % placeHolderList.length;
    }, 4 * SECOND_IN_MS);
  }
}

function mapStateToProps(state) {
  let { currentUser, isDarkMode, dateFieldOrder, format24HourTime } =
    state;

  return {
    currentUser,
    isDarkMode,
    dateFieldOrder,
    format24HourTime,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allCalendars = useAllCalendars();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      allCalendars={allCalendars}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(NlpInput));
