import React, { Component, createRef } from "react";
import {
  KEYCODE_C,
  KEYCODE_COMMAND_FIREFOX,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  KEYCODE_CONTROL,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ENTER,
  KEYCODE_ESCAPE,
  KEYCODE_K,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  ReplaceSpaceWithUnderscore,
  combinedContactAndDomainResponse,
  getRecentContactsMatches,
  handleError,
  hasEventPreventDefault,
  isMac,
} from "../../services/commonUsefulFunctions";
import StyleConstants, {
  COMPONENT_NOTIFICATION,
  MIN_HEIGHT,
} from "../../services/globalVariables";
import broadcast from "../../broadcasts/broadcast";
import _ from "underscore";
import { connect } from "react-redux";
import { getDomainAndContacts, sortContactsArrayByExactMatch } from "../../lib/contactFunctions";
import { getRecentlySearchedContacts } from "../../lib/stateManagementFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import {
  getInputStringFromEvent,
  getNameAndEmailFromCopiedEmailString,
  isValidEmail,
  truncateString,
} from "../../lib/stringFunctions";
import { isNullOrUndefined } from "../../services/typeGuards";
import { blurCalendar } from "../../services/appFunctions";

const SUGGESTION_WIDTH = 330;
const MARGIN_LEFT_ON_JUSTIFY_BETWEEN = 280;
const EMAIL_SECTION = "EMAIL_SECTION";
const NAME_SECTION = "NAME_SECTION";

class PersonalizeSlotsSection extends Component {
  constructor(props) {
    super(props);
    this._isCmdKeyDown = false;

    this.state = {
      suggestions: [],
      isMac: isMac(),
    };

    this.determineActionOnKeyDownSuggestion =
      this.determineActionOnKeyDownSuggestion.bind(this);
    this.onKeyDownEscape = this.onKeyDownEscape.bind(this);
    this.onMouseLeaveSuggestion = this.onMouseLeaveSuggestion.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onBlurEmail = this.onBlurEmail.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onInputContentEditableKeyDown =
      this.onInputContentEditableKeyDown.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        <div className="relative">
          {this.renderOptionalSection(
            "Invitee Email",
            this.props.optionalInviteeEmail,
            this.onChangeEmail,
            "",
            this.onBlurEmail,
            this.email,
            this.onInputContentEditableKeyDown,
            EMAIL_SECTION,
            this._emailClassName
          )}

          {this.renderInviteeEmailWarning()}

          {this.renderSuggestions(EMAIL_SECTION)}
        </div>

        <div className="relative">
          {this.renderOptionalSection(
            "Invitee Name",
            this.props.optionalInviteeName,
            this.onChangeName,
            "",
            this.onBlurName,
            this.name,
            this.onInputContentEditableKeyDown,
            NAME_SECTION,
            this._nameClassName
          )}

          {this.renderSuggestions(NAME_SECTION)}
        </div>
      </div>
    );
  }

  onPaste(e) {
    try {
      const pasteText = e.clipboardData.getData('text');
      const parsedValue = getNameAndEmailFromCopiedEmailString(pasteText);
      if (!parsedValue) {
        return;
      }
      hasEventPreventDefault(e);
      const {
        name, 
        email
      } = parsedValue;
      if (name && !this.props.optionalInviteeName) {
        this.props.onChangeInviteeName(name);
      }

      if (email && !this.props.optionalInviteeEmail) {
        this.props.onChangeInviteeEmail(email);
      }
    } catch (error) {
      handleError(error);
    }
  }

  renderOptionalSection(
    label,
    value,
    onChange,
    placeHolder,
    onBlur,
    ref,
    onKeyChange,
    section,
    additionalClassName
  ) {
    if (isNullOrUndefined(value)) {
      return null;
    }
    // have to set autoComplete to new-password to turn off auto complete
    // https://stackoverflow.com/questions/40200130/how-to-disable-chrome-to-autofill-username-email-password

    return (
      <div className="display-flex-flex-direction-row align-items-center justify-content-space-between margin-top-15">
        <div className="default-font-size font-weight-300 secondary-text-color">
          {label}
        </div>

        <input
          className={`default-input-field ${additionalClassName || ""}`}
          value={value}
          ref={ref}
          onChange={onChange}
          onPaste={this.onPaste}
          placeholder={placeHolder}
          style={{
            width: 220,
            paddingLeft: 10,
            paddingRight: 10,
            minHeight: MIN_HEIGHT,
          }}
          onBlur={() => {
            onBlur && onBlur();
          }}
          onKeyDown={this.onKeyDownEscape}
          onKeyDownCapture={onKeyChange ? onKeyChange : _.noop}
          onFocusCapture={() => this.setState({ section: section })}
          autoComplete="new-password"
        />
      </div>
    );
  }

  renderSuggestions(section) {
    const { suggestions } = this.state;

    if (suggestions.length === 0 || this.state.section !== section) {
      return null;
    }

    return (
      <div
        className="availability-detail-suggestion-dropdown margin-top-ten position-absolute"
        style={{ zIndex: 1 }}
      >
        {suggestions.map((suggestion, index) => {
          return (
            <div
              className="location-suggestion-item"
              style={
                suggestion.active
                  ? {
                      backgroundColor: this.props.isDarkMode
                        ? StyleConstants.darkModeHoverBackgroundColor
                        : "#F2F3F4",
                      width: SUGGESTION_WIDTH,
                    }
                  : { width: SUGGESTION_WIDTH }
              }
              key={`attendee_suggestion_${index}`}
              onMouseEnter={() => this.onMouseEnterSuggestion(suggestion)}
              onMouseLeave={this.onMouseLeaveSuggestion}
              onClick={() => this.handleOnClickSuggestion(suggestion)}
              ref={this.state.refs[suggestion.key]}
            >
              <span>{truncateString(suggestion.label, 50)}</span>
            </div>
          );
        })}
      </div>
    );
  }

  renderInviteeEmailWarning() {
    if (!this.state.inviteeEmailHasError) {
      return null;
    }

    return (
      <div
        className="create-event-time-warning"
        style={{
          marginLeft: this.props.justifyBetweenLabel
            ? MARGIN_LEFT_ON_JUSTIFY_BETWEEN
            : 110,
        }}
      >
        Invalid email address
      </div>
    );
  }

  determineActionOnKeyDownSuggestion(keyCode) {
    let suggestions = this.state.suggestions;

    let currentIndex = this.getCurrentActiveIndex(suggestions);

    // don't want to use switch because can't declare the same variable more than one in one block
    let updatedState = {};
    if (keyCode === KEYCODE_UP_ARROW) {
      let { suggestion, index } = this.pressUpSuggestion(
        currentIndex,
        suggestions
      );

      updatedState = {
        suggestionIndex: index,
        suggestions: suggestion,
        refs: this.createRefForSuggestion(suggestion),
      };
    } else if (keyCode === KEYCODE_DOWN_ARROW) {
      let { suggestion, index } = this.pressDownSuggestions(
        currentIndex,
        suggestions
      );

      updatedState = {
        suggestionIndex: index,
        suggestions: suggestion,
        refs: this.createRefForSuggestion(suggestion),
      };
    } else if (
      (keyCode === KEYCODE_ENTER || keyCode === KEYCODE_TAB) &&
      currentIndex !== undefined &&
      currentIndex !== null &&
      suggestions[currentIndex]
    ) {
      updatedState = { suggestions: [], refs: {} };

      let selectedOption = suggestions[currentIndex];
      if (selectedOption.email) {
        this.props.onChangeInviteeEmail(selectedOption.email);
        updatedState.inviteeEmailHasError = false;

        this._emailClassName = COMPONENT_NOTIFICATION;
      }

      if (selectedOption.name) {
        this.props.onChangeInviteeName(selectedOption.name);

        this._nameClassName = COMPONENT_NOTIFICATION;
      }

      this.startComponentHasChangedNotificationTimer();
    }

    this._isCmdKeyDown = false;

    this.setState(updatedState);
  }

  onKeyDownEscape(e) {
    if (e?.keyCode === KEYCODE_ESCAPE) {
      blurCalendar();
    }
  }

  onMouseEnterSuggestion(suggestion) {
    let resetSuggestions = this.makeAllSuggestionsInactive(
      this.state.suggestions
    );

    let updatedSuggestions = [];

    resetSuggestions.forEach((s) => {
      if (s.index === suggestion.index) {
        let updatedElement = s;
        updatedElement.active = true;

        updatedSuggestions = updatedSuggestions.concat(updatedElement);
      } else {
        updatedSuggestions = updatedSuggestions.concat(s);
      }
    });

    this.setState({
      hoverOverSuggestion: true,
      suggestions: updatedSuggestions,
      refs: this.createRefForSuggestion(updatedSuggestions),
    });
  }

  onMouseLeaveSuggestion() {
    this.setState({ hoverOverSuggestion: false });
  }

  onChangeName(e) {
    this.props.onChangeInviteeName(getInputStringFromEvent(e));
    this.onInputChange(e);
  }

  async onInputChange(e) {
    // search with indexdb
    const value = getInputStringFromEvent(e);
    const searchInput = value.toLowerCase();

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const user = this.getUser();

    const { contactResponse, domainResponse } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser: user,
      userEmail: user.email,
      searchText: searchInput,
    });
    if (!this._isMounted) {
      return;
    }

    this.setOptionsBasedOnSearch(domainResponse, contactResponse, value);
  }

  getUser() {
    return this.props.selectedUser ?? this.props.currentUser;
  }

  createValue(name, email) {
    if (!name) {
      return email;
    } else {
      if (name.includes(email)) {
        return email;
      } else {
        return `${name} (${email})`;
      }
    }
  }

  setOptionsBasedOnSearch(domainResponse, contactResponse, query) {
    if (!this._isMounted) {
      return;
    }

    let matchingRecentContacts = getRecentContactsMatches(
      getRecentlySearchedContacts(this.getUser()),
      query
    );
    const filteredEmails = combinedContactAndDomainResponse(
      matchingRecentContacts,
      domainResponse,
      contactResponse,
      []
    ).filter((c) => !c.hasMultiple);
    const sortedEmails = sortContactsArrayByExactMatch(filteredEmails, query);

    let suggestions = sortedEmails.map((e, index) => {
      let labelValue = this.createValue(e.name, e.email);
      return {
        email: e.email,
        label: truncateString(labelValue, 200),
        name: e.name,
        active: index === 0,
        index,
        key: ReplaceSpaceWithUnderscore(labelValue),
      };
    });

    this.setState({
      suggestions,
      refs: this.createRefForSuggestion(suggestions),
    });
  }

  createRefForSuggestion(suggestions) {
    if (!suggestions || suggestions.length === 0) {
      return {};
    }

    return suggestions.reduce((result, option) => {
      result[option.key] = createRef();

      return result;
    }, {});
  }

  onBlurEmail() {
    if (this.state.hoverOverSuggestion) {
      // do nothing, otherwise click on suggestion does not run
    } else if (
      this.props.optionalInviteeEmail &&
      this.props.optionalInviteeEmail.length > 0 &&
      !isValidEmail(this.props.optionalInviteeEmail) &&
      !this.state.inviteeEmailHasError
    ) {
      this.setState({ inviteeEmailHasError: true, suggestions: [], refs: {} });
    } else if (
      ((this.state.props && this.props.optionalInviteeEmail.length === 0) ||
      isValidEmail(this.props.optionalInviteeEmail)) &&
      this.state.inviteeEmailHasError
    ) {
      this.setState({ inviteeEmailHasError: false, suggestions: [], refs: {} });
    } else {
      this.setState({ suggestions: [], refs: {} });
    }
  }

  onInputContentEditableKeyDown(event) {
    if (
      [
        KEYCODE_ENTER,
        KEYCODE_TAB,
        KEYCODE_UP_ARROW,
        KEYCODE_DOWN_ARROW,
        KEYCODE_ESCAPE,
      ].includes(event.keyCode)
    ) {
      if (event.keyCode === KEYCODE_ENTER) {
        event.preventDefault();
      } else if (
        event.keyCode === KEYCODE_TAB &&
        this.state.suggestions &&
        this.state.suggestions.length > 0
      ) {
        event.preventDefault();
      }

      this.onKeyUpDownEnter(event);
    } else {
      this.onKeyDownSelect(event);
    }
  }

  onKeyDownSelect(data) {
    if (
      this.state.isMac &&
      (data.keyCode === KEYCODE_COMMAND_LEFT ||
        data.keyCode === KEYCODE_COMMAND_RIGHT ||
        data.keyCode === KEYCODE_COMMAND_FIREFOX)
    ) {
      this._isCmdKeyDown = true;
    } else if (!this.state.isMac && data.keyCode === KEYCODE_CONTROL) {
      this._isCmdKeyDown = true;
    } else {
      if (this._isCmdKeyDown && data.keyCode === KEYCODE_C) {
        broadcast.publish("COPY_AVAILABILITY_CONTENT");
      } else if (this._isCmdKeyDown && data.keyCode === KEYCODE_K) {
        broadcast.publish("TURN_ON_COMMAND_CENTER");
      } else if (data.keyCode === KEYCODE_ESCAPE) {
        blurCalendar();
      }
      this._isCmdKeyDown = false;
    }
  }

  onKeyUpDownEnter(e) {
    //if key is up, down, enter, or tab -> use default key
    if (e.keyCode === KEYCODE_ESCAPE) {
      if (this.state.suggestions.length > 0) {
        this.setState({ suggestions: [], refs: {} });
      }
    }

    this.determineActionOnKeyDownSuggestion(e.keyCode);
  }

  onChangeEmail(e) {
    this.setState({ inviteeEmailHasError: false });

    this.onInputChange(e);
    this.props.onChangeInviteeEmail(getInputStringFromEvent(e));
  }

  getCurrentActiveIndex(list) {
    if (!list || list.length === 0) {
      return null;
    }

    let index = null;

    list.forEach((e, i) => {
      if (!index && e.active) {
        index = i;
      }
    });

    return index;
  }

  makeAllSuggestionsInactive(suggestions) {
    let updatedSuggestions = [];
    let element;

    suggestions.forEach((s) => {
      element = s;
      element.active = false;

      updatedSuggestions = updatedSuggestions.concat(element);
    });

    return updatedSuggestions;
  }

  startComponentHasChangedNotificationTimer(shouldCauseRerender = false) {
    clearTimeout(this.notificationTimer);
    this.notificationTimer = null;

    this.notificationTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.clearComponentHasChangedNotification();
    }, 500);

    if (shouldCauseRerender) {
      this.setState({ rerenderCount: this.state.rerenderCount + 1 });
    }
  }

  clearComponentHasChangedNotification() {
    this._emailClassName = null;
    this._nameClassName = null;
  }

  pressDownSuggestions(currentIndex, suggestions) {
    let updatedSuggestions = this.makeAllSuggestionsInactive(suggestions);

    if (currentIndex === suggestions.length - 1) {
      this.scrollToOption(updatedSuggestions[0]);

      // last suggestion
      return { suggestion: updatedSuggestions, index: null };
    } else if (isNullOrUndefined(currentIndex)) {
      // Nothing is currently active/highlighted
      if (updatedSuggestions[0]) {
        this.scrollToOption(updatedSuggestions[0]);
        // Make sure the first index exists
        updatedSuggestions[0].active = true;
      }

      return { suggestion: updatedSuggestions, index: 0 };
    } else {
      // go down one
      updatedSuggestions[currentIndex + 1].active = true;

      let activeSuggestion = updatedSuggestions[currentIndex + 1];

      this.scrollToOption(activeSuggestion);

      return { suggestion: updatedSuggestions, index: currentIndex + 1 };
    }
  }

  scrollToOption(newIndex) {
    if (newIndex) {
      let ref = this.state.refs[newIndex.key];

      ref &&
        ref.current &&
        ref.current.scrollIntoView({
          behavior: "auto",
          block: "nearest",
        });
    }
  }

  pressUpSuggestion(currentIndex, suggestions) {
    let updatedSuggestions = this.makeAllSuggestionsInactive(suggestions);

    if (currentIndex === 0) {
      // first suggestion
      return { suggestion: updatedSuggestions, index: null };
    } else if (currentIndex === undefined || currentIndex === null) {
      // Can't do !currentIndex because 0 would also fail here
      // Nothing is currently active/highlighted
      let lastSuggestionIndex = suggestions.length - 1;

      if (lastSuggestionIndex >= 0 && updatedSuggestions[lastSuggestionIndex]) {
        // Make sure the first index exists
        updatedSuggestions[lastSuggestionIndex].active = true;

        this.scrollToOption(updatedSuggestions[lastSuggestionIndex]);
      }

      return { suggestion: updatedSuggestions, index: 0 };
    } else {
      updatedSuggestions[currentIndex - 1].active = true;

      this.scrollToOption(updatedSuggestions[currentIndex - 1]);

      return { suggestion: updatedSuggestions, index: currentIndex - 1 };
    }
  }

  handleOnClickSuggestion(suggestion) {
    let updatedState = { suggestions: [], refs: {} };

    if (suggestion.email) {
      this.props.onChangeInviteeEmail(suggestion.email);
      updatedState.inviteeEmailHasError = false;

      this._emailClassName = COMPONENT_NOTIFICATION;
    }

    if (suggestion.name) {
      this.props.onChangeInviteeName(suggestion.name);

      this._nameClassName = COMPONENT_NOTIFICATION;
    }

    this.startComponentHasChangedNotificationTimer();

    this.setState(updatedState);
  }
}

function mapStateToProps(state) {
  const { currentUser, isDarkMode, emailToNameIndex, eventFormEmails } = state;

  return {
    currentUser,
    isDarkMode,
    emailToNameIndex,
    eventFormEmails,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(mapStateToProps)(withStore(PersonalizeSlotsSection));
