import React from "react";
import { X, Check } from "react-feather";
import { useSelector } from "react-redux";

import ProfilePictureWithName from "../profilePictureWithName";
import GoogleCalendarService from "../../services/googleCalendarService";
import {
  determineCalendarColor,
  getCalendarFromEmail,
} from "../../lib/calendarFunctions";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { getEnrichedContactProfilePhoto } from "../../lib/enrichedContactFunctions";
import { getDefaultBackgroundColor } from "../../lib/styleFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";

const ProfilePictureWithStatus = ({
  attendee,
  showColorBorder,
}) => {
  const { allCalendars } = useAllCalendars();
  const isDarkMode = useSelector(state => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const temporarySecondaryCalendarColorsIndex = useSelector(state => state.temporarySecondaryCalendarColorsIndex);
  let {
    attendee_event_attending,
    attendee_event_tentative,
    attendee_event_needs_action,
  } = GoogleCalendarService;

  const determineColorOfAttendee = (email) => {
    let color = "#E0E0E0";

    if (showColorBorder) {
      const matchingCalendar = getCalendarFromEmail({
        email,
        allLoggedInUsers,
        allCalendars,
        masterAccount,
      });
      if (matchingCalendar) {
        return determineCalendarColor(matchingCalendar);
      } else {
        return temporarySecondaryCalendarColorsIndex[email] || "#E0E0E0";
      }
    }

    return color;
  };

  const determineResponseIconBorderColor = (attendee) => {
    if (attendee.responseStatus === attendee_event_needs_action) {
      return "transparent";
    }

    return getDefaultBackgroundColor(isDarkMode);
  };

  const setIconAndColor = (response) => {
    const ICON_WEIGHT = { strokeWidth: 4 };

    switch (response) {
      case attendee_event_attending:
        return {
          color: "#CEEAD6",
          icon: <Check color={"#137333"} size="10" style={ICON_WEIGHT} />,
        };
      case attendee_event_tentative:
        return {
          color: "#E8EAED",
          icon: (
            <div
              style={{
                color: "#3C4043",
                fontWeight: 600,
                fontSize: 12,
                marginTop: 2,
              }}
            >
              ?
            </div>
          ),
        };
      case attendee_event_needs_action:
        return { color: "transparent", icon: <></> };
      default:
        return {
          color: "#FAD2CF",
          icon: <X color={"#B31412"} style={ICON_WEIGHT} size="10" />,
        };
    }
  };

  const { color, icon } = setIconAndColor(attendee.responseStatus);
  const email = getObjectEmail(attendee);
  return (
    <div className="h-full">
      <ProfilePictureWithName
        name={email}
        style={{
          borderWidth: "2px",
          borderColor: determineColorOfAttendee(email),
          borderStyle: "solid",
          backgroundColor: "#E0E0E0",
          color: "#959BA0",
          fontWeight: "400",
          height: 30,
          width: 30,
          fontSize: 12,
        }}
        avatarUrl={getEnrichedContactProfilePhoto(attendee)}
        renderFeatherIcon={true}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          bottom: "12px",
          left: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 50,
            width: 13,
            height: 13,
            border: `1px solid ${determineResponseIconBorderColor(attendee)}`,
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export default ProfilePictureWithStatus;
