import React, { useMemo, useEffect, useState, useRef, useCallback } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Fetcher from "../../services/fetcher";
import { constructRequestURL, isErrorResponse } from "../../services/api";
import { useSelector } from "react-redux";
import {
  MONTHLY,
  YEARLY,
  STRIPE_PUBLISHABLE_KEY,
  ERROR_TYPE_PAYMENT,
} from "../../lib/vimcalVariables";
import classNames from "classnames";
import { ArrowRight } from "react-feather";
import {
  handleError,
  sendMessageToSentry,
  isMatchingLoweredCasedString,
} from "../../services/commonUsefulFunctions";
import {
  BLUE_BUTTON,
  DOLLARS_IN_CENTS,
  GENERIC_ERROR_MESSAGE,
  SECOND_IN_MS,
} from "../../services/globalVariables";
import { useHistory } from "react-router-dom";
import appBroadcast from "../../broadcasts/appBroadcast";
import { isAdminsDayDiscount, isWinbackPromotion, isYCDiscount, storeDefaultPaymentMethod } from "../../lib/stateManagementFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import {
  useAnnualUpgradeInvoicesStore,
  useDefaultPaymentMethod,
  usePromotionsStore,
  useHasBillingBeenFetched,
  useStripeUpcomingInvoices,
  useSubscriptionStore,
} from "../../services/stores/finance";
import componentLoader from "../../lib/componentLoader";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackError } from "../tracking";
import { getHumanReadableDollarFromCents, getHasFreeMonth, getNewSubscriptionSubtotal } from "../../lib/stripeFunctions";
import { getInputStringFromEvent } from "../../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isKeyDownTab } from "../../services/keyboardEventFunctions";
import CloseButton from "../closeButton";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import type { PaymentMethod, StripeCardElement, StripeCardElementOptions } from "@stripe/stripe-js";
import type { Elements } from "@stripe/react-stripe-js/dist/react-stripe";
import type { Stripe } from "@stripe/stripe-js";
import { STRIPE_ENDPOINTS } from "../../lib/endpoints";
import { fetcherGet, fetcherPatch, fetcherPost } from "../../services/fetcherFunctions";
import { getUserConnectedAccountToken } from "../../services/maestro/maestroAccessors";
import CustomButtonV2 from "../buttons/customButtonV2";
import LoadingSkeleton from "../loadingSkeleton";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import _ from "underscore";

const importStripeModule = (
  stripePaymentModalRef: React.MutableRefObject<typeof StripePaymentModal | undefined>,
  stripePromiseRef: React.MutableRefObject<Promise<Stripe | null> | null>,
  elementsRef: React.MutableRefObject<typeof Elements | undefined>,
  setHasCompletedImport: StateSetter<boolean>,
  onLoad: () => void = _.noop,
) => {
  Promise.all([
    componentLoader({
      lazyComponent: () =>
        import(
          /* webpackChunkName: 'stripePaymentModal' */
          "./stripePaymentModal"
        ),
      componentName: "stripePaymentModal",
    }),
    componentLoader({
      lazyComponent: () =>
        import(
          /* webpackChunkName: 'stripeJs' */
          "@stripe/stripe-js"
        ),
      componentName: "stripeJs",
    }),
    componentLoader({
      lazyComponent: () =>
        import(
          /* webpackChunkName: 'reactStripe' */
          "@stripe/react-stripe-js"
        ),
      componentName: "reactStripe",
    }),
  ])
    .then((result) => {
      const [{ default: StripePaymentModal }, { loadStripe }, { Elements }] =
        result;

      stripePaymentModalRef.current = StripePaymentModal;
      stripePromiseRef.current = loadStripe(STRIPE_PUBLISHABLE_KEY);
      elementsRef.current = Elements;
      setHasCompletedImport(true);
      onLoad();
    })
    .catch(handleError);
};

export function useStripeModule({ onLoad }: { onLoad?: () => void } = {}) {
  const [hasCompletedImport, setHasCompletedImport] = useState(false);
  const stripePaymentModalRef = useRef<typeof StripePaymentModal>();
  const stripePromiseRef = useRef<Promise<Stripe | null> | null>(null);
  const elementsRef = useRef<typeof Elements>();

  const triggerImport = useCallback(() => {
    if (!hasCompletedImport) {
      importStripeModule(
        stripePaymentModalRef,
        stripePromiseRef,
        elementsRef,
        setHasCompletedImport,
        onLoad,
      );
    } else if (onLoad) {
      onLoad();
    }
  }, [hasCompletedImport, onLoad]);

  useEffect(() => {
    return () => {
      // remove any pointers to avoid memory leaks
      stripePaymentModalRef.current = undefined;
      stripePromiseRef.current = null;
      elementsRef.current = undefined;
    };
  }, []);

  return {
    stripePaymentModalRef,
    stripePromiseRef,
    elementsRef,
    hasCompletedImport,
    importStripeModule: triggerImport,
  };
}

function useOptions(): StripeCardElementOptions {
  const isDarkMode = useSelector(state => state.isDarkMode);

  const fontSize = "14px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: isDarkMode ? "white" : "#424770",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize],
  );

  return options;
}

interface StripePaymentModalProps {
  // TODO: Should this always be required? Or should we assume monthly when not provided?
  plan?: typeof YEARLY | typeof MONTHLY
  setShouldFreezeModal: StateSetter<boolean>
  email?: string | null
  isTeamPlan?: boolean
  referral?: Referral
  onSuccess?: () => void
  hideInstructions?: boolean
  isPersonalOnboarding?: boolean
  onError?: (message: string) => void
  shouldRenderCloseButton?: boolean
  closeModal?: () => void
}

function StripePaymentModal({
  plan,
  setShouldFreezeModal,
  email = null, // this should be null unless it's passed in from the query param ?email=userEmail
  referral,
  onSuccess,
  hideInstructions,
  isPersonalOnboarding,
  onError,
  isTeamPlan = false,
  shouldRenderCloseButton,
  closeModal,
}: StripePaymentModalProps) {
  const needCard = useDefaultPaymentMethod(
    (state) => state.needCard,
  );
  const stripe = useStripe();
  const elements = useElements();
  const cardElementRef = useRef<StripeCardElement | null>(null);
  const options = useOptions();
  const [nameOnCard, setNameOnCard] = useState("");

  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const unappliedPromotions = usePromotionsStore(
    (state) => state.unappliedPromotions,
  );
  const setSubscription = useSubscriptionStore(
    (state) => state.setSubscription,
  );
  const subscription = useSubscriptionStore((state) => state.subscription);

  const setDefaultPaymentMethod = useDefaultPaymentMethod(
    (state) => state.setDefaultPaymentMethod,
  );
  const hasFreeMonth = getHasFreeMonth({ referral, promotions: unappliedPromotions });

  const [stripeError, setStripeError] = useState("");
  const [disableClicks, setDisableClicks] = useState(false);
  const history = useHistory();
  const componentIsMounted = useIsMounted();

  const isMaestroAccount = isUserMaestroUser(masterAccount);
  const hasBillingBeenFetched = useHasBillingBeenFetched(state => state.hasBillingBeenFetched);
  const annualUpgradeNonProratedInvoice = useAnnualUpgradeInvoicesStore(state => state.annualUpgradeNonProratedInvoice);
  const upcomingInvoice = useStripeUpcomingInvoices(state => state.stripeUpcomingInvoices);

  const getEmail = () => {
    return email ?? getUserEmail(currentUser);
  };

  const isAuthorized = () => {
    return !isEmptyObjectOrFalsey(currentUser);
  };

  const onSetError = (errorMessage: string) => {
    if (onError) {
      onError(errorMessage);
    }
    setStripeError(errorMessage);
  };

  useEffect(() => {
    // It should already be pre-fetched by this point, but add this as a sanity check.
    if (!hasBillingBeenFetched) {
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    }
  }, []);

  useEffect(() => {
    setShouldFreezeModal && setShouldFreezeModal(disableClicks);

    return () => {
      setShouldFreezeModal && setShouldFreezeModal(false);
    };
  }, [disableClicks]);

  const savePaymentMethod = async (paymentMethod: PaymentMethod) => {
    const paymentMethodId = paymentMethod?.id;
    const paymentMethodPayloadData = {
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
        yearly: plan === YEARLY,
        email: getEmail(),
        is_team_plan: isTeamPlan,
      }),
    };

    const savePaymentMethodUrl = constructRequestURL(
      isAuthorized()
        ? STRIPE_ENDPOINTS.UPDATE_PAYMENT_METHOD(masterAccount.id)
        : STRIPE_ENDPOINTS.UPDATE_PAYMENT_METHOD_WITHOUT_AUTH(masterAccount.id),
    );
    // Save a (credit card) default payment method to a Stripe customer object associated with a Vimcal master account
    const response = await fetcherPatch<{ default_payment_method: DefaultPaymentMethod }>({
      url: savePaymentMethodUrl,
      payloadData: paymentMethodPayloadData,
      authorizationRequired: isAuthorized(),
      email: getEmail(),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (!isEmptyObjectOrFalsey(response) && !isEmptyObjectOrFalsey(response.default_payment_method)) {
      setDefaultPaymentMethod(response.default_payment_method);
      backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    }
  };

  const syncAccountState = async () => {
    /* Only sync account state if logged in */
    /* If not logged in, gets synced on log in */
    if (isEmptyObjectOrFalsey(currentUser) || !isMatchingLoweredCasedString(currentUser.email, email)) {
      onCompletePayment();
      return;
    }

    const syncAccountStateUrl = constructRequestURL(STRIPE_ENDPOINTS.SYNC_ACCOUNT_STATE(masterAccount.id));

    // always going to be authorized here
    const updatedMasterAccount = await fetcherPost({
      url: syncAccountStateUrl,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    });

    if (isEmptyObjectOrFalsey(updatedMasterAccount)) {
      setDisableClicks(false);
      onSetError(GENERIC_ERROR_MESSAGE);
      sendMessageToSentry("Stripe_error", "empty updated master account");
      trackError({
        category: ERROR_TYPE_PAYMENT,
        errorMessage: "no master account",
        userToken: currentUser.token,
      });
      return;
    }

    appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount);
    fetcherGet({
      url: constructRequestURL(STRIPE_ENDPOINTS.SUBSCRIPTION_DETAILS),
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    })
      .then((response) => {
        if (
          isEmptyObjectOrFalsey(response) ||
          isErrorResponse(response) ||
          !componentIsMounted.current
        ) {
          if (componentIsMounted.current) {
            onSetError(response?.error ?? GENERIC_ERROR_MESSAGE);
            trackError({
              category: ERROR_TYPE_PAYMENT,
              errorMessage: `get subscription error - ${JSON.stringify(response)}`,
              userToken: currentUser.token,
            });
            setDisableClicks(false);
          }
          return;
        }
        const { subscription, default_payment_method } = response;
        if (subscription) {
          setSubscription(subscription);
        }

        if (default_payment_method) {
          setDefaultPaymentMethod(default_payment_method);
          storeDefaultPaymentMethod(default_payment_method);
        }

        setTimeout(() => {
          if (!componentIsMounted.current) {
            return;
          }

          onCompletePayment();
        }, 0.5 * SECOND_IN_MS);
      })
      .catch((error) => {
        handleError(error);
        if (componentIsMounted.current) {
          onSetError(getErrorMessage(error));
          trackError({
            category: ERROR_TYPE_PAYMENT,
            errorMessage: "catch_1",
            userToken: currentUser.token,
          });
          setDisableClicks(false);
        }
        // sendMessageToSentry('Update Stripe Details', "unable to update subscription and default payment method");
      });
  };

  const onCompletePayment = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      history.push("/home?ref=r"); // to make sure we get rid of the stripe webworkers
    }
  };

  const handleAddCard = async (event: React.FormEvent<HTMLFormElement>) => {
    setDisableClicks(true);
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { paymentMethod, error: createPaymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: masterAccount.stripe_email,
          name: nameOnCard,
        },
      });

    if (createPaymentMethodError || !paymentMethod) {
      onSetError(createPaymentMethodError?.message ?? "");
      trackError({
        category: ERROR_TYPE_PAYMENT,
        errorMessage: `error_0 ${createPaymentMethodError?.message} - ${createPaymentMethodError}`,
        userToken: getUserToken(currentUser),
      });
      setDisableClicks(false);
      sendMessageToSentry(
        "Stripe_error",
        `create payment error: ${createPaymentMethodError?.message}`,
      );
    } else {
      await savePaymentMethod(paymentMethod);
      syncAccountState();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setDisableClicks(true);
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { paymentMethod, error: createPaymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: masterAccount.stripe_email,
          name: nameOnCard,
        },
      });

    if (createPaymentMethodError || !paymentMethod) {
      onSetError(createPaymentMethodError?.message ?? "");

      trackError({
        category: ERROR_TYPE_PAYMENT,
        errorMessage: `error_1 ${createPaymentMethodError?.message} - ${createPaymentMethodError}`,
        userToken: getUserToken(currentUser),
      });

      setDisableClicks(false);
      sendMessageToSentry(
        "Stripe_error",
        `create payment error: ${createPaymentMethodError?.message}`,
      );
    } else {
      try {
        // All users are initiated with a monthly subscription account creation.
        // Users that canceled their subscription or churned from the free trial will have no subscription
        // Overdue invoices are charged immediately upon newly attached payment method
        if (isEmptyObjectOrFalsey(subscription)) {
          await savePaymentMethod(paymentMethod);
          await initSubscription();
        } else {
          if (plan === YEARLY && subscription?.status === "active") {
            const paymentMethodId = paymentMethod.id;
            const paymentMethodPayloadData = {
              body: JSON.stringify({
                payment_method_id: paymentMethodId,
                email: getEmail(),
              }),
            };
            const upgradeSubscriptionUrl = constructRequestURL(
              isAuthorized()
                ? STRIPE_ENDPOINTS.UPGRADE_SUBSCRIPTION
                : STRIPE_ENDPOINTS.UPGRADE_SUBSCRIPTION_WITHOUT_AUTH,
            );
            // Void existing open unpaid invoices; attach default payment method, upgrade subscription to annual
            const upgradeResponse = await fetcherPost({
              url: upgradeSubscriptionUrl,
              payloadData: paymentMethodPayloadData,
              authorizationRequired: isAuthorized(),
              email: getEmail(),
              connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
            });

            if (!upgradeResponse?.subscriptionId) {
              setDisableClicks(false);

              trackError({
                category: ERROR_TYPE_PAYMENT,
                errorMessage: "yearly - no subscription id",
                userToken: getUserToken(currentUser),
              });

              onSetError(GENERIC_ERROR_MESSAGE);
              sendMessageToSentry(
                "Stripe_error",
                "empty object response from stripe",
              );
              return;
            }
          } else {
            await savePaymentMethod(paymentMethod);
            await initSubscription();
          }
        }

        syncAccountState();
      } catch (e) {
        handleError(e);
        setDisableClicks(false);

        onSetError(getErrorMessage(e as Error));

        trackError({
          category: ERROR_TYPE_PAYMENT,
          errorMessage: "catch_0",
          userToken: getUserToken(currentUser),
        });
      }
    }
  };

  const initSubscription = async () => {
    let createSubscriptionUrl: string;

    // Call authed route if user is logged in
    if (!isEmptyObjectOrFalsey(currentUser)) {
      createSubscriptionUrl = constructRequestURL("users/subscription");
    } else {
      createSubscriptionUrl = constructRequestURL(
        "users/init_subscription_without_auth",
      );
    }

    // trial should be set to true for new users, otherwise false
    const isTrial = () => {
      if (isEmptyObjectOrFalsey(currentUser) || !isMatchingLoweredCasedString(currentUser.email, email)) {
        return false;
      }

      return true;
    };

    const payloadData = {
      body: JSON.stringify({
        yearly: plan === YEARLY,
        ...(isPersonalOnboarding && { trial: isTrial(), send_invoice: true }),
        email: getEmail(),
        skip_subscription_creation: true,
      }),
    };

    const createSubscriptionResponse = await Fetcher.post(
      createSubscriptionUrl,
      payloadData,
      isAuthorized(), // If we have current user, we want auth to be true
      getEmail(),
    );

    if (
      !componentIsMounted.current ||
      isEmptyObjectOrFalsey(createSubscriptionResponse)
    ) {
      return;
    }
    const { subscription, master_account: masterAccount } =
      createSubscriptionResponse;

    appBroadcast.publish("UPDATE_MASTER_ACCOUNT", masterAccount);

    if (subscription) {
      setSubscription(subscription);
    }
  };

  const subtitle = ((): string => {
    if (hasFreeMonth) {
      if (plan === MONTHLY) {
        return "Your subscription will start after the free month.";
      }
      return "You will be credited one free month.";
    }

    if (isYCDiscount(unappliedPromotions)) {
      if (plan === MONTHLY) {
        return "Enjoy a 50% discount on your subscription for the first six months with our monthly plan.";
      }
      return "Enjoy a 50% discount on your subscription for the first six months with our annual plan.";
    }
    if (isAdminsDayDiscount(unappliedPromotions)) {
      return "Enjoy a 50% discount on your subscription for the first year.";
    }

    return "Your subscription will start now.";
  })();

  const paymentAmount = ((): JSX.Element | string => {
    if (!hasBillingBeenFetched) {
      return <LoadingSkeleton style={{ marginLeft: "0.5ch", height: "1.4em", width: "5ch" }} />;
    }

    if (hasFreeMonth && plan === MONTHLY) {
      // The first month is free for monthly subscribers when an applicable discount/referral is applied.
      return "$0.00";
    }

    let total = getNewSubscriptionSubtotal({
      annualUpgradeNonProratedInvoice,
      isEA: isMaestroAccount,
      plan,
      subscription,
      upcomingInvoice,
    });

    if (hasFreeMonth) {
      // The user effectively gets a 10% discount off the first year when they have a free month discount.
      total *= 0.9;
    } else if (isYCDiscount(unappliedPromotions) || isAdminsDayDiscount(unappliedPromotions)) {
      total *= 0.5;
    } else if (isWinbackPromotion(unappliedPromotions)) {
      total -= 15 * DOLLARS_IN_CENTS;
    }

    return `$${getHumanReadableDollarFromCents(Math.max(total, 0))}`;
  })();

  return (
    <div className="stripe-card-element">
      <form onSubmit={needCard ? handleAddCard : handleSubmit}>
        {hideInstructions ? null : (
          <>
            <div className="font-size-16 font-weight-400 flex items-center justify-between">
              {subtitle}
              {shouldRenderCloseButton && closeModal
                ? <CloseButton onClick={closeModal} />
                : null
              }
            </div>
            <div className="flex flex-row items-center mt-5 font-size-14 secondary-text-color">
              <ArrowRight size={14} className="mr-1" /> Total due now{" "}
              {paymentAmount}
            </div>
            <div className="flex flex-row items-center mt-2 font-size-14 secondary-text-color">
              <ArrowRight size={14} className="mr-1" />
              Subscribing to
              <div className="font-weight-400 ml-1">
                {plan === MONTHLY ? "Monthly" : "Yearly"}
              </div>
            </div>
          </>
        )}

        {hideInstructions ? null : (
          <div className="font-weight-400 mt-5 font-size-14">Name On Card</div>
        )}
        <input
          className={classNames(
            "font-size-14-important",
            "stripe-card-element font-weight-300 w-full",
            hideInstructions ? "margin-top-20px-important" : "",
          )}
          autoFocus={true}
          value={nameOnCard}
          placeholder={"First and last name"}
          onChange={(e) => setNameOnCard(getInputStringFromEvent(e))}
          onKeyDown={(e) => {
            if (isKeyDownTab(e) && cardElementRef.current) {
              cardElementRef.current.focus();
            }
          }}
          tabIndex={0}
        />
        {hideInstructions ? null : <div className="font-weight-400 font-size-14">Card</div>}
        <CardElement options={options} onReady={(el) => (cardElementRef.current = el)} />
        {stripeError && <div className="warning-color default-font-size">{stripeError}</div>}
        <CustomButtonV2
          className="w-full"
          label="Submit"
          type="submit"
          buttonType={BLUE_BUTTON}
          disabled={!stripe || disableClicks || !hasBillingBeenFetched}
          shouldRenderSpinner={disableClicks}
        />
      </form>
    </div>
  );
}

function getErrorMessage(error: Error) {
  if (isEmptyObjectOrFalsey(error)) {
    return GENERIC_ERROR_MESSAGE;
  }

  if (error.message) {
    return error.message;
  }

  if (error.toString) {
    return error.toString();
  }

  return GENERIC_ERROR_MESSAGE;
}


export default StripePaymentModal;
