import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getBlockedCalendarList } from "../../lib/availabilityFunctions";
import {
  determineCalendarColor,
  getCalendarFromUserCalendarID,
  getCalendarName,
} from "../../lib/calendarFunctions";
import {
  DARK_MODE_CELL_BORDER,
  DARK_MODE_MODAL_BACKGROUND_COLOR,
  DARK_MODE_MONTHLY_CALENDAR_AGENDA_BACKGROUND_COLOR,
} from "../../services/globalVariables";
import {
  useAllCalendars,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { getCalendarUserCalendarID } from "../../services/calendarAccessors";
import { Edit2 } from "react-feather";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";

const BLOCKED_CALENDAR_LIMIT = 5;
export default function BlockedCalendarSection({
  onSelectBlockingCalendars,
  containerClassNameOverride,
  titleClassNameOverride,
  blockedCalendars,
  blockedCalendarsID,
  isInModal,
  inputPreviewContainerClassNameOverride,
  hideEdit,
  skipHover,
  isDisabledCheckForConflict,
  showPencilOnHover
}) {
  // userCalendarIDs should be an array of calendar IDs
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const [allBlockedCalendars] = useState(
    getBlockedCalendarList(blockedCalendars)
  );
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const [showCalendarPreviewModal, setOpenCalendarPreviewModal] =
    useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const onClickOpenCalendarList = () => {
    if (isDisabledCheckForConflict) {
      return;
    }
    setOpenCalendarPreviewModal(false);
    if (onSelectBlockingCalendars) {
      onSelectBlockingCalendars();
    }
  };

  const renderCalendarColorsAndCount = () => {
    if (isEmptyArray(blockedCalendarsID)) {
      return (
        <div
          className={classNames("default-font-size select-none ", isDisabledCheckForConflict ? "opacity-60" : "cursor-pointer")}
          onClick={onClickOpenCalendarList}
        >
          None
        </div>
      );
    }
    return (
      <div
        onClick={onClickOpenCalendarList}
        className={classNames(
          "select-none",
          "flex items-center",
          isDisabledCheckForConflict ? "opacity-60" : "cursor-pointer"
        )}
        onMouseEnter={() => {
          if (!skipHover) {
            setOpenCalendarPreviewModal(true);
          }
        }}
        onMouseLeave={() => {
          if (!skipHover) {
            setOpenCalendarPreviewModal(false);
          }
        }}
      >
        {blockedCalendarsID.map((userCalendarID, index) => {
          if (index > BLOCKED_CALENDAR_LIMIT) {
            return null;
          }

          const matchingCalendar = getMatchingCalendar({
            userCalendarID,
            allCalendars,
            allBlockedCalendars,
          });
          if (isEmptyObjectOrFalsey(matchingCalendar)) {
            return null;
          }

          const color = determineCalendarColor(matchingCalendar);
          return (
            <div
              key={`blocked-calendar-${userCalendarID}-${index}`}
              style={{
                background: color,
                borderColor: getBackgroundColor({ isDarkMode, isInModal }),
              }}
              className={classNames(
                "w-4 h-4 rounded-full",
                index === 0 ? "" : "-ml-1.5",
                "border-2 border-solid"
              )}
            ></div>
          );
        })}
        {renderMoreCalendars(blockedCalendarsID)}

        <div className="default-font-size ml-1.5">
          {`(${blockedCalendarsID.length})`}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "relative",
        containerClassNameOverride ?? "flex mt-2.5 items-start"
      )}
    >
      {renderPreviewCalendarList({
        showCalendarPreviewModal,
        blockedCalendarsID,
        allBlockedCalendars,
        allCalendars,
        emailToNameIndex,
        isDarkMode,
        inputPreviewContainerClassNameOverride,
        currentUser,
        masterAccount,
      })}

      <div
        className={classNames(
          titleClassNameOverride ?? "availability-detail-section-text",
          isDisabledCheckForConflict ? "opacity-60" : ""
        )}
      >
        Check for conflict
      </div>

      {hideEdit ? (
        renderCalendarColorsAndCount()
      ) : (
        <div 
          onClick={onClickOpenCalendarList}
          className={classNames(showPencilOnHover ? "hoverable-visibility-parent" : "", "flex items-center")}
        >
          {renderCalendarColorsAndCount()}
          {showPencilOnHover ? <Edit2 size={12} className="hoverable-visibility-child ml-3 cursor-pointer" /> : null}
        </div>
      )}
    </div>
  );
}

function renderMoreCalendars(blockedCalendarsIDs) {
  if (blockedCalendarsIDs.length <= BLOCKED_CALENDAR_LIMIT) {
    return null;
  }

  return <div className="default-font-size ml-1">{"..."}</div>;
}

function getBackgroundColor({ isInModal, isDarkMode }) {
  if (isInModal) {
    return isDarkMode ? DARK_MODE_MODAL_BACKGROUND_COLOR : "white";
  } else {
    return isDarkMode
      ? DARK_MODE_MONTHLY_CALENDAR_AGENDA_BACKGROUND_COLOR
      : "white";
  }
}

function getMatchingCalendar({
  userCalendarID,
  allCalendars,
  allBlockedCalendars,
}) {
  const matchingCalendarFromBlockedCalendars = allBlockedCalendars.find(
    (c) => getCalendarUserCalendarID(c) === userCalendarID
  );
  if (matchingCalendarFromBlockedCalendars) {
    return matchingCalendarFromBlockedCalendars;
  }
  if (allCalendars[userCalendarID]) {
    return allCalendars[userCalendarID];
  }
  return getCalendarFromUserCalendarID({
    userCalendarID,
    allCalendars,
  });
}

function renderPreviewCalendarList({
  showCalendarPreviewModal,
  blockedCalendarsID,
  allBlockedCalendars,
  allCalendars,
  emailToNameIndex,
  isDarkMode,
  inputPreviewContainerClassNameOverride,
  currentUser,
  masterAccount,
}) {
  if (!showCalendarPreviewModal) {
    return null;
  }
  if (isEmptyArrayOrFalsey(blockedCalendarsID)) {
    return null;
  }

  const renderShowMore = () => {
    if (blockedCalendarsID.length <= BLOCKED_CALENDAR_LIMIT) {
      return null;
    }

    return (
      <div className="default-font-size margin-left-18px">
        {`+ ${blockedCalendarsID.length - BLOCKED_CALENDAR_LIMIT} more`}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "absolute z-10 -top-3",
        "w-48",
        isDarkMode ? "" : "box-shadow-6",
        "p-3",
        "rounded-md",
        "soft-border",
        "gap-y-1",
        "flex flex-col",
        inputPreviewContainerClassNameOverride ?? ""
      )}
      style={{backgroundColor: isDarkMode ? DARK_MODE_CELL_BORDER : "white"}}
    >
      {blockedCalendarsID.map((userCalendarID, index) => {
        if (index > BLOCKED_CALENDAR_LIMIT) {
          return null;
        }

        const matchingCalendar = getMatchingCalendar({
          userCalendarID,
          allCalendars,
          allBlockedCalendars,
        });
        if (isEmptyObjectOrFalsey(matchingCalendar)) {
          return null;
        }

        const color = determineCalendarColor(matchingCalendar);
        const name = getCalendarName({
          calendar: matchingCalendar,
          emailToNameIndex,
          currentUser,
          masterAccount,
        });
        return (
          <div
            key={`blocked-calendar-color-and-name-${userCalendarID}-${index}`}
            className={"flex items-center"}
          >
            <div
              style={{ background: color }}
              className={classNames("w-3 h-3 rounded-full mr-2")}
            ></div>
            <div
              className={classNames("default-font-size", "truncate-text w-5/6")}
            >
              {name}
            </div>
          </div>
        );
      })}

      {renderShowMore()}
    </div>
  );
}
