import { useReducer } from "react";

/**
 * Call the returned function to force a re-render of the component.
 *
 * Note: This is generally not the best way to re-render a component. Use sparingly.
 * @see https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
 */
export default function useForceUpdate() {
  const [updateCount, forceUpdate] = useReducer((x: number) => x + 1, 0);

  return { updateCount, forceUpdate };
}
