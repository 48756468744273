import React, { useState } from "react";
import { User } from "react-feather";

function ProfilePictureWithName(props) {
  const [useFallback, setUseFallback] = useState(false);

  return (
    <div
      style={Object.assign(
        {
          borderRadius: "50%",
          backgroundColor: "#01579B",
          color: "white",
          width: 31,
          height: 31,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        props.style
      )}
      className={props.className || ""}
    >
      {renderIcon({ props, setUseFallback, useFallback })}
    </div>
  );
}

function renderIcon({ props, setUseFallback, useFallback }) {
  // Uncomment to test an invalid url
  // const temp = `https://invalid.url:invalid/super-invalid`;

  // if (temp && !useFallback) {
  if (props.avatarUrl && !useFallback) {
    return (
      <img
        src={props.avatarUrl}
        alt={`${props.name}-profile-icon`}
        onError={() => setUseFallback(true)}
        style={{
          borderRadius: "50%",
          width: 31,
          height: 31,
        }}
      />
    );
  }

  if (props.renderFeatherIcon || useFallback) {
    const ICON_SIZE = 22; 
    const STROKE_WIDTH = 1;

    return <User size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />;
  }

  return props.name && props.name.length >= 1
    ? props.name[0].toUpperCase()
    : "";
}

export default ProfilePictureWithName;
