import React, { useEffect, useState } from "react";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { useHideRightHandSidebar } from "../services/stores/appFunctionality";
import FocusModeCountdown from "./focusModeCountdown";

export default function FloatingFocusModeCountdownTimer({
  shouldHide = false,
}) {
  const shouldHideRightHandSide = useHideRightHandSidebar(
    (state) => state.shouldHideRightHandSide
  );
  const [countdownTimer, setCountdownTimer] = useState("");

  useEffect(() => {
    focusModeBroadcast.subscribe(
      "SET_FLOATING_FOCUS_MODE_COUNTDOWN_TIMER",
      setCountdownTimer
    );

    return () => {
      focusModeBroadcast.unsubscribe(
        "SET_FLOATING_FOCUS_MODE_COUNTDOWN_TIMER",
      );
    };
  }, []);

  if (shouldHide || !shouldHideRightHandSide || !countdownTimer) {
    return null;
  }

  return (
    <div
      className={"fixed bottom-0 right-0 z-50"}
      onClick={() => layoutBroadcast.publish("TOGGLE_FOCUS_MODE", true)}
    >
      <FocusModeCountdown
        focusModeCountDown={countdownTimer}
        inputClassName="large-blur hover-upcoming-event-background-color"
        hideBorder={true}
      />
    </div>
  );
}
