import React from "react";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import broadcast from "../../broadcasts/broadcast";
import ProfilePictureThroughURL from "../profilePictureThroughURL";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { getUserEmail, getUserProfilePhotoUrl } from "../../lib/userFunctions";
import { useSelector } from "react-redux";
import { Check, X } from "react-feather";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import classNames from "classnames";
import { isSameEmail } from "../../lib/stringFunctions";

export default function PerAccountSection({ onClose }) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const logoutOfAccount = ({ account, e }) => {
    backendBroadcasts.publish("LOGOUT_OF_SINGLE_ACCOUNT", { user: account, e });
  };

  if (isEmptyArray(allLoggedInUsers)) {
    return null;
  }

  const renderXCloseAccount = (account) => {
    return (
      <div
        className={classNames(
          "reveal-on-hover",
          "mr-3",
        )}
      >
        {allLoggedInUsers.length === 1 ? null : (
          <div
            className="flex items-center justify-center"
            onClick={(e) => {
              onClose();
              logoutOfAccount({ account, e });
            }}
          >
            <X className="hoverable-secondary-text-color" size={16} />
          </div>
        )}
      </div>
    );
  };

  return allLoggedInUsers.map((account, index) => {
    return (
      <div
        className={classNames(
          "account-email-hover", // hover
          "flex items-center cursor-pointer",
          "rounded-md",
          "py-2",
          "justify-between",
          "hoverable-container",
        )}
        style={{
          marginLeft: "-12px",
          marginRight: "-12px",
          paddingLeft: "12px",
        }}
        key={`account_${getUserEmail(account)}`}
        onClick={() => {
          onClose();
          broadcast.publish("SWITCH_ACCOUNTS", { account });
        }}
      >
        <div className="flex items-center gap-2">
          <ProfilePictureThroughURL
            avatarUrl={getUserProfilePhotoUrl({user: account, masterAccount})}
            email={getUserEmail(account)}
            isSmall={true}
          />
          <div className="truncate-text max-width-200px font-weight-300 default-font-size select-none">
            {getUserEmail(account)}
          </div>
          {isSameEmail(getUserEmail(account), getUserEmail(currentUser)) ? (
            <Check
              className="non-clickable-icon-modal mr-3"
              size="14"
              strokeWidth={3}
            />
          ) : (
            null
          )}
        </div>

        <div className={classNames("default-font-size items-center flex")}>
          {renderXCloseAccount(account)}
        </div>
      </div>
    );
  });
}
