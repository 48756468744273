import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Fetcher from "../fetcher";
import { constructRequestURL } from "../api";
import { handleError } from "../commonUsefulFunctions";
import { debounce } from "underscore";
import { getUserName } from "../../lib/userFunctions";
import { useMasterAccount } from "../stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../typeGuards";

const checkUsername = async (usernameCandidate, userEmail) => {
  if (!usernameCandidate || !userEmail) {
    return false;
  }

  try {
    const url = constructRequestURL(`username/${usernameCandidate}`, true);
    const response = await Fetcher.get(url, {}, true, userEmail);
    if (isEmptyObjectOrFalsey(response)) {
      handleError(Error("check username returned empty response"));
      return false;
    }

    return response?.username_exists === false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export default function useUsernameCheck({ usernameCandidate, selectedUser }) {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const { userName: initialUserName } = getUserName({
    masterAccount,
    user: selectedUser,
  });
  const checkUsernameDebounced = useRef(
    debounce(async (username, userEmail) => {
      const isAvailable = await checkUsername(username, userEmail);
      setIsFetching(false);
      setIsAvailable(isAvailable);
    }, 1000)
  ).current;

  useEffect(() => {
    if (initialUserName === usernameCandidate) {
      return;
    }
    setIsFetching(true);
    const checkUsernameAsync = async () => {
      setIsAvailable(false);
      checkUsernameDebounced(usernameCandidate, currentUser.email);
    };
    checkUsernameAsync();
  }, [usernameCandidate]);

  return [isAvailable, isFetching];
}
