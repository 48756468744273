import React, { Component } from "react";
import SaveButton from "../components/saveButton";
import StyleConstants, { SAVE_BUTTON_HEIGHT } from "../services/globalVariables";
import CustomSelect from "../components/select";
import MonthlyCalendar from "./monthlyCalendar";
import GoogleCalendarService from "../services/googleCalendarService";
import {
  NumberToName,
  WeekOfMonth,
  ConvertStringToWeekDayConstant,
  ConvertDayOfWeekIntegerIntoWeekdayConstant,
} from "../services/commonUsefulFunctions";
import { RRule } from "rrule";
import { addMonths, format, addDays, parseISO } from "date-fns";
import classNames from "classnames";
import { connect } from "react-redux";
import { getReactSelectBaseStyle } from "./select/styles";
import { createDateInUTC } from "../lib/dateFunctions";
import RadioButton from "./radioButton";
import { pluralize } from "../lib/stringFunctions";
import { NumericInput } from "./numericInput";

// Strings from google
let {
  daysString,
  weeksString,
  monthsString,
  yearsString,
  after,
  never,
  on,
  onDayX,
  nthDayOfWeek,
} = GoogleCalendarService;

// Strings that are used in the UI
const DAYS_OF_THE_WEEK = [
  ["S", 6],
  ["M", 0],
  ["T", 1],
  ["W", 2],
  ["T", 3],
  ["F", 4],
  ["S", 5],
];

let endOptions = [never, on, after];

let freqOption = [
  { value: daysString, label: daysString },
  { value: weeksString, label: weeksString },
  { value: monthsString, label: monthsString },
  { value: yearsString, label: yearsString },
];

class CustomRepeatOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: props.recurrence.interval,
      freq: props.recurrence.freq,
      repeatDayOfWeek:
        props.recurrence.freq.label === weeksString
          ? props.recurrence.repeatInfoForMonthorWeek
          : [props.date.getDay()],
      repeatMonthly: this.createMonthlyRule(props.date),
      endOption: props.recurrence.endOnInformation[0],
      shouldDisplayDateModal: false,
      byDate:
        props.recurrence.endOnInformation[0] === on
          ? props.recurrence.endOnInformation[1]
          : addMonths(props.date, 3),
      count:
        props.recurrence.endOnInformation[0] === after
          ? props.recurrence.endOnInformation[1]
          : 1,
    };

    this.onChange = this.onChange.bind(this);
    this.setEndsOnDate = this.setEndsOnDate.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.listMonthlyRepeatOptions = this.listMonthlyRepeatOptions.bind(this);
    this.createMonthlyRule = this.createMonthlyRule.bind(this);
    this.onChangeDays = this.onChangeDays.bind(this);
    this.onChangeInterval = this.onChangeInterval.bind(this);
    this.onChangeCount = this.onChangeCount.bind(this);
  }

  render() {
    return (
      <div className="select-custom-repeat-option-container">
        {this.state.shouldDisplayDateModal
          ? this.renderDateModal()
          : this.renderCustomRepeatPage()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderDateModal() {
    return (
      <MonthlyCalendar
        modalCalendarSelectedDay={new Date(this.state.byDate)}
        onNavigateToDate={this.setEndsOnDate}
        noSaveButton={true}
      />
    );
  }

  renderCustomRepeatPage() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="event-form-section-header mr-2">Repeat every</div>

          <NumericInput
            min={1}
            value={this.state.interval}
            onChange={this.onChangeInterval}
          />

          <div className="ml-2.5 select-default-font-size">
            <CustomSelect
              className={classNames(
                this.props.isDarkMode ? "dark-mode-select" : ""
              )}
              value={this.state.freq}
              onChange={(option) => this.setFreqToMonthly(option)}
              options={freqOption}
              classNamePrefix="dark-mode-modal"
              overrideStyles={getReactSelectBaseStyle({
                isDarkMode: this.props.isDarkMode,
                controlWidth: "100px",
              })}
            />
          </div>
        </div>

        {this.state.freq.label === weeksString &&
          this.renderRepeatOnDaysOfWeek()}

        {this.state.freq.label === monthsString
          ? this.renderRepeatMonthlyOptions()
          : null}

        <div className="event-form-section-header mt-5">Ends</div>

        <div>{this.renderEndOptions()}</div>

        <SaveButton
          width={"100%"}
          height={SAVE_BUTTON_HEIGHT} 
          marginTop={50}
          onClick={() =>
            this.props.setRecurring({
              recurrence: this.createRepeatRecurrenceObject(),
              rRule: this.createRRule(),
            })
          }
        />
      </div>
    );
  }

  renderRepeatMonthlyOptions() {
    return (
      <div style={{ marginTop: 15 }}>
        <CustomSelect
          className={classNames(
            "monthly-repeat-select",
            this.props.isDarkMode ? "dark-mode-select" : ""
          )}
          value={this.state.repeatMonthly}
          onChange={(option) => this.onChange("repeatMonthly", option)}
          options={this.listMonthlyRepeatOptions()}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode: this.props.isDarkMode,
            controlWidth: "260px",
          })}
          classNamePrefix="dark-mode-modal"
        />
      </div>
    );
  }

  renderRepeatOnDaysOfWeek() {
    return (
      <div>
        <div className="event-form-section-header mt-5">Repeat on</div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {this.renderDaysOfWeek()}
        </div>
      </div>
    );
  }

  renderDaysOfWeek() {
    return DAYS_OF_THE_WEEK.map((day, index) => {
      return (
        <div
          onClick={() => this.onChangeDays(day[1])}
          style={{
            backgroundColor: this.isSelectedDayOfWeek(day[1])
              ? StyleConstants.blueColor
              : StyleConstants.lightGray,
            color: this.isSelectedDayOfWeek(day[1])
              ? "white"
              : "rgb(149, 155, 160)",
            cursor: "pointer",
            borderRadius: 100,
            width: 28,
            height: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
          key={`days_of_week_${day[0]}_${index}`}
        >
          {day[0]}
        </div>
      );
    });
  }

  renderEndOptions() {
    return endOptions.map((option, index) => {
      if (option === after && this.props.isOutlookCalendar) {
        // outlook does not support after x counts
        return null;
      }

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
          key={`days_of_week_end_${index}`}
          onClick={() => this.onChange("endOption", option)}
        >
          <RadioButton isSelected={this.state.endOption === option} />
          <div className="ml-2.5 cursor-pointer event-form-default-font-size select-none">
            {option}
          </div>

          {option === on && this.renderStopRepeatOnDate()}

          {option === after && this.renderRepeatUntilAfterXOccurencesInput()}
        </div>
      );
    });
  }

  renderStopRepeatOnDate() {
    return (
      <div
        style={{
          marginLeft: 68,
          backgroundColor: StyleConstants.lightGray,
          padding: 10,
          color:
            this.state.endOption === on
              ? StyleConstants.darkGray
              : StyleConstants.mediumGray,
          cursor: "pointer",
        }}
        className="event-form-default-font-size rounded-md"
        onClick={() => this.toggleState("shouldDisplayDateModal")}
      >
        {format(this.state.byDate, "PPP")}
      </div>
    );
  }

  renderRepeatUntilAfterXOccurencesInput() {
    return (
      <div
        style={{
          marginLeft: 54,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <NumericInput
          min={1}
          value={this.state.count}
          onChange={this.onChangeCount}
          disabled={this.state.endOption !== after}
        />

        <div
          className={classNames(
            "select-none event-form-default-font-size ml-2.5",
            this.state.endOption === after
              ? "color-default-text-color"
              : "secondary-text-color"
          )}
        >
          {pluralize(this.state.count, "occurrence")}
        </div>
      </div>
    );
  }

  //================
  // EVENT HANDLERS
  //================

  onChange(key, input) {
    this.setState({
      [key]: input,
    });
  }

  onChangeInterval(interval) {
    this.setState({
      interval: interval,
    });
  }

  onChangeCount(count) {
    this.setState({
      count: count,
    });
  }

  onChangeDays(day) {
    let selectedDays = this.state.repeatDayOfWeek || [];

    if (selectedDays.includes(day)) {
      if (selectedDays.length > 1) {
        // Can not all be unselected
        selectedDays = selectedDays.filter((d) => d !== day);
      }
    } else {
      selectedDays = selectedDays.concat(day);
    }

    this.setState({ repeatDayOfWeek: selectedDays });
  }

  setFreqToMonthly(input) {
    this.setState({
      repeatMonthly: this.listMonthlyRepeatOptions()[0],
      freq: input,
    });
  }

  setEndsOnDate(option) {
    this.setState({
      byDate: option,
    });

    this.toggleState("shouldDisplayDateModal");
  }

  //=================
  // PRIVATE METHODS
  //=================

  createMonthlyRule(date) {
    if (this.props.recurrence.freq.label === monthsString) {
      if (
        this.props.recurrence.repeatInfoForMonthorWeek.bynweekday ||
        this.props.recurrence.repeatInfoForMonthorWeek.byweekday
      ) {
        return this.listMonthlyRepeatOptions(date)[1];
      } else {
        return this.listMonthlyRepeatOptions(date)[0];
      }
    } else {
      return null;
    }
  }

  createRRule() {
    let input = this.createRepeatRecurrenceObject();
    let date = this.props.date;

    // converting it to the constants in RRULE
    let freq = ConvertStringToWeekDayConstant(input.freq.value);
    let interval = input.interval;

    let rulesObject = { freq: freq, interval: interval };

    if (input.freq.value === weeksString) {
      // -1 so our weeks align with rrule since rrule starts week on a monday and Moment starts on Sundays

      rulesObject.byweekday = input.repeatInfoForMonthorWeek;
    } else if (input.freq.value === monthsString) {
      if (input.repeatInfoForMonthorWeek.value === onDayX) {
        // Get the day of the month and turn it into an integer
        rulesObject.bymonthday = parseInt(format(date, "d"));
      } else {
        // This produces monthly on the xth Monday, Tuesday of the month
        let byWeekDay = date.getDay() === 0 ? 6 : date.getDay() - 1;

        rulesObject.byweekday = ConvertDayOfWeekIntegerIntoWeekdayConstant(
          byWeekDay,
          WeekOfMonth(date)
        );
      }
    }

    if (input.endOnInformation[0] === on) {
      const inputDate = parseISO(input.endOnInformation[1]);
      // create the date in utc time
      const untilDateUTC = createDateInUTC(inputDate);
      rulesObject.until = untilDateUTC;
    } else if (input.endOnInformation[0] === after) {
      rulesObject.count = input.endOnInformation[1];
    }

    return new RRule(rulesObject);
  }

  createRepeatRecurrenceObject() {
    return {
      interval: this.state.interval,
      freq: this.state.freq,
      repeatInfoForMonthorWeek: this.determineRepeatEveryResult(),
      endOnInformation: this.determineEndResult(),
    };
  }

  listMonthlyRepeatOptions(date = this.props.date) {
    let monthDay = "Monthly on day " + format(date, "d");
    let numberOfDayInWeek =
      "Monthly on the " +
      NumberToName(WeekOfMonth(date)) +
      " " +
      format(date, "EEEE");

    let list = [
      { value: onDayX, label: monthDay },
      { value: nthDayOfWeek, label: numberOfDayInWeek },
    ];

    return list;
  }

  determineRepeatEveryResult() {
    if (this.state.freq.label === weeksString) {
      return this.state.repeatDayOfWeek;
    } else {
      return this.state.repeatMonthly;
    }
  }

  determineEndResult() {
    const { isOutlookCalendar } = this.props;
    const { byDate, count, endOption } = this.state;

    switch (endOption) {
      case on:
        const endDate = isOutlookCalendar ? byDate : addDays(byDate, 1);
        return [on, endDate.toISOString()];
      case after:
        return [after, count];
      default:
        return [never, null];
    }
  }

  toggleState(keyName) {
    this.setState((prevState, props) => {
      return { [keyName]: !prevState[keyName] };
    });
  }

  isSelectedDayOfWeek(day) {
    return (
      this.state.repeatDayOfWeek && this.state.repeatDayOfWeek.includes(day)
    );
  }
}

function mapStateToProps(state) {
  let { isDarkMode } = state;

  return {
    isDarkMode,
  };
}

export default connect(mapStateToProps, null)(CustomRepeatOptions);
