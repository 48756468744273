export function getChronoText(chrono) {
  return chrono?.text;
}

// this is from the parser that picks up "11/14-11/16"
export function getChronoKnownEndFromStart(chrono) {
  return chrono?.[0]?.start?.knownValues?.knownEnd;
}

export const CHRONO_KEYS = {
  MONTH: "month",
  DAY: "day",
  YEAR: "year",
  WEEKDAY: "weekday",
  HOUR: "hour",
  MINUTE: "minute",
};

// month needs to go first otherwise if set date like 31 on feb, it's going to be incorrect.
export function sortChronoKeys(keys) {
  if (keys.includes(CHRONO_KEYS.MONTH)) {
    return [CHRONO_KEYS.MONTH, ...keys.filter((key) => key !== CHRONO_KEYS.MONTH)];
  }
  return keys;
}
