import React from "react";

const DEFAULT_BLUE_COLOR = "#74A7FF";
const Arrow = ({color}) => {
	const arrowColor = color ?? DEFAULT_BLUE_COLOR;
	const arrowStyle = {
    display: 'inline-block',
    width: '0',
    height: '0',
    borderLeft: '12px solid transparent',
    borderRight: '12px solid transparent',
    borderTop: `12px solid ${arrowColor}`,
    transform: 'rotate(-90deg)'
  };

  const arrowStemStyle = {
    display: "inline-block",
    width: "50px", // Adjust the length of the stem here
    height: "2px", // Match the height with the arrowhead's base
    backgroundColor: arrowColor,
		marginRight: "-6px"
  };

  return (
    <div className="flex items-center justify-center">
      <div style={arrowStemStyle} />
      <div style={arrowStyle} />
    </div>
  );
};

export default Arrow;
