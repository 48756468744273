import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import SlotsAnimationJSONLight from "./slotsAnimationLight.json";
import SlotsAnimationJSONDark from "./slotsAnimationDark.json";
import classNames from "classnames";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";
import SaveButton from "../../saveButton";
import { KEYCODE_ENTER, KEYCODE_ESCAPE } from "../../../services/commonUsefulFunctions";

// https://lottiereact.com

export default function SlotsAnimation({ onClose }) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const renderOverlay = () => {
    return (
      <div
        className={classNames("layout-over-lay", "z-10", "small-blur")}
        style={{backgroundColor: isDarkMode ? "rgba(3, 3, 3, 0.5)" : "rgba(3, 3, 3, 0.1)"}}
        onClick={() => onClose ? onClose() : onClickGotIt()}
      ></div>
    );
  };

  const onKeyDown = (e) => {
    const keyCode = e.keyCode;
  
    if (keyCode === KEYCODE_ENTER || keyCode === KEYCODE_ESCAPE) {
      onClose ? onClose() : onClickGotIt()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const renderSaveButton = () => {
    const SAVE_BUTTON_HEIGHT = "36px";
    const SAVE_BUTTON_WIDTH = "180px";

    return (
      <SaveButton
        className="event-form-save-button"
        style={{
          height: SAVE_BUTTON_HEIGHT,
          width: SAVE_BUTTON_WIDTH,
          marginBottom: "32px",
          marginTop: "24px"
        }}
        onClick={() => onClose ? onClose() : onClickGotIt()}
        shortcut={"Enter"}
        buttonText={"Got it"}
      />
    );
  };

  const {
    containerWidth,
    containerLeftPosition
  } = getSlotsAnimationContainerStyle();
  return (
    <div>
      {renderOverlay()}
      <div
        className={classNames(
          "z-50 fixed flex-col items-center rounded-xl",
          isDarkMode ? "" : "box-shadow-1"
        )}
        style={{
          width: containerWidth,
          top: "30%",
          left: containerLeftPosition,
          backgroundColor: isDarkMode ? "#25282B" : "white",
        }}
      >
        <Lottie
          animationData={
            isDarkMode ? SlotsAnimationJSONDark : SlotsAnimationJSONLight
          }
          loop={true}
        />

        <div
          className={classNames("flex flex-col items-center w-full pt-6 rounded-b-xl", isDarkMode ? "" : "bg-gray-50")}
          style={{ backgroundColor: isDarkMode ? "#1C1D26" : undefined }}
        >
          <div className="font-size-16 font-weight-400">
            Drag to create Slots
          </div>

          {renderSaveButton()}
        </div>
      </div>
    </div>
  );
}

function onClickGotIt() {
  layoutBroadcast.publish("TOGGLE_OFF_SLOTS_ANIMATION");
};

function getSlotsAnimationContainerStyle() {
  if (!window) {
    return {containerWidth : "40%", containerLeftPosition: "30%"}; // 50 - width/2
  }

  if (window.innerWidth < 600) {
    return {containerWidth : "80%", containerLeftPosition: "10%"};
  } else if (window.innerWidth < 1000) {
    return {containerWidth : "60%", containerLeftPosition: "20%"};
  } else if (window.innerWidth < 1200) {
    return {containerWidth : "50%", containerLeftPosition: "25%"};
  }

  return {containerWidth : "600px", containerLeftPosition: "calc(50% - 300px)"};
}
