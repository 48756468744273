import React from "react";
import { useSelector } from "react-redux";
import AILogo from "../icons/aiLogo";
import { FIND_TIME_CAPSULE_ID } from "../../services/elementIDVariables";
import { getDefaultFontColor } from "../../lib/styleFunctions";
import PillButton from "../buttons/pillButton";

export default function FindTimeCapsule({ id, ...props }: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children">) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <PillButton id={id ?? FIND_TIME_CAPSULE_ID} {...props}>
      <AILogo
        fillColor={getDefaultFontColor(isDarkMode)}
      />
      <div className="ml-2 font-weight-300">Find times</div>
    </PillButton>
  );
}
