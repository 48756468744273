import React, { useMemo, useState } from "react";
import { X } from "react-feather";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import { useTeamPlan } from "../../../services/stores/userData";
import { isEmptyArrayOrFalsey } from "../../../services/typeGuards";
import CustomButtonV2 from "../../buttons/customButtonV2";
import ColoredLine from "../../line";
import RemoveMemberModal from "../../teamPlans/removeMemberModal";
import { isMemberOwner } from "../../teamPlans/sharedFunctions";

interface EditSeatsProps {
  onRequestClose: () => void
}

export default function EditSeats({ onRequestClose }: EditSeatsProps) {
  const teamPlan = useTeamPlan(state => state.teamPlan);

  const { vimcalUsers, vimcalEAUsers } = useMemo(() => {
    const vimcalUsers: TeamPlanMember[] = [];
    const vimcalEAUsers: TeamPlanMember[] = [];

    [...teamPlan.active_users, ...teamPlan.pending_users].forEach(member => {
      if (member.scheduling_for_others) {
        vimcalEAUsers.push(member);
      } else {
        vimcalUsers.push(member);
      }
    });

    return { vimcalUsers, vimcalEAUsers };
  }, [teamPlan]);

  return (
    <div className="trial-over-background-gradient p-8">
      <div className="width-500px">
        <div className="text-3xl font-normal text-center text-white">
          Edit your plan
        </div>
        <div className="dark-mode-scroll-bar overflow-auto" style={{ maxHeight: "60vh" }}>
          <div className="width-500px mx-auto default-font-size font-normal text-white pr-2">
            <SeatSection members={vimcalUsers} title="Vimcal" />
            <SeatSection members={vimcalEAUsers} title="Vimcal EA" />
          </div>
        </div>
        <div className="width-500px mx-auto">
          <ColoredLine inputClassName="my-5" style={{ backgroundColor: "rgba(255, 255, 255, 0.16)"}} />
        </div>
        <CustomButtonV2
          className="width-500px mx-auto"
          label="Done"
          onClick={onRequestClose}
          buttonType={BLUE_BUTTON}
        />
      </div>
    </div>
  );
}

interface SeatSectionProps {
  members: TeamPlanMember[]
  title: string
}

function SeatSection({ members, title }: SeatSectionProps) {
  const [memberToRemove, setMemberToRemove] = useState<TeamPlanMember>();

  if (isEmptyArrayOrFalsey(members)) {
    return null;
  }

  return (
    <div className="mt-5 flex flex-col gap-3">
      <div>{title}</div>
      {members.map(member => (
        <div key={member.token} className="flex justify-between items-center">
          <div className="non-selected-text-color">{member.email}</div>
          {isMemberOwner(member) ? <div></div> : (
            <div className="non-selected-text-color hover:opacity-100 cursor-pointer">
              <X size={16} onClick={() => setMemberToRemove(member)} />
            </div>
          )}
        </div>
      ))}
      <RemoveMemberModal
        isOpen={!!memberToRemove}
        onRequestClose={() => setMemberToRemove(undefined)}
        removedTeammate={memberToRemove}
      />
    </div>
  );
}
