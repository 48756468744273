import { getUserEmail } from "../../lib/userFunctions";
import { constructRequestURL } from "../../services/api";
import { handleError } from "../../services/commonUsefulFunctions";
import Fetcher from "../../services/fetcher";
import type BackendContainer from "../backendContainer";

export function fetchOutstandingSlots(this: BackendContainer) {
  const { currentUser, outstandingSlotsStore } = this.props;

  const url = constructRequestURL("outstanding-slots", true);
  Fetcher.get<{ outstanding_slots: OutstandingSlot[] }>(url, {}, true, getUserEmail(currentUser))
    .then(response => {
      if (!response || !("outstanding_slots" in response)) {
        throw new Error("Unexpected response when fetching previously sent Slots.");
      }

      outstandingSlotsStore.setOutstandingSlots(response.outstanding_slots);
    })
    .catch((e) => {
      handleError(e);
    });
}

interface PatchOutstandingSlotParams {
  archived?: boolean
  slotTitle?: string
  slug: string
  username: string
}

export function patchOutstandingSlot(
  this: BackendContainer,
  { archived, slotTitle, slug, username }: PatchOutstandingSlotParams,
) {
  const { currentUser, outstandingSlotsStore } = this.props;
  const url = constructRequestURL(`slots/by-slug/${username}/${slug}`, true);
  const payload = {
    body: JSON.stringify({ archived, slot_title: slotTitle }),
  };
  Fetcher.patch<{ outstanding_slot: OutstandingSlot }>(url, payload, true, getUserEmail(currentUser))
    .then(response => {
      if (!response || !("outstanding_slot" in response)) {
        throw new Error("Unexpected response when patching outstanding slot.");
      }

      if (archived) {
        outstandingSlotsStore.removeBySlug(slug);
      } else {
        outstandingSlotsStore.updateOutstandingSlot(response.outstanding_slot);
      }
    })
    .catch((e) => {
      handleError(e);
    });
}
