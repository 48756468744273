import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { X } from "react-feather";
import { DEFAULT_FONT_COLOR } from "../../services/globalVariables";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import {
  SLOTS_SPAN_HOTKEY,
  SLOTS_SPAN_TYPE,
} from "../../lib/availabilityFunctions";
import * as Mousetrap from "mousetrap";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import broadcast from "../../broadcasts/broadcast";
import { FIND_TIMES_MODAL_ID } from "../../services/elementIDVariables";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";
import ShortcutTiles from "../shortcutTiles/shortcutTiles";

export default function GrabAvailableSlotsModal({onClose}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const componentIsMounted = useIsMounted();
  useEffect(() => {
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.MORNING, onClickMoveToMorning);
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.AFTERNOON, onClickMoveToAfternoon);
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.NEXT_WEEK, onClickMoveToNextWeek);
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.ONLY_WEEK_IN_VIEW, onClickWeekInView);
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.RANDOM, onClickRandom);
    Mousetrap.bind(SLOTS_SPAN_HOTKEY.ONLY_THIS_CURRENT_WEEK, onClickOnlyThisCurrentWeek);

    return () => {
      broadcast.publish("ENABLE_HOT_KEYS", true);
    }
  }, []);

  const onClickMoveToMorning = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.MORNING
    );
  };

  const onClickRandom = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.RANDOM
    );
  }

  const onClickMoveToAfternoon = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.AFTERNOON
    );
  };

  const onClickMoveToNextWeek = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.NEXT_WEEK
    );
  };

  const onClickWeekInView = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.ONLY_WEEK_IN_VIEW
    );
  }

  const onClickOnlyThisCurrentWeek = () => {
    if (!componentIsMounted?.current) {
      return;
    }
    availabilityBroadcast.publish(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      SLOTS_SPAN_TYPE.ONLY_THIS_CURRENT_WEEK
    );
  };

  return (
    <div className="p-5" id={FIND_TIMES_MODAL_ID}>
      {renderHeader(onClose)}
      {renderOption({
        text: "Move to morning",
        onClick: onClickMoveToMorning,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.MORNING),
        isDarkMode,
      })}
      {renderOption({
        text: "Move to afternoon",
        onClick: onClickMoveToAfternoon,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.AFTERNOON),
        isDarkMode,
      })}
      {renderOption({
        text: "Move to next week",
        onClick: onClickMoveToNextWeek,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.NEXT_WEEK),
        isDarkMode,
      })}
      {renderOption({
        text: "Current workweek",
        onClick: onClickOnlyThisCurrentWeek,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.ONLY_THIS_CURRENT_WEEK),
        isDarkMode,
      })}
      {renderOption({
        text: "Week in view",
        onClick: onClickWeekInView,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.ONLY_WEEK_IN_VIEW),
        isDarkMode,
      })}
      {renderOption({
        text: "Random",
        onClick: onClickRandom,
        shortCut: capitalizeFirstLetter(SLOTS_SPAN_HOTKEY.RANDOM),
        isDarkMode,
      })}
    </div>
  );
}

function renderHeader(onClose) {
  return (
    <div className="flex items-center justify-between">
      <div>✨ Find times</div>
      <X
        size={18}
        className="clickable-icon"
        onClick={onClose}
      />
    </div>
  );
}

function renderOption({ text, onClick, shortCut, isDarkMode }) {
  return (
    <div
      className={classNames(
        "flex items-center justify-between relative h-12 w-full rounded-md default-font-size",
        "px-4",
        "mt-4",
        "cursor-pointer",
        "grab-available-slots-option-container",
        "duration-200",
        "select-none"
      )}
      onClick={onClick}
    >
      <div>{text}</div>
      <div>
        <ShortcutTiles
          shortcut={shortCut}
          backgroundColor={isDarkMode ? "#2D2E3A" : DEFAULT_FONT_COLOR}
          border={isDarkMode ? "transparent" : undefined}
        />
      </div>
    </div>
  );
}
