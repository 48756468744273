import React, { useEffect, useState } from "react";
import NewsPaper from "../icons/newsPaper";
import { useSelector } from "react-redux";
import {
  DARK_MODE_SECONDARY_TEXT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
} from "../../services/globalVariables";
import { ChevronDown } from "react-feather";
import classNames from "classnames";
import { useEnrichedCompanyStore } from "../../services/stores/enrichedCompanies";
import { RIGHT_PANEL_CONTENT_WIDTH } from "../../lib/vimcalVariables";
import OpenAILogo from "../icons/openAILogo";
import { usePermissionsStore } from "../../services/stores/permissionsStore";
import { shouldHideAIFeatures } from "../../lib/featureFlagFunctions";

export default function EnrichedCompanyDetails({
  userEventID,
  attendeeDomains,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [openedCompanyProfiles, setOpenCompanyProfiles] = useState(new Set());
  const enrichCompaniesPermission = usePermissionsStore(
    (state) => state.enrichCompaniesPermission
  );
  const enrichedCompanies = useEnrichedCompanyStore(
    (state) => state.enrichedCompanies
  );
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    setOpenCompanyProfiles(new Set());
  }, [userEventID]);

  if (
    !showDisplayEnrichedProfile({
      attendeeDomains,
      enrichedCompanies,
    })
  ) {
    return null;
  }

  const toggleOpen = (companyURL) => {
    if (openedCompanyProfiles.has(companyURL)) {
      openedCompanyProfiles.delete(companyURL);
      setOpenCompanyProfiles(new Set(openedCompanyProfiles));
    } else {
      openedCompanyProfiles.add(companyURL);
      setOpenCompanyProfiles(new Set(openedCompanyProfiles));
    }
  };
  const filteredDomain = attendeeDomains.filter(
    (domain) => enrichedCompanies[domain]
  );

  if (!enrichCompaniesPermission) {
    return null;
  }

  if (shouldHideAIFeatures(currentUser)) {
    return null;
  }
  return (
    <div className="flex items-start justify-start mt-4">
      <div className="flex flex-col enrich-company-container">
        {filteredDomain.map((domain, index) => {
          return renderCompanyInfo({
            domain,
            enrichedCompanies,
            index,
            toggleOpen: () => toggleOpen(domain),
            isOpen: openedCompanyProfiles.has(domain),
            isDarkMode,
          });
        })}
      </div>
    </div>
  );
}

function renderCompanyInfo({
  domain,
  enrichedCompanies,
  index,
  toggleOpen,
  isOpen,
  isDarkMode,
}) {
  if (!domain) {
    return null;
  }

  return (
    <div
      key={`enrich-company-${index}`}
      className={classNames(index > 0 ? "mt-2" : "")}
    >
      <div
        className="hoverable-secondary-text-color flex items-center select-none"
        onClick={toggleOpen}
      >
        <NewsPaper
          strokeColor={
            isDarkMode
              ? DARK_MODE_SECONDARY_TEXT_COLOR
              : LIGHT_MODE_SECONDARY_TEXT_COLOR
          }
          size={16}
          containerClassName="flex justify-center items-center"
        />
        <div className="ml-2">{`More on ${domain.toLowerCase()}`}</div>
        <ChevronDown
          size={12}
          className={classNames(
            "ml-1 duration-300 transform",
            isOpen ? "rotate-180" : ""
          )}
        />
      </div>
      {renderCompanySummary({
        summary: enrichedCompanies[domain],
        index,
        isOpen,
      })}
    </div>
  );
}

function showDisplayEnrichedProfile({ attendeeDomains, enrichedCompanies }) {
  if (!attendeeDomains || attendeeDomains.length === 0) {
    return false;
  }

  let shouldDisplay = false;
  attendeeDomains.forEach((domain) => {
    if (enrichedCompanies[domain]) {
      shouldDisplay = true;
    }
  });

  return shouldDisplay;
}

function renderNews({ news, index, isOpen }) {
  if (!isOpen) {
    return null;
  }

  if (!news?.companyNews) {
    return null;
  }
  return (
    <div key={`enrich-company-news-${index}`}>
      <div className="mt-2.5">Recent news: </div>
      <div className={classNames("mt-1 ml-6", "duration-200")}>
        {news.companyNews}
      </div>
    </div>
  );
}

function renderCompanySummary({ summary, index, isOpen }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div key={`enrich-company-summary-${index}`}>
      <div className={classNames("mt-1 ml-6", "duration-200")}>{summary}</div>
      <div
        className="flex justify-center"
        style={{ width: RIGHT_PANEL_CONTENT_WIDTH }}
      >
        <div className="secondary-text-color powered-by-gpt-capsule mt-2">
          <OpenAILogo /> <div className="ml-2">Powered by GPT</div>
        </div>
      </div>
    </div>
  );
}
