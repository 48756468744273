import classNames from "classnames";
import React, { useState } from "react";
import { Check } from "react-feather";
import OnboardingDark from "../../../onboarding/resources/onboardingDark";
import OnboardingLight from "../../../onboarding/resources/onboardingLight";
import {
  isOSSchemeDarkMode,
} from "../../../../services/commonUsefulFunctions";
import broadcast from "../../../../broadcasts/broadcast";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import { getSelectedTheme, THEME } from "../../../../lib/userFunctions";

const DARK_MODE_COLOR = "#20222E";
const LIGHT_MODE_COLOR = "white";

export default function SelectTheme() {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentTheme = getSelectedTheme({masterAccount});

  // saving this in state so updates are faster (without having to wait for current user to come back on update)
  const [selectedTheme, setTheme] = useState(currentTheme);

  return (
    <div>
      <div className="flex items-center mt-2 w-full justify-between">
        {renderBox({
          label: "Dark Mode",
          isSelected: selectedTheme === THEME.DARK_MODE_THEME,
          isDark: true,
          onClick: () => {
            settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
            setTheme(THEME.DARK_MODE_THEME);
            broadcast.publish("ENABLE_DARK_MODE");
          },
        })}
        {renderBox({
          label: "Light Mode",
          isSelected: selectedTheme === THEME.LIGHT_MODE_THEME,
          isDark: false,
          onClick: () => {
            settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
            setTheme(THEME.LIGHT_MODE_THEME);
            broadcast.publish("DISABLE_DARK_MODE");
          },
        })}
        {renderBox({
          label: "Match OS",
          isSelected: selectedTheme === THEME.MATCH_OS_THEME,
          isDark: isOSSchemeDarkMode(),
          onClick: () => {
            settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
            setTheme(THEME.MATCH_OS_THEME);
            broadcast.publish("SET_COLOR_SCHEME_BASED_ON_OS");
            broadcast.publish("SET_COLOR_SCHEME_ON_OS_BACKEND");
          },
        })}
      </div>
    </div>
  );
}

function renderBox({ label, isSelected, isDark, onClick }) {
  return (
    <div
      className="flex flex-col items-center justify-center cursor-pointer"
      onClick={onClick}
    >
      <div
        style={{ backgroundColor: isDark ? DARK_MODE_COLOR : LIGHT_MODE_COLOR }}
        className={classNames(
          "refer-popup-container duration-200 p-2 default-corner-radius",
          "relative",
        )}
      >
        <div
          className={classNames(
            "h-7 w-7 rounded-full selected-theme-circle absolute -top-2 -right-2 flex items-center justify-center",
            isSelected ? "visible" : "invisible",
          )}
        >
          <Check size={20} color={"white"} />
        </div>

        {isDark ? <OnboardingDark /> : <OnboardingLight />}
      </div>

      <div
        className={classNames(
          "default-font-size mt-3",
          isSelected ? "primary-text-color" : "secondary-text-color",
        )}
      >
        {label}
      </div>
    </div>
  );
}
