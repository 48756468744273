import React, { useEffect, useState } from "react";
import { getActiveCommandCentersKey } from "../../services/commonUsefulFunctions";
import OnboardingSaveButton from "./onboardingSaveButton";
import Mousetrap from "mousetrap";
import { useSelector } from "react-redux";
import {useIsMounted} from "../../services/customHooks/useIsMounted";
import {CONFETTI_DURATION, TEAM_PHOTO} from "./sharedVariables";
import OnboardingConfetti from "./onboardingConfetti";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import OnboardingShortcutTiles from "../shortcutTiles/onboardingShortcutTiles";
// import confetti from "canvas-confetti";

export default function ThankYouPage(props) {
  const componentIsMounted = useIsMounted();
  const [shouldDisplayConfetti, setDisplayConfetti] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], () => props.onClickNext());
    setDisplayConfetti(true);
    const confettiTimeout = setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      setDisplayConfetti(false);
    }, CONFETTI_DURATION - 500);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });

    return () => {
      Mousetrap.reset();
      clearTimeout(confettiTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderConfetti = () => {
    if (!shouldDisplayConfetti) {
      return null;
    }

    return <OnboardingConfetti />;
  };

  return (
    <div className="onboarding-container min-height-600px">
      <div className="section-title">You're all ready!</div>

      <div className="welcome-subtitle mt-3 display-flex-flex-direction-row">
        If you ever need anything, say hi with 
        <div className="ml-1 mr-2 flex">
          <OnboardingShortcutTiles shortcut={`${getActiveCommandCentersKey()} K`} />
        </div>
        <div>{"-> “Aloha”"}</div>
      </div>

      {renderConfetti()}
      <img
        alt=""
        className="gif-tutorial mt-5"
        style={{ width: "inherit" }}
        src={TEAM_PHOTO}
      />
      <OnboardingSaveButton buttonText="Take me to Vimcal" onClick={props.onClickNext} />
    </div>
  );
}
