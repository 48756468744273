import React from "react";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import { format } from "date-fns";
import classNames from "classnames";

export default function ProgressInYear({ endJSDate, showSkeletonScreen }) {
  if (showSkeletonScreen) {
    return (
      <div className="metrics-data-container mt-5 padding-20px-override">
        <MetricsSkeletonLoadingPanel width={"100%"} height={45} />
      </div>
    );
  }
  return (
    <div className="metrics-data-container mt-5 padding-20px-override">
      <div className="flex items-center">
        {renderPercentageOfYearLabel({ endJSDate })}
        {renderPercentageChoppedLine({ endJSDate })}
      </div>
    </div>
  );
}

function renderPercentageOfYearLabel({ endJSDate }) {
  return (
    <div className="flex flex-col justify-center font-weight-400 metrics-default-section-header">
      <div className="metrics-secondary-text-color width-max-content">
        {`${format(endJSDate ?? new Date(), "y")} progress`}
      </div>

      <div className="metrics-primary-text-color mt-2">{`${getPercentageOfYear({
        endJSDate,
      })}%`}</div>
    </div>
  );
}

function renderPercentageChoppedLine({ endJSDate }) {
  return (
    <div className="w-full h-2 flex ml-5 rounded-lg">
      <div className="flex w-full rounded-lg gap-1">
        {renderMonth({ month: 0, endJSDate })}
        {renderMonth({ month: 1, endJSDate })}
        {renderMonth({ month: 2, endJSDate })}
        {renderMonth({ month: 3, endJSDate })}
        {renderMonth({ month: 4, endJSDate })}
        {renderMonth({ month: 5, endJSDate })}
        {renderMonth({ month: 6, endJSDate })}
        {renderMonth({ month: 7, endJSDate })}
        {renderMonth({ month: 8, endJSDate })}
        {renderMonth({ month: 9, endJSDate })}
        {renderMonth({ month: 10, endJSDate })}
        {renderMonth({ month: 11, endJSDate })}
      </div>
    </div>
  );
}

function getPercentageOfYear({ endJSDate }) {
  const date = endJSDate ?? new Date();
  const startOfYear = new Date(date.getFullYear(), 0, 0);
  const diff =
    date -
    startOfYear +
    (startOfYear.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
  const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const lastDayOfYear = new Date(date.getFullYear(), 11, 31);
  const totalDays =
    Math.floor((lastDayOfYear - firstDayOfYear) / (1000 * 60 * 60 * 24)) + 1;
  const percentageOfYear = (dayOfYear / totalDays) * 100;
  return Math.min(Math.ceil(percentageOfYear), 100);
}

function renderMonth({ month, endJSDate }) {
  const percentage = getMonthCompletion({ month, endJSDate });
  const highLightStyle = { height: "100%" };
  if (percentage !== 0) {
    const { start, end } = getMonthlyGradientStartEnd(month);
    highLightStyle.backgroundImage = `linear-gradient(to right, ${start}, ${end})`;
    // highLightStyle.backgroundColor = "orange";
    highLightStyle.width = `${percentage.toFixed(0)}%`; // e.g. 93%
    highLightStyle.borderTopLeftRadius = month === 0 ? "0.25rem" : "0";
    highLightStyle.borderBottomLeftRadius = month === 0 ? "0.25rem" : "0";
    highLightStyle.borderTopRightRadius = month === 11 ? "0.25rem" : "0";
    highLightStyle.borderBottomRightRadius = month === 11 ? "0.25rem" : "0";
  }
  return (
    <div
      className={classNames(
        "rounded-lg h-2",
        "w-full",
        "metrics-faded-background-color"
      )}
      style={{
        borderTopLeftRadius: month === 0 ? "0.25rem" : "0",
        borderBottomLeftRadius: month === 0 ? "0.25rem" : "0",
        borderTopRightRadius: month === 11 ? "0.25rem" : "0",
        borderBottomRightRadius: month === 11 ? "0.25rem" : "0",
      }}
    >
      <div style={highLightStyle}></div>
    </div>
  );
}
function getMonthCompletion({ month, endJSDate }) {
  const date = endJSDate ?? new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDate = date.getDate();

  if (month < currentMonth) {
    return 100;
  } else if (month > currentMonth) {
    return 0;
  } else {
    const startOfMonth = new Date(currentYear, month, 1);
    const endOfMonth = new Date(currentYear, month + 1, 0);
    const daysInMonth = (endOfMonth - startOfMonth) / (1000 * 60 * 60 * 24) + 1;
    return (currentDate / daysInMonth) * 100;
  }
}

function getMonthlyGradientStartEnd(month) {
  switch (month) {
    case 0:
      return { start: "#C9177C", end: "#CD1E76" };
    case 1:
      return { start: "#CD1E76", end: "#D22571" };
    case 2:
      return { start: "#D22571", end: "#D82C6B" };
    case 3:
      return { start: "#D82C6B", end: "#DD3366" };
    case 4:
      return { start: "#DD3366", end: "#E33A60" };
    case 5:
      return { start: "#E33A60", end: "#E9415B" };
    case 6:
      return { start: "#E9415B", end: "#EF4855" };
    case 7:
      return { start: "#EF4855", end: "#F44F50" };
    case 8:
      return { start: "#F44F50", end: "#FA564A" };
    case 9:
      return { start: "#FA564A", end: "#FF5D45" };
    case 10:
      return { start: "#FF5D45", end: "#FF6762" };
    case 11:
      return { start: "#FF6762", end: "#FF6762" };
    default:
      return { start: "transparent", end: "transparent" };
  }
}
