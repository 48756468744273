import React from "react";
import { cleanUpDomainEmail, truncateString } from "../../../lib/stringFunctions";
import { X } from "react-feather";
import { isEmptyArrayOrFalsey } from "../../../services/typeGuards";

interface DomainLabelContainerProps {
  domainEmails: string[]
  removeEmail: (email: string) => void
}

export default function DomainLabelContainer({ domainEmails, removeEmail }: DomainLabelContainerProps) {
  if (isEmptyArrayOrFalsey(domainEmails)) {
    return null;
  }

  return (
    <span className="w-full flex-wrap relative flex items-center">
      {domainEmails.map((email) => {
        const displayName = cleanUpDomainEmail(email);
        return (
          <div
            key={`internal-domain-${displayName}`}
            className="selected-email-container"
          >
            <div className="margin-right-5">
              {truncateString(displayName, 40)}
            </div>

            <div className="display-flex-center">
              {removeEmail ? (
                <X
                  className="hoverable-secondary-text-color"
                  size={13}
                  onClick={() => removeEmail(email)}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </span>
  );
}
