import React, { Component } from "react";
import SaveButton from "./saveButton";
import Checkbox from "./checkbox";
import StyleConstants, {
  BLUE_BUTTON,
  SAVE_BUTTON_HEIGHT,
  WHITE_BUTTON,
} from "../services/globalVariables";
import GoogleCalendarService from "../services/googleCalendarService";
import CustomButton from "../components/customButton";
import classNames from "classnames";

// Strings from google
let { modifyEventsString, inviteOthersString, seeGuestListString } =
  GoogleCalendarService;

class SelectGuestPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifyEvent: this.props.defaultOption[0] || false,
      inviteOthers: this.props.defaultOption[1] || false,
      seeGuestList: this.props.defaultOption[2] || false,
    };
  }

  render() {
    return (
      <div className="mt-5 default-font-size">
        {this.renderCheckBoxAndPermissions()}

        {this.props.updateOnChange ? null : this.renderSaveButton()}
      </div>
    );
  }

  renderSaveButton() {
    if (this.props.showCancelAndSaveButton) {
      return (
        <div className="display-flex-flex-direction-row-justify-content-flex-end margin-top-30">
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={() => this.props.closeModal()}
            label="Cancel"
            addPaddingToRight={true}
          />

          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => {
              this.props.sendInformationBackToParentComponent(
                this.createResponseObject()
              );
            }}
            label="Save"
          />
        </div>
      );
    } else {
      return (
        <SaveButton
          width="100%"
          height={SAVE_BUTTON_HEIGHT}
          marginTop={30}
          onClick={() => {
            this.props.sendInformationBackToParentComponent(
              this.createResponseObject()
            );
          }}
        />
      );
    }
  }

  //================
  // RENDER METHODS
  //================

  renderCheckBoxAndPermissions() {
    const permissions = [
      ["modifyEvent", modifyEventsString],
      ["inviteOthers", inviteOthersString],
      ["seeGuestList", seeGuestListString],
    ];

    return permissions.map((permission, index) => {
      const isChecked = this.state[permission[0]] || this.state["modifyEvent"];
      return (
        <div
          key={`event_form_guest_permissions_${index}`}
          className="flex items-center mb-2"
        >
          <Checkbox
            isChecked={isChecked}
            onChange={() => this.toggleState(permission[0])}
            style={
              permission[1] === modifyEventsString
                ? null
                : {
                    backgroundColor: this.determineBackgroundColor(
                      permission[0]
                    ),
                  }
            }
          />

          <div
            onClick={() => this.toggleState(permission[0])}
            className={classNames(
              "margin-left-20 cursor-pointer select-none",
              "default-font-color"
            )}
          >
            {permission[1]}
          </div>
        </div>
      );
    });
  }

  //================
  // EVENT HANDLERS
  //================

  toggleState(keyName) {
    const {
      modifyEvent,
    } = this.state;
    if (modifyEvent && keyName !== "modifyEvent") {
      return;
    }
    this.setState(
      (prevState) => {
        return { [keyName]: !prevState[keyName] };
      },
      () => {
        if (this.props.updateOnChange) {
          this.props.sendInformationBackToParentComponent(
            this.createResponseObject()
          );
        }
      }
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  createResponseObject() {
    let modifyEventResponse = this.state.modifyEvent
      ? modifyEventsString
      : false;
    let inviteOthersResponse = this.state.inviteOthers
      ? inviteOthersString
      : false;
    let seeGuestListResponse = this.state.seeGuestList
      ? seeGuestListString
      : false;

    return [modifyEventResponse, inviteOthersResponse, seeGuestListResponse];
  }

  determineBackgroundColor(keyName) {
    if (this.state.modifyEvent) {
      return StyleConstants.mediumGray;
    } 
    if (this.state[keyName]) {
      return StyleConstants.blueColor;
    } 
    return "transparent";
  }
}

export default SelectGuestPermissions;
