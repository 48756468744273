import classNames from "classnames";
import React from "react";
import { ChevronDown } from "react-feather";

export default function ExpandIconWithAnimation({ isOpen, onClick, className }) {
  return (
    <ChevronDown
      size={16}
      className={classNames("duration-200", isOpen ? "rotate-180-degrees" : "", "cursor-pointer", className || "")}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    />
  );
}
