import React, { useState, useEffect, useMemo } from "react";
import {
  LIGHT_MODE_VIDEO_CONFERENCING_GIF,
  LIGHT_MODE_AVAILABILITY,
  LIGHT_MODE_NLP,
  TEAM_PHOTO,
  LIGHT_MODE_TIME_TRAVEL,
} from "./sharedVariables";
import "../../styles/welcome-style.css";
import "../../styles/welcome-dark-mode.css";
import WelcomePageEmphasizePersonalOnboarding from "./welcomePageEmphasizePersonalOnboarding";
import ThemeSelection from "./themeSelection";
import { useSelector } from "react-redux";
import {
  DARK_MODE_THEME,
  DOWNLOAD_INTEL_DESKTOP_LINK,
  DOWNLOAD_M1_DESKTOP_LINK,
  zoomImageURL,
  hangoutIconURL,
  COMMAND_CENTER_PNG,
} from "../../services/globalVariables";
import {
  loadTheme,
  OpenLink,
  isElectron,
  detectBrowser,
} from "../../services/commonUsefulFunctions";
import CmdKInstruction from "./cmdKInstruction";
import AppBroadcast from "../../broadcasts/appBroadcast";
import CommandCenterSection from "./commandCenterSection";
import CommandCenterReminder from "./commandCenterReminder";
import EventModalPopup from "../eventModalPopup";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import OnboardingAvailability from "./onboardingAvailability";
import AvailabilityGif from "./availabilityGif";
import PasteInEmail from "./pasteInEmail";
// import NLPDemo from './NLPDemo';
import ThankYouPage from "./thankYouPage";
import { usePreloadImages } from "../../services/customHooks/usePreloadImages";
import {
  getAccountState,
  isInOnboarding,
  getPromotionCode,
  getSavedPromoCode,
  removeSavedPromoCode,
  isWaitingToBeOnboarded,
  userNeedsTypeForm,
  isAccountReferred,
} from "../../lib/stateManagementFunctions";
import {
  ACCOUNT_STATE_WAIT_LIST,
  ACCOUNT_STATE_NEW_USER,
  ACCOUNT_STATE_ABANDONED,
  ACCOUNT_STATE_PREPAID,
  ACCOUNT_STATE_PAYING,
} from "../../lib/vimcalVariables";
import { addDataLayerTracking, trackEvent, trackInstall } from "../tracking";
import DesktopTitleBar from "../desktopTitleBar";
import CalendarHomeView from "../../views/calendarHomeView";
import useLatestMasterAccount from "../../services/customHooks/useLatestMasterAccount";
import { useAllCalendars, useMasterAccount } from "../../services/stores/SharedAccountData";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { useSubscriptionStore } from "../../services/stores/finance";
import { doesMagicLinkExist, isUserMaestroUser } from "../../services/maestroFunctions";
import { isOutlookUser } from "../../lib/outlookFunctions";
import {
  clearStoredAttribution,
  getStoredAttribution,
} from "../../lib/attributionFunctions";
import broadcast from "../../broadcasts/broadcast";
import CustomizePersonalLink from "./customizePersonalLink";
import {
  isInternalTeamUser,
  isSelfServeOpen,
  shouldSeeMandatoryOnboarding,
} from "../../lib/featureFlagFunctions";
import DownloadPage from "../settings/downloadPage";
import { TYPEFORM_ROUTE } from "../../services/routingFunctions";
import ShortcutHoverHint from "../shortcutHoverHint";
import DefaultBackButton from "../defaultBackButton";
import appBroadcast from "../../broadcasts/appBroadcast";
import onboardBroadcast from "../../broadcasts/onboardBroadcast";
import { useUserCodes } from "../../services/stores/userData";
import { useAccountActivity, useTutorialWizard } from "../../services/stores/appFunctionality";
import {
  MAGIC_LINK_ONBOARD_STEP,
  OnboardStep,
  ONBOARD_STEP,
  ONBOARD_STEP_ORDER,
  trackOnboarding,
} from "../../lib/tracking/onboardingTracking";
import ChooseOnboardingType from "./chooseOnboardingType";
import AddAdditionalAccountScreen from "./addAdditionalAccountScreen";
import { isGoogleAuthCode } from "../../lib/googleFunctions";
import { getAuthCode } from "../../services/queryParamFunctions";
import { updateMetricsCalendars } from "../metrics/metricsAccessorFunctions";
import { getAllPrimaryCalendarsIDsFromAllCalendars } from "../../lib/calendarFunctions";
import RoleQuestionSelection from "./roleQuestionSelection";
import OtherProductQuestion from "./OtherProductQuestion";
import { PRODUCTS, ROLES } from "./sharedFunctions";
import NumberOfExecutiveAssistants from "./numberOfExecutiveAssistants";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isUserInDarkMode } from "../../lib/settingsFunctions";
import { MAGIC_LINK_PATH } from "../../services/maestro/maestroRouting";
import MagicLinkSelectCalendars from "../magicLink/calendarSelect";
import MagicLinkConnectAccount from "../magicLink/connectAccount";
import { APP_BROADCAST_VALUES } from "../../lib/broadcastValues";
import { isDomainEnabled } from "../../lib/ssoFunctions";
import { getEmailDomain } from "../../lib/stringFunctions";
import { getUserEmail, getUserToken } from "../../lib/userFunctions";
import { fetchPersonalLinkForPersonalOnboarding } from "../../services/roundRobinFunctions";
import AddTeamMembers from "./addTeamMembers";
import "../../styles/team-plans.css";
import { fetcherGet } from "../../services/fetcherFunctions";
import { constructRequestURLV2, isErrorResponse } from "../../services/api";
import { TEAM_PLAN_ENDPOINTS } from "../../lib/endpoints";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import CreateTeamPlan from "./createTeamPlan";
import JoinTeamPlanByDomain from "./joinTeamPlanByDomain";
import { checkDomainCapture } from "../queries/teamPlans";

const PRELOAD_IMAGE_LIST = [
  LIGHT_MODE_VIDEO_CONFERENCING_GIF,
  zoomImageURL,
  hangoutIconURL,
  LIGHT_MODE_AVAILABILITY,
  LIGHT_MODE_TIME_TRAVEL,
  LIGHT_MODE_NLP,
  TEAM_PHOTO,
  COMMAND_CENTER_PNG.LIGHT_MODE,
  COMMAND_CENTER_PNG.DARK_MODE,
] as const;

const DOWNLOAD_DESKTOP_TITLE = "Vimcal Desktop App";
const DOWNLOAD_DESKTOP_SUBTITLE =
  "Download the Vimcal desktop app for a faster experience with better notifications.";

/* At current time (5/31/24), these props are used only by magic links */
interface OnboardingContainerProps {
  magicLink?: MagicLink
  magicLinkAllCalendars?: unknown
  setMagicLinkAllCalendars?: unknown
}

export default function OnboardingContainer({
  magicLink,
  magicLinkAllCalendars,
  setMagicLinkAllCalendars,
}: OnboardingContainerProps) {

  const currentUser = useSelector((state) => state.currentUser);
  const subscription = useSubscriptionStore((state) => state.subscription);

  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const hasReceivedLoginCode = useAccountActivity(
    (state) => state.hasReceivedLoginCode,
  );
  const resetAccountActivity = useAccountActivity(
    (state) => state.resetAccountActivity,
  );
  const [isAppElectron] = useState(isElectron());
  const [showBackButton, setShowBackButton] = useState(false);
  const [
    shouldRenderCalendarHomeViewContainer,
    setRenderCalendarHomeViewContainer,
  ] = useState(false);
  usePreloadImages(PRELOAD_IMAGE_LIST);
  const history = useHistory();
  const attribution = useUserCodes((state) => state.attribution);
  const showTutorialWizard = useTutorialWizard((state) => state.showTutorialWizard);
  const isRedirectFromAddAdditionalAccount = useMemo(() => !!getAuthCode(), []);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const [role, setRole] = useState<ValueOf<typeof ROLES>>();
  const [customRole, setCustomRole] = useState("");
  const [numberOfExecutiveAssistants, setNumberOfExecutiveAssistants] = useState("");
  const [otherProducts, setOtherProducts] = useState<ValueOf<typeof PRODUCTS>[]>([]);
  const isMagicLink = doesMagicLinkExist({ magicLink });
  const [shouldShowSSOLoginButton, setShouldShowSSOLoginButton] = useState(false);
  const [teamPlanInvites, setTeamPlanInvites] = useState<TeamPlanInvite[]>();
  const [teamPlansFromDomainCapture, setTeamPlansFromDomainCapture] = useState<TruncatedTeamPlan[]>();

  const handleLatestMasterAcount = (updatedMasterAccount: MasterAccount) => {
    if (shouldSkipRerouteToHome()) {
      // used for testing
      return;
    }

    // Stay in magic link onboarding for Maestro
    if (isMagicLink) {
      return;
    }

    if (isSelfServeOpen()) {
      if (!isInOnboarding(updatedMasterAccount)) {
        history.push("/home");
      } else {
        setAppToDarkMode({ masterAccount: updatedMasterAccount });
      }
    } else {
      // force onboarding
      if (userNeedsTypeForm(updatedMasterAccount)) {
        // go to typeform page
        history.push(`/${TYPEFORM_ROUTE}`);
        return;
      }

      if (isAccountReferred(updatedMasterAccount)) {
        // already here
        setAppToDarkMode({ masterAccount: updatedMasterAccount });
        return;
      }

      if (isWaitingToBeOnboarded(updatedMasterAccount)) {
        setAppToDarkMode({ masterAccount: updatedMasterAccount });
        return;
      }

      // only get here if user is not suppose to be in onboarding
      history.push("/home");
    }
  };

  const startAccountSubscription = () => {
    const currentAccountState = getAccountState(masterAccount);

    if (ACCOUNT_STATE_PREPAID === currentAccountState) {
      AppBroadcast.publish(APP_BROADCAST_VALUES.UPDATE_ACCOUNT_STATE, ACCOUNT_STATE_PAYING);
    } else if (isInOnboarding(masterAccount) || isEmptyObjectOrFalsey(subscription)) {
      // we can call init_trialed_subscription even if there's an existing subscription. Backen checks it
      // kick off subscription if in onboarding or subscription is empty
      AppBroadcast.publish(APP_BROADCAST_VALUES.INIT_TRIALED_SUBSCRIPTION);
    }
  };

  const [getLatestMasterAccount] = useLatestMasterAccount();

  useEffect(() => {
    handleLoginAdditionalUsers();
    if (hasReceivedLoginCode) {
      resetAccountActivity();
    }

    if (isEmptyObjectOrFalsey(currentUser)) {
      history.push("/login");
      return;
    }

    const userAttribution = attribution || getStoredAttribution();
    if (isSelfServeOpen()) {
      if (isInOnboarding(masterAccount)) {
        trackInstall({
          attribution: userAttribution,
          userToken: getUserToken(currentUser),
        });

        addDataLayerTracking({
          event: "completed_user_signup",
          userToken: getUserToken(currentUser),
          client: detectBrowser(),
        });
      } else {
        trackEvent({
          category: "onboarding",
          action: userAttribution,
          label: "user-not-in-onboarding",
          userToken: getUserToken(currentUser),
        });
      }
    }

    // remove attribution since we've already tracked it
    if (getStoredAttribution()) {
      clearStoredAttribution();
    }

    backendBroadcasts.publish("TRACK_WINDOW_SIZE");

    if (isOutlookUser(currentUser)) {
      backendBroadcasts.publish("GET_DEFAULT_OUTLOOK_CONFERENCING");
    }

    getLatestMasterAccount(handleLatestMasterAcount);

    checkForPromoCode();
    updateAccountState({ masterAccount });

    // loadTheme(LIGHT_MODE_THEME);
    // AppBroadcast.publish("SET_APP_LIGHT_MODE");
    // always default to dark
    setAppToDarkMode({ masterAccount });

    trackEvent({
      category: "onboarding",
      action: "0_onboarding",
      label: "onboarding_start",
      userToken: getUserToken(currentUser),
    });

    onboardBroadcast.subscribe("SHOW_BACK_BUTTON_IN_ONBOARDING", () => {
      if (!isSelfServeOpen()) {
        // only show if self serve is open
        setShowBackButton(true);
      }
    });

    checkForSSOLogin();

    // fetch personal link here even for EAs because
    // 1. we always use calendly for EA onboardings
    // 2. this reduces the perceived load time of the booking links
    fetchPersonalLinkForPersonalOnboarding(currentUser, false);

    return () => {
      onboardBroadcast.unsubscribe("SHOW_BACK_BUTTON_IN_ONBOARDING");
    };
  }, []);

  const checkForSSOLogin = async () => {
    return; // disable for now
    const isEnabled = await isDomainEnabled(getEmailDomain(getUserEmail(currentUser)));
    if (isEnabled) {
      setShouldShowSSOLoginButton(true);
    }
  };

  const getInitialStep = (): OnboardStep => {
    if (isMagicLink) {
      return MAGIC_LINK_ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT;
    }

    if (isRedirectFromAddAdditionalAccount) {
      return ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT;
    }

    if (isUserMaestroUser(masterAccount)) {
      // No need to ask what their role is.
      setRole(ROLES.EXECUTIVE_ASSISTANT);
      return ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS;
    }

    return ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE;
  };

  const [step, setStep] = useState<OnboardStep>(getInitialStep);
  const [showDownloadOptions, setDisplayDownloadOptions] = useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const getNextStep = (currentStep: ValueOf<typeof ONBOARD_STEP>) => {
    return ONBOARD_STEP_ORDER.indexOf(currentStep) + 1;
  };

  const setNextStep = (step: ValueOf<typeof ONBOARD_STEP>) => {
    if (step === ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE) {
      if (role === ROLES.EXECUTIVE_ASSISTANT) {
        setStep(ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS);
      } else {
        setStep(ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE);
      }
      return;
    }

    if (step === ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS) {
      setStep(ONBOARD_STEP.CALENDLY_BOOKING);
      return;
    }

    const nextStepIndex = getNextStep(step);
    if (nextStepIndex === ONBOARD_STEP_ORDER.length - 1) {
      // last step -> send user to /home
      history.push("/home");
      return;
    } else if (nextStepIndex === 6) {
      setRenderCalendarHomeViewContainer(true);
    }

    if (ONBOARD_STEP_ORDER[nextStepIndex] === ONBOARD_STEP.COMMAND_CENTER_PROMPT) {
      // get subscription before so we can check if subscription has already been started
      backendBroadcasts.publish("GET_SUBSCRIPTION_DETAILS");
    } else if (ONBOARD_STEP_ORDER[nextStepIndex] === ONBOARD_STEP.INTERACTIVE_SLOTS) {
      // The user's subscription must be started before the team plan step.
      showTutorialWizard();
      startAccountSubscription();
    } else if (ONBOARD_STEP_ORDER[nextStepIndex] === ONBOARD_STEP.SLOTS_GIF) {
      fetcherGet<{ team_plan_invites: TeamPlanInvite[] } | ErrorResponse>({
        url: constructRequestURLV2(TEAM_PLAN_ENDPOINTS.CHECK_INVITES),
        email: getUserEmail(currentUser),
      }).then(response => {
        if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
          return;
        }
        setTeamPlanInvites(response.team_plan_invites);
      });
      if (currentUser) {
        checkDomainCapture(currentUser).then(response => {
          setTeamPlansFromDomainCapture(response ?? []);
        });
      }
    } else if (ONBOARD_STEP_ORDER[nextStepIndex] === ONBOARD_STEP.DOWNLOAD_PAGE) {
      // set here so there's a little more time for the calendars to load
      // update metrics calendars
      const primaryCalendarUserCalendarIDs = getAllPrimaryCalendarsIDsFromAllCalendars(allCalendars).filter(id => !!id);
      if (primaryCalendarUserCalendarIDs.length > 1) {
        updateMetricsCalendars({
          userCalendarIDs: primaryCalendarUserCalendarIDs,
          user: currentUser,
        });
      }
    }

    if (!ONBOARD_STEP_ORDER[nextStepIndex]) {
      return;
    }
    setStep(ONBOARD_STEP_ORDER[nextStepIndex]);
  };

  const setNextMagicLinkStep = (step: ValueOf<typeof MAGIC_LINK_ONBOARD_STEP>) => {
    switch(step) {
      case MAGIC_LINK_ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT:
        setStep(MAGIC_LINK_ONBOARD_STEP.SELECT_CALENDARS);
        return;
      case MAGIC_LINK_ONBOARD_STEP.SELECT_CALENDARS:
        setStep(MAGIC_LINK_ONBOARD_STEP.CONNECT_ACCOUNTS);
        return;
      default:
        return;
    }
  };

  const shouldSkipRerouteToHome = () => {
    // should only be used for testing
    if (isEmptyObjectOrFalsey(currentUser)) {
      return false;
    }

    if (isInternalTeamUser(currentUser)) {
      return true;
    }

    return false;
  };

  const renderContent = () => {
    switch (step) {
      case ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE:
        return (
          <RoleQuestionSelection
            role={role}
            setRole={setRole}
            customRole={customRole}
            setCustomRole={setCustomRole}
            onClickNext={() => {
              if (role === ROLES.EXECUTIVE_ASSISTANT) {
                // TODO: turn account into maestro for show maestro onboarding page.
              }
              setNextStep(ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE);
            }}
            step={ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE}
          />
        );
      case ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS:
        return (
          <NumberOfExecutiveAssistants
            numberOfExecutiveAssistants={numberOfExecutiveAssistants}
            setNumberOfExecutiveAssistants={setNumberOfExecutiveAssistants}
            onClickNext={() => setNextStep(ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS)}
          />
        );
      case ONBOARD_STEP.QUESTION_OTHER_PRODUCTS:
        return (
          <OtherProductQuestion
            otherProducts={otherProducts}
            setOtherProducts={setOtherProducts}
            onClickNext={() => {
              setNextStep(ONBOARD_STEP.QUESTION_OTHER_PRODUCTS);
            }}
            step={ONBOARD_STEP.QUESTION_OTHER_PRODUCTS}
            role={role}
            customRole={customRole}
            numberOfExecutiveAssistants={numberOfExecutiveAssistants}
          />
        );
      case ONBOARD_STEP.CALENDLY_BOOKING:
        return (
          <WelcomePageEmphasizePersonalOnboarding
            onClickNext={() => setNextStep(ONBOARD_STEP.HOME)}
            step={ONBOARD_STEP.CALENDLY_BOOKING}
            shouldSeeMandatoryOnboarding={shouldSeeMandatoryOnboarding({ role })}
            isEA={role === ROLES.EXECUTIVE_ASSISTANT || isUserMaestroUser(masterAccount)}
            numberOfExecutiveAssistants={numberOfExecutiveAssistants}
          />
        );
      case ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE:
        return (
          <ChooseOnboardingType
            onClickSelfServe={() => setNextStep(ONBOARD_STEP.HOME)}
            onClickPersonal={() => setStep(ONBOARD_STEP.CALENDLY_BOOKING)}
            step={ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE}
          />
        );
      case ONBOARD_STEP.THEME_SELECTION:
        return (
          <ThemeSelection
            onClickNext={() => setNextStep(ONBOARD_STEP.THEME_SELECTION)}
            step={ONBOARD_STEP.THEME_SELECTION}
          />
        );
      case ONBOARD_STEP.DOWNLOAD_PAGE:
        return (
          <DownloadPage
            onClickNext={() => setNextStep(ONBOARD_STEP.DOWNLOAD_PAGE)}
            step={ONBOARD_STEP.DOWNLOAD_PAGE}
          />
        );
      case ONBOARD_STEP.USER_NAME:
        return (
          <CustomizePersonalLink
            onClickNext={() => setNextStep(ONBOARD_STEP.USER_NAME)}
            step={ONBOARD_STEP.USER_NAME}
          />
        );
      case ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT:
        return (
          <AddAdditionalAccountScreen
            onClickNext={() => setNextStep(ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT)}
            step={ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT}
            shouldShowSSOLoginButton={shouldShowSSOLoginButton}
          />
        );
      // case CONFERENCING_SETTING:
      //   return (
      //     <ConferencingSettings
      //       onClickNext={() => setNextStep(CONFERENCING_SETTING)}
      //       outlookDefaultConferencing={outlookDefaultConferencing}
      //     />
      //   );
      // case FLASH_SHORTCUTS:
      //   return (
      //     <FlashShortcuts
      //       onClickNext={() => setNextStep(FLASH_SHORTCUTS)}
      //       sanityCheckRemoveAdditionalTimeZones={true}
      //       step={ONBOARD_STEP_ORDER.indexOf(FLASH_SHORTCUTS)}
      //     />
      //   );
      // case GO_TO_CONFERENCING:
      //   return (
      //     <VideoConferencingToast
      //       onClickNext={() => setNextStep(GO_TO_CONFERENCING)}
      //       step={ONBOARD_STEP_ORDER.indexOf(GO_TO_CONFERENCING)}
      //     />
      //   );
      case ONBOARD_STEP.COMMAND_CENTER_PROMPT:
        return (
          <CmdKInstruction
            onClickNext={() => setNextStep(ONBOARD_STEP.COMMAND_CENTER_PROMPT)}
            step={ONBOARD_STEP.COMMAND_CENTER_PROMPT}
          />
        );
      case ONBOARD_STEP.INTERACTIVE_COMMAND_CENTER:
        return (
          <CommandCenterSection
            onClickNext={() =>
              setNextStep(ONBOARD_STEP.INTERACTIVE_COMMAND_CENTER)
            }
            step={ONBOARD_STEP.INTERACTIVE_COMMAND_CENTER}
          />
        );
      case ONBOARD_STEP.COMMAND_K_GIF:
        return (
          <CommandCenterReminder
            onClickNext={() => setNextStep(ONBOARD_STEP.COMMAND_K_GIF)}
            step={ONBOARD_STEP.COMMAND_K_GIF}
          />
        );
      case ONBOARD_STEP.SLOTS_GIF:
        return (
          <AvailabilityGif
            onClickNext={() => setNextStep(ONBOARD_STEP.SLOTS_GIF)}
            step={ONBOARD_STEP.SLOTS_GIF}
          />
        );
      case ONBOARD_STEP.INTERACTIVE_SLOTS:
        return (
          <OnboardingAvailability
            onClickNext={() => setNextStep(ONBOARD_STEP.INTERACTIVE_SLOTS)}
            step={ONBOARD_STEP.INTERACTIVE_SLOTS}
          />
        );
      // case TIME_TRAVEL_GIF:
      //   return (
      //     <TimeTravelGif
      //       onClickNext={() => setNextStep(TIME_TRAVEL_GIF)}
      //       step={ONBOARD_STEP_ORDER.indexOf(TIME_TRAVEL_GIF)}
      //     />
      //   );
      case ONBOARD_STEP.PASTE_IN_EMAIL:
        return (
          <PasteInEmail
            onClickNext={() => setNextStep(ONBOARD_STEP.PASTE_IN_EMAIL)}
            step={ONBOARD_STEP.PASTE_IN_EMAIL}
          />
        );
      // case TIME_TRAVEL:
      //   return (
      //     <OnboardTimeTravel
      //       onClickNext={() => setNextStep(TIME_TRAVEL)}
      //       availabilitySlots={availabilitySlots}
      //       step={ONBOARD_STEP_ORDER.indexOf(TIME_TRAVEL)}
      //     />
      //   );
      // case AVAILABILITY_TIME_TRAVEL_REMINDER:
      //   return (
      //     <AvailabilityAndTimeZoneSummary
      //       onClickNext={() => setNextStep(AVAILABILITY_TIME_TRAVEL_REMINDER)}
      //       step={ONBOARD_STEP_ORDER.indexOf(AVAILABILITY_TIME_TRAVEL_REMINDER)}
      //     />
      //   );
      // case NLP_DEMO:
      //   return (
      //     <NlpGif
      //       onClickNext={() => setNextStep(NLP_DEMO)}
      //       step={ONBOARD_STEP_ORDER.indexOf(NLP_DEMO)}
      //     />
      //   );
      case ONBOARD_STEP.JOIN_TEAM_PLAN_BY_DOMAIN:
        return (
          <JoinTeamPlanByDomain
            onJoin={() => setNextStep(ONBOARD_STEP.ADD_TEAM_MEMBERS)}
            onSkip={() => setNextStep(step)}
            setTeamPlansFromDomainCapture={setTeamPlansFromDomainCapture}
            teamPlansFromDomainCapture={teamPlansFromDomainCapture}
          />
        );
      case ONBOARD_STEP.CREATE_TEAM_PLAN:
        return (
          <CreateTeamPlan
            onClickNext={() => setNextStep(step)}
            onSkip={() => setNextStep(ONBOARD_STEP.ADD_TEAM_MEMBERS)}
            teamPlanInvites={teamPlanInvites}
          />
        );
      case ONBOARD_STEP.ADD_TEAM_MEMBERS:
        return (
          <AddTeamMembers
            onClickNext={() => setNextStep(step)}
          />
        );
      case ONBOARD_STEP.TEAM_THANK_YOU:
        return (
          <ThankYouPage
            onClickNext={() => {
              trackOnboarding({
                user: currentUser,
                masterAccount,
                step: ONBOARD_STEP.FULL_PRODUCT,
              });
              setNextStep(ONBOARD_STEP.TEAM_THANK_YOU);
            }}
            step={ONBOARD_STEP.TEAM_THANK_YOU}
          />
        );
      case MAGIC_LINK_ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT:
        return (
          <AddAdditionalAccountScreen
            magicLink={magicLink}
            onClickNext={() => setNextMagicLinkStep(step)}
            step={step}
          />
        );
      case MAGIC_LINK_ONBOARD_STEP.SELECT_CALENDARS:
        return (
          <MagicLinkSelectCalendars
            magicLink={magicLink}
            magicLinkAllCalendars={magicLinkAllCalendars}
            onClickNext={() => setNextMagicLinkStep(step)}
            setMagicLinkAllCalendars={setMagicLinkAllCalendars}
            step={step}
          />
        );
      case MAGIC_LINK_ONBOARD_STEP.CONNECT_ACCOUNTS:
        return (
          <MagicLinkConnectAccount
            magicLink={magicLink}
            magicLinkAllCalendars={magicLinkAllCalendars}
            step={step}
          />
        );
      default:
        return null;
    }
  };

  const renderDownloadDesktopOptions = () => {
    return (
      <EventModalPopup
        isOpen={showDownloadOptions}
        onRequestClose={() => setDisplayDownloadOptions(false)}
        width={500}
        title={""}
        style={determineDefaultModalStyle(isDarkMode)}
      >
        <div className="display-flex flex-direction-column align-items-center justify-content-center text-center">
          <div className="section-title">{DOWNLOAD_DESKTOP_TITLE}</div>
          <div className="welcome-subtitle">{DOWNLOAD_DESKTOP_SUBTITLE}</div>

          <div
            className="download-desktop-button download-button-purple mt-5"
            onClick={() => OpenLink(DOWNLOAD_INTEL_DESKTOP_LINK)}
          >
            Download for Mac (most common)
          </div>

          <div
            className={classNames(
              "download-desktop-button",
              isDarkMode ? "download-button-white" : "download-button-grey",
              "mt-5",
              "mb-5",
            )}
            onClick={() => OpenLink(DOWNLOAD_M1_DESKTOP_LINK)}
          >
            Download for Mac (M1)
          </div>
        </div>
      </EventModalPopup>
    );
  };

  const handleLoginAdditionalUsers = () => {
    // note this is for adding additional accounts -> only handling web for now
    const authCode = getAuthCode();

    if (!authCode) {
      return;
    }

    if (isGoogleAuthCode(window.location.search)) {
      broadcast.publish("ON_LOGIN_SUCCESS", {
        response: { code: authCode },
        isMagicLink,
        shouldSkipMagicLinkUserCheck: true,
      });
    } else {
      appBroadcast.publish("OUTLOOK_LOGIN_WITH_AUTH_CODE", {
        response: authCode,
        isFromOnboarding: true,
        isMagicLink,
        shouldSkipMagicLinkUserCheck: true,
      });
    }

    isMagicLink ?
      history.push(`/${MAGIC_LINK_PATH}`) :
      history.push("/welcome");
  };

  const getMagicLinkStepLocation = () => {
    switch (step) {
      case MAGIC_LINK_ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT:
        return 1;
      case MAGIC_LINK_ONBOARD_STEP.SELECT_CALENDARS:
        return 2;
      case MAGIC_LINK_ONBOARD_STEP.CONNECT_ACCOUNTS:
        return 3;
      default:
        return 1;
    }
  };

  const getStepLocation = () => {
    switch (step) {
      case ONBOARD_STEP.QUESTION_ABOUT_CURRENT_ROLE:
      case ONBOARD_STEP.QUESTION_NUMBER_OF_EXECUTIVE_ASSISTANTS:
      case ONBOARD_STEP.QUESTION_OTHER_PRODUCTS:
      case ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE:
      case ONBOARD_STEP.CALENDLY_BOOKING:
      case ONBOARD_STEP.HOME:
      case ONBOARD_STEP.THEME_SELECTION:
        return 1;
      case ONBOARD_STEP.USER_NAME:
      case ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT:
        return 2;
      case ONBOARD_STEP.COMMAND_K_GIF:
      case ONBOARD_STEP.COMMAND_CENTER_PROMPT:
      case ONBOARD_STEP.INTERACTIVE_COMMAND_CENTER:
        return 3;
      case ONBOARD_STEP.SLOTS_GIF:
      case ONBOARD_STEP.INTERACTIVE_SLOTS:
      case ONBOARD_STEP.PASTE_IN_EMAIL:
        return 4;
      case ONBOARD_STEP.JOIN_TEAM_PLAN_BY_DOMAIN:
      case ONBOARD_STEP.CREATE_TEAM_PLAN:
      case ONBOARD_STEP.ADD_TEAM_MEMBERS:
      case ONBOARD_STEP.DOWNLOAD_PAGE:
        return 5;
      default:
        return 1;
    }
  };

  const renderMagicLinkProgressBar = () => {
    if (step === MAGIC_LINK_ONBOARD_STEP.CONNECT_ACCOUNTS) {
      return null;
    }

    return (
      <div className="onboard-progress-bar">
        <div className="secondary-text-color default-font-color default-font-size mb-2">{`Step ${getMagicLinkStepLocation()} of 3`}</div>
        <div className="flex justify-center">
          <Dot isSelected={step === MAGIC_LINK_ONBOARD_STEP.ADD_ADDITIONAL_ACCOUNT} />
          <Dot isSelected={step === MAGIC_LINK_ONBOARD_STEP.SELECT_CALENDARS} />
          <Dot />
        </div>
      </div>
    );
  };

  const renderProgressBar = () => {
    if (
      step === ONBOARD_STEP.TEAM_THANK_YOU ||
      step === ONBOARD_STEP.HOME ||
      step === ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE ||
      step === ONBOARD_STEP.CALENDLY_BOOKING ||
      step === ONBOARD_STEP.DOWNLOAD_PAGE
    ) {
      return null;
    }

    const stepLocation = getStepLocation();

    return (
      <div className="onboard-progress-bar">
        <div className="secondary-text-color default-font-color default-font-size mb-2">Step {stepLocation} of 5</div>
        <div className="flex justify-center">
          {[1, 2, 3, 4, 5].map(location => (
            <Dot key={location} isSelected={stepLocation === location} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "overflow-y-auto flex flex-col",
        step === ONBOARD_STEP.HOME ||
          step === ONBOARD_STEP.CHOOSE_ONBOARDING_PAGE ||
          step === ONBOARD_STEP.CALENDLY_BOOKING
          ? "height-100vh"
          : "onboarding-overflow-wrapper",
        step === ONBOARD_STEP.TEAM_THANK_YOU ||
          step === ONBOARD_STEP.INTERACTIVE_SLOTS
          ? "overflow-x-hidden"
          : "",
      )}
    >
      {isAppElectron && <DesktopTitleBar />}
      {!isSelfServeOpen() && showBackButton ? renderBackButton() : null}
      {renderContent()}
      {renderDownloadDesktopOptions()}
      { isMagicLink ? renderMagicLinkProgressBar() : renderProgressBar()}
      {shouldRenderCalendarHomeViewContainer ? <CalendarHomeView isInOnboardingPreload={true} /> : null}
    </div>
  );
}

function renderBackButton() {
  return (
    <ShortcutHoverHint
      below
      style={{ left: 54, top: 116, width: "max-content" }}
      title={"Log out"}
    >
      <DefaultBackButton onClick={onClickLogout} />
    </ShortcutHoverHint>
  );
}

function onClickLogout() {
  appBroadcast.publish("CLICK_LOG_OUT");
}

function checkForPromoCode() {
  const savedPromoCode = getSavedPromoCode();
  const promotionCode = savedPromoCode ?? getPromotionCode();

  if (promotionCode) {
    AppBroadcast.publish("ADD_PROMOTION_CODE", promotionCode);
  }

  if (savedPromoCode) {
    removeSavedPromoCode();
  }
}

function updateAccountState({ masterAccount }) {
  if (!isSelfServeOpen()) {
    return;
  }

  if (
    [ACCOUNT_STATE_ABANDONED, ACCOUNT_STATE_WAIT_LIST].includes(
      getAccountState(masterAccount) ?? "",
    )
  ) {
    // if new or waitlist -> set to new user
    AppBroadcast.publish(APP_BROADCAST_VALUES.UPDATE_ACCOUNT_STATE, ACCOUNT_STATE_NEW_USER);
  }
}

function setAppToDarkMode({ masterAccount }) {
  if (!isUserInDarkMode({ masterAccount })) {
    loadTheme(DARK_MODE_THEME);
    AppBroadcast.publish("SET_APP_DARK_MODE");
    broadcast.publish("ENABLE_DARK_MODE");
  } else {
    loadTheme(DARK_MODE_THEME);
    AppBroadcast.publish("SET_APP_DARK_MODE");
  }
}

interface DotProps {
  isSelected?: boolean
}

function Dot({ isSelected }: DotProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);

  const determineBackground = () => {
    if (isDarkMode) {
      return isSelected ? "bg-gray-400" : "bg-gray-600";
    } else {
      return isSelected ? "bg-gray-400" : "bg-gray-300";
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames(
          "progress-dot",
          isSelected ? "w-3 h-3 mt-1.5 mx-2.5 mb-2" : "w-2 h-2 my-2 mx-2.5",
          determineBackground(),
        )}
      ></div>
    </div>
  );
}
