import React, { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import type {
  MultiValueProps,
  OptionProps,
  NamedProps as SelectProps,
  ValueType,
} from "react-select";
import type { Option } from "react-select/src/filters";
import classNames from "classnames";

import { CustomSelect, SelectOptionType } from "../select";
import {
  OutlookCategory,
  useOutlookCategoriesStore,
} from "../../services/stores/outlookCategoriesStore";
import { isCalendarOutlookCalendar } from "../../lib/calendarFunctions";
import { getCalendarOwnerEmail } from "../../services/calendarAccessors";
import CircleWithColor from "../circleWithColor";
import { OUTLOOK_COLORS } from "../../services/outlookColors";
import DropdownIndicator from "../select/dropDownIndicator";
import { getReactSelectBaseStyle } from "../select/styles";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import CheckBox from "../checkbox";

interface CategoryOption extends SelectOptionType {
  label: string;
  presetName: string;
}

function buildCategoryOption({
  color,
  displayName,
  id,
}: OutlookCategory): CategoryOption {
  return {
    label: displayName,
    presetName: color,
    value: id,
  };
}

interface SelectCategoriesProps {
  initialCategories?: string[];
  onChange: (categories: string[]) => void;
  selectedCalendar: VimcalCalendar;
  containerClassName?: string;
  showBorder?: boolean;
  isDisabled?: boolean;
}

export default function SelectCategories({
  initialCategories,
  onChange,
  selectedCalendar,
  containerClassName,
  showBorder,
  isDisabled,
}: SelectCategoriesProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const isOutlookCalendar = isCalendarOutlookCalendar(selectedCalendar);
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories,
  );
  const calendarOwnerEmail = getCalendarOwnerEmail(selectedCalendar);
  const calendarCategories = outlookCategories[calendarOwnerEmail] ?? [];
  const [selectedOptions, setSelectedOptions] = useState<
    ValueType<CategoryOption, true>
  >(
    initialCategories
      ?.map((displayName) =>
        calendarCategories.find((c) => c.displayName === displayName),
      )
      .filter((c): c is OutlookCategory => typeof c !== "undefined")
      .map(buildCategoryOption) ?? [],
  );

  useEffect(() => {
    // Clear the selectedOptions if there are options selected but none of them belong to the selected calendar.
    const availableIds = new Set(
      calendarCategories.map((category) => category.id),
    );
    if (
      !isEmptyArrayOrFalsey(selectedOptions) &&
      !selectedOptions.some((option) => availableIds.has(option.value))
    ) {
      setSelectedOptions([]);
    }
  }, [calendarOwnerEmail]);

  const options = useMemo(() => {
    return [...calendarCategories]
      .sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        } else if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      })
      .map(buildCategoryOption);
  }, [calendarOwnerEmail, outlookCategories]);

  const handleChange: SelectProps<CategoryOption, true>["onChange"] = (
    newSelectedOptions,
  ) => {
    setSelectedOptions(newSelectedOptions);
    const displayNames =
      newSelectedOptions?.map((option) => option.label) ?? [];
    onChange(displayNames);
  };

  if (!isOutlookCalendar) {
    return null;
  }

  return (
    <CustomSelect<CategoryOption, true>
      className={classNames(
        "outlook-category-select",
        containerClassName,
        isEmptyArrayOrFalsey(selectedOptions) ? "empty-state" : "",
      )}
      classNamePrefix="dark-mode"
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator,
        MultiValue: SelectedLabel,
        Option: CategoryOption,
      }}
      hideSelectedOptions={false}
      inputId="event_category_selector"
      isMulti
      isSearchable={true}
      onChange={handleChange}
      openMenuOnFocus={true}
      options={options}
      overrideStyles={getReactSelectBaseStyle({
        isDarkMode,
        showBorder: showBorder || false,
      })}
      tabSelectsValue={false}
      placeholder="Add Categories"
      value={selectedOptions}
      filterOption={(
        option: Omit<Option, "data"> & { data: CategoryOption },
        rawInput,
      ) => {
        return option.data.label
          .toLowerCase()
          .includes(rawInput.trim().toLowerCase());
      }}
      isDisabled={isDisabled}
    />
  );
}

function CategoryOption({
  data,
  isSelected,
  selectOption,
}: Omit<OptionProps<CategoryOption, true>, "data"> & { data: CategoryOption }) {
  return (
    <div className="category-option" onClick={() => selectOption(data)}>
      <div className="mr-2">
        <CheckBox isChecked={isSelected} />
      </div>
      <div className="mr-1.5">
        <CircleWithColor
          color={OUTLOOK_COLORS[data.presetName]?.hex}
          colorName={null}
          size={12}
        />
      </div>
      <div className="category-option-label">{data.label}</div>
    </div>
  );
}

/**
 * The index prop is available, even though the library doesn't include it in MultiValueProps.
 */
function SelectedLabel({
  data,
  index = 0,
}: MultiValueProps<CategoryOption> & { index?: number }) {
  return (
    <div className="selected-label" style={{ zIndex: -1 * index }}>
      <CircleWithColor
        color={OUTLOOK_COLORS[data.presetName]?.hex}
        colorName={null}
        size={12}
      />
    </div>
  );
}
