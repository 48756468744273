import React from "react";
import { XSquare } from "react-feather";
import CustomSelect from "../select";
import { OUT_OF_OFFICE_AUTO_DECLINE_ALL, OUT_OF_OFFICE_AUTO_DECLINE_NEW, OUT_OF_OFFICE_AUTO_DECLINE_NONE } from "../../services/googleCalendarService";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getReactSelectBaseStyle } from "../select/styles";
import { getInputStringFromEvent } from "../../lib/stringFunctions";

const AUTO_DECLINE_OPTIONS = [
  { label: 'Do not automatically decline', value: OUT_OF_OFFICE_AUTO_DECLINE_NONE },
  { label: 'Automatically decline new meetings', value: OUT_OF_OFFICE_AUTO_DECLINE_NEW },
  { label: 'Automatically decline all meetings', value: OUT_OF_OFFICE_AUTO_DECLINE_ALL },
];

interface OutOfOfficeOptionsProps {
  autoDeclineMode: string
  declineMessage: string
  setAutoDeclineMode: (newAutoDeclineMode: string) => void
  setDeclineMessage: (newDeclineMessage: string) => void
}

/**
 * These options are only available for Google events.
 */
export default function OutOfOfficeOptions({ autoDeclineMode, declineMessage, setAutoDeclineMode, setDeclineMessage }: OutOfOfficeOptionsProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);

  return (
    <>
      <XSquare size={"14"} className="secondary-text-color" />
      <CustomSelect
        className={classNames(isDarkMode ? "dark-mode-select" : "")}
        classNamePrefix="dark-mode"
        isSearchable={false}
        onChange={(option) => option && setAutoDeclineMode(option.value)}
        openMenuOnFocus
        tabSelectsValue={false}
        options={AUTO_DECLINE_OPTIONS}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode: isDarkMode,
          showBorder: false,
        })}
        value={AUTO_DECLINE_OPTIONS.find(option => option.value === autoDeclineMode)}
      />
      {autoDeclineMode === OUT_OF_OFFICE_AUTO_DECLINE_NONE ? null : (
        <>
          {/* Empty div for grid alignment. */}
          <div></div>
          <div className="px-1 pb-1">
            <input
              className="out-of-office-message-input w-full"
              onChange={e => setDeclineMessage(getInputStringFromEvent(e))}
              placeholder="Decline message"
              value={declineMessage}
            />
          </div>
        </>
      )}
    </>
  );
}
