global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React from "react";
import Classnames from "classnames";
import { DEFAULT_CHECK_BOX_SPINNER_ID } from "../../services/elementIDVariables";

/* Spinner used for checkboxes next to calendar toggle */
function CheckBoxSpinner({
  className,
  color,
  size,
  hideRightSidePadding,
  hideTopMargin = false,
  id=DEFAULT_CHECK_BOX_SPINNER_ID,
}) {
  const sizeStr = `${size}px`;
  return (
    <>
      <div
        className={Classnames("checkbox-spinner", className)}
        style={{
          borderBottomColor: color,
          height: sizeStr,
          marginTop: hideTopMargin ? 0 : sizeStr,
          width: sizeStr,
        }}
        id={id}
      />
      {hideRightSidePadding ? null : (
        <div style={{ height: sizeStr, width: sizeStr }} />
      )}
    </>
  );
}

export default CheckBoxSpinner;
