import React, { Component } from "react";
import { connect } from "react-redux";
import { isAISchedulingModalOpen, isAppInTaskMode, isModalOpen } from "../services/appFunctions";
import {
  createAvailabilityTextFromEvent,
  getAllHTMLContent,
  getTimeInAnchorTimeZone,
  getTimeZoneOffSet,
  guessTimeZone,
  isAfterMinute,
  isHTMLText,
  parseDateChronoDMY,
  removeSpecialHTMLCharacters,
  replaceContinuousEmptyStrings,
  sortEventsJSDate,
} from "../services/commonUsefulFunctions";
import {
  REVERSE_SLOTS_MODELS,
  REVERSE_SLOTS_PROVIDERS,
  formatTextForSlotCompletion,
  getReverseSlotCompletion,
} from "../lib/aiGTP/sharedFunctions";
import {
  addHours,
  addMinutes,
  differenceInSeconds,
  format,
  getHours,
  getMinutes,
  isSameMinute,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import {
  createTemporaryEvent,
  getParsableBookingLinkData,
  isAllowedBookingLink,
  splitSlotIntoDuration,
} from "../lib/availabilityFunctions";
import {
  callGoogleVisionAPI,
  extractTextFromImage,
} from "../lib/aiGTP/imageFunctions";
import pasteBroadcast from "../broadcasts/pasteBroadcast";
import broadcast from "../broadcasts/broadcast";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { makeJsonParsable, safeJSONParse } from "../lib/jsonFunctions";
import {
  GENERIC_ERROR_MESSAGE,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { removeDuplicateEventsBasedOnTime } from "../lib/eventFunctions";
import {
  findUpcomingDayOfWeek,
  getISO8601StringOffSetInMinutes,
  isStringInISO8601Format,
} from "../lib/timeFunctions";
import { addDays } from "date-fns";
import {
  getCalendlyDataFromText,
  isCalendlyText,
} from "../lib/calendlyFunctions";
import {
  getAllVimcalSlotTimesFromText,
  getVimcalBookingLinkDataFromText,
  getVimcalBookingURLFromText,
  getVimcalSlotDurationFromText,
  getVimcalSlotTimeZoneFromText,
  isVimcalBookingLinkText,
} from "../lib/vimcalFunctions";
import { trackEvent } from "./tracking";
import { trackFeatureUsage } from "./tracking";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { getCountOfSubstringInString } from "../lib/stringFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { immutablySortArray, isEmptyArray } from "../lib/arrayFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { shouldHideAIFeatures } from "../lib/featureFlagFunctions";
import { getUserToken } from "../lib/userFunctions";

const IS_DEBUG = false && !process.env.REACT_APP_CLIENT_ENV;
class PasteContainer extends Component {
  constructor(props) {
    super(props);

    this._fetchID = 0;

    this.handlePaste = this.handlePaste.bind(this);
    this.handlePasteText = this.handlePasteText.bind(this);
    this.discardPasteText = this.discardPasteText.bind(this);
    this.handleUploadedImage = this.handleUploadedImage.bind(this);
    this.handlePasteImage = this.handlePasteImage.bind(this);
    this.setPotentialEvents = this.setPotentialEvents.bind(this);

    pasteBroadcast.subscribe("HANDLE_PASTE_TEXT_AI", this.handlePasteText);
    pasteBroadcast.subscribe("DISCARD_PASTE_TEXT_AI", this.discardPasteText);
    pasteBroadcast.subscribe("HANDLE_UPLOADED_IMAGE", this.handleUploadedImage);
    pasteBroadcast.subscribe("SET_PASTED_POTENTIAL_EVENTS", this.setPotentialEvents);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("paste", this.handlePaste);
  }

  componentWillUnmount() {
    this._isMounted = false;
    pasteBroadcast.unsubscribe("HANDLE_PASTE_TEXT_AI");
    pasteBroadcast.unsubscribe("DISCARD_PASTE_TEXT_AI");
    pasteBroadcast.unsubscribe("HANDLE_UPLOADED_IMAGE");
    pasteBroadcast.unsubscribe("SET_PASTED_POTENTIAL_EVENTS");

    window.removeEventListener("paste", this.handlePaste);
  }

  render() {
    return null;
  }

  async handlePaste(e) {
    // If the target element is an input, do not proceed
    const { actionMode } = this.props;

    if (
      isAppInTaskMode({
        actionMode,
      })
    ) {
      return;
    }

    if (isModalOpen()) {
      return;
    }

    if (
      e.target.tagName.toLowerCase() === "input" ||
      e.target.tagName.toLowerCase() === "textarea" ||
      e.target.tagName.toLowerCase() === "br" || // this is for quill
      e.target.tagName.toLowerCase() === "span"
    ) {
      return;
    }

    if (isAISchedulingModalOpen()) {
      return;
    }

    if (this.shouldHideAIFeatures()) {
      return;
    }

    // Prevent the default paste action
    e.preventDefault();

    // Access the clipboard content
    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedText = clipboardData.getData("Text")?.trim(); // need to use let here because if it's rich text, we need to strip all rich text
    if (pastedText) {
      layoutBroadcast.publish("UPLOAD_AI_SCHEDULER", {
        initialText: pastedText,
      });
      this.handlePasteText({ pastedText });
      return;
    }

    const pastedImage = clipboardData.files?.[0];
    if (pastedImage?.type?.startsWith("image/")) {
      layoutBroadcast.publish("UPLOAD_AI_SCHEDULER", {
        isLoading: true,
      });
      this.handlePasteImage({ pastedImage });
    }
  }

  async handleUploadedImage(uploadedImage) {
    if (!uploadedImage || !uploadedImage?.split(",")?.[1]) {
      return;
    }
    const extractedText = await callGoogleVisionAPI(
      uploadedImage.split(",")[1]
    );
    if (!this._isMounted) {
      return;
    }
    layoutBroadcast.publish("UPLOAD_AI_SCHEDULER", {
      initialText: extractedText,
    });
    pasteBroadcast.publish("UPDATE_REVERSE_SLOTS_TEXT", extractedText);
    this.handlePasteText({ pastedText: extractedText, isFromImage: true });
  }

  shouldHideAIFeatures() {
    return shouldHideAIFeatures(this.props.currentUser);
  }

  // TODO: update to gpt-4o
  // 32mb limit
  // vellum help center: https://docs.vellum.ai/help-center/prompts/images
  async handlePasteImage({ pastedImage }) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const pasteImage = event.target.result;
      const { saveOriginalReverseSlotsImage } = this.props.temporaryStateStore;
      saveOriginalReverseSlotsImage(pasteImage);
    };
    reader.readAsDataURL(pastedImage);
    const extractedText = await extractTextFromImage(pastedImage);
    if (!this._isMounted) {
      return;
    }

    this.handlePasteText({ pastedText: extractedText, isFromImage: true });
  }

  async handlePasteText({ pastedText, isFromImage }) {
    if (this.shouldHideAIFeatures()) {
      return;
    }

    const { reverseSlotsPermission } = this.props.permissionsStore;
    if (!reverseSlotsPermission) {
      return;
    }
    if (!pastedText) {
      return;
    }
    const { currentTimeZone, currentUser } = this.props;

    trackFeatureUsage({
      action: `reverseSlots_${isFromImage ? "image" : "text"}`,
      userToken: getUserToken(currentUser),
    });

    if (isAllowedBookingLink(pastedText)) {
      getParsableBookingLinkData(pastedText);
      return;
    }

    this._fetchID += 1;
    const savedFetchdID = this._fetchID;

    const formattedText = this.formatPastedAndImageText(pastedText);

    const startTime = new Date(); // for tracking time diff
    const explictTimesAndDuration = this.getAllExplicitTimes(formattedText); // returns {potentialTimes, duration}
    const vimcalBookingLinkURLFromText = getVimcalBookingURLFromText(pastedText);
    if (vimcalBookingLinkURLFromText) {
      // found vimcal booking link in pasted string
      // something like book-vimcal.com/t/something was found
      backendBroadcasts.publish("GET_VIMCAL_LINK_DATA", vimcalBookingLinkURLFromText);
      return;
    }
    if (explictTimesAndDuration?.potentialTimes?.length > 0) {
      const { potentialTimes, duration, timeZone } = explictTimesAndDuration;
      // to save input text to vellum, doesn't do anything otherwise
      getReverseSlotCompletion({
        inputText: formattedText,
        currentTimeZone,
        currentDate: format(new Date(), "PPP"),
        currentTime: format(new Date(), "p"),
        currentUser,
        currentYear: format(new Date(), "y"),
      });
      // early return
      const savedFetchdID = this._fetchID;
      setTimeout(() => {
        if (savedFetchdID !== this._fetchID || !this._isMounted) {
          return;
        }

        this.setPotentialEvents({
          rawText: pastedText,
          splittedEvents: potentialTimes,
          completionID: null,
          parsedEvents: potentialTimes,
          startTime,
          duration,
          senderTimeZone: timeZone
        });
      }, SECOND_IN_MS * 2);
      return;
    }

    // Do something with the clipboard content
    if (IS_DEBUG) {
      console.log("Pasted data:", pastedText);
      console.log("formattedText_data:", formattedText);
    }

    const { inputText, detectedDuration } =
      formatTextForSlotCompletion(formattedText);
    IS_DEBUG && console.log("detected_duration", detectedDuration);
    IS_DEBUG && console.log("inputText for model:", inputText);
    const {
      model,
      provider
    } = this.getModelAndProvider({inputText});
    IS_DEBUG && console.log("model_and_provider", {model, provider});

    const response = await getReverseSlotCompletion({
      inputText,
      currentTimeZone,
      currentDate: format(new Date(), "PPP"),
      currentTime: format(new Date(), "p"),
      currentUser,
      currentYear: format(new Date(), "y"),
      model,
      provider
    });
    if (!response?.reverse_slots) {
      this.closeModalAndShowErrorOccured();
      return;
    }
    const { reverse_slots: reverseSlots, completion_id: completionID } =
      response;
    IS_DEBUG && console.log("reverse_slot_result", reverseSlots);
    if (!this._isMounted) {
      return;
    }

    // parsed result looks like this:
    // [
    //   {
    //     startTime: ISO_8601,
    //     endTime: ISO_8601,
    //     explicitDateDetected: boolean,
    //     explicitRelativeDayDetected: boolean,
    //     relativeDay: string,
    //     timeZone: IANA
    //   }
    //   ...
    // ]
    const parsedResults = this.formatGTPResult({ result: reverseSlots });
    IS_DEBUG && console.log("parsedResults_", parsedResults);
    const senderTimeZone = parsedResults?.[0]?.timeZone ?? currentTimeZone;
    if (isEmptyArray(parsedResults)) {
      this.closeModalAndShowErrorOccured();
      return;
    }

    const parsedEvents = parsedResults
      .map((parsedResult, index) => {
        const processedEvent = this.processModelEvents({
          inputText,
          parsedResult,
          detectedDuration,
        });

        if (!processedEvent) {
          return null;
        }

        const { startTime, endTime } = processedEvent;

        return createTemporaryEvent({
          startTime,
          endTime,
          index,
          hideCancel: true,
          isTemporaryAIEvent: true,
          isGroupVote: false,
          resourceId: currentUser?.email
        });
      })
      .filter((event) => event);

    if (savedFetchdID !== this._fetchID) {
      return null;
    }
    IS_DEBUG && console.log("parsedEvents_", parsedEvents);

    const filteredEvents = removeDuplicateEventsBasedOnTime(parsedEvents); // Filter for duplicates
    let splittedEvents = [];
    filteredEvents.forEach((e) => {
      const newSlots = splitSlotIntoDuration({
        breakDuration: detectedDuration || 30,
        start: e.eventStart,
        end: e.eventEnd,
        currentSlots: splittedEvents,
        hideCancel: true,
        isTemporaryAIEvent: true,
      });

      splittedEvents = splittedEvents.concat(newSlots);
    });
    this.setPotentialEvents({
      rawText: pastedText,
      splittedEvents,
      completionID,
      parsedEvents,
      startTime,
      duration: detectedDuration,
      senderTimeZone: senderTimeZone
    });
  }

  setPotentialEvents({
    splittedEvents,
    completionID,
    rawText,
    parsedEvents,
    startTime, // used to track length of time for await
    duration,
    senderTimeZone,
    isCalendly,
    isTryNextMonth,
    calendlyURL,
  }) {
    const filteredEvents = removeDuplicateEventsBasedOnTime(splittedEvents);
    if (filteredEvents[0]) {
      const sorted = immutablySortArray(filteredEvents, (a, b) =>
        sortEventsJSDate(a, b, false)
      );
      const { eventStart } = sorted[0];
      mainCalendarBroadcast.publish("JUMP_TO_DATE_AND_SCROLL_TO_TIME", eventStart);
    }

    const { currentTimeZone, format24HourTime, currentUser } = this.props;
    const { setReverseSlotsData, resetReverseSlotsData } =
      this.props.temporaryStateStore;
    const parsedText = createAvailabilityTextFromEvent({
      eventList: filteredEvents,
      currentTimeZone,
      format24HourTime,
      skipPreSlotText: true,
    });
    setReverseSlotsData({
      text: parsedText,
      completionID,
      events: filteredEvents,
      originalReverseSlotsInputText: rawText,
      duration,
      senderTimeZone: senderTimeZone || currentTimeZone
    });
    IS_DEBUG && console.log("filteredEvents_", filteredEvents);

    IS_DEBUG &&
      console.log(
        "diff_in_second_since_start",
        differenceInSeconds(new Date(), startTime)
      );
    IS_DEBUG &&
      console.log(
        "created_temporary_events",
        parsedEvents,
        parsedEvents.length
      );
    trackEvent({
      category: "reverse_slots",
      action: "reverse_slots_response_in_sec",
      label: differenceInSeconds(new Date(), startTime),
      userToken: getUserToken(currentUser),
    });
    IS_DEBUG && console.log("splittedEvents_", splittedEvents);
    if (isEmptyArray(splittedEvents) && isCalendly && !isTryNextMonth && calendlyURL) {
      // retry with next month
      backendBroadcasts.publish("GET_CALENDLY_LINK_DATA", {calendlyURL, isTryNextMonth: true});
      return;
    }
    broadcast.publish("CLOSE_LAYOUT_MODAL");

    if (isEmptyArray(splittedEvents)) {
      resetReverseSlotsData();
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "No potential events were detected."
      );
      return;
    }
    this.props.setTemporaryEvents(splittedEvents);
  }

  // the models don't always work as expected, so we need to do some post-processing
  processModelEvents({ inputText, parsedResult, detectedDuration }) {
    IS_DEBUG && console.log("processModelEvents_0", parsedResult);
    const { explicitDateDetected, explicitRelativeDayDetected, relativeDay, timeZone } =
      parsedResult;
    const { eventStart, eventEnd } = this.convertParsedEventStringToJSDate({
      parsedEvent: parsedResult,
      inputText,
      detectedDuration,
      timeZone
    });

    if (!eventStart || !eventEnd) {
      return null;
    }

    if (explicitDateDetected) {
      // detected date -> go with the detected date
      IS_DEBUG && console.log("processModelEvents_1", parsedResult);
      IS_DEBUG && console.log("processModelEvents_1_event_start_and_end", { eventStart, eventEnd });
      return {
        startTime: eventStart,
        endTime: eventEnd,
      };
    }

    // no date was detected, this is all relative
    // go with the day of the week in the future
    const now = getTimeInAnchorTimeZone(
      new Date(),
      guessTimeZone(),
      this.props.currentTimeZone
    );

    if (explicitRelativeDayDetected) {
      IS_DEBUG && console.log("processModelEvents_2", parsedResult);
      if (relativeDay.toLowerCase() === "today") {
        return {
          startTime: setHours(
            setMinutes(now, getMinutes(eventStart)),
            getHours(eventStart)
          ),
          endTime: setHours(
            setMinutes(now, getMinutes(eventEnd)),
            getHours(eventEnd)
          ),
        };
      }

      if (relativeDay.toLowerCase() === "tomorrow") {
        return {
          startTime: setHours(
            setMinutes(addDays(now, 1), getMinutes(eventStart)),
            getHours(eventStart)
          ),
          endTime: setHours(
            setMinutes(addDays(now, 1), getMinutes(eventEnd)),
            getHours(eventEnd)
          ),
        };
      }

      if (
        format(eventStart, "EEEE").toLowerCase() ===
          relativeDay.toLowerCase() &&
        isAfterMinute(eventStart, now)
      ) {
        // make sure that date is the day of the week and the result is correct
        IS_DEBUG && console.log("processModelEvents_2", parsedResult);
        return {
          startTime: eventStart,
          endTime: eventEnd,
        };
      }

      // if it's in the past -> get upcoming day of week for the day of week
      const upcomingDayOfWeek = findUpcomingDayOfWeek(relativeDay);
      return {
        startTime: setHours(
          setMinutes(upcomingDayOfWeek, getMinutes(eventStart)),
          getHours(eventStart)
        ),
        endTime: setHours(
          setMinutes(upcomingDayOfWeek, getMinutes(eventEnd)),
          getHours(eventEnd)
        ),
      };
    }

    // neither date nor day of week was detected -> set to tomorrow
    if (isAfterMinute(eventStart, now)) {
      return {
        startTime: eventStart,
        endTime: eventEnd,
      };
    }
    return {
      startTime: addDays(eventStart, 1),
      endTime: addDays(eventEnd, 1),
    };
  }

  formatGTPResult({ result }) {
    if (!result) {
      return null;
    }

    let formattedResult =
      result.includes("[") && !result.includes("]") ? result + "]" : result;
    
    formattedResult = this.removeLines(formattedResult, ['...', '//']);

    const regex = /\[\s*({[\s\S]*})\s*\]/;
    const match = formattedResult.match(regex);
    // IS_DEBUG && console.log("match_", match);
    const jsonObjectRegex = /\{[\s\S]*?\}/; // Regular expression to match everything between '{' and '}'
    const jsonObjectMatch = result.match(jsonObjectRegex); // Apply regex to the string

    if (match && match[1]) {
      // if in array format
      const jsonArrayString = `[${match[1]}]`;
      const jsonArrayStringWithoutNewLines = replaceContinuousEmptyStrings(
        jsonArrayString.replace(/\n/g, "")
      );
      const formatStringIntoJSON = this.formatResponseIntoProperJSJSON(
        jsonArrayStringWithoutNewLines
      );
      IS_DEBUG && console.log("formatStringIntoJSON_", formatStringIntoJSON);
      const jsonArray = safeJSONParse(formatStringIntoJSON);
      return jsonArray;
    } else if (jsonObjectMatch) {
      const jsonStr = jsonObjectMatch[0]; // Extract matched string
      const jsonObj = safeJSONParse(jsonStr); // Convert string to JSON object
      if (jsonObj) {
        return [jsonObj];
      }

      return null;
    } else {
      IS_DEBUG && console.log("No match found");
      return null;
    }
  }

  formatResponseIntoProperJSJSON(inputString) {
    const jsonPattern = /^[\[\{].*[\]\}]$/;

    // Check if the input string is already JSON-like
    if (jsonPattern.test(inputString.trim())) {
      return inputString;
    }

    const stringWithDoubleQuotes = inputString.replace(/'/g, '"');
    const validJsonString = stringWithDoubleQuotes.replace(
      /([{,]\s*)(\w+)(\s*:)/g,
      '$1"$2"$3'
    );
    return makeJsonParsable(validJsonString);
  }

  isTimeInEarlyMorning(jsDate) {
    return getHours(jsDate) >= 1 && getHours(jsDate) <= 7;
  }

  containsTimeAM(str, num) {
    // num is an int
    // use a regular expression to check if a string includes any permutation of "1-7" with "am" or "AM" right next to it, with or without a space
    // also matches times like "6:00am", "7:30am", etc.
    const regex = new RegExp(`(${num})(?::\\d{2})?\\s?[Aa][Mm]`);
    return regex.test(str);
  }

  isOriginalTimeInPM(dateString) {
    // Extract the hour from the string
    const hour = parseInt(dateString.slice(11,13), 10);

    // If the hour is 12 or more, it's PM
    return hour >= 12;
  }

  convertParsedEventStringToJSDate({
    parsedEvent,
    inputText,
    detectedDuration,
    timeZone
  }) {
    if (isEmptyObjectOrFalsey(parsedEvent)) {
      return {};
    }

    const { startTime, endTime } = parsedEvent;
    if (
      !isStringInISO8601Format(startTime) ||
      !isStringInISO8601Format(endTime)
    ) {
      // need to check for iso format, otherwise, could cause crash
      return {};
    }
    const { currentTimeZone } = this.props;

    // check if the offset is correct

    let eventStart = parseISO(startTime);
    let eventEnd = parseISO(endTime);

    // all in minutes
    const timeZoneOffSet = -1 * getTimeZoneOffSet(eventStart, timeZone || currentTimeZone); // in getTimeZoneOffSet, we set it to -1 * so this just reverses that
    const stringOffSet = getISO8601StringOffSetInMinutes(startTime);
    const differenceInTimeZoneOffSets = stringOffSet - timeZoneOffSet;
    IS_DEBUG && console.log("differenceInTimeZoneOffSets_", differenceInTimeZoneOffSets);

    if (timeZone && differenceInTimeZoneOffSets !== 0) {
      // check if the time zone offset is correct
      eventStart = addMinutes(eventStart, differenceInTimeZoneOffSets);
      eventEnd = addMinutes(eventEnd, differenceInTimeZoneOffSets);
    }

    if (this.isOriginalTimeInPM(startTime)) {
      // if the original time is in PM, then we need to add 12 hours to the parsed time
    } else if (
      !this.containsTimeAM(inputText, getHours(eventStart)) &&
      this.isTimeInEarlyMorning(eventStart) &&
      this.isTimeInEarlyMorning(eventEnd)
    ) {
      //if both are in early morning -> set to afternoon
      // there's a bug with anthropic where it's gives it in the morning when these shoudl be in the afternoon
      eventStart = addHours(eventStart, 12);
      eventEnd = addHours(eventEnd, 12);
    }

    if (isSameMinute(eventStart, eventEnd)) {
      eventEnd = addMinutes(eventEnd, detectedDuration || 30);
    }

    const localTimeZone = guessTimeZone();
    if (localTimeZone !== currentTimeZone) {
      // since parseISO only works in local time, we need to convert it to the current time zone
      return {
        eventStart: getTimeInAnchorTimeZone(
          eventStart,
          localTimeZone,
          currentTimeZone
        ),
        eventEnd: getTimeInAnchorTimeZone(
          eventEnd,
          localTimeZone,
          currentTimeZone
        ),
      };
    }

    return { eventStart, eventEnd };
  }

  discardPasteText() {
    this._fetchID += 1;
  }

  closeModalAndShowErrorOccured() {
    broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      GENERIC_ERROR_MESSAGE
    );
    pasteBroadcast.publish("RESET_MODAL_LOADING");
  }

  getModelAndProvider({inputText}) {
    return {
      provider: REVERSE_SLOTS_PROVIDERS.PROVIDER_ANTHROPIC,
      model: REVERSE_SLOTS_MODELS.MODEL_CLAUDE_INSTANT_1_2 // instant 1.2 fixes a lot of our problems with more than 5 potential times
    };
    // return {
    //   provider: REVERSE_SLOTS_PROVIDERS.PROVIDER_OPEN_AI,
    //   model: REVERSE_SLOTS_MODELS.MODEL_GPT_4_TURBO // instant 1.2 fixes a lot of our problems with more than 5 potential times
    // };

    const DEFAULT_MODEL_AND_PROVIDER = {
      model: REVERSE_SLOTS_MODELS.MODEL_CLAUDE_INSTANT_1_1,
      provider: REVERSE_SLOTS_PROVIDERS.PROVIDER_ANTHROPIC,
    };
    const HEAVIER_MODEL_AND_PROVIDER = {
      model: REVERSE_SLOTS_MODELS.MODEL_GPT_3_5_TURBO,
      provider: REVERSE_SLOTS_PROVIDERS.PROVIDER_OPEN_AI
    };

    if (!inputText) {
      return DEFAULT_MODEL_AND_PROVIDER;
    }
    
    if (getCountOfSubstringInString({str: inputText, substr: "-"}) >= 5
      || getCountOfSubstringInString({str: inputText, substr: "•"}) >= 5
      || this.getCountOfAmPmInString(inputText) > 8
    ) {
      return HEAVIER_MODEL_AND_PROVIDER;
    }

    return DEFAULT_MODEL_AND_PROVIDER;
  }

  getCountOfAmPmInString(str) {
    if (!str) {
      return 0;
    }

    const matches = str.match(/(?:\d| )am|pm/gi)
    return matches?.length ?? 0;
  }

  detectedMonthNotInCurrentMonthFromText(text) {
    if (!text) {
      return false;
    }
    const monthRegex =
      /(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)/gi;

    const monthLookup = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const matches = text.matchAll(monthRegex);

    const currentMonth = new Date().getMonth();

    for (const match of matches) {
      const monthName = match[1];
      const monthNumber =
        monthLookup[
          monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase()
        ];

      if (monthNumber !== currentMonth) {
        return true;
      }
    }

    return false;
  }

  getAllExplicitTimes(text) {
    // this is so we can parse calendly links
    const calendlyTimes = this.getCalendlyTimes(text);
    if (calendlyTimes) {
      return calendlyTimes;
    }

    const vimcalTimes = this.getVimcalTimes(text);
    if (vimcalTimes) {
      return vimcalTimes;
    }

    return null;
  }

  getMatchedTimesFromText(text) {
    if (!text) {
      return [];
    }

    const regex = /\b(?:\d{1,2}):\d{2}\s?[ap]m\b/gi;

    let match;
    let matches = [];

    while ((match = regex.exec(text)) !== null) {
      let index = match.index;
      if (index === 0 || text[index - 1] !== "(") {
        matches = matches.concat(match[0]);
      }
    }

    return matches;
  }

  // WIP: need to fix this
  getVimcalSlotsTimes(text) {
    const matchedTimes = getAllVimcalSlotTimesFromText(text);
    if (isEmptyArray(matchedTimes)) {
      return null;
    }
    const { currentTimeZone } = this.props;

    const duration = getVimcalSlotDurationFromText(text);
    const timeZone = getVimcalSlotTimeZoneFromText(text) ?? currentTimeZone;

    return this.getTimesFromParsingText({
      inputJsDateDate: new Date(),
      duration,
      timeZone,
      matchedTimes,
      forwardDate: false,
    });
  }

  getVimcalTimes(text) {
    const matchedTimes = this.getMatchedTimesFromText(text);

    const isVimcal = isVimcalBookingLinkText(text);
    if (!isVimcal || isEmptyArray(matchedTimes)) {
      return null;
    }

    const { currentTimeZone } = this.props;

    const { date, duration, timeZone } = getVimcalBookingLinkDataFromText(
      text,
      currentTimeZone
    );

    return this.getTimesFromParsingText({
      date,
      duration,
      timeZone,
      matchedTimes,
    });
  }

  getCalendlyTimes(text) {
    const matchedTimes = this.getMatchedTimesFromText(text);
    const isCalendly = isCalendlyText(text);
    if (!isCalendly || isEmptyArray(matchedTimes)) {
      return null;
    }

    const { currentTimeZone } = this.props;

    const { date, duration, timeZone } = getCalendlyDataFromText(
      text,
      currentTimeZone
    );

    return this.getTimesFromParsingText({
      date,
      duration,
      timeZone,
      matchedTimes,
    });
  }

  // instead of using AI, this is more hard coded to get times
  getTimesFromParsingText({
    inputJsDateDate,
    date,
    duration,
    timeZone,
    matchedTimes,
    forwardDate = true,
  }) {
    const jsDate = inputJsDateDate ?? parseDateChronoDMY(date);
    const { currentTimeZone, currentUser } = this.props;
    // create a list of times with dates
    let potentialTimes = [];
    if (currentTimeZone !== timeZone) {
      matchedTimes.forEach((time, index) => {
        const jsTime = parseDateChronoDMY(time, jsDate);
        if (!jsTime) {
          return;
        }
        const startTime = getTimeInAnchorTimeZone(
          jsTime,
          timeZone,
          currentTimeZone
        );
        potentialTimes = potentialTimes.concat(
          createTemporaryEvent({
            startTime: startTime,
            endTime: addMinutes(startTime, duration),
            index,
            hideCancel: true,
            isTemporaryAIEvent: true,
            isGroupVote: false,
            resourceId: currentUser?.email
          })
        );
      });
    } else {
      matchedTimes.forEach((time, index) => {
        const jsTime = parseDateChronoDMY(time, jsDate, forwardDate);
        if (!jsTime) {
          return;
        }
        potentialTimes = potentialTimes.concat(
          createTemporaryEvent({
            startTime: jsTime,
            endTime: addMinutes(jsTime, duration),
            index,
            hideCancel: true,
            isTemporaryAIEvent: true,
            isGroupVote: false,
            resourceId: currentUser?.email
          })
        );
      });
    }

    if (isEmptyArray(potentialTimes)) {
      return null;
    }

    return { potentialTimes, duration, timeZone };
  }

  formatPastedAndImageText(text) {
    if (!text) {
      return "";
    }

    // remove single character lines where the character is a letter
    let formattedText = text
      .split("\n")
      .filter((line) => !(line.trim().length === 1 && isNaN(line.trim())))
      .join("\n");
    if (isHTMLText(formattedText)) {
      formattedText = removeSpecialHTMLCharacters(
        getAllHTMLContent({ html: formattedText })
      );
    }
    return formattedText;
  }

  removeLines(input, undesiredChars) {
    return input.split('\n')
      .filter(line => !undesiredChars.some(char => line.includes(char)))
      .join('\n');
  }
}

function mapStateToProps(state) {
  const {
    currentTimeZone,
    format24HourTime,
    currentUser,
    actionMode,
  } = state;

  return {
    currentTimeZone,
    format24HourTime,
    currentUser,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTemporaryEvents: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const temporaryStateStore = useTemporaryStateStore();
  const permissionsStore = usePermissionsStore();

  return (
    <BaseComponent
      {...props}
      temporaryStateStore={temporaryStateStore}
      permissionsStore={permissionsStore}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(PasteContainer));
