import React, { useMemo } from "react";
import { useAllLoggedInUsers, useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { isUserExecutiveUser, isUserMaestroUser, isUserMagicLinkUser } from "../../../../services/maestroFunctions";
import { MY_CALENDAR_TAG_LABEL } from "./smartTagSetting";
import CheckBox from "../../../checkbox";
import { getPrimaryUser, getUserEmail, getUserToken, isEmailExecEmail } from "../../../../lib/userFunctions";
import ExecutiveLabel from "../../../../../components/executiveLabel";
import { isEmptyArrayOrFalsey } from "../../../../services/typeGuards";
import classNames from "classnames";
import { CustomSelect } from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import { lowerCaseAndTrimString, pluralize } from "../../../../lib/stringFunctions";
import { getReactSelectBaseStyle } from "../../../select/styles";

export default function TagsAccountDropdown({selectedUserTokens, setSelectedUserTokens}) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const getDefaultUserToken = () => {
    const primaryUser = getPrimaryUser(allLoggedInUsers, masterAccount);
    const nonExecUsers = allLoggedInUsers.filter((user) => !isUserExecutiveUser({user}));
    return getUserToken(primaryUser) || getUserToken(nonExecUsers[0]);
  };
  const options = useMemo(() => {
    const execUsers = allLoggedInUsers.filter((user) => isUserExecutiveUser({user}) && !isUserMagicLinkUser({user}));
    const result = [{
      label: MY_CALENDAR_TAG_LABEL,
      value: getDefaultUserToken(),
    }];
    if (execUsers.length > 0) {
      execUsers.forEach((user) => {
        result.push({
          label: getUserEmail(user),
          value: getUserToken(user),
        });
      });
    }
    return result;
  }, [allLoggedInUsers]);

  if (!isUserMaestroUser(masterAccount)) {
    return null;
  }

  const handleChange = (options) => {
    if (isEmptyArrayOrFalsey(options)) {
      setSelectedUserTokens([]);
      return;
    }
    const updatedOptions = options.map((option) => option.value);
    setSelectedUserTokens(updatedOptions);
  };

  const renderOption = (option) => {
    const {
      selectOption,
      data,
      isSelected,
    } = option;
    const isExecEmail = isEmailExecEmail({email: option.label, masterAccount, allLoggedInUsers});
    return (
      <div
        className="flex gap-2 items-end default-font-size category-option"
        onClick={() => {
          selectOption(data);
        }}
      >
        <CheckBox isChecked={isSelected} />
        <div className={classNames("category-option-label", "flex items-center gap-2")}>
          <div className={classNames("truncate")}>{option.label}</div>
          {isExecEmail ? <ExecutiveLabel /> : null}
        </div>
      </div>
    );
  };
  return (
    <div className={classNames("flex items-center gap-2 default-font-size mb-4")}>
      <div className="secondary-text-color">Apply to</div>
      <CustomSelect
        className={classNames(
          "outlook-category-select",
          isEmptyArrayOrFalsey(selectedUserTokens) ? "empty-state" : "",
          "width-256px",
        )}
        classNamePrefix="dark-mode"
        closeMenuOnSelect={false}
        components={{
          DropdownIndicator,
          MultiValue: (props) => {
            return <SelectedCalendarLabel {...props} selectedUserTokens={selectedUserTokens} />;
          },
          Option: renderOption,
        }}
        hideSelectedOptions={false}
        inputId="tag-select-calendar-vimcal-ea"
        isSearchable={false}
        onChange={handleChange}
        openMenuOnFocus={true}
        options={options}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
        })}
        tabSelectsValue={false}
        placeholder="calendar"
        value={options.filter((option) => {
          if (option.label === MY_CALENDAR_TAG_LABEL && isEmptyArrayOrFalsey(selectedUserTokens)) {
            return true;
          }
          return selectedUserTokens.includes(option.value);
        })}
        filterOption={(
          option,
          rawInput,
        ) => {
          return lowerCaseAndTrimString(option.data.label)
            .includes(lowerCaseAndTrimString(rawInput));
        }}
        isMulti
        isClearable={false} // this removes the x button
      />
    </div>
  );
}

function SelectedCalendarLabel(param) {
  const {
    data,
    selectedUserTokens,
    index,
    options,
  } = param;
  const {
    value,
  } = data;
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const matchingUser = allLoggedInUsers.find((user) => getUserToken(user) === value);
  const allTokensInOptions = (options || []).map((option) => option.value);
  const filteredLength = selectedUserTokens.filter((token) => allTokensInOptions.includes(token));

  if (filteredLength.length === 0 && data.label === MY_CALENDAR_TAG_LABEL) {
    return (
      <div className="default-font-size">{MY_CALENDAR_TAG_LABEL}</div>
    );
  }
  if (filteredLength.length === 1 && data.value === filteredLength[0]) {
    return (
      <div className="default-font-size">{`${data.label}${matchingUser ? "" : " (expired)"}`}</div>
    );
  }
  if (index > 0) {
    // otherwise, we're going to show multiple versions of `n calendars`
    return null;
  }
  const filteredCalendarLength = filteredLength.length;
  return (
    <div>{`${filteredCalendarLength} ${pluralize(filteredCalendarLength, "calendar")}`}</div>
  );
}
