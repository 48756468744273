import create from "zustand";
import { persist } from "zustand/middleware";

// TODO: Probably safer to use null instead of an empty object as the empty state.
interface TeamPlanState {
  teamPlan: TeamPlan | Record<string, never>,
  setTeamPlan: (teamPlan: TeamPlan | Record<string, never>) => void
  resetTeamPlan: () => void
}

export const useTeamPlan = create(
  persist<TeamPlanState>(
    (set) => ({
      teamPlan: {},
      setTeamPlan: (teamPlan) => {
        set(() => ({ teamPlan: teamPlan }));
      },
      resetTeamPlan: () => {
        set(() => ({ teamPlan: {} }));
      },
    }),
    {
      name: "team-plan-storage", // unique name
    },
  ),
);

interface UserCodes {
  affiliate: string | null
  attribution: string | null
  from: string | null
  promo: string | null
  referral: string | null // TODO: Confirm
  utmCampaign: string | null
  utmMedium: string | null
  utmSource: string | null // TODO: Confirm
}

interface UserCodesState extends UserCodes {
  hasSetCodes: boolean
  setAffiliate: (affiliate: string) => void
  setReferral: (referral: string) => void
  setUtmSource: (utmSource: string) => void
  setPromo: (promo: string) => void
  setUserCodes: (userCodes: Partial<UserCodes>) => void
  resetUserCodes: () => void
}

// referral -> referral_code=CODE
// source -> utm_source=something
// promo -> d=CODE
// affiliate -> via=CODE
export const useUserCodes = create(
  persist<UserCodesState>(
    (set) => ({
      hasSetCodes: false,
      referral: null,
      setReferral: (referral) => {
        set(() => ({ referral }));
      },
      utmSource: null,
      setUtmSource: (utmSource) => {
        set(() => ({ utmSource }));
      },
      promo: null,
      setPromo: (promo) => {
        set(() => ({ promo }));
      },
      affiliate: null,
      setAffiliate: (affiliate) => {
        set(() => ({ affiliate }));
      },
      from: null,
      utmMedium: null,
      utmCampaign: null,
      attribution: null,
      setUserCodes: (userCodes) => {
        if (!userCodes) {
          return;
        }

        const {
          referral,
          utmSource,
          promo,
          affiliate,
          attribution,
          utmMedium,
          utmCampaign,
          from,
        } = userCodes;

        set((state) => ({
          hasSetCodes: true,
          referral: referral || state.referral,
          utmSource: utmSource || state.utmSource,
          promo: promo || state.promo,
          affiliate: affiliate || state.affiliate,
          attribution: attribution || state.attribution,
          utmMedium: utmMedium || state.utmMedium,
          utmCampaign: utmCampaign || state.utmCampaign,
          from: from || state.from,
        }));
      },
      resetUserCodes: () => {
        set(() => ({
          referral: null,
          utmSource: null,
          promo: null,
          affiliate: null,
          utmMedium: null,
          utmCampaign: null,
          attribution: null,
          hasSetCodes: false,
        }));
      },
    }),
    {
      name: "user-codes-storage", // unique name
    },
  ),
);

interface UserTimeZoneState {
  userTimeZoneIndex: Record<string, string>;
  userTimeZoneLastSetIndex: Record<string, string>;
  setUserTimeZoneIndex: (userTimeZoneIndex: Record<string, string>) => void;
  setUserTimeZoneLastSetIndex: (userTimeZoneLastSetIndex: Record<string, string>) => void;
  resetUserTimeZoneIndex: () => void;
}

export const useUserTimeZoneIndexStore = create<UserTimeZoneState>((set) => ({
  userTimeZoneIndex: {
    // "john@vimcal.com": "Asia/Kolkata", // TODO: delete
    // "mike@vimcal.com": "America/New_York", // TODO: delete
    // "kenny@vimcal.com": "America/New_York", // TODO: delete
  }, // {email: timezone}
  setUserTimeZoneIndex: (userTimeZoneIndex) => {
    set(() => ({ userTimeZoneIndex }));
  },
  userTimeZoneLastSetIndex: {}, // {email: isoString}
  setUserTimeZoneLastSetIndex: (userTimeZoneLastSetIndex) => {
    set(() => ({ userTimeZoneLastSetIndex }));
  },
  resetUserTimeZoneIndex: () => {
    set(() => ({ userTimeZoneIndex: {}, userTimeZoneLastSetIndex: {} }));
  },
}));
