import React, { PureComponent } from "react";
import StyleConstants, {
  ALL_BOOKING_VARIABLES,
} from "../services/globalVariables";
import {
  createInputTextBlock,
  extractTextFromHtml,
  findCursorPositionOnChange,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ENTER,
  KEYCODE_ESCAPE,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  createDefaultPersonalLinkEventSummary,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import { connect } from "react-redux";
import { HelpCircle } from "react-feather";
import classNames from "classnames";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import TitleVariables from "./availability/titleVariables";
import { isEmptyArray } from "../lib/arrayFunctions";
import {
  getHumanReadableBookingVariable,
  removeBookingLinkBlockBrackets,
} from "../lib/availabilityFunctions";
import { getDefaultFontColor } from "../lib/styleFunctions";
import { allowDefaultToEmptySlotsTitle } from "../lib/featureFlagFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { replaceUnixSpaceWithStringSpace, truncateString } from "../lib/stringFunctions";
import { blurCalendar } from "../services/appFunctions";

class EventTitleInput extends PureComponent {
  constructor(props) {
    super(props);

    const { masterAccount } = this.props.masterAccount;
    const getSummary = () => {
      const { summary, currentUser } = this.props;
      const defaultSummary = createDefaultPersonalLinkEventSummary({
        user: props.currentUser,
        masterAccount,
      });
      if (allowDefaultToEmptySlotsTitle(currentUser)) {
        return summary ?? defaultSummary;
      }
      return summary || defaultSummary;
    };

    this.state = {
      summary: getSummary(),
      suggestions: [],
      hoveredSuggestionIndex: -1,
      isHoveringOverShowMoreInfo: false,
    };

    this.onBlurInput = this.onBlurInput.bind(this);
    this.onChangeInputContentEditableChange =
      this.onChangeInputContentEditableChange.bind(this);
    this.onInputContentEditableKeyDown =
      this.onInputContentEditableKeyDown.bind(this);
    this.handleOnClickSuggestion = this.handleOnClickSuggestion.bind(this);
    this.onMouseEnterQuestionMark = this.onMouseEnterQuestionMark.bind(this);
    this.onMouseLeaveQuestionMark = this.onMouseLeaveQuestionMark.bind(this);
    this.addValueIntoTitle = this.addValueIntoTitle.bind(this);
    this.handleInputPaste = this.handleInputPaste.bind(this);
    this.removeTitle = this.removeTitle.bind(this);

    Broadcast.subscribe(
      `UPDATE_EVENT_INPUT_VALUE_${props.containerId}`,
      this.addValueIntoTitle
    );
    Broadcast.subscribe(
      `REMOVE_EVENT_INPUT_VALUE_${props.containerId}`,
      this.removeTitle
    );
  }

  componentDidMount() {
    this._isMounted = true;

    this.addValueIntoTitle();
    
    let element = this.getElement();

    if (!element) {
      return;
    }

    element.addEventListener("input", this.onChangeInputContentEditableChange);
    element.addEventListener("keydown", this.onInputContentEditableKeyDown);
    element.addEventListener("paste", this.handleInputPaste);
    element = null;
  }

  componentWillUnmount() {
    this._isMounted = false;

    Broadcast.unsubscribe(`UPDATE_EVENT_INPUT_VALUE_${this.props.containerId}`);
    Broadcast.unsubscribe(`REMOVE_EVENT_INPUT_VALUE_${this.props.containerId}`);

    let element = this.getElement();

    if (!element) {
      return;
    }

    element.removeEventListener(
      "input",
      this.onChangeInputContentEditableChange
    );
    element.removeEventListener("keydown", this.onInputContentEditableKeyDown);
    element.removeEventListener("paste", this.handleInputPaste);
    element = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this._isMounted) {
      // do nothing
      return;
    }
    if (prevState.summary !== this.state.summary) {
      let element = this.getElement();

      const innerText = element?.innerText || element?.textContent || "";
      const IS_DEBUG = false;

      const nameRegex = new RegExp(
        removeBookingLinkBlockBrackets(
          ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK
        ),
        "gim"
      );
      const companyRegex = new RegExp(
        removeBookingLinkBlockBrackets(
          ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME
        ),
        "gim"
      );
      IS_DEBUG && console.log("element.innerText_0", innerText, innerText.length);
      let title = replaceUnixSpaceWithStringSpace(
        innerText.replaceAll(
          nameRegex,
          ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK
        )
      );
      IS_DEBUG && console.log("title_1", title);
      title = replaceUnixSpaceWithStringSpace(
        title.replaceAll(
          companyRegex,
          ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME
        )
      );

      IS_DEBUG && console.log("on_save_title_3", title.length);
      IS_DEBUG && console.log("======");
      this.props.onSaveTitle(title);
      element = null;
    }
  }

  render() {
    return (
      <div className="position-relative">
        <div
          className={classNames(
            "personal-link-event-title",
            this.props.inputClassname
          )}
          id={this.props.containerId}
          contentEditable={true}
          suppressContentEditableWarning={true}
          ref={this.props.innerRef || this.contentEditableTitle}
          autoFocus={true}
          tabIndex={3}
          onBlurCapture={this.onBlurInput}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          style={{
            width: this.props.width,
            paddingLeft: this.props.inputPaddingLeft,
          }}
        />

        {this.renderHelp()}
        {this.renderHelpModal()}

        {this.renderSuggestions()}
      </div>
    );
  }

  renderHelpModal() {
    if (!this.state.isHoveringOverShowMoreInfo) {
      return null;
    }
    const { extraAvailableVariables } = this.props;
    return (
      <TitleVariables
        containerClassName={"right-5 top-9"}
        variables={[ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK].concat(
          extraAvailableVariables ?? []
        )}
      />
    );
  }

  renderSuggestions() {
    if (this.props.hideAllSuggestions) {
      return null;
    }
    const { suggestions } = this.state;
    if (isEmptyArray(suggestions)) {
      return null;
    }
    const getBackgroundColor = (index) => {
      if (index === this.state.hoveredSuggestionIndex) {
        return this.props.isDarkMode
          ? StyleConstants.darkModeHoverBackgroundColor
          : "#F2F3F4";
      }
      return undefined;
    };
    return (
      <div
        className="autocomplete-dropdown-container margin-top-ten"
        style={{
          marginLeft: 0,
          maxHeight: 180,
          overflowY: "auto",
          width: this.props.width || 320,
        }}
      >
        <div
          className="location-suggestion-item"
          style={{ width: this.props.width || 330, fontWeight: 400 }}
        >
          Available variables
        </div>

        {suggestions.map((suggestion, index) => {
          return (
            <div
              className="location-suggestion-item"
              style={{
                backgroundColor: getBackgroundColor(index),
                width: this.props.width || 330,
              }}
              key={`attendee_suggestion_${suggestion.index}`}
              onMouseEnter={() => {
                this.setState({ hoveredSuggestionIndex: index });
              }}
              onMouseLeave={() => this.setState({ hoveredSuggestionIndex: -1 })}
              onClick={() => this.handleOnClickSuggestion(suggestion)}
              // ref={this.state.refs[suggestion.key]}
            >
              <span>{truncateString(suggestion.description, 50)}</span>
            </div>
          );
        })}
      </div>
    );
  }

  renderHelp() {
    if (this.props.hideHint) {
      return null;
    }
    return (
      <div
        className="question-mark-show-more"
        onMouseEnter={this.onMouseEnterQuestionMark}
        onMouseLeave={this.onMouseLeaveQuestionMark}
      >
        <HelpCircle className="clickable-icon" size={18} />
      </div>
    );
  }

  onInputContentEditableKeyDown(event) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
    const { suggestions, hoveredSuggestionIndex } = this.state;

    if (
      [
        KEYCODE_ENTER,
        KEYCODE_TAB,
        KEYCODE_UP_ARROW,
        KEYCODE_DOWN_ARROW,
        KEYCODE_ESCAPE,
      ].includes(event.keyCode)
    ) {
      if (event.keyCode === KEYCODE_ENTER || event.keyCode === KEYCODE_TAB) {
        event.preventDefault();
        this.onKeySelectValue(event);
      }

      if (event.keyCode === KEYCODE_ESCAPE) {
        if (suggestions?.length > 0) {
          this.setState({ suggestions: [], refs: {}, replaceString: "" });
        } else {
          this.onKeyDownEscape();
        }
      }

      if (event.keyCode === KEYCODE_UP_ARROW) {
        event.preventDefault();
        if (hoveredSuggestionIndex < 0) {
          this.setState({
            hoveredSuggestionIndex: 0,
          });
        } else if (hoveredSuggestionIndex === 0) {
          this.setState({
            hoveredSuggestionIndex: suggestions.length - 1, // go to last element
          });
        } else {
          this.setState({
            hoveredSuggestionIndex: hoveredSuggestionIndex - 1, // go up one
          });
        }
      }

      if (event.keyCode === KEYCODE_DOWN_ARROW) {
        event.preventDefault();
        if (hoveredSuggestionIndex < 0) {
          this.setState({
            hoveredSuggestionIndex: suggestions.length - 1, //last element
          });
        } else if (hoveredSuggestionIndex === suggestions.length - 1) {
          this.setState({
            hoveredSuggestionIndex: 0, // go to first element
          });
        } else {
          this.setState({
            hoveredSuggestionIndex: hoveredSuggestionIndex + 1, // go up one
          });
        }
      }
    }
  }

  onKeySelectValue(e) {
    //if key is up, down, enter, or tab -> use default key
    const { suggestions, hoveredSuggestionIndex } = this.state;
    if (isEmptyArray(suggestions)) {
      this.onKeyDownEscape();
      return;
    }

    const value = this.state.suggestions[hoveredSuggestionIndex];
    if (!value) {
      return;
    }
    this.handleOnClickSuggestion(value);
  }

  onKeyDownEscape() {
    blurCalendar();
  }

  onChangeInputContentEditableChange(e) {
    if (!this._isMounted) {
      return;
    }

    let element = this.getElement();

    if (!element) {
      return;
    }

    const DEBUG = false;
    let updatedSummary = element.innerText;
    element = null;

    DEBUG && console.log("updatedSummary", updatedSummary);
    let updatedState = { summary: updatedSummary };

    const changeStartedIndex = findCursorPositionOnChange(
      this.state.summary,
      updatedSummary
    );

    // text up to cursor
    const upToText = updatedSummary.substr(0, changeStartedIndex + 1);
    // console.log('upToText', upToText);

    // get last word before space
    const textArray = upToText.split(" ");
    const lastWord = textArray[textArray.length - 1] ?? "";
    DEBUG && console.log("lastWord_", lastWord);

    // check if invitee_name starts with last word
    const lastWordInInviteeNameBlockIndex =
      ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK.toLowerCase().indexOf(
        lastWord.toLowerCase()
      );
    const lastWordCompanyBlockIndex =
      ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME.toLowerCase().indexOf(
        lastWord.toLowerCase()
      );
    DEBUG &&
      console.log("lastWordCompanyBlockIndex_", lastWordCompanyBlockIndex);

    if (
      lastWord &&
      (lastWordInInviteeNameBlockIndex === 0 ||
        lastWordInInviteeNameBlockIndex === 2 ||
        lastWordCompanyBlockIndex === 0 ||
        lastWordCompanyBlockIndex === 2)
    ) {
      DEBUG && console.log("in set_0");
      updatedState.replaceString = lastWord;
      updatedState.suggestions = this.getAllVariables();
    } else if (e.data === "{") {
      DEBUG && console.log("in set_1");
      updatedState.replaceString = "{";
      updatedState.suggestions = this.getAllVariables();
    } else {
      DEBUG && console.log("in set_2");
      updatedState.replaceString = "";
      updatedState.suggestions = [];
    }

    if (!isEmptyArray(updatedState.suggestions)) {
      updatedState.hoveredSuggestionIndex = 0;
    }

    DEBUG && console.log("updatedState", updatedState);

    this.setState(updatedState);
  }

  getAllVariables() {
    let variables = [
      {
        value: ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK,
        description: getHumanReadableBookingVariable(
          ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK
        ),
        index: 0,
      },
    ];
    if (!isEmptyArray(this.props.extraAvailableVariables)) {
      this.props.extraAvailableVariables.forEach((variable, index) => {
        if (variable === ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME) {
          variables = variables.concat([
            {
              value: ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME,
              description: getHumanReadableBookingVariable(variable),
              index: index + 1,
            },
          ]);
        }
      });
    }
    return variables;
  }

  onBlurInput() {
    if (this.state.hoveredSuggestionIndex < 0) {
      this.setState({
        suggestions: [],
        replaceString: "",
      });
    }
  }

  getElement() {
    return document.getElementById(this.props.containerId);
  }

  handleOnClickSuggestion(suggestion) {
    const IS_DEBUG = false;
    IS_DEBUG && console.log("suggestion_", suggestion);
    let element = this.getElement();

    if (isEmptyObjectOrFalsey(suggestion) || !element) {
      IS_DEBUG && console.log("suggestion is empty");
      element = null;
      return;
    }

    let updatedSummary = element.innerHTML;

    const displayBlockInviteeName = createInputTextBlock(
      removeBookingLinkBlockBrackets(suggestion?.value),
      this.props.isDarkMode,
      this.determineTextContainerColor(),
      true
    );
    IS_DEBUG &&
      console.log("displayBlockInviteeName_", displayBlockInviteeName);
    updatedSummary = updatedSummary.replace(
      this.state.replaceString,
      displayBlockInviteeName
    );

    element.innerHTML = updatedSummary;

    let updatedSummaryText = extractTextFromHtml(updatedSummary);

    const setCaretToEnd = () => {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(element);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
      range.detach(); // optimization

      // set scroll to the end if multiline
      element.scrollTop = element.scrollHeight;
    };

    setCaretToEnd(element);
    element = null;

    this.setState({
      summary: updatedSummaryText,
      replaceString: "",
      suggestions: [],
    });
  }

  determineTextContainerColor() {
    return getDefaultFontColor(this.props.isDarkMode);
  }

  removeTitle() {
    let element = this.getElement();
    if (!element) {
      return;
    }
    element.innerHTML = "";
    element = null;
    this.setState({ summary: "" });
  }

  addValueIntoTitle(newSummary) {
    const IS_DEBUG = false;
    let element = this.getElement();
    if (!element) {
      return;
    }

    let summary = replaceUnixSpaceWithStringSpace(
      newSummary ?? this.state.summary
    );

    IS_DEBUG && console.log("summary_0", summary);
    const displayBlockInviteeName = createInputTextBlock(
      removeBookingLinkBlockBrackets(ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK),
      this.props.isDarkMode,
      this.determineTextContainerColor(),
      true
    );
    const displayBlockCompanyName = createInputTextBlock(
      removeBookingLinkBlockBrackets(
        ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME
      ),
      this.props.isDarkMode,
      this.determineTextContainerColor(),
      true
    );

    const inviteeNameWithSpace = ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK + " ";
    const inviteeCompanyWithSpace =
      ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME + " ";

    // summary = summary.replace(
    //   new RegExp(inviteeNameWithSpace, "gmi"),
    //   displayBlockInviteeName
    // );
    // summary = summary.replace(
    //   new RegExp(inviteeCompanyWithSpace, "gmi"),
    //   displayBlockCompanyName
    // );
    IS_DEBUG && console.log("summary_1", summary);

    summary = summary.replace(
      new RegExp(ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK, "gmi"),
      displayBlockInviteeName
    );
    summary = summary.replace(
      new RegExp(ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME, "gmi"),
      displayBlockCompanyName
    );
    IS_DEBUG && console.log("summary_2", summary);

    const nameRegex = new RegExp(
      `(\\s|\\b)${ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK}\\s`,
      "gim"
    );
    const companyRegex = new RegExp(
      `(\\s|\\b)${ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME}\\s`,
      "gim"
    );
    summary = summary.replace(nameRegex, displayBlockInviteeName);
    summary = summary.replace(companyRegex, displayBlockCompanyName);
    IS_DEBUG && console.log("summary_3", summary);

    element.innerHTML = summary;
    element = null;

    const updatedSummary = extractTextFromHtml(summary);
    IS_DEBUG && console.log("updatedSummary_", updatedSummary);

    this.setState({ summary: updatedSummary });
  }

  onMouseEnterQuestionMark() {
    this.setState({ isHoveringOverShowMoreInfo: true });
  }

  onMouseLeaveQuestionMark() {
    this.setState({ isHoveringOverShowMoreInfo: false });
  }

  handleInputPaste(e) {
    e.preventDefault();
    let element = this.getElement();

    if (!element) {
      return;
    }

    // get text representation of clipboard
    const plainText = (e.originalEvent || e).clipboardData.getData(
      "text/plain"
    );

    // insert text manually
    document.execCommand("insertHTML", false, plainText);
    element = null;
  }
}

function mapStateToProps(state) {
  const { isDarkMode, currentUser } = state;

  return {
    isDarkMode,
    currentUser,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} />;
};

export default connect(mapStateToProps, null)(withStore(EventTitleInput));
