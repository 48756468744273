import React, { useState } from "react";
import { useSelector } from "react-redux";
import SettingsEventsContent from "./settingsEventsContent";
import CustomSelect from "../../../select";
import classNames from "classnames";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../../services/stores/SharedAccountData";
import { isInt } from "../../../../services/commonUsefulFunctions";
import { getReactSelectBaseStyle } from "../../../select/styles";
import { format } from "date-fns";
import {
  GOOGLE_DMY_FORMAT,
  GOOGLE_MDY_FORMAT,
  GOOGLE_YMD_FORMAT,
} from "../../../../services/googleCalendarService";
import SelectTheme from "./selectTheme";
import { PREFERENCES_SETTINGS_ID } from "../../../../lib/vimcalVariables";
import AnchorTimeZoneModal from "../../../anchorTimeZoneModal";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import DefaultGuestPermissions from "./defaultGuestPermissions";
import WorkHours from "./workHours";
import DefaultMeetingLength from "./defaultMeetingLength";
import SettingsSubtitle from "../../common/settingsSubtitle";
import {
  getAccountDateFormat,
  getAccountStartOfWeek,
  getDefaultMeetingLength,
} from "../../../../lib/settingsFunctions";
import { SelectUser, getAllUsers } from "../../common/selectUser";
import {
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../../../../services/maestroFunctions";
import { shouldDisplayColorLabel } from "../../../../lib/featureFlagFunctions";
import ColorLabelSetting from "./colorLabelSetting";
import { getSelectedUserIndex } from "../../settingFunctions";
import SettingAlwaysSendAsEA from "./settingAlwaysSendAsEA";
import InternalDomainsSettingsContainer from "./internalDomainsSettingsContainer";

const SHARED_CLASSNAME =
  "default-font-size user-select-none flex items-center w-full justify-between";

const CURRENT_DATE = new Date();
const DATE_FORMAT = [
  {
    value: GOOGLE_DMY_FORMAT,
    label: getSelectValueForDateFormat(GOOGLE_DMY_FORMAT),
  },
  {
    value: GOOGLE_MDY_FORMAT,
    label: getSelectValueForDateFormat(GOOGLE_MDY_FORMAT),
  },
  {
    value: GOOGLE_YMD_FORMAT,
    label: getSelectValueForDateFormat(GOOGLE_YMD_FORMAT),
  },
];
const LABEL_CLASSNAME = "secondary-text-color";

export default function UserPreferences({ initialSettingsUser }) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const allUsers = getAllUsers({
    currentUser,
    allLoggedInUsers,
    masterAccount,
  });
  const [selectedUserIndex, setSelectedUserIndex] = useState(
    getSelectedUserIndex(initialSettingsUser, allUsers)
  );
  const selectedUser = allUsers[selectedUserIndex]?.value;
  const isUpdatingExecutiveProfile = isUserDelegatedUser(selectedUser);

  const renderSelectedUser = () => {
    if (!isUserMaestroUser(masterAccount)) {
      return null;
    }

    const SELECT_USER_ID = "general-settings-select-user-id";

    return (
      <SelectUser
        setSelectedUserIndex={setSelectedUserIndex}
        id={SELECT_USER_ID}
        selectedUser={selectedUser}
        inputContainerClassName="settings-select-user-position"
        addExecutiveLabel={true}
        showAccountLabel={true}
      />
    );
  };

  return (
    <div className="w-full pr-5">
      {renderSelectedUser()}
      <SettingsSubtitle
        id={PREFERENCES_SETTINGS_ID.THEME}
        labelClassName="mb-4"
      >
        Theme
      </SettingsSubtitle>
      <SelectTheme containerClassName="mt-4" />

      <SettingsSubtitle id={PREFERENCES_SETTINGS_ID.TIME_ZONE}>
        Time Zone
      </SettingsSubtitle>
      <AnchorTimeZoneModal
        selectedUser={selectedUser}
        isUpdatingExecutiveProfile={isUpdatingExecutiveProfile}
      />

      <SettingsSubtitle id={PREFERENCES_SETTINGS_ID.DISPLAY}>
        Display
      </SettingsSubtitle>
      {renderStartOfWeek({
        onChange: (option) => {
          const updatedStartOfWeek = parseInt(option.value);
          const updatedSettings = { start_of_week: updatedStartOfWeek };
          updateMasterAccountSettingsForFrontendAndBackend({
            masterAccount,
            updatedSettings,
          });
        },
        value: getSelectStartOfWeek(getAccountStartOfWeek({ masterAccount })),
        isDarkMode,
      })}
      {renderDateFormat({
        onChange: (option) => {
          const updatedSettings = { date_format: option.value };
          updateMasterAccountSettingsForFrontendAndBackend({
            masterAccount,
            updatedSettings,
            user: selectedUser,
          });
        },
        value: {
          value: getAccountDateFormat({ masterAccount, user: selectedUser }),
          label: getSelectValueForDateFormat(
            getAccountDateFormat({ masterAccount, user: selectedUser }),
          ),
        },
        isDarkMode,
      })}
      <WorkHours
        isUpdatingExecutiveProfile={isUpdatingExecutiveProfile}
        selectedUser={selectedUser}
      />

      <SettingsSubtitle id={PREFERENCES_SETTINGS_ID.EVENTS}>
        Events
      </SettingsSubtitle>
      <SettingsEventsContent selectedUser={selectedUser} />
      {isUserMaestroUser(masterAccount) ? <SettingAlwaysSendAsEA /> : null}
      <DefaultMeetingLength
        onSet={(value) => {
          const formattedValue = parseInt(value);
          if (
            !isInt(formattedValue) ||
            getDefaultMeetingLength({ masterAccount }) === formattedValue
          ) {
            return;
          }

          const updatedSettings = {
            default_meeting_duration: value,
          };
          updateMasterAccountSettingsForFrontendAndBackend({
            masterAccount,
            updatedSettings,
            isUpdatingExecutiveProfile,
            user: selectedUser,
          });
        }}
        value={getDefaultMeetingLength({ masterAccount, user: selectedUser })}
        containerClassName={SHARED_CLASSNAME}
      />
      <>
        <SettingsSubtitle id={PREFERENCES_SETTINGS_ID.INTERNAL_DOMAINS}>
          Internal Domains and Emails
        </SettingsSubtitle>
        <InternalDomainsSettingsContainer selectedUser={selectedUser} />
      </>
      {shouldDisplayColorLabel({ user: selectedUser }) ? (
        <>
          <SettingsSubtitle id={PREFERENCES_SETTINGS_ID.COLOR_LABEL_ID}>
            Color Labels
          </SettingsSubtitle>
          <ColorLabelSetting selectedUser={selectedUser} />
        </>
      ) : null}

      <SettingsSubtitle>Event Permissions</SettingsSubtitle>
      <DefaultGuestPermissions />
    </div>
  );
}

function renderDateFormat({ onChange, value, isDarkMode }) {
  return (
    <div className={classNames(SHARED_CLASSNAME, "my-2")}>
      <div className={LABEL_CLASSNAME}>Date format</div>
      <CustomSelect
        value={value}
        classNamePrefix="dark-mode-modal"
        options={DATE_FORMAT}
        onChange={onChange}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          controlWidth: "110px",
        })}
      />
    </div>
  );
}

function renderStartOfWeek({ onChange, value, isDarkMode }) {
  return (
    <div className={SHARED_CLASSNAME}>
      <div className={LABEL_CLASSNAME}>Start of week</div>
      <CustomSelect
        value={value}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          controlWidth: "100px",
        })}
        classNamePrefix="dark-mode-modal"
        options={[
          {
            label: "Monday",
            value: "1",
          },
          {
            label: "Sunday",
            value: "0",
          },
          {
            label: "Saturday",
            value: "6",
          },
        ]}
        onChange={onChange}
      />
    </div>
  );
}

function getSelectStartOfWeek(value) {
  if (value === 0 || value === "0") {
    return { value: "0", label: "Sunday" };
  } else if (value === 6 || value === "6") {
    return { value: "6", label: "Saturday" };
  } else if (value === 1 || value === "1") {
    return { value: "1", label: "Monday" };
  }
}

function getSelectValueForDateFormat(dateFormat) {
  switch (dateFormat) {
    case GOOGLE_DMY_FORMAT:
      return format(CURRENT_DATE, "d/M/y");
    case GOOGLE_MDY_FORMAT:
      return format(CURRENT_DATE, "M/d/y");
    case GOOGLE_YMD_FORMAT:
      return format(CURRENT_DATE, "y/M/d");
    default:
      return format(CURRENT_DATE, "M/d/y");
  }
}
