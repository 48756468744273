import _ from "underscore";
import { randomNumber } from "../../../services/randomFunctions";
import { type ZoomSchedulersState } from "../../../services/stores/SharedAccountData";
import { formatEmail, isSameEmail, lowerCaseAndTrimString, lowerCaseAndTrimStringWithGuard } from "../../../lib/stringFunctions";
import { getObjectEmail } from "../../../lib/objectFunctions";

export const ZOOM_AUDIO_OPTIONS = {
  BOTH: "both",
  TELEPHONY: "telephony",
  VOIP: "voip",
} as const;

export const ZOOM_AUTO_RECORDING_OPTIONS = {
  NONE: "none",
  LOCAL: "local",
  CLOUD: "cloud",
} as const;

export const ZOOM_MEETING_TYPES = {
  INSTANT: 1,
  SCHEDULED: 2,
  UNFIXED_RECURRING: 3,
  PMI: 4,
  FIXED_RECURRING: 8,
  SCREEN_SHARE_ONLY: 10,
} as const;

export type ZoomSettingsState = {
  audio: ZoomMeeting["settings"]["audio"]
  autoRecording: ZoomMeeting["settings"]["auto_recording"]
  autoStartMeetingSummary: ZoomMeeting["settings"]["auto_start_meeting_summary"]
  autoStartAICompanionQuestions: ZoomMeeting["settings"]["auto_start_ai_companion_questions"]
  continuousMeetingChat: ZoomMeeting["settings"]["continuous_meeting_chat"]["enable"]
  hostVideo: ZoomMeeting["settings"]["host_video"]
  joinBeforeHost: ZoomMeeting["settings"]["join_before_host"]
  meetingAuthentication: ZoomMeeting["settings"]["meeting_authentication"]
  muteUponEntry: ZoomMeeting["settings"]["mute_upon_entry"]
  participantVideo: ZoomMeeting["settings"]["participant_video"]
  password: ZoomMeeting["password"]
  usePMI: ZoomMeeting["settings"]["use_pmi"]
  waitingRoom: ZoomMeeting["settings"]["waiting_room"]
}

export function getInitialValues(zoomMeeting: ZoomMeeting): ZoomSettingsState {
  return {
    audio: zoomMeeting?.settings?.audio ?? ZOOM_AUDIO_OPTIONS.BOTH,
    autoRecording: zoomMeeting?.settings?.auto_recording ?? ZOOM_AUTO_RECORDING_OPTIONS.NONE,
    autoStartMeetingSummary: zoomMeeting?.settings?.auto_start_meeting_summary ?? false,
    autoStartAICompanionQuestions: zoomMeeting?.settings?.auto_start_ai_companion_questions ?? false,
    continuousMeetingChat: zoomMeeting?.settings?.continuous_meeting_chat?.enable ?? false,
    hostVideo: zoomMeeting?.settings?.host_video ?? false,
    joinBeforeHost: zoomMeeting?.settings?.join_before_host ?? false,
    meetingAuthentication: zoomMeeting?.settings?.meeting_authentication ?? false,
    muteUponEntry: zoomMeeting?.settings?.mute_upon_entry ?? false,
    participantVideo: zoomMeeting?.settings?.participant_video ?? false,
    password: zoomMeeting?.password ?? "",
    usePMI: zoomMeeting?.settings?.use_pmi ?? false,
    waitingRoom: zoomMeeting?.settings?.waiting_room ?? false,
  };
}

export function buildPartialZoomMeetingFromSettings(updatedSettings: ZoomSettingsState, hostEmail: string): DeepPartial<ZoomMeeting> & { schedule_for: string } {
  if (updatedSettings.usePMI) {
    return {
      schedule_for: hostEmail,
      settings: {
        use_pmi: updatedSettings.usePMI,
      },
    };
  }

  return {
    password: updatedSettings.password,
    schedule_for: hostEmail,
    settings: {
      audio: updatedSettings.audio,
      auto_recording: updatedSettings.autoRecording,
      auto_start_meeting_summary: updatedSettings.autoStartMeetingSummary,
      auto_start_ai_companion_questions: updatedSettings.autoStartAICompanionQuestions,
      continuous_meeting_chat: {
        enable: updatedSettings.continuousMeetingChat,
      },
      host_video: updatedSettings.hostVideo,
      join_before_host: updatedSettings.joinBeforeHost,
      meeting_authentication: updatedSettings.meetingAuthentication,
      mute_upon_entry: updatedSettings.muteUponEntry,
      participant_video: updatedSettings.participantVideo,
      use_pmi: updatedSettings.usePMI,
      waiting_room: updatedSettings.waitingRoom,
    },
  };
}

export function haveSettingsChanged(zoomMeeting: ZoomMeeting, updatedSettings: ZoomSettingsState) {
  return !_.isEqual(getInitialValues(zoomMeeting), updatedSettings);
}

/**
 * Generate a 6-digit random number, including leading zeroes.
 */
export function generateNewPassword() {
  const passwordInt = randomNumber(0, 999999);
  return passwordInt.toString(10).padStart(6, "0");
}

/**
 * Make the Zoom meeting ID more readable by adding spaces so digits are in groups of
 * 3 or 4. Zoom meeting IDs are either 9, 10, or 11 digits long per their docs. If
 * a number with a different length is provided, do not make any changes.
 *
 * Example outputs:
 * - `123 456 789`
 * - `123 456 7890`
 * - `123 4567 8901`
 * @see https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0065196
 */
export function makeZoomIdReadable(id: number) {
  let idString = id.toString(10);
  let spaceIndeces: number[] = [];

  if (idString.length === 9) {
    spaceIndeces = [6, 3];
  } else if (idString.length === 10) {
    spaceIndeces = [6, 3];
  } else if (idString.length === 11) {
    spaceIndeces = [7, 3];
  }

  for (const index of spaceIndeces) {
    idString = idString.slice(0, index) + " " + idString.slice(index);
  }
  return idString;
}

export function extractMeetingIdFromURL(urlString: string | null | undefined) {
  if (!urlString) {
    return null;
  }
  if (!lowerCaseAndTrimStringWithGuard(urlString).includes("zoom")) {
    return null;
  }

  try {
    // When a personal meeting link is used for a scheduled meeting, the `omn` param is
    // included for the ID of that individual meeting.
    // @see https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0066271
    const url = new URL(urlString);
    const zoomOMN = parseInt(url.searchParams.get("omn") || "", 10);
    if (!isNaN(zoomOMN)) {
      return zoomOMN;
    }
  } catch {
    // No-op.
  }

  const regex = /\d{9,}/;
  const results = regex.exec(urlString);

  if (!results) {
    return null;
  }

  const meetingId = parseInt(results[0], 10);

  // Shouldn't be possible since the regex only matches digits, but just in case.
  if (isNaN(meetingId)) {
    return null;
  }

  return meetingId;
}

export function getAuthedZoomEmail(schedulers: ZoomSchedulersState["schedulers"], email: string | null | undefined) {
  let delegatedEmail: string | null = null;

  if (!email) {
    return null;
  }

  for (const scheduler of Object.values(schedulers)) {
    // If all_schedulers failed to update then we skip the scheduler
    if (scheduler.error) {
      continue;
    }
    // Fallback is only for if users have schedulers persisted before the host_email
    // field was included. The schedulers are refetched on load, so this shouldn't
    // be an issue to begin with.
    if (isSameEmail(email, scheduler?.host_email || getObjectEmail(scheduler))) {
      return formatEmail(email);
    }
    if (scheduler?.schedulers?.some(s => isSameEmail(email, getObjectEmail(s)))) {
      delegatedEmail = getObjectEmail(scheduler) ?? null;
    }
  }

  return formatEmail(delegatedEmail) || null;
}

export function isZoomMeetingPMI(zoomMeeting: ZoomMeeting) {
  try {
    return zoomMeeting.type === ZOOM_MEETING_TYPES.PMI || zoomMeeting.settings.use_pmi;
  } catch {
    return false;
  }
}

/**
 * TODO: Combine with stringIncludesZoomURLCombinations.
 *
 * @deprecated Do not use for now, does not check all cases.
 */
export function isZoomJoinURL(url: string) {
  return /zoom.us\/j\//i.test(url);
}
