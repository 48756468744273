import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import MaestroCard from "./maestroCard";
import { MAESTRO_ACCOUNT_TYPES, MAESTRO_NEW_USER_PAGES } from "../../../services/globalMaestroVariables";
import { NEW_EA_LOGIN_PATH } from "../../../services/maestro/maestroRouting";
import { canUseMagicLinks } from "../../../lib/featureFlagFunctions";

const CardSelectTwo = ({ history, setMaestroAuthPage }) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <>
      {/* No credentials / limited access */}
      <MaestroCard
        bluePillText="Quickest option"
        onClick={() => setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.CONVERT_USER_FORM)}
        text="No, let me start without involving them"
      />
      {/* Gate Magic Link for internal.  Show Full Access for everyone else */}
      {
        canUseMagicLinks(currentUser) ? (
          <MaestroCard
            onClick={() => setMaestroAuthPage(MAESTRO_NEW_USER_PAGES.MAGIC_LINK_FORM)}
            shouldAddLeftMargin={true}
            text="Yes, connect to their account."
          />
        ) : (
          <MaestroCard
            onClick={() => history.push(`/${NEW_EA_LOGIN_PATH}?accountType=` + MAESTRO_ACCOUNT_TYPES.SCHEDULER_FOR_OTHERS)}
            shouldAddLeftMargin={true}
            text="I have their email and password"
          />
        )
      }
    </>
  );
};

export default withRouter(CardSelectTwo);
