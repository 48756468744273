import React, { Component } from "react";
import { EA_COUNT_ROUND_ROBIN_OPTIONS } from "../onboarding/sharedFunctions";

const CALENDLY_LINK_WITHOUT_REROUTING =
  "https://calendly.com/vimcal/onboarding?hide_gdpr_banner=1";
export const CALENDLY_LINK_FOR_EA =
  "https://calendly.com/vimcal/vimcal-ea-onboarding?hide_gdpr_banner=1";
export const CALENDLY_LINK_FOR_EA_DAVIDS = "https://calendly.com/d/cm9z-5zs-35s/vimcal-ea-intro-call?month=2025-03";

export default class CalendlyBookingPage extends Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    script.setAttribute("async", true);
    head.appendChild(script);
  }

  getCalendlyLink() {
    const { isEA, numberOfExecutiveAssistants } = this.props;
    if (isEA) {
      if (EA_COUNT_ROUND_ROBIN_OPTIONS.includes(numberOfExecutiveAssistants)) {
        return CALENDLY_LINK_FOR_EA;
      } else {
        return CALENDLY_LINK_FOR_EA_DAVIDS;
      }
    } else {
      return CALENDLY_LINK_WITHOUT_REROUTING;
    }
  }

  render() {
    return (
      <div
        className="calendly-inline-widget"
        data-url={this.getCalendlyLink()}
        style={{ minWidth: "320px", height: "686px", marginTop: "-38px" }}
      />
    );
  }
}
