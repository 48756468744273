import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import broadcast from "../../broadcasts/broadcast";
import { useTutorialWizard } from "../../services/stores/appFunctionality";
import { Check, ChevronLeft, Minus, X } from "react-feather";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getAccountCompletedToolTips } from "../../lib/userFunctions";
import { tooltipKeys } from "../../services/tooltipVariables";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { ONBOARDING_GLOW_TYPE } from "../onboarding/sharedFunctions";
import { TUTORIAL_WIZARD_MODAL_ID } from "../../services/elementIDVariables";
import { hasUserCompletedAllTutorial } from "../../lib/tracking/onboardingTracking";
import ShortcutHoverHint from "../shortcutHoverHint";
import { BOX_SHADOW, getDefaultFontColor } from "../../lib/styleFunctions";

const CONTAINER_CLASS_NAME = "p-5";

const CONTENT_TYPE = {
  HOME: "home",
  SLOTS: "slots",
  TIME_TRAVEL: "timeTravel",
  NLP: "nlp",
};

export default function TutorialWizard() {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const minimizeTutorialWizard = useTutorialWizard(
    (state) => state.minimizeTutorialWizard
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const completedToolTips = getAccountCompletedToolTips(masterAccount);
  const [contentType, setContentType] = useState(CONTENT_TYPE.HOME);
  const removeTutorialWizard = useTutorialWizard(
    (state) => state.removeTutorialWizard
  );
  const onClickBack = () => {
    setContentType(CONTENT_TYPE.HOME);
  };

  useEffect(() => {
    return () => {
      // remove all glows
      mainCalendarBroadcast.publish("REMOVE_GLOW_ON_BUTTON");
      broadcast.publish("REMOVE_GUTTER_FLASH");
    };
  }, []);

  const renderContent = () => {
    switch (contentType) {
      case CONTENT_TYPE.SLOTS:
        return renderShareAvailabilityInfo({
          isDarkMode,
          onClickBack: onClickBack,
        });
      case CONTENT_TYPE.TIME_TRAVEL:
        return renderTimeTravel({
          isDarkMode,
          onClickBack: onClickBack,
        });
      case CONTENT_TYPE.NLP:
        return renderNLPMoreInfo({
          isDarkMode,
          onClickBack: onClickBack,
        });
      default:
        return renderHome({
          isDarkMode,
          completedToolTips,
          setContentType,
          minimize: minimizeTutorialWizard,
          removeTutorialWizard,
        });
    }
  };

  return (
    <div
      id={TUTORIAL_WIZARD_MODAL_ID}
      style={{ boxShadow: BOX_SHADOW.ZERO }}
      className="rounded-2xl"
    >
      {renderContent()}
    </div>
  );
}

function renderHeader({ minimize, completedToolTips, removeTutorialWizard }) {
  const completedAll = hasUserCompletedAllTutorial({ completedToolTips });
  return (
    <div className="flex items-center justify-between font-size-20 mb-7">
      <div className="select-none">
        {completedAll ? "Congrats! You're ready 🎉" : "What do you want to do?"}
      </div>
      <div className="flex items-center">
        <ShortcutHoverHint
          style={{ top: 20 }}
          bottom
          title={"Minimize"}
        >
          <Minus
            size={16}
            className="cursor-pointer hoverable-secondary-text-color mr-3"
            onClick={minimize}
          />
        </ShortcutHoverHint>

        <ShortcutHoverHint bottom title={"Remove"} style={{top: 20}}>
          <X
            size={16}
            className="cursor-pointer hoverable-secondary-text-color"
            onClick={removeTutorialWizard}
          />
        </ShortcutHoverHint>
      </div>
    </div>
  );
}

function renderOption({
  text,
  onClick,
  isDarkMode,
  isSelected,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div
      className={classNames(
        "flex items-center relative h-12 w-full rounded-md default-font-size",
        "px-4",
        "mt-4",
        "cursor-pointer",
        "grab-available-slots-option-container",
        "duration-200",
        "select-none"
      )}
      onClick={onClick}
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        if (onMouseLeave) {
          onMouseLeave();
          return;
        }
        mainCalendarBroadcast.publish("REMOVE_GLOW_ON_BUTTON");
      }}
    >
      {isSelected ? renderSelectedButton() : renderUnselectedButton()}
      <div className="ml-4">{text}</div>
    </div>
  );
}

const BUTTON_SIZE = "20px";
function renderUnselectedButton() {
  return (
    <div
      style={{
        border: "1px dashed gray",
        backgroundColor: "transparent",
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        borderRadius: "50%",
      }}
    ></div>
  );
}

function renderSelectedButton() {
  return (
    <div
      style={{
        backgroundColor: "#3B81F7",
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        borderRadius: "50%",
      }}
      className="flex items-center justify-center"
    >
      <Check color="white" size={12} />
    </div>
  );
}

function renderHome({
  isDarkMode,
  completedToolTips,
  setContentType,
  minimize,
  removeTutorialWizard,
}) {
  return (
    <div className={CONTAINER_CLASS_NAME}>
      {renderHeader({ minimize, completedToolTips, removeTutorialWizard })}
      {renderOption({
        text: "Propose times to meet",
        onClick: () => {
          broadcast.publish("TOGGLE_AVAILABILITY_MODE");
          setContentType(CONTENT_TYPE.SLOTS);
        },
        onMouseEnter: () => {
          mainCalendarBroadcast.publish(
            "TOGGLE_ON_GLOW_BUTTON",
            ONBOARDING_GLOW_TYPE.CREATE_AVAILABILITY_BUTTON
          );
        },
        isDarkMode,
        isSelected: completedToolTips.includes(tooltipKeys.SLOTS),
      })}
      {renderOption({
        text: "Create an event",
        onClick: () => {
          broadcast.publish("CREATE_EVENT_HANDLER");
          setContentType(CONTENT_TYPE.NLP);
        },
        onMouseEnter: () => {
          mainCalendarBroadcast.publish(
            "TOGGLE_ON_GLOW_BUTTON",
            ONBOARDING_GLOW_TYPE.CREATE_EVENT_BUTTON
          );
        },
        isDarkMode,
        isSelected: completedToolTips.includes(tooltipKeys.NLP),
      })}
      {renderOption({
        text: "Schedule meeting across time zones",
        onClick: () => {
          broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
          setContentType(CONTENT_TYPE.TIME_TRAVEL);
        },
        onMouseEnter: () => {
          broadcast.publish("SHOW_GUTTER_FLASH");
        },
        onMouseLeave: () => {
          broadcast.publish("REMOVE_GUTTER_FLASH");
        },
        isDarkMode,
        isSelected: completedToolTips.includes(tooltipKeys.TIME_TRAVEL),
      })}
    </div>
  );
}

function renderShareAvailabilityInfo({ isDarkMode, onClickBack }) {
  return (
    <div className={CONTAINER_CLASS_NAME}>
      <div className="flex items-center select-none">
        <ChevronLeft
          size={20}
          color={getDefaultFontColor(isDarkMode)}
          className="cursor-pointer"
          onClick={onClickBack}
        />

        <div className="ml-2">Share availabilities with</div>
        <div className="tutorial-wizard-type-keyword ml-2">Slots</div>
      </div>

      <div className="default-font-size secondary-text-color mt-4">
        Drag times you'd like to offer someone and Vimcal will format them for
        you to copy, booking link included.
      </div>

      <div className="default-font-size secondary-text-color mt-6">
        By far our most popular feature, Slots combines the speed of booking
        links with the personal touch of hand-picked times, all while protecting
        your calendar from unwanted meetings.
      </div>
    </div>
  );
}

function renderTimeTravel({ isDarkMode, onClickBack }) {
  return (
    <div className={CONTAINER_CLASS_NAME}>
      <div className="flex items-center select-none">
        <ChevronLeft
          size={20}
          color={getDefaultFontColor(isDarkMode)}
          className="cursor-pointer"
          onClick={onClickBack}
        />
        <div className="ml-2">Convert time zones with</div>
        <div className="tutorial-wizard-type-keyword ml-2">Time Travel</div>
      </div>

      <div className="default-font-size secondary-text-color mt-4">
        Press “Z” to instantly display up to 5 time zones side by side.
      </div>

      <div className="default-font-size secondary-text-color mt-6">
        You can search by city (e.g. “Austin”) or by time zone (e.g. “PT”).
      </div>

      <div className="default-font-size secondary-text-color mt-6">
        Time Travel is most powerful when combined with Slots to send your
        availabilities in someone else’s time zone as a courtesy.
      </div>
    </div>
  );
}

function renderNLPMoreInfo({ isDarkMode, onClickBack }) {
  return (
    <div className={CONTAINER_CLASS_NAME}>
      <div className="flex items-center select-none">
        <ChevronLeft
          size={20}
          color={getDefaultFontColor(isDarkMode)}
          className="cursor-pointer"
          onClick={onClickBack}
        />
        <div className="ml-2">Create events with</div>
        <div className="tutorial-wizard-type-keyword ml-1">NLP</div>
      </div>

      <div className="default-font-size secondary-text-color mt-4">
        There are only two easy rules to remember:
      </div>

      <div className="default-font-size secondary-text-color mt-2 ml-2">
        1)
        <span className="font-weight-400 default-font-color mx-1">
          Always start with the title you want to give the event.
        </span>
        Everything afterwards can be in any order.
      </div>
      <div className="default-font-size secondary-text-color mt-2 ml-2">
        2) Specific fields are triggered by specific keywords, e.g. "Coffee at
        Starbucks for 45 minutes with Jimmy at 2pm ET on Tuesday"
      </div>
    </div>
  );
}
