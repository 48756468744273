import classNames from "classnames";
import React, { useEffect } from "react";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { constructRequestURL } from "../../services/api";
import Broadcast from "../../broadcasts/broadcast";
import {
  hasEventPreventDefault,
  isTooltipCompleted,
} from "../../services/commonUsefulFunctions";
import Fetcher from "../../services/fetcher";
import { COMMAND_KEY, PC_CONTROL_KEY } from "../../services/globalVariables";
import { useMasterAccount } from "../../services/stores/SharedAccountData";

import { dropdownTips, tooltipKeys } from "../../services/tooltipVariables";
import ShortcutTile from "../shortcutTiles/shortcutTile";

const Tip = ({
  description,
  tipKey,
  hideWelcomeTips,
  isCompleted,
  name,
  shortcutKeys,
}) => {
  const isMac = useSelector((state) => state.isMac);
  const { COMMAND_CENTER, FLASH_HOTKEYS, TIME_TRAVEL, PERSONAL_LINKS, FOCUS_MODE } =
    tooltipKeys;

  const handleTooltipClick = (e) => {
    hasEventPreventDefault(e);
    switch (tipKey) {
      case COMMAND_CENTER:
        Broadcast.publish("TURN_ON_COMMAND_CENTER");
        break;
      case FLASH_HOTKEYS:
        Broadcast.publish("TOGGLE_GLOBAL_KEY_MAP");
        break;
      case TIME_TRAVEL:
        Broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
        break;
      case PERSONAL_LINKS:
        Broadcast.publish("OPEN_PERSONAL_LINKS");
        break;
      case FOCUS_MODE:
        layoutBroadcast.publish("TOGGLE_FOCUS_MODE", true);
        break;
      default:
        return;
    }
    hideWelcomeTips();
  };

  return (
    <div className="welcome-tips-tip-container" onClick={handleTooltipClick}>
      <div
        className={classNames(
          "welcome-tips-tip-info-container",
          isCompleted ? "welcome-tips-tip-info-container-completed" : ""
        )}
      >
        <div className="welcome-tips-tip-info-name">{name}</div>
        {description ? (
          <div className="welcome-tips-tip-info-description">{description}</div>
        ) : null}
        {shortcutKeys?.length > 0 ? (
          <div className="flex items-center" >
            <div className="welcome-tips-tip-info-shortcut">Press </div>
            <div style={{ display: "flex" }}>
              {shortcutKeys.map((key, idx) => (
                <ShortcutTile
                  shortcut={
                    key === "determineCmdKey"
                      ? isMac
                        ? COMMAND_KEY
                        : PC_CONTROL_KEY
                      : key
                  }
                  key={`welcome-tips-tip-shortcut-${idx}-${key}`}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={classNames(
          "welcome-tips-completion-checkbox-container",
          isCompleted
            ? "welcome-tips-completion-checkbox-container-completed"
            : ""
        )}
      >
        {isCompleted ? <Check size={14} /> : null}
      </div>
    </div>
  );
};

const WelcomeTips = ({ hideWelcomeTips, isHidden }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount();

  const handleSkipWelcomeTips = async (e) => {
    hasEventPreventDefault(e);

    /* If successful, the response is the data we want */
    /* { settings: { columns for master account settings } } */
    const skipWelcomeTipsResponse = await Fetcher.patch(
      constructRequestURL("tooltips/skip"),
      {},
      true,
      currentUser.email
    );

    /* Check if we got data back and if the dropdown should be removed */
    if (
      skipWelcomeTipsResponse?.master_account_settings?.welcome_tour_dismissed
    ) {
      const { setMasterAccountSettings } = masterAccount;

      setMasterAccountSettings(skipWelcomeTipsResponse.master_account_settings);
    } else {
      /* TODO: Add error handling */
      return;
    }
  };

  useEffect(() => {
    Broadcast.subscribe("SKIP_WELCOME_TIPS", handleSkipWelcomeTips);
    return () => {
      Broadcast.unsubscribe("SKIP_WELCOME_TIPS");
    };
  });

  return (
    <div onMouseLeave={hideWelcomeTips}>
      <div
        className={classNames(
          "welcome-tips-dropdown-container",
          isHidden ? "welcome-tips-dropdown-container-hidden" : "",
          isDarkMode ? "dark" : ""
        )}
      >
        {dropdownTips.map((tip, idx) => (
          <Tip
            key={`welcome-tips-tip-${idx}-${tip?.name}`}
            {...tip}
            isCompleted={isTooltipCompleted(
              masterAccount?.masterAccount,
              tip?.key
            )}
            hideWelcomeTips={hideWelcomeTips}
            tipKey={tip?.key}
          />
        ))}
      </div>
      <div
        className={classNames(
          "welcome-tips-skip-button",
          isHidden ? "" : "welcome-tips-skip-button-show",
          isDarkMode ? "dark" : "",
          "select-none"
        )}
        onClick={handleSkipWelcomeTips}
      >
        Skip Welcome Tips
      </div>
    </div>
  );
};

export default WelcomeTips;
