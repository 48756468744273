import React, { useCallback, useMemo } from "react";

import classNames from "classnames";
import { AlertCircle, Paperclip, RotateCcw, X } from "react-feather";

import CustomButtonBox from "./customButtonBox";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { downloadInMemoryFile } from "../lib/fileFunctions";
import { isUserInDarkMode } from "../lib/settingsFunctions";
import { OpenLink } from "../services/commonUsefulFunctions";
import { isDriveFile } from "../services/googleDriveFunctions";

interface AttachmentPreviewProps {
  file: File | DriveFile;
  fileErrors?: string[];
  handleRetryFileUpload: () => void;
  hoveredFileIndex: number | null;
  index: number;
  isUploading: boolean;
  onClickRemove: () => void;
  setHoveredFileIndex: (index: number | null) => void;
}

export default function AttachmentPreview({
  file,
  fileErrors,
  handleRetryFileUpload,
  hoveredFileIndex,
  index,
  isUploading,
  onClickRemove,
  setHoveredFileIndex,
}: AttachmentPreviewProps) {
  const { masterAccount } = useMasterAccount();
  const isDarkMode = isUserInDarkMode({ masterAccount });
  const fileName = useMemo(
    () => (isDriveFile(file) ? file?.title || "" : file?.name || ""),
    [file],
  );

  const handleOnClick = useCallback(() => {
    if (isDriveFile(file)) {
      OpenLink(file.fileUrl);
      return;
    }

    if (file instanceof File) {
      downloadInMemoryFile(file);
      return;
    }
  }, [file]);

  return (
    <div className="flex items-center">
      <div
        className={index === 0 ? "" : "margin-top-ten"}
        onMouseLeave={() => setHoveredFileIndex(null)}
      >
        <CustomButtonBox
          className={
            isEmptyArrayOrFalsey(fileErrors) ? "" : "button-error-border"
          }
          onClick={handleOnClick}
        >
          <div className="file-attachment-expand-file">
            {isEmptyArrayOrFalsey(fileErrors) ? (
              <Paperclip className="non-clickable-icon" size="12" />
            ) : (
              <div>
                {hoveredFileIndex === index ? (
                  <div style={{ position: "absolute" }}>
                    {fileErrors.map((fileError, errorIndex) => (
                      <div
                        className={classNames(
                          "more-information-modal w-max-content-important",
                          isDarkMode
                            ? "background-color-modal-background-color"
                            : "bg-white",
                        )}
                        key={`attachement_${fileName}_${index}_error_${errorIndex}`}
                      >
                        {fileError}
                      </div>
                    ))}
                  </div>
                ) : null}
                <AlertCircle
                  className="non-clickable-icon"
                  color="red"
                  size="16"
                  onMouseEnter={() => setHoveredFileIndex(index)}
                />
              </div>
            )}

            <div className="file-attachment-title">{fileName}</div>

            {isUploading ? null : (
              <X
                className="ml-1 cursor-pointer"
                onClick={(e) => {
                  if (e?.stopPropagation) {
                    e.stopPropagation();
                  }

                  onClickRemove();
                }}
                size={12}
              />
            )}
          </div>
        </CustomButtonBox>
      </div>

      {isEmptyArrayOrFalsey(fileErrors) ? null : (
        <RotateCcw
          className="cursor-pointer ml-2"
          onClick={handleRetryFileUpload}
          size={14}
        />
      )}
    </div>
  );
}
