import classNames from "classnames";
import React from "react";
import { Check, X } from "react-feather";
import { useSelector } from "react-redux";
import {
  DARK_MODE_SECONDARY_TEXT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
} from "../../../services/globalVariables";
import CheckBoxSpinner from "../../spinners/checkboxSpinner";

interface ApprovalBadgeWithLoadingSpinnerProps {
  isFetching?: boolean
  warning?: string
  spinnerLocationClassName?: string
  containerClassName?: string
}

export default function ApprovalBadgeWithLoadingSpinner({
  isFetching,
  warning,
  spinnerLocationClassName,
  containerClassName,
}: ApprovalBadgeWithLoadingSpinnerProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  if (isFetching) {
    return (
      <CheckBoxSpinner
        className={spinnerLocationClassName}
        color={
          isDarkMode
            ? DARK_MODE_SECONDARY_TEXT_COLOR
            : LIGHT_MODE_SECONDARY_TEXT_COLOR
        }
        hideRightSidePadding={true}
        size={14}
      />
    );
  }

  if (warning) {
    return (
      <div className={classNames(containerClassName, "rounded-full w-5 h-5 flex items-center justify-center bg-red-600 ml-2")}>
        <X size={10} color={"white"} strokeWidth={3} />
      </div>
    );
  }

  // approved
  return (
    <div className={classNames(containerClassName, "rounded-full w-5 h-5 flex items-center justify-center bg-green-600 ml-2")}>
      <Check size={10} color={"white"} strokeWidth={3} />
    </div>
  );
}
