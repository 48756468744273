import React, { Component } from "react";
import { connect } from "react-redux";
import recurrenceBroadcast from "../broadcasts/recurrenceBroadcast";
import { getUserEmailFromEvent } from "../lib/calendarFunctions";
import { isValidEvent } from "../lib/eventFunctions";
import {
  getOriginalRecurringEventFromIndex,
  updateOriginalRecurringEventIndex,
} from "../lib/recurringEventFunctions";
import {
  fetchEvent,
  handleError,
} from "../services/commonUsefulFunctions";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
} from "../services/eventResourceAccessors";
import { useAllCalendars } from "../services/stores/SharedAccountData";
import { isMeetWithEvent } from "../lib/meetWithFunctions";
import { RECURRENCE_BROADCAST_VALUES } from "../lib/broadcastValues";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";

class RecurrenceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.fetchOriginalEvent = this.fetchOriginalEvent.bind(this);

    recurrenceBroadcast.subscribe(
      RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT,
      this.fetchOriginalEvent,
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    recurrenceBroadcast.unsubscribe(RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT);
  }

  fetchOriginalEvent(event) {
    const { allCalendars } = this.props.allCalendars;
    const {
      originalRecurrenceEventIndex,
      setOriginalRecurrenceEventIndex
    } = this.props;

    if (isEmptyObjectOrFalsey(event) || !isValidEvent(event) || isMeetWithEvent(event)) {
      return;
    }

    if (
      !(
        getEventMasterEventID(event) &&
        !getOriginalRecurringEventFromIndex(
          event,
          originalRecurrenceEventIndex
        )
      )
    ) {
      // Only fetch if it does not already have recurrence event
      return;
    }

    const userEmail = getUserEmailFromEvent(event, allCalendars);

    fetchEvent(
      getEventUserCalendarID(event),
      getEventMasterEventID(event),
      userEmail
    )
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response) || response.error) {
          return;
        }

        const { event: originalEvent } = response;

        const updatedIndex = updateOriginalRecurringEventIndex({
          currentEvent: event,
          originalEvent,
          originalRecurrenceEventIndex: originalRecurrenceEventIndex,
        });

        setOriginalRecurrenceEventIndex(updatedIndex);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { originalRecurrenceEventIndex } = state;

  return {
    originalRecurrenceEventIndex,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOriginalRecurrenceEventIndex: (data) =>
      dispatch({ data, type: "SET_ORIGINAL_RECURRENCE_EVENT_INDEX" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();

  return <BaseComponent {...props} allCalendars={allCalendars} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(RecurrenceContainer));
