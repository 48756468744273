import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { useTeamPlan } from "../../../../services/stores/userData";
import { useIsMounted } from "../../../../services/customHooks/useIsMounted";
import { useStripeModule } from "../../../onboarding/stripePaymentModal";
import EventModalPopup from "../../../eventModalPopup";
import { isMonthlyPlan } from "../../../../lib/stripeFunctions";
import { devErrorPrint } from "../../../../services/devFunctions";
import type { EmailPlan, StripeCost } from "../../updatedAddTeamMembersModal";
import { BLUE_BUTTON } from "../../../../services/globalVariables";
import type { Stripe as StripeTypes } from "stripe-types";
import ReviewAddingSeats from "./reviewAddingSeats";
import ReviewFillingSeats from "./reviewFillingSeats";
import CustomButtonV2 from "../../../buttons/customButtonV2";
import SpinnerV2 from "../../../spinnerV2";
import { getExistingTeamMembers } from "../../sharedFunctions";
import { getObjectEmail } from "../../../../lib/objectFunctions";
import { groupEmailPlanDictionaryBySeat } from "../sharedFunctions";
import { useOpenSeats, useSubmitInviteMembers } from "../../hooks";
import { useDefaultPaymentMethod } from "../../../../services/stores/finance";
import _ from "underscore";
import { determineDefaultModalStyle } from "../../../../lib/modalFunctions";
import { getStripeCostDueToday } from "../../../../services/accessors/stripeCost";

interface ReviewSectionProps {
  fetchProratedCost: () => void
  isFetching: boolean
  nextPage: () => void
  defaultVimcalSeats: number
  vimcalEASeats: number
  emailPlanDictionary: Record<string, EmailPlan>
  stripeSubscription: StripeTypes.Subscription | null | Record<string, never>
  stripeCost: StripeCost
}

/**
 * The review page before confirming and sending invites/adding seats to a team plan.
 */
export default function ReviewSection(props: ReviewSectionProps) {
  const {
    fetchProratedCost,
    isFetching,
    nextPage,
    stripeCost,
    stripeSubscription,
    emailPlanDictionary,
    defaultVimcalSeats,
    vimcalEASeats,
  } = props;
  const isOverSeatLimit = (getStripeCostDueToday(stripeCost) ?? 0) > 0;

  const { inviteTeamMembers, isSubmittingInvites } = useSubmitInviteMembers();
  const componentIsMounted = useIsMounted();
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();
  const needCard = useDefaultPaymentMethod(state => state.needCard);

  /**********************************************/
  /* ========== Variables for stripe ========== */
  /**********************************************/

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldFreezeModal, setShouldFreezeModal] = useState(false);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const onLoad = useCallback(() => setIsModalOpen(true), []);
  const {
    stripePaymentModalRef,
    stripePromiseRef,
    elementsRef,
    hasCompletedImport,
    importStripeModule,
  } = useStripeModule({ onLoad });

  const { vimcalEmails, vimcalEAEmails } = groupEmailPlanDictionaryBySeat(emailPlanDictionary);

  // For payment preview purposes, we only care about new users being added to the plan.
  const existingMemberEmails = getExistingTeamMembers(teamPlan).map(u => getObjectEmail(u));
  const newVimcalEmails = vimcalEmails.filter(e => !existingMemberEmails.includes(e));
  const newVimcalEAEmails = vimcalEAEmails.filter(e => !existingMemberEmails.includes(e));

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCompleteClick = () => {
    inviteTeamMembers({
      vimcalEAEmails,
      vimcalEASeats,
      vimcalEmails,
      vimcalSeats: defaultVimcalSeats,
    }).then(() => {
      if (componentIsMounted.current) {
        nextPage();
      }
    }).catch(_.noop); // Errors are already handled.
  };

  const onClickUpsertPayment = () => {
    importStripeModule();
  };

  const onPaymentError = (error: string) => {
    // do nothing for now. pending backend investigation on bugs found on sentry
    devErrorPrint(error, "onPaymentError_BillingPaymentMethod");
  };

  const onSuccessfulPayment = () => {
    fetchProratedCost();
    setIsModalOpen(false);
  };

  const renderStripeContent = () => {
    const ElementsComponent = elementsRef.current;
    const StripePaymentModalComponent = stripePaymentModalRef.current;
    if (!ElementsComponent || !StripePaymentModalComponent) {
      return;
    }
    return (
      <ElementsComponent stripe={stripePromiseRef.current}>
        <StripePaymentModalComponent
          isTeamPlan={true}
          setShouldFreezeModal={setShouldFreezeModal}
          onSuccess={onSuccessfulPayment}
          hideInstructions={true}
          onError={onPaymentError}
        />
      </ElementsComponent>
    );
  };

  const renderStripeModal = () => {
    return (
      <EventModalPopup
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        width={400}
        title={"Add your credit card"}
        style={determineDefaultModalStyle(isDarkMode)}
        shouldFreeze={shouldFreezeModal}
      >
        {hasCompletedImport ? renderStripeContent() : null}
      </EventModalPopup>
    );
  };

  if (isFetching) {
    return (
      <div className="flex-grow flex items-center justify-center">
        <SpinnerV2 />
      </div>
    );
  }

  return (
    <>
      <div className="font-size-20 mb-5">Review your plan</div>
      <div className="flex-grow font-normal">
        {isOverSeatLimit ? (
          <ReviewAddingSeats
            defaultVimcalSeats={defaultVimcalSeats + newVimcalEmails.length - openVimcalSeats}
            isPayingMonthly={isMonthlyPlan({ stripeSubscriptions: stripeSubscription })}
            onClickUpsertPayment={onClickUpsertPayment}
            stripeCost={stripeCost}
            vimcalEASeats={vimcalEASeats + newVimcalEAEmails.length - openVimcalEASeats}
          />
        ) : <ReviewFillingSeats emailPlanDictionary={emailPlanDictionary} />}
      </div>
      <CustomButtonV2
        label={isOverSeatLimit ? "Complete payment" : "Send invites"}
        disabled={isSubmittingInvites || isFetching || (isOverSeatLimit ? needCard : false)}
        onClick={handleCompleteClick}
        buttonType={BLUE_BUTTON}
        className="self-center px-10"
        removeDefaultPadding
        shouldRenderSpinner={isSubmittingInvites}
      />
      {renderStripeModal()}
    </>
  );
}
