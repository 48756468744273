import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { differenceInDays, differenceInMinutes, endOfDay, getHours, isBefore, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getTimeInAnchorTimeZone } from "../../services/commonUsefulFunctions";
import { SECOND_IN_MS } from "../../services/globalVariables";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { HOUR_CELL_WIDTH, isWithinWorkHours, SCROLL_INTO_VIEW_OPTIONS } from "./helperFunctions";

export const DRAGGABLE_ID = "schedule-assistant-selected-range";
export const DRAGGABLE_LEFT_EDGE_ID = "schedule-assistant-selected-range-left-edge";
export const DRAGGABLE_RIGHT_EDGE_ID = "schedule-assistant-selected-range-right-edge";

interface SelectedRangeProps {
  dragOffsetRef: React.MutableRefObject<{ x: number, y: number } | null>
  endTimeZone: string
  isShowingWorkHoursOnly: boolean
  selectedStartTime?: Date
  selectedEndTime?: Date
  selectedRangeRef: React.MutableRefObject<HTMLDivElement | null>
  startTimeZone: string
  windowStartDate: Date
  windowEndDate: Date
  workHours: { startWorkHour: number, endWorkHour: number }
}

export default function SelectedRange({
  dragOffsetRef,
  endTimeZone,
  isShowingWorkHoursOnly,
  selectedEndTime,
  selectedStartTime,
  selectedRangeRef,
  startTimeZone,
  windowEndDate,
  windowStartDate,
  workHours,
}: SelectedRangeProps) {
  const {
    activatorEvent,
    activeNodeRect,
    attributes,
    isDragging,
    listeners,
    node,
    setNodeRef,
  } = useDraggable({ id: DRAGGABLE_ID });

  const numberOfWorkingHours = workHours.endWorkHour - workHours.startWorkHour;
  const numberOfNonWorkingHours = 24 - numberOfWorkingHours;

  // For now we only display a single time zone in scheduling assistant.
  const convertedEndTime = useMemo(() => {
    if (selectedEndTime) {
      return getTimeInAnchorTimeZone(selectedEndTime, endTimeZone, startTimeZone);
    }
  }, [selectedEndTime]);

  useEffect(() => {
    setTimeout(() => {
      node.current?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS);
    }, 0.05 * SECOND_IN_MS);
  }, []);

  useEffect(() => {
    if (isDragging && activatorEvent instanceof MouseEvent && !isEmptyObjectOrFalsey(activeNodeRect)) {
      if (dragOffsetRef.current) {
        // We only need to set this once: when the user starts dragging.
        return;
      }

      const centerX = (activeNodeRect.left + activeNodeRect.right) / 2;
      dragOffsetRef.current = {
        x: centerX - activatorEvent.x - (activeNodeRect.width / 2),
        // The range is fixed in the y-direction, no need to offset.
        y: 0,
      };
    } else {
      // Clear the offset when not dragging.
      dragOffsetRef.current = null;
    }
  }, [activatorEvent, activeNodeRect, isDragging]);

  const getLeft = useCallback((time?: Date) => {
    if (!time) {
      return 0;
    }

    const startOffset = differenceInMinutes(time, startOfDay(windowStartDate));
    if (isShowingWorkHoursOnly) {
      const days = differenceInDays(startOfDay(time), startOfDay(windowStartDate));
      if (!isWithinWorkHours(time, workHours)) {
        const hours = getHours(time);
        const daysOffset = hours < workHours.startWorkHour ? days : days + 1;
        return daysOffset * numberOfWorkingHours * HOUR_CELL_WIDTH;
      }
      const hiddenHours = days * numberOfNonWorkingHours + workHours.startWorkHour;
      return (startOffset / 60 - hiddenHours) * HOUR_CELL_WIDTH;
    } else {
      return startOffset * HOUR_CELL_WIDTH / 60;
    }
  }, [isShowingWorkHoursOnly, windowStartDate]);

  const startLeft = useMemo(() => getLeft(selectedStartTime), [getLeft, selectedStartTime]);
  const width = useMemo(() => {
    const endLeft = getLeft(convertedEndTime);
    return endLeft - startLeft;
  }, [getLeft, convertedEndTime, startLeft]);

  const updateRefs = (element: HTMLDivElement | null) => {
    setNodeRef(element);
    selectedRangeRef.current = element;
  };

  // No range selected, nothing to render.
  if (!convertedEndTime || !selectedStartTime) {
    return null;
  }

  // Invalid range.
  if (isBefore(convertedEndTime, selectedStartTime)) {
    return null;
  }

  // The range is not in the visible window.
  if (convertedEndTime < startOfDay(windowStartDate) || selectedStartTime > endOfDay(windowEndDate)) {
    return null;
  }

  return (
    <div
      className={classNames("absolute", isDragging ? "cursor-grabbing" : "cursor-grab", "rounded-md")}
      style={{
        backgroundColor: "#5caae544",
        top: 72,
        bottom: 0,
        left: startLeft,
        width,
        zIndex: 2,
        border: "2px solid #5caae5",
        scrollMargin: 64,
      }}
      ref={updateRefs}
      {...listeners}
      {...attributes}
    >
      <DraggableEdge edge="left" oppositeTimestamp={convertedEndTime} />
      <DraggableEdge edge="right" oppositeTimestamp={selectedStartTime} />
    </div>
  );
}

interface DraggableEdgeProps {
  edge: "left" | "right"
  oppositeTimestamp: Date
}

function DraggableEdge({ edge, oppositeTimestamp }: DraggableEdgeProps) {
  const [fixedEdgeTimestamp, setFixedEdgeTimestamp] = useState(oppositeTimestamp);
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
  } = useDraggable({
    id: edge === "left" ? DRAGGABLE_LEFT_EDGE_ID : DRAGGABLE_RIGHT_EDGE_ID,
    data: { fixedEdgeTimestamp },
  });

  useEffect(() => {
    // While this edge is being dragged, the opposite edge should never change.
    if (!isDragging) {
      setFixedEdgeTimestamp(oppositeTimestamp);
    }
  }, [isDragging, oppositeTimestamp]);

  return (
    <div
      ref={setNodeRef}
      className={classNames("absolute top-0 bottom-0 flex justify-center", edge === "left" ? "-left-1" : "-right-1")}
      style={{ width: 6, cursor: "col-resize" }}
      {...attributes}
      {...listeners}
    >
      <div
        className="selected-range-grip rounded-full w-3 h-3 absolute"
        style={{
          top: edge === "left" ? "12%" : undefined,
          bottom: edge === "right" ? "12%" : undefined,
        }}
      >
      </div>
    </div>
  );
}
