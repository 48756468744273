import React from "react";

export default function UndoButton() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48503 1.125L1.94336 4.66667L5.48503 8.20833M8.31836 13.1667H10.4434C11.0015 13.1667 11.5541 13.0567 12.0698 12.8432C12.5854 12.6296 13.0539 12.3165 13.4486 11.9219C13.8432 11.5272 14.1563 11.0587 14.3698 10.5431C14.5834 10.0274 14.6934 9.47479 14.6934 8.91667C14.6934 8.35855 14.5834 7.8059 14.3698 7.29026C14.1563 6.77463 13.8432 6.30611 13.4486 5.91146C13.0539 5.51681 12.5854 5.20376 12.0698 4.99018C11.5541 4.7766 11.0015 4.66667 10.4434 4.66667H2.65169"
        stroke="#4F5168"
        strokeWidth="1.41667"
      />
    </svg>
  );
}
