import React from "react";
import classnames from "classnames";
import { format, isSameDay } from "date-fns";
import { hasEventPreventDefault, hasStopEventPropagation, isMac } from "../../services/commonUsefulFunctions";
import _ from "underscore";

export default function StatelessCalendarDayHeader({ date, onClickHeader }) {
  const determineDayOfMonthBackgroundColor = () => {
    if (isSameDay(date, new Date())) {
      return "agenda-hover-blue-background-color";
    } else {
      return "bg-transparent";
    }
  };

  const isToday = isSameDay(new Date(), date);

	const determineDayOfWeekFontColor = () => {
    if (isToday) {
      return 'agenda-hover-blue';
    } else {
      return "default-font-color";
    }
  }

  const onClick = (e) => {
    hasStopEventPropagation(e);
    hasEventPreventDefault(e);
    if (onClickHeader) {
      onClickHeader(date);
    }
  }

  return (
    <div
      className={classnames(
        "border-none",
        "display-flex",
        "flex-direction-column",
        "justify-content-center",
        "align-items-center",
        "pt-4",
        "cursor-pointer"
      )}
      onClick={onClick}
    >
      <div
        className={classnames(
          "font-size-10",
          "w-8",
          "h-2.5",
          "font-weight-400",
          "font",
          determineDayOfWeekFontColor(),
          "transition-monthly-agenda-date",
          "leading-normal",
        )}
      >
        {format(date, "ccc").toUpperCase()}
      </div>

      <div
        className={classnames(
          "mt-1.5",
          "border-radius-5px",
          "display-flex",
          "justify-content-center",
          "align-items-center",
          determineDayOfMonthBackgroundColor(),
          "transition-monthly-agenda-date",
          "font-size-20",
          "font-weight-300",
          "custom-day-header-month",
          isToday ? "text-white" : "default-font-color",
        )}
      >
        <div className="font-size-14">
          {format(date, "d")}
        </div>
      </div>
    </div>
  );
}
