import React, { useMemo, useRef, useState } from "react";
import { getInternalDomainAndEmails } from "../../../../lib/settingsFunctions";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import {
  cleanUpDomainEmail,
  formatEmail,
  isGenericDomain,
  isUrl,
  isValidEmail,
  lowerCaseAndTrimString,
} from "../../../../lib/stringFunctions";
import InternalDomainSetting from "../../common/internalDomainSetting";
import SaveButton from "../../common/saveButton";
import { useIsMounted } from "../../../../services/customHooks/useIsMounted";
import { BACKEND_SETTINGS_NAMES } from "../../../../lib/vimcalVariables";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import { isUserDelegatedUser } from "../../../../services/maestroFunctions";
import { delayByMs } from "../../../../lib/asyncFunctions";
import { SECOND_IN_MS } from "../../../../services/globalVariables";
import { removeDuplicatesFromArray } from "../../../../lib/arrayFunctions";

export default function InternalDomainsSettingsContainer({ selectedUser }) {
  const [currentDomainText, setCurrentDomainText] = useState("");
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const [warning, setWarning] = useState("");
  const currentInternalDomains = useMemo(() => {
    return getInternalDomainAndEmails({
      masterAccount,
      user: selectedUser ?? currentUser,
    });
  }, [masterAccount, selectedUser, currentUser]);
  const [hasChanged, setHasChanged] = useState(false);

  const [domainEmails, setDomainEmails] = useState(currentInternalDomains);
  const domainEmailsRef = useRef(currentInternalDomains);
  const componentIsMounted = useIsMounted();
  const setDomainEmailsAndRef = (emails) => {
    domainEmailsRef.current = emails;
    setDomainEmails(emails);
    setWarning("");
  };

  const addEmail = (email) => {
    const formattedEmail = formatEmail(email);
    if (isGenericDomain(formattedEmail)) {
      setWarning(
        `"${formattedEmail} is too generic. Please enter your organization's domain (e.g. vimcal.com) or a specific teammate's email, e.g. freelancer@${formattedEmail}."`
      );
      return;
    }
    if (!isUrl(formattedEmail) && !isValidEmail(formattedEmail)) {
      setWarning("Please enter a valid URL or email address");
      return;
    }

    if (domainEmails.includes(formattedEmail)) {
      return;
    }
    setHasChanged(true);
    setDomainEmailsAndRef(
      removeDuplicatesFromArray([...domainEmails, formattedEmail])
    );
  };

  const removeEmail = (email) => {
    setDomainEmailsAndRef(
      domainEmails.filter((guestEmail) => guestEmail !== email)
    );
    setHasChanged(true);
  };

  const onClickSave = async () => {
    if (
      currentDomainText &&
      (isUrl(lowerCaseAndTrimString(currentDomainText)) ||
        isValidEmail(lowerCaseAndTrimString(currentDomainText)))
    ) {
      addEmail(currentDomainText);
      setCurrentDomainText("");
      await delayByMs(0.1 * SECOND_IN_MS);
    }

    if (!componentIsMounted.current) {
      return;
    }

    setWarning("");
    const updatedDomains = domainEmailsRef.current.filter((link) =>
      isUrl(cleanUpDomainEmail(link))
    );
    const updatedEmails = domainEmailsRef.current.filter((link) =>
      isValidEmail(cleanUpDomainEmail(link))
    );
    const internalDomains = removeDuplicatesFromArray(
      updatedDomains.concat(updatedEmails)
    );
    const updatedSettings = {
      [BACKEND_SETTINGS_NAMES.INTERNAL_DOMAINS]: internalDomains,
    };
    updateMasterAccountSettingsForFrontendAndBackend({
      isUpdatingExecutiveProfile: isUserDelegatedUser(selectedUser),
      masterAccount,
      updatedSettings,
      user: selectedUser,
      shouldRefetchEvents: true,
    });
    setHasChanged(false);
  };

  return (
    <>
      <InternalDomainSetting
        selectedUser={selectedUser}
        addEmail={addEmail}
        removeEmail={removeEmail}
        domainEmails={domainEmails}
        currentDomainText={currentDomainText}
        setCurrentDomainText={(e) => {
          setHasChanged(true);
          setCurrentDomainText(e);
        }}
      />
      {warning ? (
        <div className="warning-color default-font-size mt-1">{warning}</div>
      ) : null}

      <SaveButton
        disabled={!hasChanged}
        onClick={onClickSave}
        className="mt-4"
      />
    </>
  );
}
