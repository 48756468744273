import React from "react";
import { ZOOM_VARIABLES } from "../../services/zoomFunctions";
import { useSelector } from "react-redux";
import Arrow from "../arrow";
import { MAESTRO_ZOOM_MODAL_INFO_TYPE } from "../../lib/maestroFunctions";
import classNames from "classnames";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";

export default function MaestroZoomLoginInfo({
  type,
  onClose,
  onClickZoomLogin,
}) {
  // type is MAESTRO_ZOOM_MODAL_INFO_TYPE
  const isDarkMode = useSelector((state) => state.isDarkMode);

  if (true) { // TODO: change this later
  // if (type === MAESTRO_ZOOM_MODAL_INFO_TYPE.WITH_ONLY_CHECK_BOX) {
    return (
      <div className="overflow-x-hidden">
        <div
          className="overflow-y-auto overflow-x-hidden pr-2"
          style={{ maxHeight: "calc(90vh - 128px)" }}
        >
          {renderSharePermission({ isDarkMode, type: MAESTRO_ZOOM_MODAL_INFO_TYPE.WITH_ONLY_CHECK_BOX })}
        </div>

        {renderButton({ onClose, onClickZoomLogin })}
      </div>
    );
  }

  return (
    <div className="overflow-x-hidden">
      <div
        className="overflow-y-auto overflow-x-hidden pr-2"
        style={{ maxHeight: "calc(90vh - 128px)" }}
      >
        {renderSelectUser({ isDarkMode, type })}
        {renderSharePermission({ isDarkMode, type })}
      </div>

      {renderButton({ onClose, onClickZoomLogin })}
    </div>
  );
}

function renderButton({ onClose, onClickZoomLogin }) {
  const onClickSend = () => {
    onClickZoomLogin();
    onClose();
  };
  return (
    <div
      className={classNames(
        "weekly-calendar-modal-content-button-options mr-2"
      )}
      style={{ marginTop: 20 }}
    >
      <CustomButton
        buttonType={BLUE_BUTTON}
        onClick={onClickSend}
        label={"Log into Zoom"}
      />
    </div>
  );
}
function renderSelectUser({ isDarkMode }) {
  const zoomImageURL = isDarkMode
    ? ZOOM_VARIABLES.ZOOM_SELECT_USER_IMG_DARK_MODE
    : ZOOM_VARIABLES.ZOOM_SELECT_USER_IMG_LIGHT_MODE;
  return (
    <div>
      <div className="font-size-14">{"Step 1"}</div>

      <div className="default-font-size secondary-text-color mt-1">
        Make sure you are toggled to the exec you would like to provide access
        for.
      </div>

      <div className="relative">
        <img
          alt=""
          className="rounded-lg default-bottom-left-modal-border mt-2"
          style={{
            width: "680px",
          }}
          src={zoomImageURL}
        />
        <div className="absolute top-8 right-44">
          <Arrow />
        </div>
      </div>
    </div>
  );
}

function renderSharePermission({ isDarkMode, type }) {
  const zoomPermissionScreen = isDarkMode
    ? ZOOM_VARIABLES.ZOOM_PERMISSION_SCREEN_DARK_MODE
    : ZOOM_VARIABLES.ZOOM_PERMISSION_SCREEN_LIGHT_MODE;
  const zoomPermissionCheckBox = isDarkMode
    ? ZOOM_VARIABLES.ZOOM_PERMISSION_CHECK_BOX_DARK_MODE
    : ZOOM_VARIABLES.ZOOM_PERMISSION_CHECK_BOX_LIGHT_MODE;
  return (
    <div
      className={classNames(
        type === MAESTRO_ZOOM_MODAL_INFO_TYPE.WITH_EVERY_STEP || !type
          ? "mt-4"
          : ""
      )}
    >
      {!type || type === MAESTRO_ZOOM_MODAL_INFO_TYPE.WITH_EVERY_STEP ? (
        <>
          <div className="font-size-14">{"Step 2"}</div>

          <div className="default-font-size secondary-text-color mt-1">
            Check ‘Allow this app to use my shared permissions’.
          </div>
        </>
      ) : (
        <div className="default-font-size secondary-text-color">
          Make sure to check ‘Allow this app to use my shared permissions’.
        </div>
      )}

      <div className="relative">
        <img
          alt=""
          className="rounded-lg default-bottom-left-modal-border mt-2"
          style={{
            width: "400px",
          }}
          src={zoomPermissionScreen}
        />
        <img
          alt=""
          className="rounded-lg default-bottom-left-modal-border"
          style={{
            width: "400px",
            top: "200px",
            position: "absolute",
            left: "286px",
          }}
          src={zoomPermissionCheckBox}
        />
        <div
          className="absolute"
          style={{ left: "214px", bottom: "98px", transform: "rotate(-10deg)" }}
        >
          <Arrow />
        </div>
      </div>
    </div>
  );
}
