import React, { PureComponent } from "react";
import { Trash2 } from "react-feather";
import { connect } from "react-redux";
import { constructRequestURL } from "../../services/api";
import { withRouter } from "react-router-dom";
import {
  DoesEventHaveOtherAttendees,
  constructQueryParams,
  formatTimeForBackendJsDate,
  filterOutModalFromArray,
  getGoogleEventId,
  isCreator,
  getRRuleStringFromRecurrence,
  determineSyncWindow,
  constructEmailData,
  isOrganizer,
  getEmailBasedOnCalendarId,
  fetchEvent,
} from "../../services/commonUsefulFunctions";
import EventModalPopup from "../eventModalPopup";
import ShortcutHoverHint from "../shortcutHoverHint";
import GlobalKeyMapTile from "../globalKeyMapTile";
import Broadcast from "../../broadcasts/broadcast";
import WarningUpdateRecurringEvent from "../warningUpdateRecurringEvent";
import GoogleCalendarService, {
  DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
  GOOGLE_UPDATES,
} from "../../services/googleCalendarService";
import SendEmailUpdateModal from "../sendEmailUpdateModal";
import { createRecurrenceCutOffDate, getOriginalRecurringEventFromIndex, removeOriginalRecurringEventFromIndex } from "../../lib/recurringEventFunctions";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import {
  BACKEND_MONTH,
  FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import {
  getEmailFromUserCalendarID,
  getMatchingUserFromEvent,
  getUserEmailFromEvent,
} from "../../lib/calendarFunctions";
import {
  isEventFirstRecurringInstance,
  trimOriginalRecurrenceRule,
} from "../../lib/recurringEventFunctions";
import {
  getEventAttendees,
  getEventICalUID,
  getEventMasterEventID,
  getEventOrganizer,
  getEventOriginalStartTime,
  getEventStart,
  getEventTitle,
  getEventUserCalendarID,
} from "../../services/eventResourceAccessors";
import { trackEvent } from "../tracking";
import classNames from "classnames";
import { isVersionV2 } from "../../services/versionFunctions";
import {
  isCancelledEvent,
  isGoogleEvent,
  isOrganizerSelf,
  isOutlookEvent,
  isOutlookNonOrganizer,
} from "../../lib/eventFunctions";
import { createWindow } from "../../lib/stateManagementFunctions";
import { DELETE_RECURRING_TYPE } from "../../lib/vimcalFunctions";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { getSelectedDayWithBackup } from "../../lib/syncFunctions";
import { isEventHoldEvent } from "../../services/holdFunctions";
import { getDefaultHeaders } from "../../lib/fetchFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { getDeleteEventCopy } from "../../lib/copy";
import { lowerCaseAndTrimString, lowerCaseAndTrimStringWithGuard } from "../../lib/stringFunctions";
import { getUserToken } from "../../lib/userFunctions";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { isActionModeCreateAvailability } from "../../services/appFunctions";

const deleteButtonTile = {
  left: "-15px",
  top: "-19px",
};

const DELETE_EVENT_MULTIPLE_ATTENDEES = "delete event with multiple attendees";
const DELETE_RECURRING_EVENT = "delete recurring event";

const {
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
} = GoogleCalendarService;

class DeleteEventButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalContent: DELETE_EVENT_MULTIPLE_ATTENDEES,
      modalTitle:
        "There are other guests on this event. Do you still want to delete event?",
      modalArray: [],
      deleteRecurrenceType: null,
      shouldSendUpdates: true,
    };

    this.onDelete = this.onDelete.bind(this);
    this.onToggleModal = this.onToggleModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.deleteFollowingRecurringInstances =
      this.deleteFollowingRecurringInstances.bind(this);
    this.deleteAllInstancesOfRecurringEvent =
      this.deleteAllInstancesOfRecurringEvent.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.updateSendEmailUpdate = this.updateSendEmailUpdate.bind(this);
    this.updateRecurrenceDelete = this.updateRecurrenceDelete.bind(this);

    !this.props.shouldIgnoreBroadcast &&
      Broadcast.subscribe("CLICK_DELETE", this.onClickDelete);
    if (this.props.shouldHide) {
      // need this otherwise there will be race condition with unmount which leads to unsubscribe after this subscribes
      Broadcast.subscribe("POPUP_CLICK_DELETE", this.onClickDelete);
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (isEmptyObjectOrFalsey(this.props.event)) {
      return;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.props.shouldHide) {
      Broadcast.unsubscribe("POPUP_CLICK_DELETE");
    }

    !this.props.shouldIgnoreBroadcast && Broadcast.unsubscribe("CLICK_DELETE");
  }

  render() {
    const {
      event,
      containerMarginRight,
      shortCutStyle,
      shouldHide,
      actionMode,
    } = this.props;
    const hoverHintCopy = getDeleteEventCopy({event});
    const isCancelHintCopy = lowerCaseAndTrimStringWithGuard(hoverHintCopy).includes("cancel");
    return event && !isActionModeCreateAvailability(actionMode) ? (
      <>
        <ShortcutHoverHint
          left
          style={{ marginLeft: isCancelHintCopy ? "-146px" : "-104px", marginTop: "-9px", width: "max-content" }}
          title={hoverHintCopy}
          shortcut={"Del"}
          containerStyle={{
            marginRight: containerMarginRight
              ? containerMarginRight - 2
              : 0,
          }}
        >
          <GlobalKeyMapTile
            style={shortCutStyle || deleteButtonTile}
            shortcut={"Del"}
          />

          <Trash2
            size="15"
            className={classNames(
              "clickable-icon cursor-pointer",
              shouldHide ? "hidden" : ""
            )}
            id="deleteButton"
            onClick={this.onClickDelete}
          />
        </ShortcutHoverHint>

        {this.renderModal()}
      </>
    ) : null;
  }

  //================
  // RENDER METHODS
  //================

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.isModalOpen}
        onRequestClose={this.onToggleModal}
        width={450}
        title={this.state.modalTitle}
        style={determineDefaultModalStyle(this.props.isDarkMode)}
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  renderModalContent() {
    const {
      event
    } = this.props;
    switch (this.state.modalContent) {
      case DELETE_EVENT_MULTIPLE_ATTENDEES:
        return (
          <SendEmailUpdateModal
            onClickDismiss={this.onToggleModal}
            onClickDoNotSend={() => this.updateSendEmailUpdate(false)}
            sendUpdate={(message) => this.updateSendEmailUpdate(true, message)}
            hideDoNotSendButton={isOutlookEvent(event)}
          />
        );
      case DELETE_RECURRING_EVENT:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.onToggleModal}
            onClick={this.updateRecurrenceDelete}
            withoutThisAndFollowing={isOutlookNonOrganizer(event)}
          />
        );
      default:
        return <div></div>;
    }
  }

  //================
  // EVENT HANDLERS
  //================

  updateRecurrenceDelete(response) {
    let updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      DELETE_RECURRING_EVENT
    );

    this.setState(
      { modalArray: updatedModal, deleteRecurrenceType: response },
      this.confirmDelete
    );
  }

  updateSendEmailUpdate(response, message) {
    let updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      DELETE_EVENT_MULTIPLE_ATTENDEES
    );

    if (message?.length > 0) {
      trackEvent({
        category: "email",
        action: "email_attendees_delete_event",
        label: `attendees_count_${getEventAttendees(this.props.event)?.length}`,
        userToken: getUserToken(this.props.currentUser),
      });
    }

    this.setState(
      {
        shouldSendUpdates: response,
        modalArray: updatedModal,
        message: message,
      },
      this.confirmDelete
    );
  }

  async deleteAllInstancesOfRecurringEvent() {
    const {
      currentTimeZone,
      currentUser,
      event,
      selectedDay,
      selectedCalendarView,
      weekStart,
    } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const { shouldSendUpdates, message } = this.state;
    const { masterAccount } = this.props.masterAccount;

    let originalRecurringEvent = this.getOriginalRecurringEvent();

    if (!originalRecurringEvent) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Deleting..."
      );
      const response = await fetchEvent(
        getEventUserCalendarID(event),
        getEventMasterEventID(event),
        getUserEmailFromEvent(event, allCalendars)
      );
      if (!response?.event) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE
        );
        return;
      }
      originalRecurringEvent = response.event;
    }

    const isV2 = isVersionV2();
    const timeWindows = determineSyncWindow({
      selectedDay,
      selectedCalendarView,
      weekStart,
    });

    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    const { user_calendar_id } = originalRecurringEvent;
    const calendar_provider_id = getEmailFromUserCalendarID(
      getEventUserCalendarID(event),
      allCalendars
    );
    const event_provider_id = getGoogleEventId(event);

    const params = constructQueryParams({
      sendUpdates:
        shouldSendUpdates && !message
          ? GOOGLE_UPDATES.ALL
          : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(
        timeWindows.maxDate,
        weekStart,
        false
      ),
      ical_uid: getEventICalUID(event),
      // V1 specific fields
      ...(!isV2 && {
        google_calendar_id: calendar_provider_id,
        google_event_id: event_provider_id,
      }),
      // V2 specific fields
      ...(isV2 && {
        calendar_provider_id,
        event_provider_id,
        master_event_id: getEventMasterEventID(event),
      }),
    });

    const path = isV2
      ? "recurring_events/all"
      : `calendars/${user_calendar_id}/recurring_events/${event_provider_id}/all`;

    const emailData = constructEmailData({
      event,
      currentUser: getMatchingUserFromEvent({
        event, 
        allCalendars, 
        allLoggedInUsers
      }) ?? currentUser,
      subject: event?.summaryUpdatedWithVisibility || getEventTitle(event),
      currentTimeZone: event?.startTimeZone || currentTimeZone,
      masterAccount,
    });

    const payloadData = {
      body: JSON.stringify({
        ...emailData,
        ...(isV2 && {
          has_attendees: getEventAttendees(event),
          organizer: isOrganizerSelf(event),
          is_canceled: isCancelledEvent(event),
        }),
        message,
      }),
      headers: getDefaultHeaders(),
    };

    // delete all
    Broadcast.publish("DELETE_MULTIPLE_EVENT_REQUEST", {
      url: constructRequestURL(path, isV2) + `?${params}`,
      payloadData,
      userEmail: this.getUserEmail(),
      isDeleteAllEvents: true,
      deletedEvent: this.props.event,
      type: DELETE_RECURRING_TYPE.ALL,
    });
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    this.props.history.push("/home");
  }

  async deleteFollowingRecurringInstances({ cutoffDate, message }) {
    const { event, originalRecurrenceEventIndex } = this.props;
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      selectedDay,
      selectedCalendarView,
      weekStart
    } = this.props;
    let originalRecurringEvent = this.getOriginalRecurringEvent();
    if (!originalRecurringEvent) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Deleting..."
      );
      const response = await fetchEvent(
        getEventUserCalendarID(event),
        getEventMasterEventID(event),
        getUserEmailFromEvent(event, allCalendars)
      );
      if (!response?.event) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          FAILED_TO_DELETE_MULTIPLE_EVENTS_MESSAGE
        );
        return;
      }
      originalRecurringEvent = response.event;
    }
    const masterEventStartDate = getEventStart(originalRecurringEvent);
    const isFirstOccurrence = isEventFirstRecurringInstance(
      getEventOriginalStartTime(event),
      masterEventStartDate
    );
    if (isFirstOccurrence) {
      await this.deleteAllInstancesOfRecurringEvent();
      return;
    }
    const timeWindows = determineSyncWindow({
      selectedDay,
      selectedCalendarView,
      weekStart,
    });

    const sendUpdates =
      this.state.shouldSendUpdates && !message
        ? GOOGLE_UPDATES.ALL
        : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE;
    const timeMin = formatTimeForBackendJsDate(
      timeWindows.minDate,
      this.props.weekStart,
      true
    );
    const timeMax = formatTimeForBackendJsDate(
      timeWindows.maxDate,
      this.props.weekStart,
      false
    );
    const calendar_provider_id = getEmailFromUserCalendarID(
      getEventUserCalendarID(this.props.event),
      allCalendars
    );
    const event_provider_id = getGoogleEventId(this.props.event);
    const ical_uid = getEventICalUID(this.props.event);
    const master_event_id = getEventMasterEventID(this.props.event);

    let params = {};
    let path = "";
    let queryParams = {};
    let url = "";

    if (isVersionV2()) {
      params = {
        sendUpdates,
        timeMin,
        timeMax,
        calendar_provider_id,
        event_provider_id,
        ical_uid,
        master_event_id,
        master_event_start_date: masterEventStartDate.dateTime,
      };

      path = "recurring_events/following";
      queryParams = constructQueryParams(params);
      url = constructRequestURL(path, isVersionV2()) + `?${queryParams}`;
    } else {
      params = {
        sendUpdates,
        timeMin,
        timeMax,
        google_calendar_id: calendar_provider_id,
        google_event_id: event_provider_id,
        ical_uid,
      };

      path = `calendars/${getEventUserCalendarID(
        this.props.event
      )}/recurring_events/${master_event_id}`;
      queryParams = constructQueryParams(params);
      url = constructRequestURL(path) + `?${queryParams}`;
    }
    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer

    const { masterAccount } = this.props.masterAccount;
    const ruleString = getRRuleStringFromRecurrence(originalRecurringEvent);
    const organizer = getEventOrganizer(this.props.event);
    const updated_original_recurrence = trimOriginalRecurrenceRule(
      ruleString,
      cutoffDate
    );
    const requestBody = {
      organizer: organizer && organizer.self,
      updated_original_recurrence,
    };

    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      currentUser
    } = this.props;
    const emailData = constructEmailData({
      event: this.props.event,
      currentUser: getMatchingUserFromEvent({
        event, 
        allCalendars, 
        allLoggedInUsers
      }) ?? currentUser,
      subject:
        this.props.event?.summaryUpdatedWithVisibility ||
        getEventTitle(this.props.event),
      currentTimeZone:
        this.props.event?.startTimeZone || this.props.currentTimeZone,
      masterAccount,
    });
    const payloadData = {
      body: JSON.stringify({
        ...requestBody,
        ...emailData,
        message: message,
      }),
      headers: getDefaultHeaders(),
    };

    // delete this and following
    Broadcast.publish("DELETE_MULTIPLE_EVENT_REQUEST", {
      url,
      payloadData,
      userEmail: this.getUserEmail(),
      deletedEvent: this.props.event,
      type: DELETE_RECURRING_TYPE.THIS_AND_FOLLOWING,
    });
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    // reset the index. For outlook, outlook does not send us updated version of the masterEventID
    const updatedRecurringEventIndex = removeOriginalRecurringEventFromIndex({
      event,
      originalRecurrenceEventIndex,
    });
    this.props.setOriginalRecurrenceEventIndex(updatedRecurringEventIndex);
  }

  getUserEmail() {
    const { allCalendars } = this.props.allCalendars;

    return getUserEmailFromEvent(this.props.event, allCalendars);
  }

  createWindow() {
    const { selectedDay, selectedCalendarView, weekStart } = this.props;

    return createWindow({
      windowJSDate: getSelectedDayWithBackup(selectedDay), 
      isMonth: selectedCalendarView === BACKEND_MONTH, 
      weekStart, 
      selectedCalendarView
    });
  }

  onClickDelete() {
    const { allCalendars } = this.props.allCalendars;
    const { event } = this.props;
    let updatedModalArray = [];

    const isCreatorOrOrganizer = (() => {
      if (isCreator(event, allCalendars)) {
        return true;
      }
      if (isOrganizerSelf(event)) {
        return true;
      }

      return isOrganizer(
        getEventAttendees(event),
        getEmailBasedOnCalendarId(event, allCalendars),
      );
    })();

    const hasOtherAttendees = DoesEventHaveOtherAttendees(event, allCalendars);

    if (getEventMasterEventID(event)) {
      updatedModalArray = updatedModalArray.concat({
        modalContent: DELETE_RECURRING_EVENT,
        modalTitle: "Delete recurring event",
      });
    }

    if (isEventHoldEvent(event)) {
      // do nothing
    } else if (
      hasOtherAttendees &&
      isCreatorOrOrganizer
    ) {
      const getModalTitle = () => {
        if (isOutlookEvent(event)) {
          return "Cancel event and notify attendee";
        }
        return "Would you like to send cancellation emails to guests?";
      };

      updatedModalArray = updatedModalArray.concat({
        modalContent: DELETE_EVENT_MULTIPLE_ATTENDEES,
        modalTitle: getModalTitle(),
      });
    }

    this.setState({ modalArray: updatedModalArray }, this.confirmDelete);
  }

  async confirmDelete() {
    if (this.state.modalArray?.length > 0) {
      const currentModal = this.state.modalArray[0];
      this.setModalState(currentModal.modalContent, currentModal.modalTitle);
      return;
    }

    if (this.state.isModalOpen) {
      this.onToggleModal();
    }

    mainCalendarBroadcast.publish("SET_LAST_DECLINED_EVENT", this.props.event);
    
    const {
      event,
    } = this.props;
    switch (this.state.deleteRecurrenceType) {
      case EDIT_RECURRING_FOLLOWING_EVENTS:
        await this.deleteFollowingRecurringInstances({
          cutoffDate: createRecurrenceCutOffDate(event),
          message: this.state.message,
        });

        break;
      case EDIT_RECURRING_ALL_INSTANCES:
        await this.deleteAllInstancesOfRecurringEvent();

        break;
      case EDIT_RECURRING_INSTANCE_ONLY:
      default:
        this.onDelete(this.state.message);

        break;
    }
  }

  setModalState(content, title) {
    layoutBroadcast.publish("SET_LAST_MOUSETRAP_BIND_TIME");

    this.setState({
      modalContent: content,
      modalTitle: title,
      isModalOpen: true,
    });
  }

  onToggleModal() {
    layoutBroadcast.publish("SET_LAST_MOUSETRAP_BIND_TIME");

    this.setState((prevState, props) => {
      return {
        isModalOpen: !prevState.isModalOpen,
        modalArray: [],
      };
    });
  }

  onDelete(message) {
    Broadcast.publish("DELETE_EVENT", this.props.event, {
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(this.props.event),
        this.props.allCalendars.allCalendars
      ),
      event_provider_id: getGoogleEventId(this.props.event),
      sendUpdates: this.state.shouldSendUpdates,
      userEmail: this.getUserEmail(),
      message: message,
    });
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

    this.props.history.push("/home");
  }

  getOriginalRecurringEvent() {
    return getOriginalRecurringEventFromIndex(
      this.props.event,
      this.props.originalRecurrenceEventIndex
    );
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    isDarkMode,
    originalRecurrenceEventIndex,
    selectedDay,
    selectedCalendarView,
    weekStart,
    actionMode,
  } = state;

  return {
    currentUser,
    currentTimeZone,
    isDarkMode,
    originalRecurrenceEventIndex,
    selectedDay,
    selectedCalendarView,
    weekStart,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOriginalRecurrenceEventIndex: (data) =>
      dispatch({ data, type: "SET_ORIGINAL_RECURRENCE_EVENT_INDEX" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(DeleteEventButton)));
