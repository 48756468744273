import React, { Component } from "react";
import ColoredLine from "./line";
import { constructRequestURL, constructRequestURLV2 } from "../services/api";
import {
  getEmailBasedOnCalendarId,
  constructQueryParams,
  getGoogleEventId,
  getLastDayOfMonthlyCalendarJSDate,
  getFirstDayOfMonthlyCalendarJSDate,
  getLastDayOfWeekJsDate,
  getRRuleStringFromRecurrence,
  formatEventForReactBigCalendar,
  hasEventPreventDefault,
  hasStopEventPropagation,
  isValidJSDate,
  handleError,
  isAfterMinute,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  GOOGLE_UPDATES,
} from "../services/googleCalendarService";
import StyleConstants, {
  DEFAULT_FONT_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import { connect } from "react-redux";
import Broadcast from "../broadcasts/broadcast";
import GlobalKeyMapTile from "./globalKeyMapTile";
import EventModalPopup from "./eventModalPopup";
import WarningUpdateRecurringEvent from "./warningUpdateRecurringEvent";
import { addMinutes, addWeeks, differenceInMinutes, parseISO } from "date-fns";
import classNames from "classnames";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getCalendarFromUserCalendarID,
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
} from "../lib/calendarFunctions";
import {
  getEventAttendees,
  getEventEndJSDate,
  getEventICalUID,
  getEventID,
  getEventMasterEventID,
  getEventOrganizer,
  getEventStartJSDate,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
  getOutlookResponseRequested,
} from "../services/eventResourceAccessors";
import {
  allowOutlookEventProposal,
  getEventStartDateTime,
  getHumanAttendees,
  getSelfRSVPComment,
  getSelfRSVPStatus,
  isGoogleEvent,
  isOrganizerSelf,
  isOutlookEvent,
  isOutlookNonOrganizer,
  shouldShowProposeTime,
} from "../lib/eventFunctions";
import {
  EVENT_RESPONSE_MORE_OPTIONS,
  OUTLOOK_PROPOSE_TIME_OPTIONS_ID,
  RSVP_SECTION_ID,
} from "../services/elementIDVariables";
import { isVersionV2 } from "../services/versionFunctions";
import { createUpdatedSingleEventWithEventResponse } from "../lib/mimicEventUpdate";
import {
  createRecurrenceCutOffDate,
  getOriginalRecurringEventFromIndex,
  trimOriginalRecurrenceRule,
} from "../lib/recurringEventFunctions";
import ProposeTimeIcon from "./icons/proposeTimeIcon";
import { onClickGoogleProposeTime } from "../lib/googleFunctions";
import ShortcutHoverHint from "./shortcutHoverHint";
import SendEmailUpdateModal from "./sendEmailUpdateModal";
import { isOutlookEventAndOrganizer } from "../lib/outlookFunctions";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { areSameHourAndMinute, updateDatePreserveTime, updateTimePreserveDate } from "../lib/dateFunctions";
import { BACKEND_BROADCAST_VALUES, BROADCAST_VALUES, EXPANDED_VIEW_BROADCAST_VALUES } from "../lib/broadcastValues";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { Feather, MoreHorizontal, X } from "react-feather";
import AddGoogleRSVPNote from "./expandedView/addGoogleRSVPNote";
import { isSameEmail, isValidEmail } from "../lib/stringFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import {
  getModalBackgroundColorWithNoOpacity,
  getSidePoppedOverModalBorder,
  SCHEDULING_ASSISTANT_MODAL_WIDTH,
} from "../lib/styleFunctions";
import expandedEventViewBroadcast from "../broadcasts/expandedEventViewBroadcast";
import { determineDefaultModalStyle, getSchedulingAssistantModalStyles, MODAL_OVERLAY_Z_INDEXES } from "../lib/modalFunctions";
import SchedulingAssistantContainer from "./scheduleAssistant/schedulingAssistantContainer";
import { getUserEmail } from "../lib/userFunctions";
import { fetcherPost } from "../services/fetcherFunctions";
import OutlookProposeTimeNote from "./outlookProposeTimeNote";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { getCalendarObject } from "../services/calendarAccessors";

let {
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
} = GoogleCalendarService;

let {
  attendee_event_attending,
  attendee_event_tentative,
  attendee_event_declined,
} = GoogleCalendarService;

const ALL_RESPONSE_OPTIONS = [
  ["Yes", attendee_event_attending, "GY"],
  ["No", attendee_event_declined, "GN"],
  ["Maybe", attendee_event_tentative, "GM"],
];

const OUTLOOK_OWNER_OPTIONS = [
  ["Yes", attendee_event_attending, "GY"],
  ["No", attendee_event_declined, "GN"],
];

let responseTileStyle = {
  left: "0px",
  top: "-30px",
  fontSize: "24px",
};

const MODAL_TYPE = {
  RECURRING: "RECURRING",
  EMAIL: "EMAIL",
  ADD_NOTE: "ADD_NOTE",
  MORE_OPTIONS: "MORE_OPTIONS",
  OUTLOOK_PROPOSE_TIME_OPTIONS: "OUTLOOK_PROPOSE_TIME_OPTIONS",
  SCHEDULING_ASSISTANT: "SCHEDULING_ASSISTANT",
  PROPOSE_TIME_NOTE: "PROPOSE_TIME_NOTE",
};

const RECURRING_TYPE = {
  EDIT_RECURRING_FOLLOWING_EVENTS: EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES: EDIT_RECURRING_ALL_INSTANCES,
  EDIT_RECURRING_INSTANCE_ONLY: EDIT_RECURRING_INSTANCE_ONLY,
};

// tentativelyAccepted or decline are outlook variables
const OUTLOOK_PROPOSE_TIME = {
  TENTATIVE: "tentativelyAccept",
  DECLINE: "decline",
};

class EventResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: getSelfRSVPStatus(props.event, this.getMatchingEventEmail()),
      isModalOpen: false,
      modalTitle: this.determineModalType(MODAL_TYPE.RECURRING),
      modalType: MODAL_TYPE.RECURRING,
      selectedRSVPRecurringType: undefined,
      selectedOutlookProposeTimeType: null, // OUTLOOK_PROPOSE_TIME.TENTATIVE or OUTLOOK_PROPOSE_TIME.DECLINE
      outlookProposedTimeStart: null,
      outlookProposedTimeEnd: null,
    };

    this.updateAttendance = this.updateAttendance.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.onRSVP = this.onRSVP.bind(this);
    this.onSelectRecurringRSVP = this.onSelectRecurringRSVP.bind(this);
    this.constructOutlookRsvpFollowingParams =
      this.constructOutlookRsvpFollowingParams.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.sendEmailToOrganizer = this.sendEmailToOrganizer.bind(this);
    this.onClickDoNotSendEmailToOrganizer =
      this.onClickDoNotSendEmailToOrganizer.bind(this);
    this.openEditGoogleRSVPNoteModal =
      this.openEditGoogleRSVPNoteModal.bind(this);
    this.onClickSendGoogleRSVPNote = this.onClickSendGoogleRSVPNote.bind(this);
    this.onClickGoogleProposeTime = this.onClickGoogleProposeTime.bind(this);
    this.onClickOutlookProposeTime = this.onClickOutlookProposeTime.bind(this);
    this.onClickTentativeAndProposeTime = this.onClickTentativeAndProposeTime.bind(this);
    this.onClickDeclineAndProposeTime = this.onClickDeclineAndProposeTime.bind(this);
    this.onClickShowSchedulingAssistantModal = this.onClickShowSchedulingAssistantModal.bind(this);
    
    // for outlook propose time
    this.setOutlookProposedStartDate = this.setOutlookProposedStartDate.bind(this);
    this.setOutlookProposedStartTime = this.setOutlookProposedStartTime.bind(this);
    this.setOutlookProposedEndDate = this.setOutlookProposedEndDate.bind(this);
    this.setOutlookProposedEndTime = this.setOutlookProposedEndTime.bind(this);
    this.setOutlookProposedDateTime = this.setOutlookProposedDateTime.bind(this);
    this.onSubmitOutlookProposedTime = this.onSubmitOutlookProposedTime.bind(this);
    this.onClickShowProposeTimeNoteModal = this.onClickShowProposeTimeNoteModal.bind(this);
    this.onCloseSchedulingAssistantModal = this.onCloseSchedulingAssistantModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.shouldHide) {
      // to prevent race condition where this unmounts after new invisible container gets mounted
      Broadcast.subscribe("POPUP_ACCEPT_EVENT", () =>
        this.updateAttendance(attendee_event_attending)
      );
      Broadcast.subscribe("POPUP_DECLINE_EVENT", () =>
        this.updateAttendance(attendee_event_declined)
      );
      Broadcast.subscribe("POPUP_MAYBE_ATTENDING_EVENT", () =>
        this.updateAttendance(attendee_event_tentative)
      );
      expandedEventViewBroadcast.subscribe(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME, this.onClickTentativeAndProposeTime);
      expandedEventViewBroadcast.subscribe(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME, this.onClickDeclineAndProposeTime);
    } else {
      Broadcast.subscribe("ACCEPT_EVENT", () =>
        this.updateAttendance(attendee_event_attending)
      );
      Broadcast.subscribe("DECLINE_EVENT", () =>
        this.updateAttendance(attendee_event_declined)
      );
      Broadcast.subscribe("MAYBE_ATTENDING_EVENT", () =>
        this.updateAttendance(attendee_event_tentative)
      );
      expandedEventViewBroadcast.subscribe(EXPANDED_VIEW_BROADCAST_VALUES.ADD_GOOGLE_RSVP_COMMENT, this.openEditGoogleRSVPNoteModal);
      expandedEventViewBroadcast.subscribe(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME, this.onClickTentativeAndProposeTime);
      expandedEventViewBroadcast.subscribe(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME, this.onClickDeclineAndProposeTime);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      getEventUserEventID(prevProps.event) !==
      getEventUserEventID(this.props.event)
    ) {
      this.setState({
        status: getSelfRSVPStatus(
          this.props.event,
          this.getMatchingEventEmail()
        ),
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.shouldHide) {
      Broadcast.unsubscribe("POPUP_ACCEPT_EVENT");
      Broadcast.unsubscribe("POPUP_DECLINE_EVENT");
      Broadcast.unsubscribe("POPUP_MAYBE_ATTENDING_EVENT");
      expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME);
      expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME);
    } else {
      Broadcast.unsubscribe("ACCEPT_EVENT");
      Broadcast.unsubscribe("DECLINE_EVENT");
      Broadcast.unsubscribe("MAYBE_ATTENDING_EVENT");
      expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.ADD_GOOGLE_RSVP_COMMENT);
      expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME);
      expandedEventViewBroadcast.unsubscribe(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME);
    }
  }

  render() {
    return (
      <div
        id={RSVP_SECTION_ID}
        className={classNames(
          this.props.isPopUpEvent ? "" : "mt-2.5",
          this.props.isPopUpEvent
            ? "popup-event-response-wrapper"
            : "event-response-wrapper",
          this.props.shouldHide ? "hidden" : ""
        )}
      >
        <ColoredLine />

        <div className="attendance-response-wrapper">
          <div className="attending-question-title">Going?</div>

          <div className="flex items-center">
            <div className="event-response-answers">
              {this.renderResponses()}
            </div>
            {isGoogleEvent(this.props.event) ? this.renderMoreGoogleEventOptions() : this.renderMoreOutlookEventOptions()}
          </div>
        </div>
        {this.renderRSVPComment()}

        {this.renderModal()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderAddNote() {
    if (!this.isGoogleEvent() || this.getSelfRSVPComment()) {
      return null;
    }
    return (
      <ShortcutHoverHint
        above
        style={{ bottom: "45px", right: "0px", width: "max-content" }}
        title={"Add note"}
      >
        <div
          className="event-response-answers ml-2 items-center justify-center cursor-pointer hoverable-secondary-text-color"
          style={{ height: 37, width: 36 }}
          onClick={this.openEditGoogleRSVPNoteModal}
        >
          <Feather className="fill-transparent" size={17} />
        </div>
      </ShortcutHoverHint>
    );
  }

  openEditGoogleRSVPNoteModal() {
    this.setState({ modalType: MODAL_TYPE.ADD_NOTE, isModalOpen: true });
  }

  renderRSVPComment() {
    const comment = this.getSelfRSVPComment();
    if (!this.isGoogleEvent() || !comment) {
      return null;
    }

    const onClickPill = () => {
      this.openEditGoogleRSVPNoteModal();
    };

    const onClickDelete = (e) => {
      hasEventPreventDefault(e);
      hasStopEventPropagation(e);
      this.onClickSendGoogleRSVPNote({ note: "" });
    };

    return (
      <div className="flex w-full justify-end pr-2 pb-2">
        <div
          className={classNames(
            "py-1 px-2 pill-border w-max select-none cursor-pointer",
            "flex items-center gap-2",
            "hoverable-secondary-text-color"
          )}
          onClick={onClickPill}
        >
          <div className="truncate-text max-width-240">{`Edit note: ${comment}`}</div>
          <X size={12} onClick={onClickDelete} className="clickable-icon" />
        </div>
      </div>
    );
  }

  renderMoreOutlookEventOptions() {
    const {
      event,
    } = this.props;
    if (!allowOutlookEventProposal(event)) {
      return null;
    }
    return this.renderOutlookProposeTime();
  }

  renderMoreGoogleEventOptions() {
    const { allCalendars } = this.props.allCalendars;
    const { event } = this.props;
    const shouldRenderProposeTime = shouldShowProposeTime({
      event,
      allCalendars,
    });
    const shouldShowGoogleAddNote =
      this.isGoogleEvent() && !this.getSelfRSVPComment();
    if (!shouldShowGoogleAddNote && !shouldRenderProposeTime) {
      return null;
    }
    if (shouldRenderProposeTime && !shouldShowGoogleAddNote) {
      return this.renderGoogleProposeTime();
    }
    if (!shouldRenderProposeTime && shouldShowGoogleAddNote) {
      return this.renderAddNote();
    }
    return (
      <div
        className="event-response-answers ml-2 cursor-pointer hoverable-secondary-text-color"
        style={{ height: 37 }}
        onClick={() => {
          const element = document.getElementById(EVENT_RESPONSE_MORE_OPTIONS);
          if (!element) {
            return;
          }
          const rect = element.getBoundingClientRect();
          this.setState({
            isModalOpen: true,
            modalType: MODAL_TYPE.MORE_OPTIONS,
            moreOptionsTop: rect.top - 92,
            moreOptionsLeft: rect.left - 68,
          });
        }}
        id={EVENT_RESPONSE_MORE_OPTIONS}
      >
        <MoreHorizontal className="" />
      </div>
    );
  }

  renderOutlookProposeTime() {
    return (
      <ShortcutHoverHint
        above
        style={{ bottom: "45px", right: "0px", width: "max-content" }}
        title={"Propose new time"}
      >
        <div
          className="event-response-answers ml-2 cursor-pointer svg-child-hoverable-secondary-text-color"
          style={{ height: 37 }}
          onClick={this.onClickOutlookProposeTime}
          id={OUTLOOK_PROPOSE_TIME_OPTIONS_ID}
        >
          <ProposeTimeIcon className="" />
        </div>
      </ShortcutHoverHint>
    );
  }

  renderGoogleProposeTime() {
    const { allCalendars } = this.props.allCalendars;
    const { event } = this.props;
    if (!shouldShowProposeTime({ event, allCalendars })) {
      return null;
    }
    return (
      <ShortcutHoverHint
        above
        style={{ bottom: "45px", right: "0px", width: "max-content" }}
        title={"Propose time"}
      >
        <div
          className="event-response-answers ml-2 cursor-pointer svg-child-hoverable-secondary-text-color"
          style={{ height: 37 }}
          onClick={this.onClickGoogleProposeTime}
        >
          <ProposeTimeIcon className="" />
        </div>
      </ShortcutHoverHint>
    );
  }

  onClickOutlookProposeTime() {
    const element = document.getElementById(OUTLOOK_PROPOSE_TIME_OPTIONS_ID);
    if (!element) {
      return;
    }
    const rect = element.getBoundingClientRect();
    this.setState({
      modalType: MODAL_TYPE.OUTLOOK_PROPOSE_TIME_OPTIONS,
      isModalOpen: true,
      moreOptionsTop: rect.top - 92,
      moreOptionsLeft: rect.left - 182,
    });
  }

  onClickGoogleProposeTime() {
    const { event } = this.props;
    onClickGoogleProposeTime({ event });
  }

  renderResponses() {
    const { event } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const responses = isOutlookEventAndOrganizer({
      event,
      allCalendars,
    })
      ? OUTLOOK_OWNER_OPTIONS
      : ALL_RESPONSE_OPTIONS;

    return responses.map((response, index) => {
      return (
        <div key={`$response_attending_${response}_${index}`}>
          <GlobalKeyMapTile style={responseTileStyle} shortcut={response[2]} />

          <button
            key={`eventResponse${index}`}
            style={this.determineResponseButtonStyle(response)}
            onClick={() => this.updateAttendance(response[1])}
            className={classNames(
              "hoverable-container-text duration-200",
              index !== 2 ? "mr-1" : ""
            )}
          >
            {response[0]}
          </button>
        </div>
      );
    });
  }

  renderModal() {
    const { isDarkMode } = this.props;
    const { isModalOpen, moreOptionsTop, moreOptionsLeft } = this.state;
    const getStyle = () => {
      if (this.isMoreOptionsModal()) {
        return {
          overlay: {
            position: "fixed",
            inset: 0,
            backgroundColor: "transparent",
            zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
          },
          content: {...({
            padding: "0px",
            position: "absolute",
            top: moreOptionsTop,
            right: "auto",
            left: moreOptionsLeft,
            bottom: "auto",
            zIndex: 5,
            backgroundColor: getModalBackgroundColorWithNoOpacity(isDarkMode),
            color: isDarkMode ? StyleConstants.darkModeModalTextColor : "",
          }),
          ...getSidePoppedOverModalBorder(isDarkMode),
          },
        };
      }

      if (this.state.modalType === MODAL_TYPE.SCHEDULING_ASSISTANT) {
        return getSchedulingAssistantModalStyles(isDarkMode);
      }

      const style = determineDefaultModalStyle(isDarkMode);
      if (this.isAddCommentModal()) {
        style.content.overflow = "visible";
      }
      return style;
    };

    return (
      <EventModalPopup
        isOpen={isModalOpen}
        onRequestClose={() => {
          if (this.state.modalType === MODAL_TYPE.SCHEDULING_ASSISTANT) {
            this.onCloseSchedulingAssistantModal();
            return;
          }
          this.closeModal();
        }}
        width={this.getModalWidth()}
        title={this.determineModalType()}
        style={getStyle()}
        hideCloseButton={this.isMoreOptionsModal()}
        hideTitle={this.isMoreOptionsModal()}
      >
        {this.determineModal()}
      </EventModalPopup>
    );
  }

  closeSchedulingAssistantModal() {
    this.setState({
      modalType: null,
      isModalOpen: false,
    });
  }

  isAddCommentModal() {
    return this.state.modalType === MODAL_TYPE.ADD_NOTE;
  }

  getModalWidth() {
    if (this.state.modalType === MODAL_TYPE.SCHEDULING_ASSISTANT) {
      return SCHEDULING_ASSISTANT_MODAL_WIDTH;
    }
    return this.isMoreOptionsModal() ? "max-content" : 450;
  }

  onClickSendGoogleRSVPNote({ note, rsvp }) {
    // to delete note, just send in "" or null
    if (!note && !this.getSelfRSVPComment()) {
      this.closeModal();
      return; // don't do anything if there's no note to delete
    }
    // this only affects this event (do not update recurring events)
    const { event } = this.props;
    const { allCalendars } = this.props.allCalendars;

    const constructAttendees = () => {
      const previousAttendees = getEventAttendees(event);
      const email = this.getMatchingEventEmail();
      const attendeesListWithoutSelf = previousAttendees.filter(
        (attendee) => !isSameEmail(getObjectEmail(attendee), email)
      );
      const currentSelfAttendance =
        previousAttendees.filter((attendee) =>
          isSameEmail(getObjectEmail(attendee), email)
        )?.[0] ?? {};

      const selfAttendance = {
        ...currentSelfAttendance,
        email,
        self: true,
        comment: note,
        responseStatus: rsvp ?? this.state.status,
      };

      return {
        attendees: [...attendeesListWithoutSelf, selfAttendance],
      };
    };

    const eventAttendingStatus = constructAttendees();
    const isV2 = isVersionV2();
    const eventProviderId = getGoogleEventId(event);
    const calendarProviderId = getEmailFromUserCalendarID(
      getEventUserCalendarID(event),
      allCalendars
    );

    const payloadData = {
      body: JSON.stringify({
        user_event_id: getEventUserEventID(event),
        event_provider_id: eventProviderId,
        calendar_provider_id: calendarProviderId,
        event: eventAttendingStatus,
        organizer: isOrganizerSelf(event),
        master_event_id: getEventMasterEventID(event),
      }),
    };

    const params = constructQueryParams({ sendUpdates: GOOGLE_UPDATES.ALL });
    const path = "events/rsvp";

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      note ? "Deleting note..." : "Note saved"
    );
    Broadcast.publish(
      BROADCAST_VALUES.UPDATE_EVENT,
      {
        url: `${constructRequestURL(path, isV2)}?${params}`,
        payloadData,
        remapWeeklyCalendarHotKeys: true,
        userEmail: getUserEmailFromEvent(event, allCalendars),
        originalEvent: event,
        updatedTemporaryEvent: createUpdatedSingleEventWithEventResponse({
          event,
          eventAttendingStatus: eventAttendingStatus,
        }),
      },
      isV2
    );

    if (note) {
      // only review if added note;
      this.removePreviewEvent();
    }
  }

  onClickTentativeAndProposeTime() {
    this.setUpProposeTimeModal(OUTLOOK_PROPOSE_TIME.TENTATIVE);
  }

  setUpProposeTimeModal(selectedOutlookProposeTimeType) {
    const { event } = this.props;
    const attendees = getEventAttendees(event);
    if (!isEmptyArrayOrFalsey(attendees)) {
      Broadcast.publish("ON_UPDATE_EVENT_FORM_EMAIL", {
        prevEmails: null,
        newEmails: attendees.map((attendee) => getObjectEmail(attendee)).filter(email => isValidEmail(email)),
        userEmail: getEventUserEmail(event),
      });
    }

    this.setState({
      selectedOutlookProposeTimeType,
      outlookProposedTimeStart: getEventStartJSDate(event),
      outlookProposedTimeEnd: getEventEndJSDate(event),
    }, () => {
      this.onClickShowSchedulingAssistantModal();
    });
  }

  onClickDeclineAndProposeTime() {
    this.setUpProposeTimeModal(OUTLOOK_PROPOSE_TIME.DECLINE);
  }

  renderOutlookProposeTimeOptions() {
    const SHARED_CLASS_NAME =
      "p-3 modal-text-options-select modal-text-hover-override flex justify-between select-none";

    return (
      <div className={classNames("default-font-size cursor-pointer")}>
        <div
          className={classNames(SHARED_CLASS_NAME, "flex items-center")}
          onClick={this.onClickTentativeAndProposeTime}
        >
          Tentative and propose new time
        </div>
        <div
          className={classNames(SHARED_CLASS_NAME)}
          onClick={this.onClickDeclineAndProposeTime}
        >
          Decline and propose new time
        </div>
      </div>
    );
  }

  renderMoreOptionsModalContent() {
    const SHARED_CLASS_NAME =
      "p-3 modal-text-options-select modal-text-hover-override flex justify-between select-none";
    return (
      <div className={classNames("default-font-size cursor-pointer")}>
        <div
          className={classNames(SHARED_CLASS_NAME, "flex items-center")}
          onClick={this.onClickGoogleProposeTime}
        >
          Propose time
        </div>
        <div
          className={classNames(SHARED_CLASS_NAME)}
          onClick={this.openEditGoogleRSVPNoteModal}
        >
          Add note
        </div>
      </div>
    );
  }

  isMoreOptionsModal() {
    return this.state.modalType === MODAL_TYPE.MORE_OPTIONS
      || this.state.modalType === MODAL_TYPE.OUTLOOK_PROPOSE_TIME_OPTIONS;
  }

  onClickShowSchedulingAssistantModal() {
    this.setState({
      modalType: MODAL_TYPE.SCHEDULING_ASSISTANT,
      isModalOpen: true,
      modalTitle: this.determineModalType(MODAL_TYPE.SCHEDULING_ASSISTANT),
    });
  }

  onClickShowProposeTimeNoteModal({startTime, endTime}) {
    Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
    mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
    this.setState({
      modalType: MODAL_TYPE.PROPOSE_TIME_NOTE,
      isModalOpen: true,
      modalTitle: this.determineModalType(MODAL_TYPE.PROPOSE_TIME_NOTE),
      outlookProposedTimeStart: startTime,
      outlookProposedTimeEnd: endTime,
    });
  }

  determineModal() {
    const { event } = this.props;
    const { outlookProposedTimeStart, outlookProposedTimeEnd } = this.state;
    switch (this.state.modalType) {
      case MODAL_TYPE.PROPOSE_TIME_NOTE:
        return (
          <OutlookProposeTimeNote
            startTime={outlookProposedTimeStart}
            endTime={outlookProposedTimeEnd}
            onClose={this.closeModal}
            onSubmit={this.onSubmitOutlookProposedTime}
          />
        );
      case MODAL_TYPE.SCHEDULING_ASSISTANT:
        return (
          <SchedulingAssistantContainer
            attendees={getHumanAttendees(event)}
            selectedUser={this.getMatchingUserForEvent()}
            eventStart={outlookProposedTimeStart}
            eventEnd={outlookProposedTimeEnd}
            calendar={this.getMatchingCalendarForEvent()}
            setEventStartDate={this.setOutlookProposedStartDate}
            setEventStartTime={this.setOutlookProposedStartTime}
            setEventEndTime={this.setOutlookProposedEndTime}
            setEventDateAndTime={this.setOutlookProposedDateTime}
            buttonCopy="Done"
            onSubmit={this.onClickShowProposeTimeNoteModal}
            onClose={this.onCloseSchedulingAssistantModal}
          />
        );
      case MODAL_TYPE.OUTLOOK_PROPOSE_TIME_OPTIONS:
        return this.renderOutlookProposeTimeOptions();
      case MODAL_TYPE.MORE_OPTIONS:
        return this.renderMoreOptionsModalContent();
      case MODAL_TYPE.ADD_NOTE:
        return (
          <AddGoogleRSVPNote
            onClose={this.closeModal}
            currentNote={this.getSelfRSVPComment()}
            onSave={this.onClickSendGoogleRSVPNote}
            currentRSVP={this.state.status}
          />
        );
      case MODAL_TYPE.EMAIL:
        return (
          <SendEmailUpdateModal
            onClickDismiss={this.closeModal}
            onClickDoNotSend={this.onClickDoNotSendEmailToOrganizer}
            sendUpdate={this.sendEmailToOrganizer}
            placeHolderText="Add a message to the organizer"
          />
        );
      default:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.onSelectRecurringRSVP}
            withoutThisAndFollowing={isOutlookNonOrganizer(event)}
          />
        );
    }
  }

  //================
  // EVENT HANDLERS
  //================

  onSelectRecurringRSVP(option) {
    if (this.shouldShowEmailModal()) {
      this.setState({ selectedRSVPRecurringType: option });
      this.openEmailModal();
      return;
    }

    if (this.state.isModalOpen) {
      this.closeModal();
    }

    this.rsvpEvent({ recurringType: option });
  }

  rsvpEvent({ recurringType, message, sendResponse = true }) {
    switch (recurringType) {
      case RECURRING_TYPE.EDIT_RECURRING_FOLLOWING_EVENTS:
        this.rsvpFollowing({
          cutoffDate: this.props.event.defaultStartTime,
          cutoffZone: this.props.event.startTimeZone,
          message,
        });
        break;
      case RECURRING_TYPE.EDIT_RECURRING_ALL_INSTANCES:
        this.rsvpAll({
          windowStart: this.props.event.defaultStartTime,
          windowEnd: this.props.event.startTimeZone,
          message,
        });
        break;
      case RECURRING_TYPE.EDIT_RECURRING_INSTANCE_ONLY:
      default:
        this.updateEvent({ message, sendResponse });
        break;
    }
  }

  openRecurringModal() {
    this.setState({
      isModalOpen: true,
      modalTitle: this.determineModalType(MODAL_TYPE.RECURRING),
      modalType: MODAL_TYPE.RECURRING,
    });
  }

  openEmailModal() {
    this.setState({
      isModalOpen: true,
      modalTitle: this.determineModalType(MODAL_TYPE.EMAIL),
      modalType: MODAL_TYPE.EMAIL,
    });
  }

  determineModalType(inputState) {
    switch (inputState ?? this.state.modalType) {
      case MODAL_TYPE.ADD_NOTE:
        return "Add a note";
      case MODAL_TYPE.RECURRING:
        return "RSVP to recurring event";
      case MODAL_TYPE.EMAIL:
        return "Would you like to send a note to the organizer?";
      case MODAL_TYPE.SCHEDULING_ASSISTANT:
        return "Propose a new time";
      case MODAL_TYPE.PROPOSE_TIME_NOTE:
        return "Add a note";
      default:
        return "RSVP to recurring event";
    }
  }

  closeModal() {
    layoutBroadcast.publish("SET_LAST_MOUSETRAP_BIND_TIME");
    this.setState({
      isModalOpen: false,
      moreOptionsTop: null,
      moreOptionsLeft: null,
      selectedOutlookProposeTimeType: null,
      outlookProposedTimeStart: null,
      outlookProposedTimeEnd: null,
    });
  }

  onCloseSchedulingAssistantModal() {
    Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
    mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
    this.closeModal();
  }

  updateAttendance(newStatus) {
    this.setState(
      {
        status: newStatus,
      },
      this.onRSVP
    );
  }

  setDisappearingMessage() {
    let message;

    switch (this.state.newStatus) {
      case attendee_event_attending:
        message = "attending";
        break;
      case attendee_event_tentative:
        message = "maybe attending";
        break;
      case attendee_event_declined:
        message = "not attending";
        break;
      default:
        break;
    }

    if (message) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `You marked ${message}.`
      );
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  onClickDoNotSendEmailToOrganizer() {
    this.closeModal();
    this.rsvpEvent({ recurringType: this.state.selectedRSVPRecurringType, sendResponse: false });
  }

  sendEmailToOrganizer(message) {
    this.closeModal();
    this.rsvpEvent({ recurringType: this.state.selectedRSVPRecurringType, message });
  }

  determineResponseButtonStyle(response) {
    const { event, isDarkMode } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const email = getEmailBasedOnCalendarId(event, allCalendars);
    const isOptionSelected = response[1] === getSelfRSVPStatus(event, email);

    const determineSelectedOptionBackgroundColor = () => {
      if (isOptionSelected) {
        return isDarkMode ? "#444754" : "white";
      }

      return "transparent";
    };

    return {
      fontWeight: "400",
      backgroundColor: determineSelectedOptionBackgroundColor(),
      fontSize: 12,
      color: isOptionSelected ? this.determineDefaultTextColor() : null,
      borderRadius: 6,
      boxShadow: isOptionSelected
        ? "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px"
        : "",
    };
  }

  determineDefaultTextColor() {
    return this.props.isDarkMode
      ? StyleConstants.darkModeTextColor
      : DEFAULT_FONT_COLOR;
  }

  onRSVP() {
    if (getEventMasterEventID(this.props.event)) {
      if (this.isOneOffRecurringInstance()) {
        this.updateEvent({});
        return;
      }
      this.openRecurringModal();
    } else if (this.shouldShowEmailModal()) {
      this.openEmailModal();
    } else {
      this.updateEvent({});
    }

    if (this.state.status === attendee_event_declined) {
      mainCalendarBroadcast.publish(
        "SET_LAST_DECLINED_EVENT",
        this.props.event
      );
    } else {
      Broadcast.publish("SET_LAST_SELECTED_EVENT", this.props.event);
    }
  }

  getHumanReadableStatus() {
    switch (this.state.status) {
      case attendee_event_tentative:
        return "Tentative";
      case attendee_event_declined:
        return "Declined";
      case attendee_event_attending:
        return "Accepted";
      default:
        return "Accepted";
    }
  }

  shouldShowEmailModal() {
    const { event } = this.props;

    if (getEventOrganizer(event)?.self) {
      return false;
    }

    if (isOutlookEvent(event) && !getOutlookResponseRequested(event)) {
      return false;
    }

    return (
      isOutlookEvent(this.props.event) &&
      getEventAttendees(this.props.event)?.length > 0
    );
  }

  rsvpAll({windowStart, windowEnd, message}) {
    const { event, weekStart } = this.props;
    const { allCalendars } = this.props.allCalendars;

    const isV2 = isVersionV2();
    const eventProviderId = getGoogleEventId(event);
    const { self: isOrganizer } = getEventOrganizer(event) || {};
    const userCalendarId = getEventUserCalendarID(event);
    const masterEventId = getEventMasterEventID(event);
    const hasRequestedResponse = isOutlookEvent(event) && getOutlookResponseRequested(event);

    const params = constructQueryParams({
      ical_uid: getEventICalUID(event),
      timeMin: getFirstDayOfMonthlyCalendarJSDate(
        parseISO(windowStart),
        weekStart
      ).toISOString(),
      timeMax: getLastDayOfMonthlyCalendarJSDate(
        parseISO(windowStart),
        weekStart
      ).toISOString(),
      time_zone: windowEnd,
      // V1 specific fields
      ...(!isV2 && {
        google_event_id: eventProviderId,
      }),
      // V2 specific fieldså
      ...(isV2 && {
        event_provider_id: eventProviderId,
        calendar_provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(event),
          allCalendars
        ),
        master_event_id: masterEventId,
      }),
    });

    this.setDisappearingMessage();
    Broadcast.publish(
      BROADCAST_VALUES.UPDATE_INSTANCES,
      {
        path: isV2
          ? `recurring_events/rsvp/all?${params}`
          : `/calendars/${userCalendarId}/recurring_events/${masterEventId}/rsvp_all?${params}`,
        body: {
          event: this.constructUpdateEventAttendingStatus({isRecurring: true, message}),
          ...(isV2 && {
            organizer: isOrganizer,
            send_response: hasRequestedResponse ? true : false,
          }),
        },
        originalEvent: event,
        deleteAllInstances: this.shouldDeleteRecurringEvent(),
      },
      isV2
    );

    this.removePreviewEvent();
  }

  removePreviewEvent() {
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  }

  rsvpFollowing({ cutoffDate, cutoffZone, message }) {
    const { event, weekStart } = this.props;
    const { allCalendars } = this.props.allCalendars;

    const isV2 = isVersionV2();
    const eventProviderId = getGoogleEventId(event);
    const eventUserCalendarId = getEventUserCalendarID(event);
    const { self: isOrganizer } = getEventOrganizer(event) || {};
    const masterEventId = getEventMasterEventID(event);

    const path = isV2
      ? "recurring_events/rsvp/following"
      : `calendars/${eventUserCalendarId}/recurring_events/${masterEventId}/rsvp_following`;

    const params = constructQueryParams({
      ical_uid: getEventICalUID(event),
      timeMin: parseISO(cutoffDate).toISOString(),
      timeMax: getLastDayOfWeekJsDate(
        addWeeks(parseISO(cutoffDate), 3),
        weekStart
      ).toISOString(),
      time_zone: cutoffZone,
      // V1 specific fields
      ...(!isV2 && {
        google_event_id: eventProviderId,
      }),
      // V2 specific fields
      ...(isV2 && {
        event_provider_id: eventProviderId,
        calendar_provider_id: getEmailFromUserCalendarID(
          eventUserCalendarId,
          allCalendars
        ),
        master_event_id: masterEventId,
      }),
    });

    this.setDisappearingMessage();
    Broadcast.publish(
      BROADCAST_VALUES.UPDATE_INSTANCES,
      {
        path: `${path}?${params}`,
        body: {
          event: this.constructUpdateEventAttendingStatus({isRecurring: true, message}),
          ...(isV2 && {
            organizer: isOrganizer,
          }),
          ...(isV2 &&
            isOutlookEvent(event) &&
            isOrganizer &&
            this.constructOutlookRsvpFollowingParams()),
        },
        originalEvent: event,
      },
      isV2
    );
  }

  // when you move an event to a different time and choose "this event",
  // the one off event is still part of the recurrence series
  isOneOffRecurringInstance() {
    const { event, originalRecurrenceEventIndex, currentTimeZone } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const masterEvent = getOriginalRecurringEventFromIndex(
      event,
      originalRecurrenceEventIndex
    );
    if (isEmptyObjectOrFalsey(masterEvent)) {
      // sanity check
      // if doesn't exist -> just mark this event
      return true;
    }

    // format with eventStart and eventEnd
    const formattedMasterEvent = formatEventForReactBigCalendar({
      event: masterEvent,
      currentTimeZone,
    });
    if (
      !formattedMasterEvent?.eventStart ||
      !formattedMasterEvent?.eventEnd ||
      !event?.eventStart ||
      !event?.eventEnd
    ) {
      // sanity check
      // if doesn't exist -> just mark this event
      return true;
    }
    const areSameEventStartHourAndMinute = areSameHourAndMinute(
      formattedMasterEvent.eventStart,
      event.eventStart
    );
    const areSameEventEndHourAndMinute = areSameHourAndMinute(
      formattedMasterEvent.eventEnd,
      event.eventEnd
    );
    if (!areSameEventStartHourAndMinute || !areSameEventEndHourAndMinute) {
      return true;
    }

    // if event response is different compared to master event -> is also one off event
    const eventAttendance = getSelfRSVPStatus(
      event,
      getEmailBasedOnCalendarId(event, allCalendars)
    );
    const masterEventAttendance = getSelfRSVPStatus(
      formattedMasterEvent,
      getEmailBasedOnCalendarId(formattedMasterEvent, allCalendars)
    );
    if (eventAttendance !== masterEventAttendance) {
      return true;
    }
    return false;
  }

  updateEvent({ message, sendResponse = true }) {
    const { event } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const isV2 = isVersionV2();

    const eventProviderId = getGoogleEventId(event);
    const calendarProviderId = getEmailFromUserCalendarID(
      getEventUserCalendarID(event),
      allCalendars
    );
    const eventAttendingStatus =
      this.constructUpdateEventAttendingStatus({isRecurring: false, message});
    const hasRequestedResponse = isOutlookEvent(event) && getOutlookResponseRequested(event);

    const payloadData = {
      body: JSON.stringify({
        user_event_id: getEventUserEventID(event),
        // V1 specific fields
        ...(!isV2 && {
          google_calendar_id: calendarProviderId,
          google_event_id: eventProviderId,
          event: {
            google_calendar_event: eventAttendingStatus,
          },
        }),
        // V2 specific fields
        ...(isV2 && {
          event_provider_id: eventProviderId,
          calendar_provider_id: calendarProviderId,
          event: eventAttendingStatus,
          organizer: isOrganizerSelf(event),
          master_event_id: getEventMasterEventID(event),
          // send_response only used for Outlook
          send_response: hasRequestedResponse ? sendResponse : false,
        }),
      }),
    };

    const params = constructQueryParams({ sendUpdates: GOOGLE_UPDATES.ALL });
    const path = isV2 ? "events/rsvp" : "events";

    this.setDisappearingMessage();

    Broadcast.publish(
      BROADCAST_VALUES.UPDATE_EVENT,
      {
        url: `${constructRequestURL(path, isV2)}?${params}`,
        payloadData,
        remapWeeklyCalendarHotKeys: true,
        userEmail: getUserEmailFromEvent(event, allCalendars),
        originalEvent: event,
        updatedTemporaryEvent: createUpdatedSingleEventWithEventResponse({
          event,
          eventAttendingStatus: eventAttendingStatus,
        }),
        shouldDeleteEvent: this.shouldDeleteEvent(),
      },
      isV2
    );

    this.removePreviewEvent();
  }

  shouldDeleteRecurringEvent() {
    if (
      isOutlookEvent(this.props.event) &&
      this.state.status === attendee_event_declined
    ) {
      return true;
    }
    return false;
  }

  shouldDeleteEvent() {
    // on outlook decline event, outlook cancels the event/events (recurring)
    if (
      isOutlookEvent(this.props.event) &&
      this.state.status === attendee_event_declined
    ) {
      return true;
    }
    if (this.state.status === attendee_event_declined
      && !this.props.showDeclinedEvents
    ) {
      return true;
    }
    return false;
  }

  constructUpdateEventAttendingStatus({isRecurring, message}) {
    const { status } = this.state;
    const { event } = this.props;

    const previousAttendees = getEventAttendees(event);

    const email = this.getMatchingEventEmail();
    const attendeesListWithoutSelf = previousAttendees.filter(
      (attendee) => !isSameEmail(getObjectEmail(attendee), email)
    );
    const currentSelfAttendance =
      previousAttendees.filter((attendee) =>
        isSameEmail(getObjectEmail(attendee), email)
      )?.[0] ?? {};

    const selfAttendance = {
      ...(isRecurring ? {} : currentSelfAttendance), // do not applly note to every instance
      email,
      responseStatus: status,
      self: true,
      ...(message && { comment: message }),
    };

    return {
      attendees: [...attendeesListWithoutSelf, selfAttendance],
    };
  }

  constructOutlookRsvpFollowingParams() {
    const { event, originalRecurrenceEventIndex } = this.props;

    // On outlook we will also send parameters to accomplish a delete following
    const masterEvent = getOriginalRecurringEventFromIndex(
      event,
      originalRecurrenceEventIndex
    );
    const ruleString = getRRuleStringFromRecurrence(masterEvent);
    const cutOff = createRecurrenceCutOffDate(event);
    const updated_original_recurrence = trimOriginalRecurrenceRule(
      ruleString,
      cutOff
    );

    return {
      updated_original_recurrence,
      master_event_start_date: getEventStartDateTime(masterEvent),
    };
  }

  getMatchingEventEmail() {
    const { event } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const email = getEmailBasedOnCalendarId(event, allCalendars);
    return email;
  }

  getSelfRSVPComment() {
    return getSelfRSVPComment(this.props.event, this.getMatchingEventEmail());
  }

  isGoogleEvent() {
    return isGoogleEvent(this.props.event);
  }

  getMatchingUserForEvent() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const email = this.getMatchingEventEmail();
    return allLoggedInUsers.find((user) => isSameEmail(getUserEmail(user), email));
  }

  getMatchingCalendarForEvent() {
    const { allCalendars } = this.props.allCalendars;
    const userCalendarID = getEventUserCalendarID(this.props.event);
    return getCalendarFromUserCalendarID({
      userCalendarID,
      allCalendars,
    });
  }

  setOutlookProposedStartDate(jsDate) {
    const {
      outlookProposedTimeStart,
      outlookProposedTimeEnd,
    } = this.state;
    const currentStart = outlookProposedTimeStart;
    const currentEnd = outlookProposedTimeEnd;
    if (!isValidJSDate(currentStart) || !isValidJSDate(currentEnd)) {
      return;
    }
    const duration = differenceInMinutes(currentEnd, currentStart);
    const updatedStart = updateDatePreserveTime(currentStart, jsDate);
    this.setState({
      outlookProposedTimeStart: updatedStart,
      outlookProposedTimeEnd: addMinutes(updatedStart, duration),
    });
  }

  setOutlookProposedStartTime(jsDate) {
    const {
      outlookProposedTimeStart,
      outlookProposedTimeEnd,
    } = this.state;
    const currentStart = outlookProposedTimeStart;
    if (!isValidJSDate(currentStart)) {
      return;
    }

    const duration = differenceInMinutes(outlookProposedTimeEnd, outlookProposedTimeStart);
    const newEnd = addMinutes(jsDate, duration);

    this.setState({
      outlookProposedTimeStart: updateTimePreserveDate(currentStart, jsDate),
      outlookProposedTimeEnd: newEnd,
    });
  }

  setOutlookProposedEndDate(jsDate) {
    const {
      outlookProposedTimeEnd,
    } = this.state;
    const currentEnd = outlookProposedTimeEnd;
    if (!isValidJSDate(currentEnd)) {
      return;
    }
    this.setState({
      outlookProposedTimeEnd: updateDatePreserveTime(currentEnd, jsDate),
    });
  }

  setOutlookProposedEndTime(jsDate) {
    const {
      outlookProposedTimeEnd,
    } = this.state;
    const currentEnd = outlookProposedTimeEnd;
    if (!isValidJSDate(currentEnd)) {
      return;
    }
    this.setState({
      outlookProposedTimeEnd: updateTimePreserveDate(currentEnd, jsDate),
    });
  }

  async onSubmitOutlookProposedTime(comment = "") {
    try {
      const {
        outlookProposedTimeStart,
        outlookProposedTimeEnd,
      } = this.state;
      const {
        event,
        currentUser,
        currentTimeZone,
      } = this.props;
      const {
        selectedOutlookProposeTimeType,
      } = this.state;
      const payloadData = {
        body: JSON.stringify({
          event_provider_id: getEventID(event),
          comment,
          end_time: outlookProposedTimeEnd.toISOString(),
          start_time: outlookProposedTimeStart.toISOString(),
          time_zone: currentTimeZone,
          action_type: selectedOutlookProposeTimeType,
          user_calendar_id: getEventUserCalendarID(event),
        }),
      };

      this.closeModal();
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Proposing new times...",
      );

      const path = "events/propose_new_times";
      const url = constructRequestURLV2(path);

      const response = await fetcherPost({
        url,
        payloadData,
        email: getUserEmail(this.getMatchingUserForEvent() || currentUser),
      });

      if (!this._isMounted) {
        return;
      }
      if (response?.error) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Failed to propose new times",
        );
        return;
      }
      if (!isEmptyObjectOrFalsey(response?.event)) {
        backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.UPDATE_EVENT_RESPONSE, {
          response: {
            ...response,
            calendar: getCalendarObject(this.getMatchingCalendarForEvent()), // faking response for calendar here
          },
          isEdit: true,
          remapWeeklyCalendarHotKeys: true,
        });
      }
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Sent proposed time",
      );
    } catch(error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Failed to propose new times",
      );
    }
  }

  setOutlookProposedDateTime({startTime, endTime}) {
    this.setState({
      outlookProposedTimeStart: startTime,
      outlookProposedTimeEnd: endTime,
    });
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    isDarkMode,
    originalRecurrenceEventIndex,
    weekStart,
    showDeclinedEvents,
  } = state;

  return {
    currentUser,
    currentTimeZone,
    isDarkMode,
    originalRecurrenceEventIndex,
    weekStart,
    showDeclinedEvents,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(EventResponse));
