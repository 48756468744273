import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import produce from "immer";

import {
  getCalendarIsSelected,
  getCalendarProviderId,
  getCalendarUserCalendarID,
} from "../../../services/calendarAccessors";
import {
  determineCalendarColor,
  getCalendarName,
} from "../../../lib/calendarFunctions";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import CheckBox from "../../checkbox";

/* Render calendars with checkboxes for user */
function CalendarSelectList(props) {
  const {
    calendars,
    magicLinkAllCalendars,
    primaryUserCalendarID,
    setMagicLinkAllCalendars,
    userEmail,
  } = props;
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const toggleCalendar = (calendar) => {
    /* Update state using immer - Kenny  */
    const updatedCalendars = produce(magicLinkAllCalendars, draftState => {
      const updatedCalendar = draftState[userEmail].find((existingCalendar) =>
        getCalendarUserCalendarID(calendar) === getCalendarUserCalendarID(existingCalendar),
      );

      /* Can't update if calendar is missing */
      /* Don't update if it is the primary calendar (enforce true) */
      if (
        !updatedCalendar ||
        getCalendarUserCalendarID(updatedCalendar) === primaryUserCalendarID
      ) {
        return;
      }

      /* Make sure not to change object somewhere else as it can break - Mike */
      updatedCalendar.selected = !updatedCalendar.selected;
    });

    setMagicLinkAllCalendars(updatedCalendars);
  };

  return calendars.map((calendar, idx) => {
    const id = `user_calendar_${userEmail}_${getCalendarProviderId(
      calendar,
    )}_${idx}`;
    const calendarColor = determineCalendarColor(calendar);
    const isCalendarSelected = getCalendarIsSelected(calendar);

    return (
      <div
        key={id}
        id={id}
        className={classNames(
          "default-font-size",
          "font-weight-300 flex items-center cursor-pointer",
          "container-hover-icon-visibility",
          "calendar-hover-agenda-panel",
          "secondary-text-color",
          "magic-link-calendar-select-calendar-name",
          calendars.length - 1 === idx
            ? "magic-link-calendar-last-calendar"
            : "",
        )}
        onClick={() => toggleCalendar(calendar)}
      >
        <div className="truncate-text w-full default-font-size select-none">
          {getCalendarName({
            calendar,
            currentUser,
            emailToNameIndex: null,
            masterAccount,
          })}
        </div>

        <CheckBox
          backgroundColor={isCalendarSelected ? calendarColor : "transparent"}
          borderColor={calendarColor}
          borderWidth={1}
          disabled={
            getCalendarUserCalendarID(calendar) === primaryUserCalendarID
          }
          isChecked={isCalendarSelected}
        />
      </div>
    );
  });
}

export default CalendarSelectList;
