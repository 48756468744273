import React from "react";
import AnimatedGreenCheckMark from "../icons/animatedGreenCheckMark";

interface ToastPageProps {
  className?: string
  message: string
}

export default function ToastPage({ className, message }: ToastPageProps) {
  return (
    <div className={className ?? "onboarding-container"}>
      <div className="font-size-24 font-weight-300">{message}</div>

      <AnimatedGreenCheckMark />
    </div>
  );
}
