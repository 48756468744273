import { immutablySortArray } from "../../lib/arrayFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import { useTeamPlan } from "../../services/stores/userData";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { Page, PAGES } from "./updatedAddTeamMembersModal";

export const CONFIRMED = "confirmed" as const;
export const PENDING = "pending" as const;

export const TEAM_ROLES = {
  MEMBER: "member",
  ADMIN: "admin",
  OWNER: "owner",
} as const;

export const ROLE_OPTIONS = [
  { value: TEAM_ROLES.MEMBER, label: "Member" },
  { value: TEAM_ROLES.ADMIN, label: "Admin" },
] as const;

export type TeamPlanMemberWithStatus = TeamPlanMember & {
  status: typeof CONFIRMED | typeof PENDING
}

export const TEST_DATA = {
  token: "asadlfkjlaskdfj",
  seats: 7,
  pending_users: [
    { full_name: "Kobe Bryant", email: "kobe.bryant@vimcal.com", status: PENDING },
    { full_name: "Kobe Bryant1", email: "kobe.bryant@vimcal.com1", status: PENDING },
    { full_name: "Kobe Bryant2", email: "kobe.bryant@vimcal.com2", status: PENDING },
    {
      full_name: "Sophie lastfull_name",
      email: "sophieWithReallyLongEmailSOmethingSomethingSomething@vimcal.com2",
      status: PENDING,
    },
    {
      full_name: "Sophie lastfull_name",
      email: "sophieWithReallyLongEmailSOmethingSomethingSomething@vimcal.com3",
      status: PENDING,
    },
    { full_name: "Kobe Bryant", email: "kobe.bryant@vimcal.com3", status: PENDING },
  ],
  active_users: [
    { full_name: "Alex Bayer", email: "alex@vimcal.com", status: CONFIRMED },
    {
      full_name: "Michael Jordan",
      email: "michael.jordan@vimcal.com",
      status: CONFIRMED,
    },

    { full_name: "John Li", email: "john@vimcal.com", status: CONFIRMED },
    { full_name: "Jeffrey Qiu", email: "jeffrey@vimcal.com", status: CONFIRMED },
    {
      full_name: "Dylan withReallyLongfull_nameJustToTestTruncate",
      email: "dylan@vimcal.com",
      status: CONFIRMED,
    },
    {
      full_name: "Sophie lastfull_name",
      email: "sophieWithReallyLongEmailSOmethingSomethingSomething@vimcal.com",
      status: PENDING,
    },
    {
      full_name: "Michael Zhao",
      email: "mike@vimcal.com",
      status: CONFIRMED,
    },

    { full_name: "Alex Bayer", email: "alex@vimcal.com1", status: CONFIRMED },
    {
      full_name: "Michael Jordan",
      email: "michael.jordan@vimcal.com",
      status: CONFIRMED,
    },

    { full_name: "John Li", email: "john@vimcal.com1", status: CONFIRMED },
    { full_name: "Jeffrey Qiu", email: "jeffrey@vimcal.com1", status: CONFIRMED },
    {
      full_name: "Dylan withReallyLongfull_nameJustToTestTruncate",
      email: "dylan@vimcal.com1",
      status: CONFIRMED,
    },
    {
      full_name: "Sophie lastfull_name",
      email: "sophieWithReallyLongEmailSOmethingSomethingSomething@vimcal.com1",
      status: PENDING,
    },
    {
      full_name: "Michael Zhao",
      email: "mike@vimcal.com1",
      status: CONFIRMED,
    },

    { full_name: "Alex Bayer", email: "alex@vimcal.com2", status: CONFIRMED },
    {
      full_name: "Michael Jordan",
      email: "michael.jordan@vimcal.com2",
      status: CONFIRMED,
    },
    { full_name: "John Li", email: "john@vimcal.com2", status: CONFIRMED },
    { full_name: "Jeffrey Qiu", email: "jeffrey@vimcal.com2", status: CONFIRMED },
    {
      full_name: "Dylan withReallyLongfull_nameJustToTestTruncate",
      email: "dylan@vimcal.com2",
      status: CONFIRMED,
    },
    {
      full_name: "Michael Zhao",
      email: "mike@vimcal.com2",
      status: CONFIRMED,
    },

    { full_name: "Alex Bayer", email: "alex@vimcal.com3", status: CONFIRMED },
    {
      full_name: "Michael Jordan",
      email: "michael.jordan@vimcal.com3",
      status: CONFIRMED,
    },
    { full_name: "John Li", email: "john@vimcal.com3", status: CONFIRMED },
    { full_name: "Jeffrey Qiu", email: "jeffrey@vimcal.com3", status: CONFIRMED },
    {
      full_name: "Dylan withReallyLongfull_nameJustToTestTruncate",
      email: "dylan@vimcal.com3",
      status: CONFIRMED,
    },
    {
      full_name: "Michael Zhao",
      email: "mike@vimcal.com3",
      status: CONFIRMED,
    },
  ],
};

export function getExistingTeamMembers(teamPlan: TeamPlan | Record<string, never>) {
  if (isEmptyObjectOrFalsey(teamPlan)) {
    return [];
  }

  const {
    active_users,
    pending_users,
    admin_user,
  } = teamPlan;

  const allUsers: TeamPlanMemberWithStatus[] = [];
  if (!isEmptyArrayOrFalsey(active_users)) {
    active_users.forEach(u => {
      allUsers.push({ ...u, status: CONFIRMED });
    });
  }

  if (!isEmptyArrayOrFalsey(pending_users)) {
    pending_users.forEach(u => {
      allUsers.push({ ...u, status: PENDING });
    });
  }

  if (allUsers.every(member => !isSameEmail(getObjectEmail(member), getObjectEmail(admin_user)))) {
    allUsers.push({ ...admin_user, status: CONFIRMED });
  }
  if (isEmptyArrayOrFalsey(allUsers)) {
    // if there's nothing to sort, just return empty array;
    return [];
  }

  const sortKey = "email";

  const sorted = allUsers.sort(function(a, b) {
    if(a[sortKey] < b[sortKey]) { return -1; }
    if(a[sortKey] > b[sortKey]) { return 1; }
    return 0;
  });
  return sorted;
}

export function getDisplayName(member: TeamPlanMember | undefined) {
  if (isEmptyObjectOrFalsey(member)) {
    return "";
  }

  const { email, full_name, first_name, last_name } = member;
  if (full_name) {
    return full_name;
  } else if (first_name && last_name) {
    return `${member.first_name} ${member.last_name}`;
  } else {
    return email;
  }
}

export function isMemberAdmin(member: TeamPlanMember) {
  return member.team_plan_role === TEAM_ROLES.ADMIN || member.team_plan_role === TEAM_ROLES.OWNER;
}

export function isMemberOwner(member: TeamPlanMember) {
  return member.team_plan_role === TEAM_ROLES.OWNER;
}

interface isTeamPlanModalHeaderClickableOptions {
  addedSeats: number,
  currentPage: Page
  newPage: Page
  pages: Page[]
}

export function isTeamPlanModalHeaderClickable({
  addedSeats,
  currentPage,
  newPage,
  pages,
}: isTeamPlanModalHeaderClickableOptions): boolean {
  const currentIndex = pages.indexOf(currentPage);
  const newIndex = pages.indexOf(newPage);

  if (newIndex === -1) {
    return false;
  }

  // Prevent skipping the create step.
  // Get the latest plan directly from the store since the component that calls this
  // may not have re-rendered from the update yet.
  const latestTeamPlan = useTeamPlan.getState().teamPlan;
  if (currentPage === PAGES.CREATE_TEAM && isEmptyObjectOrFalsey(latestTeamPlan)) {
    return false;
  }

  /* Prevent page skipping */
  if (newIndex - currentIndex > 1) {
    return false;
  }

  /* Check seats are being added before going to review page */
  if (newPage === PAGES.REVIEW && addedSeats <= 0) {
    return false;
  }

  /* Always allow going back */
  /* Allow going forward one page (unless prevented above) */
  if (
    newIndex < currentIndex ||
    (currentIndex + 1 === newIndex)
  ) {
    return true;
  }

  return false;
}
