import { TinyColor } from "@ctrl/tinycolor";

let _darkModeColorCache = {};
let _lightModeColorCache = {};
let _darkModeTextColorCache = {};
let _lightModeTextColorCache = {};
export function getWorkLocationBackgroundColor({ color, isDarkMode }) {
  if (isDarkMode) {
    if (_darkModeColorCache[color]) {
      return _darkModeColorCache[color];
    }
    const darkenedColor = new TinyColor(color)
      .darken(30)
      .desaturate(20)
      .toHex8String();
    _darkModeColorCache[color] = darkenedColor;
    return darkenedColor;
  }

  // light mode below
  if (_lightModeColorCache[color]) {
    return _lightModeColorCache[color];
  }
  const lightenedColor = new TinyColor(color)
    .lighten(50)
    .desaturate(30)
    .toHexString();
  _lightModeColorCache[color] = lightenedColor;

  return lightenedColor;
}

export function getWorkLocationTextColor({ color, isDarkMode, shouldDim }) {
  if (!shouldDim) {
    return color;
  }

  if (isDarkMode) {
    if (_darkModeTextColorCache[color]) {
      return _darkModeTextColorCache[color];
    }
    const darkenedColor = new TinyColor(color)
      .darken(10)
      .desaturate(10)
      .toHex8String();
    _darkModeTextColorCache[color] = darkenedColor;
    return darkenedColor;
  }

  // light mode below
  if (_lightModeTextColorCache[color]) {
    return _lightModeTextColorCache[color];
  }
  const lightenedColor = new TinyColor(color)
    .lighten(20)
    .desaturate(10)
    .toHexString();
  _lightModeTextColorCache[color] = lightenedColor;

  return lightenedColor;
}
