import Delta from "quill-delta";

import { lowerCaseAndTrimStringWithGuard } from "../../lib/stringFunctions";
import {
  REMINDER_EMAIL_BODY_ID,
  REMINDER_EMAIL_TITLE_ID,
  REMINDER_EMAIL_TOOLBAR_ID,
} from "../../services/elementIDVariables";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isTypeObject,
  isTypeString,
} from "../../services/typeGuards";
import { EMAIL_REMINDER_BLOT_NAME } from "../../lib/quill/blots";

export const REMINDER_EMAIL_CONTENT = {
  TITLE: "Title",
  BODY: "Body",
};

export const REMINDER_EMAIL_TITLE_VARIABLES = {
  EVENT_DATE: "{{Event date}}",
  EVENT_NAME: "{{Event name}}",
  EVENT_TIME: "{{Event time}}",
  MY_NAME: "{{My name}}",
};

export const REMINDER_EMAIL_BODY_VARIABLES = {
  EVENT_DATE: "{{Event date}}",
  EVENT_DESCRIPTION: "{{Event description}}",
  EVENT_LOCATION: "{{Event location}}",
  EVENT_NAME: "{{Event name}}",
  EVENT_TIME: "{{Event time}}",
  INVITEE_FULL_NAME: "{{Invitee full name}}",
  MY_NAME: "{{My name}}",
};

const ALL_REMINDER_EMAIL_VARIABLES = {
  ...REMINDER_EMAIL_TITLE_VARIABLES,
  ...REMINDER_EMAIL_BODY_VARIABLES,
};

export function isReminderEmailContentTitle(contentType) {
  return contentType === REMINDER_EMAIL_CONTENT.TITLE;
}

export const REMINDER_EMAIL_TIMING_UNIT = {
  DAYS: "day",
  HOURS: "hour",
  MINUTES: "min",
};

export function getReminderEmailUnitsOptions() {
  return Object.values(REMINDER_EMAIL_TIMING_UNIT).map((unit) => {
    return { value: unit, label: unit };
  });
}

export const REMINDER_EMAIL_DEFAULT_TITLE = `Reminder: ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_NAME} with ${REMINDER_EMAIL_TITLE_VARIABLES.MY_NAME} at ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_TIME} on ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_DATE}`;
export const REMINDER_EMAIL_DEFAULT_BODY = `Hi ${REMINDER_EMAIL_BODY_VARIABLES.INVITEE_FULL_NAME},\nJust a friendly reminder that your ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_NAME} with ${REMINDER_EMAIL_TITLE_VARIABLES.MY_NAME} is at ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_TIME} on ${REMINDER_EMAIL_TITLE_VARIABLES.EVENT_DATE}\n${REMINDER_EMAIL_BODY_VARIABLES.EVENT_LOCATION}\n${REMINDER_EMAIL_BODY_VARIABLES.EVENT_DESCRIPTION}`;
export const REMINDER_EMAIL_DEFAULT_TIMING = [
  { advance_time: 1, duration_unit: REMINDER_EMAIL_TIMING_UNIT.DAYS },
];

export const DEFAULT_REMINDER_EMAILS = {
  body: REMINDER_EMAIL_DEFAULT_BODY,
  subject: REMINDER_EMAIL_DEFAULT_TITLE,
  timings: REMINDER_EMAIL_DEFAULT_TIMING,
};

export const REMINDER_EMAILS_PROPERTIES = {
  BODY: "body",
  TITLE: "subject",
  TIMINGS: "timings",
};

export const constructReminderEmailContainerID = (contentType) => {
  const formattedContentType = lowerCaseAndTrimStringWithGuard(contentType);

  return `${REMINDER_EMAIL_TOOLBAR_ID}-${formattedContentType}`;
};

export const determineDefaultReminderEmailText = (contentType) => {
  switch (contentType) {
    case REMINDER_EMAIL_CONTENT.BODY:
      return REMINDER_EMAIL_DEFAULT_BODY;
    case REMINDER_EMAIL_CONTENT.TITLE:
      return REMINDER_EMAIL_DEFAULT_TITLE;
    default:
      return "";
  }
};

export const getReminderEmailContentID = (contentType) => {
  switch (contentType) {
    case REMINDER_EMAIL_CONTENT.TITLE:
      return REMINDER_EMAIL_TITLE_ID;
    case REMINDER_EMAIL_CONTENT.BODY:
      return REMINDER_EMAIL_BODY_ID;
    default:
      return "";
  }
};

const getContentVariables = (contentType) => {
  switch (contentType) {
    case REMINDER_EMAIL_CONTENT.TITLE:
      return REMINDER_EMAIL_TITLE_VARIABLES;
    case REMINDER_EMAIL_CONTENT.BODY:
      return REMINDER_EMAIL_BODY_VARIABLES;
    default:
      return ALL_REMINDER_EMAIL_VARIABLES;
  }
};

export function getHumanReadableReminderEmailText(inputString) {
  return inputString.replace(/{{(.*?)}}/g, (match, p1) => {
    // Remove underscores and capitalize words
    return p1
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  });
}

const removeUFEFF = (text) => {
  if (!text) {
    return "";
  }

  return text.replace(/\ufeff/g, "");
};

export function replaceReminderEmailPlainTextWithVariables({
  contentType,
  text,
}) {
  if (!text) {
    return "";
  }

  const formattedText = removeUFEFF(text);
  const contentVariables = getContentVariables(contentType);
  return Object.entries(contentVariables).reduce((acc, [key, value]) => {
    const regex = new RegExp(key.replace(/_/g, " "), "gi");
    return acc.replace(regex, value);
  }, formattedText);
}

export function createTextDeltaOp(text) {
  return { insert: text };
}

export function createVariableDeltaOp(variable) {
  const humanReadableVariable = getHumanReadableReminderEmailText(variable);
  return { insert: { [EMAIL_REMINDER_BLOT_NAME]: `${humanReadableVariable}` } };
}

function allIndexesForVariable({ text, variable }) {
  let currentIndex = text.toLowerCase().indexOf(variable.toLowerCase());
  let variableIndexes = [];

  while (currentIndex >= 0) {
    /* Add the index and the variable length */
    variableIndexes.push({ index: currentIndex, variable });
    /* Set the current index to the next indexOf */
    currentIndex = text
      .toLowerCase()
      .indexOf(variable.toLowerCase(), currentIndex + 1);
  }

  return variableIndexes;
}

// TODO: Write unit tests
function convertStringIntoOps({ contentVariableValues, text }) {
  let allVariableIndexes = contentVariableValues
    .map((variable) => allIndexesForVariable({ text, variable }))
    .flat()
    .sort((a, b) => {
      const aIndex = a.index;
      const bIndex = b.index;

      /* Indexes should never be equal */
      return aIndex < bIndex ? -1 : 1;
    });

  /* No variable so we return the text as delta */
  if (isEmptyArrayOrFalsey(allVariableIndexes)) {
    return [createTextDeltaOp(text)];
  }

  let currentIndex = 0;
  let deltaOps = [];
  let remainderText = "";
  allVariableIndexes.forEach(variableIndex => {
    const { index, variable } = variableIndex;
    const textBeforeVariable = text.slice(currentIndex, index);

    deltaOps.push(createTextDeltaOp(textBeforeVariable));
    deltaOps.push(createVariableDeltaOp(variable));

    /* Set starting index for next variable */
    currentIndex = index + variable.length;

    /* Set the rest of the text as remainder */
    remainderText = text.slice(currentIndex);
  });

  /* Add remaining text at the end */
  if (isTypeString(remainderText) && remainderText.length > 0) {
    deltaOps.push(createTextDeltaOp(remainderText));
  }

  return deltaOps;
}

/* Converts plaintext into a Delta for Quill */
export function formatTextToVariableDelta({ contentType, text }) {
  const delta = new Delta();

  if (!text) {
    return delta.insert("");
  }

  const contentVariableValues = Object.values(getContentVariables(contentType));
  const formattedText = removeUFEFF(text);
  const deltaOps = convertStringIntoOps({
    contentVariableValues,
    text: formattedText,
  });
  delta.ops = deltaOps;

  return delta;
}

/* Check strings in delta and format into variable if necessary */
export function formatVariablesInDelta({ contentType, delta }) {
  const newDelta = new Delta();

  if (isEmptyArrayOrFalsey(delta?.ops)) {
    return delta.insert("");
  }

  const contentVariableValues = Object.values(getContentVariables(contentType));
  let updatedDeltaOps = [];

  delta.ops.forEach((op) => {
    /* Op is inserting a variable so we don't need to look at it */
    if (isTypeObject(op.insert)) {
      updatedDeltaOps.push(op);
    }

    /* Op is a string so we check to see if we need to split variables in their own op */
    if (isTypeString(op.insert)) {
      const splitDeltaOps = convertStringIntoOps({
        contentVariableValues,
        text: op.insert,
      });
      updatedDeltaOps = updatedDeltaOps.concat(splitDeltaOps);
    }
  });

  newDelta.ops = updatedDeltaOps;
  return newDelta;
}

/* Converts Delta object to string */
export function formatDeltaToText({ contentType, delta }) {
  if (isEmptyArrayOrFalsey(delta?.ops)) {
    return "";
  }

  return delta.ops
    .map((op) => {
      if (op.insert[EMAIL_REMINDER_BLOT_NAME]) {
        return replaceReminderEmailPlainTextWithVariables({
          contentType,
          text: op.insert[EMAIL_REMINDER_BLOT_NAME],
        });
      }

      if (isReminderEmailContentTitle(contentType)) {
        return op.insert.replace(/\n/g, " ");
      }

      return op.insert;
    })
    .join("");
}

/**
 * Counts the length of the Delta with variables counting for 1.
 * This is to set the cursor after typing an element inline.
 */
export function countDeltaLength({ delta }) {
  let count = 0;

  delta?.ops?.forEach((op) => {
    if (op?.insert[EMAIL_REMINDER_BLOT_NAME]) {
      count += 1;
    }

    if (isTypeString(op?.insert)) {
      count += op.insert.length;
    }
  });

  return count;
}

export function createInitialContentEditableText({
  reminderEmailBody,
  reminderEmailTitle,
}) {
  const initialReminderBody = formatTextToVariableDelta({
    contentType: REMINDER_EMAIL_CONTENT.BODY,
    text: reminderEmailBody,
  });

  const initialReminderTitle = formatTextToVariableDelta({
    contentType: REMINDER_EMAIL_CONTENT.TITLE,
    text: reminderEmailTitle,
  });

  return { initialReminderBody, initialReminderTitle };
}

export function filterDuplicateTimings({ reminderEmails }) {
  if (isEmptyObjectOrFalsey(reminderEmails)) {
    return reminderEmails;
  }

  let uniqueTimings = [];
  reminderEmails.timings = reminderEmails.timings.filter((timing) => {
    const { advance_time, duration_unit } = timing;
    const timingString = `${advance_time}${duration_unit}`;

    /* Remove from array if not unique */
    if (uniqueTimings.includes(timingString)) {
      return false;
    }

    uniqueTimings.push(timingString);
    return true;
  });

  return reminderEmails;
}
