import React, { useState } from "react";
import { useUserTimeZoneIndexStore } from "../services/stores/userData";
import {
  getUserTimeZoneDisplayString,
  onClickUserLocalTimeZone,
} from "../services/appFunctions";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
  hasEventPreventDefault,
  hasStopEventPropagation,
} from "../services/commonUsefulFunctions";
import { getTooltipStyles } from "../lib/tooltipFunctions";
import { getGMTOffsetString } from "../services/timeZone";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";

export default function UserLocalTimeZone({
  email,
  className = "",
  alwaysShowLocalTimeTooltip = false,
}) {
  const userTimeZoneIndex = useUserTimeZoneIndexStore(
    (state) => state.userTimeZoneIndex
  );
  const selectedDate = useSelector((state) => state.selectedDate);
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const tooltipStyles = {
    ...getTooltipStyles({
      hoveredElement,
      tooltipElement,
      placement: "bottom",
    }),
    ...{ zIndex: 1 },
  };

  const userTimeZone = userTimeZoneIndex[email];
  if (!userTimeZone) {
    return null;
  }
  const gmtOffsetString = getGMTOffsetString(
    userTimeZone,
    getSelectedDayWithBackup(selectedDate)
  );
  return (
    <div
      className={classNames(
        className || "",
        "hoverable-secondary-text-color select-none",
        "hoverable-visibility-parent",
        alwaysShowLocalTimeTooltip ? "" : "relative"
      )}
      onClick={(e) => {
        hasEventPreventDefault(e);
        hasStopEventPropagation(e);
        onClickUserLocalTimeZone({
          timeZone: userTimeZone,
          currentTimeZone,
        });
      }}
    >
      {alwaysShowLocalTimeTooltip ? null : (
        <div
          ref={(element) => setTooltipElement(element)}
          className={classNames(
            "hover-hint-base-style",
            "hoverable-visibility-child",
            "pointer-events-none",
            "user-hover-hint-background",
            "w-max"
          )}
          style={tooltipStyles}
        >
          {`${gmtOffsetString}`}
        </div>
      )}

      <div ref={(element) => setHoveredElement(element)}>
        {getUserTimeZoneDisplayString({
          format24HourTime,
          userTimeZoneIndex,
          email,
          currentTimeZone,
        }) + (alwaysShowLocalTimeTooltip ? ` ${gmtOffsetString}` : "")}
      </div>
    </div>
  );
}
