global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../customButton";
import {
  BLUE_BUTTON,
} from "../../../../services/globalVariables";
import DisabledButton from "../../../disabledButton";
import { BACKEND_CUSTOM_CONFERENCING, BACKEND_HANGOUT } from "../../../../services/googleCalendarService";
import AppBroadcast from "../../../../broadcasts/appBroadcast";
import { getUserEmail } from "../../../../lib/userFunctions";
import { getPrimaryCalendarConferencing, isOutlookUser } from "../../../../lib/outlookFunctions";
import { useAllCalendars } from "../../../../services/stores/SharedAccountData";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import { getInputStringFromEvent, isUrl } from "../../../../lib/stringFunctions";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import { getCustomConferencingName, getCustomConferencingURL, getDefaultUserConferencing } from "../../../../lib/settingsFunctions";

const INPUT_WIDTH = { width: 280 };

const CustomConferencing = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  
  const getUser = () => {
    return props.inputUser ?? currentUser;
  }

  const allCalendars = useAllCalendars(state => state.allCalendars);
  const initialConferencingUrl = useMemo(
    () => getCustomConferencingURL({ user: getUser() }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const initialConferencingName = useMemo(
    () => getCustomConferencingName({ user: getUser() }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [url, setUrl] = useState(initialConferencingUrl);
  const {
    initialUrl, 
    initialName
  } = getInitialCustomConferencingData({user: getUser()});
  const [URLWarning, setURLWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);
  const [name, setName] = useState(initialConferencingName);

  const handleUrlChange = (e) => {
    const newUrl = getInputStringFromEvent(e);
    setUrl(newUrl);

    if (URLWarning && isUrl(newUrl) && newUrl.includes("https")) {
      setURLWarning(false);
    }
  };

  const handleNameChange = (e) => {
    const text = getInputStringFromEvent(e);
    setName(text);
    if (nameWarning && text?.length > 0) {
      setNameWarning(false);
    }
  };

  const onClickSave = (shouldSetAsDefault) => {
    if (!url && !name) {
      // remove custom conferencing
      AppBroadcast.publish("SET_CUSTOM_CONFERENCING_LINK", {
        conferencingURL: "",
        conferencingName: "",
        user: getUser(),
      });

      if (getDefaultUserConferencing({ user: getUser() }) === BACKEND_CUSTOM_CONFERENCING) {
        // if custom conferencing was the default conferencing, set the default conferencing to hangout for google users and outlook for outlook users
        if (isOutlookUser(getUser())) {
          const defaultUserClientConferencing = getPrimaryCalendarConferencing(allCalendars, getUser().email);
          updateSettingsBroadcast.publish(
            UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_CONFERENCING, {
              defaultConferencing: defaultUserClientConferencing,
              user: getUser(),
            },
          );
        } else {
          updateSettingsBroadcast.publish(
            UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_CONFERENCING,
            {
              defaultConferencing: BACKEND_HANGOUT,
              user: getUser(),
            },
          );
        }
      }
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
      setURLWarning(false);

      return;
    }

    if (!isUrl(url)) {
      setURLWarning("Please enter a valid URL");
      if (!name || name.trim().length === 0) {
        setNameWarning("Please enter a valid conferencing name");
      }
      return;
    }

    if (!url.includes("https")) {
      setURLWarning("The URL above needs to include https://");
      return;
    }

    if (!name || name.trim().length === 0) {
      setNameWarning("Please enter a valid conferencing name");
      return;
    }

    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");

    AppBroadcast.publish("SET_CUSTOM_CONFERENCING_LINK", {
      conferencingURL: url,
      conferencingName: name,
      user: getUser(),
    });

    if (shouldSetAsDefault) {
      updateSettingsBroadcast.publish(
        "UPDATE_DEFAULT_CONFERENCING",
        {
          defaultConferencing: BACKEND_CUSTOM_CONFERENCING,
          user: getUser(),
        },
      );
    }
  };

  return (
    <div className="width-100-percent">
      <div className="display-flex-flex-direction-row align-items-center justify-between mt-4">
        <div className="default-font-size secondary-text-color">Conferencing name</div>

        <input
          className="default-input-field"
          style={INPUT_WIDTH}
          value={name}
          onChange={handleNameChange}
          placeholder="e.g. Webex"
        />
      </div>
      {nameWarning ? (
        <div className="event-form-different-time-zone-warning font-size-12 width-100-percent">
          {nameWarning}
        </div>
      ) : null}

      <div className="display-flex-flex-direction-row align-items-center justify-between mt-4">
        <div className="default-font-size secondary-text-color">Conferencing URL</div>

        <input
          className="default-input-field"
          style={INPUT_WIDTH}
          value={url}
          onChange={handleUrlChange}
          placeholder="e.g. https://example.webex.com/meet"
        />
      </div>

      {URLWarning ? (
        <div className="event-form-different-time-zone-warning font-size-12 width-100-percent">
          {URLWarning}
        </div>
      ) : null}

      <div className="display-flex-flex-direction-row-justify-content-flex-end mt-4">
        {initialUrl === url && initialName === name ? (
          <DisabledButton label="Save" />
        ) : (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => onClickSave(false)}
            label="Save"
          />
        )}
      </div>
    </div>
  );
};

function getInitialCustomConferencingData({user}) {
  return {
    initialUrl: getCustomConferencingURL({ user }),
    initialName: getCustomConferencingName({ user }),
  };
}

export default CustomConferencing;
