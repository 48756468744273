import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import Mousetrap from "mousetrap";
import SelectAnswer from "./selectAnswer";
import OnboardingSaveButton from "./onboardingSaveButton";
import { trackEvent } from "../tracking";
import { getUserToken } from "../../lib/userFunctions";
import { OTHER_PRODUCTS, PRODUCTS, ROLES } from "./sharedFunctions";
import { postOnboardingResponse } from "../queries/onboardingResponses";

export default function OtherProductQuestion({
  step,
  onClickNext,
  otherProducts,
  setOtherProducts,
  role,
  customRole,
  numberOfExecutiveAssistants,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [customProducts, setCustomProducts] = useState("");

  const isOtherProduct = otherProducts.includes(PRODUCTS.OTHER);

  const onClickSave = () => {
    const otherProductsWithCustom = otherProducts.map(product => (
      product === PRODUCTS.OTHER ? (customProducts || product) : product
    ));

    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });
    trackEvent({
      category: "onboarding_questionnaire",
      action: "other_products",
      label: otherProductsWithCustom.join(", "),
      userToken: getUserToken(currentUser),
    });
    postOnboardingResponse(
      currentUser,
      role === ROLES.OTHER ? customRole : role,
      otherProductsWithCustom,
      numberOfExecutiveAssistants,
    );
    onClickNext();
  };

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, [otherProducts]);

  return (
    <div className="onboarding-container min-height-500px">
      <div className="section-title">What other products are you using?</div>
      <div className="flex flex-col mt-8 mb-3 gap-5 width-250">
        {OTHER_PRODUCTS.map((type) => {
          return (
            <SelectAnswer
              key={type}
              label={type}
              onClick={() => {
                if (otherProducts.includes(type)) {
                  const updatedProducts = otherProducts.filter((product) => product !== type);
                  setOtherProducts(updatedProducts);
                } else {
                  const updatedProducts = otherProducts.concat(type);
                  setOtherProducts(updatedProducts);
                }
              }}
              isSelected={otherProducts.includes(type)}
            />
          );
        })}
      </div>

      {isOtherProduct ? (
        <div className="width-250">
          <input
            className="default-input-field"
            placeholder="Other products"
            value={customProducts}
            onChange={(e) => setCustomProducts(e.target.value)}
          />
        </div>
      ) : null}

      <OnboardingSaveButton
        buttonText="Continue"
        onClick={onClickSave}
      />
    </div>
  );
}
