import React, { Component } from "react";
import { isInt, KEYCODE_ESCAPE } from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import classNames from "classnames";
import { connect } from "react-redux";
import BufferFromNow from "./availability/bufferFromNow";
import {
  getBufferDaysHoursMinutesFromMinutes,
  getDefaultMinuteListForReactSelect,
} from "../lib/availabilityFunctions";
import BlockedCalendarSection from "./availability/blockedCalendarSection";
import CustomSelect from "./select";
import { DAYS_INTO_THE_FUTURE_REACT_SELECT } from "./availability/resources/availabilityVariables";
import { getReactSelectBaseStyle } from "./select/styles";
import DropdownIndicator from "./select/dropDownIndicator";
import IgnoreConflicts from "./availability/ignoreConflicts";
import { pluralize } from "../lib/stringFunctions";
import IgnoreInternalConflicts from "./availability/ignoreInternalConflicts";
import { getInternalDomainAndEmails } from "../lib/settingsFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import CreatableSelect from "../../components/creatableSelect";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import Alert from "./availability/alerts/alert";
import { PERSONAL_LINK_NO_BLOCKING_CALENDARS_COPY } from "../lib/copy";

class PersonalLinkSettingsPage extends Component {
  constructor(props) {
    super(props);

    this._selectDaysRef = React.createRef();
    this._selectBufferBefore = React.createRef();
    this._selectBufferAfter = React.createRef();

    this.state = {
      daysForward: props.daysForward || 14,
      bufferBefore: props.bufferBefore || 0,
      bufferAfter: props.bufferAfter || 0,
      bufferFromNow:
        props.bufferFromNow || getBufferDaysHoursMinutesFromMinutes(0),
      daysList: DAYS_INTO_THE_FUTURE_REACT_SELECT,
      minuteList: getDefaultMinuteListForReactSelect(true),
      isIgnoreConflicts: props.isIgnoreConflicts,
      isIgnoreInternalConflicts: props.isIgnoreInternalConflicts,
    };

    this.onSelectDays = this.onSelectDays.bind(this);
    this.onSelectBufferBefore = this.onSelectBufferBefore.bind(this);
    this.onSelectBufferAfter = this.onSelectBufferAfter.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.focusTopIndexTab = this.focusTopIndexTab.bind(this);
    this.onFocusBottomFocusGuard = this.onFocusBottomFocusGuard.bind(this);
    this.onFocusTopFocusGuard = this.onFocusTopFocusGuard.bind(this);
    this.onSelectBufferFromNow = this.onSelectBufferFromNow.bind(this);

    Broadcast.subscribe(
      "FOCUS_PERSONAL_LINK_SETTING_TOP_INDEX_TAB",
      this.focusTopIndexTab,
    );
  }

  componentWillUnmount() {
    Broadcast.unsubscribe("FOCUS_PERSONAL_LINK_SETTING_TOP_INDEX_TAB");
  }

  render() {
    return (
      <div>
        <div tabIndex={1} onFocusCapture={this.onFocusTopFocusGuard}></div>

        <div className="default-font-size secondary-text-color font-weight-400 mt-5">
          Invitees can schedule
        </div>

        <div className="display-flex-flex-direction-row align-items-center mt-2">
          <CustomSelect
            isSearchable={true}
            ref={this._selectDaysRef}
            className={classNames(
              "select-duration-time",
              this.props.isDarkMode ? "dark-mode-select" : "",
              "select-default-font-size",
            )}
            classNamePrefix="dark-mode"
            value={{
              value: this.state.daysForward,
              label: this.state.daysForward,
            }}
            options={this.state.daysList}
            onChange={this.onSelectDays}
            noOptionsMessage={() => "Set"}
            openMenuOnFocus={true}
            onKeyDown={this.onKeyDown}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={true}
            tabIndex={2}
          />

          <div className="font-weight-300 default-font-size ml-2.5 secondary-text-color">
            {`${pluralize(this.state.daysForward, "day")} into the future`}
          </div>
        </div>

        {this.props.shouldDisplayBufferPastDaysForwardWarning ? (
          <div className="default-font-size warning-color">
            Buffer is further out than what the invitee can schedule into the
            future
          </div>
        ) : null}

        <div className="default-font-size secondary-text-color font-weight-400 mt-5">
          Buffers
        </div>

        <div className="mt-2 display-flex-flex-direction-row">
          <div style={{ width: 170 }}>
            <div className="font-weight-300 default-font-size secondary-text-color">
              Before conflicts
            </div>

            <div className="display-flex-flex-direction-row align-items-center mt-2">
              <div>
                <CreatableSelect
                  isSearchable={true}
                  ref={this._selectBufferBefore}
                  className={classNames(
                    "select-duration-time",
                    this.props.isDarkMode ? "dark-mode-select" : "",
                    "select-default-font-size",
                  )}
                  classNamePrefix="dark-mode"
                  value={{
                    value: this.state.bufferBefore,
                    label: this.state.bufferBefore,
                  }}
                  options={this.state.minuteList}
                  onChange={this.onSelectBufferBefore}
                  noOptionsMessage={() => "Set"}
                  openMenuOnFocus={true}
                  onKeyDown={this.onKeyDown}
                  formatCreateLabel={(userInput) => userInput}
                  tabSelectsValue={true}
                  tabIndex={3}
                  styles={getReactSelectBaseStyle({
                    isDarkMode: this.props.isDarkMode,
                    showBorder: true,
                  })}
                  components={{ DropdownIndicator }}
                />
              </div>

              <div className="font-weight-300 default-font-size ml-2.5">
                mins
              </div>
            </div>
          </div>

          <div style={{ width: 170 }}>
            <div className="font-weight-300 default-font-size ml-2.5 secondary-text-color">
              After conflicts
            </div>

            <div className="display-flex-flex-direction-row align-items-center mt-2">
              <div>
                <CreatableSelect
                  isSearchable={true}
                  ref={this._selectBufferAfter}
                  className={classNames(
                    "select-duration-time",
                    this.props.isDarkMode ? "dark-mode-select" : "",
                    "select-default-font-size",
                  )}
                  classNamePrefix="dark-mode"
                  value={{
                    value: this.state.bufferAfter,
                    label: this.state.bufferAfter,
                  }}
                  options={this.state.minuteList}
                  onChange={this.onSelectBufferAfter}
                  noOptionsMessage={() => "Set"}
                  openMenuOnFocus={true}
                  onKeyDown={(e) => this.onKeyDown(e, true)}
                  formatCreateLabel={(userInput) => userInput}
                  tabSelectsValue={true}
                  tabIndex={4}
                  styles={getReactSelectBaseStyle({
                    isDarkMode: this.props.isDarkMode,
                    showBorder: true,
                  })}
                  components={{ DropdownIndicator }}
                />
              </div>

              <div className="font-weight-300 default-font-size ml-2.5">
                mins
              </div>
            </div>
          </div>

          <div tabIndex={5} onFocusCapture={this.onFocusBottomFocusGuard}></div>
        </div>

        <BufferFromNow
          bufferFromNow={this.state.bufferFromNow}
          setBufferFromNow={(value) => this.onSelectBufferFromNow(value)}
          isPersonalLinks={true}
        />

        <div className="mt-5 relative">
          <IgnoreConflicts
            isIgnoreConflicts={this.state.isIgnoreConflicts}
            onChange={(isIgnoreConflicts) => {
              this.setState({ isIgnoreConflicts });
              this.props.saveSetting({ isIgnoreConflicts });
            }}
          />
        </div>
        {this.getInternalDomains()?.length > 0
          ? <div className="mt-4 relative">
            <IgnoreInternalConflicts
              isIgnoreInternalConflicts={this.state.isIgnoreInternalConflicts}
              onChange={(isIgnoreInternalConflicts) => {
                this.setState({ isIgnoreInternalConflicts });
                this.props.saveSetting({ isIgnoreInternalConflicts });
              }}
              displayGoToSetting={false}
              moreInfoOverrideClassName={"top-22px-override"}
            />
          </div>
          : null
        }
        <BlockedCalendarSection
          containerClassNameOverride={classNames(
            "mt-4",
            "flex mt-4 justify-between",
          )}
          titleClassNameOverride={
            "default-font-size font-weight-300 secondary-text-color "
          }
          onSelectBlockingCalendars={this.props.onClickSetBlockingCalendar}
          blockedCalendars={this.props.blockedCalendars}
          blockedCalendarsID={this.props.blockedCalendarsID}
          isDisabledCheckForConflict={this.state.isIgnoreConflicts}
        />
        {this.shouldShowWarning() ? <Alert
          title={PERSONAL_LINK_NO_BLOCKING_CALENDARS_COPY.TITLE}
          subText={PERSONAL_LINK_NO_BLOCKING_CALENDARS_COPY.SUBTEXT}
          excludeDefaultMargins={true}
          className="mt-2"
        /> : null}
      </div>
    );
  }

  getInternalDomains() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return getInternalDomainAndEmails({
      masterAccount,
      user: this.props.user ?? currentUser,
    }) ?? [];
  }

  onKeyDown(event) {
    // ESC key
    if (event && event.keyCode === KEYCODE_ESCAPE) {
      this._selectDaysRef.current.blur();
      this._selectBufferBefore.current.blur();
      this._selectBufferAfter.current.blur();
    }
  }

  onFocusBottomFocusGuard() {
    this.focusTopIndexTab();
  }

  onFocusTopFocusGuard() {
    this._selectBufferAfter &&
      this._selectBufferAfter.current &&
      this._selectBufferAfter.current.focus();
  }

  focusTopIndexTab() {
    this._selectDaysRef &&
      this._selectDaysRef.current &&
      this._selectDaysRef.current.focus();
  }

  shouldShowWarning() {
    if (this.state.isIgnoreConflicts) {
      return false;
    }
    return isEmptyArrayOrFalsey(this.props.blockedCalendarsID);
  }

  onSelectDays(selected) {
    const daysForward = selected.value;
    this.props.saveSetting({ daysForward });

    this.setState({ daysForward });
  }

  onSelectBufferBefore(selected) {
    const bufferBefore = selected.value;
    if (!isInt(bufferBefore)) {
      return;
    }
    this.props.saveSetting({ bufferBefore });

    this.setState({ bufferBefore });
  }

  onSelectBufferAfter(selected) {
    const bufferAfter = selected.value;
    if (!isInt(bufferAfter)) {
      return;
    }

    this.props.saveSetting({ bufferAfter });

    this.setState({ bufferAfter });
  }

  onSelectBufferFromNow(value) {
    this.props.saveSetting({ bufferFromNow: value });
    this.setState({ bufferFromNow: value });
  }
}

function mapStateToProps(state) {
  const { isDarkMode, currentUser } = state;

  return {
    isDarkMode,
    currentUser,
  };
}

// eslint-disable-next-line
const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      masterAccount={masterAccount}
    />
  );
};

export default connect(mapStateToProps, null)(withStore(PersonalLinkSettingsPage));
