import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { getSelectTimeList } from "../../../select/helpFunctions";
import CustomSelect from "../../../select";
import { getReactSelectBaseStyle } from "../../../select/styles";
import { getIsAccountIn24HourFormat, getWorkHours } from "../../../../lib/settingsFunctions";

const LABEL_CLASSNAME = "secondary-text-color";
const SHARED_CLASSNAME =
  "default-font-size user-select-none flex items-center w-full justify-between";

export default function WorkHours({ selectedUser, isUpdatingExecutiveProfile }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const is24HourFormat = getIsAccountIn24HourFormat({ masterAccount, user: selectedUser });
  const list = getSelectTimeList({ is24HourFormat });
  const {
    startWorkHour: initialStartWorkHour,
    endWorkHour: initialEndWorkHour,
  } = getWorkHours({ masterAccount, user: selectedUser });

  useEffect(() => {
    if (!selectedUser) {
      return;
    }
    const {
      startWorkHour: initialStartWorkHour,
      endWorkHour: initialEndWorkHour,
    } = getWorkHours({ masterAccount, user: selectedUser });
    setStartWorkHour(initialStartWorkHour);
    setEndWorkHour(initialEndWorkHour);
  }, [selectedUser]);

  const [startWorkHour, setStartWorkHour] = useState(initialStartWorkHour);
  const [endWorkHour, setEndWorkHour] = useState(initialEndWorkHour);
  const [showWarning, setShowWarning] = useState(false);

  const onChangeStart = (option) => {
    if (option.value >= endWorkHour - 1) {
      setShowWarning(true);
    } else {
      setStartWorkHour(option.value);
      setShowWarning(false);
      const updatedSettings = {
        work_hours: { start: option.value, end: endWorkHour },
      };
      updateMasterAccountSettingsForFrontendAndBackend({
        masterAccount,
        updatedSettings,
        user: selectedUser,
        isUpdatingExecutiveProfile,
      });
    }
  };
  const onChangeEnd = (option) => {
    if (startWorkHour >= option.value - 1) {
      setShowWarning(true);
    } else {
      setEndWorkHour(option.value);
      setShowWarning(false);
      const updatedSettings = {
        work_hours: { start: startWorkHour, end: option.value },
      };
      updateMasterAccountSettingsForFrontendAndBackend({
        masterAccount,
        updatedSettings,
        user: selectedUser,
        isUpdatingExecutiveProfile,
      });
    }
  };

  return (
    <div>
      <div className={classNames(SHARED_CLASSNAME, "my-2")}>
        <div className={LABEL_CLASSNAME}>Start work hour</div>
        <CustomSelect
          value={list[startWorkHour]}
          classNamePrefix="dark-mode-modal"
          options={list}
          onChange={onChangeStart}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            controlWidth: "80px",
          })}
        />
      </div>

      <div className={SHARED_CLASSNAME}>
        <div className={LABEL_CLASSNAME}>End work hour</div>
        <CustomSelect
          value={list[endWorkHour]}
          classNamePrefix="dark-mode-modal"
          options={list}
          onChange={onChangeEnd}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            controlWidth: "80px",
          })}
        />
      </div>
      {showWarning ? (
        <div className="default-font-size mt-3 flex justify-end text-red-500">
          Start work hour must be at least an hour before end work hour
        </div>
      ) : null}
    </div>
  );
}
