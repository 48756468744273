import React from "react";
import { X } from "react-feather";

import ShortcutHoverHint from "../../components/shortcutHoverHint";
import { isMac } from "../../services/commonUsefulFunctions";

const MaestroExitButton = ({ onClick, shouldHideHint }) => {
  return (
    <div className="maestro-exit-button-container maestro-back-exit-button-container">
      <ShortcutHoverHint
        below
        style={{
          backgroundColor: "#050012",
          top: isMac() ? "0px" : "calc(100% - 50px)",
          right: isMac() ? "60px" : "calc(100% + 10px)",
          width: "max-content",
          zIndex: 4,
        }}
        title={"Return home"}
        shortcut={"Esc"}
        overrideShortcutTextColor={"white"}
        labelOverrideClassName={"text-white-override"}
        shouldHideHint={shouldHideHint}
      >
        <button
          className="weekly-toolbar-button maestro-back-exit-button"
          onClick={onClick}
        >
          <X className="non-clickable-icon" size="30" color="white" />
        </button>
      </ShortcutHoverHint>
    </div>
  );
};

export default MaestroExitButton;
