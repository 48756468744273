import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addAbbrevationToTimeZone,
  CreateTimeZoneList,
  createAbbreviationForTimeZone,
  guessTimeZone,
  isTooltipCompleted,
} from "../services/commonUsefulFunctions";
import CommandCenter from "./commandCenter";
import Broadcast from "../broadcasts/broadcast";
import {
  doTemporaryTimeZonesExist,
  getRecentlySearchedTimeZones,
  getRecentTimeZoneGroups,
  createTimeZoneGroupLabel,
  getFilteredGroupTimeZones,
  getMostLeftHandTimeZone,
} from "../lib/stateManagementFunctions";
import { isAfter, parseISO } from "date-fns";
import AppBroadcast from "../broadcasts/appBroadcast";
import { tooltipKeys } from "../services/tooltipVariables";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { 
  countryTimeZones,
  getAllKeyValueCountryTimeZones,
  getAllTimeZoneForGMTOffset, 
  getMatchingMultipleTimeZoneKey, 
  getMatchingPopularTimeZones, 
  getMatchingSingleTimeZoneCountry, 
  getMatchingSingleTimeZoneCountryResult, 
  getMatchingTimeZoneForMultipleCountryTZs, 
  getMatchingTimeZoneForMultipleTimeZoneCountry, 
  getMatchingTimeZoneFromSingleTimeZoneCountry, 
  getOffSetFromGMTSearchString, 
  stringContainsGMTAndNumber,
  timeZonesForCountriesWithMultipleTimeZone
} from "../services/timeZone";
import { useAppTimeZones } from "../services/stores/appFunctionality";
import { getGoogleMapsAutoComplete } from "../lib/googleFunctions";
import { isAvailabilityPanelShowing } from "../services/appFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { SECOND_IN_MS } from "../services/globalVariables";
import { immutablySortArray } from "../lib/arrayFunctions";
import { BROADCAST_VALUES } from "../lib/broadcastValues";

class TimeZoneCommandCenter extends Component {
  constructor(props) {
    super(props);

    this._searchLocationTimer = null;

    let timeZoneObject = CreateTimeZoneList(this.props.currentTimeZone);
    if (this.props.filterOutLocalTimeZone) {
      let localTimeZone = guessTimeZone();
      timeZoneObject = timeZoneObject.filter(
        (tz) => tz.value !== localTimeZone
      );
    }

    const createInitialOptions = () => {
      let options = [];
      if (isAvailabilityPanelShowing()
        && !isEmptyArrayOrFalsey(props.anchorTimeZones)
      ) {
        const filteredAnchors = props.anchorTimeZones.filter(tz => tz !== this.props.currentTimeZone)
          .map(tz => ({value: tz, label: addAbbrevationToTimeZone({timeZone: tz})}));
        const filteredAnchorOptions = this.createOptions(filteredAnchors);
        options = options.concat(filteredAnchorOptions);
      }

      if (this.props.currentTimeZoneLabel) {
        options = options.concat(this.createInitialOptions())
          .concat(this.createRemoveSecondaryCalendarOption());
      } else {
        options = options.concat(this.createInitialOptions());
      }
      return options;
    };

    const timeZoneOptions = this.props.currentTimeZoneLabel
      ? []
        .concat(this.createOptions(timeZoneObject))
        .concat(this.createRemoveSecondaryCalendarOption())
      : this.createOptions(timeZoneObject);
    const initialOptions = createInitialOptions();
    this.state = {
      timeZoneObject,
      defaultOptions: timeZoneOptions,
      options: initialOptions,
      input: "",
      initialOptions: initialOptions,
    };

    this.onSelectTimeZone = this.onSelectTimeZone.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.searchForLocationSuggestion =
      this.searchForLocationSuggestion.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUpLocationAutoComplete();

    /* Check if the tooltip has been completed */
    const { masterAccount } = this.props.masterAccount;
    if (!isTooltipCompleted(masterAccount, tooltipKeys.TIME_TRAVEL)) {
      Broadcast.publish(BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED, tooltipKeys.TIME_TRAVEL);
    }
  }

  componentWillUnmount() {
    this.locationAutocomplete = null;
    if (this._searchLocationTimer) {
      clearTimeout(this._searchLocationTimer);
    }
    this._searchLocationTimer = null;
    this._isMounted = false;
  }

  render() {
    if (!this.props.shouldShowCommandCenter) {
      return null;
    }

    return (
      <CommandCenter
        title="Time Travel"
        placeholder="Search by city or time zone"
        handleCloseModal={this.props.handleCloseModal}
        options={this.state.options}
        onChangeInput={this.updateInput}
        updateSearchResultStateOnChange={true}
        additionalClassName={this.props.additionalClassName}
      />
    );
  }

  updateInput(input) {
    if (!this._isMounted) {
      return;
    }

    this._searchLocationTimer && clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;

    this._searchLocationTimer = setTimeout(
      () => this.searchForLocationSuggestion(input),
      SECOND_IN_MS / 2
    );

    let updatedOptions = this.state.initialOptions;

    if (input.length > 0) {
      updatedOptions = this.filterOptions(input);
    }

    this.setState({ input: input, options: updatedOptions });
  }

  createRemoveSecondaryCalendarOption() {
    if (doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
      return [
        {
          key: "removeSecondaryTimeZoneTz",
          title: "Remove temporary time zones",
          onClickHandler: this.resetTimeZone,
          searchQueries: "remove, secondary, time zone",
          shortcut: "ESC",
        },
      ];
    }

    return [];
  }

  createOptions(timeZones) {
    return timeZones.map((timeZone, index) => {
      return {
        key: timeZone.value,
        title: timeZone.label,
        onClickHandler: () => this.onSelectTimeZone(timeZone.value),
        searchQueries:
          timeZone.label
            .split(/_|\/| /)
            .toString()
            .toLowerCase() +
          " " +
          timeZone.searchQueries,
        shortcut: "Time zone",
        doNotSplitShortcut: true,
      };
    });
  }

  onSelectTimeZone(timeZone) {
    const updatedTimeZone = timeZone || guessTimeZone();

    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: updatedTimeZone,
    });

    const currentTimeZoneList = getRecentlySearchedTimeZones(this.props.currentUser);
    let filteredValue = currentTimeZoneList.filter(
      (t) => t.value !== updatedTimeZone
    );

    let timeZoneLabel = updatedTimeZone;
    this.state.timeZoneObject.forEach((t) => {
      if (t.value === updatedTimeZone) {
        timeZoneLabel = t.label;
      }
    });

    let updatedRecentTimeZones = [
      {
        isTimeZone: true,
        value: updatedTimeZone,
        label: timeZoneLabel,
        ts: new Date().toISOString(),
      },
    ].concat(filteredValue);

    Broadcast.publish(
      "UPDATE_RECENTLY_SEARCHED_TIME_ZONES",
      updatedRecentTimeZones
    );
  }

  resetTimeZone() {
    Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
  }

  setUpLocationAutoComplete() {
    this.locationAutocomplete = getGoogleMapsAutoComplete();
  }

  searchForLocationSuggestion(inputText = null) {
    if (!this._isMounted) {
      return;
    }

    const input = inputText || this.state.input;
    const {
      currentTimeZone,
    } = this.props;

    if (this.locationAutocomplete && input?.length > 0) {
      this.locationAutocomplete.getPlacePredictions(
        { input: input, types: ["(cities)"] },
        (predictions) => {
          if (!this._isMounted) {
            return;
          }
          const matchingCountryResults = this.getMatchingCountrysTimezone(input);
          const currentOptions = this.state.options;
          const isTimeZoneOptions = currentOptions.filter((c) => !c.isCity);
          const matchingPopularTimeZones = getMatchingPopularTimeZones({
            input
          }).filter(tz => tz !== currentTimeZone);
          let updatedOptions = matchingPopularTimeZones.length > 0
            ? isTimeZoneOptions.concat(matchingCountryResults)
            : matchingCountryResults.concat(isTimeZoneOptions);
          if (!predictions) {
            // add country time zones
            this.setState({ options: updatedOptions });
            return;
          }

          const formattedSuggestions =
            this.formatLocationPredictions(predictions);
          updatedOptions = updatedOptions
            .concat(formattedSuggestions);
          if (matchingPopularTimeZones.length > 0) {
            const formattedPopularTimeZones = matchingPopularTimeZones.map(tz => {
              return ({value: tz, label: addAbbrevationToTimeZone({timeZone: tz})});
            });
            updatedOptions = this.createOptions(formattedPopularTimeZones).concat(formattedSuggestions);
          }

          this.setState({ options: updatedOptions });
        }
      );
    } else {
      if (!this.locationAutocomplete) {
        this.setUpLocationAutoComplete();
      }
    }
  }

  getMatchingCountrysTimezone(input) {
    if (!input) {
      return [];
    }
    const singleTimeZoneCountry = this.formatSingleTimeZoneCountryResult(getMatchingSingleTimeZoneCountryResult(input));
    const multipleTimeZoneCountry = this.formatMultipleTimeZoneCountryResult(getMatchingTimeZoneForMultipleCountryTZs(input));
    return multipleTimeZoneCountry.concat(singleTimeZoneCountry);
  }

  getAllCountryTimeZones() {
    const matches = getAllKeyValueCountryTimeZones();
    return matches.map((match) => {
      const {
        key,
        value: timeZone,
        type
      } = match;
      return {
        key,
        onClickHandler: () => {
          Broadcast.publish("SELECT_TIME_ZONE", {
            timeZone
          });
        },
        searchQueries: key,
        shortcut: type,
        title: `(${createAbbreviationForTimeZone(timeZone)}) ${key}`,
      }
    });
  }

  formatMultipleTimeZoneCountryResult(matches) {
    return matches.map((key) => {
      const countryKey = getMatchingMultipleTimeZoneKey(key);
      return {
        isCountry: true,
        key: countryKey,
        onClickHandler: () => {
          this.searchForMultipleCountryTimeZone(countryKey);
        },
        searchQueries: countryKey,
        shortcut: "Region",
        title: `(${createAbbreviationForTimeZone(timeZonesForCountriesWithMultipleTimeZone[countryKey])}) ${countryKey}`,
      }
    });
  }

  formatSingleTimeZoneCountryResult(matches) {
    return matches.map((country) => {
      const matchingCountry = getMatchingSingleTimeZoneCountry(country);
      return {
        isCountry: true,
        key: country,
        onClickHandler: () => this.searchForSingleCountryTimeZone(country),
        searchQueries: country,
        shortcut: "Country",
        title: `(${createAbbreviationForTimeZone(countryTimeZones[matchingCountry])}) ${matchingCountry}`,
      }
    });
  }

  searchForMultipleCountryTimeZone(key) {
    const matchingTimeZone = getMatchingTimeZoneForMultipleTimeZoneCountry(key);
    if (!matchingTimeZone) {
      return;
    }
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: matchingTimeZone
    });
  }

  searchForSingleCountryTimeZone(country) {
    const matchingTimeZone = getMatchingTimeZoneFromSingleTimeZoneCountry(country);
    if (!matchingTimeZone) {
      return;
    }
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: matchingTimeZone
    });
  }

  formatLocationPredictions(predictions) {
    if (isEmptyArrayOrFalsey(predictions)) {
      return [];
    }

    return predictions.map((p, index) => {
      return {
        key: p.description.replace(/,/g, "_").replace(/ /g, "_"),
        isCity: true,
        onClickHandler: () => this.searchForLocale(p.description),
        searchQueries: p.description,
        title: p.description,
        shortcut: "City",
      };
    });
  }

  searchForLocale(description) {
    AppBroadcast.publish("FETCH_TIMEZONE_BY_GEOCODE", description);
  }

  onSelectTimeZoneFromCity(timeZone, city) {
    const updatedTimeZone = timeZone || guessTimeZone();

    let hasUpdatedTimeZone = false;
    Broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: updatedTimeZone
    });
    hasUpdatedTimeZone = true

    if (!hasUpdatedTimeZone) {
      Broadcast.publish("SELECT_TIME_ZONE", {
        timeZone: updatedTimeZone
      });
    }

    const currentTimeZoneList = getRecentlySearchedTimeZones(this.props.currentUser);
    let filteredValue = currentTimeZoneList.filter((t) => t.label !== city);

    let updatedRecentTimeZones = [
      {
        isTimeZone: false,
        value: timeZone,
        label: city,
        ts: new Date().toISOString(),
      },
    ].concat(filteredValue);

    Broadcast.publish(
      "UPDATE_RECENTLY_SEARCHED_TIME_ZONES",
      updatedRecentTimeZones
    );
  }

  getLeftHandTimeZone() {
    const {
      defaultBrowserTimeZone
    } = this.props;
    const {
      lastSelectedTimeZone
    } = this.props.appTimeZone;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone,
      skipCheckForTemporaryTimeZones: true
    })
  }

  createInitialOptions() {
    let groupTimeZones = [];
    const setGroupTimeZones = (groupTimeZone) => {
      const {
        newTimeZone,
        filteredGroupTimeZone,
      } = getFilteredGroupTimeZones({
        groupTimeZone,
        leftHandTimeZone: this.getLeftHandTimeZone(),
      });
      Broadcast.publish(
        "SELECT_TIME_ZONE",
        {
          timeZone: newTimeZone,
          groupTimeZone: filteredGroupTimeZone,
        }
      );
    };

    const sortRecentTimeZoneOptions = (a, b) => {
      if (a.ts && !b.ts) {
        return -1;
      } else if (!a.ts && b.ts) {
        return 1;
      } else if (a.ts && b.ts) {
        if (isAfter(parseISO(a.ts), parseISO(b.ts))) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    };

    let temporaryTimeZones = this.props.temporaryTimeZones || [];
    let temporaryTimeZonesLabel =
      temporaryTimeZones.length === 0
        ? ""
        : createTimeZoneGroupLabel({ value: temporaryTimeZones });
    const recentGroupedTimeZones = getRecentTimeZoneGroups(this.props.currentUser);
    recentGroupedTimeZones.forEach((group) => {
      const groupLabel = createTimeZoneGroupLabel(group);
      if (temporaryTimeZonesLabel === groupLabel) {
        return;
      }
      groupTimeZones = groupTimeZones.concat({
        key: groupLabel,
        title: groupLabel,
        onClickHandler: () => setGroupTimeZones(group.value),
        searchQueries: groupLabel.toLowerCase(),
        shortcut: "Multiple",
        doNotSplitShortcut: true,
        ts: group.ts,
      });
    });

    let individualRecentTimeZones = [];
    getRecentlySearchedTimeZones(this.props.currentUser).forEach((tz) => {
      if (temporaryTimeZones.includes(tz.value)) {
        return;
      }
      if (tz.isTimeZone) {
        individualRecentTimeZones = individualRecentTimeZones.concat({
          key: tz.value,
          title: tz.label,
          onClickHandler: () => this.onSelectTimeZone(tz.value),
          searchQueries:
            tz.label
              .split(/_|\/| /)
              .toString()
              .toLowerCase() +
            " " +
            tz.searchQueries,
          shortcut: "Time zone",
          isRecent: true,
          doNotSplitShortcut: true,
          ts: tz.ts,
        });
      } else {
        individualRecentTimeZones = individualRecentTimeZones.concat({
          key: tz.label.replace(/,/g, "_").replace(/ /g, "_"),
          isCity: true,
          onClickHandler: () =>
            this.onSelectTimeZoneFromCity(tz.value, tz.label),
          searchQueries: tz.label,
          title: tz.label,
          isRecent: true,
          shortcut: "City",
          ts: tz.ts,
        });
      }
    });

    return immutablySortArray(groupTimeZones
      .concat(individualRecentTimeZones), (a, b) => sortRecentTimeZoneOptions(a, b));
  }

  filterOptions(input = null) {
    let inputText = input || this.state.input;
    let loweredInput = inputText.toLowerCase();
    let currentOptions = this.state.options;

    let isCityOptions = currentOptions.filter(
      (c) =>
        c.isCity &&
        (c.title.toLowerCase().includes(loweredInput) || !c.isRecent)
    );

    let filteredOptions = this.state.defaultOptions.filter(
      (tz) =>
        tz.title.toLowerCase().includes(loweredInput) ||
        tz.key.toLowerCase().includes(loweredInput) ||
        tz.searchQueries.includes(loweredInput)
    );

    let overrideInitialValue = []; // for values we want to place at the front
    if (stringContainsGMTAndNumber(inputText)) {
      const offset = getOffSetFromGMTSearchString(inputText);
      if (offset) {
        if (inputText.includes("+")) {
          const timeZone = getAllTimeZoneForGMTOffset(`gmt+${offset}`);
          const matchingValues = this.state.defaultOptions.filter(val => {
            return timeZone?.includes(val.key);
          });
          
          overrideInitialValue = matchingValues;
        } else if (inputText.includes("-")) {
          const timeZone = getAllTimeZoneForGMTOffset(`gmt-${offset}`);
          const matchingValues = this.state.defaultOptions.filter(val => {
            return timeZone?.includes(val.key);
          });
          
          overrideInitialValue = matchingValues;
        }
      }
    }

    const updatedOptions = overrideInitialValue.concat(filteredOptions).concat(isCityOptions);
    return updatedOptions;
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    temporaryTimeZones,
    anchorTimeZones,
  } = state;

  return {
    currentUser,
    currentTimeZone,
    defaultBrowserTimeZone,
    currentTimeZoneLabel,
    temporaryTimeZones,
    anchorTimeZones,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();
  const appTimeZone = useAppTimeZones();

  return <BaseComponent {...props} masterAccount={masterAccount} appTimeZone={appTimeZone} />;
};

export default connect(
  mapStateToProps,
  null
)(withStore(TimeZoneCommandCenter));
