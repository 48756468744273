import { useCallback, useEffect } from "react";
import Fetcher from "../../services/fetcher";
import { constructRequestURL } from "../../services/api";
import {
  getEventAttendees,
  getEventUserCalendarID,
} from "../../services/eventResourceAccessors";
import { handleError } from "../../services/commonUsefulFunctions";
import { useStoredContactsStore } from "../../services/stores/enrichedContacts";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import contactBroadcast from "../../broadcasts/contactBroadcast";
import { getCalendarUserEmail } from "../../lib/calendarFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import { useAllCalendars } from "../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { getUserEmail } from "../../lib/userFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
} from "../../services/typeGuards";
import { equalAfterTrimAndLowerCased } from "../../lib/stringFunctions";

function getFirstAndLastName(name) {
  if (!name) {
    return { firstName: null, lastName: null };
  }

  const nameSplit = name.split(" ");
  return { firstName: nameSplit[0], lastName: nameSplit.slice(1)?.join(" ") };
}

function ContactsContainer({ events }) {
  const { addStoredEmails, storedEmails } = useStoredContactsStore();
  const componentIsMounted = useIsMounted();
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const currentUserEmail = getUserEmail(currentUser);

  const updateContacts = useCallback(
    (allCalendars, events) => {
      const storedEmailsSet = new Set(storedEmails[currentUserEmail] ?? []);
      const duplicateContactsFromPrimaryCalendar = events
        /* Filter for events on primary calendar first */
        ?.filter((event) => {
          if (!getEventAttendees(event)) {
            // filter out attendees
            return false;
          }

          const userCalendarID = getEventUserCalendarID(event);
          const calendar = allCalendars[userCalendarID];

          if (!isEmptyObjectOrFalsey(calendar)) {
            return equalAfterTrimAndLowerCased(
              getCalendarUserEmail(calendar),
              currentUserEmail
            );
          }

          return false;
        })
        /* Flat map all the attendees */
        .flatMap((event) => {
          return getEventAttendees(event)
            .filter((attendee) => !!getObjectEmail(attendee))
            .filter((attendee) => !!attendee.displayName)
            .map((attendee) => {
              const { firstName, lastName } = getFirstAndLastName(
                attendee.displayName
              );

              return {
                email: getObjectEmail(attendee),
                firstName,
                lastName,
                fullName: attendee.displayName,
              };
            });
        })
        .filter((contact) => {
          // filter out emails that we already saved
          return !storedEmailsSet.has(getObjectEmail(contact));
        });

      if (duplicateContactsFromPrimaryCalendar.length === 0) {
        return;
      }

      const uniqueContactsIndex = {}; // key = email, value = contact
      duplicateContactsFromPrimaryCalendar.forEach((contact) => {
        const email = getObjectEmail(contact);
        if (!email) {
          return;
        }

        if (!uniqueContactsIndex[email]) {
          uniqueContactsIndex[email] = contact;
        } else if (!uniqueContactsIndex[email]?.fullName && contact.fullName) {
          // full name does not exist in uniqueContacts, but does in contact -> add in uniqueContacts
          uniqueContactsIndex[email] = contact;
        }
      });

      // uniqueContacts:
      // {email: 'mike@vimcal.com', firstName: null, lastName: null, fullName: undefined}
      const uniqueContacts = Object.values(uniqueContactsIndex);
      const emails = uniqueContacts
        ?.map((c) => getObjectEmail(c))
        ?.filter((email) => !!email);
      if (emails?.length > 0) {
        addStoredEmails({
          emails,
          userEmail: currentUserEmail,
        });
      }

      const path = "contacts";
      const url = constructRequestURL(path, true);
      const payloadData = {
        body: JSON.stringify({
          contacts: uniqueContacts,
        }),
      };

      Fetcher.post(url, payloadData, true)
        .then((response) => {
          if (!componentIsMounted.current || isEmptyObjectOrFalsey(response)) {
            return;
          }

          const { contacts } = response;
          if (isEmptyArrayOrFalsey(contacts)) {
            return;
          }
          const emails = uniqueContacts
            .filter((contact) => !!getObjectEmail(contact))
            .map((c) => getObjectEmail(c));
          addStoredEmails({
            emails,
            userEmail: currentUserEmail,
          });
          // as long as backend returns something, we store the input into db.
          contactBroadcast.publish("SAVE_CONTACTS_INTO_DB", {
            user: currentUser,
            contacts,
          });
        })
        .catch((error) => {
          handleError(error);
        });
    },
    [
      currentUserEmail,
      storedEmails,
      addStoredEmails,
      componentIsMounted,
      currentUser,
    ]
  );

  useEffect(() => {
    updateContacts(allCalendars, events);
  }, [allCalendars, events, updateContacts]);

  useEffect(() => {
    contactBroadcast.subscribe("GET_EVENT_CONTACTS", updateContacts);

    return () => {
      contactBroadcast.unsubscribe("GET_EVENT_CONTACTS");
    };
  }, []);

  return null;
}

export default ContactsContainer;
