import { isErrorResponse } from "../services/api";
import { SECOND_IN_MS } from "../services/globalVariables";
import { isNullOrUndefined } from "../services/typeGuards";
import { shouldOpen429Retry } from "./featureFlagFunctions";

// response of the format {error, retry_after}
export function shouldRetryBasedOnResponse(response, user) {
  try {
    if (!shouldOpen429Retry(user)) {
      // gate it for now
      return false;
    }
    if (!isErrorResponse(response)) {
      return false;
    }
    const retryAfterMS = getRetryAfterMS(response);
    if (isNullOrUndefined(retryAfterMS)) {
      return false;
    }
    if (isNaN(retryAfterMS)) {
      return false;
    }
    return retryAfterMS;
  } catch (e) {
    return false;
  }
}

export function getRetryAfterMS(response) {
  try {
    const retryAfterSeconds = parseInt(response?.retry_after || 0, 10);
    return retryAfterSeconds * SECOND_IN_MS;
  } catch (e) {
    return null;
  }
}

export const TEST_429_ERROR_RESPONSE = {error: "test", retry_after: 5};
