import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import EventModalPopup from "../eventModalPopup";
import {
  DISCARD_WARNING_MODAL_WIDTH,
  AVAILABILITY_GROUP_VOTE_CONTAINER_ID,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import GroupVoteInfoContainer from "./groupVoteInfoContainer";
import Broadcast from "../../broadcasts/broadcast";
import classNames from "classnames";
import {
  getActiveGroupVotes,
  isGroupVoteLinkExpired,
} from "../../lib/availabilityFunctions";
import { ChevronDown } from "react-feather";
import GroupVoteListEmptyState from "./groupVoteListEmptyState";
import SaveAndCancelButton from "../buttons/saveAndCancelButton";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isSpreadsheetGroupLink } from "../../lib/groupVoteFunctions";
import { getGroupVoteLinkEventsHold } from "../../services/maestro/maestroAccessors";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";

export default function GroupVoteListView({
  groupVoteLinks,
  onEdit,
  onCopyLink,
  onPreview,
  onDuplicate,
  onDelete,
  selectedUser,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [currentBookingLink, setCurrentBookingLink] = useState(null);
  const [isExpiredOpen, setIsExpiredOpen] = useState(false);

  const expiredGroupVotes = useMemo(
    () => groupVoteLinks.filter((gv) => isGroupVoteLinkExpired(gv)),
    [groupVoteLinks],
  );
  const activeGroupVotes = useMemo(
    () => getActiveGroupVotes(groupVoteLinks),
    [groupVoteLinks],
  );

  const onCloseModal = () => {
    setIsModalOpen(false);
    setCurrentBookingLink(null);
  };

  const onClickDelete = (bookingLink) => {
    setIsModalOpen(true);
    setCurrentBookingLink(bookingLink);
  };

  const onConfirmDelete = () => {
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `Deleted "${determineEventTitle()}"`,
    );
    onDelete(currentBookingLink);
    setIsModalOpen(false);
  };

  const determineEventTitle = () => {
    return currentBookingLink?.title || "(No title)";
  };

  const determineModalTitle = () => {
    /* Check for holds */
    if (
      !isEmptyObjectOrFalsey(
        getGroupVoteLinkEventsHold({ bookingLink: currentBookingLink }),
      )
    ) {
      const groupVoteType = isSpreadsheetGroupLink(currentBookingLink)
        ? "Spreadsheet"
        : "Link";
      return `Deleting this Group Vote ${groupVoteType} will also delete the correlating Smart Holds on the calendar. Are you sure you want to proceed?`;
    }

    return `Are you sure you want to delete "${determineEventTitle()}"`;
  };

  const renderEmptyMessage = () => {
    if (activeGroupVotes?.length > 0) {
      return null;
    }

    return <GroupVoteListEmptyState />;
  };

  const renderExpiredLinks = () => {
    if (expiredGroupVotes.length === 0) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(
            "hoverable-secondary-text-color flex w-full justify-between select-none mb-2.5",
            activeGroupVotes?.length > 0 ? "" : "mt-5",
          )}
          style={{ width: 320 }}
          onClick={() => setIsExpiredOpen(!isExpiredOpen)}
        >
          <div
            className={"default-font-size duration-200"}
          >{`Expired links (${expiredGroupVotes.length})`}</div>
          <ChevronDown
            size={16}
            className={classNames(
              isExpiredOpen ? "rotate-upside-down" : "",
              "duration-200",
            )}
          />
        </div>

        {
          isExpiredOpen ?
            expiredGroupVotes.map((bl) => {
              return (
                <GroupVoteInfoContainer
                  uniqueKey={`group-vote-availability-wrapper-${bl.token}`}
                  bookingLinkInfo={bl}
                  key={`group-vote-availability-wrapper-${bl.token}`}
                  onEdit={onEdit}
                  onCopyLink={onCopyLink}
                  onPreview={onPreview}
                  onDuplicate={onDuplicate}
                  onDelete={onClickDelete}
                  selectedUser={selectedUser}
                />
              );
            }) :
            null
        }
      </>
    );
  };

  return (
    <div
      id={AVAILABILITY_GROUP_VOTE_CONTAINER_ID}
      className="select-availability-content-layout-wrapper flex flex-col"
    >
      {renderEmptyMessage()}
      {activeGroupVotes.map((bl) => {
        return (
          <GroupVoteInfoContainer
            uniqueKey={`group-vote-availability-wrapper-${bl.token}`}
            bookingLinkInfo={bl}
            key={`group-vote-availability-wrapper-${bl.token}`}
            onEdit={onEdit}
            onCopyLink={onCopyLink}
            onPreview={onPreview}
            onDuplicate={onDuplicate}
            onDelete={onClickDelete}
            selectedUser={selectedUser}
          />
        );
      })}

      {renderExpiredLinks()}

      <EventModalPopup
        isOpen={isModalOpen}
        skipDisablingHotKeys={true}
        onRequestClose={onCloseModal}
        width={DISCARD_WARNING_MODAL_WIDTH}
        title={determineModalTitle()}
        style={determineDefaultModalStyle(isDarkMode)}
      >
        <SaveAndCancelButton
          onClose={onCloseModal}
          onConfirm={onConfirmDelete}
          confirmButtonText={"Delete"}
          autoFocusConfirmButton={true}
        />
      </EventModalPopup>
    </div>
  );
}
