// @ts-nocheck
// TODO: Enable type checking.
import React, { Component, createRef, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import {
  KEYCODE_ENTER,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ESCAPE,
  KEYCODE_K,
  KEYCODE_SEMI_COLON,
  initializeCommandCenterIndex,
  isCommandKeyPressed,
  chronoParseHasKnownValues,
  hasEventPreventDefault,
  isBeforeMinute,
  isOnboardingMode,
  parseChronoDMY,
  parseDateChronoDMY,
  hasStopEventPropagation,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import * as chrono from "chrono-node";
import Broadcast from "../broadcasts/broadcast";
import {
  EVENT_TEMPLATE,
  TEXT_TEMPLATE,
  MOMENT_DMY_DATE_FORMAT,
} from "../services/googleCalendarService";
import ColoredLine from "./line";
import {
  MEDIUM_GRAY,
  LIGHTGRAY,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import { getListOfEmailsFromString } from "../lib/eventFunctions";
import {
  format,
  setDay,
  addWeeks,
  addYears,
  subYears,
  setHours,
  setMinutes,
  subWeeks,
} from "date-fns";
import classNames from "classnames";
import { useIsMouseMoving } from "../services/stores/appFunctionality";
import { capitalizeFirstLetter, getInputStringFromEvent, isValidEmail, lowerCaseAndTrimStringWithGuard, truncateString } from "../lib/stringFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import FilledShortcutTiles from "./shortcutTiles/filledShortcutTiles";

class CommandCenter extends Component {
  constructor(props) {
    super(props);

    const refs = props.options.reduce((result, option) => {
      result[option.key] = createRef();
      return result;
    }, {});

    this.state = {
      hoverIndex: 0,
      keyMap: {},
      inputText: props.defaultText ?? "",
      searchResults: [],
      refs,
      addtionalRefs: {},
      currentDate: new Date(),
      isOnboardingMode: isOnboardingMode(),
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleNextSelectedOption = this.handleNextSelectedOption.bind(this);
    this.handlePreviousSelectedOption =
      this.handlePreviousSelectedOption.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.jumpToDate = this.jumpToDate.bind(this);
    this.createStringWithNext = this.createStringWithNext.bind(this);
    this.createNewContact = this.createNewContact.bind(this);
    this.createEventTemplate = this.createEventTemplate.bind(this);
    this.createTextTemplate = this.createTextTemplate.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener("keyup", this.handleKeyUp, false);

    this.props.setIsCommandCenterOn(true);

    if (this.props.defaultText) {
      this.handleSearchWithDefaultText();
    }

    this.props.onChangeInput && this.props.onChangeInput(this.state.inputText);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.options !== this.props.options) {
      let refs = this.props.options.reduce((result, option) => {
        result[option.key] = createRef();

        return result;
      }, {});

      refs = { ...refs, ...this.state.addtionalRefs };

      if (this.props.updateSearchResultStateOnChange) {
        let newSearchResults = this.props.options;

        if (this.props.options?.length === 0) {
          let inputText = this.state.inputText;

          if (this.props.isContactCommandCenter) {
            newSearchResults = [
              {
                key: "createNewContact",
                title: `Show: ${truncateString(inputText, 60)}`,
                onClickHandler: this.createNewContact,
              },
            ];

            this.createRefForResults(newSearchResults);
          }
        }

        this.setState({
          refs,
          searchResults: newSearchResults,
        });
      } else {
        this.setState({ refs });
      }
    }
  }

  componentWillUnmount() {
    this.props.setIsCommandCenterOn(false);

    document.removeEventListener("mousedown", this.handleOutsideClick, false);
    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener("keyup", this.handleKeyUp, false);
  }

  render() {
    const options = this.getOptions();

    return (
      <div
        className={classNames(
          "command-center-wrapper",
          this.determineContainerClassName()
        )}
      >
        <div
          ref={(node) => (this.commandCenter = node)}
          className={classNames(
            "command-center-text-wrapper",
            "default-bottom-left-modal-border",
            this.state.isOnboardingMode
              ? "command-center-text-wrapper-onboard"
              : "medium-blur"
          )}
          style={{ top: this.props.isMac ? "" : "25%" }}
        >
          <div className="command-center-title">{this.props.title}</div>

          <div className="command-center-result-wrapper">
            <div className="command-center-result-top">
              <div className="command-center-padding-box-shadow">
                <div className="position-relative">
                  <input
                    className="command-center-input"
                    type="text"
                    placeholder={this.props.placeholder || ""}
                    value={this.state.inputText}
                    onChange={this.handleSearchTextChange}
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>

          {this.shouldRenderLine(options) && (
            <div className="display-flex-justify-content-center">
              <ColoredLine
                style={{
                  width: "90%",
                  backgroundColor: this.props.isDarkMode
                    ? "#73757B"
                    : LIGHTGRAY,
                }}
              />
            </div>
          )}

          <div 
            style={{ 
              maxHeight: "270px", 
              overflowY: "auto", 
              borderBottomLeftRadius: "14px", 
              borderBottomRightRadius: "14px"
            }}
          >
            {this.renderOptions(options)}
          </div>
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderOptions(options) {
    if (!options || options.length === 0) {
      return null;
    }

    return options.map((option, index) => {
      return (
        <div
          className={
            this.isSelectedOption(index, option.isDefault)
              ? "command-center-is-selected"
              : "command-center-is-not-selected"
          }
          ref={this.state.refs[option.key]}
          key={`commandCenterOption${index}`}
          onMouseEnter={() => this.setHoverIndex(index)}
          onClick={(e) => this.determineOnClickAction(e, option)}
          style={
            option.isDefault ? { cursor: "default", color: MEDIUM_GRAY } : {}
          }
        >
          <div>{truncateString(option.title, 90)}</div>

          {this.state.isOnboardingMode ? null : (
            <span className="flex items-center">
              <FilledShortcutTiles
                shortcut={option.shortcut}
                doNotSplit={option.doNotSplitShortcut}
                isCommandCenter
              />
            </span>
          )}
        </div>
      );
    });
  }

  //================
  // EVENT HANDLERS
  //================

  handleSearchWithDefaultText() {
    const searchResults = this.getSearchResults(this.props.defaultText);
    const newState = {
      searchResults: searchResults || [],
    };

    if (searchResults?.length <= this.state.hoverIndex) {
      newState["hoverIndex"] = 0;
    }

    this.setState(newState);
  }

  handleSearchTextChange(e) {
    const inputText = getInputStringFromEvent(e);
    const searchResults = this.getSearchResults(inputText);

    this.props.onChangeInput && this.props.onChangeInput(inputText);

    const newState = {
      inputText,
      searchResults: searchResults || [],
    };

    newState["hoverIndex"] = 0;

    this.setState(newState, () => {
      this.scrollToTop();
    });
  }

  onClickOption(option) {
    if (this.props.disableSelectOption) {
      // Do nothing
    } else if (this.props.globalHandler && option.handlerValue) {
      this.props.globalHandler(option.handlerValue);
    } else if (option.onClickHandler) {
      option.onClickHandler();
    }

    this.props.handleCloseModal();
  }

  handleKeyUp(e) {
    this.updateKeyMap(e);
  }

  shouldRenderLine(options) {
    return (
      options && options.length === 1 && options[0] && options[0].isDefault
    );
  }

  determineOnClickAction(e, option) {
    hasEventPreventDefault(e);
    hasStopEventPropagation(e);
    if (option.isDefault) {
      // Do nothing
      return;
    }

    this.onClickOption(option);
  }

  updateKeyMap(e) {
    const keyMap = _.clone(this.state.keyMap);
    keyMap[e.keyCode] = e.type === "keydown";
    this.setState({ keyMap });
  }

  // Test on other browsers and Windows
  handleKeyDown(e) {
    this.updateKeyMap(e);

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_K]
    ) {
      this.props.handleCloseModal();
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_SEMI_COLON]
    ) {
      this.props.handleCloseModal();
    }

    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        this.props.handleCloseModal();

        return;
      case KEYCODE_UP_ARROW:
        this.handlePreviousSelectedOption(e);

        break;
      case KEYCODE_DOWN_ARROW:
        this.handleNextSelectedOption(e);

        break;
      case KEYCODE_ENTER:
        const availableOptions = this.getOptions();
        const hoverIndex = this.state.hoverIndex ?? 0

        // checks if hover index is greater than the available options
        const currentHoverIndex = hoverIndex > availableOptions.length - 1 || hoverIndex < 0
          ? 0
          : hoverIndex;
        hasEventPreventDefault(e);

        if (this.props.disableSelectOption) {
          this.props.handleCloseModal();
        } else if (availableOptions[currentHoverIndex]) {
          if (
            this.props.globalHandler &&
            availableOptions[currentHoverIndex].handlerValue
          ) {
            this.props.globalHandler(
              availableOptions[currentHoverIndex].handlerValue
            );
          } else if (availableOptions[currentHoverIndex].onClickHandler) {
            availableOptions[currentHoverIndex].onClickHandler();
          }

          !!document.getElementById("layout") && this.props.handleCloseModal();
        }

        break;
      default:
        break;
    }
  }

  jumpToDate(date) {
    if (!isValidJSDate(date)) {
      return;
    }

    this.props.setSelectedDay(date);
    Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", date);
  }

  handleNextSelectedOption(e) {
    // Prevent default behavior of moving cursor to the end of input text
    hasEventPreventDefault(e);

    let newIndex = (this.state.hoverIndex + 1) % this.getOptions().length;

    this.setState({
      hoverIndex: newIndex,
    });

    this.scrollToOption(newIndex);
  }

  handlePreviousSelectedOption(e) {
    // Prevent default behavior of moving cursor to the beginning of input text
    hasEventPreventDefault(e);

    let newIndex;

    if (this.state.hoverIndex === 0) {
      newIndex = this.getOptions().length - 1;
    } else {
      newIndex = this.state.hoverIndex - 1;
    }

    this.setState({
      hoverIndex: newIndex,
    });

    this.scrollToOption(newIndex);
  }

  //=================
  // PRIVATE METHODS
  //=================

  getSearchResults(inputText) {
    const index = initializeCommandCenterIndex(this.props.options);

    let result;

    if (this.props.updateSearchResultStateOnChange) {
      result = this.props.options;
    } else {
      result = index.search(inputText);
    }

    if (this.props.isContactCommandCenter && result?.length === 0) {
      result = result.concat([
        {
          key: "createNewContact",
          title: `Show email: ${inputText}`,
          onClickHandler: this.createNewContact,
        },
      ]);

      this.createRefForResults(result);

      return result;
    } else if (this.props.showResultFromDefaultText) {
      const chronoParse = this.customChronoParse(inputText);
      const chronoParseResult = this.createDatesFromInput(chronoParse, inputText);

      // Create event
      // result = result.concat([{
      //   key: 'emptySearchString',
      //   title: `Create event: ${inputText}`,
      //   onClickHandler: () => this.createEventThroughGoogle(inputText),
      // }]);

      if (chronoParseResult) {
        const resultDate = chronoParseResult.map((c) => {
          return {
            key: `goToDate_${c.toISOString()}`,
            title: `Jump to ${format(c, "MMMM do, yyyy")}`,
            onClickHandler: () => this.jumpToDate(c),
            searchQueries: `goToDate_${format(c, "MMMM do, yyyy")}`,
          };
        });

        result = result.concat(resultDate);
      }

      if (
        this.props.usePlaceHolderAsDefaultText &&
        result &&
        result.length === 0
      ) {
        result = result.concat([
          {
            key: "defaultOption",
            title: this.props.usePlaceHolderAsDefaultText,
            isDefault: true,
            onClickHandler: () => { },
          },
        ]);
      }

      this.createRefForResults(result);

      return result;
    } else if (
      this.props.usePlaceHolderAsDefaultText &&
      result &&
      result.length === 0
    ) {
      result = result.concat([
        {
          key: "defaultOption",
          title: this.props.usePlaceHolderAsDefaultText,
          isDefault: true,
          onClickHandler: () => { },
        },
      ]);

      this.createRefForResults(result);

      return result;
    } else if (
      inputText?.length > 0 &&
      this.props.isTemplateCommandCenter &&
      result &&
      result.length === 0 &&
      !this.props.skipCreateTemplate
    ) {
      result = result.concat([
        {
          key: "createNewEventTemplate",
          title: `Create event template: ${capitalizeFirstLetter(inputText)}`,
          onClickHandler: () => this.createEventTemplate(inputText),
        },
        {
          key: "createNewTextTemplate",
          title: `Create a Sticky: ${capitalizeFirstLetter(inputText)}`,
          onClickHandler: () => this.createTextTemplate(inputText),
        },
      ]);

      this.createRefForResults(result);

      return result;
    } else {
      return result;
    }
  }

  // create new contact from search
  createNewContact() {
    let emailList = getListOfEmailsFromString(this.state.inputText, [
      this.props.currentUser.email,
    ]);
    if (emailList && emailList.length > 1) {
      this.props.globalHandler &&
        this.props.globalHandler({ email: emailList });
      return;
    }

    if (
      !this.state.inputText ||
      this.state.inputText.length === 0 ||
      !isValidEmail(this.state.inputText)
    ) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `${this.state.inputText} is not a valid email`
      );
      return;
    }

    const contact = {
      contact: {
        email: this.state.inputText,
        fullName: "",
        name: "",
        updated: "",
      },
      email: this.state.inputText,
      isNewContact: true,
    };

    this.props.globalHandler && this.props.globalHandler(contact);
  }

  chronoHasTime(chronoParse) {
    if (
      chronoParse.length > 0 &&
      chronoParse[0] &&
      chronoParse[0].start &&
      chronoParse[0].start.knownValues &&
      (chronoParse[0].start.knownValues.hours ||
        chronoParse[0].start.knownValues.minute ||
        chronoParse[0].start.knownValues.meridiem)
    ) {
      return true;
    }

    return false;
  }

  chronoHasWeekDate(text) {
    const chronoParse = this.customChronoParse(text);

    return (
      chronoParse.length > 0 &&
      chronoParse[0] &&
      chronoParse[0].start &&
      chronoParse[0].start.knownValues &&
      chronoParse[0].start.knownValues.weekday >= 0
    );
  }

  customChronoParse(text) {
    // if it's DYM -> we use a different chrono locale to parse it, otherwise we always use the default en locale
    if (this.props.dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
      const result = parseChronoDMY(text, new Date(), false);

      // If chrono with DMY does not pick anything up, we try it with the english locale
      if (!result || result.length === 0) {
        return chrono.en.parse(text);
      } else {
        return result;
      }
    } else {
      return chrono.en.parse(text);
    }
  }

  customChronoParseDate(text, currentDate = this.state.currentDate) {
    if (this.props.dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
      const result = parseDateChronoDMY(text, currentDate);

      if (!result) {
        return chrono.en.parseDate(text, currentDate, { forwardDate: true });
      } else {
        return result;
      }
    } else {
      return chrono.en.parseDate(text, currentDate, { forwardDate: true });
    }
  }

  createRefForResults(resultArray) {
    let copyRefs = _.clone(this.state.refs);
    let addtionalRefs = {};

    resultArray.forEach((d) => {
      // make sure to create a new ref for every element and not set it outside forEach loop
      let createdRef = createRef();

      copyRefs[d.key] = createdRef;
      addtionalRefs[d.key] = createdRef;
    });

    this.setState({
      refs: copyRefs,
      addtionalRefs,
    });
  }

  createResultForExactDate(inputText) {
    return [this.customChronoParseDate(inputText)];
  }

  shouldAddNextToWeekDayString(word, input, chronoParse) {
    if (this.chronoHasWeekDate(word)) {
      let dayOfWeek = setDay(new Date(), word);

      if (Object.keys(chronoParse[0].start.knownValues).includes("hour")) {
        dayOfWeek = setHours(dayOfWeek, chronoParse[0].start.knownValues.hour);
      }

      if (Object.keys(chronoParse[0].start.knownValues).includes("minute")) {
        dayOfWeek = setMinutes(
          dayOfWeek,
          chronoParse[0].start.knownValues.minute
        );
      }

      return isBeforeMinute(dayOfWeek, new Date());
    } else {
      return false;
    }
  }

  createStringWithNext(accumulator, currentValue, input, chronoParse) {
    let updatedValue = this.shouldAddNextToWeekDayString(
      currentValue,
      input,
      chronoParse
    )
      ? `next ${currentValue}`
      : currentValue;

    return accumulator + " " + updatedValue;
  }

  addNextToText(inputArray, input, chronoParse) {
    let updatedArray = _.clone(inputArray);

    if (
      this.shouldAddNextToWeekDayString(updatedArray[0], input, chronoParse)
    ) {
      updatedArray[0] = `next ${updatedArray[0]}`;
    }

    return updatedArray.reduce((accumulator, currentValue) =>
      this.createStringWithNext(accumulator, currentValue, input, chronoParse)
    );
  }

  inputContainsDayOfWeekButNotDate(chronoParse) {
    let hasKnownValues = chronoParseHasKnownValues(chronoParse);

    if (!hasKnownValues) {
      return false;
    }

    let chronoStart = _.clone(chronoParse[0].start.knownValues);

    let chronoStartKeys = Object.keys(chronoStart);

    let chronoStartWeekDay = chronoStartKeys.includes("weekday");
    let chronoStartDate = chronoStartKeys.some(
      (r) => ["day", "month", "year"].indexOf(r) >= 0
    );

    return chronoStartWeekDay && !chronoStartDate;
  }

  createResultForYearAndMonth(chronoParse, inputText) {
    let day0;
    let day1;
    let day2;

    if (chronoParse[0].start.knownValues.weekday >= 0) {
      let dayOfWeek = chronoParse[0].start.knownValues.weekday;
      let jsDate = setDay(this.customChronoParseDate(inputText), dayOfWeek);
      day0 = jsDate;
      day1 = addWeeks(jsDate, 1);
      day2 = addWeeks(jsDate, 2);

      return [day0, day1, day2];
    } else {
      let jsDate = this.customChronoParseDate(inputText);
      day0 = jsDate;
      day1 = addWeeks(jsDate, 1);
      day2 = addWeeks(jsDate, 2);
      return [day0, day1, day2];
    }
  }

  createResultForMonthAndDay(chronoParse, inputText) {
    let day0;
    let day1;
    let day2;
    let year1;
    let year2;

    if (chronoParse[0].start.knownValues.weekday >= 0) {
      let dayOfWeek = chronoParse[0].start.knownValues.weekday;
      let jsDate = setDay(this.customChronoParseDate(inputText), dayOfWeek);
      day0 = jsDate;
      day1 = addWeeks(jsDate, 1);
      day2 = addWeeks(jsDate, 2);

      return [day0, day1, day2];
    } else {
      let jsDate = this.customChronoParseDate(inputText);
      day0 = jsDate;
      year1 = subYears(jsDate, 1);
      year2 = addYears(jsDate, 1);

      return [day0, year1, year2];
    }
  }

  createResultForMonth(chronoParse, inputText) {
    let day0;
    let year1;
    let year2;

    if (chronoParse[0].start.knownValues.weekday >= 0) {
      const dayOfWeek = chronoParse[0].start.knownValues.weekday;
      const jsDate = setDay(this.customChronoParseDate(inputText), dayOfWeek);
      day0 = jsDate;
      year1 = subYears(jsDate, 1);
      year2 = addYears(jsDate, 1);
    } else {
      const jsDate = this.customChronoParseDate(inputText);
      day0 = jsDate;
      year1 = subYears(jsDate, 1);
      year2 = addYears(jsDate, 1);
    }

    return [day0, year1, year2];
  }

  createResultForNoKnownValue(chronoParse, inputText) {
    let day0;
    let day1;
    let day2;

    if (chronoParse[0]?.start?.knownValues?.weekday >= 0) {
      const dayOfWeek = chronoParse[0].start.knownValues.weekday;
      const jsDate = setDay(this.customChronoParseDate(inputText), dayOfWeek);
      if (lowerCaseAndTrimStringWithGuard(inputText).includes("last")) {
        day0 = subWeeks(jsDate, 1);
        day1 = jsDate;
        day2 = addWeeks(jsDate, 1);
      } else {
        day0 = jsDate;
        day1 = addWeeks(jsDate, 1);
        day2 = addWeeks(jsDate, 2);
      }
      return [day0, day1, day2];
    } else {
      const jsDate = this.customChronoParseDate(inputText);
      day0 = jsDate;
      day1 = addWeeks(jsDate, 1);
      day2 = addWeeks(jsDate, 2);

      return [day0, day1, day2];
    }
  }

  createDatesFromInput(chronoParse, inputText) {
    if (isEmptyArrayOrFalsey(chronoParse)) {
      return null;
    }
    if (
      chronoParse[0]?.start?.knownValues?.month &&
      chronoParse[0]?.start?.knownValues?.day &&
      chronoParse[0]?.start?.knownValues?.year
    ) {
      return this.createResultForExactDate(inputText);
    } else if (
      chronoParse[0]?.start?.knownValues?.year &&
      chronoParse[0]?.start?.knownValues?.month &&
      !chronoParse[0]?.start?.knownValues?.day
    ) {
      return this.createResultForYearAndMonth(chronoParse, inputText);
    } else if (
      chronoParse[0]?.start?.knownValues?.month &&
      !chronoParse[0]?.start?.knownValues?.day
    ) {
      return this.createResultForMonth(chronoParse, inputText);
    } else if (
      chronoParse[0]?.start?.knownValues?.month &&
      chronoParse[0]?.start?.knownValues?.day
    ) {
      return this.createResultForMonthAndDay(chronoParse, inputText);
    } else if (
      chronoParse[0]?.start?.knownValues &&
      !chronoParse[0]?.start?.knownValues?.month &&
      !chronoParse[0]?.start?.knownValues?.day &&
      !chronoParse[0]?.start?.knownValues?.year
    ) {
      return this.createResultForNoKnownValue(chronoParse, inputText);
    } else {
      return [this.customChronoParseDate(inputText)];
    }
  }

  getRef(newIndex) {
    const hoveredOption = this.getHoveredOption(newIndex);
    return hoveredOption && hoveredOption.key
      ? this.state.refs[hoveredOption.key]
      : null;
  }

  getHoveredOption(newIndex) {
    const options = this.getOptions();
    return options ? options[newIndex] : {};
  }

  scrollToTop() {
    const ref = this.getRef(0);
    const param = {
      behavior: "auto",
      block: "nearest",
    };
    ref?.current?.scrollIntoView(param);
  }

  scrollToOption(newIndex) {
    if (!newIndex) {
      return;
    }
    if (newIndex) {
      const ref = this.getRef(newIndex);

      const param = {
        behavior: "auto",
        block: "nearest",
      };

      ref?.current?.scrollIntoView(param);
    }
  }

  setHoverIndex(index) {
    if (this.isSelectedOption(index) || !this.props.isMouseMovingRef.current.isMouseMoving) {
      return;
    }

    this.setState({ hoverIndex: index });
  }

  handleOutsideClick(e) {
    if (this.commandCenter.contains(e.target) || this.state.isOnboardingMode) {
      return;
    }

    hasStopEventPropagation(e);
    this.props.handleCloseModal();
  }

  //=================
  // PRIVATE METHODS
  //=================

  getOptions() {
    if (this.state.inputText === "") {
      return this.props.options;
    } else {
      return this.state.searchResults;
    }
  }

  determineContainerClassName() {
    if (this.props.additionalClassName) {
      return this.props.additionalClassName;
    } else if (this.state.isOnboardingMode) {
      return "command-center-wrapper-onboard";
    } else {
      return "";
    }
  }

  isSelectedOption(index, isDefault = false) {
    return index === this.state.hoverIndex && !isDefault;
  }

  createEventTemplate(inputText) {
    let title = capitalizeFirstLetter(inputText);

    Broadcast.publish("UPDATE_TEMPLATE", EVENT_TEMPLATE, null, null, title);
  }

  createTextTemplate(inputText) {
    const title = capitalizeFirstLetter(inputText);
    Broadcast.publish("UPDATE_TEMPLATE", TEXT_TEMPLATE, null, null, title);
  }
}

function mapStateToProps(state) {
  const {
    currentUser,
    isMac,
    currentPreviewedEvent,
    currentTimeZone,
    activeCalendars,
    isDarkMode,
    dateFieldOrder,
  } = state;

  return {
    currentUser,
    isMac,
    currentPreviewedEvent,
    currentTimeZone,
    activeCalendars,
    isDarkMode,
    dateFieldOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
    setCurrentTemplate: (data) =>
      dispatch({ data: data, type: "SET_CURRENT_TEMPLATE" }),
    setIsCommandCenterOn: (data) =>
      dispatch({ data: data, type: "SET_IS_COMMAND_CENTER_ON" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  // warning with subscribeWithSelector
  // https://bestofreactjs.com/repo/pmndrs-zustand-react-awesome-react-hooks
  const isMouseMovingRef = useRef(useIsMouseMoving.getState().isMouseMoving);
  // transient update through zustand
  // https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(
    () =>
      useIsMouseMoving.subscribe(
        (isMouseMoving) => (isMouseMovingRef.current = isMouseMoving),
        (state) => state.isMouseMoving
      ),
    []
  );
  return <BaseComponent {...props} isMouseMovingRef={isMouseMovingRef} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(CommandCenter))) as React.FC<any>;
