import React from "react";
import { useSelector } from "react-redux";
import { determineDefaultModalStyle } from "../../lib/modalFunctions";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";
import { RED_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import CustomButtonV2 from "../buttons/customButtonV2";
import EventModalPopup from "../eventModalPopup";
import { TEAM_ROLES } from "./sharedFunctions";

interface ConfirmChangeRoleModalProps {
  closeModal: () => void
  confirm: () => void
  isModalOpen: boolean
  newRole: ValueOf<typeof TEAM_ROLES>
}

/**
 * When an admin changes their role down to member, ask them to confirm.
 */
export default function ConfirmChangeRoleModal({
  closeModal,
  confirm,
  isModalOpen,
  newRole,
}: ConfirmChangeRoleModalProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);

  return(
    <EventModalPopup
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      width={420}
      title={"Change your role?"}
      style={determineDefaultModalStyle(isDarkMode)}
    >
      <div className="default-font-size">
        Are you sure you want to change your role to {capitalizeFirstLetter(newRole)}?
      </div>
      <div className="mt-6 flex gap-2.5 justify-end">
        <CustomButtonV2
          buttonType={WHITE_BUTTON}
          label="Cancel"
          onClick={closeModal}
        />
        <CustomButtonV2
          buttonType={RED_BUTTON}
          label="Change"
          onClick={confirm}
        />
      </div>
    </EventModalPopup>
  );
}
