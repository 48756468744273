import {
  getEventEndAllDayDate,
  getEventEndDateTime,
  getEventEndTimeZone,
  getEventEndValue,
  getEventStartTimeZone,
  getEventStartValue,
  isAllDayEvent,
} from "../lib/eventFunctions";
import { getAppVersion, VERSION_1 } from "./versionFunctions";
import { format, parseISO, subDays } from "date-fns";
import {
  convertToTimeZone,
  DaysDifferenceJSDate,
  determineEventTitle,
	formatEndDateJsDate,
	createRBCEventEnd,
	convertDateIntoEpochUnixWeek,
	createUniqueEtag,
	GetConferenceURL,
  formatEventForReactBigCalendar,
} from "./commonUsefulFunctions";
import { getEventID } from "./eventResourceAccessors";
import { EXCLUDED_DOMAINS } from "./globalVariables";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { getUserEmail, isUserEmailPartOfAllLoggedInUsers } from "../lib/userFunctions";
import { getCalendarFromEmail, getCalendarUserEmail } from "../lib/calendarFunctions";
import { isUserDelegatedUser } from "./maestroFunctions";
import { getEmailDomain, lowerCaseAndTrimString } from "../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export function formatMeetWithEvents({
  event,
  currentTimeZone,
  calendarID,
  color = null,
  isPreviewOutlookEvent,
}) {
  // only pass back values in raw_json
  const currentVersion = getAppVersion();

  if (currentVersion === VERSION_1) {
    const formattedEvent = { raw_json: event };

    const jsStart = parseISO(getEventStartValue(formattedEvent));
    const jsEnd = parseISO(getEventEndValue(formattedEvent));
    const eventID = getEventID(formattedEvent);

    const isAllDay = isAllDayEvent(formattedEvent)
    const eventEnd = isAllDay
      ? formatEndDateJsDate(jsEnd)
      : convertToTimeZone(jsEnd, { timeZone: currentTimeZone })

    return {
      summaryUpdatedWithVisibility: determineEventTitle(formattedEvent),
      eventStart: isAllDay
        ? jsStart
        : convertToTimeZone(jsStart, { timeZone: currentTimeZone }),
      defaultStartTime: getEventStartValue(formattedEvent),
      defaultEndTime: getEventEndAllDayDate(formattedEvent)
        ? format(subDays(jsEnd, 1), "yyyy-MM-dd")
        : getEventEndDateTime(formattedEvent),
      startTimeZone: getEventStartTimeZone(formattedEvent),
      endTimeZone: getEventEndTimeZone(formattedEvent),
      eventEnd,
      rbcEventEnd: createRBCEventEnd(formattedEvent, currentTimeZone, jsStart, eventEnd),
      allDay: isAllDay,
      epochUnixWeek: convertDateIntoEpochUnixWeek(jsStart),
      epochUnixWeekEnd: convertDateIntoEpochUnixWeek(jsEnd),
      displayAsAllDay:
        isAllDay || DaysDifferenceJSDate(jsStart, jsEnd, currentTimeZone),
      calendarId: calendarID,
      conferenceUrl: GetConferenceURL(formattedEvent),
      user_calendar_id: calendarID,
      color: color,
      user_event_id: `${eventID}_${calendarID}`,
      raw_json: event,
      uniqueEtag: createUniqueEtag(formattedEvent, `${eventID}_${calendarID}`),
      gcal_event_id: eventID,
      temporaryCalendarId: calendarID,
      isMeetWithEvent: true,
      resourceId: calendarID,
      isPreviewOutlookEvent,
    };
  } else {
    const formattedEventWithoutColor = formatEventForReactBigCalendar({
      event,
      currentTimeZone,
      calendarID,
      isPreviewOutlookEvent,
    });
    const eventID = getEventID(formattedEventWithoutColor);

    return {
      ...formattedEventWithoutColor,
      color,
      user_event_id: `${eventID}_${calendarID}`,
      uniqueEtag: `${eventID}_${calendarID}_${getEventStartValue(event)}`,
      gcal_event_id: eventID,
      temporaryCalendarId: calendarID,
      isMeetWithEvent: true,
      event_color: color,
      resourceId: calendarID,
      user_email: calendarID,
    };
  }
}

export function getMatchingUserWithDomain({
  allLoggedInUsers,
  domain,
  currentUser,
}) {
  if (!domain || isEmptyArray(allLoggedInUsers)) {
    return null;
  }
  const loweredCaseDomain = lowerCaseAndTrimString(domain);
  if (EXCLUDED_DOMAINS.includes(domain)) {
    return null;
  }

  const currentUserDomain = lowerCaseAndTrimString(getEmailDomain(currentUser?.email));
  if (currentUserDomain === loweredCaseDomain) {
    return currentUser;
  }

  let matchUser;
  allLoggedInUsers.forEach((user) => {
    if (isEmptyObjectOrFalsey(user)) {
      return;
    }

    const userEmailDomain = lowerCaseAndTrimString(getEmailDomain(user.email));
    if (userEmailDomain === domain && !isUserDelegatedUser(user)) {
      matchUser = user;
    }
  });
  return matchUser;
}

// get the user email associated with the contact
export function getMeetWithUserEmail({
  allLoggedInUsers,
  contact,
  currentUser,
  allCalendars,
  masterAccount,
}) {
  if (isEmptyObjectOrFalsey(contact)) {
    return null;
  }

  const contactEmail = getObjectEmail(contact);
  const searchedEmailDomain = getEmailDomain(contactEmail);
  const matchingUser = getMatchingUserWithDomain({
    allLoggedInUsers,
    domain: searchedEmailDomain,
    currentUser,
  });
  if (getUserEmail(matchingUser)) {
    return getUserEmail(matchingUser);
  }
  const matchingCalendar = getCalendarFromEmail({
    email: contactEmail,
    allCalendars,
    masterAccount,
    allLoggedInUsers,
  });
  if (getCalendarUserEmail(matchingCalendar)) {
    return getCalendarUserEmail(matchingCalendar);
  }
  const contactUserEmail = contact.userEmail;
  if (isUserEmailPartOfAllLoggedInUsers({ allLoggedInUsers, userEmail: contactUserEmail })) {
    return contactUserEmail;
  }
  return null;
}
