import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";

export default function RichTextContainer({
  autoFocus,
  containerClassName,
  val,
  onSetValue,
  readOnly,
  placeHolder,
  modules,
  id,
}) {
  const [value, setValue] = useState("");
  const quillRef = useRef(null); // Create a Ref
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  }

  const onBlur = () => {
    setIsFocused(false);
  }

  useEffect(() => {
    if (autoFocus && quillRef?.current?.focus) {
      quillRef.current.focus();
    }
  }, []);

  const onChange = (val) => {
    if (onSetValue) {
      onSetValue(val);
    } else {
      setValue(val);
    }
  };

  return (
    <div className={classNames("width-100-percent", containerClassName ?? "", isFocused ? "react-quill-focused-container" : "")}>
      <ReactQuill 
        id={id}
        ref={quillRef} 
        value={val ?? value} 
        onChange={onChange} 
        readOnly={readOnly}
        placeholder={placeHolder ??""}
        modules={modules ?? undefined}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}
