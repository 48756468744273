import React from "react";
import { Navigate } from "rbc-fork-react-big-calendar";
import TimeGrid from "rbc-fork-react-big-calendar/lib/TimeGrid";
import { determineCalendarView } from "../lib/stateManagementFunctions";
import { subDays, addDays, startOfDay } from "date-fns";
import { isSameOrBeforeDay } from "../services/commonUsefulFunctions";
import { getRBCMinTime, getRBCMaxTime } from "../lib/rbcFunctions";

class MyWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minTime: getRBCMinTime(),
      maxTime: getRBCMaxTime(),
    };
  }

  render() {
    const { date, resources } = this.props;
    const range = MyWeek.range(date, this.props);

    // If there are resources, show resource view
    const shouldShowResourceView = resources?.length > 1;

    return (
      <TimeGrid
        {...this.props}
        range={range}
        max={this.state.maxTime}
        min={this.state.minTime}
        resources={shouldShowResourceView ? resources : null}
      />
    );
  }
}

MyWeek.navigate = (date, action, ...props) => {
  let selectedCalendarView = 7;

  if (props && props.length > 0 && props[0] && props[0].selectedCalendarView) {
    selectedCalendarView = determineCalendarView(props[0].selectedCalendarView);
  }

  switch (action) {
    case Navigate.PREVIOUS:
      return subDays(date, selectedCalendarView);

    case Navigate.NEXT:
      return addDays(date, selectedCalendarView);

    default:
      return date;
  }
};

MyWeek.range = (date, props) => {
  const start = startOfDay(date);
  let selectedCalendarView = 6;

  if (props?.selectedCalendarView) {
    selectedCalendarView =
      determineCalendarView(props.selectedCalendarView) - 1;
  }

  const end = startOfDay(addDays(start, selectedCalendarView));

  let current = start;
  let range = [];

  while (isSameOrBeforeDay(current, end)) {
    range = range.concat(current);
    current = addDays(current, 1);
  }

  return range;
};

MyWeek.title = (date) => {
  return `Week of ${date.toLocaleDateString()}`;
};

export default MyWeek;
