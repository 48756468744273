import React from "react";
import { ChevronRight, X } from "react-feather";
import Broadcast from "../../broadcasts/broadcast";

const TooltipBox = ({
  description,
  helpCenterSection,
  hideLearnMore = false,
  onClick,
  title,
}) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-header-with-x">
        <h3>{title}</h3>
        <X size={14} className="tooltip-x" onClick={onClick} />
      </div>

      <div className="tooltip-description">{description}</div>

      {!hideLearnMore || !!helpCenterSection ? (
        <div
          className="tooltip-learn-more"
          onClick={() =>
            Broadcast.publish("OPEN_HELP_CENTER", helpCenterSection)
          }
        >
          Learn More <ChevronRight size={12} />
        </div>
      ) : null}
    </div>
  );
};

export default TooltipBox;
