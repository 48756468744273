export interface CombinedTags extends Tag {
  isSmartTag?: boolean;
}

export type Tags = CombinedTags[];

export const DISPLAY_LOCATION_EVENT_FORM = "event-form";
export const DISPLAY_LOCATION_EXPANDED_EVENT = "expanded-event";
export const DISPLAY_LOCATION_POPUP_EVENT = "popup-event";
export const DISPLAY_LOCATION_AUDIT = "audit";
export type ValidDisplayLocations =
  | typeof DISPLAY_LOCATION_EVENT_FORM
  | typeof DISPLAY_LOCATION_EXPANDED_EVENT
  | typeof DISPLAY_LOCATION_POPUP_EVENT
  | typeof DISPLAY_LOCATION_AUDIT;

export type SelectorDisplayIndexType = {
  index?: number | null;
  displayLocation: ValidDisplayLocations | null;
};

/* We omit keys based on the file */
/* Different files require different keys, but share the same name */
export type TagsProps = {
  displayLocation: ValidDisplayLocations;
  filterValue: string;
  tags: Tags;
  selectorDisplayIndex: SelectorDisplayIndexType;
  setFilterValue: (value: string) => void;
  setTags: (value: Tags) => void;
  setSelectorDisplayIndex: (value: SelectorDisplayIndexType) => void;
  userEmail?: string;
};
