import React from "react";
import { PHOTOS } from "../../services/globalVariables";
import AILoadingAnimation from "../animation/aiLoadingAnimation";
import classNames from "classnames";
import LogoFlash from "../../focusMode/components/logoFlash";
import { useSelector } from "react-redux";
import { getUserToken } from "../../lib/userFunctions";

export default function LoadingFullScreen() {
  const currentUser = useSelector((state) => state.currentUser);
  const isLoggedIn = !!getUserToken(currentUser);
  return (
    <div
      style={{
        backgroundImage: `url(${PHOTOS.LOADING_SCREEN_PHOTO})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={classNames("focus-mode-background flex height-100vh max-z-index")}
    >
      {renderContent(isLoggedIn)}
    </div>
  );
}

function renderContent(isLoggedIn) {
  return (
    <div className="relative transparent-full-screen max-z-index">
      <div
        className={classNames(
          "medium-blur layout-over-lay text-white font-size-24",
          "flex flex-col justify-center items-center"
        )}
        style={{ backgroundColor: "rgba(17, 15, 31, 0.8)" }}
      >
        <div>
          <AILoadingAnimation />
        </div>
        <div className="text-white">
          {isLoggedIn
            ? "Please hang tight while we set up your calendar"
            : "Get ready to experience the fastest calendar"
          }
        </div>
      </div>

      <LogoFlash containerClassName="absolute bottom-4 left-12" />
    </div>
  );
}
