import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getMatchingUserAndTags } from "../../lib/tagsFunctions";
import { useAllLoggedInUsers, useMasterAccount } from "../../services/stores/SharedAccountData";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";

export default function TagsSuggestions({
  existingTags,
  setTags,
  searchString,
  containerClassName,
  userEmail,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const userTags = getMatchingUserAndTags({
    currentUser,
    allLoggedInUsers,
    userEmail,
    masterAccount,
  });
  const matchingTags = getMatchingTags({
    existingTags,
    userTags,
    searchString,
  });
  if (isEmptyArrayOrFalsey(matchingTags)) {
    return null;
  }

  const onAddTag = (tag) => {
    if (!existingTags) {
      setTags([tag]);
      return;
    }

    setTags(existingTags.concat([tag]));
  };

  return (
    <div
      className={classNames(
        containerClassName ?? "",
        "flex flex-wrap items-center relative",
        "grid-gap-6px"
      )}
      style={{ width: 310 }}
    >
      <div className="default-font-size default-font-weight secondary-text-color mr-2">
        Suggestions
      </div>
      {matchingTags.map((tag, index) => {
        return (
          <div
            className="flex items-center w-max duration-200 cursor-pointer tag-suggestion-container select-none"
            key={`${tag.name}_${index}`}
            onClick={() => onAddTag(tag)}
          >
            <div
              className="rounded-full h-2.5 w-2.5 mr-2"
              style={{ backgroundColor: tag.color }}
            ></div>

            <div className="default-font-size default-font-weight">
              {tag.name}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function getMatchingTags({ existingTags, userTags, searchString }) {
  if (isEmptyArrayOrFalsey(userTags) || !searchString || searchString.trim().length < 3) {
    return null;
  }

  const lowerCaseSearchText = searchString.trim().toLowerCase();
  const loweredTextArray = lowerCaseSearchText
    .split(" ")
    .map((word) => word.trim());
  const matchingTags = [];
  const existingTagsWithBackup = existingTags ?? [];
  const existingTagNamesLowered = existingTagsWithBackup.map(
    (tag) => tag?.name?.toLowerCase() ?? ""
  );

  // filter out tags that already exist
  const filteredTags = userTags.filter(
    (tag) =>
      tag?.name && !existingTagNamesLowered.includes(tag.name.toLowerCase())
  );
  filteredTags.forEach((tag) => {
    const loweredTagName = tag?.name?.toLowerCase().trim() ?? "";
    if (
      loweredTextArray.includes(loweredTagName) ||
      lowerCaseSearchText.includes(loweredTagName) || 
      loweredTagName.includes(lowerCaseSearchText)
    ) {
      matchingTags.push(tag);
    }
  });
  return matchingTags;
}
