import React, { useEffect, useRef, useState } from "react";
import ZoomSettings from "./zoomSettings";
import UpdatePhone from "./updatePhone";
import GoogleMeetModal from "./googleMeetModal";
import CustomConferencing from "./customConferencing";
import DefaultUserOutlookConferenceSettings from "./defaultUserOutlookConferenceSettings";
import { CONFERENCING_SETTINGS_ID } from "../../../../lib/vimcalVariables";
import {
  getAllUsers,
  SelectUser,
  SELECT_USER_TYPE,
} from "../../common/selectUser";
import {
  BACKEND_OUTLOOK_CONFERENCING,
  convertOutlookConferencingToHumanReadable,
  getPrimaryCalendarConferencing,
  isOutlookUser,
} from "../../../../lib/outlookFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
  useZoomSchedulers,
} from "../../../../services/stores/SharedAccountData";
import {
  BACKEND_CUSTOM_CONFERENCING,
  BACKEND_HANGOUT,
  BACKEND_NO_CONFERENCING,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_ZOOM,
} from "../../../../services/googleCalendarService";
import {
  getZoomPersonalLink,
  isUserLoggedIntoZoom,
  isValidCustomConferencing,
  isValidPhoneConferencing,
} from "../../../../lib/conferencing";
import { useSelector } from "react-redux";
import { isUserBeingScheduledFor } from "../../../../services/maestroFunctions";
import updateSettingsBroadcast from "../../../../broadcasts/updateSettingsBroadcast";
import { getSelectedUserIndex } from "../../settingFunctions";
import { getUserEmail } from "../../../../lib/userFunctions";
import CustomSelectV2, { SELECT_VARIANTS } from "../../../select/selectV2";
import { getCustomConferencingName, getDefaultPhoneOption, getDefaultUserConferencing } from "../../../../lib/settingsFunctions";
import { BLUE_BUTTON, SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../../../services/globalVariables";
import broadcast from "../../../../broadcasts/broadcast";
import { OUTLOOK_CONFERENCING } from "../../../../resources/outlookVariables";
import settingsBroadcast from "../../../../broadcasts/settingsBroadcast";
import { UPDATE_SETTINGS_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import CustomButtonV2 from "../../../buttons/customButtonV2";
import SettingsSubtitle from "../../common/settingsSubtitle";
import AccountLabel from "../../accountLabel";
import { POSITION } from "../../../../lib/styleFunctions";

const SELECT_USER_ID = "select-user-id";

function ConferencingSettingsModal({
  initialConferencing,
  initialSettingsUser,
}) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [allUsersInDropdown, setAllUsersInDropdown] = useState(() => getAllUsers({
    currentUser,
    allLoggedInUsers,
    masterAccount,
    selectUserType: SELECT_USER_TYPE.ALL_USERS,
    addExecutiveLabel: true,
  }));
  const [selectedUserIndex, setSelectedUserIndex] = useState(() =>
    getSelectedUserIndex(initialSettingsUser, allUsersInDropdown),
  );
  const allUsersInDropdownRef = useRef(allUsersInDropdown);
  const selectedUserIndexRef = useRef(selectedUserIndex);

  const selectedUser = allUsersInDropdown[selectedUserIndex]?.value;
  const [tempDefaultConferencingValue, setTempDefaultConferencingValue] = useState(null); // for when we're saying default conferencing
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const zoomSchedulers = useZoomSchedulers((state) => state.schedulers);

  useEffect(() => {
    const updatedAllUsersInDropdown = getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType: SELECT_USER_TYPE.ALL_USERS,
      addExecutiveLabel: true,
    });

    // find old selected user
    const oldSelectedUser = allUsersInDropdownRef.current[selectedUserIndexRef.current]?.value;
    const updatedIndex = getSelectedUserIndex(oldSelectedUser, updatedAllUsersInDropdown);
    setSelectedUserIndex(updatedIndex);
    setAllUsersInDropdown(updatedAllUsersInDropdown);
    allUsersInDropdownRef.current = updatedAllUsersInDropdown;
  }, [allLoggedInUsers]);

  useEffect(() => {
    settingsBroadcast.subscribe(UPDATE_SETTINGS_BROADCAST_VALUES.CLEAR_TEMPORARY_DEFAULT_CONFERENCING, () => {
      setTempDefaultConferencingValue(null);
    });
    const initialConferencingElement =
      document.getElementById(initialConferencing);
    if (initialConferencingElement) {
      initialConferencingElement.scrollIntoView({ behavior: "instant" });
    } else {
      scrollToTopOfPage();
    }
    return () => {
      settingsBroadcast.unsubscribe(UPDATE_SETTINGS_BROADCAST_VALUES.CLEAR_TEMPORARY_DEFAULT_CONFERENCING);
    };
  }, []);

  const scrollToTopOfPage = () => {
    const customElement = document.getElementById(SELECT_USER_ID);
    customElement?.scrollIntoView({ behavior: "instant" });
  };

  const shouldShowZoomSetAsDefault = () => {
    if (
      isUserBeingScheduledFor({
        user: selectedUser,
        schedulers: zoomSchedulers,
        masterAccount,
      })
    ) {
      return true;
    }
    return (
      getZoomPersonalLink({
        user: selectedUser,
        schedulers: zoomSchedulers,
        masterAccount,
      }) || isUserLoggedIntoZoom(selectedUser)
    );
  };

  const updatedSelectedUserIndexWithRef = (updatedIndex) => {
    // update ref and state
    selectedUserIndexRef.current = updatedIndex;
    setSelectedUserIndex(updatedIndex);
  };

  const getDefaultConferencingValue = () => {
    const defaultConferencing = getDefaultUserConferencing({ user: selectedUser });
    if (defaultConferencing === BACKEND_ZOOM) {
      return {value: BACKEND_ZOOM, label: "Zoom"};
    }
    if (defaultConferencing === BACKEND_PHONE || defaultConferencing === BACKEND_WHATS_APP) {
      return {value: BACKEND_PHONE, label: getDefaultPhoneOption({ user: selectedUser }) === BACKEND_PHONE ? "Phone" : "WhatsApp"};
    }
    if (defaultConferencing === BACKEND_CUSTOM_CONFERENCING) {
      return {value: BACKEND_CUSTOM_CONFERENCING, label: getCustomConferencingName({ user: selectedUser })};
    }
    if (defaultConferencing === BACKEND_NO_CONFERENCING) {
      return {value: BACKEND_NO_CONFERENCING, label: "No conferencing"};
    }
    if (isOutlookUser(selectedUser)) {
      const defaultUserClientConferencing = getPrimaryCalendarConferencing(
        allCalendars,
        getUserEmail(selectedUser),
      );
      if (defaultConferencing === BACKEND_OUTLOOK_CONFERENCING && OUTLOOK_CONFERENCING[defaultUserClientConferencing]) {
        return {value: defaultUserClientConferencing, label: defaultUserClientConferencing};
      }
      return {value: BACKEND_NO_CONFERENCING, label: "No conferencing"};
    } else {
      return {value: BACKEND_HANGOUT, label: "Google Meet"};
    }
  };

  const getOptions = () => {
    const options = [];
    if (shouldShowZoomSetAsDefault()) {
      options.push({
        value: BACKEND_ZOOM,
        label: "Zoom",
      });
    }
    if (isValidPhoneConferencing(selectedUser)) {
      options.push({
        value: BACKEND_PHONE,
        label: getDefaultPhoneOption({ user: selectedUser }) === BACKEND_PHONE ? "Phone" : "WhatsApp",
      });
    }
    if (isValidCustomConferencing(selectedUser)) {
      options.push({
        value: BACKEND_CUSTOM_CONFERENCING,
        label: getCustomConferencingName({ user: selectedUser }),
      });
    }
    if (isOutlookUser(selectedUser)) {
      const defaultUserClientConferencing = getPrimaryCalendarConferencing(
        allCalendars,
        getUserEmail(selectedUser),
      );
      if (defaultUserClientConferencing && OUTLOOK_CONFERENCING[defaultUserClientConferencing]) {
        options.push({
          value: defaultUserClientConferencing,
          label: defaultUserClientConferencing,
        });
      }
    } else {
      options.push({
        value: BACKEND_HANGOUT,
        label: "Google Meet",
      });
    }
    options.push({
      value: BACKEND_NO_CONFERENCING,
      label: "No conferencing",
    });
    return options;
  };

  return (
    <div className="flex flex-col overflow-y-auto pr-5">
      <AccountLabel position={POSITION.TOP} />
      <SelectUser
        setSelectedUserIndex={updatedSelectedUserIndexWithRef}
        id={SELECT_USER_ID}
        selectedUser={selectedUser}
        inputContainerClassName=""
        addExecutiveLabel={true}
        inputOptions={allUsersInDropdown}
      />

      <SettingsSubtitle>Default conferencing:</SettingsSubtitle>
      <div className="secondary-text-color default-font-size">When creating events or creating Slots, use this as the default conferencing.</div>
      <CustomSelectV2
        className="w-52 mt-2"
        value={tempDefaultConferencingValue || getDefaultConferencingValue()}
        options={getOptions()}
        onChange={(option) => {
          setTempDefaultConferencingValue(option);
        }}
        isSearchable={false}
        variant={SELECT_VARIANTS.OUTLINED}
      />
      <div className="display-flex-flex-direction-row-justify-content-flex-end mt-4">
        <CustomButtonV2
          buttonType={BLUE_BUTTON}
          onClick={() => {
            updateSettingsBroadcast.publish("UPDATE_DEFAULT_CONFERENCING", {
              defaultConferencing: tempDefaultConferencingValue.value,
              user: selectedUser,
            });
            broadcast.publish(
              SET_DISAPPEARING_NOTIFICATION_MESSAGE,
              "Updated default conferencing",
            );
          }}
          label="Save"
          disabled={!tempDefaultConferencingValue}
        />
      </div>

      <div key={getUserEmail(selectedUser)}>
        <SettingsSubtitle id={CONFERENCING_SETTINGS_ID.ZOOM}>Zoom:</SettingsSubtitle>
        <ZoomSettings inputUser={selectedUser} />

        {renderProviderConferencing({
          user: selectedUser,
          allCalendars,
        })}

        <SettingsSubtitle id={CONFERENCING_SETTINGS_ID.PHONE}>Phone</SettingsSubtitle>
        <UpdatePhone inputUser={selectedUser} />

        <SettingsSubtitle id={CONFERENCING_SETTINGS_ID.CUSTOM}>Custom</SettingsSubtitle>
        <CustomConferencing inputUser={selectedUser} />
      </div>
    </div>
  );
}

function renderProviderConferencing({
  user,
  allCalendars,
}) {
  if (isOutlookUser(user)) {
    const getConferencingName = () => {
      const primaryCalendarConferencing =
        convertOutlookConferencingToHumanReadable(
          getPrimaryCalendarConferencing(allCalendars, user.email),
        );
      return primaryCalendarConferencing ?? "Outlook";
    };
    return (
      <div className="mb-10">
        <SettingsSubtitle id={CONFERENCING_SETTINGS_ID.MICROSOFT}>{getConferencingName()}</SettingsSubtitle>
        <DefaultUserOutlookConferenceSettings inputUser={user} />
      </div>
    );
  } else {
    return (
      <div className="mb-10">
        <SettingsSubtitle id={CONFERENCING_SETTINGS_ID.GOOGLE}>Google Meet</SettingsSubtitle>
        <GoogleMeetModal inputUser={user} />
      </div>
    );
  }
}

export default ConferencingSettingsModal;
