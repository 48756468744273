import React, { Component } from "react";
import { connect } from "react-redux";
import ShortcutTile from "./shortcutTiles/shortcutTile";

class GlobalkeyMapTile extends Component {
  render() {
    return (
      !this.props.shouldHide && (
        <span
          style={{
            position: "relative",
            zIndex:
              this.props.style && this.props.style.zIndex
                ? this.props.style.zIndex
                : 999,
          }}
        >
          {this.renderTile()}
          {this.props.children}
        </span>
      )
    );
  }

  renderTile() {
    return (
      this.shouldShowHint() && (
        <div
          style={Object.assign(
            {
              position: "absolute",
              top: 0,
              cursor: "default",
            },
            this.props.style
          )}
        >
          <ShortcutTile
            backgroundColor={"#ED6F9F"}
            color={"white"}
            shortcut={this.props.shortcut}
            width={this.props.width}
          />
        </div>
      )
    );
  }

  shouldShowHint() {
    return !this.props.isMobileView && this.props.shouldShowGlobalKeyMap;
  }
}

function mapStateToProps(state) {
  let { shouldShowGlobalKeyMap, isMobileView } = state;

  return { shouldShowGlobalKeyMap, isMobileView };
}

export default connect(mapStateToProps)(GlobalkeyMapTile);
