import moment from "moment";
import { differenceInMinutes, format, isSameMinute } from "date-fns";
import {
  convertToTimeZone,
  handleError,
  removeDuplicatesFromArray,
} from "./commonUsefulFunctions";
import { safeGuardTimeZones } from "../lib/timeFunctions";
import { BACKEND_HANGOUT } from "../services/googleCalendarService";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import {
  EXAMPLE_BOOKING_LINK,
  EXAMPLE_UPDATED_SLOT,
} from "../components/scheduling/testFunctions";
import { determineBookingURL } from "../lib/availabilityFunctions";
import { getRandomInt } from "../lib/helper";
import {
  getAttendeeEmails,
  getEventEnd,
  getEventStart,
  getEventTitle,
  getEventUserCalendarID,
  getEventUserEmail,
} from "./eventResourceAccessors";
import {
  getCalendarProviderId,
  getCalendarIsPrimary,
  getCalendarUserCalendarID,
  getCalendarEmail,
} from "./calendarAccessors";
import { isVersionV2 } from "./versionFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getStartTimeUTC } from "../lib/eventFunctions";
import { getCalendarUserEmail, isCalendarSelected } from "../lib/calendarFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { isLocal } from "./devFunctions";
import { createUUID } from "./randomFunctions";
import { lowerCaseAndTrimStringWithGuard } from "../lib/stringFunctions";

export function findEarliestAndLastestEventTime(events, where) {
  if (!events) {
    console.log("no events");
    return;
  }

  console.log("findEarliestAndLastestEventTime_where", events.length, where);

  let eventsList = events.map((e) => e.start_time_utc);

  let maxTime = eventsList[0];
  let minTime = eventsList[0];
  eventsList.forEach((e) => {
    if (!maxTime) {
      maxTime = e;
    } else if (moment(e).isAfter(moment(maxTime), "minute")) {
      maxTime = e;
    }

    if (!minTime) {
      minTime = e;
    } else if (moment(e).isBefore(moment(minTime), "minute")) {
      minTime = e;
    }
  });

  console.log("minTime", minTime);
  console.log("maxTime", maxTime);
}

export function getMinMaxDateOfEvents(events = []) {
  // used for testing
  let maxEvent;
  let minEvent;
  let monthlyCount = { total: events.length };

  events.forEach((e) => {
    if (!maxEvent) {
      maxEvent = e;
    } else if (
      moment(e.eventStart).isAfter(moment(maxEvent.eventStart), "minute")
    ) {
      maxEvent = e;
    }

    if (!minEvent) {
      minEvent = e;
    } else if (
      moment(e.eventStart).isBefore(moment(minEvent.eventStart), "minute")
    ) {
      minEvent = e;
    }

    let startMonth = moment(e.eventStart).startOf("week").format("L");

    if (monthlyCount[startMonth]) {
      monthlyCount[startMonth] = monthlyCount[startMonth].concat(e);
    } else {
      monthlyCount[startMonth] = [e];
    }
  });

  return monthlyCount;

  if (maxEvent && minEvent) {
    return {
      numEvents: events.length,
      maxTime: maxEvent.eventStart,
      minTime: minEvent.eventStart,
    };
  } else {
    return { numEvents: events.length, maxTime: null, minTime: null };
  }
}

export function testJsDateEquality(dateA, dateB, where = "", original) {
  let isSame = isSameMinute(dateA, dateB);
  if (!isSame) {
    console.log({ dateA, dateB, isSame, where, original });
  } else {
    console.log("same", where);
  }

  return isSame;
}

export function getEventSummary(e) {
  return !e ? null : e.summaryUpdatedWithVisibility;
}

export function getResponseEventTitles(events, email) {
  let titles = [];

  events.forEach((e) => {
    titles = titles.concat(getEventTitle(e));
  });

  console.log("titles", email, titles);
}

export function testForEventExistence(listOfEvents, partialTitle, where = "") {
  let foundAny = false;
  listOfEvents.forEach((r) => {
    const eventTitle = getEventTitle(r);
    if (eventTitle?.includes(partialTitle)) {
      foundAny = true;
      console.log("r", where, r, r.summaryUpdatedWithVisibility);
    }
  });

  if (!foundAny) {
    console.log("none-found", partialTitle, where);
  }
}

export function printForEventExistence(eventList, title, where) {
  eventList.forEach((e) => {
    if (e.summaryUpdatedWithVisibility === title) {
      console.log(
        "event: ",
        where,
        e.resourceId,
        e.summaryUpdatedWithVisibility
      );
    }
  });
}

export function printForEventExistenceCount(eventList, title, where = "") {
  let totalEvents = [];
  eventList.forEach((e) => {
    if (e.summaryUpdatedWithVisibility === title) {
      totalEvents = totalEvents.concat(e);
    }
  });
  console.log(
    "totalEvents",
    where,
    totalEvents,
    totalEvents.length > 1 ? "WARNING!!!!" : ""
  );
}

export function printForMergedEvent(eventList, title, where = "") {
  let event;
  eventList.forEach((e) => {
    if (e.summaryUpdatedWithVisibility === title) {
      event = e;
    }
  });
  if (!event) {
    console.log("no match");
    return;
  } else if (!event.mergedEvents) {
    console.log("no merged event", event);
    return;
  }

  console.log(
    "mergedEventOrder",
    event.mergedEvents.map((e) => e.resourceId)
  );
}

export function getEventsFromEachCalendar(eventsList, where = "") {
  // return index of {user_calendar_id: [events]}
  let testIndex = {};
  eventsList.forEach((e) => {
    if (testIndex[getEventUserCalendarID(e)]) {
      testIndex[getEventUserCalendarID(e)] =
        testIndex[getEventUserCalendarID(e)].concat(e);
    } else {
      testIndex[getEventUserCalendarID(e)] = [e];
    }
  });

  // console.log("events-per-calendar:", where, testIndex);
  // Object.keys(testIndex).forEach((key) => {
  //   console.log("per day_", key, getNumberOfEventsPerDay(testIndex[key]));
  // })
  return testIndex;
}

export function printKnownValues(chronoParse, element = 0) {
  console.log("chronoParse", chronoParse);
  console.log("knownValues", chronoParse[element]?.start?.knownValues);
}

export function actionCalendarsExist(activeCalendars, where = "") {
  const id = ""; // enter in id to test
  console.log("activeCalendarIncludes", activeCalendars.includes(id), where);
}

export function printParticularEvent(eventList, title) {
  let matchedEvent;
  eventList.forEach((e) => {
    if (
      e.summaryUpdatedWithVisibility.toLowerCase().includes(title.toLowerCase())
    ) {
      matchedEvent = e;
    }
  });

  console.log("matchedEvent", matchedEvent);
}

export function testGMTTimeZones() {
  const testTimes = ["0", "00", "01", "1", "5", "10", "12"];

  let testStrings = [];
  testTimes.forEach((s) => {
    testStrings = testStrings.concat({ timeZone: `GMT-${s}:00` });
    testStrings = testStrings.concat({
      timeZone: `GMT+${s}:00`,
      timeZoneName: "short",
    });
  });

  testStrings.forEach((s) => {
    const newTimeZone = safeGuardTimeZones(s);
    console.log(
      s.timeZone,
      newTimeZone,
      convertToTimeZone(new Date(), newTimeZone),
      new Date().toLocaleString("en", newTimeZone).split(" ").pop()
    );
  });

  const defaultList = [
    "Etc/GMT",
    "Etc/GMT+0",
    "Etc/GMT+1",
    "Etc/GMT+10",
    "Etc/GMT+12",
    "Etc/GMT-0",
    "Etc/GMT-10",
    "Etc/GMT0",
  ];
  defaultList.forEach((d) => {
    const newTimeZone = safeGuardTimeZones({ timeZone: d });
    console.log(
      d,
      newTimeZone,
      convertToTimeZone(new Date(), newTimeZone),
      new Date().toLocaleString("en", newTimeZone).split(" ").pop()
    );
  });
}

export function testGroupVoteLinkEndPoints(currentUser) {
  // end points that we can test
  // doc: https://docs.google.com/document/d/1bqKiRwQQBdUCcR6-DMbVYwx1NId1QyI_oS1SV-40awM/edit
  const CREATE_BOOKING_LINK = "CREATE_BOOKING_LINK";
  const REMOVE_BOOKING_LINK = "REMOVE_BOOKING_LINK";
  const FETCH_GROUP_VOTE_LINK = "FETCH_GROUP_VOTE_LINK";
  const PULL_BOOKING_LINK_FROM_AVAILABILITY_PROJECT =
    "PULL_BOOKING_LINK_FROM_AVAILABILITY_PROJECT";
  const UPDATE_BOOKING_LINK = "UPDATE_BOOKING_LINK";
  const EMAIL_BOOKING_LINK = "EMAIL_BOOKING_LINK";

  const CURRENTLY_TESTING = REMOVE_BOOKING_LINK; //* you can switch each of the test cases above. They're all mutually exclusive

  const {
    title,
    description,
    conferencing,
    calendar_provider_id,
    google_calendar_id,
    location,
    token,
    selected_slots,
    time_zone,
    anonymous,
    attendees,
  } = EXAMPLE_BOOKING_LINK;

  if (CURRENTLY_TESTING === CREATE_BOOKING_LINK) {
    const path = `group_vote_links`;
    const url = constructRequestURL(path, isVersionV2());
    const payloadData = {
      body: JSON.stringify({
        group_vote_link: {
          title,
          location,
          description,
          conferencing,
          calendar_provider_id, // can ignore this for google_calendar_id
          time_zone,
          anonymous,
          selected_slots,
          token,
          attendees,
        },
      }),
    };

    Fetcher.post(url, payloadData, true, currentUser.email)
      .then((response) => {
        console.log("create response", response);
      })
      .catch(handleError);
  } else if (CURRENTLY_TESTING === REMOVE_BOOKING_LINK) {
    const path = `group_vote_links/${token}`;
    const url = constructRequestURL(path, isVersionV2());

    Fetcher.delete(url, {}, true, currentUser.email)
      .then((response) => {
        console.log("delete response", response);
      })
      .catch(handleError);
  } else if (CURRENTLY_TESTING === FETCH_GROUP_VOTE_LINK) {
    const path = `group_vote_links`;
    const url = constructRequestURL(path, isVersionV2());

    Fetcher.get(url, {}, true, currentUser.email)
      .then((response) => {
        console.log("get booking link response", response);
      })
      .catch(handleError);
  } else if (
    CURRENTLY_TESTING === PULL_BOOKING_LINK_FROM_AVAILABILITY_PROJECT
  ) {
    const path = `group_vote_links/${token}`;
    const url = constructRequestURL(path, isVersionV2());

    Fetcher.get(url)
      .then((response) => {
        console.log("availability project link response", response);
      })
      .catch(handleError);
  } else if (CURRENTLY_TESTING === UPDATE_BOOKING_LINK) {
    const path = `group_vote_links/${token}/response`;
    const url = constructRequestURL(path, isVersionV2());

    const UPDATE_VARIABLE = createUUID();
    const payloadData = {
      body: JSON.stringify({
        group_vote_link: {
          title: title + UPDATE_VARIABLE,
          location: location + UPDATE_VARIABLE,
          description: description + UPDATE_VARIABLE,
          conferencing: BACKEND_HANGOUT,
          calendar_provider_id: "mike@vimcal.com", // can ignore this for google_calendar_id
          anonymous: false,
          attendees: attendees.concat(EXAMPLE_UPDATED_SLOT),
        },
      }),
    };

    Fetcher.patch(url, payloadData, true, currentUser.email)
      .then((response) => {
        console.log("update booking link response", response);
      })
      .catch(handleError);
  } else if (CURRENTLY_TESTING === EMAIL_BOOKING_LINK) {
    let path = `email`; //TODO: path is not specified in the doc
    let url = constructRequestURL(path);

    const payloadData = {
      body: JSON.stringify({
        group_vote_link: {
          attendees: ["john@vimcal.com", "michael.zhao@vimcal.com"],
          message: "testing email",
          url: `${determineBookingURL()}/g/${token}`,
        },
      }),
    };

    Fetcher.get(url, payloadData, true, currentUser.email)
      .then((response) => {
        console.log("email booking link response", response);
      })
      .catch(handleError);
  } else {
    console.warn("not properly set up");
  }
}

export function createExampleEvents() {
  let exampleEvents = [];
  for (let i = 0; i < 500; i++) {
    const date = getRandomInt(14, 20);
    const hour = getRandomInt(1, 22);
    exampleEvents = exampleEvents.concat({
      id: exampleEvents.length,
      summaryUpdatedWithVisibility: `${i} random event`,
      eventStart: new Date(2021, 10, date, hour, getRandomInt(1, 59), 0),
      rbcEventEnd: new Date(2021, 10, date, hour + 1, getRandomInt(1, 59), 0),
      status: "test",
      raw_json: { status: "test" },
    });
  }
  return exampleEvents;
}

export function getTestCalendar(email, allCalendars) {
  return Object.values(allCalendars).find(
    (c) => getCalendarProviderId(c) === email && getCalendarIsPrimary(c)
  );
}

export function getEventsFromEventsListWithUserEventID(
  eventsList,
  userEventID
) {
  return eventsList.filter((e) => e.user_event_id === userEventID);
}

export function shouldPrintEvent(event) {
  return getEventTitle(event)?.toLowerCase().includes("test.com");
}

export function getAllEventUniqueUserEmails(events) {
  if (isEmptyArray(events)) {
    return null;
  }
  return removeDuplicatesFromArray(events.map((e) => getEventUserEmail(e)));
}

export function getMinMaxOfEvents(events) {
  const allDates = events.map((e) => getStartTimeUTC(e));
  const sorted = allDates.sort();
  return { min: sorted[0], max: sorted[sorted.length - 1] };
}

export function getEventsIndexByDate(events, label) {
  const index = {};
  events.forEach((e) => {
    const date = format(e.eventStart, "yyyy-MM-dd");
    if (index[date]) {
      index[date] = index[date].concat(e);
    } else {
      index[date] = [e];
    }
  });
  console.log(label ?? "index_", index);
}

export function getNumberOfEventsPerDay(events, label) {
  const index = {};
  events.forEach((e) => {
    const date = format(e.eventStart, "yyyy-MM-dd");
    if (index[date]) {
      index[date] = index[date] + 1;
    } else {
      index[date] = 1;
    }
  });
  // console.log(label, index);
  return index;
}

export function printSelectedEvent({ eventList, name }) {
  const matchingEvents = eventList.filter((e) => getEventTitle(e) === name);
  console.log("matchingEvents_", matchingEvents);
}

export function filterForSelectedCalendars(allCalendars) {
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return [];
  }
  return Object.values(allCalendars).filter((c) => isCalendarSelected(c));
}

export const TEST_PREVIEW_OUTLOOK_LOCATION = {
  CALENDAR_LIST: "CALENDAR_LIST",
  MAIN_CALENDAR: "MAIN_CALENDAR",
  GET_FULL_EVENT: "GET_FULL_EVENT",
  INITIAL_SYNC: "INITIAL_SYNC",
};
export function isTestingPreviewOutlook(location) {
  return false;
  if (isLocal()) {
    // depending on what we want to test, comment out the locations that we do not need
    return [
      TEST_PREVIEW_OUTLOOK_LOCATION.CALENDAR_LIST,
      TEST_PREVIEW_OUTLOOK_LOCATION.MAIN_CALENDAR,
      TEST_PREVIEW_OUTLOOK_LOCATION.GET_FULL_EVENT,
      TEST_PREVIEW_OUTLOOK_LOCATION.INITIAL_SYNC,
    ].includes(location);
  }
  return false;
}

export function getEventCountForTitle(events, title) {
  return events?.filter((e) => getEventTitle(e)?.includes(title)).length;
}

export function localConsoleError(...params) {
  if (!isLocal()) {
    return;
  }
  if (isEmptyArrayOrFalsey(params)) {
    return;
  }
  console.error(...params);
}

export function localConsoleLog(...params) {
  if (isEmptyArrayOrFalsey(params)) {
    return;
  }
  if (!isLocal()) {
    return;
  }
  console.log(...params);
}

export function isTestingOutlookConferenceRoom() {
  return isLocal();
}

export function testForMetricsTitleOrAttendeeEmail({events, matchStr}) {
  const formattedStr = lowerCaseAndTrimStringWithGuard(matchStr);
  const filtered = events.filter((e) => {
    const title = lowerCaseAndTrimStringWithGuard(getEventTitle(e));
    const attendeeEmailsStr = getAttendeeEmails(e).join("_");
    return title?.includes(formattedStr) || attendeeEmailsStr.includes(formattedStr);
  });
  return filtered;
}

export function getTotalDurationOfEvents(events) {
  try {
    return events.reduce((a, b) => {
      const differenceInMin = differenceInMinutes(getEventEnd(b), getEventStart(b));
      return a + differenceInMin;
    }, 0);
  } catch (e) {
    localConsoleError("error getting total duration of events", e);
    return 0;
  }
}

export function printCalendarInfoFromUserCalendarIDs({userCalendarIDs, allCalendars}) {
  Object.values(allCalendars).forEach((c) => {
    userCalendarIDs.forEach(userCalendarID => {
      if (userCalendarID === getCalendarUserCalendarID(c)) {
        console.log("matching_calendar: ", {email: getCalendarEmail(c), userEmail: getCalendarUserEmail(c), id: getCalendarUserCalendarID(c)});
      }
    });
  });
}
