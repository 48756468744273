import React, { useCallback, useMemo } from "react";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { getUserEmail } from "../../lib/userFunctions";
import { equalAfterTrimAndLowerCased } from "../../lib/stringFunctions";
import {
  GOOGLE_INCREMENTAL_AUTH_NAMES,
  onClickGoogleLogin,
} from "../../lib/googleFunctions";
import CustomButton from "../customButton";
import broadcast from "../../broadcasts/broadcast";
import { removeLocalEventFormState } from "../../services/sharedEventFormFunctions";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../services/globalVariables";
import {
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../../services/maestroFunctions";
import { MAESTRO_ACCOUNT_TYPES } from "../../services/globalMaestroVariables";
import GoogleDriveIncrementalAuth from "../icons/googleDriveIncrementalAuth";
import { BROADCAST_VALUES } from "../../lib/broadcastValues";

const MESSAGES = {
  [GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE]:
    "All files you upload to calendar events will be stored in your Google Drive. Vimcal does not store any of your attachments.",
  [GOOGLE_INCREMENTAL_AUTH_NAMES.GROUPS]:
    "After granting this permission, you will be able to send event invites to groups in your Google workspace and see the members of each group.",
} as const;

const TITLES = {
  [GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE]:
    "Grant Vimcal access to Google Drive",
  [GOOGLE_INCREMENTAL_AUTH_NAMES.GROUPS]:
    "Grant Vimcal access to Google Groups",
} as const;

type IncrementalPermissionsModalProps = {
  email?: string;
  messageType: ValueOf<typeof GOOGLE_INCREMENTAL_AUTH_NAMES>;
  showConsentScreen?: boolean;
};

export default function IncrementalPermissionsModal({
  email,
  messageType,
  showConsentScreen,
}: IncrementalPermissionsModalProps) {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const currentUserEmail = getUserEmail(currentUser);
  const messageEmail = email || currentUserEmail;
  const matchingUser = allLoggedInUsers.find((user) =>
    equalAfterTrimAndLowerCased(getUserEmail(user), messageEmail),
  );

  const accountType = useMemo(() => {
    if (!isUserMaestroUser(masterAccount)) {
      return undefined;
    }

    if (isUserDelegatedUser(matchingUser)) {
      return MAESTRO_ACCOUNT_TYPES.SCHEDULER_FOR_OTHERS;
    } else {
      return MAESTRO_ACCOUNT_TYPES.MYSELF;
    }
  }, [masterAccount, matchingUser]);

  const renderAuthImage = useCallback(() => {
    switch (messageType) {
      case GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE:
        return <GoogleDriveIncrementalAuth />;
    }

    return null;
  }, [messageType]);
  const hasImage = messageType === GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE;

  return (
    <div className={hasImage ? "mt-5" : ""}>
      {hasImage ? renderAuthImage() : null}
      <div className={hasImage ? "mt-4" : ""}>{TITLES[messageType]}</div>
      <div className="default-font-size secondary-text-color mt-2">
        {MESSAGES[messageType]}
      </div>
      <div className="display-flex justify-content-flex-end mt-8">
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={() => {
            broadcast.publish(BROADCAST_VALUES.CLOSE_LAYOUT_MODAL);

            /* For event form specifically, clear localData to prevent form from opening */
            if (messageType === GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE) {
              removeLocalEventFormState();
            }
          }}
          label="Cancel"
        />
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={() => {
            onClickGoogleLogin({
              hint: messageEmail,
              incrementalAuthName: messageType,
              showConsentScreen,
              accountType,
            });
          }}
          addPaddingToRight={false}
          label="Grant Access"
        />
      </div>
    </div>
  );
}
