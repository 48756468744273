import React from "react";
import ContactHandle, { type ContactHandleProps } from "../contactHandle";
import Link from "./link";

export default function LinkHandle(props: ContactHandleProps) {
  return (
    <ContactHandle {...props}>
      <Link size={11} className="full-attendee-list-attendee-handle pl-px" />
    </ContactHandle>
  )
}
