import React, { Component, createRef } from "react";
import CustomSelect from "./select";
import SaveButton from "../components/saveButton";
import GoogleCalendarService from "../services/googleCalendarService";
import { connect } from "react-redux";
import classNames from "classnames";
import DropdownIndicator from "./select/dropDownIndicator";
import DatePicker from "react-datepicker";
import {
  addMinutes,
  format,
  isValid,
  set,
  setMinutes,
  startOfHour,
  startOfMinute,
  subMinutes,
} from "date-fns";
import {
  chronoHasKnownTime,
  customChronoWordDetection,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ENTER,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  updateStringForTime,
} from "../services/commonUsefulFunctions";
import { getInputStringFromEvent } from "../lib/stringFunctions";
import { getDateTimeFormat } from "../lib/dateFunctions";
import { SAVE_BUTTON_HEIGHT } from "../services/globalVariables";
import { NumericInput } from "./numericInput";

// Strings from google
let { minutesString, hoursString, daysString, weeksString, popup, email } =
  GoogleCalendarService;

// Strings that are used in the UI
let notificationType = [
  { value: popup, label: "Notification" },
  { value: email, label: "Email" },
];

let notificationTimeType = [
  { value: minutesString, label: minutesString },
  { value: hoursString, label: hoursString },
  { value: daysString, label: daysString },
  { value: weeksString, label: weeksString },
];

class AddAdditionalReminders extends Component {
  constructor(props) {
    super(props);

    this.allDayTimeRef = createRef();

    let defaultOptions = {
      allDay: [1, { value: daysString, label: daysString }],
      notAllDay: [10, { value: "minutes", label: "minutes" }],
    };

    this.state = {
      notificationType: this.setInitialNotificationType(),
      number: this.setInitialNumber(defaultOptions),
      notificationTimeType: props.allDay
        ? defaultOptions.allDay[1]
        : defaultOptions.notAllDay[1],
      selectedOption: props.timeList[0],
      defaultOptions: defaultOptions,
      allDayTime: startOfHour(set(new Date(), { hours: 9 })),
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
  }

  componentDidMount() {
    let scrollTo = this.refs.highlightedItem;

    scrollTo && scrollTo.scrollIntoView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.number === 0 && prevState.number !== 0) {
      this.setState({
        allDayTime: startOfHour(set(new Date(), { hours: 0 })),
      });
    }
  }

  render() {
    return (
      <div className="event-form-add-additional-reminder-container">
        {this.props.allDay
          ? this.renderAllDayReminders()
          : this.renderDefaultReminders(notificationTimeType)}

        <SaveButton
          width="100%"
          height={SAVE_BUTTON_HEIGHT}
          buttonText="Add Notification"
          marginTop={this.props.allDay ? 0 : 100}
          onClick={this.onClickSave}
        />
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderAllDayReminders() {
    // Google's API does NOT support reminders that trigger after the event starts, even
    // though this can be done directly inside Google Calendar.
    const isSameDayReminder = this.state.number === 0;

    return (
      <div className="flex items-center mb-36">
        {this.renderDefaultReminders(
          notificationTimeType.slice(2, notificationTimeType.length)
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
            justifyContent: "space-around",
          }}
        >
          <div
            className="default-font-size ml-2.5 mr-2.5 flex justify-center items-center"
            style={{ height: 40 }}
          >
            {isSameDayReminder ? "when event starts" : "before at"}
          </div>

          {isSameDayReminder ? null : this.renderTimePicker()}
        </div>
      </div>
    );
  }

  renderTimePicker() {
    const { format24HourTime, isStartTime, additionalClassname } = this.props;
    return (
      <div className="all-day-reminder-dropdown">
        <DatePicker
          selected={this.state.allDayTime}
          onChange={(time, other) =>
            this.onSelectAllDayNotificationTime(time, other)
          }
          showTimeSelect
          id={"all-day-notification-picker"}
          // tabIndex={this.props.inputTabIndex}
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption={isStartTime ? "Start time" : "End time"}
          dateFormat={getDateTimeFormat(format24HourTime)}
          timeFormat={getDateTimeFormat(format24HourTime)}
          calendarClassName="select-time-input"
          className={classNames(
            `select-event-time-input ${additionalClassname}`,
            "select-event-time-normal",
            "theme-select-event-time-input"
          )}
          showPopperArrow={false}
          popperClassName="event-form-react-select-input-popper"
          onKeyDown={this.onKeyDown}
          ref={this.allDayTimeRef}
          autoComplete="off"
          onChangeRaw={(e) =>
            this.setState({ inputText: getInputStringFromEvent(e) })
          }
        />
      </div>
    );
  }

  renderDefaultReminders(notificationTimeTypeInput) {
    return (
      <div className="flex mt-5">
        <CustomSelect
          showBorder={true}
          components={{ DropdownIndicator }}
          className={classNames(
            "select-notification-type",
            this.props.isDarkMode ? "dark-mode-select" : "",
            "mr-2",
          )}
          classNamePrefix="dark-mode-modal"
          value={this.state.notificationType}
          options={notificationType}
          onChange={(option) => this.onChange("notificationType", option)}
        />

        <NumericInput
          value={this.state.number}
          onChange={this.onChangeNumber}
        />

        <CustomSelect
          showBorder={true}
          className={classNames(
            this.props.isDarkMode ? "dark-mode-select" : "",
            this.props.allDay ? "all-day-notification-time-type-selection" : "",
            "ml-2.5 w-24"
          )}
          value={this.state.notificationTimeType}
          classNamePrefix="dark-mode-modal"
          options={notificationTimeTypeInput}
          onChange={(option) => this.onChange("notificationTimeType", option)}
        />
      </div>
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  onSelectAllDayNotificationTime(time, origin) {
    // time comes in as jsDate
    if (!origin) {
      // Problem with library, onSelect also triggers when you type so need to detect if change came from typing or selecting.
      // if origin is not null -> typing.
      this.setState({ allDayTime: time });
    }
  }

  onClickSave() {
    this.props.setAdditionalReminder(this.createObjectOfAdditionalReminders());
  }

  setInitialNumber(defaultOptions) {
    if (this.props.defaultOption) {
      return this.props.defaultOption.minutes;
    } else {
      return this.props.allDay
        ? defaultOptions.allDay[0]
        : defaultOptions.notAllDay[0];
    }
  }

  setInitialNotificationType() {
    if (this.props.defaultOption) {
      if (this.props.defaultOption.method === popup) {
        return notificationType[0];
      } else {
        return notificationType[1];
      }
    } else {
      return notificationType[0];
    }
  }

  createObjectOfAdditionalReminders() {
    let type = this.state.notificationType.value;
    let notificationTimeMultiplier;

    switch (this.state.notificationTimeType.value) {
      case minutesString:
        notificationTimeMultiplier = 1;
        break;
      case hoursString:
        notificationTimeMultiplier = 60;
        break;
      case daysString:
        notificationTimeMultiplier = 1440;
        break;
      case weeksString:
        notificationTimeMultiplier = 10080;
        break;
      default:
        notificationTimeMultiplier = 1;
    }

    if (this.props.allDay) {
      return [
        type,
        this.state.number,
        this.state.notificationTimeType.value,
        format(this.state.allDayTime, "p"),
      ];
    } else {
      return {
        method: type,
        minutes: notificationTimeMultiplier * this.state.number,
      };
    }
  }

  onKeyDown(key) {
    if (key && [KEYCODE_TAB, KEYCODE_ENTER].includes(key.keyCode)) {
      this.saveInputContent();
      this.allDayTimeRef.current.setOpen(false);
    } else if ([KEYCODE_DOWN_ARROW, KEYCODE_UP_ARROW].includes(key.keyCode)) {
      let newlySelected;

      if (!this.state.allDayTime || !isValid(this.state.allDayTime)) {
        newlySelected = setMinutes(
          new Date(),
          Math.ceil(new Date().getMinutes() / 30) * 30
        );
      } else {
        newlySelected =
          key.keyCode === KEYCODE_DOWN_ARROW
            ? addMinutes(this.state.allDayTime, 30)
            : subMinutes(this.state.allDayTime, 30);
      }

      const newlyFocusedElement = document.getElementsByClassName(
        "react-datepicker__time-list-item--selected"
      );
      newlyFocusedElement &&
        newlyFocusedElement.length > 0 &&
        newlyFocusedElement[0].scrollIntoView({ block: "center" });

      this.setState({ allDayTime: newlySelected });
    }
  }

  isSelectedOption(option) {
    return option === this.state.selectedOption;
  }

  saveInputContent() {
    const { inputText } = this.state;

    let formattedInputString = inputText;
    const updatedTimeString = updateStringForTime(inputText);
    if (updatedTimeString) {
      formattedInputString = updatedTimeString;
    }

    if (!formattedInputString) {
      return;
    }

    const chronoGuess = customChronoWordDetection({
      text: formattedInputString,
    });
    const chronoGuessHasKnownTime = chronoHasKnownTime(chronoGuess);

    if (chronoGuessHasKnownTime) {
      // event time is valid
      const hours = chronoGuess[0].start.knownValues.hour;
      const minutes = chronoGuess[0].start.knownValues.minute;

      const updatedTime = startOfMinute(set(new Date(), { hours, minutes }));
      this.setState({ allDayTime: updatedTime });
    }
  }

  //================
  // EVENT HANDLERS
  //================

  onChangeNumber(number) {
    this.setState({
      number,
    });
  }

  onChange(key, input) {
    this.setState({
      [key]: input,
    });
  }
}

function mapStateToProps(state) {
  const { isDarkMode, format24HourTime } = state;

  return {
    isDarkMode,
    format24HourTime,
  };
}

export default connect(mapStateToProps, null)(AddAdditionalReminders);
