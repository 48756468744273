import React from "react";

import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { hasGroupSpreadsheetAccess } from "../../lib/featureFlagFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";

const POLL_VARIANT = "poll";
const SPREADSHEET_VARIANT = "spreadsheet";

export default function GroupVoteListEmptyState() {
  const currentUser = useSelector(state => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  return (
    <div className="w-full pl-3.5 pr-4">
      <CreateGroupVoteCard
        buttonLabel="Create new poll"
        header="Group poll"
        subheader="Poll individual respondents for their preferred time slots."
        onClick={() => availabilityBroadcast.publish("ADD_GROUP_VOTE_LINK")}
        image={<CreateGroupVoteImage variant={POLL_VARIANT} />}
      />
      {hasGroupSpreadsheetAccess({user: currentUser, masterAccount}) ? <CreateGroupVoteCard
        buttonLabel="Create new spreadsheet"
        header="Group spreadsheet"
        subheader="A live, collaborative spreadsheet where anyone with the link can edit any vote or comment."
        onClick={() => availabilityBroadcast.publish("ADD_GROUP_SPREADSHEET_LINK")}
        image={<CreateGroupVoteImage variant={SPREADSHEET_VARIANT} />}
      /> : null}
    </div>
  );
}

interface CreateGroupVoteCardProps {
  buttonLabel: string
  header: string
  subheader: string
  image: React.ReactNode
  onClick: () => void
}

function CreateGroupVoteCard({ buttonLabel, header, subheader, image, onClick }: CreateGroupVoteCardProps) {

  return (
    <div className="create-group-vote-card">
      <div className="flex-grow">
        <div className="font-size-15 font-weight-500 mb-2">{header}</div>
        <div className="create-group-vote-card-subheader">{subheader}</div>
        <CustomButton buttonType={BLUE_BUTTON} label={buttonLabel} onClick={onClick} />
      </div>
      <div>
        {image}
      </div>
    </div>
  );
}

interface CreateGroupVoteImageProps {
  variant: "poll" | "spreadsheet"
}

// TODO: Finish building this out, icons aren't finalized.
function CreateGroupVoteImage({ variant }: CreateGroupVoteImageProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);

  const selectedIcon = variant === POLL_VARIANT
    ? <Check size={12} color={isDarkMode ? "#FFFFFF99" : "#4F516899"} />
    : <GroupSpreadsheetIcon />;

  const selectedCell = (
    <div className="create-group-vote-image-selected">
      {selectedIcon}
    </div>
  );

  return (
    <div className="create-group-vote-image">
      {selectedCell}
      <div></div>
      <div></div>
      {selectedCell}
    </div>
  );
}

function GroupSpreadsheetIcon() {
  const isDarkMode = useSelector(state => state.isDarkMode);
  const linesStrokeColor = isDarkMode ? "#5A5B65" : "#4f516860";

  return (
    <div className="flex flex-col">
      <Check className="mb-1" size={10} color={isDarkMode ? "#FFFFFF99" : "#4F516899"} />
      <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M1.65222 1.73633H30.6522" stroke={linesStrokeColor} strokeWidth="2" strokeLinecap="round"/>
        <path opacity="0.5" d="M1.65222 6.73633H17.6522" stroke={linesStrokeColor} strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </div>
  );
}
