import { parseISO } from "date-fns";
import create from "zustand";

export interface OutstandingSlotsState {
  outstandingSlots: OutstandingSlot[]
  setOutstandingSlots: (newOutstandingSlots: OutstandingSlot[]) => void
  updateOutstandingSlot: (newOutstandingSlot: OutstandingSlot) => void
  removeBySlug: (slug: string) => void
  resetOutstandingSlots: () => void
}

export const useOutstandingSlotsStore = create<OutstandingSlotsState>(
  (set) => ({
    outstandingSlots: [],
    // Overwrites the current array of outstanding slots with a new array.
    setOutstandingSlots: (newOutstandingSlots) => {
      set(() => ({ outstandingSlots: newOutstandingSlots }));
    },
    // Add or update an outstanding slot to the store, matching by the slug.
    // Preserve the sort order (newest first) when updating/adding.
    updateOutstandingSlot: (newOutstandingSlot) => {
      set((state) => {
        const outstandingSlots = [...state.outstandingSlots];
        const indexToReplace = outstandingSlots.findIndex(slot => slot.slug === newOutstandingSlot.slug);
        if (indexToReplace >= 0) {
          outstandingSlots[indexToReplace] = newOutstandingSlot;
        } else {
          const newOutstandingSlotCreatedAt = parseISO(newOutstandingSlot.created_at);
          const indexToInsert = outstandingSlots.findIndex(slot => (
            parseISO(slot.created_at) < newOutstandingSlotCreatedAt
          ));
          if (indexToInsert === -1) {
            outstandingSlots.push(newOutstandingSlot);
          } else {
            outstandingSlots.splice(indexToInsert, 0, newOutstandingSlot);
          }
        }

        return { outstandingSlots };
      });
    },
    // Remove an outstanding slot from the store.
    removeBySlug: (slug) => {
      set((state) => ({
        outstandingSlots: state.outstandingSlots.filter(outstandingSlot => outstandingSlot.slug !== slug),
      }));
    },
    resetOutstandingSlots: () => {
      set(() => ({ outstandingSlots: [] }));
    },
  }),
);
