import React, { PureComponent } from "react";

import ExpandedHangoutInfo from "./expandedHangoutInfo";
// import NativeZoomContent from './nativeZoomContent';

import GoogleCalendarService from "../../services/googleCalendarService";
import { getEventConferenceData } from "../../services/eventResourceAccessors";
const {
  googleHangoutString,
  zoomString,
  noConferenceString,
} = GoogleCalendarService;

class ConferenceStatus extends PureComponent {
  renderTitle(title = null) {
    return (
      <div className="margin-top-4 default-font-size margin-left-20">
        {title || "No Conferencing"}
      </div>
    );
  }

  renderExpandedHangoutInformation() {
    const originalEvent = this.props.originalEvent;

    const eventConferenceData = getEventConferenceData(originalEvent);
    if (eventConferenceData?.conferenceId) {
      let options = {};

      eventConferenceData.entryPoints.forEach((a) => {
        options[a.entryPointType] = a;
      });

      return <ExpandedHangoutInfo options={options} />;
    } else {
      return <div className="margin-left-20 default-font-size">Hangouts</div>;
    }
  }

  render() {
    switch (this.props.conference) {
      case zoomString:
        // return this.renderExpandedZoomInfo();
        return this.renderTitle(this.props.conference);
      case googleHangoutString:
        return this.renderExpandedHangoutInformation();
      default:
        if (this.props.conference !== noConferenceString) {
          return this.renderTitle(this.props.conference);
        }

        return this.renderTitle();
    }
  }
}

export default ConferenceStatus;
