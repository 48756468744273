import React from "react";
import { connect } from "react-redux";

import Broadcast from "../../../broadcasts/broadcast";
import {
  SLOTS_AVAILABILITY_SELECTION,
  PERSONAL_LINK_SELECTION,
  GROUP_VOTE_SELECT_OPTION,
  ACTION_MODE,
} from "../../../services/globalVariables";
import {
  useAllCalendars,
  useMasterAccount,
} from "../../../services/stores/SharedAccountData";
import ShortcutTile from "../../shortcutTiles/shortcutTile";

const AvailabilityFeatures = (props) => {
  const setAvailabilityType = async (type) => {
    props.setShouldDisplayMenu(false);
    await props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);

    Broadcast.publish("SET_AVAILABILITY_TYPE", type);
  };

  return (
    <div className="side-menu-tool-bar-section-wrapper">
      <div className="side-menu-tool-bar-text">Availability Features</div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => setAvailabilityType(SLOTS_AVAILABILITY_SELECTION)}
      >
        <div className="side-menu-description-text">Slots</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={"A"} />
        </div>
      </div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => setAvailabilityType(PERSONAL_LINK_SELECTION)}
      >
        <div className="side-menu-description-text">Personal Links</div>
        <div className="side-menu-toolbar-shortcut-tiles">
          <ShortcutTile shortcut={"Shift"} />
          <ShortcutTile shortcut={"Y"} />
        </div>
      </div>

      <div
        className="side-menu-toolbar-list-item"
        onClick={() => setAvailabilityType(GROUP_VOTE_SELECT_OPTION)}
      >
        <div className="side-menu-description-text">Group Vote</div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
    />
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withStore(AvailabilityFeatures));
