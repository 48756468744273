import React from "react";
import { Star } from "react-feather";
import { determineIfAttendeeIsCritical } from "../../../lib/availabilityFunctions";
import { GOLD_STAR_HEX_CODE } from "../../../lib/styleFunctions";

export default function CriticalAttendeeDisplay({ attendee, criticalAttendees }) {
  const { email } = attendee;
  const isCriticalAttendee = determineIfAttendeeIsCritical({ criticalAttendees, email });

  if (!isCriticalAttendee) {
    return null;
  }

  return (
    <div className="flex items-center gold-text-color font-size-12">
      <Star
        className="mr-1"
        color={GOLD_STAR_HEX_CODE}
        fill={GOLD_STAR_HEX_CODE}
        size={11}
      />
      <div className="italic" style={{ color: GOLD_STAR_HEX_CODE }}>Critical attendee</div>
    </div>
  );
}
