/**
 * This file was copied/modified from the AvailabilityLink component.
 * This has some hard-coded values to enable round-robin onboarding without
 * building out a complete round-robin feature.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";

import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Clock,
  Globe,
  Check,
  Calendar,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  X,
} from "react-feather";
import Classnames from "classnames";
import classNames from "classnames";
import { format } from "date-fns";
import PhoneNumber from "awesome-phonenumber";
import Fetcher from "../../services/fetcher";
import { constructRequestURL } from "../../services/api";
import {
  constructQueryParams,
  createSlotsForSelection,
  sortTimeSlots,
  SortArrayByDate,
  OpenLink,
  addAbbrevationToTimeZone,
  handleError,
  guessTimeZone,
  reformatMinDuration,
  determineDurationString,
  convertToTimeZone,
  isMobile,
  isOnboardingMode,
  generateAvailabilityToken,
  shouldRoundToNearest15,
} from "../../services/commonUsefulFunctions";
import ColoredLine from "../line";
import AvailabilityMonthlyCalendar from "../availabilityMonthlyCalendar";
import {
  conferencingDescriptor,
  BACKEND_ZOOM,
  GOOGLE_UPDATES,
  getVimcalRichTextSignature,
} from "../../services/googleCalendarService";
import {
  zoomImageURL,
  INVITEE_NAME_BLOCK,
  NEEDS_ACTION_STATUS,
  ACCEPTED_STATUS,
  BACKEND_PERSONAL_LINK,
  UTC_TIME_ZONE,
  DEFAULT_FONT_SIZE_PX,
  DEFAULT_BLUE,
  NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX,
} from "../../services/globalVariables";
import {
  determineBookingURL,
} from "../../lib/availabilityFunctions";
import {
  useZoomSchedulers,
  useMasterAccount,
  useAllLoggedInUsers,
} from "../../services/stores/SharedAccountData";
import { createUniqueZoom } from "../../services/zoomFunctions";
import { trackError, trackEvent } from "../tracking";
import { getPersonalOnboardingSpecialist } from "./sharedFunctions";
import { isVersionV2 } from "../../services/versionFunctions";
import { getProfilePhotoUrl, getSelectedUserName, getSocialLinks, getUserEmail, getUserToken } from "../../lib/userFunctions";
import Spinner from "../spinner";
import { isSelfServeOpen } from "../../lib/featureFlagFunctions";
import HoverableLogo from "../hoverableLogo";
import onboardBroadcast from "../../broadcasts/onboardBroadcast";
import ContactHandles from "../contact/contactHandles";
import { VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND } from "../../services/globalVariables";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { compileFreeSlots, fetchPersonalLinkForPersonalOnboarding, ROUND_ROBIN_CATEGORY } from "../../services/roundRobinFunctions";
import { OnboardingState, useOnboardingStore } from "../../services/stores/onboardingStore";
import { randomNumber } from "../../services/randomFunctions";
import { capitalizeFirstLetterOfEveryWord, isValidEmail } from "../../lib/stringFunctions";
import { getRandomElement } from "../../lib/arrayFunctions";

const TIME_FORMAT = "h:mma";
const BACKEND_PERSONAL = "personal";

const MOBILE_WIDTH_LIMIT = 600;

const DAYS_FORWARD = 14;

interface AvailabilityLinkProps {
  currentUser: User
  inputName: string
  inputEmail: string
  onComplete: () => void
  onClickTryUsOut: () => void
  customOnSuccessButtonCopy?: string
  onboardingStore: OnboardingState
  isEA: boolean
  hideOnClickNext: boolean
}

interface AvailabilityLinkState {
  error: boolean
  finishedLoadingData: boolean
}

class RoundRobinScheduler extends Component<AvailabilityLinkProps, AvailabilityLinkState> {
  constructor(props: AvailabilityLinkProps) {
    super(props);

    const index = this.getIndex();

    this._hasClickedSchedule = false; // used to track double click

    this.formRef = React.createRef();

    const isInOnboarding = isOnboardingMode();
    const { masterAccount } = props.masterAccount;

    const { token, photo } = getPersonalOnboardingSpecialist(masterAccount);

    this.state = {
      title: null,
      isSubmitting: false, // need both this and isSubmitting to prevent double click and to show loading (cause re-render)
      freeBusy: null,
      user: null,
      isAllBooked: false,
      expired: false,
      error: false,
      daySlots: {},
      selectedDay: null,
      selectedTime: null,
      hoverDay: null,
      onClickedConfirmTime: false,
      hasCapitalizedFirstLetter: false,
      showSentConfirmationEmail: false,
      nameHasIssue: false,
      emailHasIssue: false,
      shouldDisplayRequestAccessEmailSent: false,
      shouldShowMobileSelectTime: false,
      inputName: props.inputName ?? "",
      inputEmail: props.inputEmail ?? "",
      currentDayIndex: [],
      isMobile: isMobile(),
      isOnboardingMode: isInOnboarding,
      token: isInOnboarding ? token : props.match.params.token, // if onboarding, use sophie's personal link
      // token: isInOnboarding ? "b7c5633064531f2f15ffba78" : props.match.params.token, // https://book.vimcal.com/p/b7c5633064531f2f15ffba78 (for testing)
      index: index ? parseInt(Number(index)) : 0,
      errorWarning: null,
      availabilityEvents: null,
      zoomLink: null,
      isPreview: false,
      isPreviewExpired: false,
      timeSlotsInfo: null,
      host_time_zone: guessTimeZone(),
      newAttendeeSlots: [],
      isPersonalLink: isInOnboarding ?? false,
      finishedLoadingData: false,
      onboardingSpecialistPhoto: photo,

    };

    this.book = this.book.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterDaySlot = this.onMouseEnterDaySlot.bind(this);
    this.onClickConfirmOnTime = this.onClickConfirmOnTime.bind(this);
    this.onClickBackFromConfirmNameAndEmail =
      this.onClickBackFromConfirmNameAndEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClickSchedule = this.onClickSchedule.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.onClickBackFromSelectTime = this.onClickBackFromSelectTime.bind(this);
    this.goBackADay = this.goBackADay.bind(this);
    this.goForwardADay = this.goForwardADay.bind(this);
    this.closeWarningMessage = this.closeWarningMessage.bind(this);
    this.onClickDaySlot = this.onClickDaySlot.bind(this);
    this.onClickLogoOnConfirmationPage = this.onClickLogoOnConfirmationPage.bind(this);

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    this._isMounted = true;
    // The data should already be loaded or loading at this point, but call
    // this just to be certain.
    this.fetchInitialData();

    trackEvent({
      category: ROUND_ROBIN_CATEGORY,
      action: "0_rendered_book_personal_onboarding",
      label: "personal_onboarding_signup",
      userToken: getUserToken(this.props.currentUser),
    });

    if (
      !isEmptyObjectOrFalsey(this.props.onboardingStore.availabilities) &&
      !isEmptyObjectOrFalsey(this.props.onboardingStore.personalLinks)
    ) {
      this.handleResponseFromGetFreeEvents();
    }
  }

  componentDidUpdate(prevProps: AvailabilityLinkProps, prevState: AvailabilityLinkState) {
    if (this?.formRef?.current) {
      // We currently assign the formRef to the top of the form.
      // For overflowed forms it auto scrolls to the bottom on
      // load so we scroll to the top of the form upon update.
      this.formRef.current.scrollIntoView();
    }

    if (!isSelfServeOpen() && prevState.showSentConfirmationEmail !== this.state.showSentConfirmationEmail) {
      // only show back button if selfserve is not open
      onboardBroadcast.publish("SHOW_BACK_BUTTON_IN_ONBOARDING");
    }

    if (
      !this.state.finishedLoadingData &&
      isEmptyObjectOrFalsey(this.props.onboardingStore.availabilities) &&
      isEmptyObjectOrFalsey(this.props.onboardingStore.personalLinks) &&
      !this.props.onboardingStore.isFetchingAvailabilities
    ) {
      this.setState({ error: true, finishedLoadingData: true });
    }

    if (
      !isEmptyObjectOrFalsey(this.props.onboardingStore.availabilities) &&
      !isEmptyObjectOrFalsey(this.props.onboardingStore.personalLinks) &&
      (
        this.props.onboardingStore.availabilities !== prevProps.onboardingStore.availabilities ||
        this.props.onboardingStore.personalLinks !== prevProps.onboardingStore.personalLinks
      )
    ) {
      this.handleResponseFromGetFreeEvents();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.disappearingErrorMessageTimer);
    this.disappearingErrorMessageTimer = null;

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    return (
      <div
        className={classNames(
          "availability-link-background",
          "rounded-xl",
          "light-mode-scroll-important",
        )}
      >
        {this.renderLinkContainer()}
      </div>
    );
  }

  renderLoadingScreen() {
    return (
      <div className="cursor-pointer width-100-percent display-flex justify-content-center align-items-center position-relative">
        <div style={{ position: "absolute", top: 200 }}>
          <div className={"spin"}></div>
        </div>
      </div>
    );
  }

  renderLinkContainer() {
    return (
      <div
        className="availability-link-container"
        style={
          this.state.isMobile
            ? (
              {
                borderRadius: 0,
                width: "100vh",
                height: "100vh",
                justifyContent: "center",
              }
            ) : this.determineContainerStyle()
        }
      >
        {this.determineContent()}
      </div>
    );
  }

  renderShowError() {
    return this.renderShowErrorMessage("An error has occurred!");
  }

  renderShowExpired() {
    return this.renderShowErrorMessage("The link you clicked on has expired!");
  }

  renderShowErrorMessage(message = "An error has occurred!") {
    return (
      <div className="availability-error-container">
        <div style={{ fontSize: 30, marginBottom: 5 }}>Oh no!</div>

        <div style={{ fontSize: 18, fontWeight: 300, marginBottom: 70 }}>
          {message}
        </div>
      </div>
    );
  }

  renderConfirmationEmail() {
    return (
      <div
        className="color-default-text-color position-relative entire-availability-container-size"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflowY: "auto",
          width: this.state.isMobile ? "90%" : null,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 60 }}>
          Confirmed!
        </div>

        {this.state.user && (
          <div
            className="text-center"
            style={{
              fontSize: 14,
              fontWeight: 300,
              marginTop: 3,
            }}
          >
            {`You are scheduled with ${
              getSelectedUserName({user: this.state.user}).fullName || getUserEmail(this.state.user)
            }`}
          </div>
        )}

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "75%" }}>{this.renderEventInfoWithIcons("confirmed-page-event-detail-width", "flex flex-col items-center justify-center")}</div>
        </div>

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 40,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        {this.renderRequestAccess()}
      </div>
    );
  }

  onClickLogoOnConfirmationPage() {
    if (this.props.onClickTryUsOut) {
      this.props.onClickTryUsOut();
    } else {
      OpenLink("https://vimcal.com");
    }
  }

  renderRequestAccess() {
    if (this.state.shouldDisplayRequestAccessEmailSent) {
      return null;
    }
    if (this.props.hideOnClickNext) {
      return null;
    }

    if (!isSelfServeOpen()) {
      return (
        <HoverableLogo />
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          className="text-center"
          style={{ fontSize: 12, fontWeight: 300 }}
        >
          The world's fastest calendar, beautifully designed for a remote
          world
        </div>

        <div
          className="start-free-trial-button mt-8 transition-shadow duration-200 hover:shadow-2xl"
          onClick={this.onClickLogoOnConfirmationPage}
        >
          {this.props.customOnSuccessButtonCopy ?? "Check Us Out"}
        </div>
      </div>
    );
  }

  renderBackButton(onClickHandler) {
    return (
      <div
        className="availability-back-button-container"
        onClick={onClickHandler}
      >
        <div className="availability-back-button">
          <ArrowLeft size={15} color={"#4E516A"} />
        </div>
      </div>
    );
  }

  createRegionCodeText(regionCode) {
    return `${regionCode} (+${PhoneNumber.getCountryCodeForRegionCode(
      regionCode
    )})`;
  }

  renderEnterNameAndEmail() {
    const determineLabel = () => {
      if (this.state.isSubmitting) {
        return <Spinner useSmallSpinner={true} className="absolute -top-4" />;
      }
      return "Schedule Event";
    }
    return (
      <div
        className={classNames(
          "availability-enter-name-email-container position-relative"
        )}
        style={this.state.isMobile ? { marginTop: 15 } : {}}
      >
        <div className="width-100-percent">
          <div
            className="flex justify-start font-size-400 font-size-14"
            ref={this.formRef}
          >
            Enter Details
          </div>
        </div>

        <div className="width-100-percent font-size-14">
          <div style={{ marginTop: 30, width: "100%" }}>
            <div className="mb-4">Name *</div>

            <input
              className="availability-input"
              autoFocus={true}
              value={this.state.inputName}
              onChange={this.onChangeName}
            />

            {this.state.nameHasIssue &&
              this.renderWarning("*Please enter your name")}
          </div>

          <div style={{ marginTop: 20, width: "100%" }}>
            <div className="mb-4">Email *</div>

            <input
              className="availability-input"
              value={this.state.inputEmail}
              onChange={this.onChangeEmail}
            />

            {this.state.emailHasIssue &&
              this.renderWarning("*Please enter a valid email")}
          </div>
        </div>

        <div
          className="availability-schedule-event-button select-none sticky bottom-5"
          onClick={this.onClickSchedule}
        >
          {determineLabel()}
        </div>
      </div>
    );
  }

  renderWarning(warningString) {
    return (
      <div
        className="event-form-different-time-zone-warning"
        style={{ fontSize: 12, width: "100%" }}
      >
        {warningString}
      </div>
    );
  }

  renderErrorWarning() {
    if (!this.state.errorWarning) {
      return null;
    }

    return (
      <div
        className="availability-display-error-warning-container"
        style={
          this.state.isMobile
            ? { borderRadius: 0 }
            : {
                top: 0,
                zIndex: 1,
              }
        }
      >
        <div
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
          onClick={this.closeWarningMessage}
        >
          <X />
        </div>

        <div style={{ fontSize: 16 }}>{this.state.errorWarning.title}</div>

        <div style={{ fontSize: 14, marginTop: 8 }}>
          {this.state.errorWarning.subText}
        </div>
      </div>
    );
  }

  renderSelectDayAndTimeContainer() {
    return (
      <div
        className={
          this.state.isMobile
            ? "availability-select-day-time-container-is-mobile"
            : "availability-select-day-time-container-is-not-mobile position-relative"
        }
      >
        {this.renderErrorWarning()}

        {this.renderEventInfoAndSelectDay()}
        {!this.state.isMobile && this.renderSelectTime()}
      </div>
    );
  }

  renderEventInfoAndSelectDay() {
    return (
      <div
        className="availability-left-container"
        style={
          this.state.isMobile
            ? {
                border: "none",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }
            : {}
        }
      >
        {this.renderEventInfo()}

        <ColoredLine width={this.state.isMobile ? "100%" : "90%"} />

        {this.determineSelectDateMethod()}
      </div>
    );
  }

  renderEventInfo(additionalClassName = null) {
    const { allLoggedInUsers: { allLoggedInUsers }, masterAccount: { masterAccount } } = this.props;
    const { calendar_provider_id } = this.state;

    const user = calendar_provider_id ? allLoggedInUsers.find(user => user.email === calendar_provider_id) : null;
    const profilePhotoUrl = getProfilePhotoUrl({ masterAccount, user });
    const socialLinks = getSocialLinks({ masterAccount, user });

    return (
      <div
        className="availability-event-info"
        style={
          this.state.isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 15,
              }
            : { paddingTop: 25, paddingBottom: 25, paddingLeft: 30 }
        }
      >
        <div
          className={classNames(
            "items-center",
            this.state.isOnboardingMode ? "mb-2.5" : "mb-1"
          )}
        >
          {this.state.isOnboardingMode 
            ? this.renderProfilePicture() 
            : (profilePhotoUrl ? this.renderProfilePicture(profilePhotoUrl) : null)}

          {this.state.user ? (
            <div className="flex flex-wrap items-center gap-x-3 gap-y-2 mb-1">
              <div className="availability-user-name leading-none pt-px">
                {this.state.initialInputName
                  ? `${capitalizeFirstLetterOfEveryWord(
                      this.state.initialInputName
                    )} and ${getSelectedUserName({user: this.state.user}).fullName || this.state.user.email}`
                  : `${getSelectedUserName({user: this.state.user}).fullName || this.state.user.email}`}
              </div>
              <ContactHandles attendee={{ social_links: socialLinks }} isPreview />
            </div>
          ) : null}
        </div>

        <div className="availability-link-title font-size-20 font-weight-400">
          {this.determineLargeTitle()}
        </div>

        {this.renderEventInfoWithIcons(additionalClassName)}
      </div>
    );
  }

  renderProfilePicture() {
    return (
      <img
        style={{ marginTop: -8 }}
        alt=""
        width="50px"
        height="50px"
        className="mr-2 rounded-full"
        src={VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND}
      />
    );
  }

  renderEventInfoWithIcons(additionalClassName = null, containerClassName) {
    const FONT_SIZE = "font-size-12";
    const ICON_FONT_SIZE = 16;
    return (
      <div className={classNames("width-100-percent", containerClassName ?? "")}>
        <div
          className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
        >
          <div
            className={classNames("margin-right-10", FONT_SIZE)}
            style={{ paddingTop: 4 }}
          >
            <Clock size={ICON_FONT_SIZE} />
          </div>

          {`Duration: ${this.determineDurationText()}`}
        </div>

        {this.state.onClickedConfirmTime && this.state.selectedTime && (
          <div
            className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
          >
            <div
              className={classNames("margin-right-10", FONT_SIZE)}
            >
              <Calendar size={ICON_FONT_SIZE} />
            </div>

            {this.state.selectedTime.start}
          </div>
        )}

        <div
          className={`display-flex-flex-direction-row mt-1.5 align-items-center ${FONT_SIZE} ${additionalClassName ?? ""}`}
        >
          <div
            className={classNames("margin-right-10", FONT_SIZE)}
          >
            {this.renderConferencingIcon()}
          </div>

          {conferencingDescriptor(
            BACKEND_ZOOM,
            this.props.currentUser,
          )}
        </div>

        <div
          className={`display-flex-flex-direction-row mt-1.5 ${FONT_SIZE} ${additionalClassName}`}
        >
          <div
            className="margin-right-10 display-flex"
          >
            <Globe size={ICON_FONT_SIZE} />
          </div>

          <div>{addAbbrevationToTimeZone({timeZone: guessTimeZone()})}</div>
        </div>
      </div>
    );
  }

  renderSelectDay() {
    return (
      <div className="availability-bottom-left-container">
        <div
          className="availability-link-title"
          style={{
            paddingLeft: this.state.isMobile ? 15 : 30,
            paddingTop: 25,
            paddingBottom: 15,
          }}
        >
          Select Day
        </div>

        <div
          className="select-availability-day-slots-container"
          style={this.state.isMobile ? { overflowY: "auto" } : {}}
        >
          {this.renderDaySelection()}
        </div>
      </div>
    );
  }

  renderDaySelection() {
    if (!this.state.daySlots || Object.keys(this.state.daySlots).length === 0) {
      return;
    }

    return this.state.sortedDayArray.map((k, index) => {
      return (
        <div
          key={`availability_day_slots_${index}`}
          onClick={() => this.onClickDaySlot(k)}
          className="availability-link-day-container display-flex-flex-direction-row position-relative"
          style={
            this.state.isMobile
              ? Object.assign(
                  {
                    width: "100%",
                    justifyContent: "center",
                  },
                  this.daySlotStyle(k)
                )
              : Object.assign(this.daySlotStyle(k))
          }
          onMouseEnter={() => this.onMouseEnterDaySlot(k)}
          onMouseLeave={this.onMouseLeave}
        >
          <div className="display-flex-flex-direction-row align-items-center">
            <div
              className="availability-link-day-side-bar"
              style={
                k === this.state.selectedDay
                  ? { backgroundColor: DEFAULT_BLUE }
                  : {}
              }
            ></div>

            <div
              style={{
                position: "absolute",
                left: this.state.isMobile ? "15px" : "30px",
              }}
              className="font-size-14"
            >
              {k}
            </div>
          </div>

          {k === this.state.selectedDay && !this.state.isMobile && (
            <div className="availability-check-mark">
              <Check size={18} color={"#6177d9"} />
            </div>
          )}
        </div>
      );
    });
  }

  renderSelectTime() {
    return (
      <div className="render-right-hand-container">
        {this.renderRightHandTitle()}

        <div className="availability-time-slot-container">
          {this.renderTimeSlots()}
        </div>
      </div>
    );
  }

  renderRightHandTitle() {
    if (!this.state.selectedDay) {
      return;
    }

    return (
      <div className="margin-bottom-25">
        <div className="right-hand-title">
          {moment(this.state.selectedDay).format("LL")}
        </div>

        {this.renderTitleTime()}
      </div>
    );
  }

  renderTitleTime() {
    return null;
    if (this.state.selectedTime) {
      return (
        <div className="render-title-time">
          {moment(this.state.selectedTime.start).format("dddd")}{" "}
          {moment(this.state.selectedTime.start).format(TIME_FORMAT)} -{" "}
          {moment(this.state.selectedTime.end).format(TIME_FORMAT)}
        </div>
      );
    } else {
      return <div style={{ height: 16 }}></div>;
    }
  }

  renderTimeSlots() {
    if (
      !(
        this.state.selectedDay &&
        this.state.daySlots &&
        this.state.daySlots[this.state.selectedDay]
      )
    ) {
      return;
    }

    return this.state.daySlots[this.state.selectedDay].map((s, index) => {
      return this.renderSelectedTimeSlot(s, index);
    });
  }

  renderSelectedTimeSlot(s, index) {
    let shouldDisplayConfirm =
      this.state.selectedTime && s.start === this.state.selectedTime.start;

    return (
      <div
        key={`availability_selected_time_slot_${index}`}
        className="display-flex-flex-direction-row width-100-percent"
        style={{
          justifyContent: this.state.isMobile ? "center" : "flex-start",
        }}
        onClick={() => this.onClickTimeSlot(s)}
      >
        <div
          className={Classnames(
            "availability-link-time-slot-container",
            shouldDisplayConfirm
              ? "availability-confirm-time-width"
              : "availability-time-width"
          )}
        >
          {shouldDisplayConfirm && !this.state.isMobile
            ? `${moment(s.start).format(TIME_FORMAT)} - ${moment(s.end).format(
                TIME_FORMAT
              )}`
            : `${moment(s.start).format(TIME_FORMAT)}`}
        </div>

        <div
          className={Classnames(
            "confirm-button",
            shouldDisplayConfirm ? "availability-confirm-time-width" : ""
          )}
          style={shouldDisplayConfirm ? { marginRight: 10 } : { width: 0 }}
          onClick={this.onClickConfirmOnTime}
        >
          {shouldDisplayConfirm ? "Confirm" : ""}
        </div>
      </div>
    );
  }

  renderConferencingIcon() {
    return <img alt="" width="16px" height="16px" src={zoomImageURL} />;
  }

  fetchInitialData() {
    fetchPersonalLinkForPersonalOnboarding(this.props.currentUser, this.props.isEA);
  }

  handleResponseFromGetFreeEvents() {
    const {
      availabilities,
      personalLinks,
    } = this.props.onboardingStore;

    const compiledFreeSlots = compileFreeSlots({
      availabilities: Object.values(availabilities),
      daysForward: DAYS_FORWARD,
      durationMinutes: this.getDuration(),
      personalLinks: Object.values(personalLinks),
      roundUpInterval: this.getRoundUpInterval(),
    });

    const {
      sortedDayArray,
      selectedDay,
      daySlots,
    } = this.constructDayAndTimeSlotsForPersonalLink(compiledFreeSlots);

    this.setState({
      sortedDayArray,
      selectedDay,
      daySlots,
      zoomLink: this.props.currentUser.zoom_link,
      phoneNumber: {
        regionCode: this.props.currentUser.phone_region_code,
        number: this.props.currentUser.phone_number,
      },
      // userCalendarId: getEventUserCalendarID(response),
      finishedLoadingData: true,
    });
  }

  constructDayAndTimeSlotsForPersonalLink(freeSlots: { start_time: string, end_time: string, availableLinkTokens: string[] }[]) {
    // freeSlots is the result of taking out the busy slots
    if (freeSlots.length === 0) {
      this.setState({ isAllBooked: true });
      return;
    }

    let daySlots = createSlotsForSelection(freeSlots, this.getDuration());

    if (isEmptyObjectOrFalsey(daySlots)) {
      this.setState({ isAllBooked: true });
      return;
    }

    daySlots = sortTimeSlots(daySlots);

    const daysArray = daySlots ? Object.keys(daySlots) : [];
    const sortedDayArray = daysArray.sort((a, b) => SortArrayByDate(a, b));
    const selectedDay = sortedDayArray[0];

    return { sortedDayArray, selectedDay, daySlots };
  }

  book() {
    if (this._hasClickedSchedule) {
      return;
    }

    this._hasClickedSchedule = true;
    this.setState({isSubmitting: true});

    this.onClickSchedulePersonalOnboarding();
  }

  createBookingLinkSummary() {
    if (this.state.title && this.state.title.length > 0) {
      return this.state.title.replace(INVITEE_NAME_BLOCK, this.state.inputName);
    } else {
      return `${this.state.inputName.trim()} <> ${
        getSelectedUserName({user: this.state.user}).fullName || this.state.user.email
      }`;
    }
  }

  createPersonalLinkSummary() {
    return this.props.isEA ? "📆 Vimcal EA Onboarding" : "📆 Vimcal Onboarding";
  }

  bookPersonalLink(token: string) {
    const eventData = this.constructPersonalLinkEventData(token);

    const path = "personal_links/book";
    const params = {
      sendUpdates: GOOGLE_UPDATES.ALL,
      conferenceDataVersion: 1,
      link_type: BACKEND_PERSONAL,
      link_token: token,
      // appointment_token: this.state.appointmentToken,
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isVersionV2()) + `?${queryParams}`;

    const payloadData = { body: JSON.stringify(eventData) };

    this.submitBook(url, payloadData);
  }

  submitBook(url, payloadData) {
    return Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted) {
          return;
        } else if (!response) {
          trackError({
            category: "personal_onboarding_signup",
            errorMessage: "error_3_null_response",
            userToken: getUserToken(this.props.currentUser),
          });

          this.setState({ error: true });
          return;
        }

        if (this.props.onComplete) {
          this.props.onComplete();
        }

        this.setState({ showSentConfirmationEmail: true });
      })
      .catch((error) => {
        this.handleCatchError(error, true);
        trackError({
          category: "personal_onboarding_signup",
          errorMessage: error?.toString
            ? "error_4 " + error.toString()
            : "error_4",
          userToken: this.props.currentUser.token,
        });
      });
  }

  onClickSchedule() {
    const newState = {};

    if (this.state.inputName.length === 0) {
      newState["nameHasIssue"] = true;
    } else {
      newState["nameHasIssue"] = false;
    }

    const trimmedEmail = this.state.inputEmail.trim();

    if (!isValidEmail(trimmedEmail)) {
      newState["emailHasIssue"] = true;
    } else {
      newState["emailHasIssue"] = false;
    }

    if (
      newState["emailHasIssue"] ||
      newState["nameHasIssue"]
    ) {
      trackEvent({
        category: ROUND_ROBIN_CATEGORY,
        action: "error_with_email_name" + trimmedEmail + this.state.inputName,
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.props.currentUser),
      });

      this.setState(newState);
    } else {
      trackEvent({
        category: ROUND_ROBIN_CATEGORY,
        action: "2_schedule_event",
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.props.currentUser),
      });
      this.book();
    }
  }

  getIndex() {
    if (typeof URL !== "function") {
      // Unsafe to use the function
      return null;
    }

    const url_string = window.location.href;
    const url = new URL(url_string);

    const index = url.searchParams.get("index");

    return index;
  }

  onClickBackFromConfirmNameAndEmail() {
    this.setState({
      onClickedConfirmTime: false,
      nameHasIssue: false,
      emailHasIssue: false,
    });
  }

  onClickBackFromSelectTime() {
    this.setState({ shouldShowMobileSelectTime: false });
  }

  onChangeName(e) {
    if (
      e.target.value &&
      e.target.value.length === 1 &&
      !this.state.hasCapitalizedFirstLetter
    ) {
      this.setState({
        inputName: e.target.value.toUpperCase(),
        hasCapitalizedFirstLetter: true,
      });

      return;
    }

    this.setState({ inputName: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ inputEmail: e.target.value });
  }

  onClickDaySlot(day) {
    if (day === this.state.selectedDay && !this.state.isMobile) {
      return;
    }

    const newState = { selectedDay: day, selectedTime: null };
    if (this.state.isMobile) {
      newState["shouldShowMobileSelectTime"] = true;
    }

    this.setState(newState);
  }

  handleWindowSizeChange() {
    const displayWidth = window.innerWidth;
    if (displayWidth <= MOBILE_WIDTH_LIMIT && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (displayWidth > MOBILE_WIDTH_LIMIT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }

  onMouseLeave() {
    this.setState({ hoverDay: null });
  }

  onMouseEnterDaySlot(day) {
    this.setState({ hoverDay: day });
  }

  daySlotStyle(day) {
    if (this.state.selectedDay === day) {
      return { backgroundColor: "#EEF4FF", color: "#3980F8" };
    } else if (this.state.hoverDay === day && !this.state.isMobile) {
      return { backgroundColor: "#F6F7F7" };
    } else {
      return {};
    }
  }

  determineContent() {
    if (!this.state.finishedLoadingData) {
      return this.renderLoadingScreen();
    } else if (this.state.expired) {
      return this.renderShowExpired();
    } else if (this.state.isAllBooked) {
      return this.renderIsAllBooked();
    } else if (this.state.error) {
      return this.renderShowError();
    } else if (this.state.showSentConfirmationEmail) {
      return this.renderConfirmationEmail();
    } else if (this.state.onClickedConfirmTime) {
      return this.renderEventInformationAndEnterNameAndEmail();
    } else if (this.state.isMobile && this.state.shouldShowMobileSelectTime) {
      return this.renderMobileSelectTime();
    } else {
      return this.renderSelectDayAndTimeContainer();
    }
  }

  renderIsAllBooked() {
    return this.renderShowErrorMessage("All slots are booked.");
  }

  renderMobileSelectTime() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {this.renderBackButton(this.onClickBackFromSelectTime)}

        <div style={{ marginTop: 38, fontSize: 22, fontWeight: 400 }}>
          Select a Time
        </div>

        <div style={{ fontSize: DEFAULT_FONT_SIZE_PX, fontWeight: 300, marginTop: 12 }}>
          Duration: {this.getDuration()} minutes
        </div>

        <div
          className="display-flex-flex-direction-row"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ marginTop: 2, marginRight: 10 }}>
            <Globe size={13} />
          </div>

          <div style={{ fontSize: DEFAULT_FONT_SIZE_PX }}>
            {addAbbrevationToTimeZone({timeZone: guessTimeZone()})}
          </div>
        </div>

        <ColoredLine style={{ marginTop: 34, width: "100%" }} />

        {this.renderMobileSwipeDate()}

        <div
          className="width-100-percent"
          style={{ overflowY: "auto", marginBottom: 80 }}
        >
          {this.renderTimeSlots()}
        </div>
      </div>
    );
  }

  renderMobileSwipeDate() {
    const currentDayIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    return (
      <div
        className="display-flex-flex-direction-row"
        style={{ marginTop: 42, marginBottom: 30 }}
      >
        {currentDayIndex > 0 ? (
          <div className="availability-back-button" onClick={this.goBackADay}>
            <ChevronLeft size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}

        <div
          style={{
            marginLeft: 47,
            marginRight: 47,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>
            {moment(this.state.selectedDay).format("dddd")}
          </div>

          <div
            className="text-center"
            style={{ fontSize: 15, fontWeight: 300 }}
          >
            {moment(this.state.selectedDay).format("MMM D, YYYY")}
          </div>
        </div>

        {currentDayIndex < this.state.sortedDayArray.length - 1 ? (
          <div
            className="availability-back-button"
            onClick={this.goForwardADay}
          >
            <ChevronRight size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}
      </div>
    );
  }

  renderEventInformationAndEnterNameAndEmail() {
    return (
      <div
        className={
          this.state.isMobile
            ? "width-100-percent overflow-y-auto"
            : "width-100-percent display-flex-flex-direction-row"
        }
      >
        <div
          className="availability-left-container position-relative"
          style={
            this.state.isMobile
              ? {
                  border: "none",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderBottom: "1px solid #d7d9d6",
                }
              : { alignItems: "flex-start" }
          }
        >
          {this.renderBackButton(this.onClickBackFromConfirmNameAndEmail)}

          <div
            className="schedule-event-page-margin-top"
            style={this.state.isMobile ? { marginTop: 30 } : {}}
          >
            {this.renderEventInfo("mt-1.5")}
          </div>
        </div>

        {this.renderEnterNameAndEmail()}
      </div>
    );
  }

  determineContainerStyle() {
    if (
      this.state.error ||
      this.state.expired ||
      this.state.isAllBooked
    ) {
      return { width: 500, height: 700 };
    } else {
      return {};
    }
  }

  determineSelectDateMethod() {
    return (
      <AvailabilityMonthlyCalendar
        onClickDaySlot={this.onClickDaySlot}
        availableSlots={this.state.daySlots}
        selectedDay={this.state.selectedDay}
        calendarType="US"
        containerClassName="mt-5"
      />
    );
  }

  constructPersonalLinkEventData(token: string) {
    if (!this.state.selectedTime) {
      return;
    }

    const start = {
      dateTime: moment(this.state.selectedTime.start).format(),
    };

    const end = {
      dateTime: moment(this.state.selectedTime.end).format(),
    };

    const summary = this.createPersonalLinkSummary();

    const attendees = this.createAttendees(token);
    const availability = this.props.onboardingStore.availabilities[token];
    const meetingToken = generateAvailabilityToken(this.props.currentUser);

    const description = this.createDescription(meetingToken);

    const calendar_event = {
      event_start: start,
      event_end: end,
      summary,
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      conferenceData: this.createConferencingForOnboardingPersonalLink(availability),
      attendees,
      description,
    };

    if (
      availability.personal_link.conferencing === BACKEND_ZOOM &&
      this.state.zoomLink
    ) {
      // no location -> but has zoom personal link
      calendar_event.location = this.state.zoomLink;
    }

    // Body: 2:30pm - Wednesday, June 10, 2020
    // Subject: 2:30pm Wed, Jun 10, 2020
    const event = isVersionV2() ? { calendar_event } : { google_calendar_event: calendar_event };
    return {
      event,
      user_calendar_id: availability.user_calendar_id,
      meeting_token: meetingToken,
      conferencing: availability.personal_link.conferencing,
      ...(availability.personal_link.location && {
        location: availability.personal_link.location,
      }),
      ...this.createEmailData(),
    };
  }

  createEmailData() {
    return {
      invitee_email: this.state.inputEmail,
      invitee_name: this.state.inputName,
      duration: this.getDuration(),
      invitee_time_zone_text: addAbbrevationToTimeZone({timeZone: guessTimeZone()}),
      invitee_start_time_body: this.createStartTimeBody(false),
      invitee_start_time_subject: this.createStartTimeSubject(false),
      host_start_time_subject: this.createStartTimeSubject(true),
      host_start_time_body: this.createStartTimeBody(true),
      host_time_zone_text: addAbbrevationToTimeZone({timeZone: this.state.host_time_zone}),
    };
  }

  createDescription(meetingToken: string) {
    let updatedDescription = this.state.description || "";

    if (!(updatedDescription === "" || updatedDescription.length === 0)) {
      // add new line if there's information there already
      updatedDescription = updatedDescription + "\n\n";
    }

    const link = determineBookingURL();
    const cancelLink = `Cancel: \n ${link}/c/${meetingToken}`;
    const rescheduleLink = `Reschedule: \n ${link}/r/${meetingToken}`;

    const {
      masterAccount
    } = this.props.masterAccount;
    updatedDescription =
      updatedDescription +
      `Need to change this event? \n\n ${cancelLink} \n\n ${rescheduleLink} \n\n ${getVimcalRichTextSignature(masterAccount)}`;

    return updatedDescription;
  }

  createAttendees(token: string) {
    const availability = this.props.onboardingStore.availabilities[token];

    return [
      {
        self: true,
        email: getUserEmail(availability.user),
        organizer: true,
        responseStatus: ACCEPTED_STATUS,
        displayName: availability.user.full_name,
      },
      {
        responseStatus: NEEDS_ACTION_STATUS,
        email: this.state.inputEmail.trim(),
        displayName: this.state.inputName.trim(),
      },
    ];
  }

  createStartTimeBody(isHost: boolean) {
    const time = this.state.selectedTime.start;

    return format(
      convertToTimeZone(moment(time).toDate(), {
        timeZone: isHost ? this.state.host_time_zone : guessTimeZone(),
      }),
      "h:mmaaa - eeee, MMMM d, yyyy",
    );
  }

  createStartTimeSubject(isHost: boolean) {
    const time = this.state.selectedTime.start;

    return format(
      convertToTimeZone(moment(time).toDate(), {
        timeZone: isHost ? this.state.host_time_zone : guessTimeZone(),
      }),
      "h:mmaaa EEE, MMM d, yyyy",
    );
  }

  goBackADay() {
    const currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex - 1],
      selectedTime: null,
    });
  }

  goForwardADay() {
    const currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex + 1],
      selectedTime: null,
    });
  }

  onClickConfirmOnTime() {
    if (this.state.isOnboardingMode) {
      trackEvent({
        category: ROUND_ROBIN_CATEGORY,
        action: "1_selected_confirm_button",
        label: "personal_onboarding_signup",
        userToken: getUserToken(this.props.currentUser),
      });
    }

    this.setState({ onClickedConfirmTime: true });
  }

  onClickTimeSlot(time) {
    this.setState({ selectedTime: time });
  }

  closeWarningMessage() {
    this.setState({ errorWarning: null });
  }

  displayErrorWarning(message: string) {
    this.setState({ errorWarning: message });

    clearTimeout(this.disappearingErrorMessageTimer);

    this.disappearingErrorMessageTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.closeWarningMessage();
    }, 5000);
  }

  determineDurationText() {
    const durationObject = reformatMinDuration(this.getDuration());

    return determineDurationString(durationObject);
  }

  doesResponseHaveErrorAndHandleError(response) {
    let hasError = false;

    if (!response) {
      hasError = true;
      this.setState({ error: true });
    } else if (response.error) {
      hasError = true;
      if (response.error === "expired") {
        this.setState({ expired: true });
      } else {
        this.setState({ error: true });
      }
    } else if (response.type === "error") {
      hasError = true;

      this.setState({ error: true });
    }

    return hasError;
  }

  handleCatchError(error, shouldUpdateSubmitting = false) {
    handleError(error);

    if (!this._isMounted) {
      return;
    }

    if (shouldUpdateSubmitting) {
      this.setState({ error: true });
    } else {
      this.setState({ error: true });
    }
  }

  determineLargeTitle() {
    if (this.state.name) {
      return this.state.name;
    }

    if (this.state.title && this.state.title.length > 0) {
      if (
        this.state.title.includes(INVITEE_NAME_BLOCK) &&
        this.state.initialInputName
      ) {
        return this.state.title.replace(
          INVITEE_NAME_BLOCK,
          capitalizeFirstLetterOfEveryWord(this.state.initialInputName)
        );
      } else if (!this.state.title.includes(INVITEE_NAME_BLOCK)) {
        return this.state.title;
      }
    }

    return this.createPersonalLinkSummary();
  }

  getVersion() {
    return isVersionV2() ? "v2" : "v1"
  }

  createConferencingForOnboardingPersonalLink(availability: PersonalLinkAvailability) {
    const conferencing = availability.personal_link.conferencing;

    if (
      conferencing === BACKEND_ZOOM &&
      this.state.uniqueZoomMeeting
    ) {
      return createUniqueZoom(this.state.uniqueZoomMeeting);
    } else if (
      conferencing === BACKEND_ZOOM &&
      this.state.zoom_link
    ) {
      const zoomEntryPoint = {
        entryPointType: "video",
        uri: this.state.zoomLink,
        label: this.state.zoomLink,
      };

      const conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: "Zoom Meeting",
        iconUri: zoomImageURL,
      };

      const entryPoints = [zoomEntryPoint];

      const conferencing = { entryPoints, conferenceSolution };
      if (this.state.zoomId) {
        // zoomId is used so you can join the meeting from the zoom app
        conferencing.conferenceId = this.state.zoomId;
      }

      return conferencing;
    }
  }

  createZoomUniqueLinkParam() {
    return {
      meeting: {
        topic: this.createPersonalLinkSummary(),
        timezone: UTC_TIME_ZONE,
        start_time: moment(this.state.selectedTime.start)
          .toDate()
          .toISOString(),
        duration: moment(this.state.selectedTime.end)
          .startOf("minute")
          .diff(
            moment(this.state.selectedTime.start).startOf("minute"),
            "minute"
          ),
      },
    };
  }

  // creates unique zoom and do not sue current user's
  onClickSchedulePersonalOnboarding() {
    const availableTokens:string[] = this.state.selectedTime.availableLinkTokens;
    const {
      currentUser,
      onboardingStore,
    } = this.props;
    const pickToken = () => {
      const getSpecialistName = (selectedToken) => {
        if (selectedToken === NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX.PAT) {
          return "pat";
        } else if (selectedToken === NORMAL_VIMCAL_ONBOARDING_PERSONAL_LINK_INDEX.JAN) {
          return "jan";
        } else {
          return "other";
        }
      };
      // make it evenly split for now since David is taking all the Vimcal EA slots
      const PAT_WEIGHT = 30; // if it's 70, this is really 85 since (100 - 70) / (length of token) = 85
      // const JAN_WEIGHT = 30; // no need to use Jan's weight until we have more onboarding specialists
      const TRACKING_KEY = "token_weight_personal_onboarding_signup";
      if (availableTokens.length === 1) {
        // if there's only one, we have to go with that token
        const selectedToken = availableTokens[0];
        trackEvent({
          category: ROUND_ROBIN_CATEGORY,
          action: `${getSpecialistName(selectedToken)}_default`,
          label: TRACKING_KEY,
          userToken: getUserToken(currentUser),
        });
        return selectedToken;
      }
      if (randomNumber(0, 100) <= PAT_WEIGHT) {
        const selectedToken = availableTokens[0]; // pat's PL link is the first element of the array
        trackEvent({
          category: ROUND_ROBIN_CATEGORY,
          action: `${getSpecialistName(selectedToken)}_pat`,
          label: TRACKING_KEY,
          userToken: getUserToken(currentUser),
        });
        return selectedToken;
      }
      const selectedToken = getRandomElement(availableTokens);
      trackEvent({
        category: ROUND_ROBIN_CATEGORY,
        action: `${getSpecialistName(selectedToken)}_other`,
        label: TRACKING_KEY,
        userToken: getUserToken(currentUser),
      });
      return selectedToken;
    };
    const token = pickToken();
    const availability = onboardingStore.availabilities[token];

    if (
      availability.personal_link.conferencing === BACKEND_ZOOM &&
      availability.default_zoom_mode !== BACKEND_PERSONAL_LINK
    ) {
      const path = `personal_links/${token}/zoom_meetings`;
      const url = constructRequestURL(path); // TODO: change to v2 for zoom later
      const param = this.createZoomUniqueLinkParam();
      const payloadData = {
        body: JSON.stringify(param),
      };

      // if user not logged in -> 401 error
      Fetcher.post(url, payloadData)
        .then((response) => {
          if (!this._isMounted) {
            return;
          }

          if (isEmptyObjectOrFalsey(response) || !response.join_url) {
            // create with personal links
            this.bookPersonalLink(token);
            return;
          }

          const { join_url, id } = response;

          this.setState(
            { zoomLink: join_url, zoomId: id, uniqueZoomMeeting: response },
            () => this.bookPersonalLink(token),
          );
        })
        .catch((error) => {
          handleError(error);
          if (!this._isMounted) {
            return;
          }

          this.bookPersonalLink(token);
        });
    } else {
      this.bookPersonalLink(token);
    }
  }

  getDuration() {
    return this.props.isEA ? 45 : 30;
  }

  getRoundUpInterval() {
    return shouldRoundToNearest15(this.getDuration()) ? 15 : 30;
  }
}

function mapStateToProps(state) {
  let { currentUser, temporaryEvents } = state;

  return { currentUser, temporaryEvents };
}

const withStore = (BaseComponent) => (props) => {
  const allLoggedInUsers = useAllLoggedInUsers();
  const zoomSchedulers = useZoomSchedulers();
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount();
  const onboardingStore = useOnboardingStore();

  return (
    <BaseComponent
      {...props}
      allLoggedInUsers={allLoggedInUsers}
      zoomSchedulers={zoomSchedulers}
      isDarkMode={isDarkMode}
      masterAccount={masterAccount}
      onboardingStore={onboardingStore}
    />
  );
};

export default connect(mapStateToProps)(
  withRouter(withStore(RoundRobinScheduler)),
);
