import React, { PureComponent } from "react";
import { loadTheme } from "../../services/commonUsefulFunctions";
import { connect } from "react-redux";
import { CONFETTI_DURATION, TEAM_PHOTO } from "./sharedVariables";
import OnboardingConfetti from "./onboardingConfetti";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { withRouter } from "react-router-dom";
import { DARK_MODE_THEME } from "../../services/globalVariables";
import appBroadcast from "../../broadcasts/appBroadcast";
import broadcast from "../../broadcasts/broadcast";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import DefaultBackButton from "../defaultBackButton";
import ShortcutHoverHint from "../shortcutHoverHint";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
// import confetti from "canvas-confetti";

class TypeformThankYouPage extends PureComponent {
  constructor(props) {
    super(props);
    this._confettiTimeout = null;
    this.state = {
      shouldDisplayConfetti: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.isUserLoggedIn()) {
      backendBroadcasts.publish("GET_LATEST_MASTER_ACCOUNT", true);
    }
    
    loadTheme(DARK_MODE_THEME);
    if (!this.props.isDarkMode && this.isUserLoggedIn()) {
      // set to dark mode
      // appBroadcast.publish("SET_APP_DARK_MODE");
      broadcast.publish("ENABLE_DARK_MODE");
    }

    this.setState({ shouldDisplayConfetti: true }, () => {
      this._confettiTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this.setState({ shouldDisplayConfetti: false });
      }, CONFETTI_DURATION - 500);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._confettiTimeout && clearTimeout(this._confettiTimeout);
  }

  render() {
    return (
      <div className="height-100vh overflow-x-hidden dark-mode-default-background-color">
        {this.renderBackButton()}

        <div className="onboarding-container onboarding-container-medium">
          <div className="section-title text-white">
            {this.props.isConfirmedBooking ? "Thank you for booking a time!" : "Thanks for joining waitlist!"}
          </div>

          <div className="welcome-subtitle mt-3 display-flex-flex-direction-row text-white">
            We look forward to onboarding you
          </div>

          {this.renderConfetti()}
          <img
            alt=""
            className="gif-tutorial mt-5"
            style={{ width: "inherit" }}
            src={TEAM_PHOTO}
          />
        </div>
      </div>
    );
  }

  isUserLoggedIn() {
    return !isEmptyObjectOrFalsey(this.props.currentUser);
  }

  renderBackButton() {
    if (!this.isUserLoggedIn()) {
      return null;
    }

    return (
      <ShortcutHoverHint
        below
        style={{ left: 54, top: 112, width: "max-content" }}
        title={"Log out"}
      >
        <DefaultBackButton onClick={this.onClickBack} />
      </ShortcutHoverHint>
    );
  }

  onClickBack() {
    appBroadcast.publish("CLICK_LOG_OUT");
  }

  renderConfetti() {
    if (!this.state.shouldDisplayConfetti) {
      return null;
    }

    return <OnboardingConfetti />;
  }
}

function mapStateToProps(state) {
  let { currentUser, isDarkMode } = state;

  return {
    currentUser,
    isDarkMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} />;
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withStore(TypeformThankYouPage)));
