import React, { useState } from "react";
import VimcalEALogo from "./vimcalEALogo";
import { formatEmail, getInputStringFromEvent, isValidEmail } from "../../js/lib/stringFunctions";
import CustomSelectV2, { SELECT_VARIANTS } from "../../js/components/select/selectV2";
import classNames from "classnames";
import CustomButtonV2 from "../../js/components/buttons/customButtonV2";
import { BLUE_BUTTON, EXCLUDED_DOMAINS } from "../../js/services/globalVariables";
import { trackEvent } from "../../js/components/tracking";
import { VIMCAL_EA_FORM_CATEGORY } from "../../js/services/experiments";
import Fetcher from "../../js/services/fetcher";
import { constructRequestURLV2 } from "../../js/services/api";
import SpinnerV2 from "../../js/components/spinnerV2";
import { EA_COUNT_OPTIONS } from "../../js/components/onboarding/sharedFunctions";
import { openLinkOnSamePage } from "../../js/services/commonUsefulFunctions";
import { CALENDLY_LINK_FOR_EA, CALENDLY_LINK_FOR_EA_DAVIDS } from "../../js/components/specialComponents/calendlyBookingPage";
import { useUserCodes } from "../../js/services/stores/userData";
import { safeJSONStringify } from "../../js/lib/jsonFunctions";

const TYPE_OF_EAS = [
  "I schedule on behalf of others",
  "Someone schedules for me",
  "Other",
];

const HOW_DID_YOU_HEAR_ABOUT_US = [
  "Newsletter",
  "LinkedIn",
  "my boss",
  "Co-worker",
  "Referral",
  "Vimcal booking link",
  "Other",
];

const LABEL_CLASSNAME = "font-weight-400";

const SHARED_INPUT_CLASSNAME = "rounded-md default-input-field";
const SHARED_SECTION_WRAPPER_CLASSNAME = "flex flex-col gap-1.5";


export default function VimcalEASignupPage({ id }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workEmail, setWorkEmail] = useState("");
  const [workEmailError, setWorkEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [typeOfEA, setTypeOfEA] = useState(TYPE_OF_EAS[0]);
  const [numberOfEAs, setNumberOfEAs] = useState(EA_COUNT_OPTIONS[0]);
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(null);
  const [showWorkEmailWarning, setShowWorkEmailWarning] = useState(false);
  const userCodes = useUserCodes();
  const {
    referral,
    utmSource,
    promo,
    affiliate,
    attribution,
    utmMedium,
    utmCampaign,
    from,
  } = userCodes;

  const onClickSubmit = async () => {
    // check email is valid
    try {
      if (isSubmitting) {
        return;
      }
      if (!isValidEmail(workEmail)) {
        setIsSubmitting(false);
        setWorkEmailError(true);
        return;
      }
      
      setIsSubmitting(true);
      trackEvent({
        category: `${VIMCAL_EA_FORM_CATEGORY}-submitted`,
        action: id,
        label: safeJSONStringify({
          workEmail,
          firstName,
          lastName,
          company,
          typeOfEA,
          numberOfEAs,
          howDidYouHearAboutUs,
          utmSource,
          utmMedium,
          utmCampaign,
          referral,
          promo,
          attribution,
          affiliate,
          from,
        }),
      });
      const path = "hubspot/create";
      const url = constructRequestURLV2(path);
      const param = {
        work_email: workEmail,
        first_name: firstName,
        last_name: lastName,
        company: company,
        ea_type: typeOfEA,
        number_of_eas: numberOfEAs,
        source: howDidYouHearAboutUs,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        referral_code: referral,
        promo_code: promo,
        attribution,
        affiliate,
        from,
      };
      const payloadData = {
        body: JSON.stringify(param),
      };
      await Fetcher.post(url, payloadData, false);
      setIsSubmitting(false);

      // redirect to calendly page
      const getCalendlyLinkURL = () => {
        const baseURL = numberOfEAs === EA_COUNT_OPTIONS[0] ? CALENDLY_LINK_FOR_EA : CALENDLY_LINK_FOR_EA_DAVIDS;
        if (firstName && lastName && workEmail) {
          const name = `${firstName} ${lastName}`;
          return `${baseURL}&name=${encodeURIComponent(name)}&email=${encodeURIComponent(workEmail)}`;
        }
        if (workEmail) {
          return `${baseURL}&email=${encodeURIComponent(workEmail)}`; // only email
        }
        return baseURL;
      };
      openLinkOnSamePage(getCalendlyLinkURL());
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const createOption = (options) => {
    return options.map((option) => ({value: option, label: option}));
  };

  const renderError = () => {
    if (workEmailError) {
      return (<div className="text-red-500 font-size-12">Please enter a valid email</div>);
    }
    if (showWorkEmailWarning) {
      return (<div className="light-mode-secondary-text-color font-size-12">Please enter a work email</div>);
    }
    return null;
  };

  return (
    <div className={
      classNames(
        "bg-white w-full h-full light-mode-font-color default-font-size",
        "flex",
        "items-center justify-center",
      )}
    >
      <div className="w-72 flex flex-col gap-3">
        <VimcalEALogo />
        <div className="font-weight-500 font-size-20 my-4">Book a free intro call</div>
        <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
          <div className={LABEL_CLASSNAME}>Work Email</div>
          <input
            value={workEmail}
            onChange={(e) => {
              if (workEmailError) {
                setWorkEmailError(false);
              }
              if (showWorkEmailWarning) {
                setShowWorkEmailWarning(false);
              }
              setWorkEmail(getInputStringFromEvent(e));
            }}
            className={SHARED_INPUT_CLASSNAME}
            onBlur={() => {
              if (!isValidEmail(workEmail)) {
                setWorkEmailError(true);
                return;
              }
              const formattedEmail = formatEmail(workEmail);
              if (EXCLUDED_DOMAINS.some((domain) => formattedEmail.includes(domain))) {
                setShowWorkEmailWarning(true);
              }
            }}
          />
          {renderError()}
        </div>


        <div className="flex items-center gap-4">
          <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
            <div className={LABEL_CLASSNAME}>First Name</div>
            <input
              value={firstName}
              onChange={(e) => setFirstName(getInputStringFromEvent(e))}
              className={SHARED_INPUT_CLASSNAME}
            />
          </div>

          <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
            <div className={LABEL_CLASSNAME}>Last Name</div>
            <input
              value={lastName}
              onChange={(e) => setLastName(getInputStringFromEvent(e))}
              className={SHARED_INPUT_CLASSNAME}
            />
          </div>
        </div>

        <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
          <div className={LABEL_CLASSNAME}>Company</div>
          <input
            value={company}
            onChange={(e) => setCompany(getInputStringFromEvent(e))}
            className={SHARED_INPUT_CLASSNAME}
          />
        </div>

        <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
          <div className={LABEL_CLASSNAME}>How many EAs work at your company?</div>
          <CustomSelectV2
            className="w-full"
            value={numberOfEAs ? {value: numberOfEAs, label: numberOfEAs} : {
              value: numberOfEAs.value,
              label: numberOfEAs.label,
            }}
            options={createOption(EA_COUNT_OPTIONS)}
            onChange={(option) => setNumberOfEAs(option.value)}
            isSearchable={true}
            variant={SELECT_VARIANTS.OUTLINED}
          />
        </div>

        <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
          <div className={LABEL_CLASSNAME}>What describes you the most?</div>
          <CustomSelectV2
            className=" w-full"
            value={{value: typeOfEA, label: typeOfEA}}
            options={createOption(TYPE_OF_EAS)}
            onChange={(option) => setTypeOfEA(option.value)}
            isSearchable={true}
            variant={SELECT_VARIANTS.OUTLINED}
          />
        </div>
        <div className={SHARED_SECTION_WRAPPER_CLASSNAME}>
          <div className={LABEL_CLASSNAME}>How did you hear about us?</div>
          <CustomSelectV2
            className="w-full"
            value={howDidYouHearAboutUs ? {value: howDidYouHearAboutUs, label: howDidYouHearAboutUs} : {value: "", label: "Select"}}
            options={createOption(HOW_DID_YOU_HEAR_ABOUT_US)}
            onChange={(option) => setHowDidYouHearAboutUs(option.value)}
            isSearchable={true}
            variant={SELECT_VARIANTS.OUTLINED}
          />
        </div>
        <CustomButtonV2
          className="mt-8"
          buttonType={BLUE_BUTTON}
          label={"Submit"}
          onClick={onClickSubmit}
          shouldRenderSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
}
