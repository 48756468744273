import create from "zustand";
import { guessTimeZone } from "../commonUsefulFunctions";

export const useTemporaryTimeZonesStore = create((set) => ({
  hoveredTimeZone: null,
  setHoveredTimeZone: (timeZone) => {
    set(() => ({ hoveredTimeZone: timeZone }));
  },
  resetHoveredTimeZone: () => {
    set(() => ({ hoveredTimeZone: null }));
  },
}));


export const useTemporaryStateStore = create((set) => ({
  previousTimeZoneOnMainCalendarUpdate: null, // since events in mainCalendar updates asynchronously, there's a race condition where we could update temporary events with the previous time zone. This is used to remember the previous time zone on mainCalendar update.
  setPreviousTimeZoneOnMainCalendarUpdate: (previousTimeZoneOnMainCalendarUpdate) => {
    set(() => ({ previousTimeZoneOnMainCalendarUpdate }));
  },
  hoveredEventID: null,
  setHoveredEventID: (id) => {
    set((state) => ({ hoveredEventID: id }));
  },
  hoveredSlotsSlug: null,
  setHoveredSlotsSlug: (slug) => {
    set((state) => ({ hoveredSlotsSlug: slug }));
  },
  resetHoveredSlotsSlug: () => {
    set((state) => ({ hoveredSlotsSlug: null }));
  },
  reverseSlotsText: "",
  completionID: null,
  reversedSlotsEvents: [],
  originalReverseSlotsImage: null,
  originalReverseSlotsInputText: "",
  reverseSlotDuration: 30,
  senderTimeZone: null,
  saveOriginalReverseSlotsImage: (image) => {
    set((state) => ({ originalReverseSlotsImage: image }));
  },
  bookingLinkState: {},
  meetWithEvents: [],
  eventFormEvents: [],
  findTimeUser: null,
  findTimeDuration: null,
  holdEvent: {},
  setFindTimeData: ({user, duration}) => {
    set((state) => ({ 
      findTimeUser: user,
      findTimeDuration: duration
    }));
  },
  resetFindTimeData: () => {
    set((state) => ({ 
      findTimeUser: null,
      findTimeDuration: null
    }));
  },
  setEventFormEvents: (eventFormEvents) => {
    set((state) => ({ eventFormEvents }));
  },
  setMeetWithEvents: (meetWithEvents) => {
    set((state) => ({ meetWithEvents }));
  },
  setBookingLinkState: (bookingLinkState) => {
    set((state) => ({ 
      bookingLinkState
    }));
  },
  resetBookingLinkState: () => {
    set((state) => ({ 
      bookingLinkState: {}
    }));
  },
  setReverseSlotsData({
    text,
    completionID,
    events,
    originalReverseSlotsInputText,
    duration = 30,
    senderTimeZone = guessTimeZone(),
  }) {
    set((state) => ({
      reverseSlotsText: text,
      completionID,
      reversedSlotsEvents: events,
      originalReverseSlotsInputText,
      reverseSlotDuration: duration,
      senderTimeZone,
    }));
  },
  setReversSlotsEventsAndText({ reverseSlotsText, reversedSlotsEvents }) {
    set((state) => ({
      reverseSlotsText,
      reversedSlotsEvents,
    }));
  },
  setReverseSlotDuration: (duration) => {
    set((state) => ({ reverseSlotDuration: duration }));
  },
  setHoldEvent: (event) => {
    set(() => ({ holdEvent: event }))
  },
  resetHoveredEventID: () => set((state) => ({ hoveredEventID: null })),
  isHideTimesWithBusyEvents: false,
  toggleIsHideTimesWithBusyEvents() {
    set((state) => ({
      isHideTimesWithBusyEvents: !state.isHideTimesWithBusyEvents,
    }));
  },
  resetReverseSlotsData: () =>
    set((state) => ({
      reverseSlotsText: "",
      completionID: null,
      reversedSlotsEvents: [],
      originalReverseSlotsImage: null,
      originalReverseSlotsInputText: "",
      reverseSlotDuration: 30,
      senderTimeZone: null,
      isHideTimesWithBusyEvents: false,
      meetWithEvents: [],
      eventFormEvents: [],
      findTimeUser: null,
      findTimeDuration: null,
      previousTimeZoneOnMainCalendarUpdate: null,
    })),
  resetHoldEvent: () => set(() => ({ holdEvent: {} })) // Hold events are kept in store to remember which holds are to be deleted when an event is created
}));
