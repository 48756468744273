import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EventExpandedView from "./eventExpandedView";
import CloseButton from "../components/closeButton";
import SidePanel from "../components/sidePanel";
import {
  constructQueryParams,
  formatEventForReactBigCalendar,
  expandedDateAndTimeString,
  hasEventPreventDefault,
  calculateMarginTopClassname,
  handleError,
  convertToTimeZone,
  sortEventsJSDate,
  getLastPreviousEvent,
  KEYCODE_ENTER,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  KEYCODE_ESCAPE,
  KEYCODE_DELETE,
  getNextUpcomingEvent,
  isEventInThePast,
  isElectron,
} from "../services/commonUsefulFunctions";
import StyleConstants, {
  FADED_GREY_TEXT_COLOR_DARK_MODE,
} from "../services/globalVariables";
import Broadcast from "../broadcasts/broadcast";
import db from "../services/db";
import { constructRequestURL } from "../services/api";
import { determineEventColor } from "../lib/eventFunctions";
import {
  getActiveCalendarsForUser,
  getActiveCalendarsIDsFromAllCalendars,
  getMatchingUserFromEvent,
  getUserCalendarIDFromEmail,
} from "../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import SearchSpinner from "../components/spinners/searchSpinner";
import { getEventAttendees, getEventDescription, getEventID, getEventLocation, getEventTitle, getEventUserCalendarID } from "../services/eventResourceAccessors";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import Fetcher from "../services/fetcher";
import { getUpcomingCalendarUserCalendarIDs, getUserEmail } from "../lib/userFunctions";
import { convertDBElementToString, createCaseInsensitiveRegex } from "../lib/contactFunctions";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { getInputStringFromEvent, lowerCaseAndTrimStringWithGuard } from "../lib/stringFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { createUUID } from "../services/randomFunctions";
import { getCalendarUserCalendarID } from "../services/calendarAccessors";
import classNames from "classnames";
import { getTitleWithBackup } from "../lib/styleFunctions";
import { getMatchingUIUserForEvent } from "../lib/tagsFunctions";

class Search extends Component {
  constructor(props) {
    super(props);

    this.typingTimeout = null;
    this._fetchQuery = null;
    this._fetchID = null;
    const { masterAccount } = props.masterAccount;
    const { allCalendars } = props.allCalendars;
    const { allLoggedInUsers } = props.allLoggedInUsers;

    this.state = {
      inputText: "",
      searchResults: [],
      autoCompleteText: "",
      hoverIndex: 0,
      hoverPreviewSearchResult: null,
      typing: false,
      refs: {},
      loadingEventsFromWeb: false,
      isElectron: isElectron(),
      mainCalendars: getUpcomingCalendarUserCalendarIDs({ masterAccount, allCalendars, allLoggedInUsers }),
      isNoResultsFound: false
    };

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleNextSelectedOption = this.handleNextSelectedOption.bind(this);
    this.handlePreviousSelectedOption =
      this.handlePreviousSelectedOption.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onClickSearchResult = this.onClickSearchResult.bind(this);
    this.getSearchResultsFromDB = this.getSearchResultsFromDB.bind(this);
    this.getSearchResultsFromBackend = this.getSearchResultsFromBackend.bind(this);
    this.googleSearchOnTypingTimeOut =
      this.googleSearchOnTypingTimeOut.bind(this);
    this.closeSearchModal = this.closeSearchModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);

    this._isMounted = false;

    clearTimeout(this.typingTimeout);

    this.typingTimeout = null;
  }

  render() {
    return (
      <div
        className={`search-wrapper ${calculateMarginTopClassname(
          this.props.shouldShowTopBar
        )}`}
      >
        <div className="search-header-wrapper">
          {this.renderSearchInput()}

          <div className="search-close-button-wrapper">
            <CloseButton onClick={this.closeSearchModal} />
          </div>

          <div
            className={isElectron() ? "search-empty-state-wrapper-electron" : "search-empty-state-wrapper"}
          >
            {this.renderSearchContent()}
          </div>
        </div>

        <SidePanel className="side-panel-container-left-border">
          {this.renderEventExtendedPreview()}
        </SidePanel>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderSearchContent() {
    if (this.state.searchResults?.length > 0 && this.state.inputText?.trim()) {
      return this.renderSearchAndSpinner();
    } else if (this.state.isNoResultsFound) {
      return (
        <div className="search-result-empty-wrapper">
          No results found
        </div>
      );
    } else {
      return this.renderEmptyState();
    }
  }
  renderEmptyState() {
    return (
      <div className="search-result-empty-wrapper">
        Search by event title, location, or attendees.
      </div>
    );
  }

  renderSearchInput() {
    return (
      <div className="search-render-input">
        <div className="search-render-input-top">
          {this.state.loadingEventsFromWeb && (
            <SearchSpinner
              color={
                this.props.isDarkMode ? "rgba(255, 255, 255, 0.85)" : "#4E516A"
              }
              marginLeft={42}
              size={14}
            />
          )}
          <div className="search-render-input-layer">
            {/* Auto-complete suggestion underlay */}
            <div className="search-auto-complete">
              <div className="search-input-inline">
                <span className="search-input-text-wrapper">
                  {this.state.autoCompleteText}
                </span>
              </div>
            </div>

            {/* Text input overlay */}
            <div className="search-text-input-overlay-wrapper">
              <textarea
                id="searchTextBox"
                autoFocus={true}
                spellCheck={false}
                wrap="off"
                value={this.state.inputText}
                rows={1}
                onChange={this.handleSearchTextChange}
                placeholder={"Search"}
                className="search-input-text-wrapper"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEventExtendedPreview() {
    if (isEmptyObjectOrFalsey(this.state.hoverPreviewSearchResult)) {
      return null
    }

    return (
      <EventExpandedView
        ignoreMarginTop={true}
        event={this.state.hoverPreviewSearchResult}
        shouldIgnoreBroadcast={true}
        hideToolbar={true}
      />
    );
  }

  renderSearchAndSpinner() {
    return <div>{this.renderSearchResults()}</div>;
  }

  renderSearchResults() {
    const {
      currentTimeZone
    } =  this.props;
    const determineTextColor = (event, index) => {
      const isPastEvent = isEventInThePast({event, currentTimeZone});
      if (this.props.isDarkMode) {
        return isPastEvent ? FADED_GREY_TEXT_COLOR_DARK_MODE : null;
      } else {
        return isPastEvent ? "rgba(32,33,36,0.5)" : null;
      }
    };
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      currentUser
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const { outlookCategories } = this.props.outlookCategoriesStore;

    return this.state.searchResults.map((result, index) => {
      const eventID = getEventID(result);
      const matchingUser = getMatchingUIUserForEvent({
        event: result,
        allCalendars,
        allLoggedInUsers,
        masterAccount,
        currentUser,
      });

      if (eventID) {
        return (
          <div
            ref={this.state.refs[eventID]}
            key={`search_result_${index}`}
            onClick={() => this.onClickSearchResult()}
            onMouseEnter={() => this.handleHover(result, index)}
            className="search-result-bar flex flex-col justify-center"
            style={{
              backgroundColor: this.determineBackgroundColor(index),
              color: determineTextColor(result, index),
            }}
          >
            <div className="search-date mt-0">
              {expandedDateAndTimeString(
                result,
                this.props.format24HourTime,
                this.props.dateFieldOrder
              )}
            </div>

            <div className={classNames("flex flex-row items-center mt-2 font-size-14")}>
              {this.state.mainCalendars?.length > 1 ? (
                <div
                  style={{
                    backgroundColor: determineEventColor({
                      event: result,
                      allCalendars,
                      user: matchingUser,
                      currentUser,
                      allLoggedInUsers,
                      outlookCategories,
                      masterAccount,
                      where: "search",
                    }),
                    width: 8,
                    height: 8,
                    borderRadius: "100%",
                    marginRight: 8,
                  }}
                ></div>
              ) : null}
              <div className="truncate search-left-panel-title-max-width">{getTitleWithBackup(result.summaryUpdatedWithVisibility)}</div>
            </div>
          </div>
        );
      }

      return null;
    });
  }

  //================
  // EVENT HANDLERS
  //================

  handleHover(result, index) {
    this.setState({
      hoverIndex: index,
      hoverPreviewSearchResult: result,
    });
  }

  handleNextSelectedOption(e) {
    hasEventPreventDefault(e);

    let newIndex =
      (this.state.hoverIndex + 1) % this.state.searchResults.length;

    this.setState({
      hoverIndex: newIndex,
      hoverPreviewSearchResult: this.state.searchResults[newIndex],
    });

    this.scrollToOption(newIndex);
  }

  handleKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        this.closeSearchModal();

        return;
      case KEYCODE_UP_ARROW:
        this.handlePreviousSelectedOption(e);

        break;
      case KEYCODE_DOWN_ARROW:
        this.handleNextSelectedOption(e);

        break;
      case KEYCODE_ENTER:
        this.onClickSearchResult();

        break;
      case KEYCODE_DELETE:
        document.activeElement &&
          document.activeElement.id !== "searchTextBox" &&
          this.onDeleteEvent();

        break;
      default:
        break;
    }
  }

  onDeleteEvent() {
    !!document.getElementById("deleteButton") &&
      Broadcast.publish("CLICK_DELETE");
  }

  handlePreviousSelectedOption(e) {
    hasEventPreventDefault(e);

    let newIndex;

    if (this.state.hoverIndex === 0) {
      newIndex = this.state.searchResults.length - 1;
    } else {
      newIndex = this.state.hoverIndex - 1;
    }

    this.setState({
      hoverIndex: newIndex,
      hoverPreviewSearchResult: this.state.searchResults[newIndex],
    });

    this.scrollToOption(newIndex);
  }

  scrollToOption(newIndex, block = null) {
    let ref = this.getRef(newIndex);

    ref &&
      ref.current &&
      ref.current.scrollIntoView({
        behavior: "auto",
        block: block || "nearest",
      });
  }

  getRef(newIndex) {
    const hoveredOption = this.getHoveredOption(newIndex);

    const eventID = getEventID(hoveredOption);
    return (
      eventID &&
      this.state.refs[eventID]
    );
  }

  getHoveredOption(newIndex) {
    return this.state.searchResults[newIndex];
  }

  handleSearchTextChange(e) {
    if (e.nativeEvent && e.nativeEvent.inputType !== "insertLineBreak") {
      this._fetchID = createUUID();
      const text = getInputStringFromEvent(e);
      this.getSearchResultsFromDB(text, this._fetchID);

      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);

        this.typingTimeout = null;
      }

      this.typingTimeout = setTimeout(this.googleSearchOnTypingTimeOut, 200);

      this.setState({
        inputText: text,
        typing: false,
      });
    }
  }

  googleSearchOnTypingTimeOut() {
    if (!this._isMounted) {
      return true;
    }

    this.getSearchResultsFromBackend();
  }

  //=================
  // PRIVATE METHODS
  //=================

  getSearchResultsFromDB(inputText, fetchID) {
    let newState = {};

    if (inputText === "") {
      newState["hoverPreviewSearchResult"] = null;

      this.setState(newState);
    } else {
      // indexdb sometimes stores it as string, sometimes as array
      const searchQuery = lowerCaseAndTrimStringWithGuard(inputText);

      const { allLoggedInUsers } = this.props.allLoggedInUsers;
      let dbPromises = [];
      let allEvents = [];
      allLoggedInUsers.forEach((user) => {
        const calendarIDs = this.getSearchUserCalendarIDs(user);
        if (isEmptyArrayOrFalsey(calendarIDs)) {
          return; // no reason to fetch
        }
        const dbPromise = db
          .fetch(getUserEmail(user))
          .events
          .filter(function (event) {
            const regex = createCaseInsensitiveRegex(searchQuery);
            return (
              regex.test(convertDBElementToString(event?.summary)) ||
              regex.test(convertDBElementToString(event?.location)) ||
              regex.test(convertDBElementToString(event?.attendees))
            );
          })
          .and(function (item) {
            return calendarIDs.includes(item.calendarId);
          })
          .limit(20)
          .toArray()
          .then((response) => {
            if (!this._isMounted || fetchID !== this._fetchID) {
              return;
            }

            const searchResults = response.map((e) => {
              return e.event;
            });

            const filteredEvents = searchResults.filter(
              (e) => e.eventStart && e.eventEnd
            );
            allEvents = allEvents.concat(filteredEvents);
          })
          .catch((err) => {
            handleError(err);
          });

        dbPromises = dbPromises.concat(dbPromise);
      });

      Promise.all(dbPromises).then(() => {
        if (!this._isMounted || fetchID !== this._fetchID) {
          return;
        }

        let sortedEvents = allEvents.sort((a, b) => sortEventsJSDate(a, b));
        let { hoverIndex } = this.getSelectedEvent(sortedEvents);

        let refs = sortedEvents.reduce((result, option) => {
          result[getEventID(option)] = createRef();

          return result;
        }, {});

        newState["hoverPreviewSearchResult"] = sortedEvents[hoverIndex];
        newState["hoverIndex"] = hoverIndex;

        newState["searchResults"] = sortedEvents;
        newState["refs"] = refs;

        this.setState(newState, () => {
          this.scrollToOption(hoverIndex, "center");
        });
      });
    }
  }

  getSearchUserCalendarIDs(user) {
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      currentUser
    } = this.props;

    const activeCalendars = getActiveCalendarsForUser({
      allCalendars,
      userEmail: getUserEmail(user ?? currentUser),
    });
    return activeCalendars.map((calendar) => getCalendarUserCalendarID(calendar));
  }

  determineBackgroundColor(index) {
    if (this.props.isDarkMode) {
      if (this.isSelectedOption(index)) {
        return StyleConstants.darkModeHoverBackgroundColor;
      } else {
        return StyleConstants.darkModeBackgroundColor;
      }
    } else {
      if (this.isSelectedOption(index)) {
        return StyleConstants.searchHoveredColor;
      } else {
        return "white";
      }
    }
  }

  getSearchResultsFromBackend() {
    const query = this.state.inputText;
    this._fetchQuery = query;

    if (!query || query.length === 0) {
      return;
    }

    const searchedWords = query.split(" ");
    const {
      allCalendars
    } = this.props.allCalendars;
    
    const { 
      allLoggedInUsers
    } = this.props.allLoggedInUsers;

    let searchPromises = [];
    let allResults = []; // only test allResults if we actually get results back
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return;
    }

    this.setState({ 
      loadingEventsFromWeb: true, 
      isNoResultsFound: false
    });

    searchedWords.forEach((word) => {
      const path = "events/search";
      const params = {
        orderBy: "updated", // or 'startTime'
      };

      allLoggedInUsers.forEach((user) => {
        const calendarIds = this.getSearchUserCalendarIDs(user);
        if (isEmptyArrayOrFalsey(calendarIds)) {
          return; // no reason to fetch
        }
        const body = {
          query: word,
          calendarIds,
        };
        const payloadData = {
          body: JSON.stringify(body),
        };
        const queryParams = constructQueryParams(params);
        const url = constructRequestURL(path, true) + `?${queryParams}`;

        const searchPromise = Fetcher.post(url, payloadData, true, getUserEmail(user))
          .then((response) => {
            if (!this._isMounted || isEmptyObjectOrFalsey(response) || this._fetchQuery !== query) {
              return;
            }

            if (response.results?.length >= 0) {
              this.handleMultipleCalendarResults({
                response: response.results, 
                v2: false,
                where: "response_results"
              });
              allResults = allResults.concat(response.results);
            } else if (response.events?.length >= 0) {
              this.handleMultipleCalendarResults({
                response: response.events, 
                v2: true,
                where: "response_events"
              });
              allResults = allResults.concat(response.events);
            }
          }).catch((err) => {
            handleError(err);
          })
          searchPromises = searchPromises.concat(searchPromise);
      });
    });

    Promise.all(searchPromises)
      .then(() => {
        if (!this._isMounted || this._fetchQuery !== query) {
          return;
        }

        if (allResults?.length === 0) {
          this.setState({ isNoResultsFound: true, loadingEventsFromWeb: false });
        }
      })
      .catch((err) => {});
  }

  formatSearchEventsV1(response) {
    let formattedEvents = [];

    response.forEach((calendarObject) => {
      Object.keys(calendarObject).forEach((k) => {
        calendarObject[k]?.forEach((e) => {
          formattedEvents = formattedEvents.concat(
            formatEventForReactBigCalendar({
              event: e, 
              currentTimeZone: this.props.currentTimeZone, 
              calendarId: k
            })
          );
        });
      });
    });

    return formattedEvents;
  }

  formatSearchEventsV2(events) {
    return events.map(
      (event) => formatEventForReactBigCalendar({
        event, 
        currentTimeZone: this.props.currentTimeZone, 
        calendarId: getEventUserCalendarID(event)
      })
    );
  }

  regexSearchForText(searchText, inputTextArray) {
    // if inputText is "ken chen anniversary" and the searchText is "ken anniversary", then we want to return true
    if (!inputTextArray || inputTextArray.length === 0) {
      return false;
    }

    for (let i = 0; i < inputTextArray.length; i++) {
      if (!searchText.includes(inputTextArray[i])) {
        return false;
      }
    }

    return true;
  }

  getAttendeeName(attendee) {
    if (isEmptyObjectOrFalsey(attendee)) {
      return ""
    };
    const {
      email,
      displayName
    } = attendee;

    let returnString = "";
    if (displayName) {
      returnString += displayName.toLowerCase() + " ";
    }

    if (email) {
      returnString += email.toLowerCase();
    }

    return returnString;
  }

  filterOutEvents(events) {
    if (!events || events.length === 0) {
      return [];
    }

    const {
      inputText
    } = this.state;
    if (!inputText || inputText.trim().length === 0) {
      return [];
    }

    const loweredInputText = inputText?.toLowerCase() ?? "";
    const loweredInputTextArray = loweredInputText.split(" ");
    let filteredEvents = [];
    events.forEach((e) => {
      const title = getEventTitle(e)?.toLowerCase() ?? "";
      if (this.regexSearchForText(title, loweredInputTextArray)) {
        filteredEvents = filteredEvents.concat(e);
        return;
      }

      const attendeesString = getEventAttendees(e)?.map((attendee) => this.getAttendeeName(attendee)).join(" ") ?? "";
      if (this.regexSearchForText(attendeesString, loweredInputTextArray)) {
        filteredEvents = filteredEvents.concat(e);
        return;
      }

      const description = getEventDescription(e)?.toLowerCase() ?? "";
      if (this.regexSearchForText(description, loweredInputTextArray)) {
        filteredEvents = filteredEvents.concat(e);
        return;
      }

      const location = getEventLocation(e)?.toLowerCase() ?? "";
      if (this.regexSearchForText(location, loweredInputTextArray)) {
        filteredEvents = filteredEvents.concat(e);
        return;
      }
    });

    return filteredEvents;
  }

  handleMultipleCalendarResults({response, v2 = false, where}) {
    const formattedEvents = v2 ? this.formatSearchEventsV2(response) : this.formatSearchEventsV1(response);
    const existingSearchEvents = this.state.searchResults || [];
    let existingSearchResultsId = [];
    existingSearchEvents.forEach((e) => {
      if (e.uniqueEtag) {
        existingSearchResultsId = existingSearchResultsId.concat(e.uniqueEtag);
      }
    });
    const existingEventsSet = new Set(existingSearchResultsId);
    const filteredEvents = this.filterOutEvents(formattedEvents).filter(
      (e) =>
        e.eventStart &&
        e.eventEnd &&
        !existingEventsSet.has(e.uniqueEtag)
    );

    const allSearchedEvents = existingSearchEvents.concat(filteredEvents);
    const sortedEvents = allSearchedEvents.sort((a, b) => sortEventsJSDate(a, b));

    const refs = sortedEvents.reduce((result, option) => {
      const eventID = getEventID(option);
      if (eventID) {
        result[eventID] = createRef();
      }

      return result;
    }, {});

    const newState = { loadingEventsFromWeb: false };

    const { hoverIndex } = this.getSelectedEvent(sortedEvents);

    newState["hoverPreviewSearchResult"] = sortedEvents[hoverIndex];
    newState["hoverIndex"] = hoverIndex;

    newState["searchResults"] = sortedEvents;
    newState["refs"] = refs;

    this.setState(newState, () => {
      this.scrollToOption(hoverIndex, "center");
    });
  }

  handlePrimaryCalendarSearchResults(response, user) {
    if (isEmptyObjectOrFalsey(response) || !response.events) {
      return;
    }
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;

    let events = response.events;

    let searchResults = this.state.searchResults || [];

    let searchResultsId = [];
    searchResults.forEach((r) => {
      const eventID = getEventID(r);
      if (eventID) {
        searchResultsId = searchResultsId.concat(eventID);
      }
    });

    events = events.filter((e) => !searchResultsId.includes(e.id));

    const userCalendarID = getUserCalendarIDFromEmail({
      email: getUserEmail(user),
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
    let formattedEvents = events.map((e) => {
      return formatEventForReactBigCalendar({
        event: e,
        currentTimeZone: this.props.currentTimeZone,
        calendarId: userCalendarID
      });
    });

    let filteredEvents = formattedEvents.filter(
      (e) => e.eventStart && e.eventEnd
    );

    searchResults = searchResults.concat(filteredEvents);
    let sortedEvents = searchResults.sort((a, b) => sortEventsJSDate(a, b));

    let refs = sortedEvents.reduce((result, option) => {
      const eventID = getEventID(option);
      if (eventID) {
        result[eventID] = createRef();
      }

      return result;
    }, {});

    let newState = { loadingEventsFromWeb: false };

    let { hoverIndex } = this.getSelectedEvent(sortedEvents);

    newState["hoverPreviewSearchResult"] = sortedEvents[hoverIndex];
    newState["hoverIndex"] = hoverIndex;

    newState["searchResults"] = sortedEvents;
    newState["refs"] = refs;

    this.setState(newState, () => {
      this.scrollToOption(hoverIndex, "center");
    });
  }

  getSelectedEvent(events) {
    let eventsIndex = events.map((e) => e.uniqueEtag);
    let upcomingEvent = getNextUpcomingEvent(events);
    let updatedHoverIndex = 0;
    if (upcomingEvent) {
      updatedHoverIndex = eventsIndex.indexOf(upcomingEvent.uniqueEtag);
      return {
        hoverIndex: updatedHoverIndex,
        event: upcomingEvent,
      };
    } else {
      // no upcoming events -> take last event
      let previousEvent = getLastPreviousEvent(events);
      let updatedHoverIndex = 0;
      if (previousEvent) {
        updatedHoverIndex = eventsIndex.indexOf(previousEvent.uniqueEtag);
        return {
          hoverIndex: updatedHoverIndex,
          event: upcomingEvent,
        };
      }

      return {
        hoverIndex: 0,
        event: null,
      };
    }
  }

  onClickSearchResult() {
    if (
      !isEmptyObjectOrFalsey(this.state.hoverPreviewSearchResult) &&
      this.state.inputText &&
      this.state.inputText.length > 0
    ) {
      const date = convertToTimeZone(
        this.state.hoverPreviewSearchResult.defaultStartTime,
        { timeZone: this.props.currentTimeZone }
      );
      Broadcast.publish("NAVIGATE_DATE", date);

      mainCalendarBroadcast.publish("SET_PREVIEW_EVENT", this.state.hoverPreviewSearchResult);

      this.closeSearchModal();

      const {
        allCalendars
      } = this.props.allCalendars;
      const {
        currentUser
      } = this.props;
      if (
        !getActiveCalendarsIDsFromAllCalendars({
          allCalendars,
          currentUserEmail: getUserEmail(currentUser)
        }).includes(this.state.hoverPreviewSearchResult.calendarId)
      ) {
        Broadcast.publish(
          "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
          this.state.hoverPreviewSearchResult.calendarId
        );
      }

      this.props.history.push("/home/expanded");
    }
  }

  closeSearchModal() {
    Broadcast.publish("TOGGLE_SEARCH");
  }

  isSelectedOption(index) {
    return index === this.state.hoverIndex;
  }
}

function mapStateToProps(state) {
  let {
    currentTimeZone,
    currentUser,
    isDarkMode,
    format24HourTime,
    dateFieldOrder,
  } = state;

  return {
    currentTimeZone,
    currentUser,
    isDarkMode,
    format24HourTime,
    dateFieldOrder,
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const outlookCategoriesStore = useOutlookCategoriesStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
      outlookCategoriesStore={outlookCategoriesStore}
    />
  );
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withStore(Search)));
