import React from "react";
import CustomButton from "../../../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../../../services/globalVariables";

export default function TagsSaveCancelButton({
  isOpen,
  onClickCancel,
  onClickSave,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex items-center justify-end mt-5">
      <CustomButton
        buttonType={WHITE_BUTTON}
        onClick={onClickCancel}
        label="Cancel"
        addPaddingToRight={true}
      />

      <CustomButton
        buttonType={BLUE_BUTTON}
        onClick={onClickSave}
        label="Save"
      />
    </div>
  );
}
