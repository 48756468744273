import React from "react";

export default function Btv({className = ""}) {
  return (
    <svg width="220" height="52" viewBox="0 0 220 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_145_745)">
        <path d="M63.8794 16.6645V24.0551L67.2519 24.0228C70.1565 23.9986 70.6809 23.9744 71.0924 23.8533C72.7141 23.3773 73.8598 22.2639 74.2471 20.8116C74.4246 20.1662 74.4004 19.085 74.2067 18.4557C73.9243 17.5521 73.3596 16.9147 72.222 16.2531C72.0848 16.1724 72.0929 16.1482 72.351 15.9223C73.2143 15.1639 73.6097 13.6551 73.2789 12.3884C72.932 11.0733 72.0041 10.0728 70.6164 9.51609C70.1403 9.32245 70.0839 9.32245 67.0099 9.29825L63.8794 9.27404V16.6645ZM69.8741 11.6945C70.3259 11.9204 70.7858 12.4207 70.8988 12.8241C71.1166 13.6067 70.923 14.4135 70.4066 14.9057C69.8902 15.3898 69.6563 15.4462 67.8974 15.4785L66.2999 15.5108V13.5421C66.2999 12.4529 66.3241 11.5412 66.3644 11.5089C66.3967 11.4767 67.1148 11.4605 67.9539 11.4767C69.3658 11.5009 69.5191 11.5251 69.8741 11.6945ZM70.6244 17.7296C71.2134 17.9313 71.8105 18.5606 71.9476 19.1173C72.2058 20.1823 71.7621 21.1101 70.7777 21.562C70.3985 21.7395 70.2614 21.7475 68.3411 21.7717L66.2999 21.7959V19.6982V17.5924H68.2604C69.8902 17.5924 70.2936 17.6166 70.6244 17.7296Z" fill="white"/>
        <path d="M125.295 10.4278L125.319 11.5815L127.441 11.6057L129.555 11.6219V17.8344V24.0469H130.806H132.056V17.8344V11.6219H134.194H136.332V10.452V9.28206H130.806H125.271L125.295 10.4278Z" fill="white"/>
        <path d="M89.0524 12.1867V13.6389H87.9632H86.874V14.6475V15.656H87.9632H89.0524V18.3347C89.0524 21.1908 89.1089 21.7556 89.4559 22.4333C90.061 23.6194 91.2873 24.1922 93.0059 24.0954C93.4738 24.0712 93.9499 24.0228 94.079 23.9986L94.2968 23.9501V22.9174V21.8766L94.0386 21.9573C93.8934 21.9976 93.4819 22.0299 93.1269 22.0299C92.2717 22.0299 91.836 21.8282 91.5778 21.3038C91.4003 20.9568 91.3922 20.8842 91.3922 18.3024V15.656H92.6025H93.8127V14.6475V13.6389H92.6025H91.3922V12.1867V10.7344H90.2223H89.0524V12.1867Z" fill="white"/>
        <path d="M96.6363 12.1866V13.6388H95.7488H94.8613V14.6474V15.6559H95.7408H96.6283L96.6525 18.7057L96.6767 21.7555L96.9833 22.3767C97.4351 23.2804 98.1451 23.8129 99.202 24.0388C99.7345 24.1518 100.816 24.1437 101.397 24.0227L101.84 23.9258L101.865 22.885C101.881 21.9169 101.873 21.8523 101.744 21.9088C101.663 21.9491 101.243 21.9895 100.816 22.0137C100.13 22.046 99.9847 22.0298 99.67 21.8684C99.4522 21.7636 99.2343 21.578 99.1214 21.4005C98.9358 21.11 98.9358 21.0536 98.9116 18.3749L98.8874 15.6559H100.388H101.881V14.6474V13.6388H100.388H98.8954V12.1866V10.7343H97.7659H96.6363V12.1866Z" fill="white"/>
        <path d="M80.1692 13.4855C79.0073 13.655 78.0472 14.1391 77.2323 14.962C76.6353 15.5672 76.2722 16.1642 75.9818 17.0275C75.7316 17.7778 75.6671 19.2785 75.8527 20.1257C76.2884 22.0621 77.8052 23.6193 79.7012 24.0791C80.4516 24.2647 81.7667 24.2486 82.5493 24.0469C84.1226 23.6435 85.4781 22.5058 85.9944 21.1423L86.1477 20.7389L84.9536 20.747H83.7676L83.5256 21.0858C82.9285 21.9088 82.154 22.2315 80.9115 22.175C80.2741 22.1508 80.0562 22.1024 79.677 21.9169C78.8056 21.5054 78.1521 20.634 78.0311 19.7465L77.9746 19.3673H82.0975H86.2284L86.2768 19.1575C86.3575 18.8429 86.1639 17.5439 85.938 16.8661C85.1231 14.4457 82.8156 13.0983 80.1692 13.4855ZM82.2104 15.5994C82.9204 15.9464 83.5094 16.6967 83.7676 17.5681L83.816 17.7536H80.9518C79.1606 17.7536 78.0795 17.7214 78.0795 17.673C78.0795 17.4228 78.4587 16.6644 78.7411 16.3417C79.3866 15.6075 80.0804 15.317 81.1132 15.3574C81.6295 15.3816 81.8635 15.43 82.2104 15.5994Z" fill="white"/>
        <path d="M107.286 13.4775C105.584 13.7438 104.164 14.7765 103.454 16.253C102.123 19.0123 103.075 22.28 105.6 23.6031C107.061 24.3696 109.11 24.418 110.635 23.7242C111.829 23.1836 112.845 22.0783 113.209 20.9164L113.265 20.7389H112.103H110.941L110.627 21.1585C110.385 21.4812 110.167 21.6587 109.755 21.8685C109.215 22.1428 109.174 22.1509 108.263 22.1509C107.416 22.1509 107.278 22.1267 106.867 21.933C105.979 21.5215 105.286 20.626 105.148 19.7062L105.092 19.3673H109.271H113.443L113.394 18.5766C113.338 17.5923 113.184 17.0114 112.757 16.1481C112.297 15.196 111.522 14.4215 110.586 13.9777C110.215 13.8002 109.739 13.6147 109.529 13.5663C108.989 13.4372 107.811 13.3888 107.286 13.4775ZM109.32 15.5914C109.57 15.7043 109.917 15.9464 110.094 16.1239C110.409 16.4386 110.78 17.1002 110.885 17.5278L110.933 17.7537H108.053H105.181L105.229 17.5681C105.665 16.0997 106.762 15.3009 108.255 15.3574C108.698 15.3816 108.989 15.4381 109.32 15.5914Z" fill="white"/>
        <path d="M139.761 13.5179C138.962 13.6631 138.349 13.9132 137.663 14.3731C136.905 14.8814 136.34 15.5188 135.929 16.3418C135.485 17.2212 135.34 17.8667 135.34 18.8429C135.348 20.392 135.808 21.5055 136.897 22.6027C138.01 23.7162 139.188 24.2083 140.77 24.2083C142.351 24.2083 143.529 23.7162 144.643 22.6027C145.732 21.5055 146.192 20.3678 146.192 18.7945C146.192 16.1481 144.376 13.9939 141.738 13.5098C140.996 13.3807 140.504 13.3807 139.761 13.5179ZM142.085 15.8335C142.343 15.9545 142.722 16.2288 142.94 16.4547C144.505 18.0603 144.062 20.8277 142.085 21.804C140.778 22.4494 139.067 22.0218 138.261 20.8519C137.155 19.2383 137.575 16.9227 139.14 16.0029C139.753 15.6398 140.036 15.5753 140.85 15.5995C141.488 15.6156 141.698 15.656 142.085 15.8335Z" fill="white"/>
        <path d="M152.711 13.4856C152.089 13.6066 151.387 14.01 150.944 14.4941L150.532 14.946V14.2924V13.6389H149.362H148.192V18.8429V24.0469H149.362H150.532V21.0778C150.532 17.9231 150.564 17.5681 150.944 16.8259C151.161 16.4063 151.621 15.9303 152.025 15.7124C152.275 15.5753 152.493 15.5349 152.969 15.5349C153.525 15.5349 153.614 15.5592 153.937 15.777C154.381 16.0917 154.703 16.721 154.808 17.4713C154.849 17.7779 154.889 19.3916 154.889 21.0375V24.0469H156.019H157.148V21.1585C157.148 17.5359 157.229 17.076 158.011 16.2127C158.471 15.7044 158.851 15.543 159.585 15.5349C160.141 15.5349 160.23 15.5592 160.553 15.777C160.997 16.0917 161.319 16.721 161.424 17.4713C161.465 17.7779 161.505 19.3916 161.505 21.0375V24.0469H162.635H163.764V20.6179C163.764 17.8263 163.74 17.0679 163.635 16.5596C163.288 14.8169 162.223 13.7196 160.642 13.4695C159.197 13.2355 157.632 13.9132 156.906 15.0831L156.68 15.4381L156.543 15.1638C156.462 15.0186 156.204 14.6878 155.962 14.4377C155.163 13.5986 153.953 13.2436 152.711 13.4856Z" fill="white"/>
        <path d="M170.251 13.4776C168.153 13.7842 166.378 15.3655 165.813 17.431C165.588 18.2701 165.62 19.6336 165.886 20.4566C166.644 22.756 168.662 24.2083 171.098 24.2083C172.066 24.2083 172.728 24.055 173.599 23.6274C174.543 23.1756 175.455 22.2719 175.923 21.328C176.391 20.4001 176.544 19.6578 176.496 18.5363C176.447 17.5117 176.197 16.6806 175.681 15.8819C174.559 14.123 172.397 13.1629 170.251 13.4776ZM172.421 15.8416C173.026 16.1401 173.543 16.6645 173.866 17.3342C174.124 17.8505 174.132 17.907 174.132 18.8429C174.132 19.7708 174.124 19.8353 173.874 20.3436C173.204 21.7072 171.679 22.401 170.251 21.9895C168.895 21.6023 168.064 20.5211 167.976 19.0527C167.919 17.9877 168.177 17.2293 168.839 16.5112C169.501 15.8093 170.122 15.5672 171.187 15.5995C171.84 15.6156 172.034 15.656 172.421 15.8416Z" fill="white"/>
        <path d="M197.046 13.5098C195.077 13.8971 193.568 15.18 192.915 17.0437C192.632 17.8183 192.56 19.3028 192.761 20.0935C193.245 22.0299 194.754 23.5629 196.65 24.047C197.473 24.2567 198.627 24.2648 199.442 24.055C200.458 23.7888 201.152 23.3934 201.935 22.6027C203.016 21.5216 203.484 20.392 203.492 18.8429C203.492 17.8667 203.339 17.2454 202.887 16.3176C202.258 15.0267 200.926 13.9697 199.466 13.5986C198.764 13.4211 197.707 13.3807 197.046 13.5098ZM199.442 15.8819C201.79 17.0357 201.741 20.6906 199.353 21.812C197.699 22.5947 195.771 21.7798 195.125 20.037C194.883 19.3754 194.891 18.2217 195.142 17.5682C195.472 16.7129 196.053 16.1239 196.892 15.7689C197.304 15.5995 197.473 15.5753 198.135 15.5914C198.821 15.6156 198.966 15.6479 199.442 15.8819Z" fill="white"/>
        <path d="M119.413 13.7277C118.905 13.9213 118.163 14.5748 117.912 15.0428L117.735 15.3736L117.711 14.5022L117.687 13.6389H116.565H115.436V18.8429V24.0469H116.565H117.695V21.4812C117.695 20.0774 117.735 18.6574 117.775 18.3266C117.872 17.5923 118.138 16.9953 118.582 16.5193C119.163 15.8819 119.841 15.6882 120.946 15.8415L121.414 15.9142L121.39 14.7523L121.366 13.5986L120.599 13.5824C119.994 13.5663 119.744 13.5986 119.413 13.7277Z" fill="white"/>
        <path d="M182.652 13.6873C181.982 13.9294 181.409 14.3973 181.038 15.0105L180.869 15.2929V14.4619V13.6389H179.699H178.529V18.8429V24.0469H179.699H180.861L180.885 20.9568C180.909 17.9554 180.917 17.8667 181.095 17.4148C181.345 16.8017 181.885 16.2046 182.41 15.9868C182.87 15.7851 183.346 15.7447 184.04 15.8415L184.508 15.9142L184.483 14.7523L184.459 13.5986L183.733 13.5824C183.241 13.5663 182.894 13.6066 182.652 13.6873Z" fill="white"/>
        <path d="M190.293 13.6712C189.558 13.9374 188.945 14.4457 188.623 15.0509L188.453 15.3736V14.5022V13.6389H187.283H186.113V18.8429V24.0469H187.275H188.445L188.469 20.9568C188.493 17.9554 188.501 17.8586 188.679 17.4148C189.171 16.1885 190.317 15.5672 191.584 15.8415L192.084 15.9545V14.7523V13.5582H191.341C190.93 13.5663 190.462 13.6147 190.293 13.6712Z" fill="white"/>
        <path d="M204.105 13.6632C204.105 13.6954 207.107 22.6592 207.413 23.5387L207.591 24.047H208.664H209.737L210.859 20.6987C211.464 18.8591 211.996 17.3826 212.02 17.4149C212.053 17.4472 212.577 18.9479 213.19 20.7632L214.304 24.0551L215.385 24.0308L216.474 24.0066L218.193 18.8833C219.137 16.0675 219.911 13.7358 219.919 13.6954C219.919 13.6632 219.379 13.639 218.709 13.639H217.507L217.354 14.1392C216.781 16.0352 215.28 20.6583 215.248 20.6583C215.224 20.6583 214.683 19.077 214.046 17.1486L212.892 13.639H212.02H211.141L210.262 16.2369C209.769 17.6731 209.237 19.2545 209.067 19.7547C208.906 20.263 208.745 20.6422 208.712 20.6018C208.68 20.5696 208.164 18.9882 207.575 17.0841L206.494 13.639H205.3C204.646 13.639 204.105 13.647 204.105 13.6632Z" fill="white"/>
        <path d="M15.8008 30.5338L5.43311 40.9015L5.63481 41.1758C6.48198 42.378 8.47483 44.4677 9.82223 45.5892C13.5175 48.647 17.8582 50.5592 22.7717 51.2934C24.2644 51.5193 28.3308 51.4951 29.8315 51.2611C33.7526 50.6399 37.1009 49.3651 40.2798 47.2754C42.6922 45.6941 45.0885 43.4511 46.6537 41.313L46.9603 40.8934L36.6007 30.5338C30.9045 24.8377 26.225 20.1742 26.2008 20.1742C26.1765 20.1742 21.497 24.8377 15.8008 30.5338ZM29.9928 33.5271L33.7446 37.2869L33.5428 37.4402C33.4299 37.529 32.9216 37.8114 32.4052 38.0776C28.5486 40.0786 23.8529 40.0786 19.9963 38.0776C19.4799 37.8114 18.9716 37.529 18.8587 37.4402L18.6569 37.2869L22.4087 33.5271C24.4741 31.4617 26.1765 29.7754 26.2008 29.7754C26.225 29.7754 27.9274 31.4617 29.9928 33.5271Z" fill="white"/>
        <path d="M62.3707 27.7341C62.3949 27.8148 63.7988 31.0905 65.4931 35.0197C67.1875 38.949 68.5913 42.2085 68.6155 42.265C68.6478 42.3376 68.8334 42.3618 69.3094 42.3457L69.9549 42.3215L73.0853 35.0278C74.8119 31.0098 76.2239 27.6938 76.2239 27.6615C76.2239 27.6212 75.8366 27.605 75.3687 27.6131L74.5134 27.6373L71.9235 33.6643C70.4954 36.9722 69.3094 39.6751 69.2852 39.659C69.261 39.6428 68.083 36.9238 66.663 33.6159L64.0812 27.605L63.2017 27.5969C62.403 27.5969 62.3304 27.6131 62.3707 27.7341Z" fill="white"/>
        <path d="M100.993 30.5016V31.9539H99.9042H98.8149V32.6397V33.3255H99.9042H100.993V36.5285C100.993 40.014 101.042 40.5304 101.437 41.192C101.687 41.6277 102.042 41.9262 102.607 42.1924C103.002 42.378 103.148 42.4022 103.938 42.4022C104.43 42.4022 104.979 42.3538 105.173 42.2973L105.512 42.1924V41.5389C105.512 40.9015 105.512 40.8935 105.334 40.9499C105.229 40.9822 104.826 41.0226 104.439 41.0468C103.252 41.1194 102.704 40.7724 102.526 39.8607C102.478 39.6267 102.446 38.0857 102.446 36.3833V33.3255H103.979H105.512V32.6397V31.9539H103.979H102.446V30.5016V29.0493H101.72H100.993V30.5016Z" fill="white"/>
        <path d="M80.4595 31.7602C78.2811 32.1152 76.7481 33.4868 76.1672 35.5845C76.0623 35.9799 76.022 36.3833 76.0301 37.1982C76.0381 38.1502 76.0623 38.3681 76.2479 38.9328C76.6997 40.3044 77.6034 41.3937 78.7571 41.9584C79.1202 42.1359 79.6285 42.3376 79.8947 42.4022C80.5079 42.5555 81.8392 42.5555 82.5169 42.3941C83.8562 42.0795 84.9616 41.2404 85.5264 40.1027C85.9701 39.2233 85.9621 39.2152 85.123 39.2152H84.413L84.1306 39.6671C83.2269 41.1113 81.1937 41.6196 79.5317 40.8127C78.4586 40.2883 77.6195 39.1346 77.5388 38.0534L77.4904 37.5209H81.7908H86.0911L86.0427 36.6899C85.9863 35.7056 85.833 35.1004 85.4538 34.3178C84.9051 33.2044 83.8643 32.3169 82.6783 31.9458C82.1619 31.7844 80.9436 31.6876 80.4595 31.7602ZM82.6379 33.4384C83.5254 33.8741 84.0822 34.576 84.4049 35.6571C84.4775 35.8992 84.534 36.1493 84.534 36.2058C84.534 36.2945 83.8804 36.3107 81.0566 36.3107H77.5711L77.6276 35.9637C77.7082 35.4393 78.2085 34.4953 78.6361 34.0677C79.338 33.3738 80.1933 33.0672 81.3309 33.1157C81.9521 33.1399 82.1135 33.1802 82.6379 33.4384Z" fill="white"/>
        <path d="M92.4733 31.8247C91.5212 32.0425 90.7628 32.5347 90.1577 33.3254L89.9398 33.6078V32.7768V31.9538H89.2137H88.4875V37.1578V42.3618H89.2137H89.9398V39.4976C89.9398 36.0121 90.0286 35.4231 90.666 34.4953C91.3518 33.4948 92.3119 33.0188 93.4172 33.1075C94.4096 33.1882 95.2165 33.8014 95.6037 34.7696C95.7812 35.2134 95.7893 35.294 95.8135 38.7876L95.8377 42.3618H96.5639H97.29L97.2658 38.6262C97.2416 35.0197 97.2335 34.8826 97.056 34.3662C96.814 33.6401 96.3057 32.9058 95.7812 32.5105C94.9421 31.8731 93.5383 31.5827 92.4733 31.8247Z" fill="white"/>
        <path d="M129.716 31.7602C128.32 32.0023 127.481 32.3976 126.683 33.1964C126.255 33.624 126.029 33.9386 125.779 34.455C125.303 35.4232 125.166 36.0122 125.166 37.1175C125.166 38.2067 125.295 38.7796 125.763 39.772C126.368 41.0306 127.619 42.0472 129.03 42.4022C130.168 42.6927 131.846 42.491 132.887 41.9423C133.581 41.5793 134.388 40.7563 134.702 40.1028C135.146 39.1669 135.162 39.2153 134.323 39.2153H133.597L133.25 39.7155C132.548 40.716 131.62 41.1839 130.321 41.1759C129.474 41.1678 128.909 40.9984 128.224 40.5385C127.312 39.9414 126.658 38.7877 126.65 37.7791V37.5209H130.926H135.203V36.8513C135.203 35.9396 135.025 35.1812 134.63 34.3905C134.033 33.1641 133.008 32.2927 131.733 31.9216C131.29 31.7844 130.095 31.6957 129.716 31.7602ZM131.201 33.2044C132.314 33.4949 133.194 34.3824 133.541 35.5765C133.775 36.3914 134.146 36.3107 130.2 36.3107C127.142 36.3107 126.731 36.2946 126.731 36.1897C126.731 35.9396 127.07 35.0521 127.304 34.6809C128.119 33.4142 129.692 32.8091 131.201 33.2044Z" fill="white"/>
        <path d="M139.664 31.8005C137.897 32.099 136.841 33.4061 137.131 34.9552C137.244 35.5926 137.486 36.0525 137.922 36.4962C138.414 36.9965 139.027 37.2788 140.407 37.6419C141.028 37.8113 141.698 38.0211 141.899 38.1099C142.448 38.3681 142.835 38.8037 142.932 39.2717C143.174 40.3932 142.416 41.1435 140.955 41.2161C139.632 41.2888 138.64 40.7562 138.293 39.7881L138.148 39.3766H137.429H136.711L136.76 39.7881C136.865 40.6514 137.494 41.5227 138.349 41.9907C138.971 42.3296 139.874 42.5232 140.802 42.5232C142.9 42.5232 144.118 41.7002 144.4 40.0866C144.578 39.0781 144.255 38.1018 143.529 37.4241C142.98 36.9077 142.674 36.7705 141.141 36.351C139.221 35.8346 138.785 35.5764 138.591 34.8503C138.406 34.1726 138.809 33.4706 139.543 33.1882C140.092 32.9785 140.939 32.9946 141.472 33.2367C141.94 33.4464 142.416 33.9144 142.593 34.342L142.706 34.6163H143.432H144.158L144.11 34.3904C143.852 33.3415 143.231 32.5509 142.327 32.1313C141.496 31.7521 140.616 31.6391 139.664 31.8005Z" fill="white"/>
        <path d="M122.769 31.9941C121.955 32.2927 121.277 32.8655 120.914 33.5674L120.72 33.9305L120.696 32.9381L120.672 31.9538H119.954H119.228V37.1578V42.3618H119.946H120.664L120.704 39.2717C120.736 35.8992 120.752 35.7943 121.245 34.8664C121.567 34.2694 122.076 33.7853 122.673 33.511C123.068 33.3335 123.197 33.3093 123.794 33.3415L124.472 33.3738V32.6235V31.8731L123.77 31.8812C123.375 31.8812 122.931 31.9296 122.769 31.9941Z" fill="white"/>
        <path d="M107.383 35.6006C107.416 38.7069 107.44 39.3282 107.553 39.7235C108.069 41.5066 109.392 42.5232 111.2 42.5232C112.539 42.5232 113.652 41.9342 114.403 40.8289C114.532 40.6352 114.54 40.6514 114.54 41.4905L114.548 42.3618H115.274H116V37.1578V31.9538H115.282H114.564L114.524 35.0359C114.5 37.5048 114.467 38.2067 114.37 38.5456C113.919 40.0382 113.039 40.9499 111.813 41.1597C110.901 41.321 109.901 40.9015 109.392 40.1269C108.908 39.4089 108.9 39.2878 108.9 35.4474V31.9538H108.126H107.351L107.383 35.6006Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_145_745">
          <rect width="219.456" height="51.314" fill="white" transform="translate(0.543945 0.245667)"/>
        </clipPath>
      </defs>
    </svg>

  );
}
