import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  OpenLink,
  isMac,
} from "../../services/commonUsefulFunctions";
import {
  BLUE_BUTTON,
  DOWNLOAD_INTEL_DESKTOP_LINK,
  DOWNLOAD_M1_DESKTOP_LINK,
  DOWNLOAD_PC_DESKTOP_LINK,
  VIMCAL_IOS_QR_CODE,
} from "../../services/globalVariables";
import Mousetrap from "mousetrap";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { isMacIntelSync } from "../../services/appFunctions";
import { format } from "date-fns";
import CustomButtonV2 from "../buttons/customButtonV2";
import WindowsIcon from "../icons/windowsIcon";
import AppleIcon from "../icons/appleIcon";
import { Check } from "react-feather";
import OnboardingSaveButton from "../onboarding/onboardingSaveButton";

export default function DownloadPage({ onClickNext, step }) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickNext);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, []);

  const onClickDownload = () => {
    if (isMac()) {
      if (isMacIntelSync()) {
        onClickDownloadMacIntel();
        return;
      }
      onClickDownloadSilicon();
    } else {
      onClickDownloadWindows();
    }
  };

  const getLabel = () => {
    if (isMac()) {
      return (
        <div className="flex items-center gap-2">
          <AppleIcon />
          Download for Mac
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <WindowsIcon />
        Download for Windows
      </div>
    );
  };

  const imageSrc = process.env.PUBLIC_URL + `/day${format(new Date(), "d")}.png`;
  return (
    <div className="onboarding-container default-font-size">
      <img src={imageSrc} alt="Download Vimcal on all your devices" className="w-14"/>
      <div className="font-size-18 mt-2">Download Vimcal</div>
      <div className="soft-container-border p-6 rounded-xl mt-4">
        <CustomButtonV2
          removeDefaultPadding={true}
          className="px-10 py-5"
          label={getLabel()}
          buttonType={BLUE_BUTTON}
          onClick={() => {
            onClickDownload();
          }}
        />
        <div className="secondary-text-color flex flex-col gap-2 mt-4 pl-8">
          <div className="flex items-center gap-2">
            <Check size={12} />
          Free
          </div>
          <div className="flex items-center gap-2">
            <Check size={12} />
          Most responsive
          </div>
          <div className="flex items-center gap-2">
            <Check size={12} />
          Fast
          </div>
          {isMac() ?
            <div className="flex items-center gap-2">
              <Check size={12} />
          Menu bar for upcoming
            </div> : null}
        </div>
      </div>

      <div className="mt-5 mb-2 secondary-text-color">Also available on mobile</div>
      <div className="soft-container-border rounded-xl">
        <div className="p-4 flex items-center" style={{gap: "72px"}}>
          <div>
            <div className="flex items-center gap-2 primary-text-color">
              <AppleIcon />
              <div>Vimcal for iOS</div>
            </div>
            <div className="secondary-text-color mt-2">Scan to download</div>
          </div>

          <img
            alt=""
            width="100px"
            height="100px"
            className=""
            src={VIMCAL_IOS_QR_CODE}
          />
        </div>
      </div>
      <OnboardingSaveButton
        buttonText="Continue"
        onClick={onClickNext}
        marginTopOverride={32}
        className="flex-shrink-0"
      />
    </div>
  );
}

function onClickDownloadSilicon() {
  OpenLink(DOWNLOAD_M1_DESKTOP_LINK);
}

function onClickDownloadMacIntel() {
  OpenLink(DOWNLOAD_INTEL_DESKTOP_LINK);
}

function onClickDownloadWindows() {
  OpenLink(DOWNLOAD_PC_DESKTOP_LINK);
}
