import React, { Component } from "react";
import {
  constructQueryParams,
  getEmailBasedOnCalendarId,
  getGoogleEventId,
  isEditable,
} from "../services/commonUsefulFunctions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EventExpandedView from "../views/eventExpandedView";
import ColorSelector from "./colorSelector/colorSelector";
import {
  HIDDEN_EVENT_TOGGLE,
  allowOutlookEventProposal,
  doesEventAskForResponse,
  getSelfRSVPStatus,
  isEventHiddenEvent,
  isGoogleEvent,
  isOutOfOfficeEvent,
  isOutlookEvent,
  isTemporaryEvent,
  shouldShowHiddenEventsOption,
  toggleEventHiddenEventProperty,
} from "../lib/eventFunctions";
import Broadcast from "../broadcasts/broadcast";
import { Check } from "react-feather";
import {
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_DECLINED,
  GOOGLE_UPDATES,
  ATTENDEE_EVENT_TENTATIVE,
  FREE_DURING_EVENT,
  BUSY_DURING_EVENT,
  OUT_OF_OFFICE_DURING_EVENT,
} from "../services/googleCalendarService";
import classNames from "classnames";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getEmailFromUserCalendarID,
  getUserEmailFromEvent,
  hasWriteAccessToUserCalendarID,
} from "../lib/calendarFunctions";
import {
  convertVhToPixels,
  isMonthlyView,
  isSelectColorPopup,
  shouldTruncateRightHandPanel,
} from "../services/appFunctions";
import FreeBusyContainer from "./freeBusyContainer";
import { useHideRightHandSidebar } from "../services/stores/appFunctionality";
import EventResponse from "./eventResponse";
import {
  MAIN_CALENDAR_ID,
  POPUP_EXPANDED_VIEW_ID,
  POP_UP_CONTAINER_ID,
} from "../services/elementIDVariables";
import DeleteEventButton from "./expandedView/deleteEventButton";
import {
  EVENT_CONTAINER_WIDTH,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import {
  getEventExtendedProperties,
  getEventExtendedPropertiesPrivate,
  getEventMasterEventID,
  getEventTransparency,
  getEventUniqueEtag,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import Tags from "./tags";
import { DISPLAY_LOCATION_POPUP_EVENT } from "./tags/tagsVariables";
import {
  TAGS_LIMIT,
  calculateEventColorId,
  getAllTagsFromEvent,
  getMatchingUIUserForEvent,
  getTagsFromEvent,
  hasReachedTagsLimit,
} from "../lib/tagsFunctions";
import { getOriginalRecurringEventFromIndex } from "../lib/recurringEventFunctions";
import { constructRequestURL } from "../services/api";
import { createUpdatedSingleEventWithColor } from "../lib/mimicEventUpdate";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import tagsBroadcast from "../broadcasts/tagsBroadcast";
import {
  isOutlookEventAndOrganizer,
  shouldHideEventResponseOptions,
  shouldGateExtendedProperties,
} from "../lib/outlookFunctions";
import { BROADCAST_VALUES, EXPANDED_VIEW_BROADCAST_VALUES } from "../lib/broadcastValues";
import { OUTLOOK_SHOW_AS } from "../resources/outlookVariables";
import { isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { getDeleteEventCopy } from "../lib/copy";
import { getColorLabels } from "../lib/settingsFunctions";
import { shouldDisplayColorLabel } from "../lib/featureFlagFunctions";
import expandedEventViewBroadcast from "../broadcasts/expandedEventViewBroadcast";
import { isMeetWithEvent } from "../lib/meetWithFunctions";
import { getUserEmail } from "../lib/userFunctions";

// if change this, also need to change the following classNames:
// pop-up-expanded-view-content-container-not-all-day
const MAX_HEIGHT_OF_POPUP = "95vh";

const MAX_HEIGHT_ALL_DAY_EVENT = "calc(100vh - 150px)";

class PopupEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: 800,
      top: 0,
      left: 0,
      zIndex: 0,
      popupEvent: null,
    };

    this.determinePopupPosition = this.determinePopupPosition.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setTags = this.setTags.bind(this);
    this.removePopupEvent = this.removePopupEvent.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const { top, left, popupEvent } = this.determinePopupPosition();

    if (
      top !== this.state.top ||
      left !== this.state.left ||
      popupEvent !== this.state.popupEvent
    ) {
      this.setState({
        top: top,
        left: left,
        zIndex: 4,
        popupEvent,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.popupEvent?.event &&
      prevProps.popupEvent?.event &&
      getEventUniqueEtag(this.props.popupEvent.event) !==
        getEventUniqueEtag(prevProps.popupEvent.event)
    ) {
      // need to set new popup event first otherwise we get race condition
      this.setState(
        {
          zIndex: 4,
          popupEvent: this.props.popupEvent.event,
        },
        () => {
          const { top, left } = this.determinePopupPosition();

          if (top !== this.state.top || left !== this.state.left) {
            this.setState({
              top: top,
              left: left,
              zIndex: 4,
            });
          }
        }
      );
    } else {
      const { top, left, popupEvent } = this.determinePopupPosition();

      if (
        top !== this.state.top ||
        left !== this.state.left ||
        popupEvent !== this.state.popupEvent
      ) {
        this.setState({
          top: top,
          left: left,
          zIndex: 4,
          popupEvent,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const {
      isDarkMode,
    } = this.props;
    return (
      <>
        {this.renderOverlay()}

        <div
          id={POP_UP_CONTAINER_ID}
          className={classNames(
            "event-pop-up rounded-md absolute",
            "xl-2-blur",
            isDarkMode ? "soft-border" : "background-color-white-override half-px-border",
            "box-shadow-0",
          )}
          style={{
            maxHeight: this.isPoppedEventAllDay()
              ? MAX_HEIGHT_ALL_DAY_EVENT
              : MAX_HEIGHT_OF_POPUP,
            width: isSelectColorPopup(this.props.popupEvent)
              ? undefined
              : EVENT_CONTAINER_WIDTH,
            top: this.state.top,
            left: this.state.left,
            zIndex: this.state.zIndex,
          }}
        >
          {this.renderContent()}
        </div>
      </>
    );
  }

  renderOverlay() {
    const { popupEvent } = this.props;
    if (isSelectColorPopup(popupEvent)) {
      return (
        <div
          onClick={this.removePopupEvent}
          className="layout-over-lay"
          style={{
            zIndex: 3,
            left: "-10000px",
            backgroundColor: "transparent",
          }}
        ></div>
      );
    }
    return null;
  }

  removePopupEvent() {
    this.props.removePopupEvent();
  }

  renderContent() {
    if (this.isEventHiddenEvent()) {
      if (isSelectColorPopup(this.state.popupEvent)) {
        return (
          <div className="z-4">
            {this.renderHiddenEventsContent()}
            {this.renderDeleteEvent()}
          </div>
        );
      }
      return (
        <EventExpandedView
          event={this.state.popupEvent.event}
          id={POPUP_EXPANDED_VIEW_ID}
          isPopUpEvent={true}
        />
      );
    }
    if (isEmptyObjectOrFalsey(this.state.popupEvent)) {
      return null;
    }
    if (isSelectColorPopup(this.state.popupEvent)) {
      if (this.isEventEditable()) {
        return (
          <div className="z-4">
            <ColorSelector event={this.state.popupEvent?.event} />
            {this.renderTags()}
            {this.renderOutlookProposeTime()}
            {this.renderMarkFreeBusy()}
            {this.renderAttendanceContextOptions()}
            {this.emailAttendees()}
            {this.isMonthlyView() ? null : this.renderHiddenEventsContent()}
            {this.renderDeleteEvent()}
          </div>
        );
      } else if (!this.isMonthlyView()
        && !isTemporaryEvent(this.state.popupEvent?.event)
        && !isMeetWithEvent(this.state.popupEvent?.event)
      ) {
        return (
          <div className="z-4">
            {this.renderHiddenEventsContent()}
            {this.emailAttendees()}
          </div>
        );
      } else {
        return <div className="z-4">{this.emailAttendees()}</div>;
      }
    }
    return (
      <EventExpandedView
        event={this.state.popupEvent.event}
        id={POPUP_EXPANDED_VIEW_ID}
        isPopUpEvent={true}
      />
    );
  }

  isEventEditable() {
    const { actionMode } = this.props;
    const { reverseSlotsText } = this.props.temporaryStateStore;
    const { allCalendars } = this.props.allCalendars;
    return isEditable({
      event: this.state.popupEvent.event,
      allCalendars,
      actionMode,
      reverseSlotsText,
      isPrivateProperty: true,
    });
  }

  renderOutlookProposeTime() {
    const { event } = this.state.popupEvent;
    if (!allowOutlookEventProposal(event)) {
      return null;
    }
    return (
      <>
        <div
          className={classNames(
            "default-font-size font-weight-300",
            "p-3 cursor-pointer hover-background-color",
            "user-select-none border-top-1px",
            "flex justify-between items-center"
          )}
          onClick={() => {
            expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.TENTATIVE_AND_PROPOSE_TIME);
          }}
        >
          <div>Tentative and propose</div>
        </div>
        <div
          className={classNames(
            "default-font-size font-weight-300",
            "p-3 cursor-pointer hover-background-color",
            "user-select-none border-top-1px",
            "flex justify-between items-center"
          )}
          onClick={() => {
            expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DECLINE_AND_PROPOSE_TIME);
          }}
        >
          <div>Decline and propose</div>
        </div>
      </>
    );
  }

  shouldShowHiddenEventsOption() {
    return shouldShowHiddenEventsOption(this.state.popupEvent?.event);
  }

  isEventHiddenEvent() {
    return !this.isMonthlyView() && isEventHiddenEvent(this.state.popupEvent?.event);
  }

  isMonthlyView() {
    return isMonthlyView(this.props.selectedCalendarView);
  }

  renderHiddenEventsContent() {
    const eventUserEventID = getEventUserEventID(this.state.popupEvent?.event);
    const isEventCurrentlyHidden = this.isEventHiddenEvent();
    return (
      <div
        className={classNames(
          "default-font-size font-weight-300",
          "p-2 cursor-pointer hover-background-color user-select-none",
          "display-flex align-middle",
          "border-top-1px",
        )}
        onClick={() => {
          if (!eventUserEventID) {
            return;
          }
          const event = this.state.popupEvent?.event;
          const toggle = isEventCurrentlyHidden ? HIDDEN_EVENT_TOGGLE.OFF : HIDDEN_EVENT_TOGGLE.ON;
          toggleEventHiddenEventProperty(event, toggle);
          this.removePopupEvent();
        }}
      >
        {isEventCurrentlyHidden ? "Unhide event" : "Hide event"}
      </div>
    );
  }

  renderMarkFreeBusy() {
    const { event } = this.state.popupEvent;
    if (isEmptyObjectOrFalsey(event)) {
      return null;
    }

    if (!this.isEventEditable()) {
      return null;
    }

    if (isOutOfOfficeEvent(event) && isGoogleEvent(event)) {
      return null;
    }

    const possibleOptions = [
      {
        label: "Free",
        value: FREE_DURING_EVENT,
        action: () =>
          Broadcast.publish(
            BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY,
            FREE_DURING_EVENT
          ),
      },
      {
        label: "Busy",
        value: BUSY_DURING_EVENT,
        action: () =>
          Broadcast.publish(
            BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY,
            BUSY_DURING_EVENT
          ),
      },
    ];

    if (isOutlookEvent(event)) {
      possibleOptions.push({
        label: "Out of office",
        value: OUT_OF_OFFICE_DURING_EVENT,
        action: () =>
          Broadcast.publish(
            BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY,
            OUT_OF_OFFICE_DURING_EVENT
          ),
      });
      possibleOptions.push({
        label: "Tentative",
        value: OUTLOOK_SHOW_AS.TENTATIVE,
        action: () =>
          Broadcast.publish(
            BROADCAST_VALUES.MARK_EVENT_AS_FREE_BUSY,
            OUTLOOK_SHOW_AS.TENTATIVE
          ),
      });
    }

    const transparency = getEventTransparency(event) ?? BUSY_DURING_EVENT;
    const isRightSideTruncated = shouldTruncateRightHandPanel(
      this.props.hideRightHandSidebar
    );

    // Note:
    // we only add the FreeBusyContainer if the right hand side is hidden otherwise we're going to have 2 subscribers
    return (
      <div id="popup-attendance-response border-top-1px">
        {isRightSideTruncated ? <FreeBusyContainer event={event} /> : null}

        <div className="font-size-12 p-2 border-top-1px select-none">Busy?</div>

        <div>
          {possibleOptions.map((r) => {
            const currentTransparency = transparency === r.value;
            return (
              <div
                key={r.value}
                className={classNames(
                  "default-font-size font-weight-300 p-2 cursor-pointer hover-background-color user-select-none",
                  "display-flex align-middle",
                  currentTransparency ? "pl-2.5" : "pl-8"
                )}
                onClick={() =>
                  this.onClickResponse(r.action, isRightSideTruncated)
                }
              >
                {currentTransparency ? (
                  <Check size={16} className="mr-1.5" />
                ) : null}

                {r.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderAttendanceContextOptions() {
    const { event } = this.state.popupEvent;
    if (isEmptyObjectOrFalsey(event)) {
      return null;
    }

    if (isOutOfOfficeEvent(event)) {
      return null;
    }

    if (!this.shouldDisplayResponse()) {
      return null;
    }

    const isRightSideTruncated = shouldTruncateRightHandPanel(
      this.props.hideRightHandSidebar
    );

    const possibleResponses = this.getPossibleResponses(isRightSideTruncated);
    const email = getEmailBasedOnCalendarId(
      event,
      this.props.allCalendars.allCalendars
    );

    const renderEventResponseContainer = () => {
      if (!isRightSideTruncated) {
        return null;
      }

      return <EventResponse event={event} shouldHide={true} />;
    };

    const attendance = getSelfRSVPStatus(event, email);
    return (
      <div id="popup-attendance-response">
        <div className="font-size-12 p-2 border-top-1px">Going?</div>

        <div>
          {possibleResponses.map((r, index) => {
            let isCurrentAttendance = attendance === r.value;
            return (
              <div
                key={`pop-up-response-${index}`}
                className={classNames(
                  "default-font-size font-weight-300 p-2 cursor-pointer hover-background-color user-select-none",
                  "display-flex align-middle",
                  isCurrentAttendance ? "pl-2.5" : "pl-8"
                )}
                onClick={() =>
                  this.onClickResponse(r.action, isRightSideTruncated)
                }
              >
                {isCurrentAttendance ? (
                  <Check size={16} className="mr-1.5" />
                ) : null}

                {r.label}
              </div>
            );
          })}
        </div>

        {renderEventResponseContainer(event)}
      </div>
    );
  }

  emailAttendees() {
    const { event } = this.state.popupEvent;
    if (isEmptyObjectOrFalsey(event)) {
      return null;
    }

    const action = () => Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", event);
    return (
      <div
        className={classNames(
          "default-font-size font-weight-300",
          "p-2 cursor-pointer hover-background-color user-select-none",
          "display-flex align-middle",
          "border-top-1px"
        )}
        onClick={() => this.onClickResponse(action)}
      >
        Email attendees
      </div>
    );
  }

  renderDeleteEvent() {
    const { event } = this.state.popupEvent;
    if (isEmptyObjectOrFalsey(event)) {
      return null;
    }
    const isRightSideTruncated = shouldTruncateRightHandPanel(
      this.props.hideRightHandSidebar
    );

    const action = () => {
      if (isRightSideTruncated) {
        Broadcast.publish("POPUP_CLICK_DELETE");
      } else {
        Broadcast.publish("CLICK_DELETE");
      }
    };
    return (
      <div
        className={classNames(
          "default-font-size font-weight-300",
          "p-2 cursor-pointer hover-background-color user-select-none",
          "display-flex align-middle",
          "border-top-1px"
        )}
        onClick={() => {
          action();
        }}
      >
        {getDeleteEventCopy({event, alwaysShowEventText: true})}
        {isRightSideTruncated ? (
          <DeleteEventButton event={event} shouldHide={true} />
        ) : null}
      </div>
    );
  }

  getMatchingUIUserForEvent() {
    const event = this.state.popupEvent?.event;
    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getMatchingUIUserForEvent({event, allCalendars, allLoggedInUsers, masterAccount, currentUser});
  }

  renderTags() {
    const { event } = this.state.popupEvent;
    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const matchingUser = this.getMatchingUIUserForEvent();
    const allTags = getAllTagsFromEvent({
      event,
      user: matchingUser,
      currentUser,
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    });

    /* Check if calendar is owner role if belonging to Outlook */
    /* We can't write extended properties to shared calendars */
    const eventUserCalendarID = getEventUserCalendarID(event);
    const calendar = allCalendars[eventUserCalendarID];

    if (shouldGateExtendedProperties(calendar)) {
      return null;
    }

    const shouldShowTagsOnLeftHandSide = () => {
      const {
        left,
      } = this.state;
      // 160 is width of the popup event container
      // 160 is width of the tags container
      return (left + 160 + 160) > (window.innerWidth - 20);
    };

    return (
      <Tags
        displayLocation={DISPLAY_LOCATION_POPUP_EVENT}
        tags={allTags}
        setTags={this.setTags}
        shouldExpandTag={this.state.popupEvent?.shouldExpandTag ?? false}
        userEmail={getUserEmail(matchingUser)}
        matchingUser={matchingUser}
        showOnLeftHandSide={shouldShowTagsOnLeftHandSide()}
      />
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  getPossibleResponses(isRightSideTruncated) {
    const { event } = this.state.popupEvent;
    const { allCalendars } = this.props.allCalendars;
    const YES_RESPONSE = {
      label: "Yes",
      value: ATTENDEE_EVENT_ATTENDING,
      action: () => {
        if (isRightSideTruncated) {
          Broadcast.publish(`POPUP_ACCEPT_EVENT`);
        } else {
          Broadcast.publish(`ACCEPT_EVENT`);
        }
      },
    };
    const NO_RESPONSE = {
      label: "No",
      value: ATTENDEE_EVENT_DECLINED,
      action: () => {
        if (isRightSideTruncated) {
          Broadcast.publish(`POPUP_DECLINE_EVENT`);
        } else {
          Broadcast.publish(`DECLINE_EVENT`);
        }
      },
    };
    const MAYBE_RESPONSE = {
      label: "Maybe",
      value: ATTENDEE_EVENT_TENTATIVE,
      action: () => {
        if (isRightSideTruncated) {
          Broadcast.publish(`POPUP_MAYBE_ATTENDING_EVENT`);
        } else {
          Broadcast.publish(`MAYBE_ATTENDING_EVENT`);
        }
      },
    };

    if (
      isOutlookEventAndOrganizer({
        event,
        allCalendars,
      })
    ) {
      return [YES_RESPONSE, NO_RESPONSE];
    }

    return [YES_RESPONSE, NO_RESPONSE, MAYBE_RESPONSE];
  }

  setTags(tags) {
    if (isEmptyObjectOrFalsey(this.state.popupEvent)) {
      this.removePopupEvent();
      return;
    }

    const { event } = this.state.popupEvent;
    const { allCalendars } = this.props.allCalendars;

    const existingTags = getTagsFromEvent(event);
    if (tags?.length > existingTags?.length && hasReachedTagsLimit(tags)) {
      // do not show this if we're removing tags
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `You can only add up to ${TAGS_LIMIT} tags.`
      );
      return;
    }

    const id = calculateEventColorId({
      allCalendars,
      event,
      tags,
      previousTags: getTagsFromEvent(event),
    });
    const eventData = this.constructEventDataV2({
      id,
      tags,
    });
    const isRecurringEvent = getEventMasterEventID(event);

    if (isRecurringEvent) {
      // recurring event
      tagsBroadcast.publish(
        "DISPLAY_RECURRING_TAGS_MODAL",
        getOriginalRecurringEventFromIndex(
          event,
          this.props.originalRecurrenceEventIndex
        ),
        event,
        eventData
      );

      return;
    }

    /* Fake tag selection update */
    const updatedPopupEvent = {
      ...this.state.popupEvent,
      event: {
        ...this.state.popupEvent?.event,
        color_id: id,
        extended_properties: {
          ...getEventExtendedProperties(this.state.popupEvent?.event),
          private: {
            ...getEventExtendedPropertiesPrivate(this.state.popupEvent?.event),
            tags: JSON.stringify(tags),
          },
        },
      },
    };
    this.props.setPopupView(updatedPopupEvent);

    const path = "events";
    const params = {
      sendUpdates: GOOGLE_UPDATES.NONE,
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars
      ),
      event_provider_id: getGoogleEventId(event),
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, true) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
      updatedTemporaryEvent: createUpdatedSingleEventWithColor({
        event,
        colorID: id,
        extendedProperties: getEventExtendedProperties(eventData?.calendar_event),
      }),
    });

    Broadcast.publish("SET_LAST_SELECTED_EVENT", event);
  }

  constructEventDataV2({ id, tags = [] }) {
    const {
      event
    } = this.state.popupEvent;
    const {
      allCalendars
    } = this.props.allCalendars;
    const eventData = {
      user_calendar_id: getEventUserCalendarID(event),
      calendar_event: {
        ...(!isNullOrUndefined(id) && { colorId: id }),
        provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(event),
          allCalendars
        ),
        extended_properties: {
          ...getEventExtendedProperties(event),
          private: {
            ...getEventExtendedPropertiesPrivate(event),
            tags: JSON.stringify(tags),
          },
        },
      },
      user_event_id: getEventUserEventID(event),
    };
    return eventData;
  }

  // used to determine the top left corner of the popup
  determinePopupPosition() {
    const { popupEvent } = this.props;
    const poppedEvent = this.determineEvent(); // get poppedEvent or hoverPopupEvent
    const mainCalendarBound = document.getElementById(MAIN_CALENDAR_ID);

    // important since google shows busy, free, tentative, and out of office vs google which is only free and busy
    const isPoppedEventOutlookEvent = isOutlookEvent(poppedEvent?.event);

    const getInnerWidth = () => {
      if (!mainCalendarBound) {
        return window.innerWidth;
      }
      return mainCalendarBound.getBoundingClientRect().width;
    };

    const getInnerHeight = () => {
      if (!mainCalendarBound) {
        return window.innerHeight;
      }
      return mainCalendarBound.getBoundingClientRect().height;
    };
    const innerHeight = getInnerHeight();

    const getPreviewHeight = () => {
      let eventDom = document.getElementById(POPUP_EXPANDED_VIEW_ID);
      const height = eventDom ? eventDom.getBoundingClientRect().height : 300;
      eventDom = null;
      const maxPopupHeight = convertVhToPixels(MAX_HEIGHT_OF_POPUP);
      return height > maxPopupHeight ? maxPopupHeight : height;
    };

    const getLeftContextPosition = (clickLocation) => {
      if (isEmptyObjectOrFalsey(popupEvent)) {
        return Math.round(clickLocation.X);
      }

      const WIDTH_OF_CONTAINER = 160;
      if (clickLocation.X + WIDTH_OF_CONTAINER > window.innerWidth) {
        return clickLocation.X - WIDTH_OF_CONTAINER;
      }

      return Math.round(clickLocation.X);
    };

    const getEventTopLeftWithGuard = ({ left, top }) => {
      const innerWidth = getInnerWidth();
      if (left <= 0) {
        // move to right of the event
        if (!poppedEvent?.location?.width) {
          return { left: 10, top };
        }
        const leftOfContainer =
          poppedEvent.location.left + poppedEvent.location.width + 10;
        if (leftOfContainer + EVENT_CONTAINER_WIDTH > getInnerWidth()) {
          // flows to right of container
          return {
            left: poppedEvent.location.left,
            top: top + (poppedEvent?.location?.height ?? 0) + 10,
          };
        }
        return { left: leftOfContainer, top };
      }

      if (left + EVENT_CONTAINER_WIDTH > innerWidth) {
        // too far to the right
        return { left: innerWidth - EVENT_CONTAINER_WIDTH - 10, top };
      }
      return { left, top };
    };

    const getPositionWithPopUpHeight = (popUpHeight) => {
      if (popupEvent.clickLocation.Y + popUpHeight > innerHeight) {
        const updatedTop =
          popupEvent.clickLocation.Y -
          (popupEvent.clickLocation.Y + popUpHeight - innerHeight) -
          10;

        return {
          left: getLeftContextPosition(popupEvent.clickLocation),
          top: Math.round(updatedTop),
          popupEvent: popupEvent,
        };
      }

      return {
        left: getLeftContextPosition(popupEvent.clickLocation),
        top: Math.round(popupEvent.clickLocation.Y),
        popupEvent: popupEvent,
      };
    };
    const OUTLOOK_PROPOSE_TIME_HEIGHT = 42 * 2; // 42 each for tenative and decline
    const outlookProposeTimeHeight = isPoppedEventOutlookEvent && allowOutlookEventProposal(poppedEvent?.event) 
      ? OUTLOOK_PROPOSE_TIME_HEIGHT
      : 0;

    if (popupEvent?.clickLocation) {
      // e.g. this is on right click and open up options
      // TODO: You can have multiple labels per line, so I don't know if this calculation is accurate
      // https://github.com/Vimcal/calendar/pull/3523/files#r1633428217
      const HEIGHT_OF_COLOR_LABEL_PILL = 33;
      const colorLabels = this.getColorLabels();
      const shouldShowColorLabels = shouldDisplayColorLabel({user: this.getMatchingUIUserForEvent() ?? this.props.currentUser});
      const colorLabelHeight = (colorLabels.length + (shouldShowColorLabels ? 1 : 0)) * HEIGHT_OF_COLOR_LABEL_PILL; // + 1 for the plus button
      if (this.isEventHiddenEvent()) {
        return getPositionWithPopUpHeight(70);
      }
      if (this.onlyShowEmailInContext()) {
        // only show email
        // if just show email: height = 38
        const POP_UP_HEIGHT_ONLY_EMAIL = this.shouldShowHiddenEventsOption() ? 75 : 40; // without response -> popup is 250px
        return getPositionWithPopUpHeight(POP_UP_HEIGHT_ONLY_EMAIL);
      }
      if (!this.shouldDisplayResponse(popupEvent)) {
        const getHeight = () => {
          if (this.shouldShowHiddenEventsOption()) {
            return isPoppedEventOutlookEvent ? 385 : 315;
          }
          return isPoppedEventOutlookEvent ? 350 : 280;
        };
        const POP_UP_HEIGHT_WITHOUT_RESPONSE = getHeight();
        return getPositionWithPopUpHeight(POP_UP_HEIGHT_WITHOUT_RESPONSE + colorLabelHeight + outlookProposeTimeHeight);
      }

      // bottom of the popup goes beyond the bottom of the page
      const getHeight = () => {
        if (this.shouldShowHiddenEventsOption()) {
          return isPoppedEventOutlookEvent ? 515 : 445;
        }
        return isPoppedEventOutlookEvent ? 480 : 410;
      };
      const POP_UP_HEIGHT = getHeight();
      return getPositionWithPopUpHeight(POP_UP_HEIGHT + colorLabelHeight + outlookProposeTimeHeight);
    }

    if (isEmptyObjectOrFalsey(poppedEvent)) {
      return { left: null, top: null };
    }

    if (this.isPoppedEventAllDay()) {
      // for all day events
      const { top, left } = getEventTopLeftWithGuard({
        left: poppedEvent.location.left - EVENT_CONTAINER_WIDTH - 10,
        top: poppedEvent.location.top,
      });
      return {
        left: Math.round(left),
        top: Math.round(top),
        popupEvent: poppedEvent,
      };
    }

    const { top, left } = getEventTopLeftWithGuard({
      top: poppedEvent.location.top,
      left: poppedEvent.location.left - EVENT_CONTAINER_WIDTH - 10,
    });

    if (top + getPreviewHeight() > innerHeight) {
      return {
        left: Math.round(left),
        top: innerHeight - getPreviewHeight() - 40,
        popupEvent: poppedEvent,
      };
    }
    return {
      left: Math.round(left),
      top: Math.round(top),
      popupEvent: poppedEvent,
    };
  }

  determineEvent() {
    if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      return this.props.popupEvent;
    } else if (!isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
      return this.props.hoverPopupEvent;
    } else {
      return null;
    }
  }

  updateWindowDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }

  shouldDisplayResponse(popupEvent = null) {
    const { event } = popupEvent || this.state.popupEvent;
    if (isEmptyObjectOrFalsey(event)) {
      return false;
    }
    const {
      allCalendars,
    } = this.props.allCalendars;

    const eventAsksForResponse = doesEventAskForResponse({
      event,
      allCalendars,
    });

    if (!eventAsksForResponse) {
      return false;
    }

    if (shouldHideEventResponseOptions(event)) {
      return false;
    }

    return (
      eventAsksForResponse &&
      hasWriteAccessToUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars,
      )
    );
  }

  onClickResponse(actionResponse, skipRemovePopUp = false) {
    actionResponse();
    if (!skipRemovePopUp) {
      this.removePopupEvent();
    }
  }

  onlyShowEmailInContext() {
    if (isEmptyObjectOrFalsey(this.state.popupEvent)) {
      return false;
    }

    return !this.isEventEditable();
  }

  getColorLabels() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      currentUser
    } = this.props;
    return getColorLabels({
      masterAccount,
      user: this.getMatchingUIUserForEvent() ?? currentUser,
    });
  }

  isPoppedEventAllDay() {
    return this.determineEvent()?.event?.displayAsAllDay;
  }
}

function mapStateToProps(state) {
  let {
    popupEvent,
    hoverPopupEvent,
    weekStart,
    currentUser,
    isDarkMode,
    originalRecurrenceEventIndex,
    selectedCalendarView,
    actionMode,
  } = state;

  return {
    popupEvent,
    hoverPopupEvent,
    weekStart,
    currentUser,
    isDarkMode,
    originalRecurrenceEventIndex,
    selectedCalendarView,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPopupView: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const temporaryStateStore = useTemporaryStateStore();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      hideRightHandSidebar={hideRightHandSidebar}
      temporaryStateStore={temporaryStateStore}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(PopupEvent)));
