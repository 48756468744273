import React, { useEffect } from "react";
import { useTutorialWizard } from "../services/stores/appFunctionality";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { ChevronDown } from "react-feather";
import VimcalIcon from "../focusMode/resources/vimcalIcon";
import {
  getModalBackgroundColor,
} from "../services/globalVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import broadcast from "../broadcasts/broadcast";
import TutorialWizard from "./modal/tutorialWizard";
import { getAccountCompletedToolTips } from "../lib/userFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import { TUTORIAL_WIZARD_EXPERIMENT_BUCKET, getTutorialWizardBucket, hasUserCompletedAllTutorial } from "../lib/tracking/onboardingTracking";
import { isUserInOnboarding } from "../lib/stateManagementFunctions";
import { getDefaultFontColor } from "../lib/styleFunctions";

export default function TutorialWizardButton({ shouldHide }) {
  const isTutorialWizardShowing = useTutorialWizard(
    (state) => state.isTutorialWizardShowing
  );
  const isTutorialWizardMinimized = useTutorialWizard(
    (state) => state.isTutorialWizardMinimized
  );
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  // functions
  const expandTutorialWizard = useTutorialWizard(
    (state) => state.expandTutorialWizard
  );
  const minimizeTutorialWizard = useTutorialWizard(
    (state) => state.minimizeTutorialWizard
  );
  const showMinifiedTutorialWizard = useTutorialWizard((state) => state.showMinifiedTutorialWizard);
  const hasRemovedTutorialWizard = useTutorialWizard((state) => state.hasRemovedTutorialWizard);
  const removeTutorialWizard = useTutorialWizard((state) => state.removeTutorialWizard);

  const onClickButton = () => {
    if (isTutorialWizardMinimized) {
      layoutBroadcast.publish("OPEN_TUTORIAL_WIZARD_MODAL");
      expandTutorialWizard();
      return;
    }
    broadcast.publish("CLOSE_LAYOUT_MODAL");
    minimizeTutorialWizard();
  };

  useEffect(() => {
    const completedToolTips = getAccountCompletedToolTips(masterAccount);
    if (hasRemovedTutorialWizard) {
      // if manually removed -> do not show again
    } else if (isUserInOnboarding(masterAccount)) {
      // remove wizard if state is onboarding
      removeTutorialWizard();
    } else if (getTutorialWizardBucket({masterAccount}) === TUTORIAL_WIZARD_EXPERIMENT_BUCKET.WIZARD
      && !hasUserCompletedAllTutorial({ completedToolTips })
      && !isTutorialWizardShowing
    ) {
      // if user logs out and logs back in -> show tutorial wizard
      showMinifiedTutorialWizard();
    }
  }, []);

  if (!isTutorialWizardShowing || shouldHide) {
    return null;
  }
  return createPortal(
    <div className={classNames("fixed bottom-8 left-8", "z-index-1")}>
      {isTutorialWizardMinimized ? null : (
        <div
          className={classNames(
            "fixed left-8 bottom-24 w-96 rounded-2xl",
            "default-bottom-left-modal-border",
            "large-blur"
          )}
          style={{
            backgroundColor: getModalBackgroundColor(isDarkMode),
            borderRadius: "16px",
          }}
        >
          <TutorialWizard />
        </div>
      )}
      <div
        className={classNames(
          "rounded-lg",
          "w-12 h-12",
          "rounded-full",
          "flex items-center justify-center",
          "cursor-pointer",
          "duration-200",
          "tutorial-wizard-button",
          "default-bottom-left-modal-border"
        )}
        onClick={onClickButton}
      >
        {renderContent({
          isTutorialWizardMinimized,
          isDarkMode,
        })}
      </div>
    </div>,
    document.body
  );
}

function renderContent({ isTutorialWizardMinimized, isDarkMode }) {
  if (isTutorialWizardMinimized) {
    return (
      <VimcalIcon
        height={20}
        width={20}
        className={"flex justify-center items-center"}
        fillColor={getDefaultFontColor(isDarkMode)}
      />
    );
  }

  return <ChevronDown size={24} strokeWidth={3} />;
}
