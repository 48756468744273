import React, { useState } from "react";
import {
  getHumanReadableTimeInMinutes,
  getMetricsBreakdownID,
  getMetricsCategoryPercentage,
  getMetricsMeetingsBreakdown,
  getSpecialTagDisplayColorForMetrics,
  METRICS_VIEW,
} from "./metricsAccessorFunctions";
import ToggleForHourOrPercentage from "./toggleForHourOrPercentage";
import MetricsBreakDownEmptyState from "./metricsBreakDownEmptyState";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { isEmptyArray } from "../../lib/arrayFunctions";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import { getHumanReadableTagName } from "../../lib/tagsFunctions";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";

export default function MetricsBreakDown({ data, showSkeletonScreen }) {
  const [type, setType] = useState(METRICS_VIEW.HOUR);
  const allBreakDowns = getMetricsMeetingsBreakdown({ data });

  if (showSkeletonScreen) {
    return (
      <div className="metrics-data-container w-full mt-5 flex flex-col">
        <MetricsSkeletonLoadingPanel width={170} height={24} />

        <MetricsSkeletonLoadingPanel
          width={"100%"}
          height={40}
          marginTop={40}
        />
        <MetricsSkeletonLoadingPanel width={"80%"} height={40} marginTop={24} />
        <MetricsSkeletonLoadingPanel width={"60%"} height={40} marginTop={24} />
        <MetricsSkeletonLoadingPanel width={"40%"} height={40} marginTop={24} />
      </div>
    );
  }

  return (
    <div className="metrics-data-container w-full mt-5">
      <div className="w-full flex justify-between">
        <div
          className={classNames(
            "metrics-primary-text-color font-weight-400 w-full flex justify-between items-center metrics-default-font-size",
            "flex-col"
          )}
        >
          <div className="flex justify-start w-full metrics-default-section-header">Tags</div>
          {isEmptyArray(allBreakDowns) ? null : (
            <div className="metrics-secondary-text-color font-size-12 mt-2 flex justify-start w-full">
              These stats show proportion of time spent on each category out of
              all your calendar events.
            </div>
          )}
        </div>

        {isEmptyArray(allBreakDowns) ? null : (
          <ToggleForHourOrPercentage type={type} setType={setType} />
        )}
      </div>

      <RenderAllBreakDowns data={data} type={type} />
    </div>
  );
}

function RenderAllBreakDowns({ data, type }) {
  const allBreakDowns = getMetricsMeetingsBreakdown({ data });
  const highestValue = getHighestBreakdownValue({ data });
  if (isEmptyArrayOrFalsey(allBreakDowns)) {
    return <MetricsBreakDownEmptyState />;
  }

  return (
    <div className="flex items-end justify-between mt-5 z-10 relative pb-2">
      <div className="flex items-end justify-start flex-col w-full gap-4">
        {allBreakDowns.map((breakDown, index) => {
          return (
            <RenderHorizontalBreakdown
              breakDown={breakDown}
              highestValue={highestValue}
              index={index}
              type={type}
              data={data}
              key={getMetricsBreakdownID(breakDown) || `breakdown-item-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
}

function getHighestBreakdownValue({ data }) {
  const allBreakDowns = getMetricsMeetingsBreakdown({ data });
  let highestValue = 0;
  allBreakDowns.forEach((breakDown) => {
    if (breakDown.time > highestValue) {
      highestValue = breakDown.time;
    }
  });

  return highestValue;
}

function RenderHorizontalBreakdown({
  breakDown,
  highestValue,
  index,
  type,
  data,
}) {
  const { category_name, time } = breakDown;
  const MAX_CONTAINER_WIDTH = 600;
  const barWidth = ((time / highestValue) * MAX_CONTAINER_WIDTH).toFixed(0);
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <div
      key={`breakdown-item-${index}`}
      className="flex items-center justify-start select-none h-10 relative w-full"
    >
      <div className="flex items-center w-full justify-start">
        <div
          className={classNames(
            "metrics-primary-text-color font-weight-400 font-size-12",
            "mr-2",
            "truncate-text",
            "text-left",
            "metrics-break-text-container text-left"
          )}
        >
          {capitalizeFirstLetter(getHumanReadableTagName(category_name))}
        </div>
        <div
          style={{
            width: `${barWidth}px`,
            height: "20px",
            minHeight: "20px",
            borderRadius: "4px",
            backgroundColor: getSpecialTagDisplayColorForMetrics({
              breakDown,
              currentUser,
            }),
          }}
        ></div>
      </div>
      <div
        className={classNames(
          type === METRICS_VIEW.HOUR
            ? "metrics-secondary-text-color"
            : "text-transparent",
          "duration-500",
          "absolute top-8",
          "font-weight-300 font-size-12",
          "text-wrap-nowrap"
        )}
      >
        {`${getHumanReadableTimeInMinutes(time)}`}
      </div>
      <div
        className={classNames(
          type === METRICS_VIEW.PERCENTAGE
            ? "metrics-secondary-text-color"
            : "text-transparent",
          "duration-500",
          "absolute top-8",
          "font-weight-300 font-size-12",
          "text-wrap-nowrap"
        )}
      >
        {`${getMetricsCategoryPercentage({ data, breakDown })}%`}
      </div>
    </div>
  );
}

function renderBlob() {
  return (
    <div
      className={classNames(
        "metrics-blue-radial metrics-gradient-glow z-0",
        "overflow-hidden max-h-full max-w-full"
      )}
      style={{
        width: "calc(100% + 1000px)",
        height: "500px",
        top: 0,
        left: -10,
        borderRadius: 0,
      }}
    ></div>
  );
}
