import React, { useEffect, useState } from "react";
import OnboardingSaveButton from "./onboardingSaveButton";
import { DARK_PURPLE } from "./sharedVariables";
import Mousetrap from "mousetrap";
import {
  DARK_MODE_COMMAND_CENTER_BACKGROUND_COLOR,
  DARK_MODE_THEME,
  LIGHTGRAY,
  LIGHT_MODE_THEME,
  DARK_MODE_CELL_BORDER,
  DARK_MODE_DIVDIER_COLOR,
} from "../../services/globalVariables";
import {
  loadTheme,
  hasEventPreventDefault,
  isOSSchemeDarkMode,
} from "../../services/commonUsefulFunctions";
import AppBroadcast from "../../broadcasts/appBroadcast";
import { useSelector } from "react-redux";
import Broadcast from "../../broadcasts/broadcast";
import { isDefaultThemeDarkMode } from "./sharedFunctions";
import OnboardingLight from "./resources/onboardingLight";
import OnboardingDark from "./resources/onboardingDark";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import {
  getDefaultBackgroundColor,
  getDefaultFontColor,
} from "../../lib/styleFunctions";
import {
  isUserInDarkMode,
  shouldMatchOSSetting,
} from "../../lib/settingsFunctions";

const MATCH_OS_THEME = "MATCH_OS_THEME";
export default function ThemeSelection(props) {
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [theme, selectTheme] = useState(
    isDefaultThemeDarkMode({ masterAccount })
      ? DARK_MODE_THEME
      : LIGHT_MODE_THEME
  );
  const [isMatchOSScheme, setMatchOSScheme] = useState(
    determineIsMatchOS({ masterAccount })
  );
  const [isOSDarkMode] = useState(isOSSchemeDarkMode()); // is the os scheme currently dark mode

  useEffect(() => {
    // theme not set here yet
    Mousetrap.bind(["enter"], onClickSave);
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step,
    });

    return () => {
      Mousetrap.reset();
    };
  }, []);

  useEffect(() => {
    Mousetrap.bind(["tab"], onTab);
    Mousetrap.bind("1", onSelectMatchOS);
    Mousetrap.bind("2", onPressLight);
    Mousetrap.bind("3", onPressDark);
  }, [theme, isMatchOSScheme]);

  const onPressLight = (e) => {
    hasEventPreventDefault(e);
    onSelectTheme(LIGHT_MODE_THEME);
  };

  const onSelectMatchOS = (e) => {
    hasEventPreventDefault(e);
    onSelectTheme(isOSDarkMode ? DARK_MODE_THEME : LIGHT_MODE_THEME, true);
  };

  const onPressDark = (e) => {
    hasEventPreventDefault(e);
    onSelectTheme(DARK_MODE_THEME);
  };

  const onTab = (e) => {
    hasEventPreventDefault(e);
    if (isMatchOSScheme) {
      onSelectTheme(LIGHT_MODE_THEME);
    } else if (theme === LIGHT_MODE_THEME) {
      onSelectTheme(DARK_MODE_THEME);
    } else {
      // dark mode without match os
      onSelectTheme(isOSDarkMode ? DARK_MODE_THEME : LIGHT_MODE_THEME, true);
    }
  };

  const onSelectTheme = (theme, isMatchOS) => {
    selectTheme(theme);
    loadTheme(theme);

    if (theme === DARK_MODE_THEME) {
      AppBroadcast.publish("SET_APP_DARK_MODE");
    } else {
      AppBroadcast.publish("SET_APP_LIGHT_MODE");
    }

    if (isMatchOS) {
      setMatchOSScheme(true);
    } else {
      setMatchOSScheme(false);
    }
  };

  const onClickSave = () => {
    props.onClickNext();
    if (isMatchOSScheme) {
      Broadcast.publish("SET_COLOR_SCHEME_ON_OS_BACKEND");
    } else if (theme === LIGHT_MODE_THEME) {
      Broadcast.publish("DISABLE_DARK_MODE");
    } else {
      Broadcast.publish("ENABLE_DARK_MODE");
    }
  };

  const getSelectedTheme = () => {
    if (shouldMatchOSSetting({ masterAccount })) {
      return MATCH_OS_THEME;
    }

    return isUserInDarkMode({ masterAccount })
      ? DARK_MODE_THEME
      : LIGHT_MODE_THEME;
  };

  const isDarkMode = theme === DARK_MODE_THEME;

  return (
    <div className="onboarding-container min-height-500px">
      <div className="section-title">Choose your style</div>

      <div className="welcome-subtitle mt-3">
        You can change the theme later using the Command Center
      </div>

      <div className="display-flex mt-8 mb-3">
        {renderSelection({
          isSelected: isMatchOSScheme,
          isRenderDarkSelection: isOSDarkMode,
          isDarkMode,
          onSelect: () =>
            onSelectTheme(
              isOSDarkMode ? DARK_MODE_THEME : LIGHT_MODE_THEME,
              true
            ),
          isMatchOS: true,
        })}
        {renderSelection({
          isSelected: !isMatchOSScheme && theme === LIGHT_MODE_THEME,
          isRenderDarkSelection: false,
          isDarkMode,
          onSelect: () => onSelectTheme(LIGHT_MODE_THEME),
        })}
        {renderSelection({
          isSelected: !isMatchOSScheme && theme === DARK_MODE_THEME,
          isRenderDarkSelection: true,
          isDarkMode,
          onSelect: () => onSelectTheme(DARK_MODE_THEME),
        })}
      </div>

      <OnboardingSaveButton buttonText="Continue" onClick={onClickSave} />
    </div>
  );
}

function renderSelection(param) {
  const { isSelected, isDarkMode, onSelect, isRenderDarkSelection, isMatchOS } =
    param;

  const determineBackgroundColor = () => {
    if (isDarkMode) {
      return isSelected ? DARK_MODE_COMMAND_CENTER_BACKGROUND_COLOR : null;
    } else {
      return isSelected ? LIGHTGRAY : null;
    }
  };

  const determineIcon = () => {
    if (isMatchOS) {
      return "1";
    } else if (isRenderDarkSelection) {
      return "3";
    } else {
      return "2";
    }
  };

  const determineLabel = () => {
    if (isMatchOS) {
      return "Match OS";
    } else if (isRenderDarkSelection) {
      return "Dark";
    } else {
      return "Light";
    }
  };

  const determineScreenBorderColor = () => {
    if (isRenderDarkSelection && !isSelected) {
      return DARK_MODE_CELL_BORDER;
    } else if (!isRenderDarkSelection && !isSelected) {
      return LIGHTGRAY;
    }

    return isSelected ? DARK_PURPLE : "transparent";
  };

  const getBorderRadius = () => {
    if (isMatchOS) {
      return {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      };
    }
    if (isRenderDarkSelection) {
      return {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      };
    }
    return {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    };
  };

  const {
    borderTopLeftRadius,
    borderBottomLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
  } = getBorderRadius();
  return (
    <div
      className="display-flex flex-direction-column align-items-center pt-8 pb-8 pl-16 pr-16 cursor-pointer"
      style={{
        backgroundColor: determineBackgroundColor(),
        border: `1px solid ${isDarkMode ? DARK_MODE_DIVDIER_COLOR : LIGHTGRAY}`,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
      }}
      onClick={onSelect}
    >
      <div
        style={{
          border: `2px solid ${determineScreenBorderColor()}`,
          height: 115,
          borderRadius: 4,
          backgroundColor: getDefaultBackgroundColor(isRenderDarkSelection),
        }}
      >
        {isRenderDarkSelection ? <OnboardingDark /> : <OnboardingLight />}
      </div>

      <div className="mt-2 font-weight-400">{determineLabel()}</div>

      <div
        className="display-flex-flex-direction-row align-items-center justify-content-center h-7 w-8 mt-1"
        style={{
          border: `1px solid ${getDefaultFontColor(isDarkMode)}`,
          borderRadius: 4,
        }}
      >
        {determineIcon()}
      </div>
    </div>
  );
}

function determineIsMatchOS(masterAccount) {
  if (shouldMatchOSSetting({ masterAccount })) {
    return true;
  }

  return !isDefaultThemeDarkMode({ masterAccount });
}
