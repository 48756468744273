import {
  handleError,
  removeEmptyLines,
} from "../../services/commonUsefulFunctions";
import { toBase64 } from "../dataFunctions";

export async function extractTextFromImage(file) {
  // extractTextFromImageTessaract(file);
  // makeVisionApiRequest()
  return await makeVisionApiRequest(file);
}

async function makeVisionApiRequest(file) {
  try {
    const content = await toBase64(file);
    return callGoogleVisionAPI(content.split(",")[1]);
  } catch (error) {
    handleError(error);
    return;
  }
}

// actually make call to google vision api
export async function callGoogleVisionAPI(content) {
  const apiKey = "AIzaSyDKfcJ7S8911-pLcUxD48QIGAd8B8wGTXE"; // this key only works with vimcal websites and localhost
  const visionApiUrl = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;

  const requestBody = {
    requests: [
      {
        image: {
          content,
        },
        features: [
          {
            type: "DOCUMENT_TEXT_DETECTION", // for OCR
          },
        ],
      },
    ],
  };

  const response = await fetch(visionApiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  const responseJson = await response.json();
  return removeEmptyLines(
    responseJson?.responses?.[0]?.fullTextAnnotation?.text
  );
}
