import { constructQueryParams, removeDuplicatesFromArray } from "../services/commonUsefulFunctions";
import { constructRequestURL, isErrorResponse } from "../services/api";
import {
  getActiveCalendarsIDsFromAllCalendars,
  getCalendarUserEmail,
  getMatchingCalendarsForUser,
  isValidCalendar,
} from "./calendarFunctions";
import { formatGCalEventsList } from "../lib/webWorkerFunctions";
import { parseSyncResponseCalendars } from "../lib/calendarFunctions";
import { isCancelledEvent } from "./eventFunctions";
import { getCalendarLastSyncedAt, getCalendarUserCalendarID } from "../services/calendarAccessors";
import { isUserLimitedAccess } from "../services/maestroFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { isEventWithinJSWindow } from "./dbFunctions";
import { getUserEmail } from "./userFunctions";
import { getDefaultHeaders } from "./fetchFunctions";
import { GET_CALENDAR_ONLY_ENDPOINT } from "./endpoints";
import { isSameEmail } from "./stringFunctions";
import { getUserConnectedAccountToken } from "../services/maestro/maestroAccessors";
import { fetcherGet, fetcherPost } from "../services/fetcherFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";

let _lastSyncTimes = {};
export function cleanLastSyncTimes() {
  _lastSyncTimes = {};
}

export function createSyncOnlyCalendarRequest({
  user,
}) {
  const email = getUserEmail(user);
  const connectedAccountToken = getUserConnectedAccountToken({ user });
  const url = constructRequestURL(GET_CALENDAR_ONLY_ENDPOINT, isVersionV2());

  return fetcherGet({
    connectedAccountToken,
    email,
    url,
  });
}

// have to chopp into different weeks
export function createCalendarEventsSyncRequestForOutlook({
  user,
  allCalendars,
  isCleanRefresh,
  inputTimeMin, // explictly use this to call sync
  inputTimeMax,
  syncTime,
  otherUserCalendarIDsToFetch,
}) {
  return createCalendarEventsSyncRequest({
    minDate: inputTimeMin,
    maxDate: inputTimeMax,
    isCleanRefresh,
    user,
    allCalendars,
    time: syncTime,
    otherUserCalendarIDsToFetch
  });
}

function createCalendarEventsSyncRequest({
  minDate,
  maxDate,
  isCleanRefresh,
  user,
  allCalendars,
  time,
  otherUserCalendarIDsToFetch,
}) {
  const path = isUserLimitedAccess(user)
    ? "proxy_users/initial_events_sync"
    : "desktop/initial_calendars_sync";
  const userEmail = getUserEmail(user);

  const params = {
    secondary_calendars_sync_dates: JSON.stringify(
      createSecondaryCalendarSyncDates(userEmail, allCalendars)
    ),
    timeMin: minDate.toISOString(),
    timeMax: maxDate.toISOString(),
    time,
  };

  if (!isCleanRefresh && _lastSyncTimes[userEmail]) {
    params["last_synced_at"] = _lastSyncTimes[userEmail];
  }

  const selectedCalendarIDs = removeDuplicatesFromArray(getActiveCalendarsIDsFromAllCalendars({
    allCalendars,
    currentUserEmail: userEmail,
  }).concat(otherUserCalendarIDsToFetch || []));
  const body = {
    selected_calendar_ids: selectedCalendarIDs,
  };

  const queryParams = constructQueryParams(params);
  // TODO: Remove Maestro check once v2 supports Maestro users
  const url =
    constructRequestURL(path, isVersionV2()) +
    "?" +
    queryParams;

  const payloadData = {
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  };

  return fetcherPost({
    authorizationRequired: true,
    connectedAccountToken: getUserConnectedAccountToken({ user }),
    email: userEmail,
    payloadData,
    url,
  });
}

export function createCalendarEventsSyncRequestGoogle({
  user,
  allCalendars,
  isCleanRefresh,
  minDate,
  maxDate,
  otherUserCalendarIDsToFetch
}) {
  if (isCleanRefresh) {
    _lastSyncTimes = {};
  }

  return {
    request: createCalendarEventsSyncRequest({
      minDate,
      maxDate,
      isCleanRefresh,
      user,
      allCalendars,
      time: Date.now(),
      otherUserCalendarIDsToFetch,
    }),
    timeMin: minDate,
    timeMax: maxDate,
  };
}

function createSecondaryCalendarSyncDates(email, allCalendars) {
  let calendarSyncDates = {};

  Object.keys(allCalendars).forEach((k) => {
    const calendar = allCalendars[k];
    if (!isValidCalendar(calendar)) {
      return;
    }

    if (isSameEmail(getCalendarUserEmail(calendar), email) && getCalendarLastSyncedAt(calendar)) {
      calendarSyncDates[getCalendarUserCalendarID(calendar)] =
        getCalendarLastSyncedAt(calendar);
    }
  });

  return calendarSyncDates;
}

export function formatSyncResponseEventsAndCalendar({
  email,
  response,
  currentTimeZone,
  allCalendars,
}) {
  if (isErrorResponse(response) || isEmptyObjectOrFalsey(response)) {
    // early exit default
    return {
      formattedEvents: [],
      responseAllCalendars: getMatchingCalendarsForUser({allCalendars, userEmail: email}),
      calendarContacts: [],
      primaryCalendarCount: 1,
    };
  }
  const { last_synced_at_utc, events, calendars } = response;

  _lastSyncTimes[email] = last_synced_at_utc;

  const { formatEventsList } = formatGCalEventsList({
    eventList: events,
    currentTimeZone,
  });

  const { responseAllCalendars, calendarContacts, primaryCalendarCount } =
    parseSyncResponseCalendars(calendars, last_synced_at_utc, allCalendars);

  return {
    formattedEvents: formatEventsList,
    responseAllCalendars,
    calendarContacts,
    primaryCalendarCount,
  };
}

export function splitEventsIntoCancelledAndEventsInWindow({
  formattedEvents,
  activeCalendarUserIDs,
  windowStartDateJSDate,
  windowEndDateJSDate,
  todayLeftJSDate,
  todayRightJSDate,
}) {
  let eventsWithinTimeWindow = [];
  let cancelledEvents = [];
  let nonCancelledEvents = [];

  formattedEvents.forEach((e) => {
    if (
      activeCalendarUserIDs.includes(getEventUserCalendarID(e)) &&
      e.epochUnixWeek &&
      (isEventWithinJSWindow({
        event: e,
        windowStart: windowStartDateJSDate,
        windowEnd: windowEndDateJSDate,
      }) ||
        isEventWithinJSWindow({
          event: e,
          windowStart: todayLeftJSDate,
          windowEnd: todayRightJSDate,
        }) ||
        isCancelledEvent(e))
    ) {
      eventsWithinTimeWindow = eventsWithinTimeWindow.concat(e);
    }

    if (isCancelledEvent(e)) {
      cancelledEvents = cancelledEvents.concat(e);
    } else {
      nonCancelledEvents = nonCancelledEvents.concat(e);
    }
  });

  return {
    eventsWithinTimeWindow,
    cancelledEvents,
    nonCancelledEvents,
  };
}
