import classNames from "classnames";
import React, { useState } from "react";
import {
  hasStopEventPropagation,
  KEYCODE_ESCAPE,
} from "../../services/commonUsefulFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import ColoredLine from "../line";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import { X } from "react-feather";
import CopiableContentContainer from "../copiableContentContainer";
import CustomButton from "../customButton";
import {
  BLUE_BUTTON,
  SECOND_IN_MS,
  WHITE_BUTTON,
} from "../../services/globalVariables";
import DisabledButton from "../disabledButton";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { useAvailabilityStore } from "../../services/stores/availabilityStores";
import {
  getInputStringFromEvent,
  isValidEmail,
  truncateString,
} from "../../lib/stringFunctions";
import { blurCalendar } from "../../services/appFunctions";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../../lib/vimcalVariables";
import { isUserMaestroUser } from "../../services/maestroFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";

export default function GroupVoteInviteAttendees({
  groupVoteLinkURL,
  token,
  closeModal,
  newAttendeeEmails,
  isSpreadsheet,
}) {
  const componentIsMounted = useIsMounted();
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  
  const [shouldDisplayCopied, setShouldDisplayCopied] = useState(false);
  const [selectedGuests, setSelctedGuests] = useState(
    newAttendeeEmails
      ? newAttendeeEmails.map((a) => {
          return { label: a, value: a, name: "" };
        })
      : []
  );
  const [message, setMessage] = useState("");
  const selectedUser = useAvailabilityStore((state) => state.selectedUser);

  const copyLink = (e) => {
    hasStopEventPropagation(e);
    navigator.clipboard.writeText(groupVoteLinkURL).then(() => {
      if (!componentIsMounted.current) {
        return;
      }
      setShouldDisplayCopied(true);
      setTimeout(() => {
        if (!componentIsMounted.current) {
          return;
        }
        setShouldDisplayCopied(false);
      }, 3 * SECOND_IN_MS);
    });
  };

  const addContact = (selected) => {
    if (!selected) {
      return;
    }

    let emailWithoutWhiteSpaces = selected.value ? selected.value.trim() : "";
    const selectedGuestEmails = createSelectedGuestsEmailArray();
    if (
      !isValidEmail(emailWithoutWhiteSpaces) ||
      selectedGuestEmails.includes(emailWithoutWhiteSpaces)
    ) {
      return;
    } else {
      setSelctedGuests(selectedGuests.concat(selected));
    }
  };

  const createSelectedGuestsEmailArray = () => {
    return selectedGuests.map((a) => {
      return a.value;
    });
  };

  const onKeyDown = (data) => {
    if (data.keyCode === KEYCODE_ESCAPE) {
      blurCalendar();
    }
  };

  const onClickRemoveEmail = (email) => {
    let updatedSelectedGuests = selectedGuests;
    updatedSelectedGuests = updatedSelectedGuests.filter(
      (g) => g.value !== email
    );
    setSelctedGuests(updatedSelectedGuests);
  };

  const renderSelectedEmails = () => {
    if (selectedGuests.length === 0) {
      return null;
    }

    const createAttendeeString = () => {
      if (!selectedGuests.length === 0) {
        return "";
      }

      let emailString = "";
      selectedGuests.forEach((a, index) => {
        emailString = emailString + a.label;
        if (index + 1 < selectedGuests.length) {
          emailString = emailString + ", ";
        }
      });

      return emailString;
    };

    return (
      <div className="selected-email w-96 max-height-unset-important">
        <CopiableContentContainer
          style={{ width: "100%", flexWrap: "wrap" }}
          iconMarginLeft={5}
          iconMarginTop={15}
          textToCopy={createAttendeeString()}
        >
          {selectedGuests.map((e, index) => {
            return (
              <div
                key={`selected_email_list_${index}`}
                className="selected-email-container display-inline-flex-important"
              >
                <div className="margin-right-5">
                  {truncateString(e.value, 40)}
                </div>

                <div className="display-flex-center">
                  <X
                    className="hoverable-secondary-text-color"
                    size={13}
                    onClick={() => onClickRemoveEmail(e.value)}
                  />
                </div>
              </div>
            );
          })}
        </CopiableContentContainer>
      </div>
    );
  };

  const renderButtons = () => {
    const onClickSave = (e) => {
      hasStopEventPropagation(e);
      availabilityBroadcast.publish("SEND_GROUP_VOTE_INVITES", {
        token,
        message,
        attendees: createSelectedGuestsEmailArray(),
        link: groupVoteLinkURL,
        selectedUser,
      });
      closeModal();
    };

    const onClickCancel = (e) => {
      hasStopEventPropagation(e);
      closeModal();
    };

    return (
      <div className="flex items-center justify-end mt-4">
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={onClickCancel}
          label="Cancel"
          addPaddingToRight={true}
        />

        {selectedGuests.length > 0 ? (
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={onClickSave}
            label="Send"
          />
        ) : (
          <DisabledButton label="Send" />
        )}
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className="default-font-size font-weight-400">Share via link</div>
        <div className="flex items-center mt-2.5">
          <div
            className={classNames(
              "px-2.5 default-font-size w-500 truncate-text",
              "h-10",
              "default-thin-border",
              "rounded",
              "flex items-center"
            )}
            onClick={copyLink}
          >
            {groupVoteLinkURL}
          </div>
          <div
            onClick={copyLink}
            className={classNames(
              "text-white ml-2.5 default-font-size px-2.5 cursor-pointer bg-blue-600 hover:bg-blue-700 duration-200 w-24 items-center justify-center h-10 text-center default-border-radius select-none",
              "flex items-center justify-center"
            )}
          >
            {shouldDisplayCopied ? "Copied!" : "Copy link"}
          </div>
        </div>
      </div>

      {isSpreadsheet || isUserMaestroUser(masterAccount) ? null : (
        <>
          <ColoredLine style={{ marginTop: 32, marginBottom: 32 }} />

          <div className="default-font-size font-weight-400">
            Share via email
          </div>
          <ReactSelectAttendeeAutoComplete
            className="email-attendees-contact"
            addAttendees={addContact}
            defaultText="Enter name or email"
            componentLocation={
              REACT_ATTENDEE_SELECT_LOCATION.GROUP_VOTE_INVITE_PEOPLE_MODAL
            }
            hideMenuOnFocus={true}
            selectedGuests={createSelectedGuestsEmailArray()}
            includeEmailAndName={true}
            isInModal={true}
            onKeyDown={onKeyDown}
          />

          {renderSelectedEmails()}

          <div className="default-font-size font-weight-400 mt-4">Message</div>

          <textarea
            value={message}
            className="email-input-container mt-2.5"
            style={{ height: 150 }}
            placeholder={"Enter message"}
            onKeyDown={onKeyDown}
            onChange={(e) => setMessage(getInputStringFromEvent(e))}
          />
          {renderButtons()}
        </>
      )}
    </div>
  );
}
