import React from "react";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import { useSelector } from "react-redux";
import { VIMCAL_FTF_DESIGN } from "../../lib/vimcalVariables";

export default function FreeTimeFinderModal() {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  return (
    <div>
      <img
        alt=""
        className="w-full"
        src={
          isDarkMode
            ? VIMCAL_FTF_DESIGN.DARK_MODE
            : VIMCAL_FTF_DESIGN.LIGHT_MODE
        }
      />

      <div className="p-5">
        <div className="agenda-upnext-text font-size-14 font-weight-300">
          New
        </div>

        <div className="font-weight-400 font-size-16 mt-4">
          Schedule with Free Time Finder
        </div>

        <div className="secondary-text-color default-font-size mt-4">
          Paste text or upload a screenshot of times other people suggest to
          you, and let Vimcal AI show you when you’re free.
        </div>

        <div className="flex justify-end mt-6">
          <div
            className="cursor-pointer blue-button rounded-sm duration-200 px-1 py-1 flex justify-center items-center default-font-size"
            onClick={onClickGoToFeature}
          >
            Try it
          </div>
        </div>
      </div>
    </div>
  );
}

function onClickGoToFeature() {
  layoutBroadcast.publish("UPLOAD_AI_SCHEDULER");
}
