import { useCallback, useState } from "react";

/**
 * Using useRef to measure the height of an element does not trigger a re-render once the
 * ref is set to the DOM node. Using a callback instead allows us to update a state and
 * trigger a re-render so that the measurements can be used.
 * 
 * Useful when a component needs to know its own dimensions and the first render can't
 * lookup by element id. 
 * 
 * @see https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export default function useMeasuredRef<T extends HTMLElement>() {
  const [domNode, setDomNode] = useState<T | null>(null);

  const measuredRef = useCallback((node: T | null) => {
    setDomNode(node);
  }, []);

  const domRect = domNode?.getBoundingClientRect();

  return { domNode, domRect, measuredRef };
}
