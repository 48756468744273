import React, { forwardRef, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import _ from "underscore";

import VariableToolbar from "./reminderEmailToolbar";
import {
  determineDefaultReminderEmailText,
  formatTextToVariableDelta,
  formatVariablesInDelta,
  getReminderEmailContentID,
  getHumanReadableReminderEmailText,
  formatDeltaToText,
  countDeltaLength,
  replaceReminderEmailPlainTextWithVariables,
} from "./reminderEmailFunctions";
import { BLOCK_TO_P_BLOT_NAME, configureBlockFormat, EMAIL_REMINDER_BLOT_NAME } from "../../lib/quill/blots";
import { constructReminderEmailModules } from "../../lib/quill/modules";
import "../../styles/reminderEmailStyles.css";
import {
  isEmptyObjectOrFalsey,
  isTypeNumber,
} from "../../services/typeGuards";
import { hasEventPreventDefault } from "../../services/commonUsefulFunctions";
import { lowerCaseAndTrimString } from "../../lib/stringFunctions";

const formats = [EMAIL_REMINDER_BLOT_NAME, BLOCK_TO_P_BLOT_NAME];

export default forwardRef(function ReminderEmailContentEditable(
  { contentDelta, contentType, setContentDelta },
  ref,
) {
  const modules = useMemo(() => {
    return constructReminderEmailModules(contentType);
  }, []);
  /* Get editor when function runs */
  const getEditorFromRef = () => {
    return ref?.current?.getEditor();
  };

  /* Copy elements as {{variable}} -> Fix for Safari */
  useEffect(() => {
    const editor = getEditorFromRef();
    const editorContainer = editor?.container;
    configureBlockFormat(true);

    const constructCutOrCopyFunction = ({ isCut }) => {
      return function(e) {
        hasEventPreventDefault(e);
        const selectionIndexAndLength = editor?.getSelection(true);

        if (!isEmptyObjectOrFalsey(selectionIndexAndLength)) {
          const { index, length } = selectionIndexAndLength;
          /* Chrome and desktop length is 0 for getSelection on span elements */
          const isTargetSpan = lowerCaseAndTrimString(e.target.tagName) === "span";
          const adjustedLength = length === 0 && isTargetSpan ? 1 : length;
          const delta = editor?.getContents(index, adjustedLength);
          const text = formatDeltaToText({ contentType, delta });

          e.clipboardData.setData("text/plain", text);

          if (isCut) {
            editor?.deleteText(index, adjustedLength);
          }
        }
      };
    };

    if (editorContainer) {
      editorContainer.addEventListener("copy", constructCutOrCopyFunction({ isCut: false }));
      editorContainer.addEventListener("cut", constructCutOrCopyFunction({ isCut: true }));
    }

    return () => {
      configureBlockFormat(false);
      if (editorContainer) {
        editorContainer.removeEventListener("copy", constructCutOrCopyFunction({ isCut: false }));
        editorContainer.removeEventListener("cut", constructCutOrCopyFunction({ isCut: true }));
      }
    };
  }, []);

  /* Drag event to focus selector */
  useEffect(() => {
    const editor = getEditorFromRef();
    const editorContainer = editor?.container;

    const handleDragStart = (e) => {
      const formattedText = replaceReminderEmailPlainTextWithVariables({ contentType, text: e.target.textContent });
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.setData("text/plain", formattedText);
    };

    if (editorContainer) {
      editorContainer.addEventListener("dragstart", handleDragStart);
    }

    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener("dragstart", handleDragStart);
      }
    };
  }, []);

  /****************************************/
  /******** CURSOR INDEX FUNCTIONS ********/
  /****************************************/

  /* Used to set index to last element on restore default */
  const setEditorToLastIndex = () => {
    const editor = getEditorFromRef();

    if (editor) {
      const length = editor.getLength();
      editor.setSelection(length - 1);
    }
  };

  /* Handles fixing the selection index if the Delta changes from formatting variable while typing */
  const updateSelectionIndex = ({ delta, selectionIndex }) => {
    const editor = getEditorFromRef();
    const currentDelta = editor?.getContents();
    // const currentSelectionIndex = editor?.getSelection(true)?.index;

    if (!isEmptyObjectOrFalsey(currentDelta)) {
      const newDeltaLength = countDeltaLength({ delta: currentDelta });
      const oldDeltaLength = countDeltaLength({ delta });

      editor?.setSelection(selectionIndex - (oldDeltaLength - newDeltaLength));
    }
  };

  /****************************************/
  /*************** HANDLERS ***************/
  /****************************************/

  const onAddVariable = (variable) => {
    const editor = getEditorFromRef();
    const selectionIndex = editor?.getSelection(true)?.index;

    /* If the index is a number, insert the variable */
    if (isTypeNumber(selectionIndex)) {
      editor.insertEmbed(
        selectionIndex,
        EMAIL_REMINDER_BLOT_NAME,
        getHumanReadableReminderEmailText(variable),
      );
      /* Move cursor after the new variable */
      editor.setSelection(selectionIndex + 1);
    }
  };

  const onChange = (html, delta, source, editor) => {
    if (editor) {
      const currentDelta = editor.getContents();
      /* For auto formatting delta */
      const fomattedDelta = formatVariablesInDelta({ contentType, delta: currentDelta });

      /* We need to update index */
      if (!_(currentDelta?.ops).isEqual(fomattedDelta?.ops)) {
        const selectionIndex = editor?.getSelection(true)?.index;
        setContentDelta(fomattedDelta);
        setTimeout(() => updateSelectionIndex({ delta: currentDelta, selectionIndex }));
        return;
      }

      /* Standard update */
      if (!_(contentDelta?.ops).isEqual(fomattedDelta?.ops)) {
        setContentDelta(fomattedDelta);
      }
    }
  };

  const onRestoreDefault = () => {
    const defaultDelta = formatTextToVariableDelta({
      contentType,
      text: determineDefaultReminderEmailText(contentType),
    });
    setContentDelta(defaultDelta);
    setTimeout(() => setEditorToLastIndex());
  };

  return (
    <>
      <div className="secondary-text-color mb-2 mt-2.5 flex justify-between items-center">
        {contentType}
        <div
          className="hoverable-secondary-text-color select-none"
          onClick={onRestoreDefault}
        >
          Restore default
        </div>
      </div>

      <div
        className="w-full p-2 rounded-md default-secondary-border"
        id={getReminderEmailContentID(contentType)}
      >
        <VariableToolbar
          contentType={contentType}
          onAddVariable={onAddVariable}
        />
        <ReactQuill
          formats={formats}
          modules={modules}
          onChange={onChange}
          preserveWhitespace={true}
          ref={ref}
          value={contentDelta}
        />
      </div>
    </>
  );
});
