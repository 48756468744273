import React, { useMemo } from "react";
import classNames from "classnames";
import Nvidia from "./company_logos/nvidia";
import Zapier from "./company_logos/zapier";
import Btv from "./company_logos/btv";
import { getVimcalEATestimonialHeader, VIMCAL_EA_HEADER_IMAGE, VIMCAL_EA_TESTIMONIAL_HEADER } from "../../js/services/experiments";
import ClearBit from "./company_logos/clearBit";


export default function VimcalEAFormTestimonial({ id, headerKey }) {
  const header = useMemo(() => getVimcalEATestimonialHeader({id, headerKey}), [id, headerKey]);

  const getHeaderImage = () => {
    if (header === VIMCAL_EA_TESTIMONIAL_HEADER.STOCK_PHOTO) {
      return <StockPhotoHeader />;
    }
    if (header === VIMCAL_EA_TESTIMONIAL_HEADER.LADY_ON_LAPTOP) {
      return <LadyOnLaptopHeader />;
    }
    return <CourtneyTestimonial />;
  };

  return (
    <div
      className={classNames(
        "vimcal-ea-form-testimonial",
        "w-full h-full light-mode-font-color default-font-size",
        "flex items-center justify-center",
      )}
    >
      <div
        className={
          classNames("flex flex-col",
            "items-center justify-between",
            "vimcal-ea-form-signup-form-testimonial-content-wrapper",
          )}
      >
        <div className={classNames("w-72 flex flex-col gap-3", header === VIMCAL_EA_TESTIMONIAL_HEADER.LADY_ON_LAPTOP ? "items-center" : "")}>
          {getHeaderImage()}
          <span className={classNames("font-size-28", "flex flex-wrap", "justify-center w-full")}>
            <span>Spend</span>
            <span className="ml-1 font-weight-500" style={{color: "#8D99FF"}}>87% less time</span>
            <span className="ml-1">on scheduling</span>
          </span>
        </div>

        <RenderListOfCompanies />
      </div>
    </div>
  );
}

function StockPhotoHeader() {
  return <img src={VIMCAL_EA_HEADER_IMAGE.STOCK_PHOTO} alt="testimonial" className="md:block hidden" />;
}

function LadyOnLaptopHeader() {
  return <img src={VIMCAL_EA_HEADER_IMAGE.LADY_ON_LAPTOP} alt="testimonial" className="md:block hidden w-44 mb-5" />;
}

function CourtneyTestimonial() {
  return (
    <div className="relative md:block hidden">
      <div className={classNames("absolute courtney-testimonial-quote")}>
        <div className="font-size-18">"I feel like I look like a boss."</div>
        <div className="font-size-10 font-weight-300 ml-20 mt-5">Courtney H.</div>
        <div className="font-size-10 font-weight-400 ml-20">Executive Assistant @ Zapier</div>
      </div>
      <img src={VIMCAL_EA_HEADER_IMAGE.COURTNEY_QUOTE} alt="testimonial" className="w-20 z-10 position-inherit" />
    </div>
  );
}

function RenderListOfCompanies() {
  return (
    <div className={classNames("")}>
      <div className="font-size-10 flex items-center justify-center mb-6">Trusted by C-Suite EAs at</div>
      <div className="flex items-center gap-2">
        <Nvidia className="vimcal-ea-form-company-logo"/>
        <ClearBit className="vimcal-ea-form-company-logo ml-2.5" width={144}/>
      </div>
      <div className="flex items-center gap-2 mt-4">
        <Zapier className="vimcal-ea-form-company-logo"/>
        <Btv className="vimcal-ea-form-company-logo"/>
      </div>
    </div>
  );
}
