import React, { useState } from "react";
import { Info } from "react-feather";
import { getTooltipStyles } from "../../lib/tooltipFunctions";
import useForceUpdate from "../../services/customHooks/useForceUpdate";
import { EVENT_MODAL_ID } from "../../services/elementIDVariables";

const PERMISSIONS: readonly Readonly<{ description: string, member: boolean, admin: boolean, owner: boolean }>[] = [
  { description: "View members", member: true, admin: true, owner: true },
  { description: "Invite members", member: true, admin: true, owner: true },
  { description: "Remove members", member: false, admin: true, owner: true },
  { description: "Update member roles", member: false, admin: true, owner: true },
  { description: "Update team info", member: false, admin: true, owner: true },
  { description: "Update payment methods", member: false, admin: true, owner: true },
  { description: "Upgrade to annual plan", member: false, admin: true, owner: true },
  { description: "Cancel plan", member: false, admin: false, owner: true },
];

/**
 * A tooltip with a table summarizing the permissions for each role in a team plan.
 */
export default function RoleSummary() {
  // Use state for these instead of a ref so that we recalculate the tooltip position
  // once mounted.
  const [hoveredElement, setHoveredElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );
  // Need forceUpdate here to force a re-render and trigger the tooltip to recalculate where it should be.
  const { forceUpdate } = useForceUpdate();

  const wrapperElement = document.getElementById(EVENT_MODAL_ID)?.querySelector<HTMLElement>(".settings-content-container");

  const tooltipStyles: React.CSSProperties = getTooltipStyles({
    hoveredElement,
    tooltipElement,
    wrapperElement,
    placement: "top",
    gap: 6,
    wrapperPadding: 32,
  });

  return (
    <div className="role-summary-group relative flex items-center" ref={setHoveredElement} onMouseEnter={forceUpdate}>
      <Info size={12} className="clickable-icon" />
      <div
        ref={setTooltipElement}
        style={tooltipStyles}
        className="role-summary-tooltip z-10 bg-gray-100 absolute default-font-color p-2 rounded-md"
      >
        <table className="w-max font-normal">
          <tbody>
            <tr>
              <th></th>
              <th>Member</th>
              <th>Admin</th>
              <th>Owner</th>
            </tr>
            {PERMISSIONS.map(permission => (
              <tr key={permission.description}>
                <td>{permission.description}</td>
                <td>{permission.member ? "X" : ""}</td>
                <td>{permission.admin ? "X" : ""}</td>
                <td>{permission.owner ? "X" : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
