import React, { Component } from "react";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import { connect } from "react-redux";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { isEmptyArray } from "../../lib/arrayFunctions";
import { isEventWithinJSWindowNotIncludingTouching } from "../../lib/dbFunctions";
import { isBusyEvent } from "../../lib/eventFunctions";
import { isTemporaryAIEvent } from "../../lib/availabilityFunctions";

class ReverseSlotsFilterReader extends Component {
  constructor(props) {
    super(props);

    this._originalTemporaryEvents = [];
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.temporaryStateStore.reverseSlotsText &&
      !this.props.temporaryStateStore.reverseSlotsText
    ) {
      // reset
      this._originalTemporaryEvents = [];
    }

    if (!this.props.temporaryStateStore.reverseSlotsText) {
      return; // early exit -> no need to do anything else
    }

    if (
      prevProps.temporaryStateStore.isHideTimesWithBusyEvents &&
      !this.props.temporaryStateStore.isHideTimesWithBusyEvents
    ) {
      // toggled from yes to no
      // reset temporary events
      mainCalendarBroadcast.publish(
        "SET_TEMPORARY_EVENTS",
        this._originalTemporaryEvents
      );
    } else if (
      !prevProps.temporaryStateStore.isHideTimesWithBusyEvents &&
      this.props.temporaryStateStore.isHideTimesWithBusyEvents
    ) {
      // toggled from no to yes
      this._originalTemporaryEvents = this.props.temporaryEvents;
      this.filterOutBusyTimes();
    }

    if (
      this.filterOutReverseSlotTimes(this.props.mainCalendarEvents).length !==
      this.filterOutReverseSlotTimes(prevProps.mainCalendarEvents).length
    ) {
      // need to check length otherwise reference is always going to be different
      // main calendar events changed (user switched weeks, etc)
      this.filterOutBusyTimes();
    }
  }

  filterOutReverseSlotTimes(events) {
    // otherwise this causes infinite loop in didUpdate
    return events?.filter((event) => !isTemporaryAIEvent(event)) ?? [];
  }

  filterOutBusyTimes() {
    const { mainCalendarEvents } = this.props;
    if (
      isEmptyArray(mainCalendarEvents) ||
      isEmptyArray(this._originalTemporaryEvents)
    ) {
      return; // no need to continue
    }
    // need to read meet with events
    const filterOutReverseSlots =
      this.filterOutReverseSlotTimes(mainCalendarEvents);

    const updatedTemporaryEvents = this._originalTemporaryEvents.filter(
      (temporaryEvent) => {
        return !filterOutReverseSlots.some((mainCalendarEvent) => {
          // is the temporary event in any of the event windows
          return (
            isBusyEvent(mainCalendarEvent) &&
            isEventWithinJSWindowNotIncludingTouching({
              event: temporaryEvent,
              windowStart: mainCalendarEvent.eventStart,
              windowEnd: mainCalendarEvent.eventEnd,
            })
          );
        });
      }
    );
    mainCalendarBroadcast.publish(
      "SET_TEMPORARY_EVENTS",
      updatedTemporaryEvents
    );
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { temporaryEvents } = state;

  return {
    temporaryEvents,
  };
}

const withStore = (BaseComponent) => (props) => {
  const temporaryStateStore = useTemporaryStateStore();

  return <BaseComponent {...props} temporaryStateStore={temporaryStateStore} />;
};

export default connect(
  mapStateToProps,
  null
)(withStore(ReverseSlotsFilterReader));
