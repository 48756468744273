import { useEffect, useMemo, useState } from "react";
import {
  SLOTS_SPAN_TYPE,
  createTemporaryEvent,
  fetchFreeBusySlots,
  getDefaultWeekWithWorkHours,
  getMeetWithEventsWithRawTimes,
  getSlotsPresets,
  grabAvailableSlots,
} from "../../lib/availabilityFunctions";
import {
  generateBookableSlotsFromObj,
  handleError,
  removeDuplicatesFromArray,
} from "../../services/commonUsefulFunctions";
import { getAllBusySlots } from "../../services/commonUsefulFunctions";
import { generateFreeSlotsFromBusySlots } from "../../services/commonUsefulFunctions";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import broadcast from "../../broadcasts/broadcast";
import {
  EXCLUDED_DOMAINS,
  GENERIC_ERROR_MESSAGE,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { useSelector } from "react-redux";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import {
  addDays,
  addHours,
  endOfDay,
  isMonday,
  isPast,
  isToday,
  nextMonday,
  parseISO,
  previousMonday,
  setHours,
  startOfDay,
} from "date-fns";
import { isSlotsPageOpen, isTutorialWizardOpen } from "../../services/appFunctions";
import { useTutorialWizard } from "../../services/stores/appFunctionality";
import { immutablySortArray, isEmptyArray } from "../../lib/arrayFunctions";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import {
  extractBusyTimesFromMeetWithResponse,
  getMeetWithEvents,
} from "../../lib/meetWithFunctions";
import { getMatchingUserWithDomain } from "../../services/meetWithFunctions";
import { determineShadedCalendarHours } from "../../lib/stateManagementFunctions";
import { getDefaultUserTimeZone, getInternalDomainAndEmails, getWorkHours } from "../../lib/settingsFunctions";
import { getEmailDomain, lowerCaseAndTrimString } from "../../lib/stringFunctions";
import { filterOutInternalEvents } from "../../lib/eventFunctions";

export default function GrabAvailableSlotsContainer({
  blockedCalendarsID,
  duration,
  user,
  blockedAttendeeEmails,
  attendees,
  isIgnoreConflicts,
  isIgnoreInternalConflicts,
}) {
  const componentIsMounted = useIsMounted();
  const currentTimeZone = useSelector((state) => state.currentTimeZone);
  const currentTimeZoneLabel = useSelector((state) => state.currentTimeZoneLabel);
  const defaultBrowserTimeZone = useSelector((state) => state.defaultBrowserTimeZone);
  const temporaryTimeZones = useSelector((state) => state.temporaryTimeZones);
  const anchorTimeZones = useSelector((state) => state.anchorTimeZones);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const weekStart = useSelector((state) => state.weekStart);
  const selectedDay = useSelector((state) => state.selectedDay);
  const [allFreeSlots, setAllFreeSlots] = useState(null);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const minimizeTutorialWizard = useTutorialWizard(
    (state) => state.minimizeTutorialWizard
  );
  const meetWithEvents = useTemporaryStateStore(
    (state) => state.meetWithEvents
  );
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const internalDomains = useMemo(() => getInternalDomainAndEmails({masterAccount, user}), [user, masterAccount]);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      "GET_UPCOMING_WEEK_AVAILABILITY",
      getUpcomingSlotsAvailability
    );
    availabilityBroadcast.subscribe(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      getAvailableSlotsFromAllFreeSlots
    );
    return () => {
      availabilityBroadcast.unsubscribe("GET_UPCOMING_WEEK_AVAILABILITY");
      availabilityBroadcast.unsubscribe("GRAB_AVAILABLE_SLOTS_WITH_TYPE");
    };
  }, []);

  useEffect(() => {
    availabilityBroadcast.subscribe(
      "GET_UPCOMING_WEEK_AVAILABILITY",
      getUpcomingSlotsAvailability
    );
    availabilityBroadcast.subscribe(
      "GRAB_AVAILABLE_SLOTS_WITH_TYPE",
      getAvailableSlotsFromAllFreeSlots
    );
  }, [
    allCalendars,
    allFreeSlots,
    blockedAttendeeEmails,
    currentTimeZone,
    duration,
    user,
    blockedCalendarsID,
    masterAccount,
    selectedDay,
    attendees,
    meetWithEvents,
    allLoggedInUsers,
    currentTimeZoneLabel,
    temporaryTimeZones,
    anchorTimeZones,
    currentUser,
    defaultBrowserTimeZone,
    isIgnoreInternalConflicts,
  ]);

  const getUpcomingSlotsAvailability = async (type, updatedDate) => {
    if (!isSlotsPageOpen()) {
      return;
    }
    const attendeeEmails =
      attendees?.map((attendee) => attendee?.email || attendee?.value) ?? [];
    const allAttendeeEmails = removeDuplicatesFromArray(
      []
        .concat(blockedAttendeeEmails)
        .concat(attendeeEmails)
        .map((email) => email.toLowerCase())
    );

    const { bufferBeforeEvent, bufferAfterEvent } = getSlotsPresets({
      allCalendars,
      currentUser: user,
      masterAccount,
    });

    // depending on time zone -> get work hours or shaded hours for overlapping time zones
    const getWorkHoursByTimeZone = () => {
      if (temporaryTimeZones?.length > 0) {
        const {
          start: startWorkHour,
          end: endWorkHour,
        } = determineShadedCalendarHours({ // returns {start: 15, end: 19}
          timeZone: currentTimeZone,
          timeZoneLabel: currentTimeZoneLabel,
          inputDefaultBrowserTimeZone: defaultBrowserTimeZone,
          temporaryTimeZones: temporaryTimeZones,
          anchorTimeZones,
          defaultTimeZone: getDefaultUserTimeZone({ masterAccount, user: currentUser }),
          masterAccount: masterAccount,
          user,
        });
        return {startWorkHour, endWorkHour};
      }

      const workHours = getWorkHours({ masterAccount, user });
      return workHours;
    };

    if (isTutorialWizardOpen()) {
      minimizeTutorialWizard();
    }

    const workHours = getWorkHoursByTimeZone();
    const {startWorkHour, endWorkHour} = workHours;

    if (endWorkHour <= startWorkHour) {
      showNoFreeTimesFound();
      return;
    }
    availabilityBroadcast.publish("OPEN_GET_AVAILABLE_SLOTS_MODAL");

    const workHoursWeekdaySlots = getDefaultWeekWithWorkHours({
      workHours,
    });

    const currentDate = updatedDate ?? selectedDay;

    const daysForward = 14;
    const bookableSlots = generateBookableSlotsFromObj({
      slots: workHoursWeekdaySlots,
      timeZone: currentTimeZone,
      daysForward,
      shouldFormatIntoUTC: false,
      bufferBefore: bufferBeforeEvent, // at least until next day
      bufferAfter: bufferAfterEvent,
      currentDate,
    });

    availabilityBroadcast.publish("SHOW_LOADING_FIND_TIME_SPINNER");

    const getFreeBusySlotsPromise = fetchFreeBusySlots({
      currentUser: user,
      conferencing: null,
      bookableSlots,
      blockedCalendars: blockedCalendarsID,
      blockedAttendeeEmails: allAttendeeEmails,
      isIgnoreInternalConflicts,
    });

    // fetch temporary events
    const searchEmails = attendeeEmails.filter(
      (email) => !blockedAttendeeEmails.includes(email)
    );
    const allEmailDomains = removeDuplicatesFromArray(
      searchEmails.map((email) => lowerCaseAndTrimString(getEmailDomain(email)))
    );
    const matchingUser = getMatchingUserWithDomain({
      allLoggedInUsers,
      domain: allEmailDomains.find(
        (domain) => !EXCLUDED_DOMAINS.includes(domain)
      ),
      currentUser: user,
    });
    const getMeetWithPromise = getMeetWithEvents({
      user: matchingUser,
      currentUser: user,
      startDate: startOfDay(currentDate),
      endDate: endOfDay(addDays(currentDate, daysForward)),
      emails: searchEmails,
      currentTimeZone,
    });

    try {
      const [freeBusySlotsResponse, meetWithResponse] = await Promise.all([
        getFreeBusySlotsPromise,
        getMeetWithPromise,
      ]);
      if (!componentIsMounted.current) {
        return;
      }
      if (!freeBusySlotsResponse) {
        broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE
        );
        return;
      }
      availabilityBroadcast.publish("RESET_LOADING_FIND_TIMES");

      const meetWithBusyTimes =
        extractBusyTimesFromMeetWithResponse(meetWithResponse);
      const getCachedMeetWithBusyEvents = () => {
        if (isIgnoreConflicts) {
          return [];
        }
        if (isIgnoreInternalConflicts) {
          return filterOutInternalEvents({events: meetWithEvents, internalDomains});
        }
        return meetWithEvents;
      };

      // busySlots:
      // {start: '2023-12-11T15:30:00.000Z', end: '2023-12-12T15:30:00.000Z'}
      const cachedMeetWithBusyEvents =
        getMeetWithEventsWithRawTimes(getCachedMeetWithBusyEvents()); // this is cached from fetching meet with in calendarList -> but if users move, this will get stale
      const busySlots = immutablySortArray(getAllBusySlots({
        response: freeBusySlotsResponse,
        roundUpInterval: 30, // roundUpInterval
        bufferBefore: bufferBeforeEvent, // bufferBefore
        bufferAfter: bufferAfterEvent, // bufferAfter
        bufferFromNow: isToday(currentDate) ? 720 : 0, // buffer from now 12*60 or half a day ahead is one day ahead
        currentDate,
      })
        .concat(cachedMeetWithBusyEvents)
        .concat(meetWithBusyTimes), (a, b) => parseISO(a.start) - parseISO(b.start)); // this needs to be sorted for this to properly work

      const allFreeSlots = generateFreeSlotsFromBusySlots({
        busySlots,
        slots: workHoursWeekdaySlots,
        daysForward,
        durationMinutes: duration,
        timeZone: currentTimeZone,
        bufferBefore: bufferBeforeEvent,
        bufferAfter: bufferAfterEvent,
        currentDate,
      });

      if (isEmptyArray(allFreeSlots)) {
        availabilityBroadcast.publish("CLOSE_GET_AVAILABLE_SLOTS_MODAL");
        broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "There are no available free times within the next two weeks."
        );
      }

      setAllFreeSlots(allFreeSlots);
      if (!allFreeSlots) {
        return;
      }

      const spanType = type ?? SLOTS_SPAN_TYPE.RANDOM;
      const filteredFreeSlots = grabAvailableSlots({
        spanType,
        freeSlots: allFreeSlots,
        currentTimeZone,
        duration,
        weekStart,
        currentDate,
      });

      setSlotsAndScroll({
        filteredFreeSlots,
        spanType,
        currentDate,
        user,
      });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      handleError(error);
    }
  };

  const getUpdatedDate = (type) => {
    if (type === SLOTS_SPAN_TYPE.ONLY_THIS_CURRENT_WEEK) {
      return startOfDay(new Date());
    }

    if (type === SLOTS_SPAN_TYPE.NEXT_WEEK) {
      // nothing
    } else if (isPast(selectedDay) || isPast(previousMonday(selectedDay))) {
      return addHours(new Date(), 2);
    }

    const { startWorkHour } = getWorkHours({ masterAccount, user });

    return type === SLOTS_SPAN_TYPE.NEXT_WEEK
      ? setHours(nextMonday(selectedDay), startWorkHour)
      : setHours(
          isMonday(selectedDay) ? selectedDay : previousMonday(selectedDay),
          startWorkHour
        );
  };

  const getAvailableSlotsFromAllFreeSlots = (type) => {
    const updatedDate = getUpdatedDate(type);
    if (isEmptyArray(allFreeSlots)) {
      getUpcomingSlotsAvailability(type, updatedDate);
      return;
    }

    if (type === SLOTS_SPAN_TYPE.NEXT_WEEK) {
      // need to grab 14 days and not 7
      mainCalendarBroadcast.publish(
        "JUMP_TO_DATE_AND_SCROLL_TO_TIME",
        updatedDate
      );
      getUpcomingSlotsAvailability(type, updatedDate);
      return;
    }

    if (type === SLOTS_SPAN_TYPE.ONLY_THIS_CURRENT_WEEK) {
      mainCalendarBroadcast.publish(
        "JUMP_TO_DATE_AND_SCROLL_TO_TIME",
        updatedDate
      );
      getUpcomingSlotsAvailability(type, updatedDate);
      return;
    }

    const filteredFreeSlots = grabAvailableSlots({
      spanType: type,
      freeSlots: allFreeSlots,
      currentTimeZone,
      duration,
      weekStart,
      currentDate: updatedDate,
    });
    if (isEmptyArray(filteredFreeSlots)) {
      // refetch and grab
      getUpcomingSlotsAvailability(type, updatedDate);
      return;
    }

    setSlotsAndScroll({
      filteredFreeSlots,
      spanType: type,
      currentDate: updatedDate,
      user,
    });
  };

  return null;
}

function showNoFreeTimesFound() {
  broadcast.publish(
    SET_DISAPPEARING_NOTIFICATION_MESSAGE,
    "There are no available free times given your work hours"
  );
}

function setSlotsAndScroll({ filteredFreeSlots, spanType, currentDate, user }) {
  availabilityBroadcast.publish("RESET_LOADING_FIND_TIMES");
  if (!filteredFreeSlots) {
    return;
  }

  if (filteredFreeSlots.length === 0) {
    showNoFreeTimesFound();
    mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", []);
    if (spanType === SLOTS_SPAN_TYPE.NEXT_WEEK) {
      mainCalendarBroadcast.publish(
        "JUMP_TO_DATE_AND_SCROLL_TO_TIME",
        currentDate
      );
    } else if (spanType === SLOTS_SPAN_TYPE.ONLY_THIS_WEEK) {
      // jump to today
      mainCalendarBroadcast.publish(
        "JUMP_TO_DATE_AND_SCROLL_TO_TIME",
        currentDate
      );
    }
    return;
  }

  let temporaryEvents = [];
  filteredFreeSlots.forEach((slot, index) => {
    const { eventStart, eventEnd } = slot;
    temporaryEvents = temporaryEvents.concat(
      createTemporaryEvent({
        startTime: eventStart,
        endTime: eventEnd,
        index,
        resourceId: user?.email,
      })
    );
  });

  // jump and scroll to first event
  const { eventStart } = filteredFreeSlots[0];
  mainCalendarBroadcast.publish("JUMP_TO_DATE_AND_SCROLL_TO_TIME", eventStart);
  mainCalendarBroadcast.publish("SET_TEMPORARY_EVENTS", temporaryEvents);
}
