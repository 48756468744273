import React from 'react';
import {
  ZOOM_CIRCULAR_ICON
} from '../services/globalVariables';

function ZoomLoginButton(props) {
  return (
    <button
      className={`${props.containerClassName || ""} zoom-login-container`}
      style={{border: 1, color: 'white', width: props.containerWidth}}
      onClick={props.onClickLogin}
    >
      <img
        alt=""
        width="20px"
        height="20px"
        src={ZOOM_CIRCULAR_ICON}
      />

      <div className={`color-white ${props.labelClassName || ""}`}>
        {props.label || "Login"}
      </div>
    </button>
  )
}

export default ZoomLoginButton
