import React from "react";
import ContactHandle, { type ContactHandleProps } from "../contactHandle";
import TelegramLogo from "./telegramLogo";

export default function TelegramHandle(props: ContactHandleProps) {
  return (
    <ContactHandle {...props}>
      <TelegramLogo size={12} className="full-attendee-list-attendee-handle" />
    </ContactHandle>
  )
}
