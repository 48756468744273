import React from "react";
import CustomSelect from "../select";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getReactSelectBaseStyle } from "../select/styles";
import {
  SORT_BY_CHRONOLOGICALLY_OPTION,
  SORT_BY_VOTE_OPTION,
} from "../scheduling/schedulingSharedVariables";

export default function SelectVoteOrChronologicallyDropdown({value, onSet}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <CustomSelect
      isSearchable={false}
      className={classNames(
        "w-44",
        isDarkMode ? "dark-mode-select" : "",
        "z-index-2"
      )}
      value={value}
      options={[SORT_BY_CHRONOLOGICALLY_OPTION, SORT_BY_VOTE_OPTION]}
      onChange={onSet}
      overrideStyles={getReactSelectBaseStyle({
        isDarkMode: false,
      })}
    />
  );
}
