export type GroupSchedulingAttendeeProp = {
  email: string;
  label: string;
  name?: string;
  value: string;
  isOptional?: boolean;
}

const ATTENDEE_ACCESS_STATE_BLOCKED = "blocked";
const ATTENDEE_ACCESS_STATE_NO_ACCESS = "no_access";
const ATTENDEE_ACCESS_STATE_UNBLOCKED = "unblocked";

export const ATTENDEE_ACCESS_STATES = {
  blocked: ATTENDEE_ACCESS_STATE_BLOCKED,
  noAccess: ATTENDEE_ACCESS_STATE_NO_ACCESS,
  unblocked: ATTENDEE_ACCESS_STATE_UNBLOCKED,
} as const;

type AttendeeAccessTypes =
  typeof ATTENDEE_ACCESS_STATE_BLOCKED |
  typeof ATTENDEE_ACCESS_STATE_NO_ACCESS |
  typeof ATTENDEE_ACCESS_STATE_UNBLOCKED;

export type GroupSchedulingAttendeeType = {
  access_state: AttendeeAccessTypes;
  isMissingTeamPlan?: boolean;
  isPendingTeamPlan?: boolean;
  email: string;
  name?: string;
  isOptional?: boolean;
}

export const FETCH_TEAM_PLAN_INVITES = "FETCH_TEAM_PLAN_INVITES";
export const ACCEPT_TEAM_PLAN_INVITE = "ACCEPT_TEAM_PLAN_INVITE";
