import classNames from "classnames";
import React, { useState } from "react";
import { HelpCircle } from "react-feather";
import { useSelector } from "react-redux";

export default function MoreInformationHint({
  containerClassName = "",
  title,
  variables,
  hintContainerClassName = "",
  description = "",
  iconContainerClassName = "",
  hintContainerClassNameLocation = "",
  hintContainerWidth = "",
  hideTitle = false,
}) {
  //variables: array of availabile variables
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const [shouldDisplayHint, setDisplayHint] = useState(false);

  return (
    <div className={classNames("relative", containerClassName)}>
      <div
        className={iconContainerClassName || ""}
        onMouseEnter={() => setDisplayHint(true)}
        onMouseLeave={() => setDisplayHint(false)}
      >
        <HelpCircle className="clickable-icon" size={18} />
      </div>

      {shouldDisplayHint ? (
        <div
          className={classNames(
            "absolute more-information-modal",
            hintContainerWidth || "w-max-content-important",
            hintContainerClassNameLocation,
            hintContainerClassName ||
              (isDarkMode
                ? "background-color-modal-background-color"
                : "bg-white")
          )}
        >
          {hideTitle ? null : <div>{title || "Available variables:"}</div>}

          <div className={classNames(hideTitle ? "" : "mt-1")}>
            {variables?.map((v, index) => {
              return (
                <div key={`more-information-${index}`} className="mt-1">
                  {v}
                </div>
              );
            })}
            {description ?? ""}
          </div>
        </div>
      ) : null}
    </div>
  );
}
