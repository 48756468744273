import { createConsumer } from "@rails/actioncable";
import { getCurrentUserEmail } from "../lib/localData";
import {
  getUpcomingCalendarUserCalendarIDs,
  getUserEmail,
} from "../lib/userFunctions";
import { handleError } from "./commonUsefulFunctions";
import { isLocal } from "./devFunctions";
import { isEmptyArrayOrFalsey } from "./typeGuards";
import { isCalendarSelected } from "../lib/calendarFunctions";
import {
  getCalendarIsPrimary,
  getCalendarUserCalendarID,
} from "./calendarAccessors";
import { shouldHideDelegatedUserCalendar } from "./maestroFunctions";

export const IS_DEBUGGING_LOCAL_WEBHOOKS = false;

function getActionCableURL() {
  const PROD_API_CABLE_URL = "wss://prod.vimcal.com/cable";
  if (process.env.REACT_APP_API_CABLE_URL) {
    return process.env.REACT_APP_API_CABLE_URL;
  }
  if (isLocal()) {
    if (IS_DEBUGGING_LOCAL_WEBHOOKS) {
      return PROD_API_CABLE_URL;
    }
    return null;
  }
  // default to prod
  return PROD_API_CABLE_URL;
}

export function createWebhooksConsumerForUser(userEmail) {
  return createConsumer(
    getActionCableURL() + "?email=" + (userEmail || getCurrentUserEmail() || "")
  );
}

export function createWebhooksConsumerForAllUsers(allLoggedInUsers) {
  if (isLocal() && !IS_DEBUGGING_LOCAL_WEBHOOKS) {
    return null;
  }
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return null;
  }
  const webhooksConsumerForAllUsers = {};
  allLoggedInUsers.forEach((user) => {
    const userEmail = getUserEmail(user);
    if (!webhooksConsumerForAllUsers[userEmail]) {
      webhooksConsumerForAllUsers[userEmail] =
        createWebhooksConsumerForUser(userEmail);
    }
  });
  return webhooksConsumerForAllUsers;
}

export function disconnectWebhooksForAllCalendarsForUser(webhooksForUser) {
  try {
    if (isEmptyArrayOrFalsey(webhooksForUser?.subscriptions?.subscriptions)) {
      return;
    }
    // disconnect on client side:
    // https://stackoverflow.com/questions/40495351/how-to-close-connection-in-action-cable
    const { subscriptions } = webhooksForUser.subscriptions;
    subscriptions.forEach((subscription) => {
      webhooksForUser.subscriptions.remove(subscription);
    });
  } catch (error) {
    handleError(error);
  }
}

export function disconnectWebhooksForUserCalendarID({
  webhooksForUser,
  userCalendarID,
}) {
  // disconnect on client side:
  // https://stackoverflow.com/questions/40495351/how-to-close-connection-in-action-cable
  try {
    if (
      isEmptyArrayOrFalsey(webhooksForUser?.subscriptions?.subscriptions) ||
      !userCalendarID
    ) {
      return;
    }
    // disconnect on client side:
    // https://stackoverflow.com/questions/40495351/how-to-close-connection-in-action-cable
    const { subscriptions } = webhooksForUser.subscriptions;

    const matchingSubscription = subscriptions.find((subscription) =>
      subscription.identifier.includes(userCalendarID)
    );
    if (matchingSubscription && webhooksForUser.subscriptions.remove) {
      webhooksForUser.subscriptions.remove(matchingSubscription);
    }
  } catch (error) {
    handleError(error);
  }
}

export function disconnectWebhooksForAllUsers(webhooksForUser) {
  try {
    if (!webhooksForUser) {
      return;
    }
    Object.values(webhooksForUser).forEach((webhook) => {
      webhook.disconnect();
    });
  } catch (error) {
    handleError(error);
  }
}

export function reloadWebhooksForAllUsers(webhooksForAllUsers) {
  try {
    if (!webhooksForAllUsers) {
      return;
    }
    Object.values(webhooksForAllUsers).forEach((webhook) => {
      if (webhook?.connection?.disconnected) {
        // no point in ensuring connection on disconnected webhook
        return;
      }
      if (!webhook?.connection?.isActive?.()) {
        // only call ensureActiveConnection if the connection is not active
        // isActive() method is generally used to check whether the WebSocket connection is currently open and functioning. It can return false in several situations where the connection is disrupted or inactive
        return;
      }
      webhook.ensureActiveConnection?.();

      // we don't want to call reload here because it will create a new subscription regardless if it already exists
      // webhook.subscriptions?.reload?.();
    });
  } catch (error) {
    handleError(error);
  }
}

export function doesWebhookSubscriptionExistForUserCalendarID({
  webhooksForAllUsers,
  userCalendarID,
}) {
  try {
    if (!webhooksForAllUsers || !userCalendarID) {
      return false;
    }
    return Object.values(webhooksForAllUsers).some((webhook) => {
      return webhook?.subscriptions?.subscriptions?.some((subscription) =>
        subscription?.identifier?.includes(userCalendarID)
      );
    });
  } catch (error) {
    handleError(error);
    return false;
  }
}

export function shouldWebhookAlwaysStayOn({
  calendar,
  masterAccount,
  allCalendars,
  allLoggedInUsers,
  skipCheckForSelected = false, // race condition
}) {
  if (shouldHideDelegatedUserCalendar({
    calendar,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
  })) {
    return false;
  }
  const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({
    masterAccount,
    allCalendars,
    allLoggedInUsers,
  });
  const userCalendarID = getCalendarUserCalendarID(calendar);
  return (
    (!skipCheckForSelected && isCalendarSelected(calendar)) ||
    upcomingCalendarIDs?.includes(userCalendarID) ||
    getCalendarIsPrimary(calendar)
  );
}
