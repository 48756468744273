import React from "react";
import { useSelector } from "react-redux";
import VimcalLogoWhite from "../logo/vimcalLogoWhite";
import VimcalLogoDarkBlue from "../logo/vimcalLogoDarkBlue";

export default function MobileSignupIcon() {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <div className="flex justify-start items-center mt-10 w-80">
      {isDarkMode ? <VimcalLogoWhite /> : <VimcalLogoDarkBlue />}
    </div>
  );
}
