import React from "react";

type GradientButtonProps = {
  disabled?: boolean;
  gradient: string;
  height?: number;
  label: string;
  onClick: () => void;
  size?: number;
  textColor?: string;
  width?: number;
}

const GradientButton = ({
  disabled = false,
  gradient = "", // https://www.w3schools.com/css/css3_gradients.asp
  height = 50,
  label = "",
  onClick = () => null,
  size = 20,
  textColor = "white",
  width = 250,
}: GradientButtonProps) => {
  return (
    <button
      className="custom-button-box overflow-y-hidden rounded-lg"
      disabled={disabled}
      onClick={onClick}
      style={{
        background: `linear-gradient(${gradient})`,
        height: height,
        width: width,
      }}
      type="button"
    >
      <div
        className="font-weight-300 flex items-center"
        style={{ fontSize: size, color: textColor }}
      >
        {label}
      </div>
    </button>
  );
};

export default GradientButton;
