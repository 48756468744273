import React, { useEffect, useMemo, useState } from "react";
import ColoredLine from "../../../line";
import { getInputStringFromEvent } from "../../../../lib/stringFunctions";
import { CustomSelect } from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  DEFAULT_SSO_VENDOR_OPTION,
  SAML_CONFIG_KEY,
  SAML_CONFIG_MOCK_VALUES,
  SSO_VENDOR_NAMES,
  createSSOIdentityProviderConfig,
  getSSOIdentityConfig,
  getSSOVendorOptions,
} from "../../../../lib/ssoFunctions";
import { getReactSelectBaseStyle } from "../../../select/styles";
import SaveButton from "../../common/saveButton";
import { getSAMLAccountID, getUserEmail } from "../../../../lib/userFunctions";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import SpinnerV2 from "../../../spinnerV2";
import { isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import { Copy } from "react-feather";
import { copyContent } from "../../../../services/appFunctions";
import { SECOND_IN_MS } from "../../../../services/globalVariables";
import { useIsMounted } from "../../../../services/customHooks/useIsMounted";

export default function SsoAdminSettingsContainer() {
  const [name, setName] = useState("");
  const [vendor, setVendor] = useState(SSO_VENDOR_NAMES.OKTA);
  const [idpMetadataURL, setIdpMetadataURL] = useState("");
  const [idpMetadataXML, setIdpMetadataXML] = useState("");
  const vendorOptions = useMemo(() => getSSOVendorOptions(), []);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [serviceProviderConfig, setServiceProviderConfig] = useState({});
  const [copiedEntityID, setCopiedEntityID] = useState(false);
  const [copiedEntityURL, setCopiedEntityURL] = useState(false);
  const [copiedSLOUrl, setCopiedSLOUrl] = useState(false);
  const [copiedEntityXML, setCopiedEntityXML] = useState(false);
  const [isWaitingForGetConfig, setIsWaitingForGetConfig] = useState(false);
  const componentIsMounted = useIsMounted();

  useEffect(() => {
    fetchServiceProviderConfig();
  }, []);

  const fetchServiceProviderConfig = async () => {
    setIsWaitingForGetConfig(true);
    const config = await getSSOIdentityConfig(getUserEmail(currentUser));
    setIsWaitingForGetConfig(false);
    if (isEmptyObjectOrFalsey(config)) {
      return;
    }
    setServiceProviderConfig(config);
  };

  const renderName = () => {
    return (
      <div className="sso-admin-input-width">
        <div className="default-font-size mb-2">Name</div>
        <input
          className="send-email-input default-font-size"
          value={name}
          onChange={(e) => {
            setName(getInputStringFromEvent(e));
          }}
          placeholder="Enter name"
        />
      </div>
    );
  };

  const renderCopied = () => {
    return <div className="default-font-size font-weight-300">Copied!</div>;
  };

  const renderVendor = () => {
    return (
      <div className="sso-admin-input-width">
        <div className="default-font-size mb-2">Vendor</div>
        <CustomSelect
          components={{ DropdownIndicator }}
          className={classNames(
            isDarkMode ? "dark-mode-select" : "",
            "margin-left-1px",
          )}
          classNamePrefix="dark-mode-modal"
          value={
            vendorOptions.find((option) => option.value === vendor) ||
            DEFAULT_SSO_VENDOR_OPTION
          }
          options={vendorOptions}
          onChange={(option) => setVendor(option.value)}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
          })}
          alwaysShowIndicator={true}
        />
      </div>
    );
  };

  const renderServiceProviderEntitySLO = () => {
    if (!serviceProviderConfig?.[SAML_CONFIG_KEY.SLO_URL]) {
      return null;
    }
    return (
      <div className="default-font-size">
        <div className="font-weight-400 mb-1 flex gap-2">
          Service Provider (Vimcal) Single Logout Service (SLO) URL
          <Copy
            size={14}
            className="clickable-icon"
            onClick={() => {
              copyContent(serviceProviderConfig[SAML_CONFIG_KEY.SLO_URL]);
              setCopiedSLOUrl(true);
              setTimeout(() => {
                if (!componentIsMounted.current) {
                  return;
                }
                setCopiedSLOUrl(false);
              }, 2 * SECOND_IN_MS);
            }}
          />
          {copiedSLOUrl ? renderCopied() : null}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <div className="secondary-text-color">
            {serviceProviderConfig[SAML_CONFIG_KEY.SLO_URL]}
          </div>
        </div>
      </div>
    );
  };

  const renderIDPMetadataURL = () => {
    return (
      <div className="sso-admin-input-width">
        <div className="default-font-size mb-2">
          Identity Provider Metadata URL
        </div>
        <input
          className="send-email-input default-font-size"
          value={idpMetadataURL}
          onChange={(e) => {
            setIdpMetadataURL(getInputStringFromEvent(e));
          }}
          placeholder="Enter URL"
        />
      </div>
    );
  };

  const renderServiceProviderEntityID = () => {
    if (!serviceProviderConfig?.[SAML_CONFIG_KEY.ENTITY_ID]) {
      return null;
    }
    return (
      <div className="default-font-size">
        <div className="font-weight-400 mb-1 flex gap-2">
          Service Provider (Vimcal) Entity ID
          <Copy
            size={14}
            className="clickable-icon"
            onClick={() => {
              copyContent(serviceProviderConfig[SAML_CONFIG_KEY.ENTITY_ID]);
              setCopiedEntityID(true);
              setTimeout(() => {
                if (!componentIsMounted.current) {
                  return;
                }
                setCopiedEntityID(false);
              }, 2 * SECOND_IN_MS);
            }}
          />
          {copiedEntityID ? renderCopied() : null}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <div className="secondary-text-color">
            {serviceProviderConfig[SAML_CONFIG_KEY.ENTITY_ID]}
          </div>
        </div>
      </div>
    );
  };

  const renderServiceProviderEntityURL = () => {
    if (!serviceProviderConfig?.[SAML_CONFIG_KEY.ACS_URL]) {
      return null;
    }
    return (
      <div className="default-font-size">
        <div className="font-weight-400 mb-1 flex gap-2">
          Service Provider (Vimcal) Assertion Consumer Service (ACS) URL
          <Copy
            size={14}
            className="clickable-icon"
            onClick={() => {
              copyContent(serviceProviderConfig[SAML_CONFIG_KEY.ACS_URL]);
              setCopiedEntityURL(true);
              setTimeout(() => {
                if (!componentIsMounted.current) {
                  return;
                }
                setCopiedEntityURL(false);
              }, 2 * SECOND_IN_MS);
            }}
          />
          {copiedEntityURL ? renderCopied() : null}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <div className="secondary-text-color">
            {serviceProviderConfig[SAML_CONFIG_KEY.ACS_URL]}
          </div>
        </div>
      </div>
    );
  };

  const renderServiceProviderEntityXML = () => {
    if (!serviceProviderConfig?.[SAML_CONFIG_KEY.ACS_XML]) {
      return null;
    }
    return (
      <div className="default-font-size">
        <div className="font-weight-400 mb-1 flex gap-2">
          Service Provider (Vimcal) XML
          <Copy
            size={14}
            className="clickable-icon"
            onClick={() => {
              copyContent(serviceProviderConfig[SAML_CONFIG_KEY.ACS_XML]);
              setCopiedEntityXML(true);
              setTimeout(() => {
                if (!componentIsMounted.current) {
                  return;
                }
                setCopiedEntityXML(false);
              }, 2 * SECOND_IN_MS);
            }}
          />
          {copiedEntityXML ? renderCopied() : null}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <div className="secondary-text-color">
            {serviceProviderConfig[SAML_CONFIG_KEY.ACS_XML]}
          </div>
        </div>
      </div>
    );
  };

  const renderIDPMetadataXML = () => {
    return (
      <div className="sso-admin-input-width">
        <div className="default-font-size mb-2">
          Identity Provider Metadata XML
        </div>
        <textarea
          className="email-input-container h-32 default-font-size"
          value={idpMetadataXML}
          onChange={(e) => {
            setIdpMetadataXML(getInputStringFromEvent(e));
          }}
          placeholder="Enter XML"
        />
      </div>
    );
  };

  const onSubmit = async () => {
    setIsWaitingForResponse(true);
    const response = await createSSOIdentityProviderConfig({
      samlAccountId: getSAMLAccountID(masterAccount),
      vendor,
      name,
      metadataURL: idpMetadataURL,
      metadataXML: idpMetadataXML,
      userEmail: getUserEmail(currentUser),
    });
    setIsWaitingForResponse(false);
    if (isEmptyObjectOrFalsey(response)) {
      return;
    }
    setServiceProviderConfig(response);
    setName("");
    setIdpMetadataURL("");
    setIdpMetadataXML("");
  };

  const renderSpinner = () => {
    return (
      <div className="flex items-center justify-center">
        <SpinnerV2 variant="small" />
      </div>
    );
  };

  const renderServiceProviderInfo = () => {
    if (isEmptyObjectOrFalsey(serviceProviderConfig)) {
      return null;
    }
    return (
      <div>
        <div className="font-size-16 mt-5">
          Service Provider (Vimcal) Information
        </div>
        <ColoredLine inputClassName={"mt-2 mb-4"} />
        <div className="default-font-size secondary-text-color">
          Below is some information about Vimcal, the service provider, that the
          identity provider (Okta, Google, Azure, etc.) may ask you for.
        </div>

        <div className="flex flex-col gap-5 mt-4">
          {renderServiceProviderEntityID()}
          {renderServiceProviderEntityURL()}
          {renderServiceProviderEntitySLO()}
          {renderServiceProviderEntityXML()}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex h-full flex-col">
        <div className="font-size-16">SSO config</div>
        <ColoredLine inputClassName={"my-2 flex-shrink-0"} />
        <div className="flex flex-col gap-5 mt-4">
          {renderName()}
          {renderVendor()}
          {renderIDPMetadataURL()}
          {renderIDPMetadataXML()}
          {isWaitingForGetConfig ? renderSpinner() : null}
        </div>
        {renderServiceProviderInfo()}
      </div>

      <div className="pr-2.5 mt-3.5">
        <SaveButton
          onClick={onSubmit}
          label={
            isWaitingForResponse ? <SpinnerV2 variant="small" /> : "Create"
          }
        />
      </div>
    </div>
  );
}
