import React from "react";
import { Search } from "react-feather";
import classNames from "classnames";
import broadcast from "../broadcasts/broadcast";

export default function CommandCenterSearchInput({ inputClassname }) {
  return (
    <div
      className={classNames(
        "command-center-search-input-button",
        "flex items-center font-weight-300 select-none cursor-pointer duration-200",
        inputClassname || "",
        "hoverable-secondary-text-color"
      )}
      onClick={() => {
        broadcast.publish("TURN_ON_COMMAND_CENTER");
      }}
    >
      <Search size={14} />
      <div className="ml-2 default-font-size">Search & commands</div>
    </div>
  );
}
