import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import OnboardingSaveButton from "./onboardingSaveButton";
import Mousetrap from "mousetrap";
import {
  hasEventPreventDefault,
  getActiveCommandCentersKey,
  isMac,
} from "../../services/commonUsefulFunctions";
import { CONFETTI_DURATION } from "./sharedVariables";
import OnboardingConfetti from "./onboardingConfetti";
import classNames from "classnames";
import _ from "underscore";
import { useSelector } from "react-redux";
import { OnboardStep, trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import MediumShortcutTile from "../shortcutTiles/mediumShortcutTile";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import { KEYBOARD_EVENT_KEYS } from "../../services/keyboardEventFunctions";

const ORIGINAL_TEXT =
  "<div>Hi Mikaela, </div><div><br></div><div>It was great grabbing coffee with you today. I'd love to continue the next steps in our conversation.</div><p><br>&nbsp;</p><p><br></p>";

const FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
];

interface PasteInEmailProps {
  onClickNext: () => void
  step: OnboardStep
}

export default function PasteInEmail({ onClickNext, step }: PasteInEmailProps) {
  const [hasChanged, setHasChanged] = useState(false);
  const [content, setContent] = useState(ORIGINAL_TEXT);
  const [shouldDisplayConfetti, setShouldDisplayConfetti] = useState(false);
  const [confettiTimer, setConfettiTimer] = useState<number>();

  const currentUser = useSelector(state => state.currentUser);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const isMounted = useIsMounted();

  const contentRef = useRef<ReactQuill>(null);

  const handleKeyEnter = useCallback((e: KeyboardEvent) => {
    if (e.key === KEYBOARD_EVENT_KEYS.ENTER && hasChanged) {
      hasEventPreventDefault(e);
      onClickNext();
    }
  }, [hasChanged, onClickNext]);

  const modules = useMemo(() => (
    {
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {
              if (hasChanged) {
                onClickNext();
              }
            },
          },
        },
      },
    }
  ), [hasChanged, onClickNext]);

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    contentRef.current?.getEditor().setSelection(200, 0);

    Mousetrap.bind(["enter"], onClickNext);
    document.addEventListener("keydown", handleKeyEnter);

    return () => {
      window.clearTimeout(confettiTimer);
      Mousetrap.reset();
      document.removeEventListener("keydown", handleKeyEnter);
    };
  }, []);

  const onUpdateText = useCallback((html: string) => {
    if (!hasChanged && html.length > ORIGINAL_TEXT.length + 40) {
      setHasChanged(true);

      contentRef.current?.blur();
      setShouldDisplayConfetti(true);
      setConfettiTimer(window.setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setShouldDisplayConfetti(false);
      }, CONFETTI_DURATION - 500));
    }

    setContent(html);
  }, [hasChanged]);

  return (
    <div
      className="onboarding-container onboarding-container-paste-in-email"
      id="onboarding-email-background"
    >
      <div className="section-title">Pre-formatted and ready to use</div>

      {shouldDisplayConfetti ? <OnboardingConfetti /> : null}

      <div className="onboarding-email-background box-shadow-6">
        <div className="display-flex align-items-center font-size-16 font-weight-300">
          <div className="mr-10">To</div>
          <div>Mikaela Smith</div>
        </div>

        <div className="default-font-size mt-6">Next steps</div>
        <div className="width-100-percent">
          <ReactQuill
            ref={contentRef}
            theme="snow"
            onChange={onUpdateText}
            value={content}
            bounds=".event-form-description-wrapper"
            defaultValue=""
            modules={modules}
            readOnly={false}
            className="onboarding-paste-email mt-8"
            formats={FORMATS}
          />

          <div className="display-flex align-items-center pt-4 mt-3 send-email-bottom-container">
            <div
              id="onboarding-email-send-button"
              className={classNames(
                "mr-5 default-font-size",
                hasChanged
                  ? "cursor-pointer hover:text-blue-500 delay-100 transition-colors"
                  : "secondary-text-color",
              )}
              onClick={hasChanged ? onClickNext : _.noop}
            >
              Send
            </div>
          </div>

          <div className="display-flex justify-content-center mt-5">
            {hasChanged ? (
              <OnboardingSaveButton
                buttonText="Continue"
                onClick={onClickNext}
              />
            ) : (
              <div className="font-size-16 flex justify-center items-center mt-5">
                Paste in your copied availability:
                <div className="ml-4">
                  <MediumShortcutTile
                    text={getActiveCommandCentersKey()}
                    isLeftHandSide={true}
                    width={isMac() ? undefined : 80}
                  />
                </div>
                <div className="ml-1">
                  <MediumShortcutTile text={"V"} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="blank-onboarding-save-button"></div>
    </div>
  );
}
