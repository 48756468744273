import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import _ from "underscore";
import "react-quill/dist/quill.snow.css";
import classNames from "classnames";
import { configureBlockFormat } from "../lib/quill/blots";

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

// https://stackoverflow.com/questions/50296878/allow-tags-in-react-quill-component
// https://github.com/zenoamaro/react-quill#custom-formats
// change bold tag from <strong/> to <bold/>
let Inline = Quill.import("blots/inline");
class BoldBlot extends Inline {}
BoldBlot.blotName = "bold";
BoldBlot.tagName = "b";
Quill.register("formats/bold", BoldBlot);

// https://github.com/quilljs/quill/issues/3065
let Block = Quill.import("blots/block");
class DivBlock extends Block {}
DivBlock.tagName = "DIV";
// true means we overwrite
Quill.register("blots/block", DivBlock, true);

const FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
  // "indent", // TODO: fix, indents have a lot of problems right now
  "clean",
];

class EventFormDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorHtml: this.props.description || "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.createModules = this.createModules.bind(this);
    this.escapeKey = this.escapeKey.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  handleChange(html) {
    this.props.onChange && this.props.onChange(html);
    this.setState({ editorHtml: html });
  }

  componentDidMount() {
    configureBlockFormat(false);
  }

  render() {
    return (
      <ReactQuill
        theme="snow"
        onChange={this.handleChange}
        value={this.state.editorHtml}
        bounds={".event-form-description-wrapper"}
        defaultValue={this.props.description || ""}
        modules={this.createModules()}
        formats={FORMATS}
        placeholder={"Add description"}
        readOnly={false}
        id={this.props.id}
        tabIndex={this.props.tabIndex}
        onFocus={this.props.onFocus ? this.props.onFocus : _.noop}
        className={classNames(
          this.props.shouldHighLightField ? "highlight-field-color" : "",
        )
        }
        onBlur={this.onBlur}
        ref={this.props.elementRef}
        onKeyDown={this.props.onKeyChange ? this.props.onKeyChange : _.noop}
      />
    );
  }

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  createModules() {
    return {
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          // tab: {
          //   key: 9,
          //   handler: this.props.onClickTab
          // },
          escape: {
            key: 27,
            handler: this.escapeKey,
          },
        },
      },
    };
  }

  escapeKey() {
    this.props.onClickEscape && this.props.onClickEscape(this.state.editorHtml);
  }

  onBlur() {
    this.props.onBlurDescription &&
      this.props.onBlurDescription(this.state.editorHtml);
  }
}

export default EventFormDescription;
