export const EMOJI_SUMMARY_DICTIONARY = {
  'coffee': {
    id: "coffee",
    name: "Hot Beverage",
    short_names: ["coffee"],
    colons: ":coffee:",
    emoticons: [],
    unified: "2615",
    skin: null,
    native: '☕'
  },
  'party': {
    id: "tada",
    name: "Party Popper",
    short_names: ["tada"],
    colons: ":tada:",
    emoticons: [],
    unified: "1f389",
    skin: null,
    native: '🎉'
  },
  'celebrate': {
    id: "tada",
    name: "Party Popper",
    short_names: ["tada"],
    colons: ":tada:",
    emoticons: [],
    unified: "1f389",
    skin: null,
    native: '🎉'
  },
  'happy hour': {
    id: "champagne",
    name: "Bottle with Popping Cork",
    short_names: ["champagne"],
    colons: ":champagne:",
    emoticons: [],
    unified: "1f37e",
    skin: null,
    native: "🍾"
  },
  'drinks': {
    id: "beer",
    name: "Beer Mug",
    short_names: ["beer"],
    colons: ":beer:",
    emoticons: [],
    unified: "1f37a",
    skin: null,
    native: "🍺"
  },
  'gym': {
    id: "muscle",
    name: "Flexed Biceps",
    short_names: ["muscle"],
    colons: ":muscle:",
    emoticons: [],
    unified: "1f4aa",
    skin: 1,
    native: "💪"
  },
  'focus': {
    id: "memo",
    name: "Memo",
    short_names: ["memo", "pencil"],
    colons: ":memo:",
    emoticons: [],
    unified: "1f4dd",
    skin: null,
    native: "📝",
  },
  'study': {
    id: "memo",
    name: "Memo",
    short_names: ["memo", "pencil"],
    colons: ":memo:",
    emoticons: [],
    unified: "1f4dd",
    skin: null,
    native: "📝",
  },
  'lunch': {
    id: "taco",
    name: "Taco",
    short_names: ["taco"],
    colons: ":taco:",
    emoticons: [],
    unified: "1f32e",
    skin: null,
    native: "🌮"
  },
  'dinner': {
    colons: ":knife_fork_plate:",
    emoticons: [],
    id: "knife_fork_plate",
    name: "Knife Fork Plate",
    native: "🍽️",
    short_names: ["knife_fork_plate"],
    skin: null,
    unified: "1f37d-fe0f"
  },
  'breakfast': {
    id: "fried_egg",
    name: "Cooking",
    short_names: ["fried_egg", "cooking"],
    colons: ":fried_egg:",
    emoticons: [],
    unified: "1f373",
    skin: null,
    native: "🍳"
  },
  'bball': {
    id: "basketball",
    name: "Basketball and Hoop",
    short_names: ["basketball"],
    colons: ":basketball:",
    emoticons: [],
    unified: "1f3c0",
    skin: null,
    native: "🏀"
  },
  'basketball': {
    id: "basketball",
    name: "Basketball and Hoop",
    short_names: ["basketball"],
    colons: ":basketball:",
    emoticons: [],
    unified: "1f3c0",
    skin: null,
    native: "🏀"
  },
  'soccer': {
    id: "soccer",
    name: "Soccer Ball",
    short_names: ["soccer"],
    colons: ":soccer:",
    emoticons: [],
    unified: "26bd",
    skin: null,
    native: "⚽"
  },
  'football': {
    id: "football",
    name: "American Football",
    short_names: ["football"],
    colons: ":football:",
    emoticons: [],
    unified: "1f3c8",
    skin: null,
    native: "🏈"
  },
  'tennis': {
    id: "tennis",
    name: "Tennis Racquet and Ball",
    short_names: ["tennis"],
    colons: ":tennis:",
    emoticons: [],
    unified: "1f3be",
    skin: null,
    native: "🎾"
  },
  'flight': {
    id: "airplane",
    name: "Airplane",
    short_names: ["airplane"],
    colons: ":airplane:",
    emoticons: [],
    unified: "2708-fe0f",
    skin: null,
    native: "✈️"
  },
  'launch': {
    id: "rocket",
    name: "Rocket",
    short_names: ["rocket"],
    colons: ":rocket:",
    emoticons: [],
    unified: "1f680",
    skin: null,
    native: "🚀"
  },
  'school': {
    id: "school_satchel",
    name: "School Satchel",
    short_names: ["school_satchel"],
    colons: ":school_satchel:",
    emoticons: [],
    unified: "1f392",
    skin: null,
    native: "🎒",
  },
  'date night': {
    id: "wine_glass",
    name: "Wine Glass",
    short_names: ["wine_glass"],
    colons: ":wine_glass:",
    emoticons: [],
    unified: "1f377",
    skin: null,
    native: "🍷",
  },
  'valentine': {
    id: "heart",
    name: "Heavy Black Heart",
    short_names: ["heart"],
    colons: ":heart:",
    emoticons: ["<3"],
    unified: "2764-fe0f",
    skin: null,
    native: "❤️"
  },
  'comedy': {
    id: "rolling_on_the_floor_laughing",
    name: "Rolling on the Floor Laughing",
    short_names: ["rolling_on_the_floor_laughing"],
    colons: ":rolling_on_the_floor_laughing:",
    emoticons: [],
    unified: "1f923",
    skin: null,
    native: "🤣"
  },
  'movie': {
    id: "popcorn",
    name: "Popcorn",
    short_names: ["popcorn"],
    colons: ":popcorn:",
    emoticons: [],
    unified: "1f37f",
    skin: null,
    native: "🍿"
  },
  'concert': {
    id: "guitar",
    name: "Guitar",
    short_names: ["guitar"],
    colons: ":guitar:",
    emoticons: [],
    unified: "1f3b8",
    skin: null,
    native: "🎸"
  },
  'christmas': {
    id: "christmas_tree",
    name: "Christmas Tree",
    short_names: ["christmas_tree"],
    colons: ":christmas_tree:",
    emoticons: [],
    unified: "1f384",
    skin: null,
    native: "🎄"
  },
  'thanksgiving': {
    id: "turkey",
    name: "Turkey",
    short_names: ["turkey"],
    colons: ":turkey:",
    emoticons: [],
    unified: "1f983",
    skin: null,
    native: "🦃"
  },
  'ski ': {
    id: "skier",
    name: "Skier",
    short_names: ["skier"],
    colons: ":skier:",
    emoticons: [],
    unified: "26f7-fe0f",
    skin: null,
    native: "⛷️"
  },
  'snowboard': {
    id: "snowboarder",
    name: "Snowboarder",
    short_names: ["snowboarder"],
    colons: ":snowboarder:",
    emoticons: [],
    unified: "1f3c2",
    skin: 1,
    native: "🏂",
  },
  'graduation': {
    id: "mortar_board",
    name: "Graduation Cap",
    short_names: ["mortar_board"],
    colons: ":mortar_board:",
    emoticons: [],
    unified: "1f393",
    skin: null,
    native: "🎓",
  },
  'beach': {
    id: "beach_with_umbrella",
    name: "Beach with Umbrella",
    short_names: ["beach_with_umbrella"],
    colons: ":beach_with_umbrella:",
    emoticons: [],
    unified: "1f3d6-fe0f",
    skin: null,
    native: "🏖️"
  },
  'vacation': {
    id: "beach_with_umbrella",
    name: "Beach with Umbrella",
    short_names: ["beach_with_umbrella"],
    colons: ":beach_with_umbrella:",
    emoticons: [],
    unified: "1f3d6-fe0f",
    skin: null,
    native: "🏖️"
  },
  'sushi': {
    id: "sushi",
    name: "Sushi",
    short_names: ["sushi"],
    colons: ":sushi:",
    emoticons: [],
    unified: "1f363",
    skin: null,
    native: "🍣",
  },
  'ramen': {
    id: "ramen",
    name: "Steaming Bowl",
    short_names: ["ramen"],
    colons: ":ramen:",
    emoticons: [],
    unified: "1f35c",
    skin: null,
    native: "🍜",
  },
  'game': {
    colons: ":game_die:",
    emoticons: [],
    id: "game_die",
    name: "Game Die",
    native: "🎲",
    short_names: ["game_die"],
    skin: null,
    unified: "1f3b2"
  },
  'baseball': {
    id: "baseball",
    name: "Baseball",
    short_names: ["baseball"],
    colons: ":baseball:",
    emoticons: [],
    unified: "26be",
    skin: null,
    native: "⚾"
  },
  'golf': {
    id: "golf",
    name: "Flag in Hole",
    short_names: ["golf"],
    colons: ":golf:",
    emoticons: [],
    unified: "26f3",
    skin: null,
    native: "⛳"
  },
  'softball': {
    id: "tennis",
    name: "Tennis Racquet and Ball",
    short_names: ["tennis"],
    colons: ":tennis:",
    emoticons: [],
    unified: "1f3be",
    skin: null,
    native: "🎾"
  },
  'ping pong': {
    id: "table_tennis_paddle_and_ball",
    name: "Table Tennis Paddle and Ball",
    short_names: ["table_tennis_paddle_and_ball"],
    colons: ":table_tennis_paddle_and_ball:",
    emoticons: [],
    unified: "1f3d3",
    skin: null,
    native: "🏓"
  },
  'exercise': {
    id: "muscle",
    name: "Flexed Biceps",
    short_names: ["muscle"],
    colons: ":muscle:",
    emoticons: [],
    unified: "1f4aa",
    skin: 1,
    native: "💪"
  },
  'workout': {
    id: "muscle",
    name: "Flexed Biceps",
    short_names: ["muscle"],
    colons: ":muscle:",
    emoticons: [],
    unified: "1f4aa",
    skin: 1,
    native: "💪"
  },
  'work out': {
    id: "muscle",
    name: "Flexed Biceps",
    short_names: ["muscle"],
    colons: ":muscle:",
    emoticons: [],
    unified: "1f4aa",
    skin: 1,
    native: "💪"
  },
  'working out': {
    id: "muscle",
    name: "Flexed Biceps",
    short_names: ["muscle"],
    colons: ":muscle:",
    emoticons: [],
    unified: "1f4aa",
    skin: 1,
    native: "💪"
  },
  'walk': {
    colons: ":man-walking:",
    emoticons: [],
    id: "man-walking",
    name: "Man Walking",
    native: "🚶‍️",
    short_names: ["man-walking"],
    skin: 1,
    unified: "1f6b6-200d-2642-fe0f"
  },
  'walking': {
    colons: ":man-walking:",
    emoticons: [],
    id: "man-walking",
    name: "Man Walking",
    native: "🚶‍️",
    short_names: ["man-walking"],
    skin: 1,
    unified: "1f6b6-200d-2642-fe0f"
  },
  'hike': {
    colons: ":man-walking:",
    emoticons: [],
    id: "man-walking",
    name: "Man Walking",
    native: "🚶‍️",
    short_names: ["man-walking"],
    skin: 1,
    unified: "1f6b6-200d-2642-fe0f"
  },
  'hiking': {
    colons: ":man-walking:",
    emoticons: [],
    id: "man-walking",
    name: "Man Walking",
    native: "🚶‍️",
    short_names: ["man-walking"],
    skin: 1,
    unified: "1f6b6-200d-2642-fe0f"
  },
  'stroll': {
    colons: ":man-walking:",
    emoticons: [],
    id: "man-walking",
    name: "Man Walking",
    native: "🚶‍️",
    short_names: ["man-walking"],
    skin: 1,
    unified: "1f6b6-200d-2642-fe0f"
  },
  'yoga': {
    colons: ":woman_in_lotus_position:",
    emoticons: [],
    id: "woman_in_lotus_position",
    name: "Woman in Lotus Position",
    native: "🧘‍️",
    short_names: ["woman_in_lotus_position"],
    skin: 1,
    unified: "1f9d8-200d-2640-fe0f"
  },
  'meditate': {
    colons: ":woman_in_lotus_position:",
    emoticons: [],
    id: "woman_in_lotus_position",
    name: "Woman in Lotus Position",
    native: "🧘‍️",
    short_names: ["woman_in_lotus_position"],
    skin: 1,
    unified: "1f9d8-200d-2640-fe0f"
  },
  'meditation': {
    colons: ":woman_in_lotus_position:",
    emoticons: [],
    id: "woman_in_lotus_position",
    name: "Woman in Lotus Position",
    native: "🧘‍️",
    short_names: ["woman_in_lotus_position"],
    skin: 1,
    unified: "1f9d8-200d-2640-fe0f"
  },
  'trivia': {
    colons: ":game_die:",
    emoticons: [],
    id: "game_die",
    name: "Game Die",
    native: "🎲",
    short_names: ["game_die"],
    skin: null,
    unified: "1f3b2"
  },
  'cook': {
    colons: ":fried_egg:",
    emoticons: [],
    id: "fried_egg",
    name: "Cooking",
    native: "🍳",
    short_names: ["fried_egg", "cooking"],
    skin: null,
    unified: "1f373"
  },
  'cooking': {
    colons: ":fried_egg:",
    emoticons: [],
    id: "fried_egg",
    name: "Cooking",
    native: "🍳",
    short_names: ["fried_egg", "cooking"],
    skin: null,
    unified: "1f373"
  },
  'bake': {
    colons: ":cookie:",
    emoticons: [],
    id: "cookie",
    name: "Cookie",
    native: "🍪",
    short_names: ["cookie"],
    skin: null,
    unified: "1f36a"
  },
  'baking': {
    colons: ":cookie:",
    emoticons: [],
    id: "cookie",
    name: "Cookie",
    native: "🍪",
    short_names: ["cookie"],
    skin: null,
    unified: "1f36a"
  },
  'netflix': {
    id: "popcorn",
    name: "Popcorn",
    short_names: ["popcorn"],
    colons: ":popcorn:",
    emoticons: [],
    unified: "1f37f",
    skin: null,
    native: "🍿"
  },
  'running': {
    colons: ":man-running:",
    emoticons: [],
    id: "man-running",
    name: "Man Running",
    native: "🏃‍️",
    short_names: ["man-running"],
    skin: 1,
    unified: "1f3c3-200d-2642-fe0f"
  },
  'jog': {
    colons: ":man-running:",
    emoticons: [],
    id: "man-running",
    name: "Man Running",
    native: "🏃‍️",
    short_names: ["man-running"],
    skin: 1,
    unified: "1f3c3-200d-2642-fe0f"
  },
  'jogging': {
    colons: ":man-running:",
    emoticons: [],
    id: "man-running",
    name: "Man Running",
    native: "🏃‍️",
    short_names: ["man-running"],
    skin: 1,
    unified: "1f3c3-200d-2642-fe0f"
  },
  'biking': {
    colons: ":bicyclist:",
    emoticons: [],
    id: "bicyclist",
    name: "Bicyclist",
    native: "🚴",
    short_names: ["bicyclist"],
    skin: 1,
    unified: "1f6b4"
  },
  'bike': {
    colons: ":bicyclist:",
    emoticons: [],
    id: "bicyclist",
    name: "Bicyclist",
    native: "🚴",
    short_names: ["bicyclist"],
    skin: 1,
    unified: "1f6b4"
  },
  'cycling': {
    colons: ":bicyclist:",
    emoticons: [],
    id: "bicyclist",
    name: "Bicyclist",
    native: "🚴",
    short_names: ["bicyclist"],
    skin: 1,
    unified: "1f6b4"
  },
  'surf': {
    colons: ":man-surfing:",
    emoticons: [],
    id: "man-surfing",
    name: "Man Surfing",
    native: "🏄‍",
    short_names: ["man-surfing"],
    skin: 1,
    unified: "1f3c4-200d-2642-fe0f"
  },
  'surfing': {
    colons: ":man-surfing:",
    emoticons: [],
    id: "man-surfing",
    name: "Man Surfing",
    native: "🏄‍",
    short_names: ["man-surfing"],
    skin: 1,
    unified: "1f3c4-200d-2642-fe0f"
  },
  'ferry': {
    colons: ":ferry:",
    emoticons: [],
    id: "ferry",
    name: "Ferry",
    native: "⛴️",
    short_names: ["ferry"],
    skin: null,
    unified: "26f4-fe0f"
  }
};
