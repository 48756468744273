import React, { type DetailedHTMLProps, type ButtonHTMLAttributes } from "react";
import {
  BLUE_BUTTON,
  WHITE_BUTTON,
  RED_BUTTON,
  DEFAULT_BUTTON,
} from "../../services/globalVariables";
import classNames from "classnames";
import { hasStopEventPropagation } from "../../services/commonUsefulFunctions";
import SpinnerV2 from "../spinnerV2";
import { blurCalendar } from "../../services/appFunctions";

const BUTTON_TYPES = [
  BLUE_BUTTON,
  WHITE_BUTTON,
  RED_BUTTON,
  DEFAULT_BUTTON,
] as const;

interface CustomButtonProps extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "children"> {
  addMarginToRight?: boolean
  buttonType?: (typeof BUTTON_TYPES)[number]
  ignoreFocusStyles?: boolean
  label: React.ReactNode
  labelClassNameOverride?: string
  labelStyle?: React.CSSProperties
  shouldRenderPlusButton?: boolean
  shouldRenderSpinner?: boolean
  removeDefaultFontStyles?: boolean
  removeDefaultPadding?: boolean
}

/**
 * Changes from V1 / migration instructions for upgrading to V2:
 * - DEFAULT_BUTTON:
 *    - The V1 DEFAULT_BUTTON has overrides to padding, font-size, font-weight, and border-radius.
 *      that the other variants don't have. These have been removed in V2 for consistency.
 *    - The V1 DEFAULT_BUTTON was the only variant that did not have a min-width set. This has been
 *      added to be consistent with the other buttons.
 * - Focus styles:
 *    - Migrate away from the _WITHOUT_FOCUS button types, and add ignoreFocusStyles={true} as a
 *      prop when needed.
 *    - The focus styles are supported regardless of the button type, so they are now included in
 *      the default and red buttons. Where not needed, add ignoreFocusStyles={true}.
 *    - The shouldFocus prop has been deprecated. Instead, use the native autoFocus={true} prop.
 * - Disabling:
 *    - Instead of rendering the DisabledButton component, pass the disabled={true} prop to this button.
 *    - The V1 DisabledButton was always locked to exactly 90px wide. This has been removed for V2.
 * - Spinner:
 *    - The shouldRenderSpinner prop will now disable the button when true.
 *    - The shouldRenderSpinner prop will now hide the plus icon when true.
 *    - The spinner will now always render in the center of the button, without needing to apply
 *      positioning overrides.
 */
export default function CustomButtonV2({
  addMarginToRight,
  buttonType,
  className,
  disabled,
  ignoreFocusStyles,
  label,
  labelClassNameOverride,
  labelStyle,
  onClick,
  removeDefaultFontStyles=false,
  removeDefaultPadding=false,
  shouldRenderPlusButton,
  shouldRenderSpinner,
  ...props
}: CustomButtonProps) {
  const buttonTypeClass = (buttonType && BUTTON_TYPES.includes(buttonType))
    ? buttonType
    : DEFAULT_BUTTON;

  const fullClassName = classNames(
    "custom-button-v2",
    className,
    addMarginToRight ? "mr-2.5" : "",
    ignoreFocusStyles ? "" : "with-focus",
    removeDefaultPadding ? "" : "px-3 py-0",
    `${buttonTypeClass}-v2`,
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    hasStopEventPropagation(e);
    onClick && onClick(e);
    blurCalendar();
  };

  return (
    <button
      className={fullClassName}
      disabled={disabled || shouldRenderSpinner}
      onClick={(disabled || shouldRenderSpinner) ? undefined : handleClick}
      {...props}
    >
      <div
        style={labelStyle}
        className={classNames(
          "relative flex items-center",
          removeDefaultFontStyles ? "" : "default-font-size font-weight-500",
          labelClassNameOverride,
        )}
      >
        {shouldRenderSpinner ? (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <SpinnerV2 variant="small" />
          </div>
        ) : null}
        {/* When spinner visible, we still need to render the original label so the button doesn't shrink. */}
        <div className={shouldRenderSpinner ? "invisible" : ""}>
          {(shouldRenderPlusButton) ? <div className="font-size-20 mr-2 mb-0.5">+</div> : null}
          {label}
        </div>
      </div>
    </button>
  );
}
