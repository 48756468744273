import React from "react";

const VimcalLogo = ({ className }) => {
  return (
    <svg
      width="1058"
      height="860"
      viewBox="0 0 1058 860"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M-581.15 179.614C-682.474 287.311 -717.5 438.123 -626.359 553.946C-562.694 634.854 -461.512 669.096 -366.716 651.4C18.6992 278.522 389.067 157.427 672.133 133.644C715.903 129.967 757.541 128.62 796.78 129.018C874.355 47.0993 961.691 -26.0091 1057.3 -88.3277L1058 -90.0756C1058 -90.0756 645.006 -186.046 242.981 -128.764C-108.057 -78.7475 -454.36 44.865 -581.15 179.614Z"
        fill="url(#paint0_linear_1294_17)"
      />
      <path
        d="M9.62891 1098.57C4.22435 1108.28 -0.632452 1118.43 -4.88592 1129.01C-59.8682 1265.65 6.60585 1420.89 143.593 1475.74C188.929 1493.89 236.287 1498.78 281.381 1492.23C326.51 1485.92 370.624 1468 409.051 1437.91C417.059 1431.64 424.612 1425.01 431.703 1418.05C425.333 1387.63 419.896 1356.81 415.428 1325.61C387.561 1131.03 399.966 939.979 446.333 760.845C384.961 780.902 323.407 813.04 263.23 854.886C172.288 918.123 85.739 1002.73 9.62891 1098.57Z"
        fill="url(#paint1_linear_1294_17)"
      />
      <path
        d="M-301.417 650.758C-370.851 744.817 -372.12 877.015 -296.293 973.378C-229.515 1058.24 -121.47 1091.76 -22.8103 1067.87C55.0398 970.361 143.783 883.779 237.754 818.434C309.169 768.775 384.315 730.927 460.422 710.254C518.133 516.812 615.784 338.588 745.112 186.573C748.787 182.254 752.487 177.955 756.213 173.679C730.373 174.234 703.568 175.595 675.872 177.922C411.503 200.134 64.2114 310.464 -301.417 650.758Z"
        fill="url(#paint2_linear_1294_17)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1294_17"
          x1="948.722"
          y1="47.4229"
          x2="-1007.26"
          y2="892.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1294_17"
          x1="948.722"
          y1="47.4229"
          x2="-1007.26"
          y2="892.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1294_17"
          x1="948.722"
          y1="47.4229"
          x2="-1007.26"
          y2="892.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB6BE" />
          <stop offset="0.113339" stopColor="#FA6753" />
          <stop offset="0.432815" stopColor="#F50058" />
          <stop offset="0.61187" stopColor="#F50081" />
          <stop offset="0.77952" stopColor="#C71FD6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VimcalLogo;
