import React from "react";
import { useSelector } from "react-redux";
import { getDefaultFontColor } from "../../lib/styleFunctions";

export default function LinkIcon() {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66663 8.66669C6.95293 9.04945 7.3182 9.36615 7.73766 9.59532C8.15712 9.82449 8.62096 9.96077 9.09773 9.99492C9.57449 10.0291 10.053 9.96027 10.5009 9.79321C10.9487 9.62616 11.3554 9.36474 11.6933 9.02669L13.6933 7.02669C14.3005 6.39802 14.6365 5.55601 14.6289 4.68202C14.6213 3.80803 14.2707 2.97199 13.6527 2.35397C13.0347 1.73594 12.1986 1.38538 11.3246 1.37778C10.4506 1.37019 9.60863 1.70617 8.97996 2.31336L7.83329 3.45336"
        stroke={getDefaultFontColor(isDarkMode)}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33334 7.33338C9.04704 6.95063 8.68177 6.63393 8.26231 6.40476C7.84285 6.17558 7.37901 6.0393 6.90224 6.00516C6.42548 5.97101 5.94695 6.0398 5.49911 6.20686C5.05127 6.37392 4.6446 6.63534 4.30668 6.97338L2.30668 8.97338C1.69948 9.60206 1.3635 10.4441 1.3711 11.3181C1.37869 12.192 1.72926 13.0281 2.34728 13.6461C2.96531 14.2641 3.80135 14.6147 4.67534 14.6223C5.54933 14.6299 6.39134 14.2939 7.02001 13.6867L8.16001 12.5467"
        stroke={getDefaultFontColor(isDarkMode)}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
