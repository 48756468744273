import React from "react";

import { BLUE_BUTTON } from "../../services/globalVariables";
import CustomButtonV2 from "../buttons/customButtonV2";
import NewTag from "../newTag";
import { BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES } from "../../lib/broadcastValues";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import CalendarAuditReadySVG from "../icons/calendarAudit/readyImage";
import CloseButton from "../closeButton";
import broadcast from "../../broadcasts/broadcast";
import { CALENDAR_AUDIT_READY_MODAL_TOOLTIP } from "../../lib/calendarAudit/variables";

export default function CalendarAuditReady({ onClose }) {
  const onClickX = () => {
    broadcast.publish(
      BROADCAST_VALUES.MARK_TOOLTIP_COMPLETED,
      CALENDAR_AUDIT_READY_MODAL_TOOLTIP,
    );
    onClose();
  };

  const onClickLetMeTry = () => {
    layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.OPEN_CALENDAR_AUDIT);
    onClose();
  };

  return (
    <div className="flex flex-col justify-center p-4">
      <div className="flex justify-end mb-2">
        <CloseButton onClick={onClickX} />
      </div>

      <CalendarAuditReadySVG isSmall={true} />
      <div className="flex items-center mb-2 mt-8">
        <div className="mr-2">Executive Calendar Audits</div>
        <NewTag />
      </div>
      <div className="font-size-12 secondary-text-color">
        Easily audit your exec{"'"}s schedule and export to share with them in one click.
      </div>
      <CustomButtonV2
        buttonType={BLUE_BUTTON}
        className="mt-8 w-12"
        label="Let me try"
        onClick={onClickLetMeTry}
        removeDefaultFontStyles={true}
      />
    </div>
  );
}
