import React, { useEffect, useState } from "react";
import { removeDuplicatesFromArray } from "../../../../services/commonUsefulFunctions";
import { Plus, X, Trash } from "react-feather";
import { CustomSelect } from "../../../select";
import DropdownIndicator from "../../../select/dropDownIndicator";
import { customMenuStyle, getReactSelectBaseStyle } from "../../../select/styles";
import CircleWithColor from "../../../circleWithColor";
import { useSelector } from "react-redux";
import CustomButton from "../../../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../../../services/globalVariables";
import AutoTagStar from "./autoTagStar";
import classNames from "classnames";
import {
  PAINTER_MATCH_ALL,
  PAINTER_RULE_CONDITION_MATCH,
  getColorFromID,
  getPainterAutoTagMatchType,
  summarizeAutoTag,
} from "../../../../lib/painterFunctions";
import { getTagUserTokens, isPriorityTag, isTransparentTag, TAG_USER_TOKENS_KEY } from "../../../../lib/tagsFunctions";
import PrioritizedStarButton from "./prioritizedStarButton";
import { getDefaultFontColor } from "../../../../lib/styleFunctions";
import { isEmptyArray } from "../../../../lib/arrayFunctions";
import {
  capitalizeFirstLetter,
  getInputStringFromEvent,
} from "../../../../lib/stringFunctions";
import {
  OPTION_CONTAINS_ANY,
  OPTION_TYPE,
  RULES_OPTIONS,
  RULE_SECTION_ATTENDEES,
  RULE_SECTION_LOCATION,
  RULE_SECTION_REACT_SELECT,
  RULE_SECTION_TITLE,
  getComparisonType,
  createEmptyRule,
  getInitialTagRuleSections,
  getOptions,
  getProtectedValues,
  getSplitValue,
  isAttendeesRule,
  isConditionIsInDomain,
  isConditionalEqual,
  isConditionalLegacyMatch,
  splitAttendeeSearchString,
} from "./smartTagHelpers";
import RuleValueInput from "./ruleValueInput";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../../../services/typeGuards";
import DidYouKnowAlert from "../../../availability/alerts/didYouKnowAlert";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import TagsAccountDropdown from "./tagsAccountDropdown";

export const MY_CALENDAR_TAG_LABEL = "My calendars";

export default function SmartTagSetting({
  colorInfo,
  index,
  colorOptions,
  onClickSave,
  onClickCancel,
  onClickDelete,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const [isShowingAutoTag, setIsShowingAutoTag] = useState(false);
  const [tagRule, setTagRule] = useState(getPainterAutoTagMatchType(colorInfo));
  const [selectedUserTokens, setSelectedUserTokens] = useState(getTagUserTokens({tag: colorInfo, masterAccount}));
  const [painterSetting, setPainterSetting] = useState({ ...colorInfo });
  const [tagRuleSections, setTagRuleSections] = useState(
    getInitialTagRuleSections(colorInfo)
  );
  const [shouldShowEmptyTitleWarning, setShowEmptyTitleWarning] =
    useState(false);
  const hasColorChangedFromNoColorToColor = isTransparentTag(colorInfo)
    && !isTransparentTag(painterSetting);

  useEffect(() => {
    if (isShowingAutoTag) {
      return;
    }

    const hasRules = tagRuleSections.some(
      (rule) => rule.value?.length > 0 || rule.values?.length > 0
    );
    if (hasRules) {
      setIsShowingAutoTag(true);
    }
  }, [tagRuleSections, isShowingAutoTag]);

  const onClickAddMoreRule = () => {
    const updatedTagRuleSection = tagRuleSections.concat(createEmptyRule());
    setTagRuleSections(updatedTagRuleSection);
  };

  const removeRule = (indexToRemove) => {
    return setTagRuleSections(
      tagRuleSections.filter((_, index) => index !== indexToRemove)
    );
  };

  if (isEmptyObjectOrFalsey(colorInfo)) {
    return null;
  }

  return renderOpenTagSection({
    index,
    isDarkMode,
    colorOptions,
    isShowingAutoTag,
    setIsShowingAutoTag,
    tagRule,
    setTagRule,
    tagRuleSections,
    setTagRuleSections,
    onClickAddMoreRule,
    onClickSave,
    painterSetting,
    setPainterSetting,
    onClickCancel,
    colorInfo,
    removeRule,
    onClickDelete,
    shouldShowEmptyTitleWarning,
    setShowEmptyTitleWarning,
    shouldShowWarningForTagsAreInternalOnly: hasColorChangedFromNoColorToColor,
    selectedUserTokens,
    setSelectedUserTokens,
  });
}

function renderOpenTagSection({
  index,
  isDarkMode,
  colorOptions,
  isShowingAutoTag,
  setIsShowingAutoTag,
  tagRule,
  setTagRule,
  tagRuleSections,
  setTagRuleSections,
  onClickAddMoreRule,
  onClickSave,
  painterSetting,
  setPainterSetting,
  onClickCancel,
  removeRule,
  onClickDelete,
  shouldShowEmptyTitleWarning,
  setShowEmptyTitleWarning,
  shouldShowWarningForTagsAreInternalOnly = false, // we only show this if changing from no color to color
  selectedUserTokens,
  setSelectedUserTokens,
}) {
  return (
    <div key={`smart-tag-${index}}`} className="painter-section-container mt-4">
      {renderOpenPickColorAndName({
        index,
        isDarkMode,
        colorOptions,
        painterSetting,
        setPainterSetting,
        onClickDelete,
        shouldShowEmptyTitleWarning,
        setShowEmptyTitleWarning,
      })}
      {shouldShowWarningForTagsAreInternalOnly ? (
        <DidYouKnowAlert
          subText={
            "Color changes from Smart Tags is only visible inside your Vimcal account. If you would like color changes to be visible for your coworkers in Google or Outlook Calendar, please edit the color directly on the calendar event."
          }
          title={"Colors only visible in Vimcal"}
        />
      ) : null}

      {renderAutoTagSection({
        isShowingAutoTag,
        setIsShowingAutoTag,
        isDarkMode,
        tagRule,
        setTagRule,
        tagRuleSections,
        setTagRuleSections,
        onClickAddMoreRule,
        onClickCancel,
        removeRule,
        selectedUserTokens,
        setSelectedUserTokens,
      })}

      {renderOpenTagButtons({
        index,
        onClickSave,
        painterSetting,
        tagRuleSections,
        tagRule,
        onClickCancel,
        shouldShowEmptyTitleWarning,
        setShowEmptyTitleWarning,
        selectedUserTokens,
      })}
    </div>
  );
}

function renderAutoTagSection({
  isShowingAutoTag,
  setIsShowingAutoTag,
  isDarkMode,
  tagRule,
  setTagRule,
  tagRuleSections,
  setTagRuleSections,
  onClickAddMoreRule,
  removeRule,
  selectedUserTokens,
  setSelectedUserTokens,
}) {
  if (!isShowingAutoTag) {
    return (
      <div
        className={classNames(
          "flex items-center",
          "hover-container-secondary-text-color-fill",
          "default-font-size mt-5 select-none duration-200 cursor-pointer"
        )}
        onClick={() => {
          if (isEmptyArrayOrFalsey(tagRuleSections)) {
            setTagRuleSections([
              {
                name: RULE_SECTION_TITLE,
                value: "",
                condition: PAINTER_RULE_CONDITION_MATCH,
              },
            ]);
          }
          setIsShowingAutoTag(true);
        }}
      >
        <AutoTagStar isDarkMode={isDarkMode} />
        <div className="ml-3 truncate-text" style={{ maxWidth: 600 }}>
          {summarizeAutoTag({
            painterSetting: {
              rules: {
                fields: tagRuleSections,
                match_method: tagRule,
              },
            },
          }) || "Add auto-tagging"}
        </div>
      </div>
    );
  }

  return (
    <div className="smart-tag-border p-3 mt-3">
      <TagsAccountDropdown selectedUserTokens={selectedUserTokens} setSelectedUserTokens={setSelectedUserTokens} />
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CustomSelect
            components={{ DropdownIndicator }}
            openMenuOnFocus={true}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              showBorder: true,
              menuListStyle: customMenuStyle({ width: 180 }),
              controllerBackgroundColor: isDarkMode ? "#454554" : "transparent",
              controllerHoverBackgroundColor: isDarkMode
                ? "#454554"
                : "#E0E0E0",
              controlWidth: 170,
              textColor: getDefaultFontColor(isDarkMode),
            })}
            tabSelectsValue={false}
            isSearchable={false}
            value={{ value: tagRule, label: capitalizeFirstLetter(tagRule) }}
            onChange={(val) => {
              setTagRule(val.value);
            }}
            classNamePrefix="dark-mode-modal"
            options={RULES_OPTIONS}
            alwaysShowIndicator={true}
          />
          <div className="secondary-text-color default-font-size font-weight-300 ml-2 select-none">
            of the following rules match:
          </div>
        </div>
      </div>

      <div className="flex mt-4">
        <div className="flex default-font-size secondary-text-color items-center w-7 justify-center select-none">
          {tagRuleSections.length > 1
            ? tagRule === PAINTER_MATCH_ALL
              ? "And"
              : "Or"
            : ""}
        </div>
        <div className="flex flex-col justify-start ml-3 w-full">
          <RenderPerRuleLabel
            tagRuleSections={tagRuleSections}
            setTagRuleSections={setTagRuleSections}
            removeRule={removeRule}
          />
        </div>
      </div>

      <div
        className="flex items-center justify-center w-full hoverable-secondary-text-color mt-4"
        onClick={onClickAddMoreRule}
      >
        <Plus size={12} />
        <div className="default-font-size font-weight-300 ml-2">Add more</div>
      </div>
    </div>
  );
}

function RenderPerRuleLabel({
  tagRuleSections,
  setTagRuleSections,
  removeRule,
}) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  // example of tagRuleSections
  // {name: 'title', value: 'alsjdalsdf', condition: 'match'}
  return tagRuleSections.map((tagRuleSection, index) => {
    const {
      name, // title, attendees, location
      value, // value of the rule
      condition, // match, not_match
      values,
      uuid,
    } = tagRuleSection;
    const isAttendees = isAttendeesRule(name);

    const getComparisonTypeForAttendees = (condition) => {
      const matchingCondition = getComparisonType(condition);
      return {
        ...matchingCondition,
        label: matchingCondition.label.replace("s", ""), // the label might read better as "Contain any" since "Attendees" is plural
      };
    };

    const getOptionsForAttendees = () => {
      const options = getOptions(OPTION_TYPE.ATTENDEE);
      return options.map((option) => {
        return {
          ...option,
          label: option.label.replace("s", ""), // the label might read better as "Contain any" since "Attendees" is plural
        };
      });
    };

    const renderOptionType = () => {
      const options = isAttendees ? getOptionsForAttendees() : getOptions(name);
      return (
        <CustomSelect
          components={{ DropdownIndicator }}
          openMenuOnFocus={true}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
            menuListStyle: customMenuStyle({ width: 140 }),
            controllerBackgroundColor: isDarkMode ? "#454554" : "transparent",
            controllerHoverBackgroundColor: isDarkMode ? "#454554" : "#E0E0E0",
            controlWidth: 140,
            textColor: getDefaultFontColor(isDarkMode),
          })}
          tabSelectsValue={false}
          isSearchable={false}
          value={
            isAttendees
              ? getComparisonTypeForAttendees(condition)
              : getComparisonType(condition)
          }
          onChange={(val) => {
            const newTagRule = [...tagRuleSections];
            if (!tagRuleSections[index]) {
              return;
            }
            const condition = val.value;
            if (
              isConditionalEqual(condition) &&
              !isConditionalEqual(newTagRule[index]?.condition)
            ) {
              // went from not "equal" to something not equal (e.g. contains_any)
              newTagRule[index] = {
                ...tagRuleSection,
                value: "",
                condition,
              };
              setTagRuleSections(newTagRule);
            } else if (
              !isConditionIsInDomain(newTagRule[index]?.condition) &&
              isConditionIsInDomain(condition)
            ) {
              // going from domain to not domain
              newTagRule[index] = {
                ...tagRuleSection,
                values: [],
                value: "",
                condition,
              };
              setTagRuleSections(newTagRule);
            } else if (
              !isConditionalEqual(condition) &&
              isConditionalEqual(newTagRule[index]?.condition)
            ) {
              // went from not "equal" (e.g. contains_any) to equal
              if (isAttendeesRule(name)) {
                newTagRule[index] = {
                  ...tagRuleSection,
                  values: [],
                  condition,
                };
                setTagRuleSections(newTagRule);
              } else {
                newTagRule[index] = {
                  ...tagRuleSection,
                  values: [],
                  condition,
                };
                setTagRuleSections(newTagRule);
              }
            } else {
              // e.g. contains_all -> contains_any
              newTagRule[index] = {
                ...tagRuleSection,
                condition,
              };
              setTagRuleSections(newTagRule);
            }
          }}
          classNamePrefix="dark-mode-modal"
          options={options}
          alwaysShowIndicator={options.length > 1}
          hideIndicator={options.length === 1}
        />
      );
    };

    const renderSplitOutValues = () => {
      if (isConditionalEqual(condition)) {
        return null;
      }

      const splittedValues = getValues();
      if (isEmptyArray(splittedValues)) {
        return null;
      }
      const onClickRemoveVal = (indexToRemove) => {
        if (!splittedValues[indexToRemove]) {
          // sanity check
          return;
        }
        const newArray = [
          ...splittedValues.slice(0, indexToRemove),
          ...splittedValues.slice(indexToRemove + 1),
        ];
        const newTagRule = [...tagRuleSections];
        if (!newTagRule[index]) {
          return;
        }
        newTagRule[index].values = newArray;
        setTagRuleSections(newTagRule);
      };

      return (
        <span
          className="flex-wrap relative flex items-center"
          style={{ width: "348px" }}
        >
          {splittedValues.map((val, index) => {
            if (!splittedValues) {
              return null;
            }
            return (
              <div
                key={`splitted_val-${uuid ?? index}`}
                className="selected-email-container"
              >
                <div className="margin-right-5">{val}</div>
                <div className="display-flex-center">
                  <X
                    className="hoverable-secondary-text-color"
                    size={13}
                    onClick={() => {
                      onClickRemoveVal(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </span>
      );
    };

    const getValues = () => {
      if (isConditionalEqual(condition)) {
        // should just use .value instead
        return [];
      }
      if (!isEmptyArray(getProtectedValues(values))) {
        return getProtectedValues(values);
      }
      if (!value) {
        return [];
      }
      if (isAttendeesRule(name)) {
        // need to split on space as well
        return splitAttendeeSearchString(value);
      }
      return getSplitValue(value);
    };

    return (
      <div key={`tag-rule-section-${uuid ?? index}`}>
        <div
          className={classNames("flex items-center", index === 0 ? "" : "mt-3")}
        >
          <CustomSelect
            components={{ DropdownIndicator }}
            openMenuOnFocus={true}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              showBorder: true,
              menuListStyle: customMenuStyle({ width: 180 }),
              controllerBackgroundColor: isDarkMode ? "#454554" : "transparent",
              controllerHoverBackgroundColor: isDarkMode
                ? "#454554"
                : "#E0E0E0",
              controlWidth: 110,
              textColor: getDefaultFontColor(isDarkMode),
            })}
            tabSelectsValue={false}
            isSearchable={false}
            value={{
              value: name,
              label: capitalizeFirstLetter(name),
            }}
            onChange={(val) => {
              const newTagRule = [...tagRuleSections];
              let condition = newTagRule[index].condition;
              const wasPreviouslyDomain = isConditionIsInDomain(
                tagRuleSections[index].condition
              );
              const wasPreviouslyEqual = isConditionalEqual(
                tagRuleSections[index].condition
              );
              if (wasPreviouslyEqual && val.value === RULE_SECTION_ATTENDEES) {
                condition = OPTION_CONTAINS_ANY.value;
              } else if (
                wasPreviouslyDomain &&
                val.value !== RULE_SECTION_ATTENDEES
              ) {
                condition = OPTION_CONTAINS_ANY.value;
              } else if (
                wasPreviouslyEqual &&
                val.value === RULE_SECTION_LOCATION
              ) {
                condition = OPTION_CONTAINS_ANY.value;
              }
              newTagRule[index] = {
                name: val.value,
                condition,
              };

              if (wasPreviouslyEqual && val.value === RULE_SECTION_ATTENDEES) {
                newTagRule[index].values = []; // reset it to empty array
              } else if (isConditionalEqual(condition)) {
                newTagRule[index].value = tagRuleSections[index].value ?? ""; // get old .value
              } else if (
                wasPreviouslyDomain ||
                (wasPreviouslyEqual && val.value === RULE_SECTION_LOCATION) // going from equal to location which doesn't have location
              ) {
                newTagRule[index].values = []; // reset it to empty array
              } else {
                newTagRule[index].values = [];
              }
              setTagRuleSections(newTagRule);
            }}
            classNamePrefix="dark-mode-modal"
            options={RULE_SECTION_REACT_SELECT}
            alwaysShowIndicator={true}
          />
          <div className="font-weight-300 ml-2 default-font-size">
            {renderOptionType()}
          </div>
          <RuleValueInput
            name={name}
            tagRuleSections={tagRuleSections}
            index={index}
            setTagRuleSections={setTagRuleSections}
            condition={condition}
            value={value}
            values={getValues()}
          />
          <X
            size={16}
            onClick={() => removeRule(index)}
            className="hoverable-secondary-text-color ml-2"
          />
        </div>
        <div className="w-80" style={{ marginLeft: "268px" }}>
          {renderSplitOutValues()}
        </div>
      </div>
    );
  });
}

function renderOpenTagButtons({
  index,
  onClickSave,
  painterSetting,
  tagRuleSections,
  tagRule,
  onClickCancel,
  shouldShowEmptyTitleWarning,
  setShowEmptyTitleWarning,
  selectedUserTokens,
}) {
  return (
    <div className="mt-5">
      <div>
        {shouldShowEmptyTitleWarning ? (
          <div className="default-font-size warning-color">
            Tags must have a label.
          </div>
        ) : null}
      </div>
      <div className="flex items-center justify-end">
        <CustomButton
          buttonType={WHITE_BUTTON}
          onClick={onClickCancel}
          label="Cancel"
          addPaddingToRight={true}
        />

        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={() => {
            if (!painterSetting?.name) {
              setShowEmptyTitleWarning(true);
              return;
            }

            const getRuleFields = (tagRuleSections) => {
              return tagRuleSections
                .filter((rule) => {
                  const { condition } = rule;
                  if (isConditionalEqual(condition)) {
                    return !!rule?.value?.trim();
                  }
                  if (isConditionalLegacyMatch(condition)) {
                    return !!rule?.value?.trim();
                  }
                  return !isEmptyArray(rule?.values);
                })
                .map((rule) => {
                  // if values, do not include value and vice versa
                  const { value, values, ...rest } = rule;
                  if (isConditionalEqual(rule.condition)) {
                    return {
                      ...rest,
                      value,
                      name: rule?.name?.toLowerCase(),
                    };
                  }
                  const getValues = () => {
                    if (!isEmptyArray(values)) {
                      return values;
                    }
                    if (isConditionalLegacyMatch(rule.condition) && value) {
                      return getSplitValue(value);
                    }
                    return [];
                  };
                  return {
                    ...rest,
                    values: removeDuplicatesFromArray(getValues()),
                    name: rule?.name?.toLowerCase(),
                  };
                });
            };

            const getRules = () => {
              if (isEmptyArray(tagRuleSections)) {
                return undefined;
              }
              return {
                match_method: tagRule,
                fields: getRuleFields(tagRuleSections),
              };
            };

            const updatedPainterSetting = {
              ...painterSetting,
              fadedColor: undefined,
              id: painterSetting.id,
              ...{
                rules: getRules(),
              },
              [TAG_USER_TOKENS_KEY]: selectedUserTokens,
            };
            onClickSave({
              painterSetting: updatedPainterSetting,
              index,
            });
          }}
          label="Save"
        />
      </div>
    </div>
  );
}

function renderOpenPickColorAndName({
  index,
  isDarkMode,
  colorOptions,
  painterSetting,
  setPainterSetting,
  onClickDelete,
  shouldShowEmptyTitleWarning,
  setShowEmptyTitleWarning,
}) {
  const { name, color_id, color } = painterSetting;
  const isPrioritized = isPriorityTag(painterSetting);

  return (
    <div className="display-flex-flex-direction-row align-items-center mt-2.5">
      <CustomSelect
        components={{ DropdownIndicator }}
        openMenuOnFocus={true}
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode,
          showBorder: true,
          menuListStyle: customMenuStyle({ width: 180 }),
        })}
        tabSelectsValue={false}
        isSearchable={false}
        className={"select-calendar-color-dropdown"}
        classNamePrefix="dark-mode"
        value={{
          label: (
            <CircleWithColor color={getColorFromID({ id: color_id, color })} />
          ),
          color: getColorFromID({ id: color_id, color }),
          name: name,
          value: color_id,
        }}
        onChange={(val) => {
          setPainterSetting({
            ...painterSetting,
            color: val.color,
            color_id: val.value,
            fadedColor: undefined,
          });
        }}
        options={colorOptions}
        maxMenuHeight={index === 10 ? 100 : 155}
        alwaysShowIndicator={true}
      />

      <input
        className="default-input-field margin-left-ten"
        style={{ height: 34 }}
        placeholder="E.g. External meeting"
        autoComplete="off"
        autoFocus={true}
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        onChange={(e) => {
          if (shouldShowEmptyTitleWarning) {
            setShowEmptyTitleWarning(false);
          }

          const updatedPainterSetting = {
            ...painterSetting,
            name: getInputStringFromEvent(e),
          };
          setPainterSetting(updatedPainterSetting);
        }}
        value={painterSetting.name}
      />
      <PrioritizedStarButton
        isPrioritized={isPrioritized}
        onClick={() => {
          setPainterSetting({
            ...painterSetting,
            is_prioritized: !isPrioritized,
          });
        }}
      />
      <div
        className="rounded-md edit-tag-icon-container height-34px flex items-center justify-center hoverable-secondary-text-color"
        style={{ width: 34, minWidth: 34 }}
        onClick={onClickDelete}
      >
        <Trash size={16} />
      </div>
    </div>
  );
}
