import classNames from "classnames";
import React, { useState } from "react";
import {
  convertMinutesToHoursWithOneDecimal,
  EXTERNAL_INTERNAL_CONSTS,
  getMetricsExternalPercentage,
  getMetricsInternalPercentage,
  getMetricsMeetingTypeExternal,
  getMetricsMeetingTypeExternalChange,
  getMetricsMeetingTypeExternalIsUp,
  getMetricsMeetingTypeInternal,
  getMetricsMeetingTypeInternalChange,
  getMetricsMeetingTypeInternalIsUp,
  METRICS_VIEW,
} from "./metricsAccessorFunctions";
import ToggleForHourOrPercentage from "./toggleForHourOrPercentage";
import TransparentPieChart from "./transparentPieChart";
import { useSelector } from "react-redux";
import {
  getExternalTagFromUser,
  getInternalTagFromUser,
  getTagColorFilteringForTransparent,
} from "../../lib/tagsFunctions";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import UpDownAbsoluteChange from "./upDownAbsoluteChange";

export default function InternalExternalBox({
  data,
  previousData,
  showSkeletonScreen,
}) {
  const [type, setType] = useState(METRICS_VIEW.HOUR);
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  if (showSkeletonScreen) {
    return (
      <div className="metrics-data-container metrics-two-container small-metrics-data-container">
        <MetricsSkeletonLoadingPanel width={170} height={20} />
        <MetricsSkeletonLoadingPanel
          width={"100%"}
          height={98}
          marginTop={16}
        />
      </div>
    );
  }

  const renderRadialCircle = () => {
    if (!isDarkMode) {
      return null;
    }
    return (
      <div
        className="metrics-purple-radial metrics-gradient-glow -top-32 -left-24"
        style={{
          width: "calc(100% + 100px)",
          height: "456px",
        }}
      ></div>
    );
  };

  return (
    <div
      className="metrics-data-container metrics-two-container small-metrics-data-container"
      style={{ height: 189 }}
    >
      <div
        className={classNames(
          "metrics-primary-text-color metrics-default-section-header font-weight-400",
          "flex justify-between items-center",
          "-mt-2"
        )}
      >
        Time in meetings
        <ToggleForHourOrPercentage type={type} setType={setType} />
      </div>
      {/* adding -margin right so the text have more room to grow */}
      <div
        className={classNames("flex items-center", "-mr-10")}
        style={{ marginTop: 27 }}
      >
        {renderGraph({ data, currentUser })}
        {renderInternalExternalLabel_animation({
          data,
          previousData,
          type,
          currentUser,
        })}
      </div>
    </div>
  );
}

function renderGraph({ data, currentUser }) {
  const internal = getMetricsMeetingTypeInternal({ data });
  const external = getMetricsMeetingTypeExternal({ data });
  const total = internal + external;
  const percentageInternal = !internal ? 0 : (internal / total) * 100;

  const externalColor =
    getTagColorFilteringForTransparent(
      getExternalTagFromUser({ user: currentUser })
    ) ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor;

  const internalColor =
    getTagColorFilteringForTransparent(
      getInternalTagFromUser({ user: currentUser })
    ) ?? EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor;
  return (
    <TransparentPieChart
      inputPercentage={percentageInternal}
      externalColor={externalColor}
      internalColor={internalColor}
    />
  );
}

function renderInternalExternalLabel({ data, previousData, type }) {
  return (
    <div className="metrics-default-font-size ml-6">
      <div className="flex items-center">
        <div
          className="h-3 w-3 rounded-full mr-3"
          style={{
            backgroundColor: EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor,
          }}
        ></div>
        <div className="metrics-primary-text-color mr-1.5">Internal</div>
        <div>
          {`${
            type === METRICS_VIEW.HOUR
              ? convertMinutesToHoursWithOneDecimal(
                  getMetricsMeetingTypeInternal({ data })
                )
              : getMetricsInternalPercentage({ data })
          }${type === METRICS_VIEW.HOUR ? " hrs" : "%"}`}{" "}
        </div>
        <UpDownAbsoluteChange
          delta={getMetricsMeetingTypeInternalChange({
            data,
            previousData,
          })}
          isUp={getMetricsMeetingTypeInternalIsUp({ data, previousData })}
        />
      </div>

      <div className="flex items-center mt-5">
        <div
          className="h-3 w-3 rounded-full mr-3"
          style={{
            backgroundColor: EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor,
          }}
        ></div>
        <div className="metrics-primary-text-color mr-1.5">External</div>
        <div>
          {`${
            type === METRICS_VIEW.HOUR
              ? convertMinutesToHoursWithOneDecimal(
                  getMetricsMeetingTypeExternal({ data })
                )
              : getMetricsExternalPercentage({ data })
          }${type === METRICS_VIEW.HOUR ? " hrs" : "%"}`}{" "}
        </div>
        <UpDownAbsoluteChange
          delta={getMetricsMeetingTypeExternalChange({
            data,
            previousData,
          })}
          isUp={getMetricsMeetingTypeExternalIsUp({ data, previousData })}
        />
      </div>
    </div>
  );
}

function renderInternalExternalLabel_animation({
  data,
  previousData,
  type,
  currentUser,
}) {
  const externalColor =
    getTagColorFilteringForTransparent(
      getExternalTagFromUser({ user: currentUser })
    ) ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor;
  const internalColor =
    getTagColorFilteringForTransparent(
      getInternalTagFromUser({ user: currentUser })
    ) ?? EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor;
  return (
    <div className="metrics-default-font-size ml-2.5">
      <div className="flex items-center">
        <div
          className="h-3 w-3 rounded-full mr-3"
          style={{
            backgroundColor:
              internalColor ??
              EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor,
          }}
        ></div>
        <div className="metrics-primary-text-color mr-1.5">Internal</div>
        <div className="">
          <div
            className={classNames(
              type === METRICS_VIEW.HOUR ? "show" : "hide",
              "child-div-animate",
              "duration-500"
            )}
          >
            {`${convertMinutesToHoursWithOneDecimal(
              getMetricsMeetingTypeInternal({ data })
            )}${" hrs"}`}{" "}
          </div>

          <div
            className={classNames(
              type === METRICS_VIEW.PERCENTAGE ? "show" : "hide",
              "child-div-animate",
              "duration-500"
            )}
          >
            {`${getMetricsInternalPercentage({ data })}${"%"}`}{" "}
          </div>
        </div>

        <UpDownAbsoluteChange
          delta={getMetricsMeetingTypeInternalChange({
            data,
            previousData,
          })}
          isUp={getMetricsMeetingTypeInternalIsUp({ data, previousData })}
        />
      </div>

      <div className="flex items-center mt-5">
        <div
          className="h-3 w-3 rounded-full mr-3"
          style={{
            backgroundColor:
              externalColor ??
              EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor,
          }}
        ></div>
        <div className="metrics-primary-text-color mr-1.5">External</div>
        <div className="">
          <div
            className={classNames(
              type === METRICS_VIEW.HOUR ? "show" : "hide",
              "child-div-animate",
              "duration-500"
            )}
          >
            {`${convertMinutesToHoursWithOneDecimal(
              getMetricsMeetingTypeExternal({ data })
            )}${" hrs"}`}{" "}
          </div>
          <div
            className={classNames(
              type === METRICS_VIEW.PERCENTAGE ? "show" : "hide",
              "child-div-animate",
              "duration-500"
            )}
          >
            {`${getMetricsExternalPercentage({ data })}${"%"}`}{" "}
          </div>
        </div>

        <UpDownAbsoluteChange
          delta={getMetricsMeetingTypeExternalChange({
            data,
            previousData,
          })}
          isUp={getMetricsMeetingTypeExternalIsUp({ data, previousData })}
        />
      </div>
    </div>
  );
}
