import React from "react";
import { POSITION } from "../../lib/styleFunctions";
import classNames from "classnames";

export default function AccountLabel({ position = POSITION.LEFT }) {
  const getPositionClassName = () => {
    switch (position) {
      case POSITION.LEFT:
        return "mr-2";
      case POSITION.RIGHT:
        return "ml-2";
      case POSITION.TOP:
        return "mb-2";
      case POSITION.BOTTOM:
        return "mt-2";
      default:
        return "mr-2"; // default to right
    }
  };
  return (
    <div className={classNames("secondary-text-color default-font-size", getPositionClassName())}>Account:</div>
  );
}
