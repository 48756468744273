import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { isOutlookEvent } from "../../lib/eventFunctions";
import { createColorsListWithDefault } from "../../services/googleColors";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";
import {
  getEventCategories,
  getEventColorID,
  getEventMasterEventID,
  getEventUserCalendarID,
} from "../../services/eventResourceAccessors";
import { getOutlookColors } from "./outlookHelpers";
import { getGoogleColors } from "./googleHelpers";
import ColorSelectorOption from "./colorSelectorOption";
import { getOriginalRecurringEventFromIndex } from "../../lib/recurringEventFunctions";
import recurrenceBroadcast from "../../broadcasts/recurrenceBroadcast";
import { RECURRENCE_BROADCAST_VALUES } from "../../lib/broadcastValues";
import {
  doesColorLabelsContainColorID,
  getColorLabelColorID,
} from "./colorLabelFunctions";
import { getColorLabels } from "../../lib/settingsFunctions";
import { batch, useDispatch } from "react-redux";
import { getGoogleEventId } from "../../services/commonUsefulFunctions";
import {
  getEmailFromUserCalendarID,
  getMatchingUserFromEvent,
  getUserEmailFromEvent,
} from "../../lib/calendarFunctions";
import broadcast from "../../broadcasts/broadcast";
import { REDUCER_TYPES } from "../../services/reducers";
import { GOOGLE_UPDATES } from "../../services/googleCalendarService";
import { constructRequestURL } from "../../services/api";
import { BROADCAST_VALUES } from "../../lib/broadcastValues";
import {
  constructOutlookEventData,
  getNewCategoryList,
} from "./outlookHelpers";
import { constructGoogleEventData } from "./googleHelpers";
import { shouldDisplayColorLabel } from "../../lib/featureFlagFunctions";
import ShortcutHoverHint from "../shortcutHoverHint";
import CreateColorLabelPlus from "./createColorLabelPlus";
import ColorLabelPill from "./colorLabelPill";
import { COLOR_SELECTOR_ID } from "../../services/elementIDVariables";
import { getMatchingUIUserForEvent } from "../../lib/tagsFunctions";

interface ColorSelectorProps {
  event: VimcalEvent;
}

export default function ColorSelector({ event }: ColorSelectorProps) {
  const dispatch = useDispatch();
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const outlookCategories = useOutlookCategoriesStore(
    (state) => state.outlookCategories
  );
  const currentCalendar = allCalendars[getEventUserCalendarID(event)];
  const originalRecurrenceEventIndex = useSelector(
    (state) => state.originalRecurrenceEventIndex
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers
  );
  const currentUser = useSelector((state) => state.currentUser);
  const matchingEventUser = getMatchingUIUserForEvent({
    event,
    allCalendars,
    allLoggedInUsers,
    masterAccount,
    currentUser,
  });
  const colorLabels = getColorLabels({
    masterAccount,
    user: matchingEventUser ?? currentUser,
  });

  useEffect(() => {
    const isRecurringEvent = Boolean(getEventMasterEventID(event));
    const originalRecurringEvent = getOriginalRecurringEventFromIndex(
      event,
      originalRecurrenceEventIndex
    );
    if (isRecurringEvent && event && !originalRecurringEvent) {
      recurrenceBroadcast.publish(
        RECURRENCE_BROADCAST_VALUES.GET_ORIGINAL_EVENT,
        event
      );
    }
  }, [event]);

  if (!currentCalendar) {
    return null;
  }

  const colors = isOutlookEvent(event)
    ? getOutlookColors(currentCalendar, outlookCategories)
    : getGoogleColors(currentCalendar);

  const selectedColors: string[] = isOutlookEvent(event)
    ? getEventCategories(event) ?? []
    : [createColorsListWithDefault()[getEventColorID(event) ?? "0"]?.name];

  if (isEmptyArrayOrFalsey(colors)) {
    return null;
  }

  const removePopup = () => {
    batch(() => {
      dispatch({ type: REDUCER_TYPES.REMOVE_POPUP_EVENT });
      dispatch({ type: REDUCER_TYPES.REMOVE_CURRENT_PREVIEW_EVENT });
    });
  };

  const onClick = (value: string) => {
    // in Google, value is color_id
    // For recurring events, we first need to ask which events get updated.
    if (getEventMasterEventID(event)) {
      const originalRecurringEvent = getOriginalRecurringEventFromIndex(
        event,
        originalRecurrenceEventIndex
      );

      if (isOutlookEvent(event)) {
        const originalCategories = getEventCategories(event) ?? [];
        broadcast.publish(
          BROADCAST_VALUES.DISPLAY_RECURRING_CATEGORIES_MODAL,
          getNewCategoryList(originalCategories, value),
          originalRecurringEvent,
          event
        );
      } else {
        broadcast.publish(
          BROADCAST_VALUES.DISPLAY_RECURRING_COLOR_MODAL,
          value,
          originalRecurringEvent,
          event
        );
      }

      removePopup();
      return;
    }

    // Handle non-recurring events directly.
    const { eventData, temporaryEventData } = isOutlookEvent(event)
      ? constructOutlookEventData({
          categoryName: value,
          event: event as VimcalOutlookEvent,
        })
      : constructGoogleEventData({
          allCalendars,
          colorId: value,
          event: event as VimcalGoogleEvent,
        });

    const queryParams = new URLSearchParams({
      sendUpdates: GOOGLE_UPDATES.NONE,
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars
      ),
      event_provider_id: getGoogleEventId(event),
    });

    const path = "events";
    const url = constructRequestURL(path, true) + "?" + queryParams.toString();

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
      updatedTemporaryEvent: temporaryEventData,
    });

    broadcast.publish("SET_LAST_SELECTED_EVENT", event);

    removePopup();
  };

  const renderColorLabelPills = () => {
    if (isOutlookEvent(event)) {
      return null;
    }
    if (!shouldDisplayColorLabel({ user: matchingEventUser })) {
      return null;
    }
    
    const selectedColorIDs: string[] = isOutlookEvent(event)
      ? getEventCategories(event) ?? []
      : [createColorsListWithDefault()[getEventColorID(event) ?? "0"]?.id];
    return (
      <div className="px-2 pt-2 gap-1 flex flex-wrap items-center">
        {colorLabels.map((colorLabel) => {
          return (
            <ColorLabelPill
              key={getColorLabelColorID(colorLabel)}
              colorLabel={colorLabel}
              isSelected={selectedColorIDs.includes(
                getColorLabelColorID(colorLabel)
              )}
              onClick={onClick}
            />
          );
        })}
        <ShortcutHoverHint
          below
          style={{
            width: "max-content",
            zIndex: 999999999999
          }}
          title={"Add color label"}
        >
          <CreateColorLabelPlus selectedUser={matchingEventUser} />
        </ShortcutHoverHint>
      </div>
    );
  };
  const filteredColors = colors.filter((color) =>
    !doesColorLabelsContainColorID(colorLabels, color.value)
  );
  return (
    <>
      {renderColorLabelPills()}
      <div className="color-selector" id={COLOR_SELECTOR_ID}>
        {filteredColors.map((color) => (
          <ColorSelectorOption
            key={color.name}
            colorOption={color}
            isSelected={selectedColors.includes(color.name)}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  );
}
