import React, { useMemo } from "react";
import classNames from "classnames";
import { BROADCAST_VALUES } from "../../../lib/broadcastValues";
import broadcast from "../../../broadcasts/broadcast";
import { useAllUserDomains } from "../../../services/stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import DefaultSwitch from "../../defaultSwitch";
import { SelectOptionType } from "../../select";
import CustomSelectV2 from "../../select/selectV2";
import { useIsCurrentUserOwner } from "../hooks";
import { TEAM_SETTING_LABEL_CLASS_NAME } from "./sharedFunctions";

interface DomainDetectionProps {
  isDomainDetectionEnabled: boolean
  onChangeSelectedDomain: (id: number | undefined) => void
  onToggleDomainCapture: () => void
  selectedDomainId: number | undefined
  sortedDomains: Domain[]
}

export default function DomainDetection({
  isDomainDetectionEnabled,
  onChangeSelectedDomain,
  onToggleDomainCapture,
  selectedDomainId,
  sortedDomains,
}: DomainDetectionProps) {
  const domains = useAllUserDomains(state => state.allUserDomains);
  const hasEligibleDomains = !isEmptyObjectOrFalsey(domains);
  const isUserOwner = useIsCurrentUserOwner();

  if (!isUserOwner) {
    return null;
  }

  return (
    <>
      <div className={classNames("flex items-center gap-4 mt-10", TEAM_SETTING_LABEL_CLASS_NAME)}>
        <div>Team discovery</div>
        <DefaultSwitch
          disabled={!hasEligibleDomains}
          isChecked={isDomainDetectionEnabled && hasEligibleDomains}
          onChange={onToggleDomainCapture}
        />
      </div>
      <div className="default-font-size secondary-text-color">
        <SubheaderContents hasEligibleDomains={hasEligibleDomains} />
      </div>
      {hasEligibleDomains ? (
        <DomainDropdown
          isDisabled={!isDomainDetectionEnabled}
          selectedDomainId={selectedDomainId}
          onChangeSelectedDomain={onChangeSelectedDomain}
          sortedDomains={sortedDomains}
        />
      ) : null}
    </>
  );
}

function SubheaderContents({ hasEligibleDomains }: { hasEligibleDomains: boolean }) {
  if (hasEligibleDomains) {
    return (
      <>
        When enabled, new users signing in from this domain will be prompted to join your team plan.
      </>
    );
  }

  const onClickLogin = () => {
    broadcast.publish(BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS);
  };

  return (
    <>
      To enable team discovery,{" "}
      <span
        className="hoverable-secondary-text-color underline"
        onClick={onClickLogin}
      >
        login with your work email
      </span>
      .
    </>
  );
}

interface DomainDropdownProps {
  isDisabled?: boolean
  onChangeSelectedDomain: (id: number | undefined) => void
  selectedDomainId: number | undefined
  sortedDomains: Domain[]
}

function DomainDropdown({ isDisabled, onChangeSelectedDomain, selectedDomainId, sortedDomains }: DomainDropdownProps) {
  const domainOptions = useMemo(() => {
    return Object.values(sortedDomains).map(domain => ({
      value: domain?.id ?? -1,
      label: domain?.name ?? "",
    }));
  }, [sortedDomains]);

  return (
    <CustomSelectV2<SelectOptionType<number>, false>
      className="mt-3 w-52"
      isDisabled={isDisabled}
      onChange={option => onChangeSelectedDomain(option?.value)}
      options={domainOptions}
      value={domainOptions.find(option => option.value === selectedDomainId)}
    />
  );
}
