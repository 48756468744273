import { useEffect, useCallback } from "react";
import { useEnrichedContactsStore } from "../services/stores/enrichedContacts";
import Fetcher from "../services/fetcher";
import { removeDuplicatesFromArray } from "../services/commonUsefulFunctions";
import { getEventAttendees } from "../services/eventResourceAccessors";
import appBroadcast from "../broadcasts/appBroadcast";
import { APP_BROADCAST_VALUES } from "../lib/broadcastValues";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";
import { getEnrichedContactsURL } from "../lib/endpoints";

function EnrichedContactsContainer({ events }) {
  const {
    addEnrichedContacts,
    searchedEmails,
    addSearchEmails,
    clearSearchedEmailsCache,
    setEnrichedContacts,
  } = useEnrichedContactsStore();

  const enrichedContactsStore = useEnrichedContactsStore(
    (state) => state.enrichedContacts
  );
  const allSearchedEmails = new Set(searchedEmails);

  const updateEnrichedContacts = useCallback(async () => {
    const enrichedContactsEmails = new Set(
      enrichedContactsStore?.map((contact) => getObjectEmail(contact))
    );

    const allDuplicateAttendees = events
      ?.flatMap((event) => {
        return getEventAttendees(event)?.flatMap((attendee) => getObjectEmail(attendee));
      })
      .filter(Boolean)
      .filter((attendee) => !enrichedContactsEmails.has(attendee));

    const allAttendees = removeDuplicatesFromArray(allDuplicateAttendees);
    const filteredEmails = allAttendees.filter(
      (email) => !allSearchedEmails.has(email)
    );

    if (isEmptyArrayOrFalsey(filteredEmails)) {
      return;
    }

    if (Array.isArray(allAttendees) && allAttendees.length) {
      const enrichedContactFetchResponse = await Fetcher.post(
        getEnrichedContactsURL(),
        {
          body: JSON.stringify({
            emails: [...new Set(filteredEmails)],
          }),
        },
        true,
      );

      if (
        !isEmptyObjectOrFalsey(enrichedContactFetchResponse) &&
        !!enrichedContactFetchResponse.enriched_contacts
      ) {
        const { enriched_contacts } = enrichedContactFetchResponse;

        if (!isEmptyObjectOrFalsey(enriched_contacts)) {
          addEnrichedContacts(enriched_contacts);
          addSearchEmails(filteredEmails);
        }
      }
    }
  }, [events]);

  const refetchEnrichedContacts = useCallback(async () => {
    const enrichedContactsEmails = enrichedContactsStore?.map((contact) => getObjectEmail(contact));

    if (enrichedContactsEmails.length) {
      const enrichedContactFetchResponse = await Fetcher.post(
        getEnrichedContactsURL(),
        { body: JSON.stringify({ emails: [...new Set(enrichedContactsEmails)] }) },
        true,
      );

      if (
        !isEmptyObjectOrFalsey(enrichedContactFetchResponse) &&
        !!enrichedContactFetchResponse.enriched_contacts
      ) {
        const { enriched_contacts } = enrichedContactFetchResponse;

        if (!isEmptyObjectOrFalsey(enriched_contacts)) {
          setEnrichedContacts(enriched_contacts);
        }
      }
    }
  }, [enrichedContactsStore])

  useEffect(() => {
    updateEnrichedContacts();
  }, [events]);

  const clearOutEnrichContactSearchedEmailsCache = () => {
    clearSearchedEmailsCache();
  };

  useEffect(() => {
    appBroadcast.subscribe(
      "CLEAR_ENRICH_CONTACT_SEARCHED_CACHE",
      clearOutEnrichContactSearchedEmailsCache
    );
    appBroadcast.subscribe(
      APP_BROADCAST_VALUES.REFETCH_ENRICHED_CONTACTS,
      refetchEnrichedContacts
    );

    return () => {
      appBroadcast.unsubscribe(APP_BROADCAST_VALUES.CLEAR_ENRICH_CONTACT_SEARCHED_CACHE);
      appBroadcast.unsubscribe(APP_BROADCAST_VALUES.REFETCH_ENRICHED_CONTACTS);
    };
  }, [refetchEnrichedContacts]);

  return null;
}

export default EnrichedContactsContainer;
