import React from "react";

const VimcalHoverableLogo = ({ color }) => (
  <svg
    width="85"
    height="22"
    viewBox="0 0 85 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.46494 4.37607C0.110796 5.81902 -0.3573 7.83964 0.860737 9.39149C1.71159 10.4755 3.06382 10.9343 4.33072 10.6972C9.48157 5.70127 14.4313 4.0788 18.2143 3.76016C18.7993 3.71089 19.3558 3.69283 19.8802 3.69817C20.9169 2.6006 22.0841 1.62107 23.3619 0.786108L23.3712 0.76269C23.3712 0.76269 17.8518 -0.523153 12.479 0.244326C7.78755 0.914467 3.15941 2.57066 1.46494 4.37607Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      d="M5.27189 15.0112C4.2585 13.7201 4.27546 11.9488 5.20341 10.6886C10.0898 6.12923 14.7312 4.651 18.2643 4.3534L19.1897 4.46931C17.4613 6.50605 16.1562 8.89395 15.3849 11.4857C14.3678 11.7627 13.3635 12.2698 12.4091 12.9352C11.1532 13.8107 9.96724 14.9707 8.92682 16.2772C7.60829 16.5973 6.16433 16.1481 5.27189 15.0112Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      d="M9.36035 16.6885C9.28812 16.8186 9.22322 16.9546 9.16637 17.0963C8.43156 18.9271 9.31995 21.007 11.1507 21.7419C11.7566 21.9851 12.3895 22.0506 12.9922 21.9629C13.5953 21.8783 14.1848 21.6382 14.6984 21.2351C14.8054 21.1511 14.9064 21.0622 15.0011 20.969C14.916 20.5614 14.8433 20.1485 14.7836 19.7305C14.4112 17.1234 14.577 14.5637 15.1966 12.1636C14.3765 12.4323 13.5538 12.8629 12.7496 13.4236C11.5342 14.2708 10.3775 15.4045 9.36035 16.6885Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      d="M18.2643 4.3534C18.6344 4.32223 18.9927 4.30399 19.338 4.29655C19.2882 4.35385 19.2388 4.41144 19.1897 4.46931L18.2643 4.3534Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.374 7.93146L30.6208 18.0452H32.9009L37.1477 7.93146L34.6868 7.9222L31.7608 15.207L28.8349 7.9222L26.374 7.93146Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2616 7.93146V18.0454H40.4945V7.93146H38.2616ZM58.3018 18.0454V11.7669C58.3018 9.14592 56.4125 7.75414 54.5255 7.75414C53.2971 7.75414 52.0064 8.37939 51.2097 9.46057L51.0666 9.65479L50.9384 9.45041C50.2413 8.33873 49.0542 7.75414 47.866 7.75414C46.7042 7.75414 45.8407 8.31421 45.2333 9.03548L44.9435 9.37959V7.93146H42.7106V18.0454H44.9435V12.6732C44.9435 11.9232 45.1103 11.1964 45.4959 10.6523C45.8868 10.1008 46.4937 9.75066 47.334 9.75066C48.0026 9.75066 48.5259 10.0135 48.8769 10.4841C49.2229 10.9482 49.3897 11.5981 49.3897 12.358V18.0454H51.6226V12.2728C51.6736 11.6102 51.8738 10.9763 52.2645 10.5111C52.6596 10.0408 53.2386 9.75066 54.0132 9.75066C54.6818 9.75066 55.2051 10.0135 55.556 10.4841C55.9021 10.9482 56.0688 11.5981 56.0688 12.358V18.0454H58.3018ZM69.3112 15.0859C68.5273 16.9095 66.7556 18.203 64.607 18.203C61.6659 18.203 59.3924 15.8161 59.3924 12.9884C59.3924 10.0461 61.7608 7.75414 64.607 7.75414C66.7182 7.75414 68.5078 9.03014 69.3103 10.8897L67.277 11.4536C66.7809 10.4645 65.8134 9.73096 64.607 9.73096C62.8328 9.73096 61.4678 11.2519 61.4678 12.9884C61.4678 14.7264 62.8341 16.2262 64.607 16.2262C65.829 16.2262 66.7993 15.514 67.2795 14.5054L69.3112 15.0859ZM78.2299 16.6828V18.0454H80.4628V7.93146H78.2299V9.32123L77.9467 9.0231C77.2273 8.26587 76.2057 7.75414 74.933 7.75414C72.1913 7.75414 69.9155 10.0401 69.9155 12.9884C69.9155 15.8201 72.1928 18.203 74.933 18.203C76.2077 18.203 77.2296 17.7094 77.9481 16.972L78.2299 16.6828ZM75.1891 16.2065C73.3605 16.2065 71.9908 14.7299 71.9908 12.9884C71.9908 11.2483 73.3592 9.75066 75.1891 9.75066C76.958 9.75066 78.3875 11.2462 78.3875 12.9884C78.3875 14.7327 76.9362 16.2065 75.1891 16.2065ZM82.6097 4.58204V18.0454H84.8427V4.58204H82.6097Z"
      // fill={color ?? "#ADB5BD"}
    />
    <path
      d="M40.7272 5.13511C40.7272 5.87393 40.1283 6.47286 39.3895 6.47286C38.6506 6.47286 38.0517 5.87393 38.0517 5.13511C38.0517 4.39629 38.6506 3.79736 39.3895 3.79736C40.1283 3.79736 40.7272 4.39629 40.7272 5.13511Z"
      // fill={color ?? "#ADB5BD"}
    />
  </svg>
);

export default VimcalHoverableLogo;
