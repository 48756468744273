import React, { useCallback, useEffect } from "react";
import AttachmentPreview from "../../attachmentPreview";
import {
  GOOGLE_DRIVE_UPLOAD_STATUS,
  GoogleDriveUploadStatus,
} from "./attachmentsDropzone";
import { constructRequestURLV2, isErrorResponse } from "../../../services/api";
import { GOOGLE_DRIVE_ENDPOINTS } from "../../../lib/endpoints";
import { fetcherPost } from "../../../services/fetcherFunctions";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";

interface GoogleDriveAttachmentPreviewProps {
  detachFile: (index: number) => void;
  file: File | DriveFile;
  index: number;
  hoveredFileIndex: number | null;
  setHoveredFileIndex: React.Dispatch<React.SetStateAction<number | null>>;
  status: GoogleDriveUploadStatus;
  updateAttachment: (
    index: number,
    updatedFields: { file?: DriveFile, status?: GoogleDriveUploadStatus }
  ) => void;
  userEmail: string;
}

/**
 * This wrapper includes logic to upload to S3 with a presigned url as soon as a file is selected.
 * Once mounted, do not change the values for the `file` or `s3Key` props.
 */
export default function GoogleDriveAttachmentPreview({
  detachFile,
  file,
  hoveredFileIndex,
  index,
  setHoveredFileIndex,
  status,
  updateAttachment,
  userEmail,
}: GoogleDriveAttachmentPreviewProps) {
  const startUpload = useCallback(async () => {
    updateAttachment(index, { status: GOOGLE_DRIVE_UPLOAD_STATUS.UPLOADING });

    const path = constructRequestURLV2(GOOGLE_DRIVE_ENDPOINTS.UPLOAD_FILE);
    const formData = new FormData();
    formData.append("file", file as File);
    const payloadData = {
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await fetcherPost<{ drive_file: BackendDriveFile } | ErrorResponse>({
      email: userEmail,
      payloadData,
      url: path,
    });

    if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      updateAttachment(index, { status: GOOGLE_DRIVE_UPLOAD_STATUS.ERROR });
      return;
    }

    const driveFile = response?.drive_file;
    const updatedFile = {
      fileId: driveFile?.id || "",
      fileUrl: driveFile?.webViewLink || "",
      iconLink: "",
      mimeType: driveFile?.mimeType || "",
      title: driveFile?.name || "",
    };
    updateAttachment(index, { file: updatedFile, status: GOOGLE_DRIVE_UPLOAD_STATUS.SUCCESS });
  }, []);

  /* Start file upload */
  useEffect(() => {
    if (status === GOOGLE_DRIVE_UPLOAD_STATUS.READY && file instanceof File) {
      startUpload();
    }
  }, [status]);

  return (
    <AttachmentPreview
      isUploading={
        status !== GOOGLE_DRIVE_UPLOAD_STATUS.SUCCESS &&
        status !== GOOGLE_DRIVE_UPLOAD_STATUS.ERROR
      }
      fileErrors={
        status === GOOGLE_DRIVE_UPLOAD_STATUS.ERROR
          ? ["There was an error uploading this file."]
          : undefined
      }
      file={file}
      handleRetryFileUpload={startUpload}
      hoveredFileIndex={hoveredFileIndex}
      index={index}
      setHoveredFileIndex={setHoveredFileIndex}
      onClickRemove={() => detachFile(index)}
    />
  );
}
