import React from "react";
import classNames from "classnames";
import Select, { type NamedProps as SelectProps } from "react-select";
import DropdownIndicator from "./dropDownIndicator";
import type { SelectOptionType } from ".";

export type SELECT_VARIANT = "filled" | "outlined";

export const SELECT_VARIANTS = {
  FILLED: "filled",
  OUTLINED: "outlined",
} as const;

// Make sure all options have `select-v2` included, otherwise these may inherit some
// styles from the v1 stylesheet.
const VARIANT_CLASS_NAMES: Record<SELECT_VARIANT, string> = {
  [SELECT_VARIANTS.FILLED]: "filled-select-v2",
  [SELECT_VARIANTS.OUTLINED]: "outlined-select-v2",
} as const;

interface CustomSelectV2Props<OptionType extends SelectOptionType, IsMulti extends boolean> extends Omit<SelectProps<OptionType, IsMulti>, "classNamePrefix"> {
  variant?: SELECT_VARIANT
}

/**
 * Changes from V1 / migration instructions for upgrading to V2:
 * - The default styling is now all applied with CSS with two pre-built variants. Pass in the
 *   `variant` prop to determine which variant is used (this replaces the `classNamePrefix`
 *   prop). Two variants are currently supported:
 *   - `filled` - The control has a background color and a subtle border.
 *   - `outlined` - The control has a border but no background color.
 * - `alwaysShowIndicator` is not included as a prop. We can add in if needed, but by default
 *   the dropdown incidator will always be visible.
 * - `hideIndicator` is not included as a prop. We can add if needed.
 *
 * To override base styles, your options are:
 * 1. If it's a reusable variant, add it as a new variant.
 * 2. For most cases: pass in a `className` and add to the stylesheets to override the styles
 *    as needed. Note that the selector in the stylesheets will need to be more specific than
 *    the base styles, otherwise they will be ignored.
 * 3. For one-off overrides: provide a `styles` prop. Note that the `!important` flag may be
 *    necessary for the styles to override the base styles due to how react-select applies
 *    these styles. Avoid this case if possible.
 *
 * TODO:
 * - Test that refs are forwarded properly. We may need to use `React.forwardRef` similar to the
 *   original component.
 */
export default function CustomSelectV2<OptionType extends SelectOptionType<any> = SelectOptionType, IsMulti extends boolean = false>({
  className,
  components,
  variant=SELECT_VARIANTS.FILLED,
  ...props
}: CustomSelectV2Props<OptionType, IsMulti>) {
  return (
    <Select<OptionType, IsMulti>
      {...props}
      className={classNames("custom-select-v2", VARIANT_CLASS_NAMES[variant], className)}
      classNamePrefix={VARIANT_CLASS_NAMES[variant]}
      components={{
        DropdownIndicator,
        ...components,
      }}
      formatOptionLabel={(option, meta) => (
        meta.context === "menu" ? (option.menuLabel ?? option.label) : option.label
      )}
    />
  );
}
