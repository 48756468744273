import React from "react";
import { EmailPlanDictionary, groupEmailPlanDictionaryBySeat, VIMCAL_DEFAULT_PLAN, VIMCAL_EA_PLAN } from "../sharedFunctions";
import { capitalizeFirstLetterOfEveryWord, pluralize } from "../../../../lib/stringFunctions";
import ColoredLine from "../../../line";
import { useTeamPlan } from "../../../../services/stores/userData";
import { getExistingTeamMembers } from "../../sharedFunctions";
import { getObjectEmail } from "../../../../lib/objectFunctions";
import { useDefaultPaymentFromSubscription, useOpenSeats } from "../../hooks";
import { useDefaultPaymentMethod } from "../../../../services/stores/finance";

interface ReviewFillingSeatsProps {
  emailPlanDictionary: EmailPlanDictionary
}

/**
 * The content of the review page when seats are only being filled (no new seats added).
 */
export default function ReviewFillingSeats({ emailPlanDictionary }: ReviewFillingSeatsProps) {
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const needCard = useDefaultPaymentMethod(state => state.needCard);
  const cardInfo = useDefaultPaymentFromSubscription();

  const vimcalSeatsFilled = Object.values(emailPlanDictionary).filter(({ plan }) => plan === VIMCAL_DEFAULT_PLAN.value).length;
  const vimcalEASeatsFilled = Object.values(emailPlanDictionary).filter(({ plan }) => plan === VIMCAL_EA_PLAN.value).length;

  const { vimcalEmails, vimcalEAEmails } = groupEmailPlanDictionaryBySeat(emailPlanDictionary);
  const existingMemberEmails = getExistingTeamMembers(teamPlan).map(u => getObjectEmail(u));
  // Users can have multiple invites sent.
  // For payment preview purposes, we only care about new users being added to the plan.
  const newVimcalEmails = vimcalEmails.filter(e => !existingMemberEmails.includes(e));
  const newVimcalEAEmails = vimcalEAEmails.filter(e => !existingMemberEmails.includes(e));

  const { openVimcalEASeats, openVimcalSeats } = useOpenSeats();
  const remainingVimcalSeats = openVimcalSeats - newVimcalEmails.length;
  const remainingVimcalEASeats = openVimcalEASeats - newVimcalEAEmails.length;

  return (
    <>
      <div>Team plan details</div>
      {vimcalSeatsFilled > 0 ? (
        <div className="secondary-text-color mt-4">
          {vimcalSeatsFilled} Vimcal {pluralize(vimcalSeatsFilled, "user")} added to team plan,{" "}
          {remainingVimcalSeats} {pluralize(remainingVimcalSeats, "seat")} remaining
        </div>
      ) : null}
      {vimcalEASeatsFilled > 0 ? (
        <div className="secondary-text-color mt-4">
          {vimcalEASeatsFilled} Vimcal EA {pluralize(vimcalEASeatsFilled, "user")} added to team plan,{" "}
          {remainingVimcalEASeats} {pluralize(remainingVimcalEASeats, "seat")} remaining
        </div>
      ) : null}

      {cardInfo && !needCard ? (
        <>
          <ColoredLine inputClassName='my-6' />

          <div className="flex gap-4">
            <div className="flex-grow">Payment method</div>
            <div className="secondary-text-color">{capitalizeFirstLetterOfEveryWord(cardInfo?.brand)}</div>
            <div className="secondary-text-color">•••• {cardInfo?.lastFour}</div>
          </div>
        </>
      ) : null}

      <ColoredLine inputClassName='my-6' />

      <div className="flex justify-between">
        <div>Total due today</div>
        <div>$0</div>
      </div>
    </>
  );
}
