import React from "react";
import {
  guessTimeZone,
  sortEventsJSDate,
  isBeforeMinute,
  isSameOrAfterMinute,
  isAfterMinute,
  isSameOrBeforeMinute,
  convertToTimeZone,
} from "./commonUsefulFunctions";
import { AGENDA_FORMAT_JS_DATE, MERGED_EVENTS } from "./globalVariables";
import {
  ATTENDEE_EVENT_DECLINED,
  ATTENDEE_EVENT_TENTATIVE,
  RSVP_STATUS,
} from "./googleCalendarService";
import {
  format,
  addMinutes,
  subMinutes,
  startOfMinute,
  parseISO,
  isSameMinute,
  addDays,
  startOfDay,
} from "date-fns";
import { getEventAttendeeStatus } from "../lib/calendarFunctions";
import {
  getSelfAttendingStatus,
  determineEventColor,
} from "../lib/eventFunctions";
import { getEventUserEventID } from "./eventResourceAccessors";
import { isEmptyArray } from "../lib/arrayFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export function findUpcomingEvent(param) {
  const { indexByDate, allCalendars, currentUserEmail, timeZone } = param;

  if (isEmptyObjectOrFalsey(indexByDate)) {
    return {
      nextEvent: null,
      events: [],
      upcomingEvents: [],
    };
  }

  const currentDate = getTodaysDate(timeZone);

  const currentTime = convertToTimeZone(new Date(), {
    timeZone: timeZone || guessTimeZone(),
  });
  let nextEvent = null;
  let newEvents = [];
  let upcomingEvents = [];
  let filteredEventsList = [];
  const getCurrentDayEvents = () => {
    const currentDayEvents = indexByDate[currentDate] ?? [];
    const nextDay = addDays(
      convertToTimeZone(new Date(), { timeZone: timeZone || guessTimeZone() }),
      1
    );
    const nextDayString = format(
      nextDay,
      AGENDA_FORMAT_JS_DATE
    );
    const nextDayEvents = indexByDate[nextDayString];
    if (nextDayEvents?.length > 0) {
      const nextDayMidnight = startOfDay(nextDay);
      const midnightEvents = nextDayEvents.filter((e) =>
        isSameMinute(e.eventStart, nextDayMidnight)
      );
      return currentDayEvents.concat(midnightEvents);
    }
    return currentDayEvents;
  };
  const currentDateEvents = getCurrentDayEvents();

  const startTimeCutoff = subMinutes(currentTime, 15); // only have start time go 10 min back
  if (!isEmptyArray(currentDateEvents)) {
    currentDateEvents.forEach((e) => {
      if (
        getEventAttendeeStatus(e, allCalendars) !== ATTENDEE_EVENT_DECLINED &&
        !e.displayAsAllDay &&
        isAfterMinute(
          convertCurrentTimeZone(e.defaultStartTime, timeZone),
          startTimeCutoff
        )
      ) {
        filteredEventsList = filteredEventsList.concat(e);
      }
    });
  }

  if (isEmptyArray(filteredEventsList)) {
    return {
      nextEvent: null,
      events: [],
      upcomingEvents: [],
    };
  }

  // Go through filtered events, find all events +-10 minutes from now.
  // If no events -> find next event;

  const plusTenMinutes = addMinutes(startOfMinute(new Date()), 10);
  const minusTenMinutes = subMinutes(startOfMinute(new Date()), 10);

  filteredEventsList.forEach((e) => {
    if (
      isSameOrBeforeMinute(parseISO(e.defaultStartTime), plusTenMinutes) &&
      isSameOrAfterMinute(parseISO(e.defaultStartTime), minusTenMinutes)
    ) {
      upcomingEvents = upcomingEvents.concat(e);
    }
  });

  if (upcomingEvents.length === 0) {
    // if no upcoming events, see if there are events that are currently going on
    upcomingEvents = fetchUpcomingByEndTime(filteredEventsList);
  }

  // If no events -> find next event out of +-10min zone
  if (upcomingEvents.length === 0) {
    filteredEventsList.forEach((e, index) => {
      nextEvent = determineWhichEventIsNextEvent(
        nextEvent,
        e,
        currentTime,
        currentUserEmail,
        timeZone
      );
    });
    if (nextEvent) {
      // get all events that are at the same time
      filteredEventsList.forEach((e) => {
        if (
          isSameMinute(
            parseISO(e.defaultStartTime),
            parseISO(nextEvent.defaultStartTime)
          )
        ) {
          upcomingEvents = upcomingEvents.concat(e);
        }
      });
    }
  } else {
    // sort (could be out of order)
    upcomingEvents = upcomingEvents.sort((a, b) => sortEventsJSDate(a, b));
    nextEvent = upcomingEvents[0];
  }

  const upcomingEventsIds = upcomingEvents.map((e) => getEventUserEventID(e));

  if (filteredEventsList.length > 0) {
    filteredEventsList.forEach((e) => {
      if (
        isBeforeMinute(
          currentTime,
          convertCurrentTimeZone(e.defaultStartTime, timeZone)
        ) &&
        !upcomingEventsIds.includes(getEventUserEventID(e))
      ) {
        newEvents = newEvents.concat(e);
      }
    });

    // Sort
    if (newEvents.length > 0) {
      newEvents = newEvents.sort((a, b) => sortEventsJSDate(a, b));
    }
  }

  // events: events that are coming up in the day but not in the large upcoming section
  return {
    nextEvent: nextEvent,
    events: newEvents,
    upcomingEvents,
  };
}

function getTodaysDate(timeZone) {
  return format(
    convertToTimeZone(new Date(), { timeZone: timeZone || guessTimeZone() }),
    AGENDA_FORMAT_JS_DATE
  );
}

function fetchUpcomingByEndTime(filteredEvents) {
  let currentMeetings = [];

  filteredEvents.forEach((e) => {
    if (e.displayAsAllDay) {
      return;
    }

    let endTime = subMinutes(startOfMinute(parseISO(e.defaultEndTime)), 10);

    if (
      isAfterMinute(endTime, new Date()) &&
      isBeforeMinute(parseISO(e.defaultStartTime), new Date())
    ) {
      currentMeetings = currentMeetings.concat(e);
    }
  });

  return currentMeetings;
}

function determineWhichEventIsNextEvent(
  currentNextEvent,
  otherEvent,
  currentTime,
  currentUserEmail,
  timeZone
) {
  const startTime = createAgendaTime(otherEvent.defaultStartTime, timeZone);
  const isOtherEventBeforeCurrentTime = isBeforeMinute(currentTime, startTime);

  if (!isOtherEventBeforeCurrentTime) {
    return currentNextEvent;
  } else if (!currentNextEvent) {
    return otherEvent;
  } else if (
    isBeforeMinute(
      convertCurrentTimeZone(currentNextEvent.defaultStartTime, timeZone),
      currentTime
    ) &&
    isSameOrAfterMinute(
      convertCurrentTimeZone(otherEvent.defaultStartTime, timeZone),
      currentTime
    ) &&
    isAfterMinute(
      convertCurrentTimeZone(otherEvent.defaultStartTime, timeZone),
      convertCurrentTimeZone(currentNextEvent.defaultStartTime, timeZone)
    )
  ) {
    // current next event is before current time and otherEvent is after currentTime and otherEvent is after currentEvent
    return otherEvent;
  }
  if (
    isBeforeMinute(
      createAgendaTime(otherEvent.defaultStartTime, timeZone),
      createAgendaTime(currentNextEvent.defaultStartTime, timeZone)
    )
  ) {
    // other event is before the current next event

    return otherEvent;
  } else if (
    isBeforeMinute(
      createAgendaTime(otherEvent.defaultStartTime, timeZone),
      createAgendaTime(currentNextEvent.defaultStartTime, timeZone)
    )
  ) {
    if (!currentUserEmail) {
      return currentNextEvent;
    }
    // when both event start time are tied -> figure out tie breaker
    const currentEventAttendance = getSelfAttendingStatus(
      currentNextEvent,
      currentUserEmail
    );
    const otherEventAttendance = getSelfAttendingStatus(
      otherEvent,
      currentUserEmail
    );

    if (
      currentEventAttendance !== RSVP_STATUS.ATTENDING &&
      otherEventAttendance === RSVP_STATUS.ATTENDING
    ) {
      return otherEvent;
    } else if (
      currentEventAttendance === RSVP_STATUS.DECLINED &&
      otherEventAttendance === RSVP_STATUS.MAYBE
    ) {
      return otherEvent;
    } else {
      return currentNextEvent;
    }
  }

  return currentNextEvent;
}

function createAgendaTime(startTime, timeZone) {
  return addMinutes(convertCurrentTimeZone(startTime, timeZone), 10);
}

function convertCurrentTimeZone(time, timeZone) {
  return convertToTimeZone(time, { timeZone: timeZone || guessTimeZone() });
}

export function isNextEventNow(nextEvent) {
  return (
    isSameOrAfterMinute(new Date(), parseISO(nextEvent.defaultStartTime)) &&
    isSameOrBeforeMinute(new Date(), parseISO(nextEvent.defaultEndTime))
  );
}

export function getEventButtonStyle({
  event,
  allCalendars,
  user,
  currentUser,
  allLoggedInUsers,
  outlookCategories,
  masterAccount,
}) {
  // to return style, need 2 things
  // attendee status
  // color of event
  const attendeeStatus = getEventAttendeeStatus(event, allCalendars);
  const color = determineEventColor({
    event,
    allCalendars,
    user,
    currentUser,
    allLoggedInUsers,
    outlookCategories,
    masterAccount,
    where: "sharedAgendaFunctions::getEventButtonStyle",
  });

  const isTransparent =
    attendeeStatus === ATTENDEE_EVENT_TENTATIVE ||
    attendeeStatus === ATTENDEE_EVENT_DECLINED;

  return {
    backgroundColor: isTransparent ? "transparent" : color,
    borderColor: color,
  };
}

export function renderMergedEventIndicator({
  event,
  allCalendars = {},
  user,
  currentUser,
  allLoggedInUsers,
  outlookCategories,
  masterAccount,
}) {
  if (isEmptyObjectOrFalsey(event)) {
    return (
      <div
        className="agenda-event-calendar-color"
        style={getEventButtonStyle({
          event,
          allCalendars,
          user,
          currentUser,
          outlookCategories,
          masterAccount,
        })}
      ></div>
    );
  }

  let mergedEvents = event[MERGED_EVENTS];
  if (isEmptyArray(mergedEvents)) {
    return (
      <div
        className="agenda-event-calendar-color"
        style={getEventButtonStyle({
          event,
          allCalendars,
          user,
          currentUser,
          allLoggedInUsers,
          outlookCategories,
          masterAccount,
        })}
      ></div>
    );
  }

  const determineIndicatorTop = (index, totalLength) => {
    if (totalLength === 1) {
      return 6;
    }
    if (totalLength > 2) {
      switch (index) {
        case 0:
          return 0;
        case 1:
          return 6;
        case 2:
          return 12;
        default:
          return 0;
      }
    }

    switch (index) {
      case 0:
        return 2;
      case 1:
        return 8;
      case 2:
        return 14;
      default:
        return 2;
    }
  };

  const eventsArray = filterOutMergedEventsWithSameColor({
    events: mergedEvents.slice(0, 3).reverse(),
    allCalendars,
    user,
    currentUser,
    allLoggedInUsers,
    outlookCategories,
    masterAccount,
  });
  return (
    <div className="relative">
      {eventsArray.map((e, index) => {
        return (
          <div
            key={`agenda-multi-cal-${getEventUserEventID(e)}-${index}`}
            className="agenda-event-calendar-color absolute"
            style={Object.assign(
              getEventButtonStyle({
                event: e,
                allCalendars,
                user,
                currentUser,
                allLoggedInUsers,
                outlookCategories,
                masterAccount,
              }),
              { top: determineIndicatorTop(index, eventsArray.length) }
            )}
          ></div>
        );
      })}
    </div>
  );
}

function filterOutMergedEventsWithSameColor({
  events,
  allCalendars,
  user,
  currentUser,
  allLoggedInUsers,
  outlookCategories,
  masterAccount,
}) {
  if (isEmptyArray(events)) {
    return [];
  }
  let existingColors = [];
  let filteredEvents = [];
  events.forEach((event) => {
    const { backgroundColor } = getEventButtonStyle({
      event,
      allCalendars,
      user,
      currentUser,
      allLoggedInUsers,
      outlookCategories,
      masterAccount,
    });
    if (!existingColors.includes(backgroundColor)) {
      existingColors = existingColors.concat(backgroundColor);
      filteredEvents = filteredEvents.concat(event);
    }
  });
  return filteredEvents;
}
