import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dropzone, { DropzoneProps } from "react-dropzone";

import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { megabytesToBytes } from "../../../lib/fileFunctions";
import CustomButtonBox from "../../customButtonBox";
import {
  getAuthenticatedUserFromUser,
  getUserEmail,
  getUserProvider,
  getUserScope,
} from "../../../lib/userFunctions";
import GoogleDriveAttachmentPreview from "./attachmentPreview";
import produce from "immer";
import broadcast from "../../../broadcasts/broadcast";
import { PERMISSION_MODAL_TYPES } from "../../../lib/authFunctions";
import { GOOGLE_INCREMENTAL_LOGIN_SCOPES } from "../../../lib/googleFunctions";
import GoogleDriveIcon from "../../icons/googleDrive";
import { storeLocalEventFormState } from "../../../services/sharedEventFormFunctions";
import { BROADCAST_VALUES } from "../../../lib/broadcastValues";
import usePrevious from "../../specialComponents/usePrevious";
import { isUserLimitedAccess } from "../../../services/maestroFunctions";
import { isSameEmail } from "../../../lib/stringFunctions";
import { isDriveFile, SUPPORTED_DRIVE_TYPES } from "../../../services/googleDriveFunctions";

const MAX_FILE_SIZE_IN_MB = 10;
const MAX_NUMBER_OF_FILES = 25;

interface Attachment {
  file: File | DriveFile;
  status: GoogleDriveUploadStatus;
}

interface GoogleDriveAttachmentsProps {
  description: string;
  eventFormState: any;
  parentAttachments: DriveFile[];
  setParentAttachments: (attachmentURLs: DriveFile[]) => void;
  user: User;
}

export const GOOGLE_DRIVE_UPLOAD_STATUS = {
  ERROR: "error",
  READY: "ready",
  UPLOADING: "uploading",
  SUCCESS: "success",
} as const;
export type GoogleDriveUploadStatus = ValueOf<
  typeof GOOGLE_DRIVE_UPLOAD_STATUS
>;

export default function GoogleDriveAttachments({
  description,
  eventFormState,
  parentAttachments,
  setParentAttachments,
  user,
}: GoogleDriveAttachmentsProps) {
  const [formError, setFormError] = useState<string>();
  const initialAttachments = useMemo(() => parentAttachments.map(
    (attachment) => ({ file: attachment, status: GOOGLE_DRIVE_UPLOAD_STATUS.SUCCESS }),
  ), []);
  const [attachments, setAttachments] = useState<Attachment[]>(initialAttachments);
  const [hoveredFileIndex, setHoveredFileIndex] = useState<number | null>(null);
  const hasGoogleDriveScope = useMemo(() => {
    return getUserScope(user).includes(GOOGLE_INCREMENTAL_LOGIN_SCOPES.DRIVE);
  }, [user]);
  const previousUser = usePrevious(user);

  /* Update parent state when local attachment state is changed */
  useEffect(() => {
    const attachmentForEvents = attachments
      .filter(attachment => isDriveFile(attachment.file)) // Filter non-drive files
      .map(attachment => attachment.file) as DriveFile[];
    setParentAttachments(attachmentForEvents);
  }, [attachments]);

  /* Clear attachments if user changed */
  useEffect(() => {
    if (
      !isEmptyObjectOrFalsey(previousUser) &&
      !isEmptyObjectOrFalsey(user)
    ) {
      const previousUserEmail = isUserLimitedAccess(previousUser) ?
        getUserEmail(getAuthenticatedUserFromUser(previousUser)) :
        getUserEmail(previousUser);
      const userEmail = isUserLimitedAccess(user) ?
        getUserEmail(getAuthenticatedUserFromUser(user)) :
        getUserEmail(user);

      if (!isSameEmail(previousUserEmail, userEmail)) {
        setAttachments([]);
      }
    }
  }, [user]);

  const buildAttachment = (file: File): Attachment => {
    return { file, status: GOOGLE_DRIVE_UPLOAD_STATUS.READY };
  };

  const detachFile = useCallback((attachmentIndex: number) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter(
        (attachment, index) => index !== attachmentIndex,
      ),
    );
  }, []);

  const openPermissionsModal = useCallback(() => {
    broadcast.publish(BROADCAST_VALUES.OPEN_INCREMENTAL_PERMISSIONS_MODAL, {
      modalContent: PERMISSION_MODAL_TYPES.UPDATE_GOOGLE_DRIVE_PERMISSIONS,
      permissionProvider: getUserProvider(user), // Should always be Google
      permissionEmail: getUserEmail(user),
    });
  }, []);

  const onDropAccepted: DropzoneProps["onDropAccepted"] = (files) => {
    /* Clear any errors */
    if (formError) {
      setFormError(undefined);
    }

    /* Open permissions modal if missing scope */
    if (!hasGoogleDriveScope) {
      openPermissionsModal();
      return;
    }

    if (attachments.length + files.length > MAX_NUMBER_OF_FILES) {
      setFormError(`Please attach no more than ${MAX_NUMBER_OF_FILES} files.`);
      return;
    }

    const newAttachments = files.map(buildAttachment);
    setAttachments((currentAttachments) => [
      ...currentAttachments,
      ...newAttachments,
    ]);
  };

  const onDropRejected: DropzoneProps["onDropRejected"] = (files) => {
    const firstError = files[0].errors[0].message;
    if (firstError.includes("File is larger than")) {
      // The default error message includes the file size in bytes, not very useful.
      setFormError(`The max file size is ${MAX_FILE_SIZE_IN_MB}MB.`);
    } else if (firstError.includes("File type must be")) {
      // The default error message lists all the acceptable MIME types, which is
      // probably overkill and confusing to users.
      setFormError("The file must be an image, a video, or a zip file.");
    } else {
      setFormError("This file could not be attached.");
    }
  };

  const updateAttachment = useCallback(
    (
      attachmentIndex: number,
      updatedFields: { file?: DriveFile; status?: GoogleDriveUploadStatus },
    ) => {
      setAttachments((currentAttachments) => {
        return produce(currentAttachments, (draftState) => {
          const indexToUpdate = draftState.findIndex(
            (attachment, index) => index === attachmentIndex,
          );
          if (indexToUpdate !== -1) {
            Object.keys(updatedFields).forEach(
              (field) =>
                (draftState[indexToUpdate][field] = updatedFields[field]),
            );
            return draftState;
          }
        });
      });
    },
    [],
  );

  return (
    <>
      {formError ? (
        <div className="text-red-500 text-xs ml-2.5">{formError}</div>
      ) : null}
      {isEmptyArrayOrFalsey(attachments) ? null : (
        <div className="my-2">
          {attachments.map((attachment, index) => (
            <GoogleDriveAttachmentPreview
              detachFile={detachFile}
              file={attachment.file}
              hoveredFileIndex={hoveredFileIndex}
              index={index}
              key={`google-drive-attachment-${index}`}
              setHoveredFileIndex={setHoveredFileIndex}
              status={attachment.status}
              updateAttachment={updateAttachment}
              userEmail={getUserEmail(user)}
            />
          ))}
        </div>
      )}
      <Dropzone
        accept={SUPPORTED_DRIVE_TYPES}
        maxSize={megabytesToBytes(MAX_FILE_SIZE_IN_MB)}
        noClick
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <CustomButtonBox
              className="mt-2"
              onClick={() => {
                if (!hasGoogleDriveScope) {
                  /* Store the event form state into localData to restore */
                  storeLocalEventFormState({ description, state: eventFormState });
                  openPermissionsModal();
                  return;
                }

                open();
              }}
            >
              <div className="file-attachment-expand-file font-weight-300">
                <GoogleDriveIcon size={14} />
                <div className="file-attachment-title">Upload to Drive</div>
              </div>
            </CustomButtonBox>
          </div>
        )}
      </Dropzone>
    </>
  );
}
