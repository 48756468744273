import React from "react";
import VimcalLogo from "./vimcalLogo";
import Ellipse from "./ellipse";
import classNames from "classnames";
import CompleteCheckmarkSVG from "../../components/icons/greenCheckMark";

export default function SsoNewUserProviderLogin({
  renderGoogleLoginButton,
  renderOutlookLoginButton,
}) {
  return (
    <div className={"vimcal-updated-login-container light-mode-font-color"}>
      <VimcalLogo className="vimcal-updated-login-logo" />
      <Ellipse className="vimcal-updated-login-ellipse" />
      <div
        className={classNames(
          "center-content-absolute",
          "bg-white",
          "rounded-md",
          "flex items-center justify-center flex-col",
          "w-96",
          "z-10 py-8"
        )}
      >
        <CompleteCheckmarkSVG />
        <div className="font-weight-500 font-size-20 mt-6 mb-4">
          SSO authentication successful
        </div>
        <div className="default-font-size">
          Authorize using your calendar to proceed
        </div>
        {renderGoogleLoginButton()}
        {renderOutlookLoginButton()}
      </div>
    </div>
  );
}
