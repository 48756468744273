import React, { useCallback, useEffect, useState } from "react";

import produce from "immer";
import Dropzone, { DropzoneProps } from "react-dropzone";
import { Paperclip } from "react-feather";
import { useSelector } from "react-redux";

import CustomButtonBox from "./customButtonBox";
import { PRESIGNED_UPLOAD_STATUS, PresignedUploadStatus } from "../services/customHooks/usePresignedUpload";
import { getUserName } from "../lib/userFunctions";
import { useMasterAccount } from "../services/stores/SharedAccountData";
import {
  SUPPORTED_COMPRESSED_FILE_TYPES,
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_VIDEO_FILE_TYPES,
  createFileNameWithPrefixAndSuffix,
  megabytesToBytes,
} from "../lib/fileFunctions";
import { SECOND_IN_MS } from "../services/globalVariables";
import FeedbackAttachmentPreview from "./feedbackAttachmentPreview";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";

const MAX_FILE_SIZE_IN_MB = 25;
const MAX_NUMBER_OF_FILES = 5;

interface Attachment {
  file: File
  s3Key: string
  status: PresignedUploadStatus
}

interface FeedbackAttachmentsProps {
  setAreAllAttachmentsUploaded: (areAllAttachmentsUploaded: boolean) => void
  setAttachmentKeys: (attachmentKeys: string[]) => void
}

export default function FeedbackAttachments({ setAreAllAttachmentsUploaded, setAttachmentKeys }: FeedbackAttachmentsProps) {
  const { masterAccount } = useMasterAccount();
  const currentUser = useSelector<{ currentUser: User }, User>((state) => state.currentUser);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [hoveredFileIndex, setHoveredFileIndex] = useState<number | null>(null);
  const [formError, setFormError] = useState<string>();

  // Auto clear form errors after a few seconds.
  useEffect(() => {
    if (formError) {
      const timeout = window.setTimeout(() => setFormError(undefined), 10 * SECOND_IN_MS);
      return () => window.clearTimeout(timeout);
    }
  }, [formError]);

  // Update the parent form state as attachments are updated.
  useEffect(() => {
    if (isEmptyArrayOrFalsey(attachments)) {
      return;
    }
    const areAllAttachmentsUploaded = attachments.every(attachment => (
      attachment.status === PRESIGNED_UPLOAD_STATUS.SUCCESS
    ));

    setAreAllAttachmentsUploaded(areAllAttachmentsUploaded);
    const s3AttachmentKey = attachments.filter(attachment => attachment?.s3Key).map(attachment => attachment.s3Key);
    setAttachmentKeys(s3AttachmentKey);
  }, [attachments]);

  // Determine the S3 key upfront as a unique identifier for the file.
  // The S3 key must not change after initializing, otherwise the presigned link will not work.
  const buildAttachment = (file: File): Attachment => {
    const { userName } = getUserName({ masterAccount, user: currentUser });
    const timestamp = new Date().valueOf();
    const s3Key = createFileNameWithPrefixAndSuffix({
      fileName: file.name,
      prefix: `${userName}/`,
      suffix: `-${timestamp}`,
    });

    return { file, s3Key, status: PRESIGNED_UPLOAD_STATUS.LOADING };
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = (files) => {
    if (attachments.length + files.length > MAX_NUMBER_OF_FILES) {
      setFormError(`Please attach no more than ${MAX_NUMBER_OF_FILES} files.`);
      return;
    }

    const newAttachments = files.map(buildAttachment);
    setAttachments(currentAttachments => [...currentAttachments, ...newAttachments]);
  };

  const onDropRejected: DropzoneProps["onDropRejected"] = (files) => {
    const firstError = files[0].errors[0].message;
    if (firstError.includes("File is larger than")) {
      // The default error message includes the file size in bytes, not very useful.
      setFormError(`The max file size is ${MAX_FILE_SIZE_IN_MB}MB.`);
    } else if (firstError.includes("File type must be")) {
      // The default error message lists all the acceptable MIME types, which is
      // probably overkill and confusing to users.
      setFormError("The file must be an image, a video, or a zip file.");
    } else {
      setFormError("This file could not be attached.");
    }
  };

  const detachFile = useCallback((s3Key: string) => {
    setAttachments(currentAttachments => (
      currentAttachments.filter(attachment => attachment.s3Key !== s3Key)
    ));
  }, []);

  const updateAttachmentStatus = useCallback((s3Key: string, status: PresignedUploadStatus) => {
    setAttachments(currentAttachments => {
      return produce(currentAttachments, (draftState) => {
        const indexToUpdate = draftState.findIndex(attachment => attachment.s3Key === s3Key);
        if (indexToUpdate !== -1) {
          draftState[indexToUpdate].status = status;
          return;
        }
      });
    });
  }, []);

  return (
    <>
      <div className="font-weight-400 font-size-14">Attachments</div>
      {formError ? (
        <div className="text-red-500 text-xs">
          {formError}
        </div>
      ) : null}
      {isEmptyArrayOrFalsey(attachments) ? null : (
        <div className="my-2">
          {attachments.map((attachment, index) => (
            <FeedbackAttachmentPreview
              key={attachment.s3Key}
              file={attachment.file}
              s3Key={attachment.s3Key}
              index={index}
              hoveredFileIndex={hoveredFileIndex}
              setHoveredFileIndex={setHoveredFileIndex}
              detachFile={detachFile}
              updateAttachmentStatus={(status) => updateAttachmentStatus(attachment.s3Key, status)}
            />
          ))}
        </div>
      )}
      <Dropzone
        maxSize={megabytesToBytes(25)}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        accept={[
          ...SUPPORTED_IMAGE_FILE_TYPES,
          ...SUPPORTED_VIDEO_FILE_TYPES,
          ...SUPPORTED_COMPRESSED_FILE_TYPES,
        ]}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <CustomButtonBox className="mt-2">
              <div className="file-attachment-expand-file font-weight-300">
                <Paperclip size={12} />
                <div className="file-attachment-title">
                  Add Attachment(s)
                </div>
              </div>
            </CustomButtonBox>
          </div>
        )}
      </Dropzone>
    </>
  );
}
