import React, { useEffect } from "react";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { removeSavedTeamInviteToken } from "../../lib/stateManagementFunctions";

interface TeamPlanJoinedModalProps {
  adminEmail: string
  closeModal: () => void
}

export default function TeamPlanJoinedModal({ adminEmail, closeModal }: TeamPlanJoinedModalProps) {
  useEffect(() => {
    removeSavedTeamInviteToken();
  }, []);

  return (
    <div>
      <p className="default-font-size">
        Congratulations, you joined the team plan from{" "}
        {adminEmail}
      </p>
      <div className="display-flex-flex-direction-row align-items-center justify-content-flex-end mt-10">
        <CustomButton
          shouldFocus={true}
          buttonType={BLUE_BUTTON}
          onClick={() => {
            closeModal();
          }}
          label="Ok"
        />
      </div>
    </div>
  );
}
