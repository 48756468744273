import React, { PureComponent } from "react";
import {
  removeDuplicatesFromArray,
  createEventTextFromSuggestion,
  createEventText,
  RoundToClosestMinute,
  addAbbrevationToTimeZone,
  TimeDifferenceBetweenTimeStartAndTimeEnd,
  KEYCODE_ESCAPE,
} from "../services/commonUsefulFunctions";
import LocationSearchInput from "../components/locationSearchInput";
import { connect, batch } from "react-redux";
import ReactSelectAttendeeAutoComplete from "../components/reactSelectAttendeeAutoComplete";
import CustomSelect from "../components/select";
import GoogleCalendarService, {
  NO_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
} from "../services/googleCalendarService";
import Broadcast from "../broadcasts/broadcast";
import Checkbox from "./checkbox";
import moment from "moment";
import {
  DEFAULT_FONT_COLOR,
  INVITEE_NAME_BLOCK,
} from "../services/globalVariables";
import SelectDuration from "./selectDuration";
import EventTitleInput from "./eventTitleInput";
import { X } from "react-feather";
import { getValidUserConferencingOptions } from "../lib/conferencing";
import { useAllCalendars, useMasterAccount, useZoomSchedulers } from "../services/stores/SharedAccountData";
import classNames from "classnames";
import { isUserBeingScheduledFor } from "../services/maestroFunctions";
import { doesListOfQuestionsIncludeCompany, doesTitleIncludeCompanyQuestion, getAllExtraBookingVariables } from "../lib/availabilityFunctions";
import { getZoomSchedulers } from "../services/zoomFunctions";
import { getUserEmail } from "../lib/userFunctions";
import { capitalizeFirstLetter, getInputStringFromEvent, isValidEmail, truncateString } from "../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { blurCalendar } from "../services/appFunctions";
import { REACT_ATTENDEE_SELECT_LOCATION } from "../lib/vimcalVariables";
import { getReactSelectBaseStyle } from "./select/styles";

// Strings from google
let { noConferenceString } = GoogleCalendarService;

const ATTENDEE = "newAttendee";

const TITLE_INPUT = "title_input";

class PersonalLinkEventForm extends PureComponent {
  constructor(props) {
    super(props);

    this.addAttendeeRef = React.createRef();
    this.timeSearch = React.createRef();
    this.windowSizeChangeTimer = null;

    this.contentEditableTitle = React.createRef();
    // these options should always be available
    const DEFAULT_CONFERENCING_OPTIONS = [
      HANGOUT_CONFERENCING_OPTION,
      NO_CONFERENCING_OPTION,
    ];

    let {
      eventStart,
      eventEnd,
      dateTimeText,
      initialStartTime,
      initialStartDate,
      initialEndTime,
      initialEndDate,
    } = this.createFromSelectedSlot(props.selectedTimeSlot);

    let { title, duration, timeZone, conferencing, location, description } =
      props;
    const {
      masterAccount
    } = this.props.masterAccount;

    this.state = {
      hasCapitalizedTitle: false,
      summary: title || this.props.defaultTitle || "",
      timeHasError: false,
      eventTimeZone: timeZone || this.props.currentTimeZone,
      isInvalidAddress: false,
      eventStart,
      eventEnd,
      dateTimeText,
      attendeesText: "",
      attendees: this.props.attendees || [],
      location: location || "",
      conference: conferencing || NO_CONFERENCING_OPTION,
      description: description || "",
      conferencingOptions: getValidUserConferencingOptions({
        currentUser: this.getUser(),
        defaultOptions: DEFAULT_CONFERENCING_OPTIONS,
        allCalendars: this.props.allCalendars.allCalendars,
        isUserBeingScheduledFor: isUserBeingScheduledFor({
          user: this.getUser(),
          schedulers: getZoomSchedulers(this.props.zoomSchedulers),
          masterAccount
        }),
      }),
      shouldSendEmail: true,
      hasSetConference: false,
      initialStartAndEndTimeDiff: TimeDifferenceBetweenTimeStartAndTimeEnd(
        eventStart,
        eventEnd
      ),
      shouldPinCopyButton: true,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      initialStartTime,
      initialStartDate,
      initialEndTime,
      initialEndDate,
      updatedContacts: null,
      duration: duration || { hours: 0, minutes: 30 },
      suggestions: [],
      replaceString: "",
      hoverOverSuggestion: false,
    };

    this.onClickEscape = this.onClickEscape.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeAttendees = this.onChangeAttendees.bind(this);
    this.onChangeWhere = this.onChangeWhere.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.goToAttendeeList = this.goToAttendeeList.bind(this);
    this.addAttendees = this.addAttendees.bind(this);
    this.setConference = this.setConference.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.setTimeText = this.setTimeText.bind(this);
    this.toggleSendEmail = this.toggleSendEmail.bind(this);
    this.summaryFocus = this.summaryFocus.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.focusDescription = this.focusDescription.bind(this);
    this.onChangeTimeText = this.onChangeTimeText.bind(this);
    this.setValidAddressWarning = this.setValidAddressWarning.bind(this);
    this.nlpFocus = this.nlpFocus.bind(this);
    this.setSummary = this.setSummary.bind(this);
    this.removeArrayOfAttendees = this.removeArrayOfAttendees.bind(this);
    this.onNlpDateAndTimeChange = this.onNlpDateAndTimeChange.bind(this);
    this.updateContacts = this.updateContacts.bind(this);
    this.setDuration = this.setDuration.bind(this);
    this.saveAvailabilityEventDetail =
      this.saveAvailabilityEventDetail.bind(this);
    this.onKeyDownEscape = this.onKeyDownEscape.bind(this);
    this.onKeyDownConferencing = this.onKeyDownConferencing.bind(this);
    this.focusTitle = this.focusTitle.bind(this);
    this.onKeyDownDescription = this.onKeyDownDescription.bind(this);

    window.addEventListener("resize", this.handleWindowSizeChange);

    Broadcast.subscribe(
      "SAVE_PERSONAL_LINK_EVENT_DETAIL",
      this.saveAvailabilityEventDetail
    );
    Broadcast.subscribe("FOCUS_SIMPLIFIED_CREATE_EVENT_TITLE", this.focusTitle);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.selectedTimeSlot) {
      Broadcast.publish("SAVE_PERSONAL_LINK_EVENT_DETAIL");
    }

    this.nlpFocus();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this._isMounted || !this.props.saveEventDetail) {
      // do nothing
    } else if (prevState.location !== this.state.location) {
      this.props.saveEventDetail({ location: this.state.location });
    } else if (prevState.duration !== this.state.duration) {
      this.props.saveEventDetail({ duration: this.state.duration });
    } else if (prevState.conference !== this.state.conference) {
      this.props.saveEventDetail({ conferencing: this.state.conference });
    } else if (prevState.description !== this.state.description) {
      this.props.saveEventDetail({ description: this.state.description });
    } else if (prevState.attendees !== this.state.attendees) {
      this.props.saveEventDetail({ attendees: this.state.attendees }, false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.windowSizeChangeTimer);
    clearTimeout(this._saveTimeout);

    this.windowSizeChangeTimer = null;
    this._saveTimeout = null;

    Broadcast.unsubscribe("SAVE_PERSONAL_LINK_EVENT_DETAIL");
    Broadcast.unsubscribe("FOCUS_SIMPLIFIED_CREATE_EVENT_TITLE");

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    const {
      customQuestions,
    } = this.props;
    const showCompanyQuestion = doesListOfQuestionsIncludeCompany(customQuestions);
    return (
      <div className="overflow-x-hidden">
        <div className="create-event-title mt-5">Title</div>
        <div tabIndex={1} onFocusCapture={this.focusDescription}></div>
        <EventTitleInput
          summary={this.state.summary}
          onSaveTitle={(title) => {
            if (this.props.title === title) {
              return;
            }
            this.props.saveEventDetail({ title }, false); // do not need to trigger update
          }}
          containerId={"personal-link-event-form"}
          extraAvailableVariables={getAllExtraBookingVariables({customQuestions})}
        />
        {!showCompanyQuestion && doesTitleIncludeCompanyQuestion(this.state.summary)
            ? <div className="warning-color default-font-size mt-1 event-title-warning">The recipient’s company is no longer being collected, which could cause unintended errors here.</div>
            : null
          }

        {this.renderDuration()}

        {this.renderAttendees()}
        {this.renderAttendeeError()}

        {this.renderSelectedAttendees()}

        {this.renderConferencing()}

        {this.renderWhere()}

        {this.renderDescription()}

        {this.renderTabGuardRail()}
      </div>
    );
  }

  renderDuration() {
    return (
      <div
        className="mt-5"
        ref={(input) => {
          this.dateTime = input;
        }}
        id="dateAndTime"
      >
        <div className="create-event-title">Event Duration</div>

        <SelectDuration
          duration={this.state.duration}
          onChange={this.setDuration}
          // onBlur={this.setPopUpTime}
          // onFocus={() => this.onFocusElement(START_TIME)}
          allDay={false}
          style={{ marginTop: 10 }}
        />
      </div>
    );
  }

  renderAttendeeError() {
    return (
      this.state.isInvalidAddress && (
        <div className="create-event-time-warning">Invalid email address.</div>
      )
    );
  }

  renderAttendees() {
    return (
      <div className="display-flex-flex-direction-row align-items-center create-event-field-margin-top">
        <div className="create-event-field-label">Who</div>

        <ReactSelectAttendeeAutoComplete
          updateContacts={this.updateContacts}
          useRecentlySearchedContacts={true}
          inputTabIndex={5}
          hidePlaceHolder={true}
          innerRef={this.addAttendeeRef}
          addAttendees={this.addAttendees}
          updateAttendeeQuery={this.onChangeAttendees}
          // onEscape={this.resetLatestFocus}
          // onCommandEnter={this.onClickSave}
          componentLocation={REACT_ATTENDEE_SELECT_LOCATION.PERSONAL_LINK_EVENT_FORM}
          selectedGuests={this.determineExistingAttendeeList()}
          // onTab={this.goToDescription}
          className={`select-attendee-create-event ${this.state.isInvalidAddress
            ? "warning-background-color-important create-select-error"
            : ""
            }`}
          id={ATTENDEE}
          setValidAddressWarning={this.setValidAddressWarning}
          includeEmailAndName={true}
        />
      </div>
    );
  }

  renderSelectedAttendees() {
    let attendees = this.state.attendees || [];

    return (
      <div className="create-event-attendee-section">
        {attendees.map((a, index) => {
          return (
            <div
              className="create-event-attendee-block display-flex-flex-direction-row"
              style={{ marginBottom: 5 }}
              key={`create-event-attendee-${index}`}
            >
              {truncateString(a.name || a.email, 28)}

              <div
                className="close-button-wrapper"
                onClick={() => this.removeAttendee(a.email)}
              >
                <X size={14} className="clickable-icon" />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderWhere() {
    return (
      <div className="display-flex-flex-direction-row align-items-center create-event-field-margin-top">
        <div className="create-event-field-label" style={{ marginRight: 2 }}>
          Where
        </div>

        <LocationSearchInput
          width={250}
          focusRef={(input) => {
            this.location = input;
          }}
          inputTabIndex={7}
          onChange={this.setLocation}
          defaultLocation={this.state.location}
          customClassName="create-event-input-field"
          hidePlaceHolder={true}
          suggestionMarginLeft={-50}
          onPressEscape={this.onKeyDownEscape}
        />
      </div>
    );
  }

  renderConferencing() {
    const { isDarkMode } = this.props;
    return (
      <div
        className="display-flex-flex-direction-row align-items-center create-event-field-margin-top"
        style={{ marginTop: 20 }}
      >
        <div className="create-event-field-label">How</div>

        <CustomSelect
          tabIndex={6}
          isSearchable={false}
          ref={"select-conferencing"}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
          })}
          openMenuOnFocus={true}
          tabSelectsValue={false}
          className={classNames(
            "select-conference-create-event",
            isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          value={this.state.conference}
          onChange={this.setConference}
          classNamePrefix="dark-mode"
          options={this.state.conferencingOptions}
          autoFocus={false}
          onKeyDown={(event) =>
            this.onKeyDownConferencing(event, "select-conferencing")
          }
        />
      </div>
    );
  }

  shouldPinCopyButton() {
    let innerHeight = window.innerHeight;
    return innerHeight <= 650;
  }

  renderSendEmail() {
    return (
      this.state.attendees &&
      this.state.attendees.length > 0 && (
        <div className="display-flex-flex-direction-row margin-top-15">
          <Checkbox
            isChecked={this.state.shouldSendEmail}
            onChange={this.toggleSendEmail}
          />

          <span className="default-font-size padding-left-5">
            Send invitation email to guests
          </span>
        </div>
      )
    );
  }

  renderDescription() {
    return (
      <div className="create-event-field-margin-top" style={{ marginTop: 30 }}>
        <div className="create-event-field-label">Description</div>

        <textarea
          className="create-event-input-field-description mt-5"
          tabIndex={8}
          ref={(input) => {
            this.description = input;
          }}
          value={this.state.description}
          style={{
            resize: "none",
            height: this.determineTextAreaHeight(),
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 3,
          }}
          onChange={this.onChangeDescription}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          autoFocus={false}
          onKeyDown={this.onKeyDownDescription}
        />

        <div className="note font-size-12-important secondary-text-color">
          * Reschedule and cancellation links will automatically be added at the
          bottom.
        </div>
      </div>
    );
  }

  renderTabGuardRail() {
    return <div tabIndex={9} onFocusCapture={this.focusTitle}></div>;
  }

  setSummary(text) {
    this.setState({ summary: text });
  }

  nlpFocus() {
    this.summary && this.summary.focus();
  }

  summaryFocus() {
    this.summary && this.summary.focus();
  }

  setConference(option) {
    this.setState({ conference: option, hasSetConference: true });
  }

  determineExistingAttendeeList() {
    let combinedAttendees = this.state.attendees || [];

    let combinedAttendeeEmailsList = combinedAttendees.map((a) => a.email);

    return combinedAttendeeEmailsList;
  }

  onKeyDownDescription(e) {
    if (e.keyCode === KEYCODE_ESCAPE) {
      this.onKeyDownEscape();
    }
  }

  onChangeTitle(e) {
    const summary = getInputStringFromEvent(e);
    if (summary.length === 1 && !this.state.hasCapitalizedTitle) {
      this.setState({
        summary: capitalizeFirstLetter(summary),
        hasCapitalizedTitle: true,
      });

      return;
    }

    this.setState({ summary });
  }

  onChangeTimeText(text) {
    this.setState({ dateTimeText: text, updatedTextWithoutSelecting: true });
  }

  onChangeDescription(e) {
    this.setState({ description: getInputStringFromEvent(e) });
  }

  focusDescription() {
    this.description && this.description.focus();
  }

  onChangeAttendees(text) {
    this.setState({ attendeesText: text });
  }

  determineTextAreaHeight() {
    if (!this.state.description || this.state.description.length < 20) {
      return 20;
    }

    let textLength = this.state.description.length;

    let height = Math.ceil(textLength / 45) * 18;

    return height < 20 ? 20 : height;
  }

  onChangeWhere(e) {
    this.setState({ location: getInputStringFromEvent(e) });
  }

  setValidAddressWarning(isInvalid) {
    if (isInvalid !== this.state.isInvalidAddress) {
      this.setState({ isInvalidAddress: isInvalid });
    }
  }

  setLocation(text) {
    this.setState({ location: text });
  }

  setTimeText(suggestion) {
    if (!suggestion || !suggestion.value) {
      return;
    }
    let { eventStart, eventEnd, dateTimeText } = createEventTextFromSuggestion(
      suggestion,
      this.props.format24HourTime
    );

    this.setState({
      dateTimeText,
      eventStart,
      eventEnd,
      timeHasError: false,
      updatedTextWithoutSelecting: false,
      eventTimeZone: this.props.currentTimeZone,
    });
  }

  setDuration(duration) {
    this.setState({ duration });
  }

  onClickEscape() {
    batch(() => {
      this.props.setActionMode(null);
      this.props.setTemporaryEvent(null);
    });
  }

  goToAttendeeList() {
    this.addAttendeeRef &&
      this.addAttendeeRef.current &&
      this.addAttendeeRef.current.focus();
  }

  updateContacts(contacts) {
    this.setState({ updatedContacts: contacts });
  }

  createFromSelectedSlot(slots) {
    if (isEmptyObjectOrFalsey(slots)) {
      let startTime = RoundToClosestMinute(moment(), 30, null, true);
      let endTime = startTime.clone().add(30, "minutes");

      return {
        eventStart: null,
        eventEnd: null,
        dateTimeText: "",
        initialStartTime: startTime,
        initialStartDate: startTime,
        initialEndTime: endTime,
        initialEndDate: endTime,
      };
    }

    let eventStart = moment(slots.start).startOf("minute");
    let eventEnd = moment(slots.end).startOf("minute");

    let dateTimeText = createEventText(
      eventStart,
      eventEnd,
      this.props.format24HourTime
    );

    // initial time and date

    return {
      eventStart,
      eventEnd,
      dateTimeText,
      initialStartTime: eventStart,
      initialStartDate: eventStart,
      initialEndTime: eventEnd,
      initialEndDate: eventEnd,
    };
  }

  removeAttendee(email) {
    let updatedAttendees = this.state.attendees;
    let loweredCaseEmail = email.toLowerCase();

    updatedAttendees = updatedAttendees.filter(
      (a) => a.email.toLowerCase() !== loweredCaseEmail
    );

    this.setState({ attendees: updatedAttendees });
  }

  removeArrayOfAttendees(removedList) {
    let newAttendeesList = this.state.attendees;

    newAttendeesList = newAttendeesList.filter(
      (a) => !removedList.includes(a.email)
    );

    let newState = { attendees: newAttendeesList };

    // remove self as an attendee if everyone else has been removed
    if (
      newAttendeesList &&
      newAttendeesList.length === 1 &&
      newAttendeesList[0].self
    ) {
      newState.attendees = [];
      newState.conference = noConferenceString;
    }

    this.setState(newState);
  }

  onNlpDateAndTimeChange(
    startTime,
    startDate,
    endTime,
    endDate,
    isStart = true
  ) {
    let momentStartTime = moment(startTime);
    let momentStartDate = moment(startDate);
    let momentEndTime = moment(endTime);
    let momentEndDate = moment(endDate);

    let eventStart = moment().startOf("minute");
    let isEventStartTimeValid = momentStartTime.isValid();
    let isStartDateValid = momentStartDate.isValid();

    eventStart.set({
      minute: isEventStartTimeValid
        ? momentStartTime.get("minute")
        : this.state.initialStartTime.get("minutes"),
      hour: isEventStartTimeValid
        ? momentStartTime.get("hour")
        : this.state.initialStartTime.get("hour"),
      date: isStartDateValid
        ? momentStartDate.get("date")
        : this.state.initialStartDate.get("date"),
      month: isStartDateValid
        ? momentStartDate.get("month")
        : this.state.initialStartDate.get("month"),
      year: isStartDateValid
        ? momentStartDate.get("year")
        : this.state.initialStartDate.get("year"),
    });

    let eventEnd = moment().startOf("minute");

    let isEventEndTimeValid = momentEndTime.isValid();
    let isEventEndDateValid = momentEndDate.isValid();
    let endTimeIfNotValid = eventStart.clone().add(30, "minutes");

    eventEnd.set({
      minute: isEventEndTimeValid
        ? momentEndTime.get("minute")
        : endTimeIfNotValid.get("minutes"),
      hour: isEventEndTimeValid
        ? momentEndTime.get("hour")
        : endTimeIfNotValid.get("hour"),
      date: isEventEndDateValid
        ? momentEndDate.get("date")
        : endTimeIfNotValid.get("date"),
      month: isEventEndDateValid
        ? momentEndDate.get("month")
        : endTimeIfNotValid.get("month"),
      year: isEventEndDateValid
        ? momentEndDate.get("year")
        : endTimeIfNotValid.get("year"),
    });

    if (eventStart.isAfter(eventEnd, "day")) {
      eventEnd.set({
        date: eventStart.get("date"),
        month: eventStart.get("month"),
        year: eventStart.get("year"),
      });
    }

    if (eventStart.isAfter(eventEnd, "minute")) {
      eventEnd = eventStart.clone().add(30, "minutes");
    }

    let dateTimeText = createEventText(
      eventStart,
      eventEnd,
      this.props.format24HourTime
    );

    this.setState({
      eventStart,
      eventEnd,
      dateTimeText,
      startTime,
      startDate,
      endTime,
      endDate,
    });
  }

  addAttendees(attendee, shouldRefocusAttendeeInput = true) {
    if (isEmptyObjectOrFalsey(attendee)) {
      return;
    }

    if (attendee.emailArray) {
      let updatedAttendees = this.processListOfEmailsForAttendees(
        attendee.emailArray
      );
      if (!updatedAttendees) {
        return;
      }

      this.setAttendees(updatedAttendees);
      return;
    }

    // Refocus attendee
    shouldRefocusAttendeeInput && this.goToAttendeeList();

    if (!attendee.__isNew__) {
      let updatedAttendees = this.state.attendees;
      let updatedAttendeesEmails = updatedAttendees.map((a) => a.email);

      if (updatedAttendeesEmails.includes(attendee.email)) {
        // check if already exist
        return;
      }

      let formattedAttendee = attendee;
      formattedAttendee.email = attendee.value;
      updatedAttendees = updatedAttendees.concat(formattedAttendee);

      this.setAttendees(updatedAttendees);
      return;
    }

    let newAttendee = attendee.value;

    // Refocus attendee
    shouldRefocusAttendeeInput && this.goToAttendeeList();

    let attendeeEmailWithoutWhiteSpaces = newAttendee ? newAttendee.trim() : "";

    if (attendeeEmailWithoutWhiteSpaces.length === 0) {
      return;
    }

    let attendeesStringFiltered = attendeeEmailWithoutWhiteSpaces
      .replace(/,/g, " ")
      .replace(/\t/g, " ")
      .replace(/</g, " ")
      .replace(/>/g, " ");

    // Split out so we know if something is pasted in or manually entered
    let attendeesArray = attendeesStringFiltered.split(" ");

    if (attendeesArray.length > 1) {
      // Enter in string separated by white space
      let updatedAttendees =
        this.processListOfEmailsForAttendees(attendeesArray);

      if (!updatedAttendees) {
        return;
      }

      this.setAttendees(updatedAttendees);
    } else if (!isValidEmail(attendeeEmailWithoutWhiteSpaces)) {
      this.setValidAddressWarning(true);
    } else {
      let updatedAttendees = this.state.attendees;
      updatedAttendees = updatedAttendees.concat({
        name: "",
        email: attendeeEmailWithoutWhiteSpaces,
      });

      this.setAttendees(updatedAttendees);
    }
  }

  setAttendees(updatedAttendees) {
    this.setState({ attendees: updatedAttendees });
  }

  cleanUpString(str) {
    // replace empty string and trim
    if (!str) {
      return "";
    }

    return str.replace(new RegExp(String.fromCharCode(160), "gmi"), " ").trim();
  }

  saveAvailabilityEventDetail() {
    let debounceTime = 0;
    if (
      this.state.attendeesText &&
      this.state.attendeesText.trim().length > 0
    ) {
      if (!isValidEmail(this.state.attendeesText.trim())) {
        this.setState({ isInvalidAddress: true });

        return;
      } else {
        debounceTime = 500;
        this.addAttendees(this.state.attendeesText, false);
      }
    }

    this._saveTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      let attendees = this.state.attendees.map((a) => a.email);
      let element = document.getElementById(TITLE_INPUT);

      if (!element) {
        return;
      }

      let summary = this.cleanUpString(
        element.innerText.replace(/Invitee_name/gim, INVITEE_NAME_BLOCK)
      );

      let availability_link = {
        title: summary,
        location: this.state.location,
        attendees,
        description: this.state.description,
        duration: this.state.duration,
        conferencing: this.state.conference,
      };

      this.props.onClickSave && this.props.onClickSave(availability_link);
      element = null;
    }, debounceTime);
  }

  processListOfEmailsForAttendees(attendeesArray) {
    let currentAttendees = this.state.attendees
      ? this.state.attendees.map((a) => a.email.trim().toLowerCase())
      : [];

    let existingAttendees = [].concat(currentAttendees);

    let filterForEmail = attendeesArray.filter(
      (e) =>
        isValidEmail(e) &&
        e !== getUserEmail(this.getUser()) &&
        !existingAttendees.includes(e.toLowerCase())
    );

    if (filterForEmail.length === 0) {
      return null;
    }

    filterForEmail = removeDuplicatesFromArray(filterForEmail);

    let newlyAddedAttendees = this.state.attendees || [];
    filterForEmail.forEach((e) => {
      newlyAddedAttendees = newlyAddedAttendees.concat({
        email: e,
        responseStatus: "needsAction",
      });
    });

    return newlyAddedAttendees;
  }

  handleWindowSizeChange() {
    if (this.windowSizeChangeTimer) {
      clearTimeout(this.windowSizeChangeTimer);

      this.windowSizeChangeTimer = null;
    }

    this.windowSizeChangeTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      let innerHeight = window.innerHeight;

      this.setState({
        innerHeight: innerHeight,
        shouldPinCopyButton: this.shouldPinCopyButton(),
      });
    }, 500);
  }

  toggleSendEmail() {
    let shouldSendEmail = !this.state.shouldSendEmail;

    this.setState({ shouldSendEmail });
  }

  onKeyDownEscape() {
    blurCalendar();
  }

  focusTitle() {
    this.contentEditableTitle &&
      this.contentEditableTitle.current &&
      this.contentEditableTitle.current.focus();
  }

  onKeyDownConferencing(event, ref) {
    // ESC key
    if (event && event.keyCode === KEYCODE_ESCAPE) {
      this.refs[ref] && this.refs[ref].blur();
    }
  }

  getUser() {
    return this.props.user ?? this.props.currentUser;
  }
}

function mapStateToProps(state) {
  let {
    currentUser,
    currentTimeZone,
    popupEvent,
    currentTimeZoneLabel,
    isDarkMode,
    format24HourTime,
  } = state;

  return {
    currentUser,
    currentTimeZone,
    popupEvent,
    currentTimeZoneLabel,
    isDarkMode,
    format24HourTime,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTemporaryEvent: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const zoomSchedulers = useZoomSchedulers();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent 
      {...props} 
      allCalendars={allCalendars} 
      zoomSchedulers={zoomSchedulers} 
      masterAccount={masterAccount}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStore(PersonalLinkEventForm));
