import React, { useState } from "react";
import { X, User, UserCheck, ChevronDown } from "react-feather";
import { useSelector } from "react-redux";
import ProfilePictureWithStatus from "./profilePictureWithStatus";
import CopiableContentContainer from "../copiableContentContainer";
import {
  convertEmailToName,
} from "../../services/commonUsefulFunctions";
import ContactHandles from "./contactHandles";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import classNames from "classnames";
import {
  getEnrichedAttendeeSocialLinks,
  getEnrichedContactProfilePhoto,
} from "../../lib/enrichedContactFunctions";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import {
  isEventAttendeeOrganizer,
  isEventAttendeeOptional,
} from "../../lib/eventFunctions";
import { isSameEmail } from "../../lib/stringFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { getEventAttendeeComment } from "../../services/eventResourceAccessors";
import { useDistroListDictionary } from "../../services/stores/eventsData";
import { getDistroListGroupMembersAndSubgroups, isDistroListEmail, isDistroListTooBigForExpansion } from "../../lib/distroListFunctions";
import broadcast from "../../broadcasts/broadcast";
import { useUserTimeZoneIndexStore } from "../../services/stores/userData";
import UserLocalTimeZone from "../userLocalTimeZone";
import { DexieDistroListMember } from "../../services/db";

interface EnrichedContactProps {
  attendee: EnrichedAttendee
  attendees: EnrichedAttendee[]
  editMode: boolean
  index: number
  makeAttendeeOptional: (attendee: EnrichedAttendee) => void
  removeAttendee: (attendee: EnrichedAttendee) => void
  renderWithCopy: boolean
  showColorBorder: boolean
  isInEventForm: boolean
  organizer: VimcalAttendee
}

const EnrichedContact = ({
  attendee,
  attendees,
  editMode,
  index,
  makeAttendeeOptional,
  removeAttendee,
  renderWithCopy = false,
  showColorBorder,
  isInEventForm,
  organizer,
}: EnrichedContactProps) => {
  const currentUser = useSelector(state => state.currentUser);
  const emailToNameIndex = useSelector(state => state.emailToNameIndex);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const distroListDictionary = useDistroListDictionary(
    (state) => state.distroListDictionary,
  );
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const [isHovering, setIsHovering] = useState(false);
  const [isExpandedGroup, setIsExpandedGroup] = useState(false);
  const userTimeZoneIndex = useUserTimeZoneIndexStore(
    (state) => state.userTimeZoneIndex,
  );

  const attendeeEmail = getObjectEmail(attendee) ?? "";

  const isDistroListAttendee = () => {
    return isDistroListEmail({
      email: attendeeEmail,
      distroListDictionary,
    });
  };

  const determineAttendeeMaxWidth = () => {
    if (isDistroListAttendee()) {
      if (isEventAttendeeOptional(attendee)) {
        return 134;
      }
      return 180;
    }

    if (
      isEventAttendeeOptional(attendee) &&
      isEventAttendeeOrganizer(attendee)
    ) {
      return 108;
    }

    if (isEventAttendeeOrganizer(attendee)) {
      return 160;
    }
    if (isEventAttendeeOptional(attendee)) {
      return 180;
    }

    return 200;
  };

  const markFlagOptionStyle = (index) => {
    if (attendees.length === 1) {
      return { top: -5, left: -100 };
    } else if (index === attendees.length - 1) {
      return { top: -30 };
    } else {
      return {};
    }
  };

  const shouldDisplayHoverHint = () => {
    if (!attendeeEmail) {
      return false;
    }
    if (attendees?.length === 1) {
      // no point in rendering name if there's only 1 person. Plus there's not enough room
      return null;
    }
    return (
      (emailToNameIndex &&
        (emailToNameIndex[attendeeEmail] ||
          isSameEmail(attendeeEmail, getUserEmail(currentUser)))) ||
      attendee.displayName
    );
  };

  const hasAttendeeLocalTime = () => {
    if (!attendeeEmail) {
      return false;
    }
    return !!userTimeZoneIndex[attendeeEmail];
  };

  // TODO VIM-3301: Investigate removing maxWidth.
  const getMaxWidthForDisplayName = () => {
    if (hasAttendeeLocalTime()) {
      if (isEventAttendeeOptional(attendee)) {
        if (editMode) {
          return determineAttendeeMaxWidth() - 74;
        }
        return determineAttendeeMaxWidth() - 34;
      }
      if (editMode) {
        return determineAttendeeMaxWidth() - 70;
      }
      return determineAttendeeMaxWidth() - 50;
    }
    return determineAttendeeMaxWidth();
  };

  const EmailContainer = () => (
    <div className="event-attendee-email-container flex items-center">
      <span
        className={classNames(
          "enrich-contact-name-email-hover-container",
          "inline-block",
          "inline-block overflow-inherit vertical-align-top",
        )}
      >
        <span
          className={classNames("event-attendee-email-container-email")}
          style={{
            maxWidth: getMaxWidthForDisplayName(),
          }}
        >
          {attendee.displayName
            ? attendee.displayName
            : convertEmailToName({
              email: attendeeEmail,
              currentUser,
              emailToNameIndex,
              masterAccount,
              allLoggedInUsers,
              attendee,
              distroListDictionary,
            })}
        </span>

        {renderHoverAttendeeName()}
      </span>
      {isEventAttendeeOrganizer(attendee) ||
      isSameEmail(attendeeEmail, getObjectEmail(organizer)) ? (
          <span className="attendee-organizer-text">(organizer)</span>
        ) : null}
      {isEventAttendeeOptional(attendee) ? (
        <span
          className={classNames(
            "attendee-optional-text",
            isDistroListAttendee() ? "vertical-align-top" : "",
          )}
        >
          Optional
        </span>
      ) : null}
      {editMode ? <UserLocalTimeZone email={attendeeEmail} className={"ml-1.5"}/> : null}
      {renderDistroListInfo()}
    </div>
  );

  const getMatchingDistroListMembers = () => {
    return getDistroListGroupMembersAndSubgroups({
      email: attendeeEmail,
      distroListDictionary,
    });
  };

  const renderDistroListInfo = () => {
    if (!isDistroListAttendee()) {
      return null;
    }
    const distroListMembers = getMatchingDistroListMembers();
    return (
      <>
        <span className="event-attendee-email-container-email ml-1.5">{`(${
          distroListMembers.length
        })`}</span>
        {isDistroListTooBigForExpansion({
          email: attendeeEmail,
          distroListDictionary,
        }) || isEmptyArrayOrFalsey(distroListMembers) ? null : (
            <ChevronDown
              size={16}
              className={classNames(
                "clickable-icon",
                "ml-1 duration-200",
                isExpandedGroup ? "rotate-180-degrees" : "",
              )}
              onClick={() => {
                setIsExpandedGroup(!isExpandedGroup);
                const membersEmails = distroListMembers
                  .map((member) => getObjectEmail(member))
                  .filter((email) => email);
                broadcast.publish("SEARCH_CONTACTS_DOMAIN_DB", membersEmails);
              }}
            />
          )}
      </>
    );
  };

  const renderDistroListGroupMembers = () => {
    if (!isDistroListAttendee() || !isExpandedGroup) {
      return null;
    }
    const groupMembers = getMatchingDistroListMembers();
    if (isEmptyArrayOrFalsey(groupMembers)) {
      return null;
    }
    const renderHoverHintForMember = (member: DexieDistroListMember) => {
      return (
        <div
          className={classNames(
            "expanded-event-email-suggestion-container",
            "enrich-contact-hover-container",
            "pointer-events-none",
          )}
        >
          <div
            className={classNames(
              "expanded-event-email-suggestion left-0px-override",
              isInEventForm ? "darker-hover-hint-override" : "",
              "transition-0s-important",
            )}
          >
            <div>{getObjectEmail(member)}</div>
          </div>
        </div>
      );
    };

    const sortedMembers = groupMembers.sort((a, b) => {
      const aEmail = getObjectEmail(a) ?? "";
      const bEmail = getObjectEmail(b) ?? "";
      const aDisplayText = emailToNameIndex?.[aEmail] || aEmail;
      const bDisplayText = emailToNameIndex?.[bEmail] || bEmail;
      return aDisplayText.localeCompare(bDisplayText);
    });
    return (
      <div
        className={classNames(
          "gap-1 flex flex-col mb-1.5",
          editMode ? "ml-11 mt-0.5" : "ml-10",
        )}
      >
        {sortedMembers.map((member) => {
          const memberEmail = getObjectEmail(member) ?? "";
          const displayText = member.displayName || emailToNameIndex?.[memberEmail] || memberEmail;
          const isSubgroup = isDistroListEmail({ email: memberEmail, distroListDictionary });
          const subgroupMemberCount = isSubgroup
            ? getDistroListGroupMembersAndSubgroups({ email: memberEmail, distroListDictionary }).length
            : 0;

          return (
            <div
              key={`attendee-distro-list-${memberEmail}-${displayText}`}
              className="enrich-contact-name-email-hover-container"
            >
              {displayText}
              {subgroupMemberCount > 0 ? ` (${subgroupMemberCount})` : ""}
              {displayText === memberEmail
                ? null
                : renderHoverHintForMember(member)}
            </div>
          );
        })}
      </div>
    );
  };

  const getProfilePictureMarginTop = () => {
    if (hasSocialLinks() && hasGoogleRSVPComments()) {
      return "mt-1.5";
    }
    if (hasGoogleRSVPComments()) {
      return "mt-0.5";
    }
    return "";
  };

  const hasSocialLinks = () => {
    return getEnrichedAttendeeSocialLinks(attendee).length > 0;
  };
  const hasGoogleRSVPComments = () => {
    return !!getEventAttendeeComment(attendee);
  };

  const renderHoverAttendeeName = () => {
    if (!shouldDisplayHoverHint()) {
      return null;
    }
    return (
      <div
        className={classNames(
          "expanded-event-email-suggestion-container",
          "enrich-contact-hover-container",
          "pointer-events-none",
        )}
      >
        <div
          className={classNames(
            "expanded-event-email-suggestion left-0px-override",
            isInEventForm ? "darker-hover-hint-override" : "",
          )}
        >
          <div>{attendeeEmail}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          editMode ? "full-attendee-list-attendee rounded-md" : "",
          "pt-1 pb-1.5",
        )}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: hasGoogleRSVPComments() ? "start" : "center",
          justifyContent: "flex-start",
          maxWidth: editMode ? 325 : 320,
          paddingLeft: editMode || isInEventForm ? 5 : 0,
        }}
      >
        <div
          className={classNames(
            "event-attendee-profile-picture",
            getProfilePictureMarginTop(),
          )}
        >
          <ProfilePictureWithStatus
            attendee={attendee}
            showColorBorder={
              !getEnrichedContactProfilePhoto(attendee) && showColorBorder
            }
          />
        </div>

        <div
          className={classNames(
            "event-attendee-profile-and-name attendee-list-attendee-container relative",
          )}
        >
          <div
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            {renderWithCopy ? (
              <CopiableContentContainer
                iconHeight={14}
                iconMarginLeft={5}
                isParentHovering={isHovering}
                textToCopy={attendeeEmail}
              >
                <EmailContainer />
              </CopiableContentContainer>
            ) : (
              <EmailContainer />
            )}
          </div>
          <ContactHandles
            attendee={attendee}
            isLastContact={attendees && attendees.length - 1 === index}
          />
          {getEventAttendeeComment(attendee) ? (
            <div
              className={classNames(
                "default-font-size secondary-text-color",
                "break-words",
                isInEventForm ? "w-52" : "",
              )}
            >
              {getEventAttendeeComment(attendee)}
            </div>
          ) : null}
        </div>

        {editMode ? (
          <div className={classNames("display-flex-center ml-auto")}>
            <div
              className={classNames(
                "clickable-icon",
                "full-attendee-icon",
                "mr-2",
              )}
              onClick={() => makeAttendeeOptional(attendee)}
            >
              {isEventAttendeeOptional(attendee) ? (
                <UserCheck size={15} />
              ) : (
                <User size={15} />
              )}

              <div
                className="mark-optional-flag select-none"
                style={markFlagOptionStyle(index)}
              >
                Mark{" "}
                {isEventAttendeeOptional(attendee) ? "required" : "optional"}
              </div>
            </div>
          </div>
        ) : null}

        {editMode ? (
          <div
            className={classNames("clickable-icon", "full-attendee-icon")}
            onClick={() => removeAttendee(attendee)}
            style={{ marginRight: 5 }}
          >
            <X size={15} />
          </div>
        ) : null}
      </div>
      {renderDistroListGroupMembers()}
    </>
  );
};

export default EnrichedContact;
