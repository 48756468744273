import React, { useEffect, useState } from "react";
import { ONBOARD_STEP, trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useSelector } from "react-redux";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import SaveAndSkipButtons from "./saveAndSkipButtons";
import ShareLink from "../teamPlans/teamPlansModal/shareLink";
import NewMemberInput from "../teamPlans/newMemberInput";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import classNames from "classnames";
import { inviteTeamMembers } from "../queries/teamPlans";
import { EmailPlanDictionary, groupEmailPlanDictionaryBySeat } from "../teamPlans/teamPlansModal/sharedFunctions";
import { useHasBillingBeenFetched } from "../../services/stores/finance";
import { isUserMaestroUser } from "../../services/maestroFunctions";

interface AddTeamMembersProps {
  onClickNext: () => void
}

/**
 * The onboarding step that allows the user to invite members to join their trial subscription.
 */
export default function AddTeamMembers({ onClickNext }: AddTeamMembersProps) {
  const currentUser = useSelector(state => state.currentUser);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [shareToken, setShareToken] = useState<string | null>(null);
  const [emailPlanDictionary, setEmailPlanDictionary] = useState<EmailPlanDictionary>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingEligibility, setIsFetchingEligibility] = useState(false);
  const resetBillingHasBeenFetched = useHasBillingBeenFetched(state => state.resetBillingHasBeenFetched);

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: ONBOARD_STEP.ADD_TEAM_MEMBERS,
    });
  }, []);

  const onClickSave = async () => {
    if (!currentUser) {
      return;
    }

    setIsSubmitting(true);
    setErrorMessage(undefined);

    const { vimcalEmails, vimcalEAEmails } = groupEmailPlanDictionaryBySeat(emailPlanDictionary);
    const teamPlan = await inviteTeamMembers(currentUser, { vimcalEmails, vimcalEAEmails });
    if (teamPlan) {
      // Reset this so billing gets refetched after entering the main app.
      resetBillingHasBeenFetched();
      onClickNext();
    } else {
      setIsSubmitting(false);
      setErrorMessage("There was an error creating your team plan.");
    }
  };

  const isEmailPlanDictionaryEmpty = isEmptyObjectOrFalsey(emailPlanDictionary);

  return (
    <div className="onboarding-container mx-auto py-20" style={{ maxWidth: 598 }}>
      <div className="flex flex-col flex-grow justify-center items-center" style={{ maxHeight: 700 }}>
        <div className="section-title text-center">
          Who else is on your team?
        </div>
        <div className="welcome-subtitle mt-3 mb-12">
          Vimcal is better with your team
        </div>

        {errorMessage ? (
          <div className="default-font-size mb-6" style={{ color: "#FF7373" }}>
            {errorMessage}
          </div>
        ) : null}

        <div className={classNames("self-stretch flex flex-col", isEmailPlanDictionaryEmpty ? "" : "flex-grow")}>
          <div className="welcome-heading mb-3 default-font-size">
            Email Invite
          </div>
          <NewMemberInput
            // Since this is the onboarding flow, the current user is the only member.
            areEAsPresent={isUserMaestroUser(masterAccount)}
            emailPlanDictionary={emailPlanDictionary}
            setEmailPlanDictionary={setEmailPlanDictionary}
            setIsFetchingEligibility={setIsFetchingEligibility}
          />
        </div>

        {isEmailPlanDictionaryEmpty ? <div className="flex-grow"></div> : null}

        <div className="self-start default-font-size">
          <div className="welcome-heading -mb-1">
            Share invite
          </div>
          <ShareLink
            shareToken={shareToken}
            setShareToken={setShareToken}
            shouldHideShareText
          />
          <div className="team-plan-link-help-text mt-2 secondary-text-color">
            Note that when your trial ends, you will be charged for anyone who joins your team from this link.
            The link expires in 14 days.
          </div>
        </div>

        <SaveAndSkipButtons
          hideDefaultIcon
          isDisabled={(!shareToken && isEmailPlanDictionaryEmpty) || isSubmitting || isFetchingEligibility}
          onClickNext={onClickNext}
          onClickSave={onClickSave}
        />
      </div>
    </div>
  );
}
