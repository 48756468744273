import React from "react";
import { useSelector } from "react-redux";
import { getDefaultFontColor } from "../../lib/styleFunctions";

const VimcalErrorLogo = () => {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const color = getDefaultFontColor(isDarkMode);

  return (
    <svg
      width="48"
      height="45"
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          d="M2.67487 8.95105C-0.0949723 11.9025 -1.05244 16.0356 1.439 19.2099C3.17937 21.4272 5.94531 22.3656 8.5367 21.8806C19.0725 11.6617 29.197 8.34301 36.935 7.69123C38.1315 7.59045 39.2697 7.55352 40.3424 7.56445C42.463 5.31941 44.8505 3.31583 47.4641 1.60795L47.4832 1.56005C47.4832 1.56005 36.1934 -1.07009 25.2035 0.499757C15.6075 1.8705 6.14082 5.25818 2.67487 8.95105Z"
          fill={color}
        />
        <path
          d="M10.4618 30.7046C8.38898 28.0638 8.42366 24.4408 10.3217 21.863C20.3167 12.5371 29.8103 9.51341 37.0372 8.90469L38.93 9.14176C35.3946 13.3078 32.7252 18.1922 31.1476 23.4936C29.0671 24.0601 27.0129 25.0974 25.0607 26.4583C22.4918 28.2491 20.0659 30.622 17.9378 33.2942C15.2408 33.949 12.2873 33.0302 10.4618 30.7046Z"
          fill={color}
        />
        <path
          d="M18.8246 34.1357C18.6768 34.4017 18.5441 34.68 18.4278 34.9697C16.9248 38.7146 18.7419 42.969 22.4867 44.4721C23.726 44.9696 25.0206 45.1035 26.2533 44.9241C27.4869 44.7511 28.6928 44.26 29.7433 43.4355C29.9622 43.2637 30.1687 43.0818 30.3625 42.8911C30.1884 42.0575 30.0398 41.2128 29.9176 40.3578C29.1558 35.0252 29.4949 29.7893 30.7624 24.88C29.0848 25.4297 27.4021 26.3105 25.7571 27.4573C23.2711 29.1903 20.9051 31.5091 18.8246 34.1357Z"
          fill={color}
        />
        <path
          d="M37.0372 8.90469C37.7943 8.84092 38.5271 8.80361 39.2334 8.7884C39.1316 8.9056 39.0304 9.0234 38.93 9.14176L37.0372 8.90469Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default VimcalErrorLogo;
