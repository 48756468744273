import { CALENDAR_CATEGORIES_ENDPOINT } from "../../lib/endpoints";
import { getUserEmail } from "../../lib/userFunctions";
import { constructRequestURL } from "../../services/api";
import { handleError } from "../../services/commonUsefulFunctions";
import Fetcher from "../../services/fetcher";
import { OutlookCategory } from "../../services/stores/outlookCategoriesStore";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";

export async function fetchOutlookCategories(user: User): Promise<Record<string, OutlookCategory[]>> {
  const url = constructRequestURL(CALENDAR_CATEGORIES_ENDPOINT, true);
  try {
    const response = await Fetcher.get<Record<string, OutlookCategory[]>>(
      url,
      {},
      true,
      getUserEmail(user),
      (error) => { throw error; },
    );

    if (isEmptyObjectOrFalsey(response)) {
      handleError("Unexpected response when fetching Outlook categories");
      return {};
    }

    return response;
  } catch (e) {
    handleError(e);
    return {};
  }
}
