import React from "react";
import { getDefaultFontColor } from "../../lib/styleFunctions";

export default function AddPeople({ isDarkMode }) {
  const color = getDefaultFontColor(isDarkMode);

  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      display="block"
    >
      <path
        d="M17.0643 12.613V14.0968H6.67725V12.613C6.67725 12.613 6.67725 9.64523 11.8708 9.64523C17.0643 9.64523 17.0643 12.613 17.0643 12.613ZM14.4676 5.56459C14.4676 5.05099 14.3153 4.54893 14.0299 4.1219C13.7446 3.69486 13.339 3.36202 12.8645 3.16548C12.39 2.96894 11.8679 2.91751 11.3642 3.01771C10.8605 3.11791 10.3978 3.36522 10.0346 3.72839C9.67143 4.09156 9.42411 4.55426 9.32392 5.05798C9.22372 5.56171 9.27514 6.08383 9.47169 6.55833C9.66823 7.03283 10.0011 7.43839 10.4281 7.72373C10.8551 8.00906 11.3572 8.16136 11.8708 8.16136C12.5595 8.16136 13.22 7.88777 13.707 7.40078C14.194 6.91379 14.4676 6.25329 14.4676 5.56459ZM17.0198 9.64523C17.4759 9.99821 17.8491 10.4468 18.1132 10.9596C18.3773 11.4723 18.5257 12.0367 18.5482 12.613V14.0968H21.516V12.613C21.516 12.613 21.516 9.91975 17.0198 9.64523ZM16.3224 2.96781C15.8117 2.96497 15.3123 3.11765 14.8905 3.40555C15.3412 4.03526 15.5835 4.79022 15.5835 5.56459C15.5835 6.33896 15.3412 7.09391 14.8905 7.72362C15.3123 8.01152 15.8117 8.1642 16.3224 8.16136C17.0111 8.16136 17.6716 7.88777 18.1586 7.40078C18.6456 6.91379 18.9192 6.25329 18.9192 5.56459C18.9192 4.87588 18.6456 4.21538 18.1586 3.72839C17.6716 3.2414 17.0111 2.96781 16.3224 2.96781Z"
        fill="#868B97"
      />
      <path
        d="M3.97559 10.7051V8.68531H5.99541C6.13437 8.68531 6.26764 8.63011 6.36591 8.53184C6.46417 8.43358 6.51937 8.30031 6.51937 8.16135C6.51937 8.02238 6.46417 7.88911 6.36591 7.79085C6.26764 7.69259 6.13437 7.63738 5.99541 7.63738H3.97559V5.61757C3.97559 5.4786 3.92039 5.34533 3.82213 5.24707C3.72386 5.14881 3.59059 5.0936 3.45163 5.0936C3.31267 5.0936 3.17939 5.14881 3.08113 5.24707C2.98287 5.34533 2.92767 5.4786 2.92767 5.61757V7.63738H0.90785C0.768886 7.63738 0.635614 7.69259 0.537352 7.79085C0.43909 7.88911 0.383887 8.02238 0.383887 8.16135C0.383887 8.30031 0.43909 8.43358 0.537352 8.53184C0.635614 8.63011 0.768886 8.68531 0.90785 8.68531H2.92767V10.7051C2.92767 10.8441 2.98287 10.9774 3.08113 11.0756C3.17939 11.1739 3.31267 11.2291 3.45163 11.2291C3.59059 11.2291 3.72386 11.1739 3.82213 11.0756C3.92039 10.9774 3.97559 10.8441 3.97559 10.7051Z"
        fill="#868B97"
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  );
}
