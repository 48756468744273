import React from "react";
import DisabledButton from "../../disabledButton";
import CustomButton from "../../customButton";
import { BLUE_BUTTON } from "../../../services/globalVariables";
import classNames from "classnames";

interface SaveButtonProps {
  className?: string
  disabled?: boolean
  label?: string
  loading?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function SaveButton({ className, disabled, label="Save", loading, onClick }: SaveButtonProps) {
  return (
    <div className={classNames("flex justify-end", className)}>
      {(disabled || loading) ? (
        <DisabledButton label={label} />
      ) : (
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={onClick}
          label={label}
        />
      )}
    </div>
  );
}
