import React, { Component } from "react";
import CopyIcon from "./copyIcon";
import FilledShortcutTiles from "./shortcutTiles/filledShortcutTiles";

export default class CopiableContentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
      isHoverIcon: false,
    };

    this.mouseEnterContainer = this.mouseEnterContainer.bind(this);
    this.mouseLeaveContainer = this.mouseLeaveContainer.bind(this);
    this.mouseEnterIcon = this.mouseEnterIcon.bind(this);
    this.mouseLeaveIcon = this.mouseLeaveIcon.bind(this);
  }

  render() {
    const shouldDisplay = typeof this.props.isParentHovering === "boolean" 
      ? this.props.isParentHovering 
      : this.state.isHovering;

    return (
      <span
        onMouseEnter={this.mouseEnterContainer}
        onMouseLeave={this.mouseLeaveContainer}
        style={Object.assign(
          {
            position: "relative",
            display: "flex",
            alignItems: "center",
          },
          this.props.style
        )}
      >
        {this.props.children}

        {!this.props.shouldHide && (
          <span
            style={{
              height: this.props.iconHeight,
              opacity: shouldDisplay ? 1 : 0,
              marginLeft: this.props.iconMarginLeft || 10,
              marginTop: this.props.iconMarginTop || 0,
              paddingTop: this.props.paddingTop || 0,
              position: "relative",
            }}
            onMouseEnter={this.mouseEnterIcon}
            onMouseLeave={this.mouseLeaveIcon}
          >
            <CopyIcon textToCopy={this.props.textToCopy} />

            {this.props.hotKeys ? (
              <div
                className="flex flex-row absolute"
                style={{
                  top: this.props.copiableHintRightHandeSide ? "-2px" : "-24px",
                  right: this.determineHotKeyRightLocation(),
                  opacity: this.state.isHoverIcon ? 1 : 0,
                }}
              >
                <FilledShortcutTiles shortcut={this.props.hotKeys} />
              </div>
            ) : null}
          </span>
        )}
      </span>
    );
  }

  determineHotKeyRightLocation() {
    if (this.props.copiableHintRightHandeSide) {
      return "-60px";
    } else if (this.props.hotKeyRight) {
      return this.props.hotKeyRight;
    }

    return "-12px";
  }

  mouseEnterContainer() {
    this.setState({ isHovering: true });
  }

  mouseLeaveContainer() {
    this.setState({ isHovering: false });
  }

  mouseEnterIcon() {
    if (!this.props.hotKeys) {
      return;
    }

    this.setState({ isHoverIcon: true });
  }

  mouseLeaveIcon() {
    if (!this.props.hotKeys) {
      return;
    }

    this.setState({ isHoverIcon: false });
  }
}
