import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { pluralize } from "../../lib/stringFunctions";

import {
  getOnePagerEndDate,
  getOnePagerMinsSaved90Days,
  getOnePagerProposedChangeOriginalDurationHumanReadable,
  getOnePagerProposedChangeMinsSavedOver90DaysHumanReadable,
  getOnePagerProposedChangeProposedDurationHumanReadable,
  getOnePagerProposedChangeOriginalFrequency,
  getOnePagerProposedChangeProposedFrequency,
  getOnePagerProposedChangeAttendeeEmailsString,
  getOnePagerProposedChangeEventEnd,
  getOnePagerProposedChangeEventStart,
  getOnePagerProposedChanges,
  getOnePagerProposedChangeTitle,
  getOnePagerStartDate,
  getOnePagerTitle,
  splitProposedChangesIntoChunks,
} from "./helperFunctions";
import { DATE_TIME_12_HOUR_FORMAT } from "../../services/googleCalendarService";
import { minutesToHoursRoundedOneDecimal } from "../../lib/calendarAudit/functions";
import { OnePagerData } from "../../lib/calendarAudit/accessors";
import CalendarAuditLogo from "../icons/calendarAudit/pdfLogo";

export default function OnePager({
  data,
  pageRef,
}: {
  data: OnePagerData,
  pageRef: React.MutableRefObject<HTMLDivElement | null>,
}) {
  const proposedChanges = getOnePagerProposedChanges(data);
  const splitProposedChanges = splitProposedChangesIntoChunks(proposedChanges);

  return (
    <div className="calendar-audit-export-pdf-container" ref={pageRef}>
      {/* Always render the first page */}
      <RenderPage
        data={data}
        page={1}
        proposedChanges={proposedChanges}
        splitProposedChanges={splitProposedChanges}
        threeProposedChanges={splitProposedChanges.shift()}
      />
      {
        splitProposedChanges?.map((threeProposedChanges, index) => (
          <RenderPage
            data={data}
            key={index + 2}
            page={index + 2}
            proposedChanges={proposedChanges}
            splitProposedChanges={splitProposedChanges}
            threeProposedChanges={threeProposedChanges}
          />
        ))
      }
    </div>
  );
}

function RenderFooter({ page, pages }) {
  return (
    <div className="flex justify-between w-full">
      <CalendarAuditLogo />
      <div>
        Page {page} of {pages}
      </div>
    </div>
  );
}

function RenderHeader({ data }) {
  return (
    <div className="flex items-center justify-between font-size-12">
      <div className="font-size-16 font-weight-500">{`Calendar audit for ${getOnePagerTitle(
        data,
      )}`}</div>
      <div className="flex items-center gap-2">
        <div>{format(getOnePagerStartDate(data), "MMM d, yyyy")}</div>
        <div>-</div>
        <div>{format(getOnePagerEndDate(data), "MMM d, yyyy")}</div>
      </div>
    </div>
  );
}

function RenderHighLevel({ data, proposedChanges }) {
  return (
    <div className="flex items-center gap-4 mt-6">
      <RenderHighLevelBox
        text={proposedChanges.length}
        subText={"Proposed changes"}
      />
      <RenderHighLevelBox
        text={`${minutesToHoursRoundedOneDecimal(
          getOnePagerMinsSaved90Days(data),
        )} hrs`}
        subText={"90 day time saved"}
      />
    </div>
  );
}

function RenderHighLevelBox({ text, subText }) {
  return (
    <div className="flex flex-col gap-1 p-4 rounded-xl calendar-audit-one-pager-border">
      <div className="font-weight-500 font-size-16">{text}</div>
      <div className="font-weight-400 font-size-12 light-mode-secondary-text-color">
        {subText}
      </div>
    </div>
  );
}

function RenderPage({
  data,
  page,
  proposedChanges,
  splitProposedChanges,
  threeProposedChanges,
}) {
  return (
    <div className="bg-white calendar-audit-export-pdf light-mode-default-text-color">
      <RenderHeader data={data} />
      {page === 1 ? (
        <>
          <RenderHighLevel data={data} proposedChanges={proposedChanges} />
          <RenderProposedChangesHeader proposedChanges={proposedChanges} />
        </>
      ) : null}
      <div className="flex flex-col gap-4 mb-auto mt-2">
        {threeProposedChanges?.map((proposedChange, index) => {
          return (
            <RenderProposedChangesBox
              index={index}
              key={index}
              page={page}
              proposedChange={proposedChange}
            />
          );
        })}
      </div>
      <RenderFooter page={page} pages={splitProposedChanges.length + 1} />
    </div>
  );
}

function RenderProposedChangesHeader({ proposedChanges }) {
  return (
    <div className="font-size-16 font-weight-500 mt-6">
      Proposed {pluralize(Object.keys(proposedChanges).length, "change")}
    </div>
  );
}

function RenderProposedChangesBox({ index, page, proposedChange  }) {
  const originalMins =
    getOnePagerProposedChangeOriginalDurationHumanReadable(proposedChange);
  const proposedMins =
    getOnePagerProposedChangeProposedDurationHumanReadable(proposedChange);
  const hasDurationChanged = originalMins !== proposedMins;
  const originalFrequency =
    getOnePagerProposedChangeOriginalFrequency(proposedChange);
  const proposedFrequency =
    getOnePagerProposedChangeProposedFrequency(proposedChange);
  const hasFrequencyChanged = originalFrequency !== proposedFrequency;
  const attendeeEmailsString =
    getOnePagerProposedChangeAttendeeEmailsString(proposedChange);
  const isRecurring = originalFrequency && proposedFrequency;
  return (
    <div className="calendar-audit-one-pager-border p-4 rounded-xl flex flex-col default-font-size">
      <div className="flex items-center justify-between">
        <div className="font-size-14 font-weight-500 w-4/5">
          {getOnePagerProposedChangeTitle(proposedChange)}
        </div>
        <div className="light-mode-secondary-text-color default-font-size">{`Change #${
          (((page - 1) * 3) - (page > 1 ? 1 : 0)) + (index + 1) // Adjust for first page having 2 elements
        }`}</div>
      </div>

      <div className="flex items-center gap-1.5 mt-0.5">
        <div>
          {format(
            getOnePagerProposedChangeEventStart(proposedChange),
            DATE_TIME_12_HOUR_FORMAT,
          )}
        </div>
        <div>-</div>
        <div>
          {format(
            getOnePagerProposedChangeEventEnd(proposedChange),
            DATE_TIME_12_HOUR_FORMAT,
          )}
        </div>
      </div>

      {attendeeEmailsString ? (
        <div className="mt-0.5 line-clamp-2">{attendeeEmailsString}</div>
      ) : null}

      <div className="font-weight-500 mt-4 default-font-size">
        Proposed changes
      </div>
      <div className="ml-4">
        <div className="light-mode-secondary-text-color mt-1">Duration:</div>
        <div className="flex items-center gap-1">
          <div
            className={classNames(
              "mt-0.5",
              hasDurationChanged
                ? "calendar-audit-has-changed-property-color font-weight-500"
                : "",
            )}
          >
            {!hasDurationChanged
              ? `${originalMins}`
              : `${originalMins} -> ${proposedMins}`}
          </div>
          {hasDurationChanged ? null : (
            <div className="light-mode-secondary-text-color italic">
              (No changes)
            </div>
          )}
        </div>

        {isRecurring ? (
          <>
            <div className="light-mode-secondary-text-color mt-2">
              Frequency:
            </div>
            <div className="flex items-center gap-1">
              <div
                className={classNames(
                  "mt-0.5",
                  hasFrequencyChanged
                    ? "calendar-audit-has-changed-property-color font-weight-500"
                    : "",
                )}
              >
                {!hasFrequencyChanged
                  ? `${originalFrequency ?? "Single event"}`
                  : `${originalFrequency} -> ${proposedFrequency}`}
              </div>
              {hasFrequencyChanged ? null : (
                <div className="light-mode-secondary-text-color italic">
                  (No changes)
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>

      <div className="font-weight-500 mt-4 default-font-size">
        Time saved{isRecurring ? " (Over 90 days)" : ""}
      </div>
      <div className="ml-4 mt-0.5">
        {getOnePagerProposedChangeMinsSavedOver90DaysHumanReadable(
          proposedChange,
        )}
      </div>
    </div>
  );
}
