import React from "react";
import { EmailPlanDictionary, PLAN_OPTIONS, VIMCAL_DEFAULT_PLAN } from "../sharedFunctions";
import { X } from "react-feather";
import { sendMessageToSentry } from "../../../../services/commonUsefulFunctions";
import CustomSelectV2 from "../../../select/selectV2";

interface InviteeRowProps extends SeatTypeDropdownProps {
  areEAsPresent: boolean
  removeAttendee: (email: string) => void
}

/**
 * A row in NewMemberInput that represents an actual user (new or existing) being invited.
 */
export default function InviteeRow({ areEAsPresent, email, emailPlanDictionary, memberListRef, onChange, removeAttendee }: InviteeRowProps) {
  if (!(email in emailPlanDictionary)) {
    sendMessageToSentry("InviteeRow", "the email prop must be a key of emailPlanDictionary");
    return null;
  }

  return (
    <div className="team-plan-new-member-input-row">
      <div className="flex-grow">{email}</div>
      {areEAsPresent ? <SeatTypeDropdown
        email={email}
        emailPlanDictionary={emailPlanDictionary}
        memberListRef={memberListRef}
        onChange={onChange}
      /> : null}
      <div className="group-scheduling-team-plans-add-attendees-x">
        <X
          className="cursor-pointer"
          onClick={() => removeAttendee(email)}
          size={14}
        />
      </div>
    </div>
  );
}

interface SeatTypeDropdownProps {
  email: string
  emailPlanDictionary: EmailPlanDictionary
  onChange: (email: string, option: typeof PLAN_OPTIONS[number] | null | undefined) => void
  memberListRef: React.RefObject<HTMLDivElement>
}

function SeatTypeDropdown({ email, emailPlanDictionary, onChange, memberListRef }: SeatTypeDropdownProps) {
  const value = emailPlanDictionary[email].plan ?? VIMCAL_DEFAULT_PLAN.value;

  return (
    <div className="w-28">
      <CustomSelectV2<typeof PLAN_OPTIONS[number]>
        value={
          PLAN_OPTIONS.find((option) => option.value === value) ??
          VIMCAL_DEFAULT_PLAN
        }
        options={PLAN_OPTIONS}
        isDisabled={emailPlanDictionary[email].locked}
        isSearchable={false}
        variant="outlined"
        menuPortalTarget={document.body}
        closeMenuOnScroll={(e) => e.target === memberListRef.current}
        onChange={(option) => onChange(email, option)}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 10000 }) }}
      />
    </div>
  );
}
