import React from "react";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { OpenLink } from "../../services/commonUsefulFunctions";
import { PARTNER_WITH_US_IMG } from "../../lib/imageFunctions";
import { PARTNER_WITH_US_URL } from "../../lib/vimcalVariables";

export default function AffiliateModal({ onClose }) {
  return (
    <div className="mt-2">
      <img
        alt=""
        className="rounded-lg"
        style={{
          width: "342px",
        }}
        src={PARTNER_WITH_US_IMG}
      />
      <div className="secondary-text-color default-font-size mt-4">
        Earn monthly income with our Friends of Vimcal program! We'll pay you an
        industry-best 30% commission of first-year revenue for customers you
        introduce to us.
      </div>

      <div className="mt-4 flex justify-end">
        <CustomButton
          buttonType={BLUE_BUTTON}
          onClick={() => {
            OpenLink(PARTNER_WITH_US_URL);
          }}
          label={"Learn more"}
        />
      </div>
    </div>
  );
}
