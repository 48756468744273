import create from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";
import { removeDuplicatesFromArray } from "../commonUsefulFunctions";
import { isEmptyArray } from "../../lib/arrayFunctions";

export const useEnrichedContactsStore = create((set) => ({
  enrichedContacts: [],
  searchedEmails: [],
  addEnrichedContacts: (newEnrichedContacts) =>
    set(
      produce((state) => {
        state.enrichedContacts = [
          ...state.enrichedContacts,
          ...newEnrichedContacts,
        ];
      })
    ),
  setEnrichedContacts: (enrichedContacts) => {
    set((state) => ({ enrichedContacts: enrichedContacts }));
  },
  addSearchEmails: (emails) => {
    set((state) => ({ searchedEmails: state.searchedEmails.concat(emails) }));
  },
  setSearchedEmails: (emails) => {
    set((state) => ({ searchedEmails: emails }));
  },
  resetEnrichedContacts: () =>
    set((state) => ({ enrichedContacts: [], searchedEmails: [] })),
  clearSearchedEmailsCache: (emails) => {
    set((state) => ({ searchedEmails: [] }));
  },
}));

function addStoredEmailsIntoUserIndex({
  lastStoredEmails,
  userEmail,
  newEmails,
}) {
  if (!userEmail || isEmptyArray(newEmails)) {
    return lastStoredEmails;
  }

  const updatedIndex = lastStoredEmails;
  if (updatedIndex[userEmail]) {
    updatedIndex[userEmail] = removeDuplicatesFromArray(
      updatedIndex[userEmail].concat(newEmails)
    );
  } else {
    updatedIndex[userEmail] = removeDuplicatesFromArray(newEmails);
  }

  return lastStoredEmails;
}

export const useStoredContactsStore = create(
  persist(
    (set) => ({
      storedEmails: {},
      addStoredEmails: ({ emails, userEmail }) => {
        set((state) => ({
          storedEmails: addStoredEmailsIntoUserIndex({
            lastStoredEmails: state.storedEmails,
            userEmail,
            newEmails: emails,
          }),
        }));
      },
      clearStoredEmails: () => {
        set((state) => ({ storedEmails: {} }));
      },
    }),
    {
      name: "stored-contacts-storage", // unique name
    }
  )
);
