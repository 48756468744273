/* eslint-disable react/display-name */
import React, { PureComponent } from "react";
import { connect, useSelector } from "react-redux";

import appBroadcast from "../../broadcasts/appBroadcast";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import Fetcher from "../../services/fetcher";
import updateSettingsBroadcast from "../../broadcasts/updateSettingsBroadcast";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { constructRequestURLV2 } from "../../services/api";
import {
  getMatchingUserFromAllUsers,
  getUserEmail,
} from "../../lib/userFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import {
  APP_BROADCAST_VALUES,
  BACKEND_BROADCAST_VALUES,
  UPDATE_SETTINGS_BROADCAST_VALUES,
} from "../../lib/broadcastValues";
import { isLocal } from "../../services/devFunctions";
import {
  guessTimeZone,
  handleError,
} from "../../services/commonUsefulFunctions";
import { BACKEND_SETTINGS_NAMES } from "../../lib/vimcalVariables";
import GoogleCalendarService from "../../services/googleCalendarService";
import settingsBroadcast from "../../broadcasts/settingsBroadcast";
import {
  SETTINGS_ENDPOINT,
  UPDATE_DEFAULT_CONFERENCING_ENDPOINT,
} from "../../lib/settingsEndpoints";
import { getUserConnectedAccountToken, isUserFromMagicLink } from "../../services/maestro/maestroAccessors";
import { fetcherPatch, fetcherPost } from "../../services/fetcherFunctions";
import availabilityBroadcast from "../../broadcasts/availabilityBroadcast";
import { isActionModeCreateAvailability } from "../../services/appFunctions";

class UpdateSettingsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.updateAccountName = this.updateAccountName.bind(this);
    this.updateDefaultConferencing = this.updateDefaultConferencing.bind(this);
    this.updateDefaultGuestPermission =
      this.updateDefaultGuestPermission.bind(this);
    this.updateMasterAccountTimeZone =
      this.updateMasterAccountTimeZone.bind(this);
    this.updateV2Settings = this.updateV2Settings.bind(this);

    /* Broadcast subscriptions */
    updateSettingsBroadcast.subscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_NAME,
      this.updateAccountName,
    );
    updateSettingsBroadcast.subscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_LOCAL_TIME_ZONE,
      this.updateMasterAccountTimeZone,
    );
    updateSettingsBroadcast.subscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_CONFERENCING,
      this.updateDefaultConferencing,
    );
    updateSettingsBroadcast.subscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_GUEST_PERMISSIONS,
      this.updateDefaultGuestPermission,
    );
    updateSettingsBroadcast.subscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
      this.updateV2Settings,
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    /* Unsubscribe from broadcast */
    updateSettingsBroadcast.unsubscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_NAME,
    );
    updateSettingsBroadcast.unsubscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_ACCOUNT_LOCAL_TIME_ZONE,
    );
    updateSettingsBroadcast.unsubscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_CONFERENCING,
    );
    updateSettingsBroadcast.unsubscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_DEFAULT_GUEST_PERMISSIONS,
    );
    updateSettingsBroadcast.unsubscribe(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
    );
  }

  async updateAccountName({
    firstName,
    lastName,
    profilePhotoS3Key,
    username,
    user,
    isUpdatingExecutiveProfile,
  }) {
    const path = "settings/name";
    const url = constructRequestURLV2(path);
    const param = {
      first_name: firstName?.trim() ?? undefined,
      last_name: lastName?.trim() ?? undefined,
      profile_photo_s3_key: profilePhotoS3Key,
      update_executive_profile: isUpdatingExecutiveProfile,
      delegated_user_email: isUpdatingExecutiveProfile
        ? user?.email
        : undefined,
      username,
    };
    const payloadData = {
      body: JSON.stringify(param),
    };

    try {
      const response = await Fetcher.patch(
        url,
        payloadData,
        true,
        user?.email ?? getUserEmail(this.props.currentUser),
      );

      if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
        return;
      }

      const { user: updatedUser, master_account } = response;

      if (!isEmptyObjectOrFalsey(updatedUser)) {
        // for delegate user
        backendBroadcasts.publish(
          BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
          updatedUser,
        );
      } else if (!isEmptyObjectOrFalsey(master_account)) {
        if (isUserFromMagicLink({ user })) {
          const updatedUser = {
            ...user,
            connected_account_details: {
              ...user.connected_account_details ?? {},
              master_account,
            },
          };

          backendBroadcasts.publish(
            BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
            updatedUser,
          );

          return;
        }

        // for normal users
        appBroadcast.publish(
          APP_BROADCAST_VALUES.UPDATE_MASTER_ACCOUNT,
          master_account,
        );
      }

      /* Render the green "Saved" with a check next to title */
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    } catch(error) {
      handleError(error);
    }
  }

  async updateDefaultConferencing({ defaultConferencing, user }) {
    const { currentUser } = this.props;

    const url = constructRequestURLV2(UPDATE_DEFAULT_CONFERENCING_ENDPOINT);
    const payloadData = {
      body: JSON.stringify({
        default_conferencing_option: defaultConferencing,
      }),
    };

    try {
      const response = await fetcherPost({
        authorizationRequired: true,
        connectedAccountToken: getUserConnectedAccountToken({ user }),
        email: getUserEmail(user) || getUserEmail(currentUser),
        payloadData,
        url,
      });

      if (
        !this._isMounted ||
        isEmptyObjectOrFalsey(response) ||
        response.error
      ) {
        return;
      }

      const { user: updatedUser } = response;

      backendBroadcasts.publish(
        BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
        updatedUser,
      );

      /* Render the green "Saved" with a check next to title */
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
      settingsBroadcast.publish(UPDATE_SETTINGS_BROADCAST_VALUES.CLEAR_TEMPORARY_DEFAULT_CONFERENCING);
    } catch (error) {
      handleError(error);
    }
  }

  updateDefaultGuestPermission({ defaultPermissions, userEmail }) {
    let updatedPermissions = { guest_permission_modify_events: false };
    const { modifyEventsString, inviteOthersString, seeGuestListString } =
      GoogleCalendarService;

    /* Enable all permissions if "Modify events" is selected */
    if (defaultPermissions.includes(modifyEventsString)) {
      updatedPermissions = {
        guest_permission_modify_events: true,
        guest_permission_invite_others: true,
        guest_permission_see_guest_list: true,
      };
    } else {
      /* Otherwise just check the other 2 values */
      updatedPermissions.guest_permission_invite_others =
        defaultPermissions.includes(inviteOthersString);
      updatedPermissions.guest_permission_see_guest_list =
        defaultPermissions.includes(seeGuestListString);
    }

    this.updateV2Settings({ settings: updatedPermissions, userEmail });
  }

  updateMasterAccountTimeZone() {
    if (isLocal()) {
      return;
    }

    const currentLocalTimeZone = guessTimeZone();
    const settings = {
      [BACKEND_SETTINGS_NAMES.LOCAL_TIME_ZONE]: currentLocalTimeZone,
    };

    this.updateV2Settings({
      settings,
    });
  }

  async updateV2Settings({ settings, user, isUpdatingExecutiveProfile, shouldRefetchEvents }) {
    const { allLoggedInUsers, currentUser, masterAccount } = this.props;
    // isUpdatingExecutiveProfile tells backend to always update the executive profile
    // otherwise we update masteraccount settings
    if (isEmptyObjectOrFalsey(settings)) {
      return;
    }

    const url = constructRequestURLV2(SETTINGS_ENDPOINT);
    const payloadData = {
      body: JSON.stringify({ settings, isUpdatingExecutiveProfile }),
    };

    try {
      const response = await fetcherPatch({
        authorizationRequired: true,
        connectedAccountToken: getUserConnectedAccountToken({ user }),
        email: getUserEmail(user) || getUserEmail(currentUser),
        payloadData,
        url,
      });

      if (
        !this._isMounted ||
        isEmptyObjectOrFalsey(response) ||
        response.error
      ) {
        return;
      }
      if (shouldRefetchEvents) {
        backendBroadcasts.publish("FETCH_EVENTS_FOR_CALENDAR_WITHIN_WINDOW");
        backendBroadcasts.publish("INITIAL_USER_SYNC");
      }

      const { master_account_settings, settings: updatedSettings } = response;

      if (master_account_settings) {
        const updatedMasterAccount = {
          ...masterAccount,
          settings: master_account_settings,
        };
        
        appBroadcast.publish(
          APP_BROADCAST_VALUES.UPDATE_MASTER_ACCOUNT,
          updatedMasterAccount,
        );
      }

      if (updatedSettings) {
        const matchingUser = getMatchingUserFromAllUsers({
          allUsers: allLoggedInUsers,
          userEmail: getUserEmail(user) ?? getUserEmail(currentUser),
        });

        if (!matchingUser) {
          return;
        }

        const getUpdatedUser = () => {
          if (isUserFromMagicLink({ user: matchingUser })) {
            const updatedConnectedAccountDetails = response.connected_account_details;
  
            return {
              ...matchingUser,
              settings: updatedSettings,
              connected_account_details: {
                ...matchingUser.connected_account_details ?? {},
                ...updatedConnectedAccountDetails ?? {},
              },
            };
          } else {
            return {
              ...matchingUser,
              settings: updatedSettings,
            };
          }
        };

        const updatedUser = getUpdatedUser();
        backendBroadcasts.publish(
          BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
          updatedUser,
        );
        const {
          actionMode,
        } = this.props;
        if (isActionModeCreateAvailability(actionMode)) {
          availabilityBroadcast.publish("REFRESH_ALL_USERS_IN_SELECT");
        }
      }
    } catch (error) {
      handleError(error);
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const {
    actionMode,
  } = state;

  return {
    actionMode
  };
}


const withStore = (BaseComponent) => (props) => {
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  return (
    <BaseComponent
      {...props}
      allLoggedInUsers={allLoggedInUsers}
      currentUser={currentUser}
      masterAccount={masterAccount}
    />
  );
};

export default connect(
  mapStateToProps,
  null,
)(withStore(UpdateSettingsContainer));
