import React from "react";

const CampFire = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9.14306C16.9691 6.75628 15.7623 4.53851 13.775 3.21631C13.3914 2.94511 12.8823 2.9313 12.4845 3.1813C12.0868 3.4313 11.8785 3.89603 11.9565 4.35931C12.106 5.33746 11.8668 6.3353 11.29 7.13931C10.8173 6.71824 10.4546 6.18801 10.2336 5.59479C10.1638 5.37255 9.99539 5.19493 9.77719 5.11332C9.559 5.03171 9.31537 5.05523 9.11681 5.17707C7.61246 6.19081 6.80876 7.96653 7.03994 9.76578C7.44076 12.3218 9.72142 14.1525 12.3036 13.9911C14.8858 13.8297 16.9207 11.7291 17 9.14306Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 21L21 15"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16.4117L4 15"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 19.5884L21 21.0001"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CampFire;
