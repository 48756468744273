import React, { Component } from "react";
import { Copy } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import {
  SET_DISAPPEARING_NOTIFICATION_MESSAGE
} from "../services/globalVariables";

class CopyIcon extends Component {
  render() {
    return (
      <span onClick={this.copyText.bind(this)}>
        <Copy size="14" className="clickable-icon" />
      </span>
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  copyText(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    navigator.clipboard.writeText(this.props.textToCopy).then(() => {
      if (!this._isMounted) {
        return;
      }

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `Copied to clipboard: ${this.props.textToCopy}`
      );
    });
  }
}

export default CopyIcon;
