import { BROADCAST_VALUES } from "../../lib/broadcastValues";
import { getEmailFromUserCalendarID } from "../../lib/calendarFunctions";
import { isOutlookEvent } from "../../lib/eventFunctions";
import { isEventFirstRecurringInstance } from "../../lib/recurringEventFunctions";
import { constructRequestURL } from "../../services/api";
import broadcast from "../../broadcasts/broadcast";
import { constructQueryParams, determineSyncWindow, formatTimeForBackendJsDate, getGoogleEventId } from "../../services/commonUsefulFunctions";
import { getEventEnd, getEventICalUID, getEventMasterEventID, getEventStart, getEventUserCalendarID, getEventUserEventID } from "../../services/eventResourceAccessors";
import { GENERIC_ERROR_MESSAGE, SET_DISAPPEARING_NOTIFICATION_MESSAGE } from "../../services/globalVariables";
import { EDIT_RECURRING_ALL_INSTANCES, EDIT_RECURRING_FOLLOWING_EVENTS, EDIT_RECURRING_INSTANCE_ONLY, GOOGLE_UPDATES } from "../../services/googleCalendarService";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { isVersionV2 } from "../../services/versionFunctions";
import MainCalendar from "../mainCalendar";

interface RecurringEventSingleResourceUpdateOptions {
  newValue: unknown
  resourceName: string
  response: typeof EDIT_RECURRING_ALL_INSTANCES | typeof EDIT_RECURRING_FOLLOWING_EVENTS | typeof EDIT_RECURRING_INSTANCE_ONLY
}

export function recurringEventSingleResourceUpdate(this: MainCalendar, {
  newValue,
  resourceName,
  response,
}: RecurringEventSingleResourceUpdateOptions) {
  const {
    originalRecurringEvent,
    updatedRecurringEvent,
  } = this.state;

  switch (response) {
    case EDIT_RECURRING_INSTANCE_ONLY:
      onChangeThisEventWithSingleResourceChange
        .bind(this)(resourceName, newValue);
      break;
    case EDIT_RECURRING_FOLLOWING_EVENTS:
      if (isEmptyObjectOrFalsey(originalRecurringEvent)) {
        broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE,
        );
      } else if (
        // TODO: Remove type casting once accessor function is typed.
        isEventFirstRecurringInstance(
          (getEventStart(originalRecurringEvent) as VimcalEventTime),
          (getEventStart(updatedRecurringEvent) as VimcalEventTime),
        )
      ) {
        updateAllInstancesWithSingleResourceChange
          .bind(this)(resourceName, newValue);
      } else {
        updateThisAndFollowingEventWithSingleResourceChange
          .bind(this)(resourceName, newValue);
      }
      break;
    case EDIT_RECURRING_ALL_INSTANCES:
      updateAllInstancesWithSingleResourceChange
        .bind(this)(resourceName, newValue);
      break;
  }
}

function onChangeThisEventWithSingleResourceChange(this: MainCalendar, resourceName: string, updatedResource: unknown) {
  if (isEmptyObjectOrFalsey(this.state.updatedRecurringEvent)) {
    broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      GENERIC_ERROR_MESSAGE,
    );
    this.closeModal();
    return;
  }

  const constructUpdatedResourceEventData = () => {
    return {
      user_calendar_id: getEventUserCalendarID(this.state.updatedRecurringEvent),
      delete_conferencing: false,
      calendar_event: {
        [resourceName]: updatedResource,
        provider_id: getGoogleEventId(this.state.updatedRecurringEvent),
      },
      user_event_id: getEventUserEventID(this.state.updatedRecurringEvent),
    };
  };

  const eventData = constructUpdatedResourceEventData();
  const event = this.state.updatedRecurringEvent;

  this.updateSingleEvent({
    event,
    updatedProperties: eventData,
  });
}

function updateAllInstancesWithSingleResourceChange(this: MainCalendar, resourceName: string, updatedResource: unknown) {
  const { originalRecurringEvent, updatedRecurringEvent: event } = this.state;
  const {
    currentTimeZone,
    selectedDay,
    selectedCalendarView,
    weekStart,
  } = this.props;
  const { allCalendars } = this.props.allCalendars;

  if (!originalRecurringEvent || isEmptyObjectOrFalsey(event)) {
    broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
    this.closeModal();
    return;
  }

  const isV2 = isVersionV2();
  const path = isOutlookEvent(event) ? "recurring_events/all" : "recurring_events/single_resource/all";

  const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
  const masterEventStartDate = getEventStart(originalRecurringEvent);
  const masterEventEndDate = getEventEnd(originalRecurringEvent);

  const queryParams = constructQueryParams({
    sendUpdates: GOOGLE_UPDATES.NONE,
    timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
    timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
    ical_uid: getEventICalUID(event),
    time_zone: currentTimeZone,
    // V1 fields
    ...(!isV2 && {
      google_event_id: getGoogleEventId(event),
    }),
    // V2 fields
    ...(isV2 && {
      calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(originalRecurringEvent), allCalendars),
      event_provider_id: getGoogleEventId(event),
      master_event_id: getGoogleEventId(originalRecurringEvent),
      master_event_start_date: masterEventStartDate?.dateTime,
      master_event_end_date: masterEventEndDate?.dateTime,
    }),
  });

  broadcast.publish(BROADCAST_VALUES.UPDATE_INSTANCES, {
    url: `${constructRequestURL(path, isV2)}?${queryParams}`,
    body: {
      [isV2 ? "calendar_event" : "event"]: { [resourceName]: updatedResource },
    },
    originalEvent: event,
  }, isV2);
  this.removePreviewEvent();

  this.closeModal();
}

function updateThisAndFollowingEventWithSingleResourceChange(this: MainCalendar, resourceName: string, updatedResource: unknown) {
  // This is not ready for use
  //example: {resourceName: transparency, updatedResource: opaque}
  // TODO: need to fix for outlook
  const { originalRecurringEvent, updatedRecurringEvent: event } = this.state;
  const {
    selectedDay,
    selectedCalendarView,
    weekStart,
  } = this.props;
  const { allCalendars } = this.props.allCalendars;
  if (!originalRecurringEvent || isEmptyObjectOrFalsey(event)) {
    broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
    this.closeModal();
    return;
  }
  const isV2 = isVersionV2();
  const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
  const masterEventStartDate = getEventStart(originalRecurringEvent);
  const masterEventEndDate = getEventEnd(originalRecurringEvent);
  const queryParams = constructQueryParams({
    timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
    timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
    // V1 specific fields
    ...(!isV2 && {
      google_event_id: getGoogleEventId(event),
    }),
    // V2 specific fields
    ...(isV2 && {
      calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(originalRecurringEvent), allCalendars),
      event_provider_id: getGoogleEventId(event),
      master_event_id: getGoogleEventId(originalRecurringEvent),
      master_event_start_date: masterEventStartDate?.dateTime,
      master_event_end_date: masterEventEndDate?.dateTime,
    }),
  });

  const path = isV2 ?
    "recurring_events/single_resource/following" :
    `calendars/${getEventUserCalendarID(event)}/recurring_events/${getEventMasterEventID(event)}/colors_following`;

  broadcast.publish(BROADCAST_VALUES.UPDATE_INSTANCES, {
    url: `${constructRequestURL(path, isV2)}?${queryParams}`,
    body: {
      [isV2 ? "calendar_event" : "event"]: { [resourceName]: updatedResource },
    },
    originalEvent: event,
  });
  this.removePreviewEvent();

  this.closeModal();
}
