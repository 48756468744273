import React, { useEffect } from "react";
import Modal, { Props as ModalProps} from "react-modal";
import ModalTitleHeader from "./modalTitleHeader";
import CloseButton from "./closeButton";
import Broadcast from "../broadcasts/broadcast";
import _ from "underscore";
import classNames from "classnames";
import { EVENT_MODAL_ID } from "../services/elementIDVariables";
import { isSafari } from "../services/commonUsefulFunctions";
import { getDefaultModalStyle } from "../lib/styleFunctions";
import { BROADCAST_VALUES } from "../lib/broadcastValues";

interface EventModalPopupProps extends HeaderProps, Pick<
  ModalProps,
  "children" | "contentLabel" | "isOpen" | "style"
>{
  contentClassName?: string
  headerClassName?: string
  modalID?: string
  height?: number | string
  hideHeader?: boolean
  shouldFreeze?: boolean
  skipDisablingHotKeys?: boolean
  width?: number | string
}

export default function EventModalPopup({
  children,
  contentClassName,
  contentLabel,
  height,
  hideHeader,
  isOpen,
  modalID,
  onRequestClose,
  shouldFreeze,
  skipDisablingHotKeys,
  style,
  width,
  ...headerProps
}: EventModalPopupProps) {

  useEffect(() => {
    if (!skipDisablingHotKeys) {
      if (isOpen) {
        Broadcast.publish(BROADCAST_VALUES.DISABLE_HOT_KEYS);
      } else {
        Broadcast.publish(BROADCAST_VALUES.ENABLE_HOT_KEYS);
      }

      return () => {
        Broadcast.publish(BROADCAST_VALUES.ENABLE_HOT_KEYS);
      };
    }
  }, [isOpen, skipDisablingHotKeys]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={shouldFreeze ? _.noop : onRequestClose}
      style={style || getDefaultModalStyle()}
      contentLabel={contentLabel}
      ariaHideApp={false}
      id={modalID || EVENT_MODAL_ID}
      className={contentClassName}
    >
      <div
        style={{ height, width }}
        className={isSafari() ? "" : "home-page-hide-scroll-bar-unless-hover"}
      >
        {hideHeader ? null : (
          <Header
            {...headerProps}
            onRequestClose={onRequestClose}
            shouldFreeze={shouldFreeze}
          />
        )}
        {children}
      </div>
    </Modal>
  );
}

interface HeaderProps extends Pick<ModalProps, "onRequestClose"> {
  exitButtonLocation?: string
  fontSizeClassName?: string
  headerClassName?: string
  hideCloseButton?: boolean
  hideTitle?: boolean
  onClickBack?: () => void
  shouldFreeze?: boolean
  title?: string
}

function Header({
  exitButtonLocation,
  fontSizeClassName,
  hideCloseButton,
  hideTitle,
  onClickBack,
  onRequestClose,
  shouldFreeze,
  title,
  headerClassName,
}: HeaderProps) {

  return (
    <div
      className={classNames(
        "flex items-start justify-between",
        hideTitle ? "" : "mb-2.5",
      )}
    >
      {hideTitle ? (
        <div className="w-full"></div>
      ) : (
        <ModalTitleHeader
          headerClassName={headerClassName}
          fontSizeClassName={fontSizeClassName}
          title={title}
          onClickBack={onClickBack}
        />
      )}

      {hideCloseButton ? null : (
        <div
          className={classNames(
            exitButtonLocation ?? "margin-left-ten",
          )}
        >
          {shouldFreeze ? null : (
            <CloseButton
              onClick={onRequestClose}
              disableHoverHint={true}
            />
          )}
        </div>
      )}
    </div>
  );
}
