import React, { useState } from "react";
import CustomButton from "./customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../services/globalVariables";
import { getInputStringFromEvent } from "../lib/stringFunctions";

export default function SendEmailUpdateModal({
  onClickDismiss,
  onClickDoNotSend,
  sendUpdate,
  shouldHideTextArea,
  placeHolderText,
  hideDoNotSendButton,
}) {
  const [message, setMessage] = useState("");

  return (
    <div className="weekly-calendar-modal-content">
      {shouldHideTextArea ? null : (
        <textarea
          placeholder={placeHolderText || "Add an optional message"}
          className="email-input-container"
          onChange={(e) => {
            setMessage(getInputStringFromEvent(e));
          }}
        />
      )}

      <div className="weekly-calendar-modal-content-button-options">
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={onClickDismiss}
          label="Cancel"
        />

        {hideDoNotSendButton ? null : (
          <CustomButton
            buttonType={WHITE_BUTTON}
            addPaddingToRight={true}
            onClick={onClickDoNotSend}
            label="Don't send"
          />
        )}

        <CustomButton
          buttonType={BLUE_BUTTON}
          shouldFocus={true}
          onClick={() => sendUpdate(message)}
          label="Send"
        />
      </div>
    </div>
  );
}
