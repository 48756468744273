import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { hasStopEventPropagation } from "../services/commonUsefulFunctions";
import { ChevronLeft } from "react-feather";

interface DefaultBackButtonProps {
  onClick: () => void
}

export default function DefaultBackButton({ onClick }: DefaultBackButtonProps) {
  return <InlineBackButton className="absolute top-16 left-16" onClick={onClick} />;
}

interface InlineBackButtonProps extends DefaultBackButtonProps {
  className?: string
}

export function InlineBackButton({ className, onClick }: InlineBackButtonProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    hasStopEventPropagation(e);
    onClick();
  };

  return (
    <div
      className={classNames(
        className,
        "flex items-center justify-center rounded-full w-10 h-10 duration-200 cursor-pointer",
        isDarkMode
          ? "bg-gray-500 hover:bg-gray-600"
          : "box-shadow-6 hover:bg-gray-200",
      )}
      onClick={handleClick}
    >
      <ChevronLeft size={24} />
    </div>
  );
}
