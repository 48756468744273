import React, { PureComponent } from "react";
import NativeConferencingInfo from "../nativeConferencingInfo";
import EventFormNativeZoomContent from "./eventFormNativeZoomContent";
import PhoneConference from "./phoneConference";
import Spinner from "../spinner";
import {
  OpenLink,
  formatRFCPhoneNumber,
  getPhoneNumberString,
} from "../../services/commonUsefulFunctions";
import GoogleCalendarService, {
  WHATS_APP_STRING,
  createZoomNoteFromUniqueZoom,
} from "../../services/googleCalendarService";
import CustomButton from "../customButton";
import {
  REAUTH_ZOOM_ERROR,
  isValidPhoneConferencing,
  isValidCustomConferencing,
} from "../../lib/conferencing";
import ZoomLoginButton from "../zoomLoginButton";
import CopiableContentContainer from "../copiableContentContainer";
import AppBroadcast from "../../broadcasts/appBroadcast";
import { getUserName, getUserPersonalZoomLink } from "../../lib/userFunctions";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import { getZoomJoinURL } from "../../lib/zoomFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import { getCustomConferencingName, getCustomConferencingURL } from "../../lib/settingsFunctions";
import { canEditZoomSettings } from "../../lib/featureFlagFunctions";
import { WHATSAPP_BASE_URL } from "../../lib/stringFunctions";

const { zoomString, phoneNumberConference } = GoogleCalendarService;

class EventFormConferenceAdditionalInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.reauthZoom = this.reauthZoom.bind(this);
  }

  renderPersonalLinkZoomURL() {
    if (!getUserPersonalZoomLink(this.getUser())) {
      return null;
    }

    return (
      <div className="ml-4">
        <EventFormNativeZoomContent
          url={this.props.zoomURL}
          hideConferencingInfo={this.props.hideConferencingInfo}
          conference={this.props.conference}
          conferenceChanged={this.props.conferenceChanged}
          originalEvent={this.props.originalEvent}
        />
      </div>
    );
  }

  getUser() {
    return this.props.user;
  }

  renderUniqueZoomDetails() {
    if (!this.props.shouldRenderZoomDetails) {
      return null;
    }

    if (isEmptyObjectOrFalsey(this.props.uniqueZoomMeeting)) {
      // render spinner
      if (this.props.isTemplate) {
        return null;
      }

      if (this.props.zoomError) {
        if (this.props.zoomError === REAUTH_ZOOM_ERROR) {
          return (
            <>
              <div className="ml-10 default-font-size mt-2 warning-color w-80">
                Error connecting to Zoom
              </div>

              <ZoomLoginButton
                onClickLogin={this.reauthZoom}
                containerClassName="ml-10 mt-2"
                labelClassName="font-size-12"
                containerWidth={150}
                label="Re-authenticate"
              />
            </>
          );
        }

        return this.renderErrorCreatingZoomMeeting();
      }

      return (
        <div
          className="display-flex-flex-direction-row align-items-center"
          style={{ position: "relative", height: 20, marginTop: 10 }}
        >
          <div style={{ position: "absolute", top: "-30px", left: "50px" }}>
            <Spinner useSmallSpinner={true} />
          </div>

          <div
            className="font-size-12 font-weight-300 user-select-none"
            style={{ marginLeft: 70, color: "#A1A1A1" }}
          >
            Adding Zoom details
          </div>
        </div>
      );
    } else {
      //  Start URL is for meeting host to start a meeting. The URL will contain a ZPK section which is used for Zoom web backend to authenticate the host.
      // Join URL is used for meeting attendees. It contains join meeting link along with meeting ID. Everyone can join the meeting as long as he/she has the join URL
      // https://devforum.zoom.us/t/whats-the-difference-between-join-url-and-start-url/1183
      return (
        <div className="ml-4">
          <EventFormNativeZoomContent
            url={getZoomJoinURL(this.props.uniqueZoomMeeting)}
            hideConferencingInfo={this.props.hideConferencingInfo}
            conference={this.props.conference}
            conferenceChanged={this.props.conferenceChanged}
            originalEvent={this.props.originalEvent}
            zoomMeeting={this.props.uniqueZoomMeeting}
            notes={createZoomNoteFromUniqueZoom(this.props.uniqueZoomMeeting)}
          />
        </div>
      );
    }
  }

  renderErrorCreatingZoomMeeting() {
    return (
      <>
        <div className="ml-10 default-font-size mt-2 warning-color w-80">
          An error occured creating the Zoom meeting
        </div>

        <CustomButton
          className="mt-2 ml-10"
          labelClassNameOverride={"font-weight-300-important"}
          onClick={this.reauthZoom}
          label={"Try again"}
        />
      </>
    );
  }

  renderNativeConferencingAdditionalInfo(inputEvent) {
    const joinLabel = this.props.conference.toLowerCase().includes("join")
      ? this.props.conference
      : `Join ${this.props.conference}`;

    return (
      <div className="ml-4">
        <div>
          <div
            className="join-conference-link margin-left-22px margin-bottom-five"
            onClick={() => OpenLink(this.props.conferenceURL)}
          >
            {joinLabel}
          </div>

          {this.props.hideConferencingInfo ? null : (
            <NativeConferencingInfo
              event={inputEvent ?? this.props.originalEvent}
              fromEventForm={true}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      conference,
      conferenceChanged,
      defaultToZoomPersonalLink,
      eventNotHavePermissionToEditActualEvent,
      isDuplicate,
      isNativeLink,
      uniqueZoomMeeting,
      user,
      wasZoomUpdated,
      isCopyToCalendarEvent,
      originalEventConferenceData,
    } = this.props;

    if (
      conference === zoomString &&
      !defaultToZoomPersonalLink &&
      wasZoomUpdated &&
      uniqueZoomMeeting &&
      canEditZoomSettings(user)
    ) {
      // If the meeting is a unique Zoom link and it was just updated, always
      // render details from the Zoom meeting itself since the event is outdated.
      return this.renderUniqueZoomDetails();
    } else if (isNativeLink &&
      !conferenceChanged &&
      !eventNotHavePermissionToEditActualEvent &&
      isCopyToCalendarEvent && 
      originalEventConferenceData
    ) {
      const inputEvent = {
        conference_data: originalEventConferenceData,
      };
      // pass in fake event that has the original event conferenceData
      return this.renderNativeConferencingAdditionalInfo(inputEvent);
    } else if (
      isNativeLink &&
      !conferenceChanged &&
      !eventNotHavePermissionToEditActualEvent &&
      !(isDuplicate && conference === zoomString)
    ) {
      return this.renderNativeConferencingAdditionalInfo();
    } else if (
      conference === zoomString &&
      defaultToZoomPersonalLink
    ) {
      return this.renderPersonalLinkZoomURL();
    } else if (
      conference === zoomString &&
      !eventNotHavePermissionToEditActualEvent
    ) {
      // return this.renderZoomURL();
      return this.renderUniqueZoomDetails();
    } else if (conference === phoneNumberConference) {
      const { masterAccount } = this.props.masterAccount;
      const { firstName } = getUserName({
        user: this.getUser(),
        masterAccount,
      });
      return (
        <PhoneConference
          firstName={firstName}
          regionCode={this.getUser()?.phone_region_code}
          number={this.getUser()?.phone_number}
        />
      );
    } else if (conference === WHATS_APP_STRING) {
      return this.renderWhatsAppConference();
    } else if (
      conference === getCustomConferencingName({ user: this.getUser() })
    ) {
      return this.renderCustomConference();
    }
    return null;
  }

  renderWhatsAppConference() {
    if (!isValidPhoneConferencing(this.getUser())) {
      return null;
    }

    let number = {
      regionCode: this.getUser()?.phone_region_code,
      number: this.getUser()?.phone_number,
    };
    const { masterAccount } = this.props.masterAccount;
    const { firstName } = getUserName({ masterAccount, user: this.getUser() });

    return (
      <div className="margin-left-16">
        <div>
          <div
            className="join-conference-link margin-left-22px"
            onClick={() =>
              OpenLink(
                `${WHATSAPP_BASE_URL}${getPhoneNumberString(
                  number.number,
                  number.regionCode
                )}`
              )
            }
          >
            Join WhatsApp Call
          </div>

          <div style={{ marginLeft: 22 }} className="margin-top-3">
            <CopiableContentContainer
              textToCopy={`${WHATSAPP_BASE_URL}${getPhoneNumberString(
                number.number,
                number.regionCode
              )}`}
            >
              <div
                className="zoom-additional-info-section"
                style={{ marginLeft: 0 }}
              >
                {`${WHATSAPP_BASE_URL}${getPhoneNumberString(
                  number.number,
                  number.regionCode
                )}`}
              </div>
            </CopiableContentContainer>

            <div className="margin-top-3">
              {`Call ${firstName} on WhatsApp (${formatRFCPhoneNumber(
                number.number,
                number.regionCode
              )})`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCustomConference() {
    if (!isValidCustomConferencing(this.getUser())) {
      return null;
    }

    return (
      <div className="margin-left-16">
        <div>
          <div
            className="join-conference-link margin-left-22px"
            onClick={() => OpenLink(getCustomConferencingURL({ user: this.getUser() }))}
          >
            {`Join ${
              getCustomConferencingName({ user: this.getUser() }) || "video conferencing"
            }`}
          </div>

          <div style={{ marginLeft: 22 }} className="margin-top-3">
            <CopiableContentContainer
              textToCopy={getCustomConferencingURL({ user: this.getUser() })}
            >
              <div
                className="zoom-additional-info-section"
                style={{ marginLeft: 0 }}
              >
                {getCustomConferencingURL({ user: this.getUser() })}
              </div>
            </CopiableContentContainer>
          </div>
        </div>
      </div>
    );
  }

  reauthZoom() {
    if (this.props.onClickZoomLogin) {
      this.props.onClickZoomLogin();
      return;
    }
    AppBroadcast.publish("AUTHENTICATE_ZOOM", this.getUser());
  }
}

const withStore = (BaseComponent) => (props) => {
  const masterAccount = useMasterAccount();

  return <BaseComponent {...props} masterAccount={masterAccount} />;
};

export default withStore(EventFormConferenceAdditionalInfo);
