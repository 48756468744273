import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadTheme } from "../../services/commonUsefulFunctions";
import AppBroadcast from "../../broadcasts/appBroadcast";
import {
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
} from "../../services/globalVariables";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import BillingSettings from "../settings/admin/billing/billingSettings";
import { InlineBackButton } from "../defaultBackButton";

export default function BillingContainer() {
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  useEffect(() => {
    if (isEmptyObjectOrFalsey(currentUser)) {
      history.push("/login?reroute=billing");
      return;
    }
    backendBroadcasts.publish("GET_BILLING_INFO");

    if (isDarkMode) {
      loadTheme(DARK_MODE_THEME);
      AppBroadcast.publish("SET_APP_DARK_MODE");
    } else {
      loadTheme(LIGHT_MODE_THEME);
      AppBroadcast.publish("SET_APP_LIGHT_MODE");
    }
  }, []);

  return (
    <div className="height-100vh w-full overflow-auto">
      <div className="mx-auto p-8" style={{ width: 712 }}>
        <div className="font-size-20 mb-5 flex items-center gap-5">
          <InlineBackButton onClick={() => history.push("/home")} />
          <span>Billing</span>
        </div>
        <BillingSettings skipBillingPromotion={false} />
      </div>
    </div>
  );
}
