import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { isInt } from "../services/commonUsefulFunctions";
import classNames from "classnames";
import { getInputStringFromEvent } from "../lib/stringFunctions";
interface NumericInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange: (value: number) => void;
}

export function NumericInput({
  min = 0,
  max = 1000,
  step = 1,
  value,
  onChange,
  ...props
}: NumericInputProps) {
  const [inputValue, setInputValue] = useState(String(value));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawNewValue = getInputStringFromEvent(event);
    setInputValue(rawNewValue);
    const newValue = parseInt(rawNewValue);
    if (!isAcceptableValue(newValue)) {
      return;
    }
    onChange(newValue);
  };

  const isAcceptableValue = (newValue: number) => {
    return isInt(newValue) && newValue >= min && newValue <= max;
  };

  const onBlur = () => {
    const newValue = parseInt(inputValue);
    if (!isAcceptableValue(newValue)) {
      setInputValue(String(value));
      return;
    }
    onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = parseInt(String(value || 0)) + step;
    setInputValue(String(newValue));
    if (!isAcceptableValue(newValue)) {
      setInputValue(String(value));
      return;
    }
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = parseInt(String(value || 0)) - step;
    setInputValue(String(newValue));
    if (!isAcceptableValue(newValue)) {
      setInputValue(String(value));
      return;
    }
    onChange(newValue);
  };

  return (
    <div className="inline-flex height-34px">
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={inputValue}
        onChange={handleChange}
        className="w-11 rounded-l-md numeric-input-container px-2 text-center bg-transparent default-font-size default-font-weight"
        onBlur={onBlur}
        {...props}
      />
      <div className="inline-flex flex-col -ml-px">
        <button
          type="button"
          onClick={handleIncrement}
          className={classNames("flex h-5 w-7 items-center justify-center rounded-tr-md numeric-input-container bg-transparent", "hoverable-secondary-text-color")}
          aria-label="Increment"
        >
          <ChevronUp
            className="h-3 w-3"
          />
        </button>
        <button
          type="button"
          onClick={handleDecrement}
          className={classNames("flex h-5 w-7 items-center justify-center rounded-br-md numeric-input-container bg-transparent", "hoverable-secondary-text-color")}
          aria-label="Decrement"
        >
          <ChevronDown
            className="h-3 w-3"
          />
        </button>
      </div>
    </div>
  );
}
