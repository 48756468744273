import React from "react";

const WarningSymbol = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68446 1.74223L10.5719 0.15625L11.4593 1.74223L19.7771 16.6074L20.6438 18.1562H18.8689H2.27487H0.5L1.36668 16.6074L9.68446 1.74223ZM3.14155 16.6074L10.5719 3.32821L18.0022 16.6074H3.14155ZM11.2634 12.6011H9.73547L9.58334 6.20492H11.4155L11.2634 12.6011ZM11.6007 14.883C11.6007 15.5114 11.1708 15.9215 10.5027 15.9215C9.83469 15.9215 9.40475 15.5114 9.40475 14.883C9.40475 14.2481 9.83469 13.838 10.5027 13.838C11.1708 13.838 11.6007 14.2481 11.6007 14.883Z"
        fill="#FFC700"
      />
    </svg>
  );
};

export default WarningSymbol;
