import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUserEmail } from "../lib/localData";

// https://stackoverflow.com/questions/48497510/simple-conditional-routing-in-reactjs
export default function AuthorizedRoute(inputProps) {
  const [hasCurrentUser] = useState(getCurrentUserEmail());
  const { component: Component, ...props } = inputProps;

  return (
    <Route
      {...props}
      render={(props) =>
        hasCurrentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
