import React, { useEffect, useState } from "react";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import AddMoreButton from "./addMoreButton";
import { getColorLabels } from "../../../../lib/settingsFunctions";
import {
  getColorLabelColorID,
  getColorLabelLabel,
  getNextUnusedColorID,
  hasColorLabelUsedAllColors,
} from "../../../colorSelector/colorLabelFunctions";
import ColorLabelRow from "./colorLabelRow";
import {
  immutableDeleteElementAtIndex,
  isEmptyArray,
} from "../../../../lib/arrayFunctions";
import SaveButton from "../../common/saveButton";
import {
  APP_SETTINGS,
  BACKEND_SETTINGS_NAMES,
} from "../../../../lib/vimcalVariables";
import { updateMasterAccountSettingsForFrontendAndBackend } from "../../../../services/appFunctions";
import { isUserDelegatedUser } from "../../../../services/maestroFunctions";
import { useSelector } from "react-redux";
import { shouldDisplayColorLabel } from "../../../../lib/featureFlagFunctions";
import produce from "immer";
import { isNullOrUndefined } from "../../../../services/typeGuards";

export default function ColorLabelSetting({ selectedUser }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);

  const getInitialColorLabels = (inputUser, inputMasterAccount ) => {
    const user = inputUser ?? selectedUser ?? currentUser;
    if (!shouldDisplayColorLabel({user})) {
      return [];
    }
    const colorLabels = getColorLabels({
      user,
      masterAccount: inputMasterAccount ?? masterAccount,
    });
    if (isEmptyArray(colorLabels)) {
      return [{ color_id: "1", label: "" }]; // default
    }
    return colorLabels;
  };

  const [colorLabels, setColorLabels] = useState(getInitialColorLabels());

  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    setColorLabels(
      getInitialColorLabels(selectedUser, masterAccount)
    );
    setHasChanged(false);
  }, [selectedUser, masterAccount]);

  const onClickAddMore = () => {
    setColorLabels((prevColorLabels) => [
      ...prevColorLabels,
      {
        color_id: getNextUnusedColorID(prevColorLabels),
        label: "",
      },
    ]);
  };

  // from react-select {value, label}
  const onChangeColor = ({ index, colorOption }) => {
    if (isNullOrUndefined(colorLabels[index])) {
      // empty text should still work here
      return;
    }
    // add immer below
    const updatedColorLabels = produce(colorLabels, (draftState) => {
      draftState[index].color_id = colorOption.value;
    });
    // add immer above
    setColorLabels(updatedColorLabels);
    setHasChanged(true);
  };

  const onChangeText = ({ index, text }) => {
    if (!colorLabels[index]) {
      return;
    }
    const updatedColorLabels = produce(colorLabels, (draftState) => {
      draftState[index].label = text;
    });
    setColorLabels(updatedColorLabels);
    setHasChanged(true);
  };

  const onClickTrash = (index) => {
    setColorLabels(immutableDeleteElementAtIndex(colorLabels, index));
    setHasChanged(true);
  };

  const onClickSave = () => {
    const filteredLabels = colorLabels.filter((colorLabel) => getColorLabelLabel(colorLabel));
    const updatedSettings = {
      [BACKEND_SETTINGS_NAMES.COLOR_LABELS]: filteredLabels,
    };
    updateMasterAccountSettingsForFrontendAndBackend({
      isUpdatingExecutiveProfile: isUserDelegatedUser(selectedUser),
      masterAccount,
      updatedSettings,
      user: selectedUser,
    });
    setHasChanged(false);
  };

  return (
    <div id={APP_SETTINGS.COLOR_LABEL}>
      {colorLabels.map((colorLabel, index) => {
        return (
          <ColorLabelRow
            key={getColorLabelColorID(colorLabel)}
            colorLabel={colorLabel}
            onChangeColor={onChangeColor}
            onChangeText={onChangeText}
            allColorLabels={colorLabels}
            onClickTrash={onClickTrash}
            index={index}
            selectedUser={selectedUser}
          />
        );
      })}
      {hasColorLabelUsedAllColors(colorLabels) ? null : (
        <AddMoreButton onClick={onClickAddMore} />
      )}

      <SaveButton disabled={!hasChanged} onClick={onClickSave} />
    </div>
  );
}
