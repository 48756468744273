import React, { useState } from "react";

import { Check } from "react-feather";
import { POP_UP_CONTAINER_ID } from "../../services/elementIDVariables";
import { getTooltipStyles } from "../../lib/tooltipFunctions";
import { ColorOption } from "./types";
import { capitalizeFirstLetter } from "../../lib/stringFunctions";

interface ColorSelectorOptionProps {
  colorOption: ColorOption;
  isSelected: boolean;
  onClick: (string: string) => void;
}

export default function ColorSelectorOption({
  colorOption: { color, name, value },
  isSelected,
  onClick,
}: ColorSelectorOptionProps) {
  // Use state for these instead of a ref so that we recalculate the tooltip position
  // once mounted.
  const [hoveredElement, setHoveredElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );

  const wrapperElement = document.getElementById(POP_UP_CONTAINER_ID);

  const tooltipStyles = getTooltipStyles({
    hoveredElement,
    tooltipElement,
    wrapperElement,
    placement: "top",
    gap: 6,
    wrapperPadding: 5,
  });

  return (
    <div
      ref={(element) => setHoveredElement(element)}
      className="color-selector-option"
      onClick={() => onClick(value)}
    >
      <div
        ref={(element) => setTooltipElement(element)}
        className="color-selector-tooltip"
        style={tooltipStyles}
      >
        {capitalizeFirstLetter(name)}
      </div>
      <div className="color-selector-circle" style={{ backgroundColor: color }}>
        {isSelected ? (
          <div className="color-selector-checkmark">
            <Check size={12} color="white" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
