import classNames from 'classnames';
import React from 'react'
import StyleConstants from '../../services/globalVariables';
import { METRICS_VIEW, METRICS_VIEW_OPTIONS } from './metricsAccessorFunctions';
import { useSelector } from 'react-redux';

export default function ToggleForHourOrPercentage({ type, setType }) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const getLeftPositionForHighLight = () => {
    if (type === METRICS_VIEW.HOUR) {
      return "6px";
    }

    return "46px";
  };

  const determineResponseButtonStyle = (option) => {
    const isOptionSelected = option === type;

    return {
      fontWeight: "400",
      backgroundColor: "transparent",
      // backgroundColor: isOptionSelected ? "red" : "transparent",
      fontSize: 12,
      color: isOptionSelected 
        ?  isDarkMode ? StyleConstants.darkModeTextColor : StyleConstants.defaultFontColor
        : undefined,
      borderRadius: 6,
      width: "40px",
    };
  };
  return (
    <div
      className={classNames(
        "flex items-center select-metrics-hour-or-percentage px-1.5 relative",
        "duration-300",
        "h-9"
      )}
    >
      <div
        className={classNames("absolute", "duration-200")}
        style={{
          width: "40px",
          height: "25px",
          left: getLeftPositionForHighLight(),
          transition: "left 300ms",
          backgroundColor: isDarkMode ? "#444754" : "white",
          boxShadow:
            "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px",
          borderRadius: "6px",
        }}
      ></div>
      {METRICS_VIEW_OPTIONS.map((option, index) => {
        return (
          <div
            className="flex py-1.5 z-10"
            key={`metrics-internal-external-container-${index}`}
          >
            <button
              key={`metrics_internal_external${index}`}
              style={determineResponseButtonStyle(option)}
              onClick={() => setType(option)}
              className={classNames("hoverable-container-text duration-200")}
            >
              {option}
            </button>
          </div>
        );
      })}
    </div>
  );
}
