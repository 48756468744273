import React from "react";
import Login from "./login/index";

function AuthorizedLogin(props) {
  return (
    <div className="search-wrapper">
      <Login handleClose={props.handleClose} authenticated={true} />
    </div>
  );
}

export default AuthorizedLogin;
