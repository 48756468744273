import React, { useEffect, useMemo, useState } from "react";

import SettingsSubtitle from "../../common/settingsSubtitle";
import SocialLinkField from "./socialLinkField";
import {
  doesUrlIncludeProtocol,
  extractTelegramUsernameFromUrl,
  extractTwitterHandleFromUrl,
  extractYouTubeHandleFromUrl,
  validateGitHubUrl,
  validateLinkedInUrl,
  validateTelegramUsernameOrUrl,
  validateTwitterHandleOrUrl,
  validateWebsiteUrl,
  validateYouTubeUrlOrHandle
} from "../../../../lib/urlFunctions";
import backendBroadcasts from "../../../../broadcasts/backendBroadcasts";
import { BACKEND_BROADCAST_VALUES } from "../../../../lib/broadcastValues";
import { useMasterAccount } from "../../../../services/stores/SharedAccountData";
import { getSocialLinks, getUserEmail } from "../../../../lib/userFunctions";
import { SOCIAL_NETWORK_NAMES } from "../../../contact/contactVariables";
import { sanitizeString } from "../../../../lib/jsVariables";
import LinkedInHandle from "../../../contact/handleIcons/linkedInHandle";
import TwitterHandle from "../../../contact/handleIcons/twitterHandle";
import LinkHandle from "../../../contact/handleIcons/linkHandle";
import TelegramHandle from "../../../contact/handleIcons/telegramHandle";
import YouTubeHandle from "../../../contact/handleIcons/youTubeHandle";
import GitHubHandle from "../../../contact/handleIcons/gitHubHandle";
import { isUserFromMagicLink } from "../../../../services/maestro/maestroAccessors";

function getUrlForSocialNetwork(socialLinks: SocialLink[], socialNetworkName: string) {
  return socialLinks.find(socialLink => socialLink.social_network_name === socialNetworkName)?.url || "";
}

/**
 * The new value could be a handle (optionally starting with `@`) or a full URL.
 * The initial Twitter handle should start with `@`.
 */
function hasTwitterHandleOrUrlChanged(twitterHandleOrUrl: string, initialTwitterHandle: string) {
  if (twitterHandleOrUrl === initialTwitterHandle) {
    return false;
  }

  if (`@${twitterHandleOrUrl}` === initialTwitterHandle) {
    return false;
  }

  if (extractTwitterHandleFromUrl(twitterHandleOrUrl) === initialTwitterHandle) {
    return false;
  }

  return true;
}

/**
 * The new value could either be a username or a full URL.
 * The initial value should be the username.
 */
function hasTelegramUsernameOrUrlChanged(telegramUsernameOrUrl: string, initialTelegramUsername: string) {
  if (telegramUsernameOrUrl === initialTelegramUsername) {
    return false;
  }

  if (extractTelegramUsernameFromUrl(telegramUsernameOrUrl) === initialTelegramUsername) {
    return false;
  }

  return true;
}


/**
 * The new value could either be a handle or a full URL.
 * The initial value could be either a handle (starting with `@`) or a full URL.
 */
function hasYouTubeHandleOrUrlChanged(youTubeHandleOrUrl: string, initialYouTubeHandleOrUrl: string) {
  if (youTubeHandleOrUrl === initialYouTubeHandleOrUrl) {
    return false;
  }

  if (extractYouTubeHandleFromUrl(youTubeHandleOrUrl) === initialYouTubeHandleOrUrl) {
    return false;
  }

  return true;
}

interface SocialLinksProps {
  formRef: React.MutableRefObject<HTMLFormElement>
  isUpdatingExecutiveProfile?: boolean
  // TODO: Build a type for the User object.
  selectedUser?: any
  showErrorText: boolean
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SocialLinks({ formRef, isUpdatingExecutiveProfile, selectedUser, setHasChanged, setIsFormValid, showErrorText }: SocialLinksProps) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const initialSocialLinks: SocialLink[] = getSocialLinks({ masterAccount, user: selectedUser }) ?? [];

  const initialLinkedInUrl = getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.LINKEDIN);
  const initialTwitterHandle = extractTwitterHandleFromUrl(
    getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.TWITTER)
  ) || "";
  const initialWebsiteUrl = getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.WEBSITE);
  const initialTelegramUsername = extractTelegramUsernameFromUrl(
    getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.TELEGRAM)
  ) || "";
  const initialYouTubeUrl = getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.YOUTUBE);
  const initialYouTubeHandleOrUrl = extractYouTubeHandleFromUrl(initialYouTubeUrl) || initialYouTubeUrl;
  const initialGitHubUrl = getUrlForSocialNetwork(initialSocialLinks, SOCIAL_NETWORK_NAMES.GITHUB);

  const [linkedInUrl, setLinkedInUrl] = useState(initialLinkedInUrl);
  const [twitterHandleOrUrl, setTwitterHandleOrUrl] = useState(initialTwitterHandle);
  const [websiteUrl, setWebsiteUrl] = useState(initialWebsiteUrl);
  const [telegramUsernameOrUrl, setTelegramUsernameOrUrl] = useState(initialTelegramUsername);
  const [youTubeHandleOrUrl, setYouTubeHandleOrUrl] = useState(initialYouTubeHandleOrUrl);
  const [gitHubUrl, setGitHubUrl] = useState(initialGitHubUrl);

  const gitHubErrorText = useMemo(() => (
    gitHubUrl && validateGitHubUrl(gitHubUrl)
  ), [gitHubUrl]);
  const linkedInErrorText = useMemo(() => (
    linkedInUrl && validateLinkedInUrl(linkedInUrl)
  ), [linkedInUrl]);
  const twitterErrorText = useMemo(() => (
    twitterHandleOrUrl && validateTwitterHandleOrUrl(twitterHandleOrUrl)
  ), [twitterHandleOrUrl]);
  const telegramErrorText = useMemo(() => (
    telegramUsernameOrUrl && validateTelegramUsernameOrUrl(telegramUsernameOrUrl)
  ), [telegramUsernameOrUrl]);
  const youTubeErrorText = useMemo(() => (
    youTubeHandleOrUrl && validateYouTubeUrlOrHandle(youTubeHandleOrUrl)
  ), [youTubeHandleOrUrl]);
  const websiteErrorText = useMemo(() => (
    websiteUrl && validateWebsiteUrl(websiteUrl)
  ), [websiteUrl]);

  useEffect(() => {
    setHasChanged(
      linkedInUrl !== initialLinkedInUrl ||
      hasTwitterHandleOrUrlChanged(twitterHandleOrUrl, initialTwitterHandle) ||
      websiteUrl !== initialWebsiteUrl ||
      hasTelegramUsernameOrUrlChanged(telegramUsernameOrUrl, initialTelegramUsername) ||
      hasYouTubeHandleOrUrlChanged(youTubeHandleOrUrl, initialYouTubeHandleOrUrl) ||
      gitHubUrl !== initialGitHubUrl
    );
  }, [
    gitHubUrl,
    linkedInUrl,
    twitterHandleOrUrl,
    telegramUsernameOrUrl,
    youTubeHandleOrUrl,
    websiteUrl,
    initialGitHubUrl,
    initialLinkedInUrl,
    initialTwitterHandle,
    initialTelegramUsername,
    initialYouTubeHandleOrUrl,
    initialWebsiteUrl,
  ])

  useEffect(() => {
    setIsFormValid(!(
      gitHubErrorText || linkedInErrorText || twitterErrorText || telegramErrorText || youTubeErrorText || websiteErrorText
    ));
  }, [gitHubErrorText, linkedInErrorText, twitterErrorText, telegramErrorText, youTubeErrorText, websiteErrorText]);

  useEffect(() => {
    setLinkedInUrl(initialLinkedInUrl);
    setTwitterHandleOrUrl(initialTwitterHandle);
    setWebsiteUrl(initialWebsiteUrl);
    setTelegramUsernameOrUrl(initialTelegramUsername);
    setYouTubeHandleOrUrl(initialYouTubeHandleOrUrl);
    setGitHubUrl(initialGitHubUrl);
  }, [selectedUser?.email]);

  const buildSocialLinksPayload = () => {
    const socialLinks: SocialLink[] = [];

    if (gitHubUrl !== initialGitHubUrl) {
      const urlWithProtocol = gitHubUrl && (doesUrlIncludeProtocol(gitHubUrl) ? gitHubUrl : `https://${gitHubUrl}`);
      if (urlWithProtocol !== gitHubUrl) setGitHubUrl(urlWithProtocol);

      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.GITHUB, url: sanitizeString(urlWithProtocol)});
    }

    if (linkedInUrl !== initialLinkedInUrl) {
      const urlWithProtocol = linkedInUrl && (doesUrlIncludeProtocol(linkedInUrl) ? linkedInUrl : `https://${linkedInUrl}`);
      if (urlWithProtocol !== linkedInUrl) setLinkedInUrl(urlWithProtocol);

      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.LINKEDIN, url: sanitizeString(urlWithProtocol)})
    }

    if (twitterHandleOrUrl !== initialTwitterHandle) {
      let urlWithProtocol: string;

      if (!twitterHandleOrUrl) {
        urlWithProtocol = "";
      } else if (twitterHandleOrUrl.includes(".com/")) {
        urlWithProtocol = doesUrlIncludeProtocol(twitterHandleOrUrl)
          ? twitterHandleOrUrl
          : `https://${twitterHandleOrUrl}`;
      } else {
        urlWithProtocol = `https://twitter.com/${twitterHandleOrUrl}`;
      }
      
      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.TWITTER, url: sanitizeString(urlWithProtocol)})
    }

    if (telegramUsernameOrUrl !== initialTelegramUsername) {
      let urlWithProtocol: string;

      if (!telegramUsernameOrUrl) {
        urlWithProtocol = "";
      } else if (telegramUsernameOrUrl.includes("t.me")) {
        urlWithProtocol = doesUrlIncludeProtocol(telegramUsernameOrUrl)
          ? telegramUsernameOrUrl
          : `https://${telegramUsernameOrUrl}`;
      } else {
        urlWithProtocol = `https://t.me/${telegramUsernameOrUrl}`;
      }

      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.TELEGRAM, url: sanitizeString(urlWithProtocol)})
    }

    if (websiteUrl !== initialWebsiteUrl) {
      const urlWithProtocol = websiteUrl && (doesUrlIncludeProtocol(websiteUrl) ? websiteUrl : `https://${websiteUrl}`);
      if (urlWithProtocol !== linkedInUrl) setWebsiteUrl(urlWithProtocol);

      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.WEBSITE, url: sanitizeString(urlWithProtocol)})
    }

    if (youTubeHandleOrUrl !== initialYouTubeHandleOrUrl) {
      let urlWithProtocol: string;

      if (!youTubeHandleOrUrl) {
        urlWithProtocol = "";
      } else if (youTubeHandleOrUrl.startsWith("@")) {
        urlWithProtocol = `https://youtube.com/${youTubeHandleOrUrl}`;
      } else {
        urlWithProtocol = doesUrlIncludeProtocol(youTubeHandleOrUrl) ? youTubeHandleOrUrl : `https://${youTubeHandleOrUrl}`;
        if (urlWithProtocol !== youTubeHandleOrUrl) {
          setYouTubeHandleOrUrl(urlWithProtocol);
        }
      }

      socialLinks.push({social_network_name: SOCIAL_NETWORK_NAMES.YOUTUBE, url: sanitizeString(urlWithProtocol)});
    }

    return socialLinks;
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const socialLinks = buildSocialLinksPayload();
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.UPDATE_SOCIAL_LINKS, {
      isUpdatingExecutiveProfile,
      socialLinks,
      user: isUpdatingExecutiveProfile || isUserFromMagicLink({ user: selectedUser }) ?
        selectedUser :
        undefined,
    });
  };

  return (
    <>
      <SettingsSubtitle>Social Links</SettingsSubtitle>
      <div className="mb-5 secondary-text-color default-font-size">
        Changing your social links will update your booking page.
      </div>
      <form ref={formRef} className="flex flex-col gap-5 w-96" onSubmit={onSubmit}>
        <SocialLinkField
          errorText={showErrorText ? linkedInErrorText : null}
          handle={<LinkedInHandle />}
          label="LinkedIn"
          placeholder="E.g. linkedin.com/company/vimcal/"
          setValue={setLinkedInUrl}
          value={linkedInUrl}
        />
        <SocialLinkField
          errorText={showErrorText ? twitterErrorText : null}
          handle={<TwitterHandle />}
          label="Twitter/X"
          placeholder="E.g. @vimcal"
          setValue={setTwitterHandleOrUrl}
          value={twitterHandleOrUrl}
        />
        <SocialLinkField
          errorText={showErrorText ? websiteErrorText : null}
          handle={<LinkHandle />}
          label="Website"
          placeholder="E.g. vimcal.com"
          setValue={setWebsiteUrl}
          value={websiteUrl}
        />
        <SocialLinkField
          errorText={showErrorText ? telegramErrorText : null}
          handle={<TelegramHandle />}
          label="Telegram"
          placeholder="E.g. vimcal"
          setValue={setTelegramUsernameOrUrl}
          value={telegramUsernameOrUrl}
        />
        <SocialLinkField
          errorText={showErrorText ? youTubeErrorText : null}
          handle={<YouTubeHandle />}
          label="YouTube"
          placeholder="E.g. @vimcal"
          setValue={setYouTubeHandleOrUrl}
          value={youTubeHandleOrUrl}
        />
        <SocialLinkField
          errorText={showErrorText ? gitHubErrorText : null}
          handle={<GitHubHandle />}
          label="GitHub"
          placeholder="E.g. github.com/vimcal"
          setValue={setGitHubUrl}
          value={gitHubUrl}
        />
      </form>
    </>
  );
}
