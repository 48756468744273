import { Stripe } from "stripe-types";
import { STRIPE_ENDPOINTS } from "../../lib/endpoints";
import { getUserEmail } from "../../lib/userFunctions";
import { constructRequestURLV2 } from "../../services/api";
import { fetcherPost } from "../../services/fetcherFunctions";
import { getUserConnectedAccountToken } from "../../services/maestro/maestroAccessors";


export function uncancelSubscription(currentUser: User) {
  const url = constructRequestURLV2(STRIPE_ENDPOINTS.UNCANCEL_SUBSCRIPTION);
  return fetcherPost<{ subscription: Stripe.Subscription } | ErrorResponse>({
    url,
    email: getUserEmail(currentUser),
    connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
  });
}
