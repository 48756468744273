import JSZip from "jszip";
import { format } from "date-fns";
import { stringify } from "../../../node_modules/csv-stringify/dist/esm/sync.js";

import {
  createAbbreviationForTimeZone,
  handleError,
} from "../services/commonUsefulFunctions";
import {
  getEventAttendees,
  getEventConferenceURL,
  getEventCreatedAt,
  getEventEndJSDate,
  getEventLocation,
  getEventStartJSDate,
  getEventTitle,
  getEventType,
  getEventUpdatedAt,
} from "../services/eventResourceAccessors";
import { getEventDuration } from "./eventFunctions";
import { getAllTagsFromEvent, getHumanReadableTagName, getTagName } from "./tagsFunctions";
import {
  calculateMinutesSavedForEvent,
  getEventProposedChanges,
} from "./calendarAudit/functions";
import { AllCalendarsState, AllLoggedInUsersState, MasterAccountState } from "../services/stores/SharedAccountData";
import { EventToRender, isTypeOfGroupedRecurrence, MasterEventRecurrences, ProposedChanges } from "./calendarAudit/accessors";

export const SUPPORTED_IMAGE_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
];
export const SUPPORTED_VIDEO_FILE_TYPES = [
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
];
export const SUPPORTED_COMPRESSED_FILE_TYPES = [
  "application/zip",
  "application/x-zip-compressed",
];

/**
 * Note: this does not account for complex extensions like .tar.gz.
 */
export function getFileExtension(file: File) {
  const fileNameParts = file.name.split(".");
  return fileNameParts.length > 1
    ? fileNameParts[fileNameParts.length - 1]
    : "";
}

export function megabytesToBytes(megabytes: number) {
  return megabytes * 2 ** 20;
}

export function isFileSizeUnderMBLimit(file: File, limit: number) {
  return file.size <= megabytesToBytes(limit);
}

export function downloadInMemoryFile(file: File) {
  const downloadElement = document.createElement("a");
  downloadElement.style.display = "none";

  downloadElement.download = file.name;
  downloadElement.href = URL.createObjectURL(file);
  downloadElement.click();
}

interface CreateFileNameWithPrefixAndSuffixOptions {
  fileName: string;
  prefix?: string;
  suffix?: string;
}

/**
 * Adds the prefix to the start of the file name, and adds the suffix right before the file extension.
 */
export function createFileNameWithPrefixAndSuffix({
  fileName,
  prefix,
  suffix,
}: CreateFileNameWithPrefixAndSuffixOptions) {
  const fileNameParts = fileName.split(".");

  if (prefix) {
    fileNameParts[0] = prefix + fileNameParts[0];
  }

  if (suffix) {
    const indexToUpdate = Math.max(fileNameParts.length - 2, 0);
    fileNameParts[indexToUpdate] = fileNameParts[indexToUpdate] + suffix;
  }

  return fileNameParts.join(".");
}

interface ZipFileInput {
  name: string;
  content: string | Blob;
  type?: string;
}

export async function downloadFilesAsZip(
  files: ZipFileInput[],
  zipFileName = "files.zip",
) {
  try {
    // Create a new ZIP archive
    const zip = new JSZip();

    // Add each file to the zip
    files.forEach(({ name, content, type }) => {
      const blob =
        content instanceof Blob ? content : new Blob([content], { type });
      zip.file(name, blob);
    });

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipFile = new File([zipBlob], zipFileName);

    downloadInMemoryFile(zipFile);
  } catch (error) {
    handleError(error);
  }
}

export function createCSVForAuditEvents({
  allCalendars,
  allLoggedInUsers,
  eventsToRender,
  groupedRecurrences,
  masterAccount,
  masterEventRecurrences,
  onlyShowRecurring,
  proposedChanges,
  user,
}: {
  allCalendars: AllCalendarsState["allCalendars"],
  allLoggedInUsers: AllLoggedInUsersState["allLoggedInUsers"],
  eventsToRender: EventToRender[],
  groupedRecurrences: Record<string, VimcalEvent[]>,
  masterAccount: MasterAccountState["masterAccount"],
  masterEventRecurrences: MasterEventRecurrences,
  onlyShowRecurring: boolean,
  proposedChanges: ProposedChanges,
  user: User,
}) {
  const formattedEvents: {
    title: string,
    startDate: string,
    endDate: string,
    attendees: string,
    location: string,
    conferenceURL: string,
    occurrences: number | string, // header row is a string
    tags: string | string[], // header row is a string
    eventType: string,
    createdAt: string,
    updatedAt: string,
    duration: number | string, // header row is a string
    proposedDuration: number | string, // header row is a string
    minsSaved: number | string, // header row is a string
  }[] = eventsToRender
    .filter((eventOrGroupedRecurrence) => {
      return onlyShowRecurring
        ? isTypeOfGroupedRecurrence(eventOrGroupedRecurrence)
        : true;
    })
    .map((eventOrGroupedRecurrence) => {
      const masterEventID = isTypeOfGroupedRecurrence(eventOrGroupedRecurrence) ?
        eventOrGroupedRecurrence.masterEventID :
        null;
      const occurrences = masterEventID
        ? groupedRecurrences[masterEventID]?.sort((eventA, eventB) => (
          getEventStartJSDate(eventA) > getEventStartJSDate(eventB) ? -1 : 1
        ))
        : [{} as VimcalEvent];
      const event: VimcalEvent = masterEventID ?
        occurrences[0] :
        eventOrGroupedRecurrence as VimcalEvent; // TODO: Update how we want to render recurring
      const proposedChangesForEvent = getEventProposedChanges({
        event,
        proposedChanges,
      });

      return {
        title: getEventTitle(event),
        startDate:
          format(getEventStartJSDate(event), "h:mm") +
          " " +
          createAbbreviationForTimeZone(),
        endDate:
          format(getEventEndJSDate(event), "h:mm") +
          " " +
          createAbbreviationForTimeZone(),
        attendees: getEventAttendees(event)
          ?.map((attendee) => attendee.email)
          .join(", "),
        location: getEventLocation(event),
        conferenceURL: getEventConferenceURL(event),
        occurrences: occurrences?.length ?? "",
        tags: getAllTagsFromEvent({
          event,
          currentUser: user,
          user,
          allLoggedInUsers,
          masterAccount,
          allCalendars,
        })?.map((tag) => getHumanReadableTagName(getTagName(tag))),
        eventType: getEventType(event),
        createdAt: getEventCreatedAt(event),
        updatedAt: getEventUpdatedAt(event),
        duration: getEventDuration(event),
        proposedDuration: proposedChangesForEvent.duration,
        minsSaved: calculateMinutesSavedForEvent({
          event,
          masterEventRecurrences,
          numberOfDays: 90,
          proposedChanges,
        }),
      };
    });
  /* Add header name for CSV */
  formattedEvents.unshift({
    title: "Title",
    startDate: "Start Time",
    endDate: "End Time",
    attendees: "Attendees",
    location: "Location",
    conferenceURL: "Conference URL",
    occurrences: "Recurring Event Count",
    tags: "Tags",
    eventType: "Event type",
    createdAt: "Created at",
    updatedAt: "Updated at",
    duration: "Duration",
    proposedDuration: "Proposed duration",
    minsSaved: "Mins saved",
  });

  return stringify(formattedEvents);
}
