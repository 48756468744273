import React, { useEffect, useState } from "react";
import { constructRequestURL, isErrorResponse } from "../../../services/api";
import { handleError } from "../../../services/commonUsefulFunctions";
import CheckBoxSpinner from "../../spinners/checkboxSpinner";
import LinkIcon from "../../icons/linkIcon";
import { useIsMounted } from "../../../services/customHooks/useIsMounted";
import { SECOND_IN_MS } from "../../../services/globalVariables";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { getUserEmail } from "../../../lib/userFunctions";
import { useIsFixedSeating } from "../hooks";
import { copyContent } from "../../../services/appFunctions";
import { useDefaultPaymentMethod } from "../../../services/stores/finance";
import { TEAM_PLAN_ENDPOINTS } from "../../../lib/endpoints";
import { fetcherPost } from "../../../services/fetcherFunctions";
import { getUserConnectedAccountToken } from "../../../services/maestro/maestroAccessors";
import { isUserInFreeTrial } from "../../../lib/stateManagementFunctions";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";

interface ShareLinkProps {
  shareToken: string | null
  setShareToken: StateSetter<string | null>
  shouldHideShareText?: boolean
}

export default function ShareLink({
  shareToken,
  setShareToken,
  shouldHideShareText,
}: ShareLinkProps) {
  const [copyTimeout, setCopyTimeout] = useState<number>();
  const [hasClickedShare, setHasClickedShare] = useState(false);
  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const componentIsMounted = useIsMounted();
  const currentUser = useSelector((state) => state.currentUser);
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const needCard = useDefaultPaymentMethod(state => state.needCard);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const isFreeTrial = isUserInFreeTrial(masterAccount);

  const isFixedSeating = useIsFixedSeating();

  /* Clear the timeout on unmount */
  useEffect(() => {
    return () => {
      window.clearTimeout(copyTimeout);
    };
  }, [copyTimeout]);

  const handleShareClick = () => {
    /* Send request to get the token to copy */
    if (!shareToken && !isFetchingToken) {
      setIsFetchingToken(true);

      const path = TEAM_PLAN_ENDPOINTS.CREATE_SHARING_LINK;
      const url = constructRequestURL(path, true);

      fetcherPost<{ token: string } | ErrorResponse>({
        url,
        email: getUserEmail(currentUser),
        connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
      })
        .then((response) => {
          setIsFetchingToken(false);
          if (isEmptyObjectOrFalsey(response) || isErrorResponse(response) || !componentIsMounted.current) {
            return;
          }

          /* Set to state for repeated clicks */
          setShareToken(response.token);

          /* Copy the token */
          copyContent(`${window.location.origin}/signup?team=${response.token}`);

          /* Set clicked state to true */
          setHasClickedShare(true);
        })
        .catch((e) => {
          /* TODO: Display error somehow */
          setIsFetchingToken(false);
          handleError(e);
        });
    } else if (shareToken) {
      /* Copy the token -> if else to prevent async setState */
      copyContent(`${window.location.origin}/signup?team=${shareToken}`);

      const resetTimeout = window.setTimeout(() => {
        if (!componentIsMounted.current) {
          return;
        }

        setCopyTimeout(undefined);
      }, 3 * SECOND_IN_MS);

      /* Set clicked state to true */
      setCopyTimeout(resetTimeout);
    }
  };

  if (needCard && !isFreeTrial) {
    return null;
  }

  if (isFixedSeating) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {
        shouldHideShareText ? <></> : (
          <div>
            <div>
              Share Invite
            </div>
            <div className="group-scheduling-team-plans-text-dark">
              {isFixedSeating
                ? "Note that users will be unable to join your plan if all the seats are full.  The link expires in 14 days."
                : "Note that you will be charged for anyone who joins your team from this link.  The link expires in 14 days."}
            </div>
          </div>
        )
      }

      <div className="flex items-center mt-4">
        {
          hasClickedShare || shareToken ?
            <div
              className={classNames(
                "group-scheduling-team-plans-share-input group-scheduling-team-plans-text-dark",
                isDarkMode ? "dark-mode" : "",
              )}
            >
              {`${window.location.origin}/signup?team=${shareToken}`}
            </div> : null
        }
        <div
          className={classNames("group-scheduling-team-plans-add-seats-share-button", isDarkMode ? "dark-mode" : "")}
          onClick={handleShareClick}
        >
          {
            isFetchingToken ?
              <CheckBoxSpinner className="" color="#FFFFFF" hideRightSidePadding={false} size={16} />
              :
              <>
                {
                  copyTimeout ? null : (
                    <div className="mr-2" style={{ height: "16.8px" }}>
                      <LinkIcon />
                    </div>
                  )
                }
                <div>
                  {hasClickedShare || shareToken
                    ? (copyTimeout ? "Copied!" : "Copy Link")
                    : "Generate Invite Link"}
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
}
