import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { ChevronDown } from "react-feather";
import { Howl } from "howler";
import { TIMER_COMPLETE } from "../sharedVariables.ts";
import Mousetrap from "mousetrap";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import focusModeBroadcast from "../../broadcasts/focusModeBroadcast";
import BigClock from "../bigClock";
import { TIMER_IS_COUNTING_DOWN_ID, TIMER_IS_NOT_COUNTING_DOWN_ID, TIMER_IS_PAUSED_ID } from "../sharedVariables";
import CountdownTimerWorker from "../../webWorkers/countdownTimer.worker";

let countDownTimer = new CountdownTimerWorker();

const TYPE_FOCUS = "Focus";
const TYPE_BREAK = "Break";
export default function PomodoroSelect({ containerClassName, isPomodoro }) {
  const types = [TYPE_FOCUS, TYPE_BREAK];
  const componentIsMounted = useIsMounted();

  const defaultTimerDuration = 25;
  const [mode, setMode] = useState(types[0]);
  const [hasStarted, setStart] = useState(false);
  const [renderCount, setRender] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isPaused, setIsPause] = useState(false);

  const remainingMinutes = useRef(defaultTimerDuration);
  const selectedMinuteRef = useRef(defaultTimerDuration);
  const remainingSeconds = useRef(0);
  const isPausedRef = useRef(false);
  const hasStartedRef = useRef(false);

  const startTimer = () => {
    setStart(true);
    hasStartedRef.current = true;
    setPause(false);
    countDownTimer.postMessage({isActive: true});
  };

  const stopTimer = () => {
    setStart(false);
    hasStartedRef.current = false;
    setPause(false);
    countDownTimer.postMessage({isActive: false});

    // reset timer back to default
    resetTimer();

    removeTimerValueThroughoutApp();
  };

  const bindSpaceToStart = () => {
    Mousetrap.bind(["space"], function (e) {
      if (!componentIsMounted.current) {
        return;
      }

      if (!hasStartedRef.current) {
        startTimer();
      } else if (isPausedRef.current){
        setPause(false);
      } else {
        setPause(true);
      }
    });
  }

  const resetTimer = () => {
    remainingMinutes.current = selectedMinuteRef.current;
    remainingSeconds.current = 0;
  };

  useEffect(() => {
    focusModeBroadcast.subscribe("BIND_SPACE_TO_START", bindSpaceToStart);
    focusModeBroadcast.subscribe("STOP_FOCUS_MODE_TIMER", stopTimer);
    focusModeBroadcast.subscribe("PAUSE_FOCUS_MODE_TIMER", () => setPause(true));
    focusModeBroadcast.subscribe("RESUME_FOCUS_MODE_TIMER", () => setPause(false));

    countDownTimer.onmessage = (event) => {
      if (!componentIsMounted.current || !event?.data) {
        return;
      }

      const {
        timeInSeconds
      } = event.data;
      countdownOnTimer(timeInSeconds);
    };

    return () => {
      removeTimerValueThroughoutApp();
      focusModeBroadcast.unsubscribe("BIND_SPACE_TO_START");
      focusModeBroadcast.unsubscribe("STOP_FOCUS_MODE_TIMER");
      focusModeBroadcast.unsubscribe("PAUSE_FOCUS_MODE_TIMER");
      focusModeBroadcast.unsubscribe("RESUME_FOCUS_MODE_TIMER");
      
      Mousetrap.unbind(["space"]);
    };
  }, []);

  const countdownOnTimer = (timeInSeconds) => {
    if (isPausedRef.current || !hasStartedRef.current) {
      return;
    }

    if (remainingMinutes.current > 0 && remainingSeconds.current === 0) {
      remainingMinutes.current = remainingMinutes.current - 1;
    }

    if (remainingSeconds.current > 0) {
      remainingSeconds.current = remainingSeconds.current - 1;
    } else {
      remainingSeconds.current = 59;
    }

    if (remainingMinutes.current === 0 && remainingSeconds.current === 0) {
      // timer is done
      countDownComplete();
    } else {
      setTimerValueThroughOutApp();
    }

    setRender(timeInSeconds); // trigger re-render
  };

  const setTimerValueThroughOutApp = () => {
    const updatedCountdownValue = `${remainingMinutes.current < 10 ? `0${remainingMinutes.current}` : remainingMinutes.current}:${remainingSeconds.current < 10 ? `0${remainingSeconds.current}` : remainingSeconds.current}`;
    focusModeBroadcast.publish("SET_FOCUS_MODE_COUNT_DOWN_SETTING_CONTAINER", updatedCountdownValue);
    focusModeBroadcast.publish("SET_FLOATING_FOCUS_MODE_COUNTDOWN_TIMER", updatedCountdownValue);
    if (window?.vimcal?.setFocusModeTimerCountdown) {
      window?.vimcal?.setFocusModeTimerCountdown(updatedCountdownValue);
    }
  }

  const removeTimerValueThroughoutApp = () => {
    // wipe out value in electron and main app foreground
    focusModeBroadcast.publish("SET_FOCUS_MODE_COUNT_DOWN_SETTING_CONTAINER", null);
    focusModeBroadcast.publish("SET_FLOATING_FOCUS_MODE_COUNTDOWN_TIMER", null);
    if (window?.vimcal?.setFocusModeTimerCountdown) {
      window?.vimcal?.setFocusModeTimerCountdown("");
    }
  }

  const countDownComplete = () => {
    stopTimer();
    playTimerCompleteSound();
  };

  const modalCover = () => {
    if (!isMenuOpen) {
      return null;
    }

    return (
      <div
        onClick={() => setMenuOpen(false)}
        className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full z-index-1"
      ></div>
    );
  };

  const playTimerCompleteSound = (option) => {
    const sound = new Howl({
      src: TIMER_COMPLETE,
      html5: true, // to prevent cors error from external source
    });
    sound.play();
  };

  const onSetMinutes = (minutes) => {
    stopTimer();
    selectedMinuteRef.current = minutes;
    remainingMinutes.current = minutes;
  };

  const dropdownMenu = () => {
    const options = mode === TYPE_FOCUS ? [25, 45] : [5, 15];
    return (
      <div
        className={classNames(
          "w-28 large-blur absolute duration-200",
          "-right-24 top-2/3",
          "flex flex-col",
          isMenuOpen ? "height-fit-content" : "h-0",
          "z-10",
          "rounded-lg"
        )}
      >
        {options.map((option, index) => {
          return (
            <div
              key={`timer-dropdown-key-${option}`}
              className={classNames(
                "w-full",
                isMenuOpen ? "" : "h-0 invisible"
              )}
            >
              <div
                className={classNames(
                  "font-size-16 font-weight-300 py-2 cursor-pointer",
                  "text-white default-hover-blur-button",
                  index === 0 ? "rounded-t-lg" : "",
                  index === options.length - 1 ? "rounded-b-lg" : ""
                )}
                onClick={() => {
                  onSetMinutes(option);
                  setMenuOpen(false);
                }}
              >
                {option} mins
              </div>
              {index !== options.length - 1 ? (
                <div className="w-full height-1px bg-white opacity-20"></div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <div
        className={classNames(
          containerClassName ?? "",
          "p-4 rounded-md default-button-blur inline-flex",
          "mb-7"
        )}
      >
        {types.map((type, index) => {
          return (
            <div
              key={`pomodoro-option-${index}`}
              // style={this.determineResponseButtonStyle(response)}
              onClick={() => {
                setMode(type);
                stopTimer();
                if (type === TYPE_FOCUS) {
                  onSetMinutes(25);
                } else {
                  onSetMinutes(5);
                }
              }}
              className={classNames(
                "font-size-16 select-none font-weight-400",
                "duration-300",
                "cursor-pointer rounded-md",
                mode === type
                  ? "selected-option-background-color text-white"
                  : "text-gray-200 opacity-60 hover:opacity-100",
                "px-4 py-2",
                // index !== type.length - 1 ? "mr-4" : ""
              )}
            >
              {type}
            </div>
          );
        })}
      </div>
    );
  };

  const setPause = (shouldPause) => {
    setIsPause(shouldPause);
    isPausedRef.current = shouldPause;
  };

  const renderTimer = () => {
    const displayMinute = () => {
      if (remainingMinutes.current < 10) {
        return `0${remainingMinutes.current}`;
      } else {
        return remainingMinutes.current;
      }
    };

    const displaySeconds = () => {
      if (remainingSeconds.current < 10) {
        return `0${remainingSeconds.current}`;
      } else {
        return remainingSeconds.current;
      }
    };

    return (
      <div className="big-clock select-none flex items-center text-center justify-center relative">
        <div className="time-section-container">{displayMinute()}</div>:
        <div className="time-section-container">{displaySeconds()}</div>
        <ChevronDown
          size={36}
          className={classNames(
            "absolute -right-12 cursor-pointer duration-200",
            "z-10",
            "opacity-60 hover:opacity-100",
            isMenuOpen ? "rotate-upside-down" : ""
          )}
          onClick={() => setMenuOpen(!isMenuOpen)}
        />
        {dropdownMenu()}
      </div>
    );
  };

  const startButton = () => {
    return (
      <div
        style={{ lineHeight: "26px" }}
        className={classNames(
          "mt-10",
          "px-10 rounded-md",
          "h-14",
          "default-button-blur",
          "font-size-24 font-weight-400 selected-option-background-color text-white",
          "start-button transition-colors duration-200",
          "select-none cursor-pointer",
          "flex items-center"
        )}
        onClick={startTimer}
      >
        <div className="h-6 text-center flex items-center">{"Start"}</div>
      </div>
    );
  };

  const pauseStopButton = () => {
    return (
      <div
        className={classNames(
          "mt-10",
          "rounded-md",
          "h-14",
          "default-button-blur",
          "font-size-24 font-weight-400 selected-option-background-color text-white",
          "select-none cursor-pointer",
          "flex items-center"
        )}
      >
        <div
          className="w-32 flex justify-center"
          onClick={() => {
            setPause(!isPausedRef.current);
          }}
        >
          {isPaused ? (
            <div
              style={{ lineHeight: "26px" }}
              className="text-center h-6 w-32 flex justify-center opacity-80 hover:opacity-100 duration-200"
            >
              {"Resume"}
            </div>
          ) : (
            <div
              style={{ lineHeight: "26px" }}
              className="text-center h-6 w-32 flex justify-center opacity-80 hover:opacity-100 duration-200"
            >
              {"Pause"}
            </div>
          )}
        </div>

        <div className="bg-gray-300 width-1px h-5"></div>
        <div
          style={{ lineHeight: "26px" }}
          className="text-center h-6 w-32 flex justify-center opacity-80 hover:opacity-100 duration-200"
          onClick={() => stopTimer()}
        >
          {"Cancel"}
        </div>
      </div>
    );
  };

  const determineContainerID = () => {
    if (!hasStarted) {
      return TIMER_IS_NOT_COUNTING_DOWN_ID;
    }

    return isPausedRef.current ? TIMER_IS_PAUSED_ID : TIMER_IS_COUNTING_DOWN_ID;
  }

  if (isPomodoro) {
    return (
      <div 
        id={determineContainerID()} 
        className="flex flex-col items-center"
      >
        {renderOptions()}
        {renderTimer()}
        {hasStarted ? pauseStopButton() : startButton()}
        {modalCover()}
      </div>
    );
  } else {
    return <BigClock />
  }
}
