import React, { useState } from "react";

import Broadcast from "../../broadcasts/broadcast";
import { getCalendarOwnerEmail } from "../../services/calendarAccessors";
import { hasEventPreventDefault } from "../../services/commonUsefulFunctions";
import {
  BLUE_BUTTON,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  WHITE_BUTTON,
} from "../../services/globalVariables";
import { useAllCalendars } from "../../services/stores/SharedAccountData";
import CustomButton from "../customButton";
import { getInputStringFromEvent } from "../../lib/stringFunctions";

const CalenderInput = ({
  disabled,
  fieldName,
  newUserData,
  setNewUserData,
  value,
  autoFocus,
}) => {
  const determineFieldLabel = () => {
    if (fieldName === "email") {
      return "Email";
    }
    if (fieldName === "firstName") {
      return "First Name";
    }
    if (fieldName === "lastName") {
      return "Last Name";
    }

    return fieldName;
  };

  const onChange = (e) => {
    setNewUserData({ ...newUserData, [e.target.name]: getInputStringFromEvent(e) });
  };

  return (
    <div className="mt-4">
      <div className="default-font-size font-weight-300">
        {determineFieldLabel()}
      </div>
      <input
        className={"default-input-field mt-2"}
        value={value}
        onChange={onChange}
        style={{ width: "100%", padding: 10 }}
        name={fieldName}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </div>
  );
};

const ConvertCalendarToUser = ({ userCalendarId, closeModal }) => {
  const { allCalendars } = useAllCalendars();
  const [newUserData, setNewUserData] = useState({
    firstName: "",
    lastName: "",
  });

  const calendar = allCalendars[userCalendarId];
  const calendarEmail = getCalendarOwnerEmail(calendar);
  const inputFields = ["email", "firstName", "lastName"];

  const onSubmit = (e) => {
    hasEventPreventDefault(e);
    const { firstName, lastName } = newUserData;
    /* Show a message if fields are missing */
    /* Theoretically email should not be missing, but keeping in case */
    if (!calendarEmail || !firstName || !lastName) {
      let missingFields = [];
      !calendarEmail && missingFields.push("Email");
      !firstName && missingFields.push("First Name");
      !lastName && missingFields.push("Last Name");

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `Missing fields: ${missingFields.join(", ")}`
      );
      return;
    }

    Broadcast.publish(
      "CONVERT_CALENDAR_TO_USER",
      {
        email: calendarEmail,
        userCalendarId,
        firstName,
        lastName
      }
    );
    closeModal();
  };

  return (
    <div className="convert-calendar-container">
      <form className="convert-calendar-form flex flex-col" onSubmit={onSubmit}>
        {inputFields.map((field, idx) => (
          <CalenderInput
            key={`convert-user-modal-${field}-${idx}`}
            disabled={field === "email"}
            fieldName={field}
            newUserData={newUserData}
            setNewUserData={setNewUserData}
            value={field === "email" ? calendarEmail : newUserData.field}
            autoFocus={field === "firstName"}
          />
        ))}
        <div className="flex justify-end mt-8">
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={closeModal}
            label="Cancel"
          />
          <CustomButton
            buttonType={BLUE_BUTTON}
            onClick={() => null}
            label="Convert"
            className="ml-4"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ConvertCalendarToUser;
