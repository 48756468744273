import React from "react";
import { isOutOfOfficeEvent } from "../../lib/eventFunctions";
import GlobalKeyMapTile from "../globalKeyMapTile";
import ShortcutHoverHint from "../shortcutHoverHint";
import { Mail } from "react-feather";

export default function Email({
  event,
  shouldHide,
  onClickEmail,
  containerStyle = {},
	hoverHintStyle={},
  iconClassName=null,
  keyMapStyle=null,
  hideShortCut
}) {
  if (isOutOfOfficeEvent(event)) {
    return null;
  }

  if (shouldHide) {
    return null;
  }

  return (
    <ShortcutHoverHint
      left
      style={hoverHintStyle}
      title={"Email"}
      shortcut={"GE"}
      containerStyle={containerStyle}
    >
      <GlobalKeyMapTile shouldHide={hideShortCut} style={keyMapStyle ?? { top: -20, left: -10, width: 35 }} shortcut={"GE"} />

      <Mail
        className={iconClassName ?? "clickable-icon mt-0.5"}
        size="16"
        onClick={onClickEmail}
      />
    </ShortcutHoverHint>
  );
}
