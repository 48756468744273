import React from "react";

const AvailabilityIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 22">
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M18.746 1.25h-16.5c-.828 0-1.5.672-1.5 1.5v16.5c0 .828.672 1.5 1.5 1.5h18c.828 0 1.5-.672 1.5-1.5v-9M.746 5.75L13.496 5.75"/>
      <path d="M22.782 5.465l-5.723 5.723-2.813.562.563-2.812 5.723-5.723c.297-.298.7-.465 1.122-.465.42 0 .824.167 1.121.465l.007.006c.619.62.619 1.624 0 2.244h0zM12 8.978L12 11.983M4.152 8.978L4.152 16.692M8 8.978L8 16.692"/>
    </g>
  </svg>;

export default AvailabilityIcon;
