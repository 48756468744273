import { addMinutes, parseISO } from "date-fns";
import { getTimeInAnchorTimeZone, guessTimeZone, isInt } from "../services/commonUsefulFunctions";
import { createTemporaryEvent } from "./availabilityFunctions";
import { removeDuplicateEventsBasedOnTime } from "./eventFunctions";
import { getUserName } from "./userFunctions";

const INVITEE_FORMAT = {
  COMBINED: "combined"
}

export function isCalendlyText(text) {
  const regex =
    /(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday),\s(?:January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2}(?:\s|$)((?:\d{1,2}):\d{2}\s?[ap]m\n)*/gi;
  const hasDateAndTimesStacked = regex.test(text);
  return hasDateAndTimesStacked;
}

export function getCalendlyDataFromText(text, defaultTimeZone) {
  if (!isCalendlyText(text)) {
    return {};
  }

  return {
    date: getCalendlyDateFromText(text),
    duration: getCalendlyDurationFromText(text),
    timeZone: getCalendlyIANATimeZone(text, defaultTimeZone),
  };
}

function getCalendlyDateFromText(text) {
  // this is so we can parse calendly links
  // gets the date that's above the times above
  const regex =
    /(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday),\s(?:January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2}(?:,\s\d{4})?(?:\s|$)((?:\d{1,2}):\d{2}\s?[ap]m\n)*/gi;
  const matchedDateAndTimes = text.match(regex);

  if (matchedDateAndTimes && matchedDateAndTimes.length > 0) {
    const dateAndTimes = matchedDateAndTimes[0];
    const dateRegex =
      /(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday),\s(?:January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2}(?:,\s\d{4})?/;
    const dateMatch = dateAndTimes.match(dateRegex);
    const date = dateMatch ? dateMatch[0] : null;
    return date;
  } else {
    return null;
  }
}

function getCalendlyDurationFromText(text) {
  const regex = /(\d+)\s?min/gi;
  const matchedMinutes = text.match(regex);

  if (matchedMinutes && matchedMinutes.length > 0) {
    const minutes = matchedMinutes[0].replace(/\s?min/gi, "");
    return isInt(minutes) ? minutes : 30;
  } else {
    return 30;
  }
}

function getCalendlyIANATimeZone(text, defaultTimeZone) {
  if (!text) {
    return defaultTimeZone ?? guessTimeZone();
  }

  const loweredText = text.toLowerCase();
  if (loweredText.includes("eastern time")) {
    return "America/New_York";
  }

  if (loweredText.includes("central time")) {
    return "America/Chicago";
  }

  if (loweredText.includes("mountain time")) {
    return "America/Denver";
  }

  if (loweredText.includes("pacific time")) {
    return "America/Los_Angeles";
  }

  if (loweredText.includes("alaska time")) {
    return "America/Anchorage";
  }

  if (loweredText.includes("hawaii time")) {
    return "Pacific/Honolulu";
  }

  return defaultTimeZone ?? guessTimeZone();
}

export function getParsedEventsAndMetaDataFromCalendlyLink({
  response, // response from backend
  currentTimeZone,
  currentUser,
  masterAccount
}) {
  if (!response || !response.calendly_response) {
    return null;
  }

  const {
    calendly_response,
    duration,
    name, // name of sender
    // description,
    location,
    invitee_name_format,
  } = response;
  const {
    days
  } = calendly_response;
  const guardedDuration = duration || 30;
  if (!days || days.length === 0) {
    // early return
    return null;
  }

  let allAvailabilities = [];
  days.forEach((d) => {
    allAvailabilities = allAvailabilities.concat(d.spots);
  });
  allAvailabilities = allAvailabilities.filter((a) => a.status === "available");

  const guessedTimeZone = guessTimeZone();
  const allAvailabilitiesJSDate = allAvailabilities.map((a) => {
    const parsedStartTime = parseISO(a.start_time);
    if (currentTimeZone && currentTimeZone !== guessedTimeZone) {
      return getTimeInAnchorTimeZone(
        parsedStartTime,
        guessedTimeZone,
        currentTimeZone
      )
    }

    return parsedStartTime;
  });
  const allEvents = allAvailabilitiesJSDate.map((time, index) => {
    return createTemporaryEvent({
      startTime: time,
      endTime: addMinutes(time, guardedDuration),
      index,
      hideCancel: true,
      isTemporaryAIEvent: true,
      isGroupVote: false
    });
  });
  const parsedEvents = removeDuplicateEventsBasedOnTime(allEvents);
  return {
    parsedEvents,
    duration: guardedDuration,
    location,
    title: invitee_name_format === INVITEE_FORMAT.COMBINED ? `${name} <> ${getUserName({masterAccount, user: currentUser}).fullName}` : null
  };
}
