import React from "react";

export default function ZoomBlueIcon() {
  return (
    <div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_62_124)">
          <mask id="mask0_62_124" maskUnits="userSpaceOnUse" x="-33" y="-29" width="158" height="90">
            <path d="M-32.1569 -28.0785H124.706V60.0785H-32.1569V-28.0785Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_62_124)">
            <mask id="mask1_62_124" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
              <path d="M16 32.0001C24.8366 32.0001 32.0001 24.8366 32.0001 16C32.0001 7.16346 24.8366 0 16 0C7.16346 0 0 7.16346 0 16C0 24.8366 7.16346 32.0001 16 32.0001Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_62_124)">
              <path d="M-0.784302 -0.784302H32.7844V32.7844H-0.784302V-0.784302Z" fill="#E5E5E4"/>
            </g>
            <mask id="mask2_62_124" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
              <path d="M16 31.6863C24.6633 31.6863 31.6863 24.6633 31.6863 16C31.6863 7.33672 24.6633 0.313721 16 0.313721C7.33672 0.313721 0.313721 7.33672 0.313721 16C0.313721 24.6633 7.33672 31.6863 16 31.6863Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_62_124)">
              <path d="M-0.470581 -0.470581H32.4706V32.4706H-0.470581V-0.470581Z" fill="white"/>
            </g>
            <mask id="mask3_62_124" maskUnits="userSpaceOnUse" x="1" y="1" width="30" height="30">
              <path d="M16 30.4314C23.9703 30.4314 30.4314 23.9702 30.4314 16C30.4314 8.02976 23.9703 1.5686 16 1.5686C8.02979 1.5686 1.56863 8.02976 1.56863 16C1.56863 23.9702 8.02979 30.4314 16 30.4314Z" fill="white"/>
            </mask>
            <g mask="url(#mask3_62_124)">
              <path d="M0.784363 0.784302H31.2158V31.2157H0.784363V0.784302Z" fill="#4A8CFF"/>
            </g>
            <mask id="mask4_62_124" maskUnits="userSpaceOnUse" x="6" y="10" width="20" height="12">
              <path d="M20.3922 14.127L24.4706 11.1466C24.8267 10.8564 25.0981 10.9239 25.0981 11.4603V20.549C25.0981 21.1513 24.7593 21.0823 24.4706 20.8627L20.3922 17.8823V14.127ZM6.58826 11.4823V18.2588C6.59199 18.9944 6.88772 19.6985 7.41043 20.2162C7.93313 20.7339 8.64003 21.0229 9.37571 21.0196H19.2581C19.3918 21.02 19.5202 20.9674 19.6151 20.8733C19.7101 20.7792 19.7639 20.6513 19.7647 20.5176V13.7411C19.761 13.0054 19.4653 12.3013 18.9426 11.7836C18.4199 11.2659 17.713 10.977 16.9773 10.9803H7.09492C6.96123 10.9799 6.83283 11.0325 6.73786 11.1266C6.64288 11.2207 6.58909 11.3486 6.58826 11.4823Z" fill="white"/>
            </mask>
            <g mask="url(#mask4_62_124)">
              <path d="M5.80396 10.196H25.8824V21.8039H5.80396V10.196Z" fill="white"/>
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_62_124">
            <rect width="32" height="32" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>
  );
}
