import React, { useState } from "react";
import AddImage from "../../icons/addImage";
import { Edit2 } from "react-feather";
import AvatarEditor from "react-avatar-editor";
import RangeInput from "../../rangeInput";
import { useSelector } from "react-redux";
import { SUPPORTED_IMAGE_FILE_TYPES, isFileSizeUnderMBLimit } from "../../../lib/fileFunctions";
import { getInputStringFromEvent } from "../../../lib/stringFunctions";
import { handleError } from "../../../services/commonUsefulFunctions";

const PROFILE_PHOTO_MB_LIMIT = 5;
const ERROR_PHOTO_TOO_LARGE = `Your photo must be under ${PROFILE_PHOTO_MB_LIMIT}MB in size`;
const ERROR_INVALID_PHOTO_TYPE = "Your photo must be a JPG or a PNG";

interface ProfilePhotoInputProps {
  errorText?: string
  originalProfilePhotoSrc?: string | null
  profilePhotoCropperRef: React.RefObject<AvatarEditor>
  profilePhotoFile?: File
  setProfilePhotoFile: React.Dispatch<React.SetStateAction<File | undefined>>
}

export default function ProfilePhotoInput({ errorText, originalProfilePhotoSrc, profilePhotoCropperRef, profilePhotoFile, setProfilePhotoFile }: ProfilePhotoInputProps) {
  // Warrning text is displayed to the user, but doesn't prevent submitting the form.
  const [warningText, setWarningText] = useState<string>();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];

    if (file && !isFileSizeUnderMBLimit(file, PROFILE_PHOTO_MB_LIMIT)) {
      setWarningText(ERROR_PHOTO_TOO_LARGE);
    } else if (file && !SUPPORTED_IMAGE_FILE_TYPES.includes(file.type)) {
      setWarningText(ERROR_INVALID_PHOTO_TYPE);
    } else {
      setWarningText(undefined);
      setProfilePhotoFile(file);
    }
  };

  return (
    <div className="profile-photo-input mb-5">
      <input
        className="hidden"
        id="profile-photo-input"
        type="file"
        onChange={onChange}
        accept={SUPPORTED_IMAGE_FILE_TYPES.join(", ")}
      />
      {profilePhotoFile
        ? <ImageCropper image={profilePhotoFile} imageCropperRef={profilePhotoCropperRef} />
        : <ImageInput photoPreviewSrc={originalProfilePhotoSrc} />}
      {(errorText || warningText) && <div className="warning-color default-font-size mt-1">{errorText || warningText}</div>}
    </div>
  );
}

interface ImageInputProps {
  photoPreviewSrc?: string | null
}

function ImageInput({ photoPreviewSrc }: ImageInputProps) {

  return (
    <label htmlFor="profile-photo-input">
      {photoPreviewSrc
        ? <img className="h-full w-full rounded-full object-cover object-center" src={photoPreviewSrc} />
        : <AddImage className="mb-0.5" />}
      {photoPreviewSrc ? <div className="edit-photo-button"><Edit2 size={10} /></div> : null}
    </label>
  );
}

interface ImageCropperProps {
  image: File
  imageCropperRef: React.RefObject<AvatarEditor>
}

function ImageCropper({ image, imageCropperRef }: ImageCropperProps) {
  const [scale, setScale] = useState(1);
  const isDarkMode = useSelector(state => state.isDarkMode);

  return (
    <div className="flex flex-col items-center gap-2 w-20 relative">
      <AvatarEditor
        ref={imageCropperRef}
        image={image}
        backgroundColor="#FFFFFF"
        border={0}
        borderRadius={75}
        className="rounded-lg"
        color={isDarkMode ? undefined : [255, 255, 255, 0.7]}
        height={75}
        width={75}
        scale={scale}
      />
      <label htmlFor="profile-photo-input">
        <div className="edit-photo-button"><Edit2 size={10} /></div>
      </label>
      <div className="leading-0 px-1">
        <RangeInput
          className="w-full"
          min={1}
          max={3}
          step={0.01}
          onChange={e => setScale(parseFloat(getInputStringFromEvent(e)))}
          value={scale}
        />
      </div>
    </div>
  );
}

export function extractImage(photoCropperRef: React.RefObject<AvatarEditor>, photoFile?: File) {
  let croppedProfilePhoto: HTMLCanvasElement | undefined;

  try {
    croppedProfilePhoto = photoCropperRef.current?.getImage();
  } catch (error) {
    // One known cause of an error here: saving an empty text file with a .jpg extension and
    // trying to upload that will throw an error. It's possible that there are other instances
    // where a mismatched extension and file type would cause this error.
    // Let the rest of the form submit when this error arises.
    // https://vimcal.sentry.io/issues/4982866306/?project=2190664
    const sentryContext = {
      extra: {
        fileName: photoFile?.name,
        // Note: this is inferred from the extension, and isn't necessarily the actual file type.
        fileType: photoFile?.type,
        fileSize: photoFile?.size,
      },
    };
    handleError(error, sentryContext);
  }

  return croppedProfilePhoto;
}
