import { createColorsListWithDefault } from "../../services/googleColors";
import { determineCalendarColor, getEmailFromUserCalendarID } from "../../lib/calendarFunctions";
import { getEventUserCalendarID, getEventUserEventID } from "../../services/eventResourceAccessors";
import { createUpdatedSingleEventWithColor } from "../../lib/mimicEventUpdate";
import { ColorOption } from "./types";
import { AllCalendarsState, StoredCalendar } from "../../services/stores/SharedAccountData";

export function getGoogleColors(currentCalendar: StoredCalendar): ColorOption[] {
  const defaultColor = determineCalendarColor(currentCalendar);
  return Object.values(createColorsListWithDefault(defaultColor)).map(({ color, id, name }) => ({
    color,
    name,
    value: id,
  }));
}

type OnClickGoogleOptions = {
  allCalendars: AllCalendarsState["allCalendars"]
  colorId: string
  event: VimcalGoogleEvent
}

export function constructGoogleEventData({ allCalendars, colorId, event }: OnClickGoogleOptions) {
  const eventData = {
    user_calendar_id: getEventUserCalendarID(event),
    calendar_event: {
      colorId: colorId,
      provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars,
      ),
    },
    user_event_id: getEventUserEventID(event),
  };

  const temporaryEventData: VimcalGoogleEvent = createUpdatedSingleEventWithColor({
    event,
    calendarColor: determineCalendarColor(allCalendars[getEventUserCalendarID(event)]),
    colorID: colorId,
  });

  return { eventData, temporaryEventData };
}
