import classNames from "classnames";
import React from "react";

/**
 * A label component that indicates executive status
 * @param {Object} props
 * @param {string} [props.className] - Additional CSS class names to apply
 * @returns {JSX.Element} Executive label component
 */
export default function ExecutiveLabel({className}) {
  return (
    <div className={classNames("executive-label", "select-none", className)}>Exec</div>
  );
}
