import React, { useState } from "react";
import CustomButton from "../../customButton";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../../services/globalVariables";
import GoogleDrivePermissionRole from "./permissionRole";
import SpinnerV2 from "../../spinnerV2";

export const GOOGLE_DRIVE_PERMISSION_TYPES = {
  USER: "user", // Share with people
  GROUP: "group", // Not seen in G-cal
  DOMAIN: "domain", // Not seen in G-cal
  ANYONE: "anyone", // Allow anyone with the link to access
} as const;

export const GOOGLE_DRIVE_PERMISSION_ROLES = {
  OWNER: "owner", // Not seen in G-cal
  ORGANIZER: "organizer", // Not seen in G-cal
  FILE_ORGANIZER: "fileOrganizer", // Not seen in G-cal
  WRITER: "writer", // Editor
  COMMENTER: "commenter", // Commenter
  READER: "reader", // Viewer
} as const;
export const GOOGLE_DRIVE_DONT_GIVE_ACCESS = "GOOGLE_DRIVE_DONT_GIVE_ACCESS"; // Not an actual permission type

export type GoogleDrivePermissions = {
  role: ValueOf<typeof GOOGLE_DRIVE_PERMISSION_ROLES>;
  type:
    | ValueOf<typeof GOOGLE_DRIVE_PERMISSION_TYPES>
    | typeof GOOGLE_DRIVE_DONT_GIVE_ACCESS;
};

type GoogleDrivePermissionsModalProps = {
  isSubmittingDrivePermissions: boolean
  onClickDismiss: () => void
  onClickSave: (permissions: GoogleDrivePermissions) => void
}

export default function GoogleDrivePermissionsModal({
  isSubmittingDrivePermissions,
  onClickDismiss,
  onClickSave,
}: GoogleDrivePermissionsModalProps) {
  const [permissions, setPermissions] = useState<GoogleDrivePermissions>({
    role: GOOGLE_DRIVE_PERMISSION_ROLES.READER,
    type: GOOGLE_DRIVE_PERMISSION_TYPES.USER,
  });

  return (
    <div className="flex flex-col overflow-hidden gap-5">
      <GoogleDrivePermissionRole
        parentPermissions={permissions}
        setParentPermissions={setPermissions}
        type={GOOGLE_DRIVE_PERMISSION_TYPES.USER}
      />
      <GoogleDrivePermissionRole
        parentPermissions={permissions}
        setParentPermissions={setPermissions}
        type={GOOGLE_DRIVE_PERMISSION_TYPES.ANYONE}
      />
      <GoogleDrivePermissionRole
        parentPermissions={permissions}
        setParentPermissions={setPermissions}
        type={GOOGLE_DRIVE_DONT_GIVE_ACCESS}
      />
      <div className="flex justify-end">
        <CustomButton
          buttonType={WHITE_BUTTON}
          addPaddingToRight={true}
          onClick={onClickDismiss}
          label="Cancel"
        />
        <CustomButton
          buttonType={BLUE_BUTTON}
          shouldFocus={true}
          onClick={() => onClickSave(permissions)}
          label={isSubmittingDrivePermissions ? <SpinnerV2 variant="small" /> : "Save Event"}
        />
      </div>
    </div>
  );
}
