import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  backfillMetricsDataBack12Weeks,
  bulkFetchMetricsReports,
  EXTERNAL_INTERNAL_CONSTS,
  getHumanReadableTimeInMinutes,
  getMetricsData,
  getMetricsMeetingsBreakdown,
  getMetricsMeetingTypeExternal,
  getMetricsMeetingTypeInternal,
  getMetricsStartDate,
  getPastWeeksExternalMetrics,
  getPastWeeksInternalMetrics,
  getSpecialTagDisplayColorForMetrics,
  getWeekNumberAndYear,
  hasEveryWeekForLast12Weeks,
  hasExplicitExternalTag,
  hasExplicitInternalTag,
} from "./metricsAccessorFunctions";
import { useSelector } from "react-redux";
import {
  getCalendarGridColor,
  getDefaultSecondaryTextColor,
} from "../../lib/styleFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import {
  handleError,
  isValidJSDate,
} from "../../services/commonUsefulFunctions";
import { getHumanReadableTagName } from "../../lib/tagsFunctions";
import {
  removeDuplicatesFromArray,
} from "../../lib/arrayFunctions";
import {
  DEFAULT_PRIMARY_CALENDAR_COLOR,
  SECOND_IN_MS,
} from "../../services/globalVariables";
import CheckBox from "../checkbox";
import produce from "immer";
import MetricsSkeletonLoadingPanel from "./metricsSkeletonLoadingPanel";
import { useMetricsStore } from "../../services/stores/metricsStore";
import { isSameDay } from "date-fns";
import { SPECIAL_TAGS_TYPE } from "../../lib/vimcalVariables";
import { isEmptyArrayOrFalsey } from "../../services/typeGuards";
import { isSameEmail } from "../../lib/stringFunctions";
import { getUserEmail } from "../../lib/userFunctions";
import { isUserDelegatedUser } from "../../services/maestroFunctions";

// rechart takes in data like following:
export default function MetricsLineGraph({
  period,
  startDate,
  user,
  showSkeletonScreen,
}) {
  const [graphData, setGraphData] = useState([]);
  const [allKeys, setAllKeys] = useState([]);
  const last12Weeks = useMetricsStore((state) => state.last12Weeks);
  const [breakdownDictionary, setBreakdownDictionary] = useState({}); //{id: {color, name}}
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const componentIsMounted = useIsMounted();
  const currentUser = useSelector((state) => state.currentUser);
  const getUser = () => user || currentUser;

  // note: we need to watch for oversetting graph data because by resetting it, we override any annimation effects
  const isFirstRender = useRef(true);
  const lastFetchHistoricalPeriodAndDate = useRef({}); // so we don't overfetch

  useEffect(() => {
    if (isEmptyArrayOrFalsey(last12Weeks) || !hasEveryWeekForLast12Weeks(last12Weeks)) {
      // since this is on initial load, if we have stale data -> fetch again
      lastFetchHistoricalPeriodAndDate.current = { period, startDate, userEmail: getUserEmail(getUser()) };
      fetchHistoricalData({ inputPeriod: period, inputStartDate: startDate });
      return;
    }
    fetchHistoricalData({ inputData: last12Weeks });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // for first render, we handle it in the previous useEffect
      isFirstRender.current = false;
      return;
    }
    if (
      lastFetchHistoricalPeriodAndDate.current.period === period &&
      isValidJSDate(lastFetchHistoricalPeriodAndDate.current.startDate) &&
      isSameDay(lastFetchHistoricalPeriodAndDate.current.startDate, startDate)
      && isSameEmail(lastFetchHistoricalPeriodAndDate.current.userEmail, getUserEmail(getUser()))
    ) {
      return;
    }
    fetchHistoricalData({ inputPeriod: period, inputStartDate: startDate });
    lastFetchHistoricalPeriodAndDate.current = { period, startDate, userEmail: getUserEmail(getUser()) };
  }, [period, startDate, user, getUserEmail(getUser())]);

  const fetchHistoricalData = async ({
    inputData,
    inputPeriod,
    inputStartDate,
  }) => {
    try {
      if (!isEmptyArrayOrFalsey(graphData)) {
        setGraphData([]);
      }
      const getReports = async () => {
        if (!isEmptyArrayOrFalsey(inputData)) {
          return inputData;
        }
        const {
          weekNumber,
          year,
        } = getWeekNumberAndYear(inputStartDate ?? startDate);
        const userForBackendCall = getUser();
        return bulkFetchMetricsReports({
          period: inputPeriod ?? period,
          periodNumber: weekNumber,
          year,
          currentUser: userForBackendCall,
          delegatedUserEmail: isUserDelegatedUser(userForBackendCall) ? getUserEmail(userForBackendCall) : null,
        });
      };

      const reports = await getReports();
      if (!componentIsMounted.current) {
        return;
      }
      if (isEmptyArrayOrFalsey(reports)) {
        return;
      }
      const filtered = reports.filter(
        (report) => !!getMetricsData(report)
      );
      let updatedKeys = [];
      const updatedBreakdownIndex = produce(
        breakdownDictionary,
        (draftState) => {
          const graphData = filtered.map((report) => {
            const data = getMetricsData(report);
            const breakdowns = getMetricsMeetingsBreakdown({ data });
            const date = getMetricsStartDate({ response: report });
            const periodData = {
              date,
            };
            const hasInternalTagInBreakdown =
              hasExplicitInternalTag(breakdowns);
            const hasExternalTagInBreakdown =
              hasExplicitExternalTag(breakdowns);
            const allPastWeeksBreakdowns = filtered.map((response) =>
              getMetricsMeetingsBreakdown({ data: getMetricsData(response) })
            );
            if (!hasInternalTagInBreakdown) {
              // only add if there's no explicit internal tag
              const matchingBreakdownInternalMetrics =
                getPastWeeksInternalMetrics({
                  pastWeeksBreakdowns: allPastWeeksBreakdowns,
                });
              const id =
                matchingBreakdownInternalMetrics?.id ||
                SPECIAL_TAGS_TYPE.INTERNAL;
              draftState[id] = {
                color: matchingBreakdownInternalMetrics
                  ? getSpecialTagDisplayColorForMetrics({
                      breakDown: matchingBreakdownInternalMetrics,
                      currentUser: getUser(),
                    })
                  : EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor,
                name: getHumanReadableTagName(SPECIAL_TAGS_TYPE.INTERNAL),
                isSelected: draftState[id]?.isSelected ?? true,
              };
              periodData[id] = getMetricsMeetingTypeInternal({ data });
              updatedKeys = updatedKeys.concat(id);
            }
            if (!hasExternalTagInBreakdown) {
              const matchingBreakdownExternalMetrics =
                getPastWeeksExternalMetrics({
                  pastWeeksBreakdowns: allPastWeeksBreakdowns,
                });
              const id =
                matchingBreakdownExternalMetrics?.id ||
                SPECIAL_TAGS_TYPE.EXTERNAL;
              draftState[id] = {
                color: matchingBreakdownExternalMetrics
                  ? getSpecialTagDisplayColorForMetrics({
                      breakDown: matchingBreakdownExternalMetrics,
                      currentUser: getUser(),
                    })
                  : EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor,
                name: getHumanReadableTagName(SPECIAL_TAGS_TYPE.EXTERNAL),
                isSelected: draftState[id]?.isSelected ?? true,
              };
              periodData[id] = getMetricsMeetingTypeExternal({ data });
              updatedKeys = updatedKeys.concat(id);
            }

            // Use id as key for each breakdown item since name can be unique
            breakdowns.forEach((breakDown) => {
              const { id, category_name: categoryName, time } = breakDown;
              const name = getHumanReadableTagName(categoryName);
              updatedKeys = updatedKeys.concat(id);
              if (!draftState[id] || draftState[id].date < date) {
                // opt for most recent
                draftState[id] = {
                  color: getSpecialTagDisplayColorForMetrics({
                    breakDown,
                    currentUser: getUser(),
                  }),
                  name,
                  isSelected: draftState[id]?.isSelected ?? true,
                };
              }
              periodData[id] = time || 0;
            });
            return periodData;
          });
          const filteredAllKeys = removeDuplicatesFromArray(updatedKeys);
          graphData.forEach((item) => {
            filteredAllKeys.forEach((key) => {
              if (!item[key]) {
                item[key] = 0;
              }
            });
          });
          const withBackfillData = backfillMetricsDataBack12Weeks({
            existingData: graphData,
            initialDate: inputStartDate ?? startDate,
          });
          setGraphData(withBackfillData);
          setAllKeys(filteredAllKeys);
        }
      );
      setBreakdownDictionary(updatedBreakdownIndex);
    } catch (error) {
      handleError(error);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className="rounded-md metrics-select-calendar-modal p-2.5 default-font-size z-10">
          <p className="secondary-text-color mb-1.5">{`${label}`}</p>
          {payload.map((item, index) => {
            return (
              <div
                className="flex items-center gap-1.5"
                key={item?.name || index}
              >
                <div
                  className="h-2 w-2 rounded-full"
                  style={{
                    backgroundColor: getProtectedColor(
                      breakdownDictionary[item?.name]?.color
                    ),
                  }}
                ></div>
                <div className="w-20 truncate-text" style={{ maxWidth: 80 }}>
                  {`${getProtectedName(breakdownDictionary[item?.name]?.name)}`}
                </div>
                <div className="font-weight-400">
                  {getHumanReadableTimeInMinutes(item?.value)}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const CustomLegend = (props) => {
    return null;
    // const { payload } = props;
    // return (
    //   <ul style={{ color: "orange" }}>
    //     {payload.map((entry, index) => (
    //       <li key={`item-${index}`}>{entry.value}</li>
    //     ))}
    //   </ul>
    // );
  };

  const CustomLineDot = (props) => {
    const { cx, cy, payload, value, dataKey } = props;
    const [hover, setHover] = useState(false);
    const getDotRadius = () => {
      if (hover) {
        return 4;
      }
      return 0;
    };
    return (
      <circle
        cx={cx}
        cy={cy}
        r={getDotRadius()}
        fill={getProtectedColor(breakdownDictionary[dataKey]?.color)}
        opacity={breakdownDictionary[dataKey]?.isSelected ? 1 : 0.3}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    );
  };

  const getProtectedColor = (color) => {
    if (!color || color === "transparent") {
      return DEFAULT_PRIMARY_CALENDAR_COLOR;
    }
    return color;
  };

  const getProtectedName = (name) => {
    return name || "Untitled";
  };

  const renderLegendItems = () => {
    return (
      <div className="mt-3 flex gap-6 flex-wrap" style={{ paddingLeft: 65 }}>
        {allKeys.map((key, index) => {
          const color = getProtectedColor(breakdownDictionary[key]?.color);
          const isSelected = breakdownDictionary[key]?.isSelected ?? true;
          const onToggle = () => {
            const updated = produce(breakdownDictionary, (draftState) => {
              if (draftState[key]) {
                draftState[key].isSelected = !isSelected;
              }
            });
            setBreakdownDictionary(updated);
          };
          return (
            <div
              key={key}
              className="flex items-center gap-2 cursor-pointer relative select-none"
              onClick={onToggle}
            >
              <CheckBox
                isChecked={isSelected}
                borderColor={color}
                backgroundColor={isSelected ? color : "transparent"}
              />
              <div className="default-font-size cursor-pointer">
                {getProtectedName(breakdownDictionary[key]?.name)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getMaxValue = (data, keys) => {
    let max = 0;
    data.forEach((item) => {
      keys.forEach((key) => {
        if (item[key] && item[key] > max) {
          max = item[key];
        }
      });
    });
    return max;
  };

  const calculateTickInterval = (maxValue) => {
    const maxHours = maxValue / 60; // Convert to hours
    if (maxHours <= 10) {
      return 60; // 1 hour
    }
    if (maxHours <= 20) {
      return 120; // 2 hours
    }
    if (maxHours <= 50) {
      return 300; // 5 hours
    }
    if (maxHours <= 100) {
      return 600; // 10 hours
    }
    return 1800; // 30 hours
  };

  const maxValue = getMaxValue(graphData, allKeys);
  const tickInterval = calculateTickInterval(maxValue);
  const ticks = Array.from(
    { length: Math.ceil(maxValue / tickInterval) + 1 },
    (_, i) => i * tickInterval
  );

  if (isEmptyArrayOrFalsey(graphData) || showSkeletonScreen) {
    return (
      <div className="w-full metrics-data-container mt-5">
        <MetricsSkeletonLoadingPanel width={"100%"} height={300} />
        <div className="flex gap-4 items-center mt-2">
          <MetricsSkeletonLoadingPanel width={100} height={20} />
          <MetricsSkeletonLoadingPanel width={100} height={20} />
          <MetricsSkeletonLoadingPanel width={100} height={20} />
        </div>
      </div>
    );
  }

  return (
    <div className="default-font-size w-full metrics-data-container mt-5 padding-left-0px-important">
      <div className="metrics-secondary-text-color font-weight-400 metrics-default-section-header mb-4 ml-10">
        Meeting Breakdown — Trailing 12 Weeks
      </div>
      <LineChart width={760} height={300} data={graphData} className="z-10">
        <CartesianGrid
          stroke={getCalendarGridColor(isDarkMode)}
          strokeDasharray="3 3"
          vertical={false}
        />
        <XAxis
          dataKey="date"
          stroke={getCalendarGridColor(isDarkMode)}
          tick={{ fill: getDefaultSecondaryTextColor(isDarkMode) }} // X-axis labels color
        />
        <YAxis
          stroke={getCalendarGridColor(isDarkMode)}
          tick={{ fill: getDefaultSecondaryTextColor(isDarkMode) }}
          tickFormatter={(value) => value / 60}
          label={{
            value: "Hours",
            angle: -90,
            position: "insideLeft",
            fill: getDefaultSecondaryTextColor(isDarkMode),
            dx: 12, // Adjust this value to move the label closer or further
          }}
          domain={[0, maxValue]}
          ticks={ticks}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        {allKeys.map((key, index) => {
          return (
            <Line
              key={`${key}`}
              type="monotone"
              dataKey={key}
              stroke={getProtectedColor(breakdownDictionary[key]?.color)}
              opacity={breakdownDictionary[key]?.isSelected ? 1 : 0.3}
              dot={<CustomLineDot />}
              animationDuration={SECOND_IN_MS}
              animationEasing="ease-in-out"
            />
          );
        })}
      </LineChart>

      {renderLegendItems()}
    </div>
  );
}
