import React from "react";
import { useSelector } from "react-redux";
import { DEFAULT_FONT_COLOR } from "../../services/globalVariables";
import ShortcutTiles from "./shortcutTiles";

interface OnboardingShortcutTilesProps {
  shortcut: string
}

export default function OnboardingShortcutTiles({ shortcut }: OnboardingShortcutTilesProps) {
  const isDarkMode = useSelector(state => state.isDarkMode);

  if (isDarkMode) {
    return <ShortcutTiles shortcut={shortcut} />;
  }

  return (
    <ShortcutTiles
      shortcut={shortcut}
      backgroundColor="transparent"
      border={`1px solid ${DEFAULT_FONT_COLOR}`}
      color={DEFAULT_FONT_COLOR}
    />
  );
}
