import React from "react";
import { Info } from "react-feather";
import { ALERT_TYPE, MORE_INFO_ICON_SIZE } from "./utils";
import classNames from "classnames";

export interface AlertProps {
  className?: string
  excludeDefaultMargins?: boolean
  subText?: React.ReactNode
  title?: React.ReactNode
  variant?: ValueOf<typeof ALERT_TYPE>
}

const VARIANT_CLASS_NAMES: Record<ValueOf<typeof ALERT_TYPE>, string> = {
  [ALERT_TYPE.CRITICAL]: "critical-alert",
  [ALERT_TYPE.WARNING]: "warning-alert",
  [ALERT_TYPE.DID_YOU_KNOW]: "did-you-know-alert",
};

export default function Alert({ className, excludeDefaultMargins, title, subText, variant=ALERT_TYPE.WARNING }: AlertProps) {
  const variantClassName = VARIANT_CLASS_NAMES[variant];
  const marginClassName = excludeDefaultMargins ? "" : "my-4";

  // need to use align-item when there's no title but subtext since otherwise the icon and text are not aligned
  return (
    <div className={classNames("default-alert-container", variantClassName, marginClassName, className, !title && subText ? "alert-subtitle-only" : "")}>
      <Info className="alert-more-info-icon" size={MORE_INFO_ICON_SIZE} />
      <div>
        {title ? <div className="font-normal">{title}</div> : null}
        {subText ? <div className={classNames("font-light", title ? "mt-1" : "")}>{subText}</div> : null}
      </div>
    </div>
  );
}
