import React from "react";

const Beach = ({ className, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21H4"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.905 3.66609V3.66609C18.786 5.31309 20.833 9.59309 19.679 13.6481L19.604 13.9131C19.276 15.0681 18.01 15.6731 16.905 15.2041L5.21699 10.2421C4.11199 9.77309 3.66799 8.44209 4.27099 7.40309L4.40899 7.16409C6.52499 3.51909 11.025 2.01809 14.905 3.66609Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.905 3.66602L15.164 4.71302C15.933 7.82802 15.258 11.123 13.325 13.684V13.684"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.905 3.66602L13.972 4.20702C11.197 5.81702 9.29602 8.59302 8.79602 11.762V11.762"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.06 12.72L7.54999 21"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Beach;
