export function getBackendSubscriptionStripeSubID(backendSubscription: Optional<BackendSubscription>) {
  return backendSubscription?.stripe_sub_id;
}

export function getBackendSubscriptionIsFixedSeating(backendSubscription: null | undefined): undefined
export function getBackendSubscriptionIsFixedSeating(backendSubscription: BackendSubscription): boolean
export function getBackendSubscriptionIsFixedSeating(backendSubscription: BackendSubscription | null): undefined | boolean
export function getBackendSubscriptionIsFixedSeating(backendSubscription: Optional<BackendSubscription>) {
  return backendSubscription?.is_fixed_seating;
}

export function getBackendSubscriptionCoreSeatCount(backendSubscription: null | undefined): undefined
export function getBackendSubscriptionCoreSeatCount(backendSubscription: BackendSubscription): number
export function getBackendSubscriptionCoreSeatCount(backendSubscription: Optional<BackendSubscription>) {
  return backendSubscription?.non_ea_seat_count;
}

export function getBackendSubscriptionEASeatCount(backendSubscription: null | undefined): undefined
export function getBackendSubscriptionEASeatCount(backendSubscription: BackendSubscription): number
export function getBackendSubscriptionEASeatCount(backendSubscription: Optional<BackendSubscription>) {
  return backendSubscription?.ea_seat_count;
}
