import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TrialLabel from "../../onboarding/trialLabel";
import {
  getDaysLeftOnTrial,
  isInOnboarding,
  getDefaultPaymentMethod,
  userNeedsTypeForm,
  isWaitingToBeOnboarded,
  isAccountReferred,
} from "../../../lib/stateManagementFunctions";
import {
  useSubscriptionStore,
  useDefaultPaymentMethod,
} from "../../../services/stores/finance";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import classNames from "classnames";
import { hasBookedOnboarding } from "../../../services/accountFunctions";
import { shouldShowTrialActive } from "../../../lib/userFunctions";
import layoutBroadcast from "../../../broadcasts/layoutBroadcast";
import { APP_SETTINGS } from "../../../lib/vimcalVariables";
import { isSelfServeOpen } from "../../../lib/featureFlagFunctions";
import { TYPEFORM_ROUTE } from "../../../services/routingFunctions";
import { isUserMaestroUser } from "../../../services/maestroFunctions";

class SideMenuBarToolBarSection extends Component {
  constructor(props) {
    super(props);

    this.openBilling = this.openBilling.bind(this);
    this.goToJoinPage = this.goToJoinPage.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.rerouteOnLoad();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="side-menu-tool-bar-section-wrapper">
        <div className="side-menu-tool-bar-text">Account</div>

        {this.renderBillingOption()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderBillingOption() {
    const shouldGoToJoinPage = this.pushToTrialOverPage();
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      subscription
    } = this.props.subscriptionStore;
    return (
      <div 
        className={classNames(shouldGoToJoinPage 
          ? "pl-5 flex items-center flex-row relative cursor-pointer h-10 side-menu-content-hover-background-color" 
          : "side-menu-toolbar-list-item" 
        )} 
        onClick={shouldGoToJoinPage ? this.goToJoinPage : this.openBilling}
      >
        <div className="use-template-icon">
          <div className="side-menu-description-text">{"Billing"}</div>

          {shouldGoToJoinPage ? (
            <TrialLabel
              daysLeft={getDaysLeftOnTrial({
                subscription,
                masterAccount
              })}
              additionalClassNames="trial-days-left-margin-left"
            />
          ) : null}
        </div>
      </div>
    );
  }

  pushToTrialOverPage() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const defaultPaymentMethod = this.getDefaultPaymentMethod();
    return shouldShowTrialActive({masterAccount, defaultPaymentMethod})
  }

  goToJoinPage() {
    layoutBroadcast.publish("SHOW_TRIAL_IS_OVER_MODAL")
  }


  //================
  // EVENT HANDLERS
  //================

  getDefaultPaymentMethod() {
    const {
      defaultPaymentMethod
    } = this.props.defaultPaymentMethodStore;
    return defaultPaymentMethod ||
      getDefaultPaymentMethod();
  }

  rerouteOnLoad() {
    // if user gets to home, we shouldn't reroute them
    return;
    const {
      masterAccount
    } = this.props.masterAccount;
    if (isSelfServeOpen()) {
      if (isInOnboarding(masterAccount)) {
        if (hasBookedOnboarding()) {
          return;
        }
  
        if (!isUserMaestroUser(masterAccount)) {
          // do not send maestro users to onboarding
          this.props.history.push("/welcome");
        }
      }
    } else {
      // with self serve closed
      if (userNeedsTypeForm(masterAccount)) {
        this.props.history.push(`/${TYPEFORM_ROUTE}`);
        return;
      }

      if (isAccountReferred(masterAccount)) {
        this.props.history.push("/welcome");
        return
      }
      
      if (isWaitingToBeOnboarded(masterAccount)) {
        this.props.history.push("/welcome");
        return;
      }
    }
  }

  openBilling() {
    // New users are initialized with a trialed stripe subscription without a default payment method.
    // The first time a user submits a default payment method is when they select the monthly or annual
    // subscription option on /join.
    // We only need to show the /join page until the user explicitly selects their option of choice.
    // We assume that having a default payment method implies that the user has explicitly chosen an option.
    this.props.setShouldDisplayMenu(false);
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {initialContent: APP_SETTINGS.BILLING});
  }
}

function mapStateToProps(state) {
  let { currentUser } = state;

  return {
    currentUser,
  };
}

const withStore = (BaseComponent) => (props) => {
  const subscriptionStore = useSubscriptionStore();
  const defaultPaymentMethodStore = useDefaultPaymentMethod();
  const masterAccount = useMasterAccount();

  return (
    <BaseComponent
      {...props}
      subscriptionStore={subscriptionStore}
      defaultPaymentMethodStore={defaultPaymentMethodStore}
      masterAccount={masterAccount}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setShouldDisplayMenu: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_DISPLAY_MENU" })
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(SideMenuBarToolBarSection)));
