import React, { PureComponent } from "react";
import ReactSelectAttendeeAutoComplete from "./reactSelectAttendeeAutoComplete";
import {
  KEYCODE_ESCAPE,
  removeDuplicatesFromArray,
  KEYCODE_K,
  KEYCODE_C,
  isMac,
  KEYCODE_SEMI_COLON,
} from "../services/commonUsefulFunctions";
import { X, Minus, Plus } from "react-feather";
import Broadcast from "../broadcasts/broadcast";
import {
  AVAILABILITY_ADDITIONAL_DETAILS_ID,
  COMPONENT_NOTIFICATION,
  SLOTS_DETAIL_FIELDS,
  MEETING_DETAIL_ID,
  HIDE_SHOW_AVAILABILITY_DETAILS,
  ALL_BOOKING_VARIABLES,
} from "../services/globalVariables";
import { connect } from "react-redux";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import LocationSearchInput from "../components/locationSearchInput";
import { createWritableCalendarList } from "../lib/stateManagementFunctions";
import EventTitleInput from "./eventTitleInput";
import CustomSelect from "./select";
import {
  createConferencingOptions,
  getZoomPersonalLink,
  isDefaultZoomPersonalLink,
  isValidPhoneConferencing,
} from "../lib/conferencing";
import GoogleCalendarService, {
  BACKEND_ZOOM,
  BACKEND_PHONE,
  ZOOM_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION,
  BACKEND_HANGOUT,
} from "../services/googleCalendarService";
import classnames from "classnames";
import EventFormDescription from "./eventFormDescription";
import MoreInformationHint from "./moreInformationHint";
import { getValidUserConferencingOptions } from "../lib/conferencing";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
  useZoomSchedulers,
} from "../services/stores/SharedAccountData";
import {
  doesCalendarHaveEditableRole,
  getCalendarName,
  getCalendarUserEmail,
  getOutlookCalendarDefaultOnlineMeetingProvider,
  getUserCalendar,
  isCalendarOutlookCalendar,
} from "../lib/calendarFunctions";
import CheckBox from "./checkbox";
import BlockedCalendarSection from "./availability/blockedCalendarSection";
import { getBlockedCalendars } from "../services/accountFunctions";
import {
  doesListOfQuestionsIncludeCompany,
  doesTitleIncludeCompanyQuestion,
  getAllExtraBookingVariables,
  getBlockedCalendarsID,
} from "../lib/availabilityFunctions";
import DefaultSwitch from "./defaultSwitch";
import { COMBINE_ADJACENT_SLOTS_ID } from "../services/elementIDVariables";
import {
  convertOutlookConferencingToHumanReadable,
  isOutlookConferencingOption,
} from "../lib/outlookFunctions";
import { getMatchingExecutiveCalendar, getMatchingExecutiveUserFromCalendar, isCalendarExecutiveCalendar, isUserBeingScheduledFor, isUserMaestroUser, shouldHideDelegatedUser } from "../services/maestroFunctions";
import { getReactSelectBaseStyle } from "./select/styles";
import { createCalendarReactSelectOptions, filterForCalendarSelect, getMinutesList } from "./select/helpFunctions";
import DropdownIndicator from "./select/dropDownIndicator";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { APP_SETTINGS, CONFERENCING_SETTINGS_ID, REACT_ATTENDEE_SELECT_LOCATION, SLOTS_ELEMENT } from "../lib/vimcalVariables";
import GroupScheduling from "./scheduling/groupScheduling";
import { blurCalendar, getEventKeyCode, getSelectedTextInApp } from "../services/appFunctions";
import { getGoogleCalendarAllowedConferenceType } from "../services/calendarAccessors";
import { getZoomSchedulers } from "../services/zoomFunctions";
import FindTimesHoverableText from "./availability/findTimesHoverableText";
import { isEmptyArray } from "../lib/arrayFunctions";
import ColoredLine from "./line";
import IgnoreConflicts from "./availability/ignoreConflicts";
import { getUserEmail } from "../lib/userFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { equalAfterTrimAndLowerCased, isSameEmail, isValidEmail } from "../lib/stringFunctions";
import IgnoreInternalConflicts from "./availability/ignoreInternalConflicts";
import CreatableSelect from "../../components/creatableSelect";
import { getObjectEmail } from "../lib/objectFunctions";
import ExecutiveLabel from "../../components/executiveLabel";

const {
  TITLE_DETAIL,
  DURATION_DETAIL,
  CONFERENCING_DETAIL,
  ATTENDEES_DETAIL,
  SELECTED_CALENDAR_DETAIL,
  LOCATION_DETAIL,
  DESCRIPTION_DETAIL,
} = SLOTS_DETAIL_FIELDS;

const { ZOOM_STRING, PHONE_NUMBER_CONFERENCE } = GoogleCalendarService;

const SUGGESTION_WIDTH = 330;
const MARGIN_LEFT_ON_JUSTIFY_BETWEEN = 280;

class AvailabilityAdditionalDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.attendees = React.createRef();
    this.name = React.createRef();
    this.email = React.createRef();

    //* used to flash component on auto change
    this.notificationTimer = null;
    this._titleClassName = null;
    this._durationClassName = null;
    this._conferencingClassName = null;
    this._attendeesClassName = null;
    this._calendarClassName = null;
    this._locationClassName = null;
    this._descriptionClassName = null;

    const allWritableCalendarsForSelect = this.getWritableCalendars();
    this.state = {
      attendees: [],
      suggestions: [],
      refs: {},
      section: null,
      isMac: isMac(),
      location: "",
      conferencingOptions: this.getInitialConferencingOptions(),
      allWritableCalendarsForSelect,
      showDetails: false,
      hideDescription:
        props.hideInitDescription &&
        (!this.props.description || this.props.description.length === 0),
      calendarSelectOptions: this.createCalendarReactSelectOptions(allWritableCalendarsForSelect),
    };

    this.addAttendees = this.addAttendees.bind(this);
    this.onKeyDownEscape = this.onKeyDownEscape.bind(this);
    this.onKeyDownSelect = this.onKeyDownSelect.bind(this);
    this.addEmailsIntoDetail = this.addEmailsIntoDetail.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.blurElement = this.blurElement.bind(this);
    this.focusAvailabilityElement = this.focusAvailabilityElement.bind(this);
    this.onSelectConferencing = this.onSelectConferencing.bind(this);
    this.setConferenceAsZoom = this.setConferenceAsZoom.bind(this);
    this.flashChanges = this.flashChanges.bind(this);
    this.toggleShowDetails = this.toggleShowDetails.bind(this);
    this.isConferencingAndHasPersonalLink =
      this.isConferencingAndHasPersonalLink.bind(this);
    this.showDescription = this.showDescription.bind(this);

    if (!props.ignoreBroadcast) {
      AvailabilityBroadcast.subscribe("ADD_ATTENDEE", this.addEmailsIntoDetail);
      AvailabilityBroadcast.subscribe(
        "FOCUS_AVAILABILITY_ELEMENT",
        this.focusAvailabilityElement
      );
      AvailabilityBroadcast.subscribe(
        "SET_CREATE_AVAILABILITY_CONFERENCE",
        this.setConferenceAsZoom
      );
      AvailabilityBroadcast.subscribe(
        "FLASH_SLOTS_DETAIL_CHANGES",
        this.flashChanges
      );
      AvailabilityBroadcast.subscribe(
        "TOGGLE_AVAILABILITY_DETAILS",
        this.toggleShowDetails
      );
      AvailabilityBroadcast.subscribe(
        "SHOW_SLOTS_DESCRIPTION",
        this.showDescription
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkForDefaultAttendees();
  }

  componentDidUpdate(prevProps) {
    this.checkForUpdateAttendeeName(prevProps);
    this.checkForUpdateSelectedUser(prevProps);
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.notificationTimer);
    this.notificationTimer = null;

    if (!this.props.ignoreBroadcast) {
      AvailabilityBroadcast.unsubscribe("ADD_ATTENDEE");
      AvailabilityBroadcast.unsubscribe("FOCUS_AVAILABILITY_ELEMENT");
      AvailabilityBroadcast.unsubscribe("SET_CREATE_AVAILABILITY_CONFERENCE");
      AvailabilityBroadcast.unsubscribe("FLASH_SLOTS_DETAIL_CHANGES");
      AvailabilityBroadcast.unsubscribe("TOGGLE_AVAILABILITY_DETAILS");
      AvailabilityBroadcast.unsubscribe("SHOW_SLOTS_DESCRIPTION");
    }
  }

  render() {
    const { shouldHide, showHideDetails } = this.props;

    const { showDetails } = this.state;

    if (shouldHide) {
      return null;
    }
    const { isSlots, containerClassName } = this.props;

    if (this.props.showOnlyDurationAndAttendees) {
      return (
        <div
          id={AVAILABILITY_ADDITIONAL_DETAILS_ID}
          className="position-relative availability-additional-details"
        >
          {this.props.isSlots ? this.renderAttendees() : null}
          {this.renderDurationTitle()}
        </div>
      );
    }

    return (
      <div
        id={AVAILABILITY_ADDITIONAL_DETAILS_ID}
        className={classnames("position-relative availability-additional-details", containerClassName || "")}
      >
        {this.renderEventTitle()}
        {this.props.isSlots ? this.renderAttendees() : null}
        {this.props.showFindTimesForEveryone
          ? this.renderFindTimesForEveryone()
          : null}
        {this.renderDurationTitle()}
        {this.renderCombineAdjacentTimeSlots()}
        {this.renderConferencing()}
        {isSlots ? null : this.renderBlockedCalendarsSection()}
        {isSlots ? this.renderMeetingDetailPreviewSection() : null}
        {isSlots ? this.renderLocation() : null}
        {!showHideDetails || showDetails ? this.renderOtherDetails() : null}
      </div>
    );
  }

  renderConflictCalendarSection() {
    const { hideBlockedCalendars, isSlotsPlainText, isSlots } = this.props;
    if (hideBlockedCalendars || isSlotsPlainText) {
      return null;
    }
    if (!isSlots) {
      return null;
    }
    const renderLine = () => {
      return (
        <div className={"my-4 -ml-5 -mr-5"}>
          <ColoredLine />
        </div>
      );
    };
    return (
      <div className="relative">
        {renderLine()}
        {this.renderIgnoreConflicts()}
        {this.renderIgnoreInternal()}
        {this.renderBlockedCalendarsSection()}
        {renderLine()}
      </div>
    );
  }

  renderIgnoreInternal() {
    const onChange = (ignoreInternal) => {
      if (this.props.setUpdateIngoreInternalConflicts) {
        this.props.setUpdateIngoreInternalConflicts(ignoreInternal);
        blurCalendar();
      }
    };
    return (
      <IgnoreInternalConflicts
        onChange={onChange}
        isIgnoreInternalConflicts={this.props.isIgnoreInternalConflicts}
        className={"mt-4"}
        selectedUser={this.getUser()}
      />
    );
  }

  renderIgnoreConflicts() {
    const onChange = (isIgnoreConflicts) => {
      if (this.props.onChangeIgnoreConflicts) {
        this.props.onChangeIgnoreConflicts(isIgnoreConflicts);
      }
    };
    return (
      <IgnoreConflicts 
        onChange={onChange}
        isIgnoreConflicts={this.props.isIgnoreConflicts}
      />
    );
  }

  renderBlockedCalendarsSection() {
    const {
      hideBlockedCalendars,
      justifyBetweenLabel,
      blockedCalendars,
      onSelectBlockingCalendars,
      blockedCalendarsID,
      isSlotsPlainText,
      isIgnoreConflicts,
    } = this.props;

    if (hideBlockedCalendars || isSlotsPlainText) {
      return null;
    }

    const isInModal = !!justifyBetweenLabel;
    const user = this.getUser();
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const blockedCalendarsFromUser = getBlockedCalendars({ user, allLoggedInUsers, allCalendars, masterAccount });

    return (
      <BlockedCalendarSection
        blockedCalendars={blockedCalendars ?? blockedCalendarsFromUser}
        blockedCalendarsID={
          blockedCalendarsID ?? getBlockedCalendarsID(blockedCalendarsFromUser)
        }
        onSelectBlockingCalendars={onSelectBlockingCalendars}
        containerClassNameOverride={
          isInModal ? null : "flex mt-4 justify-between"
        }
        isInModal={isInModal}
        titleClassNameOverride={
          isInModal
            ? "availability-detail-section-text margin-right-250px"
            : null
        }
        inputPreviewContainerClassNameOverride={isInModal ? "left-32" : null}
        isDisabledCheckForConflict={isIgnoreConflicts}
        hideEdit={isIgnoreConflicts}
        skipHover={isIgnoreConflicts}
      />
    );
  }

  renderOtherDetails() {
    const { isSlots } = this.props;
    return (
      <>
        {isSlots ? null : this.renderAttendees()}
        {this.renderSelectCalendar()}
        {isSlots ? null : this.renderLocation()}
        {this.renderConflictCalendarSection()}
        {this.renderDescription()}
        {this.shouldShowRescheduleToggle() ? this.renderRescheduling() : null}
      </>
    );
  }

  shouldShowRescheduleToggle() {
    if (this.props.hideReschedulingOption) {
      return false;
    }

    return this.props.showHideDetails || !this.state.showDetails;
  }

  renderLocation() {
    return (
      <div
        className={classnames(
          "slots-row-container-medium slots-row-default-margin-top",
          "display-flex-flex-direction-row align-items-center",
          this.props.justifyBetweenLabel ? "justify-between" : ""
        )}
      >
        <div
          className={classnames(
            "default-font-size font-weight-300",
            this.props.justifyBetweenLabel ? "" : "mr-6",
            "secondary-text-color"
          )}
        >
          Location
        </div>

        <LocationSearchInput
          width={this.props.justifyBetweenLabel ? SUGGESTION_WIDTH : 212}
          focusRef={(input) => {
            this.location = input;
          }}
          onChange={this.setLocation}
          defaultLocation={this.props.location}
          customClassName={classnames(
            "create-event-input-field",
            this._locationClassName,
            this.props.isGroupVote ? "ml-6" : "margin-left-30"
          )}
          hidePlaceHolder={true}
          suggestionMarginLeft={this.props.justifyBetweenLabel ? 30 : -50}
          onPressEscape={this.blurElement}
          isAvailability={true}
          checkForTemplateShortcut={this.props.isSlots}
        />
      </div>
    );
  }

  renderSelectCalendar() {
    const {
      onChangeUser,
    } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;

    const onSelectCalendar = (option) => {
      const updatedCalendar = option.value;
      this.props.onChangeCalendar(updatedCalendar);
      const isExecCalendar = isCalendarExecutiveCalendar({ calendar: updatedCalendar, allLoggedInUsers });
      if (!onChangeUser) {
        // do nothing
      } else if (isExecCalendar && getMatchingExecutiveUserFromCalendar({ calendar: updatedCalendar, allLoggedInUsers })) {
        const execUser = getMatchingExecutiveUserFromCalendar({ calendar: updatedCalendar, allLoggedInUsers });
        onChangeUser(getUserEmail(execUser));
      } else if (!isSameEmail(getCalendarUserEmail(updatedCalendar), getUserEmail(this.getUser()))) {
        // need to switch user
        onChangeUser(getCalendarUserEmail(updatedCalendar));
      }

      // Check if selected calendar and current conferencing is provided by Outlook
      if (
        isCalendarOutlookCalendar(updatedCalendar) &&
        isOutlookConferencingOption(this.props.conferencing?.value)
      ) {
        const updatedConferencingOption =
          getOutlookCalendarDefaultOnlineMeetingProvider(updatedCalendar);

        // Maybe add a popup or notification that we're swapping off conferencing if it's not a conferencing option?
        // Or maybe change to a different conferencing option?
        isOutlookConferencingOption(updatedConferencingOption)
          ? this.onSelectConferencing({
              value: updatedConferencingOption,
              label: convertOutlookConferencingToHumanReadable(
                updatedConferencingOption
              ),
            })
          : this.onSelectConferencing(NO_CONFERENCING_OPTION);
      }

      blurCalendar();
    };

    const {
      selectedCalendar,
      emailToNameIndex,
    } = this.props;
    const calendarName = getCalendarName({
      calendar: selectedCalendar,
      emailToNameIndex,
      currentUser: this.getUser(),
      masterAccount,
    });
    const isExecutiveCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar: selectedCalendar, allLoggedInUsers });

    return (
      <div
        className={classnames(
          "slots-row-container-medium",
          "slots-row-default-margin-top"
        )}
      >
        <div
          className="default-font-size font-weight-300 additional-details-calendar-section secondary-text-color"
          style={{ marginRight: this.props.justifyBetweenLabel ? 302 : 53 }}
        >
          Calendar
        </div>

        <CustomSelect
          value={{
            label: (
              <div className="flex items-center default-font-size">
                <div className={classnames("truncate", isExecutiveCalendar ? "max-width-118px" : "")}>{calendarName}</div>
                {isExecutiveCalendar ? <ExecutiveLabel className="ml-2" /> : null}
              </div>
            ),
            value: selectedCalendar,
          }}
          onChange={onSelectCalendar}
          options={this.state.calendarSelectOptions}
          onKeyDown={this.onKeyDownSelect}
          className={classnames(
            "select-availability-conferencing default-font-size",
            this._calendarClassName,
            this.props.isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          style={{ marginLeft: 102 }}
          classNamePrefix="dark-mode"
          tabSelectsValue={false}
          openMenuOnFocus={true}
          ref={(input) => {
            this.selectCalendar = input;
          }}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode: this.props.isDarkMode,
            showBorder: true,
          })}
          alwaysShowIndicator={true}
          onMenuOpen={() => {
            this.setState({ calendarSelectOptions: this.createCalendarReactSelectOptions() });
          }}
          filterOption={(candidate, input) => {
            const {
              masterAccount,
            } = this.props.masterAccount;
            const {
              emailToNameIndex,
            } = this.props;
            return filterForCalendarSelect({
              candidate,
              input,
              emailToNameIndex,
              user: this.getUser(),
              masterAccount,
            });
          }}
        />
      </div>
    );
  }

  renderFindTimesForEveryone() {
    const { onClickFindTimesForEveryone } = this.props;
    if (!onClickFindTimesForEveryone) {
      return null;
    }
    return <FindTimesHoverableText onClick={onClickFindTimesForEveryone} />;
  }

  renderAttendees() {
    const {
      slotsSectionRowClassName,
      onChangeAttendees
    } = this.props;
    if (!onChangeAttendees) {
      return null;
    }

    return (
      <>
        <div
          className={classnames(
            "display-flex-flex-direction-row align-items-center justify-content-space-between",
            "slots-row-container-medium",
            "slots-row-default-margin-top",
            slotsSectionRowClassName || "",
          )}
        >
          <div className="default-font-size font-weight-300 secondary-text-color">
            Attendees
          </div>

          <ReactSelectAttendeeAutoComplete
            createLabel="Add"
            innerRef={this.attendees}
            addAttendees={this.addAttendees}
            componentLocation={REACT_ATTENDEE_SELECT_LOCATION.AVAILABILITY}
            selectedGuests={this.props.attendees}
            useRecentlySearchedContacts={true}
            useRecentlySearched={true}
            includeEmailAndName={true}
            className={classnames(
              "availability-search-attendees",
              this._attendeesClassName
            )}
            onKeyDown={this.onKeyDownSelect}
            calendarUserEmail={getCalendarUserEmail(this.props.selectedCalendar)}
          />
        </div>

        <GroupScheduling
          attendees={this.props.attendees ?? []}
          blockedCalendars={this.props.blockedAttendeesCalendars}
          removeAttendee={(email) => this.removeAttendee(email)}
          setBlockedCalendars={this.props.onChangeBlockedAttendeesCalendars}
          hideSwitch={this.props.hideAttendeesSwitch}
          disableAttendeesSwitchAndSetToFalse={
            this.props.disableAttendeesSwitchAndSetToFalse
          }
          selectedUser={this.getUser()}
        />
      </>
    );
  }

  renderMeetingDetailPreviewSection() {
    if (!this.props.showHideDetails) {
      return null;
    }
    return (
      <div id={MEETING_DETAIL_ID}>
        <div
          className="display-flex justify-content-center create-availability-panel-preview-text mt-5 align-items-center"
          onClick={this.toggleShowDetails}
        >
          {this.state.showDetails ? (
            <Minus
              size={12}
              id={HIDE_SHOW_AVAILABILITY_DETAILS}
              className="margin-right-5 mb-0.5"
            />
          ) : (
            <Plus size={12} className="margin-right-5 mb-0.5" />
          )}

          {this.state.showDetails
            ? "Hide invite details"
            : "Personalize invite"}
        </div>
      </div>
    );
  }

  renderSelectedAttendees() {
    let attendees = this.props.attendees || [];

    return (
      <div
        className="create-event-attendee-section"
        style={{
          marginLeft: this.props.justifyBetweenLabel
            ? MARGIN_LEFT_ON_JUSTIFY_BETWEEN
            : 110,
        }}
      >
        {attendees.map((a, index) => {
          return (
            <div
              className="create-event-attendee-block display-flex-flex-direction-row align-items-center"
              style={{ marginBottom: 5 }}
              key={`create-event-attendee-${index}`}
            >
              <div className="truncate-text max-w-44">{a.name || getObjectEmail(a)}</div>
              <X
                size={14}
                className="clickable-icon margin-left-5"
                onClick={() => this.removeAttendee(a.email)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderEventTitle() {
    if (!this.props.onChangeTitle) {
      return null;
    }
    const { customQuestions } = this.props;
    const showCompanyQuestion =
      doesListOfQuestionsIncludeCompany(customQuestions);

    const { INVITEE_NAME_BLOCK, INVITEE_COMPANY_NAME } = ALL_BOOKING_VARIABLES;
    const getAllAvailableVariables = () => {
      if (showCompanyQuestion) {
        return [INVITEE_NAME_BLOCK].concat(INVITEE_COMPANY_NAME);
      }
      return [INVITEE_NAME_BLOCK];
    };

    return (
      <>
        <div
          className={classnames(
            "display-flex-flex-direction-row align-items-center justify-content-space-between",
            "mt-1"
          )}
        >
          <div className="availability-detail-section-text flex mt-2.5 items-center">
            Title
            {this.props.moveTitleHintNextToTitle ? (
              <MoreInformationHint
                containerClassName="ml-2.5 default-font-color"
                variables={getAllAvailableVariables()}
                hintContainerClassName={
                  this.props.isDarkMode ? "bg-gray-500" : ""
                }
              />
            ) : null}
          </div>

          <EventTitleInput
            innerRef={(input) => {
              this.title = input;
            }}
            width={this.props.justifyBetweenLabel ? SUGGESTION_WIDTH : 280}
            onSaveTitle={this.props.onChangeTitle}
            inputPaddingLeft={0}
            onKeyDown={this.onKeyDownSelect}
            summary={this.props.eventTitle}
            inputClassname={this._titleClassName}
            hideHint={this.props.moveTitleHintNextToTitle}
            containerId={this.props.containerId || "default-additional-details"}
            extraAvailableVariables={getAllExtraBookingVariables({
              user: this.getUser(),
              customQuestions,
            })}
          />
        </div>
        {!showCompanyQuestion &&
        doesTitleIncludeCompanyQuestion(this.props.eventTitle) ? (
          <div className="warning-color default-font-size mt-1 event-title-warning">
            The recipient's company is no longer being collected, which could
            cause unintended errors here.
          </div>
        ) : null}
      </>
    );
  }

  renderCombineAdjacentTimeSlots() {
    if (!this.props.setCombineAdjacentTimeSlots) {
      return null;
    }

    const onClickToggle = () => {
      this.props.setCombineAdjacentTimeSlots(
        !this.props.combineAdjacentTimeSlots
      );
    };

    return (
      <div className="flex items-center my-4">
        <div
          className="default-font-size cursor-pointer select-none secondary-text-color"
          style={{ marginRight: 216 }}
          onClick={onClickToggle}
        >
          Combine adjacent Slots
        </div>

        <DefaultSwitch
          id={`settings-${COMBINE_ADJACENT_SLOTS_ID}`}
          isChecked={this.props.combineAdjacentTimeSlots}
          onChange={(isOn) => {
            blurCalendar();
            this.props.setCombineAdjacentTimeSlots(isOn);
          }}
        />
      </div>
    );
  }
  renderDurationTitle() {
    if (!this.props.onSelectMinutes) {
      return null;
    }
    const onSelectMinutes = (selected) => {
      this.props.onSelectMinutes(selected);
      this.selectDuration?.blur();
    };

    return (
      <div
        className={classnames(
          "slots-row-container-medium",
          "slots-row-default-margin-top"
        )}
      >
        <div
          className={classnames(
            "availability-detail-section-text",
            this.props.justifyBetweenLabel ? "mr-3" : "mr-14"
          )}
        >
          Duration
        </div>

        <div
          className={classnames(
            "display-flex-flex-direction-row align-items-center"
          )}
          style={{ marginLeft: this.props.justifyBetweenLabel ? 294 : null }}
        >
          <CreatableSelect
            onKeyDown={this.onKeyDownSelect}
            className={classnames(
              "select-duration-time-font-300",
              this._durationClassName,
              "select-default-font-size"
            )}
            classNamePrefix="dark-mode"
            value={{ value: this.props.duration, label: this.props.duration }}
            options={getMinutesList()}
            onChange={onSelectMinutes}
            noOptionsMessage={() => "Set"}
            openMenuOnFocus={true}
            formatCreateLabel={(userInput) => userInput}
            tabSelectsValue={this.props.minutesQuery?.length > 0}
            onInputChange={this.props.onDurationInputChange}
            ref={(input) => {
              this.selectDuration = input;
            }}
            styles={getReactSelectBaseStyle({
              isDarkMode: this.props.isDarkMode,
              showBorder: true,
            })}
            components={{ DropdownIndicator }}
          />

          <div className="font-size-12 ml-2.5 secondary-text-color">
            minutes
          </div>
        </div>
      </div>
    );
  }

  renderConferencing() {
    if (!this.props.onSelectConferencing) {
      return null;
    }

    return (
      <div
        className={classnames(
          "slots-row-container-medium",
          "slots-row-default-margin-top"
        )}
      >
        <div
          className={classnames(
            "availability-detail-section-text",
            "additional-availability-conferencing",
            this.props.justifyBetweenLabel ? "mr-4" : "mr-7"
          )}
        >
          Conferencing
        </div>

        <CustomSelect
          onKeyDown={this.onKeyDownSelect}
          isSearchable={false}
          className={classnames(
            "select-availability-conferencing default-font-size",
            this._conferencingClassName,
            this.props.justifyBetweenLabel
              ? "availability-settings-section-margin-left"
              : "",
            this.props.isDarkMode ? "dark-mode-select" : "",
            "select-default-font-size"
          )}
          classNamePrefix="dark-mode"
          tabSelectsValue={false}
          openMenuOnFocus={true}
          ref={(input) => {
            this.selectConferencing = input;
          }}
          value={this.props.conferencing}
          onChange={this.onSelectConferencing}
          options={this.getConferencingOptions()}
          showBorder={true}
          alwaysShowIndicator={true}
        />
      </div>
    );
  }

  renderRescheduling() {
    if (this.props.hideReschedule) {
      return;
    }

    return (
      <div className={classnames("slots-row-container-medium")}>
        <div>
          <CheckBox
            isChecked={this.props.allowRescheduleAndCancel}
            onChange={this.props.onChangeAllowReschedule}
          />
        </div>
        <div
          className="availability-detail-section-text cursor-pointer select-none ml-3"
          onClick={this.props.onChangeAllowReschedule}
        >
          Include rescheduling {"&"} cancellation links
        </div>
      </div>
    );
  }

  showDescription() {
    this.setState({ hideDescription: false });
  }

  renderDescription() {
    if (this.state.hideDescription) {
      return (
        <div
          className="hoverable-secondary-text-color default-font-size flex items-center mt-4"
          onClick={this.showDescription}
        >
          <Plus size={12} className="mr-3 mb-0.5" />
          Add description
        </div>
      );
    }

    return (
      <div
        className={classnames(
          "mt-4 mb-16",
          this.props.justifyBetweenLabel ? "flex justify-between" : ""
        )}
      >
        <div className="default-font-size font-weight-300 secondary-text-color">
          Description
        </div>

        <div
          className={classnames(
            this.props.justifyBetweenLabel ? "" : "mt-2.5",
            "availability-description-container",
            this._descriptionClassName
          )}
        >
          <EventFormDescription
            description={this.props.description}
            id={"availability description"}
            className="event-form-description-input display-linebreak"
            elementRef={this.props.descriptionRef}
            tabIndex={8}
            onClickTab={this.blurElement}
            onClickEscape={this.blurElement}
            onKeyChange={this.onKeyDownSelect}
            onChange={this.props.onChangeDescription}
          />
        </div>
      </div>
    );
  }

  determineTextAreaHeight() {
    if (!this.props.description || this.props.description.length < 20) {
      return 20;
    }

    let textLength = this.props.description.length;
    let height = Math.ceil(textLength / 45) * 18;

    return height < 20 ? 20 : height;
  }

  removeAttendee(email) {
    let updatedAttendees = this.state.attendees;
    let loweredCaseEmail = email.toLowerCase();

    updatedAttendees = updatedAttendees.filter(
      (a) => a.email.toLowerCase() !== loweredCaseEmail
    );
    Broadcast.publish("REMOVE_EMAIL", email);

    this.setAttendees(updatedAttendees);
  }

  blurElement() {
    blurCalendar();
  }

  onKeyDownEscape(e) {
    if (e?.keyCode === KEYCODE_ESCAPE) {
      blurCalendar();
    }
  }

  addAttendees(attendee, shouldRefocusAttendeeInput = true) {
    if (isEmptyObjectOrFalsey(attendee)) {
      return;
    }

    if (this.props.searchForContact) {
      Broadcast.publish(
        "ADD_EMAIL_TO_SEARCHED_EMAILS",
        {
          email: attendee.emailArray ?? attendee.value ?? attendee.email,
          contact: attendee,
          userEmail: getUserEmail(this.getUser()),
        },
        true
      );
    }

    if (attendee.emailArray) {
      let updatedAttendees = this.processListOfEmailsForAttendees(
        attendee.emailArray
      );
      if (!updatedAttendees) {
        return;
      }

      this.setAttendees(updatedAttendees);
      return;
    }

    // Refocus attendee
    shouldRefocusAttendeeInput && this.goToAttendeeList();

    if (!attendee.__isNew__) {
      let updatedAttendees = this.state.attendees;
      let updatedAttendeesEmails = updatedAttendees.map((a) => a.email);

      if (
        updatedAttendeesEmails.includes(attendee.email) ||
        updatedAttendeesEmails.includes(attendee.value)
      ) {
        // check if already exist
        return;
      }

      let formattedAttendee = attendee;
      formattedAttendee.email = attendee.value;
      updatedAttendees = updatedAttendees.concat(formattedAttendee);

      this.setAttendees(updatedAttendees);
      return;
    }

    let newAttendee = attendee.value;

    let attendeeEmailWithoutWhiteSpaces = newAttendee ? newAttendee.trim() : "";

    if (attendeeEmailWithoutWhiteSpaces.length === 0) {
      return;
    }

    let attendeesStringFiltered = attendeeEmailWithoutWhiteSpaces
      .replace(/,/g, " ")
      .replace(/\t/g, " ")
      .replace(/</g, " ")
      .replace(/>/g, " ");

    // Split out so we know if something is pasted in or manually entered
    let attendeesArray = attendeesStringFiltered.split(" ");

    if (attendeesArray.length > 1) {
      // Enter in string separated by white space
      let updatedAttendees =
        this.processListOfEmailsForAttendees(attendeesArray);

      if (!updatedAttendees) {
        return;
      }

      this.setAttendees(updatedAttendees);
    } else if (!isValidEmail(attendeeEmailWithoutWhiteSpaces)) {
      // do nothing
    } else {
      let updatedAttendees = this.state.attendees;
      updatedAttendees = updatedAttendees.concat({
        name: "",
        email: attendeeEmailWithoutWhiteSpaces,
      });

      this.setAttendees(updatedAttendees);
    }
  }

  getMatchingAttendee(inputAttendee) {
    const { attendees } = this.props;
    return (attendees ?? []).find((attendee) =>
      equalAfterTrimAndLowerCased(attendee?.email, inputAttendee?.email)
    );
  }

  setAttendees(inputAttendees) {
    const updatedAttendees = (inputAttendees ?? []).map((attendee) => {
      const matchingAttendee = this.getMatchingAttendee(attendee);
      if (matchingAttendee) {
        return {
          ...matchingAttendee,
          ...attendee,
        };
      }
      return attendee;
    });

    this.props.onChangeAttendees &&
      this.props.onChangeAttendees(updatedAttendees);
    let updatedState = { attendees: updatedAttendees };
    if (!this.state.showDetails && updatedAttendees?.length > 0) {
      updatedState.showDetails = true;
    }
    this.setState(updatedState);
  }

  processListOfEmailsForAttendees(attendeesArray) {
    let currentAttendees = this.state.attendees
      ? this.state.attendees.map((a) => a.email.trim().toLowerCase())
      : [];

    let existingAttendees = [].concat(currentAttendees);

    let filterForEmail = attendeesArray.filter(
      (e) => isValidEmail(e) && !existingAttendees.includes(e.toLowerCase())
    );

    if (filterForEmail.length === 0) {
      return null;
    }

    filterForEmail = removeDuplicatesFromArray(filterForEmail);

    let newlyAddedAttendees = this.state.attendees || [];
    filterForEmail.forEach((email) => {
      newlyAddedAttendees = newlyAddedAttendees.concat({ email, name: "" });
    });

    return newlyAddedAttendees;
  }

  goToAttendeeList() {
    this.attendees && this.attendees.current && this.attendees.current.focus();
  }

  onKeyDownSelect(event) {
    const isCtrlOrCmdPressed = isMac() ? event.metaKey : event.ctrlKey;
    const keyCode = getEventKeyCode(event);

    if (keyCode === KEYCODE_SEMI_COLON && isCtrlOrCmdPressed) {
      Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER", event, SLOTS_ELEMENT.DESCRIPTION);
      return;
    }

    const selectedText = getSelectedTextInApp();
    if (selectedText?.length > 0) {
      return;
    }

    if (isCtrlOrCmdPressed) {
      if (keyCode === KEYCODE_C) {
        Broadcast.publish("COPY_AVAILABILITY_CONTENT");
      } else if (keyCode === KEYCODE_K) {
        Broadcast.publish("TURN_ON_COMMAND_CENTER");
      }
    } else if (keyCode === KEYCODE_ESCAPE) {
      blurCalendar();
    }
  }

  startComponentHasChangedNotificationTimer(shouldCauseRerender = false) {
    clearTimeout(this.notificationTimer);
    this.notificationTimer = null;

    this.notificationTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.clearComponentHasChangedNotification();
    }, 500);

    if (shouldCauseRerender) {
      this.setState({ rerenderCount: this.state.rerenderCount + 1 });
    }
  }

  clearComponentHasChangedNotification() {
    this._titleClassName = null;
    this._durationClassName = null;
    this._conferencingClassName = null;
    this._attendeesClassName = null;
    this._calendarClassName = null;
    this._locationClassName = null;
    this._descriptionClassName = null;
  }

  addEmailsIntoDetail(emails) {
    if (isEmptyArray(emails)) {
      return;
    }

    const validEmails = emails
      .filter((e) => isValidEmail(e))
      .map((e) => e.toLowerCase().trim());
    let updatedAttendees = this.state.attendees || [];
    const currentEmails = updatedAttendees
      .filter((a) => a?.email)
      .map((a) => a.email.toLowerCase().trim());
    const filteredEmails = validEmails.filter(
      (e) => !currentEmails.includes(e)
    );
    if (filteredEmails.length === 0) {
      return;
    }

    const newAttendees = filteredEmails.map((email) => {
      let name = this.props.emailToNameIndex
        ? this.props.emailToNameIndex[email]
        : ""; // If no name -> name should be empty string
      return {
        email,
        label: name ? `${name} (${email})` : email,
        name,
        value: email,
      };
    });

    // this.state.attendees format:
    // email: "john@vimcal.com"
    // label: "John Li (john@vimcal.com)"
    // name: "John Li"
    // value: "john@vimcal.com"

    // need to check for duplicates

    updatedAttendees = updatedAttendees.concat(newAttendees);
    this.setAttendees(updatedAttendees);
  }

  setLocation(text) {
    this.props.onChangeLocation && this.props.onChangeLocation(text);
    this.setState({ location: text });
  }

  checkForDefaultAttendees() {
    if (this.props.eventFormEmails?.length > 0) {
      let updatedAttendees = [];
      this.props.eventFormEmails.forEach((e) => {
        let name =
          this.props.emailToNameIndex && this.props.emailToNameIndex[e]
            ? this.props.emailToNameIndex[e]
            : ""; // If no name -> name should be empty string
        let attendee = {
          email: e,
          label: name ? `${name} (${e})` : e,
          name,
          value: e,
        };

        updatedAttendees = updatedAttendees.concat(attendee);
      });

      this.setAttendees(updatedAttendees);
    }
  }

  checkForUpdateAttendeeName(prevProps) {
    if (
      this.props.emailToNameIndex &&
      (!prevProps.emailToNameIndex ||
        (prevProps.emailToNameIndex &&
          Object.keys(prevProps.emailToNameIndex).length !==
            Object.keys(this.props.emailToNameIndex).length)) &&
      this.state.attendees.length > 0
    ) {
      // reset attendees
      let updatedAttendees = [];
      this.state.attendees?.forEach((a) => {
        if (!a.name) {
          let name = this.props.emailToNameIndex[a.email] || ""; // If no name -> name should be empty string
          let attendee = {
            email: a.email,
            label: name ? `${name} (${a.email})` : a.email,
            name,
            value: a.email,
          };
          updatedAttendees = updatedAttendees.concat(attendee);
        } else {
          updatedAttendees = updatedAttendees.concat(a);
        }
      });

      this.setAttendees(updatedAttendees);
    }
  }

  getUser() {
    return this.props.selectedUser ?? this.props.currentUser;
  }

  checkForUpdateSelectedUser(prevProps) {
    if (prevProps.selectedUser !== this.props.selectedUser) {
      this.setState({
        allWritableCalendarsForSelect: this.getWritableCalendars(),
        conferencingOptions: this.getInitialConferencingOptions(),
      });
    }
  }

  getWritableCalendars() {
    const { masterAccount } = this.props.masterAccount;
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      emailToNameIndex,
      isSlots,
    } = this.props;
    const user = this.getUser();
    if (isSlots) {
      return createWritableCalendarList({
        emailToNameIndex,
        allCalendars,
        currentUser: user,
        masterAccount,
        allLoggedInUsers,
      });
    }
    if (shouldHideDelegatedUser({ user, allCalendars })) {
      const matchingCalendar = getMatchingExecutiveCalendar({
        allCalendars,
        user,
      });
      if (doesCalendarHaveEditableRole(matchingCalendar)) {
        const calendarName = getCalendarName({
          calendar: matchingCalendar,
          emailToNameIndex,
          masterAccount,
          currentUser: user,
        });
        return [{
          label: calendarName,
          value: matchingCalendar,
          name: calendarName,
          userEmail: getCalendarUserEmail(matchingCalendar),
        }];
      }
    }

    return createWritableCalendarList({
      emailToNameIndex,
      allCalendars: getUserCalendar(
        allCalendars,
        getUserEmail(this.getUser()),
      ),
      currentUser: user,
      masterAccount,
      allLoggedInUsers,
    });
  }

  focusAvailabilityElement(element) {
    if (element === "description") {
      AvailabilityBroadcast.publish("SHOW_SLOTS_DESCRIPTION");
    }

    if (
      [
        "description",
        "location",
        "calendar",
        "email",
        "name",
        "attendees",
      ].includes(element)
    ) {
      this.showHiddenDetails();
    }

    switch (element) {
      case "title":
        if (!this.title) {
          return;
        }
        this.title.focus();
        // select all the content in the element
        document.execCommand("selectAll", false, null);
        // collapse selection to the end
        document.getSelection().collapseToEnd();
        return;
      case "duration":
        this.selectDuration?.focus();
        return;
      case "conferencing":
        this.selectConferencing?.focus(this);
        return;
      case "description":
        this.props.descriptionRef?.current?.focus();
        return;
      case "location":
        this.location?.focus();
        return;
      case "calendar":
        this.selectCalendar?.focus();
        return;
      case "email":
        this.email?.current?.focus();
        return;
      case "name":
        this.name?.current?.focus();
        return;
      case "attendees":
        this.attendees?.current?.focus();
        return;
      default:
        return;
    }
  }

  showHiddenDetails() {
    this.props.showHideDetails && this.setState({ showDetails: true });
  }

  onSelectConferencing(conferencing) {
    const user = this.getUser();
    const { masterAccount } = this.props.masterAccount;

    if (
      isDefaultZoomPersonalLink(user) &&
      (!user.zoom_link || user.zoom_link.length <= 5) &&
      this.isConferencingAndHasPersonalLink(conferencing)
    ) {
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
        initialContent: APP_SETTINGS.CONFERENCING,
        conferencing: CONFERENCING_SETTINGS_ID.ZOOM,
      });

      this.selectConferencing && this.selectConferencing.blur();
      return;
    } else if (
      !isDefaultZoomPersonalLink(user) &&
      !user.has_zoom_access &&
      this.isConferencingAndHasPersonalLink(conferencing) &&
      !isUserBeingScheduledFor({
        user,
        schedulers: getZoomSchedulers(this.props.zoomSchedulers),
        masterAccount,
      })
    ) {
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
        initialContent: APP_SETTINGS.CONFERENCING,
        conferencing: CONFERENCING_SETTINGS_ID.ZOOM,
      });

      this.selectConferencing && this.selectConferencing.blur();
      return;
    } else if (
      conferencing &&
      conferencing.value === BACKEND_PHONE &&
      !isValidPhoneConferencing(user)
    ) {
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
        initialContent: APP_SETTINGS.CONFERENCING,
        conferencingSetting: CONFERENCING_SETTINGS_ID.PHONE,
      });

      this.selectConferencing && this.selectConferencing.blur();
      return;
    }

    if (this.props.onSelectConferencing) {
      this.props.onSelectConferencing(conferencing);
    }
  }

  isConferencingAndHasPersonalLink(conferencing) {
    const { masterAccount } = this.props.masterAccount;
    return (
      conferencing?.value === BACKEND_ZOOM &&
      !getZoomPersonalLink({
        user: this.getUser(),
        schedulers: getZoomSchedulers(this.props.zoomSchedulers),
        masterAccount,
      })
    );
  }

  setConferenceAsZoom(conferenceType) {
    if (!this.props.onSelectConferencing) {
      return;
    }
    if (conferenceType === ZOOM_STRING) {
      this.props.onSelectConferencing(ZOOM_CONFERENCING_OPTION);
    } else if (conferenceType === PHONE_NUMBER_CONFERENCE) {
      this.props.onSelectConferencing(PHONE_CONFERENCING_OPTION);
    }

    this.selectConferencing && this.selectConferencing.blur();
  }

  flashChanges(changed) {
    // TITLE_DETAIL,
    // DURATION_DETAIL,
    // CONFERENCING_DETAIL,
    // ATTENDEES_DETAIL,
    // SELECTED_CALENDAR_DETAIL,
    // LOCATION_DETAIL,
    // DESCRIPTION_DETAIL
    if (isEmptyObjectOrFalsey(changed)) {
      return;
    }
    this.showHiddenDetails();

    if (changed[TITLE_DETAIL]) {
      this._titleClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[DURATION_DETAIL]) {
      this._durationClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[CONFERENCING_DETAIL]) {
      this._conferencingClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[ATTENDEES_DETAIL]) {
      this._attendeesClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[SELECTED_CALENDAR_DETAIL]) {
      this._calendarClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[LOCATION_DETAIL]) {
      this._locationClassName = COMPONENT_NOTIFICATION;
    }
    if (changed[DESCRIPTION_DETAIL]) {
      this._descriptionClassName = COMPONENT_NOTIFICATION;
    }
    this.startComponentHasChangedNotificationTimer(true);
  }

  toggleShowDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  isOutlookCalendar() {
    return isCalendarOutlookCalendar(this.props.selectedCalendar);
  }

  getConferencingOptions() {
    const { conferencingOptions } = this.state;

    if (!this.isOutlookCalendar()) {
      if (
        !getGoogleCalendarAllowedConferenceType(this.props.selectedCalendar)
      ) {
        return conferencingOptions.filter((c) => c.value !== BACKEND_HANGOUT);
      }
      return conferencingOptions;
    } else {
      return conferencingOptions.filter(
        (c) => c.value !== BACKEND_HANGOUT
      );
    }
  }

  getInitialConferencingOptions() {
    const user = this.getUser();
    const DEFAULT_CONFERENCING_OPTIONS = [
      HANGOUT_CONFERENCING_OPTION,
      NO_CONFERENCING_OPTION,
    ];
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;

    return this.props.checkForValidConferencing
      ? getValidUserConferencingOptions({
          currentUser: user,
          defaultOptions: DEFAULT_CONFERENCING_OPTIONS,
          isUserBeingScheduledFor: isUserBeingScheduledFor({
            user,
            schedulers: getZoomSchedulers(this.props.zoomSchedulers),
            masterAccount,
          }),
          allCalendars,
        })
      : createConferencingOptions(user);
  }

  createCalendarReactSelectOptions(inputWritableCalendars) {
    const {
      currentUser,
      emailToNameIndex,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    return createCalendarReactSelectOptions({
      currentCalendar: this.props.selectedCalendar,
      writableCalendars: inputWritableCalendars ?? this.state?.allWritableCalendarsForSelect,
      masterAccount,
      emailToNameIndex,
      user: this.getUser(),
      currentUser,
      allLoggedInUsers,
    });
  }
}

function mapStateToProps(state) {
  const { currentUser, isDarkMode, emailToNameIndex, eventFormEmails } = state;

  return {
    currentUser,
    isDarkMode,
    emailToNameIndex,
    eventFormEmails,
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const zoomSchedulers = useZoomSchedulers();
  const masterAccount = useMasterAccount();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      zoomSchedulers={zoomSchedulers}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(mapStateToProps)(
  withStore(AvailabilityAdditionalDetails)
);
