import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CustomButton from "../../../components/customButton";
import CustomSelect from "../../../components/select";
import {
  BLUE_BUTTON,
  DARK_MODE_MODAL_BACKGROUND_COLOR,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../../services/globalVariables";
import { getReactSelectBaseStyle } from "../../../components/select/styles";
import {
  getLoggedInEmails,
  hasEventPreventDefault,
} from "../../../services/commonUsefulFunctions";
import {
  doesAllLoggedInUsersContainOutlookUsers,
  getBestGuessOutlookNonDelegatedUser,
  getUserEmail,
} from "../../../lib/userFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../services/stores/SharedAccountData";
import { useSelector } from "react-redux";
import { isOutlookUser } from "../../../lib/outlookFunctions";
import {
  getInputStringFromEvent,
  isValidEmail,
} from "../../../lib/stringFunctions";
import appBroadcast from "../../../broadcasts/appBroadcast";
import magicLinkBroadcast from "../../../broadcasts/magicLinkBroadcast";
import {
  getCalendarOwnerEmail,
  getCalendarSummary,
  getCalendarUserCalendarID,
} from "../../../services/calendarAccessors";
import { isCalendarConvertableToSecondary } from "../../../lib/calendarFunctions";
import { isEmptyArray } from "../../../lib/arrayFunctions";
import broadcast from "../../../broadcasts/broadcast";
import { isNullOrUndefined } from "../../../services/typeGuards";
import { MAGIC_LINK_BROADCAST_VALUES } from "../../../lib/broadcastValues";

const NewUserForm = ({ isMagicLink }) => {
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [warning, setWarning] = useState(false);
  const [newUserData, setNewUserData] = useState({
    delegateEmail: "",
    email: "",
    firstName: "",
    lastName: "",
    userCalendarId: "",
  });
  const { delegateEmail, email, firstName, lastName, userCalendarId } =
    newUserData;

  const calendarOptions = Object.values(allCalendars)
    .filter((calendar) => {
      const loggedInAccountsEmails = getLoggedInEmails({currentUser, allLoggedInUsers});
      const calendarEmail = getCalendarOwnerEmail(calendar);

      return (
        isCalendarConvertableToSecondary({
          allCalendars,
          allLoggedInUsers,
          calendar,
          currentUser: currentUser,
          masterAccount,
        }) && !loggedInAccountsEmails.includes(calendarEmail)
      );
    })
    .map((calendar) => {
      return {
        label: getCalendarSummary(calendar),
        value: getCalendarUserCalendarID(calendar),
      };
    });

  const hasOutlookUsers =
    doesAllLoggedInUsersContainOutlookUsers(allLoggedInUsers);

  const selectedMatchingCalendar = calendarOptions.find(
    (option) => option.value === userCalendarId,
  );

  useEffect(() => {
    if (!hasOutlookUsers) {
      return;
    }

    setNewUserData({
      ...newUserData,
      delegateEmail: getUserEmail(getBestGuessOutlookNonDelegatedUser({
        masterAccount,
        currentUser,
        allLoggedInUsers,
      })),
    });
    return () => {};
  }, []);

  useEffect(() => {
    magicLinkBroadcast.subscribe(
      MAGIC_LINK_BROADCAST_VALUES.TOGGLE_CREATE_MAGIC_LINK_SPINNER,
      toggleShowLoadingSpinner,
    );

    return () => {
      magicLinkBroadcast.unsubscribe(
        MAGIC_LINK_BROADCAST_VALUES.TOGGLE_CREATE_MAGIC_LINK_SPINNER,
      );
    };
  }, []);

  /*********************/
  /* Handler Functions */
  /*********************/

  const handleInputChange = (e) => {
    setNewUserData({
      ...newUserData,
      [e.target.name]: getInputStringFromEvent(e),
    });
  };

  const onSubmitConvert = (e) => {
    hasEventPreventDefault(e);
    const calendar = allCalendars[userCalendarId];
    const calendarEmail = getCalendarOwnerEmail(calendar);

    if (!userCalendarId && email) {
      // use different permissions
      // handle response same way as authenticateProxyUser
      appBroadcast.publish("AUTHENTICATE_BY_OUTLOOK_EMAIL", {
        delegateEmail,
        email,
        firstName,
        lastName,
      });
      return;
    }

    /* Create an array of { errorString, value } for fields we want to check */
    const fieldValuesToCheck = [
      { errorString: "Email", value: calendarEmail },
      { errorString: "First Name", value: firstName },
      { errorString: "Last Name", value: lastName },
      { errorString: "Calendar", value: userCalendarId },
    ];
    let missingFieldValueErrors = [];

    /* Loop through the field keys */
    fieldValuesToCheck.forEach((field) => {
      const { errorString, value } = field;

      /* Add the errorString to missingFieldValueErrors if value is missing */
      if (!value) {
        missingFieldValueErrors.push(errorString);
      }
    });

    /* Show a message for fields that are missing */
    if (!isEmptyArray(missingFieldValueErrors)) {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        `Missing fields: ${missingFieldValueErrors.join(", ")}`,
      );
      return;
    }

    broadcast.publish("CONVERT_CALENDAR_TO_USER", {
      email: calendarEmail,
      firstName,
      lastName,
      userCalendarId,
    });
  };

  const onSubmitMagicLink = (e) => {
    hasEventPreventDefault(e);

    /* Early return to prevent multiple requests */
    if (showLoadingSpinner) {
      return;
    }

    /* Toggle spinner on */
    toggleShowLoadingSpinner(true);

    magicLinkBroadcast.publish(
      MAGIC_LINK_BROADCAST_VALUES.CREATE_MAGIC_LINK,
      {
        email,
        firstName,
        lastName,
      },
    );
  };

  /* Default to opposite but allow overwrite */
  const toggleShowLoadingSpinner = (updatedShowLoadingSpinner = !showLoadingSpinner) => {
    setShowLoadingSpinner(updatedShowLoadingSpinner);
  };

  /********************/
  /* Render Functions */
  /********************/

  const renderCalendarSelectSection = () => {
    /* Magic link doesn't need calendar selection */
    if (isMagicLink) {
      return null;
    }

    return (
      <CustomSelect
        isClearable={true}
        alwaysShowIndicator={true}
        openMenuOnFocus={true}
        tabSelectsValue={false}
        className={classNames(
          "maestro-login-select-calendar",
          selectedMatchingCalendar ? "" : "secondary-text-color",
        )}
        classNamePrefix="dark-mode"
        darkModeOverride={true}
        onChange={(e) => {
          setNewUserData({ ...newUserData, userCalendarId: e?.value });
        }}
        options={calendarOptions}
        placeholder="Select exec calendar"
        overrideStyles={getReactSelectBaseStyle({
          isDarkMode: true,
          showBorder: true,
          overrideMenuListBackgroundColor: DARK_MODE_MODAL_BACKGROUND_COLOR,
        })}
        value={selectedMatchingCalendar}
      />
    );
  };

  const renderInputLabel = ({ inputName }) => {
    const inputNameLabelName = {
      email: "Email address",
      firstName: "First name",
      lastName: "Last name",
    };

    /* Only render for magic links */
    /* Also check if inputName is valid */
    if (!isMagicLink || isNullOrUndefined(inputNameLabelName[inputName])) {
      return null;
    }

    return (
      <p className="w-max default-font-size secondary-text-color mb-2">
        {inputNameLabelName[inputName]}
      </p>
    );
  };

  // TODO: refactor this so we don't use margin auto with set width. We should just use flex box and center align it.
  const renderManuallyAddEmailSection = ({ shouldCheckIfOutlook }) => {
    if (
      shouldCheckIfOutlook && // Don't check Outlook for magic links
      (!hasOutlookUsers || !isOutlookUser(currentUser)) // TODO: Remove or statement if we enable delegateEmail
    ) {
      return null;
    }

    return (
      <>
        {isMagicLink ? null : (
          <div className="secondary-text-color my-4 font-size-14">OR</div>
        )}
        {renderInputLabel({ inputName: "email" })}
        <input
          className={"default-input-field maestro-login-input"}
          value={email}
          onChange={handleInputChange}
          name="email"
          placeholder="Add exec email address"
        />
        {warning ? (
          <div className="default-font-size warning-color flex maestro-login-row-container">
            {warning}
          </div>
        ) : null}
      </>
    );
  };

  const renderStepLabel = ({ step }) => {
    /* Don't render step label for magic link */
    if (isMagicLink) {
      return null;
    }

    return <div className="maestro-login-step-label">Step {step}</div>;
  };

  return (
    <form
      className="convert-calendar-form"
      onSubmit={(e) => {
        hasEventPreventDefault(e);
        /* Clear warning if it exists */
        if (warning) {
          setWarning(false);
        }

        /* Only check email if no selected calendar */
        if (!userCalendarId && email && !isValidEmail(email)) {
          setWarning("Please enter a valid email.");
        }

        isMagicLink ? onSubmitMagicLink(e) : onSubmitConvert(e);
      }}
    >
      <div>
        {renderStepLabel({ step: 1 })}
        {renderCalendarSelectSection()}
        {renderManuallyAddEmailSection({ shouldCheckIfOutlook: !isMagicLink })}
      </div>

      <div className={isMagicLink ? "mt-6" : "mt-8"}>
        {renderStepLabel({ step: 2 })}
        {renderInputLabel({ inputName: "firstName" })}
        <input
          className={"default-input-field maestro-login-input"}
          value={firstName}
          onChange={handleInputChange}
          name="firstName"
          placeholder="Exec first name"
        />
      </div>
      <div className="mt-6">
        {renderInputLabel({ inputName: "lastName" })}
        <input
          className={"default-input-field maestro-login-input"}
          value={lastName}
          onChange={handleInputChange}
          name="lastName"
          placeholder="Exec last name"
        />
      </div>
      <div className="flex justify-center mt-8">
        <CustomButton
          buttonType={BLUE_BUTTON}
          className="convert-calendar-button"
          onClick={() => null}
          label={isMagicLink ? "Add Executive" : "Convert"}
          removeSpinnerRight={true}
          shouldRenderSpinner={showLoadingSpinner}
          type="submit"
        />
      </div>
    </form>
  );
};

export default NewUserForm;
