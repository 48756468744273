import React from 'react';

export default function PageCompleteCheckmark() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#3565E3" />
      <path d="M7 10.1L9.1 12.2L13.3 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
