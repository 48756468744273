import React, { useState } from "react";
import { Star, X } from "react-feather";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { getObjectEmail } from "../../lib/objectFunctions";
import { truncateString } from "../../lib/stringFunctions";
import { determineIfAttendeeIsCritical } from "../../lib/availabilityFunctions";
import { GOLD_STAR_HEX_CODE } from "../../lib/styleFunctions";

const CriticalAttendeeSelect = (props) => {
  const {
    attendees,
    criticalAttendees,
    onRemoveEmail,
    toggleCriticalAttendee,
  } = props;
  const [hintIndex, setHintIndex] = useState(null);
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const CriticalAttendeeHint = ({ index }) => {
    if (index !== hintIndex) {
      return null;
    }

    return (
      <div
        className={
          classNames(
            "absolute more-information-modal w-max-content-important",
            isDarkMode ? "background-color-modal-background-color" : "bg-white",
            "-left-56 top-8",
          )
        }
      >
        <div className="font-normal">Critical Attendee</div>
        <div>This attendee is critical to the event</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center col-start-1 col-end-3">
      {
        attendees.map((attendee, index) => {
          const attendeeEmail = getObjectEmail(attendee);
          const isLastAttendee = index === attendees.length - 1;
          const isCriticalAttendee = determineIfAttendeeIsCritical({ criticalAttendees, email: attendeeEmail });

          return (
            <div
              className={
                classNames(
                  "flex items-center default-font-size",
                  isLastAttendee ? "" : "mb-4",
                )
              }
              key={attendeeEmail}
            >
              {truncateString(attendeeEmail ?? "", 32)}
              <div className="ml-auto flex items-center">
                <div
                  className="relative"
                  onMouseEnter={() => setHintIndex(index)}
                  onMouseLeave={() => setHintIndex(null)}
                >
                  <CriticalAttendeeHint index={index} />
                  <Star
                    className="mr-4 gold-star-hoverable-secondary-text-color"
                    color={isCriticalAttendee ? GOLD_STAR_HEX_CODE : undefined}
                    fill={isCriticalAttendee ? GOLD_STAR_HEX_CODE : "transparent"}
                    onClick={() => toggleCriticalAttendee(attendeeEmail)}
                    size={20}
                  />
                </div>
                <X
                  className="hoverable-secondary-text-color"
                  size={16}
                  onClick={() => {
                    onRemoveEmail(attendeeEmail);
                  }}
                />
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default CriticalAttendeeSelect;
