import React, { useCallback, useMemo, useState } from "react";
import {
  useBackendSubscriptionStore,
  useStripeSubscriptions,
} from "../../../services/stores/finance";
import { isScheduledToBeCancelled } from "../../../lib/stripeFunctions";
import { isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import { pluralize } from "../../../lib/stringFunctions";
import { isUserMaestroUser } from "../../../services/maestroFunctions";
import { useMasterAccount } from "../../../services/stores/SharedAccountData";
import CustomButtonV2 from "../../buttons/customButtonV2";
import { BLUE_BUTTON, WHITE_BUTTON } from "../../../services/globalVariables";
import EventModalPopup from "../../eventModalPopup";
import { useSelector } from "react-redux";
import RemoveSeats from "../removeSeats";
import UpdatedAddTeamMembersModal from "../../teamPlans/updatedAddTeamMembersModal";
import { useTeamPlan } from "../../../services/stores/userData";
import { isUserInFreeTrial } from "../../../lib/stateManagementFunctions";
import settingsBroadcast from "../../../broadcasts/settingsBroadcast";
import { SETTINGS_BROADCAST_VALUES } from "../../../lib/broadcastValues";
import { APP_SETTINGS } from "../../../lib/vimcalVariables";
import BlockWrapper from "./blockWrapper";
import { determineDefaultModalStyle } from "../../../lib/modalFunctions";
import { useIsFixedSeating } from "../../teamPlans/hooks";
import { getBackendSubscriptionCoreSeatCount, getBackendSubscriptionEASeatCount } from "../../../services/accessors/backendSubscription";

/**
 * Renders the block that allows the user to view/manage the seats in their subscription.
 */
export default function SeatsBlock() {
  const backendSubscription = useBackendSubscriptionStore(state => state.backendSubscription);
  const masterAccount = useMasterAccount(state => state.masterAccount);

  const [nonEASeats, EASeats] = useMemo(() => {
    if (!backendSubscription) {
      return isUserMaestroUser(masterAccount) ? [0, 1] : [1, 0];
    }

    return [
      getBackendSubscriptionCoreSeatCount(backendSubscription),
      getBackendSubscriptionEASeatCount(backendSubscription),
    ];
  }, [backendSubscription]);

  return (
    <BlockWrapper title="Your subscription">
      <div className="grid grid-cols-2 gap-2 w-full" style={{ maxWidth: 208 }}>
        <SeatsRow label="Vimcal" seatCount={nonEASeats} />
        <SeatsRow label="Vimcal EA" seatCount={EASeats} />
      </div>
      <div><ManageSeatsActions /></div>
    </BlockWrapper>
  );
}

interface SeatsRowProps {
  label: string
  seatCount: number
}

function SeatsRow({ label, seatCount }: SeatsRowProps) {
  if (seatCount <= 0) {
    return null;
  }

  return (
    <>
      <div className="secondary-text-color">{label}</div>
      <div className="text-right">{seatCount} {pluralize(seatCount, "seat")}</div>
    </>
  );
}

const ADD_SEATS_MODAL = "add_seats";
const REMOVE_SEATS_MODAL = "remove_seats";
type ManageSeatModalType = typeof ADD_SEATS_MODAL | typeof REMOVE_SEATS_MODAL;

function ManageSeatsActions() {
  const [currentModal, setCurrentModal] = useState<ManageSeatModalType>();
  const isDarkMode = useSelector(state => state.isDarkMode);
  const teamPlan = useTeamPlan(state => state.teamPlan);
  const masterAccount = useMasterAccount(state => state.masterAccount);
  const stripeSubscription = useStripeSubscriptions(state => state.stripeSubscriptions);

  const isOnTeamPlan = !isEmptyObjectOrFalsey(teamPlan);
  const isFixedSeating = useIsFixedSeating();
  const isInTrial = isUserInFreeTrial(masterAccount);
  const disableButtons = isScheduledToBeCancelled({ stripeSubscription });
  const isAddSeatsModalOpen = currentModal === ADD_SEATS_MODAL;
  const showRemoveButton = isOnTeamPlan && !isInTrial && !isFixedSeating;

  const blueButtonLabel = useMemo(() => {
    if (!isOnTeamPlan) {
      return "Create team plan";
    }

    if (isInTrial) {
      return "Manage team plan";
    }

    return isFixedSeating ? "Invite members" : "Add seats";
  }, [isFixedSeating, isInTrial, isOnTeamPlan]);

  const blueButtonOnClick = useCallback(() => {
    if (isInTrial && isOnTeamPlan) {
      // In trial team plans, there is no concept of empty seats.
      settingsBroadcast.publish(
        SETTINGS_BROADCAST_VALUES.SET_SETTINGS_PAGE,
        { option: APP_SETTINGS.TEAM },
      );
      return;
    }

    setCurrentModal(ADD_SEATS_MODAL);
  }, [isInTrial, isOnTeamPlan]);

  const closeModal = () => setCurrentModal(undefined);

  return (
    <div className="flex gap-2">
      {showRemoveButton ? <CustomButtonV2
        buttonType={WHITE_BUTTON}
        disabled={disableButtons}
        label="Remove seats"
        onClick={() => setCurrentModal(REMOVE_SEATS_MODAL)}
      /> : null}
      <CustomButtonV2
        buttonType={BLUE_BUTTON}
        disabled={disableButtons}
        label={blueButtonLabel}
        onClick={blueButtonOnClick}
      />
      <EventModalPopup
        isOpen={!!currentModal}
        onRequestClose={closeModal}
        width={isAddSeatsModalOpen ? undefined : 380}
        title={isAddSeatsModalOpen ? undefined : "Remove seats"}
        hideHeader={isAddSeatsModalOpen}
        style={determineDefaultModalStyle(isDarkMode, isAddSeatsModalOpen)}
      >
        {isAddSeatsModalOpen ? (
          <UpdatedAddTeamMembersModal closeModal={closeModal} />
        ) : (
          <RemoveSeats onClose={closeModal} />
        )}
      </EventModalPopup>
    </div>
  );
}
