import classNames from "classnames";
import React from "react";
import { Edit2 } from "react-feather";

const CONTENT_WIDTH = 315;

export default function PersonalLinkDetailsPanel(props) {
  const { onClick, children } = props;

  return (
    <div className="margin-top-twenty">
      <div className="hoverable-secondary-text-color">
        <div
          id="availability-text-content"
          className={classNames(
            "select-availability-panel-content-wrapper",
            "select-availability-panel-content-flex",
            "cursor-pointer",
          )}
          style={{ width: CONTENT_WIDTH }}
          onClick={onClick}
        >
          {children}
          <div className="edit-pen-always-showing">
            <Edit2 size={14} />
          </div>
        </div>
      </div>
    </div>
  );
}
