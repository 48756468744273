import React from "react";
import { EXTERNAL_INTERNAL_CONSTS } from "./metricsAccessorFunctions";

const TransparentPieChart = ({ inputPercentage, externalColor, internalColor }) => {
  const percentage = getPercentage(inputPercentage);
  // const percentage = getPercentage(inputPercentage);
  const strokeWidth = 6; // Adjust this value to change the border thickness
  const circleRadius = 50 - strokeWidth / 2;
  const circleCircumference = 2 * Math.PI * circleRadius;

  const marginInPixels = 10;
  const marginInPercentage = Math.max(0, Math.floor((marginInPixels * 100) / circleCircumference));
  const remainingPercentage = Math.max(100 - percentage - marginInPercentage - 10, 0);

  const getDashArray = (value, gap) => {
    const dashLength = (circleCircumference * value) / 100;
    const gapLength =
      circleCircumference - dashLength - (circleCircumference * gap) / 100;
    return `${dashLength} ${gapLength}`;
  };

  return (
    <div 
      style={{ width: "70px", minWidth: "70px", height: "70px", position: "relative" }}
      className="flex items-center justify-center mr-5"
    >
      <svg
        width="70"
        height="70"
        viewBox="0 0 100 100"
        style={{
          transform: "rotate(-90deg)",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={internalColor ?? "#CE1E7A"} />
            <stop offset="100%" stopColor={internalColor ?? "#F95D66"} />
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          r={circleRadius}
          stroke={externalColor ?? EXTERNAL_INTERNAL_CONSTS.EXTERNAL.backgroundColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray={getDashArray(
            remainingPercentage,
            marginInPercentage
          )}
          strokeDashoffset="0"
        />

        <circle
          cx="50"
          cy="50"
          r={circleRadius}
          // stroke={EXTERNAL_INTERNAL_CONSTS.INTERNAL.backgroundColor}
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray={getDashArray(percentage, marginInPercentage)}
          strokeDashoffset={
            (circleCircumference * (percentage + marginInPercentage)) / 100 + 5
          }
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: strokeWidth,
          left: strokeWidth,
          right: strokeWidth,
          bottom: strokeWidth,
          borderRadius: "50%",
          background: "transparent",
        }}
      />
    </div>
  );
};

function getPercentage(inputPercentage) {
  if (inputPercentage > 99) {
    return 100;
  }

  if (inputPercentage > 84 ) {
    return Math.floor(85);
  }
  return Math.floor(inputPercentage);
}

export default TransparentPieChart;
